import React, { useEffect, useState, useRef, useReducer } from "react";
import "./../../assets/css/Dashboard.css";
import Header from "./../Header";
import Leftpanel from "./../Leftpanel";
import { Link, Redirect } from "react-router-dom";
import Select from "react-select";
import { FormGroup, Label, Input } from "reactstrap";
import OutsideClickHandler from "react-outside-click-handler";
import addDays from "date-fns/addDays";
import Imageratio from "../../Components/commonComponents/ImgRatio"
import Newfeatureicon from "../../Components/commonIcons/Newfeatureicon.js";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";

import { Carousel } from "react-responsive-carousel";
import DatePicker from "react-datepicker";

import moment from "moment";
import { InnerFoot } from "./../Foot";
import Slider from "./.././Reusable/Slider";
import userdefaultImgOnError from "../../assets/images/dashboard/accountprofile.png";
import wpdefaultImgOnError from "../../assets/images/dashboard/workspace/workspace-default-img.png";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import styles from "./cmsBuilderstyle.css";
import imagebackground from "./../../assets/images/dashboard/cms/no-post-img.png";
import newDefaultImage from "./../../assets/images/dashboard/default-img.png";
import imagebackground2 from "./../../assets/images/dashboard/cms/bannerimg.jpg";
import imagebackground3 from "./../../assets/images/dashboard/cms/list-preview-banner.png";
import imagebackground4 from "./../../assets/images/dashboard/my-account.png";

import gallery1 from "./../../assets/images/dashboard/cms/gallery1.png";
import gallery2 from "./../../assets/images/dashboard/cms/gallery2.png";
import gallery3 from "./../../assets/images/dashboard/cms/gallery3.png";
import gallery4 from "./../../assets/images/dashboard/cms/gallery4.png";

import related1 from "./../../assets/images/dashboard/cms/related1.png";
import related2 from "./../../assets/images/dashboard/cms/related2.png";
import related3 from "./../../assets/images/dashboard/cms/related3.png";
import related4 from "./../../assets/images/dashboard/cms/related4.png";
import SettingIcon from "../../Components/commonIcons/SettingIcon";
// import useOutSideClick from "../useOutSideClick";
import {
  bulkPostUpdate,
  cmsDeletePostApi,
  cmsGetAtrApi,
  cmsgetCategoryApi,
  cmsGetPostsApi,
  cmsgetTagsApi,
  cmsSavePostApiForListing,
  forcelyDeletePost,
  GetpostapiForFunctionalCompo,
  hideUnhidePostApi,
  isFeaturedPostApi,
  PostapiForFunctionalCompo,
  recyclePostApi,
  teamApiForCMS,
} from "../../Api/CmsApi/Cmsapi";
import { ParentFilterModal } from "./ParentFilterModal";
import BottomScrollListener from "react-bottom-scroll-listener";
import SortIcon from "./SortIcon";
import {
  MultiSelectionPopUpForAttribute,
  MultiSelectionPopUpForCustomApp,
} from "../Reusable/MultiSelectionPopUp";
import { errortoast, sucesstoast } from "../Toaster";
import DeleteIcon from "../../Components/commonIcons/DeleteIcon";
import Tooltip from "../../Components/commonIcons/Tooltip";
import LockedToggle from "../../Components/commonComponents/LockedToggle";
import greyBookmarkIcon from "../../assets/images/dashboard/grey-bookmark-post.svg"
// import redBookmarkIcon from "../../assets/images/dashboard/red-boomark-post.svg"

const Postlist = () => {
  const wrapperRef = useRef(null);
  const rightSideRef = useRef(null);
  const showTagRef = useRef(null);
  const showAtrRef = useRef(null);
  const showCatRef = useRef(null);
  const showAuthorRef = useRef(null);
  const showPublishDateRef = useRef(null);
  const showRelatedPostsRef = useRef(null);

  const [activeTab, setActiveTab] = useState("1");
  const [showAttribute, setShowAttribute] = useState(true);
  const [showAttributeAsaList, setShowAttributeAsaList] = useState(true);
  const [spinner, setSpinner] = useState(
    parseInt(localStorage.getItem("website_technology")) === 3 ? true : false
  );
  const [settingSpinner, setSettingSpinner] = useState(
    parseInt(localStorage.getItem("website_technology")) === 3 ? false : true
  );
  const [isTrashed, setIsTrashed] = useState(false);
  const [bulkDeletePopup, setBulkDeletePopup] = useState(false);
  const [postData, setPostData] = useState([]);
  const [postDataCopy, setPostDataCopy] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [spinner2, setSpinner2] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [eachPostId, setEachPostId] = useState({});
  const [isDeleted, setisDeleted] = useState(false);
  const [isLoader, setIsLoadere] = useState("d-none");
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [postfiltershow, setPostFilterShow] = useState(false);

  const [openCatMultiSelectionPopUp, setOpenCatMultiSelectionPopUp] =
    useState(false);
  const [openAtrMultiSelectionPopUp, setOpenAtrMultiSelectionPopUp] =
    useState(false);
  const [openTagMultiSelectionPopUp, setOpenTagMultiSelectionPopUp] =
    useState(false);
  const [catSubData, setCatSubData] = useState([]);
  const [oldCatSubData, setOldCatSubData] = useState([]);
  const [catBreadcrumbArr, setCatBreadcrumbArr] = useState([]);
  const [oldCatBreadcrumbArr, setOldCatBreadcrumbArr] = useState([]);
  const [tagSubData, setTagSubData] = useState([]);
  const [oldTagSubData, setOldTagSubData] = useState([]);
  const [tagBreadcrumbArr, setTagBreadcrumbArr] = useState([]);
  const [oldTagBreadcrumbArr, setOldTagBreadcrumbArr] = useState([]);
  const [atrSubData, setAtrSubData] = useState([]);
  const [oldAtrSubData, setOldAtrSubData] = useState([]);
  const [atrBreadcrumbArr, setAtrBreadcrumbArr] = useState([]);
  const [oldAtrBreadcrumbArr, setOldAtrBreadcrumbArr] = useState([]);

  const [tempCatSelection, setTempCatSelection] = useState([]);
  const [tempAtrSelection, setTempAtrSelection] = useState([]);
  const [tempTagSelection, setTempTagSelection] = useState([]);
  const [catSelection, setCatSelection] = useState([]);
  const [atrSelection, setAtrSelection] = useState([]);
  const [tagSelection, setTagSelection] = useState([]);
  const [catSearchValForMultiPopUp, setCatSearchValForMultiPopUp] =
    useState("");
  const [atrSearchValForMultiPopUp, setAtrSearchValForMultiPopUp] =
    useState("");
  const [tagSearchValForMultiPopUp, setTagSearchValForMultiPopUp] =
    useState("");
  const [bottomSpinner, setBottomSpinner] = useState(false);
  const [featuredToggle, setFeaturedToggle] = useState(false);

  const [postListSetting, setPostListSetting] = useState(
    parseInt(localStorage.getItem("website_technology")) === 3 ? false : true
  );

  const [showSearch, setShowSearch] = useState(true);
  const [showGridView, setShowGridView] = useState(true);
  const [isWebView, setIsWebView] = useState(false);
  const [showAuthor, setShowAuthor] = useState(true);
  const [showPublishDate, setShowPublishDate] = useState(true);
  const [showPublishDateForListing, setShowPublishDateForListing] =
    useState(true);
  const [showCategories, setShowCategories] = useState(true);

  const [showTags, setShowTags] = useState(true);
  const [showAddInfo, setShowAddInfo] = useState(false);

  const [showRelatedPosts, setShowRelatedPosts] = useState(true);
  const [isRelatedToPopup, setIsRelatedToPopup] = useState(false);
  const [openMobileRelatedToDropdown, setOpenMobileRelatedToDropdown] =
    useState(false);
  const [postRelatedTo, setPostRelatedTo] = useState("tags");
  const [settingSaveBtnLoader, setSettingSaveBtnLoader] = useState("d-none");
  const [settingSaveBtnVal, setSettingSaveBtnVal] = useState("Save");
  const [previewListingView, setPreviewListingView] = useState(
    showGridView ? "grid" : "list"
  );
  const [activeSlide, setActiveSlide] = useState(0);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateTimeValue, setDateTimeValue] = useState(null);
  const [openMainFilter, setOpenMainFilter] = useState(false);
  const [openMobPostFilter, setOpenMobPostFilter] = useState(false);
  const [openMobParentFilter, setOpenMobParentFilter] = useState(false);
  const [openMobParentFilter1, setOpenMobParentFilter1] = useState(false);
  const [openMobParentFilter2, setOpenMobParentFilter2] = useState(false);
  const [openMobParentFilter3, setOpenMobParentFilter3] = useState(false);

  const [openStatusFilter, setOpenStatusFilter] = useState(false);
  const [openMobStatusFilter, setOpenMobStatusFilter] = useState(false);
  const [openTagFilter, setOpenTagFilter] = useState(false);
  const [openMobTagFilter, setOpenMobTagFilter] = useState(false);
  const [openCatFilter, setOpenCatFilter] = useState(false);
  const [openMobCatFilter, setOpenMobCatFilter] = useState(false);
  const [redDotOnFilter, setRedDotOnFilter] = useState(false);

  const [imgRatioForListing, setImgRatioForListing] = useState("4:3");
  const [imgRatioForDetails, setImgRatioForDetails] = useState("4:3");
  const [imgShapeForListing, setImgShapeForListing] = useState("soft_corner");
  const [imgShapeForDetails, setImgShapeForDetails] = useState("soft_corner");
  const [imgCenterForDetails, setImgCenterForDetails] = useState(true);
  const [imgCenterForListing, setImgCenterForListing] = useState(true);
  const [sectionTopMargin, setSectionTopMargin] = useState("7.5");
  const [sectionBottomMargin, setSectionBottomMargin] = useState("7.5");
  const [sectionRightMargin, setSectionRightMargin] = useState("15");
  const [sectionLeftMargin, setSectionLeftMargin] = useState("15");
  const [mergeUserOwnerData, setMergeUserOwnerData] = useState([]);

  const [optionForMainFilter, setOptionForMainFilter] = useState([
    { value: "Status", label: "Status" },
    { value: "Categories", label: "Categories" },
    { value: "Tags", label: "Tags" },
  ]);
  const [filterSelectionArr, setFilterSelectionArr] = useState([
    {
      index: 0,
      value: "",
    },
  ]);
  const [filterDisabled, setFilterDisabled] = useState(false);
  const [tagArrForPost, setTagArrForPost] = useState([]);

  const [tagData, setTagData] = useState([]);
  const [atrListingData, setAtrListingData] = useState([]);
  const [duumySpin1, setDuumySpin1] = useState(false);
  const [duumySpin2, setDuumySpin2] = useState(false);
  const [openMobileCatDropdown, setOpenMobileCatDropdown] = useState(false);
  const [openMobileTagDropdown, setOpenMobileTagDropdown] = useState(false);
  const [tagSearchVal, setTagSearchVal] = useState("");
  const [catSearchVal, setCatSearchVal] = useState("");
  const [catCheckedArr, setCatCheckedArr] = useState([]);
  const [tagCheckedArr, setTagCheckedArr] = useState([]);
  const [spinner3, setSpinner3] = useState(true);
  const [catArrForPost, setCatArrForPost] = useState([]);
  const [statusFilterVal, setStatusFilterVal] = useState('');
  const [mobSelectedMainFilterIndx, setMobSelectedMainFilterIndx] = useState(0);
  const [submitval, setSubmitval] = useState("Delete");
  const [loaderimage, setLoaderimage] = useState(
    require("../../assets/images/signup/Loader_GIF.gif").default
  );
  const [profilepath, setProfilepath] = useState("");
  const [subTagData, setSubTagData] = useState([]);

  const [subData, setSubData] = useState([]);

  // For post data
  const [page, setPage] = useState(1);
  const [paginationSpinner, setPaginationSpinner] = useState(false);
  const [hitNextPagination, setHitNextPagination] = useState(false);
  // const [subData , setSubData] = useState(false);

  // For category data
  const [pageForCat, setPageForCat] = useState(1);
  const [paginationSpinnerForCat, setPaginationSpinnerForCat] = useState(false);
  const [hitNextPaginationForCat, setHitNextPaginationForCat] = useState(false);

  //For Tag data
  const [pageForTag, setPageForTag] = useState(1);
  const [paginationSpinnerForTag, setPaginationSpinnerForTag] = useState(false);
  const [hitNextPaginationForTag, setHitNextPaginationForTag] = useState(false);

  //For Attribute data
  const [pageForAtr, setPageForAtr] = useState(1);
  const [paginationSpinnerForAtr, setPaginationSpinnerForAtr] = useState(false);
  const [hitNextPaginationForAtr, setHitNextPaginationForAtr] = useState(false);

  const [bulkUpdateFilter, setBulkUpdateFilter] = useState(false);
  const [bulkUpdateLoader, setBulkUpdateLoader] = useState(false);
  const [bulkDeleteLoader, setBulkDeleteLoader] = useState(false);
  const [tableView, setTableView] = useState(() => {
    if (localStorage.getItem("postsTableView")) {
      return localStorage.getItem("postsTableView") === "true";
    }
    return false;
  });
  const [sortFields, setSortFields] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      title: false,
      author_info: false,
      created_at: false,
      updated_at: false,
      status: false,
    }
  );

  const [bulkUpdateData, setBulkUpdateData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      author_user_id: null,
      status: null,
      is_featured: null,
    }
  );
  const [selectedArr, setSelectedArr] = useState([]);

  const [profileimage, setProfileimgage] = useState("");
  const [appRightPanelExpand, setAppRightPanelExpand] = useState(
    localStorage.getItem("appSideBarCollapse") === true ||
      localStorage.getItem("appSideBarCollapse") === "true"
      ? true
      : false
  );

  const [postState, setPostState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      settings: {
        showExcerptForListing: false,
        settingUpdatedAt: "",
        enableBookmarkingOnList:parseInt(localStorage.getItem("website_technology")) === 3 ? true: false,
        showBookmarkIconInHeader:parseInt(localStorage.getItem("website_technology")) === 3 ? true: false,
        showFilterIconOnList: false,
        showSortIconOnList: false,
        showTotalCountOnList: false,
        showAuthorOnList: false,
        showMediaInFullScreenOnDetails:false,
        showMediaAsThumbnailOnDetails:false,
        showShareIconOnDetails:false,
      },
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      parseInt(localStorage.getItem("website_technology")) !== 3 &&
      window.location.pathname.includes("custom")
    ) {
      window.location.href = "/app/dashboard";
    }
    setProfilepath(
      localStorage.getItem("workspaceId") !== "" &&
        localStorage.getItem("workspaceId") !== "undefined" &&
        localStorage.getItem("workspaceId") !== undefined &&
        localStorage.getItem("workspaceId") !== null
        ? process.env.REACT_APP_Image_Path +
            (parseInt(localStorage.getItem("logged_in_user_role_id")) === 1
              ? localStorage.getItem("logged_in_user_id")
              : localStorage.getItem("invited_by_user_id")) +
            process.env.REACT_APP_Workspace_Profile +
            localStorage.getItem("workspaceId") +
            "/"
        : process.env.REACT_APP_Image_Path +
            // (localStorage.getItem("roleid") === "1"
            //   ? localStorage.getItem("originalid")
            //   : localStorage.getItem("user_id")) +
            localStorage.getItem("logged_in_user_id") +
            process.env.REACT_APP_Profile
    );

    setProfileimgage(
      localStorage.getItem("workspaceId") !== "" &&
        localStorage.getItem("workspaceId") !== "undefined" &&
        localStorage.getItem("workspaceId") !== undefined &&
        localStorage.getItem("workspaceId") !== null
        ? localStorage.getItem("workspaceImage")
        : localStorage.getItem("user_profile")
    );
    if (
      localStorage.getItem("agencyid") &&
      localStorage.getItem("agencyid") == 1
    ) {
      document.title = `${
        postListSetting ? "Post settings" : "Posts"
      } | AppMySite`;
    } else {
      document.title = `${
        postListSetting ? "Post settings" : "Posts"
      } | ${localStorage.getItem("agencyname")}`;
    }

    const params = {
      app_id: btoa(localStorage.getItem("Appid")),
    };
    GetpostapiForFunctionalCompo(
      params,
      setShowSearch,
      setShowGridView,
      setShowAuthor,
      setShowPublishDate,
      setShowCategories,
      setShowTags,
      setShowRelatedPosts,
      setPostRelatedTo,
      setSettingSpinner,
      setIsWebView,
      setShowAddInfo,
      setShowPublishDateForListing,
      setShowAttribute,
      setShowAttributeAsaList,

      setImgCenterForDetails,
      setImgCenterForListing,
      setImgRatioForDetails,
      setImgRatioForListing,
      setImgShapeForDetails,
      setImgShapeForListing,
      setSectionTopMargin,
      setSectionBottomMargin,
      setSectionLeftMargin,
      setSectionRightMargin,

      postState,
      setPostState
    );

    if (parseInt(localStorage.getItem("website_technology")) === 3) {
      let limit = 20;
      let pageNo = 1;
      setPage(pageNo);

      cmsGetPostsApi(
        setPostData,
        btoa(localStorage.getItem("Appid")),
        setSpinner,
        categoryFilter,
        limit,
        pageNo,
        postData,
        setSubData,
        setHitNextPagination,
        setPaginationSpinner,
        setPostDataCopy
      );
    }

    if (parseInt(localStorage.getItem("website_technology")) === 3) {
      let limit = 99;
      let pageNo = 1;
      setPageForTag(pageNo);

      cmsGetAtrApi(
        setDuumySpin1,
        setAtrListingData,
        setDuumySpin2,
        "post-list",
        // apiAtrData,
        [],
        false,
        limit,
        pageNo,
        atrListingData,
        setHitNextPaginationForAtr,
        setPaginationSpinnerForAtr,
        ""
      );

      cmsgetTagsApi(
        setTagData,
        btoa(localStorage.getItem("Appid")),
        setSpinner3,
        false,
        setTagCheckedArr,
        [],
        limit,
        page,
        tagData,
        setHitNextPaginationForTag,
        setPaginationSpinnerForTag,
        setSubTagData
      );
    }

    teamApiForCMS(
      {
        app_id: btoa(localStorage.getItem("Appid")),
      },
      setMergeUserOwnerData
    );

    if (parseInt(localStorage.getItem("website_technology")) === 3) {
      let limit = 99;
      let pageNo = 1;
      setPageForCat(pageNo);

      cmsgetCategoryApi(
        setCategoryData,
        btoa(localStorage.getItem("Appid")),
        setSpinner3,
        false,
        false,
        setCatCheckedArr,
        [],
        false,
        limit,
        pageNo,
        categoryData,
        setHitNextPaginationForCat,
        setPaginationSpinnerForCat,
        ""
      );
    }
  }, [isDeleted]);

  useEffect(() => {
    if (!postData.length) {
      setTableView(false);
    } else {
      setTableView(localStorage.getItem("postsTableView") === "true");
    }
  }, [postData])

  const handleSettingsToggles = (key) => {
    let newPostSettings = { ...postState.settings };
    newPostSettings[key] = !newPostSettings[key];

    if(key==="showMediaInFullScreenOnDetails" || key==="showMediaAsThumbnailOnDetails"){

        if(newPostSettings[key]){
        setActiveSlide(key === "showMediaAsThumbnailOnDetails" && newPostSettings.showMediaInFullScreenOnDetails ? 2 :
                       key === "showMediaInFullScreenOnDetails" ? 2 : 1 )
      }else{
        setActiveSlide(key ==="showMediaAsThumbnailOnDetails" && newPostSettings.showMediaInFullScreenOnDetails ? 2 : 1)
      }
      }
    


      setPostState({ settings: newPostSettings });
  };

  const handleMobileRelatedToDropdown = (e) => {
    setOpenMobileRelatedToDropdown(!openMobileRelatedToDropdown);
  };

  const handleRelatedToPopUp = (e) => {
    if(!window.matchMedia("(max-width: 1200px)").matches && !isWebView && showRelatedPosts){
      setIsRelatedToPopup(!isRelatedToPopup);
    }
  };
  const handleRelatedToChange = (e, value) => {
    setPostRelatedTo(value);
    setOpenMobileRelatedToDropdown(false);
  };

  const handlePostFilter = () => {
    // setBulkUpdateFilter(true)
    setPostFilterShow(!postfiltershow);
    if (postfiltershow === true) {
      document.body.classList.remove("opec");
    } else {
      document.body.classList.add("opec");
    }
  };

  const handleDrawerClose = (shouldClose) => {
    if (shouldClose) {
      document.body.classList.remove("opec");
      setBulkUpdateFilter(false);
      setSelectedArr([]);
    }
    setBulkUpdateData({
      author_user_id: null,
      status: null,
      is_featured: null,
    });
    setAtrListingData(
      atrListingData.map((elem) => ({ ...elem, is_checked: false }))
    );
    setCatArrForPost([]);
    setTagArrForPost([]);
    setTagSelection([]);
    setAtrSelection([]);
    setCatSelection([]);
    setTempAtrSelection([]);
    setTempCatSelection([]);
    setTempTagSelection([]);
    setDateTimeValue(null);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  // const handleDeletePage = (e, postId) => {
  //   const params = { post_id: postId };
  //   cmsDeletePostApi(params);
  // };

  const handleSearch = (e) => {
    setSearchVal(e.target.value);
  };

  // Trash Pop up

  const handleTrashedPopUp = (e, from, id) => {
    setIsTrashed(!isTrashed);
    if (from === "add" && postData !== undefined) {
      setEachPostId(id);
    }
  };

  const handleBulkDeletePopup = (from) => {
    if (from === "delete") {
      handleBulkUpdateSubmit(true);
      return;
    }
    setBulkDeletePopup(!bulkDeletePopup);
  };

  //Trash Post
  const handleTrashedPost = (e) => {
    setIsTrashed(!isTrashed);
    // setSubmitval("");
    // setIsLoadere("d-block");
    const params = {
      post_id: eachPostId,
      app_id: parseInt(localStorage.getItem("Appid")),
    };
    setSpinner(true);
    cmsDeletePostApi(params, setisDeleted, isDeleted);
    // setisDeleted(!isDeleted);
  };

  // Featured post

  const handleFeaturedPost = (e, postId, is_featured, postData22, indx) => {
    let newArr = [...postData];
    newArr[indx].is_featured = !is_featured;
    setPostData(newArr);
    const params = {
      post_id: postId,
      is_featured: !is_featured,
      app_id: parseInt(localStorage.getItem("Appid")),
    };
    isFeaturedPostApi(params, postData, setPostData, indx);
  };

  //Recover Post
  const handleRecoverPost = (e, PostId) => {
    const params = {
      post_id: PostId,
      app_id: parseInt(localStorage.getItem("Appid")),
    };
    setSpinner(true);
    recyclePostApi(params, setisDeleted, isDeleted);
  };

  // Forcely Delete Post
  const handleForclyDelete = (e, postId) => {
    const params = {
      post_id: postId,
      app_id: parseInt(localStorage.getItem("Appid")),
    };
    forcelyDeletePost(params, setisDeleted, isDeleted);
    setSpinner(true);
  };

  let publisCount = 0;
  let draftCount = 0;
  let scheduledCount = 0;
  let trashCount = 0;
  let allCount = 0;

  const newArr = [];
  const childParentList = (newArr) => {
    return newArr.map((eachCat) => {
      return <></>;
    });
  };

  function recurs(arr, check) {
    return arr.map((eachCat, indx) => {
      newArr.push(eachCat);

      if (eachCat.child !== undefined && eachCat.child.length > 0) {
        // if parent has childrens
        recurs(eachCat.child, "child");
      }

      return (
        check === "parent" &&
        indx === categoryData.length - 1 &&
        childParentList(newArr)
      );
    });
  }

  const handleListingView = (e, from) => {
    setPreviewListingView(from);
  };

  const addCopySuffix = (inputString) => {
    // Check if the string already contains a "clone(n)" suffix
    if (inputString.includes("clone")) {
      var match = inputString.match(/clone\((\d+)\)/);

      if (match) {
        var existingNumber = parseInt(match[1]);
        var newNumber = existingNumber + 1;
        var newSuffix = "- clone - clone";

        return inputString.replace(/clone\(\d+\)/, newSuffix);
      }
    }

    return inputString + " - clone";
  };

  const handleClonePost = (e, eachPost) => {
    setBottomSpinner(true);
    // let copyObj = JSON.parse(JSON.stringify(eachPost)) ;

    let assignCategoryArr = [];
    let assignTagArr = [];
    if (eachPost.categories !== null && eachPost.categories.length > 0) {
      eachPost.categories.map((cat) => {
        assignCategoryArr.push(cat.$oid);
      });
    }
    if (eachPost.tags !== null && eachPost.tags.length > 0) {
      eachPost.tags.map((cat) => {
        assignTagArr.push(cat.$oid);
      });
    }
    let newContentArr = JSON.parse(JSON.stringify(eachPost.content));
    newContentArr.map((element) => {
      if (element.item_type === "title") {
        element.value.title.text = addCopySuffix(eachPost.title);
      }
    });

    // copyObj.author_user_id = copyObj.author_user_id!==undefined && copyObj.author_user_id!==null ?
    // copyObj.author_user_id :copyObj.user_id ;
    // copyObj.title = addCopySuffix(copyObj.title);
    // copyObj.content = newContentArr;
    // copyObj.tags =assignTagArr;
    // copyObj.categories =assignCategoryArr;
    // copyObj.published_at =copyObj.published_at?new Date(parseInt(copyObj.published_at.$date.$numberLong)):new Date() ;
    // copyObj.post_id = "" ;

    const params = {
      app_id: eachPost.app_id,
      user_id: eachPost.user_id,
      author_user_id: parseInt(localStorage.getItem("logged_in_user_id")),

      title: addCopySuffix(eachPost.title),
      description: eachPost.description,

      content: newContentArr,

      featured_media: eachPost.featured_media,
      source_file: eachPost.source_file,
      status: eachPost.status,

      tags: assignTagArr,
      categories: assignCategoryArr,
      published_at: eachPost.published_at
        ? new Date(parseInt(eachPost.published_at.$date.$numberLong))
        : new Date(),
      published_immediate: eachPost.published_immediate,
      post_id: "",
      excerpt: eachPost.excerpt,
      is_featured: eachPost.is_featured,
      isassigncategory: eachPost.isassigncategory,
      isassigntag: eachPost.isassigntag,
      show_categories: eachPost.show_categories ? eachPost.show_categories : 0,
      show_tags: eachPost.show_tags ? eachPost.show_tags : 0,
      show_author: eachPost.show_author ? eachPost.show_author : 0,
      related_to: eachPost.related_to ? eachPost.related_to : "categories",
      show_related_posts: eachPost.show_related_posts
        ? eachPost.show_related_posts
        : 0,
      show_publish_date: eachPost.show_publish_date
        ? eachPost.show_publish_date
        : 0,
      override_post_master_settings: eachPost.override_post_master_settings
        ? eachPost.override_post_master_settings
        : 0,
      attributes_data: eachPost.attributes_data ? eachPost.attributes_data : [],
      show_in_results:
        eachPost.show_in_results !== undefined &&
        eachPost.show_in_results !== null
          ? eachPost.show_in_results
          : 1,
    };

    cmsSavePostApiForListing(params, setBottomSpinner, () => {
      let limit = 20;
      let pageNo = 1;
      setPage(pageNo);
      cmsGetPostsApi(
        setPostData,
        btoa(localStorage.getItem("Appid")),
        setBottomSpinner,
        categoryFilter,
        limit,
        pageNo,
        postData,
        setSubData,
        setHitNextPagination,
        setPaginationSpinner,
        setPostDataCopy
      );
    });
  };
  const handleEyeButton = (e, postId, status, indx) => {
    let newArr = [...postData];
    newArr[indx].show_in_results =
      status === 1 || status === undefined || status === null ? 0 : 1;
    setPostData(newArr);
    const params = {
      app_id: parseInt(localStorage.getItem("Appid")),
      post_id: postId,
      show_in_results:
        status === 1 || status === undefined || status === null ? 0 : 1,
    };
    hideUnhidePostApi(params, postData, setPostData, indx);
  };

  const handlePostListSettingBtn = () => {
    setPostListSetting(!postListSetting);
    // setTableView(false)
  };
  const handleSubmitPostSettingsData = () => {
    setSettingSaveBtnLoader("d-block");
    setSettingSaveBtnVal("");

    const params = {
      app_id: parseInt(localStorage.getItem("Appid")),
      enable_post_search: showSearch ? 1 : 0,
      posts_view_style: showGridView ? "grid" : "list",
      show_author: showAuthor ? 1 : 0,
      show_publish_date: showPublishDate ? 1 : 0,
      show_publish_date_for_listing: showPublishDateForListing ? 1 : 0,
      show_categories: showCategories ? 1 : 0,
      show_tags: showTags ? 1 : 0,
      show_related_posts: showRelatedPosts ? 1 : 0,
      related_to: postRelatedTo,
      show_attributes_on_detail_page:
        parseInt(localStorage.getItem("website_technology")) === 3
          ? showAttribute === true
            ? 1
            : 0
          : 0,
      show_attributes_terms_as_list_on_detail_page:
        parseInt(localStorage.getItem("website_technology")) === 3
          ? showAttributeAsaList === true
            ? 1
            : 0
          : 0,
      enable_web_view_interface_bool:
        parseInt(localStorage.getItem("website_technology")) === 3
          ? 0
          : isWebView
          ? 1
          : 0,
      show_additional_information:
        parseInt(localStorage.getItem("website_technology")) === 3
          ? 0
          : showAddInfo
          ? 1
          : 0,
      image_ratio_on_post_listing_page: imgRatioForListing,
      image_ratio_on_post_detail_page: imgRatioForDetails,
      image_shape_on_post_listing_page: imgShapeForListing,
      image_shape_on_post_detail_page: imgShapeForDetails,
      show_center_crop_images_on_post_listing_page:
        imgCenterForListing === true ? 1 : 0,
      show_center_crop_images_on_post_detail_page:
        imgCenterForDetails === true ? 1 : 0,
      section_top_margin_on_post_detail_page: sectionTopMargin,
      section_bottom_margin_on_post_detail_page: sectionBottomMargin,
      section_left_margin_on_post_detail_page: sectionLeftMargin,
      section_right_margin_on_post_detail_page: sectionRightMargin,
      show_excerpt: postState.settings.showExcerptForListing ? 1 : 0,
      
      show_filter_on_post_listing_page:parseInt(localStorage.getItem("website_technology")) === 3 ?
      postState.settings.showFilterIconOnList  ? 1 : 0 : 0,
      show_sorting_on_post_listing_page:parseInt(localStorage.getItem("website_technology")) === 3 ?
      postState.settings.showSortIconOnList  ? 1 : 0 : 0,
      show_total_count_on_post_listing_page:parseInt(localStorage.getItem("website_technology")) === 3 ?
      postState.settings.showTotalCountOnList  ? 1 : 0 : 0,

      show_author_on_post_listing_page:postState.settings.showAuthorOnList  ? 1 : 0,
      enable_share_on_post_detail_page:parseInt(localStorage.getItem("website_technology")) !== 3 ?
      postState.settings.showShareIconOnDetails  ? 1 : 0 : 0,
      show_media_in_full_screen_on_post_detail_page:postState.settings.showMediaInFullScreenOnDetails  ? 1 : 0,
      show_media_as_thumbnail_on_post_detail_page:postState.settings.showMediaAsThumbnailOnDetails  ? 1 : 0,

      show_bookmark_icon_on_post_listing_page: ( postState.settings.enableBookmarkingOnList && parseInt(localStorage.getItem("website_technology")) === 3) ? postState.settings.showBookmarkIconInHeader ? 1 : 0 : 0,
      enable_bookmarking: (postState.settings.enableBookmarkingOnList && parseInt(localStorage.getItem("website_technology")) === 3)  ? 1 : 0,
    };
    PostapiForFunctionalCompo(
      params,
      setSettingSaveBtnLoader,
      setSettingSaveBtnVal,
      postState,
      setPostState
    );
  };


  const handlesliderChange = (from) => {
 

    if(from==="left"){
      if(activeSlide>0){

      
      setActiveSlide(
        (activeSlide===2&& !postState.settings.showMediaInFullScreenOnDetails) ?1:activeSlide-1,
      )
    }
    }else{
      if(activeSlide<2 ){

        setActiveSlide(
          (activeSlide===1&& !postState.settings.showMediaInFullScreenOnDetails ) ?1 :activeSlide+1,
        )
      }
    }
   
    
  };

  const handleShowCatToggle = (e) => {
    setActiveSlide(1);
    
    if (e.target.checked) {
      setShowCategories(true);
    } else {
      setShowCategories(false);
    }

    if (!window.matchMedia("(max-width: 1200px)").matches) {
      setTimeout(() => {
        const itemElement = showAuthorRef.current;
        if (itemElement) {
          itemElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      },1000);
    }
  };

  const handleShowTagToggle = (e) => {
    setActiveSlide(1);

    if (e.target.checked) {
      setShowTags(true);
    } else {
      setShowTags(false);
    }

    if (!window.matchMedia("(max-width: 1200px)").matches) {
      setTimeout(() => {
        const itemElement = showTagRef.current;
        if (itemElement) {
          itemElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      },1000);
    }
  };

  const handleShowAtrToggle = (e) => {
    setActiveSlide(1);

    if (e.target.checked) {
      setShowAttribute(true);
    } else {
      setShowAttribute(false);
    }

    if (!window.matchMedia("(max-width: 1200px)").matches) {
      setTimeout(() => {
        const itemElement = showAtrRef.current;
        if (itemElement) {
          itemElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      },1000);
    }
  };

  const handleShowAtrAsaListToggle = (e) => {
    setActiveSlide(1);

    if (e.target.checked) {
      setShowAttributeAsaList(true);
    } else {
      setShowAttributeAsaList(false);
    }

    if (!window.matchMedia("(max-width: 1200px)").matches) {
      setTimeout(() => {
        const itemElement = showAtrRef.current;
        if (itemElement) {
          itemElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      },1000);
    }
  };

  const handleShowAuthorToggle = (e) => {
    setActiveSlide(1);
    if (e.target.checked) {
      setShowAuthor(true);
    } else {
      setShowAuthor(false);
    }
    if (!window.matchMedia("(max-width: 1200px)").matches) {
      setTimeout(() => {
        const previewItemElement = showAuthorRef.current;

        if (previewItemElement) {
          previewItemElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      },1000);
    }
  };

  const handleShowPublishDate = (e) => {
    setActiveSlide(1);
    if (e.target.checked) {
      setShowPublishDate(true);
    } else {
      setShowPublishDate(false);
    }
    if (!window.matchMedia("(max-width: 1200px)").matches) {
      setTimeout(() => {
        const previewItemElement = showAuthorRef.current;

        if (previewItemElement) {
          previewItemElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      },1000);
    }
  };

  const handleShowPublishDate2 = (e) => {
    setActiveSlide(0);
    if (e.target.checked) {
      setShowPublishDateForListing(true);
    } else {
      setShowPublishDateForListing(false);
    }
    // if(!window.matchMedia("(max-width: 1200px)").matches){
    //   setTimeout(()=>{
    //   const previewItemElement = showAuthorRef.current;

    //   if ( previewItemElement) {

    //     previewItemElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    //   }
    // })}
  };

  const handleShowRelatedPosts = (e) => {
    setActiveSlide(1);
    if (e.target.checked) {
      setShowRelatedPosts(true);
    } else {
      setShowRelatedPosts(false);
    }

    if (!window.matchMedia("(max-width: 1200px)").matches) {
      setTimeout(() => {
        const previewItemElement = showRelatedPostsRef.current;

        if (previewItemElement) {
          previewItemElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      },1000);
    }
  };

  // Filter functionality

  const handleOpenParentDropdown = (index) => {
    if (index === 0) {
      setOpenMobParentFilter1(!openMobParentFilter1);
      setOpenMobParentFilter2(false);
      setOpenMobParentFilter3(false);
    } else if (index === 1) {
      setOpenMobParentFilter2(!openMobParentFilter2);
      setOpenMobParentFilter1(false);
      setOpenMobParentFilter3(false);
    } else if (index === 2) {
      setOpenMobParentFilter3(!openMobParentFilter3);
      setOpenMobParentFilter2(false);
      setOpenMobParentFilter1(false);
    }
  };

  const handleMobPostFilter = (e) => {
    setOpenMobPostFilter(!openMobPostFilter);
  };

  const handleOpenStatusFilter = (e) => {
    setOpenStatusFilter(!openStatusFilter);
  };
  const handleOpenTagFilter = (e) => {
    setOpenTagFilter(!openTagFilter);
  };
  const handleOpenCatFilter = (e) => {
    setOpenCatFilter(!openCatFilter);
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // match with the menu
      borderRadius: 3,
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#7782a1" : "#C8CEDB",
      backgroundColor: state.isDisabled ? "#ffffff" : "",
      height: 50,
      minHeight: 50,
      fontSize: 14,
      color: "#7782A1",
      paddingLeft: 10,
      boxShadow: state.isFocused ? "none" : "none",
      "&:hover": {
        boxShadow: state.isFocused ? "none" : "none",
      },
    }),
    valueContainer: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
      fontSize: 14,
      color: "#7782A1",
    }),
    menu: (base) => ({
      ...base,
      fontSize: "14px",
    }),
    input: (base) => ({
      ...base,
      margin: 0,
      padding: 0,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#1B2952", // Custom colour
      marginRight: 20,
    }),
  };

  const handleMainFilterChange = (option, index) => {
    let isMatch = false;
    let newArr = [...filterSelectionArr];
    let obj = {
      index: index,
      value: option.value,
    };
    newArr &&
      newArr.map((row, indx) => {
        if (row.index === index) {
          row.value = option.value;
          isMatch = true;
        }
      });
    if (!isMatch) {
      newArr.push(obj);
    }
    setFilterSelectionArr(newArr);
    const hasStatus = filterSelectionArr.some(filter => filter.value === "Status");

    const isFilterDisabled = !hasStatus && filterSelectionArr.some(
      (filter) =>
        (filter.value === "Categories" && categoryData.length === 0) ||
        (filter.value === "Tags" && tagData.length === 0)
    );

    setFilterDisabled(isFilterDisabled)

    setOpenMobParentFilter1(false);
    setOpenMobParentFilter2(false);
    setOpenMobParentFilter3(false);
  };

  const isCatOrTagSelected = filterSelectionArr.some(
    (filter) =>
      (filter.value === "Categories" && catArrForPost.length === 0) ||
      (filter.value === "Tags" && tagArrForPost.length === 0)
  );

  const handleReturnMainFilter = (val, index) => {
    return (
      <div
        className="mobile-view-click"
        onClick={
          window.matchMedia("(max-width: 1200px)").matches
            ? () => handleOpenParentDropdown(index)
            : null
        }
      >
        <Select
          options={optionForMainFilter}
          classNamePrefix="post-filter"
          onChange={(option) => handleMainFilterChange(option, index)}
          required={true}
          placeholder="Select"
          styles={customStyles}
          isClearable={false}
          isSearchable={false}
          value={optionForMainFilter.filter((option) => option.value === val)}
          isDisabled={
            window.matchMedia("(max-width: 1200px)").matches ? true : false
          }
          isOptionDisabled={(option) => {
            if (filterSelectionArr.length > 0) {
              return filterSelectionArr.find(
                (row) => row.value === option.value
              );
            } else {
              return false;
            }
          }}
        />
      </div>
    );
  };

  const handleStatusFilterChange = (e, val) => {
    setStatusFilterVal(val);
    setOpenMobStatusFilter(false);
  };

  // Select dropdown Category selection
  const handleAddCatDrop = (e, obj, position) => {
    let newArr = [...catArrForPost];

    const updatedCheckedState = catCheckedArr.map((item, index) =>
      index === position ? !item : item
    );

    setCatCheckedArr(updatedCheckedState);

    if (e.target.checked) {
      newArr.push(obj);
      setCatArrForPost(newArr);
      setTimeout(() => {
        if (document.getElementById("calculate-ele-height")) {
          if (
            document.getElementById("calculate-ele-height").clientHeight > 42 &&
            document.getElementById("calculate-ele-height").clientHeight <= 69
          ) {
            document
              .getElementById("test-height")
              .classList.add("filter-increase-height1");
          } else if (
            document.getElementById("calculate-ele-height").clientHeight > 69 &&
            document.getElementById("calculate-ele-height").clientHeight <= 98
          ) {
            document
              .getElementById("test-height")
              .classList.add("filter-increase-height2");
          } else if (
            document.getElementById("calculate-ele-height").clientHeight > 98
          ) {
            document
              .getElementById("test-height")
              .classList.add("filter-increase-height3");
          }
        }
      });
    } else {
      for (let i = 0; i < newArr.length; i++) {
        if (newArr[i]._id === obj._id) {
          newArr.splice(i, 1);
          break;
        }
      }
      setCatArrForPost(newArr);

      setTimeout(() => {
        if (document.getElementById("calculate-ele-height")) {
          if (
            document.getElementById("calculate-ele-height").clientHeight <= 53
          ) {
            document
              .getElementById("test-height")
              .classList.remove("filter-increase-height1");
          } else if (
            document.getElementById("calculate-ele-height").clientHeight <= 91
          ) {
            document
              .getElementById("test-height")
              .classList.remove("filter-increase-height2");
          } else if (
            document.getElementById("calculate-ele-height").clientHeight <= 130
          ) {
            document
              .getElementById("test-height")
              .classList.remove("filter-increase-height3");
          }
        }
      });
    }
  };

  const handleAddTagDrop = (e, obj, position) => {
    let newArr = [...tagArrForPost];

    const updatedCheckedState = tagCheckedArr.map((item, index) =>
      index === position ? !item : item
    );

    setTagCheckedArr(updatedCheckedState);

    if (e.target.checked) {
      newArr.push(obj);
      setTagArrForPost(newArr);
      setTimeout(() => {
        if (document.getElementById("calculate-ele-height-tag")) {
          if (
            document.getElementById("calculate-ele-height-tag").clientHeight >
              42 &&
            document.getElementById("calculate-ele-height-tag").clientHeight <=
              69
          ) {
            document
              .getElementById("test-height-tag")
              .classList.add("filter-increase-height1");
          } else if (
            document.getElementById("calculate-ele-height-tag").clientHeight >
              69 &&
            document.getElementById("calculate-ele-height-tag").clientHeight <=
              98
          ) {
            document
              .getElementById("test-height-tag")
              .classList.add("filter-increase-height2");
          } else if (
            document.getElementById("calculate-ele-height-tag").clientHeight >
            98
          ) {
            document
              .getElementById("test-height-tag")
              .classList.add("filter-increase-height3");
          }
        }
      });
    } else {
      for (let i = 0; i < newArr.length; i++) {
        if (newArr[i]._id === obj._id) {
          newArr.splice(i, 1);
          break;
        }
      }
      setTagArrForPost(newArr);
      setTimeout(() => {
        if (document.getElementById("calculate-ele-height-tag")) {
          if (
            document.getElementById("calculate-ele-height-tag").clientHeight <=
            53
          ) {
            document
              .getElementById("test-height-tag")
              .classList.remove("filter-increase-height1");
          } else if (
            document.getElementById("calculate-ele-height-tag").clientHeight <=
            91
          ) {
            document
              .getElementById("test-height-tag")
              .classList.remove("filter-increase-height2");
          } else if (
            document.getElementById("calculate-ele-height-tag").clientHeight <=
            130
          ) {
            document
              .getElementById("test-height-tag")
              .classList.remove("filter-increase-height3");
          }
        }
      });
    }
  };

  const handleDash = (level) => {
    switch (level) {
      case 0:
        return <span></span>;
      case 1:
        return <span> - </span>;
      case 2:
        return <span> - - </span>;
      case 3:
        return <span> - - - </span>;
      case 4:
        return <span> - - - - </span>;
      case 5:
        return <span> - - - - - </span>;
      case 6:
        return <span> - - - - - - </span>;
      case 1:
        return <span> - - - - - - - </span>;
      case 7:
        return <span> - - - - - - - - </span>;
      case 8:
        return <span> - - - - - - - - - </span>;
      case 9:
        return <span> - - - - - - - - - - </span>;
    }
  };

  const returnSelectedStatusLabel = (status) => {
    let label = null;
    if (status === "") {
      label = "All";
    } else if (status === "publish") {
      label = "Published";
    } else if (status === "draft") {
      label = "Draft";
    } else if (status === "scheduled") {
      label = "Scheduled";
    }
    return label;
  };

  const returnDeleteButton = (val, indx) => {
    return (
      filterSelectionArr.length > 1 && (
        <button
          className="delete-post-filter-button"
          // style={{backgroundColor:localStorage.getItem('button_background_color') && localStorage.getItem('button_background_color') != 'undefined' && localStorage.getItem('button_background_color') != 'null' ? localStorage.getItem('button_background_color') : '',color:localStorage.getItem('button_text_color')}}
          onClick={() => handleDeleteFilter(val, indx)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15.999"
            viewBox="0 0 15 15.999"
          >
            <g
              id="Group_38004"
              data-name="Group 38004"
              transform="translate(-15 -15)"
            >
              <g
                id="Group_3246"
                data-name="Group 3246"
                transform="translate(15 15.5)"
              >
                <path
                  id="Rectangle_2307"
                  data-name="Rectangle 2307"
                  d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z"
                  transform="translate(1.5 1.999)"
                  fill="none"
                  stroke="#bcc1ce"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <path
                  id="Rectangle_2308"
                  data-name="Rectangle 2308"
                  d="M1,0H4A1,1,0,0,1,5,1V2A0,0,0,0,1,5,2H0A0,0,0,0,1,0,2V1A1,1,0,0,1,1,0Z"
                  transform="translate(5)"
                  fill="none"
                  stroke="#bcc1ce"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <g
                  id="Group_3245"
                  data-name="Group 3245"
                  transform="translate(5.5 5.5)"
                >
                  <line
                    id="Line_110"
                    data-name="Line 110"
                    y2="5"
                    transform="translate(4 0)"
                    fill="none"
                    stroke="#bcc1ce"
                    stroke-miterlimit="10"
                    stroke-width="1"
                  />
                  <line
                    id="Line_111"
                    data-name="Line 111"
                    y1="5"
                    transform="translate(0 0)"
                    fill="none"
                    stroke="#bcc1ce"
                    stroke-miterlimit="10"
                    stroke-width="1"
                  />
                </g>
                <line
                  id="Line_112"
                  data-name="Line 112"
                  x2="15"
                  transform="translate(0 1.999)"
                  fill="none"
                  stroke="#bcc1ce"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
              </g>
            </g>
          </svg>
        </button>
      )
    );
  };

  const handleImgRatioForListing = (e, val) => {
    setActiveSlide(0);
    setImgRatioForListing(val);
  };

  const handleImgRatioForDetails = (e, val) => {
    setActiveSlide(1);
    setImgRatioForDetails(val);
  };

  const handleImgShapeForListing = (e, val) => {
    setActiveSlide(0);
    setImgShapeForListing(val);
  };

  const handleImgShapeForDetails = (e, val) => {
    setActiveSlide(1);
    setImgShapeForDetails(val);
  };

  const handleImgCenterForListing = (e) => {
    setActiveSlide(0);
    if (e.target.checked) {
      setImgCenterForListing(true);
    } else {
      setImgCenterForListing(false);
    }
  };

  const handleImgCenterForDetails = (e) => {
    setActiveSlide(1);
    if (e.target.checked) {
      setImgCenterForDetails(true);
    } else {
      setImgCenterForDetails(false);
    }
  };


  const handleSectionMargin = (e, from) => {
    const regex = /^(\d+(\.5)?)?$|^$/;

    let value = e.target.value;
    setActiveSlide(1);
    const roundToNearestHalf = (num) => {
      if (num % 1 !== 0) {
        return Math.floor(num) + 0.5; 
      }
      return num;
    };

    if (!isNaN(value) && value !== "") {
      value = parseFloat(value);
      value = roundToNearestHalf(value); 
      if (value % 1 === 0) {
        value = value.toFixed(0);
      } else {
        value = value.toFixed(1); 
      }
    }



    if (value >= 0 && value <= 30 && regex.test(value)) {
          if (from === "top") {
            setSectionTopMargin(value);
          } else if (from === "bottom") {
            setSectionBottomMargin(value);
          } else if (from === "right") {
            setSectionRightMargin(value);
          } else if (from === "left") {
            setSectionLeftMargin(value);
          }
        }
    else {
        if (from === "top") {
          setSectionTopMargin("7.5");
        } else if (from === "bottom") {
          setSectionBottomMargin("7.5");
        } else if (from === "right") {
          setSectionRightMargin("15");
        } else if (from === "left") {
          setSectionLeftMargin("15");
        }

    }
  };

  const handleSectionMarginOnBlur = (e, from) => {
    let value = e.target.value;
    
    setActiveSlide(1);
    // console.log(value.includes("."),value.toString(),"lll");
  //   if (value.includes(".") && value[value.length - 1] === ".") {
  //     value = value.slice(0, -1); 
  //  }
    if (!value) {
        if (from === "top") {
          setSectionTopMargin("7.5");
        } else if (from === "bottom") {
          setSectionBottomMargin("7.5");
        } else if (from === "right") {
          setSectionRightMargin("15");
        } else if (from === "left") {
          setSectionLeftMargin("15");
        }
    } 

  };

  const handleSectionMarginOnKeyDown = (e) => {
    if (e.key.toLowerCase() === "e" || e.key === "+" || e.key === "-") {
      e.preventDefault(); 
    }
  };


  const handleNotHitOnBottom = () => {};

  const calculateHeight = (ratio, width) => {
    let height = (width * 3) / 4;
    switch (ratio) {
      case "4:3":
        height = (width * 3) / 4;
        break;
      case "3:4":
        height = (width * 4) / 3;
        break;
      case "1:1":
        height = (width * 1) / 1;
        break;
      case "16:9":
        height = (width * 9) / 16;
        break;
      case "9:16":
        height = (width * 16) / 9;
        break;
    }
    return height;
  };

  const returnSelectedFilter = (val, indx) => {
    //  return filterSelectionArr && filterSelectionArr.map((row)=>{
    if (val === "Status") {
      return (
        <div className="post-filter-dropdown">
          <Dropdown
            isOpen={
              window.matchMedia("(max-width: 1200px)").matches
                ? false
                : openStatusFilter
            }
            onClick={
              window.matchMedia("(max-width: 1200px)").matches
                ? handleMobileStatusDropdown
                : null
            }
            toggle={(e) => handleOpenStatusFilter(e)}
          >
            <DropdownToggle caret>
              {statusFilterVal !== null && statusFilterVal !== undefined
                ? returnSelectedStatusLabel(statusFilterVal)
                : "Select"}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={(e) => handleStatusFilterChange(e, "")}
                active={statusFilterVal === ""}
              >
                All
              </DropdownItem>
              <DropdownItem
                onClick={(e) => handleStatusFilterChange(e, "draft")}
                active={statusFilterVal === "draft"}
              >
                Draft
              </DropdownItem>
              <DropdownItem
                onClick={(e) => handleStatusFilterChange(e, "publish")}
                active={statusFilterVal === "publish"}
              >
                Published
              </DropdownItem>
              <DropdownItem
                onClick={(e) => handleStatusFilterChange(e, "scheduled")}
                active={statusFilterVal === "scheduled"}
              >
                Scheduled
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      );
    } else if (val === "Categories") {
      return (
        <div className="post-filter-dropdown translate-fix">
          <div className="categogy-chip-section" id="calculate-ele-height">
            <Dropdown
              isOpen={
                window.matchMedia("(max-width: 1200px)").matches
                  ? false
                  : openCatFilter
              }
              onClick={
                window.matchMedia("(max-width: 1200px)").matches
                  ? handleMobileCatDropdown
                  : null
              }
              toggle={(e) => handleOpenCatFilter(e)}
            >
              <DropdownToggle caret>
                <span className="search-dropdown-before">
                  {catArrForPost.length === 0 ? (
                    "Select"
                  ) : (
                    <div onClick={(e) => handleOpenCatFilter(e)}>
                      {catArrForPost &&
                        catArrForPost.map((row, ind) => {
                          return (
                            <div>
                              <p>
                                {ind === 0 ? row.name : " , " + row.name}{" "}
                                {/* <span
                                         onClick={(e) =>
                                           handleDelCat(e, ind,row)
                                         }
                                       >
                                         <img  className="img-fluid" src={require("../../assets/images/dashboard/remove-tag.png").default}alt="Remove"/>
                                       </span> */}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </span>
              </DropdownToggle>

              <DropdownMenu id="test-height">
                {categoryData.length === 0 && (
                  <div className="no-app-dropdown">
                    <img
                      className="img-fluid"
                      src={
                        require("../../assets/images/dashboard/no-tag-available.png")
                          .default
                      }
                      alt="No App Found"
                    />
                  </div>
                )}
                {categoryData.length > 0 && (
                  <FormGroup>
                    <div className="dropdown-search">
                      <input
                        placeholder="Search"
                        type="text"
                        value={catSearchVal}
                        onChange={(e) => {
                          setCatSearchVal(e.target.value);
                        }}
                      ></input>
                      {catSearchVal.trim() && (
                        <p
                          onClick={(e) => {
                            setCatSearchVal("");
                          }}
                        >
                          Clear
                        </p>
                      )}
                    </div>
                  </FormGroup>
                )}
                {categoryData &&
                  categoryData
                    .filter((row) =>
                      row.name
                        .toLowerCase()
                        .includes(catSearchVal.toLowerCase())
                    )
                    .map((res, indx) => {
                      return (
                        <div className="category-created">
                          <label
                            className="category-list-container"
                            for={`category${indx}`}
                          >
                            <input
                              type="checkbox"
                              id={`category${indx}`}
                              name="category1"
                              onChange={(e) => handleAddCatDrop(e, res, indx)}
                              // value={res}
                              checked={catArrForPost.includes(res)}
                            />
                            <span className="category-checkmark"></span>
                            {res.level < 9 && handleDash(res.level)}
                            {res.name}
                          </label>
                        </div>
                      );
                    })}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      );
    } else if (val === "Tags") {
      return (
        <div className="post-filter-dropdown">
          <div className="categogy-chip-section" id="calculate-ele-height-tag">
            <Dropdown
              isOpen={
                window.matchMedia("(max-width: 1200px)").matches
                  ? false
                  : openTagFilter
              }
              onClick={
                window.matchMedia("(max-width: 1200px)").matches
                  ? handleMobileTagDropdown
                  : null
              }
              toggle={(e) => handleOpenTagFilter(e)}
            >
              <DropdownToggle caret>
                <span className="search-dropdown-before">
                  {tagArrForPost.length === 0 ? (
                    "Select"
                  ) : (
                    <div onClick={(e) => handleOpenTagFilter(e)}>
                      {tagArrForPost &&
                        tagArrForPost.map((row, ind) => {
                          return (
                            <div>
                              <p>
                                {ind === 0 ? row.name : " , " + row.name}{" "}
                                {/* <span
                        onClick={(e) =>
                          handleDelTag(e, ind,row)
                        }
                      >
                        <img  className="img-fluid" src={require("../../assets/images/dashboard/remove-tag.png").default}alt="Remove"/>
                      </span> */}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </span>
              </DropdownToggle>

              <DropdownMenu id="test-height-tag">
                {tagData.length === 0 && (
                  <div className="no-app-dropdown">
                    <img
                      className="img-fluid"
                      src={
                        require("../../assets/images/dashboard/no-tag-available.png")
                          .default
                      }
                      alt="No App Found"
                    />
                  </div>
                )}
                {tagData.length > 0 && (
                  <FormGroup>
                    <div className="dropdown-search">
                      <input
                        placeholder="Search"
                        type="text"
                        value={tagSearchVal}
                        onChange={(e) => {
                          setTagSearchVal(e.target.value);
                        }}
                      ></input>
                      {tagSearchVal.trim() && (
                        <p
                          onClick={(e) => {
                            setTagSearchVal("");
                          }}
                        >
                          Clear
                        </p>
                      )}
                    </div>
                  </FormGroup>
                )}
                {tagData &&
                  tagData
                    .filter((row) =>
                      row.name
                        .toLowerCase()
                        .includes(tagSearchVal.toLowerCase())
                    )
                    .map((res, indx) => {
                      return (
                        <div className="category-created">
                          <label
                            className="category-list-container"
                            for={`tag${indx}`}
                          >
                            <input
                              type="checkbox"
                              id={`tag${indx}`}
                              name="tag1"
                              onChange={(e) => handleAddTagDrop(e, res, indx)}
                              // value={res}
                              checked={tagArrForPost.includes(res)}
                            />
                            <span className="category-checkmark"></span>

                            {res.name}
                          </label>
                        </div>
                      );
                    })}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      );
    }
    // })
  };

  const handleFilterSubmit = () => {
    let adjustTagArr = "";
    let adjustCatArr = "";

    tagArrForPost &&
      tagArrForPost.map((row, indx) => {
        adjustTagArr =
          adjustTagArr +
          row._id +
          (indx !== tagArrForPost.length - 1 ? "," : "");
      });

    catArrForPost &&
      catArrForPost.map((row, indx) => {
        adjustCatArr =
          adjustCatArr +
          row._id +
          (indx !== catArrForPost.length - 1 ? "," : "");
      });

    let tagCheck = false;
    let catCheck = false;
    let statusCheck = false;

    filterSelectionArr &&
      filterSelectionArr.map((row) => {
        if (row.value === "Tags") {
          tagCheck = true;
        } else if (row.value === "Categories") {
          catCheck = true;
        } else if (row.value === "Status") {
          statusCheck = true;
        }
      });

    let finalFilterVal = "";
    finalFilterVal = `&tag_filter=${
      tagCheck ? adjustTagArr : ""
    }&category_filter=${catCheck ? adjustCatArr : ""}&status_filter=${
      statusCheck && statusFilterVal ? statusFilterVal : ""
    }`;
    setSpinner(true);
    setPostFilterShow(false);
    document.body.classList.remove("opec");
    setOpenMobPostFilter(false);
    if (adjustTagArr || adjustCatArr || statusFilterVal) {
      setRedDotOnFilter(true);
    }

    let limit = 20;
    let pageNo = 1;
    setPage(pageNo);
    setSelectedArr([]);

    cmsGetPostsApi(
      setPostData,
      btoa(localStorage.getItem("Appid")),
      setSpinner,
      finalFilterVal,
      limit,
      pageNo,
      postData,
      setSubData,
      setHitNextPagination,
      setPaginationSpinner,
      setPostDataCopy
    );
  };

  const handleAddField = () => {
    let newArr = [...filterSelectionArr];
    let obj = {
      index: filterSelectionArr.length,
      value: "",
    };
    newArr.push(obj);
    setFilterSelectionArr(newArr);
  };

  const handleResetFilter = () => {
    let newArr = [];
    setRedDotOnFilter(false);
    setTagArrForPost(newArr);
    setCatArrForPost(newArr);
    let selectionNewArr = [{ index: 0, value: "" }];
    setFilterSelectionArr(selectionNewArr);
    setStatusFilterVal(null);
    let finalFilterVal = "";
    finalFilterVal = `&tag_filter=&category_filter=&status_filter=`;
    setSpinner(true);
    setPostFilterShow(false);
    document.body.classList.remove("opec");
    setOpenMobPostFilter(false);

    let limit = 20;
    let pageNo = 1;
    setPage(pageNo);

    cmsGetPostsApi(
      setPostData,
      btoa(localStorage.getItem("Appid")),
      setSpinner,
      finalFilterVal,
      limit,
      pageNo,
      postData,
      setSubData,
      setHitNextPagination,
      setPaginationSpinner,
      setPostDataCopy
    );
  };
  const handleDeleteFilter = (val, key, from) => {
    if(val==="Categories"){
      setCatArrForPost([]);
    } 
    if(val==="Tags"){
      setTagArrForPost([]);
    }
    let newArr = [...filterSelectionArr];
    newArr.splice(key, 1);
    newArr &&
      newArr.map((row, indx) => {
        row.index = indx;
      });
    setFilterSelectionArr(newArr);
  };
  const handlePostFilterOutsideClick = () => {
    if (bulkUpdateFilter) return;
    if (window.matchMedia("(min-width: 1200px)").matches) {
      setPostFilterShow(false);
    }

    document.body.classList.remove("opec");
  };

  const handleUpdateFilterOutsideClick = (e) => {
    if (
      e.target?.className === "opec" ||
      (!openCatMultiSelectionPopUp &&
        !openTagMultiSelectionPopUp &&
        !openAtrMultiSelectionPopUp &&
        !bulkDeletePopup)
    ) {
      setBulkUpdateFilter(false);
      document.body.classList.remove("opec");
    }
  };

  const handleMobileCatDropdown = () => {
    setOpenMobCatFilter(!openMobCatFilter);
  };

  const handleMobileTagDropdown = () => {
    setOpenMobTagFilter(!openMobTagFilter);
  };

  const handleMobileStatusDropdown = () => {
    setOpenMobStatusFilter(!openMobStatusFilter);
  };

  const handleMobileMainDropdown = () => {
    setPostFilterShow(false);
  };

  const handleRightPanelExpand = (value) => {
    setAppRightPanelExpand(value);
  };

  const handleContainerOnBottom = () => {
    if (paginationSpinner === false && bottomSpinner === false) {
      if (hitNextPagination) {
        let limit = 20;
        let pageNo = page + 1;
        setPage(pageNo);
        setPaginationSpinner(true);
        cmsGetPostsApi(
          setPostData,
          btoa(localStorage.getItem("Appid")),
          setSpinner,
          null,
          limit,
          pageNo,
          postData,
          setSubData,
          setHitNextPagination,
          setPaginationSpinner,
          setPostDataCopy
        );
      }
    }
  };

  const handleSortTable = (key, type) => {
    type === "reset"
      ? setPostData(postDataCopy)
      : setPostData(
          [...postData].sort(
            (a, b) =>
              ((key === "author_info"
                ? a[key].name?.toLowerCase()
                : a[key]?.toLowerCase()) >
              (key === "author_info"
                ? b[key].name?.toLowerCase()
                : b[key]?.toLowerCase())
                ? 1
                : -1) * (type === "asc" ? 1 : -1)
          )
        );
  };

  const getTotalLength = (items) => {
    if (searchVal) {
      let totalLength = items.filter((elem) =>
        elem.title.toLowerCase().includes(searchVal.toLowerCase())
      ).length;
      return totalLength;
    } else {
      let totalLength = items.length;
      return totalLength;
    }
  };

  const handleSelected = (id) => {
    if (id === "all") {
      if (searchVal) {
        const filteredPosts = postData.filter((post) => {
          return post?.title?.toLowerCase().includes(searchVal.toLowerCase());
        });
        selectedArr.length === filteredPosts.length
          ? setSelectedArr([])
          : setSelectedArr(filteredPosts.map((elem) => elem._id));
      } else {
        selectedArr.length === postData.length
          ? setSelectedArr([])
          : setSelectedArr(postData.map((elem) => elem._id));
      }
    } else {
      const currArr = [...selectedArr];
      if (currArr.includes(id)) {
        setSelectedArr(currArr.filter((elem) => elem !== id));
      } else {
        setSelectedArr([...currArr, id]);
      }
    }
  };

  const handleOpenCatSelectionPopUp = (from) => {
    if (from === "cancel") {
      setCatSubData(oldCatSubData);
      setCatBreadcrumbArr(oldCatBreadcrumbArr);
      setTempCatSelection(catSelection);
    } else {
      if (from === "open") {
        if (!catSubData || catSubData.length === 0) {
          setCatSubData(categoryData);
        }
        setCatSelection(tempCatSelection);

        setOldCatSubData(catSubData);
        setOldCatBreadcrumbArr(catBreadcrumbArr);
        let newCatArrForPost = [];
        function recursNew(arr) {
          arr.map((eachCat) => {
            let obj = {
              name: "",
              _id: "",
            };
            for (let i = 0; i < tempCatSelection.length; i++) {
              if (eachCat._id === tempCatSelection[i]) {
                obj._id = eachCat._id;
                obj.name = eachCat.name;
                newCatArrForPost.push(obj);
                break;
              }
            }

            if (eachCat.child !== undefined && eachCat.child.length > 0) {
              // if parent has childrens
              recursNew(eachCat.child);
            }

            // return arr;
          });
        }

        recursNew(categoryData);

        if (tempCatSelection.length === newCatArrForPost.length) {
          setCatArrForPost(newCatArrForPost);
        }
      }
    }
    setOpenCatMultiSelectionPopUp(!openCatMultiSelectionPopUp);
  };

  const handleCatMultiSelectionChange = (e, pID, indx) => {
    // let newArr = JSON.parse(JSON.stringify(tempCatSelection));
    let newArr = [...tempCatSelection];

    if (!e.target.checked) {
      newArr = newArr && newArr.filter((res) => res !== pID);
    } else {
      newArr.push(pID);
    }

    setTempCatSelection(newArr);
  };

  const handleBottomScrollListnerForCat = () => {
    if (paginationSpinnerForCat === false) {
      if (hitNextPaginationForCat) {
        let limit = 99;
        let pageNo = pageForCat + 1;

        setPageForCat(pageNo);
        setPaginationSpinnerForCat(true);

        cmsgetCategoryApi(
          setCategoryData,
          btoa(localStorage.getItem("Appid")),
          setSpinner3,
          false,
          false,
          setCatCheckedArr,
          categoryData,
          false,
          limit,
          pageNo,
          categoryData,
          setHitNextPaginationForCat,
          setPaginationSpinnerForCat,
          setCatSubData
        );
      }
    }
  };

  const handleDelCat = (e, indx, row) => {
    let newArr = [...catArrForPost];
    newArr.splice(indx, 1);
    setCatArrForPost(newArr);

    let newCatSelection = [...tempCatSelection];
    newCatSelection = newCatSelection.filter((res) => res !== row._id);
    setTempCatSelection(newCatSelection);
    setCatSelection(newCatSelection);

    e.stopPropagation();
  };

  const handleOpenTagSelectionPopUp = (from) => {
    if (from === "cancel") {
      setTagSubData(oldTagSubData);
      setTagBreadcrumbArr(oldTagBreadcrumbArr);
      setTempTagSelection(tagSelection);
    } else {
      if (from === "open") {
        if (!tagSubData || tagSubData.length === 0) {
          setTagSubData(tagData);
        }
        setTagSelection(tempTagSelection);

        setOldTagSubData(tagSubData);
        setOldTagBreadcrumbArr(tagBreadcrumbArr);
        let newTagArrForPost = [];

        tagData &&
          tagData.map((eachTag) => {
            let obj = {
              name: "",
              _id: "",
            };
            for (let i = 0; i < tempTagSelection.length; i++) {
              if (eachTag._id === tempTagSelection[i]) {
                obj._id = eachTag._id;
                obj.name = eachTag.name;
                newTagArrForPost.push(obj);
                break;
              }
            }
          });

        if (tempTagSelection.length === newTagArrForPost.length) {
          setTagArrForPost(newTagArrForPost);
        }
      }
    }
    setOpenTagMultiSelectionPopUp(!openTagMultiSelectionPopUp);
  };

  const handleTagMultiSelectionChange = (e, pID, indx) => {
    // let newArr = JSON.parse(JSON.stringify(tempCatSelection));
    let newArr = [...tempTagSelection];

    if (!e.target.checked) {
      newArr = newArr && newArr.filter((res) => res !== pID);
    } else {
      newArr.push(pID);
    }

    setTempTagSelection(newArr);
  };

  const handleBottomScrollListnerForTag = () => {
    if (paginationSpinnerForTag === false) {
      if (hitNextPaginationForTag) {
        let limit = 99;
        let pageNo = pageForTag + 1;

        setPageForTag(pageNo);
        setPaginationSpinnerForTag(true);

        cmsgetTagsApi(
          setTagData,
          btoa(localStorage.getItem("Appid")),
          setSpinner3,
          false,
          setTagCheckedArr,
          tagData,
          limit,
          pageNo,
          tagData,
          setHitNextPaginationForTag,
          setPaginationSpinnerForTag,
          setTagSubData
        );
      }
    }
  };

  const handleDelTag = (e, indx, row) => {
    let newArr = [...tagArrForPost];
    newArr.splice(indx, 1);
    setTagArrForPost(newArr);

    let newTagSelection = [...tempTagSelection];
    newTagSelection = newTagSelection.filter((res) => res !== row._id);
    setTempTagSelection(newTagSelection);
    setTagSelection(newTagSelection);

    e.stopPropagation();
  };

  const handleOpenAtrSelectionPopUp = (from) => {
    if (from === "cancel") {
      setAtrSubData(oldAtrSubData);
      setAtrBreadcrumbArr(oldAtrBreadcrumbArr);
      setTempAtrSelection(atrSelection);
    } else {
      if (from === "open") {
        if (!atrSubData || atrSubData.length === 0) {
          setAtrSubData(atrListingData);
        }
        setAtrSelection(tempAtrSelection);

        setOldAtrSubData(atrSubData);
        setOldAtrBreadcrumbArr(atrBreadcrumbArr);

        let newArr1 = [...atrListingData];

        newArr1 &&
          newArr1.map((res1) => {
            res1.is_checked = false;
            res1.attribute_terms &&
              res1.attribute_terms.map((row1) => {
                row1.is_checked = false;
              });
          });
        for (let i = 0; i < tempAtrSelection.length; i++) {
          handleAddAttribute("", tempAtrSelection[i]);
          // handleAddAttributeVal("",tempAtrSelection[i]);
        }
      }
    }
    setOpenAtrMultiSelectionPopUp(!openAtrMultiSelectionPopUp);
  };

  const handleAtrMultiSelectionChange = (e, res1, indx) => {
    // let newArr = JSON.parse(JSON.stringify(tempCatSelection));
    let newArr = [...tempAtrSelection];

    if (!e.target.checked) {
      if (res1.attribute_icon_name !== undefined) {
        for (let i = 0; i < newArr.length; i++) {
          if (res1._id === newArr[i]) {
            newArr.splice(i, 1);
          }
        }
        res1.attribute_terms &&
          res1.attribute_terms.map((val1) => {
            for (let i = 0; i < newArr.length; i++) {
              if (val1._id === newArr[i]) {
                newArr.splice(i, 1);
              }
            }
          });
      } else {
        newArr = newArr && newArr.filter((res) => res !== res1._id);
      }
    } else {
      newArr.push(res1._id);
      if (res1.attribute) {
        newArr.push(res1.attribute);
      }
    }

    setTempAtrSelection(newArr);
  };

  const handleAddAttribute = (e, atrId) => {
    let newArr = [...atrListingData];

    newArr &&
      newArr.map((res) => {
        if (res._id === atrId) {
          res.is_checked = true;
        }
        res.attribute_terms &&
          res.attribute_terms.map((val) => {
            if (val._id === atrId) {
              val.is_checked = true;
              res.is_checked = true;
            }
          });
      });
    setAtrListingData(newArr);
  };

  const handleBottomScrollListnerForAtr = () => {
    if (paginationSpinnerForAtr === false) {
      if (hitNextPaginationForAtr) {
        let limit = 99;
        let pageNo = pageForAtr + 1;

        setPageForAtr(pageNo);
        setPaginationSpinnerForAtr(true);

        cmsGetAtrApi(
          setDuumySpin1,
          setAtrListingData,
          setDuumySpin2,
          "post-list",
          atrSelection,
          false,
          limit,
          pageNo,
          atrListingData,
          setHitNextPaginationForAtr,
          setPaginationSpinnerForAtr,
          setAtrSubData
        );
      }
    }
  };

  const isAtrAssign = () => {
    let isAssign = false;
    for (let i = 0; i < atrListingData.length; i++) {
      if (atrListingData[i].is_checked === true) {
        isAssign = true;
        break;
      }
      //    atrListingData[i].attribute_terms && atrListingData[i].attribute_terms.map((val)=>{
      //     if(val.is_checked===true){
      //       isAssign=true;
      //     }

      //  })
      //  break;
    }

    return isAssign;
  };

  const countNoOfValAtr = (atrId) => {
    let no = 0;
    for (let i = 0; i < atrListingData.length; i++) {
      if (atrListingData[i]._id === atrId) {
        atrListingData[i].attribute_terms &&
          atrListingData[i].attribute_terms.map((val) => {
            if (val.is_checked === true) {
              no++;
            }
          });
        break;
      }
    }

    return no;
  };

  const handleDelAttribute = (e, atrId) => {
    let newArr = [...atrListingData];
    let newAtrSelection = [];

    newArr &&
      newArr.map((res) => {
        if (res._id === atrId) {
          res.is_checked =
            res.is_checked === undefined || res.is_checked === null
              ? true
              : !res.is_checked;

          res.is_checked === false &&
            res.attribute_terms &&
            res.attribute_terms.map((val) => {
              val.is_checked = false;
            });
        }
      });

    newArr &&
      newArr.map((res1) => {
        if (res1.is_checked) {
          newAtrSelection.push(res1._id);
        }

        res1.attribute_terms &&
          res1.attribute_terms.map((val) => {
            if (val.is_checked) {
              newAtrSelection.push(val._id);
            }
          });
      });
    setAtrSelection(newAtrSelection);
    setTempAtrSelection(newAtrSelection);
    setAtrListingData(newArr);
    e.stopPropagation();
  };

  const getSelectedAttributes = () => {
    return atrListingData.reduce((acc, elem) => {
      if (atrSelection.includes(elem._id)) {
        acc.push({
          id: elem._id,
          attribute_icon_name: elem.attribute_icon_name,
          identifier: elem.attribute_name,
          label: elem.attribute_name,
          terms: elem.attribute_terms
            .filter((data) => data.is_checked)
            .map((term) => ({
              id: term._id,
              identifier: term.attribute_term_name,
              label: term.attribute_term_name,
            })),
        });
      }
      return acc;
    }, []);
  };

  const handleBulkUpdateSubmit = async (fromDelete) => {
    if (bulkUpdateData.status?.value === "scheduled" && !dateTimeValue) {
      errortoast("Please choose date and time");
      return;
    }
    fromDelete ? setBulkDeleteLoader(true) : setBulkUpdateLoader(true);
    const apiResponse = await bulkPostUpdate({
      app_id: parseInt(localStorage.getItem("Appid")),
      post_ids: selectedArr,
      author_user_id: bulkUpdateData.author_user_id?.value || "",
      categories: catSelection,
      tags: tagSelection,
      attributes_data: getSelectedAttributes(),
      status: bulkUpdateData.status?.value || "",
      is_featured: bulkUpdateData.is_featured
        ? bulkUpdateData.is_featured.value
        : null,
      is_deleted: fromDelete ? 1 : 0,
      published_at:
        bulkUpdateData.status?.value === "scheduled" ? dateTimeValue : "",
    });
    if (apiResponse?.data?.code === 200) {
      sucesstoast(
        `${selectedArr.length > 1 ? "Posts" : "Post"} ${
          fromDelete ? "deleted" : "updated"
        } successfully`
      );
      setSpinner(true);
      handleDrawerClose(true);
      let limit = 20;
      let pageNo = 1;
      setPage(pageNo);
      cmsGetPostsApi(
        setPostData,
        btoa(localStorage.getItem("Appid")),
        setSpinner,
        categoryFilter,
        limit,
        pageNo,
        postData,
        setSubData,
        setHitNextPagination,
        setPaginationSpinner,
        setPostDataCopy
      );
    } else {
      
      errortoast(apiResponse.data.status);
    }
    fromDelete ? setBulkDeleteLoader(false) : setBulkUpdateLoader(false);
    fromDelete && setBulkDeletePopup(false);
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  return (
    <>
      {/* {recurs(categoryData, "parent")} */}
      <div className="d-md-block">
        <Header
          customclass={"header-responsive-new"}
          postfiltershow={postfiltershow}
          handleMobileMainDropdown={handleMobileMainDropdown}
        />
      </div>

      {/* Mobile View */}
      {/* Mobile View End*/}
      <section className="dasboard_page mobile-display  mt-0 cms-module">
        <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
        <BottomScrollListener
          onBottom={
            window.matchMedia("(max-width: 1200px)").matches
              ? handleContainerOnBottom
              : handleNotHitOnBottom
          }
          offset={100}
        >
          {(scrollRef) => (
            <div
              style={{
                height: "calc(100vh - 60px)",
                overflowY: "auto",
              }}
              ref={scrollRef}
              className={`right-panel-gap ${
                localStorage.getItem("appSideBarCollapse") !== undefined &&
                localStorage.getItem("appSideBarCollapse") !== null &&
                localStorage.getItem("appSideBarCollapse") === "true"
                  ? " right-panel-gap-expand"
                  : ""
              }`}
            >
              {/* <div className="transparent_background"> */}

              {/* Bulk Update filter */}
              <OutsideClickHandler
                onOutsideClick={(e) => handleUpdateFilterOutsideClick(e)}
              >
                <div
                  className={`${
                    bulkUpdateFilter ? "show-bulkUpdate-filte" : ""
                  } bulkUpdate-filter`}
                >
                  <h2 className="bulkUpdate-filter-head d-flex justify-content-between">
                    <span>
                      Bulk update{" "}
                      <Tooltip
                        message={"Apply changes to multiple posts at once."}
                      />
                    </span>
                    <span
                      className="bulk-update-Reset"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDrawerClose()}
                    >
                      {/* <img src={require('../../assets/images/dashboard/cross.png').default} alt="" /> */}
                      Reset{" "}
                      <Tooltip
                        message={"Clear all selections and start over."}
                      />
                    </span>
                  </h2>
                  <div className="bulkUpdate-filter-body-parent">
                    <div className="bulkupdate-filter-selectdiv">
                      <h3>
                        Author
                        <Tooltip
                          message={"Assign an author to the selected posts."}
                        />
                      </h3>
                      <Select
                        options={mergeUserOwnerData.map((e) => ({
                          label: e.name,
                          value: e.user_id,
                        }))}
                        classNamePrefix="post-filter"
                        onChange={(e) =>
                          setBulkUpdateData({ author_user_id: e })
                        }
                        placeholder="Select"
                        isSearchable={false}
                        value={bulkUpdateData.author_user_id}
                        formatOptionLabel={(option) => {
                          const res = mergeUserOwnerData?.find(
                            (user) => user.name === option.label
                          );
                          return (
                            <div className="cms-author">
                              <div>
                                {res.profile_image &&
                                res.profile_image.indexOf("https") > -1 ? (
                                  <img
                                    className="img-fluid"
                                    src={res.profile_image}
                                    alt="profile-img"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = imagebackground4;
                                    }}
                                  />
                                ) : (
                                  <img
                                    className="img-fluid"
                                    src={
                                      process.env.REACT_APP_Image_Path +
                                      res.user_id +
                                      process.env.REACT_APP_Profile +
                                      res.profile_image
                                    }
                                    alt="profile-img"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = imagebackground4;
                                    }}
                                  />
                                )}
                              </div>

                              <p>{res.name}</p>
                            </div>
                          );
                        }}
                      />
                    </div>

                    <h3>
                      Assign categories
                      <Tooltip
                        message={
                          " Add and assign categories to classify posts broadly."
                        }
                      />
                    </h3>
                    <div
                      onClick={() => handleOpenCatSelectionPopUp("open")}
                      className="bulkUpdate-filter-body-select"
                    >
                      {/* <p>All</p> */}
                      <span
                        style={{
                          color: catArrForPost.length === 0 ? "#BCCBCE" : "",
                        }}
                        className="search-dropdown-before"
                      >
                        {catArrForPost.length === 0 ? (
                          "Assign categories to your post."
                        ) : (
                          <div>
                            {catArrForPost &&
                              catArrForPost.map((row, ind) => {
                                return (
                                  <div>
                                    <p>{row.name} </p>
                                    <span
                                      onClick={(e) => handleDelCat(e, ind, row)}
                                    >
                                      <img
                                        className="img-fluid"
                                        src={
                                          require("../../assets/images/dashboard/remove-tag.png")
                                            .default
                                        }
                                        alt="Remove"
                                      />
                                    </span>
                                  </div>
                                );
                              })}
                          </div>
                        )}
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                      >
                        <g
                          id="Group_42683"
                          data-name="Group 42683"
                          transform="translate(-1005 -1029)"
                        >
                          <g
                            id="Rectangle_31915"
                            data-name="Rectangle 31915"
                            transform="translate(1005 1029)"
                            fill="#fff"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          >
                            <rect width="20" height="19" rx="2" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="19"
                              height="18"
                              rx="1.5"
                              fill="none"
                            />
                          </g>
                          <path
                            id="Path_119031"
                            data-name="Path 119031"
                            d="M15578.713-3593.554h2"
                            transform="translate(-14569.713 4628.054)"
                            fill="none"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          />
                          <path
                            id="Path_119032"
                            data-name="Path 119032"
                            d="M15578.713-3593.554h2"
                            transform="translate(-14569.713 4632.054)"
                            fill="none"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          />
                          <path
                            id="Path_119033"
                            data-name="Path 119033"
                            d="M15578.713-3593.554h2"
                            transform="translate(-14569.713 4636.054)"
                            fill="none"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          />
                          <path
                            id="Path_119034"
                            data-name="Path 119034"
                            d="M15578.711-3593.554h8"
                            transform="translate(-14565.713 4628.054)"
                            fill="none"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          />
                          <path
                            id="Path_119035"
                            data-name="Path 119035"
                            d="M15578.711-3593.554h8"
                            transform="translate(-14565.713 4632.054)"
                            fill="none"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          />
                          <path
                            id="Path_119036"
                            data-name="Path 119036"
                            d="M15578.711-3593.554h8"
                            transform="translate(-14565.713 4636.054)"
                            fill="none"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          />
                        </g>
                      </svg>
                    </div>

                    <h3>
                      Assign tags
                      <Tooltip
                        message={
                          " Add and assign tags to describe the context within your post."
                        }
                      />
                    </h3>
                    <div
                      onClick={() => handleOpenTagSelectionPopUp("open")}
                      className="bulkUpdate-filter-body-select"
                    >
                      {/* <p>All</p> */}
                      <span
                        className="search-dropdown-before"
                        style={{
                          color: tagArrForPost.length === 0 ? "#BCCBCE" : "",
                        }}
                      >
                        {tagArrForPost.length === 0 ? (
                          "Assign tags to your post."
                        ) : (
                          <div>
                            {tagArrForPost &&
                              tagArrForPost.map((row, ind) => {
                                return (
                                  <div>
                                    <p>{row.name} </p>
                                    <span
                                      onClick={(e) => handleDelTag(e, ind, row)}
                                    >
                                      <img
                                        className="img-fluid"
                                        src={
                                          require("../../assets/images/dashboard/remove-tag.png")
                                            .default
                                        }
                                        alt="Remove"
                                      />
                                    </span>
                                  </div>
                                );
                              })}
                          </div>
                        )}
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                      >
                        <g
                          id="Group_42683"
                          data-name="Group 42683"
                          transform="translate(-1005 -1029)"
                        >
                          <g
                            id="Rectangle_31915"
                            data-name="Rectangle 31915"
                            transform="translate(1005 1029)"
                            fill="#fff"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          >
                            <rect width="20" height="19" rx="2" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="19"
                              height="18"
                              rx="1.5"
                              fill="none"
                            />
                          </g>
                          <path
                            id="Path_119031"
                            data-name="Path 119031"
                            d="M15578.713-3593.554h2"
                            transform="translate(-14569.713 4628.054)"
                            fill="none"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          />
                          <path
                            id="Path_119032"
                            data-name="Path 119032"
                            d="M15578.713-3593.554h2"
                            transform="translate(-14569.713 4632.054)"
                            fill="none"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          />
                          <path
                            id="Path_119033"
                            data-name="Path 119033"
                            d="M15578.713-3593.554h2"
                            transform="translate(-14569.713 4636.054)"
                            fill="none"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          />
                          <path
                            id="Path_119034"
                            data-name="Path 119034"
                            d="M15578.711-3593.554h8"
                            transform="translate(-14565.713 4628.054)"
                            fill="none"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          />
                          <path
                            id="Path_119035"
                            data-name="Path 119035"
                            d="M15578.711-3593.554h8"
                            transform="translate(-14565.713 4632.054)"
                            fill="none"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          />
                          <path
                            id="Path_119036"
                            data-name="Path 119036"
                            d="M15578.711-3593.554h8"
                            transform="translate(-14565.713 4636.054)"
                            fill="none"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          />
                        </g>
                      </svg>
                    </div>

                    <h3>
                      Assign attributes
                      <Tooltip
                        message={
                          "Add and assign attributes for filtering posts effectively."
                        }
                      />
                    </h3>
                    <div
                      onClick={() => handleOpenAtrSelectionPopUp("open")}
                      className="bulkUpdate-filter-body-select"
                    >
                      {/* <p>All</p> */}
                      <span
                        className="search-dropdown-before"
                        style={{
                          color: isAtrAssign() === false ? "#BCCBCE" : "",
                        }}
                      >
                        {isAtrAssign() === false ? (
                          "Assign attributes to your post."
                        ) : (
                          <div
                          // onClick={(e) => setOpenAttributeDropdown(!openAttributeDropdown)}
                          >
                            {atrListingData &&
                              atrListingData.map((row, ind) => {
                                return (
                                  row.is_checked === true && (
                                    <div>
                                      <p>
                                        {row.attribute_name}{" "}
                                        {` ( ${countNoOfValAtr(row._id)} )`}
                                      </p>
                                      <span
                                        onClick={(e) =>
                                          handleDelAttribute(e, row._id)
                                        }
                                      >
                                        <img
                                          className="img-fluid"
                                          src={
                                            require("../../assets/images/dashboard/remove-tag.png")
                                              .default
                                          }
                                          alt="Remove"
                                        />
                                      </span>
                                    </div>
                                  )
                                );
                              })}
                          </div>
                        )}
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                      >
                        <g
                          id="Group_42683"
                          data-name="Group 42683"
                          transform="translate(-1005 -1029)"
                        >
                          <g
                            id="Rectangle_31915"
                            data-name="Rectangle 31915"
                            transform="translate(1005 1029)"
                            fill="#fff"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          >
                            <rect width="20" height="19" rx="2" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="19"
                              height="18"
                              rx="1.5"
                              fill="none"
                            />
                          </g>
                          <path
                            id="Path_119031"
                            data-name="Path 119031"
                            d="M15578.713-3593.554h2"
                            transform="translate(-14569.713 4628.054)"
                            fill="none"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          />
                          <path
                            id="Path_119032"
                            data-name="Path 119032"
                            d="M15578.713-3593.554h2"
                            transform="translate(-14569.713 4632.054)"
                            fill="none"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          />
                          <path
                            id="Path_119033"
                            data-name="Path 119033"
                            d="M15578.713-3593.554h2"
                            transform="translate(-14569.713 4636.054)"
                            fill="none"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          />
                          <path
                            id="Path_119034"
                            data-name="Path 119034"
                            d="M15578.711-3593.554h8"
                            transform="translate(-14565.713 4628.054)"
                            fill="none"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          />
                          <path
                            id="Path_119035"
                            data-name="Path 119035"
                            d="M15578.711-3593.554h8"
                            transform="translate(-14565.713 4632.054)"
                            fill="none"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          />
                          <path
                            id="Path_119036"
                            data-name="Path 119036"
                            d="M15578.711-3593.554h8"
                            transform="translate(-14565.713 4636.054)"
                            fill="none"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          />
                        </g>
                      </svg>
                    </div>

                    <div className="bulkupdate-filter-selectdiv">
                      <h3>
                        Status
                        <Tooltip
                          message={
                            "Set the publication status for the selected posts."
                          }
                        />
                      </h3>
                      <Select
                        options={[
                          { label: "Draft", value: "draft" },
                          { label: "Published", value: "publish" },
                          { label: "Scheduled", value: "scheduled" },
                        ]}
                        classNamePrefix="post-filter"
                        placeholder="Select"
                        onChange={(e) => setBulkUpdateData({ status: e })}
                        isSearchable={false}
                        value={bulkUpdateData.status}
                      />
                    </div>
                    {bulkUpdateData.status?.value === "scheduled" && (
                      <div
                        className="bulkUpdate-filter-body-datepicker"
                        onClick={() => setShowDatePicker(!showDatePicker)}
                      >
                        {/* <img
                   onClick={() =>
                    setShowDatePicker(!showDatePicker)
                  }
                    src={
                      require("../../assets/images/dashboard/cms/calendar.png")
                        .default
                    }
                  /> */}{" "}
                        <DatePicker
                          className="date-input-post2"
                          selected={dateTimeValue}
                          onChange={(date) => setDateTimeValue(date)}
                          filterTime={filterPassedTime}
                          showTimeSelect
                          dateFormat="d MMMM yyyy, HH:mm"
                          timeIntervals={15}
                          minDate={new Date()}
                          maxDate={addDays(new Date(), 365)}
                        />
                      </div>
                    )}

                    {/* <div className="Features-div">
              <h3>Featured</h3>
              <label  class="switch" for="isFeatured">
                                <input
                                  type="checkbox"
                                  id="isFeatured"
                                  checked={bulkUpdateData.is_featured}
                                />
                                <div onClick={() => setBulkUpdateData({is_featured: bulkUpdateData.is_featured ? 0 : 1})} class="slider round"></div>
                              </label>
            </div> */}
                    <div className="bulkupdate-filter-selectdiv">
                      <h3>
                        Featured
                        <Tooltip
                          message={
                            ' Select "Featured" to highlight multiple posts or “Unfeatured” to remove their highlighted status.'
                          }
                        />
                      </h3>
                      <Select
                        options={[
                          { label: "Featured", value: true },
                          { label: "Unfeatured", value: false },
                        ]}
                        classNamePrefix="post-filter"
                        placeholder="Select"
                        onChange={(e) => setBulkUpdateData({ is_featured: e })}
                        isSearchable={false}
                        value={bulkUpdateData.is_featured}
                      />
                    </div>

                    {bulkUpdateLoader ? (
                      <button className="d-flex justify-content-center align-items-center bulkupdate-filter-apply-enable">
                        <div className="new-loader mob-done-loader"></div>
                      </button>
                    ) : (
                      <button
                        className="bulkupdate-filter-apply-enable"
                        disabled={
                          (!catSelection.length &&
                            !tagSelection.length &&
                            !atrSelection.length &&
                            !bulkUpdateData.author_user_id &&
                            !bulkUpdateData.status &&
                            !bulkUpdateData.is_featured) ||
                          bulkDeleteLoader
                        }
                        onClick={() => handleBulkUpdateSubmit()}
                      >
                        Apply
                      </button>
                    )}

                    {/* {bulkDeleteLoader ? 
              <button className="d-flex justify-content-center align-items-center bulkupdate-filter-delete-enable">
              <div className="new-loader mob-done-loader" style={{
                border: '2px solid #3064F9',
                borderTop: '2px solid transparent'
              }}></div>
            </button>
              : */}
                    <button
                      className="bulkupdate-filter-delete-enable "
                      disabled={bulkUpdateLoader}
                      onClick={() => handleBulkDeletePopup()}
                    >
                      <DeleteIcon
                        color={bulkUpdateLoader ? "#bcc1ce" : "#3064F9"}
                      />
                      Delete selected
                    </button>
                    {/* } */}
                  </div>
                </div>
              </OutsideClickHandler>
              {/* Bulk Update filter end */}
              <OutsideClickHandler
                onOutsideClick={(e) => handlePostFilterOutsideClick(e)}
              >
                <div
                  className={
                    postfiltershow
                      ? "post-filter show-post-filter"
                      : "post-filter"
                  }
                >
                  <div className="post-filter-height">
                    <div className="post-filter-head">
                      <h1>Filter</h1>
                      <p onClick={(e) => handleResetFilter(e)}>Reset</p>
                    </div>

                    <div className="post-filter-body">
                      {filterSelectionArr &&
                        filterSelectionArr.map((row, indx) => {
                          return (
                            <div>
                              {indx > 0 && <h6>and</h6>}
                              <div className="post-filter-dropdown">
                                {handleReturnMainFilter(row.value, indx)}
                                <ParentFilterModal
                                  key={indx}
                                  handleMainFilterChange={
                                    handleMainFilterChange
                                  }
                                  handleOpenParentDropdown={
                                    handleOpenParentDropdown
                                  }
                                  openMobParentFilter={
                                    indx === 0
                                      ? openMobParentFilter1
                                      : indx === 1
                                      ? openMobParentFilter2
                                      : openMobParentFilter3
                                  }
                                  optionForMainFilter={optionForMainFilter}
                                  filterSelectionArr={filterSelectionArr}
                                  index={indx}
                                  value={row.value}
                                />
                              </div>
                              <div className="delete-post-filter">
                                {returnSelectedFilter(row.value, indx)}
                                {row.value &&
                                  returnDeleteButton(row.value, indx)}
                              </div>
                            </div>
                          );
                        })}
                      <div className="post-filters-buttons-div">
                        {filterSelectionArr.length < 3 && (
                          <button
                          className="post-filters-buttons"
                            onClick={(e) => handleAddField(e)}
                            style={{
                              backgroundColor: localStorage.getItem(
                                "button_background_color"
                              ),
                              border:
                                "1px solid " +
                                localStorage.getItem("button_background_color"),
                              color: localStorage.getItem("button_text_color"),
                              "--custom_color5":
                                localStorage.getItem("agencyid") == 1
                                  ? "#134bec"
                                  : localStorage.getItem(
                                      "button_hover_bgcolor"
                                    ),
                              "--custom_color4":
                                localStorage.getItem("agencyid") == 1
                                  ? "white"
                                  : localStorage.getItem(
                                      "button_hover_txtcolor"
                                    ),
                              "--custom_color6":
                                localStorage.getItem("agencyid") == 1
                                  ? "#3064f9"
                                  : localStorage.getItem(
                                      "button_hover_bgcolor"
                                    ),
                            }}
                          >
                            + Add field
                          </button>
                        )}
                        <button
                          onClick={(e) => handleFilterSubmit(e)}
                          className={isCatOrTagSelected || filterDisabled|| (filterSelectionArr.length == 1 && filterSelectionArr[0].value == "")?"filter-buttondisabled":"post-filters-buttons"}
                          style={{
                            backgroundColor: !(isCatOrTagSelected || filterDisabled|| (filterSelectionArr.length == 1 && filterSelectionArr[0].value == "")) ? localStorage.getItem(
                              "button_background_color" 
                            ): "",
                            border: !(isCatOrTagSelected || filterDisabled|| (filterSelectionArr.length == 1 && filterSelectionArr[0].value == "")) ?
                              "1px solid " +
                              localStorage.getItem("button_background_color"): "none",
                            color: !(isCatOrTagSelected || filterDisabled|| (filterSelectionArr.length == 1 && filterSelectionArr[0].value == ""))  ? localStorage.getItem("button_text_color"): 'white',
                            "--custom_color5":
                              localStorage.getItem("agencyid") == 1
                                ? "#134bec"
                                : localStorage.getItem("button_hover_bgcolor"),
                            "--custom_color4":
                              localStorage.getItem("agencyid") == 1
                                ? "white"
                                : localStorage.getItem("button_hover_txtcolor"),
                            "--custom_color6":
                              localStorage.getItem("agencyid") == 1
                                ? "#3064f9"
                                : localStorage.getItem("button_hover_bgcolor"),
                          }}
                          disabled={isCatOrTagSelected || filterDisabled|| (filterSelectionArr.length == 1 && filterSelectionArr[0].value == "")}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <Modal className="protip-popup-message" isOpen={window.matchMedia("(max-width: 1200px)").matches?openMobPostFilter:false} centered>
          
          <ModalBody>
            <div className="mobileglobalsearch">
              <button onClick={handleMobPostFilter}><svg xmlns="http://www.w3.org/2000/svg" width="20.759" height="15.408" viewBox="0 0 20.759 15.408">
            <g id="Group_27465" data-name="Group 27465" transform="translate(-19.742 -22.295)">
              <path id="Path_81245" data-name="Path 81245" d="M-13783.922-19217h19.346" transform="translate(13805.077 19247)" fill="none" stroke="#7782a1" stroke-width="2"/>
              <path id="Path_82232" data-name="Path 82232" d="M14053.656,19255.426l-7,7,7,7" transform="translate(-14025.504 -19232.424)" fill="none" stroke="#7782a1" stroke-width="2"/>
            </g>
          </svg></button>
          
          </div>
          <div className={ "post-filter"} style={{position:"unset"}}>
            <div className="post-filter-height">
              <div className="post-filter-head">
                <h1>Filter</h1>
                <p onClick={(e)=>handleResetFilter(e)}>Reset</p>
              </div>

              <div className="post-filter-body">

                      {
                        filterSelectionArr && filterSelectionArr.map((row,indx)=>{
                          return(
                            <div>
                            {indx > 0 &&<h6>and</h6>}
                            <div className="post-filter-dropdown">
                            {handleReturnMainFilter(row.value,indx)}
                           <ParentFilterModal
                              key={indx}
                              handleMainFilterChange={handleMainFilterChange}
                              handleOpenParentDropdown={handleOpenParentDropdown}
                              openMobParentFilter ={indx===0 ? openMobParentFilter1 : indx===1 ? openMobParentFilter2 : openMobParentFilter3}
                              optionForMainFilter ={optionForMainFilter}
                              filterSelectionArr={filterSelectionArr}
                              index={indx}
                              value={row.value}
                            />
                          
                            </div>
                            <div className="delete-post-filter">
                            {returnSelectedFilter(row.value,indx)}
                            {row.value && returnDeleteButton(row.value,indx) }
                             </div>
                          
                          </div>
                          )
                        })
                      }
            

                



            <div className="post-filters-buttons">
                


                {
                  filterSelectionArr.length < 3 && 
                
                <button onClick={(e)=>handleAddField(e)}>+ Add field</button>
         }
               <button onClick={(e)=>handleFilterSubmit(e)}>Search</button>
              </div>

</div>
            </div>
          </div>
         
          </ModalBody>
      </Modal> */}
                </div>
                {/* Mobile view Main filter dropdown */}
              </OutsideClickHandler>

              <div
                className="d-flex align-items-center"
                style={{
                  justifyContent: "space-between",
                }}
              >
                <div className="post-heading">
                  <h1>
                    Posts{" "}
                    <p class="cms-posts-tooltip">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                      >
                        <g
                          id="Group_35001"
                          data-name="Group 35001"
                          transform="translate(-1727 -19)"
                        >
                          <g
                            id="Ellipse_7706"
                            data-name="Ellipse 7706"
                            transform="translate(1727 19)"
                            fill="none"
                            stroke="#bcc1ce"
                            stroke-width="1.5"
                          >
                            <circle cx="9" cy="9" r="9" stroke="none"></circle>
                            <circle cx="9" cy="9" r="8.25" fill="none"></circle>
                          </g>
                          <line
                            id="Line_343"
                            data-name="Line 343"
                            y1="2"
                            transform="translate(1736 23)"
                            fill="none"
                            stroke="#bcc1ce"
                            stroke-miterlimit="10"
                            stroke-width="1.5"
                          ></line>
                          <path
                            id="Path_98224"
                            data-name="Path 98224"
                            d="M0,2V-4"
                            transform="translate(1736 31)"
                            fill="none"
                            stroke="#bcc1ce"
                            stroke-width="1.5"
                          ></path>
                        </g>
                      </svg>
                      <p class="cms-posts-tooltipsubheader">
                        A post is a time stamped article organized through
                        categories and tags. Most recent posts will appear
                        first.
                      </p>
                    </p>
                  </h1>
                  {/* <div style={{display:"flex"}}>
                    {
                      !postListSetting &&
                      
                    <Link
                    className="d-flex d-xl-none align-items-center"
                    to="/app/post/create"
                    >
                    <img className="mr-2" src={ require("../../assets/images/dashboard/plus.png") .default } />{" "}
                    Create
                              </Link>
                        }
                 
                    </div> */}
                    <div className="mob-cms-view-setting-home-btn">
                    {parseInt(localStorage.getItem("website_technology")) ===
                      3 && (
                      <>
                        {postListSetting === false ? (
                          //     <div className="consent-setting-clicked postlist-setting-clicked" style={{marginLeft: "20px",marginRight:"0px",cursor:"default"}}>
                          //             <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                          //        <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                          //     <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                          //     <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="#7782a1" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                          //   </g>
                          // </svg>
                          //             </div>
                          <></>
                        ) : (
                          <div
                            className="notification-setting postlist-setting-clicked"
                            style={{ marginLeft: "20px", marginRight: "0px" }}
                            onClick={() => {
                              handlePostListSettingBtn();
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="26"
                              height="26"
                              viewBox="0 0 26 26"
                            >
                              <g
                                id="Group_42975"
                                data-name="Group 42975"
                                transform="translate(-20 -527)"
                              >
                                <rect
                                  id="Rectangle_25572"
                                  data-name="Rectangle 25572"
                                  width="26"
                                  height="26"
                                  transform="translate(20 527)"
                                  fill="none"
                                />
                                <path
                                  id="Path_119115"
                                  data-name="Path 119115"
                                  d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z"
                                  transform="translate(22.5 529.36)"
                                  fill="none"
                                  stroke="#7782a1"
                                  stroke-miterlimit="10"
                                  stroke-width="1.5"
                                />
                              </g>
                            </svg>
                          </div>
                        )}
                      </>
                    )}

                        
                          <div
                          onClick={() => {
                            handlePostListSettingBtn();
                          }}
                          className="cms-setting-btn"
                        >
                          <SettingIcon
                           
                           isActive={postListSetting}
                          />
                        </div>
                        </div>
                </div>
                {parseInt(localStorage.getItem("website_technology")) !== 3 &&
                  postListSetting &&
                  !window.matchMedia("(max-width: 1200px)").matches && (
                    <div className="createpage otherpostmargin">
                      <button
                        className="createpage"
                        onClick={(e) => handleSubmitPostSettingsData(e)}
                        style={{
                          backgroundColor: localStorage.getItem(
                            "button_background_color"
                          ),
                          border:
                            "1px solid " +
                            localStorage.getItem("button_background_color"),
                          color: localStorage.getItem("button_text_color"),
                          "--custom_color5":
                            localStorage.getItem("agencyid") == 1
                              ? "#134bec"
                              : localStorage.getItem("button_hover_bgcolor"),
                          "--custom_color4":
                            localStorage.getItem("agencyid") == 1
                              ? "white"
                              : localStorage.getItem("button_hover_txtcolor"),
                          "--custom_color6":
                            localStorage.getItem("agencyid") == 1
                              ? "#3064f9"
                              : localStorage.getItem("button_hover_bgcolor"),
                        }}
                      >
                        <div className={settingSaveBtnLoader}>
                          <div className="new-loader"></div>
                        </div>
                        <div>{settingSaveBtnVal}</div>
                      </button>
                    </div>
                  )}
              </div>

              {!postListSetting && (
                <div className="notification-mob-create-btn postCreate-mob-btn">
                  <Link
                    to="/app/post/create"
                    className=" update-mob-upgadelink"
                  >
                    <img
                      src={
                        require("../../assets/images/dashboard/plus.png")
                          .default
                      }
                      className="img-fluid mr-2 symbolspace"
                      alt="plus"
                    />
                    Create
                  </Link>
                </div>
              )}
              {parseInt(localStorage.getItem("website_technology")) === 3 && (
                <div
                  className="pageshead"
                  style={{
                    padding:
                      postListSetting &&
                      window.matchMedia("(max-width: 1200px)").matches
                        ? "0px 15px"
                        : "",
                  }}
                >
                  <div className="post-sublinks">
                    <Link
                      className="active"
                      to="/app/posts"
                      style={{
                        "border-bottom":
                          localStorage.getItem("agencyid") == 1
                            ? "2px solid #3064f9"
                            : "2px solid " +
                              localStorage.getItem("agency_primary_color"),
                        color:
                          localStorage.getItem("agencyid") == 1
                            ? "#3064f9"
                            : localStorage.getItem("agency_primary_color"),
                        "--custom_color4":
                          localStorage.getItem("agencyid") == 1
                            ? "#3064f9"
                            : localStorage.getItem("agency_primary_color"),
                        "--custom_color5":
                          localStorage.getItem("agencyid") == 1
                            ? "#3064f9"
                            : localStorage.getItem("agency_primary_color"),
                      }}
                    >
                      Posts
                    </Link>
                    <Link
                      to="/app/post-categories"
                      style={{
                        "--custom_color4":
                          localStorage.getItem("agencyid") == 1
                            ? "#3064f9"
                            : localStorage.getItem("agency_primary_color"),
                        "--custom_color5":
                          localStorage.getItem("agencyid") == 1
                            ? "#3064f9"
                            : localStorage.getItem("agency_primary_color"),
                      }}
                    >
                      Categories
                    </Link>

                    <Link
                      to="/app/post-tags"
                      style={{
                        "--custom_color4":
                          localStorage.getItem("agencyid") == 1
                            ? "#3064f9"
                            : localStorage.getItem("agency_primary_color"),
                        "--custom_color5":
                          localStorage.getItem("agencyid") == 1
                            ? "#3064f9"
                            : localStorage.getItem("agency_primary_color"),
                      }}
                    >
                      Tags
                    </Link>
                    <Link
                      to="/app/post-attributes"
                      style={{
                        "--custom_color4":
                          localStorage.getItem("agencyid") == 1
                            ? "#3064f9"
                            : localStorage.getItem("agency_primary_color"),
                        "--custom_color5":
                          localStorage.getItem("agencyid") == 1
                            ? "#3064f9"
                            : localStorage.getItem("agency_primary_color"),
                      }}
                    >
                      Attributes
                    </Link>
                  </div>

                  <div className="createpage allpages">
                    {postState?.settings?.settingUpdatedAt &&
                      postListSetting && (
                        <p
                          style={{
                            display: window.matchMedia("(max-width: 1200px)")
                              .matches
                              ? "none"
                              : "block",
                            textAlign: "center",
                            color: "#bcc1ce",
                            fontSize: "12px",
                          }}
                        >
                          Updated{" "}
                          {moment(postState?.settings?.settingUpdatedAt).format(
                            "D MMMM YYYY, HH:mm"
                          )}{" "}
                        </p>
                      )}
                    {postData && postData.length > 0 && !postListSetting && (
                      <div className="cms-pages-search">
                        <input
                          placeholder="Search..."
                          type="text"
                          value={searchVal}
                          onChange={(e) => handleSearch(e)}
                        ></input>
                        <img
                          src={
                            require("../../assets/images/dashboard/cms/lens.png")
                              .default
                          }
                        />
                      </div>
                    )}

                    {!postListSetting && !spinner && (
                      <div
                        className="post-filter-icon"
                        style={{
                          position: "relative",
                        }}
                      >
                        <svg
                          onClick={
                            // window.matchMedia("(max-width: 1200px)").matches ?handleMobPostFilter :
                            handlePostFilter
                          }
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                        >
                          <g
                            id="Group_37996"
                            data-name="Group 37996"
                            transform="translate(-1600 -118)"
                          >
                            <line
                              id="Line_388"
                              data-name="Line 388"
                              x1="2"
                              transform="translate(1618 128)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <line
                              id="Line_389"
                              data-name="Line 389"
                              x1="14"
                              transform="translate(1600 128)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <circle
                              id="Ellipse_8280"
                              data-name="Ellipse 8280"
                              cx="2"
                              cy="2"
                              r="2"
                              transform="translate(1608 119)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <line
                              id="Line_390"
                              data-name="Line 390"
                              x1="8"
                              transform="translate(1612 121)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <line
                              id="Line_391"
                              data-name="Line 391"
                              x1="8"
                              transform="translate(1600 121)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <line
                              id="Line_392"
                              data-name="Line 392"
                              x1="14"
                              transform="translate(1606 135)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <line
                              id="Line_393"
                              data-name="Line 393"
                              x1="2"
                              transform="translate(1600 135)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <circle
                              id="Ellipse_8281"
                              data-name="Ellipse 8281"
                              cx="2"
                              cy="2"
                              r="2"
                              transform="translate(1602 133)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <circle
                              id="Ellipse_8282"
                              data-name="Ellipse 8282"
                              cx="2"
                              cy="2"
                              r="2"
                              transform="translate(1614 126)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                          </g>
                        </svg>
                        {redDotOnFilter === true ? (
                          <div
                            className="filterapplyed-reddot"
                            style={{
                              top: "-1px",
                            }}
                          ></div>
                        ) : null}
                      </div>
                    )}

                    {parseInt(localStorage.getItem("website_technology")) ===
                      3 && (
                      <>
                        {postListSetting === false ? (
                          //     <div className="consent-setting-clicked postlist-setting-clicked" style={{marginLeft: "20px",marginRight:"0px",cursor:"default"}}>
                          //             <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                          //        <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                          //     <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                          //     <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="#7782a1" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                          //   </g>
                          // </svg>
                          //             </div>
                          <></>
                        ) : (
                          <div
                            className="notification-setting postlist-setting-clicked web-post-home-btn web-post-home-btn"
                            style={{ marginLeft: "20px", marginRight: "0px" }}
                            onClick={() => {
                              handlePostListSettingBtn();
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="26"
                              height="26"
                              viewBox="0 0 26 26"
                            >
                              <g
                                id="Group_42975"
                                data-name="Group 42975"
                                transform="translate(-20 -527)"
                              >
                                <rect
                                  id="Rectangle_25572"
                                  data-name="Rectangle 25572"
                                  width="26"
                                  height="26"
                                  transform="translate(20 527)"
                                  fill="none"
                                />
                                <path
                                  id="Path_119115"
                                  data-name="Path 119115"
                                  d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z"
                                  transform="translate(22.5 529.36)"
                                  fill="none"
                                  stroke="#7782a1"
                                  stroke-miterlimit="10"
                                  stroke-width="1.5"
                                />
                              </g>
                            </svg>
                          </div>
                        )}
                      </>
                    )}

                    {!spinner && !selectedArr.length > 0 && (
                     
                       
                       <div
                    onClick={() => {
                      handlePostListSettingBtn();
                    }}
                    className="cms-setting-btn web-cms-setting-btn"
                  >
                    <SettingIcon
                     
                      isActive={postListSetting}
                    />
                  </div>
                     
                    )}

                    {!spinner &&
                      !selectedArr.length > 0 &&
                      postData.length > 0 && (
                        <button
                          onClick={() => {
                            setTableView((view) => {
                              localStorage.setItem("postsTableView", !view);
                              return !view;
                            });
                            setPostListSetting(false);
                          }}
                          className={
                            tableView
                              ? "setting-bt-off-hide-web setting-btn-web"
                              : "setting-bt-on-hide-web setting-btn-web"
                          }
                        >
                          {!tableView ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22.5"
                              height="19.5"
                              viewBox="0 0 22.5 19.5"
                            >
                              <g
                                id="Group_42206"
                                data-name="Group 42206"
                                transform="translate(-1850.25 -196.25)"
                              >
                                <path
                                  id="Path_118853"
                                  data-name="Path 118853"
                                  d="M1.167,0H19.833A1.093,1.093,0,0,1,21,1V17a1.093,1.093,0,0,1-1.167,1H1.167A1.093,1.093,0,0,1,0,17V1A1.093,1.093,0,0,1,1.167,0Z"
                                  transform="translate(1851 197)"
                                  fill="none"
                                  stroke="#7782a1"
                                  stroke-width="1.5"
                                />
                                <line
                                  id="Line_443"
                                  data-name="Line 443"
                                  x2="21"
                                  transform="translate(1851 203)"
                                  fill="none"
                                  stroke="#7782a1"
                                  stroke-miterlimit="10"
                                  stroke-width="1.5"
                                />
                                <line
                                  id="Line_445"
                                  data-name="Line 445"
                                  y1="18"
                                  transform="translate(1858 197)"
                                  fill="none"
                                  stroke="#7782a1"
                                  stroke-miterlimit="10"
                                  stroke-width="1.5"
                                />
                                <line
                                  id="Line_446"
                                  data-name="Line 446"
                                  x2="21"
                                  transform="translate(1851 209)"
                                  fill="none"
                                  stroke="#7782a1"
                                  stroke-miterlimit="10"
                                  stroke-width="1.5"
                                />
                                <line
                                  id="Line_467"
                                  data-name="Line 467"
                                  y1="18"
                                  transform="translate(1865 197)"
                                  fill="none"
                                  stroke="#7782a1"
                                  stroke-miterlimit="10"
                                  stroke-width="1.5"
                                />
                              </g>
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="23"
                              height="20.001"
                              viewBox="0 0 23 20.001"
                            >
                              <path
                                id="Subtraction_287"
                                data-name="Subtraction 287"
                                d="M3924.2,756H3920v-5h6v3.2A1.8,1.8,0,0,1,3924.2,756Zm-6.2,0h-7v-5h7v5Zm-9,0h-4.2a1.8,1.8,0,0,1-1.8-1.8V751h6v5Zm0-7h-6v-5h6v5Zm17,0h-6v-5h6v5Zm-8,0h-7v-5h7v5Zm8-7h-6v-6l4.2,0a1.8,1.8,0,0,1,1.8,1.8V742Zm-8,0h-7v-6l7,0v6Zm-9,0h-6v-4.206a1.8,1.8,0,0,1,1.8-1.8h4.2v6Z"
                                transform="translate(-3903 -735.999)"
                                fill="#7782a1"
                              />
                            </svg>
                          )}
                        </button>
                      )}

                    {selectedArr.length === 0 && (
                      <div className="d-none d-xl-flex">
                        {postListSetting ? (
                          <button
                            className="createpage"
                            onClick={(e) => handleSubmitPostSettingsData(e)}
                            style={{
                              backgroundColor: localStorage.getItem(
                                "button_background_color"
                              ),
                              border:
                                "1px solid " +
                                localStorage.getItem("button_background_color"),
                              color: localStorage.getItem("button_text_color"),
                              "--custom_color5":
                                localStorage.getItem("agencyid") == 1
                                  ? "#134bec"
                                  : localStorage.getItem(
                                      "button_hover_bgcolor"
                                    ),
                              "--custom_color4":
                                localStorage.getItem("agencyid") == 1
                                  ? "white"
                                  : localStorage.getItem(
                                      "button_hover_txtcolor"
                                    ),
                              "--custom_color6":
                                localStorage.getItem("agencyid") == 1
                                  ? "#3064f9"
                                  : localStorage.getItem(
                                      "button_hover_bgcolor"
                                    ),
                            }}
                          >
                            <div className={settingSaveBtnLoader}>
                              <div className="new-loader"></div>
                            </div>
                            <div>{settingSaveBtnVal}</div>
                          </button>
                        ) : (
                          <Link
                            className="createpage d-none d-xl-flex"
                            to="/app/post/create"
                            style={{
                              backgroundColor: localStorage.getItem(
                                "button_background_color"
                              ),
                              border:
                                "1px solid " +
                                localStorage.getItem("button_background_color"),
                              color: localStorage.getItem("button_text_color"),
                              "--custom_color5":
                                localStorage.getItem("agencyid") == 1
                                  ? "#134bec"
                                  : localStorage.getItem(
                                      "button_hover_bgcolor"
                                    ),
                              "--custom_color4":
                                localStorage.getItem("agencyid") == 1
                                  ? "white"
                                  : localStorage.getItem(
                                      "button_hover_txtcolor"
                                    ),
                              "--custom_color6":
                                localStorage.getItem("agencyid") == 1
                                  ? "#3064f9"
                                  : localStorage.getItem(
                                      "button_hover_bgcolor"
                                    ),
                            }}
                          >
                            <img
                              className="mr-2"
                              src={
                                require("../../assets/images/dashboard/cms/plus.png")
                                  .default
                              }
                            />{" "}
                            <span
                              style={{
                                marginTop: "2px",
                              }}
                            >
                              Create
                            </span>
                          </Link>
                        )}
                      </div>
                    )}
                    {tableView && selectedArr.length > 0 && (
                      <button
                        onClick={(e) => {
                          document.body.classList.add("opec");
                          setBulkUpdateFilter(true);
                        }}
                        className="createpage d-none d-xl-flex"
                        style={{
                          backgroundColor: localStorage.getItem(
                            "button_background_color"
                          ),
                          border:
                            "1px solid " +
                            localStorage.getItem("button_background_color"),
                          color: localStorage.getItem("button_text_color"),
                          "--custom_color5":
                            localStorage.getItem("agencyid") == 1
                              ? "#134bec"
                              : localStorage.getItem("button_hover_bgcolor"),
                          "--custom_color4":
                            localStorage.getItem("agencyid") == 1
                              ? "white"
                              : localStorage.getItem("button_hover_txtcolor"),
                          "--custom_color6":
                            localStorage.getItem("agencyid") == 1
                              ? "#3064f9"
                              : localStorage.getItem("button_hover_bgcolor"),
                        }}
                      >
                        <span>Update</span>
                      </button>
                    )}
                  </div>
                </div>
              )}

              {postListSetting ? (
                <>
                  {" "}
                  <div className="whitebg post-list-settings">
                    {settingSpinner ? (
                      <div
                        style={{
                          height: "80vh",
                          borderRadius: "6px",
                        }}
                        className="cmsnewspinner"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xlink="http://www.w3.org/1999/xlink"
                          style={{
                            margin: "auto",
                            background: "rgb(241, 242, 243,0)",
                            display: "block",
                            shaperendering: "auto",
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                          width="200px"
                          height="200px"
                          viewBox="0 0 100 100"
                          preserveAspectRatio="xMidYMid"
                        >
                          <g
                            transform="translate(20 50)"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#e6261f"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="-0.375s"
                                calcMode="spline"
                                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                values="0;1;0"
                                keyTimes="0;0.5;1"
                                dur="1s"
                                repeatCount="indefinite"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              ></animateTransform>
                            </circle>
                          </g>
                          <g
                            transform="translate(40 50)"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#f7d038"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="-0.25s"
                                calcMode="spline"
                                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                values="0;1;0"
                                keyTimes="0;0.5;1"
                                dur="1s"
                                repeatCount="indefinite"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              ></animateTransform>
                            </circle>
                          </g>
                          <g
                            transform="translate(60 50)"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#49da9a"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="-0.125s"
                                calcMode="spline"
                                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                values="0;1;0"
                                keyTimes="0;0.5;1"
                                dur="1s"
                                repeatCount="indefinite"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              ></animateTransform>
                            </circle>
                          </g>
                          <g
                            transform="translate(80 50)"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#4355db"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="0s"
                                calcMode="spline"
                                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                values="0;1;0"
                                keyTimes="0;0.5;1"
                                dur="1s"
                                repeatCount="indefinite"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              ></animateTransform>
                            </circle>
                          </g>
                        </svg>
                      </div>
                    ) : (
                      <div className="cms-posts-listing">
                        <div
                          className="select-parent cms-settings pageslistdetail page-editor post-pageslistdetail"
                          style={{
                            marginTop: window.matchMedia("(max-width: 1200px)")
                              .matches
                              ? "20px"
                              : "",
                          }}
                        >
                          <div className="post-list-settings-head">
                            <h2>Settings</h2>
                            <p>
                              Customize user-experience with these powerful post
                              settings.
                            </p>
                          </div>
                          <h3 className="post-setting-title">Post list</h3>

                          <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                            <div class="d-flex justify-content-start align-items-center ">
                              <h4 class="mb-0">
                                Show search icon
                                {parseInt(
                                  localStorage.getItem("website_technology")
                                ) !== 3 ? (
                                  <h6 class="cmspostsetting-tooltip">
                                    <svg
                                      style={{ cursor: "pointer" }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                    >
                                      <g
                                        id="Group_40296"
                                        data-name="Group 40296"
                                        transform="translate(-1727 -19.132)"
                                      >
                                        <g
                                          id="Ellipse_7706"
                                          data-name="Ellipse 7706"
                                          transform="translate(1727 19.132)"
                                          fill="#fff"
                                          stroke="#bcc1ce"
                                          stroke-width="1.2"
                                        >
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="7"
                                            stroke="none"
                                          ></circle>
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="6.4"
                                            fill="none"
                                          ></circle>
                                        </g>
                                        <path
                                          id="Path_98061"
                                          data-name="Path 98061"
                                          d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                          transform="translate(1725.126 17.539)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></path>
                                        <line
                                          id="Line_343"
                                          data-name="Line 343"
                                          y2="1.2"
                                          transform="translate(1734 29.132)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></line>
                                      </g>
                                    </svg>
                                    <h6 class="cmspostsetting-tooltipsubheader">
                                      {" "}
                                      Enable to display a search icon in header for quicker access to required content.
                                    </h6>
                                  </h6>
                                ) : (
                                  <h6 class="cmspostsetting-tooltip">
                                    <svg
                                      style={{ cursor: "pointer" }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                    >
                                      <g
                                        id="Group_40296"
                                        data-name="Group 40296"
                                        transform="translate(-1727 -19.132)"
                                      >
                                        <g
                                          id="Ellipse_7706"
                                          data-name="Ellipse 7706"
                                          transform="translate(1727 19.132)"
                                          fill="#fff"
                                          stroke="#bcc1ce"
                                          stroke-width="1.2"
                                        >
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="7"
                                            stroke="none"
                                          ></circle>
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="6.4"
                                            fill="none"
                                          ></circle>
                                        </g>
                                        <path
                                          id="Path_98061"
                                          data-name="Path 98061"
                                          d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                          transform="translate(1725.126 17.539)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></path>
                                        <line
                                          id="Line_343"
                                          data-name="Line 343"
                                          y2="1.2"
                                          transform="translate(1734 29.132)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></line>
                                      </g>
                                    </svg>
                                    <h6 class="cmspostsetting-tooltipsubheader">
                                      {" "}
                                      Enable to display a search icon in header for quicker access to required content.
                                    </h6>
                                  </h6>
                                )}
                              </h4>
                            </div>
                            <div class="on_off d-flex  ">
                              <label class="switch" for="showSearch">
                                <input
                                  type="checkbox"
                                  id="showSearch"
                                  checked={showSearch}
                                  onChange={(e) => {
                                    setShowSearch(e.target.checked);
                                    setActiveSlide(0);
                                  }}
                                />
                                <div class="slider round"></div>
                              </label>
                            </div>
                          </div>

                          <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                            <div class="d-flex justify-content-start align-items-center ">
                              <h4 class="mb-0">
                                Show as grid
                                {parseInt(
                                  localStorage.getItem("website_technology")
                                ) !== 3 ? (
                                  <h6 class="cmspostsetting-tooltip">
                                    <svg
                                      style={{ cursor: "pointer" }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                    >
                                      <g
                                        id="Group_40296"
                                        data-name="Group 40296"
                                        transform="translate(-1727 -19.132)"
                                      >
                                        <g
                                          id="Ellipse_7706"
                                          data-name="Ellipse 7706"
                                          transform="translate(1727 19.132)"
                                          fill="#fff"
                                          stroke="#bcc1ce"
                                          stroke-width="1.2"
                                        >
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="7"
                                            stroke="none"
                                          ></circle>
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="6.4"
                                            fill="none"
                                          ></circle>
                                        </g>
                                        <path
                                          id="Path_98061"
                                          data-name="Path 98061"
                                          d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                          transform="translate(1725.126 17.539)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></path>
                                        <line
                                          id="Line_343"
                                          data-name="Line 343"
                                          y2="1.2"
                                          transform="translate(1734 29.132)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></line>
                                      </g>
                                    </svg>
                                    <h6 class="cmspostsetting-tooltipsubheader">
                                      Enable this toggle to display posts in a
                                      grid layout.
                                    </h6>
                                  </h6>
                                ) : (
                                  <h6 class="cmspostsetting-tooltip">
                                    <svg
                                      style={{ cursor: "pointer" }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                    >
                                      <g
                                        id="Group_40296"
                                        data-name="Group 40296"
                                        transform="translate(-1727 -19.132)"
                                      >
                                        <g
                                          id="Ellipse_7706"
                                          data-name="Ellipse 7706"
                                          transform="translate(1727 19.132)"
                                          fill="#fff"
                                          stroke="#bcc1ce"
                                          stroke-width="1.2"
                                        >
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="7"
                                            stroke="none"
                                          ></circle>
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="6.4"
                                            fill="none"
                                          ></circle>
                                        </g>
                                        <path
                                          id="Path_98061"
                                          data-name="Path 98061"
                                          d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                          transform="translate(1725.126 17.539)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></path>
                                        <line
                                          id="Line_343"
                                          data-name="Line 343"
                                          y2="1.2"
                                          transform="translate(1734 29.132)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></line>
                                      </g>
                                    </svg>
                                    <h6 class="cmspostsetting-tooltipsubheader">
                                      Enable to display posts in a grid layout.
                                    </h6>
                                  </h6>
                                )}
                              </h4>
                            </div>
                            <div class="on_off d-flex  ">
                              <label class="switch" for="showGridView">
                                <input
                                  type="checkbox"
                                  id="showGridView"
                                  checked={showGridView}
                                  onChange={(e) => {
                                    setShowGridView(e.target.checked);
                                    setPreviewListingView(
                                      e.target.checked ? "grid" : "list"
                                    );
                                    setActiveSlide(0);
                                  }}
                                />
                                <div class="slider round"></div>
                              </label>
                            </div>
                          </div>

                          {/* Show excerpt */}
                          <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                            <div class="d-flex justify-content-start align-items-center ">
                              <h4 class="mb-0">
                                Show excerpt
                                <h6 class="cmspostsetting-tooltip">
                                  <svg
                                    style={{ cursor: "pointer" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                  >
                                    <g
                                      id="Group_40296"
                                      data-name="Group 40296"
                                      transform="translate(-1727 -19.132)"
                                    >
                                      <g
                                        id="Ellipse_7706"
                                        data-name="Ellipse 7706"
                                        transform="translate(1727 19.132)"
                                        fill="#fff"
                                        stroke="#bcc1ce"
                                        stroke-width="1.2"
                                      >
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="7"
                                          stroke="none"
                                        ></circle>
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="6.4"
                                          fill="none"
                                        ></circle>
                                      </g>
                                      <path
                                        id="Path_98061"
                                        data-name="Path 98061"
                                        d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                        transform="translate(1725.126 17.539)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-miterlimit="10"
                                        stroke-width="1.2"
                                      ></path>
                                      <line
                                        id="Line_343"
                                        data-name="Line 343"
                                        y2="1.2"
                                        transform="translate(1734 29.132)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-miterlimit="10"
                                        stroke-width="1.2"
                                      ></line>
                                    </g>
                                  </svg>
                                  <h6 class="cmspostsetting-tooltipsubheader">
                                    Enable to show the excerpt of each post.
                                  </h6>
                                </h6>
                              </h4>
                            </div>
                            <div class="on_off d-flex  ">
                              <label class="switch" for="showExcerptForListing">
                                <input
                                  type="checkbox"
                                  id="showExcerptForListing"
                                  checked={
                                    postState.settings.showExcerptForListing
                                  }
                                  onChange={() => {
                                    handleSettingsToggles(
                                      "showExcerptForListing"
                                    );
                                    setActiveSlide(0);
                                  }}
                                />
                                <div class="slider round"></div>
                              </label>
                            </div>
                          </div>
                          {/* Show excerpt */}

                          <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                            <div class="d-flex justify-content-start align-items-center ">
                              <h4 class="mb-0">
                                Show published date
                                {parseInt(
                                  localStorage.getItem("website_technology")
                                ) !== 3 ? (
                                  <h6 class="cmspostsetting-tooltip">
                                    <svg
                                      style={{ cursor: "pointer" }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                    >
                                      <g
                                        id="Group_40296"
                                        data-name="Group 40296"
                                        transform="translate(-1727 -19.132)"
                                      >
                                        <g
                                          id="Ellipse_7706"
                                          data-name="Ellipse 7706"
                                          transform="translate(1727 19.132)"
                                          fill="#fff"
                                          stroke="#bcc1ce"
                                          stroke-width="1.2"
                                        >
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="7"
                                            stroke="none"
                                          ></circle>
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="6.4"
                                            fill="none"
                                          ></circle>
                                        </g>
                                        <path
                                          id="Path_98061"
                                          data-name="Path 98061"
                                          d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                          transform="translate(1725.126 17.539)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></path>
                                        <line
                                          id="Line_343"
                                          data-name="Line 343"
                                          y2="1.2"
                                          transform="translate(1734 29.132)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></line>
                                      </g>
                                    </svg>
                                    <h6 class="cmspostsetting-tooltipsubheader">
                                      Enable this toggle to show the publication
                                      date of each post.
                                    </h6>
                                  </h6>
                                ) : (
                                  <h6 class="cmspostsetting-tooltip">
                                    <svg
                                      style={{ cursor: "pointer" }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                    >
                                      <g
                                        id="Group_40296"
                                        data-name="Group 40296"
                                        transform="translate(-1727 -19.132)"
                                      >
                                        <g
                                          id="Ellipse_7706"
                                          data-name="Ellipse 7706"
                                          transform="translate(1727 19.132)"
                                          fill="#fff"
                                          stroke="#bcc1ce"
                                          stroke-width="1.2"
                                        >
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="7"
                                            stroke="none"
                                          ></circle>
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="6.4"
                                            fill="none"
                                          ></circle>
                                        </g>
                                        <path
                                          id="Path_98061"
                                          data-name="Path 98061"
                                          d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                          transform="translate(1725.126 17.539)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></path>
                                        <line
                                          id="Line_343"
                                          data-name="Line 343"
                                          y2="1.2"
                                          transform="translate(1734 29.132)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></line>
                                      </g>
                                    </svg>
                                    <h6 class="cmspostsetting-tooltipsubheader">
                                      Enable to show the publication date of
                                      each post.
                                    </h6>
                                  </h6>
                                )}
                              </h4>
                            </div>
                            <div class="on_off d-flex  ">
                              <label
                                class="switch"
                                for="showPublishDateForListing"
                              >
                                <input
                                  type="checkbox"
                                  id="showPublishDateForListing"
                                  checked={showPublishDateForListing}
                                  onChange={(e) => handleShowPublishDate2(e)}
                                />
                                <div class="slider round"></div>
                              </label>
                            </div>
                          </div>

                          <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                            <div class="d-flex justify-content-start align-items-center ">
                              <h4 class="mb-0">
                                Image ratio
                                <h6 class="cmspostsetting-tooltip">
                                  <svg
                                    style={{ cursor: "pointer" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                  >
                                    <g
                                      id="Group_40296"
                                      data-name="Group 40296"
                                      transform="translate(-1727 -19.132)"
                                    >
                                      <g
                                        id="Ellipse_7706"
                                        data-name="Ellipse 7706"
                                        transform="translate(1727 19.132)"
                                        fill="#fff"
                                        stroke="#bcc1ce"
                                        stroke-width="1.2"
                                      >
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="7"
                                          stroke="none"
                                        ></circle>
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="6.4"
                                          fill="none"
                                        ></circle>
                                      </g>
                                      <path
                                        id="Path_98061"
                                        data-name="Path 98061"
                                        d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                        transform="translate(1725.126 17.539)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-miterlimit="10"
                                        stroke-width="1.2"
                                      ></path>
                                      <line
                                        id="Line_343"
                                        data-name="Line 343"
                                        y2="1.2"
                                        transform="translate(1734 29.132)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-miterlimit="10"
                                        stroke-width="1.2"
                                      ></line>
                                    </g>
                                  </svg>
                                  <h6 class="cmspostsetting-tooltipsubheader">
                                    Define the image aspect ratio for your
                                    posts. Select a suitable image ratio
                                    corresponding to your image.
                                  </h6>
                                </h6>
                              </h4>
                            </div>

                            <div className="post-box-size">
                              <p className="cms-icon-tooltip post-shape-icon-tooltip"
                                onClick={(e) =>
                                  handleImgRatioForListing(e, "16:9")
                                }
                              >
                                <svg
                                  className={
                                    imgRatioForListing === "16:9"
                                      ? "post-shapeselect"
                                      : "post-shapenotselect"
                                  }
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="14"
                                  viewBox="0 0 24 14"
                                >
                                  <g
                                    id="Path_118621"
                                    data-name="Path 118621"
                                    fill="#fff"
                                  >
                                    <path
                                      d="M 23 13 L 19.25 13 L 1 13 L 1 1 L 23 1 L 23 13 Z"
                                      stroke="none"
                                    />
                                    <path
                                      className={
                                        imgRatioForListing === "16:9"
                                          ? "post-shapeselect"
                                          : "post-shapenotselect"
                                      }
                                      d="M 2 2 L 2 12 L 22 12 L 22 2 L 2 2 M 0 0 L 24 0 L 24 14 L 0 14 L 0 0 Z"
                                      stroke="none"
                                      fill=""
                                    />
                                  </g>
                                </svg>
                                <span>16:9</span>
                                <span className="cms-icon-tooltipsubheader">Select for a widescreen image format, ideal for panoramic views and cinematic visuals.</span>
                              </p>

                              <p className="cms-icon-tooltip post-shape-icon-tooltip"
                                onClick={(e) =>
                                  handleImgRatioForListing(e, "4:3")
                                }
                              >
                                <svg
                                  className={
                                    imgRatioForListing === "4:3"
                                      ? "post-shapeselect"
                                      : "post-shapenotselect"
                                  }
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="15"
                                  viewBox="0 0 20 15"
                                >
                                  <g
                                    id="Rectangle_18739"
                                    data-name="Rectangle 18739"
                                    fill="#fff"
                                    stroke=""
                                    stroke-width="2"
                                  >
                                    <rect
                                      width="20"
                                      height="15"
                                      stroke="none"
                                    />
                                    <rect
                                      x="1"
                                      y="1"
                                      width="18"
                                      height="13"
                                      fill="none"
                                    />
                                  </g>
                                </svg>
                                <span>4:3</span>
                                <span className="cms-icon-tooltipsubheader">Opt to maintain a classic image aspect ratio, suitable for balanced compositions.</span>
                              </p>
                              <p
                              className="cms-icon-tooltip post-shape-icon-tooltip"
                                onClick={(e) =>
                                  handleImgRatioForListing(e, "1:1")
                                }
                              >
                                <svg
                                  className={
                                    imgRatioForListing === "1:1"
                                      ? "post-shapeselect"
                                      : "post-shapenotselect"
                                  }
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                >
                                  <g
                                    id="Rectangle_18739"
                                    data-name="Rectangle 18739"
                                    fill="#fff"
                                    stroke=""
                                    stroke-width="2"
                                  >
                                    <rect
                                      width="18"
                                      height="18"
                                      stroke="none"
                                    />
                                    <rect
                                      x="1"
                                      y="1"
                                      width="16"
                                      height="16"
                                      fill="none"
                                    />
                                  </g>
                                </svg>
                                <span className="product-ratio-select">
                                  1:1
                                </span>
                                <span className="cms-icon-tooltipsubheader">Choose for a square image format, great for symmetrical and uniform visuals.</span>
                              </p>
                              <p
                             className="cms-icon-tooltip post-shape-icon-tooltip"
                                onClick={(e) =>
                                  handleImgRatioForListing(e, "3:4")
                                }
                              >
                                <svg
                                  className={
                                    imgRatioForListing === "3:4"
                                      ? "post-shapeselect"
                                      : "post-shapenotselect"
                                  }
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="20"
                                  viewBox="0 0 15 20"
                                >
                                  <g
                                    id="Rectangle_18739"
                                    data-name="Rectangle 18739"
                                    fill="#fff"
                                    stroke=""
                                    stroke-width="2"
                                  >
                                    <rect
                                      width="15"
                                      height="20"
                                      stroke="none"
                                    />
                                    <rect
                                      x="1"
                                      y="1"
                                      width="13"
                                      height="18"
                                      fill="none"
                                    />
                                  </g>
                                </svg>
                                <span>3:4</span>
                                <span className="cms-icon-tooltipsubheader">Pick for a portrait-oriented image format, perfect for capturing vertical scenes and portraits.</span>
                              </p>
                              <p className="cms-icon-tooltip post-shape-icon-tooltip"
                                onClick={(e) =>
                                  handleImgRatioForListing(e, "9:16")
                                }
                              >
                                <svg
                                  className={
                                    imgRatioForListing === "9:16"
                                      ? "post-shapeselect"
                                      : "post-shapenotselect"
                                  }
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="24"
                                  viewBox="0 0 14 24"
                                >
                                  <g
                                    id="Rectangle_31455"
                                    data-name="Rectangle 31455"
                                    transform="translate(14) rotate(90)"
                                    fill="#fff"
                                    stroke=""
                                    stroke-width="2"
                                  >
                                    <rect
                                      width="24"
                                      height="14"
                                      stroke="none"
                                    />
                                    <rect
                                      x="1"
                                      y="1"
                                      width="22"
                                      height="12"
                                      fill="none"
                                    />
                                  </g>
                                </svg>
                                <span>9:16</span>
                                <span className="cms-icon-tooltipsubheader">Opt for a vertical image format perfect for capturing tall scenes and portrait-oriented visuals.</span>
                              </p>
                            </div>
                          </div>

                          <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                            <div class="d-flex justify-content-start align-items-center ">
                              <h4 class="mb-0">
                                Image shape
                                <h6 class="cmspostsetting-tooltip">
                                  <svg
                                    style={{ cursor: "pointer" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                  >
                                    <g
                                      id="Group_40296"
                                      data-name="Group 40296"
                                      transform="translate(-1727 -19.132)"
                                    >
                                      <g
                                        id="Ellipse_7706"
                                        data-name="Ellipse 7706"
                                        transform="translate(1727 19.132)"
                                        fill="#fff"
                                        stroke="#bcc1ce"
                                        stroke-width="1.2"
                                      >
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="7"
                                          stroke="none"
                                        ></circle>
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="6.4"
                                          fill="none"
                                        ></circle>
                                      </g>
                                      <path
                                        id="Path_98061"
                                        data-name="Path 98061"
                                        d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                        transform="translate(1725.126 17.539)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-miterlimit="10"
                                        stroke-width="1.2"
                                      ></path>
                                      <line
                                        id="Line_343"
                                        data-name="Line 343"
                                        y2="1.2"
                                        transform="translate(1734 29.132)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-miterlimit="10"
                                        stroke-width="1.2"
                                      ></line>
                                    </g>
                                  </svg>
                                  <h6 class="cmspostsetting-tooltipsubheader">
                                    {" "}
                                    Define the preferred shape of your post
                                    images.
                                  </h6>
                                </h6>
                              </h4>
                            </div>

                            <div className="shape-of-post">
                              <svg
                                onClick={(e) =>
                                  handleImgShapeForListing(e, "sharp_corner")
                                }
                                className={
                                  imgShapeForListing === "sharp_corner"
                                    ? "post-shapeselect"
                                    : "post-shapenotselect"
                                }
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                              >
                                <g
                                  id="Rectangle_18739"
                                  data-name="Rectangle 18739"
                                  fill="#fff"
                                  stroke=""
                                  stroke-width="2"
                                >
                                  <rect width="18" height="18" stroke="none" />
                                  <rect
                                    x="1"
                                    y="1"
                                    width="16"
                                    height="16"
                                    fill="none"
                                  />
                                </g>
                              </svg>
                              <svg
                                onClick={(e) =>
                                  handleImgShapeForListing(e, "soft_corner")
                                }
                                className={
                                  imgShapeForListing === "soft_corner"
                                    ? "post-shapeselect"
                                    : "post-shapenotselect"
                                }
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                              >
                                <g
                                  id="Rectangle_18739"
                                  data-name="Rectangle 18739"
                                  fill="#fff"
                                  stroke=""
                                  stroke-width="2"
                                >
                                  <rect
                                    width="18"
                                    height="18"
                                    rx="5"
                                    stroke="none"
                                  />
                                  <rect
                                    x="1"
                                    y="1"
                                    width="16"
                                    height="16"
                                    rx="4"
                                    fill="none"
                                  />
                                </g>
                              </svg>

                              {/* <svg
                                onClick={(e)=>handleImgShapeForListing(e,"circle")} 
                                className={imgShapeForListing==="circle"?'post-shapeselect':'post-shapenotselect'} 
                               xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                            <g id="Rectangle_31721" data-name="Rectangle 31721" fill="#fff" stroke="" stroke-width="2">
                              <rect width="18" height="18" rx="9" stroke="none"/>
                              <rect x="1" y="1" width="16" height="16" rx="8" fill="none"/>
                            </g>
                          </svg> */}
                            </div>
                          </div>

                          <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                            <div class="d-flex justify-content-start align-items-center ">
                              <h4 class="mb-0">
                                Center crop images
                                <h6 class="cmspostsetting-tooltip">
                                  <svg
                                    style={{ cursor: "pointer" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                  >
                                    <g
                                      id="Group_40296"
                                      data-name="Group 40296"
                                      transform="translate(-1727 -19.132)"
                                    >
                                      <g
                                        id="Ellipse_7706"
                                        data-name="Ellipse 7706"
                                        transform="translate(1727 19.132)"
                                        fill="#fff"
                                        stroke="#bcc1ce"
                                        stroke-width="1.2"
                                      >
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="7"
                                          stroke="none"
                                        ></circle>
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="6.4"
                                          fill="none"
                                        ></circle>
                                      </g>
                                      <path
                                        id="Path_98061"
                                        data-name="Path 98061"
                                        d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                        transform="translate(1725.126 17.539)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-miterlimit="10"
                                        stroke-width="1.2"
                                      ></path>
                                      <line
                                        id="Line_343"
                                        data-name="Line 343"
                                        y2="1.2"
                                        transform="translate(1734 29.132)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-miterlimit="10"
                                        stroke-width="1.2"
                                      ></line>
                                    </g>
                                  </svg>
                                  <h6 class="cmspostsetting-tooltipsubheader">
                                    When enabled, your images will be centred
                                    within the image frame, for a consistent
                                    look. When disabled, images will be
                                    displayed to fit the frame while maintaining
                                    their original aspect ratios.
                                  </h6>
                                </h6>
                              </h4>
                            </div>
                            <div class="on_off d-flex  ">
                              <label class="switch" for="imgCenterForListing">
                                <input
                                  type="checkbox"
                                  id="imgCenterForListing"
                                  checked={imgCenterForListing}
                                  onChange={(e) => handleImgCenterForListing(e)}
                                />
                                <div class="slider round"></div>
                              </label>
                            </div>
                          </div>

                          {/* New toggle */}

                          
           {
            parseInt(
              localStorage.getItem("website_technology")
            ) === 3 &&
            <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
              <div class="d-flex justify-content-start align-items-center ">
                  <h4 class="mb-0">Show filter
                  <h6 class="cmspostsetting-tooltip"><svg style={{cursor: "pointer"}} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><h6 class="cmspostsetting-tooltipsubheader"> Enable to allow your app users to filter posts by attributes.</h6></h6>
                  {/* <Newfeatureicon/> */}
                  </h4>
                </div>
                <div class="on_off d-flex  ">
                <label class="switch" for="showFilterIconOnList">
                    <input
                      type="checkbox"
                      id="showFilterIconOnList"
                      checked={postState.settings.showFilterIconOnList}
                      onChange={() =>{
                        handleSettingsToggles("showFilterIconOnList")
                        setActiveSlide(0);
                      }}
                    />
                    <div class="slider round"></div>
                  </label>
                </div>
            </div>
           }

          {
            parseInt(
              localStorage.getItem("website_technology")
            ) === 3 &&
            <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
              <div class="d-flex justify-content-start align-items-center ">
                  <h4 class="mb-0">Show sort
                  <h6 class="cmspostsetting-tooltip"><svg style={{cursor: "pointer"}} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><h6 class="cmspostsetting-tooltipsubheader">Enable to allow your customers to sort posts based on their preferences.</h6></h6>
                  {/* <Newfeatureicon/> */}
                  </h4>
                </div>
                <div class="on_off d-flex  ">
                <label class="switch" for="showSortIconOnList">
                    <input
                      type="checkbox"
                      id="showSortIconOnList"
                      checked={postState.settings.showSortIconOnList}
                      onChange={() =>{
                        handleSettingsToggles("showSortIconOnList")
                        setActiveSlide(0);
                      }}
                    />
                    <div class="slider round"></div>
                  </label>
                </div>
            </div>
          }

          {
            parseInt(
              localStorage.getItem("website_technology")
            ) === 3 &&
            <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
              <div class="d-flex justify-content-start align-items-center ">
                  <h4 class="mb-0">Show total count
                  <h6 class="cmspostsetting-tooltip"><svg style={{cursor: "pointer"}} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><h6 class="cmspostsetting-tooltipsubheader">Enable to display the total count of posts.</h6></h6>
                  {/* <Newfeatureicon/> */}
                  </h4>
                </div>
                <div class="on_off d-flex  ">
                <label class="switch" for="showTotalCountOnList">
                    <input
                      type="checkbox"
                      id="showTotalCountOnList"
                      checked={postState.settings.showTotalCountOnList}
                      onChange={() =>{
                        handleSettingsToggles("showTotalCountOnList")
                        setActiveSlide(0);
                      }}
                    />
                    <div class="slider round"></div>
                  </label>
                </div>
            </div>
          }




            <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
              <div class="d-flex justify-content-start align-items-center ">
                  <h4 class="mb-0">Show author
                  <h6 class="cmspostsetting-tooltip"><svg style={{cursor: "pointer"}} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><h6 class="cmspostsetting-tooltipsubheader">Enable to display the author's name.</h6></h6>
                  {/* <Newfeatureicon/> */}
                  </h4>
                </div>
                <div class="on_off d-flex  ">
                <label class="switch" for="showAuthorOnList">
                    <input
                      type="checkbox"
                      id="showAuthorOnList"
                      checked={postState.settings.showAuthorOnList}
                      onChange={() =>{
                        handleSettingsToggles("showAuthorOnList")
                        setActiveSlide(0);
                      }}
                    />
                    <div class="slider round"></div>
                  </label>
                </div>
            </div>


            {
          parseInt(localStorage.getItem("website_technology")) === 3 ?

            <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
              <div class="d-flex justify-content-start align-items-center ">
                  <h4 class="mb-0">Enable bookmarking
                  <h6 class="cmspostsetting-tooltip"><svg style={{cursor: "pointer"}} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><h6 class="cmspostsetting-tooltipsubheader"> Enable to allow users to bookmark posts for quick access.</h6></h6>
                  <Newfeatureicon/>
                  </h4>
                </div>
                <div class="on_off d-flex  ">
                <label class="switch" for="enableBookmarkingOnList">
                    <input
                      type="checkbox"
                      id="enableBookmarkingOnList"
                      checked={postState.settings.enableBookmarkingOnList}
                      onChange={() =>{
                        handleSettingsToggles("enableBookmarkingOnList")
                        setActiveSlide(0);
                      }}
                    />
                    <div class="slider round"></div>
                  </label>
                </div>
            </div>
            :
            <></>
            }

        {
          parseInt(localStorage.getItem("website_technology")) === 3 ?

            <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
              <div class="d-flex justify-content-start align-items-center ">
                  <h4 class="mb-0">Show bookmark icon
                  <h6 class="cmspostsetting-tooltip"><svg style={{cursor: "pointer"}} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><h6 class="cmspostsetting-tooltipsubheader">Enable to display a bookmark icon in the header to access bookmarked posts.</h6></h6>
                  <Newfeatureicon/>
                  </h4>
                </div>
                {
                              !postState.settings.enableBookmarkingOnList ?
                              <LockedToggle/> :
                <div class="on_off d-flex  ">
                  <label class="switch" for="showBookmarkIconInHeader">
                    <input
                      type="checkbox"
                      id="showBookmarkIconInHeader"
                      checked={postState.settings.showBookmarkIconInHeader}
                      onChange={() =>{
                        handleSettingsToggles("showBookmarkIconInHeader")
                        setActiveSlide(0);
                      }}
                    />
                    <div class="slider round"></div>
                  </label>
                </div> }
            </div>
              :
         <></>
        }

                          <div className="post-list-setting-seprator"></div>

                          <h3 className="post-setting-title">Post details</h3>

                          {parseInt(
                            localStorage.getItem("website_technology")
                          ) !== 3 && (
                            <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                              <div class="d-flex justify-content-start align-items-center ">
                                <h4 class="mb-0">
                                  Render as web view
                                  <h6 class="cmspostsetting-tooltip">
                                    <svg
                                      style={{ cursor: "pointer" }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                    >
                                      <g
                                        id="Group_40296"
                                        data-name="Group 40296"
                                        transform="translate(-1727 -19.132)"
                                      >
                                        <g
                                          id="Ellipse_7706"
                                          data-name="Ellipse 7706"
                                          transform="translate(1727 19.132)"
                                          fill="#fff"
                                          stroke="#bcc1ce"
                                          stroke-width="1.2"
                                        >
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="7"
                                            stroke="none"
                                          ></circle>
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="6.4"
                                            fill="none"
                                          ></circle>
                                        </g>
                                        <path
                                          id="Path_98061"
                                          data-name="Path 98061"
                                          d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                          transform="translate(1725.126 17.539)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></path>
                                        <line
                                          id="Line_343"
                                          data-name="Line 343"
                                          y2="1.2"
                                          transform="translate(1734 29.132)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></line>
                                      </g>
                                    </svg>
                                    <h6 class="cmspostsetting-tooltipsubheader">
                                      Enable to render the page exactly as
                                      displayed on your mobile browser.
                                    </h6>
                                  </h6>
                                </h4>
                              </div>
                              <div class="on_off d-flex  ">
                                <label class="switch" for="isWebView">
                                  <input
                                    type="checkbox"
                                    id="isWebView"
                                    checked={isWebView}
                                    onChange={(e) => {
                                      setIsWebView(e.target.checked);
                                      setActiveSlide(1);
                                    }}
                                  />
                                  <div class="slider round"></div>
                                </label>
                              </div>
                            </div>
                          )}

                          {parseInt(
                            localStorage.getItem("website_technology")
                          ) !== 3 && 
                          <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                            <div class="d-flex justify-content-start align-items-center ">
                              <h4 class="mb-0">
                                Share
                                <h6 class="cmspostsetting-tooltip">
                                  <svg
                                    style={{ cursor: "pointer" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                  >
                                    <g
                                      id="Group_40296"
                                      data-name="Group 40296"
                                      transform="translate(-1727 -19.132)"
                                    >
                                      <g
                                        id="Ellipse_7706"
                                        data-name="Ellipse 7706"
                                        transform="translate(1727 19.132)"
                                        fill="#fff"
                                        stroke="#bcc1ce"
                                        stroke-width="1.2"
                                      >
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="7"
                                          stroke="none"
                                        ></circle>
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="6.4"
                                          fill="none"
                                        ></circle>
                                      </g>
                                      <path
                                        id="Path_98061"
                                        data-name="Path 98061"
                                        d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                        transform="translate(1725.126 17.539)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-miterlimit="10"
                                        stroke-width="1.2"
                                      ></path>
                                      <line
                                        id="Line_343"
                                        data-name="Line 343"
                                        y2="1.2"
                                        transform="translate(1734 29.132)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-miterlimit="10"
                                        stroke-width="1.2"
                                      ></line>
                                    </g>
                                  </svg>
                                  <h6 class="cmspostsetting-tooltipsubheader">
                                  Enable to display a share icon for quick access to sharing options.
                                  </h6>
                                </h6>
                                {/* <Newfeatureicon/> */}
                              </h4>
                            </div>
                            {
                              isWebView ?
                              <LockedToggle/>
                              :
                            
                            <div class="on_off d-flex  ">
                              <label class="switch" for="showShareIconOnDetails">
                                <input
                                  type="checkbox"
                                  id="showShareIconOnDetails"
                                  checked={postState.settings.showShareIconOnDetails}
                                  onChange={() =>{
                                    handleSettingsToggles("showShareIconOnDetails")
                                    setActiveSlide(1);
                                  }}
                                />
                                <div class="slider round"></div>
                              </label>
                            </div>
}
                          </div>
                          }

                          <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                            <div class="d-flex justify-content-start align-items-center ">
                              <h4 class="mb-0">
                                Show author
                                <h6 class="cmspostsetting-tooltip">
                                  <svg
                                    style={{ cursor: "pointer" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                  >
                                    <g
                                      id="Group_40296"
                                      data-name="Group 40296"
                                      transform="translate(-1727 -19.132)"
                                    >
                                      <g
                                        id="Ellipse_7706"
                                        data-name="Ellipse 7706"
                                        transform="translate(1727 19.132)"
                                        fill="#fff"
                                        stroke="#bcc1ce"
                                        stroke-width="1.2"
                                      >
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="7"
                                          stroke="none"
                                        ></circle>
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="6.4"
                                          fill="none"
                                        ></circle>
                                      </g>
                                      <path
                                        id="Path_98061"
                                        data-name="Path 98061"
                                        d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                        transform="translate(1725.126 17.539)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-miterlimit="10"
                                        stroke-width="1.2"
                                      ></path>
                                      <line
                                        id="Line_343"
                                        data-name="Line 343"
                                        y2="1.2"
                                        transform="translate(1734 29.132)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-miterlimit="10"
                                        stroke-width="1.2"
                                      ></line>
                                    </g>
                                  </svg>
                                  <h6 class="cmspostsetting-tooltipsubheader">
                                    Enable to display the author's name.
                                  </h6>
                                </h6>
                              </h4>
                            </div>
                            {
                              isWebView ?
                              <LockedToggle/>
                              :
                            <div class="on_off d-flex  ">
                              <label class="switch" for="showAuthor">
                                <input
                                  type="checkbox"
                                  id="showAuthor"
                                  checked={showAuthor}
                                  onChange={(e) => handleShowAuthorToggle(e)}
                                />
                                <div class="slider round"></div>
                              </label>
                            </div>}
                          </div>

                          <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                            <div class="d-flex justify-content-start align-items-center ">
                              <h4 class="mb-0">
                                Show published date
                                <h6 class="cmspostsetting-tooltip">
                                  <svg
                                    style={{ cursor: "pointer" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                  >
                                    <g
                                      id="Group_40296"
                                      data-name="Group 40296"
                                      transform="translate(-1727 -19.132)"
                                    >
                                      <g
                                        id="Ellipse_7706"
                                        data-name="Ellipse 7706"
                                        transform="translate(1727 19.132)"
                                        fill="#fff"
                                        stroke="#bcc1ce"
                                        stroke-width="1.2"
                                      >
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="7"
                                          stroke="none"
                                        ></circle>
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="6.4"
                                          fill="none"
                                        ></circle>
                                      </g>
                                      <path
                                        id="Path_98061"
                                        data-name="Path 98061"
                                        d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                        transform="translate(1725.126 17.539)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-miterlimit="10"
                                        stroke-width="1.2"
                                      ></path>
                                      <line
                                        id="Line_343"
                                        data-name="Line 343"
                                        y2="1.2"
                                        transform="translate(1734 29.132)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-miterlimit="10"
                                        stroke-width="1.2"
                                      ></line>
                                    </g>
                                  </svg>
                                  <h6 class="cmspostsetting-tooltipsubheader">
                                    Enable to show the publication date of the
                                    post.
                                  </h6>
                                </h6>
                              </h4>
                            </div>
                            {
                              isWebView ?
                              <LockedToggle/>
                              :
                            <div class="on_off d-flex  ">
                              <label class="switch" for="showPublishDate">
                                <input
                                  type="checkbox"
                                  id="showPublishDate"
                                  checked={showPublishDate}
                                  onChange={(e) => handleShowPublishDate(e)}
                                />
                                <div class="slider round"></div>
                              </label>
                            </div>}
                          </div>

                          <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                            <div class="d-flex justify-content-start align-items-center ">
                              <h4 class="mb-0">
                                Show categories
                                <h6 class="cmspostsetting-tooltip">
                                  <svg
                                    style={{ cursor: "pointer" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                  >
                                    <g
                                      id="Group_40296"
                                      data-name="Group 40296"
                                      transform="translate(-1727 -19.132)"
                                    >
                                      <g
                                        id="Ellipse_7706"
                                        data-name="Ellipse 7706"
                                        transform="translate(1727 19.132)"
                                        fill="#fff"
                                        stroke="#bcc1ce"
                                        stroke-width="1.2"
                                      >
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="7"
                                          stroke="none"
                                        ></circle>
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="6.4"
                                          fill="none"
                                        ></circle>
                                      </g>
                                      <path
                                        id="Path_98061"
                                        data-name="Path 98061"
                                        d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                        transform="translate(1725.126 17.539)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-miterlimit="10"
                                        stroke-width="1.2"
                                      ></path>
                                      <line
                                        id="Line_343"
                                        data-name="Line 343"
                                        y2="1.2"
                                        transform="translate(1734 29.132)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-miterlimit="10"
                                        stroke-width="1.2"
                                      ></line>
                                    </g>
                                  </svg>
                                  <h6 class="cmspostsetting-tooltipsubheader">
                                    Enable to display post categories.
                                  </h6>
                                </h6>
                              </h4>
                            </div>
                            {
                              isWebView ?
                              <LockedToggle/>
                              :
                            <div class="on_off d-flex  ">
                              <label class="switch" for="showCategories">
                                <input
                                  type="checkbox"
                                  id="showCategories"
                                  checked={showCategories}
                                  onChange={(e) => handleShowCatToggle(e)}
                                />
                                <div class="slider round"></div>
                              </label>
                            </div>
}
                          </div>

                          {parseInt(
                            localStorage.getItem("website_technology")
                          ) === 3 && (
                            <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                              <div class="d-flex justify-content-start align-items-center ">
                                <h4 class="mb-0">
                                  Show attributes
                                  <h6 class="cmspostsetting-tooltip">
                                    <svg
                                      style={{ cursor: "pointer" }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                    >
                                      <g
                                        id="Group_40296"
                                        data-name="Group 40296"
                                        transform="translate(-1727 -19.132)"
                                      >
                                        <g
                                          id="Ellipse_7706"
                                          data-name="Ellipse 7706"
                                          transform="translate(1727 19.132)"
                                          fill="#fff"
                                          stroke="#bcc1ce"
                                          stroke-width="1.2"
                                        >
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="7"
                                            stroke="none"
                                          ></circle>
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="6.4"
                                            fill="none"
                                          ></circle>
                                        </g>
                                        <path
                                          id="Path_98061"
                                          data-name="Path 98061"
                                          d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                          transform="translate(1725.126 17.539)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></path>
                                        <line
                                          id="Line_343"
                                          data-name="Line 343"
                                          y2="1.2"
                                          transform="translate(1734 29.132)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></line>
                                      </g>
                                    </svg>
                                    <h6 class="cmspostsetting-tooltipsubheader">
                                      Enable to display post attributes.
                                      Attributes offer a way to add specific
                                      characteristics or properties to your
                                      post, enhancing its depth and relevance.
                                    </h6>
                                  </h6>
                                </h4>
                              </div>
                              {
                              isWebView ?
                              <LockedToggle/>
                              :
                              <div class="on_off d-flex  ">
                                <label class="switch" for="showAttribute">
                                  <input
                                    type="checkbox"
                                    id="showAttribute"
                                    onChange={(e) => handleShowAtrToggle(e)}
                                    checked={showAttribute}
                                  />
                                  <div class="slider round"></div>
                                </label>
                              </div>}
                            </div>
                          )}
                          {
                            parseInt(
                              localStorage.getItem("website_technology")
                            ) === 3 && (
                              <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                                <div class="d-flex justify-content-start align-items-center ">
                                  <h4 class="mb-0">
                                    Show attribute values as list
                                    <h6 class="cmspostsetting-tooltip">
                                      <svg
                                        style={{ cursor: "pointer" }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                      >
                                        <g
                                          id="Group_40296"
                                          data-name="Group 40296"
                                          transform="translate(-1727 -19.132)"
                                        >
                                          <g
                                            id="Ellipse_7706"
                                            data-name="Ellipse 7706"
                                            transform="translate(1727 19.132)"
                                            fill="#fff"
                                            stroke="#bcc1ce"
                                            stroke-width="1.2"
                                          >
                                            <circle
                                              cx="7"
                                              cy="7"
                                              r="7"
                                              stroke="none"
                                            ></circle>
                                            <circle
                                              cx="7"
                                              cy="7"
                                              r="6.4"
                                              fill="none"
                                            ></circle>
                                          </g>
                                          <path
                                            id="Path_98061"
                                            data-name="Path 98061"
                                            d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                            transform="translate(1725.126 17.539)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-miterlimit="10"
                                            stroke-width="1.2"
                                          ></path>
                                          <line
                                            id="Line_343"
                                            data-name="Line 343"
                                            y2="1.2"
                                            transform="translate(1734 29.132)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-miterlimit="10"
                                            stroke-width="1.2"
                                          ></line>
                                        </g>
                                      </svg>
                                      <h6 class="cmspostsetting-tooltipsubheader">
                                        Enable to display attribute values as a
                                        list instead of separated by commas.
                                      </h6>
                                    </h6>
                                  </h4>
                                </div>
                                {
                              isWebView || !showAttribute ?
                              <LockedToggle/>
                              :
                                <div class="on_off d-flex  ">
                                  <label
                                    class="switch"
                                    for="showAttributeAsaList"
                                  >
                                    <input
                                      type="checkbox"
                                      id="showAttributeAsaList"
                                      onChange={(e) =>
                                        handleShowAtrAsaListToggle(e)
                                      }
                                      checked={showAttributeAsaList}
                                    />
                                    <div class="slider round"></div>
                                  </label>
                                </div>}
                              </div>
                            )}

                          <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                            <div class="d-flex justify-content-start align-items-center ">
                              <h4 class="mb-0">
                                Show tags
                                {showAttribute &&
                                parseInt(
                                  localStorage.getItem("website_technology")
                                ) === 3 ? (
                                  <h6 class="cmspostsetting-tooltip">
                                    <svg
                                      style={{ cursor: "pointer" }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                    >
                                      <g
                                        id="Group_40296"
                                        data-name="Group 40296"
                                        transform="translate(-1727 -19.132)"
                                      >
                                        <g
                                          id="Ellipse_7706"
                                          data-name="Ellipse 7706"
                                          transform="translate(1727 19.132)"
                                          fill="#fff"
                                          stroke="#bcc1ce"
                                          stroke-width="1.2"
                                        >
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="7"
                                            stroke="none"
                                          ></circle>
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="6.4"
                                            fill="none"
                                          ></circle>
                                        </g>
                                        <path
                                          id="Path_98061"
                                          data-name="Path 98061"
                                          d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                          transform="translate(1725.126 17.539)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></path>
                                        <line
                                          id="Line_343"
                                          data-name="Line 343"
                                          y2="1.2"
                                          transform="translate(1734 29.132)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></line>
                                      </g>
                                    </svg>
                                    <h6 class="cmspostsetting-tooltipsubheader">
                                      Enable to display tags associated with the
                                      post.Tags help organize and categorize
                                      your content for better user experience.
                                    </h6>
                                  </h6>
                                ) : (
                                  <h6 class="cmspostsetting-tooltip">
                                    <svg
                                      style={{ cursor: "pointer" }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                    >
                                      <g
                                        id="Group_40296"
                                        data-name="Group 40296"
                                        transform="translate(-1727 -19.132)"
                                      >
                                        <g
                                          id="Ellipse_7706"
                                          data-name="Ellipse 7706"
                                          transform="translate(1727 19.132)"
                                          fill="#fff"
                                          stroke="#bcc1ce"
                                          stroke-width="1.2"
                                        >
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="7"
                                            stroke="none"
                                          ></circle>
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="6.4"
                                            fill="none"
                                          ></circle>
                                        </g>
                                        <path
                                          id="Path_98061"
                                          data-name="Path 98061"
                                          d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                          transform="translate(1725.126 17.539)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></path>
                                        <line
                                          id="Line_343"
                                          data-name="Line 343"
                                          y2="1.2"
                                          transform="translate(1734 29.132)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></line>
                                      </g>
                                    </svg>
                                    <h6 class="cmspostsetting-tooltipsubheader">
                                      Enable to display tags associated with the
                                      post.Tags help organize your content for
                                      better user experience.
                                    </h6>
                                  </h6>
                                )}
                              </h4>
                            </div>
                            {
                              isWebView ?
                              <LockedToggle/>
                              :
                            <div class="on_off d-flex  ">
                              <label class="switch" for="showTags">
                                <input
                                  type="checkbox"
                                  id="showTags"
                                  checked={showTags}
                                  onChange={(e) => handleShowTagToggle(e)}
                                />
                                <div class="slider round"></div>
                              </label>
                            </div>}
                          </div>

                          {parseInt(
                            localStorage.getItem("website_technology")
                          ) !== 3 && (
                            <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                              <div class="d-flex justify-content-start align-items-center ">
                                <h4 class="mb-0">
                                  Show additional information
                                  <h6 class="cmspostsetting-tooltip">
                                    <svg
                                      style={{ cursor: "pointer" }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                    >
                                      <g
                                        id="Group_40296"
                                        data-name="Group 40296"
                                        transform="translate(-1727 -19.132)"
                                      >
                                        <g
                                          id="Ellipse_7706"
                                          data-name="Ellipse 7706"
                                          transform="translate(1727 19.132)"
                                          fill="#fff"
                                          stroke="#bcc1ce"
                                          stroke-width="1.2"
                                        >
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="7"
                                            stroke="none"
                                          ></circle>
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="6.4"
                                            fill="none"
                                          ></circle>
                                        </g>
                                        <path
                                          id="Path_98061"
                                          data-name="Path 98061"
                                          d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                          transform="translate(1725.126 17.539)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></path>
                                        <line
                                          id="Line_343"
                                          data-name="Line 343"
                                          y2="1.2"
                                          transform="translate(1734 29.132)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></line>
                                      </g>
                                    </svg>
                                    <h6 class="cmspostsetting-tooltipsubheader">
                                      Enable to showcase the attributes and
                                      associated details pertaining to the post.
                                    </h6>
                                  </h6>
                                </h4>
                              </div>
                              {
                              isWebView ?
                              <LockedToggle/>
                              :
                              <div class="on_off d-flex  ">
                                <label class="switch" for="showAddInfo">
                                  <input
                                    type="checkbox"
                                    id="showAddInfo"
                                    checked={showAddInfo}
                                    onChange={(e) => {
                                      setShowAddInfo(e.target.checked);
                                      setActiveSlide(1);
                                    }}
                                  />
                                  <div class="slider round"></div>
                                </label>
                              </div>
}
                            </div>
                          )}

                         <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                            <div class="d-flex justify-content-start align-items-center ">
                              <h4 class="mb-0">
                              Show media in full screen
                                <h6 class="cmspostsetting-tooltip">
                                  <svg
                                    style={{ cursor: "pointer" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                  >
                                    <g
                                      id="Group_40296"
                                      data-name="Group 40296"
                                      transform="translate(-1727 -19.132)"
                                    >
                                      <g
                                        id="Ellipse_7706"
                                        data-name="Ellipse 7706"
                                        transform="translate(1727 19.132)"
                                        fill="#fff"
                                        stroke="#bcc1ce"
                                        stroke-width="1.2"
                                      >
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="7"
                                          stroke="none"
                                        ></circle>
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="6.4"
                                          fill="none"
                                        ></circle>
                                      </g>
                                      <path
                                        id="Path_98061"
                                        data-name="Path 98061"
                                        d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                        transform="translate(1725.126 17.539)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-miterlimit="10"
                                        stroke-width="1.2"
                                      ></path>
                                      <line
                                        id="Line_343"
                                        data-name="Line 343"
                                        y2="1.2"
                                        transform="translate(1734 29.132)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-miterlimit="10"
                                        stroke-width="1.2"
                                      ></line>
                                    </g>
                                  </svg>
                                  <h6 class="cmspostsetting-tooltipsubheader">
                                  Enable to display a full-screen expanded view of media upon tapping.
                                  </h6>
                                </h6>
                                {/* <Newfeatureicon/> */}
                              </h4>
                            </div>
                            {
                              isWebView ?
                              <LockedToggle/>
                              :
                            <div class="on_off d-flex  ">
                              <label class="switch" for="showMediaInFullScreenOnDetails">
                                <input
                                  type="checkbox"
                                  id="showMediaInFullScreenOnDetails"
                                  checked={postState.settings.showMediaInFullScreenOnDetails}
                                  onChange={() =>{
                                    handleSettingsToggles("showMediaInFullScreenOnDetails")
                                  }}
                                />
                                <div class="slider round"></div>
                              </label>
                            </div>}
                          </div>

                          {parseInt(localStorage.getItem("website_technology")) === 3  &&
                          <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                            <div class="d-flex justify-content-start align-items-center ">
                              <h4 class="mb-0">
                              Show media as thumbnails
                                <h6 class="cmspostsetting-tooltip">
                                  <svg
                                    style={{ cursor: "pointer" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                  >
                                    <g
                                      id="Group_40296"
                                      data-name="Group 40296"
                                      transform="translate(-1727 -19.132)"
                                    >
                                      <g
                                        id="Ellipse_7706"
                                        data-name="Ellipse 7706"
                                        transform="translate(1727 19.132)"
                                        fill="#fff"
                                        stroke="#bcc1ce"
                                        stroke-width="1.2"
                                      >
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="7"
                                          stroke="none"
                                        ></circle>
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="6.4"
                                          fill="none"
                                        ></circle>
                                      </g>
                                      <path
                                        id="Path_98061"
                                        data-name="Path 98061"
                                        d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                        transform="translate(1725.126 17.539)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-miterlimit="10"
                                        stroke-width="1.2"
                                      ></path>
                                      <line
                                        id="Line_343"
                                        data-name="Line 343"
                                        y2="1.2"
                                        transform="translate(1734 29.132)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-miterlimit="10"
                                        stroke-width="1.2"
                                      ></line>
                                    </g>
                                  </svg>
                                  <h6 class="cmspostsetting-tooltipsubheader">
                                  Enable to display media content as thumbnails.
                                  </h6>
                                </h6>
                                {/* <Newfeatureicon/> */}
                              </h4>
                            </div>
                            {
                              isWebView || !postState.settings.showMediaInFullScreenOnDetails ?
                              <LockedToggle/>
                              :
                            <div class="on_off d-flex  ">
                              <label class="switch" for="showMediaAsThumbnailOnDetails">
                                <input
                                  type="checkbox"
                                  id="showMediaAsThumbnailOnDetails"
                                  checked={postState.settings.showMediaAsThumbnailOnDetails}
                                  onChange={() =>{
                                    handleSettingsToggles("showMediaAsThumbnailOnDetails")
                                  }}
                                />
                                <div class="slider round"></div>
                              </label>
                            </div>}
                          </div>
                          }

                          <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                            <div class="d-flex justify-content-start align-items-center ">
                              <h4 class="mb-0">
                                Show related posts
                                {parseInt(
                                  localStorage.getItem("website_technology")
                                ) === 3 ? (
                                  <h6 class="cmspostsetting-tooltip">
                                    <svg
                                      style={{ cursor: "pointer" }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                    >
                                      <g
                                        id="Group_40296"
                                        data-name="Group 40296"
                                        transform="translate(-1727 -19.132)"
                                      >
                                        <g
                                          id="Ellipse_7706"
                                          data-name="Ellipse 7706"
                                          transform="translate(1727 19.132)"
                                          fill="#fff"
                                          stroke="#bcc1ce"
                                          stroke-width="1.2"
                                        >
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="7"
                                            stroke="none"
                                          ></circle>
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="6.4"
                                            fill="none"
                                          ></circle>
                                        </g>
                                        <path
                                          id="Path_98061"
                                          data-name="Path 98061"
                                          d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                          transform="translate(1725.126 17.539)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></path>
                                        <line
                                          id="Line_343"
                                          data-name="Line 343"
                                          y2="1.2"
                                          transform="translate(1734 29.132)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></line>
                                      </g>
                                    </svg>
                                    <h6 class="cmspostsetting-tooltipsubheader">
                                      Enable to display related posts.
                                    </h6>
                                  </h6>
                                ) : (
                                  <h6 class="cmspostsetting-tooltip">
                                    <svg
                                      style={{ cursor: "pointer" }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                    >
                                      <g
                                        id="Group_40296"
                                        data-name="Group 40296"
                                        transform="translate(-1727 -19.132)"
                                      >
                                        <g
                                          id="Ellipse_7706"
                                          data-name="Ellipse 7706"
                                          transform="translate(1727 19.132)"
                                          fill="#fff"
                                          stroke="#bcc1ce"
                                          stroke-width="1.2"
                                        >
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="7"
                                            stroke="none"
                                          ></circle>
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="6.4"
                                            fill="none"
                                          ></circle>
                                        </g>
                                        <path
                                          id="Path_98061"
                                          data-name="Path 98061"
                                          d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                          transform="translate(1725.126 17.539)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></path>
                                        <line
                                          id="Line_343"
                                          data-name="Line 343"
                                          y2="1.2"
                                          transform="translate(1734 29.132)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></line>
                                      </g>
                                    </svg>
                                    <h6 class="cmspostsetting-tooltipsubheader">
                                      Enable to showcase similar posts beneath
                                      the current post, providing app users with
                                      additional content of interest.
                                    </h6>
                                  </h6>
                                )}
                              </h4>
                            </div>
                            {
                              isWebView ?
                              <LockedToggle/>
                              :
                            <div class="on_off d-flex  ">
                              <label class="switch" for="showRelatedPosts">
                                <input
                                  type="checkbox"
                                  id="showRelatedPosts"
                                  checked={showRelatedPosts}
                                  onChange={(e) => handleShowRelatedPosts(e)}
                                />
                                <div class="slider round"></div>
                              </label>
                            </div>}
                          </div>


                            <h6 className="mt-20" style={{ color: "#1b2952" }}>
                              Filter related posts by
                              <h5 class="cmspostsetting-tooltip">
                                <svg
                                  style={{ cursor: "pointer" }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                >
                                  <g
                                    id="Group_40296"
                                    data-name="Group 40296"
                                    transform="translate(-1727 -19.132)"
                                  >
                                    <g
                                      id="Ellipse_7706"
                                      data-name="Ellipse 7706"
                                      transform="translate(1727 19.132)"
                                      fill="#fff"
                                      stroke="#bcc1ce"
                                      stroke-width="1.2"
                                    >
                                      <circle
                                        cx="7"
                                        cy="7"
                                        r="7"
                                        stroke="none"
                                      ></circle>
                                      <circle
                                        cx="7"
                                        cy="7"
                                        r="6.4"
                                        fill="none"
                                      ></circle>
                                    </g>
                                    <path
                                      id="Path_98061"
                                      data-name="Path 98061"
                                      d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                      transform="translate(1725.126 17.539)"
                                      fill="none"
                                      stroke="#bcc1ce"
                                      stroke-miterlimit="10"
                                      stroke-width="1.2"
                                    ></path>
                                    <line
                                      id="Line_343"
                                      data-name="Line 343"
                                      y2="1.2"
                                      transform="translate(1734 29.132)"
                                      fill="none"
                                      stroke="#bcc1ce"
                                      stroke-miterlimit="10"
                                      stroke-width="1.2"
                                    ></line>
                                  </g>
                                </svg>
                                <h5
                                  style={{ top: "2px" }}
                                  class="cmspostsetting-tooltipsubheader"
                                >
                                  Choose a filter option from the dropdown menu
                                  to refine the related posts displayed.
                                </h5>
                              </h5>
                            </h6>

                            <div className={`categogy-chip-section ${isWebView || !showRelatedPosts ? "filter-related-post-disable":""}`}>
                              <Dropdown
                                className="cms-filterby-post search-dropdown"
                                isOpen={
                                  window.matchMedia("(max-width: 1200px)")
                                    .matches || isWebView || !showRelatedPosts
                                    ? false
                                    : isRelatedToPopup
                                }
                                onClick={
                                  window.matchMedia("(max-width: 1200px)")
                                    .matches && !isWebView && showRelatedPosts
                                    ? handleMobileRelatedToDropdown
                                    : null
                                }
                                toggle={handleRelatedToPopUp}
                              >
                                <DropdownToggle caret>
                                  <span
                                    className="search-dropdown-before"
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {postRelatedTo ? postRelatedTo : "Select"}
                                  </span>
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem
                                    onClick={(e) =>
                                      handleRelatedToChange(e, "authors")
                                    }
                                    active={postRelatedTo === "authors"}
                                  >
                                    Authors
                                  </DropdownItem>

                                  <DropdownItem
                                    onClick={(e) =>
                                      handleRelatedToChange(e, "categories")
                                    }
                                    active={postRelatedTo === "categories"}
                                  >
                                    Categories
                                  </DropdownItem>

                                  <DropdownItem
                                    onClick={(e) =>
                                      handleRelatedToChange(e, "featured")
                                    }
                                    active={postRelatedTo === "featured"}
                                  >
                                    Featured
                                  </DropdownItem>

                                  <DropdownItem
                                    onClick={(e) =>
                                      handleRelatedToChange(e, "tags")
                                    }
                                    active={postRelatedTo === "tags"}
                                  >
                                    Tags
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </div>


                          {parseInt(
                            localStorage.getItem("website_technology")
                          ) === 3 && (
                            <>
                              <h6
                                className="mt-20"
                                style={{ color: "#1b2952" }}
                              >
                                Section margins (pixels)
                                <h5 class="cmspostsetting-tooltip">
                                  <svg
                                    style={{ cursor: "pointer" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                  >
                                    <g
                                      id="Group_40296"
                                      data-name="Group 40296"
                                      transform="translate(-1727 -19.132)"
                                    >
                                      <g
                                        id="Ellipse_7706"
                                        data-name="Ellipse 7706"
                                        transform="translate(1727 19.132)"
                                        fill="#fff"
                                        stroke="#bcc1ce"
                                        stroke-width="1.2"
                                      >
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="7"
                                          stroke="none"
                                        ></circle>
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="6.4"
                                          fill="none"
                                        ></circle>
                                      </g>
                                      <path
                                        id="Path_98061"
                                        data-name="Path 98061"
                                        d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                        transform="translate(1725.126 17.539)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-miterlimit="10"
                                        stroke-width="1.2"
                                      ></path>
                                      <line
                                        id="Line_343"
                                        data-name="Line 343"
                                        y2="1.2"
                                        transform="translate(1734 29.132)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-miterlimit="10"
                                        stroke-width="1.2"
                                      ></line>
                                    </g>
                                  </svg>
                                  <h5
                                    style={{ top: "2px" }}
                                    class="cmspostsetting-tooltipsubheader"
                                  >
                                    Adjust the spacing around your section as
                                    per your preference.
                                  </h5>
                                </h5>
                              </h6>
                              <div className="post-section-margin-top ">  
                              {/*className= "post-section-margin-disable" */}
                                <div className="post-section-margin-first">
                                  <div>
                                  <input
                                    className="post-section-margin-first-input"
                                    type="number"
                                    value={sectionTopMargin}
                                    min={0}
                                    max={30}
                                    step={0.5}
                                    onChange={(e) => handleSectionMargin(e, "top")}
                                    onBlur={(e) => handleSectionMarginOnBlur(e, "top")}
                                    onKeyDown={(e)=>handleSectionMarginOnKeyDown(e)}
                                  />
                                    <h4>Top</h4>
                                  </div>
                                  <div>
                                    <input
                                      className="post-section-margin-first-input"
                                      type="number"
                                      value={sectionBottomMargin}
                                      min={0}
                                      max={30}
                                      step={0.5}
                                      onChange={(e) => handleSectionMargin(e, "bottom")}
                                      onBlur={(e) => handleSectionMarginOnBlur(e, "bottom")}
                                      onKeyDown={(e)=>handleSectionMarginOnKeyDown(e)}
                                    />
                                    <h4>Bottom</h4>
                                  </div>
                                </div>
                                <div className="post-section-margin-second">
                                  <div>
                                    <input
                                      className="post-section-margin-socond-input"
                                      type="number"
                                      value={sectionLeftMargin}
                                      min={0}
                                      max={30}
                                      step={0.5}
                                      onChange={(e) => handleSectionMargin(e, "left")}
                                      onBlur={(e) => handleSectionMarginOnBlur(e, "left")}
                                      onKeyDown={(e)=>handleSectionMarginOnKeyDown(e)}
                                    />
                                    <h4>Left</h4>
                                  </div>
                                  <div style={{ marginRight: "0px" }}>
                                    <input
                                      className="post-section-margin-socond-input"
                                      type="number"
                                      value={sectionRightMargin}
                                      min={0}
                                      max={30}
                                      step={0.5}
                                      onChange={(e) => handleSectionMargin(e, "right")}
                                      onBlur={(e) => handleSectionMarginOnBlur(e, "right")}
                                      onKeyDown={(e)=>handleSectionMarginOnKeyDown(e)}
                                    />
                                    <h4>Right</h4>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {parseInt(
                            localStorage.getItem("website_technology")
                          ) !== 3 && (
                            <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                              <div class="d-flex justify-content-start align-items-center ">
                                <h4 class="mb-0">
                                  Image ratio
                                  <h6 class="cmspostsetting-tooltip">
                                    <svg
                                      style={{ cursor: "pointer" }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                    >
                                      <g
                                        id="Group_40296"
                                        data-name="Group 40296"
                                        transform="translate(-1727 -19.132)"
                                      >
                                        <g
                                          id="Ellipse_7706"
                                          data-name="Ellipse 7706"
                                          transform="translate(1727 19.132)"
                                          fill="#fff"
                                          stroke="#bcc1ce"
                                          stroke-width="1.2"
                                        >
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="7"
                                            stroke="none"
                                          ></circle>
                                          <circle
                                            cx="7"
                                            cy="7"
                                            r="6.4"
                                            fill="none"
                                          ></circle>
                                        </g>
                                        <path
                                          id="Path_98061"
                                          data-name="Path 98061"
                                          d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                          transform="translate(1725.126 17.539)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></path>
                                        <line
                                          id="Line_343"
                                          data-name="Line 343"
                                          y2="1.2"
                                          transform="translate(1734 29.132)"
                                          fill="none"
                                          stroke="#bcc1ce"
                                          stroke-miterlimit="10"
                                          stroke-width="1.2"
                                        ></line>
                                      </g>
                                    </svg>
                                    <h6 class="cmspostsetting-tooltipsubheader">
                                      Define the image aspect ratio for your
                                      posts. Select a suitable image ratio
                                      corresponding to your image.
                                    </h6>
                                  </h6>
                                </h4>
                              </div>
                              <div className="post-img-ratio-div">
                                  <Imageratio selectedImgRatio={imgRatioForDetails} imgRatio={"16:9"} isDisabled={isWebView} handleRatioChange={handleImgRatioForDetails} tooltipContent={"Select for a widescreen image format, ideal for panoramic views and cinematic visuals."}/>
                                  <Imageratio selectedImgRatio={imgRatioForDetails} imgRatio={"4:3"} isDisabled={isWebView} handleRatioChange={handleImgRatioForDetails} tooltipContent={"Opt to maintain a classic image aspect ratio, suitable for balanced compositions."}/>
                                  <Imageratio selectedImgRatio={imgRatioForDetails} imgRatio={"1:1"} isDisabled={isWebView} handleRatioChange={handleImgRatioForDetails} tooltipContent={"Choose for a square image format, great for symmetrical and uniform visuals."}/>
                                  <Imageratio selectedImgRatio={imgRatioForDetails} imgRatio={"3:4"} isDisabled={isWebView} handleRatioChange={handleImgRatioForDetails} tooltipContent={"Pick for a portrait-oriented image format, perfect for capturing vertical scenes and portraits."}/>
                                  <Imageratio selectedImgRatio={imgRatioForDetails} imgRatio={"9:16"} isDisabled={isWebView} handleRatioChange={handleImgRatioForDetails} tooltipContent={"Opt for a vertical image format perfect for capturing tall scenes and portrait-oriented visuals."}/>
                              </div>
                              
                            </div>
                          )}

                          <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                            <div class="d-flex justify-content-start align-items-center ">
                              <h4 class="mb-0">
                                Image shape
                                <h6 class="cmspostsetting-tooltip">
                                  <svg
                                    style={{ cursor: "pointer" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                  >
                                    <g
                                      id="Group_40296"
                                      data-name="Group 40296"
                                      transform="translate(-1727 -19.132)"
                                    >
                                      <g
                                        id="Ellipse_7706"
                                        data-name="Ellipse 7706"
                                        transform="translate(1727 19.132)"
                                        fill="#fff"
                                        stroke="#bcc1ce"
                                        stroke-width="1.2"
                                      >
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="7"
                                          stroke="none"
                                        ></circle>
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="6.4"
                                          fill="none"
                                        ></circle>
                                      </g>
                                      <path
                                        id="Path_98061"
                                        data-name="Path 98061"
                                        d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                        transform="translate(1725.126 17.539)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-miterlimit="10"
                                        stroke-width="1.2"
                                      ></path>
                                      <line
                                        id="Line_343"
                                        data-name="Line 343"
                                        y2="1.2"
                                        transform="translate(1734 29.132)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-miterlimit="10"
                                        stroke-width="1.2"
                                      ></line>
                                    </g>
                                  </svg>
                                  <h6 class="cmspostsetting-tooltipsubheader">
                                    Define the preferred shape of your post
                                    images.
                                  </h6>
                                </h6>
                              </h4>
                            </div>

                            <div className={`shape-of-post ${isWebView ?"shape-of-post-disable":" "}`}>
                              <svg
                                onClick={(e) =>
                                  !isWebView && handleImgShapeForDetails(e, "sharp_corner")
                                }
                                className={
                                  imgShapeForDetails === "sharp_corner"
                                    ? "post-shapeselect"
                                    : "post-shapenotselect"
                                }
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                              >
                                <g
                                  id="Rectangle_18739"
                                  data-name="Rectangle 18739"
                                  fill="#fff"
                                  stroke=""
                                  stroke-width="2"
                                >
                                  <rect width="18" height="18" stroke="none" />
                                  <rect
                                    x="1"
                                    y="1"
                                    width="16"
                                    height="16"
                                    fill="none"
                                  />
                                </g>
                              </svg>
                              <svg
                                onClick={(e) =>
                                  !isWebView && handleImgShapeForDetails(e, "soft_corner")
                                }
                                className={
                                  imgShapeForDetails === "soft_corner"
                                    ? "post-shapeselect"
                                    : "post-shapenotselect"
                                }
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                              >
                                <g
                                  id="Rectangle_18739"
                                  data-name="Rectangle 18739"
                                  fill="#fff"
                                  stroke=""
                                  stroke-width="2"
                                >
                                  <rect
                                    width="18"
                                    height="18"
                                    rx="5"
                                    stroke="none"
                                  />
                                  <rect
                                    x="1"
                                    y="1"
                                    width="16"
                                    height="16"
                                    rx="4"
                                    fill="none"
                                  />
                                </g>
                              </svg>

                              {/* <svg
                                               onClick={(e)=> !isWebView && handleImgShapeForDetails(e,"circle")} 
                                               className={imgShapeForDetails==="circle"?'post-shapeselect':'post-shapenotselect'}
                                              xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                        <g id="Rectangle_31721" data-name="Rectangle 31721" fill="#fff" stroke="" stroke-width="2">
                          <rect width="18" height="18" rx="9" stroke="none"/>
                          <rect x="1" y="1" width="16" height="16" rx="8" fill="none"/>
                        </g>
                      </svg> */}
                            </div>
                          </div>

                          <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                            <div class="d-flex justify-content-start align-items-center ">
                              <h4 class="mb-0">
                                Center crop images
                                <h6 class="cmspostsetting-tooltip">
                                  <svg
                                    style={{ cursor: "pointer" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                  >
                                    <g
                                      id="Group_40296"
                                      data-name="Group 40296"
                                      transform="translate(-1727 -19.132)"
                                    >
                                      <g
                                        id="Ellipse_7706"
                                        data-name="Ellipse 7706"
                                        transform="translate(1727 19.132)"
                                        fill="#fff"
                                        stroke="#bcc1ce"
                                        stroke-width="1.2"
                                      >
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="7"
                                          stroke="none"
                                        ></circle>
                                        <circle
                                          cx="7"
                                          cy="7"
                                          r="6.4"
                                          fill="none"
                                        ></circle>
                                      </g>
                                      <path
                                        id="Path_98061"
                                        data-name="Path 98061"
                                        d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                        transform="translate(1725.126 17.539)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-miterlimit="10"
                                        stroke-width="1.2"
                                      ></path>
                                      <line
                                        id="Line_343"
                                        data-name="Line 343"
                                        y2="1.2"
                                        transform="translate(1734 29.132)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-miterlimit="10"
                                        stroke-width="1.2"
                                      ></line>
                                    </g>
                                  </svg>
                                  <h6 class="cmspostsetting-tooltipsubheader">
                                    When enabled, your images will be centred
                                    within the image frame, for a consistent
                                    look. When disabled, images will be
                                    displayed to fit the frame while maintaining
                                    their original aspect ratios.
                                  </h6>
                                </h6>
                              </h4>
                            </div>
                            {
                              isWebView ?
                              <LockedToggle/>
                              :
                            <div class="on_off d-flex  ">
                              <label class="switch" for="imgCenterForDetails">
                                <input
                                  type="checkbox"
                                  id="imgCenterForDetails"
                                  checked={imgCenterForDetails}
                                  onChange={(e) => handleImgCenterForDetails(e)}
                                />
                                <div class="slider round"></div>
                              </label>
                            </div>}
                          </div>
                        </div>

                        <div className="posts-preview-list">
                          <div className="login-mob-previewtop">
                            <div
                              className={` login-mob-previewtopback   ${
                                activeSlide === 0
                                  ? "login-mob-previewtopbackopacity "
                                  : ""
                              } `}
                              onClick={() => handlesliderChange("left")}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="7.017"
                                height="11.914"
                                viewBox="0 0 7.017 11.914"
                              >
                                <path
                                  id="Path_101670"
                                  data-name="Path 101670"
                                  d="M-14099.574-20964l-5.426,5.426,5.426,5.426"
                                  transform="translate(14106.061 20964.531)"
                                  fill="none"
                                  stroke="#7782a1"
                                  stroke-width="1.5"
                                />
                              </svg>
                            </div>
                            <div
                              onClick={() => ( (activeSlide === 1 && isWebView ) || (activeSlide === 1 && !postState.settings.showMediaInFullScreenOnDetails) || activeSlide === 2 ) ? null : handlesliderChange("right")}
                              className={` login-mob-previewtopnext ${
                              ( (activeSlide === 1 && isWebView ) || (activeSlide === 1 && !postState.settings.showMediaInFullScreenOnDetails) || activeSlide === 2 )
                                  ? "login-mob-previewtopbackopacity" + ""
                                  : "mob-previewtopnextdiv"
                              } `}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="7.017"
                                height="11.914"
                                viewBox="0 0 7.017 11.914"
                              >
                                <path
                                  id="Path_101670"
                                  data-name="Path 101670"
                                  d="M-14105-20964l5.426,5.426-5.426,5.426"
                                  transform="translate(14105.53 20964.531)"
                                  fill="none"
                                  stroke="#7782a1"
                                  stroke-width="1.5"
                                />
                              </svg>
                            </div>
                          </div>

                          <div className="posts-preview-detail">
                            <Carousel
                              selectedItem={activeSlide}
                              // onChange={(e) => handlesliderChange(e)}
                              showArrows={false}
                              swipeable={true}
                              showThumbs={false}
                            >
                              
                              
                              {parseInt(
                                localStorage.getItem("website_technology")
                              ) === 3 ? (
                                //  Preview for POST LISTING in Custom App (Grid,List)

                                <div className="preview-2">
                                  <div className="post-listing-head">
                                    <div className="post-listing-head-left">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/header-back-arrow.svg")
                                            .default
                                        }
                                      />

                                      <h3 className="post-listing-heading">
                                        Post list
                                      </h3>
                                    </div>

                                    <div className="post-listing-head-right">
                                      {showSearch && (
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/search-icon.svg")
                                              .default
                                          }
                                        />
                                      )}

                                      { ( postState.settings.showBookmarkIconInHeader && postState.settings.enableBookmarkingOnList ) ? 
                                        <img
                                          className="post-list-bookmark"
                                          src={
                                            require("../../assets/images/dashboard/bookmark-icon.svg")
                                              .default
                                          }
                                        />
                                      :
                                      <></>
                                    }
                                    </div>
                                  </div>

                                  <div className="post-listing-filters">
                                    <div className="post-listing-filters-left">
                                      {postState.settings
                                        .showSortIconOnList && (
                                        <img
                                          style={{
                                            width: "16px",
                                            height: "16px",
                                          }}
                                          src={
                                            require("../../assets/images/dashboard/sort-icon.svg")
                                              .default
                                          }
                                        />
                                      )}
                                      {postState.settings
                                        .showFilterIconOnList && (
                                        <img
                                          style={{
                                            width: "16px",
                                            height: "16px",
                                          }}
                                          src={
                                            require("../../assets/images/dashboard/filter-icon.svg")
                                              .default
                                          }
                                        />
                                      )}
                                    </div>
                                    <div className="post-listing-filters-right">
                                      {postState.settings
                                        .showTotalCountOnList && postData?.length > 0 &&  (
                                        <p className="total-post-count">{postData.length}</p>
                                      )}

                                      {previewListingView === "grid" ? (
                                        <img
                                          style={{
                                            width: "16px",
                                            height: "16px",
                                          }}
                                          src={
                                            require("../../assets/images/dashboard/list-icon.svg")
                                              .default
                                          }
                                        />
                                      ) : (
                                        <img
                                          style={{
                                            width: "16px",
                                            height: "16px",
                                          }}
                                          src={
                                            require("../../assets/images/dashboard/grid-icon.svg")
                                              .default
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>

                                  {previewListingView === "grid" ? (
                                    <div className="post-listing-style-one">
                                      {postData && postData.length > 0 ? (
                                        postData.map((eachPost, indx) => {
                                          return (
                                            (eachPost.show_in_results === 1 ||
                                              eachPost.show_in_results ===
                                                undefined) && (
                                              <div className="post-list-type-one">
                                                <div
                                                  className="post-type-one-thumbnail"
                                                  style={{
                                                    height: calculateHeight(
                                                      imgRatioForListing,
                                                      135
                                                    ),
                                                    borderRadius:
                                                      imgShapeForListing ===
                                                      "soft_corner"
                                                        ? "10px"
                                                        : imgShapeForListing ===
                                                          "circle"
                                                        ? "100%"
                                                        : "0px",
                                                    position: "relative",

                                                    backgroundSize:
                                                      !eachPost.source_file
                                                        ? "auto"
                                                        : imgCenterForListing
                                                        ? "cover"
                                                        : "contain",
                                                    backgroundColor:
                                                      !eachPost.source_file
                                                        ? "#ebebeb"
                                                        : "",
                                                    backgroundImage:
                                                      "url(" +
                                                      (!eachPost.source_file
                                                        ? newDefaultImage
                                                        : eachPost.source_file.indexOf(
                                                            "https"
                                                          ) > -1
                                                        ? eachPost.source_file
                                                        : process.env
                                                            .REACT_APP_Image_Path +
                                                          localStorage.getItem(
                                                            "user_id"
                                                          ) +
                                                          "/" +
                                                          localStorage.getItem(
                                                            "Appid"
                                                          ) +
                                                          process.env
                                                            .REACT_APP_CMS_Media +
                                                          eachPost.source_file) +
                                                      ")",
                                                  }}
                                                >
                                                 {
                                                  postState.settings.enableBookmarkingOnList  ?
                                                  <div className="post-list-bookmark-div">
                                                    <img className="post-list-bookmark-img" src={greyBookmarkIcon} alt="bookmark-list-icon"/>
                                                  </div>
                                                  :
                                                  <></>
                                                 }
                                                </div>

                                                {postState.settings
                                                  .showAuthorOnList && (
                                                  <div className="post-list-author">
                                                    <div className="post-author-grid-lt">
                                                      {eachPost.author_info ? (
                                                        !eachPost.author_info
                                                          ?.profile_image ? (
                                                          <img
                                                            src={
                                                              userdefaultImgOnError
                                                            }
                                                            className="img-fluid"
                                                            alt="profile logo"
                                                          />
                                                        ) : (
                                                          <>
                                                            {eachPost.author_info?.profile_image?.indexOf(
                                                              "https"
                                                            ) === -1 ? (
                                                              <img
                                                                className="header-profil-img"
                                                                src={
                                                                  process.env
                                                                    .REACT_APP_Image_Path +
                                                                  eachPost
                                                                    .author_info
                                                                    .id +
                                                                  "/" +
                                                                  "profile" +
                                                                  "/" +
                                                                  eachPost
                                                                    .author_info
                                                                    ?.profile_image
                                                                }
                                                                onError={({
                                                                  currentTarget,
                                                                }) => {
                                                                  currentTarget.onerror =
                                                                    null;
                                                                  currentTarget.src =
                                                                    userdefaultImgOnError;
                                                                }}
                                                              />
                                                            ) : (
                                                              <img
                                                                className="header-profil-img"
                                                                src={
                                                                  eachPost
                                                                    .author_info
                                                                    ?.profile_image
                                                                }
                                                                onError={({
                                                                  currentTarget,
                                                                }) => {
                                                                  currentTarget.onerror =
                                                                    null;
                                                                  currentTarget.src =
                                                                    userdefaultImgOnError;
                                                                }}
                                                              />
                                                            )}
                                                          </>
                                                        )
                                                      ) : (
                                                        <img
                                                          src={
                                                            userdefaultImgOnError
                                                          }
                                                          className="img-fluid"
                                                          alt="profile logo"
                                                        />
                                                      )}
                                                    </div>

                                                    <div>
                                                      <h5 className="post-one-author">
                                                        {" "}
                                                        {eachPost.author_info !==
                                                          undefined &&
                                                        eachPost.author_info !==
                                                          null
                                                          ? eachPost.author_info
                                                              .name
                                                          : eachPost.owner_user
                                                          ? eachPost.owner_user
                                                              .name
                                                          : localStorage.getItem(
                                                              "user_name"
                                                            )}
                                                      </h5>
                                                    </div>
                                                  </div>
                                                )}

                                                {eachPost.title &&
                                                eachPost.title.length >= 50 ? (
                                                  <h4
                                                    className="post-one-title"
                                                    style={{
                                                      fontSize: "9px",
                                                    }}
                                                  >
                                                    {eachPost.title.substring(
                                                      0,
                                                      50
                                                    ) + "..."}
                                                  </h4>
                                                ) : (
                                                  <h4
                                                    className="post-one-title"
                                                    style={{
                                                      fontSize: "9px",
                                                    }}
                                                  >
                                                    {eachPost.title}
                                                  </h4>
                                                )}

                                                {postState.settings
                                                  .showExcerptForListing &&
                                                  eachPost.excerpt && (
                                                    <p
                                                      className="post-one-excerpt"
                                                      style={{
                                                        marginBottom: "7px",
                                                      }}
                                                    >
                                                      {eachPost.excerpt
                                                        .length >= 70
                                                        ? eachPost.excerpt.substring(
                                                            0,
                                                            70
                                                          ) + "..."
                                                        : eachPost.excerpt}
                                                    </p>
                                                  )}

                                                {showPublishDateForListing && (
                                                  <h6 className="post-one-timestamp">
                                                    {" "}
                                                    {moment(
                                                      eachPost.created_at
                                                    ).format(
                                                      "D MMMM YYYY, HH:mm"
                                                    )}
                                                    {eachPost.is_featured && (
                                                      <svg
                                                        style={{
                                                          marginLeft: "4px",
                                                          position: "relative",
                                                          top: "-2px",
                                                        }}
                                                        id="iconfinder_star_216411_2_"
                                                        data-name="iconfinder_star_216411 (2)"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="8.051"
                                                        height="7.666"
                                                        viewBox="0 0 8.051 7.666"
                                                      >
                                                        <g
                                                          id="Group_42888"
                                                          data-name="Group 42888"
                                                          transform="translate(0 0)"
                                                        >
                                                          <path
                                                            id="Path_119118"
                                                            data-name="Path 119118"
                                                            d="M6.4,6.5l-2.539.281a.243.243,0,0,0-.2.165.238.238,0,0,0,.067.251c.755.688,1.889,1.717,1.889,1.717s-.311,1.5-.518,2.5a.242.242,0,0,0,.094.243.24.24,0,0,0,.259.013c.889-.5,2.22-1.263,2.22-1.263l2.219,1.263a.24.24,0,0,0,.355-.257c-.206-1-.516-2.5-.516-2.5L11.619,7.2a.24.24,0,0,0-.135-.416L8.945,6.5,7.893,4.175a.244.244,0,0,0-.22-.141.24.24,0,0,0-.218.141Z"
                                                            transform="translate(-3.649 -4.034)"
                                                            fill="#6f6f6f"
                                                          />
                                                        </g>
                                                      </svg>
                                                    )}
                                                  </h6>
                                                )}
                                              </div>
                                            )
                                          );
                                        })
                                      ) : (
                                        <div className="nopagecreated 1">
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              padding: "0px 15px 180px",
                                            }}
                                          >
                                            <img
                                              style={{
                                                width: "unset",
                                              }}
                                              src={
                                                require("../../assets/images/dashboard/no-post-create.png")
                                                  .default
                                              }
                                            />
                                            <p
                                              style={{
                                                textAlign: "center",
                                              }}
                                            >
                                              Nothing here at the moment. Come
                                              back later?
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="post-listing-style-two">
                                      {postData && postData.length > 0 ? (
                                        postData.map((eachPost, indx) => {
                                          return (
                                            (eachPost.show_in_results === 1 ||
                                              eachPost.show_in_results ===
                                                undefined) && (
                                              <div className="post-list-type-two">
                                                <div
                                                  className="post-type-two-data"
                                                  style={{ textAlign: "left" }}
                                                >
                                                  {eachPost.title &&
                                                  eachPost.title.length >=
                                                    70 ? (
                                                    <h3
                                                      className="post-two-heading"
                                                      style={{
                                                        fontSize: "9px",
                                                      }}
                                                    >
                                                      {eachPost.title.substring(
                                                        0,
                                                        70
                                                      ) + "..."}
                                                    </h3>
                                                  ) : (
                                                    <h3
                                                      className="post-two-heading"
                                                      style={{
                                                        fontSize: "9px",
                                                      }}
                                                    >
                                                      {eachPost.title}
                                                    </h3>
                                                  )}

                                                  {postState.settings
                                                    .showExcerptForListing &&
                                                    eachPost.excerpt && (
                                                      <h5 className="post-two-excerpt">
                                                        {eachPost.excerpt
                                                          .length >= 70
                                                          ? eachPost.excerpt.substring(
                                                              0,
                                                              70
                                                            ) + "..."
                                                          : eachPost.excerpt}
                                                      </h5>
                                                    )}

                                                  <div className="post-list-author">
                                                    {postState.settings
                                                      .showAuthorOnList && (
                                                      <div className="post-author-grid-lt">
                                                        {eachPost.author_info ? (
                                                          !eachPost.author_info
                                                            ?.profile_image ? (
                                                            <img
                                                              src={
                                                                userdefaultImgOnError
                                                              }
                                                              className="img-fluid"
                                                              alt="profile logo"
                                                            />
                                                          ) : (
                                                            <>
                                                              {eachPost.author_info?.profile_image?.indexOf(
                                                                "https"
                                                              ) === -1 ? (
                                                                <img
                                                                  className="header-profil-img"
                                                                  src={
                                                                    process.env
                                                                      .REACT_APP_Image_Path +
                                                                    eachPost
                                                                      .author_info
                                                                      .id +
                                                                    "/" +
                                                                    "profile" +
                                                                    "/" +
                                                                    eachPost
                                                                      .author_info
                                                                      ?.profile_image
                                                                  }
                                                                  onError={({
                                                                    currentTarget,
                                                                  }) => {
                                                                    currentTarget.onerror =
                                                                      null;
                                                                    currentTarget.src =
                                                                      userdefaultImgOnError;
                                                                  }}
                                                                />
                                                              ) : (
                                                                <img
                                                                  className="header-profil-img"
                                                                  src={
                                                                    eachPost
                                                                      .author_info
                                                                      ?.profile_image
                                                                  }
                                                                  onError={({
                                                                    currentTarget,
                                                                  }) => {
                                                                    currentTarget.onerror =
                                                                      null;
                                                                    currentTarget.src =
                                                                      userdefaultImgOnError;
                                                                  }}
                                                                />
                                                              )}
                                                            </>
                                                          )
                                                        ) : (
                                                          <img
                                                            src={
                                                              userdefaultImgOnError
                                                            }
                                                            className="img-fluid"
                                                            alt="profile logo"
                                                          />
                                                        )}
                                                      </div>
                                                    )}

                                                    {postState.settings
                                                      .showAuthorOnList && (
                                                      <h4 className="post-two-author">
                                                        {eachPost.author_info !==
                                                          undefined &&
                                                        eachPost.author_info !==
                                                          null
                                                          ? eachPost.author_info
                                                              .name
                                                          : eachPost.owner_user
                                                          ? eachPost.owner_user
                                                              .name
                                                          : localStorage.getItem(
                                                              "user_name"
                                                            )}
                                                      </h4>
                                                    )}
                                                    {postState.settings
                                                      .showAuthorOnList && (
                                                      <div className="author-seprator"></div>
                                                    )}
                                                    {showPublishDateForListing && (
                                                      <h6 className="post-two-timestamp">
                                                        {" "}
                                                        {moment(
                                                          eachPost.created_at
                                                        ).format(
                                                          "D MMMM YYYY, HH:mm"
                                                        )}
                                                      </h6>
                                                    )}
                                                  </div>
                                                </div>
                                                <div
                                                  className="post-type-two-thumbnail"
                                                  style={{
                                                    height: calculateHeight(
                                                      imgRatioForListing,
                                                      70
                                                    ),
                                                    borderRadius:
                                                      imgShapeForListing ===
                                                      "soft_corner"
                                                        ? "10px"
                                                        : imgShapeForListing ===
                                                          "circle"
                                                        ? "100%"
                                                        : "0px",

                                                    backgroundSize:
                                                      !eachPost.source_file
                                                        ? "auto"
                                                        : imgCenterForListing
                                                        ? "cover"
                                                        : "contain",
                                                    backgroundColor:
                                                      !eachPost.source_file
                                                        ? "#ebebeb"
                                                        : "",
                                                    backgroundImage:
                                                      "url(" +
                                                      (!eachPost.source_file
                                                        ? newDefaultImage
                                                        : eachPost.source_file.indexOf(
                                                            "https"
                                                          ) > -1
                                                        ? eachPost.source_file
                                                        : process.env
                                                            .REACT_APP_Image_Path +
                                                          localStorage.getItem(
                                                            "user_id"
                                                          ) +
                                                          "/" +
                                                          localStorage.getItem(
                                                            "Appid"
                                                          ) +
                                                          process.env
                                                            .REACT_APP_CMS_Media +
                                                          eachPost.source_file) +
                                                      ")",
                                                  }}
                                                >
                                                    {
                                                  postState.settings.enableBookmarkingOnList ?
                                                 <div className="post-list-bookmark-div">
                                                    <img className="post-list-bookmark-img-list-view" src={greyBookmarkIcon} alt="bookmark-list-icon"/>
                                                  </div> 
                                                 :
                                                 <></>
                                                }
                                                </div>
                                              </div>
                                            )
                                          );
                                        })
                                      ) : (
                                        <div className="nopagecreated 2">
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              padding: "0px 15px 180px",
                                            }}
                                          >
                                            <img
                                              style={{
                                                width: "unset",
                                              }}
                                              src={
                                                require("../../assets/images/dashboard/no-post-create.png")
                                                  .default
                                              }
                                            />
                                            <p
                                              style={{
                                                textAlign: "center",
                                              }}
                                            >
                                              Nothing here at the moment. Come
                                              back later?
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                //  Preview for POST LISTING in Woo/Wp App (Grid,List)
                                <div className="preview-2">
                                  <div className="post-listing-head">
                                    <div className="post-listing-head-left">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/header-back-arrow.svg")
                                            .default
                                        }
                                      />

                                      <h3 className="post-listing-heading">
                                        Post list
                                      </h3>
                                    </div>

                                    <div className="post-listing-head-right">
                                      {showSearch && (
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/search-icon.svg")
                                              .default
                                          }
                                        />
                                      )}

                                      {( postState.settings.showBookmarkIconInHeader && postState.settings.enableBookmarkingOnList ) ?  
                                        <img
                                          className="post-list-bookmark"
                                          src={
                                            require("../../assets/images/dashboard/bookmark-icon.svg")
                                              .default
                                          }
                                        />
                                      :
                                      <></>
                                    }
                                    </div>
                                  </div>

                                  <div className="post-listing-filters">
                                    <div className="post-listing-filters-left">
                                      {postState.settings
                                        .showSortIconOnList && (
                                        <img
                                          style={{
                                            width: "16px",
                                            height: "16px",
                                          }}
                                          src={
                                            require("../../assets/images/dashboard/sort-icon.svg")
                                              .default
                                          }
                                        />
                                      )}
                                      {postState.settings
                                        .showFilterIconOnList && (
                                        <img
                                          style={{
                                            width: "16px",
                                            height: "16px",
                                          }}
                                          src={
                                            require("../../assets/images/dashboard/filter-icon.svg")
                                              .default
                                          }
                                        />
                                      )}
                                    </div>
                                    <div className="post-listing-filters-right">
                                      {postState.settings
                                        .showTotalCountOnList && (
                                        <p className="total-post-count">245</p>
                                      )}

                                      {previewListingView === "grid" ? (
                                        <img
                                          style={{
                                            width: "16px",
                                            height: "16px",
                                          }}
                                          src={
                                            require("../../assets/images/dashboard/list-icon.svg")
                                              .default
                                          }
                                        />
                                      ) : (
                                        <img
                                          style={{
                                            width: "16px",
                                            height: "16px",
                                          }}
                                          src={
                                            require("../../assets/images/dashboard/grid-icon.svg")
                                              .default
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>

                                  {previewListingView === "grid" ? (
                                    <div className="post-listing-style-one">
                                      <div className="post-list-type-one">
                                        <div
                                          className="post-type-one-thumbnail"
                                          style={{
                                            position: "relative",
                                            height: calculateHeight(
                                              imgRatioForListing,
                                              135
                                            ),
                                            borderRadius:
                                              imgShapeForListing ===
                                              "soft_corner"
                                                ? "10px"
                                                : imgShapeForListing ===
                                                  "circle"
                                                ? "100%"
                                                : "0px",
                                            // backgroundPosition: imgCenterForListing ? "": "unset",
                                            backgroundSize: imgCenterForListing
                                              ? "cover"
                                              : "contain",
                                            backgroundImage:
                                              "url(" + imagebackground3 + ")",
                                          }}
                                        >
                                          {
                                          postState.settings.enableBookmarkingOnList  ?
                                         <div className="post-list-bookmark-div">
                                            <img className="post-list-bookmark-img" src={greyBookmarkIcon} alt="bookmark-list-icon"/>
                                          </div>
                                          :
                                          <></>
                                            }
                                        </div>

                                        {postState.settings
                                          .showAuthorOnList && (
                                          <div className="post-list-author">
                                            <div
                                              style={{
                                                backgroundImage:
                                                  "url(" + gallery1 + ")",
                                              }}
                                            ></div>
                                            <div>
                                              <h5 className="post-one-author">
                                                John Smith
                                              </h5>
                                            </div>
                                          </div>
                                        )}
                                        <h4 className="post-one-title">
                                          Why is travelling so important?
                                        </h4>

                                        {postState.settings
                                          .showExcerptForListing && (
                                          <p
                                            className="post-one-excerpt"
                                            style={{
                                              marginBottom:
                                                showPublishDateForListing
                                                  ? ""
                                                  : "7px",
                                            }}
                                          >
                                            Lorem ipsum dolor sit amet,
                                            consectetur
                                          </p>
                                        )}

                                        {showPublishDateForListing && (
                                          <h6 className="post-one-timestamp">
                                            12 November 2020
                                            <svg
                                              style={{
                                                marginLeft: "4px",
                                                position: "relative",
                                                top: "-2px",
                                              }}
                                              id="iconfinder_star_216411_2_"
                                              data-name="iconfinder_star_216411 (2)"
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="8.051"
                                              height="7.666"
                                              viewBox="0 0 8.051 7.666"
                                            >
                                              <g
                                                id="Group_42888"
                                                data-name="Group 42888"
                                                transform="translate(0 0)"
                                              >
                                                <path
                                                  id="Path_119118"
                                                  data-name="Path 119118"
                                                  d="M6.4,6.5l-2.539.281a.243.243,0,0,0-.2.165.238.238,0,0,0,.067.251c.755.688,1.889,1.717,1.889,1.717s-.311,1.5-.518,2.5a.242.242,0,0,0,.094.243.24.24,0,0,0,.259.013c.889-.5,2.22-1.263,2.22-1.263l2.219,1.263a.24.24,0,0,0,.355-.257c-.206-1-.516-2.5-.516-2.5L11.619,7.2a.24.24,0,0,0-.135-.416L8.945,6.5,7.893,4.175a.244.244,0,0,0-.22-.141.24.24,0,0,0-.218.141Z"
                                                  transform="translate(-3.649 -4.034)"
                                                  fill="#6f6f6f"
                                                />
                                              </g>
                                            </svg>
                                          </h6>
                                        )}
                                      </div>
                                      <div className="post-list-type-one">
                                        <div
                                          className="post-type-one-thumbnail"
                                          style={{
                                            position: "relative",
                                            height: calculateHeight(
                                              imgRatioForListing,
                                              135
                                            ),
                                            borderRadius:
                                              imgShapeForListing ===
                                              "soft_corner"
                                                ? "10px"
                                                : imgShapeForListing ===
                                                  "circle"
                                                ? "100%"
                                                : "0px",
                                            // backgroundPosition:imgCenterForListing?"": "unset",
                                            backgroundSize: imgCenterForListing
                                              ? "cover"
                                              : "contain",
                                            backgroundImage:
                                              "url(" + gallery2 + ")",
                                          }}
                                        >
                                               {
                                                  postState.settings.enableBookmarkingOnList ?
                                                <div className="post-list-bookmark-div">
                                                    <img className="post-list-bookmark-img" src={greyBookmarkIcon} alt="bookmark-list-icon"/>
                                                  </div>
                                                  :
                                                  <></>
                                               }
                                        </div>

                                        {postState.settings
                                          .showAuthorOnList && (
                                          <div className="post-list-author">
                                            <div
                                              style={{
                                                backgroundImage:
                                                  "url(" + gallery1 + ")",
                                              }}
                                            ></div>
                                            <div>
                                              <h5 className="post-one-author">
                                                John Smith
                                              </h5>
                                            </div>
                                          </div>
                                        )}
                                        <h4 className="post-one-title">
                                          Why is travelling so important?
                                        </h4>

                                        {postState.settings
                                          .showExcerptForListing && (
                                          <p
                                            className="post-one-excerpt"
                                            style={{
                                              marginBottom:
                                                showPublishDateForListing
                                                  ? ""
                                                  : "7px",
                                            }}
                                          >
                                            Lorem ipsum dolor sit amet,
                                            consectetur
                                          </p>
                                        )}

                                        {showPublishDateForListing && (
                                          <h6 className="post-one-timestamp">
                                            12 November 2020
                                          </h6>
                                        )}
                                      </div>
                                      <div className="post-list-type-one">
                                        <div
                                          className="post-type-one-thumbnail"
                                          style={{
                                            position: "relative",
                                            height: calculateHeight(
                                              imgRatioForListing,
                                              135
                                            ),
                                            borderRadius:
                                              imgShapeForListing ===
                                              "soft_corner"
                                                ? "10px"
                                                : imgShapeForListing ===
                                                  "circle"
                                                ? "100%"
                                                : "0px",
                                            // backgroundPosition:imgCenterForListing?"": "unset",
                                            backgroundSize: imgCenterForListing
                                              ? "cover"
                                              : "contain",
                                            backgroundImage:
                                              "url(" + gallery3 + ")",
                                          }}
                                        >
                                            {
                                                  postState.settings.enableBookmarkingOnList  ?
                                           <div className="post-list-bookmark-div">
                                                    <img className="post-list-bookmark-img" src={greyBookmarkIcon} alt="bookmark-list-icon"/>
                                                  </div>
                                                  :
                                                  <></> }
                                        </div>
                                        {postState.settings
                                          .showAuthorOnList && (
                                          <div className="post-list-author">
                                            <div
                                              style={{
                                                backgroundImage:
                                                  "url(" + gallery1 + ")",
                                              }}
                                            ></div>
                                            <div>
                                              <h5 className="post-one-author">
                                                John Smith
                                              </h5>
                                            </div>
                                          </div>
                                        )}
                                        <h4 className="post-one-title">
                                          Why is travelling so important?
                                        </h4>

                                        {postState.settings
                                          .showExcerptForListing && (
                                          <p
                                            className="post-one-excerpt"
                                            style={{
                                              marginBottom:
                                                showPublishDateForListing
                                                  ? ""
                                                  : "7px",
                                            }}
                                          >
                                            Lorem ipsum dolor sit amet,
                                            consectetur
                                          </p>
                                        )}

                                        {showPublishDateForListing && (
                                          <h6 className="post-one-timestamp">
                                            12 November 2020
                                          </h6>
                                        )}
                                      </div>
                                      <div className="post-list-type-one">
                                        <div
                                          className="post-type-one-thumbnail"
                                          style={{
                                            position: "relative",
                                            height: calculateHeight(
                                              imgRatioForListing,
                                              135
                                            ),
                                            borderRadius:
                                              imgShapeForListing ===
                                              "soft_corner"
                                                ? "10px"
                                                : imgShapeForListing ===
                                                  "circle"
                                                ? "100%"
                                                : "0px",
                                            // backgroundPosition:imgCenterForListing?"": "unset",
                                            backgroundSize: imgCenterForListing
                                              ? "cover"
                                              : "contain",
                                            backgroundImage:
                                              "url(" + gallery4 + ")",
                                          }}
                                        >  {
                                          postState.settings.enableBookmarkingOnList  ?
                                           <div className="post-list-bookmark-div">
                                                    <img className="post-list-bookmark-img" src={greyBookmarkIcon} alt="bookmark-list-icon"/>
                                                  </div>
                                                   :
                                                   <></>
                                                  }
                                          {/* <img
                                    className="img-fluid"
                                    style={{
                                      position:'absolute',
                                      top:"10px",
                                      right:"10px"
                                    }}
                                    src={
                                      require("../../assets/images/dashboard/cms/yellow-star.png")
                                        .default
                                    }
                                  /> */}
                                        </div>

                                        {postState.settings
                                          .showAuthorOnList && (
                                          <div className="post-list-author">
                                            <div
                                              style={{
                                                backgroundImage:
                                                  "url(" + gallery1 + ")",
                                              }}
                                            ></div>
                                            <div>
                                              <h5 className="post-one-author">
                                                John Smith
                                              </h5>
                                            </div>
                                          </div>
                                        )}
                                        <h4 className="post-one-title">
                                          Why is travelling so important?
                                        </h4>

                                        {postState.settings
                                          .showExcerptForListing && (
                                          <p
                                            className="post-one-excerpt"
                                            style={{
                                              marginBottom:
                                                showPublishDateForListing
                                                  ? ""
                                                  : "7px",
                                            }}
                                          >
                                            Lorem ipsum dolor sit amet,
                                            consectetur
                                          </p>
                                        )}

                                        {showPublishDateForListing && (
                                          <h6 className="post-one-timestamp">
                                            12 November 2020
                                            <svg
                                              style={{
                                                marginLeft: "6.2px",
                                                position: "relative",
                                                top: "-2px",
                                              }}
                                              id="iconfinder_star_216411_2_"
                                              data-name="iconfinder_star_216411 (2)"
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="8.051"
                                              height="7.666"
                                              viewBox="0 0 8.051 7.666"
                                            >
                                              <g
                                                id="Group_42888"
                                                data-name="Group 42888"
                                                transform="translate(0 0)"
                                              >
                                                <path
                                                  id="Path_119118"
                                                  data-name="Path 119118"
                                                  d="M6.4,6.5l-2.539.281a.243.243,0,0,0-.2.165.238.238,0,0,0,.067.251c.755.688,1.889,1.717,1.889,1.717s-.311,1.5-.518,2.5a.242.242,0,0,0,.094.243.24.24,0,0,0,.259.013c.889-.5,2.22-1.263,2.22-1.263l2.219,1.263a.24.24,0,0,0,.355-.257c-.206-1-.516-2.5-.516-2.5L11.619,7.2a.24.24,0,0,0-.135-.416L8.945,6.5,7.893,4.175a.244.244,0,0,0-.22-.141.24.24,0,0,0-.218.141Z"
                                                  transform="translate(-3.649 -4.034)"
                                                  fill="#6f6f6f"
                                                />
                                              </g>
                                            </svg>
                                          </h6>
                                        )}
                                      </div>
                                      <div className="post-list-type-one">
                                        <div
                                          className="post-type-one-thumbnail"
                                          style={{
                                            position: "relative",
                                            height: calculateHeight(
                                              imgRatioForListing,
                                              135
                                            ),
                                            borderRadius:
                                              imgShapeForListing ===
                                              "soft_corner"
                                                ? "10px"
                                                : imgShapeForListing ===
                                                  "circle"
                                                ? "100%"
                                                : "0px",
                                            // backgroundPosition:imgCenterForListing?"": "unset",
                                            backgroundSize: imgCenterForListing
                                              ? "cover"
                                              : "contain",
                                            backgroundImage:
                                              "url(" + gallery1 + ")",
                                          }}
                                        >  {
                                          postState.settings.enableBookmarkingOnList ?
                                           <div className="post-list-bookmark-div">
                                                    <img className="post-list-bookmark-img" src={greyBookmarkIcon} alt="bookmark-list-icon"/>
                                                  </div>
                                                   :
                                                   <></>
                                                  }
                                        </div>

                                        {postState.settings
                                          .showAuthorOnList && (
                                          <div className="post-list-author">
                                            <div
                                              style={{
                                                backgroundImage:
                                                  "url(" + gallery1 + ")",
                                              }}
                                            ></div>
                                            <div>
                                              <h5 className="post-one-author">
                                                John Smith
                                              </h5>
                                            </div>
                                          </div>
                                        )}
                                        <h4 className="post-one-title">
                                          Why is travelling so important?
                                        </h4>

                                        {postState.settings
                                          .showExcerptForListing && (
                                          <p
                                            className="post-one-excerpt"
                                            style={{
                                              marginBottom:
                                                showPublishDateForListing
                                                  ? ""
                                                  : "7px",
                                            }}
                                          >
                                            Lorem ipsum dolor sit amet,
                                            consectetur
                                          </p>
                                        )}

                                        {showPublishDateForListing && (
                                          <h6 className="post-one-timestamp">
                                            12 November 2020
                                          </h6>
                                        )}
                                      </div>
                                      <div className="post-list-type-one">
                                        <div
                                          className="post-type-one-thumbnail"
                                          style={{
                                            position: "relative",
                                            height: calculateHeight(
                                              imgRatioForListing,
                                              135
                                            ),
                                            borderRadius:
                                              imgShapeForListing ===
                                              "soft_corner"
                                                ? "10px"
                                                : imgShapeForListing ===
                                                  "circle"
                                                ? "100%"
                                                : "0px",
                                            // backgroundPosition:imgCenterForListing?"": "unset",
                                            backgroundSize: imgCenterForListing
                                              ? "cover"
                                              : "contain",
                                            backgroundImage:
                                              "url(" + imagebackground3 + ")",
                                          }}
                                        >  {
                                          postState.settings.enableBookmarkingOnList ?
                                           <div className="post-list-bookmark-div">
                                                    <img className="post-list-bookmark-img" src={greyBookmarkIcon} alt="bookmark-list-icon"/>
                                                  </div>
                                                   :
                                                   <></>
                                                  }
                                        </div>

                                        {postState.settings
                                          .showAuthorOnList && (
                                          <div className="post-list-author">
                                            <div
                                              style={{
                                                backgroundImage:
                                                  "url(" + gallery1 + ")",
                                              }}
                                            ></div>
                                            <div>
                                              <h5 className="post-one-author">
                                                John Smith
                                              </h5>
                                            </div>
                                          </div>
                                        )}
                                        <h4 className="post-one-title">
                                          Why is travelling so important?
                                        </h4>

                                        {postState.settings
                                          .showExcerptForListing && (
                                          <p
                                            className="post-one-excerpt"
                                            style={{
                                              marginBottom:
                                                showPublishDateForListing
                                                  ? ""
                                                  : "7px",
                                            }}
                                          >
                                            Lorem ipsum dolor sit amet,
                                            consectetur
                                          </p>
                                        )}

                                        {showPublishDateForListing && (
                                          <h6 className="post-one-timestamp">
                                            12 November 2020
                                            <svg
                                              style={{
                                                marginLeft: "6.2px",
                                                position: "relative",
                                                top: "-2px",
                                              }}
                                              id="iconfinder_star_216411_2_"
                                              data-name="iconfinder_star_216411 (2)"
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="8.051"
                                              height="7.666"
                                              viewBox="0 0 8.051 7.666"
                                            >
                                              <g
                                                id="Group_42888"
                                                data-name="Group 42888"
                                                transform="translate(0 0)"
                                              >
                                                <path
                                                  id="Path_119118"
                                                  data-name="Path 119118"
                                                  d="M6.4,6.5l-2.539.281a.243.243,0,0,0-.2.165.238.238,0,0,0,.067.251c.755.688,1.889,1.717,1.889,1.717s-.311,1.5-.518,2.5a.242.242,0,0,0,.094.243.24.24,0,0,0,.259.013c.889-.5,2.22-1.263,2.22-1.263l2.219,1.263a.24.24,0,0,0,.355-.257c-.206-1-.516-2.5-.516-2.5L11.619,7.2a.24.24,0,0,0-.135-.416L8.945,6.5,7.893,4.175a.244.244,0,0,0-.22-.141.24.24,0,0,0-.218.141Z"
                                                  transform="translate(-3.649 -4.034)"
                                                  fill="#6f6f6f"
                                                />
                                              </g>
                                            </svg>
                                          </h6>
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="post-listing-style-two">
                                      <div className="post-list-type-two">
                                        <div
                                          className="post-type-two-data"
                                          style={{ textAlign: "left" }}
                                        >
                                          <h3
                                            className="post-two-heading"
                                            style={{
                                              fontSize: "9px",
                                              marginBottom:
                                                showPublishDateForListing
                                                  ? ""
                                                  : "7px",
                                            }}
                                          >
                                            Please don't use circular
                                            dependencies in spring boot projects
                                          </h3>

                                          {postState.settings
                                            .showExcerptForListing && (
                                            <h5 className="post-two-excerpt">
                                              Yellow light wash 5-pocket
                                              mid-rise cropped jeans, clean look
                                              with no fade, has a button and zip
                                              closure, waistband with belt
                                              loops...
                                            </h5>
                                          )}

                                          <div className="post-list-author">
                                            {postState.settings
                                              .showAuthorOnList && (
                                              <div
                                                style={{
                                                  backgroundImage:
                                                    "url(" + gallery1 + ")",
                                                }}
                                              ></div>
                                            )}

                                            {postState.settings
                                              .showAuthorOnList && (
                                              <h4 className="post-two-author">
                                                John Smith
                                              </h4>
                                            )}
                                            {postState.settings
                                              .showAuthorOnList && (
                                              <div className="author-seprator"></div>
                                            )}
                                            {showPublishDateForListing && (
                                              <h6 className="post-two-timestamp">
                                                12 Jun 2022
                                              </h6>
                                            )}
                                          </div>
                                        </div>
                                        <div
                                          className="post-type-two-thumbnail"
                                          style={{
                                            height: calculateHeight(
                                              imgRatioForListing,
                                              70
                                            ),
                                            borderRadius:
                                              imgShapeForListing ===
                                              "soft_corner"
                                                ? "10px"
                                                : imgShapeForListing ===
                                                  "circle"
                                                ? "100%"
                                                : "0px",
                                            // backgroundPosition:imgCenterForListing?"": "unset",
                                            backgroundSize: imgCenterForListing
                                              ? "cover"
                                              : "contain",
                                            backgroundImage:
                                              "url(" + imagebackground3 + ")",
                                          }}
                                        >  {
                                          postState.settings.enableBookmarkingOnList ?
                                          <div className="post-list-bookmark-div">
                                                    <img className="post-list-bookmark-img-list-view" src={greyBookmarkIcon} alt="bookmark-list-icon"/>
                                                  </div> 
                                                   :
                                                   <></>
                                                  }
                                        </div>
                                      </div>
                                      <div className="post-list-type-two">
                                        <div
                                          className="post-type-two-data"
                                          style={{ textAlign: "left" }}
                                        >
                                          <h3
                                            className="post-two-heading"
                                            style={{
                                              fontSize: "9px",
                                              marginBottom:
                                                showPublishDateForListing
                                                  ? ""
                                                  : "7px",
                                            }}
                                          >
                                            Inspiration for new projectscan be
                                            found
                                          </h3>
                                          {postState.settings
                                            .showExcerptForListing && (
                                            <h5 className="post-two-excerpt">
                                              Yellow light wash 5-pocket
                                              mid-rise cropped jeans, clean look
                                              with no fade, has a button and zip
                                              closure, waistband with belt
                                              loops...
                                            </h5>
                                          )}

                                          <div className="post-list-author">
                                            {postState.settings
                                              .showAuthorOnList && (
                                              <div
                                                style={{
                                                  backgroundImage:
                                                    "url(" + gallery1 + ")",
                                                }}
                                              ></div>
                                            )}

                                            {postState.settings
                                              .showAuthorOnList && (
                                              <h4 className="post-two-author">
                                                John Smith
                                              </h4>
                                            )}
                                            {postState.settings
                                              .showAuthorOnList && (
                                              <div className="author-seprator"></div>
                                            )}
                                            {showPublishDateForListing && (
                                              <h6 className="post-two-timestamp">
                                                12 Jun 2022
                                              </h6>
                                            )}
                                          </div>
                                        </div>
                                        <div
                                          className="post-type-two-thumbnail"
                                          style={{
                                            height: calculateHeight(
                                              imgRatioForListing,
                                              70
                                            ),
                                            borderRadius:
                                              imgShapeForListing ===
                                              "soft_corner"
                                                ? "6px"
                                                : imgShapeForListing ===
                                                  "circle"
                                                ? "100%"
                                                : "0px",
                                            // backgroundPosition:imgCenterForListing?"": "unset",
                                            backgroundSize: imgCenterForListing
                                              ? "cover"
                                              : "contain",
                                            backgroundImage:
                                              "url(" + gallery1 + ")",
                                          }}
                                        >  {
                                          postState.settings.enableBookmarkingOnList ?
                                          <div className="post-list-bookmark-div">
                                                    <img className="post-list-bookmark-img-list-view" src={greyBookmarkIcon} alt="bookmark-list-icon"/>
                                                  </div> 
                                                   :
                                                   <></>
                                                  }
                                        </div>
                                      </div>
                                      <div className="post-list-type-two">
                                        <div
                                          className="post-type-two-data"
                                          style={{ textAlign: "left" }}
                                        >
                                          <h3
                                            className="post-two-heading"
                                            style={{
                                              fontSize: "9px",
                                              marginBottom:
                                                showPublishDateForListing
                                                  ? ""
                                                  : "7px",
                                            }}
                                          >
                                            Inspiration for new projectscan be
                                            found
                                          </h3>
                                          {postState.settings
                                            .showExcerptForListing && (
                                            <h5 className="post-two-excerpt">
                                              Yellow light wash 5-pocket
                                              mid-rise cropped jeans, clean look
                                              with no fade, has a button and zip
                                              closure, waistband with belt
                                              loops...
                                            </h5>
                                          )}

                                          <div className="post-list-author">
                                            {postState.settings
                                              .showAuthorOnList && (
                                              <div
                                                style={{
                                                  backgroundImage:
                                                    "url(" + gallery1 + ")",
                                                }}
                                              ></div>
                                            )}

                                            {postState.settings
                                              .showAuthorOnList && (
                                              <h4 className="post-two-author">
                                                John Smith
                                              </h4>
                                            )}
                                            {postState.settings
                                              .showAuthorOnList && (
                                              <div className="author-seprator"></div>
                                            )}
                                            {showPublishDateForListing && (
                                              <h6 className="post-two-timestamp">
                                                12 Jun 2022
                                              </h6>
                                            )}
                                          </div>
                                        </div>
                                        <div
                                          className="post-type-two-thumbnail"
                                          style={{
                                            height: calculateHeight(
                                              imgRatioForListing,
                                              70
                                            ),
                                            borderRadius:
                                              imgShapeForListing ===
                                              "soft_corner"
                                                ? "10px"
                                                : imgShapeForListing ===
                                                  "circle"
                                                ? "100%"
                                                : "0px",
                                            // backgroundPosition:imgCenterForListing?"": "unset",
                                            backgroundSize: imgCenterForListing
                                              ? "cover"
                                              : "contain",
                                            backgroundImage:
                                              "url(" + gallery2 + ")",
                                          }}
                                        >  {
                                          postState.settings.enableBookmarkingOnList ?
                                          <div className="post-list-bookmark-div">
                                                    <img className="post-list-bookmark-img-list-view" src={greyBookmarkIcon} alt="bookmark-list-icon"/>
                                                  </div> 
                                                   :
                                                   <></>
                                                  }
                                        </div>
                                      </div>
                                      <div className="post-list-type-two">
                                        <div
                                          className="post-type-two-data"
                                          style={{ textAlign: "left" }}
                                        >
                                          <h3
                                            className="post-two-heading"
                                            style={{
                                              fontSize: "9px",
                                              marginBottom:
                                                showPublishDateForListing
                                                  ? ""
                                                  : "7px",
                                            }}
                                          >
                                            Inspiration for new projectscan be
                                            found
                                          </h3>
                                          {postState.settings
                                            .showExcerptForListing && (
                                            <h5 className="post-two-excerpt">
                                              Yellow light wash 5-pocket
                                              mid-rise cropped jeans, clean look
                                              with no fade, has a button and zip
                                              closure, waistband with belt
                                              loops...
                                            </h5>
                                          )}

                                          <div className="post-list-author">
                                            {postState.settings
                                              .showAuthorOnList && (
                                              <div
                                                style={{
                                                  backgroundImage:
                                                    "url(" + gallery1 + ")",
                                                }}
                                              ></div>
                                            )}

                                            {postState.settings
                                              .showAuthorOnList && (
                                              <h4 className="post-two-author">
                                                John Smith
                                              </h4>
                                            )}
                                            {postState.settings
                                              .showAuthorOnList && (
                                              <div className="author-seprator"></div>
                                            )}
                                            {showPublishDateForListing && (
                                              <h6 className="post-two-timestamp">
                                                12 Jun 2022
                                              </h6>
                                            )}
                                          </div>
                                        </div>
                                        <div
                                          className="post-type-two-thumbnail"
                                          style={{
                                            height: calculateHeight(
                                              imgRatioForListing,
                                              70
                                            ),
                                            borderRadius:
                                              imgShapeForListing ===
                                              "soft_corner"
                                                ? "10px"
                                                : imgShapeForListing ===
                                                  "circle"
                                                ? "100%"
                                                : "0px",
                                            // backgroundPosition:imgCenterForListing?"": "unset",
                                            backgroundSize: imgCenterForListing
                                              ? "cover"
                                              : "contain",
                                            backgroundImage:
                                              "url(" + gallery3 + ")",
                                          }}
                                        >  {
                                          postState.settings.enableBookmarkingOnList ?
                                          <div className="post-list-bookmark-div">
                                                    <img className="post-list-bookmark-img-list-view" src={greyBookmarkIcon} alt="bookmark-list-icon"/>
                                                  </div> 
                                                   :
                                                   <></>
                                                  }
                                        </div>
                                      </div>
                                      <div className="post-list-type-two">
                                        <div
                                          className="post-type-two-data"
                                          style={{ textAlign: "left" }}
                                        >
                                          <h3
                                            className="post-two-heading"
                                            style={{
                                              fontSize: "9px",
                                              marginBottom:
                                                showPublishDateForListing
                                                  ? ""
                                                  : "7px",
                                            }}
                                          >
                                            Please don't use circular
                                            dependencies in spring boot projects
                                          </h3>
                                          {postState.settings
                                            .showExcerptForListing && (
                                            <h5 className="post-two-excerpt">
                                              Yellow light wash 5-pocket
                                              mid-rise cropped jeans, clean look
                                              with no fade, has a button and zip
                                              closure, waistband with belt
                                              loops...
                                            </h5>
                                          )}

                                          <div className="post-list-author">
                                            {postState.settings
                                              .showAuthorOnList && (
                                              <div
                                                style={{
                                                  backgroundImage:
                                                    "url(" + gallery1 + ")",
                                                }}
                                              ></div>
                                            )}

                                            {postState.settings
                                              .showAuthorOnList && (
                                              <h4 className="post-two-author">
                                                John Smith
                                              </h4>
                                            )}
                                            {postState.settings
                                              .showAuthorOnList && (
                                              <div className="author-seprator"></div>
                                            )}
                                            {showPublishDateForListing && (
                                              <h6 className="post-two-timestamp">
                                                12 Jun 2022
                                              </h6>
                                            )}
                                          </div>
                                        </div>
                                        <div
                                          className="post-type-two-thumbnail"
                                          style={{
                                            height: calculateHeight(
                                              imgRatioForListing,
                                              70
                                            ),
                                            borderRadius:
                                              imgShapeForListing ===
                                              "soft_corner"
                                                ? "10px"
                                                : imgShapeForListing ===
                                                  "circle"
                                                ? "100%"
                                                : "0px",
                                            // backgroundPosition:imgCenterForListing?"": "unset",
                                            backgroundSize: imgCenterForListing
                                              ? "cover"
                                              : "contain",
                                            backgroundImage:
                                              "url(" + gallery4 + ")",
                                          }}
                                        >  {
                                          postState.settings.enableBookmarkingOnList ?
                                          <div className="post-list-bookmark-div">
                                                    <img className="post-list-bookmark-img-list-view" src={greyBookmarkIcon} alt="bookmark-list-icon"/>
                                                  </div> 
                                                   :
                                                   <></>
                                                  }
                                        </div>
                                      </div>
                                      <div className="post-list-type-two">
                                        <div
                                          className="post-type-two-data"
                                          style={{ textAlign: "left" }}
                                        >
                                          <h3
                                            className="post-two-heading"
                                            style={{
                                              fontSize: "9px",
                                              marginBottom:
                                                showPublishDateForListing
                                                  ? ""
                                                  : "7px",
                                            }}
                                          >
                                            Inspiration for new projectscan be
                                            found
                                          </h3>
                                          {postState.settings
                                            .showExcerptForListing && (
                                            <h5 className="post-two-excerpt">
                                              Yellow light wash 5-pocket
                                              mid-rise cropped jeans, clean look
                                              with no fade, has a button and zip
                                              closure, waistband with belt
                                              loops...
                                            </h5>
                                          )}

                                          <div className="post-list-author">
                                            {postState.settings
                                              .showAuthorOnList && (
                                              <div
                                                style={{
                                                  backgroundImage:
                                                    "url(" + gallery1 + ")",
                                                }}
                                              ></div>
                                            )}

                                            {postState.settings
                                              .showAuthorOnList && (
                                              <h4 className="post-two-author">
                                                John Smith
                                              </h4>
                                            )}
                                            {postState.settings
                                              .showAuthorOnList && (
                                              <div className="author-seprator"></div>
                                            )}
                                            {showPublishDateForListing && (
                                              <h6 className="post-two-timestamp">
                                                12 Jun 2022
                                              </h6>
                                            )}
                                          </div>
                                        </div>
                                        <div
                                          className="post-type-two-thumbnail"
                                          style={{
                                            height: calculateHeight(
                                              imgRatioForListing,
                                              70
                                            ),
                                            borderRadius:
                                              imgShapeForListing ===
                                              "soft_corner"
                                                ? "10px"
                                                : imgShapeForListing ===
                                                  "circle"
                                                ? "100%"
                                                : "0px",
                                            // backgroundPosition:imgCenterForListing?"": "unset",
                                            backgroundSize: imgCenterForListing
                                              ? "cover"
                                              : "contain",
                                            backgroundImage:
                                              "url(" + imagebackground3 + ")",
                                          }}
                                        >  {
                                          postState.settings.enableBookmarkingOnList ?
                                          <div className="post-list-bookmark-div">
                                                    <img className="post-list-bookmark-img-list-view" src={greyBookmarkIcon} alt="bookmark-list-icon"/>
                                                  </div> 
                                                   :
                                                   <></>
                                                  }
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}

                            {/* //  Preview for POST DETAILS in custom , wp , woo app */}
                             <div className="preview-1">
                                <div ref={showAuthorRef}>
                                  <div className="preview-header">
                                    <div>
                                      <img
                                        className="img-fluid"
                                        src={
                                          require("../../assets/images/dashboard/post-back.svg")
                                            .default
                                        }
                                      />
                                    </div>

                                    <div>
                                      {
                                        postState.settings.showShareIconOnDetails && !isWebView &&
                                      
                                      <img
                                        className="img-fluid"
                                        src={
                                          require("../../assets/images/dashboard/share-icon.svg")
                                            .default
                                        }
                                      />
                                      }
{/* 
                                      <img
                                        style={{ marginLeft: "20px" }}
                                        className="img-fluid"
                                        src={
                                          require("../../assets/images/dashboard/bookmark-post.svg")
                                            .default
                                        }
                                      /> */}
                                    </div>
                                  </div>
                                  {
                                    isWebView ?

                                  <div className="postlist-webview-body">
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/webview-icon.png")
                                        .default
                                    }
                                    alt="webview-icon"
                                  />
                                </div>

                                :
                                  <div className="post-list-preview-detail">
                                    <div
                                      style={{
                                        margin: `0px ${
                                          sectionRightMargin + "px"
                                        } 0px ${sectionLeftMargin + "px"}`,
                                        padding: `${
                                          sectionTopMargin + "px"
                                        } 0px ${
                                          sectionBottomMargin + "px"
                                        } 0px`,
                                      }}
                                    >
                                      {showCategories && (
                                        <div className="post-list-category-preview">
                                          <h6>
                                            Travel<span>|</span>Holiday
                                            <span>|</span>Trip<span>|</span>
                                          </h6>
                                        </div>
                                      )}

                                      <h2>
                                        How to make your USA road trip a
                                        successful one
                                      </h2>

                                      <div className="post-list-author">
                                        {showAuthor && (
                                          <div
                                            style={{
                                              backgroundImage:
                                                "url(" + gallery1 + ")",
                                            }}
                                          ></div>
                                        )}

                                        {showAuthor && <h5>John Smith</h5>}

                                        {showAuthor && (
                                          <div className="author-seprator"></div>
                                        )}
                                        {showPublishDate && (
                                          <h6>12 Jun 2022</h6>
                                        )}
                                      </div>
                                    </div>

                                    <div
                                      className="post-list-preview-banner"
                                      style={{
                                        margin: `0px ${
                                          sectionRightMargin + "px"
                                        } 0px ${sectionLeftMargin + "px"}`,
                                        padding: `${
                                          sectionTopMargin + "px"
                                        } 0px ${
                                          sectionBottomMargin + "px"
                                        } 0px`,
                                      }}
                                    >
                                      <div
                                        className="post-list-preview-image"
                                        style={{
                                          backgroundImage:
                                            "url(" + imagebackground3 + ")",
                                          height: calculateHeight(
                                            imgRatioForDetails,
                                            274
                                          ),
                                          borderRadius:
                                            imgShapeForDetails === "soft_corner"
                                              ? "6px"
                                              : imgShapeForDetails === "circle"
                                              ? "100%"
                                              : "0px",
                                          // backgroundPosition:imgCenterForDetails?"": "unset",
                                          backgroundSize: imgCenterForDetails
                                            ? "cover"
                                            : "contain",
                                        }}
                                      ></div>
                                    </div>

                                    <div
                                      style={{
                                        margin: `0px ${
                                          sectionRightMargin + "px"
                                        } 0px ${sectionLeftMargin + "px"}`,
                                        padding: `${
                                          sectionTopMargin + "px"
                                        } 0px ${
                                          sectionBottomMargin + "px"
                                        } 0px`,
                                      }}
                                    >
                                      <p>
                                        Few things evoke a sense of adventure
                                        like a road trip. Whether it’s just you,
                                        your friends, your family or even with a
                                        hitchhiker, it’s certainly one of the
                                        most exciting ways to travel.{" "}
                                      </p>
                                      <p>
                                        However, responding to the call of the
                                        open road can, at times, be challenging
                                        for many inexperienced travelers. So, in
                                        this post, I’ll be showing you some tips
                                        and tricks on how to do a road trip
                                        right – and what better case study to
                                        use than the home of the road trip, the
                                        United States of Amercia?!{" "}
                                      </p>
                                    </div>

                                    <div
                                      className="atr"
                                      ref={showAtrRef}
                                      style={{
                                        margin: `0px ${
                                          sectionRightMargin + "px"
                                        } 0px ${sectionLeftMargin + "px"}`,
                                        padding: `${
                                          sectionTopMargin + "px"
                                        } 0px ${
                                          sectionBottomMargin + "px"
                                        } 0px`,
                                      }}
                                    >
                                      {parseInt(
                                        localStorage.getItem(
                                          "website_technology"
                                        )
                                      ) === 3 &&
                                        showAttribute && (
                                          <div className="attributes-preview">
                                            <div className="attributes-segment">
                                              <h4>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  viewBox="0 0 16 16"
                                                >
                                                  <g
                                                    id="Subtraction_268"
                                                    data-name="Subtraction 268"
                                                    fill="#fff"
                                                  >
                                                    <path
                                                      d="M 5.901899814605713 15.5 L 0.5 15.5 L 0.5 6.640631675720215 L 7.99727201461792 0.6403278708457947 L 15.5 6.64073657989502 L 15.5 15.49825763702393 L 10.10190010070801 15.49985218048096 L 10.10190010070801 9.59961986541748 L 10.10190010070801 9.09961986541748 L 9.601900100708008 9.09961986541748 L 6.401899814605713 9.09961986541748 L 5.901899814605713 9.09961986541748 L 5.901899814605713 9.59961986541748 L 5.901899814605713 15.5 Z"
                                                      stroke="none"
                                                    />
                                                    <path
                                                      d="M 5.401899814605713 15 L 5.401899814605713 8.59961986541748 L 10.60190010070801 8.59961986541748 L 10.60190010070801 14.99970436096191 L 15 14.99840545654297 L 15 6.881092548370361 L 7.997379302978516 1.280651688575745 L 1 6.880875587463379 L 1 15 L 5.401899814605713 15 M 9.601900100708008 16 L 9.601900100708008 9.59961986541748 L 6.401899814605713 9.59961986541748 L 6.400000095367432 16 L 0 16 L 0 6.40038013458252 L 7.997159957885742 0 L 16 6.40038013458252 L 16 15.99810981750488 L 9.601900100708008 16 Z"
                                                      stroke="none"
                                                      fill="#000"
                                                    />
                                                  </g>
                                                </svg>
                                                Property type
                                              </h4>

                                              <div>
                                                <p>Apartment</p>
                                              </div>
                                            </div>
                                            <div className="attributes-segment">
                                              <h4>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="14.942"
                                                  height="13.68"
                                                  viewBox="0 0 11.942 12.68"
                                                >
                                                  <path
                                                    id="Union_2815"
                                                    data-name="Union 2815"
                                                    d="M-965,20007.27v-7.943l5.984-2.324,5.958,2.324v7.943l-5.958,2.412Zm5.979,1.479,4.386-1.771-4.4-1.6-4.364,1.605Zm-5.123-8.83v6.424l4.719-1.738v-6.518Zm10.23,6.4v-6.4l-4.655-1.812v6.529Z"
                                                    transform="translate(964.999 -19997.002)"
                                                  />
                                                </svg>
                                                Unit options
                                              </h4>

                                              <div>
                                                {showAttributeAsaList ? (
                                                  <>
                                                    <p>2 BHK</p>
                                                    <p>3 BHK</p>
                                                    <p>4 BHK</p>
                                                  </>
                                                ) : (
                                                  <p>2 BHK , 3 BHK , 4 BHK</p>
                                                )}
                                              </div>
                                            </div>
                                            <div className="attributes-segment">
                                              <h4>
                                                <svg
                                                  id="Group_39396"
                                                  data-name="Group 39396"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="14.89"
                                                  height="13.89"
                                                  viewBox="0 0 12.89 12.89"
                                                >
                                                  <g
                                                    id="Rectangle_19142"
                                                    data-name="Rectangle 19142"
                                                    transform="translate(0 0)"
                                                    fill="#fff"
                                                    stroke="#000"
                                                    stroke-width="1"
                                                  >
                                                    <rect
                                                      width="12.89"
                                                      height="12.89"
                                                      rx="4"
                                                      stroke="none"
                                                    />
                                                    <rect
                                                      x="0.5"
                                                      y="0.5"
                                                      width="11.89"
                                                      height="11.89"
                                                      rx="3.5"
                                                      fill="none"
                                                    />
                                                  </g>
                                                  <path
                                                    id="Path_102700"
                                                    data-name="Path 102700"
                                                    d="M8.25,4.184v6.3"
                                                    transform="translate(-1.805 -1.03)"
                                                    fill="none"
                                                    stroke="#000"
                                                    stroke-width="1"
                                                  />
                                                  <path
                                                    id="Path_102701"
                                                    data-name="Path 102701"
                                                    d="M0,0V6.3"
                                                    transform="translate(9.593 6.292) rotate(90)"
                                                    fill="none"
                                                    stroke="#000"
                                                    stroke-width="1"
                                                  />
                                                </svg>
                                                Ameneties
                                              </h4>

                                              <div>
                                                {showAttributeAsaList ===
                                                true ? (
                                                  <>
                                                    <p>Intercom</p>
                                                    <p>Tennis court</p>
                                                    <p>Amphitheater</p>
                                                    <p>Cricket Pitch</p>
                                                  </>
                                                ) : (
                                                  <>
                                                    <p>
                                                      Intercom , Tennis court ,
                                                      Amphitheater , Cricket
                                                      Pitch
                                                    </p>
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                    </div>

                                    <h2
                                      style={{
                                        margin: `0px ${
                                          sectionRightMargin + "px"
                                        } 0px ${sectionLeftMargin + "px"}`,
                                        padding: `${
                                          sectionTopMargin + "px"
                                        } 0px ${
                                          sectionBottomMargin + "px"
                                        } 0px`,
                                      }}
                                    >
                                      You’ll need a car that’s up to the task
                                    </h2>
                                    <div
                                      style={{
                                        // margin:`${(sectionTopMargin==0?"-4":sectionTopMargin)+"px"} ${(sectionRightMargin==0?"-4":sectionRightMargin)+"px"} ${(sectionBottomMargin==0?"-4":sectionBottomMargin)+"px"} ${(sectionLeftMargin==0?"-4":sectionLeftMargin)+"px"}`,
                                        margin: `0px ${
                                          (sectionRightMargin == 0
                                            ? "-4"
                                            : sectionRightMargin) + "px"
                                        } 0px ${
                                          (sectionLeftMargin == 0
                                            ? "-4"
                                            : sectionLeftMargin) + "px"
                                        }`,
                                        padding: `${
                                          sectionTopMargin + "px"
                                        } 0px ${
                                          sectionBottomMargin + "px"
                                        } 0px`,
                                      }}
                                    >
                                      <div className="gallerygrid">
                                        <div className="custom-gallery padding-class">
                                          <div
                                            style={{
                                              backgroundImage:
                                                "url(" + gallery1 + ")",
                                              height: calculateHeight(
                                                imgRatioForDetails,
                                                129
                                              ),
                                              borderRadius:
                                                imgShapeForDetails ===
                                                "soft_corner"
                                                  ? "6px"
                                                  : imgShapeForDetails ===
                                                    "circle"
                                                  ? "100%"
                                                  : "0px",
                                              // backgroundPosition:imgCenterForDetails?"": "unset",
                                              backgroundSize:
                                                imgCenterForDetails
                                                  ? "cover"
                                                  : "contain",
                                            }}
                                          ></div>
                                        </div>

                                        <div className="custom-gallery padding-class">
                                          <div
                                            style={{
                                              backgroundImage:
                                                "url(" + gallery2 + ")",
                                              height: calculateHeight(
                                                imgRatioForDetails,
                                                129
                                              ),
                                              borderRadius:
                                                imgShapeForDetails ===
                                                "soft_corner"
                                                  ? "6px"
                                                  : imgShapeForDetails ===
                                                    "circle"
                                                  ? "100%"
                                                  : "0px",
                                              // backgroundPosition:imgCenterForDetails?"": "unset",
                                              backgroundSize:
                                                imgCenterForDetails
                                                  ? "cover"
                                                  : "contain",
                                            }}
                                          ></div>
                                        </div>

                                        <div className="custom-gallery padding-class">
                                          <div
                                            style={{
                                              backgroundImage:
                                                "url(" + gallery3 + ")",
                                              height: calculateHeight(
                                                imgRatioForDetails,
                                                129
                                              ),
                                              borderRadius:
                                                imgShapeForDetails ===
                                                "soft_corner"
                                                  ? "6px"
                                                  : imgShapeForDetails ===
                                                    "circle"
                                                  ? "100%"
                                                  : "0px",
                                              // backgroundPosition:imgCenterForDetails?"": "unset",
                                              backgroundSize:
                                                imgCenterForDetails
                                                  ? "cover"
                                                  : "contain",
                                            }}
                                          ></div>
                                        </div>

                                        <div className="custom-gallery padding-class">
                                          <div
                                            style={{
                                              backgroundImage:
                                                "url(" + gallery4 + ")",
                                              height: calculateHeight(
                                                imgRatioForDetails,
                                                129
                                              ),
                                              borderRadius:
                                                imgShapeForDetails ===
                                                "soft_corner"
                                                  ? "6px"
                                                  : imgShapeForDetails ===
                                                    "circle"
                                                  ? "100%"
                                                  : "0px",
                                              // backgroundPosition:imgCenterForDetails?"": "unset",
                                              backgroundSize:
                                                imgCenterForDetails
                                                  ? "cover"
                                                  : "contain",
                                            }}
                                          ></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        margin: `0px ${
                                          sectionRightMargin + "px"
                                        } 0px ${sectionLeftMargin + "px"}`,
                                        padding: `${
                                          sectionTopMargin + "px"
                                        } 0px ${
                                          sectionBottomMargin + "px"
                                        } 0px`,
                                      }}
                                    >
                                      <button>Rent A Car</button>
                                    </div>
                                    <div
                                      style={{
                                        borderTop: "7px solid #F9F9F9",
                                        backgroundColor: "#fff",
                                      }}
                                      ref={showTagRef}
                                    >
                                      {showTags && (
                                        <div
                                          style={{
                                            margin: `0px ${
                                              sectionRightMargin + "px"
                                            } 0px ${sectionLeftMargin + "px"}`,
                                            padding: `${
                                              sectionTopMargin + "px"
                                            } 0px  0px`,
                                          }}
                                          // style={{
                                          //   display:showTags?"block":"none"
                                          // }}
                                          className="post-list-tag-preview"
                                        >
                                          <h5>travel</h5>
                                          <h5>trip</h5>
                                          <h5>holiday</h5>
                                          <h5>vacation</h5>
                                          <h5>road trip</h5>
                                          <h5>vacation</h5>
                                          <h5>holiday</h5>
                                          <h5>trip</h5>
                                          <h5>travel</h5>
                                        </div>
                                      )}
                                    </div>

                                    <div
                                      style={{
                                        borderTop: "7px solid #F9F9F9",
                                        backgroundColor: "#fff",
                                      }}
                                      // ref={showTagRef}
                                    >
                                      {/* {
                     showTags &&  */}

                                      <div
                                        style={{
                                          margin: `0px ${
                                            sectionRightMargin + "px"
                                          } 0px ${sectionLeftMargin + "px"}`,
                                          padding: `${
                                            sectionTopMargin + "px"
                                          } 0px ${
                                            sectionBottomMargin + "px"
                                          } 0px`,
                                        }}
                                        // style={{
                                        //   display:showTags?"block":"none"
                                        // }}
                                        className="comments-preview"
                                      >
                                        <div className="comment-head">
                                          <h4 className="comment-heading">
                                            Comments (13)
                                          </h4>
                                          <img
                                            className="comment-head-img"
                                            src={
                                              require("../../assets/images/dashboard/comments-arrow.webp")
                                                .default
                                            }
                                          />
                                        </div>

                                        <div className="preview-comment-entry">
                                          <div className="preview-comment-author">
                                            <div
                                              style={{
                                                backgroundImage:
                                                  "url(" + gallery1 + ")",
                                              }}
                                            ></div>
                                            <h5 className="preview-comment-author-name">
                                              Zachary Young
                                            </h5>
                                            <h6 className="preview-comment-author-status">
                                              Verified
                                            </h6>
                                          </div>

                                          <h6 className="preview-comments-data">
                                            Bought this lovely dress and paired
                                            it with white sneakers to complete
                                            the look. Super happy with this
                                            look!
                                          </h6>

                                          <div className="preview-comments-footer">
                                            <h6 className="preview-comments-reply">
                                              Reply
                                            </h6>
                                            <h6 className="preview-comments-timestamp">
                                              12 July 2023
                                            </h6>
                                          </div>
                                        </div>

                                        <div className="preview-comment-entry">
                                          <div className="preview-comment-author">
                                            <div
                                              style={{
                                                backgroundImage:
                                                  "url(" + gallery1 + ")",
                                              }}
                                            ></div>
                                            <h5 className="preview-comment-author-name">
                                              Zachary Young
                                            </h5>
                                            <h6 className="preview-comment-author-status">
                                              Verified
                                            </h6>
                                          </div>

                                          <h6 className="preview-comments-data">
                                            Bought this lovely dress and paired
                                            it with white sneakers to complete
                                            the look. Super happy with this
                                            look!
                                          </h6>

                                          <div className="preview-comments-footer">
                                            <h6 className="preview-comments-reply">
                                              Reply
                                            </h6>
                                            <h6 className="preview-comments-timestamp">
                                              12 July 2023
                                            </h6>
                                          </div>
                                        </div>

                                        <div className="preview-comment-entry">
                                          <div className="preview-comment-author">
                                            <div
                                              style={{
                                                backgroundImage:
                                                  "url(" + gallery1 + ")",
                                              }}
                                            ></div>
                                            <h5 className="preview-comment-author-name">
                                              Zachary Young
                                            </h5>
                                            <h6 className="preview-comment-author-status">
                                              Verified
                                            </h6>
                                          </div>

                                          <h6 className="preview-comments-data">
                                            Bought this lovely dress and paired
                                            it with white sneakers to complete
                                            the look. Super happy with this
                                            look!
                                          </h6>

                                          <div className="preview-comments-footer">
                                            <h6 className="preview-comments-reply">
                                              Reply
                                            </h6>
                                            <h6 className="preview-comments-timestamp">
                                              12 July 2023
                                            </h6>
                                          </div>
                                        </div>

                                        <button className="comment-button">
                                          Comment
                                        </button>
                                      </div>
                                      {/* } */}
                                    </div>

                                    <div
                                      ref={showRelatedPostsRef}
                                      style={{
                                        borderTop: showRelatedPosts ?  "7px solid #F9F9F9": "",
                                        backgroundColor: "#fff",
                                      }}
                                    >
                                       {showRelatedPosts && (
                                      <div
                                        style={{
                                          margin: `0px ${
                                            sectionRightMargin + "px"
                                          } 0px ${sectionLeftMargin + "px"}`,
                                          padding: `${
                                            sectionTopMargin + "px"
                                          } 0px ${
                                            sectionBottomMargin + "px"
                                          } 0px`,
                                        }}
                                      >                                    
                                          <div>
                                            <div
                                              style={{
                                                marginLeft: "0px",
                                                marginRight: "0px",
                                              }}
                                              className="post-list-type-two"
                                            >
                                              <div
                                                className="post-type-two-data"
                                                style={{ textAlign: "left" }}
                                              >
                                                <h3
                                                  className="post-two-heading"
                                                  style={{
                                                    fontSize: "9px",
                                                    marginBottom:
                                                      showPublishDateForListing
                                                        ? ""
                                                        : "7px",
                                                  }}
                                                >
                                                  Inspiration for new
                                                  projectscan be found
                                                </h3>

                                                {postState.settings
                                                  .showExcerptForListing && (
                                                  <h5 className="post-two-excerpt">
                                                    Yellow light wash 5-pocket
                                                    mid-rise cropped jeans,
                                                    clean look with no fade, has
                                                    a button and zip closure,
                                                    waistband with belt loops...
                                                  </h5>
                                                )}

                                                <div className="post-list-author">
                                                  {postState.settings
                                                    .showAuthorOnList && (
                                                    <div
                                                      style={{
                                                        backgroundImage:
                                                          "url(" +
                                                          gallery1 +
                                                          ")",
                                                      }}
                                                    ></div>
                                                  )}

                                                  {postState.settings
                                                    .showAuthorOnList && (
                                                    <h4 className="post-two-author">
                                                      John Smith
                                                    </h4>
                                                  )}

                                                  {postState.settings
                                                    .showAuthorOnList && (
                                                    <div className="author-seprator"></div>
                                                  )}
                                                  {showPublishDateForListing && (
                                                    <h6 className="post-two-timestamp">
                                                      12 Jun 2022
                                                    </h6>
                                                  )}
                                                </div>
                                              </div>
                                              <div
                                                className="post-type-two-thumbnail"
                                                style={{
                                                  height: calculateHeight(
                                                    imgRatioForListing,
                                                    70
                                                  ),
                                                  borderRadius:
                                                    imgShapeForListing ===
                                                    "soft_corner"
                                                      ? "6px"
                                                      : imgShapeForListing ===
                                                        "circle"
                                                      ? "100%"
                                                      : "0px",
                                                  // backgroundPosition:imgCenterForListing?"": "unset",
                                                  backgroundSize:
                                                    imgCenterForListing
                                                      ? "cover"
                                                      : "contain",
                                                  backgroundImage:
                                                    "url(" + gallery1 + ")",
                                                }}
                                              >  {
                                                postState.settings.enableBookmarkingOnList ?
                                                <div className="post-list-bookmark-div">
                                                    <img className="post-list-bookmark-img-list-view" src={greyBookmarkIcon} alt="bookmark-list-icon"/>
                                                  </div> 
                                                   :
                                                   <></>
                                                  }
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                marginLeft: "0px",
                                                marginRight: "0px",
                                              }}
                                              className="post-list-type-two"
                                            >
                                              <div
                                                className="post-type-two-data"
                                                style={{ textAlign: "left" }}
                                              >
                                                <h3
                                                  className="post-two-heading"
                                                  style={{
                                                    fontSize: "9px",
                                                    marginBottom:
                                                      showPublishDateForListing
                                                        ? ""
                                                        : "7px",
                                                  }}
                                                >
                                                  Inspiration for new
                                                  projectscan be found
                                                </h3>

                                                {postState.settings
                                                  .showExcerptForListing && (
                                                  <h5 className="post-two-excerpt">
                                                    Yellow light wash 5-pocket
                                                    mid-rise cropped jeans,
                                                    clean look with no fade, has
                                                    a button and zip closure,
                                                    waistband with belt loops...
                                                  </h5>
                                                )}

                                                <div className="post-list-author">
                                                  {postState.settings
                                                    .showAuthorOnList && (
                                                    <div
                                                      style={{
                                                        backgroundImage:
                                                          "url(" +
                                                          gallery1 +
                                                          ")",
                                                      }}
                                                    ></div>
                                                  )}

                                                  {postState.settings
                                                    .showAuthorOnList && (
                                                    <h4 className="post-two-author">
                                                      John Smith
                                                    </h4>
                                                  )}

                                                  {postState.settings
                                                    .showAuthorOnList && (
                                                    <div className="author-seprator"></div>
                                                  )}
                                                  {showPublishDateForListing && (
                                                    <h6 className="post-two-timestamp">
                                                      12 Jun 2022
                                                    </h6>
                                                  )}
                                                </div>
                                              </div>
                                              <div
                                                className="post-type-two-thumbnail"
                                                style={{
                                                  height: calculateHeight(
                                                    imgRatioForListing,
                                                    70
                                                  ),
                                                  borderRadius:
                                                    imgShapeForListing ===
                                                    "soft_corner"
                                                      ? "10px"
                                                      : imgShapeForListing ===
                                                        "circle"
                                                      ? "100%"
                                                      : "0px",
                                                  // backgroundPosition:imgCenterForListing?"": "unset",
                                                  backgroundSize:
                                                    imgCenterForListing
                                                      ? "cover"
                                                      : "contain",
                                                  backgroundImage:
                                                    "url(" + gallery2 + ")",
                                                }}
                                              >  {
                                                postState.settings.enableBookmarkingOnList ?
                                                <div className="post-list-bookmark-div">
                                                    <img className="post-list-bookmark-img-list-view" src={greyBookmarkIcon} alt="bookmark-list-icon"/>
                                                  </div> 
                                                   :
                                                   <></>
                                                  }
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                marginLeft: "0px",
                                                marginRight: "0px",
                                              }}
                                              className="post-list-type-two"
                                            >
                                              <div
                                                className="post-type-two-data"
                                                style={{ textAlign: "left" }}
                                              >
                                                <h3
                                                  className="post-two-heading"
                                                  style={{
                                                    fontSize: "9px",
                                                    marginBottom:
                                                      showPublishDateForListing
                                                        ? ""
                                                        : "7px",
                                                  }}
                                                >
                                                  Inspiration for new
                                                  projectscan be found
                                                </h3>

                                                {postState.settings
                                                  .showExcerptForListing && (
                                                  <h5 className="post-two-excerpt">
                                                    Yellow light wash 5-pocket
                                                    mid-rise cropped jeans,
                                                    clean look with no fade, has
                                                    a button and zip closure,
                                                    waistband with belt loops...
                                                  </h5>
                                                )}

                                                <div className="post-list-author">
                                                  {postState.settings
                                                    .showAuthorOnList && (
                                                    <div
                                                      style={{
                                                        backgroundImage:
                                                          "url(" +
                                                          gallery1 +
                                                          ")",
                                                      }}
                                                    ></div>
                                                  )}

                                                  {postState.settings
                                                    .showAuthorOnList && (
                                                    <h4 className="post-two-author">
                                                      John Smith
                                                    </h4>
                                                  )}

                                                  {postState.settings
                                                    .showAuthorOnList && (
                                                    <div className="author-seprator"></div>
                                                  )}
                                                  {showPublishDateForListing && (
                                                    <h6 className="post-two-timestamp">
                                                      12 Jun 2022
                                                    </h6>
                                                  )}
                                                </div>
                                              </div>
                                              <div
                                                className="post-type-two-thumbnail"
                                                style={{
                                                  height: calculateHeight(
                                                    imgRatioForListing,
                                                    70
                                                  ),
                                                  borderRadius:
                                                    imgShapeForListing ===
                                                    "soft_corner"
                                                      ? "10px"
                                                      : imgShapeForListing ===
                                                        "circle"
                                                      ? "100%"
                                                      : "0px",
                                                  // backgroundPosition:imgCenterForListing?"": "unset",
                                                  backgroundSize:
                                                    imgCenterForListing
                                                      ? "cover"
                                                      : "contain",
                                                  backgroundImage:
                                                    "url(" + gallery3 + ")",
                                                }}
                                              >  {
                                                postState.settings.enableBookmarkingOnList ?
                                                <div className="post-list-bookmark-div">
                                                    <img className="post-list-bookmark-img-list-view" src={greyBookmarkIcon} alt="bookmark-list-icon"/>
                                                  </div> 
                                                   :
                                                   <></>
                                                  }
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                marginLeft: "0px",
                                                marginRight: "0px",
                                              }}
                                              className="post-list-type-two"
                                            >
                                              <div
                                                className="post-type-two-data"
                                                style={{ textAlign: "left" }}
                                              >
                                                <h3
                                                  className="post-two-heading"
                                                  style={{
                                                    fontSize: "9px",
                                                    marginBottom:
                                                      showPublishDateForListing
                                                        ? ""
                                                        : "7px",
                                                  }}
                                                >
                                                  Inspiration for new
                                                  projectscan be found
                                                </h3>

                                                {postState.settings
                                                  .showExcerptForListing && (
                                                  <h5 className="post-two-excerpt">
                                                    Yellow light wash 5-pocket
                                                    mid-rise cropped jeans,
                                                    clean look with no fade, has
                                                    a button and zip closure,
                                                    waistband with belt loops...
                                                  </h5>
                                                )}

                                                <div className="post-list-author">
                                                  {postState.settings
                                                    .showAuthorOnList && (
                                                    <div
                                                      style={{
                                                        backgroundImage:
                                                          "url(" +
                                                          gallery1 +
                                                          ")",
                                                      }}
                                                    ></div>
                                                  )}

                                                  {postState.settings
                                                    .showAuthorOnList && (
                                                    <h4 className="post-two-author">
                                                      John Smith
                                                    </h4>
                                                  )}

                                                  {postState.settings
                                                    .showAuthorOnList && (
                                                    <div className="author-seprator"></div>
                                                  )}
                                                  {showPublishDateForListing && (
                                                    <h6 className="post-two-timestamp">
                                                      12 Jun 2022
                                                    </h6>
                                                  )}
                                                </div>
                                              </div>
                                              <div
                                                className="post-type-two-thumbnail"
                                                style={{
                                                  height: calculateHeight(
                                                    imgRatioForListing,
                                                    70
                                                  ),
                                                  borderRadius:
                                                    imgShapeForListing ===
                                                    "soft_corner"
                                                      ? "10px"
                                                      : imgShapeForListing ===
                                                        "circle"
                                                      ? "100%"
                                                      : "0px",
                                                  // backgroundPosition:imgCenterForListing?"": "unset",
                                                  backgroundSize:
                                                    imgCenterForListing
                                                      ? "cover"
                                                      : "contain",
                                                  backgroundImage:
                                                    "url(" + gallery4 + ")",
                                                }}
                                              >  {
                                                postState.settings.enableBookmarkingOnList ?
                                               <div className="post-list-bookmark-div">
                                                    <img className="post-list-bookmark-img-list-view" src={greyBookmarkIcon} alt="bookmark-list-icon"/>
                                                  </div>  
                                                   :
                                                   <></>
                                                  }
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                marginLeft: "0px",
                                                marginRight: "0px",
                                              }}
                                              className="post-list-type-two"
                                            >
                                              <div
                                                className="post-type-two-data"
                                                style={{ textAlign: "left" }}
                                              >
                                                <h3
                                                  className="post-two-heading"
                                                  style={{
                                                    fontSize: "9px",
                                                    marginBottom:
                                                      showPublishDateForListing
                                                        ? ""
                                                        : "7px",
                                                  }}
                                                >
                                                  Inspiration for new
                                                  projectscan be found
                                                </h3>

                                                {postState.settings
                                                  .showExcerptForListing && (
                                                  <h5 className="post-two-excerpt">
                                                    Yellow light wash 5-pocket
                                                    mid-rise cropped jeans,
                                                    clean look with no fade, has
                                                    a button and zip closure,
                                                    waistband with belt loops...
                                                  </h5>
                                                )}

                                                <div className="post-list-author">
                                                  {postState.settings
                                                    .showAuthorOnList && (
                                                    <div
                                                      style={{
                                                        backgroundImage:
                                                          "url(" +
                                                          gallery1 +
                                                          ")",
                                                      }}
                                                    ></div>
                                                  )}

                                                  {postState.settings
                                                    .showAuthorOnList && (
                                                    <h4 className="post-two-author">
                                                      John Smith
                                                    </h4>
                                                  )}

                                                  {postState.settings
                                                    .showAuthorOnList && (
                                                    <div className="author-seprator"></div>
                                                  )}
                                                  {showPublishDateForListing && (
                                                    <h6 className="post-two-timestamp">
                                                      12 Jun 2022
                                                    </h6>
                                                  )}
                                                </div>
                                              </div>
                                              <div
                                                className="post-type-two-thumbnail"
                                                style={{
                                                  height: calculateHeight(
                                                    imgRatioForListing,
                                                    70
                                                  ),
                                                  borderRadius:
                                                    imgShapeForListing ===
                                                    "soft_corner"
                                                      ? "10px"
                                                      : imgShapeForListing ===
                                                        "circle"
                                                      ? "100%"
                                                      : "0px",
                                                  // backgroundPosition:imgCenterForListing?"": "unset",
                                                  backgroundSize:
                                                    imgCenterForListing
                                                      ? "cover"
                                                      : "contain",
                                                  backgroundImage:
                                                    "url(" +
                                                    imagebackground3 +
                                                    ")",
                                                }}
                                              >  {
                                                postState.settings.enableBookmarkingOnList ?
                                                <div className="post-list-bookmark-div">
                                                    <img className="post-list-bookmark-img-list-view" src={greyBookmarkIcon} alt="bookmark-list-icon"/>
                                                  </div> 
                                                   :
                                                   <></>
                                                  }
                                              </div>
                                            </div>
                                          </div>
                                       
                                      </div>
                                       )}
                                    </div>

                                    <div></div>
                                  </div>
                                  }
                                </div>
                              </div>
                              {
                                postState.settings.showMediaInFullScreenOnDetails && !isWebView ?
                                  
                                  <div className='w-100 ' style={{
                                    height:"644px"
                                  }}>
                                  {
                                    postState.settings.showMediaAsThumbnailOnDetails ?
                                    <div className=' previewthumbnail-img'>
                                    
                                    </div> :
                                
                                    <div className='previewthumbnail-imagetwo'>

                                    </div> }
                                  </div>
                                  :null } 
                            </Carousel>
                          </div>
                          {activeSlide === 0 && postListSetting && (
                            <div className="change-post-view">
                              <svg
                                className={
                                  previewListingView === "list" ? "active" : ""
                                }
                                onClick={(e) => handleListingView(e, "list")}
                                id="Group_38251"
                                data-name="Group 38251"
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                              >
                                <g
                                  id="Rectangle_4591"
                                  data-name="Rectangle 4591"
                                  fill="#fff"
                                  stroke=""
                                  stroke-width="2"
                                >
                                  <rect
                                    width="9"
                                    height="9"
                                    rx="1"
                                    stroke="none"
                                  />
                                  <rect
                                    x="1"
                                    y="1"
                                    width="7"
                                    height="7"
                                    fill="none"
                                  />
                                </g>
                                <g
                                  id="Rectangle_4596"
                                  data-name="Rectangle 4596"
                                  transform="translate(0 11)"
                                  fill="#fff"
                                  stroke=""
                                  stroke-width="2"
                                >
                                  <rect
                                    width="9"
                                    height="9"
                                    rx="1"
                                    stroke="none"
                                  />
                                  <rect
                                    x="1"
                                    y="1"
                                    width="7"
                                    height="7"
                                    fill="none"
                                  />
                                </g>
                                <path
                                  id="Path_9130"
                                  data-name="Path 9130"
                                  d="M954.017,177.785h9"
                                  transform="translate(-943.016 -175.434)"
                                  fill="#fff"
                                  stroke=""
                                  stroke-width="2"
                                />
                                <path
                                  id="Path_9133"
                                  data-name="Path 9133"
                                  d="M954.017,177.785h9"
                                  transform="translate(-943.016 -163.775)"
                                  fill="#fff"
                                  stroke=""
                                  stroke-width="2"
                                />
                                <path
                                  id="Path_9131"
                                  data-name="Path 9131"
                                  d="M954.017,177.785h6"
                                  transform="translate(-943.016 -171.364)"
                                  fill="#fff"
                                  stroke=""
                                  stroke-width="2"
                                />
                                <path
                                  id="Path_9132"
                                  data-name="Path 9132"
                                  d="M954.017,177.785h6"
                                  transform="translate(-943.016 -159.706)"
                                  fill="#fff"
                                  stroke=""
                                  stroke-width="2"
                                />
                              </svg>

                              <svg
                                className={
                                  previewListingView === "grid" ? "active" : ""
                                }
                                onClick={(e) => handleListingView(e, "grid")}
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                              >
                                <g
                                  id="Group_38250"
                                  data-name="Group 38250"
                                  transform="translate(0.222)"
                                >
                                  <g
                                    id="Rectangle_4592"
                                    data-name="Rectangle 4592"
                                    transform="translate(-0.222)"
                                    fill="#fff"
                                    stroke=""
                                    stroke-width="2"
                                  >
                                    <rect
                                      width="9"
                                      height="9"
                                      rx="1"
                                      stroke="none"
                                    />
                                    <rect
                                      x="1"
                                      y="1"
                                      width="7"
                                      height="7"
                                      fill="none"
                                    />
                                  </g>
                                  <g
                                    id="Rectangle_4595"
                                    data-name="Rectangle 4595"
                                    transform="translate(-0.222 11)"
                                    fill="#fff"
                                    stroke=""
                                    stroke-width="2"
                                  >
                                    <rect
                                      width="9"
                                      height="9"
                                      rx="1"
                                      stroke="none"
                                    />
                                    <rect
                                      x="1"
                                      y="1"
                                      width="7"
                                      height="7"
                                      fill="none"
                                    />
                                  </g>
                                  <g
                                    id="Rectangle_4593"
                                    data-name="Rectangle 4593"
                                    transform="translate(10.778)"
                                    fill="#fff"
                                    stroke=""
                                    stroke-width="2"
                                  >
                                    <rect
                                      width="9"
                                      height="9"
                                      rx="1"
                                      stroke="none"
                                    />
                                    <rect
                                      x="1"
                                      y="1"
                                      width="7"
                                      height="7"
                                      fill="none"
                                    />
                                  </g>
                                  <g
                                    id="Rectangle_4594"
                                    data-name="Rectangle 4594"
                                    transform="translate(10.778 11)"
                                    fill="#fff"
                                    stroke=""
                                    stroke-width="2"
                                  >
                                    <rect
                                      width="9"
                                      height="9"
                                      rx="1"
                                      stroke="none"
                                    />
                                    <rect
                                      x="1"
                                      y="1"
                                      width="7"
                                      height="7"
                                      fill="none"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="post-list-setting-btn-mobile">
                    <button
                      onClick={(e) => handleSubmitPostSettingsData(e)}
                      style={{
                        backgroundColor:
                          localStorage.getItem("button_background_color") &&
                          localStorage.getItem("button_background_color") !=
                            "undefined" &&
                          localStorage.getItem("button_background_color") !=
                            "null"
                            ? localStorage.getItem("button_background_color")
                            : "",
                        color: localStorage.getItem("button_text_color"),
                      }}
                    >
                      <div className={settingSaveBtnLoader}>
                        <div
                          className={
                            "d-flex justify-content-center align-items-center "
                          }
                        >
                          <div className="new-loader"></div>
                        </div>
                      </div>
                      <div>{settingSaveBtnVal}</div>
                    </button>
                  </div>
                  {postState?.settings?.settingUpdatedAt && (
                    <p
                      style={{
                        display: window.matchMedia("(max-width: 1200px)")
                          .matches
                          ? "block"
                          : "none",
                        textAlign: "center",
                        color: "#bcc1ce",
                        fontSize: "12px",
                      }}
                    >
                      Updated{" "}
                      {moment(postState?.settings?.settingUpdatedAt).format(
                        "D MMMM YYYY, HH:mm"
                      )}{" "}
                    </p>
                  )}
                </>
              ) : tableView ? (
                <div className="bulkUpdatewhitebg postbulkUpdatewhitebg">
                  <BottomScrollListener
                    onBottom={
                      window.matchMedia("(min-width: 1200px)").matches
                        ? handleContainerOnBottom
                        : handleNotHitOnBottom
                    }
                    offset={100}
                  >
                    {(scrollRef) => (
                      <div ref={scrollRef} className="bulkUpdate-page">
                        {spinner === true ? (
                          <div
                            className="cmsnewspinner"
                            style={{
                              height: "100%",
                              borderRadius: "6px",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xlink="http://www.w3.org/1999/xlink"
                              style={{
                                margin: "auto",
                                background: "rgb(241, 242, 243,0)",
                                display: "block",
                                shaperendering: "auto",
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                              width="200px"
                              height="200px"
                              viewBox="0 0 100 100"
                              preserveAspectRatio="xMidYMid"
                            >
                              <g
                                transform="translate(20 50)"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="6"
                                  fill="#e6261f"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <animateTransform
                                    attributeName="transform"
                                    type="scale"
                                    begin="-0.375s"
                                    calcMode="spline"
                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                    values="0;1;0"
                                    keyTimes="0;0.5;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  ></animateTransform>
                                </circle>
                              </g>
                              <g
                                transform="translate(40 50)"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="6"
                                  fill="#f7d038"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <animateTransform
                                    attributeName="transform"
                                    type="scale"
                                    begin="-0.25s"
                                    calcMode="spline"
                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                    values="0;1;0"
                                    keyTimes="0;0.5;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  ></animateTransform>
                                </circle>
                              </g>
                              <g
                                transform="translate(60 50)"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="6"
                                  fill="#49da9a"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <animateTransform
                                    attributeName="transform"
                                    type="scale"
                                    begin="-0.125s"
                                    calcMode="spline"
                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                    values="0;1;0"
                                    keyTimes="0;0.5;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  ></animateTransform>
                                </circle>
                              </g>
                              <g
                                transform="translate(80 50)"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="6"
                                  fill="#4355db"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <animateTransform
                                    attributeName="transform"
                                    type="scale"
                                    begin="0s"
                                    calcMode="spline"
                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                    values="0;1;0"
                                    keyTimes="0;0.5;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  ></animateTransform>
                                </circle>
                              </g>
                            </svg>
                          </div>
                        ) : (
                          <>
                            <div className="bulkUpdate-page-head">
                              <div className="bulk-radio-button">
                                <input
                                  type="radio"
                                  checked={
                                    selectedArr.length ===
                                    getTotalLength(postData)
                                  }
                                />
                                <span
                                  onClick={() => handleSelected("all")}
                                  className="bulk-update-select-checkmark"
                                ></span>
                              </div>
                              <p className="bulkupdate-head-ptag">
                                <div className="bulkhead-first-div post-bulkhead-first-div">
                                  <SortIcon
                                    heading={"Post"}
                                    sortFields={sortFields}
                                    setSortFields={setSortFields}
                                    tableKey={"title"}
                                    handleSortTable={handleSortTable}
                                  />
                                </div>
                                <div className="bulkhead-second-div">
                                  <SortIcon
                                    heading={"Author"}
                                    sortFields={sortFields}
                                    setSortFields={setSortFields}
                                    tableKey={"author_info"}
                                    handleSortTable={handleSortTable}
                                  />
                                </div>
                                <div className="bulkhead-third-div">
                                  <SortIcon
                                    heading={"Created"}
                                    sortFields={sortFields}
                                    setSortFields={setSortFields}
                                    tableKey={"created_at"}
                                    handleSortTable={handleSortTable}
                                  />
                                </div>
                                <div className="bulkhead-fourth-div">
                                  <SortIcon
                                    heading={"Updated"}
                                    sortFields={sortFields}
                                    setSortFields={setSortFields}
                                    tableKey={"updated_at"}
                                    handleSortTable={handleSortTable}
                                  />
                                </div>
                                <div className="bulkhead-fifth-div">
                                  <SortIcon
                                    heading={"Status"}
                                    sortFields={sortFields}
                                    setSortFields={setSortFields}
                                    tableKey={"status"}
                                    handleSortTable={handleSortTable}
                                  />
                                </div>
                              </p>
                              <div className="bulkhead-sixth-div"></div>
                            </div>
                            <div className="bulkUpdate-page-body">
                              {bottomSpinner && (
                                <div
                                  className="form-group"
                                  style={{ background: "none", margin: "auto" }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xlink="http://www.w3.org/1999/xlink"
                                    style={{
                                      margin: "auto",
                                      background: "rgb(241, 242, 243,0)",
                                      display: "block",
                                      shaperendering: "auto",
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                    width="200px"
                                    height="100px"
                                    viewBox="0 0 100 100"
                                    preserveAspectRatio="xMidYMid"
                                  >
                                    <g
                                      transform="translate(20 50)"
                                      style={{
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                    >
                                      <circle
                                        cx="0"
                                        cy="0"
                                        r="6"
                                        fill="#e6261f"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <animateTransform
                                          attributeName="transform"
                                          type="scale"
                                          begin="-0.375s"
                                          calcMode="spline"
                                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                          values="0;1;0"
                                          keyTimes="0;0.5;1"
                                          dur="1s"
                                          repeatCount="indefinite"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        ></animateTransform>
                                      </circle>
                                    </g>
                                    <g
                                      transform="translate(40 50)"
                                      style={{
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                    >
                                      <circle
                                        cx="0"
                                        cy="0"
                                        r="6"
                                        fill="#f7d038"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <animateTransform
                                          attributeName="transform"
                                          type="scale"
                                          begin="-0.25s"
                                          calcMode="spline"
                                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                          values="0;1;0"
                                          keyTimes="0;0.5;1"
                                          dur="1s"
                                          repeatCount="indefinite"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        ></animateTransform>
                                      </circle>
                                    </g>
                                    <g
                                      transform="translate(60 50)"
                                      style={{
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                    >
                                      <circle
                                        cx="0"
                                        cy="0"
                                        r="6"
                                        fill="#49da9a"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <animateTransform
                                          attributeName="transform"
                                          type="scale"
                                          begin="-0.125s"
                                          calcMode="spline"
                                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                          values="0;1;0"
                                          keyTimes="0;0.5;1"
                                          dur="1s"
                                          repeatCount="indefinite"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        ></animateTransform>
                                      </circle>
                                    </g>
                                    <g
                                      transform="translate(80 50)"
                                      style={{
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                    >
                                      <circle
                                        cx="0"
                                        cy="0"
                                        r="6"
                                        fill="#4355db"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <animateTransform
                                          attributeName="transform"
                                          type="scale"
                                          begin="0s"
                                          calcMode="spline"
                                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                          values="0;1;0"
                                          keyTimes="0;0.5;1"
                                          dur="1s"
                                          repeatCount="indefinite"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        ></animateTransform>
                                      </circle>
                                    </g>
                                  </svg>
                                </div>
                              )}
                              {postData &&
                                postData.length > 0 &&
                                postData
                                  .filter((row, ind) =>
                                    row.title
                                      .toLowerCase()
                                      .includes(searchVal.toLowerCase())
                                  )
                                  .map((eachPost, indx) => {
                                    let status;
                                    if (eachPost.status === "publish") {
                                      status = "statusgreen";
                                    } else if (
                                      eachPost.status === "scheduled"
                                    ) {
                                      status = "statusblue";
                                    } else if (eachPost.status === "draft") {
                                      status = "statusgrey";
                                    }
                                    if (eachPost.deleted_at === null) {
                                      allCount++;
                                    }

                                    return (
                                      eachPost.deleted_at === null && (
                                        <div
                                          key={`post_${indx}`}
                                          className={`${
                                            eachPost.show_in_results ||
                                            eachPost.show_in_results ===
                                              undefined
                                              ? "bulk-update-type-div"
                                              : "bulk-update-type-div-disable"
                                          }`}
                                        >
                                          <div className="bulk-radio-button">
                                            <input
                                              type="radio"
                                              checked={selectedArr.includes(
                                                eachPost._id
                                              )}
                                            />
                                            <span
                                              onClick={() =>
                                                handleSelected(eachPost._id)
                                              }
                                              className="bulk-update-select-checkmark"
                                            ></span>
                                          </div>

                                          <Link
                                            to={`/app/post/${eachPost._id}`}
                                            className="bulk-update-clickable"
                                          >
                                            {/* <div className="bulkUpdate-page-body-firstdiv"> */}
                                            <Link
                                              className="bulkUpdate-page-body-firstdiv bulkUpdate-post-body-firstdiv"
                                              to={`/app/post/${eachPost._id}`}
                                            >
                                              <img
                                                className="bulkupdate-post-thumbnail"
                                                src={
                                                  eachPost.source_file
                                                    ? eachPost.source_file?.indexOf(
                                                        "https"
                                                      ) > -1
                                                      ? eachPost.source_file
                                                      : process.env
                                                          .REACT_APP_Image_Path +
                                                        localStorage.getItem(
                                                          "user_id"
                                                        ) +
                                                        "/" +
                                                        localStorage.getItem(
                                                          "Appid"
                                                        ) +
                                                        process.env
                                                          .REACT_APP_CMS_Media +
                                                        eachPost.source_file
                                                    : imagebackground
                                                }
                                                alt=""
                                              />
                                              <span className="align-self-center">
                                                {eachPost.title &&
                                                eachPost.title.length >= 50
                                                  ? eachPost.title.substring(
                                                      0,
                                                      50
                                                    ) + "..."
                                                  : eachPost.title}{" "}
                                              </span>
                                            </Link>
                                            {/* </div> */}

                                            <div className="bulkUpdate-page-body-seconddiv">
                                              <img
                                                style={{
                                                  width: "24px",
                                                  borderRadius: "50%",
                                                  height: "24px",
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "cover",
                                                }}
                                                src={
                                                  eachPost.author_info
                                                    .profile_image
                                                    ? eachPost.author_info.profile_image?.indexOf(
                                                        "https"
                                                      ) === -1
                                                      ? process.env
                                                          .REACT_APP_Image_Path +
                                                        eachPost.author_info
                                                          .id +
                                                        "/" +
                                                        "profile" +
                                                        "/" +
                                                        eachPost.author_info
                                                          .profile_image
                                                      : eachPost.author_info
                                                          .profile_image
                                                    : userdefaultImgOnError
                                                }
                                                onError={({
                                                  currentTarget,
                                                }) => {
                                                  currentTarget.onerror = null;
                                                  currentTarget.src =
                                                    userdefaultImgOnError;
                                                }}
                                                className="img-fluid"
                                                alt="profile logo"
                                              />
                                              {eachPost.author_info?.name}
                                            </div>
                                            <div className="bulkUpdate-page-body-thirddiv">
                                              {eachPost.created_at &&
                                                moment(
                                                  eachPost.created_at
                                                ).format("D MMMM YYYY, HH:mm")}
                                            </div>
                                            <div className="bulkUpdate-page-body-fourthdiv">
                                              {eachPost.updated_at &&
                                                moment(
                                                  eachPost.updated_at
                                                ).format("D MMMM YYYY, HH:mm")}
                                            </div>
                                            <div
                                              className="bulkUpdate-page-body-fifthdiv"
                                              style={{ color: "#7782A1" }}
                                            >
                                              <div
                                                className={`pagestatus ${status}`}
                                              >
                                                {eachPost.status === "publish"
                                                  ? "published"
                                                  : eachPost.status}
                                              </div>
                                            </div>
                                          </Link>
                                          <div>
                                            <div className="pageaction bulkUpdate-page-body-sixthdiv">
                                              <span
                                                className="cms-list-visiblity"
                                                onClick={(e) =>
                                                  handleEyeButton(
                                                    e,
                                                    eachPost._id,
                                                    eachPost.show_in_results,
                                                    indx,
                                                    eachPost.child
                                                  )
                                                }
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="25.284"
                                                  height="20.465"
                                                  viewBox="0 0 25.284 20.465"
                                                >
                                                  <g
                                                    id="Group_39189"
                                                    data-name="Group 39189"
                                                    transform="translate(0.568 0.353)"
                                                  >
                                                    <path
                                                      id="Path_103079"
                                                      data-name="Path 103079"
                                                      d="M68.716,9.742a13.852,13.852,0,0,1-12.07,7.684A13.876,13.876,0,0,1,44.568,9.742,13.876,13.876,0,0,1,56.646,2.058,13.852,13.852,0,0,1,68.716,9.742Z"
                                                      transform="translate(-44.568 0.138)"
                                                      fill="none"
                                                      stroke=""
                                                      stroke-miterlimit="10"
                                                      stroke-width="1"
                                                    />
                                                    <ellipse
                                                      id="Ellipse_8629"
                                                      data-name="Ellipse 8629"
                                                      cx="3.842"
                                                      cy="3.842"
                                                      rx="3.842"
                                                      ry="3.842"
                                                      transform="translate(8.233 6.038)"
                                                      fill="none"
                                                      stroke=""
                                                      stroke-miterlimit="10"
                                                      stroke-width="1"
                                                    />
                                                    <line
                                                      id="Line_430"
                                                      data-name="Line 430"
                                                      x2="19.758"
                                                      y2="19.758"
                                                      transform="translate(2.196 0.001)"
                                                      fill="none"
                                                      stroke=""
                                                      stroke-miterlimit="10"
                                                      stroke-width="1"
                                                    />
                                                  </g>
                                                </svg>
                                              </span>

                                              {!eachPost.is_featured ? (
                                                <img
                                                  onClick={(e) =>
                                                    handleFeaturedPost(
                                                      e,
                                                      eachPost._id,
                                                      eachPost.is_featured,
                                                      eachPost,
                                                      indx
                                                    )
                                                  }
                                                  className="img-fluid"
                                                  style={{
                                                    marginRight: "20px",
                                                  }}
                                                  src={
                                                    require("../../assets/images/dashboard/cms/white-star-icon.png")
                                                      .default
                                                  }
                                                  alt="featured"
                                                />
                                              ) : (
                                                <img
                                                  onClick={(e) =>
                                                    handleFeaturedPost(
                                                      e,
                                                      eachPost._id,
                                                      eachPost.is_featured,
                                                      eachPost,
                                                      indx
                                                    )
                                                  }
                                                  className="img-fluid"
                                                  style={{
                                                    marginRight: "20px",
                                                  }}
                                                  src={
                                                    require("../../assets/images/dashboard/cms/yellow-star.png")
                                                      .default
                                                  }
                                                />
                                              )}

                                              <img
                                                onClick={(e) => {
                                                  const listBody =
                                                    document.querySelector(
                                                      ".bulkUpdate-page"
                                                    );
                                                  if (listBody) {
                                                    listBody?.scrollTo({
                                                      top: 0,
                                                      behavior: "smooth",
                                                    });
                                                  }
                                                  handleClonePost(e, eachPost);
                                                }}
                                                className="img-fluid"
                                                src={
                                                  require("../../assets/images/dashboard/cms/duplicate.png")
                                                    .default
                                                }
                                              />

                                              <button
                                                style={{
                                                  position: "relative",
                                                  top: "-1px",
                                                }}
                                                className="pages-delete-mob"
                                                onClick={(e) =>
                                                  handleTrashedPopUp(
                                                    e,
                                                    "add",
                                                    eachPost._id
                                                  )
                                                }
                                              >
                                                <img
                                                  className="img-fluid"
                                                  src={
                                                    require("../../assets/images/dashboard/cms/delete.png")
                                                      .default
                                                  }
                                                />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    );
                                  })}

                              {paginationSpinner &&
                                window.matchMedia("(min-width: 1200px)")
                                  .matches && (
                                  <div
                                    className={`form-group`}
                                    style={{
                                      background: "none",
                                      margin: "auto",
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xlink="http://www.w3.org/1999/xlink"
                                      style={{
                                        margin: "auto",
                                        background: "rgb(241, 242, 243,0)",
                                        display: "block",
                                        shaperendering: "auto",
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                      width="200px"
                                      height="100px"
                                      viewBox="0 0 100 100"
                                      preserveAspectRatio="xMidYMid"
                                    >
                                      <g
                                        transform="translate(20 50)"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <circle
                                          cx="0"
                                          cy="0"
                                          r="6"
                                          fill="#e6261f"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        >
                                          <animateTransform
                                            attributeName="transform"
                                            type="scale"
                                            begin="-0.375s"
                                            calcMode="spline"
                                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                            values="0;1;0"
                                            keyTimes="0;0.5;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            style={{
                                              animationplaystate: "running",
                                              animationdelay: "0s",
                                            }}
                                          ></animateTransform>
                                        </circle>
                                      </g>
                                      <g
                                        transform="translate(40 50)"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <circle
                                          cx="0"
                                          cy="0"
                                          r="6"
                                          fill="#f7d038"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        >
                                          <animateTransform
                                            attributeName="transform"
                                            type="scale"
                                            begin="-0.25s"
                                            calcMode="spline"
                                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                            values="0;1;0"
                                            keyTimes="0;0.5;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            style={{
                                              animationplaystate: "running",
                                              animationdelay: "0s",
                                            }}
                                          ></animateTransform>
                                        </circle>
                                      </g>
                                      <g
                                        transform="translate(60 50)"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <circle
                                          cx="0"
                                          cy="0"
                                          r="6"
                                          fill="#49da9a"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        >
                                          <animateTransform
                                            attributeName="transform"
                                            type="scale"
                                            begin="-0.125s"
                                            calcMode="spline"
                                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                            values="0;1;0"
                                            keyTimes="0;0.5;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            style={{
                                              animationplaystate: "running",
                                              animationdelay: "0s",
                                            }}
                                          ></animateTransform>
                                        </circle>
                                      </g>
                                      <g
                                        transform="translate(80 50)"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <circle
                                          cx="0"
                                          cy="0"
                                          r="6"
                                          fill="#4355db"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        >
                                          <animateTransform
                                            attributeName="transform"
                                            type="scale"
                                            begin="0s"
                                            calcMode="spline"
                                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                            values="0;1;0"
                                            keyTimes="0;0.5;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            style={{
                                              animationplaystate: "running",
                                              animationdelay: "0s",
                                            }}
                                          ></animateTransform>
                                        </circle>
                                      </g>
                                    </svg>
                                  </div>
                                )}
                            </div>{" "}
                          </>
                        )}
                      </div>
                    )}
                  </BottomScrollListener>
                </div>
              ) : (
                <div className="whitebg all-added-pages">
                  {postData.length > 0 && (
                    <div className="pages-list-title">
                      <h2>Post</h2>
                    </div>
                  )}

                  <div
                    style={{
                      display: postData && postData.length > 0 ? "flex" : "",
                    }}
                  >
                    <BottomScrollListener
                      onBottom={
                        window.matchMedia("(min-width: 1200px)").matches
                          ? handleContainerOnBottom
                          : handleNotHitOnBottom
                      }
                      offset={100}
                    >
                      {(scrollRef) => (
                        <div ref={scrollRef} className="pageslistdetail">
                          {spinner === true ? (
                            <div
                              className="cmsnewspinner"
                              style={{ height: "78vh" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xlink="http://www.w3.org/1999/xlink"
                                style={{
                                  margin: "auto",
                                  background: "rgb(241, 242, 243,0)",
                                  display: "block",
                                  shaperendering: "auto",
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                                width="200px"
                                height="200px"
                                viewBox="0 0 100 100"
                                preserveAspectRatio="xMidYMid"
                              >
                                <g
                                  transform="translate(20 50)"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <circle
                                    cx="0"
                                    cy="0"
                                    r="6"
                                    fill="#e6261f"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  >
                                    <animateTransform
                                      attributeName="transform"
                                      type="scale"
                                      begin="-0.375s"
                                      calcMode="spline"
                                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                      values="0;1;0"
                                      keyTimes="0;0.5;1"
                                      dur="1s"
                                      repeatCount="indefinite"
                                      style={{
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                    ></animateTransform>
                                  </circle>
                                </g>
                                <g
                                  transform="translate(40 50)"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <circle
                                    cx="0"
                                    cy="0"
                                    r="6"
                                    fill="#f7d038"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  >
                                    <animateTransform
                                      attributeName="transform"
                                      type="scale"
                                      begin="-0.25s"
                                      calcMode="spline"
                                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                      values="0;1;0"
                                      keyTimes="0;0.5;1"
                                      dur="1s"
                                      repeatCount="indefinite"
                                      style={{
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                    ></animateTransform>
                                  </circle>
                                </g>
                                <g
                                  transform="translate(60 50)"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <circle
                                    cx="0"
                                    cy="0"
                                    r="6"
                                    fill="#49da9a"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  >
                                    <animateTransform
                                      attributeName="transform"
                                      type="scale"
                                      begin="-0.125s"
                                      calcMode="spline"
                                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                      values="0;1;0"
                                      keyTimes="0;0.5;1"
                                      dur="1s"
                                      repeatCount="indefinite"
                                      style={{
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                    ></animateTransform>
                                  </circle>
                                </g>
                                <g
                                  transform="translate(80 50)"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <circle
                                    cx="0"
                                    cy="0"
                                    r="6"
                                    fill="#4355db"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  >
                                    <animateTransform
                                      attributeName="transform"
                                      type="scale"
                                      begin="0s"
                                      calcMode="spline"
                                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                      values="0;1;0"
                                      keyTimes="0;0.5;1"
                                      dur="1s"
                                      repeatCount="indefinite"
                                      style={{
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                    ></animateTransform>
                                  </circle>
                                </g>
                              </svg>
                            </div>
                          ) : (
                            <TabContent activeTab={activeTab}>
                              <TabPane tabId="1">
                                {bottomSpinner && (
                                  <div
                                    className="form-group"
                                    style={{
                                      background: "none",
                                      margin: "auto",
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xlink="http://www.w3.org/1999/xlink"
                                      style={{
                                        margin: "auto",
                                        background: "rgb(241, 242, 243,0)",
                                        display: "block",
                                        shaperendering: "auto",
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                      width="200px"
                                      height="100px"
                                      viewBox="0 0 100 100"
                                      preserveAspectRatio="xMidYMid"
                                    >
                                      <g
                                        transform="translate(20 50)"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <circle
                                          cx="0"
                                          cy="0"
                                          r="6"
                                          fill="#e6261f"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        >
                                          <animateTransform
                                            attributeName="transform"
                                            type="scale"
                                            begin="-0.375s"
                                            calcMode="spline"
                                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                            values="0;1;0"
                                            keyTimes="0;0.5;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            style={{
                                              animationplaystate: "running",
                                              animationdelay: "0s",
                                            }}
                                          ></animateTransform>
                                        </circle>
                                      </g>
                                      <g
                                        transform="translate(40 50)"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <circle
                                          cx="0"
                                          cy="0"
                                          r="6"
                                          fill="#f7d038"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        >
                                          <animateTransform
                                            attributeName="transform"
                                            type="scale"
                                            begin="-0.25s"
                                            calcMode="spline"
                                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                            values="0;1;0"
                                            keyTimes="0;0.5;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            style={{
                                              animationplaystate: "running",
                                              animationdelay: "0s",
                                            }}
                                          ></animateTransform>
                                        </circle>
                                      </g>
                                      <g
                                        transform="translate(60 50)"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <circle
                                          cx="0"
                                          cy="0"
                                          r="6"
                                          fill="#49da9a"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        >
                                          <animateTransform
                                            attributeName="transform"
                                            type="scale"
                                            begin="-0.125s"
                                            calcMode="spline"
                                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                            values="0;1;0"
                                            keyTimes="0;0.5;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            style={{
                                              animationplaystate: "running",
                                              animationdelay: "0s",
                                            }}
                                          ></animateTransform>
                                        </circle>
                                      </g>
                                      <g
                                        transform="translate(80 50)"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <circle
                                          cx="0"
                                          cy="0"
                                          r="6"
                                          fill="#4355db"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        >
                                          <animateTransform
                                            attributeName="transform"
                                            type="scale"
                                            begin="0s"
                                            calcMode="spline"
                                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                            values="0;1;0"
                                            keyTimes="0;0.5;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            style={{
                                              animationplaystate: "running",
                                              animationdelay: "0s",
                                            }}
                                          ></animateTransform>
                                        </circle>
                                      </g>
                                    </svg>
                                  </div>
                                )}
                                {postData && postData.length > 0 ? (
                                  postData
                                    .filter((row, ind) =>
                                      row.title
                                        .toLowerCase()
                                        .includes(searchVal.toLowerCase())
                                    )
                                    .map((eachPost, indx) => {
                                      let status;
                                      if (eachPost.status === "publish") {
                                        status = "statusgreen";
                                      } else if (
                                        eachPost.status === "scheduled"
                                      ) {
                                        status = "statusblue";
                                      } else if (eachPost.status === "draft") {
                                        status = "statusgrey";
                                      }
                                      if (eachPost.deleted_at === null) {
                                        allCount++;
                                      }

                                      return (
                                        eachPost.deleted_at === null && (
                                          <div
                                            className={`postdetail ${
                                              eachPost.show_in_results === 1 ||
                                              eachPost.show_in_results ===
                                                undefined
                                                ? ""
                                                : "cms-entry-hidden"
                                            }`}
                                          >
                                            <div className="postdetail-name">
                                              <Link
                                                to={`/app/post/${eachPost._id}`}
                                              ></Link>
                                              <div
                                                className="post-thumbnail"
                                                style={{
                                                  backgroundImage:
                                                    "url(" +
                                                    (!eachPost.source_file
                                                      ? imagebackground
                                                      : eachPost.source_file.indexOf(
                                                          "https"
                                                        ) > -1
                                                      ? eachPost.source_file
                                                      : process.env
                                                          .REACT_APP_Image_Path +
                                                        localStorage.getItem(
                                                          "user_id"
                                                        ) +
                                                        "/" +
                                                        localStorage.getItem(
                                                          "Appid"
                                                        ) +
                                                        process.env
                                                          .REACT_APP_CMS_Media +
                                                        eachPost.source_file) +
                                                    ")",
                                                }}
                                              ></div>
                                              <div>
                                                <h3>{eachPost.title}</h3>

                                                <div className="postcreator-detail">
                                                  {eachPost.author_info !==
                                                    undefined &&
                                                  eachPost.author_info !==
                                                    null ? (
                                                    <div>
                                                      {eachPost.author_info
                                                        .profile_image === "" ||
                                                      eachPost.author_info
                                                        .profile_image ===
                                                        null ||
                                                      eachPost.author_info
                                                        .profile_image ===
                                                        "null" ||
                                                      eachPost.author_info
                                                        .profile_image ===
                                                        undefined ||
                                                      eachPost.author_info
                                                        .profile_image ===
                                                        "undefined" ||
                                                      !eachPost.author_info
                                                        .profile_image ? (
                                                        <img
                                                          style={{
                                                            width: "24px",
                                                            height: "24px",
                                                          }}
                                                          src={
                                                            userdefaultImgOnError
                                                          }
                                                          className="img-fluid"
                                                          alt="profile logo"
                                                        />
                                                      ) : (
                                                        <>
                                                          {eachPost.author_info.profile_image.indexOf(
                                                            "https"
                                                          ) === -1 ? (
                                                            <img
                                                              className="header-profil-img"
                                                              src={
                                                                process.env
                                                                  .REACT_APP_Image_Path +
                                                                eachPost
                                                                  .author_info
                                                                  .id +
                                                                "/" +
                                                                "profile" +
                                                                "/" +
                                                                eachPost
                                                                  .author_info
                                                                  .profile_image
                                                              }
                                                              style={{
                                                                width: "24px",
                                                                borderRadius:
                                                                  "50%",
                                                                height: "24px",
                                                                backgroundSize:
                                                                  "cover",
                                                              }}
                                                              onError={({
                                                                currentTarget,
                                                              }) => {
                                                                currentTarget.onerror =
                                                                  null;
                                                                currentTarget.src =
                                                                  userdefaultImgOnError;
                                                              }}
                                                            />
                                                          ) : (
                                                            <img
                                                              className="header-profil-img"
                                                              src={
                                                                eachPost
                                                                  .author_info
                                                                  .profile_image
                                                              }
                                                              style={{
                                                                width: "24px",
                                                                borderRadius:
                                                                  "50%",
                                                                height: "24px",
                                                                backgroundRepeat:
                                                                  "no-repeat",
                                                                backgroundSize:
                                                                  "cover",
                                                              }}
                                                              onError={({
                                                                currentTarget,
                                                              }) => {
                                                                currentTarget.onerror =
                                                                  null;
                                                                currentTarget.src =
                                                                  userdefaultImgOnError;
                                                              }}
                                                            />
                                                          )}
                                                        </>
                                                      )}
                                                    </div>
                                                  ) : eachPost.owner_user ? (
                                                    <div>
                                                      {eachPost.owner_user
                                                        .profile_image === "" ||
                                                      eachPost.owner_user
                                                        .profile_image ===
                                                        null ||
                                                      eachPost.owner_user
                                                        .profile_image ===
                                                        "null" ||
                                                      eachPost.owner_user
                                                        .profile_image ===
                                                        undefined ||
                                                      eachPost.owner_user
                                                        .profile_image ===
                                                        "undefined" ||
                                                      !eachPost.owner_user
                                                        .profile_image ? (
                                                        <img
                                                          style={{
                                                            width: "24px",
                                                            height: "24px",
                                                          }}
                                                          src={
                                                            userdefaultImgOnError
                                                          }
                                                          className="img-fluid"
                                                          alt="profile logo"
                                                        />
                                                      ) : (
                                                        <>
                                                          {eachPost.owner_user.profile_image.indexOf(
                                                            "https"
                                                          ) === -1 ? (
                                                            <img
                                                              className="header-profil-img"
                                                              src={
                                                                process.env
                                                                  .REACT_APP_Image_Path +
                                                                eachPost
                                                                  .owner_user
                                                                  .id +
                                                                "/" +
                                                                "profile" +
                                                                "/" +
                                                                eachPost
                                                                  .owner_user
                                                                  .profile_image
                                                              }
                                                              style={{
                                                                width: "24px",
                                                                borderRadius:
                                                                  "50%",
                                                                height: "24px",
                                                                backgroundSize:
                                                                  "cover",
                                                              }}
                                                              onError={({
                                                                currentTarget,
                                                              }) => {
                                                                currentTarget.onerror =
                                                                  null;
                                                                currentTarget.src =
                                                                  userdefaultImgOnError;
                                                              }}
                                                            />
                                                          ) : (
                                                            <img
                                                              className="header-profil-img"
                                                              src={
                                                                eachPost
                                                                  .owner_user
                                                                  .profile_image
                                                              }
                                                              style={{
                                                                width: "24px",
                                                                borderRadius:
                                                                  "50%",
                                                                height: "24px",
                                                                backgroundRepeat:
                                                                  "no-repeat",
                                                                backgroundSize:
                                                                  "cover",
                                                              }}
                                                              onError={({
                                                                currentTarget,
                                                              }) => {
                                                                currentTarget.onerror =
                                                                  null;
                                                                currentTarget.src =
                                                                  userdefaultImgOnError;
                                                              }}
                                                            />
                                                          )}
                                                        </>
                                                      )}
                                                    </div>
                                                  ) : (
                                                    <div>
                                                      {profileimage === "" ||
                                                      profileimage === null ||
                                                      profileimage === "null" ||
                                                      profileimage ===
                                                        undefined ||
                                                      profileimage ===
                                                        "undefined" ||
                                                      !profileimage ? (
                                                        <img
                                                          style={{
                                                            width: "24px",
                                                            //borderRadius: "50%",
                                                            height: "24px",
                                                            backgroundRepeat:
                                                              "no-repeat",
                                                            backgroundSize:
                                                              "cover",
                                                          }}
                                                          src={
                                                            userdefaultImgOnError
                                                          }
                                                          className="img-fluid"
                                                          alt="profile logo"
                                                        />
                                                      ) : (
                                                        <>
                                                          {profileimage.indexOf(
                                                            "https"
                                                          ) === -1 ? (
                                                            <img
                                                              className="header-profil-img"
                                                              style={{
                                                                // background:
                                                                //   "url(" +
                                                                //   profilepath +
                                                                //   profileimage +
                                                                //   ")",
                                                                width: "24px",
                                                                borderRadius:
                                                                  "50%",
                                                                height: "24px",
                                                                backgroundSize:
                                                                  "cover",
                                                              }}
                                                              src={
                                                                profilepath +
                                                                profileimage
                                                              }
                                                              onError={({
                                                                currentTarget,
                                                              }) => {
                                                                currentTarget.onerror =
                                                                  null;
                                                                currentTarget.src =
                                                                  userdefaultImgOnError;
                                                              }}
                                                            />
                                                          ) : (
                                                            <img
                                                              className="header-profil-img"
                                                              style={{
                                                                // background:
                                                                //   "url(" + profileimage + ")",
                                                                width: "24px",
                                                                borderRadius:
                                                                  "50%",
                                                                height: "24px",
                                                                backgroundRepeat:
                                                                  "no-repeat",
                                                                backgroundSize:
                                                                  "cover",
                                                              }}
                                                              src={profileimage}
                                                              onError={({
                                                                currentTarget,
                                                              }) => {
                                                                currentTarget.onerror =
                                                                  null;
                                                                currentTarget.src =
                                                                  userdefaultImgOnError;
                                                              }}
                                                            />
                                                          )}
                                                        </>
                                                      )}
                                                    </div>
                                                  )}

                                                  <h2>
                                                    {eachPost.author_info !==
                                                      undefined &&
                                                    eachPost.author_info !==
                                                      null
                                                      ? eachPost.author_info
                                                          .name
                                                      : eachPost.owner_user
                                                      ? eachPost.owner_user.name
                                                      : localStorage.getItem(
                                                          "user_name"
                                                        )}
                                                  </h2>
                                                </div>

                                                <div className="post-status-detail">
                                                  <p
                                                    className={`pagestatus ${status}`}
                                                  >
                                                    {eachPost.status ===
                                                    "publish"
                                                      ? "published"
                                                      : eachPost.status}
                                                  </p>

                                                  <h4>
                                                    {moment(
                                                      eachPost.created_at
                                                    ).format(
                                                      "D MMMM YYYY, HH:mm"
                                                    )}
                                                  </h4>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="pageaction">
                                              <span
                                                className="cms-list-visiblity cms-icon-tooltip"
                                                onClick={(e) =>
                                                  handleEyeButton(
                                                    e,
                                                    eachPost._id,
                                                    eachPost.show_in_results,
                                                    indx
                                                  )
                                                }
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="25.284"
                                                  height="20.465"
                                                  viewBox="0 0 25.284 20.465"
                                                >
                                                  <g
                                                    id="Group_39189"
                                                    data-name="Group 39189"
                                                    transform="translate(0.568 0.353)"
                                                  >
                                                    <path
                                                      id="Path_103079"
                                                      data-name="Path 103079"
                                                      d="M68.716,9.742a13.852,13.852,0,0,1-12.07,7.684A13.876,13.876,0,0,1,44.568,9.742,13.876,13.876,0,0,1,56.646,2.058,13.852,13.852,0,0,1,68.716,9.742Z"
                                                      transform="translate(-44.568 0.138)"
                                                      fill="none"
                                                      stroke=""
                                                      stroke-miterlimit="10"
                                                      stroke-width="1"
                                                    />
                                                    <ellipse
                                                      id="Ellipse_8629"
                                                      data-name="Ellipse 8629"
                                                      cx="3.842"
                                                      cy="3.842"
                                                      rx="3.842"
                                                      ry="3.842"
                                                      transform="translate(8.233 6.038)"
                                                      fill="none"
                                                      stroke=""
                                                      stroke-miterlimit="10"
                                                      stroke-width="1"
                                                    />
                                                    <line
                                                      id="Line_430"
                                                      data-name="Line 430"
                                                      x2="19.758"
                                                      y2="19.758"
                                                      transform="translate(2.196 0.001)"
                                                      fill="none"
                                                      stroke=""
                                                      stroke-miterlimit="10"
                                                      stroke-width="1"
                                                    />
                                                  </g>
                                                </svg>
                                                <span
                                                  className="cms-icon-tooltipsubheader"
                                                  style={{
                                                    width: " fit-content",
                                                    padding: "4px 10px",
                                                  }}
                                                >
                                                  Hide
                                                </span>
                                              </span>

                                              <span
                                                className="cms-icon-tooltip"
                                                style={{ margin: "unset" }}
                                              >
                                                {eachPost.status ===
                                                  "publish" && (
                                                  <>
                                                    {eachPost.is_featured ? (
                                                      <img
                                                        className="img-fluid"
                                                        onClick={(e) =>
                                                          handleFeaturedPost(
                                                            e,
                                                            eachPost._id,
                                                            eachPost.is_featured,
                                                            eachPost,
                                                            indx
                                                          )
                                                        }
                                                        src={
                                                          require("../../assets/images/dashboard/cms/yellow-star.png")
                                                            .default
                                                        }
                                                      />
                                                    ) : (
                                                      <img
                                                        className="img-fluid"
                                                        onClick={(e) =>
                                                          handleFeaturedPost(
                                                            e,
                                                            eachPost._id,
                                                            eachPost.is_featured,
                                                            eachPost,
                                                            indx
                                                          )
                                                        }
                                                        src={
                                                          require("../../assets/images/dashboard/cms/white-star-icon.png")
                                                            .default
                                                        }
                                                        alt="featured"
                                                      />
                                                    )}
                                                  </>
                                                )}
                                                <span
                                                  className="cms-icon-tooltipsubheader"
                                                  style={{
                                                    width: " fit-content",
                                                    padding: "4px 10px",
                                                  }}
                                                >
                                                  Feature
                                                </span>
                                              </span>
                                              <span
                                                className="cms-icon-tooltip"
                                                style={{ marginLeft: "20px" }}
                                              >
                                                {/* <Link to={`/app/post/${eachPost._id}`}> */}
                                                <img
                                                  onClick={(e) =>
                                                    handleClonePost(e, eachPost)
                                                  }
                                                  className="img-fluid"
                                                  src={
                                                    require("../../assets/images/dashboard/cms/duplicate.png")
                                                      .default
                                                  }
                                                />
                                                <span
                                                  className="cms-icon-tooltipsubheader"
                                                  style={{
                                                    width: " fit-content",
                                                    padding: "4px 10px",
                                                  }}
                                                >
                                                  Copy
                                                </span>
                                              </span>
                                              {/* </Link> */}

                                              <button
                                                style={{
                                                  marginLeft: "20px",
                                                  position: "relative",
                                                  top: "-2px",
                                                }}
                                                onClick={(e) =>
                                                  handleTrashedPopUp(
                                                    e,
                                                    "add",
                                                    eachPost._id
                                                  )
                                                }
                                              >
                                                <span
                                                  className="cms-icon-tooltip cms-icon-tooltip-delete"
                                                  style={{ margin: "unset" }}
                                                >
                                                  <img
                                                    className="img-fluid"
                                                    src={
                                                      require("../../assets/images/dashboard/cms/delete.png")
                                                        .default
                                                    }
                                                  />
                                                  <span
                                                    className="cms-icon-tooltipsubheader"
                                                    style={{
                                                      width: " fit-content",
                                                      padding: "4px 10px",
                                                    }}
                                                  >
                                                    {" "}
                                                    Delete
                                                  </span>
                                                </span>
                                              </button>
                                            </div>
                                          </div>
                                        )
                                      );
                                    })
                                ) : (
                                  <div className="nopagecreated 3">
                                    <div>
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/no-post-create.png")
                                            .default
                                        }
                                      />
                                      <p>
                                        Nothing here at the moment. Come back
                                        later?
                                      </p>
                                    </div>
                                  </div>
                                )}

                                {/* {allCount === 0 && postData && postData.length > 0 && (
                      <div className="nopagecreated 4">
                        <div>
                        <img
                            src={
                              require("../../assets/images/dashboard/no-post-create.png")
                                .default
                            }
                          />
                          <p>Nothing here at the moment. Come back later?</p>
                        </div>
                      </div>
                    )} */}

                                {paginationSpinner &&
                                  window.matchMedia("(min-width: 1200px)")
                                    .matches && (
                                    <div
                                      className={`form-group`}
                                      style={{
                                        background: "none",
                                        margin: "auto",
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xlink="http://www.w3.org/1999/xlink"
                                        style={{
                                          margin: "auto",
                                          background: "rgb(241, 242, 243,0)",
                                          display: "block",
                                          shaperendering: "auto",
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                        width="200px"
                                        height="100px"
                                        viewBox="0 0 100 100"
                                        preserveAspectRatio="xMidYMid"
                                      >
                                        <g
                                          transform="translate(20 50)"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        >
                                          <circle
                                            cx="0"
                                            cy="0"
                                            r="6"
                                            fill="#e6261f"
                                            style={{
                                              animationplaystate: "running",
                                              animationdelay: "0s",
                                            }}
                                          >
                                            <animateTransform
                                              attributeName="transform"
                                              type="scale"
                                              begin="-0.375s"
                                              calcMode="spline"
                                              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                              values="0;1;0"
                                              keyTimes="0;0.5;1"
                                              dur="1s"
                                              repeatCount="indefinite"
                                              style={{
                                                animationplaystate: "running",
                                                animationdelay: "0s",
                                              }}
                                            ></animateTransform>
                                          </circle>
                                        </g>
                                        <g
                                          transform="translate(40 50)"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        >
                                          <circle
                                            cx="0"
                                            cy="0"
                                            r="6"
                                            fill="#f7d038"
                                            style={{
                                              animationplaystate: "running",
                                              animationdelay: "0s",
                                            }}
                                          >
                                            <animateTransform
                                              attributeName="transform"
                                              type="scale"
                                              begin="-0.25s"
                                              calcMode="spline"
                                              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                              values="0;1;0"
                                              keyTimes="0;0.5;1"
                                              dur="1s"
                                              repeatCount="indefinite"
                                              style={{
                                                animationplaystate: "running",
                                                animationdelay: "0s",
                                              }}
                                            ></animateTransform>
                                          </circle>
                                        </g>
                                        <g
                                          transform="translate(60 50)"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        >
                                          <circle
                                            cx="0"
                                            cy="0"
                                            r="6"
                                            fill="#49da9a"
                                            style={{
                                              animationplaystate: "running",
                                              animationdelay: "0s",
                                            }}
                                          >
                                            <animateTransform
                                              attributeName="transform"
                                              type="scale"
                                              begin="-0.125s"
                                              calcMode="spline"
                                              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                              values="0;1;0"
                                              keyTimes="0;0.5;1"
                                              dur="1s"
                                              repeatCount="indefinite"
                                              style={{
                                                animationplaystate: "running",
                                                animationdelay: "0s",
                                              }}
                                            ></animateTransform>
                                          </circle>
                                        </g>
                                        <g
                                          transform="translate(80 50)"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        >
                                          <circle
                                            cx="0"
                                            cy="0"
                                            r="6"
                                            fill="#4355db"
                                            style={{
                                              animationplaystate: "running",
                                              animationdelay: "0s",
                                            }}
                                          >
                                            <animateTransform
                                              attributeName="transform"
                                              type="scale"
                                              begin="0s"
                                              calcMode="spline"
                                              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                              values="0;1;0"
                                              keyTimes="0;0.5;1"
                                              dur="1s"
                                              repeatCount="indefinite"
                                              style={{
                                                animationplaystate: "running",
                                                animationdelay: "0s",
                                              }}
                                            ></animateTransform>
                                          </circle>
                                        </g>
                                      </svg>
                                    </div>
                                  )}
                              </TabPane>
                            </TabContent>
                          )}
                        </div>
                      )}
                    </BottomScrollListener>
                    {postData.length > 0 && (
                      <div className="posts-preview-list">
                        <div className="posts-preview-list-box">
                          <div className="post-listing-head">
                            <div className="post-listing-head-left">
                              <img
                                src={
                                  require("../../assets/images/dashboard/header-back-arrow.svg")
                                    .default
                                }
                              />

                              <h3 className="post-listing-heading">
                                Post list
                              </h3>
                            </div>

                            <div className="post-listing-head-right">
                              {showSearch && (
                                <img
                                  src={
                                    require("../../assets/images/dashboard/search-icon.svg")
                                      .default
                                  }
                                />
                              )}

                              {( postState.settings.showBookmarkIconInHeader && postState.settings.enableBookmarkingOnList ) ? 
                                <img
                                  className="post-list-bookmark"
                                  src={
                                    require("../../assets/images/dashboard/bookmark-icon.svg")
                                      .default
                                  }
                                />
                                :
                                <></>
                              }
                            </div>
                          </div>

                          <div className="post-listing-filters">
                            <div className="post-listing-filters-left">
                              {postState.settings.showSortIconOnList && (
                                <img
                                  style={{ width: "16px", height: "16px" }}
                                  src={
                                    require("../../assets/images/dashboard/sort-icon.svg")
                                      .default
                                  }
                                />
                              )}
                              {postState.settings.showFilterIconOnList && (
                                <img
                                  style={{ width: "16px", height: "16px" }}
                                  src={
                                    require("../../assets/images/dashboard/filter-icon.svg")
                                      .default
                                  }
                                />
                              )}
                            </div>
                            <div className="post-listing-filters-right">
                              {postState.settings.showTotalCountOnList && postData?.length > 0 && (
                                <p className="total-post-count">{postData.length}</p>
                              )}

                              {previewListingView === "grid" ? (
                                <img
                                  style={{ width: "16px", height: "16px" }}
                                  src={
                                    require("../../assets/images/dashboard/list-icon.svg")
                                      .default
                                  }
                                />
                              ) : (
                                <img
                                  style={{ width: "16px", height: "16px" }}
                                  src={
                                    require("../../assets/images/dashboard/grid-icon.svg")
                                      .default
                                  }
                                />
                              )}
                            </div>
                          </div>

                          {previewListingView === "grid" ? (
                            <div className="post-listing-style-one">
                              {postData && postData.length > 0 ? (
                                postData.map((eachPost, indx) => {
                                  return (
                                    (eachPost.show_in_results === 1 ||
                                      eachPost.show_in_results ===
                                        undefined) && (
                                      <div className="post-list-type-one">
                                        <div
                                          className="post-type-one-thumbnail"
                                          style={{
                                            height: calculateHeight(
                                              imgRatioForListing,
                                              135
                                            ),
                                            borderRadius:
                                              imgShapeForListing ===
                                              "soft_corner"
                                                ? "10px"
                                                : imgShapeForListing ===
                                                  "circle"
                                                ? "100%"
                                                : "0px",
                                            position: "relative",
                                            backgroundSize:
                                              !eachPost.source_file
                                                ? "auto"
                                                : imgCenterForListing
                                                ? "cover"
                                                : "contain",
                                            backgroundColor:
                                              !eachPost.source_file
                                                ? "#ebebeb"
                                                : "",
                                            backgroundImage:
                                              "url(" +
                                              (!eachPost.source_file
                                                ? newDefaultImage
                                                : eachPost.source_file.indexOf(
                                                    "https"
                                                  ) > -1
                                                ? eachPost.source_file
                                                : process.env
                                                    .REACT_APP_Image_Path +
                                                  localStorage.getItem(
                                                    "user_id"
                                                  ) +
                                                  "/" +
                                                  localStorage.getItem(
                                                    "Appid"
                                                  ) +
                                                  process.env
                                                    .REACT_APP_CMS_Media +
                                                  eachPost.source_file) +
                                              ")",
                                          }}
                                        >
                                         {
                                                  postState.settings.enableBookmarkingOnList ?
                                           <div className="post-list-bookmark-div">
                                                    <img className="post-list-bookmark-img" src={greyBookmarkIcon} alt="bookmark-list-icon"/>
                                                  </div>
                                                   :
                                                   <></>
                                                  }
                                        </div>

                                        {postState.settings
                                          .showAuthorOnList && (
                                          <div className="post-list-author">
                                            <div className="post-author-grid-lt">
                                              {eachPost.author_info ? (
                                                !eachPost.author_info
                                                  ?.profile_image ? (
                                                  <img
                                                    src={userdefaultImgOnError}
                                                    className="img-fluid"
                                                    alt="profile logo"
                                                  />
                                                ) : (
                                                  <>
                                                    {eachPost.author_info?.profile_image?.indexOf(
                                                      "https"
                                                    ) === -1 ? (
                                                      <img
                                                        className="header-profil-img"
                                                        src={
                                                          process.env
                                                            .REACT_APP_Image_Path +
                                                          eachPost.author_info
                                                            .id +
                                                          "/" +
                                                          "profile" +
                                                          "/" +
                                                          eachPost.author_info
                                                            ?.profile_image
                                                        }
                                                        onError={({
                                                          currentTarget,
                                                        }) => {
                                                          currentTarget.onerror =
                                                            null;
                                                          currentTarget.src =
                                                            userdefaultImgOnError;
                                                        }}
                                                      />
                                                    ) : (
                                                      <img
                                                        className="header-profil-img"
                                                        src={
                                                          eachPost.author_info
                                                            ?.profile_image
                                                        }
                                                        onError={({
                                                          currentTarget,
                                                        }) => {
                                                          currentTarget.onerror =
                                                            null;
                                                          currentTarget.src =
                                                            userdefaultImgOnError;
                                                        }}
                                                      />
                                                    )}
                                                  </>
                                                )
                                              ) : (
                                                <img
                                                  src={userdefaultImgOnError}
                                                  className="img-fluid"
                                                  alt="profile logo"
                                                />
                                              )}
                                            </div>

                                            <div>
                                              <h5 className="post-one-author">
                                                {" "}
                                                {eachPost.author_info !==
                                                  undefined &&
                                                eachPost.author_info !== null
                                                  ? eachPost.author_info.name
                                                  : eachPost.owner_user
                                                  ? eachPost.owner_user.name
                                                  : localStorage.getItem(
                                                      "user_name"
                                                    )}
                                              </h5>
                                            </div>
                                          </div>
                                        )}

                                        {eachPost.title &&
                                        eachPost.title.length >= 50 ? (
                                          <h4
                                            className="post-one-title"
                                            style={{
                                              fontSize: "9px",
                                            }}
                                          >
                                            {eachPost.title.substring(0, 50) +
                                              "..."}
                                          </h4>
                                        ) : (
                                          <h4
                                            className="post-one-title"
                                            style={{
                                              fontSize: "9px",
                                            }}
                                          >
                                            {eachPost.title}
                                          </h4>
                                        )}

                                        {postState.settings
                                          .showExcerptForListing &&
                                          eachPost.excerpt && (
                                            <p
                                              className="post-one-excerpt"
                                              style={{ marginBottom: "7px" }}
                                            >
                                              {eachPost.excerpt.length >= 70
                                                ? eachPost.excerpt.substring(
                                                    0,
                                                    70
                                                  ) + "..."
                                                : eachPost.excerpt}
                                            </p>
                                          )}

                                        {showPublishDateForListing && (
                                          <h6 className="post-one-timestamp">
                                            {" "}
                                            {moment(eachPost.created_at).format(
                                              "D MMMM YYYY, HH:mm"
                                            )}
                                            {eachPost.is_featured && (
                                              <svg
                                                style={{
                                                  marginLeft: "4px",
                                                  position: "relative",
                                                  top: "-2px",
                                                }}
                                                id="iconfinder_star_216411_2_"
                                                data-name="iconfinder_star_216411 (2)"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="8.051"
                                                height="7.666"
                                                viewBox="0 0 8.051 7.666"
                                              >
                                                <g
                                                  id="Group_42888"
                                                  data-name="Group 42888"
                                                  transform="translate(0 0)"
                                                >
                                                  <path
                                                    id="Path_119118"
                                                    data-name="Path 119118"
                                                    d="M6.4,6.5l-2.539.281a.243.243,0,0,0-.2.165.238.238,0,0,0,.067.251c.755.688,1.889,1.717,1.889,1.717s-.311,1.5-.518,2.5a.242.242,0,0,0,.094.243.24.24,0,0,0,.259.013c.889-.5,2.22-1.263,2.22-1.263l2.219,1.263a.24.24,0,0,0,.355-.257c-.206-1-.516-2.5-.516-2.5L11.619,7.2a.24.24,0,0,0-.135-.416L8.945,6.5,7.893,4.175a.244.244,0,0,0-.22-.141.24.24,0,0,0-.218.141Z"
                                                    transform="translate(-3.649 -4.034)"
                                                    fill="#6f6f6f"
                                                  />
                                                </g>
                                              </svg>
                                            )}
                                          </h6>
                                        )}
                                      </div>
                                    )
                                  );
                                })
                              ) : (
                                <div className="nopagecreated 1">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      padding: "0px 15px 180px",
                                    }}
                                  >
                                    <img
                                      style={{
                                        width: "unset",
                                      }}
                                      src={
                                        require("../../assets/images/dashboard/no-post-create.png")
                                          .default
                                      }
                                    />
                                    <p
                                      style={{
                                        textAlign: "center",
                                      }}
                                    >
                                      Nothing here at the moment. Come back
                                      later?
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="post-listing-style-two">
                              {postData && postData.length > 0 ? (
                                postData.map((eachPost, indx) => {
                                  return (
                                    (eachPost.show_in_results === 1 ||
                                      eachPost.show_in_results ===
                                        undefined) && (
                                      <div className="post-list-type-two">
                                        <div
                                          className="post-type-two-data"
                                          style={{ textAlign: "left" }}
                                        >
                                          {eachPost.title &&
                                          eachPost.title.length >= 70 ? (
                                            <h3
                                              className="post-two-heading"
                                              style={{
                                                fontSize: "9px",
                                              }}
                                            >
                                              {eachPost.title.substring(0, 70) +
                                                "..."}
                                            </h3>
                                          ) : (
                                            <h3
                                              className="post-two-heading"
                                              style={{
                                                fontSize: "9px",
                                              }}
                                            >
                                              {eachPost.title}
                                            </h3>
                                          )}

                                          {postState.settings
                                            .showExcerptForListing &&
                                            eachPost.excerpt && (
                                              <h5 className="post-two-excerpt">
                                                {eachPost.excerpt.length >= 70
                                                  ? eachPost.excerpt.substring(
                                                      0,
                                                      70
                                                    ) + "..."
                                                  : eachPost.excerpt}
                                              </h5>
                                            )}

                                          <div className="post-list-author">
                                            {postState.settings
                                              .showAuthorOnList && (
                                              <div className="post-author-grid-lt">
                                                {eachPost.author_info ? (
                                                  !eachPost.author_info
                                                    ?.profile_image ? (
                                                    <img
                                                      src={
                                                        userdefaultImgOnError
                                                      }
                                                      className="img-fluid"
                                                      alt="profile logo"
                                                    />
                                                  ) : (
                                                    <>
                                                      {eachPost.author_info?.profile_image?.indexOf(
                                                        "https"
                                                      ) === -1 ? (
                                                        <img
                                                          className="header-profil-img"
                                                          src={
                                                            process.env
                                                              .REACT_APP_Image_Path +
                                                            eachPost.author_info
                                                              .id +
                                                            "/" +
                                                            "profile" +
                                                            "/" +
                                                            eachPost.author_info
                                                              ?.profile_image
                                                          }
                                                          onError={({
                                                            currentTarget,
                                                          }) => {
                                                            currentTarget.onerror =
                                                              null;
                                                            currentTarget.src =
                                                              userdefaultImgOnError;
                                                          }}
                                                        />
                                                      ) : (
                                                        <img
                                                          className="header-profil-img"
                                                          src={
                                                            eachPost.author_info
                                                              ?.profile_image
                                                          }
                                                          onError={({
                                                            currentTarget,
                                                          }) => {
                                                            currentTarget.onerror =
                                                              null;
                                                            currentTarget.src =
                                                              userdefaultImgOnError;
                                                          }}
                                                        />
                                                      )}
                                                    </>
                                                  )
                                                ) : (
                                                  <img
                                                    src={userdefaultImgOnError}
                                                    className="img-fluid"
                                                    alt="profile logo"
                                                  />
                                                )}
                                              </div>
                                            )}

                                            {postState.settings
                                              .showAuthorOnList && (
                                              <h4 className="post-two-author">
                                                {eachPost.author_info !==
                                                  undefined &&
                                                eachPost.author_info !== null
                                                  ? eachPost.author_info.name
                                                  : eachPost.owner_user
                                                  ? eachPost.owner_user.name
                                                  : localStorage.getItem(
                                                      "user_name"
                                                    )}
                                              </h4>
                                            )}
                                            {postState.settings
                                              .showAuthorOnList && (
                                              <div className="author-seprator"></div>
                                            )}
                                            {showPublishDateForListing && (
                                              <h6 className="post-two-timestamp">
                                                {" "}
                                                {moment(
                                                  eachPost.created_at
                                                ).format("D MMMM YYYY, HH:mm")}
                                              </h6>
                                            )}
                                          </div>
                                        </div>
                                        <div
                                          className="post-type-two-thumbnail"
                                          style={{
                                            height: calculateHeight(
                                              imgRatioForListing,
                                              70
                                            ),
                                            borderRadius:
                                              imgShapeForListing ===
                                              "soft_corner"
                                                ? "10px"
                                                : imgShapeForListing ===
                                                  "circle"
                                                ? "100%"
                                                : "0px",

                                            backgroundSize:
                                              !eachPost.source_file
                                                ? "auto"
                                                : imgCenterForListing
                                                ? "cover"
                                                : "contain",
                                            backgroundColor:
                                              !eachPost.source_file
                                                ? "#ebebeb"
                                                : "",
                                            backgroundImage:
                                              "url(" +
                                              (!eachPost.source_file
                                                ? newDefaultImage
                                                : eachPost.source_file.indexOf(
                                                    "https"
                                                  ) > -1
                                                ? eachPost.source_file
                                                : process.env
                                                    .REACT_APP_Image_Path +
                                                  localStorage.getItem(
                                                    "user_id"
                                                  ) +
                                                  "/" +
                                                  localStorage.getItem(
                                                    "Appid"
                                                  ) +
                                                  process.env
                                                    .REACT_APP_CMS_Media +
                                                  eachPost.source_file) +
                                              ")",
                                          }}
                                        >
                                            {
                                                  postState.settings.enableBookmarkingOnList ?
                                          <div className="post-list-bookmark-div">
                                                    <img className="post-list-bookmark-img-list-view" src={greyBookmarkIcon} alt="bookmark-list-icon"/>
                                                  </div> 
                                                   :
                                                   <></>
                                                  }
                                        </div>
                                      </div>
                                    )
                                  );
                                })
                              ) : (
                                <div className="nopagecreated 2">
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      padding: "0px 15px 180px",
                                    }}
                                  >
                                    <img
                                      style={{
                                        width: "unset",
                                      }}
                                      src={
                                        require("../../assets/images/dashboard/no-post-create.png")
                                          .default
                                      }
                                    />
                                    <p
                                      style={{
                                        textAlign: "center",
                                      }}
                                    >
                                      Nothing here at the moment. Come back
                                      later?
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>

                        <div className="change-post-view">
                          <svg
                            className={
                              previewListingView === "list" ? "active" : ""
                            }
                            onClick={(e) => handleListingView(e, "list")}
                            id="Group_38251"
                            data-name="Group 38251"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                          >
                            <g
                              id="Rectangle_4591"
                              data-name="Rectangle 4591"
                              fill="#fff"
                              stroke=""
                              stroke-width="2"
                            >
                              <rect width="9" height="9" rx="1" stroke="none" />
                              <rect
                                x="1"
                                y="1"
                                width="7"
                                height="7"
                                fill="none"
                              />
                            </g>
                            <g
                              id="Rectangle_4596"
                              data-name="Rectangle 4596"
                              transform="translate(0 11)"
                              fill="#fff"
                              stroke=""
                              stroke-width="2"
                            >
                              <rect width="9" height="9" rx="1" stroke="none" />
                              <rect
                                x="1"
                                y="1"
                                width="7"
                                height="7"
                                fill="none"
                              />
                            </g>
                            <path
                              id="Path_9130"
                              data-name="Path 9130"
                              d="M954.017,177.785h9"
                              transform="translate(-943.016 -175.434)"
                              fill="#fff"
                              stroke=""
                              stroke-width="2"
                            />
                            <path
                              id="Path_9133"
                              data-name="Path 9133"
                              d="M954.017,177.785h9"
                              transform="translate(-943.016 -163.775)"
                              fill="#fff"
                              stroke=""
                              stroke-width="2"
                            />
                            <path
                              id="Path_9131"
                              data-name="Path 9131"
                              d="M954.017,177.785h6"
                              transform="translate(-943.016 -171.364)"
                              fill="#fff"
                              stroke=""
                              stroke-width="2"
                            />
                            <path
                              id="Path_9132"
                              data-name="Path 9132"
                              d="M954.017,177.785h6"
                              transform="translate(-943.016 -159.706)"
                              fill="#fff"
                              stroke=""
                              stroke-width="2"
                            />
                          </svg>

                          <svg
                            className={
                              previewListingView === "grid" ? "active" : ""
                            }
                            onClick={(e) => handleListingView(e, "grid")}
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                          >
                            <g
                              id="Group_38250"
                              data-name="Group 38250"
                              transform="translate(0.222)"
                            >
                              <g
                                id="Rectangle_4592"
                                data-name="Rectangle 4592"
                                transform="translate(-0.222)"
                                fill="#fff"
                                stroke=""
                                stroke-width="2"
                              >
                                <rect
                                  width="9"
                                  height="9"
                                  rx="1"
                                  stroke="none"
                                />
                                <rect
                                  x="1"
                                  y="1"
                                  width="7"
                                  height="7"
                                  fill="none"
                                />
                              </g>
                              <g
                                id="Rectangle_4595"
                                data-name="Rectangle 4595"
                                transform="translate(-0.222 11)"
                                fill="#fff"
                                stroke=""
                                stroke-width="2"
                              >
                                <rect
                                  width="9"
                                  height="9"
                                  rx="1"
                                  stroke="none"
                                />
                                <rect
                                  x="1"
                                  y="1"
                                  width="7"
                                  height="7"
                                  fill="none"
                                />
                              </g>
                              <g
                                id="Rectangle_4593"
                                data-name="Rectangle 4593"
                                transform="translate(10.778)"
                                fill="#fff"
                                stroke=""
                                stroke-width="2"
                              >
                                <rect
                                  width="9"
                                  height="9"
                                  rx="1"
                                  stroke="none"
                                />
                                <rect
                                  x="1"
                                  y="1"
                                  width="7"
                                  height="7"
                                  fill="none"
                                />
                              </g>
                              <g
                                id="Rectangle_4594"
                                data-name="Rectangle 4594"
                                transform="translate(10.778 11)"
                                fill="#fff"
                                stroke=""
                                stroke-width="2"
                              >
                                <rect
                                  width="9"
                                  height="9"
                                  rx="1"
                                  stroke="none"
                                />
                                <rect
                                  x="1"
                                  y="1"
                                  width="7"
                                  height="7"
                                  fill="none"
                                />
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* Bulk update list end*/}

              {spinner === true ? (
                window.matchMedia("(max-width: 1200px)").matches && (
                  <div className="newspinner">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      style={{
                        margin: "auto",
                        background: "rgb(241, 242, 243,0)",
                        display: "block",
                        shaperendering: "auto",
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                      width="200px"
                      height="200px"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid"
                    >
                      <g
                        transform="translate(20 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#e6261f"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.375s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(40 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#f7d038"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.25s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(60 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#49da9a"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.125s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(80 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#4355db"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="0s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                    </svg>
                  </div>
                )
              ) : (
                <>
                  {bottomSpinner &&
                    window.matchMedia("(max-width: 1200px)").matches && (
                      <div
                        className="form-group"
                        style={{ background: "none", margin: "auto" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xlink="http://www.w3.org/1999/xlink"
                          style={{
                            margin: "auto",
                            background: "rgb(241, 242, 243,0)",
                            display: "block",
                            shaperendering: "auto",
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                          width="200px"
                          height="100px"
                          viewBox="0 0 100 100"
                          preserveAspectRatio="xMidYMid"
                        >
                          <g
                            transform="translate(20 50)"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#e6261f"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="-0.375s"
                                calcMode="spline"
                                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                values="0;1;0"
                                keyTimes="0;0.5;1"
                                dur="1s"
                                repeatCount="indefinite"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              ></animateTransform>
                            </circle>
                          </g>
                          <g
                            transform="translate(40 50)"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#f7d038"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="-0.25s"
                                calcMode="spline"
                                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                values="0;1;0"
                                keyTimes="0;0.5;1"
                                dur="1s"
                                repeatCount="indefinite"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              ></animateTransform>
                            </circle>
                          </g>
                          <g
                            transform="translate(60 50)"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#49da9a"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="-0.125s"
                                calcMode="spline"
                                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                values="0;1;0"
                                keyTimes="0;0.5;1"
                                dur="1s"
                                repeatCount="indefinite"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              ></animateTransform>
                            </circle>
                          </g>
                          <g
                            transform="translate(80 50)"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#4355db"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="0s"
                                calcMode="spline"
                                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                values="0;1;0"
                                keyTimes="0;0.5;1"
                                dur="1s"
                                repeatCount="indefinite"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              ></animateTransform>
                            </circle>
                          </g>
                        </svg>
                      </div>
                    )}

                  {!postListSetting && (
                    <div>
                      {postData && postData.length > 0
                        ? postData
                            .filter((row, ind) =>
                              row.title
                                .toLowerCase()
                                .includes(searchVal.toLowerCase())
                            )
                            .map((eachPost, indx) => {
                              let status;
                              if (eachPost.status === "publish") {
                                status = "statusgreen";
                              } else if (eachPost.status === "scheduled") {
                                status = "statusblue";
                              } else if (eachPost.status === "draft") {
                                status = "statusgrey";
                              }
                              if (eachPost.deleted_at === null) {
                                allCount++;
                              }

                              return (
                                eachPost.deleted_at === null && (
                                  <div className="cms-post-listing-mobile">
                                    <div
                                      className="cms-post-mob-one"
                                      style={{
                                        justifyContent: "space-between",
                                        position: "relative",
                                        opacity:
                                          eachPost.show_in_results === 1 ||
                                          eachPost.show_in_results === undefined
                                            ? 1
                                            : 0.5,
                                      }}
                                    >
                                      <Link
                                        style={{
                                          position: "absolute",
                                          width: "100%",
                                          height: "100%",
                                          top: "0",
                                          left: "0",
                                        }}
                                        to={`/app/post/${eachPost._id}`}
                                      ></Link>
                                      <div>
                                        <h3>{eachPost.title}</h3>

                                        <div className="postcreator-detail">
                                          {eachPost.author_info !== undefined &&
                                          eachPost.author_info !== null ? (
                                            <div>
                                              {eachPost.author_info
                                                .profile_image === "" ||
                                              eachPost.author_info
                                                .profile_image === null ||
                                              eachPost.author_info
                                                .profile_image === "null" ||
                                              eachPost.author_info
                                                .profile_image === undefined ||
                                              eachPost.author_info
                                                .profile_image ===
                                                "undefined" ||
                                              !eachPost.author_info
                                                .profile_image ? (
                                                <img
                                                  style={{
                                                    width: "24px",
                                                    height: "24px",
                                                  }}
                                                  src={userdefaultImgOnError}
                                                  className="img-fluid"
                                                  alt="profile logo"
                                                />
                                              ) : (
                                                <>
                                                  {eachPost.author_info.profile_image.indexOf(
                                                    "https"
                                                  ) === -1 ? (
                                                    <img
                                                      className="header-profil-img"
                                                      src={
                                                        process.env
                                                          .REACT_APP_Image_Path +
                                                        eachPost.author_info
                                                          .id +
                                                        "/" +
                                                        "profile" +
                                                        "/" +
                                                        eachPost.author_info
                                                          .profile_image
                                                      }
                                                      style={{
                                                        width: "24px",
                                                        borderRadius: "50%",
                                                        height: "24px",
                                                        backgroundSize: "cover",
                                                      }}
                                                      onError={({
                                                        currentTarget,
                                                      }) => {
                                                        currentTarget.onerror =
                                                          null;
                                                        currentTarget.src =
                                                          userdefaultImgOnError;
                                                      }}
                                                    />
                                                  ) : (
                                                    <img
                                                      className="header-profil-img"
                                                      src={
                                                        eachPost.author_info
                                                          .profile_image
                                                      }
                                                      style={{
                                                        width: "24px",
                                                        borderRadius: "50%",
                                                        height: "24px",
                                                        backgroundRepeat:
                                                          "no-repeat",
                                                        backgroundSize: "cover",
                                                      }}
                                                      onError={({
                                                        currentTarget,
                                                      }) => {
                                                        currentTarget.onerror =
                                                          null;
                                                        currentTarget.src =
                                                          userdefaultImgOnError;
                                                      }}
                                                    />
                                                  )}
                                                </>
                                              )}
                                            </div>
                                          ) : eachPost.owner_user ? (
                                            <div>
                                              {eachPost.owner_user
                                                .profile_image === "" ||
                                              eachPost.owner_user
                                                .profile_image === null ||
                                              eachPost.owner_user
                                                .profile_image === "null" ||
                                              eachPost.owner_user
                                                .profile_image === undefined ||
                                              eachPost.owner_user
                                                .profile_image ===
                                                "undefined" ||
                                              !eachPost.owner_user
                                                .profile_image ? (
                                                <img
                                                  style={{
                                                    width: "24px",
                                                    height: "24px",
                                                  }}
                                                  src={userdefaultImgOnError}
                                                  className="img-fluid"
                                                  alt="profile logo"
                                                />
                                              ) : (
                                                <>
                                                  {eachPost.owner_user.profile_image.indexOf(
                                                    "https"
                                                  ) === -1 ? (
                                                    <img
                                                      className="header-profil-img"
                                                      src={
                                                        process.env
                                                          .REACT_APP_Image_Path +
                                                        eachPost.owner_user.id +
                                                        "/" +
                                                        "profile" +
                                                        "/" +
                                                        eachPost.owner_user
                                                          .profile_image
                                                      }
                                                      style={{
                                                        width: "24px",
                                                        borderRadius: "50%",
                                                        height: "24px",
                                                        backgroundSize: "cover",
                                                      }}
                                                      onError={({
                                                        currentTarget,
                                                      }) => {
                                                        currentTarget.onerror =
                                                          null;
                                                        currentTarget.src =
                                                          userdefaultImgOnError;
                                                      }}
                                                    />
                                                  ) : (
                                                    <img
                                                      className="header-profil-img"
                                                      src={
                                                        eachPost.owner_user
                                                          .profile_image
                                                      }
                                                      style={{
                                                        width: "24px",
                                                        borderRadius: "50%",
                                                        height: "24px",
                                                        backgroundRepeat:
                                                          "no-repeat",
                                                        backgroundSize: "cover",
                                                      }}
                                                      onError={({
                                                        currentTarget,
                                                      }) => {
                                                        currentTarget.onerror =
                                                          null;
                                                        currentTarget.src =
                                                          userdefaultImgOnError;
                                                      }}
                                                    />
                                                  )}
                                                </>
                                              )}
                                            </div>
                                          ) : (
                                            <div>
                                              {profileimage === "" ||
                                              profileimage === null ||
                                              profileimage === "null" ||
                                              profileimage === undefined ||
                                              profileimage === "undefined" ||
                                              !profileimage ? (
                                                <img
                                                  style={{
                                                    width: "24px",
                                                    //borderRadius: "50%",
                                                    height: "24px",
                                                    backgroundRepeat:
                                                      "no-repeat",
                                                    backgroundSize: "cover",
                                                  }}
                                                  src={userdefaultImgOnError}
                                                  className="img-fluid"
                                                  alt="profile logo"
                                                />
                                              ) : (
                                                <>
                                                  {profileimage.indexOf(
                                                    "https"
                                                  ) === -1 ? (
                                                    <img
                                                      className="header-profil-img"
                                                      style={{
                                                        // background:
                                                        //   "url(" +
                                                        //   profilepath +
                                                        //   profileimage +
                                                        //   ")",
                                                        width: "24px",
                                                        borderRadius: "50%",
                                                        height: "24px",
                                                        backgroundSize: "cover",
                                                      }}
                                                      src={
                                                        profilepath +
                                                        profileimage
                                                      }
                                                      onError={({
                                                        currentTarget,
                                                      }) => {
                                                        currentTarget.onerror =
                                                          null;
                                                        currentTarget.src =
                                                          userdefaultImgOnError;
                                                      }}
                                                    />
                                                  ) : (
                                                    <img
                                                      className="header-profil-img"
                                                      style={{
                                                        // background:
                                                        //   "url(" + profileimage + ")",
                                                        width: "24px",
                                                        borderRadius: "50%",
                                                        height: "24px",
                                                        backgroundRepeat:
                                                          "no-repeat",
                                                        backgroundSize: "cover",
                                                      }}
                                                      src={profileimage}
                                                      onError={({
                                                        currentTarget,
                                                      }) => {
                                                        currentTarget.onerror =
                                                          null;
                                                        currentTarget.src =
                                                          userdefaultImgOnError;
                                                      }}
                                                    />
                                                  )}
                                                </>
                                              )}
                                            </div>
                                          )}

                                          <h2>
                                            {eachPost.author_info !==
                                              undefined &&
                                            eachPost.author_info !== null
                                              ? eachPost.author_info.name
                                              : eachPost.owner_user
                                              ? eachPost.owner_user.name
                                              : localStorage.getItem(
                                                  "user_name"
                                                )}
                                          </h2>
                                        </div>
                                      </div>
                                      <div
                                        className="post-thumbnail-mobile"
                                        style={{
                                          backgroundImage:
                                            "url(" +
                                            (!eachPost.source_file
                                              ? imagebackground
                                              : eachPost.source_file.indexOf(
                                                  "https"
                                                ) > -1
                                              ? eachPost.source_file
                                              : process.env
                                                  .REACT_APP_Image_Path +
                                                localStorage.getItem(
                                                  "user_id"
                                                ) +
                                                "/" +
                                                localStorage.getItem("Appid") +
                                                process.env
                                                  .REACT_APP_CMS_Media +
                                                eachPost.source_file) +
                                            ")",
                                        }}
                                      ></div>
                                    </div>

                                    <div className="cms-post-mob-two">
                                      <div
                                        className="cms-post-status-detail"
                                        style={{
                                          opacity:
                                            eachPost.show_in_results === 1 ||
                                            eachPost.show_in_results ===
                                              undefined
                                              ? 1
                                              : 0.5,
                                        }}
                                      >
                                        <p className={`pagestatus ${status}`}>
                                          {eachPost.status === "publish"
                                            ? "published"
                                            : eachPost.status}
                                        </p>

                                        <h4>
                                          {moment(eachPost.created_at).format(
                                            "D MMMM YYYY, HH:mm"
                                          )}
                                        </h4>
                                      </div>

                                      <div
                                        className={`post-actions-mob ${
                                          eachPost.show_in_results === 1 ||
                                          eachPost.show_in_results === undefined
                                            ? ""
                                            : "cms-entry-mob"
                                        } `}
                                      >
                                        <span
                                          className="cms-list-visiblity "
                                          onClick={(e) =>
                                            handleEyeButton(
                                              e,
                                              eachPost._id,
                                              eachPost.show_in_results,
                                              indx
                                            )
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20.284"
                                            height="18.465"
                                            viewBox="0 0 25.284 20.465"
                                          >
                                            <g
                                              id="Group_39189"
                                              data-name="Group 39189"
                                              transform="translate(0.568 0.353)"
                                            >
                                              <path
                                                id="Path_103079"
                                                data-name="Path 103079"
                                                d="M68.716,9.742a13.852,13.852,0,0,1-12.07,7.684A13.876,13.876,0,0,1,44.568,9.742,13.876,13.876,0,0,1,56.646,2.058,13.852,13.852,0,0,1,68.716,9.742Z"
                                                transform="translate(-44.568 0.138)"
                                                fill="none"
                                                stroke=""
                                                stroke-miterlimit="10"
                                                stroke-width="1"
                                              />
                                              <ellipse
                                                id="Ellipse_8629"
                                                data-name="Ellipse 8629"
                                                cx="3.842"
                                                cy="3.842"
                                                rx="3.842"
                                                ry="3.842"
                                                transform="translate(8.233 6.038)"
                                                fill="none"
                                                stroke=""
                                                stroke-miterlimit="10"
                                                stroke-width="1"
                                              />
                                              <line
                                                id="Line_430"
                                                data-name="Line 430"
                                                x2="19.758"
                                                y2="19.758"
                                                transform="translate(2.196 0.001)"
                                                fill="none"
                                                stroke=""
                                                stroke-miterlimit="10"
                                                stroke-width="1"
                                              />
                                            </g>
                                          </svg>
                                        </span>

                                        {eachPost.status === "publish" && (
                                          <>
                                            {eachPost.is_featured ? (
                                              <img
                                                className="img-fluid"
                                                onClick={(e) =>
                                                  handleFeaturedPost(
                                                    e,
                                                    eachPost._id,
                                                    eachPost.is_featured,
                                                    eachPost,
                                                    indx
                                                  )
                                                }
                                                src={
                                                  require("../../assets/images/dashboard/cms/yellow-star.png")
                                                    .default
                                                }
                                              />
                                            ) : (
                                              <img
                                                className="img-fluid"
                                                onClick={(e) =>
                                                  handleFeaturedPost(
                                                    e,
                                                    eachPost._id,
                                                    eachPost.is_featured,
                                                    eachPost,
                                                    indx
                                                  )
                                                }
                                                src={
                                                  require("../../assets/images/dashboard/cms/white-star-icon.png")
                                                    .default
                                                }
                                                alt="featured"
                                              />
                                            )}
                                          </>
                                        )}

                                        <img
                                          style={{ marginLeft: "12px" }}
                                          onClick={(e) =>
                                            handleClonePost(e, eachPost)
                                          }
                                          className="img-fluid"
                                          src={
                                            require("../../assets/images/dashboard/cms/duplicate.png")
                                              .default
                                          }
                                        />

                                        <button
                                          style={{
                                            marginLeft: "12px",
                                            backgroundColor:
                                              localStorage.getItem(
                                                "button_background_color"
                                              ) &&
                                              localStorage.getItem(
                                                "button_background_color"
                                              ) != "undefined" &&
                                              localStorage.getItem(
                                                "button_background_color"
                                              ) != "null"
                                                ? localStorage.getItem(
                                                    "button_background_color"
                                                  )
                                                : "",
                                            color:
                                              localStorage.getItem(
                                                "button_text_color"
                                              ),
                                          }}
                                          onClick={(e) =>
                                            handleTrashedPopUp(
                                              e,
                                              "add",
                                              eachPost._id
                                            )
                                          }
                                        >
                                          {" "}
                                          <img
                                            className="img-fluid"
                                            src={
                                              require("../../assets/images/dashboard/cms/delete.png")
                                                .default
                                            }
                                          />{" "}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )
                              );
                            })
                        : window.matchMedia("(max-width: 1200px)").matches && (
                            <div className="nopagecreated 5">
                              <div>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/no-post-create.png")
                                      .default
                                  }
                                />
                                <p>
                                  Nothing here at the moment. Come back later?
                                </p>
                              </div>
                            </div>
                          )}

                      {paginationSpinner &&
                        window.matchMedia("(max-width: 1200px)").matches && (
                          <div
                            className={`form-group`}
                            style={{ background: "none", margin: "auto" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xlink="http://www.w3.org/1999/xlink"
                              style={{
                                margin: "auto",
                                background: "rgb(241, 242, 243,0)",
                                display: "block",
                                shaperendering: "auto",
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                              width="200px"
                              height="100px"
                              viewBox="0 0 100 100"
                              preserveAspectRatio="xMidYMid"
                            >
                              <g
                                transform="translate(20 50)"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="6"
                                  fill="#e6261f"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <animateTransform
                                    attributeName="transform"
                                    type="scale"
                                    begin="-0.375s"
                                    calcMode="spline"
                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                    values="0;1;0"
                                    keyTimes="0;0.5;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  ></animateTransform>
                                </circle>
                              </g>
                              <g
                                transform="translate(40 50)"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="6"
                                  fill="#f7d038"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <animateTransform
                                    attributeName="transform"
                                    type="scale"
                                    begin="-0.25s"
                                    calcMode="spline"
                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                    values="0;1;0"
                                    keyTimes="0;0.5;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  ></animateTransform>
                                </circle>
                              </g>
                              <g
                                transform="translate(60 50)"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="6"
                                  fill="#49da9a"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <animateTransform
                                    attributeName="transform"
                                    type="scale"
                                    begin="-0.125s"
                                    calcMode="spline"
                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                    values="0;1;0"
                                    keyTimes="0;0.5;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  ></animateTransform>
                                </circle>
                              </g>
                              <g
                                transform="translate(80 50)"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="6"
                                  fill="#4355db"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <animateTransform
                                    attributeName="transform"
                                    type="scale"
                                    begin="0s"
                                    calcMode="spline"
                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                    values="0;1;0"
                                    keyTimes="0;0.5;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  ></animateTransform>
                                </circle>
                              </g>
                            </svg>
                          </div>
                        )}
                    </div>
                  )}
                  {/* }
                </>
                 } */}
                </>
              )}
            </div>
          )}
        </BottomScrollListener>

        <Modal
          isOpen={isTrashed}
          centered
          className="delete-popup"
          toggle={(e) => handleTrashedPopUp(e, "cancel")}
          // className={this.props.className}
          dataclassName="addonpopup"
        >
          <ModalBody className="">
            <div>
              <h5>Delete this post?</h5>
              <h6>This action will permanently delete your post.</h6>

              {/* <div className="d-flex align-items-center justify-content-between finalselection">
                <div onClick={(e) => handleTrashedPopUp(e, "cancel")}>
                  <p>Cancel</p>
                </div>

                <div onClick={(e) => handleTrashedPost(e)}>
                  <div className="d-flex align-items-center justify-content-center h-100 w-100">
                    <p>
                      <div className={isLoader}>
                        <img src={loaderimage} alt="loader" />
                      </div>
                      <div>{submitval}</div>
                    </p>
                  </div>
                </div> */}
              <div className="d-flex align-items-center justify-content-center cms-delete-popup-footer">
                <div
                  className="d-none d-xl-block grey-btn"
                  onClick={(e) => handleTrashedPopUp(e, "cancel")}
                >
                  <p>Cancel</p>
                </div>
                <div onClick={(e) => handleTrashedPost(e)}>
                  <div className="d-flex align-items-center justify-content-center h-100 w-100 red-btn">
                    <p>
                      <div className={isLoader}>
                        <img src={loaderimage} alt="loader" />
                      </div>
                      <div>{submitval}</div>
                    </p>
                  </div>
                </div>
                <div
                  className="d-block d-xl-none grey-btn"
                  onClick={(e) => handleTrashedPopUp(e, "cancel")}
                >
                  <p>Cancel</p>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={bulkDeletePopup}
          centered
          className="delete-popup bulk-delete-popup"
          toggle={handleBulkDeletePopup}
          // className={this.props.className}
          dataclassName="addonpopup"
        >
          <ModalHeader
            className="bulk-delete-cross-head"
            toggle={handleBulkDeletePopup}
          >
            <svg
              width="13.059"
              height="13.059"
              viewBox="0 0 13.059 13.059"
              onClick={handleBulkDeletePopup}
            >
              <path
                id="Union_18"
                data-name="Union 18"
                d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                transform="translate(0.529 0.531)"
                fill="#C8CEDB"
                stroke="#C8CEDB"
                strokeWidth="1.5"
              />
            </svg>
          </ModalHeader>
          <ModalBody className="">
            <div>
              <h5 style={{ marginBottom: "18px" }}>Bulk delete?</h5>
              <h6>This action will permanently delete your selected posts.</h6>
              <div className="d-flex align-items-center justify-content-center cms-delete-popup-footer">
                <div
                  className="d-none d-xl-block grey-btn"
                  onClick={handleBulkDeletePopup}
                >
                  <p>Cancel</p>
                </div>
                <div onClick={() => handleBulkDeletePopup("delete")}>
                  <div className="d-flex align-items-center justify-content-center h-100 w-100 red-btn">
                    <p>
                      {bulkDeleteLoader ? (
                        <div className="d-flex justify-content-center align-items-center h-100">
                          <div className="new-red-loader"></div>
                        </div>
                      ) : (
                        <div>Delete</div>
                      )}
                    </p>
                  </div>
                </div>
                <div
                  className="d-block d-xl-none grey-btn"
                  onClick={handleBulkDeletePopup}
                >
                  <p>Cancel</p>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/* Post related to Mobile Dropdown */}
        <Modal
          className="protip-popup-message"
          isOpen={
            window.matchMedia("(max-width: 1200px)").matches
              ? openMobileRelatedToDropdown
              : false
          }
          centered
        >
          <ModalBody>
            <div className="mobileglobalsearch">
              <button onClick={handleMobileRelatedToDropdown}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.759"
                  height="15.408"
                  viewBox="0 0 20.759 15.408"
                >
                  <g
                    id="Group_27465"
                    data-name="Group 27465"
                    transform="translate(-19.742 -22.295)"
                  >
                    <path
                      id="Path_81245"
                      data-name="Path 81245"
                      d="M-13783.922-19217h19.346"
                      transform="translate(13805.077 19247)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                    <path
                      id="Path_82232"
                      data-name="Path 82232"
                      d="M14053.656,19255.426l-7,7,7,7"
                      transform="translate(-14025.504 -19232.424)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </button>
            </div>

            <div className="mobilesearchsuggestion">
              {/* <p  style={{	
            fontSize:"16px",	
            color: "#1B2952",	
             fontFamily: "Axiforma-Regular",	
            margin: "0px 20px 40px 20px",	
             display: "block",	
          }}
            onClick={(e)=>handleRelatedToChange(e,"")}
            active={postRelatedTo===""}
            >Select
            </p> */}

              <p
                style={{
                  fontSize: "16px",
                  color: "#1B2952",
                  fontFamily: "Axiforma-Regular",
                  margin: "0px 20px 40px 20px",
                  display: "block",
                }}
                onClick={(e) => handleRelatedToChange(e, "authors")}
                active={postRelatedTo === "authors"}
              >
                Authors
              </p>

              <p
                style={{
                  fontSize: "16px",
                  color: "#1B2952",
                  fontFamily: "Axiforma-Regular",
                  margin: "0px 20px 40px 20px",
                  display: "block",
                }}
                onClick={(e) => handleRelatedToChange(e, "categories")}
                active={postRelatedTo === "categories"}
              >
                Categories
              </p>

              <p
                style={{
                  fontSize: "16px",
                  color: "#1B2952",
                  fontFamily: "Axiforma-Regular",
                  margin: "0px 20px 40px 20px",
                  display: "block",
                }}
                onClick={(e) => handleRelatedToChange(e, "featured")}
                active={postRelatedTo === "featured"}
              >
                Featured
              </p>

              <p
                style={{
                  fontSize: "16px",
                  color: "#1B2952",
                  fontFamily: "Axiforma-Regular",
                  margin: "0px 20px 40px 20px",
                  display: "block",
                }}
                onClick={(e) => handleRelatedToChange(e, "tags")}
                active={postRelatedTo === "tags"}
              >
                Tags
              </p>
            </div>
          </ModalBody>
        </Modal>
        {/* Mobile view Category filter dropdown */}
        <Modal
          className="protip-popup-message"
          isOpen={
            window.matchMedia("(max-width: 1200px)").matches
              ? openMobCatFilter
              : false
          }
          centered
        >
          <ModalBody>
            <div className="mobileglobalsearch">
              <button onClick={handleMobileCatDropdown}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.759"
                  height="15.408"
                  viewBox="0 0 20.759 15.408"
                >
                  <g
                    id="Group_27465"
                    data-name="Group 27465"
                    transform="translate(-19.742 -22.295)"
                  >
                    <path
                      id="Path_81245"
                      data-name="Path 81245"
                      d="M-13783.922-19217h19.346"
                      transform="translate(13805.077 19247)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                    <path
                      id="Path_82232"
                      data-name="Path 82232"
                      d="M14053.656,19255.426l-7,7,7,7"
                      transform="translate(-14025.504 -19232.424)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </button>
              {categoryData.length > 0 && (
                <input
                  placeholder="Search"
                  type="text"
                  value={catSearchVal}
                  onChange={(e) => {
                    setCatSearchVal(e.target.value);
                  }}
                ></input>
              )}
              {catSearchVal.trim() && (
                <p
                  onClick={(e) => {
                    setCatSearchVal("");
                  }}
                >
                  Clear
                </p>
              )}
            </div>

            <div className="mobilesearchsuggestion">
              {categoryData.length === 0 && (
                <div className="no-app-dropdown">
                  <img
                    className="img-fluid"
                    src={
                      require("../../assets/images/dashboard/no-apps-created.png")
                        .default
                    }
                    alt="No App Found"
                  />
                </div>
              )}
              {categoryData &&
                categoryData
                  .filter((row) =>
                    row.name.toLowerCase().includes(catSearchVal.toLowerCase())
                  )
                  .map((res, indx) => {
                    return (
                      <div className="category-created">
                        <label
                          className="category-list-container"
                          for={`cat${indx}`}
                        >
                          <input
                            type="checkbox"
                            id={`cat${indx}`}
                            name="category1"
                            onChange={(e) => handleAddCatDrop(e, res, indx)}
                            // value={res}
                            checked={catArrForPost.includes(res)}
                          />
                          <span className="category-checkmark"></span>
                          {res.level < 9 && handleDash(res.level)}
                          {res.name}
                        </label>
                      </div>
                    );
                  })}
            </div>
          </ModalBody>
        </Modal>
        {/* Mobile view Tag filter dropdown */}
        <Modal
          className="protip-popup-message"
          isOpen={
            window.matchMedia("(max-width: 1200px)").matches
              ? openMobTagFilter
              : false
          }
          centered
        >
          <ModalBody>
            <div className="mobileglobalsearch">
              <button onClick={handleMobileTagDropdown}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.759"
                  height="15.408"
                  viewBox="0 0 20.759 15.408"
                >
                  <g
                    id="Group_27465"
                    data-name="Group 27465"
                    transform="translate(-19.742 -22.295)"
                  >
                    <path
                      id="Path_81245"
                      data-name="Path 81245"
                      d="M-13783.922-19217h19.346"
                      transform="translate(13805.077 19247)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                    <path
                      id="Path_82232"
                      data-name="Path 82232"
                      d="M14053.656,19255.426l-7,7,7,7"
                      transform="translate(-14025.504 -19232.424)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </button>
              {tagData.length > 0 && (
                <input
                  placeholder="Search"
                  type="text"
                  value={tagSearchVal}
                  onChange={(e) => {
                    setTagSearchVal(e.target.value);
                  }}
                ></input>
              )}
              {tagSearchVal.trim() && (
                <p
                  onClick={(e) => {
                    setTagSearchVal("");
                  }}
                >
                  Clear
                </p>
              )}
            </div>

            <div className="mobilesearchsuggestion">
              {tagData.length === 0 && (
                <div className="no-app-dropdown">
                  <img
                    className="img-fluid"
                    src={
                      require("../../assets/images/dashboard/no-apps-created.png")
                        .default
                    }
                    alt="No App Found"
                  />
                </div>
              )}
              {tagData &&
                tagData
                  .filter((row) =>
                    row.name.toLowerCase().includes(tagSearchVal.toLowerCase())
                  )
                  .map((res, indx) => {
                    return (
                      <div className="category-created">
                        <label
                          className="category-list-container"
                          for={`tagMobile${indx}`}
                        >
                          <input
                            type="checkbox"
                            id={`tagMobile${indx}`}
                            name="tagMobile1"
                            onChange={(e) => handleAddTagDrop(e, res, indx)}
                            // value={res}
                            checked={tagArrForPost.includes(res)}
                          />
                          <span className="category-checkmark"></span>

                          {res.name}
                        </label>
                      </div>
                    );
                  })}
            </div>
          </ModalBody>
        </Modal>
        {/* Mobile view Status filter dropdown */}
        <Modal
          className="protip-popup-message"
          isOpen={
            window.matchMedia("(max-width: 1200px)").matches
              ? openMobStatusFilter
              : false
          }
          centered
        >
          <ModalBody>
            <div className="mobileglobalsearch">
              <button onClick={handleMobileStatusDropdown}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.759"
                  height="15.408"
                  viewBox="0 0 20.759 15.408"
                >
                  <g
                    id="Group_27465"
                    data-name="Group 27465"
                    transform="translate(-19.742 -22.295)"
                  >
                    <path
                      id="Path_81245"
                      data-name="Path 81245"
                      d="M-13783.922-19217h19.346"
                      transform="translate(13805.077 19247)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                    <path
                      id="Path_82232"
                      data-name="Path 82232"
                      d="M14053.656,19255.426l-7,7,7,7"
                      transform="translate(-14025.504 -19232.424)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </button>
            </div>

            <div className="mobilesearchsuggestion">
              <p
               className="mobilesearchsuggestion-para"
                onClick={(e) => handleStatusFilterChange(e, "")}
              >
                All
              </p>
              <p
                className="mobilesearchsuggestion-para"
                onClick={(e) => handleStatusFilterChange(e, "draft")}
              >
                Draft
              </p>
              <p
                className="mobilesearchsuggestion-para"
                onClick={(e) => handleStatusFilterChange(e, "publish")}
              >
                Published
              </p>

              <p
               className="mobilesearchsuggestion-para"
                onClick={(e) => handleStatusFilterChange(e, "scheduled")}
              >
                Scheduled
              </p>
            </div>
          </ModalBody>
        </Modal>
      </section>

      <MultiSelectionPopUpForCustomApp
        openPopUp={openCatMultiSelectionPopUp}
        handlePopUp={handleOpenCatSelectionPopUp}
        data={categoryData}
        handleInputClick={handleCatMultiSelectionChange}
        selectedArr={tempCatSelection}
        subData={catSubData}
        setSubData={setCatSubData}
        searchVal={catSearchValForMultiPopUp}
        setSearchVal={setCatSearchValForMultiPopUp}
        breadcrumbArr={catBreadcrumbArr}
        setBreadcrumbArr={setCatBreadcrumbArr}
        from={"category"}
        oldBreadcrumbArr={oldCatBreadcrumbArr}
        oldSubData={oldCatSubData}
        handleBottomScrollListner={handleBottomScrollListnerForCat}
        paginationSpinner={paginationSpinnerForCat}
      />

      <MultiSelectionPopUpForCustomApp
        openPopUp={openTagMultiSelectionPopUp}
        handlePopUp={handleOpenTagSelectionPopUp}
        data={tagData}
        handleInputClick={handleTagMultiSelectionChange}
        selectedArr={tempTagSelection}
        subData={tagSubData}
        setSubData={setTagSubData}
        searchVal={tagSearchValForMultiPopUp}
        setSearchVal={setTagSearchValForMultiPopUp}
        breadcrumbArr={tagBreadcrumbArr}
        setBreadcrumbArr={setTagBreadcrumbArr}
        from={"category"}
        oldBreadcrumbArr={oldTagBreadcrumbArr}
        oldSubData={oldTagSubData}
        handleBottomScrollListner={handleBottomScrollListnerForTag}
        paginationSpinner={paginationSpinnerForTag}
      />

      <MultiSelectionPopUpForAttribute
        openPopUp={openAtrMultiSelectionPopUp}
        handlePopUp={handleOpenAtrSelectionPopUp}
        data={atrListingData}
        handleInputClick={handleAtrMultiSelectionChange}
        selectedArr={tempAtrSelection}
        subData={atrSubData}
        setSubData={setAtrSubData}
        searchVal={atrSearchValForMultiPopUp}
        setSearchVal={setAtrSearchValForMultiPopUp}
        breadcrumbArr={atrBreadcrumbArr}
        setBreadcrumbArr={setAtrBreadcrumbArr}
        from={"category"}
        oldBreadcrumbArr={oldAtrBreadcrumbArr}
        oldSubData={oldAtrSubData}
        handleBottomScrollListner={handleBottomScrollListnerForAtr}
        paginationSpinner={paginationSpinnerForAtr}
      />
    </>
  );
};

export default Postlist;
