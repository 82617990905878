import axios from "axios";
import {errortoast, sucesstoast} from '../../Dashboard/Toaster';
import { Redirect, useHistory } from "react-router-dom";
import { parse } from "date-fns";
import moment from "moment";
import S3 from "react-aws-s3";
import { CustomS3Client } from "../../utils/S3utils";
const Url = process.env.REACT_APP_API_URL + "api/app/";
const RedirectUrl = process.env.REACT_APP_cors_api;
const cmsUrl = process.env.REACT_APP_API_URL + "api/cms/";
const segmenttypearray = [
                        {field: "first_session", type: 1}, 
                        {field: "last_session", type: 2}, 
                        {field: "session_count", type: 3}, 
                        {field: "session_time", type: 4}, 
                        {field: "language", type: 5}, 
                        {field: "app_version", type: 6}, 
                        {field: "location", type: 7}, 
                        {field: "email", type: 8}, 
                        {field: "country", type: 9}, 
                        {field: "test_users", type: 10}, 
                    ];
const statickey = [
                    {"operator":"=", "opname":"eq"}, 
                    {"operator":"!=", "opname":"neq"},
                    {"operator":">", "opname":"gt"},
                    {"operator":"<", "opname":"lt"},
                ];

let awspath = "";
let clientid = "";
let appicon = "";
let applogo = "";
let splashimg = "";
let loginbackground = "";
let loginlogo = "";
let dashboard_banner = "";

const changeurl = () => {
        awspath =   process.env.REACT_APP_Image_Path + clientid + process.env.REACT_APP_Profile;
                    appicon = process.env.REACT_APP_Image_Path + clientid + "/";
                    applogo = process.env.REACT_APP_Image_Path + clientid + "/";
                    splashimg = process.env.REACT_APP_Image_Path + clientid + "/";
                    loginbackground = process.env.REACT_APP_Image_Path + clientid + "/";
                    loginlogo = process.env.REACT_APP_Image_Path + clientid + "/";
                    dashboard_banner = process.env.REACT_APP_Image_Path + clientid + "/";
};

const getBase64FromUrl = async (useUrl) => {
    try 
    {
        const data = await fetch(useUrl);
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            };
        });
    } 
    catch 
    {
        errortoast("Something went wrong please try again");
    }
};

const returnSelectedModuleName = (module_name,mergeArray,tempSlug,tempRestBase,tempPostType) => {

    let label = "";
    if (module_name === "chat") {
      label = "Chat";
    } else if (module_name === "main_menu") {
      label = "Menu";
    } else if (module_name === "settings") {
      label = "Settings";
    } else if (module_name === "web_view") {
      label = "Web view";
    } else if (module_name === "home") {
      label = "Home screen";
    } else if (module_name === "profile") {
      label = "Profile";
    } else if (module_name === "product_categories") {
      label = "Product categories";
    } else if (module_name === "product_search") {
      label = "Product search";
    } else if (module_name === "cart") {
      label = "Cart";
    } else if (module_name === "pages") {
      label = "Pages";
    } else if (module_name === "orders") {
      label = "Orders";
    }
    else if (module_name === "cms_post") {
      label = "Posts";
    }
    else if (module_name === "cms_page") {
      label = "Pages";
    }
    else if (module_name === "cms_category") {
      label = "Post categories";
    }
    else if (module_name === "cms_tag") {
      label = "Post Tags";
    }  
    else if (module_name === "email") {
      label = "Email";
    }
    else if (module_name === "phone") {
      label = "Phone";
    }
    else if (module_name === "share") {
      label = "Share";
    }
    else {
  
      mergeArray && mergeArray.length > 0 &&
      mergeArray.map((value, k) => 
      {
    
        if(module_name + tempSlug + tempRestBase + tempPostType === value.type + value.slug + value.rest_base + value.post_type){
    
          label = value.name;
        }
              
      })
    }
  
  
    return label;
  };

// Get all notifications
export const Getnotification= (setSpinner,
    setDisplaytable,
    setDisplayownerappicon,
    setMultisite,
    setAndroidnotifyid,
    setIOSnotifyid,
    setIs_enable_screen) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.get(Url + "list-notifications/" + btoa(localStorage.getItem("Appid")),{
      headers: headers
    }).then(res => {
      
    if(res.data.code === 200){

        localStorage.setItem("create_notification_img",JSON.stringify(res.data.app_icon_data));
        setSpinner(false);
        setDisplaytable(res.data.data);
        setDisplayownerappicon(res.data.app_icon_data)
		setMultisite(res.data.multisite);
        setAndroidnotifyid(res.data.android_notification_project_id);
        setIOSnotifyid(res.data.ios_notification_project_id);
        setIs_enable_screen(res.data.is_enable_screen);
        
    }
     
    })
}

//sendNotificationPopup
export const sendNotificationPopup = (params, setIsLoader, setSubmitval, redirectToList, setSaveDraftVal) => {
    //Loader(state);
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    axios.post(Url + "send-notification",params,{
      headers: headers
        }).then(res => {
            //return false;
            if(res.data.code === 200)
            {
                setIsLoader("d-none");
				setSubmitval("Send Notification");                
                //state.props.history.push("/app/pushnotification");
				redirectToList();
                setSaveDraftVal("Save draft");
            }
            else if(res.data.code === 400)
            {
				setIsLoader("d-none");
				setSubmitval("Send Notification");
                setSaveDraftVal("Save draft");
				if(res.data.data.delivery_date !== undefined)
				{
					errortoast(res.data.data.delivery_date[0]);
				}
                else if(res.data.data.title !== undefined)
				{
					errortoast(res.data.data.title[0]);
				}
                else if(res.data.data.description !== undefined)
				{
					errortoast(res.data.data.description[0]);
				}
            }
            else if(res.data.code === 500)
            {
				setIsLoader("d-none");
				setSubmitval("Send Notification");
                setSaveDraftVal("Save draft");
				errortoast(res.data.status);
            }
            else
            {
                errortoast(res.data.status);
				setIsLoader("d-none");
				setSubmitval("Send Notification");
                setSaveDraftVal("Save draft");
                
            }
       }).catch(() => {
			
			setIsLoader("d-block");
			setSubmitval("Send Notification");
            setSaveDraftVal("Save draft");
           	errortoast("Something went wrong. please try after sometime.")
       })
}

//EditNotification
export const GetNotificationByID = (pushNotifyID, setTitle, setMessage, setChkAndroid, setChkIos, setDeviceType, setChkScheduleNow, setDeliveryType, setDateTimeValue) => {
    //Loader(state);
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    axios.get(Url + "get-notification-detail/" + btoa(localStorage.getItem("Appid")) + "/" + pushNotifyID,{
      headers: headers
        }).then(res => {
            //return false;
            if(res.data.code === 200)
            {
                //setIsLoader("d-none");
				//setSubmitval("Send Notification");  
                //state.props.history.push("/app/pushnotification");
				//redirectToList();
                setTitle(res.data.data.title);
                setMessage(res.data.data.description);

                if(res.data.data.device_type === 'both')
                {
                    setChkAndroid("android");
                    setChkIos("ios");
                }
                else if(res.data.data.device_type === 'android')
                {
                    setChkAndroid("android");
                    setChkIos("");
                }
                else if(res.data.data.device_type === 'ios')
                {
                    setChkAndroid("");
                    setChkIos("ios");
                }
                
                setDeviceType(res.data.data.device_type);
                //setChkScheduleNow(res.data.data.device_type);
                setDeliveryType(res.data.data.delivery_type);
                
                if(res.data.data.delivery_type === 1)
                {
                    setChkScheduleNow(true);
                }
                else
                {
                    setChkScheduleNow(false);
                    let ddate = new Date(res.data.data.delivery_date);
                    setDateTimeValue(ddate);
                }
            }
            else if(res.data.code === 400)
            {
				//setIsLoader("d-none");
				//setSubmitval("Send Notification");
				/*if(res.data.data.delivery_date !== undefined)
				{
					errortoast(res.data.data.delivery_date[0]);
				}*/
                
            }
            else if(res.data.code === 500)
            {
				//setIsLoader("d-block");
				//setSubmitval("Send Notification");
				//errortoast(res.data.status);
                
            }
            else
            {
                //errortoast(res.data.status);
				//setIsLoader("d-block");
				//setSubmitval("Send Notification");
                
            }
       })
       .catch((err) => {
            errortoast("Something went wrong");
            
        });
}

// Delete notifications
export const DeletePushNotification= (params, setIsDelete, isdelete) => {
    //const history = useHistory();

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.post(Url + "delete-notification", params,{
      headers: headers
    }).then(res => {
      
    if(res.data.code === 200){
        
        //setSpinner(false);
        //redirectToList(); 
        setIsDelete(!isdelete);
    }
     
    })
}

export const GetAllUsers = ( setSpinner, appid, setAllUsers, setPlanName,setCode,setreseller_package_id,setplan_code,) =>{
    
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
      
    axios.get(Url + "os-get-devices/"+ btoa(appid),{
        headers: headers
    }).then(res => {
        
        if(res.data.code === 200){
            
            setSpinner(false);
            setCode(res.data.code);
            if(res.data.data !== undefined && res.data.data !== "" && res.data.data !== null)
            {
                const userslistjson = JSON.parse(res.data.data);
                const playersdata = userslistjson.players; 
                //redirectToList();
                setAllUsers(playersdata);
            }

            setPlanName(res.data.plan_data.plan);
            setSpinner(false);
        }
        else if(res.data.code === 203){
            setCode(res.data.code);
            setSpinner(false);
            setreseller_package_id(res.data.reseller_package_id);
            setplan_code(res.data.plan_code);
        }      
       
    })
}

export const saveSegmentData = (params, setBtnLoader, setSubmitLabel, redirectToAudience) =>{

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("amstoken"),
      }
      axios.post(Url + "os-create-segment",params,{
        headers: headers
        }).then(res => {
            if(res.data.code === 200)
            {
                setBtnLoader("d-none");
                setSubmitLabel("Create");                

                sucesstoast("Segment has been created successfully.");
                redirectToAudience();
            }
            else if(res.data.code === 400)
            {
                setBtnLoader("d-none");
                setSubmitLabel("Create");
                
                if(res.data.data.name[0] !== null && res.data.data.name[0] !== '' && res.data.data.name[0] !== undefined)
                {
                    errortoast(res.data.data.name[0]);
                }
                else if(res.data.data.filters_array[0] !== null && res.data.data.filters_array[0] !== '' && res.data.data.filters_array[0] !== undefined)
                {
                    errortoast(res.data.data.filters_array[0]);
                }
            }
            else if(res.data.code === 500)
            {
                setBtnLoader("d-none");
                setSubmitLabel("Create");
                
                if(res.data.error !== null && res.data.error !== '')
                {
                    if(res.data.error.error != null && res.data.error.error != '')
                    {
                        errortoast(res.data.error.error);
                    }
                    else
                    {
                        errortoast(res.data.error.errors[0]);
                    }

                }
            }            
        }).catch((err) => {
              
            setBtnLoader("d-none");
            setSubmitLabel("Create");
            
            //errortoast("Something went wrong. please try after sometime.")
         })
}


//Get Segment List
export const getSegmentList = (setSegmentData, setSpinner, setPlanName,setCode,setreseller_package_id,setplan_code,) => {
    //Loader(state);
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    axios.get(Url + "os-get-segments/" + btoa(localStorage.getItem("Appid")),{
      headers: headers
        }).then(res => {
            //return false;

            setSpinner(false);

            if(res.data.code === 200)
            {
        
                setCode(res.data.code);

                setSegmentData( res.data.data );
                
                setPlanName(res.data.plan_data.plan);

            }
            else if(res.data.code === 400)
            {
                

                setSpinner(false);
                setCode(res.data.code);
            }
            else if(res.data.code === 500)
            {
                
                setCode(res.data.code);
                setSpinner(false);
            }
            else if(res.data.code === 203){
                setCode(res.data.code);
                setSpinner(false);
                setreseller_package_id(res.data.reseller_package_id);
                setplan_code(res.data.plan_code);
            }
            else
            {
                
                setSpinner(false);
            }


       })
       .catch((err) => {
            errortoast("Something went wrong");
            
        });
}

//Delete Segment
export const DeleteSegment= (params, setIsDelete, isdelete, setConfirmPopupLabel, setRemovePopupLoader, setDeleteSegmentPopup, deletesegmentpopup, setSpinner) => {
    

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.post(Url + "os-delete-segment", params,{
      headers: headers
    }).then(res => {
      
        if(res.data.code === 200)
        {
            
            //setSpinner(false);
            setDeleteSegmentPopup(!deletesegmentpopup);
            setIsDelete(!isdelete);
            setConfirmPopupLabel("Delete");
            setRemovePopupLoader("d-none");
            //setDeleteSegmentID(null);
            setSpinner(true);
            
        }
        else
        {
            //setSpinner(false);
            setDeleteSegmentPopup(!deletesegmentpopup);
            if(res.data.mesg !== undefined && res.data.mesg !== '' && res.data.mesg !== null)
            {
                errortoast(res.data.mesg);
            }
        }
     
    })
}

// Get all one signal notifications
export const GetOnseSignalNotification= (
    setSpinner,
    setAllNotifications,
    setPlanName,
    setMultisite,
    finalFilterVal,
    setFilter,
    setCode,
    setreseller_package_id,
    setplan_code,
    limit,
    page,
    ntfListData,
    setBottomSpinner,
    setHitNextPagination,
    ) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.get(Url + `os-get-notifications/${btoa(localStorage.getItem("Appid"))}?page=${(page?page:1)}&limit=${(limit?limit:20)}${finalFilterVal ? finalFilterVal : "" }`,{
      headers: headers
    }).then(res => {
      
        if(res.data.code === 200)
        {
            
            localStorage.setItem("create_notification_img",JSON.stringify(res.data.app_icon_data));
            setCode(res.data.code);
            setSpinner(false);
            setPlanName(res.data.plan_data.plan);

            if(finalFilterVal != '')
                {
                    setFilter(1);
                }
                else
                {
                    setFilter(0);
                }


            setMultisite(res.data.multisite);

            if(res.data.androiddata && res.data.androiddata.is_rich_notification_enabled !== null && res.data.androiddata.is_rich_notification_enabled !== "" && res.data.androiddata.is_rich_notification_enabled !== undefined)
            {
                localStorage.setItem("android_os_notification_enable", res.data.androiddata.is_rich_notification_enabled);
            }

            if(res.data.iosdata && res.data.iosdata.is_rich_notification_enabled !== null && res.data.iosdata.is_rich_notification_enabled !== "" && res.data.iosdata.is_rich_notification_enabled !== undefined)
            {
                localStorage.setItem("ios_os_notification_enable", res.data.iosdata.is_rich_notification_enabled);
            }
            
            setHitNextPagination((res.data.data.length > 0 && res.data.data.length===limit) ?true:false);
            setBottomSpinner(false);
            let ntfMergeData = [];
            if(page > 1){
              ntfMergeData = ntfListData.concat(res.data.data);
              setAllNotifications(ntfMergeData);
            }else{
                setAllNotifications(res.data.data);
            }
        }
        else if(res.data.code === 400)
        {
            setSpinner(false);
        }
        else if(res.data.code === 203){
            setCode(res.data.code);
            setSpinner(false);
            setreseller_package_id(res.data.reseller_package_id);
            setplan_code(res.data.plan_code);
        }
    })
}

export const getAllSegmentList = (setSegmentList, setTagCheckedArr, setTagCheckedArr1, setAndroidNotificationStatus, setiOSNotificationStatus,setSpinner,
    setIsEnableBackgroundLocationPermissionAndroid,
    setIsEnableForegroundLocationPermissionAndroid,
    setIsEnableLocationPermissionIOS,
   ) => {
    //Loader(state);
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    axios.get(Url + "os-get-segments/" + btoa(localStorage.getItem("Appid")),{
      headers: headers
        }).then(res => {
            
            
            if(res.data.code === 200)
            {
                setIsEnableBackgroundLocationPermissionAndroid(res.data.is_enable_background_location_permission_android);
                setIsEnableForegroundLocationPermissionAndroid(res.data.is_enable_foreground_location_permission_android);
                setIsEnableLocationPermissionIOS(res.data.is_enable_location_permission_ios);
                //let parserightdata= JSON.parse(res.data.data);
                //setRightPartData(res.data.data);
                
                //setPlanName(res.data.plan_data.plan);

                //setSpinner(false);

                const segmentdata = res.data.data;
                
                //setSegmentList(segmentname);
                setSpinner(false);
                setSegmentList(segmentdata);
                //setSegmentList(res.data.data);
                let falsearrupdate = [];
                for(let i = 0; i < segmentdata.length; i++)
                {
                    falsearrupdate[i] = false;
                }

                setTagCheckedArr(falsearrupdate);
                setTagCheckedArr1(falsearrupdate);

                setAndroidNotificationStatus(res.data.android_status != null ? res.data.android_status :  false);
                setiOSNotificationStatus(res.data.ios_status != null ? res.data.ios_status : false);
            }


       })
       .catch((err) => {
            errortoast("Something went wrong");
            
        });
}

//sendPushNotificationOneSignal
export const sendPushNotificationOneSignal = (params, redirectToNotificationList, setIsLoader, setSubmitval) => {

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    axios.post(Url + "os-send-notification",params,{
      headers: headers
        })
        .then(res => {

            if(res.data.code == 200)
            {
                
                setIsLoader("d-none");
				setSubmitval("Send");                
                redirectToNotificationList();
            }
            else if(res.data.code == 400)
            {
                
				setIsLoader("d-none");
				setSubmitval("Send");
				/*if(res.data.data.delivery_date !== undefined)
				{
					errortoast(res.data.data.delivery_date[0]);
				}*/
            }
            else if(res.data.code == 500)
            {
                
				setIsLoader("d-none");
				setSubmitval("Send");
				//errortoast(res.data.status);

                if(res.data.message.errors[0] !== '')
                {
                    errortoast(res.data.message.errors[0]);
                }
            }
            else
            {
                
                //errortoast(res.data.status);
				setIsLoader("d-none");
				setSubmitval("Send");
                
            }
       }).catch((err) => {
			
			setIsLoader("d-none");
			setSubmitval("Send");
            
           	//errortoast("Something went wrong. please try after sometime.")
       })
}


//Edit Segment
export const editSegmentData= (params, setSegmentRules, setSegmentName, setInputKey, setInputValue, setIsSegmentCategoryDisplay, setInputValue1, setSegmentID, setFilterType, setSpinner, setOpenRowNumber, setSegmentCount) => {
    

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.post(Url + "os-get-segment_info", params,{
        headers: headers
    })
    .then(res => {
      
    if(res.data.code === 200)
    {
  
        //setEditMode(true); 
        setSpinner(false);
        setSegmentID(res.data.data.segment_id); 

        setSegmentName(res.data.data.segment_name);
        let rulesarr = [];
        let rulesarrkey = [];
        let rulesarrvalue = [];
        //let rulesarrlat = [];
        let rulesarrlong = [];
        let rulesdata = JSON.parse(res.data.data.rules_json);
    
        setSegmentCount(rulesdata.length);

        

        rulesdata.map((rules, ridx)=>{
      
            let ruleindex = rules.field;
            let typedata = "";
            let typerelation = "";
            //let typelatitude = "";
            let typelongitude = "";
            let typevalue = "";
            /*let data1 = segmenttypearray.find(obj =>{
                                return (obj.field === ruleindex ? obj.type : "");
                            });*/
            if(rules.operator !== 'AND')
            {
                segmenttypearray.map((stype, sindex) =>{
                    if(ruleindex === stype.field)
                    {
                        typedata = stype.type;
                    }
                });

                statickey.map((skeydata, skeyidx) =>{
                    if(rules.relation === skeydata.operator)
                    {
                        typerelation = skeydata.opname;
                    }
                });
                
                
                
                if(rules.hours_ago !== undefined && rules.hours_ago !== '')
                {
                    typevalue = rules.hours_ago;
                }
                else if(rules.value !== undefined && rules.value !== '')
                {
                    typevalue = rules.value;
                }
                else if(rules.radius !== undefined && rules.radius !== '')
                {
                    typerelation = rules.radius;
                    typevalue = rules.lat;
                    typelongitude = rules.long;
                }
                
                
                rulesarr.push(typedata);
                rulesarrkey.push(typerelation);
                rulesarrvalue.push(typevalue);
                rulesarrlong.push(typelongitude);

                //rulesarr.push(data1.type);
                //rulesarrkey.push(datarelation.opname);
                //rulesarrvalue.push(rules.value);
                //rulesarrlong();
            }
            
        });

        
      
        setOpenRowNumber(rulesarr);
        setSegmentRules(rulesarr);
        setInputKey(rulesarrkey);
        setInputValue(rulesarrvalue);
        setIsSegmentCategoryDisplay(false);
        setInputValue1(rulesarrlong);
        setFilterType(rulesarr);
        //setSpinner(false);
        //setRightLoader(true);
        //setIsDelete(!isdelete);
        //setLeftLoader(false);

    }
     
    })
}

//Update Segmwent One Signal
//export const updateSegmentData = (params, setBtnLoader, setSubmitval, setRightLoader, setSegmentName, setSegmentRules, setIsSegmentCategoryDisplay, setSegmentID, setIsEditMode, setFilterType, setInputKey, setInputValue, setInputValue1) =>{
export const updateSegmentData = (params, setBtnLoader, setSubmitLabel, setSegmentName, setSegmentRules, setSegmentID, setFilterType, setInputKey, setInputValue, setInputValue1, redirectToAudience) =>{

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("amstoken"),
      }
      axios.post(Url + "os-update-segment",params,{
        headers: headers
        }).then(res => {
            if(res.data.code === 200)
            {
       
                setBtnLoader("d-none");
                setSubmitLabel("Save");
                //window.location.reload();
                //setRightLoader(true);
                //setIsAdded(!isadded);
                setSegmentName("");
                setSegmentRules([]);
                //setIsSegmentCategoryDisplay(true);
                setSegmentID("");
                //setIsEditMode(false);
                setFilterType([]);
                setInputKey([]); 
                setInputValue([]); 
                setInputValue1([]);

                redirectToAudience();
                
                sucesstoast("The record has been saved.");

            }
            else if(res.data.code === 400)
            {
                
                setBtnLoader("d-none");
                setSubmitLabel("Save");
            }
            else if(res.data.code === 500)
            {
                
                setBtnLoader("d-none");
                setSubmitLabel("Save");

                if(res.data.error !== null && res.data.error !== '')
                {
                    errortoast(res.data.error.errors[0]);
                }
            }
            else if(res.data.code === 501)
            {
                setBtnLoader("d-none");
                setSubmitLabel("Save");

                errortoast("Segment with the given name already exists.");
            }            
        }).catch((err) => {
              
            
            setBtnLoader("d-none");
            setSubmitLabel("Save");
            
            //errortoast("Something went wrong. please try after sometime.")
         })
}

//saveDraftPushNotificationOneSignal
export const saveDraftPushNotificationOneSignal = (params, redirectToNotificationList, setDraftLoader, setDraftSubmitVal, setDraftSpinner,redirectdraft) => 
{

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    axios.post(Url + "os-save-draft-notification",params,{
      headers: headers
        })
        .then(res => {

            if(res.data.code == 200)
            {
                
                setDraftSpinner(false);
                setDraftLoader("d-none");
				setDraftSubmitVal("Save as Draft");
                sucesstoast("Your changes have been saved");
                if(redirectdraft)
                {
                    window.location.href = "/app/advanced-notification/"+btoa(res.data.data.id);
                }

            }
            else if(res.data.code == 400)
            {
                
                setDraftSpinner(false);
				setDraftLoader("d-none");
				setDraftSubmitVal("Save as Draft");
				if(res.data.data.delivery_type !== undefined)
				{
					errortoast(res.data.data.delivery_type[0]);
				}
            }
            else if(res.data.code == 500)
            {
                
                setDraftSpinner(false);
				setDraftLoader("d-none");
				setDraftSubmitVal("Save as Draft");
				//errortoast(res.data.status);
            }
            else
            {
                
                //errortoast(res.data.status);
                setDraftSpinner(false);
				setDraftLoader("d-none");
				setDraftSubmitVal("Save as Draft");
                
            }
       }).catch((err) => {
			
            setDraftSpinner(false);
			setDraftLoader("d-none");
			setDraftSubmitVal("Save as Draft");
            
           	//errortoast("Something went wrong. please try after sometime.")
       })
}


//Delete Notification
export const DeleteNotification= (params, setIsDelete, isdelete, setConfirmPopupLabel, setRemoveLoader, setDeleteNotificationPopup, deletenotificationpopup, setSpinner) => {
    //const history = useHistory();

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.post(Url + "os-delete-notification", params,{
      headers: headers
    }).then(res => {
      
        if(res.data.code === 200){
            
            //setSpinner(false);
            setDeleteNotificationPopup(!deletenotificationpopup); 
            setIsDelete(!isdelete);
            setConfirmPopupLabel("Delete");
            setRemoveLoader("d-none");
            setSpinner(true);
        } 
    })
    .catch((err) => {
        errortoast("Something went wrong");
        
        setIsDelete(!isdelete);
    });
}

//Edit Notification
export const EditNotification = (params, setSpinner, setTitle, setMessage, setNotificationImage, 
                                setChkAndroid, setChkIos, setDeviceType, setChkScheduleNow, setDateTimeValue, 
                                setDeliveryType, setIncludeSegmentList, setExcludeSegmentList, setChkSendAllUsers, setLinkName, setLinkUrl, setTagCheckedArr, setSegmentList, setTagCheckedArr1, setNotificationImgName,
                                setAndroidNotificationStatus, setiOSNotificationStatus,setIncludeSegmentArr,setExcludeSegmentArr,
                                setItemType,
                                setTempItemType,
                                setSlug,
                                setTempSlug,
                                setRestBase,
                                setTempRestBase,
                                setPostType,
                                setTempPostType,
                                setTempInvidualSelectedItemId,
                                setInvidualSelectedItemId,
                                setWebViewUrl, 
                                setTempItemName,
                                setItemName,   
                                setIsEnableBackgroundLocationPermissionAndroid,
                                setIsEnableForegroundLocationPermissionAndroid,
                                setIsEnableLocationPermissionIOS,      
                            ) => {
    //Loader(state);
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    axios.get(Url + "os-get-notification/" + btoa(localStorage.getItem("Appid")) + "/" + params.notification_id,{
      headers: headers
        }).then(res => {            
            if(res.data.code === 200)
            {
                setIsEnableBackgroundLocationPermissionAndroid(res.data.is_enable_background_location_permission_android);
                setIsEnableForegroundLocationPermissionAndroid(res.data.is_enable_foreground_location_permission_android);
                setIsEnableLocationPermissionIOS(res.data.is_enable_location_permission_ios);      
                setSpinner(false);
                setTitle(res.data.data.title);
                setMessage(res.data.data.description);
                setAndroidNotificationStatus(res.data.android_notification_enable);
                setiOSNotificationStatus(res.data.ios_notification_enable);
                setChkSendAllUsers(res.data.data.is_send_all_users == 1 || res.data.data.is_send_all_users == true ? true : false );
                if(res.data.data.device_type === 'both')
                {
                    setChkAndroid("android");
                    setChkIos("ios");
                }
                else if(res.data.data.device_type === 'android')
                {
                    setChkAndroid("android");
                    setChkIos("");
                }
                else if(res.data.data.device_type === 'ios')
                {
                    setChkAndroid("");
                    setChkIos("ios");
                }
                
                setDeviceType(res.data.data.device_type);
                //setChkScheduleNow(res.data.data.device_type);
                setDeliveryType(res.data.data.delivery_type);
                if(res.data.data.delivery_type === 2)
                {
                    let ddate = new Date(res.data.data.delivery_date);
                    setDateTimeValue(ddate);
                    setChkScheduleNow(false);
                }
                else if(res.data.data.delivery_type === 1)
                {
                    setDateTimeValue("");
                    setChkScheduleNow(true);
                }
                
                let metadata = JSON.parse(res.data.data.meta_data);
                let finalsegmentdata = [];
                let finalsegmentdata1 = [];
                
                if(metadata !== null)
                {   

                    if(res.data.data.includefilter !== undefined && res.data.data.includefilter !== "" && res.data.data.includefilter !== null)
                    {

                        let seginclude = JSON.parse(res.data.data.includefilter);
                        
                        seginclude && seginclude.map((res1,indx1)=>{
                            
                            finalsegmentdata.push(res1.segment_name);
                            
                        });

                        
                        
                        setIncludeSegmentArr(finalsegmentdata);
                        setIncludeSegmentList(JSON.parse(res.data.data.includefilter));
                        
                    }
                    else
                    {
                        setIncludeSegmentArr([]);
                        setIncludeSegmentList([]);
                    }
                    
                    if( (res.data.data.excludefilter) !== undefined && res.data.data.excludefilter !== "" && res.data.data.excludefilter !== null )
                    {
                        let segexclude = JSON.parse(res.data.data.excludefilter);
                       
                        segexclude && segexclude.map((res1,indx1)=>{
                            
                            finalsegmentdata1.push(res1.segment_name);
                        })

                        
                        setExcludeSegmentArr(finalsegmentdata1);
                        setExcludeSegmentList(segexclude);

                    }
                    else
                    {
                        setExcludeSegmentArr([]);
                        setExcludeSegmentList([]);
                        finalsegmentdata1 = [...finalsegmentdata];
                    }

                    if((metadata.image && (metadata.image != undefined )))
                        {
                            setNotificationImage(metadata.image.replaceAll(process.env.REACT_APP_Image_Path + localStorage.getItem("user_id") + '/' + localStorage.getItem("Appid") + process.env.REACT_APP_Notification_Media,''));
                        }
                        else
                        {
                            setNotificationImage('');
                        }


                    let imagename = metadata.image;

                    if(imagename !== "" && imagename !== undefined && imagename !== null)
                    {
                        let imgnamespl = imagename.split("/");
                        let getimagename = imgnamespl[imgnamespl.length -1];
                        setNotificationImgName(getimagename);
                    }

                    

                    
                    setSegmentList(res.data.allsegments);
                    
                    
                    // setLinkName(metadata.url_name); 

                    // if(metadata.url !== undefined && metadata.url !== "" && metadata.url !== null)
                    // {
                    //     let metaurl = (metadata.url).replace("http://", "");
                    //     metaurl = (metaurl).replace("https://", "");
                    //     setLinkUrl(metaurl);
                    // }

                    if(metadata.custom_data !== undefined && metadata.custom_data !== null && metadata.custom_data !== "")
                    {
                    setItemType(metadata.custom_data.item_type);
                    setTempItemType(metadata.custom_data.item_type);
                    setSlug(metadata.custom_data.slug);
                    setTempSlug(metadata.custom_data.slug);
                    setRestBase(metadata.custom_data.rest_base);
                    setTempRestBase(metadata.custom_data.rest_base);
                    setPostType(metadata.custom_data.post_type );
                    setTempPostType(metadata.custom_data.post_type );
                    setTempInvidualSelectedItemId(metadata.custom_data.item_id );
                    setInvidualSelectedItemId(metadata.custom_data.item_id );
                    setTempItemName(metadata.custom_data.item_title);
                    setItemName(metadata.custom_data.item_title);
                    setWebViewUrl(metadata.custom_data.web_view_url); 
                    }
                    
                }
            }
            else if(res.data.code === 400)
            {

                
            }
            else if(res.data.code === 500)
            {

                
            }
            else
            {

                
            }
       })
       .catch((err) => {
            
            errortoast("Something went wrong");
            
        });
}

/*********One Signal Report*******************/
export const GetNotificationReport = (notificationid, setDeliverCount, setCTRValue, setInfluenceValue, setClickedCount, setNotClickedCount, setConfirmDeliveryCount, setNotConfirmDeliveryCount, setNotificationCompleted, timevalue, setFailedCount, setRemainingCount, setErroredCount, setAndroidCount, setIosCount, setTitle, setStatus, setTotalSent, setTotalDelivered, setSpinner,setconfirmcount) => {
    //Loader(state);
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    axios.get(Url + "os-get-notification-outcomes/" + btoa(localStorage.getItem("Appid"))+"/"+notificationid,{
      headers: headers
        }).then(res => {            
            if(res.data.code === 200)
            {
                
                setSpinner(false);
                setDeliverCount(res.data.data?.successfull);
                setCTRValue(res.data.data?.click_through_rate);
                //setInfluenceValue
                setClickedCount(res.data.data?.clicked);
                setNotClickedCount(res.data.data?.did_not_click);
                setConfirmDeliveryCount(res.data.data?.confirmed_delivery);
                setNotConfirmDeliveryCount((parseInt(res.data.data?.successfull) - parseInt(res.data.data?.confirmed_delivery == null ? 0 : res.data.data.confirmed_delivery)));
                setconfirmcount(res.data.data?.confirmed_delivery == null ? 0 : res.data.data?.confirmed_delivery);
                
                let completedate = moment(res.data.data.completed_at).format("D MMMM YYYY, HH:mm");
                let completedatetime = moment(res.data.data.completed_at).format("hh:mm");

                let currdate = moment(new Date()).format("D MMM YYYY");
                let currdatetime = moment(new Date()).format("hh:mm");

                setNotificationCompleted(completedate);

                setFailedCount(res.data.data?.failed);
                setRemainingCount(res.data.data?.remaining);
                setErroredCount(res.data.data?.errored);

                if(res.data.data.platform_delivery_stats !== undefined && res.data.data.platform_delivery_stats !== null && res.data.data.platform_delivery_stats !== '')
                {
                    if(res.data.data.platform_delivery_stats.android !== undefined && res.data.data.platform_delivery_stats.android !== null && res.data.data.platform_delivery_stats.android !== '')
                    {
                        setAndroidCount(res.data.data.platform_delivery_stats.android.successful);
                    }
                    else
                    {
                        setAndroidCount(0);
                    }

                    if(res.data.data.platform_delivery_stats.ios !== undefined && res.data.data.platform_delivery_stats.ios !== null && res.data.data.platform_delivery_stats.ios !== '')
                    {
                        setIosCount(res.data.data.platform_delivery_stats.ios.successful);
                    }                    
                    else
                    {
                        setIosCount(0);
                    }
                }

                setTitle(res.data.notification?.title);
                setStatus(res.data.notification?.status);
                setTotalSent(parseInt(res.data.data?.successfull) + parseInt(res.data.data?.failed));
                setTotalDelivered(res.data.data?.confirmed_delivery);
               

            }
            else if(res.data.code === 400)
            {
				
                
            }


       })
       .catch((err) => {
            // errortoast("Something went wrong");
            
        });
}

//Get Segment Count
export const getSegmentCount = (setSegmentCount) => {
    //Loader(state);
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    axios.get(Url + "os-get-segments-count/" + btoa(localStorage.getItem("Appid")),{
      headers: headers
        }).then(res => {
            
            //setIsLoader("d-none");                     
            
            if(res.data.code === 200)
            {
                if(res.data.status === 'success')
                {
                    setSegmentCount(res.data.segmentcount);
                }
                else if(res.data.status === 'error')
                {
                    setSegmentCount(0);
                }
                
            }

       })
       .catch((err) => {
            errortoast("Something went wrong");
            
        });
}

//Delete Notification
export const SaveUploadNotificationIcon= (params, state) => {
    //const history = useHistory();

    state.setState({
        disabledsubmitbutton: true,
    });

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.post(Url + "save-notification-icon", params,{
      headers: headers
    }).then((res) => {
      
        if (res.data.code === 200) 
        {
            //setSpinner(false);

            if(res.data.data.notification_icon_json !== undefined && res.data.data.notification_icon_json !== '' && res.data.data.notification_icon_json !== null)
            {
                let iconjson = JSON.parse(res.data.data.notification_icon_json);
                if(iconjson.upload_icon !== undefined && iconjson.upload_icon !== '' && iconjson.upload_icon !== null)
                {
                    state.setState({
                        savespinner:false,
                        last_updated_timestamp:res.data.data.updated_at,
                        finalfile: iconjson.upload_icon
                    });
                }
                else
                {
                    state.setState({
                        savespinner:false,
                        last_updated_timestamp:res.data.data.updated_at
                    });
                }
            }
            else
            {
                state.setState({
                    savespinner:false,
                    last_updated_timestamp:res.data.data.updated_at
                });
            }

            sucesstoast("Your changes have been saved.");
        } 
        else if (res.data.code === 400)
        {
            //setSpinner(false);

            state.setState({
                savespinner:false,
            });

            if (Object.keys(res.data.data).indexOf("appicontxt") === -1) 
            {
                
                state.setState({
                    appicontxterror: null,
                    disabledsubmitbutton: false,
                });
                errortoast(res.data.status);
            } 
            else 
            {
                

                errortoast(res.data.status);
            }
        } 
    })
    .catch((err) => {
        errortoast("Something went wrong");
        
        //setIsDelete(!isdelete);
    });
}

//Get Notification Icon
export const GetNotificationIcon = (state) => {
    
    clientid = localStorage.getItem("user_id");
    changeurl();

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }

    axios.get(Url + "get-notification-icon/" + btoa(localStorage.getItem("Appid")),{
        headers: headers
    }).then(async (res) => {
        if (res.data.code === 200) 
        {
            
            
                state.setState({
                        is_default:res.data.is_default,
                        finalfile: res.data.data.upload_icon !== undefined ? res.data.data.upload_icon : '',
                        designid:res.data.data.design_id,
                        spinner: false,
                        //is_publish: res.data.build_status,
                        last_updated_timestamp:res.data.data.icon_updated_timestamp,
                });
            
        } 
        else if (res.data.code === 401)
        {
            if (res.data.data === "App does not exists.") 
            {
                state.props.history.push("/my-apps");
                errortoast(res.data.data);
                state.setState({
                    spinner: false,
                });          
            }
        }
        else
        {
            errortoast("Something went wrong. Try again after sometime.");
            state.setState({
                spinner: false,
            });
        }
    })
    .catch((error) => 
    {
        // errortoast("Something went wrong. Try again after sometime.");
    });

}

// export const SaveNotificationConfiguration = (params, setIsSettingLoader, setSubmitSettingVal, setOSErrorText) =>{

//     const headers = {
//         'Content-Type': 'application/json',
//         'Authorization': "Bearer " + localStorage.getItem("amstoken"),
//     }
      
//     axios.post(Url + "os-save-android-configuration", params,{
//         headers: headers
//     }).then((res) => {
        
//         if (res.data.code === 200) 
//         {
//             //setSpinner(false);
//             setIsSettingLoader("d-none");
//             setSubmitSettingVal("Save");

//             if(res.data.androiddata !== null && res.data.androiddata !== "" && res.data.androiddata !== undefined)
//             {
//                 if(res.data.androiddata.jsonresponse !== null && res.data.androiddata.jsonresponse !== "" && res.data.androiddata.jsonresponse !== undefined)
//                 {
//                     let androiderr = JSON.parse(res.data.androiddata.jsonresponse);

//                     if(androiderr.errors !== "" && androiderr.errors !== null && androiderr.errors !== undefined)
//                     {
//                         setOSErrorText(androiderr.errors);
//                     }
//                 }
//             }
//             else if(res.data.iosdata !== null && res.data.iosdata !== "" && res.data.iosdata !== undefined)
//             {
//                 if(res.data.iosdata.jsonresponse !== null && res.data.iosdata.jsonresponse !== "" && res.data.iosdata.jsonresponse !== undefined)
//                 {
//                     let ioserr = JSON.parse(res.data.iosdata.jsonresponse);

//                     if(ioserr.errors !== "" && ioserr.errors !== null && ioserr.errors !== undefined)
//                     {
//                         setOSErrorText(ioserr.errors);
//                     }
//                 }
//             }
            

//             if(res.data.android_status_code === "os_app_created" && res.data.ios_status_code === "os_app_created")
//             {
//                 sucesstoast("Android & iOS configuration has been saved successfully.");
//             }
//             else if(res.data.android_status_code === "os_app_created" && res.data.ios_status_code === "os_update_error")
//             {
//                 sucesstoast("Your details have been submitted. iOS configuration has not been updated.");
//             }
//             else if(res.data.android_status_code === "os_app_created" && res.data.ios_status_code === "os_app_create_error")
//             {
//                 errortoast("Your details have been submitted. iOS configuration is wrong.");
//             }
//             else if(res.data.android_status_code === "os_app_created" && res.data.ios_status_code === "os_app_updated")
//             {
//                 sucesstoast("Your details have been submitted.");
//             }
//             else if(res.data.android_status_code === "os_app_create_error" && res.data.ios_status_code === "os_app_created")
//             {
//                 sucesstoast("Your details have been submitted. Android configuration is wrong.");
//             }
//             else if(res.data.android_status_code === "os_app_exists" && res.data.ios_status_code === "os_update_error")
//             {
//                 sucesstoast("Your details have been submitted. iOS configuration is wrong.");
//             }
//             else if(res.data.android_status_code === "os_app_exists" && res.data.ios_status_code === "os_app_updated")
//             {
//                 sucesstoast("Your details have been submitted.");
//             }
//             else if(res.data.android_status_code === "os_update_error" && res.data.ios_status_code === "os_app_exists")
//             {
//                 sucesstoast("Your details have been submitted. Android configuration is wrong.");
//             }
//             else if(res.data.android_status_code === "os_app_updated" && res.data.ios_status_code === "os_app_exists")
//             {
//                 sucesstoast("Your details have been submitted.");
//             }
//             else if(res.data.android_status_code === "os_app_create_error")
//             {
//                 errortoast("Android configuration is wrong.");
//             }
//             else if(res.data.ios_status_code === "os_app_create_error" || res.data.ios_status_code === "os_updated_error")
//             {
//                 errortoast("iOS configuration is wrong.");
//             }
//             else if(res.data.ios_status_code === "os_app_updated")
//             {
//                 sucesstoast("Your details have been submitted.");
//             }
//             else if(res.data.android_status_code === "os_app_created")
//             {
//                 sucesstoast("Your details have been submitted.");
//             }
//             else if(res.data.plain_android_status === "success")
//             {
//                 sucesstoast("Your details have been submitted.");
//             }
//             else if(res.data.plain_ios_status === "success")
//             {
//                 sucesstoast("Your details have been submitted.");
//             }
//             else if(res.data.plain_android_status === "files_save_error")
//             {
//                 errortoast("Oops! Something went wrong.");
//             }
//             else if(res.data.plain_ios_status === "files_save_error")
//             {
//                 errortoast("Oops! Something went wrong.");
//             }

//             /*if(res.data.android_error_code === "os_app_create_error")
//             {
//                 errortoast("Android configuration is not correct");
//             }
//             else if(res.data.android_error_code === "os_update_error")
//             {
//                 errortoast("Android configuration has not been updated");
//             }
//             else if(res.data.android_error_code === "os_app_error")
//             {
//                 errortoast("Android configuration is not correct");
//             }
//             else if(res.data.android_success_code === "os_app_created")
//             {
//                 sucesstoast("Android configuration has been saved successfully");
//             }
//             else if(res.data.android_success_code === "os_app_updated")
//             {
//                 sucesstoast("Android configuration has been updated successfully");
//             }
//             else if(res.data.ios_error_code === "os_app_error")
//             {
//                 errortoast("iOS configuration is not correct");
//             }                
//             else if(res.data.ios_error_code === "os_update_error")
//             {
//                 errortoast("iOS configuration has not been updated");
//             }
//             else if(res.data.ios_error_code === "os_app_create_error")
//             {
//                 errortoast("iOS configuration is not correct");
//             }
//             else if(res.data.ios_error_code === "os_app_created")
//             {
//                 sucesstoast("iOS configuration has been saved successfully");
//             }
//             else if(res.data.ios_error_code === "os_app_updated")
//             {
//                 sucesstoast("iOS configuration has been updated successfully");
//             }
//             else if(res.data.ios_error_code === "os_app_exists")
//             {
//                 sucesstoast("iOS configuration is already verified");
//             }
//             else if(res.data.plain_android_success_code === 'success')
//             {
//                 sucesstoast("The record has been saved.");
//             }
//             else if(res.data.plain_ios_success_code === 'success')
//             {
//                 
//                 sucesstoast("The record has been saved.");
//             }
//             else if(res.data.plain_ios_success_code === 'success')
//             {
//                 
//                 sucesstoast("The record has been saved.");
//             }
//             else if(res.data.plain_android_error_code === 'files_save_error')
//             {
//                 
//                 sucesstoast("Oops! Something went wrong.");
//             }
//             else if(res.data.plain_ios_error_code === 'files_save_error')
//             {
//                 
//                 sucesstoast("Oops! Something went wrong.");
//             }


//             if(res.data.android_response !== null && res.data.android_response !== "" && res.data.android_response !== undefined)
//             {
//                 if(res.data.android_response.is_android_files_uploaded && res.data.android_response.is_android_files_uploaded === 2)
//                 {
//                     if(res.data.android_error_code === "os_app_error")
//                     {
//                         errortoast("Android configuration is not correct");
//                     }                
//                     else if(res.data.android_error_code === "os_update_error")
//                     {
//                         errortoast("Android configuration has not been updated");
//                     }
//                     else if(res.data.android_error_code === "os_app_create_error")
//                     {
//                         errortoast("Android configuration is not correct");
//                     }
//                     else if(res.data.android_success_code === "os_app_created")
//                     {
//                         sucesstoast("Android configuration has been saved successfully");
//                     }
//                     else if(res.data.android_success_code === "os_app_updated")
//                     {
//                         sucesstoast("Android configuration has been updated successfully");
//                     }
//                     else if(res.data.android_success_code === "os_app_exists")
//                     {
//                         sucesstoast("Android configuration is already verified");
//                     }
//                 }
//             }
//             if(res.data.ios_response !== null && res.data.ios_response !== "" && res.data.ios_response !== undefined)
//             {
//                 if(res.data.ios_response.is_ios_files_uploaded && res.data.ios_response.is_ios_files_uploaded === 2)
//                 {
//                     if(res.data.ios_error_code === "os_app_error")
//                     {
//                         errortoast("iOS configuration is not correct");
//                     }                
//                     else if(res.data.ios_error_code === "os_update_error")
//                     {
//                         errortoast("iOS configuration has not been updated");
//                     }
//                     else if(res.data.ios_error_code === "os_app_create_error")
//                     {
//                         errortoast("iOS configuration is not correct");
//                     }
//                     else if(res.data.ios_error_code === "os_app_created")
//                     {
//                         sucesstoast("iOS configuration has been saved successfully");
//                     }
//                     else if(res.data.ios_error_code === "os_app_updated")
//                     {
//                         sucesstoast("iOS configuration has been updated successfully");
//                     }
//                     else if(res.data.ios_error_code === "os_app_exists")
//                     {
//                         sucesstoast("iOS configuration is already verified");
//                     }
//                 }
//                 if(res.data.iosresp.is_ios_files_uploaded && res.data.iosresp.is_ios_files_uploaded === 2)
//                 {
//                     
//                     errortoast("Uploaded p12 file/password  is invalid.");
//                 }
//                 else if(res.data.iosresp.is_ios_files_uploaded && res.data.iosresp.is_ios_files_uploaded === 1)
//                 {
//                     
//                     sucesstoast("The record has been saved.");
//                 }
//                 else if(res.data.iosresp.is_android_files_uploaded && res.data.iosresp.is_android_files_uploaded === 2)
//                 {
//                     
//                     errortoast("Uploaded p12 file/password  is invalid.");
//                 }
//                 else if(res.data.iosresp.is_android_files_uploaded && res.data.iosresp.is_android_files_uploaded === 1)
//                 {
//                     
//                     sucesstoast("The record has been saved.");
//                 }
//             }
//             else if(res.data.androidresp !== null && res.data.androidresp !== "" && res.data.androidresp !== undefined)
//             {
//                 if(res.data.androidresp.is_ios_files_uploaded && res.data.androidresp.is_ios_files_uploaded === 2)
//                 {
//                     
//                     errortoast("Uploaded p12 file/password  is invalid.");
//                 }
//                 else if(res.data.androidresp.is_ios_files_uploaded && res.data.androidresp.is_ios_files_uploaded === 1)
//                 {
//                     
//                     sucesstoast("The record has been saved.");
//                 }
//                 else if(res.data.androidresp.is_android_files_uploaded && res.data.androidresp.is_android_files_uploaded === 2)
//                 {
//                     
//                     errortoast("Uploaded p12 file/password  is invalid.");
//                 }
//                 else if(res.data.androidresp.is_android_files_uploaded && res.data.androidresp.is_android_files_uploaded === 1)
//                 {
//                     
//                     sucesstoast("The record has been saved.");
//                 }
//             }
//             else if(res.data.plainandroid === 'success' && res.data.plainios === 'success')
//             {
//                 
//                 sucesstoast("The record has been saved.");
//             }
//             else if(res.data.plain_android_success_code === 'success')
//             {
//                 
//                 sucesstoast("The record has been saved.");
//             }
//             else if(res.data.plain_ios_success_code === 'success')
//             {
//                 
//                 sucesstoast("The record has been saved.");
//             }
//             else if(res.data.plain_ios_success_code === 'success')
//             {
//                 
//                 sucesstoast("The record has been saved.");
//             }
//             else if(res.data.plain_android_error_code === 'files_save_error')
//             {
//                 
//                 sucesstoast("Oops! Something went wrong.");
//             }
//             else if(res.data.plain_ios_error_code === 'files_save_error')
//             {
//                 
//                 sucesstoast("Oops! Something went wrong.");
//             }
            
            

//             if(res.data.status === "success")
//             {
//                 sucesstoast("The record has been saved.");
//             }
//             else if(res.data.androidstatus === "success")
//             {
//                 sucesstoast("The record has been saved.");
//             }
//             else if(res.data.iosstatus === "success")
//             {
//                 sucesstoast("The record has been saved.");
//             }
//             else if(res.data.androidstatus === "already_updated")
//             {
//                 sucesstoast("The record has been saved.");
//             }
//             else if(res.data.androidresp === "android_data_error")
//             {
//                 
//             }
//             else if(res.data.androidresp === null)
//             {
//                 
//             }*/
//         } 
//         else if (res.data.code === 400)
//         {
//             setIsSettingLoader("d-none");
//             setSubmitSettingVal("Save");

//             errortoast("Oops! Something went wrong.");
//         }
//         else if (res.data.code === 500)
//         {
//             setIsSettingLoader("d-none");
//             setSubmitSettingVal("Save");

//             errortoast("Oops! Something went wrong.");
//         } 
//     })
//     .catch((err) => {
//         errortoast("Something went wrong");
//         
//     });
// }

export const SaveNotificationConfiguration = (params, setIsSettingLoader, setSubmitSettingVal, setOSErrorText,setUpdatedAt) =>{

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
      
    axios.post(Url + "save-plain-notification-settings", params,{
        headers: headers
    }).then((res) => {
        
        if (res.data.code === 200) 
        {
            //setSpinner(false);
            setIsSettingLoader("d-none");
            setSubmitSettingVal("Save");
            sucesstoast("The record has been saved.");
            if(setUpdatedAt){
            setUpdatedAt(res.data.updated_at);
            }

        } 
        else if (res.data.code === 400)
        {
            setIsSettingLoader("d-none");
            setSubmitSettingVal("Save");

            errortoast("Oops! Something went wrong.");
        }
        else if (res.data.code === 500)
        {
            setIsSettingLoader("d-none");
            setSubmitSettingVal("Save");

            errortoast("Oops! Something went wrong.");
        }else{
            setIsSettingLoader("d-none");
            setSubmitSettingVal("Save");

            errortoast("Oops! Something went wrong.");

        } 
    })
    .catch((err) => {
        errortoast("Something went wrong");
        
    });
}

export const OldSaveRichNotificationConfiguration = (params, setIsSettingLoader, setSubmitSettingVal, setOSErrorText,setUpdatedAt) =>
{

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
      
    axios.post(Url + "os-save-android-configuration", params,{
        headers: headers
    }).then((res) => {
        
        if (res.data.code === 200) 
        {
            setIsSettingLoader("d-none");
            setSubmitSettingVal("Save");
        if(res.data.android_status != null && res.data.android_status != undefined && res.data.android_status != "success")
        {
            errortoast("Oops! Something went wrong.");
            setOSErrorText(JSON.parse(res.data.androiddata.jsonresponse));
        }
        else if(res.data.ios_status != null && res.data.ios_status != undefined && res.data.ios_status != "success")
        {
            errortoast("Oops! Something went wrong.");
            setOSErrorText(JSON.parse(res.data.iosdata.jsonresponse));
        }
        else
        {
            sucesstoast("The record has been saved.");
        }
            
            if(setUpdatedAt)
            {
                setUpdatedAt(res.data.updated_at);
            }

        } 
        else if (res.data.code === 400)
        {
            setIsSettingLoader("d-none");
            setSubmitSettingVal("Save");
            errortoast("Oops! Something went wrong.");
        }
        else if (res.data.code === 500)
        {
            setIsSettingLoader("d-none");
            setSubmitSettingVal("Save");

            errortoast("Oops! Something went wrong.");
        }
        else
        {
            setIsSettingLoader("d-none");
            setSubmitSettingVal("Save");
            errortoast("Oops! Something went wrong.");
        } 
    })
    .catch((err) => 
    {
        setIsSettingLoader("d-none");
        setSubmitSettingVal("Save");
        errortoast("Something went wrong");
        
    });
}

export const SaveRichNotificationConfiguration = (params, setIsSettingLoader, setSubmitSettingVal, setOSErrorText,setUpdatedAt) =>
{

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
      
    axios.post(Url + "save-rich-notification-settings", params,{
        headers: headers
    }).then((res) => {
        
        if (res.data.code === 200) 
        {
            setIsSettingLoader("d-none");
            setSubmitSettingVal("Save");
            if(res.data.android_status != null && res.data.android_status != undefined && res.data.android_status != "success")
            {
                errortoast("Oops! Something went wrong.");
                setOSErrorText(JSON.parse(res.data.androiddata.jsonresponse));
            }
            else if(res.data.ios_status != null && res.data.ios_status != undefined && res.data.ios_status != "success")
            {
                errortoast("Oops! Something went wrong.");
                setOSErrorText(JSON.parse(res.data.iosdata.jsonresponse));
            }
            else
            {
                sucesstoast("The record has been saved.");
            }
            
            if(setUpdatedAt)
            {
                setUpdatedAt(res.data.updated_at);
            }

        } 
        else if (res.data.code === 400)
        {
            setIsSettingLoader("d-none");
            setSubmitSettingVal("Save");
            errortoast("Oops! Something went wrong.");
        }
        else if (res.data.code === 500)
        {
            setIsSettingLoader("d-none");
            setSubmitSettingVal("Save");

            errortoast("Oops! Something went wrong.");
        }
        else
        {
            setIsSettingLoader("d-none");
            setSubmitSettingVal("Save");
            errortoast("Oops! Something went wrong.");
        } 
    })
    .catch((err) => 
    {
        setIsSettingLoader("d-none");
        setSubmitSettingVal("Save");
        errortoast("Something went wrong");
        
    });
}


export const GetSettingDetails = (setAndroidServerKey, setAndroidNotificationStatus, setiOSNotificationStatus, setiOSConfigureFileName, setAndroidConfigureFilename, setIosPlistConfigureFileName, setGcmSenderId, setAndroidBundleID, setIosBundleID, setIosConfigurePassword, setSpinner, setOSErrorText) =>{

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
  
    axios.get(Url + "os-get-configuration/" + btoa(localStorage.getItem("Appid")),{
        headers: headers
    }).then(async (res) => {
        if (res.data.code === 200) 
        {
           
            setSpinner(false);

            

            
            

            if(res.data.androiddata !== null && res.data.androiddata !== '' && res.data.androiddata !== undefined)
            {
                setAndroidNotificationStatus(res.data.androiddata.is_rich_notification_enabled);
                

                localStorage.setItem("android_os_notification_enable", res.data.androiddata.is_rich_notification_enabled);

                setAndroidServerKey(res.data.androiddata.notification_key);
                setAndroidConfigureFilename(res.data.androiddata.notification_config_file);
                setGcmSenderId(res.data.androiddata.gcm_sender_id);

            }
            else
            {
                localStorage.setItem("android_os_notification_enable", 0);
            }

            setAndroidBundleID(res.data.android_bundle_id);

            if(res.data.iosdata !== null && res.data.iosdata !== '' && res.data.iosdata !== undefined)
            {
                setiOSNotificationStatus(res.data.iosdata.is_rich_notification_enabled);

                localStorage.setItem("ios_os_notification_enable", res.data.iosdata.is_rich_notification_enabled);

                setiOSConfigureFileName(res.data.iosdata.apns_p12_certificate);
                setIosPlistConfigureFileName(res.data.iosdata.apns_provisioning_profile);

                setIosConfigurePassword(res.data.iosdata.apns_p12_certificate_password);
            }
            else
            {
                localStorage.setItem("ios_os_notification_enable", 0);
            }

            setIosBundleID(res.data.ios_bundle_id);

            if(res.data.osandroiddata !== null && res.data.osandroiddata !== '' && res.data.osandroiddata !== undefined)
            {
                if(res.data.osandroiddata.jsonresponse !== null && res.data.osandroiddata.jsonresponse !== "" && res.data.osandroiddata.jsonresponse !== undefined )
                {
                    let androiderr = JSON.parse(res.data.osandroiddata.jsonresponse);
                    if(androiderr.errors !== null && androiderr.errors !== "" && androiderr.errors !== undefined)
                    {
                        setOSErrorText(androiderr.errors);
                    }
                }
            }
            else if(res.data.osiosdata !== null && res.data.osiosdata !== '' && res.data.osiosdata !== undefined)
            {
                if(res.data.osiosdata.jsonresponse !== null && res.data.osiosdata.jsonresponse !== "" && res.data.osiosdata.jsonresponse !== undefined )
                {
                    let ioserr = JSON.parse(res.data.osiosdata.jsonresponse);
                    if(ioserr.errors !== null && ioserr.errors !== "" && ioserr.errors !== undefined)
                    {
                        setOSErrorText(ioserr.errors);
                    }
                }
            }
        
        }         
        else
        {
            errortoast("Something went wrong. Try again after sometime.");
        
        }
    })
    .catch((error) => 
    {
        // errortoast("Something went wrong. Try again after sometime.");
    });
}

//UploAD aNDOID cERTIFICATE TO s3
//export const UploadCertificateToS3 = (params, setOriginalJsonFile, setAndoidCertificateS3Name, setBtnSubmitStatus, setAndroidFileUpdStatus, setgcmsenderiddata) => {
export const UploadCertificateToS3 = (params, setGCMSenderIdData) => {

    
    if (params.original !== "")
    {
        function dataURLtoFileoriginal(dataurl, filename) 
        {
            
            var arr = dataurl.split(","),
            //mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
  
            while (n--) 
            {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: "application/json" });
        }

        let originalfile = dataURLtoFileoriginal(params.original, "hello.json");

        
        
        const oriconfig = {
                            bucketName: process.env.REACT_APP_bucketName,
                            dirName: params.original_path.replace(/\/$/, "") /* optional */,
                            region: process.env.REACT_APP_region,
                            accessKeyId: process.env.REACT_APP_accessKeyId,
                            secretAccessKey: process.env.REACT_APP_secretAccessKey,
                            s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
                        };

        // const ReactS3original = new CustomS3Client(oriconfig);
        const customS3Client = new CustomS3Client(oriconfig);
        const originalFileName = params.imgname;
        
        

        customS3Client.uploadFile(originalfile, originalFileName)
        .then((data) => {
            if (data.status === 204) 
            {
                
                //setOriginalJsonFile(data.location);
                
                //setAndoidCertificateS3Name(data.key.split("/").pop());
                //setBtnSubmitStatus(true);
                //setAndroidFileUpdStatus(true);

                // const configurejsonresp = fetch(data.location,{
                //                         headers : { 
                //                             'Content-Type': 'application/json',
                //                             'Accept': 'application/json'
                //                         }
                //                     }
                //                 )
                //                 .then(function(response){
                                    
                //                     //const configurefileresp = JSON.parse(response);
                                    
                                    
                //                     return response.json();
                //                 })
                //                 .then(function(myJson) {
                                    
                //                     if(myJson.project_info !== undefined && myJson.project_info !== null && myJson.project_info !== '' && setGCMSenderIdData != '')
                //                     {
                //                         //setgcmsenderiddata(myJson.project_info.project_number);
                //                         setGCMSenderIdData(myJson.project_info.project_number);
                //                     }
                //                     else
                //                     {
                //                         // errortoast("Incorrect file format uploaded.");
                //                     }
                //                 });

                
                
                
                //callback();
            }
        })
        .catch((err) => {
            errortoast("Something went wrong. Try again after sometime.");
        });
    }
    else
    {
        //callback();
    }
};

export const UploadCertificateToIOSS3 = (params, setGCMSenderIOSIdData) => {

    
    if (params.original !== "")
    {
        function dataURLtoFileoriginal(dataurl, filename) 
        {
            
            var arr = dataurl.split(","),
            //mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
  
            while (n--) 
            {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new Blob([u8arr], { type: "application/json" });
        }

        let originalfile = dataURLtoFileoriginal(params.original, "hello.json");

        
        
        const oriconfig = {
                            bucketName: process.env.REACT_APP_bucketName,
                            dirName: params.original_path.replace(/\/$/, "") /* optional */,
                            region: process.env.REACT_APP_region,
                            accessKeyId: process.env.REACT_APP_accessKeyId,
                            secretAccessKey: process.env.REACT_APP_secretAccessKey,
                            s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
                        };

        const ReactS3original = new CustomS3Client(oriconfig);
        const originalFileName = params.imgname;
        
        

        ReactS3original.uploadFile(originalfile, originalFileName)
        .then((data) => {
            if (data.status === 204) 
            {
                
                //setOriginalJsonFile(data.location);
                //
                //setAndoidCertificateS3Name(data.key.split("/").pop());
                //setBtnSubmitStatus(true);
                //setAndroidFileUpdStatus(true);

                // const configurejsonresp = fetch(data.location,{
                //                         headers : { 
                //                             'Content-Type': 'application/json',
                //                             'Accept': 'application/json'
                //                         }
                //                     }
                //                 )
                //                 .then(function(response){
                                    
                                    
                //                     return response.json();
                //                 })
                //                 .then(function(myJson) {
                                   

                //                     if(myJson.project_info !== undefined && myJson.project_info !== null && myJson.project_info !== '')
                //                     {
                //                         setGCMSenderIOSIdData(myJson.project_info.project_number);
                //                     }
                //                     else
                //                     {
                //                         // errortoast("Incorrect file format uploaded.");
                //                     }
                //                 });

                
                
                
                //callback();
            }
        })
        .catch((err) => {
            errortoast("Something went wrong. Try again after sometime.");
        });
    }
    else
    {
        //callback();
    }
};

//UploAD aNDOID cERTIFICATE TO s3
//export const UploadP12CertificateToS3 = (params, setiOSOriginalJsonFile, setiOSCertificateS3Name, setBtnSubmitStatus, setIosFileUpdStatus) => {
export const UploadP12CertificateToS3 = (params) => {
    

    if (params.original !== "") 
    {
        function dataURLtoFileoriginal(dataurl, filename) 
        {

            var arr = dataurl.split(","),
            //mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

            while (n--) 
            {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new Blob([u8arr], { type: "application/p12" });
        }

        let originalfile = dataURLtoFileoriginal(params.original, "hello.p12");

        

        const oriconfig = {
            bucketName: process.env.REACT_APP_bucketName,
            dirName: params.original_path.replace(/\/$/, "") /* optional */,
            region: process.env.REACT_APP_region,
            accessKeyId: process.env.REACT_APP_accessKeyId,
            secretAccessKey: process.env.REACT_APP_secretAccessKey,
            s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
        };

        const ReactS3original = new CustomS3Client(oriconfig);
        const originalFileName = params.imgname;

        

        ReactS3original.uploadFile(originalfile, originalFileName)
            .then((data) => {
                if (data.status === 204) 
                {
                    
                    /*setiOSOriginalJsonFile(data.location);
                    
                    setiOSCertificateS3Name(data.key.split("/").pop());
                    setBtnSubmitStatus(true);
                    setIosFileUpdStatus(true);*/

                    /*state.setState({
                        pushnotifications3name: data.key.split("/").pop(),
                        disabledsubmitbutton: false,
                        original_plist_file: data.location,
                    });
                    callback();*/
                }
            })
            .catch((err) => {
                errortoast("Something went wrong. Try again after sometime.");
            });
    } 
    else 
    {
        //callback();
    }
};


//export const UploadProvisionCertificateToS3 = (params, setiOSPlistOriginalJsonFile, setiOSPlistCertificateS3Name, setBtnSubmitStatus, setIosPlistFileUpdStatus) => {

export const UploadProvisionCertificateToS3 = (params) => {
    //

    if (params.original !== "") 
    {
        function dataURLtoFileoriginal(dataurl, filename) 
        {

            var arr = dataurl.split(","),
            //mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

            while (n--) 
            {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new Blob([u8arr], { type: "application/mobileprovision" });
        }

        let originalfile = dataURLtoFileoriginal(params.original, "hello.mobileprovision");



        const oriconfig = {
            bucketName: process.env.REACT_APP_bucketName,
            dirName: params.original_path.replace(/\/$/, "") /* optional */,
            region: process.env.REACT_APP_region,
            accessKeyId: process.env.REACT_APP_accessKeyId,
            secretAccessKey: process.env.REACT_APP_secretAccessKey,
            s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
        };

        const ReactS3original = new CustomS3Client(oriconfig);
        const originalFileName = params.imgname;


        ReactS3original.uploadFile(originalfile, originalFileName)
            .then((data) => {
                if (data.status === 204) 
                {
                    /*setiOSPlistOriginalJsonFile(data.location);
                    setiOSPlistCertificateS3Name(data.key.split("/").pop());
                    setBtnSubmitStatus(true);
                    setIosPlistFileUpdStatus(true);*/

                    /*state.setState({
                        pushnotifications3name: data.key.split("/").pop(),
                        disabledsubmitbutton: false,
                        original_plist_file: data.location,
                    });
                    callback();*/
                }
            })
            .catch((err) => {
                errortoast("Something went wrong. Try again after sometime.");
            });
    } 
    else 
    {
        //callback();
    }
};

export const SaveNotificationConfigurationSH1 = (params, setIsSettingLoader, setSubmitSettingVal, setSHADepend, sha1depend,Callback) =>{

	const headers = {
		'Content-Type': 'application/json',
		'Authorization': "Bearer " + localStorage.getItem("amstoken"),
	}
	axios.post(Url + "save-plain-notification-settings",params,{
		headers: headers
	}).then(res => {
		if(res.data.code === 200)
		{
			// sucesstoast("Your records has been saved successfully.");
			setIsSettingLoader("d-none");
			setSubmitSettingVal("Save");	
            setSHADepend(sha1depend + 1);
            Callback();
				  
		}
		else if(res.data.code === 400)
		{
			errortoast("Oops! Something went wrong.");
			setIsSettingLoader("d-none");
			setSubmitSettingVal("Save");	
		}
		else
		{
            errortoast("Oops! Something went wrong.");
			setIsSettingLoader("d-none");
			setSubmitSettingVal("Save");

		}
	}).catch(() => {
		
		errortoast("Oh no! Something’s not right.")
	})
	
}

export const GetPlainSettingDetails = (setPlainAndroidNotificationStatus, setAndroidServerKey, setPlainAndroidConfigureFilename, setPlainiOSNotificationStatus, setPlainiOSConfigureFileName, setiOSServerKey, setAndroidBundleID, setIosBundleID, setAndroidSHA1Key, setSHA1_InProcess, setAppPublish, setPlanname,setSpinner,setUpdatedAt,setRichAndroidNotificationStatus,setRichiOSNotificationStatus) =>
{

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
  
    axios.get(Url + "get-plain-notification-settings/" + btoa(localStorage.getItem("Appid")),{
        headers: headers
    }).then(async (res) => {
        if (res.data.code === 200) 
        {
            setUpdatedAt(res.data.updated_at);
            setSpinner(false);
        
            if(res.data.androiddata !== null && res.data.androiddata !== '' && res.data.androiddata !== undefined)
            {
                setPlainAndroidNotificationStatus(res.data.androiddata.is_notification_enabled);
                setRichAndroidNotificationStatus(res.data.androiddata.is_rich_notification_enabled);
                // localStorage.setItem("android_plain_notification_enable", res.data.androiddata.is_notification_enabled);
                setAndroidServerKey(res.data.androiddata.notification_key);
                setPlainAndroidConfigureFilename(res.data.androiddata.notification_config_file);

                setAndroidSHA1Key(res.data.androiddata.sha1);
				setSHA1_InProcess(res.data.androiddata.in_process);

                setAppPublish(res.data.androiddata.is_app_already_published);
            }

            setAndroidBundleID(res.data.android_bundle_id);

            if(res.data.iosdata !== null && res.data.iosdata !== '' && res.data.iosdata !== undefined)
            {
                setPlainiOSNotificationStatus(res.data.iosdata.is_notification_enabled);
                setRichiOSNotificationStatus(res.data.iosdata.is_rich_notification_enabled);
                localStorage.setItem("ios_plain_notification_enable", res.data.iosdata.is_notification_enabled);
                setPlainiOSConfigureFileName(res.data.iosdata.notification_config_file);
                setiOSServerKey(res.data.iosdata.notification_key);
                
            }

            setIosBundleID(res.data.ios_bundle_id);
            setPlanname(res.data.plandata.plan);
        
        }         
        else
        {
            setSpinner(false);
            // errortoast("Something went wrong. Try again after sometime.");
        
        }
    })
    .catch((error) => 
    {
        
        // errortoast("Something went wrong. Try again after sometime.");
    });
}
export const GetNotificationSettingDetails = (setSHA1Loader,setPlainAndroidNotificationStatus, setAndroidServerKey, setPlainAndroidConfigureFilename, setPlainiOSNotificationStatus, setPlainiOSConfigureFileName, setiOSServerKey, setAndroidBundleID, setIosBundleID, setAndroidSHA1Key, setSHA1_InProcess, setAppPublish, setPlanname,setSpinner,setUpdatedAt,setRichAndroidNotificationStatus,setRichiOSNotificationStatus,setiOSConfigureFileName,setIosPlistConfigureFileName,setIosConfigurePassword,setAndroidServiceAcoountName,setPlainiOSServiceAccount,setIsAddonPurchased,Callback) =>
{
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
  
    axios.get(Url + "os-get-configuration/" + btoa(localStorage.getItem("Appid")),{
        headers: headers
    }).then(async (res) => {
        if (res.data.code === 200) 
        {

            setSpinner(false);

            if(res.data.androiddata !== null && res.data.androiddata !== '' && res.data.androiddata !== undefined)
            {
                setPlainAndroidNotificationStatus(res.data.androiddata.is_notification_enabled);
                setRichAndroidNotificationStatus(res.data.androiddata.is_rich_notification_enabled);
                setAndroidServerKey(res.data.androiddata.notification_key);
                setPlainAndroidConfigureFilename(res.data.androiddata.notification_config_file);
                setAndroidServiceAcoountName(res.data.androiddata.notification_service_account_json);
                setAndroidSHA1Key(res.data.androiddata.sha1);
				setSHA1_InProcess(res.data.androiddata.in_process);
                setAppPublish(res.data.androiddata.is_app_already_published);
                setUpdatedAt(res.data.androiddata.updated_at);
                if(res.data.androiddata.sha1 !== null && res.data.androiddata.sha1 !== '' && res.data.androiddata.sha1 !== undefined) setSHA1Loader(false);
            }

            setAndroidBundleID(res.data.android_bundle_id);

            if(res.data.iosdata !== null && res.data.iosdata !== '' && res.data.iosdata !== undefined)
            {
                setPlainiOSNotificationStatus(res.data.iosdata.is_notification_enabled);
                setRichiOSNotificationStatus(res.data.iosdata.is_rich_notification_enabled);
                setPlainiOSConfigureFileName(res.data.iosdata.notification_config_file);
                setPlainiOSServiceAccount(res.data.iosdata.notification_service_account_json);
                setiOSServerKey(res.data.iosdata.notification_key);
                setiOSConfigureFileName(res.data.iosdata.apns_p12_certificate);
                setIosPlistConfigureFileName(res.data.iosdata.apns_provisioning_profile);
                setIosConfigurePassword(res.data.iosdata.apns_p12_certificate_password);
                setUpdatedAt(res.data.iosdata.updated_at);
                
                
            }

            setIosBundleID(res.data.ios_bundle_id);
            setPlanname(res.data.plandata.plan);
            setIsAddonPurchased(res.data.plandata?.is_rich_addon_purchased);
            
            if(res.data.androiddata.in_process != 0)
            {
                Callback();
            }

        
        }         
        else
        {
            setSpinner(false);
            
        }
    })
    .catch((error) => 
    {
        setSpinner(false);
        setSHA1Loader(false);
        
        // errortoast("Something went wrong. Try again after sometime.");
    })
}

export const GetNotificationConfigDetails = (setConfigDetails) =>
{
    
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + localStorage.getItem("amstoken"),
        }
      
        axios.get(Url + "os-get-configuration/" + btoa(localStorage.getItem("Appid")),{
            headers: headers
        }).then(async (res) => {
            if (res.data.code === 200) 
            {
                setConfigDetails(res.data);
            }         
            else
            {
                
            }
        })
        .catch((error) => 
        {

        });
    }

export const GetPlainNotificationSettingDetails = (setPlainAndroidNotificationStatus, setAndroidServerKey, setPlainAndroidConfigureFilename, setPlainiOSNotificationStatus, setPlainiOSConfigureFileName, setiOSServerKey, setAndroidBundleID, setIosBundleID, setAndroidSHA1Key, setSHA1_InProcess, setAppPublish, setPlanname,setSpinner,setUpdatedAt,setRichAndroidNotificationStatus,setRichiOSNotificationStatus) =>
{

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
  
    axios.get(Url + "get-plain-notification-settings/" + btoa(localStorage.getItem("Appid")),{
        headers: headers
    }).then(async (res) => {
        if (res.data.code === 200) 
        {
            setUpdatedAt(res.data.updated_at);
            setSpinner(false);
            
            
            if(res.data.androiddata !== null && res.data.androiddata !== '' && res.data.androiddata !== undefined)
            {
                setPlainAndroidNotificationStatus(res.data.androiddata.is_notification_enabled);
                setRichAndroidNotificationStatus(res.data.androiddata.is_rich_notification_enabled);
                // localStorage.setItem("android_plain_notification_enable", res.data.androiddata.is_notification_enabled);
                setAndroidServerKey(res.data.androiddata.notification_key);
                setPlainAndroidConfigureFilename(res.data.androiddata.notification_config_file);

                setAndroidSHA1Key(res.data.androiddata.sha1);
				setSHA1_InProcess(res.data.androiddata.in_process);

                setAppPublish(res.data.androiddata.is_app_already_published);
            }

            setAndroidBundleID(res.data.android_bundle_id);

            if(res.data.iosdata !== null && res.data.iosdata !== '' && res.data.iosdata !== undefined)
            {
                setPlainiOSNotificationStatus(res.data.iosdata.is_notification_enabled);
                setRichiOSNotificationStatus(res.data.iosdata.is_rich_notification_enabled);
                localStorage.setItem("ios_plain_notification_enable", res.data.iosdata.is_notification_enabled);
                setPlainiOSConfigureFileName(res.data.iosdata.notification_config_file);
                setiOSServerKey(res.data.iosdata.notification_key);
                
            }

            setIosBundleID(res.data.ios_bundle_id);
            setPlanname(res.data.plandata.plan);
        
        }         
        else
        {
            setSpinner(false);
            // errortoast("Something went wrong. Try again after sometime.");
        
        }
    })
    .catch((error) => 
    {
        
        // errortoast("Something went wrong. Try again after sometime.");
    });
}

export const GetRichNotificationSettingDetails = (setPlainAndroidNotificationStatus, setAndroidServerKey, setPlainAndroidConfigureFilename, setPlainiOSNotificationStatus, setPlainiOSConfigureFileName, setiOSServerKey, setAndroidBundleID, setIosBundleID, setAndroidSHA1Key, setSHA1_InProcess, setAppPublish, setPlanname,setSpinner,setUpdatedAt,setRichAndroidNotificationStatus,setRichiOSNotificationStatus,setiOSConfigureFileName,setIosPlistConfigureFileName,setIosConfigurePassword) =>
{

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
  
    axios.get(Url + "os-get-configuration/" + btoa(localStorage.getItem("Appid")),{
        headers: headers
    }).then(async (res) => {
        if (res.data.code === 200) 
        {
            setUpdatedAt(res.data.updated_at);
            setSpinner(false);
            
            
            if(res.data.androiddata !== null && res.data.androiddata !== '' && res.data.androiddata !== undefined)
            {
                setPlainAndroidNotificationStatus(res.data.androiddata.is_notification_enabled);
                setRichAndroidNotificationStatus(res.data.androiddata.is_rich_notification_enabled);
                // localStorage.setItem("android_plain_notification_enable", res.data.androiddata.is_notification_enabled);
                setAndroidServerKey(res.data.androiddata.notification_key);
                setPlainAndroidConfigureFilename(res.data.androiddata.notification_config_file);

                setAndroidSHA1Key(res.data.androiddata.sha1);
				setSHA1_InProcess(res.data.androiddata.in_process);

                setAppPublish(res.data.androiddata.is_app_already_published);
            }

            setAndroidBundleID(res.data.android_bundle_id);

            if(res.data.iosdata !== null && res.data.iosdata !== '' && res.data.iosdata !== undefined)
            {
                setPlainiOSNotificationStatus(res.data.iosdata.is_notification_enabled);
                setRichiOSNotificationStatus(res.data.iosdata.is_rich_notification_enabled);
                localStorage.setItem("ios_plain_notification_enable", res.data.iosdata.is_notification_enabled);
                setPlainiOSConfigureFileName(res.data.iosdata.notification_config_file);
                setiOSConfigureFileName(res.data.iosdata.apns_p12_certificate);
                setIosPlistConfigureFileName(res.data.iosdata.apns_provisioning_profile);
                setIosConfigurePassword(res.data.iosdata.apns_p12_certificate_password);
                setiOSServerKey(res.data.iosdata.notification_key);
                
            }

            setIosBundleID(res.data.ios_bundle_id);
            setPlanname(res.data.plandata.plan);
        
        }         
        else
        {
            setSpinner(false);
            // errortoast("Something went wrong. Try again after sometime.");
        
        }
    })
    .catch((error) => 
    {
        
        // errortoast("Something went wrong. Try again after sometime.");
    });
}

// export const GetSettingDataChecker = (setSHA1_InProcess, setAndroidSHA1Key, setSHA1Loader, setSHADepend, sha1depend) =>{

//   const headers = {
//       'Content-Type': 'application/json',
//       'Authorization': "Bearer " + localStorage.getItem("amstoken"),
//   }

//   axios.get(Url + "os-get-configuration/" + btoa(localStorage.getItem("Appid")),{
//       headers: headers
//   }).then((res) => {
//       	if (res.data.code === 200) 
//       	{

          	
//             if(res.data.androiddata.sha1 !== null && res.data.androiddata.sha1 !== '' && res.data.androiddata.sha1 !== undefined)
//             {
//               setAndroidSHA1Key(res.data.androiddata.sha1);
//               setSHA1_InProcess(res.data.androiddata.in_process);

//               if(res.data.androiddata.in_process === 0)
//               {                
//                 setSHA1Loader(false);
//                 //setSHADepend(0);
//               }

              
                
//             }
//             else
//             {
//                 //callback();
//                 if(sha1depend !== 0)
//                 {
                  
//                     setTimeout(()=>{
//                       setSHA1Loader(true);
//                       setSHADepend(sha1depend + 1);
//                     }, 3000);
//                 }
                

//             }



			    
      
//       }
// 		else if(res.data.code == 400)
// 		{
// 			
// 		}         
//       	else
//       	{
//           	errortoast("Something went wrong. Try again after sometime.");
      
//       	}
//     })
//     .catch((error) => 
//     {
//         // errortoast("Something went wrong. Try again after sometime.");
//     });
// }


export const GetSettingDataChecker = (setSHA1_InProcess, setAndroidSHA1Key, setSHA1Loader, setSHADepend, sha1depend) =>{

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
  
    axios.get(Url + "get-plain-notification-settings/" + btoa(localStorage.getItem("Appid")),{
        headers: headers
    }).then((res) => {
            if (res.data.code === 200) 
            {
              
              if(res.data.androiddata!==undefined && res.data.androiddata!==null && res.data.androiddata!=="" && res.data.androiddata.sha1 !== undefined && res.data.androiddata.sha1 !== null && res.data.androiddata.sha1 !== '')
              {
                setAndroidSHA1Key(res.data.androiddata.sha1);
                setSHA1_InProcess(res.data.androiddata.in_process);
  
                if(res.data.androiddata.in_process === 0)
                {                
                  setSHA1Loader(false);
                  //setSHADepend(0);
                }
  
                
                  
              }
              else
              {
                  
                  //callback();
                  if(sha1depend !== 0)
                  {
                    
                      setTimeout(()=>{
                        
                        setSHA1Loader(true);
                        setSHADepend(sha1depend + 1);
                      }, 15000);
                  }
                  
  
              }
  
  
  
                  
        
        }
          else if(res.data.code == 400)
          {
              
          }         
            else
            {
                errortoast("Something went wrong. Try again after sometime.");
        
            }
      })
      .catch((error) => 
      {
        
          // errortoast("Something went wrong. Try again after sometime.");
      });
  }


//export const UploadPlainPlistCertificateToS3 = (params, setiOSPlistOriginalJsonFile, setiOSPlistCertificateS3Name, setBtnSubmitStatus, setIosPlistFileUpdStatus) => {
export const UploadPlainPlistCertificateToS3 = (params) => {

    

    if (params.original !== "") 
    {
        function dataURLtoFileoriginal(dataurl, filename) 
        {

            var arr = dataurl.split(","),
            //mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

            while (n--) 
            {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new Blob([u8arr], { type: "application/plist" });
        }

        let originalfile = dataURLtoFileoriginal(params.original, "hello.plist");

        

        const oriconfig = {
            bucketName: process.env.REACT_APP_bucketName,
            dirName: params.original_path.replace(/\/$/, "") /* optional */,
            region: process.env.REACT_APP_region,
            accessKeyId: process.env.REACT_APP_accessKeyId,
            secretAccessKey: process.env.REACT_APP_secretAccessKey,
            s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
        };

        const ReactS3original = new CustomS3Client(oriconfig);
        const originalFileName = params.imgname;

        

        ReactS3original.uploadFile(originalfile, originalFileName)
            .then((data) => {
			if (data.status === 204) 
			{
				
				/*setiOSPlistOriginalJsonFile(data.location);
				
				setiOSPlistCertificateS3Name(data.key.split("/").pop());
				setBtnSubmitStatus(true);
				setIosPlistFileUpdStatus(true);*/

			}
		})
		.catch((err) => {
			errortoast("Something went wrong. Try again after sometime.");
		});
    } 
    else 
    {
        //callback();
    }
};

// Location Pop up APIs
export const BottoMenuTypeApi = (storeUrl ,setTypesData, callback) => {
    const headers = {
        "Content-Type": "application/json",
        'X-App-Device-Type':'ios',
        'X-App-Code-Ver':localStorage.getItem("Apiversion"),
      };
    axios
      .get(
        RedirectUrl +
          storeUrl.replace("www.", "") +
          "/wp-json/wp/v2/types?page=1%26per_page=99",
        {
          headers: headers,
          data:{},
        }
      )
      .then((res) => {
        if(setTypesData){
         setTypesData(res.data);
        }
        if (callback) {
          callback(res.data);
        }
      })
      .catch((err) => {
        
      });
  };
  
  export const BottoMenuTaxonomyApi = (storeUrl ,setTaxonomyData, callback) => {
    const headers = {
        "Content-Type": "application/json",
        'X-App-Device-Type':'ios',
        'X-App-Code-Ver':localStorage.getItem("Apiversion"),
      };
    axios
      .get(
        RedirectUrl +
          storeUrl.replace("www.", "") +
          "/wp-json/wp/v2/taxonomies?page=1%26per_page=99",
        {
          headers: headers,
          data:{},
        }
      )
      .then((res) => 
      {
        if(setTaxonomyData){
            setTaxonomyData(res.data);
        }
  
        if (callback) {
          callback(res.data);
        }
      })
      .catch((err) => {
        
      });
  };

  // Pages for Bottom Menu
 export const cmsGetPagesForBtm = (
    rest_base,
    isAllowPagination , 
    setIsAllowPagination , 
    setPageNo , 
    pageNo,
    setPaginationSpinner,
    storeUrl,
    subdropdownData,
    setSubdropdownData,
    subData,
    setSubData,
    setTempSubdata,
    tempSubData,
    setTempBreadcrumbArr,
    tempBreadcrumbArr,
    setBreadcrumbArr,
    breadcrumbArr,
    setSubdropdownSpinner,
    subdropdownSpinner,
    setTempShowL1Options,
    tempShowL1Options,
    setShowL1Options,
    showL1Options,
    setModuleLabel,
    moduleLabel,
    setTempModuleLabel,
    tempModuleLabel,
    setTempItemType,
    tempItemType,
    setItemType,
    itemType,
    setSpinnerForsubDrop,
    page,
    itemId,
    tempRestBase,
    tempSlug,
    tempPostType,
    mergeArray,
    limit,

 ) => {
    let newArrForPreview = [];
    let newBreadcrumb=[];
    let newSubData=[];
    let parentId="";
    let allPages =[];

    setPageNo(page?page:1);
    setIsAllowPagination(page?isAllowPagination:true);
    setPaginationSpinner(page?true:false);

    function recursForPreview(arr) {
      arr = arr.map((eachPage, indx1) => {
        let obj = { value: "", label: "", id: "" };
        obj.id = eachPage._id;
        obj.value = eachPage._id;
        obj.label = eachPage.title;
        newArrForPreview.push(eachPage);
     
        if (eachPage.child !== undefined && eachPage.child.length > 0) {
          // if parent has childrens
          recursForPreview(eachPage.child);
        }
  
        return arr;
      });
    }
  
    function recursForBreadcrumb(arr) {
  
      arr.map((eachPage) => {
        let obj = {
          name:"",
          id:"",
        }
      
        if(eachPage._id === parentId){
          parentId = eachPage.parent;
          obj.id = eachPage._id;
          obj.name = eachPage.title;
          newBreadcrumb.push(obj);
  
        }
  
       if (eachPage.child !== undefined && eachPage.child.length > 0) {
         // if parent has childrens
         recursForBreadcrumb(eachPage.child);
       }
  
       // return arr;
     });
   }
  
   function recursData(arr) {
    arr.map((eachPage) => {
    
     if(newBreadcrumb.length > 0 && eachPage.parent===newBreadcrumb[newBreadcrumb.length-1].id){
   
      newSubData.push(eachPage);
     }
  
     if (eachPage.child !== undefined && eachPage.child.length > 0) {
       // if parent has childrens
       recursData(eachPage.child);
     }
  
     // return arr;
   });
  }
  
  function recurs(arr) {
    arr && arr.map((eachPage)=>{
  
      recursForBreadcrumb(allPages);
     if (eachPage.child !== undefined && eachPage.child.length > 0) {
       recurs(eachPage.child);
     }
    })
  }
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("amstoken"),
    };
  
    axios
      .get(cmsUrl + `getPages/${btoa(localStorage.getItem("Appid"))}?page=${page?page:1}&limit=${limit?limit:99}`, {
        headers: headers,
      })
      .then((res) => {
        if (res.data.code === 200) {

        setPaginationSpinner(false);
        setIsAllowPagination(( res.data.data && res.data.data.length > 0 && res.data.data.length >= limit )  ? true : false);

        allPages=res.data.data
        recursForPreview(allPages);
        parentId=itemId;

        if(!page || page <= 1){

            recurs(allPages);
    
        }

          newBreadcrumb.splice(0,1)
          newBreadcrumb.reverse();
          recursData(allPages);


          if(!newBreadcrumb || newBreadcrumb.length===0){

            setSubdropdownData((page && page > 1 )?subdropdownData.concat(allPages) :allPages);
            
            setSubData((page && page > 1 )?
            (newSubData.length===0 ? subData.concat(allPages):subData.concat(newSubData)) :
            (newSubData.length===0 ? allPages: newSubData ));
            
            setTempSubdata((page && page > 1 )?
            (newSubData.length===0 ? tempSubData.concat(allPages):tempSubData.concat(newSubData)) :
            (newSubData.length===0 ? allPages: newSubData ));


          }else{
            
            setSubdropdownData(allPages);
            setSubData(newSubData.length===0?allPages:newSubData);
            setTempSubdata(newSubData.length===0?allPages:newSubData);

          }



          setTempBreadcrumbArr(newBreadcrumb);
          setBreadcrumbArr(newBreadcrumb);
          setSubdropdownSpinner(false);
          setTempShowL1Options(false);
          setTempModuleLabel(returnSelectedModuleName(tempItemType,mergeArray,tempSlug,tempRestBase,tempPostType));
          setModuleLabel(returnSelectedModuleName(itemType,mergeArray,tempSlug,tempRestBase,tempPostType));
          setSpinnerForsubDrop(false);
        } else {
  
            setSubdropdownData([]);
            setSubData([]);
            setTempSubdata([]);
            setTempBreadcrumbArr([]);
            setBreadcrumbArr([]);
            setSubdropdownSpinner(false);
            setShowL1Options(true);
            setTempShowL1Options(true);
            setTempModuleLabel(returnSelectedModuleName(tempItemType,mergeArray,tempSlug,tempRestBase,tempPostType));
            setModuleLabel(returnSelectedModuleName(itemType,mergeArray,tempSlug,tempRestBase,tempPostType));
            setSpinnerForsubDrop(false);
        }
      })
      .catch((err) => {
        setSubdropdownData([]);
        setSubData([]);
        setTempSubdata([]);
        setTempBreadcrumbArr([]);
        setBreadcrumbArr([]);
        setSubdropdownSpinner(false);
        setShowL1Options(true);
        setTempShowL1Options(true);
        setTempModuleLabel(returnSelectedModuleName(tempItemType,mergeArray,tempSlug,tempRestBase,tempPostType));
        setModuleLabel(returnSelectedModuleName(itemType,mergeArray,tempSlug,tempRestBase,tempPostType));
        setSpinnerForsubDrop(false);
        
      });
  };

    //Get all categories for Bottom Menu
    export const cmsgetCategoryForBtm = (
        rest_base,
        isAllowPagination , 
        setIsAllowPagination , 
        setPageNo , 
        pageNo,
        setPaginationSpinner,
        storeUrl,
        subdropdownData,
        setSubdropdownData,
        subData,
        setSubData,
        setTempSubdata,
        tempSubData,
        setTempBreadcrumbArr,
        tempBreadcrumbArr,
        setBreadcrumbArr,
        breadcrumbArr,
        setSubdropdownSpinner,
        subdropdownSpinner,
        setTempShowL1Options,
        tempShowL1Options,
        setShowL1Options,
        showL1Options,
        setModuleLabel,
        moduleLabel,
        setTempModuleLabel,
        tempModuleLabel,
        setTempItemType,
        tempItemType,
        setItemType,
        itemType,
        setSpinnerForsubDrop,
        page,
        itemId,
        tempRestBase,
        tempSlug,
        tempPostType,
        mergeArray,
        limit,
      ) => {
        let newArrForPreview = [];
        let newBreadcrumb=[];
        let newSubData=[];
        let parentId="";
        let allPages =[];
    
        setPageNo(page?page:1);
        setIsAllowPagination(page?isAllowPagination:true);
        setPaginationSpinner(page?true:false);
      
      
      
        function recursForBreadcrumb(arr) {
      
          arr.map((eachPage) => {
            let obj = {
              name:"",
              id:"",
            }
          
            if(eachPage._id == parentId){
              parentId = eachPage.parent;
              obj.id = eachPage._id.toString();
              obj.name = eachPage.name;
              newBreadcrumb.push(obj);
      
            }
      
           if (eachPage.child !== undefined && eachPage.child.length > 0) {
             // if parent has childrenrens
             recursForBreadcrumb(eachPage.child);
           }
      
           // return arr;
         });
       }
      
       function recursData(arr) {
        arr.map((eachPage) => {
        
         if(newBreadcrumb.length > 0 && eachPage.parent == newBreadcrumb[newBreadcrumb.length-1].id){
       
          newSubData.push(eachPage);
         }
      
         if (eachPage.child !== undefined && eachPage.child.length > 0) {
           // if parent has childrenrens
           recursData(eachPage.child);
         }
      
         // return arr;
       });
      }
      
      function recurs(arr) {
        arr && arr.map((eachPage)=>{
      
          recursForBreadcrumb(allPages);
         if (eachPage.child !== undefined && eachPage.child.length > 0) {
           recurs(eachPage.child);
         }
        })
      }
        const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("amstoken"),
        };
      
        axios
          .get(cmsUrl + `getCategories/${btoa(localStorage.getItem("Appid"))}?page=${page?page:1}&limit=${limit?limit:99}`, {
            headers: headers,
          })
          .then((res) => {
            if (res.status === 200) {

            
        setPaginationSpinner(false);
        setIsAllowPagination(( res.data.data && res.data.data.length > 0 && res.data.data.length >= limit )  ? true : false);   
                
            allPages=res.data.data;
          
            parentId=itemId;
            
        if(!page || page <= 1){

            recurs(allPages);
    
        }

           
          
            newBreadcrumb.splice(0,1)
            newBreadcrumb.reverse();
            recursData(allPages);
    
    
            if(!newBreadcrumb || newBreadcrumb.length===0){

                setSubdropdownData((page && page > 1 )?subdropdownData.concat(allPages) :allPages);
                
                setSubData((page && page > 1 )?
                (newSubData.length===0 ? subData.concat(allPages):subData.concat(newSubData)) :
                (newSubData.length===0 ? allPages: newSubData ));
                
                setTempSubdata((page && page > 1 )?
                (newSubData.length===0 ? tempSubData.concat(allPages):tempSubData.concat(newSubData)) :
                (newSubData.length===0 ? allPages: newSubData ));
    
    
              }else{
                
                setSubdropdownData(allPages);
                setSubData(newSubData.length===0?allPages:newSubData);
                setTempSubdata(newSubData.length===0?allPages:newSubData);
    
              }
           
            setTempBreadcrumbArr(newBreadcrumb);
            setBreadcrumbArr(newBreadcrumb);
            setSubdropdownSpinner(false);
            setTempShowL1Options(false);
            setTempModuleLabel(returnSelectedModuleName(tempItemType,mergeArray,tempSlug,tempRestBase,tempPostType));
            setModuleLabel(returnSelectedModuleName(itemType,mergeArray,tempSlug,tempRestBase,tempPostType));
            setSpinnerForsubDrop(false);
            } else {
    
                setSubdropdownData([]);
                setSubData([]);
                setTempSubdata([]);
                setTempBreadcrumbArr([]);
                setBreadcrumbArr([]);
                setSubdropdownSpinner(false);
                setShowL1Options(true);
                setTempShowL1Options(true);
                setTempModuleLabel(returnSelectedModuleName(tempItemType,mergeArray,tempSlug,tempRestBase,tempPostType));
                setModuleLabel(returnSelectedModuleName(itemType,mergeArray,tempSlug,tempRestBase,tempPostType));
                setSpinnerForsubDrop(false);
    
            }
          })
          .catch((err) => {
            setSubdropdownData([]);
            setSubData([]);
            setTempSubdata([]);
            setTempBreadcrumbArr([]);
            setBreadcrumbArr([]);
            setSubdropdownSpinner(false);
            setShowL1Options(true);
            setTempShowL1Options(true);
            setTempModuleLabel(returnSelectedModuleName(tempItemType,mergeArray,tempSlug,tempRestBase,tempPostType));
            setModuleLabel(returnSelectedModuleName(itemType,mergeArray,tempSlug,tempRestBase,tempPostType));
            setSpinnerForsubDrop(false);
            
          });
      };
  
  //Get all Tags for Bottom Menu
  export const cmsgetTagsForBtm = (
    rest_base,
    isAllowPagination , 
    setIsAllowPagination , 
    setPageNo , 
    pageNo,
    setPaginationSpinner,
    storeUrl,
    subdropdownData,
    setSubdropdownData,
    subData,
    setSubData,
    setTempSubdata,
    tempSubData,
    setTempBreadcrumbArr,
    tempBreadcrumbArr,
    setBreadcrumbArr,
    breadcrumbArr,
    setSubdropdownSpinner,
    subdropdownSpinner,
    setTempShowL1Options,
    tempShowL1Options,
    setShowL1Options,
    showL1Options,
    setModuleLabel,
    moduleLabel,
    setTempModuleLabel,
    tempModuleLabel,
    setTempItemType,
    tempItemType,
    setItemType,
    itemType,
    setSpinnerForsubDrop,
    page,
    itemId,
    tempRestBase,
    tempSlug,
    tempPostType,
    mergeArray,
    limit,
  ) => {

    let newData = [];

    setPageNo(page?page:1);
    setIsAllowPagination(page?isAllowPagination:true);
    setPaginationSpinner(page?true:false);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("amstoken"),
    };
  
    axios
      .get(cmsUrl + `getTags/${btoa(localStorage.getItem("Appid"))}?page=${page?page:1}&limit=${limit?limit:99}`, {
        headers: headers,
      })
      .then((res) => {
        if (res.data.code === 200) {

            setPaginationSpinner(false);
            setIsAllowPagination(( res.data.data && res.data.data.length > 0 && res.data.data.length >= limit )  ? true : false);   

          newData = res.data.data;

          setSubdropdownData((page && page > 1 ) ? subdropdownData.concat(newData) :newData);
          setSubData((page && page > 1 )?subData.concat(newData) :newData);
          setTempSubdata((page && page > 1 )?tempSubData.concat(newData) :newData);


          setTempBreadcrumbArr([]);
          setBreadcrumbArr([]);
          setSubdropdownSpinner(false);
          setTempShowL1Options(false);
          setTempModuleLabel(returnSelectedModuleName(tempItemType,mergeArray,tempSlug,tempRestBase,tempPostType));
          setModuleLabel(returnSelectedModuleName(itemType,mergeArray,tempSlug,tempRestBase,tempPostType));
          setSpinnerForsubDrop(false);
        } else {

            setSubdropdownData(newData);
            setSubData(newData);
            setTempSubdata(newData);
            setTempBreadcrumbArr([]);
            setBreadcrumbArr([]);
            setSubdropdownSpinner(false);
            setTempShowL1Options(true);
            setShowL1Options(true);
            setTempModuleLabel(returnSelectedModuleName(tempItemType,mergeArray,tempSlug,tempRestBase,tempPostType));
            setModuleLabel(returnSelectedModuleName(itemType,mergeArray,tempSlug,tempRestBase,tempPostType));
            setSpinnerForsubDrop(false);
        }
      })
      .catch((err) => {
      
        setSubdropdownData(newData);
        setSubData(newData);
        setTempSubdata(newData);
        setTempBreadcrumbArr([]);
        setBreadcrumbArr([]);
        setSubdropdownSpinner(false);
        setTempShowL1Options(true);
        setShowL1Options(true);
        setTempModuleLabel(returnSelectedModuleName(tempItemType,mergeArray,tempSlug,tempRestBase,tempPostType));
        setModuleLabel(returnSelectedModuleName(itemType,mergeArray,tempSlug,tempRestBase,tempPostType));
        setSpinnerForsubDrop(false);
      });
  };
  
  //CMS Get All Post API for Bottom Menu
  export const cmsGetPostsForBtm = (
    rest_base,
    isAllowPagination , 
    setIsAllowPagination , 
    setPageNo , 
    pageNo,
    setPaginationSpinner,
    storeUrl,
    subdropdownData,
    setSubdropdownData,
    subData,
    setSubData,
    setTempSubdata,
    tempSubData,
    setTempBreadcrumbArr,
    tempBreadcrumbArr,
    setBreadcrumbArr,
    breadcrumbArr,
    setSubdropdownSpinner,
    subdropdownSpinner,
    setTempShowL1Options,
    tempShowL1Options,
    setShowL1Options,
    showL1Options,
    setModuleLabel,
    moduleLabel,
    setTempModuleLabel,
    tempModuleLabel,
    setTempItemType,
    tempItemType,
    setItemType,
    itemType,
    setSpinnerForsubDrop,
    page,
    itemId,
    tempRestBase,
    tempSlug,
    tempPostType,
    mergeArray,
    limit,
  ) => {
    let newData = [];

    setPageNo(page?page:1);
    setIsAllowPagination(page?isAllowPagination:true);
    setPaginationSpinner(page?true:false);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("amstoken"),
    };
  
    axios
      .get(
        cmsUrl + `getPosts/${btoa(localStorage.getItem("Appid"))}?page=${page?page:1}&limit=${limit?limit:99}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.data.code === 200) {

            setPaginationSpinner(false);
            setIsAllowPagination(( res.data.data && res.data.data.length > 0 && res.data.data.length >= limit )  ? true : false);   

          newData = res.data.data;

          setSubdropdownData((page && page > 1 ) ? subdropdownData.concat(newData) :newData);
          setSubData((page && page > 1 )?subData.concat(newData) :newData);
          setTempSubdata((page && page > 1 )?tempSubData.concat(newData) :newData);


          setTempBreadcrumbArr([]);
          setBreadcrumbArr([]);
          setSubdropdownSpinner(false);
          setTempShowL1Options(false);
          setTempModuleLabel(returnSelectedModuleName(tempItemType,mergeArray,tempSlug,tempRestBase,tempPostType));
          setModuleLabel(returnSelectedModuleName(itemType,mergeArray,tempSlug,tempRestBase,tempPostType));
          setSpinnerForsubDrop(false);

        } else {

            setSubdropdownData(newData);
            setSubData(newData);
            setTempSubdata(newData);
            setTempBreadcrumbArr([]);
            setBreadcrumbArr([]);
            setSubdropdownSpinner(false);
            setTempShowL1Options(true);
            setShowL1Options(true);
            setTempModuleLabel(returnSelectedModuleName(tempItemType,mergeArray,tempSlug,tempRestBase,tempPostType));
            setModuleLabel(returnSelectedModuleName(itemType,mergeArray,tempSlug,tempRestBase,tempPostType));
            setSpinnerForsubDrop(false);
  
        }
      })
      .catch((err) => {
        setSubdropdownData(newData);
        setSubData(newData);
        setTempSubdata(newData);
        setTempBreadcrumbArr([]);
        setBreadcrumbArr([]);
        setSubdropdownSpinner(false);
        setTempShowL1Options(true);
        setShowL1Options(true);
        setTempModuleLabel(returnSelectedModuleName(tempItemType,mergeArray,tempSlug,tempRestBase,tempPostType));
        setModuleLabel(returnSelectedModuleName(itemType,mergeArray,tempSlug,tempRestBase,tempPostType));
        setSpinnerForsubDrop(false);
      });
  };

  export const restBaseTaxonomyapi = (
    rest_base,
    isAllowPagination , 
    setIsAllowPagination , 
    setPageNo , 
    pageNo,
    setPaginationSpinner,
    storeUrl,
    subdropdownData,
    setSubdropdownData,
    subData,
    setSubData,
    setTempSubdata,
    tempSubData,
    setTempBreadcrumbArr,
    tempBreadcrumbArr,
    setBreadcrumbArr,
    breadcrumbArr,
    setSubdropdownSpinner,
    subdropdownSpinner,
    setTempShowL1Options,
    tempShowL1Options,
    setShowL1Options,
    showL1Options,
    setModuleLabel,
    moduleLabel,
    setTempModuleLabel,
    tempModuleLabel,
    setTempItemType,
    tempItemType,
    setItemType,
    itemType,
    setSpinnerForsubDrop,
    page,
    itemId,
    tempRestBase,
    tempSlug,
    tempPostType,
    mergeArray,
    limit
    ) => {
    
    setPageNo(page?page:1);
    setIsAllowPagination(page?isAllowPagination:true);
    setPaginationSpinner(page?true:false);
    let newData = [];
    const headers = {
        "Content-Type": "application/json",
        'X-App-Device-Type':'ios',
        'X-App-Code-Ver':localStorage.getItem("Apiversion"),
      };
    axios
      .get(
        `${RedirectUrl}${storeUrl.replace("www.", "")}/wp-json/wp/v2/${rest_base}?page=${page?page:1}%26per_page=99`,
  
        {
          headers: headers,
          data:{},
        }
      )    .then((res) => {
        if (res.status === 200) {
            setPaginationSpinner(false);
            setIsAllowPagination(( res.data && res.data.length > 0 && res.data.length > 98 )  ? true : false);
          newData = res.data;    
          setSubdropdownData((page && page > 1 )?subdropdownData.concat(newData):newData);
          setSubData((page && page > 1 )?subData.concat(newData) :newData);
          setTempSubdata((page && page > 1 )?tempSubData.concat(newData) :newData);
          setTempBreadcrumbArr([]);
          setBreadcrumbArr([]);
          setSubdropdownSpinner(false);
          setTempShowL1Options(false);
          setTempModuleLabel(returnSelectedModuleName(tempItemType,mergeArray,tempSlug,tempRestBase,tempPostType));     
          setModuleLabel(returnSelectedModuleName(itemType,mergeArray,tempSlug,tempRestBase,tempPostType));    
          setSpinnerForsubDrop(false);
        } else {
  
            setSubdropdownData(newData);
            setSubData(newData);
            setTempSubdata(newData);
            setTempBreadcrumbArr([]);
            setBreadcrumbArr([]);
            setSubdropdownSpinner(false);
            setShowL1Options(true);
            setTempShowL1Options(true);
            setTempModuleLabel(returnSelectedModuleName(tempItemType,mergeArray,tempSlug,tempRestBase,tempPostType));     
            setModuleLabel(returnSelectedModuleName(itemType,mergeArray,tempSlug,tempRestBase,tempPostType));    
            setSpinnerForsubDrop(false);
            setPaginationSpinner(false);
            setIsAllowPagination(false);  
        }
      })
      .catch((err) => {
        setSubdropdownData(newData);
        setSubData(newData);
        setTempSubdata(newData);
        setTempBreadcrumbArr([]);
        setBreadcrumbArr([]);
        setSubdropdownSpinner(false);
        setShowL1Options(true);
        setTempShowL1Options(true);
        setTempModuleLabel(returnSelectedModuleName(tempItemType,mergeArray,tempSlug,tempRestBase,tempPostType));     
        setModuleLabel(returnSelectedModuleName(itemType,mergeArray,tempSlug,tempRestBase,tempPostType));    
        setSpinnerForsubDrop(false);
        setPaginationSpinner(false);
        setIsAllowPagination(false);  
      });

  };
  
  
  export const productCategoriesTreeAPIForBtm = (
    rest_base,
    isAllowPagination , 
    setIsAllowPagination , 
    setPageNo , 
    pageNo,
    setPaginationSpinner,
    storeUrl,
    subdropdownData,
    setSubdropdownData,
    subData,
    setSubData,
    setTempSubdata,
    tempSubData,
    setTempBreadcrumbArr,
    tempBreadcrumbArr,
    setBreadcrumbArr,
    breadcrumbArr,
    setSubdropdownSpinner,
    subdropdownSpinner,
    setTempShowL1Options,
    tempShowL1Options,
    setShowL1Options,
    showL1Options,
    setModuleLabel,
    moduleLabel,
    setTempModuleLabel,
    tempModuleLabel,
    setTempItemType,
    tempItemType,
    setItemType,
    itemType,
    setSpinnerForsubDrop,
    page,
    itemId,
    tempRestBase,
    tempSlug,
    tempPostType,
    mergeArray,
    ) => {
    let newArrForPreview = [];
    let newBreadcrumb=[];
    let newSubData=[];
    let parentId="";
    let allPages =[];

  
    function recursForBreadcrumb(arr) {
  
      arr.map((eachPage) => {
        let obj = {
          name:"",
          id:"",
        }
      
        if(eachPage.term_id == parentId){
          parentId = eachPage.parent;
          obj.id = eachPage.term_id.toString();
          obj.name = eachPage.name;
          newBreadcrumb.push(obj);
  
        }
  
       if (eachPage.children !== undefined && eachPage.children.length > 0) {
         // if parent has childrenrens
         recursForBreadcrumb(eachPage.children);
       }
  
       // return arr;
     });
   }
  
   function recursData(arr) {
    arr.map((eachPage) => {
    
     if(newBreadcrumb.length > 0 && eachPage.parent == newBreadcrumb[newBreadcrumb.length-1].id){
   
      newSubData.push(eachPage);
     }
  
     if (eachPage.children !== undefined && eachPage.children.length > 0) {
       // if parent has childrenrens
       recursData(eachPage.children);
     }
  
     // return arr;
   });
  }
  
  function recurs(arr) {
    arr && arr.map((eachPage)=>{
  
      recursForBreadcrumb(allPages);
     if (eachPage.children !== undefined && eachPage.children.length > 0) {
       recurs(eachPage.children);
     }
    })
  }
    const headers = {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + localStorage.getItem("amstoken"),
    };
    axios
      .get(`${storeUrl}/wp-json/wc/v3/ams-categories`, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 200) {
        allPages=res.data;
    
        parentId=itemId;
        recurs(allPages);
       
      
          newBreadcrumb.splice(0,1)
          newBreadcrumb.reverse();
          recursData(allPages);


          setSubdropdownData(allPages);
          setSubData(newSubData.length===0?allPages:newSubData);
          setTempSubdata(newSubData.length===0?allPages:newSubData);
          setTempBreadcrumbArr(newBreadcrumb);
          setBreadcrumbArr(newBreadcrumb);
          setSubdropdownSpinner(false);
          setTempShowL1Options(false);
          setTempModuleLabel(returnSelectedModuleName(tempItemType,mergeArray,tempSlug,tempRestBase,tempPostType));
          setModuleLabel(returnSelectedModuleName(itemType,mergeArray,tempSlug,tempRestBase,tempPostType));
          setSpinnerForsubDrop(false);

        } else {
  
            setSubdropdownData([]);
            setSubData([]);
            setTempSubdata([]);
            setTempBreadcrumbArr([]);
            setBreadcrumbArr([]);
            setSubdropdownSpinner(false);
            setShowL1Options(true);
            setTempShowL1Options(true);
            setTempModuleLabel(returnSelectedModuleName(tempItemType,mergeArray,tempSlug,tempRestBase,tempPostType));
            setModuleLabel(returnSelectedModuleName(itemType,mergeArray,tempSlug,tempRestBase,tempPostType));
            setSpinnerForsubDrop(false);
        }
      })
      .catch((err) => {
  
        setSubdropdownData([]);
        setSubData([]);
        setTempSubdata([]);
        setTempBreadcrumbArr([]);
        setBreadcrumbArr([]);
        setSubdropdownSpinner(false);
        setShowL1Options(true);
        setTempShowL1Options(true);
        setTempModuleLabel(returnSelectedModuleName(tempItemType,mergeArray,tempSlug,tempRestBase,tempPostType));
        setModuleLabel(returnSelectedModuleName(itemType,mergeArray,tempSlug,tempRestBase,tempPostType));
        setSpinnerForsubDrop(false);
      });
  };