import React from "react";
import { Link } from "react-router-dom";
import { Verifyemailaccount } from "../../Api/Authenticate/Api";
import {
  GetverifyuserprofileApi
} from "../../Api/Dashboard/DashboardApi";
import "./notvaliduser.css";
import Header from "./Header";
export default class Notvalidateuser extends React.Component {
  state = {
    code: 200,
	resendtxt: "Resend",
	loader: "d-none",
  status: 0,
  };
  componentDidMount() 
  {
    GetverifyuserprofileApi(this);
    if(localStorage.getItem("agency_id") && localStorage.getItem("agency_id") == 1)
      {
        document.title = "Verify email | AppMySite";
      }
      else
      {
        document.title = "Verify email |"+ ' ' +localStorage.getItem('agencyname');        
      }
    window.scrollTo(0, 0);
  }
  handlesend = () => {
		const params = {
		  token: localStorage.getItem("amstoken"),
		  check: "check",
		};
		
		this.setState({
			resendtxt: "",
			loader: "d-block"
		});

		Verifyemailaccount(params, this);
  };
  // handleskip = () => {
  //   this.props.handleprops.handleuser_status();
  // };

  render() {
    return (
      <>
       <Header getstartedhide={"d-block"} customclass={"ipad-getstarted-new"} />
        <div className=" myapps-notvaliduser">
          <div>
            <div className="">
              <section
                
                className=""
              >
                <div className="d-flex justify-content-center align-items-center ">
                  <div className="text-center" style={{ backgroundColor: "#fff" }}>
                    <img
                      className=" "
                      src={
                        require("../../assets/images/dashboard/new-email-unverified.png")
                          .default
                      }
                      alt="notverify"
                    />
                    <div className=" text-center justify-content-center align-items-center notvalidusertxt">
                     
                        <h1>Verify email address </h1>
                        <p >
                        In order to access all features, please verify your email address right away. Look for a verification link in your inbox.
                        </p>
                  
                    </div>
                    <div className="d-flex justify-content-center align-items-center new-notvalid-buttons">
                      
						{/* {this.props.handleprops.state.state.status_code !==
                        204 && (
                        <Link
                          to="#"
                          onClick={(e) => this.handleskip()}
                          className=" new-notvalid-user-skip "
                        >
                          Skip
                        </Link>
						)} */}
						
						<button className="new-notvalid-user-btn" style={{ width: "auto",
            backgroundColor:localStorage.getItem('button_background_color'),
            color:localStorage.getItem('button_text_color'),
            "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
            "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor')
            }}  onClick={(e) => this.handlesend()} >
							<div className={this.state.loader}>
								<div className="new-loader"></div>
							</div>
							{this.state.resendtxt}                        
						</button>
						
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        
      </>
    );
  }
}
