import React from "react";
import Header from "../Header";
import Leftpanel from "../Leftpanel";
import { Media, Spinner } from "reactstrap";
import { Foot, InnerFoot } from "../../Dashboard/Foot";
import Verifyemailstatus from "../../Verification/Checkverification";
import Buildcomponent from "./Buildcomponent";
import { Analyticsapi } from "../../Api/Dashboard/DashboardApi";
import Custombundle from "./Custombundle";
import Amsvideo from "../Reusable/Amsvideos";
import moment from "moment";
import styles from "./Downloadapp.module.css";
export default class Publishapp extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      spinner: true,
      data: [],
      versiondata: [],
      plan_code: "preview",
      modalandroid: false,
      versionhistory: true,
      buildhistory: false,
      ios_bundle_id: "",
      iosstatus: undefined,
      // urlvar: "/subscription/" + btoa(localStorage.getItem("Appid")),
      urlvar: "/app/pricing/",
      hitheader: "0",
      //multisite
      multisite: 0,
      //builder_status
      builder_status: null,
      allowed_module: "preview",
      appRightPanelExpand: localStorage.getItem("appSideBarCollapse") === true ||
			localStorage.getItem("appSideBarCollapse") === "true"
			  ? true
			  : false,
    };
  }
  versionHistory = () => {
    this.setState({
      versionhistory: true,
      buildhistory: false,
    });
  };
  buildHistory = () => {
    this.setState({
      versionhistory: false,
      buildhistory: true,
    });
  };
  versionTable = () => {
    const params = {
      type: "all",
    };
    Analyticsapi(params, this);
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = "Download";
    //this.versionTable()
  }
  handlespinner = () => {
    this.setState({
      spinner: false,
    });
  };
  upgradeurl = () => {
    this.props.history.push(this.state.urlvar);
  };
  handleandroidpopup = () => {
    this.setState({
      modalandroid: !this.state.modalandroid,
    });
  };
  handleloader = () => {
    this.setState({
      spinner: true,
    });
  };
  handlestoploader = () => {
    this.setState({
      spinner: false,
    });
  };
  handlestoreiosdata = (ios_bundle_id, iosstatus) => {
    this.setState({
      ios_bundle_id: ios_bundle_id,
      iosstatus: iosstatus,
    });
  };
  hitheader = () => {
    let hitval = parseInt(this.state.hitheader) + 1;
    this.setState({
      hitheader: hitval.toString(),
    });
  };

  handleRightPanelExpand = (value) => {

    this.setState({
      appRightPanelExpand: value
    })
    }


  render() {
    const Checkstatus = (res) => {
      if (res.app_in_process === 1) {
        return <p className="app-version-blue">Processing</p>;
      }
      if (res.app_in_process === 0) {
        if (
          res.build_status === "1" &&
          res.bitrise_success_step === "success" &&
          res.bitbucket_success_step === "success"
        ) {
          //When App is available to Download
          return <p className="green-active working">Completed</p>;
        }
        if (
          res.build_status === "4" &&
          res.bitrise_success_step === "success" &&
          res.bitbucket_success_step === "success"
        ) {
          //When App is available to Download
          return <p className="green-active working">Completed</p>;
        }
        if (res.build_status === "2") {
          //Rebuild
          return <><p className="app-version-draft notworking">Failed</p>
          <div className="tooltip-container ml-1 d-inline">
          <span className="tooltip1">{res.description}</span>
          <svg width="15" height="15" viewBox="0 0 17 17">
            <g
              id="Group_2842"
              data-name="Group 2842"
              transform="translate(-1057.848 -227.299)"
            >
              <circle
                id="Ellipse_213"
                data-name="Ellipse 213"
                cx="8"
                cy="8"
                r="8"
                transform="translate(1058.348 227.799)"
                fill="none"
                stroke="#7782a1"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
              <path
                id="Path_6038"
                data-name="Path 6038"
                d="M1117.679,269.735a2.423,2.423,0,1,1,3.16,2.309,1.05,1.05,0,0,0-.737,1v.712"
                transform="translate(-53.507 -35.558)"
                fill="none"
                stroke="#7782a1"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
              <line
                id="Line_3"
                data-name="Line 3"
                y2="1.047"
                transform="translate(1066.592 239.465)"
                fill="none"
                stroke="#7782a1"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
            </g>
          </svg>
        </div>
          </>
          ;
        }
        if (res.build_status === "0") {
          //Rebuild
          return <p className="app-version-draft-code notworking">Draft</p>;
        }
        if (res.build_status === "3") {
          //Rebuild
          return <p className="app-version-draft-code notworking">Draft</p>;
        }
      }
    };
    const Tooltip = (e) => {
      return (
        <div className="tooltip-container ml-1 d-inline">
          <span className="tooltip1">{e}</span>
          <svg width="15" height="15" viewBox="0 0 17 17">
            <g
              id="Group_2842"
              data-name="Group 2842"
              transform="translate(-1057.848 -227.299)"
            >
              <circle
                id="Ellipse_213"
                data-name="Ellipse 213"
                cx="8"
                cy="8"
                r="8"
                transform="translate(1058.348 227.799)"
                fill="none"
                stroke="#7782a1"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
              <path
                id="Path_6038"
                data-name="Path 6038"
                d="M1117.679,269.735a2.423,2.423,0,1,1,3.16,2.309,1.05,1.05,0,0,0-.737,1v.712"
                transform="translate(-53.507 -35.558)"
                fill="none"
                stroke="#7782a1"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
              <line
                id="Line_3"
                data-name="Line 3"
                y2="1.047"
                transform="translate(1066.592 239.465)"
                fill="none"
                stroke="#7782a1"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
            </g>
          </svg>
        </div>
      );
    };
    return (
      <>
        <div className="d-md-block">
          <Header
            hitheaderapi={this.state.hitheader}
            customclass={"header-responsive-new"}
          />
        </div>
        {/* Mobile View */}
        {/* Mobile View End*/}
        <section className="dasboard_page mobile-display mobile-view-download">
        <Leftpanel handleRightPanelExpand={this.handleRightPanelExpand} />
        <div className={`right-panel-gap ${(localStorage.getItem("appSideBarCollapse") !== undefined &&
                      localStorage.getItem("appSideBarCollapse") !== null&&
                      localStorage.getItem("appSideBarCollapse") === "true") ?
                          " right-panel-gap-expand": ""}`}>
            {/* Notification */}

            {/*<div className="d-flex  align-items-center annual-plan-offer mb-3" onClick={this.upgradeurl}>
				<h5 >60% off on annual plans <span>up to 31st August 2020</span></h5>
				<svg width="18.051" height="9.438" viewBox="0 0 18.051 9.438">
				<g id="Group_4509" data-name="Group 4509" transform="translate(-1677.29 -233.293)">
					<g id="Group_4508" data-name="Group 4508" transform="translate(1677.29 234)">
					<path id="Path_8344" data-name="Path 8344" d="M-7765-2995h16.637" transform="translate(7765 2999.012)" fill="none" stroke="#fafafa" strokeWidth="2"/>
					<path id="Path_8345" data-name="Path 8345" d="M-7752-2962l4.012,4.012-4.012,4.012" transform="translate(7764.625 2962)" fill="none" stroke="#fafafa" strokeWidth="2"/>
					</g>
				</g>
				</svg>
            </div>*/}

            {/* Notification End*/}
            {/* Email Verification */}
            <div className="d-none d-md-flex w-100">
              <Verifyemailstatus />
            </div>
            {/* Email Verification End*/}
            <div className="right_panel" style={{ minHeight: "80vh" }}>
              {this.state.spinner ? (
               <div className="newspinner">
               <svg xmlns="http://www.w3.org/2000/svg" 
         xlink="http://www.w3.org/1999/xlink" 
         style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
         width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
         <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
           <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
           style={{animationplaystate: "running", animationdelay: "0s"}}>
           </animateTransform>
         </circle>
         </g>
         <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
         style={{animationplaystate: "running", animationdelay: "0s"}}>
         </animateTransform>
         </circle>
         </g>
         <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
         style={{animationplaystate: "running", animationdelay: "0s"}}>
 
         </animateTransform>
         </circle>
         </g>
         <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
         style={{animationplaystate: "running", animationdelay: "0s"}}>
 
         </animateTransform>
         </circle>
         </g>
         </svg>
             </div>
              ) : (
                <> </>
              )}
              <div className="right_general">
                <div className="d-flex justify-content-between">
                  <div className="go_live w-100">
                    <div className="customiseappfeatures downspac">
                      <div className="altpad altpad-responsive altpad-mobile">
                        <div className="d-flex d-md-none justify-content-between setting-name">
                          <div className="w-100">
                            {/* <div className="app_iconname setting-subheading"> */}

                            {/* Custombundleid */}

                            {/* <div className="d-md-none d-block  w-100 pr-4 pb-2 pt-3"> */}
                            {/* Custombundleid */}
                            {/* {this.state.spinner === false && (
														this.state.multisite.is_app_link_to_multisite !== 1 && (
													 		<Custombundle plan_code={this.state.allowed_module} callloader={this.handleloader} stoploader = {this.handlestoploader} storeiosdata = {this.handlestoreiosdata}/>
													 	)
													 )} */}
                            {/* Custombundleidend */}
                            {/* </div> */}
                            {/* Custombundleidend */}
                            {/* </div> */}
                          </div>
                        </div>
                        {/* Email Verification */}
                        {window.matchMedia("(max-width: 700px)").matches && (
                          <div className="d-flex d-md-none w-100">
                            <Verifyemailstatus />
                          </div>
                        )}
                        {/* Email Verification End*/}
                        {this.state.multisite.is_app_link_to_multisite ===
                          0 && (
                          <div className="d-flex  align-items-center downlspac app_description pt-md-0 pt-3 pr-2 pr-md-0 api-head downdspac">
                            <div className="app_iconname d-md-block d-none w-50">
                              <div className="d-flex align-items-center">
                                <h3>Download</h3>
                                {/* <Amsvideo
                                  timespan={938}
                                  addclass="d-md-flex d-none align-items-center justify-content-between see_video"
                                /> */}
                              </div>
                              <h5 className="d-none d-md-block">
                                Build and download your iOS and Android app
                                here.
                                {/* <Media
                                  href="https://www.appmysite.com/support/category/download-app/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="align-items-center mx-1 kb-link"
                                >
                                  Learn More{" "}
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/Goto.png")
                                        .default
                                    }
                                    className="gotoappicon appear-spacing"
                                    alt="Goto"
                                  />
                                </Media> */}
                                {" "}
                              </h5>
                            </div>
                            <div className="relnotespace">
                              <div className="d-flex align-items-center justify-content-end">
                                {/* <a href="https://www.appmysite.com/support/news-category/release-notes/" target="_blank" rel="noopener noreferrer" className="release-notes-blue responsiveview-notes">
										<p className="responsiveview-notes">Release Notes</p>
										<svg xmlns="http://www.w3.org/2000/svg" width="11.989" height="11.984" viewBox="0 0 11.989 11.984">
										<g id="Group_5642" data-name="Group 5642" transform="translate(-5978.4 9345.725)">
											<path id="Path_9496" data-name="Path 9496" d="M5.35,0H1.337A1.337,1.337,0,0,0,0,1.337V8.693a1.337,1.337,0,0,0,1.337,1.337H8.693a1.337,1.337,0,0,0,1.337-1.337V4.681" transform="translate(5979 -9344.372)" fill="none" stroke="#7782a1" strokeWidth="1.2"/>
											<path id="Path_9497" data-name="Path 9497" d="M5985.375-9339.272l6.608-6.608" transform="translate(-2.028 0.756)" fill="none" stroke="#7782a1" strokeWidth="1.2"/>
											<path id="Path_9498" data-name="Path 9498" d="M0,0H3.845V3.845" transform="translate(5985.944 -9345.125)" fill="none" stroke="#7782a1" strokeWidth="1.2"/>
										</g>
										</svg>

									</a> */}
                                {this.state.allowed_module !== "preview" && (
                                  <div className="ml-3">
                                    <p className={styles.builder_status}>
                                      Builder Status:{" "}
                                      <span
                                        className={styles.operational}
                                        style={{
                                          color:
                                            this.state.builder_status.colour,
                                        }}
                                      >
                                        {this.state.builder_status.title}
                                      </span>
                                    </p>
                                  </div>
                                )}
                              </div>
                              {this.state.allowed_module !== "preview" &&
                                this.state.builder_status !== null &&
                                this.state.builder_status.description !== "" &&
                                parseInt(this.state.builder_status.status) !==
                                  0 && (
                                  <div className="w-100 text-right">
                                    <p
                                      className={
                                        styles.description +
                                        " p-0 m-0  mr-4 mr-md-0"
                                      }
                                      style={{
                                        color: this.state.builder_status.colour,
                                      }}
                                    >
                                      {this.state.builder_status.description}
                                    </p>
                                  </div>
                                )}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="app_iconname d-none setting-subheading">
                        {/* Custombundleid */}

                        <div className="d-md-none d-block  w-100 pr-4 pb-2 pt-3">
                          {/* Custombundleid */}
                          {this.state.spinner === false &&
                            this.state.multisite.is_app_link_to_multisite !==
                              1 && (
                              <Custombundle
                                plan_code={this.state.allowed_module}
                                callloader={this.handleloader}
                                stoploader={this.handlestoploader}
                                storeiosdata={this.handlestoreiosdata}
                              />
                            )}
                          {/* Custombundleidend */}
                        </div>
                        {/* Custombundleidend */}
                      </div>
                      {/* {this.state.allowed_module !== "preview" && (
							(this.state.builder_status !== null && this.state.builder_status.description !== "" ) && 
							parseInt(this.state.builder_status.status) !== 0
							&&
							(<div className="w-100 text-right">
								<p className={styles.description + " p-0 m-0  mr-4 mr-md-0"} style={{color:this.state.builder_status.colour}}>{this.state.builder_status.description}</p>
							</div>))} */}

                      {/* Build component */}
                      <div className="app-dash-analytics">
                        <Buildcomponent
                          spinnerdown={this.handlespinner}
                          analyticsapi={this.versionTable}
                          hitheader={(e) => this.hitheader()}
                          pathname={this.props.location.pathname}
                          location={this.props.location.search}
                          iosbundleid={this.state.ios_bundle_id}
                          iosstatus={this.state.iosstatus}
                          getthis={this}
                        />
                      </div>
                      {/* Custombundleid */}
                      <div className="d-md-block w-100 mt-4 app-status-spacing">
                        {this.state.spinner === false &&
                          this.state.multisite.is_app_link_to_multisite !==
                            1 && (
                            <Custombundle
                              plan_code={this.state.allowed_module}
                              callloader={this.handleloader}
                              stoploader={this.handlestoploader}
                              storeiosdata={this.handlestoreiosdata}
                            />
                          )}
                      </div>
                      {/* Custombundleidend */}
                      {this.state.data.length === 0 ? null : (
                        <>
                          <div
                            className="app-versions mobile-app-version  w-100"
                            style={{ position: "relative" }}
                          >
                            <div className="d-flex justify-content-between align-items-center w-100">
                              <div className="w-100 build-history-tab">
                                <ul>
                                  <li
                                    onClick={this.versionHistory}
                                    className={
                                      this.state.versionhistory === true
                                        ? "active"
                                        : "notactive"
                                    }
                                  >
                                    Version History
                                  </li>
                                  <li
                                    onClick={this.buildHistory}
                                    className={
                                      this.state.buildhistory === true
                                        ? "active"
                                        : "notactive"
                                    }
                                  >
                                    Build History
                                  </li>
                                </ul>
                              </div>
                            </div>
                            {this.state.versionhistory === true ? (
                              <div>
                                <div className="d-none d-md-flex align-items-center dashboard-version-page justify-content-between table-top pl-0 pr-0">
                                  <div className="subscription-type">
                                    <p>Date</p>
                                  </div>
                                  <div className="last-payment">
                                    <p className="d-inline">
                                      Source <br />
                                      Code Version
                                    </p>
                                    {Tooltip(
                                      "This version represents the version of AppMySite's app code, relative to other versions. For example, 1.9.0"
                                    )}
                                  </div>
                                  <div className="next-payment">
                                    <p className="d-inline">
                                      Android Version Name &amp; Code
                                    </p>
                                    {Tooltip(
                                      "The Android Version Name value represents the release version of the app code, and is visible on the Google Play Store, while the Android Version Code represents the version of the app code relative to other versions and is not visible to others. "
                                    )}
                                  </div>

                                  <div className="next-payment">
                                    <p className="d-inline">
                                      iOS Build Version &amp; String
                                    </p>
                                    {Tooltip(
                                      "The iOS Build Version is shown in the App Store and that version should match the version number you enter later in App Store Connect, while the iOS Build String is required by the App Store and is used throughout the system to identify your app's released or unreleased build. "
                                    )}
                                  </div>

                                  <div className="bundle-id">
                                    <p>Bundle ID</p>
                                  </div>
                                  <div className="payment-status">
                                    <p>Download</p>
                                  </div>
                                </div>
                                {this.state.versiondata.map((res, i) => (
                                  <div
                                    className="d-flex align-items-center dashboard-version-page justify-content-between common-height"
                                    key={i}
                                  >
                                    <div className="subscription-type d-flex align-items-center">
                                      {res.technology === "ios" ? (
                                        <svg
                                          width="48"
                                          height="48"
                                          viewBox="0 0 48 48"
                                        >
                                          <g
                                            id="Group_4567"
                                            data-name="Group 4567"
                                            transform="translate(-310 -951)"
                                          >
                                            <g
                                              id="Rectangle_1782"
                                              data-name="Rectangle 1782"
                                              transform="translate(310 951)"
                                              fill="#fff"
                                              stroke="#c8cedb"
                                              strokeWidth="1"
                                            >
                                              <rect
                                                width="48"
                                                height="48"
                                                rx="12"
                                                stroke="none"
                                              />
                                              <rect
                                                x="0.5"
                                                y="0.5"
                                                width="47"
                                                height="47"
                                                rx="11.5"
                                                fill="none"
                                              />
                                            </g>
                                            <g
                                              id="apple-logo"
                                              transform="translate(325 964)"
                                            >
                                              <g
                                                id="Group_1428"
                                                data-name="Group 1428"
                                                transform="translate(0 0)"
                                              >
                                                <path
                                                  id="Path_668"
                                                  data-name="Path 668"
                                                  d="M55.062,116.8a5.047,5.047,0,0,1-.914-2.956,4.723,4.723,0,0,1,.861-2.744,8.59,8.59,0,0,1,1.511-1.564,6.835,6.835,0,0,0-1.379-1.325,4.847,4.847,0,0,0-2.823-.861,7.481,7.481,0,0,0-2.293.451,6.637,6.637,0,0,1-1.9.451,8.528,8.528,0,0,1-1.829-.4,8.818,8.818,0,0,0-2.346-.4,4.576,4.576,0,0,0-3.764,1.909,7.864,7.864,0,0,0-1.5,4.957,13.589,13.589,0,0,0,1.949,6.667q1.975,3.433,3.99,3.433a4.792,4.792,0,0,0,1.75-.451,5.109,5.109,0,0,1,1.882-.437,5.861,5.861,0,0,1,2,.424,6.128,6.128,0,0,0,1.856.424q1.7,0,3.406-2.6a12.8,12.8,0,0,0,1.63-3.314A4.032,4.032,0,0,1,55.062,116.8Z"
                                                  transform="translate(-38.692 -102.365)"
                                                  fill="#f68751"
                                                />
                                                <path
                                                  id="Path_669"
                                                  data-name="Path 669"
                                                  d="M236.5,4.189a5.441,5.441,0,0,0,1.233-1.829,5.056,5.056,0,0,0,.384-1.8c0-.035,0-.079-.007-.133s-.006-.1-.006-.132a1.276,1.276,0,0,1-.033-.146A1.232,1.232,0,0,0,238.033,0a5.283,5.283,0,0,0-3.314,1.962,6.045,6.045,0,0,0-1.034,3.406,7.407,7.407,0,0,0,1.378-.225A3.756,3.756,0,0,0,236.5,4.189Z"
                                                  transform="translate(-224.632 0)"
                                                  fill="#f68751"
                                                />
                                              </g>
                                            </g>
                                          </g>
                                        </svg>
                                      ) : (
                                        <svg
                                          width="48"
                                          height="48"
                                          viewBox="0 0 48 48"
                                        >
                                          <g
                                            id="Group_4566"
                                            data-name="Group 4566"
                                            transform="translate(-310 -755)"
                                          >
                                            <g
                                              id="Rectangle_1755"
                                              data-name="Rectangle 1755"
                                              transform="translate(310 755)"
                                              fill="#fff"
                                              stroke="#c8cedb"
                                              strokeWidth="1"
                                            >
                                              <rect
                                                width="48"
                                                height="48"
                                                rx="12"
                                                stroke="none"
                                              />
                                              <rect
                                                x="0.5"
                                                y="0.5"
                                                width="47"
                                                height="47"
                                                rx="11.5"
                                                fill="none"
                                              />
                                            </g>
                                            <path
                                              id="Path_7894"
                                              data-name="Path 7894"
                                              d="M1.568,17.216A1.479,1.479,0,0,1,0,15.845V9.978A1.48,1.48,0,0,1,1.568,8.605,1.481,1.481,0,0,1,3.135,9.978v5.867A1.48,1.48,0,0,1,1.568,17.216Zm11.858,5.737L14.8,24.32a.432.432,0,1,1-.612.61L12.5,23.247a8,8,0,0,1-3.782,0L7.033,24.93a.432.432,0,1,1-.612-.61l1.363-1.359A5.524,5.524,0,0,1,3.93,18c0-.027,13.332,0,13.332,0A5.516,5.516,0,0,1,13.425,22.954Zm-4.79-3.389a.783.783,0,1,0,.784.783A.783.783,0,0,0,8.635,19.565Zm3.922,0a.783.783,0,1,0,.784.783A.783.783,0,0,0,12.557,19.565Zm7.05-2.349a1.48,1.48,0,0,1-1.568-1.372V9.978a1.582,1.582,0,0,1,3.135,0v5.867A1.48,1.48,0,0,1,19.607,17.216ZM3.93,7.83A2.35,2.35,0,0,1,6.275,5.483V1.372A1.48,1.48,0,0,1,7.842,0,1.479,1.479,0,0,1,9.409,1.372V5.483h2.356V1.372A1.48,1.48,0,0,1,13.333,0,1.479,1.479,0,0,1,14.9,1.372V5.483h.01A2.35,2.35,0,0,1,17.262,7.83v9.389H3.931V7.83Z"
                                              transform="translate(344.174 791.057) rotate(180)"
                                              fill="#01c99b"
                                            />
                                          </g>
                                        </svg>
                                      )}
                                      <div style={{ marginLeft: "10px" }}>
                                        {/* <h4>Version {res.portal_version}</h4> */}
                                        <h4 className="mb-0">
                                          {moment(res.date).format(
                                            "Do MMM YYYY"
                                          )}
                                        </h4>
                                        <h4
                                          className="mb-0"
                                          style={{
                                            color: "#7782A1",
                                            fontSize: "13px",
                                          }}
                                        >
                                          {moment(res.date).format("h:mm a")}
                                        </h4>
                                      </div>
                                    </div>
                                    <div className="last-payment d-none d-md-block">
                                      <h4>
                                        {res.app_in_process === 0 &&
                                        (res.build_status === "0" ||
                                          res.build_status === "3") ? (
                                          <>-</>
                                        ) : res.app_code_version === "" ? (
                                          <span>-</span>
                                        ) : (
                                          res.app_code_version
                                        )}
                                      </h4>
                                    </div>
                                    <div className="next-payment d-none d-md-block">
                                      {res.technology === "ios" ? (
                                        <h4>-</h4>
                                      ) : (
                                        <h4>
                                          {res.app_in_process === 0 &&
                                          (res.build_status === "0" ||
                                            res.build_status === "3") ? (
                                            <>-</>
                                          ) : (
                                            <>
                                              {res.build_release === "" ? (
                                                <span>-</span>
                                              ) : (
                                                res.build_release
                                              )}
                                              <span>
                                                {" "}
                                                (
                                                {res.version === "" ? (
                                                  <span>-</span>
                                                ) : (
                                                  res.version
                                                )}
                                                )
                                              </span>
                                            </>
                                          )}
                                        </h4>
                                      )}
                                    </div>
                                    {/* <div className="payment-method d-none d-md-block">
													{res.technology === "ios"
													?
													<h4>-</h4>
													:
													<h4>
														{res.app_in_process === 0 && (res.build_status === "0" || res.build_status === "3")
														?
														<>-</>
														:
														res.version  === "" ? <span>-</span> : res.version
														}
													
													</h4>
													}
													
													</div> */}
                                    {/* iOS Data */}

                                    <div className="payment-method d-none d-md-block">
                                      {res.technology === "ios" ? (
                                        <h4>
                                          {res.app_in_process === 0 &&
                                          (res.build_status === "0" ||
                                            res.build_status === "3") ? (
                                            <>-</>
                                          ) : (
                                            <>
                                              {res.build_release === "" ? (
                                                <span>-</span>
                                              ) : (
                                                res.build_release
                                              )}
                                              <span>
                                                {" "}
                                                (
                                                {res.version === "" ? (
                                                  <span>-</span>
                                                ) : (
                                                  res.version
                                                )}
                                                )
                                              </span>
                                            </>
                                          )}
                                        </h4>
                                      ) : (
                                        <h4>-</h4>
                                      )}
                                    </div>
                                    {/* <div className="next-payment d-none d-md-block">
													{res.technology === "ios"
													?
													<h4>
														{res.app_in_process === 0 && (res.build_status === "0" || res.build_status === "3")
														?
														<>-</>
														:
														res.version  === "" ? <span>-</span> : res.version
														}
													
													</h4>
													:
													<h4> - </h4>
													}
													</div> */}
                                    <div className="bundle-id d-none d-md-block">
                                      {res.app_in_process === 0 &&
                                      res.build_status === "1" &&
                                      res.bundle_id === null ? (
                                        <>
                                          com.appname.app
                                          {localStorage.getItem("Appid")}
                                        </>
                                      ) : res.bundle_id === null ? (
                                        <span>-</span>
                                      ) : (
                                        res.bundle_id
                                      )}
                                    </div>
                                    <div className="payment-status">
                                      {res.technology === "android" ? (
                                        <>
                                          {(res.app_in_process === 0 &&
                                            res.build_status === "1" &&
                                            res.bitrise_success_step ===
                                              "success" &&
                                            res.bitbucket_success_step ===
                                              "success") ||
                                          (res.app_in_process === 0 &&
                                            res.build_status === "4" &&
                                            res.bitrise_success_step ===
                                              "success" &&
                                            res.bitbucket_success_step ===
                                              "success") ? (
                                            <a
                                              className="apkbutton"
                                              href={
                                                process.env
                                                  .REACT_APP_Image_Path +
                                                res.build_url
                                              }
                                              download
                                            >
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/apkbutton.png")
                                                    .default
                                                }
                                                alt="Download"
                                                className="img-fluid"
                                              />
                                            </a>
                                          ) : (
                                            <span className="subsbutton">
                                              -
                                            </span>
                                          )}
                                          {(res.app_in_process === 0 &&
                                            res.build_status === "1" &&
                                            res.bitrise_success_step ===
                                              "success" &&
                                            res.bitbucket_success_step ===
                                              "success") ||
                                          (res.app_in_process === 0 &&
                                            res.build_status === "4" &&
                                            res.bitrise_success_step ===
                                              "success" &&
                                            res.bitbucket_success_step ===
                                              "success") ? (
                                            <a
                                              className="aabbutton"
                                              href={
                                                process.env
                                                  .REACT_APP_Image_Path +
                                                res.zip_path
                                              }
                                              download
                                            >
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/aabbutton.png")
                                                    .default
                                                }
                                                alt="Download"
                                                className="img-fluid"
                                              />
                                            </a>
                                          ) : (
                                            <span className="subsbutton">
                                              -
                                            </span>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {(res.app_in_process === 0 &&
                                            res.build_status === "1" &&
                                            res.bitrise_success_step ===
                                              "success" &&
                                            res.bitbucket_success_step ===
                                              "success") ||
                                          (res.app_in_process === 0 &&
                                            res.build_status === "4" &&
                                            res.bitrise_success_step ===
                                              "success" &&
                                            res.bitbucket_success_step ===
                                              "success") ? (
                                            <a
                                              className="aabbutton"
                                              href={
                                                process.env
                                                  .REACT_APP_Image_Path +
                                                res.build_url
                                              }
                                              download
                                            >
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/ipabutton.png")
                                                    .default
                                                }
                                                alt="Download"
                                                className="img-fluid"
                                              />
                                            </a>
                                          ) : (
                                            <span className="subsbutton">
                                              -
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <></>
                            )}
                            {this.state.buildhistory === true ? (
                              <div>
                                <div className="d-none d-md-flex align-items-center dashboard-version-page justify-content-between table-top pl-0 pr-0">
                                  <div className="subscription-type block1">
                                    <p>Date</p>
                                  </div>
                                  <div className="last-payment block2">
                                    <p className="d-inline">Type</p>
                                  </div>
                                  <div className="last-payment block2">
                                    <p className="d-inline">
                                      Source <br />
                                      Code Version
                                    </p>
                                    {Tooltip(
                                      "This version represents the version of AppMySite's app code, relative to other versions. For example, 1.9.0"
                                    )}
                                  </div>
                                  <div className="next-payment block3">
                                    <p className="d-inline">
                                      Android Version Name &amp; Code
                                    </p>
                                    {Tooltip(
                                      "The Android Version Name value represents the release version of the app code, and is visible on the Google Play Store, while the Android Version Code represents the version of the app code relative to other versions and is not visible to others. "
                                    )}
                                  </div>

                                  <div className="next-payment block3">
                                    <p className="d-inline">
                                      iOS Build Version &amp; String
                                    </p>
                                    {Tooltip(
                                      "The iOS Build Version is shown in the App Store and that version should match the version number you enter later in App Store Connect, while the iOS Build String is required by the App Store and is used throughout the system to identify your app's released or unreleased build. "
                                    )}
                                  </div>
                                  <div className="bundle-id block4">
                                    <p>Bundle ID</p>
                                  </div>
                                  <div className="payment-status block5">
                                    <p>Status</p>
                                  </div>
                                  <div className="payment-status block6">
                                    <p>Download</p>
                                  </div>
                                </div>
                                {this.state.data.map((res, i) => (
                                  <div
                                    className="d-flex align-items-center dashboard-version-page justify-content-between common-height"
                                    key={i}
                                  >
                                    <div className="subscription-type d-flex align-items-center">
                                      {res.technology === "ios" ? (
                                        <svg
                                          width="48"
                                          height="48"
                                          viewBox="0 0 48 48"
                                        >
                                          <g
                                            id="Group_4567"
                                            data-name="Group 4567"
                                            transform="translate(-310 -951)"
                                          >
                                            <g
                                              id="Rectangle_1782"
                                              data-name="Rectangle 1782"
                                              transform="translate(310 951)"
                                              fill="#fff"
                                              stroke="#c8cedb"
                                              strokeWidth="1"
                                            >
                                              <rect
                                                width="48"
                                                height="48"
                                                rx="12"
                                                stroke="none"
                                              />
                                              <rect
                                                x="0.5"
                                                y="0.5"
                                                width="47"
                                                height="47"
                                                rx="11.5"
                                                fill="none"
                                              />
                                            </g>
                                            <g
                                              id="apple-logo"
                                              transform="translate(325 964)"
                                            >
                                              <g
                                                id="Group_1428"
                                                data-name="Group 1428"
                                                transform="translate(0 0)"
                                              >
                                                <path
                                                  id="Path_668"
                                                  data-name="Path 668"
                                                  d="M55.062,116.8a5.047,5.047,0,0,1-.914-2.956,4.723,4.723,0,0,1,.861-2.744,8.59,8.59,0,0,1,1.511-1.564,6.835,6.835,0,0,0-1.379-1.325,4.847,4.847,0,0,0-2.823-.861,7.481,7.481,0,0,0-2.293.451,6.637,6.637,0,0,1-1.9.451,8.528,8.528,0,0,1-1.829-.4,8.818,8.818,0,0,0-2.346-.4,4.576,4.576,0,0,0-3.764,1.909,7.864,7.864,0,0,0-1.5,4.957,13.589,13.589,0,0,0,1.949,6.667q1.975,3.433,3.99,3.433a4.792,4.792,0,0,0,1.75-.451,5.109,5.109,0,0,1,1.882-.437,5.861,5.861,0,0,1,2,.424,6.128,6.128,0,0,0,1.856.424q1.7,0,3.406-2.6a12.8,12.8,0,0,0,1.63-3.314A4.032,4.032,0,0,1,55.062,116.8Z"
                                                  transform="translate(-38.692 -102.365)"
                                                  fill="#f68751"
                                                />
                                                <path
                                                  id="Path_669"
                                                  data-name="Path 669"
                                                  d="M236.5,4.189a5.441,5.441,0,0,0,1.233-1.829,5.056,5.056,0,0,0,.384-1.8c0-.035,0-.079-.007-.133s-.006-.1-.006-.132a1.276,1.276,0,0,1-.033-.146A1.232,1.232,0,0,0,238.033,0a5.283,5.283,0,0,0-3.314,1.962,6.045,6.045,0,0,0-1.034,3.406,7.407,7.407,0,0,0,1.378-.225A3.756,3.756,0,0,0,236.5,4.189Z"
                                                  transform="translate(-224.632 0)"
                                                  fill="#f68751"
                                                />
                                              </g>
                                            </g>
                                          </g>
                                        </svg>
                                      ) : (
                                        <svg
                                          width="48"
                                          height="48"
                                          viewBox="0 0 48 48"
                                        >
                                          <g
                                            id="Group_4566"
                                            data-name="Group 4566"
                                            transform="translate(-310 -755)"
                                          >
                                            <g
                                              id="Rectangle_1755"
                                              data-name="Rectangle 1755"
                                              transform="translate(310 755)"
                                              fill="#fff"
                                              stroke="#c8cedb"
                                              strokeWidth="1"
                                            >
                                              <rect
                                                width="48"
                                                height="48"
                                                rx="12"
                                                stroke="none"
                                              />
                                              <rect
                                                x="0.5"
                                                y="0.5"
                                                width="47"
                                                height="47"
                                                rx="11.5"
                                                fill="none"
                                              />
                                            </g>
                                            <path
                                              id="Path_7894"
                                              data-name="Path 7894"
                                              d="M1.568,17.216A1.479,1.479,0,0,1,0,15.845V9.978A1.48,1.48,0,0,1,1.568,8.605,1.481,1.481,0,0,1,3.135,9.978v5.867A1.48,1.48,0,0,1,1.568,17.216Zm11.858,5.737L14.8,24.32a.432.432,0,1,1-.612.61L12.5,23.247a8,8,0,0,1-3.782,0L7.033,24.93a.432.432,0,1,1-.612-.61l1.363-1.359A5.524,5.524,0,0,1,3.93,18c0-.027,13.332,0,13.332,0A5.516,5.516,0,0,1,13.425,22.954Zm-4.79-3.389a.783.783,0,1,0,.784.783A.783.783,0,0,0,8.635,19.565Zm3.922,0a.783.783,0,1,0,.784.783A.783.783,0,0,0,12.557,19.565Zm7.05-2.349a1.48,1.48,0,0,1-1.568-1.372V9.978a1.582,1.582,0,0,1,3.135,0v5.867A1.48,1.48,0,0,1,19.607,17.216ZM3.93,7.83A2.35,2.35,0,0,1,6.275,5.483V1.372A1.48,1.48,0,0,1,7.842,0,1.479,1.479,0,0,1,9.409,1.372V5.483h2.356V1.372A1.48,1.48,0,0,1,13.333,0,1.479,1.479,0,0,1,14.9,1.372V5.483h.01A2.35,2.35,0,0,1,17.262,7.83v9.389H3.931V7.83Z"
                                              transform="translate(344.174 791.057) rotate(180)"
                                              fill="#01c99b"
                                            />
                                          </g>
                                        </svg>
                                      )}

                                      <div style={{ marginLeft: "10px" }}>
                                        {/* <h4>Version {res.portal_version }</h4> */}
                                        <h4 className="mb-0">
                                          {moment(res.date).format(
                                            "Do MMM YYYY"
                                          )}
                                        </h4>
                                        <h4
                                          className="mb-0"
                                          style={{
                                            color: "#7782A1",
                                            fontSize: "13px",
                                          }}
                                        >
                                          {moment(res.date).format("h:mm a")}
                                        </h4>
                                      </div>
                                    </div>
                                    <div className="last-payment d-none d-md-block">
                                        <h4>
                                          { res.website_technology === 2 ?
                                              <p class="apptype-tooltip"><img src={require("../../Agency/assets/images/webtoapp.png").default} alt="webtoapp"/><p class="apptype-tooltipsubheader">Web to App</p></p>
                                          :
                                              (res.website_technology === 3 ?
                                                  <p class="apptype-tooltip"><img src={require("../../Agency/assets/images/customapp.png").default} alt="customapps"/> <p class="apptype-tooltipsubheader">Custom App</p></p>   
                                              :
                                                  (res.website_technology === 4 ?
                                                      <p class="apptype-tooltip"><img src={require("../../Agency/assets/images/wordpress.png").default} alt="wordpress"/><p class="apptype-tooltipsubheader">Wordpress App</p></p>
                                                  :
                                                      (res.website_technology === 5 ?
                                                          <p class="apptype-tooltip"><img src={require("../../Agency/assets/images/woocomerce.png").default} alt="woocommerce"/><p class="apptype-tooltipsubheader">WooCommerce App</p></p>
                                                      :
                                                          <p class="apptype-tooltip">{"-"}</p>
                                                      )
                                                  )
                                              )
                                          }
                                        </h4>
                                    </div>
                                    <div className="last-payment d-none d-md-block">
                                      <h4>
                                        {res.app_in_process === 0 &&
                                        (res.build_status === "0" ||
                                          res.build_status === "3") ? (
                                          <>-</>
                                        ) : res.app_code_version === "" ? (
                                          <span>-</span>
                                        ) : (
                                          res.app_code_version
                                        )}
                                      </h4>
                                    </div>
                                    <div className="next-payment d-none d-md-block">
                                      {res.technology === "ios" ? (
                                        <h4>-</h4>
                                      ) : (
                                        <h4>
                                          {res.app_in_process === 0 &&
                                          (res.build_status === "0" ||
                                            res.build_status === "3") ? (
                                            <>-</>
                                          ) : (
                                            <>
                                              {res.build_release === "" ? (
                                                <span>-</span>
                                              ) : (
                                                res.build_release
                                              )}
                                              <span>
                                                {" "}
                                                (
                                                {res.version === "" ? (
                                                  <span>-</span>
                                                ) : (
                                                  res.version
                                                )}
                                                )
                                              </span>
                                            </>
                                          )}
                                        </h4>
                                      )}
                                    </div>
                                    {/* <div className="payment-method d-none d-md-block">
													{res.technology === "ios"
													?
															<h4>-
															</h4>
													   
													:
															<h4>
															{res.app_in_process === 0 && (res.build_status === "0" || res.build_status === "3")
															?
															<>-</>
															:
															res.version  === "" ? <span>-</span> : res.version
															}
															</h4>
													  
													}
													
													</div> */}
                                    {/* iOS column */}
                                    <div className="payment-method d-none d-md-block">
                                      {res.technology === "ios" ? (
                                        <h4>
                                          {res.app_in_process === 0 &&
                                          (res.build_status === "0" ||
                                            res.build_status === "3") ? (
                                            <>-</>
                                          ) : (
                                            <>
                                              {res.build_release === "" ? (
                                                <span>-</span>
                                              ) : (
                                                res.build_release
                                              )}
                                              <span>
                                                {" "}
                                                (
                                                {res.version === "" ? (
                                                  <span>-</span>
                                                ) : (
                                                  res.version
                                                )}
                                                )
                                              </span>
                                            </>
                                          )}
                                        </h4>
                                      ) : (
                                        <h4>-</h4>
                                      )}
                                    </div>

                                    {/* <div className="next-payment d-none d-md-block">

														{res.technology === "ios"
														?
															<h4>
																{res.app_in_process === 0 && (res.build_status === "0" || res.build_status === "3")
																?
																<>-</>
																:
																res.version  === "" ? <span>-</span> : res.version
																}
															</h4>
														:
															<h4>-</h4>
														
														}
														</div> */}

                                    <div className="bundle-id d-none d-md-block">
                                      <h4>
                                        {res.app_in_process === 0 &&
                                        (res.build_status === "1" ||
                                          res.build_status === "4") &&
                                        res.bundle_id === null ? (
                                          <>
                                            com.appname.app
                                            {localStorage.getItem("Appid")}
                                          </>
                                        ) : res.bundle_id === null ? (
                                          <span>-</span>
                                        ) : (
                                          res.bundle_id
                                        )}
                                      </h4>
                                    </div>
                                    <div className="payment-status d-none d-md-block">
                                      {Checkstatus(res)}
                                    </div>
                                    <div
                                      className={
                                        "payment-status d-md-block" +
                                        styles.buildbutton
                                      }
                                    >
                                      {res.technology === "android" ? (
                                        <>
                                          {(res.app_in_process === 0 &&
                                            res.build_status === "1" &&
                                            res.bitrise_success_step ===
                                              "success" &&
                                            res.bitbucket_success_step ===
                                              "success") ||
                                          (res.app_in_process === 0 &&
                                            res.build_status === "4" &&
                                            res.bitrise_success_step ===
                                              "success" &&
                                            res.bitbucket_success_step ===
                                              "success") ? (
                                            <a
                                              className="apkbutton"
                                              href={
                                                process.env
                                                  .REACT_APP_Image_Path +
                                                res.build_url
                                              }
                                              download
                                            >
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/apkbutton.png")
                                                    .default
                                                }
                                                alt="Download"
                                                className="img-fluid"
                                              />
                                            </a>
                                          ) : (
                                            <span className="subsbutton">
                                              -
                                            </span>
                                          )}
                                          {(res.app_in_process === 0 &&
                                            res.build_status === "1" &&
                                            res.bitrise_success_step ===
                                              "success" &&
                                            res.bitbucket_success_step ===
                                              "success") ||
                                          (res.app_in_process === 0 &&
                                            res.build_status === "4" &&
                                            res.bitrise_success_step ===
                                              "success" &&
                                            res.bitbucket_success_step ===
                                              "success") ? (
                                            <a
                                              className="aabbutton"
                                              href={
                                                process.env
                                                  .REACT_APP_Image_Path +
                                                res.zip_path
                                              }
                                              download
                                            >
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/aabbutton.png")
                                                    .default
                                                }
                                                alt="Download"
                                                className="img-fluid"
                                              />
                                            </a>
                                          ) : (
                                            <span className="subsbutton">
                                              -
                                            </span>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {(res.app_in_process === 0 &&
                                            res.build_status === "1" &&
                                            res.bitrise_success_step ===
                                              "success" &&
                                            res.bitbucket_success_step ===
                                              "success") ||
                                          (res.app_in_process === 0 &&
                                            res.build_status === "4" &&
                                            res.bitrise_success_step ===
                                              "success" &&
                                            res.bitbucket_success_step ===
                                              "success") ? (
                                            <a
                                              className="aabbutton"
                                              href={
                                                process.env
                                                  .REACT_APP_Image_Path +
                                                res.build_url
                                              }
                                              download
                                            >
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/ipabutton.png")
                                                    .default
                                                }
                                                alt="Download"
                                                className="img-fluid"
                                              />
                                            </a>
                                          ) : (
                                            <span className="subsbutton">
                                              -
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {InnerFoot()} */}
          </div>
        </section>
      </>
    );
  }
}
