import React from 'react';
import { Link } from "react-router-dom"; 
export const Error = (props) => {
    return(
      <div className="api-not-connected w-100">
        <div className="conecttoapi connectioerror">
            <div className="text-center mb-4">
            <h2 className="mb-3">{props.errordata.heading_first}</h2>
                <h3>{props.errordata.heading_second}</h3>
            </div>
            <div className="text-center mb-4">
                <img src={require("../../assets/images/dashboard/plugin-error.png").default} alt="Connecting" className="img-fluid"/>
            </div>
            <div className="text-center mb-4">
                <p className="w-100">{props.errordata.heading_third}</p>
            </div>
            <div className="text-center mb-2">
                <Link to={props.errordata.button_link} style={{width:"215px",textTransform:"capitalize",lineHeight: "39px"}}>{props.errordata.button_text}</Link>
            </div>
        </div>
     </div>
    )
  }