import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import "./../../assets/css/Dashboard.css";
import Header from "./../Header";
import Leftpanel from "./../Leftpanel";
import { Link, Redirect, useLocation } from "react-router-dom";
import ReactTags from "react-tag-autocomplete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import emailValidator from "email-validator";
import addDays from "date-fns/addDays";
import imagebackground3 from "./../../assets/images/dashboard/cms/uploadbackground.png";
import imagebackground from "./../../assets/images/dashboard/my-account.png";
import {
  Media,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import imagebackground2 from "./../../assets/images/dashboard/cms/bannerimg.jpg";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";

import { FormGroup, Label, Input } from "reactstrap";
// import { ErrorBoundary } from "react-error-boundary";

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
// import Editor from "./EditorImplement";

import Title from "./Title";
import ImageBlock from "./ImageBlock";
import HeadingBlock from "./HeadingBlock";
import ParagraphBlock from "./ParagraphBlock";
import SpacerBlock from "./SpacerBlock";
import SeparatorBlock from "./SeparatorBlock";
import ButtonBlock from "./ButtonBlock";
import GalleryBlock from "./GalleryBlock";
import CodeBlock from "./CodeBlock";
import Preview from "./Preview";
import {
  cmsGetEachPostApi,
  cmsGetPostsApi,
  cmsGetParent,
  cmsSavePostApi,
  cmsgetTagsApi,
  cmsgetCategoryApi,
  teamApiForCMS,
  GetpostapiForFunctionalCompo,
  cmsGetAtrApi,
  cmsgetCatForEditPost,
  getAdvertisementDataForCMS,
} from "../../Api/CmsApi/Cmsapi";
import { useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { parentPage, tagsAndCategory } from "./CommonPopUp";
import "./cmsBuilderstyle.css";
import { errortoast } from "../Toaster";
// import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import ReactSelect from "react-select";

import Select from "react-select";
import { uploadAnyBase64Imagetos3 } from "../../Api/NewHomescreen/NewHomescreenApi";
import VideoBlock from "./VideoBlock";
import YouTubeVideoBlock from "./YouTubeVideoBlock";
import VimeoVideoBlock from "./VimeoVidepBlock";
import FacebookVideo from "./FacebookVideo";
import NewImageGalleryPopup from "../NewImageGalleryPopup";
import AttributeBlock from "./AttributeBlock";
import {
  MultiSelectionPopUpForAttribute,
  MultiSelectionPopUpForCustomApp,
} from "../Reusable/MultiSelectionPopUp";
import BannerAdBlock from "./BannerAdBlock";
import CustomAdBlock from "./CustomAdBlock";
import { useReducer } from "react";
import SettingIcon from "../../Components/commonIcons/SettingIcon";
const EditPost = (props) => {
  const leftSideRef = useRef(null);
  const rightSideRef = useRef(null);
  const showTagRef = useRef(null);
  const showCatRef = useRef(null);
  const showAuthorRef = useRef(null);
  const showPublishDateRef = useRef(null);
  const showRelatedPostsRef = useRef(null);

  const [activeTab, setactiveTab] = useState(true);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showcustomBlockpopup, setshowcustomBlockpopup] = useState(false);
  const [selectedItem, setselectedItem] = useState("");
  const [galleryToggle, setgalleryToggle] = useState("6");
  // const [content, setcontent] = useState("");
  // const [featuredImage, setfeaturedImage] = useState("");
  const [parentId, setparentId] = useState(0);
  const [cmsData, setcmsData] = useState([]);
  const [isRowOpen, setisRowOpen] = useState([]);

  const [galleryTab, setgalleryTab] = useState("6");

  const [value, setValue] = useState("");
  const [isCatDropdown, setIsCatDropdown] = useState(false);
  const [isTagDropdown, setIsTagDropdown] = useState(false);
  // const [allPost, setAllPost] = useState([]);
  const [title, setTitle] = useState("");
  const [spinner, setSpinner] = useState(true);
  const [spinner2, setSpinner2] = useState(true);
  const [spinner3, setSpinner3] = useState(true);
  const [excerpt, setExcerpt] = useState("");
  const [is_featured, setIsFeatured] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [tagData, setTagData] = useState([]);

  const [catArrForPost, setCatArrForPost] = useState([]);
  const [displayCatArrForPost, setDisplayCatArrForPost] = useState([]);
  const [tagArrForPost, setTagArrForPost] = useState([]);
  const [atrArrForPost, setAtrArrForPost] = useState([]);

  const [dateTimeValue, setDateTimeValue] = useState(new Date());
  const [isLoader, setIsLoader] = useState("d-none");
  const [submitval, setSubmitval] = useState("Publish");

  const [isLoader2, setIsLoader2] = useState("d-none");
  const [submitval2, setSubmitval2] = useState("Save draft");

  const [showMobilPreview, setShowhowMobilPreview] = useState(false);

  const [publishImmediate, setPublishImmediate] = useState(true);
  const [showTag, setShowTag] = useState(true);
  const [showCat, setShowCat] = useState(true);
  const [showAuthor, setShowAuthor] = useState(true);

  const [openItemIndex, setOpenItemIndex] = useState(null);

  const [openMobileCatDropdown, setOpenMobileCatDropdown] = useState(false);
  const [openMobileTagDropdown, setOpenMobileTagDropdown] = useState(false);
  const [tagSearchVal, setTagSearchVal] = useState("");
  const [atrSearchVal, setAtrSearchVal] = useState("");
  const [catSearchVal, setCatSearchVal] = useState("");
  const [catCheckedArr, setCatCheckedArr] = useState([]);
  const [tagCheckedArr, setTagCheckedArr] = useState([]);
  const [statusOfPost, setStatusOfPost] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [isRelatedToPopup, setIsRelatedToPopup] = useState(false);

  const [postRelatedTo, setPostRelatedTo] = useState("tags");
  const [isAuthorPopup, setIsAuthorPopup] = useState(false);
  const [authorId, setAuthorId] = useState(
    parseInt(localStorage.getItem("logged_in_user_id"))
  );
  const [openMobileAuthorDropdown, setOpenMobileAuthorDropdown] =
    useState(false);
  const [openMobileRelatedToDropdown, setOpenMobileRelatedToDropdown] =
    useState(false);
  // const [teamInvitedUserData, setTeamInvitedUserData] = useState([]);
  // const [teamOwnerData, setTeamOwnerData] = useState(null);
  const [mergeUserOwnerData, setMergeUserOwnerData] = useState([]);
  const [openAdvanceSetting, setOpenAdvanceSetting] = useState(false);
  const [showPublishDate, setShowPublishDate] = useState(true);
  const [showRelatedPosts, setShowRelatedPosts] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showGridView, setShowGridView] = useState(false);
  const [overrideMasterSettings, setOverrideMasterSettings] = useState(0);
  const [resetSpinner, setResetSpinner] = useState(false);
  const [openAdvnaceSetting, setOpenAdvnaceSetting] = useState(false);
  const [searchValForPopUp, setSearchValForPopUp] = useState("");
  const [isGalleryPopup, setIsGalleryPopup] = useState(false);
  const [templistingThumb, setTempListingThumb] = useState("");
  const [base64imgThumb, setBase64imgThumb] = useState("");

  const [imgRatioForListing, setImgRatioForListing] = useState("1:1");
  const [imgRatioForDetails, setImgRatioForDetails] = useState("1:1");
  const [imgShapeForListing, setImgShapeForListing] = useState("soft_corner");
  const [imgShapeForDetails, setImgShapeForDetails] = useState("soft_corner");
  const [imgCenterForDetails, setImgCenterForDetails] = useState(true);
  const [imgCenterForListing, setImgCenterForListing] = useState(true);
  const [sectionTopMargin, setSectionTopMargin] = useState("7.5");
  const [sectionBottomMargin, setSectionBottomMargin] = useState("7.5");
  const [sectionRightMargin, setSectionRightMargin] = useState("15");
  const [sectionLeftMargin, setSectionLeftMargin] = useState("15");

  const [listingThumb, setListingThumb] = useState("");
  const [appRightPanelExpand, setAppRightPanelExpand] = useState(
    localStorage.getItem("appSideBarCollapse") === true ||
      localStorage.getItem("appSideBarCollapse") === "true"
      ? true
      : false
  );
  const [openAttributeDropdown, setOpenAttributeDropdown] = useState(false);
  const [openMobAttributeDropdown, setOpenMobAttributeDropdown] =
    useState(false);

  const [atrListingData, setAtrListingData] = useState([]);
  const [duumySpin1, setDuumySpin1] = useState(false);
  const [duumySpin2, setDuumySpin2] = useState(false);
  const [openAttDropSelectedRow, setOpenAttDropSelectedRow] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [selectedAtrIdsArr, setSelectedAtrIdsArr] = useState([]);
  const [openCatMultiSelectionPopUp, setOpenCatMultiSelectionPopUp] =
    useState(false);
  const [openAtrMultiSelectionPopUp, setOpenAtrMultiSelectionPopUp] =
    useState(false);
  const [openTagMultiSelectionPopUp, setOpenTagMultiSelectionPopUp] =
    useState(false);
  const [catSubData, setCatSubData] = useState([]);
  const [oldCatSubData, setOldCatSubData] = useState([]);
  const [catBreadcrumbArr, setCatBreadcrumbArr] = useState([]);
  const [oldCatBreadcrumbArr, setOldCatBreadcrumbArr] = useState([]);
  const [tagSubData, setTagSubData] = useState([]);
  const [oldTagSubData, setOldTagSubData] = useState([]);
  const [tagBreadcrumbArr, setTagBreadcrumbArr] = useState([]);
  const [oldTagBreadcrumbArr, setOldTagBreadcrumbArr] = useState([]);
  const [atrSubData, setAtrSubData] = useState([]);
  const [oldAtrSubData, setOldAtrSubData] = useState([]);
  const [atrBreadcrumbArr, setAtrBreadcrumbArr] = useState([]);
  const [oldAtrBreadcrumbArr, setOldAtrBreadcrumbArr] = useState([]);

  const [tempCatSelection, setTempCatSelection] = useState([]);
  const [tempAtrSelection, setTempAtrSelection] = useState([]);
  const [tempTagSelection, setTempTagSelection] = useState([]);
  const [catSelection, setCatSelection] = useState([]);
  const [atrSelection, setAtrSelection] = useState([]);
  const [tagSelection, setTagSelection] = useState([]);
  const [catSearchValForMultiPopUp, setCatSearchValForMultiPopUp] =
    useState("");
  const [atrSearchValForMultiPopUp, setAtrSearchValForMultiPopUp] =
    useState("");
  const [tagSearchValForMultiPopUp, setTagSearchValForMultiPopUp] =
    useState("");
  const [adsData, setAdsData] = useState([]);
  const [selectedPreviewDevice, setSelectedPreviewDevice] = useState("android");
  const [master, setMasterSetting] = useState(null);

  const [page, setPage] = useState(1);
  const [paginationSpinner, setPaginationSpinner] = useState(false);
  const [hitNextPagination, setHitNextPagination] = useState(false);

  // For category data
  const [pageForCat, setPageForCat] = useState(1);
  const [paginationSpinnerForCat, setPaginationSpinnerForCat] = useState(false);
  const [hitNextPaginationForCat, setHitNextPaginationForCat] = useState(false);

  //For Tag data
  const [pageForTag, setPageForTag] = useState(1);
  const [paginationSpinnerForTag, setPaginationSpinnerForTag] = useState(false);
  const [hitNextPaginationForTag, setHitNextPaginationForTag] = useState(false);

  //For Attribute data
  const [pageForAtr, setPageForAtr] = useState(1);
  const [paginationSpinnerForAtr, setPaginationSpinnerForAtr] = useState(false);
  const [hitNextPaginationForAtr, setHitNextPaginationForAtr] = useState(false);
  const [iapValue, setIapValue] = useState(null);
  const [fromState, setFromState] = useState("")
  const [thumbnailActive, setThumbnailActive] = useState(false);

  const [postState, setPostState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      settings: {
        settingUpdatedAt: "",
        showBookmarkIconOnList: false,
        showFilterIconOnList: false,
        showSortIconOnList: false,
        showTotalCountOnList: false,
        enableBookmarkingOnList: false,
        showAuthorOnList: false,

        showExcerptForListing: false,
        showPublishDateForListing: false,
      },
    }
  );

  let { postID } = useParams("postID");
  useEffect(() => {
    if (
      parseInt(localStorage.getItem("website_technology")) !== 3 &&
      window.location.pathname.includes("custom")
    ) {
      window.location.href = "/app/dashboard";
    }
    if (
      localStorage.getItem("agencyid") &&
      localStorage.getItem("agencyid") == 1
    ) {
      document.title =
        postID !== undefined &&
        postID !== null &&
        postID !== "create" &&
        postID !== ""
          ? "Edit post | AppMySite"
          : "Create post | AppMySite";
    } else {
      document.title =
        postID !== undefined &&
        postID !== null &&
        postID !== "create" &&
        postID !== ""
          ? "Edit post |" + " " + localStorage.getItem("agencyname")
          : "Create post |" + " " + localStorage.getItem("agencyname");
    }

    const params = {
      app_id: btoa(localStorage.getItem("Appid")),
    };
    teamApiForCMS(params, setMergeUserOwnerData);

    getAdvertisementDataForCMS(setAdsData);

    if (
      postID !== undefined &&
      postID !== null &&
      postID !== "create" &&
      postID !== ""
    ) {
      cmsGetEachPostApi(
        setcmsData,
        postID,
        setExcerpt,
        setIsFeatured,
        setTitle,
        setCatArrForPost,
        setTagArrForPost,
        setSpinner,
        apiCall,
        setDateTimeValue,
        setPublishImmediate,
        setShowCat,
        setShowTag,
        setStatusOfPost,
        setUpdatedAt,
        setShowAuthor,
        setPostRelatedTo,
        setAuthorId,
        setShowPublishDate,
        setShowRelatedPosts,
        setOverrideMasterSettings,
        setTempListingThumb,
        setListingThumb,
        setSelectedAtrIdsArr,
        setCatSelection,
        setTempCatSelection,
        setAtrSelection,
        setTempAtrSelection,
        setTagSelection,
        setTempTagSelection,
        setMasterSetting,
        setSectionTopMargin,
        setSectionBottomMargin,
        setSectionLeftMargin,
        setSectionRightMargin,
        setImgShapeForDetails,
        setImgCenterForDetails,
        setImgShapeForListing,
        setImgRatioForListing,
        setImgCenterForListing,
        postState,
        setPostState,
        setThumbnailActive
      );
    } else {
      const params = {
        app_id: btoa(localStorage.getItem("Appid")),
      };
      GetpostapiForFunctionalCompo(
        params,
        setShowSearch,
        setShowGridView,
        setShowAuthor,
        setShowPublishDate,
        setShowCat,
        setShowTag,
        setShowRelatedPosts,
        setPostRelatedTo,
        false,
        false,
        false,
        false,
        false,
        false,

        setImgCenterForDetails,
        setImgCenterForListing,
        setImgRatioForDetails,
        setImgRatioForListing,
        setImgShapeForDetails,
        setImgShapeForListing,
        setSectionTopMargin,
        setSectionBottomMargin,
        setSectionLeftMargin,
        setSectionRightMargin,

        postState,
        setPostState
      );
      apiCall([], [], []);
      let arr = [
        {
          item_type: "title",
          status: 1,
          value: {
            title: { text: "" },
          },
          position: 0,
        },
        {
          item_type: "image",
          status: 1,
          value: {},
          portal_value: {
            design_id: "",
            type: 1,
            source_file: "",
            image_link: "",
            resize_image_link: "",
            base64_image: "",
            base64_org_image: "",
          },

          position: 1,
          style: {
            aspect_ratio: "4:3",
          },
        },

        {
          item_type: "heading",
          status: 1,
          value: { heading: { text: "" } },
          position: 2,
          style: {
            text_color: "",
            text_align: "left",

            bold_text: null,
            italic_text: null,
            underline: null,
          },
        },
        {
          item_type: "paragraph",
          status: 1,
          value: {
            paragraph: {
              text: "<p>Craft a compelling narrative here. Share your thoughts, insights, or details related to your post’s topic. Engage your audience with valuable content that adds depth to your message. </p><p><br></p><p>Remember to keep it respectful and within the app store guidelines. Let’s start the conversation</p>",
            },
          },
          position: 3,
          style: { text_align: "", text_color: "" },
        },
      ];
      setcmsData(arr);
      setSpinner(false);
    }

    initialLoad();
  }, []);

  const apiCall = (apiTagData, apiCatData, apiAtrData) => {
    let limit = 99;
    let pageNo = 1;

    setPageForCat(pageNo);
    setPageForTag(pageNo);
    setPageForAtr(pageNo);

    cmsGetAtrApi(
      setDuumySpin1,
      setAtrListingData,
      setDuumySpin2,
      "edit-post",
      apiAtrData,
      false,
      limit,
      pageNo,
      atrListingData,
      setHitNextPaginationForAtr,
      setPaginationSpinnerForAtr,
      ""
    );

    cmsgetTagsApi(
      setTagData,
      btoa(localStorage.getItem("Appid")),
      setSpinner3,
      false,
      setTagCheckedArr,
      apiTagData,
      limit,
      pageNo,
      tagData,
      setHitNextPaginationForTag,
      setPaginationSpinnerForTag,
      ""
    );

    cmsgetCategoryApi(
      setCategoryData,
      btoa(localStorage.getItem("Appid")),
      setSpinner3,
      false,
      false,
      setCatCheckedArr,
      apiCatData,
      false,
      limit,
      pageNo,
      categoryData,
      setHitNextPaginationForCat,
      setPaginationSpinnerForCat,
      ""
    );
  };

  const toggle = (tab) => {
    // if (activeTab !== tab) {
    setactiveTab(!activeTab);
    // }
  };
  const galleryToggleUpdate = (gtab) => {
    if (galleryTab !== gtab) {
      setgalleryTab(gtab);
    }
  };

  const handleDeviceChange = (e, from) => {
    setSelectedPreviewDevice(from);
  };

  const customBlock = () => {
    setshowcustomBlockpopup(!showcustomBlockpopup);
    setselectedItem("");
  };

  const initialLoad = (from, dragDstIndx, val) => {
    let isRowOpen = [];

    for (let i = 0; i < cmsData.length; i++) {
      // if (isLastItemOpen === true && i === cmsData.length - 1) {
      //   isRowOpen.push(true);
      // } else {
      if (from !== undefined && i === dragDstIndx) {
        isRowOpen.push(val);
      } else {
        isRowOpen.push(false);
      }
    }
    // }
    setisRowOpen(isRowOpen);
  };
  const handleItem = (e) => {
    setselectedItem(e.target.value);
  };

  const checkIfAtrBlockIsAdded = () => {
    let bool = false;
    for (let i = 0; i < cmsData.length; i++) {
      if (cmsData[i].item_type === "attribute") {
        bool = true;
        break;
      }
    }
    return bool;
  };

  const checkIfCustomAdBlockIsAdded = () => {
    let bool = false;
    for (let i = 0; i < cmsData.length; i++) {
      if (cmsData[i].item_type === "custom_ad") {
        bool = true;
        break;
      }
    }
    return bool;
  };

  const handleAddItem = (e) => {
    // let counter = this.state.counter;

    e.preventDefault();
    if (selectedItem === "") return;
    // setIsLastItemOpen(true);
    // setOpenItemIndex(cmsData.length);

    let param;
    switch (selectedItem) {
      case "image":
        param = {
          item_type: "image",
          status: 1,
          value: {
            // image: "",
            // source_file: "",
            // image_url: "",
          },
          portal_value: {
            design_id: "",
            type: 1,
            source_file: "",
            image_link: "",
            resize_image_link: "",
            base64_image: "",
            base64_org_image: "",
          },

          position: cmsData.length,
          style: {
            aspect_ratio: "4:3",
          },
        };

        break;
      case "gallery":
        param = {
          item_type: selectedItem,
          status: 1,
          value: {},
          portal_value: [
            {
              design_id: "",
              source_file: null,
              image_link: "",
              resize_image_link: "",
              type: 1,
              base64_image: "",
              base64_org_image: "",
            },
            {
              design_id: "",
              source_file: null,
              image_link: "",
              resize_image_link: "",
              type: 1,
              base64_image: "",
              base64_org_image: "",
            },
            {
              design_id: "",
              source_file: null,
              image_link: "",
              resize_image_link: "",
              type: 1,
              base64_image: "",
              base64_org_image: "",
            },
            {
              design_id: "",
              source_file: null,
              image_link: "",
              resize_image_link: "",
              type: 1,
              base64_image: "",
              base64_org_image: "",
            },
          ],
          showOnlyArr: ["", "", "", ""],
          position: cmsData.length,
          style: { row: 2, column: 2, aspect_ratio: "4:3" },
        };
        break;
      case "heading":
        param = {
          item_type: selectedItem,
          status: 1,
          value: { heading: { text: "" } },
          position: cmsData.length,
          style: {
            text_color: "",
            text_align: "left",

            bold_text: null,
            italic_text: null,
            underline: null,
          },
        };
        break;
      case "separator":
        param = {
          item_type: selectedItem,
          status: 1,
          position: cmsData.length,
          value: {
            separator: "",
          },
          style: {
            color: "#808080",
            height: "10",
          },
          // value: [],
        };
        break;
      case "spacer":
        param = {
          item_type: selectedItem,
          status: 1,
          position: cmsData.length,
          value: {
            spacer: "",
          },
          style: { height: "5" },
          // value: [],
        };
        break;
      case "button":
        param = {
          item_type: selectedItem,
          status: 1,
          value: {
            button: {
              text: "Click here",
              link: "url",
              target: "",
              target_alias: "",
              // phone_code: "91",
            },
          },
          position: cmsData.length,
          style: {
            text_color: "#ffffff",
            button_color: "#1a1a1a",

            text_align: "",
          },
        };
        break;
      case "code":
        param = {
          item_type: selectedItem,
          status: 1,
          value: {
            code: {
              text: "",
            },
          },
          position: cmsData.length,
          style: null,
        };
        break;
      case "paragraph":
        param = {
          item_type: selectedItem,
          status: 1,
          value: { paragraph: { text: "" } },
          position: cmsData.length,
          style: { text_align: "", text_color: "" },
        };
        break;
      case "custom_video":
        param = {
          item_type: selectedItem,
          status: 1,
          value: null,

          position: cmsData.length,
          style: {
            height: "",
            width: "",
          },
          portal_value: {
            thumbnail: "",
            source_file: "",
          },
        };
        break;
      case "facebook_video":
        param = {
          item_type: selectedItem,
          status: 1,
          value: { facebook_video: { video_url: "" } },
          position: cmsData.length,
          style: null,
          portal_value: {
            is_link_err: false,
            link: "",
          },
        };
        break;
      case "vimeo_video":
        param = {
          item_type: selectedItem,
          status: 1,
          value: { vimeo_video: { video_id: "" } },
          position: cmsData.length,
          style: null,
          portal_value: {
            is_link_err: false,
            link: "",
          },
        };
        break;
      case "youtube_video":
        param = {
          item_type: selectedItem,
          status: 1,
          value: { youtube_video: { video_id: "" } },
          position: cmsData.length,
          style: null,
          portal_value: {
            is_link_err: false,
            link: "",
          },
        };
        break;
      case "attribute":
        param = {
          item_type: selectedItem,
          status: 1,
          value: {},
          position: cmsData.length,
          style: null,
        };
        break;
      case "banner_ad":
        param = {
          item_type: selectedItem,
          status: 1,
          value: { ad_id: "", ad_name: "" },
          position: cmsData.length,
          style: null,
        };
        break;
      case "custom_ad":
        param = {
          item_type: selectedItem,
          status: 1,
          value: { ad_id: "", ad_name: "" },
          position: cmsData.length,
          style: null,
        };
        break;
    }

    let cmsArray = [...cmsData];

    cmsArray.push(param);

    setcmsData(cmsArray);
    setshowcustomBlockpopup(!showcustomBlockpopup);
    setselectedItem("");
  };

  const deleteRow = (e, key) => {
    e.preventDefault();
    // setIsLastItemOpen(false);
    let newArr = [...cmsData];
    newArr.splice(key, 1);
    newArr.map((row, i) => {
      row.position = i;
    });

    //let isRowOpen = [];
    // for (let a = 1; a < cmsData.length; a++) {
    //   isRowOpen.push(false);
    // }

    //setisRowOpen(isRowOpen);
    setcmsData(newArr);
  };

  const duplicateItem = (e, eachBlock) => {
    // setIsLastItemOpen(true);
    let cmsArray = JSON.parse(JSON.stringify(cmsData));
    const newEachBlock = JSON.parse(JSON.stringify(eachBlock));
    cmsArray.push(newEachBlock);
    cmsArray.map((row, key) => {
      row.position = key;
    });
    setcmsData(cmsArray);
  };

  const handleItemClickRight = (index) => {
    if (!window.matchMedia("(max-width: 1200px)").matches) {
      const previewItemElement = rightSideRef?.current?.children[index];

      if (previewItemElement) {
        previewItemElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  };

  const handleItemClickLeft = (index, callback) => {
    if (!window.matchMedia("(max-width: 1200px)").matches) {
      const itemElement = leftSideRef?.current?.children[index];

      if (itemElement) {
        itemElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
      setTimeout(() => {
        callback();
      }, 500);
    }
  };

  const handleIsRowOpen = (e, key, from) => {
    setOpenItemIndex(key);
    let updateRowOpen = [...isRowOpen];
    updateRowOpen = isRowOpen.map((res, j) =>
      key === j ? (from === "linkErr" ? true : !res) : false
    );

    setisRowOpen(updateRowOpen);
    setTimeout(() => {
      handleItemClickLeft(key, () => {
        handleItemClickRight(key);
      });
    }, 500);
  };

  //Finding first image to send into API
  const firstImage = () => {
    let indx = null;
    for (let i = 0; i < cmsData.length; i++) {
      if (cmsData[i].item_type === "image") {
        indx = i;
        break;
      }
    }
    return indx;
  };

  //Finding first paragraph to send into API
  const firstPara = () => {
    let indx = null;
    for (let i = 0; i < cmsData.length; i++) {
      if (cmsData[i].item_type === "paragraph") {
        indx = i;
        break;
      }
    }
    return indx;
  };

  // Sending Pages Data to API
  const handleSubmitPostData = (e, status) => {
    e.preventDefault();

    const YouTubeGetID = (link) => {
      let video_id = "";
      video_id = link.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      return video_id[2] !== undefined
        ? video_id[2].split(/[^0-9a-z_\-]/i)[0]
        : video_id[0];
    };

    const VimeoGetID = (link) => {
      let video_id = "";

      var match = /vimeo.*\/(\d+)/i.exec(link);

      if (match) {
        video_id = match[1];
      }
      return video_id;
    };

    const btnSpinnerProcess = () => {
      setIsLoader("d-none");
      setSubmitval("Publish");
      setIsLoader2("d-none");
      setSubmitval2("Save draft");
    };

    if (status === "publish") {
      setIsLoader("d-block");
      setSubmitval("");
    } else if (status === "draft") {
      setIsLoader2("d-block");
      setSubmitval2("");
    }
    if (base64imgThumb) {
      const params = {
        data: base64imgThumb,
        filetype: "png",

        path:
          localStorage.getItem("user_id") +
          "/" +
          localStorage.getItem("Appid") +
          process.env.REACT_APP_CMS_Media,
      };
      uploadAnyBase64Imagetos3(params, listingThumb.replace(".png", ""));

      setBase64imgThumb("");
    }

    let newArr = [...cmsData];
    newArr.map((row, indx) => {
      if (row.item_type === "gallery") {
        if (
          row.portal_value.length >
          parseInt(row.style.column) * parseInt(row.style.row)
        ) {
          row.portal_value.length =
            parseInt(row.style.column) * parseInt(row.style.row);
        }
        row.portal_value &&
          row.portal_value.length > 0 &&
          row.portal_value.map((eachGallery) => {
            // Uploading Resize and cropped image on s3
            if (eachGallery.base64_image && eachGallery.type === 2) {
              const params = {
                data: eachGallery.base64_image,
                filetype: "png",

                path:
                  localStorage.getItem("user_id") +
                  "/" +
                  localStorage.getItem("Appid") +
                  process.env.REACT_APP_CMS_Media,
              };
              uploadAnyBase64Imagetos3(
                params,
                eachGallery.resize_image_link.replace(".png", "")
              );

              eachGallery.base64_image = "";
            }

            //Upoading Original Image on s3

            //  if (
            //   eachGallery.base64_org_image && eachGallery.type===2
            //    ) {
            //      const params = {
            //        data: eachGallery.base64_org_image,
            //        filetype: "png",

            //        path:  localStorage.getItem("user_id") +
            //        "/" +
            //        localStorage.getItem("Appid") +
            //        process.env.REACT_APP_CMS_Media,
            //      };
            //      uploadAnyBase64Imagetos3(params,eachGallery.image_link.replace(".png",""))

            //      eachGallery.base64_org_image="";

            //    }
            eachGallery.base64_org_image = "";
          });
      } else if (row.item_type === "image") {
        if (row.portal_value.base64_image && row.portal_value.type === 2) {
          const params = {
            data: row.portal_value.base64_image,
            filetype: "png",

            path:
              localStorage.getItem("user_id") +
              "/" +
              localStorage.getItem("Appid") +
              process.env.REACT_APP_CMS_Media,
          };
          uploadAnyBase64Imagetos3(
            params,
            row.portal_value.resize_image_link.replace(".png", "")
          );

          row.portal_value.base64_image = "";
        }

        //Upoading Original Image on s3
        // if (
        //   row.portal_value.base64_org_image && row.portal_value.type===2
        //    ) {

        //      const params = {
        //        data: row.portal_value.base64_org_image,
        //        filetype: "png",

        //        path:  localStorage.getItem("user_id") +
        //        "/" +
        //        localStorage.getItem("Appid") +
        //        process.env.REACT_APP_CMS_Media,
        //      };
        //      uploadAnyBase64Imagetos3(params,row.portal_value.image_link.replace(".png",""))

        //      row.portal_value.base64_org_image="";
        //    }
        row.portal_value.base64_org_image = "";
      } else if (row.item_type === "youtube_video") {
        if (!row.portal_value.link) {
          row.portal_value.is_link_err = true;
        } else {
          row.value.youtube_video.video_id = YouTubeGetID(
            row.portal_value.link
          );
        }
      } else if (row.item_type === "vimeo_video") {
        if (!row.portal_value.link) {
          row.portal_value.is_link_err = true;
        } else {
          row.value.vimeo_video.video_id = VimeoGetID(row.portal_value.link);
        }
      } else if (row.item_type === "facebook_video") {
        if (!row.portal_value.link) {
          row.portal_value.is_link_err = true;
        } else {
          row.value.facebook_video.video_url = row.portal_value.link;
          // FBGetID(row.portal_value.link);
        }
      }
    });
    setcmsData(newArr);

    let error = false;
    let error2 = false;
    let error3 = false;
    let emailErr = false;
    let youTubeUrlErr = false;
    let vimeoUrlErr = false;
    let facebookUrlErr = false;

    let finalAtrSelectedArr = [];
    atrListingData &&
      atrListingData.map((res) => {
        let obj = {
          id: "",
          label: "",
          identifier: "",
          terms: [],
          attribute_icon_name: "",
        };

        if (res.is_checked) {
          obj.id = res._id;
          obj.label = res.attribute_name;
          obj.identifier = res.attribute_name;
          obj.terms = [];
          obj.attribute_icon_name = res.attribute_icon_name;
        }
        res.attribute_terms &&
          res.attribute_terms.map((val) => {
            let nestedObj = {
              id: "",
              label: "",
              identifier: "",
            };
            if (val.is_checked) {
              nestedObj.id = val._id;
              nestedObj.label = val.attribute_term_name;
              nestedObj.identifier = val.attribute_term_name;

              obj.terms.push(nestedObj);
            }
          });
        if (res.is_checked) {
          finalAtrSelectedArr.push(obj);
        }
      });

    for (let i = 0; i < cmsData.length; i++) {
      if (cmsData[i].item_type === "gallery") {
        if (
          cmsData[i].style.row === "" ||
          cmsData[i].style.row === undefined ||
          cmsData[i].style.row === null ||
          cmsData[i].style.column === "" ||
          cmsData[i].style.column === undefined ||
          cmsData[i].style.column === null
        ) {
          error = true;
        }
      } else if (cmsData[i].item_type === "spacer") {
        if (
          cmsData[i].style.height === undefined ||
          cmsData[i].style.height === "" ||
          cmsData[i].style.height === null
        ) {
          error3 = true;
        }
      } else if (cmsData[i].item_type === "separator") {
        if (
          cmsData[i].style.height === undefined ||
          cmsData[i].style.height === "" ||
          cmsData[i].style.height === null
        ) {
          error2 = true;
        }
      } else if (cmsData[i].item_type === "button") {
        if (cmsData[i].value.button.link === "email") {
          if (!emailValidator.validate(cmsData[i].value.button.target)) {
            emailErr = true;
          }
        }
      } else if (cmsData[i].item_type === "youtube_video") {
        if (
          cmsData[i].portal_value.is_link_err !== false ||
          !cmsData[i].portal_value.link
        ) {
          youTubeUrlErr = true;
          handleIsRowOpen("", i, "linkErr");
          btnSpinnerProcess();
          break;
        }
      } else if (cmsData[i].item_type === "vimeo_video") {
        if (
          cmsData[i].portal_value.is_link_err !== false ||
          !cmsData[i].portal_value.link
        ) {
          vimeoUrlErr = true;
          handleIsRowOpen("", i, "linkErr");
          btnSpinnerProcess();
          break;
        }
      } else if (cmsData[i].item_type === "facebook_video") {
        if (
          cmsData[i].portal_value.is_link_err !== false ||
          !cmsData[i].portal_value.link
        ) {
          facebookUrlErr = true;
          handleIsRowOpen("", i, "linkErr");
          btnSpinnerProcess();
          break;
        }
      }
    }

    let catIdArr = [];
    let tagIdArr = [];
    catArrForPost &&
      catArrForPost.map((row) => {
        // if(row.$oid){

        //   catIdArr.push(row.$oid);
        // }else{
        catIdArr.push(row._id);
        // }
      });
    tagArrForPost &&
      tagArrForPost.map((row) => {
        // if(row.$oid){

        //   tagIdArr.push(row.$oid);
        // }else{
        tagIdArr.push(row._id);
        // }
      });
    const date = new Date();
    const params = {
      app_id: parseInt(localStorage.getItem("Appid")),
      user_id: parseInt(localStorage.getItem("user_id")),
      author_user_id: authorId,
      override_post_master_settings: overrideMasterSettings,
      // client_id: 389,
      title: title,
      description:
        firstPara() !== null && firstPara() !== undefined
          ? cmsData[firstPara()].value.paragraph.text
          : "",
      content: cmsData,
      // parent: parentId,
      featured_media: "", // cmsData[firstImage()].value.image,
      // source_file:
      //   firstImage() !== null && firstImage() !== undefined
      //     ? cmsData[firstImage()].portal_value.source_file
      //     : "",
      source_file: listingThumb,
      status:
        publishImmediate === true
          ? status
          : status === "draft"
          ? "draft"
          : "scheduled",
      // dateTimeValue === null ||
      // dateTimeValue === "" ||
      // dateTimeValue === undefined
      //   ? status
      //   : status === "draft"
      //   ? status
      //   : "scheduled",
      tags: tagIdArr,
      categories: catIdArr,
      published_at: publishImmediate === true ? date : dateTimeValue,
      published_immediate: publishImmediate,
      post_id:
        postID !== undefined &&
        postID !== null &&
        postID !== "create" &&
        postID !== ""
          ? postID
          : "",
      excerpt: excerpt,
      is_featured: is_featured,
      show_categories: showCat ? 1 : 0,
      show_tags: showTag ? 1 : 0,
      show_author: showAuthor ? 1 : 0,
      related_to: postRelatedTo,
      show_related_posts: showRelatedPosts ? 1 : 0,
      show_publish_date: showPublishDate ? 1 : 0,
      attributes_data: finalAtrSelectedArr,
    };
    const numberRegex = /^\d*$/;


    if (params.title === undefined || params.title.trim() === "") {
      errortoast("Title can't be empty");
      btnSpinnerProcess();
    } else if (error) {
      errortoast("Select atleast one row and one column");
      btnSpinnerProcess();
    } else if (error2) {
      errortoast("Please enter valid seperator height");
      btnSpinnerProcess();
    } else if (error3) {
      errortoast("Please enter valid spacer height");
      btnSpinnerProcess();
    } else if (emailErr) {
      errortoast("Please enter valid email");
      btnSpinnerProcess();
    } else if (dateTimeValue === "") {
      errortoast("Please choose date and time");
      btnSpinnerProcess();
    } else if (youTubeUrlErr) {
      btnSpinnerProcess();
    } else if (facebookUrlErr) {
      btnSpinnerProcess();
    } else if (vimeoUrlErr) {
      btnSpinnerProcess();
    }
    // else if(iapValue?.length<1){
      
    //   errortoast(
    //     "Product ID is a required field."
    //   );
    //   btnSpinnerProcess();
    // }
    // else if (!numberRegex.test(iapValue)) {
    //   errortoast("Product ID must contain numbers only.");
    //   btnSpinnerProcess();

    // }
    else {
      cmsSavePostApi(
        params,
        setIsLoader,
        setIsLoader2,
        setSubmitval,
        setSubmitval2,
        redirectFun,
        status,
        setUpdatedAt
      );
    }
  };

  // Drag an drop
  const reorderList = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.map((row, indx) => {
      row.position = indx;
    });
    return result;
  };

  const handleDragEnd = (result) => {
    if (
      result.destination === null ||
      result.destination === undefined ||
      result.source === undefined
    ) {
      return;
    } else {
      setcmsData(
        reorderList(cmsData, result.source.index, result.destination.index)
      );
      initialLoad(
        "drag",
        result.destination.index,
        isRowOpen[result.source.index]
      );
    }
  };

  //Rendering specific(selected) block
  const handleSwitch = (cmsRow, indx, provided) => {
    switch (cmsRow.item_type) {
      case "title":
        return (
          <Title
            indx={indx}
            cmsArray={cmsData}
            setcmsData={setcmsData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            // eachBlock={cmsRow}
            provided={provided}
            from={"post"}
            title={title}
            setTitle={setTitle}
          />
        );
      case "image":
        return (
          <ImageBlock
            indx={indx}
            cmsArray={cmsData}
            setcmsData={setcmsData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={deleteRow}
            duplicateItem={duplicateItem}
            eachBlock={cmsRow}
            provided={provided}
            searchValForPopUp={searchValForPopUp}
            setSearchValForPopUp={setSearchValForPopUp}
            parentComponent={"post"}
            setTempListingThumb={setTempListingThumb}
            setListingThumb={setListingThumb}
            listingThumb={listingThumb}
            fromState={fromState}
            setThumbnailActive={setThumbnailActive}
            thumbnailActive={thumbnailActive}
            setBase64imgThumb={setBase64imgThumb}
          />
        );

      case "heading":
        return (
          <HeadingBlock
            indx={indx}
            cmsArray={cmsData}
            setcmsData={setcmsData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={deleteRow}
            duplicateItem={duplicateItem}
            eachBlock={cmsRow}
            provided={provided}
          />
        );

      case "paragraph":
        return (
          <ParagraphBlock
            indx={indx}
            cmsArray={cmsData}
            setcmsData={setcmsData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={deleteRow}
            duplicateItem={duplicateItem}
            value={value}
            setValue={setValue}
            eachBlock={cmsRow}
            provided={provided}
          />
        );

      case "spacer":
        return (
          <SpacerBlock
            indx={indx}
            cmsArray={cmsData}
            setcmsData={setcmsData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={deleteRow}
            duplicateItem={duplicateItem}
            eachBlock={cmsRow}
            provided={provided}
          />
        );

      case "separator":
        return (
          <SeparatorBlock
            indx={indx}
            cmsArray={cmsData}
            setcmsData={setcmsData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={deleteRow}
            duplicateItem={duplicateItem}
            eachBlock={cmsRow}
            provided={provided}
          />
        );

      case "button":
        return (
          <ButtonBlock
            indx={indx}
            cmsArray={cmsData}
            setcmsData={setcmsData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={deleteRow}
            duplicateItem={duplicateItem}
            eachBlock={cmsRow}
            provided={provided}
            setIapValue={setIapValue}
            iapValue={iapValue}
            from="Post"
          />
        );

      case "gallery":
        return (
          <GalleryBlock
            indx={indx}
            cmsArray={cmsData}
            setcmsData={setcmsData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={deleteRow}
            duplicateItem={duplicateItem}
            galleryToggle={galleryToggle}
            setgalleryToggle={setgalleryToggle}
            galleryTab={galleryTab}
            setgalleryTab={setgalleryTab}
            eachBlock={cmsRow}
            galleryToggleUpdate={galleryToggleUpdate}
            // dummyArr={dummyArr}
            // setDummyArr={setDummyArr}
            searchValForPopUp={searchValForPopUp}
            setSearchValForPopUp={setSearchValForPopUp}
            provided={provided}
          />
        );

      case "code":
        return (
          <CodeBlock
            indx={indx}
            cmsArray={cmsData}
            setcmsData={setcmsData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={deleteRow}
            duplicateItem={duplicateItem}
            eachBlock={cmsRow}
            provided={provided}
          />
        );
      case "custom_video":
        return (
          <VideoBlock
            indx={indx}
            cmsArray={cmsData}
            setcmsData={setcmsData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={deleteRow}
            duplicateItem={duplicateItem}
            eachBlock={cmsRow}
            provided={provided}
            searchValForPopUp={searchValForPopUp}
            setSearchValForPopUp={setSearchValForPopUp}
            from={"page"}
          />
        );
      case "facebook_video":
        return (
          <FacebookVideo
            indx={indx}
            cmsArray={cmsData}
            setcmsData={setcmsData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={deleteRow}
            duplicateItem={duplicateItem}
            eachBlock={cmsRow}
            provided={provided}
          />
        );
      case "youtube_video":
        return (
          <YouTubeVideoBlock
            indx={indx}
            cmsArray={cmsData}
            setcmsData={setcmsData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={deleteRow}
            duplicateItem={duplicateItem}
            eachBlock={cmsRow}
            provided={provided}
          />
        );
      case "vimeo_video":
        return (
          <VimeoVideoBlock
            indx={indx}
            cmsArray={cmsData}
            setcmsData={setcmsData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={deleteRow}
            duplicateItem={duplicateItem}
            eachBlock={cmsRow}
            provided={provided}
          />
        );
      case "attribute":
        return (
          <AttributeBlock
            indx={indx}
            cmsArray={cmsData}
            setcmsData={setcmsData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={deleteRow}
            duplicateItem={duplicateItem}
            eachBlock={cmsRow}
            provided={provided}
          />
        );
      case "banner_ad":
        return (
          <BannerAdBlock
            indx={indx}
            cmsArray={cmsData}
            setcmsData={setcmsData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={deleteRow}
            duplicateItem={duplicateItem}
            eachBlock={cmsRow}
            adsData={adsData}
            setAdsData={setAdsData}
            provided={provided}
          />
        );
      case "custom_ad":
        return (
          <CustomAdBlock
            indx={indx}
            cmsArray={cmsData}
            setcmsData={setcmsData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={deleteRow}
            duplicateItem={duplicateItem}
            eachBlock={cmsRow}
            adsData={adsData}
            setAdsData={setAdsData}
            provided={provided}
          />
        );
    }
  };

  // Featured Toggle
  const handleIsFeatured = (e) => {
    setIsFeatured(e.target.checked);
  };

  const handleOpenAttributeDropdown = (e, from) => {
    setOpenAttributeDropdown(!openAttributeDropdown);
  };

  const handleMobileAttributeDropdown = () => {
    setOpenMobAttributeDropdown(!openMobAttributeDropdown);
  };

  // Date Time Picker
  const handleDateTimePicker = (dateTime) => {
    setDateTimeValue(dateTime);
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  // Redirect fun
  const redirectFun = (post_id, status) => {
    if (status === "draft") {
      props.history.push(`/app/post/${post_id}`);
      setStatusOfPost(status);
    } else {
      props.history.push("/app/posts");
    }
  };

  //Delete Tag or Category chunk
  const handleDelCat = (e, indx, row) => {
    let newArr = [...catArrForPost];
    newArr.splice(indx, 1);
    setCatArrForPost(newArr);

    let newCatSelection = [...tempCatSelection];
    newCatSelection = newCatSelection.filter((res) => res !== row._id);
    setTempCatSelection(newCatSelection);
    setCatSelection(newCatSelection);

    e.stopPropagation();
  };

  const handleDelTag = (e, indx, row) => {
    let newArr = [...tagArrForPost];
    newArr.splice(indx, 1);
    setTagArrForPost(newArr);

    let newTagSelection = [...tempTagSelection];
    newTagSelection = newTagSelection.filter((res) => res !== row._id);
    setTempTagSelection(newTagSelection);
    setTagSelection(newTagSelection);

    e.stopPropagation();
  };

  const handleMobileTagDropdown = () => {
    setOpenMobileTagDropdown(!openMobileTagDropdown);
  };

  const handleAddAttribute = (e, atrId) => {
    let newArr = [...atrListingData];

    newArr &&
      newArr.map((res) => {
        if (res._id === atrId) {
          res.is_checked = true;
        }
        res.attribute_terms &&
          res.attribute_terms.map((val) => {
            if (val._id === atrId) {
              val.is_checked = true;
              res.is_checked = true;
            }
          });
      });
    setAtrListingData(newArr);
  };

  const handleDelAttribute = (e, atrId) => {
    let newArr = [...atrListingData];
    let newAtrSelection = [];

    newArr &&
      newArr.map((res) => {
        if (res._id === atrId) {
          res.is_checked =
            res.is_checked === undefined || res.is_checked === null
              ? true
              : !res.is_checked;

          res.is_checked === false &&
            res.attribute_terms &&
            res.attribute_terms.map((val) => {
              val.is_checked = false;
            });
        }
      });

    newArr &&
      newArr.map((res1) => {
        if (res1.is_checked) {
          newAtrSelection.push(res1._id);
        }

        res1.attribute_terms &&
          res1.attribute_terms.map((val) => {
            if (val.is_checked) {
              newAtrSelection.push(val._id);
            }
          });
      });
    setAtrSelection(newAtrSelection);
    setTempAtrSelection(newAtrSelection);
    setAtrListingData(newArr);
    e.stopPropagation();
  };

  const countNoOfValAtr = (atrId) => {
    let no = 0;
    for (let i = 0; i < atrListingData.length; i++) {
      if (atrListingData[i]._id === atrId) {
        atrListingData[i].attribute_terms &&
          atrListingData[i].attribute_terms.map((val) => {
            if (val.is_checked === true) {
              no++;
            }
          });
        break;
      }
    }

    return no;
  };

  const isAtrAssign = () => {
    let isAssign = false;
    for (let i = 0; i < atrListingData.length; i++) {
      if (atrListingData[i].is_checked === true) {
        isAssign = true;
        break;
      }
      //    atrListingData[i].attribute_terms && atrListingData[i].attribute_terms.map((val)=>{
      //     if(val.is_checked===true){
      //       isAssign=true;
      //     }

      //  })
      //  break;
    }

    return isAssign;
  };

  const handleAddTagDrop = (e, obj, position) => {
    let newArr = [...tagArrForPost];

    const updatedCheckedState = tagCheckedArr.map((item, index) =>
      index === position ? !item : item
    );

    setTagCheckedArr(updatedCheckedState);

    if (e.target.checked) {
      newArr.push(obj);
      setTagArrForPost(newArr);
    } else {
      for (let i = 0; i < newArr.length; i++) {
        if (newArr[i]._id === obj._id) {
          newArr.splice(i, 1);
          break;
        }
      }
      setTagArrForPost(newArr);
    }
  };

  //Handle Immediate Publish Toggle
  const handleImmediateToggle = (e) => {
    const date = new Date();
    setPublishImmediate(e.target.checked);
    if (e.target.checked === true) {
      setDateTimeValue(date);
    } else {
      setDateTimeValue("");
    }
  };

  const handleShowCatToggle = (e) => {
    setOverrideMasterSettings(1);
    if (e.target.checked) {
      setShowCat(true);
    } else {
      setShowCat(false);
    }

    if (!window.matchMedia("(max-width: 1200px)").matches) {
      setTimeout(() => {
        const itemElement = showCatRef?.current;
        if (itemElement) {
          itemElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      });
    }
  };

  const handleShowTagToggle = (e) => {
    setOverrideMasterSettings(1);
    if (e.target.checked) {
      setShowTag(true);
    } else {
      setShowTag(false);
    }

    if (!window.matchMedia("(max-width: 1200px)").matches) {
      setTimeout(() => {
        const itemElement = showTagRef?.current;
        if (itemElement) {
          itemElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      });
    }
  };
  const handleShowAuthorToggle = (e) => {
    setOverrideMasterSettings(1);
    if (e.target.checked) {
      setShowAuthor(true);
    } else {
      setShowAuthor(false);
    }
    if (!window.matchMedia("(max-width: 1200px)").matches) {
      const previewItemElement = rightSideRef?.current?.children[0];

      if (previewItemElement) {
        previewItemElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  };

  const handleShowPublishDate = (e) => {
    setOverrideMasterSettings(1);
    if (e.target.checked) {
      setShowPublishDate(true);
    } else {
      setShowPublishDate(false);
    }
    if (!window.matchMedia("(max-width: 1200px)").matches) {
      const previewItemElement = rightSideRef?.current?.children[0];

      if (previewItemElement) {
        previewItemElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  };

  const handleShowRelatedPosts = (e) => {
    setOverrideMasterSettings(1);
    if (e.target.checked) {
      setShowRelatedPosts(true);
    } else {
      setShowRelatedPosts(false);
    }
    if (!window.matchMedia("(max-width: 1200px)").matches) {
      setTimeout(() => {
        const itemElement = showRelatedPostsRef?.current;
        if (itemElement) {
          itemElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      });
    }
  };

  const handleRelatedToPopUp = (e) => {
    setIsRelatedToPopup(!isRelatedToPopup);
  };

  const handleMobileRelatedToDropdown = (e) => {
    setOpenMobileRelatedToDropdown(!openMobileRelatedToDropdown);
  };

  const handleAuthorPopUp = (e) => {
    setIsAuthorPopup(!isAuthorPopup);
  };

  const handleRelatedToChange = (e, value) => {
    setOverrideMasterSettings(1);
    setPostRelatedTo(value);
    setOpenMobileRelatedToDropdown(false);
  };

  const handleAuthorChange = (e, value) => {
    setAuthorId(value);
    setOpenMobileAuthorDropdown(false);
    // setPostRelatedTo(value)
  };
  const returnSelectedAuthorName = (author_id) => {
    let name = "";
    mergeUserOwnerData &&
      mergeUserOwnerData.map((res) => {
        if (res.user_id === author_id) {
          name = res.name;
        }
      });
    return name;
  };

  const returnSelectedImagePath = (author_id) => {
    let path = "";
    mergeUserOwnerData &&
      mergeUserOwnerData.map((res) => {
        if (res.user_id === author_id) {
          path =
            res.profile_image && res.profile_image.indexOf("https") > -1
              ? res.profile_image
              : process.env.REACT_APP_Image_Path +
                res.user_id +
                process.env.REACT_APP_Profile +
                res.profile_image;
        }
      });
    return path;
  };

  const handleMobileAuthorDropdown = (e) => {
    setOpenMobileAuthorDropdown(!openMobileAuthorDropdown);
  };

  const handleAdvancedSetting = () => {
    setOpenAdvanceSetting(!openAdvanceSetting);
  };
  const handleResetAdvancedSetting = () => {
    setOpenAdvnaceSetting(!openAdvanceSetting);
    setOverrideMasterSettings(0);
    const params = {
      app_id: btoa(localStorage.getItem("Appid")),
    };
    setResetSpinner(true);
    GetpostapiForFunctionalCompo(
      params,
      setShowSearch,
      setShowGridView,
      setShowAuthor,
      setShowPublishDate,
      setShowCat,
      setShowTag,
      setShowRelatedPosts,
      setPostRelatedTo,
      setResetSpinner,
      false,
      false,
      false,
      false,
      false,

      setImgCenterForDetails,
      setImgCenterForListing,
      setImgRatioForDetails,
      setImgRatioForListing,
      setImgShapeForDetails,
      setImgShapeForListing,
      setSectionTopMargin,
      setSectionBottomMargin,
      setSectionLeftMargin,
      setSectionRightMargin,

      postState,
      setPostState
    );
  };

  const handleRightPanelExpand = (value) => {
    setAppRightPanelExpand(value);
  };

  const imageGalleryPopup = (e,from) => {
    setIsGalleryPopup(!isGalleryPopup);
    if(from === "thumbnail"){
      setFromState("thumbnail")
    }
  };

  const handleCancelImage = (e) => {
    setBase64imgThumb("");
    setTempListingThumb("");
    setListingThumb("");
    setThumbnailActive(false)
  };

  const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };

  const settingYourdata = async (
    imgName,
    from,
    orgFileName,
    url1200,
    useUrl,
    uploadedFileBase64,
    croppedImageForCms
  ) => {
    
    const resizeImgName =
      "ams_resize_" +
      new Date().getTime() +
      "_" +
      Math.floor(1000 + Math.random() * 9000) +
      ".png";

    if (from === "resize") {
      setBase64imgThumb(await blobToBase64(croppedImageForCms));

      setTempListingThumb(imgName);

      setListingThumb(resizeImgName);
      if(fromState === "thumbnail"){
        setThumbnailActive(true)
      }
    } else if (from === "apiImg") {
      setTempListingThumb(imgName);
      setBase64imgThumb("");
      setListingThumb(url1200);
      if(fromState === "thumbnail"){
        setThumbnailActive(true)
      }
    }
  };

  const yourErrFun = () => {
    // let newArr = [...cmsArray];
    // newArr[indx].portal_value.source_file = prevImgUrl;
    // setcmsData(newArr);
  };

  const loadpicVistaCreatePopUp = (data) => {
    //  let newArr = [...cmsArray];
    //  newArr[indx].portal_value.source_file = data.url;
    //     newArr[indx].portal_value.resize_image_link = data.url;

    //     newArr[indx].portal_value.image_link = "";
    //     newArr[indx].portal_value.type = 1;
    //     newArr[indx].portal_value.base64_image = ""
    //     newArr[indx].portal_value.base64_org_image = ""
    //     newArr[indx].portal_value.design_id = data.id;

    //  setcmsData(newArr);
    setIsGalleryPopup(false);
    setTempListingThumb(data.url);
    setListingThumb(data.url);
    setBase64imgThumb("");
  };

  const closeVistaCreatePopUp = () => {};

  const handleOpenCatSelectionPopUp = (from) => {
    if (from === "cancel") {
      setCatSubData(oldCatSubData);
      setCatBreadcrumbArr(oldCatBreadcrumbArr);
      setTempCatSelection(catSelection);
    } else {
      if (from === "open") {
        if (!catSubData || catSubData.length === 0) {
          setCatSubData(categoryData);
        }
        setCatSelection(tempCatSelection);

        setOldCatSubData(catSubData);
        setOldCatBreadcrumbArr(catBreadcrumbArr);
        let newCatArrForPost = [];
        function recursNew(arr) {
          arr.map((eachCat) => {
            let obj = {
              name: "",
              _id: "",
            };
            for (let i = 0; i < tempCatSelection.length; i++) {
              if (eachCat._id === tempCatSelection[i]) {
                obj._id = eachCat._id;
                obj.name = eachCat.name;
                newCatArrForPost.push(obj);
                break;
              }
            }

            if (eachCat.child !== undefined && eachCat.child.length > 0) {
              // if parent has childrens
              recursNew(eachCat.child);
            }

            // return arr;
          });
        }

        recursNew(categoryData);

        if (tempCatSelection.length === newCatArrForPost.length) {
          setCatArrForPost(newCatArrForPost);
        }
      }
    }
    setOpenCatMultiSelectionPopUp(!openCatMultiSelectionPopUp);
  };

  const handleCatMultiSelectionChange = (e, pID, indx) => {
    // let newArr = JSON.parse(JSON.stringify(tempCatSelection));
    let newArr = [...tempCatSelection];

    if (!e.target.checked) {
      newArr = newArr && newArr.filter((res) => res !== pID);
    } else {
      newArr.push(pID);
    }

    setTempCatSelection(newArr);
  };

  const handleOpenAtrSelectionPopUp = (from) => {
    if (from === "cancel") {
      setAtrSubData(oldAtrSubData);
      setAtrBreadcrumbArr(oldAtrBreadcrumbArr);
      setTempAtrSelection(atrSelection);
    } else {
      if (from === "open") {
        if (!atrSubData || atrSubData.length === 0) {
          setAtrSubData(atrListingData);
        }
        setAtrSelection(tempAtrSelection);

        setOldAtrSubData(atrSubData);
        setOldAtrBreadcrumbArr(atrBreadcrumbArr);

        let newArr1 = [...atrListingData];

        newArr1 &&
          newArr1.map((res1) => {
            res1.is_checked = false;
            res1.attribute_terms &&
              res1.attribute_terms.map((row1) => {
                row1.is_checked = false;
              });
          });
        for (let i = 0; i < tempAtrSelection.length; i++) {
          handleAddAttribute("", tempAtrSelection[i]);
          // handleAddAttributeVal("",tempAtrSelection[i]);
        }
      }
    }
    setOpenAtrMultiSelectionPopUp(!openAtrMultiSelectionPopUp);
  };

  const handleAtrMultiSelectionChange = (e, res1, indx) => {
    // let newArr = JSON.parse(JSON.stringify(tempCatSelection));
    let newArr = [...tempAtrSelection];

    if (!e.target.checked) {
      if (res1.attribute_icon_name !== undefined) {
        for (let i = 0; i < newArr.length; i++) {
          if (res1._id === newArr[i]) {
            newArr.splice(i, 1);
          }
        }
        res1.attribute_terms &&
          res1.attribute_terms.map((val1) => {
            for (let i = 0; i < newArr.length; i++) {
              if (val1._id === newArr[i]) {
                newArr.splice(i, 1);
              }
            }
          });
      } else {
        newArr = newArr && newArr.filter((res) => res !== res1._id);
      }

      // newArr = newArr && newArr.filter((res)=>res!==res1._id);
    } else {
      newArr.push(res1._id);
      if (res1.attribute) {
        newArr.push(res1.attribute);
      }
    }

    setTempAtrSelection(newArr);
  };
  const handleOpenTagSelectionPopUp = (from) => {
    if (from === "cancel") {
      setTagSubData(oldTagSubData);
      setTagBreadcrumbArr(oldTagBreadcrumbArr);
      setTempTagSelection(tagSelection);
    } else {
      if (from === "open") {
        if (!tagSubData || tagSubData.length === 0) {
          setTagSubData(tagData);
        }
        setTagSelection(tempTagSelection);

        setOldTagSubData(tagSubData);
        setOldTagBreadcrumbArr(tagBreadcrumbArr);
        let newTagArrForPost = [];

        tagData &&
          tagData.map((eachTag) => {
            let obj = {
              name: "",
              _id: "",
            };
            for (let i = 0; i < tempTagSelection.length; i++) {
              if (eachTag._id === tempTagSelection[i]) {
                obj._id = eachTag._id;
                obj.name = eachTag.name;
                newTagArrForPost.push(obj);
                break;
              }
            }
          });

        if (tempTagSelection.length === newTagArrForPost.length) {
          setTagArrForPost(newTagArrForPost);
        }
      }
    }
    setOpenTagMultiSelectionPopUp(!openTagMultiSelectionPopUp);
  };

  const handleTagMultiSelectionChange = (e, pID, indx) => {
    // let newArr = JSON.parse(JSON.stringify(tempCatSelection));
    let newArr = [...tempTagSelection];

    if (!e.target.checked) {
      newArr = newArr && newArr.filter((res) => res !== pID);
    } else {
      newArr.push(pID);
    }

    setTempTagSelection(newArr);
  };

  const handleBottomScrollListnerForCat = () => {
    if (paginationSpinnerForCat === false) {
      if (hitNextPaginationForCat) {
        let limit = 99;
        let pageNo = pageForCat + 1;

        setPageForCat(pageNo);
        setPaginationSpinnerForCat(true);

        cmsgetCategoryApi(
          setCategoryData,
          btoa(localStorage.getItem("Appid")),
          setSpinner3,
          false,
          false,
          setCatCheckedArr,
          categoryData,
          false,
          limit,
          pageNo,
          categoryData,
          setHitNextPaginationForCat,
          setPaginationSpinnerForCat,
          setCatSubData
        );
      }
    }
  };

  const handleBottomScrollListnerForTag = () => {
    if (paginationSpinnerForTag === false) {
      if (hitNextPaginationForTag) {
        let limit = 99;
        let pageNo = pageForTag + 1;

        setPageForTag(pageNo);
        setPaginationSpinnerForTag(true);

        cmsgetTagsApi(
          setTagData,
          btoa(localStorage.getItem("Appid")),
          setSpinner3,
          false,
          setTagCheckedArr,
          tagData,
          limit,
          pageNo,
          tagData,
          setHitNextPaginationForTag,
          setPaginationSpinnerForTag,
          setTagSubData
        );
      }
    }
  };

  const handleBottomScrollListnerForAtr = () => {
    if (paginationSpinnerForAtr === false) {
      if (hitNextPaginationForAtr) {
        let limit = 99;
        let pageNo = pageForAtr + 1;

        setPageForAtr(pageNo);
        setPaginationSpinnerForAtr(true);

        cmsGetAtrApi(
          setDuumySpin1,
          setAtrListingData,
          setDuumySpin2,
          "edit-post",
          atrSelection,
          false,
          limit,
          pageNo,
          atrListingData,
          setHitNextPaginationForAtr,
          setPaginationSpinnerForAtr,
          setAtrSubData
        );
      }
    }
  };

  useMemo(() => initialLoad(), [cmsData.length]);

  return (
    <>
      <div className=" d-md-block">
        <Header customclass={"header-responsive-new"} />
      </div>
      {/* Mobile View */}
      {/* Mobile View End*/}
      <section className="dasboard_page cms-module mobile-display mt-4 mt-md-0">
        <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
        {/* <div className="transparent_background"> */}
        <div
          className={`right-panel-gap ${
            localStorage.getItem("appSideBarCollapse") !== undefined &&
            localStorage.getItem("appSideBarCollapse") !== null &&
            localStorage.getItem("appSideBarCollapse") === "true"
              ? " right-panel-gap-expand"
              : ""
          }`}
        >
          <div className="pageshead">
            <div>
              <h1>
                {postID !== undefined &&
                postID !== null &&
                postID !== "create" &&
                postID !== ""
                  ? "Edit post"
                  : "New post"}
              </h1>
            </div>
            <div className="createpage">
              {updatedAt && (
                <p className="profile-latest-update  d-none d-xl-block">
                  Updated {moment(updatedAt).format("D MMMM YYYY, HH:mm")}
                </p>
              )}
              <div>
                <div>
                  {activeTab === true ? (
                    //     <div className="consent-setting-clicked" style={{marginRight: "20px",cursor:"default"}}>
                    //             <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                    //        <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                    //     <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                    //     <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="#7782a1" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                    //   </g>
                    // </svg>
                    //             </div>
                    <></>
                  ) : (
                    <div
                      className="notification-setting "
                      style={{ marginRight: "20px" }}
                      onClick={() => {
                        toggle();
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                      >
                        <g
                          id="Group_42975"
                          data-name="Group 42975"
                          transform="translate(-20 -527)"
                        >
                          <rect
                            id="Rectangle_25572"
                            data-name="Rectangle 25572"
                            width="26"
                            height="26"
                            transform="translate(20 527)"
                            fill="none"
                          />
                          <path
                            id="Path_119115"
                            data-name="Path 119115"
                            d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z"
                            transform="translate(22.5 529.36)"
                            fill="none"
                            stroke="#7782a1"
                            stroke-miterlimit="10"
                            stroke-width="1.5"
                          />
                        </g>
                      </svg>
                    </div>
                  )}

                  <div
                          onClick={() => {
                            toggle();
                          }}
                          className="cms-editpage-btn"
                        >
                          <SettingIcon
                           
                           isActive={!activeTab}
                          />
                        </div>
                  
                  <button
                    onClick={() => {
                      setShowhowMobilPreview(!showMobilPreview);
                    }}
                    className="cmspreviewbtn d-none"
                  >
                    Preview
                  </button>
                  <button
                    disabled={isLoader2 === "d-block" || isLoader === "d-block"}
                    className={`d-none d-xl-flex ${
                      isLoader === "d-block"
                        ? "saveasdraft-disable"
                        : "saveasdraft"
                    }`}
                    onClick={(e) => handleSubmitPostData(e, "draft")}
                    style={{
                      // backgroundColor:localStorage.getItem('button_background_color'),
                      border:
                        "1px solid " +
                        localStorage.getItem("button_background_color"),
                      color: localStorage.getItem("button_text_color"),
                      "--custom_color5":
                        localStorage.getItem("agencyid") == 1
                          ? "#134bec"
                          : localStorage.getItem("button_hover_bgcolor"),
                      "--custom_color4":
                        localStorage.getItem("agencyid") == 1
                          ? "white"
                          : localStorage.getItem("button_hover_txtcolor"),
                      "--custom_color6":
                        localStorage.getItem("agencyid") == 1
                          ? "#3064f9"
                          : localStorage.getItem("button_hover_bgcolor"),
                    }}
                  >
                    <div className={isLoader2}>
                      <div className="new-blue-loader"></div>
                    </div>
                    <div>
                      <p
                        style={{
                          color: localStorage.getItem(
                            "button_background_color"
                          ),
                        }}
                      >
                        {window.matchMedia("(max-width: 1200px)").matches
                          ? isLoader2 === "d-none"
                            ? "Save"
                            : ""
                          : submitval2}
                      </p>
                    </div>
                  </button>

                    <button
                    disabled={isLoader2 === "d-block" || isLoader === "d-block"}
                    className={`d-none d-xl-flex ${
                      isLoader2 === "d-block" ? "creatnew-disable" : "createnew"
                    }`}
                    onClick={(e) => {
                      
                  
                        handleSubmitPostData(e, "publish");
                    
                    }}
                    style={{
                      backgroundColor: localStorage.getItem(
                        "button_background_color"
                      ),
                      border:
                        "1px solid " +
                        localStorage.getItem("button_background_color"),
                      color: localStorage.getItem("button_text_color"),
                      "--custom_color5":
                        localStorage.getItem("agencyid") == 1
                          ? "#134bec"
                          : localStorage.getItem("button_hover_bgcolor"),
                      "--custom_color4":
                        localStorage.getItem("agencyid") == 1
                          ? "white"
                          : localStorage.getItem("button_hover_txtcolor"),
                      "--custom_color6":
                        localStorage.getItem("agencyid") == 1
                          ? "#3064f9"
                          : localStorage.getItem("button_hover_bgcolor"),
                    }}
                  >
                    <div className={isLoader}>
                      <div className="new-loader"></div>
                    </div>
                    <div>
                      <span
                        style={{
                          marginTop: "2px",
                        }}
                      >
                        {statusOfPost === "publish"
                          ? isLoader === "d-none"
                            ? "Save"
                            : ""
                          : submitval}
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="whitebg cmspagelayout">
            <div className="custom-page-preview">
              <div className="row">
                {((showMobilPreview === false &&
                  window.matchMedia("(max-width: 1200px)").matches) ||
                  window.matchMedia("(min-width: 1200px)").matches) && (
                  <div className="col-md-12 col-xl-6">
                    <div
                      className="page-editor post-setting-page-editor"
                      style={{ position: "relative" }}
                    >
                      {spinner === true ? (
                        <div className="newspinner">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                            style={{
                              margin: "auto",
                              background: "rgb(241, 242, 243,0)",
                              display: "block",
                              shaperendering: "auto",
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                            width="100px"
                            height="100px"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="xMidYMid"
                          >
                            <g
                              transform="translate(20 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#e6261f"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.375s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(40 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#f7d038"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.25s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(60 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#49da9a"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.125s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(80 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#4355db"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="0s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                          </svg>
                        </div>
                      ) : (
                        <div>
                          <div className="pages-options">
                            <Modal
                              isOpen={showcustomBlockpopup}
                              className="addblockpopup"
                              centered
                              toggle={customBlock}
                            >
                              <ModalHeader toggle={customBlock}>
                                <div className="crossmodal mt-1">
                                  <svg
                                    onClick={customBlock}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10.96"
                                    height="10.961"
                                    viewBox="0 0 10.96 10.961"
                                  >
                                    <g
                                      id="Group_38368"
                                      data-name="Group 38368"
                                      transform="translate(-1914.917 -169.92)"
                                    >
                                      <path
                                        id="Path_82532"
                                        data-name="Path 82532"
                                        d="M-16354-3830v14"
                                        transform="translate(10781.152 14442.694) rotate(45)"
                                        fill="none"
                                        stroke="#c8cedb"
                                        stroke-width="1.5"
                                      />
                                      <path
                                        id="Path_82533"
                                        data-name="Path 82533"
                                        d="M-16354-3830v14"
                                        transform="translate(-12346.896 9036.155) rotate(135)"
                                        fill="none"
                                        stroke="#c8cedb"
                                        stroke-width="1.5"
                                      />
                                    </g>
                                  </svg>
                                </div>
                                Add section
                              </ModalHeader>
                              <ModalBody toggle={customBlock}>
                                <FormGroup
                                  tag="fieldset"
                                  className="select-block-type"
                                >
                                  <FormGroup>
                                    <Label className="addblock-ratio">
                                      <Input
                                        type="radio"
                                        name="addblockradio"
                                        value="heading"
                                        onClick={(e) => handleItem(e)}
                                      />

                                      <div className="image">
                                        <div className="d-block text-center">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20.003"
                                            height="21"
                                            viewBox="0 0 20.003 21"
                                          >
                                            <path
                                              id="Union_1758"
                                              data-name="Union 1758"
                                              d="M10456,17357v-3h2v-15h3v15h2v3Zm-13,0v-3h2v-15h3v6h10v3h-10v6h2v3Zm13-18v-3h7v3Zm-13,0v-3h7v3Z"
                                              transform="translate(-10442.999 -17336)"
                                              fill=""
                                            />
                                          </svg>
                                          <p>Heading</p>
                                        </div>
                                      </div>
                                    </Label>
                                    <span className="block-type-tooltip1">
                                      Highlight important sections with clear
                                      and bold headings.
                                    </span>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label className="addblock-ratio">
                                      <Input
                                        type="radio"
                                        name="addblockradio"
                                        value="paragraph"
                                        onClick={(e) => handleItem(e)}
                                      />
                                      <div className="image">
                                        <div className="d-block text-center">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="19"
                                            height="22"
                                            viewBox="0 0 19 22"
                                          >
                                            <path
                                              id="Union_176"
                                              data-name="Union 176"
                                              d="M4.75,22V18.857H7.917V3.143H3.167V6.285H0V0H19V6.285H15.833V3.143h-4.75V18.857H14.25V22Z"
                                              fill=""
                                            />
                                          </svg>

                                          <p>Paragraph</p>
                                        </div>
                                      </div>
                                    </Label>
                                    <span className="block-type-tooltip1">
                                      Add a block of text to describe your
                                      product or service.
                                    </span>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label className="addblock-ratio">
                                      <Input
                                        type="radio"
                                        name="addblockradio"
                                        value="image"
                                        onClick={(e) => handleItem(e)}
                                      />
                                      <div className="image">
                                        <div className="d-block text-center">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="25.001"
                                            height="22"
                                            viewBox="0 0 25.001 22"
                                          >
                                            <path
                                              id="Exclusion_356"
                                              data-name="Exclusion 356"
                                              d="M22.657,22H2.343A2.4,2.4,0,0,1,0,19.556V7.333a2.408,2.408,0,0,1,1.96-2.41l3.386-.589,1.072-2.8A2.337,2.337,0,0,1,8.593,0h7.813a2.338,2.338,0,0,1,2.176,1.536l1.072,2.8,3.387.589A2.407,2.407,0,0,1,25,7.333V19.556A2.4,2.4,0,0,1,22.657,22ZM12.413,6.875a5.469,5.469,0,1,0,5.469,5.469A5.475,5.475,0,0,0,12.413,6.875Zm0,8.751a3.282,3.282,0,1,1,3.281-3.281A3.285,3.285,0,0,1,12.413,15.626Z"
                                              transform="translate(0.001)"
                                              fill=""
                                            />
                                          </svg>

                                          <p>Image</p>
                                        </div>
                                      </div>
                                    </Label>
                                    <span className="block-type-tooltip2">
                                      Upload an image to enhance the visual
                                      appeal of your content.
                                    </span>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label className="addblock-ratio">
                                      <Input
                                        type="radio"
                                        name="addblockradio"
                                        value="gallery"
                                        onClick={(e) => handleItem(e)}
                                      />
                                      <div className="image">
                                        <div className="d-block text-center">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="25.001"
                                            height="25.003"
                                            viewBox="0 0 25.001 25.003"
                                          >
                                            <path
                                              id="Union_1754"
                                              data-name="Union 1754"
                                              d="M15.061,25a1,1,0,0,1-1-1V15.062a1,1,0,0,1,1-1H24a1,1,0,0,1,1,1V24a1,1,0,0,1-1,1ZM1,25a1,1,0,0,1-1-1V15.062a1,1,0,0,1,1-1H9.935a1,1,0,0,1,1,1V24a1,1,0,0,1-1,1ZM15.061,10.936a1,1,0,0,1-1-1V1a1,1,0,0,1,1-1H24a1,1,0,0,1,1,1V9.936a1,1,0,0,1-1,1ZM1,10.936a1,1,0,0,1-1-1V1A1,1,0,0,1,1,0H9.935a1,1,0,0,1,1,1V9.936a1,1,0,0,1-1,1Z"
                                              transform="translate(0.003 0.003)"
                                              fill=""
                                            />
                                          </svg>
                                          <p>Gallery</p>
                                        </div>
                                      </div>
                                    </Label>
                                    <span className="block-type-tooltip1">
                                      Showcase multiple images in a creative
                                      gallery format.
                                    </span>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label className="addblock-ratio">
                                      <Input
                                        type="radio"
                                        name="addblockradio"
                                        value="custom_video"
                                        onClick={(e) => handleItem(e)}
                                      />
                                      <div className="image">
                                        <div className="d-block text-center">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="32.003"
                                            height="21.998"
                                            viewBox="0 0 32.003 21.998"
                                          >
                                            <path
                                              id="Subtraction_156"
                                              data-name="Subtraction 156"
                                              d="M11989,19084h-20a2,2,0,0,1-2-2v-18a2,2,0,0,1,2-1.992h20a2,2,0,0,1,2,1.992V19070l6.4-4.8a.977.977,0,0,1,.6-.205,1.006,1.006,0,0,1,1,1v14a1,1,0,0,1-1,1,.993.993,0,0,1-.6-.2l-6.4-4.8v6A2,2,0,0,1,11989,19084Zm-12-15.2a1,1,0,0,0-1,1v6.391a1,1,0,0,0,1.532.848l5.11-3.2a1,1,0,0,0,0-1.7l-5.11-3.2A1,1,0,0,0,11977,19068.8Z"
                                              transform="translate(-11966.996 -19062.002)"
                                              fill=""
                                            />
                                          </svg>

                                          <p>Video</p>
                                        </div>
                                      </div>
                                    </Label>
                                    <span className="block-type-tooltip2">
                                      Upload your video to enhance your post
                                      with engaging multimedia content.
                                    </span>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label className="addblock-ratio">
                                      <Input
                                        type="radio"
                                        name="addblockradio"
                                        value="code"
                                        onClick={(e) => handleItem(e)}
                                      />
                                      <div className="image">
                                        <div className="d-block text-center">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="32.938"
                                            height="20.627"
                                            viewBox="0 0 32.938 20.627"
                                          >
                                            <path
                                              id="Union_1761"
                                              data-name="Union 1761"
                                              d="M10463.327,17354.047l7.566-7.736-7.563-7.732,2.521-2.578,7.563,7.732,2.525,2.582-10.089,10.313Zm-20.325-7.732,2.521-2.582,7.567-7.732,2.518,2.578-7.563,7.732,7.567,7.736-2.524,2.58Z"
                                              transform="translate(-10443.002 -17336)"
                                              fill=""
                                            />
                                          </svg>

                                          <p>Code</p>
                                        </div>
                                      </div>
                                    </Label>
                                    <span className="block-type-tooltip1">
                                      Add iframe snippets for interactivity and
                                      specialized features.
                                    </span>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label className="addblock-ratio">
                                      <Input
                                        type="radio"
                                        name="addblockradio"
                                        value="button"
                                        onClick={(e) => handleItem(e)}
                                      />
                                      <div className="image">
                                        <div className="d-block text-center">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="25"
                                            height="25"
                                            viewBox="0 0 25 25"
                                          >
                                            <path
                                              id="Union_183"
                                              data-name="Union 183"
                                              d="M25,25H0V0H25V25ZM3.126,21.876H21.876V3.124H3.126ZM6.29,12.54H9.376V7.771h6.249v4.77h3.166l-6.249,6.25Z"
                                              fill=""
                                            />
                                          </svg>

                                          <p>Button</p>
                                        </div>
                                      </div>
                                    </Label>
                                    <span className="block-type-tooltip2">
                                      Insert engaging buttons to direct users
                                      and encourage interaction.
                                    </span>
                                  </FormGroup>
                                  {checkIfAtrBlockIsAdded() === true ? (
                                    <></>
                                  ) : (
                                    <FormGroup>
                                      <Label className="addblock-ratio">
                                        <Input
                                          type="radio"
                                          name="addblockradio"
                                          value="attribute"
                                          onClick={(e) => handleItem(e)}
                                        />
                                        <div className="image">
                                          <div className="d-block text-center">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="23.998"
                                              height="23.998"
                                              viewBox="0 0 23.998 23.998"
                                            >
                                              <g
                                                id="Group_38611"
                                                data-name="Group 38611"
                                                transform="translate(-908.003 -296.001)"
                                              >
                                                <path
                                                  id="Subtraction_159"
                                                  data-name="Subtraction 159"
                                                  d="M1084,23420h-20a2,2,0,0,1-2-2v-20a2,2,0,0,1,2-2h20a2,2,0,0,1,2,2v20A2,2,0,0,1,1084,23420Zm-17-8a1,1,0,0,0-1,1v2a1,1,0,0,0,1,.994h2a1,1,0,0,0,1-.994v-2a1,1,0,0,0-1-1Zm6,1a1,1,0,1,0,0,2h8a1,1,0,1,0,0-2Zm-6-7a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1.006h2a1,1,0,0,0,1-1.006v-2a1,1,0,0,0-1-1Zm6,1a1,1,0,1,0,0,2h8a1,1,0,1,0,0-2Zm-6-7a1,1,0,0,0-1,1.006v2a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1v-2a1,1,0,0,0-1-1.006Zm6,1.006a1,1,0,1,0,0,2h8a1,1,0,1,0,0-2Z"
                                                  transform="translate(-154 -23100)"
                                                  fill=""
                                                />
                                              </g>
                                            </svg>
                                            <p>Attribute</p>
                                          </div>
                                        </div>
                                      </Label>
                                      <span style={{ bottom: "-85px" }}>
                                        Showcase attributes associated to
                                        content along with their corresponding
                                        values.
                                      </span>
                                    </FormGroup>
                                  )}
                                  <FormGroup>
                                    <Label className="addblock-ratio">
                                      <Input
                                        type="radio"
                                        name="addblockradio"
                                        value="spacer"
                                        onClick={(e) => handleItem(e)}
                                      />
                                      <div className="image">
                                        <div className="d-block text-center">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18.391"
                                            height="28.775"
                                            viewBox="0 0 18.391 28.775"
                                          >
                                            <path
                                              id="Union_1783"
                                              data-name="Union 1783"
                                              d="M14430.072,17362.652l-7.068-7.074,2.118-2.117,5.573,5.57v-17.289l-5.573,5.574-2.123-2.123,7.073-7.072,2.122-2.123,2.118,2.123,7.073,7.072-2.118,2.123-5.573-5.574v17.289l5.573-5.57,2.122,2.117-7.072,7.074-2.123,2.121Z"
                                              transform="translate(-14422.999 -17335.998)"
                                              fill=""
                                            />
                                          </svg>
                                          <p>Spacer</p>
                                        </div>
                                      </div>
                                    </Label>
                                    <span className="block-type-tooltip2">
                                      Create space between elements for a neat
                                      and organized layout.
                                    </span>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label className="addblock-ratio">
                                      <Input
                                        type="radio"
                                        name="addblockradio"
                                        value="separator"
                                        onClick={(e) => handleItem(e)}
                                      />
                                      <div className="image">
                                        <div className="d-block text-center">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="25"
                                            height="24"
                                            viewBox="0 0 25 3"
                                          >
                                            <path
                                              id="Union_1776"
                                              data-name="Union 1776"
                                              d="M25,0V3H0V0Z"
                                              fill=""
                                            />
                                          </svg>
                                          <p>Separator</p>
                                        </div>
                                      </div>
                                    </Label>
                                    <span className="block-type-tooltip2">
                                      Insert a separator to visually divide
                                      different sections of your post.
                                    </span>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label className="addblock-ratio">
                                      <Input
                                        type="radio"
                                        name="addblockradio"
                                        value="youtube_video"
                                        onClick={(e) => handleItem(e)}
                                      />
                                      <div className="image">
                                        <div className="d-block text-center">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="26"
                                            height="18.281"
                                            viewBox="0 0 26 18.281"
                                          >
                                            <path
                                              id="Subtraction_157"
                                              data-name="Subtraction 157"
                                              d="M8589,18851.277c-.021,0-2.057,0-4.318-.062-1.328-.045-2.472-.1-3.4-.166a14.751,14.751,0,0,1-2.442-.312,3.3,3.3,0,0,1-1.46-.834,3.1,3.1,0,0,1-.512-.66,3.021,3.021,0,0,1-.329-.785,12.992,12.992,0,0,1-.312-1.85c-.069-.6-.122-1.3-.163-2.064-.065-1.307-.065-2.375-.065-2.385s0-1.08.065-2.385c.041-.764.094-1.463.163-2.066a12.909,12.909,0,0,1,.313-1.848,3.272,3.272,0,0,1,2.3-2.312,14.854,14.854,0,0,1,2.442-.316c.926-.068,2.069-.125,3.4-.162,2.259-.072,4.3-.072,4.318-.072s2.059,0,4.317.072c1.326.037,2.468.094,3.4.162a14.952,14.952,0,0,1,2.442.316,3.263,3.263,0,0,1,2.3,2.313,13.4,13.4,0,0,1,.316,1.848c.065.6.12,1.3.159,2.066.07,1.3.07,2.373.07,2.385s0,1.078-.07,2.385c-.039.766-.094,1.461-.159,2.064a13.494,13.494,0,0,1-.316,1.85,3.25,3.25,0,0,1-.327.785,2.993,2.993,0,0,1-.516.66,3.268,3.268,0,0,1-1.454.834,14.847,14.847,0,0,1-2.442.313c-.93.07-2.071.121-3.4.166C8591.057,18851.277,8589.02,18851.277,8589,18851.277Zm-2.66-12.982v7.73l6.8-3.867-6.8-3.863Z"
                                              transform="translate(-8576.003 -18832.996)"
                                              fill=""
                                            />
                                          </svg>
                                          <p>YouTube video</p>
                                        </div>
                                      </div>
                                    </Label>
                                    <span className="block-type-tooltip1">
                                      Add captivating videos by pasting the
                                      YouTube link here.
                                    </span>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label className="addblock-ratio">
                                      <Input
                                        type="radio"
                                        name="addblockradio"
                                        value="facebook_video"
                                        onClick={(e) => handleItem(e)}
                                      />
                                      <div className="image">
                                        <div className="d-block text-center">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="12.081"
                                            height="26"
                                            viewBox="0 0 12.081 26"
                                          >
                                            <path
                                              id="_104498_facebook_icon"
                                              data-name="104498_facebook_icon"
                                              d="M30.063,14.515H25.949v-2.7a1.1,1.1,0,0,1,1.145-1.25H30V6.112L26,6.1c-4.439,0-5.449,3.323-5.449,5.449v2.97H17.982v4.591h2.567V32.1h5.4V19.106h3.644Z"
                                              transform="translate(-17.982 -6.096)"
                                              fill=""
                                            />
                                          </svg>

                                          <p>Facebook video</p>
                                        </div>
                                      </div>
                                    </Label>
                                    <span className="block-type-tooltip3">
                                      {" "}
                                      Engage your audience with social media
                                      content by pasting the Facebook video link
                                      here.
                                    </span>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label className="addblock-ratio">
                                      <Input
                                        type="radio"
                                        name="addblockradio"
                                        value="vimeo_video"
                                        onClick={(e) => handleItem(e)}
                                      />
                                      <div className="image">
                                        <div className="d-block text-center">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="28"
                                            height="24.216"
                                            viewBox="0 0 28 24.216"
                                          >
                                            <path
                                              id="_5305169_vimeo_vimeo_logo_icon"
                                              data-name="5305169_vimeo_vimeo logo_icon"
                                              d="M27.986,40.2Q27.8,44.285,22.276,51.39q-5.71,7.419-9.662,7.421-2.449,0-4.142-4.519-1.13-4.143-2.26-8.287-1.257-4.518-2.7-4.519a8.663,8.663,0,0,0-2.2,1.318L0,41.108q2.072-1.821,4.085-3.643,2.765-2.388,4.15-2.514,3.268-.314,4.023,4.468.816,5.16,1.132,6.416.943,4.281,2.075,4.28.878,0,2.641-2.778a10.943,10.943,0,0,0,1.885-4.227q.251-2.4-1.885-2.4a5.248,5.248,0,0,0-2.073.459q2.065-6.76,7.886-6.57Q28.234,34.726,27.986,40.2Z"
                                              transform="translate(0 -34.594)"
                                              fill=""
                                            />
                                          </svg>
                                          <p>Vimeo video</p>
                                        </div>
                                      </div>
                                    </Label>
                                    <span className="block-type-tooltip2">
                                      Showcase stunning videos by pasting the
                                      Vimeo link in this block.
                                    </span>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label className="addblock-ratio">
                                      <Input
                                        type="radio"
                                        name="addblockradio"
                                        value="banner_ad"
                                        onClick={(e) => handleItem(e)}
                                      />
                                      <div className="image">
                                        <div className="d-block text-center">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                          >
                                            <g
                                              id="Group_41751"
                                              data-name="Group 41751"
                                              transform="translate(-137.686 -1407)"
                                            >
                                              <g
                                                id="Group_5389"
                                                data-name="Group 5389"
                                                transform="translate(137.686 1407)"
                                              >
                                                <path
                                                  id="Path_9215"
                                                  data-name="Path 9215"
                                                  d="M92,8.034C92.179,8,92.884,8,93.052,8A12.172,12.172,0,0,1,104.89,19.491v9.433A3.141,3.141,0,0,1,101.7,32a3.489,3.489,0,0,1-2.975-3.068V19.5a6.07,6.07,0,0,0-5.743-5.5,6.661,6.661,0,0,0-.982.014"
                                                  transform="translate(-80.89 -7.998)"
                                                  fill=""
                                                />
                                                <path
                                                  id="Path_9216"
                                                  data-name="Path 9216"
                                                  d="M146.162,143.083A3.081,3.081,0,1,1,143.08,140,3.082,3.082,0,0,1,146.162,143.083Z"
                                                  transform="translate(-122.165 -122.165)"
                                                  fill=""
                                                />
                                                <path
                                                  id="Path_9217"
                                                  data-name="Path 9217"
                                                  d="M19.946,32.1a3.126,3.126,0,0,0,3.2-3.05,3.042,3.042,0,0,0-3.23-2.908h-.034a6.072,6.072,0,0,1-5.666-6.368l0-.057a6.1,6.1,0,0,1,5.731-5.508,3.019,3.019,0,0,0,3.2-2.916,3.128,3.128,0,0,0-3.2-3.051c-.071,0-.143,0-.214,0C14.378,8.42,8.073,12.754,8.01,20.12v.045c0,7.475,6.738,11.93,11.76,11.93h.177Z"
                                                  transform="translate(-8.01 -8.203)"
                                                  fill=""
                                                />
                                              </g>
                                            </g>
                                          </svg>
                                          <p>Banner ad</p>
                                        </div>
                                      </div>
                                    </Label>
                                    <span className="block-type-tooltip3">
                                      Incorporate promotional banners to enhance
                                      engagement and generate revenue.{" "}
                                    </span>
                                  </FormGroup>
                                  <FormGroup>
                                    <Label className="addblock-ratio">
                                      <Input
                                        type="radio"
                                        name="addblockradio"
                                        value="custom_ad"
                                        onClick={(e) => handleItem(e)}
                                      />
                                      <div className="image">
                                        <div className="d-block text-center">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="30"
                                            height="19.001"
                                            viewBox="0 0 30 19.001"
                                          >
                                            <g
                                              id="Group_42976"
                                              data-name="Group 42976"
                                              transform="translate(0.632 1.559)"
                                            >
                                              <rect
                                                id="Rectangle_22260"
                                                data-name="Rectangle 22260"
                                                width="30"
                                                height="18"
                                                rx="2"
                                                transform="translate(-0.632 -0.558)"
                                                fill=""
                                              />
                                              <text
                                                id="_"
                                                data-name="&lt;/&gt;"
                                                transform="translate(14.367 12.441)"
                                                fill="#fff"
                                                font-size="13"
                                                font-family="Axiforma-Bold, Axiforma"
                                                font-weight="700"
                                              >
                                                <tspan x="-11.512" y="0">
                                                  &lt;/&gt;
                                                </tspan>
                                              </text>
                                            </g>
                                          </svg>
                                          <p>Custom ad</p>
                                        </div>
                                      </div>
                                    </Label>
                                    <span style={{ bottom: "-69px" }}>
                                      {" "}
                                      Insert a custom ad seamlessly into your
                                      content.
                                    </span>
                                  </FormGroup>
                                  ​
                                </FormGroup>
                              </ModalBody>
                              <ModalFooter toggle={customBlock}>
                                <Button onClick={customBlock}>Cancel</Button>
                                <Button
                                  className={`${
                                    selectedItem !== "" ? "" : "disabled"
                                  }`}
                                  onClick={(e) => handleAddItem(e)}
                                >
                                  Done
                                </Button>
                              </ModalFooter>
                            </Modal>
                          </div>

                          <TabContent activeTab={activeTab}>
                            <TabPane tabId={true}>
                              <Row>
                                <Col sm="12">
                                  <div className="cms-pages-left">
                                    <div class="accordionWrapper pages-fields">
                                      <DragDropContext
                                        onDragEnd={handleDragEnd}
                                      >
                                        <Droppable droppableId="1234567">
                                          {(provided) => (
                                            <div ref={provided.innerRef}>
                                              <div
                                                className="scroll-new-container"
                                                ref={leftSideRef}
                                              >
                                                {cmsData &&
                                                  cmsData.map(
                                                    (cmsRow, indx) => {
                                                      return (
                                                        <Draggable
                                                          draggableId={indx.toString()}
                                                          key={indx}
                                                          index={indx}
                                                        >
                                                          {(provided) => (
                                                            <div
                                                              className="draggable-container"
                                                              ref={
                                                                provided.innerRef
                                                              }
                                                              {...provided.draggableProps}
                                                            >
                                                              {/* <ErrorBoundary
                                                      FallbackComponent={
                                                        ErrorFallback
                                                      }
                                                      onError={myErrorHandler}
                                                    > */}
                                                              {handleSwitch(
                                                                cmsRow,
                                                                indx,
                                                                provided
                                                              )}
                                                              {/* </ErrorBoundary> */}
                                                            </div>
                                                          )}
                                                        </Draggable>
                                                      );
                                                    }
                                                  )}
                                                {provided.placeholder}
                                              </div>
                                            </div>
                                          )}
                                        </Droppable>
                                      </DragDropContext>
                                    </div>

                                    <div className="add-more-blocks">
                                      <p
                                        className="addmoreblock"
                                        style={{
                                          cursor: "pointer",
                                          marginBottom: "0px",
                                        }}
                                        onClick={customBlock}
                                      >
                                        <img
                                          className="mr-2"
                                          src={
                                            require("../../assets/images/dashboard/cms/blue-plus.png")
                                              .default
                                          }
                                        />
                                        Add section
                                      </p>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </TabPane>

                            <TabPane tabId={false}>
                              <Row>
                                <Col sm="12">
                                  <div className="select-parent cms-settings">
                                    <div className="post-settings-head">
                                      <h2>Settings</h2>
                                      <h3>
                                        Customize and organize your pages.
                                      </h3>
                                    </div>

                                    <h6>Author</h6>
                                    <div className="categogy-chip-section">
                                      <Dropdown
                                        className="author-dropdown search-dropdown"
                                        isOpen={
                                          window.matchMedia(
                                            "(max-width: 1200px)"
                                          ).matches
                                            ? false
                                            : isAuthorPopup
                                        }
                                        onClick={
                                          window.matchMedia(
                                            "(max-width: 1200px)"
                                          ).matches
                                            ? handleMobileAuthorDropdown
                                            : null
                                        }
                                        toggle={handleAuthorPopUp}
                                      >
                                        <DropdownToggle caret>
                                          <div className="cms-author cms-author-selected">
                                            {returnSelectedAuthorName(
                                              authorId
                                            ) && (
                                              <div
                                                style={{
                                                  textTransform: "capitalize",
                                                }}
                                              >
                                                <img
                                                  className="img-fluid"
                                                  src={returnSelectedImagePath(
                                                    authorId
                                                  )}
                                                  alt="profile-img"
                                                  onError={({
                                                    currentTarget,
                                                  }) => {
                                                    currentTarget.onerror =
                                                      null;
                                                    currentTarget.src =
                                                      imagebackground;
                                                  }}
                                                />
                                              </div>
                                            )}

                                            <h3
                                              style={{
                                                top: returnSelectedAuthorName(
                                                  authorId
                                                )
                                                  ? ""
                                                  : "0",
                                              }}
                                            >
                                              {returnSelectedAuthorName(
                                                authorId
                                              )
                                                ? returnSelectedAuthorName(
                                                    authorId
                                                  )
                                                : "Select"}
                                            </h3>
                                          </div>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          {/* <DropdownItem  
                                   onClick={(e)=>handleAuthorChange(e,"")}
                                   active={postRelatedTo===""}
                                  >Select
                                  </DropdownItem> */}

                                          {mergeUserOwnerData &&
                                            mergeUserOwnerData.map((res) => {
                                              return (
                                                res.status !== 0 && (
                                                  <DropdownItem
                                                    onClick={(e) =>
                                                      handleAuthorChange(
                                                        e,
                                                        res.user_id
                                                      )
                                                    }
                                                    active={
                                                      authorId === res.user_id
                                                    }
                                                  >
                                                    <div className="cms-author">
                                                      <div>
                                                        {res.profile_image &&
                                                        res.profile_image.indexOf(
                                                          "https"
                                                        ) > -1 ? (
                                                          <img
                                                            className="img-fluid"
                                                            src={
                                                              res.profile_image
                                                            }
                                                            alt="profile-img"
                                                            onError={({
                                                              currentTarget,
                                                            }) => {
                                                              currentTarget.onerror =
                                                                null;
                                                              currentTarget.src =
                                                                imagebackground;
                                                            }}
                                                          />
                                                        ) : (
                                                          <img
                                                            className="img-fluid"
                                                            src={
                                                              process.env
                                                                .REACT_APP_Image_Path +
                                                              res.user_id +
                                                              process.env
                                                                .REACT_APP_Profile +
                                                              res.profile_image
                                                            }
                                                            alt="profile-img"
                                                            onError={({
                                                              currentTarget,
                                                            }) => {
                                                              currentTarget.onerror =
                                                                null;
                                                              currentTarget.src =
                                                                imagebackground;
                                                            }}
                                                          />
                                                        )}
                                                      </div>

                                                      <p>{res.name}</p>
                                                    </div>
                                                  </DropdownItem>
                                                )
                                              );
                                            })}
                                        </DropdownMenu>
                                      </Dropdown>
                                    </div>

                                    <h6>
                                      Thumbnail
                                      <p className="custom-settings-tooltip">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="14"
                                          height="14"
                                          viewBox="0 0 14 14"
                                        >
                                          <g
                                            id="Group_40296"
                                            data-name="Group 40296"
                                            transform="translate(-1727 -19.132)"
                                          >
                                            <g
                                              id="Ellipse_7706"
                                              data-name="Ellipse 7706"
                                              transform="translate(1727 19.132)"
                                              fill="#fff"
                                              stroke="#bcc1ce"
                                              stroke-width="1.2"
                                            >
                                              <circle
                                                cx="7"
                                                cy="7"
                                                r="7"
                                                stroke="none"
                                              ></circle>
                                              <circle
                                                cx="7"
                                                cy="7"
                                                r="6.4"
                                                fill="none"
                                              ></circle>
                                            </g>
                                            <path
                                              id="Path_98061"
                                              data-name="Path 98061"
                                              d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                              transform="translate(1725.126 17.539)"
                                              fill="none"
                                              stroke="#bcc1ce"
                                              stroke-miterlimit="10"
                                              stroke-width="1.2"
                                            ></path>
                                            <line
                                              id="Line_343"
                                              data-name="Line 343"
                                              y2="1.2"
                                              transform="translate(1734 29.132)"
                                              fill="none"
                                              stroke="#bcc1ce"
                                              stroke-miterlimit="10"
                                              stroke-width="1.2"
                                            ></line>
                                          </g>
                                        </svg>
                                        <p className="custom-settings-tooltipsubheader">
                                          Upload your post thumbnail image here.
                                          This image will be featured on all
                                          pages where this post will appear.
                                        </p>
                                      </p>
                                    </h6>
                                    <div className="image-uploader">
                                      <div
                                        className="d-flex align-items-center"
                                        onClick={(e) => imageGalleryPopup(e,"thumbnail")}
                                      >
                                        <div
                                          className="uploadpixabayimage"
                                          style={{
                                            backgroundImage:
                                              "url(" +
                                              (templistingThumb !== undefined &&
                                              templistingThumb !== null &&
                                              templistingThumb !== ""
                                                ? templistingThumb.indexOf(
                                                    "https"
                                                  ) > -1
                                                  ? templistingThumb
                                                  : process.env
                                                      .REACT_APP_Image_Path +
                                                    localStorage.getItem(
                                                      "user_id"
                                                    ) +
                                                    "/" +
                                                    localStorage.getItem(
                                                      "Appid"
                                                    ) +
                                                    process.env
                                                      .REACT_APP_CMS_Media +
                                                    templistingThumb
                                                : imagebackground3) +
                                              ")",
                                          }}
                                        ></div>

                                        <p
                                          style={{
                                            color:
                                              templistingThumb === "" ||
                                              templistingThumb === undefined ||
                                              templistingThumb === null
                                                ? "#BCCBCE"
                                                : "",
                                          }}
                                          className={
                                            templistingThumb !== "" &&
                                            templistingThumb !== undefined
                                              ? ""
                                              : "cms-image-placeholder"
                                          }
                                        >
                                          {templistingThumb !== "" &&
                                          templistingThumb !== undefined
                                            ? templistingThumb.replace(
                                                "https://create.vista.com/downloads/?filePath=%2Fdownloads%2F",
                                                ""
                                              )
                                            : "Upload an image."}
                                        </p>
                                      </div>
                                      {templistingThumb !== "" &&
                                        templistingThumb !== undefined && (
                                          <svg
                                            width="11.06"
                                            height="11.06"
                                            viewBox="0 0 11.06 11.06"
                                            onClick={(e) =>
                                              handleCancelImage(e)
                                            }
                                          >
                                            <path
                                              id="Union_1696"
                                              data-name="Union 1696"
                                              d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z"
                                              transform="translate(0.53 0.53)"
                                              fill="none"
                                              stroke="#7782a1"
                                              stroke-width="1.5"
                                            />
                                          </svg>
                                        )}
                                    </div>

                                    <h6 className="mt-20">
                                      Assign categories
                                      <p className="custom-settings-tooltip">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="14"
                                          height="14"
                                          viewBox="0 0 14 14"
                                        >
                                          <g
                                            id="Group_40296"
                                            data-name="Group 40296"
                                            transform="translate(-1727 -19.132)"
                                          >
                                            <g
                                              id="Ellipse_7706"
                                              data-name="Ellipse 7706"
                                              transform="translate(1727 19.132)"
                                              fill="#fff"
                                              stroke="#bcc1ce"
                                              stroke-width="1.2"
                                            >
                                              <circle
                                                cx="7"
                                                cy="7"
                                                r="7"
                                                stroke="none"
                                              ></circle>
                                              <circle
                                                cx="7"
                                                cy="7"
                                                r="6.4"
                                                fill="none"
                                              ></circle>
                                            </g>
                                            <path
                                              id="Path_98061"
                                              data-name="Path 98061"
                                              d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                              transform="translate(1725.126 17.539)"
                                              fill="none"
                                              stroke="#bcc1ce"
                                              stroke-miterlimit="10"
                                              stroke-width="1.2"
                                            ></path>
                                            <line
                                              id="Line_343"
                                              data-name="Line 343"
                                              y2="1.2"
                                              transform="translate(1734 29.132)"
                                              fill="none"
                                              stroke="#bcc1ce"
                                              stroke-miterlimit="10"
                                              stroke-width="1.2"
                                            ></line>
                                          </g>
                                        </svg>
                                        <p className="custom-settings-tooltipsubheader">
                                          Organize your content efficiently by
                                          adding categories.
                                        </p>
                                      </p>
                                    </h6>
                                    <div
                                      style={{
                                        alignItems: "center",
                                        cursor: "pointer",
                                      }}
                                      className="categogy-chip-section cat-parent-input-popup"
                                      onClick={() =>
                                        handleOpenCatSelectionPopUp("open")
                                      }
                                      id="calculate-ele-height"
                                    >
                                      <span
                                        style={{
                                          color:
                                            catArrForPost.length === 0
                                              ? "#BCCBCE"
                                              : "",
                                        }}
                                        className="search-dropdown-before"
                                      >
                                        {catArrForPost.length === 0 ? (
                                          "Assign categories to your post."
                                        ) : (
                                          <div>
                                            {catArrForPost &&
                                              catArrForPost.map((row, ind) => {
                                                return (
                                                  <div>
                                                    <p>{row.name} </p>
                                                    <span
                                                      style={{
                                                        marginLeft: "5px",
                                                      }}
                                                      onClick={(e) =>
                                                        handleDelCat(
                                                          e,
                                                          ind,
                                                          row
                                                        )
                                                      }
                                                    >
                                                      <img
                                                        className="img-fluid"
                                                        src={
                                                          require("../../assets/images/dashboard/remove-tag.png")
                                                            .default
                                                        }
                                                        alt="Remove"
                                                      />
                                                    </span>
                                                  </div>
                                                );
                                              })}
                                          </div>
                                        )}
                                      </span>
                                      <svg
                                        style={{ minWidth: "20px" }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="19"
                                        viewBox="0 0 20 19"
                                      >
                                        <g
                                          id="Group_42666"
                                          data-name="Group 42666"
                                          transform="translate(-1005 -1029)"
                                        >
                                          <g
                                            id="Rectangle_31915"
                                            data-name="Rectangle 31915"
                                            transform="translate(1005 1029)"
                                            fill="#fff"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          >
                                            <rect
                                              width="20"
                                              height="19"
                                              rx="2"
                                              stroke="none"
                                            />
                                            <rect
                                              x="0.5"
                                              y="0.5"
                                              width="19"
                                              height="18"
                                              rx="1.5"
                                              fill="none"
                                            />
                                          </g>
                                          <path
                                            id="Path_119031"
                                            data-name="Path 119031"
                                            d="M15578.713-3593.554h2"
                                            transform="translate(-14569.713 4628.054)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          />
                                          <path
                                            id="Path_119032"
                                            data-name="Path 119032"
                                            d="M15578.713-3593.554h2"
                                            transform="translate(-14569.713 4632.054)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          />
                                          <path
                                            id="Path_119033"
                                            data-name="Path 119033"
                                            d="M15578.713-3593.554h2"
                                            transform="translate(-14569.713 4636.054)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          />
                                          <path
                                            id="Path_119034"
                                            data-name="Path 119034"
                                            d="M15578.711-3593.554h8"
                                            transform="translate(-14565.713 4628.054)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          />
                                          <path
                                            id="Path_119035"
                                            data-name="Path 119035"
                                            d="M15578.711-3593.554h8"
                                            transform="translate(-14565.713 4632.054)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          />
                                          <path
                                            id="Path_119036"
                                            data-name="Path 119036"
                                            d="M15578.711-3593.554h8"
                                            transform="translate(-14565.713 4636.054)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          />
                                        </g>
                                      </svg>
                                    </div>

                                    <h6 className="mt-20">
                                      Assign tags
                                      <p className="custom-settings-tooltip">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="14"
                                          height="14"
                                          viewBox="0 0 14 14"
                                        >
                                          <g
                                            id="Group_40296"
                                            data-name="Group 40296"
                                            transform="translate(-1727 -19.132)"
                                          >
                                            <g
                                              id="Ellipse_7706"
                                              data-name="Ellipse 7706"
                                              transform="translate(1727 19.132)"
                                              fill="#fff"
                                              stroke="#bcc1ce"
                                              stroke-width="1.2"
                                            >
                                              <circle
                                                cx="7"
                                                cy="7"
                                                r="7"
                                                stroke="none"
                                              ></circle>
                                              <circle
                                                cx="7"
                                                cy="7"
                                                r="6.4"
                                                fill="none"
                                              ></circle>
                                            </g>
                                            <path
                                              id="Path_98061"
                                              data-name="Path 98061"
                                              d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                              transform="translate(1725.126 17.539)"
                                              fill="none"
                                              stroke="#bcc1ce"
                                              stroke-miterlimit="10"
                                              stroke-width="1.2"
                                            ></path>
                                            <line
                                              id="Line_343"
                                              data-name="Line 343"
                                              y2="1.2"
                                              transform="translate(1734 29.132)"
                                              fill="none"
                                              stroke="#bcc1ce"
                                              stroke-miterlimit="10"
                                              stroke-width="1.2"
                                            ></line>
                                          </g>
                                        </svg>
                                        <p className="custom-settings-tooltipsubheader">
                                          Enhance post visibility and
                                          searchability by assigning tags. Tags
                                          help users quickly identify and access
                                          content based on specific topics or
                                          themes.
                                        </p>
                                      </p>
                                    </h6>

                                    <div
                                      style={{
                                        alignItems: "center",
                                        cursor: "pointer",
                                      }}
                                      className="categogy-chip-section cat-parent-input-popup"
                                      onClick={() =>
                                        handleOpenTagSelectionPopUp("open")
                                      }
                                    >
                                      <span
                                        className="search-dropdown-before"
                                        style={{
                                          color:
                                            tagArrForPost.length === 0
                                              ? "#BCCBCE"
                                              : "",
                                        }}
                                      >
                                        {tagArrForPost.length === 0 ? (
                                          "Assign tags to your post."
                                        ) : (
                                          <div>
                                            {tagArrForPost &&
                                              tagArrForPost.map((row, ind) => {
                                                return (
                                                  <div>
                                                    <p>{row.name} </p>
                                                    <span
                                                      style={{
                                                        marginLeft: "5px",
                                                      }}
                                                      onClick={(e) =>
                                                        handleDelTag(
                                                          e,
                                                          ind,
                                                          row
                                                        )
                                                      }
                                                    >
                                                      <img
                                                        className="img-fluid"
                                                        src={
                                                          require("../../assets/images/dashboard/remove-tag.png")
                                                            .default
                                                        }
                                                        alt="Remove"
                                                      />
                                                    </span>
                                                  </div>
                                                );
                                              })}
                                          </div>
                                        )}
                                      </span>
                                      <svg
                                        style={{ minWidth: "20px" }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="19"
                                        viewBox="0 0 20 19"
                                      >
                                        <g
                                          id="Group_42666"
                                          data-name="Group 42666"
                                          transform="translate(-1005 -1029)"
                                        >
                                          <g
                                            id="Rectangle_31915"
                                            data-name="Rectangle 31915"
                                            transform="translate(1005 1029)"
                                            fill="#fff"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          >
                                            <rect
                                              width="20"
                                              height="19"
                                              rx="2"
                                              stroke="none"
                                            />
                                            <rect
                                              x="0.5"
                                              y="0.5"
                                              width="19"
                                              height="18"
                                              rx="1.5"
                                              fill="none"
                                            />
                                          </g>
                                          <path
                                            id="Path_119031"
                                            data-name="Path 119031"
                                            d="M15578.713-3593.554h2"
                                            transform="translate(-14569.713 4628.054)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          />
                                          <path
                                            id="Path_119032"
                                            data-name="Path 119032"
                                            d="M15578.713-3593.554h2"
                                            transform="translate(-14569.713 4632.054)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          />
                                          <path
                                            id="Path_119033"
                                            data-name="Path 119033"
                                            d="M15578.713-3593.554h2"
                                            transform="translate(-14569.713 4636.054)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          />
                                          <path
                                            id="Path_119034"
                                            data-name="Path 119034"
                                            d="M15578.711-3593.554h8"
                                            transform="translate(-14565.713 4628.054)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          />
                                          <path
                                            id="Path_119035"
                                            data-name="Path 119035"
                                            d="M15578.711-3593.554h8"
                                            transform="translate(-14565.713 4632.054)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          />
                                          <path
                                            id="Path_119036"
                                            data-name="Path 119036"
                                            d="M15578.711-3593.554h8"
                                            transform="translate(-14565.713 4636.054)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          />
                                        </g>
                                      </svg>
                                    </div>

                                    <h6 className="mt-20">
                                      Assign attributes
                                      <p className="custom-settings-tooltip">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="14"
                                          height="14"
                                          viewBox="0 0 14 14"
                                        >
                                          <g
                                            id="Group_40296"
                                            data-name="Group 40296"
                                            transform="translate(-1727 -19.132)"
                                          >
                                            <g
                                              id="Ellipse_7706"
                                              data-name="Ellipse 7706"
                                              transform="translate(1727 19.132)"
                                              fill="#fff"
                                              stroke="#bcc1ce"
                                              stroke-width="1.2"
                                            >
                                              <circle
                                                cx="7"
                                                cy="7"
                                                r="7"
                                                stroke="none"
                                              ></circle>
                                              <circle
                                                cx="7"
                                                cy="7"
                                                r="6.4"
                                                fill="none"
                                              ></circle>
                                            </g>
                                            <path
                                              id="Path_98061"
                                              data-name="Path 98061"
                                              d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                              transform="translate(1725.126 17.539)"
                                              fill="none"
                                              stroke="#bcc1ce"
                                              stroke-miterlimit="10"
                                              stroke-width="1.2"
                                            ></path>
                                            <line
                                              id="Line_343"
                                              data-name="Line 343"
                                              y2="1.2"
                                              transform="translate(1734 29.132)"
                                              fill="none"
                                              stroke="#bcc1ce"
                                              stroke-miterlimit="10"
                                              stroke-width="1.2"
                                            ></line>
                                          </g>
                                        </svg>
                                        <p className="custom-settings-tooltipsubheader">
                                          Specify additional attributes to your
                                          posts for a more detailed
                                          categorization.
                                        </p>
                                      </p>
                                    </h6>

                                    <div
                                      style={{
                                        alignItems: "center",
                                        cursor: "pointer",
                                      }}
                                      className="categogy-chip-section cat-parent-input-popup"
                                      onClick={() =>
                                        handleOpenAtrSelectionPopUp("open")
                                      }
                                    >
                                      <span
                                        className="search-dropdown-before"
                                        style={{
                                          color:
                                            isAtrAssign() === false
                                              ? "#BCCBCE"
                                              : "",
                                        }}
                                      >
                                        {isAtrAssign() === false ? (
                                          "Assign attributes to your post."
                                        ) : (
                                          <div
                                            onClick={(e) =>
                                              handleOpenAttributeDropdown(e)
                                            }
                                          >
                                            {atrListingData &&
                                              atrListingData.map((row, ind) => {
                                                return (
                                                  row.is_checked === true && (
                                                    <div>
                                                      <p>
                                                        {row.attribute_name}{" "}
                                                        {` ( ${countNoOfValAtr(
                                                          row._id
                                                        )} )`}
                                                        <span
                                                          onClick={(e) =>
                                                            handleDelAttribute(
                                                              e,
                                                              row._id
                                                            )
                                                          }
                                                        >
                                                          <img
                                                            className="img-fluid"
                                                            src={
                                                              require("../../assets/images/dashboard/remove-tag.png")
                                                                .default
                                                            }
                                                            alt="Remove"
                                                          />
                                                        </span>
                                                      </p>
                                                    </div>
                                                  )
                                                );
                                              })}
                                          </div>
                                        )}
                                      </span>
                                      <svg
                                        style={{ minWidth: "20px" }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="19"
                                        viewBox="0 0 20 19"
                                      >
                                        <g
                                          id="Group_42666"
                                          data-name="Group 42666"
                                          transform="translate(-1005 -1029)"
                                        >
                                          <g
                                            id="Rectangle_31915"
                                            data-name="Rectangle 31915"
                                            transform="translate(1005 1029)"
                                            fill="#fff"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          >
                                            <rect
                                              width="20"
                                              height="19"
                                              rx="2"
                                              stroke="none"
                                            />
                                            <rect
                                              x="0.5"
                                              y="0.5"
                                              width="19"
                                              height="18"
                                              rx="1.5"
                                              fill="none"
                                            />
                                          </g>
                                          <path
                                            id="Path_119031"
                                            data-name="Path 119031"
                                            d="M15578.713-3593.554h2"
                                            transform="translate(-14569.713 4628.054)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          />
                                          <path
                                            id="Path_119032"
                                            data-name="Path 119032"
                                            d="M15578.713-3593.554h2"
                                            transform="translate(-14569.713 4632.054)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          />
                                          <path
                                            id="Path_119033"
                                            data-name="Path 119033"
                                            d="M15578.713-3593.554h2"
                                            transform="translate(-14569.713 4636.054)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          />
                                          <path
                                            id="Path_119034"
                                            data-name="Path 119034"
                                            d="M15578.711-3593.554h8"
                                            transform="translate(-14565.713 4628.054)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          />
                                          <path
                                            id="Path_119035"
                                            data-name="Path 119035"
                                            d="M15578.711-3593.554h8"
                                            transform="translate(-14565.713 4632.054)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          />
                                          <path
                                            id="Path_119036"
                                            data-name="Path 119036"
                                            d="M15578.711-3593.554h8"
                                            transform="translate(-14565.713 4636.054)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          />
                                        </g>
                                      </svg>
                                    </div>

                                    <label style={{ color: "#7782A1" }}>
                                      Excerpt
                                      <p className="custom-settings-tooltip">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="14"
                                          height="14"
                                          viewBox="0 0 14 14"
                                        >
                                          <g
                                            id="Group_40296"
                                            data-name="Group 40296"
                                            transform="translate(-1727 -19.132)"
                                          >
                                            <g
                                              id="Ellipse_7706"
                                              data-name="Ellipse 7706"
                                              transform="translate(1727 19.132)"
                                              fill="#fff"
                                              stroke="#bcc1ce"
                                              stroke-width="1.2"
                                            >
                                              <circle
                                                cx="7"
                                                cy="7"
                                                r="7"
                                                stroke="none"
                                              ></circle>
                                              <circle
                                                cx="7"
                                                cy="7"
                                                r="6.4"
                                                fill="none"
                                              ></circle>
                                            </g>
                                            <path
                                              id="Path_98061"
                                              data-name="Path 98061"
                                              d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                              transform="translate(1725.126 17.539)"
                                              fill="none"
                                              stroke="#bcc1ce"
                                              stroke-miterlimit="10"
                                              stroke-width="1.2"
                                            ></path>
                                            <line
                                              id="Line_343"
                                              data-name="Line 343"
                                              y2="1.2"
                                              transform="translate(1734 29.132)"
                                              fill="none"
                                              stroke="#bcc1ce"
                                              stroke-miterlimit="10"
                                              stroke-width="1.2"
                                            ></line>
                                          </g>
                                        </svg>
                                        <p className="custom-settings-tooltipsubheader">
                                          Craft a concise and engaging summary
                                          or teaser for your post. Keep it brief
                                          and captivating to capture attention
                                          and generate interest.
                                        </p>
                                      </p>
                                    </label>
                                    <textarea
                                      placeholder="Write a brief summary or teaser for your post"
                                      onChange={(e) =>
                                        setExcerpt(e.target.value)
                                      }
                                      value={excerpt}
                                      maxLength={70}
                                    ></textarea>

                                    <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                                      <div class="d-flex justify-content-start align-items-center ">
                                        <h4 class="mb-0">
                                          Publish immediately
                                        </h4>
                                      </div>
                                      <div class="on_off d-flex  ">
                                        <label class="switch" for="immediate">
                                          <input
                                            type="checkbox"
                                            id="immediate"
                                            checked={publishImmediate}
                                            onChange={(e) =>
                                              handleImmediateToggle(e)
                                            }
                                          />
                                          <div class="slider round"></div>
                                        </label>
                                      </div>
                                    </div>

                                    {publishImmediate === false && (
                                      <div
                                        className="parentsection"
                                        onClick={() =>
                                          setShowDatePicker(!showDatePicker)
                                        }
                                      >
                                        {" "}
                                        {/* {showDatePicker === true && ( */}
                                        <DatePicker
                                          className="date-input-post2"
                                          selected={dateTimeValue}
                                          onChange={(date) =>
                                            handleDateTimePicker(date)
                                          }
                                          filterTime={filterPassedTime}
                                          // locale="en-GB"
                                          showTimeSelect
                                          // dateFormat="LLLL"
                                          dateFormat="d MMMM yyyy, HH:mm"
                                          // timeFormat="p"
                                          timeIntervals={15}
                                          // dateFormat="Pp"
                                          // filterTime={filterPassedTime}
                                          minDate={new Date()}
                                          maxDate={addDays(new Date(), 365)}
                                        />
                                        {/* )} */}{" "}
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/cms/calendar.png")
                                              .default
                                          }
                                        />
                                      </div>
                                    )}
                                    <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                                      <div class="d-flex justify-content-start align-items-center ">
                                        <h4 class="mb-0">Featured Post</h4>
                                      </div>
                                      <div class="on_off d-flex  ">
                                        <label class="switch" for="share_app">
                                          <input
                                            type="checkbox"
                                            id="share_app"
                                            checked={is_featured}
                                            onChange={(e) =>
                                              handleIsFeatured(e)
                                            }
                                          />
                                          <div class="slider round"></div>
                                        </label>
                                      </div>
                                    </div>

                                    <div className="post-list-setting-seprator"></div>

                                    <div className="post-advacnce-settings">
                                      <h4
                                        onClick={(e) =>
                                          handleAdvancedSetting(e)
                                        }
                                      >
                                        Advanced settings
                                        {openAdvanceSetting ? (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="11.063"
                                            height="6.591"
                                            viewBox="0 0 11.063 6.591"
                                          >
                                            <path
                                              id="Path_102807"
                                              data-name="Path 102807"
                                              d="M-17299.424-4404.885l5-5,5,5"
                                              transform="translate(17299.955 4410.946)"
                                              fill="none"
                                              stroke="#3064f9"
                                              stroke-width="1.5"
                                            />
                                          </svg>
                                        ) : (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="11.063"
                                            height="6.591"
                                            viewBox="0 0 11.063 6.591"
                                          >
                                            <path
                                              id="Path_102807"
                                              data-name="Path 102807"
                                              d="M-17299.424-4409.885l5,5,5-5"
                                              transform="translate(17299.955 4410.416)"
                                              fill="none"
                                              stroke="#3064f9"
                                              stroke-width="1.5"
                                            />
                                          </svg>
                                        )}
                                      </h4>

                                      <h3
                                        onClick={(e) =>
                                          handleResetAdvancedSetting(e)
                                        }
                                      >
                                        {resetSpinner ? (
                                          <div
                                            style={{
                                              background: "none",
                                              margin: "auto",
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              xlink="http://www.w3.org/1999/xlink"
                                              style={{
                                                position: "relative",
                                                top: "-20px",
                                                height: "30px",
                                                width: "40px",
                                                margin: "auto",
                                                background:
                                                  "rgb(241, 242, 243,0)",
                                                display: "block",
                                                shaperendering: "auto",
                                                animationplaystate: "running",
                                                animationdelay: "0s",
                                              }}
                                              width="200px"
                                              height="100px"
                                              viewBox="0 0 100 100"
                                              preserveAspectRatio="xMidYMid"
                                            >
                                              <g
                                                transform="translate(20 50)"
                                                style={{
                                                  animationplaystate: "running",
                                                  animationdelay: "0s",
                                                }}
                                              >
                                                <circle
                                                  cx="0"
                                                  cy="0"
                                                  r="6"
                                                  fill="#e6261f"
                                                  style={{
                                                    animationplaystate:
                                                      "running",
                                                    animationdelay: "0s",
                                                  }}
                                                >
                                                  <animateTransform
                                                    attributeName="transform"
                                                    type="scale"
                                                    begin="-0.375s"
                                                    calcMode="spline"
                                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                                    values="0;1;0"
                                                    keyTimes="0;0.5;1"
                                                    dur="1s"
                                                    repeatCount="indefinite"
                                                    style={{
                                                      animationplaystate:
                                                        "running",
                                                      animationdelay: "0s",
                                                    }}
                                                  ></animateTransform>
                                                </circle>
                                              </g>
                                              <g
                                                transform="translate(40 50)"
                                                style={{
                                                  animationplaystate: "running",
                                                  animationdelay: "0s",
                                                }}
                                              >
                                                <circle
                                                  cx="0"
                                                  cy="0"
                                                  r="6"
                                                  fill="#f7d038"
                                                  style={{
                                                    animationplaystate:
                                                      "running",
                                                    animationdelay: "0s",
                                                  }}
                                                >
                                                  <animateTransform
                                                    attributeName="transform"
                                                    type="scale"
                                                    begin="-0.25s"
                                                    calcMode="spline"
                                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                                    values="0;1;0"
                                                    keyTimes="0;0.5;1"
                                                    dur="1s"
                                                    repeatCount="indefinite"
                                                    style={{
                                                      animationplaystate:
                                                        "running",
                                                      animationdelay: "0s",
                                                    }}
                                                  ></animateTransform>
                                                </circle>
                                              </g>
                                              <g
                                                transform="translate(60 50)"
                                                style={{
                                                  animationplaystate: "running",
                                                  animationdelay: "0s",
                                                }}
                                              >
                                                <circle
                                                  cx="0"
                                                  cy="0"
                                                  r="6"
                                                  fill="#49da9a"
                                                  style={{
                                                    animationplaystate:
                                                      "running",
                                                    animationdelay: "0s",
                                                  }}
                                                >
                                                  <animateTransform
                                                    attributeName="transform"
                                                    type="scale"
                                                    begin="-0.125s"
                                                    calcMode="spline"
                                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                                    values="0;1;0"
                                                    keyTimes="0;0.5;1"
                                                    dur="1s"
                                                    repeatCount="indefinite"
                                                    style={{
                                                      animationplaystate:
                                                        "running",
                                                      animationdelay: "0s",
                                                    }}
                                                  ></animateTransform>
                                                </circle>
                                              </g>
                                              <g
                                                transform="translate(80 50)"
                                                style={{
                                                  animationplaystate: "running",
                                                  animationdelay: "0s",
                                                }}
                                              >
                                                <circle
                                                  cx="0"
                                                  cy="0"
                                                  r="6"
                                                  fill="#4355db"
                                                  style={{
                                                    animationplaystate:
                                                      "running",
                                                    animationdelay: "0s",
                                                  }}
                                                >
                                                  <animateTransform
                                                    attributeName="transform"
                                                    type="scale"
                                                    begin="0s"
                                                    calcMode="spline"
                                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                                    values="0;1;0"
                                                    keyTimes="0;0.5;1"
                                                    dur="1s"
                                                    repeatCount="indefinite"
                                                    style={{
                                                      animationplaystate:
                                                        "running",
                                                      animationdelay: "0s",
                                                    }}
                                                  ></animateTransform>
                                                </circle>
                                              </g>
                                            </svg>
                                          </div>
                                        ) : (
                                          "Reset"
                                        )}
                                      </h3>
                                    </div>
                                    {openAdvanceSetting && (
                                      <>
                                        <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                                          <div class="d-flex justify-content-start align-items-center ">
                                            <h4 class="mb-0">Show author</h4>
                                          </div>
                                          <div class="on_off d-flex  ">
                                            <label
                                              class="switch"
                                              for="showAuthor"
                                            >
                                              <input
                                                type="checkbox"
                                                id="showAuthor"
                                                checked={showAuthor}
                                                onChange={(e) =>
                                                  handleShowAuthorToggle(e)
                                                }
                                              />
                                              <div class="slider round"></div>
                                            </label>
                                          </div>
                                        </div>

                                        <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                                          <div class="d-flex justify-content-start align-items-center ">
                                            <h4 class="mb-0">
                                              Show publish date
                                            </h4>
                                          </div>
                                          <div class="on_off d-flex  ">
                                            <label
                                              class="switch"
                                              for="showPublishDate"
                                            >
                                              <input
                                                type="checkbox"
                                                id="showPublishDate"
                                                checked={showPublishDate}
                                                onChange={(e) =>
                                                  handleShowPublishDate(e)
                                                }
                                              />
                                              <div class="slider round"></div>
                                            </label>
                                          </div>
                                        </div>

                                        <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                                          <div class="d-flex justify-content-start align-items-center ">
                                            <h4 class="mb-0">
                                              Show categories
                                            </h4>
                                          </div>
                                          <div class="on_off d-flex  ">
                                            <label class="switch" for="showCat">
                                              <input
                                                type="checkbox"
                                                id="showCat"
                                                checked={showCat}
                                                onChange={(e) =>
                                                  handleShowCatToggle(e)
                                                }
                                              />
                                              <div class="slider round"></div>
                                            </label>
                                          </div>
                                        </div>

                                        <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                                          <div class="d-flex justify-content-start align-items-center ">
                                            <h4 class="mb-0">Show tags</h4>
                                          </div>
                                          <div class="on_off d-flex  ">
                                            <label class="switch" for="showTag">
                                              <input
                                                type="checkbox"
                                                id="showTag"
                                                checked={showTag}
                                                onChange={(e) =>
                                                  handleShowTagToggle(e)
                                                }
                                              />
                                              <div class="slider round"></div>
                                            </label>
                                          </div>
                                        </div>

                                        <div class="featured-post d-flex justify-content-between mt-0 align-items-center">
                                          <div class="d-flex justify-content-start align-items-center ">
                                            <h4 class="mb-0">
                                              Show related posts
                                            </h4>
                                          </div>
                                          <div class="on_off d-flex  ">
                                            <label
                                              class="switch"
                                              for="showRelatedPosts"
                                            >
                                              <input
                                                type="checkbox"
                                                id="showRelatedPosts"
                                                checked={showRelatedPosts}
                                                onChange={(e) =>
                                                  handleShowRelatedPosts(e)
                                                }
                                              />
                                              <div class="slider round"></div>
                                            </label>
                                          </div>
                                        </div>
                                        {showRelatedPosts && (
                                          <h6 className="mt-20">
                                            Filter related posts by
                                          </h6>
                                        )}
                                        {showRelatedPosts && (
                                          <div className="categogy-chip-section post-settings-filter-dropdown">
                                            <Dropdown
                                              className="cms-filterby-post search-dropdown"
                                              isOpen={
                                                window.matchMedia(
                                                  "(max-width: 1200px)"
                                                ).matches
                                                  ? false
                                                  : isRelatedToPopup
                                              }
                                              onClick={
                                                window.matchMedia(
                                                  "(max-width: 1200px)"
                                                ).matches
                                                  ? handleMobileRelatedToDropdown
                                                  : null
                                              }
                                              toggle={handleRelatedToPopUp}
                                            >
                                              <DropdownToggle caret>
                                                <span
                                                  className="search-dropdown-before"
                                                  style={{
                                                    textTransform: "capitalize",
                                                  }}
                                                >
                                                  {postRelatedTo
                                                    ? postRelatedTo
                                                    : "Select"}
                                                </span>
                                              </DropdownToggle>
                                              <DropdownMenu>
                                                {/* <DropdownItem  
                                   onClick={(e)=>handleRelatedToChange(e,"")}
                                   active={postRelatedTo===""}
                                  >Select
                                  </DropdownItem> */}

                                                <DropdownItem
                                                  onClick={(e) =>
                                                    handleRelatedToChange(
                                                      e,
                                                      "authors"
                                                    )
                                                  }
                                                  active={
                                                    postRelatedTo === "authors"
                                                  }
                                                >
                                                  Authors
                                                </DropdownItem>

                                                <DropdownItem
                                                  onClick={(e) =>
                                                    handleRelatedToChange(
                                                      e,
                                                      "categories"
                                                    )
                                                  }
                                                  active={
                                                    postRelatedTo ===
                                                    "categories"
                                                  }
                                                >
                                                  Categories
                                                </DropdownItem>

                                                <DropdownItem
                                                  onClick={(e) =>
                                                    handleRelatedToChange(
                                                      e,
                                                      "featured"
                                                    )
                                                  }
                                                  active={
                                                    postRelatedTo === "featured"
                                                  }
                                                >
                                                  Featured
                                                </DropdownItem>

                                                <DropdownItem
                                                  onClick={(e) =>
                                                    handleRelatedToChange(
                                                      e,
                                                      "tags"
                                                    )
                                                  }
                                                  active={
                                                    postRelatedTo === "tags"
                                                  }
                                                >
                                                  Tags
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </Dropdown>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </TabPane>
                          </TabContent>

                          <div className="cms-mobile-btn-navigation">
                            <button
                              disabled={
                                isLoader2 === "d-block" ||
                                isLoader === "d-block"
                              }
                              className={` ${
                                isLoader === "d-block"
                                  ? "save-draft-mobile-disable"
                                  : "save-draft-mobile"
                              }`}
                              onClick={(e) => handleSubmitPostData(e, "draft")}
                              style={{
                                // backgroundColor:localStorage.getItem('button_background_color'),
                                border:
                                  "1px solid " +
                                  localStorage.getItem(
                                    "button_background_color"
                                  ),
                                color:
                                  localStorage.getItem("button_text_color"),
                                "--custom_color5":
                                  localStorage.getItem("agencyid") == 1
                                    ? "#134bec"
                                    : localStorage.getItem(
                                        "button_hover_bgcolor"
                                      ),
                                "--custom_color4":
                                  localStorage.getItem("agencyid") == 1
                                    ? "white"
                                    : localStorage.getItem(
                                        "button_hover_txtcolor"
                                      ),
                                "--custom_color6":
                                  localStorage.getItem("agencyid") == 1
                                    ? "#3064f9"
                                    : localStorage.getItem(
                                        "button_hover_bgcolor"
                                      ),
                              }}
                            >
                              <div className={isLoader2}>
                                <div className="new-blue-loader"></div>
                              </div>
                              <div
                                style={{
                                  color: localStorage.getItem(
                                    "button_background_color"
                                  ),
                                }}
                              >
                                {window.matchMedia("(max-width: 1200px)")
                                  .matches
                                  ? isLoader2 === "d-none"
                                    ? "Save draft"
                                    : ""
                                  : submitval2}
                              </div>
                            </button>

                            <button
                              className={`${
                                isLoader2 === "d-block"
                                  ? "publish-mobile-disable"
                                  : "publish-mobile"
                              }`}
                              disabled={
                                isLoader2 === "d-block" ||
                                isLoader === "d-block"
                              }
                              onClick={(e) =>
                                handleSubmitPostData(e, "publish")
                              }
                              style={{
                                backgroundColor: localStorage.getItem(
                                  "button_background_color"
                                ),
                                border:
                                  "1px solid " +
                                  localStorage.getItem(
                                    "button_background_color"
                                  ),
                                color:
                                  localStorage.getItem("button_text_color"),
                                "--custom_color5":
                                  localStorage.getItem("agencyid") == 1
                                    ? "#134bec"
                                    : localStorage.getItem(
                                        "button_hover_bgcolor"
                                      ),
                                "--custom_color4":
                                  localStorage.getItem("agencyid") == 1
                                    ? "white"
                                    : localStorage.getItem(
                                        "button_hover_txtcolor"
                                      ),
                                "--custom_color6":
                                  localStorage.getItem("agencyid") == 1
                                    ? "#3064f9"
                                    : localStorage.getItem(
                                        "button_hover_bgcolor"
                                      ),
                              }}
                            >
                              <div className={isLoader}>
                                <div className="new-loader"></div>
                              </div>
                              <div>
                                {statusOfPost === "publish"
                                  ? isLoader === "d-none"
                                    ? "Save"
                                    : ""
                                  : submitval}
                              </div>
                            </button>
                            {updatedAt && (
                              <p
                                className="profile-latest-update"
                                style={{
                                  width: "100%",
                                  marginTop: "15px",
                                  textAlign: "center",
                                }}
                              >
                                Updated{" "}
                                {moment(updatedAt).format("D MMMM YYYY, HH:mm")}
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {((showMobilPreview === true &&
                  window.matchMedia("(max-width: 1200px)").matches) ||
                  window.matchMedia("(min-width: 1200px)").matches) && (
                  <div className="col-md-12 col-xl-6">
                    <div className="create-page-preview post-setting-create-page-preview">
                      {/* <Suspense fallback=""> */}
                      <Preview
                        cmsData={cmsData}
                        title={title}
                        openItemIndex={openItemIndex}
                        diff={"post"}
                        publishImmediate={publishImmediate}
                        dateTimeValue={dateTimeValue}
                        rightSideRef={rightSideRef}
                        showAuthor={showAuthor}
                        showPublishDate={showPublishDate}
                        showCat={showCat}
                        showTag={showTag}
                        showRelatedPosts={showRelatedPosts}
                        selectedCat={catArrForPost}
                        selectedTag={tagArrForPost}
                        authorName={returnSelectedAuthorName(authorId)}
                        authorImg={returnSelectedImagePath(authorId)}
                        showAuthorRef={showAuthorRef}
                        showCatRef={showCatRef}
                        showTagRef={showTagRef}
                        showPublishDateRef={showPublishDateRef}
                        showRelatedPostsRef={showRelatedPostsRef}
                        setShowRelatedPosts={setShowRelatedPosts}
                        postId={
                          postID !== undefined &&
                          postID !== null &&
                          postID !== "create" &&
                          postID !== ""
                            ? postID
                            : ""
                        }
                        atrListingData={atrListingData}
                        adsData={adsData}
                        selectedDevice={selectedPreviewDevice}
                        sectionTopMargin={sectionTopMargin}
                        sectionBottomMargin={sectionBottomMargin}
                        sectionLeftMargin={sectionLeftMargin}
                        sectionRightMargin={sectionRightMargin}
                        imageShape={imgShapeForDetails}
                        imgCenterForDetails={imgCenterForDetails}
                        imgCenterForListing={imgCenterForListing}
                        imgShapeForListing={imgShapeForListing}
                        imgRatioForListing={imgRatioForListing}
                        postState={postState}
                        // dummyArr={dummyArr}
                      />
                      {checkIfCustomAdBlockIsAdded() && (
                        <div className="newplatswitch notificationplat">
                          <ul
                            id="myTabs"
                            className="nav nav-pills nav-justified  d-flex justify-content-center"
                            role="tablist"
                            data-tabs="tabs"
                          >
                            <li
                              style={{
                                marginRight: "16px",
                              }}
                              className={`android-icontochange ${
                                selectedPreviewDevice === "android"
                                  ? " techselect"
                                  : "technotselect"
                              }`}
                            >
                              <svg
                                onClick={(e) =>
                                  handleDeviceChange(e, "android")
                                }
                                xmlns="http://www.w3.org/2000/svg"
                                width="18.591"
                                height="22"
                                viewBox="0 0 18.591 22"
                              >
                                <path
                                  id="Path_7126"
                                  data-name="Path 7126"
                                  d="M1.376,15.116A1.3,1.3,0,0,1,0,13.912V8.761A1.3,1.3,0,0,1,1.376,7.556,1.3,1.3,0,0,1,2.753,8.761v5.151A1.3,1.3,0,0,1,1.376,15.116Zm10.411,5.037,1.2,1.2a.379.379,0,0,1-.537.536l-1.48-1.478a7.024,7.024,0,0,1-3.32,0L6.175,21.889a.379.379,0,1,1-.537-.536l1.2-1.193A4.85,4.85,0,0,1,3.45,15.8c0-.023,11.706,0,11.706,0A4.843,4.843,0,0,1,11.788,20.153ZM7.582,17.178a.687.687,0,1,0,.689.687A.687.687,0,0,0,7.582,17.178Zm3.444,0a.687.687,0,1,0,.689.687A.687.687,0,0,0,11.025,17.178Zm6.19-2.062a1.3,1.3,0,0,1-1.377-1.2V8.761a1.389,1.389,0,0,1,2.753,0v5.151A1.3,1.3,0,0,1,17.216,15.116ZM3.45,6.875a2.063,2.063,0,0,1,2.059-2.06V1.205A1.3,1.3,0,0,1,6.886,0,1.3,1.3,0,0,1,8.261,1.205V4.814H10.33V1.205A1.3,1.3,0,0,1,11.706,0a1.3,1.3,0,0,1,1.376,1.205V4.814h.009a2.063,2.063,0,0,1,2.066,2.06v8.243H3.451V6.875Z"
                                  transform="translate(18.591 22) rotate(180)"
                                  fill=""
                                />
                              </svg>
                            </li>

                            <li
                              style={{
                                marginRight: "0px",
                              }}
                              className={`ios-icontochange ${
                                selectedPreviewDevice === "ios"
                                  ? " techselect"
                                  : "technotselect"
                              } `}
                            >
                              <svg
                                onClick={(e) => handleDeviceChange(e, "ios")}
                                xmlns="http://www.w3.org/2000/svg"
                                width="18.417"
                                height="22"
                                viewBox="0 0 18.417 22"
                              >
                                <g id="apple-logo" transform="translate(0)">
                                  <g
                                    id="Group_1428"
                                    data-name="Group 1428"
                                    transform="translate(0 0)"
                                  >
                                    <path
                                      id="Path_668"
                                      data-name="Path 668"
                                      d="M55.02,116.775a5.034,5.034,0,0,1-.912-2.948,4.711,4.711,0,0,1,.859-2.737,8.568,8.568,0,0,1,1.507-1.56,6.818,6.818,0,0,0-1.375-1.322,4.834,4.834,0,0,0-2.816-.859A7.461,7.461,0,0,0,50,107.8a6.621,6.621,0,0,1-1.891.45,8.505,8.505,0,0,1-1.825-.4,8.8,8.8,0,0,0-2.34-.4,4.564,4.564,0,0,0-3.755,1.9,7.844,7.844,0,0,0-1.494,4.945,13.554,13.554,0,0,0,1.944,6.65q1.97,3.424,3.979,3.424a4.779,4.779,0,0,0,1.745-.45,5.1,5.1,0,0,1,1.878-.436,5.846,5.846,0,0,1,2,.423,6.112,6.112,0,0,0,1.851.423q1.692,0,3.4-2.591a12.762,12.762,0,0,0,1.626-3.305A4.022,4.022,0,0,1,55.02,116.775Z"
                                      transform="translate(-38.692 -102.378)"
                                      fill=""
                                    />
                                    <path
                                      id="Path_669"
                                      data-name="Path 669"
                                      d="M236.488,4.178a5.427,5.427,0,0,0,1.23-1.824,5.044,5.044,0,0,0,.383-1.8c0-.035,0-.079-.007-.132s-.006-.1-.006-.132a1.28,1.28,0,0,1-.033-.145A1.224,1.224,0,0,0,238.021,0a5.269,5.269,0,0,0-3.305,1.957,6.03,6.03,0,0,0-1.031,3.4,7.388,7.388,0,0,0,1.375-.225A3.747,3.747,0,0,0,236.488,4.178Z"
                                      transform="translate(-224.655 0)"
                                      fill=""
                                    />
                                  </g>
                                </g>
                              </svg>
                            </li>
                          </ul>
                        </div>
                      )}
                      {/* </Suspense> */}
                      <div
                        className="cmsmobilepreview"
                        onClick={() => {
                          setShowhowMobilPreview(!showMobilPreview);
                        }}
                      >
                        <img
                          src={
                            require("../../assets/images/dashboard/preview-cross.png")
                              .default
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <Modal
          className="protip-popup-message"
          isOpen={
            window.matchMedia("(max-width: 1200px)").matches
              ? openMobileCatDropdown
              : false
          }
          centered
        >
          <ModalBody>
            <div className="mobileglobalsearch">
              <button onClick={handleMobileCatDropdown}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.759"
                  height="15.408"
                  viewBox="0 0 20.759 15.408"
                >
                  <g
                    id="Group_27465"
                    data-name="Group 27465"
                    transform="translate(-19.742 -22.295)"
                  >
                    <path
                      id="Path_81245"
                      data-name="Path 81245"
                      d="M-13783.922-19217h19.346"
                      transform="translate(13805.077 19247)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                    <path
                      id="Path_82232"
                      data-name="Path 82232"
                      d="M14053.656,19255.426l-7,7,7,7"
                      transform="translate(-14025.504 -19232.424)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </button>
              {categoryData.length > 0 && (
                <input
                  placeholder="Search"
                  type="text"
                  value={catSearchVal}
                  onChange={(e) => {
                    setCatSearchVal(e.target.value);
                  }}
                ></input>
              )}
              {catSearchVal.trim() && (
                <p
                  onClick={(e) => {
                    setCatSearchVal("");
                  }}
                >
                  Clear
                </p>
              )}
            </div>

            <div className="mobilesearchsuggestion">
              {categoryData.length === 0 && (
                <div className="no-app-dropdown">
                  <img
                    className="img-fluid"
                    src={
                      require("../../assets/images/dashboard/no-apps-created.png")
                        .default
                    }
                    alt="No App Found"
                  />
                </div>
              )}
             {categoryData &&
                                                categoryData
                                                  .filter((row) =>
                                                    row.name
                                                      .toLowerCase()
                                                      .includes(
                                                        catSearchVal.toLowerCase()
                                                      )
                                                  )
                                                  .map((res,indx) => {
                                                    return (
                                                      <div
                                                      className="category-created"
                                                       
                                                      >
                                                        <label className="category-list-container" for={`cat${indx}`}>
                                                        <input type="checkbox" id={`cat${indx}`} name="category1" 
                                                         onChange={(e) =>
                                                          handleAddCatDrop(e,res,indx)
                                                        }
                                                        // value={res}
                                                        checked={catCheckedArr[indx]}
                                                        />
                                                        <span className="category-checkmark"></span>
                                                          {res.level < 9 &&
                                                            handleDash(
                                                              res.level
                                                            )}
                                                          {res.name}
                                                        </label>
                                                      </div>
                                                    );
                                                  })}
            </div>
          </ModalBody>
        </Modal> */}

        {/* <Modal
          className="protip-popup-message"
          isOpen={
            window.matchMedia("(max-width: 1200px)").matches
              ? openMobileTagDropdown
              : false
          }
          centered
        >
          <ModalBody>
            <div className="mobileglobalsearch">
              <button onClick={handleMobileTagDropdown}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.759"
                  height="15.408"
                  viewBox="0 0 20.759 15.408"
                >
                  <g
                    id="Group_27465"
                    data-name="Group 27465"
                    transform="translate(-19.742 -22.295)"
                  >
                    <path
                      id="Path_81245"
                      data-name="Path 81245"
                      d="M-13783.922-19217h19.346"
                      transform="translate(13805.077 19247)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                    <path
                      id="Path_82232"
                      data-name="Path 82232"
                      d="M14053.656,19255.426l-7,7,7,7"
                      transform="translate(-14025.504 -19232.424)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </button>
              {tagData.length > 0 && (
                <input
                  placeholder="Search"
                  type="text"
                  value={tagSearchVal}
                  onChange={(e) => {
                    setTagSearchVal(e.target.value);
                  }}
                ></input>
              )}
              {tagSearchVal.trim() && (
                <p
                  onClick={(e) => {
                    setTagSearchVal("");
                  }}
                >
                  Clear
                </p>
              )}
            </div>

            <div className="mobilesearchsuggestion">
              {tagData.length === 0 && (
                <div className="no-app-dropdown">
                  <img
                    className="img-fluid"
                    src={
                      require("../../assets/images/dashboard/no-apps-created.png")
                        .default
                    }
                    alt="No App Found"
                  />
                </div>
              )}
             {tagData &&
                                                tagData
                                                  .filter((row) =>
                                                    row.name
                                                      .toLowerCase()
                                                      .includes(
                                                        tagSearchVal.toLowerCase()
                                                      )
                                                  )
                                                  .map((res,indx) => {
                                                    return (
                                                      <div
                                                      className="category-created"
                                                       
                                                      >
                                                        <label className="category-list-container" for={`tagMobile${indx}`}>
                                                        <input type="checkbox" id={`tagMobile${indx}`} name="tagMobile1" 
                                                         onChange={(e) =>
                                                          handleAddTagDrop(e,res,indx)
                                                        }
                                                        // value={res}
                                                        checked={tagCheckedArr[indx]}
                                                        />
                                                        <span className="category-checkmark"></span>
                                                         
                                                          {res.name}
                                                        </label>
                                                      </div>
                                                    );
                                                  })}
            </div>
          </ModalBody>
        </Modal> */}

        {/* Author Mobile Dropdown */}
        <Modal
          className="protip-popup-message"
          isOpen={
            window.matchMedia("(max-width: 1200px)").matches
              ? openMobileAuthorDropdown
              : false
          }
          centered
        >
          <ModalBody>
            <div className="mobileglobalsearch">
              <button onClick={handleMobileAuthorDropdown}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.759"
                  height="15.408"
                  viewBox="0 0 20.759 15.408"
                >
                  <g
                    id="Group_27465"
                    data-name="Group 27465"
                    transform="translate(-19.742 -22.295)"
                  >
                    <path
                      id="Path_81245"
                      data-name="Path 81245"
                      d="M-13783.922-19217h19.346"
                      transform="translate(13805.077 19247)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                    <path
                      id="Path_82232"
                      data-name="Path 82232"
                      d="M14053.656,19255.426l-7,7,7,7"
                      transform="translate(-14025.504 -19232.424)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </button>
              {/* {

          allPage.length > 0 &&
          <input placeholder="Search" type="text" value={searchPageVal}
                                  onChange={(e)=>{
                                   setSearchPageVal(e.target.value)
                                  }}
                                  ></input>
                                }
              {searchPageVal.trim() && <p onClick={(e)=>{
                                   setSearchPageVal("")
                                }}>Clear</p> } */}
            </div>

            <div className="mobilesearchsuggestion">
              {mergeUserOwnerData &&
                mergeUserOwnerData.map((res) => {
                  return (
                    res.status !== 0 && (
                      <div className="cms-author-mobile">
                        <div>
                          {res.profile_image &&
                          res.profile_image.indexOf("https") > -1 ? (
                            <img
                              className="img-fluid"
                              src={res.profile_image}
                              alt="profile-img"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = imagebackground;
                              }}
                            />
                          ) : (
                            <img
                              className="img-fluid"
                              src={
                                process.env.REACT_APP_Image_Path +
                                res.user_id +
                                process.env.REACT_APP_Profile +
                                res.profile_image
                              }
                              alt="profile-img"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = imagebackground;
                              }}
                            />
                          )}
                        </div>

                        <p onClick={(e) => handleAuthorChange(e, res.user_id)}>
                          {res.name}
                        </p>
                      </div>
                    )
                  );
                })}
            </div>
          </ModalBody>
        </Modal>
        {/* Post related to Mobile Dropdown */}
        <Modal
          className="protip-popup-message"
          isOpen={
            window.matchMedia("(max-width: 1200px)").matches
              ? openMobileRelatedToDropdown
              : false
          }
          centered
        >
          <ModalBody>
            <div className="mobileglobalsearch">
              <button onClick={handleMobileRelatedToDropdown}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.759"
                  height="15.408"
                  viewBox="0 0 20.759 15.408"
                >
                  <g
                    id="Group_27465"
                    data-name="Group 27465"
                    transform="translate(-19.742 -22.295)"
                  >
                    <path
                      id="Path_81245"
                      data-name="Path 81245"
                      d="M-13783.922-19217h19.346"
                      transform="translate(13805.077 19247)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                    <path
                      id="Path_82232"
                      data-name="Path 82232"
                      d="M14053.656,19255.426l-7,7,7,7"
                      transform="translate(-14025.504 -19232.424)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </button>
            </div>

            <div className="mobilesearchsuggestion">
              {/* <p  style={{	
            fontSize:"16px",	
            color: "#1B2952",	
             fontFamily: "Axiforma-Regular",	
            margin: "0px 20px 40px 20px",	
             display: "block",	
          }}
            onClick={(e)=>handleRelatedToChange(e,"")}
            active={postRelatedTo===""}
            >Select
            </p> */}

              <p
                className="mobilesearchsuggestion-para"
                onClick={(e) => handleRelatedToChange(e, "authors")}
                active={postRelatedTo === "authors"}
              >
                Authors
              </p>

              <p
               className="mobilesearchsuggestion-para"
                onClick={(e) => handleRelatedToChange(e, "categories")}
                active={postRelatedTo === "categories"}
              >
                Categories
              </p>

              <p
               className="mobilesearchsuggestion-para"
                onClick={(e) => handleRelatedToChange(e, "featured")}
                active={postRelatedTo === "featured"}
              >
                Featured
              </p>

              <p
                className="mobilesearchsuggestion-para"
                onClick={(e) => handleRelatedToChange(e, "tags")}
                active={postRelatedTo === "tags"}
              >
                Tags
              </p>
            </div>
          </ModalBody>
        </Modal>
        {/* Atribut Mobile Pop Up */}
        {/* <Modal
          className="protip-popup-message"
          isOpen={
            window.matchMedia("(max-width: 1200px)").matches
              ? openMobAttributeDropdown
              : false
          }
          centered
        >
          <ModalBody>
            <div className="mobileglobalsearch">
              <button onClick={handleMobileAttributeDropdown}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.759"
                  height="15.408"
                  viewBox="0 0 20.759 15.408"
                >
                  <g
                    id="Group_27465"
                    data-name="Group 27465"
                    transform="translate(-19.742 -22.295)"
                  >
                    <path
                      id="Path_81245"
                      data-name="Path 81245"
                      d="M-13783.922-19217h19.346"
                      transform="translate(13805.077 19247)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                    <path
                      id="Path_82232"
                      data-name="Path 82232"
                      d="M14053.656,19255.426l-7,7,7,7"
                      transform="translate(-14025.504 -19232.424)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </button>
              {atrListingData.length > 0 && (
                <input
                  placeholder="Search"
                  type="text"
                  value={atrSearchVal}
                  onChange={(e) => {
                    setAtrSearchVal(e.target.value);
                  }}
                ></input>
              )}
              {atrSearchVal.trim() && (
                <p
                  onClick={(e) => {
                    setAtrSearchVal("");
                  }}
                >
                  Clear
                </p>
              )}
            </div>

            <div className="mobilesearchsuggestion">
              {atrListingData.length === 0 && (
                <div className="no-app-dropdown">
                  <img
                    className="img-fluid"
                    src={
                      require("../../assets/images/dashboard/no-apps-created.png")
                        .default
                    }
                    alt="No App Found"
                  />
                </div>
              )}
             {atrListingData &&
                atrListingData
                  .filter((row) =>
                    row.attribute_name
                      .toLowerCase()
                      .includes(
                        atrSearchVal.toLowerCase()
                      )
                  )
                  .map((res,indx) => {
                    return (
                      <div
                      className="category-created attributes-added attributes-added-mob"

                       
                      >
                        <div className="atr-plus-minus">
                          {
                          res.attribute_terms &&res.attribute_terms.length > 0  &&
                          
                          <>

                        {openAttDropSelectedRow[indx]===true?
                        <svg onClick={(e)=>handleIsRowOpenForAttDrop(e,indx)} xmlns="http://www.w3.org/2000/svg" width="12.001" height="12.001" viewBox="0 0 12.001 12.001">
                          <g id="Group_41420" data-name="Group 41420" transform="translate(-1625.001 -271.996)">
                            <path id="Path_102802" data-name="Path 102802" d="M1630.5,193.655v12" transform="translate(0.501 78.341)" fill="none"/>
                            <path id="Path_102803" data-name="Path 102803" d="M.75,12H-.75V0H.75Z" transform="translate(1637.002 277.996) rotate(90)" fill="#bcc1ce"/>
                          </g>
                        </svg>
                        :
                        <svg onClick={(e)=>handleIsRowOpenForAttDrop(e,indx)}  xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                          <path id="Union_2686" data-name="Union 2686" d="M6238.75,16452h-1.5v-5.248H6232v-1.5h5.25V16440h1.5v5.252H6244v1.5h-5.25Z" transform="translate(-6232 -16440.004)" fill="#bcc1ce"/>
                        </svg>
                          } 
                          </>
                  }
                        <label className="parent-atr category-list-container" for={`attributeMob${indx}`}>

                        
                        <input type="checkbox" 
                        id={`attributeMob${indx}`}
                         name="attribute2"
                         checked={res.is_checked}
                         onChange={(e) =>
                          handleAddAttribute(e,res._id)
                        }
                         />

                        <span className="category-checkmark"></span>
                        
                          {res.attribute_name}
                        </label>
                        </div>


                        {res.attribute_terms.length>0&&openAttDropSelectedRow[indx]===true &&
                        res.attribute_terms.map((eachVal,valIndx)=>{
                          return(
                            <label className="child-atr  category-list-container" for={`attributeValMob${valIndx}`}>
                            <input type="checkbox" id={`attributeValMob${valIndx}`} name="attributeVal2" 
                             onChange={(e) =>
                              handleAddAttributeVal(e,eachVal._id)
                            }
                       
                            checked={eachVal.is_checked}
                            />
                            <span className="category-checkmark"></span>
                            
                              {eachVal.attribute_term_name}
                            </label>
                          );
                        })
                      
                        }
                      </div>
                    );
                  })}
            </div>
          </ModalBody>
        </Modal> */}

        <NewImageGalleryPopup
          isGalleryPopup={isGalleryPopup}
          setIsGalleryPopup={setIsGalleryPopup}
          settingYourdata={settingYourdata}
          yourErrFun={yourErrFun}
          uploadPath={
            localStorage.getItem("user_id") +
            "/" +
            localStorage.getItem("Appid") +
            process.env.REACT_APP_CMS_Media
          }
          settingOrgImgFrom3rdParty={false}
          hideImageGallery={false}
          widthForRestriction={1125}
          heightForRestriction={480}
          aspectRatio={"16:9"}
          isNotCustomUploadOrgImg={true}
          isCms={true}
          designid={""}
          loadpicVistaCreatePopUp={loadpicVistaCreatePopUp}
          closeVistaCreatePopUp={closeVistaCreatePopUp}
          upload1200Url={false}

          // searchValForPopUp ={searchValForPopUp}
          // setSearchValForPopUp ={setSearchValForPopUp}
        />

        {/* For Category */}

        <MultiSelectionPopUpForCustomApp
          openPopUp={openCatMultiSelectionPopUp}
          handlePopUp={handleOpenCatSelectionPopUp}
          data={categoryData}
          handleInputClick={handleCatMultiSelectionChange}
          selectedArr={tempCatSelection}
          subData={catSubData}
          setSubData={setCatSubData}
          searchVal={catSearchValForMultiPopUp}
          setSearchVal={setCatSearchValForMultiPopUp}
          breadcrumbArr={catBreadcrumbArr}
          setBreadcrumbArr={setCatBreadcrumbArr}
          from={"category"}
          oldBreadcrumbArr={oldCatBreadcrumbArr}
          oldSubData={oldCatSubData}
          handleBottomScrollListner={handleBottomScrollListnerForCat}
          paginationSpinner={paginationSpinnerForCat}
        />

        {/* For Tag */}

        <MultiSelectionPopUpForCustomApp
          openPopUp={openTagMultiSelectionPopUp}
          handlePopUp={handleOpenTagSelectionPopUp}
          data={tagData}
          handleInputClick={handleTagMultiSelectionChange}
          selectedArr={tempTagSelection}
          subData={tagSubData}
          setSubData={setTagSubData}
          searchVal={tagSearchValForMultiPopUp}
          setSearchVal={setTagSearchValForMultiPopUp}
          breadcrumbArr={tagBreadcrumbArr}
          setBreadcrumbArr={setTagBreadcrumbArr}
          from={"category"}
          oldBreadcrumbArr={oldTagBreadcrumbArr}
          oldSubData={oldTagSubData}
          handleBottomScrollListner={handleBottomScrollListnerForTag}
          paginationSpinner={paginationSpinnerForTag}
        />

        {/* For Attribute */}

        <MultiSelectionPopUpForAttribute
          openPopUp={openAtrMultiSelectionPopUp}
          handlePopUp={handleOpenAtrSelectionPopUp}
          data={atrListingData}
          handleInputClick={handleAtrMultiSelectionChange}
          selectedArr={tempAtrSelection}
          subData={atrSubData}
          setSubData={setAtrSubData}
          searchVal={atrSearchValForMultiPopUp}
          setSearchVal={setAtrSearchValForMultiPopUp}
          breadcrumbArr={atrBreadcrumbArr}
          setBreadcrumbArr={setAtrBreadcrumbArr}
          from={"category"}
          oldBreadcrumbArr={oldAtrBreadcrumbArr}
          oldSubData={oldAtrSubData}
          handleBottomScrollListner={handleBottomScrollListnerForAtr}
          paginationSpinner={paginationSpinnerForAtr}
        />
      </section>
    </>
  );
};

export default EditPost;
