import React from "react";

const BackwardIcon = ({ state }) => {
  return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="7.273"
        height="12.849"
        viewBox="0 0 7.273 12.849"
      >
        <g
          id="Group_38818"
          data-name="Group 38818"
          transform="translate(0.849 0.424)"
        >
          <g id="Group_38817" data-name="Group 38817" transform="translate(0)">
            <path
              id="Path_102772"
              data-name="Path 102772"
              d="M0,6,6,0l6,6"
              transform="translate(0 12) rotate(-90)"
              fill="none"
              stroke={state.iconColor}
              stroke-width="1.2"
            />
          </g>
        </g>
      </svg>
  );
};

export default BackwardIcon;
