import React, { useState, useEffect } from "react";
import Header from "../Header";
import Accountleftpanel from "../Accountleftpanel";
import "../User/User.css"
import { Spinner, Modal, ModalHeader, ModalBody } from "reactstrap";
import {
  Userlist,
  Saveuser,
  Deleteuser,
  Changestatus,
  DeleteWorkspaceuser,
  saveUserForWorkspace,
  getUserForWorkspace,
  WorkspaceChangestatus
} from "../../Api/User/Userapi";
import { Notaddonactive } from "../User/Notaddonactive";
import { Limit } from "../../Components/commonComponents/Team/Limitmodal";

import { Notallowedtouser } from "../User/Usernotalllowed";
import moment from "moment";
import { Limittrigger } from "../User/Context";
export const Team = (props) => {
  const [submitval, setsubmit] = useState("INVITE");
  const [loader, setLoader] = useState("d-none");
  const loaderimage =
    require("../../assets/images/signup/Loader_GIF.gif").default;
  const { className } = props;
  const [spinner, setSpinner] = useState(true);
  const [data, setData] = useState([]);
  const [allowed_screen, setallowed_screen] = useState("");
  const [userdata, setUserdata] = useState(null);
  const [user_id, setuser_id] = useState("");
  const [userEmail, setuserEmail] = useState("");
  const [role_id, setrole_id] = useState(2);
  const [adduser, setadduser] = useState(false);
  const [droprole, setDroprole] = useState("");
  const [searchsuer, setSearchsuer] = useState("");
  const [user_email, setuser_email] = useState("");
  const [mode, setMode] = useState("adduser");
  const [user_role_id, setuser_role_id] = useState(null);
  const [subscription_plan, setSubscription_plan] = useState(null);
  const [user_count, setuser_count] = useState(null);
  const [limitreached, setlimitreached] = useState(false);
  const [addon_quantity, setaddon_quantity] = useState(null);
  const [allowed_users, setallowed_users] = useState(0);
  const [ownerData, setOwnerData] = useState(null);
  //count to call the useEffect function again
  const [count, setCount] = useState(1);
  const [plan, setPlan] = useState(null);
  const [openDeletePopUp,setOpenDeletePopUp] = useState(false);
  const [isLoader,setIsLoader] = useState("d-none");
  const [deleteUserID,setDeleteUserID] = useState(null);
  const [submitvalDeleteVal, setSubmitvalDeleteVal] = useState("Delete");
  //Send user data to form incase of Edit
  const editForm = (id, email, role_id, mode) => {
    setadduser(true);
    setuser_id(id);
    setuserEmail(email);
    setrole_id(role_id);
    setMode(mode);
    setsubmit("UPDATE");
  };

  //Modal open and close
  const addusertoggle = () => {
    setadduser(!adduser);
    setuserEmail("");
    setuser_id("");
    setrole_id(2);
    setLoader("d-none");
    setMode("adduser");
    setsubmit("INVITE");
  };

  //Add user button
  const adduserbutton = () => {
    // setadduser(!adduser);
    if (allowed_users === "") {
      setadduser(!adduser);
    } else {
      if (user_count >= addon_quantity + allowed_users) {
        setlimitreached(true);
      } else {
        setadduser(!adduser);
      }
    }
  };

  //Incase of any error
  // const errorhandling = () => {
  //   setLoader("d-none");
  //   setMode("adduser");
  //   setsubmit("INVITE");
  // };

  //call this function after the form submission
  const insCount = () => {
    setCount(count + 1);
    setuserEmail("");
    setLoader("d-none");
    setsubmit("INVITE");
    setMode("adduser");
    setsubmit("INVITE");
  };

  // Call table api onload
  useEffect(() => {
    // document.title = "Team | AppMySite";
    if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
    {
      document.title = "Team | AppMySite";
     
    }
    else
    {
      document.title = "Team |"+ ' ' +localStorage.getItem('agencyname');
    }
    setSpinner(true);

    const params = {
      workspace_id: btoa(parseInt(localStorage.getItem("workspaceId"))),
    };

    getUserForWorkspace(
      params,
      setSpinner,
      setOwnerData,
      setUserdata,
      setuser_role_id,
      setuser_email,
      setData,
      setSubscription_plan,
      setuser_count,
      setaddon_quantity,
      setallowed_users,
      setallowed_screen,
       setPlan
    );
  }, []);

  // Form Submission
  const finalForm = (e) => {
    e.preventDefault();
    setLoader("d-block");
    setsubmit("");
    const param = {
      id: user_id,
      // app_id: parseInt(localStorage.getItem("Appid")),
      workspace_id: parseInt(localStorage.getItem("workspaceId")),
      email: userEmail.trim(),
      role_id: parseInt(role_id),
    };
    saveUserForWorkspace(param, setLoader, setadduser, adduser);
  };

    //Delete user PopUp
    const handleDeleteUserID = (id) => {
      setDeleteUserID(id);
      setOpenDeletePopUp(!openDeletePopUp);
    };

  // Delete user


  const userdelete = (e,from) => {
    if(from==="add"){
    document.getElementById(deleteUserID + "pp").innerHTML = "Removing...";
    const deleteparams = {
      id: deleteUserID,
      app_id: "",
      workspace_id: parseInt(localStorage.getItem("workspaceId")),
    };
    DeleteWorkspaceuser(deleteparams, insCount);
  }
  setOpenDeletePopUp(!openDeletePopUp);
  };

  // Mobile edit user toggle
  const showMenu = (id) => {
    let data = document.getElementById(id);
    data.classList.remove("on-mobile");
  };
  const limitreached_modal = {
    limitreached_status: limitreached,
    subscription_plan: subscription_plan,
    handlelimitstatus: (e) => setlimitreached(false),
    addon_quantity: addon_quantity,
    plan:plan ,
  };
  const handlestatus = (e, id) => {
    let user_status_change = data.filter((res) => {
      if (res.id === id) {
        res.status = e.target.checked === true ? 1 : 2;
      }
      return res;
    });
    setData(user_status_change);

    const params = {
      id: id,
      app_id: "",
      workspace_id: parseInt(localStorage.getItem("workspaceId")),
      status: e.target.checked === true ? 1 : 2,
    };
	
	if(parseInt(localStorage.getItem("workspaceId")) !== undefined && parseInt(localStorage.getItem("workspaceId")) !== null && parseInt(localStorage.getItem("workspaceId")) !== '')
	{
		WorkspaceChangestatus(params, setlimitreached, () => {
		  let user_status_change_on_error = data.filter((res) => {
			if (res.id === id) {
			  res.status = 2;
			}
			return res;
		  });
		  setData(user_status_change_on_error);
		});
	}
	else
	{
		Changestatus(params, setlimitreached, () => {
		  let user_status_change_on_error = data.filter((res) => {
			if (res.id === id) {
			  res.status = 2;
			}
			return res;
		  });
		  setData(user_status_change_on_error);
		});
	}
  };
  //setting role ID

  return (
    <>
      <div className="">
        <Header customclass={"header-responsive-new"} />
      </div>

      <section className="dasboard_page mobile-display mobile-view-user">
        <Accountleftpanel
          ipadView={"initial-leftpanel-ipadview"}
          userCondition={"d-none"}
          checker={true}
        />
        <div className="transparent_background ipad-usermodule-view">

              <div
                className="right_panel custon_gap2 pb-4 ipad-usermodule-headpadding team-right-panel-div"
               
              >
                          {spinner ? (
           <div className="newspinner">
           <svg xmlns="http://www.w3.org/2000/svg" 
     xlink="http://www.w3.org/1999/xlink" 
     style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
     width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
     <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
     <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
       <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
       style={{animationplaystate: "running", animationdelay: "0s"}}>
       </animateTransform>
     </circle>
     </g>
     <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
     <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
     <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
     style={{animationplaystate: "running", animationdelay: "0s"}}>
     </animateTransform>
     </circle>
     </g>
     <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
     <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
     <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
     style={{animationplaystate: "running", animationdelay: "0s"}}>

     </animateTransform>
     </circle>
     </g>
     <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
     <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
     <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
     style={{animationplaystate: "running", animationdelay: "0s"}}>

     </animateTransform>
     </circle>
     </g>
     </svg>
         </div>
          ) : (
            <>
                <div className="right_general">
                  <div className="row">
                    {user_role_id === 4 ? (
                      <Notallowedtouser />
                    ) : (
                      <>
                        {subscription_plan !== null && (
                          <>
                            {subscription_plan ===
                            process.env.REACT_APP_Lifetime_preview ? (
                              Notaddonactive()
                            ) : allowed_screen !== 0 ? (
                              <div className="col-md-12">
                                <section>
                                  <div className="d-flex align-items-center justify-content-between top-portion alt-portion">
                                    <div className="app_iconname d-none d-md-block">
                                      <h3 className="mb-2 mt-2">
                                        User management
                                      </h3>
                                      <h5 className="d-none d-md-block">
                                        Collaborate with team members and
                                        clients in one place.
                                      </h5>
                                    </div>
                                    <div className="d-flex align-items-center user-role-dropdown justify-content-between">
                                      {/* {data.length > 0 && ( */}
                                      <div className="color_picker_full user-role-dropdown ">
                                        <div className="form-group store-url mt-0">
                                          <div className="d-flex justify-content-end align-items-center input-btn spinntop">
                                            <div className="user_status mr-2 d-none d-md-block">
                                              {subscription_plan !== null &&
                                                allowed_users !== "" &&
                                                parseInt(user_count + 1) <=
                                                  (subscription_plan !== null &&
                                                    parseInt(addon_quantity) +
                                                      parseInt(
                                                        allowed_users + 1
                                                      )) && (
                                                  <p className="mb-0 ">
                                                    {parseInt(user_count + 1)}/
                                                    {subscription_plan !==
                                                      null &&
                                                      parseInt(addon_quantity) +
                                                        parseInt(
                                                          allowed_users
                                                        ) +
                                                        1}{" "}
                                                    <span>used</span>
                                                  </p>
                                                )}
                                            </div>
                                            <div className="http ">
                                              <select
                                                name="websiteLocator"
                                                value={droprole}
                                                onChange={(e) =>
                                                  setDroprole(e.target.value)
                                                }
                                              >
                                                <option value="">All</option>
                                                <option value="1">Owner</option>
                                                <option value="2">Admin</option>
                                                <option value="3">
                                                  Manager
                                                </option>
                                                <option value="4">User</option>
                                              </select>
                                            </div>
                                            <input
                                              className=" form-control"
                                              type="text"
                                              value={searchsuer}
                                              placeholder="Search by email..."
                                              onChange={(e) =>
                                                setSearchsuer(e.target.value)
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      {/* )}  */}
                                      {user_role_id !== 3 && (
                                        <div
                                          className="create-new fixed-button ml-md-3"
                                          aria-disabled
                                          onClick={adduserbutton}
                                        >
                                          <div className="next_btn pointcursor mobilecreatenew add-notification d-flex align-items-center justify-content-between coming-soon">
                                            <svg
                                              width="22"
                                              height="16"
                                              viewBox="0 0 22 16"
                                              className="mr-2"
                                            >
                                              <g
                                                id="iconfinder_add_user_309049"
                                                transform="translate(-1 -4)"
                                              >
                                                <circle
                                                  id="Ellipse_2309"
                                                  data-name="Ellipse 2309"
                                                  cx="4"
                                                  cy="4"
                                                  r="4"
                                                  transform="translate(11 4)"
                                                  fill="#fff"
                                                />
                                                <path
                                                  id="Path_7193"
                                                  data-name="Path 7193"
                                                  d="M15,14c-6.1,0-8,4-8,4v2H23V18S21.1,14,15,14Z"
                                                  fill="#fff"
                                                />
                                                <line
                                                  id="Line_133"
                                                  data-name="Line 133"
                                                  y2="8"
                                                  transform="translate(5 7)"
                                                  fill="none"
                                                  stroke="#fff"
                                                  strokeMiterlimit="10"
                                                  strokeWidth="2"
                                                />
                                                <line
                                                  id="Line_134"
                                                  data-name="Line 134"
                                                  x1="8"
                                                  transform="translate(1 11)"
                                                  fill="none"
                                                  stroke="#fff"
                                                  strokeMiterlimit="10"
                                                  strokeWidth="2"
                                                />
                                              </g>
                                            </svg>
                                            <p>Add User</p>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className="d-flex align-items-center user-management justify-content-between table-top workspace-user-table-top"
                                   
                                  >
                                    <div className="user-detail">
                                      <p>Users</p>
                                    </div>
                                    <div className="user-role">
                                      <p>Role</p>
                                    </div>
                                    <div className="user-last-login">
                                      <p>Last Login</p>
                                    </div>
                                    <div className="user-status">
                                      <p>Status</p>
                                    </div>
                                    <div
                                      style={{ textAlign: "right" }}
                                      className="user-action"
                                    >
                                      <p>Action</p>
                                    </div>
                                  </div>
                                  {/* Owners Data */}

                                  {ownerData !== null &&
                                    (droprole === "" || droprole === "1") &&
                                    (searchsuer === "" ||
                                      ownerData.email
                                        .toLowerCase()
                                        .indexOf(searchsuer.toLowerCase()) !==
                                        -1) && (
                                      <div className="d-flex align-items-center justify-content-between user-details mt-3">
                                        <div className="user-detail d-flex align-items-start justify-content-start">
                                          <div className="mr-2">
                                            {ownerData.profile_image === null ||
                                            ownerData.profile_image === "" ||
                                            ownerData.profile_image ===
                                              undefined ||
                                            ownerData.profile_image ===
                                              "undefined" ? (
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/accountprofile.png")
                                                    .default
                                                }
                                                alt="jacob"
                                                className="img-fluid"
                                                height="45"
                                                width="45"
                                              />
                                            ) : (
                                              <>
                                                {ownerData.profile_image.indexOf(
                                                  "https"
                                                ) === -1 ? (
                                                  <div
                                                    className="proimg"
                                                    style={{
                                                      backgroundImage:
                                                        "url(" +
                                                        process.env
                                                          .REACT_APP_Image_Path +
                                                        (user_role_id === 1
                                                          ? localStorage.getItem(
                                                              "logged_in_user_id"
                                                            )
                                                          : localStorage.getItem(
                                                              "invited_by_user_id"
                                                            )) +
                                                        "/profile/" +
                                                        ownerData.profile_image +
                                                        ")",
                                                      width: "45px",
                                                      borderRadius: "50%",
                                                      height: "45px",
                                                      // backgroundRepeat: "no-repeat",
                                                      // backgroundSize: "cover",
                                                      // background
                                                    }}
                                                  ></div>
                                                ) : (
                                                  <div
                                                    style={{
                                                      background:
                                                        "url(" +
                                                        ownerData.profile_image +
                                                        ")",
                                                      width: "45px",
                                                      borderRadius: "50%",
                                                      height: "45px",
                                                      backgroundRepeat:
                                                        "no-repeat",
                                                      backgroundSize: "cover",
                                                    }}
                                                  ></div>
                                                )}
                                              </>
                                            )}
                                          </div>
                                          <div className="user-mobile-role">
                                            <h3>{ownerData.name}</h3>
                                            <p className="d-none d-md-block">
                                              {ownerData.email}
                                            </p>
                                            {/* Duplicate section for user details MOBILE starts */}
                                            <div className="d-flex d-md-none">
                                              <p>Owner</p>

                                              <p
                                                className="ml-2 user-last-logintag"
                                              
                                              >
                                                {ownerData.last_login === ""
                                                  ? "-"
                                                  : moment(
                                                      ownerData.last_login
                                                    ).format("Do MMM YYYY")}
                                                <span
                                                
                                                  className="mb-1 ml-1 d-inline userlastlogin-time"
                                                >
                                                  {" "}
                                                  {ownerData.last_login !==
                                                    "" &&
                                                    moment(
                                                      ownerData.last_login
                                                    ).format("h:mm:ss a")}
                                                </span>
                                                <br />
                                                {ownerData.last_login_IP === ""
                                                  ? ownerData.registration_IP ===
                                                    ""
                                                    ? ""
                                                    : "IP " +
                                                      ownerData.registration_IP
                                                  : "IP " +
                                                    ownerData.last_login_IP}
                                              </p>
                                            </div>
                                            {/* Duplicate section for user details MOBILE ends */}
                                          </div>
                                        </div>
                                        <div className="user-role d-none d-md-block">
                                          <p className="">Owner</p>
                                        </div>
                                        <div className="user-last-login d-none d-md-flex align-items-center justify-content-start">
                                          <div>
                                            <p className="mr-3 mb-1">
                                              {ownerData.last_login === ""
                                                ? "-"
                                                : moment(
                                                    ownerData.last_login
                                                  ).format("Do MMM YYYY")}
                                              <span
                                               
                                                className="mb-2 d-inline mt-1 userlastlogin-datetype"
                                              >
                                                {" "}
                                                {ownerData.last_login !== "" &&
                                                  moment(
                                                    ownerData.last_login
                                                  ).format("h:mm:ss a")}
                                              </span>
                                            </p>
                                            <p>
                                              {ownerData.last_login_IP === ""
                                                ? ownerData.registration_IP ===
                                                  ""
                                                  ? ""
                                                  : "IP " +
                                                    ownerData.registration_IP
                                                : "IP " +
                                                  ownerData.last_login_IP}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="user-status text-center userinvitation-tag" >
                                          -
                                        </div>
                                        <div className="user-action d-flex align-items-center justify-content-end">
                                          -
                                        </div>
                                      </div>
                                    )}
                                  {/* )} */}
                                  {/* Owners Data End*/}

                                  {/* Userdata */}

                                  {userdata &&
                                    userdata
                                      .filter((res) =>
                                        //Dropdown for rolw selection
                                        droprole === ""
                                          ? res.role_id
                                          : res.role_id === parseInt(droprole)
                                      )
                                      .filter((res) =>
                                        searchsuer === ""
                                          ? res.invitation_email
                                          : res.invitation_email
                                              .toLowerCase()
                                              .indexOf(
                                                searchsuer.toLowerCase()
                                              ) !== -1
                                      )
                                      .map((res) => {
                                        return (
                                          <div className="d-flex align-items-center justify-content-between user-details mt-3">
                                            <div className="user-detail d-flex align-items-start justify-content-start">
                                              <div className="mr-2">
                                                {res.image === null ||
                                                res.image === "" ||
                                                res.image === undefined ||
                                                res.image === "undefined" ? (
                                                  <img
                                                    src={
                                                      require("../../assets/images/dashboard/accountprofile.png")
                                                        .default
                                                    }
                                                    alt="jacob"
                                                    className="img-fluid"
                                                    height="45"
                                                    width="45"
                                                  />
                                                ) : (
                                                  <>
                                                    {res.image.indexOf(
                                                      "https"
                                                    ) === -1 ? (
                                                      <div
                                                        className="proimg"
                                                        style={{
                                                          backgroundImage:
                                                            "url(" +
                                                            process.env
                                                              .REACT_APP_Image_Path +
                                                            res.user_id +
                                                            "/profile/" +
                                                            res.image +
                                                            ")",
                                                          width: "45px",
                                                          borderRadius: "50%",
                                                          height: "45px",
                                                          // backgroundRepeat: "no-repeat",
                                                          // backgroundSize: "cover",
                                                          // background
                                                        }}
                                                      ></div>
                                                    ) : (
                                                      <div
                                                        style={{
                                                          background:
                                                            "url(" +
                                                            res.image +
                                                            ")",
                                                          width: "45px",
                                                          borderRadius: "50%",
                                                          height: "45px",
                                                          backgroundRepeat:
                                                            "no-repeat",
                                                          backgroundSize:
                                                            "cover",
                                                        }}
                                                      ></div>
                                                    )}
                                                  </>
                                                )}
                                              </div>
                                              <div className="user-mobile-role">
                                                <h3>
                                                  {res.user_name === ""
                                                    ? "-"
                                                    : res.user_name}
                                                </h3>
                                                <p className="d-none d-md-block">
                                                  {res.invitation_email}
                                                </p>
                                                {/* Duplicate section for user details MOBILE starts */}
                                                <div className="d-flex d-md-none">
                                                  <p>
                                                    {" "}
                                                    {(() => {
                                                      if (res.role_id === 1) {
                                                        return "Owner";
                                                      } else if (
                                                        res.role_id === 2
                                                      ) {
                                                        return "Admin";
                                                      } else if (
                                                        res.role_id === 3
                                                      ) {
                                                        return "Manager";
                                                      } else {
                                                        return "User";
                                                      }
                                                    })()}
                                                  </p>

                                                  <p
                                                    className="ml-2 user-last-logintag"
                                                  
                                                  >
                                                    {res.last_login === ""
                                                      ? "-"
                                                      : moment(
                                                          res.last_login
                                                        ).format("Do MMM YYYY")}
                                                    <span
                                                     
                                                      className="mb-1 ml-1 d-inline userlastlogin-time"
                                                    >
                                                      {" "}
                                                      {res.last_login !== "" &&
                                                        moment(
                                                          res.last_login
                                                        ).format("h:mm:ss a")}
                                                    </span>
                                                    <br />
                                                    {res.last_login_IP === ""
                                                      ? res.registration_IP ===
                                                        ""
                                                        ? ""
                                                        : "IP " +
                                                          res.registration_IP
                                                      : "IP " +
                                                        res.last_login_IP}
                                                  </p>
                                                </div>
                                                {/* Duplicate section for user details MOBILE ends */}
                                              </div>
                                            </div>
                                            <div className="user-role d-none d-md-block">
                                              <p>
                                                {" "}
                                                {(() => {
                                                  if (res.role_id === 1) {
                                                    return "Owner";
                                                  } else if (
                                                    res.role_id === 2
                                                  ) {
                                                    return "Admin";
                                                  } else if (
                                                    res.role_id === 3
                                                  ) {
                                                    return "Manager";
                                                  } else {
                                                    return "User";
                                                  }
                                                })()}
                                              </p>
                                            </div>
                                            <div className="user-last-login d-none d-md-flex align-items-center justify-content-start">
                                              
                                                <div>
                                                  <p className="mr-3 mb-1">
                                                    {res.last_login === ""
                                                      ? "-"
                                                      : moment(
                                                          res.last_login
                                                        ).format("Do MMM YYYY")}
                                                    <span
                                                     
                                                      className="mb-2 d-inline mt-1 userlastlogin-datetype"
                                                    >
                                                      {" "}
                                                      {res.last_login !== "" &&
                                                        moment(
                                                          res.last_login
                                                        ).format("h:mm:ss a")}
                                                    </span>
                                                  </p>
                                                  <p>
                                                    {res.last_login_IP === ""
                                                      ? res.registration_IP ===
                                                        ""
                                                        ? ""
                                                        : "IP " +
                                                          res.registration_IP
                                                      : "IP " +
                                                        res.last_login_IP}
                                                  </p>
                                                </div>
                                              
                                            </div>

                                            {res.status === 0 ? (
                                                <p className=" user-status text-center invitationsent userinvitation-tag">
                                                
                                                  Invitation Sent
                                                </p>
                                              )
                                            : res.status > 0 ? (
                                              <div className="user-status text-center">
                                                {user_email !==
                                                  res.invitation_email &&
                                                  (res.role_id >=
                                                  user_role_id ? (
                                                    <>
                                                      <div className="on_off d-flex ">
                                                        <label className="switch">
                                                          <input
                                                            type="checkbox"
                                                            id={res.id}
                                                            checked={
                                                              res.status === 1
                                                                ? true
                                                                : false
                                                            }
                                                            onChange={(e) =>
                                                              handlestatus(
                                                                e,
                                                                res.id
                                                              )
                                                            }
                                                          />
                                                          <div className="slider round"></div>
                                                        </label>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <></>
                                                  ))}
                                              </div>
                                            ) : (
                                              <div className="user-status text-center"></div>
                                            )}
                                            <div
                                              className={
                                                res.status === 1 ||
                                                res.status === 2
                                                  ? "user-action d-none d-md-flex align-items-center justify-content-between"
                                                  : "user-action d-none d-md-flex align-items-center justify-content-end"
                                              }
                                            >
                                              {user_email !==
                                                res.invitation_email &&
                                                (res.role_id >= user_role_id ? (
                                                  <>
                                                    {(res.status === 1 ||
                                                      res.status === 2) && (
                                                      <p
                                                        onClick={(e) =>
                                                          editForm(
                                                            res.id,
                                                            res.invitation_email,
                                                            res.role_id,
                                                            "editmode"
                                                          )
                                                        }
                                                      >
                                                        Edit
                                                      </p>
                                                    )}
                                                    <p
                                                      onClick={(e) =>
                                                        handleDeleteUserID(res.id)
                                                      }
                                                      id={res.id + "pp"}
                                                    >
                                                      {" "}
                                                      Remove
                                                    </p>
                                                  </>
                                                ) : (
                                                  <></>
                                                ))}
                                            </div>
                                            {/* Mobile dots section starts */}
                                            {user_email !==
                                              res.invitation_email && (
                                              <div
                                                onClick={(e) =>
                                                  showMenu("id" + res.id)
                                                }
                                                className="d-md-none edit-dots"
                                              >
                                                <img
                                                  src={
                                                    require("../../assets/images/dashboard/dots.png")
                                                      .default
                                                  }
                                                  alt="dots"
                                                  className="d-md-none mobil-dots"
                                                />
                                              </div>
                                            )}
                                            {/* Mobile dots section ends */}
                                            {/* Mobile edit & remove dropdown starts */}

                                            <div className="showselection d-md-none">
                                              <div
                                                id={"id" + res.id}
                                                className="user-action on-mobile"
                                              >
                                                {user_email !==
                                                  res.invitation_email &&
                                                  (res.role_id >=
                                                  user_role_id ? (
                                                    <>
                                                      {(res.status === 1 ||
                                                        res.status === 2) && (
                                                        <p
                                                          onClick={(e) =>
                                                            editForm(
                                                              res.id,
                                                              res.invitation_email,
                                                              res.role_id,
                                                              "editmode"
                                                            )
                                                          }
                                                        >
                                                          Edit
                                                        </p>
                                                      )}
                                                      <p
                                                        onClick={(e) =>
                                                          handleDeleteUserID(res.id)
                                                        }
                                                        id={res.id}
                                                      >
                                                        {" "}
                                                        Remove
                                                      </p>
                                                    </>
                                                  ) : (
                                                    <></>
                                                  ))}
                                              </div>
                                            </div>

                                            {/* Mobile edit & remove dropdown ends */}
                                          </div>
                                        );
                                      })}
                                </section>
                              </div>
                            ) : (
                              Notaddonactive()
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
                </>
          )}
              </div>
           
        </div>
      </section>
      {/* Limit Reached modal  */}
      <Limittrigger.Provider value={limitreached_modal}>
        <Limit />
      </Limittrigger.Provider>
      {/* Limit Reached modal  End*/}
      <Modal
        isOpen={adduser}
        toggle={addusertoggle}
        style={{ maxWidth: "750px", maxHeight: "247px" }}
        className={
          // className
          +" user-popup-mobile"
        }
        centered
        dataclass="useradd"
      >
        <ModalHeader
          toggle={addusertoggle}
          className="modal-xxl-content invite-user pb-0"
        >
          <p className="user_modalheading">
            {mode === "adduser" ? "Invite new users " : "Edit Role"}
          </p>
          <p>
            {mode === "adduser"
              ? "Once an invitation is accepted, your user will be able to access your app based on the role and permissions allocated."
              : "Edit role for user " + userEmail}
          </p>
        </ModalHeader>
        <ModalBody className="invite-user-body">
          <form id="userform" onSubmit={(e) => finalForm(e)}>
            {mode === "adduser" && (
              <div className="form-group ">
                <input
                  type="email"
                  placeholder="emailaddress@example.com"
                  className="w-100"
                  // value={userEmail}
                  required
                  onChange={(e) => setuserEmail(e.target.value)}
                />
              </div>
            )}
            <div className=" push_notification ">
              <div className="color_picker w-100">
                <label className="container_1 mr-4">
                  <div>
                    <h5>Admin</h5>
                    <p>Best for business owners and company administrators.</p>
                  </div>
                  <input
                    type="radio"
                    name="admin"
                    value={2}
                    defaultChecked={role_id === 2 ? true : false}
                    onClick={(e) => setrole_id(e.target.value)}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="color_picker w-100">
                <label className="container_1 mr-4">
                  <div>
                    <h5>Manager</h5>
                    <p>
                      Best for people who will need to manage the app, without
                      access to billing or subscription.{" "}
                    </p>
                  </div>
                  <input
                    type="radio"
                    name="admin"
                    value={3}
                    defaultChecked={role_id === 3 ? true : false}
                    onClick={(e) => setrole_id(e.target.value)}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="color_picker w-100 ">
                <label className="container_1 mr-4">
                  <div>
                    <h5>User</h5>
                    <p>
                      Best for users who will need to manage the app but will
                      not require access to billing, subscription, or user
                      management.
                    </p>
                  </div>
                  <input
                    type="radio"
                    name="admin"
                    value={4}
                    defaultChecked={role_id === 4 ? true : false}
                    onClick={(e) => setrole_id(e.target.value)}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </form>
        </ModalBody>
        <div className="d-flex justify-content-between align-items-center cancel-user mt-4">
          <div className="cancel-btn mr-2 mr-md-0" onClick={addusertoggle}>
            Cancel
          </div>
          <button type="submit" className="next_btn" form="userform">
            <div className="d-flex align-items-center justify-content-center h-100 w-100">
              <div className={loader}>
                <img src={loaderimage} alt="loader" />
              </div>{" "}
              <div>{submitval}</div>
            </div>
          </button>
        </div>
      </Modal>
      <Modal
          isOpen={openDeletePopUp}
          centered
          className="user-delete-popup"
          toggle={(e) => userdelete(e, "cancel")}
          // className={this.props.className}
          dataclassName="addonpopup"
        >
          <ModalBody className="">
          <div className="crossmodal mt-1">
              
              <svg  onClick={(e) => userdelete(e, "cancel")} xmlns="http://www.w3.org/2000/svg" width="10.96" height="10.961" viewBox="0 0 10.96 10.961">
                <g id="Group_38368" data-name="Group 38368" transform="translate(-1914.917 -169.92)">
                  <path id="Path_82532" data-name="Path 82532" d="M-16354-3830v14" transform="translate(10781.152 14442.694) rotate(45)" fill="none" stroke="#c8cedb" stroke-width="1.5"/>
                  <path id="Path_82533" data-name="Path 82533" d="M-16354-3830v14" transform="translate(-12346.896 9036.155) rotate(135)" fill="none" stroke="#c8cedb" stroke-width="1.5"/>
                </g>
              </svg>
              

            </div>
            <div>
              <h5>Delete this user?</h5>
              <h6>Are you sure you want to delete this user? Once deleted, the user will lose access to the app.</h6>

           
                 <div className="d-flex align-items-center justify-content-between cms-delete-popup-footer">
                
                <div className="d-none d-xl-block grey-btn" onClick={(e) => userdelete(e, "cancel")}>
                  <p>Cancel</p>
                </div>
                <div onClick={(e) => userdelete(e,"add")}>
                  <div className="d-flex align-items-center justify-content-center h-100 w-100 red-btn">
                    <p>
                      <div className={isLoader}>
                        <img src={loaderimage} alt="loader" />
                      </div>
                      <div>{submitvalDeleteVal}</div>
                    </p>
                  </div>
                </div>
                <div className="d-block d-xl-none grey-btn" onClick={(e) => userdelete(e, "cancel")}>
                  <p>Cancel</p>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
    </>
  );
};
