import React from "react";
import { Carousel } from "react-responsive-carousel";
import CartIcon from "../../../assets/images/dashboard/CartIcon.svg";
import DiscountBadgeIcon from "../../../Components/commonIcons/DiscountBadgeIcon";
import ForwardIcon from "../../../Components/commonIcons/ForwardIcon";
import ListViewIcon from "../../../assets/images/dashboard/ListViewIcon.svg";
import PointStarIcon from "../../../assets/images/dashboard/PointStarIcon.svg";
import ProductDefaultImage from "../../../assets/images/dashboard/ProductDefaultImage.svg";
import ProductImagePaginationIcon from "../../../assets/images/dashboard/ProductImagePaginationIcon.svg";
import ProductWishlistIcon from "../../../assets/images/dashboard/ProductWishlistIcon.svg";
import SearchIcon from "../../../assets/images/dashboard/SearchIcon.svg";
import ShareIcon from "../../../assets/images/dashboard/ShareIcon.svg";
import SortIcon from "../../../assets/images/dashboard/SortIcon.svg";
import StarRatingIcon from "../../../assets/images/dashboard/starRatingIcon.svg";
import WishlistIcon from "../../../assets/images/dashboard/WishlistIcon.svg";
import backIcon from "../../../assets/images/dashboard/backIcon.svg";
import productFilterIcon from "../../../assets/images/dashboard/productFilterIcon.svg";
import webViewIcon from "../../../assets/images/dashboard/webview-icon.png";

const ProductsPreview = ({
  productState,
  wishlistRef,
  relatedProductsRef,
  additionalInfoRef,
}) => {
  const calculateHeight = (width, ratio) => {
    let height = (width * 3) / 4;

    switch (ratio) {
      case "4:3":
        height = (width * 3) / 4;
        break;
      case "3:4":
        height = (width * 4) / 3;
        break;
      case "1:1":
        height = (width * 1) / 1;
        break;
      case "16:9":
        height = (width * 9) / 16;
        break;
      case "9:16":
        height = (width * 16) / 9;
        break;
    }
    return height;
  };

  return (
    <div className="productsetting-mobile-screen ">
      <Carousel
        selectedItem={productState.activeSlide}
        // onChange={(e) =>this.props.value.handlesliderChange(e)}
        showArrows={false}
        swipeable={true}
        showThumbs={false}
      >
        <div className="w-100 pro-first-screen-scroll">
          <div className="product-preview-header">
            <h2 className="product-preview-header-maintag">
              <img
                src={backIcon}
                alt="back-icon"
                className="product-back-icon"
              />
              <p className="product-preview-header-text">Product list</p>
            </h2>
            <h3 className="product-preview-header-secondtag">
              {(productState.showProductSearch === true ||
                productState.showProductSearch === 1) && (
                <img
                  src={SearchIcon}
                  alt="SearchIcon"
                  className="product-default-img"
                />
              )}

              <img
                src={CartIcon}
                alt="SearchIcon"
                className="product-default-img"
              />
              <p className="cartitem-save">2</p>
            </h3>
          </div>
          <div className="">
            <div className="toggleswitch-flex">
              <p className="product-filterswitch">
                {productState.showSortOnListingPage && (
                  <img
                    src={SortIcon}
                    alt="SearchIcon"
                    className="product-default-img"
                  />
                )}
                {productState.showFilterOnListingPage && (
                  <div className="productpreview-filter">
                    <img
                      src={productFilterIcon}
                      alt="productFilterIcon"
                      className="product-filter-icon"
                    />
                    <div className="red-dot-filter"></div>
                  </div>
                )}
              </p>

              <p className="product-alignmenttoggle product-filterswitch">
                {/* <p className="product-filterswitch">245</p> */}
                {
                  (productState.tempShowGridView && (
                    <img
                      src={ListViewIcon}
                      alt="listview"
                      className="product-svgimg"
                    />
                  ))}
              </p>
            </div>
            {/* List view */}
            {(!productState.productViewStyle ||
              !productState.tempShowGridView) &&
              Array(7)
                .fill("")
                .map((item, idx) => (
                  <div className="product-preview-list-page">
                    <ul
                      style={{
                        borderRadius:
                          productState.imageShapeOnListingPage ===
                            "soft_corner" ||
                          productState.imageShapeOnListingPage === "circle"
                            ? "10px"
                            : "",
                      }}
                    >
                      <li
                        className="product-preview-img"
                        style={{
                          borderRadius:
                            productState.imageShapeOnListingPage ===
                            "soft_corner"
                              ? "10px"
                              : "",
                          height: calculateHeight(
                            100,
                            productState.imageRatioOnListingPage
                          ),
                        }}
                      >
                        <h3 className="d-flex product-img-head">
                          {productState.enableWishlistOnListingPage && (
                            <p className="product-wishlist">
                              <img
                                src={ProductWishlistIcon}
                                alt="product"
                                className="product-default-img"
                              />
                            </p>
                          )}

                          {productState.enableDiscountBadge && (
                            <>
                              {!productState.showDiscountPercentage ? (
                                <div className="product-percentage-div">
                                  <DiscountBadgeIcon
                                    color={productState.discountBadgeBgColour}
                                    showDiscountPercentage={
                                      productState.showDiscountPercentage
                                    }
                                  />

                                  <p
                                    style={{
                                      color:
                                        productState.discountBadgeTextColour,
                                    }}
                                  >
                                    %
                                  </p>
                                </div>
                              ) : (
                                <div className="product-percentage-text-div">
                                  <DiscountBadgeIcon
                                    color={productState.discountBadgeBgColour}
                                    showDiscountPercentage={
                                      productState.showDiscountPercentage
                                    }
                                  />

                                  <p
                                    style={{
                                      color:
                                        productState.discountBadgeTextColour,
                                    }}
                                  >
                                    12%
                                  </p>
                                </div>
                              )}
                            </>
                          )}
                        </h3>
                        <h3 className="product-prev-image">
                          <img
                            src={ProductDefaultImage}
                            alt="product"
                            className="product-default-img"
                          />
                          {/* <ProductDefaultImage /> */}
                        </h3>
                      </li>
                      <li className="product-detailslist grid-product-detailslist">
                        <ul
                          className="product-preview-prdetails"
                          style={{
                            borderRadius:
                              productState.imageShapeOnListingPage ===
                                "soft_corner" ||
                              productState.imageShapeOnListingPage === "circle"
                                ? "10px"
                                : "",
                          }}
                        >
                          <li>
                            <p className="product-preview-name">
                              Woman yellow solid round neck longline t-shirt
                            </p>
                          </li>
                          <li className="product-preview-rating">
                            <img
                              src={StarRatingIcon}
                              alt="SearchIcon"
                              className="StarRatingIcon-icon"
                            />
                            <p className="product-rating-point">
                              4.2
                              <span className="product-rating-point-span">
                                (53)
                              </span>
                            </p>
                          </li>
                          <li className="d-flex product-prev-pricing">
                            <h3 className="product-discounted-price">$59</h3>
                            <h2 className="product-price">$67</h2>
                          </li>
                          <div className="grid-add-tocart">
                            <div>
                              {productState.showOutOfStock ? (
                                idx !== 3 && idx !== 5 ? (
                                  <p className="product-in-stock">
                                    10 in Stock
                                  </p>
                                ) : (
                                  <p className="product-out-of-stock">
                                    Out of stock
                                  </p>
                                )
                              ) : null}
                            </div>
                            {productState.showAddToCartOnListingPage && (
                              <li className="product-save-cart">+</li>
                            )}
                          </div>
                        </ul>
                      </li>
                    </ul>
                  </div>
                ))}
          </div>
          {/* Grid view */}
          {productState.productViewStyle && productState.tempShowGridView && (
            <div className="">
              <div className="product-flex-view">
                {Array(8)
                  .fill("")
                  .map((item, idx) => (
                    <div className="product-flex-preview-list-page">
                      <ul
                        style={{
                          borderRadius:
                            productState.imageShapeOnListingPage ===
                              "soft_corner" ||
                            productState.imageShapeOnListingPage === "circle"
                              ? "10px"
                              : "",
                        }}
                      >
                        <li
                          className="product-preview-img"
                          style={{
                            borderRadius:
                              productState.imageShapeOnListingPage ===
                              "soft_corner"
                                ? "10px"
                                : "",
                            height: calculateHeight(
                              136,
                              productState.imageRatioOnListingPage
                            ),
                          }}
                        >
                          <h3 className="d-flex product-img-head">
                            {productState.showOutOfStock && (
                              <p
                                className="stocklist-flex-page"
                                style={{
                                  backgroundColor: idx == 6 && "#FFF1F2",
                                  color: idx == 6 && "#FF4D5F",
                                }}
                              >
                                {idx === 6 ? "Out of Stock" : "10 in Stock"}
                              </p>
                            )}

                            {productState.enableDiscountBadge && (
                              <>
                                {!productState.showDiscountPercentage ? (
                                  <div className="product-percentage-div">
                                    <DiscountBadgeIcon
                                      color={productState.discountBadgeBgColour}
                                      showDiscountPercentage={
                                        productState.showDiscountPercentage
                                      }
                                    />
                                    <p
                                      style={{
                                        color:
                                          productState.discountBadgeTextColour,
                                      }}
                                    >
                                      %
                                    </p>
                                  </div>
                                ) : (
                                  <div className="product-percentage-text-div">
                                    <DiscountBadgeIcon
                                      color={productState.discountBadgeBgColour}
                                      showDiscountPercentage={
                                        productState.showDiscountPercentage
                                      }
                                    />

                                    <p
                                      style={{
                                        color:
                                          productState.discountBadgeTextColour,
                                      }}
                                    >
                                      12%
                                    </p>
                                  </div>
                                )}
                              </>
                            )}
                          </h3>
                          <h3 className="product-prev-image">
                            <img
                              src={ProductDefaultImage}
                              alt="product"
                              className="product-default-img"
                            />
                          </h3>
                          {/* {
                  productState.showOutOfStock &&  <p className='stocklist-flex-page'>10 in Stock</p> } */}
                          <p className="product-multi-img">
                            <img
                              src={ProductImagePaginationIcon}
                              alt="product"
                              className="product-default-img"
                            />
                          </p>
                          {productState.enableWishlistOnListingPage && (
                            <p className="product-wishlist">
                              <img
                                src={ProductWishlistIcon}
                                alt="product"
                                className="product-default-img"
                              />
                            </p>
                          )}
                        </li>
                        <li className="product-detailslist">
                          <ul
                            className="product-preview-prdetails"
                            style={{
                              borderRadius:
                                productState.imageShapeOnListingPage ===
                                  "soft_corner" ||
                                productState.imageShapeOnListingPage ===
                                  "circle"
                                  ? "10px"
                                  : "",
                            }}
                          >
                            <li>
                              <p className="product-preview-name">
                                Woman yellow solid round neck longline t-shirt
                              </p>
                            </li>
                            <li className="product-preview-rating">
                              <img
                                src={StarRatingIcon}
                                alt="SearchIcon"
                                className="StarRatingIcon-icon"
                              />
                              <p className="product-rating-point">
                                4.2
                                <span className="product-rating-point-span">
                                  (53)
                                </span>
                              </p>
                            </li>
                            <li className="d-flex justify-content-between product-prev-pricing">
                              <div>
                                <h3 className="product-discounted-price">
                                  $59
                                </h3>
                                <h2 className="product-price">$67</h2>
                              </div>
                              {productState.showAddToCartOnListingPage && (
                                <div className="product-save-cart">+</div>
                              )}
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
        {/* Second Slide */}
        <div className="product-firstscreen">
          {/* Product details div */}
          {/* {
            !productState.showImagesInGallery ?  */}
          {productState.enableWebViewInterface ? (
            <div className="product-webview-preview">
              <div className="webview-preview-head">
                <img
                  src={backIcon}
                  alt="back-icon"
                  className="product-back-icon"
                />
              </div>

              <div className="postlist-webview-body">
                <img src={webViewIcon} alt="webview-icon" />
              </div>
            </div>
          ) : (
            <div className="w-100">
              <div
                className="selected-product"
                style={{
                  height: calculateHeight(
                    294,
                    productState.imageRatioOnDetailPage
                  ),
                }}
              >
                <div className="productimg-topdiv" ref={wishlistRef}>
                  <div>
                    <p className="productimag-backside">
                      <img
                        src={backIcon}
                        alt="back-icon"
                        className="product-back-icon"
                      />
                    </p>
                  </div>
                  <div className="d-flex product-details-right">
                    <p className="productimag-backside">
                      <img
                        src={ShareIcon}
                        alt="product"
                        className="product-default-img"
                      />
                    </p>
                    {productState.enableWishlistOnDetailPage && (
                      <p className="productimag-backside">
                        <img
                          src={WishlistIcon}
                          alt="product"
                          className="product-default-img"
                        />
                      </p>
                    )}
                    <p className="productimag-backside">
                      <img
                        src={CartIcon}
                        alt="SearchIcon"
                        className="product-default-img"
                      />
                    </p>
                  </div>
                </div>
                <div className="productimg-view">
                  <img
                    src={ProductDefaultImage}
                    alt="product"
                    className="product-default-img"
                  />
                </div>
                <div className="product-details-images">
                  <img
                    src={ProductImagePaginationIcon}
                    alt="product"
                    className="product-default-img"
                  />
                </div>
              </div>
              <div className="product-descripton-firstdiv">
                <ul className="product-descripton-first-div-ul">
                  <li className="productname-text">
                    Woman yellow solid round neck t-shirt
                  </li>

                  <li className="d-flex justify-content-between user-total-ratting">
                    <div className="starandrating">
                      <img
                        src={StarRatingIcon}
                        alt="SearchIcon"
                        className="StarRatingIcon-icon"
                      />
                      4.5
                      <span className="rating-count">(245 reviews)</span>
                    </div>
                    {/* <div className='see-reviewdiv'>
                <p>See reviews</p>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="4.561" height="7.707" viewBox="0 0 4.561 7.707">
                    <path id="Path_118622" data-name="Path 118622" d="M0,3.5,3.5,0,7,3.5" transform="translate(3.854 0.354) rotate(90)" fill="none" stroke="#a1a1a1" stroke-width="1" />
                  </svg>
                </span>
              </div> */}
                  </li>

                  <li>
                    <div className="d-flex">
                      <p className="currentprice">$57</p>
                      <p className="previousprice">$67</p>
                      <div className="product-percentege-div">
                        <p>12%</p>
                      </div>
                    </div>
                    <p className="inventorydata">In Stock</p>
                  </li>
                </ul>
                <ul className="product-descripton-first-div-ul">
                  <li className="products-detilas">
                    Yellow light wash 5-pocket mid-rise cropped jeans, clean
                    look with no fade, has a button and zip closure, waistband
                    with belt loops.
                  </li>
                  <li className="products-detilasnumber">SKU: 08000749670</li>
                </ul>
              </div>
              <div className="product-descripton-seconddiv">
                <ul className="product-descripton-seconddiv-div-ul">
                  <li className="product-points">
                    <img
                      src={PointStarIcon}
                      alt="Pointstar"
                      width={"20px"}
                      height={"20px"}
                      className="product-default-img"
                    />
                    <p className="product-points-text">
                      Complete your order and earn <span>202 Points</span> for a
                      discount on a future purchase.
                    </p>
                  </li>
                </ul>
                <ul className="product-descripton-seconddiv-div-ul">
                  <li className="sizedropdown">
                    <p className="sizedropdownhead">Size</p>
                    <div>
                      <p className="sizedetails">Small (S)</p>
                      <p className="sizedetails">Medium (M) </p>
                      <p className="sizedetails">Large (L) </p>
                      <p className="sizedetails">Extra large (XL) </p>
                    </div>
                  </li>
                  <li className="sizedropdown">
                    <p className="sizedropdownhead">Color</p>
                    <div>
                      <p className="sizedetails">Red</p>
                      <p className="sizedetails">Yellow</p>
                      <p className="sizedetails">Blue</p>
                    </div>
                  </li>
                  <li className="d-flex justify-content-between">
                    <div className="addtocartbluebtn">Add to cart</div>
                  </li>
                </ul>
              </div>
              <div className="product-flex-view product-detial-flex-view">
                {Array(3)
                  .fill("")
                  .map((item, index) => (
                    <div className="product-flex-preview-list-page">
                      <ul
                        style={{
                          borderRadius:
                            productState.imageShapeOnListingPage ===
                              "soft_corner" ||
                            productState.imageShapeOnListingPage === "circle"
                              ? "10px"
                              : "",
                        }}
                      >
                        <li
                          className="product-preview-img"
                          style={{
                            borderRadius:
                              productState.imageShapeOnListingPage ===
                              "soft_corner"
                                ? "10px"
                                : "",
                            height: calculateHeight(
                              136,
                              productState.imageRatioOnListingPage
                            ),
                          }}
                        >
                          <h3 className="d-flex product-img-head">
                            {productState.showOutOfStock && (
                              <p className="stocklist-flex-page">10 in Stock</p>
                            )}

                            {productState.enableDiscountBadge && (
                              <>
                                {!productState.showDiscountPercentage ? (
                                  <div className="product-percentage-div">
                                    <DiscountBadgeIcon
                                      color={productState.discountBadgeBgColour}
                                      showDiscountPercentage={
                                        productState.showDiscountPercentage
                                      }
                                    />
                                    <p
                                      style={{
                                        color:
                                          productState.discountBadgeTextColour,
                                      }}
                                    >
                                      %
                                    </p>
                                  </div>
                                ) : (
                                  <div className="product-percentage-text-div">
                                    <DiscountBadgeIcon
                                      color={productState.discountBadgeBgColour}
                                      showDiscountPercentage={
                                        productState.showDiscountPercentage
                                      }
                                    />
                                    <p
                                      style={{
                                        color:
                                          productState.discountBadgeTextColour,
                                      }}
                                    >
                                      12%
                                    </p>
                                  </div>
                                )}
                              </>
                            )}
                          </h3>
                          <h3 className="product-prev-image">
                            <img
                              src={ProductDefaultImage}
                              alt="product"
                              className="product-default-img"
                            />
                          </h3>
                          {/* {
                         productState.showOutOfStock &&  <p className='stocklist-flex-page'>10 in Stock</p> } */}
                          <p className="product-multi-img">
                            <img
                              src={ProductImagePaginationIcon}
                              alt="product"
                              className="product-default-img"
                            />
                          </p>
                          {productState.enableWishlistOnListingPage && (
                            <p className="product-wishlist">
                              <img
                                src={ProductWishlistIcon}
                                alt="product"
                                className="product-default-img"
                              />
                            </p>
                          )}
                        </li>
                        <li className="product-detailslist">
                          <ul
                            className="product-preview-prdetails"
                            style={{
                              borderRadius:
                                productState.imageShapeOnListingPage ===
                                  "soft_corner" ||
                                productState.imageShapeOnListingPage ===
                                  "circle"
                                  ? "10px"
                                  : "",
                            }}
                          >
                            <li>
                              <p className="product-preview-name">
                                Woman yellow solid round neck longline t-shirt
                              </p>
                            </li>
                            <li className="product-preview-rating">
                              <img
                                src={StarRatingIcon}
                                alt="SearchIcon"
                                className="StarRatingIcon-icon"
                              />
                              <p className="product-rating-point">
                                4.2
                                <span className="product-rating-point-span">
                                  (53)
                                </span>
                              </p>
                            </li>
                            <li className="d-flex justify-content-between product-prev-pricing">
                              <div>
                                <h3 className="product-discounted-price">
                                  $59
                                </h3>
                                <h2 className="product-price">$67</h2>
                              </div>
                              {productState.show_add_to_cart_on_listing_page && (
                                <div className="product-save-cart">+</div>
                              )}
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  ))}
              </div>

              <div className="product-descripton-thirddiv">
                <ul className="product-descripton-thirddiv-div-ul">
                  <li className="products-detilas">
                    Yellow light wash 5-pocket mid-rise cropped jeans, clean
                    look with no fade, has a button and zip closure, waistband
                    with belt loops Blue light wash 5-pocket mid-rise cropped
                    jeans, clean look with no fade, has a button and zip
                    closure, waistband with belt loops
                  </li>
                  <li className="producttype-img">
                    {" "}
                    <img
                      src={ProductDefaultImage}
                      alt="product"
                      className="product-default-img"
                    />
                  </li>
                  <li ref={additionalInfoRef} className="products-detilas">
                    Yellow light wash 5-pocket mid-rise cropped jeans, clean
                    look with no fade, has a button and zip.
                  </li>
                </ul>
              </div>

              {productState.showAdditionalInformation && (
                <div className="product-descripton-fourthdiv">
                  <ul className="product-descripton-fourthdiv-div-ul">
                    {/* <li className='additional-information'>Additional Information</li> */}
                    <li className="fit-type">
                      <h1>Fit Type</h1>
                      {productState.showAdditionalInformationAsListOnDetailPage ? (
                        <p>
                          <li style={{ lineHeight: "15px" }}>Classic Fit</li>
                          <li style={{ lineHeight: "15px" }}>Regular fit</li>
                          <li style={{ lineHeight: "15px" }}>Slim fit</li>
                        </p>
                      ) : (
                        <p>
                          <li>Classic Fit, Regular fit, Slim fit</li>
                        </p>
                      )}
                    </li>
                    <li className="fit-type">
                      <h1>Color</h1>
                      {productState.showAdditionalInformationAsListOnDetailPage ? (
                        <p>
                          <li style={{ lineHeight: "15px" }}>Orange</li>
                          <li style={{ lineHeight: "15px" }}>Green</li>
                          <li style={{ lineHeight: "15px" }}>Blue</li>
                          <li style={{ lineHeight: "15px" }}>White</li>
                        </p>
                      ) : (
                        <p>
                          <li>Orange, Green, Blue, White</li>
                        </p>
                      )}
                    </li>
                    <li className="material">
                      <h1>Material</h1>
                      {productState.showAdditionalInformationAsListOnDetailPage ? (
                        <p>
                          <li style={{ lineHeight: "15px" }}>Cotton</li>
                          <li style={{ lineHeight: "15px" }}>Silk</li>
                          <li style={{ lineHeight: "15px" }}>Wool</li>
                        </p>
                      ) : (
                        <p>
                          <li>Cotton, Silk, Wool</li>
                        </p>
                      )}
                    </li>
                  </ul>
                </div>
              )}

              <div className="product-descripton-thirddiv">
                <ul className="product-descripton-thirddiv-div-ul">
                  {Array(3)
                    .fill("")
                    .map((item, idx) => (
                      <li
                        key={idx}
                        className="producttype-img"
                        style={{ marginTop: idx % 2 === 0 && "0px" }}
                      >
                        {" "}
                        <img
                          src={ProductDefaultImage}
                          alt="product"
                          className="product-default-img"
                        />
                      </li>
                    ))}
                </ul>
              </div>
              {/* </div> */}
              <div className="product-descripton-fifthdiv">
                <ul className="product-descripton-fourthdiv-div-ul">
                  <li className="d-flex justify-content-between user-total-ratting">
                    <div>Customer reviews (42)</div>
                    <div className="see-reviewdiv">
                      <ForwardIcon />
                    </div>
                  </li>
                  {Array(3)
                    .fill("")
                    .map((item, idx) => (
                      <li className="user-comment">
                        <li className="product-username">
                          Zachary Young
                          <span>Verfied</span>
                        </li>
                        <li className="d-flex justify-content-between dateof-comment">
                          <div className="ratting-count">
                            <img
                              src={StarRatingIcon}
                              alt="SearchIcon"
                              className="StarRatingIcon-icon"
                            />
                            4.5
                          </div>
                          <p>12 August, 2018</p>
                        </li>
                        <li className="user-comment-text">
                          Bought this lovely dress and paired it with white
                          sneakers to complete the look. Super happy with this
                          look!
                        </li>
                      </li>
                    ))}

                  <li className="writereview-btn">Write a review</li>
                </ul>
              </div>

              <div ref={relatedProductsRef}>
                {productState.showRelatedProducts && (
                  <div
                    // ref={this.props.relatedProductsRef}
                    className="product-descripton-sixdiv"
                    // style={{
                    //   display:!productState.showRelatedProducts?"none":""
                    // }}
                  >
                    {Array(3)
                      .fill("")
                      .map((item, idx) => (
                        <div className="product-preview-list-page">
                          <ul
                            style={{
                              borderRadius:
                                productState.imageShapeOnListingPage ===
                                  "soft_corner" ||
                                productState.imageShapeOnListingPage ===
                                  "circle"
                                  ? "10px"
                                  : "",
                            }}
                          >
                            <li
                              className="product-preview-img"
                              style={{
                                borderRadius:
                                  productState.imageShapeOnListingPage ===
                                  "soft_corner"
                                    ? "10px"
                                    : "",
                                height: calculateHeight(
                                  100,
                                  productState.imageRatioOnListingPage
                                ),
                              }}
                            >
                              <h3 className="d-flex product-img-head">
                                {productState.enableWishlistOnListingPage && (
                                  <p className="product-wishlist">
                                    <img
                                      src={ProductWishlistIcon}
                                      alt="product"
                                      className="product-default-img"
                                    />
                                  </p>
                                )}

                                {productState.enableDiscountBadge && (
                                  <>
                                    {!productState.showDiscountPercentage ? (
                                      <div className="product-percentage-div">
                                        <DiscountBadgeIcon
                                          color={
                                            productState.discountBadgeBgColour
                                          }
                                          showDiscountPercentage={
                                            productState.showDiscountPercentage
                                          }
                                        />
                                        <p
                                          style={{
                                            color:
                                              productState.discountBadgeTextColour,
                                          }}
                                        >
                                          %
                                        </p>
                                      </div>
                                    ) : (
                                      <div className="product-percentage-text-div">
                                        <DiscountBadgeIcon
                                          color={
                                            productState.discountBadgeBgColour
                                          }
                                          showDiscountPercentage={
                                            productState.showDiscountPercentage
                                          }
                                        />
                                        <p
                                          style={{
                                            color:
                                              productState.discountBadgeTextColour,
                                          }}
                                        >
                                          12%
                                        </p>
                                      </div>
                                    )}
                                  </>
                                )}
                              </h3>
                              <h3 className="product-prev-image">
                                <img
                                  src={ProductDefaultImage}
                                  alt="product"
                                  className="product-default-img"
                                />
                              </h3>
                            </li>
                            <li className="product-detailslist grid-product-detailslist">
                              <ul
                                className="product-preview-prdetails"
                                style={{
                                  borderRadius:
                                    productState.imageShapeOnListingPage ===
                                      "soft_corner" ||
                                    productState.imageShapeOnListingPage ===
                                      "circle"
                                      ? "10px"
                                      : "",
                                }}
                              >
                                <li>
                                  <p className="product-preview-name">
                                    Woman yellow solid round neck longline
                                    t-shirt
                                  </p>
                                </li>
                                <li className="product-preview-rating">
                                  <img
                                    src={StarRatingIcon}
                                    alt="SearchIcon"
                                    className="StarRatingIcon-icon"
                                  />
                                  <p className="product-rating-point">
                                    4.2
                                    <span className="product-rating-point-span">
                                      (53)
                                    </span>
                                  </p>
                                </li>
                                <li className="d-flex product-prev-pricing">
                                  <h3 className="product-discounted-price">
                                    $59
                                  </h3>
                                  <h2 className="product-price">$67</h2>
                                </li>
                                <div className="grid-add-tocart">
                                  <div>
                                    {productState.showOutOfStock && (
                                      <p className="product-in-stock">
                                        10 in Stock
                                      </p>
                                    )}
                                  </div>
                                  {productState.show_add_to_cart_on_listing_page && (
                                    <li className="product-save-cart">+</li>
                                  )}
                                </div>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {/* Third Slide */}
        {productState.showImagesInGallery &&
          !productState?.enableWebViewInterface && (
            <div
              className="w-100 "
              style={{
                height: "644px",
                display: productState?.enableWebViewInterface
                  ? "none"
                  : "block",
              }}
            >
              {productState.showImagesAsThumbnailsInGallery ? (
                <div className=" product-img"></div>
              ) : (
                <div className="product-imagetwo"></div>
              )}
            </div>
          )}

        {/* Fourth Slide */}
        <div className="" style={{ height: "635px" }}>
          {productState.showNativePaymentScreen ? (
            <div className="checkout-prev-secondimg"></div>
          ) : (
            <div className="checkout-prev-firstimg"></div>
          )}
        </div>
      </Carousel>
    </div>
  );
};

export default ProductsPreview;
