import React from "react";

const NotificationCreateFooter = ({
  notificationData,
  handleSaveDraft,
  toggleConfirmationModal,
}) => {
  const draftStyle = {
    border: "1px solid " + localStorage.getItem("button_background_color"),
    color: localStorage.getItem("button_text_color"),
    "--custom_color5":
      +localStorage.getItem("agencyid") === 1
        ? "#134bec"
        : localStorage.getItem("button_hover_bgcolor"),
    "--custom_color4":
      +localStorage.getItem("agencyid") === 1
        ? "white"
        : localStorage.getItem("button_hover_txtcolor"),
    "--custom_color6":
      +localStorage.getItem("agencyid") === 1
        ? "#3064f9"
        : localStorage.getItem("button_hover_bgcolor"),
  };
  const draftDisabledStyle = {
    border: "",
    color: "",
    "--custom_color4": +localStorage.getItem("agencyid") === 1 ? "white" : "",
    "--custom_color6": +localStorage.getItem("agencyid") === 1 ? "#3064f9" : "",
  };
  const sendStyle = {
    backgroundColor: localStorage.getItem("button_background_color"),
    border: "1px solid " + localStorage.getItem("button_background_color"),
    color: localStorage.getItem("button_text_color"),
    "--custom_color5":
      +localStorage.getItem("agencyid") === 1
        ? "#134bec"
        : localStorage.getItem("button_hover_bgcolor"),
    "--custom_color4":
      +localStorage.getItem("agencyid") === 1
        ? "white"
        : localStorage.getItem("button_hover_txtcolor"),
    "--custom_color6":
      +localStorage.getItem("agencyid") === 1
        ? "#3064f9"
        : localStorage.getItem("button_hover_bgcolor"),
  };
  return (
    <div className="mobnewdraft_fotter">
      {(!notificationData.title.trim() && !notificationData.message.trim()) ||
      (!notificationData.settings.sendToAndroid &&
        !notificationData.settings.sendToIos) ? (
        <button className="savedraft-buttondisabled" style={draftDisabledStyle}>
          <div className=""> Save draft </div>
        </button>
      ) : (
        <button className="pushnotification-savedraft" style={draftStyle}>
          {notificationData.draftSpinner ? (
            <div className="w-100">
              <div className="new-blue-loader"></div>
            </div>
          ) : (
            <div
              onClick={() =>
                notificationData.draftSpinner ? null : handleSaveDraft()
              }
            >
              Save draft
            </div>
          )}
        </button>
      )}

      {!notificationData.settings.sendToAndroid &&
      !notificationData.settings.sendToIos ? (
        <button className="send-buttondisabled"> Send </button>
      ) : !notificationData.title.trim() || !notificationData.message.trim() ? (
        <button className="send-buttondisabled"> Send </button>
      ) : (
        <button
          onClick={() => toggleConfirmationModal()}
          className={
            notificationData.title.trim() && notificationData.message.trim()
              ? "send-notificationsend"
              : "send-buttondisabled"
          }
          style={sendStyle}
        >
          Send
        </button>
      )}
    </div>
  );
};

export default NotificationCreateFooter;
