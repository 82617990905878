import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiEndPoints from "../../utils/apiEndpoints";
import { prepareAuthHeaders } from "../../utils/commonUtils";
import { basicNotificationSlice } from "./Notification/basicNotificationSlice";

export const mergeApiSlice = createApi({
  reducerPath: "mergeAppsData",
  baseQuery: fetchBaseQuery({
    baseUrl: apiEndPoints.appUrl,
    prepareHeaders: (headers) => prepareAuthHeaders(headers),
  }),
  tagTypes: ["app-delete", "app-link", "app-update"],

  endpoints: (builder) => ({
    // Gives the list of all apps that are merged with current app
    getLinkedApps: builder.query({
      query: () =>
        `multi-site/list-linked-apps/` +
        btoa(localStorage.getItem("Appid")) +
        "?page=1&limit=100&timestamp=" +
        new Date().getTime(),
      providesTags: ["app-delete", "app-link", "app-update"],
    }),
    // Gives the list of all apps that are available to be merged with current app
    getAllApps: builder.query({
      query: ({ page = 1, workspace_id = null, search_field }) =>
        "merge-apps/list-all-apps/" +
        btoa(localStorage.getItem("Appid")) +
        "?limit=40&page=" +
        page +
        "&workspace_id=" +
        workspace_id +
        "&search_field=" +
        search_field +
        "&timestamp=" +
        new Date().getTime(),
      providesTags: ["app-link", "app-delete"],
    }),

    deleteLinkedApp: builder.mutation({
      query: (body) => ({
        url: "multi-site/delete-app",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["app-delete"],
    }),

    linkApps: builder.mutation({
      query: (body) => ({
        url: "multi-site/link-app",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["app-link"],
    }),

    unlinkApps: builder.mutation({
      query: (body) => ({
        url: "multi-site/unlink-app",
        method: "POST",
        body: body,
      }),
      onQueryStarted: (_, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(
            basicNotificationSlice.util.invalidateTags([
              "plainNotif-listing-update",
            ])
          );
        });
      },
    }),

    updateSettings: builder.mutation({
      query: (body) => ({
        url: "merge-apps/update-settings",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["app-update"],
    }),
  }),
});

export const {
  useLinkAppsMutation,
  useDeleteLinkedAppMutation,
  useUpdateSettingsMutation,
  useGetAllAppsQuery,
  useGetLinkedAppsQuery,
  useUnlinkAppsMutation
} = mergeApiSlice;
