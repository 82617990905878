export const iosDevices = {
  "12.4": [
    {
      deviceName: "iPad (5th Generation)",
      deviceHeight: 2048,
      deviceWidth: 1536
    },
    {
      deviceName: "iPad (6th Generation)",
      deviceHeight: 1536,
      deviceWidth: 1285
    },
    {
      deviceName: "iPad Air",
      deviceHeight: 2048,
      deviceWidth: 1536
    },
    {
      deviceName: "iPad Mini (5th generation)",
      deviceHeight: 2048,
      deviceWidth: 1320.69
    },
    {
      deviceName: "iPad Mini 4",
      deviceHeight: 2048,
      deviceWidth: 1536
    },
    {
      deviceName: "iPad Pro (11-inch)",
      deviceHeight: 2388,
      deviceWidth: 1703
    },
    {
      deviceName: "iPad Pro (12.9 inch 2nd Generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (12.9-inch) (3rd generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (9.7 inch)",
      deviceHeight: 2048,
      deviceWidth: 1413
    },
    {
      deviceName: "iPhone 6 Plus",
      deviceHeight: 1920,
      deviceWidth: 1080
    },
    {
      deviceName: "iPhone 7",
      deviceHeight: 1334,
      deviceWidth: 750
    },
    {
      deviceName: "iPhone 7 Plus",
      deviceHeight: 1920,
      deviceWidth: 1080
    },
    {
      deviceName: "iPhone 8",
      deviceHeight: 1334,
      deviceWidth: 633
    },
    {
      deviceName: "iPhone 8 Plus",
      deviceHeight: 1920,
      deviceWidth: 913
    },
    {
      deviceName: "iPhone SE",
      deviceHeight: 1334,
      deviceWidth: 750
    },
    {
      deviceName: "iPhone X",
      deviceHeight: 2436,
      deviceWidth: 1193
    },
    {
      deviceName: "iPhone XR",
      deviceHeight: 1792,
      deviceWidth: 869
    },
    {
      deviceName: "iPhone XS",
      deviceHeight: 2436,
      deviceWidth: 1197
    },
    {
      deviceName: "iPhone XS Max",
      deviceHeight: 2688,
      deviceWidth: 1326
    }
  ],
 "13.4": [
    {
      deviceName: "iPad (5th Generation)",
      deviceHeight: 2048,
      deviceWidth: 1536
    },
    {
      deviceName: "iPad Air 2",
      deviceHeight: 2048,
      deviceWidth: 1536
    },
    {
      deviceName: "iPad Mini (5th generation)",
      deviceHeight: 2048,
      deviceWidth: 1320.69
    },
    {
      deviceName: "iPad Pro (11-inch)",
      deviceHeight: 2388,
      deviceWidth: 1703
    },
    {
      deviceName: "iPad Pro (12.9-inch) (3rd generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPhone 11",
      deviceHeight: 1792,
      deviceWidth: 862.31
    },
    {
      deviceName: "iPhone 11 Pro",
      deviceHeight: 2436,
      deviceWidth: 1165.6
    },
    {
      deviceName: "iPhone 11 Pro Max",
      deviceHeight: 2688,
      deviceWidth: 1277.84
    },
    {
      deviceName: "iPhone 8",
      deviceHeight: 1334,
      deviceWidth: 633
    },
    {
      deviceName: "iPhone 8 Plus",
      deviceHeight: 1920,
      deviceWidth: 913
    },
    {
      deviceName: "iPhone X",
      deviceHeight: 2436,
      deviceWidth: 1193
    },
    {
      deviceName: "iPhone XR",
      deviceHeight: 1792,
      deviceWidth: 869
    },
    {
      deviceName: "iPhone XS",
      deviceHeight: 2436,
      deviceWidth: 1197
    },
    {
      deviceName: "iPhone XS Max",
      deviceHeight: 2688,
      deviceWidth: 1326
    }
  ],
  "13.5": [
    {
      deviceName: "iPad (6th Generation)",
      deviceHeight: 1536,
      deviceWidth: 1285
    },
    {
      deviceName: "iPad (7th generation)",
      deviceHeight: 2160,
      deviceWidth: 1465
    },
    {
      deviceName: "iPad Mini (5th generation)",
      deviceHeight: 2048,
      deviceWidth: 1320.69
    },
    {
      deviceName: "iPad Pro (11-inch)",
      deviceHeight: 2388,
      deviceWidth: 1703
    },
    {
      deviceName: "iPad Pro (11-inch) (2nd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (12.9-inch) (3rd generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (12.9-inch) (4th generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (9.7 inch)",
      deviceHeight: 2048,
      deviceWidth: 1413
    },
    {
      deviceName: "iPhone 11",
      deviceHeight: 1792,
      deviceWidth: 862.31
    },
    {
      deviceName: "iPhone 11 Pro",
      deviceHeight: 2436,
      deviceWidth: 1165.6
    },
    {
      deviceName: "iPhone 11 Pro Max",
      deviceHeight: 2688,
      deviceWidth: 1277.84
    },
    {
      deviceName: "iPhone 8",
      deviceHeight: 1334,
      deviceWidth: 633
    },
    {
      deviceName: "iPhone 8 Plus",
      deviceHeight: 1920,
      deviceWidth: 913
    },
    {
      deviceName: "iPhone X",
      deviceHeight: 2436,
      deviceWidth: 1193
    },
    {
      deviceName: "iPhone XR",
      deviceHeight: 1792,
      deviceWidth: 869
    },
    {
      deviceName: "iPhone XS",
      deviceHeight: 2436,
      deviceWidth: 1197
    },
    {
      deviceName: "iPhone XS Max",
      deviceHeight: 2688,
      deviceWidth: 1326
    }
  ],
  "13.6": [
    {
      deviceName: "iPad (6th Generation)",
      deviceHeight: 1536,
      deviceWidth: 1285
    },
    {
      deviceName: "iPad (7th generation)",
      deviceHeight: 2160,
      deviceWidth: 1465
    },
    {
      deviceName: "iPad Mini (5th generation)",
      deviceHeight: 2048,
      deviceWidth: 1320.69
    },
    {
      deviceName: "iPad Pro (11-inch)",
      deviceHeight: 2388,
      deviceWidth: 1703
    },
    {
      deviceName: "iPad Pro (11-inch) (2nd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (12.9-inch) (3rd generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (12.9-inch) (4th generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (9.7 inch)",
      deviceHeight: 2048,
      deviceWidth: 1413
    },
    {
      deviceName: "iPhone 11",
      deviceHeight: 1792,
      deviceWidth: 862.31
    },
    {
      deviceName: "iPhone 11 Pro",
      deviceHeight: 2436,
      deviceWidth: 1165.6
    },
    {
      deviceName: "iPhone 11 Pro Max",
      deviceHeight: 2688,
      deviceWidth: 1277.84
    },
    {
      deviceName: "iPhone 8",
      deviceHeight: 1334,
      deviceWidth: 633
    },
    {
      deviceName: "iPhone 8 Plus",
      deviceHeight: 1920,
      deviceWidth: 913
    },
    {
      deviceName: "iPhone X",
      deviceHeight: 2436,
      deviceWidth: 1193
    },
    {
      deviceName: "iPhone XR",
      deviceHeight: 1792,
      deviceWidth: 869
    },
    {
      deviceName: "iPhone XS",
      deviceHeight: 2436,
      deviceWidth: 1197
    },
    {
      deviceName: "iPhone XS Max",
      deviceHeight: 2688,
      deviceWidth: 1326
    }
  ],
  "14.1": [
    {
      deviceName: "iPad (6th Generation)",
      deviceHeight: 1536,
      deviceWidth: 1285
    },
    {
      deviceName: "iPad (7th generation)",
      deviceHeight: 2160,
      deviceWidth: 1465
    },
    {
      deviceName: "iPad (8th generation)",
      deviceHeight: 2160,
      deviceWidth: 1620
    },
    {
      deviceName: "iPad Air (3rd generation)",
      deviceHeight: 2224,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Air (4th generation)",
      deviceHeight: 2360,
      deviceWidth: 1687.5
    },
    {
      deviceName: "iPad Mini (5th generation)",
      deviceHeight: 2048,
      deviceWidth: 1320.69
    },
    {
      deviceName: "iPad Pro (11-inch)",
      deviceHeight: 2388,
      deviceWidth: 1703
    },
    {
      deviceName: "iPad Pro (11-inch) (2nd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (12.9-inch) (3rd generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (9.7 inch)",
      deviceHeight: 2048,
      deviceWidth: 1413
    },
    {
      deviceName: "iPhone 11",
      deviceHeight: 1792,
      deviceWidth: 862.31
    },
    {
      deviceName: "iPhone 11 Pro",
      deviceHeight: 2436,
      deviceWidth: 1165.6
    },
    {
      deviceName: "iPhone 11 Pro Max",
      deviceHeight: 2688,
      deviceWidth: 1277.84
    },
    {
      deviceName: "iPhone 12",
      deviceHeight: 2532,
      deviceWidth: 1212.39
    },
    {
      deviceName: "iPhone 12 Mini",
      deviceHeight: 2340,
      deviceWidth: 1123.69
    },
    {
      deviceName: "iPhone 12 Pro",
      deviceHeight: 2532,
      deviceWidth: 1212.33
    },
    {
      deviceName: "iPhone 12 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1332.72
    },
    {
      deviceName: "iPhone 8",
      deviceHeight: 1334,
      deviceWidth: 633
    },
    {
      deviceName: "iPhone 8 Plus",
      deviceHeight: 1920,
      deviceWidth: 913
    },
    {
      deviceName: "iPhone SE (2nd generation)",
      deviceHeight: 1334,
      deviceWidth: 750
    },
    {
      deviceName: "iPhone SE (2nd generation)",
      deviceHeight: 1334,
      deviceWidth: 750
    },
    {
      deviceName: "iPhone X",
      deviceHeight: 2436,
      deviceWidth: 1193
    },
    {
      deviceName: "iPhone XR",
      deviceHeight: 1792,
      deviceWidth: 869
    },
    {
      deviceName: "iPhone XS",
      deviceHeight: 2436,
      deviceWidth: 1197
    },
    {
      deviceName: "iPhone XS Max",
      deviceHeight: 2688,
      deviceWidth: 1326
    }
  ],
  "14.2": [
    {
      deviceName: "iPad (6th Generation)",
      deviceHeight: 1536,
      deviceWidth: 1285
    },
    {
      deviceName: "iPad (7th generation)",
      deviceHeight: 2160,
      deviceWidth: 1465
    },
    {
      deviceName: "iPad (8th generation)",
      deviceHeight: 2160,
      deviceWidth: 1620
    },
    {
      deviceName: "iPad Air (3rd generation)",
      deviceHeight: 2224,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Air (4th generation)",
      deviceHeight: 2360,
      deviceWidth: 1687.5
    },
    {
      deviceName: "iPad Mini (5th generation)",
      deviceHeight: 2048,
      deviceWidth: 1320.69
    },
    {
      deviceName: "iPad Pro (11-inch)",
      deviceHeight: 2388,
      deviceWidth: 1703
    },
    {
      deviceName: "iPad Pro (11-inch) (2nd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (12.9-inch) (3rd generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (9.7 inch)",
      deviceHeight: 2048,
      deviceWidth: 1413
    },
    {
      deviceName: "iPhone 11",
      deviceHeight: 1792,
      deviceWidth: 862.31
    },
    {
      deviceName: "iPhone 11 Pro",
      deviceHeight: 2436,
      deviceWidth: 1165.6
    },
    {
      deviceName: "iPhone 11 Pro Max",
      deviceHeight: 2688,
      deviceWidth: 1277.84
    },
    {
      deviceName: "iPhone 12",
      deviceHeight: 2532,
      deviceWidth: 1212.39
    },
    {
      deviceName: "iPhone 12 Mini",
      deviceHeight: 2340,
      deviceWidth: 1123.69
    },
    {
      deviceName: "iPhone 12 Pro",
      deviceHeight: 2532,
      deviceWidth: 1212.33
    },
    {
      deviceName: "iPhone 12 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1332.72
    },
    {
      deviceName: "iPhone 8",
      deviceHeight: 1334,
      deviceWidth: 633
    },
    {
      deviceName: "iPhone 8 Plus",
      deviceHeight: 1920,
      deviceWidth: 913
    },
    {
      deviceName: "iPhone SE (2nd generation)",
      deviceHeight: 1334,
      deviceWidth: 750
    },
    {
      deviceName: "iPhone X",
      deviceHeight: 2436,
      deviceWidth: 1193
    },
    {
      deviceName: "iPhone XR",
      deviceHeight: 1792,
      deviceWidth: 869
    },
    {
      deviceName: "iPhone XS",
      deviceHeight: 2436,
      deviceWidth: 1197
    },
    {
      deviceName: "iPhone XS Max",
      deviceHeight: 2688,
      deviceWidth: 1326
    }
  ],
  "16.0": [
    {
      deviceName: "iPad (6th Generation)",
      deviceHeight: 1536,
      deviceWidth: 1285
    },
    {
      deviceName: "iPad (7th generation)",
      deviceHeight: 2160,
      deviceWidth: 1465
    },
    {
      deviceName: "iPad (8th generation)",
      deviceHeight: 2160,
      deviceWidth: 1620
    },
    {
      deviceName: "iPad (9th generation)",
      deviceHeight: 1620,
      deviceWidth: 1104
    },
    {
      deviceName: "iPad Air (3rd generation)",
      deviceHeight: 2224,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Air (4th generation)",
      deviceHeight: 2360,
      deviceWidth: 1687.5
    },
    {
      deviceName: "iPad Mini (5th generation)",
      deviceHeight: 2048,
      deviceWidth: 1320.69
    },
    {
      deviceName: "iPad Pro (11-inch)",
      deviceHeight: 2388,
      deviceWidth: 1703
    },
    {
      deviceName: "iPad Pro (11-inch) (2nd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (11-inch) (3rd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (12.9-inch) (3rd generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (12.9-inch) (4th generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPhone 11",
      deviceHeight: 1792,
      deviceWidth: 862.31
    },
    {
      deviceName: "iPhone 11 Pro",
      deviceHeight: 2436,
      deviceWidth: 1165.6
    },
    {
      deviceName: "iPhone 11 Pro Max",
      deviceHeight: 2688,
      deviceWidth: 1277.84
    },
    {
      deviceName: "iPhone 12",
      deviceHeight: 2532,
      deviceWidth: 1212.39
    },
    {
      deviceName: "iPhone 12 Mini",
      deviceHeight: 2340,
      deviceWidth: 1123.69
    },
    {
      deviceName: "iPhone 12 Pro",
      deviceHeight: 2532,
      deviceWidth: 1212.33
    },
    {
      deviceName: "iPhone 12 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1332.72
    },
    {
      deviceName: "iPhone 13",
      deviceHeight: 2532,
      deviceWidth: 1207.58
    },
    {
      deviceName: "iPhone 13 Mini",
      deviceHeight: 2340,
      deviceWidth: 1120.95
    },
    {
      deviceName: "iPhone 13 Pro",
      deviceHeight: 2532,
      deviceWidth: 1208.57
    },
    {
      deviceName: "iPhone 13 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1327.43
    },
    {
      deviceName: "iPhone 14",
      deviceHeight: 2532,
      deviceWidth: 1215.29
    },
    {
      deviceName: "iPhone 14 Plus",
      deviceHeight: 2778,
      deviceWidth: 1336.04
    },
    {
      deviceName: "iPhone 14 Pro",
      deviceHeight: 2532,
      deviceWidth: 1170
    },
    {
      deviceName: "iPhone 14 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1284
    }
  ],
  "16.2": [
    {
      deviceName: "iPad (6th Generation)",
      deviceHeight: 1536,
      deviceWidth: 1285
    },
    {
      deviceName: "iPad (7th generation)",
      deviceHeight: 2160,
      deviceWidth: 1465
    },
    {
      deviceName: "iPad (8th generation)",
      deviceHeight: 2160,
      deviceWidth: 1620
    },
    {
      deviceName: "iPad (9th generation)",
      deviceHeight: 1620,
      deviceWidth: 1104
    },
    {
      deviceName: "iPad Air (3rd generation)",
      deviceHeight: 2224,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Air (4th generation)",
      deviceHeight: 2360,
      deviceWidth: 1687.5
    },
    {
      deviceName: "iPad Mini (5th generation)",
      deviceHeight: 2048,
      deviceWidth: 1320.69
    },
    {
      deviceName: "iPad Pro (11-inch)",
      deviceHeight: 2388,
      deviceWidth: 1703
    },
    {
      deviceName: "iPad Pro (11-inch) (2nd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (11-inch) (3rd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (12.9-inch) (3rd generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (12.9-inch) (4th generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPhone 11",
      deviceHeight: 1792,
      deviceWidth: 862.31
    },
    {
      deviceName: "iPhone 11 Pro",
      deviceHeight: 2436,
      deviceWidth: 1165.6
    },
    {
      deviceName: "iPhone 11 Pro Max",
      deviceHeight: 2688,
      deviceWidth: 1277.84
    },
    {
      deviceName: "iPhone 12",
      deviceHeight: 2532,
      deviceWidth: 1212.39
    },
    {
      deviceName: "iPhone 12 Mini",
      deviceHeight: 2340,
      deviceWidth: 1123.69
    },
    {
      deviceName: "iPhone 12 Pro",
      deviceHeight: 2532,
      deviceWidth: 1212.33
    },
    {
      deviceName: "iPhone 12 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1332.72
    },
    {
      deviceName: "iPhone 13",
      deviceHeight: 2532,
      deviceWidth: 1207.58
    },
    {
      deviceName: "iPhone 13 Mini",
      deviceHeight: 2340,
      deviceWidth: 1120.95
    },
    {
      deviceName: "iPhone 13 Pro",
      deviceHeight: 2532,
      deviceWidth: 1208.57
    },
    {
      deviceName: "iPhone 13 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1327.43
    },
    {
      deviceName: "iPhone 14",
      deviceHeight: 2532,
      deviceWidth: 1215.29
    },
    {
      deviceName: "iPhone 14 Plus",
      deviceHeight: 2778,
      deviceWidth: 1336.04
    },
    {
      deviceName: "iPhone 14 Pro",
      deviceHeight: 2532,
      deviceWidth: 1170
    },
    {
      deviceName: "iPhone 14 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1284
    }
  ],
  "16.4": [
    {
      deviceName: "iPad (6th Generation)",
      deviceHeight: 1536,
      deviceWidth: 1285
    },
    {
      deviceName: "iPad (7th generation)",
      deviceHeight: 2160,
      deviceWidth: 1465
    },
    {
      deviceName: "iPad (8th generation)",
      deviceHeight: 2160,
      deviceWidth: 1620
    },
    {
      deviceName: "iPad (9th generation)",
      deviceHeight: 1620,
      deviceWidth: 1104
    },
    {
      deviceName: "iPad Air (3rd generation)",
      deviceHeight: 2224,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Air (4th generation)",
      deviceHeight: 2360,
      deviceWidth: 1687.5
    },
    {
      deviceName: "iPad Mini (5th generation)",
      deviceHeight: 2048,
      deviceWidth: 1320.69
    },
    {
      deviceName: "iPad Pro (11-inch)",
      deviceHeight: 2388,
      deviceWidth: 1703
    },
    {
      deviceName: "iPad Pro (11-inch) (2nd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (11-inch) (3rd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (12.9-inch) (3rd generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (12.9-inch) (4th generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPhone 11",
      deviceHeight: 1792,
      deviceWidth: 862.31
    },
    {
      deviceName: "iPhone 11 Pro",
      deviceHeight: 2436,
      deviceWidth: 1165.6
    },
    {
      deviceName: "iPhone 11 Pro Max",
      deviceHeight: 2688,
      deviceWidth: 1277.84
    },
    {
      deviceName: "iPhone 12",
      deviceHeight: 2532,
      deviceWidth: 1212.39
    },
    {
      deviceName: "iPhone 12 Mini",
      deviceHeight: 2340,
      deviceWidth: 1123.69
    },
    {
      deviceName: "iPhone 12 Pro",
      deviceHeight: 2532,
      deviceWidth: 1212.33
    },
    {
      deviceName: "iPhone 12 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1332.72
    },
    {
      deviceName: "iPhone 13",
      deviceHeight: 2532,
      deviceWidth: 1207.58
    },
    {
      deviceName: "iPhone 13 Mini",
      deviceHeight: 2340,
      deviceWidth: 1120.95
    },
    {
      deviceName: "iPhone 13 Pro",
      deviceHeight: 2532,
      deviceWidth: 1208.57
    },
    {
      deviceName: "iPhone 13 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1327.43
    },
    {
      deviceName: "iPhone 14",
      deviceHeight: 2532,
      deviceWidth: 1215.29
    },
    {
      deviceName: "iPhone 14 Plus",
      deviceHeight: 2778,
      deviceWidth: 1336.04
    },
    {
      deviceName: "iPhone 14 Pro",
      deviceHeight: 2532,
      deviceWidth: 1170
    },
    {
      deviceName: "iPhone 14 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1284
    },
    {
      deviceName: "iPhone 8",
      deviceHeight: 1334,
      deviceWidth: 633
    }
  ],
  "17.0": [
    {
      deviceName: "iPad (6th Generation)",
      deviceHeight: 1536,
      deviceWidth: 1285
    },
    {
      deviceName: "iPad (7th generation)",
      deviceHeight: 2160,
      deviceWidth: 1465
    },
    {
      deviceName: "iPad (8th generation)",
      deviceHeight: 2160,
      deviceWidth: 1620
    },
    {
      deviceName: "iPad (9th generation)",
      deviceHeight: 1620,
      deviceWidth: 1104
    },
    {
      deviceName: "iPad Air (3rd generation)",
      deviceHeight: 2224,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Air (4th generation)",
      deviceHeight: 2360,
      deviceWidth: 1687.5
    },
    {
      deviceName: "iPad Mini (5th generation)",
      deviceHeight: 2048,
      deviceWidth: 1320.69
    },
    {
      deviceName: "iPad Pro (11-inch)",
      deviceHeight: 2388,
      deviceWidth: 1703
    },
    {
      deviceName: "iPad Pro (11-inch) (2nd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (11-inch) (3rd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (12.9-inch) (3rd generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (12.9-inch) (4th generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPhone 11",
      deviceHeight: 1792,
      deviceWidth: 862.31
    },
    {
      deviceName: "iPhone 11 Pro",
      deviceHeight: 2436,
      deviceWidth: 1165.6
    },
    {
      deviceName: "iPhone 11 Pro Max",
      deviceHeight: 2688,
      deviceWidth: 1277.84
    },
    {
      deviceName: "iPhone 12",
      deviceHeight: 2532,
      deviceWidth: 1212.39
    },
    {
      deviceName: "iPhone 12 Mini",
      deviceHeight: 2340,
      deviceWidth: 1123.69
    },
    {
      deviceName: "iPhone 12 Pro",
      deviceHeight: 2532,
      deviceWidth: 1212.33
    },
    {
      deviceName: "iPhone 12 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1332.72
    },
    {
      deviceName: "iPhone 13",
      deviceHeight: 2532,
      deviceWidth: 1207.58
    },
    {
      deviceName: "iPhone 13 Mini",
      deviceHeight: 2340,
      deviceWidth: 1120.95
    },
    {
      deviceName: "iPhone 13 Pro",
      deviceHeight: 2532,
      deviceWidth: 1208.57
    },
    {
      deviceName: "iPhone 13 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1327.43
    },
    {
      deviceName: "iPhone 14",
      deviceHeight: 2532,
      deviceWidth: 1215.29
    },
    {
      deviceName: "iPhone 15",
      deviceHeight: 1179,
      deviceWidth: 558.67
    },
    {
      deviceName: "iPhone 14 Plus",
      deviceHeight: 2778,
      deviceWidth: 1336.04
    },
    {
      deviceName: "iPhone 15 Plus",
      deviceHeight: 1290,
      deviceWidth: 611.04
    },
    {
      deviceName: "iPhone 15 Pro",
      deviceHeight: 1179,
      deviceWidth: 555.85
    },
    {
      deviceName: "iPhone 14 Pro",
      deviceHeight: 2532,
      deviceWidth: 1170
    },
    {
      deviceName: "iPhone 14 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1284
    },
    {
      deviceName: "iPhone 15 Pro Max",
      deviceHeight: 1290,
      deviceWidth: 605.46
    }
  ],
  "17.2": [
    {
      deviceName: "iPad (6th Generation)",
      deviceHeight: 1536,
      deviceWidth: 1285
    },
    {
      deviceName: "iPad (7th generation)",
      deviceHeight: 2160,
      deviceWidth: 1465
    },
    {
      deviceName: "iPad (8th generation)",
      deviceHeight: 2160,
      deviceWidth: 1620
    },
    {
      deviceName: "iPad (9th generation)",
      deviceHeight: 1620,
      deviceWidth: 1104
    },
    {
      deviceName: "iPad Air (3rd generation)",
      deviceHeight: 2224,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Air (4th generation)",
      deviceHeight: 2360,
      deviceWidth: 1687.5
    },
    {
      deviceName: "iPad Mini (5th generation)",
      deviceHeight: 2048,
      deviceWidth: 1320.69
    },
    {
      deviceName: "iPad Pro (11-inch)",
      deviceHeight: 2388,
      deviceWidth: 1703
    },
    {
      deviceName: "iPad Pro (11-inch) (2nd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (11-inch) (3rd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (12.9-inch) (3rd generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (12.9-inch) (4th generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPhone 11",
      deviceHeight: 1792,
      deviceWidth: 862.31
    },
    {
      deviceName: "iPhone 11 Pro",
      deviceHeight: 2436,
      deviceWidth: 1165.6
    },
    {
      deviceName: "iPhone 11 Pro Max",
      deviceHeight: 2688,
      deviceWidth: 1277.84
    },
    {
      deviceName: "iPhone 12",
      deviceHeight: 2532,
      deviceWidth: 1212.39
    },
    {
      deviceName: "iPhone 12 Mini",
      deviceHeight: 2340,
      deviceWidth: 1123.69
    },
    {
      deviceName: "iPhone 12 Pro",
      deviceHeight: 2532,
      deviceWidth: 1212.33
    },
    {
      deviceName: "iPhone 12 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1332.72
    },
    {
      deviceName: "iPhone 13",
      deviceHeight: 2532,
      deviceWidth: 1207.58
    },
    {
      deviceName: "iPhone 13 Mini",
      deviceHeight: 2340,
      deviceWidth: 1120.95
    },
    {
      deviceName: "iPhone 13 Pro",
      deviceHeight: 2532,
      deviceWidth: 1208.57
    },
    {
      deviceName: "iPhone 13 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1327.43
    },
    {
      deviceName: "iPhone 14",
      deviceHeight: 2532,
      deviceWidth: 1215.29
    },
    {
      deviceName: "iPhone 15",
      deviceHeight: 1179,
      deviceWidth: 558.67
    },
    {
      deviceName: "iPhone 14 Plus",
      deviceHeight: 2778,
      deviceWidth: 1336.04
    },
    {
      deviceName: "iPhone 15 Plus",
      deviceHeight: 1290,
      deviceWidth: 611.04
    },
    {
      deviceName: "iPhone 15 Pro",
      deviceHeight: 1179,
      deviceWidth: 555.85
    },
    {
      deviceName: "iPhone 14 Pro",
      deviceHeight: 2532,
      deviceWidth: 1170
    },
    {
      deviceName: "iPhone 14 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1284
    },
    {
      deviceName: "iPhone 15 Pro Max",
      deviceHeight: 1290,
      deviceWidth: 605.46
    }
  ],
  "17.4": [
    {
      deviceName: "iPad (6th Generation)",
      deviceHeight: 1536,
      deviceWidth: 1285
    },
    {
      deviceName: "iPad (7th generation)",
      deviceHeight: 2160,
      deviceWidth: 1465
    },
    {
      deviceName: "iPad (8th generation)",
      deviceHeight: 2160,
      deviceWidth: 1620
    },
    {
      deviceName: "iPad (9th generation)",
      deviceHeight: 1620,
      deviceWidth: 1104
    },
    {
      deviceName: "iPad Air (3rd generation)",
      deviceHeight: 2224,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Air (4th generation)",
      deviceHeight: 2360,
      deviceWidth: 1687.5
    },
    {
      deviceName: "iPad Mini (5th generation)",
      deviceHeight: 2048,
      deviceWidth: 1320.69
    },
    {
      deviceName: "iPad Pro (11-inch)",
      deviceHeight: 2388,
      deviceWidth: 1703
    },
    {
      deviceName: "iPad Pro (11-inch) (2nd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (11-inch) (3rd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (12.9-inch) (3rd generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (12.9-inch) (4th generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPhone 11",
      deviceHeight: 1792,
      deviceWidth: 862.31
    },
    {
      deviceName: "iPhone 11 Pro",
      deviceHeight: 2436,
      deviceWidth: 1165.6
    },
    {
      deviceName: "iPhone 11 Pro Max",
      deviceHeight: 2688,
      deviceWidth: 1277.84
    },
    {
      deviceName: "iPhone 12",
      deviceHeight: 2532,
      deviceWidth: 1212.39
    },
    {
      deviceName: "iPhone 12 Mini",
      deviceHeight: 2340,
      deviceWidth: 1123.69
    },
    {
      deviceName: "iPhone 12 Pro",
      deviceHeight: 2532,
      deviceWidth: 1212.33
    },
    {
      deviceName: "iPhone 12 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1332.72
    },
    {
      deviceName: "iPhone 13",
      deviceHeight: 2532,
      deviceWidth: 1207.58
    },
    {
      deviceName: "iPhone 13 Mini",
      deviceHeight: 2340,
      deviceWidth: 1120.95
    },
    {
      deviceName: "iPhone 13 Pro",
      deviceHeight: 2532,
      deviceWidth: 1208.57
    },
    {
      deviceName: "iPhone 13 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1327.43
    },
    {
      deviceName: "iPhone 14",
      deviceHeight: 2532,
      deviceWidth: 1215.29
    },
    {
      deviceName: "iPhone 15",
      deviceHeight: 1179,
      deviceWidth: 558.67
    },
    {
      deviceName: "iPhone 14 Plus",
      deviceHeight: 2778,
      deviceWidth: 1336.04
    },
    {
      deviceName: "iPhone 14 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1284
    },
    {
      deviceName: "iPhone 15 Plus",
      deviceHeight: 1290,
      deviceWidth: 611.04
    },
    {
      deviceName: "iPhone 15 Pro",
      deviceHeight: 1179,
      deviceWidth: 555.85
    },
    {
      deviceName: "iPhone 14 Pro",
      deviceHeight: 2532,
      deviceWidth: 1170
    },
    {
      deviceName: "iPhone 15 Pro Max",
      deviceHeight: 1290,
      deviceWidth: 605.46
    }
  ],
  "17.5": [
    {
      deviceName: "iPad (6th Generation)",
      deviceHeight: 1536,
      deviceWidth: 1285
    },
    {
      deviceName: "iPad (7th generation)",
      deviceHeight: 2160,
      deviceWidth: 1465
    },
    {
      deviceName: "iPad (8th generation)",
      deviceHeight: 2160,
      deviceWidth: 1620
    },
    {
      deviceName: "iPad (9th generation)",
      deviceHeight: 1620,
      deviceWidth: 1104
    },
    {
      deviceName: "iPad Air (3rd generation)",
      deviceHeight: 2224,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Air (4th generation)",
      deviceHeight: 2360,
      deviceWidth: 1687.5
    },
    {
      deviceName: "iPad Mini (5th generation)",
      deviceHeight: 2048,
      deviceWidth: 1320.69
    },
    {
      deviceName: "iPad Pro (11-inch)",
      deviceHeight: 2388,
      deviceWidth: 1703
    },
    {
      deviceName: "iPad Pro (11-inch) (2nd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (11-inch) (3rd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (12.9-inch) (3rd generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (11-inch)",
      deviceHeight: 2388,
      deviceWidth: 1703
    },
    {
      deviceName: "iPad Pro (12.9-inch) (4th generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPhone 11",
      deviceHeight: 1792,
      deviceWidth: 862.31
    },
    {
      deviceName: "iPhone 11 Pro",
      deviceHeight: 2436,
      deviceWidth: 1165.6
    },
    {
      deviceName: "iPhone 11 Pro Max",
      deviceHeight: 2688,
      deviceWidth: 1277.84
    },
    {
      deviceName: "iPhone 12",
      deviceHeight: 2532,
      deviceWidth: 1212.39
    },
    {
      deviceName: "iPhone 12 Mini",
      deviceHeight: 2340,
      deviceWidth: 1123.69
    },
    {
      deviceName: "iPhone 12 Pro",
      deviceHeight: 2532,
      deviceWidth: 1212.33
    },
    {
      deviceName: "iPhone 12 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1332.72
    },
    {
      deviceName: "iPhone 13",
      deviceHeight: 2532,
      deviceWidth: 1207.58
    },
    {
      deviceName: "iPhone 13 Mini",
      deviceHeight: 2340,
      deviceWidth: 1120.95
    },
    {
      deviceName: "iPhone 13 Pro",
      deviceHeight: 2532,
      deviceWidth: 1208.57
    },
    {
      deviceName: "iPhone 13 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1327.43
    },
    {
      deviceName: "iPhone 14",
      deviceHeight: 2532,
      deviceWidth: 1215.29
    },
    {
      deviceName: "iPhone 15",
      deviceHeight: 1179,
      deviceWidth: 558.67
    },
    {
      deviceName: "iPhone 14 Plus",
      deviceHeight: 2778,
      deviceWidth: 1336.04
    },
    {
      deviceName: "iPhone 14 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1284
    },
    {
      deviceName: "iPhone 15 Plus",
      deviceHeight: 1290,
      deviceWidth: 611.04
    },
    {
      deviceName: "iPhone 15 Pro",
      deviceHeight: 1179,
      deviceWidth: 555.85
    },
    {
      deviceName: "iPhone 14 Pro",
      deviceHeight: 2532,
      deviceWidth: 1170
    },
    {
      deviceName: "iPhone 15 Pro Max",
      deviceHeight: 1290,
      deviceWidth: 605.46
    }
  ],
  "18.0": [
    {
      deviceName: "iPad (7th generation)",
      deviceHeight: 2160,
      deviceWidth: 1465
    },
    {
      deviceName: "iPad (8th generation)",
      deviceHeight: 2160,
      deviceWidth: 1620
    },
    {
      deviceName: "iPad (9th generation)",
      deviceHeight: 1620,
      deviceWidth: 1104
    },
    {
      deviceName: "iPad Air (3rd generation)",
      deviceHeight: 2224,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Air (4th generation)",
      deviceHeight: 2360,
      deviceWidth: 1687.5
    },
    {
      deviceName: "iPad Mini (5th generation)",
      deviceHeight: 2048,
      deviceWidth: 1320.69
    },
    {
      deviceName: "iPad Pro (11-inch)",
      deviceHeight: 2388,
      deviceWidth: 1703
    },
    {
      deviceName: "iPad Pro (11-inch) (2nd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (11-inch) (3rd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (12.9-inch) (3rd generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (11-inch)",
      deviceHeight: 2388,
      deviceWidth: 1703
    },
    {
      deviceName: "iPad Pro (12.9-inch) (4th generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPhone 11",
      deviceHeight: 1792,
      deviceWidth: 862.31
    },
    {
      deviceName: "iPhone 11 Pro",
      deviceHeight: 2436,
      deviceWidth: 1165.6
    },
    {
      deviceName: "iPhone 11 Pro Max",
      deviceHeight: 2688,
      deviceWidth: 1277.84
    },
    {
      deviceName: "iPhone 12",
      deviceHeight: 2532,
      deviceWidth: 1212.39
    },
    {
      deviceName: "iPhone 12 Mini",
      deviceHeight: 2340,
      deviceWidth: 1123.69
    },
    {
      deviceName: "iPhone 12 Pro",
      deviceHeight: 2532,
      deviceWidth: 1212.33
    },
    {
      deviceName: "iPhone 12 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1332.72
    },
    {
      deviceName: "iPhone 13",
      deviceHeight: 2532,
      deviceWidth: 1207.58
    },
    {
      deviceName: "iPhone 13 Mini",
      deviceHeight: 2340,
      deviceWidth: 1120.95
    },
    {
      deviceName: "iPhone 13 Pro",
      deviceHeight: 2532,
      deviceWidth: 1208.57
    },
    {
      deviceName: "iPhone 13 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1327.43
    },
    {
      deviceName: "iPhone 14",
      deviceHeight: 2532,
      deviceWidth: 1215.29
    },
    {
      deviceName: "iPhone 15",
      deviceHeight: 1179,
      deviceWidth: 558.67
    },
    {
      deviceName: "iPhone 14 Plus",
      deviceHeight: 2778,
      deviceWidth: 1336.04
    },
    {
      deviceName: "iPhone 14 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1284
    },
    {
      deviceName: "iPhone 15 Plus",
      deviceHeight: 1290,
      deviceWidth: 611.04
    },
    {
      deviceName: "iPhone 15 Pro",
      deviceHeight: 1179,
      deviceWidth: 555.85
    },
    {
      deviceName: "iPhone 14 Pro",
      deviceHeight: 2532,
      deviceWidth: 1170
    },
    {
      deviceName: "iPhone 15 Pro Max",
      deviceHeight: 1290,
      deviceWidth: 605.46
    }
  ],
  "14.4": [
    {
      deviceName: "iPad (8th generation)",
      deviceHeight: 2160,
      deviceWidth: 1620
    },
    {
      deviceName: "iPad Air (3rd generation)",
      deviceHeight: 2224,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Air (4th generation)",
      deviceHeight: 2360,
      deviceWidth: 1687.5
    },
    {
      deviceName: "iPad Mini (5th generation)",
      deviceHeight: 2048,
      deviceWidth: 1320.69
    },
    {
      deviceName: "iPad Pro (11-inch)",
      deviceHeight: 2388,
      deviceWidth: 1703
    },
    {
      deviceName: "iPad Pro (11-inch) (2nd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (12.9-inch) (3rd generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (9.7 inch)",
      deviceHeight: 2048,
      deviceWidth: 1413
    },
    {
      deviceName: "iPhone 11",
      deviceHeight: 1792,
      deviceWidth: 862.31
    },
    {
      deviceName: "iPhone 11 Pro",
      deviceHeight: 2436,
      deviceWidth: 1165.6
    },
    {
      deviceName: "iPhone 11 Pro Max",
      deviceHeight: 2688,
      deviceWidth: 1277.84
    },
    {
      deviceName: "iPhone 12",
      deviceHeight: 2532,
      deviceWidth: 1212.39
    },
    {
      deviceName: "iPhone 12 Mini",
      deviceHeight: 2340,
      deviceWidth: 1123.69
    },
    {
      deviceName: "iPhone 12 Pro",
      deviceHeight: 2532,
      deviceWidth: 1212.33
    },
    {
      deviceName: "iPhone 12 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1332.72
    },
    {
      deviceName: "iPhone 8",
      deviceHeight: 1334,
      deviceWidth: 633
    },
    {
      deviceName: "iPhone 8 Plus",
      deviceHeight: 1920,
      deviceWidth: 913
    },
    {
      deviceName: "iPhone SE (2nd generation)",
      deviceHeight: 1334,
      deviceWidth: 750
    },
    {
      deviceName: "iPhone X",
      deviceHeight: 2436,
      deviceWidth: 1193
    },
    {
      deviceName: "iPhone XR",
      deviceHeight: 1792,
      deviceWidth: 869
    },
    {
      deviceName: "iPhone XS",
      deviceHeight: 2436,
      deviceWidth: 1197
    },
    {
      deviceName: "iPhone XS Max",
      deviceHeight: 2688,
      deviceWidth: 1326
    }
  ],
  "14.5": [
    {
      deviceName: "iPad (8th generation)",
      deviceHeight: 2160,
      deviceWidth: 1620
    },
    {
      deviceName: "iPad Air (3rd generation)",
      deviceHeight: 2224,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Air (4th generation)",
      deviceHeight: 2360,
      deviceWidth: 1687.5
    },
    {
      deviceName: "iPad Mini (5th generation)",
      deviceHeight: 2048,
      deviceWidth: 1320.69
    },
    {
      deviceName: "iPad Pro (11-inch)",
      deviceHeight: 2388,
      deviceWidth: 1703
    },
    {
      deviceName: "iPad Pro (11-inch) (2nd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (11-inch) (3rd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (12.9-inch) (3rd generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (9.7 inch)",
      deviceHeight: 2048,
      deviceWidth: 1413
    },
    {
      deviceName: "iPhone 11",
      deviceHeight: 1792,
      deviceWidth: 862.31
    },
    {
      deviceName: "iPhone 11 Pro",
      deviceHeight: 2436,
      deviceWidth: 1165.6
    },
    {
      deviceName: "iPhone 11 Pro Max",
      deviceHeight: 2688,
      deviceWidth: 1277.84
    },
    {
      deviceName: "iPhone 12",
      deviceHeight: 2532,
      deviceWidth: 1212.39
    },
    {
      deviceName: "iPhone 12 Mini",
      deviceHeight: 2340,
      deviceWidth: 1123.69
    },
    {
      deviceName: "iPhone 12 Pro",
      deviceHeight: 2532,
      deviceWidth: 1212.33
    },
    {
      deviceName: "iPhone 12 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1332.72
    },
    {
      deviceName: "iPhone 8",
      deviceHeight: 1334,
      deviceWidth: 633
    },
    {
      deviceName: "iPhone 8 Plus",
      deviceHeight: 1920,
      deviceWidth: 913
    },
    {
      deviceName: "iPhone SE (2nd generation)",
      deviceHeight: 1334,
      deviceWidth: 750
    },
    {
      deviceName: "iPhone X",
      deviceHeight: 2436,
      deviceWidth: 1193
    },
    {
      deviceName: "iPhone XR",
      deviceHeight: 1792,
      deviceWidth: 869
    },
    {
      deviceName: "iPhone XS",
      deviceHeight: 2436,
      deviceWidth: 1197
    },
    {
      deviceName: "iPhone XS Max",
      deviceHeight: 2688,
      deviceWidth: 1326
    }
  ],
  "15.0": [
    {
      deviceName: "iPad (8th generation)",
      deviceHeight: 2160,
      deviceWidth: 1620
    },
    {
      deviceName: "iPad Air (3rd generation)",
      deviceHeight: 2224,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Air (4th generation)",
      deviceHeight: 2360,
      deviceWidth: 1687.5
    },
    {
      deviceName: "iPad Mini (5th generation)",
      deviceHeight: 2048,
      deviceWidth: 1320.69
    },
    {
      deviceName: "iPad Pro (11-inch)",
      deviceHeight: 2388,
      deviceWidth: 1703
    },
    {
      deviceName: "iPad Pro (11-inch) (2nd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (11-inch) (3rd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (12.9-inch) (3rd generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (12.9-inch) (4th generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (9.7 inch)",
      deviceHeight: 2048,
      deviceWidth: 1413
    },
    {
      deviceName: "iPhone 11",
      deviceHeight: 1792,
      deviceWidth: 862.31
    },
    {
      deviceName: "iPhone 11 Pro",
      deviceHeight: 2436,
      deviceWidth: 1165.6
    },
    {
      deviceName: "iPhone 11 Pro Max",
      deviceHeight: 2688,
      deviceWidth: 1277.84
    },
    {
      deviceName: "iPhone 12",
      deviceHeight: 2532,
      deviceWidth: 1212.39
    },
    {
      deviceName: "iPhone 12 Mini",
      deviceHeight: 2340,
      deviceWidth: 1123.69
    },
    {
      deviceName: "iPhone 12 Pro",
      deviceHeight: 2532,
      deviceWidth: 1212.33
    },
    {
      deviceName: "iPhone 12 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1332.72
    },
    {
      deviceName: "iPhone 13",
      deviceHeight: 2532,
      deviceWidth: 1207.58
    },
    {
      deviceName: "iPhone 13 Mini",
      deviceHeight: 2340,
      deviceWidth: 1120.95
    },
    {
      deviceName: "iPhone 13 Pro",
      deviceHeight: 2532,
      deviceWidth: 1208.57
    },
    {
      deviceName: "iPhone 13 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1327.43
    },
    {
      deviceName: "iPhone 8",
      deviceHeight: 1334,
      deviceWidth: 633
    },
    {
      deviceName: "iPhone 8 Plus",
      deviceHeight: 1920,
      deviceWidth: 913
    },
    {
      deviceName: "iPhone SE (2nd generation)",
      deviceHeight: 1334,
      deviceWidth: 750
    },
    {
      deviceName: "iPhone X",
      deviceHeight: 2436,
      deviceWidth: 1193
    },
    {
      deviceName: "iPhone XR",
      deviceHeight: 1792,
      deviceWidth: 869
    },
    {
      deviceName: "iPhone XS",
      deviceHeight: 2436,
      deviceWidth: 1197
    },
    {
      deviceName: "iPhone XS Max",
      deviceHeight: 2688,
      deviceWidth: 1326
    }
  ],
  "15.2": [
    {
      deviceName: "iPad (8th generation)",
      deviceHeight: 2160,
      deviceWidth: 1620
    },
    {
      deviceName: "iPad Air (3rd generation)",
      deviceHeight: 2224,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Air (4th generation)",
      deviceHeight: 2360,
      deviceWidth: 1687.5
    },
    {
      deviceName: "iPad Mini (5th generation)",
      deviceHeight: 2048,
      deviceWidth: 1320.69
    },
    {
      deviceName: "iPad Pro (11-inch)",
      deviceHeight: 2388,
      deviceWidth: 1703
    },
    {
      deviceName: "iPad Pro (11-inch) (2nd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (11-inch) (3rd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (12.9-inch) (3rd generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (12.9-inch) (4th generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (9.7 inch)",
      deviceHeight: 2048,
      deviceWidth: 1413
    },
    {
      deviceName: "iPhone 11",
      deviceHeight: 1792,
      deviceWidth: 862.31
    },
    {
      deviceName: "iPhone 11 Pro",
      deviceHeight: 2436,
      deviceWidth: 1165.6
    },
    {
      deviceName: "iPhone 11 Pro Max",
      deviceHeight: 2688,
      deviceWidth: 1277.84
    },
    {
      deviceName: "iPhone 12",
      deviceHeight: 2532,
      deviceWidth: 1212.39
    },
    {
      deviceName: "iPhone 12 Mini",
      deviceHeight: 2340,
      deviceWidth: 1123.69
    },
    {
      deviceName: "iPhone 12 Pro",
      deviceHeight: 2532,
      deviceWidth: 1212.33
    },
    {
      deviceName: "iPhone 12 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1332.72
    },
    {
      deviceName: "iPhone 13",
      deviceHeight: 2532,
      deviceWidth: 1207.58
    },
    {
      deviceName: "iPhone 13 Mini",
      deviceHeight: 2340,
      deviceWidth: 1120.95
    },
    {
      deviceName: "iPhone 13 Pro",
      deviceHeight: 2532,
      deviceWidth: 1208.57
    },
    {
      deviceName: "iPhone 13 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1327.43
    },
    {
      deviceName: "iPhone 8",
      deviceHeight: 1334,
      deviceWidth: 633
    },
    {
      deviceName: "iPhone 8 Plus",
      deviceHeight: 1920,
      deviceWidth: 913
    },
    {
      deviceName: "iPhone SE (2nd generation)",
      deviceHeight: 1334,
      deviceWidth: 750
    },
    {
      deviceName: "iPhone X",
      deviceHeight: 2436,
      deviceWidth: 1193
    },
    {
      deviceName: "iPhone XR",
      deviceHeight: 1792,
      deviceWidth: 869
    },
    {
      deviceName: "iPhone XS",
      deviceHeight: 2436,
      deviceWidth: 1197
    },
    {
      deviceName: "iPhone XS Max",
      deviceHeight: 2688,
      deviceWidth: 1326
    }
  ],
  "15.4": [
    {
      deviceName: "iPad (8th generation)",
      deviceHeight: 2160,
      deviceWidth: 1620
    },
    {
      deviceName: "iPad Air (3rd generation)",
      deviceHeight: 2224,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Air (4th generation)",
      deviceHeight: 2360,
      deviceWidth: 1687.5
    },
    {
      deviceName: "iPad Mini (5th generation)",
      deviceHeight: 2048,
      deviceWidth: 1320.69
    },
    {
      deviceName: "iPad Pro (11-inch)",
      deviceHeight: 2388,
      deviceWidth: 1703
    },
    {
      deviceName: "iPad Pro (11-inch) (2nd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (11-inch) (3rd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (12.9-inch) (3rd generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (12.9-inch) (4th generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (9.7 inch)",
      deviceHeight: 2048,
      deviceWidth: 1413
    },
    {
      deviceName: "iPhone 11",
      deviceHeight: 1792,
      deviceWidth: 862.31
    },
    {
      deviceName: "iPhone 11 Pro",
      deviceHeight: 2436,
      deviceWidth: 1165.6
    },
    {
      deviceName: "iPhone 11 Pro Max",
      deviceHeight: 2688,
      deviceWidth: 1277.84
    },
    {
      deviceName: "iPhone 12",
      deviceHeight: 2532,
      deviceWidth: 1212.39
    },
    {
      deviceName: "iPhone 12 Mini",
      deviceHeight: 2340,
      deviceWidth: 1123.69
    },
    {
      deviceName: "iPhone 12 Pro",
      deviceHeight: 2532,
      deviceWidth: 1212.33
    },
    {
      deviceName: "iPhone 12 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1332.72
    },
    {
      deviceName: "iPhone 13",
      deviceHeight: 2532,
      deviceWidth: 1207.58
    },
    {
      deviceName: "iPhone 13 Mini",
      deviceHeight: 2340,
      deviceWidth: 1120.95
    },
    {
      deviceName: "iPhone 13 Pro",
      deviceHeight: 2532,
      deviceWidth: 1208.57
    },
    {
      deviceName: "iPhone 13 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1327.43
    },
    {
      deviceName: "iPhone 8",
      deviceHeight: 1334,
      deviceWidth: 633
    },
    {
      deviceName: "iPhone 8 Plus",
      deviceHeight: 1920,
      deviceWidth: 913
    },
    {
      deviceName: "iPhone SE (2nd generation)",
      deviceHeight: 1334,
      deviceWidth: 750
    },
    {
      deviceName: "iPhone X",
      deviceHeight: 2436,
      deviceWidth: 1193
    },
    {
      deviceName: "iPhone XR",
      deviceHeight: 1792,
      deviceWidth: 869
    },
    {
      deviceName: "iPhone XS",
      deviceHeight: 2436,
      deviceWidth: 1197
    },
    {
      deviceName: "iPhone XS Max",
      deviceHeight: 2688,
      deviceWidth: 1326
    }
  ],
  "15.5": [
    {
      deviceName: "iPad (8th generation)",
      deviceHeight: 2160,
      deviceWidth: 1620
    },
    {
      deviceName: "iPad Air (3rd generation)",
      deviceHeight: 2224,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Air (4th generation)",
      deviceHeight: 2360,
      deviceWidth: 1687.5
    },
    {
      deviceName: "iPad Mini (5th generation)",
      deviceHeight: 2048,
      deviceWidth: 1320.69
    },
    {
      deviceName: "iPad Pro (11-inch)",
      deviceHeight: 2388,
      deviceWidth: 1703
    },
    {
      deviceName: "iPad Pro (11-inch) (2nd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (11-inch) (3rd generation)",
      deviceHeight: 2388,
      deviceWidth: 1668
    },
    {
      deviceName: "iPad Pro (12.9-inch) (3rd generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (12.9-inch) (4th generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (9.7 inch)",
      deviceHeight: 2048,
      deviceWidth: 1413
    },
    {
      deviceName: "iPhone 11",
      deviceHeight: 1792,
      deviceWidth: 862.31
    },
    {
      deviceName: "iPhone 11 Pro",
      deviceHeight: 2436,
      deviceWidth: 1165.6
    },
    {
      deviceName: "iPhone 11 Pro Max",
      deviceHeight: 2688,
      deviceWidth: 1277.84
    },
    {
      deviceName: "iPhone 12",
      deviceHeight: 2532,
      deviceWidth: 1212.39
    },
    {
      deviceName: "iPhone 12 Mini",
      deviceHeight: 2340,
      deviceWidth: 1123.69
    },
    {
      deviceName: "iPhone 12 Pro",
      deviceHeight: 2532,
      deviceWidth: 1212.33
    },
    {
      deviceName: "iPhone 12 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1332.72
    },
    {
      deviceName: "iPhone 13",
      deviceHeight: 2532,
      deviceWidth: 1207.58
    },
    {
      deviceName: "iPhone 13 Mini",
      deviceHeight: 2340,
      deviceWidth: 1120.95
    },
    {
      deviceName: "iPhone 13 Pro",
      deviceHeight: 2532,
      deviceWidth: 1208.57
    },
    {
      deviceName: "iPhone 13 Pro Max",
      deviceHeight: 2778,
      deviceWidth: 1327.43
    },
    {
      deviceName: "iPhone 8",
      deviceHeight: 1334,
      deviceWidth: 633
    },
    {
      deviceName: "iPhone 8 Plus",
      deviceHeight: 1920,
      deviceWidth: 913
    },
    {
      deviceName: "iPhone SE (2nd generation)",
      deviceHeight: 1334,
      deviceWidth: 750
    },
    {
      deviceName: "iPhone X",
      deviceHeight: 2436,
      deviceWidth: 1193
    },
    {
      deviceName: "iPhone XR",
      deviceHeight: 1792,
      deviceWidth: 869
    },
    {
      deviceName: "iPhone XS",
      deviceHeight: 2436,
      deviceWidth: 1197
    },
    {
      deviceName: "iPhone XS Max",
      deviceHeight: 2688,
      deviceWidth: 1326
    }
  ],
  "13.0": [
    {
      deviceName: "iPad Mini (5th generation)",
      deviceHeight: 2048,
      deviceWidth: 1320.69
    },
    {
      deviceName: "iPad Pro (10.5 inch)",
      deviceHeight: 2048,
      deviceWidth: 1536
    },
    {
      deviceName: "iPad Pro (11-inch)",
      deviceHeight: 2388,
      deviceWidth: 1703
    },
    {
      deviceName: "iPad Pro (12.9-inch) (3rd generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (9.7 inch)",
      deviceHeight: 2048,
      deviceWidth: 1413
    },
    {
      deviceName: "iPhone 11",
      deviceHeight: 1792,
      deviceWidth: 862.31
    },
    {
      deviceName: "iPhone 11 Pro",
      deviceHeight: 2436,
      deviceWidth: 1165.6
    },
    {
      deviceName: "iPhone 11 Pro Max",
      deviceHeight: 2688,
      deviceWidth: 1277.84
    },
    {
      deviceName: "iPhone 8",
      deviceHeight: 1334,
      deviceWidth: 633
    },
    {
      deviceName: "iPhone 8 Plus",
      deviceHeight: 1920,
      deviceWidth: 913
    },
    {
      deviceName: "iPhone X",
      deviceHeight: 2436,
      deviceWidth: 1193
    },
    {
      deviceName: "iPhone XR",
      deviceHeight: 1792,
      deviceWidth: 869
    },
    {
      deviceName: "iPhone XS",
      deviceHeight: 2436,
      deviceWidth: 1197
    },
    {
      deviceName: "iPhone XS Max",
      deviceHeight: 2688,
      deviceWidth: 1326
    }
  ],
  "13.3": [
    {
      deviceName: "iPad Pro (11-inch)",
      deviceHeight: 2388,
      deviceWidth: 1703
    },
    {
      deviceName: "iPad Pro (12.9-inch) (3rd generation)",
      deviceHeight: 2732,
      deviceWidth: 2048
    },
    {
      deviceName: "iPad Pro (9.7 inch)",
      deviceHeight: 2048,
      deviceWidth: 1413
    },
    {
      deviceName: "iPhone 11",
      deviceHeight: 1792,
      deviceWidth: 862.31
    },
    {
      deviceName: "iPhone 11 Pro",
      deviceHeight: 2436,
      deviceWidth: 1165.6
    },
    {
      deviceName: "iPhone 11 Pro Max",
      deviceHeight: 2688,
      deviceWidth: 1277.84
    },
    {
      deviceName: "iPhone 8",
      deviceHeight: 1334,
      deviceWidth: 633
    },
    {
      deviceName: "iPhone 8 Plus",
      deviceHeight: 1920,
      deviceWidth: 913
    }
  ]
};
