import React from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"; // Assuming you're using reactstrap for Dropdown
import Skip from "../commonIcons/Skip";
import Error from "../commonIcons/Error";
import Success from "../commonIcons/Success";
import Tooltip from "../commonIcons/Tooltip.js";
const CreateAppForm = ({
  title,
  websiteName,
  appNameError,
  handleWebsiteName,
  workspaceWebTechnology,
  websitePlatform,
  handleWebsiteType,
  workspacePlan,
  licenceType,
  licencePlanName,
  handleLicenceId,
  licenceId,
  openAppDropdown,
  handleAppDropdown,
  storeUrlError,
  websiteUrl,
  readOnly,
  handleWebsiteUrl,
  updateInput,
  createApp,
  loader,
  submitVal,
  match, // Access match from props
}) => {
  // Access match.params here
  const { workspaceId } = match.params;
  return (
    <div className="new-createapp-form">
      <div className="d-flex new-createapp-head">
        <h1 className="title" id="clockid">
          {title}
        </h1>
        <div className="d-flex align-items-center">
          {localStorage.getItem("skipdone") !== null &&
          localStorage.getItem("skipdone") !== undefined &&
          localStorage.getItem("skipdone") == "done" ? (
            <>
              <a href="/apps">Skip</a>
              <Skip />
            </>
          ) : null}
        </div>
      </div>
      <div>
        <form onSubmit={createApp}>
          <div className="form-group new-createapp-firstinput">
            <label className="new-myapp-name">Give your app a name*<Tooltip
                            message={
                              " Enter a name that represents your app. This will be displayed to app users and can be updated at any time."
                            }
                            />
                            </label>
            <input
              type="text"
              name="websiteName"
              className="form-control"
              maxLength="30"
              value={websiteName}
              onChange={(e) => handleWebsiteName(e)}
            />
            {appNameError ? (
              <div className="d-flex align-items-center new-error">
                <Error />
                <p>{appNameError}</p>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="form-group new-create-app-group">
            <p className="new-web-type-text">App type*<Tooltip
                            message={
                              "Select the type of app you would like to create based on your requirement. This can be updated at any time."
                            }
                            /></p>
            <div className="create-firstappdiv">

  {(workspaceWebTechnology === 2 ||
                workspaceWebTechnology === 3 ||
                workspaceWebTechnology === 5) &&
              match.params.workspaceId !== "undefined" &&
              match.params.workspaceId &&
              workspacePlan !== process.env.REACT_APP_STRIPE_PREVIEW ? (
                <div className="d-flex align-items-center new-apptype-seconddiv disabled-apptype">
                  <div className="d-flex align-items-center new-apptype">
                    <img
                      src={
                        require("../../assets/images/getstarted/createapp-wordpress.png")
                          .default
                      }
                      className="img-fluid"
                      alt="error"
                    />
                    <p>WordPress App</p>
                  </div>
                </div>
              ) : websitePlatform === 4 ? (
                <div
                  className="d-flex align-items-center new-apptype-seconddiv select-wordpress apptype-notselect"
                  onClick={(e) => handleWebsiteType(4)}
                >
                  <div
                    className="d-flex align-items-center new-apptype"
                    onClick={(e) => handleWebsiteType(4)}
                  >
                    <img
                      src={
                        require("../../assets/images/getstarted/createapp-wordpress.png")
                          .default
                      }
                      className="img-fluid"
                      alt="error"
                    />
                    <p>WordPress App</p>
                  </div>
                  <Success />
                </div>
              ) : websitePlatform !== 4 ? (
                <div
                  className="d-flex align-items-center new-apptype-seconddiv apptype-notselect"
                  onClick={(e) => handleWebsiteType(4)}
                >
                  <div className="d-flex align-items-center new-apptype">
                    <img
                      src={
                        require("../../assets/images/getstarted/createapp-wordpress.png")
                          .default
                      }
                      className="img-fluid"
                      alt="error"
                    />
                    <p>WordPress App</p>
                  </div>
                </div>
              ) : null}

{(workspaceWebTechnology === 2 ||
                workspaceWebTechnology === 3 ||
                workspaceWebTechnology === 4) &&
              match.params.workspaceId !== "undefined" &&
              match.params.workspaceId &&
              workspacePlan !== process.env.REACT_APP_STRIPE_PREVIEW ? (
                <div className="d-flex align-items-center new-wooapptype-div  disabled-apptype">
                  <div className="d-flex align-items-center new-apptype">
                    <img
                      src={
                        require("../../assets/images/getstarted/createapp-woo.png")
                          .default
                      }
                      className="img-fluid"
                      alt="error"
                    />
                    <p>WooCommerce App</p>
                  </div>
                </div>
              ) : websitePlatform === 5 ? (
                <div className="d-flex align-items-center new-wooapptype-div select-wooComerce apptype-notselect">
                  <div className="d-flex align-items-center new-apptype">
                    <img
                      src={
                        require("../../assets/images/getstarted/createapp-woo.png")
                          .default
                      }
                      className="img-fluid"
                      alt="error"
                    />
                    <p>WooCommerce App</p>
                  </div>
                  <Success />
                </div>
              ) : websitePlatform !== 5 ? (
                <div
                  className="d-flex align-items-center new-wooapptype-div  apptype-notselect"
                  onClick={(e) => handleWebsiteType(5)}
                >
                  <div className="d-flex align-items-center new-apptype">
                    <img
                      src={
                        require("../../assets/images/getstarted/createapp-woo.png")
                          .default
                      }
                      className="img-fluid"
                      alt="error"
                    />
                    <p>WooCommerce App</p>
                  </div>
                </div>
              ) : null}

            
            </div>

            <div className="create-firstappdiv">

            {(workspaceWebTechnology === 2 ||
                workspaceWebTechnology === 3 ||
                workspaceWebTechnology === 5) &&
              match.params.workspaceId !== "undefined" &&
              match.params.workspaceId &&
              workspacePlan !== process.env.REACT_APP_STRIPE_PREVIEW ? (
                <div className="d-flex align-items-center new-apptype-div disabled-apptype">
                  <div className="d-flex align-items-center new-apptype">
                    <img
                      src={
                        require("../../assets/images/getstarted/createapp-website.png")
                          .default
                      }
                      className="img-fluid"
                      alt="error"
                    />
                    <p>Web to App</p>
                  </div>
                </div>
              ) : websitePlatform !== 2 ? (
                <div
                  className="d-flex align-items-center new-apptype-div apptype-notselect"
                  onClick={(e) => handleWebsiteType(2)}
                >
                  <div className="d-flex align-items-center new-apptype">
                    <img
                      src={
                        require("../../assets/images/getstarted/createapp-website.png")
                          .default
                      }
                      className="img-fluid"
                      alt="error"
                    />
                    <p>Web to App</p>
                  </div>
                </div>
              ) : websitePlatform === 2 ||
                websitePlatform === 1 ||
                !websitePlatform ? (
                <div className="d-flex align-items-center new-apptype-div select-web-toapp apptype-notselect">
                  <div className="d-flex align-items-center new-apptype">
                    <img
                      src={
                        require("../../assets/images/getstarted/createapp-website.png")
                          .default
                      }
                      className="img-fluid"
                      alt="error"
                    />
                    <p>Web to App</p>
                  </div>
                  <Success />
                </div>
              ) : null}


              

              {(workspaceWebTechnology === 2 ||
                workspaceWebTechnology === 4 ||
                workspaceWebTechnology === 5) &&
              match.params.workspaceId !== "undefined" &&
              match.params.workspaceId &&
              workspacePlan !== process.env.REACT_APP_STRIPE_PREVIEW ? (
                <div className="d-flex align-items-center new-customapptype-seconddiv  disabled-apptype">
                  <div className="d-flex align-items-center new-apptype">
                    <img
                      src={
                        require("../../assets/images/getstarted/createapp-custom.png")
                          .default
                      }
                      className="img-fluid"
                      alt="error"
                    />
                    <p>Custom App</p>
                  </div>
                </div>
              ) : websitePlatform == 3 ? (
                <div className="d-flex align-items-center new-customapptype-seconddiv select-Customapp apptype-notselect">
                  <div className="d-flex align-items-center new-apptype">
                    <img
                      src={
                        require("../../assets/images/getstarted/createapp-custom.png")
                          .default
                      }
                      className="img-fluid"
                      alt="error"
                    />
                    <p>Custom App</p>
                  </div>
                  <Success />
                </div>
              ) : websitePlatform != 3 ? (
                <div
                  className="d-flex align-items-center new-customapptype-seconddiv  apptype-notselect"
                  onClick={(e) => handleWebsiteType(3)}
                >
                  <div className="d-flex align-items-center new-apptype">
                    <img
                      src={
                        require("../../assets/images/getstarted/createapp-custom.png")
                          .default
                      }
                      className="img-fluid"
                      alt="error"
                    />
                    <p>Custom App</p>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          {websitePlatform === 1 || !websitePlatform ? (
            <div className="form-group form-group-slider-create mb-0">
              {/* Your content here */}
            </div>
          ) : (
            websitePlatform !== 3 &&
            websitePlatform !== 2 && <div className="toggle-sub-height"></div>
          )}

          {(!match.params.workspaceId ||
            match.params.workspaceId === "undefined") &&
            licenceType.length > 0 && (
              <div className="form-group createapp-lastinput">
                <p className="new-web-type-url">Apply deal<Tooltip
                            message={
                              "Select a deal to apply to your app."
                            }
                            /></p>
                <div className="createapp-dropdown">
                  <Dropdown isOpen={openAppDropdown} toggle={handleAppDropdown}>
                    <DropdownToggle caret>{licencePlanName}</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => handleLicenceId("", "Select")}
                      >
                        Select
                      </DropdownItem>
                      {licenceType.map((res, i) =>
                        res.used_app_count === res.total_app_count ? (
                          <DropdownItem
                            className="expireddeal"
                            value={res.licence_uuid}
                            key={i}
                          >
                            <span>{res.plan_name}</span>
                            <span>
                              {res.used_app_count +
                                (res.total_app_count === "" ? "" : "/") +
                                res.total_app_count}{" "}
                              used
                            </span>
                          </DropdownItem>
                        ) : res.status === "inactive" ? (
                          <DropdownItem
                            className="expireddeal"
                            value={res.licence_uuid}
                            key={i}
                          >
                            <span>{res.plan_name}</span>
                            <span>Expired</span>
                          </DropdownItem>
                        ) : res.used_app_count < res.total_app_count ||
                          res.total_app_count === "" ? (
                          <DropdownItem
                            active={res.licence_uuid === licenceId}
                            onClick={() =>
                              handleLicenceId(res.licence_uuid, res.plan_name)
                            }
                            value={res.licence_uuid}
                            key={i}
                          >
                            <span>{res.plan_name}</span>
                            {res.total_app_count !== "" ? (
                              <span>
                                {res.used_app_count +
                                  (res.total_app_count === "" ? "" : "/") +
                                  res.total_app_count}{" "}
                                used
                              </span>
                            ) : (
                              <span>Unlimited</span>
                            )}
                          </DropdownItem>
                        ) : (
                          <DropdownItem
                            className="disableddeal"
                            value={res.licence_uuid}
                            key={i}
                          >
                            <span>{res.plan_name}</span>
                            <span>Not applicable</span>
                          </DropdownItem>
                        )
                      )}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            )}

          {websitePlatform !== 3 && (
            <div className="form-group createapp-lastinput">
              <p className="new-web-type-url">Website*<Tooltip
                            message={
                              "Enter your website’s address. This will be used to connect your app and sync content seamlessly."
                            }
                            /></p>
              <div className="d-flex justify-content-between align-items-center input-btn spinntop">
                <input
                  className="w-100 form-control"
                  type="text"
                  name="websiteUrl"
                  readOnly={readOnly}
                  value={websiteUrl}
                  placeholder=""
                  onChange={handleWebsiteUrl}
                  onBlur={updateInput}
                />
              </div>

              {storeUrlError && (
                <div className="d-flex align-items-center new-error">
                  <Error />
                  <p>{storeUrlError}</p>
                </div>
              )}
            </div>
          )}
          <div
            className={
              websitePlatform != "3" && websiteUrl && websiteName
                ? "createapp-createbutton"
                : websitePlatform == "3" && websiteName
                ? "createapp-createbutton"
                : "createapp-disbutton"
            }
            onClick={createApp}
          >
            <button
              style={{
                "--custom_color1":
                  localStorage.getItem("agencyid") == 1
                    ? "#3064f9"
                    : localStorage.getItem("button_background_color"),
                "--custom_color2":
                  localStorage.getItem("agencyid") == 1
                    ? "white"
                    : localStorage.getItem("button_text_color"),
                "--custom_color5":
                  localStorage.getItem("agencyid") == 1
                    ? "#134bec"
                    : localStorage.getItem("button_hover_bgcolor"),
                "--custom_color4":
                  localStorage.getItem("agencyid") == 1
                    ? "white"
                    : localStorage.getItem("button_hover_txtcolor"),
              }}
              type={
                websitePlatform != "3" && websiteUrl && websiteName
                  ? "submit"
                  : websitePlatform == "3" && websiteName
                  ? "submit"
                  : "button"
              }
            >
              <div className="d-flex align-items-center justify-content-center">
                <div className={loader ? "d-block" : "d-none"}>
                  <div className="new-loader"></div>
                </div>{" "}
                <div>{submitVal}</div>
              </div>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateAppForm;
