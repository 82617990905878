import React, { Component } from "react";
import { Carousel } from "react-responsive-carousel";

export class Web2AppPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // activeSlide: 0,
    };
  }
  returnSelectedModuleImg = (module_name, ad_position) => {
    if (module_name === "home_screen") {
      if (ad_position === "top") {
        return (
          <img
            src={
              require("../../assets/images/dashboard/advertisement/hometopadd.png")
                .default
            }
          />
        );
      } else {
        return (
          <img
            src={
              require("../../assets/images/dashboard/advertisement/homebottomadd.png")
                .default
            }
          />
        );
      }
    } else if (module_name === "product_cat" || module_name === "blog_cat") {
      if (ad_position === "top") {
        return (
          <img
            src={
              require("../../assets/images/dashboard/advertisement/categoriespagetopadd.png")
                .default
            }
          />
        );
      } else {
        return (
          <img
            src={
              require("../../assets/images/dashboard/advertisement/categoriespagebottomadd.png")
                .default
            }
          />
        );
      }
    } else if (module_name === "product_list") {
      if (ad_position === "top") {
        return (
          <img
            src={
              require("../../assets/images/dashboard/advertisement/producttopadd.png")
                .default
            }
          />
        );
      } else {
        return (
          <img
            src={
              require("../../assets/images/dashboard/advertisement/productbottomadd.png")
                .default
            }
          />
        );
      }
    } else if (module_name === "product_details") {
      if (ad_position === "top") {
        return (
          <img
            src={
              require("../../assets/images/dashboard/advertisement/productdetailtopadd.png")
                .default
            }
          />
        );
      } else {
        return (
          <img
            src={
              require("../../assets/images/dashboard/advertisement/productdetailbottomadd.png")
                .default
            }
          />
        );
      }
    } else if (module_name === "cart") {
      if (ad_position === "top") {
        return (
          <img
            src={
              require("../../assets/images/dashboard/advertisement/carttopadd.png")
                .default
            }
          />
        );
      } else {
        return (
          <img
            src={
              require("../../assets/images/dashboard/advertisement/cartbottomadd.png")
                .default
            }
          />
        );
      }
    } else if (module_name === "page_list") {
      if (ad_position === "top") {
        return (
          <img
            src={
              require("../../assets/images/dashboard/advertisement/pagestopadd.png")
                .default
            }
          />
        );
      } else {
        return (
          <img
            src={
              require("../../assets/images/dashboard/advertisement/pagesbottomadd.png")
                .default
            }
          />
        );
      }
    } else if (module_name === "page_details") {
      if (ad_position === "top") {
        return (
          <img
            src={
              require("../../assets/images/dashboard/advertisement/pagedetailstopadd.png")
                .default
            }
          />
        );
      } else {
        return (
          <img
            src={
              require("../../assets/images/dashboard/advertisement/pagedetailsbottomadd.png")
                .default
            }
          />
        );
      }
    } else if (
      module_name === "post_details" ||
      module_name === "blog_details"
    ) {
      if (ad_position === "top") {
        return (
          <img
            src={
              require("../../assets/images/dashboard/advertisement/postpageaddtop.png")
                .default
            }
          />
        );
      } else {
        return (
          <img
            src={
              require("../../assets/images/dashboard/advertisement/postpageaddbottom.png")
                .default
            }
          />
        );
      }
    } else if (module_name === "web_view") {
      if (ad_position === "top") {
        return (
          <img
            src={
              require("../../assets/images/dashboard/advertisement/webviewadd.png")
                .default
            }
          />
        );
      } else {
        return (
          <img
            src={
              require("../../assets/images/dashboard/advertisement/webviewadd.png")
                .default
            }
          />
        );
      }
    }
  };
  render() {
    return (
      <Carousel
        selectedItem={this.props.value.state.activeSlide}
        // onChange={(e) => this.handlesliderChange(e)}
        showArrows={false}
        swipeable={true}
        showThumbs={false}
      >
        {this.props.value.state.adType === "interstitial" ? (
          <div className="second-ad">
            <div className="second-ad-div-main">
              <div className="second-ad-div-cross">
                <img
                  src={
                    require("../../assets/images/dashboard/cross.png").default
                  }
                />
              </div>
              <div className="second-ad-div-text">Google interstitial ad</div>
              <div className="second-div-ad">Ad</div>
            </div>
          </div>
        ) : this.props.value.state.adType === "interstitial" ? (
          <div className="first-ad">
            <div className="first-ad-top">
              <div className="first-ad-top-div">
                <div className="first-ad-top-div-img"></div>
                <p>Continue to app</p>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="5.273"
                height="8.849"
                viewBox="0 0 5.273 8.849"
              >
                <path
                  id="Path_108856"
                  data-name="Path 108856"
                  d="M5767.731-7085.358l4,4-4,4"
                  transform="translate(-5767.307 7085.783)"
                  fill="none"
                  stroke="#b0b0b0"
                  stroke-width="1.2"
                />
              </svg>
            </div>
            <div className="first-ad-last">
              <p className="ad-first-open-title">Advertisement</p>
              <div className="ad-first-open-text-div">
                <p className="ad-first-tag">Ad</p>
                <p className="ad-first-open-text">Google app open ad</p>
              </div>
            </div>
          </div>
        ) : (
          this.props.value.state.locationArr &&
          this.props.value.state.locationArr.map((res, indx) => {
            return (
              <div
                className={`ad-slide-${indx}`}
                style={{
                  height: "649px",
                }}
              >
                <div className="every-ad-img">
                  {/* <div className="third-ad-div"> */}
                  <div className="third-ad-textdiv">
                    <div
                      className={`${
                        this.props.value.state.adType === "banner"
                          ? "third-ad-text"
                          : "fourth-ad-text"
                      } ${
                        this.props.value.state.fitBanner === true
                          ? ""
                          : "ad-with-padding"
                      }`}
                      style={{
                        top:
                          res.ad_position === "top"
                            ? this.props.value.state.fitBanner === true
                              ? "44px"
                              : "54px"
                            : "unset",
                        bottom: res.ad_position === "bottom" ? "15px" : "unset",
                        // height:this.props.value.state.adType === "custom"?"88px":"44px"
                      }}
                    >
                      {this.props.value.state.adType === "custom" ? (
                        <p>Custom ad</p>
                      ) : (
                        <p>Google Admob banner</p>
                      )}
                    </div>
                    {/* </div> */}
                  </div>
                  {this.returnSelectedModuleImg(
                    res.module_name,
                    res.ad_position
                  )}
                </div>
              </div>
            );
          })
        )}

        {/* <div className="ad-slide-2"><p>ll</p></div> */}
      </Carousel>
    );
  }
}

export default Web2AppPreview;
