import axios from "axios";
import S3 from "react-aws-s3";
import { errortoast, sucesstoast } from "../../Dashboard/Toaster";
import { CustomS3Client } from "../../utils/S3utils";
const Url = process.env.REACT_APP_API_URL + "api/app/";
const IconUrl = process.env.REACT_APP_API_URL + "api/list-icons";
const NewIconUrl = process.env.REACT_APP_API_URL + "api/newlist-icons";
const FreeIkIconUrl = process.env.REACT_APP_API_URL + "api/app/get-icons";
const RedirectUrl = process.env.REACT_APP_cors_api;
const cmsUrl = process.env.REACT_APP_API_URL + "api/cms/";

const Loader = (state) => {
  state.setState({
    submitbuttontext: "",
    loader: "loader",
  });
};

const returnSelectedModuleName = (module_name,row,state) => {
  
  let label = "";
  if (module_name === "chat") {
    label = "Chat";
  } else if (module_name === "main_menu") {
    label = "Menu";
  } else if (module_name === "settings") {
    label = "Settings";
  } else if (module_name === "web_view") {
    label = "Web view";
  } else if (module_name === "home") {
    label = "Home screen";
  } else if (module_name === "profile") {
    label = "Profile";
  } else if (module_name === "product_categories") {
    label = "Product categories";
  } else if (module_name === "product_search") {
    label = "Product search";
  } else if (module_name === "cart") {
    label = "Cart";
  } else if (module_name === "pages") {
    label = "Pages";
  } else if (module_name === "orders") {
    label = "Orders";
  }
  else if (module_name === "cms_post") {
    label = "Posts";
  }
  else if (module_name === "cms_page") {
    label = "Pages";
  }
  else if (module_name === "cms_category") {
    label = "Post categories";
  }
  else if (module_name === "cms_tag") {
    label = "Post Tags";
  }
  // else {

  //   state.state.mergeArray && state.state.mergeArray.length > 0 &&
  //   state.state.mergeArray.map((value, k) => 
  //   {
  
  //     if(row.temp_item_type + row.slug + row.rest_base === value.type + value.slug + value.rest_base){
  //       
  //       label = value.name;
  //     }
            
  //   })
  // }


  return label;
};

// Get api
export const Bottommenuget = (state, callback) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Url + "get-bottom-menu/" + btoa(localStorage.getItem("Appid")), {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        if (res.data.data !== null) 
        {
          let newBottomMenuArr =res.data.data.bottom_menu_items;

          newBottomMenuArr.map((item)=>{
            item.temp_item_type=item.item_type;
            item.module_label="";
            item.temp_module_label="";
            item.temp_invidual_selected_item_id= item.invidual_selected_item_id;
            item.temp_subData=[];
            item.subData=[];
            item.temp_breadcrumbArr=[];
            item.breadcrumbArr=[];
            item.showL1Options=true;
            item.temp_showL1Options=true;
            item.subdropdownSpinner= true;
            item.subdropdownData= [];
          })

          
          state.setState({
            android_build_version:res.data.android_build_version,
            android_supported_version:res.data.android_supported_version,
            ios_build_version:res.data.ios_build_version,
            ios_supported_version:res.data.ios_supported_version,
            isMenuEnable:res.data.enable_menu,
            isAnyDefaultIteminMenu:res.data.is_any_default_item_in_menu,
        });
            state.setState(
              {
                storeUrl: res.data.store_url,
                //theme
                theme: res.data.website_type,
                // spinner: false,
                bottom_menu_items: newBottomMenuArr,
                bottomicontext:
                  res.data.data.bottom_menu_style.bottom_menu_show_text === 1
                    ? true
                    : false,
                
                toggle :
                    res.data.data.enable_bottom_menu !== undefined
                    ? res.data.data.enable_bottom_menu == 1 ? true : false
                    : true,
                
                menutext :
                    res.data.data.bottom_menu_style.bottom_menu_text_for_menu !== undefined
                    ? res.data.data.bottom_menu_style.bottom_menu_text_for_menu
                    : 'More',
                
                menuicon :
                    res.data.data.bottom_menu_style.bottom_menu_icon_for_menu !== undefined
                    ? res.data.data.bottom_menu_style.bottom_menu_icon_for_menu != '' ? res.data.data.bottom_menu_style.bottom_menu_icon_for_menu : ''
                    : '',
                
                gridcolumn :
                    res.data.data.bottom_menu_style.bottom_menu_grid_view_column !== undefined
                    ? res.data.data.bottom_menu_style.bottom_menu_grid_view_column
                    : 2,

                iconshape :
                    res.data.data.bottom_menu_style.bottom_menu_grid_view_tile_shape !== undefined
                    ? res.data.data.bottom_menu_style.bottom_menu_grid_view_tile_shape
                    : "square",
                
                bottomiconview :
                    res.data.data.bottom_menu_style.bottom_menu_show_icon !== undefined
                    ? res.data.data.bottom_menu_style.bottom_menu_show_icon == 1 ? true : false
                    : true,
                
                icontext :
                    res.data.data.bottom_menu_style.bottom_menu_grid_view_show_text !== undefined
                    ? res.data.data.bottom_menu_style.bottom_menu_grid_view_show_text == 1 ? true : false
                    : true,
                
                iconview :
                    res.data.data.bottom_menu_style.bottom_menu_grid_view_show_icon !== undefined
                    ? res.data.data.bottom_menu_style.bottom_menu_grid_view_show_icon == 1 ? true : false
                    : true,
                
                gridview :
                    res.data.data.bottom_menu_style.bottom_menu_show_grid_view !== undefined
                    ? res.data.data.bottom_menu_style.bottom_menu_show_grid_view == 1 ? true : false
                    : true,
                
                backgroundColor:
                    res.data.data.bottom_menu_style.bottom_menu_bg_colour_object !== undefined
                  ? res.data.data.bottom_menu_style.bottom_menu_bg_colour_object.portal_data 
                  : res.data.data.bottom_menu_style.bottom_menu_bg_colour,
                
                iconcolor: 
                  res.data.data.bottom_menu_style.bottom_menu_icon_colour_object?.portal_data
                  || 'rgba(255,255,255,1)',

                selectediconcolor: 
                  res.data.data.bottom_menu_style.bottom_menu_selected_icon_colour_object?.portal_data
                  || 'rgba(187,209,255,1)',
                
                menuiconcolor:res.data.data.bottom_menu_style?.bottom_menu_icon_colour_type_for_menu || ""  ,
                
                gridbackgroundcolor: 
                  res.data.data.bottom_menu_style.bottom_menu_grid_view_bg_color_object !== undefined 
                  ? res.data.data.bottom_menu_style.bottom_menu_grid_view_bg_color_object.portal_data
                  : 'linear-gradient(129deg, rgba(50, 167, 255,1) 0%, rgba(90, 111, 251,1) 50%, rgba(119, 87, 255,1) 100%)',
                
                pagebackgroundcolor: 
                  res.data.data.bottom_menu_style.bottom_menu_grid_page_bg_color_object !== undefined 
                  ? res.data.data.bottom_menu_style.bottom_menu_grid_page_bg_color_object.portal_data
                  : '#eeeeee',
                
                gridiconcolor:
                    res.data.data.bottom_menu_style.bottom_menu_grid_view_icon_color_object !== undefined
                  ? res.data.data.bottom_menu_style.bottom_menu_grid_view_icon_color_object.portal_data
                  : 'rgba(255,255,255,1)',

                gridselectediconcolor: 
                    res.data.data.bottom_menu_style.bottom_menu_grid_view_selected_icon_color_object !== undefined 
                  ? res.data.data.bottom_menu_style.bottom_menu_grid_view_selected_icon_color_object.portal_data
                  : 'rgba(187,209,255,1)',
                
                gridselectedtilebackgroundcolor:
                  res.data.data.bottom_menu_style.bottom_menu_grid_view_selected_tile_background_color_object !== undefined 
                  ? res.data.data.bottom_menu_style.bottom_menu_grid_view_selected_tile_background_color_object.portal_data
                  : 'rgba(255,255,255,0.7)',
                
                gridselectedtilebordercolor:
                  res.data.data.bottom_menu_style.bottom_menu_grid_view_selected_tile_border_color_object !== undefined 
                  ? res.data.data.bottom_menu_style.bottom_menu_grid_view_selected_tile_border_color_object.portal_data
                  : 'rgba(60,153,254,1)',
                
                gridtextcolor: 
                  res.data.data.bottom_menu_style.bottom_menu_grid_view_icon_text_color_object !== undefined 
                  ? res.data.data.bottom_menu_style.bottom_menu_grid_view_icon_text_color_object.portal_data
                  : 'rgba(255,255,255,1)',
                
                gridselectedtextcolor: 
                  res.data.data.bottom_menu_style.bottom_menu_grid_view_selected_icon_text_color_object !== undefined 
                  ? res.data.data.bottom_menu_style.bottom_menu_grid_view_selected_icon_text_color_object.portal_data
                  : 'rgba(255,255,255,1)',
                
                gridtilebackgroundcolor: 
                  res.data.data.bottom_menu_style.bottom_menu_grid_view_tile_background_color_object !== undefined 
                  ? res.data.data.bottom_menu_style.bottom_menu_grid_view_tile_background_color_object.portal_data
                  : 'rgba(255,255,255, 0.7)',
                
                gridtilebordercolor: 
                  res.data.data.bottom_menu_style.bottom_menu_grid_view_tile_border_color_object !== undefined 
                  ? res.data.data.bottom_menu_style.bottom_menu_grid_view_tile_border_color_object.portal_data
                  : 'rgba(60,153,254,1)',
                
                icontextcolor:
                    res.data.data.bottom_menu_style.bottom_menu_text_colour_object !== undefined 
                  ? res.data.data.bottom_menu_style.bottom_menu_text_colour_object.portal_data
                  : res.data.data.bottom_menu_style.bottom_menu_icon_and_text_colour,

                selectedicontextcolor:
                      res.data.data.bottom_menu_style.bottom_menu_selected_text_colour_object !== undefined
                    ? res.data.data.bottom_menu_style.bottom_menu_selected_text_colour_object.portal_data
                    : res.data.data.bottom_menu_style.bottom_menu_selected_item_colour,
                  
                borderColor:
                    res.data.data.bottom_menu_style.bottom_menu_border_colour_object !== undefined 
                  ? res.data.data.bottom_menu_style.bottom_menu_border_colour_object.portal_data
                  : res.data.data.bottom_menu_style.bottom_menu_border_colour,

                android_build_version: res.data.android_build_version,
                keys_data: res.data.keys_data,
                is_chat_enabled: res.data.is_chat_enabled,
                chat_data: res.data.chat_data,
                updated_at:res.data.updated_at?res.data.updated_at:"",
              },
              () => callback()
            );
        } 
        else {
          state.setState(
            {
              spinner: false,
              theme: res.data.website_type,
              android_build_version: res.data.android_build_version,
              selectedtextcolor: "#000000",
              keys_data: res.data.keys_data,
              bottom_menu_items: [
                {
                  status: 0,
                  item_name: "Home",
                  item_type: "home",
                  item_icon: "house-chimney-1.png",
                  item_icon_type: "bold",
                  is_default: 0,
                  item_position: 0,
                  id: "home",
                  slug: "",
                  rest_base: "",
                },
              ],
              setting: [false],
            },
            () => callback()
          );
        }
      }
    })
    .catch((error) => {});
};
//Geticon
export const Getmenulist = (state, type) => {
  state.setState({
    icon_spinner: true,
    selectediconitem: "",
  });
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  
  axios
    .get(IconUrl + "?icon_type=" + type, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          iconlist1: res.data.data,
          icon_spinner: false,
          icon_type: type,
          icon_selected_type: type,
        });
      }
    })
    .catch((error) => {});
};

//Geticon
export const GetNewmenulist = (state,shape,color,searchicon,page) => {
  if(page == 1)
  {
    state.setState({
      icon_spinner: true,
      selectediconitem: "",
      page:1,
      nextpageicon_spinner:false,
      setIsPushed:true,

    });
    state.setState({iconlist:[]});
  }
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  if(searchicon == '')
  {
      searchicon = 'solid-black';
  }

  

  axios
    .get(NewIconUrl+"/"+ shape+"/"+ color+"/"+ searchicon+"/"+page , {
      headers: headers,
    })
    .then((res) => {
      if(res.data.code === 200) 
      {
        if(page == 1)
        {
          state.setState({
            iconlist: res.data.data.data,
            icon_spinner: false,
            selecticonshapefilter:shape,
            selecticoncolorfilter:color,
            setIsPushed:true,
            nextpageicon_spinner:false
          });
        }
        else
        {
          state.setState({
            iconlist: state.state.iconlist.concat(res.data.data.data),
            selecticonshapefilter:shape,
            selecticoncolorfilter:color,
            nextpageicon_spinner:false,
            setIsPushed:true,
          });
        }
      }
    })
    .catch(() => {
      state.setState({
        icon_spinner: false,
      });
    });
};

//Geticon
export const GetFreeIkNewmenulist = (state,shape,color,searchicon,page) => {
  if(page == 1)
  {
    state.setState({
      icon_spinner: true,
      selectediconitem: "",
      page:1,
      nextpageicon_spinner:false,
      setIsPushed:true,

    });
    state.setState({iconlist:[]});
  }
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  if(searchicon == '')
  {
      searchicon = 'solid-black';
  }

  

  axios
    .get(FreeIkIconUrl+"/"+btoa(localStorage.getItem("Appid"))+"?term="+ searchicon +"&slug="+ searchicon +"&color="+ color+"&shape="+ shape+"&page="+page , {
      headers: headers,
    })
    .then((res) => {
      if(res.data.code === 200) 
      {
        if(page == 1)
        {
          state.setState({
            iconlist: res.data.data,
            icon_spinner: false,
            // selecticonshapefilter:shape,
            // selecticoncolorfilter:color,
            setIsPushed:true,
            nextpageicon_spinner:false
          });
        }
        else
        {
          state.setState({
            iconlist: state.state.iconlist.concat(res.data.data),
            // selecticonshapefilter:shape,
            // selecticoncolorfilter:color,
            nextpageicon_spinner:false,
            setIsPushed:true,
          });
        }
      }
    })
    .catch(() => {
      state.setState({
        icon_spinner: false,
      });
    });
};

//save Api
export const Bottommenusubmit = (params, state) => {
  state.setState({
    submitbuttontext: "",
    loader: "new-loader",
  });
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  
  axios
    .post(Url + "save-bottom-menu", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) 
      {

        state.setState({
          submitbuttontext: "Save",
          loader: "d-none",
          updated_at:res.data.getdata.updated_at,
          // spinner: true,
        });

        // state.loaddata();
        // window.location.reload();
        sucesstoast("Data saved successfully");
      }
      else
      {
        errortoast("Some Param Missing");
      }
    })
    .catch(() => {
      state.setState({
        submitbuttontext: "Save",
        loader: "d-none",
      });
      errortoast("Something went wrong. please try after sometime.");
    });
};

//To Get Bottom menu > Types and toxonomies

export const BottoMenuTypeApi = (state, callback) => {
  const headers = {
    "Content-Type": "application/json",
    'X-App-Device-Type':'ios',
    'X-App-Code-Ver':localStorage.getItem("Apiversion"),
  };

  axios
    .get(
      RedirectUrl +
        state.state.storeUrl.replace("www.", "") +
        "/wp-json/wp/v2/types?page=1%26per_page=99",
      {
        headers: headers,
        data:{},
      }
    )
    .then((res) => {
      state.setState({
        typesData: res.data,
        spinner2: false,
      });
      if (callback !== undefined) {
        callback();
      }
    })
    .catch((err) => {
      state.setState({
        spinner: false,
        spinner2: false,
        isAll: false,
        subdropdownCheck: false,
      });
      
    });
};

export const BottoMenuTaxonomyApi = (state, checker,callback) => {
  const headers = {
    "Content-Type": "application/json",
    'X-App-Device-Type':'ios',
    'X-App-Code-Ver':localStorage.getItem("Apiversion"),
  };

  axios
    .get(
      RedirectUrl +
        state.state.storeUrl.replace("www.", "") +
        "/wp-json/wp/v2/taxonomies?page=1%26per_page=99",
      {
        headers: headers,
        data:{},
      }
    )
    .then((res) => 
    {
      state.setState({
        taxonomyData: res.data,
        spinner2: false,
      });
      if (checker === "yes") 
      {
        state.handleMergeArray();
      }
      if (callback !== undefined) {
        callback();
      }
    })
    .catch((err) => {
      state.setState({
        spinner: false,
        spinner2: false,
        isAll: false,
        subdropdownCheck: false,
      });
      
    });
};

let newArr = [];

function recurs(arr) {
  arr = arr.map((eachCat, indx) => {
    // let obj = { value: "", label: "", id: "" };
    // obj.id = eachCat._id;
    // obj.value = eachCat.name;
    // obj.label = eachCat.name;
    newArr.push(eachCat);

    if (eachCat.child !== undefined && eachCat.child.length > 0) {
      // if parent has childrens
      recurs(eachCat.child);
    }

    return arr;
  });
}

// Pages for Bottom Menu
export const cmsGetPagesForBtm = (state, indx, page , limit) => {
 
  state.setState({
    pageNo:page?page:1,
    paginationSpinner:page?true:false,
    isAllowPagination:page?state.state.isAllowPagination:true,
  })

 
  let newArrForPreview = [];
  let newBreadcrumb=[];
  let newSubData=[];
  let parentId="";
  let allPages =[];
  let newBottomMenuArr = [...state.state.bottom_menu_items];

  
  function recursForPreview(arr) {
    arr = arr.map((eachPage, indx1) => {

      // if(eachPage._id === newBottomMenuArr[indx].item && !parentId){
      //   parentId = eachPage._id;
    
      //  }
      let obj = { value: "", label: "", id: "" };
      obj.id = eachPage._id;
      obj.value = eachPage._id;
      obj.label = eachPage.title;
      newArrForPreview.push(eachPage);
   
      if (eachPage.child !== undefined && eachPage.child.length > 0) {
        // if parent has childrens
        recursForPreview(eachPage.child);
      }

      return arr;
    });
  }

  function recursForBreadcrumb(arr) {

    arr.map((eachPage) => {
      let obj = {
        name:"",
        id:"",
      }
    
      if(eachPage._id === parentId){
        parentId = eachPage.parent;
        obj.id = eachPage._id;
        obj.name = eachPage.title;
        newBreadcrumb.push(obj);

      }

     if (eachPage.child !== undefined && eachPage.child.length > 0) {
       // if parent has childrens
       recursForBreadcrumb(eachPage.child);
     }

     // return arr;
   });
 }

 function recursData(arr) {
  arr.map((eachPage) => {
  
   if(newBreadcrumb.length > 0 && eachPage.parent===newBreadcrumb[newBreadcrumb.length-1].id){
 
    newSubData.push(eachPage);
   }

   if (eachPage.child !== undefined && eachPage.child.length > 0) {
     // if parent has childrens
     recursData(eachPage.child);
   }

   // return arr;
 });
}

function recurs(arr) {
  arr && arr.map((eachPage)=>{

    recursForBreadcrumb(allPages);
   if (eachPage.child !== undefined && eachPage.child.length > 0) {
     recurs(eachPage.child);
   }
  })
}
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(cmsUrl + `getPages/${btoa(localStorage.getItem("Appid"))}?page=${page?page:1}&limit=${limit?limit:99}`, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {

      state.setState({
        paginationSpinner:false,
        isAllowPagination:( res.data.data && res.data.data.length > 0 && res.data.data.length >= limit )  ? true : false,
      });


      allPages=res.data.data
      recursForPreview(allPages);
      parentId=newBottomMenuArr[indx].invidual_selected_item_id;

      if(!page || page <= 1){

        recurs(allPages);

      }
     

        newBreadcrumb.splice(0,1)
        newBreadcrumb.reverse();
 

        recursData(allPages);


        if(!newBreadcrumb || newBreadcrumb.length===0){

          newBottomMenuArr[indx].subdropdownData = (page && page > 1 )?newBottomMenuArr[indx].subdropdownData.concat(allPages) :allPages;
          newBottomMenuArr[indx].subData = (page && page > 1 )?
          (newSubData.length===0 ? newBottomMenuArr[indx].subData.concat(allPages):newBottomMenuArr[indx].subData.concat(newSubData)) :
          (newSubData.length===0 ? allPages: newSubData );
          newBottomMenuArr[indx].temp_subData = (page && page > 1 )?
          (newSubData.length===0 ? newBottomMenuArr[indx].temp_subData.concat(allPages):newBottomMenuArr[indx].temp_subData.concat(newSubData)) :
          (newSubData.length===0 ? allPages: newSubData );
        }else{
          
          newBottomMenuArr[indx].subdropdownData = allPages;
          newBottomMenuArr[indx].subData = (newSubData.length===0 ? allPages: newSubData );
          newBottomMenuArr[indx].temp_subData = (newSubData.length===0 ? allPages: newSubData );
        }

        newBottomMenuArr[indx].temp_breadcrumbArr = newBreadcrumb;
        newBottomMenuArr[indx].breadcrumbArr = newBreadcrumb;
        newBottomMenuArr[indx].subdropdownSpinner = false;
        newBottomMenuArr[indx].temp_showL1Options =false;


        newBottomMenuArr[indx].temp_module_label = returnSelectedModuleName(
          newBottomMenuArr[indx].temp_item_type , newBottomMenuArr[indx] , state
        );
       
        newBottomMenuArr[indx].module_label = returnSelectedModuleName(
          newBottomMenuArr[indx].item_type , newBottomMenuArr[indx] , state
        );
        state.setState({
          bottom_menu_items: newBottomMenuArr,
          spinnerForsubDrop : false ,
        });
      } else {


        let newBottomMenuArr = [...state.state.bottom_menu_items];
        newBottomMenuArr[indx].subdropdownData = [];
        newBottomMenuArr[indx].subData = [];
        newBottomMenuArr[indx].temp_subData = [];
        newBottomMenuArr[indx].temp_breadcrumbArr = [];
        newBottomMenuArr[indx].breadcrumbArr = [];
        newBottomMenuArr[indx].subdropdownSpinner = false;
        newBottomMenuArr[indx].showL1Options = true;
        newBottomMenuArr[indx].temp_showL1Options = true;
        newBottomMenuArr[indx].temp_module_label = returnSelectedModuleName(
          newBottomMenuArr[indx].temp_item_type , newBottomMenuArr[indx] , state
        );
       
        newBottomMenuArr[indx].module_label = returnSelectedModuleName(
          newBottomMenuArr[indx].item_type , newBottomMenuArr[indx] , state
        );
        state.setState({
          bottom_menu_items: newBottomMenuArr,
          spinnerForsubDrop : false ,
        });
      }
    })
    .catch((err) => {
      // errortoast("Oh no! Something’s not right.");
      let newBottomMenuArr = [...state.state.bottom_menu_items];
      newBottomMenuArr[indx].subdropdownData = [];
      newBottomMenuArr[indx].subData = [];
      newBottomMenuArr[indx].temp_subData = [];
      newBottomMenuArr[indx].subdropdownSpinner = false;
      newBottomMenuArr[indx].showL1Options =true;
    
      newBottomMenuArr[indx].temp_showL1Options = true;
    
      newBottomMenuArr[indx].temp_breadcrumbArr = [];
      newBottomMenuArr[indx].breadcrumbArr = [];
      newBottomMenuArr[indx].temp_module_label = returnSelectedModuleName(
        newBottomMenuArr[indx].temp_item_type , newBottomMenuArr[indx] , state
      );
     
      newBottomMenuArr[indx].module_label = returnSelectedModuleName(
        newBottomMenuArr[indx].item_type , newBottomMenuArr[indx] , state
      );
      state.setState({
        bottom_menu_items: newBottomMenuArr,
        spinnerForsubDrop : false ,
      });
      
    });
};

//Get all Tags for Bottom Menu
export const cmsgetTagsForBtm = (state, indx, page , limit) => {


  state.setState({
    pageNo:page?page:1,
    paginationSpinner:page?true:false,
    isAllowPagination:page?state.state.isAllowPagination:true,
  })



  let newBottomMenuArr=[...state.state.bottom_menu_items];
  let newData = [];
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(cmsUrl + `getTags/${btoa(localStorage.getItem("Appid"))}?page=${page?page:1}&limit=${limit?limit:99}`, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {

        state.setState({
          paginationSpinner:false,
          isAllowPagination:( res.data.data && res.data.data.length > 0 && res.data.data.length >= limit )  ? true : false,
        });

        newData = res.data.data;

        newBottomMenuArr[indx].subdropdownData = (page && page > 1 ) ? newBottomMenuArr[indx].subdropdownData.concat(newData) :newData;
        newBottomMenuArr[indx].subData = (page && page > 1 )?newBottomMenuArr[indx].subData.concat(newData) :newData;
        newBottomMenuArr[indx].temp_subData =(page && page > 1 )?newBottomMenuArr[indx].temp_subData.concat(newData) :newData;


        newBottomMenuArr[indx].temp_breadcrumbArr = [];
        newBottomMenuArr[indx].breadcrumbArr = [];
        newBottomMenuArr[indx].subdropdownSpinner = false;
       
        newBottomMenuArr[indx].temp_showL1Options =false;
        
       
        newBottomMenuArr[indx].temp_module_label = returnSelectedModuleName(
          newBottomMenuArr[indx].temp_item_type , newBottomMenuArr[indx] , state
        );
       
        newBottomMenuArr[indx].module_label = returnSelectedModuleName(
          newBottomMenuArr[indx].item_type , newBottomMenuArr[indx] , state
        );  
        state.setState({
          bottom_menu_items: newBottomMenuArr,
          spinnerForsubDrop : false ,
        });
      } else {
        newBottomMenuArr[indx].subdropdownData = newData;
        newBottomMenuArr[indx].subData = newData;
        newBottomMenuArr[indx].temp_subData = newData;
        newBottomMenuArr[indx].temp_breadcrumbArr = [];
        newBottomMenuArr[indx].breadcrumbArr = [];
        newBottomMenuArr[indx].subdropdownSpinner = false;
        newBottomMenuArr[indx].showL1Options = true;
        newBottomMenuArr[indx].temp_showL1Options = true;
        newBottomMenuArr[indx].temp_module_label = returnSelectedModuleName(
          newBottomMenuArr[indx].temp_item_type , newBottomMenuArr[indx] , state
        );
        newBottomMenuArr[indx].module_label = returnSelectedModuleName(
          newBottomMenuArr[indx].item_type , newBottomMenuArr[indx] , state
        );

        state.setState({
          bottom_menu_items: newBottomMenuArr,
          spinnerForsubDrop : false ,
        });

      }
    })
    .catch((err) => {
      newBottomMenuArr[indx].subdropdownData = newData;
      newBottomMenuArr[indx].subData = newData;
      newBottomMenuArr[indx].temp_subData = newData;
      newBottomMenuArr[indx].temp_breadcrumbArr = [];
      newBottomMenuArr[indx].breadcrumbArr = [];
      newBottomMenuArr[indx].subdropdownSpinner = false;
      newBottomMenuArr[indx].showL1Options = true;
      newBottomMenuArr[indx].temp_showL1Options = true;
      newBottomMenuArr[indx].temp_module_label =returnSelectedModuleName(
        newBottomMenuArr[indx].temp_item_type , newBottomMenuArr[indx] , state
      );
      newBottomMenuArr[indx].module_label = returnSelectedModuleName(
        newBottomMenuArr[indx].item_type , newBottomMenuArr[indx] , state
      );

      state.setState({
        bottom_menu_items: newBottomMenuArr,
        spinnerForsubDrop : false ,
      });
    });
};

//Get all categories for Bottom Menu
export const cmsgetCategoryForBtm = (state, indx, page , limit) => {
  
  state.setState({
    pageNo:page?page:1,
    paginationSpinner:page?true:false,
    isAllowPagination:page?state.state.isAllowPagination:true,
  })

  let newArrForPreview = [];
  let newBreadcrumb=[];
  let newSubData=[];
  let parentId="";
  let allPages =[];
  let newBottomMenuArr = [...state.state.bottom_menu_items];



  function recursForBreadcrumb(arr) {

    arr.map((eachPage) => {
      let obj = {
        name:"",
        id:"",
      }
    
      if(eachPage._id == parentId){
        parentId = eachPage.parent;
        obj.id = eachPage._id.toString();
        obj.name = eachPage.name;
        newBreadcrumb.push(obj);

      }

     if (eachPage.child !== undefined && eachPage.child.length > 0) {
       // if parent has childrenrens
       recursForBreadcrumb(eachPage.child);
     }

     // return arr;
   });
 }

 function recursData(arr) {
  arr.map((eachPage) => {
  
   if(newBreadcrumb.length > 0 && eachPage.parent == newBreadcrumb[newBreadcrumb.length-1].id){
 
    newSubData.push(eachPage);
   }

   if (eachPage.child !== undefined && eachPage.child.length > 0) {
     // if parent has childrenrens
     recursData(eachPage.child);
   }

   // return arr;
 });
}

function recurs(arr) {
  arr && arr.map((eachPage)=>{

    recursForBreadcrumb(allPages);
   if (eachPage.child !== undefined && eachPage.child.length > 0) {
     recurs(eachPage.child);
   }
  })
}
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(cmsUrl + `getCategories/${btoa(localStorage.getItem("Appid"))}?page=${page?page:1}&limit=${limit?limit:99}`, {
      headers: headers,
    })
    .then((res) => {
      if (res.status === 200) {

        state.setState({
          paginationSpinner:false,
          isAllowPagination:( res.data.data && res.data.data.length > 0 && res.data.data.length >= limit )  ? true : false,
        });

      allPages = res.data.data;
    
      parentId=newBottomMenuArr[indx].invidual_selected_item_id;

      if(!page || page <= 1){
        recurs(allPages);
      }
     
    
      newBreadcrumb.splice(0,1)
        newBreadcrumb.reverse();
      



        recursData(allPages);


        if(!newBreadcrumb || newBreadcrumb.length===0){

          newBottomMenuArr[indx].subdropdownData = (page && page > 1 )?newBottomMenuArr[indx].subdropdownData.concat(allPages) :allPages;
          newBottomMenuArr[indx].subData = (page && page > 1 )?
          (newSubData.length===0 ? newBottomMenuArr[indx].subData.concat(allPages):newBottomMenuArr[indx].subData.concat(newSubData)) :
          (newSubData.length===0 ? allPages: newSubData );
          newBottomMenuArr[indx].temp_subData = (page && page > 1 )?
          (newSubData.length===0 ? newBottomMenuArr[indx].temp_subData.concat(allPages):newBottomMenuArr[indx].temp_subData.concat(newSubData)) :
          (newSubData.length===0 ? allPages: newSubData );
        }else{

          newBottomMenuArr[indx].subdropdownData = allPages;
          newBottomMenuArr[indx].subData = (newSubData.length===0 ? allPages: newSubData );
          newBottomMenuArr[indx].temp_subData = (newSubData.length===0 ? allPages: newSubData );
        }
        
        newBottomMenuArr[indx].temp_breadcrumbArr = newBreadcrumb;
        newBottomMenuArr[indx].breadcrumbArr = newBreadcrumb;
        newBottomMenuArr[indx].customAppPagedData = newArrForPreview;
        newBottomMenuArr[indx].subdropdownSpinner = false;
        newBottomMenuArr[indx].temp_showL1Options = false;

        newBottomMenuArr[indx].temp_module_label = returnSelectedModuleName(
          newBottomMenuArr[indx].temp_item_type , newBottomMenuArr[indx] , state
        );
       
        newBottomMenuArr[indx].module_label = returnSelectedModuleName(
          newBottomMenuArr[indx].item_type , newBottomMenuArr[indx] , state
        );
        state.setState({
          bottom_menu_items: newBottomMenuArr,
          spinnerForsubDrop : false ,
        });
      } else {


        let newBottomMenuArr = [...state.state.bottom_menu_items];
        newBottomMenuArr[indx].subdropdownData = [];
        newBottomMenuArr[indx].subData = [];
        newBottomMenuArr[indx].temp_subData = [];
        newBottomMenuArr[indx].temp_breadcrumbArr = [];
        newBottomMenuArr[indx].breadcrumbArr = [];
        newBottomMenuArr[indx].subdropdownSpinner = false;
        newBottomMenuArr[indx].showL1Options = true;
        newBottomMenuArr[indx].temp_showL1Options = true;
        newBottomMenuArr[indx].temp_module_label = returnSelectedModuleName(
          newBottomMenuArr[indx].temp_item_type , newBottomMenuArr[indx] , state
        );
       
        newBottomMenuArr[indx].module_label = returnSelectedModuleName(
          newBottomMenuArr[indx].item_type , newBottomMenuArr[indx] , state
        );
        state.setState({
          bottom_menu_items: newBottomMenuArr,
          spinnerForsubDrop : false ,
        });
      }
    })
    .catch((err) => {
      // errortoast("Oh no! Something’s not right.");
      let newBottomMenuArr = [...state.state.bottom_menu_items];
      newBottomMenuArr[indx].subdropdownData = [];
      newBottomMenuArr[indx].subData = [];
      newBottomMenuArr[indx].temp_subData = [];
      newBottomMenuArr[indx].subdropdownSpinner = false;
      newBottomMenuArr[indx].showL1Options =true;
    
      newBottomMenuArr[indx].temp_showL1Options = true;
    
      newBottomMenuArr[indx].temp_breadcrumbArr = [];
      newBottomMenuArr[indx].breadcrumbArr = [];
      newBottomMenuArr[indx].temp_module_label = returnSelectedModuleName(
        newBottomMenuArr[indx].temp_item_type , newBottomMenuArr[indx] , state
      );
     
      newBottomMenuArr[indx].module_label = returnSelectedModuleName(
        newBottomMenuArr[indx].item_type , newBottomMenuArr[indx] , state
      );
      state.setState({
        bottom_menu_items: newBottomMenuArr,
        spinnerForsubDrop : false ,
      });
      
    });
};

//CMS Get All Post API for Bottom Menu
export const cmsGetPostsForBtm = (state, indx,page,limit) => {

  state.setState({
    pageNo:page?page:1,
    paginationSpinner:page?true:false,
    isAllowPagination:page?state.state.isAllowPagination:true,
  })



  let newBottomMenuArr=[...state.state.bottom_menu_items];
  let newData = [];
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(
      cmsUrl + `getPosts/${btoa(localStorage.getItem("Appid"))}?page=${page?page:1}&limit=${limit?limit:99}`,
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {

        state.setState({
          paginationSpinner:false,
          isAllowPagination:( res.data.data && res.data.data.length > 0 && res.data.data.length >= limit )  ? true : false,
        });

        newData = res.data.data;

        newBottomMenuArr[indx].subdropdownData = (page && page > 1 ) ? newBottomMenuArr[indx].subdropdownData.concat(newData) :newData;
        newBottomMenuArr[indx].subData = (page && page > 1 )?newBottomMenuArr[indx].subData.concat(newData) :newData;
        newBottomMenuArr[indx].temp_subData =(page && page > 1 )?newBottomMenuArr[indx].temp_subData.concat(newData) :newData;
        newBottomMenuArr[indx].temp_breadcrumbArr = [];
        newBottomMenuArr[indx].breadcrumbArr = [];
        newBottomMenuArr[indx].subdropdownSpinner = false;
        newBottomMenuArr[indx].temp_showL1Options = false;

        newBottomMenuArr[indx].temp_module_label = returnSelectedModuleName(
          newBottomMenuArr[indx].temp_item_type , newBottomMenuArr[indx] , state
        );
       
        newBottomMenuArr[indx].module_label = returnSelectedModuleName(
          newBottomMenuArr[indx].item_type , newBottomMenuArr[indx] , state
        );
        state.setState({
          bottom_menu_items: newBottomMenuArr,
          spinnerForsubDrop : false ,
        });
      } else {
        newBottomMenuArr[indx].subdropdownData = newData;
        newBottomMenuArr[indx].subData = newData;
        newBottomMenuArr[indx].temp_subData = newData;
        newBottomMenuArr[indx].temp_breadcrumbArr = [];
        newBottomMenuArr[indx].breadcrumbArr = [];
        newBottomMenuArr[indx].subdropdownSpinner = false;
        newBottomMenuArr[indx].showL1Options = true;
        newBottomMenuArr[indx].temp_showL1Options = true;
        newBottomMenuArr[indx].temp_module_label = returnSelectedModuleName(
          newBottomMenuArr[indx].temp_item_type , newBottomMenuArr[indx] , state
        );
        newBottomMenuArr[indx].module_label = returnSelectedModuleName(
          newBottomMenuArr[indx].item_type , newBottomMenuArr[indx] , state
        );

        state.setState({
          bottom_menu_items: newBottomMenuArr,
          spinnerForsubDrop : false ,
        });

      }
    })
    .catch((err) => {
      newBottomMenuArr[indx].subdropdownData = newData;
      newBottomMenuArr[indx].subData = newData;
      newBottomMenuArr[indx].temp_subData = newData;
      newBottomMenuArr[indx].temp_breadcrumbArr = [];
      newBottomMenuArr[indx].breadcrumbArr = [];
      newBottomMenuArr[indx].subdropdownSpinner = false;
      newBottomMenuArr[indx].showL1Options = true;
      newBottomMenuArr[indx].temp_showL1Options = true;
      newBottomMenuArr[indx].temp_module_label =returnSelectedModuleName(
        newBottomMenuArr[indx].temp_item_type , newBottomMenuArr[indx] , state
      );
      newBottomMenuArr[indx].module_label = returnSelectedModuleName(
        newBottomMenuArr[indx].item_type , newBottomMenuArr[indx] , state
      );

      state.setState({
        bottom_menu_items: newBottomMenuArr,
        spinnerForsubDrop : false ,
      });
    });
};

// Upload any image to s3
export const uploadAnyBase64Imagetos3 = (
  params,
  orgFileName,
  callback,
  selectediconcolor

) => {
  //convert into image(base64 image to file object)
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      // mime = arr[0].match(/:(.*?);/)[1],
      mime = "image/png",
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }

  //Usage example:
  let file = dataURLtoFile(params.data, "hello.png");

  

  //send to s3
  const config = {
    bucketName: process.env.REACT_APP_bucketName,
    dirName: params.path.replace(/\/$/, "") /* optional */,
    region: process.env.REACT_APP_region,
    accessKeyId: process.env.REACT_APP_accessKeyId,
    secretAccessKey: process.env.REACT_APP_secretAccessKey,
    s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
  };
  // const ReactS3Client = new S3(config);
  const customS3Client = new CustomS3Client(config);

  const newFileName = orgFileName;

  customS3Client.uploadFile(file, newFileName)
    .then((data) => {
      if (data.status === 204) {
        // settingImgtoArray(newFileName + ".png", "original");
            callback(newFileName + ".png",selectediconcolor);
      }
    })
    .catch((err) => {
     
   
      // errortoast("Something went wrong. Try again after sometime.");
    });
};
