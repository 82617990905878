import React from "react";
import { Link } from "react-router-dom";
import Styles from "./connecntivity.module.css";
import Header from "../Header";
import Leftpanel from "../Leftpanel";
import { Modal, ModalHeader, ModalBody, Spinner, Media, ModalFooter } from "reactstrap";
import {
  GetwebsiteData,
  Getappcredentailapi,
  Validateurl,
  Getadminstatus,
  newAppcredentailapi,
} from "../../Api/Connectivity/Connectivity";
import { Foot } from "../Foot";
//import Amsvideo from "../Reusable/Amsvideos";
import Verifyemailstatus from "../../Verification/Checkverification";
import Troubleshooting from "./Troubleshooting";
import Tooltip from "../Tooltip";
import { Error } from "../Reusable/Connectivityerror";
import "./Apikey.css";
import { Appcredentailapi } from "../../Api/Dashboard/DashboardApi";
import moment from "moment";
import { connect } from "react-redux";
import { appDashboardApiSlice } from "../../services/appDashboardApiSlice";
export const detail = (props, hit) => {
  // const storeurl = (hit) => {
  //   const params = {
  //     domain: props.state.storeurl,
  //   };
  //   Validateurl(params, props, hit);
  // };
  return (
    <>
      <div className="apidescription">
        <div className="d-flex align-items-center justify-content-between">
          {hit === "application" ? (
            <>
              <ul
                className="ml-0 d-md-block datashow  getuser"
                onClick={(e) => props.handleWPpopUp(e)}
              >
                <p>
                  How to get?
                  {/* <span>
                    {" "}
                    <img
                      src={
                        require("../../assets/images/dashboard/Question.png")
                          .default
                      }
                      style={{ width: "14px" }}
                      alt="Goto"
                    />
                  </span> */}
                </p>
              </ul>
            </>
          ) : (
            <>
              <ul
                className="ml-0 d-md-block datashow  getuser"
                onClick={(e) => props.handleWOOpopUp(e)}
              >
                <p>
                  How to get?
                  {/* <span>
                    {" "}
                    <img
                      src={
                        require("../../assets/images/dashboard/Question.png")
                          .default
                      }
                      style={{ width: "14px" }}
                      alt="Goto"
                    />
                  </span> */}
                </p>
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
};
class Apikeys extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      spinner: true,
      woo_display_setting: false,
      wordpress_display_setting: false,
      modalWooPlugin: false,
      modalError: false,
      modalVideo: false,
      apikey: "",
      apisecret: "",
      projectid: "",
      packagename: "",
      submitval: "Verify",
      submitval2: "Save",
      loader: "d-none",
      loader2: "d-none",
      loaderimage: require("../../assets/images/signup/Loader_GIF.gif").default,
      success: "null",
      check_apisecret_for_image: "",
      check_adminpass_for_image: "",
      //Error
      api_secreterror: null,
      package_nameerror: null,
      project_iderror: null,
      api_keyerror: null,
      storeurl: "",
      eyeimage: require("../../assets/images/signup/secure.png").default,
      passattibutetype: "password",
      websiteurl: "",
      securetoggle: undefined,
      handlerender: true,
      showlist: false,
      plugincontent:
        "Your WooCommerce store could not connect with the mobile app.",
      verifykey: "",
      wordpress_display: true,
      woo_display: false,
      //admin usrname and password
      wp_admin_username: "",
      wp_admin_application_password: "",
      authorization_type: 2,
      website_type: null,
      is_verified: null,
      wooPopUp: false,
      wpPopUp: false,
      is_connected: null,
      updated_at:"",
      appRightPanelExpand: localStorage.getItem("appSideBarCollapse") === true ||
      localStorage.getItem("appSideBarCollapse") === "true"
        ? true
        : false,
     
      showRedInputWP:false,
      showRedInputWOO:false,
      openDisableTogglePopUp:false,   
    };
  }
  getCredential = () => {
    this.stopmounting = true;
    Getappcredentailapi(this, "api", () => {
      this.setState({
        submitval:
          parseInt(localStorage.getItem("website_technology")) === 4
            ? "Verify"
            : "Verify",
      });
    });
    this.setState({
      storeurl: localStorage.getItem("store_url"),
    });
  };
  componentDidMount() {
    // document.title = "Grant Access";
    if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
    {
      document.title = "WordPress access | AppMySite";
    }
    else 
    {
      document.title = "WordPress access |"+ ' ' +localStorage.getItem('agencyname');
    }
    window.scrollTo(0, 0);
    this.getCredential();
  }
  componentWillUnmount() {
    this.stopmounting = false;
  }

  //Open wordpres and Woocommerce Pop up
  handleWOOpopUp = (e) => {
    this.setState({
      wooPopUp: !this.state.wooPopUp,
    });
  };
  handleWPpopUp = (e) => {
    this.setState({
      wpPopUp: !this.state.wpPopUp,
    });
  };
  // Take me there function
  storeurl = (hit) => {
    const params = {
      domain: this.state.storeurl,
    };
    Validateurl(params, this, hit);
  };
  // Latest version modal
  togglemodalWooPlugin = (e) => {
    e.preventDefault();
    this.setState({
      modalWooPlugin: !this.state.modalWooPlugin,
    });
  };

  // Error modal
  togglemodalError = (e) => {
    e.preventDefault();
    this.setState({
      modalError: !this.state.modalError,
    });
  };

  // handle input value
  handleformvalue = (e) => {
    
    if (e.target.name === "wp_admin_username") {
      this.setState({
        [e.target.name]: e.target.value,
        showRedInputWP : false,
      });
    } else {
      if( e.target.name === "wp_admin_application_password" ){
        this.setState({
          showRedInputWP : false,
        });
      }else{
        this.setState({
          showRedInputWOO : false,
        });
      }
      this.setState({
        [e.target.name]: e.target.value.replaceAll(" ", ""),
      });
    }
  };

  // Handle force authentication
  handlesecuretoggle = (e) => {
    this.setState({
      securetoggle: !this.state.securetoggle,
    });
  };

  // check form value's verification and hit the save api.
  pluginsubmit = (e) => {

    let authorization_type = 0;
    if (this.state.woo_display === true && this.state.woo_display_setting === true) {
      authorization_type = 1;
    }
    if (this.state.wordpress_display === true && this.state.wordpress_display_setting=== true) {
      authorization_type = 2;
    }
    if (
      this.state.woo_display === false &&
      this.state.wordpress_display === false
    ) {
      authorization_type = 0;
    }


    e.preventDefault();
    const buttonClicked = e.nativeEvent.submitter;
    if(buttonClicked.id==="savebtn"){
      const paramsdata = {
        app_id: parseInt(localStorage.getItem("Appid")),
        authorization_type: authorization_type,
        consumer_key: this.state.apisecret.trim(),
        consumer_secret: this.state.apikey.trim(),
        wp_admin_username: this.state.wp_admin_username.trim(),
        wp_admin_application_password:this.state.wp_admin_application_password.trim(),
        is_verified: this.state.is_verified!==null && this.state.is_verified!==undefined ?this.state.is_verified :0 ,
        connection_to_establish: this.state.securetoggle === true ? 1 : 0,
        is_connected: this.state.is_connected!==null&&this.state.is_connected!==undefined?this.state.is_connected:0,
      };
      newAppcredentailapi(paramsdata, this);

      return;
    }
    // }else{

   
    this.setState({
      api_secreterror: null,
      package_nameerror: null,
      project_iderror: null,
      api_keyerror: null,
    });
    let weburl = localStorage.getItem("store_url").split("");

    let sendwrl = localStorage.getItem("store_url");

    if (weburl[weburl.length - 1] === "/") {
      sendwrl = localStorage.getItem("store_url");
    } else {
      sendwrl = localStorage.getItem("store_url") + "/";
    }
    const params = {
      websiteurl: sendwrl.replace(/(^\w+:|^)\/\//, ""),
      appid: localStorage.getItem("Appid"),
      //project_id:this.state.projectid,
      consumer_secret: this.state.apikey.trim(),
      //package_name:this.state.packagename,
      consumer_key: this.state.apisecret.trim(),
      is_verified: 0,
      connection_to_establish: this.state.securetoggle === true ? 1 : 0,
      wp_admin_username: this.state.wp_admin_username.trim(),
      wp_admin_application_password:
        this.state.wp_admin_application_password.trim(),
      authorization_type: authorization_type,
    };


    // for cs ck
    if (params.authorization_type === 1) {
      GetwebsiteData(params, this);
    }
    // for admin
    if (params.authorization_type === 2) {
      Getadminstatus(params, this);
    }
    if (params.authorization_type === 0) {
      Getadminstatus(params, this);
    }
    setTimeout(() => {
      this.props.refetchItems();
    },3000);

  // }
  };


  // Show requirement list on mobile view
  handleshowlist = () => {
    this.setState({
      showlist: !this.state.showlist,
    });
  };

  // switch right site image based on toggle
  handlewordpress_display = (toggle_belongs_to, e) => {
    let toggle_belongs = toggle_belongs_to;
    if (toggle_belongs === "wordpress") {
      this.setState({
        wordpress_display: e.target.checked,
        // woo_display: e.target.checked === true && false,
      });
    }
    if (toggle_belongs === "woocommerce") {
      this.setState({
        woo_display: e.target.checked,
        // wordpress_display: e.target.checked === true && false,
      });
    }

    // let authorization_type = 0;
    // if (toggle_belongs === "woocommerce" && e.target.checked === true && this.state.woo_display_setting === true) {
    //   authorization_type = 1;
    // }
    // if (toggle_belongs === "wordpress" && e.target.checked === true && this.state.wordpress_display_setting=== true) {
    //   authorization_type = 2;
    // }
    // if (
    //   (toggle_belongs === "woocommerce"  && e.target.checked === false &&   this.state.wordpress_display === false)
    //    ||
    //   (toggle_belongs === "wordpress" &&  e.target.checked === false && this.state.woo_display === false)
    // ) {
    //   authorization_type = 0;
    // }

    // 

    // const paramsdata = {
    //   app_id: parseInt(localStorage.getItem("Appid")),
    //   authorization_type: authorization_type,
    //   consumer_key: this.state.apisecret.trim(),
    //   consumer_secret: this.state.apikey.trim(),
    //   wp_admin_username: this.state.wp_admin_username.trim(),
    //   wp_admin_application_password:this.state.wp_admin_application_password.trim(),
    //   is_verified: this.state.is_verified!==null && this.state.is_verified!==undefined ?this.state.is_verified :0 ,
    //   connection_to_establish: this.state.securetoggle === true ? 1 : 0,
    //   is_connected: this.state.is_connected!==null&&this.state.is_connected!==undefined?this.state.is_connected:0,
    // };
    // newAppcredentailapi(paramsdata, this);
  };
  //show and hide password
  changepass = () => {
    this.setState(
      {
        passattribute: !this.state.passattribute,
      },
      () => {
        //callback
        this.setState({
          passattibutetype:
            this.state.passattribute === true ? "text" : "password",
          eyeimage:
            this.state.passattribute === true
              ? require("../../assets/images/signup/eyeopen.png").default
              : require("../../assets/images/signup/secure.png").default,
        });
      }
    );
  };
  handleopening = (e) => {
    let toggle_belongs = e;
    if (toggle_belongs === "wordpress") {
      this.setState({
        wordpress_display_setting: true,
        // wordpress_display:
        //   this.state.wordpress_display_setting === true
        //     ? this.state.wordpress_display
        //     : false,
        woo_display_setting: false,
        // woo_display: false,
      });
    }
    if (toggle_belongs === "woocommerce") {
      this.setState({
        woo_display_setting: true,
        // woo_display:
        //   this.state.woo_display_setting === true
        //     ? this.state.woo_display
        //     : false,
        wordpress_display_setting: false,
        // wordpress_display: false,
      });
    }
  };

  handleVibrateStrip = (e, isDisable) => {
    // if (isDisable) {
    //   let element1 = document.getElementById("check-for-vibrate");
    //   let element2 = document.getElementById("check-for-vibrate-for-mobil");

    //   if (element1) {
    //     element1.classList.add("vibrate-message");
    //     setTimeout(() => {
    //       element1.classList.remove("vibrate-message");
    //     }, 3000);
    //   }

    //   if (element2) {
    //     element2.classList.add("vibrate-message");
    //     setTimeout(() => {
    //       element2.classList.remove("vibrate-message");
    //     }, 3000);
    //   }
    // }
    if (isDisable) {
    this.setState({
      modalError:!this.state.modalError,
    })
    }
  };

  handleRightPanelExpand = (value) => {

    this.setState({
      appRightPanelExpand: value
    })
    }

  render() {
    const Errordata = {
      heading_first: "Connect website with app",
      heading_second:
        "This step is mandatory to sync your website with the mobile app.",
      // heading_third: "Install the AppMySite WP plugin.",
      heading_third: "Install the WP plugin.",
      button_text: "Connect App",
      button_link: "/app/connect/wp-plugin",
    };
    return (
      <>
    
        <div className="d-md-block">
          <Header customclass={"header-responsive-new"} />
        </div>
        <section className="dasboard_page mobile-display apikeys-page mobile-view-apikeys">
          {this.state.handlerender === true ? (
               <Leftpanel handleRightPanelExpand={this.handleRightPanelExpand} />
          ) : (
            <>
                  <Leftpanel handleRightPanelExpand={this.handleRightPanelExpand} />
            </>
          )}

          {/* <div className="transparent_background ipad-width-responsive"> */}
          <div className={`right-panel-gap ${(localStorage.getItem("appSideBarCollapse") !== undefined &&
                      localStorage.getItem("appSideBarCollapse") !== null&&
                      localStorage.getItem("appSideBarCollapse") === "true") ?
                          " right-panel-gap-expand": ""}`}>
            <div className="d-none d-md-flex w-100">
              <Verifyemailstatus />
            </div>
            {/* {this.state.is_connected === 0 && this.state.is_verified === 1 && (
              <div
                id="check-for-vibrate"
                className=" justify-content-between align-items-center pl-3 pr-3 flex-wrap  notificationdiv4"
              >
                <div className="notificationdiv1">
                  <h6 className="m-0 p-0">
                    <img
                      src={
                        require("../../assets/images/dashboard/exclamation.png")
                          .default
                      }
                      alt="alert"
                    />
                  
                    We were unable to connect to your website via authenticated
                    access.
                  </h6>
                </div>
              </div>
            )} */}
            {/* {this.state.is_verified === 0 && (
              <div
                id="check-for-vibrate"
                className=" justify-content-between align-items-center pl-3 pr-3 flex-wrap notificationdiv4"
              >
                <div className="notificationdiv1 w-100">
                  <div className="install-plugin-text-api-key">
                    <h6 className="m-0 p-0">
                      <img
                        src={
                          require("../../assets/images/dashboard/exclamation.png")
                            .default
                        }
                        alt="alert"
                      />
                      Our plugin could not be found on your website.{" "}
                    </h6>
                    <Link
                      className="mr-1 linkheader underline-hover"
                      to={"/app/connect/wp-plugin"}
                    >
                      Install Plugin
                    </Link>
                  </div>
                </div>
               
              </div>
            )} */}
            <div className='web-connectivity-head'>
              <h1>Connectivity
              <p className=" connectivity-tooltip">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
              <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                  <circle cx="9" cy="9" r="9" stroke="none"/>
                  <circle cx="9" cy="9" r="8.25" fill="none"/>
                </g>
                <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"/>
                <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
              </g>
            </svg>
              <p className=" connectivity-tooltipsubheader">
              Here, you can set up connectivity to your website. These connections enable the seamless transfer of data and content between your app and your data sources.
              </p>
            </p></h1>
            </div>

            <div className="connectivity-mob-head">
              <p>Grant access</p>
            </div>
            <div className="consent-modal-tab">
              <div style={{ height: "40px" }}>
                
              {parseInt(localStorage.getItem("website_technology")) !==
                      2 &&
                  <Link to={"/app/connect/wp-plugin"}  style={{"--custom_color4": localStorage.getItem('agencyid') == 1 ? 
                  "#3064f9" : localStorage.getItem('agency_primary_color'),
                  "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#3064f9"
                   : localStorage.getItem('agency_primary_color')}}
                    >Install plugin</Link>  }
                
                {/* { parseInt(localStorage.getItem("website_technology")) !== 2 &&
                  parseInt(localStorage.getItem("website_technology")) !== 3 ? */}
                  {parseInt(localStorage.getItem("website_technology")) !==
                      2 &&
                  <Link 
                  to={"/app/connect/wp-grant-access"}
                  className="active"
                  style={{"border-bottom": localStorage.getItem('agencyid') == 1 ?
                   "2px solid #3064f9" : "2px solid "+ localStorage.getItem('agency_primary_color'),
                   "color": localStorage.getItem('agencyid') == 1 ? "#3064f9" : 
                   localStorage.getItem('agency_primary_color'),
                   "--custom_color4": localStorage.getItem('agencyid') == 1 ? "#3064f9" : 
                   localStorage.getItem('agency_primary_color'),"--custom_color5":
                    localStorage.getItem('agencyid') == 1 ? "#3064f9" : 
                    localStorage.getItem('agency_primary_color')}} 
                 >Grant access</Link> }


                  <Link to={"/app/connect/troubleshoot-logs"}
                  style={{"--custom_color4": localStorage.getItem('agencyid') == 1 ? "#3064f9" :
                   localStorage.getItem('agency_primary_color'),
                   "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#3064f9" 
                   : localStorage.getItem('agency_primary_color')}}>Troubleshoot</Link>

                  <Link className="monitorLink" to={"/app/connect/monitor"}
                  style={{"--custom_color4": localStorage.getItem('agencyid') == 1 ? "#3064f9" 
                  : localStorage.getItem('agency_primary_color'),
                  "--custom_color5": 
                  localStorage.getItem('agencyid') == 1 ? "#3064f9" :
                   localStorage.getItem('agency_primary_color')}}>Monitor</Link>
             
              </div>
              <div className='date-and-savediv'>
                {/* {lastdate && lastdate !== '' && lastdate !== undefined ?
                      <p className='consent-save-date'>Updated  {moment(lastdate).format(
                                "D MMMM YYYY, HH:mm"
                              )}{" "}</p>
                    : null
                    }
                <button type="submit" style={{backgroundColor:localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor')}} className={upgradeconset != 0 ? "consent-login-save-enable" : "consent-login-save-disable"} onClick={upgradeconset != 0 ? (e) => postConsentData(e) : void(0)}>
                    <div className="d-flex align-items-center justify-content-center h-100 w-100">
                    {saveloader == true ?
                          <div className="new-loader"></div>
                    :
                          <div>Save</div>
                    }
                    </div>
                </button> */}
                {
                  this.state.updated_at && <p className="connectionupdatedatetime">Updated {moment(
                    this.state.updated_at
                  ).format("D MMMM YYYY, HH:mm")}</p>
                }
                
                {/* <button className="connectionsavebtn connectionsavedisable">
                  Save
                </button> */}

                        {/* Save Web btn */}
                        {this.state.spinner === false &&
                          (parseInt(
                            localStorage.getItem("website_technology")
                          ) === 5 ? (
                            (this.state.woo_display_setting === false &&
                              this.state.wordpress_display_setting === false) 
                            //   ||
                            // (this.state.woo_display === false &&
                            //   this.state.woo_display_setting === true) ||
                            // (this.state.wordpress_display === false &&
                            //   this.state.wordpress_display_setting === true) 
                              ? (
                              <div
                                className="disable-trobleshoot-save-btn"
                                onClick={(e) =>
                                  this.handleVibrateStrip(e, true)
                                }
                              >
                                <button
                                  disabled={true}
                                  id="savebtn"
                                  type="submit"
                                  className="connectionsavedisable"
                                  form="pluginsub"
                                  
                                >
                             
                                  <div className="d-flex align-items-center justify-content-center h-100 w-100 ">
                                    <div className={this.state.loader2}>
                                    <div className="new-blue-loader"></div>
                                    </div>{" "}
                                    <div className="varifytext">
                                      {this.state.submitval2}
                                    </div>
                                  </div>
                                </button>
                              </div>
                            ) : (
                              <div
                                className={` ${
                                  this.state.is_verified === 0
                                    ? "disable-trobleshoot-save-btn"
                                    : ""
                                }`}
                                onClick={(e) =>
                                  this.state.is_verified === 0
                                    ? this.handleVibrateStrip(
                                        e,
                                        this.state.is_verified === 0
                                      )
                                    : null
                                }
                              >
                              {this.state.is_verified === 0 ?
                                <button
                                  id="savebtn"
                                  disabled={this.state.is_verified === 0}
                                  type="submit"
                                  className={this.state.is_verified === 0?"connectionsavedisable":"connectionsavebtn"}
                                  form="pluginsub"
                                  
                                >
                            
                                  <div className="d-flex align-items-center justify-content-center h-100 w-100 ">
                                    <div className={this.state.loader2}>
                                    <div className="new-blue-loader"></div>
                                    </div>{" "}
                                    <div className="varifytext">
                                      {this.state.submitval2}
                                    </div>
                                  </div>
                                </button>
                              :
                                <button
                                  type="submit"
                                  id="savebtn"
                                  className="connectionsavebtn"
                                  form="pluginsub"
                                  style={{backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                                >
                                
                                  <div className="d-flex align-items-center justify-content-center h-100 w-100 ">
                                    <div className={this.state.loader2}>
                                    <div className="new-blue-loader"></div>
                                    </div>{" "}
                                    <div className="varifytext">
                                      {this.state.submitval2}
                                    </div>
                                  </div>
                                </button>
                              }
                              
                                  
                              </div>
                            )
                          ) : (
                            <div
                              className={` ${
                                this.state.is_verified === 0 
                                  ? "disable-trobleshoot-save-btn"
                                  : ""
                              }`}
                              onClick={(e) =>
                                this.state.is_verified === 0
                                  ? this.handleVibrateStrip(
                                      e,
                                      this.state.is_verified === 0
                                    )
                                  : null
                              }
                            >
                            {this.state.is_verified === 0 ?
                              <button
                                disabled={true}
                                id="savebtn"
                                type="submit"
                                // className={Styles.save_btn}
                                className={"connectionsavedisable"}
                                form="pluginsub"                                
                              >
                              
                                <div className="d-flex align-items-center justify-content-center h-100 w-100 ">
                                  <div className={this.state.loader2}>
                                  <div className="new-blue-loader"></div>
                                  </div>{" "}
                                  <div style={{
                                    color:"white"
                                  }}>{this.state.submitval2}</div>
                                </div>
                              </button>
                            :
                            <button
                            type="submit"
                            id="savebtn"
                            className={"connectionsavebtn"}
                            form="pluginsub"
                            style={{backgroundColor:localStorage.getItem('button_background_color') && localStorage.getItem('button_background_color') != 'undefined' && localStorage.getItem('button_background_color') != 'null' ? localStorage.getItem('button_background_color') : '',border:"1px solid "+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color')}}
                    
                            
                          >
                           
                            <div className="d-flex align-items-center justify-content-center h-100 w-100 ">
                              <div className={this.state.loader2}>
                              <div className="new-blue-loader"></div>
                              </div>{" "}
                              <div>{this.state.submitval2}</div>
                            </div>
                          </button>
                          }
                            </div>
                          ))}


                        {/* Verify Web btn */}
                        {this.state.spinner === false &&
                          (parseInt(
                            localStorage.getItem("website_technology")
                          ) === 5 ? (
                            (this.state.woo_display_setting === false &&
                              this.state.wordpress_display_setting === false) ||
                            (this.state.woo_display === false &&
                              this.state.woo_display_setting === true) ||
                            (this.state.wordpress_display === false &&
                              this.state.wordpress_display_setting === true) ? (
                              <div
                                className="disable-trobleshoot-save-btn"
                                onClick={(e) =>
                                  this.handleVibrateStrip(e, true)
                                }
                              >
                                <button
                                  disabled={true}
                                  type="submit"
                                  className="connectionsavedisable"
                                  form="pluginsub"
                                  style={{
                                    margin:"0px",
                                    backgroundColor:localStorage.getItem('button_background_color'),
                                    border:'1px solid '+localStorage.getItem('button_background_color'),
                                    color:localStorage.getItem('button_text_color'),
                                    "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                                    "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
                                    "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                                >
                            
                                  <div className="d-flex align-items-center justify-content-center h-100 w-100 ">
                                    <div className={this.state.loader}>
                                    <div className="new-loader"></div>
                                    </div>{" "}
                                    <div className="varifytext">
                                      {this.state.submitval}
                                    </div>
                                  </div>
                                </button>
                              </div>
                            ) : (
                              <div
                                className={` ${
                                  this.state.is_verified === 0
                                    ? "disable-trobleshoot-save-btn"
                                    : ""
                                }`}
                                onClick={(e) =>
                                  this.state.is_verified === 0
                                    ? this.handleVibrateStrip(
                                        e,
                                        this.state.is_verified === 0
                                      )
                                    : null
                                }
                              >
                              {this.state.is_verified === 0 ?
                                <button
                                  disabled={this.state.is_verified === 0}
                                  type="submit"
                                  className={this.state.is_verified === 0?"connectionsavedisable" :"connectivity-btn"}
                                  form="pluginsub"
                                  style={{  margin:"0px" ,
                                  backgroundColor:localStorage.getItem('button_background_color'),
                                  border:'1px solid '+localStorage.getItem('button_background_color'),
                                  color:localStorage.getItem('button_text_color'),
                                  "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                                  "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
                                  "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                                >
                               
                                  <div className="d-flex align-items-center justify-content-center h-100 w-100 ">
                                    <div className={this.state.loader}>
                                    <div className="new-loader"></div>
                                    </div>{" "}
                                    <div className="varifytext">
                                      {this.state.submitval}
                                    </div>
                                  </div>
                                </button>
                              :
                                <button
                                  type="submit"
                                  className="connectivity-btn"
                                  form="pluginsub"
                                  style={{backgroundColor:localStorage.getItem('button_background_color'),
                                  border:'1px solid '+localStorage.getItem('button_background_color'),
                                  color:localStorage.getItem('button_text_color'),
                                  "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                                  "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
                                  "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                                >
                           
                                  <div className="d-flex align-items-center justify-content-center h-100 w-100 ">
                                    <div className={this.state.loader}>
                                    <div className="new-loader"></div>
                                    </div>{" "}
                                    <div className="varifytext">
                                      {this.state.submitval}
                                    </div>
                                  </div>
                                </button>
                              }
                              
                                  
                              </div>
                            )
                          ) : (
                            <div
                              className={` ${
                                this.state.is_verified === 0 ||this.state.wordpress_display===false
                                  ? "disable-trobleshoot-save-btn"
                                  : ""
                              }`}
                              onClick={(e) =>
                                this.state.is_verified === 0
                                  ? this.handleVibrateStrip(
                                      e,
                                      this.state.is_verified === 0
                                    )
                                  : null
                              }
                            >
                            {this.state.is_verified === 0||this.state.wordpress_display===false ?
                              <button
                                disabled={true}
                                type="submit"
                                className={"connectionsavedisable"}
                                form="pluginsub" 
                                style={{
                                  margin:"0px"
                                }}                               
                              >
                               
                                <div className="d-flex align-items-center justify-content-center h-100 w-100 ">
                                  <div className={this.state.loader}>
                                  <div className="new-loader"></div>
                                  </div>{" "}
                                  <div>{this.state.submitval}</div>
                                </div>
                              </button>
                            :
                            <button
                            type="submit"
                            className={"connectivity-btn"}
                            form="pluginsub"
                            style={{margin:"0px" ,
                            backgroundColor:localStorage.getItem('button_background_color') && localStorage.getItem('button_background_color') != 'undefined' && localStorage.getItem('button_background_color') != 'null' ? localStorage.getItem('button_background_color') : '',
                            border:"1px solid "+localStorage.getItem('button_background_color'),
                            color:localStorage.getItem('button_text_color'),
                             "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                                  "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
                                  "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                            }}
                            
                            
                          >
                          
                            <div className="d-flex align-items-center justify-content-center h-100 w-100 ">
                              <div className={this.state.loader}>
                              <div className="new-loader"></div>
                              </div>{" "}
                              <div>{this.state.submitval}</div>
                            </div>
                          </button>
                          }
                            </div>
                          ))}
               </div>

              

            </div>
            {this.state.spinner ? (
                <div className="connectivityspinn connectivityspinner">
                  {/* <Spinner animation="border" variant="primary" /> */}
                  <div className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      style={{
                        margin: "auto",
                        background: "rgb(241, 242, 243,0)",
                        display: "block",
                        shaperendering: "auto",
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                      width="200px"
                      height="200px"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid"
                    >
                      <g
                        transform="translate(20 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#e6261f"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.375s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(40 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#f7d038"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.25s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(60 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#49da9a"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.125s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(80 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#4355db"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="0s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                    </svg>
                  </div>
                </div>
              ) : (
            <div className="new-div">
            <div
              className={
                Styles.right_pannel + " rightdivv " + Styles.ipad_head_padding
              }
              // style={{ minHeight: "auto" }}
              // style={{ minHeight: "80vh" }}
            >
            
                <>
                  {window.matchMedia("(max-width: 700px)").matches && (
                    <div className="d-flex d-md-none w-100">
                      <Verifyemailstatus />
                    </div>
                  )}
                  {/* {this.state.is_verified === 0 &&
                  (this.state.website_type === 1 ||
                    this.state.website_type === 2 ||
                    this.state.website_type === 3) ? (
                    <div className="pt-4 pb-4">
                      <Error errordata={Errordata} />
                    </div>
                  ) : ( */}
                  <>
                    {/* <div className="apispac headerofpage">
                        <div
                          className={
                            "d-flex  align-items-center justify-content-between api-head  " +
                            Styles.page_heading
                          }
                        > */}
                    {/* <div className="d-flex  align-items-center justify-content-between borderr headmargin ">
                      <div className="d-flex justify-content-between w-100 align-items-center panel3">
                       
                        <div className="app_iconname d-md-block d-none installheader">
                          <div className="d-flex  align-items-center">
                            <h3 className="downloadheader">
                              Grant Access and Verify Ownership
                            </h3>
                          
                          </div>
                          <h5 className=" ipad-plugin-subtext headerh5">
                            In order to connect your website to your app, you
                            must Install the plugin on your website.{" "}
                           
                          </h5>
                        </div>
                        {this.state.spinner === false &&
                          (parseInt(
                            localStorage.getItem("website_technology")
                          ) === 5 ? (
                            (this.state.woo_display_setting === false &&
                              this.state.wordpress_display_setting === false) ||
                            (this.state.woo_display === false &&
                              this.state.woo_display_setting === true) ||
                            (this.state.wordpress_display === false &&
                              this.state.wordpress_display_setting === true) ? (
                              <div
                                className="fixed-button  disable-trobleshoot-btn"
                                onClick={(e) =>
                                  this.handleVibrateStrip(e, true)
                                }
                              >
                                <button
                                  disabled={true}
                                  type="submit"
                                  className="next_btn takefull"
                                  form="pluginsub"
                        
                                >
                                  {
                                  <div className="d-flex align-items-center justify-content-center h-100 w-100 ">
                                    <div className={this.state.loader}>
                                      <img
                                        src={this.state.loaderimage}
                                        alt="loader"
                                      />
                                    </div>{" "}
                                    <div className="varifytext">
                                      {this.state.submitval}
                                    </div>
                                  </div>
                                </button>
                              </div>
                            ) : (
                              <div
                                className={`fixed-button buttonDiv ${
                                  this.state.is_verified === 0
                                    ? "disable-trobleshoot-btn"
                                    : ""
                                }`}
                                onClick={(e) =>
                                  this.state.is_verified === 0
                                    ? this.handleVibrateStrip(
                                        e,
                                        this.state.is_verified === 0
                                      )
                                    : null
                                }
                              >
                              {this.state.is_verified === 0 ?
                                <button
                                  disabled={this.state.is_verified === 0}
                                  type="submit"
                                  className="next_btn takefull"
                                  form="pluginsub"
                                  
                                >
                                  {
                                  <div className="d-flex align-items-center justify-content-center h-100 w-100 ">
                                    <div className={this.state.loader}>
                                      <img
                                        src={this.state.loaderimage}
                                        alt="loader"
                                      />
                                    </div>{" "}
                                    <div className="varifytext">
                                      {this.state.submitval}
                                    </div>
                                  </div>
                                </button>
                              :
                                <button
                                  type="submit"
                                  className="next_btn takefull"
                                  form="pluginsub"
                                  style={{backgroundColor:localStorage.getItem('button_background_color'),
                                  border:'1px solid '+localStorage.getItem('button_background_color'),
                                  color:localStorage.getItem('button_text_color'),
                                  "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                                  "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
                                  "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                                  }}
                                >
                                  {
                                  <div className="d-flex align-items-center justify-content-center h-100 w-100 ">
                                    <div className={this.state.loader}>
                                      <img
                                        src={this.state.loaderimage}
                                        alt="loader"
                                      />
                                    </div>{" "}
                                    <div className="varifytext">
                                      {this.state.submitval}
                                    </div>
                                  </div>
                                </button>
                              }
                              
                                  
                              </div>
                            )
                          ) : (
                            <div
                              className={`fixed-button buttonDiv ${
                                this.state.is_verified === 0 ||this.state.wordpress_display===false
                                  ? "disable-trobleshoot-btn"
                                  : ""
                              }`}
                              onClick={(e) =>
                                this.state.is_verified === 0
                                  ? this.handleVibrateStrip(
                                      e,
                                      this.state.is_verified === 0
                                    )
                                  : null
                              }
                            >
                            {this.state.is_verified === 0||this.state.wordpress_display===false ?
                              <button
                                disabled={true}
                                type="submit"
                                className={Styles.save_btn}
                                form="pluginsub"                                
                              >
                                {
                                <div className="d-flex align-items-center justify-content-center h-100 w-100 ">
                                  <div className={this.state.loader}>
                                    <img
                                      src={this.state.loaderimage}
                                      alt="loader"
                                    />
                                  </div>{" "}
                                  <div>{this.state.submitval}</div>
                                </div>
                              </button>
                            :
                            <button
                            type="submit"
                            className={Styles.save_btn}
                            form="pluginsub"
                            style={{backgroundColor:localStorage.getItem('button_background_color') && localStorage.getItem('button_background_color') != 'undefined' && localStorage.getItem('button_background_color') != 'null' ? localStorage.getItem('button_background_color') : '',border:"1px solid "+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color')}}
                    
                            
                          >
                            {
                            <div className="d-flex align-items-center justify-content-center h-100 w-100 ">
                              <div className={this.state.loader}>
                                <img
                                  src={this.state.loaderimage}
                                  alt="loader"
                                />
                              </div>{" "}
                              <div>{this.state.submitval}</div>
                            </div>
                          </button>
                          }
                            </div>
                          ))}
                      </div>
                    </div> */}
                    
                    <div
                      className="right_general"
                      style={{ opacity: this.state.spinner === true ? 0 : 1 }}
                    >

                      
                      <div className="mainCol">
                        <div></div>
                        <div className="connectmainapidiv">
                          <div className=" coll1">
                        
                            <form
                              onSubmit={this.pluginsubmit}
                              id="pluginsub"
                              className="mt-md-2 mt-0"
                              autoComplete="off"
                            >

                              <div className="grantaccess-toggle">
                                <div>
                                  <a  onClick={(e) =>
                                    this.handleopening("wordpress")
                                  }>
                                
                                     <img
                                        src={
                                          require(`../../assets/images/dashboard/connect/${this.state.wordpress_display_setting?"wordpresstoggle":"wordpressnottoggle"}.png`)
                                            .default
                                        }
                                        className={this.state.wordpress_display_setting?"active":""}
                                        alt="wordpress"
                                      />
                                  </a>
                                  {parseInt(
                                localStorage.getItem("website_technology")
                              ) === 5 && (

                                     <a onClick={(e) =>
                                      this.handleopening("woocommerce")} className="wootoggleswitch">
                                       
                                      <img
                                        src={
                                          require(`../../assets/images/dashboard/connect/${this.state.woo_display_setting?"wootoggle":"woonottoggle"}.png`)
                                            .default
                                        }
                                        className={this.state.woo_display_setting?"active":""}
                                        alt="wordpress"
                                      />
                                    
                              </a> ) }
                              
                                </div>
                             
                                  {
                                    parseInt(
                                      localStorage.getItem("website_technology")
                                    ) === 5 && this.state.wordpress_display_setting &&
                                      <p>WordPress recommended</p> }
                              </div>
                              <div className={Styles.toggle_parent }>
                                {/* <div
                                  className={
                                    Styles.toggle_heading +
                                    " d-flex  align-items-center justify-content-between formdivheight"
                                  }
                                  onClick={(e) =>
                                    this.handleopening("wordpress")
                                  }
                                >
                                  <div>
                                    <img
                                      src={
                                        require("../../assets/images/dashboard/connect/wordpress.png")
                                          .default
                                      }
                                      className="img-fluid wordpressimg"
                                      alt="wordpress"
                                    />
                                  </div>
                                  <div>
                                    <span className="recommend">
                                      Recommended option
                                    </span>
                                    <img
                                      src={
                                        require("../../assets/images/dashboard/connect/droparrow.png")
                                          .default
                                      }
                                      className="woodropimg"
                                      // className={`img-fluid ml-4 ${
                                      //   this.state
                                      //     .wordpress_display_setting === true
                                      //     ? Styles.arrowdirectionreverse
                                      //     : Styles.arrowdirection
                                      // }`}
                                      alt="arrow"
                                    />
                                  </div>
                                </div> */}
                                {this.state.wordpress_display_setting ===
                                  true && (
                                  <div className="formsbox">
                                    <div className="d-flex align-items-center apidescription justify-content-between grantaccess">
                                      <h3 className="mt-0 pt-0 d-md-block mb-0 grant">
                                      Connect using WordPress Application Password
                                      </h3>

                                      <label className="switch mobile-switch toplogos">
                                        <input
                                          disabled={
                                            this.state.is_verified === 0
                                          }
                                          type="checkbox"
                                          className="toplogo"
                                          checked={this.state.wordpress_display}
                                          onChange={(e) =>
                                            this.handlewordpress_display(
                                              "wordpress",
                                              e
                                            )
                                          }
                                          id="wordpress_display"
                                        />
                                        <div
                                          className="slider round"
                                          onClick={(e) =>
                                            this.state.is_verified === 0
                                              ? this.handleVibrateStrip(
                                                  e,
                                                  this.state.is_verified === 0
                                                )
                                              : null
                                          }
                                        ></div>
                                      </label>
                                      {/* <label
                                          className="switch ml-3"
                                          htmlFor="wordpress_display"
                                        >
                                          <input
                                           disabled={this.state.is_verified===0}
                                            type="checkbox"
                                            className="toplogo"
                                            checked={
                                              this.state.wordpress_display
                                            }
                                            onChange={(e) =>
                                              this.handlewordpress_display(
                                                "wordpress",
                                                e
                                              )
                                            }
                                            id="wordpress_display"
                                          />
                                          <div
                                            className={Styles.slider + " round"}
                                          ></div>
                                        </label> */}
                                    </div>
                                    <div className="  pluggedinornot1">
                              <div className="images">
                              {(() => {
                                   
                                    if (this.state.wordpress_display === true && this.state.wordpress_display_setting === true) {
                                      if (this.state.success === true) {
                                        if (
                                          this.state
                                            .check_adminpass_for_image === ""
                                        ) {
                                          return  (<div className="noticealert">
                                          <div>
                                            <img
                                              src={
                                                require("../../assets/images/dashboard/redCross.png")
                                                  .default
                                              }
                                              alt="alert"
                                              className="mr-1"
                                            />
                                          </div>
                                          <div>
                                          <p style={{
                                              color:"#FF4848"
                                            }}>
                                              Disconnected
                                            </p>
                                          </div>
                                        </div> )

                                        } else {
                                          return (
                                            <div className="noticealert">
                                            <div>
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/greentick.png")
                                                    .default
                                                }
                                                alt="alert"
                                                className="mr-1"
                                              />
                                            </div>
                                            <div>
                                              <p style={{color:"#52CB86"}}>
                                                Connected
                                              </p>
                                            </div>
                                          </div>

                                          )
                                        }
                                      } else if (this.state.success === false) {
                                        if (
                                          this.state
                                            .check_adminpass_for_image === ""
                                        ) {
                                          return (
<div className="noticealert">
                                            <div>
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/redCross.png")
                                                    .default
                                                }
                                                alt="alert"
                                                className="mr-1"
                                              />
                                            </div>
                                            <div>
                                            <p style={{
                                                color:"#FF4848"
                                              }}>
                                                Disconnected
                                              </p>
                                            </div>
                                          </div>

                                          )
                                        } else {
                                          return (
<div className="noticealert">
                                            <div>
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/redCross.png")
                                                    .default
                                                }
                                                alt="alert"
                                                className="mr-1"
                                              />
                                            </div>
                                            <div>
                                            <p style={{
                                                color:"#FF4848"
                                              }}>
                                                Disconnected
                                              </p>
                                            </div>
                                          </div>

                                          )
                                        }
                                      } else {
                                        return (
<div className="noticealert">
                                            <div>
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/redCross.png")
                                                    .default
                                                }
                                                alt="alert"
                                                className="mr-1"
                                              />
                                            </div>
                                            <div>
                                            <p style={{
                                                color:"#FF4848"
                                              }}>
                                                Disconnected
                                              </p>
                                            </div>
                                          </div>

                                        )
                                      }
                                    }
                                    if (
                                      this.state.wordpress_display === false ||
                                      this.state.woo_display === false
                                    ) {
                                      if (
                                        this.state.wordpress_display_setting === true
                                      ) {
                                        return (
                                          <div className="noticealert">
                                            <div>
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/redCross.png")
                                                    .default
                                                }
                                                alt="alert"
                                                className="mr-1"
                                              />
                                            </div>
                                            <div>
                                            <p style={{
                                                color:"#FF4848"
                                              }}>
                                                Disconnected
                                              </p>
                                            </div>
                                          </div>

                                        )
                                      } 
                                    }
                                  })()}
                              </div>
                            </div>

                                    <div
                                      className={`new-apikeys-section  formwoo pt-0 formsize ${Styles.stbackground}`}
                                    >
                                      <div className="userpasswordFont">
                                        <div className="d-flex copy align-items-center justify-content-between flex-wrap ">
                                          <div className="module_title d-flex align-items-end justify-content-between w-100">
                                            <h4 className="mb-0">
                                            Admin username
                                            <p  style={{marginLeft: "6px",
                                            
                                          
                                          }} className="workspace-tooltip">
                                            <svg 
                                            // onClick={(e) => this.handleWPpopUp(e)} style={{
                                            //   cursor:"pointer"
                                            // }} 
                                             xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
  <g id="Group_41154" data-name="Group 41154" transform="translate(-1727 -19.132)">
    <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
      <circle cx="7" cy="7" r="7" stroke="none"/>
      <circle cx="7" cy="7" r="6.4" fill="none"/>
    </g>
    <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
    <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
  </g>
</svg>
              <p className="workspace-tooltipsubheader">
              Enter the Admin username associated with your WordPress website here.
              </p>
            </p>
                                            </h4>
                                            {/* <div className="on_off d-flex  align-items-center">
                                              <a
                                                target="_blank"
                                                href={
                                                  this.state.storeurl +
                                                  "/wp-admin/profile.php"
                                                }
                                              >
                                                <span
                                                  onClick={(e) =>
                                                    this.storeurl("application")
                                                  }
                                                  className="d-md-inline  gotosite d-flex align-items-end "
                                                >
                                                  {" "}
                                                  Go to my site{" "}
                                                  <img
                                                    src={
                                                      require("../../assets/images/dashboard/Goto.png")
                                                        .default
                                                    }
                                                    className="gotoappicon1 linkspec gotospacekey"
                                                    alt="Goto"
                                                  />
                                                </span>
                                              </a>
                                            </div> */}
                                          </div>
                                          <div className="on_off"></div>
                                          <div className="color_picker_full">
                                            <div className="form-group">
                                              <input
                                                type="text"
                                                style={{
                                                  border:this.state.showRedInputWP? "1px solid #FF8E8E":null,
                                                }}
                                                required={
                                                  this.state.wordpress_display
                                                }
                                                className="form-control form-name word-application-input"
                                                autoComplete="off"
                                                disabled={
                                                  !this.state.wordpress_display
                                                }
                                                name="wp_admin_username"
                                                value={
                                                  this.state.wp_admin_username
                                                }
                                                onChange={(e) =>
                                                  this.handleformvalue(e)
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="d-flex copy align-items-center justify-content-between flex-wrap  secondinputheader">
                                          <div className="module_title">
                                            <h4 className="mb-0 ">
                                            Application password
                                              <p style={{marginLeft: "6px"}} className="workspace-tooltip">
                                            <svg 
                                            // onClick={(e) => this.handleWPpopUp(e)} style={{
                                            //   cursor:"pointer"
                                            // }} 
                                            xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
  <g id="Group_41154" data-name="Group 41154" transform="translate(-1727 -19.132)">
    <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
      <circle cx="7" cy="7" r="7" stroke="none"/>
      <circle cx="7" cy="7" r="6.4" fill="none"/>
    </g>
    <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
    <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
  </g>
</svg>
              <p className="workspace-tooltipsubheader">
              Enter the Application Password associated to the Admin username entered above. This password is used to securely establish a connection with your website.
              </p>
            </p>
                                            </h4>
                                          </div>
                                          <div className="on_off">
                                            <div className="tooltip"></div>
                                          </div>
                                          <div className="color_picker_full ">
                                            <div className="form-group">
                                              <input
                                                type={
                                                  this.state.passattibutetype
                                                }
                                                required={
                                                  this.state.wordpress_display
                                                }
                                                style={{
                                                  border:this.state.showRedInputWP? "1px solid #FF8E8E":null,
                                                }}
                                                
                                                autoComplete="new-password"
                                                className="form-control form-name word-application-input"
                                                title="Enter the 24 character Application  Password"
                                                pattern="[A-Za-z0-9!@#$_()-{};:''%^*|\/?]{24}"
                                                name="wp_admin_application_password"
                                                value={
                                                  this.state
                                                    .wp_admin_application_password
                                                }
                                                disabled={
                                                  !this.state.wordpress_display
                                                }
                                                onChange={(e) =>
                                                  this.handleformvalue(e)
                                                }
                                              />
                                              <div
                                                className=" eye_apikeys"
                                                onClick={this.changepass}
                                              >
                                                <img
                                                  src={this.state.eyeimage}
                                                  className="img-fluid"
                                                  alt="secure"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {detail(this, "application")}
                                  </div>
                                )}
                              </div>
                              {/* Woocommerce */}
                              {parseInt(
                                localStorage.getItem("website_technology")
                              ) === 5 && (
                                <div className={Styles.toggle_parent}>
                                  {/* <div
                                    className={
                                      Styles.toggle_heading +
                                      " d-flex  align-items-center justify-content-between formdivheight"
                                    }
                                    onClick={(e) =>
                                      this.handleopening("woocommerce")
                                    }
                                  >
                                    <div>
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/connect/woo.png")
                                            .default
                                        }
                                        className="img-fluid wordpressimg"
                                        alt="wordpress"
                                      />
                                    </div>
                                    <div>
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/connect/droparrow.png")
                                            .default
                                        }
                                        className="woodropimg"
                                        // className={`img-fluid ml-4 ${
                                        //   this.state.woo_display_setting ===
                                        //   true
                                        //     ? Styles.arrowdirectionreverse
                                        //     : Styles.arrowdirection
                                        // }`}
                                        alt="arrow"
                                      />
                                    </div>
                                  </div> */}

                                  {this.state.woo_display_setting === true && (
                                    <div className="formsbox">
                                      <div className="d-flex align-items-center apidescription justify-content-between grantaccess">
                                        <h3 className="mt-0 pt-0  d-md-block mb-0 grant">
                                        Connect using WooCommerce REST API
                                        </h3>

                                        <label className="switch mobile-switch toplogos">
                                          <input
                                            type="checkbox"
                                            disabled={
                                              this.state.is_verified === 0
                                            }
                                            className="toplogo "
                                            checked={this.state.woo_display}
                                            onChange={(e) =>
                                              this.handlewordpress_display(
                                                "woocommerce",
                                                e
                                              )
                                            }
                                            id="woo_display"
                                          />
                                          <div
                                            className="slider round"
                                            onClick={(e) =>
                                              this.state.is_verified === 0
                                                ? this.handleVibrateStrip(
                                                    e,
                                                    this.state.is_verified === 0
                                                  )
                                                : null
                                            }
                                          ></div>
                                        </label>
                                      </div>
                                     
                                      <div className="  pluggedinornot1">
                              <div className="images">
                              {(() => {
                                    if (this.state.woo_display === true && this.state.woo_display_setting === true) {
                                      if (this.state.success === true) {
                                        if (
                                          this.state
                                            .check_apisecret_for_image === ""
                                        ) {
                                          return (
<div className="noticealert">
                                            <div>
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/redCross.png")
                                                    .default
                                                }
                                                alt="alert"
                                                className="mr-1"
                                              />
                                            </div>
                                            <div>
                                            <p style={{
                                                color:"#FF4848"
                                              }}>
                                                Disconnected
                                              </p>
                                            </div>
                                          </div>

                                          )
                                        } else {
                                          return (
                                            <div className="noticealert">
                                            <div>
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/greentick.png")
                                                    .default
                                                }
                                                alt="alert"
                                                className="mr-1"
                                              />
                                            </div>
                                            <div>
                                              <p style={{color:"#52CB86"}}>
                                                Connected
                                              </p>
                                            </div>
                                          </div>

                                          )
                                        }
                                      } else if (this.state.success === false) {
                                        return (
<div className="noticealert">
                                            <div>
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/redCross.png")
                                                    .default
                                                }
                                                alt="alert"
                                                className="mr-1"
                                              />
                                            </div>
                                            <div>
                                            <p style={{
                                                color:"#FF4848"
                                              }}>
                                                Disconnected
                                              </p>
                                            </div>
                                          </div>

                                        )
                                      } else {
                                        return (
                                          <div className="noticealert">
                                            <div>
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/redCross.png")
                                                    .default
                                                }
                                                alt="alert"
                                                className="mr-1"
                                              />
                                            </div>
                                            <div>
                                            <p style={{
                                                color:"#FF4848"
                                              }}>
                                                Disconnected
                                              </p>
                                            </div>
                                          </div>

                                        )
                                      }
                                    }
                                    
                                    if (
                                      this.state.wordpress_display === false ||
                                      this.state.woo_display === false
                                    ) {
                                      if (
                                        this.state.woo_display_setting === true
                                      ) {
                                        return (
                                          <div className="noticealert">
                                            <div>
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/redCross.png")
                                                    .default
                                                }
                                                alt="alert"
                                                className="mr-1"
                                              />
                                            </div>
                                            <div>
                                            <p style={{
                                                color:"#FF4848"
                                              }}>
                                                Disconnected
                                              </p>
                                            </div>
                                          </div>

                                        )
                                      } 
                                    }
                                  })()}
                              </div>
                            </div>
                                      <div
                                        className={`new-apikeys-section  pt-0 formwoo formsize ${Styles.stbackground}`}
                                      >
                                        <div className="userpasswordFont">
                                          <div className="d-flex copy align-items-center justify-content-between flex-wrap ">
                                            <div className="module_title d-flex align-items-end justify-content-between w-100">
                                              <h4 className="mb-0">
                                                WooCommerce Consumer Key
                                                <p style={{marginLeft: "6px"}} className="workspace-tooltip">
                                            <svg onClick={(e) => this.handleWOOpopUp(e)} style={{
                                              cursor:"pointer"
                                            }} 
                                            
                                             xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
  <g id="Group_41154" data-name="Group 41154" transform="translate(-1727 -19.132)">
    <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
      <circle cx="7" cy="7" r="7" stroke="none"/>
      <circle cx="7" cy="7" r="6.4" fill="none"/>
    </g>
    <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
    <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
  </g>
</svg>
            </p>
                                              </h4>
                                              <div className="on_off d-flex  align-items-center">
                                                <a
                                                  target="_blank"
                                                  href={
                                                    this.state.storeurl +
                                                    "/wp-admin/admin.php?page=wc-settings&tab=advanced&section=keys&create-key=1"
                                                  }
                                                >
                                                  {/* <span
                                                    onClick={(e) =>
                                                      this.storeurl("cs")
                                                    }
                                                    className="d-md-inline gotosite"
                                                  >
                                                    {" "}
                                                    Go to my site{" "}
                                                    <img
                                                      src={
                                                        require("../../assets/images/dashboard/Goto.png")
                                                          .default
                                                      }
                                                      className="gotoappicon1 linkspec gotospacekey  align-items-end "
                                                      alt="Goto"
                                                    />
                                                  </span> */}
                                                </a>
                                              </div>
                                            </div>
                                            <div className="on_off"></div>
                                            <div className="color_picker_full ">
                                              <div className="form-group">
                                                <input
                                                  style={{
                                                    border:this.state.showRedInputWOO? "1px solid #FF8E8E":null,
                                                  }}
                                                  type="text"
                                                  required={
                                                    this.state.woo_display
                                                  }
                                                  className="form-control form-name word-application-input"
                                                  title="Enter the 43 character WooCommerce Consumer Key"
                                                  pattern="[A-Za-z0-9!@#$_()-]{43}"
                                                  name="apisecret"
                                                  autoComplete="off"
                                                  value={this.state.apisecret}
                                                  disabled={
                                                    !this.state.woo_display
                                                  }
                                                  onChange={(e) =>
                                                    this.handleformvalue(e)
                                                  }
                                                />
                                              </div>
                                              {this.state.api_secreterror ? (
                                                <div className="d-flex align-items-center mt-1 error">
                                                  <img
                                                    src={
                                                      require("../../assets/images/signup/error.png")
                                                        .default
                                                    }
                                                    className="img-fluid"
                                                    alt="error"
                                                  />
                                                  <p className="pb-0">
                                                    {this.state.api_secreterror}
                                                  </p>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </div>
                                          <div className="d-flex copy align-items-center justify-content-between flex-wrap  secondinputheader">
                                            <div className="module_title">
                                              <h4 className="mb-0 ">
                                                WooCommerce Consumer Secret
                                                <p style={{marginLeft: "6px"}} className="workspace-tooltip">
                                            <svg 
                                            onClick={(e) => this.handleWOOpopUp(e)} style={{
                                              cursor:"pointer"
                                            }} 
                                            xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
  <g id="Group_41154" data-name="Group 41154" transform="translate(-1727 -19.132)">
    <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
      <circle cx="7" cy="7" r="7" stroke="none"/>
      <circle cx="7" cy="7" r="6.4" fill="none"/>
    </g>
    <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
    <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
  </g>
</svg>
            </p>
                                              </h4>
                                            </div>
                                            <div className="on_off">
                                              <div className="tooltip"></div>
                                            </div>
                                            <div className="color_picker_full ">
                                              <div className="form-group">
                                                <input
                                                  type="text"
                                                  required={
                                                    this.state.woo_display
                                                  }
                                                  className="form-control form-name word-application-input"
                                                  title="Enter the 43 character WooCommerce Consumer Secret"
                                                  name="apikey"
                                                  autoComplete="off"
                                                  value={this.state.apikey}
                                                  style={{
                                                    border:this.state.showRedInputWOO? "1px solid #FF8E8E":null,
                                                  }}
                                                  disabled={
                                                    !this.state.woo_display
                                                  }
                                                  pattern="[A-Za-z0-9!@#$_()-]{43}"
                                                  onChange={(e) =>
                                                    this.handleformvalue(e)
                                                  }
                                                />
                                              </div>
                                              {this.state.api_keyerror ? (
                                                <div className="d-flex align-items-center mt-1 error">
                                                  <img
                                                    src={
                                                      require("../../assets/images/signup/error.png")
                                                        .default
                                                    }
                                                    className="img-fluid"
                                                    alt="error"
                                                  />
                                                  <p className="pb-0">
                                                    {this.state.api_keyerror}
                                                  </p>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="">
                                          <div className=" checkboxdiv">
                                            <div
                                              className=""
                                              style={{ display: "flex" }}
                                            >
                                              {/* <label
                                                    className="switch11"
                                                    htmlFor="secureconnection"
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      id="secureconnection"
                                                      defaultChecked={
                                                        this.state.securetoggle
                                                      }
                                                      onClick={
                                                        this.handlesecuretoggle
                                                      }
                                                    />
                                                
                                                  </label> */}

                                              <label className="checkboxmainbox">
                                                <input
                                                  type="checkbox"
                                                  id="secureconnection"
                                                  defaultChecked={
                                                    this.state.securetoggle
                                                  }
                                                  onClick={
                                                    this.handlesecuretoggle
                                                  }
                                                />
                                                <span className="checkmark"></span>
                                              </label>
                                            </div>

                                            <Tooltip
                                              firsttag={"h4"}
                                              tagclass={"mb-0 mr-2 ml-3"}
                                              classdata={
                                                "d-flex justify-content-start align-items-center checkboxtext"
                                              }
                                              heading={
                                                "Force authentication as query string "
                                              }
                                              content={
                                                'Enabling this toggle will transmit your Consumer Key and the Consumer Secret in the query string, appended in the URL. This is not completely secure and should only be turned on if HTTP Authorisation is disabled on your server.'
                                              }
                                            />
                                          </div>
                                        </div>
                                        
                                        {/* <h5
                                              className="d-md-block mb-0 mt-4"
                                              style={{ fontSize: "14px" }}
                                            >
                                              Ensure your website has a valid
                                              SSL certificate, and that your
                                              Permalink is set to Post Name.
                                            </h5> */}
                                      </div>
                                      {detail(this, "cs")}
                                    </div>
                                  )}
                                </div>
                              )}
                            </form>
                          </div>
                          <div className=" colImage" id="MacGuffin">
                            <div className="install-plugin mt-4 mt-md-0">
                              {/* Code to make change in image */}
                              <div className="plugin_img ">
                                <img
                                  src={(() => {
                                    if (this.state.woo_display === true && this.state.woo_display_setting === true) {
                                      if (this.state.success === true) {
                                        if (
                                          this.state
                                            .check_apisecret_for_image === ""
                                        ) {
                                          return require("../../assets/images/dashboard/connect/woonotconnect.png")
                                            .default;
                                        } else {
                                          return require("../../assets/images/dashboard/connect/wooconnect.png")
                                            .default;
                                        }
                                      } else if (this.state.success === false) {
                                        return require("../../assets/images/dashboard/connect/woonotconnect.png")
                                          .default;
                                      } else {
                                        return require("../../assets/images/dashboard/connect/woonotconnect.png")
                                          .default;
                                      }
                                    }
                                    if (this.state.wordpress_display === true && this.state.wordpress_display_setting === true) {
                                      if (this.state.success === true) {
                                        if (
                                          this.state
                                            .check_adminpass_for_image === ""
                                        ) {
                                          return require("../../assets/images/dashboard/connect/wordpressnotconnect.png")
                                            .default;
                                        } else {
                                          return require("../../assets/images/dashboard/connect/wordpressconnect.png")
                                            .default;
                                        }
                                      } else if (this.state.success === false) {
                                        if (
                                          this.state
                                            .check_adminpass_for_image === ""
                                        ) {
                                          return require("../../assets/images/dashboard/connect/wordpressnotconnect.png")
                                            .default;
                                        } else {
                                          return require("../../assets/images/dashboard/connect/wordpressnotconnect.png")
                                            .default;
                                        }
                                      } else {
                                        return require("../../assets/images/dashboard/connect/wordpressnotconnect.png")
                                          .default;
                                      }
                                    }
                                    if (
                                      this.state.wordpress_display === false ||
                                      this.state.woo_display === false
                                    ) {
                                      if (
                                        this.state.woo_display_setting === true
                                      ) {
                                        return require("../../assets/images/dashboard/connect/woonotconnect.png")
                                          .default;
                                      } else {
                                        return require("../../assets/images/dashboard/connect/wordpressnotconnect.png")
                                          .default;
                                      }
                                    }
                                  })()}
                                  className="img-fluid success-mobile-size"
                                  alt="Plugin"
                                />
                              </div>
                              {/* <div className="plugin_content mt-md-3 text-center pluginname">
                                {(() => {
                                  switch (this.state.success) {
                                    case false:
                                      if (
                                        this.state.wordpress_display ===
                                          false &&
                                        this.state.woo_display === false
                                      ) {
                                        return (
                                          <h4 style={{ color: "#C8CEDB" }}>
                                            {" "}
                                            Connection to website not
                                            established{" "}
                                          </h4>
                                        );
                                      } else if (
                                        this.state.wordpress_display === true &&
                                        this.state.check_adminpass_for_image ===
                                          ""
                                      ) {
                                        return (
                                          <h4 style={{ color: "#C8CEDB" }}>
                                            {" "}
                                            Connection to website not
                                            established{" "}
                                          </h4>
                                        );
                                      } else if (
                                        this.state.woo_display === true &&
                                        this.state.check_apisecret_for_image ===
                                          ""
                                      ) {
                                        return (
                                          <h4 style={{ color: "#C8CEDB" }}>
                                            {" "}
                                            Connection to website not
                                            established{" "}
                                          </h4>
                                        );
                                      } else {
                                        return (
                                          <h4>
                                            {" "}
                                            <span style={{ color: "#C8CEDB" }}>
                                              Connection to website failed{" "}
                                            </span>{" "}
                                          </h4>
                                        );
                                      }
                                    case null:
                                      return (
                                        <h4 style={{ color: "#C8CEDB" }}>
                                          {" "}
                                          Connection to website not established{" "}
                                        </h4>
                                      );
                                    case true:
                                      if (
                                        this.state.wordpress_display ===
                                          false &&
                                        this.state.woo_display === false
                                      ) {
                                        return (
                                          <h4 style={{ color: "#C8CEDB" }}>
                                            {" "}
                                            Connection to website not
                                            established{" "}
                                          </h4>
                                        );
                                      } else if (
                                        this.state.wordpress_display === true &&
                                        this.state.check_adminpass_for_image ===
                                          ""
                                      ) {
                                        return (
                                          <h4 style={{ color: "#C8CEDB" }}>
                                            {" "}
                                            Connection to website not
                                            established{" "}
                                          </h4>
                                        );
                                      } else if (
                                        this.state.woo_display === true &&
                                        this.state.check_apisecret_for_image ===
                                          ""
                                      ) {
                                        return (
                                          <h4 style={{ color: "#C8CEDB" }}>
                                            {" "}
                                            Connection to website not
                                            established{" "}
                                          </h4>
                                        );
                                      } else {
                                        return (
                                          <h4>
                                            {" "}
                                            <span style={{ color: "#C8CEDB" }}>
                                              Connected to website successfully.{" "}
                                            </span>{" "}
                                          </h4>
                                        );
                                      }
                                    default:
                                      return (
                                        <h4 style={{ color: "#C8CEDB" }}>
                                          {" "}
                                          Connection to website not established
                                        </h4>
                                      );
                                  }
                                })()}
                              </div> */}
                            </div>
                          </div>

                          <div id="there"></div>
                        </div>
                      </div>
                    </div>
                  </>
                  {/* )} */}
                </>
             
            </div>
                        <div className="mob-view-api-btns">

                        {/* Save Mob btn */}
                        {this.state.spinner === false &&
                          (parseInt(
                            localStorage.getItem("website_technology")
                          ) === 5 ? (
                            (this.state.woo_display_setting === false &&
                              this.state.wordpress_display_setting === false) 
                            //   ||
                            // (this.state.woo_display === false &&
                            //   this.state.woo_display_setting === true) ||
                            // (this.state.wordpress_display === false &&
                            //   this.state.wordpress_display_setting === true) 
                              ? (
                              <div
                                className="disable-trobleshoot-save-btn"
                                onClick={(e) =>
                                  this.handleVibrateStrip(e, true)
                                }
                              >
                                <button
                                  id="savebtn"
                                  disabled={true}
                                  type="submit"
                                  className="connectionsavedisable"
                                  form="pluginsub"
                        
                                >
                               
                                  <div className="d-flex align-items-center justify-content-center h-100 w-100 ">
                                    <div className={this.state.loader2}>
                                    <div className="new-blue-loader"></div>
                                    </div>{" "}
                                    <div className="varifytext">
                                      {this.state.submitval2}
                                    </div>
                                  </div>
                                </button>
                              </div>
                            ) : (
                              <div
                                className={` ${
                                  this.state.is_verified === 0
                                    ? "disable-trobleshoot-save-btn"
                                    : ""
                                }`}
                                onClick={(e) =>
                                  this.state.is_verified === 0
                                    ? this.handleVibrateStrip(
                                        e,
                                        this.state.is_verified === 0
                                      )
                                    : null
                                }
                              >
                              {this.state.is_verified === 0 ?
                                <button
                                  disabled={this.state.is_verified === 0}
                                  id="savebtn"
                                  type="submit"
                                  className={this.state.is_verified === 0?"connectionsavedisable":"connectionsavebtn"}
                                  form="pluginsub"
                                  
                                >
                             
                                  <div className="d-flex align-items-center justify-content-center h-100 w-100 ">
                                    <div className={this.state.loader2}>
                                    <div className="new-blue-loader"></div>
                                    </div>{" "}
                                    <div className="varifytext">
                                      {this.state.submitval2}
                                    </div>
                                  </div>
                                </button>
                              :
                                <button
                                  type="submit"
                                  id="savebtn"
                                  className="connectionsavebtn"
                                  form="pluginsub"
                                  style={{backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                                >
                               
                                  <div className="d-flex align-items-center justify-content-center h-100 w-100 ">
                                    <div className={this.state.loader2}>
                                    <div className="new-blue-loader"></div>
                                    </div>{" "}
                                    <div className="varifytext">
                                      {this.state.submitval2}
                                    </div>
                                  </div>
                                </button>
                              }
                              
                                  
                              </div>
                            )
                          ) : (
                            <div
                              className={` ${
                                this.state.is_verified === 0 
                                  ? "disable-trobleshoot-save-btn"
                                  : ""
                              }`}
                              onClick={(e) =>
                                this.state.is_verified === 0
                                  ? this.handleVibrateStrip(
                                      e,
                                      this.state.is_verified === 0
                                    )
                                  : null
                              }
                            >
                            {this.state.is_verified === 0 ?
                              <button
                                disabled={true}
                                id="savebtn"
                                type="submit"
                                className={"connectionsavedisable"}
                                form="pluginsub"                                
                              >
                            
                                <div className="d-flex align-items-center justify-content-center h-100 w-100 ">
                                  <div className={this.state.loader2}>
                                  <div className="new-blue-loader"></div>
                                  </div>{" "}
                                  <div>{this.state.submitval2}</div>
                                </div>
                              </button>
                            :
                            <button
                            type="submit"
                            id="savebtn"
                            className={"connectionsavebtn"}
                            form="pluginsub"
                            style={{backgroundColor:localStorage.getItem('button_background_color') && localStorage.getItem('button_background_color') != 'undefined' && localStorage.getItem('button_background_color') != 'null' ? localStorage.getItem('button_background_color') : '',border:"1px solid "+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color')}}
                    
                            
                          >
                         
                            <div className="d-flex align-items-center justify-content-center h-100 w-100 ">
                              <div className={this.state.loader2}>
                              <div className="new-blue-loader"></div>
                              </div>{" "}
                              <div>{this.state.submitval2}</div>
                            </div>
                          </button>
                          }
                            </div>
                          ))}

                          {/* Verify Mob btn */}
                          {this.state.spinner === false &&
                          (parseInt(
                            localStorage.getItem("website_technology")
                          ) === 5 ? (
                            (this.state.woo_display_setting === false &&
                              this.state.wordpress_display_setting === false) ||
                            (this.state.woo_display === false &&
                              this.state.woo_display_setting === true) ||
                            (this.state.wordpress_display === false &&
                              this.state.wordpress_display_setting === true) ? (
                              <div
                                className="disable-trobleshoot-save-btn"
                                onClick={(e) =>
                                  this.handleVibrateStrip(e, true)
                                }
                              >
                                <button
                                  disabled={true}
                                  type="submit"
                                  className="connectionsavedisable btn-conn-disable-margin"
                                  form="pluginsub"
                                  style={{backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                                >
                         
                                  <div className="d-flex align-items-center justify-content-center h-100 w-100 ">
                                    <div className={this.state.loader}>
                                    <div className="new-loader"></div>
                                    </div>{" "}
                                    <div className="varifytext">
                                      {this.state.submitval}
                                    </div>
                                  </div>
                                </button>
                              </div>
                            ) : (
                              <div
                                className={` ${
                                  this.state.is_verified === 0
                                    ? "disable-trobleshoot-save-btn"
                                    : ""
                                }`}
                                onClick={(e) =>
                                  this.state.is_verified === 0
                                    ? this.handleVibrateStrip(
                                        e,
                                        this.state.is_verified === 0
                                      )
                                    : null
                                }
                              >
                              {this.state.is_verified === 0 ?
                                <button
                                  disabled={this.state.is_verified === 0}
                                  type="submit"
                                  className={this.state.is_verified === 0?"connectionsavedisable btn-conn-disable-margin" :"connectivity-btn"}
                                  form="pluginsub"
                                  style={{backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                                >
                            
                                  <div className="d-flex align-items-center justify-content-center h-100 w-100 ">
                                    <div className={this.state.loader}>
                                    <div className="new-loader"></div>
                                    </div>{" "}
                                    <div className="varifytext">
                                      {this.state.submitval}
                                    </div>
                                  </div>
                                </button>
                              :
                                <button
                                  type="submit"
                                  className="connectivity-btn"
                                  form="pluginsub"
                                  style={{backgroundColor:localStorage.getItem('button_background_color'),
                                  border:'1px solid '+localStorage.getItem('button_background_color'),
                                  color:localStorage.getItem('button_text_color'),
                                  "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                                >
                         
                                  <div className="d-flex align-items-center justify-content-center h-100 w-100 ">
                                    <div className={this.state.loader}>
                                    <div className="new-loader"></div>
                                    </div>{" "}
                                    <div className="varifytext">
                                      {this.state.submitval}
                                    </div>
                                  </div>
                                </button>
                              }
                              
                                  
                              </div>
                            )
                          ) : (
                            <div
                              className={` ${
                                this.state.is_verified === 0 ||this.state.wordpress_display===false
                                  ? "disable-trobleshoot-save-btn"
                                  : ""
                              }`}
                              onClick={(e) =>
                                this.state.is_verified === 0
                                  ? this.handleVibrateStrip(
                                      e,
                                      this.state.is_verified === 0
                                    )
                                  : null
                              }
                            >
                            {this.state.is_verified === 0||this.state.wordpress_display===false ?
                              <button
                                disabled={true}
                                type="submit"
                                className={"connectionsavedisable btn-conn-disable-margin"}
                                form="pluginsub"                                
                              >
                             
                                <div className="d-flex align-items-center justify-content-center h-100 w-100 ">
                                  <div className={this.state.loader}>
                                  <div className="new-loader"></div>
                                  </div>{" "}
                                  <div>{this.state.submitval}</div>
                                </div>
                              </button>
                            :
                            <button
                            type="submit"
                            className={Styles.save_btn}
                            form="pluginsub"
                            style={{
                              
                              backgroundColor:localStorage.getItem('button_background_color') && localStorage.getItem('button_background_color') != 'undefined' && localStorage.getItem('button_background_color') != 'null' ? localStorage.getItem('button_background_color') : '',
                              border:"1px solid "+localStorage.getItem('button_background_color'),
                              color:localStorage.getItem('button_text_color'),
                              "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                              "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
                              "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                            }}
                    
                            
                          >
                          
                            <div className="d-flex align-items-center justify-content-center h-100 w-100 ">
                              <div className={this.state.loader}>
                              <div className="new-loader"></div>
                              </div>{" "}
                              <div>{this.state.submitval}</div>
                            </div>
                          </button>
                          }
                            </div>
                          ))}
                          {
                            this.state.updated_at &&
                          
            <p className="connectionupdatedatetime">Updated {moment(
                          this.state.updated_at
                        ).format("D MMMM YYYY, HH:mm")}</p> }
                  </div>
            
                

            <div className="stepsDiv">
                          <div className="step1">
                            <div className="iconImg">
                              <img
                                src={
                                  require("../../assets/images/dashboard/connectstep1.png")
                                    .default
                                }
                                alt="Goto"
                              />
                            </div>
                            <div>
                            <label>Step 1</label>
                            <p>Log in to your website’s backend</p>
                            </div>
                          </div>
                          <div className="step1">
                            <div className="iconImg">
                              <img
                                src={
                                  require("../../assets/images/dashboard/connectstep2.png")
                                    .default
                                }
                                alt="Goto"
                              />
                            </div>
                            <div>
                            <label>Step 2</label>
                            {/* <p>Install & activate the AppMySite plugin</p> */}
                            <p>Get application credentials</p>
                            </div>
                            
                          </div>
                          <div className="step1">
                            <div className="iconImg">
                              <img
                                src={
                                  require("../../assets/images/dashboard/connectstep3.png")
                                    .default
                                }
                                alt="Goto"
                              />
                            </div>
                            <div>
                            <label>Step 3</label>
                            <p>Verify your connectivity</p>
                            </div>
                            
                          </div>
                        </div>
                        </div> )}
            {/* {Foot()} */}
          </div>
        </section>
        <Modal
          isOpen={this.state.modalWooPlugin}
          toggle={this.togglemodalWooPlugin}
          style={{ maxWidth: "680px", maxHeight: "350px" }}
          className={this.props.className}
          centered
        >
          <ModalHeader
            toggle={this.togglemodalWooPlugin}
            className="modal-xxl-content modal-categories"
          >
            <div
              className=" connect-crossmodal"
              onClick={this.togglemodalWooPlugin}
            >
              <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                <path
                  id="Union_18"
                  data-name="Union 18"
                  d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                  transform="translate(0.529 0.531)"
                  fill="#1b2952"
                  stroke="#1b2952"
                  strokeWidth="1.5"
                />
              </svg>
            </div>
          </ModalHeader>
          <ModalBody className="update-woo-plugin">
            <div className="text-center mb-4">
              <h2 className="mb-3">WooCommerce update required</h2>
              <h3>You are using an old unsupported version of Woocommerce. Update to the latest version to proceed.</h3>
            </div>
            {/* <div className="text-center mb-4">
              <img
                src={
                  require("../../assets/images/dashboard/woocom-1.png").default
                }
                alt="Connecting"
                className="img-fluid"
              />
            </div>
            <div className="text-center mb-4 ml-5 mr-5 woo-below-content">
              <p>
                Ensure that the WooCommerce plugin on your website is updated to
                its latest version. Click below to update your store to the
                latest version and proceed.{" "}
              </p>
            </div> */}
            <div className="text-center mb-5 conecttoapi">
              <Link to="" onClick={this.togglemodalWooPlugin}>
              Okay
              </Link>
            </div>
          </ModalBody>
        </Modal>

        {/*Modal for not connect to your website wordpress*/}

        {/* <Modal
          isOpen={this.state.modalError}
          toggle={this.togglemodalError}
          // style={{ maxWidth: "480px", maxHeight: "326px" }}
           className="module1"
          centered
        >
          <ModalHeader
            toggle={this.togglemodalError}
            className="modal-xxl-content modal-categories"
          >
            <div className="crossmodal mt-1" onClick={this.togglemodalError}>
              // <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
              //   <path
              //     id="Union_18"
              //     data-name="Union 18"
              //     d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
              //     transform="translate(0.529 0.531)"
              //     fill="#1b2952"
              //     stroke="#1b2952"
              //     strokeWidth="1.5"
              //   />
              // </svg>
               <img
                      src={
                        require("../../assets/images/dashboard/cross.png")
                          .default
                      }
                      alt="alert"
                    
                    />
            </div>
          </ModalHeader>
          <ModalBody className="update-woo-plugin">
            <div className="text-center mb-4 wronguser">
              <h2 className="mb-3 ">
                We could not connect to your website
              </h2>
              <h3>
              Unknown username. Check again or try your email address.
              </h3>
            </div>
            <div className="text-center mb-3 conecttoapi" style={{marginBottom: "28px !important"}}>
              <Link to="" onClick={this.togglemodalError}>
                OKAY
              </Link>
            </div>
            <div className="text-center underpopup">
              <Troubleshooting
                cs={this.state.apikey.trim()}
                ck={this.state.apisecret.trim()}
                storeurl={this.state.websiteurl.trim()}
                emailModal={this.emailModal}
              />
            </div>
          </ModalBody>
        </Modal> */}

        {/*Modal for not connect to your website wooCommerce*/}
        <Modal
          isOpen={this.state.modalError}
          toggle={this.togglemodalError}
          style={{ maxWidth: "480px" }}
          className="module1"
          centered
        >
          <ModalHeader
            toggle={this.togglemodalError}
            className="modal-xxl-content modal-categories modal_toheader"
          >
            <div className="connect-crossmodal mt-1" onClick={this.togglemodalError}>
              <img
                src={require("../../assets/images/dashboard/cross.png").default}
                alt="alert"
              />
            </div>
          </ModalHeader>
         
          <ModalBody className="update-woo-plugin update-woo-plugin1">
            <div className="text-center  wronguser">
              <h2 className="mb-3 ">{this.state.wordpress_display_setting?"Unable to connect to your website":"Unable to connect to your website"}</h2>
              <h3>{
                this.state.wordpress_display_setting?"We were unable to establish a connection with your website. Your website might be unreachable or the provided credentials may be incorrect. Please verify and try again. ":
                "We were unable to establish a connection with your website. Your website might be unreachable or the provided credentials may be incorrect. Please verify and try again."
                } </h3>
            </div>
            <div className="connect-troubleshootdiv">
            <div className="text-center conecttoapi1" onClick={this.togglemodalError}>
              <Link to="#" >
              Cancel
              </Link>
            </div>
            <div className="text-center connect-underpopup">
              <Troubleshooting
                className="linktroubleshoot"
                cs={this.state.apikey.trim()}
                ck={this.state.apisecret.trim()}
                storeurl={this.state.websiteurl.trim()}
                emailModal={this.emailModal}
              />
            </div>
            </div>
          </ModalBody>
        </Modal>

        {/*Modal for how to get userName and password wordpress*/}

        <Modal
          isOpen={this.state.wpPopUp}
          toggle={this.handleWPpopUp}
          style={{ maxWidth: "760px", maxHeight: "590px" }}
          className="modalipad"
          centered
        >
          <ModalHeader
            toggle={this.handleWPpopUp}
            className="modal-xxl-content modal-categories crossicons"
          >
            <div className="connect-crossmodal" onClick={this.handleWPpopUp}>
              {/* <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                  <path
                    id="Union_18"
                    data-name="Union 18"
                    d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                    transform="translate(0.529 0.531)"
                    fill="#1b2952"
                    stroke="#1b2952"
                    strokeWidth="1.5"
                  />
                </svg> */}
              <img
                src={require("../../assets/images/dashboard/cross.png").default}
                alt="alert"
              />
            </div>
          </ModalHeader>
          <ModalBody className="update-woo-plugin stepss">
            <div className="  wronguser">
              <h2 className="mb-3">How to get your WordPress Application Password?</h2>
              {/* <p>Log in to your WordPress website's admin panel as an administrator.</p> */}

              <ul className="connect-stepsul">
              <li><span>Log in to your WordPress website's admin panel as an administrator.</span></li>
                <li><span>Navigate to Users &gt;&gt; Profile.</span></li>
                  <li><span>Locate and copy your username.</span>
                  
                  </li>
               
                <li><span>Paste your Username in the Admin username field.</span></li>
                  <li><span>On the same page, scroll down to Application Passwords.</span></li>
               
                <li><span>In the 'New Application Password Name' field, enter any name or alias of your app.</span></li>
                <li><span>Click the Add New Application Password button.</span></li>
                <li><span>Copy your new Password and paste it in the Application Password field.</span></li>
              </ul>
              {/* <div style={{ display: "grid" }}>
                <div className="stepsboxes">
                  <div className="stepApi">
                    <div className="iconImg1">
                      <img
                        src={
                          require("../../assets/images/dashboard/lockicon.png")
                            .default
                        }
                        alt="Goto"
                      />
                    </div>
                    <div className="step1label">
                      <label>Step 1</label>
                      <p>
                        Login to your WordPress website's admin panel, and
                        navigate to User >
                        <a
                          target="_blank"
                          href={`${this.state.websiteurl}/wp-admin/profile.php`}
                          style={{ color: "#3064F9" }}
                        >
                          {" "}
                          Profile.
                        </a>
                      </p>
                    </div>
                  </div>

                  <div className="stepApi">
                    <div className="iconImg1">
                      <img
                        src={
                          require("../../assets/images/dashboard/connect/step2img.png")
                            .default
                        }
                        alt="Goto"
                      />
                    </div>
                    <div className="step1label">
                      <label>Step 2</label>
                      <p>
                        Copy your Username and paste it below. You must be an
                        administrator.
                      </p>
                    </div>
                  </div>

                  <div className="stepApi searchicons">
                    <div className="iconImg1">
                      <img
                        src={
                          require("../../assets/images/dashboard/connect/step3img.png")
                            .default
                        }
                        alt="Goto"
                      />
                    </div>
                    <div className="step1label">
                      <label>Step 3</label>
                      <p>
                        Navigate to Application Passwords on the Profile page.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="stepbox1">
                  <div className="stepApi">
                    <div className="iconImg1">
                      <img
                        src={
                          require("../../assets/images/dashboard/connect/step4img.png")
                            .default
                        }
                        alt="Goto"
                      />
                    </div>
                    <div className="step1label">
                      <label>Step 4</label>
                      <p>
                        Input in the 'New Application Password Name' field.
                      </p>
                    </div>
                  </div>

                  <div className="stepApi">
                    <div className="iconImg1">
                      <img
                        src={
                          require("../../assets/images/dashboard/connect/step5img.png")
                            .default
                        }
                        alt="Goto"
                      />
                    </div>
                    <div className="step1label">
                      <label>Step 5</label>
                      <p>Click on the 'Add New Application Password' button.</p>
                    </div>
                  </div>

                  <div className="stepApi searchicons">
                    <div className="iconImg1">
                      <img
                        src={
                          require("../../assets/images/dashboard/connect/step6img.png")
                            .default
                        }
                        alt="Goto"
                      />
                    </div>
                    <div className="step1label">
                      <label>Step 6</label>
                      <p>
                        {" "}
                        Copy your Application Password and paste it in
                        application password.
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            <div className=" underpopup stepsdis">
            If you cannot find the Application Passwords section on the profile page, it's possible that one of your security plugins (e.g., Wordfence) has disabled it. Refer to the security plugin's documentation on how to enable Application Passwords. 
            </div>

            <div className="connectmodal-mob-btn">
            <button className="connect-modalokeybtn" onClick={this.handleWPpopUp}>Okay</button>
            <a
                                                target="_blank"
                                                href={
                                                  this.state.storeurl +
                                                  "/wp-admin/profile.php"
                                                }
                                              >

        
            <button className="connect-gotowebsite" onClick={(e) =>
                                                    this.storeurl("application")
                                                  }>Go to website
            <svg xmlns="http://www.w3.org/2000/svg" width="11.2" height="11.2" viewBox="0 0 11.2 11.2">
  <g id="Group_9160" data-name="Group 9160" transform="translate(-695.4 -246.4)">
    <path id="Path_10239" data-name="Path 10239" d="M4,1H1A1,1,0,0,0,0,2V9a1,1,0,0,0,1,1H8A1,1,0,0,0,9,9V6" transform="translate(696 247)" fill="none" stroke="#fff" stroke-width="1.2"/>
    <path id="Path_10240" data-name="Path 10240" d="M4,4V0H0" transform="translate(702 247)" fill="none" stroke="#fff" stroke-width="1.2"/>
    <path id="Path_10241" data-name="Path 10241" d="M2144-23303l-6,6" transform="translate(-1438 23550)" fill="none" stroke="#fff" stroke-width="1.2"/>
  </g>
</svg>
            </button>
            </a>
           </div>
          </ModalBody>
          <ModalFooter>
            
            <button className="connect-modalokeybtn" onClick={this.handleWPpopUp}>Okay</button>
            <a
                                                target="_blank"
                                                href={
                                                  this.state.storeurl +
                                                  "/wp-admin/profile.php"
                                                }
                                              >
            <button className="connect-gotowebsite" onClick={(e) =>
                                                    this.storeurl("application")
                                                  }>Go to website
            <svg xmlns="http://www.w3.org/2000/svg" width="11.2" height="11.2" viewBox="0 0 11.2 11.2">
  <g id="Group_9160" data-name="Group 9160" transform="translate(-695.4 -246.4)">
    <path id="Path_10239" data-name="Path 10239" d="M4,1H1A1,1,0,0,0,0,2V9a1,1,0,0,0,1,1H8A1,1,0,0,0,9,9V6" transform="translate(696 247)" fill="none" stroke="#fff" stroke-width="1.2"/>
    <path id="Path_10240" data-name="Path 10240" d="M4,4V0H0" transform="translate(702 247)" fill="none" stroke="#fff" stroke-width="1.2"/>
    <path id="Path_10241" data-name="Path 10241" d="M2144-23303l-6,6" transform="translate(-1438 23550)" fill="none" stroke="#fff" stroke-width="1.2"/>
  </g>
</svg>
            </button>
            </a>
          </ModalFooter>
        </Modal>

        {/*Modal for how to get userName and password woocommerce */}

        <Modal
          isOpen={this.state.wooPopUp}
          toggle={this.handleWOOpopUp}
          style={{ maxWidth: "760px", maxHeight: "590px" }}
          className="modalipad"
          centered
        >
          <ModalHeader
            toggle={this.handleWOOpopUp}
            className="modal-xxl-content modal-categories crossicons "
          >
            <div className="connect-crossmodal mt-1" onClick={this.handleWOOpopUp}>
              {/* <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                <path
                  id="Union_18"
                  data-name="Union 18"
                  d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                  transform="translate(0.529 0.531)"
                  fill="#1b2952"
                  stroke="#1b2952"
                  strokeWidth="1.5"
                />
              </svg> */}
              <img
                src={require("../../assets/images/dashboard/cross.png").default}
                alt="alert"
              />
            </div>
          </ModalHeader>
          <ModalBody className="update-woo-plugin stepsswoo ">
            <div className="  wronguser">
              <h2 className="mb-3">How to get your WooCommerce API key?</h2>
              {/* <p>To enable the WooCommerce REST API, log in to your WordPress website's admin panel as an administrator. Navigate to WooCommerce > Settings > Advanced > REST API > Add Key.</p>
              <p>On the "Create New Key" page, provide the following information:</p> */}
              <ul className="connect-stepsul">
                <li><span>Log in to your website's admin panel as an administrator.</span></li>
                <li><span>Navigate to WooCommerce &gt; Settings &gt; Advanced &gt; REST API &gt; Add Key.</span></li>
                <li><span>On the "Create New Key" page, provide the following information:</span></li>
                  <li className="child-li-text" style={{marginTop: "15px", marginLeft: "15px" }}> <span>Description: Enter app name.</span></li>
                  <li className="child-li-text" style={{marginTop: "15px", marginLeft: "15px" }}><span>User: Select an Admin user.</span></li>
                    <li className="child-li-text" style={{marginTop: "15px", marginLeft: "15px" }}><span>Permission: Choose "Read/Write."</span> </li>
                <li><span>Click the "Generate API Key" button.</span> </li>
              </ul>
              
            </div>

            <div className="connectmodal-mob-btn">
            <button className="connect-modalokeybtn" onClick={this.handleWOOpopUp}>Okay</button>
            <a
                                                  target="_blank"
                                                  href={
                                                    this.state.storeurl +
                                                    "/wp-admin/admin.php?page=wc-settings&tab=advanced&section=keys&create-key=1"
                                                  }
                                                >
            <button className="connect-gotowebsite" onClick={(e) =>
                                                    this.storeurl("cs")
                                                  }>Go to website
            <svg xmlns="http://www.w3.org/2000/svg" width="11.2" height="11.2" viewBox="0 0 11.2 11.2">
  <g id="Group_9160" data-name="Group 9160" transform="translate(-695.4 -246.4)">
    <path id="Path_10239" data-name="Path 10239" d="M4,1H1A1,1,0,0,0,0,2V9a1,1,0,0,0,1,1H8A1,1,0,0,0,9,9V6" transform="translate(696 247)" fill="none" stroke="#fff" stroke-width="1.2"/>
    <path id="Path_10240" data-name="Path 10240" d="M4,4V0H0" transform="translate(702 247)" fill="none" stroke="#fff" stroke-width="1.2"/>
    <path id="Path_10241" data-name="Path 10241" d="M2144-23303l-6,6" transform="translate(-1438 23550)" fill="none" stroke="#fff" stroke-width="1.2"/>
  </g>
</svg>
            </button>
            </a>
           </div>
          </ModalBody>
          <ModalFooter>
            <button className="connect-modalokeybtn" onClick={this.handleWOOpopUp}>Okay</button>
            <a
                                                  target="_blank"
                                                  href={
                                                    this.state.storeurl +
                                                    "/wp-admin/admin.php?page=wc-settings&tab=advanced&section=keys&create-key=1"
                                                  }
                                                >
            <button className="connect-gotowebsite" onClick={(e) =>
                                                    this.storeurl("cs")
                                                  }>Go to website
            <svg xmlns="http://www.w3.org/2000/svg" width="11.2" height="11.2" viewBox="0 0 11.2 11.2">
  <g id="Group_9160" data-name="Group 9160" transform="translate(-695.4 -246.4)">
    <path id="Path_10239" data-name="Path 10239" d="M4,1H1A1,1,0,0,0,0,2V9a1,1,0,0,0,1,1H8A1,1,0,0,0,9,9V6" transform="translate(696 247)" fill="none" stroke="#fff" stroke-width="1.2"/>
    <path id="Path_10240" data-name="Path 10240" d="M4,4V0H0" transform="translate(702 247)" fill="none" stroke="#fff" stroke-width="1.2"/>
    <path id="Path_10241" data-name="Path 10241" d="M2144-23303l-6,6" transform="translate(-1438 23550)" fill="none" stroke="#fff" stroke-width="1.2"/>
  </g>
</svg>
            </button>
            </a>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  refetchItems: () => dispatch(appDashboardApiSlice.endpoints.appDashboardData.initiate()).refetch(),
});

export default connect(mapStateToProps, mapDispatchToProps)(Apikeys)