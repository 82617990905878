import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  typeData: [],
  taxonomyData: [],
  userWebsiteMenuData: [],
  appName:""
};

const userWebsiteDataSlice = createSlice({
  name: "userWebsiteData",
  initialState,
  reducers: {
    setTypeData(state, action) {
      state.typeData = action.payload;
    },
    setTaxonomyData(state, action) {
      state.taxonomyData = action.payload;
    },

    setUserWebsiteMenuData(state, action) {
      state.userWebsiteMenuData = action.payload;
    },
    setUserAppName(state, action) {
      state.appName = action.payload;
    },
  },
});

export const { setTypeData, setTaxonomyData, setUserWebsiteMenuData,setUserAppName } =
  userWebsiteDataSlice.actions;

export default userWebsiteDataSlice.reducer;
