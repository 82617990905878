import React from "react";
import "../assets/css/Dashboard.css";
import "../assets/css/profileleftpanel.css";
import Header from "./Header";
import ProfileLeftPanel from "./ProfileLeftPanel";
import ReactSelect from "react-select";
import emailValidator from "email-validator";
import {
  GetuserprofileApi,
  ProfileApi,
  ProfileImagebusketApi,
  Countrylist,
  DeleteUser,
  ConnectGoogleProfile,
  ConnectFacebookProfile,
  RemoveSocialProfile,
  CallProfileCountrycode,
} from "../Api/Dashboard/DashboardApi";
import {
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import Tooltip from "../Dashboard/Tooltip";
// import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Foot } from "../Dashboard/Foot";
import { errortoast } from "../Dashboard/Toaster";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import "../assets/css/Newprofile.css";
import userdefaultImgOnError from "../assets/images/dashboard/accountprofile.png";
import wpdefaultImgOnError from "../assets/images/dashboard/workspace/workspace-default-img.png";
import NewImageGalleryPopup from "./NewImageGalleryPopup";
import moment from "moment";
import { Verifyemailaccount } from "../Api/Authenticate/Api";
import MobileProfileMenu from "./MobileProfileMenu";
import {getAgencyData}  from "../Api/Agency/AgencyApi";

	const customStyles = {
  control: (base, state) => ({
    ...base,
    // match with the menu
    borderRadius: 3,
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#7782a1" : "#C8CEDB",
    backgroundColor: state.isDisabled ? "#ffffff" : "",
    height: 50,
    minHeight: 50,
    fontSize: 14,
    color: "#7782A1",
    paddingLeft: 10,
    boxShadow: state.isFocused ? "none" : "none",
    "&:hover": {
      boxShadow: state.isFocused ? "none" : "none",
    },
  }),
  valueContainer: (base) => ({
    ...base,
    height: 50,
    minHeight: 50,
    fontSize: 14,
    color: "#7782A1",
  }),
  menu: (base) => ({
    ...base,
    fontSize: "14px",
  }),
  input: (base) => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    // color: "#1B2952", // Custom colour
    // marginRight: 20,
  }),
};


export default class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      modal: false,
      phonecodemodal: false,
      name: "",
      buttontext: "CROP",
      email: "",
      oldemail: "",
      phone_code: "",
      country_name_prefix: "",
      phone: "",
      gender: "",
      spinner: true,
      profile_image: "",
      date: "",
      profile: localStorage.getItem("user_profile"),
      submitval: "Save",
      loader: "d-none",
      deleteLoader: "d-none",
      deleteLoaderimage:
        require("../assets/images/dashboard/workspace/error-popup-loader.gif")
          .default,
      billingloader: "d-none",
      loaderimage: require("../assets/images/signup/Loader_GIF.gif").default,
      verifyEmailLoader: false,
      phonecodesearch: "",
      check: "",
      image: "",
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 2 / 2,
      croppedImage: null,
      croppedAreaPixels: null,
      rotation: 0,
      role_id: 2,
      countryselectfield: [],
      apps_count: undefined,
      delete_app: false,
      status: 1,
      customer_type: null,
      user_type: "",
      childState: "",
      profilepath:
        process.env.REACT_APP_Image_Path +
        // (localStorage.getItem("roleid") === "1"
        //   ? localStorage.getItem("originalid")
        //   : localStorage.getItem("user_id")) +
        localStorage.getItem("logged_in_user_id") +
        process.env.REACT_APP_Profile,
      linked_google: "",
      linked_facebook: "",
      linked_apple: "",
      captchavalue: "",
      user_roles: "",
      user_industry: "",
      company_size: "",
      google_spinner: false,
      fb_spinner: false,
      txtgoogle: "Google account",
      txtfacebook: "Facebook account",
      isgooglelogin: false,
      errcookies: "",
      right_panel_expand: true,
      openCountryList: false,
      dropdownSearchVal: "",
      updated_at: "",
      delete_email_err: false,
      open_profile_menu: false,
      optionsForRole: [
        { value: "Business Owner", label: "Business Owner", name: "userroles" },
        { value: "Customer Success", label: "Customer Success", name: "userroles" },
        { value: "Engineering", label: "Engineering", name: "userroles" },
        { value: "Leadership", label: "Leadership", name: "userroles" },
        { value: "Marketing", label: "Marketing", name: "userroles" },
        { value: "Operations", label: "Operations", name: "userroles" },
        { value: "Sales", label: "Sales", name: "userroles" },
        { value: "Support", label: "Support", name: "userroles" },
        { value: "Other", label: "Other", name: "userroles" },
      ],
      optionsForCompanySize: [
        { value: "1-19 employees", label: "1-19 employees", name: "company_size" },
        { value: "20-49 employees", label: "20-49 employees", name: "company_size" },
        { value: "50-249 employees", label: "50-249 employees", name: "company_size" },
        { value: "250-999 employees", label: "250-999 employees", name: "company_size" },
        { value: "1,000+ employees", label: "1,000+ employees", name: "company_size" },

      ],
      optionsForIndustory: [
        { value: "Arts", label: "Arts", name: "userindustry" },
        { value: "Automotive", label: "Automotive", name: "userindustry" },

        { value: "Constructions & Engineering", label: "Constructions & Engineering", name: "userindustry" },
        { value: "Consulting", label: "Consulting", name: "userindustry" },
        { value: "Consumer Goods", label: "Consumer Goods", name: "userindustry" },
        { value: "Education", label: "Education", name: "userindustry" },
        { value: "Energy", label: "Energy", name: "userindustry" },
        { value: "Entertainment", label: "Entertainment", name: "userindustry" },
        { value: "Finance", label: "Finance", name: "userindustry" },
        { value: "Fintech", label: "Fintech", name: "userindustry" },
        { value: "Food & Beverages", label: "Food & Beverages", name: "userindustry" },
        { value: "Government", label: "Government", name: "userindustry" },
        { value: "Healthcare", label: "Healthcare", name: "userindustry" },
        { value: "Human Resources", label: "Human Resources", name: "userindustry" },
        { value: "IT", label: "IT", name: "userindustry" },
        { value: "Insurance", label: "Insurance", name: "userindustry" },
        { value: "Logistics", label: "Logistics", name: "userindustry" },
        { value: "Manufacturing", label: "Manufacturing", name: "userindustry" },
        { value: "Marketing", label: "Marketing", name: "userindustry" },
        { value: "Media", label: "Media", name: "userindustry" },
        { value: "NGO", label: "NGO", name: "userindustry" },
        { value: "Professional Services", label: "Professional Services", name: "userindustry" },
        { value: "Real Estate", label: "Real Estate", name: "userindustry" },
        { value: "Restaurants", label: "Restaurants", name: "userindustry" },
        { value: "Retail", label: "Retail", name: "userindustry" },
        { value: "Software", label: "Software", name: "userindustry" },
        { value: "Sports", label: "Sports", name: "userindustry" },
        { value: "Telecom", label: "Telecom", name: "userindustry" },
        { value: "Travel & Leisure", label: "Travel & Leisure", name: "userindustry" },
      ],
      name_err: false,
      email_err: false,
      role_err: false,
      company_err: false,
      industory_err: false,
      is_fb_login_enabled:0,
      google_app_id:"",
      facebookid:"",
      is_google_login_enabled:0,
      captcha_key:"",
      captcha_secret:"",
      is_google_captcha_enabled:0,
      is_fb_login_enabled:0,
      openMobRoleDropdown: false,
      openMobCompanySizeDropdown: false,
      openMobOperateDropdown: false,
      
    };

  }
  async componentDidMount() 
  {
    window.scrollTo(0, 0);

    // document.title = "Profile | AppMySite";

    if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
      {
        document.title = "Profile | AppMySite";
      }
      else
      {
        document.title = "Profile |"+ ' ' +localStorage.getItem('agencyname');        
      }

    document.addEventListener("mousedown", this.handleClickOutside);
    GetuserprofileApi(this);
    Countrylist(this);
    CallProfileCountrycode(this);
    localStorage.setItem("workspaceId", "");
    localStorage.setItem("workspaceName", "");
    localStorage.setItem("workspaceImage", "");
    const params = {
      "domain_name": window.location.host
  }

      getAgencyData(params, this);
  }


  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside(event) {
    try {

      if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {


        this.setState({
          openCountryList: false
        })

      }
    } catch (err) {
      // this.setState({
      //   openCountryList:!this.state.openCountryList
      // })
    }
  }


  handlephonecodesearch = (e) => {
    this.setState({
      phonecodesearch: e.target.value,
    });
  };
  photoggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };
  showCroppedImage = async () => {
    this.setState({
      buttontext: (
        <img
          src={require("../assets/images/signup/Loader_GIF.gif").default}
          alt="loading.."
          style={{ marginTop: "-5px" }}
        />
      ),
    });
    try {
      const croppedImage = await getCroppedImg(
        this.state.image,
        this.state.croppedAreaPixels
      );
      const params = {
        data: croppedImage,
        path:
          this.state.role_id === 1
            ? localStorage.getItem("originalid") + process.env.REACT_APP_Profile
            : localStorage.getItem("user_id") + process.env.REACT_APP_Profile,
      };
      ProfileImagebusketApi(params, this);
    } catch (e) {
      console.error(e);
    }
  };
  onCropChange = (crop) => {
    this.setState({ crop });
  };

  recaptchaonChange = (value) => {
    this.setState({
      captchavalue: value,
    });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({
      croppedAreaPixels: croppedAreaPixels,
      croppedArea: croppedArea,
    });
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };
  profiledata = (e) => {
    e.preventDefault();

    let isErrforCheck = false;


    // if (this.state.captchavalue === "") {
    //   errortoast("Captcha is required");
    //   return;
    // }
    let email_err_temp = this.state.email_err;
    let today = this.state.date;

    if (this.state.date === "" || this.state.date === null) {
      today = null;
    } else {
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();

      today = yyyy + "-" + mm + "-" + dd;
    }
    let phonenumber = this.state.phone;
    if (!this.state.phone) {
      phonenumber = null;
    }
    const params = {
      name: this.state.name.trim(),
      email: this.state.email.trim(),
      gender: this.state.gender,
      phonecode: this.state.phone_code,
      phone: phonenumber,
      //   dob:today,
      dob: "",
      profile: this.state.profile,
      check: this.state.check,
      owner: this.state.user_type,
      user_job_role: this.state.user_roles,
      user_industry: this.state.user_industry,
      user_company_size: this.state.company_size,
      phone_code_country_iso: this.state.country_name_prefix,
      agency_id:localStorage.getItem('agency_id') ? parseInt(localStorage.getItem('agency_id')) : 1
    };

    if (!params.name) {
      this.setState({
        name_err: true
      })
      isErrforCheck = true;

    }
    if (!params.email) {

      email_err_temp = "Required"
      this.setState({
        email_err: "Required"
      })
      isErrforCheck = true;
    }
    if (!emailValidator.validate(params.email) && email_err_temp !== "Required") {

      email_err_temp = true
      this.setState({
        email_err: true
      })
      isErrforCheck = true;
    }

    if (!params.user_job_role) {

      this.setState({
        role_err: true
      })
      isErrforCheck = true;
    } if (!params.user_company_size) {

      this.setState({
        company_err: true
      })
      isErrforCheck = true;
    } if (!params.user_industry) {

      this.setState({
        industory_err: true
      })
      isErrforCheck = true;
    }
    if (isErrforCheck === false) {
      ProfileApi(params, this);
    }


  };
  togglephonecodemodal = () => {
    this.setState({
      phonecodemodal: !this.state.phonecodemodal,
    });
  };
  handlename = (e) => 
  {
    var setvalue = e.target.value.replace(/[`@#$%^&*+~!()_|?=;:'",.<>\{\}\[\]\\\/]/gi, "");
    this.setState({
      name: setvalue,

    });
    if (setvalue.trim()) 
    {
      this.setState({

        name_err: false
      });
    }
  };
  handleemail = (e) => 
  {
    this.setState({
      email: e.target.value,
      email_err: false
    });
  };
  handlephone = (e) => {
    this.setState({
      phone: e.target.value.replace(/[^0-9]/g, ""),
    });
  };

  handleOptionChange = (changeEvent) => {
    this.setState({
      gender: changeEvent.target.value,
    });
  };

  handleprofileimage = (e, state) => {

    this.setState({
      modal: true,
      childState: state
    });


    // var self = this;
    // var reader = new FileReader();
    // var file = e.target.files[0];

    // if (
    //   file.name.indexOf("png") !== -1 ||
    //   file.name.indexOf("jpeg") !== -1 ||
    //   file.name.indexOf("jpg") !== -1
    // ) {
    //   reader.onload = function (upload) {
    //     self.setState({
    //       errorimage: "",
    //       modal: true,
    //       profile: upload.target.result,
    //       profile_image: upload.target.result,
    //       image: upload.target.result,
    //     });
    //   };

    //   reader.readAsDataURL(file);
    // } else {
    //   errortoast(
    //     "File format not supported. Upload PNG, JPEG or JPG file only."
    //   );
    // }
  };
  onChange = (date) => {
    this.setState({ date });
  };
  removeimage = (e, state) => {
    this.setState({
      profile: "",
      profile_image: "",
      check: 2,
    });
    if (state !== undefined) {
      state.setState({
        profile: "",
      });
    }
  };

  handleseachradio = (e) => {
    this.setState({
      phone_code: e.target.value,
      phonecodemodal: false,
    });
  };
  delete_accountmodal = (e) => {
    e.preventDefault();
    this.setState({
      userdeletemodal: !this.state.userdeletemodal,
      delete_email_err: false
    });
  };
  //Delete User
  Deleteuser = (e) => {
    var emailconfirm = document.getElementById("emailconfirm").value;

    if (
      emailconfirm &&
      (emailconfirm.toLowerCase()) === this.state.email.trim()
    ) {
      this.setState({
        deleteLoader: "d-block",
      });
      e.preventDefault();
      const params = {};
      DeleteUser(params, this);
    } else if (
      emailconfirm &&
      (emailconfirm.toLowerCase()) !== this.state.email.trim()
    ) {
      errortoast("Your email address must match your profile email address");
    } else {
      this.setState({
        delete_email_err: true
      })
    }
  };
  deleteapps = (e) => {
    e.preventDefault();
    this.setState({
      delete_app: !this.state.delete_app,
    });
  };
  handleUserTypeChange = (e) => {
    this.setState({
      user_type: e.target.value,
    });
  };

  //Google Login
  responseGoogleProfile = (response) => {

    if (response !== undefined && response.error != undefined) {
      if (response.error == "popup_closed_by_user") {
        this.setState({
          errcookies: "Authorization is blocked. This is usually due to your browser’s privacy settings or an extension. Please check that you’re not blocking third-party cookies and try again."
        });
      }
      else {
        if (
          response != undefined &&
          response.details != undefined &&
          response.details != ""
        ) {
          this.setState({ errcookies: "Authorization is blocked. This is usually due to your browser’s privacy settings or an extension. Please check that you’re not blocking third-party cookies and try again." });
        }
        else {
          this.setState({ errcookies: "Authorization is blocked. This is usually due to your browser’s privacy settings or an extension. Please check that you’re not blocking third-party cookies and try again." });
        }
      }
    }

    if (
      response.profileObj !== undefined &&
      this.state.isgooglelogin === true
    ) {

      //return false;

      if (response.profileObj.email === this.state.email) {
        this.setState({ google_spinner: true });

        const params = {
          googleId: response.profileObj.googleId,
          access_token: response.tokenObj.access_token,
        };

        ConnectGoogleProfile(params, this);
      } else {
        errortoast(
          "Your social email address must match your profile email address"
        );
        return;
      }
    }
  };
  //Facebook Login

  responseFacebookProfile = (response) => {

    if (response.picture !== undefined) {


      if (response.email === this.state.email) {
        this.setState({ fb_spinner: true });
        const params = {
          email: response.email,
          fbid: response.id,
          access_token: response.accessToken,
        };

        ConnectFacebookProfile(params, this);
      } else {
        errortoast(
          "Your social email address must match your profile email address"
        );
        return;
      }
    }
  };

  removeGoogleLogins = (e) => {
    e.preventDefault();

    this.setState({ google_spinner: true });

    const params = {
      platform: "google",
    };
    RemoveSocialProfile(params, this);
  };

  removeFacebookLogins = (e) => {
    e.preventDefault();

    this.setState({ fb_spinner: true });

    const params = {
      platform: "facebook",
    };
    RemoveSocialProfile(params, this);
  };

  handleUserRoleChange = (e) => {

    this.setState({
      user_roles: e.value,
      role_err: false,
      openMobRoleDropdown: false,
    });
  };

  handleUserIndustryChange = (e) => {
    this.setState({
      user_industry: e.value,
      industory_err: false,
      openMobOperateDropdown: false,
    });
  };

  handleCompanySizeChange = (e) => {
    this.setState({
      company_size: e.value,
      company_err: false,
      openMobCompanySizeDropdown: false,
    });
  };

  hanleGoogleClick = (e) => {
    this.setState({ isgooglelogin: true });

  };

  // NEw Image Upload Pop up integration

  settingYourdata = (imgName, from) => {

    if (from !== "original") {
      if (this.state.childState) {
        this.state.childState.setState({
          profile: imgName,
        })
      }
      this.setState({
        profile: imgName,

      })
    }
  }

  handleRightPanelExpand = (rightpanelvalue) => {

    this.setState({
      right_panel_expand: rightpanelvalue
    })
  }

  yourErrFun = () => {

  }
  handleCountryDropdown = (e, res) => {

    this.setState({
      phone_code: res.phone_code,
      country_name_prefix: res.country_code,
      openCountryList: window.matchMedia("(max-width: 1200px)").matches ? this.state.openCountryList : !this.state.openCountryList,
      phonecodemodal: window.matchMedia("(max-width: 1200px)").matches ? !this.state.phonecodemodal : this.state.phonecodemodal
    })

  }

  handleOpenCoutryList = () => {


    this.setState({
      openCountryList: !this.state.openCountryList
    })

  }
  handleDropdownSearch = (e) => {
    this.setState({
      dropdownSearchVal: e.target.value
    })
  }

  // togglephonecodemodal = () =>{
  //   this.setState({
  //     phonecodemodal:!this.state.phonecodemodal
  //   })
  // }
  handlesend = () => {
    const params = {
      token: localStorage.getItem("amstoken"),
      check: "check",
    };
    this.setState({
      verifyEmailLoader:true
    })
    Verifyemailaccount(params, this);
  };
  openProfileMenus = (e) => {
    this.setState({
      open_profile_menu: !this.state.open_profile_menu
    })
  }


  loadpicVistaCreatePopUp = (data) =>{
    
       this.setState({
        modal:false,
        profile:data.url,
        // designid:data.id,
        
     
       })
     
      }
    
    
     closeVistaCreatePopUp = () =>{
      
      }
        handleOpenMobRoleDropdown = () => {
    this.setState({
      openMobRoleDropdown: !this.state.openMobRoleDropdown,
    });
  };
  handleOpenMobCompanySizeDropdown = () => {
    this.setState({
      openMobCompanySizeDropdown: !this.state.openMobCompanySizeDropdown,
    });
  };
  handleOpenMobOperateDropdown = () => {
    this.setState({
      openMobOperateDropdown: !this.state.openMobOperateDropdown,
    });
  };

  render() {
      // const customStyles = {
    //   control: (base, state) => ({
    //     ...base,
    //     // match with the menu
    //     borderRadius: 3,
    //     backgroundColor:state.isDisabled?"#ffffff":"",
    //     // color: "#7782A1",
    //     // boxShadow: state.isFocused ? "none" : "none",
    //     // "&:hover": {
    //     //   boxShadow: state.isFocused ? "none" : "none",
    //     // },
    //   }),
    //   valueContainer: (base) => ({
    //     ...base,
    //     height: 50,
    //     minHeight: 50,
    //     fontSize: 14,
    //     color: "#7782A1",
    //   }),
    //   menu: (base) => ({
    //     ...base,
    //     fontSize: "14px",
    //   }),
    //   input: (base) => ({
    //     ...base,
    //     margin: 0,
    //     padding: 0,
    //   }),
    //   indicatorSeparator: (base) => ({
    //     ...base,
    //     display: "none",
    //   }),
    //   dropdownIndicator: (base) => ({
    //     ...base,
    //     color: "#1B2952", // Custom colour
    //     marginRight: 20,
    //   }),
    // };


    return (
      <>
        {/* <MobileProfileMenu openProfileMenus={this.openProfileMenus} /> */}

        <Header openProfileMenus={this.openProfileMenus} />

        <section className="portal-body">
          <ProfileLeftPanel rightpanelbody={this.handleRightPanelExpand} profile={this.state.profile} />


          <div className={(localStorage.getItem('sidebaserotate') !== null && localStorage.getItem('sidebaserotate') !== undefined &&
            localStorage.getItem('sidebaserotate') === 'true') ? "right-panel-gap right-panel-gap-expand"
            : "right-panel-gap "}>


            <div
              className="right_panel_profile pl-0 pr-0 pb-0"
              style={{ minHeight: "auto" }}
            >



              <div className="right_general ">

                <div className="new-my-profile-page mt-md-0 ">
                  <div className="div-my-profile-page ">
                  {/*
                    this.state.status === 0 &&
                    <div className="notification-strip">
                      <div className="d-flex profile-notificationdiv">
                        <img
                          src={
                            require("../assets/images/dashboard/exclamation.png").default
                          }
                          alt="app-icon"
                          className=""
                        />
                        <p>Your account has not been verified. Please click on the link in the verification email sent to your email.</p>
                      </div>
                      <p></p>
                    </div>
                  */}
                  <div className="new-my-profile-head d-flex align-items-center justify-content-between">

                    <div className="d-flex align-items-center">
                      <h1 className="">My profile</h1>
                      <p className="myprofile-tooltip">

                        <img
                          src={
                            require("../assets/images/dashboard/Billing/billingexclamation.png").default
                          }
                          alt="app-icon"
                          className=""
                        />
                        <p className="myprofile-tooltipsubheader">
                          Add your personal details to update your account.
                          {/* <p className="myprofile-tooltip-learnmore">
        Lear More
        <img
          src={
            require("../assets/images/dashboard/Goto.png").default
          }
          alt="app-icon"
          className=""
        />
      </p> */}
                        </p>

                      </p>

                    </div>
                    <div className=" profile-lastupdate-button">
                      {
                        this.state.updated_at && <p className="profile-latest-update">Updated {moment(
                          this.state.updated_at
                        ).format("D MMMM YYYY, HH:mm")}</p>}
                      <button
                        type="submit"
                        className="newprofile-save-btn d-none d-xl-block"
                        form="form1"
                        style={{backgroundColor:localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),border:'1px solid '+localStorage.getItem('button_background_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor')}}
                      // onClick={this.profiledata}
                      >
                        <div className="d-flex align-items-center justify-content-center h-100 w-100">
                          <div className={this.state.loader}>
                            {/*<img src={this.state.loaderimage} alt="loader" />*/}
                            <div className="new-loader"></div>
                          </div>{" "}
                          <div>{this.state.submitval}</div>
                        </div>
                      </button>
                    </div>
                  </div>
                  </div>
                  <div className=" profile-my-profile-shadow" style={{ overflow: "visible" }}>

                    {
                      this.state.spinner ?
                        <div className=" profile-spinner" >
                          <svg xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                            style={{ margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s" }}
                            width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                            <g transform="translate(20 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                              <circle cx="0" cy="0" r="6" fill="#e6261f" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                  style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                </animateTransform>
                              </circle>
                            </g>
                            <g transform="translate(40 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                              <circle cx="0" cy="0" r="6" fill="#f7d038" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                  style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                </animateTransform>
                              </circle>
                            </g>
                            <g transform="translate(60 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                              <circle cx="0" cy="0" r="6" fill="#49da9a" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                  style={{ animationplaystate: "running", animationdelay: "0s" }}>

                                </animateTransform>
                              </circle>
                            </g>
                            <g transform="translate(80 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                              <circle cx="0" cy="0" r="6" fill="#4355db" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                  style={{ animationplaystate: "running", animationdelay: "0s" }}>

                                </animateTransform>
                              </circle>
                            </g>
                          </svg>
                        </div>
                        :
                        <div className="div-my-profile-page ">


                          <div className="new-my-profile-shadow">


                            {/* Personal Information */}
                            <div className="new-my-profile-personal">
                              <div
                                className=" profile-img-mobile "
                                style={{ backgroundColor: "#ffffff" }}
                              >
                                <form
                                  id="form1"
                                  onSubmit={this.profiledata}
                                  encType="multipart/form-data"
                                >
                                  <p className="myprofileimage-tooltip">



                                    <div
                                      className="d-flex align-items-center  profile-pic-container-new "
                                      style={{ width: "100%" }}
                                    >
                                      <label
                                        className="label-profile-pic-new "
                                        htmlFor="profilepic"
                                        onClick={(e) => this.handleprofileimage(e)}
                                      >
                                        {
                                          (this.state.profile === "" ||
                                            this.state.profile === null ||
                                            this.state.profile === "null" ||
                                            this.state.profile === undefined ||
                                            this.state.profile === "undefined" ||
                                            !this.state.profile) &&
                                          <div className="middle">
                                            <div className="camimage">

                                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20">
                                                <g id="_8666672_camera_icon" data-name="8666672_camera_icon" transform="translate(0 -2)">
                                                  <path id="Path_98235" data-name="Path 98235" d="M23,19a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2V8A2,2,0,0,1,3,6H7L9,3h6l2,3h4a2,2,0,0,1,2,2Z" fill="none" stroke="#bcc1ce" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                  <circle id="Ellipse_7718" data-name="Ellipse 7718" cx="4" cy="4" r="4" transform="translate(8 9)" fill="none" stroke="#bcc1ce" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                                </g>
                                              </svg>

                                            </div>
                                          </div>
                                        }
                                        {this.state.profile === "" ||
                                          this.state.profile === null ||
                                          this.state.profile === "null" ||
                                          this.state.profile === undefined ||
                                          this.state.profile === "undefined" ||
                                          !this.state.profile ? (
                                          <img
                                            style={{
                                              width: "80px",
                                              borderRadius: "50%",
                                              height: "80px",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "cover",
                                            }}
                                            src={
                                              require("../assets/images/dashboard/my-account.png")
                                                .default
                                            }
                                            className="proimg profile-pic-img"
                                            alt="profile logo"
                                          />
                                        ) : (
                                          <>
                                            {this.state.profile.indexOf("https") ===
                                              -1 ? (
                                              <img
                                                src={
                                                  this.state.profilepath +
                                                  this.state.profile
                                                }
                                                alt=""
                                                style={{
                                                  // background:
                                                  //   "url(" +
                                                  //   this.state.profilepath +
                                                  //   this.state.profile +
                                                  //   ")",
                                                  width: "80px",
                                                  borderRadius: "50%",
                                                  height: "80px",
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "cover",
                                                }}
                                                onError={({ currentTarget }) => {
                                                  currentTarget.onerror = null;
                                                  currentTarget.src =
                                                    localStorage.getItem(
                                                      "workspaceId"
                                                    ) !== "" &&
                                                      localStorage.getItem(
                                                        "workspaceId"
                                                      ) !== "undefined" &&
                                                      localStorage.getItem(
                                                        "workspaceId"
                                                      ) !== undefined &&
                                                      localStorage.getItem(
                                                        "workspaceId"
                                                      ) !== "null" &&
                                                      localStorage.getItem(
                                                        "workspaceId"
                                                      ) !== null
                                                      ? wpdefaultImgOnError
                                                      : userdefaultImgOnError;
                                                }}
                                                className="proimg profile-pic-img"
                                              />
                                            ) : (
                                              <img
                                                src={this.state.profile}
                                                alt=""
                                                style={{
                                                  // background:
                                                  //   "url(" + this.state.profile + ")",
                                                  width: "82px",
                                                  borderRadius: "50%",
                                                  height: "82px",
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "cover",
                                                }}
                                                onError={({ currentTarget }) => {
                                                  currentTarget.onerror = null;
                                                  currentTarget.src =
                                                    localStorage.getItem(
                                                      "workspaceId"
                                                    ) !== "" &&
                                                      localStorage.getItem(
                                                        "workspaceId"
                                                      ) !== "undefined" &&
                                                      localStorage.getItem(
                                                        "workspaceId"
                                                      ) !== undefined &&
                                                      localStorage.getItem(
                                                        "workspaceId"
                                                      ) !== "null" &&
                                                      localStorage.getItem(
                                                        "workspaceId"
                                                      ) !== null
                                                      ? wpdefaultImgOnError
                                                      : userdefaultImgOnError;
                                                }}
                                                className="proimg profile-pic-img"
                                              />
                                            )}
                                          </>
                                        )}


                                        {/* <input
                                  // type="file"
                                  onClick={(e) => this.handleprofileimage(e)}
                                  id="profilepic"
                                  className="d-none"

                                /> */}
                                      </label>
                                      <div className="mob-imageupload-remove">
                                        <p className="newimg-upload" onClick={(e) => this.handleprofileimage(e)}>Upload</p>
                                        {
                                          this.state.profile !== null && this.state.profile !== "null" &&
                                          this.state.profile !== undefined && this.state.profile !== "undefined" && this.state.profile !== ""
                                          && <p className="old-imgremove" onClick={(e) => this.removeimage(e)}>Remove</p>}
                                      </div>
                                      <div className="pl-3 abcd">
                                        {/* <p className="" style={{ textAlign: "left" }}>
                                {localStorage.getItem("workspaceId") !== "" &&
                                  localStorage.getItem("workspaceId") !==
                                  "undefined" &&
                                  localStorage.getItem("workspaceId") !==
                                  undefined &&
                                  localStorage.getItem("workspaceId") !== null
                                  ? localStorage.getItem("workspaceName")
                                  : localStorage.getItem("user_name")}
                              </p> */}
                                        {/* {this.state.profile_image === "" ||
                                  this.state.profile === "" ||
                                  this.state.profile === null ||
                                  this.state.profile === "null" ||
                                  this.state.profile === undefined ? (
                                  <></>
                                ) : (
                                  <div
                                    className="mt-2"
                                    onClick={(e) => this.removeimage(e)}
                                  >
                                    <p className="" style={{ textAlign: "left" }}>
                                      Remove
                                    </p>
                                  </div>
                                )} */}
                                      </div>
                                    </div>
                                    <div className="myprofile-tooltipupload ">
                                      <p className="profilepic-upload" onClick={(e) => this.handleprofileimage(e)}>Upload </p>
                                      {
                                        this.state.profile !== null && this.state.profile !== "null" &&
                                        this.state.profile !== undefined && this.state.profile !== "undefined" && this.state.profile !== ""
                                        &&
                                        <p className="profilepic-remove" onClick={(e) => this.removeimage(e)}> Remove</p>}
                                    </div>


                                  </p>
                                </form>
                              </div>
                              <form className="d-flex  justify-content-between flex-wrap">
                                <div className="color_picker inputtypeone">
                                  <h4>Full name*</h4>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      // required
                                      className={`form-control form-nameone ${this.state.name_err ? "profile-validate-border" : ""}`}
                                      form="form1"
                                      value={this.state.name}
                                      onChange={(e) => this.handlename(e)}
                                      maxLength="70"
                                    />

                                  </div>
                                  {
                                    this.state.name_err &&
                                    <div className="d-flex profile-validation">
                                      <svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                                        <path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848" />
                                        <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1" />
                                        <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1" />
                                      </svg>


                                      <p className="profile-redinput">Required</p>
                                    </div>
                                  }
                                </div>


                                <div className="color_picker inputtypetwo">
                                  <h4>Email*</h4>
                                  <div
                                    className="form-group"
                                    style={{ position: "relative" }}
                                  >
                                    <input
                                      type="text"
                                      style={{ paddingRight: "90px" }}
                                      // required
                                      className="form-control form-nametwo"
                                      form="form1"
                                      value={this.state.email}
                                      onChange={(e) => this.handleemail(e)}
                                    />
                                    <Link
                                      to={this.state.status === 1 ? "#" : "#"}
                                      style={{

                                        cursor:
                                          this.state.status === 1
                                            ? "inherit"
                                            : "cursor",
                                        alignItems: "center",

                                      }}
                                      className="d-flex align-items-center profile-email-input-status"
                                    >
                                      {/*this.state.status !== 1 && (
                                <svg
                                  width="17.534"
                                  height="17.534"
                                  viewBox="0 0 17.534 17.534"
                                  className="mr-2"
                                >
                                  <g
                                    id="Group_2049"
                                    data-name="Group 2049"
                                    transform="translate(-853.179 -186.985)"
                                  >
                                    <circle
                                      id="Ellipse_1"
                                      data-name="Ellipse 1"
                                      cx="8.017"
                                      cy="8.017"
                                      r="8.017"
                                      transform="translate(853.929 187.735)"
                                      fill="none"
                                      stroke="#c8cedb"
                                      strokeMiterlimit="10"
                                      strokeWidth="1.5"
                                    />
                                    <line
                                      id="Line_2"
                                      data-name="Line 2"
                                      x1="0.017"
                                      y1="5.201"
                                      transform="translate(861.928 192.102)"
                                      fill="none"
                                      stroke="#c8cedb"
                                      strokeMiterlimit="10"
                                      strokeWidth="1.5"
                                    />
                                    <circle
                                      id="Ellipse_2"
                                      data-name="Ellipse 2"
                                      cx="0.572"
                                      cy="0.572"
                                      r="0.572"
                                      transform="translate(861.373 199.09)"
                                      fill="#ff4848"
                                      stroke="#c8cedb"
                                      strokeWidth="1"
                                    />
                                  </g>
                                </svg>
                              )*/}

                                      {this.state.status === 1 ? (
                                        <img
                                          src={
                                            require("../assets/images/dashboard/app-verified.png")
                                              .default
                                          }
                                          className=""
                                          alt="Verified"
                                        />
                                      ) : (
                                        this.state.verifyEmailLoader?
                                        <div className="new-red-loader"></div>
                                        :
                                        <p onClick={(e) => this.handlesend(e)}>Verify email</p>

                                      )}
                                    </Link>


                                  </div>
                                  {
                                    this.state.email_err && <div className="d-flex profile-validation">
                                      <svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                                        <path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848" />
                                        <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1" />
                                        <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1" />
                                      </svg>

                                      <p className="profile-redinput">{this.state.email_err === "Required" ? "Required" : "Invalid email"}</p>
                                    </div>
                                  }
                                  {this.state.customer_type === 2 &&
                                    this.state.oldemail.trim() !==
                                    this.state.email.trim() && (
                                      <p style={{ color: "#1B2952", fontSize: "12px", marginBottom: "0px", marginTop: "10px" }}>
                                        If you are an AppSumo user, please ensure your
                                        email address on your AppSumo account matches
                                        your AppMySite email.
                                      </p>
                                    )}
                                </div>

                                {/* <div className="d-flex align-items-center justify-content-between flex-wrap "> */}
                                <div className="color_picker inputtypeone">
                                  <h4>Phone</h4>
                                  <div>
                                    <div className="form-group select-input d-flex align-items-center">
                                      <div

                                        className="phonecodeselects"
                                        ref={this.wrapperRef}
                                      >
                                        {
                                          this.state.openCountryList &&
                                          // <Modal    isOpen={this.state.openCountryList} toggle={(e)=>this.handleOpenCoutryList(e)}>


                                          <div className="profile-selectflag" >
                                            <div className="d-flex profile-selectflagtop">
                                              <input className="searchfleginput" placeholder="Search..."
                                                value={this.state.dropdownSearchVal} onChange={(e) => this.handleDropdownSearch(e)} />
                                              {this.state.dropdownSearchVal.trim() && <p onClick={(e) => {
                                                this.setState({
                                                  dropdownSearchVal: ""
                                                })
                                              }} className="clear-search">Clear</p>}
                                            </div>
                                            <div className="countrydiv">
                                              <div className=" countryflagtype">
                                                {/* <img
                                    src={
                                      require("../assets/images/dashboard/country-default.png")
                                        .default
                                    }
                                  />
                                  <p>United States (+1)</p> */}
                                                {this.state.countryselectfield
                                                  .filter((name) =>
                                                    name.country_name
                                                      .toLowerCase()
                                                      .includes(this.state.dropdownSearchVal.toLowerCase())
                                                  )
                                                  .map((res, i) => (
                                                    <div
                                                      className="d-flex align-items-center justify-content-between country-line-flag"
                                                      key={i}
                                                      onClick={(e) => this.handleCountryDropdown(e, res)}
                                                    >
                                                      <div className="d-flex align-items-center justify-content-start"

                                                      >
                                                        <img
                                                          src={
                                                            process.env.REACT_APP_Image_Path +
                                                            process.env.REACT_APP_SMALL_FLAG +
                                                            res.country_code.toLowerCase() +
                                                            ".png"
                                                          }
                                                          alt={i}
                                                        />
                                                        <p className="country-name">
                                                          {res.country_name} ({"+" + res.phone_code})
                                                        </p>
                                                      </div>

                                                    </div>
                                                  ))}
                                              </div>
                                            </div>
                                          </div>
                                        }
                                        <div style={{ width: "100%", height: "50px", display: "flex", alignItems: "center", cursor: "pointer" }} onClick={(e) => window.matchMedia("(max-width: 1200px)").matches ?
                                          this.togglephonecodemodal(e) : this.handleOpenCoutryList(e)}>

                                          <img

                                            src={
                                              process.env.REACT_APP_Image_Path +
                                              process.env.REACT_APP_SMALL_FLAG +
                                              this.state.country_name_prefix.toLowerCase() +
                                              ".png"
                                            }
                                            alt="country-flag"
                                          />
                                          {"+" + this.state.phone_code}
                                        </div>
                                      </div>

                                      <input
                                        type="text"
                                        className="form-control form-namethree phone-inputs phone-input-radius"
                                        form="form1"
                                        value={this.state.phone}
                                        onChange={(e) => this.handlephone(e)}

                                      />
                                    </div>

                                    {/* </Modal> */}

                                  </div>
                                </div>
                                <div className="color_picker inputtypetwo">
                                  <h4>What role best describes you?*</h4>
                                   <div
                                className="mobile-view-click"
                                  onClick={
                                    window.matchMedia("(max-width: 1200px)")
                                      .matches
                                      ? () => this.handleOpenMobRoleDropdown()
                                      : null
                                  }
                                >
                                  <ReactSelect
                                    options={this.state.optionsForRole}
                                    onChange={(e) =>
                                      this.handleUserRoleChange(e)
                                    }
                                    required={true}
                                    classNamePrefix="profile-role-dropdown"
                                    value={{ label: this.state.user_roles }}
                                    //  value={this.state.optionsForRole.filter(function(option) {
                                    //   return option.value === this.state.user_roles;
                                    placeholder="Select...."
                                    isSearchable={false}
                                    isClearable={false}
                                    isDisabled={
                                      window.matchMedia("(max-width: 1200px)")
                                        .matches
                                        ? true
                                        : false
                                    }
                                    styles={customStyles}
                                  />
                                </div>
                                  {/* <select
                            className="owner form-selectone"
                            name="userroles"
                            value={this.state.user_roles}
                            onChange={(e) => this.handleUserRoleChange(e)}
                            // required
                            form="form1"
                          >
                            <option
                              value=""
                              style={{ display: "none" }}
                            ></option>
                            <option value="Business Owner">
                              {" "}
                              Business Owner{" "}
                            </option>
                            <option value="Customer Success">
                              {" "}
                              Customer Success{" "}
                            </option>
                            <option value="Engineering"> Engineering </option>
                            <option value="Leadership"> Leadership </option>
                            <option value="Marketing"> Marketing </option>
                            <option value="Operations"> Operations </option>
                            <option value="Sales"> Sales </option>
                            <option value="Support"> Support </option>
                            <option value="Other"> Other </option>
                          </select> */}

                                  {
                                    this.state.role_err && <div className="d-flex profile-validation">
                                      <svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                                        <path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848" />
                                        <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1" />
                                        <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1" />
                                      </svg>

                                      <p className="profile-redinput">Required</p>
                                    </div>
                                  }
                                </div>
                                <div className="color_picker inputtypeone">
                                  <h4>What is your company size?*</h4>
                                 <div
                                className="mobile-view-click"
                                  onClick={
                                    window.matchMedia("(max-width: 1200px)")
                                      .matches
                                      ? () =>
                                          this.handleOpenMobCompanySizeDropdown()
                                      : null
                                  }
                                >
                                  <ReactSelect
                                    options={this.state.optionsForCompanySize}
                               
                                    onChange={(e) =>
                                      this.handleCompanySizeChange(e)
                                    }
                                    required={true}
                                    classNamePrefix="profile-role-dropdown"
                                    value={{ label: this.state.company_size }}
                                    //  value={this.state.optionsForRole.filter(function(option) {
                                    //   return option.value === this.state.user_roles;
                                    placeholder="Select..."
                                    isSearchable={false}
                                    isClearable={false}
                                    isDisabled={
                                      window.matchMedia("(max-width: 1200px)")
                                        .matches
                                        ? true
                                        : false
                                    }
                                    styles={customStyles}
                                  />
                                </div>
                                  {/* <select
                            className="owner form-selecttwo"
                            name="company_size"
                            value={this.state.company_size}
                            onChange={(e) => this.handleCompanySizeChange(e)}
                            // required
                            form="form1"
                          >
                            <option
                              value=""
                              style={{ display: "none" }}
                            ></option>
                            <option value="1-19 employees">
                              {" "}
                              1-19 employees{" "}
                            </option>
                            <option value="20-49 employees">
                              {" "}
                              20-49 employees{" "}
                            </option>
                            <option value="50-249 employees">
                              {" "}
                              50-249 employees{" "}
                            </option>
                            <option value="250-999 employees">
                              {" "}
                              250-999 employees{" "}
                            </option>
                            <option value="1,000+ employees">
                              {" "}
                              1,000+ employees{" "}
                            </option>
                          </select> */}
                                  {
                                    this.state.company_err &&
                                    <div className="d-flex profile-validation">
                                      <svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                                        <path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848" />
                                        <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1" />
                                        <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1" />
                                      </svg>

                                      <p className="profile-redinput">Required</p>
                                    </div>
                                  }
                                </div>
                                <div className="color_picker inputtypetwo">
                                  <h4>What industry do you operate in?*</h4>
                                  <div
                                className="mobile-view-click"
                                  onClick={
                                    window.matchMedia("(max-width: 1200px)")
                                      .matches
                                      ? () =>
                                          this.handleOpenMobOperateDropdown()
                                      : null
                                  }
                                >
                                  <ReactSelect
                                    options={this.state.optionsForIndustory}
                                    onChange={(e) =>
                                      this.handleUserIndustryChange(e)
                                    }
                                    required={true}
                                    classNamePrefix="profile-role-dropdown"
                                    value={{ label: this.state.user_industry }}
                                    //  value={this.state.optionsForRole.filter(function(option) {
                                    //   return option.value === this.state.user_roles;
                                    placeholder="Select..."
                                    isSearchable={false}
                                    isClearable={false}
                                    isDisabled={
                                      window.matchMedia("(max-width: 1200px)")
                                        .matches
                                        ? true
                                        : false
                                    }
                                    styles={customStyles}
                                  />
                                </div>
                                  {/* <select
                            className="owner form-selectthree"
                            name="userindustry"
                            value={this.state.user_industry}
                            onChange={(e) => this.handleUserIndustryChange(e)}
                            // required
                            form="form1"
                          >
                            <option
                              value=""
                              style={{ display: "none" }}
                            ></option>
                            <option value="Arts"> Arts </option>
                            <option value="Automotive"> Automotive </option>
                            <option value="Constructions & Engineering">
                              {" "}
                              Constructions & Engineering{" "}
                            </option>
                            <option value="Consulting"> Consulting </option>
                            <option value="Consumer Goods">
                              {" "}
                              Consumer Goods{" "}
                            </option>
                            <option value="Education"> Education </option>
                            <option value="Energy"> Energy </option>
                            <option value="Entertainment">
                              {" "}
                              Entertainment{" "}
                            </option>
                            <option value="Finance"> Finance </option>
                            <option value="Fintech"> Fintech </option>
                            <option value="Food & Beverages">
                              {" "}
                              Food & Beverages{" "}
                            </option>
                            <option value="Government"> Government </option>
                            <option value="Healthcare"> Healthcare </option>
                            <option value="Human Resources">
                              {" "}
                              Human Resources{" "}
                            </option>
                            <option value="IT"> IT </option>
                            <option value="Insurance"> Insurance </option>
                            <option value="Logistics"> Logistics </option>
                            <option value="Manufacturing">
                              {" "}
                              Manufacturing{" "}
                            </option>
                            <option value="Marketing"> Marketing </option>
                            <option value="Media"> Media </option>
                            <option value="NGO"> NGO </option>
                            <option value="Professional Services">
                              {" "}
                              Professional Services{" "}
                            </option>
                            <option value="Real Estate"> Real Estate </option>
                            <option value="Restaurants"> Restaurants </option>
                            <option value="Retail"> Retail </option>
                            <option value="Software"> Software </option>
                            <option value="Sports"> Sports </option>
                            <option value="Telecom"> Telecom </option>
                            <option value="Travel & Leisure">
                              {" "}
                              Travel & Leisure{" "}
                            </option>
                          </select> */}
                                  {
                                    this.state.industory_err && <div className="d-flex profile-validation">
                                      <svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                                        <path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848" />
                                        <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1" />
                                        <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1" />
                                      </svg>

                                      <p className="profile-redinput">Required</p>
                                    </div>
                                  }
                                </div>
                              </form>

                              {/* <div className="col-md-12 p-0">
                        <div className="customcheck customcheck-red">
                          <ReCAPTCHA
                            sitekey="6LeOmMAUAAAAAGj7NXf5SlZA25DNAfvL1HwxaAeP"
                            onChange={this.recaptchaonChange}
                          />
                        </div>
                        <button
                          type="submit"
                          className="next_btn order2 d-block d-xl-none"
                          form="form1"
                          onClick={this.profiledata}
                        >
                          <div className="d-flex align-items-center justify-content-center h-100 w-100">
                            <div className={this.state.loader}>
                              
                              <div className="new-loader"></div>
                            </div>{" "}
                            <div>{this.state.submitval}</div>
                          </div>
                        </button>
                      </div> */}
                            </div>
                          </div>
                          <div className="profile-mob-save-btn">
                            <button
                              type="submit"
                              className="newprofile-save-mob-btn  "
                              form="form1"
                            // onClick={this.profiledata}
                            >
                              <div className="d-flex align-items-center justify-content-center h-100 w-100" style={{ marginTop: "2px" }}>
                                <div className={this.state.loader}>
                                  {/*<img src={this.state.loaderimage} alt="loader" />*/}
                                  <div className="new-loader"></div>
                                </div>{" "}
                                <div>{this.state.submitval}</div>
                              </div>
                            </button> {
                              this.state.updated_at && <p className="mob-profile-update">Updated {moment(
                                this.state.updated_at
                              ).format("D MMMM YYYY, HH:mm")}</p>}

                          </div>
                        {this.state.is_google_login_enabled == 1 || this.state. is_fb_login_enabled == 1 || localStorage.getItem('agencyid') == 1 ?
                          <div className="new-my-profile-shadowsecond">
                            {
                              (this.state.google_spinner === true || this.state.fb_spinner === true) ?
                                <div className="test-spinner" style={{ backgroundColor: "white" }} >
                                  <svg xmlns="http://www.w3.org/2000/svg"
                                    xlink="http://www.w3.org/1999/xlink"
                                    style={{ margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s" }}
                                    width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                    <g transform="translate(20 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                      <circle cx="0" cy="0" r="6" fill="#e6261f" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                        <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                          style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                        </animateTransform>
                                      </circle>
                                    </g>
                                    <g transform="translate(40 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                      <circle cx="0" cy="0" r="6" fill="#f7d038" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                        <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                          style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                        </animateTransform>
                                      </circle>
                                    </g>
                                    <g transform="translate(60 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                      <circle cx="0" cy="0" r="6" fill="#49da9a" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                        <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                          style={{ animationplaystate: "running", animationdelay: "0s" }}>

                                        </animateTransform>
                                      </circle>
                                    </g>
                                    <g transform="translate(80 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                      <circle cx="0" cy="0" r="6" fill="#4355db" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                        <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                          style={{ animationplaystate: "running", animationdelay: "0s" }}>

                                        </animateTransform>
                                      </circle>
                                    </g>
                                  </svg>
                                </div> 
                              : this.state.is_google_login_enabled == 1 || this.state. is_fb_login_enabled == 1 || localStorage.getItem('agencyid') == 1 ?

                                <div className="new-profile-social">
                                  <h4>Social accounts</h4>
                                  <h5>
                                    Seamlessly login to your account using your social profiles.
                                  </h5>
                                  <div className="row">
                                  {localStorage.getItem('agencyid') == 1 ?
                                    <div className="col-md-6">
                                      <div className="d-flex align-items-center">
                                        <div className="profile-social-login">
                                          <svg id="logo_googleg_48dp" xmlns="http://www.w3.org/2000/svg" width="24.162" height="24.162" viewBox="0 0 24.162 24.162">
                                            <path id="Shape" d="M20.6,9.835a13.889,13.889,0,0,0-.22-2.471H9v4.673h6.5a5.557,5.557,0,0,1-2.411,3.646v3.031H17a11.782,11.782,0,0,0,3.6-8.879Z" transform="translate(3.081 2.521)" fill="#4285f4" fill-rule="evenodd" />
                                            <path id="Shape-2" data-name="Shape" d="M11.753,20.5a11.534,11.534,0,0,0,8-2.927l-3.9-3.031A7.288,7.288,0,0,1,4.993,10.71H.957v3.13a12.076,12.076,0,0,0,10.8,6.656Z" transform="translate(0.328 3.666)" fill="#34a853" fill-rule="evenodd" />
                                            <path id="Shape-3" data-name="Shape" d="M5.321,12.679a7.142,7.142,0,0,1,0-4.591V4.958H1.285a12.1,12.1,0,0,0,0,10.851l4.036-3.13Z" transform="translate(0 1.697)" fill="#fbbc05" fill-rule="evenodd" />
                                            <path id="Shape-4" data-name="Shape" d="M11.753,4.8a6.527,6.527,0,0,1,4.618,1.807l3.465-3.465A11.61,11.61,0,0,0,11.753,0,12.076,12.076,0,0,0,.957,6.655l4.036,3.13A7.2,7.2,0,0,1,11.753,4.8Z" transform="translate(0.328)" fill="#ea4335" fill-rule="evenodd" />
                                            <path id="Shape-5" data-name="Shape" d="M0,0H24.162V24.162H0Z" fill="none" fill-rule="evenodd" />
                                          </svg>

                                        </div>
                                        <div className="social-parent-div">
                                          <p className="newp-profile">{this.state.txtgoogle}</p>
                                       
                                          <div className="d-flex new-profileconnect">
                                            {this.state.linked_google === 1 && <p className="newprofile-afterconnect">Connected</p>}
                                            {this.state.linked_google === 1 ? 
                                            (
                                              <a
                                                className="remove"
                                                onClick={this.removeGoogleLogins}
                                              >
                                                Remove
                                              </a>
                                            ) 
                                            : 
                                            (
                                                <a href={process.env.REACT_APP_API_URL + "auth/google/profile/" + localStorage.getItem("agency_id") +"/" + localStorage.getItem("user_id")}>
                                                  Connect
                                                </a>
                                              // <GoogleLogin
                                              //   clientId="209790035322-npn2ek1udiuu9c4v8lrkdooi6kgv7tv4.apps.googleusercontent.com"
                                              //   render={(renderProps) => (
                                              //     <div
                                              //       onClick={(this.state.errcookies === '') ? renderProps.onClick : ""}
                                              //       disabled={renderProps.disabled}
                                              //       className=" connect-status"
                                              //     >
                                              //       {" "}
                                              //       {this.state.seterror ?
                                              //         (
                                              //           <img
                                              //             className="google-disabled"
                                              //             src={require("../assets/images/signup/google-disabled.png").default}
                                              //             alt="Googlelogo"
                                              //           />
                                              //         ) :
                                              //         (
                                              //           (

                                              //             this.state.errcookies !== '' ?
                                              //               <>
                                              //                 <div className="profile-connect-socialmedia 1" > Connect </div>
                                              //               </>
                                              //               :
                                              //               <a onClick={this.hanleGoogleClick}>
                                              //                 Connect
                                              //               </a>
                                              //           )
                                              //         )
                                              //       }
                                              //     </div>
                                              //   )}
                                              //   buttonText=""
                                              //   onSuccess={this.responseGoogleProfile}
                                              //   onFailure={this.responseGoogleProfile}
                                              //   cookiePolicy={"single_host_origin"}
                                              //   isSignedIn={true}
                                              // />
                                            )
                                            }
                                          </div>
                                        
                                        </div>
                                      </div>
                                    </div>
                                  : localStorage.getItem('agencyid') != 1 && this.state.is_google_login_enabled == 1 ?
                                  <div className="col-md-6">
                                  <div className="d-flex align-items-center">
                                    <div className="profile-social-login">
                                      <svg id="logo_googleg_48dp" xmlns="http://www.w3.org/2000/svg" width="24.162" height="24.162" viewBox="0 0 24.162 24.162">
                                        <path id="Shape" d="M20.6,9.835a13.889,13.889,0,0,0-.22-2.471H9v4.673h6.5a5.557,5.557,0,0,1-2.411,3.646v3.031H17a11.782,11.782,0,0,0,3.6-8.879Z" transform="translate(3.081 2.521)" fill="#4285f4" fill-rule="evenodd" />
                                        <path id="Shape-2" data-name="Shape" d="M11.753,20.5a11.534,11.534,0,0,0,8-2.927l-3.9-3.031A7.288,7.288,0,0,1,4.993,10.71H.957v3.13a12.076,12.076,0,0,0,10.8,6.656Z" transform="translate(0.328 3.666)" fill="#34a853" fill-rule="evenodd" />
                                        <path id="Shape-3" data-name="Shape" d="M5.321,12.679a7.142,7.142,0,0,1,0-4.591V4.958H1.285a12.1,12.1,0,0,0,0,10.851l4.036-3.13Z" transform="translate(0 1.697)" fill="#fbbc05" fill-rule="evenodd" />
                                        <path id="Shape-4" data-name="Shape" d="M11.753,4.8a6.527,6.527,0,0,1,4.618,1.807l3.465-3.465A11.61,11.61,0,0,0,11.753,0,12.076,12.076,0,0,0,.957,6.655l4.036,3.13A7.2,7.2,0,0,1,11.753,4.8Z" transform="translate(0.328)" fill="#ea4335" fill-rule="evenodd" />
                                        <path id="Shape-5" data-name="Shape" d="M0,0H24.162V24.162H0Z" fill="none" fill-rule="evenodd" />
                                      </svg>

                                    </div>
                                    <div className="social-parent-div">
                                      <p className="newp-profile">{this.state.txtgoogle}</p>
                                   
                                      <div className="d-flex new-profileconnect">
                                        {this.state.linked_google === 1 && <p className="newprofile-afterconnect">Connected</p>}
                                        {this.state.linked_google === 1 ? 
                                        (
                                          <a
                                            className="remove"
                                            onClick={this.removeGoogleLogins}
                                          >
                                            Remove
                                          </a>
                                        ) 
                                        : 
                                        (
                                         <a href={process.env.REACT_APP_API_URL + "auth/google/profile/" + localStorage.getItem("agency_id") +"/" + localStorage.getItem("user_id")}>Connect</a>
                                          // <GoogleLogin
                                          //   clientId={this.state.google_app_id}
                                          //   render={(renderProps) => (
                                          //     <div
                                          //       onClick={(this.state.errcookies === '') ? renderProps.onClick : ""}
                                          //       disabled={renderProps.disabled}
                                          //       className=" connect-status"
                                          //     >
                                          //       {" "}
                                          //       {this.state.seterror ?
                                          //         (
                                          //           <img
                                          //             className="google-disabled"
                                          //             src={require("../assets/images/signup/google-disabled.png").default}
                                          //             alt="Googlelogo"
                                          //           />
                                          //         ) :
                                          //         (
                                          //           (

                                          //             this.state.errcookies !== '' ?
                                          //               <>
                                          //                 <div className="profile-connect-socialmedia 2" > Connect </div>
                                          //               </>
                                          //               :
                                          //               <a onClick={this.hanleGoogleClick}>
                                          //                 Connect
                                          //               </a>
                                          //           )
                                          //         )
                                          //       }
                                          //     </div>
                                          //   )}
                                          //   buttonText=""
                                          //   onSuccess={this.responseGoogleProfile}
                                          //   onFailure={this.responseGoogleProfile}
                                          //   cookiePolicy={"single_host_origin"}
                                          //   isSignedIn={true}
                                          // />
                                        )
                                        }
                                      </div>
                                    
                                    </div>
                                  </div>
                                </div>
                                  :  null
                                  }
                                    {/* {localStorage.getItem('agencyid') == 1 ?
                                    <div className="col-md-6">
                                      <div className="d-flex align-items-center">
                                        <div className="profile-social-login">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="11.287" height="24.483" viewBox="0 0 11.287 24.483">
                                            <path id="Path_88" data-name="Path 88" d="M48.644,33.981H45.3V46.246H40.224V33.981H37.812V29.67h2.412V26.881c0-1.995.947-5.118,5.117-5.118l3.757.016v4.184H46.373A1.032,1.032,0,0,0,45.3,27.138v2.537h3.791Z" transform="translate(-37.812 -21.763)" fill="#4d72d8" />
                                          </svg>
                                        </div>
                                        <div className="social-parent-div">
                                          <p className="newp-profile">{this.state.txtfacebook}</p>

                                            <div className="d-flex new-profileconnect">
                                              {this.state.linked_facebook === 1 && <p className="newprofile-afterconnect">Connected</p>}
                                              {this.state.linked_facebook === 1 ? (
                                                // this.state.fb_spinner ? (
                                                //   <div className="activeandroidspinn">
                                                //     <Spinner
                                                //       animation="border"
                                                //       variant="primary"
                                                //     />
                                                //   </div>
                                                // ) : (

                                                <a
                                                  className="remove"
                                                  onClick={this.removeFacebookLogins}
                                                >
                                                  Remove
                                                </a>
                                                // )
                                              ) : (
                                                <FacebookLogin
                                                  appId="454532105165574"
                                                  autoLoad={false}
                                                  fields="name,email,picture"
                                                  callback={this.responseFacebookProfile}
                                                  render={(renderProps) => (
                                                    <div className="connect-status" onClick={(this.state.errcookies === '') ? renderProps.onClick : ""}>
                                                      {" "}
                                                      {
                                                        // this.state.fb_spinner ?
                                                        //   (
                                                        //     <div className="activeandroidspinn">
                                                        //       <Spinner
                                                        //         animation="border"
                                                        //         variant="primary"
                                                        //       />
                                                        //     </div>
                                                        //   )
                                                        //   :
                                                        (
                                                          (this.state.errcookies !== '' && this.state.errcookies !== null) ?
                                                            <div className="profile-connect-socialmedia"> Connect </div>
                                                            :
                                                            <a>Connect</a>
                                                        )
                                                      }
                                                    </div>
                                                  )}
                                                  cssClass="fbtn"
                                                  disableMobileRedirect={true}
                                                />
                                              )}
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                    : this.state. is_fb_login_enabled == 1 && localStorage.getItem('agencyid') != 1 ?
                                    <div className="col-md-6">
                                    <div className="d-flex align-items-center">
                                      <div className="profile-social-login">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11.287" height="24.483" viewBox="0 0 11.287 24.483">
                                          <path id="Path_88" data-name="Path 88" d="M48.644,33.981H45.3V46.246H40.224V33.981H37.812V29.67h2.412V26.881c0-1.995.947-5.118,5.117-5.118l3.757.016v4.184H46.373A1.032,1.032,0,0,0,45.3,27.138v2.537h3.791Z" transform="translate(-37.812 -21.763)" fill="#4d72d8" />
                                        </svg>
                                      </div>
                                      <div className="social-parent-div">
                                        <p className="newp-profile">{this.state.txtfacebook}</p>

                                          <div className="d-flex new-profileconnect">
                                            {this.state.linked_facebook === 1 && <p className="newprofile-afterconnect">Connected</p>}
                                            {this.state.linked_facebook === 1 ? (
                                              // this.state.fb_spinner ? (
                                              //   <div className="activeandroidspinn">
                                              //     <Spinner
                                              //       animation="border"
                                              //       variant="primary"
                                              //     />
                                              //   </div>
                                              // ) : (

                                              <a
                                                className="remove"
                                                onClick={this.removeFacebookLogins}
                                              >
                                                Remove
                                              </a>
                                              // )
                                            ) : (
                                              <FacebookLogin
                                                appId={this.state.facebookid}
                                                autoLoad={false}
                                                fields="name,email,picture"
                                                callback={this.responseFacebookProfile}
                                                render={(renderProps) => (
                                                  <div className="connect-status" onClick={(this.state.errcookies === '') ? renderProps.onClick : ""}>
                                                    {" "}
                                                    {
                                                      // this.state.fb_spinner ?
                                                      //   (
                                                      //     <div className="activeandroidspinn">
                                                      //       <Spinner
                                                      //         animation="border"
                                                      //         variant="primary"
                                                      //       />
                                                      //     </div>
                                                      //   )
                                                      //   :
                                                      (
                                                        (this.state.errcookies !== '' && this.state.errcookies !== null) ?
                                                          <div className="profile-connect-socialmedia"> Connect </div>
                                                          :
                                                          <a>Connect</a>
                                                      )
                                                    }
                                                  </div>
                                                )}
                                                cssClass="fbtn"
                                                disableMobileRedirect={true}
                                              />
                                            )}
                                          </div>
                                      </div>
                                    </div>
                                  </div>
                                    : null
                                    } */}
                                  </div>
                                  {(this.state.errcookies !== '' && this.state.errcookies !== null) ?
                                    <div className="new-social-error">
                                      <div className="errordiv">

                                        <p>Permission needed. Check your browser settings to make sure third-party cookies are allowed. </p>
                                      </div>
                                      <div>
                                        <svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                          <path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ff4848" />
                                          <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.207)" fill="none" stroke="#fff" stroke-width="2" />
                                          <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2" />
                                        </svg>
                                      </div>
                                    </div>
                                    :
                                    null
                                  }
                                </div>
                              : null
                            }
                          </div>
                        : <></>
                        }



                          <div className="new-my-profile-thirdshadow">
                            <div className="new-profile-delete ">
                              <div>
                                <h3>Delete your account</h3>
                                <h5>
                                  This action is irreversible. You cannot retrieve your data once removed.
                                </h5>
                              </div>

                              <div className="">
                                {this.state.apps_count === 0 ? (
                                  <div
                                    onClick={(e) => this.delete_accountmodal(e)}
                                    className="new-my-profile-delete"
                                  >
                                    Delete account
                                  </div>
                                ) : (
                                  <div
                                    onClick={(e) => this.deleteapps(e)}
                                    className="new-my-profile-delete"
                                  >
                                    Delete account
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                    }
                  </div>
                </div>

              </div>
            </div>
            {/* {Foot()} */}
          </div>
        </section>


        {/* New Image upload Pop Up */}

        <NewImageGalleryPopup
          isGalleryPopup={this.state.modal}
          setIsGalleryPopup={true}
          settingYourdata={this.settingYourdata}
          yourErrFun={this.yourErrFun}
          uploadPath={
            //  localStorage.getItem("user_id") +
            //  process.env.REACT_APP_Profile
            localStorage.getItem("originalid") +
            process.env.REACT_APP_Profile
          }
          settingOrgImgFrom3rdParty={false}
          hideImageGallery={true}
          widthForRestriction={120}
          heightForRestriction={120}
          aspectRatio={"1:1"}
          classCompoState={this}
          cropShape={"round"}
          isNotCustomUploadOrgImg={true}
          // designid={""}
          // loadpicVistaCreatePopUp={this.loadpicVistaCreatePopUp}
          // closeVistaCreatePopUp={this.closeVistaCreatePopUp}
        />

        {/* <Modal
          isOpen={this.state.modal}
          centered
          className={this.props.className}
          style={{ maxWidth: "40%" }}
        >
          <ModalHeader
            toggle={this.photoggle}
            className="modal-xxl-content croppopup"
          >
            <p>Crop Image</p>
            <div className="crossmodal mt-2" onClick={this.photoggle}>
              <svg width="14.779" height="14.778" viewBox="0 0 14.779 14.778">
                <g
                  id="Group_2926"
                  data-name="Group 2926"
                  transform="translate(0.75 0.75)"
                >
                  <path
                    id="Union_18"
                    data-name="Union 18"
                    d="M6.64,6.638,0,13.277,6.64,6.638,0,0,6.64,6.638,13.279,0,6.64,6.638l6.64,6.639Z"
                    fill="#fff"
                    stroke="#7782a1"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                  ></path>
                </g>
              </svg>
            </div>
          </ModalHeader>
          <ModalBody className="p-0 cropbody">
            <div
              style={{
                position: "relative",
                width: "95%",
                height: "95%",
                margin: "auto",
                top: "10px",
              }}
            >
              <Cropper
                image={this.state.image}
                crop={this.state.crop}
                zoom={this.state.zoom}
                aspect={this.state.aspect}
                cropShape="round"
                showGrid={false}
                onCropChange={this.onCropChange}
                onCropComplete={this.onCropComplete}
                onZoomChange={this.onZoomChange}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="d-flex justify-content-end w-100">
              <div
                onClick={this.showCroppedImage}
                className="next_btn text-center butt"
                style={{ width: "150px" }}
              >
                {this.state.buttontext}
              </div>
            </div>
          </ModalFooter>
        </Modal> */}

        {/* Country code  */}

        {/* <div>
        <div className="d-flex align-items-center search-box justify-content-center profile-country-search">
              <div className="d-flex align-items-center w-100 ">
              <svg xmlns="http://www.w3.org/2000/svg" width="20.759" height="15.408" viewBox="0 0 20.759 15.408">
  <g id="Group_27465" data-name="Group 27465" transform="translate(-19.742 -22.295)">
    <path id="Path_81245" data-name="Path 81245" d="M-13783.922-19217h19.346" transform="translate(13805.077 19247)" fill="none" stroke="#7782a1" stroke-width="2"/>
    <path id="Path_82232" data-name="Path 82232" d="M14053.656,19255.426l-7,7,7,7" transform="translate(-14025.504 -19232.424)" fill="none" stroke="#7782a1" stroke-width="2"/>
  </g>
</svg>

                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  value={this.state.phonecodesearch}
                  onChange={(e) => this.handlephonecodesearch(e)}
                />
                <p className="cleartext">clear</p>
              </div>
            </div>
            <div className=" profile-countrycode-flag ">
              {this.state.countryselectfield
                .filter((name) =>
                  name.country_name
                    .toLowerCase()
                    .includes(this.state.phonecodesearch.toLowerCase())
                )
                .map((res, i) => (
                  <div
                    className="d-flex align-items-center justify-content-between country-line-flag"
                    key={i}
                  >
                    <div className="d-flex align-items-center justify-content-start">
                      <img
                        src={
                          process.env.REACT_APP_Image_Path +
                          process.env.REACT_APP_SMALL_FLAG +
                          res.country_code.toLowerCase() +
                          ".png"
                        }
                        alt={i}
                      />
                      <p className="country-name">
                        {res.country_name} ({"+" + res.phone_code})
                      </p>
                    </div>
                  
                  </div>
                ))}
            </div>
        </div> */}
        {/* 
                            <ReactSelect
                                      options={this.state.optionsForUserPortal}
                                      onChange={(e) => this.handleChange(e)}
                                      required={true}
                                      defaultMenuIsOpen
                                      placeholder="Select app"
                                      // styles={customStyles}
                                      isClearable={false}
                                    /> */}

        <Modal
          isOpen={this.state.phonecodemodal}
          // isOpen= {false}
          toggle={this.togglephonecodemodal}
          style={{ maxWidth: "700px", maxHeight: "350px" }}
          className={this.props.className + " profile-code-modal"}
          centered
          dataclass="countrycode"
        >
          <ModalHeader
            toggle={this.togglephonecodemodal}
            className=" "
          >
            {/* <p className="modal-title">Country Code</p> */}
            {/* <div
              className="crossmodal mt-1"
              onClick={this.togglephonecodemodal}
            >
              {/* <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                <path
                  id="Union_18"
                  data-name="Union 18"
                  d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                  transform="translate(0.529 0.531)"
                  fill="#1b2952"
                  stroke="#1b2952"
                  strokeWidth="1.5"
                />
              </svg> */}
            {/* </div> */}
            <div className="d-flex align-items-center search-box justify-content-center profile-country-search">
              <div className="d-flex align-items-center w-100 ">
                <div className="backaero-svgimg" onClick={this.togglephonecodemodal}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20.759" height="15.408" viewBox="0 0 20.759 15.408">
                    <g id="Group_27465" data-name="Group 27465" transform="translate(-19.742 -22.295)">
                      <path id="Path_81245" data-name="Path 81245" d="M-13783.922-19217h19.346" transform="translate(13805.077 19247)" fill="none" stroke="#7782a1" stroke-width="2" />
                      <path id="Path_82232" data-name="Path 82232" d="M14053.656,19255.426l-7,7,7,7" transform="translate(-14025.504 -19232.424)" fill="none" stroke="#7782a1" stroke-width="2" />
                    </g>
                  </svg>
                </div>

                <input
                  type="text"
                  className="form-control flagsearch-input"
                  placeholder="Search..."
                  value={this.state.dropdownSearchVal} onChange={(e) => this.handleDropdownSearch(e)} />
                {this.state.dropdownSearchVal.trim() && <p onClick={(e) => {
                  this.setState({
                    dropdownSearchVal: ""
                  })
                }} className="cleartext">Clear</p>}
              </div>
            </div>
          </ModalHeader>
          <ModalBody className="code-selector p-0">
            {/* <div className="d-flex align-items-center search-box justify-content-center country-line">
              <div className="d-flex align-items-center w-100 ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Country.."
                  value={this.state.phonecodesearch}
                  onChange={(e) => this.handlephonecodesearch(e)}
                />
              </div>
            </div> */}
            <div className=" profile-countrycode-flag ">
              {this.state.countryselectfield
                .filter((name) =>
                  name.country_name
                    .toLowerCase()
                    .includes(this.state.dropdownSearchVal.toLowerCase())
                )
                .map((res, i) => (
                  <div
                    className="d-flex align-items-center justify-content-between country-line-flag"
                    key={i}
                  >
                    <div className="d-flex align-items-center justify-content-start"
                      onClick={(e) => this.handleCountryDropdown(e, res)}
                    >
                      <img
                        src={
                          process.env.REACT_APP_Image_Path +
                          process.env.REACT_APP_SMALL_FLAG +
                          res.country_code.toLowerCase() +
                          ".png"
                        }
                        alt={i}
                      />
                      <p className="country-name">
                        {res.country_name} ({"+" + res.phone_code})
                      </p>
                    </div>
                    {/* <label className="container1 mt-md-2 mb-md-4">
                      <input
                        type="radio"
                        name="radio"
                        value={res.phone_code}
                        onClick={(e) => this.handleseachradio(e)}
                      />
                      <span className="checkmark"></span>
                    </label> */}
                  </div>
                ))}
            </div>
          </ModalBody>
        </Modal>


        <Modal
          isOpen={this.state.userdeletemodal}

          toggle={this.delete_accountmodal}
          // isOpen={this.state.delete_app}
          // toggle={this.deleteapps}
          style={{ maxWidth: "600px", maxHeight: "340px" }}
          centered
          className="deletemodaltype"
        >
          <ModalHeader toggle={this.deleteapps} className="modal-xxl-content">
            <div className="profile-crossmodal " onClick={this.delete_accountmodal}>
              <img
                src={require("../assets/images/dashboard/cross.png").default}
              />
            </div>
          </ModalHeader>
          <ModalBody className="new-delete-my-account">
            {/* <div className="crossmodal mt-1" onClick={this.delete_accountmodal}>
              <img
                src={require("../assets/images/dashboard/cross.png").default}
              />
            </div> */}
            <div>
              <div className="new-delete-account-popup">
                <h4>Delete Account</h4>
                {(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1) ?
                <h5>
                  All your data, including but not limited to Apps, Workspaces and Organisations will be permanently deleted. This action cannot be undone. Are you sure you want to delete this account?

                </h5>
                :
                  <h5>All your data including your apps will be permanently deleted. This action cannot be undone. Are you sure you want to delete this account?</h5>
                }
                <h6>Please type in your email below to confirm*.</h6>
                <div className="confirm-email-validation">
                  <input placeholder="Email" type="email" id="emailconfirm" autoComplete="off" onPaste={(e) => { e.preventDefault(); return false;}} />
                  {
                    this.state.delete_email_err &&
                    <div className="d-flex profileemail-validation">
                      <svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                        <path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848" />
                        <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1" />
                        <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1" />
                      </svg>

                      <p className="profile-redinput">Required</p>
                    </div>
                  }
                </div>
              </div>

              <div className="new-delete-account-footer">
                <div onClick={this.delete_accountmodal}>
                  <p>Cancel</p>
                </div>
                <div onClick={(e) => this.Deleteuser(e)}>
                  <p className={this.state.deleteLoader}>
                    {/* <img
                      className="delete-loader"
                      src={this.state.deleteLoaderimage}
                      alt="step"
                    /> */}
                     <div className="new-red-loader"></div> 
                   {/* {
                this.state.isHover ?
                <div className="new-loader"></div> :
                <div className="new-red-loader"></div> 
               } */}
                  </p>
                  {this.state.deleteLoader !== "d-block" && <p>Delete</p>}
                </div>
              </div>
              {/* <div className="d-flex justify-content-end finalselection">
                <div onClick={this.delete_accountmodal}>
                  <p>No</p>
                </div>
                <div onClick={(e) => this.Deleteuser(e)}>
                  <p>Yes</p>
                </div>
              </div> */}
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.delete_app}
          toggle={this.deleteapps}
          style={{ maxWidth: "480px", maxHeight: "296px" }}
          className="deletemodaltype"
          centered
          dataclass="countrycode"
        >
          <ModalHeader toggle={this.deleteapps} className="modal-xxl-content">
            <div className="profile-crossmodal " onClick={this.deleteapps}>
              <img
                src={require("../assets/images/dashboard/cross.png").default}
              />
            </div>
          </ModalHeader>
          <ModalBody className="new-before-delete-step">
            <div>
              <h4>Active products found</h4>
              {(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") != 1) ?
              <h6>Before deleting your profile you must delete all your Apps.{" "}</h6>
              :
              <h6>
                  Before deleting your profile you must delete all your Apps, Workspaces and Organizations.{" "}
              </h6>
              }

              <div className="before-delete-footer">
                {/* <div className="before-delete-cancel">
                <p onClick={this.deleteapps}>Cancel</p>
              </div> */}

                <div className="new-before-delete-ok">
                  <p onClick={this.deleteapps}>Okay</p>
                </div>
              </div>
                 </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={
            window.matchMedia("(max-width: 1200px)").matches
              ? this.state.openMobRoleDropdown
              : false
          }
          style={{ maxWidth: "700px", maxHeight: "350px" }}
          className={" profile-code-modal"}
          centered
          dataclass="countrycode"
        >
          <ModalHeader className=" ">
            <div className="d-flex align-items-center search-box justify-content-center profile-country-search">
              <div className="d-flex align-items-center w-100 ">
                <div
                  className="backaero-svgimg"
                  onClick={this.handleOpenMobRoleDropdown}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.759"
                    height="15.408"
                    viewBox="0 0 20.759 15.408"
                  >
                    <g
                      id="Group_27465"
                      data-name="Group 27465"
                      transform="translate(-19.742 -22.295)"
                    >
                      <path
                        id="Path_81245"
                        data-name="Path 81245"
                        d="M-13783.922-19217h19.346"
                        transform="translate(13805.077 19247)"
                        fill="none"
                        stroke="#7782a1"
                        stroke-width="2"
                      />
                      <path
                        id="Path_82232"
                        data-name="Path 82232"
                        d="M14053.656,19255.426l-7,7,7,7"
                        transform="translate(-14025.504 -19232.424)"
                        fill="none"
                        stroke="#7782a1"
                        stroke-width="2"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </ModalHeader>
          <ModalBody className="code-selector p-0">
            <div className="mob-popup-company-size">
              {this.state.optionsForRole.map((res, i) => (
                <div
                  className="d-flex align-items-center justify-content-between"
                  key={i}
                >
                  <div
                    className="d-flex align-items-center justify-content-start"
                    onClick={() => this.handleUserRoleChange(res)}
                    // onClick={(e) => this.handleCountryDropdown(e, res)}
                  >
                    <p className="mob-company-size">{res.value}</p>
                  </div>
                </div>
              ))}
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={
            window.matchMedia("(max-width: 1200px)").matches
              ? this.state.openMobOperateDropdown
              : false
          }
          style={{ maxWidth: "700px", maxHeight: "350px" }}
          className={" profile-code-modal"}
          centered
          dataclass="countrycode"
        >
          <ModalHeader className=" ">
            <div className="d-flex align-items-center search-box justify-content-center profile-country-search">
              <div className="d-flex align-items-center w-100 ">
                <div
                  className="backaero-svgimg"
                  onClick={this.handleOpenMobOperateDropdown}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.759"
                    height="15.408"
                    viewBox="0 0 20.759 15.408"
                  >
                    <g
                      id="Group_27465"
                      data-name="Group 27465"
                      transform="translate(-19.742 -22.295)"
                    >
                      <path
                        id="Path_81245"
                        data-name="Path 81245"
                        d="M-13783.922-19217h19.346"
                        transform="translate(13805.077 19247)"
                        fill="none"
                        stroke="#7782a1"
                        stroke-width="2"
                      />
                      <path
                        id="Path_82232"
                        data-name="Path 82232"
                        d="M14053.656,19255.426l-7,7,7,7"
                        transform="translate(-14025.504 -19232.424)"
                        fill="none"
                        stroke="#7782a1"
                        stroke-width="2"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </ModalHeader>
          <ModalBody className="code-selector p-0">
            <div className="mob-popup-company-size">
              {this.state.optionsForIndustory.map((res, i) => (
                <div
                  className="d-flex align-items-center justify-content-between"
                  key={i}
                >
                  <div
                    className="d-flex align-items-center justify-content-start"
                    onClick={() => this.handleUserIndustryChange(res)}
                    // onClick={(e) => this.handleCountryDropdown(e, res)}
                  >
                    <p className="mob-company-size">{res.value}</p>
                  </div>
                </div>
              ))}
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={
            window.matchMedia("(max-width: 1200px)").matches
              ? this.state.openMobCompanySizeDropdown
              : false
          }
          style={{ maxWidth: "700px", maxHeight: "350px" }}
          className={" profile-code-modal"}
          centered
          dataclass="countrycode"
        >
          <ModalHeader className=" ">
            <div className="d-flex align-items-center search-box justify-content-center profile-country-search">
              <div className="d-flex align-items-center w-100 ">
                <div
                  className="backaero-svgimg"
                  onClick={this.handleOpenMobCompanySizeDropdown}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.759"
                    height="15.408"
                    viewBox="0 0 20.759 15.408"
                  >
                    <g
                      id="Group_27465"
                      data-name="Group 27465"
                      transform="translate(-19.742 -22.295)"
                    >
                      <path
                        id="Path_81245"
                        data-name="Path 81245"
                        d="M-13783.922-19217h19.346"
                        transform="translate(13805.077 19247)"
                        fill="none"
                        stroke="#7782a1"
                        stroke-width="2"
                      />
                      <path
                        id="Path_82232"
                        data-name="Path 82232"
                        d="M14053.656,19255.426l-7,7,7,7"
                        transform="translate(-14025.504 -19232.424)"
                        fill="none"
                        stroke="#7782a1"
                        stroke-width="2"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </ModalHeader>
          <ModalBody className="code-selector p-0">
            <div className="mob-popup-company-size">
              {this.state.optionsForCompanySize.map((res, i) => (
                <div
                  className="d-flex align-items-center justify-content-between"
                  key={i}
                >
                  <div
                    className="d-flex align-items-center justify-content-start"
                    onClick={() => this.handleCompanySizeChange(res)}
                    // onClick={(e) => this.handleCountryDropdown(e, res)}
                  >
                    <p className="mob-company-size">{res.value}</p>
                  </div>
                </div>
              ))}
            </div>

          </ModalBody>
        </Modal>
      </>
    );
  }
}