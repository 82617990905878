import React, { useEffect, useReducer } from "react";
import Header from "../../Header";
import Leftpanel from "../../Leftpanel";
import "./mergeApps.css";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import MergeAppNewLayout from "./MergeAppNewLayout";
import MergeAppNewSettings from "./MergeAppNewSettings";
import MergeAppNewAlreadyMerged from "./MergeAppNewAlreadyMerged";
import MergeAppNewUpgradePage from "./MergeAppNewUpgradePage";
import { Applist } from "../../../Api/Multisite/Multisiteapi";
import MergeAppSidePreview from "./MergeAppSidePreview";
import { errortoast, sucesstoast } from "../../Toaster";
import MergeAppAddModal from "./MergeAppAddModal";
import moment from "moment";
import {
  useDeleteLinkedAppMutation,
  useGetLinkedAppsQuery,
  useUpdateSettingsMutation,
} from "../../../Redux/slices/mergeappSlice";
import SettingIcon from "../../../Components/commonIcons/SettingIcon";
import PremiumTag from "../../../Components/commonIcons/PremiumTag";

const MergeApps = () => {
  const {
    data: linkedAppsData = [],
    error: linkedAppsDataApiErr,
    isError: linkedAppsDataError,
    isLoading: linkedAppsDataLoading,
    isFetching: linkedAppsDataFetching,
  } = useGetLinkedAppsQuery();

  const [deleteLinkedApp, { deleteLinkedAppResult }] =
    useDeleteLinkedAppMutation();

  const [updateSettings] = useUpdateSettingsMutation();

  const [mergeState, setMergeState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      mdlunlink: false,
      enableHome: true,
      enableSetting: false,
      enableLayoutPage: false,
      bodyspinner: false,
      tableData: [],
      country_id_for_selection: null,
      country_iso: null,
      status_code: null,
      plan_code: null,
      addon_code: "",
      selected_country: [],
      removeappstate: false,
      delete_app_id: null,
      parent_app_name: "",
      multisite: null,
      is_enable_screen: 0,
      appuser_role_id: localStorage.getItem("appuser_role_id"),
      reseller_package_id: "",
      selectedLayout: "layout-0",
      updatedAt: "",
      settings: {
        title: "Make a selection",
        showOnEveryLaunch: true,
        showOnFirstLaunch: true,
        showLocations: false,
        showDistance: true,
        showAppIcon: true,
        showAppName: true,
        showWebsiteLink: true,
        showCountryFlag: false,
        showCountryName: true,
        showLanguage: true,
        locationMarkerIcon: "default",
        markerColor: "#838383",
        selectedMarkerColor: "#FF4848",
        iconFilter: 0,
      },
      savingDetails: false,
      isUnlinking: false,
      appRightPanelExpand: false,
      skipButtonActive: false,
    }
  );

  useEffect(() => {
    if (
      localStorage.getItem("agencyid") &&
      localStorage.getItem("agencyid") == 1
    ) {
      document.title = "Merge | AppMySite";
    } else {
      document.title = "Merge | " + localStorage.getItem("agencyname");
    }

    if (!linkedAppsDataLoading && !linkedAppsDataError) {
      if (linkedAppsData.code === 200) {
        if (!linkedAppsData?.data?.length) {
          setMergeState({
            is_enable_screen: linkedAppsData.is_enable_screen,
            bodyspinner: false,
            tableData: linkedAppsData.data,
            multisite: linkedAppsData.multisite,
            updatedAt: linkedAppsData?.updated_at,
            parent_app_name: linkedAppsData.multisite?.parent_app_name,
            parent_app_url: linkedAppsData.multisite?.parent_app_url,
          });
        } else {
          const settings = {
            title: linkedAppsData.data[0]?.title || "Make a selection",
            showOnEveryLaunch: Boolean(
              linkedAppsData.data[0]?.show_on_every_launch
            ),
            showOnFirstLaunch: Boolean(
              linkedAppsData.data[0]?.show_on_first_launch
            ),
            showLocations: Boolean(linkedAppsData.data[0]?.show_locations),
            showDistance: Boolean(linkedAppsData.data[0]?.show_distance),
            showAppIcon: Boolean(linkedAppsData.data[0]?.show_app_icon),
            showAppName: Boolean(linkedAppsData.data[0]?.show_app_name),
            showWebsiteLink: Boolean(linkedAppsData.data[0]?.show_site_url),
            showCountryFlag: Boolean(linkedAppsData.data[0]?.show_country_flag),
            showCountryName: Boolean(linkedAppsData.data[0]?.show_country_name),
            showLanguage: Boolean(linkedAppsData.data[0]?.show_language),
            iconFilter: linkedAppsData.data[0]?.is_black_icon,
            markerColor: linkedAppsData.data[0]?.location_marker_color_object
              ? JSON.parse(linkedAppsData.data[0]?.location_marker_color_object)
                  ?.portal_data
              : "#838383",
            selectedMarkerColor: linkedAppsData.data[0]
              ?.selected_location_marker_color_object
              ? JSON.parse(
                  linkedAppsData.data[0]?.selected_location_marker_color_object
                )?.portal_data
              : "#FF4848",
            locationMarkerIcon:
              linkedAppsData.data[0]?.location_marker_image || "default",
          };
          setMergeState({
            settings,
            selectedLayout: linkedAppsData.data[0]?.layout || "layout-0",
            updatedAt: linkedAppsData?.updated_at,
            tableData: linkedAppsData.data,
            show_site_url:
              linkedAppsData.data[0] !== undefined
                ? linkedAppsData.data[0].show_site_url
                : 0,
            show_app_icon:
              linkedAppsData.data[0] !== undefined
                ? linkedAppsData.data[0].show_app_icon
                : 0,
            show_app_name:
              linkedAppsData.data[0] !== undefined
                ? linkedAppsData.data[0].show_app_name
                : 0,
            show_country_flag:
              linkedAppsData.data[0] !== undefined
                ? linkedAppsData.data[0].show_country_flag
                : 0,
            show_country_name:
              linkedAppsData.data[0] !== undefined
                ? linkedAppsData.data[0].show_country_name
                : 0,
            parent_app_name: linkedAppsData.multisite.parent_app_name,
            parent_app_url: linkedAppsData.multisite.parent_app_url,
            bodyspinner: false,
            plan_code: linkedAppsData.app_plan,
            selected_country: linkedAppsData.selected_country,
            multisite: linkedAppsData.multisite,
            handleurl:
              linkedAppsData.data.length === 0
                ? false
                : linkedAppsData.data[0].show_site_url === 1
                ? true
                : false,
            is_enable_screen: linkedAppsData.is_enable_screen,
          });
        }
      } else if (linkedAppsData.code === 203) {
        setMergeState({
          bodyspinner: false,
          status_code: linkedAppsData.code,
          plan_code: linkedAppsData.app_plan,
          reseller_package_id: linkedAppsData.reseller_package_id,
          multisite: linkedAppsData.multisite,
          addon_code: linkedAppsData.addon_code,
        });
      }
    }
  }, [linkedAppsData]);

  const unlink = (shouldFetchApps) => {
    setMergeState({ mdlunlink: !mergeState.mdlunlink });
  };

  const handleDeleteModal = (id) => {
    setMergeState({
      removeappstate: !mergeState.removeappstate,
      delete_app_id: id,
    });
  };

  const handleDeleteLinkedApp = async () => {
    setMergeState({ isUnlinking: true });
    const appId = localStorage.getItem("Appid");

    deleteLinkedApp({
      app_id: parseInt(appId),
      id: mergeState.delete_app_id,
    })
      .unwrap()
      .then((response) => {
        if (response.code !== 200) {
          errortoast(response?.data);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setMergeState({
          removeappstate: !mergeState.removeappstate,
          delete_app_id: null,
          isUnlinking: false,
        });
      });
  };

  const handleUnlinkAppReset = () => {
    Applist(
      {
        app_id: btoa(localStorage.getItem("Appid")),
      },
      { setState: setMergeState },
      true
    );
  };

  const handleSave = () => {
    setMergeState({ savingDetails: true });
    const {
      selectedLayout,
      settings: {
        title,
        showOnEveryLaunch,
        showOnFirstLaunch,
        showLocations,
        showDistance,
        showAppIcon,
        showAppName,
        showWebsiteLink,
        showCountryFlag,
        showCountryName,
        showLanguage,
        locationMarkerIcon,
        markerColor,
        selectedMarkerColor,
        iconFilter,
      },
    } = mergeState;
    const settingsData = {
      app_id: parseInt(localStorage.getItem("Appid")),
      layout: selectedLayout,
      location_marker_color_object: markerColor,
      location_marker_image: locationMarkerIcon,
      selected_location_marker_color_object: selectedMarkerColor,
      show_distance: Number(showDistance),
      show_language: Number(showLanguage),
      show_locations: Number(showLocations),
      show_on_every_launch: Number(showOnEveryLaunch),
      show_on_first_launch: Number(showOnFirstLaunch),
      title,
      show_app_icon: Number(showAppIcon),
      show_app_name: Number(showAppName),
      show_country_flag: Number(showCountryFlag),
      show_country_name: Number(showCountryName),
      show_site_url: Number(showWebsiteLink),
      is_black_icon: iconFilter,
    };

    updateSettings(settingsData)
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          sucesstoast("Data Saved Successfully");
          setMergeState({
            updatedAt: response.data.updated_at,
          });
        } else {
          errortoast(
            response?.data[Object.keys(response.data)[0]][0] ||
              "Error updating Data"
          );
        }
      })
      .catch((err) => {})
      .finally(() => {
        setMergeState({
          savingDetails: false,
        });
      });
  };

  const handleRightPanelExpand = (value) => {
    setMergeState({
      appRightPanelExpand: value,
    });
  };

  const handleSkipClick = () => {
    setMergeState({ skipButtonActive: true });
  };

  return (
    <>
      <Header customclass={"header-responsive-new"} />
      <section className="newAdvertisment-page">
        <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
        <div
          className={`right-panel-gap ${
            localStorage.getItem("appSideBarCollapse") !== undefined &&
            localStorage.getItem("appSideBarCollapse") !== null &&
            localStorage.getItem("appSideBarCollapse") === "true"
              ? " right-panel-gap-expand"
              : ""
          }`}
        >
          <div className="newmergeapp-head">
            <div
              className="mergeapp-agencyupgrade-div"
              style={{
                gridTemplateColumns:
                  localStorage.getItem("agencyid") &&
                  localStorage.getItem("agencyid") != 1 &&
                  (mergeState.status_code === 203 || mergeState.plan_code)
                    ? ""
                    : "auto auto",
              }}
            >
              <h1>
                Merge
                <p className=" productsetting-tooltip">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                  >
                    <g
                      id="Group_35001"
                      data-name="Group 35001"
                      transform="translate(-1727 -19)"
                    >
                      <g
                        id="Ellipse_7706"
                        data-name="Ellipse 7706"
                        transform="translate(1727 19)"
                        fill="none"
                        stroke="#bcc1ce"
                        stroke-width="1.5"
                      >
                        <circle cx="9" cy="9" r="9" stroke="none" />
                        <circle cx="9" cy="9" r="8.25" fill="none" />
                      </g>
                      <line
                        id="Line_343"
                        data-name="Line 343"
                        y1="2"
                        transform="translate(1736 23)"
                        fill="none"
                        stroke="#bcc1ce"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <path
                        id="Path_98224"
                        data-name="Path 98224"
                        d="M0,2V-4"
                        transform="translate(1736 31)"
                        fill="none"
                        stroke="#bcc1ce"
                        stroke-width="1.5"
                      />
                    </g>
                  </svg>
                  <p className="productsetting-tooltipsubheader">
                    Combine multiple apps into a unified mobile app
                    effortlessly.
                  </p>
                </p>
              </h1>
              {mergeState?.skipButtonActive && <PremiumTag/> }
              {localStorage.getItem("agencyid") &&
              localStorage.getItem("agencyid") != 1 &&
              (mergeState.status_code === 203 || mergeState.plan_code) ? (
                <div className="upgrade-feature">
                  <h4 className="upgrade-unavailable">
                    This feature is currently unavailable
                  </h4>
                  <p className="upgrade-support">
                    Please contact Support for assistance.
                  </p>
                </div>
              ) : (
                mergeState.multisite &&
                !mergeState.multisite?.is_app_link_to_multisite > 0 &&
                mergeState.status_code !== 203 && (
                  <>
                    {mergeState.tableData?.length > 1 ? (
                      <div className="newmergeapp-head-right">
                        {mergeState.enableSetting ||
                        mergeState.enableLayoutPage ? (
                          <>
                            <p className="mergeapp-save-date-time">
                              {mergeState.updatedAt &&
                                "Updated " +
                                  moment(mergeState.updatedAt).format(
                                    "D MMMM YYYY, HH:mm"
                                  )}
                            </p>
                            <div
                              onClick={() =>
                                setMergeState({
                                  enableHome: true,
                                  enableLayoutPage: false,
                                  enableSetting: false,
                                })
                              }
                              className={`${
                                mergeState.enableHome
                                  ? "consent-setting-clicked"
                                  : ""
                              } consent-setting`}
                              style={{ marginRight: "20px" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="26"
                                viewBox="0 0 26 26"
                              >
                                <g
                                  id="Group_42975"
                                  data-name="Group 42975"
                                  transform="translate(-20 -527)"
                                >
                                  <rect
                                    id="Rectangle_25572"
                                    data-name="Rectangle 25572"
                                    width="26"
                                    height="26"
                                    transform="translate(20 527)"
                                    fill="none"
                                  />
                                  <path
                                    className={
                                      mergeState.enableHome
                                        ? "select-setting"
                                        : "notselect-setting"
                                    }
                                    id="Path_119115"
                                    data-name="Path 119115"
                                    d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z"
                                    transform="translate(22.5 529.36)"
                                    fill="#7782a1"
                                    stroke="#7782a1"
                                    stroke-miterlimit="10"
                                    stroke-width="1.5"
                                  />
                                </g>
                              </svg>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div
                          onClick={() =>
                            mergeState.enableLayoutPage
                              ? setMergeState({
                                  enableLayoutPage: false,
                                  enableHome: true,
                                  enableSetting: false,
                                })
                              : setMergeState({
                                  enableLayoutPage: true,
                                  enableHome: false,
                                  enableSetting: false,
                                })
                          }
                          className={`${
                            mergeState.enableLayoutPage
                              ? "consent-setting-clicked"
                              : ""
                          } consent-setting`}
                          style={{ marginRight: "20px" }}
                        >
                          {!mergeState.enableLayoutPage && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xlink="http://www.w3.org/1999/xlink"
                              width="26"
                              height="26"
                              viewBox="0 0 26 26"
                            >
                              <defs>
                                <clipPath id="clip-pat">
                                  <rect
                                    id="Rectangle_29591"
                                    data-name="Rectangle 29591"
                                    width="26"
                                    height="26"
                                    transform="translate(62 1)"
                                    fill="rgba(0,0,0,0)"
                                  />
                                </clipPath>
                              </defs>
                              <g
                                id="Mask_Group_25287"
                                data-name="Mask Group 25287"
                                transform="translate(-62 -1)"
                                clip-path="url(#clip-pat)"
                              >
                                <g
                                  id="Group_39235"
                                  data-name="Group 39235"
                                  transform="translate(63.887 2.887)"
                                >
                                  <rect
                                    id="Rectangle_29590"
                                    data-name="Rectangle 29590"
                                    width="16"
                                    height="22"
                                    rx="1.5"
                                    transform="translate(3.113 0.113)"
                                    fill="none"
                                    stroke="#7782a1"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                  />
                                  <path
                                    id="Path_119244"
                                    data-name="Path 119244"
                                    d="M11.113,5.113h6"
                                    transform="translate(-1)"
                                    fill="none"
                                    stroke="#7782a1"
                                    stroke-width="1.5"
                                  />
                                  <path
                                    id="Path_119245"
                                    data-name="Path 119245"
                                    d="M11.113,5.113h6"
                                    transform="translate(-1 6)"
                                    fill="none"
                                    stroke="#7782a1"
                                    stroke-width="1.5"
                                  />
                                  <path
                                    id="Path_119246"
                                    data-name="Path 119246"
                                    d="M11.113,5.113h6"
                                    transform="translate(-1 12)"
                                    fill="none"
                                    stroke="#7782a1"
                                    stroke-width="1.5"
                                  />
                                  <g
                                    id="Group_43104"
                                    data-name="Group 43104"
                                    transform="translate(6.113 4.363)"
                                  >
                                    <path
                                      id="Path_119244-2"
                                      data-name="Path 119244"
                                      d="M11.113,5.113h2"
                                      transform="translate(-11.113 -4.363)"
                                      fill="none"
                                      stroke="#7782a1"
                                      stroke-width="1.5"
                                    />
                                    <path
                                      id="Path_119245-2"
                                      data-name="Path 119245"
                                      d="M11.113,5.113h2"
                                      transform="translate(-11.113 1.637)"
                                      fill="none"
                                      stroke="#7782a1"
                                      stroke-width="1.5"
                                    />
                                    <path
                                      id="Path_119246-2"
                                      data-name="Path 119246"
                                      d="M11.113,5.113h2"
                                      transform="translate(-11.113 7.637)"
                                      fill="none"
                                      stroke="#7782a1"
                                      stroke-width="1.5"
                                    />
                                  </g>
                                </g>
                              </g>
                            </svg>
                          )}
                          {mergeState.enableLayoutPage && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xlink="http://www.w3.org/1999/xlink"
                              width="26"
                              height="26"
                              viewBox="0 0 26 26"
                            >
                              <defs>
                                <clipPath id="clip-pathq">
                                  <rect
                                    id="Rectangle_32448"
                                    data-name="Rectangle 32448"
                                    width="26"
                                    height="26"
                                    transform="translate(62 1)"
                                    fill="rgba(0,0,0,0)"
                                  />
                                </clipPath>
                              </defs>
                              <g
                                id="Mask_Group_34872"
                                data-name="Mask Group 34872"
                                transform="translate(-62 -1)"
                                clip-path="url(#clip-pathq)"
                              >
                                <g
                                  id="Group_43107"
                                  data-name="Group 43107"
                                  transform="translate(63.887 2.887)"
                                >
                                  <g
                                    id="Rectangle_32447"
                                    data-name="Rectangle 32447"
                                    transform="translate(3.113 0.113)"
                                    fill="#7782a1"
                                    stroke="#7782a1"
                                    stroke-linejoin="round"
                                    stroke-width="1"
                                  >
                                    <rect
                                      width="16"
                                      height="22"
                                      rx="1.5"
                                      stroke="none"
                                    />
                                    <rect
                                      x="-0.5"
                                      y="-0.5"
                                      width="17"
                                      height="23"
                                      rx="2"
                                      fill="none"
                                    />
                                  </g>
                                  <path
                                    id="Union_3151"
                                    data-name="Union 3151"
                                    d="M15359,21365v-2h6v2Zm-4,0v-2h2v2Zm4-6v-2h6v2Zm-4,0v-2h2v2Zm4-6v-2h6v2Zm-4,0v-2h2v2Z"
                                    transform="translate(-15348.887 -21346.887)"
                                    fill="#fff"
                                  />
                                </g>
                              </g>
                            </svg>
                          )}
                        </div>
                        {/* <div
                          onClick={() =>
                            mergeState.enableSetting
                              ? setMergeState({
                                  enableSetting: false,
                                  enableLayoutPage: false,
                                  enableHome: true,
                                })
                              : setMergeState({
                                  enableSetting: true,
                                  enableLayoutPage: false,
                                  enableHome: false,
                                })
                          }
                          className={`${
                            mergeState.enableSetting
                              ? "consent-setting-clicked"
                              : ""
                          } consent-setting`}
                          style={{ marginRight: "0px" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="26"
                            viewBox="0 0 26 26"
                          >
                            <g
                              id="Group_38340"
                              data-name="Group 38340"
                              transform="translate(-20 -527)"
                            >
                              <rect
                                id="Rectangle_25572"
                                data-name="Rectangle 25572"
                                width="26"
                                height="26"
                                transform="translate(20 527)"
                                fill="none"
                              />
                              <path
                                className={
                                  mergeState.enableSetting
                                    ? "select-setting"
                                    : "notselect-setting"
                                }
                                id="Path_99646"
                                data-name="Path 99646"
                                d="M11.513,22.5a10.829,10.829,0,0,0,1.266-.074.615.615,0,0,0,.542-.642,2.437,2.437,0,0,1,1.488-2.388,2.278,2.278,0,0,1,.887-.171,2.527,2.527,0,0,1,1.855.788.616.616,0,0,0,.837.067,10.968,10.968,0,0,0,1.739-1.76.615.615,0,0,0-.066-.829,2.432,2.432,0,0,1,1.592-4.231h.056a.73.73,0,0,0,.72-.542,11,11,0,0,0,0-2.5.592.592,0,0,0-.613-.54h-.022A2.543,2.543,0,0,1,19.4,8.19a2.437,2.437,0,0,1,.613-2.744.614.614,0,0,0,.066-.836A10.919,10.919,0,0,0,18.3,2.851a.606.606,0,0,0-.377-.131.615.615,0,0,0-.454.2,2.532,2.532,0,0,1-1.855.785,2.259,2.259,0,0,1-.868-.165A2.449,2.449,0,0,1,13.238,1.2a.614.614,0,0,0-.544-.63,11.023,11.023,0,0,0-2.474,0,.615.615,0,0,0-.539.643A2.443,2.443,0,0,1,8.195,3.6a2.318,2.318,0,0,1-.89.172,2.524,2.524,0,0,1-1.853-.788.615.615,0,0,0-.837-.069,10.968,10.968,0,0,0-1.739,1.76.615.615,0,0,0,.066.829A2.43,2.43,0,0,1,1.279,9.733a.718.718,0,0,0-.708.54,11.037,11.037,0,0,0,0,2.5.617.617,0,0,0,.6.542h.181A2.464,2.464,0,0,1,3.6,14.808a2.438,2.438,0,0,1-.615,2.743.614.614,0,0,0-.066.837A11,11,0,0,0,4.7,20.145a.616.616,0,0,0,.83-.069,2.535,2.535,0,0,1,1.853-.785,2.3,2.3,0,0,1,.87.165A2.443,2.443,0,0,1,9.76,21.8a.614.614,0,0,0,.545.628A11.109,11.109,0,0,0,11.513,22.5Z"
                                transform="translate(21.501 529.151)"
                                fill=""
                                stroke="#7782a1"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                              />
                              <path
                                id="Path_99647"
                                data-name="Path 99647"
                                d="M9.818,5.846a4,4,0,1,1-4,4A4,4,0,0,1,9.818,5.846Z"
                                transform="translate(23.181 530.804)"
                                fill="#ffff"
                                stroke="#7782A1"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                              />
                            </g>
                          </svg>
                        </div> */}

                        <div
                          onClick={() =>
                            mergeState.enableSetting
                              ? setMergeState({
                                  enableSetting: false,
                                  enableLayoutPage: false,
                                  enableHome: true,
                                })
                              : setMergeState({
                                  enableSetting: true,
                                  enableLayoutPage: false,
                                  enableHome: false,
                                })
                          }
                        >
                          <SettingIcon isActive={mergeState.enableSetting} />
                        </div>
                        {mergeState.enableSetting ||
                        mergeState.enableLayoutPage ? (
                          <>
                            {mergeState.savingDetails ? (
                              <button className="newmergeapp-btn-disable newmergeapp-btn-enable d-flex justify-content-center align-items-center">
                                <div className="new-loader "></div>
                              </button>
                            ) : (
                              <button
                                onClick={() => handleSave()}
                                className={"newmergeapp-btn-enable"}
                              >
                                Save
                              </button>
                            )}
                          </>
                        ) : null}
                      </div>
                    ) : null}
                  </>
                )
              )}
            </div>
          </div>
          {mergeState.multisite &&
          mergeState.multisite?.is_app_link_to_multisite > 0 ? (
            <MergeAppNewAlreadyMerged
              multisite={mergeState?.multisite}
              handleUnlinkAppReset={handleUnlinkAppReset}
            />
          ) : (
            <div className="mergeapp-all-pages">
              {mergeState.enableHome && (
                <>
                  <div className="newmergeapp-maindiv">
                    {linkedAppsDataLoading || linkedAppsDataFetching ? (
                      <div className="newspinner">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xlink="http://www.w3.org/1999/xlink"
                          style={{
                            margin: "auto",
                            background: "rgb(241, 242, 243,0)",
                            display: "block",
                            shaperendering: "auto",
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                          width="200px"
                          height="200px"
                          viewBox="0 0 100 100"
                          preserveAspectRatio="xMidYMid"
                        >
                          <g
                            transform="translate(20 50)"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#e6261f"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="-0.375s"
                                calcMode="spline"
                                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                values="0;1;0"
                                keyTimes="0;0.5;1"
                                dur="1s"
                                repeatCount="indefinite"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              ></animateTransform>
                            </circle>
                          </g>
                          <g
                            transform="translate(40 50)"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#f7d038"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="-0.25s"
                                calcMode="spline"
                                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                values="0;1;0"
                                keyTimes="0;0.5;1"
                                dur="1s"
                                repeatCount="indefinite"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              ></animateTransform>
                            </circle>
                          </g>
                          <g
                            transform="translate(60 50)"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#49da9a"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="-0.125s"
                                calcMode="spline"
                                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                values="0;1;0"
                                keyTimes="0;0.5;1"
                                dur="1s"
                                repeatCount="indefinite"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              ></animateTransform>
                            </circle>
                          </g>
                          <g
                            transform="translate(80 50)"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#4355db"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="0s"
                                calcMode="spline"
                                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                values="0;1;0"
                                keyTimes="0;0.5;1"
                                dur="1s"
                                repeatCount="indefinite"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              ></animateTransform>
                            </circle>
                          </g>
                        </svg>
                      </div>
                    ) : mergeState.status_code === 203 &&
                      !mergeState.skipButtonActive ? (
                      <MergeAppNewUpgradePage
                        mergeState={mergeState}
                        handleSkipClick={handleSkipClick}
                      />
                    ) : mergeState.is_enable_screen !== 0 ||
                      mergeState.skipButtonActive ? (
                      <>
                        {/* Table Data */}
                        {mergeState.tableData &&
                        mergeState.tableData?.length > 1 ? (
                          <div className="newmergeapp-listing-div ">
                            {/* Merged app list */}
                            <div className="newmergeapp-list-left ">
                              <div className="newmergeapp-list-left-head">
                                <p className="newmergeapp-list-left-first">
                                  App
                                </p>

                                <p className="newmergeapp-list-left-last"></p>
                              </div>
                              {[
                                mergeState.tableData.find(
                                  (item) =>
                                    parseInt(localStorage.getItem("Appid")) ===
                                    item.child_app_id
                                ),
                                ...mergeState.tableData.filter(
                                  (item) =>
                                    parseInt(localStorage.getItem("Appid")) !==
                                    item.child_app_id
                                ),
                              ].map(
                                (elem, idx) =>
                                  elem && (
                                    <div
                                      className="merge-app-list-div"
                                      key={`mergeapp_${idx}`}
                                    >
                                      <div className="merge-app-apptype">
                                        <img
                                          className="merge-app-appicon"
                                          src={
                                            process.env.REACT_APP_Image_Path +
                                            elem.user_id +
                                            "/" +
                                            elem.child_app_id +
                                            process.env
                                              .REACT_APP_AppIcon_Display +
                                            elem.image
                                          }
                                          onError={({ currentTarget }) => {
                                            currentTarget.src =
                                              require("../../../assets/images/dashboard/the-icon.png").default;
                                          }}
                                          alt={"merge-app-appicon-" + idx}
                                        ></img>
                                        <div className="marge-app-nameId">
                                          <h3>
                                            {elem.app_name}{" "}
                                            {idx === 0 && <span>Default</span>}
                                          </h3>
                                          <p>ID: {elem.child_app_id}</p>
                                        </div>
                                      </div>

                                      <div className="delete-merge-app">
                                        {parseInt(
                                          localStorage.getItem("Appid")
                                        ) === elem.child_app_id ? (
                                          mergeState.tableData.length === 1 ? (
                                            <img
                                              src={
                                                require("../../../assets/images/dashboard/delete-icon.png")
                                                  .default
                                              }
                                              alt="delete app"
                                              className="img-fluid ml-2"
                                              onClick={(e) =>
                                                handleDeleteModal(elem.id)
                                              }
                                            />
                                          ) : null
                                        ) : (
                                          <img
                                            src={
                                              require("../../../assets/images/dashboard/delete-icon.png")
                                                .default
                                            }
                                            alt="delete app"
                                            className="img-fluid ml-2"
                                            onClick={(e) =>
                                              handleDeleteModal(elem.id)
                                            }
                                          />
                                        )}
                                      </div>
                                    </div>
                                  )
                              )}
                              <div className="add-apps-btn">
                                <div
                                  onClick={() =>
                                    setMergeState({
                                      mdlunlink: !mergeState.mdlunlink,
                                    })
                                  }
                                >
                                  <img
                                    src={
                                      require("../../../assets/images/dashboard/plus.png")
                                        .default
                                    }
                                    alt="publishUpgrade"
                                  />
                                  Add apps
                                </div>
                              </div>
                            </div>
                            <div className="newmergeapp-list-preview ">
                              <MergeAppSidePreview mergeState={mergeState} />
                            </div>
                          </div>
                        ) : (
                          <div className="nomerge-listed  ">
                            <img
                              src={
                                require("../../../assets/images/dashboard/nomergeappfound.png")
                                  .default
                              }
                              alt="publishUpgrade-ic"
                            />
                            <p>Nothing here at the moment. Come back later?</p>
                            <button
                              onClick={() =>
                                setMergeState({
                                  mdlunlink: !mergeState.mdlunlink,
                                })
                              }
                            >
                              <img
                                src={
                                  require("../../../assets/images/dashboard/cms/plus.png")
                                    .default
                                }
                                alt="publishUpgrade-ic"
                              />
                              Merge apps
                            </button>
                          </div>
                        )}
                        {/* Table Data End*/}
                      </>
                    ) : (
                      mergeState.plan_code && (
                        <MergeAppNewUpgradePage mergeState={mergeState} />
                      )
                    )}
                  </div>
                </>
              )}

              {/* Mergeapp layout */}
              {mergeState.enableLayoutPage && (
                <MergeAppNewLayout
                  selectedLayout={mergeState.selectedLayout}
                  setMergeState={setMergeState}
                />
              )}

              {/* Mergeapp setting */}
              {mergeState.enableSetting && (
                <div className="mergeapp-setting-maindiv">
                  <MergeAppNewSettings
                    settings={mergeState.settings}
                    setMergeState={setMergeState}
                  />
                  <div className="mergeapp-setting-right">
                    <MergeAppSidePreview mergeState={mergeState} />
                  </div>
                </div>
              )}

              {mergeState.multisite &&
              !mergeState.multisite?.is_app_link_to_multisite > 0 &&
              mergeState.status_code !== 203 &&
              mergeState.tableData.length > 1 ? (
                <div className="mergeapp-mob-save-button-div">
                  {mergeState.enableSetting || mergeState.enableLayoutPage ? (
                    <>
                      {mergeState.savingDetails ? (
                        <button className="newmergeapp-btn-disable newmergeapp-btn-enable d-flex justify-content-center align-items-center">
                          <div className="new-loader "></div>
                        </button>
                      ) : (
                        <button
                          onClick={() => handleSave()}
                          className={"newmergeapp-btn-enable"}
                        >
                          Save
                        </button>
                      )}
                      <p>
                        {mergeState.updatedAt &&
                          "Updated " +
                            moment(mergeState.updatedAt).format(
                              "D MMMM YYYY, HH:mm"
                            )}
                      </p>
                    </>
                  ) : null}
                </div>
              ) : (
                mergeState.enableSetting && (
                  <div className="mergeapp-mob-save-button-div">
                    {mergeState.savingDetails ? (
                      <button className="newmergeapp-btn-disable newmergeapp-btn-enable d-flex justify-content-center align-items-center">
                        <div className="new-loader "></div>
                      </button>
                    ) : (
                      <button
                        onClick={() => handleSave()}
                        className={"newmergeapp-btn-enable"}
                      >
                        Save
                      </button>
                    )}
                    <p>
                      {mergeState.updatedAt &&
                        "Updated " +
                          moment(mergeState.updatedAt).format(
                            "D MMMM YYYY, HH:mm"
                          )}
                    </p>
                  </div>
                )
              )}
            </div>
          )}

          {mergeState.mdlunlink && (
            <MergeAppAddModal mergeState={mergeState} unlink={unlink} />
          )}
          <Modal
            className="notification-conf-secondmodal"
            style={{ maxWidth: "480px", maxHeight: "280px" }}
            isOpen={mergeState.removeappstate}
            toggle={() =>
              setMergeState({ removeappstate: !mergeState.removeappstate })
            }
            centered
          >
            <ModalHeader>
              <div
                className="notification-conf-secondmodal-crossmodal"
                style={{ zIndex: "9" }}
              >
                <img
                  onClick={() =>
                    setMergeState({
                      removeappstate: !mergeState.removeappstate,
                    })
                  }
                  src={
                    require("../../../assets/images/dashboard/cross.png")
                      .default
                  }
                  alt="cross ic"
                />
              </div>
            </ModalHeader>

            <ModalBody>
              <div className="notification-conf-popup-maindiv">
                <div className="notification-conf-popup-head">
                  <h2>Unlink this app?</h2>
                </div>

                <p>
                  Are you sure you want to unlink this app from your merged app?
                  Once unlinked, the app will no longer be accessible within the
                  merged app and will have to be published separately.{" "}
                </p>

                <div className="notification-conf-popup-bottom">
                  <button
                    className="notification-conf-popup-cancel"
                    onClick={() =>
                      setMergeState({
                        removeappstate: !mergeState.removeappstate,
                      })
                    }
                  >
                    Cancel
                  </button>
                  {mergeState.isUnlinking ? (
                    <div className="mergeapp-conf-popup-delete d-flex justify-content-center align-items-center">
                      <div className="new-red-loader"></div>
                    </div>
                  ) : (
                    <button
                      className="mergeapp-conf-popup-delete"
                      onClick={handleDeleteLinkedApp}
                    >
                      Unlink
                    </button>
                  )}
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </section>
    </>
  );
};

export default MergeApps;
