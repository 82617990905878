import React from "react";

import { Media, Spinner } from "reactstrap";
import Leftpanel from "../Leftpanel";
import Header from "../Header";
import Tooltip from "../Tooltip";
import styles from "./build.module.css";
import Gotosvg from "./Gotosvg.jsx";

import {
  BuildappApi,
  androidbuilingapp,
  androidpushcertificatefunction,
  iosAndroidBuildReset,
} from "../../Api/Dashboard/DashboardApi";
import { getiosformdata, jksfile } from "../../Api/Custombundle/Custombundle";
import Errormessage from "../Reusable/Errormessage";
import { InnerFoot } from "../Foot";
const Url = process.env.REACT_APP_Image_Path;
const altUrl = process.env.REACT_APP_Image_Path;
const attrUrl = process.env.REACT_APP_android_signing_files;
const titleUrl = process.env.REACT_APP_firebase;

const Topsection = () => {
  return (
    <div className="d-md-flex d-none align-items-start justify-content-between mb-3 ipad-margin-top">
      <div className="dasboard-page-title">
        <div className="app_iconname">
          <div className="d-flex align-items-center">
            <h3 className="mt-2">Android Build Configuration</h3>
          </div>
          <h5 className="mt-2">
            Configure your Android app build. Changes made will reflect once
            your build is generated.{" "}
            {/* <Media
              href="https://www.appmysite.com/support/category/download-app/download-android-app/android-build-configuration/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More{" "}
              <img
                src={require("../../assets/images/dashboard/Goto.png").default}
                className="gotoappicon appear-spacing"
                alt="Goto"
              />
            </Media> */}
            {" "}
          </h5>
        </div>
      </div>
    </div>
  );
};
export default class BuildAndroid extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      subscription: localStorage.getItem("subscriptionactive"),
      urlvar: "",
      spinner: true,
      modalspinner: true,
      errormodal: false,
      title: "",
      errormessage: "",
      android_pushtoggle: true,
      google_enable: false,
      androidgoogle_enable: true,
      //Form Data
      submitval: "Build App",
      submitval2: "Save",
      submitval3: "Reset",
      allowBuildBtn: true,
      allowSaveBtn: true,
      allowResetBtn: true,
      loader2: "d-none",
      loaderimage2: require("../../assets/images/signup/Loader_GIF.gif")
        .default,
      loader3: "d-none",
      loaderimage3: require("../../assets/images/signup/Loader_GIF.gif")
        .default,
      disabledsubmitbutton: false,
      appid: "",
      userid: "",
      // sha_copy:"Copy",
      //android
      androidserverkey: "",
      androidpushnotificationfilename: "",
      androidpushcertificate: "",
      androidpushnotifications3name: "",
      androidsubmitval: "Build App",
      androidloader: "d-none",
      androidloaderimage: require("../../assets/images/signup/Loader_GIF.gif")
        .default,
      androiddisabledsubmitbutton: false,

      bundle_android_identifier: "",
      original_json_file: "",
      //android custom build filed
      jks_file: "",
      jks_filename: ".jks file supported",
      getjksfilenameAWS: "",
      keystore_alias: "",
      keystore_password: "",
      keystore_private_key_password: "",
      android_custom_status: 0,
      is_beta_release: true,
      //admob
      admob_app_id: "",
      is_admob_enabled: true,
      ios_admob_app_id: "",
      ios_is_admob_enabled: false,
      sha_spinner: false,
      in_process_sha: null,
      sha1: null,
      build_process: null,
      //permission
      is_enable_location_permission: false,
      is_enable_camera_permission: false,
      is_enable_calender_permission: false,
      is_enable_storage_permission: false,
      is_enable_microphone_permission: false,
      is_enable_contacts_permission: false,
      //Redirect to parent page
      app_data: null,
      chat_preferences: null,
      is_app_already_published: "",
      is_social_login_enabled: "",
      website_tech: parseInt(localStorage.getItem("website_technology")),
      appRightPanelExpand: localStorage.getItem("appSideBarCollapse") === true ||
			localStorage.getItem("appSideBarCollapse") === "true"
			  ? true
			  : false,
		androidsenderid: "",
    };
  }
  interval;

  waitfunction = () => {
    clearInterval(this.interval);

    const androidparams = {
      type: "android",
      for_sha: true,
    };
    this.interval = setInterval(() => {
      this.state.in_process_sha !== 0 &&
        getiosformdata(androidparams, this, () => {});
    }, 30000);
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    const androidparams = {
      type: "android",
      for_sha: false,
    };
    
    getiosformdata(androidparams, this, () => {});
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  //admob handling
  handleis_admob_enabled = (e) => {
    this.setState({
      is_admob_enabled: e.target.checked,
    });
  };

  handleadmob_app_id = (e) => {
    this.setState({
      admob_app_id: e.target.value,
    });
  };

  finalbuild = () => {
    const buildparams = {
      app_id: localStorage.getItem("Appid"),
      platform: "android",
      is_beta_release: this.state.is_beta_release === true ? 1 : 0,
    };
    BuildappApi(buildparams, this);
  };

  handleandroidserver_key = (e) => {
    this.setState({
      androidserverkey: e.target.value,
    });
  };
	
	handleandroidsender_id = (e) => {
		this.setState({
		androidsenderid: e.target.value,
		});
  	};

  handleandroidpushnotification = (e) => {
    let self = this;
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file !== undefined) {
      if (file.name.indexOf("json") !== -1) {
        this.setState({
          androidpushnotificationfilename: file.name
            .split(".")
            .slice(0, -1)
            .join("."),
        });
        reader.onload = function (upload) {
          self.setState(
            {
              androidpushcertificate: upload.target.result,
            },
            () => {
              self.androidformpushnotification();
            }
          );
        };
        reader.readAsDataURL(file);
      } else {
        this.setState({
          errormodal: true,
          title: "File",
          errormessage: "File format not supported. Upload json file only.",
        });
      }
    }
  };

  handleandroidpushnotificationtoggle = (e) => {
    this.setState({
      android_pushtoggle: e.target.checked,
    });
  };

  handleandroidgoogle_enable = (e) => {
    this.setState({
      androidgoogle_enable: e.target.checked,
    });
  };

  androidformpushnotification = () => {
    this.setState({
      androiddisabledsubmitbutton: true,
    });
    const params = {
      original_path:
        localStorage.getItem("user_id") +
        "/" +
        localStorage.getItem("Appid") +
        "/firebase",
      original: this.state.androidpushcertificate,
      imgname: this.state.androidpushnotificationfilename,
    };
    androidpushcertificatefunction(params, this, () => {});
  };

  androidUploadcertificatetoS3 = (scenerio, checker) => {
    const androiddata = {
      app_id: parseInt(localStorage.getItem("Appid")),
      notification_config_file: this.state.androidpushnotifications3name,
      is_notification_enabled: this.state.android_pushtoggle === true ? 1 : 0,
      is_google_login_enabled: this.state.androidgoogle_enable === true ? 1 : 0,
      notification_key: this.state.androidserverkey,
      jks_file:
        this.state.android_custom_status === 0
          ? ""
          : this.state.getjksfilenameAWS,
      keystore_alias:
        this.state.android_custom_status === 0 ? "" : this.state.keystore_alias,
      keystore_password:
        this.state.android_custom_status === 0
          ? ""
          : this.state.keystore_password,
      keystore_private_key_password:
        this.state.android_custom_status === 0
          ? ""
          : this.state.keystore_private_key_password,
      is_admob_enabled: this.state.is_admob_enabled === true ? 1 : 0,
      admob_app_id: this.state.admob_app_id,
      //Permission
      instant_generate_signing_files: scenerio === "sha" ? 1 : 0,
      is_enable_location_permission:
        this.state.is_enable_location_permission === true ? 1 : 0,
      is_enable_camera_permission:
        this.state.is_enable_camera_permission === true ? 1 : 0,
      is_enable_calender_permission:
        this.state.is_enable_calender_permission === true ? 1 : 0,
      is_enable_storage_permission:
        this.state.is_enable_storage_permission === true ? 1 : 0,
      is_enable_microphone_permission:
        this.state.is_enable_microphone_permission === true ? 1 : 0,
      is_enable_contacts_permission:
        this.state.is_enable_contacts_permission === true ? 1 : 0,
		gcm_sender_id: this.state.androidsenderid,
    };

    if (this.state.allowBuildBtn === true || checker === 2) {
      androidbuilingapp(androiddata, this, checker);
    }
  };

  // only for custom build

  handlecheck = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handlejks_file = (e) => {
    let self = this;
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file !== undefined) {
      if (file.name.indexOf("jks") !== -1) {
        this.setState({
          jks_filename: file.name.split(".").slice(0, -1).join("."),
        });
        reader.onload = function (upload) {
          self.setState(
            {
              jks_file: upload.target.result,
            },
            () => {
              self.updatetoaws();
            }
          );
        };
        reader.readAsDataURL(file);
      } else {
        this.setState({
          errormodal: true,
          title: "File",
          errormessage: "File format not supported. Upload jks file only.",
        });
      }
    }
  };

  updatetoaws = () => {
    const awsparams = {
      original_path:
        localStorage.getItem("user_id") +
        "/" +
        localStorage.getItem("Appid") +
        "/apk/signing_files/",
      original: this.state.jks_file,
      imgname: this.state.jks_filename,
    };
    jksfile(awsparams, this);
  };

  handlepermission = (e) => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked,
    });
  };
  // only for custom build end
  androidsubmit = (e, checker) => {
    e.preventDefault();

    if (checker === undefined && this.state.allowBuildBtn === true) {
      this.setState({
        allowSaveBtn: false,
      });
    }
    if (checker === 2) {
      this.setState({
        allowBuildBtn: false,
      });
    }

    if (
      this.state.android_pushtoggle === true &&
      this.state.androidpushnotifications3name === ""
    ) {
      this.setState({
        errormodal: true,
        title: "Firebase Configuration",
        errormessage: "Please upload Google Firebase Configuration",
      });
    } else if (
      this.state.android_custom_status === 1 &&
      this.state.is_app_already_published === 1 &&
      this.state.keystore_alias.trim() === ""
    ) {
      this.setState({
        errormodal: true,
        title: "Keystore Alias",
        errormessage: "Please enter Keystore Alias",
      });
    } else if (
      this.state.android_custom_status === 1 &&
      this.state.is_app_already_published === 1 &&
      this.state.keystore_private_key_password === ""
    ) {
      this.setState({
        errormodal: true,
        title: "Keystore Password",
        errormessage: "Please enter Keystore password",
      });
    } else if (
      this.state.android_custom_status === 1 &&
      this.state.is_app_already_published === 1 &&
      this.state.keystore_password === ""
    ) {
      this.setState({
        errormodal: true,
        title: "KeyStore Private Key Password",
        errormessage: "Please enter KeyStore Private Key Password",
      });
    } else if (
      this.state.android_custom_status === 1 &&
      this.state.is_app_already_published === 1 &&
      this.state.jks_file === ""
    ) {
      this.setState({
        errormodal: true,
        title: "Keystore File",
        errormessage: "Please upload Keystore file",
      });
    } else if (
      this.state.is_admob_enabled === true &&
      this.state.admob_app_id.trim() === ""
    ) {
      this.setState({
        errormodal: true,
        title: "Admob App ID",
        errormessage: "Please enter Admob App ID",
      });
    } else if (
      this.state.android_pushtoggle === true &&
      this.state.androidserverkey === ""
    ) {
      this.setState({
        errormodal: true,
        title: "Firebase Server Key",
        errormessage: "Please enter Google Firebase Server Key",
      });
    } else if (
      this.state.androidgoogle_enable === true &&
      this.state.androidpushnotifications3name === ""
    ) {
      this.setState({
        errormodal: true,
        title: "Firebase Configuration",
        errormessage: "Please upload Google Firebase Configuration",
      });
    } else if (
      this.state.android_custom_status === 1 &&
      this.state.is_app_already_published === 1 &&
      this.state.getjksfilenameAWS === ""
    ) {
      this.setState({
        errormodal: true,
        title: "Keystore File",
        errormessage: "Please upload Keystore File",
      });
    } else {
      if (checker === 2) {
        this.setState({
          submitval2: "",
          loader2: "d-block",
        });
      } else if (checker === undefined && this.state.allowBuildBtn === true) {
        this.setState({
          androidsubmitval: "",
          androidloader: "d-block",
        });
      }
      this.setState({
        allowResetBtn: false,
      });
      this.androidUploadcertificatetoS3("build", checker);
    }
  };
  //sha
  handleshakey = (e) => {
    e.preventDefault();
    this.setState({
      sha_spinner: true,
      in_process_sha: null,
      sha: "",
      androiddisabledsubmitbutton: true,
    });
    // Same function has been used for build creation
    this.androidUploadcertificatetoS3("sha");
  };
  handleselection = (id) => {
    var copyText = document.getElementById(id);
    var textArea = document.createElement("textarea");
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
    // if(id === "copy_sha"){
    //   this.setState({
    //     sha_copy: "Copied",
    //     });
    // }
  };
  handleis_beta_release = (e) => {
    this.setState({
      is_beta_release: e.target.checked,
    });
  };
  errormodal = () => {
    this.setState({
      errormodal: !this.state.errormodal,
    });
  };

  handleSave = (e) => {
    if (this.state.allowSaveBtn === true) {
      this.androidsubmit(e, 2);
    }
  };

  handleReset = (e) => {
    // this.setState({
    //   submitval3: "",
    //   loader3: "d-block",
    // });
    const param = {
      app_id: parseInt(localStorage.getItem("Appid")),
      type: "android",
    };

    if (this.state.allowResetBtn === true) {
      this.setState({
        submitval3: "",
        loader3: "d-block",
        allowSaveBtn: false,
        allowBuildBtn: false,
      });
      iosAndroidBuildReset(param, this);
    }
  };


  handleRightPanelExpand = (value) => {

    this.setState({
      appRightPanelExpand: value
    })
    }

  render() {
    if (this.state.app_data !== null) {
      if (
        (this.state.build_process === 1 || 
          // || this.state.app_data.dashboard_screen_count === 0 ||
          this.state.app_data.multisite.is_app_link_to_multisite === 1 ||
          this.state.app_data.user_status !== 1 ||
          this.state.app_data.plan === "preview") &&
        (parseInt(localStorage.getItem("website_technology")) === 4 || parseInt(localStorage.getItem("website_technology")) === 5)
      ) 
      {
        this.props.history.push("/download-app");
      }
      
      // if (parseInt(localStorage.getItem("website_technology")) === 4) 
      // {
      //   if (this.state.app_data.is_verified === 0) 
      //   {
      //     this.props.history.push("/download-app");
      //   }
      // }

      // if(parseInt(localStorage.getItem("website_technology")) === 5) 
      // {
      //   if (this.state.app_data.is_verified === 0 || this.state.app_data.is_connected !== 1) 
      //   {
      //     this.props.history.push("/download-app");
      //   }
      // }
    }

    
    return (
      <>
        {this.state.errormodal === true && <Errormessage value={this} />}
        <div className="d-md-block">
          <Header customclass={"header-responsive-new"} />
        </div>
        <section className="dasboard_page mobile-display mobile-view-download">
        <Leftpanel handleRightPanelExpand={this.handleRightPanelExpand} />
          {/* <div className="transparent_background"> */}

          <div className={`right-panel-gap ${(localStorage.getItem("appSideBarCollapse") !== undefined &&
                      localStorage.getItem("appSideBarCollapse") !== null&&
                      localStorage.getItem("appSideBarCollapse") === "true") ?
                          " right-panel-gap-expand": ""}`}>


            <div className="right_panel custon_gap2  pt-md-4 pt-0 pb-0">
              <div className="right_general">
                <div
                  className="d-flex justify-content-between pb-md-3 mobile-border-android"
                  style={{ borderBottom: "1px solid #ced4da" }}
                >
                  <Topsection />
                  <div className="d-flex">
                    {this.state.modalspinner === false && (
                      <div className=" d-flex justify-content-end align-items-center ios-modal-bottom  p-0 border-0 fixed-button cus-btn-mobile cus-reset">
                        <button
                          type="button"
                          className={styles.buttonfixed + " next_btn buildbtn"}
                          form="androidmodalform"
                          disabled={this.state.androiddisabledsubmitbutton}
                          style={{
                            background:
                              this.state.androiddisabledsubmitbutton === true
                                ? "rgb(112, 155, 250)"
                                : "#3064F9",
                            borderColor:
                              this.state.androiddisabledsubmitbutton === true
                                ? "rgb(112, 155, 250)"
                                : "#3064F9",
                          }}
                          onClick={(e) => this.handleReset(e)}
                        >
                          <div className="d-flex align-items-center justify-content-center h-100 w-100">
                            <div className={this.state.loader3}>
                              <img src={this.state.loaderimage3} alt="loader" />
                            </div>{" "}
                            <div>{this.state.submitval3}</div>
                          </div>
                        </button>
                      </div>
                    )}
                    {this.state.modalspinner === false && (
                      <div className=" d-flex justify-content-end align-items-center ios-modal-bottom  p-0 border-0 fixed-button cus-btn-mobile cus-save">
                        <button
                          type="button"
                          className={styles.buttonfixed + " next_btn buildbtn"}
                          form="androidmodalform"
                          disabled={this.state.androiddisabledsubmitbutton}
                          style={{
                            background:
                              this.state.androiddisabledsubmitbutton === true
                                ? "rgb(112, 155, 250)"
                                : "#3064F9",
                            borderColor:
                              this.state.androiddisabledsubmitbutton === true
                                ? "rgb(112, 155, 250)"
                                : "#3064F9",
                          }}
                          onClick={(e) => this.handleSave(e)}
                        >
                          <div className="d-flex align-items-center justify-content-center h-100 w-100">
                            <div className={this.state.loader2}>
                              <img src={this.state.loaderimage2} alt="loader" />
                            </div>{" "}
                            <div>{this.state.submitval2}</div>
                          </div>
                        </button>
                      </div>
                    )}

                    {this.state.modalspinner === false && (
                      <div className=" d-flex justify-content-end align-items-center ios-modal-bottom  p-0 border-0 fixed-button cus-btn-mobile cus-build">
                        <button
                          type="submit"
                          className={styles.buttonfixed + " next_btn buildbtn"}
                          form="androidmodalform"
                          disabled={this.state.androiddisabledsubmitbutton}
                          style={{
                            background:
                              this.state.androiddisabledsubmitbutton === true
                                ? "rgb(112, 155, 250)"
                                : localStorage.getItem('button_background_color'),
                            borderColor:
                              this.state.androiddisabledsubmitbutton === true
                                ? "rgb(112, 155, 250)"
                                : localStorage.getItem('button_background_color'),
                            color:localStorage.getItem('button_text_color'),
                            "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                            "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
                            "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-center h-100 w-100">
                            <div className={this.state.androidloader}>
                              <img
                                src={this.state.androidloaderimage}
                                alt="loader"
                              />
                            </div>{" "}
                            <div>{this.state.androidsubmitval}</div>
                          </div>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-3 pb-3 d-md-flex justify-content-end flex-column sub-heading-spacing">
                  {/* {
                  (this.state.android_pushtoggle || this.state.androidgoogle_enable) && (
                      */}
                  <div
                    className={
                      styles.bundleId +
                      " d-flex align-items-center justify-content-end mobile-view-toggle versionspac responsive-toggle-pad temp-toggle-align mobile-toggle-align"
                    }
                  >
                    {/* <div className="new_division mid-responsive-spacing toggle-pad">
              <div className="grey-content upload-provision beta-toggle-alignment">
                                          <div className=" d-flex justify-content-between align-items-center mobile_padd_issue">
                                            <div className="ios-buid-width-change w-75 toggle-width-change">
                                                {/* Tool tip */}
                    {/* <Tooltip firsttag={"h3"} tagclass={"mb-1 mr-2"} classdata={"d-flex justify-content-start align-items-center"} heading={"Get the latest beta code release?"} content={""} /> */}
                    {/* Tool tip */}
                    {/* </div>
                                            <div className="upload-btn toggle-responsive text-right ios-buid-width-fix toggle-width-fix w-25">
                                                <div className="on_off d-flex  align-items-center justify-content-end">
                                                  <label
                                                      className="switch"
                                                      htmlFor="betarelease"
                                                      >
                                                      <input type="checkbox" id="betarelease" form="beta-form" checked={this.state.is_beta_release} onChange={(e) => this.handleis_beta_release(e)}
                                                      />
                                                      <div className="slider round"></div>
                                                  </label>
                                                </div>
                                            </div>
                                          </div>
                                          <div className="w-75 toggle-subtext">
                                            <h5 style={{ fontSize: "13px" }} className=" align-items-center justify-content-start mb-0">
                                            Turn on to get android code version 1.15.1, instead of the stable 1.14.6 code version. 
                                          
                                            </h5>
                                          </div>
                                      </div>
                                      </div> */}
                    {this.state.bundle_android_identifier !== "" && (
                      <div className="d-flex spec-responsive subtext-space subtext-new-responsive">
                        <p className="mb-0 d-flex">
                          {" "}
                          <span>Your Bundle ID:</span>{" "}
                          <span
                            id="android_bundle"
                            style={{ color: "#1B2952" }}
                          >
                            {this.state.bundle_android_identifier}
                          </span>
                        </p>
                        <img
                          className="ml-2"
                          src={
                            require("../../assets/images/dashboard/config/build/copy.png")
                              .default
                          }
                          alt="copy"
                          onClick={(e) =>
                            this.handleselection("android_bundle")
                          }
                        />
                      </div>
                    )}
                  </div>
                  <form id="beta-form" onSubmit={this.androidsubmit}>
                    {/* <div className="d-flex justify-content-between align-items-center ios-modal-bottom new-modal-spacing mt-4 mt-md-0 pl-0">
              <div className="supportipad">
                 
                  <label className="containerstyle upload-provision pt-0 pb-0	">
                    <Tooltip
                      firsttag={"h3"}
                      tagclass={"mb-0 mr-2"}
                      classdata={
                        "d-flex justify-content-start align-items-center"
                      }
                      heading={"Beta Release"}
                      content={
                        "Enable Android support if your previously published android app supported Android devices. "
                      }
                    />
                    <input
                      type="checkbox"
                      checked={this.state.is_beta_release}
                      onChange={(e) => this.handleis_beta_release(e)}
                    />
                    <span className="checkmark"></span>
                  </label>
                
              </div>
            </div> */}
                    {/* <div className=" d-flex justify-content-between align-items-center mobile_padd_issue">
                                            <div className="ios-buid-width-change w-100 pr-4 betatoggle"> */}
                    {/* Tool tip */}
                    {/* <Tooltip
                      firsttag={"h3"}
                      tagclass={"mb-0 mr-2 betatext"}
                      classdata={
                        "d-flex justify-content-start align-items-center"
                      }
                      heading={"Beta Release"}
                      content={
                        "Get early access to Android code version 1.15.0. "
                      }
                      customclass={"beta-spacing"}
                      newclass={"d-none d-md-block"}
                    /> */}
                    {/* Tool tip */}
                    {/* </div>
                                            <div className="upload-btn toggle-responsive-spacing text-right ios-buid-width-fix w-25 togglebuttonspac">
                                                <div className="on_off d-flex  align-items-center justify-content-end">
                                                  <label
                                                      className="switch"
                                                      htmlFor="betarelease"
                                                      >
                                                      <input type="checkbox" id="betarelease" checked={this.state.is_beta_release} onChange={(e) => this.handleis_beta_release(e)}
                                                      />
                                                      <div className="slider round"></div>
                                                  </label>
                                                </div>
                                            </div>
                                          </div> */}
                  </form>
                  {/*                       
                  )
                } */}
                </div>
                <div>
                  {this.state.modalspinner ? (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ height: "200px" }}
                    >
                      <div className="newspinner">
                <svg xmlns="http://www.w3.org/2000/svg" 
          xlink="http://www.w3.org/1999/xlink" 
          style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
          width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
            style={{animationplaystate: "running", animationdelay: "0s"}}>
            </animateTransform>
          </circle>
          </g>
          <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
          style={{animationplaystate: "running", animationdelay: "0s"}}>
          </animateTransform>
          </circle>
          </g>
          <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
          style={{animationplaystate: "running", animationdelay: "0s"}}>

          </animateTransform>
          </circle>
          </g>
          <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
          style={{animationplaystate: "running", animationdelay: "0s"}}>

          </animateTransform>
          </circle>
          </g>
          </svg>
              </div>
                    </div>
                  ) : (
                    <>
                      <form
                        id="androidmodalform"
                        onSubmit={this.androidsubmit}
                        className="d-md-flex justify-content-between provision-certificate pt-md-0"
                        style={{ height: "auto" }}
                      >
                        <div className="w-100 new_width_input ">
                          {this.state.android_custom_status === 1 &&
                            this.state.is_app_already_published === 1 && (
                              <>
                                <div className="d-flex align-items-center justify-content-start mb-md-2 ">
                                  <div>
                                    <img
                                      className="mr-2 mr-md-3"
                                      src={
                                        require("../../assets/images/dashboard/config/build/migrate.png")
                                          .default
                                      }
                                      alt="migrate"
                                    />
                                  </div>
                                  <div className="heading_config">
                                    <h4>Migration</h4>
                                    <h5 className="mt-2 align-items-center justify-content-start mb-0">
                                      Use this option if you already have a live
                                      Android app which you would like to
                                      replace with an app built on AppMySite
                                      {/* <Media
                                        style={{
                                          marginLeft: "5px",
                                          textDecoration: "none",
                                          display: "inline",
                                        }}
                                        href="https://www.appmysite.com/support/already-have-a-live-android-app-replace-it-with-the-appmysite-app/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Learn More{" "}
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/Goto.png")
                                              .default
                                          }
                                          className="gotoappicon appear-spacing"
                                          alt="Goto"
                                        />
                                      </Media> */}
                                    </h5>
                                  </div>
                                </div>
                                {/* Keystore data */}

                                <div
                                  className="d-flex justify-content-between align-items-start flex-flow-row mt-4"
                                  style={{
                                    borderBottom: "1px solid #ced4da",
                                    paddingBottom: "30px",
                                    marginBottom: "30px",
                                  }}
                                >
                                  <div className="new_division">
                                    <div className="grey-content upload-provision">
                                      <h3>KeyStore Alias</h3>
                                      <div className="upload-btn text-right custom-class-add pt-md-0">
                                        <input
                                          type="text"
                                          className="w-100 form-control form-name certificate-file mt-md-2"
                                          required
                                          name="keystore_alias"
                                          value={this.state.keystore_alias}
                                          onChange={(e) => this.handlecheck(e)}
                                        />
                                      </div>
                                    </div>
                                    <div className="grey-content upload-provision">
                                      <h3>KeyStore Password</h3>
                                      <div className="upload-btn text-right custom-class-add pt-md-0">
                                        <input
                                          type="password"
                                          className="w-100 form-control form-name certificate-file mt-md-2"
                                          required
                                          name="keystore_private_key_password"
                                          value={
                                            this.state
                                              .keystore_private_key_password
                                          }
                                          onChange={(e) => this.handlecheck(e)}
                                          autoComplete="new-password"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="new_division">
                                    <div className="grey-content upload-provision">
                                      <h3>KeyStore Private Key Password</h3>
                                      <div className="upload-btn text-right custom-class-add pt-md-0">
                                        <input
                                          type="password"
                                          className="w-100 form-control form-name certificate-file mt-md-2"
                                          required
                                          name="keystore_password"
                                          value={this.state.keystore_password}
                                          onChange={(e) => this.handlecheck(e)}
                                        />
                                      </div>
                                    </div>
                                    <div className="grey-content upload-provision border-0 ">
                                      <div className="d-block d-md-flex justify-content-between align-items-center flex-column">
                                        <div className="w-100 d-flex ">
                                          {/* Tool tip */}
                                          <Tooltip
                                            firsttag={"h3"}
                                            tagclass={"mb-0 mr-2"}
                                            classdata={
                                              "d-flex justify-content-start align-items-center"
                                            }
                                            heading={"Upload Keystore File"}
                                            content={
                                              "A keystore is a storage mechanism for security certificates. It is used to sign an APK before deployment to the Google Play Store."
                                            }
                                          />
                                        </div>
                                        <div className="upload-btn text-right ios-buid-width-fix  new-button-upload justify-content-between">
                                          <input
                                            type="file"
                                            id="jks_file"
                                            onChange={(e) =>
                                              this.handlejks_file(e)
                                            }
                                            style={{ display: "none" }}
                                          />
                                          <label
                                            htmlFor="jks_file"
                                            className={
                                              this.state.jks_filename.indexOf(
                                                "supported"
                                              ) > -1
                                                ? "notuploaded"
                                                : "fileuploaded"
                                            }
                                          >
                                            {this.state.jks_filename.indexOf(
                                              "supported"
                                            ) > -1 && (
                                              <svg
                                                className="mr-2"
                                                style={{ marginTop: "-4px" }}
                                                width="9.944"
                                                height="13.214"
                                                viewBox="0 0 9.944 13.214"
                                              >
                                                {" "}
                                                <g
                                                  id="Group_2921"
                                                  data-name="Group 2921"
                                                  transform="translate(0.53 1.061)"
                                                >
                                                  {" "}
                                                  <path
                                                    id="Path_6311"
                                                    data-name="Path 6311"
                                                    d="M14161.5-3240.188v-12.1"
                                                    transform="translate(-14157.059 3252.341)"
                                                    fill="none"
                                                    stroke="#7782A1"
                                                    strokeWidth="1.5"
                                                  />{" "}
                                                  <path
                                                    id="Path_6312"
                                                    data-name="Path 6312"
                                                    d="M6.281,6.281V0H0"
                                                    transform="translate(0 4.441) rotate(-45)"
                                                    fill="none"
                                                    stroke="#7782A1"
                                                    strokeWidth="1.5"
                                                  />{" "}
                                                </g>{" "}
                                              </svg>
                                            )}
                                            {this.state.jks_filename.indexOf(
                                              "supported"
                                            ) > -1
                                              ? "Upload File"
                                              : "Uploaded"}
                                          </label>

                                          <div className="d-flex justify-content-end align-items-center uploaded-file">
                                            {this.state.jks_filename.indexOf(".jks") !== 0 ? 
                                            (
                                              <Media target="_blank"
                                                className="text-decoration-none"
                                                href={altUrl +
                                                  this.state.userid +
                                                  "/" +
                                                  this.state.appid +
                                                  "/" +
                                                  attrUrl +
                                                  (this.state.jks_filename.indexOf(".jks") === -1
                                                  ? this.state.jks_filename + ".jks"
                                                  : this.state.jks_filename)
                                                  }
                                                rel="noopener noreferrer"
                                                tag="a"
                                              >
                                                {this.state.jks_filename.indexOf(".jks") > -1
                                                  ? this.state.jks_filename
                                                  : this.state.jks_filename}{" "}
                                                <img src={require("../../assets/images/dashboard/Goto.png").default}
                                                  className="gotospacekey jks-spacing"
                                                  alt="Goto"
                                                />
                                              </Media>
                                            ) : (
                                              <p className="file-supported">
                                                {" "}
                                                {this.state.jks_filename}{" "}
                                              </p>
                                            )}

                                            {this.state.jks_filename.indexOf(
                                              "supported"
                                            ) === -1 && (
                                              <label
                                                htmlFor="androidpushnotification"
                                                className="labcur"
                                              >
                                                <img
                                                  src={
                                                    require("../../assets/images/dashboard/red-cross.png")
                                                      .default
                                                  }
                                                  style={{
                                                    marginRight: "12px",
                                                  }}
                                                  alt="step"
                                                  className="ml-3"
                                                  width="11.5px"
                                                  height="11.5px"
                                                />
                                              </label>
                                            )}
                                            {this.state.jks_file !== "" && (
                                              <div className="text-center"></div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          {/* Keystore data end*/}
                          {/* only for custom build end */}
                          <div className="d-md-flex justify-content-between">
                            <div className="w-100 new_width_input">
                              <div className="d-flex align-items-center justify-content-start  mb-md-2">
                                <div>
                                  <img
                                    className="mr-2 mr-md-3"
                                    src={
                                      require("../../assets/images/dashboard/config/fire.png")
                                        .default
                                    }
                                    alt="app-store"
                                  />
                                </div>
                                <div className="heading_config">
                                  <h4 className="m-0">Google Firebase</h4>
                                  <h5 className="mt-2 align-items-center justify-content-start mb-0">
                                    Connect your app to your Firebase
                                    project to enable push notifications and
                                    social login.
                                    {/* <Media
                                      style={{
                                        marginLeft: "5px",
                                        textDecoration: "none",
                                        display: "inline",
                                      }}
                                      href="https://www.appmysite.com/support/how-to-configure-push-notifications-for-android/"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Learn More{" "}
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/Goto.png")
                                            .default
                                        }
                                        className="gotoappicon appear-spacing"
                                        alt="Goto"
                                      />
                                    </Media> */}
                                  </h5>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between align-items-start flex-flow-row mt-md-4 mb-md-2">
                                <div className="new_division">
                                  <div className="grey-content upload-provision">
                                    <div className=" d-flex justify-content-between align-items-center mobile_padd_issue">
                                      <div className="ios-buid-width-change w-75">
                                        {/* Tool tip */}
                                        <Tooltip
                                          firsttag={"h3"}
                                          tagclass={"mb-1 mr-2"}
                                          classdata={
                                            "d-flex justify-content-start align-items-center"
                                          }
                                          heading={"Enable Push Notifications?"}
                                          content={""}
                                        />
                                        {/* Tool tip */}
                                      </div>
                                      <div className="upload-btn text-right ios-buid-width-fix w-25">
                                        <div className="on_off d-flex  align-items-center justify-content-end">
                                          <label
                                            className="switch"
                                            htmlFor="androidrebuild"
                                          >
                                            <input
                                              type="checkbox"
                                              id="androidrebuild"
                                              checked={
                                                this.state.android_pushtoggle
                                              }
                                              onChange={(e) =>
                                                this.handleandroidpushnotificationtoggle(
                                                  e
                                                )
                                              }
                                            />
                                            <div className="slider round"></div>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="heading_config">
                                      <h5
                                        style={{ fontSize: "13px" }}
                                        className=" align-items-center justify-content-start mb-0"
                                      >
                                        Turn on to enable push notifications in
                                        your app.
                                      </h5>
                                    </div>
                                  </div>
								  
								  
								  {this.state.website_tech !== 2 &&
                                    parseInt(
                                      localStorage.getItem("website_technology")
                                    ) !== 3 && (
                                      <div className="grey-content upload-provision">
                                        <div className="mobile_padd_issue d-flex justify-content-between align-items-center">
                                          <div className="ios-buid-width-change w-75 toggle-new-spacing">
                                            {/* Tool tip */}
                                            <Tooltip
                                              firsttag={"h3"}
                                              tagclass={"mb-1 mr-2"}
                                              classdata={
                                                "d-flex justify-content-start align-items-center"
                                              }
                                              heading={"Enable Social Logins?"}
                                              content={""}
                                            />
                                            {/* Tool tip */}
                                          </div>
                                          {this.state
                                            .is_social_login_enabled === 0 &&
                                            this.state.androidgoogle_enable ===
                                              true && (
                                              <div className="new-hover-element mt-md-0 mt-2 new-hover-social">
                                                <img
                                                  className="ml-2 mt-0"
                                                  src={
                                                    require("../../assets/images/dashboard/socialwarning.png")
                                                      .default
                                                  }
                                                />
                                                <span className="new-hover-element-text custom-hover-btn cus-ischat">
                                                  You need to enable your
                                                  preferred social logins.
                                                  Navigate to Settings -&#62;
                                                  Users.
                                                </span>
                                              </div>
                                            )}
                                          <div className="upload-btn text-right ios-buid-width-fix w-25 toggle-flex-spacing">
                                            <div className="on_off d-flex  align-items-center justify-content-end">
                                              <label
                                                className="switch"
                                                htmlFor="google_enable"
                                              >
                                                <input
                                                  type="checkbox"
                                                  id="google_enable"
                                                  checked={
                                                    this.state
                                                      .androidgoogle_enable
                                                  }
                                                  onChange={(e) =>
                                                    this.handleandroidgoogle_enable(
                                                      e
                                                    )
                                                  }
                                                />
                                                <div className="slider round"></div>
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="heading_config">
                                          <h5
                                            style={{ fontSize: "13px" }}
                                            className=" align-items-center justify-content-start mb-0"
                                          >
                                            Turn on to enable social login in
                                            your app.
                                          </h5>
                                        </div>
                                      </div>
                                    )}
                                  {/* Firebase Key */}
                                  {this.state.android_pushtoggle && (
                                    <div className="grey-content upload-provision  ">
                                      <div className="mt-1">
                                        <div className="">
                                          {/* Tool tip */}
                                          <Tooltip
                                            firsttag={"h3"}
                                            tagclass={"mb-0 mr-2"}
                                            classdata={
                                              "d-flex justify-content-start align-items-center"
                                            }
                                            heading={
                                              "Google Firebase Server Key"
                                            }
                                            content={
                                              "Go to Project Settings > Cloud Messaging > Server key in your Firebase account."
                                            }
                                          />
                                          {/* Tool tip */}
                                        </div>
                                        <div className="upload-btn text-right custom-class-add pt-md-1">
                                          <input
                                            type="text"
                                            className="form-control w-100 form-name certificate-file"
                                            placeholder="AAAAO6UeSfU:APA91bHNXci_xS5D"
                                            value={this.state.androidserverkey}
                                            onChange={
                                              this.handleandroidserver_key
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {/*this.state.website_tech !== 2 &&
                                    parseInt(
                                      localStorage.getItem("website_technology")
                                    ) !== 3 && (
                                      <div className="grey-content upload-provision">
                                        <div className="mobile_padd_issue d-flex justify-content-between align-items-center">
                                          <div className="ios-buid-width-change w-75 toggle-new-spacing">
                                     
                                            <Tooltip
                                              firsttag={"h3"}
                                              tagclass={"mb-1 mr-2"}
                                              classdata={
                                                "d-flex justify-content-start align-items-center"
                                              }
                                              heading={"Enable Social Logins?"}
                                              content={""}
                                            />
                                     
                                          </div>
                                          {this.state
                                            .is_social_login_enabled === 0 &&
                                            this.state.androidgoogle_enable ===
                                              true && (
                                              <div className="new-hover-element mt-md-0 mt-2 new-hover-social">
                                                <img
                                                  className="ml-2 mt-0"
                                                  src={
                                                    require("../../assets/images/dashboard/socialwarning.png")
                                                      .default
                                                  }
                                                />
                                                <span className="new-hover-element-text custom-hover-btn cus-ischat">
                                                  You need to enable your
                                                  preferred social logins.
                                                  Navigate to Settings -&#62;
                                                  Users.
                                                </span>
                                              </div>
                                            )}
                                          <div className="upload-btn text-right ios-buid-width-fix w-25 toggle-flex-spacing">
                                            <div className="on_off d-flex  align-items-center justify-content-end">
                                              <label
                                                className="switch"
                                                htmlFor="google_enable"
                                              >
                                                <input
                                                  type="checkbox"
                                                  id="google_enable"
                                                  checked={
                                                    this.state
                                                      .androidgoogle_enable
                                                  }
                                                  onChange={(e) =>
                                                    this.handleandroidgoogle_enable(
                                                      e
                                                    )
                                                  }
                                                />
                                                <div className="slider round"></div>
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="heading_config">
                                          <h5
                                            style={{ fontSize: "13px" }}
                                            className=" align-items-center justify-content-start mb-0"
                                          >
                                            Turn on to enable social login in
                                            your app.
                                          </h5>
                                        </div>
                                      </div>
									)}*/}
                                </div>
                              </div>
							  
							  <div className="d-flex justify-content-between align-items-start flex-flow-row">
                              	{ (this.state.android_pushtoggle && this.state.app_data.plan === "premium") && (
                                    <div className="grey-content upload-provision  new_division">
                                      	<div className="mt-1">
	                                        <div className="">
                                          		
                                          		<Tooltip firsttag={"h3"} tagclass={"mb-0 mr-2"} classdata={ "d-flex justify-content-start align-items-center" } heading={ "Sender ID" } content={ "Sender id." } />
                                          		
                                        	</div>
											<div className="upload-btn text-right custom-class-add pt-md-1">
                                          		<input type="text" className="form-control w-100 form-name certificate-file" placeholder="823572130190" value={this.state.androidsenderid} onChange={ this.handleandroidsender_id } />
											</div>
                                      	</div>
                                    </div>
								)}
							</div>
                              {/* SHA KEY */}

                              <div className="d-flex justify-content-between align-items-start flex-flow-row">
                                {(this.state.android_custom_status === 0 ||
                                  (this.state.is_app_already_published === 0 &&
                                    this.state.android_custom_status === 1)) &&
                                  this.state.androidgoogle_enable && (
                                    <div className="new_division">
                                      <div className="grey-content upload-provision  ">
                                        <div>
                                          <div className="">
                                            {/* Tool tip */}
                                            <Tooltip
                                              firsttag={"h3"}
                                              tagclass={"mb-0 mr-2"}
                                              classdata={
                                                "d-flex justify-content-start align-items-center"
                                              }
                                              heading={"SHA-1 Key"}
                                              content={
                                                "Click ‘Generate’ to create your SHA key. Then within your Firebase account, go to Project Settings > General > Android App > SHA certificate fingerprints and paste your SHA-1 key there. "
                                              }
                                            />
                                            {/* Tool tip */}
                                          </div>

                                          <div
                                            className="d-flex justify-content-between align-items-center upload-btn text-right ios-buid-width-fix  new-button-upload "
                                            style={{
                                              backgroundColor:
                                                this.state.jks_file === null ||
                                                this.state.jks_file === ""
                                                  ? "#F4F7FD"
                                                  : "white",
                                            }}
                                          >
                                            <div
                                              className={
                                                styles.adjustshawidth +
                                                " form-control  form-name certificate-file text-left pl-2"
                                              }
                                              style={{
                                                backgroundColor:
                                                  this.state.jks_file ===
                                                    null ||
                                                  this.state.jks_file === ""
                                                    ? "#F4F7FD"
                                                    : "white",
                                                border: "none",
                                                height: "55px",
                                                lineHeight: "43px",
                                              }}
                                            >
                                              <p
                                                style={{
                                                  textOverflow: "ellipsis",
                                                  overflow: "hidden",
                                                  whiteSpace: "nowrap",
                                                }}
                                                id="copy_sha"
                                              >
                                                {this.state.sha1}
                                              </p>
                                            </div>
                                            {this.state.sha_spinner === false &&
                                              this.state.sha1 !== "" &&
                                              this.state.sha1 !== null && (
                                                <div
                                                  style={{ cursor: "pointer" }}
                                                  onClick={(e) =>
                                                    this.handleselection(
                                                      "copy_sha"
                                                    )
                                                  }
                                                >
                                                  <img
                                                    className="ml-2"
                                                    src={
                                                      require("../../assets/images/dashboard/config/build/copy.png")
                                                        .default
                                                    }
                                                    alt="copy"
                                                  />
                                                </div>
                                              )}
                                            <div className="d-flex justify-content-end align-items-center uploaded-file">
                                              {this.state.sha_spinner ===
                                              true ? (
                                                <div className="d-flex align-items-center">
                                                  <Spinner
                                                    animation="border"
                                                    variant="primary"
                                                    style={{
                                                      color:
                                                        "rgb(48, 100, 249)",
                                                      height: "20px",
                                                      width: "20px",
                                                    }}
                                                  />
                                                  <p
                                                    className="file-supported pr-1 pl-1"
                                                    style={{
                                                      color: "#3064F9",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={(e) =>
                                                      this.handleshakey(e)
                                                    }
                                                  >
                                                    Generating
                                                  </p>
                                                </div>
                                              ) : (
                                                (this.state.jks_file === null ||
                                                  this.state.jks_file ===
                                                    "") && (
                                                  <p
                                                    className="file-supported pr-3"
                                                    style={{
                                                      color: "#3064F9",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={(e) =>
                                                      this.handleshakey(e)
                                                    }
                                                  >
                                                    Generate
                                                  </p>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                <div className="new_division">
                                  {(this.state.android_pushtoggle ||
                                    this.state.androidgoogle_enable) && (
                                    <div className="grey-content upload-provision">
                                      <div className="gf-config">
                                        <div className="ios-buid-width-change ios-new-modal  d-block d-md-flex justify-content-between align-items-center mb-2 mb-md-0 mobile-view-tooltext-align">
                                          {/* Tool tip */}
                                          <Tooltip
                                            firsttag={"h3"}
                                            tagclass={"mb-0 mr-2"}
                                            classdata={
                                              "d-flex justify-content-start align-items-center "
                                            }
                                            heading={
                                              "Google Firebase Configuration"
                                            }
                                            content={
                                              "To obtain your Firebase configuration, go to Project Settings > General > Your Apps > google-services.json in your Firebase account. "
                                            }
                                          />
                                          {/* Tool tip */}
                                          {/* 
                                            <p>
                                                <span>Upload google-services.json</span>
                                            </p>
                                            */}
                                        </div>
                                        <div className="upload-btn text-right ios-buid-width-fix  new-button-upload justify-content-between pt-md-1">
                                          <input
                                            type="file"
                                            id="androidpushnotification"
                                            onChange={(e) =>
                                              this.handleandroidpushnotification(
                                                e
                                              )
                                            }
                                            style={{ display: "none" }}
                                          />
                                          <label
                                            htmlFor="androidpushnotification"
                                            className={
                                              this.state.androidpushnotificationfilename.indexOf(
                                                "supported"
                                              ) > -1
                                                ? "notuploaded"
                                                : "fileuploaded"
                                            }
                                          >
                                            {this.state.androidpushnotificationfilename.indexOf(
                                              "supported"
                                            ) > -1 && (
                                              <svg
                                                className="mr-2"
                                                style={{ marginTop: "-4px" }}
                                                width="9.944"
                                                height="13.214"
                                                viewBox="0 0 9.944 13.214"
                                              >
                                                <g
                                                  id="Group_2921"
                                                  data-name="Group 2921"
                                                  transform="translate(0.53 1.061)"
                                                >
                                                  <path
                                                    id="Path_6311"
                                                    data-name="Path 6311"
                                                    d="M14161.5-3240.188v-12.1"
                                                    transform="translate(-14157.059 3252.341)"
                                                    fill="none"
                                                    stroke="#7782A1"
                                                    strokeWidth="1.5"
                                                  />
                                                  <path
                                                    id="Path_6312"
                                                    data-name="Path 6312"
                                                    d="M6.281,6.281V0H0"
                                                    transform="translate(0 4.441) rotate(-45)"
                                                    fill="none"
                                                    stroke="#7782A1"
                                                    strokeWidth="1.5"
                                                  />
                                                </g>
                                              </svg>
                                            )}
                                            {this.state.androidpushnotificationfilename.indexOf(
                                              "supported"
                                            ) > -1
                                              ? "Upload File"
                                              : "Uploaded"}
                                          </label>
                                          <div className="d-flex justify-content-end align-items-center uploaded-file">
                                            <p className="file-supported">
                                              {this.state.androidpushnotificationfilename.indexOf("supported") > -1 ||
                                              this.state.androidpushnotificationfilename.indexOf(".json") > -1 ? (
                                                <Media
                                                  className="align-items-center text-decoration-none"
                                                  target="_blank"
                                                  href={
                                                    Url +
                                                    localStorage.getItem(
                                                      "user_id"
                                                    ) +
                                                    "/" +
                                                    localStorage.getItem(
                                                      "Appid"
                                                    ) +
                                                    "/" +
                                                    titleUrl +
                                                    (this.state.androidpushnotificationfilename.indexOf(
                                                      ".json"
                                                    ) > -1
                                                      ? this.state
                                                          .androidpushnotificationfilename
                                                      : this.state
                                                          .androidpushnotificationfilename)
                                                  }
                                                  rel="noopener noreferrer"
                                                  tag={this.state.androidpushnotificationfilename.indexOf("supported") > -1 ||
                                                       this.state.androidpushnotificationfilename.indexOf(".json") > -1
                                                      ? "a"
                                                      : "p"
                                                  }
                                                >
                                                  {" "}
                                                  {this.state.androidpushnotificationfilename.indexOf(".json") > -1
                                                    ? this.state.androidpushnotificationfilename
                                                    : this.state.androidpushnotificationfilename}{" "}
                                                  <img
                                                    src={require("../../assets/images/dashboard/Goto.png").default}
                                                    className="gotospacekey notif-spacing"
                                                    alt="Goto"
                                                  />
                                                </Media>
                                              ) : (
                                                this.state
                                                  .androidpushnotificationfilename
                                              )}
                                            </p>
                                            {this.state.androidpushnotificationfilename.indexOf(
                                              "supported"
                                            ) > -1 && (
                                              <label
                                                htmlFor="androidpushnotification"
                                                className="labcur"
                                              >
                                                <img
                                                  src={
                                                    require("../../assets/images/dashboard/red-cross.png")
                                                      .default
                                                  }
                                                  style={{
                                                    marginRight: "12px",
                                                  }}
                                                  alt="step"
                                                  className="ml-2"
                                                  width="11.5px"
                                                  height="11.5px"
                                                />
                                              </label>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>

                              {/* Admob */}
                              <div className="flex-flow-row">
                                <div className="ad-mob-div d-flex align-items-center justify-content-start mt-3 mb-md-2">
                                  <div>
                                    <img
                                      className="mr-2 mr-md-3"
                                      src={
                                        require("../../assets/images/dashboard/config/admob.png")
                                          .default
                                      }
                                      alt="app-store"
                                    />
                                  </div>
                                  <div className="heading_config">
                                    <h4 className="m-0">Admob</h4>
                                    <h5 className="mt-2 align-items-center justify-content-start mb-0">
                                      Connect your app to your Google
                                      AdMob account to display ads in your
                                      Android app
                                      {/* <Media
                                        style={{
                                          marginLeft: "5px",
                                          textDecoration: "none",
                                          display: "inline",
                                        }}
                                        href="https://www.appmysite.com/support/configure-android-build-for-google-admobs/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Learn More{" "}
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/Goto.png")
                                              .default
                                          }
                                          className="gotoappicon appear-spacing"
                                          alt="Goto"
                                        />
                                      </Media> */}
                                    </h5>
                                  </div>
                                </div>
                                <div className="new_division">
                                  <div className="grey-content upload-provision mt-md-4">
                                    <div className=" mobile_padd_issue d-flex justify-content-between align-items-center">
                                      <div className="ios-buid-width-change w-75">
                                        {/* Tool tip */}
                                        <Tooltip
                                          firsttag={"h3"}
                                          tagclass={"mb-1 mr-2"}
                                          classdata={
                                            "d-flex justify-content-start align-items-center"
                                          }
                                          heading={" Enable App Monetization?"}
                                          content={""}
                                        />
                                        {/* Tool tip */}
                                        <div className="heading_config">
                                          <h5
                                            style={{ fontSize: "13px" }}
                                            className=" align-items-center justify-content-start mb-0"
                                          >
                                            Turn on to enable app monetization
                                            {/* <Media
                                            style={{ marginLeft: "5px", textDecoration: "none",display:"inline"}}
                                            href="https://www.appmysite.com/support/category/download-ios-app/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            
                                            >
                                            Learn More
                                            </Media> */}
                                          </h5>
                                        </div>
                                      </div>

                                      <div className="upload-btn text-right ios-buid-width-fix w-25">
                                        <div className="on_off d-flex  align-items-center justify-content-end">
                                          <label
                                            className="switch"
                                            htmlFor="is_admob_enabled"
                                          >
                                            <input
                                              type="checkbox"
                                              id="is_admob_enabled"
                                              checked={
                                                this.state.is_admob_enabled
                                              }
                                              onChange={(e) =>
                                                this.handleis_admob_enabled(e)
                                              }
                                            />
                                            <div className="slider round"></div>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {this.state.is_admob_enabled && (
                                    <div className="grey-content upload-provision  border-0">
                                      <div>
                                        <div className="">
                                          {/* Tool tip */}
                                          <Tooltip
                                            firsttag={"h3"}
                                            tagclass={"mb-0 mr-2"}
                                            classdata={
                                              "d-flex justify-content-start align-items-center"
                                            }
                                            heading={"Admob App ID"}
                                            content={
                                              "Enter your Google AdMob App ID here. "
                                            }
                                          />
                                          {/* Tool tip */}
                                        </div>
                                        <div className="upload-btn text-right custom-class-add pt-md-1">
                                          <input
                                            type="text"
                                            className="w-100 form-control form-name certificate-file"
                                            placeholder="ca-app-pub-7382648443445~23"
                                            required
                                            value={this.state.admob_app_id}
                                            onChange={(e) =>
                                              this.handleadmob_app_id(e)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {/* Admob end */}

                              <div className="d-flex align-items-start justify-content-start mt-3 mb-md-2 flex-flow-row ad-mob-div">
                                <div className="d-flex align-items-center justify-content-start mt-1 mt-md-0 mb-md-2 mb-3">
                                  <div>
                                    <img
                                      className="mr-2 mr-md-3"
                                      src={
                                        require("../../assets/images/dashboard/config/build/lock.png")
                                          .default
                                      }
                                      alt="app-store"
                                    />
                                  </div>
                                  <div className="heading_config">
                                    <h4 className="m-0">App Permissions</h4>
                                    <h5 className="mt-2 align-items-center justify-content-start mb-0">
                                      If you’re using web views, and your
                                      website requires access to native device
                                      features, you must enable them from here
                                      to avoid getting rejected by the app
                                      stores.
                                      {/* <Media
                                          style={{
                                          marginLeft: "5px",
                                          textDecoration: "none",
                                          display:"inline"
                                          }}
                                          href="https://www.appmysite.com/support/category/download-ios-app/"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          >
                                          Learn More
                                          </Media> */}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between align-items-start flex-flow-row mb-md-3 mb-5">
                                <div className="new_division">
                                  <div className="grey-content upload-provision">
                                    <div className=" d-flex justify-content-between align-items-center mobile_padd_issue">
                                      <div>
                                        <img
                                          className="mr-2 mr-md-3"
                                          src={
                                            require("../../assets/images/dashboard/config/build/map.png")
                                              .default
                                          }
                                          alt="app-store"
                                        />
                                      </div>
                                      <div className="ios-buid-width-change w-100">
                                        <h3>Location</h3>
                                        <div className="heading_config">
                                          <h5
                                            style={{ fontSize: "13px" }}
                                            className="d-flex align-items-center justify-content-start mb-0 mt-1"
                                          >
                                            Turn on if your app requires access
                                            to a user’s location.
                                          </h5>
                                        </div>
                                      </div>
                                      <div className="upload-btn text-right ios-buid-width-fix w-25">
                                        <div className="on_off d-flex  align-items-center justify-content-end">
                                          <label
                                            className="switch"
                                            htmlFor="is_enable_location_permission"
                                          >
                                            <input
                                              type="checkbox"
                                              id="is_enable_location_permission"
                                              name="is_enable_location_permission"
                                              checked={
                                                this.state
                                                  .is_enable_location_permission
                                              }
                                              onChange={(e) =>
                                                this.handlepermission(e)
                                              }
                                            />
                                            <div className="slider round"></div>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="grey-content upload-provision">
                                    <div className=" d-flex justify-content-between align-items-center mobile_padd_issue">
                                      <div>
                                        <img
                                          className="mr-2 mr-md-3"
                                          src={
                                            require("../../assets/images/dashboard/config/build/micro.png")
                                              .default
                                          }
                                          alt="micro"
                                        />
                                      </div>
                                      <div className="ios-buid-width-change w-100">
                                        <h3>Microphone</h3>
                                        <div className="heading_config">
                                          <h5
                                            style={{ fontSize: "13px" }}
                                            className="d-flex align-items-center justify-content-start mb-0 mt-1"
                                          >
                                            Turn on if your app requires access
                                            to a user’s microphone.
                                          </h5>
                                        </div>
                                      </div>
                                      <div className="upload-btn text-right ios-buid-width-fix w-25">
                                        <div className="on_off d-flex  align-items-center justify-content-end">
                                          <label
                                            className="switch"
                                            htmlFor="is_enable_microphone_permission"
                                          >
                                            <input
                                              type="checkbox"
                                              id="is_enable_microphone_permission"
                                              name="is_enable_microphone_permission"
                                              checked={
                                                this.state
                                                  .is_enable_microphone_permission
                                              }
                                              onChange={(e) =>
                                                this.handlepermission(e)
                                              }
                                            />
                                            <div className="slider round"></div>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* <div className="grey-content upload-provision">
                          <div className=" d-flex justify-content-between align-items-center mobile_padd_issue">
                              <div>
                                <img
                                className="mr-2 mr-md-3"
                                src={
                                require("../../assets/images/dashboard/config/build/folder.png")
                                .default
                                }
                                alt="folder"
                                />
                              </div>
                              <div className="ios-buid-width-change w-75">
                                <h3>Storage</h3>
                                <div className="heading_config">
                                    <h5
                                    style={{ fontSize: "13px" }}
                                    className="d-flex align-items-center justify-content-start mb-0 mt-1"
                                    >
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod.
                                    </h5>
                              </div>
                            </div>
                            <div className="upload-btn text-right ios-buid-width-fix w-25">
                              <div className="on_off d-flex  align-items-center justify-content-end">
                                  <label className="switch" htmlFor="is_enable_storage_permission">
                                    <input type="checkbox" id="is_enable_storage_permission" name="is_enable_storage_permission" checked={this.state.is_enable_storage_permission} onChange={e => this.handlepermission(e)}/>
                                    <div className="slider round"></div>
                                  </label>
                              </div>
                            </div>
                        </div>
                      </div> */}
                                </div>
                                <div className="new_division">
                                  {/* <div className="grey-content upload-provision">
                                      <div className=" d-flex justify-content-between align-items-center mobile_padd_issue">
                                          <div>
                                            <img
                                            className="mr-2 mr-md-3"
                                            src={
                                            require("../../assets/images/dashboard/config/build/calendar.png")
                                            .default
                                            }
                                            alt="app-store"
                                            />
                                          </div>
                                          <div className="ios-buid-width-change w-75">
                                            <h3>Calendar</h3>
                                            <div className="heading_config">
                                                <h5
                                                style={{ fontSize: "13px" }}
                                                className="d-flex align-items-center justify-content-start mb-0 mt-1"
                                                >
                                                Lorem ipsum dolor sit amet, consectetur
                                                adipiscing elit, sed do eiusmod.
                                                </h5>
                                            </div>
                                          </div>
                                          <div className="upload-btn text-right ios-buid-width-fix w-25">
                                            <div className="on_off d-flex  align-items-center justify-content-end">
                                                <label className="switch" htmlFor="is_enable_calender_permission">
                                                  <input type="checkbox" id="is_enable_calender_permission"  name="is_enable_calender_permission" checked={this.state.is_enable_calender_permission} onChange={e => this.handlepermission(e)}/>
                                                  <div className="slider round"></div>
                                                </label>
                                            </div>
                                          </div>
                                      </div>
                                    </div> */}

                                  <div className="grey-content upload-provision">
                                    <div className=" d-flex justify-content-between align-items-center mobile_padd_issue">
                                      <div>
                                        <img
                                          className="mr-2 mr-md-3"
                                          src={
                                            require("../../assets/images/dashboard/config/build/camera.png")
                                              .default
                                          }
                                          alt="camera"
                                        />
                                      </div>
                                      <div className="ios-buid-width-change w-100">
                                        <h3>Camera &amp; Media library</h3>
                                        <div className="heading_config">
                                          <h5
                                            style={{ fontSize: "13px" }}
                                            className="d-flex align-items-center justify-content-start mb-0 mt-1"
                                          >
                                            Turn on if your app requires access
                                            to a user’s camera or media library.
                                          </h5>
                                        </div>
                                      </div>
                                      <div className="upload-btn text-right ios-buid-width-fix w-25">
                                        <div className="on_off d-flex  align-items-center justify-content-end">
                                          <label
                                            className="switch"
                                            htmlFor="is_enable_camera_permission"
                                          >
                                            <input
                                              type="checkbox"
                                              id="is_enable_camera_permission"
                                              disabled={
                                                this.state.chat_preferences !==
                                                  null &&
                                                this.state.chat_preferences.some(
                                                  (res) =>
                                                    res.module_status === 1
                                                )
                                              }
                                              name="is_enable_camera_permission"
                                              checked={
                                                this.state
                                                  .is_enable_camera_permission
                                              }
                                              onChange={(e) =>
                                                this.handlepermission(e)
                                              }
                                            />
                                            <div className="slider round"></div>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="grey-content upload-provision">
                                    <div className=" d-flex justify-content-between align-items-center mobile_padd_issue">
                                      <div>
                                        <img
                                          className="mr-2 mr-md-3"
                                          src={
                                            require("../../assets/images/dashboard/config/build/profile.png")
                                              .default
                                          }
                                          alt="profile"
                                        />
                                      </div>
                                      <div className="ios-buid-width-change w-100">
                                        <h3>Contacts</h3>
                                        <div className="heading_config">
                                          <h5
                                            style={{ fontSize: "13px" }}
                                            className="d-flex align-items-center justify-content-start mb-0 mt-1"
                                          >
                                            Turn on if your app requires access
                                            to a user’s contacts.
                                          </h5>
                                        </div>
                                      </div>
                                      <div className="upload-btn text-right ios-buid-width-fix w-25">
                                        <div className="on_off d-flex  align-items-center justify-content-end">
                                          <label
                                            className="switch"
                                            htmlFor="is_enable_contacts_permission"
                                          >
                                            <input
                                              type="checkbox"
                                              id="is_enable_contacts_permission"
                                              name="is_enable_contacts_permission"
                                              checked={
                                                this.state
                                                  .is_enable_contacts_permission
                                              }
                                              onChange={(e) =>
                                                this.handlepermission(e)
                                              }
                                            />
                                            <div className="slider round"></div>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* {InnerFoot()} */}
          </div>
        </section>
      </>
    );
  }
}
