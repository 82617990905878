import React from 'react'

const Knowledgebase = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g
      id="Group_41131"
      data-name="Group 41131"
      transform="translate(-1549 -169)"
    >
      <rect
        id="Rectangle_25577"
        data-name="Rectangle 25577"
        width="24"
        height="24"
        transform="translate(1549 169)"
        fill="none"
      />
      <g id="Group_35093" data-name="Group 35093">
        <path
          id="Path_98228"
          data-name="Path 98228"
          d="M10.5,17.783l-.228-.189A12.707,12.707,0,0,0,.5,14.783V.61a12.707,12.707,0,0,1,9.772,2.811l.228.189Z"
          transform="translate(1551 172)"
          fill="none"
          stroke="#1b2952"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <path
          id="Path_98229"
          data-name="Path 98229"
          d="M10.5,17.783l.228-.189A12.707,12.707,0,0,1,20.5,14.783V.61a12.707,12.707,0,0,0-9.772,2.811L10.5,3.61Z"
          transform="translate(1551 172)"
          fill="none"
          stroke="#1b2952"
          stroke-linejoin="round"
          stroke-width="1"
        />
      </g>
    </g>
  </svg>
  )
}

export default Knowledgebase