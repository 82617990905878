import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiEndPoints from "../utils/apiEndpoints";
import { prepareAuthHeaders } from "../utils/commonUtils";

export const splashSlice = createApi({
  reducerPath: "splash",
  keepUnusedDataFor: 1800,
  baseQuery: fetchBaseQuery({
    baseUrl: apiEndPoints.userUrl,
    prepareHeaders: (headers) => prepareAuthHeaders(headers),
  }),
  tagTypes: ["get-splash"],
  endpoints: (builder) => ({
    splashData: builder.query({
      query: () => `get_splash/${btoa(localStorage.getItem("Appid"))}`,
      providesTags: ["get-splash"],
    }),
    saveSplashData: builder.mutation({
      query: (newData) => ({
        url: "splash",
        method: "POST",
        body: newData,
      }),
      invalidatesTags: ["get-splash"],
    }),
  }),
});

export const { useSplashDataQuery, useSaveSplashDataMutation } = splashSlice;
