import React, { useEffect, useState } from "react";
import "./Contact.css";
import Select from "react-select";
import {
  Media,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import emailValidator from "email-validator";
import ReCAPTCHA from "react-google-recaptcha";
import { errortoast } from "../Toaster";
import toast from "toasted-notes";
import { ContactCountrylist, Getdata } from "../../Api/Dashboard/DashboardApi";
import HeaderOutsidePortal from "../Reusable/HeaderOutsidePortal";

const EnterpriseContactUs = () => {
  const [helpDropdownOpen, setHelpDropdownOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [message, setMessage] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [industry, setIndustry] = useState("");
  const [roleInCompany, setRoleInCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneCode, setPhoneCode] = useState("1");
  const [countryNamePrefix, setcountryNamePrefix] = useState("US");
  const [captcha, setCaptcha] = useState("");

  const [nameErr, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [mssgErr, setMssgErr] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [companyNameErr, setCompanyNameErr] = useState(false);
  const [companySizeErr, setCompanySizeErr] = useState(false);
  const [industryErr, setIndustryErr] = useState(false);
  const [roleInCompanyErr, setRoleInCompanyErr] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);
  const [loader, setLoader] = useState("d-none");
  const [isOpenPhoneCodeModal, setIsOpenPhoneCodeModal] = useState(false);
  const [captchaErr, setCaptchaErr] = useState(false);
  const [phonecodesearch, setPhonecodesearch] = useState("");
  const [countryselectfield, setCountryselectfield] = useState([]);
  const [openMobcompanysizeDropdown, setOpenMobcompanysizeDropdown] =
    useState(false);
  const [openMobIndustoryDropdown, setOpenMobIndustoryDropdown] =
    useState(false);
  const [openMobRoleDropdown, setOpenMobRoleDropdown] = useState(false);
  const [loaderimage, setLoaderimage] = useState(
    require("../../assets/images/signup/Loader_GIF.gif").default
  );

  const [openAppDropdown, setOpenAppDropdown] = useState(false);
  const [openMobileAppDropdown, setOpenMobileAppDropdown] = useState(false);
  const [submitval, setSubmitval] = useState("Send");
  const [optionsForRole, setOptionsForRole] = useState([
    { value: "Business Owner", label: "Business Owner", name: "userroles" },
    { value: "Customer Success", label: "Customer Success", name: "userroles" },
    { value: "Engineering", label: "Engineering", name: "userroles" },
    { value: "Leadership", label: "Leadership", name: "userroles" },
    { value: "Marketing", label: "Marketing", name: "userroles" },
    { value: "Operations", label: "Operations", name: "userroles" },
    { value: "Sales", label: "Sales", name: "userroles" },
    { value: "Support", label: "Support", name: "userroles" },
    { value: "Other", label: "Other", name: "userroles" },
  ]);
  const [optionsForCompanySize, setOptionsForCompanySize] = useState([
    { value: "1-19 employees", label: "1-19 employees", name: "company_size" },
    {
      value: "20-49 employees",
      label: "20-49 employees",
      name: "company_size",
    },
    {
      value: "50-249 employees",
      label: "50-249 employees",
      name: "company_size",
    },
    {
      value: "250-999 employees",
      label: "250-999 employees",
      name: "company_size",
    },
    {
      value: "1,000+ employees",
      label: "1,000+ employees",
      name: "company_size",
    },
  ]);
  const [optionsForIndustory, setoptionsForIndustory] = useState([
    { value: "Arts", label: "Arts", name: "userindustry" },
    { value: "Automotive", label: "Automotive", name: "userindustry" },

    {
      value: "Constructions & Engineering",
      label: "Constructions & Engineering",
      name: "userindustry",
    },
    { value: "Consulting", label: "Consulting", name: "userindustry" },
    { value: "Consumer Goods", label: "Consumer Goods", name: "userindustry" },
    { value: "Education", label: "Education", name: "userindustry" },
    { value: "Energy", label: "Energy", name: "userindustry" },
    { value: "Entertainment", label: "Entertainment", name: "userindustry" },
    { value: "Finance", label: "Finance", name: "userindustry" },
    { value: "Fintech", label: "Fintech", name: "userindustry" },
    {
      value: "Food & Beverages",
      label: "Food & Beverages",
      name: "userindustry",
    },
    { value: "Government", label: "Government", name: "userindustry" },
    { value: "Healthcare", label: "Healthcare", name: "userindustry" },
    {
      value: "Human Resources",
      label: "Human Resources",
      name: "userindustry",
    },
    { value: "IT", label: "IT", name: "userindustry" },
    { value: "Insurance", label: "Insurance", name: "userindustry" },
    { value: "Logistics", label: "Logistics", name: "userindustry" },
    { value: "Manufacturing", label: "Manufacturing", name: "userindustry" },
    { value: "Marketing", label: "Marketing", name: "userindustry" },
    { value: "Media", label: "Media", name: "userindustry" },
    { value: "NGO", label: "NGO", name: "userindustry" },
    {
      value: "Professional Services",
      label: "Professional Services",
      name: "userindustry",
    },
    { value: "Real Estate", label: "Real Estate", name: "userindustry" },
    { value: "Restaurants", label: "Restaurants", name: "userindustry" },
    { value: "Retail", label: "Retail", name: "userindustry" },
    { value: "Software", label: "Software", name: "userindustry" },
    { value: "Sports", label: "Sports", name: "userindustry" },
    { value: "Telecom", label: "Telecom", name: "userindustry" },
    {
      value: "Travel & Leisure",
      label: "Travel & Leisure",
      name: "userindustry",
    },
  ]);

  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    document.title = "Talk to Sales | AppMySite";
    document.body.classList.remove("opec");

    try {
      ContactCountrylist("", setCountryselectfield);
      Getdata(setcountryNamePrefix, setPhoneCode);
    } catch {
      
    }
  }, []);

  const history = useHistory();

  const handleOpenMobCmpnySizeDropdown = () => {
    setOpenMobcompanysizeDropdown(!openMobcompanysizeDropdown);
  };

  const handleOpenMobIndustoryDropdown = () => {
    setOpenMobIndustoryDropdown(!openMobIndustoryDropdown);
  };
  const handleOpenMobRoleDropdown = () => {
    setOpenMobRoleDropdown(!openMobRoleDropdown);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (e.target.value.trim() !== "") {
      setEmailError(false);
    }
  };
  const handleRecaptcha = (value) => {
    setCaptcha(value);
    setCaptchaErr(false);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
    if (e.target.value.trim() !== "") {
      setNameError(false);
    }
  };

  const handleWebsiteChange = (e) => {
    setWebsite(e.target.value);
    if (e.target.value.trim() !== "") {
      setUrlError(false);
    }
  };

  const handleCompanyChange = (e) => {
    setCompanyName(e.target.value);
    if (e.target.value.trim() !== "") {
      setCompanyNameErr(false);
    }
  };

  const handleCompanySize = (e) => {
    setCompanySize(e.value);
    setCompanySizeErr(false);
    if (window.matchMedia("(max-width: 1200px)").matches) {
      setOpenMobcompanysizeDropdown(false);
    }
  };
  const handleIndustryChange = (e) => {
    setIndustry(e.value);
    setIndustryErr(false);
    if (window.matchMedia("(max-width: 1200px)").matches) {
      setOpenMobIndustoryDropdown(false);
    }
  };
  const handleRoleChange = (e) => {
    setRoleInCompany(e.value);
    setRoleInCompanyErr(false);
    if (window.matchMedia("(max-width: 1200px)").matches) {
      setOpenMobRoleDropdown(false);
    }
  };
  const handlePhoneChange = (e) => {
    setPhone(e.target.value.replace(/[^0-9]/g, ""));
    setPhoneErr(false);
  };
  //
  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    if (e.target.value.trim() !== "") {
      setMssgErr(false);
    }
  };
  const helpToggle = () => {
    setHelpDropdownOpen(!helpDropdownOpen);
  };

  const sucesstoast = (message) => {
    toast.notify(
      <div
        style={{
          borderLeft: "5px solid #01C99B",
          height: "100px",
          width: "420px",
        }}
        className="mobiletoaster"
      >
        <div className="d-flex align-items-center h-100">
          <div className="mr-3 ml-3">
            <svg width="35" height="35" viewBox="0 0 35 35">
              <circle
                id="Ellipse_1736"
                data-name="Ellipse 1736"
                cx="17.5"
                cy="17.5"
                r="17.5"
                fill="#01c99b"
              />
              <path
                id="Path_6845"
                data-name="Path 6845"
                d="M801.834,128.881l4.166,3.8,8.015-8.015"
                transform="translate(-790.333 -111.171)"
                fill="none"
                stroke="#fff"
                strokeWidth="2"
              />
            </svg>
          </div>
          <div>
            <h6 className="text-left mb-1">Success</h6>
            <p className="p-0 m-0 text-left">{message}</p>
          </div>
        </div>
      </div>,

      {
        position: "top", // top-left, top, top-right, bottom-left, bottom, bottom-right
        duration: 6000, // This notification will not automatically close
      }
    );
  };

  const openContriesCode = () => {
    setIsOpenPhoneCodeModal(!isOpenPhoneCodeModal);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Submit Loader

    const Loader = () => {
      setSubmitval("");
      setLoader("loader mr-2");
    };
    const erroLoader = () => {
      setSubmitval("Send");
      setLoader("d-none");
    };
    Loader();

    let params = {
      // name: name,
      // email: email,
      website_url: website.trim(),
      // message: message,
      // is_enterprise_support: 2,
      customer_name: name.trim(),
      enterprise_email: email.trim(),
      enterprise_phone_code: phoneCode,
      enterprise_phone: phone,
      enterprise_name: companyName.trim(),
      enterprise_size: companySize,
      industry: industry,
      role_in_enterprise: roleInCompany,

      message: message.trim(),
    };
    let email_err_temp = false;
    let isErrforCheck = false;
    const isUrl =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    if (!params.customer_name) {
      setNameError(true);
      erroLoader();
    }

    if (!params.enterprise_email) {
      email_err_temp = "Required";
      setEmailError("Required");
      isErrforCheck = true;
      erroLoader();
    }
    if (
      !emailValidator.validate(params.enterprise_email) &&
      email_err_temp !== "Required"
    ) {
      email_err_temp = true;
      setEmailError(true);
      isErrforCheck = true;
      erroLoader();
    }

    //  if (!params.enterprise_phone) {
    //     setPhoneErr(true);
    //     isErrforCheck = true;
    //     erroLoader();
    //   }
    if (!params.enterprise_name) {
      isErrforCheck = true;
      setCompanyNameErr(true);
      erroLoader();
    }
    if (!params.enterprise_size) {
      isErrforCheck = true;
      setCompanySizeErr(true);
      erroLoader();
    }
    if (!params.website_url) {
      isErrforCheck = true;
      setUrlError(true);
      erroLoader();
    }

    if (!params.industry) {
      isErrforCheck = true;
      setIndustryErr(true);
      erroLoader();
    }
    if (!params.role_in_enterprise) {
      isErrforCheck = true;
      setRoleInCompanyErr(true);
      erroLoader();
    }
    if (!params.message) {
      isErrforCheck = true;
      setMssgErr(true);
      erroLoader();
    }
    if (!captcha) {
      isErrforCheck = true;
      erroLoader();
      setCaptchaErr(true);
      // errortoast("Captcha is required");
    }
    

    if (isErrforCheck === false) {
      const headers = {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + localStorage.getItem("amstoken"),
      };
      axios
        .post(url + "api/enterprise-contact-us", params, {
          headers: headers,
        })
        .then((res) => {
          if (res.data.code === 200) {
            sucesstoast("Your details have been submitted. ");
            erroLoader();
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else if (res.data.code === 400) {
            erroLoader();
            errortoast("Oh no! Something’s not right.");
          } else {
            erroLoader();
            errortoast("Oh no! Something’s not right.");
          }
        })
        .catch((err) => {
          erroLoader();
          errortoast("Oh no! Something’s not right."); 
        });
    }
  };
  const handlephonecodesearch = (e) => {
    setPhonecodesearch(e.target.value);
  };

  const handleseachradio = (e) => {
    setPhoneCode(e.target.value);
    setIsOpenPhoneCodeModal(false);
  };
  const handleAppdropdown = () => {
    setOpenAppDropdown(!openAppDropdown);
  };

  const handleMobileAppDropdown = () => {
    setOpenMobileAppDropdown(!openMobileAppDropdown);
  };

  const handleCountryChange = (e, phoneCode, country_code) => {
    setPhoneCode(phoneCode);
    setcountryNamePrefix(country_code);
    setOpenMobileAppDropdown(!openMobileAppDropdown);
  };

  const customStyleForCompanyDropdown = {
    control: (base, state) => ({
      ...base,
      backgroundColor: state.isDisabled ? "#ffffff" : "",
      borderColor: companySizeErr === true ? "#ff4848" : state.isFocused ? "#7782a1" : "#C8CEDB",
    }),
  };
  const customStyleForIndustoryDropdown = {
    control: (base, state) => ({
      ...base,
      backgroundColor: state.isDisabled ? "#ffffff" : "",
      borderColor: industryErr === true ? "#ff4848" : state.isFocused ? "#7782a1" : "#C8CEDB",
    }),
  };
  const customStyleForRoleDropdown = {
    control: (base, state) => ({
      ...base,
      backgroundColor: state.isDisabled ? "#ffffff" : "",
      borderColor: roleInCompanyErr === true ? "#ff4848" : state.isFocused ? "#7782a1" : "#C8CEDB",
    }),
  };

  return (
    <div>
      <HeaderOutsidePortal
        classNameProp={"contact-us-header-white-bg"}
        SignInShow={true}
      />
      {/* <header className="ipad-display-head contact-us-header">
        <div className="container signhead">
          <div className="logo">
            <Media href="/apps" tag="a">
              {" "}
              <svg width="160" height="30" viewBox="0 0 160 30">
                <defs>
                  <clipPath id="clip-path">
                    <rect
                      id="Rectangle_9416"
                      data-name="Rectangle 9416"
                      width="160"
                      height="30"
                      transform="translate(420 25)"
                      fill="#fff"
                    />
                  </clipPath>
                  <clipPath id="clip-path-2">
                    <rect
                      id="Rectangle_9415"
                      data-name="Rectangle 9415"
                      width="159.961"
                      height="29.409"
                      fill="none"
                    />
                  </clipPath>
                </defs>
                <g
                  id="Mask_Group_270"
                  data-name="Mask Group 270"
                  transform="translate(-420 -25)"
                  clip-path="url(#clip-path)"
                >
                  <g
                    id="Group_8532"
                    data-name="Group 8532"
                    transform="translate(420 25)"
                  >
                    <g
                      id="Group_8531"
                      data-name="Group 8531"
                      clip-path="url(#clip-path-2)"
                    >
                      <path
                        id="Path_38777"
                        data-name="Path 38777"
                        d="M14.084,58.114v1.932a6.185,6.185,0,0,0-5.352-2.718C3.839,57.293,0,60.318,0,66.093c0,5.876,3.671,8.935,8.631,8.9,1.867-.034,4.5-.986,5.453-2.957v2.549l4.1-.435V57.677ZM9.107,71.189h0a5.081,5.081,0,0,1,0-10.158c6.523,0,6.523,10.158,0,10.158"
                        transform="translate(0 -52.015)"
                        fill="#3064f9"
                      />
                      <path
                        id="Path_38778"
                        data-name="Path 38778"
                        d="M220.423,57.7a6.662,6.662,0,0,0-5.382,2.61V58.016l-4.1.435V81.758l4.1-.435V72.93c1.208,1.771,3.776,2.3,5.517,2.3,5.369,0,8.46-3.872,8.46-8.764,0-4.926-3.431-8.766-8.6-8.766m-.306,13.522a4.643,4.643,0,0,1-4.756-4.757,4.756,4.756,0,0,1,9.513,0,4.644,4.644,0,0,1-4.757,4.757"
                        transform="translate(-191.396 -52.354)"
                        fill="#3064f9"
                      />
                      <path
                        id="Path_38779"
                        data-name="Path 38779"
                        d="M430.726,57.7h0a6.651,6.651,0,0,0-5.427,2.672V58.013h0l-4.1.435V81.758l4.1-.435V72.865c1.191,1.822,3.8,2.365,5.561,2.365,5.369,0,8.46-3.872,8.46-8.764,0-4.927-3.431-8.766-8.6-8.766m-.3,13.521a4.643,4.643,0,0,1-4.756-4.757,4.756,4.756,0,0,1,9.513,0,4.644,4.644,0,0,1-4.757,4.757"
                        transform="translate(-382.175 -52.354)"
                        fill="#3064f9"
                      />
                      <path
                        id="Path_38780"
                        data-name="Path 38780"
                        d="M1182.5,63.525c-2.139-.135-3.125-.747-3.125-1.833,0-1.054,1.087-1.664,3.059-1.664a5.647,5.647,0,0,1,4.144,1.493l2.31-2.717a8.993,8.993,0,0,0-6.522-2.209c-3.059,0-7.067,1.36-7.067,5.232,0,3.8,3.771,4.96,6.931,5.2,2.379.137,3.33.612,3.33,1.767,0,1.223-1.461,2.039-3.024,2a9.189,9.189,0,0,1-5.776-2.344l-2.038,2.956a9.8,9.8,0,0,0,7.746,2.99c4.791,0,7.2-2.548,7.2-5.5,0-4.451-4.009-5.164-7.168-5.369Z"
                        transform="translate(-1065.881 -51.35)"
                        fill="#3064f9"
                      />
                      <path
                        id="Path_38781"
                        data-name="Path 38781"
                        d="M1355.016,61V77.473l-4.146.441V61.439l4.115-.439Z"
                        transform="translate(-1225.71 -55.348)"
                        fill="#3064f9"
                      />
                      <path
                        id="Path_38782"
                        data-name="Path 38782"
                        d="M1355.016,0V3.594l-4.146.441V.439Z"
                        transform="translate(-1225.71)"
                        fill="#3064f9"
                      />
                      <path
                        id="Path_38783"
                        data-name="Path 38783"
                        d="M1422.3,32.125a9.249,9.249,0,0,1-3.431.714c-3.637.137-6.015-1.936-6.015-6.116V19.209h-3.125l1.708-3.566h1.417v-4.2L1417,11v4.643h2.912v3.566h-2.946v7.514a2.08,2.08,0,0,0,2.175,2.379,4.749,4.749,0,0,0,2.005-.51Z"
                        transform="translate(-1279.116 -9.981)"
                        fill="#3064f9"
                      />
                      <path
                        id="Path_38784"
                        data-name="Path 38784"
                        d="M1547.2,56.24c-5.436,0-8.9,3.669-8.9,8.765,0,5.365,3.432,8.934,9.207,8.934a10.293,10.293,0,0,0,7.2-2.717l-2.651-2.616a6.92,6.92,0,0,1-4.485,1.561c-2.92,0-4.722-1.5-4.993-3.566h13.078c.647-6.9-2.854-10.361-8.457-10.361m-4.552,6.932a4.509,4.509,0,0,1,4.687-3.262c2.378,0,4.077,1.087,4.348,3.262Z"
                        transform="translate(-1395.774 -51.029)"
                        fill="#3064f9"
                      />
                      <path
                        id="Path_38785"
                        data-name="Path 38785"
                        d="M692.448,66.457v8.691l-4.146.441V66.457c0-2.208-.918-4.077-3.159-4.077s-3.635,1.935-3.635,4.146v8.622l-4.146.441V66.526c0-2.21-1.155-4.214-3.431-4.214-2.151,0-3.461,1.847-3.562,3.949v8.9l-4.1.435V59.116l4.056-.432h.044v2.053a4.853,4.853,0,0,1,4.41-2.3,5.019,5.019,0,0,1,4.961,3.125,6.062,6.062,0,0,1,5.6-3.057c4.757,0,7.1,2.92,7.1,7.95"
                        transform="translate(-604.539 -53.025)"
                        fill="#3064f9"
                      />
                      <path
                        id="Path_38786"
                        data-name="Path 38786"
                        d="M966.816,60.87l-.034.081-.3.69L956.8,84.132l-4.771.507,3.1-7.212-6.519-16.05,4.627-.491.02.056,0,.009,4.212,11.387,4.546-10.958Z"
                        transform="translate(-860.72 -55.23)"
                        fill="#3064f9"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </Media>
          </div>
          <div className="d-flex align-items-center contact-page">
            <div className="back-home">
              <Media
                tag="a"
                href={process.env.REACT_APP_WEBSITE_URL}
                className=""
              >
                <img
                  src={
                    require("../../assets/images/signup/website-icon.png")
                      .default
                  }
                  alt="contact"
                  className="d-block d-md-none"
                />{" "}
                <span className="d-none d-md-block">Website</span>
              </Media>
           
              <Media
                tag="a"
                href="/sign-in"
                className="d-none d-md-block ipad-signup-spacing"
              >
                Sign in
              </Media>
              <Media tag="a" href="/get-in-touch" className="d-none">
                <img
                  src={
                    require("../../assets/images/signup/contact-icon.png")
                      .default
                  }
                  alt="contact"
                  className="mr-md-2 ml-md-4"
                />
                Contact
              </Media>
              <Media
                className="d-none mr-3 "
                tag="a"
                href={process.env.REACT_APP_WEBSITE_URL}
              >
                <img
                  src={require("../../assets/images/signup/back.png").default}
                  alt="cross"
                />
              </Media>

              <Media tag="a" href="/get-in-touch" className="d-none d-md-none">
                <img
                  src={
                    require("../../assets/images/signup/contact-icon.png")
                      .default
                  }
                  alt="contact"
                  className="mr-md-2 ml-md-4"
                />
              </Media>
            </div>
            <div className="login-help">
              <Dropdown isOpen={helpDropdownOpen} toggle={helpToggle}>
                <DropdownToggle className="d-flex align-items-center  justify-content-between">
                  <p>
                    <img
                      src={
                        require("../../assets/images/signup/help.png").default
                      }
                      alt="help"
                      className="d-block d-md-none"
                    />{" "}
                    <span className="d-none d-md-block">Help</span>
                  </p>
                </DropdownToggle>
                <DropdownMenu className="helpheaderdropdown">
                  <div className="support-tab">
                    <p>Support</p>
                  </div>
                  <DropdownItem>
                    <a
                      href="https://www.appmysite.com/support/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="d-flex justify-content-start align-items-center">
                        <svg
                          width="19.229"
                          height="19.363"
                          viewBox="0 0 19.229 19.363"
                          className="mr-3"
                        >
                          <g
                            id="Group_3391"
                            data-name="Group 3391"
                            transform="translate(-435.997 -836.966)"
                          >
                            <g id="Group_3390" data-name="Group 3390">
                              <g id="Group_3389" data-name="Group 3389">
                                <path
                                  id="Path_6899"
                                  data-name="Path 6899"
                                  d="M438.538,837.716a1.791,1.791,0,0,0-1.791,1.791v10.205a1.791,1.791,0,0,0,1.791,1.791h1.537v3.213l3.812-3.213h8.8a1.791,1.791,0,0,0,1.791-1.791V839.507a1.791,1.791,0,0,0-1.791-1.791Z"
                                  transform="translate(0 0)"
                                  fill="none"
                                  stroke="#7782a1"
                                  strokeMiterlimit="10"
                                  strokeWidth="1.5"
                                />
                              </g>
                            </g>
                            <g
                              id="Group_3258"
                              data-name="Group 3258"
                              transform="translate(443.313 840.973)"
                            >
                              <path
                                id="Path_6900"
                                data-name="Path 6900"
                                d="M491.39,867.121a2.3,2.3,0,1,1,3.036,2.177,1.105,1.105,0,0,0-.738,1.051v.56"
                                transform="translate(-491.39 -864.823)"
                                fill="none"
                                stroke="#7782a1"
                                strokeMiterlimit="10"
                                strokeWidth="1.5"
                              />
                              <line
                                id="Line_114"
                                data-name="Line 114"
                                y2="1"
                                transform="translate(2.289 6.983)"
                                fill="none"
                                stroke="#7782a1"
                                strokeMiterlimit="10"
                                strokeWidth="1.5"
                              />
                            </g>
                          </g>
                        </svg>
                        Help Center
                      </div>
                    </a>
                  </DropdownItem>

                  <DropdownItem>
                    <a
                      href="https://www.appmysite.com/blog/news-category/whats-new/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="d-flex justify-content-start align-items-center">
                        <svg
                          width="15.63"
                          height="20.148"
                          viewBox="0 0 15.63 20.148"
                          className="mr-3"
                        >
                          <g
                            id="Group_3395"
                            data-name="Group 3395"
                            transform="translate(-1602.25 -235.044)"
                          >
                            <g id="Group_3393" data-name="Group 3393">
                              <g id="Group_3394" data-name="Group 3394">
                                <g id="Group_3392" data-name="Group 3392">
                                  <g
                                    id="Group_3261"
                                    data-name="Group 3261"
                                    transform="translate(1501.73 -580.365)"
                                  >
                                    <path
                                      id="Path_6903"
                                      data-name="Path 6903"
                                      d="M136.063,822.932a7.056,7.056,0,0,1-2.831,5.953,3.764,3.764,0,0,0-1.516,3.012h-5.422a3.8,3.8,0,0,0-1.537-3.027,7.065,7.065,0,1,1,11.306-5.937Z"
                                      transform="translate(-20.67 0)"
                                      fill="none"
                                      stroke="#7782a1"
                                      strokeMiterlimit="10"
                                      strokeWidth="1.5"
                                    />
                                    <path
                                      id="Path_6904"
                                      data-name="Path 6904"
                                      d="M160.622,936.41v2.31a.6.6,0,0,1-.6.6H155.8a.6.6,0,0,1-.6-.6v-2.31Z"
                                      transform="translate(-49.577 -104.512)"
                                      fill="none"
                                      stroke="#7782a1"
                                      strokeMiterlimit="10"
                                      strokeWidth="1.5"
                                    />
                                    <line
                                      id="Line_115"
                                      data-name="Line 115"
                                      y1="6.168"
                                      transform="translate(108.335 825.73)"
                                      fill="none"
                                      stroke="#7782a1"
                                      strokeMiterlimit="10"
                                      strokeWidth="1.5"
                                    />
                                    <path
                                      id="Path_6905"
                                      data-name="Path 6905"
                                      d="M164.009,873.157l-2.111,2.111-2.111-2.111"
                                      transform="translate(-53.563 -49.538)"
                                      fill="none"
                                      stroke="#7782a1"
                                      strokeMiterlimit="10"
                                      strokeWidth="1.5"
                                    />
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                        What’s New
                      </div>
                    </a>
                  </DropdownItem>
                  <DropdownItem>
                    <a
                      href="https://www.appmysite.com/support/news-category/release-notes/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="d-flex justify-content-start align-items-center">
                        <svg
                          width="18.232"
                          height="16.447"
                          viewBox="0 0 18.232 16.447"
                          className="mr-3"
                        >
                          <g
                            id="Group_4770"
                            data-name="Group 4770"
                            transform="translate(-766.57 -805.64)"
                          >
                            <path
                              id="Path_6906"
                              data-name="Path 6906"
                              d="M784.052,807.522v7.438c-.156.156-16.732,0-16.732,0v-7.438a1.131,1.131,0,0,1,1.13-1.132h14.471A1.131,1.131,0,0,1,784.052,807.522Z"
                              fill="none"
                              stroke="#7782a1"
                              strokeMiterlimit="10"
                              strokeWidth="1.5"
                            />
                            <path
                              id="Path_6907"
                              data-name="Path 6907"
                              d="M784.052,875.61v2.059a1.131,1.131,0,0,1-1.13,1.132H768.45a1.131,1.131,0,0,1-1.13-1.132V875.61S783.9,875.766,784.052,875.61Z"
                              transform="translate(0 -60.65)"
                              fill="none"
                              stroke="#7782a1"
                              strokeMiterlimit="10"
                              strokeWidth="1.5"
                            />
                            <rect
                              id="Rectangle_2320"
                              data-name="Rectangle 2320"
                              width="2.94"
                              height="3.187"
                              transform="translate(774.216 818.15)"
                              fill="none"
                              stroke="#7782a1"
                              strokeMiterlimit="10"
                              strokeWidth="1.5"
                            />
                            <line
                              id="Line_116"
                              data-name="Line 116"
                              x2="7.194"
                              transform="translate(772.089 821.337)"
                              fill="none"
                              stroke="#7782a1"
                              strokeMiterlimit="10"
                              strokeWidth="1.5"
                            />
                          </g>
                        </svg>
                        Release Notes
                      </div>
                    </a>
                  </DropdownItem>

                  <DropdownItem>
                    <a
                      href="https://www.g2.com/products/appmysite/reviews/start"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="d-flex justify-content-start align-items-center">
                        <img
                          src={
                            require("../../assets/images/addons/review.png")
                              .default
                          }
                          alt="review"
                          className="mr-3"
                        />
                        Leave a Review
                      </div>
                    </a>
                  </DropdownItem>
                  <DropdownItem>
                    <a
                      href="https://www.appmysite.com/support/roadmap/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="d-flex justify-content-start align-items-center">
                        <img
                          src={
                            require("../../assets/images/dashboard/roadmap.png")
                              .default
                          }
                          alt="roadmap"
                          className="mr-3 pr-1"
                        />
                        Roadmap
                      </div>
                    </a>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <Media
              tag="a"
              href="/sign-in"
              className="d-block d-md-none contact-mobile-login"
            >
              <img
                src={
                  require("../../assets/images/signup/login-icon.png").default
                }
                alt="login"
                className="mr-md-2"
              />
            </Media>
          </div>
        </div>
      </header> */}
      <div className="contactus-page contactUs">
        {/* <img
          src={require("../../assets/images/signup/branding.png").default}
          className="branding1"
          alt="AMS branding"
        />

        <img
          src={require("../../assets/images/signup/branding2.png").default}
          className="branding2"
          alt="AMS branding"
        /> */}
        <div
          className="custom-container custom-container-responsive border-1"
          style={{ position: "relative" }}
        >
          {/* <div className="bottom-mob1-menu bottom-mobile-menu-responsive">
            <div className=" w-100  d-sm-block">
              <div>
                <ul>
                  <li>
                    <a
                      className="contact-link-mob-spacing"
                      href="https://www.appmysite.com/support/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Help Center
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://community.appmysite.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Community
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.appmysite.com/support/roadmap/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Roadmap
                    </a>
                  </li>
                  <li>
                    <a
                      className="contact-link-mob-spacing"
                      href="https://www.appmysite.com/support/news-category/release-notes/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Release Notes
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="d-flex justify-content-between">
            {/* <div className="left-sec leftsidedata">
              <div className="border-bottom-0 d-flex justify-content-between">
                <div className="contact-us-heading">
                  <h5 className="d-md-block">Talk to sales</h5>
                  <p>
                  Interested in an enterprise plan? Get in touch with our sales team to learn more.
                  </p>
                </div>
              </div>
              <div className="">
                <div className="checkLabel">
                  <li>
                    <img
                      src={
                        require("../../assets/images/dashboard/checkbox.png")
                          .default
                      }
                      alt="support"
                    />
                    <label className="checktext1">
                      {" "}
                      Custom solutions for Enterprises
                    </label>
                  </li>
                  <li className="checkLabelli">
                    <img
                      src={
                        require("../../assets/images/dashboard/checkbox.png")
                          .default
                      }
                      alt="support"
                    />
                    <label> Ease of use & guided setup</label>
                  </li>
                  <li className="checkLabelli">
                    <img
                      src={
                        require("../../assets/images/dashboard/checkbox.png")
                          .default
                      }
                      alt="support"
                    />
                    <label> Enhanced support over email & phone</label>
                  </li>
                </div>
                <div className="iconimg">
                  <img
                    className="img-fluid d-none d-lg-block "
                    src={
                      require("../../assets/images/dashboard/ratingBox.png")
                        .default
                    }
                    alt="support"
                  />
                </div>

                <div className="talk">
                  <p>
                      Have a general question?{" "}
                    <Link to={"/get-in-touch"}>Get in touch.</Link>
                  </p>
                </div>
              </div>
            </div> */}

            <div className="right-sec rightsecdata ">
              <div className="border-bottom-0 d-flex justify-content-between hedaing-contact">
                <div className="contact-us-headings ">
                  <h5 className="new-contect-us-head">Talk to sales</h5>
                  <p className="new-contect-us-para">
                    Let’s start a conversation about your enterprise
                    requirements.
                  </p>
                </div>
              </div>

              <form onSubmit={(e) => handleSubmit(e)} className="contactUsform">
                <div className="form-group">
                  <label>Full name*</label>
                  <input
                    // required
                    type="text"
                    className={`form-control ${
                      nameErr ? "contactus-errorinput" : ""
                    }`}
                    name="subject"
                    // placeholder="Name*"
                    value={name}
                    onChange={(e) => handleNameChange(e)}
                    maxLength="200"
                    autocomplete="off"
                  />
                  <div className="d-flex align-items-center contact-errormessages ">
                    <div>
                      {nameErr ? (
                        <div className="d-flex align-items-center contact-us-error">
                          {/* <img
                            src={
                              require("../../assets/images/signup/error.png")
                                .default
                            }
                            className="img-fluid"
                            alt="error"
                          /> */}
                          <svg
                            id="Group_29966"
                            data-name="Group 29966"
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                          >
                            <path
                              id="Path_82474"
                              data-name="Path 82474"
                              d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z"
                              fill="#ff4848"
                            />
                            <path
                              id="Path_7077"
                              data-name="Path 7077"
                              d="M10991-4358.209v4"
                              transform="translate(-10984.499 4361.208)"
                              fill="none"
                              stroke="#fff"
                              stroke-width="1"
                            />
                            <path
                              id="Path_7078"
                              data-name="Path 7078"
                              d="M10991-4347.192v1.229"
                              transform="translate(-10984.499 4356.1)"
                              fill="none"
                              stroke="#fff"
                              stroke-width="1"
                            />
                          </svg>
                          <p>Required field.</p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label>Work email*</label>
                  <input
                    type="text"
                    className={`form-control ${
                      emailError ? "contactus-errorinput" : ""
                    }`}
                    name="email"
                    value={email}
                    onChange={(e) => handleEmailChange(e)}
                    // placeholder="Work email*"
                    // required
                    maxLength="200"
                    autocomplete="off"
                  />
                  <div className="d-flex align-items-center contact-errormessages">
                    <div>
                      {emailError ? (
                        <div className="d-flex align-items-center contact-us-error">
                          {/* <img
                            src={
                              require("../../assets/images/signup/error.png")
                                .default
                            }
                            className="img-fluid"
                            alt="error"
                          /> */}
                          <svg
                            id="Group_29966"
                            data-name="Group 29966"
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                          >
                            <path
                              id="Path_82474"
                              data-name="Path 82474"
                              d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z"
                              fill="#ff4848"
                            />
                            <path
                              id="Path_7077"
                              data-name="Path 7077"
                              d="M10991-4358.209v4"
                              transform="translate(-10984.499 4361.208)"
                              fill="none"
                              stroke="#fff"
                              stroke-width="1"
                            />
                            <path
                              id="Path_7078"
                              data-name="Path 7078"
                              d="M10991-4347.192v1.229"
                              transform="translate(-10984.499 4356.1)"
                              fill="none"
                              stroke="#fff"
                              stroke-width="1"
                            />
                          </svg>
                          <p>
                            {emailError === "Required"
                              ? "Required field."
                              : "Enter a valid email"}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group contact-usphone-dropdown">
                  <div>
                    <label>Phone number</label>
                    <div className="">
                      <div
                        className="d-flex"
                        // className="phonecodeselect phoneinput1"
                        // onClick={(e) => openContriesCode(e)}
                      >
                        <Dropdown
                          isOpen={
                            window.matchMedia("(max-width: 1200px)").matches
                              ? false
                              : openAppDropdown
                          }
                          onClick={
                            window.matchMedia("(max-width: 1200px)").matches
                              ? handleMobileAppDropdown
                              : null
                          }
                          toggle={handleAppdropdown}
                        >
                          <DropdownToggle
                            caret
                            className="connectus-dropdowntoggle"
                          >
                            <img
                              src={
                                process.env.REACT_APP_Image_Path +
                                process.env.REACT_APP_SMALL_FLAG +
                                countryNamePrefix.toLowerCase() +
                                ".png"
                              }
                              alt={"flag"}
                            />
                            <h1>+{phoneCode}</h1>
                          </DropdownToggle>
                          <DropdownMenu className="contact-dropdownmenu">
                            {/* <div className="no-app-dropdown">
                                    <img className="img-fluid" src={require("../assets/images/dashboard/no-apps-created.png").default} alt="No App Found" />
                                    </div> */}
                            <FormGroup>
                              <div className="dropdown-search">
                                <input
                                  placeholder="Search..."
                                  type="text"
                                  onChange={(e) => {
                                    setPhonecodesearch(e.target.value);
                                  }}
                                  value={phonecodesearch}
                                ></input>
                                {phonecodesearch.trim() && (
                                  <p
                                    onClick={(e) => {
                                      setPhonecodesearch("");
                                    }}
                                  >
                                    Clear
                                  </p>
                                )}
                              </div>
                            </FormGroup>
                            {countryselectfield
                              .filter((name) =>
                                name.country_name
                                  .toLowerCase()
                                  .includes(phonecodesearch.toLowerCase())
                              )
                              .map((res, i) => (
                                <DropdownItem
                                  className="d-flex"
                                  onClick={(e) =>
                                    handleCountryChange(
                                      e,
                                      res.phone_code,
                                      res.country_code
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      process.env.REACT_APP_Image_Path +
                                      process.env.REACT_APP_SMALL_FLAG +
                                      res.country_code.toLowerCase() +
                                      ".png"
                                    }
                                    alt={i}
                                  />
                                  <p className="country-name">
                                    {res.country_name} ({"+" + res.phone_code})
                                  </p>
                                </DropdownItem>
                              ))}
                          </DropdownMenu>
                        </Dropdown>
                        {/* {"+"}
                        {phoneCode} */}
                        {/* </div>
                      <div> */}
                        <input
                          type="tel"
                          className="form-control  contact-flaginputbox"
                          // name="email"
                          // required
                          maxLength="19"
                          value={phone}
                          onChange={(e) => handlePhoneChange(e)}
                          autocomplete="off"
                        />
                      </div>
                    </div>
                    {/* <div className="d-flex align-items-center contact-errormessages ">
                      <div>
                        {phoneErr ? (
                          <div className="d-flex align-items-center contact-us-error">
                         
                               <svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                            <path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848" />
                            <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1" />
                            <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1" />
                          </svg>
                            <p>Required</p>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="form-group">
                  <label>Company name*</label>
                  <input
                    type="text"
                    className={`form-control ${
                      companyNameErr ? "contactus-errorinput" : ""
                    }`}
                    // name="email"
                    value={companyName}
                    onChange={(e) => handleCompanyChange(e)}
                    // placeholder="Work email*"
                    // required
                    maxLength="200"
                    autocomplete="off"
                  />
                  <div className="d-flex align-items-center contact-errormessages">
                    <div>
                      {companyNameErr ? (
                        <div className="d-flex align-items-center contact-us-error">
                          {/* <img
                            src={
                              require("../../assets/images/signup/error.png")
                                .default
                            }
                            className="img-fluid"
                            alt="error"
                          /> */}
                          <svg
                            id="Group_29966"
                            data-name="Group 29966"
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                          >
                            <path
                              id="Path_82474"
                              data-name="Path 82474"
                              d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z"
                              fill="#ff4848"
                            />
                            <path
                              id="Path_7077"
                              data-name="Path 7077"
                              d="M10991-4358.209v4"
                              transform="translate(-10984.499 4361.208)"
                              fill="none"
                              stroke="#fff"
                              stroke-width="1"
                            />
                            <path
                              id="Path_7078"
                              data-name="Path 7078"
                              d="M10991-4347.192v1.229"
                              transform="translate(-10984.499 4356.1)"
                              fill="none"
                              stroke="#fff"
                              stroke-width="1"
                            />
                          </svg>
                          <p>Required field.</p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="work">
                    <div className="workinput2">
                      <label>Website*</label>
                      <input
                        type="text"
                        className={`form-control ${
                          urlError ? "contactus-errorinput" : ""
                        }`}
                        // name="email"
                        value={website}
                        onChange={(e) => handleWebsiteChange(e)}
                        // placeholder="Company*"
                        // required
                        maxLength="200"
                        autocomplete="off"
                      />
                      <div className="d-flex align-items-center contact-errormessages">
                        <div>
                          {urlError ? (
                            <div className="d-flex align-items-center contact-us-error">
                              <svg
                                id="Group_29966"
                                data-name="Group 29966"
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                              >
                                <path
                                  id="Path_82474"
                                  data-name="Path 82474"
                                  d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z"
                                  fill="#ff4848"
                                />
                                <path
                                  id="Path_7077"
                                  data-name="Path 7077"
                                  d="M10991-4358.209v4"
                                  transform="translate(-10984.499 4361.208)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="1"
                                />
                                <path
                                  id="Path_7078"
                                  data-name="Path 7078"
                                  d="M10991-4347.192v1.229"
                                  transform="translate(-10984.499 4356.1)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="1"
                                />
                              </svg>
                              <p>Required field.</p>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="selectinput workinput1">
                      <label>Company size*</label>
                      <div
                        className="mobile-view-click"
                        onClick={(e) => handleOpenMobCmpnySizeDropdown(e)}
                      >
                        <Select
                          styles={customStyleForCompanyDropdown}
                          options={optionsForCompanySize}
                    
                          placeholder="Select"
                          // className={`${companySizeErr?"contactus-errorinput":""}`}
                          classNamePrefix="new-enterprisecontact-dropdown"
                          onChange={(e) => handleCompanySize(e)}
                          isClearable={false}
                          isSearchable={false}
                          value={optionsForCompanySize.filter(
                            (option) => option.value === companySize
                          )}
                          // required
                          isDisabled={
                            window.matchMedia("(max-width: 1200px)").matches
                              ? true
                              : false
                          }
                          autocomplete="off"
                        />
                      </div>

                      <div className="d-flex align-items-center contact-errormessages">
                        <div>
                          {companySizeErr ? (
                            <div className="d-flex align-items-center contact-us-error">
                              <svg
                                id="Group_29966"
                                data-name="Group 29966"
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                              >
                                <path
                                  id="Path_82474"
                                  data-name="Path 82474"
                                  d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z"
                                  fill="#ff4848"
                                />
                                <path
                                  id="Path_7077"
                                  data-name="Path 7077"
                                  d="M10991-4358.209v4"
                                  transform="translate(-10984.499 4361.208)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="1"
                                />
                                <path
                                  id="Path_7078"
                                  data-name="Path 7078"
                                  d="M10991-4347.192v1.229"
                                  transform="translate(-10984.499 4356.1)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="1"
                                />
                              </svg>
                              <p>Required field.</p>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group ">
                  <div className="work">
                    <div className="workinput2">
                      <label>Industry*</label>
                      <div className="mobile-view-click" onClick={(e) => handleOpenMobIndustoryDropdown(e)}>
                        <Select
                          styles={customStyleForIndustoryDropdown}
                          options={optionsForIndustory}
                          classNamePrefix="new-enterprisecontact-dropdown"
                          placeholder="Select"
                          // className={`${industryErr?"contactus-errorinput":""}`}
                          onChange={(e) => handleIndustryChange(e)}
                          autocomplete="off"
                          isClearable={false}
                          isSearchable={false}
                          value={optionsForIndustory.filter(
                            (option) => option.value === industry
                          )}
                          isDisabled={
                            window.matchMedia("(max-width: 1200px)").matches
                              ? true
                              : false
                          }
                        />
                      </div>

                      <div className="d-flex align-items-center contact-errormessages">
                        <div>
                          {industryErr ? (
                            <div className="d-flex align-items-center contact-us-error">
                              <svg
                                id="Group_29966"
                                data-name="Group 29966"
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                              >
                                <path
                                  id="Path_82474"
                                  data-name="Path 82474"
                                  d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z"
                                  fill="#ff4848"
                                />
                                <path
                                  id="Path_7077"
                                  data-name="Path 7077"
                                  d="M10991-4358.209v4"
                                  transform="translate(-10984.499 4361.208)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="1"
                                />
                                <path
                                  id="Path_7078"
                                  data-name="Path 7078"
                                  d="M10991-4347.192v1.229"
                                  transform="translate(-10984.499 4356.1)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="1"
                                />
                              </svg>
                              <p>Required field.</p>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="selectinput workinput1">
                      <label>Role in the company*</label>
                      <div className="mobile-view-click" onClick={(e) => handleOpenMobRoleDropdown(e)}>
                        <Select
                          styles={customStyleForRoleDropdown}
                          options={optionsForRole}
                          classNamePrefix="new-enterprisecontact-dropdown"
                          placeholder="Select"
                          // className={`${roleInCompanyErr?"contactus-errorinput":""}`}
                          onChange={(e) => handleRoleChange(e)}
                          // required
                          isClearable={false}
                          isSearchable={false}
                          autocomplete="off"
                          value={optionsForRole.filter(
                            (option) => option.value === roleInCompany
                          )}
                          isDisabled={
                            window.matchMedia("(max-width: 1200px)").matches
                              ? true
                              : false
                          }
                        />
                      </div>

                      <div className="d-flex align-items-center contact-errormessages">
                        <div>
                          {roleInCompanyErr ? (
                            <div className="d-flex align-items-center contact-us-error">
                              <svg
                                id="Group_29966"
                                data-name="Group 29966"
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                              >
                                <path
                                  id="Path_82474"
                                  data-name="Path 82474"
                                  d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z"
                                  fill="#ff4848"
                                />
                                <path
                                  id="Path_7077"
                                  data-name="Path 7077"
                                  d="M10991-4358.209v4"
                                  transform="translate(-10984.499 4361.208)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="1"
                                />
                                <path
                                  id="Path_7078"
                                  data-name="Path 7078"
                                  d="M10991-4347.192v1.229"
                                  transform="translate(-10984.499 4356.1)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="1"
                                />
                              </svg>
                              <p>Required field.</p>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label>Message*</label>
                  <textarea
                    // required
                    className={`form-control ${
                      mssgErr ? "contactus-errorinput" : ""
                    }`}
                    rows="4"
                    name="message"
                    value={message}
                    onChange={(e) => handleMessageChange(e)}
                  ></textarea>
                  <div className="d-flex align-items-center contact-errormessages">
                    <div>
                      {mssgErr ? (
                        <div className="d-flex align-items-center contact-us-error">
                          <svg
                            id="Group_29966"
                            data-name="Group 29966"
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                          >
                            <path
                              id="Path_82474"
                              data-name="Path 82474"
                              d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z"
                              fill="#ff4848"
                            />
                            <path
                              id="Path_7077"
                              data-name="Path 7077"
                              d="M10991-4358.209v4"
                              transform="translate(-10984.499 4361.208)"
                              fill="none"
                              stroke="#fff"
                              stroke-width="1"
                            />
                            <path
                              id="Path_7078"
                              data-name="Path 7078"
                              d="M10991-4347.192v1.229"
                              transform="translate(-10984.499 4356.1)"
                              fill="none"
                              stroke="#fff"
                              stroke-width="1"
                            />
                          </svg>
                          <p>Required field.</p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="contact-captcha">
                  <ReCAPTCHA
                    sitekey="6LeOmMAUAAAAAGj7NXf5SlZA25DNAfvL1HwxaAeP"
                    onChange={handleRecaptcha}
                  />
                </div>
                <div className="d-flex align-items-center contact-errormessages">
                  <div>
                    {captchaErr ? (
                      <div className="d-flex align-items-center contact-us-error">
                        {/* <img
                                src={
                                  require("../../assets/images/signup/error.png")
                                    .default
                                }
                                className="img-fluid"
                                alt="error"
                              /> */}
                        <svg
                          id="Group_29966"
                          data-name="Group 29966"
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                        >
                          <path
                            id="Path_82474"
                            data-name="Path 82474"
                            d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z"
                            fill="#ff4848"
                          />
                          <path
                            id="Path_7077"
                            data-name="Path 7077"
                            d="M10991-4358.209v4"
                            transform="translate(-10984.499 4361.208)"
                            fill="none"
                            stroke="#fff"
                            stroke-width="1"
                          />
                          <path
                            id="Path_7078"
                            data-name="Path 7078"
                            d="M10991-4347.192v1.229"
                            transform="translate(-10984.499 4356.1)"
                            fill="none"
                            stroke="#fff"
                            stroke-width="1"
                          />
                        </svg>
                        <p>Required field.</p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <button type="submit" className="save-contact">
                  <div className="d-flex align-items-center justify-content-center h-100 w-100">
                    <div className={loader}>
                      {/* <img src={loaderimage} alt="loader" /> */}
                      <div className="new-loader"></div>
                    </div>{" "}
                    <div>{submitval}</div>
                  </div>
                </button>
              </form>
              <div className="contact-us-enterprise d-flex">
                <p>Have a general question?</p>
                <Link to={"/get-in-touch"}>Get in touch.</Link>
              </div>
            </div>
          </div>

          <div className=" sociallaccount-mob">
            <a
              target="_blank"
              href="https://www.facebook.com/AppMySiteOfficial/"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8.302"
                height="18.009"
                viewBox="0 0 8.302 18.009"
              >
                <path
                  id="Path_550"
                  data-name="Path 550"
                  d="M45.78,30.75H43.317v9.022H39.586V30.75H37.812V27.579h1.774V25.528a3.5,3.5,0,0,1,3.764-3.765l2.764.012v3.078H44.109a.759.759,0,0,0-.791.864v1.866h2.788Z"
                  transform="translate(-37.812 -21.763)"
                  fill="#bcc1ce"
                />
              </svg>
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UCI_mJos_doMXl1XOfViSjWg"
            >
              <svg
                id="black"
                xmlns="http://www.w3.org/2000/svg"
                width="17.229"
                height="12.118"
                viewBox="0 0 17.229 12.118"
              >
                <g id="youtube" transform="translate(0 0)">
                  <path
                    id="Fill-195"
                    d="M17.883,3.557a3.729,3.729,0,0,0-.685-1.71,2.464,2.464,0,0,0-1.726-.73C13.061.943,9.444.943,9.444.943H9.436s-3.616,0-6.027.174a2.464,2.464,0,0,0-1.726.73A3.726,3.726,0,0,0,1,3.557,26.057,26.057,0,0,0,.826,6.345V7.653A26.055,26.055,0,0,0,1,10.441a3.726,3.726,0,0,0,.685,1.71,2.92,2.92,0,0,0,1.9.736c1.378.132,5.858.173,5.858.173s3.62-.006,6.031-.18a2.463,2.463,0,0,0,1.726-.73,3.729,3.729,0,0,0,.685-1.71,26.115,26.115,0,0,0,.172-2.788V6.345A26.116,26.116,0,0,0,17.883,3.557ZM7.662,9.237V4.4l4.655,2.429Z"
                    transform="translate(-0.826 -0.943)"
                    fill="#bcc1ce"
                    fill-rule="evenodd"
                  />
                </g>
              </svg>
            </a>

            <a
              target="_blank"
              href="https://www.linkedin.com/authwall?trk=ripf&trkInfo=AQG2-pmDoPjzWQAAAYAjBqZAALbz89_67lPgJJ1_1QUzU3CX5TIZZFmVJ7YC5wtH_iHzjrWWc3Sogj2RMeV0u1VWR8KZ3n6DNqb61VCokgWkoYk5M3RSqqPyjoFDg_DkReI2qxA=&originalReferer=https://appmysite.com/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fapp-my-site%2F"
            >
              <svg
                id="black"
                xmlns="http://www.w3.org/2000/svg"
                width="16.011"
                height="15.939"
                viewBox="0 0 16.011 15.939"
              >
                <g id="linkedin" transform="translate(0 0)">
                  <path
                    id="Path_6398"
                    data-name="Path 6398"
                    d="M3.412,15.094V4.552H.153V15.094Zm0-14.278A1.6,1.6,0,0,0,1.742-.846,1.607,1.607,0,0,0,.053.816,1.6,1.6,0,0,0,1.7,2.477h.019A1.608,1.608,0,0,0,3.412.816ZM8.727,15.094V9.088a2.444,2.444,0,0,1,.118-.872,1.957,1.957,0,0,1,1.834-1.308,1.944,1.944,0,0,1,2.105,2.18v6.006h3.28V8.927c0-3.3-1.764-4.841-4.116-4.841A3.56,3.56,0,0,0,8.7,5.9l.024-1.41H5.452c.047,1.009,0,10.605,0,10.605Z"
                    transform="translate(-0.053 0.846)"
                    fill="#bcc1ce"
                    fill-rule="evenodd"
                  />
                </g>
              </svg>
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/accounts/login/?next=%2Fappmysite_official%2F"
            >
              <svg
                id="instagram-logo"
                xmlns="http://www.w3.org/2000/svg"
                width="16.325"
                height="16.324"
                viewBox="0 0 16.325 16.324"
              >
                <path
                  id="Path_551"
                  data-name="Path 551"
                  d="M11.82,0H4.5A4.51,4.51,0,0,0,0,4.505V11.82a4.51,4.51,0,0,0,4.5,4.505H11.82a4.51,4.51,0,0,0,4.505-4.505V4.505A4.51,4.51,0,0,0,11.82,0Zm3.057,11.82a3.06,3.06,0,0,1-3.057,3.057H4.5A3.06,3.06,0,0,1,1.448,11.82V4.505A3.06,3.06,0,0,1,4.5,1.448H11.82a3.06,3.06,0,0,1,3.057,3.057V11.82Z"
                  transform="translate(0)"
                  fill="#bcc1ce"
                />
                <path
                  id="Path_552"
                  data-name="Path 552"
                  d="M45.174,40.97a4.206,4.206,0,1,0,4.206,4.206A4.211,4.211,0,0,0,45.174,40.97Zm0,6.964a2.758,2.758,0,1,1,2.758-2.758A2.761,2.761,0,0,1,45.174,47.934Z"
                  transform="translate(-37.012 -37.014)"
                  fill="#bcc1ce"
                />
                <path
                  id="Path_553"
                  data-name="Path 553"
                  d="M119.983,28.251a1.062,1.062,0,1,0,.751.311A1.066,1.066,0,0,0,119.983,28.251Z"
                  transform="translate(-107.438 -25.523)"
                  fill="#bcc1ce"
                />
              </svg>
            </a>
            <a target="_blank" href="https://twitter.com/appmysite_live/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19.133"
                height="15.545"
                viewBox="0 0 19.133 15.545"
              >
                <path
                  id="twitter"
                  d="M19.133,49.84a8.178,8.178,0,0,1-2.26.619A3.9,3.9,0,0,0,18.6,48.292a7.839,7.839,0,0,1-2.487.949,3.922,3.922,0,0,0-6.785,2.682,4.039,4.039,0,0,0,.091.894,11.1,11.1,0,0,1-8.085-4.1,3.923,3.923,0,0,0,1.205,5.242,3.874,3.874,0,0,1-1.772-.483v.043a3.94,3.94,0,0,0,3.143,3.854,3.914,3.914,0,0,1-1.028.129,3.468,3.468,0,0,1-.743-.067A3.96,3.96,0,0,0,5.8,60.166,7.881,7.881,0,0,1,.939,61.839,7.348,7.348,0,0,1,0,61.785a11.043,11.043,0,0,0,6.017,1.76A11.087,11.087,0,0,0,17.181,52.384c0-.173-.006-.341-.014-.507A7.825,7.825,0,0,0,19.133,49.84Z"
                  transform="translate(0 -48)"
                  fill="#bcc1ce"
                />
              </svg>
            </a>
            <a
              target="_blank"
              href="https://www.producthunt.com/products/appmysite#appmysite"
            >
              <svg
                id="product-hunt"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <path
                  id="Path_41879"
                  data-name="Path 41879"
                  d="M10,20A10,10,0,1,0,0,10,10,10,0,0,0,10,20Z"
                  fill="#bcc1ce"
                  fill-rule="evenodd"
                />
                <path
                  id="Path_41880"
                  data-name="Path 41880"
                  d="M96,76.8v7.594h1.424V82.021h2.155a2.646,2.646,0,0,0,2.591-2.61,2.6,2.6,0,0,0-2.591-2.61Zm3.6,3.8H97.424V78.224H99.6a1.187,1.187,0,0,1,0,2.373Z"
                  transform="translate(-88.317 -70.597)"
                  fill="#fff"
                  fill-rule="evenodd"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div className=" sociallaccount">
        <a target="_blank" href="https://www.facebook.com/AppMySiteOfficial/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8.302"
            height="18.009"
            viewBox="0 0 8.302 18.009"
          >
            <path
              id="Path_550"
              data-name="Path 550"
              d="M45.78,30.75H43.317v9.022H39.586V30.75H37.812V27.579h1.774V25.528a3.5,3.5,0,0,1,3.764-3.765l2.764.012v3.078H44.109a.759.759,0,0,0-.791.864v1.866h2.788Z"
              transform="translate(-37.812 -21.763)"
              fill="#bcc1ce"
            />
          </svg>
        </a>
        <a
          target="_blank"
          href="https://www.youtube.com/channel/UCI_mJos_doMXl1XOfViSjWg"
        >
          <svg
            id="black"
            xmlns="http://www.w3.org/2000/svg"
            width="17.229"
            height="12.118"
            viewBox="0 0 17.229 12.118"
          >
            <g id="youtube" transform="translate(0 0)">
              <path
                id="Fill-195"
                d="M17.883,3.557a3.729,3.729,0,0,0-.685-1.71,2.464,2.464,0,0,0-1.726-.73C13.061.943,9.444.943,9.444.943H9.436s-3.616,0-6.027.174a2.464,2.464,0,0,0-1.726.73A3.726,3.726,0,0,0,1,3.557,26.057,26.057,0,0,0,.826,6.345V7.653A26.055,26.055,0,0,0,1,10.441a3.726,3.726,0,0,0,.685,1.71,2.92,2.92,0,0,0,1.9.736c1.378.132,5.858.173,5.858.173s3.62-.006,6.031-.18a2.463,2.463,0,0,0,1.726-.73,3.729,3.729,0,0,0,.685-1.71,26.115,26.115,0,0,0,.172-2.788V6.345A26.116,26.116,0,0,0,17.883,3.557ZM7.662,9.237V4.4l4.655,2.429Z"
                transform="translate(-0.826 -0.943)"
                fill="#bcc1ce"
                fill-rule="evenodd"
              />
            </g>
          </svg>
        </a>

        <a
          target="_blank"
          href="https://www.linkedin.com/authwall?trk=ripf&trkInfo=AQG2-pmDoPjzWQAAAYAjBqZAALbz89_67lPgJJ1_1QUzU3CX5TIZZFmVJ7YC5wtH_iHzjrWWc3Sogj2RMeV0u1VWR8KZ3n6DNqb61VCokgWkoYk5M3RSqqPyjoFDg_DkReI2qxA=&originalReferer=https://appmysite.com/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fapp-my-site%2F"
        >
          <svg
            id="black"
            xmlns="http://www.w3.org/2000/svg"
            width="16.011"
            height="15.939"
            viewBox="0 0 16.011 15.939"
          >
            <g id="linkedin" transform="translate(0 0)">
              <path
                id="Path_6398"
                data-name="Path 6398"
                d="M3.412,15.094V4.552H.153V15.094Zm0-14.278A1.6,1.6,0,0,0,1.742-.846,1.607,1.607,0,0,0,.053.816,1.6,1.6,0,0,0,1.7,2.477h.019A1.608,1.608,0,0,0,3.412.816ZM8.727,15.094V9.088a2.444,2.444,0,0,1,.118-.872,1.957,1.957,0,0,1,1.834-1.308,1.944,1.944,0,0,1,2.105,2.18v6.006h3.28V8.927c0-3.3-1.764-4.841-4.116-4.841A3.56,3.56,0,0,0,8.7,5.9l.024-1.41H5.452c.047,1.009,0,10.605,0,10.605Z"
                transform="translate(-0.053 0.846)"
                fill="#bcc1ce"
                fill-rule="evenodd"
              />
            </g>
          </svg>
        </a>
        <a
          target="_blank"
          href="https://www.instagram.com/accounts/login/?next=%2Fappmysite_official%2F"
        >
          <svg
            id="instagram-logo"
            xmlns="http://www.w3.org/2000/svg"
            width="16.325"
            height="16.324"
            viewBox="0 0 16.325 16.324"
          >
            <path
              id="Path_551"
              data-name="Path 551"
              d="M11.82,0H4.5A4.51,4.51,0,0,0,0,4.505V11.82a4.51,4.51,0,0,0,4.5,4.505H11.82a4.51,4.51,0,0,0,4.505-4.505V4.505A4.51,4.51,0,0,0,11.82,0Zm3.057,11.82a3.06,3.06,0,0,1-3.057,3.057H4.5A3.06,3.06,0,0,1,1.448,11.82V4.505A3.06,3.06,0,0,1,4.5,1.448H11.82a3.06,3.06,0,0,1,3.057,3.057V11.82Z"
              transform="translate(0)"
              fill="#bcc1ce"
            />
            <path
              id="Path_552"
              data-name="Path 552"
              d="M45.174,40.97a4.206,4.206,0,1,0,4.206,4.206A4.211,4.211,0,0,0,45.174,40.97Zm0,6.964a2.758,2.758,0,1,1,2.758-2.758A2.761,2.761,0,0,1,45.174,47.934Z"
              transform="translate(-37.012 -37.014)"
              fill="#bcc1ce"
            />
            <path
              id="Path_553"
              data-name="Path 553"
              d="M119.983,28.251a1.062,1.062,0,1,0,.751.311A1.066,1.066,0,0,0,119.983,28.251Z"
              transform="translate(-107.438 -25.523)"
              fill="#bcc1ce"
            />
          </svg>
        </a>
        <a target="_blank" href="https://twitter.com/appmysite_live/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19.133"
            height="15.545"
            viewBox="0 0 19.133 15.545"
          >
            <path
              id="twitter"
              d="M19.133,49.84a8.178,8.178,0,0,1-2.26.619A3.9,3.9,0,0,0,18.6,48.292a7.839,7.839,0,0,1-2.487.949,3.922,3.922,0,0,0-6.785,2.682,4.039,4.039,0,0,0,.091.894,11.1,11.1,0,0,1-8.085-4.1,3.923,3.923,0,0,0,1.205,5.242,3.874,3.874,0,0,1-1.772-.483v.043a3.94,3.94,0,0,0,3.143,3.854,3.914,3.914,0,0,1-1.028.129,3.468,3.468,0,0,1-.743-.067A3.96,3.96,0,0,0,5.8,60.166,7.881,7.881,0,0,1,.939,61.839,7.348,7.348,0,0,1,0,61.785a11.043,11.043,0,0,0,6.017,1.76A11.087,11.087,0,0,0,17.181,52.384c0-.173-.006-.341-.014-.507A7.825,7.825,0,0,0,19.133,49.84Z"
              transform="translate(0 -48)"
              fill="#bcc1ce"
            />
          </svg>
        </a>
        <a
          target="_blank"
          href="https://www.producthunt.com/products/appmysite#appmysite"
        >
          <svg
            id="product-hunt"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <path
              id="Path_41879"
              data-name="Path 41879"
              d="M10,20A10,10,0,1,0,0,10,10,10,0,0,0,10,20Z"
              fill="#bcc1ce"
              fill-rule="evenodd"
            />
            <path
              id="Path_41880"
              data-name="Path 41880"
              d="M96,76.8v7.594h1.424V82.021h2.155a2.646,2.646,0,0,0,2.591-2.61,2.6,2.6,0,0,0-2.591-2.61Zm3.6,3.8H97.424V78.224H99.6a1.187,1.187,0,0,1,0,2.373Z"
              transform="translate(-88.317 -70.597)"
              fill="#fff"
              fill-rule="evenodd"
            />
          </svg>
        </a>
      </div>
      {/* Country code  */}
      <Modal
        isOpen={
          window.matchMedia("(max-width: 1200px)").matches
            ? openMobileAppDropdown
            : false
        }
        style={{ maxWidth: "700px", maxHeight: "350px" }}
        className={" profile-code-modal"}
        centered
        dataclass="countrycode"
      >
        <ModalHeader className=" ">
          <div className="d-flex align-items-center search-box justify-content-center profile-country-search">
            <div className="d-flex align-items-center w-100 ">
              <div
                className="backaero-svgimg"
                onClick={handleMobileAppDropdown}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.759"
                  height="15.408"
                  viewBox="0 0 20.759 15.408"
                >
                  <g
                    id="Group_27465"
                    data-name="Group 27465"
                    transform="translate(-19.742 -22.295)"
                  >
                    <path
                      id="Path_81245"
                      data-name="Path 81245"
                      d="M-13783.922-19217h19.346"
                      transform="translate(13805.077 19247)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                    <path
                      id="Path_82232"
                      data-name="Path 82232"
                      d="M14053.656,19255.426l-7,7,7,7"
                      transform="translate(-14025.504 -19232.424)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </div>
              <input
                className="form-control flagsearch-input"
                placeholder="Search..."
                type="text"
                onChange={(e) => {
                  setPhonecodesearch(e.target.value);
                }}
                value={phonecodesearch}
              ></input>
              {phonecodesearch.trim() && (
                <p
                  className="cleartext"
                  onClick={(e) => {
                    setPhonecodesearch("");
                  }}
                >
                  Clear
                </p>
              )}
            </div>
          </div>
        </ModalHeader>
        <ModalBody className="code-selector p-0">
          <div className=" profile-countrycode-flag ">
            {countryselectfield
              .filter((name) =>
                name.country_name
                  .toLowerCase()
                  .includes(phonecodesearch.toLowerCase())
              )
              .map((res, i) => (
                <div
                  className="d-flex align-items-center justify-content-between country-line-flag"
                  key={i}
                >
                  <div
                    className="d-flex align-items-center justify-content-start"
                    onClick={(e) =>
                      handleCountryChange(e, res.phone_code, res.country_code)
                    }
                    // onClick={(e) => this.handleCountryDropdown(e, res)}
                  >
                    <img
                      src={
                        process.env.REACT_APP_Image_Path +
                        process.env.REACT_APP_SMALL_FLAG +
                        res.country_code.toLowerCase() +
                        ".png"
                      }
                      alt={i}
                    />
                    <p className="enterprise-country-name">
                      {res.country_name} ({"+" + res.phone_code})
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={
          window.matchMedia("(max-width: 1200px)").matches
            ? openMobcompanysizeDropdown
            : false
        }
        style={{ maxWidth: "700px", maxHeight: "350px" }}
        className={" profile-code-modal"}
        centered
        dataclass="countrycode"
      >
        <ModalHeader className=" ">
          <div className="d-flex align-items-center search-box justify-content-center profile-country-search">
            <div className="d-flex align-items-center w-100 ">
              <div
                className="backaero-svgimg"
                onClick={handleOpenMobCmpnySizeDropdown}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.759"
                  height="15.408"
                  viewBox="0 0 20.759 15.408"
                >
                  <g
                    id="Group_27465"
                    data-name="Group 27465"
                    transform="translate(-19.742 -22.295)"
                  >
                    <path
                      id="Path_81245"
                      data-name="Path 81245"
                      d="M-13783.922-19217h19.346"
                      transform="translate(13805.077 19247)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                    <path
                      id="Path_82232"
                      data-name="Path 82232"
                      d="M14053.656,19255.426l-7,7,7,7"
                      transform="translate(-14025.504 -19232.424)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </ModalHeader>
        <ModalBody className="code-selector p-0">
          <div className="mob-popup-company-size">
            {optionsForCompanySize.map((res, i) => (
              <div
                className="d-flex align-items-center justify-content-between"
                key={i}
              >
                <div
                  className="d-flex align-items-center justify-content-start"
                  onClick={() => handleCompanySize(res)}
                  // onClick={(e) => this.handleCountryDropdown(e, res)}
                >
                  <p className="mob-company-size">{res.value}</p>
                </div>
              </div>
            ))}
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={
          window.matchMedia("(max-width: 1200px)").matches
            ? openMobIndustoryDropdown
            : false
        }
        style={{ maxWidth: "700px", maxHeight: "350px" }}
        className={" profile-code-modal"}
        centered
        dataclass="countrycode"
      >
        <ModalHeader className=" ">
          <div className="d-flex align-items-center search-box justify-content-center profile-country-search">
            <div className="d-flex align-items-center w-100 ">
              <div
                className="backaero-svgimg"
                onClick={handleOpenMobIndustoryDropdown}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.759"
                  height="15.408"
                  viewBox="0 0 20.759 15.408"
                >
                  <g
                    id="Group_27465"
                    data-name="Group 27465"
                    transform="translate(-19.742 -22.295)"
                  >
                    <path
                      id="Path_81245"
                      data-name="Path 81245"
                      d="M-13783.922-19217h19.346"
                      transform="translate(13805.077 19247)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                    <path
                      id="Path_82232"
                      data-name="Path 82232"
                      d="M14053.656,19255.426l-7,7,7,7"
                      transform="translate(-14025.504 -19232.424)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </ModalHeader>
        <ModalBody className="code-selector p-0">
          <div className="mob-popup-company-size">
            {optionsForIndustory.map((res, i) => (
              <div
                className="d-flex align-items-center justify-content-between"
                key={i}
              >
                <div
                  className="d-flex align-items-center justify-content-start"
                  onClick={() => handleIndustryChange(res)}
                  // onClick={(e) => this.handleCountryDropdown(e, res)}
                >
                  <p className="mob-company-size">{res.value}</p>
                </div>
              </div>
            ))}
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={
          window.matchMedia("(max-width: 1200px)").matches
            ? openMobRoleDropdown
            : false
        }
        style={{ maxWidth: "700px", maxHeight: "350px" }}
        className={" profile-code-modal"}
        centered
        dataclass="countrycode"
      >
        <ModalHeader className=" ">
          <div className="d-flex align-items-center search-box justify-content-center profile-country-search">
            <div className="d-flex align-items-center w-100 ">
              <div
                className="backaero-svgimg"
                onClick={handleOpenMobRoleDropdown}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.759"
                  height="15.408"
                  viewBox="0 0 20.759 15.408"
                >
                  <g
                    id="Group_27465"
                    data-name="Group 27465"
                    transform="translate(-19.742 -22.295)"
                  >
                    <path
                      id="Path_81245"
                      data-name="Path 81245"
                      d="M-13783.922-19217h19.346"
                      transform="translate(13805.077 19247)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                    <path
                      id="Path_82232"
                      data-name="Path 82232"
                      d="M14053.656,19255.426l-7,7,7,7"
                      transform="translate(-14025.504 -19232.424)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </ModalHeader>
        <ModalBody className="code-selector p-0">
          <div className="mob-popup-company-size">
            {optionsForRole.map((res, i) => (
              <div
                className="d-flex align-items-center justify-content-between"
                key={i}
              >
                <div
                  className="d-flex align-items-center justify-content-start"
                  onClick={() => handleRoleChange(res)}
                  // onClick={(e) => this.handleCountryDropdown(e, res)}
                >
                  <p className="mob-company-size">{res.value}</p>
                </div>
              </div>
            ))}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default EnterpriseContactUs;
