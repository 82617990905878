export const customStyles = {
    control: (base, state) => ({
      ...base,
      // match with the menu
      borderRadius: 3,
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#7782a1" : "#C8CEDB",
      backgroundColor: state.isDisabled ? "#ffffff" : "",
      height: 50,
      minHeight: 50,
      fontSize: 14,
      color: "#7782A1",
      paddingLeft: 10,
      boxShadow: state.isFocused ? "none" : "none",
      "&:hover": {
        boxShadow: state.isFocused ? "none" : "none",
      },
    }),
    valueContainer: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
      fontSize: 14,
      color: "#7782A1",
    }),
    menu: (base) => ({
      ...base,
      fontSize: "14px",
    }),
    input: (base) => ({
      ...base,
      margin: 0,
      padding: 0,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      // color: "#1B2952", // Custom colour
      // marginRight: 20,
    }),
  };