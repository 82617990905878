import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { errortoast, sucesstoast } from "../Toaster";
import postscribe from "postscribe";
// import TextAreaWithLineNumber from 'text-area-with-line-number';
import JoditEditor from "jodit-react";
import CodeMirror from "@uiw/react-codemirror";
// import { javascript } from "@codemirror/lang-javascript";

const config = {
  readonly: false,
  height: "auto",
  toolbar: false,
  // width: "100%",
  toolbarAdaptive: false,
  defaultMode: "2",
  toolbarButtonSize: "middle",
  colorPickerDefaultTab: "background",
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  defaultActionOnPaste: "insert_as_text",
  // defaultActionOnPaste: "insert_clear_html",
  textIcons: false,
  buttons: [
    // "fontsize",
    // "bold",
    // "italic",
    // "underline",
    // "align",
    // "ul",
    // "ol",
    // "link",
  ],
  // controls: {
  //   fontsize: {
  //     list: {
  //       9: "9",
  //       10: "10",
  //       11: "11",
  //       12: "12",
  //       13: "13",
  //       14: "14",
  //       15: "15",
  //       16: "16",
  //       17: "17",
  //       18: "18",
  //       19: "19",
  //       20: "20",
  //     },
  //   },
  // },
  // uploader: { insertImageAsBase64URI: true },
};

const CodeBlock = ({
  indx,
  cmsArray,
  setcmsData,
  isRowOpen,
  handleIsRowOpen,
  deleteRow,
  duplicateItem,
  eachBlock,
  provided,
}) => {
  const editor = useRef(null);
  const [code, setcode] = useState(cmsArray[indx].value.code.text);

  
  const handleCode = (e) => {

  if(cmsArray[indx].value.code.text!==code){
   
    let cmsData = [...cmsArray];
    cmsData[indx].value.code.text = code;
    
    setcmsData(cmsData);
  }

  };

  const handleInputChange = (e) => {
    setcode(e);
  };

  const handleCodeSubmit = () => {
    // try {
    // if (code.trim().substring(0, 7) === "<script") {
    //   let cmsData = [...cmsArray];
    //   cmsData[indx].value.code = code;
    //   setcode(code);
    //   setcmsData(cmsData);
    //   try {
    //     postscribe("#ppppppp", code);
    //   } catch {
    //   }
    //   sucesstoast("Valid");
    // } else {
    //   let cmsData = [...cmsArray];
    //   cmsData[indx].value.code = "";
    //   setcmsData(cmsData);
    //   setcode("");
    //   errortoast("Enter only script tag");
    //   return;
    //   // }
    // } catch {
    // let cmsData = [...cmsArray];
    // cmsData[indx].value.code = e.target.value;
    // setcmsData(cmsData);
    // setcode("");
    // errortoast("Enter valid syntax");
    // }
  };

  // const errorHandling = (code) => {};

  const handleStatus = (e) =>{

    let cmsData = [...cmsArray];
    cmsData[indx].status = ( cmsData[indx].status===1 || cmsData[indx].status===undefined || cmsData[indx].status===null)
    ?0:1;
  
    // setbgColor(e.hex);
    setcmsData(cmsData);
  }

  return (
    <div className={`accordionItem ${cmsArray[indx].status===0?"cms-section-hidden":""}`} id="ppppppp" >
      {/* <script>
        {(function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, "script", "dataLayer", "GTM-P2XMLGW")}
      </script> */}
      {/* <script>{useMemo(() => errorHandling(code), [code])}</script> */}

      {/* <Markup content={code} /> */}

      <h2
        class="accordionItemHeading allflex"
        draggableId={indx.toString()}
        
        {...provided.dragHandleProps}
      >
        Code

        <span className="cms-section-visiblity" onClick={(e)=>handleStatus(e)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="25.284" height="20.465" viewBox="0 0 25.284 20.465">
        <g id="Group_39189" data-name="Group 39189" transform="translate(0.568 0.353)">
          <path id="Path_103079" data-name="Path 103079" d="M68.716,9.742a13.852,13.852,0,0,1-12.07,7.684A13.876,13.876,0,0,1,44.568,9.742,13.876,13.876,0,0,1,56.646,2.058,13.852,13.852,0,0,1,68.716,9.742Z" transform="translate(-44.568 0.138)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1"/>
          <ellipse id="Ellipse_8629" data-name="Ellipse 8629" cx="3.842" cy="3.842" rx="3.842" ry="3.842" transform="translate(8.233 6.038)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1"/>
          <line id="Line_430" data-name="Line 430" x2="19.758" y2="19.758" transform="translate(2.196 0.001)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1"/>
        </g>
      </svg>
    </span>
    
        <p className="cms-accordian-p"
          onClick={(e) => {
            handleIsRowOpen(e, indx);
          }}
        >
          <img
            src={
              isRowOpen[indx] === true
                ? require("../../assets/images/dashboard/cms/accordian-open.png")
                    .default
                : require("../../assets/images/dashboard/cms/accordian-icon.png")
                    .default
            }
          />
        </p>
      </h2>

      {isRowOpen[indx] === true && (
        <div class="accordionItemContent">
          <div className="code-block">
            {/* <textarea
              // key={"lll"}
              className="code-line"
              onBlur={(e) => handleCode(e)}
              onChange={(e) => handleInputChange(e)}
              value={code}
            /> */}
           {/* <TextAreaWithLineNumber  
              onBlur={(e) => handleCode(e)}
              onChange={handleInputChange}
              value={cmsArray[indx].value.code.text}
              /> */}
      
            {/* <JoditEditor
              ref={editor}
              config={config}
              onBlur={(e) => handleCode(e)}
              onChange={(content) => handleInputChange(content)}
              value={code}
            /> */}

        <CodeMirror
        value={code}
        height="200px"
        theme="dark"
       
        onMouseLeave={(e) => handleCode(e)}
        // extensions={[javascript({ jsx: true })]}
        onChange={handleInputChange}
      />


          </div>

          <div className="duplicate-delete">
            <div className="cms-dup-del-btn"   onClick={(e) => duplicateItem(e, eachBlock)}>
            <span class="cms-delete-tooltip">
            <img
                src={
                  require("../../assets/images/dashboard/cms/duplicate.png")
                    .default
                }
              />
               <span class="cms-delete-tooltipsubheader">Duplicate</span>
                  </span>
            </div>

            <div className="cms-dup-del-btn"   onClick={(e) => deleteRow(e, indx)}>
            <span class="cms-delete-tooltip">
            <img
                src={
                  require("../../assets/images/dashboard/cms/delete.png")
                    .default
                }
              />
               <span class="cms-delete-tooltipsubheader">Delete</span>
                  </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CodeBlock;
