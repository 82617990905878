import React, { useEffect, useState } from "react";

const SortIcon = ({
  sortFields,
  tableKey,
  setSortFields,
  handleSortTable,
  heading
}) => {
  const [isClicked, setIsClicked] = useState(false);
  const [value, setValue] = useState(1);

  const handleSortIcons = (obj, key) => {
    Object.keys(obj).forEach(k => obj[k] = k === key ? value : 0);
    return obj;
  };

  useEffect(() => {
    if(sortFields[tableKey] === 0){
      setValue(1)
      setIsClicked(false)
    }
  }, [sortFields])

  const handleFieldChange = () => {
    setValue((p) => {
      if (p === 2) {
        return 0;
      }
      return p + 1;
    });
    setSortFields(handleSortIcons(sortFields, tableKey));
    !value ? setIsClicked(false) : setIsClicked(true);
    handleSortTable(
      tableKey,
      value === 1 ? "asc" : value === 2 ? "dsc" : "reset"
    );
  };

  const renderIcon = () => {
    switch (sortFields[tableKey]) {
      case 0:
        return null;
      case 1:
        return (
          <span className="ml-2">
            <img
              src={
                require("../../assets/images/dashboard/cms/sort-down-arrow.png")
                  .default
              }
              alt=""
            />
          </span>
        );
      case 2:
        return (
          <span className="ml-2">
            <img
              src={
                require("../../assets/images/dashboard/cms/sort-up-arrow.png")
                  .default
              }
              alt=""
            />
          </span>
        );
      default:
        return;
    }
  };

  return (
    <div
      className="sort-icon-container d-flex"
      onClick={() => handleFieldChange(value)}
    >
      {heading}
      <div>
        {!isClicked && (
          <span className="table-sort-icon">
            <span className="ml-2">
              <img
                src={
                  require("../../assets/images/dashboard/cms/sort-down-arrow.png")
                    .default
                }
                alt=""
              />
            </span>
          </span>
        )}
        {isClicked && renderIcon()}
      </div>
    </div>
  );
};

export default SortIcon;
