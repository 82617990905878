import React, { useState } from "react";
import imagebackground from "../../../assets/images/dashboard/cms/uploadbackground.png";
import Reactcolorpicker from "../../../Dashboard/Appearance/NewReactcolorpicker.js";
import LogoUploader from "../../../Dashboard/LogoUploader.js";
import SubMenuSettings from "../../../Components/commonComponents/MenuBuilder/SubMenuSettings.js";
import Tooltip from "../../../Components/commonIcons/Tooltip.js";
import Newfeatureicon from "../../../Components/commonIcons/Newfeatureicon.js";

const MenuSetting = ({ menuState, setMenuState }) => {
  const [openGallerPopUp, setOpenGallerPopUp] = useState(false);

  const settingYourdata = (imgName, from) => {
    if (from !== "original") {
      let currSetting = { ...menuState.settings };
      currSetting.useLogoOrText = "logo";
      currSetting.headerLogo = imgName;
      setMenuState({
        settings: currSetting,
      });
    }
  };

  const yourErrFun = () => {};

  const handleMenuName = (e) => {
    let currSetting = { ...menuState.settings };

    currSetting.headerTitle = e.target.value;

    setMenuState({
      settings: currSetting,
    });
  };

  const handleTextOrLogoInputChange = (e, from) => {
    let currSetting = { ...menuState.settings };

    currSetting.useLogoOrText = currSetting.useLogoOrText !== from ? from : "";

    setMenuState({
      settings: currSetting,
    });
  };

  const handleOpenGallerPopUp = () => {
    setOpenGallerPopUp(!openGallerPopUp);
  };

  const handleColorChange = (color, key) => {
    let currSetting = { ...menuState.settings };
    currSetting[key] = color;
    setMenuState({
      settings: currSetting,
    });
  };

  return (
    <div
      className={
        menuState.openMenuSetting && !menuState.openMenuSorting
          ? "menubuilder-settings"
          : "d-none"
      }
    >
      <div className="menubuilder-settings-head">
        <h3 className="menuBuilder-setting-text">Settings</h3>
        <h4 className="menuBuilder-setting-descrption">
          Customize the appearance of your menu to suit your preference.
        </h4>
      </div>

      <div className="icon-menu-setting">
        <div className="menusettingicondiv">
          <div className="menusettingiconlabel">
            <h6 className="menuBuilder-logolabel">
              Logo{" "}
              <Tooltip
                message={"Upload your logo to be displayed in the app bar."}
              />
            </h6>
            <div className="menusettingiconpick">
              <div
                className="menuiconpictext"
                onClick={(e) => handleOpenGallerPopUp(e)}
              >
                <>
                  {!menuState.settings.headerLogo ? (
                    <img
                      className="img-fluid"
                      src={imagebackground}
                      width="20"
                      alt=""
                    />
                  ) : menuState.settings.headerLogo.indexOf(
                      "cdn-icons-png.flaticon.com"
                    ) > -1 ? (
                    <img
                      className="img-fluid"
                      width="20"
                      alt=""
                      src={menuState.settings.headerLogo}
                    />
                  ) : (
                    <img
                      className="img-fluid"
                      width="20"
                      alt=""
                      src={
                        process.env.REACT_APP_Image_Path +
                        parseInt(localStorage.getItem("user_id")) +
                        "/" +
                        parseInt(localStorage.getItem("Appid")) +
                        process.env.REACT_APP_NEW_ICON_PATH +
                        menuState.settings.headerLogo
                      }
                    />
                  )}
                </>
                {menuState.settings.headerLogo ? (
                  <p className="menuIcon-Name">
                    {menuState.settings.headerLogo}
                  </p>
                ) : (
                  <p
                  className="menuIcon-Name-upload"
                   
                  >
                    Upload
                  </p>
                )}
              </div>
              <label class="setting-menubuilder-radio menubuilder-radio-firstbtn">
                <input
                  type="radio"
                  class="menubuilder-radio-button__input"
                  id="useLogo"
                  name="useLogoName"
                  onChange={(e) => handleTextOrLogoInputChange(e, "logo")}
                  checked={
                    menuState.settings.useLogoOrText === "logo" ? true : false
                  }
                />

                <span
                  class="menubuilder-radio-button__control"
                 
                ></span>
              </label>
            </div>
          </div>

          <p className="select_type_or">OR</p>

          <label className="menusettingiconlabel">
            <h6 className="menuBuilder-logolabel">
              Text
              <Tooltip message={"Display desired text in the app bar."} />
            </h6>
            <div className="menusettingiconpick">
              <input
                className="menu-header-text-input"
                maxLength={20}
                onChange={(e) => handleMenuName(e)}
                value={menuState.settings.headerTitle}
              ></input>
              <label class="setting-menubuilder-radio menubuilder-radio-firstbtn">
                <input
                  type="radio"
                  class="menubuilder-radio-button__input"
                  id="useText"
                  name="useTextName"
                  onChange={(e) => handleTextOrLogoInputChange(e, "text")}
                  checked={
                    menuState.settings.useLogoOrText === "text" ? true : false
                  }
                />
                <span
                  class="menubuilder-radio-button__control"
                 
                ></span>
              </label>
            </div>
          </label>
        </div>
        <div className="menuBuilder-theme-options">
          <div className="w-100">
            <h6 className="menuBuilder-theme-label">
              Text color <Tooltip message={"Define the item text color."} />
            </h6>
            <Reactcolorpicker
              colorname={menuState.settings.headerTextColor}
              onChange={(color) => handleColorChange(color, "headerTextColor")}
              classselection={"fixback"}
              valuecontrol={!menuState.hidecontrol}
              presetColors={menuState.presetColors}
            />
          </div>
          <div className="menuBuilder-divnotrequired"></div>
        </div>
        <div className="menuBuilder-theme-background-color">
          <div className="menuBuilder-colorUpdate-div">
            <h6 className="menuBuilder-theme-label">
              Background color <Tooltip message={"Set the background color."} />
            </h6>
            <Reactcolorpicker
              colorname={menuState.settings.backgroundColor}
              onChange={(color) => handleColorChange(color, "backgroundColor")}
              classselection={"fixback"}
              valuecontrol={menuState.hidecontrol}
              presetColors={menuState.presetColors}
            />
          </div>
          {/* {menuState.isPrimaryMenuEnabled &&
          menuState.isSecondaryMenuEnabled ? ( */}
          <div className="menuBuilder-colorUpdate-div">
            <h6 className="menuBuilder-theme-label">
              Separator color{" "}
              <Tooltip message={"Set the color of the separator."} />
            </h6>
            <Reactcolorpicker
              colorname={menuState.settings.separatorColor}
              onChange={(color) => handleColorChange(color, "separatorColor")}
              classselection={"fixback"}
              valuecontrol={!menuState.hidecontrol}
              presetColors={menuState.presetColors}
            />
          </div>
          {/* ) : (
            <></>
          )} */}
        </div>
      </div>

      {/* {menuState.isPrimaryMenuEnabled && menuState.isMainMenuEnabled ? ( */}
      <SubMenuSettings
        from={"Primary"}
        syncWithWebsiteMenuToggleVal={menuState.isPrimaryMenuSyncWithWP}
        showIcon={menuState.settings.showPrimaryMenuItemsIcon}
        showAsGrid={menuState.settings.showPrimaryMenuItemsAsGrid}
        showText={menuState.settings.showPrimaryMenuItemsText}
        iconShape={menuState.settings.primaryMenuItemsShape}
        gridColumn={menuState.settings.primaryMenuItemsGridCol}
        iconColor={menuState.settings.primaryMenuItemsIconColor}
        itemTextColor={menuState.settings.primaryMenuItemsTextColor}
        iconBGcolor={menuState.settings.primaryMenuItemsIconBGcolor}
        iconBorderColor={menuState.settings.primaryMenuItemsIconBorderColor}
        menuListingData={menuState.primaryMenuListingData}
        setMenuState={setMenuState}
        settings={menuState.settings}
      />
      {/* ) : (
        <></>
      )} */}

      {/* {menuState.isSecondaryMenuEnabled && menuState.isMainMenuEnabled ? ( */}
      <SubMenuSettings
        from={"Secondary"}
        syncWithWebsiteMenuToggleVal={menuState.isSecondaryMenuSyncWithWP}
        showIcon={menuState.settings.showSecondaryMenuItemsIcon}
        showAsGrid={menuState.settings.showSecondaryMenuItemsAsGrid}
        showText={menuState.settings.showSecondaryMenuItemsText}
        iconShape={menuState.settings.secondaryMenuItemsShape}
        gridColumn={menuState.settings.secondaryMenuItemsGridCol}
        iconColor={menuState.settings.secondaryMenuItemsIconColor}
        itemTextColor={menuState.settings.secondaryMenuItemsTextColor}
        iconBGcolor={menuState.settings.secondaryMenuItemsIconBGcolor}
        iconBorderColor={menuState.settings.secondaryMenuItemsIconBorderColor}
        menuListingData={menuState.secondaryMenuListingData}
        setMenuState={setMenuState}
        settings={menuState.settings}
      />
      {/* ) : (
        <></>
      )} */}

      <LogoUploader
        isGalleryPopup={openGallerPopUp}
        setIsGalleryPopup={setOpenGallerPopUp}
        settingYourdata={settingYourdata}
        yourErrFun={yourErrFun}
        uploadPath={
          parseInt(localStorage.getItem("user_id")) +
          "/" +
          parseInt(localStorage.getItem("Appid")) +
          process.env.REACT_APP_NEW_ICON_PATH
        }
        settingOrgImgFrom3rdParty={false}
        hideImageGallery={true}
        widthForRestriction={200}
        heightForRestriction={26}
        aspectRatio={"200:26"}
        cropShape={"sharp"}
        isNotCustomUploadOrgImg={true}
        // designid={""}
        // loadpicVistaCreatePopUp={loadpicVistaCreatePopUp}
        // closeVistaCreatePopUp={closeVistaCreatePopUp}
      />
      <div className="menusetting-haptic-toggle-div">
        <h4 className="menusetting-toggle-div-head">
          Haptic vibration on touch{" "}
          <Tooltip
            message={
              "Enable haptic vibration when app users interact with the Menu. This creates a subtle vibration, enhancing the tactile experience for app users."
            }
          />
          {/* <Newfeatureicon/> */}
        </h4>

        <label className=" common-toogle-switch " htmlFor={"hapticFeedback"}>
          <input
            type="checkbox"
            className="common-switch-input"
            id={"hapticFeedback"}
            name={"hapticFeedback"}
            checked={menuState.settings.isHapticEnabled}
            onChange={(e) => {
              let currObj = { ...menuState.settings };
              currObj.isHapticEnabled = e.target.checked;
              setMenuState({
                settings: currObj,
              });
            }}
          />
          <div className="switch-slider round"></div>
        </label>
      </div>
    </div>
  );
};

export default MenuSetting;
