import React from "react";
import { Media } from "reactstrap";
import styles from "./chart.module.css";
import "./Appcount.css";
import firebaseIc from "../../assets/images/dashboard/firebaseIc.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
class Appcount extends React.Component {
  render() {
    let count = 0;

    return (
      <>

        <div

          className="report_header"
        >
          <div className="d-flex">
            {" "}
            <h3 className="mb-2">Analytics <p className=" consent-tooltip">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                    <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                      <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                        <circle cx="9" cy="9" r="9" stroke="none" />
                        <circle cx="9" cy="9" r="8.25" fill="none" />
                      </g>
                      <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5" />
                      <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5" />
                    </g>
                  </svg>
                  <p className="consent-tooltipsubheader">
                  Unlock insights, explore user behavior, engagement, and key app metrics. Track downloads, user sessions, and content related analytics to make informed decisions and drive success.
                  </p>
                </p></h3>

                <p className="appcount-fireimg1" style={{
              borderLeft:(this.props.value.state.iosnotifyid!==null||this.props.value.state.androidnotifyid!==null)?"":""
            }}>
             
              <span
                style={{
                  color: `${this.props.value.state.analytics_status.colour}`,
                }}
              >
                {this.props.value.state.analytics_status.title}
              </span>
            </p>

            
          </div>
          <div className=" appcount_color">

          <div className="analtics-firebase-btn" onClick={(e) => this.props.value.handleFirebasePopUp(e)}>
            <img
                      src={firebaseIc}
                      alt="firebase"
                      
                    />
                    <p className="analtics-firebase-btn-text">Firebase</p>
            </div>
          {/* <svg
          onClick={(e) => this.props.value.handleFirebasePopUp(e)}
          xmlns="http://www.w3.org/2000/svg" width="20.744" height="28.477" viewBox="0 0 20.744 28.477">
  <g id="Group_29078" data-name="Group 29078" transform="translate(-1596.768 -174.167)">
    <g id="firebase-1" transform="translate(1596.768 174.167)">
      <path id="Path_9361" data-name="Path 9361" d="M0,22.986l.172-.241L8.328,7.271l.017-.164L4.75.354A.667.667,0,0,0,3.5.565Z" transform="translate(0 0)" fill="#ffc24a"/>
      <path id="Path_9362" data-name="Path 9362" d="M1.253,24l.13-.254L9.453,8.431,5.867,1.651a.606.606,0,0,0-1.153.21Z" transform="translate(-1.151 -1.196)" fill="#ffa712"/>
      <path id="Path_9364" data-name="Path 9364" d="M105.067,57.442l2.677-2.741-2.678-5.111a.69.69,0,0,0-1.215,0l-1.431,2.728v.232l2.647,4.892Z" transform="translate(-94.101 -45.227)" fill="#f4bd62"/>
      <path id="Path_9365" data-name="Path 9365" d="M105.312,58.5l2.6-2.665-2.6-4.955a.6.6,0,0,0-1.092-.048l-1.438,2.77-.043.142Z" transform="translate(-94.394 -46.397)" fill="#ffa50e"/>
      <path id="Path_9367" data-name="Path 9367" d="M0,103.016l.078-.079.284-.115L10.8,92.425l.132-.36L8.326,87.1Z" transform="translate(0 -80.03)" fill="#f6820c"/>
      <path id="Path_9368" data-name="Path 9368" d="M11.3,93.948l9.444-5.267-2.7-16.61a.645.645,0,0,0-1.094-.353L0,88.7l9.39,5.243a1.96,1.96,0,0,0,1.91,0" transform="translate(0 -65.719)" fill="#fde068"/>
      <path id="Path_9369" data-name="Path 9369" d="M21.845,89.532,19.168,73.049a.567.567,0,0,0-.983-.359L1.29,89.568l9.287,5.191a1.945,1.945,0,0,0,1.9,0l9.372-5.227Z" transform="translate(-1.185 -66.615)" fill="#fcca3f"/>
      <path id="Path_9370" data-name="Path 9370" d="M11.3,286.206a1.96,1.96,0,0,1-1.91,0L.077,281.038l-.076.08,9.39,5.243a1.96,1.96,0,0,0,1.91,0l9.444-5.267-.023-.142L11.3,286.206Z" transform="translate(-0.001 -258.132)" fill="#eeab37"/>
    </g>
  </g>
</svg> */}

   




            <div className="analytics-track-data">


              <p>Enable analytics:  <p className=" productsetting-tooltip" style={{marginRight: "0px"}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
  <g id="Group_40191" data-name="Group 40191" transform="translate(-1727 -19.132)">
    <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="none" stroke="#bcc1ce" stroke-width="1.2">
      <circle cx="7" cy="7" r="7" stroke="none"/>
      <circle cx="7" cy="7" r="6.4" fill="none"/>
    </g>
    <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
    <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
  </g>
</svg>
                  <p className="productsetting-tooltipsubheader">
                  Enable to track usage analytics of your app. If disabled, your app’s usage data will not be tracked or stored.
                  </p>
                </p></p>
          
              <label className="switch" htmlFor="analyticstoggletrack">
                <input 
                type="checkbox" 
                id="analyticstoggletrack" 
                name="analyticstoggletrack" 
                checked={this.props.value.state.trackData}
                onChange={(e)=>this.props.value.handleTrackDataToggle(e,"toggle")}
                />
                <div className="slider round"></div>
              </label>
            </div>
          </div>
        </div>

        

        <div className="">
          
        <div className="analytics-mob-toggle-div">
              <p className="analytics-mob-track">Enable analytics:  <p className=" productsetting-tooltip">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
  <g id="Group_40191" data-name="Group 40191" transform="translate(-1727 -19.132)">
    <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="none" stroke="#bcc1ce" stroke-width="1.2">
      <circle cx="7" cy="7" r="7" stroke="none"/>
      <circle cx="7" cy="7" r="6.4" fill="none"/>
    </g>
    <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
    <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
  </g>
</svg>
                  <p className="productsetting-tooltipsubheader">
                  Enable to track usage analytics of your app. If disabled, your app’s usage data will not be tracked or stored.
                  </p>
                </p></p>

              <label className="switch" htmlFor="analyticstoggleTrackMob">
                <input 
                type="checkbox" 
                id="analyticstoggleTrackMob" 
                name="analyticstoggleTrackMob"
                checked={this.props.value.state.trackData}
                onChange={(e)=>this.props.value.handleTrackDataToggle(e,"toggle")}
                />
                <div className="slider round"></div>
              </label>
          </div>

        </div>

  {
  this.props.value.state.trackDataFinalVal === true && 

        <div className={styles.installs + " "}>
          <div className="d-flex align-items-center appcount_gplay">
            <div>
              <img
                src={
                  require("../../assets/images/dashboard/analytics/playandroid.png")
                    .default
                }
                className="img-fluid"
                alt="playstore"
              />
            </div>
            <div className=" Appcount_android">
              {this.props.value.state.app_analytics === null || this.props.value.state.app_analytics === undefined ? (
                <h2 className="m-0 appcount_user-stats-data">0</h2>
              ) : (
                <h2 className="m-0 appcount_user-stats-data">
                  {(() => {
                    let androiddefault = this.props.value.state.app_analytics.play_store_app_downloads.default;
                    let androidcustom = this.props.value.state.app_analytics.play_store_app_downloads.custom;
                    if (androiddefault === undefined && androidcustom === undefined) 
                    {
                      return 0;
                    } 
                    else if (androiddefault === undefined) 
                    {
                      if (androidcustom.bundle_id === this.props.value.state.android_bundle_identifier) 
                      {
                        return androidcustom.numberOfInstalls.toLocaleString("en-US");
                      } 
                      else 
                      {
                        return 0;
                      }
                    } 
                    else if (androidcustom === undefined) 
                    {
                      if (androiddefault.bundle_id === this.props.value.state.android_bundle_identifier) 
                      {
                        return androiddefault.numberOfInstalls.toLocaleString("en-US");
                      } 
                      else 
                      {
                        return 0;
                      }
                    } 
                    else if 
                    (
                      androiddefault !== undefined && androidcustom !== undefined
                    ) 
                    {
                      if (androiddefault.bundle_id === this.props.value.state.android_bundle_identifier) 
                      {
                        return androiddefault.numberOfInstalls.toLocaleString("en-US");
                      } 
                      else 
                      {
                        return androidcustom.numberOfInstalls.toLocaleString("en-US");
                      }
                    }
                  })()}
                </h2>
              )}

              <p className="mb-0">Total android downloads</p>
            </div>
          </div>
          <div className="d-flex align-items-center  appcount_gplay">
            <div>
              <img
                src={
                  require("../../assets/images/dashboard/analytics/app-storea36.png")
                    .default
                }
                className="img-fluid"
                alt="appstore"
              />
            </div>
            <div className="Appcount_android">
              <p className="mb-0"><a  style={{color: "#3064F9"}} target="_blank" href={`https://appstoreconnect.apple.com/analytics/apps/d30`}> Total iOS downloads <img
                      src={
                        require("../../assets/images/dashboard/Goto.png")
                          .default
                      }
                      className=""
                      alt="Goto"
                    /></a></p>
            </div>
          </div>
          <div className="d-flex align-items-center ">
            <div>
              <img
                src={
                  require("../../assets/images/dashboard/analytics/usercount-ana.png")
                    .default
                }
                className="img-fluid"
                alt="appstore"
              />
            </div>
            <div className="  Appcount_android">
              {this.props.value.state.reportdata.map((res) => {
                if (
                  res.item_type &&
                  res.item_type === "app_user_count" && res.value
                ) {
                  count = res.value;
                }
              })}
              <h2 className="m-0 appcount_user-stats-data">{count.toLocaleString("en-US")}</h2>
              <p className="mb-0">Total unique users </p>
            </div>
          </div>
        </div>
  }

      </>
    );
  }
}

export { Appcount as default };
