export const languagesArray =   [
  {
      "name": "Afrikaans",
      "code": "af",
      "oriname": "Afrikaans",
      "type": "LTR",
      value :"af",
      label :"Afrikaans",
  },
  {
      "name": "Amharic",
      "code": "am",
      "oriname": "አማርኛ",
      "type": "LTR",
      value :"am",
      label :"Amharic",
  },
  {
      "name": "Arabic",
      "code": "ar",
      "oriname": "العربية",
      "type": "RTL",
      value :"ar",
      label :"Arabic",
  },
  {
      "name": "Bulgarian",
      "code": "bg_BG",
      "oriname": "Български",
      "type": "LTR",
      value :"bg_BG",
      label :"Bulgarian",
  },
  {
      "name": "Catalan",
      "code": "ca",
      "oriname": "Català",
      "type": "LTR",
      value :"ca",
      label :"Catalan",
  },
  {
      "name": "Catalan (Balear)",
      "code": "bal",
      "oriname": "Català (Balear)",
      "type": "LTR",
      value :"bal",
      label :"Catalan (Balear)",
  },
  {
      "name": "Chinese (China)",
      "code": "zh_CN",
      "oriname": "简体中文",
      "type": "LTR",
      value :"zh_CN",
      label :"Chinese (China)",
  },
  {
      "name": "Chinese (Hong Kong)",
      "code": "zh_HK",
      "oriname": "香港中文版",
      "type": "LTR",
      value :"zh_HK",
      label :"Chinese (Hong Kong)",
  },
  {
      "name": "Chinese (Taiwan)",
      "code": "zh_TW",
      "oriname": "繁體中文",
      "type": "LTR",
      value :"zh_TW",
      label :"Chinese (Taiwan)",
  },
  {
      "name": "Croatian",
      "code": "hr",
      "oriname": "Hrvatski",
      "type": "LTR",
      value :"hr",
      label :"Croatian",
  },
  {
      "name": "Czech",
      "code": "cs_CZ",
      "oriname": "Čeština‎",
      "type": "LTR",
      value :"cs_CZ",
      label :"Czech",
  },
  {
      "name": "Dutch",
      "code": "nl_NL",
      "oriname": "Nederlands",
      "type": "LTR",
      value :"nl_NL",
      label :"Dutch",
  },
  {
      "name": "Dutch (Belgium)",
      "code": "nl_BE",
      "oriname": "Nederlands (België)",
      "type": "LTR",
      value :"nl_BE",
      label :"Dutch (Belgium)",
  },
  {
      "name": "Danish",
      "code": "da_DK",
      "oriname": "Dansk",
      "type": "LTR",
      value :"da_DK",
      label :"Danish",
  },
  {
      "name": "English (Australia)",
      "code": "en_AU",
      "oriname": "English (Australia)",
      "type": "LTR",
      value :"en_AU",
      label :"English (Australia)",
  },
  {
      "name": "English (Canada)",
      "code": "en_CA",
      "oriname": "English (Canada)",
      "type": "LTR",
      value :"en_CA",
      label :"English (Canada)",
  },
  {
      "name": "English (New Zealand)",
      "code": "en_NZ",
      "oriname": "English (New Zealand)",
      "type": "LTR",
      value :"en_NZ",
      label :"English (New Zealand)",
  },
  {
      "name": "English (South Africa)",
      "code": "en_ZA",
      "oriname": "English (South Africa)",
      "type": "LTR",
      value :"en_ZA",
      label :"English (South Africa)",
  },
  {
      "name": "English (UK)",
      "code": "en_GB",
      "oriname": "English (UK)",
      "type": "LTR",
      value :"en_GB",
      label :"English (UK)",
  },
  {
      "name": "English (United States)",
      "code": "en",
      "oriname": "English (United States)",
      "type": "LTR",
      value :"en",
      label :"English (United States)",
  },
  {
      "name": "Finnish",
      "code": "fi",
      "oriname": "Suomi",
      "type": "LTR",
      value :"fi",
      label :"Finnish",
  },
  {
      "name": "French (Belgium)",
      "code": "fr_BE",
      "oriname": "Français de Belgique",
      "type": "LTR",
      value :"fr_BE",
      label :"French (Belgium)",
  },
  {
      "name": "French (Canada)",
      "code": "fr_CA",
      "oriname": "Français du Canada",
      "type": "LTR",
      value :"fr_CA",
      label :"French (Canada)",
  },
  {
      "name": "French (France)",
      "code": "fr_FR",
      "oriname": "Français",
      "type": "LTR",
      value :"fr_FR",
      label :"French (France)",
  },
  {
      "name": "German",
      "code": "de_DE",
      "oriname": "Deutsch",
      "type": "LTR",
      value :"de_DE",
      label :"German",
  },
  {
      "name": "German (Switzerland)",
      "code": "de_CH",
      "oriname": "Deutsch (Schweiz)",
      "type": "LTR",
      value :"de_CH",
      label :"German (Switzerland)",
  },
  {
      "name": "Hebrew",
      "code": "he_IL",
      "oriname": "עִבְרִית",
      "type": "RTL",
      value :"he_IL",
      label :"Hebrew",
  },
  {
      "name": "Hindi",
      "code": "hi_IN",
      "oriname": "हिन्दी",
      "type": "LTR",
      value :"hi_IN",
      label :"Hindi",
  },
  {
      "name": "Indonesian",
      "code": "id_ID",
      "oriname": "Bahasa Indonesia",
      "type": "LTR",
      value :"id_ID",
      label :"Indonesian",
  },
  {
      "name": "Italian",
      "code": "it_IT",
      "oriname": "Italiano",
      "type": "LTR",
      value :"it_IT",
      label :"Italian",
  },
  {
      "name": "Japanese",
      "code": "ja",
      "oriname": "日本語",
      "type": "LTR",
      value :"ja",
      label :"Japanese",
  },
  {
      "name": "Korean",
      "code": "ko_KR",
      "oriname": "한국어",
      "type": "LTR",
      value :"ko_KR",
      label :"Korean",
  },
  {
      "name": "Malay",
      "code": "ms_MY",
      "oriname": "Bahasa Melayu",
      "type": "LTR",
      value :"ms_MY",
      label :"Malay",
  },
  {
      "name": "Norwegian (Bokmål)",
      "code": "nb_NO",
      "oriname": "Norsk bokmål",
      "type": "LTR",
      value :"nb_NO",
      label :"Norwegian (Bokmål)",
  },
  {
      "name": "Norwegian (Nynorsk)",
      "code": "nn_NO",
      "oriname": "Norsk nynorsk",
      "type": "LTR",
      value :"nn_NO",
      label :"Norwegian (Nynorsk)",
  },
  {
      "name": "Polish",
      "code": "pl_PL",
      "oriname": "Polski",
      "type": "LTR",
      value :"pl_PL",
      label :"Polish",
  },
  {
      "name": "Persian",
      "code": "fa_IR",
      "oriname": "فارسی",
      "type": "RTL",
      value :"fa_IR",
      label :"Persian",
  },
  {
      "name": "Persian (Afghanistan)",
      "code": "fa_AF",
      "oriname": "(فارسی (افغانستان",
      "type": "RTL",
      value :"fa_AF",
      label :"Persian (Afghanistan)",
  },
  {
      "name": "Portuguese (Brazil)",
      "code": "pt_BR",
      "oriname": "Português do Brasil",
      "type": "LTR",
      value :"pt_BR",
      label :"Portuguese (Brazil)",
  },
  {
      "name": "Portuguese (Portugal)",
      "code": "pt_PT",
      "oriname": "Português",
      "type": "LTR",
      value :"pt_PT",
      label :"Portuguese (Portugal)",
  },
  {
      "name": "Russian",
      "code": "ru_RU",
      "oriname": "Русский",
      "type": "LTR",
      value :"ru_RU",
      label :"Russian",
  },
  {
      "name": "Spanish (Argentina)",
      "code": "es_AR",
      "oriname": "Español de Argentina",
      "type": "LTR",
      value :"es_AR",
      label :"Spanish (Argentina)",
  },
  {
      "name": "Spanish (Chile)",
      "code": "es_CL",
      "oriname": "Español de Chile",
      "type": "LTR",
      value :"es_CL",
      label :"Spanish (Chile)",
  },
  {
      "name": "Spanish (Colombia)",
      "code": "es_CO",
      "oriname": "Español de Colombia",
      "type": "LTR",
      value :"es_CO",
      label :"Spanish (Colombia)",
  },
  {
      "name": "Spanish (Costa Rica)",
      "code": "es_CR",
      "oriname": "Español de Costa Rica",
      "type": "LTR",
      value :"es_CR",
      label :"Spanish (Costa Rica)",
  },
  {
      "name": "Spanish (Guatemala)",
      "code": "es_GT",
      "oriname": "Español de Guatemala",
      "type": "LTR",
      value :"es_GT",
      label :"Spanish (Guatemala)",
  },
  {
      "name": "Spanish (Mexico)",
      "code": "es_MX",
      "oriname": "Español de México",
      "type": "LTR",
      value :"es_MX",
      label :"Spanish (Mexico)",
  },
  {
      "name": "Spanish (Peru)",
      "code": "es_PE",
      "oriname": "Español de Perú",
      "type": "LTR",
      value :"es_PE",
      label :"Spanish (Peru)",
  },
  {
      "name": "Spanish (Puerto Rico)",
      "code": "es_PR",
      "oriname": "Español de Puerto Rico",
      "type": "LTR",
      value :"es_PR",
      label :"Spanish (Puerto Rico)",
  },
  {
      "name": "Spanish (Spain)",
      "code": "es_ES",
      "oriname": "Español",
      "type": "LTR",
      value :"es_ES",
      label :"Spanish (Spain)",
  },
  {
      "name": "Spanish (Uruguay)",
      "code": "es_UY",
      "oriname": "Español de Uruguay",
      "type": "LTR",
      value :"es_UY",
      label :"Spanish (Uruguay)",
  },
  {
      "name": "Spanish (Venezuela)",
      "code": "es_VE",
      "oriname": "Español de Venezuela",
      "type": "LTR",
      value :"es_VE",
      label :"Spanish (Venezuela)",
  },
  {
      "name": "Swahili",
      "code": "sw",
      "oriname": "Kiswahili",
      "type": "LTR",
      value :"sw",
      label :"Swahili",
  },
  {
      "name": "Swedish",
      "code": "sv_SE",
      "oriname": "Svenska",
      "type": "LTR",
      value :"sv_SE",
      label :"Swedish",
  },
  {
      "name": "Swiss German",
      "code": "gsw",
      "oriname": "Schwyzerdütsch",
      "type": "LTR",
      value :"gsw",
      label :"Swiss German",
  },
  {
      "name": "Thai",
      "code": "th",
      "oriname": "ไทย",
      "type": "LTR",
      value :"th",
      label :"Thai",
  },
  {
      "name": "Turkish",
      "code": "tr_TR",
      "oriname": "Türkçe",
      "type": "LTR",
      value :"tr_TR",
      label :"Turkish",
  },
  {
      "name": "Vietnamese",
      "code": "vi",
      "oriname": "Tiếng Việt",
      "type": "LTR",
      value :"vi",
      label :"Vietnamese",
  },
  {
      "name": "Zulu",
      "code": "zul",
      "oriname": "isiZulu",
      "type": "LTR",
      value :"zul",
      label :"Zulu",
  }
]