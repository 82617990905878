import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import ImageDownloadIcon from "../../../Components/commonComponents/AppIcon/ImageDownloadIcon";
import GridlinesTooltip from "../../../Components/commonComponents/AppIcon/GridlinesTooltip";
import { downloadFile } from "../../../utils/commonUtils";
import "@lottiefiles/lottie-player";
import animation from "../../../assets/json/AI_loader.json";
const AppIconPreview = ({
  appIconState,
  setAppIconState,
  handleImgDownloadPopUp,
  handleVistaPopup,
  handleImgGalleryPopUp,
}) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const appIconUrl = appIconState.appIcon?.includes("create.vista.com")
    ? appIconState.appIcon
    : appIconState.appIcon?.includes("ams")
    ? process.env.REACT_APP_Image_Path +
      localStorage.getItem("user_id") +
      "/" +
      localStorage.getItem("Appid") +
      process.env.REACT_APP_AppIcon_Crop +
      appIconState.appIcon
    : "";

  const appIconErrorUrl =
    process.env.REACT_APP_Image_Path +
    localStorage.getItem("user_id") +
    "/" +
    localStorage.getItem("Appid") +
    process.env.REACT_APP_AppIcon_Display +
    appIconState.appIcon;

  const handlegridbutton = () => {
    setAppIconState({ openVistaPopup: false });
    setAppIconState({
      hideGrideBtn: !appIconState.hideGrideBtn,
    });
  };
  const handleSliderArrow = (e, slide) => {
    setAppIconState({ openVistaPopup: false });
    setActiveSlide(slide);
    if (!slide) {
      document.querySelector(".icon-slide-2")?.classList.add("d-none");
    } else {
      document.querySelector(".icon-slide-2")?.classList.remove("d-none");
    }
  };

  return (
    <>
      {appIconState.isAppIconDefault ? (
        <img
          className="app-icon-default"
          src={
            require("../../../assets/images/dashboard/app-icon-default.png")
              .default
          }
          alt="App-icon-default"
        />
      ) : (
        <>
          <div className="apperance-slider">

            <div className="icon-slide-controls">
              <div
                className={`icon-left-arow ${
                  !activeSlide ? "arrow-disabled" : ""
                }`}
                onClick={(e) => handleSliderArrow(e, 0)}
              ></div>
              <div
                className={`icon-right-arow ${
                  activeSlide ? "arrow-disabled" : ""
                }`}
                onClick={(e) => handleSliderArrow(e, 1)}
              ></div>
            </div> 
            

            <Carousel
              selectedItem={activeSlide}
              // onChange={(e) => handlesliderChange(e)}
              showArrows={false}
              swipeable={true}
              showThumbs={false}
            >
              <div className="the-icon-width myFirstdiv">
                <div className="icon-slide-1">
                  <div
                    className="app-icon-download"
                    onClick={() => {
                      if (appIconState.hideGrideBtn) {
                        if (window.matchMedia("(max-width: 1200px)").matches) {
                          handleImgDownloadPopUp();
                        } else {
                          downloadFile(appIconUrl, "ams_app_icon");
                        }
                      }
                    }}
                  >
                    <img
                      className="img-fluid"
                      src={appIconUrl}
                      onError={({ currentTarget }) => {
                        currentTarget.src = appIconErrorUrl;
                      }}
                      alt="App-icon-default"
                    />
                    {appIconState.hideGrideBtn ? (
                      <ImageDownloadIcon />
                    ) : (
                      <>
                        <div className="app-icon-grid">
                          <img
                            src={
                              require("../../../assets/images/dashboard/app-icon-grid.png")
                                .default
                            }
                            className="img-fluid"
                          />
                        </div>
                      </>
                    )}
                  </div>

                  <>
                  {appIconState.hideGrideBtn ? (
                    <>
                      {!appIconState.isAppIconDefault ? (
                        <div
                          className="app-icon-the-tooltip"
                          onClick={() => handlegridbutton()}
                        >
                          <p className="app-icon-grid-line-icon-show">
                            Show gridlines
                          </p>
                          <GridlinesTooltip
                            text={
                              "Gridlines keep your design centered and aligned. They also make sure your design does not spill out upon rendering."
                            }
                          />
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <div
                      className="app-icon-the-tooltip"
                      onClick={() => handlegridbutton()}
                    >
                      <p className="app-icon-grid-line-icon-hide">
                        Hide gridlines
                      </p>
                      <GridlinesTooltip
                        text={
                          "Gridlines keep your design centered and aligned. They also make sure your design does not spill out upon rendering."
                        }
                      />
                    </div>
                  )}
                  </>


                  <p
                    className="edit-design-mobile-link"
                    onClick={() => handleVistaPopup()}
                  >
                    {appIconState.vistaDesignId
                      ? "Edit design"
                      : "Start designing"}
                  </p>

                  <div className="app-icon-options d-flex d-xl-none">
                    <span onClick={() => handleImgGalleryPopUp()}>
                      or upload an image
                    </span>
                    {parseInt(localStorage.getItem("agencyid")) > 1 ? (
                      <></>
                    ) : (
                      <Link to="/app/addons" className="ask-an-expert">
                        <img
                          src={
                            require("../../../assets/images/dashboard/diamond.png")
                              .default
                          }
                          alt="diamond"
                        />{" "}
                        Ask an expert
                      </Link>
                    )}
                  </div>
                </div>
              </div>
             
              <div className="the-icon-width mySeconddiv">
                <div className="icon-slide-2 d-none">
                  <div className="app-icon-preview">
                    <img
                      src={
                        require("../../../assets/images/dashboard/new-mobile-screen.png")
                          .default
                      }
                      className="img-fluid"
                      alt="icon-preview"
                    />
                    <div className="app-icon-small">
                      <div className="app-icon-small-preview">
                        <img
                          src={appIconUrl}
                          className="img-fluid"
                          onError={({ currentTarget }) => {
                            currentTarget.src = appIconErrorUrl;
                          }}
                          alt="icon"
                        />
                      </div>
                      <p>{localStorage.getItem("Appname")}</p>
                    </div>
                  </div>


                  <p
                    className="edit-design-mobile-link"
                    onClick={() => handleVistaPopup()}
                  >
                    {appIconState.vistaDesignId
                      ? "Edit design"
                      : "Start designing"}
                  </p>

                  <div className="app-icon-options d-flex d-xl-none">

                    <span onClick={() => handleImgGalleryPopUp()}>
                      or upload an image
                    </span>

                    {parseInt(localStorage.getItem("agencyid")) > 1 ? (
                      <></>
                    ) : (
                      <Link to="/app/addons" className="ask-an-expert">
                        <img
                          src={
                            require("../../../assets/images/dashboard/diamond.png")
                              .default
                          }
                          alt="diamond"
                        />{" "}
                        Ask an expert
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </>
      )}
    </>
  );
};

export default AppIconPreview;
