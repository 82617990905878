import React from 'react'; 
import styles from './multisite.module.css'; 
export const Popupnotanyapp = () => {
    return(
       <div className={"text-center mt-5 mb-5 " + styles.notanyapp}>
           <img src={require("../../assets/images/dashboard/popupnotapp.png").default} alt="notanyapp" className="img-fluid"/>
           {/* <img
                            src={
                              require("../../assets/images/dashboard/no-media.png")
                                .default
                            }
                            alt="no-media"
                            className="img-fluid"
                          />
                          <p className='notingat-the-moment'>Nothing here at the moment</p> */}
           <h2 className="">No apps found for merging
           </h2>
           <p >No apps available for merging. While you may have other apps in your account, they may not be compatible for merging.</p>
       </div>
    )
}
