import React from "react";
import "../assets/css/Getstarted.css";
import "../assets/css/Media.css";
import { Link } from "react-router-dom";
import Header from "../Dashboard/Header";
import { getFoot } from "../Dashboard/Foot";

const Getstartedsection = () => {
  return (
    <>
      <section className="height64 height64-getstarted">
        <div className="container">
          <div className="d-block d-md-flex justify-content-between w-t-ams ipad-block ipad-getstarted-flex getstarted-page">
            <div className="d-none d-lg-block ipdad-video ipdad-video-display">
              <img
                src={
                  require("../assets/images/getstarted/getstarted.svg").default
                }
                className="addvideo m-auto img-fluid"
                alt="getstarted"
              />
              {/* <video autoPlay playsInline loop  muted className="addvideo m-auto img-fluid d-none d-md-block" src={require("../assets/images/getstarted/white-rectangle-video.mp4").default} >
				<source src={require("../assets/images/getstarted/white-rectangle-video.mp4").default} type="video/mp4" />
				</video> */}
            </div>
            <div className="welcome welcome-laptop">
              <h1>
                Welcome to <span>AppMySite!</span>
              </h1>
              <p className="d-block  launch-steps launch-steps-red">
                You are now ready to build your mobile app. Just follow these
                easy steps to get your app live.
              </p>

              <div className="d-none">
                <img
                  src={
                    require("../assets/images/getstarted/getstarted.png")
                      .default
                  }
                  className="addvideo m-auto img-fluid"
                  alt="getstarted"
                />
                {/* <video autoPlay playsInline loop  muted className="addvideo m-auto img-fluid" src={require("../assets/images/getstarted/white-rectangle-video.mp4").default} >
							<source src={require("../assets/images/getstarted/white-rectangle-video.mp4").default} type="video/mp4" />
						</video> */}
              </div>
              <div className="threestep">
                <div className="d-flex  justify-content-start align-items-center greentick greentick-red">
                  <img
                    src={
                      require("../assets/images/getstarted/greentick.png")
                        .default
                    }
                    alt="Greentick"
                    className="img-fluid"
                  />
                  <h4 className="ml-2">Personalize Your App</h4>
                </div>
                <div className="d-flex  justify-content-start align-items-center greentick greentick-red">
                  <img
                    src={
                      require("../assets/images/getstarted/greentick.png")
                        .default
                    }
                    alt="Greentick"
                    className="img-fluid"
                  />
                  <h4 className="ml-2">Connect Your Website</h4>
                </div>
                <div className="d-flex  justify-content-start align-items-center">
                  <img
                    src={
                      require("../assets/images/getstarted/greentick.png")
                        .default
                    }
                    alt="Greentick"
                    className="img-fluid"
                  />
                  <h4 className="ml-2">Publish Your App</h4>
                </div>
              </div>
              <div className="greytext">
                {/* <p className="d-none d-md-block">** Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p> */}
                <div className="d-block d-md-flex justify-content-start align-items-center start-btn ipad-block">
                  <Link
                    to={`/app/create${
                      +localStorage.getItem("workspaceId") !== "" &&
                      localStorage.getItem("workspaceId") !== "undefined" &&
                      localStorage.getItem("workspaceId") !== undefined &&
                      localStorage.getItem("workspaceId") !== "null" &&
                      localStorage.getItem("workspaceId") !== null
                        ? "/" + btoa(localStorage.getItem("workspaceId"))
                        : ""
                    }`}
                    className="sendbtn"
                  >
                    Create App
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

// Header Section End

export default class Getstarted extends React.Component {
  componentDidMount() {
    if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
    {
      document.title = "Welcome to AppMySite";
    }
    else
    {
      document.title = "Welcome to"+ ' ' +localStorage.getItem('agencyname');      
    }
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <Header getstartedhide={"d-block"} customclass={"ipad-getstarted-new"} />
        <Getstartedsection />
        <div  className="getstarted-footer">
          {/* {getFoot()} */}
        </div>
      </>
    );
  }
}
