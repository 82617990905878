// constants.js

import { getLocalStorageNumber } from "../../utils/commonUtils";

export const BARCOLORSETTINGS = [
  {
    key: "bottom_menu_bg_colour",
    label: "Background color",
    tooltip: "Set the background color for your Bottom bar.",
    isDisabled: false,
    hidecontrol: false,
  },
  {
    key: "bottom_menu_border_colour",
    label: "Border color",
    tooltip: "Choose the border color of your Bottom bar.",
    isDisabled: false,
    hidecontrol: true,
  },
  {
    key: "bottom_menu_icon_colour",
    label: "Icon color",
    tooltip:
      "Choose a color for icons in the Bottom bar. This feature currently only works if a solid black icon is selected from the icon selection pop up.",
    isDisabled: true,
    hidecontrol: true,
  },
  {
    key: "bottom_menu_selected_icon_colour",
    label: "Selected icon color",
    tooltip: "Specify the color of selected icons in the Bottom bar",
    isDisabled: true,
    hidecontrol: true,
  },
  {
    key: "bottom_menu_text_colour",
    label: "Text color",
    tooltip: "Define the color of the text in the Bottom bar.",
    isDisabled: false,
    hidecontrol: true,
  },
  {
    key: "bottom_menu_selected_text_colour",
    label: "Selected text color",
    tooltip: "Specify the color of selected text in the Bottom bar.",
    isDisabled: false,
    hidecontrol: true,
  },
];

export const MENUCOLORSETTING = [
  {
    key: "bottom_menu_grid_view_icon_color",
    label: "Icon color",
    tooltip: "Set the background color for your Bottom bar.",
    isDisabled: true,
    hidecontrol: true,
  },
  {
    key: "bottom_menu_grid_view_icon_text_color",
    label: "Text color",
    tooltip: "Define the color of the item names in the Bottom menu",
    isDisabled: false,
    hidecontrol: true,
  },
  {
    key: "bottom_menu_grid_view_tile_background_color",
    label: "Tile background color",
    tooltip: "Set the tile background color",
    isDisabled: false,
    hidecontrol: false,
  },
  {
    key: "bottom_menu_grid_view_tile_border_color",
    label: "Tile border color",
    tooltip: "Define the tile border color.",
    isDisabled: false,
    hidecontrol: true,
  },
  {
    key: "bottom_menu_grid_view_selected_icon_color",
    label: "Selected icon color",
    tooltip: "Define the color of the selected icon in the Bottom menu.",
    isDisabled: true,
    hidecontrol: false,
  },
  {
    key: "bottom_menu_grid_view_selected_icon_text_color",
    label: "Selected text color",
    tooltip: "Define the color of the selected item name in the Bottom menu",
    isDisabled: false,
    hidecontrol: true,
  },
  {
    key: "bottom_menu_grid_view_selected_tile_background_color",
    label: "Selected tile background color",
    tooltip: "Set the selected tile background color.",
    isDisabled: false,
    hidecontrol: false,
  },
  {
    key: "bottom_menu_grid_view_selected_tile_border_color",
    label: "Selected tile border color",
    tooltip: "Define the selected tile border color.",
    isDisabled: false,
    hidecontrol: true,
  },
  {
    key: "bottom_menu_grid_view_bg_color",
    label: "Background color",
    tooltip: "Customize the background color of the Bottom menu.",
    isDisabled: false,
    hidecontrol: false,
  },
  {
    key: "bottom_menu_grid_page_bg_color",
    label: "Page background color",
    tooltip: "Customize the background color of the page.",
    isDisabled: false,
    hidecontrol: true,
  },
];

export const WORDPRESSOPTIONS = [
  {
    id: "chat",
    item_name: "Chat",
    item_type: "chat",
    is_tree: "",
  },
  {
    id: 0,
    item_name: "Home screen",
    item_type: "home",
    is_tree: "",
  },
  {
    id: 1,
    item_name: "Menu",
    item_type: "main_menu",
    is_tree: "",
  },
  {
    id: 2,
    item_name: "Pages",
    item_type: "pages",
    is_tree: "",
  },
  {
    id: 3,
    item_name: "Profile",
    item_type: "profile",
    is_tree: "",
  },
  {
    id: 4,
    item_name: "Settings",
    item_type: "settings",
    is_tree: "",
  },
  {
    id: 5,
    item_name: "Web view",
    item_type: "web_view",
    is_tree: "",
  },
];
export const WOOCOMMERCEOPTIONS = [
  {
    id: 1,
    item_name: "Cart",
    item_type: "cart",
    is_tree: "",
  },
  {
    id: 2,
    item_name: "Chat",
    item_type: "chat",
    is_tree: "",
  },
  {
    id: 3,
    item_name: "Home screen",
    item_type: "home",
    is_tree: "",
  },
  {
    id: 4,
    item_name: "Menu",
    item_type: "main_menu",
    is_tree: "",
  },
  {
    id: 5,
    item_name: "Orders",
    item_type: "orders",
    is_tree: "",
  },
  {
    id: 6,
    item_name: "Pages",
    item_type: "pages",
    is_tree: "",
  },
  {
    id: 7,
    item_name: "Product categories",
    item_type: "product_categories",
    is_tree: "",
  },
  {
    id: 8,
    item_name: "Profile",
    item_type: "profile",
    is_tree: "",
  },
  {
    id: 9,
    item_name: "Settings",
    item_type: "settings",
    is_tree: "",
  },
  {
    id: 10,
    item_name: "Web view",
    item_type: "web_view",
    is_tree: "",
  },
];
export const CUSTOMAPPOPTIONS = [
  {
    id: 1,
    name: "Chat",
    item_type: "chat",
    post_type: "",
    rest_base: "",
    slug: "",

    is_tree: "",
  },
  {
    id: 2,
    name: "Menu",
    item_type: "main_menu",
    post_type: "",
    rest_base: "",
    slug: "",
    is_tree: "",
  },
  {
    id: 3,
    name: "Profile",
    item_type: "profile",
    post_type: "",
    rest_base: "",
    slug: "",
    is_tree: "",
  },
  {
    id: 4,
    name: "Settings",
    item_type: "settings",
    post_type: "",
    rest_base: "",
    slug: "",

    is_tree: "",
  },
  {
    id: 5,
    name: "Web View",
    item_type: "web_view",
    post_type: "",
    rest_base: "",
    slug: "",

    is_tree: "",
  },
];
export const W2APPOPTIONS = [
  {
    id: 0,
    item_name: "Chat",
    item_type: "chat",
    is_tree: "",
  },
  {
    id: 1,
    item_name: "Menu",
    item_type: "main_menu",
    is_tree: "",
  },
  {
    id: 2,
    item_name: "Settings",
    item_type: "settings",
    is_tree: "",
  },
  {
    id: 3,
    item_name: "Web view",
    item_type: "web_view",
    is_tree: "",
  },
];

export const DEFAULT_ITEM_POPUP = {
  title: "Choose a default item",
  description:
    "You must select a default item. The default item will appear every time your app is launched, providing quick access to your most important feature.",
  submitButton: "Okay",
};

export const MAIN_MODULE_VALIDATION_POPUP = {
  title: "Default landing screen not selected",
  description:
    "You cannot disable both navigation options. Please ensure either the Bottom Bar or the Menu is enabled. At least one navigation option must be enabled.",
  submitButton: "Okay",
};

export const LINK_ARRAY = [
  {
    link: "/app/home",
    name: "Home screen",
    is_active: false,
    is_show:
      getLocalStorageNumber("website_technology") === 4 ||
      getLocalStorageNumber("website_technology") === 5,
  },
  {
    link: "/app/bottom-bar",
    name: "Bottom bar",
    is_active: true,
    is_show: true,
  },
  {
    link: "/app/menu",
    name: "Menu",
    is_active: false,
    is_show: true,
  },
];

export const SETTINGS = {
  bottom_menu_bg_colour: "rgba(255,255,255,1)",
  bottom_menu_border_colour: "rgba(222,222,222,1)",
  bottom_menu_icon_colour: "rgba(26,26,26,1)",
  bottom_menu_selected_icon_colour: "rgba(161,161,161,1)",
  bottom_menu_text_colour: "rgba(26,26,26,1)",
  bottom_menu_selected_text_colour: "rgba(161,161,161,1)",
  bottom_menu_show_icon: 1,
  bottom_menu_show_text: 1,
  bottom_menu_text_for_menu: "More",
  bottom_menu_icon_for_menu: "",
  bottom_menu_icon_colour_type_for_menu: "",
  bottom_menu_show_grid_view: 1,
  bottom_menu_grid_view_show_icon: 1,
  bottom_menu_grid_view_show_text: 1,
  bottom_menu_grid_view_tile_shape: "square",
  bottom_menu_grid_view_column: 3,
  bottom_menu_grid_view_icon_color: "rgba(26,26,26,1)",
  bottom_menu_grid_view_icon_text_color: "rgba(26,26,26,1)",
  bottom_menu_grid_view_tile_background_color: "rgba(249,249,249, 0.7)",
  bottom_menu_grid_view_tile_border_color: "rgba(222,222,222,1)",
  bottom_menu_grid_view_selected_icon_color: "rgba(161,161,161,1)",
  bottom_menu_grid_view_selected_icon_text_color: "rgba(161,161,161,1)",
  bottom_menu_grid_view_selected_tile_background_color: "rgba(255,255,255,0.7)",
  bottom_menu_grid_view_selected_tile_border_color: "rgba(161,161,161,1)",
  bottom_menu_grid_view_bg_color: "rgba(255,255,255,1)",
  bottom_menu_grid_page_bg_color: "rgba(161,161,161,1)",
  enable_haptic_on_touch: true,
};
