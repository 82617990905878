import moment from "moment";

export const MetaTitle = (meta) => {
  let metaTitle = "";

  if (
    !localStorage.getItem("agencyid") ||
    parseInt(localStorage.getItem("agencyid")) === 1
  ) {
    metaTitle = `${meta} | AppMySite`;
  } else {
    metaTitle = `${meta} | ${localStorage.getItem("agencyname")}`;
  }

  return metaTitle;
};

export const getLocalStorageNumber = (key) => {
  return parseInt(localStorage.getItem(key), 10);
};

export const getBase64FromUrl = async (useUrl) => {
  try {
    const data = await fetch(useUrl);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  } catch {
    return "";
  }
};

export const convertHTMLEntity = (text) => {
  if (!text) {
    return "";
  }
  const span = document.createElement("span");

  return text.replace(/&[#A-Za-z0-9]+;/gi, (entity, position, text) => {
    span.innerHTML = entity;
    return span.innerText;
  });
};

export const hexToRgb = (hex) => {
  if (hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? [
          parseInt(result[1], 16),
          parseInt(result[2], 16),
          parseInt(result[3], 16),
        ]
      : null;
  }
};

export const countBlackIcons = (data, totalItems = 0, blackIconItems = 0) => {
  data &&
    data.forEach((item) => {
      blackIconItems++;
      if (item.item_icon_flaticon_color === "black") {
        totalItems++;
      }
      if (item.children && item.children.length > 0) {
        const result = countBlackIcons(
          item.children,
          totalItems,
          blackIconItems
        );
        totalItems = result.totalItems;
        blackIconItems = result.blackIconItems;
      }
    });
  return { totalItems, blackIconItems };
};

export const formatedDateTime = (timestamp, format) => {
  return timestamp
    ? moment(new Date(timestamp).toISOString()).format(format)
    : "";
};

export const accordionDataUpdatation = (currArr, itemId, from, value) => {
  return (
    currArr &&
    currArr.map((item) => {
      if (item.id === itemId) {
        if (from === "opening_item") {
          item.open_item = !item.open_item;
        } else if (from === "updating_item_name") {
          item.item_name = value;
          item.text = value;
        } else if (from === "updating_web_view_url") {
          item.web_view_url = value.replace(/^https?:\/\//i, "");
        } else if (from === "updating_email_call_share_value") {
          item.value = value;
        } else if (from === "updating_hide_show_status") {
          item.status = item.status ? 0 : 1;
        } else if (from === "updating_icon") {
          item.item_icon = value.item_icon;
          item.item_icon_newtype = "flaticon";
          item.item_icon_type = "new";
          item.item_icon_flaticon_color = value.item_icon_flaticon_color;
        } else if (from === "updating_item") {
          item.is_default = 1;
          item.temp_item_type = value.item_type || value.type;
          item.temp_rest_base = value.rest_base;
          item.temp_slug = value.slug;
          item.temp_post_type = value.post_type;
          item.item_title = value.name;
          item.selected_item_name = value.item_name || value.name;
          item.temp_individual_selected_item_id = "";
          item.is_tree = value.is_tree;
          item.selected_ids = [];
          item.last_item = value.item_name || value.name;
          item.subdropdownSpinner = true;
          item.temp_showL1Options = false;
        } else if (from === "updating_item_option") {
          item.temp_individual_selected_item_id =
            value.individual_selected_item_id;
          item.item_title = value.item_title;
          item.last_item = value.last_item;
          item.selected_ids = value.selected_ids;
          item.temp_slug =
            parseInt(localStorage.getItem("website_technology")) === 3
              ? value.slug
              : item.temp_slug;
        } else if (from === "updating_default_item") {
          item.item_is_default = 0;
          if (value) {
            item.item_is_default = 1;
            item.status = 1;
          }
        }
      } else {
        if (from === "opening_item") {
          item.open_item = false;
        } else if (from === "updating_default_item") {
          item.item_is_default = 0;
        }
      }

      if (item.children && item.children.length) {
        accordionDataUpdatation(item.children, itemId, from, value);
      }
      return item;
    })
  );
};

export const bottomaccordionDataUpdatation = (currArr, itemId, from, value) => {
  return (
    currArr &&
    currArr.map((item, indx) => {
      if (indx === itemId) {
        if (from === "updating_web_view_url") {
          item.web_view_url = value.replace(/^https?:\/\//i, "");
        } else if (from === "updating_email_call_share_value") {
          item.value = value;
        } else if (from === "updating_item") {
          item.temp_item_type = value.item_type || value.type;
          item.temp_rest_base = value.rest_base;
          item.temp_slug = value.slug;
          item.temp_post_type = value.post_type;
          item.item_title = value.name;
          item.selected_item_name = value.item_name || value.name;
          item.temp_invidual_selected_item_id = "";
          item.is_tree = value.is_tree;
          item.selected_ids = [];
          item.last_item = value.item_name || value.name;
          item.subdropdownSpinner = true;
          item.temp_showL1Options = false;
        } else if (from === "updating_item_option") {
          item.temp_invidual_selected_item_id = value.invidual_selected_item_id;
          item.item_title = value.item_title;
          item.last_item = value.last_item;
          item.selected_ids = value.selected_ids;
          item.temp_slug =
            parseInt(localStorage.getItem("website_technology")) === 3
              ? value.slug
              : item.temp_slug;
        }
      }
      return item;
    })
  );
};
// Fn to download any config file
export const downloadFile = async (fileUrl, fileName) => {
  await fetch(fileUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
    })
    .catch((error) => console.error("Error downloading the file:", error));
};

// Fn to add header to any RTK Slice API Request
export const prepareAuthHeaders = (headers) => {
  headers.set("Content-Type", "application/json");
  const token = localStorage.getItem("amstoken");
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

export const closeVistaPopUp = (isVistaPopUpOpen) => {
  if (!isVistaPopUpOpen) {
    if (document.querySelector(".crello-closeBtn")) {
      document.querySelector(".crello-closeBtn").click();
    }
  }
};

export const handlePaddlePrices = (price) => {
  let numb = price?.replace(",", "");
  numb = numb?.replace(/^\D+/g, "");
  return numb;
};

export const handlePaddleCurrency = (price) => {
  // Remove digits and special characters
  let currency = price?.replace(/[0-9`~!@#%^&*()_|+\-=?;:'",.<>{}[\]\\/]/g, "");
  currency = currency?.replace("US", "");

  return currency;
};
export const isJSON = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return JSON.parse(str);
};