import React, { useEffect } from "react";
import moment from "moment";
import {
  Lifetimepricing,
  Makepayment,
  Makestrippayment2,
  SendpaymentinfotoserverApi,
} from "../../Api/Dashboard/DashboardApi";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import "./Billings.css";
import "./Deals.css";
import { Link } from "react-router-dom";
import { paddleChangePlan } from "../../Api/Addons/Addonsapi";
export default class DealList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spinner: true,
      app_id: this.props.appId,
      dealsData: [],
      needOfId: 1,
      // loader: "d-none",
      loaderimage: require("../../assets/images/signup/Loader_GIF.gif").default,
      // accordian: false,
      accordian: false,
      index: "",
      customer_type: 1,
      index_checker: "",
      customer_billing_type: "",
      // subscription_plan: "",
      is_plan: 1,
      mobileAccordian:false
    };
  }
  componentDidMount() {
    Lifetimepricing(
      this,
      () => {
        if (this.props.btnCheck === 0) {
          this.props.props.setState({
            customer_billing_type: this.state.customer_billing_type,
            spinner: false,
            dealsData:this.state.dealsData
          });
        }
      },
      this.state.app_id
    );
  }
  handleAccordian = (e, i) => {
    if (i === this.state.index) {
      this.setState({
        accordian: !this.state.accordian,
        index: i,
      });
    } else {
      this.setState({
        accordian: !this.state.accordian,
        index: i,
      });
    }
  };
  handleMobileAccordian = () => {
   this.setState({
    mobileAccordian:!this.state.mobileAccordian
   })
  };
  
  handleClick = (e, id, customerBillingType, row, i) => {
    let tempCBT;
  
    this.setState({
      submitval: "",
      index_checker: i,
      // subscription_plan: row.app_plan,
    });


    if(row.is_deal_plan===0||row.is_new_deal===1){
      tempCBT=this.state.customer_billing_type; //root
    }else{
      tempCBT=customerBillingType;//index
    
      
    }
   
    


  
    if (tempCBT === 1) {
      const addonandpaymnet = [
        {
          zoho_plan_code: row.plan_data.plan_code,
          type: 1,
          amount: 0,
          quantity: 1,
        },
      ];

      const params = {
        app_id: parseInt(localStorage.getItem("Appid")),
        plan_code: row.plan_data.plan_code,
        addon_payment: 0,
        addon_array: [],
        deal_id: id,
        action: "",
        product: "",
        app_plan: row.app_plan_code,
      };
      const sendparam = {
        app_id: parseInt(localStorage.getItem("Appid")),
        total: 0,
        plan_array: addonandpaymnet,
        currency: "$",
        order_type: "upgrade",
      };
      SendpaymentinfotoserverApi(sendparam, this, () => {
        Makepayment(params, this, this.props);
      });
    } else if (tempCBT === 2) {
      const sendtostrip = {
        app_id: parseInt(localStorage.getItem("Appid")),
        plan_array: [
          {
            price_id: row.plan_data.plan_code,
            quantity: 1,
            type: 1,
          },
        ],
        payment_method_id: "payment_method_id",
        order_type: 1,
        deal_id: id,
        app_plan: row.app_plan_code,
        // action:this.state.payment_method_id
      };
      Makestrippayment2(sendtostrip, this.props, "", this);
    }else if(tempCBT === 3){
      const paddleParam = {
        workspace_id: null,
        app_id: parseInt(localStorage.getItem("Appid")),
        deal_id: id,
        order_type: 1,
        price_id: row.plan_data.plan_code,
        quantity: 1,
        type: 1,
      };
      paddleChangePlan(paddleParam,this)
    }
  };

  handleReturnActiveImg = (customer_type) => {

    if (customer_type === 2) {
      return (
        <img
          src={require("../../assets/images/deals/delas-tako.png").default}
          alt="app-icon"
          className=""
        />
      );
    } else if (customer_type === 3) {
      return (
        <img
          src={require("../../assets/images/deals/deals-inf.png").default}
          alt="app-icon"
          className=""
        />
      );
    } else if (customer_type === 4) {
      return (
        <img
          src={require("../../assets/images/deals/deals-woo.png").default}
          alt="app-icon"
          className=""
        />
      );
    }
  };

  handleReturnDeactiveImg = (customer_type) => {

    if (customer_type === 2) {
      return (
        <img
          src={require("../../assets/images/deals/deact-tako.png").default}
          alt="app-icon"
          className=""
        />
      );
    } else if (customer_type === 3) {
      return (
        <img
          src={require("../../assets/images/deals/deact-inf.png").default}
          alt="app-icon"
          className=""
        />
      );
    } else if (customer_type === 4) {
      return (
        <img
          src={require("../../assets/images/deals/deact-woo.png").default}
          alt="app-icon"
          className=""
        />
      );
    }
  };

  render() {
    let count = 0;
    return (
      <div className={`${this.state.spinner===true?"deals-billing_right_panel min-height-page":""} mobile-container billing_nodataimg`}>
        {
        this.state.spinner ? (
          <div className="payment-billingnewspinner" >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
              style={{
                margin: "auto",
                background: "rgb(241, 242, 243,0)",
                display: "block",
                shaperendering: "auto",
                animationplaystate: "running",
                animationdelay: "0s",
              }}
              width="200px"
              height="200px"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
            >
              <g
                transform="translate(20 50)"
                style={{ animationplaystate: "running", animationdelay: "0s" }}
              >
                <circle
                  cx="0"
                  cy="0"
                  r="6"
                  fill="#e6261f"
                  style={{
                    animationplaystate: "running",
                    animationdelay: "0s",
                  }}
                >
                  <animateTransform
                    attributeName="transform"
                    type="scale"
                    begin="-0.375s"
                    calcMode="spline"
                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                    values="0;1;0"
                    keyTimes="0;0.5;1"
                    dur="1s"
                    repeatCount="indefinite"
                    style={{
                      animationplaystate: "running",
                      animationdelay: "0s",
                    }}
                  ></animateTransform>
                </circle>
              </g>
              <g
                transform="translate(40 50)"
                style={{ animationplaystate: "running", animationdelay: "0s" }}
              >
                <circle
                  cx="0"
                  cy="0"
                  r="6"
                  fill="#f7d038"
                  style={{
                    animationplaystate: "running",
                    animationdelay: "0s",
                  }}
                >
                  <animateTransform
                    attributeName="transform"
                    type="scale"
                    begin="-0.25s"
                    calcMode="spline"
                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                    values="0;1;0"
                    keyTimes="0;0.5;1"
                    dur="1s"
                    repeatCount="indefinite"
                    style={{
                      animationplaystate: "running",
                      animationdelay: "0s",
                    }}
                  ></animateTransform>
                </circle>
              </g>
              <g
                transform="translate(60 50)"
                style={{ animationplaystate: "running", animationdelay: "0s" }}
              >
                <circle
                  cx="0"
                  cy="0"
                  r="6"
                  fill="#49da9a"
                  style={{
                    animationplaystate: "running",
                    animationdelay: "0s",
                  }}
                >
                  <animateTransform
                    attributeName="transform"
                    type="scale"
                    begin="-0.125s"
                    calcMode="spline"
                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                    values="0;1;0"
                    keyTimes="0;0.5;1"
                    dur="1s"
                    repeatCount="indefinite"
                    style={{
                      animationplaystate: "running",
                      animationdelay: "0s",
                    }}
                  ></animateTransform>
                </circle>
              </g>
              <g
                transform="translate(80 50)"
                style={{ animationplaystate: "running", animationdelay: "0s" }}
              >
                <circle
                  cx="0"
                  cy="0"
                  r="6"
                  fill="#4355db"
                  style={{
                    animationplaystate: "running",
                    animationdelay: "0s",
                  }}
                >
                  <animateTransform
                    attributeName="transform"
                    type="scale"
                    begin="0s"
                    calcMode="spline"
                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                    values="0;1;0"
                    keyTimes="0;0.5;1"
                    dur="1s"
                    repeatCount="indefinite"
                    style={{
                      animationplaystate: "running",
                      animationdelay: "0s",
                    }}
                  ></animateTransform>
                </circle>
              </g>
            </svg>
          </div>
        ) 
        : (
          this.state.dealsData.map((row, i) => {
            if (
              row.status === "active" &&
              (row.total_app_count === "" ||
                row.used_app_count < row.total_app_count)
            ) {
              count++;
            }
            let customerBillingType;
            if (row.plan_data) {
              customerBillingType = row.plan_data.customer_billing_type;
            } else {
              customerBillingType = "notFound";
            }
            return (
              ((row.status === "active" &&
                (row.used_app_count < row.total_app_count ||
                  row.total_app_count === "")) ||
                this.props.btnCheck !== 1) && (
                <div className=" billings-deal-container">
                  <div
                    className={`align-items-center  sm-flex-wrap billing-dealsbackground web-delas ${
                      row.status === "active" ? "" : "disableddeals"
                    }`}
                    key={i}
                  >
                    <div className="delas-icons">
                      <>
                        {row.status === "active"
                          ? this.handleReturnActiveImg(row.customer_type)
                          : this.handleReturnDeactiveImg(row.customer_type)}
                      </>
                      <div class="buy">
                        <span
                          class="up-border"
                          style={{
                            backgroundColor:
                              this.state.accordian && this.state.index === i
                                ? "#F1F4FB"
                                : "",
                          }}
                        >
                          {/* <img
                          src={
                            require("../../assets/images/deals/ticket-first.png").default
                               }
                           alt="app-icon"
                           className=""                                     
                            /> */}
                        </span>
                        <span class="down-border">
                          {/* <img
                          src={
                            require("../../assets/images/deals/ticket-second.png").default
                               }
                           alt="app-icon"
                           className=""                                     
                            /> */}
                        </span>
                      </div>
                    </div>
                    <div className="delasline-div"></div>
                    <div className="deals-plan-textdiv">
                      <div className=" deals-plans-namehead ">
                        {row.customer_type === 2 ? ( // for 2 AppSumo for 3 stackcommerce // row.plan_data.plan_code===row.app_plan? Selected : null
                          <h5>AppSumo</h5>
                        ) : (
                          <h5>Stackcommerce</h5>
                        )}
                        {row.plan_data && (
                          <h3>
                            {row.plan_data.plan_name}{" "}
                            {row.customer_type === 2 && (
                              <a
                                className="upgrade-linkk"
                                href={`https://appsumo.8odi.net/c/${process.env.REACT_APP_APPSUMO_AFFILIATE_ID}/416948/7443?sharedid=button1&u=https://appsumo.com/account/redemption/${row.invoice_id}/#change-plan`}
                              >
                                Upgrade{" "}
                                <img
                                  src={
                                    require("../../assets/images/dashboard/Goto.png")
                                      .default
                                  }
                                  alt="goto"
                                />
                              </a>
                            )}
                          </h3>
                        )}
                        {row.activation_date && (
                          <h5 className="deal-activation-date">
                            {/* Activated on 25th Jan 2022 */}
                            Activated on{" "}
                            {moment(row.activation_date).format("D MMMM YYYY")}
                          </h5>
                        )}
                      </div>
                      <div className="deal-count deals-count">
                        {row.total_app_count === "" ? (
                          <h5>{row.used_app_count} used</h5>
                        ) : (
                          <h5>
                            {row.used_app_count} of {row.total_app_count} used
                          </h5>
                        )}
                        {row.expiry_date === null ? (
                          <div className="deal-expiry-date deals-expiry-date">
                            <h5>
                              {row.status === "inactive"
                                ? "Expired"
                                : "Never Expires"}
                            </h5>
                          </div>
                        ) : (
                          <div className="deal-expiry-date deals-expiry-date">
                            {moment(row.expiry_date).isAfter(moment()) ===
                            false ? (
                              <h5 className="text-danger">
                                Expired on{" "}
                                {moment(row.expiry_date).format("D MMMM YYYY")}
                              </h5>
                            ) : (
                              <h5>
                                Expires on{" "}
                                {moment(row.expiry_date).format("D MMMM YYYY")}
                              </h5>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="deal-info  justify-content-end d-flex align-items-center mobile-grid">
                        <div className="deals-info-coupons">
                          <div className="d-flex   deals-coupon-head">
                            <img
                              src={
                                require("../../assets/images/dashboard/coupon-tag.png")
                                  .default
                              }
                              className="dealselect-tag-img"
                            ></img>{" "}
                            <h6 className=" deals-coupon-code-text">
                              {row.licence_uuid}
                            </h6>
                          </div>
                          <div className="d-flex justify-content-end align-items-center">
                            {row.customer_type === 2 && (
                              <p
                                onClick={(e) => this.handleAccordian(e, i)}
                                style={{
                                  cursor: "pointer",
                                  marginTop: "0",
                                  marginBottom: "0",
                                  fontSize: "12px",
                                  color: "#BCC1CE",
                                }}
                              >
                                Plan details
                                {this.state.accordian === true &&
                                this.state.index === i ? (
                                  <img
                                    className="ml-1"
                                    src={
                                      require("../../assets/images/deals/upperArrow.png")
                                        .default
                                    }
                                    alt="no-deals"
                                  />
                                ) : (
                                  <img
                                    className="ml-1"
                                    src={
                                      require("../../assets/images/deals/downArrow.png")
                                        .default
                                    }
                                    alt="no-deals"
                                  />
                                )}
                              </p>
                            )}

                            <span
                              className="deals-status ml-3"
                              style={
                                row.status === "active"
                                  ? {
                                      // backgroundColor: "#E5FFED",
                                      color: "#47CE70",
                                    }
                                  : row.status === "inactive"
                                  ? {
                                      // backgroundColor: "transparent",
                                      // border: "1px solid #C8CEDB",
                                      color: "#C8CEDB",
                                    }
                                  : {
                                      // backgroundColor: "transparent",
                                      // border: "1px solid rgb(226, 0, 0)",
                                      color: "rgb(226, 0, 0)",
                                    }
                              }
                            >
                              {row.status}
                            </span>
                          </div>
                        </div>
                        {this.props.btnCheck === 1 && (
                          <div className="deal-select-btn">
                            <button
                              className={
                                row.is_selected_deal === 1 &&
                                row.plan_data.plan_code === row.app_plan
                                  ? "btn custom-secondary deal-selection-btn"
                                  : "btn btn-primary deal-selection-btn"
                              }
                              disabled={
                                row.is_selected_deal === 1 &&
                                row.plan_data.plan_code === row.app_plan
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                this.handleClick(
                                  e,
                                  row.id,
                                  customerBillingType,
                                  row,
                                  i
                                )
                              }
                            >
                              {this.state.index_checker === i && (
                                <div>
                                  <img
                                    src={this.state.loaderimage}
                                    alt="loader"
                                  />
                                </div>
                              )}

                              {this.state.index_checker === i
                                ? ""
                                : row.is_selected_deal === 1 &&
                                  row.plan_data.plan_code === row.app_plan
                                ? "Selected"
                                : "Select"}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* mob-display/ */}

                  <div
                    className={`mob-deals ${
                      row.status === "active" ? "" : "mob-disableddeals"
                    }`}
                  >
                    <div className="   " key={i}>
                      <div className="d-flex justify-content-between delas-mob-delashead">
                        <div className=" delas-mob-title">
                          {row.customer_type === 2 ? ( // for 2 AppSumo for 3 stackcommerce // row.plan_data.plan_code===row.app_plan? Selected : null
                            <h5>AppSumo</h5>
                          ) : (
                            <h5>Stackcommerce</h5>
                          )}
                          {row.plan_data && (
                            <h3>
                              {row.plan_data.plan_name}{" "}
                              {row.customer_type === 2 && (
                                <a
                                  className="upgrade-link"
                                  href={`https://appsumo.8odi.net/c/${process.env.REACT_APP_APPSUMO_AFFILIATE_ID}/416948/7443?sharedid=button1&u=https://appsumo.com/account/redemption/${row.invoice_id}/#change-plan`}
                                  target="_blank"
                                >
                                  Upgrade{" "}
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/Goto.png")
                                        .default
                                    }
                                    alt="goto"
                                  />
                                </a>
                              )}
                            </h3>
                          )}
                          {row.activation_date && (
                            <h5 className="deal-activation-date">
                              {/* Activated on 25th Jan 2022 */}
                              Activated on{" "}
                              {moment(row.activation_date).format(
                                "D MMMM YYYY"
                              )}
                            </h5>
                          )}
                        </div>
                        <div className="deals-img">
                          {row.status === "active"
                            ? this.handleReturnActiveImg(row.customer_type)
                            : this.handleReturnDeactiveImg(row.customer_type)}
                          {/* <div class="buy">
                          <span class="up-border"></span>
                           <span class="down-border"></span>
               
                       </div> */}
                        </div>
                      </div>
                    </div>
                    <div class="buy">
                      <span class="up-mob-border">
                        {/* <img
                          src={
                            require("../../assets/images/deals/ticket-first.png").default
                               }
                           alt="app-icon"
                           className=""                                     
                            /> */}
                      </span>
                      <span class="down-mob-border">
                        {/* 
                           <img
                          src={
                            require("../../assets/images/deals/ticket-second.png").default
                               }
                           alt="app-icon"
                           className=""                                     
                            /> */}
                      </span>
                    </div>
                    <div className="deal-info  d-grid align-items-center mobile-grid deals-mob-bottomdiv">
                      <div className="deals-mob-count ">
                        <div className="d-flex justify-content-between">
                          {row.total_app_count === "" ? (
                            <h5>{row.used_app_count} used</h5>
                          ) : (
                            <h5>
                              {row.used_app_count} of {row.total_app_count} used
                            </h5>
                          )}
                          <span
                            className="deals-status ml-3"
                            style={
                              row.status === "active"
                                ? {
                                    color: "#47CE70",
                                    // backgroundColor: "#E5FFED",
                                  }
                                : row.status === "inactive"
                                ? {
                                    // backgroundColor: "transparent",
                                    // border: "1px solid #C8CEDB",
                                    color: "#C8CEDB",
                                  }
                                : {
                                    // backgroundColor: "transparent",
                                    // border: "1px solid rgb(226, 0, 0)",
                                    color: "rgb(226, 0, 0)",
                                  }
                            }
                          >
                            {row.status}
                          </span>
                        </div>
                        {row.expiry_date === null ? (
                          <div className="deals-expiry-date">
                            <h5>
                              {row.status === "inactive"
                                ? "Expired"
                                : "Never Expires"}
                            </h5>
                          </div>
                        ) : (
                          <div className="deals-expiry-date">
                            {moment(row.expiry_date).isAfter(moment()) ===
                            false ? (
                              <h5 className="text-danger">
                                Expired on{" "}
                                {moment(row.expiry_date).format("D MMMM YYYY")}
                              </h5>
                            ) : (
                              <h5>
                                Expires on{" "}
                                {moment(row.expiry_date).format("D MMMM YYYY")}
                              </h5>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="">
                        <div
                          className={`d-flex   deals-coupon-mob-head ${
                            row.status === "active" ? "" : "mob-disableddealss"
                          }`}
                        >
                          <img
                            src={
                              require("../../assets/images/dashboard/coupon-tag.png")
                                .default
                            }
                            className="dealselect-tag-img"
                          ></img>{" "}
                          <h6 className="deals-coupon-code-text">
                            {row.licence_uuid}
                          </h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-2">
                          {row.customer_type === 2 && (
                            <p
                              onClick={(e) => this.handleMobileAccordian()}
                              style={{
                                cursor: "pointer",
                                marginTop: "0",
                                marginBottom: "0",
                                fontSize: "13px",
                                color: "#BCC1CE",
                              }}
                            >
                              Plan details
                              {this.state.accordian === true &&
                              this.state.index === i ? (
                                <img
                                  className="ml-1"
                                  src={
                                    require("../../assets/images/dashboard/arrow-grey.png")
                                      .default
                                  }
                                  alt="no-deals"
                                />
                              ) : (
                                <img
                                  className="ml-1"
                                  src={
                                    require("../../assets/images/dashboard/arrow-grey.png")
                                      .default
                                  }
                                  alt="no-deals"
                                />
                              )}
                            </p>
                          )}

                          {/* <span
                            className="deal-status ml-3"
                            style={
                              row.status === "active"
                                ? {
                                    backgroundColor: "#47CE70",
                                    border: "1px solid #47CE70",
                                  }
                                : row.status === "inactive"
                                ? {
                                    backgroundColor: "transparent",
                                    border: "1px solid #C8CEDB",
                                    color: "#C8CEDB",
                                  }
                                : {
                                    backgroundColor: "transparent",
                                    border: "1px solid rgb(226, 0, 0)",
                                    color: "rgb(226, 0, 0)",
                                  }
                            }
                          >
                            {row.status}
                          </span> */}
                        </div>
                      </div>
                      {this.props.btnCheck === 1 && (
                        <div className="deal-select-btn">
                          <button
                            className={
                              row.is_selected_deal === 1 &&
                              row.plan_data.plan_code === row.app_plan
                                ? "btn custom-secondary deal-selection-btn"
                                : "btn btn-primary deal-selection-btn"
                            }
                            disabled={
                              row.is_selected_deal === 1 &&
                              row.plan_data.plan_code === row.app_plan
                                ? true
                                : false
                            }
                            onClick={(e) =>
                              this.handleClick(
                                e,
                                row.id,
                                customerBillingType,
                                row,
                                i
                              )
                            }
                          >
                            {this.state.index_checker === i && (
                              <div>
                                <img
                                  src={this.state.loaderimage}
                                  alt="loader"
                                />
                              </div>
                            )}

                            {this.state.index_checker === i
                              ? ""
                              : row.is_selected_deal === 1 &&
                                row.plan_data.plan_code === row.app_plan
                              ? "Selected"
                              : "Select"}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  {this.state.accordian === true && this.state.index === i && (
                    <div className="d-flex  deals-description-newchart">
                      <img
                        onClick={(e) => this.handleAccordian(e, i)}
                        src={
                          require("../../assets/images/dashboard/cross.png")
                            .default
                        }
                        alt="alert"
                      />
                      <ul className="dealss-desc-list">
                        <h5 className="dealsss-desc-heading">Deals Terms</h5>
                        <li>Activate your license within 60 days of purchase</li>
                        <li>
                        Lifetime access to AppMySite pro plan
                        </li>
                        <li>
                        Includes all future Pro Plan updates
                        </li>
                        <li>Ability to upgrade or downgrade between 5 license tiers</li>
                        {/* <li>
                          Ability to upgrade/downgrade between 5 license tiers
                        </li> */}
                        <li>
                          60-day money-back guarantee, no matter the reason
                        </li>
                      </ul>
                      <ul className="dealss-seconddesc-list">
                        <h5 className="dealsss-desc-heading">Plan details</h5>
                        <li>Convert any Website to app</li>
                        <li>Convert WordPress to app</li>
                        <li>Convert WooCommerce to app</li>
                        <li>Publish on Android & iOS App Stores</li>
                        <li>App personalization</li>
                        <li>Test on real device</li>
                        <li>Push notifications</li>
                        <li>App Advertisement</li>
                        <li>User Consent</li>
                        {/* <li>App Advertisement</li>
                        <li>User Consent</li>
                        <li>AppMySite Support </li> */}
                      </ul>
                    </div>
                  )}
                </div>
              )
            );
          })
        )}

        <Modal
          isOpen={this.state.mobileAccordian}
          centered
          className="creditpopup"
          style={{ maxWidth: "490px !important" }}
        >
          <ModalHeader 
             style={{ padding: "27px" }}
            className="modal-xxl-content"
          >
            <div className="crossmodal-deals " >
              <img onClick={(e)=>this.handleMobileAccordian(e)}
                src={require("../../assets/images/dashboard/cross.png").default}
                alt="alert"
              />
            </div>
          </ModalHeader>
          <ModalBody className=" popup-spec-view deals-updateappmod">
          <div className="d-flex justify-content-between deals-descriptions-chart">
                      <ul className="deals-desc-list">
                        <h5 className="deals-desc-heading">Deals Terms</h5>
                        {/* <li>Lifetime access to AppMySite Pro Plan</li>
                        <li>
                          No codes, no stacking - just choose the plan that's
                          right for you
                        </li>
                        <li>
                          You must activate your license within 60 days of
                          purchase
                        </li>
                        <li>All future Pro Plan updates</li>
                        <li>
                          Ability to upgrade/downgrade between 5 license tiers
                        </li>
                        <li>
                          60-day money-back guarantee, no matter the reason
                        </li> */}
                          <li>Activate your license within 60 days of purchase</li>
                        <li>
                        Lifetime access to AppMySite pro plan
                        </li>
                        <li>
                        Includes all future Pro Plan updates
                        </li>
                        <li>Ability to upgrade or downgrade between 5 license tiers</li>
                        {/* <li>
                          Ability to upgrade/downgrade between 5 license tiers
                        </li> */}
                        <li>
                          60-day money-back guarantee, no matter the reason
                        </li>
                      </ul>
                      <ul className="deals-desc-list">
                        <h5 className="dealss-desc-heading">
                         Plan details
                        </h5>
                        {/* <li>Collaborate</li>
                        <li>Ready to publish app</li>
                        <li>Mobile platforms (Android + iOS)</li>
                        <li>Distribution license (Android + iOS)</li>
                        <li>Web platforms (Woo-commerce + Word-press)</li>
                        <li>Personalized app design</li>
                        <li>Real-time sync with website</li> */}
                        <li>Convert any Website to app</li>
                        <li>Convert WordPress to app</li>
                        <li>Convert WooCommerce to app</li>
                        <li>Publish on Android & iOS App Stores</li>
                        <li>App personalization</li>
                        <li>Test on real device</li>
                        <li>Push notifications</li>
                        <li>App Advertisement</li>
                        <li>User Consent</li>
                      </ul>
                    </div>
          
          
          </ModalBody>
         
        </Modal>

        {/* No deals Page/ */}

        {this.state.spinner === false &&
        (this.state.dealsData.length === 0 ||
          (count === 0 && this.props.btnCheck === 1)) ? (
          <div class="">
            <img
              src={require("../../assets/images/deals/NoDeals.png").default}
              className="nodeals-img mx-auto d-block noDeals"
              alt="no-deals "
            />

            <div className="billing_nodatatext">
              {/* <h5 className="text-center">No deals are available now</h5> */}

              <span className="custom-size text-center not-subscribed span1">
                Nothing here at the moment.
              </span>
              <Link
                to={"/redeem"}
                className="custom-size text-center not-subscribed span2"
              >
                Redeem code
              </Link>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}
