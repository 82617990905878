import React from "react";

const RectangularShape = ({ shape, handleShape }) => {
  return (
    <svg
      className={
        shape === "reactangular"
          ? "imageshape-svg-select"
          : "imageshape-svg-notselect"
      }
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      onClick={() => handleShape("reactangular")}
    >
      <g
        id="Rectangle_18739"
        data-name="Rectangle 18739"
        fill=""
        stroke=""
        stroke-width="2"
      >
        <rect width="18" height="18" stroke="none" />
        <rect x="1" y="1" width="16" height="16" fill="none" />
      </g>
    </svg>
  );
};

export default RectangularShape;
