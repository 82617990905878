import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const generalSettingsApiSlice = createApi({
  reducerPath: "generalSettings",
  keepUnusedDataFor: 1800,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("amstoken")}`
      );
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getWebsiteType: builder.query({
      query: () =>
        "api/user/websitetype?agency_id=" +
        btoa(localStorage.getItem("agencyid")),
      providesTags: ["generalSettingsSave"],
      keepUnusedDataFor: 15 * 60,
    }),
    getCountryList: builder.query({
      query: () => "api/user/country",
      //   providesTags: ["generalSettingsSave"],
      keepUnusedDataFor: 15 * 60,
    }),
    getWorkspaces: builder.query({
      query: (limit) => `api/get-workspaces?limit=${limit}`,
      //   providesTags: ["generalSettingsSave"],
      keepUnusedDataFor: 15 * 60,
    }),
    getGeneralSettings: builder.query({
      query: (params) =>
        "api/user/guest-login/" +
        params.app_id +
        "?workspace_id=" +
        params.workspace_id,
      providesTags: ["generalSettingsSave"],
      keepUnusedDataFor: 15 * 60,
    }),
    saveGeneralSettingsData: builder.mutation({
      query: (payload) => ({
        url: "api/user/updateApp",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["generalSettingsSave"],
    }),
    getSocialSettings: builder.query({
      query: (params) =>
        "api/user/get_app_setting/" + btoa(localStorage.getItem("Appid")),
      providesTags: ["socialSettingsSave"],
      keepUnusedDataFor: 15 * 60,
    }),

    saveSocialSettingsData: builder.mutation({
      query: (payload) => ({
        url: "api/user/app_setting_social",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["socialSettingsSave"],
    }),
    deleteApp: builder.mutation({
      query: (id) => ({
        url: "api/user/delete_app",
        method: "POST",
        body: id,
      }),
    }),
    leaveApp: builder.mutation({
      query: (id) => ({
        url: "api/app/user-management/leave-app",
        method: "POST",
        body: id,
      }),
    }),
    extractWebsiteColors: builder.mutation({
      query: (id) => ({
        url: "api/app/extract-website-colors",
        method: "POST",
        body: id,
      }),
    }),
    moveApp: builder.mutation({
      query: (payload) => ({
        url: "api/app/transfer-app",
        method: "POST",
        body: payload,
      }),
      // invalidatesTags: ["socialSettingsSave"],
    }),
  }),
});

export const {
  useGetWebsiteTypeQuery,
  useGetCountryListQuery,
  useGetWorkspacesQuery,
  useGetGeneralSettingsQuery,
  useSaveGeneralSettingsDataMutation,
  useDeleteAppMutation,
  useLeaveAppMutation,
  useExtractWebsiteColorsMutation,
  useSaveSocialSettingsDataMutation,
  useGetSocialSettingsQuery,
  useMoveAppMutation,
} = generalSettingsApiSlice;
