import React, { useState } from "react";
import { UnlinkMergedapp } from "../../../Api/Multisite/Multisiteapi";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const MergeAppNewAlreadyMerged = ({ multisite, handleUnlinkAppReset }) => {
  const [unlinkModal, setUnlinkModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleunlink = () => {
    setLoading(true);
    const params = {
      app_id: parseInt(localStorage.getItem("Appid"))
    };
    UnlinkMergedapp(params, handleUnlinkAppReset, setLoading, setUnlinkModal);
  };

  return (
    <>
      <div className="merged-app-maindiv">
        <div className="mergeapp-grid">
          <div className="mergeapp-parent-app">
            <div className="mergeapp-parent-tag">
              <h3>Primary app</h3>
            </div>
            <div className="mergeapp-appicon">
              <img
                src={
                  process.env.REACT_APP_Image_Path +
                  multisite.parent_app_user_id+
                  "/" +
                  multisite.parent_app_id +
                  process.env.REACT_APP_AppIcon_Display +
                  multisite.parent_app_icon
                }
                onError={({ currentTarget }) => {
                  currentTarget.src =
                    require("../../../assets/images/dashboard/child-merge-icon.png").default;
                }}
                alt="Appicon"
              />
            </div>
            <h4>{multisite?.parent_app_name}</h4>
            <h5>ID: {multisite?.parent_app_id}</h5>
          </div>

          <img
            src={
              require("../../../assets/images/dashboard/download-merge.png")
                .default
            }
            alt="download-img"
            className="img-fluid"
          />
          <div className="mergeapp-child-app">
            <div className="mergeapp-child-tag">
              <h3>Merged app</h3>
            </div>

            <div className="mergeapp-appicon">
              <img
                src={
                  process.env.REACT_APP_Image_Path +
                  localStorage.getItem("user_id") +
                  "/" +
                  localStorage.getItem("Appid") +
                  process.env.REACT_APP_AppIcon_Display +
                  localStorage.getItem("Appicon")
                }
                onError={({ currentTarget }) => {
                  currentTarget.src =
                    require("../../../assets/images/dashboard/child-merge-icon.png").default;
                }}
                alt="Appicon"
              />
            </div>
            <h4>{localStorage.getItem("Appname")}</h4>
            <h5>ID: {localStorage.getItem("Appid")}</h5>
          </div>
        </div>

        <h1>This app is already merged</h1>
        <h2>
          This app appears to be merged with another app. To manage this
          feature, navigate to the primary app where this feature was enabled.
          Alternatively, click Unlink to disconnect this app.
        </h2>
        <div className="mergedapp-merged-btns">
          <a href="/apps">Manage</a>
          <a onClick={() => setUnlinkModal(!unlinkModal)}>Unlink</a>
        </div>
      </div>
      <Modal
        className="notification-conf-secondmodal"
        style={{ maxWidth: "480px", maxHeight: "280px" }}
        isOpen={unlinkModal}
        toggle={() => setUnlinkModal(!unlinkModal)}
        centered
      >
        <ModalHeader>
          <div
            className="notification-conf-secondmodal-crossmodal"
            style={{ zIndex: "9" }}
          >
            <img
              onClick={() => setUnlinkModal(!unlinkModal)}
              src={
                require("../../../assets/images/dashboard/cross.png").default
              }
            />
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="notification-conf-popup-maindiv">
            <div className="notification-conf-popup-head">
              <h2>Are you sure you want to unlink?</h2>
            </div>

            <p>This action will disconnect the merged apps.</p>

            <div className="notification-conf-popup-bottom">
              <button
                className="notification-conf-popup-cancel"
                onClick={() => setUnlinkModal(!unlinkModal)}
              >
                Cancel
              </button>

              {loading ? (
                <div className="notification-conf-popup-confirm d-flex justify-content-center align-items-center">
                  <div className="new-loader"></div>
                </div>
              ) : (
                <button
                  className="notification-conf-popup-confirm"
                  onClick={handleunlink}
                >
                  Confirm
                </button>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default MergeAppNewAlreadyMerged;
