import React from "react";
import Header from "../Header";


export default class AppPricing extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state  = {
    checker:localStorage.getItem('Website_technology'),
    appuser_role_id:localStorage.getItem('appuser_role_id'),
    wordpressprice:'',
    webtoapprpice:'',
    wocommerceprice:'',
    customappprice:'',
    currency:'$',
    appuser_role_id:localStorage.getItem('appuser_role_id'),
  };
}
settingPaddlePrices = (i,prices) => {
  var numb = prices.price.gross.replace(',','');
  numb = numb.replace(/^\D+/g, '');

  
  this.setState({currency:(prices.price.gross.replace(/[0-9]/g, '')).replace(/[`~!@#%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')});
 
  if(i == 0)
  {
    this.setState({webtoapprpice: this.newcurrencyFormater(parseFloat(parseFloat(numb)/12).toFixed(2), "en-"+ prices.country)});
    
  }
  if(i == 1)
  {
    this.setState({wordpressprice: this.newcurrencyFormater(parseFloat(parseFloat(numb)/12).toFixed(2), "en-"+ prices.country)});
  }
  if(i == 2)
  {
    this.setState({wocommerceprice: this.newcurrencyFormater(parseFloat(parseFloat(numb)/12).toFixed(2), "en-"+ prices.country)});
    
  }
  if(i == 3)
  {
    this.setState({customappprice: this.newcurrencyFormater(parseFloat(parseFloat(numb)/12).toFixed(2), "en-"+ prices.country)});
    
  }
};

newcurrencyFormater = (number, country_name) => {
  let formatedNum = new Intl.NumberFormat(country_name).format(number);
  return formatedNum;
};


  componentDidMount() 
  { localStorage.setItem("AgencyForThankyouPage","");
    window.scrollTo(0, 0);
    document.title = "App plans | AppMySite";
    this.stopmounting = true;

    const Paddle = window.Paddle;

    if (
      window.location.host.indexOf("localhost") > -1 ||
      window.location.host.indexOf("test.app") > -1 ||
      window.location.host.indexOf("stage.app") > -1 ||
      window.location.host.indexOf("dev.app") > -1
    ) {
      window.Paddle.Environment.set("sandbox");
    }
    Paddle.Setup({
      vendor: parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID),
    });

  if(process.env.REACT_APP_PADDLE_PRODUCT_IDS !== null && process.env.REACT_APP_PADDLE_PRODUCT_IDS !== undefined && localStorage.getItem('customer_billing_type') == 3)
  {
    var p = process.env.REACT_APP_PADDLE_PRODUCT_IDS.split(",");
    
    p.map((item, i) => 
    {
      window.Paddle.Product.Prices(parseInt(item), (prices) =>
      this.settingPaddlePrices(i,prices)
    );
    });  
  } 
    

  }
  

  handleSeePlans = (e, web_technology) => {
    this.setState({
      web_technology: web_technology,
    });
  };

  render() 
  {
    if (this.state.appuser_role_id === 3 || this.state.appuser_role_id === 4) {
      this.props.history.push("/app/dashboard");
    }

    return (
      <>
      
      <Header customclass={"header-responsive-new"} />
      
        {(this.state.appuser_role_id == 3 || this.state.appuser_role_id == 4) ?
         <>
         <section className="blueback allplans teampricing paddingbottom80 pricing-main-page">
              <div className="col-md-12">
              <div className="w-100 text-center restrictuser pt-5 pb-5">
                {/* <img
                  src={require("../../assets/images/dashboard/del-user.png").default}
                  alt="Changes not allowed"
                  className="mb-4 img-fluid"
                /> */}
                <img
            src={require("../../assets/images/dashboard/restrict-user.png").default}
            alt="Changes not allowed"
            className="mb-4 img-fluid"
          />
                {/* <h4>You currently do not have permissions to view this feature.</h4>
                <h4> Please contact your Adm inistrator if you require access.</h4> */}
                 <h3>You currently do not have permission to access this feature. Please contact your administrator if you require access</h3>
          <h4 >You currently do not have permission to access this feature.</h4>
          <h4 > Please contact your administrator if you require access</h4>
              </div>
            </div>
          </section>
          </>
        :
          <>
          <section className="allplans teampricing paddingtop140 paddingbottom80 pricing-main-page">
          <div className="container">
              
              <h1 className="text-center">Pay-per-app products</h1>
              
              <div className="tabbed price-main-tab">
                
                
              <div className="tab-content">
                  <div className="new-price-grid">
                  
                  <div className="pricingsummary web-to-app-pricingsummary">
                      <a  href={"/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'))}></a>
                    {parseInt(localStorage.getItem('website_technology')) === 2 ?
                      <div className="the-active-plan">
                        <span>Active</span>
                      </div>
                    : null
                    }
                      

                      <img src={ require("../../assets/images/pricing/web-to-app.png") .default }alt="pricing-type" className="img-fluid"/>
                      <h4>Website to App</h4>
                      
                      <p>Convert any website into a native iOS & Android mobile app, via web views.</p>
                      
                      <h5><span>Starting from </span>{this.state.webtoapprpice !== '' && this.state.webtoapprpice !== undefined ? this.state.currency.replace('US','') +this.state.webtoapprpice : '$9'}/month</h5>
                      
                  </div>
                      
                  <div className="pricingsummary wordpress-pricingsummary">
                  <a  href={"/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid'))}></a>
                      {parseInt(localStorage.getItem('website_technology')) === 4  ?
                      <div className="the-active-plan">
                      <span>Active</span>
                      </div>
                      : null
                      }
                      <img src={ require("../../assets/images/pricing/wordpress-app.png") .default }alt="pricing-type" className="img-fluid"/>

                      <h4>WordPress to App</h4>
                      
                      <p>Convert any WordPress website into a native iOS & Android mobile app, with a simple WordPress plugin.</p>
                      
                      <h5><span>Starting from </span>{this.state.wordpressprice !== '' && this.state.wordpressprice !== undefined ? this.state.currency.replace('US','') +this.state.wordpressprice : '$9'}/month</h5>
                      
                  </div>
                      
                  <div className="pricingsummary wooCommerce-to-app-pricingsummary">
                  <a  href={"/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'))}></a>
                      {parseInt(localStorage.getItem('website_technology')) === 5  ?
                      <div className="the-active-plan">
                      <span>Active</span>
                      </div>
                      : null
                      }

                      <img src={ require("../../assets/images/pricing/woocomerce-app.png") .default }alt="pricing-type" className="img-fluid"/>
                      <h4>WooCommerce to App</h4>
                      
                      <p>Convert any WooCommerce store into a native iOS & Android mobile app, with a simple WordPress plugin.</p>
                      
                      <h5><span>Starting from </span>{this.state.wocommerceprice !== '' && this.state.wocommerceprice !== undefined ? this.state.currency.replace('US','') +this.state.wocommerceprice : '$19'}/month</h5>
                      
                  </div>
                      
                  <div className="pricingsummary custom-app-pricingsummary">
                  <a  href={"/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'))}></a>
                  {parseInt(localStorage.getItem('website_technology')) === 3  ?
                      <div className="the-active-plan">
                      <span>Active</span>
                      </div>
                      : null
                      }
                      <img src={ require("../../assets/images/pricing/custom-app.png") .default }alt="pricing-type" className="img-fluid"/>

                      <h4>Custom App</h4>
                      
                      <p>Create a powerful content driven native iOS & Android app, even if you don’t have a website.</p>
                      
                      <h5><span>Starting from </span>{this.state.customappprice !== '' && this.state.customappprice !== undefined ? this.state.currency.replace('US','') +this.state.customappprice : '$9'}/month</h5>
                      
                  </div>
                      
                  <div className="pricingsummary greyed">

                  <img src={ require("../../assets/images/pricing/shopify-app-grey.png") .default }alt="pricing-type" className="img-fluid"/>

                      <h4>Shopify to App</h4>
                      
                      <p>Convert any Shopify store into a native iOS & Android mobile app, with a simple Shopify plugin.</p>
                      
                      {/* <h5>$49<span>per month onword</span></h5> */}
                      
                      <h6 className="comingsoon">Launching soon</h6>

                      
                  </div>
                      
                  <div className="empyty-type">
                      {/* <a href="/talk-to-sales/"></a>
                      <div className="pricing6"></div>
                      <h4>
                        <img src={ require("../../assets/images/pricing/pricing-type-6.png") .default }alt="pricing-type" className="img-fluid"/> Enterprise Apps</h4>
                      
                      <p>Looking for an Enterprise solution? Get in touch.</p>
                      
                      <h3>Get in Touch <svg width="12.867" height="10.963" viewBox="0 0 12.867 10.963">
                        <g id="Group_27881" data-name="Group 27881" transform="translate(11.807 0.53) rotate(90)">
                          <path id="Path_70955" data-name="Path 70955" d="M0,11.8V0" transform="translate(5.012 0.002)" fill="none" stroke="#3064f9" stroke-width="1.5"/>
                          <path id="Path_70956" data-name="Path 70956" d="M0,4.953,4.951,0,9.9,4.953" transform="translate(0)" fill="none" stroke="#3064f9" stroke-width="1.5"/>
                        </g>
                          </svg>
                      </h3> */}
                      
                  </div>
              </div>
                  </div>  
              </div>    
              
              </div>
          </section>

            
          </>
        }


          </>
    )
  }
}
