import React from "react";

const AppleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.417"
      height="22"
      viewBox="0 0 18.417 22"
    >
      <g id="apple-logo" transform="translate(0)">
        <g id="Group_1428" data-name="Group 1428" transform="translate(0 0)">
          <path
            id="Path_668"
            data-name="Path 668"
            d="M55.02,116.775a5.034,5.034,0,0,1-.912-2.948,4.711,4.711,0,0,1,.859-2.737,8.568,8.568,0,0,1,1.507-1.56,6.818,6.818,0,0,0-1.375-1.322,4.834,4.834,0,0,0-2.816-.859A7.461,7.461,0,0,0,50,107.8a6.621,6.621,0,0,1-1.891.45,8.505,8.505,0,0,1-1.825-.4,8.8,8.8,0,0,0-2.34-.4,4.564,4.564,0,0,0-3.755,1.9,7.844,7.844,0,0,0-1.494,4.945,13.554,13.554,0,0,0,1.944,6.65q1.97,3.424,3.979,3.424a4.779,4.779,0,0,0,1.745-.45,5.1,5.1,0,0,1,1.878-.436,5.846,5.846,0,0,1,2,.423,6.112,6.112,0,0,0,1.851.423q1.692,0,3.4-2.591a12.762,12.762,0,0,0,1.626-3.305A4.022,4.022,0,0,1,55.02,116.775Z"
            transform="translate(-38.692 -102.378)"
            fill=""
          />
          <path
            id="Path_669"
            data-name="Path 669"
            d="M236.488,4.178a5.427,5.427,0,0,0,1.23-1.824,5.044,5.044,0,0,0,.383-1.8c0-.035,0-.079-.007-.132s-.006-.1-.006-.132a1.28,1.28,0,0,1-.033-.145A1.224,1.224,0,0,0,238.021,0a5.269,5.269,0,0,0-3.305,1.957,6.03,6.03,0,0,0-1.031,3.4,7.388,7.388,0,0,0,1.375-.225A3.747,3.747,0,0,0,236.488,4.178Z"
            transform="translate(-224.655 0)"
            fill=""
          />
        </g>
      </g>
    </svg>
  );
};

export default AppleIcon;
