/* eslint-disable no-loop-func */
import React, { Suspense } from "react";
import Header from "../Header";
import Leftpanel from "../Leftpanel";
import { Media } from "reactstrap";
import { Spinner } from "reactstrap";
import "../../assets/css/Reports.css";
import { Getanalytics, Reportdata } from "../../Api/Reports/ReportsApi";
import Appcount from "./Appcount";
import Donut from "./Donut";
import Histogram from "./Histogram";
import Datatable from "./Datatable";
import Notaddonactive from "./Notactiveaddon";
import { InnerFoot } from "../../Dashboard/Foot";
import { Notallowedtouser } from "../User/Usernotalllowed";
// import moment from 'moment';
//import responsejson from '../../Api/Reports/Response';
import styles from "./chart.module.css";
import { Error } from "../Reusable/Connectivityerror";
import "./Report.css";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { trackDataSaveApi } from "../../Api/Reports/AnalyticsConnectionApi";

export default class Reports extends React.Component {
  state = {
    token: "",
    spinner: true,
    data: [],
    reportdata: [],
    selectfilter: "7d",
    setsearchvalue:"7d",
    code: 200,
    plan_code: null,
    addon_code: null,
    histogramspinner: false,
    analytics_status: [],
    iosnotifyid: null,
    androidnotifyid: null,
    appuser_role_id:localStorage.getItem('appuser_role_id'),
    appRightPanelExpand: localStorage.getItem("appSideBarCollapse") === true ||
    localStorage.getItem("appSideBarCollapse") === "true"
      ? true
      : false,
    opendaysdropdown : false,  
    openIntervalDrop : false,
    intervalFilterVal:"day",
    enableDisableBtnText:"Enable",
    enableDisableLoader:"d-none",
    trackData:true,
    trackDataFinalVal:true,
    openTrackDataPopUp:false,
    opeFirebasePopUp:false,
    appstoreConnectionStatus:0,
  };
  
  componentDidMount() {
    window.scrollTo(0, 0);
    if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
      {
        document.title = "Analytics | AppMySite";
      }
      else
      {
        document.title = "Analytics |"+ ' ' +localStorage.getItem('agencyname');      
      }
    this.stopmounting = true;

    Reportdata(this, "analytics", () => {
      if(parseInt(localStorage.getItem("website_technology")) === 2){
        Getanalytics(this.state.token, this, "non-wp-analytics");
      }else if (parseInt(localStorage.getItem("website_technology")) === 3) {
          Getanalytics(this.state.token, this, "cms-analytics");
      }else if (parseInt(localStorage.getItem("website_technology")) === 4) {
          Getanalytics(this.state.token, this, "wordpress-analytics");
      }else if(parseInt(localStorage.getItem("website_technology")) === 5) {
          Getanalytics(this.state.token, this, "woocommerce-analytics");
      }
      
    });

  }
  handleselectfilter = (value) => {
    if(value!=this.state.selectfilter){

    
    this.setState(
      {
        selectfilter: value,
        histogramspinner: true,
        setsearchvalue:value,
        intervalFilterVal:value==="1d"?"hour": ( value==="180d" || value==="360d" )?"month":"day",
      },
      () => {
        if(parseInt(localStorage.getItem("website_technology")) === 2){
          Getanalytics(this.state.token, this, "non-wp-analytics");
        }else if (parseInt(localStorage.getItem("website_technology")) === 3) {
            Getanalytics(this.state.token, this, "cms-analytics");
        }else if (parseInt(localStorage.getItem("website_technology")) === 4) {
            Getanalytics(this.state.token, this, "wordpress-analytics");
        }else if(parseInt(localStorage.getItem("website_technology")) === 5) {
            Getanalytics(this.state.token, this, "woocommerce-analytics");
        }
      }
    );
    }
  };

  handleIntervalFilter = (value) =>{
    if(value!=this.state.intervalFilterVal){

    
  this.setState({
    intervalFilterVal:value,
    histogramspinner: true,
  },()=>{
    if(parseInt(localStorage.getItem("website_technology")) === 2){
      Getanalytics(this.state.token, this, "non-wp-analytics");
    }else if (parseInt(localStorage.getItem("website_technology")) === 3) {
        Getanalytics(this.state.token, this, "cms-analytics");
    }else if (parseInt(localStorage.getItem("website_technology")) === 4) {
        Getanalytics(this.state.token, this, "wordpress-analytics");
    }else if(parseInt(localStorage.getItem("website_technology")) === 5) {
        Getanalytics(this.state.token, this, "woocommerce-analytics");
    }
  }
  
  )
  }}

  Last7Days = (checklength) => {
    var result = [];
    for (var i = 0; i < checklength; i++) {
      var d = new Date();
      d.setDate(d.getDate() - i);
      result.push(d.toDateString());
    }

    return { result };
  };

  getPreviousThreeMonths = (NoOfMonth) => {
    const now = new Date();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();
  
    const monthNames = [
      'January', 'February', 'March', 'April',
      'May', 'June', 'July', 'August',
      'September', 'October', 'November', 'December'
    ];
  
    const previousMonths = [];
  
    for (let i = NoOfMonth-1; i >= 0; i--) {
      const month = currentMonth - i;
      const year = month < 0 ? currentYear - 1 : currentYear;
  
      previousMonths.push(`${monthNames[(month + 12) % 12]} ${year}`);
    }
  
    return previousMonths;
  }
  

  
 

  getLatestMonth = () =>{
    const now = new Date();
    const hours = now.getHours();
    const todayHours = [] ;
    if(this.state.selectfilter==="90d"){
      todayHours=[];
    }
    else if(this.state.selectfilter==="180d"){
      todayHours=[];
    }else if(this.state.selectfilter==="360d"){
      todayHours=[];
    }
   
  
    return todayHours;
  }


  getLatestHour = () =>{
    const now = new Date();
    const hours = now.getHours();
    const todayHours = [] ;
    // if(this.state.selectfilter==="1d"){
    //   todayHours=["0","1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23"];
    // }else{
      for (let i=0; i<=hours; i++){
        todayHours.push(i);
      // }
    }
  
    return todayHours;
  }


  // Creating Labels
  checkdate = (e) => {
    let newObject = [];
    let a = 0;
    let newdate =
    this.state.intervalFilterVal==="hour"?
    this.getLatestHour()
    :
     this.state.intervalFilterVal==="month"?this.getPreviousThreeMonths(this.state.selectfilter==="90d"?3:this.state.selectfilter==="180d"?6:12)
    :
    this.Last7Days(
      parseInt(this.state.selectfilter.replace("d", ""))
    );
  //  return newdate;

    if(this.state.intervalFilterVal==="day"){

    
    for (a; a < newdate.result.length; a++) {
      newObject.push(
        `${newdate.result[a].split(" ")[2]} ${newdate.result[a].split(" ")[1]}`
      );
    }
    let finalobj = [...newObject].reverse();

    return finalobj.map((resv) => resv);
  }else{
    return newdate;
  }
  };


  // 
  checkdata = (e) => {
    let newObject = [];
    let newdate =
    this.state.intervalFilterVal==="hour"?
    this.getLatestHour()
    :
     this.state.intervalFilterVal==="month"?this.getPreviousThreeMonths(this.state.selectfilter==="90d"?3:this.state.selectfilter==="180d"?6:12)
    :
    this.Last7Days(
      parseInt(this.state.selectfilter.replace("d", ""))
    );

    if(this.state.intervalFilterVal==="day"){

 
    let a = 0;
    for (a; a < newdate.result.length; a++) {
      if (
        e.some(
          (e) => newdate.result[a] === new Date(e.key_as_string).toDateString()
        ) === true
      ) {
        newObject.push(
          e.filter(
            (e) =>
              newdate.result[a] === new Date(e.key_as_string).toDateString()
          )[0]
        );
      } else {
        newObject.push({ doc_count: 0 });
      }
    }
    let finalobj = [...newObject].reverse();
 
    return finalobj.map((resv) => resv.doc_count);
  }else if(this.state.intervalFilterVal==="hour"){

   
    newdate && newdate.map((val,indx)=>{
     
      let selelectedIndx = 0;
      let isMatch = false;
      for (let i=0; i < e.length; i++) {
        if(new Date(e[i].key_as_string).getHours() == val){
          isMatch=true;
          selelectedIndx=i;
          break;
        }
      }
      if(isMatch){

        newObject.push(e[selelectedIndx].doc_count);
      }else{
        newObject.push(0);
      }
    })

    return newObject;
  }
  else if(this.state.intervalFilterVal==="month"){

   
    newdate && newdate.map((val,indx)=>{
     
      let selelectedIndx = 0;
      let isMatch = false;
      for (let i=0; i < e.length; i++) {
        if(new Date(e[i].key_as_string).getMonth() ==new Date(val).getMonth() ){
          isMatch=true;
          selelectedIndx=i;
          break;
        }
      }
      if(isMatch){

        newObject.push(e[selelectedIndx].doc_count);
      }else{
        newObject.push(0);
      }
    })
    return newObject;
  }
  };

 

  componentWillUnmount() {
    this.stopmounting = false;
  }

  handleTrackDataToggle = (e,from) => {

    if(from==="toggle"){
      this.setState({
        trackData:e.target.checked,
        enableDisableBtnText:e.target.checked === true ? "Enable" : "Disable",
      })
    }else{

      this.setState({
        trackData:this.state.trackData===true?false:true,
      })
    }

      this.setState({
        openTrackDataPopUp : !this.state.openTrackDataPopUp,
      })
  }
  handleEnableTrackDataBtn = (e) => {
      this.setState({
        trackData:true,
        enableDisableBtnText:"Enable",
        openTrackDataPopUp : !this.state.openTrackDataPopUp,
      })
  }

  handleTrackDataSave = (e,from) => {

    this.setState({
      enableDisableLoader:"d-block",
      enableDisableBtnText:"",
    })
      const params = {
        app_id:parseInt(localStorage.getItem("Appid")),
        record_analytics_data : this.state.trackData===true?1:0,
      }
      trackDataSaveApi(params,this);
    

  }

  handleFirebasePopUp = (e,from) => {
      this.setState({
        opeFirebasePopUp : !this.state.opeFirebasePopUp,
      })
  }

  handleRightPanelExpand = (value) => {

    this.setState({
      appRightPanelExpand: value
    })
    }
    handleAppdropdown = ()=>{
      this.setState({
        opendaysdropdown: !this.state.opendaysdropdown
      })
    }

    handleIntervaldropdown = ()=>{
      this.setState({
        openIntervalDrop: !this.state.openIntervalDrop
      })
    }
  

  render() {

    const Errorecommdata = {
      heading_first: "Timed Out",
      heading_second: "Kindly Try After Some Time",
      button_text: "Go To Dashboard",
      button_link: "/app/dashboard",
    };
    const Errordata = {
      heading_first: "Connect website with app",
      heading_second:
        "This step is mandatory to sync your website with the mobile app.",
      heading_third:
        "Install the Wordpress plugin and verify it in to connect website with app.",
      button_text: "Connect APP",
      button_link: "/app/connect/wp-plugin",
    };

    

    return (
      <>
        <div className="">
          <Header customclass={"header-responsive-new"} />
        </div>
        {/* Mobile View */}
        
        {/* Mobile View End*/}
        <section className="dasboard_page  mt-md-0 report_dasboard_page">
        <Leftpanel handleRightPanelExpand={this.handleRightPanelExpand} />
          
<div className={`right-panel-gap ${(localStorage.getItem("appSideBarCollapse") !== undefined &&
                      localStorage.getItem("appSideBarCollapse") !== null&&
                      localStorage.getItem("appSideBarCollapse") === "true") ?
                          " right-panel-gap-expand": ""}`}>
            <div
              className=""
              // style={{ minHeight: "100%" }}
            >
              <div
                className={
                  "right_general main-analytics-div " + styles.spacing
                }

              >
                {
                  this.state.spinner  &&
                  <div

                  className="report_header"
                >
                 <div className="d-flex">
            {" "}
            <h3 className="mb-2">Analytics <p className=" consent-tooltip">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                    <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                      <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                        <circle cx="9" cy="9" r="9" stroke="none" />
                        <circle cx="9" cy="9" r="8.25" fill="none" />
                      </g>
                      <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5" />
                      <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5" />
                    </g>
                  </svg>
                  <p className="consent-tooltipsubheader">
                  Unlock insights, explore user behavior, engagement, and key app metrics. Track downloads, user sessions, and content related analytics to make informed decisions and drive success.
                  </p>
                </p></h3>
            
                </div> 
                </div>
                }

                {this.state.spinner ? (
                  <div className="newspinner">
                  <svg xmlns="http://www.w3.org/2000/svg" 
            xlink="http://www.w3.org/1999/xlink" 
            style={{margin: "auto", background: "rgb(241, 242, 243,0)", 
            display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
            width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
              <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
              style={{animationplaystate: "running", animationdelay: "0s"}}>
              </animateTransform>
            </circle>
            </g>
            <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
            style={{animationplaystate: "running", animationdelay: "0s"}}>
            </animateTransform>
            </circle>
            </g>
            <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
            style={{animationplaystate: "running", animationdelay: "0s"}}>
  
            </animateTransform>
            </circle>
            </g>
            <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
            style={{animationplaystate: "running", animationdelay: "0s"}}>
  
            </animateTransform>
            </circle>
            </g>
            </svg>
                </div>
                ) : ( this.state.code === 203) ? (
                  <Suspense fallback={""}>
                    <div className="d-flex align-items-center justify-content-between report_header">
            
                        <h3 className="">Analytics
                        <p className=" consent-tooltip">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                    <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                      <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                        <circle cx="9" cy="9" r="9" stroke="none" />
                        <circle cx="9" cy="9" r="8.25" fill="none" />
                      </g>
                      <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5" />
                      <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5" />
                    </g>
                  </svg>
                  <p className="consent-tooltipsubheader">
                  Unlock insights, explore user behavior, engagement, and key app metrics. Track downloads, user sessions, and content related analytics to make informed decisions and drive success.
                  </p>
                </p>
                        </h3>
                        {
                      (localStorage.getItem("agencyid") && localStorage.getItem("agencyid") != 1) && this.state.code !== 200 &&
                      window.matchMedia("(min-width: 1200px)").matches &&
                    <div style={{}} className="upgrade-feature">
                      <h4 className="upgrade-unavailable">This feature is currently unavailable</h4>
                      <p className="upgrade-support">Please contact Support for assistance.</p>
                    </div>
                    }
                        {/* <h5 className="d-none d-md-block">
                          Understand your customers and their app usage better,
                          and make informed decisions.{" "} */}
                          {/* <Media
                            href="https://www.appmysite.com/support/category/analytics/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="align-items-center mx-1 kb-link"
                          >
                            Learn More{" "}
                            <img
                              src={
                                require("../../assets/images/dashboard/Goto.png")
                                  .default
                              }
                              className="gotoappicon appear-spacing"
                              alt="Goto"
                            />
                          </Media>{" "} */}
                        {/* </h5> */}
                     
                      {/* <div className="analytics-status">
                       <div className="d-flex firebase">
              {this.state.androidnotifyid !== null ?
                
                  <p><svg width="15.688" height="21.536" viewBox="0 0 15.688 21.536">
                      <g id="firebase-1" transform="translate(0 0)">
                        <path id="Path_9361" data-name="Path 9361" d="M0,17.383.13,17.2,6.3,5.5l.013-.124L3.592.268a.5.5,0,0,0-.944.159Z" transform="translate(0 0)" fill="#ffc24a"/>
                        <path id="Path_9362" data-name="Path 9362" d="M1.253,18.465l.1-.192,6.1-11.58L4.742,1.566a.458.458,0,0,0-.872.158Z" transform="translate(-1.176 -1.221)" fill="#ffa712"/>
                        <path id="Path_9364" data-name="Path 9364" d="M104.422,55.439l2.024-2.073L104.421,49.5a.522.522,0,0,0-.919,0l-1.082,2.063v.176l2,3.7Z" transform="translate(-96.129 -46.202)" fill="#f4bd62"/>
                        <path id="Path_9365" data-name="Path 9365" d="M104.685,56.548l1.968-2.015-1.968-3.747a.453.453,0,0,0-.826-.036l-1.088,2.1-.033.107Z" transform="translate(-96.428 -47.397)" fill="#ffa50e"/>
                        <path id="Path_9367" data-name="Path 9367" d="M0,99.138l.059-.059.215-.087,7.892-7.862.1-.272L6.3,87.1Z" transform="translate(0 -81.754)" fill="#f6820c"/>
                        <path id="Path_9368" data-name="Path 9368" d="M8.545,88.483,15.688,84.5l-2.04-12.561a.488.488,0,0,0-.827-.267L0,84.518l7.1,3.965a1.482,1.482,0,0,0,1.444,0" transform="translate(0 -67.135)" fill="#fde068"/>
                        <path id="Path_9369" data-name="Path 9369" d="M16.834,85.382,14.81,72.916a.429.429,0,0,0-.743-.272L1.29,85.409l7.023,3.926a1.471,1.471,0,0,0,1.433,0l7.088-3.953Z" transform="translate(-1.211 -68.05)" fill="#fcca3f"/>
                        <path id="Path_9370" data-name="Path 9370" d="M8.546,284.925a1.482,1.482,0,0,1-1.444,0L.058,281.017l-.057.06,7.1,3.965a1.482,1.482,0,0,0,1.444,0l7.142-3.983-.018-.108-7.125,3.973Z" transform="translate(-0.001 -263.695)" fill="#eeab37"/>
                      </g>
                    </svg>
                    Reports
                  </p>  
                : this.state.iosnotifyid !== null ?
                  <p><svg width="15.688" height="21.536" viewBox="0 0 15.688 21.536">
                      <g id="firebase-1" transform="translate(0 0)">
                        <path id="Path_9361" data-name="Path 9361" d="M0,17.383.13,17.2,6.3,5.5l.013-.124L3.592.268a.5.5,0,0,0-.944.159Z" transform="translate(0 0)" fill="#ffc24a"/>
                        <path id="Path_9362" data-name="Path 9362" d="M1.253,18.465l.1-.192,6.1-11.58L4.742,1.566a.458.458,0,0,0-.872.158Z" transform="translate(-1.176 -1.221)" fill="#ffa712"/>
                        <path id="Path_9364" data-name="Path 9364" d="M104.422,55.439l2.024-2.073L104.421,49.5a.522.522,0,0,0-.919,0l-1.082,2.063v.176l2,3.7Z" transform="translate(-96.129 -46.202)" fill="#f4bd62"/>
                        <path id="Path_9365" data-name="Path 9365" d="M104.685,56.548l1.968-2.015-1.968-3.747a.453.453,0,0,0-.826-.036l-1.088,2.1-.033.107Z" transform="translate(-96.428 -47.397)" fill="#ffa50e"/>
                        <path id="Path_9367" data-name="Path 9367" d="M0,99.138l.059-.059.215-.087,7.892-7.862.1-.272L6.3,87.1Z" transform="translate(0 -81.754)" fill="#f6820c"/>
                        <path id="Path_9368" data-name="Path 9368" d="M8.545,88.483,15.688,84.5l-2.04-12.561a.488.488,0,0,0-.827-.267L0,84.518l7.1,3.965a1.482,1.482,0,0,0,1.444,0" transform="translate(0 -67.135)" fill="#fde068"/>
                        <path id="Path_9369" data-name="Path 9369" d="M16.834,85.382,14.81,72.916a.429.429,0,0,0-.743-.272L1.29,85.409l7.023,3.926a1.471,1.471,0,0,0,1.433,0l7.088-3.953Z" transform="translate(-1.211 -68.05)" fill="#fcca3f"/>
                        <path id="Path_9370" data-name="Path 9370" d="M8.546,284.925a1.482,1.482,0,0,1-1.444,0L.058,281.017l-.057.06,7.1,3.965a1.482,1.482,0,0,0,1.444,0l7.142-3.983-.018-.108-7.125,3.973Z" transform="translate(-0.001 -263.695)" fill="#eeab37"/>
                      </g>
                    </svg>
                    Reports
                  </p>
                : null
              }
          <div>
                {this.state.androidnotifyid !== null ?
                  <a target="_blank" href={`https://console.firebase.google.com/project/${this.state.androidnotifyid}/analytics/`}>Android <img
                        src={
                          require("../../assets/images/dashboard/Goto.png").default
                        }
                        className="dashboard-gotospace"
                        alt="Goto"
                      /></a>
                : null
              }

                {this.state.iosnotifyid !== null ?
                  <a target="_blank" href={`https://console.firebase.google.com/project/${this.state.iosnotifyid}/analytics/`}> iOS <img
                        src={
                          require("../../assets/images/dashboard/Goto.png").default
                        }
                        className="dashboard-gotospace"
                        alt="Goto"
                      /></a>
                : null
              }
            </div>
          </div>
                        <p>
                          Analytics Status1:{" "}
                          <span
                            style={{
                              color: `${this.state.analytics_status.colour}`,
                            }}
                          >
                            {this.state.analytics_status.title}
                          </span>
                        </p>
                      </div> */}
                    </div>
                    {
                      (localStorage.getItem("agencyid") && localStorage.getItem("agencyid") != 1) && this.state.code !== 200 &&
                      window.matchMedia("(max-width: 1200px)").matches &&
                    <div style={{margin: "0px"}} className="upgrade-feature">
                      <h4 style={{textAlign: "unset"}} className="upgrade-unavailable">This feature is currently unavailable</h4>
                      <p style={{textAlign: "unset"}} className="upgrade-support">Please contact Support for assistance.</p>
                    </div>
                    }
                    {/* {(this.state.appuser_role_id == 3 || this.state.appuser_role_id == 4) ?
                       Notallowedtouser() 
                     : */}
                      <Notaddonactive value={this} />
                    {/* } */}
                </Suspense>
                ) : ((parseInt(localStorage.getItem("website_technology")) === 4) && this.state.data.is_plugin_enabled === 1) ||
                      this.state.data.plan === "premium" || 
                    ((parseInt(localStorage.getItem("website_technology")) === 5) && this.state.data.is_plugin_enabled === 1 && this.state.data.is_connectivity_filled_and_connected === 1) ||
                      parseInt(localStorage.getItem("website_technology")) === 3 ||
                       parseInt(localStorage.getItem("website_technology")) === 2  ? (
                  <>
                    <Appcount value={this} />
                    {
                      this.state.trackDataFinalVal === false ?
                        <Notaddonactive value={this} />
                    :
                    <>
                    <div className="d-flex align-items-center justify-content-between report_analyticss">
                      <div className="row row-analyticss w-100 ">
                        <div
                          className={
                            styles.histogram +
                            " " +
                            styles.histogramparent
                          }
                        >
                          <div
                            className={
                              "d-flex justify-content-between align-items-center Report_new-analytics-bar-main " +
                              styles.heading
                            }
                          >
                            <h2 className="m-0">App traffic</h2>

                            
                            {/* <div>
                              <select
                                onChange={(e) => this.handleselectfilter(e)}
                                value={this.state.selectfilter}
                                className={"typeselect " + styles.selectfield}
                              >
                                <option value="1d">Last 24 hours</option>
                                <option value="7d">Last 7 days</option>
                                <option value="14d">Last 14 days</option>
                                <option value="30d">Last 30 days</option>
                                <option value="60d">Last 60 days</option>
                                <option value="90d">Last 3 months</option>
                                <option value="180d">Last 6 months</option>
                                <option value="360d">Last 12 months</option>
                              </select>
                            </div> */}
                       
                      <div className="analytics-dropdown">
                            <Dropdown 
                                    isOpen={this.state.opendaysdropdown}
                                    // onClick={
                                    //   window.matchMedia("(max-width: 1200px)")
                                    //     .matches
                                    //     ? this.handleMobileAppDropdown
                                    //     : null
                                    // }
                                    toggle={this.handleAppdropdown}
                                    >
                                      <div className={this.state.opendaysdropdown? "report-aerotop": "report-aerohidden"}>
                                      {/* <img
                                        src={
                                          require("../../assets/images/dashboard/dropdownaerotop.png")
                                            .default
                                        }
                                        className=""
                                        alt=" "
                                      /> */}
                                      </div>
                                    <DropdownToggle caret>
                                      <span>{this.state.setsearchvalue == '1d' ? 'Today'
                                            :this.state.setsearchvalue == '7d' ? '7 days'
                                            // :this.state.setsearchvalue == '14d' ? '14 days'
                                            :this.state.setsearchvalue == '30d' ? '1 month'
                                            // :this.state.setsearchvalue == '60d' ? '2 months'
                                            :this.state.setsearchvalue == '90d' ? '3 months'
                                            :this.state.setsearchvalue == '180d' ? '6 months'
                                            :this.state.setsearchvalue == '360d' ? '12 months'
                                            : '7 Days'
                                            }
                                      </span>
                                    </DropdownToggle>
                                    <DropdownMenu style={{width: "160px"}} end={true}>
                                      
                                      <DropdownItem active={this.state.selectfilter=="1d"} onClick={() =>this.handleselectfilter('1d')}>Today</DropdownItem>
                                      <DropdownItem active={this.state.selectfilter=="7d"}  onClick={() =>this.handleselectfilter('7d')}>7 days</DropdownItem>
                                      {/* <DropdownItem active={this.state.selectfilter=="14d"}  onClick={() =>this.handleselectfilter('14d')}>14 days</DropdownItem> */}
                                      <DropdownItem active={this.state.selectfilter=="30d"}  onClick={() =>this.handleselectfilter('30d')}>1 month</DropdownItem>
                                      {/* <DropdownItem active={this.state.selectfilter=="60d"}  onClick={() =>this.handleselectfilter('60d')}>2 months</DropdownItem> */}
                                      <DropdownItem active={this.state.selectfilter=="90d"}  onClick={() =>this.handleselectfilter('90d')}>3 months</DropdownItem>
                                      <DropdownItem active={this.state.selectfilter=="180d"}  onClick={() =>this.handleselectfilter('180d')}>6 months</DropdownItem>
                                      <DropdownItem active={this.state.selectfilter=="360d"}  onClick={() =>this.handleselectfilter('360d')}>12 months</DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>

                                  <Dropdown className="analytics-second-dropdown"
                                    isOpen={this.state.openIntervalDrop}
                                    // onClick={
                                    //   window.matchMedia("(max-width: 1200px)")
                                    //     .matches
                                    //     ? this.handleMobileAppDropdown
                                    //     : null
                                    // }
                                    toggle={this.handleIntervaldropdown}
                                    >
                                      <div className={this.state.openIntervalDrop? "report-aerotopsecond": "report-aerohidden"}>
                                      {/* <img
                                        src={
                                          require("../../assets/images/dashboard/dropdownaerotop.png")
                                            .default
                                        }
                                        className=""
                                        alt=" "
                                      /> */}
                                      </div>
                                    <DropdownToggle caret>
                                      <span>{this.state.intervalFilterVal == 'hour' ? 'Hour'
                                            :this.state.intervalFilterVal == 'day' ? 'Day'
                                            :this.state.intervalFilterVal == 'month' ? 'Month'
                               
                                            : 'Day'
                                            }
                                      </span>
                                    </DropdownToggle>
                                    <DropdownMenu style={{width: "160px"}} end={true}>
                                    {/* {this.state.selectfilter== "90d" || this.state.selectfilter=="180d" ||  this.state.selectfilter=="360d" ?
                                      <></> 
                                    :
                                      <> */}
                                      <DropdownItem 
                                       disabled={this.state.setsearchvalue !=="1d"?true:false}
                                       onClick={() =>this.handleIntervalFilter('hour')}>Hour</DropdownItem>
                                      <DropdownItem 
                                      disabled={this.state.setsearchvalue ==="1d"?true:false}
                                      onClick={() =>this.handleIntervalFilter('day')}>Day</DropdownItem>
                                      {/* </>
                                    } */}

                                      <DropdownItem  
                                      disabled={(this.state.setsearchvalue ==="90d" || this.state.setsearchvalue ==="180d" || this.state.setsearchvalue ==="360d" ) ?false:true}
                                      onClick={() =>this.handleIntervalFilter('month')}>Month</DropdownItem>
                                    
                                    </DropdownMenu>
                                  </Dropdown>
                                    {/* <h6>|</h6> */}


                                  {/* <Dropdown 
                                    isOpen={
                                      window.matchMedia("(max-width: 1200px)")
                                        .matches
                                        ? false
                                        : this.state.opendaysdropdown
                                    }
                                    toggle={this.handleAppdropdown}
                                    >
                                    <DropdownToggle caret>
                                      <span>12 months</span>
                                    </DropdownToggle>
                                    <DropdownMenu end={true}>
                                      
                                      <DropdownItem>Today</DropdownItem>
                                      <DropdownItem>Yesterday</DropdownItem>
                                      <DropdownItem>7 days</DropdownItem>
                                      <DropdownItem>1 month</DropdownItem>
                                      <DropdownItem>3 month</DropdownItem>
                                      <DropdownItem>6 month</DropdownItem>
                                      <DropdownItem>12 month</DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown> */}
                            </div>
                            {/* <div className="analytics-dropdown">
                            <Dropdown 
                                    isOpen={this.state.openIntervalDrop}
                                    
                                    toggle={this.handleIntervaldropdown}
                                    >
                                    <DropdownToggle caret>
                                      <span>{this.state.intervalFilterVal == 'hour' ? 'Hour'
                                            :this.state.intervalFilterVal == 'day' ? 'Day'
                                            :this.state.intervalFilterVal == 'month' ? 'Month'
                               
                                            : 'Day'
                                            }
                                      </span>
                                    </DropdownToggle>
                                    <DropdownMenu end={true}>
                                      
                                      <DropdownItem 
                                       disabled={this.state.setsearchvalue !=="1d"?true:false}
                                       onClick={() =>this.handleIntervalFilter('hour')}>Hour</DropdownItem>
                                      <DropdownItem 
                                      disabled={this.state.setsearchvalue ==="1d"?true:false}
                                      onClick={() =>this.handleIntervalFilter('day')}>Day</DropdownItem>
                                      <DropdownItem  
                                      disabled={(this.state.setsearchvalue ==="90d" || this.state.setsearchvalue ==="180d" || this.state.setsearchvalue ==="360d" ) ?false:true}
                                      onClick={() =>this.handleIntervalFilter('month')}>Month</DropdownItem>
                                    
                                    </DropdownMenu>
                                  </Dropdown>
                            </div> */}
                        
                          </div>
                          {this.state.histogramspinner? (
                               <div className="analytics-spin" style={{ background: "none" }}>
                         
                               <svg xmlns="http://www.w3.org/2000/svg" 
               xlink="http://www.w3.org/1999/xlink" 
               style={{margin: "auto", background: "rgba(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
               width="200px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
               <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
               <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                 <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                 style={{animationplaystate: "running", animationdelay: "0s"}}>
                 </animateTransform>
               </circle>
               </g>
               <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
               <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
               <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
               style={{animationplaystate: "running", animationdelay: "0s"}}>
               </animateTransform>
               </circle>
               </g>
               <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
               <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
               <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
               style={{animationplaystate: "running", animationdelay: "0s"}}>
     
               </animateTransform>
               </circle>
               </g>
               <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
               <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
               <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
               style={{animationplaystate: "running", animationdelay: "0s"}}>
     
               </animateTransform>
               </circle>
               </g>
                               </svg>
                             </div>
                          ) : (
                            this.state.reportdata.map(
                              (res) =>
                                res.item_type ===
                                  "app_api_requests_histogram" &&
                                ( (!res.value || !res.value.length ) ?
                                   (
                                  <div
                                    className={
                                      styles.adjustbargraphheight +
                                      " new-analytics-barheight"
                                    }
                                   
                                  >
                                    <div className={styles.adjustimg}>
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/analytics-nodata-web.png")
                                            .default
                                        }
                                        className="d-md-block d-none analytics-bar-img Report_nodata_img"
                                        alt=" "
                                      />
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/analytics-nodata-mobile.png")
                                            .default
                                        }
                                        className="d-md-none d-block analytics-nodata-img Report_mobile_img"
                                        alt=" "
                                      />
                                    </div>
                                  </div>
                                ) : (
                                    <>
                                      {
                                        <>
                                          {res.value[0].key_as_string && (
                                            <>
                                              <Histogram
                                                value={{
                                                  maintainAspectRatio: true,
                                                  responsive: true,
                                                  labels: this.checkdate(
                                                    res.value
                                                  ),

                                                  datasets: [
                                                    {
                                                      label: "Traffic",
                                                      responsive: true,
                                                      maintainAspectRatio: true,
                                                      data: this.checkdata(
                                                        res.value
                                                      ),
                                                      backgroundColor:
                                                        "#A7BEFF",
                                                      borderColor: "#A7BEFF",
                                                      fill: true,
                                                      padding: "20px",
                                                    },
                                                  ],
                                                }}
                                              />
                                            </>
                                          )}
                                        </>
                                      }
                                    </>
                                  
                                ))
                            )
                          )}
                        </div>
                        <div
                          className={
                            styles.histogram +
                            " d-flex align-items-center  " +
                            styles.donutparent
                          }
                        >
                          {this.state.histogramspinner ? (
                               <div className="analytics-spin" style={{ background: "none" }}>
                               <svg xmlns="http://www.w3.org/2000/svg" 
               xlink="http://www.w3.org/1999/xlink" 
               style={{margin: "auto", background: "rgba(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
               width="200px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
               <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
               <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                 <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                 style={{animationplaystate: "running", animationdelay: "0s"}}>
                 </animateTransform>
               </circle>
               </g>
               <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
               <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
               <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
               style={{animationplaystate: "running", animationdelay: "0s"}}>
               </animateTransform>
               </circle>
               </g>
               <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
               <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
               <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
               style={{animationplaystate: "running", animationdelay: "0s"}}>
     
               </animateTransform>
               </circle>
               </g>
               <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
               <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
               <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
               style={{animationplaystate: "running", animationdelay: "0s"}}>
     
               </animateTransform>
               </circle>
               </g>
               </svg>
                             </div>
                          ) : (
                            <>
                              <div
                                style={{
                                  width: "100%",
                                }}
                                className="d-flex align-items-center justify-content-center"
                              >
                                {(() => {
                                  let app_count = this.state.reportdata;
                                  let count = 0;

                                  for (
                                    count;
                                    count < app_count.length;
                                    count++
                                  ) {
                                    if (
                                      app_count[count].item_type ===
                                      "android_vs_ios_users_count"
                                    ) {
                                      return (
                                        <>
                                          <div
                                            className={
                                               app_count[count].value
                                                  .length > 0
                                                  ? "text-center  mt-3"
                                                  : "text-center "
                                            }
                                          >
                                            {app_count[count].value
                                              .length > 0 ? (
                                              
                                                app_count[count].value
                                              .length > 0 ? (
                                                
                                                  app_count[count].value
                                                .length > 0 ? (
                                                  <p
                                                    className={
                                                      "p-0 m-0 " +
                                                      styles.tookval
                                                    }
                                                  >
                                                    {(!app_count[count].value[0]
                                                      ? 0
                                                      : parseInt(
                                                          app_count[count].value[0]
                                                            .doc_count
                                                        )) +
                                                      (!app_count[count].value[1] 
                                                        ? 0
                                                        : parseInt(
                                                            app_count[count]
                                                              .value[1]
                                                              .doc_count
                                                          ))}
                                                  </p>
                                                ) : (
                                                  <p
                                                    className={
                                                      "p-0 m-0 " +
                                                      styles.tookval
                                                    }
                                                    style={{ color: "#C8CEDB" }}
                                                  >
                                                    0
                                                  </p>
                                                )
                                              ) : (
                                                <p
                                                  className={
                                                    "p-0 m-0 " + styles.tookval
                                                  }
                                                  style={{ color: "#C8CEDB" }}
                                                >
                                                  0
                                                </p>
                                              )
                                            ) : (
                                              <p
                                                className={
                                                  "p-0 m-0 " + styles.tookval
                                                }
                                                style={{ color: "#C8CEDB"}}
                                              >
                                                0
                                              </p>
                                            )}

                                            <p
                                              className={
                                                "p-0 m-0 " + styles.tookcount
                                              }
                                            >
                                              Traffic Count
                                            </p>
                                          </div>
                                        </>
                                      );
                                    }
                                  }
                                })()}
                              </div>
                              <div className={styles.donutwidth}>
                                <div
                                  className={
                                    styles.heading +
                                    " chart_header"
                                  }
                                >
                                  <h2>App traffic by device</h2>
                                </div>
                                <Donut value={this} />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="justify-content-start flex-wrap Report_divsameheight">
                      <Datatable value={this} />
                    </div>
                    </>
                    }
                  </>
                ) : (
                  <div className="pt-5">
                    {
                      <Error errordata={Errordata} />
                      
                      // New code comment
                      // parseInt(localStorage.getItem("website_technology")) === 4 ? 
                      // (
                      //   <Error errordata={Errorecommdata} />
                      // ) 
                      // : 
                      // (
                      //   <Error errordata={Errordata} />
                      // )
                      // New code comment





                      // this.state.data.is_plugin_enabled === 1
                      // ?
                      // <Error errordata = {Errorecommdata}/>
                      //  :
                      // <Error errordata = {Errordata}/>
                    }

                    {}
                  </div>
                )}
              </div>
            </div>
            {/* {InnerFoot()} */}
          </div>

          <Modal isOpen={this.state.openTrackDataPopUp} 
          className="analytics-secondmodal" 
          style={{ maxWidth: "480px", maxHeight: "280px" }} 
          toggle={(e) => this.handleTrackDataToggle(e,"popUp")}
          centered 
          >
                    <ModalHeader>
             
                        <div className="analytics-secondmodal-crossmodal" 
                        onClick={(e) => this.handleTrackDataToggle(e,"popUp")} 
                        style={{ zIndex:"9" }} >
                            <img src={ require("../../assets/images/dashboard/cross.png").default } />
                        </div>
                    </ModalHeader>

                    <ModalBody>

                        <div className='analytics-popup-maindiv'>
                            <div className='analytics-popup-head'>
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                    <g id="Group_41408" data-name="Group 41408" transform="translate(-324.314 -455.386)">
                                        <g id="Ellipse_2829" data-name="Ellipse 2829" transform="translate(324.314 455.386)" fill="#fff1f1" stroke="#ff4848" stroke-width="1">
                                            <circle cx="8" cy="8" r="8" stroke="none" />
                                            <circle cx="8" cy="8" r="7.5" fill="none" />
                                        </g>
                                        <path id="Path_8311" data-name="Path 8311" d="M0,3.438,3.438,0" transform="translate(330.187 461.902) rotate(-45)" fill="none" stroke="#ff4848" stroke-width="1" />
                                        <path id="Path_10113" data-name="Path 10113" d="M0,1.118,1.117,0" transform="translate(331.826 466.634) rotate(-45)" fill="none" stroke="#ff4848" stroke-width="1" />
                                    </g>
                                </svg> */}
                                {
                                  this.state.trackData ?
                                 <h2>Would you like to enable analytics tracking?</h2> 
                                  :
                                <h2>Would you like to disable analytics tracking?</h2> }
                            </div>

                            {
                                  this.state.trackData ?
                                 <p>Enabling analytics tracking will start tracking your app’s usage data.</p> 
                                  :
                                  <p>Disabling analytics tracking will suspend the tracking of your app’s usage data.</p> }
                            

                            <div className='analytics-popup-bottom'>
                                
                                <button className='analytics-popup-cancel' onClick={(e) => this.handleTrackDataToggle(e,"popUp")}>Cancel</button>

                                <button 
                                className='analytics-popup-confirm' 
                                onClick={(e) => this.handleTrackDataSave(e)}
                                style={{
                                  backgroundColor:localStorage.getItem('button_background_color'),
                                  color:localStorage.getItem('button_text_color'),
                                  "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                                  "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
                                  "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')
                                }}
                                >
                      <div className={this.state.enableDisableLoader}>
                      <div className="d-flex justify-content-center">
                                  <div className="new-loader"></div>

                                    </div>
                      </div>
                      <div>
                        {this.state.enableDisableBtnText}
                      </div>
                                  
                                  </button>
                                {/* <button className='analytics-popup-deletebtn' onClick={(e) => this.handleDeleteAd(e)}>Delete</button> */}

                            </div>
                        </div>

                    </ModalBody>

                </Modal>



                <Modal
            className="analytics-firebase-popup"
            style={{ maxWidth: "600px", maxHeight: "350px" }}
            isOpen={this.state.opeFirebasePopUp}
            centered
            toggle={(e) => this.handleFirebasePopUp(e)}
          >
            <ModalHeader>

              <div
                className="firebase-popup-crossmodal"
                onClick={(e) => this.handleFirebasePopUp(e)}
                style={{
                  zIndex: "9"
                }}
              >
                <img
                  src={
                    require("../../assets/images/dashboard/cross.png").default
                  }
                />
              </div>
            </ModalHeader>
            <ModalBody>

              <div className='analytics-firebase-maindiv'>

                <div className="d-flex align-items-center justify-content-center">

                  <img
                    src={
                      require("../../assets/images/dashboard/notificationmodal.png")
                        .default
                    }
                    className=""
                    alt=""
                  />
                </div>

                

                  <h2>Get analytics report</h2>
               
                <p>Access comprehensive analytics of your app's performance on Google Firebase.</p>

                <div className='analytics-firebase-bottom'>
                  {
                  this.state.androidnotifyid !== null ? 

                   <a target="_blank"
                   href={`https://console.firebase.google.com/project/${this.state.androidnotifyid}/analytics/`}
                    className="analytics-enable-btn">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18.591" height="22" viewBox="0 0 18.591 22">
                      <path id="Path_7126" data-name="Path 7126" d="M1.376,15.116A1.3,1.3,0,0,1,0,13.912V8.761A1.3,1.3,0,0,1,1.376,7.556,1.3,1.3,0,0,1,2.753,8.761v5.151A1.3,1.3,0,0,1,1.376,15.116Zm10.411,5.037,1.2,1.2a.379.379,0,0,1-.537.536l-1.48-1.478a7.024,7.024,0,0,1-3.32,0L6.175,21.889a.379.379,0,1,1-.537-.536l1.2-1.193A4.85,4.85,0,0,1,3.45,15.8c0-.023,11.706,0,11.706,0A4.843,4.843,0,0,1,11.788,20.153ZM7.582,17.178a.687.687,0,1,0,.689.687A.687.687,0,0,0,7.582,17.178Zm3.444,0a.687.687,0,1,0,.689.687A.687.687,0,0,0,11.025,17.178Zm6.19-2.062a1.3,1.3,0,0,1-1.377-1.2V8.761a1.389,1.389,0,0,1,2.753,0v5.151A1.3,1.3,0,0,1,17.216,15.116ZM3.45,6.875a2.063,2.063,0,0,1,2.059-2.06V1.205A1.3,1.3,0,0,1,6.886,0,1.3,1.3,0,0,1,8.261,1.205V4.814H10.33V1.205A1.3,1.3,0,0,1,11.706,0a1.3,1.3,0,0,1,1.376,1.205V4.814h.009a2.063,2.063,0,0,1,2.066,2.06v8.243H3.451V6.875Z" transform="translate(18.591 22) rotate(180)" fill="#3ddb86" />
                    </svg>
                    <p>Android</p>
                    <img
                      src={
                        require("../../assets/images/dashboard/Goto.png")
                          .default
                      }
                      className=""
                      alt="Goto"
                    />
                  </a> 
                   :  
                  <a className="analytics-firebase_disable">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18.591" height="22" viewBox="0 0 18.591 22">
                                    <path id="Path_7126" data-name="Path 7126" d="M1.376,15.116A1.3,1.3,0,0,1,0,13.912V8.761A1.3,1.3,0,0,1,1.376,7.556,1.3,1.3,0,0,1,2.753,8.761v5.151A1.3,1.3,0,0,1,1.376,15.116Zm10.411,5.037,1.2,1.2a.379.379,0,0,1-.537.536l-1.48-1.478a7.024,7.024,0,0,1-3.32,0L6.175,21.889a.379.379,0,1,1-.537-.536l1.2-1.193A4.85,4.85,0,0,1,3.45,15.8c0-.023,11.706,0,11.706,0A4.843,4.843,0,0,1,11.788,20.153ZM7.582,17.178a.687.687,0,1,0,.689.687A.687.687,0,0,0,7.582,17.178Zm3.444,0a.687.687,0,1,0,.689.687A.687.687,0,0,0,11.025,17.178Zm6.19-2.062a1.3,1.3,0,0,1-1.377-1.2V8.761a1.389,1.389,0,0,1,2.753,0v5.151A1.3,1.3,0,0,1,17.216,15.116ZM3.45,6.875a2.063,2.063,0,0,1,2.059-2.06V1.205A1.3,1.3,0,0,1,6.886,0,1.3,1.3,0,0,1,8.261,1.205V4.814H10.33V1.205A1.3,1.3,0,0,1,11.706,0a1.3,1.3,0,0,1,1.376,1.205V4.814h.009a2.063,2.063,0,0,1,2.066,2.06v8.243H3.451V6.875Z" transform="translate(18.591 22) rotate(180)" fill="" />
                                  </svg>
                  <p>Android</p>
                
                </a> 
                }
                {
                  this.state.iosnotifyid !== null ? 

                   <a target="_blank"
                   href={`https://console.firebase.google.com/project/${this.state.iosnotifyid}/analytics/`}
                    className="analytics-enable-btn"  style={{marginRight: "0px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18.417" height="22" viewBox="0 0 18.417 22">
                      <g id="apple-logo" transform="translate(0)">
                        <g id="Group_1428" data-name="Group 1428" transform="translate(0 0)">
                          <path id="Path_668" data-name="Path 668" d="M55.02,116.775a5.034,5.034,0,0,1-.912-2.948,4.711,4.711,0,0,1,.859-2.737,8.568,8.568,0,0,1,1.507-1.56,6.818,6.818,0,0,0-1.375-1.322,4.834,4.834,0,0,0-2.816-.859A7.461,7.461,0,0,0,50,107.8a6.621,6.621,0,0,1-1.891.45,8.505,8.505,0,0,1-1.825-.4,8.8,8.8,0,0,0-2.34-.4,4.564,4.564,0,0,0-3.755,1.9,7.844,7.844,0,0,0-1.494,4.945,13.554,13.554,0,0,0,1.944,6.65q1.97,3.424,3.979,3.424a4.779,4.779,0,0,0,1.745-.45,5.1,5.1,0,0,1,1.878-.436,5.846,5.846,0,0,1,2,.423,6.112,6.112,0,0,0,1.851.423q1.692,0,3.4-2.591a12.762,12.762,0,0,0,1.626-3.305A4.022,4.022,0,0,1,55.02,116.775Z" transform="translate(-38.692 -102.378)" fill="#666" />
                          <path id="Path_669" data-name="Path 669" d="M236.488,4.178a5.427,5.427,0,0,0,1.23-1.824,5.044,5.044,0,0,0,.383-1.8c0-.035,0-.079-.007-.132s-.006-.1-.006-.132a1.28,1.28,0,0,1-.033-.145A1.224,1.224,0,0,0,238.021,0a5.269,5.269,0,0,0-3.305,1.957,6.03,6.03,0,0,0-1.031,3.4,7.388,7.388,0,0,0,1.375-.225A3.747,3.747,0,0,0,236.488,4.178Z" transform="translate(-224.655 0)" fill="#666" />
                        </g>
                      </g>
                    </svg>
                    <p>iOS</p>
                    <img
                      src={
                        require("../../assets/images/dashboard/Goto.png")
                          .default
                      }
                      className=""
                      alt="Goto"
                    />

                  </a> 
                   :   

                  <a className="analytics-firebase_disable"  style={{marginRight: "0px"}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18.417" height="22" viewBox="0 0 18.417 22">
                                    <g id="apple-logo" transform="translate(0)">
                                      <g id="Group_1428" data-name="Group 1428" transform="translate(0 0)">
                                        <path id="Path_668" data-name="Path 668" d="M55.02,116.775a5.034,5.034,0,0,1-.912-2.948,4.711,4.711,0,0,1,.859-2.737,8.568,8.568,0,0,1,1.507-1.56,6.818,6.818,0,0,0-1.375-1.322,4.834,4.834,0,0,0-2.816-.859A7.461,7.461,0,0,0,50,107.8a6.621,6.621,0,0,1-1.891.45,8.505,8.505,0,0,1-1.825-.4,8.8,8.8,0,0,0-2.34-.4,4.564,4.564,0,0,0-3.755,1.9,7.844,7.844,0,0,0-1.494,4.945,13.554,13.554,0,0,0,1.944,6.65q1.97,3.424,3.979,3.424a4.779,4.779,0,0,0,1.745-.45,5.1,5.1,0,0,1,1.878-.436,5.846,5.846,0,0,1,2,.423,6.112,6.112,0,0,0,1.851.423q1.692,0,3.4-2.591a12.762,12.762,0,0,0,1.626-3.305A4.022,4.022,0,0,1,55.02,116.775Z" transform="translate(-38.692 -102.378)" fill="" />
                                        <path id="Path_669" data-name="Path 669" d="M236.488,4.178a5.427,5.427,0,0,0,1.23-1.824,5.044,5.044,0,0,0,.383-1.8c0-.035,0-.079-.007-.132s-.006-.1-.006-.132a1.28,1.28,0,0,1-.033-.145A1.224,1.224,0,0,0,238.021,0a5.269,5.269,0,0,0-3.305,1.957,6.03,6.03,0,0,0-1.031,3.4,7.388,7.388,0,0,0,1.375-.225A3.747,3.747,0,0,0,236.488,4.178Z" transform="translate(-224.655 0)" fill="" />
                                      </g>
                                    </g>
                                  </svg>
                  <p>ios</p>
                 

                </a>
                   } 
                </div>

              </div>

            </ModalBody>
          </Modal>

        </section>
      </>
    );
  }
}
