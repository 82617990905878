import React from "react";

const RedEmoji = ({ isActive }) => {
  return (
    <svg
      className={`g2redsvg ${isActive ? "activeRedG2icon" : ""}`}
      id="_9025890_smiley_sad_icon"
      data-name="9025890_smiley_sad_icon"
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <rect
        id="Rectangle_31264"
        data-name="Rectangle 31264"
        width="36"
        height="36"
        fill="none"
      />
      <circle
        className="redsvgback"
        id="Ellipse_8287"
        data-name="Ellipse 8287"
        cx="15"
        cy="15"
        r="15"
        transform="translate(3 3)"
        fill=""
        stroke=""
        stroke-miterlimit="10"
        stroke-width="2"
      />
      <circle
        className="redsvgeye"
        id="Ellipse_8290"
        data-name="Ellipse 8290"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(22 14)"
        fill=""
      />
      <circle
        className="redsvgeye"
        id="Ellipse_8291"
        data-name="Ellipse 8291"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(11 14)"
        fill=""
      />
      <path
        className="redsvgsmile"
        id="Path_104595"
        data-name="Path 104595"
        d="M96.4,155.046a5.667,5.667,0,0,0-10,0"
        transform="translate(-73.4 -130.294)"
        fill=""
        stroke=""
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </svg>
  );
};

export default RedEmoji;
