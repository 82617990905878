import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import crossIconImg from "../../../Agency/assets/images/cross.png";
import { USERS_LIST } from "../../../Constants/teamConstants/TeamConstants";

const InviteUserModal = ({
  teamState,
  setTeamState,
  addUsertoggle,
  inviteUserFunction,
  inviteUserLoading,
}) => {
  return (
    <Modal
      isOpen={teamState.addUser}
      toggle={addUsertoggle}
      style={{ maxWidth: "600px", maxHeight: "247px" }}
      className="modal-dialog workspace-team-popup modal-dialog-centered"
      dataclass="useradd"
      centered
    >
      <ModalHeader className="payper-app-invite-user">
        {teamState.mode === "addUser" ? (
          <>
            <p className="user_modalheading">Invite user</p>
            <p className="user_modalsubheading">
              Team member will have access as soon as they accept the
              invitation.
            </p>
          </>
        ) : (
          <>
            <p className="user_modalheading">Edit user role</p>
            <p className="user_modalsubheading">
              Switch toggle to change the user role.{" "}
            </p>
          </>
        )}

        <div className="crossmodal mt-1" onClick={addUsertoggle}>
          <img src={crossIconImg} alt="crossIcon" />
        </div>
      </ModalHeader>

      <ModalBody className=" payperapp-user-body">
        <div className="form-group ">
          {teamState.mode === "addUser" && (
            <div className="form-group ">
              <label className="payperapp-user-body-label">Email*</label>
              <input
                type="email"
                placeholder="jason.bourne@example.com"
                className="payperapp-user-input"
                value={teamState.userEmail}
                required
                onChange={(e) => setTeamState({ userEmail: e.target.value })}
              />
            </div>
          )}
        </div>

        <div className="payperapp-push_notification">
          {USERS_LIST.map((item, index) => (
            <div className="payperapp-color_picker ">
              <label className="container_1">
                <div>
                  <h5
                    className="payperapp-user-body-label-heading"
                    style={{
                      color:
                        localStorage.getItem("workspaceId") &&
                        localStorage.getItem("workspaceId") !== "undefined" &&
                        localStorage.getItem("workspaceId") !== "null"
                          ? parseInt(
                              localStorage.getItem("logged_in_user_role_id")
                            ) <= parseInt(item.value)
                            ? ""
                            : "#BCC1CE"
                          : parseInt(teamState.userRoleId) <=
                            parseInt(item.value)
                          ? ""
                          : "#BCC1CE",
                    }}
                  >
                    {item?.title}
                  </h5>
                  <p
                    className="payperapp-user-body-label-paragraph"
                    style={{
                      color:
                        localStorage.getItem("workspaceId") &&
                        localStorage.getItem("workspaceId") !== "undefined" &&
                        localStorage.getItem("workspaceId") !== "null"
                          ? parseInt(
                              localStorage.getItem("logged_in_user_role_id")
                            ) <= parseInt(item.value)
                            ? ""
                            : "#BCC1CE"
                          : parseInt(teamState.userRoleId) <=
                            parseInt(item.value)
                          ? ""
                          : "#BCC1CE",
                    }}
                  >
                    {item?.content}
                  </p>
                </div>

                {localStorage.getItem("workspaceId") &&
                localStorage.getItem("workspaceId") !== "null" ? (
                  <input
                    type="radio"
                    name="admin"
                    value={item.value}
                    defaultChecked={
                      parseInt(teamState.role_id) === parseInt(item.value)
                        ? true
                        : false
                    }
                    onClick={(e) => setTeamState({ role_id: e.target.value })}
                    disabled={
                      parseInt(
                        localStorage.getItem("logged_in_user_role_id")
                      ) <= parseInt(item.value)
                        ? false
                        : true
                    }
                  />
                ) : (
                  <input
                    type="radio"
                    name="admin"
                    value={item.value}
                    defaultChecked={
                      parseInt(teamState.role_id) === parseInt(item.value)
                        ? true
                        : false
                    }
                    onClick={(e) => setTeamState({ role_id: e.target.value })}
                    disabled={
                      parseInt(teamState.userRoleId) <= parseInt(item.value)
                        ? false
                        : true
                    }
                  />
                )}

                <span className="checkmark"></span>
              </label>
            </div>
          ))}
        </div>

        <div className=" mob-payperapp-btn-bottom">
          <div className="cancel-btn" onClick={addUsertoggle}>
            {teamState.mode === "addUser" ? "Back" : "Cancel"}
          </div>
          <button
            type="submit"
            className={
              teamState.userEmail.trim() != "" && teamState.role_id != 1
                ? "payperapp-team_btn "
                : "payperapp-team-user-inactive"
            }
            style={{
              backgroundColor: localStorage.getItem("button_background_color"),
              border:
                "1px solid " + localStorage.getItem("button_background_color"),
              color: localStorage.getItem("button_text_color"),
              "--custom_color5":
                localStorage.getItem("agencyid") == 1
                  ? "#134bec"
                  : localStorage.getItem("button_hover_bgcolor"),
              "--custom_color4":
                localStorage.getItem("agencyid") == 1
                  ? "white"
                  : localStorage.getItem("button_hover_txtcolor"),
              "--custom_color6":
                localStorage.getItem("agencyid") == 1
                  ? "#3064f9"
                  : localStorage.getItem("button_hover_bgcolor"),
            }}
            form="userform"
            onClick={
              teamState.userEmail.trim() != "" && teamState.role_id != 1
                ? (e) => inviteUserFunction(e)
                : void 0
            }
            id="btninviteid"
          >
            <div className="d-flex align-items-center justify-content-center h-100 w-100">
              {inviteUserLoading ? (
                <div className="new-loader"></div>
              ) : (
                teamState.submitValue
              )}
            </div>
          </button>
        </div>
      </ModalBody>

      <div className="payperapp-user-cancel-user web-payperapp-btn-bottom">
        <div className="cancel-btn mr-2 mr-md-0" onClick={addUsertoggle}>
          {teamState.mode === "addUser" ? "Back" : "Cancel"}
        </div>
        <button
          type="submit"
          className={
            teamState.userEmail.trim() != "" &&
            teamState.role_id != 1 &&
            teamState.role_id != teamState.selectedUserIdForEdit
              ? "payperapp-team_btn "
              : "payperapp-team-user-inactive"
          }
          form="userform"
          onClick={
            teamState.userEmail.trim() != "" &&
            teamState.role_id != 1 &&
            teamState.role_id != teamState.selectedUserIdForEdit
              ? (e) => inviteUserFunction(e)
              : void 0
          }
          id="btninviteid"
          style={{
            backgroundColor: localStorage.getItem("button_background_color"),
            border:
              "1px solid " + localStorage.getItem("button_background_color"),
            color: localStorage.getItem("button_text_color"),
            "--custom_color5":
              localStorage.getItem("agencyid") == 1
                ? "#134bec"
                : localStorage.getItem("button_hover_bgcolor"),
            "--custom_color4":
              localStorage.getItem("agencyid") == 1
                ? "white"
                : localStorage.getItem("button_hover_txtcolor"),
            "--custom_color6":
              localStorage.getItem("agencyid") == 1
                ? "#3064f9"
                : localStorage.getItem("button_hover_bgcolor"),
          }}
          disabled={inviteUserLoading}
        >
          <div className="d-flex align-items-center justify-content-center h-100 w-100">
            {inviteUserLoading ? (
              <div className="new-loader"></div>
            ) : (
              teamState.submitValue
            )}
          </div>
        </button>
      </div>
    </Modal>
  );
};

export default InviteUserModal;
