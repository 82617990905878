import React, {useState, useEffect} from "react";
//import "./../assets/css/common.css";
import { Link, useParams } from "react-router-dom";
import {
    verifySMTPConfiguration
} from "../Api/User/Userapi";
//import NewSpinner from "./../NewSpinner";

const AgencySMTPVerification = (props) =>{

    const [token, setToken] = useState("");
    const [spinner, setSpinner] = useState(false);
    const [message, setMessage] = useState("Please wait invitation is in process.");
    const [code, setCode] = useState(200);

    let { activetoken } = useParams("activetoken");

    useEffect(()=>{

        document.title = "Agency SMTP Verification";

        const params = {
            remember_token: activetoken
        }

        verifySMTPConfiguration(params, setSpinner, setMessage, setCode, redirectToMainPage);

    }, []);

    const redirectToMainPage = (redirecturl) =>{

        props.history.push(redirecturl);
    }

    return(
    <>
        {spinner  ? 
            <div className="spinn spinnmyapp">
                <div className="newspinner">
                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                        <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                            <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                </animateTransform>
                            </circle>
                        </g>
                        <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                            <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                </animateTransform>
                            </circle>
                        </g>
                        <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                            <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                </animateTransform>
                            </circle>
                        </g>
                        <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                            <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                </animateTransform>
                            </circle>
                        </g>
                    </svg>
                </div>
            </div>
        : 
            <></>
        }
          
        <div className="text-center d-flex align-items-center justify-content-center h-100 w-100">
            <div>
                {code === 200 ?
                    <p  className="mt-0 mb-4 agency-smtp-verification-msg">{message}</p> 
                :
                    <div className="divheight">
                        <section  className="mt-4 mt-md-0 h-100 p-3 p-md-0 agency-smtp-verification-background-color">                        
                            <div className='d-flex justify-content-center align-items-center h-100 w-100'>
                                    <div className="text-center w-100">
                                        <img className="pb-md-4 pb-3  img-fluid" src={require("../assets/images/dashboard/link-expired.png").default} alt="Link Expired" />
                                        <div className="thank-you-page text-center d-flex justify-content-center align-items-center">
                                            <div  className="w-100 link-expired">						
                                                <h1>{message}</h1>
                                                <p className="link-expired-msg">It appears that the link you are trying to access has expired.</p>
                                            </div>
                                        </div>
                                        <div className="mt-4 resend-expired-link d-block d-md-flex justify-content-center align-items-center">                                        
                                            <Link to="/agencies" className="back_btn mr-md-4 mr-2" >Back to Login</Link>
                                            {/* <button className="next_btn" >
                                                Resend Link
                                            </button> */}
                                        </div>
                                    </div>
                                </div>
                        </section>                
                    </div>
                }   
            </div>
        </div>
    </>
    );

}

export default AgencySMTPVerification;