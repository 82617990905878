import React from 'react';       
import {  Modal, ModalHeader, ModalBody } from 'reactstrap';   
class Amsvideo extends React.Component{
    constructor(props){
    super(props);
    this.state = {
        modalVideo:false,
    }
    }
    togglemodalVideo = (e) => {
		e.preventDefault();
		this.setState({
			modalVideo:!this.state.modalVideo
		})
	}
    render(){
        return(
            <>
            <div className={this.props.addclass + " mb-2 mt-2"} onClick={this.togglemodalVideo}>
           <img src={require("../../assets/images/dashboard/seevideo.png").default}  alt="apivideo" className="apivi ml-3 mr-2"/>
							<p>See Video</p>
						</div>
            <Modal isOpen={this.state.modalVideo}   toggle={this.togglemodalVideo} style={{width:"100%",height:"500px",maxWidth:"1067px"}} className="modal-xl youtubevid" centered>
					<ModalHeader toggle={this.togglemodalVideo} className="modal-categories" >
						<div className="crossmodal mb-2 ml-2" onClick={this.togglemodalVideo}>
                            
							<svg  width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
							<path id="Union_18" data-name="Union 18" d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z" transform="translate(0.529 0.531)" fill="#fff" stroke="#fff" strokeWidth="1.5"/>
							</svg>

						</div>
					</ModalHeader>
					<ModalBody className="update-woo-plugin p-0" >
						<div className="text-center ">
                        <iframe width="100%" height="600px" src=
                            {"https://www.youtube.com/embed/-u2jYkZy-JU?start=" + this.props.timespan + "&autoplay=1" }
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowFullScreen 
                            title="video"
                        ></iframe>
					</div>
					</ModalBody>
					
            </Modal> 
            </>
        )
    }        
}  

export default React.memo(Amsvideo)