import React, { useEffect, useReducer } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import GreenEmoji from "./GreenEmoji";
import RedEmoji from "./RedEmoji";
import YellowEmoji from "./YellowEmoji";
import {
  headerApiSlice,
  useSaveG2RatingDataMutation,
} from "../../services/headerApiSlice";
import { errortoast, sucesstoast } from "../../Dashboard/Toaster";

const CustomerFeedback = () => {
  const storedRating = localStorage.getItem("g2Rating");
  const [feedbackState, setFeedbackState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      userinfo: "",
      spinner: true,
      feedbackPoint:
        storedRating && storedRating !== "null" && storedRating !== "undefined"
          ? parseInt(storedRating)
          : 0,
      email: "",
      submitBtnSpin: false,
      isLoader: "d-none",
      g2Timestamp: "",
      showMobSingleGreenSvg: true,
      isHover: false,
      isLoading: false,
    }
  );

  const [trigger] = headerApiSlice.endpoints.g2RatingData.useLazyQuery();

  const [createFeedbackData] = useSaveG2RatingDataMutation();

  useEffect(() => {
    setFeedbackState({
      isLoading: true,
    });
    trigger()
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          localStorage.setItem("g2Rating", response.data.g2_rating);
          setFeedbackState({
            email: response?.data?.email,
            g2Timestamp: response?.data?.g2_created_at,
            feedbackPoint: response?.data?.g2_rating ?? 0,
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        setFeedbackState({
          isLoading: false,
        });
      });
  }, []);

  const saveUserFeedbackApi = (params) => {
    createFeedbackData(params)
      .unwrap()
      .then((res) => {
        if (res.code === 200) {
          setFeedbackState({
            submitBtnSpin: false,
            isLoader: "d-none",
            showSubmitBtn: false,
            showMobSingleGreenSvg: true,
          });
        } else {
          setFeedbackState({
            submitBtnSpin: false,
            isLoader: "d-none",
          });
        }
      })
      .catch(() => {});
  };

  const handleSubmitBtn = (point) => {
    setFeedbackState({
      submitBtnSpin: !feedbackState.submitBtnSpin,
      isLoader: "d-block",
    });
    const params = {
      agency_id: btoa(localStorage.getItem("agencyid")),
      user_id: parseInt(localStorage.getItem("user_id")),
      g2rating: point,
    };
    saveUserFeedbackApi(params);
  };

  const handleFeedbackPoints = (e, point) => {
    localStorage.setItem("g2Rating", point);
    setFeedbackState({
      feedbackPoint: point,
    });
    handleSubmitBtn(point);
  };

  const handleReturnMobSelectedSvg = () => {
    if (feedbackState.feedbackPoint === 0) {
      return <GreenEmoji isActive={false} />;
    } else if (feedbackState.feedbackPoint === 1) {
      return <RedEmoji isActive={true} />;
    } else if (feedbackState.feedbackPoint === 2) {
      return <YellowEmoji isActive={true} />;
    } else if (feedbackState.feedbackPoint === 3) {
      return <GreenEmoji isActive={true} />;
    }
  };

  const handleMobSvgOutsideClick = () => {
    setFeedbackState({
      showMobSingleGreenSvg: true,
    });
  };

  const handleReturnSelectedSvgScenerio = () => {
    if (feedbackState.feedbackPoint === 0) {
      return (
        <>
          <div onClick={(e) => handleFeedbackPoints(e, 3)}>
            <GreenEmoji isActive={false} />
          </div>

          <div onClick={(e) => handleFeedbackPoints(e, 2)}>
            <YellowEmoji isActive={false} />
          </div>
          <div onClick={(e) => handleFeedbackPoints(e, 1)}>
            <RedEmoji isActive={false} />
          </div>
        </>
      );
    } else if (feedbackState.feedbackPoint === 1) {
      return (
        <>
          <div onClick={(e) => handleFeedbackPoints(e, 1)}>
            <RedEmoji isActive={true} />
          </div>
          <div onClick={(e) => handleFeedbackPoints(e, 2)}>
            <YellowEmoji isActive={false} />
          </div>
          <div onClick={(e) => handleFeedbackPoints(e, 3)}>
            <GreenEmoji isActive={false} />
          </div>
        </>
      );
    } else if (feedbackState.feedbackPoint === 2) {
      return (
        <>
          <div onClick={(e) => handleFeedbackPoints(e, 2)}>
            <YellowEmoji isActive={true} />
          </div>
          <div onClick={(e) => handleFeedbackPoints(e, 3)}>
            <GreenEmoji isActive={false} />
          </div>
          <div onClick={(e) => handleFeedbackPoints(e, 1)}>
            <RedEmoji isActive={false} />
          </div>
        </>
      );
    } else if (feedbackState.feedbackPoint === 3) {
      return (
        <>
          <div onClick={(e) => handleFeedbackPoints(e, 3)}>
            <GreenEmoji isActive={true} />
          </div>
          <div onClick={(e) => handleFeedbackPoints(e, 2)}>
            <YellowEmoji isActive={false} />
          </div>
          <div onClick={(e) => handleFeedbackPoints(e, 1)}>
            <RedEmoji isActive={false} />
          </div>
        </>
      );
    }
  };

  return (
    <>
      {!feedbackState.isLoading ? (
        <div>
          <div className="g2submitreview-maindiv">
            {feedbackState.showMobSingleGreenSvg && (
              <div
                className="mobileg2appearscreen"
                onClick={() => {
                  setFeedbackState({
                    showMobSingleGreenSvg: false,
                  });
                }}
              >
                {handleReturnMobSelectedSvg()}
              </div>
            )}

            {(!feedbackState.showMobSingleGreenSvg &&
              window.matchMedia("(max-width: 1200px)").matches) ||
            window.matchMedia("(min-width: 1200px)").matches ? (
              <>
                <OutsideClickHandler
                  onOutsideClick={(e) => handleMobSvgOutsideClick(e)}
                >
                  <div
                    className="g2submitreview g2submitnotextract g2submitnotextract-head"
                    id="g2submitnotextract-id"
                  >
                    {handleReturnSelectedSvgScenerio()}
                  </div>
                </OutsideClickHandler>
              </>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CustomerFeedback;
