export const FONTS =[
  { name: "Arial", fontFamily: "Arial" },
  { name: "Arial Black", fontFamily: "Arial-Black" },
  { name: "Axiforma-Regular", fontFamily: "axiforma-regular" },
  { name: "Axiforma-Bold",  fontFamily: "axiforma-bold"  },
  { name: "Bainsley Regular", fontFamily: "bainsley-regular" },
  { name: "Bainsley Bold", fontFamily: "bainsley-bold" },
  { name: "Barracuda Regular", fontFamily: "barracuda-regular" },
  { name: "Barracuda Bold", fontFamily: "barracuda-bold" },
  { name: "Betelgeuse Regular", fontFamily: "betelgeuse-regular" },
  { name: "Betelgeuse Bold", fontFamily: "betelgeuse-bold" },
  { name: "Bookman", fontFamily: "Bookman" },
  { name: "Cactron Regular", fontFamily: "cactron-regular" },
  { name: "Cactron Bold", fontFamily: "cactronbold" },
  { name: "Cokanut Regular", fontFamily: "cokanut-regular" },
  { name: "Cokanut Bold", fontFamily: "cokanut-bold" },
  { name: "Comic Sans MS", fontFamily: "ComicSansMS" },
  { name: "Cormorant Regular", fontFamily: "cormorant-regular" },
  { name: "Cormorant Bold", fontFamily: "cormorant-bold" },
  { name: "Courier New", fontFamily: "Courier-New" },
  { name: "Dukas Cf Regular", fontFamily: "dukascf-regular" },
  { name: "Dukas Cf Bold", fontFamily: "dukascf-bold" },
  { name: "Emberly Regular", fontFamily: "emberly-regular" },
  { name: "Emberly Bold", fontFamily: "emberly-bold" },
  { name: "Epigrafica Regular", fontFamily: "epigrafica-regular" },
  { name: "Epigrafica Bold", fontFamily: "epigrafica-bold" },
  { name: "Garamond", fontFamily: "Garamond" },
  { name: "Georgia", fontFamily: "Georgia" },
  { name: "Grenze Regular", fontFamily: "grenze-regular" },
  { name: "Grenze Bold", fontFamily: "grenze-bold" },
  { name: "Horizon Regular", fontFamily: "horizon-regular" },
  { name: "Horizon Bold", fontFamily: "horizon-bold" },
  { name: "Impact", fontFamily: "Impact" },
  { name: "Karantina Regular", fontFamily: "karantina-regular" },
  { name: "Karantina Bold", fontFamily: "karantina-bold" },
  { name: "Kuban Pro Regular", fontFamily: "kubanpro-regular" },
  { name: "Kuban Pro Bold", fontFamily: "kubanpro-bold" },
  { name: "Kufam Regular", fontFamily: "kufam-regular" },
  { name: "Kufam Bold", fontFamily: "kufam-bold" },
  { name: "Mango Grotesque Regular", fontFamily: "mangoGrotesque-regular" },
  { name: "Mango Grotesque Bold", fontFamily: "mangoGrotesque-bold" },
  { name: "Meshed Display Regular", fontFamily: "mesheddisplay-regular" },
  { name: "Meshed Display Bold", fontFamily: "mesheddisplay-bold" },
  { name: "Nymeria Regular", fontFamily: "nymeria-regular" },
  { name: "Nymeria Bold", fontFamily: "nymeria-bold" },
  { name: "Palatino", fontFamily: "Palatino" },
  { name: "Piazzolla Regular", fontFamily: "piazzolla-regular" },
  { name: "Piazzolla Bold", fontFamily: "piazzolla-bold" },
  { name: "Poppins SemiBold", fontFamily: "Poppins-SemiBold" },
  { name: "Radio Canada Regular", fontFamily: "radiocanada-regular" },
  { name: "Radio Canada Bold", fontFamily: "radiocanada-bold" },
  { name: "Rebeqa Font Regular", fontFamily: "rebeqa-regular" },
  { name: "Rebeqa Font Bold", fontFamily: "rebeqa-bold" },
  { name: "Rogerex Regular", fontFamily: "rogerex-regular" },
  { name: "Rogerex Bold", fontFamily: "rogerex-bold" },
  { name: "Rozina Regular", fontFamily: "RozinaV04Regular" },
  { name: "Rozina Bold", fontFamily: "RozinaV06Bold" },
  { name: "Sans Serif", fontFamily: "sans-serif" },
  { name: "Times New Roman", fontFamily: "TimesNewRomanPSMT" },
  { name: "Trebuchet MS", fontFamily: "Trebuchet-MS" },
  { name: "Unbounded Polkadot Regular", fontFamily: "unbounded-regular" },
  { name: "Unbounded Polkadot Bold", fontFamily: "unbounded-bold" },
  { name: "Verdana", fontFamily: "Verdana" }
];

