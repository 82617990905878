import React from "react";
import Header from "../Header";
import Leftpanel from "../Leftpanel";
import moment from "moment";
import { Savechatdata, ChatDataget } from "../../Api/Chat/Chatapi";
import BottomScrollListener from "react-bottom-scroll-listener";
import { FormGroup, Label, Input,Button } from "reactstrap";
import CodeMirror from "@uiw/react-codemirror";
import RebuildRequired from "../Appearance/RebuildRequired";
import "./Newchat.css";
import imagebackground from "./../../assets/images/dashboard/cms/uploadbackground.png";
import defaultIcon from "../../assets/images/dashboard/chaticon.png";
import Reactcolorpicker from "../Appearance/NewReactcolorpicker";
import Accordion from 'react-bootstrap/Accordion';
import { Color, Solver } from "../BottomBar/Iconcolor";
import SettingIcon from "../../Components/commonIcons/SettingIcon";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import {
  GetNewmenulist,
  Bottommenuget,
  uploadAnyBase64Imagetos3,
  GetFreeIkNewmenulist,
} from "../../Api/Bottommenu/BottomBarapi";
import { errortoast } from "../Toaster";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import IconSelectionPopUp from "../Reusable/IconSelectionPopUp";
import SkipBtn from "../../Components/commonIcons/SkipBtn";
import { previewContext } from "../../App";
import PremiumTag from "../../Components/commonIcons/PremiumTag";
const getBase64FromUrl = async (useUrl) => {
  try {
    const data = await fetch(useUrl);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => 
      {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  } catch 
  {
    return "";
  }
};
export default class Newchat extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errormodal:false,
      bodyspinner: true,
      submitval: "Save",
      loader: "d-none",
      loaderimage: require("../../assets/images/signup/Loader_GIF.gif").default,
      zendesk_app_id: "",
      zendesk_status: false,
      zendesk_client_id: "",
      zendesk_accountkey: "",
      livechat_licence_id: "",
      livechat_group_id: "",
      livechat_status: false,
      tawk_url: "",
      tawk_status: false,
      custom_url: "",
      custom_status: false,
      hubspot_url: "",
      hubspot_status: false,
      chatgpt_url: "",
      chatgpt_status: false,
      script_status: false,
      module_script: "",
      intercom_status: false,
      android_app_id: "",
      android_api_key: "",
      ios_app_id: "",
      ios_api_key: "",
      show_chat_on_all_screen: false,
      is_ios_rebuild_required: 0,
      build_status: 0,
      enablesetting:false,
      code: 203,
      hit: false,
      plan_code: "preview",
      build_process_status: false,
      showMobilePreview:false,
      android_build_version:null,
      android_supported_version:null,
      ios_build_version:null,
      ios_supported_version:null,
      rebuildpopvalue:false,
      last_updated_timestamp:'',
      appuser_role_id:localStorage.getItem('appuser_role_id'),
      addon_code:"",
      reseller_package_id:"",
      show_floating_chat_icon:false,
      show_chat_link_in_profile:false,
      show_chat_link_in_settings:false,
      nextpageicon_spinner: false,
      selecticoncolorfilter: 1,
      selecticonshapefilter: 1,
      searchicon: "",
      iconlist: [],
      show_image:1,
      addicons: false,
      selectedindexforicon: "",
      searchicon:"",
      selectediconitem:"",
      selectediconcolor:"",
      floating_chat_icon_name:"chat_bubble.png",
      floating_chat_icon_type:"",
      floating_chat_icon_bg_color:"",
      floating_chat_icon_color:"",
      chat_link_text_in_profile:"Chat With Us",
      chat_link_icon_name_in_profile:"chat_bubble.png",
      chat_link_icon_type_in_profile:"",
      chat_link_text_in_settings:"",
      icon_scroll_count: 100000,
      hidecontrol: true,
      presetColors: null,
      selectfreeikiconcolorfilter:"",
      selectfreeikiconshapefilter:"",
      isDoneBtnDisabled:false,
      openIconPopUp:false,
      chaticoncolor:"rgba(255,255,255,1)",
      uploadIconFrom:"",
      chaticonfilter:
      "invert(100%) sepia(100%) saturate(0%) hue-rotate(358deg) brightness(102%) contrast(101%)",
      chatbackgroundColor: "rgba(26, 26, 26, 0.8)",
      appRightPanelExpand: localStorage.getItem("appSideBarCollapse") === true ||
      localStorage.getItem("appSideBarCollapse") === "true"
        ? true
        : false,
        skipButtonActive:false,
        appPlan:localStorage.getItem("app_plan"),
    };
  }
  

  componentDidMount() {
    window.scrollTo(0, 0);
    if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
        {
          document.title = "Chat | AppMySite";

        }
        else
        {
          document.title = "Chat |"+ ' ' +localStorage.getItem('agencyname');
        }
    Bottommenuget(this,this.initialLoad);
    ChatDataget(this);
  }

  initialLoad = (from, dragDstIndx, val) => 
  {
    let previewbottom = [];
    let newArr = this.state.bottom_menu_items;

    for (let i = 0; i < newArr.length; i++) 
    {
      if(newArr[i].status == 1)
      {
        previewbottom.push(newArr[i]);
      }
    }

    if(previewbottom.length  == 5)
    {
      this.setState({setvalue:5,showexpand:false,pewview_bottom_item:previewbottom});
    }
    else if(previewbottom.length  > 5)
    {
      this.setState({setvalue:4,showexpand:true,pewview_bottom_item:previewbottom});
    }
    else
    {
      this.setState({setvalue:previewbottom.length,pewview_bottom_item:previewbottom});
    }

      this.handleinputcolor();
      this.handleiconcolorinput();
  };
  handleforminput = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      hit: true,
    });
  };
  handlecodeforminput = (e) => {
    this.setState({
      module_script: e,
      hit: true,
    });
  };
  handleformstatus = (e) => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked,
      hit: true,
    });
    if (name !== "show_chat_on_all_screen") {
      if (name !== "zendesk_status") this.setState({ zendesk_status: 0 });
      if (name !== "livechat_status") this.setState({ livechat_status: 0 });
      if (name !== "intercom_status") this.setState({ intercom_status: 0 });
      if (name !== "tawk_status") this.setState({ tawk_status: 0 });
      if (name !== "custom_status") this.setState({ custom_status: 0 });
      if (name !== "script_status") this.setState({ script_status: 0 });
      if (name !== "hubspot_status") this.setState({ hubspot_status: 0 });
      if (name !== "chatgpt_status") this.setState({ chatgpt_status: 0 });
    }
  };

  handlesettingformstatus = (e) => 
  {
    const { name, checked } = e.target;


    if(name == 'show_floating_chat_icon' && checked === true)
    {
      this.setState({show_image:1});
    }

    if(name == 'show_chat_link_in_profile' && checked === true)
    {
      this.setState({show_image:2});
    }

    if(name == 'show_chat_link_in_settings' && checked === true)
    {
      this.setState({show_image:3});
    }

    this.setState({
      [name]: checked,
      hit: true,
    });
  }

  closeRequireBuildPopup = (e) =>
  {

    this.setState({rebuildpopvalue:!this.state.rebuildpopvalue});
  }
  handlesettingbutton = () => {
    this.setState({enablesetting:!this.state.enablesetting});
    }

  checkVersion(a, b) {
    const x = a.split('.').map(e => parseInt(e, 10));
    const y = b.split('.').map(e => parseInt(e, 10));

    var a1 = '';
    var b1 = '';
    for (const i in x) 
    {
        if(i == 0)
        {
          a1 = x[i] ;
          b1 = y[i] ;
        }
        else
        {
          a1 = a1 + '.'+ x[i] ;
          b1 = b1 + '.'+ y[i] ;
        }

    }
    return a1 < b1 ? 1 : 0;
}

handleRowStatus = (indx) =>
{
  this.setState({show_image:indx});
}

submitfunction = (e) =>
{

  e.preventDefault();
    // if (this.state.hit === true) {
      if(this.state.zendesk_status === true)
      {
        if(this.state.zendesk_accountkey.trim() == '' || this.state.zendesk_accountkey == '' || this.state.zendesk_accountkey ==  null || this.state.zendesk_accountkey ==  undefined)
        {
  
          errortoast("Account key is a required field.");
          return;
        }
      }
      if(this.state.livechat_status === true)
      {
        if(this.state.livechat_licence_id.trim() == '' || this.state.livechat_licence_id == '' || this.state.livechat_licence_id ==  null || this.state.livechat_licence_id ==  undefined)
        {
  
          errortoast("License ID is a required field.");
          return;
  
        }
      }
      if(this.state.intercom_status === true && this.state.ios_app_id.trim() == '')
      {
        if(this.state.android_app_id.trim() == '' || this.state.android_app_id == '' || this.state.android_app_id ==  null || this.state.android_app_id ==  undefined)
        {
  
          errortoast("Android app ID is a required field.");
          return;
  
        }
        
        else{
          
          
          if(this.state.android_api_key.trim() == '' || this.state.android_api_key == '' || this.state.android_api_key ==  null || this.state.android_api_key ==  undefined)
          {
    
            errortoast("Android API key is a required field.");
            return;
    
           
        }
        }
      }
  
      if(this.state.intercom_status === true && this.state.android_app_id.trim() == '')
      {
        if(this.state.ios_app_id.trim() == '' || this.state.ios_app_id == '' || this.state.ios_app_id ==  null || this.state.ios_app_id ==  undefined)
        {
  
          errortoast("iOS app ID is a required field.");
          return;
  
        }else{
          if(this.state.ios_api_key.trim() == '' || this.state.ios_api_key == '' || this.state.ios_api_key ==  null || this.state.ios_api_key ==  undefined)
          {
    
            errortoast("iOS API key is a required field.");
            return;
    
           
        }
  
      }
    }
    if(this.state.intercom_status === true && this.state.android_app_id.trim() != '' && this.state.ios_app_id.trim() != '')
      {
        if( (this.state.android_api_key.trim() != '' && this.state.android_api_key != '' && this.state.android_api_key !=  null && this.state.android_api_key !=  undefined )
        || ( this.state.ios_api_key.trim() != '' && this.state.ios_api_key != '' && this.state.ios_api_key !=  null && this.state.ios_api_key !=  undefined )
        )
        {
         
      }else{
        errortoast("Either Android API key or iOS API key is a required field.");
        return;
      }
  
      }
  
      if(this.state.tawk_status === true)
      {
        if(this.state.tawk_url.trim() == '' || this.state.tawk_url == '' || this.state.tawk_url ==  null || this.state.tawk_url ==  undefined)
        {
  
          errortoast("URL is a required field.");
          return;
  
        }
      }
  
      if(this.state.custom_status === true)
      {
        if(this.state.custom_url.trim() == '' || this.state.custom_url == '' || this.state.custom_url ==  null || this.state.custom_url ==  undefined)
        {
  
          errortoast("URL is a required field.");
          return;
  
        }
      }
  
      if(this.state.script_status === true)
      {
        if(this.state.module_script.trim() == '' || this.state.module_script == '' || this.state.module_script ==  null || this.state.module_script ==  undefined)
        {
  
          errortoast("Script is a required field.");
          return;
  
        }
      }
  
      if(this.state.chatgpt_status === true)
      {
        if(this.state.chatgpt_url.trim() == '' || this.state.chatgpt_url == '' || this.state.chatgpt_url ==  null || this.state.chatgpt_url ==  undefined)
        {
          errortoast("URL is a required field.");
          
          return;
  
        }
      }
  
      if(this.state.hubspot_status === true)
      {
        if(this.state.hubspot_url.trim() == '' || this.state.hubspot_url == '' || this.state.hubspot_url ==  null || this.state.hubspot_url ==  undefined)
        {
  
          errortoast("URL is a required field.");
          return;
  
        }
      }

      const params = {
        app_id: parseInt(localStorage.getItem("Appid")),
        is_android_rebuild_required:
          this.state.build_status.android_success_build_count > 0 ? 1 : 0,
        is_ios_rebuild_required: this.state.is_ios_rebuild_required,
        data: [
          {
            module: "zendesk",
            module_status: this.state.zendesk_status === true ? 1 : 0,
            module_data: {
              module_account_key: this.state.zendesk_accountkey.trim(),
            },
          },
          {
            module: "livechat",
            module_status: this.state.livechat_status === true ? 1 : 0,
            module_data: {
              module_licence_id: this.state.livechat_licence_id.trim(),
              module_group_id: this.state.livechat_group_id.trim(),
            },
          },
          {
            module: "intercom",
            module_status: this.state.intercom_status === true ? 1 : 0,
            module_data: {
              android_app_id: this.state.android_app_id.trim(),
              android_api_key: this.state.android_api_key.trim(),
              ios_app_id: this.state.ios_app_id.trim(),
              ios_api_key: this.state.ios_api_key.trim(),
              show_chat_on_all_screen:
                this.state.show_chat_on_all_screen === true ? 1 : 0,
            },
          },
          {
            module: "tawkto",
            module_status: this.state.tawk_status === true ? 1 : 0,
            module_data: {
              module_url: this.state.tawk_url,
            },
          },
          {
            module: "custom_url",
            module_status: this.state.custom_status === true ? 1 : 0,
            module_data: {
              module_url: this.state.custom_url.trim(),
            },
          },
          {
            module: "custom_script",
            module_status: this.state.script_status === true ? 1 : 0,
            module_data: {
              module_script: this.state.module_script.trim(),
            },
          },
          {
            module: "chatgpt_url",
            module_status: this.state.chatgpt_status === true ? 1 : 0,
            module_data: {
              module_url: this.state.chatgpt_url.trim(),
            },
          },
          {
            module: "hubspot_url",
            module_status: this.state.hubspot_status === true ? 1 : 0,
            module_data: {
              module_url: this.state.hubspot_url.trim(),
            },
          },
        ],
        show_floating_chat_icon:this.state.show_floating_chat_icon || this.state.show_floating_chat_icon == 1 ? 1 : 0,
        floating_chat_icon_name:this.state.floating_chat_icon_name,
        floating_chat_icon_bg_color:this.state.chatbackgroundColor,
        floating_chat_icon_color:this.state.chaticoncolor,
        show_chat_link_in_profile:this.state.show_chat_link_in_profile || this.state.show_chat_link_in_profile == 1 ? 1 : 0,
        chat_link_text_in_profile:this.state.chat_link_text_in_profile,
        chat_link_icon_name_in_profile:this.state.chat_link_icon_name_in_profile,
        show_chat_link_in_settings:this.state.show_chat_link_in_settings || this.state.show_chat_link_in_settings == 1 ? 1 : 0,
        chat_link_text_in_settings:this.state.chat_link_text_in_settings,
        floating_chat_icon_type:this.state.floating_chat_icon_type,
        chat_link_icon_type_in_profile:this.state.chat_link_icon_type_in_profile,
      };
      Savechatdata(params, this);

}

handleItemicon = (indx) =>
  {
    
    if(indx == 'floatingchat')
    {
      this.setState({floating_chat_icon_name:"",floating_chat_icon_type:"",addicons: false,searchicon:"",selectediconitemclick:false});
    }
    else if(indx == 'menuicon')
    {
      this.setState({chat_link_icon_name_in_profile:"",chat_link_icon_type_in_profile:"",addicons: false,searchicon:"",selectediconitemclick:false});
    }
 

  }

hexToRgb = (hex) => {
  if (hex !== undefined) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? [
          parseInt(result[1], 16),
          parseInt(result[2], 16),
          parseInt(result[3], 16),
        ]
      : null;
  }
};

handleiconcolorinput = () =>
{
    let assumeinputvalue = 0;
    var newbottom_menu_items = this.state.bottom_menu_items;
    for (let ab = 0; ab < this.state.bottom_menu_items.length; ab++) 
    {

      if(newbottom_menu_items[ab] && newbottom_menu_items[ab].item_icon_flaticon_color == 'black')
      {
        assumeinputvalue =  (parseInt(assumeinputvalue) + 1);
      }
    }

    this.setState({
      setinputcolor:assumeinputvalue,
      addicons: false,
      addicons1: false,
      searchicon: "",
    });
}

handleinputcolor = () =>
  {
      //Change icon color
      var rgb = this.hexToRgb(this.state.iconcolor);

      if (rgb !== undefined && rgb === null && this.state.iconcolor != "") 
      {
        const colorvalue = this.state.iconcolor.match(/\d+/g).map(Number);
        rgb = [];
        rgb[0] = colorvalue[0];
        rgb[1] = colorvalue[1];
        rgb[2] = colorvalue[2];
      } 
      else if 
      (
        rgb !== undefined &&
        this.state.iconcolor != "" &&
        rgb.length !== 3
      ) 
      {
        return;
      }

      if (this.state.iconcolor !== undefined && this.state.iconcolor != "") 
      {
        const color = new Color(rgb[0], rgb[1], rgb[2]);
        const solver = new Solver(color);
        const result = solver.solve();

        this.setState({
          iconfilter: result.filter,
        });
      }

  }

  handlechatinputcolor = (color) =>
  {
      //Change icon color
      var rgb = this.hexToRgb(color);

      if (rgb !== undefined && rgb === null && color != "") 
      {
        const colorvalue = color.match(/\d+/g).map(Number);
        rgb = [];
        rgb[0] = colorvalue[0];
        rgb[1] = colorvalue[1];
        rgb[2] = colorvalue[2];
      } 
      else if 
      (
        rgb !== undefined &&
        color != "" &&
        rgb.length !== 3
      ) 
      {
        return;
      }

      if (color) 
      {
        const color = new Color(rgb[0], rgb[1], rgb[2]);
        const solver = new Solver(color);
        const result = solver.solve();

        this.setState({
          chaticonfilter: result.filter,
        });
      }

  }

  handleSearchSubmit = (e) => {
    e.preventDefault();
    this.setState({
      page: 1,
      iconlist:[],
    });

    GetFreeIkNewmenulist(
      this,
      this.state.selectfreeikiconshapefilter,
      this.state.selectfreeikiconcolorfilter,
      this.state.searchicon,
      "1"
    );

    // GetNewmenulist(
    //   this,
    //   this.state.selecticonshapefilter,
    //   this.state.selecticoncolorfilter,
    //   this.state.searchicon,
    //   "1"
    // );
  };


  handlecolorfilter = (e) => 
    {
  
      if(e.target.value == 1)
      {
        var colorfreeik = ''; 
      }
      if(e.target.value == 2)
      {
        var colorfreeik = 'black'; 
      }
      if(e.target.value == 3)
      {
        var colorfreeik = 'multicolor'; 
      }
      if(e.target.value == 4)
      {
        var colorfreeik = 'gradient'; 
      }
  
      this.setState({
        selecticoncolorfilter: e.target.value,
        selectfreeikiconcolorfilter: colorfreeik,
        page: 1,
        nextpageicon_spinner:false,
        iconlist:[],
      });
  
      GetFreeIkNewmenulist(
        this,
        this.state.selectfreeikiconshapefilter,
        colorfreeik,
        this.state.searchicon,
        "1"
      );
  
      // GetNewmenulist(
      //   this,
      //   this.state.selecticonshapefilter,
      //   e.target.value,
      //   this.state.searchicon,
      //   "1"
      // );
    };

    handleshapefilter = (e) => {
  

      if(e.target.value == 1)
        {
          var shapefreeik = ''; 
        }
        if(e.target.value == 2)
        {
          var shapefreeik = 'outline'; 
        }
        if(e.target.value == 3)
        {
          var shapefreeik = 'fill'; 
        }
        if(e.target.value == 4)
        {
          var shapefreeik = 'lineal-color'; 
        }
        if(e.target.value == 5)
          {
            var shapefreeik = 'hand-drawn'; 
          }
  
          this.setState({
            selecticonshapefilter: e.target.value,
            selectfreeikiconshapefilter : shapefreeik,
            page: 1,
            nextpageicon_spinner:false,
            iconlist:[],
          });
  
      GetFreeIkNewmenulist(
        this,
        shapefreeik,
        this.state.selectfreeikiconcolorfilter,
        this.state.searchicon,
        "1"
      );
  
      // GetNewmenulist(
      //   this,
      //   e.target.value,
      //   this.state.selecticoncolorfilter,
      //   this.state.searchicon,
      //   "1"
      // );
    };

  //Get icon name
  handleiconname = (e) => 
  {
    this.setState({
      selectediconitem: e.target.value,
      selectedflaticoncolor: e.target.getAttribute("iconcolor"),
    });
  };

  paneDidMount = (node) => {
    if (node) {
      node.addEventListener("scroll", this.handleScroll.bind(this));
    }
  };

handleContainerOnBottom = () => 
  {
    if (this.state.icon_scroll_count < this.state.iconlist.length) 
    {
      this.setState({
        icon_scroll_count: this.state.icon_scroll_count + 30,
      });
    }
  };

  handleScroll = (event) => {
    var node = event.target;
   

    const bottom = node.scrollHeight - node.scrollTop < node.clientHeight + 180;
    if (bottom && this.state.setIsPushed) 
    {
      this.setState({ page: this.state.page + 1, nextpageicon_spinner: true });
      this.setState({setIsPushed:false});

      setTimeout(GetFreeIkNewmenulist(
      this,
      this.state.selectfreeikiconshapefilter,
      this.state.selectfreeikiconcolorfilter,
      this.state.searchicon,
      this.state.page
      ),100);

    //  setTimeout(GetNewmenulist(
    //   this,
    //   this.state.selecticonshapefilter,
    //   this.state.selecticoncolorfilter,
    //   this.state.searchicon,
    //   this.state.page
    // ), 100);
    }
  };

handleIconPopUp = (e,from) =>{
    this.setState({
      openIconPopUp:!this.state.openIconPopUp,
      isDoneBtnDisabled:false,
      uploadIconFrom:from,
    })
}

saveIconName = (name,selectedflaticoncolor) =>{
  this.setState({
    openIconPopUp:!this.state.openIconPopUp,
    isDoneBtnDisabled:false,
  })

  if(this.state.uploadIconFrom==="floatingchat"){
    this.setState({
      floating_chat_icon_name:name,
      floating_chat_icon_type:selectedflaticoncolor,
    })
  }else if(this.state.uploadIconFrom==="menuicon"){
    this.setState({
      chat_link_icon_name_in_profile:name,
    })
  }
}


handleCancelIcon =() =>{
  this.setState({
    floating_chat_icon_name:"",
  })
}


  //Open Icon popup
  changeIcon = (i) => 
    {
      this.setState({selecticonshapefilter:"1",selecticoncolorfilter:"1",selectfreeikiconcolorfilter:'',selectfreeikiconshapefilter:''});
  
      // GetNewmenulist(this, "1", "1", "shape", "1");
      GetFreeIkNewmenulist(this, "", "", "solid-black", "1");
      
      if(i == 'menu')
      {
        this.setState(
          {
            addicons: !this.state.addicons,
            selectedindexforicon: i,
            selectediconitem: "",
            selectedflaticoncolor: "",
          },
          () => 
          {
            if (this.state.addicons === false) 
            {
              this.setState({
                iconitem: "",
                selectediconitem: "",
                selectedflaticoncolor: "",
                item_icon_selected_newtype:'',
                icon_selected_type: "",
                icon_type: "",
                searchicon: "",
              });
            }
          }
        );
      }
      else if (i !== undefined) 
      {
        this.setState(
          {
            addicons: !this.state.addicons,
            selectedindexforicon: i,
            selectediconitem: "",
            selectedflaticoncolor: "",
          },
          () => {
            if (this.state.addicons === false) {
              this.setState({
                iconitem: "",
                selectediconitem: "",
                selectedflaticoncolor: "",
                item_icon_selected_newtype:'',
                icon_selected_type: "",
                icon_type: "",
                searchicon: "",
              });
            }
          }
        );
      } 
      else 
      {
        this.setState(
          {
            addicons: !this.state.addicons,
            selectedindexforicon: "",
            selectediconitem: "",
            selectedflaticoncolor: "",
          },
          () => {
            if (this.state.addicons === false) {
              this.setState({
                iconitem: "",
                selectediconitem: "",
                selectedflaticoncolor: "",
                item_icon_selected_newtype:'',
                icon_selected_type: "",
                icon_type: "",
                searchicon: "",
              });
            }
          }
        );
      }
    };

  callbackiconshow = (iconsavefilename,selectedflaticoncolor) =>
  {
    if(this.state.selectedindexforicon == 'floatingchat' && this.state.selectediconitem !== "")
    {
      this.setState({floating_chat_icon_name:iconsavefilename,floating_chat_icon_type:selectedflaticoncolor,addicons: false,searchicon:"",selectediconitemclick:false});
    }
    else if(this.state.selectedindexforicon == 'menuicon' && this.state.selectediconitem !== "")
    {
      this.setState({chat_link_icon_name_in_profile:iconsavefilename,chat_link_icon_type_in_profile:selectedflaticoncolor,addicons: false,searchicon:"",selectediconitemclick:false});
    }
    else 
    {
          this.setState({
            addicons: false,
            searchicon: "",
            selectediconitemclick:false
          });
    }
  }

  handleImgUrl = (url,iconsavefilename,callbackfunction,selectedflaticoncolor) => 
{
  let img = new Image();
  img.src = url;

  img.onload = async function () 
  {
      let base64String = await getBase64FromUrl(url);
      const params = {
        data: base64String,
        filetype: "png",

        path:  localStorage.getItem("user_id") +"/"+localStorage.getItem("Appid")+process.env.REACT_APP_NEW_ICON_PATH,
      };
      uploadAnyBase64Imagetos3(params,iconsavefilename,callbackfunction,selectedflaticoncolor);   
  } 
  img.onerror = function () 
  {
  };
  
};

    //Add icon from icon popup
    handleaddicon = () => 
    {
        this.setState({selectediconitemclick:true});
        const iconsavefilename =  "ams_chaticon_" +new Date().getTime() +"_" +Math.floor(1000 + Math.random() * 9000);
        
        this.handleImgUrl(this.state.selectediconitem,iconsavefilename,this.callbackiconshow,this.state.selectedflaticoncolor);
      
    };

  formsubmit = (e,cb) => 
  
  {
    e.preventDefault();
    if(this.state.code === 203){
      cb(e)
      return;
    }
  
    if(this.state.zendesk_status === true)
    {
      if(this.state.zendesk_accountkey.trim() == '' || this.state.zendesk_accountkey == '' || this.state.zendesk_accountkey ==  null || this.state.zendesk_accountkey ==  undefined)
      {

        errortoast("Account key is a required field.");
        return;
      }
    }
    if(this.state.livechat_status === true)
    {
      if(this.state.livechat_licence_id.trim() == '' || this.state.livechat_licence_id == '' || this.state.livechat_licence_id ==  null || this.state.livechat_licence_id ==  undefined)
      {

        errortoast("License ID is a required field.");
        return;

      }
    }
    if(this.state.intercom_status === true && this.state.ios_app_id.trim() == '')
    {
      if(this.state.android_app_id.trim() == '' || this.state.android_app_id == '' || this.state.android_app_id ==  null || this.state.android_app_id ==  undefined)
      {

        errortoast("Android app ID is a required field.");
        return;

      }
      
      else{
        
        
        if(this.state.android_api_key.trim() == '' || this.state.android_api_key == '' || this.state.android_api_key ==  null || this.state.android_api_key ==  undefined)
        {
  
          errortoast("Android API key is a required field.");
          return;
  
         
      }
      }
    }

    if(this.state.intercom_status === true && this.state.android_app_id.trim() == '')
    {
      if(this.state.ios_app_id.trim() == '' || this.state.ios_app_id == '' || this.state.ios_app_id ==  null || this.state.ios_app_id ==  undefined)
      {

        errortoast("iOS app ID is a required field.");
        return;

      }else{
        if(this.state.ios_api_key.trim() == '' || this.state.ios_api_key == '' || this.state.ios_api_key ==  null || this.state.ios_api_key ==  undefined)
        {
  
          errortoast("iOS API key is a required field.");
          return;
  
         
      }

    }
  }
  if(this.state.intercom_status === true && this.state.android_app_id.trim() != '' && this.state.ios_app_id.trim() != '')
    {
      if( (this.state.android_api_key.trim() != '' && this.state.android_api_key != '' && this.state.android_api_key !=  null && this.state.android_api_key !=  undefined )
      || ( this.state.ios_api_key.trim() != '' && this.state.ios_api_key != '' && this.state.ios_api_key !=  null && this.state.ios_api_key !=  undefined )
      )
      {
       
    }else{
      errortoast("Either Android API key or iOS API key is a required field.");
      return;
    }

    }

    if(this.state.tawk_status === true)
    {
      if(this.state.tawk_url.trim() == '' || this.state.tawk_url == '' || this.state.tawk_url ==  null || this.state.tawk_url ==  undefined)
      {

        errortoast("URL is a required field.");
        return;

      }
    }

    if(this.state.custom_status === true)
    {
      if(this.state.custom_url.trim() == '' || this.state.custom_url == '' || this.state.custom_url ==  null || this.state.custom_url ==  undefined)
      {

        errortoast("URL is a required field.");
        return;

      }
    }

    if(this.state.script_status === true)
    {
      if(this.state.module_script.trim() == '' || this.state.module_script == '' || this.state.module_script ==  null || this.state.module_script ==  undefined)
      {

        errortoast("Script is a required field.");
        return;

      }
    }

    if(this.state.chatgpt_status === true)
    {
      if(this.state.chatgpt_url.trim() == '' || this.state.chatgpt_url == '' || this.state.chatgpt_url ==  null || this.state.chatgpt_url ==  undefined)
      {
        errortoast("URL is a required field.");
        
        return;

      }
    }

    if(this.state.hubspot_status === true)
    {
      if(this.state.hubspot_url.trim() == '' || this.state.hubspot_url == '' || this.state.hubspot_url ==  null || this.state.hubspot_url ==  undefined)
      {

        errortoast("URL is a required field.");
        return;

      }
    }
    if(this.state.build_process_status)
    {
      errortoast("Wait,build in processing");   
      return;
    }

    this.setState({loader:"new-loader"});
    
    if(this.state.android_build_version != null || this.state.ios_build_version != null)
    {

      if(this.state.android_build_version != null &&  this.state.android_supported_version != null)
      {
        
        if(this.checkVersion(this.state.android_build_version,this.state.android_supported_version))
        {
          this.setState({rebuildpopvalue:!this.state.rebuildpopvalue});
          return ;
        }
      }
      else if(this.state.ios_build_version != null &&  this.state.ios_supported_version != null)
      {

        if(this.checkVersion(this.state.ios_build_version,this.state.ios_supported_version))
        {
          this.setState({rebuildpopvalue:!this.state.rebuildpopvalue});
          return ;
        }

      }

    }

    // if (this.state.hit === true) {
      const params = {
        app_id: parseInt(localStorage.getItem("Appid")),
        is_android_rebuild_required:
          this.state.build_status.android_success_build_count > 0 ? 1 : 0,
        is_ios_rebuild_required: this.state.is_ios_rebuild_required,
        data: [
          {
            module: "zendesk",
            module_status: this.state.zendesk_status === true ? 1 : 0,
            module_data: {
              module_account_key: this.state.zendesk_accountkey.trim(),
            },
          },
          {
            module: "livechat",
            module_status: this.state.livechat_status === true ? 1 : 0,
            module_data: {
              module_licence_id: this.state.livechat_licence_id.trim(),
              module_group_id: this.state.livechat_group_id.trim(),
            },
          },
          {
            module: "intercom",
            module_status: this.state.intercom_status === true ? 1 : 0,
            module_data: {
              android_app_id: this.state.android_app_id.trim(),
              android_api_key: this.state.android_api_key.trim(),
              ios_app_id: this.state.ios_app_id.trim(),
              ios_api_key: this.state.ios_api_key.trim(),
              show_chat_on_all_screen:
                this.state.show_chat_on_all_screen === true ? 1 : 0,
            },
          },
          {
            module: "tawkto",
            module_status: this.state.tawk_status === true ? 1 : 0,
            module_data: {
              module_url: this.state.tawk_url,
            },
          },
          {
            module: "custom_url",
            module_status: this.state.custom_status === true ? 1 : 0,
            module_data: {
              module_url: this.state.custom_url.trim(),
            },
          },
          {
            module: "custom_script",
            module_status: this.state.script_status === true ? 1 : 0,
            module_data: {
              module_script: this.state.module_script.trim(),
            },
          },
          {
            module: "chatgpt_url",
            module_status: this.state.chatgpt_status === true ? 1 : 0,
            module_data: {
              module_url: this.state.chatgpt_url && this.state.chatgpt_url != '' ? this.state.chatgpt_url.trim() : '',
            },
          },
          {
            module: "hubspot_url",
            module_status: this.state.hubspot_status === true ? 1 : 0,
            module_data: {
              module_url: this.state.hubspot_url && this.state.hubspot_url != '' ? this.state.hubspot_url.trim() : '',
            },
          },
              ],
        show_floating_chat_icon:this.state.show_floating_chat_icon || this.state.show_floating_chat_icon == 1 ? 1 : 0,
        floating_chat_icon_name:this.state.floating_chat_icon_name==="chat_bubble.png"?"":this.state.floating_chat_icon_name,
        floating_chat_icon_bg_color:this.state.chatbackgroundColor,
        floating_chat_icon_color:this.state.chaticoncolor,
        show_chat_link_in_profile:this.state.show_chat_link_in_profile || this.state.show_chat_link_in_profile == 1 ? 1 : 0,
        chat_link_text_in_profile:this.state.chat_link_text_in_profile==="Chat"?"":this.state.chat_link_text_in_profile,
        chat_link_icon_name_in_profile:this.state.chat_link_icon_name_in_profile==="chat_bubble.png"?"":this.state.chat_link_icon_name_in_profile,
        show_chat_link_in_settings:this.state.show_chat_link_in_settings || this.state.show_chat_link_in_settings == 1 ? 1 : 0,
        chat_link_text_in_settings:this.state.chat_link_text_in_settings==="Chat With Us"?"":this.state.chat_link_text_in_settings,
        floating_chat_icon_type:this.state.floating_chat_icon_type,
        chat_link_icon_type_in_profile:this.state.chat_link_icon_type_in_profile,
      };

      Savechatdata(params, this);
    // }
  };

  //  zendesk_status: 0,
  //  livechat_status: 0,
  //  tawk_status: 0,
  //  custom_status: 0,
  //  script_status: 0,
  // intercom_status: 0,
  handlePreviewImage = () => {
    let imgName = "No-chat";
    if (this.state.zendesk_status === 1 || this.state.zendesk_status === true) 
    {
      imgName = "Zendesk";
    } 
    else if (this.state.livechat_status === 1 || this.state.livechat_status === true) 
    {
      imgName = "LiveChat";
    } 
    else if (this.state.tawk_status === 1 || this.state.tawk_status === true) 
    {
      imgName = "Tawk";
    } 
    else if (this.state.custom_status === 1 || this.state.custom_status === true) 
    {
      imgName = "Customurl";
    }
    else if (this.state.script_status === 1 || this.state.script_status === true) 
    {
      imgName = "Customurl";
    } 
    else if (this.state.intercom_status === 1 || this.state.intercom_status === true) 
    {
      imgName = "Intercom";
    }
    else if (this.state.chatgpt_status === 1 || this.state.chatgpt_status === true) 
    {
      imgName = "chatgpt-preview";
    }
    else if (this.state.hubspot_status === 1 || this.state.hubspot_status === true) 
    {
      imgName = "hubspot-preview";
    }
    return imgName;
  };

   handleMobileShowHidePreview=()=>{
   this.setState({
   showMobilePreview:!this.state.showMobilePreview
  })
  }

  searchicon = (e) => {
    this.setState({
      searchicon: e.target.value,
    });
  };

  handleRightPanelExpand = (value) => {

    this.setState({
      appRightPanelExpand: value
    })
    }
  
    changeiconcolor = (color) => 
    {
      this.setState({chaticoncolor:color}, () => {this.handlechatinputcolor(color);});
    };

    changebackgroundcolor = (color) => 
    {
      this.setState({
        chatbackgroundColor: color,
      });
    };

    errormodal = () => {
      this.setState({
        errormodal: !this.state.errormodal,
      });
    };
    handleSkip = ()=>{
      this.setState({
        skipButtonActive:true,
      });
    }


  render() {
    return (
<previewContext.Consumer> 
  {
    (data)=>(
          <>
           <Modal 
                    isOpen={this.state.errormodal}   
                    style={{ maxWidth: "460px", maxHeight: "240px" }}
                    className="generalstngs2 bottombar-defaultpopup"
                    centered
                    toggle={e => this.errormodal(e)}
                  >
                  <ModalHeader>
                    <div className="crossmodal mt-1" onClick={(e) => this.errormodal(e)}>
                      <img src={require("../../assets/images/dashboard/cross.png").default} />
                    </div>
                  </ModalHeader>
                  <ModalBody>
                  <div>
                      <div>
                          <h4>{this.state.title}</h4>
                          <h6>{this.state.errormessage}</h6>
                        <div className="generalstngs2-footer">
                          <div className="generalstngs2-ok" onClick={e => this.errormodal(e)}>                
                            <p >
                            Okay								
                            </p>
                          </div>
                        </div>
                      </div>
                  </div>
                  </ModalBody>
              </Modal> 
          <div className="">
            <Header customclass={"header-responsive-new"} />
          </div>
          {/* Mobile View */}
         
          {/* Mobile View End*/}
          <section className=" dasboard_page mobile-display mt-0 mobpage_chat">
          <Leftpanel handleRightPanelExpand={this.handleRightPanelExpand} />
            {/* <div className=" ipad-chat-head-view  Chat_mobletop">
              <div
                className="  ipad-chat-headpadding chat_padding" */}
            {/* <div className="transparent_background ipad-chat-head-view Chat_mobletop"> */}
            <div className={`right-panel-gap ${(localStorage.getItem("appSideBarCollapse") !== undefined &&
                        localStorage.getItem("appSideBarCollapse") !== null&&
                        localStorage.getItem("appSideBarCollapse") === "true") ?
                            " right-panel-gap-expand": ""}`}>
  
  
              <div className="chat-head">
                  <h1>Chat <span class="chat-tooltip">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5"><circle cx="9" cy="9" r="9" stroke="none"></circle><circle cx="9" cy="9" r="8.25" fill="none"></circle></g><line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"></line><path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5"></path></g></svg>
                    <span style={{top: "-2px"}} class="chat-tooltipsubheader">Enable in-app chat for direct communication between your app users and chat agents.</span>
                    </span>
                  </h1>
                {this.state.code === 203 && !this.state.skipButtonActive ? 
                  <>
                  {
                        (localStorage.getItem("agencyid") && localStorage.getItem("agencyid") != 1) && this.state.code !== 200 &&
                        window.matchMedia("(min-width: 1200px)").matches && this.state.bodyspinner === false &&
                      <div style={{}} className="upgrade-feature">
                        <h4 className="upgrade-unavailable">This feature is currently unavailable</h4>
                        <p className="upgrade-support">Please contact Support for assistance.</p>
                      </div>
                      }
                  </>
                :
                  <div className="chat-head-right">
                  {/* <p>Updated 22 November 2023, 16:32</p> */}
                  {this.state.last_updated_timestamp && this.state.last_updated_timestamp !== '' && this.state.last_updated_timestamp !== undefined ?
                        <p>Updated  {moment(this.state.last_updated_timestamp).format(
                                  "D MMMM YYYY, HH:mm"
                                )}{" "}</p>
                      : null
                      }
                      {this.state.skipButtonActive && <PremiumTag/> }
  
                      {
                        this.state.enablesetting == false ? 
                      
                  //     <div className="consent-setting-clicked" style={{marginRight: "20px",cursor:"default"}}>
                  //             <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                  //        <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                  //     <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                  //     <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="#7782a1" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                  //   </g>
                  // </svg>
                  //             </div>
                  <></>
                      :
                    <div className="notification-setting " onClick={(e) => this.handlesettingbutton()}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                    <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                      <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                      <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                    </g>
                  </svg>
      
  
                          </div>
                      }
                      
                 
                  <div onClick={(e) => this.handlesettingbutton()}>
                            <SettingIcon
                             
                              isActive={this.state.enablesetting }
                            />
                          </div>
                  <button
                                className={
                                  this.state.build_process_status === true
                                    ? "blockuser next_btn checknew newwidthd chat_next_btn"
                                    : "next_btn checknew chat_next_btn"
                                }
                                style={{backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                                form="formsubmit"
                                // disabled={this.state.skipButtonActive}
                              >
                                <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                  <div className={this.state.loader}>
                                    
                                  </div>{" "}
                                  <div>
                                    {this.state.submitval}
                                  </div>
                                </div>
                              </button>
                  </div>
                }
              </div>
              {
                        (localStorage.getItem("agencyid") && localStorage.getItem("agencyid") != 1) && this.state.code !== 200 &&
                        window.matchMedia("(max-width: 1200px)").matches && this.state.bodyspinner === false &&
                      <div style={{marginLeft: "13px"}} className="upgrade-feature">
                        <h4 style={{textAlign: "unset"}} className="upgrade-unavailable">This feature is currently unavailable</h4>
                        <p style={{textAlign: "unset"}} className="upgrade-support">Please contact Support for assistance.</p>
                      </div>
                      }
              <div className="chat-main-topdiv">
              {this.state.bodyspinner ? (
                    <div className="newspinner">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xlink="http://www.w3.org/1999/xlink"
                        style={{
                          margin: "auto",
                          background: "rgb(241, 242, 243,0)",
                          display: "block",
                          shaperendering: "auto",
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                        width="200px"
                        height="200px"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="xMidYMid"
                      >
                        <g
                          transform="translate(20 50)"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <circle
                            cx="0"
                            cy="0"
                            r="6"
                            fill="#e6261f"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <animateTransform
                              attributeName="transform"
                              type="scale"
                              begin="-0.375s"
                              calcMode="spline"
                              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                              values="0;1;0"
                              keyTimes="0;0.5;1"
                              dur="1s"
                              repeatCount="indefinite"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            ></animateTransform>
                          </circle>
                        </g>
                        <g
                          transform="translate(40 50)"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <circle
                            cx="0"
                            cy="0"
                            r="6"
                            fill="#f7d038"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <animateTransform
                              attributeName="transform"
                              type="scale"
                              begin="-0.25s"
                              calcMode="spline"
                              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                              values="0;1;0"
                              keyTimes="0;0.5;1"
                              dur="1s"
                              repeatCount="indefinite"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            ></animateTransform>
                          </circle>
                        </g>
                        <g
                          transform="translate(60 50)"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <circle
                            cx="0"
                            cy="0"
                            r="6"
                            fill="#49da9a"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <animateTransform
                              attributeName="transform"
                              type="scale"
                              begin="-0.125s"
                              calcMode="spline"
                              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                              values="0;1;0"
                              keyTimes="0;0.5;1"
                              dur="1s"
                              repeatCount="indefinite"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            ></animateTransform>
                          </circle>
                        </g>
                        <g
                          transform="translate(80 50)"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <circle
                            cx="0"
                            cy="0"
                            r="6"
                            fill="#4355db"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <animateTransform
                              attributeName="transform"
                              type="scale"
                              begin="0s"
                              calcMode="spline"
                              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                              values="0;1;0"
                              keyTimes="0;0.5;1"
                              dur="1s"
                              repeatCount="indefinite"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            ></animateTransform>
                          </circle>
                        </g>
                      </svg>
                    </div>
                  ) : 
                  (
                    <>
                      {
                      this.state.code === 203 && !this.state.skipButtonActive ? 
                        (
                          <div className="chatbg-upgrade">
                              <div className="chat-upgrade-page">
                                
                                <div className="chat-upgrade-left">
                                  <h3>Chat with your app users</h3>
                                  <h4>Chat with your audience using your preferred chat software.</h4>
                                  <ul>
                                    <li>Effortless integration with leading chat platforms.</li>
                                    <li>Decide where to showcase Chat within your app.</li>
                                    <li>Respond directly from your existing chat software.</li>
                                    <li>Be available for your customers in real-time.</li>
                                  </ul>
                                  {/* <a href="/app/pricing">Upgrade</a> */}
  
                                  <div className="upgrade-skip-chat">
                                  {atob(localStorage.getItem("plan_code")) ===
                                    process.env.REACT_APP_Lifetime_preview ||
                                  atob(localStorage.getItem("plan_code")).indexOf(
                                    process.env.REACT_APP_Lifetime_plan
                                  ) > -1 || this.state.reseller_package_id ? (
                                    (localStorage.getItem("workspaceId") === "" ||
                                      localStorage.getItem("workspaceId") === undefined ||
                                      localStorage.getItem("workspaceId") === "undefined" ||
                                      localStorage.getItem("workspaceId") === null) && (
                                      ( this.state.appuser_role_id==3 || this.state.appuser_role_id== 4 ||
                                        parseInt(localStorage.getItem("logged_in_user_role_id")) === 3 ||
                                        parseInt(localStorage.getItem("logged_in_user_role_id")) === 4) ?
                                        <button className="disable-role-btn" disabled>Contact your admin</button> :
                                      <Link
                                        to={"/app/addons"}
                                        className="d-block"
                                      >
                                        Buy addon
                                      </Link>
                                    )
                                  )
                                      : 
                                        ( this.state.appuser_role_id==3 || this.state.appuser_role_id== 4 ||
                                          parseInt(localStorage.getItem("logged_in_user_role_id")) === 3 ||
                                          parseInt(localStorage.getItem("logged_in_user_role_id")) === 4) ? 
                                          <button className="disable-role-btn" disabled>Contact your admin</button> 
                                      : (localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || 
                                      localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || 
                                      localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1) ?
                                        <Link
                                        className="d-block"
                                        to={
                                          localStorage.getItem("workspaceId") !== "" &&
                                          localStorage.getItem("workspaceId") !== undefined &&
                                          localStorage.getItem("workspaceId") !== "undefined" &&
                                          localStorage.getItem("workspaceId") !== null
                                            ?`/workspace/pricing/${btoa(localStorage.getItem("workspaceId"))}`
                                            :  parseInt(localStorage.getItem('website_technology')) === 4 
                                            ?  "/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid'))
                                            : parseInt(localStorage.getItem('website_technology')) === 5 
                                            ? "/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'))
                                            : parseInt(localStorage.getItem('website_technology')) === 3 ? 
                                              "/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'))
                                            : parseInt(localStorage.getItem('website_technology')) === 2 ? 
                                              "/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'))
                                          : "/app/pricing"
                                        }
                                      >
                                        Upgrade
                                      </Link>
                                      : null
                                    }
  
                                    <div className="skip-btn-head" onClick={() => this.handleSkip()}>
                                      <SkipBtn/>
                                    </div>
  
                                    </div>
                                </div>
                                
                                <div className="chat-upgrade-right">
                                  <img src={require("../../assets/images/dashboard/chatimg.png").default} alt="chatimg" className="img-fluid"/>
                                </div>
                              </div>
                          </div>
                        ) 
                      : (
                         <>
                         
                          <form onSubmit={(e)=>this.formsubmit(e,data.toggleActivateModal)} id="formsubmit">
  
                            <div className={this.state.enablesetting == true ? 'd-none' : 'chat-main'}>
                            <div className="chatbg">
  
                                <div className="chat-left">
  
                                  <div className="chat-type">
                                    <div className="chat-name-head">
                                      <div className="chat-name-head-right">
                                        <img  src={require("../../assets/images/dashboard/zendesk-icon.png").default} alt="zendesk" className="img-fluid"/>
                                        <h2>Zendesk chat</h2>
                                      </div>
                                      <label className="switch" for="zendesk">
                                        <input type="checkbox" id="zendesk" name="zendesk_status" checked={this.state.zendesk_status} onChange={(e) =>this.handleformstatus(e)}/>
                                        <div className="slider round"></div>
                                      </label>
                                    </div>
                                    {this.state.zendesk_status === true && (
                                      <div className="chat-name-body">
                                        <h4>Account key</h4>
                                        <input type="text" name="zendesk_accountkey" placeholder="sZQbfwqUat6hdyddJyuTh6YTwOrbiogm"  value={this.state.zendesk_accountkey}  onChange={(e) =>
                                            this.handleforminput(e)
                                          }/>
                                      </div>
                                    )}
                                  </div>
  
                                  <div className="chat-type">
  
                                    <div className="chat-name-head">
                                      <div className="chat-name-head-right">
                                        <img  src={require("../../assets/images/dashboard/intercom-icon.png").default} alt="intercom" className="img-fluid"/>
                                        <h2>Intercom</h2>
                                      </div>
                                      <label className="switch" for="intercom">
                                        <input type="checkbox" id="intercom" name="intercom_status"  checked={this.state.intercom_status} onChange={(e) =>
                                            this.handleformstatus(e)
                                          }/>
                                        <div className="slider round"></div>
                                      </label>
                                    </div>
                                    {this.state.intercom_status === true && (
                                      <div className="chat-name-body">
                                        <h4>Android app ID</h4>
                                        <input type="text" placeholder="hzvt6t0z"  name="android_app_id"
                                            value={this.state.android_app_id}
                                            onChange={(e) =>
                                              this.handleforminput(e)
                                            }/>
  
                                        <h4>Android API key</h4>
                                        <input type="text" placeholder="android_sdk-38b483bf8fb2a546d9w9f63d6e8d..." name="android_api_key"
                                            value={this.state.android_api_key}
                                            onChange={(e) =>
                                              this.handleforminput(e)
                                            }/>
  
                                        <h4>iOS app ID</h4>
                                        <input type="text" placeholder="hzvt6t0z" name="ios_app_id"
                                            value={this.state.ios_app_id}
                                            onChange={(e) =>
                                              this.handleforminput(e)
                                            }/>
  
                                        <h4>iOS API key</h4>
                                        <input type="text" placeholder="ios_sdk-5a5abd98ec38717b42717b70d2f9faf5a9..." name="ios_api_key"
                                            value={this.state.ios_api_key}
                                            onChange={(e) =>
                                              this.handleforminput(e)
                                            }/>
                                      </div>
                                    )}
  
                                  </div>
  
                                  <div className="chat-type">
  
                                    <div className="chat-name-head">
                                      <div className="chat-name-head-right">
                                        <img  src={require("../../assets/images/dashboard/livechat-icon.png").default} alt="livechat" className="img-fluid"/>
                                        <h2>LiveChat</h2>
                                      </div>
                                      <label className="switch" for="livechat">
                                        <input type="checkbox" id="livechat"   name="livechat_status"
                                          checked={this.state.livechat_status}
                                          onChange={(e) =>
                                            this.handleformstatus(e)
                                          }/>
                                        <div className="slider round"></div>
                                      </label>
                                    </div>
                                    {this.state.livechat_status === true && (
                                    <div className="chat-name-body">
                                      <h4>License ID</h4>
                                      <input type="text" placeholder="12990341"  name="livechat_licence_id"
                                            value={this.state.livechat_licence_id}
                                            onChange={(e) =>
                                              this.handleforminput(e)
                                            }/>
  
                                      <h4>Group ID</h4>
                                      <input type="text" name="livechat_group_id"
                                            value={this.state.livechat_group_id}
                                            onChange={(e) =>
                                              this.handleforminput(e)
                                            } />
                                    </div>
                                    )}
  
                                  </div>
  
                                  <div className="chat-type">
  
                                    <div className="chat-name-head">
                                      <div className="chat-name-head-right">
                                        <img  src={require("../../assets/images/dashboard/tawk-icon.png").default} alt="tawk" className="img-fluid"/>
                                        <h2>Tawk.to</h2>
                                      </div>
                                      <label className="switch" for="tawk">
                                        <input type="checkbox"  id="tawk"
                                          name="tawk_status"
                                          checked={this.state.tawk_status}
                                          onChange={(e) =>
                                            this.handleformstatus(e)
                                          }/>
                                        <div className="slider round"></div>
                                      </label>
  
                                    </div>
                                    {this.state.tawk_status === true && (
                                    <div className="chat-name-body">
                                      <h4>URL</h4>
                                      <input type="text" placeholder="www.tawk.to/chat/61774339d6e7610a49b2115f/1fe0ltdff"  name="tawk_url"
                                          value={this.state.tawk_url}
                                          onChange={(e) =>
                                            this.handleforminput(e)
                                          }
                                        />
                                    </div>
                                    )}
  
                                  </div>
  
                                  {/* Hubspot start*/}
                                  <div className="chat-type">
  
                                      <div className="chat-name-head">
                                        <div className="chat-name-head-right">
                                          <img  src={require("../../assets/images/chat/hubspot-icon.png").default} alt="custom-url" className="img-fluid"/>
                                          <h2>Hubspot</h2>
                                        </div>
                                        <label className="switch" for="hubspot">
                                          <input type="checkbox" id="hubspot" name="hubspot_status"
                                            checked={this.state.hubspot_status === true ? true : false}
                                            onChange={(e) =>
                                              this.handleformstatus(e)
                                            }/>
                                          <div className="slider round"></div>
                                        </label>
                                      </div>
                                      {this.state.hubspot_status === true && (
                                      <div className="chat-name-body">
                                        <h4>URL</h4>
                                        <input type="text" placeholder="www.hubspot.com/chat/61274"  name="hubspot_url"
                                            value={this.state.hubspot_url}
                                            onChange={(e) =>
                                              this.handleforminput(e)
                                            }/>
                                      </div>
                                      )}
  
                                      </div>
                                      {/* Hubspot end*/}
  
                                      {/* Chat GPT start */}
                                  <div className="chat-type">
  
                                        <div className="chat-name-head">
                                          <div className="chat-name-head-right">
                                            <img  src={require("../../assets/images/chat/chatgpt-icon.png").default} alt="custom-url" className="img-fluid"/>
                                            <h2>Chat GPT</h2>
                                          </div>
                                          <label className="switch" for="chatGPT">
                                            <input type="checkbox" id="chatGPT" name="chatgpt_status"
                                              checked={(this.state.chatgpt_status == 1 || this.state.chatgpt_status == true) ? true : false}
                                              onChange={(e) =>
                                                this.handleformstatus(e)
                                              }/>
                                            <div className="slider round"></div>
                                          </label>
                                        </div>
                                        {(this.state.chatgpt_status == true || this.state.chatgpt_status == 1) && (
                                        <div className="chat-name-body">
                                          <h4>URL</h4>
                                          <input type="text" placeholder="www.chatgpt.com/chat/61274"  name="chatgpt_url"
                                              value={this.state.chatgpt_url}
                                              onChange={(e) =>
                                                this.handleforminput(e)
                                              }/>
                                        </div>
                                        )}
  
                                        </div>
                                        {/* Chat GPT end */}
  
                                  <div className="chat-type">
  
                                    <div className="chat-name-head">
                                      <div className="chat-name-head-right">
                                        <img  src={require("../../assets/images/dashboard/custom-url-icon.png").default} alt="custom-url" className="img-fluid"/>
                                        <h2>Custom Url</h2>
                                      </div>
                                      <label className="switch" for="chatfive">
                                        <input type="checkbox" id="chatfive" name="custom_status"
                                          checked={this.state.custom_status}
                                          onChange={(e) =>
                                            this.handleformstatus(e)
                                          }/>
                                        <div className="slider round"></div>
                                      </label>
                                    </div>
                                    {this.state.custom_status === true && (
                                    <div className="chat-name-body">
                                      <h4>URL</h4>
                                      <input type="text" placeholder="www.example.com/chat/61274"  name="custom_url"
                                          value={this.state.custom_url}
                                          onChange={(e) =>
                                            this.handleforminput(e)
                                          }/>
                                    </div>
                                     )}
  
                                  </div>
  
                                  <div className="chat-type">
                                    <div className="chat-name-head">
                                      <div className="chat-name-head-right">
                                        <img  src={require("../../assets/images/dashboard/custom-script-icon.png").default} alt="custom-script" className="img-fluid"/>
                                        <h2>Custom Script</h2>
                                      </div>
                                      <label className="switch" for="chatsix">
                                        <input type="checkbox" id="chatsix" name="script_status"
                                          checked={this.state.script_status}
                                          onChange={(e) =>
                                            this.handleformstatus(e)
                                          }/>
                                        <div className="slider round"></div>
                                      </label>
                                    </div>
                                    {this.state.script_status === true && (
                                    <div className="chat-name-body">
                                      <h4>Script</h4>
                                      <CodeMirror
                                          name="module_script"
                                          value={this.state.module_script}
                                          height="200px"
                                          theme="dark"
                                          onChange={(e) =>this.handlecodeforminput(e)}
                                        />
                                      {/* <textarea
                                          required
                                          rows={3}
                                          placeholder={`e.g. <script type="text/javascript">var Chat_API=Chat_API||{},Chat_LoadStart=new Date();(function(){var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];s1.async=true;s1.src='https://embed.Chat.com/dfdfdfdf/1fe0lt7fe';s1.charset='UTF-8';s1.setAttribute('crossorigin','*');s0.parentNode.insertBefore(s1,s0);})();</script>`}
                                          className="form-control form-name"
                                          name="module_script"
                                          value={this.state.module_script}
                                          onChange={(e) =>
                                            this.handleforminput(e)
                                          }
                                        ></textarea> */}
                                    </div>
                                     )}
                                    
                                  </div>
  
                                </div>
  
  
                                <div className="chat-right">
                                  {/* <img  src={require("../../assets/images/dashboard/No-chat.png").default} alt="No-chat" className="img-fluid"/>
                                  <img  src={require("../../assets/images/dashboard/Zendesk.png").default} alt="Zendesk" className="img-fluid d-none"/>
                                  <img  src={require("../../assets/images/dashboard/Intercom.png").default} alt="Intercom" className="img-fluid d-none"/>
                                  <img  src={require("../../assets/images/dashboard/LiveChat.png").default} alt="LiveChat" className="img-fluid d-none"/>
                                  <img  src={require("../../assets/images/dashboard/Tawk.png").default} alt="Tawk" className="img-fluid d-none"/>
                                  <img  src={require("../../assets/images/dashboard/Customurl.png").default} alt="Customurl" className="img-fluid d-none"/> */}
                                  <img  src={require(`../../assets/images/dashboard/${this.handlePreviewImage()}.png`).default} alt="Customurl" className="img-fluid"/>
                                
                                </div>
                            </div>
  
                            <div className="chat-mob-btns">
                            <button
                                className={
                                  this.state.build_process_status === true
                                    ? "blockuser next_btn checknew newwidthd chat_next_btn"
                                    : "next_btn checknew chat_next_btn"
                                }
                                style={{backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                                form="formsubmit"
                                disabled={this.state.build_process_status}
                              >
                                <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                  <div className={this.state.loader}>
                                    
                                  </div>{" "}
                                  <div>
                                    {this.state.build_process_status === true
                                      ? "Build Processing"
                                      : this.state.submitval}
                                  </div>
                                </div>
                              </button>
                              {this.state.last_updated_timestamp && this.state.last_updated_timestamp !== '' && this.state.last_updated_timestamp !== 'null' && this.state.last_updated_timestamp !== undefined ?
                                  <p>Updated  {moment(this.state.last_updated_timestamp).format(
                                            "D MMMM YYYY, HH:mm"
                                          )}{" "}</p>
                                : null
                                }
                            </div>
                            </div>  
  
                            <div className={this.state.enablesetting == true ? 'chat-settings' : 'd-none'}>
                              <div className="chat-setting-bg">
  
                                <div className="chat-setting-left">
  
                                  <div className="chat-setting-header">
                                    <h2>Settings</h2>
                                    <h3>Customize the in-app chat experience effortlessly. Toggle options to personalise and control chat visibility for your app.</h3>
                                  </div>
  
                                  <div className="chat-setting-accordion">
  
                                  <Accordion>
  
                                    <Accordion.Item eventKey="0" className="chat-setting-main" onClick={this.state.show_floating_chat_icon ? () =>this.handleRowStatus(1) : void(0)}>
                                      <Accordion.Header>
                                      <div className="chat-setting-toggle">
                                      <h5>Show floating chat icon<p className="gsetting-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><p style={{textAlign: "left"}} className="gsetting-tooltipsubheader">Display your chat icon as a floating element.</p></p></h5>
  
                                      <div className="chat-setting-switch">
                                      <label className="switch" for="chatnine">
                                        <input type="checkbox" id="chatnine" name="show_floating_chat_icon" checked={this.state.show_floating_chat_icon} onChange={(e) =>this.handlesettingformstatus(e)}/>
                                        <div className="slider round"></div>
                                      </label>
                                       
                                          <svg className={this.state.show_floating_chat_icon ? "thechatarrow" : ''} style={{transform:this.state.show_floating_chat_icon ? '' : 'rotate(180deg)' }} id="Group_39685" data-name="Group 39685" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                                            <circle id="Ellipse_6953" data-name="Ellipse 6953" cx="11" cy="11" r="11" fill="none"/>
                                            <path id="Path_81302" data-name="Path 81302" d="M-12935-18908l6-6,6,6" transform="translate(12940.501 18921.504)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
                                          </svg>
                                      
                                    </div>
  
                                    </div>
                                      </Accordion.Header>
                                      
                                        {this.state.show_floating_chat_icon == true || this.state.show_floating_chat_icon == 1 ? 
                                          <>
                                          <Accordion.Body>
                                            <h4 className="head-gap">Icon<p className="gsetting-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><p style={{textAlign: "left"}} className="gsetting-tooltipsubheader">Personalize your chat icon.</p></p></h4>
                                            <label className="set-full-img">
                                                <div className="image-uploader">
                                                    <div className="d-flex align-items-center" 
                                                    onClick={(e)=>this.handleIconPopUp(e,"floatingchat")}
                                                    // onClick={(e) => this.changeIcon('floatingchat')}
                                                      >
                                                    
                                                        <div className="chat-icon-background">
                                                          <div className={this.state.floating_chat_icon_name ? "uploadpixabayimage chatafterupload" :"uploadpixabayimage"} style={{backgroundImage : this.state.floating_chat_icon_name ?  
                                                                 this.state.floating_chat_icon_name==="chat_bubble.png"? "url(" +  defaultIcon + ")"
                                                                 :
                                                                 "url("+process.env.REACT_APP_Image_Path+
                                                                         localStorage.getItem("user_id") +"/"+localStorage.getItem("Appid")+process.env.REACT_APP_NEW_ICON_PATH +
                                                                         this.state.floating_chat_icon_name + 
                                                                      ")" 
                                                                : "url(" +  imagebackground + ")"}}></div>
                                                                </div>
                                                        <p style={{marginBottom: "0px" , color: this.state.floating_chat_icon_name && this.state.floating_chat_icon_name != '' ? "" : "#BCC1CE"}}>{this.state.floating_chat_icon_name && this.state.floating_chat_icon_name != '' ? this.state.floating_chat_icon_name.replace("%2Bxml","") : "Upload your image here"}</p>
                                                    </div>
                                                  {this.state.floating_chat_icon_name ?
                                                    <svg width="11.06" height="11.06" viewBox="0 0 11.06 11.06"  onClick={this.state.floating_chat_icon_name && this.state.floating_chat_icon_name != '' ? () =>this.handleItemicon('floatingchat') : void(0)}>
                                                    <path id="Union_1696" data-name="Union 1696" d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z" transform="translate(0.53 0.53)" fill="none" stroke="#7782a1" stroke-width="1.5" /> </svg>
                                                  : <></>
                                                  }
                                                </div>
                                            </label>
  
                                            
                                            <div className="row">
                                              <div className="col-md-6">
                                              <h4>Icon background color<p className="gsetting-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><p style={{textAlign: "left"}} className="gsetting-tooltipsubheader">Set a background color for your chat icon.</p></p></h4>
                                                <div className="chat-color-input">
                                                <Reactcolorpicker 
                                                  colorname={this.state.chatbackgroundColor}
                                                  onChange={this.changebackgroundcolor}
                                                  classselection={"fixback"}
                                                  valuecontrol={!this.state.hidecontrol}
                                                  presetColors={this.state.presetColors}>
                                                    
                                                </Reactcolorpicker>
                                                </div>
                                              </div>
                                            {this.state.floating_chat_icon_type == 'black' ?
                                              <div className="col-md-6">
                                              <h4>Icon color<p className="gsetting-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><p style={{textAlign: "left"}} className="gsetting-tooltipsubheader">Set a color for your Chat icon.</p></p></h4>
                                                <div className="chat-color-input">
                                                  <Reactcolorpicker 
                                                    colorname={this.state.chaticoncolor}
                                                    onChange={this.changeiconcolor}
                                                    classselection={"fixback"}
                                                    valuecontrol={this.state.hidecontrol}
                                                    presetColors={this.state.presetColors}>
                                                  </Reactcolorpicker>
                                                </div>
                                              </div>
                                            : <></>
                                            }
                                            </div>
                                            </Accordion.Body>
                                          </>
                                        : <></>
                                        }
                                          
                                     
                                    </Accordion.Item>
                                  {(
                                    parseInt(localStorage.getItem("website_technology")) !== 2 && 
                                    parseInt(localStorage.getItem("website_technology")) !== 3 ) &&
                                    <Accordion.Item eventKey="1" className="chat-setting-main" onClick={this.state.show_chat_link_in_profile ? () =>this.handleRowStatus(2) : void(0)}>
                                    <Accordion.Header>
                                    <div className="chat-setting-toggle">
                                    <h5>Show Chat link in Profile<p className="gsetting-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><p style={{textAlign: "left"}} className="gsetting-tooltipsubheader">Enable to display the Chat link on the user profile screen.</p></p></h5>
  
                                    <div className="chat-setting-switch">
                                    <label className="switch" for="chattenth">
                                      <input type="checkbox" id="chattenth"  name="show_chat_link_in_profile" checked={this.state.show_chat_link_in_profile} onChange={(e) =>this.handlesettingformstatus(e)}/>
                                      <div className="slider round"></div>
                                    </label>
                                   
                                      <svg className={this.state.show_chat_link_in_profile == true || this.state.show_chat_link_in_profile == 1 ? "thechatarrow" : ''} style={{transform:this.state.show_chat_link_in_profile == true || this.state.show_chat_link_in_profile == 1 ? '' : 'rotate(180deg)' }} id="Group_39685" data-name="Group 39685" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                                      <circle id="Ellipse_6953" data-name="Ellipse 6953" cx="11" cy="11" r="11" fill="none"/>
                                      <path id="Path_81302" data-name="Path 81302" d="M-12935-18908l6-6,6,6" transform="translate(12940.501 18921.504)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
                                    </svg>
                                    
                                  </div>
  
                                  </div>
                                    </Accordion.Header>
                                   
                                    {this.state.show_chat_link_in_profile == true || this.state.show_chat_link_in_profile == 1 ? 
                                    <>
                                     <Accordion.Body>
                                        <h4 className="head-gap">Text<p className="gsetting-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><p style={{textAlign: "left"}} className="gsetting-tooltipsubheader">Enter the text that appears next to the Chat icon.</p></p></h4>
                                      <input name="chat_link_text_in_profile"
                                            maxLength={100}
                                            value={this.state.chat_link_text_in_profile}
                                            onChange={(e) =>
                                              this.handleforminput(e)
                                            }/>
                                      <h4>Chat icon<p className="gsetting-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><p style={{textAlign: "left"}} className="gsetting-tooltipsubheader">Upload a custom icon to represent the Chat option on the user profile screen.</p></p></h4>
                                      <label className="set-full-img">
                                          <div className="image-uploader">
                                              <div className="d-flex align-items-center" 
                                              // onClick={(e) => this.changeIcon('menuicon')}
                                              onClick={(e)=>this.handleIconPopUp(e,'menuicon')}
                                              >
                                              
                                              <div className="chat-icon-background">
                                                  <div className={this.state.chat_link_icon_name_in_profile ? "uploadpixabayimage chatafterupload" : "uploadpixabayimage" } style={{backgroundImage : this.state.chat_link_icon_name_in_profile ?  
                                                                       this.state.chat_link_icon_name_in_profile==="chat_bubble.png"? "url(" +  defaultIcon + ")"
                                                                    :"url("+process.env.REACT_APP_Image_Path+
                                                                          localStorage.getItem("user_id") +"/"+localStorage.getItem("Appid")+process.env.REACT_APP_NEW_ICON_PATH +
                                                                          this.state.chat_link_icon_name_in_profile + 
                                                                        ")" 
                                                                  : "url(" + imagebackground + ")"}}>
  
                                                  </div>
                                                  </div>
                                              
                                              <p style={{marginBottom: "0px" , color: this.state.chat_link_icon_name_in_profile && this.state.chat_link_icon_name_in_profile != '' ? "" : "#BCC1CE"}}>{this.state.chat_link_icon_name_in_profile && this.state.chat_link_icon_name_in_profile != '' ? this.state.chat_link_icon_name_in_profile : "Upload an icon for chat"}</p>
                                              </div>
                                            {
                                              this.state.chat_link_icon_name_in_profile &&
                                            
                                              <svg width="11.06" height="11.06" viewBox="0 0 11.06 11.06" onClick={this.state.chat_link_icon_name_in_profile ? () =>this.handleItemicon('menuicon') : void(0)}>
                                              <path id="Union_1696" data-name="Union 1696" d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z" transform="translate(0.53 0.53)" fill="none" stroke="#7782a1" stroke-width="1.5" /> </svg>
                                            }
                                                                                
                                          </div>
                                      </label>
                                      </Accordion.Body>
                                    </>
                                    : 
                                    <></>
                                    }
                                   
                                    </Accordion.Item>
                                  }
                                    
  
                                    <Accordion.Item eventKey="2" className="chat-setting-main" onClick={this.state.show_chat_link_in_settings ? () =>this.handleRowStatus(3) : void(0)}>
                                      <Accordion.Header>
                                      <div className="chat-setting-toggle">
                                      <h5>Show Chat link in Settings<p className="gsetting-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><p style={{textAlign: "left"}} className="gsetting-tooltipsubheader">Enable to display the Chat link on the Settings screen.</p></p></h5>
                                      
                                      <div className="chat-setting-switch">
                                      <label className="switch" for="chatelventh">
                                        <input type="checkbox" id="chatelventh" name="show_chat_link_in_settings" checked={this.state.show_chat_link_in_settings} onChange={(e) =>this.handlesettingformstatus(e)}/>
                                        <div className="slider round"></div>
                                      </label>
                                      
                                      <svg className={this.state.show_chat_link_in_settings == true || this.state.show_chat_link_in_settings == 1 ? "thechatarrow" : ''} style={{transform:this.state.show_chat_link_in_settings == true || this.state.show_chat_link_in_settings == 1 ? '' : 'rotate(180deg)' }} id="Group_39685" data-name="Group 39685" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                                      <circle id="Ellipse_6953" data-name="Ellipse 6953" cx="11" cy="11" r="11" fill="none"/>
                                      <path id="Path_81302" data-name="Path 81302" d="M-12935-18908l6-6,6,6" transform="translate(12940.501 18921.504)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
                                    </svg>
                                    
  
                                    </div>
  
                                    </div>
                                      </Accordion.Header>
                                      {this.state.show_chat_link_in_settings == true || this.state.show_chat_link_in_settings == 1 ? 
                                      <Accordion.Body>
                                      <h4 className="head-gap">Text<p className="gsetting-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><p style={{textAlign: "left"}} className="gsetting-tooltipsubheader">Enter the text that appears next to the Chat icon.</p></p></h4>
                                      <input name="chat_link_text_in_settings"
                                            maxLength={100}
                                            value={this.state.chat_link_text_in_settings}
                                            onChange={(e) =>
                                              this.handleforminput(e)
                                            }/>
                                      </Accordion.Body>
                                      : <></>
                                      }
                                    </Accordion.Item>
  
                                  </Accordion>
                                  </div>
  
                                </div>
  
                                <div className="chat-setting-right">
                                {this.state.show_image == 1 ?
                                  <div className="chat-preview-screen-one " >
  
                                    <div className="chat-preview-head-one">
                                      <div>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11">
                                        <path id="Path_102594" data-name="Path 102594" d="M1333.767,259.9h12" transform="translate(-1333.767 -259.402)" fill="none" stroke="#818181" stroke-width="1"/>
                                        <path id="Path_102595" data-name="Path 102595" d="M1333.767,259.9h12" transform="translate(-1333.767 -249.402)" fill="none" stroke="#818181" stroke-width="1"/>
                                        <path id="Path_102596" data-name="Path 102596" d="M1333.767,259.9h12" transform="translate(-1333.767 -254.402)" fill="none" stroke="#818181" stroke-width="1"/>
                                      </svg>
  
                                      <h2>Home</h2>
                                      </div>
  
                                      <svg id="Group_38383" data-name="Group 38383" xmlns="http://www.w3.org/2000/svg" width="13.822" height="13.822" viewBox="0 0 13.822 13.822">
                                        <g id="Ellipse_246" data-name="Ellipse 246" transform="translate(12.351) rotate(90)" fill="none" stroke="#818181" stroke-width="1">
                                          <ellipse cx="6.175" cy="6.176" rx="6.175" ry="6.176" stroke="none"/>
                                          <ellipse cx="6.175" cy="6.176" rx="5.675" ry="5.676" fill="none"/>
                                        </g>
                                        <path id="Path_6064" data-name="Path 6064" d="M0,4.508V0" transform="translate(13.468 13.468) rotate(135)" fill="none" stroke="#818181" stroke-width="1"/>
                                      </svg>
                                    </div>
                                  {this.state.show_floating_chat_icon ?
                                    <div className="floating-chat-icon" style={{background: this.state.chatbackgroundColor ? this.state.chatbackgroundColor : "rgba(26, 26, 26, 0.8)"}}>
                                      <img 
                                          style={{
                                            filter:
                                              (this.state.floating_chat_icon_type == 'black' && this.state.chaticoncolor) || (this.state.floating_chat_icon_name == '') || (this.state.floating_chat_icon_name == 'chat_bubble.png') 
                                              ? this.state.chaticonfilter
                                              : null,
                                          }}
                                          src={this.state.floating_chat_icon_name && this.state.floating_chat_icon_name!=="chat_bubble.png" ?  
                                                process.env.REACT_APP_Image_Path+
                                                localStorage.getItem("user_id") +"/"+localStorage.getItem("Appid")+process.env.REACT_APP_NEW_ICON_PATH +
                                                this.state.floating_chat_icon_name 
                                                                      
                                              : require("../../assets/images/dashboard/chaticon.png").default} className="img-fluid"/>
                                    </div>
                                  : <></>
                                  }
                                    <div className="chat-preview-bottombar" style={{background:this.state.backgroundColor,borderTop: "1px solid "+this.state.borderColor}}>
                                    {this.state.pewview_bottom_item && this.state.pewview_bottom_item.map((cmsRow, indx) => {
                                    return indx >= 0 && indx < this.state.setvalue ? 
                                    (
                                      <div className="bottombar-options">
                                        <div>
                                          <div>
                                          {this.state.bottomiconview || this.state.bottomiconview == 1 ?
                                          <>
                                            { cmsRow.item_icon_newtype != '' && cmsRow.item_icon_newtype !== undefined && cmsRow.item_icon_newtype === "flaticon" ?
                                              <img className="img-fluid" 
                                              style={{
                                                filter:
                                                  (this.state.setinputcolor === this.state.bottom_menu_items.length) &&
                                                  (cmsRow.item_icon_flaticon_color === "black" || cmsRow.item_icon_flaticon_color === undefined) && 
                                                  cmsRow.item_icon_newtype === 'flaticon' && this.state.selectedtextcolor !== "" ? 
                                                  cmsRow.is_default || cmsRow.is_default == 1 ? this.state.selectediconfilter : this.state.iconfilter
                                                  : null,
                                              }}
                                              src={cmsRow.item_icon != '' && cmsRow.item_icon != undefined && cmsRow.item_icon ? process.env.REACT_APP_Image_Path+
                                                    localStorage.getItem("user_id") +"/"+
                                                    localStorage.getItem("Appid")+
                                                    process.env.REACT_APP_NEW_ICON_PATH +
                                                    cmsRow.item_icon 
                                                  : require("../../assets/images/dashboard/create-bottom-bar.png").default} />
                                            : cmsRow.item_icon && cmsRow.item_icon != '' ?
                                                <img
                                                className="img-fluid"
                                                src={
                                                  process.env.REACT_APP_Image_Path +
                                                  (() => {
                                                    switch (cmsRow.item_icon_type) {
                                                      case "light":
                                                        return process.env.REACT_APP_light_path;
                                                      case "bold":
                                                        return process.env.REACT_APP_bold_path;
                                                      case "regular":
                                                        return process.env.REACT_APP_regular_path;
                                                      case "new":
                                                        return (
                                                          parseInt(
                                                            localStorage.getItem("user_id")
                                                          ) +
                                                          "/" +
                                                          parseInt(
                                                            localStorage.getItem("Appid")
                                                          ) +
                                                          process.env.REACT_APP_NEW_ICON_PATH
                                                        );
                                                      default:
                                                        return process.env.REACT_APP_light_path;
                                                    }
                                                  })() +
                                                  cmsRow.item_icon
                                                }
                                                alt="home-item"
                                                width="25"
                                              />
                                            :
                                              <img
                                              className="img-fluid"
                                              src={require("../../assets/images/dashboard/create-bottom-bar.png").default}
                                              alt="home-item"
                                              width="25"
                                              />
                                            }
                                          </>
                                          : <></>
                                          }
                                          </div>
                                          {this.state.bottomicontext || this.state.bottomicontext == 1 ?
                                            <p style={{color:cmsRow.is_default || cmsRow.is_default == 1 ? this.state.selectedicontextcolor :this.state.icontextcolor}}>{cmsRow.item_name}</p>
                                          : <></>
                                          } 
                                        </div>
                                      </div>
                                        )
                                        : (<></>)
                                      })
                                    }
  
                                  {this.state.pewview_bottom_item && this.state.pewview_bottom_item.length >= 6 ?
                                    <div className="bottombar-options">
                                      <div>
                                        {this.state.bottomiconview || this.state.bottomiconview == 1 ?
                                        <>
                                          {this.state.menuicon ?
                                            <img className="img-fluid" src={process.env.REACT_APP_Image_Path+
                                              localStorage.getItem("user_id") +"/"+localStorage.getItem("Appid")+process.env.REACT_APP_NEW_ICON_PATH +
                                              this.state.menuicon}/>  
                                                              
                                          : <img className="img-fluid" src={require("../../assets/images/dashboard/bottombar-more.png").default}/>
                                          }
                                          </>
                                          
                                        : <></>
                                        }
                                        {this.state.bottomicontext || this.state.bottomicontext == 1 ?
                                          <p style={{color: this.state.icontextcolor}}>{this.state.menutext}</p>
                                        : <></>
                                        } 
                                      
                                      </div>
                                    </div>
                                  : <></>
                                  }
  
                                      {/* <div className="bottombar-options">
                                        <div>
                                          <div>
                                          <img  src={require("../../assets/images/dashboard/zendesk-icon.png").default} alt="zendesk" className="img-fluid"/>
                                          </div>
                                          <p style={{color: "#000"}}>About</p>
                                        </div>
                                      </div>
  
                                      <div className="bottombar-options">
                                        <div>
                                          <div>
                                          <img  src={require("../../assets/images/dashboard/zendesk-icon.png").default} alt="zendesk" className="img-fluid"/>
                                          </div>
                                          <p style={{color: "#000"}}>Blog</p>
                                        </div>
                                      </div>
  
                                      <div className="bottombar-options">
                                        <div>
                                          <div>
                                          <img  src={require("../../assets/images/dashboard/zendesk-icon.png").default} alt="zendesk" className="img-fluid"/>
                                          </div>
                                          <p style={{color: "#000"}}>Watch</p>
                                        </div>
                                      </div>
  
                                      <div className="bottombar-options">
                                        <div>
                                          <div>
                                          <img  src={require("../../assets/images/dashboard/zendesk-icon.png").default} alt="zendesk" className="img-fluid"/>
                                          </div>
                                          <p style={{color: "#000"}}>Profile</p>
                                        </div>
                                      </div> */}
  
                                    </div>
                                    
                                  </div>
                                : this.state.show_image == 2 ?
                                  <div className="chat-preview-screen-two ">
  
                                    <div className="chat-preview-head-two">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11">
                                        <path id="Path_102594" data-name="Path 102594" d="M1333.767,259.9h12" transform="translate(-1333.767 -259.402)" fill="none" stroke="#818181" stroke-width="1"/>
                                        <path id="Path_102595" data-name="Path 102595" d="M1333.767,259.9h12" transform="translate(-1333.767 -249.402)" fill="none" stroke="#818181" stroke-width="1"/>
                                        <path id="Path_102596" data-name="Path 102596" d="M1333.767,259.9h12" transform="translate(-1333.767 -254.402)" fill="none" stroke="#818181" stroke-width="1"/>
                                      </svg>
  
                                      <h2>My Account</h2>
  
                                    </div>
  
                                    <div className="chat-intro">
                                      <h3>Hi John Doe!</h3>
                                      <h4>john.doe@example.com</h4>
                                    </div>
  
                                    <div className="chat-list-two">
  
                                      <div className="chat-list-two-entry">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                                        <g id="Group_39650" data-name="Group 39650" transform="translate(0.441 -0.42)">
                                          <g id="Ellipse_3055" data-name="Ellipse 3055" transform="translate(-0.441 0.42)" fill="none" stroke="#333" stroke-width="1">
                                            <circle cx="11" cy="11" r="11" stroke="none"/>
                                            <circle cx="11" cy="11" r="10.5" fill="none"/>
                                          </g>
                                          <g id="iconfinder_00-ELASTOFONT-STORE-READY_star_2703064" transform="translate(3.187 3.031)" fill="none">
                                            <path d="M9.35,8.722a.287.287,0,0,0-.082.254l.409,2.388L7.534,10.237a.288.288,0,0,0-.267,0L5.124,11.364l.409-2.388a.287.287,0,0,0-.082-.254L3.717,7.031l2.4-.348a.287.287,0,0,0,.216-.157L7.4,4.354,8.472,6.526a.287.287,0,0,0,.216.157l2.4.348Z" stroke="none"/>
                                            <path d="M 5.124161720275879 11.3640079498291 L 7.267141819000244 10.23652744293213 C 7.350740909576416 10.19250774383545 7.450722217559814 10.19249629974365 7.53438138961792 10.23651790618896 L 9.677361488342285 11.36399745941162 L 9.267951965332031 8.976187705993652 C 9.251901626586914 8.883257865905762 9.282881736755371 8.788457870483398 9.350431442260742 8.722397804260254 L 11.08435153961182 7.030987739562988 L 8.688331604003906 6.682787418365479 C 8.594661712646484 6.669347763061523 8.514041900634766 6.610377311706543 8.472241401672363 6.526037693023682 L 7.40075159072876 4.353567600250244 L 6.329261779785156 6.526057720184326 C 6.287461757659912 6.610397338867188 6.206851482391357 6.66936731338501 6.113171577453613 6.682807445526123 L 3.717151641845703 7.031017303466797 L 5.451091766357422 8.722407341003418 C 5.518641471862793 8.788467407226562 5.54962158203125 8.883267402648926 5.533571720123291 8.976197242736816 L 5.124161720275879 11.3640079498291 M 3.796021699905396 13.1927375793457 L 4.484612941741943 9.176618576049805 L 1.56913161277771 6.332677364349365 L 5.598434448242188 5.747112274169922 L 7.40075159072876 2.092837572097778 L 9.203073501586914 5.747091770172119 L 13.23235130310059 6.332637310028076 L 10.31690692901611 9.176608085632324 L 11.00550174713135 13.19272708892822 L 7.400764942169189 11.29618358612061 L 3.796021699905396 13.1927375793457 Z" stroke="none" fill="#333"/>
                                          </g>
                                        </g>
                                      </svg>
                                      <p>My Rewards</p>
                                      <h5>250 pts</h5>
                                      </div>
  
                                      <div className="chat-list-two-entry">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="19.966" height="20.044" viewBox="0 0 19.966 20.044">
                                      <g id="Group_39652" data-name="Group 39652" transform="translate(0.5)">
                                        <path id="Path_8421" data-name="Path 8421" d="M877.9,251.686l-3.025,1.441a3.214,3.214,0,0,0-1.833,2.9v2.828h18.966v-2.828a3.214,3.214,0,0,0-1.832-2.9l-3.025-1.441" transform="translate(-873.041 -239.312)" fill="rgba(0,0,0,0)" stroke="#333" stroke-width="1"/>
                                        <g id="Path_8422" data-name="Path 8422" transform="translate(-883.337 -208)" fill="rgba(0,0,0,0)">
                                          <path d="M 892.8203125 219.4113159179688 C 891.583251953125 219.4113159179688 890.3633422851562 218.7239227294922 889.3853759765625 217.4757232666016 C 888.4591064453125 216.2934722900391 887.9060668945312 214.775146484375 887.9060668945312 213.4142303466797 C 887.9060668945312 210.7045135498047 890.110595703125 208.4999847412109 892.8203125 208.4999847412109 C 895.530029296875 208.4999847412109 897.7344970703125 210.7045135498047 897.7344970703125 213.4142303466797 C 897.7344970703125 214.7751312255859 897.1814575195312 216.29345703125 896.255126953125 217.4757385253906 C 895.2772216796875 218.7239227294922 894.057373046875 219.4113159179688 892.8203125 219.4113159179688 Z" stroke="none"/>
                                          <path d="M 892.8203125 208.9999847412109 C 890.3862915039062 208.9999847412109 888.4060668945312 210.9802093505859 888.4060668945312 213.4142303466797 C 888.4060668945312 215.8887023925781 890.4429931640625 218.9113159179688 892.8203125 218.9113159179688 C 895.1975708007812 218.9113159179688 897.2344970703125 215.8887023925781 897.2344970703125 213.4142303466797 C 897.2344970703125 210.9802093505859 895.2542724609375 208.9999847412109 892.8203125 208.9999847412109 M 892.8203125 207.9999847412109 C 895.810546875 207.9999847412109 898.2344970703125 210.4240112304688 898.2344970703125 213.4142303466797 C 898.2344970703125 216.4044494628906 895.810546875 219.9113159179688 892.8203125 219.9113159179688 C 889.8300170898438 219.9113159179688 887.4060668945312 216.4044494628906 887.4060668945312 213.4142303466797 C 887.4060668945312 210.4240112304688 889.8300170898438 207.9999847412109 892.8203125 207.9999847412109 Z" stroke="none" fill="#333"/>
                                        </g>
                                        <line id="Line_119" data-name="Line 119" y1="2.33" transform="translate(4.626 17.214)" fill="none" stroke="#333" stroke-width="1"/>
                                        <line id="Line_120" data-name="Line 120" y1="2.33" transform="translate(14.34 17.214)" fill="none" stroke="#333" stroke-width="1"/>
                                      </g>
                                    </svg>
                                      <p>My Profile</p>
                                      </div>
  
                                      <div className="chat-list-two-entry">
                                      <svg id="Group_39655" data-name="Group 39655" xmlns="http://www.w3.org/2000/svg" width="16.38" height="21.734" viewBox="0 0 16.38 21.734">
                                        <g id="Path_10017" data-name="Path 10017" transform="translate(0)" fill="none">
                                          <path d="M8.19,0a8.19,8.19,0,0,1,8.19,8.19c0,6.926-8.19,13.545-8.19,13.545S0,15.242,0,8.19A8.19,8.19,0,0,1,8.19,0Z" stroke="none"/>
                                          <path d="M 8.189750671386719 1 C 4.225310325622559 1 1.000000953674316 4.225309371948242 1.000000953674316 8.189749717712402 C 1.000000953674316 13.5692024230957 6.330878257751465 18.77000999450684 8.185030937194824 20.41588973999023 C 10.03843212127686 18.74909973144531 15.37950038909912 13.4854211807251 15.37950038909912 8.189749717712402 C 15.37950038909912 4.225309371948242 12.15419006347656 1 8.189750671386719 1 M 8.189750671386719 0 C 12.7128210067749 0 16.37950134277344 3.666679382324219 16.37950134277344 8.189749717712402 C 16.37950134277344 15.11612892150879 8.189750671386719 21.73433876037598 8.189750671386719 21.73433876037598 C 8.189750671386719 21.73433876037598 0 15.24165916442871 0 8.189749717712402 C 0 3.666679382324219 3.666680335998535 0 8.189750671386719 0 Z" stroke="none" fill="#333"/>
                                        </g>
                                        <g id="Ellipse_3094" data-name="Ellipse 3094" transform="translate(5.144 5.405)" fill="none" stroke="#333" stroke-width="1">
                                          <circle cx="3" cy="3" r="3" stroke="none"/>
                                          <circle cx="3" cy="3" r="2.5" fill="none"/>
                                        </g>
                                      </svg>
  
                                      <p>My Address</p>
                                      </div>
  
                                      <div className="chat-list-two-entry">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17">
                                      <g id="Group_39654" data-name="Group 39654" transform="translate(0.051 -0.007)">
                                        <g id="Rectangle_4570" data-name="Rectangle 4570" transform="translate(-0.051 0.007)" fill="none" stroke="#333" stroke-width="1">
                                          <rect width="18" height="17" rx="3" stroke="none"/>
                                          <rect x="0.5" y="0.5" width="17" height="16" rx="2.5" fill="none"/>
                                        </g>
                                        <g id="Path_9026" data-name="Path 9026" transform="translate(7.959 0.007)" fill="none">
                                          <path d="M-2,0H3.98V7.475L.969,5.98-2,7.475Z" stroke="none"/>
                                          <path d="M -1.000000476837158 0.9999961853027344 L -1.000000476837158 5.851680755615234 L 0.9660394191741943 4.861776351928711 L 2.979819297790527 5.861724853515625 L 2.979819297790527 0.9999961853027344 L -1.000000476837158 0.9999961853027344 M -2.000000476837158 -3.814697265625e-06 L 3.979819297790527 -3.814697265625e-06 L 3.979819297790527 7.474776268005371 L 0.9691493511199951 5.979816436767578 L -2.000000476837158 7.474776268005371 L -2.000000476837158 -3.814697265625e-06 Z" stroke="none" fill="#333"/>
                                        </g>
                                      </g>
                                    </svg>
  
                                      <p>My Orders</p>
                                      </div>
  
                                      <div className="chat-list-two-entry">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="25.435" height="25.434" viewBox="0 0 25.435 25.434">
                                        <g id="Group_39649" data-name="Group 39649" transform="translate(16.978 0.847) rotate(45)">
                                          <path id="Path_8423" data-name="Path 8423" d="M10.762,17.628a5.381,5.381,0,1,1-7.671-4.869V2.227L5.381,0,7.667,2.227V3.644l-1.484,1.6,1.484,1.6v.861l-1.484,1.6,1.484,1.6v1.841A5.375,5.375,0,0,1,10.762,17.628Z" transform="translate(0 0)" fill="none" stroke="#333" stroke-width="1"/>
                                          <circle id="Ellipse_251" data-name="Ellipse 251" cx="1.299" cy="1.299" r="1.299" transform="translate(4.081 18.556)" fill="none" stroke="#333" stroke-width="1"/>
                                        </g>
                                      </svg>
                                      <p>Change Password</p>
                                      </div>
                                    {this.state.show_chat_link_in_profile ?
                                      <div className="chat-list-two-entry">
                                      {this.state.chat_link_icon_name_in_profile && this.state.chat_link_icon_name_in_profile!=="chat_bubble.png"  ?
                                      <img src={process.env.REACT_APP_Image_Path+
                                                localStorage.getItem("user_id") +"/"+localStorage.getItem("Appid")+process.env.REACT_APP_NEW_ICON_PATH +
                                                this.state.chat_link_icon_name_in_profile} 
                                            style={{maxWidth:"20px"}}/>                               
                                      :  
                                          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                          <g id="Group_42297" data-name="Group 42297" transform="translate(8332.5 -5106.5)">
                                            <path id="Path_119007" data-name="Path 119007" d="M9,0A9,9,0,1,1,4.662,16.863c.01,0-3.678.887-3.678.887s.891-3.25.878-3.267A8.744,8.744,0,0,1,0,9,9,9,0,0,1,9,0Z" transform="translate(-8332 5107)" fill="none" stroke="#333" stroke-linejoin="round" stroke-width="1"/>
                                            <g id="Ellipse_9318" data-name="Ellipse 9318" transform="translate(-8328 5115)" fill="none" stroke="#333" stroke-width="1">
                                              <circle cx="1" cy="1" r="1" stroke="none"/>
                                              <circle cx="1" cy="1" r="0.5" fill="none"/>
                                            </g>
                                            <g id="Ellipse_9319" data-name="Ellipse 9319" transform="translate(-8324 5115)" fill="none" stroke="#333" stroke-width="1">
                                              <circle cx="1" cy="1" r="1" stroke="none"/>
                                              <circle cx="1" cy="1" r="0.5" fill="none"/>
                                            </g>
                                            <g id="Ellipse_9320" data-name="Ellipse 9320" transform="translate(-8320 5115)" fill="none" stroke="#333" stroke-width="1">
                                              <circle cx="1" cy="1" r="1" stroke="none"/>
                                              <circle cx="1" cy="1" r="0.5" fill="none"/>
                                            </g>
                                          </g>
                                        </svg>
                                    
                                      }
                                        <p>{this.state.chat_link_text_in_profile != '' ? this.state.chat_link_text_in_profile : 'Chat'}</p>
                                      </div>
                                    : <></>
                                    }
  
                                      <div className="chat-list-two-entry">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="23.002" height="23.002" viewBox="0 0 23.002 23.002">
                                  <g id="Settings_Copy" data-name="Settings Copy" transform="translate(0.75 0.75)">
                                    <path id="Shape" d="M10.737,0A10.681,10.681,0,0,0,9.5.072.6.6,0,0,0,8.971.7,2.384,2.384,0,0,1,7.517,3.034,2.229,2.229,0,0,1,6.65,3.2,2.468,2.468,0,0,1,4.837,2.43a.6.6,0,0,0-.818-.065,10.7,10.7,0,0,0-1.7,1.72.6.6,0,0,0,.065.81,2.391,2.391,0,0,1,.6,2.658A2.368,2.368,0,0,1,.828,9.029H.773a.714.714,0,0,0-.705.529A10.745,10.745,0,0,0,.072,12a.579.579,0,0,0,.6.528H.693a2.487,2.487,0,0,1,2.34,1.454,2.383,2.383,0,0,1-.6,2.682.6.6,0,0,0-.064.817A10.661,10.661,0,0,0,4.106,19.2a.593.593,0,0,0,.369.127.6.6,0,0,0,.443-.2,2.472,2.472,0,0,1,1.812-.767,2.232,2.232,0,0,1,.849.161,2.393,2.393,0,0,1,1.472,2.293.6.6,0,0,0,.532.615A10.7,10.7,0,0,0,12,21.43a.6.6,0,0,0,.527-.628,2.388,2.388,0,0,1,1.453-2.334,2.241,2.241,0,0,1,.869-.168,2.463,2.463,0,0,1,1.812.77.6.6,0,0,0,.818.066,10.677,10.677,0,0,0,1.7-1.72.6.6,0,0,0-.065-.81,2.374,2.374,0,0,1,1.625-4.131.7.7,0,0,0,.691-.529,10.75,10.75,0,0,0,0-2.445.6.6,0,0,0-.585-.529l-.045,0-.133,0a2.408,2.408,0,0,1-2.2-1.457,2.381,2.381,0,0,1,.6-2.681.6.6,0,0,0,.065-.818A10.712,10.712,0,0,0,17.395,2.3a.6.6,0,0,0-.811.067,2.474,2.474,0,0,1-1.811.767,2.238,2.238,0,0,1-.851-.161A2.389,2.389,0,0,1,12.45.681a.6.6,0,0,0-.532-.615A10.738,10.738,0,0,0,10.737,0Zm.03,14.314a3.586,3.586,0,1,1,3.586-3.586A3.59,3.59,0,0,1,10.767,14.314Z" transform="translate(21.501 21.502) rotate(180)" fill="none" stroke="#333333" strokeWidth="1.5"/>
                                    </g>
                                  </svg>
                                      <p>Settings</p>
                                      </div>
  
                                      <div className="chat-list-two-entry">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="17.356" height="16.479" viewBox="0 0 17.356 16.479">
                                      <g id="logout" transform="translate(0.5 0.5)">
                                        <path id="Path_72" data-name="Path 72" d="M8.3,0,4.151,4.151,0,0" transform="translate(12.206 11.733) rotate(-90)" fill="none" stroke="#333" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                        <path id="Path_73" data-name="Path 73" d="M-1811.926,597.942h11.474" transform="translate(1816.645 -590.36)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="1"/>
                                        <path id="Path_10015" data-name="Path 10015" d="M17.145,0H11.361A2.361,2.361,0,0,0,9,2.361V13.118a2.361,2.361,0,0,0,2.361,2.361h5.784" transform="translate(-9 0)" fill="none" stroke="#333" stroke-width="1"/>
                                      </g>
                                    </svg>
  
                                      <p>Logout</p>
                                      </div>
  
                                    </div>
  
                                    <div className="chat-preview-bottombar" style={{background:this.state.backgroundColor,borderTop: "1px solid "+this.state.borderColor}}>
  
                                      {/* <div className="bottombar-options">
                                        <div>
                                          <div>
                                          <img  src={require("../../assets/images/dashboard/zendesk-icon.png").default} alt="zendesk" className="img-fluid"/>
                                          </div>
                                          <p style={{color: "#000"}}>Home</p>
                                        </div>
                                      </div>
  
                                      <div className="bottombar-options">
                                        <div>
                                          <div>
                                          <img  src={require("../../assets/images/dashboard/zendesk-icon.png").default} alt="zendesk" className="img-fluid"/>
                                          </div>
                                          <p style={{color: "#000"}}>About</p>
                                        </div>
                                      </div>
  
                                      <div className="bottombar-options">
                                        <div>
                                          <div>
                                          <img  src={require("../../assets/images/dashboard/zendesk-icon.png").default} alt="zendesk" className="img-fluid"/>
                                          </div>
                                          <p style={{color: "#000"}}>Blog</p>
                                        </div>
                                      </div>
  
                                      <div className="bottombar-options">
                                        <div>
                                          <div>
                                          <img  src={require("../../assets/images/dashboard/zendesk-icon.png").default} alt="zendesk" className="img-fluid"/>
                                          </div>
                                          <p style={{color: "#000"}}>Watch</p>
                                        </div>
                                      </div>
  
                                      <div className="bottombar-options">
                                        <div>
                                          <div>
                                          <img  src={require("../../assets/images/dashboard/zendesk-icon.png").default} alt="zendesk" className="img-fluid"/>
                                          </div>
                                          <p style={{color: "#000"}}>Profile</p>
                                        </div>
                                      </div> */}
                                       {this.state.pewview_bottom_item && this.state.pewview_bottom_item.map((cmsRow, indx) => {
                                    return indx >= 0 && indx < this.state.setvalue ? 
                                    (
                                      <div className="bottombar-options">
                                        <div>
                                          <div>
                                          {this.state.bottomiconview || this.state.bottomiconview == 1 ?
                                          <>
                                            { cmsRow.item_icon_newtype != '' && cmsRow.item_icon_newtype !== undefined && cmsRow.item_icon_newtype === "flaticon" ?
                                              <img className="img-fluid" 
                                              style={{
                                                filter:
                                                  (this.state.setinputcolor === this.state.bottom_menu_items.length) &&
                                                  (cmsRow.item_icon_flaticon_color === "black" || cmsRow.item_icon_flaticon_color === undefined) && 
                                                  cmsRow.item_icon_newtype === 'flaticon' && this.state.selectedtextcolor !== "" ? 
                                                  cmsRow.is_default || cmsRow.is_default == 1 ? this.state.selectediconfilter : this.state.iconfilter
                                                  : null,
                                              }}
                                              src={cmsRow.item_icon != '' && cmsRow.item_icon != undefined && cmsRow.item_icon ? process.env.REACT_APP_Image_Path+
                                                    localStorage.getItem("user_id") +"/"+
                                                    localStorage.getItem("Appid")+
                                                    process.env.REACT_APP_NEW_ICON_PATH +
                                                    cmsRow.item_icon 
                                                  : require("../../assets/images/dashboard/create-bottom-bar.png").default} />
                                            : cmsRow.item_icon && cmsRow.item_icon != '' ?
                                                <img
                                                className="img-fluid"
                                                src={
                                                  process.env.REACT_APP_Image_Path +
                                                  (() => {
                                                    switch (cmsRow.item_icon_type) {
                                                      case "light":
                                                        return process.env.REACT_APP_light_path;
                                                      case "bold":
                                                        return process.env.REACT_APP_bold_path;
                                                      case "regular":
                                                        return process.env.REACT_APP_regular_path;
                                                      case "new":
                                                        return (
                                                          parseInt(
                                                            localStorage.getItem("user_id")
                                                          ) +
                                                          "/" +
                                                          parseInt(
                                                            localStorage.getItem("Appid")
                                                          ) +
                                                          process.env.REACT_APP_NEW_ICON_PATH
                                                        );
                                                      default:
                                                        return process.env.REACT_APP_light_path;
                                                    }
                                                  })() +
                                                  cmsRow.item_icon
                                                }
                                                alt="home-item"
                                                width="25"
                                              />
                                            :
                                              <img
                                              className="img-fluid"
                                              src={require("../../assets/images/dashboard/create-bottom-bar.png").default}
                                              alt="home-item"
                                              width="25"
                                              />
                                            }
                                          </>
                                          : <></>
                                          }
                                          </div>
                                          {this.state.bottomicontext || this.state.bottomicontext == 1 ?
                                            <p style={{color:cmsRow.is_default || cmsRow.is_default == 1 ? this.state.selectedicontextcolor :this.state.icontextcolor}}>{cmsRow.item_name}</p>
                                          : <></>
                                          } 
                                        </div>
                                      </div>
                                        )
                                        : (<></>)
                                      })
                                    }
  
                                  {this.state.pewview_bottom_item && this.state.pewview_bottom_item.length >= 6 ?
                                    <div className="bottombar-options">
                                      <div>
                                        {this.state.bottomiconview || this.state.bottomiconview == 1 ?
                                        <>
                                          {this.state.menuicon ?
                                            <img className="img-fluid" src={process.env.REACT_APP_Image_Path+
                                              localStorage.getItem("user_id") +"/"+localStorage.getItem("Appid")+process.env.REACT_APP_NEW_ICON_PATH +
                                              this.state.menuicon}/>  
                                                              
                                          : <img className="img-fluid" src={require("../../assets/images/dashboard/bottombar-more.png").default}/>
                                          }
                                          </>
                                          
                                        : <></>
                                        }
                                        {this.state.bottomicontext || this.state.bottomicontext == 1 ?
                                          <p style={{color: this.state.icontextcolor}}>{this.state.menutext}</p>
                                        : <></>
                                        } 
                                      
                                      </div>
                                    </div>
                                  : <></>
                                  }
  
                                    </div>
  
                                  </div>
                                : this.state.show_image == 3 ?
                                  <div className="chat-preview-screen-three">
                                    <div className="chat-preview-head-three">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12.518" height="12.519" viewBox="0 0 12.518 12.519">
                                    <g id="Group_39670" data-name="Group 39670" transform="translate(0.707 0.354)">
                                      <g id="Group_2097" data-name="Group 2097" transform="translate(0 0)">
                                        <path id="Path_6037" data-name="Path 6037" d="M0,5.906,5.906,0l5.906,5.906" transform="translate(0 11.812) rotate(-90)" fill="none" stroke="#333" stroke-miterlimit="10" stroke-width="1"/>
                                      </g>
                                      <path id="Path_9120" data-name="Path 9120" d="M-13838.824-23465.541h11.811" transform="translate(13838.824 23471.447)" fill="none" stroke="#333" stroke-miterlimit="10" stroke-width="1"/>
                                    </g>
                                  </svg>
                                      <h2>Settings</h2>
  
                                    </div>
  
                                    <div className="chat-preview-list-three">
  
                                      <div className="chat-preview-list-type-one">
                                        <h4>Push Notifications</h4>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="14" viewBox="0 0 25 14">
                                          <g id="Group_39674" data-name="Group 39674" transform="translate(-0.272 0.447)">
                                            <rect id="Rectangle_4415" data-name="Rectangle 4415" width="25" height="14" rx="7" transform="translate(0.272 -0.447)" fill="#a1a1a1"/>
                                            <circle id="Ellipse_2891" data-name="Ellipse 2891" cx="6" cy="6" r="6" transform="translate(12.272 0.553)" fill="#fff"/>
                                          </g>
                                        </svg>
                                      </div>
  
                                      <div className="chat-preview-list-type-two">
                                        <div>
                                          <h5>Site</h5>
                                          <p>appmysite.com</p>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11.571" height="10.371" viewBox="0 0 11.571 10.371">
                                          <g id="Group_39671" data-name="Group 39671" transform="translate(0 0.354)">
                                            <path id="Path_8624" data-name="Path 8624" d="M0,0,4.832,4.832,9.664,0" transform="translate(6.032 9.664) rotate(-90)" fill="rgba(0,0,0,0)" stroke="#a1a1a1" stroke-width="1"/>
                                            <path id="Path_9125" data-name="Path 9125" d="M-11679.105-23265.5h-10.863" transform="translate(11689.969 23270.332)" fill="none" stroke="#a1a1a1" stroke-width="1"/>
                                          </g>
                                        </svg>
                                      </div>
  
                                      <div className="chat-preview-list-type-two">
                                        <div>
                                          <h5>Currency</h5>
                                          <p>USD</p>
                                        </div>
                                      </div>
  
                                      <div className="chat-preview-list-type-two">
                                        <div>
                                          <h5>Language</h5>
                                          <p>English</p>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11.571" height="10.371" viewBox="0 0 11.571 10.371">
                                          <g id="Group_39671" data-name="Group 39671" transform="translate(0 0.354)">
                                            <path id="Path_8624" data-name="Path 8624" d="M0,0,4.832,4.832,9.664,0" transform="translate(6.032 9.664) rotate(-90)" fill="rgba(0,0,0,0)" stroke="#a1a1a1" stroke-width="1"/>
                                            <path id="Path_9125" data-name="Path 9125" d="M-11679.105-23265.5h-10.863" transform="translate(11689.969 23270.332)" fill="none" stroke="#a1a1a1" stroke-width="1"/>
                                          </g>
                                        </svg>
                                      </div>
  
                                      <div className="chat-preview-list-type-two">
                                        <div>
                                          <h5>Terms & Conditions</h5>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11.571" height="10.371" viewBox="0 0 11.571 10.371">
                                          <g id="Group_39671" data-name="Group 39671" transform="translate(0 0.354)">
                                            <path id="Path_8624" data-name="Path 8624" d="M0,0,4.832,4.832,9.664,0" transform="translate(6.032 9.664) rotate(-90)" fill="rgba(0,0,0,0)" stroke="#a1a1a1" stroke-width="1"/>
                                            <path id="Path_9125" data-name="Path 9125" d="M-11679.105-23265.5h-10.863" transform="translate(11689.969 23270.332)" fill="none" stroke="#a1a1a1" stroke-width="1"/>
                                          </g>
                                        </svg>
                                      </div>
                                    {this.state.show_chat_link_in_settings ?
                                      <div className="chat-preview-list-type-two chat-preview-list-type-two-gap">
                                        <div>
                                          <h5>{this.state.chat_link_text_in_settings && this.state.chat_link_text_in_settings != '' ? this.state.chat_link_text_in_settings : 'Chat With Us'}</h5>
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11.571" height="10.371" viewBox="0 0 11.571 10.371">
                                          <g id="Group_39671" data-name="Group 39671" transform="translate(0 0.354)">
                                            <path id="Path_8624" data-name="Path 8624" d="M0,0,4.832,4.832,9.664,0" transform="translate(6.032 9.664) rotate(-90)" fill="rgba(0,0,0,0)" stroke="#a1a1a1" stroke-width="1"/>
                                            <path id="Path_9125" data-name="Path 9125" d="M-11679.105-23265.5h-10.863" transform="translate(11689.969 23270.332)" fill="none" stroke="#a1a1a1" stroke-width="1"/>
                                          </g>
                                        </svg>
                                      </div>
                                    : <></>
                                    }
  
                                      <div className="chat-preview-list-type-two chat-preview-list-type-two-gap">
                                        <div>
                                          <h5>Delete Account</h5>
                                        </div>
                                      </div>
  
                                    </div>
                                  </div>
                                : <></>
                                }
                                </div>
                              </div>
  
                              <div className="chat-mob-btns">
                              <button
                                className={
                                  this.state.build_process_status === true
                                    ? "blockuser next_btn checknew newwidthd chat_next_btn"
                                    : "next_btn checknew chat_next_btn"
                                }
                                style={{backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                                form="formsubmit"
                                disabled={this.state.build_process_status}
                              >
                                <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                  <div className={this.state.loader}>
                                    
                                  </div>{" "}
                                  <div>
                                    {this.state.submitval}
                                  </div>
                                </div>
                              </button>
                              {this.state.last_updated_timestamp && this.state.last_updated_timestamp !== '' && this.state.last_updated_timestamp !== 'null' && this.state.last_updated_timestamp !== undefined ?
                                  <p>Updated  {moment(this.state.last_updated_timestamp).format(
                                            "D MMMM YYYY, HH:mm"
                                          )}{" "}</p>
                                : null
                                }
                              </div>
  
                            </div>
                          </form>
                        </>
                        )
                      }
                    </>
                  )}
              </div>
            </div>
            <RebuildRequired rebuildpop={this.state.rebuildpopvalue} btnRebuildCancel={this.closeRequireBuildPopup} submitform={this.submitfunction}/>
  
          {/* FlatICon Pop Up */}
  
          
          <IconSelectionPopUp 
            handleIconPopUp={this.handleIconPopUp} 
            saveIconName={this.saveIconName}
            openIconPopUp={this.state.openIconPopUp}
            widthForRestriction={128}
            heightForRestriction={128}
  
           />
          {/* Flat Icon Pop Up */}
          </section>
        </>
      )
  }
    </previewContext.Consumer>
    )
  }
}
