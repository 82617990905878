import React, { useEffect, useState, Suspense } from "react";
import styles from "./publish.module.css";
import Header from "../../Header";
import Tooltip from "../../Tooltip";
import Notappleaccount from "./Notappleaccount";

import { Media, Spinner } from "reactstrap";
import History from "./History";
import Storeconnection from "./Storeconnection";
import Leftpanel from "../../Leftpanel";
import { Publishget, Publishform } from "../../../Api/Publishapp/Publishappapi";
import { Creation } from "./Context";
import { Link } from "react-router-dom";
import Errormessage from "../../Reusable/Hookerror";
import { Notallowedtouser } from "../../User/Usernotalllowed";
import { InnerFoot } from "../../Foot";
const Notaddonactive = React.lazy(() => import("./Notaddonactive"));
export const Publishios = () => {
  const [errormodal, setErrormodal] = useState(false);
  const [title, settitle] = useState("");
  const [errormessage, seterrormessage] = useState("");
  const [count] = useState(1);
  const [submitval, setsubmit] = useState("Submit");
  const [submitreview, setsubmitreview] = useState("Submit for review");
  const [submitreviewloader, setsubmitreviewloader] = useState("d-none");
  const [loader, setLoader] = useState("d-none");
  const [loaderimage] = useState(
    require("../../../assets/images/signup/Loader_GIF.gif").default
  );
  // const [submitreviewloaderimage] = useState(require( "../../../assets/images/signup/Loader_GIF.gif").default);
  const [opencreationpopup, setPopup] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [data, setData] = useState(null);
  const [last_successful_build, setlast_successful_build] = useState(null);
  const [whatnew, setnew] = useState("");
  const [is_build_process, setis_build_process] = useState(0);
  const [publishing_logs, setpublishing_logs] = useState([]);
  const [code, setCode] = useState(200);
  const [plan_code, setplan_code] = useState("");
  const [addon_code, setaddon_code] = useState("");
  const [reseller_package_id, setreseller_package_id] = useState(null);
  const [enableScreen, setEnableScreen] = useState(null);
  const [devicetype, setDeviceType] = useState(null);
  const [appid, setAppid] = useState("");
  const [appuser_role_id, setappuser_role_id] = localStorage.getItem('appuser_role_id');
  const [appRightPanelExpand, setAppRightPanelExpand] = useState( localStorage.getItem("appSideBarCollapse") === true ||
	localStorage.getItem("appSideBarCollapse") === "true"
	  ? true
	  : false);
  const load = (hittype, isSubscribed) => {
    if (hittype === true) {
      const params = {
        app_id: btoa(localStorage.getItem("Appid")),
      };
      const interval = setTimeout(() => {
        Publishget(
          params,
          setData,
          setAppid,
          setSpinner,
          setlast_successful_build,
          setis_build_process,
          setpublishing_logs,
          setCode,
          setplan_code,
          setaddon_code,
          setreseller_package_id,
          setnew,
          load,
          setLoader,
          setsubmit,
          setEnableScreen,
          setDeviceType,
          setsubmitreview,
          setsubmitreviewloader
        );
      }, 120000);
      return () => clearTimeout(interval);
    } else {
      setSpinner(true);

      const params = {
        app_id: btoa(localStorage.getItem("Appid")),
      };
      Publishget(
        params,
        setData,
        setAppid,
        setSpinner,
        setlast_successful_build,
        setis_build_process,
        setpublishing_logs,
        setCode,
        setplan_code,
        setaddon_code,
        setreseller_package_id,
        setnew,
        load,
        setLoader,
        setsubmit,
        setEnableScreen,
        setDeviceType,
        setsubmitreview,
        setsubmitreviewloader
      );
    }
  };

  useEffect(() => {
    document.title = "Publish";
    load(false);
  }, [count]);

  const sendparam = {
    opencreationpopup: opencreationpopup,
    setPopup: setPopup,
    load: load,
    data: data,
  };
  const handlenew = (e) => {
    let value = e.target.value.replace(/[^\w\s]/gi, "");
    setnew(value);
  };
  const publishform = (e, submitvalue) => {
    e.preventDefault();

    const params = {
      app_id: parseInt(localStorage.getItem("Appid")),
      action: "step2",
      submit_for_review: submitvalue,
      whats_new: whatnew,
    };
    if (is_build_process === 0 && last_successful_build === null) {
      settitle("Create Build");
      setErrormodal(true);
      seterrormessage("You have not created a build yet ");
    } else {
      if (is_build_process === 0) {
        if (whatnew === "") {
          settitle("What's new in this version");
          setErrormodal(true);
          seterrormessage("Please fill this field");
        } else {
          if (last_successful_build !== null && data !== null) {
            if (data.status !== 4 || data.status !== 1 || data.status !== 2) {
              Publishform(
                params,
                setLoader,
                setsubmit,
                load,
                setsubmitreview,
                setsubmitreviewloader
              );
            }
          } else {
            Publishform(
              params,
              setLoader,
              setsubmit,
              load,
              setsubmitreview,
              setsubmitreviewloader
            );
          }
        }
      } else {
        setErrormodal(true);
        settitle("Build Processing");
        seterrormessage("Your build is still processing.");
      }
    }
  };

  const handleappcreationpopup = (e) => {
    if (is_build_process === 0) {
      setPopup(true);
    } else {
      setErrormodal(true);
      settitle("Build Processign");
      seterrormessage("Your build is still processing.");
    }
  };

  const Errortext = {
    title: title,
    errormessage: errormessage,
    errormodal: errormodal,
    setErrormodal: setErrormodal,
  };

  const handleRightPanelExpand = (value) => {

		setAppRightPanelExpand(value);
	  }


  return (
    <>
      {errormodal === true && <Errormessage value={Errortext} />}
      <Creation.Provider value={sendparam}>
        <Storeconnection />
      </Creation.Provider>

      <div className="d-md-block">
        <Header customclass={"header-responsive-new"} />
      </div>
      <section className="dasboard_page mobile-view-download mobile-display">
      <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
      <div className={`right-panel-gap ${(localStorage.getItem("appSideBarCollapse") !== undefined &&
                      localStorage.getItem("appSideBarCollapse") !== null&&
                      localStorage.getItem("appSideBarCollapse") === "true") ?
                          " right-panel-gap-expand": ""}`}>
          {spinner === false && (
            <>
              {code === 200 && data !== null && (
                <>
                  {data.status === 3 && (
                    <div className="d-flex justify-content-between showwebstrip align-items-center pl-3 pr-3 flex-wrap mb-md-3 mt-2 mt-md-0">
                      <div className="d-flex align-items-center">
                        <img
                          src={
                            require("../../../assets/images/dashboard/alertstrip.png")
                              .default
                          }
                          alt="alert"
                          className="mr-1"
                        />
                        <h6 className="m-0 p-0">
                          Your last submission failed.
                        </h6>
                      </div>
                    </div>
                  )}

                  {data.status === 4 && data.submit_for_review === 0 && (
                    <div
                      className="d-flex justify-content-between showwebstrip align-items-center pl-3 pr-3 flex-wrap mb-md-3 mt-2 mt-md-0"
                      style={{ borderColor: "#91E6AA", background: "#F8FFFA" }}
                    >
                      <div className="d-flex align-items-start">
                        <img
                          src={
                            require("../../../assets/images/dashboard/submited.png")
                              .default
                          }
                          alt="alert"
                          className="mr-1"
                        />
                        <h6 className="m-0 p-0" style={{ color: "#1B2952" }}>
                          Your last submission was successful.
                        </h6>
                      </div>
                    </div>
                  )}
                  {data.status === 4 && data.submit_for_review === 1 && (
                    <div
                      className="d-flex justify-content-between showwebstrip align-items-center pl-3 pr-3 flex-wrap mb-md-3 mt-2 mt-md-0"
                      style={{ borderColor: "#91E6AA", background: "#F8FFFA" }}
                    >
                      <div className="d-flex align-items-start">
                        <img
                          src={
                            require("../../../assets/images/dashboard/submited.png")
                              .default
                          }
                          alt="alert"
                          className="mr-1"
                        />
                        <h6 className="m-0 p-0" style={{ color: "#1B2952" }}>
                          Your last submission was successful.
                        </h6>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
          <div
            className={
              "right_panel custon_gap2 right-panel-publish pb-4 pt-md-4 pt-0 " +
              styles.hideback
            }
            style={{ minHeight: "80vh" }}
          >
            <div className="right_general">
              <div className="row">
                {spinner ? (
                  <div className="newspinner">
                  <svg xmlns="http://www.w3.org/2000/svg" 
            xlink="http://www.w3.org/1999/xlink" 
            style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
            width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
              <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
              style={{animationplaystate: "running", animationdelay: "0s"}}>
              </animateTransform>
            </circle>
            </g>
            <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
            style={{animationplaystate: "running", animationdelay: "0s"}}>
            </animateTransform>
            </circle>
            </g>
            <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
            style={{animationplaystate: "running", animationdelay: "0s"}}>
    
            </animateTransform>
            </circle>
            </g>
            <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
            style={{animationplaystate: "running", animationdelay: "0s"}}>
    
            </animateTransform>
            </circle>
            </g>
            </svg>
                </div>
                ) : code === 203 ? (
                  <div className="col-md-12">
                    <Suspense fallback={""}>
                      <div className="d-flex align-items-center justify-content-between top-portion alt-portion publish-portion">
                        <div className="app_iconname d-none d-md-block">
                          <h3 className="mb-2 new-publish-margin">Publish</h3>
                          <h5 className="d-none d-md-block">
                            Automatically publish your iOS app to the Apple App
                            Store.
                            {/* <Media
                              href="https://www.appmysite.com/support/auto-publish-to-app-store/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="align-items-center mx-1 kb-link"
                            >
                              Learn More{" "}
                              <img
                                src={
                                  require("../../../assets/images/dashboard/Goto.png")
                                    .default
                                }
                                className="gotoappicon appear-spacing"
                                alt="Goto"
                              />
                            </Media>{" "} */}
                          </h5>
                        </div>
                      </div>
                      {/* {(appuser_role_id == 3 || appuser_role_id == 4) ?
                       Notallowedtouser() 
                      : */}
                       <Notaddonactive
                        value={plan_code}
                        reseller={reseller_package_id}
                        appuser_role_id={appuser_role_id}
                        addon_code={addon_code}
                        />
                       {/* } */}
                    </Suspense>
                  </div>
                ) : enableScreen === 0 ? (
                  <div className="col-md-12 publish-new-spacing">
                    <Suspense fallback={""}>
                      <div className="d-flex align-items-center justify-content-between top-portion alt-portion publish-portion">
                        <div className="app_iconname d-none d-md-block">
                          <h3 className="mb-2 new-publish-margin">Publish</h3>
                          <h5 className="d-none d-md-block">
                            Automatically publish your iOS app to the Apple App
                            Store.
                            {/* <Media
                              href="https://www.appmysite.com/support/auto-publish-to-app-store/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="align-items-center mx-1 kb-link"
                            >
                              Learn More{" "}
                              <img
                                src={
                                  require("../../../assets/images/dashboard/Goto.png")
                                    .default
                                }
                                className="gotoappicon appear-spacing"
                                alt="Goto"
                              />
                            </Media>{" "} */}
                          </h5>
                        </div>
                      </div>
                      {/* {(appuser_role_id == 3 || appuser_role_id == 4) ?
                       Notallowedtouser() 
                      : */}
                        <Notaddonactive
                          value={plan_code}
                          reseller={reseller_package_id}
                          appuser_role_id={appuser_role_id}
                          addon_code={addon_code}
                          
                        />
                      {/* } */}
                    </Suspense>
                  </div>
                ) : (
                  <div className="col-md-12">
                    <section className={styles.header}>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="app_iconname d-none d-md-block">
                          <h3 className="mb-2">Publish</h3>
                          <h5 className="d-none d-md-block">
                            Automatically publish your iOS app to the Apple App
                            Store.
                            {/* <Media
                              href="https://www.appmysite.com/support/auto-publish-to-app-store/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="align-items-center mx-1 kb-link"
                            >
                              Learn More{" "}
                              <img
                                src={
                                  require("../../../assets/images/dashboard/Goto.png")
                                    .default
                                }
                                className="gotoappicon appear-spacing"
                                alt="Goto"
                              />
                            </Media>{" "} */}
                          </h5>
                        </div>
                        {
                          <>
                            {data !== null &&
                              (data.status === 0 ||
                                data.status === 3 ||
                                (last_successful_build !== null &&
                                  data.build_trigger_id !==
                                    last_successful_build.id)) && (
                                <div className="fixed-button">
                                  <div className="d-flex justify-content-between">
                                    {/* style={{width:"200px"}} */}
                                    <button
                                      className="next_btn w-100"
                                      onClick={(e) => publishform(e, 0)}
                                    >
                                      <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                        <div className={loader}>
                                          <img src={loaderimage} alt="loader" />
                                        </div>{" "}
                                        <div>{submitval}</div>
                                      </div>
                                    </button>
                                    {/* <button className="next_btn" style={{background:"none",color:"#3064F9",paddingLeft:"0px",paddingRight:"0px",width:"200px"}} onClick={e => publishform(e, 1)} >
                                                                        
                                                                    <div className="d-flex align-items-center justify-content-center h-100 w-100"> 
                                                                        <div className={submitreviewloader}> <Spinner animation="border" variant="primary" style={{width:"20px", height:"20px"}}/></div> <div>{submitreview}</div>
                                                                    </div>	
                                                                       
                                                                </button>  */}
                                  </div>
                                </div>
                              )}
                            {code === 200 &&
                              data !== null &&
                              (data.status === 1 || data.status === 2) && (
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={
                                        require("../../../assets/images/dashboard/ezgif.gif")
                                          .default
                                      }
                                      alt="App Build"
                                      className="img-fluid mr-2"
                                      width="40"
                                    />
                                    <h6
                                      className={`${styles.font} "m-0" "p-0"`}
                                      style={{
                                        color: "#1B2952",
                                        fontSize: "15px",
                                      }}
                                    >
                                      Processing upload
                                    </h6>
                                  </div>
                                </div>
                              )}
                          </>
                        }
                      </div>
                    </section>
                    <section>
                      {data === null ? (
                        <Creation.Provider value={sendparam}>
                          <Notappleaccount />
                        </Creation.Provider>
                      ) : (
                        <>
                          <div className={"mb-5 " + styles.divwidth}>
                            <div
                              className={
                                "d-flex justify-content-between align-items-center " +
                                styles.appheading
                              }
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  src={
                                    require("../../../assets/images/dashboard/apple-logo.png")
                                      .default
                                  }
                                  alt="notanyapp"
                                  className="img-fluid mr-2"
                                  width="32"
                                />
                                <h2>iOS App</h2>
                              </div>
                              <div
                                className={styles.appstore}
                                onClick={(e) => handleappcreationpopup(e)}
                              >
                                <svg
                                  width="19.247"
                                  height="17"
                                  viewBox="0 0 19.247 17"
                                >
                                  <path
                                    id="Path_9711"
                                    data-name="Path 9711"
                                    d="M158.166,211.173h0l-.93,1.61a1.229,1.229,0,0,1-2.128-1.229l.685-1.186.066-.114a1.014,1.014,0,0,1,.986-.405s1.363.148,1.462.857A.829.829,0,0,1,158.166,211.173Zm13.184-4.112h-2.9a.428.428,0,0,1-.317-.125l0,0-3.1-5.374,0,0-.186-.267c-.3-.466-.789.726-.789.726a4.166,4.166,0,0,0,.312,3.3l4.31,7.465a1.229,1.229,0,1,0,2.128-1.229l-1.078-1.867c-.021-.045-.057-.168.164-.169h1.464a1.229,1.229,0,1,0,0-2.457Zm-5.63,1.67s.155.787-.444.787H154.561a1.229,1.229,0,0,1,0-2.457h2.755c.445-.026.55-.283.55-.283h0l3.6-6.229h0a.274.274,0,0,0,0-.252l-1.188-2.057a1.229,1.229,0,0,1,2.128-1.229l.551.954.55-.952a1.229,1.229,0,0,1,2.128,1.229l-5,8.667c-.022.053-.029.136.134.15h2.991v.029A2.009,2.009,0,0,1,165.72,208.731Z"
                                    transform="translate(-153.332 -196.398)"
                                    fill="#7782a1"
                                  />
                                </svg>
                                <p>App store connection</p>
                                {appid !== "" && (
                                  <img
                                    src={
                                      require("../../../assets/images/dashboard/app-verified.png")
                                        .default
                                    }
                                    alt="active"
                                    className="img-fluid d-none d-md-block"
                                    style={{
                                      position: "relative",
                                      left: "10px",
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                            <div className={styles.create_connection}>
                              <div className="color_picker w-100 mt-4">
                                <div className="form-group">
                                  {/* Tool tip */}
                                  <Tooltip
                                    firsttag={"h4"}
                                    tagclass={"mb-0 mr-2"}
                                    classdata={
                                      "d-flex justify-content-start align-items-center mb-1"
                                    }
                                    heading={"iOS Build version "}
                                    content={
                                      " Select the iOS build version you wish to deploy. "
                                    }
                                  />
                                  {/* Tool tip */}
                                  <div className="d-flex align-items-center justify-content-between form-control form-name">
                                    {is_build_process === 0 &&
                                      last_successful_build === null && (
                                        <input
                                          type="text"
                                          style={{
                                            background: "none",
                                            border: "none",
                                            flex: 1,
                                            color: "#FF4848",
                                            fontSize: "13px",
                                          }}
                                          disabled
                                          value="You have not created a build yet "
                                        />
                                      )}
                                    {is_build_process === 0 &&
                                      last_successful_build !== null && (
                                        <input
                                          type="text"
                                          style={{
                                            background: "none",
                                            border: "none",
                                          }}
                                          disabled
                                          value={
                                            last_successful_build.build_release
                                          }
                                        />
                                      )}
                                    {is_build_process === 1 && (
                                      <input
                                        type="text"
                                        style={{
                                          background: "none",
                                          border: "none",
                                          color: "#FF4848",
                                          flex: 1,
                                          fontSize: "13px",
                                        }}
                                        disabled
                                        value="Your build is still processing."
                                      />
                                    )}
                                    {is_build_process === 0 &&
                                      last_successful_build !== null &&
                                      data.build_trigger_id !==
                                        last_successful_build.id && (
                                        <div>
                                          <p
                                            className="p-0 m-0"
                                            style={{
                                              color: "#7782A1",
                                              fontSize: "13px",
                                            }}
                                          >
                                            This build can be published.
                                          </p>
                                        </div>
                                      )}
                                    {is_build_process === 0 &&
                                      last_successful_build !== null &&
                                      data.build_trigger_id ===
                                        last_successful_build.id &&
                                      data.status === 4 && (
                                        <div>
                                          {data.submit_for_review === 0 && (
                                            <p
                                              className="p-0 m-0"
                                              style={{
                                                color: "#7782A1",
                                                fontSize: "13px",
                                              }}
                                            >
                                              This build has already been
                                              uploaded.
                                            </p>
                                          )}
                                          {data.submit_for_review === 1 && (
                                            <p
                                              className="p-0 m-0"
                                              style={{
                                                color: "#7782A1",
                                                fontSize: "13px",
                                              }}
                                            >
                                              This build has already been
                                              uploaded.
                                            </p>
                                          )}
                                        </div>
                                      )}
                                    {is_build_process === 1 && (
                                      <div>
                                        <Link
                                          to="/download-app"
                                          className="p-0 m-0"
                                          style={{
                                            color: "#3064F9",
                                            fontSize: "13px",
                                          }}
                                        >
                                          Go to downloads
                                        </Link>
                                      </div>
                                    )}
                                    {is_build_process === 0 &&
                                      last_successful_build === null && (
                                        <div>
                                          <Link
                                            to="/download-app"
                                            className="p-0 m-0"
                                            style={{
                                              color: "#3064F9",
                                              fontSize: "13px",
                                            }}
                                          >
                                            Go to downloads
                                          </Link>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                              <div className="color_picker w-100 mt-4">
                                <div className="form-group">
                                  {/* Tool tip */}
                                  <Tooltip
                                    firsttag={"h4"}
                                    tagclass={"mb-0 mr-2"}
                                    classdata={
                                      "d-flex justify-content-start align-items-center mb-1"
                                    }
                                    heading={"What’s new in this version"}
                                    content={
                                      "Describe what's new in this version of your app, such as new features, improvements, and bug fixes.This will appear on your app store listing."
                                    }
                                  />
                                  {/* Tool tip */}
                                  <textarea
                                    className="form-control"
                                    rows="4"
                                    value={whatnew}
                                    onChange={(e) => handlenew(e)}
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* History */}
                          <Creation.Provider value={publishing_logs}>
                            <History />
                          </Creation.Provider>
                        </>
                      )}
                    </section>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* {InnerFoot()} */}
        </div>
      </section>
    </>
  );
};
