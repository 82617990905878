import React from 'react'; 
import { GetuserprofileApi } from "../Api/Dashboard/DashboardApi";
import {Verifyemailaccount} from "../Api/Authenticate/Api"
class Verifyemailstatus extends React.PureComponent {
    constructor(props){
        super(props);
        this.state = {
            status:"",
            token:"",
            verifytext:"Send verification email"
        }
    }
    componentDidMount(){
        GetuserprofileApi(this);
        this.setState({
            token:localStorage.getItem("amstoken")
        })
    }
    handlesend = () => {
        const params = {
            token:this.state.token,
            check:"check"
        }
        Verifyemailaccount(params,this)
    }
    render(){
        return(
            <>
            {this.state.status === 0 && (<div className="verify-account add-color d-block d-md-flex w-100 align-items-center  mt-2 mt-md-0 justify-content-between">
                      <div className="verify-alert d-flex align-items-start align-items-md-center">
                          <img src={require('../assets/images/dashboard/alertstrip.png').default} className="img-fluid" alt="alert" />
                          <p>Your account has not been verified. Click on the account verification link sent to you on email.</p>
                      </div>
                      <div className="resend-verify-email">
                              <div className="resend-email" onClick={e=>this.handlesend(e)}>{this.state.verifytext}</div>
                      </div>
             </div>
            )}
            </>
        )
    }
} 
export default React.memo(Verifyemailstatus)
