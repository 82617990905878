import axios from "axios";
import { errortoast, sucesstoast } from "../../Dashboard/Toaster";
const Url = process.env.REACT_APP_API_URL + "api/app/";
const cmsUrl = process.env.REACT_APP_API_URL + "api/cms/";
const RedirectUrl = process.env.REACT_APP_cors_api;

const Loader = (state) => {
  state.setState({
    submitval: "",
    loader: "loader",
  });
};

const returnSelectedModuleName = (module_name) => {
  let label = "";
  if (module_name === "home_screen") {
    label = "Home screen";
  } else if (module_name === "product_cat") {
    label = "Product categories";
  } else if (module_name === "blog_cat") {
    label = "Blog categories";
  } else if (module_name === "product_list") {
    label = "Product list";
  } else if (module_name === "product_details") {
    label = "Product details";
  } else if (module_name === "cart") {
    label = "Cart";
  } else if (module_name === "page_list") {
    label = "Page list";
  } else if (module_name === "page_details") {
    label = "Page details";
  } else if (module_name === "blog_details") {
    label = "Blog details";
  } else if (module_name === "post_details") {
    label = "Post details";
  } else if (module_name === "post_list") {
    label = "Post list";
  }
  else if (module_name === "blog_list") {
    label = "Post list";
  }
  else if (module_name === "post_cat") {
    label =parseInt(localStorage.getItem("website_technology"))===3?"Categories" : "Blog categories";
  }
  else if (module_name === "web_view") {
    label = "Web view";
  }
  return label;
};

// Get Api Advertisement
export const getAdvertisementData = (state, filterVal, callback,param) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(
      Url + `get-app-monetization-data/${btoa(localStorage.getItem("Appid"))}?page=${param?param.page:1}&limit=${param?param.limit:20}${(filterVal ? filterVal : "")}`,
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        if (
          state.state.adId !== undefined &&
          state.state.adId !== "undefined" &&
          state.state.adId !== null &&
          state.state.adId !== "null" &&
          state.state.adId !== "create"
        ) {
          // For Edit screen
          let editData = {};
          res.data.data &&
            res.data.data.map((row) => {
              if (row.id === parseInt(atob(state.state.adId))) {
                editData = row;
              }
            });

          let finalLocationArr = [];
          editData.location &&
            JSON.parse(editData.location) &&
            JSON.parse(editData.location).map((res) => {
              let obj = {
                module_name: res.module_name,
                subdropdownData: [],
                ad_position: res.ad_position,
                item: res.item,
                module_label: returnSelectedModuleName(res.module_name),
                subdropdownSpinner: false,
                customAppPagedData: [],
                breadcrumbArr:[],
                temp_module_label:returnSelectedModuleName(res.module_name),
                temp_module_name:res.module_name,
                temp_item:res.item,
                temp_subData:[],
                temp_breadcrumbArr:[],
                showL1Options:true,
                temp_showL1Options:true,
                isAllowPagination:true,
                page:1,
              };
              finalLocationArr.push(obj);
            });
          state.setState({
            eachAdPosition:editData.ad_position!==undefined&&editData.ad_position!==null?editData.ad_position:0,
            adName: editData.name,
            adType: editData.type,
            oldAdType:editData.type,
            androidAdUnitId: editData.android_ad_unit_id,
            updatedAt: editData.updated_at?editData.updated_at:"",
            iosAdUnitId: editData.ios_ad_unit_id,
            androidAdScript: editData.android_ad_custom_script,
            iosAdScript: editData.ios_ad_custom_script,
            // adRatio : editData.custom_ad_width?editData.custom_ad_width:"50",
            custom_ad_height:editData.custom_ad_height?editData.custom_ad_height:"50",
            custom_ad_width:editData.custom_ad_width?editData.custom_ad_width:"50",
            locationArr: editData.location && JSON.parse(editData.location) && JSON.parse(editData.location).length > 0
              ? finalLocationArr
              : [
                  {
                    module_name: "web_view",
                    item: "",
                    ad_position: "top",
                    module_label: "Web view",
                    subdropdownSpinner: false,
                    subdropdownData: [],
                    customAppPagedData: [],
                    breadcrumbArr:[],
                    temp_module_label:"Web view",
                    temp_module_name:"web_view",
                    temp_item:"",
                    temp_subData:[],
                    temp_breadcrumbArr:[],
                    showL1Options:true,
                    temp_showL1Options:true,
                    isAllowPagination:true,
                    page:1,
                  },
                ],
          });
        }
        state.setState({
          //theme
          appOpenTypeAdCount:res.data.app_open_type_ad_count,
          fitBanner:res.data.settings &&
          res.data.settings.fit_banner_ad_in_full_screen === 1 ? true : false,
          theme: res.data.theme,
          plan: res.data.plan,
          //checbox
          spinner: false,
          // listingData:
          //   res.data.data === undefined || res.data.data === null
          //     ? null
          //     : res.data.data,
          is_enable_screen:
            res.data.is_enable_screen === undefined
              ? 0
              : res.data.is_enable_screen,
          hitNextPagination : (res.data.data.length > 0 && res.data.data.length===param.limit) ?true:false,
          bottomSpinner:false,
        });

        
        let ntfMergeData = [];
        if(param && param.page > 1){
          ntfMergeData = param.advertisementData.concat(res.data.data);
          state.setState({
            listingData:ntfMergeData
          })
        }else{
          state.setState({
            listingData:res.data.data,
          })
        }
        if (callback) {
          callback();
        }
      }
    });
};

//save Api
export const SaveAdvertisement = (params, state, status) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "save-app-monetization-data", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        // state.hitonload();
        sucesstoast("Your changes have been saved.");
        state.setState({
          submitval: "Publish",
          isLoader: "d-none",
          submitval2: "Save",
          isLoader2: "d-none",
          updatedAt: res.data.data.updated_at  ? res.data.data.updated_at : "",
        });
        state.redirectFun(res.data.data.id, status);
      } else {
        errortoast("Oh no! Something’s not right.");
        state.setState({
          submitval: "Publish",
          isLoader: "d-none",
          submitval2: "Save",
          isLoader2: "d-none",
        });
      }
    })
    .catch(() => {
      state.setState({
        submitval: "Publish",
        isLoader: "d-none",
        submitval2: "Save",
        isLoader2: "d-none",
      });
      errortoast("Oh no! Something’s not right.");
    });
};

//Delete Api
export const deleteAdvertisement = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "delete-app-monetization-data", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        // state.hitonload();
        sucesstoast("Ad deleted sucessfully.");

        state.setState({
          runInitialLoad: !state.state.runInitialLoad,
        });
      } else {
        errortoast("Oh no! Something’s not right.");
        state.setState({
          runInitialLoad: !state.state.runInitialLoad,
        });
      }
    })
    .catch(() => {
      state.setState({
        runInitialLoad: !state.state.runInitialLoad,
      });
      errortoast("Oh no! Something’s not right.");
    });
};

//Setting Api
export const saveAdvertisementSetting = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "save-app-monetization-settings", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        // state.hitonload();
        sucesstoast("Your changes have been saved.");

        state.setState({
          submitval: "Save",
          isLoader: "d-none",
          updateAt:res.data.data.updated_at,
        });
      } else {
        errortoast("Oh no! Something’s not right.");
        state.setState({
          submitval: "Save",
          isLoader: "d-none",
        });
      }
    })
    .catch(() => {
      state.setState({
        submitval: "Save",
        isLoader: "d-none",
      });
      errortoast("Oh no! Something’s not right.");
    });
};

//Get Setting Api
export const getAdvertisementSetting = (state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(
      Url +
        `get-app-monetization-settings/${btoa(localStorage.getItem("Appid"))}`,
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          settingSpinner:false,
          fitBanner:res.data.settings &&
            res.data.settings.fit_banner_ad_in_full_screen === 1 ? true : false,
          androidEnableAd:res.data.android_signing_data &&
            res.data.android_signing_data.is_admob_enabled === 1 ? true : false,
          androidAdmobId:res.data.android_signing_data ? res.data.android_signing_data.admob_app_id:"",
          iosEnableAd:res.data.ios_signing_data &&
            res.data.ios_signing_data.is_admob_enabled === 1 ? true : false,
          iosAdmobId:res.data.ios_signing_data ? res.data.ios_signing_data.admob_app_id:"",
          iosBundleId: res.data.ios_bundle_id,
          androidBundleId: res.data.android_bundle_id,
          updateAt: res.data.updated_at,
          plan:res.data.plan,
        });
      } else {
      }
    })
    .catch((err) => {

    });
};

export const UserlistForAd = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(
      Url +
        "user-management/" +
        params.app_id +
        "?page=1&limit=100&search_field=&timestamp=" +
        new Date().getTime(),
      // +"&allow_listing_for_all=1"
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        let userDataArr = res.data.data;
        let ownerObj = {
          image: res.data.owner_data.profile_image,
          profile_name: res.data.owner_data.name,
          status: 1,
          user_id: res.data.owner_data.id,
          invitation_email: res.data.owner_data.email,
        };
        userDataArr.unshift(ownerObj);

        state.setState({
          mergeUserOwnerData: userDataArr,
        });
      } else if (res.data.code === 401) {
      } else {
      }
    })
    .catch((error) => {});
};

export const cmsGetPostsApiForAd = (state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(cmsUrl + `getPosts/${btoa(localStorage.getItem("Appid"))}?page=1&limit=99`, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          customPosts: res.data.data,
        });
      }
    })
    .catch((err) => {
      // errortoast("Oh no! Something’s not right.");
   
    });
};

export const cmsGetPagesApiForAd = (state) => {
  let newArr = [];

  function recurs(arr) {
    arr = arr.map((eachPage, indx) => {
    
      newArr.push(eachPage);

      if (eachPage.child !== undefined && eachPage.child.length > 0) {
        // if parent has childrens
        recurs(eachPage.child);
      }

      return arr;
    });
  }
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(cmsUrl + `getPages/${btoa(localStorage.getItem("Appid"))}?page=1&limit=99`, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        recurs(res.data.data);
        state.setState({
          customPages:newArr,
        });
      }else{
        state.setState({
          customPages:newArr,
        });
      }
    })
    .catch((err) => {
      // errortoast("Oh no! Something’s not right.");
     
    });
};

export const cmsGetAtrApiForAd = (state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(cmsUrl + `get-attributes/${btoa(localStorage.getItem("Appid"))}?page=1&limit=99`, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          customAtrData: res.data.data,
        });
      }
    })
    .catch((err) => {
      // errortoast("Oh no! Something’s not right.");

    });
};



export const cmsGetPostsApiForAdItemType = (state, indx,from,page,limit) => {

  state.setState({
    paginationSpinner:page?true:false,
  })

  let newData = [];
  let newLocationArr = [...state.state.locationArr];
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(cmsUrl + `getPosts/${btoa(localStorage.getItem("Appid"))}?page=${page?page:1}&limit=${limit?limit:99}`, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {

        state.setState({
          paginationSpinner:false,
        });



        newData = res.data.data;

        newLocationArr[indx].page = page?page:1;
        newLocationArr[indx].isAllowPagination = ( res.data.data && res.data.data.length > 0 && res.data.data.length >= limit )  ? true : false;
        newLocationArr[indx].subdropdownData = (page && page > 1 ) ? newLocationArr[indx].subdropdownData.concat(newData) :newData;
        newLocationArr[indx].subData = (page && page > 1 )?newLocationArr[indx].subData.concat(newData) :newData;
        newLocationArr[indx].temp_subData =(page && page > 1 )?newLocationArr[indx].temp_subData.concat(newData) :newData;


        newLocationArr[indx].temp_breadcrumbArr = [];
        newLocationArr[indx].breadcrumbArr = [];
        newLocationArr[indx].subdropdownSpinner = false;
        newLocationArr[indx].temp_showL1Options =from==="not-initial"?false:
        ( newLocationArr[indx].breadcrumbArr.length > 0 || (newLocationArr[indx].item &&newLocationArr[indx].item!=="all" ) )?false : true;
        newLocationArr[indx].temp_module_label = returnSelectedModuleName(
          newLocationArr[indx].temp_module_name
        );
        newLocationArr[indx].module_label = returnSelectedModuleName(
          newLocationArr[indx].module_name
        );

        state.setState({
          locationArr: newLocationArr,
        });
      } else {
   
      
        newLocationArr[indx].subdropdownData = newData;
        newLocationArr[indx].subData = newData;
        newLocationArr[indx].temp_subData = newData;
        newLocationArr[indx].temp_breadcrumbArr = [];
        newLocationArr[indx].breadcrumbArr = [];
        newLocationArr[indx].subdropdownSpinner = false;
        newLocationArr[indx].showL1Options = true;
        newLocationArr[indx].temp_showL1Options = true;
        newLocationArr[indx].temp_module_label = returnSelectedModuleName(
          newLocationArr[indx].temp_module_name
        );
        newLocationArr[indx].module_label = returnSelectedModuleName(
          newLocationArr[indx].module_name
        );

        state.setState({
          locationArr: newLocationArr,
        });
  
      }
    })
    .catch((err) => {
      newLocationArr[indx].subdropdownData = newData;
      newLocationArr[indx].subData = newData;
      newLocationArr[indx].temp_subData = newData;
      newLocationArr[indx].temp_breadcrumbArr = [];
      newLocationArr[indx].breadcrumbArr = [];
      newLocationArr[indx].subdropdownSpinner = false;
      newLocationArr[indx].showL1Options = true;
      newLocationArr[indx].temp_showL1Options = true;
      newLocationArr[indx].temp_module_label = returnSelectedModuleName(
        newLocationArr[indx].temp_module_name
      );
      newLocationArr[indx].module_label = returnSelectedModuleName(
        newLocationArr[indx].module_name
      );

      state.setState({
        locationArr: newLocationArr,
      });
      // errortoast("Oh no! Something’s not right.");

    });
};

export const cmsGetPagesApiForAdItemType = (state, indx,from,page,limit) => {

  state.setState({
    paginationSpinner:page?true:false,

  })

  let newArrForPreview = [];
  let newBreadcrumb=[];
  let newSubData=[];
  let parentId="";
  let allPages =[];
  let newLocationArr = [...state.state.locationArr];

  function recursForPreview(arr) {
    arr = arr.map((eachPage, indx1) => {
      let obj = { value: "", label: "", id: "" };
      obj.id = eachPage._id;
      obj.value = eachPage._id;
      obj.label = eachPage.title;
      newArrForPreview.push(eachPage);
   
      if (eachPage.child !== undefined && eachPage.child.length > 0) {
        // if parent has childrens
        recursForPreview(eachPage.child);
      }

      return arr;
    });
  }

  function recursForBreadcrumb(arr) {

    arr.map((eachPage) => {
      let obj = {
        name:"",
        id:"",
      }
    
      if(eachPage._id === parentId){
        parentId = eachPage.parent;
        obj.id = eachPage._id;
        obj.name = eachPage.title;
        newBreadcrumb.push(obj);

      }

     if (eachPage.child !== undefined && eachPage.child.length > 0) {
       // if parent has childrens
       recursForBreadcrumb(eachPage.child);
     }

     // return arr;
   });
 }

 function recursData(arr) {
  arr.map((eachPage) => {
  
   if(newBreadcrumb.length > 0 && eachPage.parent===newBreadcrumb[newBreadcrumb.length-1].id){
 
    newSubData.push(eachPage);
   }

   if (eachPage.child !== undefined && eachPage.child.length > 0) {
     // if parent has childrens
     recursData(eachPage.child);
   }

   // return arr;
 });
}

function recurs(arr) {
  arr && arr.map((eachPage)=>{

    recursForBreadcrumb(allPages);
   if (eachPage.child !== undefined && eachPage.child.length > 0) {
     recurs(eachPage.child);
   }
  })
}


  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(cmsUrl + `getPages/${btoa(localStorage.getItem("Appid"))}?page=${page?page:1}&limit=${limit?limit:99}`, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {

        state.setState({
          paginationSpinner:false,
        });

      allPages=res.data.data
      recursForPreview(allPages);
      parentId=newLocationArr[indx].item;

      if(!page || page <= 1){

        recurs(allPages);

      }
     
      newBreadcrumb.splice(0,1)
        newBreadcrumb.reverse();

        recursData(allPages);


        if(!newBreadcrumb || newBreadcrumb.length===0){

          newLocationArr[indx].subdropdownData = (page && page > 1 )?newLocationArr[indx].subdropdownData.concat(allPages) :allPages;
          newLocationArr[indx].subData = (page && page > 1 )?
          (newSubData.length===0 ? newLocationArr[indx].subData.concat(allPages):newLocationArr[indx].subData.concat(newSubData)) :
          (newSubData.length===0 ? allPages: newSubData );
          newLocationArr[indx].temp_subData = (page && page > 1 )?
          (newSubData.length===0 ? newLocationArr[indx].temp_subData.concat(allPages):newLocationArr[indx].temp_subData.concat(newSubData)) :
          (newSubData.length===0 ? allPages: newSubData );
        }else{
          
          newLocationArr[indx].subdropdownData = allPages;
          newLocationArr[indx].subData = (newSubData.length===0 ? allPages: newSubData );
          newLocationArr[indx].temp_subData = (newSubData.length===0 ? allPages: newSubData );
        }

        newLocationArr[indx].page = page?page:1;
        newLocationArr[indx].isAllowPagination = ( res.data.data && res.data.data.length > 0 && res.data.data.length >= limit )  ? true : false;
        newLocationArr[indx].temp_breadcrumbArr = newBreadcrumb;
        newLocationArr[indx].breadcrumbArr = newBreadcrumb;
        newLocationArr[indx].customAppPagedData = newLocationArr[indx].customAppPagedData.concat(newArrForPreview);
        newLocationArr[indx].subdropdownSpinner = false;
        
        newLocationArr[indx].temp_showL1Options =from==="not-initial"?false:
        ( newLocationArr[indx].breadcrumbArr.length > 0 || (newLocationArr[indx].item &&newLocationArr[indx].item!=="all" ) )?false : true;
        newLocationArr[indx].temp_module_label = returnSelectedModuleName(
          newLocationArr[indx].temp_module_name
        );
        newLocationArr[indx].module_label = returnSelectedModuleName(
          newLocationArr[indx].module_name
        );
        state.setState({
          locationArr: newLocationArr,
        });
      } else {


        let newLocationArr = [...state.state.locationArr];
        newLocationArr[indx].subdropdownData = [];
        newLocationArr[indx].subData = [];
        newLocationArr[indx].temp_subData = [];
        newLocationArr[indx].temp_breadcrumbArr = [];
        newLocationArr[indx].breadcrumbArr = [];
        newLocationArr[indx].subdropdownSpinner = false;
        newLocationArr[indx].showL1Options = true;
        newLocationArr[indx].temp_showL1Options = true;
        newLocationArr[indx].temp_module_label = returnSelectedModuleName(
          newLocationArr[indx].temp_module_name
        );
        newLocationArr[indx].module_label = returnSelectedModuleName(
          newLocationArr[indx].module_name
        );
        state.setState({
          locationArr: newLocationArr,
        });
      }
    })
    .catch((err) => {
      // errortoast("Oh no! Something’s not right.");
      let newLocationArr = [...state.state.locationArr];
      newLocationArr[indx].subdropdownData = [];
      newLocationArr[indx].subData = [];
      newLocationArr[indx].temp_subData = [];
      newLocationArr[indx].subdropdownSpinner = false;
      newLocationArr[indx].showL1Options =true;
    
      newLocationArr[indx].temp_showL1Options = true;
    
      newLocationArr[indx].temp_breadcrumbArr = [];
      newLocationArr[indx].breadcrumbArr = [];
      newLocationArr[indx].temp_module_label = returnSelectedModuleName(
        newLocationArr[indx].temp_module_name
      );
      newLocationArr[indx].module_label = returnSelectedModuleName(
        newLocationArr[indx].module_name
      );
      state.setState({
        locationArr: newLocationArr,
      });
      
    });
};

export const restBaseTaxonomyapiAd = (rest_base, state, indx, from,page) => {
  state.setState({
    paginationSpinner:page?true:false,
  })
  let newLocationArr = [...state.state.locationArr];
  let newData = [];

  const convertHTMLEntity = (text) => {
    const span = document.createElement("span");

    if (text !== undefined) {
      return text.replace(/&[#A-Za-z0-9]+;/gi, (entity) => {
        span.innerHTML = entity;
        return span.innerText;
      });
    }
  };
  // let subdropdowndata=[];
  const headers = {
    "Content-Type": "application/json",
    'X-App-Device-Type':'ios',
    'X-App-Code-Ver':localStorage.getItem("Apiversion"),
  };
  axios
    .get(
      `${RedirectUrl}${state.state.storeUrl.replace("www.", "")}/wp-json/wp/v2/${rest_base}?page=${page?page:1}%26per_page=99`,
      {
        headers: headers,
        data:{},
      }
    )
    .then((res) => {
      if (res.status === 200) {
        newData = res.data;
        state.setState({
          paginationSpinner:false,
        });


        newLocationArr[indx].page = page?page:1;
        newLocationArr[indx].isAllowPagination = ( res.data && res.data.length > 0 && res.data.length > 98 )  ? true : false;
        newLocationArr[indx].subdropdownData = ( page && page > 1)?newLocationArr[indx].subdropdownData.concat(newData) :newData;
        newLocationArr[indx].subData = ( page && page > 1)?newLocationArr[indx].subData.concat(newData) :newData;
        newLocationArr[indx].temp_subData = ( page && page > 1)?newLocationArr[indx].temp_subData.concat(newData) :newData;



        newLocationArr[indx].temp_breadcrumbArr = [];
        newLocationArr[indx].breadcrumbArr = [];
        newLocationArr[indx].subdropdownSpinner = false;
        newLocationArr[indx].temp_showL1Options =from==="not-initial"?false:
        ( newLocationArr[indx].breadcrumbArr.length > 0 || (newLocationArr[indx].item &&newLocationArr[indx].item!=="all" ) )?false : true;
        newLocationArr[indx].temp_module_label = returnSelectedModuleName(
          newLocationArr[indx].temp_module_name
        );
        newLocationArr[indx].module_label = returnSelectedModuleName(
          newLocationArr[indx].module_name
        );
        state.setState({
          locationArr: newLocationArr,
        });
      } else {
        newLocationArr[indx].subdropdownData = newData;
        newLocationArr[indx].subData = newData;
        newLocationArr[indx].temp_subData = newData;
        newLocationArr[indx].temp_breadcrumbArr = [];
        newLocationArr[indx].breadcrumbArr = [];
        newLocationArr[indx].subdropdownSpinner = false;
        newLocationArr[indx].showL1Options = true;
        newLocationArr[indx].temp_showL1Options = true;
        newLocationArr[indx].temp_module_label = returnSelectedModuleName(
          newLocationArr[indx].temp_module_name
        );
        newLocationArr[indx].module_label = returnSelectedModuleName(
          newLocationArr[indx].module_name
        );

        state.setState({
          locationArr: newLocationArr,
          paginationSpinner:false,
        });
      }
    })
    .catch((err) => {
      newLocationArr[indx].subdropdownData = newData;
      newLocationArr[indx].subData = newData;
      newLocationArr[indx].temp_subData = newData;
      newLocationArr[indx].temp_breadcrumbArr = [];
      newLocationArr[indx].breadcrumbArr = [];
      newLocationArr[indx].subdropdownSpinner = false;
      newLocationArr[indx].showL1Options = true;
      newLocationArr[indx].temp_showL1Options = true;
      newLocationArr[indx].temp_module_label = returnSelectedModuleName(
        newLocationArr[indx].temp_module_name
      );
      newLocationArr[indx].module_label = returnSelectedModuleName(
        newLocationArr[indx].module_name
      );

      state.setState({
        locationArr: newLocationArr,
        paginationSpinner:false,
      });

    });
};

export const productCategoriesTreeAPIForAds = (state,indx,from) => {
  let newArrForPreview = [];
  let newBreadcrumb=[];
  let newSubData=[];
  let parentId="";
  let allPages =[];
  let newLocationArr = [...state.state.locationArr];



  function recursForBreadcrumb(arr) {

    arr.map((eachPage) => {
      let obj = {
        name:"",
        id:"",
      }
    
      if(eachPage.term_id == parentId){
        parentId = eachPage.parent;
        obj.id = eachPage.term_id.toString();
        obj.name = eachPage.name;
        newBreadcrumb.push(obj);

      }

     if (eachPage.children !== undefined && eachPage.children.length > 0) {
       // if parent has childrenrens
       recursForBreadcrumb(eachPage.children);
     }

     // return arr;
   });
 }

 function recursData(arr) {
  arr.map((eachPage) => {
  
   if(newBreadcrumb.length > 0 && eachPage.parent == newBreadcrumb[newBreadcrumb.length-1].id){
 
    newSubData.push(eachPage);
   }

   if (eachPage.children !== undefined && eachPage.children.length > 0) {
     // if parent has childrenrens
     recursData(eachPage.children);
   }

   // return arr;
 });
}

function recurs(arr) {
  arr && arr.map((eachPage)=>{

    recursForBreadcrumb(allPages);
   if (eachPage.children !== undefined && eachPage.children.length > 0) {
     recurs(eachPage.children);
   }
  })
}
  const headers = {
    "Content-Type": "application/json",
    // Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(`${state.state.storeUrl}/wp-json/wc/v3/ams-categories`, {
      headers: headers,
    })
    .then((res) => {
      if (res.status === 200) {
      allPages=res.data;
    
      parentId=newLocationArr[indx].item;
      recurs(allPages);
     
    
      newBreadcrumb.splice(0,1)
        newBreadcrumb.reverse();
      

        recursData(allPages);
        newLocationArr[indx].subdropdownData = allPages;
        newLocationArr[indx].subData = newSubData.length===0?allPages:newSubData;
        newLocationArr[indx].temp_subData = newSubData.length===0?allPages:newSubData;
        newLocationArr[indx].temp_breadcrumbArr = newBreadcrumb;
        newLocationArr[indx].breadcrumbArr = newBreadcrumb;
        newLocationArr[indx].customAppPagedData = newArrForPreview;
        newLocationArr[indx].subdropdownSpinner = false;
        // newLocationArr[indx].showL1Options =from==="not-initial"?false:
        // ( newLocationArr[indx].breadcrumbArr.length > 0 || (newLocationArr[indx].item &&newLocationArr[indx].item!=="all" ) )?false : true;
        newLocationArr[indx].temp_showL1Options =from==="not-initial"?false:
        ( newLocationArr[indx].breadcrumbArr.length > 0 || (newLocationArr[indx].item &&newLocationArr[indx].item!=="all" ) )?false : true;
        newLocationArr[indx].temp_module_label = returnSelectedModuleName(
          newLocationArr[indx].temp_module_name
        );
        newLocationArr[indx].module_label = returnSelectedModuleName(
          newLocationArr[indx].module_name
        );
        state.setState({
          locationArr: newLocationArr,
        });
      } else {


        let newLocationArr = [...state.state.locationArr];
        newLocationArr[indx].subdropdownData = [];
        newLocationArr[indx].subData = [];
        newLocationArr[indx].temp_subData = [];
        newLocationArr[indx].temp_breadcrumbArr = [];
        newLocationArr[indx].breadcrumbArr = [];
        newLocationArr[indx].subdropdownSpinner = false;
        newLocationArr[indx].showL1Options = true;
        newLocationArr[indx].temp_showL1Options = true;
        newLocationArr[indx].temp_module_label = returnSelectedModuleName(
          newLocationArr[indx].temp_module_name
        );
        newLocationArr[indx].module_label = returnSelectedModuleName(
          newLocationArr[indx].module_name
        );
        state.setState({
          locationArr: newLocationArr,
        });
      }
    })
    .catch((err) => {
      // errortoast("Oh no! Something’s not right.");
      let newLocationArr = [...state.state.locationArr];
      newLocationArr[indx].subdropdownData = [];
      newLocationArr[indx].subData = [];
      newLocationArr[indx].temp_subData = [];
      newLocationArr[indx].subdropdownSpinner = false;
      newLocationArr[indx].showL1Options =true;
    
      newLocationArr[indx].temp_showL1Options = true;
    
      newLocationArr[indx].temp_breadcrumbArr = [];
      newLocationArr[indx].breadcrumbArr = [];
      newLocationArr[indx].temp_module_label = returnSelectedModuleName(
        newLocationArr[indx].temp_module_name
      );
      newLocationArr[indx].module_label = returnSelectedModuleName(
        newLocationArr[indx].module_name
      );
      state.setState({
        locationArr: newLocationArr,
      });

    });
};

//To Get Bottom menu > Types and toxonomies

export const advertisementTypeApi = (state, callback) => {
  const headers = {
    "Content-Type": "application/json",
    'X-App-Device-Type':'ios',
    'X-App-Code-Ver':localStorage.getItem("Apiversion"),
  };
  axios
    .get(
      RedirectUrl +
        state.state.storeUrl.replace("www.", "") +
        "/wp-json/wp/v2/types?page=1%26per_page=99",
      {
        headers: headers,
        data:{},
      }
    )
    .then((res) => {
      state.setState({
        typesData: res.data,
        spinner2: false,
      });
      if (callback !== undefined) {
        callback();
      }
    })
    .catch((err) => {
      state.setState({
        spinner2: false,
      });
     
    });
};

export const advertisementTaxonomyApi = (state,callback) => {
  const headers = {
    "Content-Type": "application/json",
    'X-App-Device-Type':'ios',
    'X-App-Code-Ver':localStorage.getItem("Apiversion"),
  };
  axios
    .get(
      RedirectUrl +
        state.state.storeUrl.replace("www.", "") +
        "/wp-json/wp/v2/taxonomies?page=1%26per_page=99",
      {
        headers: headers,
        data:{},
      }
    )
    .then((res) => 
    {
      state.setState({
        taxonomyData: res.data,
      });
      
      if (callback !== undefined) {
        callback();
      }
    })
    .catch((err) => {
      state.setState({
        spinner2: false,
    
      });

    });
};