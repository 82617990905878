import React from "react";
import Header from "../Header";
import { Link, Redirect } from "react-router-dom";
import Leftpanel from "../Leftpanel";
import { Foot } from "../Foot";
import {
  GetpackageAll,
  DowngradeApi,
  SendpaymentinfotoserverApi,
  Makestrippayment,
  workspacePlanListing,
  MakestripWorkspacePayment,
} from "../../Api/Dashboard/DashboardApi";
import { Spinner, Modal, ModalHeader, ModalBody } from "reactstrap";
import styles from "../Appdownload/subscription.module.css";

export default class PriceCategories extends React.PureComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef(); // Create a ref object
    this.state = {
      monthlydata: [],
      yearlydata: [],
      lifetimedata: [],
      spinner: false,
      filter: "yearly",
      mactive: false,
      yactive: true,
      subscriptionboolen: "",
      currency: "",
      zoho_plan_code: "",
      subscription_id: "",
      appid: "",
      one_time: [],
      user_status: "",
      build_process_status: "",
      discount: 0,
      appuser_role_id: null,
      deluser: false,
      popupdata: null,
      loader_remove: "d-none",
      loaderimage_remove: require("../../assets/images/dashboard/loader.gif")
        .default,
      submitval_confirmpop: "Yes",
      mobilefilter: "Preview",
      payment_methods: [],
      customer_billing_type: null,
      lifetimecheck: 0,
      // handleDealsPage: 0,
      tab_check: "Preview",
      allowPopUp: false,
      plan_switching: "",
      web_technology: "",
      isPlan: 1,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = "Pricing";
  }

  allowPopUpFunc = () => {
    this.setState({
      allowPopUp: !this.state.allowPopUp,
    });
    // localStorage.setItem("switch_plan_restrict", "");
  };

  allowPopUpFunc2 = () => {
    window.location.pathname =`/workspace/pricing/${btoa(localStorage.getItem("workspaceId"))}`;
  };

  handleSeePlans = (e, web_technology) => {
    
    this.setState({
      web_technology: web_technology,
    });
    workspacePlanListing(this, web_technology);
  };

  handlescroll = () => {
    const { index, selected } = this.props;
    if (index === selected) {
      this.myRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  render() {
    if (this.state.appuser_role_id === 3 || this.state.appuser_role_id === 4) {
      this.props.history.push("/app/dashboard");
    }

    if (this.state.plan_switching === "ok") {
      window.location.pathname = `/plan-listing/${this.props.match.params.id}/${this.state.web_technology}`;
    }

    return (
      <>
        <div className="">
          <Header customclass={"header-responsive-new"} />
        </div>
        {/* Mobile View */}
       
        {/* Mobile View End*/}
        <section className="dasboard_page" style={{ background: "none" }}>
          {/* <div className="d-md-none d-block">
            <Header customclass={"header-responsive-new"} />
          </div> */}
          {this.state.spinner ? (
            <div className="spinn">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <>
              <div
                className={
                  "transparent_background upgradepage plans-bottompadding " +
                  styles.makechange
                }
              >
                <div className="subscriptions-top-head">
                  <div className={styles.setbackground + " pricing-background"}>
                    <div
                      className={
                        styles.widthset +
                        " card-container-width card-container-width-mobile"
                      }
                    >
                      <div className="mt-md-3 mt-0 pb-3 pb-md-0 d-md-flex d-block justify-content-center subscription-plan-head-mobile">
                        <div className={styles.heading}>
                          <h3 className="pricing-mb-5 subscriptions-new-head subscriptions-new-head-mobile">
                            Plans for Everyone
                          </h3>
                        </div>
                      </div>
                      <div className="common-container">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="price-card price-card-new">
                              <div className="icon-mb-3">
                                <img
                                  src={
                                    require("../../assets/images/dashboard/web.png")
                                      .default
                                  }
                                  alt="web"
                                />
                              </div>
                              <h3>Website to App</h3>
                              <p>
                                Convert any website into a native iOS & Android
                                mobile app via web views
                              </p>
                              <h6 className="onward-plans">
                                {/* <span>$3</span>per month onword */}
                              </h6>
                              <Link
                                onClick={(e) => this.handleSeePlans(e, 2)}
                                // to={`/plan-listing/${this.props.match.params.id}/2`}
                                className={
                                  styles.getstartedactive + " select-price"
                                }
                              >
                                See Plans
                              </Link>
                              <p className="rest-api-info">
                                Web View Integration
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="price-card price-card-new word">
                              <div className="icon-mb-3">
                                <img
                                  src={
                                    require("../../assets/images/dashboard/wordpress.png")
                                      .default
                                  }
                                  alt="web"
                                />
                              </div>
                              <h3>WordPress to App</h3>
                              <p>
                                Convert any Wordpress website into a native iOS
                                & Android mobile app
                              </p>
                              <h6 className="onward-plans">
                                {/* <span>$19</span>per month onword */}
                              </h6>
                              <Link
                                onClick={(e) => this.handleSeePlans(e, 1)}
                                // to={`/plan-listing/${this.props.match.params.id}/1`}
                                className={
                                  styles.getstartedactive + " select-price"
                                }
                              >
                                See Plans
                              </Link>
                              <p className="rest-api-info">
                                REST API Integration
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="price-card price-card-new woo">
                              <div className="icon-mb-3">
                                <img
                                  src={
                                    require("../../assets/images/dashboard/woo.png")
                                      .default
                                  }
                                  alt="web"
                                />
                              </div>
                              <h3>WooCommerce to App</h3>
                              <p>
                                Convert any WooCommerce website into a native
                                iOS & Android mobile app
                              </p>
                              <h6 className="onward-plans">
                                {/* <span>$19</span>per month onword */}
                              </h6>
                              <Link
                                onClick={(e) => this.handleSeePlans(e, 1)}
                                // to={`/plan-listing/${this.props.match.params.id}/1`}
                                className={
                                  styles.getstartedactive + " select-price"
                                }
                              >
                                See Plans
                              </Link>
                              <p className="rest-api-info">
                                REST API Integration
                              </p>
                            </div>
                          </div>
                          {/* <div className="col-md-4 mt-4">
                            <div className="price-card price-card-new woo">
                              <div className="icon-mb-3">
                                <img
                                  src={
                                    require("../../assets/images/dashboard/new-custom.png")
                                      .default
                                  }
                                  alt="web"
                                />
                              </div>
                              <h3>Custom App</h3>
                              <p>
                                Create a powerful native iOS and Android app,
                                without a website.
                              </p>
                              <h6 className="onward-plans"></h6>
                              <Link
                                onClick={(e) => this.handleSeePlans(e, 3)}
                                className={
                                  styles.getstartedactive + " select-price"
                                }
                              >
                                See Plans
                              </Link>
                              <p className="rest-api-info">
                                REST API Integration
                              </p>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      {/* Summary start */}
                    </div>
                  </div>
                  <div className="customer-reviews common-container customer-reviews-mobile">
                    <div className="customer-review-text customer-review-text-mobile">
                      <h3>Here’s what our customers are saying.</h3>
                      <p>
                        More than 200,000 customers love and trust AppMySite.
                        Partner with the best app builder software and reach
                        your business goals faster.
                      </p>
                    </div>
                    <div className="customer-review-stars">
                      <img
                        src={
                          require("../../assets/images/dashboard/pop-ratings.png")
                            .default
                        }
                        className="pop-ratings-img d-md-none d-block"
                      />
                      <div className="review-section-first d-none d-md-block">
                        <div className="trustpilot-review trustpilot-review-mobile">
                          <img
                            className="trustpilot-img"
                            src={
                              require("../../assets/images/dashboard/trustpilot-review.png")
                                .default
                            }
                          />
                        </div>
                        <div className="gplay-review gplay-review-mobile">
                          <img
                            className="gplay-img"
                            src={
                              require("../../assets/images/dashboard/gplay-review.png")
                                .default
                            }
                          />
                        </div>
                      </div>
                      <div className="review-section-second d-none d-md-block">
                        <div className="capterra-review capterra-review-mobile">
                          <img
                            className="capterra-img"
                            src={
                              require("../../assets/images/dashboard/capterra-review.png")
                                .default
                            }
                          />
                        </div>
                        <div className="wp-review wp-review-mobile">
                          <img
                            className="wp-img"
                            src={
                              require("../../assets/images/dashboard/wp-review.png")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="awards-container">
                    {/* <h5>We have been recognized by G2</h5> */}
                    <a
                      rel="nofollow"
                      target="_blank"
                      href="https://www.g2.com/products/appmysite/reviews"
                    >
                      {" "}
                      <img
                        src={
                          require("../../assets/images/dashboard/awards.png")
                            .default
                        }
                        // width="521px"
                        // height="145px"
                        className="d-none d-md-block"
                      />
                      <img
                        src={
                          require("../../assets/images/dashboard/awards-mobile.png")
                            .default
                        }
                        // width="521px"
                        // height="145px"
                        className="d-block d-md-none"
                      />
                    </a>
                  </div>
                  <div className="featured-platforms featured-platforms-mobile">
                    {/* <h5>AppMySite has been featured on</h5> */}
                    <div className="featured-platforms-icons featured-platforms-icons-mobile">
                      {/* <div className="pf-group pf-group-left"> */}
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../../assets/images/dashboard/wpengine-icon.png")
                              .default
                          }
                        />
                      </div>
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../../assets/images/dashboard/wpexplorer-icon.png")
                              .default
                          }
                        />
                      </div>
                      {/* </div> */}
                      {/* <div className="pf-group pf-group-right"> */}
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../../assets/images/dashboard/themeisle-icon.png")
                              .default
                          }
                        />
                      </div>
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../../assets/images/dashboard/designrush-icon.png")
                              .default
                          }
                        />
                      </div>
                      {/* </div> */}
                      {/* <div className="pf-group pf-group-left"> */}
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../../assets/images/dashboard/astra-icon.png")
                              .default
                          }
                        />
                      </div>
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../../assets/images/dashboard/superbthemes-icon.png")
                              .default
                          }
                        />
                      </div>
                      {/* </div> */}
                      {/* <div className="pf-group pf-group-right"> */}
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../../assets/images/dashboard/upcity-icon.png")
                              .default
                          }
                        />
                      </div>
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../../assets/images/dashboard/cybernews-icon.png")
                              .default
                          }
                        />
                      </div>
                      {/* </div> */}
                      {/* <div className="last-pf-group-mobile"> */}
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../../assets/images/dashboard/elegantthemes-icon.png")
                              .default
                          }
                        />
                      </div>
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../../assets/images/dashboard/androidheadlines-icon.png")
                              .default
                          }
                        />
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </section>
        {/* Swithing Plan Restricton Pop up */}
        <Modal
          isOpen={this.state.allowPopUp}
          toggle={this.allowPopUpFunc}
          style={{ maxWidth: "600px", maxHeight: "247px" }}
          className={this.props.className}
          centered
          dataclass="countrycode"
        >
          <ModalHeader
            toggle={this.allowPopUpFunc}
            className="modal-xxl-content"
          >
            <div className="crossmodal mt-1" onClick={this.allowPopUpFunc}>
              <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                <path
                  id="Union_18"
                  data-name="Union 18"
                  d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                  transform="translate(0.529 0.531)"
                  fill="#1b2952"
                  stroke="#1b2952"
                  strokeWidth="1.5"
                />
              </svg>
            </div>
          </ModalHeader>
          <ModalBody className="completesteps delete_apps">
            <img
              src={
                require("../../assets/images/dashboard/technology.png").default
              }
              alt="step"
            />
            <h4>Subscription not compatible</h4>
            <p className="mt-2">
              It appears that your Workspace contains apps that are not
              compatible with this subscription type. Please choose another
              plan.
            </p>
            <div className="buttonbox">
              <p onClick={this.allowPopUpFunc}>OKAY</p>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
