import React from "react";

const Expand = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="4"
      viewBox="0 0 30 4"
    >
      <rect
        id="Rectangle_29336"
        data-name="Rectangle 29336"
        width="30"
        height="4"
        rx="2"
        fill="#fff"
        opacity="0.2"
      />
    </svg>
  );
};

export default Expand;
