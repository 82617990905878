import React, { useEffect, useState } from "react";
import "./Theme.css";
import goback from "../../../assets/images/dashboard/goback.svg";
import goforward from "../../../assets/images/dashboard/goforward.svg";
import Tooltip from "../../../Components/commonIcons/Tooltip";
import UploadTagnotSelected from "../../../assets/images/dashboard/themeiconupload.svg";
import crossicon from "../../../assets/images/dashboard/crossicon.svg";
import IconSelectionPopUp from "../../../Dashboard/Reusable/IconSelectionPopUp";
import { Carousel } from "react-responsive-carousel";
import {
  HEADER_ICONS_ARRAY,
  LISTING_ICONS_ARRAY,
  PROFILE_ICONS_ARRAY,
  SETTINGS_ICONS_ARRAY,
} from "../../../Constants/AppTheme/icons";
import "../../../assets/css/Dashboard.css";
import gallery1 from "../../../assets/images/dashboard/cms/gallery1.png";
import gallery2 from "../../../assets/images/dashboard/cms/gallery2.png";
import gallery3 from "../../../assets/images/dashboard/cms/gallery3.png";
import gallery4 from "../../../assets/images/dashboard/cms/gallery4.png";
import imagebackground3 from "../../../assets/images/dashboard/cms/list-preview-banner.png";
import ProductDefaultImage from "../../../assets/images/dashboard/defaulttumbnail.svg";
import ProductImagePaginationIcon from "../../../assets/images/dashboard/bottomcrouseldot.svg";
import StarRatingIcon from "../../../assets/images/dashboard/starRatingIcon.svg";
import DarkStarIcon from "../../../assets/images/dashboard/darkStar.svg";
import themeForwardArrowImg from "../../../assets/images/dashboard/comments-arrow.webp";
import profileImg from "../../../assets/images/dashboard/profileUser.png";
import Newfeatureicon from "../../../Components/commonIcons/Newfeatureicon";

const ThemeIcons = ({
  iconData,
  setUpdatedHeaderPayload,
  setUpdatedListingPayload,
  setUpdatedProfilePayload,
  setUpdatedSettingsPayload,
  generatePayload,
  headerBackgroundColor,
  textColor,
  seperatorColor,
  iconFilter,
  allBlack,
  setAllBlack,
}) => {
  const [updatedHeaderIcons, setUpdatedHeaderIcons] =
    useState(HEADER_ICONS_ARRAY);
  const [updatedListingIcons, setUpdatedListingIcons] =
    useState(LISTING_ICONS_ARRAY);
  const [updatedProfileIcons, setUpdatedProfileIcons] =
    useState(PROFILE_ICONS_ARRAY);
  const [updatedSettingsIcons, setUpdatedSettingsIcons] =
    useState(SETTINGS_ICONS_ARRAY);
  const [iconsCategory, setIconsCategory] = useState(updatedHeaderIcons);
  const [index, setIndex] = useState();
  const [iconPopup, setIconPopup] = useState({
    openIconPopUp: false,
    isDoneBtnDisabled: false,
  });
  const [activeSlide, setActiveSlide] = useState(0);
  const [iconType, setIconType] = useState("");
  const [selectedflaticoncolor, setSelectedflaticoncolor] = useState(true);

  const getItemObject = (iconName, type) => {
    const iconObject =
      type == "listingScreen"
        ? updatedListingIcons.find((icon) => icon.name === iconName)
        : type == "headerScreen"
        ? updatedHeaderIcons?.find((icon) => icon.name === iconName)
        : type == "settingsScreen"
        ? updatedSettingsIcons.find((icon) => icon.name === iconName)
        : updatedProfileIcons.find((icon) => icon.name === iconName);
    return iconObject?.item_icon;
  };

  const handleIconPopUp = (idx, type, iconName) => {
    setIndex(idx);
    setIconPopup((prevIconPopup) => ({
      openIconPopUp: !prevIconPopup.openIconPopUp,
      isDoneBtnDisabled: false,
    }));

    if (type === "headerIcons") {
      setIconType("header");
      iconName == "Search icon"
        ? setActiveSlide(1)
        : iconName == "Share icon" ||
          iconName == "Bookmark icon" ||
          iconName == "Back icon"
        ? setActiveSlide(2)
        : iconName == "Cart icon" ||
          iconName == "Wishlist icon" ||
          iconName == "Profile icon"
        ? setActiveSlide(3)
        : setActiveSlide(0);
      setIconsCategory(updatedHeaderIcons);
    } else if (type === "listingIcons") {
      setIconType("listing");
      setActiveSlide(1);
      setIconsCategory(updatedListingIcons);
    } else if (type === "profileIcons") {
      setIconType("profile");
      setActiveSlide(0);
      setIconsCategory(updatedProfileIcons);
    } else if (type === "settingIcons") {
      setIconType("settings");
      setActiveSlide(4);
      setIconsCategory(SETTINGS_ICONS_ARRAY);
    } else {
      setActiveSlide(activeSlide);
    }
  };

  const callbackiconshow = (
    iconsavefilename,
    selectedflaticoncolor,
    iconType
  ) => {
    setIconsCategory((iconsCategory[index].item_icon = iconsavefilename));
    if (iconType == "header") {
      setUpdatedHeaderIcons((prevIcons) => {
        const newIcons = [...prevIcons];
        newIcons[index].item_icon = iconsavefilename;
        newIcons[index].icon_color = selectedflaticoncolor;
        return newIcons;
      });
    } else if (iconType == "listing") {
      setUpdatedListingIcons((prevIcons) => {
        const newIcons = [...prevIcons];
        newIcons[index].item_icon = iconsavefilename;
        newIcons[index].icon_color = selectedflaticoncolor;
        return newIcons;
      });
    } else if (iconType == "profile") {
      setUpdatedProfileIcons((prevIcons) => {
        const newIcons = [...prevIcons];
        newIcons[index].item_icon = iconsavefilename;
        newIcons[index].icon_color = selectedflaticoncolor;
        return newIcons;
      });
    } else {
      setUpdatedSettingsIcons((prevIcons) => {
        const newIcons = [...prevIcons];
        newIcons[index].item_icon = iconsavefilename;
        newIcons[index].icon_color = selectedflaticoncolor;
        return newIcons;
      });
    }
  };

  const saveIconName = (iconsavefilename, selectedflaticoncolor) => {
    callbackiconshow(iconsavefilename, selectedflaticoncolor, iconType);
    setIconPopup({
      openIconPopUp: !iconPopup.openIconPopUp,
      isDoneBtnDisabled: false,
    });
  };

  const setIconToDefault = (idx, type, iconName) => {
    if (type === "headerIcons") {
      iconName == "Search icon"
        ? setActiveSlide(1)
        : iconName == "Share icon" ||
          iconName == "Bookmark icon" ||
          iconName == "Back icon"
        ? setActiveSlide(2)
        : iconName == "Cart icon" ||
          iconName == "Wishlist icon" ||
          iconName == "Profile icon"
        ? setActiveSlide(3)
        : setActiveSlide(0);
      setSelectedflaticoncolor(!selectedflaticoncolor);
      setIconsCategory((prevIconsCategory) => {
        const updatedIconsCategory = [...updatedHeaderIcons];
        updatedIconsCategory[idx].item_icon =
          HEADER_ICONS_ARRAY[idx].initial_item_icon;
        updatedIconsCategory[idx].icon_color = "black";

        setUpdatedHeaderIcons(updatedIconsCategory);
        return updatedIconsCategory;
      });
    } else if (type == "listingIcons") {
      setActiveSlide(1);

      setIconsCategory((prevIconsCategory) => {
        const updatedIconsCategory = [...updatedListingIcons];
        updatedIconsCategory[idx].item_icon =
          LISTING_ICONS_ARRAY[idx].initial_item_icon;
        updatedIconsCategory[idx].icon_color = "black";
        setUpdatedListingIcons(updatedIconsCategory);
        return updatedIconsCategory;
      });
    } else if (type == "profileIcons") {
      setActiveSlide(0);

      setIconsCategory((prevIconsCategory) => {
        const updatedIconsCategory = [...updatedProfileIcons];
        updatedIconsCategory[idx].item_icon =
          PROFILE_ICONS_ARRAY[idx].initial_item_icon;
        updatedIconsCategory[idx].icon_color = "black";
        setUpdatedProfileIcons(updatedIconsCategory);
        return updatedIconsCategory;
      });
    } else {
      setActiveSlide(4);

      setIconsCategory((prevIconsCategory) => {
        const updatedIconsCategory = [...updatedSettingsIcons];
        updatedIconsCategory[idx].item_icon =
          SETTINGS_ICONS_ARRAY[idx].initial_item_icon;
        updatedIconsCategory[idx].icon_color = "black";
        setUpdatedSettingsIcons(updatedIconsCategory);
        return updatedIconsCategory;
      });
    }
  };

  const handlesliderChange = (e, type) => {
    if (type === "back" && e > 0) {
      setActiveSlide(e - 1);
    } else if (type === "front" && e < 4) {
      setActiveSlide(e + 1);
    }
  };

  async function fetchAndUpdateIcons(data) {
    const updateArray = (array, mapping) => {
      return array.map((item) => {
        const mappingItem = mapping[item.name];
        if (mappingItem) {
          return {
            ...item,
            item_icon: mappingItem.name,
            icon_color: mappingItem.is_black == 1 ? "black" : "",
          };
        }
        return item;
      });
    };

    const headerMapping = {
      "Hamburger menu icon": data?.header_hamburger_menu_icon,
      "Back icon": data?.header_back_icon,
      "Search icon": data?.header_search_icon,
      "Share icon": data?.header_share_icon,
      "Wishlist icon": data?.header_wishlist_icon,
      "Bookmark icon": data?.header_bookmark_icon,
      "Notification icon": data?.header_notification_icon,
      "Cart icon": data?.header_cart_icon,
      "Profile icon": data?.header_profile_icon,
    };

    const listingMapping = {
      "Sort icon": data?.listing_sort_icon,
      "Filter icon": data?.listing_filter_icon,
      "List view icon": data?.listing_list_view_icon,
      "Grid view icon": data?.listing_grid_view_icon,
    };

    const profileMapping = {
      "Reward icon": data?.profile_reward_icon,
      "Bookmark icon": data?.profile_bookmark_icon,
      "Wishlist icon": data?.profile_wishlist_icon,
      "Order history icon": data?.profile_order_history_icon,
      "Saved address icon": data?.profile_saved_address_icon,
      "Change password icon": data?.profile_change_password_icon,
      "Chat icon": data?.profile_chat_icon,
      "Settings icon": data?.profile_settings_icon,
      "Logout icon": data?.profile_logout_icon,
      "Delete account icon": data?.profile_delete_account_icon,
    };

    const settingsMapping = {
      "Push notifications icon": data?.setting_push_notifications_icon,
      "Appearance icon": data?.setting_appearance_icon,
      "Notification icon": data?.setting_notification_icon,
      "Site icon": data?.setting_site_icon,
      "Currency icon": data?.setting_currency_icon,
      "Language icon": data?.setting_language_icon,
      "Terms and conditions icon": data?.setting_terms_and_conditions_icon,
      "Chat icon": data?.setting_chat_icon,
      "Rate us icon": data?.setting_rate_us_icon,
      "Offline mode icon": data?.setting_offline_mode_icon,
    };

    const updatedHeaderIconsArray = updateArray(
      HEADER_ICONS_ARRAY,
      headerMapping
    );
    const updatedListingIconsArray = updateArray(
      LISTING_ICONS_ARRAY,
      listingMapping
    );
    const updatedProfileIconsArray = updateArray(
      PROFILE_ICONS_ARRAY,
      profileMapping
    );
    const updatedSettingsIconsArray = updateArray(
      SETTINGS_ICONS_ARRAY,
      settingsMapping
    );

    setUpdatedHeaderIcons(updatedHeaderIconsArray);
    setUpdatedListingIcons(updatedListingIconsArray);
    setUpdatedProfileIcons(updatedProfileIconsArray);
    setUpdatedSettingsIcons(updatedSettingsIconsArray);
    setUpdatedHeaderPayload(generatePayload("header", updatedHeaderIcons));
    setUpdatedListingPayload(generatePayload("listing", updatedListingIcons));
    setUpdatedProfilePayload(generatePayload("profile", updatedProfileIcons));
    setUpdatedSettingsPayload(generatePayload("setting", updatedSettingsIcons));
  }

  const countBlackIcons = (data, totalItems = 0, blackIconItems = 0) => {
    data &&
      data.forEach((item) => {
        blackIconItems++;
        if (item.icon_color === "black") {
          totalItems++;
        }
      });
    if (totalItems === blackIconItems) {
      setAllBlack(true);
    } else {
      setAllBlack(false);
    }
  };

  useEffect(() => {
    fetchAndUpdateIcons(iconData);
  }, []);

  useEffect(() => {
    const payloadMappings = {
      header: updatedHeaderIcons,
      listing: updatedListingIcons,
      profile: updatedProfileIcons,
      setting: updatedSettingsIcons,
    };

    Object.entries(payloadMappings).forEach(([key, icons]) => {
      const generateFunctionMap = {
        header: setUpdatedHeaderPayload,
        listing: setUpdatedListingPayload,
        profile: setUpdatedProfilePayload,
        setting: setUpdatedSettingsPayload,
      };

      generateFunctionMap[key](generatePayload(key, icons));
    });
  }, [
    updatedHeaderIcons,
    updatedListingIcons,
    updatedProfileIcons,
    updatedSettingsIcons,
  ]);

  useEffect(() => {
    countBlackIcons(updatedHeaderIcons);
  }, [updatedHeaderIcons, selectedflaticoncolor]);

  return (
    <div className="themeSelct-color-div">
      <div className="themeSelct-color-icon-update">
        <div className="theme-icons-head">
          <h2 className="theme-icons-title">Icons<div className="theme-icon-new-tag">
                    {/* <Newfeatureicon/> */}
                    </div></h2>
          <p className="theme-icons-para">
            Customize the appearance of various icons in your app to match your
            brand's look and feel.
          </p>
        </div>

        <div className="theme-icon-select-maindiv">
          <div>
            <h2 className="them-type-icon">
              Header icons
              <Tooltip
                message={"These icons will appear in the app’s native header."}
              />
            </h2>
            {updatedHeaderIcons &&
              updatedHeaderIcons?.map((icon, idx) => (
                <div>
                  <label className="icon-head-name">
                    {icon.name}
                    <Tooltip message={icon.tooltipMessage} />
                    
                  </label>
                  <div
                    onClick={() =>
                      handleIconPopUp(idx, "headerIcons", icon.name)
                    }
                    className="theme-iconUpload-div"
                  >
                    <div className="theme-icons-name">
                      <div className="theme-icon-uploaded">
                        {" "}
                        <img
                          className="theme-icon-size"
                          src={
                            icon?.item_icon && icon.item_icon.includes("ams")
                              ? `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${icon.item_icon}`
                              : `${process.env.REACT_APP_Image_Path}${process.env.REACT_APP_default_icon_path}${icon.item_icon}`
                          }
                        />
                      </div>

                      <p className="theme-icon-name">{icon.item_icon}</p>
                    </div>
                    <div className="theme-icon-deleteupload">
                      <img
                        className="theme-remove-cross-icon"
                        style={{
                          display:
                            icon.item_icon ==
                            HEADER_ICONS_ARRAY[idx].initial_item_icon
                              ? "none"
                              : "block",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setIconToDefault(idx, "headerIcons", icon.name);
                        }}
                        src={crossicon}
                      />
                      <img
                        className="theme-icon-light-opacity"
                        src={UploadTagnotSelected}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <div>
            <h2 className="them-type-icon">
              Listing screen icons
              <Tooltip
                message={
                  "These icons will appear on the result listing screens."
                }
              />
            </h2>
            {updatedListingIcons &&
              updatedListingIcons.map((icon, idx) => (
                <div>
                  <label className="icon-head-name">
                    {icon?.name} <Tooltip message={icon.tooltipMessage} />
                    
                  </label>
                  <div
                    onClick={() => handleIconPopUp(idx, "listingIcons")}
                    className="theme-iconUpload-div"
                  >
                    <div className="theme-icons-name">
                      <div className="theme-icon-uploaded">
                        <img
                          className="theme-icon-size"
                          src={
                            icon?.item_icon && icon.item_icon.includes("ams")
                              ? `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${icon.item_icon}`
                              : `${process.env.REACT_APP_Image_Path}${process.env.REACT_APP_default_icon_path}${icon.item_icon}`
                          }
                        ></img>
                      </div>
                      <p className="theme-icon-name">{icon?.item_icon}</p>
                    </div>
                    <div className="theme-icon-deleteupload">
                      <img
                        className="theme-remove-cross-icon"
                        style={{
                          display:
                            icon.item_icon ==
                            LISTING_ICONS_ARRAY[idx].initial_item_icon
                              ? "none"
                              : "block",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setIconToDefault(idx, "listingIcons");
                        }}
                        src={crossicon}
                      />
                      <img
                        className="theme-icon-light-opacity"
                        src={UploadTagnotSelected}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <div>
            <h2 className="them-type-icon">Profile</h2>
            {updatedProfileIcons &&
              updatedProfileIcons.map((icon, idx) => (
                <div>
                  <label className="icon-head-name">
                    {icon?.name} <Tooltip message={icon.tooltipMessage} />
                    
                  </label>
                  <div
                    onClick={() => handleIconPopUp(idx, "profileIcons")}
                    className="theme-iconUpload-div"
                  >
                    <div className="theme-icons-name">
                      <div className="theme-icon-uploaded">
                        <img
                          className="theme-icon-size"
                          src={
                            icon?.item_icon && icon.item_icon.includes("ams")
                              ? `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${icon.item_icon}`
                              : `${process.env.REACT_APP_Image_Path}${process.env.REACT_APP_default_icon_path}${icon.item_icon}`
                          }
                        ></img>
                      </div>
                      <p className="theme-icon-name">{icon?.item_icon}</p>
                    </div>
                    <div className="theme-icon-deleteupload">
                      <img
                        className="theme-remove-cross-icon"
                        style={{
                          display:
                            icon.item_icon ==
                            PROFILE_ICONS_ARRAY[idx].initial_item_icon
                              ? "none"
                              : "block",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setIconToDefault(idx, "profileIcons");
                        }}
                        src={crossicon}
                      />
                      <img
                        className="theme-icon-light-opacity"
                        src={UploadTagnotSelected}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <div>
            <h2 className="them-type-icon">Settings</h2>
            {updatedSettingsIcons &&
              updatedSettingsIcons.map((icon, idx) => (
                <div>
                  <label className="icon-head-name">
                    {icon?.name}
                    {icon.tooltipMessage && (
                      <Tooltip message={icon.tooltipMessage} />
                    )}
                     
                  </label>
                  <div
                    onClick={() => handleIconPopUp(idx, "settingIcons")}
                    className="theme-iconUpload-div"
                  >
                    <div className="theme-icons-name">
                      <div className="theme-icon-uploaded">
                        <img
                          className="theme-icon-size"
                          src={
                            icon?.item_icon && icon.item_icon.includes("ams")
                              ? `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${icon.item_icon}`
                              : `${process.env.REACT_APP_Image_Path}${process.env.REACT_APP_default_icon_path}${icon.item_icon}`
                          }
                        ></img>
                      </div>
                      <p className="theme-icon-name">{icon?.item_icon}</p>
                    </div>
                    <div className="theme-icon-deleteupload">
                      <img
                        className="theme-remove-cross-icon"
                        style={{
                          display:
                            icon.item_icon ==
                            SETTINGS_ICONS_ARRAY[idx].initial_item_icon
                              ? "none"
                              : "block",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setIconToDefault(idx, "settingIcons");
                        }}
                        src={crossicon}
                      />
                      <img
                        className="theme-icon-light-opacity"
                        src={UploadTagnotSelected}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="themeSelct-color-Right">
        <div className="login-mob-previewtop">
          <div
            onClick={() => handlesliderChange(activeSlide, "back")}
            className={
              activeSlide <= 0
                ? "login-mob-previewtopback login-mob-previewtopbackopacity"
                : "login-mob-previewtopback"
            }
          >
            <img src={goback} />
          </div>
          <div
            onClick={() => handlesliderChange(activeSlide, "front")}
            className={
              activeSlide >= 4
                ? "login-mob-previewtopnext mob-previewtopnextdiv login-mob-previewtopbackopacity"
                : "login-mob-previewtopnext mob-previewtopnextdiv"
            }
          >
            <img src={goforward} />
          </div>
        </div>
        <div className="theme-preview-carousel">
          <div className="theme-preview-frame">
            <Carousel
              selectedItem={activeSlide}
              showArrows={false}
              swipeable={true}
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
            >
              <div>
                <div
                  className="theme-preview-header"
                  style={{
                    background: `${headerBackgroundColor}`,
                    borderBottom: `1px solid ${seperatorColor}`,
                  }}
                >
                  <div className="theme-preview-header-left">
                    <img
                      className="theme-preview-head-left-icon"
                      style={{
                        filter: allBlack && iconFilter,
                      }}
                      src={
                        `${
                          process.env.REACT_APP_Image_Path
                        }${localStorage.getItem(
                          "user_id"
                        )}/${localStorage.getItem("Appid")}${
                          process.env.REACT_APP_NEW_ICON_PATH
                        }${getItemObject(
                          "Hamburger menu icon",
                          "headerScreen"
                        )}`.includes("ams")
                          ? `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject(
                              "Hamburger menu icon",
                              "headerScreen"
                            )}`
                          : `${process.env.REACT_APP_Image_Path}${
                              process.env.REACT_APP_default_icon_path
                            }${getItemObject(
                              "Hamburger menu icon",
                              "headerScreen"
                            )}`
                      }
                    />

                    <p
                      style={{ color: textColor }}
                      className="theme-preview-head-text"
                    >
                      Account
                    </p>
                  </div>
                </div>
                <div className="preview-profilebody">
                  <div className="profile-details">
                    <div className="profile-details-div">
                      <div className="profile-details-img">
                        <img src={profileImg} />
                      </div>
                      <div className="profile-name-email">
                        <h2 className="profile-name">John Doe</h2>
                        <p className="profile-email">john.doe@example.com</p>
                      </div>
                    </div>
                    <img src={themeForwardArrowImg} />
                  </div>

                  <div className="profile-preview-icons">
                    <div className="profile-icons-div">
                      <div className="theme-preview-icondiv">
                        <img
                          src={
                            `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject("Reward icon")}`.includes("ams")
                              ? `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${getItemObject("Reward icon")}`
                              : `${process.env.REACT_APP_Image_Path}${
                                  process.env.REACT_APP_default_icon_path
                                }${getItemObject("Reward icon")}`
                          }
                        />
                      </div>

                      <p className="profile-icon-name">Rewards</p>
                    </div>
                    <p className="profile-icons-point">250 pts</p>
                  </div>

                  <div className="profile-preview-icons">
                    <div className="profile-icons-div">
                      <div className="theme-preview-icondiv">
                        <img
                          src={
                            `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject("Bookmark icon")}`.includes("ams")
                              ? `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${getItemObject("Bookmark icon")}`
                              : `${process.env.REACT_APP_Image_Path}${
                                  process.env.REACT_APP_default_icon_path
                                }${getItemObject("Bookmark icon")}`
                          }
                        />
                      </div>
                      <p className="profile-icon-name">Bookmark</p>
                    </div>
                    <img src={themeForwardArrowImg} />
                  </div>

                  <div className="profile-preview-icons">
                    <div className="profile-icons-div">
                      <div className="theme-preview-icondiv">
                        <img
                          src={
                            `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject("Wishlist icon")}`.includes("ams")
                              ? `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${getItemObject("Wishlist icon")}`
                              : `${process.env.REACT_APP_Image_Path}${
                                  process.env.REACT_APP_default_icon_path
                                }${getItemObject("Wishlist icon")}`
                          }
                        />
                      </div>
                      <p className="profile-icon-name">Wishlist</p>
                    </div>
                    <img src={themeForwardArrowImg} />
                  </div>

                  <div className="profile-preview-icons">
                    <div className="profile-icons-div">
                      <div className="theme-preview-icondiv">
                        <img
                          src={
                            `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject("Order history icon")}`.includes(
                              "ams"
                            )
                              ? `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${getItemObject("Order history icon")}`
                              : `${process.env.REACT_APP_Image_Path}${
                                  process.env.REACT_APP_default_icon_path
                                }${getItemObject("Order history icon")}`
                          }
                        />
                      </div>
                      <p className="profile-icon-name">Orders</p>
                    </div>
                    <img src={themeForwardArrowImg} />
                  </div>

                  <div className="profile-preview-icons">
                    <div className="profile-icons-div">
                      <div className="theme-preview-icondiv">
                        <img
                          src={
                            `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject("Saved address icon")}`.includes(
                              "ams"
                            )
                              ? `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${getItemObject("Saved address icon")}`
                              : `${process.env.REACT_APP_Image_Path}${
                                  process.env.REACT_APP_default_icon_path
                                }${getItemObject("Saved address icon")}`
                          }
                        />
                      </div>
                      <p className="profile-icon-name">Address</p>
                    </div>
                    <img src={themeForwardArrowImg} />
                  </div>

                  <div className="profile-preview-icons">
                    <div className="profile-icons-div">
                      <div className="theme-preview-icondiv">
                        <img
                          src={
                            `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject("Change password icon")}`.includes(
                              "ams"
                            )
                              ? `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${getItemObject("Change password icon")}`
                              : `${process.env.REACT_APP_Image_Path}${
                                  process.env.REACT_APP_default_icon_path
                                }${getItemObject("Change password icon")}`
                          }
                        />
                      </div>
                      <p className="profile-icon-name">Change password</p>
                    </div>
                    <img src={themeForwardArrowImg} />
                  </div>

                  <div className="profile-preview-icons">
                    <div className="profile-icons-div">
                      <div className="theme-preview-icondiv">
                        <img
                          src={
                            `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject("Chat icon")}`.includes("ams")
                              ? `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${getItemObject("Chat icon")}`
                              : `${process.env.REACT_APP_Image_Path}${
                                  process.env.REACT_APP_default_icon_path
                                }${getItemObject("Chat icon")}`
                          }
                        />
                      </div>
                      <p className="profile-icon-name">Chat</p>
                    </div>
                    <img src={themeForwardArrowImg} />
                  </div>

                  <div className="profile-preview-icons">
                    <div className="profile-icons-div">
                      <div className="theme-preview-icondiv">
                        <img
                          src={
                            `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject("Settings icon")}`.includes("ams")
                              ? `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${getItemObject("Settings icon")}`
                              : `${process.env.REACT_APP_Image_Path}${
                                  process.env.REACT_APP_default_icon_path
                                }${getItemObject("Settings icon")}`
                          }
                        />
                      </div>
                      <p className="profile-icon-name">Settings</p>
                    </div>
                    <img src={themeForwardArrowImg} />
                  </div>

                  <div className="profile-preview-icons">
                    <div className="profile-icons-div">
                      <div className="theme-preview-icondiv">
                        <img
                          style={{
                            filter:
                              " invert(81%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(92%) contrast(85%)",
                          }}
                          src={
                            `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject("Logout icon")}`.includes("ams")
                              ? `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${getItemObject("Logout icon")}`
                              : `${process.env.REACT_APP_Image_Path}${
                                  process.env.REACT_APP_default_icon_path
                                }${getItemObject("Logout icon")}`
                          }
                        />
                      </div>
                      <p className="profile-icon-logout">Logout</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="preview-1">
                <div
                  style={{
                    background: `${headerBackgroundColor}`,
                    borderBottom: `1px solid ${seperatorColor}`,
                  }}
                  className="theme-post-listing-head "
                >
                  <div className="post-listing-head-left">
                    <img
                      className="preview-theme-icon-size"
                      style={{
                        filter: allBlack && iconFilter,
                      }}
                      src={
                        `${
                          process.env.REACT_APP_Image_Path
                        }${localStorage.getItem(
                          "user_id"
                        )}/${localStorage.getItem("Appid")}${
                          process.env.REACT_APP_NEW_ICON_PATH
                        }${getItemObject(
                          "Back icon",
                          "headerScreen"
                        )}`.includes("ams")
                          ? `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject("Back icon", "headerScreen")}`
                          : `${process.env.REACT_APP_Image_Path}${
                              process.env.REACT_APP_default_icon_path
                            }${getItemObject("Back icon", "headerScreen")}`
                      }
                    />

                    <h3
                      style={{ color: textColor }}
                      className="theme-post-listing-heading"
                    >
                      Post list
                    </h3>
                  </div>

                  <div className="post-listing-head-right">
                    <img
                      className="preview-theme-icon-size"
                      style={{
                        filter: allBlack && iconFilter,
                      }}
                      src={
                        `${
                          process.env.REACT_APP_Image_Path
                        }${localStorage.getItem(
                          "user_id"
                        )}/${localStorage.getItem("Appid")}${
                          process.env.REACT_APP_NEW_ICON_PATH
                        }${getItemObject(
                          "Search icon",
                          "headerScreen"
                        )}`.includes("ams")
                          ? `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject("Search icon", "headerScreen")}`
                          : `${process.env.REACT_APP_Image_Path}${
                              process.env.REACT_APP_default_icon_path
                            }${getItemObject("Search icon", "headerScreen")}`
                      }
                    />

                    <img
                      className="post-list-bookmark preview-theme-icon-size"
                      style={{
                        filter: allBlack && iconFilter,
                      }}
                      src={
                        `${
                          process.env.REACT_APP_Image_Path
                        }${localStorage.getItem(
                          "user_id"
                        )}/${localStorage.getItem("Appid")}${
                          process.env.REACT_APP_NEW_ICON_PATH
                        }${getItemObject(
                          "Bookmark icon",
                          "headerScreen"
                        )}`.includes("ams")
                          ? `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject("Bookmark icon", "headerScreen")}`
                          : `${process.env.REACT_APP_Image_Path}${
                              process.env.REACT_APP_default_icon_path
                            }${getItemObject("Bookmark icon", "headerScreen")}`
                      }
                    />
                  </div>
                </div>

                <div className="post-listing-filters">
                  <div className="post-listing-filters-left">
                    <img
                      className="post-list-filter-left-icon"
                      src={
                        `${
                          process.env.REACT_APP_Image_Path
                        }${localStorage.getItem(
                          "user_id"
                        )}/${localStorage.getItem("Appid")}${
                          process.env.REACT_APP_NEW_ICON_PATH
                        }${getItemObject(
                          "Sort icon",
                          "listingScreen"
                        )}`.includes("ams")
                          ? `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject("Sort icon", "listingScreen")}`
                          : `${process.env.REACT_APP_Image_Path}${
                              process.env.REACT_APP_default_icon_path
                            }${getItemObject("Sort icon", "listingScreen")}`
                      }
                    />

                    <img
                      className="post-list-filter-left-icon"
                      src={
                        `${
                          process.env.REACT_APP_Image_Path
                        }${localStorage.getItem(
                          "user_id"
                        )}/${localStorage.getItem("Appid")}${
                          process.env.REACT_APP_NEW_ICON_PATH
                        }${getItemObject(
                          "Filter icon",
                          "listingScreen"
                        )}`.includes("ams")
                          ? `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject("Filter icon", "listingScreen")}`
                          : `${process.env.REACT_APP_Image_Path}${
                              process.env.REACT_APP_default_icon_path
                            }${getItemObject("Filter icon", "listingScreen")}`
                      }
                    />
                  </div>
                  <div className="post-listing-filters-right">
                    {/* <p className="total-post-count">245</p> */}

                    <img
                      className="post-list-filter-left-icon"
                      src={
                        `${
                          process.env.REACT_APP_Image_Path
                        }${localStorage.getItem(
                          "user_id"
                        )}/${localStorage.getItem("Appid")}${
                          process.env.REACT_APP_NEW_ICON_PATH
                        }${getItemObject(
                          "List view icon",
                          "listingScreen"
                        )}`.includes("ams")
                          ? `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject(
                              "List view icon",
                              "listingScreen"
                            )}`
                          : `${process.env.REACT_APP_Image_Path}${
                              process.env.REACT_APP_default_icon_path
                            }${getItemObject(
                              "List view icon",
                              "listingScreen"
                            )}`
                      }
                    />

                    <img
                      className="post-list-filter-left-icon"
                      src={
                        `${
                          process.env.REACT_APP_Image_Path
                        }${localStorage.getItem(
                          "user_id"
                        )}/${localStorage.getItem("Appid")}${
                          process.env.REACT_APP_NEW_ICON_PATH
                        }${getItemObject(
                          "Grid view icon",
                          "listingScreen"
                        )}`.includes("ams")
                          ? `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject(
                              "Grid view icon",
                              "listingScreen"
                            )}`
                          : `${process.env.REACT_APP_Image_Path}${
                              process.env.REACT_APP_default_icon_path
                            }${getItemObject(
                              "Grid view icon",
                              "listingScreen"
                            )}`
                      }
                    />
                  </div>
                </div>
                <div className="post-listing-style-one">
                  <div className="post-list-type-one">
                    <div
                      className="post-type-one-thumbnail post-type-one-thumbnail-styling"
                      style={{
                        backgroundImage: "url(" + imagebackground3 + ")",
                      }}
                    ></div>

                    <div className="post-list-author">
                      <div
                        style={{
                          backgroundImage: "url(" + gallery1 + ")",
                        }}
                      ></div>
                      <div>
                        <h5 className="post-one-author">John Smith</h5>
                      </div>
                    </div>
                    <h4 className="post-one-title">
                      Why is travelling so important?
                    </h4>

                    <p className="post-one-excerpt post-theme-one-excerpt-margin">
                      Lorem ipsum dolor sit amet, consectetur
                    </p>

                    <h6 className="post-one-timestamp post-theme-one-timestamp">
                      12 November 2020
                      <img src={DarkStarIcon} alt="Dark-icon" className="theme-product-star"/>
                    </h6>
                  </div>
                  <div className="post-list-type-one">
                    <div
                      className="post-type-one-thumbnail post-type-one-thumbnail-styling"
                      style={{
                        backgroundImage: "url(" + gallery2 + ")",
                      }}
                    ></div>

                    <div className="post-list-author">
                      <div
                        style={{
                          backgroundImage: "url(" + gallery1 + ")",
                        }}
                      ></div>
                      <div>
                        <h5 className="post-one-author">John Smith</h5>
                      </div>
                    </div>
                    <h4 className="post-one-title">
                      Why is travelling so important?
                    </h4>

                    <p className="post-one-excerpt post-theme-one-excerpt-margin">
                      Lorem ipsum dolor sit amet, consectetur
                    </p>

                    <h6 className="post-one-timestamp">12 November 2020</h6>
                  </div>
                  <div className="post-list-type-one">
                    <div
                      className="post-type-one-thumbnail post-type-one-thumbnail-styling"
                      style={{
                        backgroundImage: "url(" + gallery3 + ")",
                      }}
                    ></div>

                    <div className="post-list-author">
                      <div
                        style={{
                          backgroundImage: "url(" + gallery1 + ")",
                        }}
                      ></div>
                      <div>
                        <h5 className="post-one-author">John Smith</h5>
                      </div>
                    </div>
                    <h4 className="post-one-title">
                      Why is travelling so important?
                    </h4>

                    <p className="post-one-excerpt post-theme-one-excerpt-margin">
                      Lorem ipsum dolor sit amet, consectetur
                    </p>

                    <h6 className="post-one-timestamp">12 November 2020</h6>
                  </div>
                  <div className="post-list-type-one">
                    <div
                      className="post-type-one-thumbnail post-type-one-thumbnail-styling"
                      style={{
                        backgroundImage: "url(" + gallery4 + ")",
                      }}
                    ></div>

                    <div className="post-list-author">
                      <div
                        style={{
                          backgroundImage: "url(" + gallery1 + ")",
                        }}
                      ></div>
                      <div>
                        <h5 className="post-one-author">John Smith</h5>
                      </div>
                    </div>
                    <h4 className="post-one-title">
                      Why is travelling so important?
                    </h4>

                    <p className="post-one-excerpt post-theme-one-excerpt-margin">
                      Lorem ipsum dolor sit amet, consectetur
                    </p>

                    <h6 className="post-one-timestamp post-theme-one-timestamp">
                      12 November 2020
                      <img src={DarkStarIcon} alt="Dark-icon" className="theme-product-star"/>
                    </h6>
                  </div>
                  <div className="post-list-type-one">
                    <div
                      className="post-type-one-thumbnail post-type-one-thumbnail-styling "
                      style={{
                        backgroundImage: "url(" + gallery1 + ")",
                      }}
                    ></div>

                    <div className="post-list-author">
                      <div
                        style={{
                          backgroundImage: "url(" + gallery1 + ")",
                        }}
                      ></div>
                      <div>
                        <h5 className="post-one-author">John Smith</h5>
                      </div>
                    </div>
                    <h4 className="post-one-title">
                      Why is travelling so important?
                    </h4>

                    <p className="post-one-excerpt post-theme-one-excerpt-margin">
                      Lorem ipsum dolor sit amet, consectetur
                    </p>

                    <h6 className="post-one-timestamp">12 November 2020</h6>
                  </div>
                  <div className="post-list-type-one">
                    <div
                      className="post-type-one-thumbnail post-type-one-thumbnail-styling"
                      style={{
                        backgroundImage: "url(" + gallery2 + ")",
                      }}
                    ></div>

                    <div className="post-list-author">
                      <div
                        style={{
                          backgroundImage: "url(" + gallery1 + ")",
                        }}
                      ></div>
                      <div>
                        <h5 className="post-one-author">John Smith</h5>
                      </div>
                    </div>
                    <h4 className="post-one-title">
                      Why is travelling so important?
                    </h4>

                    <p className="post-one-excerpt post-theme-one-excerpt-margin">
                      Lorem ipsum dolor sit amet, consectetur
                    </p>

                    <h6 className="post-one-timestamp post-theme-one-timestamp">
                      12 November 2020
                      <img src={DarkStarIcon} alt="Dark-icon" className="theme-product-star" />
                    </h6>
                  </div>
                </div>
              </div>
              <div>
                <div className="preview-1">
                  <div>
                    <div
                      style={{
                        background: headerBackgroundColor,
                        borderBottom: `1px solid ${seperatorColor}`,
                      }}
                      className="preview-header"
                    >
                      <div>
                        <img
                          className="img-fluid preview-theme-icon-size"
                          style={{
                            filter: allBlack && iconFilter,
                          }}
                          src={
                            `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject(
                              "Back icon",
                              "headerScreen"
                            )}`.includes("ams")
                              ? `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${getItemObject("Back icon", "headerScreen")}`
                              : `${process.env.REACT_APP_Image_Path}${
                                  process.env.REACT_APP_default_icon_path
                                }${getItemObject("Back icon", "headerScreen")}`
                          }
                        />
                      </div>

                      <div>
                        <img
                          className="img-fluid preview-theme-icon-size"
                          style={{
                            filter: allBlack && iconFilter,
                          }}
                          src={
                            `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject(
                              "Share icon",
                              "headerScreen"
                            )}`.includes("ams")
                              ? `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${getItemObject("Share icon", "headerScreen")}`
                              : `${process.env.REACT_APP_Image_Path}${
                                  process.env.REACT_APP_default_icon_path
                                }${getItemObject("Share icon", "headerScreen")}`
                          }
                        />

                        <img
                          style={{
                            filter: allBlack && iconFilter,
                          }}
                          className="img-fluid preview-theme-icon-size theme-right-icon-margin"
                          src={
                            `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject(
                              "Bookmark icon",
                              "headerScreen"
                            )}`.includes("ams")
                              ? `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${getItemObject(
                                  "Bookmark icon",
                                  "headerScreen"
                                )}`
                              : `${process.env.REACT_APP_Image_Path}${
                                  process.env.REACT_APP_default_icon_path
                                }${getItemObject(
                                  "Bookmark icon",
                                  "headerScreen"
                                )}`
                          }
                        />
                      </div>
                    </div>
                    <div className="post-list-preview-detail">
                      <div className="theme-post-footer-div">
                        <div className="post-list-category-preview">
                          <h6>
                            Travel<span>|</span>Holiday<span>|</span>Trip
                            <span>|</span>
                          </h6>
                        </div>
                        <h2 className="theme-post-list-preview-title">
                          How to make your USA road trip a successful one
                        </h2>
                        <div className="post-list-author">
                          <div
                            style={{
                              backgroundImage: "url(" + gallery1 + ")",
                            }}
                          ></div>
                          <h5>John Smith</h5>
                          <div className="author-separator"></div>
                          <h6>12 Jun 2022</h6>
                        </div>
                      </div>
                      <div className="post-list-preview-banner theme-post-footer-div">
                        <div
                          className="post-list-preview-image"
                          style={{
                            backgroundImage: "url(" + imagebackground3 + ")",
                            height: "205.5px",
                            borderRadius: "6px",
                            backgroundSize: "cover",
                          }}
                        ></div>
                      </div>
                      <div className="theme-post-footer-div">
                        <p className="theme-post-list-preview-description">
                          Few things evoke a sense of adventure like a road
                          trip. Whether it’s just you, your friends, your family
                          or even with a hitchhiker, it’s certainly one of the
                          most exciting ways to travel.
                        </p>
                        <p className="theme-post-list-preview-description">
                          However, responding to the call of the open road can,
                          at times, be challenging for many inexperienced
                          travelers. So, in this post, I’ll be showing you some
                          tips and tricks on how to do a road trip right – and
                          what better case study to use than the home of the
                          road trip, the United States of America?!
                        </p>
                      </div>
                     
                      <div className="theme-post-footer-gap">
                        <div className="post-list-tag-preview theme-post-footer-div">
                          <h5>travel</h5>
                          <h5>trip</h5>
                          <h5>holiday</h5>
                          <h5>vacation</h5>
                          <h5>road trip</h5>
                          <h5>vacation</h5>
                          <h5>holiday</h5>
                          <h5>trip</h5>
                          <h5>travel</h5>
                        </div>
                      </div>
                      <div className="theme-post-footer-gap">
                        <div className="comments-preview theme-post-footer-div">
                          <div className="comment-head">
                            <h4 className="comment-heading">Comments (13)</h4>
                            <img
                              className="comment-head-img"
                              src={themeForwardArrowImg}
                              alt="Comments Arrow"
                            />
                          </div>
                          <div className="preview-comment-entry">
                            <div className="preview-comment-author">
                              <div
                                style={{
                                  backgroundImage: `url(${gallery1})`,
                                }}
                              ></div>
                              <h5 className="preview-comment-author-name">
                                Zachary Young
                              </h5>
                              <h6 className="preview-comment-author-status">
                                Verified
                              </h6>
                            </div>
                            <h6 className="preview-comments-data">
                              Bought this lovely dress and paired it with white
                              sneakers to complete the look. Super happy with
                              this look!
                            </h6>
                            <div className="preview-comments-footer">
                              <h6 className="preview-comments-reply">Reply</h6>
                              <h6 className="preview-comments-timestamp">
                                12 July 2023
                              </h6>
                            </div>
                          </div>
                          <div className="preview-comment-entry">
                            <div className="preview-comment-author">
                              <div
                                style={{
                                  backgroundImage: `url(${gallery1})`,
                                }}
                              ></div>
                              <h5 className="preview-comment-author-name">
                                Zachary Young
                              </h5>
                              <h6 className="preview-comment-author-status">
                                Verified
                              </h6>
                            </div>
                            <h6 className="preview-comments-data">
                              Bought this lovely dress and paired it with white
                              sneakers to complete the look. Super happy with
                              this look!
                            </h6>
                            <div className="preview-comments-footer">
                              <h6 className="preview-comments-reply">Reply</h6>
                              <h6 className="preview-comments-timestamp">
                                12 July 2023
                              </h6>
                            </div>
                          </div>
                          <div className="preview-comment-entry">
                            <div className="preview-comment-author">
                              <div
                                style={{
                                  backgroundImage: `url(${gallery1})`,
                                }}
                              ></div>
                              <h5 className="preview-comment-author-name">
                                Zachary Young
                              </h5>
                              <h6 className="preview-comment-author-status">
                                Verified
                              </h6>
                            </div>
                            <h6 className="preview-comments-data">
                              Bought this lovely dress and paired it with white
                              sneakers to complete the look. Super happy with
                              this look!
                            </h6>
                            <div className="preview-comments-footer">
                              <h6 className="preview-comments-reply">Reply</h6>
                              <h6 className="preview-comments-timestamp">
                                12 July 2023
                              </h6>
                            </div>
                          </div>
                          {/* <button className="theme-comment-button">
                            Comment
                          </button> */}
                        </div>
                      </div>
                      <div className="theme-post-footer-gap">
                        <div className="theme-post-footer-div"></div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="product-firstscreen">
                  <div className="w-100 ">
                    <div className="selected-product theme-selected-product">
                      <div className="productimg-topdiv">
                        <div>
                          <p
                            className="productimag-backside"
                            style={{ background: headerBackgroundColor }}
                          >
                            <img
                              style={{
                                filter: allBlack && iconFilter,
                              }}
                              className="preview-theme-icon-size"
                              src={
                                `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${getItemObject(
                                  "Back icon",
                                  "headerScreen"
                                )}`.includes("ams")
                                  ? `${
                                      process.env.REACT_APP_Image_Path
                                    }${localStorage.getItem(
                                      "user_id"
                                    )}/${localStorage.getItem("Appid")}${
                                      process.env.REACT_APP_NEW_ICON_PATH
                                    }${getItemObject(
                                      "Back icon",
                                      "headerScreen"
                                    )}`
                                  : `${process.env.REACT_APP_Image_Path}${
                                      process.env.REACT_APP_default_icon_path
                                    }${getItemObject(
                                      "Back icon",
                                      "headerScreen"
                                    )}`
                              }
                            />
                          </p>
                        </div>
                        <div className="d-flex">
                          <p
                            className="productimag-backside theme-productimag-backside"
                            style={{
                              background: headerBackgroundColor,
                            }}
                          >
                            <img
                              className="preview-theme-icon-size"
                              style={{
                                filter: allBlack && iconFilter,
                              }}
                              src={
                                `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${getItemObject(
                                  "Wishlist icon",
                                  "headerScreen"
                                )}`.includes("ams")
                                  ? `${
                                      process.env.REACT_APP_Image_Path
                                    }${localStorage.getItem(
                                      "user_id"
                                    )}/${localStorage.getItem("Appid")}${
                                      process.env.REACT_APP_NEW_ICON_PATH
                                    }${getItemObject(
                                      "Wishlist icon",
                                      "headerScreen"
                                    )}`
                                  : `${process.env.REACT_APP_Image_Path}${
                                      process.env.REACT_APP_default_icon_path
                                    }${getItemObject(
                                      "Wishlist icon",
                                      "headerScreen"
                                    )}`
                              }
                            />
                          </p>

                          <p
                            className="productimag-backside theme-productimag-backside"
                            style={{
                              background: headerBackgroundColor,
                            }}
                          >
                            <img
                              style={{
                                filter: allBlack && iconFilter,
                              }}
                              className="preview-theme-icon-size"
                              src={
                                `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${getItemObject(
                                  "Cart icon",
                                  "headerScreen"
                                )}`.includes("ams")
                                  ? `${
                                      process.env.REACT_APP_Image_Path
                                    }${localStorage.getItem(
                                      "user_id"
                                    )}/${localStorage.getItem("Appid")}${
                                      process.env.REACT_APP_NEW_ICON_PATH
                                    }${getItemObject(
                                      "Cart icon",
                                      "headerScreen"
                                    )}`
                                  : `${process.env.REACT_APP_Image_Path}${
                                      process.env.REACT_APP_default_icon_path
                                    }${getItemObject(
                                      "Cart icon",
                                      "headerScreen"
                                    )}`
                              }
                            />
                          </p>

                          <p
                            className="productimag-backside"
                            style={{ background: headerBackgroundColor }}
                          >
                            <img
                              style={{
                                filter: allBlack && iconFilter,
                              }}
                              className="preview-theme-icon-size"
                              src={
                                `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${getItemObject(
                                  "Profile icon",
                                  "headerScreen"
                                )}`.includes("ams")
                                  ? `${
                                      process.env.REACT_APP_Image_Path
                                    }${localStorage.getItem(
                                      "user_id"
                                    )}/${localStorage.getItem("Appid")}${
                                      process.env.REACT_APP_NEW_ICON_PATH
                                    }${getItemObject(
                                      "Profile icon",
                                      "headerScreen"
                                    )}`
                                  : `${process.env.REACT_APP_Image_Path}${
                                      process.env.REACT_APP_default_icon_path
                                    }${getItemObject(
                                      "Profile icon",
                                      "headerScreen"
                                    )}`
                              }
                            />
                          </p>
                        </div>
                      </div>
                      <div className="productimg-view">
                        <img src={ProductDefaultImage} alt="Product-img" />
                      </div>
                      <div className="product-details-images">
                        <img
                          src={ProductImagePaginationIcon}
                          alt="Pagination-icon"
                        />
                      </div>
                    </div>
                    <div className="product-descripton-firstdiv">
                      <ul>
                        <li className="productname-text">
                          Woman yellow solid round neck t-shirt
                        </li>

                        <li className="d-flex justify-content-between user-total-ratting">
                          <div className="theme-ratting">
                            <img src={StarRatingIcon} alt="StarRatingIcon" />
                            <div>
                              {" "}
                              4.5
                              <span className="rating-count">
                                (245 reviews)
                              </span>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div className="d-flex">
                            <p className="currentprice">$57</p>
                            <p className="previousprice">$67</p>
                            <div className="product-percentege-div">
                              <p>12%</p>
                            </div>
                          </div>
                          <p className="inventorydata">In Stock</p>
                        </li>
                      </ul>
                      <ul>
                        <li className="products-detilas">
                          Yellow light wash 5-pocket mid-rise cropped jeans,
                          clean look with no fade, has a button and zip closure,
                          waistband with belt loops.
                        </li>
                        <li className="products-detilasnumber">
                          SKU: 08000749670
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div
                  style={{
                    background: headerBackgroundColor,
                    borderBottom: `1px solid ${seperatorColor}`,
                  }}
                  className="theme-preview-header"
                >
                  <div className="theme-preview-header-left">
                    <img
                      className="theme-preview-header-left-icon"
                      style={{
                        filter: allBlack && iconFilter,
                      }}
                      src={
                        `${
                          process.env.REACT_APP_Image_Path
                        }${localStorage.getItem(
                          "user_id"
                        )}/${localStorage.getItem("Appid")}${
                          process.env.REACT_APP_NEW_ICON_PATH
                        }${getItemObject(
                          "Hamburger menu icon",
                          "headerScreen"
                        )}`.includes("ams")
                          ? `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject(
                              "Hamburger menu icon",
                              "headerScreen"
                            )}`
                          : `${process.env.REACT_APP_Image_Path}${
                              process.env.REACT_APP_default_icon_path
                            }${getItemObject(
                              "Hamburger menu icon",
                              "headerScreen"
                            )}`
                      }
                    />

                    <p
                      style={{ color: textColor }}
                      className="theme-preview-head-text"
                    >
                      Settings
                    </p>
                  </div>
                </div>
                <div className="preview-profilebody">
                  <div className="profile-preview-icons">
                    <div className="profile-icons-div">
                      <div className="theme-preview-icondiv">
                        <img
                          src={
                            `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject(
                              "Push notifications icon",
                              "settingsScreen"
                            )}`.includes("ams")
                              ? `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${getItemObject(
                                  "Push notifications icon",
                                  "settingsScreen"
                                )}`
                              : `${process.env.REACT_APP_Image_Path}${
                                  process.env.REACT_APP_default_icon_path
                                }${getItemObject(
                                  "Push notifications icon",
                                  "settingsScreen"
                                )}`
                          }
                        />
                      </div>
                      <p className="profile-icon-name">Push Notifications</p>
                    </div>
                    <div className="previewToggle">
                      <div className="roundToggle"></div>
                    </div>
                  </div>
                  <div className="profile-preview-icons">
                    <div className="profile-icons-div">
                      <div className="theme-preview-icondiv">
                        <img
                          src={
                            `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject(
                              "Appearance icon",
                              "settingsScreen"
                            )}`.includes("ams")
                              ? `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${getItemObject(
                                  "Appearance icon",
                                  "settingsScreen"
                                )}`
                              : `${process.env.REACT_APP_Image_Path}${
                                  process.env.REACT_APP_default_icon_path
                                }${getItemObject(
                                  "Appearance icon",
                                  "settingsScreen"
                                )}`
                          }
                        />
                      </div>
                      <p className="profile-icon-name">Appearance</p>
                    </div>
                    <img src={themeForwardArrowImg} />
                  </div>
                  <div className="profile-preview-icons">
                    <div className="profile-icons-div">
                      <div className="theme-preview-icondiv">
                        <img
                          src={
                            `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject(
                              "Offline mode icon",
                              "settingsScreen"
                            )}`.includes("ams")
                              ? `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${getItemObject(
                                  "Offline mode icon",
                                  "settingsScreen"
                                )}`
                              : `${process.env.REACT_APP_Image_Path}${
                                  process.env.REACT_APP_default_icon_path
                                }${getItemObject(
                                  "Offline mode icon",
                                  "settingsScreen"
                                )}`
                          }
                        />{" "}
                      </div>
                      <p className="profile-icon-name">Offline mode</p>
                    </div>
                    <div className="previewToggle">
                      <div className="roundToggle"></div>
                    </div>
                  </div>

                  <div className="profile-preview-icons">
                    <div className="profile-icons-div">
                      <div className="theme-preview-icondiv">
                        <img
                          src={
                            `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject(
                              "Notification icon",
                              "settingsScreen"
                            )}`.includes("ams")
                              ? `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${getItemObject(
                                  "Notification icon",
                                  "settingsScreen"
                                )}`
                              : `${process.env.REACT_APP_Image_Path}${
                                  process.env.REACT_APP_default_icon_path
                                }${getItemObject(
                                  "Notification icon",
                                  "settingsScreen"
                                )}`
                          }
                        />{" "}
                      </div>
                      <p className="profile-icon-name">Notifications</p>
                    </div>
                    <img src={themeForwardArrowImg} />
                  </div>

                  <div className="profile-preview-icons">
                    <div className="profile-icons-div">
                      <div className="theme-preview-icondiv">
                        <img
                          src={
                            `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject(
                              "Site icon",
                              "settingsScreen"
                            )}`.includes("ams")
                              ? `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${getItemObject(
                                  "Site icon",
                                  "settingsScreen"
                                )}`
                              : `${process.env.REACT_APP_Image_Path}${
                                  process.env.REACT_APP_default_icon_path
                                }${getItemObject(
                                  "Site icon",
                                  "settingsScreen"
                                )}`
                          }
                        />{" "}
                      </div>
                      <div>
                        <p className="profile-icon-name">Site</p>
                        <p className="profile-icon-type">yoursite.com</p>
                      </div>
                    </div>
                    <img src={themeForwardArrowImg} />
                  </div>
                  <div className="profile-preview-icons">
                    <div className="profile-icons-div">
                      <div className="theme-preview-icondiv">
                        <img
                          src={
                            `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject(
                              "Currency icon",
                              "settingsScreen"
                            )}`.includes("ams")
                              ? `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${getItemObject(
                                  "Currency icon",
                                  "settingsScreen"
                                )}`
                              : `${process.env.REACT_APP_Image_Path}${
                                  process.env.REACT_APP_default_icon_path
                                }${getItemObject(
                                  "Currency icon",
                                  "settingsScreen"
                                )}`
                          }
                        />{" "}
                      </div>
                      <div>
                        <p className="profile-icon-name">Currency</p>
                        <p className="profile-icon-type">USD</p>
                      </div>
                    </div>
                    <img src={themeForwardArrowImg} />
                  </div>
                  <div className="profile-preview-icons">
                    <div className="profile-icons-div">
                      <div className="theme-preview-icondiv">
                        <img
                          src={
                            `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject(
                              "Language icon",
                              "settingsScreen"
                            )}`.includes("ams")
                              ? `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${getItemObject(
                                  "Language icon",
                                  "settingsScreen"
                                )}`
                              : `${process.env.REACT_APP_Image_Path}${
                                  process.env.REACT_APP_default_icon_path
                                }${getItemObject(
                                  "Language icon",
                                  "settingsScreen"
                                )}`
                          }
                        />{" "}
                      </div>
                      <div>
                        <p className="profile-icon-name">Language</p>
                        <p className="profile-icon-type">English</p>
                      </div>
                    </div>
                    <img src={themeForwardArrowImg} />
                  </div>

                  <div className="profile-preview-icons">
                    <div className="profile-icons-div">
                      <div className="theme-preview-icondiv">
                        <img
                          src={
                            `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject(
                              "Terms and conditions icon",
                              "settingsScreen"
                            )}`.includes("ams")
                              ? `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${getItemObject(
                                  "Terms and conditions icon",
                                  "settingsScreen"
                                )}`
                              : `${process.env.REACT_APP_Image_Path}${
                                  process.env.REACT_APP_default_icon_path
                                }${getItemObject(
                                  "Terms and conditions icon",
                                  "settingsScreen"
                                )}`
                          }
                        />{" "}
                      </div>
                      <p className="profile-icon-name">Terms & Conditions</p>
                    </div>
                    <img src={themeForwardArrowImg} />
                  </div>
                  <div className="profile-preview-icons">
                    <div className="profile-icons-div">
                      <div className="theme-preview-icondiv">
                        <img
                          src={
                            `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject(
                              "Chat icon",
                              "settingsScreen"
                            )}`.includes("ams")
                              ? `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${getItemObject(
                                  "Chat icon",
                                  "settingsScreen"
                                )}`
                              : `${process.env.REACT_APP_Image_Path}${
                                  process.env.REACT_APP_default_icon_path
                                }${getItemObject(
                                  "Chat icon",
                                  "settingsScreen"
                                )}`
                          }
                        />{" "}
                      </div>
                      <p className="profile-icon-name">Chat</p>
                    </div>
                    <img src={themeForwardArrowImg} />
                  </div>
                  <div className="profile-preview-icons">
                    <div className="profile-icons-div">
                      <div className="theme-preview-icondiv">
                        <img
                          src={
                            `${
                              process.env.REACT_APP_Image_Path
                            }${localStorage.getItem(
                              "user_id"
                            )}/${localStorage.getItem("Appid")}${
                              process.env.REACT_APP_NEW_ICON_PATH
                            }${getItemObject(
                              "Rate us icon",
                              "settingsScreen"
                            )}`.includes("ams")
                              ? `${
                                  process.env.REACT_APP_Image_Path
                                }${localStorage.getItem(
                                  "user_id"
                                )}/${localStorage.getItem("Appid")}${
                                  process.env.REACT_APP_NEW_ICON_PATH
                                }${getItemObject(
                                  "Rate us icon",
                                  "settingsScreen"
                                )}`
                              : `${process.env.REACT_APP_Image_Path}${
                                  process.env.REACT_APP_default_icon_path
                                }${getItemObject(
                                  "Rate us icon",
                                  "settingsScreen"
                                )}`
                          }
                        />{" "}
                      </div>
                      <p className="profile-icon-name">Rate us</p>
                    </div>
                    <img src={themeForwardArrowImg} />
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
      <IconSelectionPopUp
        handleIconPopUp={handleIconPopUp}
        saveIconName={saveIconName}
        openIconPopUp={iconPopup.openIconPopUp}
        widthForRestriction={128}
        heightForRestriction={128}
      />
    </div>
  );
};

export default ThemeIcons;
