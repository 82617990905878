import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiEndPoints from "../utils/apiEndpoints";

export const productsApiSlice = createApi({
  reducerPath: "products",
  keepUnusedDataFor: 30 * 60,
  baseQuery: fetchBaseQuery({ 
    baseUrl: apiEndPoints.userUrl,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("amstoken")}`
      );
      return headers;
    },
  }),
  endpoints: (builder) => ({
    productsData: builder.query({
      query: () => `product-toggle/${btoa(localStorage.getItem("Appid"))}`,
      keepUnusedDataFor: 5 * 60, // 5 minutes
      providesTags: ["saveProductsData"],
    }),
    saveProductsData: builder.mutation({
      query: (productPayload) => ({
        url: "save-product-toggle",
        method: "POST",
        body: productPayload,
      }),
      invalidatesTags: ["saveProductsData"],
    })

  }),
});

export const {  useProductsDataQuery, useSaveProductsDataMutation} = productsApiSlice;
