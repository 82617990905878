import React, { useState, useEffect, Suspense } from "react";
import "./../../assets/css/Dashboard.css";
import "./../../assets/css/profileleftpanel.css";
import "./../../assets/css/appicon.css";
import Header from "./../Header";
import Leftpanel from "./../Leftpanel";
import { Link } from "react-router-dom";
import { Spinner, Media } from "reactstrap";
import {
  GetOnseSignalNotification,
  DeleteNotification,
  SaveNotificationConfiguration,
} from "../../Api/RichNotification/RichNotificationApi";
import Select from "react-select";
import OutsideClickHandler from "react-outside-click-handler";
import FilterPopup from "./FilterPopup";
import DatePicker from "react-datepicker";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledDropdown,
} from "reactstrap";
import { Notaddonactive, UpgradeNotification } from "./UpgradeNotification";
import moment from "moment";
import BottomScrollListener from "react-bottom-scroll-listener";
import PremiumTag from "../../Components/commonIcons/PremiumTag";
//import NotificationConfigure from "./NotificationConfigure";
//import { InnerFoot } from "../Foot";
//import Table from "./Table";
//import { Tabledata } from "./Context";
const Multisitealert = React.lazy(() => import("./Multisitealert"));

const NotificationsList = (props) => {
  const [spinner, setSpinner] = useState(true);
  const [displaytable, setDisplaytable] = useState([]);
  const [displayownerappicon, setDisplayownerappicon] = useState([]);
  const [deletenoti, setDeletenoti] = useState(false);
  const [deletenotifyid, setDeletenotifyid] = useState("");
  const [iosnotifyid, setIOSnotifyid] = useState(null);
  const [androidnotifyid, setANDROIDnotifyid] = useState(null);
  const [asnotifyid, setASnotifyid] = useState(null);
  const [count, setCount] = useState(1);
  const [multisite, setMultisite] = useState(null);
  const [is_enable_screen, setIs_enable_screen] = useState(1);
  const [isfilteropen, setIsFilterOpen] = useState(false);
  const [allnotifications, setAllNotifications] = useState([]);
  const [planname, setPlanName] = useState("");
  const [code, setCode] = useState(203);
  const [plan_code, setplan_code] = useState("");
  const [reseller_package_id, setreseller_package_id] = useState(null);
  const [statusfilterval, setStatusFilterVal] = useState(null);
  const [devicefilterval, setDeviceFilterVal] = useState(null);
  const [openMobStatusFilter, setOpenMobStatusFilter] = useState(false);
  const [openMobDeviceFilter, setOpenMobDeviceFilter] = useState(false);
  //const [startdate, setStartDate] = useState(new Date());
  const [startdate, setStartDate] = useState(null);
  const [enddate, setEndDate] = useState(null);
  const [reddotonfilter, setRedDotOnFilter] = useState(false);
  const [openMobParentFilter1, setOpenMobParentFilter1] = useState(false);
  const [openMobParentFilter2, setOpenMobParentFilter2] = useState(false);
  const [openMobParentFilter3, setOpenMobParentFilter3] = useState(false);
  const [openStatusFilter, setOpenStatusFilter] = useState(false);
  const [openDeviceFilter, setOpenDeviceFilter] = useState(false);
  const [searchval, setSearchVal] = useState("");
  const [deletenotificationpopup, setDeleteNotificationPopup] = useState(false);
  const [isdelete, setIsDelete] = useState(false);
  const [confirmpoplabel, setConfirmPopupLabel] = useState("Delete");
  const [removeloader, setRemoveLoader] = useState("d-none");
  const [deletenotificationid, setDeleteNotificationID] = useState(null);
  const [configstatus, setConfigStatus] = useState(false);
  const [issettingloader, setIsSettingLoader] = useState("d-none");
  const [submitsettingval, setSubmitSettingVal] = useState("Save");

  const [androidserverkey, setAndroidServerKey] = useState(null);
  const [androidnotificationstatus, setAndroidNotificationStatus] = useState(0);
  const [iosnotificationstatus, setiOSNotificationStatus] = useState(0);
  const [iosconfigurefilename, setiOSConfigureFileName] = useState("");
  const [androidconfigurefilename, setAndroidConfigureFilename] = useState("");
  const [iosplistconfigurefilename, setIosPlistConfigureFileName] =
    useState("");
  const [gcmsenderid, setGcmSenderId] = useState(null);
  const [iosconfigurepassword, setIosConfigurePassword] = useState(null);
  const [androidbundleid, setAndroidBundleID] = useState(null);
  const [iosbundleid, setIosBundleID] = useState(null);
  const [updatedat, setUpdatedAt] = useState("");
  const [filter, setFilter] = useState(0);
  const [resetenable, setResetEnable] = useState(false);
  const [page, setPage] = useState(1);
  const [bottomSpinner, setBottomSpinner] = useState(false);
  const [hitNextPagination, setHitNextPagination] = useState(false);
  const [androidserverkeyerror, setAndroidServerKeyError] = useState(false);
  const [appRightPanelExpand, setAppRightPanelExpand] = useState(
    localStorage.getItem("appSideBarCollapse") === true ||
      localStorage.getItem("appSideBarCollapse") === "true"
      ? true
      : false
  );
  const [skipButtonActive, setSkipButtonActive] = useState(false);
  const appPlan= localStorage.getItem("app_plan")
  //const [androidconfigurefilenameerror, setAndroidConfigureFilenameError] = useState(false);
  //const [iosconfigurefilenameerror, setiOSConfigureFileNameError] = useState(false);
  //const [iosplistconfigurefilenameerror, setIosPlistConfigureFileNameError] = useState(false);

  const [optionForMainFilter, setOptionForMainFilter] = useState([
    { value: "Status", label: "Status" },
    { value: "Date", label: "Date" },
    { value: "Platform", label: "Platform" },
  ]);

  const [filterSelectionArr, setFilterSelectionArr] = useState([
    {
      index: 0,
      value: "",
    },
  ]);

  useEffect(() => {
    if (
      localStorage.getItem("agencyid") &&
      localStorage.getItem("agencyid") == 1
    ) {
      document.title = "Advanced notifications | AppMySite";
    } else {
      document.title =
        "Advanced notifications |" + " " + localStorage.getItem("agencyname");
    }
    let cleaner = true;

    let limit = 20;
    let pageNo = 1;
    setPage(pageNo);
    GetOnseSignalNotification(
      setSpinner,
      setAllNotifications,
      setPlanName,
      setMultisite,
      "",
      setFilter,
      setCode,
      setreseller_package_id,
      setplan_code,
      limit,
      pageNo,
      allnotifications,
      setBottomSpinner,
      setHitNextPagination
    );
  }, [count, isdelete]);

  const handleContainerOnBottom = () => {
    if (bottomSpinner === false) {
      if (hitNextPagination) {
        let limit = 20;
        let pageNo = page + 1;
        setPage(pageNo);
        setBottomSpinner(true);

        GetOnseSignalNotification(
          setSpinner,
          setAllNotifications,
          setPlanName,
          setMultisite,
          "",
          setFilter,
          setCode,
          setreseller_package_id,
          setplan_code,
          limit,
          pageNo,
          allnotifications,
          setBottomSpinner,
          setHitNextPagination
        );
      }
    }
  };

  const sendvalueincontext = {
    asnotifyid: asnotifyid,
    androidnotifyid: androidnotifyid,
    displaytable: displaytable,
    deletenoti: { deletenoti: deletenoti, setDeletenoti: setDeletenoti },
    deletenotifyid: {
      deletenotifyid: deletenotifyid,
      setDeletenotifyid: setDeletenotifyid,
    },
    count: { count: count, setCount: setCount },
    displayownerappicon: { displayownerappicon, setDisplayownerappicon },
    iosnotifyid: iosnotifyid,
  };

  const sendNotificationReport = (notificationid) => {
    props.history.push(
      "/app/advanced-notification/report/" + btoa(notificationid)
    );
  };

  const handleFilterPopup = () => {
    setIsFilterOpen(!isfilteropen);
    if (isfilteropen === true) {
      document.body.classList.remove("opec");
    } else {
      document.body.classList.add("opec");
    }

    document.body.classList.add("opec");
  };

  const handleFilterSubmit = () => {
    let cleaner = true;

    let dateCheck = false;
    let platFormCheck = false;
    let statusCheck = false;

    filterSelectionArr &&
      filterSelectionArr.map((row) => {
        if (row.value === "Date") {
          dateCheck = true;
        } else if (row.value === "Platform") {
          platFormCheck = true;
        } else if (row.value === "Status") {
          statusCheck = true;
        }
      });

    let finalFilterVal = "";
    finalFilterVal = `&status=${
      statusCheck && statusfilterval ? returnStatusInNum(statusfilterval) : ""
    }&plateform=${
      platFormCheck && devicefilterval ? devicefilterval : ""
    }&delivery_start_date=${
      dateCheck && startdate ? moment(startdate).format("L") : ""
    }&delivery_end_date=${
      dateCheck && enddate ? moment(enddate).format("L") : ""
    }`;

    setSpinner(true);
    setIsFilterOpen(false);
    document.body.classList.remove("opec");
    //setOpenMobPostFilter(false);

    if (startdate || enddate || statusfilterval || devicefilterval) {
      setRedDotOnFilter(true);
    }
    let limit = 20;
    let pageNo = 1;
    setPage(pageNo);

    GetOnseSignalNotification(
      setSpinner,
      setAllNotifications,
      setPlanName,
      setMultisite,
      finalFilterVal,
      setFilter,
      setCode,
      limit,
      pageNo,
      allnotifications,
      setBottomSpinner,
      setHitNextPagination
    );
  };

  const returnStatusInNum = (statusVal) => {
    let status = "";

    if (statusVal === "draft") {
      status = 0;
    } else if (statusVal === "sent") {
      status = 1;
    } else if (statusVal === "scheduled") {
      status = 2;
    } else if (statusVal === "failed") {
      status = 3;
    }
    return status;
  };

  const handleNtfnFilterOutsideClick = () => {
    if (window.matchMedia("(min-width: 1200px)").matches) {
      //setNtfnFilterShow(false);
      setIsFilterOpen(false);
    }

    document.body.classList.remove("opec");
  };

  const handleResetFilter = () => {
    let selectionNewArr = [{ index: 0, value: "" }];
    setFilterSelectionArr(selectionNewArr);

    setStatusFilterVal(null);
    setDeviceFilterVal(null);
    setStartDate(null);
    setEndDate(null);
    setResetEnable(true);

    setRedDotOnFilter(false);
  };

  const handleReturnMainFilter = (val, index) => {
    return (
      <div
        className="mobile-view-click"
        onClick={
          window.matchMedia("(max-width: 1200px)").matches
            ? () => handleOpenParentDropdown(index)
            : null
        }
      >
        <Select
          options={optionForMainFilter}
          classNamePrefix="post-filter"
          onChange={(option) => handleMainFilterChange(option, index)}
          required={true}
          placeholder="Select"
          styles={customStyles}
          isClearable={false}
          isSearchable={false}
          value={optionForMainFilter.filter((option) => option.value === val)}
          isDisabled={
            window.matchMedia("(max-width: 1200px)").matches ? true : false
          }
          isOptionDisabled={(option) => {
            if (filterSelectionArr.length > 0) {
              return filterSelectionArr.find(
                (row) => row.value === option.value
              );
            } else {
              return false;
            }
          }}
        />
      </div>
    );
  };

  const handleMainFilterChange = (option, index) => {
    let isMatch = false;
    let newArr = [...filterSelectionArr];
    let obj = {
      index: index,
      value: option.value,
    };

    newArr &&
      newArr.map((row, indx) => {
        if (row.index === index) {
          row.value = option.value;
          isMatch = true;
        }
      });

    if (!isMatch) {
      newArr.push(obj);
    }
    setFilterSelectionArr(newArr);

    setOpenMobParentFilter1(false);
    setOpenMobParentFilter2(false);
    setOpenMobParentFilter3(false);
  };

  const handleOpenParentDropdown = (index) => {
    if (index === 0) {
      setOpenMobParentFilter1(!openMobParentFilter1);
      setOpenMobParentFilter2(false);
      setOpenMobParentFilter3(false);
    } else if (index === 1) {
      setOpenMobParentFilter2(!openMobParentFilter2);
      setOpenMobParentFilter1(false);
      setOpenMobParentFilter3(false);
    } else if (index === 2) {
      setOpenMobParentFilter3(!openMobParentFilter3);
      setOpenMobParentFilter2(false);
      setOpenMobParentFilter1(false);
    }
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // match with the menu
      borderRadius: 3,
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#7782a1" : "#C8CEDB",
      backgroundColor: state.isDisabled ? "#ffffff" : "",
      height: 50,
      minHeight: 50,
      fontSize: 14,
      color: "#7782A1",
      paddingLeft: 10,
      boxShadow: state.isFocused ? "none" : "none",
      "&:hover": { boxShadow: state.isFocused ? "none" : "none" },
    }),
    valueContainer: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
      fontSize: 14,
      color: "#7782A1",
    }),
    menu: (base) => ({
      ...base,
      fontSize: "14px",
    }),
    input: (base) => ({
      ...base,
      margin: 0,
      padding: 0,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#1B2952", // Custom colour
      marginRight: 20,
    }),
  };

  const handleMobileStatusDropdown = () => {
    setOpenMobStatusFilter(!openMobStatusFilter);
  };

  const handleMobileDeviceDropdown = () => {
    setOpenMobDeviceFilter(!openMobDeviceFilter);
  };

  const returnSelectedStatusLabel = (status) => {
    let label = null;
    if (status === "") {
      label = "All";
    } else if (status === "sent") {
      label = "Sent";
    } else if (status === "draft") {
      label = "Draft";
    } else if (status === "scheduled") {
      label = "Scheduled";
    }

    return label;
  };

  const returnSelectedDeviceLabel = (device) => {
    let label = null;
    if (device === "android") {
      label = "Android";
    } else if (device === "ios") {
      label = "iOS";
    } else if (device === "both") {
      label = "Both";
    }

    return label;
  };

  const handleStatusFilterChange = (e, val) => {
    setStatusFilterVal(val);
    setOpenMobStatusFilter(false);
  };

  const handleDeviceFilterChange = (e, val) => {
    setDeviceFilterVal(val);
    setOpenMobDeviceFilter(false);
  };

  const handleOpenStatusFilter = (e) => {
    setOpenStatusFilter(!openStatusFilter);
  };

  const handleOpenDeviceFilter = (e) => {
    setOpenDeviceFilter(!openDeviceFilter);
  };

  const handleDeleteFilter = (val, key, from) => {
    let newArr = [...filterSelectionArr];
    newArr.splice(key, 1);
    newArr &&
      newArr.map((row, indx) => {
        row.index = indx;
      });
    setFilterSelectionArr(newArr);
  };

  const handleFilterDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const returnSelectedFilter = (val, indx) => {
    //  return filterSelectionArr && filterSelectionArr.map((row)=>{
    if (val === "Status") {
      return (
        <div className="post-filter-dropdown">
          <Dropdown
            isOpen={
              window.matchMedia("(max-width: 1200px)").matches
                ? false
                : openStatusFilter
            }
            onClick={
              window.matchMedia("(max-width: 1200px)").matches
                ? handleMobileStatusDropdown
                : null
            }
            toggle={(e) => handleOpenStatusFilter(e)}
          >
            <DropdownToggle caret>
              {statusfilterval !== null && statusfilterval !== undefined
                ? returnSelectedStatusLabel(statusfilterval)
                : "Select"}
            </DropdownToggle>

            <DropdownMenu>
              <DropdownItem
                onClick={(e) => handleStatusFilterChange(e, "")}
                active={statusfilterval === ""}
              >
                All
              </DropdownItem>
              <DropdownItem
                onClick={(e) => handleStatusFilterChange(e, "draft")}
                active={statusfilterval === "draft"}
              >
                Draft
              </DropdownItem>
              <DropdownItem
                onClick={(e) => handleStatusFilterChange(e, "scheduled")}
                active={statusfilterval === "scheduled"}
              >
                Scheduled
              </DropdownItem>
              <DropdownItem
                onClick={(e) => handleStatusFilterChange(e, "sent")}
                active={statusfilterval === "sent"}
              >
                Sent
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      );
    } else if (val === "Platform") {
      return (
        <div className="post-filter-dropdown">
          <Dropdown
            isOpen={
              window.matchMedia("(max-width: 1200px)").matches
                ? false
                : openDeviceFilter
            }
            onClick={
              window.matchMedia("(max-width: 1200px)").matches
                ? handleMobileDeviceDropdown
                : null
            }
            toggle={(e) => handleOpenDeviceFilter(e)}
          >
            <DropdownToggle caret>
              {devicefilterval !== null && devicefilterval !== undefined
                ? returnSelectedDeviceLabel(devicefilterval)
                : "Select"}
            </DropdownToggle>

            <DropdownMenu>
              <DropdownItem
                onClick={(e) => handleDeviceFilterChange(e, "android")}
                active={devicefilterval === "android"}
              >
                Android
              </DropdownItem>
              <DropdownItem
                onClick={(e) => handleDeviceFilterChange(e, "ios")}
                active={devicefilterval === "ios"}
              >
                iOS
              </DropdownItem>
              <DropdownItem
                onClick={(e) => handleDeviceFilterChange(e, "both")}
                active={devicefilterval === "both"}
              >
                Both
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      );
    } else if (val === "Date") {
      return (
        <div className="post-filter-dropdown pushnotification-lastsetting">
          <div className="common-filter-body-datepicker">
            <DatePicker
              className="date-input-post2"
              selected={startdate}
              onChange={handleFilterDateChange}
              startDate={startdate}
              endDate={enddate}
              selectsRange
              dateFormat="d MMM yyyy"
            />
          </div>
        </div>
      );
    }
  };

  const returnDeleteButton = (val, indx) => {
    return (
      filterSelectionArr.length > 1 && (
        <button
          className="delete-post-filter-button"
          onClick={() => handleDeleteFilter(val, indx)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
          >
            <g
              id="Group_38004"
              data-name="Group 38004"
              transform="translate(-15 -15)"
            >
              <g
                id="Group_3246"
                data-name="Group 3246"
                transform="translate(15 15.5)"
              >
                <path
                  id="Rectangle_2307"
                  data-name="Rectangle 2307"
                  d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z"
                  transform="translate(1.5 1.999)"
                  fill="none"
                  stroke="#bcc1ce"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <path
                  id="Rectangle_2308"
                  data-name="Rectangle 2308"
                  d="M1,0H4A1,1,0,0,1,5,1V2A0,0,0,0,1,5,2H0A0,0,0,0,1,0,2V1A1,1,0,0,1,1,0Z"
                  transform="translate(5)"
                  fill="none"
                  stroke="#bcc1ce"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
                <g
                  id="Group_3245"
                  data-name="Group 3245"
                  transform="translate(5.5 5.5)"
                >
                  <line
                    id="Line_110"
                    data-name="Line 110"
                    y2="5"
                    transform="translate(4 0)"
                    fill="none"
                    stroke="#bcc1ce"
                    stroke-miterlimit="10"
                    stroke-width="1"
                  />
                  <line
                    id="Line_111"
                    data-name="Line 111"
                    y1="5"
                    transform="translate(0 0)"
                    fill="none"
                    stroke="#bcc1ce"
                    stroke-miterlimit="10"
                    stroke-width="1"
                  />
                </g>
                <line
                  id="Line_112"
                  data-name="Line 112"
                  x2="15"
                  transform="translate(0 1.999)"
                  fill="none"
                  stroke="#bcc1ce"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
              </g>
            </g>
          </svg>
        </button>
      )
    );
  };

  const handleAddField = () => {
    let newArr = [...filterSelectionArr];
    let obj = { index: filterSelectionArr.length, value: "" };
    newArr.push(obj);
    setFilterSelectionArr(newArr);
  };

  const handleSearchVal = (e) => {
    // e.preventDefault();

    setSearchVal(e.target.value);
  };

  const handleDeleteNotificationPopup = (e, notificationid) => {
    setDeleteNotificationID(notificationid);
    setDeleteNotificationPopup(!deletenotificationpopup);
  };

  const handleDeletePopupCancel = () => {
    setDeleteNotificationPopup(false);
  };

  const handleDeleteNotification = (e) => {
    e.preventDefault();

    const params = {
      app_id: parseInt(localStorage.getItem("Appid")),
      notification_id: deletenotificationid,
    };

    //setSpinner(true);
    setConfirmPopupLabel("");
    setRemoveLoader("d-block");

    DeleteNotification(
      params,
      setIsDelete,
      isdelete,
      setConfirmPopupLabel,
      setRemoveLoader,
      setDeleteNotificationPopup,
      deletenotificationpopup,
      setSpinner
    );
  };

  const handleConfiguration = (e) => {
    setConfigStatus(!configstatus);
  };

  const handleSubmitConfig = () => {
    if (androidserverkey === "") {
      setAndroidServerKeyError(true);
    } else {
      setAndroidServerKeyError(false);
    }

    let android_build_settings = {
      is_rich_notification_enabled: parseInt(androidnotificationstatus),
      notification_config_file: androidconfigurefilename,
    };

    let ios_build_settings = {
      is_rich_notification_enabled: parseInt(iosnotificationstatus),
      apns_p12_certificate: iosconfigurefilename,
      apns_p12_certificate_password: iosconfigurepassword,
      apns_provisioning_profile: iosplistconfigurefilename,
    };

    const params = {
      app_id: parseInt(localStorage.getItem("Appid")),
      android_build_settings: android_build_settings,
      ios_build_settings: ios_build_settings,
      gcmsenderid: gcmsenderid,
      androidserverkey: androidserverkey,
    };

    SaveNotificationConfiguration(
      params,
      setIsSettingLoader,
      setSubmitSettingVal,
      setUpdatedAt
    );
  };

  const handleRightPanelExpand = (value) => {
    setAppRightPanelExpand(value);
  };

  const handleSkipClick = () => {
    setSkipButtonActive(true);
  };

  return (
    <>
      <div className="d-md-block">
        <Header customclass={"header-responsive-new"} />
      </div>
      {/* Mobile View */}
      {/* Mobile View End*/}
      <section className="dasboard_page newmobile-display">
        <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />

        <BottomScrollListener onBottom={handleContainerOnBottom} offset={100}>
          {(scrollRef) => (
            <div
              style={{
                height: "calc(100vh - 60px)",
                overflowY: "auto",
              }}
              ref={scrollRef}
              className={`right-panel-gap ${
                localStorage.getItem("appSideBarCollapse") !== undefined &&
                localStorage.getItem("appSideBarCollapse") !== null &&
                localStorage.getItem("appSideBarCollapse") === "true"
                  ? " right-panel-gap-expand"
                  : ""
              }`}
            >
              <div className="update-mob-upgadehead">
                <p>Advanced Notifications</p>
                <div className="update-mob-upgadehead-right">
                {appPlan !== "premium" && skipButtonActive && <PremiumTag/> }

                {(code === 200 || skipButtonActive) && (
                  <div className="build-settingmob">
                    {/* <Link to="/app/advanced-notification/create" className=" update-mob-upgadelink" >
                            <img src={ require("../../assets/images/dashboard/plus.png").default } className="img-fluid mr-2 symbolspace" alt="plus" />
                            Create
                        </Link> */}
                    {/* <div className="consent-setting-clicked" style={{marginTop: "-5px"}}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                            <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                                <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                                <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="#7782a1" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                            </g>
                            </svg>
                            </div>    */}

                    {/* <div className="consent-setting"> */}
                    <Link
                      className="consent-setting pointcursor"
                      to={"/app/notifications/build-settings"}
                      style={{ border: "unset", margin: "0px" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="24"
                        viewBox="0 0 26 24"
                      >
                        <g
                          id="Group_43299"
                          data-name="Group 43299"
                          transform="translate(-1718 -125)"
                        >
                          <rect
                            id="Rectangle_32508"
                            data-name="Rectangle 32508"
                            width="26"
                            height="24"
                            transform="translate(1718 125)"
                            fill="none"
                          />
                          <g
                            id="Rectangle_31502"
                            data-name="Rectangle 31502"
                            transform="translate(1722 125)"
                            fill="#f8fafe"
                            stroke="#7782a1"
                            stroke-width="1.5"
                          >
                            <rect width="15" height="23" rx="3" stroke="none" />
                            <rect
                              x="0.75"
                              y="0.75"
                              width="13.5"
                              height="21.5"
                              rx="2.25"
                              fill="none"
                            />
                          </g>
                          <g
                            id="Group_38341"
                            data-name="Group 38341"
                            transform="translate(1708.245 -393.755)"
                          >
                            <rect
                              id="Rectangle_25572"
                              data-name="Rectangle 25572"
                              width="16"
                              height="16"
                              transform="translate(19.755 526.755)"
                              fill="none"
                            />
                            <path
                              id="Path_99646"
                              data-name="Path 99646"
                              d="M7.234,13.953a6.622,6.622,0,0,0,.774-.045.376.376,0,0,0,.331-.393,1.49,1.49,0,0,1,.91-1.461,1.393,1.393,0,0,1,.542-.1,1.546,1.546,0,0,1,1.134.482.377.377,0,0,0,.512.041A6.708,6.708,0,0,0,12.5,11.4a.376.376,0,0,0-.04-.507A1.487,1.487,0,0,1,13.436,8.3h.034a.446.446,0,0,0,.441-.331,6.727,6.727,0,0,0,0-1.528.362.362,0,0,0-.375-.33H13.52a1.555,1.555,0,0,1-1.464-.91,1.491,1.491,0,0,1,.375-1.678.375.375,0,0,0,.04-.511,6.678,6.678,0,0,0-1.087-1.075.37.37,0,0,0-.23-.08.376.376,0,0,0-.277.125,1.549,1.549,0,0,1-1.134.48,1.381,1.381,0,0,1-.531-.1A1.5,1.5,0,0,1,8.29.928.375.375,0,0,0,7.957.543a6.741,6.741,0,0,0-1.513,0,.376.376,0,0,0-.33.394A1.494,1.494,0,0,1,5.205,2.4a1.418,1.418,0,0,1-.544.105,1.544,1.544,0,0,1-1.134-.482.376.376,0,0,0-.512-.042A6.707,6.707,0,0,0,1.952,3.054a.376.376,0,0,0,.04.507A1.486,1.486,0,0,1,.976,6.146a.439.439,0,0,0-.433.33,6.75,6.75,0,0,0,0,1.53.377.377,0,0,0,.367.331H1.02A1.507,1.507,0,0,1,2.4,9.25a1.491,1.491,0,0,1-.376,1.678.376.376,0,0,0-.04.512,6.73,6.73,0,0,0,1.086,1.074.376.376,0,0,0,.508-.042,1.55,1.55,0,0,1,1.134-.48,1.406,1.406,0,0,1,.532.1,1.494,1.494,0,0,1,.921,1.435.375.375,0,0,0,.333.384A6.794,6.794,0,0,0,7.234,13.953Z"
                              transform="translate(20.669 528.038)"
                              fill="#f8fafe"
                              stroke="#7782a1"
                              stroke-linejoin="round"
                              stroke-width="1.5"
                            />
                            <path
                              id="Path_99647"
                              data-name="Path 99647"
                              d="M8.082,5.846A2.264,2.264,0,1,1,5.818,8.111,2.265,2.265,0,0,1,8.082,5.846Z"
                              transform="translate(19.826 527.202)"
                              fill="#f8fafe"
                              stroke="#7782a1"
                              stroke-linejoin="round"
                              stroke-width="1.5"
                            />
                          </g>
                          <path
                            id="Rectangle_31503"
                            data-name="Rectangle 31503"
                            d="M0,0H5A0,0,0,0,1,5,0V1A1,1,0,0,1,4,2H1A1,1,0,0,1,0,1V0A0,0,0,0,1,0,0Z"
                            transform="translate(1727 126)"
                            fill="#7782a1"
                          />
                        </g>
                      </svg>
                    </Link>
                    {/* </div> */}
                  </div>
                )}
                </div>
              </div>

              {localStorage.getItem("agencyid") &&
                localStorage.getItem("agencyid") != 1 &&
                window.matchMedia("(max-width: 1200px)").matches &&
                code !== 200 &&
                spinner === false && (
                  <div
                    style={{ marginBottom: "15px" }}
                    className="upgrade-feature"
                  >
                    <h4
                      style={{ textAlign: "unset" }}
                      className="upgrade-unavailable"
                    >
                      This feature is currently unavailable
                    </h4>
                    <p
                      style={{ textAlign: "unset" }}
                      className="upgrade-support"
                    >
                      Please contact Support for assistance.
                    </p>
                  </div>
                )}

              {(code === 200 || skipButtonActive) && (
                <>
                  <div className="notification-mob-create-btn">
                    <Link
                      to={{
                            pathname: "/app/advanced-notification/create",
                            state: { skipButtonActive:skipButtonActive }
                          }}
                      className=" update-mob-upgadelink"
                    >
                      <img
                        src={
                          require("../../assets/images/dashboard/plus.png")
                            .default
                        }
                        className="img-fluid mr-2 symbolspace"
                        alt="plus"
                      />
                      Create
                    </Link>
                  </div>
                  <div className="pushnotification-mobfilterdiv">
                    {(allnotifications.length > 0 || filter == 1) && (
                      <div className="pushnotification-Search">
                        <form>
                          <button>
                            <svg
                              id="Group_3669"
                              data-name="Group 3669"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16.53"
                              height="16.53"
                              viewBox="0 0 16.53 16.53"
                            >
                              <g
                                id="Ellipse_246"
                                data-name="Ellipse 246"
                                transform="translate(14.675) rotate(90)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-width="1.5"
                              >
                                <ellipse
                                  cx="7.337"
                                  cy="7.337"
                                  rx="7.337"
                                  ry="7.337"
                                  stroke="none"
                                />
                                <ellipse
                                  cx="7.337"
                                  cy="7.337"
                                  rx="6.587"
                                  ry="6.587"
                                  fill="none"
                                />
                              </g>
                              <path
                                id="Path_6064"
                                data-name="Path 6064"
                                d="M0,5.347V0"
                                transform="translate(16 16) rotate(135)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-width="1.5"
                              />
                            </svg>
                          </button>
                          <input
                            placeholder="Search"
                            name="searchnotification"
                            value={searchval}
                            onChange={handleSearchVal}
                          ></input>
                        </form>
                      </div>
                    )}
                    {(allnotifications.length > 0 || filter == 1) && (
                      <div className="pushnotification-filter">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          onClick={handleFilterPopup}
                        >
                          <g
                            id="Group_37996"
                            data-name="Group 37996"
                            transform="translate(-1600 -118)"
                          >
                            <line
                              id="Line_388"
                              data-name="Line 388"
                              x1="2"
                              transform="translate(1618 128)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <line
                              id="Line_389"
                              data-name="Line 389"
                              x1="14"
                              transform="translate(1600 128)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <circle
                              id="Ellipse_8280"
                              data-name="Ellipse 8280"
                              cx="2"
                              cy="2"
                              r="2"
                              transform="translate(1608 119)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <line
                              id="Line_390"
                              data-name="Line 390"
                              x1="8"
                              transform="translate(1612 121)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <line
                              id="Line_391"
                              data-name="Line 391"
                              x1="8"
                              transform="translate(1600 121)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <line
                              id="Line_392"
                              data-name="Line 392"
                              x1="14"
                              transform="translate(1606 135)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <line
                              id="Line_393"
                              data-name="Line 393"
                              x1="2"
                              transform="translate(1600 135)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <circle
                              id="Ellipse_8281"
                              data-name="Ellipse 8281"
                              cx="2"
                              cy="2"
                              r="2"
                              transform="translate(1602 133)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                            <circle
                              id="Ellipse_8282"
                              data-name="Ellipse 8282"
                              cx="2"
                              cy="2"
                              r="2"
                              transform="translate(1614 126)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-linejoin="round"
                              stroke-width="2"
                            />
                          </g>
                        </svg>

                        {reddotonfilter && (
                          <div className="filterapplyed-reddot"></div>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}

              <div className="mob-upgradenotificationpage">
                {!window.matchMedia("(max-width: 1200px)").matches && (
                  <div
                    className="web-notification-head"
                    style={{ marginBottom: "0px" }}
                  >
                    <h1>
                      Advanced notifications
                      <p className=" consent-tooltip">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                        >
                          <g
                            id="Group_35001"
                            data-name="Group 35001"
                            transform="translate(-1727 -19)"
                          >
                            <g
                              id="Ellipse_7706"
                              data-name="Ellipse 7706"
                              transform="translate(1727 19)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-width="1.5"
                            >
                              <circle cx="9" cy="9" r="9" stroke="none" />
                              <circle cx="9" cy="9" r="8.25" fill="none" />
                            </g>
                            <line
                              id="Line_343"
                              data-name="Line 343"
                              y1="2"
                              transform="translate(1736 23)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <path
                              id="Path_98224"
                              data-name="Path 98224"
                              d="M0,2V-4"
                              transform="translate(1736 31)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-width="1.5"
                            />
                          </g>
                        </svg>
                        <p className="consent-tooltipsubheader">
                          Send out updates and engaging messages to your app
                          users.{" "}
                        </p>
                      </p>
                    </h1>
                  </div>
                )}

                <div className="notification-modal-tab">
                  <div
                    className="notificationlink notificationlink-list"
                    style={{ height: "33px" }}
                  >
                    <Link className="active" to="/app/advanced-notifications">
                      Notifications
                    </Link>
                    {/* <Link to="/app/basic-notifications">Plain notifications</Link> */}
                    <Link to="/app/advanced-notifications/subsets">
                      Group
                    </Link>
                    <Link to="/app/advanced-notifications/audience">Users</Link>
                    {/*<Link to="/app/rich-notifications/icon">Icon</Link>*/}
                  </div>

                  {localStorage.getItem("agencyid") &&
                    localStorage.getItem("agencyid") != 1 &&
                    window.matchMedia("(min-width: 1200px)").matches &&
                    code !== 200 &&
                    spinner === false && (
                      <div
                        style={{ marginBottom: "0px" }}
                        className="upgrade-feature"
                      >
                        <h4
                          style={{ textAlign: "unset" }}
                          className="upgrade-unavailable"
                        >
                          This feature is currently unavailable
                        </h4>
                        <p
                          style={{ textAlign: "unset" }}
                          className="upgrade-support"
                        >
                          Please contact Support for assistance.
                        </p>
                      </div>
                    )}

                  {(code === 200 || skipButtonActive) && (
                    <div className="notificationcreate-new ">
                      <>
                        {(allnotifications.length > 0 || filter == 1) && (
                          <div className="pushnotification-Search">
                            <form>
                              <button>
                                <svg
                                  id="Group_3669"
                                  data-name="Group 3669"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16.53"
                                  height="16.53"
                                  viewBox="0 0 16.53 16.53"
                                >
                                  <g
                                    id="Ellipse_246"
                                    data-name="Ellipse 246"
                                    transform="translate(14.675) rotate(90)"
                                    fill="none"
                                    stroke="#bcc1ce"
                                    stroke-width="1.5"
                                  >
                                    <ellipse
                                      cx="7.337"
                                      cy="7.337"
                                      rx="7.337"
                                      ry="7.337"
                                      stroke="none"
                                    />
                                    <ellipse
                                      cx="7.337"
                                      cy="7.337"
                                      rx="6.587"
                                      ry="6.587"
                                      fill="none"
                                    />
                                  </g>
                                  <path
                                    id="Path_6064"
                                    data-name="Path 6064"
                                    d="M0,5.347V0"
                                    transform="translate(16 16) rotate(135)"
                                    fill="none"
                                    stroke="#bcc1ce"
                                    stroke-width="1.5"
                                  />
                                </svg>
                              </button>
                              <input
                                placeholder="Search"
                                name="searchnotification"
                                value={searchval}
                                onChange={handleSearchVal}
                              ></input>
                            </form>
                          </div>
                        )}

                        {(allnotifications.length > 0 || filter == 1) && (
                          <div
                            className="pushnotification-filter"
                            style={{ position: "relative" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              onClick={handleFilterPopup}
                            >
                              <g
                                id="Group_37996"
                                data-name="Group 37996"
                                transform="translate(-1600 -118)"
                              >
                                <line
                                  id="Line_388"
                                  data-name="Line 388"
                                  x1="2"
                                  transform="translate(1618 128)"
                                  fill="none"
                                  stroke="#bcc1ce"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                />
                                <line
                                  id="Line_389"
                                  data-name="Line 389"
                                  x1="14"
                                  transform="translate(1600 128)"
                                  fill="none"
                                  stroke="#bcc1ce"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                />
                                <circle
                                  id="Ellipse_8280"
                                  data-name="Ellipse 8280"
                                  cx="2"
                                  cy="2"
                                  r="2"
                                  transform="translate(1608 119)"
                                  fill="none"
                                  stroke="#bcc1ce"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                />
                                <line
                                  id="Line_390"
                                  data-name="Line 390"
                                  x1="8"
                                  transform="translate(1612 121)"
                                  fill="none"
                                  stroke="#bcc1ce"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                />
                                <line
                                  id="Line_391"
                                  data-name="Line 391"
                                  x1="8"
                                  transform="translate(1600 121)"
                                  fill="none"
                                  stroke="#bcc1ce"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                />
                                <line
                                  id="Line_392"
                                  data-name="Line 392"
                                  x1="14"
                                  transform="translate(1606 135)"
                                  fill="none"
                                  stroke="#bcc1ce"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                />
                                <line
                                  id="Line_393"
                                  data-name="Line 393"
                                  x1="2"
                                  transform="translate(1600 135)"
                                  fill="none"
                                  stroke="#bcc1ce"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                />
                                <circle
                                  id="Ellipse_8281"
                                  data-name="Ellipse 8281"
                                  cx="2"
                                  cy="2"
                                  r="2"
                                  transform="translate(1602 133)"
                                  fill="none"
                                  stroke="#bcc1ce"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                />
                                <circle
                                  id="Ellipse_8282"
                                  data-name="Ellipse 8282"
                                  cx="2"
                                  cy="2"
                                  r="2"
                                  transform="translate(1614 126)"
                                  fill="none"
                                  stroke="#bcc1ce"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                />
                              </g>
                            </svg>
                            {reddotonfilter && (
                              <div
                                className="filterapplyed-reddot"
                                style={{ top: "-2px" }}
                              ></div>
                            )}
                          </div>
                        )}
                        {/* <div className="consent-setting-clicked" style={{marginRight: "20px"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                  <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                    <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                    <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="#7782a1" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                  </g>
                </svg>
                        </div> */}
                        {((multisite !== null &&
                          multisite.is_app_link_to_multisite <= 0 )||skipButtonActive) && (
                            <>
                         {appPlan !== "premium" && skipButtonActive && <PremiumTag/> }
                              <div className="consent-setting">
                                <Link
                                  to={"/app/notifications/build-settings"}
                                  className="pointcursor"
                                  style={{ border: "unset", margin: "0px" }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="26"
                                    height="24"
                                    viewBox="0 0 26 24"
                                  >
                                    <g
                                      id="Group_43299"
                                      data-name="Group 43299"
                                      transform="translate(-1718 -125)"
                                    >
                                      <rect
                                        id="Rectangle_32508"
                                        data-name="Rectangle 32508"
                                        width="26"
                                        height="24"
                                        transform="translate(1718 125)"
                                        fill="none"
                                      />
                                      <g
                                        id="Rectangle_31502"
                                        data-name="Rectangle 31502"
                                        transform="translate(1722 125)"
                                        fill="#f8fafe"
                                        stroke="#7782a1"
                                        stroke-width="1.5"
                                      >
                                        <rect
                                          width="15"
                                          height="23"
                                          rx="3"
                                          stroke="none"
                                        />
                                        <rect
                                          x="0.75"
                                          y="0.75"
                                          width="13.5"
                                          height="21.5"
                                          rx="2.25"
                                          fill="none"
                                        />
                                      </g>
                                      <g
                                        id="Group_38341"
                                        data-name="Group 38341"
                                        transform="translate(1708.245 -393.755)"
                                      >
                                        <rect
                                          id="Rectangle_25572"
                                          data-name="Rectangle 25572"
                                          width="16"
                                          height="16"
                                          transform="translate(19.755 526.755)"
                                          fill="none"
                                        />
                                        <path
                                          id="Path_99646"
                                          data-name="Path 99646"
                                          d="M7.234,13.953a6.622,6.622,0,0,0,.774-.045.376.376,0,0,0,.331-.393,1.49,1.49,0,0,1,.91-1.461,1.393,1.393,0,0,1,.542-.1,1.546,1.546,0,0,1,1.134.482.377.377,0,0,0,.512.041A6.708,6.708,0,0,0,12.5,11.4a.376.376,0,0,0-.04-.507A1.487,1.487,0,0,1,13.436,8.3h.034a.446.446,0,0,0,.441-.331,6.727,6.727,0,0,0,0-1.528.362.362,0,0,0-.375-.33H13.52a1.555,1.555,0,0,1-1.464-.91,1.491,1.491,0,0,1,.375-1.678.375.375,0,0,0,.04-.511,6.678,6.678,0,0,0-1.087-1.075.37.37,0,0,0-.23-.08.376.376,0,0,0-.277.125,1.549,1.549,0,0,1-1.134.48,1.381,1.381,0,0,1-.531-.1A1.5,1.5,0,0,1,8.29.928.375.375,0,0,0,7.957.543a6.741,6.741,0,0,0-1.513,0,.376.376,0,0,0-.33.394A1.494,1.494,0,0,1,5.205,2.4a1.418,1.418,0,0,1-.544.105,1.544,1.544,0,0,1-1.134-.482.376.376,0,0,0-.512-.042A6.707,6.707,0,0,0,1.952,3.054a.376.376,0,0,0,.04.507A1.486,1.486,0,0,1,.976,6.146a.439.439,0,0,0-.433.33,6.75,6.75,0,0,0,0,1.53.377.377,0,0,0,.367.331H1.02A1.507,1.507,0,0,1,2.4,9.25a1.491,1.491,0,0,1-.376,1.678.376.376,0,0,0-.04.512,6.73,6.73,0,0,0,1.086,1.074.376.376,0,0,0,.508-.042,1.55,1.55,0,0,1,1.134-.48,1.406,1.406,0,0,1,.532.1,1.494,1.494,0,0,1,.921,1.435.375.375,0,0,0,.333.384A6.794,6.794,0,0,0,7.234,13.953Z"
                                          transform="translate(20.669 528.038)"
                                          fill="#f8fafe"
                                          stroke="#7782a1"
                                          stroke-linejoin="round"
                                          stroke-width="1.5"
                                        />
                                        <path
                                          id="Path_99647"
                                          data-name="Path 99647"
                                          d="M8.082,5.846A2.264,2.264,0,1,1,5.818,8.111,2.265,2.265,0,0,1,8.082,5.846Z"
                                          transform="translate(19.826 527.202)"
                                          fill="#f8fafe"
                                          stroke="#7782a1"
                                          stroke-linejoin="round"
                                          stroke-width="1.5"
                                        />
                                      </g>
                                      <path
                                        id="Rectangle_31503"
                                        data-name="Rectangle 31503"
                                        d="M0,0H5A0,0,0,0,1,5,0V1A1,1,0,0,1,4,2H1A1,1,0,0,1,0,1V0A0,0,0,0,1,0,0Z"
                                        transform="translate(1727 126)"
                                        fill="#7782a1"
                                      />
                                    </g>
                                  </svg>
                                  <span className="build-settings-text">
                                    Build Settings
                                  </span>
                                </Link>
                              </div>

                              <Link
                                 to={{
                                  pathname: "/app/advanced-notification/create",
                                  state: { skipButtonActive:skipButtonActive, planCode:code }
                                }}
                                className="notification-createbtn  mobilecreatenew add-notification"
                                style={{
                                  backgroundColor: localStorage.getItem(
                                    "button_background_color"
                                  ),
                                  border:
                                    "1px solid " +
                                    localStorage.getItem(
                                      "button_background_color"
                                    ),
                                  color:
                                    localStorage.getItem("button_text_color"),
                                  "--custom_color5":
                                    localStorage.getItem("agencyid") == 1
                                      ? "#134bec"
                                      : localStorage.getItem(
                                          "button_hover_bgcolor"
                                        ),
                                  "--custom_color4":
                                    localStorage.getItem("agencyid") == 1
                                      ? "white"
                                      : localStorage.getItem(
                                          "button_hover_txtcolor"
                                        ),
                                  "--custom_color6":
                                    localStorage.getItem("agencyid") == 1
                                      ? "#3064f9"
                                      : localStorage.getItem(
                                          "button_hover_bgcolor"
                                        ),
                                }}
                              >
                                <img
                                  src={
                                    require("../../assets/images/dashboard/whiteplus.png")
                                      .default
                                  }
                                  className="img-fluid mr-2"
                                  alt="plus"
                                />
                                Create
                              </Link>
                            </>
                          )}
                      </>
                    </div>
                  )}
                </div>
              </div>

              <div
                className={
                  multisite !== null && multisite.is_app_link_to_multisite > 0
                    ? ""
                    : " notification-right-page"
                }
              >
                {spinner ? (
                  <div className="newspinner">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      style={{
                        margin: "auto",
                        background: "rgb(241, 242, 243,0)",
                        display: "block",
                        shaperendering: "auto",
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                      width="200px"
                      height="200px"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid"
                    >
                      <g
                        transform="translate(20 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#e6261f"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.375s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(40 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#f7d038"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.25s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(60 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#49da9a"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.125s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(80 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#4355db"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="0s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                    </svg>
                  </div>
                ) : (
                  <>
                    {multisite !== null &&
                    multisite.is_app_link_to_multisite > 0 ? (
                      <Suspense fallback={""}>
                        <Multisitealert value={multisite} />
                      </Suspense>
                    ) : (
                      <div className="right_general notificationtab">
                        <div className="mob-pushnotification-platform">
                          {code !== 200 && !skipButtonActive ? (
                            <>
                              <UpgradeNotification
                                reseller_package_id={reseller_package_id}
                                plan_code={plan_code}
                                handleSkipClick={handleSkipClick}
                              />
                            </>
                          ) : (
                            <div className="">
                              <div className="">
                                <div className="">
                                  {allnotifications.length !== null &&
                                  allnotifications.length > 0 ? (
                                    <div className="newsocial_pannel">
                                      <div className="notification-table">
                                        <p className="notification-message">
                                          Message
                                        </p>
                                        <p className="notification-platform">
                                          Device
                                        </p>
                                        <p className="newnotification-status">
                                          Status
                                        </p>
                                        <p className="notification-blank"></p>
                                      </div>
                                      <section className="create-notify">
                                        {allnotifications
                                          .filter(
                                            (row, ind) =>
                                              row.title
                                                .toLowerCase()
                                                .includes(
                                                  searchval.toLowerCase()
                                                ) ||
                                              row.description
                                                .toLowerCase()
                                                .includes(
                                                  searchval.toLowerCase()
                                                )
                                          )
                                          .map((res, idx) => {
                                            const metadata = JSON.parse(
                                              res.meta_data
                                            );
                                            const segexclude = JSON.parse(
                                              res.excludefilter
                                            );
                                            const seginclude = JSON.parse(
                                              res.includefilter
                                            );
                                            var imagename = "";
                                            if (
                                              metadata.image &&
                                              metadata.image != undefined
                                            ) {
                                              imagename =
                                                metadata.image.replaceAll(
                                                  process.env
                                                    .REACT_APP_Image_Path +
                                                    localStorage.getItem(
                                                      "user_id"
                                                    ) +
                                                    "/" +
                                                    localStorage.getItem(
                                                      "Appid"
                                                    ) +
                                                    process.env
                                                      .REACT_APP_Notification_Media,
                                                  ""
                                                );
                                            }
                                            const imagepath =
                                              process.env.REACT_APP_Image_Path +
                                              localStorage.getItem("user_id") +
                                              "/" +
                                              localStorage.getItem("Appid") +
                                              process.env
                                                .REACT_APP_Notification_Media;

                                            return (
                                              <div
                                                className="justify-content-between newpushcommon-height"
                                                key={idx}
                                              >
                                                <div className="os-list-notification-list newimage-noftication">
                                                  <div className="os-messagesave-div">
                                                    <div className=" os-image-margin">
                                                      {metadata.image !==
                                                        undefined &&
                                                      metadata.image !== "" &&
                                                      metadata.image !==
                                                        null ? (
                                                        metadata.image.indexOf(
                                                          "https"
                                                        ) === -1 ? (
                                                          <div
                                                            className="os-notimage  abc123"
                                                            style={{
                                                              backgroundImage:
                                                                "url('" +
                                                                process.env
                                                                  .REACT_APP_Image_Path +
                                                                localStorage.getItem(
                                                                  "user_id"
                                                                ) +
                                                                "/" +
                                                                localStorage.getItem(
                                                                  "Appid"
                                                                ) +
                                                                process.env
                                                                  .REACT_APP_Notification_Media +
                                                                metadata.image +
                                                                "')",
                                                            }}
                                                          ></div>
                                                        ) : imagename &&
                                                          imagename != "" &&
                                                          imagename !=
                                                            undefined &&
                                                          imagename != null ? (
                                                          <div
                                                            className="os-notimage  abc124"
                                                            style={{
                                                              backgroundImage:
                                                                "url('" +
                                                                imagepath +
                                                                imagename +
                                                                "')",
                                                            }}
                                                          ></div>
                                                        ) : (
                                                          <div className="os-notimage os-notimage-default abc125"></div>
                                                        )
                                                      ) : (
                                                        <div className="os-notimage os-notimage-default abc125"></div>
                                                      )}
                                                      {/* (metadata.image !== undefined && metadata.image !== '' && metadata.image !== null) ?
                                                                                            
                                                                                            ( (metadata.image).indexOf("https") === -1 ?
                                                                                            <img src={ process.env.REACT_APP_Image_Path + localStorage.getItem("user_id") + "/" + localStorage.getItem("Appid") + process.env.REACT_APP_Notification_Media + metadata.image } width="80px" height="80px" alt="Appicon" className="rounded abc123" />
                                                                                            :
                                                                                            <img src={ metadata.image } width="80px" height="80px" alt="Appicon" className="rounded abc124" />
                                                                                            )
                                                                                        :
                                                                                            <img src={ require("../../assets/images/dashboard/no-img-category.png").default } width="80px" height="80px" alt="Appicon" className="rounded abc125" />
                                                                                        */}
                                                    </div>

                                                    <div className="newnotification-heading">
                                                      <div className="rich-notification-title">
                                                        <h3 className="notification-title">
                                                          {res.title}
                                                        </h3>
                                                        <p className="notification-description">
                                                          {res.description}
                                                        </p>
                                                      </div>

                                                      {((seginclude &&
                                                        seginclude.length >
                                                          0) ||
                                                        (segexclude &&
                                                          segexclude.length >
                                                            0 &&
                                                          metadata &&
                                                          metadata.included_segments &&
                                                          metadata
                                                            .included_segments
                                                            .length > 0) ||
                                                        (metadata &&
                                                          metadata.excluded_segments &&
                                                          metadata
                                                            .excluded_segments
                                                            .length > 0)) && (
                                                        <>
                                                          <div className="os-engaged-user web-list-user">
                                                            {(
                                                              metadata &&
                                                              metadata
                                                                .included_segments
                                                                .length > 0 &&
                                                              metadata.included_segments
                                                            ).map(
                                                              (
                                                                metaval,
                                                                metaidx
                                                              ) => {
                                                                return (
                                                                  <p
                                                                    className="os-engaged-userptag 1"
                                                                    key={
                                                                      metaidx
                                                                    }
                                                                  >
                                                                    {metaval.segment_name !==
                                                                      undefined &&
                                                                    metaval.segment_name !==
                                                                      "" &&
                                                                    metaval.segment_name !==
                                                                      null
                                                                      ? metaval.segment_name
                                                                      : metaval}
                                                                    <svg
                                                                      width="9px"
                                                                      height="9px"
                                                                      viewBox="0 -0.5 21 21"
                                                                      version="1.1"
                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                      <g
                                                                        id="Page-1"
                                                                        stroke="none"
                                                                        stroke-width="1"
                                                                        fill="none"
                                                                        fill-rule="evenodd"
                                                                      >
                                                                        <g
                                                                          id="Dribbble-Light-Preview"
                                                                          transform="translate(-379.000000, -240.000000)"
                                                                          fill="#7782A1"
                                                                        >
                                                                          <g
                                                                            id="icons"
                                                                            transform="translate(56.000000, 160.000000)"
                                                                          >
                                                                            <polygon
                                                                              id="plus-[#1512]"
                                                                              points="344 89 344 91 334.55 91 334.55 100 332.45 100 332.45 91 323 91 323 89 332.45 89 332.45 80 334.55 80 334.55 89"
                                                                            ></polygon>
                                                                          </g>
                                                                        </g>
                                                                      </g>
                                                                    </svg>
                                                                  </p>
                                                                );
                                                              }
                                                            )}

                                                            {metadata &&
                                                              metadata.excluded_segments &&
                                                              metadata
                                                                .excluded_segments
                                                                .length > 0 &&
                                                              metadata.excluded_segments.map(
                                                                (
                                                                  metaval1,
                                                                  metaidx1
                                                                ) => {
                                                                  return (
                                                                    <p
                                                                      className="os-engaged-userptag 2"
                                                                      key={
                                                                        metaidx1
                                                                      }
                                                                    >
                                                                      {metaval1.segment_name !==
                                                                        undefined &&
                                                                      metaval1.segment_name !==
                                                                        "" &&
                                                                      metaval1.segment_name !==
                                                                        null
                                                                        ? metaval1.segment_name
                                                                        : metaval1}

                                                                      <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="9"
                                                                        height="1.5"
                                                                        viewBox="0 0 9 1.5"
                                                                      >
                                                                        <path
                                                                          id="Path_119017"
                                                                          data-name="Path 119017"
                                                                          d="M0,0V9"
                                                                          transform="translate(9 0.75) rotate(90)"
                                                                          fill="none"
                                                                          stroke="#bcc1ce"
                                                                          stroke-width="1.5"
                                                                        />
                                                                      </svg>
                                                                    </p>
                                                                  );
                                                                }
                                                              )}
                                                          </div>
                                                        </>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div className="os-engaged-user mob-os-engaged-user">
                                                    {((seginclude &&
                                                      seginclude.length > 0) ||
                                                      (segexclude &&
                                                        segexclude.length >
                                                          0)) &&
                                                      metadata.included_segments &&
                                                      metadata.included_segments.map(
                                                        (
                                                          metamobval,
                                                          metamobidx
                                                        ) => {
                                                          return (
                                                            <p
                                                              className="os-engaged-userptag 3"
                                                              key={metamobidx}
                                                            >
                                                              {metamobval.segment_name !==
                                                                undefined &&
                                                              metamobval.segment_name !==
                                                                "" &&
                                                              metamobval.segment_name !==
                                                                null
                                                                ? metamobval.segment_name
                                                                : metamobval}
                                                              <svg
                                                                width="9px"
                                                                height="9px"
                                                                viewBox="0 -0.5 21 21"
                                                                version="1.1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                              >
                                                                <g
                                                                  id="Page-1"
                                                                  stroke="none"
                                                                  stroke-width="1"
                                                                  fill="none"
                                                                  fill-rule="evenodd"
                                                                >
                                                                  <g
                                                                    id="Dribbble-Light-Preview"
                                                                    transform="translate(-379.000000, -240.000000)"
                                                                    fill="#7782A1"
                                                                  >
                                                                    <g
                                                                      id="icons"
                                                                      transform="translate(56.000000, 160.000000)"
                                                                    >
                                                                      <polygon
                                                                        id="plus-[#1512]"
                                                                        points="344 89 344 91 334.55 91 334.55 100 332.45 100 332.45 91 323 91 323 89 332.45 89 332.45 80 334.55 80 334.55 89"
                                                                      ></polygon>
                                                                    </g>
                                                                  </g>
                                                                </g>
                                                              </svg>
                                                            </p>
                                                          );
                                                        }
                                                      )}

                                                    {metadata.excluded_segments &&
                                                      metadata.excluded_segments.map(
                                                        (
                                                          metamobval1,
                                                          metamobidx1
                                                        ) => {
                                                          return (
                                                            <p
                                                              className="os-engaged-userptag 4"
                                                              key={metamobidx1}
                                                            >
                                                              {metamobval1.segment_name !==
                                                                undefined &&
                                                              metamobval1.segment_name !==
                                                                "" &&
                                                              metamobval1.segment_name !==
                                                                null
                                                                ? metamobval1.segment_name
                                                                : metamobval1}

                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="9"
                                                                height="1.5"
                                                                viewBox="0 0 9 1.5"
                                                              >
                                                                <path
                                                                  id="Path_119017"
                                                                  data-name="Path 119017"
                                                                  d="M0,0V9"
                                                                  transform="translate(9 0.75) rotate(90)"
                                                                  fill="none"
                                                                  stroke="#bcc1ce"
                                                                  stroke-width="1.5"
                                                                />
                                                              </svg>
                                                            </p>
                                                          );
                                                        }
                                                      )}
                                                  </div>
                                                </div>
                                                <div className=" align-items-center justify-content-start newnotification-status mob-platform-status">
                                                  <p className="mob-notification-label">
                                                    Platform
                                                  </p>
                                                  <p className="pushnotification-selecttech">
                                                    {(() => {
                                                      if (
                                                        res.device_type ===
                                                        "android"
                                                      ) {
                                                        return (
                                                          <>
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              width="18.591"
                                                              height="22"
                                                              viewBox="0 0 18.591 22"
                                                            >
                                                              <path
                                                                id="Path_7126"
                                                                data-name="Path 7126"
                                                                d="M1.376,15.116A1.3,1.3,0,0,1,0,13.912V8.761A1.3,1.3,0,0,1,1.376,7.556,1.3,1.3,0,0,1,2.753,8.761v5.151A1.3,1.3,0,0,1,1.376,15.116Zm10.411,5.037,1.2,1.2a.379.379,0,0,1-.537.536l-1.48-1.478a7.024,7.024,0,0,1-3.32,0L6.175,21.889a.379.379,0,1,1-.537-.536l1.2-1.193A4.85,4.85,0,0,1,3.45,15.8c0-.023,11.706,0,11.706,0A4.843,4.843,0,0,1,11.788,20.153ZM7.582,17.178a.687.687,0,1,0,.689.687A.687.687,0,0,0,7.582,17.178Zm3.444,0a.687.687,0,1,0,.689.687A.687.687,0,0,0,11.025,17.178Zm6.19-2.062a1.3,1.3,0,0,1-1.377-1.2V8.761a1.389,1.389,0,0,1,2.753,0v5.151A1.3,1.3,0,0,1,17.216,15.116ZM3.45,6.875a2.063,2.063,0,0,1,2.059-2.06V1.205A1.3,1.3,0,0,1,6.886,0,1.3,1.3,0,0,1,8.261,1.205V4.814H10.33V1.205A1.3,1.3,0,0,1,11.706,0a1.3,1.3,0,0,1,1.376,1.205V4.814h.009a2.063,2.063,0,0,1,2.066,2.06v8.243H3.451V6.875Z"
                                                                transform="translate(18.591 22) rotate(180)"
                                                                fill="#3ddb86"
                                                              />
                                                            </svg>{" "}
                                                            {/* Android{" "} */}
                                                          </>
                                                        );
                                                      } else if (
                                                        res.device_type ===
                                                        "ios"
                                                      ) {
                                                        return (
                                                          <>
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              width="18.417"
                                                              height="22"
                                                              viewBox="0 0 18.417 22"
                                                            >
                                                              <g
                                                                id="apple-logo"
                                                                transform="translate(0)"
                                                              >
                                                                <g
                                                                  id="Group_1428"
                                                                  data-name="Group 1428"
                                                                  transform="translate(0 0)"
                                                                >
                                                                  <path
                                                                    id="Path_668"
                                                                    data-name="Path 668"
                                                                    d="M55.02,116.775a5.034,5.034,0,0,1-.912-2.948,4.711,4.711,0,0,1,.859-2.737,8.568,8.568,0,0,1,1.507-1.56,6.818,6.818,0,0,0-1.375-1.322,4.834,4.834,0,0,0-2.816-.859A7.461,7.461,0,0,0,50,107.8a6.621,6.621,0,0,1-1.891.45,8.505,8.505,0,0,1-1.825-.4,8.8,8.8,0,0,0-2.34-.4,4.564,4.564,0,0,0-3.755,1.9,7.844,7.844,0,0,0-1.494,4.945,13.554,13.554,0,0,0,1.944,6.65q1.97,3.424,3.979,3.424a4.779,4.779,0,0,0,1.745-.45,5.1,5.1,0,0,1,1.878-.436,5.846,5.846,0,0,1,2,.423,6.112,6.112,0,0,0,1.851.423q1.692,0,3.4-2.591a12.762,12.762,0,0,0,1.626-3.305A4.022,4.022,0,0,1,55.02,116.775Z"
                                                                    transform="translate(-38.692 -102.378)"
                                                                    fill="#666"
                                                                  />
                                                                  <path
                                                                    id="Path_669"
                                                                    data-name="Path 669"
                                                                    d="M236.488,4.178a5.427,5.427,0,0,0,1.23-1.824,5.044,5.044,0,0,0,.383-1.8c0-.035,0-.079-.007-.132s-.006-.1-.006-.132a1.28,1.28,0,0,1-.033-.145A1.224,1.224,0,0,0,238.021,0a5.269,5.269,0,0,0-3.305,1.957,6.03,6.03,0,0,0-1.031,3.4,7.388,7.388,0,0,0,1.375-.225A3.747,3.747,0,0,0,236.488,4.178Z"
                                                                    transform="translate(-224.655 0)"
                                                                    fill="#666"
                                                                  />
                                                                </g>
                                                              </g>
                                                            </svg>{" "}
                                                            {/* iOS */}
                                                          </>
                                                        );
                                                      } else if (
                                                        res.device_type ===
                                                        "both"
                                                      ) {
                                                        return (
                                                          <>
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              width="18.591"
                                                              height="22"
                                                              viewBox="0 0 18.591 22"
                                                            >
                                                              <path
                                                                id="Path_7126"
                                                                data-name="Path 7126"
                                                                d="M1.376,15.116A1.3,1.3,0,0,1,0,13.912V8.761A1.3,1.3,0,0,1,1.376,7.556,1.3,1.3,0,0,1,2.753,8.761v5.151A1.3,1.3,0,0,1,1.376,15.116Zm10.411,5.037,1.2,1.2a.379.379,0,0,1-.537.536l-1.48-1.478a7.024,7.024,0,0,1-3.32,0L6.175,21.889a.379.379,0,1,1-.537-.536l1.2-1.193A4.85,4.85,0,0,1,3.45,15.8c0-.023,11.706,0,11.706,0A4.843,4.843,0,0,1,11.788,20.153ZM7.582,17.178a.687.687,0,1,0,.689.687A.687.687,0,0,0,7.582,17.178Zm3.444,0a.687.687,0,1,0,.689.687A.687.687,0,0,0,11.025,17.178Zm6.19-2.062a1.3,1.3,0,0,1-1.377-1.2V8.761a1.389,1.389,0,0,1,2.753,0v5.151A1.3,1.3,0,0,1,17.216,15.116ZM3.45,6.875a2.063,2.063,0,0,1,2.059-2.06V1.205A1.3,1.3,0,0,1,6.886,0,1.3,1.3,0,0,1,8.261,1.205V4.814H10.33V1.205A1.3,1.3,0,0,1,11.706,0a1.3,1.3,0,0,1,1.376,1.205V4.814h.009a2.063,2.063,0,0,1,2.066,2.06v8.243H3.451V6.875Z"
                                                                transform="translate(18.591 22) rotate(180)"
                                                                fill="#3ddb86"
                                                              />
                                                            </svg>
                                                            <svg
                                                              style={{
                                                                marginLeft:
                                                                  "20px",
                                                              }}
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              width="18.417"
                                                              height="22"
                                                              viewBox="0 0 18.417 22"
                                                            >
                                                              <g
                                                                id="apple-logo"
                                                                transform="translate(0)"
                                                              >
                                                                <g
                                                                  id="Group_1428"
                                                                  data-name="Group 1428"
                                                                  transform="translate(0 0)"
                                                                >
                                                                  <path
                                                                    id="Path_668"
                                                                    data-name="Path 668"
                                                                    d="M55.02,116.775a5.034,5.034,0,0,1-.912-2.948,4.711,4.711,0,0,1,.859-2.737,8.568,8.568,0,0,1,1.507-1.56,6.818,6.818,0,0,0-1.375-1.322,4.834,4.834,0,0,0-2.816-.859A7.461,7.461,0,0,0,50,107.8a6.621,6.621,0,0,1-1.891.45,8.505,8.505,0,0,1-1.825-.4,8.8,8.8,0,0,0-2.34-.4,4.564,4.564,0,0,0-3.755,1.9,7.844,7.844,0,0,0-1.494,4.945,13.554,13.554,0,0,0,1.944,6.65q1.97,3.424,3.979,3.424a4.779,4.779,0,0,0,1.745-.45,5.1,5.1,0,0,1,1.878-.436,5.846,5.846,0,0,1,2,.423,6.112,6.112,0,0,0,1.851.423q1.692,0,3.4-2.591a12.762,12.762,0,0,0,1.626-3.305A4.022,4.022,0,0,1,55.02,116.775Z"
                                                                    transform="translate(-38.692 -102.378)"
                                                                    fill="#666"
                                                                  />
                                                                  <path
                                                                    id="Path_669"
                                                                    data-name="Path 669"
                                                                    d="M236.488,4.178a5.427,5.427,0,0,0,1.23-1.824,5.044,5.044,0,0,0,.383-1.8c0-.035,0-.079-.007-.132s-.006-.1-.006-.132a1.28,1.28,0,0,1-.033-.145A1.224,1.224,0,0,0,238.021,0a5.269,5.269,0,0,0-3.305,1.957,6.03,6.03,0,0,0-1.031,3.4,7.388,7.388,0,0,0,1.375-.225A3.747,3.747,0,0,0,236.488,4.178Z"
                                                                    transform="translate(-224.655 0)"
                                                                    fill="#666"
                                                                  />
                                                                </g>
                                                              </g>
                                                            </svg>
                                                            {/* iOS &amp; Android */}
                                                          </>
                                                        );
                                                      }
                                                    })()}
                                                  </p>
                                                </div>
                                                <div className="pushnotificationstatus-edit">
                                                  <div className=" newnotification-status mob-status-table statusdate-table">
                                                    <p className="mob-notification-label">
                                                      Status
                                                    </p>
                                                    {(() => {
                                                      if (
                                                        res.status === 2 &&
                                                        res.delivery_type === 2
                                                      ) {
                                                        return (
                                                          <p
                                                            className="newscheduled mr-3" // style={{ //   color: "blue", //   borderColor: "#3064F9", // }}
                                                          >
                                                            Scheduled{" "}
                                                          </p>
                                                        );
                                                      } else if (
                                                        res.status === 0
                                                      ) {
                                                        return (
                                                          <p
                                                            className="newscheduled mr-3" // style={{ //   color: "blue", //   borderColor: "#3064F9", // }}
                                                          >
                                                            Draft{" "}
                                                          </p>
                                                        );
                                                      } else if (
                                                        res.status === 0 &&
                                                        res.delivery_type === 1
                                                      ) {
                                                        return (
                                                          <p
                                                            className="newscheduled mr-3" // style={{ //   color: "blue", //   borderColor: "#3064F9", // }}
                                                          >
                                                            Draft{" "}
                                                          </p>
                                                        );
                                                      } else if (
                                                        res.status === 1
                                                      ) {
                                                        return (
                                                          <p
                                                            className="newscheduled mr-3"
                                                            style={{
                                                              color: "#52CB86",
                                                              // borderColor: "#47CE70",
                                                            }}
                                                          >
                                                            Sent
                                                          </p>
                                                        );
                                                      } else if (
                                                        res.status === 2
                                                      ) {
                                                        return (
                                                          <p className="newscheduled mr-3">
                                                            Scheduled
                                                          </p>
                                                        );
                                                      } else if (
                                                        res.status === 3
                                                      ) {
                                                        return (
                                                          <p
                                                            className="newscheduled mr-3"
                                                            style={{
                                                              color: "#e20000",
                                                            }}
                                                          >
                                                            Failed
                                                          </p>
                                                        );
                                                      }
                                                    })()}

                                                    {(() => {
                                                      if (
                                                        res.status === 0 &&
                                                        res.delivery_type === 2
                                                      ) {
                                                        return (
                                                          <p className="newnotification-time">
                                                            {moment(
                                                              res.delivery_date
                                                            ).format(
                                                              "D MMMM YYYY, HH:mm"
                                                            )}
                                                          </p>
                                                        );
                                                      } else if (
                                                        res.status === 0 &&
                                                        res.delivery_type === 1
                                                      ) {
                                                        return (
                                                          <p className="newnotification-time">
                                                            {moment(
                                                              res.created_at
                                                            ).format(
                                                              "D MMMM YYYY, HH:mm"
                                                            )}
                                                          </p>
                                                        );
                                                      } else if (
                                                        res.status === 1
                                                      ) {
                                                        return (
                                                          <p className="newnotification-time">
                                                            {moment(
                                                              res.delivery_date
                                                            ).format(
                                                              "D MMMM YYYY, HH:mm"
                                                            )}
                                                          </p>
                                                        );
                                                      } else if (
                                                        res.status === 2
                                                      ) {
                                                        return (
                                                          <p className="newnotification-time">
                                                            {moment(
                                                              res.delivery_date
                                                            ).format(
                                                              "D MMMM YYYY, HH:mm"
                                                            )}
                                                          </p>
                                                        );
                                                      } else if (
                                                        res.status === 3
                                                      ) {
                                                        return (
                                                          <p className="newnotification-time">
                                                            {moment(
                                                              res.delivery_date
                                                            ).format(
                                                              "D MMMM YYYY, HH:mm"
                                                            )}
                                                          </p>
                                                        );
                                                      }
                                                    })()}
                                                  </div>
                                                  <div className=" d-flex align-items-center justify-content-end message-delete">
                                                    {(res.status === 0 ||
                                                      res.status === 2 ||
                                                      res.status === 3) && (
                                                      <Link
                                                        to={
                                                          "/app/advanced-notification/" +
                                                          btoa(res.id)
                                                        }
                                                        className="pointcursor notification-delete"
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width="16.314"
                                                          height="16.314"
                                                          viewBox="0 0 16.314 16.314"
                                                        >
                                                          <g
                                                            id="Group_37916"
                                                            data-name="Group 37916"
                                                            transform="translate(12.01 0) rotate(45)"
                                                          >
                                                            <g
                                                              id="Path_82084"
                                                              data-name="Path 82084"
                                                              transform="translate(0)"
                                                              fill="none"
                                                            >
                                                              <path
                                                                d="M1.086,0h4a1,1,0,0,1,1,1l-.1,12.9L2.9,16.985,0,14.086.086,1A1,1,0,0,1,1.086,0Z"
                                                                stroke="none"
                                                              />
                                                              <path
                                                                d="M 1.085980415344238 0.9999980926513672 L 1.085960388183594 1.00656795501709 L 1.002727031707764 13.67448616027832 L 2.899492502212524 15.57125759124756 L 4.988701820373535 13.4820556640625 L 5.085980415344238 0.9999980926513672 L 1.085980415344238 0.9999980926513672 M 1.085980415344238 -1.9073486328125e-06 L 5.085980415344238 -1.9073486328125e-06 C 5.638260364532471 -1.9073486328125e-06 6.085980415344238 0.4477176666259766 6.085980415344238 0.9999980926513672 L 5.985480308532715 13.89949798583984 L 2.899490118026733 16.98547744750977 L 0 14.08597755432129 L 0.08598041534423828 0.9999980926513672 C 0.08598041534423828 0.4477176666259766 0.5336999893188477 -1.9073486328125e-06 1.085980415344238 -1.9073486328125e-06 Z"
                                                                stroke="none"
                                                                fill="#7782a1"
                                                              />
                                                            </g>
                                                            <path
                                                              id="Path_82085"
                                                              data-name="Path 82085"
                                                              d="M0,.017,5.643,0"
                                                              transform="translate(0.086 4.259)"
                                                              fill="none"
                                                              stroke="#7782a1"
                                                              stroke-width="1"
                                                            />
                                                          </g>
                                                        </svg>
                                                      </Link>
                                                    )}

                                                    {(res.status === 0 ||
                                                      res.status === 2 ||
                                                      res.status === 3) && (
                                                      <>
                                                        <Link
                                                          to="#"
                                                          onClick={(e) =>
                                                            handleDeleteNotificationPopup(
                                                              e,
                                                              res.id
                                                            )
                                                          }
                                                        >
                                                          {/* <img src={ require("../../assets/images/dashboard/red-cross.png").default } alt="red-cross" /> */}
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="14"
                                                            height="14"
                                                            viewBox="0 0 14 13.999"
                                                          >
                                                            <g
                                                              id="Group_38418"
                                                              data-name="Group 38418"
                                                              transform="translate(-835.516 -682.992)"
                                                            >
                                                              <g
                                                                id="Rectangle_2307"
                                                                data-name="Rectangle 2307"
                                                                transform="translate(836.515 684.992)"
                                                                fill="none"
                                                                stroke="#7782a1"
                                                                stroke-miterlimit="10"
                                                                stroke-width="1"
                                                              >
                                                                <path
                                                                  d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z"
                                                                  stroke="none"
                                                                />
                                                                <path
                                                                  d="M.5.5h11a0,0,0,0,1,0,0V10.7a.8.8,0,0,1-.8.8H1.3a.8.8,0,0,1-.8-.8V.5A0,0,0,0,1,.5.5Z"
                                                                  fill="none"
                                                                />
                                                              </g>
                                                              <g
                                                                id="Rectangle_2308"
                                                                data-name="Rectangle 2308"
                                                                transform="translate(839.515 682.992)"
                                                                fill="none"
                                                                stroke="#7782a1"
                                                                stroke-miterlimit="10"
                                                                stroke-width="1"
                                                              >
                                                                <path
                                                                  d="M1,0H5A1,1,0,0,1,6,1V3A0,0,0,0,1,6,3H0A0,0,0,0,1,0,3V1A1,1,0,0,1,1,0Z"
                                                                  stroke="none"
                                                                />
                                                                <path
                                                                  d="M1,.5H5a.5.5,0,0,1,.5.5V2.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V1A.5.5,0,0,1,1,.5Z"
                                                                  fill="none"
                                                                />
                                                              </g>
                                                              <g
                                                                id="Group_3245"
                                                                data-name="Group 3245"
                                                                transform="translate(841.016 687.993)"
                                                              >
                                                                <line
                                                                  id="Line_110"
                                                                  data-name="Line 110"
                                                                  y2="6"
                                                                  transform="translate(3 0)"
                                                                  fill="none"
                                                                  stroke="#7782a1"
                                                                  stroke-miterlimit="10"
                                                                  stroke-width="1"
                                                                />
                                                                <line
                                                                  id="Line_111"
                                                                  data-name="Line 111"
                                                                  y1="6"
                                                                  transform="translate(0 0)"
                                                                  fill="none"
                                                                  stroke="#7782a1"
                                                                  stroke-miterlimit="10"
                                                                  stroke-width="1"
                                                                />
                                                              </g>
                                                              <line
                                                                id="Line_112"
                                                                data-name="Line 112"
                                                                x2="14"
                                                                transform="translate(835.516 685.492)"
                                                                fill="none"
                                                                stroke="#7782a1"
                                                                stroke-miterlimit="10"
                                                                stroke-width="1"
                                                              />
                                                            </g>
                                                          </svg>
                                                        </Link>
                                                      </>
                                                    )}

                                                    {res.status === 1 && (
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="18"
                                                        height="14"
                                                        viewBox="0 0 18 14"
                                                        onClick={(e) =>
                                                          sendNotificationReport(
                                                            res.id
                                                          )
                                                        }
                                                      >
                                                        <g
                                                          id="Group_38637"
                                                          data-name="Group 38637"
                                                          transform="translate(-1842 -402)"
                                                        >
                                                          <g
                                                            id="Rectangle_29261"
                                                            data-name="Rectangle 29261"
                                                            transform="translate(1843 408)"
                                                            fill="#fff"
                                                            stroke="#3064f9"
                                                            stroke-width="1"
                                                          >
                                                            <rect
                                                              width="4"
                                                              height="8"
                                                              stroke="none"
                                                            />
                                                            <rect
                                                              x="0.5"
                                                              y="0.5"
                                                              width="3"
                                                              height="7"
                                                              fill="none"
                                                            />
                                                          </g>
                                                          <g
                                                            id="Rectangle_29262"
                                                            data-name="Rectangle 29262"
                                                            transform="translate(1849 402)"
                                                            fill="#fff"
                                                            stroke="#3064f9"
                                                            stroke-width="1"
                                                          >
                                                            <rect
                                                              width="4"
                                                              height="14"
                                                              stroke="none"
                                                            />
                                                            <rect
                                                              x="0.5"
                                                              y="0.5"
                                                              width="3"
                                                              height="13"
                                                              fill="none"
                                                            />
                                                          </g>
                                                          <g
                                                            id="Rectangle_29263"
                                                            data-name="Rectangle 29263"
                                                            transform="translate(1855 405)"
                                                            fill="#fff"
                                                            stroke="#3064f9"
                                                            stroke-width="1"
                                                          >
                                                            <rect
                                                              width="4"
                                                              height="11"
                                                              stroke="none"
                                                            />
                                                            <rect
                                                              x="0.5"
                                                              y="0.5"
                                                              width="3"
                                                              height="10"
                                                              fill="none"
                                                            />
                                                          </g>
                                                          <rect
                                                            id="Rectangle_29264"
                                                            data-name="Rectangle 29264"
                                                            width="18"
                                                            height="1"
                                                            transform="translate(1842 415)"
                                                            fill="#3064f9"
                                                          />
                                                        </g>
                                                      </svg>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          })}
                                        {bottomSpinner && (
                                          <div
                                            className={`form-group`}
                                            style={{
                                              background: "none",
                                              margin: "auto",
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              xlink="http://www.w3.org/1999/xlink"
                                              style={{
                                                margin: "auto",
                                                background:
                                                  "rgb(241, 242, 243,0)",
                                                display: "block",
                                                shaperendering: "auto",
                                                animationplaystate: "running",
                                                animationdelay: "0s",
                                              }}
                                              width="200px"
                                              height="100px"
                                              viewBox="0 0 100 100"
                                              preserveAspectRatio="xMidYMid"
                                            >
                                              <g
                                                transform="translate(20 50)"
                                                style={{
                                                  animationplaystate: "running",
                                                  animationdelay: "0s",
                                                }}
                                              >
                                                <circle
                                                  cx="0"
                                                  cy="0"
                                                  r="6"
                                                  fill="#e6261f"
                                                  style={{
                                                    animationplaystate:
                                                      "running",
                                                    animationdelay: "0s",
                                                  }}
                                                >
                                                  <animateTransform
                                                    attributeName="transform"
                                                    type="scale"
                                                    begin="-0.375s"
                                                    calcMode="spline"
                                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                                    values="0;1;0"
                                                    keyTimes="0;0.5;1"
                                                    dur="1s"
                                                    repeatCount="indefinite"
                                                    style={{
                                                      animationplaystate:
                                                        "running",
                                                      animationdelay: "0s",
                                                    }}
                                                  ></animateTransform>
                                                </circle>
                                              </g>
                                              <g
                                                transform="translate(40 50)"
                                                style={{
                                                  animationplaystate: "running",
                                                  animationdelay: "0s",
                                                }}
                                              >
                                                <circle
                                                  cx="0"
                                                  cy="0"
                                                  r="6"
                                                  fill="#f7d038"
                                                  style={{
                                                    animationplaystate:
                                                      "running",
                                                    animationdelay: "0s",
                                                  }}
                                                >
                                                  <animateTransform
                                                    attributeName="transform"
                                                    type="scale"
                                                    begin="-0.25s"
                                                    calcMode="spline"
                                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                                    values="0;1;0"
                                                    keyTimes="0;0.5;1"
                                                    dur="1s"
                                                    repeatCount="indefinite"
                                                    style={{
                                                      animationplaystate:
                                                        "running",
                                                      animationdelay: "0s",
                                                    }}
                                                  ></animateTransform>
                                                </circle>
                                              </g>
                                              <g
                                                transform="translate(60 50)"
                                                style={{
                                                  animationplaystate: "running",
                                                  animationdelay: "0s",
                                                }}
                                              >
                                                <circle
                                                  cx="0"
                                                  cy="0"
                                                  r="6"
                                                  fill="#49da9a"
                                                  style={{
                                                    animationplaystate:
                                                      "running",
                                                    animationdelay: "0s",
                                                  }}
                                                >
                                                  <animateTransform
                                                    attributeName="transform"
                                                    type="scale"
                                                    begin="-0.125s"
                                                    calcMode="spline"
                                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                                    values="0;1;0"
                                                    keyTimes="0;0.5;1"
                                                    dur="1s"
                                                    repeatCount="indefinite"
                                                    style={{
                                                      animationplaystate:
                                                        "running",
                                                      animationdelay: "0s",
                                                    }}
                                                  ></animateTransform>
                                                </circle>
                                              </g>
                                              <g
                                                transform="translate(80 50)"
                                                style={{
                                                  animationplaystate: "running",
                                                  animationdelay: "0s",
                                                }}
                                              >
                                                <circle
                                                  cx="0"
                                                  cy="0"
                                                  r="6"
                                                  fill="#4355db"
                                                  style={{
                                                    animationplaystate:
                                                      "running",
                                                    animationdelay: "0s",
                                                  }}
                                                >
                                                  <animateTransform
                                                    attributeName="transform"
                                                    type="scale"
                                                    begin="0s"
                                                    calcMode="spline"
                                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                                    values="0;1;0"
                                                    keyTimes="0;0.5;1"
                                                    dur="1s"
                                                    repeatCount="indefinite"
                                                    style={{
                                                      animationplaystate:
                                                        "running",
                                                      animationdelay: "0s",
                                                    }}
                                                  ></animateTransform>
                                                </circle>
                                              </g>
                                            </svg>
                                          </div>
                                        )}
                                      </section>
                                    </div>
                                  ) : (
                                    <div className="w-100 text-center  pushnotification-notavalable">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/no-notification.png")
                                            .default
                                        }
                                        alt="nopushnotification"
                                        className="img-fluid"
                                      />
                                      <p>
                                        Nothing here at the moment. Come back
                                        later?
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              {/* {InnerFoot()} */}
            </div>
          )}
        </BottomScrollListener>

        <Modal
          isOpen={deletenotificationpopup}
          centered
          toggle={handleDeletePopupCancel}
          style={{ maxWidth: "480px", maxHeight: "270px" }}
          dataclass="newnotification"
        >
          <ModalHeader
            toggle={handleDeletePopupCancel}
            className="secondnotificationmodal"
          >
            <div className="d-md-flex new-pus_notification_head align-items-center justify-content-between ">
              <div
                className="newcrossmodaltwo"
                onClick={handleDeletePopupCancel}
              >
                <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                  <path
                    id="Union_18"
                    data-name="Union 18"
                    d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                    transform="translate(0.529 0.531)"
                    fill="#C8CEDB"
                    stroke="#C8CEDB"
                    strokeWidth="1.5"
                  />
                </svg>
              </div>
            </div>
          </ModalHeader>
          <ModalBody className="secondnewpush-notification-body">
            <div>
              <div className="d-flex justify-content-center pushnotification-delete">
                <div className="secondmodal-texts">
                  <h2>Delete this notification?</h2>

                  <p className="pushnotification-longtext">
                    Are you sure you want to proceed? Deleting this notification
                    is permanent and cannot be undone. This action will remove
                    your notification and all associated data.
                  </p>
                </div>
              </div>

              <div className=" pushnotification-finalselection">
                <div
                  className="mob-delete-notification"
                  onClick={handleDeleteNotification}
                >
                  <div className="d-flex align-items-center justify-content-center h-100 w-100">
                    <p>
                      <div className={removeloader}>
                        <div className="new-red-loader"></div>
                      </div>
                      <div>{confirmpoplabel}</div>
                    </p>
                  </div>
                </div>
                <div onClick={handleDeletePopupCancel}>
                  <p>Cancel</p>
                </div>

                <div
                  className="web-delete-notification"
                  onClick={handleDeleteNotification}
                >
                  <div className="d-flex align-items-center justify-content-center h-100 w-100">
                    <p>
                      <div className={removeloader}>
                        <div className="new-red-loader"></div>
                      </div>
                      <div>{confirmpoplabel}</div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <OutsideClickHandler
          onOutsideClick={(e) => handleNtfnFilterOutsideClick(e)}
        >
          <div
            className={
              isfilteropen === true
                ? "pushnotification-filteropen"
                : "pushnotification-filterclose"
            }
          >
            <div className="d-flex justify-content-between ">
              <h4 className="push-filterhead">Filter</h4>
              <p
                className="push-resetbtn"
                onClick={(e) => handleResetFilter(e)}
              >
                Reset
              </p>
            </div>
            <div className="myapps-filter-seconddiv">
              {/*<Select classNamePrefix="select-filter" placeholder="Select" isClearable={false} isSearchable={false} >
                        </Select> */}
              {filterSelectionArr &&
                filterSelectionArr.map((row, indx) => {
                  return (
                    <div key={indx}>
                      {indx > 0 && <h2 className="andpushnotificaion">AND</h2>}
                      <div className="post-filter-dropdown">
                        {handleReturnMainFilter(row.value, indx)}

                        <FilterPopup
                          key={indx}
                          handleMainFilterChange={handleMainFilterChange}
                          handleOpenParentDropdown={handleOpenParentDropdown}
                          openMobParentFilter={
                            indx === 0
                              ? openMobParentFilter1
                              : indx === 1
                              ? openMobParentFilter2
                              : openMobParentFilter3
                          }
                          optionForMainFilter={optionForMainFilter}
                          filterSelectionArr={filterSelectionArr}
                          index={indx}
                          value={row.value}
                        />
                      </div>
                      <div className="newdelete-post-filter">
                        {returnSelectedFilter(row.value, indx)}
                        {row.value && returnDeleteButton(row.value, indx)}
                      </div>
                    </div>
                  );
                })}
            </div>
            {/*<div className="pushdelete-fieldbtn">
                        <input type="text" className="pushNotificationfilter-input" />
                        <div  className="pushdelete-fieldsvg">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14.999" viewBox="0 0 15 14.999">
                                <g id="Group_38004" data-name="Group 38004" transform="translate(-15 -15)">
                                    <g id="Group_3246" data-name="Group 3246" transform="translate(15 15.5)">
                                        <path id="Rectangle_2307" data-name="Rectangle 2307" d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z" transform="translate(1.5 1.999)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" strokeWidth="1"/>
                                        <path id="Rectangle_2308" data-name="Rectangle 2308" d="M1,0H4A1,1,0,0,1,5,1V2A0,0,0,0,1,5,2H0A0,0,0,0,1,0,2V1A1,1,0,0,1,1,0Z" transform="translate(5)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" strokeWidth="1"/>
                                        <g id="Group_3245" data-name="Group 3245" transform="translate(5.5 5.5)">
                                            <line id="Line_110" data-name="Line 110" y2="5" transform="translate(4 0)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" strokeWidth="1"/>
                                            <line id="Line_111" data-name="Line 111" y1="5" transform="translate(0 0)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" strokeWidth="1"/>
                                        </g>
                                        <line id="Line_112" data-name="Line 112" x2="15" transform="translate(0 1.999)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" strokeWidth="1"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <h2 className="andpushnotificaion">AND</h2>*/}
            {filterSelectionArr.length < 3 && (
              <div className="pushNotification-filteradd">
                <img
                  src={
                    require("../../assets/images/dashboard/plus.png").default
                  }
                  alt="plus"
                  className="img-fluid"
                />
                <p className="add-more-field" onClick={handleAddField}>
                  {" "}
                  Add Field{" "}
                </p>
              </div>
            )}
            <div className=" advance-search-button">
              <button
                style={{
                  "--custom_color4":
                    localStorage.getItem("agencyid") == 1
                      ? "#ffffff"
                      : localStorage.getItem("agency_primary_color"),
                  "--custom_color5":
                    localStorage.getItem("agencyid") == 1
                      ? "#3064f9"
                      : localStorage.getItem("agency_primary_color"),
                }}
                className={
                  (startdate && enddate) ||
                  (devicefilterval !== null && devicefilterval !== undefined) ||
                  (statusfilterval !== null && statusfilterval !== undefined) ||
                  resetenable
                    ? "filter-buttonenable"
                    : "filter-buttondisabled"
                }
                onClick={(e) =>
                  (startdate && enddate) ||
                  (devicefilterval !== null && devicefilterval !== undefined) ||
                  (statusfilterval !== null && statusfilterval !== undefined) ||
                  resetenable
                    ? handleFilterSubmit(e)
                    : null
                }
              >
                Search
              </button>

              {/*<button className="filter-buttonenable" > Search </button>
                    <button className=" filter-buttondisabled"> Search </button>*/}
            </div>
          </div>
        </OutsideClickHandler>
      </section>
    </>
  );
};

export { NotificationsList as default };
