import React from "react";
import { Link } from "react-router-dom";
import SkipBtn from "../../../Components/commonIcons/SkipBtn";

const MergeAppNewUpgradePage = ({ mergeState,handleSkipClick }) => {
  return (
    <div className="newmergeapp-updatediv  ">
      <div className="newmergeapp-updatediv-left">
        <div>
          <h2>Merge multiple apps into one</h2>

          <p>Combine your apps into a single mobile app. </p>

          <ul>
            <li>Publish one app instead of multiple apps.</li>
            <li>
              Allow users to effortlessly switch between your merged apps.
            </li>
            <li>Auto-selection based on app user’s geolocation.</li>
          </ul>

          <div className="upgrade-skip-merge">
          {mergeState.plan_code.indexOf(process.env.REACT_APP_Lifetime_plan) >
            -1 ||
          mergeState.plan_code.indexOf(process.env.REACT_APP_Lifetime_preview) >
            -1 ||
          mergeState.reseller_package_id ? (
            (!localStorage.getItem("workspaceId") ||
              localStorage.getItem("workspaceId") === "undefined") &&
            (mergeState.appuser_role_id == 3 ||
            mergeState.appuser_role_id == 4 ||
            parseInt(localStorage.getItem("logged_in_user_role_id")) === 3 ||
            parseInt(localStorage.getItem("logged_in_user_role_id")) === 4 ? (
              <button className="disable-role-btn" disabled>
                {" "}
                Contact your admin
              </button>
            ) : (
              <Link to={"/app/addons"}>Buy addon</Link>
            ))
          ) : mergeState.appuser_role_id == 3 ||
            mergeState.appuser_role_id == 4 ||
            parseInt(localStorage.getItem("logged_in_user_role_id")) === 3 ||
            parseInt(localStorage.getItem("logged_in_user_role_id")) === 4 ? (
            <button className="disable-role-btn" disabled>
              Contact your admin
            </button>
          ) : localStorage.getItem("agencyid") == "" ||
            localStorage.getItem("agencyid") == undefined ||
            localStorage.getItem("agencyid") == null ||
            localStorage.getItem("agencyid") == "undefined" ||
            localStorage.getItem("agencyid") == "null" ||
            localStorage.getItem("agencyid") == 1 ? (
            <Link
              to={
                localStorage.getItem("workspaceId") !== "" &&
                localStorage.getItem("workspaceId") !== undefined &&
                localStorage.getItem("workspaceId") !== "undefined" &&
                localStorage.getItem("workspaceId") !== null
                  ? `/workspace/pricing/${btoa(
                      localStorage.getItem("workspaceId")
                    )}`
                  : parseInt(localStorage.getItem("website_technology")) === 4
                  ? "/app/wordpress-pricing/" +
                    btoa(localStorage.getItem("Appid"))
                  : parseInt(localStorage.getItem("website_technology")) === 5
                  ? "/app/woocommerce-pricing/" +
                    btoa(localStorage.getItem("Appid"))
                  : parseInt(localStorage.getItem("website_technology")) === 3
                  ? "/app/custom-app-pricing/" +
                    btoa(localStorage.getItem("Appid"))
                  : parseInt(localStorage.getItem("website_technology")) === 2
                  ? "/app/web-to-app-pricing/" +
                    btoa(localStorage.getItem("Appid"))
                  : "/app/pricing"
              }
            >
              Upgrade
            </Link>
          ) : null}

          <div className="skip-btn-head" onClick={handleSkipClick}>
            <SkipBtn/>
          </div>

          </div>
        </div>
      </div>

      <div className="newmergeapp-updatediv-right">
        <img
          src={
            require("../../../assets/images/dashboard/upgrademerge.png").default
          }
          alt="publishUpgrade-image"
        />
      </div>
    </div>
  );
};

export default MergeAppNewUpgradePage;
