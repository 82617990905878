import React from 'react'

const DeleteAccount = ({profileData, delete_accountmodal, deleteapps}) => {
  return (
    <div className="new-my-profile-thirdshadow">
                      <div className="new-profile-delete ">
                        <div>
                          <h3 className='profile-delete-head'>Delete your account</h3>
                          <h5 className='profile-delete-text'>
                            This action is irreversible. You cannot retrieve
                            your data once removed.
                          </h5>
                        </div>

                        <div className="">
                          {profileData.apps_count === 0 ? (
                            <div
                              onClick={(e) => delete_accountmodal(e)}
                              className="new-my-profile-delete"
                            >
                              Delete account
                            </div>
                          ) : (
                            <div
                              onClick={(e) => deleteapps(e)}
                              className="new-my-profile-delete"
                            >
                              Delete account
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
  )
}

export default DeleteAccount