import React from "react";
import { Modal, ModalBody } from "reactstrap";
import backIc from "../../../assets/images/signup/back.png";
import { FILTER_STATUS } from "../../../Constants/notificationConsts";

const MobStatusFilterModal = ({ notificationData, setNotificationData }) => {
  const handleStatusFilterChange = (val) =>
    setNotificationData({
      statusFilterVal: val,
      openMobStatusFilter: false,
    });

  return (
    <Modal
      className="protip-popup-message"
      isOpen={
        window.matchMedia("(max-width: 1200px)").matches
          ? notificationData.openMobStatusFilter
          : false
      }
      centered
    >
      <ModalBody>
        <div className="mobileglobalsearch">
          <button
            onClick={() =>
              setNotificationData({
                openMobStatusFilter: !notificationData.openMobStatusFilter,
              })
            }
          >
            <img src={backIc} alt="back" />
          </button>
        </div>

        <div className="mobilesearchsuggestion">
          <p
            className="mobilesearchsuggestion-para"
            onClick={() => handleStatusFilterChange(FILTER_STATUS.DRAFT)}
          >
            Draft
          </p>

          <p
            className="mobilesearchsuggestion-para"
            onClick={() => handleStatusFilterChange(FILTER_STATUS.SCHEDULED)}
          >
            Scheduled
          </p>
          <p
            className="mobilesearchsuggestion-para"
            onClick={() => handleStatusFilterChange(FILTER_STATUS.SENT)}
          >
            Sent
          </p>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default MobStatusFilterModal;
