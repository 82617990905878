import React from "react";
import "../assets/css/Dashboard.css";
import Header from "./Header";
import Accountleftpanel from "./Accountleftpanel";

import {
  Changepasswordapi,
  GetuserprofileApi,
} from "../Api/Dashboard/DashboardApi";

import Tooltip from "../Dashboard/Tooltip";
import { Foot } from "../Dashboard/Foot";
import { errortoast } from "./Toaster";
import ReCAPTCHA from "react-google-recaptcha";
import "../assets/css/profile.css";
export default class Changepassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      old_password: "",
      new_password: "",
      confirm_password: "",
      submitval: "SAVE PASSWORD",
      loader: "d-none",
      loaderimage: require("../assets/images/signup/Loader_GIF.gif").default,
      old_passworderror: null,
      new_passworderror: null,
      confirm_passworderror: null,
      passwordchange: null,
      eyeimage1: require("../assets/images/signup/secure.png").default,
      passattibutetype1: "password",
      passattribute1: true,
      eyeimage2: require("../assets/images/signup/secure.png").default,
      passattibutetype2: "password",
      passattribute2: true,
      eyeimage3: require("../assets/images/signup/secure.png").default,
      passattibutetype3: "password",
      passattribute3: true,
      captchavalue: "",
      progress: 0,
      count: 0,
    };
  }
  componentDidMount() {
    // document.title = "Change Password";
      if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
      {
        document.title = "Change Password | AppMySite";
        
      }
      else
      {
        document.title = "Change Password |"+ ' ' +localStorage.getItem('agencyname');
      }
    GetuserprofileApi(this);
  }
  handlechangepass = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handlechangeNewpass = (e) => {
    

    const passwordValue = e.target.value;
    const passwordLength = passwordValue.length;

    const weakRegExp = /[a-z]/;
    const almostRegExp = /[0-9]/;
    const strongRegExp = /[A-Z]/;

    var PoorPassword = false;
    var WeakPassword = false;
    var AlmostPassword = false;
    var StrongPassword = false;

    if (passwordLength >= 8) {
      PoorPassword = true;
    }

    if (passwordLength < 8) {
      PoorPassword = false;
    }

    if (weakRegExp.test(passwordValue)) {
      WeakPassword = true;
    } else {
      WeakPassword = false;
    }

    if (almostRegExp.test(passwordValue)) {
      AlmostPassword = true;
    } else {
      AlmostPassword = false;
    }

    if (strongRegExp.test(passwordValue)) {
      StrongPassword = true;
    } else {
      StrongPassword = false;
    }

    // if(passwordLength >= 72)
    // {
    //     PoorPassword=false;
    // }

    const arr1 = [PoorPassword, WeakPassword, AlmostPassword, StrongPassword];
    const count1 = arr1.filter((value) => value === true).length;
    
    this.setState({ progress: count1 });

    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onChange = (value) => {
    this.setState({
      captchavalue: value,
    });
  };
  submitchangepassword = (e) => {
    e.preventDefault();

    if (this.state.captchavalue === "") {
      errortoast("Captcha is required");
      return;
    }
    const params = {
      old_password: btoa(this.state.old_password),
      new_password: btoa(this.state.new_password),
      confirm_password: btoa(this.state.confirm_password),
    };
    Changepasswordapi(params, this);
  };

  //for eye button

  changepass = (e, checker) => {
    if (checker === 1) {
      this.setState({
        passattribute1: !this.state.passattribute1,
      });
      if (this.state.passattribute1) {
        this.setState({
          passattibutetype1: "text",
          eyeimage1: require("../assets/images/signup/eyeopen.png").default,
        });
      } else {
        this.setState({
          passattibutetype1: "password",
          eyeimage1: require("../assets/images/signup/secure.png").default,
        });
      }
    } else if (checker === 2) {
      this.setState({
        passattribute2: !this.state.passattribute2,
      });
      if (this.state.passattribute2) {
        this.setState({
          passattibutetype2: "text",
          eyeimage2: require("../assets/images/signup/eyeopen.png").default,
        });
      } else {
        this.setState({
          passattibutetype2: "password",
          eyeimage2: require("../assets/images/signup/secure.png").default,
        });
      }
    } else if (checker === 3) {
      this.setState({
        passattribute3: !this.state.passattribute3,
      });
      if (this.state.passattribute3) {
        this.setState({
          passattibutetype3: "text",
          eyeimage3: require("../assets/images/signup/eyeopen.png").default,
        });
      } else {
        this.setState({
          passattibutetype3: "password",
          eyeimage3: require("../assets/images/signup/secure.png").default,
        });
      }
    }
  };

  render() {
    
    return (
      <>
        <Header
          customclass={
            "header-ipad-changepassword header-responsive-myapps-new"
          }
        />
        {/* <div className="d-none d-md-block">
            <Header />
			  </div> */}
        {/* Mobile View */}
        {/* <Mobiletop name={"Change Password"} imgpath = {null}/> */}
        {/* Mobile View End*/}
        <section className="dasboard_page mobile-display mt-0 mobile-view-password">
          <div className="d-md-none d-block">{/*   <Leftpanel />*/}</div>
          <Accountleftpanel />

          <div className="transparent_background transparent_background_changepassword my-profile">
            {/* <h1 className="d-none d-md-block ">Change Password</h1>
            <div className="app_iconname">
              <h5 className=" mb-4 pl-3 pl-md-0 pr-3 pr-md-0 pt-2">
                Fill the details below to change your account password.{" "}
                <a
                  href="https://support.appmysite.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn More{" "}
                  <img
                    src={require("../assets/images/dashboard/Goto.png").default}
                    className="gotoappicon appear-spacing"
                    alt="Goto"
                  />
                </a>{" "}
              </h5>
            </div> */}
            <div
              className="right_panel right_panel_changepassword pb-4"
              style={{ minHeight: "80vh" }}
            >
              <h1 className="d-md-none d-block pl-3 mt-3 ">Change Password</h1>

              <div className="right_general change-password change-password-new d-flex">
                <div className="your-info pl-md-0">
                  <div className="customiseappfeatures customise-ipad-password ">
                    <h1 className="d-none d-md-block ">Change Password</h1>
                    <div className="app_iconname">
                      <h5 className=" mb-4 pl-3 pl-md-0 pr-3 pr-md-0 pt-2 profile-head-border profile-ipad-change">
                        Fill the details below to change your account password.{" "}
                        {/* <a
                          href="https://support.appmysite.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Learn More{" "}
                          <img
                            src={
                              require("../assets/images/dashboard/Goto.png")
                                .default
                            }
                            className="gotoappicon appear-spacing"
                            alt="Goto"
                          />
                        </a> */}
                        {" "}
                      </h5>
                    </div>
                    <div className="d-md-flex d-block justify-content-between">
                      <div className="password-page mr-5">
                        <form onSubmit={this.submitchangepassword}>
                          <div className="color_picker mb-3 ">
                            {/* Tool tip */}
                            <Tooltip
                              firsttag={"h4"}
                              tagclass={"mb-0 mr-2"}
                              classdata={
                                "d-flex justify-content-start align-items-center mb-1"
                              }
                              heading={"Current password"}
                              content={"Enter your current password."}
                            />
                            {/* Tool tip */}
                            <div className="form-group">
                              <input
                                type={this.state.passattibutetype1}
                                maxLength={72}
                                required
                                className="form-control input-mobile-space"
                                name="old_password"
                                value={this.state.old_password}
                                onChange={(e) => this.handlechangepass(e)}
                                autoComplete="off"
                                onPaste={(e) => {
                                  e.preventDefault();
                                  return false;
                                }}
                                onCopy={(e) => {
                                  e.preventDefault();
                                  return false;
                                }}
                              />
                              <div
                                className="eye"
                                onClick={(e) => this.changepass(e, 1)}
                              >
                                <img
                                  src={this.state.eyeimage1}
                                  className="img-fluid"
                                  alt="secure"
                                />
                              </div>
                            </div>
                            <div className="d-flex align-items-center mt-md-1 error">
                              <div>
                                {this.state.old_passworderror ? (
                                  <div className="d-flex align-items-center mt-md-1 error">
                                    <img
                                      src={
                                        require("../assets/images/signup/error.png")
                                          .default
                                      }
                                      className="img-fluid"
                                      alt="error"
                                    />
                                    <p>{this.state.old_passworderror}</p>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="color_picker mb-3">
                            {/* Tool tip */}
                            <Tooltip
                              firsttag={"h4"}
                              tagclass={"mb-0 mr-2"}
                              classdata={
                                "d-flex justify-content-start align-items-center mb-1"
                              }
                              heading={"New Password"}
                              content={"Enter a new password."}
                            />
                            {/* Tool tip */}
                            <div className="form-group">
                              <input
                                type={this.state.passattibutetype2}
                                maxLength={72}
                                required
                                className="form-control input-mobile-space"
                                name="new_password"
                                value={this.state.new_password}
                                onChange={(e) => this.handlechangeNewpass(e)}
                                autoComplete="off"
                                onPaste={(e) => {
                                  e.preventDefault();
                                  return false;
                                }}
                                onCopy={(e) => {
                                  e.preventDefault();
                                  return false;
                                }}
                              />
                              <div
                                className="eye"
                                onClick={(e) => this.changepass(e, 2)}
                              >
                                <img
                                  src={this.state.eyeimage2}
                                  className="img-fluid"
                                  alt="secure"
                                />
                              </div>
                            </div>
                            <div className="d-flex align-items-center mt-md-1 error">
                              <div>
                                {this.state.new_passworderror ? (
                                  <div className="d-flex align-items-center mt-md-1 error">
                                    <img
                                      src={
                                        require("../assets/images/signup/error.png")
                                          .default
                                      }
                                      className="img-fluid"
                                      alt="error"
                                    />
                                    <p>{this.state.new_passworderror}</p>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="color_picker mb-3">
                            {/* Tool tip */}
                            <Tooltip
                              firsttag={"h4"}
                              tagclass={"mb-0 mr-2"}
                              classdata={
                                "d-flex justify-content-start align-items-center mb-1"
                              }
                              heading={"Confirm new password"}
                              content={"Re-enter the new password."}
                            />
                            {/* Tool tip */}
                            <div className="form-group">
                              <input
                                type={this.state.passattibutetype3}
                                maxLength={72}
                                required
                                className="form-control input-mobile-space"
                                name="confirm_password"
                                value={this.state.confirm_password}
                                onChange={(e) => this.handlechangepass(e)}
                                autoComplete="off"
                                onPaste={(e) => {
                                  e.preventDefault();
                                  return false;
                                }}
                                onCopy={(e) => {
                                  e.preventDefault();
                                  return false;
                                }}
                              />
                              <div
                                className="eye"
                                onClick={(e) => this.changepass(e, 3)}
                              >
                                <img
                                  src={this.state.eyeimage3}
                                  className="img-fluid"
                                  alt="secure"
                                />
                              </div>
                            </div>
                            <div className="d-flex align-items-center mt-md-1 error">
                              <div>
                                {this.state.confirm_passworderror ? (
                                  <div className="d-flex align-items-center mt-md-1 error">
                                    <img
                                      src={
                                        require("../assets/images/signup/error.png")
                                          .default
                                      }
                                      className="img-fluid"
                                      alt="error"
                                    />
                                    <p>{this.state.confirm_passworderror}</p>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div>
                                {this.state.passwordchange ? (
                                  <div className="d-flex align-items-center mt-md-1 confirm">
                                    <p className="confirm">
                                      {this.state.passwordchange}
                                    </p>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="progressbar">
                            <div>
                              <>
                                {this.state.progress == 0 ? (
                                  <>
                                    <progress
                                      id="file"
                                      max="100"
                                      value="0"
                                      className=""
                                    >
                                      {" "}
                                    </progress>
                                    <progress
                                      id="file"
                                      max="100"
                                      value="0"
                                      className=""
                                    >
                                      {" "}
                                    </progress>
                                    <progress
                                      id="file"
                                      max="100"
                                      value="0"
                                      className=""
                                    >
                                      {" "}
                                    </progress>
                                    <progress
                                      id="file"
                                      max="100"
                                      value="0"
                                      className=""
                                    >
                                      {" "}
                                    </progress>
                                  </>
                                ) : this.state.progress == 1 ? (
                                  <>
                                    <progress
                                      id="file"
                                      max="100"
                                      value="100"
                                      className="redprogress"
                                    >
                                      {" "}
                                    </progress>
                                    <progress
                                      id="file"
                                      max="100"
                                      value="0"
                                      className=""
                                    >
                                      {" "}
                                    </progress>
                                    <progress
                                      id="file"
                                      max="100"
                                      value="0"
                                      className=""
                                    >
                                      {" "}
                                    </progress>
                                    <progress
                                      id="file"
                                      max="100"
                                      value="0"
                                      className=""
                                    >
                                      {" "}
                                    </progress>
                                  </>
                                ) : this.state.progress == 2 ? (
                                  <>
                                    <progress
                                      id="file"
                                      max="100"
                                      value="100"
                                      className="orangeprogress"
                                    >
                                      {" "}
                                    </progress>
                                    <progress
                                      id="file"
                                      max="100"
                                      value="100"
                                      className="orangeprogress"
                                    >
                                      {" "}
                                    </progress>
                                    <progress
                                      id="file"
                                      max="100"
                                      value="0"
                                      className=""
                                    >
                                      {" "}
                                    </progress>
                                    <progress
                                      id="file"
                                      max="100"
                                      value="0"
                                      className=""
                                    >
                                      {" "}
                                    </progress>
                                  </>
                                ) : this.state.progress == 3 ? (
                                  <>
                                    <progress
                                      id="file"
                                      max="100"
                                      value="100"
                                      className="lightgreenprogress"
                                    >
                                      {" "}
                                    </progress>
                                    <progress
                                      id="file"
                                      max="100"
                                      value="100"
                                      className="lightgreenprogress"
                                    >
                                      {" "}
                                    </progress>
                                    <progress
                                      id="file"
                                      max="100"
                                      value="100"
                                      className="lightgreenprogress"
                                    >
                                      {" "}
                                    </progress>
                                    <progress
                                      id="file"
                                      max="100"
                                      value="0"
                                      className=""
                                    >
                                      {" "}
                                    </progress>
                                  </>
                                ) : this.state.progress == 4 ? (
                                  <>
                                    <progress
                                      id="file"
                                      max="100"
                                      value="100"
                                      className="greenprogress"
                                    >
                                      {" "}
                                    </progress>
                                    <progress
                                      id="file"
                                      max="100"
                                      value="100"
                                      className="greenprogress"
                                    >
                                      {" "}
                                    </progress>
                                    <progress
                                      id="file"
                                      max="100"
                                      value="100"
                                      className="greenprogress"
                                    >
                                      {" "}
                                    </progress>
                                    <progress
                                      id="file"
                                      max="100"
                                      value="100"
                                      className="greenprogress"
                                    >
                                      {" "}
                                    </progress>
                                  </>
                                ) : null}
                              </>
                            </div>
                            <p className="mb-2">
                              Password Strength:
                              {this.state.progress == 0 ? (
                                <span></span>
                              ) : this.state.progress == 1 ? (
                                <span> Weak</span>
                              ) : this.state.progress == 2 ? (
                                <span> Fair</span>
                              ) : this.state.progress == 3 ? (
                                <span> Almost</span>
                              ) : this.state.progress == 4 ? (
                                <span> Awesome</span>
                              ) : null}
                            </p>
                          </div>
                          <div className="customcheck customcheck-red mb-2">
                            <ReCAPTCHA
                              sitekey="6LeOmMAUAAAAAGj7NXf5SlZA25DNAfvL1HwxaAeP"
                              onChange={this.onChange}
                            />
                          </div>
                          <div className="pb-2 profile-detail-btn profile-detail-red pt-3">
                            <button className="next_btn">
                              <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                <div className={this.state.loader}>
                                  <img
                                    src={this.state.loaderimage}
                                    alt="loader"
                                  />
                                </div>{" "}
                                <div>{this.state.submitval}</div>
                              </div>
                            </button>
                          </div>
                        </form>
                      </div>
                      <div className="password-page ml-5 sm-ml-0  d-md-block">
                        <h3>Your password must contain</h3>
                        <ul>
                          <li
                            style={{
                              textDecorationLine:
                                this.state.new_password.length > 7
                                  ? "line-through"
                                  : "none",
                              color:
                                this.state.new_password.length > 7
                                  ? "#BCC1CE"
                                  : "#7782A1",
                            }}
                            className="change-pass-inst"
                          >
                            <svg
                              className="mr-2"
                              width="10.8"
                              height="8.121"
                              viewBox="0 0 10.8 8.121"
                            >
                              <path
                                id="Path_81896"
                                data-name="Path 81896"
                                d="M2.742,118.28l3.386,3.386,6-6"
                                transform="translate(-2.035 -114.959)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-width="2"
                              />
                            </svg>
                            At least 8 characters and up to 72 characters
                          </li>
                          <li
                            style={{
                              textDecorationLine: /[A-Z]/.test(
                                this.state.new_password
                              )
                                ? "line-through"
                                : "none",
                              color: /[A-Z]/.test(this.state.new_password)
                                ? "#BCC1CE"
                                : "#7782A1",
                            }}
                          >
                            <svg
                              className="mr-2"
                              width="10.8"
                              height="8.121"
                              viewBox="0 0 10.8 8.121"
                            >
                              <path
                                id="Path_81896"
                                data-name="Path 81896"
                                d="M2.742,118.28l3.386,3.386,6-6"
                                transform="translate(-2.035 -114.959)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-width="2"
                              />
                            </svg>
                            At least 1 upper case letter (A...Z)
                          </li>
                          <li
                            style={{
                              textDecorationLine: /[0-9]/.test(
                                this.state.new_password
                              )
                                ? "line-through"
                                : "none",
                              color: /[0-9]/.test(this.state.new_password)
                                ? "#BCC1CE"
                                : "#7782A1",
                            }}
                          >
                            <svg
                              className="mr-2"
                              width="10.8"
                              height="8.121"
                              viewBox="0 0 10.8 8.121"
                            >
                              <path
                                id="Path_81896"
                                data-name="Path 81896"
                                d="M2.742,118.28l3.386,3.386,6-6"
                                transform="translate(-2.035 -114.959)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-width="2"
                              />
                            </svg>
                            At least 1 number (0...9)
                          </li>
                          <li
                            style={{
                              textDecorationLine: /[a-z]/.test(
                                this.state.new_password
                              )
                                ? "line-through"
                                : "none",
                              color: /[a-z]/.test(this.state.new_password)
                                ? "#BCC1CE"
                                : "#7782A1",
                            }}
                          >
                            <svg
                              className="mr-2"
                              width="10.8"
                              height="8.121"
                              viewBox="0 0 10.8 8.121"
                            >
                              <path
                                id="Path_81896"
                                data-name="Path 81896"
                                d="M2.742,118.28l3.386,3.386,6-6"
                                transform="translate(-2.035 -114.959)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-width="2"
                              />
                            </svg>
                            At least 1 lower case letter (a...z)
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {Foot()} */}
          </div>
        </section>
      </>
    );
  }
}
