import React from "react";
import moment from "moment";
import "../../Layouts/Pages/Customers/customers.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const CustomerEntry = ({
  first,
  second,
  third,
  fourth,
  fifth,
  sixth,
  status,
  openActivateModal,
  openDeactivateModal,
}) => {
  return (
    <div className="customer-table-entry">
      <div className="customer-table-body-one">
        <h2 className="customer-name">
          {first} {second}
        </h2>
        <p className="customer-id">#{third}</p>
      </div>

      <div className="customer-table-body-two">
        <h3 className="customer-email">{fourth}</h3>
      </div>

      <div className="customer-table-body-three">
        <h3 className="customer-last-login">{sixth || "-"}</h3>
      </div>

      <div className="customer-table-body-four">
        <h3 className="customer-created">
          {fifth ? moment(fifth).format("D MMMM YYYY, H:mm") : "-"}
        </h3>
      </div>

      <div className="customer-table-body-five">
        <h4
          className={
            // status === 0 ? "customer-active-status" : "customer-blocked-status"
            "customer-active-status"
          }
        >
          {/* {status === 0 ? "Active" : "Blocked"} */}
          Active
        </h4>
        {/* <p
          className="block-unblock"
          onClick={() =>
            status === 0 ? openDeactivateModal(third) : openActivateModal(third)
          }
        >
          {status === 0 ? "Block user" : "Unblock user"}
        </p> */}
      </div>
    </div>
  );
};

export default CustomerEntry;
