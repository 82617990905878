import React from "react";
import moment from "moment";
import "../../Layouts/Pages/Customers/customers.css";

const MobileCustomerEntry = ({
  first,
  second,
  third,
  fourth,
  fifth,
  sixth,
  status,
  openActivateModal,
  openDeactivateModal,
}) => {
  return (
    <div className="customer-mobile-entry">
      <div className="customer-entry-section-one">
        <div>
          <h2 className="customer-entry-head">Customer</h2>
          <h3 className="customer-entry-name">
            {first} {second}
          </h3>
          <h4 className="customer-entry-id">#{third}</h4>
        </div>
        <h5
          className={
            // status === 0 ? "customer-active-status" : "customer-blocked-status"
            "customer-active-status"
          }
        >
          {/* {status === 0 ? "Active" : "Blocked"} */}
          Active
        </h5>
      </div>
      <div className="customer-entry-section-two">
        <div>
          <h2 className="customer-entry-head">Email</h2>
          <h3 className="customer-entry-datail">{fourth}</h3>
        </div>
      </div>
      <div className="customer-entry-section-two">
        <div>
          <h2 className="customer-entry-head">Last login</h2>
          <h3 className="customer-entry-datail">{sixth ? sixth : "-"}</h3>
        </div>
      </div>
      <div className="customer-entry-section-three">
        <div>
          <h2 className="customer-entry-head">Created</h2>
          <h3 className="customer-entry-datail">
            {moment(fifth).format("D MMMM YYYY, H:mm")}
          </h3>
        </div>
        {/* <p
          className="block-unblock-mob"
          onClick={() =>
            status === 0 ? openDeactivateModal(third) : openActivateModal(third)
          }
        >
          {status === 0 ? "Block user" : "Unblock user"}
        </p> */}
      </div>
    </div>
  );
};

export default MobileCustomerEntry;
