import React, { Suspense } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import {
  GetDownloadappdetailsapi,
  Savesubscriptionapi,
  DownloadBuildApi,
  cancelBuild,
} from "../../Api/Dashboard/DashboardApi";
import styles from "./Downloadapp.module.css";
const Multisitealert = React.lazy(() => import("./Multisitealert"));

export default class Buildcomponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      subscription: localStorage.getItem("subscriptionactive"),
      urlvar: "",
      spinner: true,
      modalspinner: true,
      paymentgateway: 0,
      personalisation: 0,
      connectivity: 0,
      website_type: undefined,
      is_verified: undefined,
      plugin_data: undefined,
      store_url: undefined,
      modal: false,
      androidmodal: false,
      zoho_plan_code: "preview",
      addon_code: "",
      user_status: 0,
      pointsmodal: false,
      androidbuildspinner: false,
      hideandroidbuildappbutton: false,
      iosbuildspinner: false,
      hideiosbuildappbutton: false,
      rebuildapp: false,
      downloadbuild: false,
      addonrebuildapp: false,
      buildurl: "",
      buildapp: false,
      addoniosbuild: false,
      buildplatform: "",
      downloadiosbuild: false,
      rebuildiosapp: false,
      buildiosurl: "",
      zip_path: "",
      info_plist_path: "",
      iosform: false,
      //Form Data
      error_description: null,
      android_error_description: null,
      loader: "d-none",
      loaderimage: require("../../assets/images/signup/Loader_GIF.gif").default,
      appid: "",
      app_icon_count: 0,
      ios_bundle_id: props.iosbundleid,
      ios_status: props.iosstatus,
      triggerautomatic: true,
      iostriggerautomatic: true,
      //cancel build
      is_android_build_on_hold: "",
      android_bitrise_build_slug: "",
      is_ios_build_on_hold: "",
      ios_bitrise_build_slug: "",
      //version
      android_build_version: null,
      android_latest_version: null,
      //ios
      ios_build_version: null,
      ios_latest_version: null,
      file_check: 0,
      androidstatus: null,
      buildiosstatus: null,
      //is_app_link_to_multisite
      multisite: null,
      builder_status: null,
      allowed_module: "basic",
      splash_count: 0,
      style_count: 0,
      login_register_count: 0,
      appuser_role_id:localStorage.getItem('appuser_role_id'),
    };
  }
  interval;
  UNSAFE_componentWillReceiveProps({ iosbundleid, iosstatus, data }) {
    this.setState({
      ios_bundle_id: iosbundleid,
      ios_status: iosstatus,
    });
  }
  hitheader = () => {
    this.props.hitheader();
  };
  componentDidMount() {
    const buildparams = {
      app_id: localStorage.getItem("Appid"),
    };
    this.setState({
      appid: localStorage.getItem("Appid"),
    });
    DownloadBuildApi(buildparams, this);
    if (this.props.location !== "") 
    {
      
      let parts = this.props.pathname.split("/");
      let answer = parts[parts.length - 1];

      let params = '';
    
      if(answer != 'download-app')
      {
        params = {
          app_id: atob(answer),
        };
        Savesubscriptionapi(this, params, () => {
          GetDownloadappdetailsapi(this, () => {
            this.props.spinnerdown();
          });
  
          this.setState({
            // urlvar:
            //   localStorage.getItem("workspaceId") !== null &&
            //   localStorage.getItem("workspaceId") !== undefined &&
            //   localStorage.getItem("workspaceId") !== ""
            //     ? "/pricing/" + btoa(localStorage.getItem("workspaceId"))
            //     : "/subscription/" + btoa(localStorage.getItem("Appid")),
            urlvar:
              localStorage.getItem("workspaceId") !== null &&
              localStorage.getItem("workspaceId") !== undefined &&
              localStorage.getItem("workspaceId") !== ""
                ?`/workspace/pricing/${btoa(localStorage.getItem("workspaceId"))}`
                : parseInt(localStorage.getItem('website_technology')) === 4 ? "/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid'))
                : parseInt(localStorage.getItem('website_technology')) === 5 ? "/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'))
                : parseInt(localStorage.getItem('website_technology')) === 3 ? "/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'))
                : parseInt(localStorage.getItem('website_technology')) === 2 ? "/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'))
                : "/app/pricing",
            subscription: localStorage.getItem("subscriptionactive"),
          });
        });
      }
      else
      {
        GetDownloadappdetailsapi(this, () => {
          this.props.spinnerdown();
        });
        this.setState({
          // urlvar:
          //   localStorage.getItem("workspaceId") !== null &&
          //   localStorage.getItem("workspaceId") !== undefined &&
          //   localStorage.getItem("workspaceId") !== ""
          //     ? "/pricing/" + btoa(localStorage.getItem("workspaceId"))
          //     : "/subscription/" + btoa(localStorage.getItem("Appid")),
          urlvar : 
          localStorage.getItem("workspaceId") !== null &&
          localStorage.getItem("workspaceId") !== undefined &&
          localStorage.getItem("workspaceId") !== ""
            ?`/workspace/pricing/${btoa(localStorage.getItem("workspaceId"))}`
            : parseInt(localStorage.getItem('website_technology')) === 4 ? "/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid'))
            : parseInt(localStorage.getItem('website_technology')) === 5 ? "/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'))
            : parseInt(localStorage.getItem('website_technology')) === 3 ? "/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'))
            : parseInt(localStorage.getItem('website_technology')) === 2 ? "/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'))
            : "/app/pricing",
          subscription: localStorage.getItem("subscriptionactive"),
        });
      }
      
      
    } else {
      GetDownloadappdetailsapi(this, () => {
        this.props.spinnerdown();
      });
      this.setState({
        // urlvar:
        //   localStorage.getItem("workspaceId") !== null &&
        //   localStorage.getItem("workspaceId") !== undefined &&
        //   localStorage.getItem("workspaceId") !== ""
        //     ? "/pricing/" + btoa(localStorage.getItem("workspaceId"))
        //     : "/subscription/" + btoa(localStorage.getItem("Appid")),
        urlvar : 
        localStorage.getItem("workspaceId") !== null &&
        localStorage.getItem("workspaceId") !== undefined &&
        localStorage.getItem("workspaceId") !== ""
          ? `/workspace/pricing/${btoa(localStorage.getItem("workspaceId"))}`
          : parseInt(localStorage.getItem('website_technology')) === 4 ? "/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid'))
          : parseInt(localStorage.getItem('website_technology')) === 5 ? "/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'))
          : parseInt(localStorage.getItem('website_technology')) === 3 ? "/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'))
          : parseInt(localStorage.getItem('website_technology')) === 2 ? "/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'))
          : "/app/pricing",
        subscription: localStorage.getItem("subscriptionactive"),
      });
    }
  }
  versiontable = () => {
    this.props.analyticsapi();
  };
  waitfunction = () => {
    clearInterval(this.interval);
    const buildparams = {
      app_id: localStorage.getItem("Appid"),
    };

    this.interval = setInterval(() => {
      DownloadBuildApi(buildparams, this);
      this.hitheader();
    }, 40000);
  };
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  //Build create function
  createbuild = (buildplatform) => {
    if (
      buildplatform === "android" &&
      parseInt(this.state.builder_status.status) !== 1 &&
      parseInt(this.state.builder_status.status) !== 3
    ) {
      //technology check
      if (parseInt(localStorage.getItem("website_technology")) === 4 || parseInt(localStorage.getItem("website_technology")) === 5) 
      {
        // if (
        //   this.state.user_status === 1 &&
        //   this.state.app_icon_count === 1 &&
        //   this.state.personalisation === 1 &&
        //   this.state.connectivity === 1 &&
        //   this.state.splash_count === 1 &&
        //   this.state.style_count === 1 &&
        //   this.state.login_register_count === 1
        // ) {
        //   if (this.state.android_build_version === null) {
        //     this.setState({
        //       buildapp: true,
        //       buildplatform: buildplatform,
        //       is_android_build_on_hold: "",
        //     });
        //   } else {
        //     this.props.getthis.props.history.push("/android-build-config");
        //   }
        // } else {
        //   this.setState({
        //     pointsmodal: true,
        //   });
        // }
        if (this.state.user_status === 1) 
        {
          if (this.state.android_build_version === null) {
            this.setState({
              buildapp: true,
              buildplatform: buildplatform,
              is_android_build_on_hold: "",
            });
          } else {
            this.props.getthis.props.history.push("/android-build-config");
          }
        } 
        else
        {
          this.setState({
            pointsmodal: true,
          });
        }
      } //technology check
      else 
      {
        if (
          this.state.user_status === 1 
          // && this.state.app_icon_count === 1 &&
          // this.state.splash_count === 1 &&
          // this.state.style_count === 1
        ) 
        {
          if (this.state.android_build_version === null) {
            this.setState({
              buildapp: true,
              buildplatform: buildplatform,
              is_android_build_on_hold: "",
            });
          } else {
            this.props.getthis.props.history.push("/android-build-config");
          }
        }
         else
          {
          this.setState({
            pointsmodal: true,
          });
        }
      }
    }
    if (
      buildplatform === "ios" &&
      parseInt(this.state.builder_status.status) !== 2 &&
      parseInt(this.state.builder_status.status) !== 3
    ) {
      //technology check
      if (parseInt(localStorage.getItem("website_technology")) === 4 || parseInt(localStorage.getItem("website_technology")) === 5) {
        if (
          this.state.user_status === 1 
          // && this.state.app_icon_count === 1 &&
          // this.state.personalisation === 1 &&
          // this.state.connectivity === 1 &&
          // this.state.splash_count === 1 &&
          // this.state.style_count === 1 &&
          // this.state.login_register_count === 1
        ) {
          if (this.state.android_build_version === null) {
            this.setState({
              buildapp: true,
              buildplatform: buildplatform,
              is_android_build_on_hold: "",
            });
          } else {
            this.props.getthis.props.history.push("/ios-build-config");
          }
        } else {
          this.setState({
            pointsmodal: true,
          });
        }
      } //technology check
      else {
        if (
          this.state.user_status === 1 
          // && this.state.app_icon_count === 1 &&
          // this.state.splash_count === 1 &&
          // this.state.style_count === 1
        ) {
          if (this.state.android_build_version === null) {
            this.setState({
              buildapp: true,
              buildplatform: buildplatform,
              is_android_build_on_hold: "",
            });
          } else {
            this.props.getthis.props.history.push("/ios-build-config");
          }
        } else {
          this.setState({
            pointsmodal: true,
          });
        }
      }
    }
  };

  togglemodal = (e) => {
    e.preventDefault();
    this.setState({
      modal: !this.state.modal,
    });
  };

  iosdataform = (e) => {
    e.preventDefault();
    this.props.getthis.props.history.push("/ios-build-config");
  };

  handleandroidpopup = (e) => {
    e.preventDefault();
    this.props.getthis.props.history.push("/android-build-config");
  };

  togglebuildapp = (e) => {
    e.preventDefault();
    this.setState({
      buildapp: !this.state.buildapp,
    });
  };

  pointsmodalclick = (e) => {
    e.preventDefault();
    this.setState({
      pointsmodal: !this.state.pointsmodal,
    });
  };

  handlecancelbuild = (platform) => {
    const cancelbuildparam = {
      app_id: localStorage.getItem("Appid"),
      platform: platform,
    };
    cancelBuild(cancelbuildparam, this);
  };

  render() {
    const versioniosCompare = (v1, v2, options) => {
      var lexicographical = options && options.lexicographical,
        zeroExtend = options && options.zeroExtend,
        v1parts = v1.split("."),
        v2parts = v2.split(".");

      function isValidPart(x) {
        return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
      }

      if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
        return NaN;
      }

      if (zeroExtend) {
        while (v1parts.length < v2parts.length) v1parts.push("0");
        while (v2parts.length < v1parts.length) v2parts.push("0");
      }

      if (!lexicographical) {
        v1parts = v1parts.map(Number);
        v2parts = v2parts.map(Number);
      }

      for (var i = 0; i < v1parts.length; ++i) {
        if (v2parts.length === i) {
          return 1;
        }

        if (v1parts[i] === v2parts[i]) {
          continue;
        } else if (v1parts[i] > v2parts[i]) {
          return (
            <>
              <div
                className="updateversion m-0 p-0 version-spacing"
                style={{ background: "none" }}
              >
                <p
                  className="p-0 m-0 d-md-block"
                  style={{ color: "#1B2952", fontSize: "14px" }}
                >
                  An update is now available. To upgrade to code version{" "}
                  {this.state.ios_latest_version}
                  <span
                    onClick={(e) => this.createbuild("ios")}
                    className="ml-1"
                  >
                    click here
                  </span>
                </p>
              </div>
            </>
          );
        } else {
          return (
            <>
              <p
                className="p-0 m-0 d-none d-md-block"
                style={{ color: "#7782A1", fontSize: "14px" }}
              >
                Your last build was created using the latest AppMySite Code
                Version.
              </p>
            </>
          );
        }
      }

      if (v1parts.length !== v2parts.length) {
        return <></>;
      }

      return (
        <>
          <p
            className="p-0 m-0 d-none d-md-block"
            style={{ color: "#7782A1", fontSize: "14px" }}
          >
            Your last build was created using the latest AppMySite Code Version.
          </p>
        </>
      );
    };
    const versionCompare = (v1, v2, options) => {
      var lexicographical = options && options.lexicographical,
        zeroExtend = options && options.zeroExtend,
        v1parts = v1.split("."),
        v2parts = v2.split(".");

      function isValidPart(x) {
        return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
      }

      if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
        return NaN;
      }

      if (zeroExtend) {
        while (v1parts.length < v2parts.length) v1parts.push("0");
        while (v2parts.length < v1parts.length) v2parts.push("0");
      }

      if (!lexicographical) {
        v1parts = v1parts.map(Number);
        v2parts = v2parts.map(Number);
      }

      for (var i = 0; i < v1parts.length; ++i) {
        if (v2parts.length === i) {
          return 1;
        }

        if (v1parts[i] === v2parts[i]) {
          continue;
        } else if (v1parts[i] > v2parts[i]) {
          return (
            <>
              <div
                className="updateversion m-0 p-0 version-spacing"
                style={{ background: "none" }}
              >
                <p>
                  An update is now available. To upgrade to code version{" "}
                  {this.state.android_latest_version}
                  <span
                    onClick={(e) => this.createbuild("android")}
                    className="ml-1"
                  >
                    click here
                  </span>
                </p>
              </div>
            </>
          );
        } else {
          return (
            <>
              <p
                className="p-0 m-0 d-none d-md-block"
                style={{ color: "#7782A1", fontSize: "14px" }}
              >
                Your last build was created using the latest AppMySite Code
                Version.
              </p>
            </>
          );
        }
      }

      if (v1parts.length !== v2parts.length) {
        return <></>;
      }

      return (
        <>
          <p
            className="p-0 m-0 d-none d-md-block"
            style={{ color: "#7782A1", fontSize: "14px" }}
          >
            Your last build was created using the latest AppMySite Code Version.
          </p>
        </>
      );
    };
    //Show build status
    const Checkandroidstatus = (androidstatus) => {
      if (androidstatus === null) {
        return (
          <>
            <p
              className="build-text-spacing"
              style={{ color: "#C8CEDB", fontSize: "14px" }}
            >
              Draft
            </p>
          </>
        );
      }
      if (androidstatus.app_in_process === 1) {
        return (
          <>
            <p
              className="build-text-spacing"
              style={{ color: "#3064F9", fontSize: "14px" }}
            >
              Build Processing
            </p>
          </>
        );
      }
      if (androidstatus.app_in_process === 0) {
        if (androidstatus.build_status === "0") {
          //Draft case after payment
          return (
            <>
              <p
                className="build-text-spacing"
                style={{ color: "#C8CEDB", fontSize: "14px" }}
              >
                Draft
              </p>
            </>
          );
        }
        if (androidstatus.build_status === "3") {
          //Draft case after payment
          return (
            <>
              <p
                className="build-text-spacing"
                style={{ color: "#C8CEDB", fontSize: "14px" }}
              >
                Draft
              </p>
            </>
          );
        }
        if (androidstatus.build_status === "1") {
          //When App is available to Download
          return (
            <>
              <p
                className="build-text-spacing"
                style={{ color: "#47CE70", fontSize: "14px" }}
              >
                Build Completed
              </p>
              <p
                className="build-text-spacing"
                style={{ color: "#7782A1", fontSize: "14px" }}
              >
                Your last build was created successfully
              </p>
            </>
          );
        }
        if (androidstatus.build_status === "4") {
          //When App is available to Download
          return (
            <>
              <p
                className="build-text-spacing"
                style={{ color: "#47CE70", fontSize: "14px" }}
              >
                Build Completed
              </p>
              <p
                className="build-text-spacing"
                style={{ color: "#7782A1", fontSize: "14px" }}
              >
                Your last build was created successfully
              </p>
            </>
          );
        }
        if (androidstatus.build_status === "2") {
          //Rebuild
          return (
            <>
              <p
                className="build-text-spacing"
                style={{ color: "#FF4848", fontSize: "14px" }}
              >
                Build Failed
              </p>
              <p
                style={{ color: "#7782A1", fontSize: "14px" }}
                className="d-none d-md-block pr-2"
              >
                {this.state.android_error_description}
              </p>
            </>
          );
        }
      }
    };
    const Checkiosstatus = (iosstatus) => {
      if (iosstatus === null) {
        return (
          <>
            <p
              className="build-text-spacing"
              style={{ color: "#C8CEDB", fontSize: "14px" }}
            >
              Draft
            </p>
          </>
        );
      }
      if (iosstatus.app_in_process === 1) {
        return (
          <>
            <p
              className="build-text-spacing"
              style={{ color: "#3064F9", fontSize: "14px" }}
            >
              Build Processing
            </p>
          </>
        );
      }
      if (iosstatus.app_in_process === 0) {
        if (iosstatus.build_status === "0") {
          //Draft condition after payment
          return (
            <>
              <p
                className="build-text-spacing"
                style={{ color: "#C8CEDB", fontSize: "14px" }}
              >
                Draft
              </p>
            </>
          );
        }
        if (iosstatus.build_status === "3") {
          //Draft condition after payment
          return (
            <>
              <p
                className="build-text-spacing"
                style={{ color: "#C8CEDB", fontSize: "14px" }}
              >
                Draft
              </p>
            </>
          );
        }
        if (iosstatus.build_status === "1") {
          //When App is available to Download
          return (
            <>
              <p
                className="build-text-spacing"
                style={{ color: "#47CE70", fontSize: "14px" }}
              >
                Build Completed
              </p>
              <p
                className="build-text-spacing"
                style={{ color: "#7782A1", fontSize: "14px" }}
              >
                Your last build was created successfully
              </p>
            </>
          );
        }
        if (iosstatus.build_status === "4") {
          //When App is available to Download
          return (
            <>
              <p
                className="build-text-spacing"
                style={{ color: "#47CE70", fontSize: "14px" }}
              >
                Build Completed
              </p>
              <p
                className="build-text-spacing"
                style={{ color: "#7782A1", fontSize: "14px" }}
              >
                Your last build was created successfully
              </p>
            </>
          );
        }
        if (iosstatus.build_status === "2") {
          //Rebuild
          return (
            <>
              <p
                className="build-text-spacing"
                style={{ color: "#FF4848", fontSize: "14px" }}
              >
                Build Failed
              </p>
              <p
                style={{ color: "#7782A1", fontSize: "14px" }}
                className="d-none d-md-block pr-2"
              >
                {this.state.error_description}
              </p>
            </>
          );
        }
      }
    };
    return (
      <>
        {this.state.multisite !== null && (
          <div className="api-details api-details-responsive">
            {this.state.multisite.is_app_link_to_multisite > 0 ? (
              <Suspense fallback={""}>
                <Multisitealert value={this} />
              </Suspense>
            ) : (
              <div
                style={{
                  display: this.state.spinner === true ? "none" : "block",
                  minHeight: "300px",
                }}
              >
                <div className="d-md-flex align-items-stretch justify-content-between new_two_div">
                  <div className="mr-md-3 two-apps new-buid-grey new-buil_app download-tab-spacing w-100 w-md-50">
                    <div
                      className="d-flex align-items-start justify-content-between grey-download-section"
                      style={{ minHeight: "370px" }}
                    >
                      <div className="androidpublishright">
                        <h4 className="mb-0">Android</h4>
                        {this.state.allowed_module === "all" ||
                        this.state.allowed_module === "android" ? (
                          <p style={{ color: "#7782A1" }}>
                            Download Android app to launch it on the Play Store.
                          </p>
                        ) : (
                          <p>
                            Upgrade subscription to download your Android app.
                          </p>
                        )}

                        {/* Check Steps */}
                        <>
                          <p className="d-flex align-items-center mt-md-4 verify_steps">
                            <>
                              <img
                                src={
                                  this.state.user_status === 1
                                    ? require("../../assets/images/dashboard/download/verified.png")
                                        .default
                                    : require("../../assets/images/dashboard/download/verified-grey.png")
                                        .default
                                }
                                alt="verified"
                                className="img-fluid mr-2"
                              />
                              Email Verified
                            </>
                          </p>
                          {/* <p className="d-flex align-items-center  verify_steps">
                            {(parseInt(
                              localStorage.getItem("website_technology")
                            ) === 4 || parseInt(
                              localStorage.getItem("website_technology")
                            ) === 5)  ? (
                              this.state.app_icon_count === 1 &&
                              this.state.personalisation === 1 ? (
                                <>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/download/verified.png")
                                        .default
                                    }
                                    alt="verified"
                                    className="img-fluid mr-2"
                                  />
                                  Appearance
                                </>
                              ) : (
                                <>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/download/verified-grey.png")
                                        .default
                                    }
                                    alt="verified"
                                    className="img-fluid mr-2"
                                  />
                                  Appearance
                                </>
                              )
                            ) : this.state.app_icon_count === 1 &&
                              this.state.style_count === 1 &&
                              this.state.splash_count === 1 ? (
                              <>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/download/verified.png")
                                      .default
                                  }
                                  alt="verified"
                                  className="img-fluid mr-2"
                                />
                                Appearance
                              </>
                            ) : (
                              <>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/download/verified-grey.png")
                                      .default
                                  }
                                  alt="verified"
                                  className="img-fluid mr-2"
                                />
                                Appearance
                              </>
                            )}
                          </p> */}
                          {/* {parseInt(
                            localStorage.getItem("website_technology")
                          ) !== 2 &&
                          parseInt(
                            localStorage.getItem("website_technology")
                          ) !== 3 ? (
                            <p className="d-flex align-items-center verify_steps">
                              {this.state.connectivity === 1 ? (
                                <>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/download/verified.png")
                                        .default
                                    }
                                    alt="verified"
                                    className="img-fluid mr-2"
                                  />
                                  Connectivity
                                </>
                              ) : (
                                <>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/download/verified-grey.png")
                                        .default
                                    }
                                    alt="verified"
                                    className="img-fluid mr-2"
                                  />
                                  Connectivity
                                </>
                              )}
                            </p>
                          ) : (
                            <></>
                          )} */}
                          <p
                            className="d-flex align-items-center "
                            style={{ height: "45px" }}
                          ></p>
                        </>
                        {/* Check Steps End*/}
                        {/* Change build status */}
                        {this.state.androidbuildspinner && (
                          <div className=" build_started creating-build justify-content-start align-items-center d-flex mt-0">
                            <img
                              src={
                                require("../../assets/images/dashboard/ezgif.gif")
                                  .default
                              }
                              alt="App Build"
                              className="img-fluid"
                            />
                            {this.state.triggerautomatic === true ? (
                              <div>
                                <h4>Initialising</h4>
                                <p>Your build request is now initialising</p>
                              </div>
                            ) : (
                              <>
                                {this.state.is_android_build_on_hold === 0 &&
                                  this.state.android_bitrise_build_slug !==
                                    "" && (
                                    <div>
                                      <h4>Processing</h4>
                                      <p>
                                        Your build is now in process. ETA 15
                                        minutes.
                                      </p>
                                    </div>
                                  )}

                                {this.state.is_android_build_on_hold === 1 &&
                                  this.state.android_bitrise_build_slug !==
                                    "" && (
                                    <div>
                                      <h4>Queued</h4>
                                      <p>Your build has entered the queue</p>
                                    </div>
                                  )}
                                {this.state.is_android_build_on_hold === 1 &&
                                  this.state.android_bitrise_build_slug ===
                                    "" && (
                                    <div>
                                      <h4>Initialising</h4>
                                      <p>
                                        Your build request is now initialising
                                      </p>
                                    </div>
                                  )}
                              </>
                            )}

                            <div
                              className="cancel_build"
                              onClick={(e) => this.handlecancelbuild("android")}
                            >
                              <img
                                src={
                                  require("../../assets/images/dashboard/cancel-build.png")
                                    .default
                                }
                                alt="Cancel Build"
                                className="img-fluid m-0"
                              />
                            </div>
                          </div>
                        )}

                        {/* Change build status end*/}
                        <div className="d-flex align-items-center">
                        {this.state.hideandroidbuildappbutton === false &&
                          (this.state.allowed_module === "all" || this.state.allowed_module === "android" ? 
                            (
                              <>
                            {this.state.builder_status.status === 1 || this.state.builder_status.status === 3 ?
                              <Link
                                to="#"
                                className={"rebuild_button_disable"}
                                onClick={(e) => this.createbuild("android")}
                              >
                                {this.state.builder_status.status === 1 ||
                                this.state.builder_status.status === 3 ? (
                                  <div className="mb-1 new-hover-element">
                                    Build Android App
                                    <span
                                      className={
                                        "new-hover-element-text custom-hover-btn"
                                      }
                                    >
                                      {this.state.builder_status.title}
                                      <p>
                                        {this.state.builder_status.description}
                                      </p>
                                    </span>
                                  </div>
                                ) : (
                                  <>Build Android App</>
                                )}
                              </Link>
                            :
                                <Link
                                to="#"
                                className={"button_blue d-md-block"}
                                style={{backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                                onClick={(e) => this.createbuild("android")}
                              >
                                {this.state.builder_status.status === 1 ||
                                this.state.builder_status.status === 3 ? (
                                  <div className="mb-1 new-hover-element">
                                    Build Android App
                                    <span
                                      className={
                                        "new-hover-element-text custom-hover-btn"
                                      }
                                    >
                                      {this.state.builder_status.title}
                                      <p>
                                        {this.state.builder_status.description}
                                      </p>
                                    </span>
                                  </div>
                                ) : (
                                  <>Build Android App</>
                                )}
                              </Link>
                              }
                              </>
                            )
                            : (localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || 
                            localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || 
                            localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1) ? 
                            (
                              <Link
                                to={
                                  this.state.zoho_plan_code.toString() ===
                                  process.env.REACT_APP_Lifetime_preview
                                    ? "/deals"
                                    : this.state.urlvar
                                }
                                className="white_btn d-md-block"
                              >
                                Proceed
                              </Link>
                            )
                            : null
                          )
                        }
                          {/* Download apk*/}
                          {this.state.downloadbuild === true && (
                            <a
                              href={
                                process.env.REACT_APP_Image_Path +
                                this.state.buildurl
                              }
                              style={{backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                              className={
                                styles.resizebutton +
                                " button_blue d-md-block rebuildbutton"
                              }
                              download
                            >
                              Download APK
                            </a>
                          )}
                          {/* Android App bundle */}
                          {this.state.downloadbuild === true && (
                            <div
                              className={styles.resizeappbundle + " d-md-block"}
                            >
                              {this.state.aabfile !== null && (
                                <>
                                  <div className="d-flex align-items-center mt-0  ml-1">
                                    <a
                                      href={
                                        process.env.REACT_APP_Image_Path +
                                        this.state.aabfile
                                      }
                                      className="white_btn "
                                      download
                                      style={{ width: "168px" }}
                                    >
                                      Download AAB
                                    </a>
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="androidpublish text-right">
                        <img
                          src={
                            require("../../assets/images/dashboard/download/android.png")
                              .default
                          }
                          alt="android"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    {this.state.allowed_module !== "basic" && (
                      <>
                        {/* Version Start */}
                        <div className="version_new_section d-flex align-items-start justify-content-between">
                          <div className="d-flex align-items-baseline">
                            <h4 className="mt-0">Version: </h4>
                            <div className="build_status_new build-subtext-spacing">
                              <div className="build_last_new_div d-flex align-items-center">
                                {this.state.android_build_version !== null ? (
                                  <span className="texthighlite">
                                    {this.state.android_build_version}
                                  </span>
                                ) : (
                                  <span style={{ fontSize: "12px" }}>-</span>
                                )}
                              </div>
                              {this.state.allowed_module === "all" ||
                              this.state.allowed_module === "android" ? (
                                this.state.android_latest_version !== null &&
                                this.state.android_build_version !== null ? (
                                  versionCompare(
                                    this.state.android_latest_version,
                                    this.state.android_build_version
                                  )
                                ) : this.state.android_build_version !==
                                  null ? (
                                  <p
                                    className="p-0 m-0 d-none d-md-block"
                                    style={{
                                      color: "#7782A1",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Your last build was created using the latest
                                    AppMySite Code Version.
                                  </p>
                                ) : (
                                  <p
                                    className="p-0 m-0"
                                    style={{ fontSize: "12px" }}
                                  ></p>
                                )
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* Version end */}
                        {/* Build Status */}
                        <div className="build_new_section d-flex align-items-baseline justify-content-between new-section-mobile">
                          <div className="d-flex align-items-baseline">
                            <h4 className="mt-0">Build:</h4>
                            <div className="build_status_new build-subtext-spacing">
                              {Checkandroidstatus(this.state.androidstatus)}
                            </div>
                          </div>
                          <div className="build_status_new d-flex justify-content-end align-items-center">
                            {this.state.rebuildapp === true && (
                              <Link
                                // title={
                                //   this.state.builder_status.status === 1 ||
                                //   this.state.builder_status.status === 3
                                //     ? <div className="mb-1 new-hover-element">
                                //     <span className={"new-hover-element-text"}>{this.state.builder_status.description}</span>
                                //                 </div>

                                //     : ""
                                // }
                                // to="#"
                                className={
                                  this.state.builder_status.status === 1 ||
                                  this.state.builder_status.status === 3
                                    ? "rebuild_new_disable"
                                    : "d-md-block rebuild_new"
                                }
                                onClick={(e) => this.createbuild("android")}
                              >
                                <img
                                  src={
                                    this.state.builder_status.status === 1 ||
                                    this.state.builder_status.status === 3
                                      ? require("../../assets/images/dashboard/download/gray-rebuild.png")
                                          .default
                                      : require("../../assets/images/dashboard/download/rebuild.png")
                                          .default
                                  }
                                  alt="Connecting"
                                  className="img-fluid mr-2"
                                />
                                {this.state.builder_status.status === 1 ||
                                this.state.builder_status.status === 3 ? (
                                  <div className="mb-1 new-hover-element">
                                    Rebuild App
                                    <span className={"new-hover-element-text"}>
                                      {this.state.builder_status.title}
                                      <p>
                                        {this.state.builder_status.description}
                                      </p>
                                    </span>
                                  </div>
                                ) : (
                                  <>Rebuild App</>
                                )}
                              </Link>
                            )}
                            {this.state.addonrebuildapp === true && (
                              <Link
                                // title={
                                //   this.state.builder_status.status === 1 ||
                                //   this.state.builder_status.status === 3
                                //     ? <div className="mb-1 new-hover-element">
                                //     <span className={""}>{this.state.builder_status.description}</span>
                                //                 </div>
                                //     : ""
                                // }
                                // to="#"
                                className={
                                  this.state.builder_status.status === 1 ||
                                  this.state.builder_status.status === 3
                                    ? "rebuild_new_disable"
                                    : "d-md-block rebuild_new"
                                }
                                onClick={(e) => this.createbuild("android")}
                              >
                                <img
                                  src={
                                    this.state.builder_status.status === 1 ||
                                    this.state.builder_status.status === 3
                                      ? require("../../assets/images/dashboard/download/gray-rebuild.png")
                                          .default
                                      : require("../../assets/images/dashboard/download/rebuild.png")
                                          .default
                                  }
                                  alt="Connecting"
                                  className="img-fluid mr-2"
                                />
                                {this.state.builder_status.status === 1 ||
                                this.state.builder_status.status === 3 ? (
                                  <div className="mb-1 new-hover-element">
                                    Rebuild App
                                    <span className={"new-hover-element-text"}>
                                      {this.state.builder_status.title}
                                      <p>
                                        {this.state.builder_status.description}
                                      </p>
                                    </span>
                                  </div>
                                ) : (
                                  <>Rebuild App</>
                                )}
                              </Link>
                            )}
                            {/* Build again temporary */}
                            {this.state.allowed_module === "all" ||
                            this.state.allowed_module === "android" ? (
                              this.state.downloadbuild === true && (
                                <Link
                                  // title={
                                  //   this.state.builder_status.status === 1 ||
                                  //   this.state.builder_status.status === 3
                                  //     ? <div className="mb-1 new-hover-element">
                                  //     <span className={""}>{this.state.builder_status.description}</span>
                                  //                 </div>
                                  //     : ""
                                  // }
                                  to={"#"}
                                  className={
                                    this.state.builder_status.status === 1 ||
                                    this.state.builder_status.status === 3
                                      ? "rebuild_new_disable"
                                      : "d-md-block rebuild_new"
                                  }
                                  onClick={(e) => this.createbuild("android")}
                                >
                                  <img
                                    src={
                                      this.state.builder_status.status === 1 ||
                                      this.state.builder_status.status === 3
                                        ? require("../../assets/images/dashboard/download/gray-rebuild.png")
                                            .default
                                        : require("../../assets/images/dashboard/download/rebuild.png")
                                            .default
                                    }
                                    alt="Connecting"
                                    className="img-fluid mr-2"
                                  />
                                  {this.state.builder_status.status === 1 ||
                                  this.state.builder_status.status === 3 ? (
                                    <div className="mb-1 new-hover-element">
                                      Rebuild App
                                      <span
                                        className={"new-hover-element-text"}
                                      >
                                        {this.state.builder_status.title}
                                        <p>
                                          {
                                            this.state.builder_status
                                              .description
                                          }
                                        </p>
                                      </span>
                                    </div>
                                  ) : (
                                    <>Rebuild App</>
                                  )}
                                </Link>
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        {/* Build Status End*/}
                      </>
                    )}
                  </div>

                  {/* iOS Module */}
                  <div className="ml-md-3 two-apps new-buid-grey new-buil_app w-100 w-md-50">
                    <div
                      className="d-flex align-items-start justify-content-between grey-download-section"
                      style={{ minHeight: "370px" }}
                    >
                      <div className="androidpublishright">
                        <h4 className="mb-0">
                          iOS
                          {/* {this.state.ios_build_version !== null && (
                            <span>
                              ( Current Version {this.state.ios_build_version})
                            </span>
                          )} */}
                        </h4>
                        {this.state.allowed_module === "all" ? (
                          <p style={{ color: "#7782A1" }}>
                            Download your iOS app to launch it on the App Store.
                          </p>
                        ) : (
                          <p>
                            Upgrade your subscription to download your iOS app.
                          </p>
                        )}
                        {/* Steps */}
                        <>
                          <p className="d-flex align-items-center mt-md-4 verify_steps">
                            {this.state.user_status === 1 ? (
                              <>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/download/verified.png")
                                      .default
                                  }
                                  alt="verified"
                                  className="img-fluid mr-2"
                                />
                                Email Verified
                              </>
                            ) : (
                              <>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/download/verified-grey.png")
                                      .default
                                  }
                                  alt="verified"
                                  className="img-fluid mr-2"
                                />
                                Email Verified
                              </>
                            )}
                          </p>
                          {/* <p className="d-flex align-items-center verify_steps">
                            {(parseInt(
                              localStorage.getItem("website_technology")
                            ) === 4 || parseInt(
                              localStorage.getItem("website_technology")
                            ) === 5) ? (
                              this.state.app_icon_count === 1 &&
                              this.state.personalisation === 1 &&
                              this.state.splash_count === 1 &&
                              this.state.style_count === 1 &&
                              this.state.login_register_count === 1 ? (
                                <>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/download/verified.png")
                                        .default
                                    }
                                    alt="verified"
                                    className="img-fluid mr-2"
                                  />
                                  Appearance
                                </>
                              ) : (
                                <>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/download/verified-grey.png")
                                        .default
                                    }
                                    alt="verified"
                                    className="img-fluid mr-2"
                                  />
                                  Appearance
                                </>
                              )
                            ) : this.state.app_icon_count === 1 &&
                              this.state.style_count === 1 &&
                              this.state.splash_count === 1 ? (
                              <>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/download/verified.png")
                                      .default
                                  }
                                  alt="verified"
                                  className="img-fluid mr-2"
                                />
                                Appearance
                              </>
                            ) : (
                              <>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/download/verified-grey.png")
                                      .default
                                  }
                                  alt="verified"
                                  className="img-fluid mr-2"
                                />
                                Appearance
                              </>
                            )}
                          </p> */}
                          {/* {parseInt(
                            localStorage.getItem("website_technology")
                          ) !== 2 &&
                          parseInt(
                            localStorage.getItem("website_technology")
                          ) !== 3 ? (
                            <p className="d-flex align-items-center verify_steps">
                              {this.state.connectivity === 1 ? (
                                <>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/download/verified.png")
                                        .default
                                    }
                                    alt="verified"
                                    className="img-fluid mr-2"
                                  />
                                  Connectivity
                                </>
                              ) : (
                                <>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/download/verified-grey.png")
                                        .default
                                    }
                                    alt="verified"
                                    className="img-fluid mr-2"
                                  />
                                  Connectivity
                                </>
                              )}
                            </p>
                          ) : (
                            <></>
                          )} */}
                          <p className="d-flex align-items-center verify_steps">
                            {this.state.file_check > 0 ? (
                              <>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/download/verified.png")
                                      .default
                                  }
                                  alt="verified"
                                  className="img-fluid mr-2"
                                />
                                Certificates
                              </>
                            ) : (
                              <>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/download/verified-grey.png")
                                      .default
                                  }
                                  alt="verified"
                                  className="img-fluid mr-2"
                                />
                                Certificates
                              </>
                            )}
                          </p>
                        </>
                        {/* Steps End */}
                        {this.state.iosbuildspinner && (
                          <div className="build_started creating-build justify-content-start align-items-center d-flex">
                            <img
                              src={
                                require("../../assets/images/dashboard/ezgif.gif")
                                  .default
                              }
                              alt="App Build"
                              className="img-fluid"
                            />
                            {this.state.iostriggerautomatic === true ? (
                              <div>
                                <h4>Validating</h4>
                                <p>
                                  The information you provided is now being
                                  validated.
                                </p>
                              </div>
                            ) : (
                              <>
                                {this.state.is_ios_build_on_hold === 0 &&
                                  this.state.ios_bitrise_build_slug !== "" && (
                                    <div>
                                      <h4>Processing</h4>
                                      <p>
                                        Your build is now in process. ETA 20
                                        minutes.
                                      </p>
                                    </div>
                                  )}

                                {this.state.is_ios_build_on_hold === 1 &&
                                  this.state.ios_bitrise_build_slug !== "" && (
                                    <div>
                                      <h4>Queued</h4>
                                      <p>Your build has entered the queue</p>
                                    </div>
                                  )}
                                {this.state.is_ios_build_on_hold === 1 &&
                                  this.state.ios_bitrise_build_slug === "" && (
                                    <div>
                                      <h4>Validating</h4>
                                      <p>
                                        The information you provided is now
                                        being validated.
                                      </p>
                                    </div>
                                  )}
                              </>
                            )}
                            <div
                              className="cancel_build"
                              onClick={(e) => this.handlecancelbuild("ios")}
                            >
                              <img
                                src={
                                  require("../../assets/images/dashboard/cancel-build.png")
                                    .default
                                }
                                alt="Cancel Build"
                                className="img-fluid m-0"
                              />
                            </div>
                          </div>
                        )}

                        <div
                          className={
                            this.state.iosbuildspinner === false
                              ? "d-flex align-items-center mt-4 pt-1"
                              : "d-flex align-items-center "
                          }
                        >
                          {this.state.hideiosbuildappbutton === false &&
                            (this.state.allowed_module === "all" ? (
                            <>
                            {this.state.builder_status.status === 2 || this.state.builder_status.status === 3 ?
                              <Link
                                to="#"
                                className={"rebuild_button_disable"}
                                style={{backgroundColor:localStorage.getItem('button_background_color') && localStorage.getItem('button_background_color') != 'undefined' && localStorage.getItem('button_background_color') != 'null' ? localStorage.getItem('button_background_color') : '#3064F9',color:localStorage.getItem('button_text_color'),"--custom_color1": localStorage.getItem('agencyid') == 1 ? "#3064F9" : localStorage.getItem('button_background_color'),border:"1px solid "+localStorage.getItem('button_background_color')}}
                                onClick={(e) => this.createbuild("ios")}
                              >
                                {this.state.builder_status.status === 2 ||
                                this.state.builder_status.status === 3 ? (
                                  <div className="mb-1 new-hover-element">
                                    Build iOS App
                                    <span
                                      className={
                                        "new-hover-element-text custom-hover-btn"
                                      }
                                    >
                                      {this.state.builder_status.title}
                                      <p>
                                        {this.state.builder_status.description}
                                      </p>
                                    </span>
                                  </div>
                                ) : (
                                  <>Build iOS App</>
                                )}
                              </Link>
                            :
                            <Link
                                to="#"
                                className="button_blue d-md-block"
                                style={{backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                                onClick={(e) => this.createbuild("ios")}
                              >
                                {this.state.builder_status.status === 2 ||
                                this.state.builder_status.status === 3 ? (
                                  <div className="mb-1 new-hover-element">
                                    Build iOS App
                                    <span
                                      className={
                                        "new-hover-element-text custom-hover-btn"
                                      }
                                    >
                                      {this.state.builder_status.title}
                                      <p>
                                        {this.state.builder_status.description}
                                      </p>
                                    </span>
                                  </div>
                                ) : (
                                  <>Build iOS App</>
                                )}
                              </Link>
                            }
                            </>
                            ) 
                            : (localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || 
                            localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || 
                            localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1) ? 
                            (
                              <Link
                                to={
                                  this.state.zoho_plan_code.toString() ===
                                  process.env.REACT_APP_Lifetime_preview
                                    ? "/deals"
                                    : this.state.urlvar
                                }
                                className="white_btn d-md-block"
                              >
                                Proceed
                              </Link>
                            )
                            : null
                            )}
                          {this.state.downloadiosbuild === true && (
                            <>
                              <a
                                href={
                                  process.env.REACT_APP_Image_Path +
                                  this.state.buildiosurl
                                }
                                style={{backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                                className="button_blue d-md-block rebuildbutton"
                                download
                              >
                                Download IPA
                              </a>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="androidpublish text-right">
                        <img
                          src={
                            require("../../assets/images/dashboard/download/ios.png")
                              .default
                          }
                          alt="android"
                          className="img-fluid"
                        />
                      </div>
                    </div>

                    {this.state.allowed_module !== "basic" && (
                      <>
                        <div className="version_new_section d-flex align-items-start justify-content-between">
                          <div className="d-flex align-items-baseline">
                            <h4 className="mt-0">Version: </h4>

                            <div className="build_status_new build-subtext-spacing">
                              <div className="build_last_new_div d-flex align-items-center">
                                {this.state.ios_build_version !== null ? (
                                  <span className="texthighlite">
                                    {this.state.ios_build_version}
                                  </span>
                                ) : (
                                  <span style={{ fontSize: "12px" }}>-</span>
                                )}
                              </div>
                              {this.state.allowed_module === "all" ? (
                                <>
                                  {this.state.ios_latest_version !== null &&
                                  this.state.ios_build_version !== null ? (
                                    versioniosCompare(
                                      this.state.ios_latest_version,
                                      this.state.ios_build_version
                                    )
                                  ) : (
                                    <>
                                      {this.state.ios_build_version !== null ? (
                                        <p
                                          className="p-0 m-0 d-none d-md-block"
                                          style={{
                                            color: "#7782A1",
                                            fontSize: "14px",
                                          }}
                                        >
                                          Your last build was created using the
                                          latest AppMySite Code Version.
                                        </p>
                                      ) : (
                                        <p
                                          className="p-0 m-0"
                                          style={{ fontSize: "12px" }}
                                        ></p>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="build_new_section d-flex align-items-baseline justify-content-between new-section-mobile">
                          <div className="d-flex align-items-baseline">
                            <h4 className="mt-0">Build:</h4>
                            <div className="build_status_new build-subtext-spacing d-flex flex-column flex-wrap">
                              {Checkiosstatus(this.state.buildiosstatus)}
                            </div>
                          </div>
                          <div>
                            {this.state.rebuildiosapp === true && (
                              <Link
                                // title={
                                //   this.state.builder_status.status === 2 ||
                                //   this.state.builder_status.status === 3
                                //     ? <div className="mb-1 new-hover-element">
                                //     <span className={""}>{this.state.builder_status.description}</span>
                                //                 </div>
                                //     : ""
                                // }
                                to="#"
                                className={
                                  this.state.builder_status.status === 2 ||
                                  this.state.builder_status.status === 3
                                    ? "rebuild_new_disable"
                                    : "d-md-block rebuild_new"
                                }
                                onClick={(e) => this.createbuild("ios")}
                              >
                                <img
                                  src={
                                    this.state.builder_status.status === 2 ||
                                    this.state.builder_status.status === 3
                                      ? require("../../assets/images/dashboard/download/gray-rebuild.png")
                                          .default
                                      : require("../../assets/images/dashboard/download/rebuild.png")
                                          .default
                                  }
                                  alt="Connecting"
                                  className="img-fluid mr-2"
                                />
                                {this.state.builder_status.status === 2 ||
                                this.state.builder_status.status === 3 ? (
                                  <div className="mb-1 new-hover-element">
                                    Rebuild App
                                    <span className={"new-hover-element-text"}>
                                      {this.state.builder_status.title}
                                      <p>
                                        {this.state.builder_status.description}
                                      </p>
                                    </span>
                                  </div>
                                ) : (
                                  <>Rebuild App</>
                                )}
                              </Link>
                            )}
                            {/* Build after after addon */}

                            {this.state.addoniosbuild === true && (
                              <Link
                                // title={
                                //   this.state.builder_status.status === 2 ||
                                //   this.state.builder_status.status === 3
                                //     ? <div className="mb-1 new-hover-element">
                                //     <span className={""}>{this.state.builder_status.description}</span>
                                //                 </div>
                                //     : ""
                                // }
                                to="#"
                                className={
                                  this.state.builder_status.status === 2 ||
                                  this.state.builder_status.status === 3
                                    ? "rebuild_new_disable"
                                    : "d-md-block rebuild_new"
                                }
                                onClick={(e) => this.createbuild("ios")}
                              >
                                <img
                                  src={
                                    this.state.builder_status.status === 2 ||
                                    this.state.builder_status.status === 3
                                      ? require("../../assets/images/dashboard/download/gray-rebuild.png")
                                          .default
                                      : require("../../assets/images/dashboard/download/rebuild.png")
                                          .default
                                  }
                                  alt="Connecting"
                                  className="img-fluid mr-2"
                                />
                                {this.state.builder_status.status === 2 ||
                                this.state.builder_status.status === 3 ? (
                                  <div className="mb-1 new-hover-element">
                                    Rebuild App
                                    <span className={"new-hover-element-text"}>
                                      {this.state.builder_status.title}
                                      <p>
                                        {this.state.builder_status.description}
                                      </p>
                                    </span>
                                  </div>
                                ) : (
                                  <>Rebuild App</>
                                )}
                              </Link>
                            )}
                            {/* Temporary rebuild button */}
                            {this.state.allowed_module === "all" ? (
                              this.state.downloadiosbuild === true && (
                                <Link
                                  // title={
                                  //   this.state.builder_status.status === 2 ||
                                  //   this.state.builder_status.status === 3
                                  //     ?
                                  //     <div className="mb-1 new-hover-element">
                                  //     <span className={""}>{this.state.builder_status.description}</span>
                                  //                 </div>

                                  //     : ""
                                  // }
                                  to="#"
                                  className={
                                    this.state.builder_status.status === 2 ||
                                    this.state.builder_status.status === 3
                                      ? "rebuild_new_disable"
                                      : "d-md-block rebuild_new"
                                  }
                                  onClick={(e) => this.createbuild("ios")}
                                >
                                  <img
                                    src={
                                      this.state.builder_status.status === 2 ||
                                      this.state.builder_status.status === 3
                                        ? require("../../assets/images/dashboard/download/gray-rebuild.png")
                                            .default
                                        : require("../../assets/images/dashboard/download/rebuild.png")
                                            .default
                                    }
                                    alt="Connecting"
                                    className="img-fluid mr-2"
                                  />
                                  {this.state.builder_status.status === 2 ||
                                  this.state.builder_status.status === 3 ? (
                                    <div className="mb-1 new-hover-element">
                                      Rebuild App
                                      <span
                                        className={"new-hover-element-text"}
                                      >
                                        {this.state.builder_status.title}
                                        <p>
                                          {
                                            this.state.builder_status
                                              .description
                                          }
                                        </p>
                                      </span>
                                    </div>
                                  ) : (
                                    <>Rebuild App</>
                                  )}
                                </Link>
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <Modal
          isOpen={this.state.modal}
          toggle={this.togglemodal}
          style={{ maxWidth: "680px", maxHeight: "247px" }}
          className={this.props.className}
          centered
        >
          <ModalHeader toggle={this.togglemodal} className="modal-xxl-content">
            <div className="crossmodal mt-1" onClick={this.togglemodal}>
              <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                <path
                  id="Union_18"
                  data-name="Union 18"
                  d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                  transform="translate(0.529 0.531)"
                  fill="#1b2952"
                  stroke="#1b2952"
                  strokeWidth="1.5"
                />
              </svg>
            </div>
          </ModalHeader>
          <ModalBody className="conecttoapi">
            <div className="text-center mb-5">
              <h2 className="mb-3">Connect website with app</h2>
              <h3>
                This step is mandatory to sync your website with the mobile app.
              </h3>
            </div>
            <div className="text-center mb-5">
              <img
                src={
                  require("../../assets/images/dashboard/previewapi.png")
                    .default
                }
                alt="Connecting"
                className="img-fluid"
              />
            </div>
            {parseInt(localStorage.getItem("website_technology")) === 5 ? (
              <div className="text-center mb-5">
                <p>
                  Enter the WooCommerce REST API keys, install the AppMySite WP
                  plugin and verify it to connect website with app.
                </p>
              </div>
            ) : (
              <div className="text-center mb-5">
                <p>
                  Install the AppMySite WP plugin and verify it in to connect
                  website with app.
                </p>
              </div>
            )}
            <div className="text-center mb-4">
              {window.location.pathname === "/app/connect/wp-grant-access" ? (
                <Link to="#" onClick={this.togglemodal}>
                  Connect APP
                </Link>
              ) : parseInt(localStorage.getItem("website_technology")) === 5 ? 
              (
                <Link to="/app/connect/wp-grant-access">Connect APP</Link>
              ) : window.location.pathname === "/app/connect/wp-plugin" ? (
                <Link to="#" onClick={this.togglemodal}>
                  Connect APP
                </Link>
              ) : (
                <Link to="/app/connect/wp-plugin">Connect APP</Link>
              )}
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.pointsmodal}
          toggle={this.pointsmodalclick}
          style={{ maxWidth: "680px", maxHeight: "247px" }}
          className={this.props.className}
          centered
        >
          <ModalHeader
            toggle={this.pointsmodalclick}
            className="modal-xxl-content"
          >
            <div className="crossmodal mt-1" onClick={this.pointsmodalclick}>
              <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                <path
                  id="Union_18"
                  data-name="Union 18"
                  d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                  transform="translate(0.529 0.531)"
                  fill="#1b2952"
                  stroke="#1b2952"
                  strokeWidth="1.5"
                />
              </svg>
            </div>
          </ModalHeader>
          <ModalBody className="completesteps">
            <h4>Complete all the steps to download your app</h4>
            <img
              src={
                require("../../assets/images/dashboard/completesteps.png")
                  .default
              }
              alt="step"
            />
            <p>
              Designing your app and connecting it with your website is
              mandatory to download it.
            </p>
            <div className="buttonbox">
              <p onClick={this.pointsmodalclick}>Complete all Steps</p>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.buildapp}
          toggle={this.togglebuildapp}
          style={{ maxWidth: "680px", maxHeight: "350px" }}
          className={this.props.className}
          centered
        >
          <ModalHeader
            toggle={this.togglebuildapp}
            className="modal-xxl-content modal-categories update-type"
          >
            <div className="crossmodal mt-1" onClick={this.togglebuildapp}>
              <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                <path
                  id="Union_18"
                  data-name="Union 18"
                  d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                  transform="translate(0.529 0.531)"
                  fill="#1b2952"
                  stroke="#1b2952"
                  strokeWidth="1.5"
                />
              </svg>
            </div>
          </ModalHeader>
          <ModalBody className="conecttoapi publish-app-modal">
            <div className="text-center mb-md-5 mb-3">
              <h2>Ready to build your app? </h2>
            </div>
            <div className="text-center mb-md-5 mb-4">
              <img
                src={
                  require("../../assets/images/dashboard/build-app.png").default
                }
                alt="Connecting"
                className="img-fluid"
              />
            </div>
            <div className="text-center mb-md-5 mb-4">
              <p>
                While you can update almost everything about your app once it is
                built, the App Name, App Icon and Launch Screen will be locked.
                Don’t worry, you can still change these later, but a new app
                version will be required with a new build.
              </p>
            </div>
            <div className="text-center mb-4 d-flex website-type align-items-center justify-content-center">
              <div
                onClick={(e) => this.togglebuildapp(e)}
                className="cancel-btn"
              >
                Cancel
              </div>

              {this.state.buildplatform === "ios" ? (
                <div
                  onClick={(e) => this.iosdataform(e)}
                  className="update-btn ml-3"
                >
                  Confirm
                </div>
              ) : (
                <div
                  onClick={(e) => this.handleandroidpopup(e)}
                  className="update-btn ml-3"
                >
                  Confirm
                </div>
              )}
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
