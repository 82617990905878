import axios from "axios";
import S3 from "react-aws-s3";
import { errortoast, sucesstoast } from "../../Dashboard/Toaster";
import { CustomS3Client } from "../../utils/S3utils";
const Url = process.env.REACT_APP_API_URL + "api/app/";
const wpUrl = process.env.REACT_APP_Aalytics
const URL_AUTH = process.env.REACT_APP_API_URL + "api/user/";

export const getIAPApi = (
  setSpinner,
  setkey_id,
  setissue_id,
  setapple_id,
  setcertificatefilename,
  setcertificates3name,
  setKeyFileName,
  setKeyFileS3Name,
  setsubs_key_id,
  setPlan,
  setappuser_role_id,
  setCode,
  setreseller_package_id,
  setaddon_code,
  setData,
  setUpdatedAt,
  setAppstoreConnectionStatus,
  setplan_code
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(
      Url +
        "get-ios-iap-connection-data/" +
        btoa(parseInt(localStorage.getItem("Appid"))),
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        setPlan(res.data.plan);
        setSpinner(false);
        if (res.data.data !== null) {
          if (setAppstoreConnectionStatus) {
            setAppstoreConnectionStatus(res.data.data.iap_status);
          }
          setkey_id(res.data.data.key_id);
          setissue_id(res.data.data.issuer_id);
          setapple_id(res.data.data.apple_id);
          setcertificatefilename(res.data.data.subscription_key_file);
          setcertificates3name(res.data.data.subscription_key_file);
          setData(res.data.data);
          setKeyFileName(res.data.data.key_file);
          setKeyFileS3Name(res.data.data.key_file);
          setsubs_key_id(res.data.data.subscription_key_id);
          setUpdatedAt(res.data.data.updated_at);
        }
        setCode(res.data.code);
        setappuser_role_id(res.data.appuser_role_id);
      } else if (res.data.code === 203) {
        setPlan(res.data.plan);
        setappuser_role_id(res.data.appuser_role_id);
        setCode(res.data.code);
        setreseller_package_id(res.data.reseller_package_id);
        setSpinner(false);
        setaddon_code(res.data.addon_code);
        setplan_code(res.data.plan_code);
      }
    });
};

export const getServerUrl = (setUrl, setLoading) => {
  setLoading(true);
  const header_auth = {
    'Content-Type': 'application/json',
    'Authorization': "Bearer " + localStorage.getItem("amstoken"),
  }

  axios.get(URL_AUTH + "preview/" + btoa(localStorage.getItem("Appid")),
    {
      headers: header_auth
    }
  )
  .then((res) => {
    const headers = {
      "Content-Type": "application/json",
      'X-App-Device-Type':'ios',
      'X-App-Code-Ver':localStorage.getItem("Apiversion"),
      "AmsClientID": `${res?.data?.data?.AmsClientID.toString()}`,
      "AmsClientSecret": `${res?.data?.data?.AmsClientSecret}`,
      "AMSEnvironment": `${res?.data?.data?.AMSEnvironment}`,
    }

    axios
    .get(wpUrl + "/app-store-webhook",
      {
        headers: headers,
        data: {},
      }
    )
    .then((res) => {
      if(res.data.code === 200) {
        setUrl(res.data?.data?.url)
      } else {
        setUrl("")
      }
      setLoading(false);
    })
    .catch(() => {
      setUrl("");
      setLoading(false);
    })
  })
  .catch(() => {
    setUrl("");
    setLoading(false);
  })
}

export const CreateConnection = (
  params,
  setSaveBtnText,
  setVerifyBtnText,
  setLoaderForSaveBtn,
  setLoaderForVerifyBtn,
  setUpdatedAt,
  setOpenNotConnectedPopUp,
  setAppstoreConnectionStatus,
  appstoreConnectionStatus,
  setErrorDescription,
  from
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "save-ios-iap-connection-data", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        setUpdatedAt(res.data.updated_at);
        if (from === "save") {
          setSaveBtnText("Save");
          setLoaderForSaveBtn("d-none");
          sucesstoast("The record has been saved.");
        } else {
          setAppstoreConnectionStatus(
            res.data.iap_info
              ? res.data.iap_info.status
              : appstoreConnectionStatus
          );
          setVerifyBtnText("Verify");
          setLoaderForVerifyBtn("d-none");
          if (res.data.iap_info && res.data.iap_info.status === 3) {
            setErrorDescription(
              res.data.iap_info ? res.data.iap_info.description : ""
            );
            setOpenNotConnectedPopUp(true);
          } else {
            sucesstoast("The record has been verified.");
          }
        }
      } else {
        if (from === "save") {
          errortoast("Oh no! Something’s not right.");
          setSaveBtnText("Save");
          setLoaderForSaveBtn("d-none");
        } else {
          setVerifyBtnText("Verify");
          setLoaderForVerifyBtn("d-none");
        }
      }
    })
    .catch((error) => {
      
      if (from === "save") {
        setSaveBtnText("Save");
        setLoaderForSaveBtn("d-none");
      } else {
        setVerifyBtnText("Verify");
        setLoaderForVerifyBtn("d-none");
      }
      errortoast("Oh no! Something’s not right.");
    });
};

export const Certificate = (
  params,
  setcertificates3name,
  setdisabledsubmitbutton
) => {
  function dataURLtoFileoriginal(dataurl, filename) {
    var arr = dataurl.split(","),
      //mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: "application/p8" });
  }
  let originalfile = dataURLtoFileoriginal(params.original, "hello.p8");
  const oriconfig = {
    bucketName: process.env.REACT_APP_bucketName,
    dirName: params.original_path.replace(/\/$/, "") /* optional */,
    region: process.env.REACT_APP_region,
    accessKeyId: process.env.REACT_APP_accessKeyId,
    secretAccessKey: process.env.REACT_APP_secretAccessKey,
    s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
  };
  const ReactS3original = new CustomS3Client(oriconfig);
  const originalFileName = params.imgname;
  ReactS3original.uploadFile(originalfile, originalFileName)
    .then((data) => {
      if (data.status === 204) {
        setcertificates3name(data.key.split("/").pop());
        setdisabledsubmitbutton(false);
      }
    })
    .catch((err) => {
      errortoast("Something went wrong. Try again after sometime.");
    });
};

export const getAndroidIAPApi = async (setAndroidServiceAccountName) =>
  // setSpinner,
  {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("amstoken"),
    };

    try {
      const response = await axios.get(
        Url +
          "get-android-iap-info/" +
          btoa(parseInt(localStorage.getItem("Appid"))),
        { headers: headers }
      );

      const res = response.data;

      if (res.code === 200) {
        // setPlan(res.plan);
        // setSpinner(false);
        setAndroidServiceAccountName(res?.service_account_json);

        if (res.data !== null) {
          // if (setAppstoreConnectionStatus) {
          //   // setAppstoreConnectionStatus(res.data.iap_status);
          // }
          // setkey_id(res.data.key_id);
          // setissue_id(res.data.issuer_id);
          // setapple_id(res.data.apple_id);
          // setcertificatefilename(res.data.subscription_key_file);
          // setcertificates3name(res.data.subscription_key_file);
          // setData(res.data);
          // setKeyFileName(res.data.key_file);
          // setKeyFileS3Name(res.data.key_file);
          // setsubs_key_id(res.data.subscription_key_id);
          // setUpdatedAt(res.data.updated_at);
        }

        // setCode(res.code);
        // setappuser_role_id(res.appuser_role_id);
      } else if (res.code === 203) {
        // setPlan(res.plan);
        // setappuser_role_id(res.appuser_role_id);
        // setCode(res.code);
        // setreseller_package_id(res.reseller_package_id);
        // setSpinner(false);
        // setaddon_code(res.addon_code);
        // setplan_code(res.plan_code);
      }
    } catch (error) {
      console.error("Error fetching IAP data: ", error);
      // setSpinner(false);
    }
  };

export const saveAndroidIAPConnection = async (
  setSaveButtonLoader,
  androidserviceaccountname,
  setStep1Collapse,
  setStep2Collapse,
  setStep3Collapse,
  setStep4Collapse,
  appPlan,
  toggleActivateModal,
  code,
  e
) => {
  if(code !== 200){
    toggleActivateModal(e)
    return;
   }
  if (!androidserviceaccountname) {
    errortoast("Service account key is a required field.");
    setStep4Collapse(true);
    setStep1Collapse(false);
    setStep2Collapse(false);
    setStep3Collapse(false);
  } else {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("amstoken"),
    };
    setSaveButtonLoader(true);
    try {
      const response = await axios.post(
        Url + "save-android-iap-info",
        {
          app_id: parseInt(localStorage.getItem("Appid")),
          service_account_json: androidserviceaccountname,
        },
        { headers: headers }
      );

      const res = response.data;

      if (res.code === 200) {
        setSaveButtonLoader(false);

        if (res?.iap_info && res?.iap_info.status === 3) {
          // setErrorDescription(res.iap_info ? res.iap_info.description : "");
          // setOpenNotConnectedPopUp(true);
        } else {
          sucesstoast("The record has been saved.");
        }
      } else {
        setSaveButtonLoader(false);
      }
    } catch (error) {
      setSaveButtonLoader(false);

      errortoast("Oh no! Something’s not right.");
    }
  }
};
