import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import { FormGroup, Label, Input } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import imagebackground from "../../../assets/images/dashboard/homescreen-banner-default.png";
import uploadimagebackground from "../../../assets/images/dashboard/cms/uploadbackground.png";
// import Reactcolorpicker from "./NewReactcolorpicker";
// import { SketchPicker } from "react-color";
import Dropdown from "react-bootstrap/Dropdown";
import NewImageGalleryPopup from "../../NewImageGalleryPopup";
import {
  
  pagesBlogCatPostForWPandWC,

} from "../../../Api/NewHomescreen/NewHomescreenApi";
import Resizer from "react-image-file-resizer";
import NewReactcolorpicker from "../NewReactcolorpicker";
// import html2canvas from "html2canvas";
import S3 from "react-aws-s3";
import { errortoast } from "../../Toaster";
import { cmsGetPagesApi, cmsGetPostsApi } from "../../../Api/CmsApi/Cmsapi";
import { DropdownButton } from "react-bootstrap";
import { pageAndPostDataPopUp, parentPage } from "../../CmsBuilder/CommonPopUp";
import { pageSelectionForCMS } from "./allPopups";

const HMSmainBanner = ({
  homescreenData,
  setHomescreenData,
  blockIndx,
  handleStatus,
  handleIsRowOpen,
  isRowOpen,

  handleDeleteItem,
  eachBlock,
  handleDuplicateItem,

  uploadFinalCustomizeImgForApp,
  defaultDataForComaprision,presetColors,
  provided,
}) => {
  // const sheet = document.createElement("style");
  const [tabArray, setTabArray] = useState([0, 1, 2, 3]);
  const [isGalleryPopup, setIsGalleryPopup] = useState(false);
  const [isBanner, setIsBanner] = useState(false);
  // const [activeTab, setActiveTab] = useState("1");
  const [selectedImgIndex, setSelectedImgIndex] = useState(0);
  const [prevImgUrl, setPrevImgUrl] = useState("");
  const [bannerLayout, setBannerLayout] = useState(
    homescreenData[blockIndx].style.layout
  );
  const [spinner2, setSpinner2] = useState(true);

  const [pagesData, setPagesData] = useState([]);
  const [postData, setPostData] = useState([]);
  const [isPagePopUp, setIsPagePopup] = useState(false);
  const [isPostPopUp, setIsPostPopup] = useState(false);

  const [isPopDisableBtn, setIsPopDisableBtn] = useState(true);
  useEffect(() => {
    if (
      parseInt(localStorage.getItem("website_technology")) === 4 ||
      parseInt(localStorage.getItem("website_technology")) === 5
    ) {
      pagesBlogCatPostForWPandWC("pages", setPagesData);
      pagesBlogCatPostForWPandWC("posts", setPostData);
    } else if (parseInt(localStorage.getItem("website_technology")) === 3) {
      cmsGetPostsApi(
        setPostData,
        btoa(localStorage.getItem("Appid")),
        setSpinner2,
        null
      );
      cmsGetPagesApi(
        setPagesData,
        btoa(localStorage.getItem("Appid")),
        setSpinner2
      );
    }
  }, []);

  const handleBannerPopUp = (e, from) => {
    let newArr = [...homescreenData];
    if (from === "add") {
      setBannerLayout(newArr[blockIndx].style.layout);

      // newArr[blockIndx].value.main_banner.map((eachBanner, indx) => {
      //   uploadFinalCustomizeImgForApp(indx, blockIndx, newArr);
      // });
    } else {
      newArr[blockIndx].style.layout = JSON.parse(JSON.stringify(bannerLayout));
    }
    setHomescreenData(newArr);
    setIsBanner(!isBanner);
  };

  // Main Banner layout ratio
  const handleBannerLayout = (e, imageAspecRatio) => {
    let newArr = [...homescreenData];
    newArr[blockIndx].style.layout.image_aspect_ratio = imageAspecRatio;
    setHomescreenData(newArr);
  };

  // 4 image tab switching
  const toggle = (tab) => {
    setSelectedImgIndex(tab);
    let newArr = [...homescreenData];

    if (newArr[blockIndx].homescreen_selected_image_index !== tab) {
      newArr[blockIndx].homescreen_prev_image_index =
        newArr[blockIndx].homescreen_selected_image_index;

      if (
        newArr[blockIndx].value.main_banner[
          newArr[blockIndx].homescreen_prev_image_index
        ].is_data_change
      ) {
        uploadFinalCustomizeImgForApp(
          newArr[blockIndx].homescreen_prev_image_index,
          blockIndx,
          newArr
        );
      }
      newArr[blockIndx].homescreen_selected_image_index = tab;
    }

    setHomescreenData(newArr);
  };

  // Banner Image Color selection
  const handleBannerColor = (color, blockIndx, imageNo) => {
    let newArr = [...homescreenData];
    newArr[blockIndx].value.main_banner[imageNo].style.color = color;
    newArr[blockIndx].value.main_banner[imageNo].is_data_change = true;
    setHomescreenData(newArr);
  };

  // Banner Text Color Selection
  const handleBannerTextColor = (color, blockIndx, imageNo) => {
    let newArr = [...homescreenData];
    newArr[blockIndx].value.main_banner[imageNo].is_data_change = true;
    newArr[blockIndx].value.main_banner[imageNo].style.text_color = color;
    setHomescreenData(newArr);
  };

  // Banner Text
  const handleBannerText = (e, blockIndx, imageNo) => {
    let newArr = [...homescreenData];
    newArr[blockIndx].value.main_banner[imageNo].is_data_change = true;
    newArr[blockIndx].value.main_banner[imageNo].style.text = e.target.value;
    setHomescreenData(newArr);
  };

  // Banner font Size
  const handleBannerFontSize = (e, blockIndx, imageNo) => {
    let newArr = [...homescreenData];
    newArr[blockIndx].value.main_banner[imageNo].is_data_change = true;
    newArr[blockIndx].value.main_banner[imageNo].style.font_size =
      e.target.value;
    setHomescreenData(newArr);
  };

  // Banner Text Allignment
  const handleBannerTextAlignment = (e, blockIndx, imageNo) => {
    let newArr = [...homescreenData];
    newArr[blockIndx].value.main_banner[imageNo].is_data_change = true;
    newArr[blockIndx].value.main_banner[imageNo].style.text_align =
      e.target.value;
    setHomescreenData(newArr);
  };

  //Bold , Italic and Underline selection
  const handleFontFeatures = (e, blockIndx, imageNo, type) => {
    let newArr = [...homescreenData];
    newArr[blockIndx].value.main_banner[imageNo].is_data_change = true;
    if (type === "bold") {
      newArr[blockIndx].value.main_banner[imageNo].style.is_bold =
        e.target.checked === true ? 1 : 0;
    } else if (type === "italic") {
      newArr[blockIndx].value.main_banner[imageNo].style.is_italic =
        e.target.checked === true ? 1 : 0;
    } else if (type === "underline") {
      newArr[blockIndx].value.main_banner[imageNo].style.is_underline =
        e.target.checked === true ? 1 : 0;
    } else {
      return;
    }
    setHomescreenData(newArr);
  };

  // Banner Text Vertical Position
  const handleBannerVerticalTextPosition = (e, blockIndx, imageNo) => {
    let newArr = [...homescreenData];
    newArr[blockIndx].value.main_banner[imageNo].is_data_change = true;
    newArr[blockIndx].value.main_banner[imageNo].style.vertical_text_position =
      e.target.value;
    setHomescreenData(newArr);
  };

  // Banner Text Horizontal Position
  const handleBannerHorizontalTextPosition = (e, blockIndx, imageNo) => {
    let newArr = [...homescreenData];
    newArr[blockIndx].value.main_banner[imageNo].is_data_change = true;
    newArr[blockIndx].value.main_banner[
      imageNo
    ].style.horizontal_text_position = e.target.value;
    setHomescreenData(newArr);
  };

  // Click to Open Title selection
  const handleTitleChange = (e, blockIndx, imageNo) => {
    let newArr = [...homescreenData];
    newArr[blockIndx].value.main_banner[imageNo].is_data_change = true;
    newArr[blockIndx].value.main_banner[imageNo].hyperlink_value = "";
    newArr[blockIndx].value.main_banner[imageNo].hyperlink_option =
      e.target.value;
    newArr[blockIndx].value.main_banner[imageNo].hyperlink_alias = "";
    setHomescreenData(newArr);
  };

  // Click to Open Value selection
  const handleValueChange = (e, blockIndx, imageNo) => {
    let newArr = [...homescreenData];
    newArr[blockIndx].value.main_banner[imageNo].is_data_change = true;
    newArr[blockIndx].value.main_banner[imageNo].hyperlink_value =
      e.target.value;
    newArr[blockIndx].value.main_banner[imageNo].hyperlink_alias = "";

    setHomescreenData(newArr);
  };

  // Font family selction
  const handlefontFamily = (font, imageNo) => {
    let newArr = [...homescreenData];
    newArr[blockIndx].value.main_banner[imageNo].is_data_change = true;
    newArr[blockIndx].value.main_banner[imageNo].style.font_family = font;
    setHomescreenData(newArr);
  };

  // POst and Page selection
  const handlePagePopUp = (e, from) => {
    if (from !== "add") {
      let newArr = [...homescreenData];
      newArr[blockIndx].value.main_banner[selectedImgIndex].hyperlink_value =
        ""; //pTitle
      newArr[blockIndx].value.main_banner[
        selectedImgIndex
      ].is_data_change = true;
      setHomescreenData(newArr);
    }

    setIsPagePopup(!isPagePopUp);
  };

  const handlePostPopUp = (e, from) => {
    if (from !== "add") {
      let newArr = [...homescreenData];
      newArr[blockIndx].value.main_banner[selectedImgIndex].hyperlink_value =
        ""; //pTitle
      newArr[blockIndx].value.main_banner[
        selectedImgIndex
      ].is_data_change = true;
      setHomescreenData(newArr);
    }

    setIsPostPopup(!isPostPopUp);
  };

  // const handleFilterName = (data, value) => {
  //   let title = "";
  //   data &&
  //     data.map((row) => {
  //       if (
  //         (parseInt(localStorage.getItem("website_technology")) !== 3 &&
  //           row.id.toString() === value) ||
  //         (parseInt(localStorage.getItem("website_technology")) === 3 &&
  //           row._id === value)
  //       )
  //         title =
  //           parseInt(localStorage.getItem("website_technology")) === 3
  //             ? row.title
  //             : row.title.rendered;
  //     });
  //   return title;
  // };

  // const handleFilterNameForChild = (data, value, from, finalVal) => {
  //   // let title = "";
  //   data.map((eachCat, indx) => {
  //     if (eachCat._id === value) {
  //       finalVal = eachCat.title;
  //     }
  //     if (eachCat.child !== undefined && eachCat.child.length > 0) {
  //       // if parent has childrens
  //       handleFilterNameForChild(eachCat.child, value, "child", finalVal);
  //     }

  //     // return (
  //     //   check === "parent" &&
  //     //   indx === categoryData.length - 1 &&
  //     //   childParentList(newArr)
  //     // );
  //   });

  //   return finalVal;
  // };

  //Saving parent Id for Post and Page both
  const handlePopUpVal = (e, row, type) => {
    
    setIsPopDisableBtn(false);

    let newArr = [...homescreenData];
    newArr[blockIndx].value.main_banner[selectedImgIndex].is_data_change = true;
    newArr[blockIndx].value.main_banner[selectedImgIndex].hyperlink_value =
      parseInt(localStorage.getItem("website_technology")) === 3
        ? row._id
        : row.id.toString();
    newArr[blockIndx].value.main_banner[selectedImgIndex].hyperlink_alias =
      parseInt(localStorage.getItem("website_technology")) === 3
        ? row.title
        : row.title.rendered;
    setHomescreenData(newArr);
  };

  // image gallery pop up
  const imageGalleryPopup = (e) => {
    setIsGalleryPopup(!isGalleryPopup);

    let newArr = [...homescreenData];
    setPrevImgUrl(
      newArr[blockIndx].value.main_banner[
        newArr[blockIndx].homescreen_selected_image_index
      ].thumb_image
    );
  };

  //Remove image url
  const handleImageCancel = (e) => {
    let newArr = [...homescreenData];
    newArr[blockIndx].value.main_banner[
      newArr[blockIndx].homescreen_selected_image_index
    ].is_data_change = true;
    newArr[blockIndx].value.main_banner[
      newArr[blockIndx].homescreen_selected_image_index
    ].thumb_image = "";
    newArr[blockIndx].value.main_banner[
      newArr[blockIndx].homescreen_selected_image_index
    ].original_image = "";
    setHomescreenData(newArr);
    // setIsGalleryPopup(!isGalleryPopup);
  };

  const settingYourdata = (
    imgName,
    from,
    orgFileName,
    url1200,
    useUrl,
    // base64OrgImgFromApi,
    base64OrgImgFromCustom
  ) => {
    let newArr = [...homescreenData];

    if (from === "original") {
      if (
        newArr[blockIndx].value.main_banner[
          newArr[blockIndx].homescreen_prev_image_index
        ].original_image.includes("http") ||
        newArr[blockIndx].value.main_banner[
          newArr[blockIndx].homescreen_prev_image_index
        ].original_image.includes("base64")
      ) {
        newArr[blockIndx].value.main_banner[
          newArr[blockIndx].homescreen_prev_image_index
        ].is_data_change = true;
        newArr[blockIndx].value.main_banner[
          newArr[blockIndx].homescreen_prev_image_index
        ].original_image = imgName;
      } else {
        newArr[blockIndx].value.main_banner[
          newArr[blockIndx].homescreen_selected_image_index
        ].is_data_change = true;
        newArr[blockIndx].value.main_banner[
          newArr[blockIndx].homescreen_selected_image_index
        ].original_image = imgName;
      }

      setHomescreenData(newArr);
      return;
    }
    newArr[blockIndx].value.main_banner[
      newArr[blockIndx].homescreen_selected_image_index
    ].thumb_image = imgName;
    newArr[blockIndx].value.main_banner[
      newArr[blockIndx].homescreen_selected_image_index
    ].is_data_change = true;
    if (from === "resize") {
      newArr[blockIndx].value.main_banner[
        newArr[blockIndx].homescreen_selected_image_index
      ].is_data_change = true;
      newArr[blockIndx].value.main_banner[
        newArr[blockIndx].homescreen_selected_image_index
      ].original_image = base64OrgImgFromCustom;
    } else if (from === "apiImg") {
      newArr[blockIndx].value.main_banner[
        newArr[blockIndx].homescreen_selected_image_index
      ].is_data_change = true;
      newArr[blockIndx].value.main_banner[
        newArr[blockIndx].homescreen_selected_image_index
      ].original_image = useUrl;
    }
    setHomescreenData(newArr);
  };

  const settingOrgImgFrom3rdParty = (apiOrgImg) => {
    let newArr = [...homescreenData];

    if (
      newArr[blockIndx].value.main_banner[
        newArr[blockIndx].homescreen_prev_image_index
      ].original_image.includes("http") ||
      newArr[blockIndx].value.main_banner[
        newArr[blockIndx].homescreen_prev_image_index
      ].original_image.includes("base64")
    ) {
      newArr[blockIndx].value.main_banner[
        newArr[blockIndx].homescreen_prev_image_index
      ].is_data_change = true;
      newArr[blockIndx].value.main_banner[
        newArr[blockIndx].homescreen_prev_image_index
      ].original_image = apiOrgImg;
    } else {
      newArr[blockIndx].value.main_banner[
        newArr[blockIndx].homescreen_selected_image_index
      ].is_data_change = true;
      newArr[blockIndx].value.main_banner[
        newArr[blockIndx].homescreen_selected_image_index
      ].original_image = apiOrgImg;
    }

    setHomescreenData(newArr);
  };

  const yourErrFun = () => {
    // let newArr = [...cmsArray];
    // newArr[indx].portal_value.source_file = prevImgUrl;
    // setcmsData(newArr);
  };

  const handleDeleteBannerSlide = (e, imageIndx) => {
    let newArr = [...homescreenData];
    
    newArr[blockIndx].homescreen_prev_image_index = 0;

    newArr[blockIndx].homescreen_selected_image_index = 0;
    setSelectedImgIndex(0)

    newArr[blockIndx].value.main_banner.splice(imageIndx, 1);

    setHomescreenData(newArr);
  };

  const handleAddBannerSlide = () => {
    let newArr = [...homescreenData];

    let obj = {
      original_image: "",
      image: "",
      thumb_image: "",
      original_path: "",
      path: "",
      hyperlink_option: "url",
      hyperlink_value: "www.test.com",
      hyperlink_alias: "",
      is_data_change: false,

      style: {
        color:
          "linear-gradient(160deg,   rgba(51,199,255,0.6) 0%, rgba(71,95,254,0.6) 50%, rgba(107,72,254,0.6) 100%)",
        text: "Text 1",
        text_color: "#FFFFFF",
        font_size: "20px",
        font_family: "Poppins-SemiBold",
        text_align: "center",
        vertical_text_position: "center",
        horizontal_text_position: "center",
        text_style: "",
        is_bold: 1,
        is_italic: 1,
        is_underline: 0,
      },
    };
    newArr[blockIndx].value.main_banner.push(obj);

    setHomescreenData(newArr);
  };

  return (
    <div className="accordionItem">
      <h2
        className="accordionItemHeading allflex"
        draggableId={blockIndx.toString()}
        {...provided.dragHandleProps}
        onClick={(e) => handleIsRowOpen(e, blockIndx)}
      >
        Main Banner
        <div className="homescreensection">
          <label className="switch" htmlFor={`checkstatus${blockIndx}`}>
            <input
              type="checkbox"
              id={`checkstatus${blockIndx}`}
              checked={homescreenData[blockIndx].enable === 1 ? true : false}
              onChange={(e) => handleStatus(e, blockIndx)}
            />
            <span className="slider round"></span>
          </label>
        </div>
        <img
          src={
            isRowOpen[blockIndx] === true
              ? require("../../../assets/images/dashboard/cms/accordian-open.png")
                  .default
              : require("../../../assets/images/dashboard/cms/accordian-icon.png")
                  .default
          }
        />
      </h2>

      {isRowOpen[blockIndx] === true && (
        <div className="accordionItemContent">
          <div className="layoutlink d-flex align-items-center justify-content-end">
            <Link onClick={handleBannerPopUp}>
              <img
                className="mr-2"
                src={
                  require("../../../assets/images/dashboard/layout.png").default
                }
              />
              Change Layout
            </Link>
          </div>

          <Nav tabs className="homescreen-banner-tabs">
            {homescreenData[blockIndx].value.main_banner.map(
              (eachBanner, bannerIndx) => {
                return (
                  <NavItem>
                    {" "}
                    <p
                      style={{ textAlign: "center" }}
                      onClick={(e) => handleDeleteBannerSlide(e, bannerIndx)}
                    >
                    {homescreenData[blockIndx].value.main_banner.length>1 &&
                     <img src={require("../../../assets/images/dashboard/homescreen-delete.png").default }/> }
                    </p>
                    <NavLink
                      className={classnames({
                        active:
                          homescreenData[blockIndx]
                            .homescreen_selected_image_index === bannerIndx ||
                          homescreenData[blockIndx]
                            .homescreen_selected_image_index === undefined,
                      })}
                      onClick={() => {
                        toggle(bannerIndx);
                      }}
                    >
                      {" "}
                      <div
                        className="banner-thumb-img"
                        style={{
                          width: "100%",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize:
                            eachBanner.thumb_image !== null &&
                            eachBanner.thumb_image !== undefined &&
                            eachBanner.thumb_image !== ""
                              ? "cover"
                              : "",
                          backgroundImage:
                            "url(" +
                            (eachBanner.thumb_image !== null &&
                            eachBanner.thumb_image !== undefined &&
                            eachBanner.thumb_image !== ""
                              ? eachBanner.thumb_image.includes(
                                  process.env.REACT_APP_Image_Path
                                )
                                ? eachBanner.thumb_image
                                : process.env.REACT_APP_Image_Path +
                                  localStorage.getItem("user_id") +
                                  "/" +
                                  localStorage.getItem("Appid") +
                                  process.env.REACT_APP_MAIN_BANNER_IMG +
                                  eachBanner.thumb_image
                              : imagebackground) +
                            ")",
                        }}
                      ></div>
                    </NavLink>
                  </NavItem>
                );
              }
            )}
            {homescreenData[blockIndx].value.main_banner.length < 4 && (
              <NavItem>
                <h4><img src={require("../../../assets/images/dashboard/homescreen-add.png").default }/></h4>
                <NavLink
                  onClick={() => {
                    handleAddBannerSlide();
                  }}
                >
                  {" "}
                  <div
                    className="banner-thumb-img"
                    // style={{
                    //   width: "100%",
                    //   backgroundRepeat: "no-repeat",
                    //   backgroundPosition: "center",
                    //   backgroundSize: "",
                    //   backgroundImage: "url(" + imagebackground + ")",
                    // }}
                  ></div>
                </NavLink>
              </NavItem>
            )}
          </Nav>

          <TabContent
            activeTab={
              homescreenData[blockIndx].homescreen_selected_image_index
            }
            className="mt-5 homebanner-custom"
          >
            {homescreenData[blockIndx].value.main_banner &&
              homescreenData[blockIndx].value.main_banner.map(
                (eachBanner, bannerIndx) => {
                  return (
                    <TabPane tabId={bannerIndx}>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="set-full-img">
                            <h6>Banner Image </h6>
                            <div
                              className="homescreen-uploader"
                              onClick={(e) => imageGalleryPopup(e)}
                            >
                              <div className="d-flex align-items-center">
                                <div
                                  className="uploadpixabayimage"
                                  style={{
                                    backgroundImage:
                                      "url(" +
                                      (eachBanner.thumb_image.includes(
                                        process.env.REACT_APP_Image_Path
                                      )
                                        ? eachBanner.thumb_image
                                        : eachBanner.thumb_image
                                        ? process.env.REACT_APP_Image_Path +
                                          localStorage.getItem("user_id") +
                                          "/" +
                                          localStorage.getItem("Appid") +
                                          process.env
                                            .REACT_APP_MAIN_BANNER_IMG +
                                          eachBanner.thumb_image
                                        : uploadimagebackground) +
                                      ")",
                                  }}
                                ></div>
                                <p>
                                  {eachBanner.thumb_image
                                    ? eachBanner.thumb_image.includes(
                                        process.env.REACT_APP_Image_Path
                                      )
                                      ? eachBanner.thumb_image.replace(
                                          process.env.REACT_APP_Image_Path +
                                            localStorage.getItem("user_id") +
                                            "/" +
                                            localStorage.getItem("Appid") +
                                            process.env
                                              .REACT_APP_Dashboard_Crop,
                                          ""
                                        )
                                      : eachBanner.thumb_image
                                    : "Upload"}
                                </p>
                              </div>
                              {eachBanner.thumb_image ? (
                                <svg
                                  width="11.06"
                                  height="11.06"
                                  viewBox="0 0 11.06 11.06"
                                  onClick={(e) => handleImageCancel(e)}
                                >
                                  <path
                                    id="Union_1696"
                                    data-name="Union 1696"
                                    d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z"
                                    transform="translate(0.53 0.53)"
                                    fill="none"
                                    stroke="#7782a1"
                                    stroke-width="1.5"
                                  />
                                </svg>
                              ) : (
                                <></>
                              )}
                            </div>
                          </label>
                        </div>
                        <div className="col-md-6">
                          <h6>Banner Color</h6>
                          <div>
                            <div
                              className="homescreen-color-manage"
                             
                            >
                              {" "}
                              <NewReactcolorpicker
                                colorname={eachBanner.style.color}
                                onChange={(color) =>
                                  handleBannerColor(
                                    color,
                                    blockIndx,
                                    bannerIndx
                                  )
                                }
                                classselection={"fixback"}
                                valuecontrol={false}
                                presetColors={presetColors}
                              />
                              {eachBanner.style.color &&	
                                eachBanner.thumb_image && (	
                                  <svg	
                                    width="11.06"	
                                    height="11.06"	
                                    viewBox="0 0 11.06 11.06"	
                                    onClick={(e) =>	
                                      eachBanner.style.color &&	
                                      eachBanner.thumb_image	
                                        ? handleBannerColor(	
                                            "",	
                                            blockIndx,	
                                            bannerIndx	
                                          )	
                                        : null	
                                    }	
                                  >	
                                    <path	
                                      id="Union_1696"	
                                      data-name="Union 1696"	
                                      d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z"	
                                      transform="translate(0.53 0.53)"	
                                      fill="none"	
                                      stroke="#7782a1"	
                                      stroke-width="1.5"	
                                    />	
                                  </svg>	
                                )}
                              {/* <div
                          style={{
                            width: "36px",
                            height: "36px",
                            border: `1px solid ${bannerColor}`,
                            borderRadius: "0.25rem",
                            margin: "5px",
                            backgroundColor: bannerColor,
                          }}
                        ></div> */}
                              {/* <p className="m-0 p-0">{bannerColor}</p> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <input
                            className="mt-20"
                            type="text"
                            placeholder="Banner text"
                            value={eachBanner.style.text}
                            onChange={(e) =>
                              handleBannerText(e, blockIndx, bannerIndx)
                            }
                          ></input>
                        </div>
                      </div>

                      <div className="row mt-20">
                        <div className="col-md-12">
                          {/* <h6>Font</h6> */}
                          <DropdownButton
                            className="font-dropdown"
                            alignRight
                            title={eachBanner.style.font_family}
                            id="dropdown-menu-align-right"
                            style={{
                              fontFamily: eachBanner.style.font_family,
                            }}
                            value={eachBanner.style.font_family}
                            onSelect={(font) =>
                              handlefontFamily(font, bannerIndx)
                            }
                          >
                            <Dropdown.Item
                              style={{
                                fontFamily: "Arial",
                                background:
                                  eachBanner.style.font_family == "Arial"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family == "Arial"
                                    ? "white"
                                    : "",
                              }}
                              eventKey="Arial"
                            >
                              Arial
                            </Dropdown.Item>
                            <Dropdown.Item
                              style={{
                                fontFamily: "Arial-Black",
                                background:
                                  eachBanner.style.font_family == "Arial-Black"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family == "Arial-Black"
                                    ? "white"
                                    : "",
                              }}
                              eventKey="Arial-Black"
                            >
                              Arial Black
                            </Dropdown.Item>
                            <Dropdown.Item
                              style={{
                                fontFamily: "bainsley-regular",
                                background:
                                  eachBanner.style.font_family ==
                                  "bainsley-regular"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "bainsley-regular"
                                    ? "white"
                                    : "",
                              }}
                              eventKey="bainsley-regular"
                            >
                              Bainsley-Regular
                            </Dropdown.Item>
                            <Dropdown.Item
                              style={{
                                fontFamily: "bainsley-bold",
                                background:
                                  eachBanner.style.font_family ==
                                  "bainsley-bold"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "bainsley-bold"
                                    ? "white"
                                    : "",
                              }}
                              eventKey="bainsley-bold"
                            >
                              Bainsley Bold
                            </Dropdown.Item>
                            <Dropdown.Item
                              style={{
                                fontFamily: "barracuda-regular",
                                background:
                                  eachBanner.style.font_family ==
                                  "barracuda-regular"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "barracuda-regular"
                                    ? "white"
                                    : "",
                              }}
                              eventKey="barracuda-regular"
                            >
                              Barracuda Regular
                            </Dropdown.Item>
                            <Dropdown.Item
                              style={{
                                fontFamily: "barracuda-bold",
                                background:
                                  eachBanner.style.font_family ==
                                  "barracuda-bold"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "barracuda-bold"
                                    ? "white"
                                    : "",
                              }}
                              eventKey="barracuda-bold"
                            >
                              Barracuda Bold
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="betelgeuse-regular"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "betelgeuse-regular"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "betelgeuse-regular"
                                    ? "white"
                                    : "",
                                fontFamily: "betelgeuse-regular",
                              }}
                            >
                              {" "}
                              Betelgeuse-Regular
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="betelgeuse-bold"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "betelgeuse-bold"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "betelgeuse-bold"
                                    ? "white"
                                    : "",
                                fontFamily: "betelgeuse-bold",
                              }}
                            >
                              {" "}
                              Betelgeuse-Bold
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="Bookman"
                              style={{
                                background:
                                  eachBanner.style.font_family == "Bookman"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family == "Bookman"
                                    ? "white"
                                    : "",
                                fontFamily: "Bookman",
                              }}
                            >
                              {" "}
                              Bookman
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="cactron-regular"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "cactron-regular"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "cactron-regular"
                                    ? "white"
                                    : "",
                                fontFamily: "cactron-regular",
                              }}
                            >
                              {" "}
                              Cactron-Regular
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="cactronbold"
                              style={{
                                background:
                                  eachBanner.style.font_family == "cactronbold"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family == "cactronbold"
                                    ? "white"
                                    : "",
                                fontFamily: "cactronbold",
                              }}
                            >
                              {" "}
                              Cactron-Bold
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="cokanut-regular"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "cokanut-regular"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "cokanut-regular"
                                    ? "white"
                                    : "",
                                fontFamily: "cokanut-regular",
                              }}
                            >
                              {" "}
                              Cokanut-Regular
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="cokanut-bold"
                              style={{
                                background:
                                  eachBanner.style.font_family == "cokanut-bold"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family == "cokanut-bold"
                                    ? "white"
                                    : "",
                                fontFamily: "cokanut-bold",
                              }}
                            >
                              {" "}
                              Cokanut-Bold
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="ComicSansMS"
                              style={{
                                background:
                                  eachBanner.style.font_family == "ComicSansMS"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family == "ComicSansMS"
                                    ? "white"
                                    : "",
                                fontFamily: "ComicSansMS",
                              }}
                            >
                              {" "}
                              Comic Sans MS
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="cormorant-regular"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "cormorant-regular"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "cormorant-regular"
                                    ? "white"
                                    : "",
                                fontFamily: "cormorant-regular",
                              }}
                            >
                              {" "}
                              Cormorant-Regular
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="cormorant-bold"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "cormorant-bold"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "cormorant-bold"
                                    ? "white"
                                    : "",
                                fontFamily: "cormorant-bold",
                              }}
                            >
                              {" "}
                              Cormorant-Bold
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="Courier-New"
                              style={{
                                background:
                                  eachBanner.style.font_family == "Courier-New"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family == "Courier-New"
                                    ? "white"
                                    : "",
                                fontFamily: "Courier-New",
                              }}
                            >
                              {" "}
                              Courier New
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="dukascf-regular"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "dukascf-regular"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "dukascf-regular"
                                    ? "white"
                                    : "",
                                fontFamily: "dukascf-regular",
                              }}
                            >
                              {" "}
                              Dukas Cf-Regular
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="dukascf-bold"
                              style={{
                                background:
                                  eachBanner.style.font_family == "dukascf-bold"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family == "dukascf-bold"
                                    ? "white"
                                    : "",
                                fontFamily: "dukascf-bold",
                              }}
                            >
                              {" "}
                              Dukas Cf-Bold
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="emberly-regular"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "emberly-regular"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "emberly-regular"
                                    ? "white"
                                    : "",
                                fontFamily: "emberly-regular",
                              }}
                            >
                              {" "}
                              Emberly-Regular
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="emberly-bold"
                              style={{
                                background:
                                  eachBanner.style.font_family == "emberly-bold"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family == "emberly-bold"
                                    ? "white"
                                    : "",
                                fontFamily: "emberly-bold",
                              }}
                            >
                              {" "}
                              Emberly-Bold
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="epigrafica-regular"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "epigrafica-regular"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "epigrafica-regular"
                                    ? "white"
                                    : "",
                                fontFamily: "epigrafica-regular",
                              }}
                            >
                              {" "}
                              Epigrafica-Regular
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="epigrafica-bold"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "epigrafica-bold"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "epigrafica-bold"
                                    ? "white"
                                    : "",
                                fontFamily: "epigrafica-bold",
                              }}
                            >
                              {" "}
                              Epigrafica-Bold
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="Garamond"
                              style={{
                                background:
                                  eachBanner.style.font_family == "Garamond"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family == "Garamond"
                                    ? "white"
                                    : "",
                                fontFamily: "Garamond",
                              }}
                            >
                              {" "}
                              Garamond
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="Georgia"
                              style={{
                                background:
                                  eachBanner.style.font_family == "Georgia"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family == "Georgia"
                                    ? "white"
                                    : "",
                                fontFamily: "Georgia",
                              }}
                            >
                              {" "}
                              Georgia
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="grenze-regular"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "grenze-regular"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "grenze-regular"
                                    ? "white"
                                    : "",
                                fontFamily: "grenze-regular",
                              }}
                            >
                              {" "}
                              Grenze-Regular
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="grenze-bold"
                              style={{
                                background:
                                  eachBanner.style.font_family == "grenze-bold"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family == "grenze-bold"
                                    ? "white"
                                    : "",
                                fontFamily: "grenze-bold",
                              }}
                            >
                              {" "}
                              Grenze-Bold
                            </Dropdown.Item>
                            {/* <Dropdown.Item
                                    eventKey="hitmo20-regular"
                                    style={{
                                      background:
                                        eachBanner
                             
                                        .style.font_family ==
                                        "hitmo20-regular"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        eachBanner
                                  .style.font_family ==
                                        "hitmo20-regular"
                                          ? "white"
                                          : "",
                                      fontFamily: "hitmo20-regular",
                                    }}
                                  >
                                    {" "}
                                    Hitmo-Regular
                                  </Dropdown.Item> */}

                            {/* <Dropdown.Item
                                    eventKey="hitmo20-bold"
                                    style={{
                                      background:
                                        eachBanner
                                  .style.font_family == "hitmo20-bold"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        eachBanner
                                  .style.font_family == "hitmo20-bold"
                                          ? "white"
                                          : "",
                                      fontFamily: "hitmo20-bold",
                                    }}
                                  >
                                    Hitmo-Bold
                                  </Dropdown.Item> */}
                            <Dropdown.Item
                              eventKey="horizon-regular"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "horizon-regular"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "horizon-regular"
                                    ? "white"
                                    : "",
                                fontFamily: "horizon-regular",
                              }}
                            >
                              {" "}
                              Horizon Regular
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="horizon-bold"
                              style={{
                                background:
                                  eachBanner.style.font_family == "horizon-bold"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family == "horizon-bold"
                                    ? "white"
                                    : "",
                                fontFamily: "horizon-bold",
                              }}
                            >
                              {" "}
                              Horizon Bold
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="Impact"
                              style={{
                                background:
                                  eachBanner.style.font_family == "Impact"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family == "Impact"
                                    ? "white"
                                    : "",
                                fontFamily: "Impact",
                              }}
                            >
                              {" "}
                              Impact
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="karantina-regular"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "karantina-regular"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "karantina-regular"
                                    ? "white"
                                    : "",
                                fontFamily: "karantina-regular",
                              }}
                            >
                              {" "}
                              Karantina-Regular
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="karantina-bold"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "karantina-bold"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "karantina-bold"
                                    ? "white"
                                    : "",
                                fontFamily: "karantina-bold",
                              }}
                            >
                              {" "}
                              Karantina-Bold
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="kubanpro-regular"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "kubanpro-regular"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "kubanpro-regular"
                                    ? "white"
                                    : "",
                                fontFamily: "kubanpro-regular",
                              }}
                            >
                              {" "}
                              Kuban Pro Regular
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="kubanpro-bold"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "kubanpro-bold"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "kubanpro-bold"
                                    ? "white"
                                    : "",
                                fontFamily: "kubanpro-bold",
                              }}
                            >
                              {" "}
                              Kuban Pro Bold
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="kufam-regular"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "kufam-regular"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "kufam-regular"
                                    ? "white"
                                    : "",
                                fontFamily: "kufam-regular",
                              }}
                            >
                              {" "}
                              Kufam-Regular
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="kufam-bold"
                              style={{
                                background:
                                  eachBanner.style.font_family == "kufam-bold"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family == "kufam-bold"
                                    ? "white"
                                    : "",
                                fontFamily: "kufam-bold",
                              }}
                            >
                              {" "}
                              Kufam-Bold
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="mangoGrotesque-regular"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "mangoGrotesque-regular"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "mangoGrotesque-regular"
                                    ? "white"
                                    : "",
                                fontFamily: "mangoGrotesque-regular",
                              }}
                            >
                              {" "}
                              MangoGrotesque-Regular
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="mangoGrotesque-bold"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "mangoGrotesque-bold"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "mangoGrotesque-bold"
                                    ? "white"
                                    : "",
                                fontFamily: "mangoGrotesque-bold",
                              }}
                            >
                              {" "}
                              MangoGrotesque-Bold
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="mesheddisplay-regular"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "mesheddisplay-regular"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "mesheddisplay-regular"
                                    ? "white"
                                    : "",
                                fontFamily: "mesheddisplay-regular",
                              }}
                            >
                              {" "}
                              Meshed Display-Regular
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="mesheddisplay-bold"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "mesheddisplay-bold"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "mesheddisplay-bold"
                                    ? "white"
                                    : "",
                                fontFamily: "mesheddisplay-bold",
                              }}
                            >
                              {" "}
                              Meshed Display-Bold
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="nymeria-regular"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "nymeria-regular"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "nymeria-regular"
                                    ? "white"
                                    : "",
                                fontFamily: "nymeria-regular",
                              }}
                            >
                              {" "}
                              Nymeria-Regular
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="nymeria-bold"
                              style={{
                                background:
                                  eachBanner.style.font_family == "nymeria-bold"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family == "nymeria-bold"
                                    ? "white"
                                    : "",
                                fontFamily: "nymeria-bold",
                              }}
                            >
                              {" "}
                              Nymeria-Bold
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="Palatino"
                              style={{
                                background:
                                  eachBanner.style.font_family == "Palatino"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family == "Palatino"
                                    ? "white"
                                    : "",
                                fontFamily: "Palatino",
                              }}
                            >
                              {" "}
                              Palatino
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="piazzolla-regular"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "piazzolla-regular"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "piazzolla-regular"
                                    ? "white"
                                    : "",
                                fontFamily: "piazzolla-regular",
                              }}
                            >
                              {" "}
                              Piazzolla-Regular
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="piazzolla-bold"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "piazzolla-bold"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "piazzolla-bold"
                                    ? "white"
                                    : "",
                                fontFamily: "piazzolla-bold",
                              }}
                            >
                              {" "}
                              Piazzolla-Bold
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="Poppins-SemiBold"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "Poppins-SemiBold"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "Poppins-SemiBold"
                                    ? "white"
                                    : "",
                                fontFamily: "Poppins-SemiBold",
                              }}
                            >
                              {" "}
                              Poppins SemiBold
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="radiocanada-regular"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "radiocanada-regular"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "radiocanada-regular"
                                    ? "white"
                                    : "",
                                fontFamily: "radiocanada-regular",
                              }}
                            >
                              {" "}
                              Radio Canada-Regular
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="radiocanada-bold"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "radiocanada-bold"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "radiocanada-bold"
                                    ? "white"
                                    : "",
                                fontFamily: "radiocanada-bold",
                              }}
                            >
                              {" "}
                              Radio Canada-Bold
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="rebeqa-regular"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "rebeqa-regular"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "rebeqa-regular"
                                    ? "white"
                                    : "",
                                fontFamily: "rebeqa-regular",
                              }}
                            >
                              {" "}
                              Rebeqa Font-Regular
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="rebeqa-bold"
                              style={{
                                background:
                                  eachBanner.style.font_family == "rebeqa-bold"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family == "rebeqa-bold"
                                    ? "white"
                                    : "",
                                fontFamily: "rebeqa-bold",
                              }}
                            >
                              {" "}
                              Rebeqa Font-Bold
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="rogerex-regular"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "rogerex-regular"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "rogerex-regular"
                                    ? "white"
                                    : "",
                                fontFamily: "rogerex-regular",
                              }}
                            >
                              {" "}
                              Rogerex-Regular
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="rogerex-bold"
                              style={{
                                background:
                                  eachBanner.style.font_family == "rogerex-bold"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family == "rogerex-bold"
                                    ? "white"
                                    : "",
                                fontFamily: "rogerex-bold",
                              }}
                            >
                              {" "}
                              Rogerex-Bold
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="RozinaV04Regular"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "RozinaV04Regular"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "RozinaV04Regular"
                                    ? "white"
                                    : "",
                                fontFamily: "RozinaV04Regular",
                              }}
                            >
                              {" "}
                              Rozina-Regular
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="RozinaV06Bold"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "RozinaV06Bold"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "RozinaV06Bold"
                                    ? "white"
                                    : "",
                                fontFamily: "RozinaV06Bold",
                              }}
                            >
                              {" "}
                              Rozina-Bold
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="Sans-Serif"
                              style={{
                                background:
                                  eachBanner.style.font_family == "Sans-Serif"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family == "Sans-Serif"
                                    ? "white"
                                    : "",
                                fontFamily: "Sans-Serif",
                              }}
                            >
                              {" "}
                              Sans Serif
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="TimesNewRomanPSMT"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "TimesNewRomanPSMT"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "TimesNewRomanPSMT"
                                    ? "white"
                                    : "",
                                fontFamily: "TimesNewRomanPSMT",
                              }}
                            >
                              {" "}
                              Times New Roman
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="Trebuchet-MS"
                              style={{
                                background:
                                  eachBanner.style.font_family == "Trebuchet-MS"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family == "Trebuchet-MS"
                                    ? "white"
                                    : "",
                                fontFamily: "Trebuchet-MS",
                              }}
                            >
                              {" "}
                              Trebuchet MS
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="unbounded-regular"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "unbounded-regular"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "unbounded-regular"
                                    ? "white"
                                    : "",
                                fontFamily: "unbounded-regular",
                              }}
                            >
                              {" "}
                              Unbounded Polkadot-Regular
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="unbounded-bold"
                              style={{
                                background:
                                  eachBanner.style.font_family ==
                                  "unbounded-bold"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family ==
                                  "unbounded-bold"
                                    ? "white"
                                    : "",
                                fontFamily: "unbounded-bold",
                              }}
                            >
                              {" "}
                              Unbounded Polkadot-Bold
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="Verdana"
                              style={{
                                background:
                                  eachBanner.style.font_family == "Verdana"
                                    ? "#547df2"
                                    : "",
                                color:
                                  eachBanner.style.font_family == "Verdana"
                                    ? "white"
                                    : "",
                                fontFamily: "Verdana",
                              }}
                            >
                              {" "}
                              Verdana
                            </Dropdown.Item>
                          </DropdownButton>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <select
                            className="mt-20"
                            value={eachBanner.style.font_size}
                            onChange={(e) =>
                              handleBannerFontSize(e, blockIndx, bannerIndx)
                            }
                          >
                            <option disabled selected>
                              Font size
                            </option>
                            {/* <option value={"14px"}>14px</option> */}
                            <option value={"15px"}>15</option>
                            <option value={"16px"}>16</option>
                            <option value={"17px"}>17</option>
                            <option value={"18px"}>18</option>
                            <option value={"19px"}>19</option>
                            <option value={"20px"}>20</option>
                            <option value={"21px"}>21</option>
                            <option value={"22px"}>22</option>
                            <option value={"23px"}>23</option>
                            <option value={"24px"}>24</option>
                            {/* <option value={"25px"}>25px</option>
                          <option value={"26px"}>26px</option>
                          <option value={"27px"}>27px</option>
                          <option value={"28px"}>28px</option>
                          <option value={"29px"}>29px</option>
                          <option value={"30px"}>30px</option> */}
                          </select>
                        </div>

                        <div className="col-md-6">
                          <div className="homescreen-color-input mt-20">
                            <div
                              className="align-items-center"
                              style={{
                                height: "100%",
                                cursor: "pointer",
                                display: "flex",
                              }}
                            >
                              {" "}
                              <NewReactcolorpicker
                                colorname={eachBanner.style.text_color}
                                onChange={(color) =>
                                  handleBannerTextColor(
                                    color,
                                    blockIndx,
                                    bannerIndx
                                  )
                                }
                                classselection={"fixback"}
                                valuecontrol={true}
                                presetColors={presetColors}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <FormGroup
                            tag="fieldset"
                            className="heading-options topalignment0"
                          >
                            <FormGroup>
                              <Label
                                className="heading-ratio"
                                htmlFor={`${bannerIndx}font0selection${blockIndx}`}
                              >
                                <Input
                                  type="checkbox"
                                  id={`${bannerIndx}font0selection${blockIndx}`}
                                  // name="checkbox1"
                                  value="bold"
                                  onChange={(e) =>
                                    handleFontFeatures(
                                      e,
                                      blockIndx,
                                      bannerIndx,
                                      "bold"
                                    )
                                  }
                                  checked={
                                    eachBanner.style.is_bold === 1
                                      ? true
                                      : false
                                  }
                                />
                                <div className="image">
                                  <svg
                                    width="12.473"
                                    height="16.034"
                                    viewBox="0 0 12.473 16.034"
                                  >
                                    <path
                                      id="iconfinder_format-bold_3671764"
                                      d="M3,17.034V1h7.126a4.454,4.454,0,0,1,3.456,7.269,4.9,4.9,0,0,1-3.011,8.765ZM9.681,9.908H6.563v4.454H9.681a2.227,2.227,0,1,0,0-4.454ZM6.563,3.672V7.236H9.236a1.782,1.782,0,1,0,0-3.563Z"
                                      transform="translate(-3 -1)"
                                      fill=""
                                    />
                                  </svg>
                                </div>
                              </Label>
                            </FormGroup>
                            <FormGroup>
                              <Label
                                className="heading-ratio"
                                htmlFor={`${bannerIndx}font1selection${blockIndx}`}
                              >
                                <Input
                                  type="checkbox"
                                  id={`${bannerIndx}font1selection${blockIndx}`}
                                  // name="checkbox1"
                                  value="italic"
                                  onChange={(e) =>
                                    handleFontFeatures(
                                      e,
                                      blockIndx,
                                      bannerIndx,
                                      "italic"
                                    )
                                  }
                                  checked={
                                    eachBanner.style.is_italic === 1
                                      ? true
                                      : false
                                  }
                                />
                                <div className="image">
                                  <svg
                                    width="13.483"
                                    height="16.034"
                                    viewBox="0 0 13.483 16.034"
                                  >
                                    <path
                                      id="iconfinder_format_text_italic_5879738"
                                      d="M67.483,40H56.733v2.186h3.843L57.263,53.848H54v2.186H64.75V53.848H60.907L64.22,42.186h3.263Z"
                                      transform="translate(-54 -40)"
                                      fill=""
                                    />
                                  </svg>
                                </div>
                              </Label>
                            </FormGroup>
                            <FormGroup>
                              <Label
                                className="heading-ratio"
                                htmlFor={`${bannerIndx}font2selection${blockIndx}`}
                              >
                                <Input
                                  type="checkbox"
                                  id={`${bannerIndx}font2selection${blockIndx}`}
                                  // name="checkbox1"

                                  value="underline"
                                  onChange={(e) =>
                                    handleFontFeatures(
                                      e,
                                      blockIndx,
                                      bannerIndx,
                                      "underline"
                                    )
                                  }
                                  checked={
                                    eachBanner.style.is_underline === 1
                                      ? true
                                      : false
                                  }
                                />
                                <div className="image">
                                  <svg
                                    width="14.253"
                                    height="16.034"
                                    viewBox="0 0 14.253 16.034"
                                  >
                                    <path
                                      id="iconfinder_format-underline_3671767"
                                      d="M14.471,8.126a5.345,5.345,0,0,1-10.689,0V1H6.454V8.126a2.672,2.672,0,0,0,5.345,0V1h2.672ZM2,15.253H16.253v1.782H2Z"
                                      transform="translate(-2 -1)"
                                      fill=""
                                    />
                                  </svg>
                                </div>
                              </Label>
                            </FormGroup>
                            <FormGroup>
                              <Label
                                className="heading-ratio"
                                htmlFor={`${bannerIndx}alignment0Radio${blockIndx}`}
                              >
                                <Input
                                  type="radio"
                                  // name="radio3"
                                  id={`${bannerIndx}alignment0Radio${blockIndx}`}
                                  value="left"
                                  checked={
                                    eachBanner.style.text_align === "left"
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    handleBannerTextAlignment(
                                      e,
                                      blockIndx,
                                      bannerIndx
                                    )
                                  }
                                />
                                <div className="image">
                                  <svg
                                    width="16.004"
                                    height="15.046"
                                    viewBox="0 0 16.004 15.046"
                                  >
                                    <g
                                      id="Group_26426"
                                      data-name="Group 26426"
                                      transform="translate(0.002 0.75)"
                                    >
                                      <path
                                        id="Path_6061"
                                        data-name="Path 6061"
                                        d="M-14517.724-3751.961h16"
                                        transform="translate(14517.726 3751.961)"
                                        fill="none"
                                        stroke=""
                                        stroke-width="1.5"
                                      />
                                      <path
                                        id="Path_9224"
                                        data-name="Path 9224"
                                        d="M-14517.729-3751.961h12"
                                        transform="translate(14517.727 3756.233)"
                                        fill="none"
                                        stroke=""
                                        stroke-width="1.5"
                                      />
                                      <path
                                        id="Path_9225"
                                        data-name="Path 9225"
                                        d="M-14517.724-3751.961h16"
                                        transform="translate(14517.726 3760.991)"
                                        fill="none"
                                        stroke=""
                                        stroke-width="1.5"
                                      />
                                      <path
                                        id="Path_9226"
                                        data-name="Path 9226"
                                        d="M-14517.729-3751.961h12"
                                        transform="translate(14517.727 3765.507)"
                                        fill="none"
                                        stroke=""
                                        stroke-width="1.5"
                                      />
                                    </g>
                                  </svg>
                                </div>
                              </Label>
                            </FormGroup>
                            <FormGroup>
                              <Label
                                className="heading-ratio"
                                htmlFor={`${bannerIndx}alignment1Radio${blockIndx}`}
                              >
                                <Input
                                  type="radio"
                                  // name="radio3"
                                  id={`${bannerIndx}alignment1Radio${blockIndx}`}
                                  value="center"
                                  checked={
                                    eachBanner.style.text_align === "center"
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    handleBannerTextAlignment(
                                      e,
                                      blockIndx,
                                      bannerIndx
                                    )
                                  }
                                />
                                <div className="image">
                                  <svg
                                    width="16"
                                    height="15.046"
                                    viewBox="0 0 16 15.046"
                                  >
                                    <g
                                      id="Group_26428"
                                      data-name="Group 26428"
                                      transform="translate(0.498 0.75)"
                                    >
                                      <path
                                        id="Path_6061"
                                        data-name="Path 6061"
                                        d="M-14517.724-3751.961h16"
                                        transform="translate(14517.226 3751.961)"
                                        fill="none"
                                        stroke=""
                                        stroke-width="1.5"
                                      />
                                      <path
                                        id="Path_9224"
                                        data-name="Path 9224"
                                        d="M-14517.729-3751.961h12"
                                        transform="translate(14519.23 3756.233)"
                                        fill="none"
                                        stroke=""
                                        stroke-width="1.5"
                                      />
                                      <path
                                        id="Path_9225"
                                        data-name="Path 9225"
                                        d="M-14517.724-3751.961h16"
                                        transform="translate(14517.226 3760.991)"
                                        fill="none"
                                        stroke=""
                                        stroke-width="1.5"
                                      />
                                      <path
                                        id="Path_9226"
                                        data-name="Path 9226"
                                        d="M-14517.729-3751.961h12"
                                        transform="translate(14519.23 3765.507)"
                                        fill="none"
                                        stroke=""
                                        stroke-width="1.5"
                                      />
                                    </g>
                                  </svg>
                                </div>
                              </Label>
                            </FormGroup>
                            <FormGroup>
                              <Label
                                className="heading-ratio"
                                htmlFor={`${bannerIndx}alignment2Radio${blockIndx}`}
                              >
                                <Input
                                  type="radio"
                                  // name="radio3"
                                  id={`${bannerIndx}alignment2Radio${blockIndx}`}
                                  value="right"
                                  checked={
                                    eachBanner.style.text_align === "right"
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    handleBannerTextAlignment(
                                      e,
                                      blockIndx,
                                      bannerIndx
                                    )
                                  }
                                />
                                <div className="image">
                                  <svg
                                    width="16.004"
                                    height="15.045"
                                    viewBox="0 0 16.004 15.045"
                                  >
                                    <g
                                      id="Group_26427"
                                      data-name="Group 26427"
                                      transform="translate(0 0.749)"
                                    >
                                      <path
                                        id="Path_6061"
                                        data-name="Path 6061"
                                        d="M-14501.724-3751.961h-16"
                                        transform="translate(14517.724 3751.962)"
                                        fill="none"
                                        stroke=""
                                        stroke-width="1.5"
                                      />
                                      <path
                                        id="Path_9224"
                                        data-name="Path 9224"
                                        d="M-14505.729-3751.961h-12"
                                        transform="translate(14521.732 3756.233)"
                                        fill="none"
                                        stroke=""
                                        stroke-width="1.5"
                                      />
                                      <path
                                        id="Path_9225"
                                        data-name="Path 9225"
                                        d="M-14501.724-3751.961h-16"
                                        transform="translate(14517.724 3760.992)"
                                        fill="none"
                                        stroke=""
                                        stroke-width="1.5"
                                      />
                                      <path
                                        id="Path_9226"
                                        data-name="Path 9226"
                                        d="M-14505.729-3751.961h-12"
                                        transform="translate(14521.732 3765.507)"
                                        fill="none"
                                        stroke=""
                                        stroke-width="1.5"
                                      />
                                    </g>
                                  </svg>
                                </div>
                              </Label>
                            </FormGroup>
                          </FormGroup>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <FormGroup
                            tag="fieldset"
                            className="heading-options bottomalignment0 homescreen-allignment"
                          >
                            <FormGroup>
                              <Label
                                className="heading-ratio"
                                htmlFor={`${bannerIndx}position0Radio${blockIndx}`}
                              >
                                <Input
                                  type="radio"
                                  // name="radio4"
                                  id={`${bannerIndx}position0Radio${blockIndx}`}
                                  value="bottom"
                                  checked={
                                    eachBanner.style.vertical_text_position ===
                                    "bottom"
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    handleBannerVerticalTextPosition(
                                      e,
                                      blockIndx,
                                      bannerIndx
                                    )
                                  }
                                />
                                <div className="image">
                                  <svg
                                    width="18"
                                    height="15.711"
                                    viewBox="0 0 18 15.711"
                                  >
                                    <g
                                      id="Group_26896"
                                      data-name="Group 26896"
                                      transform="translate(0 0.75)"
                                    >
                                      <rect
                                        id="Rectangle_1490"
                                        data-name="Rectangle 1490"
                                        width="5"
                                        height="12"
                                        transform="translate(10.001 2.961)"
                                        fill=""
                                      />
                                      <rect
                                        id="Rectangle_1491"
                                        data-name="Rectangle 1491"
                                        width="5"
                                        height="6"
                                        transform="translate(3.001 2.961)"
                                        fill=""
                                      />
                                      <path
                                        id="Path_6061"
                                        data-name="Path 6061"
                                        d="M-14499.723-3751.961h-18"
                                        transform="translate(14517.723 3751.961)"
                                        fill="none"
                                        stroke=""
                                        stroke-width="1.5"
                                      />
                                    </g>
                                  </svg>
                                </div>
                              </Label>
                            </FormGroup>

                            <FormGroup>
                              <Label
                                className="heading-ratio"
                                htmlFor={`${bannerIndx}position1Radio${blockIndx}`}
                              >
                                <Input
                                  type="radio"
                                  // name="radio4"
                                  id={`${bannerIndx}position1Radio${blockIndx}`}
                                  value="center"
                                  checked={
                                    eachBanner.style.vertical_text_position ===
                                    "center"
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    handleBannerVerticalTextPosition(
                                      e,
                                      blockIndx,
                                      bannerIndx
                                    )
                                  }
                                />
                                <div className="image">
                                  <svg
                                    width="18"
                                    height="12"
                                    viewBox="0 0 18 12"
                                  >
                                    <g
                                      id="Group_26891"
                                      data-name="Group 26891"
                                      transform="translate(0.999 -1)"
                                    >
                                      <rect
                                        id="Rectangle_1490"
                                        data-name="Rectangle 1490"
                                        width="5"
                                        height="12"
                                        transform="translate(2 1)"
                                        fill=""
                                      />
                                      <rect
                                        id="Rectangle_1491"
                                        data-name="Rectangle 1491"
                                        width="5"
                                        height="6"
                                        transform="translate(9 4)"
                                        fill=""
                                      />
                                      <path
                                        id="Path_6061"
                                        data-name="Path 6061"
                                        d="M-14517.723-3751.961h18"
                                        transform="translate(14516.724 3758.922)"
                                        fill="none"
                                        stroke=""
                                        stroke-width="1.5"
                                      />
                                    </g>
                                  </svg>
                                </div>
                              </Label>
                            </FormGroup>

                            <FormGroup>
                              <Label
                                className="heading-ratio"
                                htmlFor={`${bannerIndx}position2Radio${blockIndx}`}
                              >
                                <Input
                                  type="radio"
                                  // name="radio4"
                                  id={`${bannerIndx}position2Radio${blockIndx}`}
                                  value="top"
                                  checked={
                                    eachBanner.style.vertical_text_position ===
                                    "top"
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    handleBannerVerticalTextPosition(
                                      e,
                                      blockIndx,
                                      bannerIndx
                                    )
                                  }
                                />
                                <div className="image">
                                  <svg
                                    id="Group_3465"
                                    data-name="Group 3465"
                                    width="18"
                                    height="15.711"
                                    viewBox="0 0 18 15.711"
                                  >
                                    <rect
                                      id="Rectangle_1490"
                                      data-name="Rectangle 1490"
                                      width="5"
                                      height="12"
                                      transform="translate(10.001 0)"
                                      fill=""
                                    />
                                    <rect
                                      id="Rectangle_1491"
                                      data-name="Rectangle 1491"
                                      width="5"
                                      height="6"
                                      transform="translate(3.001 6)"
                                      fill=""
                                    />
                                    <path
                                      id="Path_6061"
                                      data-name="Path 6061"
                                      d="M-14499.723-3751.961h-18"
                                      transform="translate(14517.723 3766.922)"
                                      fill="none"
                                      stroke=""
                                      stroke-width="1.5"
                                    />
                                  </svg>
                                </div>
                              </Label>
                            </FormGroup>

                            <FormGroup>
                              <Label
                                className="heading-ratio"
                                htmlFor={`${bannerIndx}2position0Radio${blockIndx}`}
                              >
                                <Input
                                  type="radio"
                                  // name="radio4"
                                  id={`${bannerIndx}2position0Radio${blockIndx}`}
                                  value="left"
                                  checked={
                                    eachBanner.style
                                      .horizontal_text_position === "left"
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    handleBannerHorizontalTextPosition(
                                      e,
                                      blockIndx,
                                      bannerIndx
                                    )
                                  }
                                />
                                <div className="image">
                                  <svg
                                    width="15.711"
                                    height="18"
                                    viewBox="0 0 15.711 18"
                                  >
                                    <g
                                      id="Group_26897"
                                      data-name="Group 26897"
                                      transform="translate(15.711) rotate(90)"
                                    >
                                      <rect
                                        id="Rectangle_1490"
                                        data-name="Rectangle 1490"
                                        width="5"
                                        height="12"
                                        transform="translate(2.999 0)"
                                        fill=""
                                      />
                                      <rect
                                        id="Rectangle_1491"
                                        data-name="Rectangle 1491"
                                        width="5"
                                        height="6"
                                        transform="translate(9.999 6)"
                                        fill=""
                                      />
                                      <path
                                        id="Path_6061"
                                        data-name="Path 6061"
                                        d="M0,0H18"
                                        transform="translate(0 14.961)"
                                        fill="none"
                                        stroke=""
                                        stroke-width="1.5"
                                      />
                                    </g>
                                  </svg>
                                </div>
                              </Label>
                            </FormGroup>
                            <FormGroup>
                              <Label
                                className="heading-ratio"
                                htmlFor={`${bannerIndx}2position1Radio${blockIndx}`}
                              >
                                <Input
                                  type="radio"
                                  // name="radio4"
                                  id={`${bannerIndx}2position1Radio${blockIndx}`}
                                  value="center"
                                  checked={
                                    eachBanner.style
                                      .horizontal_text_position === "center"
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    handleBannerHorizontalTextPosition(
                                      e,
                                      blockIndx,
                                      bannerIndx
                                    )
                                  }
                                />
                                <div className="image">
                                  <svg
                                    width="12"
                                    height="18"
                                    viewBox="0 0 12 18"
                                  >
                                    <g
                                      id="Group_26899"
                                      data-name="Group 26899"
                                      transform="translate(14.961) rotate(90)"
                                    >
                                      <rect
                                        id="Rectangle_1490"
                                        data-name="Rectangle 1490"
                                        width="5"
                                        height="12"
                                        transform="translate(2.999 2.961)"
                                        fill=""
                                      />
                                      <rect
                                        id="Rectangle_1491"
                                        data-name="Rectangle 1491"
                                        width="5"
                                        height="6"
                                        transform="translate(9.999 5.961)"
                                        fill=""
                                      />
                                      <path
                                        id="Path_6061"
                                        data-name="Path 6061"
                                        d="M-14517.723-3751.961h18"
                                        transform="translate(14517.723 3760.961)"
                                        fill="none"
                                        stroke=""
                                        stroke-width="1.5"
                                      />
                                    </g>
                                  </svg>
                                </div>
                              </Label>
                            </FormGroup>
                            <FormGroup>
                              <Label
                                className="heading-ratio"
                                htmlFor={`${bannerIndx}2position2Radio${blockIndx}`}
                              >
                                <Input
                                  type="radio"
                                  // name="radio4"
                                  id={`${bannerIndx}2position2Radio${blockIndx}`}
                                  value="right"
                                  checked={
                                    eachBanner.style
                                      .horizontal_text_position === "right"
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    handleBannerHorizontalTextPosition(
                                      e,
                                      blockIndx,
                                      bannerIndx
                                    )
                                  }
                                />
                                <div className="image">
                                  <svg
                                    width="15.711"
                                    height="18"
                                    viewBox="0 0 15.711 18"
                                  >
                                    <g
                                      id="Group_26898"
                                      data-name="Group 26898"
                                      transform="translate(14.961) rotate(90)"
                                    >
                                      <rect
                                        id="Rectangle_1490"
                                        data-name="Rectangle 1490"
                                        width="5"
                                        height="12"
                                        transform="translate(2.999 2.961)"
                                        fill=""
                                      />
                                      <rect
                                        id="Rectangle_1491"
                                        data-name="Rectangle 1491"
                                        width="5"
                                        height="6"
                                        transform="translate(9.999 2.961)"
                                        fill=""
                                      />
                                      <path
                                        id="Path_6061"
                                        data-name="Path 6061"
                                        d="M-14517.723-3751.961h18"
                                        transform="translate(14517.723 3751.961)"
                                        fill="none"
                                        stroke=""
                                        stroke-width="1.5"
                                      />
                                    </g>
                                  </svg>
                                </div>
                              </Label>
                            </FormGroup>
                          </FormGroup>
                        </div>
                      </div>

                      <div className="row mt-20">
                        <div className="col-md-12 ">
                          <h6>Click to open</h6>
                        </div>
                        <div className="col-md-6">
                          <select
                            value={eachBanner.hyperlink_option}
                            onChange={(e) =>
                              handleTitleChange(e, blockIndx, bannerIndx)
                            }
                          >
                            <option value="page">Page</option>
                            <option value="post">Post</option>
                            <option value="url">URL</option>
                          </select>
                        </div>

                        {eachBanner.hyperlink_option === "page" && (
                          <div className="col-md-6">
                            <div
                              className="rel"
                              onClick={(e) => handlePagePopUp(e, "add")}
                            >
                              <input
                                style={{ cursor: "pointer" }}
                                type="text"
                                placeholder="Select Page"
                                value={
                                  // parseInt(
                                  //   localStorage.getItem("website_technology")
                                  // ) === 3
                                  //   ? handleFilterNameForChild(
                                  //       pagesData,
                                  //       eachBanner.hyperlink_value,
                                  //       "parent",
                                  //       ""
                                  //     )
                                  //   : handleFilterName(
                                  //       pagesData,
                                  //       eachBanner.hyperlink_value
                                  //     )
                                  eachBanner.hyperlink_alias
                                }
                                readOnly
                              />
                              <Link className="select-opt">
                                <img
                                  src={
                                    require("../../../assets/images/dashboard/selectmenu.png")
                                      .default
                                  }
                                  alt="option"
                                />
                              </Link>
                            </div>
                            {/* <select
                            value={
                              eachBanner
                                .hyperlink_value
                            }
                            onChange={(e) =>
                              handleValueChange(e, blockIndx, bannerIndx)
                            }
                          >
                            {pagesData &&
                              pagesData.map((eachPage) => {
                                return (
                                  <option
                                    value={
                                      parseInt(
                                        localStorage.getItem(
                                          "website_technology"
                                        )
                                      ) === 3
                                        ? eachPage._id
                                        : eachPage.id
                                    }
                                  >
                                    {parseInt(
                                      localStorage.getItem("website_technology")
                                    ) === 3
                                      ? eachPage.title
                                      : eachPage.title.rendered}
                                  </option>
                                );
                              })}
                            
                          </select> */}
                          </div>
                        )}
                        {eachBanner.hyperlink_option === "post" && (
                          <div className="col-md-6">
                            <div
                              className="rel"
                              onClick={(e) => handlePostPopUp(e, "add")}
                            >
                              <input
                                style={{ cursor: "pointer" }}
                                type="text"
                                placeholder="Select Post"
                                value={
                                  //   handleFilterName(
                                  //   postData,
                                  //   eachBanner.hyperlink_value
                                  // )
                                  eachBanner.hyperlink_alias
                                }
                                readOnly
                              />
                              <Link className="select-opt">
                                <img
                                  src={
                                    require("../../../assets/images/dashboard/selectmenu.png")
                                      .default
                                  }
                                  alt="option"
                                />
                              </Link>
                            </div>
                          </div>
                        )}

                        {eachBanner.hyperlink_option === "url" && (
                          <div className="col-md-6">
                            <Input
                              placeholder="Enter URL"
                              onChange={(e) =>
                                handleValueChange(e, blockIndx, bannerIndx)
                              }
                              value={eachBanner.hyperlink_value}
                            />
                          </div>
                        )}
                      </div>
                    </TabPane>
                  );
                }
              )}
          </TabContent>
          <div className="homescreen-delete-option">
            <p onClick={(e) => handleDuplicateItem(e, eachBlock)}>
              <img
                src={
                  require("../../../assets/images/dashboard/cms/duplicate.png")
                    .default
                }
              />
              Duplicate
            </p>
            <p onClick={(e) => handleDeleteItem(e, blockIndx)}>
              <img
                src={
                  require("../../../assets/images/dashboard/cms/delete.png")
                    .default
                }
              />
              Delete
            </p>
          </div>
        </div>
      )}

      <div className="">
        {isBanner === true && blockIndx !== null && (
          <Modal
            isOpen={isBanner}
            toggle={handleBannerPopUp}
            // className={this.props.className}
            className="bannerlayoutoptions modal-dialog-centered"
          >
            <ModalHeader toggle={handleBannerPopUp}>Banner Layout</ModalHeader>
            <ModalBody>
              <FormGroup tag="fieldset" className="select-banner-layout">
                <FormGroup>
                  <Label
                    className="selectbanner-ratio"
                    htmlFor={`${blockIndx}layout0`}
                  >
                    <Input
                      id={`${blockIndx}layout0`}
                      type="radio"
                      // name="selectbanner"
                      // value="4:3"
                      checked={
                        homescreenData[blockIndx].style.layout
                          .image_aspect_ratio === "4:3"
                      }
                      onChange={(e) => handleBannerLayout(e, "4:3")}
                    />
                    <div className="image">
                      <div className="d-block text-center">
                        <img
                          className="img-fluid"
                          src={
                            require("../../../assets/images/dashboard/bannerlayout1.png")
                              .default
                          }
                        />
                      </div>
                    </div>
                  </Label>
                </FormGroup>
                <FormGroup>
                  <Label
                    className="selectbanner-ratio"
                    htmlFor={`${blockIndx}layout1`}
                  >
                    <Input
                      id={`${blockIndx}layout1`}
                      type="radio"
                      // name="selectbanner"
                      value="3:4"
                      checked={
                        homescreenData[blockIndx].style.layout
                          .image_aspect_ratio === "3:4"
                      }
                      onChange={(e) => handleBannerLayout(e, "3:4")}
                    />
                    <div className="image">
                      <div className="d-block text-center">
                        <img
                          className="img-fluid"
                          src={
                            require("../../../assets/images/dashboard/bannerlayout2.png")
                              .default
                          }
                        />
                      </div>
                    </div>
                  </Label>
                </FormGroup>
                <FormGroup>
                  <Label
                    className="selectbanner-ratio"
                    htmlFor={`${blockIndx}layout2`}
                  >
                    <Input
                      id={`${blockIndx}layout2`}
                      type="radio"
                      // name="selectbanner"
                      value="1:1"
                      checked={
                        homescreenData[blockIndx].style.layout
                          .image_aspect_ratio === "1:1"
                      }
                      onChange={(e) => handleBannerLayout(e, "1:1")}
                    />
                    <div className="image">
                      <div className="d-block text-center">
                        <img
                          className="img-fluid"
                          src={
                            require("../../../assets/images/dashboard/bannerlayout3.png")
                              .default
                          }
                        />
                      </div>
                    </div>
                  </Label>
                </FormGroup>

                <FormGroup>
                  <Label
                    className="selectbanner-ratio"
                    htmlFor={`${blockIndx}layout4`}
                  >
                    <Input
                      id={`${blockIndx}layout4`}
                      type="radio"
                      // name="selectbanner"
                      value="16:9"
                      checked={
                        homescreenData[blockIndx].style.layout
                          .image_aspect_ratio === "16:9"
                      }
                      onChange={(e) => handleBannerLayout(e, "16:9")}
                    />
                    <div className="image">
                      <div className="d-block text-center">
                        <img
                          className="img-fluid"
                          src={
                            require("../../../assets/images/dashboard/bannerlayout5.png")
                              .default
                          }
                        />
                      </div>
                    </div>
                  </Label>
                </FormGroup>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button onClick={handleBannerPopUp}>Cancel</Button>{" "}
              <Button onClick={(e) => handleBannerPopUp(e, "add")}>DONE</Button>
            </ModalFooter>
          </Modal>
        )}
      </div>

      <NewImageGalleryPopup
        isGalleryPopup={isGalleryPopup}
        setIsGalleryPopup={setIsGalleryPopup}
        settingYourdata={settingYourdata}
        yourErrFun={yourErrFun}
        uploadPath={
          localStorage.getItem("user_id") +
          "/" +
          localStorage.getItem("Appid") +
          process.env.REACT_APP_MAIN_BANNER_IMG
        }
        settingOrgImgFrom3rdParty={settingOrgImgFrom3rdParty}
        hideImageGallery={false}
        widthForRestriction={1125}
        heightForRestriction={480}
        aspectRatio={homescreenData[blockIndx].style.layout.image_aspect_ratio}
      />
      {isPagePopUp === true &&
        parseInt(localStorage.getItem("website_technology")) !== 3 &&
        pageAndPostDataPopUp(
          isPagePopUp,
          handlePagePopUp,
          pagesData,
          handlePopUpVal,
          homescreenData[blockIndx].value.main_banner[
            homescreenData[blockIndx].homescreen_selected_image_index
          ].hyperlink_value,
          "page"
          // isPopDisableBtn
        )}
      {isPagePopUp === true &&
        parseInt(localStorage.getItem("website_technology")) === 3 &&
        pageSelectionForCMS(
          isPagePopUp,
          handlePagePopUp,
          pagesData,
          handlePopUpVal,
          homescreenData[blockIndx].value.main_banner[
            homescreenData[blockIndx].homescreen_selected_image_index
          ].hyperlink_value,
          "pageID",
          "Page"
        )}
      {isPostPopUp === true &&
        pageAndPostDataPopUp(
          isPostPopUp,
          handlePostPopUp,
          postData,
          handlePopUpVal,
          homescreenData[blockIndx].value.main_banner[
            homescreenData[blockIndx].homescreen_selected_image_index
          ].hyperlink_value,
          "post"
          // isPopDisableBtn
        )}
    </div>
  );
};

export default HMSmainBanner;
