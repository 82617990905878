import React, { useEffect, useState } from "react";
import "../../Components/commonComponents/AppSettings/appsettingModal.css";
import { FONTS } from "./fonts";
import FontSelectionModal from "./FontSelectionModal";
import Noresult from "../../assets/images/dashboard/no-media.png";

const FontSelectionDropdown = ({
  appFontFamily,
  setAppFontFamily,
  setOpenFontDropdown,
  openFontDropdown,
  wrapperRef

}) => {
  const [searchValue, setSearchValue] = useState("");
  const [openFontModal, setOpenFontModal] = useState(false);
  
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setOpenFontDropdown(false)
      }
      else{
        setOpenFontModal(false)
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div>
      <div
        className="form-group theme-select-fonts align-items-center"
        ref={wrapperRef}
      >
        <div className="theme-setting-phonecode">
          {openFontDropdown && (
            <div className="dropdown-selectflag">
              <div className=" dropdown-selectflagtop">
                <input
                  className="dropdown-select-searchfleginput"
                  placeholder="Search..."
                  value={searchValue}
                  onBlur={() => setOpenFontDropdown(false)}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                {searchValue.trim() && (
                  <p
                    onClick={(e) => {
                      setSearchValue("");
                    }}
                    className="clear-search"
                  >
                    Clear
                  </p>
                )}
              </div>
              <div className="common-countrydiv">
                <div className=" common-countryflagtype">
                  {FONTS.filter((res) =>
                    res.name.toLowerCase().includes(searchValue.toLowerCase())
                  ).length === 0 ? (
                  <div className="nofont-found">
                    <img
                    src={Noresult} alt="Noresult"/>

                    <p className="noresult-text">No results found</p>
                    </div>
                  ) : (
                    FONTS.filter((res) =>
                      res.name.toLowerCase().includes(searchValue.toLowerCase())
                    ).map((res, i) => (
                      <div
                        className="country-line-flag-name-div"
                        key={i}
                        onClick={(e) => {
                          setAppFontFamily(res.fontFamily);
                          setOpenFontDropdown(false);
                        }}
                      >
                        <div className="country-flag-and-name">
                          <p className="country-name" style={{fontFamily:res.fontFamily}}>{res.name}</p>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          )}
          <div
            className="app-setting-flag-div"
            onClick={(e) =>
              window.matchMedia("(max-width: 1200px)").matches
                ? setOpenFontModal(true)
                : setOpenFontDropdown(!openFontDropdown)
            }
          >
            {appFontFamily}
          </div>
        </div>
      </div>
      <FontSelectionModal
        appFontFamily={appFontFamily}
        setAppFontFamily={setAppFontFamily}
        openFontModal={openFontModal}
        setOpenFontModal={setOpenFontModal}
      />
   
    </div>
  );
};

export default FontSelectionDropdown;
