import React from "react";

const GridlinesTooltip = ({ text }) => {
  return (
    <div className="tooltip-view">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
      >
        <g
          id="Group_35012"
          data-name="Group 35012"
          transform="translate(-1726.404 -18.404)"
        >
          <g
            id="Ellipse_7706"
            data-name="Ellipse 7706"
            transform="translate(1726.404 18.404)"
            fill="none"
            stroke="#bcc1ce"
            stroke-width="1.5"
          >
            <circle cx="8" cy="8" r="8" stroke="none" />
            <circle cx="8" cy="8" r="7.25" fill="none" />
          </g>
          <line
            id="Line_343"
            data-name="Line 343"
            y1="2"
            transform="translate(1734.404 22.405)"
            fill="none"
            stroke="#bcc1ce"
            stroke-miterlimit="10"
            stroke-width="1.5"
          />
          <path
            id="Path_98224"
            data-name="Path 98224"
            d="M0,1V-4"
            transform="translate(1734.404 29.405)"
            fill="none"
            stroke="#bcc1ce"
            stroke-width="1.5"
          />
        </g>
      </svg>

      <span className="grid-line-show-tooltip">{text}</span>
    </div>
  );
};

export default GridlinesTooltip;
