import CodeMirror from "@uiw/react-codemirror";
import moment from "moment";
import React, { useEffect, useReducer } from "react";

import LockedToggle from "../../../Components/commonComponents/LockedToggle";
import Spinner from "../../../Components/commonComponents/Spinner";
import CacheDaysDropdown from "../../../Components/commonComponents/WebView/CacheDaysDropdown.js";
import CacheDurationModal from "../../../Components/commonComponents/WebView/CacheDurationModal";
import CommonInput from "../../../Components/commonComponents/WebView/CommonInput";
import CommonToggle from "../../../Components/commonComponents/WebView/CommonToggle.js";
import LoaderDurationModal from "../../../Components/commonComponents/WebView/LoaderDurationModal";
import NativeDropdown from "../../../Components/commonComponents/WebView/NativeDropdown.js";
import TitleAlignmentInput from "../../../Components/commonComponents/WebView/TitleAlignmentInput.js";
import ModuleTitleTooltip from "../../../Components/commonIcons/ModuleTitleTooltip.js";
import Newfeatureicon from "../../../Components/commonIcons/Newfeatureicon.js";
import Tooltipicon from "../../../Components/commonIcons/Tooltip";
import Reactcolorpicker from "../../../Dashboard/Appearance/NewReactcolorpicker";
import "./Webview.css";
import CrossIcon from "../../../Components/commonIcons/CrossIcon";
import Header from "../../../Dashboard/Header";
import Leftpanel from "../../../Dashboard/Leftpanel";
import { errortoast, sucesstoast } from "../../../Dashboard/Toaster";
import {
  useGetWebViewDataQuery,
  useSaveWebViewDataMutation,
} from "../../../services/webViewApiSlice";
import { MetaTitle } from "../../../utils/commonUtils";
import WebViewPreview from "./WebViewPreview";

const WebView = () => {
  const [webViewState, setWebViewState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      bodyspinner: false,
      showOptionsShortcut: true,
      showBackAndForthArrows: true,
      showDomain: true,
      showAppheader: true,
      showWebsiteHeader: true,
      showWebsiteFooter: true,
      showWebsiteSidebar: true,
      hideHtmlElementByClass: "",
      hideHtmlElementById: "",
      enablePinchToZoomBool: false,
      rememberCookiesBool: false,
      showNativeLoader: false,
      nativeLoaderDuration: "default",
      openNativeDropdown: false,
      showWebsiteTitle: true,
      showCustomTitle: false,
      customTitle: "",
      titleAlignment: "center",
      backgroundColor:
        "linear-gradient(90deg, rgba(51, 199, 255, 1) 0%, rgba(72, 90, 254, 1) 50%, rgba(146, 65, 239, 1) 100%)",
      borderColor: "#74A4FF",
      iconColor: " #FFFFFF",
      textColor: "#FFFFFF",
      updatedAt: null,
      appRightPanelExpand:
        localStorage.getItem("appSideBarCollapse") === "true" ? true : false,
      openMobNativeDropdown: false,
      enablePullToRefresh: false,
      appendAppCodeVersionInUserAgent: false,
      enableOverrideCssFeature: false,
      cssToOverride: "",
      enableWebsiteCachePolicy: false,
      enableForcefulCacheWebpages: false,
      numberOfDaysToCache: 2,
      numberOfDaysToCacheFormat: "days",
      openDurationDropdown: false,
    }
  );
  const { data: webViewData, isLoading: isWebViewDataLoading } =
    useGetWebViewDataQuery({
      app_id: btoa(localStorage.getItem("Appid")),
    });

  const [saveWebViewData, { isLoading: isSaveWebViewDataLoading }] =
    useSaveWebViewDataMutation();

  const handlesubmit = () => {
    if (
      (webViewState.showCustomTitle || webViewState.showCustomTitle === 1) &&
      (webViewState.customTitle.trim() === "" || !webViewState.customTitle) &&
      webViewState.showAppheader
    ) {
      errortoast("Please provide custom title.");
    } else {
      const params = {
        app_id: Number(localStorage.getItem("Appid")),
        show_domain: Number(webViewState.showDomain),
        show_back_and_forth_arrows: Number(webViewState.showBackAndForthArrows),
        show_options_shortcut: Number(webViewState.showOptionsShortcut),
        show_app_header_bool: Number(webViewState.showAppheader),
        show_web_view_header_bool: Number(webViewState.showWebsiteHeader),
        show_web_view_footer_bool: Number(webViewState.showWebsiteFooter),
        show_web_view_sidebar_bool: Number(webViewState.showWebsiteSidebar),
        enable_pinch_to_zoom_bool: Number(webViewState.enablePinchToZoomBool),
        hide_html_element_by_class: webViewState.hideHtmlElementByClass || "",
        hide_html_element_by_id: webViewState.hideHtmlElementById || "",
        remember_cookies_bool: Number(webViewState.rememberCookiesBool),
        show_native_loader: Number(webViewState.showNativeLoader),
        show_website_title: Number(webViewState.showWebsiteTitle),
        show_custom_title: Number(webViewState.showCustomTitle),
        custom_title: webViewState.customTitle || "",
        title_alignment: webViewState.titleAlignment || "",
        background_color: webViewState.backgroundColor || "",
        border_color: webViewState.borderColor || "",
        icon_color: webViewState.iconColor || "",
        text_color: webViewState.textColor || "",
        native_loader_duration: webViewState.nativeLoaderDuration || "",
        enable_pull_to_refresh: Number(webViewState.enablePullToRefresh),
        append_app_code_version_in_user_agent: Number(
          webViewState.appendAppCodeVersionInUserAgent
        ),
        enable_override_css_feature: Number(
          webViewState.enableOverrideCssFeature
        ),
        css_to_override: webViewState.cssToOverride || "",
        enable_forceful_cache_webpages: Number(
          webViewState.enableForcefulCacheWebpages
        ),
        enable_website_cache_policy: Number(
          webViewState.enableWebsiteCachePolicy
        ),
        number_of_days_to_cache: Number(webViewState.numberOfDaysToCache),
        number_of_days_to_cache_format:
          webViewState.numberOfDaysToCacheFormat || "",
      };

      saveWebViewData(params)
        .unwrap()
        .then((res) => {
          if (res.code === 200) {
            setWebViewState({
              updatedAt: res.data.data.last_updated_timestamp
                ? res.data.data.last_updated_timestamp
                : null,
            });
           sucesstoast("The record has been updated.");
          }
        })
        .catch((error) => {
          errortoast("Oops! Something went wrong.");
        });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === " " && e.target.value.length === 0) {
      e.preventDefault();
    }
  };

  const handleStateChange = (type, value, additionalData = {}) => {
    switch (type) {
      case "hideHtmlElementByClass":
      case "hideHtmlElementById":
        setWebViewState({
          [type]: value.replace(/[ ,]+/g, ","),
        });
        break;

      case "checkbox":
        const { id, checked } = value;
        setWebViewState({
          [id]: checked,
        });
        break;

      case "showWebsiteTitle":
        setWebViewState({
          showWebsiteTitle: value,
          showCustomTitle: !value,
        });
        break;

      case "showCustomTitle":
        setWebViewState({
          showCustomTitle: value,
          showWebsiteTitle: !value,
        });
        break;

      case "nativeLoaderDuration":
      case "numberOfDaysToCacheFormat":
      case "backgroundColor":
      case "borderColor":
      case "iconColor":
      case "textColor":
      case "customTitle":
      case "titleAlignment":
      case "appRightPanelExpand":
      case "cssToOverride":
        setWebViewState({
          [type]: value,
          ...additionalData, // Use this to add any extra data (e.g., dropdown states)
        });
        break;

      case "toggle_open_native_dropdown":
        setWebViewState({
          openNativeDropdown: !webViewState.openNativeDropdown,
        });
        break;

      case "enableWebsiteCachePolicy":
        setWebViewState({
          enableWebsiteCachePolicy: value,
          enableForcefulCacheWebpages: false,
        });
        break;
      case "enableForcefulCacheWebpages":
        setWebViewState({
          enableWebsiteCachePolicy: false,
          enableForcefulCacheWebpages: value,
        });
        break;

      case "toggle_openMobNativeDropdown":
        setWebViewState({
          openMobNativeDropdown: !webViewState.openMobNativeDropdown,
        });
        break;

      case "toggle_openMobNativeDaysDropdown":
        if (!webViewState.enableForcefulCacheWebpages) return;
        setWebViewState({
          openMobNativeDaysDropdown: !webViewState.openMobNativeDaysDropdown,
        });
        break;

      case "toggle_override_css_feature":
        setWebViewState({
          enableOverrideCssFeature: !webViewState.enableOverrideCssFeature,
        });
        break;

      default:
        console.warn(`Unhandled type: ${type}`);
    }
  };

  const handleRightPanelExpand = (value) => {
    setWebViewState({
      appRightPanelExpand: value,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = MetaTitle("Web view settings");

    if (webViewData && webViewData.code === 200) {
      if (webViewData?.data) {
        setWebViewState({
          bodyspinner: false,
          showDomain: webViewData?.data.show_domain,
          showBackAndForthArrows: webViewData?.data.show_back_and_forth_arrows,
          showOptionsShortcut: webViewData?.data.show_options_shortcut,
          showAppheader: webViewData?.data.show_app_header_bool,
          showWebsiteHeader: webViewData?.data.show_web_view_header_bool,
          showWebsiteFooter: webViewData?.data.show_web_view_footer_bool,
          showWebsiteSidebar: webViewData?.data.show_web_view_sidebar_bool,
          hideHtmlElementByClass: webViewData?.data.hide_html_element_by_class,
          hideHtmlElementById: webViewData?.data.hide_html_element_by_id,
          enablePinchToZoomBool: webViewData?.data.enable_pinch_to_zoom_bool,
          rememberCookiesBool: webViewData?.data.remember_cookies_bool,
          showNativeLoader:
            webViewData?.data.show_native_loader === 1 ? true : false,

          // New Key
          showWebsiteTitle: webViewData?.data.show_website_title === 1,
          showCustomTitle: webViewData?.data.show_custom_title === 1,

          customTitle: webViewData?.data.custom_title
            ? webViewData?.data.custom_title
            : "",
          titleAlignment: webViewData?.data.title_alignment
            ? webViewData?.data.title_alignment
            : "left",

          backgroundColor: webViewData?.data.background_color
            ? webViewData?.data.background_color.portal_data
            : "linear-gradient(90deg, rgba(51, 199, 255, 1) 0%, rgba(72, 90, 254, 1) 50%, rgba(146, 65, 239, 1) 100%)",
          borderColor: webViewData?.data.border_color
            ? webViewData?.data.border_color.portal_data
            : "#74A4FF",
          iconColor: webViewData?.data.icon_color
            ? webViewData?.data.icon_color.portal_data
            : "#FFFFFF",
          textColor: webViewData?.data.text_color
            ? webViewData?.data.text_color.portal_data
            : "#FFFFFF",
          nativeLoaderDuration: webViewData?.data.native_loader_duration
            ? webViewData?.data.native_loader_duration
            : "2",
          updatedAt: webViewData?.data.last_updated_timestamp
            ? webViewData?.data.last_updated_timestamp
            : null,
          enablePullToRefresh: webViewData?.data.enable_pull_to_refresh === 1,
          appendAppCodeVersionInUserAgent:
            webViewData?.data.append_app_code_version_in_user_agent === 1,
          enableOverrideCssFeature: webViewData?.data
            .enable_override_css_feature
            ? true
            : false,
          cssToOverride: webViewData?.data.css_to_override || "",
          enableWebsiteCachePolicy: !webViewData?.data
            ?.enable_website_cache_policy
            ? false
            : true,
          enableForcefulCacheWebpages: Boolean(
            webViewData?.data?.enable_forceful_cache_webpages
          ),
          numberOfDaysToCache: webViewData?.data?.number_of_days_to_cache || 2,
          numberOfDaysToCacheFormat:
            webViewData?.data?.number_of_days_to_cache_format || "days",
        });
      }
    }
  }, [webViewData]);

  return (
    <div>
      <>
        <Header customclass={"header-responsive-new"} />

        <section className="dasboard_page bottombar-module mobile-display">
          <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
          <div
            className={`right-panel-gap ${
              localStorage.getItem("appSideBarCollapse") &&
              localStorage.getItem("appSideBarCollapse") === "true"
                ? " right-panel-gap-expand"
                : ""
            }`}
          >
            <div className="webView-header-div">
              <ModuleTitleTooltip
                title={"Web view"}
                text={"Manage the appearance of your in-app browser"}
              />

              <div className="webView-header-right">
                {webViewState.updatedAt && (
                  <p className="webView-updated-date">
                    Updated{" "}
                    {moment(webViewState.updatedAt).format(
                      "D MMMM YYYY, HH:mm"
                    )}
                  </p>
                )}
                <button
                  className="webView-save-btn"
                  style={{
                    backgroundColor: localStorage.getItem(
                      "button_background_color"
                    ),
                    border:
                      "1px solid " +
                      localStorage.getItem("button_background_color"),
                    color: localStorage.getItem("button_text_color"),
                    "--custom_color5":
                      localStorage.getItem("agencyid") == 1
                        ? "#134bec"
                        : localStorage.getItem("button_hover_bgcolor"),
                    "--custom_color4":
                      localStorage.getItem("agencyid") == 1
                        ? "white"
                        : localStorage.getItem("button_hover_txtcolor"),
                    "--custom_color6":
                      localStorage.getItem("agencyid") == 1
                        ? "#3064f9"
                        : localStorage.getItem("button_hover_bgcolor"),
                  }}
                  onClick={() => handlesubmit()}
                  disabled={isSaveWebViewDataLoading}
                >
                  <div className="d-flex align-items-center justify-content-center h-100 w-100">
                    {isSaveWebViewDataLoading ? (
                      <div className="new-loader"></div>
                    ) : (
                      "Save"
                    )}
                  </div>
                </button>
              </div>
            </div>

            <div className="webView-page-layout">
              <div className="webView-page">
                {isWebViewDataLoading ? (
                  <div
                    style={{
                      minHeight: "80vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Spinner />
                  </div>
                ) : (
                  <div>
                    <div className="webView-width">
                      <div className="webView-settings">
                        <div className="webView-settings-head">
                          <h3 className="webView-settings-title">Settings</h3>
                          <h4 className="webView-settings-description">
                            Control your web view interface here
                          </h4>
                        </div>

                        <div className="webview-setting-one">
                          <h3 className="webview-setting-one-title">
                            App header
                          </h3>
                          <CommonToggle
                            title="Show app header"
                            message="Display the app’s header."
                            toggleName={"showAppheader"}
                            handleStateChange={handleStateChange}
                            webViewState={webViewState}
                          />
                          {(webViewState.showAppheader ||
                            webViewState.showAppheader === 1) && (
                            <>
                              <div className="webview-settings-toggle">
                                <p className="webview-settings-toggle-title">
                                  Show website title
                                  <Tooltipicon
                                    message={" Show the title of the website."}
                                  />
                                </p>

                                <label
                                  className="switch"
                                  htmlFor="showWebsiteTitle"
                                >
                                  <input
                                    type="checkbox"
                                    id="showWebsiteTitle"
                                    name="showWebsiteTitle"
                                    checked={webViewState.showWebsiteTitle}
                                    onChange={(e) =>
                                      handleStateChange(
                                        "showWebsiteTitle",
                                        e.target.checked
                                      )
                                    }
                                  />

                                  <div className="slider round"></div>
                                </label>
                              </div>

                              <div className="webview-settings-toggle">
                                <p className="webview-settings-toggle-title">
                                  Show custom title
                                  <Tooltipicon
                                    message={"Present a title of your choice."}
                                  />
                                </p>

                                <label
                                  className="switch"
                                  htmlFor="showCustomTitle"
                                >
                                  <input
                                    type="checkbox"
                                    id="showCustomTitle"
                                    name="showCustomTitle"
                                    checked={webViewState.showCustomTitle}
                                    onChange={(e) =>
                                      handleStateChange(
                                        "showCustomTitle",
                                        e.target.checked
                                      )
                                    }
                                  />

                                  <div className="slider round"></div>
                                </label>
                              </div>
                              {(webViewState.showCustomTitle ||
                                webViewState.showCustomTitle === 1) && (
                                <div>
                                  <input
                                    type="text"
                                    className="web-view-input"
                                    placeholder="Enter custom title here"
                                    value={webViewState.customTitle}
                                    onChange={(e) =>
                                      handleStateChange(
                                        "customTitle",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              )}
                              <CommonToggle
                                title={"Show website domain"}
                                message={"Display the website’s domain name."}
                                toggleName={"showDomain"}
                                handleStateChange={handleStateChange}
                                webViewState={webViewState}
                              />

                              <div className="webview-settings-toggle">
                                <p className="webview-settings-toggle-title">
                                  Title alignment
                                  <Tooltipicon
                                    message={
                                      "Choose the desired alignment for the title."
                                    }
                                  />
                                </p>
                                <TitleAlignmentInput
                                  handleStateChange={handleStateChange}
                                  webViewState={webViewState}
                                />
                              </div>
                              <CommonToggle
                                title={"Show back and forth arrows"}
                                message={
                                  "Display arrows to navigate web pages."
                                }
                                toggleName={"showBackAndForthArrows"}
                                handleStateChange={handleStateChange}
                                webViewState={webViewState}
                              />

                              <CommonToggle
                                title={"Show kebab menu"}
                                message={
                                  "Show the three-dot-menu for additional options."
                                }
                                toggleName={"showOptionsShortcut"}
                                handleStateChange={handleStateChange}
                                webViewState={webViewState}
                              />

                              <div className=" webview-color-picker-div">
                                <div className=" webView-theme-options-div">
                                  <h6 className="webView-color-picker-label">
                                    Background color
                                    <Tooltipicon
                                      message={"Select the background color."}
                                    />
                                  </h6>
                                  <Reactcolorpicker
                                    colorname={webViewState.backgroundColor}
                                    onChange={(e) =>
                                      handleStateChange("backgroundColor", e)
                                    }
                                    classselection={"fixback"}
                                    valuecontrol={false}
                                  />
                                  {webViewState.backgroundColor !==
                                    "rgba(255,255,255,1)" && (
                                    <CrossIcon
                                      handleCrossClick={() =>
                                        handleStateChange(
                                          "backgroundColor",
                                          "rgba(255,255,255,1)"
                                        )
                                      }
                                    />
                                  )}
                                </div>

                                <div className=" webView-theme-options-div">
                                  <h6 className="webView-color-picker-label">
                                    Border color
                                    <Tooltipicon
                                      message={"Select the border color."}
                                    />
                                  </h6>
                                  <Reactcolorpicker
                                    colorname={webViewState.borderColor}
                                    onChange={(e) =>
                                      handleStateChange("borderColor", e)
                                    }
                                    classselection={"fixback"}
                                    valuecontrol={true}
                                  />

                                  {webViewState.borderColor !==
                                    "rgba(222,222,222,1)" && (
                                    <CrossIcon
                                      handleCrossClick={() =>
                                        handleStateChange(
                                          "borderColor",
                                          "rgba(222,222,222,1)"
                                        )
                                      }
                                    />
                                  )}
                                </div>
                              </div>

                              <div className=" webview-color-picker-second-div">
                                <div className="webview-theme-gap webView-theme-options-div">
                                  <h6 className="webView-color-picker-label">
                                    Icon color
                                    <Tooltipicon
                                      message={"Select the icon color."}
                                    />
                                  </h6>

                                  <Reactcolorpicker
                                    colorname={webViewState.iconColor}
                                    onChange={(e) =>
                                      handleStateChange("iconColor", e)
                                    }
                                    classselection={"fixback"}
                                    valuecontrol={true}
                                  />
                                  {webViewState.iconColor !==
                                    "rgba(26,26,26,1)" && (
                                    <CrossIcon
                                      handleCrossClick={() =>
                                        handleStateChange(
                                          "iconColor",
                                          "rgba(26,26,26,1)"
                                        )
                                      }
                                    />
                                  )}
                                </div>

                                <div className="webview-theme-gap webView-theme-options-div">
                                  <h6 className="webView-color-picker-label">
                                    Text color
                                    <Tooltipicon
                                      message={"Select the text color."}
                                    />
                                  </h6>

                                  <Reactcolorpicker
                                    colorname={webViewState.textColor}
                                    onChange={(e) =>
                                      handleStateChange("textColor", e)
                                    }
                                    classselection={"fixback"}
                                    valuecontrol={true}
                                  />
                                  {webViewState.textColor !==
                                    "rgba(26,26,26,1)" && (
                                    <CrossIcon
                                      handleCrossClick={() =>
                                        handleStateChange(
                                          "textColor",
                                          "rgba(26,26,26,1)"
                                        )
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>

                        <div className="webview-bottom-menu">
                          <h3 className="webview-setting-one-title">
                            Website controls
                          </h3>
                          <CommonToggle
                            title={" Show website header"}
                            message={"Display the website’s header."}
                            toggleName={"showWebsiteHeader"}
                            handleStateChange={handleStateChange}
                            webViewState={webViewState}
                          />

                          {(webViewState.showWebsiteHeader ||
                            webViewState.showWebsiteHeader === 1) && (
                            <CommonToggle
                              title={"Show website sidebar"}
                              message={"Display the website’s sidebar."}
                              toggleName={"showWebsiteSidebar"}
                              handleStateChange={handleStateChange}
                              webViewState={webViewState}
                            />
                          )}
                          <CommonToggle
                            title={" Show website footer"}
                            message={"Display the website’s footer."}
                            toggleName={"showWebsiteFooter"}
                            handleStateChange={handleStateChange}
                            webViewState={webViewState}
                          />
                          <CommonToggle
                            title={"Enable pinch-to-zoom"}
                            message={"Allow zooming using pinch gestures."}
                            toggleName={"enablePinchToZoomBool"}
                            handleStateChange={handleStateChange}
                            webViewState={webViewState}
                          />
                          <CommonToggle
                            title={"   Enable pull to refresh"}
                            message={
                              "When enabled, android app users can refresh the content by dragging the screen down and then releasing it, providing a seamless way to                                  fetch updated content."
                            }
                            toggleName={"enablePullToRefresh"}
                            handleStateChange={handleStateChange}
                            webViewState={webViewState}
                          />

                          <CommonToggle
                            title={"Retain user cookies"}
                            message={
                              "Preserve user-specific settings and data. Can be used for retaining web login sessions."
                            }
                            toggleName={"rememberCookiesBool"}
                            handleStateChange={handleStateChange}
                            webViewState={webViewState}
                          />

                          {/* For web to app and Custom App Only */}

                          <CommonToggle
                            title={" Show loader effect"}
                            message={
                              "Display a loading effect while the app screens load in the background."
                            }
                            toggleName={"showNativeLoader"}
                            handleStateChange={handleStateChange}
                            webViewState={webViewState}
                          />

                          {webViewState.showNativeLoader && (
                            <div className="webview-input-type-div">
                              <h6 className="webview-input-type-title">
                                Loader effect duration
                                <Tooltipicon
                                  message={
                                    "Specify the duration (in seconds) of the loader effect. If the default option is selected, the loading effect will be visible until the app screens completes loading."
                                  }
                                />
                              </h6>

                              <NativeDropdown
                                webViewState={webViewState}
                                handleStateChange={handleStateChange}
                              />

                              <h5 className="webview-input-type-subtitle">
                                seconds
                              </h5>
                            </div>
                          )}

                          {
                            <CommonToggle
                              title={" Append app code version in User Agent"}
                              message={
                                "Enable this toggle to include the app code version in the User Agent string. Enabling this option helps track and identify the app code version that is accessing your webpage."
                              }
                              toggleName={"appendAppCodeVersionInUserAgent"}
                              handleStateChange={handleStateChange}
                              webViewState={webViewState}
                            />
                          }

                          <div className="webview-input-type-div">
                            <h6 className="webview-input-type-title">
                              Hide HTML element by class
                              <Tooltipicon
                                message={
                                  "Make elements with a specific class invisible."
                                }
                              />
                            </h6>
                            <input
                              className="webview-input-type-input"
                              id="classhtmlelement"
                              placeholder="Enter classes separated by commas"
                              onKeyDown={handleKeyDown}
                              onChange={(e) =>
                                handleStateChange(
                                  "hideHtmlElementByClass",
                                  e.target.value
                                )
                              }
                              value={
                                webViewState.hideHtmlElementByClass
                                  ? webViewState.hideHtmlElementByClass
                                  : ""
                              }
                            ></input>
                          </div>

                          <div className="webview-input-type-div">
                            <h6 className="webview-input-type-title">
                              Hide HTML element by ID
                              <Tooltipicon
                                message={
                                  "Make elements with a specific ID invisible."
                                }
                              />
                            </h6>
                            <input
                              className="webview-input-type-input"
                              id="idhtmlelement"
                              placeholder="Enter IDs separated by commas"
                              onChange={(e) =>
                                handleStateChange(
                                  "hideHtmlElementById",
                                  e.target.value
                                )
                              }
                              onKeyDown={handleKeyDown}
                              value={
                                webViewState.hideHtmlElementById
                                  ? webViewState.hideHtmlElementById
                                  : ""
                              }
                            />
                          </div>

                          <CommonToggle
                            title={"Override CSS"}
                            message={
                              "Enable this toggle to override your website’s CSS and customize web views according to your  preferences. Enter custom CSS code in the code   box below."
                            }
                            toggleName={"enableOverrideCssFeature"}
                            handleStateChange={handleStateChange}
                            webViewState={webViewState}
                          />

                          {webViewState.enableOverrideCssFeature && (
                            <div
                              className="advertisment-labeldiv"
                              style={{
                                marginTop: "20px",
                              }}
                            >
                              <CodeMirror
                                value={webViewState.cssToOverride}
                                height="200px"
                                theme="dark"
                                onChange={(e) =>
                                  handleStateChange("cssToOverride", e)
                                }
                              />
                            </div>
                          )}

                          <div className="webview-settings-toggle">
                            <h6 className="webview-settings-toggle-title">
                              Honor my website’s cache policy
                              <Tooltipicon
                                message={
                                  "Enable this toggle to adhere to the caching policy of your website, ensuring that the app aligns with the caching rules set on your website."
                                }
                              />
                              {/* <Newfeatureicon /> */}
                            </h6>
                            <label
                              className="switch"
                              htmlFor="enableWebsiteCachePolicy"
                            >
                              <input
                                type="checkbox"
                                id="enableWebsiteCachePolicy"
                                name="enableWebsiteCachePolicy"
                                checked={webViewState.enableWebsiteCachePolicy}
                                onChange={(e) =>
                                  handleStateChange(
                                    "enableWebsiteCachePolicy",
                                    e.target.checked
                                  )
                                }
                              />

                              <div className="slider round"></div>
                            </label>
                          </div>

                          <div className="webview-settings-toggle">
                            <h6 className="webview-settings-toggle-title">
                              Forcefully cache my web pages
                              <Tooltipicon
                                message={
                                  "Enable this toggle to enforce caching of your web pages, independent of your website's cache policy."
                                }
                              />
                              {/* <Newfeatureicon /> */}
                            </h6>

                            <label
                              className="switch"
                              htmlFor="enableForcefulCacheWebpages"
                            >
                              <input
                                type="checkbox"
                                id="enableForcefulCacheWebpages"
                                name="enableForcefulCacheWebpages"
                                checked={
                                  webViewState.enableForcefulCacheWebpages
                                }
                                onChange={(e) =>
                                  handleStateChange(
                                    "enableForcefulCacheWebpages",
                                    e.target.checked
                                  )
                                }
                              />

                              <div className="slider round"></div>
                            </label>
                          </div>

                          <div className="webview-input-type-div">
                            <h6 className="webview-input-type-title">
                              Cache duration
                              <Tooltipicon
                                message={
                                  "Set the duration for caching of web pages in the app. The cached data will automatically get rebuilt after the specified duration."
                                }
                              />
                              {/* <Newfeatureicon /> */}
                            </h6>
                            <div
                              className={
                                "webview-cache-selection-div " +
                                (webViewState.enableForcefulCacheWebpages
                                  ? ""
                                  : "cache-duration-disable")
                              }
                            >
                              <input
                                disabled={
                                  !webViewState.enableForcefulCacheWebpages
                                }
                                min={1}
                                className="webview-cache-number-type"
                                type="number"
                                value={webViewState.numberOfDaysToCache}
                                onBlur={(e) => {
                                  if (e.target.value < 1) {
                                    e.target.value = 1;
                                  }
                                  e.target.value = e.target.value?.replace(
                                    /e$/i,
                                    ""
                                  );
                                  e.target.value = e.target.value?.replace(
                                    /[^0-9.]/g,
                                    ""
                                  );
                                  if (e.target.value?.includes(".")) {
                                    e.target.value = Math.floor(
                                      Number(e.target.value)
                                    );
                                  }
                                  setWebViewState({
                                    numberOfDaysToCache: e.target.value,
                                  });
                                }}
                                onChange={(e) => {
                                  setWebViewState({
                                    numberOfDaysToCache: e.target.value,
                                  });
                                }}
                              />

                              <CacheDaysDropdown
                                webViewState={webViewState}
                                handleStateChange={handleStateChange}
                                setWebViewState={setWebViewState}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <WebViewPreview webViewState={webViewState} />
                    </div>
                  </div>
                )}
              </div>
            </div>

            {!webViewState.bodyspinner && (
              <div className="webview-mobile-btns">
                <div
                  className="webview-mobile-btns-blue"
                  id="btnverifydomainid"
                  style={{
                    backgroundColor:
                      localStorage.getItem("button_background_color") &&
                      localStorage.getItem("button_background_color") !=
                        "undefined" &&
                      localStorage.getItem("button_background_color") != "null"
                        ? localStorage.getItem("button_background_color")
                        : "",
                    color: localStorage.getItem("button_text_color"),
                  }}
                  onClick={(e) => handlesubmit()}
                  disabled={isSaveWebViewDataLoading}
                >
                  {isSaveWebViewDataLoading ? (
                    <div className="new-loader"></div>
                  ) : (
                    "Save"
                  )}
                </div>

                {webViewState.updatedAt && (
                  <h5 className="webView-updated-date">
                    Updated{" "}
                    {moment(webViewState.updatedAt).format(
                      "D MMMM YYYY, HH:mm"
                    )}
                  </h5>
                )}
              </div>
            )}
          </div>

          <LoaderDurationModal
            handleStateChange={handleStateChange}
            webViewState={webViewState}
          />
          <CacheDurationModal
            handleStateChange={handleStateChange}
            webViewState={webViewState}
          />
        </section>
      </>
    </div>
  );
};

export default WebView;
