import axios from "axios";
import { errortoast, sucesstoast } from "../../Dashboard/Toaster";
const Url = process.env.REACT_APP_API_URL + "api/user/";

const Loader = (state) => {
    state.setState({
      submitval: "",
      loader: "loader",
    });
  };



export const ProductSaveApi = (params, state) => {
    Loader(state);
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("amstoken"),
    };
    axios
      .post(Url + "save-product-toggle", params, {
        headers: headers,
      })
      .then((res) => {
        if (res.data.code === 200) {
         
          state.setState({
            submitval: "Save",
            loader: "d-none",
            last_updated_timestamp:res.data.getdata.updated_at,
          });
          sucesstoast("Your changes have been saved.");
        } else {
            errortoast("Oh no! Something’s not right.");
        }
      })
      .catch((error) => {
        errortoast("Oh no! Something’s not right.");
        state.setState({
          submitval: "Save",
          loader: "d-none",
        });
      });
  };


export const ProductGetApi = (params, state) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("amstoken"),
    };
    axios
      .get(Url + "product-toggle/" + params.app_id, {
        headers: headers,
      })
      .then((res) => {
        if (res.data.code === 200) {
          if (res.data.data === null) {
            state.setState({
              bodyspinner: false,
              show_discount_percentage: false,
              show_related_products: true,
              show_additional_information: true,
              show_product_filter_and_sorting: true,
              show_out_of_stock: true,
              show_product_search: true,
              fit_image_horizontally: true,
              product_view_style: true,
              enable_web_view_interface: false,
              discount_badge_text_colour: "#FFFFFF",
              discount_badge_bg_colour: "#FA4143",
              enable_cart_badge: true,
              enable_discount_badge: true,
              cart_badge_counter_bg_colour: "#FF3D3D",
              cart_badge_counter_text_colour: "#FFFFFF",
              
            });
          } else {
            state.setState({
              bodyspinner: false,
              show_related_products: res.data.products_data.show_related_products_bool === 1 ? true : false,
              show_additional_information:res.data.products_data.show_additional_information_bool === 1? true: false,
              show_product_filter_and_sorting:res.data.products_data.show_product_filter_and_sorting_bool === 1? true: false,
              show_out_of_stock: res.data.products_data.show_out_of_stock_bool === 1 ? true : false,
              show_product_search:res.data.products_data.show_product_search_bool === 1 ? true : false,
              show_discount_percentage:res.data.products_data.show_discount_percentage_bool === 1 ? true : false,
              fit_image_horizontally:res.data.products_data.fit_image_horizontally === 1 ? true : false,
              product_view_style:res.data.products_data.product_view_style === undefined
                  ? true
                  : res.data.products_data.product_view_style === "grid"
                  ? true
                  : false,
                  
                temp_show_grid_view:res.data.products_data.product_view_style === undefined
                  ? true
                  : res.data.products_data.product_view_style === "grid"
                  ? true
                  : false,
              enable_web_view_interface:res.data.products_data.enable_web_view_interface_bool === undefined
                  ? false
                  : res.data.products_data.enable_web_view_interface_bool === 1
                  ? true
                  : false,
              discount_badge_text_colour:
              res.data.products_data.discount_badge_text_colour === undefined
              ?res.data.products_data.discount_badge_text_colour_object.portal_data 
              : res.data.products_data.discount_badge_text_colour ,
                // res.data.products_data.discount_badge_text_colour === undefined
                //   ? "#FFFFFF"
                //   : res.data.products_data.discount_badge_text_colour,
              discount_badge_bg_colour:
                res.data.products_data.discount_badge_bg_colour === undefined
                  ?res.data.products_data.discount_badge_bg_colour_object.portal_data.includes("gradient")?
                  res.data.products_data.discount_badge_bg_colour_object.app_data.colors[0].hex :
                  res.data.products_data.discount_badge_bg_colour_object.portal_data
                  : res.data.products_data.discount_badge_bg_colour ,
              enable_discount_badge:
                res.data.products_data.enable_discount_badge === undefined
                  ? true
                  : res.data.products_data.enable_discount_badge === 1
                  ? true
                  : false,
              enable_cart_badge:
                res.data.products_data.enable_cart_badge === undefined
                  ? true
                  : res.data.products_data.enable_cart_badge === 1
                  ? true
                  : false,
              cart_badge_counter_bg_colour:
                res.data.products_data.cart_badge_counter_bg_colour === undefined
                  ? "#FF3D3D"
                  : res.data.products_data.cart_badge_counter_bg_colour,
              cart_badge_counter_text_colour:
                res.data.products_data.cart_badge_counter_text_colour === undefined
                  ? "#FFFFFF"
                  : res.data.products_data.cart_badge_counter_text_colour,
              cartUrl: res.data.products_data.web_view_cart_url,
  
              // New Keys
              show_filter_on_listing_page:res.data.products_data.show_filter_on_listing_page === undefined 
                ? res.data.products_data.show_product_filter_and_sorting_bool===1?true:false
                : res.data.products_data.show_filter_on_listing_page === 1
                ? true
                : false ,
              show_sort_on_listing_page:res.data.products_data.show_sort_on_listing_page === undefined 
                ? res.data.products_data.show_product_filter_and_sorting_bool===1?true:false
                : res.data.products_data.show_sort_on_listing_page === 1
                ? true
                : false ,
              enable_wishlist_on_listing_page:res.data.products_data.enable_wishlist_on_listing_page === undefined 
                ? true
                : res.data.products_data.enable_wishlist_on_listing_page === 1
                ? true
                : false ,
              enable_wishlist_on_detail_page:res.data.products_data.enable_wishlist_on_detail_page === undefined 
                ? true
                : res.data.products_data.enable_wishlist_on_detail_page === 1
                ? true
                : false ,
              image_ratio_on_listing_page :res.data.products_data.image_ratio_on_listing_page?res.data.products_data.image_ratio_on_listing_page:"1:1"  ,
              image_ratio_on_detail_page :res.data.products_data.image_ratio_on_detail_page?res.data.products_data.image_ratio_on_detail_page:"1:1"  ,
              image_shape_on_listing_page : res.data.products_data.image_shape_on_listing_page?res.data.products_data.image_shape_on_listing_page:"soft_corner" ,
              show_images_in_gallery:res.data.products_data.show_images_in_gallery === undefined 
              ? true
              : res.data.products_data.show_images_in_gallery === 1
              ? true
              : false ,
              show_images_as_thumbnails_in_gallery:res.data.products_data.show_images_as_thumbnails_in_gallery === undefined 
              ? true
              : res.data.products_data.show_images_as_thumbnails_in_gallery === 1
              ? true
              : false ,
              show_additional_information_as_list_on_detail_page:res.data.products_data.show_additional_information_as_list_on_detail_page === undefined 
              ? false
              : res.data.products_data.show_additional_information_as_list_on_detail_page === 1
              ? true
              : false ,
              filter_related_products_by_on_detail_page:res.data.products_data.filter_related_products_by_on_detail_page?res.data.products_data.filter_related_products_by_on_detail_page:"default" ,
              show_native_payment_screen:res.data.checkout_data.show_payment_methods_screen_bool === undefined 
              ? true
              : res.data.checkout_data.show_payment_methods_screen_bool === 1
              ? true
              : false ,
              show_add_to_cart_on_listing_page:res.data.products_data.show_add_to_cart_on_listing_page === undefined 
              ? true
              : res.data.products_data.show_add_to_cart_on_listing_page === 1
              ? true
              : false ,
              show_center_crop_images_on_detail_page:res.data.products_data.show_center_crop_images_on_detail_page === undefined 
              ? true
              : res.data.products_data.show_center_crop_images_on_detail_page === 1
              ? true
              : false ,
              show_center_crop_images_on_listing_page:res.data.products_data.show_center_crop_images_on_listing_page === undefined 
              ? true
              : res.data.products_data.show_center_crop_images_on_listing_page === 1
              ? true
              : false ,
              last_updated_timestamp : res.data.products_data.last_updated_timestamp?res.data.products_data.last_updated_timestamp:null ,
            });
          }
        } else {
            errortoast("Oh no! Something’s not right.");
        }
      })
      .catch((error) => {});
  };