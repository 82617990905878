import axios from "axios";

import S3 from "react-aws-s3";
import { errortoast, sucesstoast } from "../../Dashboard/Toaster";
import { CustomS3Client } from "../../utils/S3utils";
const Url = process.env.REACT_APP_API_URL + "api/user/";
const sendurl = process.env.REACT_APP_API_URL + "api/app/";
export const jksfile = (params, state, callback) => {
  state.setState({
    disabledsubmitbutton: true,
  });
  if (params.original !== "") {
    function dataURLtoFileoriginal(dataurl, filename) {
      var arr = dataurl.split(","),
        //mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: "application/jks" });
    }
    let originalfile = dataURLtoFileoriginal(params.original, "hello.jks");
    const oriconfig = {
      bucketName: process.env.REACT_APP_bucketName,
      dirName: params.original_path.replace(/\/$/, "") /* optional */,
      region: process.env.REACT_APP_region,
      accessKeyId: process.env.REACT_APP_accessKeyId,
      secretAccessKey: process.env.REACT_APP_secretAccessKey,
      s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
    };
    const ReactS3original = new CustomS3Client(oriconfig);
    const originalFileName = params.imgname;
    ReactS3original.uploadFile(originalfile, originalFileName)
      .then((data) => {
        if (data.status === 204) {
          state.setState({
            getjksfilenameAWS: data.key.split("/").pop(),
            disabledsubmitbutton: false,
          });
          //callback()
        }
      })
      .catch((err) => {
        state.setState({
          disabledsubmitbutton: false,
        });
        errortoast("Something went wrong. Try again after sometime.");
      });
  } else {
    callback();
  }
};

//Save android custom bundle form
export const androidCustomBundle = (params, state) => {
  state.setState({
    submitval: "",
    loader: "loader",
    disabledsubmitbutton: true,
  });
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(sendurl + "android/save-custom-bundle", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          submitval: "Done",
          loader: "d-none",
          disabledsubmitbutton: false,
          modalandroid: false,
        });
        sucesstoast("App details successfully saved");
      } else if (res.data.code === 405) {
        state.setState({
          submitval: "Done",
          loader: "d-none",
          disabledsubmitbutton: false,
          // modalandroid: false,
        });
        errortoast("Your package name matches default package name");
      } else {
        if (res.data.data.bundle_id !== undefined) {
          errortoast(res.data.data.bundle_id);
        }
        if (res.data.data.current_version_name !== undefined) {
          errortoast(res.data.data.current_version_name);
        }
        state.setState({
          disabledsubmitbutton: false,
          submitval: "Done",
          loader: "d-none",
        });
      }
    })
    .catch((error) => {
      state.setState({
        submitval: "Done",
        loader: "d-none",
        disabledsubmitbutton: false,
      });
      errortoast("Something went wrong. Try again after sometime.");
    });
};

//Save ios custom bundle form
export const iosCustomBundle = (params, state, callback) => {
  state.setState({
    submitiosval: "",
    loaderios: "loader",
    disablediossubmitbutton: true,
  });
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(sendurl + "ios/save-custom-bundle", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        callback();
        state.setState({
          submitiosval: "Done",
          loaderios: "d-none",
          disablediossubmitbutton: false,
          modalios: false,
        });
        sucesstoast("App details successfully saved");
      } else if (res.data.code === 405) {
        state.setState({
          submitiosval: "Done",
          loaderios: "d-none",
          disablediossubmitbutton: false,
          // modalios: false,
        });
        errortoast("Your package name matches default package name");
      } else {
        if (res.data.data.bundle_id !== undefined) {
          errortoast(res.data.data.bundle_id);
        }
        if (res.data.data.current_version !== undefined) {
          errortoast(res.data.data.current_version);
        }
        state.setState({
          disablediossubmitbutton: false,
          submitiosval: "Done",
          loaderios: "d-none",
        });
      }
    })
    .catch((error) => {
      state.setState({
        submitiosval: "Done",
        loaderios: "d-none",
        disablediossubmitbutton: false,
      });
      errortoast("Something went wrong. Try again after sometime.");
    });
};

//get android custom bundle form
export const getFormData = (params, state, callback) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(
      Url +
        "app/get-custom-bundle/" +
        btoa(localStorage.getItem("Appid")) +
        "/" +
        params.type,
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        if (params.type === "android") {
          if (res.data.data !== null) {
            //Android data

            state.setState({
              bundle_id: res.data.data.bundle_id,
              is_app_already_published:
                res.data.is_app_already_published === 1 ? true : false,
              current_version_code: res.data.data.version,
              current_version_name: res.data.data.current_version,
              status: res.data.data.status === 1 ? true : false,

              is_success:
                res.data.is_success !== 0 || res.data.build_process !== 0
                  ? 1
                  : 0,
              resetbuttonandroid: res.data.is_success,
              disabledsubmitbutton: res.data.build_process !== 0 ? true : false,
              spinner: false,
            });
          } else {
            state.setState({
              spinner: false,
            });
          }
        }
        if (params.type === "ios") {
          if (res.data.data !== null) {
            state.stopSpinner();
            state.setState({
              is_app_already_published_ios: res.data.is_app_already_published === 1 ? true : false,
              ios_bundle_id: res.data.data.bundle_id,
              iOS_current_version_code: res.data.data.current_version,
              iosstatus: res.data.data.status === 1 ? true : false,
              resetbuttonios: res.data.is_success,
              is_iossuccess:
                res.data.is_success !== 0 || res.data.build_process !== 0
                  ? 1
                  : 0,
              disablediossubmitbutton:
                res.data.build_process !== 0 ? true : false,
              spinner: false,
            });
            callback();
          } else {
            state.setState({
              spinner: false,
            });
          }
        }
      }
    });
};
export const getiosformdata = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(
      Url +
        "app/get-custom-bundle/" +
        btoa(localStorage.getItem("Appid")) +
        "/" +
        params.type,
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          app_data: res.data.app_data,
          // appid:res.data.data.app_id,
          // userid:res.data.data.user_id,
        });
        if (res.data.data !== null) {
          state.setState({
            appid: res.data.data.app_id,
            userid: res.data.data.user_id,
          });
        }
        if (params.type === "ios") 
        {
          state.setState({
            is_social_login_enabled: res.data.is_social_login_enabled,
          });
          if (res.data.data !== null) 
          {
            state.setState({
              ios_bundle_id: res.data.data.bundle_id,
              ios_status: res.data.data.status === 1 ? true : false,
            });

            if (res.data.files !== null) 
            {
              state.setState({
                //iosform

                teamid: res.data.files.team_id,
                certificates3name: res.data.files.certificate,
                certificatefilename:
                  res.data.files.certificate === "" ||
                  res.data.files.certificate === null
                    ? ".p12 file supported "
                    : res.data.files.certificate,
                chat_preferences:
                res.data.app_data.chat_preferences === undefined
                  ? null
                  : res.data.app_data.chat_preferences,
                pushnotificationfilename:
                  res.data.files.notification_config_file === "" ||
                  res.data.files.notification_config_file === null
                    ? ".plist file supported  "
                    : res.data.files.notification_config_file,
                pushnotifications3name:
                  res.data.files.notification_config_file === null
                    ? ""
                    : res.data.files.notification_config_file,
                serverkey: res.data.files.notification_key,
                provision2filename:
                  res.data.files.profile === "" ||
                  res.data.files.profile === null
                    ? ".mobileprovision file supported "
                    : res.data.files.profile,
                provision2s3name: res.data.files.profile,
                pushtoggle:
                  res.data.files.is_notification_enabled === 0 ? false : true,
                google_enable:
                  res.data.files.is_google_login_enabled === 0 ? false : true,
                modalspinner: false,
                is_supporting_ipad:
                  res.data.files.is_supporting_ipad === undefined ||
                  res.data.files.is_supporting_ipad === 0
                    ? false
                    : true,
                bundle_identifier:
                  res.data.bundle_identifier === undefined ||
                  res.data.bundle_identifier === ""
                    ? "com.appmysite.app"
                    : res.data.bundle_identifier,
                certificate_password:
                  res.data.files.certificate_password === null ||
                  res.data.files.certificate_password === undefined
                    ? ""
                    : res.data.files.certificate_password,
                ios_admob_app_id: res.data.files.admob_app_id,
                ios_is_admob_enabled:
                  res.data.files.is_admob_enabled === 1 ? true : false,
                build_process: res.data.build_process,
                //permission
                is_enable_location_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions)
                        .is_enable_location_permission === 1
                    ? true
                    : false,
                location_permission_description:
                  res.data.files.permissions === null
                    ? ""
                    : JSON.parse(res.data.files.permissions)
                        .location_permission_description,
                is_enable_calender_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions)
                        .is_enable_calender_permission === 1
                    ? true
                    : false,
                calender_permission_description:
                  res.data.files.permissions === null
                    ? ""
                    : JSON.parse(res.data.files.permissions)
                        .calender_permission_description,
                is_enable_microphone_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions)
                        .is_enable_microphone_permission === 1
                    ? true
                    : false,
                microphone_permission_description:
                  res.data.files.permissions === null
                    ? ""
                    : JSON.parse(res.data.files.permissions)
                        .microphone_permission_description,
                is_enable_reminder_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions)
                        .is_enable_reminder_permission === 1
                    ? true
                    : false,
                reminder_permission_description:
                  res.data.files.permissions === null
                    ? ""
                    : JSON.parse(res.data.files.permissions)
                        .reminder_permission_description,
                is_enable_speech_recognition_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions)
                        .is_enable_speech_recognition_permission === 1
                    ? true
                    : false,
                speech_recognition_permission_description:
                  res.data.files.permissions === null
                    ? ""
                    : JSON.parse(res.data.files.permissions)
                        .speech_recognition_permission_description,
                is_enable_camera_and_photo_library_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions).is_enable_camera_and_photo_library_permission === 1 ||
                      (res.data.app_data.chat_preferences !== null && res.data.app_data.chat_preferences.some((res) => res.module_status === 1))
                    ? true
                    : false,
                camera_and_photo_library_permission_description:
                  res.data.files.permissions === null
                    ? ""
                    : JSON.parse(res.data.files.permissions)
                        .camera_and_photo_library_permission_description,
                is_enable_contacts_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions)
                        .is_enable_contacts_permission === 1
                    ? true
                    : false,
                contacts_permission_description:
                  res.data.files.permissions === null
                    ? ""
                    : JSON.parse(res.data.files.permissions)
                        .contacts_permission_description,
                is_enable_app_tracking_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions)
                        .is_enable_app_tracking_permission === 1
                    ? true
                    : false,
                app_tracking_permission_description:
                  res.data.files.permissions === null
                    ? ""
                    : JSON.parse(res.data.files.permissions)
                        .app_tracking_permission_description,
                is_enable_keychain:
                  res.data.files.is_enable_keychain === 1 ? true : false,
                keychain_url:
                  res.data.files.keychain_url === undefined
                    ? ""
                    : res.data.files.keychain_url,
              });
            } else {
              state.setState({
                modalspinner: false,
                bundle_identifier:
                  res.data.bundle_identifier === undefined ||
                  res.data.bundle_identifier === ""
                    ? "com.appmysite.app"
                    : res.data.bundle_identifier,
                teamid: "",
                chat_preferences:
                res.data.app_data.chat_preferences === undefined
                  ? null
                  : res.data.app_data.chat_preferences,
                certificates3name: "",
                certificatefilename: ".p12 file supported ",
                pushnotificationfilename: ".p12 file supported ",
                pushnotifications3name: "",
                provision2filename: ".mobileprovision file supported ",
                provision2s3name: "",
                pushtoggle: false,
                google_enable: false,
                is_supporting_ipad: false,
                certificate_password: "",
                ios_admob_app_id: "",
                ios_is_admob_enabled: false,
                is_enable_location_permission: false,
                build_process: res.data.build_process,
                //permission
                location_permission_description: "",
                is_enable_calender_permission: false,
                calender_permission_description: "",
                is_enable_microphone_permission: false,
                microphone_permission_description: "",
                is_enable_reminder_permission: false,
                reminder_permission_description: "",
                is_enable_speech_recognition_permission: false,
                speech_recognition_permission_description: "",
                is_enable_camera_and_photo_library_permission:
                (res.data.app_data.chat_preferences !== null && res.data.app_data.chat_preferences.some((res) => res.module_status === 1)) ? true : false,
                camera_and_photo_library_permission_description: "",
                is_enable_contacts_permission: false,
                contacts_permission_description: "",
                is_enable_app_tracking_permission: false,
                app_tracking_permission_description: "",
              });
            }
          } else {
            if (res.data.files !== null) {
              state.setState({
                //iosform
                is_enable_keychain:
                  res.data.files.is_enable_keychain === 1 ? true : false,
                keychain_url:
                  res.data.files.keychain_url === undefined
                    ? ""
                    : res.data.files.keychain_url,
                teamid: res.data.files.team_id,
                chat_preferences:
                res.data.app_data.chat_preferences === undefined
                  ? null
                  : res.data.app_data.chat_preferences,
                certificates3name: res.data.files.certificate,
                certificatefilename:
                  res.data.files.certificate === "" ||
                  res.data.files.certificate === null
                    ? ".p12 file supported "
                    : res.data.files.certificate,
                pushnotificationfilename:
                  res.data.files.notification_config_file === "" ||
                  res.data.files.notification_config_file === null
                    ? ".plist file supported  "
                    : res.data.files.notification_config_file,
                pushnotifications3name:
                  res.data.files.notification_config_file === null
                    ? ""
                    : res.data.files.notification_config_file,
                serverkey: res.data.files.notification_key,
                provision2filename:
                  res.data.files.profile === "" ||
                  res.data.files.profile === null
                    ? ".mobileprovision file supported "
                    : res.data.files.profile,
                provision2s3name: res.data.files.profile,
                pushtoggle:
                  res.data.files.is_notification_enabled === 1 ? true : false,
                google_enable:
                  res.data.files.is_google_login_enabled === 0 ? false : true,
                modalspinner: false,
                is_supporting_ipad:
                  res.data.files.is_supporting_ipad === undefined ||
                  res.data.files.is_supporting_ipad === 0
                    ? false
                    : true,
                bundle_identifier:
                  res.data.bundle_identifier === undefined ||
                  res.data.bundle_identifier === ""
                    ? "com.appmysite.app"
                    : res.data.bundle_identifier,
                certificate_password:
                  res.data.files.certificate_password === null ||
                  res.data.files.certificate_password === undefined
                    ? ""
                    : res.data.files.certificate_password,
                ios_admob_app_id: res.data.files.admob_app_id,
                ios_is_admob_enabled:
                  res.data.files.is_admob_enabled === 1 ? true : false,
                build_process: res.data.build_process,
                //permission
                is_enable_location_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions)
                        .is_enable_location_permission === 1
                    ? true
                    : false,
                location_permission_description:
                  res.data.files.permissions === null
                    ? ""
                    : JSON.parse(res.data.files.permissions)
                        .location_permission_description,
                is_enable_calender_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions)
                        .is_enable_calender_permission === 1
                    ? true
                    : false,
                calender_permission_description:
                  res.data.files.permissions === null
                    ? ""
                    : JSON.parse(res.data.files.permissions)
                        .calender_permission_description,
                is_enable_microphone_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions)
                        .is_enable_microphone_permission === 1
                    ? true
                    : false,
                microphone_permission_description:
                  res.data.files.permissions === null
                    ? ""
                    : JSON.parse(res.data.files.permissions)
                        .microphone_permission_description,
                is_enable_reminder_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions)
                        .is_enable_reminder_permission === 1
                    ? true
                    : false,
                reminder_permission_description:
                  res.data.files.permissions === null
                    ? ""
                    : JSON.parse(res.data.files.permissions)
                        .reminder_permission_description,
                is_enable_speech_recognition_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions)
                        .is_enable_speech_recognition_permission === 1
                    ? true
                    : false,
                speech_recognition_permission_description:
                  res.data.files.permissions === null
                    ? ""
                    : JSON.parse(res.data.files.permissions)
                        .speech_recognition_permission_description,
                is_enable_camera_and_photo_library_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions).is_enable_camera_and_photo_library_permission === 1 ||
                    (res.data.app_data.chat_preferences !== null && res.data.app_data.chat_preferences.some((res) => res.module_status === 1))
                    ? true
                    : false,
                camera_and_photo_library_permission_description:
                  res.data.files.permissions === null
                    ? ""
                    : JSON.parse(res.data.files.permissions)
                        .camera_and_photo_library_permission_description,
                is_enable_contacts_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions)
                        .is_enable_contacts_permission === 1
                    ? true
                    : false,
                contacts_permission_description:
                  res.data.files.permissions === null
                    ? ""
                    : JSON.parse(res.data.files.permissions)
                        .contacts_permission_description,
                is_enable_app_tracking_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions)
                        .is_enable_app_tracking_permission === 1
                    ? true
                    : false,
                app_tracking_permission_description:
                  res.data.files.permissions === null
                    ? ""
                    : JSON.parse(res.data.files.permissions)
                        .app_tracking_permission_description,
              });
            } else {
              state.setState({
                modalspinner: false,
                bundle_identifier:
                  res.data.bundle_identifier === undefined ||
                  res.data.bundle_identifier === ""
                    ? "com.appmysite.app"
                    : res.data.bundle_identifier,
                chat_preferences:
                res.data.app_data.chat_preferences === undefined
                  ? null
                  : res.data.app_data.chat_preferences,
                ios_admob_app_id: "",
                ios_is_admob_enabled: false,
                build_process: res.data.build_process,
                //permission
                location_permission_description: "",
                is_enable_calender_permission: false,
                calender_permission_description: "",
                is_enable_microphone_permission: false,
                microphone_permission_description: "",
                is_enable_reminder_permission: false,
                reminder_permission_description: "",
                is_enable_speech_recognition_permission: false,
                speech_recognition_permission_description: "",
                is_enable_camera_and_photo_library_permission: (res.data.app_data.chat_preferences !== null && res.data.app_data.chat_preferences.some((res) => res.module_status === 1)) ? true : false,
                camera_and_photo_library_permission_description: "",
                is_enable_contacts_permission: false,
                contacts_permission_description: "",
                is_enable_app_tracking_permission: false,
                app_tracking_permission_description: "",
              });
            }
          }
        } else {
          state.setState({
            is_app_already_published: res.data.is_app_already_published,
            is_social_login_enabled: res.data.is_social_login_enabled,
            chat_preferences:
              res.data.app_data.chat_preferences === undefined
                ? null
                : res.data.app_data.chat_preferences,
            bundle_android_identifier:
              res.data.bundle_identifier === undefined ||
              res.data.bundle_identifier === null
                ? ""
                : res.data.bundle_identifier,
          });
          if (res.data.data !== null) {
            //Android data
            state.setState({
              modalspinner: false,
            });
            if (res.data.files !== null) {
              if (res.data.files.in_process !== 0) {
                state.waitfunction();
              }

              state.setState({
                // is_app_already_published: res.data.is_app_already_published,
                androidserverkey: res.data.files.notification_key,
                androidpushnotificationfilename:
                  res.data.files.notification_config_file === "" ||
                  res.data.files.notification_config_file === null
                    ? ""
                    : res.data.files.notification_config_file,
                androidpushnotifications3name:
                  res.data.files.notification_config_file === null
                    ? ""
                    : res.data.files.notification_config_file,
                android_pushtoggle:
                  res.data.files.is_notification_enabled === 1 ? true : false,
                androidgoogle_enable:
                  res.data.files.is_google_login_enabled === 1 ? true : false,
                android_custom_status: res.data.data.status,
                jks_file: res.data.files.jks_file,
                jks_filename: res.data.files.jks_file,
                getjksfilenameAWS: res.data.files.jks_file,
                keystore_alias: res.data.files.keystore_alias_name,
                keystore_password: res.data.files.keystore_password,
                keystore_private_key_password:
                  res.data.files.keystore_private_key_password,
                admob_app_id: res.data.files.admob_app_id,
                is_admob_enabled:
                  res.data.files.is_admob_enabled === 1 ? true : false,
                in_process_sha: res.data.files.in_process,
                sha1: res.data.files.sha1,
                sha_spinner: res.data.files.in_process === 1 ? true : false,
                androiddisabledsubmitbutton:
                  res.data.files.in_process === 1 ? true : false,
                build_process: res.data.build_process,
                //permissison
                is_enable_location_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions)
                        .is_enable_location_permission === 1
                    ? true
                    : false,
                is_enable_camera_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions)
                        .is_enable_camera_permission === 1 ||
                      (res.data.app_data.chat_preferences !== null &&
                        res.data.app_data.chat_preferences.some(
                          (res) => res.module_status === 1
                        ))
                    ? true
                    : false,

                is_enable_calender_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions)
                        .is_enable_calender_permission === 1
                    ? true
                    : false,
                is_enable_storage_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions)
                        .is_enable_storage_permission === 1
                    ? true
                    : false,
                is_enable_microphone_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions)
                        .is_enable_microphone_permission === 1
                    ? true
                    : false,
                is_enable_contacts_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions)
                        .is_enable_contacts_permission === 1
                    ? true
                    : false,
              });
            } else {
              state.setState({
                androidserverkey: "",
                androidpushnotificationfilename: "",
                androidpushnotifications3name: "",
                android_pushtoggle: false,
                androidgoogle_enable: false,
                android_custom_status: res.data.data.status,
                jks_file: null,
                jks_filename: ".jks file supported",
                getjksfilenameAWS: "",
                admob_app_id: "",
                is_admob_enabled: false,
                in_process_sha: null,
                sha1: "",
                sha_spinner: false,
                build_process: res.data.build_process,
                is_enable_location_permission: false,
                is_enable_camera_permission: false,
                is_enable_calender_permission: false,
                is_enable_storage_permission: false,
                is_enable_microphone_permission: false,
                is_enable_contacts_permission: false,
              });
            }
          } else {
            state.setState({
              modalspinner: false,
            });
            if (res.data.files !== null) {
              if (res.data.files.in_process !== 0) {
                state.waitfunction();
              }
              state.setState({
                androidserverkey: res.data.files.notification_key,
                androidpushnotificationfilename:
                  res.data.files.notification_config_file === "" ||
                  res.data.files.notification_config_file === null
                    ? ""
                    : res.data.files.notification_config_file,
                androidpushnotifications3name:
                  res.data.files.notification_config_file === null
                    ? ""
                    : res.data.files.notification_config_file,
                android_pushtoggle:
                  res.data.files.is_notification_enabled === 1 ? true : false,
                androidgoogle_enable:
                  res.data.files.is_google_login_enabled === 1 ? true : false,
                android_custom_status: 0,
                admob_app_id: res.data.files.admob_app_id,
                is_admob_enabled:
                  res.data.files.is_admob_enabled === 1 ? true : false,
                in_process_sha: res.data.files.in_process,
                sha1: res.data.files.sha1,
                sha_spinner: res.data.files.in_process === 1 ? true : false,
                androiddisabledsubmitbutton:
                  res.data.files.in_process === 1 ? true : false,
                build_process: res.data.build_process,
                //jks
                jks_file: res.data.files.jks_file,
                jks_filename: res.data.files.jks_file,
                getjksfilenameAWS: res.data.files.jks_file,
                keystore_alias: res.data.files.keystore_alias_name,
                keystore_password: res.data.files.keystore_password,
                keystore_private_key_password:
                  res.data.files.keystore_private_key_password,
                //permissison
                is_enable_location_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions)
                        .is_enable_location_permission === 1
                    ? true
                    : false,
                is_enable_camera_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions)
                        .is_enable_camera_permission === 1 ||
                      (res.data.app_data.chat_preferences !== null &&
                        res.data.app_data.chat_preferences.some(
                          (res) => res.module_status === 1
                        ))
                    ? true
                    : false,
                is_enable_calender_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions)
                        .is_enable_calender_permission === 1
                    ? true
                    : false,
                is_enable_storage_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions)
                        .is_enable_storage_permission === 1
                    ? true
                    : false,
                is_enable_microphone_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions)
                        .is_enable_microphone_permission === 1
                    ? true
                    : false,
                is_enable_contacts_permission:
                  res.data.files.permissions === null
                    ? false
                    : JSON.parse(res.data.files.permissions)
                        .is_enable_contacts_permission === 1
                    ? true
                    : false,
				androidsenderid: res.data.files.gcm_sender_id,
              });
            } else {
              state.setState({
                androidserverkey: "",
                androidpushnotificationfilename: "",
                androidpushnotifications3name: "",
                android_pushtoggle: false,
                androidgoogle_enable: false,
                android_custom_status: 0,
                admob_app_id: "",
                build_process: res.data.build_process,
                is_admob_enabled: false,
                in_process_sha: null,
                sha1: "",
                sha_spinner: false,
                is_enable_location_permission: false,
                is_enable_camera_permission:
                  res.data.app_data.chat_preferences !== null &&
                  res.data.app_data.chat_preferences.some(
                    (res) => res.module_status === 1
                  )
                    ? true
                    : false,
                is_enable_calender_permission: false,
                is_enable_storage_permission: false,
                is_enable_microphone_permission: false,
                is_enable_contacts_permission: false,
              });
            }
          }
        }
      }
    });
};

export const newgetiosformdata = (params, state, callback) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(
      sendurl +
        "get-custom-bundle/" +
        btoa(localStorage.getItem("Appid")) +
        "/" +
        params.type,
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) 
      {

        if(res.data.build_process == 1)
        {
          window.location.href = "/app/download";
        }
       
        state.setState({
          app_data: res.data.app_data,
          spinner:false,
        });

        if (res.data.app_data !== null) 
        {
          state.setState({
            appid: res.data.app_data.app_id,
            userid: res.data.app_data.user_id,
            iosdata: res.data,
            spinner:false,
          });
        }

        if (params.type === "ios") 
        {
          state.setState({
                          is_social_login_enabled: res.data.is_social_login_enabled,
                          spinner:false,
                        });
          if (res.data) 
          {
            state.setState({
                  ios_bundle_id:  
                      res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files 
                      ?  res.data.already_have_live_files.bundle_id
                      :  res.data.custom_status == 1 &&  res.data.is_app_already_published == 0 && res.data.custom_bundle_files?
                         res.data.custom_bundle_files.bundle_id
                      :  res.data.custom_status == 0 &&  res.data.is_app_already_published == 0 
                      ?  res.data.current_bundle_identifier
                    : '',
                  ios_status: res.data.custom_status === 1 ? true : false,
                  iosdata : res.data,
                  spinner:false,
            });



            if (res.data ) 
            {

              state.setState({
                //iosform
                updatedAt:getUpdatedAtTimeStamp(res.data),
                issuer_id: ( res.data.app_data && res.data.app_data.ios_configuration_data && res.data.app_data.ios_configuration_data.issuer_id ) ? res.data.app_data.ios_configuration_data.issuer_id : "",
                key_id: ( res.data.app_data && res.data.app_data.ios_configuration_data && res.data.app_data.ios_configuration_data.key_id ) ? res.data.app_data.ios_configuration_data.key_id : "",
                key_file_name: ( res.data.app_data && res.data.app_data.ios_configuration_data && res.data.app_data.ios_configuration_data.key_file ) ? res.data.app_data.ios_configuration_data.key_file : "",
                key_file_s3_name: ( res.data.app_data && res.data.app_data.ios_configuration_data && res.data.app_data.ios_configuration_data.key_file ) ? res.data.app_data.ios_configuration_data.key_file : "",
                iosdata : res.data,
                is_app_already_published_ios: res.data.custom_status == 0 ? false : res.data.is_app_already_published === 1 ? true : false,
                current_version_name:
                                     res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files 
                                  ?
                                     res.data.already_have_live_files.current_version
                                  :  res.data.custom_status == 1  && res.data.is_app_already_published == 0 && res.data.custom_bundle_files
                                  ?  res.data.custom_bundle_files.current_version
                                  :  res.data.custom_status == 0  && res.data.is_app_already_published == 0 && res.data.default_bundle_files
                                  ?  res.data.default_bundle_files.current_version
                                  :  ''
                                  ,
                old_current_version_name:
                                     res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files 
                                  ?
                                     res.data.already_have_live_files.current_version
                                  :  res.data.custom_status == 1  && res.data.is_app_already_published == 0 && res.data.custom_bundle_files
                                  ?  res.data.custom_bundle_files.current_version
                                  :  res.data.custom_status == 0  && res.data.is_app_already_published == 0 && res.data.default_bundle_files
                                  ?  res.data.default_bundle_files.current_version
                                  :  ''
                                  ,
                bundle_id: 
                             res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files 
                          ?  res.data.already_have_live_files.bundle_id
                          :  res.data.custom_status == 1 &&  res.data.is_app_already_published == 0 && res.data.custom_bundle_files?
                             res.data.custom_bundle_files.bundle_id
                          :  res.data.custom_status == 0 &&  res.data.is_app_already_published == 0 && res.data.default_bundle_files?
                             res.data.default_bundle_files.bundle_id
                        : ''
                        ,
                old_bundle_id: 
                             res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files 
                          ?  res.data.already_have_live_files.bundle_id
                          :  res.data.custom_status == 1 &&  res.data.is_app_already_published == 0 && res.data.custom_bundle_files?
                             res.data.custom_bundle_files.bundle_id
                          :  res.data.custom_status == 0 &&  res.data.is_app_already_published == 0 && res.data.default_bundle_files?
                             res.data.default_bundle_files.bundle_id
                        : ''
                        ,
                status: res.data.custom_status === 1 ? true : false,
                is_success: 
                      res.data.is_success || res.data.build_process
                        ? 1
                        : 0,
                
                custompackage:res.data.custom_status === 1 || res.data.custom_status === true ? true:false,

                teamid: res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                          res.data.already_have_live_files.team_id
                        :  res.data.custom_status == 1 && res.data.is_app_already_published == 0 && res.data.custom_bundle_files?
                          res.data.custom_bundle_files.team_id
                        :  res.data.custom_status == 0 && res.data.is_app_already_published == 0 && res.data.default_bundle_files?
                          res.data.default_bundle_files.team_id
                        : ''
                        ,
                certificates3name: 
                                  res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                                  res.data.already_have_live_files.certificate
                                :  res.data.custom_status == 1 && res.data.is_app_already_published == 0 && res.data.custom_bundle_files?
                                  res.data.custom_bundle_files.certificate
                                :  res.data.custom_status == 0 && res.data.is_app_already_published == 0 && res.data.default_bundle_files?
                                res.data.default_bundle_files.certificate
                                : ''
                                ,
                spinner:false,
                certificatefilename:
                                res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files && res.data.already_have_live_files  
                                ?
                                  res.data.already_have_live_files.certificate === "" || res.data.already_have_live_files.certificate === null
                                  ? ".p12 file supported "
                                  : res.data.already_have_live_files.certificate
                                :  res.data.custom_status == 1 &&  res.data.is_app_already_published == 0 && res.data.custom_bundle_files && res.data.custom_bundle_files 
                                ?
                                  res.data.custom_bundle_files.certificate === "" || res.data.custom_bundle_files.certificate === null
                                  ? ".p12 file supported "
                                  : res.data.custom_bundle_files.certificate
                                :  res.data.custom_status == 0 &&  res.data.is_app_already_published == 0 && res.data.default_bundle_files && res.data.default_bundle_files 
                                ?
                                  res.data.default_bundle_files.certificate === "" || res.data.default_bundle_files.certificate === null
                                  ? ".p12 file supported "
                                  : res.data.default_bundle_files.certificate
                                : '.p12 file supported '
                                ,
                chat_preferences: res.data.app_data.chat_preferences === undefined
                                ? null
                                : res.data.app_data.chat_preferences,
                pushnotificationfilename:
                                res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files 
                                ?
                                  res.data.already_have_live_files.notification_config_file === "" || res.data.already_have_live_files.notification_config_file === null
                                  ? ".plist file supported "
                                  : res.data.already_have_live_files.notification_config_file
                                :  res.data.custom_status == 1 &&  res.data.is_app_already_published == 0 && res.data.custom_bundle_files
                                ?
                                  res.data.custom_bundle_files.notification_config_file === "" || res.data.custom_bundle_files.notification_config_file === null
                                  ? ".plist file supported "
                                  : res.data.custom_bundle_files.notification_config_file
                                :  res.data.custom_status == 0 &&  res.data.is_app_already_published == 0 && res.data.default_bundle_files
                                ?
                                  res.data.default_bundle_files.notification_config_file === "" || res.data.default_bundle_files.notification_config_file === null
                                  ? ".plist file supported "
                                  : res.data.default_bundle_files.notification_config_file
                                : '.plist file supported '
                                ,
                        
                pushnotifications3name:
                                res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files && res.data.already_have_live_files != null 
                                ?
                                  res.data.already_have_live_files.notification_config_file === "" || res.data.already_have_live_files.notification_config_file === null
                                  ? ""
                                  : res.data.already_have_live_files.notification_config_file
                                :  res.data.custom_status == 1 &&  res.data.is_app_already_published == 0 && res.data.custom_bundle_files && res.data.already_have_live_files != null
                                ?
                                  res.data.custom_bundle_files.notification_config_file === "" || res.data.custom_bundle_files.notification_config_file === null
                                  ? ""
                                  : res.data.custom_bundle_files.notification_config_file
                                :  res.data.custom_status == 0 &&  res.data.is_app_already_published == 0 && res.data.default_bundle_files && res.data.default_bundle_files != null
                                ?
                                  res.data.default_bundle_files.notification_config_file === "" || res.data.default_bundle_files.notification_config_file === null
                                  ? ""
                                  : res.data.default_bundle_files.notification_config_file
                                : ''
                                ,
                serverkey: 
                            res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                            res.data.already_have_live_files.notification_key
                          :  res.data.custom_status == 1 && res.data.is_app_already_published == 0 && res.data.custom_bundle_files?
                            res.data.custom_bundle_files.notification_key
                          :  res.data.custom_status == 0 && res.data.is_app_already_published == 0 && res.data.default_bundle_files?
                          res.data.default_bundle_files.notification_key
                          : ''
                          ,
                provision2filename:
                          res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files 
                          ?
                            res.data.already_have_live_files.profile === "" || res.data.already_have_live_files.profile === null
                            ? ".mobileprovision file supported "
                            : res.data.already_have_live_files.profile
                          :  res.data.custom_status == 1 &&  res.data.is_app_already_published == 0 && res.data.custom_bundle_files
                          ?
                            res.data.custom_bundle_files.profile === "" || res.data.custom_bundle_files.profile === null
                            ? ".mobileprovision file supported "
                            : res.data.custom_bundle_files.profile
                          :  res.data.custom_status == 0 &&  res.data.is_app_already_published == 0 && res.data.default_bundle_files
                          ?
                            res.data.default_bundle_files.profile === "" || res.data.default_bundle_files.profile === null
                            ? ".mobileprovision file supported "
                            : res.data.default_bundle_files.profile
                          : '.mobileprovision file supported '
                          ,
                provision2s3name: 
                          res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                          res.data.already_have_live_files.profile
                        :  res.data.custom_status == 1 &&  res.data.is_app_already_published == 0 && res.data.custom_bundle_files?
                          res.data.custom_bundle_files.profile
                        :  res.data.custom_status == 0 &&  res.data.is_app_already_published == 0 && res.data.default_bundle_files?
                        res.data.default_bundle_files.profile
                        : ''
                        ,
                notification_config_file: 
                          res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                          res.data.already_have_live_files.notification_config_file
                        : res.data.custom_status == 1 &&  res.data.is_app_already_published == 0 && res.data.custom_bundle_files?
                          res.data.custom_bundle_files.notification_config_file
                        : res.data.custom_status == 0 &&  res.data.is_app_already_published == 0 && res.data.default_bundle_files?
                          res.data.default_bundle_files.notification_config_file
                        : ''
                        ,
                pushtoggle:
                          res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                          res.data.already_have_live_files.is_notification_enabled === 0
                          ? false
                          : true
                        :  res.data.custom_status == 1 && res.data.is_app_already_published == 0 && res.data.custom_bundle_files?
                          res.data.custom_bundle_files.is_notification_enabled === 0
                          ? false
                          : true
                        :  res.data.custom_status == 0 && res.data.is_app_already_published == 0 && res.data.default_bundle_files?
                        res.data.default_bundle_files.is_notification_enabled === 0
                        ? false
                        : true
                        : ''
                        ,
                google_enable:
                        res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                        res.data.already_have_live_files.is_google_login_enabled === 0
                        ? false
                        : true
                      :  res.data.custom_status == 1 &&  res.data.is_app_already_published == 0 && res.data.custom_bundle_files?
                        res.data.custom_bundle_files.is_google_login_enabled === 0
                        ? false
                        : true
                      :  res.data.custom_status == 0 &&  res.data.is_app_already_published == 0 && res.data.default_bundle_files?
                      res.data.default_bundle_files.is_google_login_enabled === 0
                      ? false
                      : true
                      : ''
                      ,
                modalspinner: false,
                is_supporting_ipad:
                      res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                      res.data.already_have_live_files.is_supporting_ipad === undefined || res.data.already_have_live_files.is_supporting_ipad === 0
                      ? false
                      : true
                    :  res.data.custom_status == 1 && res.data.is_app_already_published == 0 && res.data.custom_bundle_files?
                    res.data.custom_bundle_files.is_supporting_ipad === undefined || res.data.custom_bundle_files.is_supporting_ipad === 0
                      ? false
                      : true
                    :  res.data.custom_status == 0 && res.data.is_app_already_published == 0 && res.data.default_bundle_files?
                    res.data.default_bundle_files.is_supporting_ipad === undefined || res.data.default_bundle_files.is_supporting_ipad === 0
                      ? false
                      : true
                    : ''
                    ,
                bundle_identifier:
                        res.data.current_bundle_identifier === undefined ||
                        res.data.current_bundle_identifier === ""
                          ? "com.appmysite.app"
                          : res.data.current_bundle_identifier, 
                certificate_password:
                        res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files 
                      ? res.data.already_have_live_files.certificate_password
                      : res.data.custom_status == 1 &&  res.data.is_app_already_published == 0 && res.data.custom_bundle_files 
                      ? res.data.custom_bundle_files.certificate_password
                      : res.data.custom_status == 0 && res.data.is_app_already_published == 0 && res.data.default_bundle_files ?
                        res.data.default_bundle_files.certificate_password
                      : ''
                      ,
                ios_admob_app_id: 
                                res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                                  res.data.already_have_live_files.admob_app_id
                                :  res.data.custom_status == 1 &&  res.data.is_app_already_published == 0 && res.data.custom_bundle_files?
                                  res.data.custom_bundle_files.admob_app_id
                                :  res.data.custom_status == 0 &&  res.data.is_app_already_published == 0 && res.data.default_bundle_files?
                                res.data.default_bundle_files.admob_app_id
                                : ''
                                ,
                ios_is_admob_enabled:
                          res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                          res.data.already_have_live_files.is_admob_enabled === 1
                          ? true
                          : false
                        :  res.data.custom_status == 1 &&  res.data.is_app_already_published == 0 && res.data.custom_bundle_files?
                        res.data.custom_bundle_files.is_admob_enabled === 1
                        :  res.data.custom_status == 0 &&  res.data.is_app_already_published == 0 && res.data.default_bundle_files?
                        res.data.default_bundle_files.is_admob_enabled === 1
                          ? true
                          : false
                        : ''
                        ,
                  
                
                build_process: res.data.build_process,
                //permission
                is_enable_location_permission:
                  
                    res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files &&  res.data.already_have_live_files.permissions 
                    ?
                        
                          JSON.parse(res.data.already_have_live_files.permissions).is_enable_location_permission == 1 ||
                          JSON.parse(res.data.already_have_live_files.permissions).is_enable_location_permission == true
                        ? true
                        : false
                    :  res.data.custom_status== 1 &&  res.data.is_app_already_published == 0  && res.data.custom_bundle_files && res.data.custom_bundle_files.permissions  
                    ?
                        JSON.parse(res.data.custom_bundle_files.permissions).is_enable_location_permission == 1 ||
                        JSON.parse(res.data.custom_bundle_files.permissions).is_enable_location_permission == true
                        ? true
                        : false
                    : res.data.custom_status== 0 &&  res.data.is_app_already_published == 0  && res.data.default_bundle_files && res.data.default_bundle_files.permissions 
                    ? 
                      JSON.parse(res.data.default_bundle_files.permissions).is_enable_location_permission == 1 ||
                      JSON.parse(res.data.default_bundle_files.permissions).is_enable_location_permission == true
                      ? true
                      : false
                    : false
                    ,
                location_permission_description:
                    res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files &&  res.data.already_have_live_files.permissions 
                    ?
                      JSON.parse(res.data.already_have_live_files.permissions).is_enable_location_permission == true ||
                      JSON.parse(res.data.already_have_live_files.permissions).is_enable_location_permission == 1
                        ? JSON.parse(res.data.already_have_live_files.permissions).location_permission_description
                        : ""
                    :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files && res.data.custom_bundle_files.permissions ?
                       
                        JSON.parse(res.data.custom_bundle_files.permissions).is_enable_location_permission == 1 ||
                        JSON.parse(res.data.custom_bundle_files.permissions).is_enable_location_permission == true
                        ? JSON.parse(res.data.custom_bundle_files.permissions).location_permission_description
                        : ""
                    :  res.data.custom_status== 0 && res.data.is_app_already_published == 0  && res.data.default_bundle_files && res.data.default_bundle_files.permissions ?
                     
                      JSON.parse(res.data.default_bundle_files.permissions).is_enable_location_permission == 1 ||
                      JSON.parse(res.data.default_bundle_files.permissions).is_enable_location_permission == true
                      ? JSON.parse(res.data.default_bundle_files.permissions).location_permission_description
                      : ""
                    : "",
                is_enable_calender_permission:
                    res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files &&  res.data.already_have_live_files.permissions 
                    ?
                      JSON.parse(res.data.already_have_live_files.permissions).is_enable_calender_permission == true ||
                      JSON.parse(res.data.already_have_live_files.permissions).is_enable_calender_permission == 1
                      ? true
                      : false
                    :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files && res.data.custom_bundle_files.permissions
                    ?
                      
                        JSON.parse(res.data.custom_bundle_files.permissions).is_enable_calender_permission == 1 ||
                        JSON.parse(res.data.custom_bundle_files.permissions).is_enable_calender_permission == true
                        ? true
                        : false
                    :  res.data.custom_status== 0 && res.data.is_app_already_published == 0  && res.data.default_bundle_files && res.data.default_bundle_files.permissions 
                    ?
                     
                      JSON.parse(res.data.default_bundle_files.permissions).is_enable_calender_permission == 1 ||
                      JSON.parse(res.data.default_bundle_files.permissions).is_enable_calender_permission == true
                      ? true
                      : false
                    : false,
                calender_permission_description:
                        res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files &&  res.data.already_have_live_files.permissions 
                        ?
                          JSON.parse(res.data.already_have_live_files.permissions).is_enable_calender_permission == true ||
                          JSON.parse(res.data.already_have_live_files.permissions).is_enable_calender_permission == 1
                            ? JSON.parse(res.data.already_have_live_files.permissions).calender_permission_description
                            : ""
                        :  res.data.custom_status == 1 &&  res.data.is_app_already_published == 0  && res.data.custom_bundle_files && res.data.custom_bundle_files.permissions
                        ? 
                            JSON.parse(res.data.custom_bundle_files.permissions).is_enable_calender_permission == 1 ||
                            JSON.parse(res.data.custom_bundle_files.permissions).is_enable_calender_permission == true
                            ? JSON.parse(res.data.custom_bundle_files.permissions).calender_permission_description
                            : ""
                        :  res.data.custom_status == 0 &&  res.data.is_app_already_published == 0  && res.data.default_bundle_files && res.data.default_bundle_files.permissions
                        ? 
                          JSON.parse(res.data.default_bundle_files.permissions).is_enable_calender_permission == 1 ||
                          JSON.parse(res.data.default_bundle_files.permissions).is_enable_calender_permission == true
                          ? JSON.parse(res.data.default_bundle_files.permissions).calender_permission_description
                          : ""
                        : "",
                is_enable_microphone_permission:
                        res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files &&  res.data.already_have_live_files.permissions 
                        ?
                          JSON.parse(res.data.already_have_live_files.permissions).is_enable_microphone_permission == true ||
                          JSON.parse(res.data.already_have_live_files.permissions).is_enable_microphone_permission == 1
                            ? true
                            : false
                        :  res.data.custom_status ==  1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files && res.data.custom_bundle_files.permissions  
                        ?
                           
                            JSON.parse(res.data.custom_bundle_files.permissions).is_enable_microphone_permission == 1 ||
                            JSON.parse(res.data.custom_bundle_files.permissions).is_enable_microphone_permission == true
                            ? true
                            : false
                        :  res.data.custom_status ==  0 && res.data.is_app_already_published == 0  && res.data.default_bundle_files && res.data.default_bundle_files.permissions ?
                         
                          JSON.parse(res.data.default_bundle_files.permissions).is_enable_microphone_permission == 1 ||
                          JSON.parse(res.data.default_bundle_files.permissions).is_enable_microphone_permission == true
                          ? true
                          : false
                        : false,
                microphone_permission_description:
                        res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files &&  res.data.already_have_live_files.permissions 
                        ?
                          JSON.parse(res.data.already_have_live_files.permissions).is_enable_microphone_permission == true ||
                          JSON.parse(res.data.already_have_live_files.permissions).is_enable_microphone_permission == 1
                            ? JSON.parse(res.data.already_have_live_files.permissions).microphone_permission_description
                            : ""
                        :  res.data.custom_status== 1 &&  res.data.is_app_already_published == 0  && res.data.custom_bundle_files && res.data.custom_bundle_files.permissions ?
                           
                            JSON.parse(res.data.custom_bundle_files.permissions).is_enable_microphone_permission == 1 ||
                            JSON.parse(res.data.custom_bundle_files.permissions).is_enable_microphone_permission == true
                            ? JSON.parse(res.data.custom_bundle_files.permissions).microphone_permission_description
                            : ""
                        :  res.data.custom_status== 0 &&  res.data.is_app_already_published == 0  && res.data.default_bundle_files && res.data.default_bundle_files.permissions ?
                         
                          JSON.parse(res.data.default_bundle_files.permissions).is_enable_microphone_permission == 1 ||
                          JSON.parse(res.data.default_bundle_files.permissions).is_enable_microphone_permission == true
                          ? JSON.parse(res.data.default_bundle_files.permissions).microphone_permission_description
                          : ""
                        : "",
                is_enable_reminder_permission:
                      res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files &&  res.data.already_have_live_files.permissions  
                      ?
                        JSON.parse(res.data.already_have_live_files.permissions).is_enable_reminder_permission == true ||
                        JSON.parse(res.data.already_have_live_files.permissions).is_enable_reminder_permission == 1
                          ? true
                          : false
                      :  res.data.custom_status==1 &&  res.data.is_app_already_published == 0  && res.data.custom_bundle_files && res.data.custom_bundle_files.permissions ?
                         
                          JSON.parse(res.data.custom_bundle_files.permissions).is_enable_reminder_permission == 1 ||
                          JSON.parse(res.data.custom_bundle_files.permissions).is_enable_reminder_permission == true
                          ? true
                          : false
                      :  res.data.custom_status==0 &&  res.data.is_app_already_published == 0  && res.data.default_bundle_files && res.data.default_bundle_files.permissions ?
                       
                        JSON.parse(res.data.default_bundle_files.permissions).is_enable_reminder_permission == 1 ||
                        JSON.parse(res.data.default_bundle_files.permissions).is_enable_reminder_permission == true
                        ? true
                        : false
                      : false,
                reminder_permission_description:
                      res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files &&  res.data.already_have_live_files.permissions 
                      ?
                        JSON.parse(res.data.already_have_live_files.permissions).is_enable_reminder_permission == true ||
                        JSON.parse(res.data.already_have_live_files.permissions).is_enable_reminder_permission == 1
                          ? JSON.parse(res.data.already_have_live_files.permissions).reminder_permission_description
                          : ""
                      :  res.data.custom_status==1 &&  res.data.is_app_already_published == 0  && res.data.custom_bundle_files && res.data.custom_bundle_files.permissions ?
                       
                          JSON.parse(res.data.custom_bundle_files.permissions).is_enable_reminder_permission == 1 ||
                          JSON.parse(res.data.custom_bundle_files.permissions).is_enable_reminder_permission == true
                          ? JSON.parse(res.data.custom_bundle_files.permissions).reminder_permission_description
                          : ""
                      :  res.data.custom_status==0 &&  res.data.is_app_already_published == 0  && res.data.default_bundle_files && res.data.default_bundle_files.permissions ?
                       
                        JSON.parse(res.data.default_bundle_files.permissions).is_enable_reminder_permission == 1 ||
                        JSON.parse(res.data.default_bundle_files.permissions).is_enable_reminder_permission == true
                        ? JSON.parse(res.data.default_bundle_files.permissions).reminder_permission_description
                        : ""
                      : "",
                is_enable_speech_recognition_permission:
                      res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files &&  res.data.already_have_live_files.permissions 
                      ?
                        JSON.parse(res.data.already_have_live_files.permissions).is_enable_speech_recognition_permission == true ||
                        JSON.parse(res.data.already_have_live_files.permissions).is_enable_speech_recognition_permission == 1
                          ? true
                          : false
                      :  res.data.custom_status==1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files && res.data.custom_bundle_files.permissions ?
                          JSON.parse(res.data.custom_bundle_files.permissions).is_enable_speech_recognition_permission == 1 ||
                          JSON.parse(res.data.custom_bundle_files.permissions).is_enable_speech_recognition_permission == true
                          ? true
                          : false
                      :  res.data.custom_status==0 && res.data.is_app_already_published == 0  && res.data.default_bundle_files && res.data.default_bundle_files.permissions ?
                        JSON.parse(res.data.default_bundle_files.permissions).is_enable_speech_recognition_permission == 1 ||
                        JSON.parse(res.data.default_bundle_files.permissions).is_enable_speech_recognition_permission == true
                        ? true
                        : false
                      : false,
                speech_recognition_permission_description:
                      res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files &&  res.data.already_have_live_files.permissions 
                      ?
                        JSON.parse(res.data.already_have_live_files.permissions).is_enable_speech_recognition_permission == true ||
                        JSON.parse(res.data.already_have_live_files.permissions).is_enable_speech_recognition_permission == 1
                          ? JSON.parse(res.data.already_have_live_files.permissions).speech_recognition_permission_description
                          : ""
                      :  res.data.custom_status==1 &&  res.data.is_app_already_published == 0  && res.data.custom_bundle_files && res.data.custom_bundle_files.permissions ?
                          JSON.parse(res.data.custom_bundle_files.permissions).is_enable_speech_recognition_permission == 1 ||
                          JSON.parse(res.data.custom_bundle_files.permissions).is_enable_speech_recognition_permission == true
                          ? JSON.parse(res.data.custom_bundle_files.permissions).speech_recognition_permission_description
                          : ""
                      :  res.data.custom_status==0 &&  res.data.is_app_already_published == 0  && res.data.default_bundle_files && res.data.default_bundle_files.permissions ?
                        JSON.parse(res.data.default_bundle_files.permissions).is_enable_speech_recognition_permission == 1 ||
                        JSON.parse(res.data.default_bundle_files.permissions).is_enable_speech_recognition_permission == true
                        ? JSON.parse(res.data.default_bundle_files.permissions).speech_recognition_permission_description
                        : ""
                      : "",
                is_enable_camera_and_photo_library_permission:
                      res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files &&  res.data.already_have_live_files.permissions 
                      ?
                        JSON.parse(res.data.already_have_live_files.permissions).is_enable_camera_and_photo_library_permission == 1 ||
                        (res.data.app_data.chat_preferences !== null && res.data.app_data.chat_preferences.some((res) => res.module_status == 1))
                          ? true
                          : false
                      :  res.data.custom_status==1 &&  res.data.is_app_already_published == 0  && res.data.custom_bundle_files && res.data.custom_bundle_files.permissions ?
                        JSON.parse(res.data.custom_bundle_files.permissions).is_enable_camera_and_photo_library_permission == 1 ||
                        (res.data.app_data.chat_preferences !== null && res.data.app_data.chat_preferences.some((res) => res.module_status == 1))
                          ? true
                          : false
                      :  res.data.custom_status==0 &&  res.data.is_app_already_published == 0  && res.data.default_bundle_files && res.data.default_bundle_files.permissions ?
                      JSON.parse(res.data.default_bundle_files.permissions).is_enable_camera_and_photo_library_permission == 1 ||
                      (res.data.app_data.chat_preferences !== null && res.data.app_data.chat_preferences.some((res) => res.module_status == 1))
                        ? true
                        : false
                      : false,
                camera_and_photo_library_permission_description:
                    res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files &&  res.data.already_have_live_files.permissions 
                    ?
                      JSON.parse(res.data.already_have_live_files.permissions).is_enable_camera_and_photo_library_permission == true ||
                      JSON.parse(res.data.already_have_live_files.permissions).is_enable_camera_and_photo_library_permission == 1
                        ? JSON.parse(res.data.already_have_live_files.permissions).camera_and_photo_library_permission_description
                        : ""
                    :  res.data.custom_status== 1 &&  res.data.is_app_already_published == 0  && res.data.custom_bundle_files && res.data.custom_bundle_files.permissions ?
                        JSON.parse(res.data.custom_bundle_files.permissions).is_enable_camera_and_photo_library_permission == 1 ||
                        JSON.parse(res.data.custom_bundle_files.permissions).is_enable_camera_and_photo_library_permission == true
                        ? JSON.parse(res.data.custom_bundle_files.permissions).camera_and_photo_library_permission_description
                        : ""
                    :  res.data.custom_status== 0 &&  res.data.is_app_already_published == 0  && res.data.default_bundle_files && res.data.default_bundle_files.permissions ?
                      JSON.parse(res.data.default_bundle_files.permissions).is_enable_camera_and_photo_library_permission == 1 ||
                      JSON.parse(res.data.default_bundle_files.permissions).is_enable_camera_and_photo_library_permission == true
                      ? JSON.parse(res.data.default_bundle_files.permissions).camera_and_photo_library_permission_description
                      : ""
                    : "",
                is_enable_contacts_permission:
                    res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files &&  res.data.already_have_live_files.permissions 
                    ?
                      JSON.parse(res.data.already_have_live_files.permissions).is_enable_contacts_permission == true ||
                      JSON.parse(res.data.already_have_live_files.permissions).is_enable_contacts_permission == 1
                        ? true
                        : false
                    :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files && res.data.custom_bundle_files.permissions ?
                        JSON.parse(res.data.custom_bundle_files.permissions).is_enable_contacts_permission == 1 ||
                        JSON.parse(res.data.custom_bundle_files.permissions).is_enable_contacts_permission == true
                        ? true
                        : false
                    :  res.data.custom_status== 0 && res.data.is_app_already_published == 0  && res.data.default_bundle_files && res.data.default_bundle_files.permissions ?
                      JSON.parse(res.data.default_bundle_files.permissions).is_enable_contacts_permission == 1 ||
                      JSON.parse(res.data.default_bundle_files.permissions).is_enable_contacts_permission == true
                      ? true
                      : false
                    : false,
                contacts_permission_description:
                    res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files &&  res.data.already_have_live_files.permissions 
                    ?
                      JSON.parse(res.data.already_have_live_files.permissions).is_enable_contacts_permission == true ||
                      JSON.parse(res.data.already_have_live_files.permissions).is_enable_contacts_permission == 1
                        ? JSON.parse(res.data.already_have_live_files.permissions).contacts_permission_description
                        : ""
                    :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files && res.data.custom_bundle_files.permissions ?
                        JSON.parse(res.data.custom_bundle_files.permissions).is_enable_contacts_permission == 1 ||
                        JSON.parse(res.data.custom_bundle_files.permissions).is_enable_contacts_permission == true
                        ? JSON.parse(res.data.custom_bundle_files.permissions).contacts_permission_description
                        : ""
                    :  res.data.custom_status== 0 && res.data.is_app_already_published == 0  && res.data.default_bundle_files && res.data.default_bundle_files.permissions ?
                      JSON.parse(res.data.default_bundle_files.permissions).is_enable_contacts_permission == 1 ||
                      JSON.parse(res.data.default_bundle_files.permissions).is_enable_contacts_permission == true
                      ? JSON.parse(res.data.default_bundle_files.permissions).contacts_permission_description
                      : ""
                    : "",
                is_enable_app_tracking_permission:
                    res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files &&  res.data.already_have_live_files.permissions 
                    ?
                      JSON.parse(res.data.already_have_live_files.permissions).is_enable_app_tracking_permission == true ||
                      JSON.parse(res.data.already_have_live_files.permissions).is_enable_app_tracking_permission == 1
                        ? true
                        : false
                    :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files && res.data.custom_bundle_files.permissions ?
                        JSON.parse(res.data.custom_bundle_files.permissions).is_enable_app_tracking_permission == 1 ||
                        JSON.parse(res.data.custom_bundle_files.permissions).is_enable_app_tracking_permission == true
                        ? true
                        : false
                    :  res.data.custom_status== 0 && res.data.is_app_already_published == 0  && res.data.default_bundle_files && res.data.default_bundle_files.permissions ?
                      JSON.parse(res.data.default_bundle_files.permissions).is_enable_app_tracking_permission == 1 ||
                      JSON.parse(res.data.default_bundle_files.permissions).is_enable_app_tracking_permission == true
                      ? true
                      : false
                    : false,
                app_tracking_permission_description:
                    res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files &&  res.data.already_have_live_files.permissions 
                    ?
                      JSON.parse(res.data.already_have_live_files.permissions).is_enable_app_tracking_permission == true ||
                      JSON.parse(res.data.already_have_live_files.permissions).is_enable_app_tracking_permission == 1
                        ? JSON.parse(res.data.already_have_live_files.permissions).app_tracking_permission_description
                        : ""
                    :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files && res.data.custom_bundle_files.permissions
                    ?
                        JSON.parse(res.data.custom_bundle_files.permissions).is_enable_app_tracking_permission == 1 ||
                        JSON.parse(res.data.custom_bundle_files.permissions).is_enable_app_tracking_permission == true
                        ? JSON.parse(res.data.custom_bundle_files.permissions).app_tracking_permission_description
                        : ""
                    :  res.data.custom_status== 0 && res.data.is_app_already_published == 0  && res.data.default_bundle_files && res.data.default_bundle_files.permissions
                    ?
                      JSON.parse(res.data.default_bundle_files.permissions).is_enable_app_tracking_permission == 1 ||
                      JSON.parse(res.data.default_bundle_files.permissions).is_enable_app_tracking_permission == true
                      ? JSON.parse(res.data.default_bundle_files.permissions).app_tracking_permission_description
                      : ""
                    : ""
                      ,
                is_enable_keychain:
                      res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files  
                    ? 
                      res.data.custom_bundle_files.is_enable_keychain == 1
                      ? true
                      : false
                    : res.data.custom_status == 1 && res.data.is_app_already_published == 0 && res.data.custom_bundle_files ?
                      res.data.custom_bundle_files.is_enable_keychain == 1
                      ? true
                      : false
                    : res.data.custom_status == 0 && res.data.is_app_already_published == 0 && res.data.default_bundle_files ?
                      res.data.default_bundle_files.is_enable_keychain == 1
                      ? true
                      : false
                    : '',
                keychain_url:
                      res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files 
                      ?
                         res.data.already_have_live_files.keychain_url                         
                      :  res.data.custom_status == 1 && res.data.is_app_already_published == 0 && res.data.custom_bundle_files
                      ? 
                        res.data.custom_bundle_files.keychain_url
                      : res.data.custom_status == 0 && res.data.is_app_already_published == 0 && res.data.default_bundle_files?
                        res.data.default_bundle_files.keychain_url
                      : '',
                is_notification_enabled :
                      res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files 
                    ?
                      res.data.already_have_live_files != '' && res.data.already_have_live_files != undefined
                      ? res.data.already_have_live_files.is_notification_enabled
                      : 0
                    :  res.data.custom_status == 1 && res.data.is_app_already_published == 0 && res.data.custom_bundle_files
                    ?
                      res.data.custom_bundle_files != '' && res.data.custom_bundle_files != undefined
                      ? res.data.custom_bundle_files.is_notification_enabled
                      : 0
                    :  res.data.custom_status == 0 && res.data.is_app_already_published == 0 && res.data.default_bundle_files
                      ?
                        res.data.default_bundle_files != '' && res.data.default_bundle_files != undefined
                        ? res.data.default_bundle_files.is_notification_enabled
                        : 0
                    : 0,
                is_rich_notification_enabled :
                    res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files 
                  ?
                    res.data.already_have_live_files != '' && res.data.already_have_live_files != undefined
                    ? res.data.already_have_live_files.is_rich_notification_enabled
                    : 0
                  :  res.data.custom_status == 1 && res.data.is_app_already_published == 0 && res.data.custom_bundle_files
                  ?
                    res.data.custom_bundle_files != '' && res.data.custom_bundle_files != undefined
                    ? res.data.custom_bundle_files.is_rich_notification_enabled
                    : 0
                  :  res.data.custom_status == 0 && res.data.is_app_already_published == 0 && res.data.default_bundle_files
                    ?
                      res.data.default_bundle_files != '' && res.data.default_bundle_files != undefined
                      ? res.data.default_bundle_files.is_rich_notification_enabled
                      : 0
                  : 0,
                is_admob_enabled :
                    res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files 
                    ?
                      res.data.already_have_live_files != '' && res.data.already_have_live_files != undefined
                      ? res.data.already_have_live_files.is_admob_enabled
                      : 0
                    :  res.data.custom_status == 1 && res.data.is_app_already_published == 0 && res.data.custom_bundle_files
                    ?
                      res.data.custom_bundle_files != '' && res.data.custom_bundle_files != undefined
                      ? res.data.custom_bundle_files.is_admob_enabled
                      : 0
                    :  res.data.custom_status == 0 && res.data.is_app_already_published == 0 && res.data.default_bundle_files
                      ?
                        res.data.default_bundle_files != '' && res.data.default_bundle_files != undefined
                        ? res.data.default_bundle_files.is_admob_enabled
                        : 0
                    : 0,
                  admob_app_id:
                    res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files
                    ? res.data.already_have_live_files.admob_app_id
                    : res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files 
                    ? res.data.custom_bundle_files.admob_app_id
                    : res.data.custom_status== 0 && res.data.is_app_already_published == 0  && res.data.default_bundle_files ?
                      res.data.default_bundle_files.admob_app_id
                    : ''
                    ,
                is_google_login_enabled :
                
                  res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files 
                    ?
                      res.data.already_have_live_files != '' && res.data.already_have_live_files != undefined
                      ? res.data.already_have_live_files.is_google_login_enabled
                      : 0
                    :  res.data.custom_status == 1 && res.data.is_app_already_published == 0 && res.data.custom_bundle_files
                    ?
                      res.data.custom_bundle_files != '' && res.data.custom_bundle_files != undefined
                      ? res.data.custom_bundle_files.is_google_login_enabled
                      : 0
                    :  res.data.custom_status == 0 && res.data.is_app_already_published == 0 && res.data.default_bundle_files
                      ?
                        res.data.default_bundle_files != '' && res.data.default_bundle_files != undefined
                        ? res.data.default_bundle_files.is_google_login_enabled
                        : 0
                    : 0,
              });

            } 
            else 
            {
              state.setState({
                modalspinner: false,
                bundle_identifier:
                res.data.current_bundle_identifier === undefined ||
                res.data.current_bundle_identifier === ""
                  ? "com.appmysite.app"
                  : res.data.current_bundle_identifier, 
                teamid: "",
                spinner:false,
                chat_preferences:
                res.data.app_data.chat_preferences === undefined
                  ? null
                  : res.data.app_data.chat_preferences,
                certificates3name: "",
                certificatefilename: ".p12 file supported ",
                pushnotificationfilename: ".p12 file supported ",
                pushnotifications3name: "",
                provision2filename: ".mobileprovision file supported ",
                provision2s3name: "",
                pushtoggle: false,
                google_enable: false,
                is_supporting_ipad: false,
                certificate_password: "",
                ios_admob_app_id: "",
                ios_is_admob_enabled: false,
                is_enable_location_permission: false,
                build_process: res.data.build_process,
                //permission
                location_permission_description: "",
                is_enable_calender_permission: false,
                calender_permission_description: "",
                is_enable_microphone_permission: false,
                microphone_permission_description: "",
                is_enable_reminder_permission: false,
                reminder_permission_description: "",
                is_enable_speech_recognition_permission: false,
                speech_recognition_permission_description: "",
                is_enable_camera_and_photo_library_permission:
                (res.data.app_data.chat_preferences !== null && res.data.app_data.chat_preferences.some((res) => res.module_status == 1)) ? true : false,
                camera_and_photo_library_permission_description: "",
                is_enable_contacts_permission: false,
                contacts_permission_description: "",
                is_enable_app_tracking_permission: false,
                app_tracking_permission_description: "",
              });
            }
          } 
        
         if(res.data && callback){
          state.setState({
            genErrDescription : ( res.data.custom_status === 1 || res.data.custom_status === true )
            ?( res.data.custom_bundle_files && res.data.custom_bundle_files.description ) ? res.data.custom_bundle_files.description :""
            :( res.data.default_bundle_files && res.data.default_bundle_files.description ) ? res.data.default_bundle_files.description:"" ,

            certificatValidityDat : ( res.data.custom_status === 1 || res.data.custom_status === true )
            ?( res.data.custom_bundle_files && res.data.custom_bundle_files.certificate_expiry_date ) ? res.data.custom_bundle_files.certificate_expiry_date:"" 
            : ( res.data.default_bundle_files && res.data.default_bundle_files.certificate_expiry_date ) ? res.data.default_bundle_files.certificate_expiry_date :"" ,

            current_process_status :  ( res.data.custom_status === 1 || res.data.custom_status === true )
             ?(res.data.custom_bundle_files && res.data.custom_bundle_files.current_process_status ) ? res.data.custom_bundle_files.current_process_status:0 
             :(res.data.default_bundle_files && res.data.default_bundle_files.current_process_status) ?res.data.default_bundle_files.current_process_status:0 ,
          })
          setTimeout(()=>{
           callback( 
            (res.data.custom_bundle_files && res.data.custom_bundle_files.current_process_status ) ? res.data.custom_bundle_files.current_process_status:0 ,
            (res.data.default_bundle_files && res.data.default_bundle_files.current_process_status) ? res.data.default_bundle_files.current_process_status:0
          );
          },2000)
         }
          
        } 
       
      }
    })
    .catch((error) => 
    {
      
      state.setState({saveclass:"d-none",buttonsaveclass:"d-block"});
    });
};

function getUpdatedAtTimeStamp(data){
  if(data.is_app_already_published){
      return data.already_have_live_files?.updated_at
  } else if(data.custom_status && !data.is_app_already_published  ){
      return data.custom_bundle_files?.updated_at
  } else{
      return data.default_bundle_files?.updated_at
  }
}
// new custom bundle api
export const newgetFormData = (params, state, callback) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(
      sendurl +
        "get-custom-bundle/" +
        btoa(localStorage.getItem("Appid")) +
        "/" +
        params.type,
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) 
      {

        if (params.type === "android") 
        {
          
          if (res.data !== null) 
          {

            if(res.data.build_process == 1)
            {
              window.location.href = "/app/download";
            }
            state.setState({
              androiddata : res.data,
              updatedAt:getUpdatedAtTimeStamp(res.data),
              bundle_id: 
                         res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                         res.data.already_have_live_files.bundle_id
                      :  res.data.custom_status == 1 && res.data.is_app_already_published == 0 && res.data.custom_bundle_files?
                         res.data.custom_bundle_files.bundle_id
                      :  res.data.custom_status == 0 && res.data.is_app_already_published == 0 && res.data.default_bundle_files?
                         res.data.default_bundle_files.bundle_id
                      :  ''
                      ,
              old_bundle_id: 
                         res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                         res.data.already_have_live_files.bundle_id
                      :  res.data.custom_status == 1 && res.data.is_app_already_published == 0 && res.data.custom_bundle_files?
                         res.data.custom_bundle_files.bundle_id
                      :  res.data.custom_status == 0 && res.data.is_app_already_published == 0 && res.data.default_bundle_files?
                         res.data.default_bundle_files.bundle_id
                      :  ''
                      ,
              bundle_android_identifier:res.data.current_bundle_identifier,
              is_app_already_published: res.data.custom_status == 0 ? false : res.data.is_app_already_published == 1 ? true : false,
              current_version_code: 
                         res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                         res.data.already_have_live_files.version
                      :  res.data.custom_status == 1 && res.data.is_app_already_published == 0 && res.data.custom_bundle_files?
                         res.data.custom_bundle_files.version
                      :  res.data.custom_status == 0 && res.data.is_app_already_published == 0 && res.data.default_bundle_files?
                         res.data.default_bundle_files.version
                      : ''
                      ,   
              old_current_version_code: 
                  res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                  res.data.already_have_live_files.version
              :  res.data.custom_status == 1 && res.data.is_app_already_published == 0 && res.data.custom_bundle_files?
                  res.data.custom_bundle_files.version
              :  res.data.custom_status == 0 && res.data.is_app_already_published == 0 && res.data.default_bundle_files?
                  res.data.default_bundle_files.version
              : ''
              ,                    
              current_version_name:  
                    res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                    res.data.already_have_live_files.current_version
                  :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files?
                    res.data.custom_bundle_files.current_version
                  :  res.data.custom_status == 0 && res.data.is_app_already_published == 0 && res.data.default_bundle_files?
                    res.data.default_bundle_files.current_version
                  : ''
                  ,
              old_current_version_name:  
                    res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                    res.data.already_have_live_files.current_version
                  :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files?
                    res.data.custom_bundle_files.current_version
                  :  res.data.custom_status == 0 && res.data.is_app_already_published == 0 && res.data.default_bundle_files?
                    res.data.default_bundle_files.current_version
                  : ''
                  ,
              status: res.data.custom_status == 1 ? true : false,
              custompackage:res.data.custom_status === 1 || res.data.custom_status == true ? true:false,
              is_success: 
                      res.data.is_success !== 0 || res.data.build_process !== 0
                        ? 1
                        : 0,
              resetbuttonandroid: res.data.is_success,
              disabledsubmitbutton: res.data.build_process !== 0 ? true : false,
              spinner: false,
              keystore_alias:
                    res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                    res.data.already_have_live_files.keystore_alias_name
                  :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files?
                    res.data.custom_bundle_files.keystore_alias_name
                  :  res.data.custom_status== 0 && res.data.is_app_already_published == 0  && res.data.default_bundle_files?
                    res.data.default_bundle_files.keystore_alias_name
                  : ''
                  ,
              keystore_password: 
                    res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                    res.data.already_have_live_files.keystore_password
                  :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files?
                    res.data.custom_bundle_files.keystore_password
                  :  res.data.custom_status == 0 && res.data.is_app_already_published == 0  && res.data.default_bundle_files?
                    res.data.default_bundle_files.keystore_password
                  : ''
                  ,
              keystore_private_key_password:
              res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                    res.data.already_have_live_files.keystore_private_key_password
                  :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files?
                    res.data.custom_bundle_files.keystore_private_key_password
                  :  res.data.custom_status== 0 && res.data.is_app_already_published == 0  && res.data.default_bundle_files?
                    res.data.default_bundle_files.keystore_private_key_password
                  : ''
                  ,
              jks_filename:
                  res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                    res.data.already_have_live_files.jks_file
                  :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files?
                    res.data.custom_bundle_files.jks_file
                  :  res.data.custom_status== 0 && res.data.is_app_already_published == 0  && res.data.default_bundle_files?
                    res.data.default_bundle_files.jks_file
                  : ''
                  ,
              getjksfilenameAWS:
                  res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                    res.data.already_have_live_files.jks_file
                  :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files?
                    res.data.custom_bundle_files.jks_file
                  :  res.data.custom_status== 0 && res.data.is_app_already_published == 0  && res.data.default_bundle_files?
                    res.data.default_bundle_files.jks_file
                  : ''
                  ,
              is_enable_foreground_location_permission:
                  res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files && res.data.already_have_live_files.permissions ?
                  res.data.already_have_live_files ? JSON.parse(res.data.already_have_live_files.permissions).is_enable_foreground_location_permission : 0
                  :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files && res.data.custom_bundle_files.permissions ?
                    res.data.custom_bundle_files ? JSON.parse(res.data.custom_bundle_files.permissions).is_enable_foreground_location_permission : 0
                  :  res.data.custom_status== 0 && res.data.is_app_already_published == 0  && res.data.default_bundle_files && res.data.default_bundle_files.permissions ?
                    res.data.default_bundle_files ? JSON.parse(res.data.default_bundle_files.permissions).is_enable_foreground_location_permission : 0
                  : ''
                  ,
              
              is_enable_background_location_permission :
                  res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files && res.data.already_have_live_files.permissions ?
                  res.data.already_have_live_files ? JSON.parse(res.data.already_have_live_files.permissions).is_enable_background_location_permission : 0
                  :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files && res.data.custom_bundle_files.permissions ?
                    res.data.custom_bundle_files ? JSON.parse(res.data.custom_bundle_files.permissions).is_enable_background_location_permission : 0
                  :  res.data.custom_status== 0 && res.data.is_app_already_published == 0  && res.data.default_bundle_files && res.data.default_bundle_files.permissions ?
                    res.data.default_bundle_files ? JSON.parse(res.data.default_bundle_files.permissions).is_enable_background_location_permission : 0
                  : ''
                  ,
              is_enable_camera_permission :
                  res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files && res.data.already_have_live_files.permissions ?
                  res.data.already_have_live_files ? JSON.parse(res.data.already_have_live_files.permissions).is_enable_camera_permission : 0
                  :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files && res.data.custom_bundle_files.permissions ?
                    res.data.custom_bundle_files ? JSON.parse(res.data.custom_bundle_files.permissions).is_enable_camera_permission : 0
                  :  res.data.custom_status== 0 && res.data.is_app_already_published == 0  && res.data.default_bundle_files && res.data.default_bundle_files.permissions ?
                    res.data.default_bundle_files ? JSON.parse(res.data.default_bundle_files.permissions).is_enable_camera_permission : 0
                  : ''
                  ,
              is_enable_photos_permission :
                  res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files && res.data.already_have_live_files.permissions ?
                  res.data.already_have_live_files ? JSON.parse(res.data.already_have_live_files.permissions).is_enable_photos_permission : 0
                  :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files && res.data.custom_bundle_files.permissions ?
                    res.data.custom_bundle_files ? JSON.parse(res.data.custom_bundle_files.permissions).is_enable_photos_permission : 0
                  :  res.data.custom_status== 0 && res.data.is_app_already_published == 0  && res.data.default_bundle_files && res.data.default_bundle_files.permissions ?
                    res.data.default_bundle_files ? JSON.parse(res.data.default_bundle_files.permissions).is_enable_photos_permission : 0
                  : ''
                  ,
              is_enable_videos_permission :
                  res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files && res.data.already_have_live_files.permissions ?
                  res.data.already_have_live_files ? JSON.parse(res.data.already_have_live_files.permissions).is_enable_videos_permission : 0
                  :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files && res.data.custom_bundle_files.permissions ?
                    res.data.custom_bundle_files ? JSON.parse(res.data.custom_bundle_files.permissions).is_enable_videos_permission : 0
                  :  res.data.custom_status== 0 && res.data.is_app_already_published == 0  && res.data.default_bundle_files && res.data.default_bundle_files.permissions ?
                    res.data.default_bundle_files ? JSON.parse(res.data.default_bundle_files.permissions).is_enable_videos_permission : 0
                  : ''
                  ,
              is_enable_microphone_permission :
                  res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files && res.data.already_have_live_files.permissions ?
                  res.data.already_have_live_files ? JSON.parse(res.data.already_have_live_files.permissions).is_enable_microphone_permission : 0
                  :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files && res.data.custom_bundle_files.permissions ?
                    res.data.custom_bundle_files ? JSON.parse(res.data.custom_bundle_files.permissions).is_enable_microphone_permission : 0
                  :  res.data.custom_status== 0 && res.data.is_app_already_published == 0  && res.data.default_bundle_files && res.data.default_bundle_files.permissions ?
                    res.data.default_bundle_files ? JSON.parse(res.data.default_bundle_files.permissions).is_enable_microphone_permission : 0
                  : ''
                  ,
              is_enable_contacts_permission :
                  res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files && res.data.already_have_live_files.permissions ?
                  res.data.already_have_live_files ? JSON.parse(res.data.already_have_live_files.permissions).is_enable_contacts_permission : 0
                  :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files && res.data.custom_bundle_files.permissions ?
                    res.data.custom_bundle_files ? JSON.parse(res.data.custom_bundle_files.permissions).is_enable_contacts_permission : 0
                  :  res.data.custom_status== 0 && res.data.is_app_already_published == 0  && res.data.default_bundle_files && res.data.default_bundle_files.permissions ?
                    res.data.default_bundle_files ? JSON.parse(res.data.default_bundle_files.permissions).is_enable_contacts_permission : 0
                  : ''
                  ,
              is_notification_enabled :
                  res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files  ?
                  res.data.already_have_live_files ? res.data.already_have_live_files.is_notification_enabled : 0
                  :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files  ?
                    res.data.custom_bundle_files ? res.data.custom_bundle_files.is_notification_enabled : 0
                  :  res.data.custom_status== 0 && res.data.is_app_already_published == 0  && res.data.default_bundle_files  ?
                    res.data.default_bundle_files ? res.data.default_bundle_files.is_notification_enabled : 0
                  : ''
                  ,
              is_rich_notification_enabled :
                res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files  ?
                res.data.already_have_live_files ? res.data.already_have_live_files.is_rich_notification_enabled : 0
                :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files  ?
                  res.data.custom_bundle_files ? res.data.custom_bundle_files.is_rich_notification_enabled : 0
                :  res.data.custom_status== 0 && res.data.is_app_already_published == 0  && res.data.default_bundle_files  ?
                  res.data.default_bundle_files ? res.data.default_bundle_files.is_rich_notification_enabled : 0
                : ''
                ,
              is_admob_enabled :
                  res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files  ?
                  res.data.already_have_live_files ? res.data.already_have_live_files.is_admob_enabled : 0
                  :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files  ?
                    res.data.custom_bundle_files ? res.data.custom_bundle_files.is_admob_enabled : 0
                  :  res.data.custom_status== 0 && res.data.is_app_already_published == 0  && res.data.default_bundle_files  ?
                    res.data.default_bundle_files ? res.data.default_bundle_files.is_admob_enabled : 0
                  : ''
                  ,
              is_google_login_enabled :
                  res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files?
                  res.data.already_have_live_files ? res.data.already_have_live_files.is_google_login_enabled : 0
                  :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files ?
                    res.data.custom_bundle_files ? res.data.custom_bundle_files.is_google_login_enabled : 0
                  :  res.data.custom_status== 0 && res.data.is_app_already_published == 0  && res.data.default_bundle_files ?
                    res.data.default_bundle_files ? res.data.default_bundle_files.is_google_login_enabled : 0
                  : ''
                  ,
              notification_config_file :
                    res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                    res.data.already_have_live_files.notification_config_file
                  :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files?
                    res.data.custom_bundle_files.notification_config_file
                  :  res.data.custom_status== 0 && res.data.is_app_already_published == 0  && res.data.default_bundle_files?
                    res.data.default_bundle_files.notification_config_file
                  : ''
                  ,

            });
          }
          else 
          {
            if(res.data  && res.data != '' && res.data != undefined)
            {
              state.setState({ is_success: res.data.is_success !== 0 || res.data.build_process !== 0
                              ? 1
                              : 0,
              resetbuttonandroid: res.data.is_success,
              bundle_android_identifier:res.data.current_bundle_identifier,
              is_app_already_published: res.data.is_app_already_published === 1 ? true : false,
              disabledsubmitbutton: res.data.build_process !== 0 ? true : false,
              spinner: false,
              keystore_alias:
                    res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                    res.data.already_have_live_files.keystore_alias_name
                  :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files?
                    res.data.custom_bundle_files.keystore_alias_name
                  : ''
                  ,
              keystore_password: 
                    res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                    res.data.already_have_live_files.keystore_password
                  :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files?
                    res.data.custom_bundle_files.keystore_password
                  : ''
                  ,
              keystore_private_key_password:
              res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                    res.data.already_have_live_files.keystore_private_key_password
                  :  res.data.custom_status== 1 && res.data.is_app_already_published == 0  && res.data.custom_bundle_files?
                    res.data.custom_bundle_files.keystore_private_key_password
                  : ''
                  ,
          jks_filename:
              res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                res.data.already_have_live_files.jks_file
              :  res.data.custom_status== 1 && 
   res.data.is_app_already_published == 0  && res.data.custom_bundle_files?
                res.data.custom_bundle_files.jks_file
              : ''
              ,
          getjksfilenameAWS:
              res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                res.data.already_have_live_files.jks_file
              :  res.data.custom_status== 1 && 
   res.data.is_app_already_published == 0  && res.data.custom_bundle_files?
                res.data.custom_bundle_files.jks_file
              : ''
              ,
          
          is_enable_foreground_location_permission:
              res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
              res.data.already_have_live_files ? JSON.parse(res.data.already_have_live_files.permissions).is_enable_foreground_location_permission : 0
              :  res.data.custom_status== 1 && 
   res.data.is_app_already_published == 0  && res.data.custom_bundle_files ?
                res.data.custom_bundle_files ? JSON.parse(res.data.custom_bundle_files.permissions).is_enable_foreground_location_permission : 0
              : ''
              ,
          
          is_enable_background_location_permission :
              res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
              res.data.already_have_live_files ? JSON.parse(res.data.already_have_live_files.permissions).is_enable_background_location_permission : 0
              :  res.data.custom_status== 1 && 
   res.data.is_app_already_published == 0  && res.data.custom_bundle_files ?
                res.data.custom_bundle_files ? JSON.parse(res.data.custom_bundle_files.permissions).is_enable_background_location_permission : 0
              : ''
              ,
          is_enable_camera_permission :
              res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
              res.data.already_have_live_files ? JSON.parse(res.data.already_have_live_files.permissions).is_enable_camera_permission : 0
              :  res.data.custom_status== 1 && 
   res.data.is_app_already_published == 0  && res.data.custom_bundle_files ?
                res.data.custom_bundle_files ? JSON.parse(res.data.custom_bundle_files.permissions).is_enable_camera_permission : 0
              : ''
              ,
          is_enable_photos_permission :
              res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
              res.data.already_have_live_files ? JSON.parse(res.data.already_have_live_files.permissions).is_enable_photos_permission : 0
              :  res.data.custom_status== 1 && 
   res.data.is_app_already_published == 0  && res.data.custom_bundle_files ?
                res.data.custom_bundle_files ? JSON.parse(res.data.custom_bundle_files.permissions).is_enable_photos_permission : 0
              : ''
              ,
          is_enable_videos_permission :
              res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
              res.data.already_have_live_files ? JSON.parse(res.data.already_have_live_files.permissions).is_enable_videos_permission : 0
              :  res.data.custom_status== 1 && 
   res.data.is_app_already_published == 0  && res.data.custom_bundle_files ?
                res.data.custom_bundle_files ? JSON.parse(res.data.custom_bundle_files.permissions).is_enable_videos_permission : 0
              : ''
              ,
          is_enable_microphone_permission :
              res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
              res.data.already_have_live_files ? JSON.parse(res.data.already_have_live_files.permissions).is_enable_microphone_permission : 0
              :  res.data.custom_status== 1 && 
   res.data.is_app_already_published == 0  && res.data.custom_bundle_files ?
                res.data.custom_bundle_files ? JSON.parse(res.data.custom_bundle_files.permissions).is_enable_microphone_permission : 0
              : ''
              ,
          is_enable_contacts_permission :
              res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
              res.data.already_have_live_files ? JSON.parse(res.data.already_have_live_files.permissions).is_enable_contacts_permission : 0
              :  res.data.custom_status== 1 && 
   res.data.is_app_already_published == 0  && res.data.custom_bundle_files ?
                res.data.custom_bundle_files ? JSON.parse(res.data.custom_bundle_files.permissions).is_enable_contacts_permission : 0
              : ''
              ,
          is_notification_enabled :
                res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                res.data.already_have_live_files ? JSON.parse(res.data.already_have_live_files).is_notification_enabled : 0
              : res.data.custom_status== 1 && 
                res.data.is_app_already_published == 0  && res.data.custom_bundle_files ?
                res.data.custom_bundle_files ? JSON.parse(res.data.custom_bundle_files).is_notification_enabled : 0
              : ''
              ,
          is_admob_enabled :
                res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                res.data.already_have_live_files ? JSON.parse(res.data.already_have_live_files).is_admob_enabled : 0
              : res.data.custom_status== 1 && 
                res.data.is_app_already_published == 0  && res.data.custom_bundle_files ?
                res.data.custom_bundle_files ? JSON.parse(res.data.custom_bundle_files).is_admob_enabled : 0
              : ''
              ,
          is_google_login_enabled :
                res.data.is_app_already_published == 1 && res.data.custom_status == 1 && res.data.already_have_live_files ?
                res.data.already_have_live_files ? JSON.parse(res.data.already_have_live_files).is_google_login_enabled : 0
              : res.data.custom_status== 1 && 
                res.data.is_app_already_published == 0  && res.data.custom_bundle_files ?
                res.data.custom_bundle_files ? JSON.parse(res.data.custom_bundle_files).is_google_login_enabled : 0
              : ''
              ,
              })
            }
            else
            {
              state.setState({
                spinner: false,
                is_success: res.data.is_success !== 0 || res.data.build_process !== 0
                                ? 1
                                : 0,
                resetbuttonandroid: res.data.is_success,
                disabledsubmitbutton: res.data.build_process !== 0 ? true : false,
                bundle_android_identifier:res.data.bundle_identifier,
              is_app_already_published: res.data.is_app_already_published === 1 ? true : false,
              });
            }
          }
        }
      }
    })
    .catch((error) => {
      
    });
};

//new save andorid data
export const andoridsave = (params, state, callback) => 
{
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(sendurl + "android/save-signing-files", params, 
    {
      headers: headers,
    })
    .then((res) => 
    {
      if (res.data.code === 200) 
      {
        state.setState({saveclass:"d-none",buttonsaveclass:"d-block", updatedAt: res.data?.data?.updated_at});

        sucesstoast("The record has been saved.");
      }
       
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
      state.setState({saveclass:"d-none",buttonsaveclass:"d-block"});
    });
};

//Post API for certificate & profile generation

export const generateCertificatProfile = (param, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(sendurl + "ios-automation/create-signing-files", param, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          generateText:"",
          generateLoader:true,
          recalling_api : !state.state.recalling_api,
          is_generating_certification_in_process:true,
         });
        
      }
      else if(res.data.code === 500){
        errortoast("Files creation is in progress.");
        state.setState({
          generateText:"",
          generateLoader:true,
          is_generating_certification_in_process:true,
         });
      }
      else{
        errortoast("Oh no! Something’s not right.");
        state.setState({
          generateText:"Generate certificate & profile",
          generateLoader:false,
          is_generating_certification_in_process:false,
         });
      }
    })
    .catch((error) => {
      
      errortoast("Oh no! Something’s not right.");
      state.setState({
        generateText:"Generate certificate & profile",
        generateLoader:false,
        is_generating_certification_in_process:false,
       });
     });
};

export const uploadKeyFileOnS3 = (params, state) => {
  function dataURLtoFileoriginal(dataurl, filename) {
    var arr = dataurl.split(","),
      //mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: "application/p8" });
  }
  let originalfile = dataURLtoFileoriginal(params.original, "hello.p8");
  const oriconfig = {
    bucketName: process.env.REACT_APP_bucketName,
    dirName: params.original_path.replace(/\/$/, "") /* optional */,
    region: process.env.REACT_APP_region,
    accessKeyId: process.env.REACT_APP_accessKeyId,
    secretAccessKey: process.env.REACT_APP_secretAccessKey,
    s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
  };
  const ReactS3original = new CustomS3Client(oriconfig);
  const originalFileName = params.imgname;
  ReactS3original.uploadFile(originalfile, originalFileName)
    .then((data) => {
      if (data.status === 204) {
        state.setState({
          key_file_s3_name: data.key.split("/").pop(),
        });
      }
    })
    .catch((err) => {
      errortoast("Something went wrong. Try again after sometime.");
    });
};
