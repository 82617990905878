import React from "react";
import styles from "./download.css";
import Header from "./../Header";
import Leftpanel from "./../Leftpanel";
import imagebackground from "./../../assets/images/dashboard/cms/uploadbackground.png";
import imagebackground2 from "./../../assets/images/dashboard/file-icon.png";
import { newgetiosformdata , generateCertificatProfile , uploadKeyFileOnS3} from "../../Api/Custombundle/Custombundle";
import { errortoast, sucesstoast } from "../Toaster";
import {
    BuildappApi,
    certificate,
    provision2s3,
    iosbuilingapp,
    iosAndroidBuildReset,
  } from "../../Api/Dashboard/DashboardApi";
import { 
	Modal,
	ModalHeader,
	ModalBody,
} from "reactstrap";
import Accordion from 'react-bootstrap/Accordion';
import moment from "moment";

const versionRegex = /^(?!0)\d+\.(?!0\d)\d+\.(?!0\d)\d+$/; // Matches only the X.Y.Z format
const specialCharRegex = /[^a-zA-Z0-9.]/; // Matches any character that is not a letter, digit, or dot

export default class Buildios extends React.PureComponent {
    constructor(props) {
      super(props);
  
      this.state = {
        openDownloadPopup:false,
        openAdvanceSetting:false,
        appRightPanelExpand:localStorage.getItem("appSideBarCollapse") === true ||
                            localStorage.getItem("appSideBarCollapse") === "true"
                            ? true
                            : false,
        spinner: true,
        plan_code: props.plan_code,
        submitval: "Done",
        loader: "d-none",
        loaderimage: require("../../assets/images/signup/Loader_GIF.gif").default,
        modalreset: false,
        submitiosval: "Done",
        loaderios: "d-none",
        submitbuttontext: "Done",
        loaderiosimage: require("../../assets/images/signup/Loader_GIF.gif")
            .default,
        disabledsubmitbutton: false,
        disablediossubmitbutton: false,
        //Android states
        bundle_id: "",
        current_version_code: "",
        current_version_name: "",
        old_bundle_id: "",
        old_current_version_code: "",
        old_current_version_name: "",
        status: undefined,
        keychain_url: "",
        is_reset: 0,
        is_success: 0,
        resetbuttonandroid: 0,
        //Android states end
        //iOS states
        ios_bundle_id: "",
        iOS_current_version_code: "",
        iosstatus: undefined,
        modalandroid: false,
        modalios: false,
        is_iossuccess: 0,
        resettype: undefined,
        resetbuttonios: 0,
        is_iosreset: 0,
        is_app_already_published: "",
        is_app_already_published_ios: "",
        is_supporting_ipad:false,
        certificatefilename: "",
        certificate_password:"",
        api_key:"",
        teamid: "",
        is_enable_location_permission: false,
        location_permission_description: "",
        is_enable_calender_permission: false,
        calender_permission_description: "",
        is_enable_microphone_permission: false,
        microphone_permission_description: "",
        is_enable_reminder_permission: false,
        reminder_permission_description: "",
        is_enable_speech_recognition_permission: false,
        speech_recognition_permission_description: "",
        is_enable_camera_and_photo_library_permission: false,
        camera_and_photo_library_permission_description: "",
        is_enable_contacts_permission: false,
        contacts_permission_description: "",
        is_enable_app_tracking_permission: false,
        app_tracking_permission_description: "",
        chat_preferences: null,
        is_notification_enabled :0,
        is_rich_notification_enabled :0,
        is_google_login_enabled : 0,
        ios_is_admob_enabled :0,
        is_admob_enabled:0,
        admob_app_id:"",
        iosdata:'',
        issuer_id:"",
        key_id:"",
        key_file_name:"",
        key_file_s3_name:"",
        error_message_for_generations_feild:"",
        generateText:"Generate certificate & profile",
        generateLoader:false,
        recalling_api:false,
        is_generating_certification_in_process:false,
        current_process_status : 0 ,
        openGenErrPopUp : false ,
        genErrDescription : "",
        certificatValidityDat : "",
        openWarningCertificatePopUp : false ,
        isUserAgree : false ,
        updatedAt:null
        };
    }
    interval;

    componentDidMount() 
      {
        if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
        {
          document.title = "iOS build settings | AppMySite";

        }
        else
        {
          document.title = "iOS build settings |"+ ' ' +localStorage.getItem('agencyname');
        }

        window.scrollTo(0, 0);
        const androidparams = {
          type: "ios",
        };

        if((atob(localStorage.getItem("plan_code")) !== "preview") && (atob(localStorage.getItem("plan_code")) !== "paddle_preview"))
          {
            newgetiosformdata(androidparams, this, (current_process_status_from_custom_bundle,current_process_status_from_default_bundle) => {

              if(
                // res.data &&  
                ( this.state.is_app_already_published_ios === 0 || this.state.is_app_already_published_ios === false )
                // ( 
                //   // res.data.custom_status == 0 || 
                //   res.data.is_app_already_published === 0 )
                ){
    
                if(this.state.custompackage === true || this.state.custompackage === 1){
    
                    if(current_process_status_from_custom_bundle === 0){
                      this.setState({
                        generateText:"Generate certificate & profile",
                        generateLoader:false,
                        is_generating_certification_in_process:false,
                      })
                    }
                    else if(current_process_status_from_custom_bundle === 1){
              
                    this.setState({
                      recalling_api : !this.state.recalling_api,
                      generateText:"",
                      generateLoader:true,
                      is_generating_certification_in_process:true,
                    })
                  } else if(current_process_status_from_custom_bundle === 2){
                    this.setState({
                      generateText:"Generate certificate & profile",
                      generateLoader:false,
                      is_generating_certification_in_process:false,
                    })
                  } else if(current_process_status_from_custom_bundle === 3){
                    this.setState({
                      generateText:"Generate certificate & profile",
                      generateLoader:false,
                      is_generating_certification_in_process:false,
                    })
                  }
    
                }else{
                      if(current_process_status_from_default_bundle === 0){
                        this.setState({
                          generateText:"Generate certificate & profile",
                          generateLoader:false,
                          is_generating_certification_in_process:false,
                        })
                       }
                      else if(current_process_status_from_default_bundle === 1){
                        this.setState({
                          recalling_api : !this.state.recalling_api,
                          generateText:"",
                          generateLoader:true,
                          is_generating_certification_in_process:true,
                        })
                      } else if(current_process_status_from_default_bundle === 2){
                        this.setState({
                          generateText:"Generate certificate & profile",
                          generateLoader:false,
                          is_generating_certification_in_process:false,
                        })
                       }
                       else if(current_process_status_from_default_bundle === 3){
                        this.setState({
                          generateText:"Generate certificate & profile",
                          generateLoader:false,
                          is_generating_certification_in_process:false,
                        })
                       }
                }
                }
            });
          }
          else
          {
            if(parseInt(localStorage.getItem('website_technology')) === 4)
              {
                window.location.href= "/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid'));
              }
            else if(parseInt(localStorage.getItem('website_technology')) === 5)
              {
                window.location.href= "/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'));
              }
            else if(parseInt(localStorage.getItem('website_technology')) === 2)
              {
                window.location.href= "/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'));
              }
            else if(parseInt(localStorage.getItem('website_technology')) === 3)
              {
                window.location.href= "/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'));
              }
              
          }

      }
    
      componentWillUnmount() {
        clearInterval(this.interval);
      }

      componentDidUpdate(prevProps, prevState) { 
        // check whether client has changed 
        if (prevState.recalling_api !== this.state.recalling_api) { 
          const androidparams = {
            type: "ios",
          };
          setTimeout(()=>{
            newgetiosformdata(androidparams, this, (current_process_status_from_custom_bundle,current_process_status_from_default_bundle) => {
            
              if( this.state.is_app_already_published_ios === 0 || this.state.is_app_already_published_ios === false )
              {
             
                if(this.state.custompackage === true || this.state.custompackage === 1){
    
                    if(current_process_status_from_custom_bundle === 0){
                      this.setState({
                        generateText:"Generate certificate & profile",
                        generateLoader:false,
                        is_generating_certification_in_process:false,
                      })
                    }
                    else if(current_process_status_from_custom_bundle === 1){
                   
                    this.setState({
                      recalling_api : !this.state.recalling_api,
                      generateText:"",
                      generateLoader:true,
                      is_generating_certification_in_process:true,
                    })
                  } else if(current_process_status_from_custom_bundle === 2){
                    this.setState({
                      generateText:"Generate certificate & profile",
                      generateLoader:false,
                      is_generating_certification_in_process:false,
                    })
                  } else if(current_process_status_from_custom_bundle === 3){
                    this.setState({
                      generateText:"Generate certificate & profile",
                      generateLoader:false,
                      is_generating_certification_in_process:false,
                    })
                  }
    
                }else{
                      if(current_process_status_from_default_bundle === 0){
                        this.setState({
                          generateText:"Generate certificate & profile",
                          generateLoader:false,
                          is_generating_certification_in_process:false,
                        })
                       }
                      else if(current_process_status_from_default_bundle === 1){
                        this.setState({
                          recalling_api : !this.state.recalling_api,
                          generateText:"",
                          generateLoader:true,
                          is_generating_certification_in_process:true,
                        })
                      } else if(current_process_status_from_default_bundle === 2){
                        this.setState({
                          generateText:"Generate certificate & profile",
                          generateLoader:false,
                          is_generating_certification_in_process:false,
                        })
                       }
                       else if(current_process_status_from_default_bundle === 3){
                        this.setState({
                          generateText:"Generate certificate & profile",
                          generateLoader:false,
                          is_generating_certification_in_process:false,
                        })
                       }
                }
                }

            });
          },30000)
        } 
      } 

      handleUserAgree = (e) => {
        this.setState({
          isUserAgree : e.target.checked === true ? true : false ,
        })
      }

      handleOpenErrPopUpForGen = () => {
        this.setState({
          openGenErrPopUp : !this.state.openGenErrPopUp ,
        })
      }

      handleIssuerId = (e) => {
       this.setState({
        issuer_id : e.target.value,
       })
      }

      handleKeyId = (e) => {
        this.setState({
         key_id : e.target.value,
        })
       }

       uploadKeyFile = (result, filename) => {
        const params = {
          original_path:localStorage.getItem("user_id") + "/" + localStorage.getItem("Appid") + "/ios/p8",
          original: result,
          imgname: filename,
        };
        uploadKeyFileOnS3(params,this);
      };

      removeKeyFile = () => {
        this.setState({
          key_file_name:"",
          key_file_s3_name:"",
        })
      }

       handlekeyFileCertificate = (e) => {
        let self = this;
        let reader = new FileReader();
        let file = e.target.files[0];
      
        if(file !== undefined){
       
          if (file.name.indexOf("p8") !== -1) {
            self.setState({
              key_file_name : file.name,
            })
            reader.onload = function (upload) {
             // setcertificate(upload.target.result);
             self.uploadKeyFile(upload.target.result, file.name.split(".").slice(0, -1).join("."))
             
            };
            reader.readAsDataURL(file);
            // setErrormodal(false); 
          } else {
            self.setState({
              key_file_s3_name : "",
              key_file_name : "",
              error_message_for_generations_feild:"File format not supported. Upload p8 file only.",
            })
            errortoast("File format not supported. Upload p8 file only.");
            // setErrormodal(true);
          }
       
        }
  }

  handleOpenWarningCertificatePopUp = (e) => {
   this.setState({
    openWarningCertificatePopUp : !this.state.openWarningCertificatePopUp ,
   })
  }
 
  handlegenerateCertificateProfile = (e) => {

    this.UploadcertificatetoS3("generate-certificate");

   this.setState({
    generateText:"",
    generateLoader:true,
    is_generating_certification_in_process : true ,
    openWarningCertificatePopUp : false ,
   });
   const param = {
    app_id: parseInt(localStorage.getItem("Appid")),
    issuer_id : this.state.issuer_id,
    key_id : this.state.key_id,
    key_file : this.state.key_file_s3_name,
   }
   generateCertificatProfile(param,this);
   
  }

    handleAttributePopUp = () =>{
        this.setState({openDownloadPopup:!this.state.openDownloadPopup});
    }
    handleRightPanelExpand = (value) => {

        this.setState({openAdvanceSetting:value});
    }
    handelOpenSetting = () => {
        this.setState({openAdvanceSetting:!this.state.openAdvanceSetting});
    }
    handleLiveAppToggleIOS = (e) => {
        this.setState({
          is_app_already_published_ios: e.target.checked === true ? true : false,
          custompackage:e.target.checked === true ? true : false,
        }, () => {
          this.afterSetStateFinished();
      });
    };

    handlecustompackage = (e) =>
      {
        this.setState({
          custompackage: e.target.checked === true ? true : false,
          is_app_already_published_ios:false,
          }, () => {
            this.afterSetStateFinished();
        });
      }

      afterSetStateFinished = () =>
      {
        this.setState({
                current_version_name:
                                     this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files 
                                  ?  this.state.iosdata.already_have_live_files.current_version
                                  :  this.state.custompackage == 1  && this.state.is_app_already_published == 0 && this.state.iosdata.custom_bundle_files
                                  ?  this.state.iosdata.custom_bundle_files.current_version
                                  :  this.state.is_app_already_published == 0 && this.state.custompackage == 0 && this.state.iosdata.default_bundle_files 
                                  ?  this.state.iosdata.default_bundle_files.current_version
                                  :  this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files != null 
                                  ?  this.state.iosdata.already_have_live_files.current_version
                                  :  ''
                                  ,
                bundle_id: 
                             this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files 
                          ?  this.state.iosdata.already_have_live_files.bundle_id
                          :  this.state.custompackage == 1 &&  this.state.is_app_already_published == 0 && this.state.iosdata.custom_bundle_files?
                             this.state.iosdata.custom_bundle_files.bundle_id
                          :  this.state.is_app_already_published == 0 && this.state.custompackage == 0 && this.state.iosdata.default_bundle_files 
                          ?  this.state.iosdata.default_bundle_files.bundle_id
                          :  this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files != null 
                          ?  this.state.iosdata.already_have_live_files.bundle_id
                          :  ''
                          ,
                ios_bundle_id:
                          this.state.is_app_already_published == 1 && this.state.custom_status == 1 && this.state.iosdata.already_have_live_files 
                          ? this.state.iosdata.already_have_live_files.bundle_id
                          : this.state.custom_status == 1 &&  this.state.is_app_already_published == 0 && this.state.iosdata.custom_bundle_files?
                            this.state.iosdata.custom_bundle_files.bundle_id
                          : this.state.custom_status == 0 &&  this.state.is_app_already_published == 0 ?
                            this.state.bundle_identifier
                        : ''
                        ,
                teamid:   this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files ?
                          this.state.iosdata.already_have_live_files.team_id
                        : this.state.custompackage == 1 && this.state.is_app_already_published == 0 && this.state.iosdata.custom_bundle_files?
                          this.state.iosdata.custom_bundle_files.team_id
                        : this.state.is_app_already_published == 0 && this.state.custompackage == 0 && this.state.iosdata.default_bundle_files 
                        ? this.state.iosdata.default_bundle_files.team_id
                        : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files != null 
                        ? this.state.iosdata.already_have_live_files.team_id
                        : ''
                        ,
                certificates3name: 
                                  this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files ?
                                  this.state.iosdata.already_have_live_files.certificate
                                :  this.state.custompackage == 1 && this.state.is_app_already_published == 0 && this.state.iosdata.custom_bundle_files?
                                  this.state.iosdata.custom_bundle_files.certificate
                                : this.state.is_app_already_published == 0 && this.state.custompackage == 0 && this.state.iosdata.default_bundle_files 
                                ? this.state.iosdata.default_bundle_files.certificate
                                : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files != null 
                                ? this.state.iosdata.already_have_live_files.certificate
                                : ''
                                ,
                certificatefilename:
                                this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files 
                                ?
                                  this.state.iosdata.already_have_live_files.certificate === "" || this.state.iosdata.already_have_live_files.certificate === null
                                  ? ".p12 file supported "
                                  : this.state.iosdata.already_have_live_files.certificate
                                :  this.state.custompackage == 1 &&  this.state.is_app_already_published == 0 && this.state.iosdata.custom_bundle_files
                                ?
                                  this.state.iosdata.custom_bundle_files.certificate === "" || this.state.iosdata.custom_bundle_files.certificate === null
                                  ? ".p12 file supported "
                                  : this.state.iosdata.custom_bundle_files.certificate
                                : this.state.is_app_already_published == 0 && this.state.custompackage == 0 && this.state.iosdata.default_bundle_files 
                                ? this.state.iosdata.default_bundle_files.certificate
                                : '.p12 file supported '
                                ,
                pushnotificationfilename:
                                this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files 
                                ?
                                  this.state.iosdata.already_have_live_files.notification_config_file === "" || this.state.iosdata.already_have_live_files.notification_config_file === null
                                  ? ".plist file supported "
                                  : this.state.iosdata.already_have_live_files.notification_config_file
                                :  this.state.custompackage == 1 &&  this.state.is_app_already_published == 0 && this.state.iosdata.custom_bundle_files
                                ?
                                  this.state.iosdata.custom_bundle_files.notification_config_file === "" || this.state.iosdata.custom_bundle_files.notification_config_file === null
                                  ? ".plist file supported "
                                  : this.state.iosdata.custom_bundle_files.notification_config_file
                                : this.state.is_app_already_published == 0 && this.state.custompackage == 0 && this.state.iosdata.default_bundle_files 
                                ? this.state.iosdata.default_bundle_files.notification_config_file
                                : '.plist file supported '
                                ,
                        
                pushnotifications3name:
                                this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files 
                                ?
                                  this.state.iosdata.already_have_live_files.notification_config_file === "" || this.state.iosdata.already_have_live_files.notification_config_file === null
                                  ? ""
                                  : this.state.iosdata.already_have_live_files.notification_config_file
                                :  this.state.custompackage == 1 &&  this.state.is_app_already_published == 0 && this.state.iosdata.custom_bundle_files
                                ?
                                  this.state.iosdata.custom_bundle_files.notification_config_file === "" || this.state.iosdata.custom_bundle_files.notification_config_file === null
                                  ? ""
                                  : this.state.iosdata.custom_bundle_files.notification_config_file
                                : this.state.is_app_already_published == 0 && this.state.custompackage == 0 && this.state.iosdata.default_bundle_files 
                                ? this.state.iosdata.default_bundle_files.notification_config_file
                                : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files != null 
                                ? this.state.iosdata.already_have_live_files.notification_config_file
                                : ''
                                ,
                serverkey: 
                            this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files ?
                            this.state.iosdata.already_have_live_files.notification_key
                          :  this.state.custompackage == 1 && this.state.is_app_already_published == 0 && this.state.iosdata.custom_bundle_files?
                            this.state.iosdata.custom_bundle_files.notification_key
                          : this.state.is_app_already_published == 0 && this.state.custompackage == 0 && this.state.iosdata.default_bundle_files 
                          ? this.state.iosdata.default_bundle_files.notification_key
                          : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files != null 
                          ? this.state.iosdata.already_have_live_files.notification_key
                          : ''
                          ,
                  notification_config_file: 
                          this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files ?
                          this.state.iosdata.already_have_live_files.notification_config_file
                        :  this.state.custompackage == 1 && this.state.is_app_already_published == 0 && this.state.iosdata.custom_bundle_files?
                          this.state.iosdata.custom_bundle_files.notification_config_file
                        : this.state.is_app_already_published == 0 && this.state.custompackage == 0 && this.state.iosdata.default_bundle_files 
                        ? this.state.iosdata.default_bundle_files.notification_config_file
                        : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files != null 
                        ? this.state.iosdata.already_have_live_files.notification_config_file
                        : ''
                        ,
                provision2filename:
                          this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files 
                          ?
                            this.state.iosdata.already_have_live_files.profile === "" || this.state.iosdata.already_have_live_files.profile === null
                            ? ".mobileprovision file supported "
                            : this.state.iosdata.already_have_live_files.profile
                          :  this.state.custompackage == 1 &&  this.state.is_app_already_published == 0 && this.state.iosdata.custom_bundle_files
                          ?
                            this.state.iosdata.custom_bundle_files.profile === "" || this.state.iosdata.custom_bundle_files.profile === null
                            ? ".mobileprovision file supported "
                            : this.state.iosdata.custom_bundle_files.profile
                          : this.state.is_app_already_published == 0 && this.state.custompackage == 0 && this.state.iosdata.default_bundle_files 
                          ? this.state.iosdata.default_bundle_files.profile
                          : '.mobileprovision file supported '
                          ,
                provision2s3name: 
                          this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files ?
                          this.state.iosdata.already_have_live_files.profile
                        :  this.state.custompackage == 1 &&  this.state.is_app_already_published == 0 && this.state.iosdata.custom_bundle_files?
                          this.state.iosdata.custom_bundle_files.profile
                        : this.state.is_app_already_published == 0 && this.state.custompackage == 0 && this.state.iosdata.default_bundle_files 
                        ? this.state.iosdata.default_bundle_files.profile
                        : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files != null 
                        ? this.state.iosdata.already_have_live_files.profile
                        : ''
                        ,
                pushtoggle:
                          this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files ?
                          this.state.iosdata.already_have_live_files.is_notification_enabled === 0
                          ? false
                          : true
                        :  this.state.custompackage == 1 && this.state.is_app_already_published == 0 && this.state.iosdata.custom_bundle_files?
                          this.state.iosdata.custom_bundle_files.is_notification_enabled === 0
                          ? false
                          : true
                        : this.state.is_app_already_published == 0 && this.state.custompackage == 0 && this.state.iosdata.default_bundle_files 
                        ?  this.state.iosdata.default_bundle_files.is_notification_enabled === 0
                          ? false
                          : true
                        : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files ?
                          this.state.iosdata.already_have_live_files.is_notification_enabled === 0
                          ? false
                          : true
                        : false
                        ,
                google_enable:
                        this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files ?
                        this.state.iosdata.already_have_live_files.is_google_login_enabled === 0
                        ? false
                        : true
                      :  this.state.custompackage == 1 &&  this.state.is_app_already_published == 0 && this.state.iosdata.custom_bundle_files?
                        this.state.iosdata.custom_bundle_files.is_google_login_enabled === 0
                        ? false
                        : true
                      : this.state.is_app_already_published == 0 && this.state.custompackage == 0 && this.state.iosdata.default_bundle_files 
                      ?  this.state.iosdata.default_bundle_files.is_google_login_enabled === 0
                        ? false
                        : true
                      : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files ? 
                        this.state.iosdata.already_have_live_files.is_google_login_enabled === 0
                        ? false
                        : true
                      : false
                      ,
                is_supporting_ipad:
                      this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files ?
                      this.state.iosdata.already_have_live_files.is_supporting_ipad === undefined || this.state.iosdata.already_have_live_files.is_supporting_ipad === 0
                      ? false
                      : true
                    :  this.state.custompackage == 1 && this.state.is_app_already_published == 0 && this.state.iosdata.custom_bundle_files?
                       this.state.iosdata.custom_bundle_files.is_supporting_ipad === undefined || this.state.iosdata.custom_bundle_files.is_supporting_ipad === 0
                      ? false
                      : true
                    : this.state.is_app_already_published == 0 && this.state.custompackage == 0 && this.state.iosdata.default_bundle_files 
                    ?  this.state.iosdata.default_bundle_files.is_supporting_ipad === undefined || this.state.iosdata.default_bundle_files.is_supporting_ipad === 0
                      ? false
                      : true
                    : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files ?
                      this.state.iosdata.already_have_live_files.is_supporting_ipad === undefined || this.state.iosdata.already_have_live_files.is_supporting_ipad === 0
                      ? false
                      : true
                    : false
                    ,
                certificate_password:
                        this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files 
                      ? this.state.iosdata.already_have_live_files.certificate_password
                      : this.state.custompackage == 1 &&  this.state.is_app_already_published == 0 && this.state.iosdata.custom_bundle_files 
                      ? this.state.iosdata.custom_bundle_files.certificate_password
                      : this.state.custompackage == 0 &&  this.state.is_app_already_published == 0 && this.state.iosdata.default_bundle_files 
                      ? this.state.iosdata.default_bundle_files.certificate_password
                      : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files ?
                        this.state.iosdata.already_have_live_files.certificate_password
                      : ""
                      ,
                // admob_app_id: 
                //                 this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files ?
                //                   this.state.iosdata.already_have_live_files.admob_app_id
                //                 :  this.state.custompackage == 1 &&  this.state.is_app_already_published == 0 && this.state.iosdata.custom_bundle_files?
                //                   this.state.iosdata.custom_bundle_files.admob_app_id
                //                 :  this.state.custompackage == 0 &&  this.state.is_app_already_published == 0 && this.state.iosdata.default_bundle_files?
                //                   this.state.iosdata.default_bundle_files.admob_app_id
                //                 : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files ?  
                //                   this.state.iosdata.already_have_live_files.admob_app_id
                //                 : ''
                //                 ,
                // ios_is_admob_enabled:
                //           this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files ?
                //           this.state.iosdata.already_have_live_files.is_admob_enabled === 1
                //           ? true
                //           : false
                //         :  this.state.custompackage == 1 &&  this.state.is_app_already_published == 0 && this.state.iosdata.custom_bundle_files?
                //         this.state.iosdata.custom_bundle_files.is_admob_enabled === 1
                //           ? true
                //           : false
                //         :  this.state.custompackage == 0 &&  this.state.is_app_already_published == 0 && this.state.iosdata.default_bundle_files?
                //           this.state.iosdata.default_bundle_files.is_admob_enabled === 1
                //             ? true
                //             : false
                //         : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files ? 
                //         this.state.iosdata.already_have_live_files.is_admob_enabled === 1
                //         ? true
                //         : false
                //         : false
                //         ,
                //permission
                is_enable_location_permission:
                  
                    this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files && 
                    this.state.iosdata.already_have_live_files.permissions
                    ?
                          JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_location_permission === 1 ||
                          JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_location_permission === true
                        ? true
                        : false
                    :  this.state.custompackage == 1 &&  this.state.is_app_already_published == 0  && this.state.iosdata.custom_bundle_files &&
                        this.state.iosdata.custom_bundle_files.permissions
                    ?
                        JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_location_permission === 1 ||
                        JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_location_permission === true
                        ? true
                        : false
                    :  this.state.custompackage == 0 &&  this.state.is_app_already_published == 0  && this.state.iosdata.default_bundle_files &&
                      this.state.iosdata.default_bundle_files.permissions
                    ? 
                      JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_location_permission === 1 ||
                      JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_location_permission === true
                      ? true
                      : false
                    : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files &&
                      this.state.iosdata.already_have_live_files.permissions 
                    ? 
                          JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_location_permission === 1 ||
                          JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_location_permission === true
                        ? true
                        : false
                    : false
                    ,
                location_permission_description:
                    this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files 
                    && this.state.iosdata.already_have_live_files.permissions 
                    ? 
                      JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_location_permission === true ||
                      JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_location_permission === 1
                        ? JSON.parse(this.state.iosdata.already_have_live_files.permissions).location_permission_description
                        : ""
                    :  this.state.custompackage== 1 && this.state.is_app_already_published == 0  && this.state.iosdata.custom_bundle_files &&
                        this.state.iosdata.custom_bundle_files.permissions  
                    ? 
                        JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_location_permission === 1 ||
                        JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_location_permission === true
                        ? JSON.parse(this.state.iosdata.custom_bundle_files.permissions).location_permission_description
                        : ""
                    :  this.state.custompackage== 0 && this.state.is_app_already_published == 0  && this.state.iosdata.default_bundle_files &&
                      this.state.iosdata.default_bundle_files.permissions  
                    ? 
                      JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_location_permission === 1 ||
                      JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_location_permission === true
                      ? JSON.parse(this.state.iosdata.default_bundle_files.permissions).location_permission_description
                    : ""
                    : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files.permissions 
                    ? 
                      JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_location_permission === true ||
                      JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_location_permission === 1
                      ?  JSON.parse(this.state.iosdata.already_have_live_files.permissions).location_permission_description
                      : ""
                    : "",

                is_enable_calender_permission:
                    this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files 
                    && this.state.iosdata.already_have_live_files.permissions 
                    ? 
                      JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_calender_permission === true ||
                      JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_calender_permission === 1
                        ? true
                        : false
                    :  this.state.custompackage== 1 && this.state.is_app_already_published == 0  && this.state.iosdata.custom_bundle_files &&
                        this.state.iosdata.custom_bundle_files.permissions  
                    ? 
                        JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_calender_permission === 1 ||
                        JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_calender_permission === true
                        ? true
                        : false
                    :  this.state.custompackage== 0 && this.state.is_app_already_published == 0  && this.state.iosdata.default_bundle_files &&
                      this.state.iosdata.default_bundle_files.permissions  
                    ? 
                      JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_calender_permission === 1 ||
                      JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_calender_permission === true
                      ? true
                      : false
                    : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files.permissions 
                    ? 
                    JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_calender_permission === true ||
                    JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_calender_permission === 1
                      ? true
                      : false
                    : false,
                calender_permission_description:
                        this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files 
                        && this.state.iosdata.already_have_live_files.permissions 
                        ? 
                          JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_calender_permission === true ||
                          JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_calender_permission === 1
                            ? JSON.parse(this.state.iosdata.already_have_live_files.permissions).calender_permission_description
                            : ""
                        :  this.state.custompackage == 1 &&  this.state.is_app_already_published == 0  && this.state.iosdata.custom_bundle_files 
                        && this.state.iosdata.custom_bundle_files.permissions  
                        ? 
                            JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_calender_permission === 1 ||
                            JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_calender_permission === true
                            ? JSON.parse(this.state.iosdata.custom_bundle_files.permissions).calender_permission_description
                            : ""
                        :  this.state.custompackage == 0 &&  this.state.is_app_already_published == 0  && this.state.iosdata.default_bundle_files 
                        && this.state.iosdata.default_bundle_files.permissions  
                        ? 
                          JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_calender_permission === 1 ||
                          JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_calender_permission === true
                          ? JSON.parse(this.state.iosdata.default_bundle_files.permissions).calender_permission_description
                          : ""
                        : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files.permissions 
                        ? 
                        JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_calender_permission === true ||
                        JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_calender_permission === 1
                          ? true
                          : ""
                        : "",
                is_enable_microphone_permission:
                        this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files 
                        && this.state.iosdata.already_have_live_files.permissions 
                        ? 
                          JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_microphone_permission === true ||
                          JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_microphone_permission === 1
                            ? true
                            : false
                        :  this.state.custompackage ==  1 && this.state.is_app_already_published == 0  && this.state.iosdata.custom_bundle_files 
                        && this.state.iosdata.custom_bundle_files.permissions  
                        ? 
                            JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_microphone_permission === 1 ||
                            JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_microphone_permission === true
                            ? true
                            : false
                        :  this.state.custompackage ==  0 && this.state.is_app_already_published == 0  && this.state.iosdata.default_bundle_files 
                        && this.state.iosdata.default_bundle_files.permissions  
                        ? 
                          JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_microphone_permission === 1 ||
                          JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_microphone_permission === true
                          ? true
                          : false
                        : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files.permissions 
                        ? 
                        JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_microphone_permission === true ||
                        JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_microphone_permission === 1
                          ? true
                          : false
                        : false,
                microphone_permission_description:
                        this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files 
                        && this.state.iosdata.already_have_live_files.permissions 
                        ? 
                          JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_microphone_permission === true ||
                          JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_microphone_permission === 1
                            ? JSON.parse(this.state.iosdata.already_have_live_files.permissions).microphone_permission_description
                            : ""
                        :  this.state.custompackage== 1 &&  this.state.is_app_already_published == 0  && this.state.iosdata.custom_bundle_files 
                        && this.state.iosdata.custom_bundle_files.permissions  
                        ? 
                            JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_microphone_permission === 1 ||
                            JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_microphone_permission === true
                            ? JSON.parse(this.state.iosdata.custom_bundle_files.permissions).microphone_permission_description
                            : ""
                        :  this.state.custompackage ==  0 && this.state.is_app_already_published == 0  && this.state.iosdata.default_bundle_files 
                        && this.state.iosdata.default_bundle_files.permissions  
                        ? 
                          JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_microphone_permission === 1 ||
                          JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_microphone_permission === true
                          ? JSON.parse(this.state.iosdata.default_bundle_files.permissions).microphone_permission_description
                          : ""
                        : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files.permissions 
                        ? 
                        JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_microphone_permission === true ||
                        JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_microphone_permission === 1
                          ? JSON.parse(this.state.iosdata.already_have_live_files.permissions).microphone_permission_description
                          : ""
                        : "",
                is_enable_reminder_permission:
                      this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files 
                      && this.state.iosdata.already_have_live_files.permissions 
                      ? 
                        JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_reminder_permission === true ||
                        JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_reminder_permission === 1
                          ? true
                          : false
                      :  this.state.custompackage==1 &&  this.state.is_app_already_published == 0  && this.state.iosdata.custom_bundle_files 
                      && this.state.iosdata.custom_bundle_files.permissions  
                      ? 
                          JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_reminder_permission === 1 ||
                          JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_reminder_permission === true
                          ? true
                          : false
                      :  this.state.custompackage == 0 &&  this.state.is_app_already_published == 0  && this.state.iosdata.default_bundle_files 
                      && this.state.iosdata.default_bundle_files.permissions  
                      ? 
                        JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_reminder_permission === 1 ||
                        JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_reminder_permission === true
                        ? true
                        : false
                      : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files.permissions 
                      ? 
                      JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_reminder_permission === true ||
                      JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_reminder_permission === 1
                        ? true
                        : false
                      : false,
                reminder_permission_description:
                      this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files 
                      && this.state.iosdata.already_have_live_files.permissions 
                      ? 
                        JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_reminder_permission === true ||
                        JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_reminder_permission === 1
                          ? JSON.parse(this.state.iosdata.already_have_live_files.permissions).reminder_permission_description
                          : ""
                      :  this.state.custompackage==1 &&  this.state.is_app_already_published == 0  && this.state.iosdata.custom_bundle_files 
                      && this.state.iosdata.custom_bundle_files.permissions  
                      ? 
                          JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_reminder_permission === 1 ||
                          JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_reminder_permission === true
                          ? JSON.parse(this.state.iosdata.custom_bundle_files.permissions).reminder_permission_description
                          : ""
                      :  this.state.custompackage == 0 &&  this.state.is_app_already_published == 0  && this.state.iosdata.default_bundle_files 
                      && this.state.iosdata.default_bundle_files.permissions  
                      ? 
                        JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_reminder_permission === 1 ||
                        JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_reminder_permission === true
                        ? JSON.parse(this.state.iosdata.default_bundle_files.permissions).reminder_permission_description
                        : ""
                      : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files.permissions 
                      ? 
                      JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_reminder_permission === true ||
                      JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_reminder_permission === 1
                        ? JSON.parse(this.state.iosdata.already_have_live_files.permissions).reminder_permission_description
                        : ""
                      : "",
                is_enable_speech_recognition_permission:
                      this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files 
                      && this.state.iosdata.already_have_live_files.permissions 
                      ? 
                        JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_speech_recognition_permission === true ||
                        JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_speech_recognition_permission === 1
                          ? true
                          : false
                      :  this.state.custompackage==1 && this.state.is_app_already_published == 0  && this.state.iosdata.custom_bundle_files 
                      && this.state.iosdata.custom_bundle_files.permissions  
                      ? 
                          JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_speech_recognition_permission === 1 ||
                          JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_speech_recognition_permission === true
                          ? true
                          : false
                      :  this.state.custompackage== 0 && this.state.is_app_already_published == 0  && this.state.iosdata.default_bundle_files 
                      && this.state.iosdata.default_bundle_files.permissions  
                      ? 
                        JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_speech_recognition_permission === 1 ||
                        JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_speech_recognition_permission === true
                        ? true
                        : false
                      : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files.permissions 
                      ? 
                      JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_speech_recognition_permission === true ||
                      JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_speech_recognition_permission === 1
                        ? true
                        : false
                      : false,
                speech_recognition_permission_description:
                      this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files 
                      && this.state.iosdata.already_have_live_files.permissions 
                      ? 
                        JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_speech_recognition_permission === true ||
                        JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_speech_recognition_permission === 1
                          ? JSON.parse(this.state.iosdata.already_have_live_files.permissions).speech_recognition_permission_description
                          : ""
                      :  this.state.custompackage==1 &&  this.state.is_app_already_published == 0  && this.state.iosdata.custom_bundle_files 
                      && this.state.iosdata.custom_bundle_files.permissions  
                      ? 
                          JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_speech_recognition_permission === 1 ||
                          JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_speech_recognition_permission === true
                          ? JSON.parse(this.state.iosdata.custom_bundle_files.permissions).speech_recognition_permission_description
                          : ""
                      :  this.state.custompackage == 0 &&  this.state.is_app_already_published == 0  && this.state.iosdata.default_bundle_files 
                      && this.state.iosdata.default_bundle_files.permissions  
                      ? 
                        JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_speech_recognition_permission === 1 ||
                        JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_speech_recognition_permission === true
                        ? JSON.parse(this.state.iosdata.default_bundle_files.permissions).speech_recognition_permission_description
                        : ""
                      : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files.permissions 
                      ? 
                      JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_speech_recognition_permission === true ||
                      JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_speech_recognition_permission === 1
                        ? JSON.parse(this.state.iosdata.already_have_live_files.permissions).speech_recognition_permission_description
                        : ""
                      : "",
                is_enable_camera_and_photo_library_permission:
                      this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files 
                      && this.state.iosdata.already_have_live_files.permissions 
                      ? 
                        JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_camera_and_photo_library_permission === 1 ||
                        (this.state.app_data.chat_preferences !== null && this.state.app_data.chat_preferences.some((res) => res.module_status === 1))
                          ? true
                          : false
                      :  this.state.custompackage==1 &&  this.state.is_app_already_published == 0  && this.state.iosdata.custom_bundle_files 
                      && this.state.iosdata.custom_bundle_files.permissions  
                      ? 
                        JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_camera_and_photo_library_permission === 1 ||
                        (this.state.app_data.chat_preferences !== null && this.state.app_data.chat_preferences.some((res) => res.module_status === 1))
                          ? true
                          : false
                      :  this.state.custompackage== 0 &&  this.state.is_app_already_published == 0  && this.state.iosdata.default_bundle_files 
                      && this.state.iosdata.default_bundle_files.permissions  
                      ? 
                      JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_camera_and_photo_library_permission === 1 ||
                      (this.state.app_data.chat_preferences !== null && this.state.app_data.chat_preferences.some((res) => res.module_status === 1))
                        ? true
                        : false
                      : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files.permissions 
                      ? 
                      JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_camera_and_photo_library_permission === 1 ||
                      (this.state.app_data.chat_preferences !== null && this.state.app_data.chat_preferences.some((res) => res.module_status === 1))
                        ? true
                        : false
                      : false,
                camera_and_photo_library_permission_description:
                    this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files 
                    && this.state.iosdata.already_have_live_files.permissions 
                    ? 
                      JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_camera_and_photo_library_permission === true ||
                      JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_camera_and_photo_library_permission === 1
                        ? JSON.parse(this.state.iosdata.already_have_live_files.permissions).camera_and_photo_library_permission_description
                        : ""
                    :  this.state.custompackage== 1 &&  this.state.is_app_already_published == 0  && this.state.iosdata.custom_bundle_files 
                    && this.state.iosdata.custom_bundle_files.permissions  
                    ? 
                        JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_camera_and_photo_library_permission === 1 ||
                        JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_camera_and_photo_library_permission === true
                        ? JSON.parse(this.state.iosdata.custom_bundle_files.permissions).camera_and_photo_library_permission_description
                        : ""
                    :  this.state.custompackage== 0 &&  this.state.is_app_already_published == 0  && this.state.iosdata.default_bundle_files 
                    && this.state.iosdata.default_bundle_files.permissions  
                    ? 
                      JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_camera_and_photo_library_permission === 1 ||
                      JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_camera_and_photo_library_permission === true
                      ? JSON.parse(this.state.iosdata.default_bundle_files.permissions).camera_and_photo_library_permission_description
                      : ""
                    : this.state.iosdata.already_have_live_files &&  this.state.iosdata.already_have_live_files.permissions 
                    ? 
                    JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_camera_and_photo_library_permission === true ||
                    JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_camera_and_photo_library_permission === 1
                      ? JSON.parse(this.state.iosdata.already_have_live_files.permissions).camera_and_photo_library_permission_description
                      : ""
                    : "",
                is_enable_contacts_permission:
                    this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files 
                    && this.state.iosdata.already_have_live_files.permissions 
                    ? 
                      JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_contacts_permission === true ||
                      JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_contacts_permission === 1
                        ? true
                        : false
                    :  this.state.custompackage== 1 && this.state.is_app_already_published == 0  && this.state.iosdata.custom_bundle_files 
                    && this.state.iosdata.custom_bundle_files.permissions  
                    ? 
                        JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_contacts_permission === 1 ||
                        JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_contacts_permission === true
                        ? true
                        : false
                    :  this.state.custompackage== 0 && this.state.is_app_already_published == 0  && this.state.iosdata.default_bundle_files 
                    && this.state.iosdata.default_bundle_files.permissions  
                    ? 
                      JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_contacts_permission === 1 ||
                      JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_contacts_permission === true
                      ? true
                      : false
                    : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files.permissions 
                    ? 
                    JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_contacts_permission === true ||
                    JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_contacts_permission === 1
                      ? true
                      : false
                    : false,
                contacts_permission_description:
                    this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files 
                    && this.state.iosdata.already_have_live_files.permissions 
                    ? 
                      JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_contacts_permission === true ||
                      JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_contacts_permission === 1
                        ? JSON.parse(this.state.iosdata.already_have_live_files.permissions).contacts_permission_description
                        : ""
                    :  this.state.custompackage== 1 && this.state.is_app_already_published == 0  && this.state.iosdata.custom_bundle_files 
                    && this.state.iosdata.custom_bundle_files.permissions  
                    ? 
                        JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_contacts_permission === 1 ||
                        JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_contacts_permission === true
                        ? JSON.parse(this.state.iosdata.custom_bundle_files.permissions).contacts_permission_description
                        : ""
                    :  this.state.custompackage== 0 && this.state.is_app_already_published == 0  && this.state.iosdata.default_bundle_files 
                    && this.state.iosdata.default_bundle_files.permissions  
                    ? 
                      JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_contacts_permission === 1 ||
                      JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_contacts_permission === true
                      ? JSON.parse(this.state.iosdata.default_bundle_files.permissions).contacts_permission_description
                      : ""
                    : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files.permissions 
                    ? 
                    JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_contacts_permission === true ||
                    JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_contacts_permission === 1
                      ? JSON.parse(this.state.iosdata.already_have_live_files.permissions).contacts_permission_description
                      : ""
                    : "",
                is_enable_app_tracking_permission:
                    this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files 
                    && this.state.iosdata.already_have_live_files.permissions 
                    ?
                      JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_app_tracking_permission === true ||
                      JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_app_tracking_permission === 1
                        ? true
                        : false
                    :  this.state.custompackage== 1 && this.state.is_app_already_published == 0  && this.state.iosdata.custom_bundle_files 
                    && this.state.iosdata.custom_bundle_files.permissions 
                    ?
                        JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_app_tracking_permission === 1 ||
                        JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_app_tracking_permission === true
                        ? true
                        : false
                    :  this.state.custompackage== 0 && this.state.is_app_already_published == 0  && this.state.iosdata.default_bundle_files
                    && this.state.iosdata.default_bundle_files.permissions 
                    ?
                      JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_app_tracking_permission === 1 ||
                      JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_app_tracking_permission === true
                      ? true
                      : false
                    : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files.permissions 
                    ? 
                    JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_app_tracking_permission === true ||
                    JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_app_tracking_permission === 1
                      ? true
                      : false
                    : false,
                app_tracking_permission_description:
                    this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files 
                    && this.state.iosdata.already_have_live_files.permissions 
                    ? 
                      JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_app_tracking_permission === true ||
                      JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_app_tracking_permission === 1
                        ? JSON.parse(this.state.iosdata.already_have_live_files.permissions).app_tracking_permission_description
                        : ""
                    :  this.state.custompackage== 1 && this.state.is_app_already_published == 0  && this.state.iosdata.custom_bundle_files 
                    && this.state.iosdata.custom_bundle_files.permissions  
                    ? 
                        JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_app_tracking_permission === 1 ||
                        JSON.parse(this.state.iosdata.custom_bundle_files.permissions).is_enable_app_tracking_permission === true
                        ? JSON.parse(this.state.iosdata.custom_bundle_files.permissions).app_tracking_permission_description
                        : ""
                    :  this.state.custompackage== 0 && this.state.is_app_already_published == 0  && this.state.iosdata.default_bundle_files 
                    && this.state.iosdata.default_bundle_files.permissions  
                    ? 
                      JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_app_tracking_permission === 1 ||
                      JSON.parse(this.state.iosdata.default_bundle_files.permissions).is_enable_app_tracking_permission === true
                      ? JSON.parse(this.state.iosdata.default_bundle_files.permissions).app_tracking_permission_description
                      : ""
                    : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files.permissions 
                    ? 
                    JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_app_tracking_permission === true ||
                    JSON.parse(this.state.iosdata.already_have_live_files.permissions).is_enable_app_tracking_permission === 1
                      ? JSON.parse(this.state.iosdata.already_have_live_files.permissions).app_tracking_permission_description
                      : ""
                    : "",
                is_enable_keychain:
                      this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files ?
                      this.state.iosdata.already_have_live_files.is_enable_keychain === 1
                      ? true
                      : false
                    :  this.state.custompackage == 1 && this.state.is_app_already_published == 0 && this.state.iosdata.custom_bundle_files?
                    this.state.iosdata.custom_bundle_files.is_enable_keychain === 1
                      ? true
                      : false
                    :  this.state.custompackage == 0 && this.state.is_app_already_published == 0 && this.state.iosdata.default_bundle_files?
                      this.state.iosdata.default_bundle_files.is_enable_keychain === 1
                        ? true
                        : false
                    : this.state.iosdata.already_have_live_files && this.state.iosdata.already_have_live_files.is_enable_keychain === 1
                    ? true
                    : false,
                keychain_url:
                      this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files 
                    ? this.state.iosdata.already_have_live_files.keychain_url
                    : this.state.custompackage == 1 && this.state.is_app_already_published == 0 && this.state.iosdata.custom_bundle_files
                    ? this.state.iosdata.custom_bundle_files.keychain_url
                    : this.state.custompackage == 0 && this.state.is_app_already_published == 0 && this.state.iosdata.default_bundle_files
                    ? this.state.iosdata.default_bundle_files.keychain_url
                    : this.state.iosdata.already_have_live_files 
                    ? this.state.iosdata.already_have_live_files.keychain_url
                    : '',
                is_notification_enabled :
                      this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files 
                    ?
                      this.state.iosdata.already_have_live_files != '' && this.state.iosdata.already_have_live_files != undefined
                      ? this.state.iosdata.already_have_live_files.is_notification_enabled
                      : 0
                    :  this.state.custompackage == 1 && this.state.is_app_already_published == 0 && this.state.iosdata.custom_bundle_files
                    ?
                      this.state.iosdata.custom_bundle_files != '' && this.state.iosdata.custom_bundle_files != undefined
                      ? this.state.iosdata.custom_bundle_files.is_notification_enabled
                      : 0
                    : this.state.iosdata.already_have_live_files != '' && this.state.iosdata.already_have_live_files != undefined
                    ? this.state.iosdata.already_have_live_files.is_notification_enabled
                    : 0,
                is_rich_notification_enabled :
                    this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files 
                      ?
                        this.state.iosdata.already_have_live_files != '' && this.state.iosdata.already_have_live_files != undefined
                        ? this.state.iosdata.already_have_live_files.is_rich_notification_enabled
                        : 0
                      :  this.state.custompackage == 1 && this.state.is_app_already_published == 0 && this.state.iosdata.custom_bundle_files
                      ?
                        this.state.iosdata.custom_bundle_files != '' && this.state.iosdata.custom_bundle_files != undefined
                        ? this.state.iosdata.custom_bundle_files.is_rich_notification_enabled
                        : 0
                      : this.state.iosdata.already_have_live_files != '' && this.state.iosdata.already_have_live_files != undefined
                      ? this.state.iosdata.already_have_live_files.is_rich_notification_enabled
                      : 0,
                is_admob_enabled :
                    this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files 
                    ?
                      this.state.iosdata.already_have_live_files != '' && this.state.iosdata.already_have_live_files != undefined
                      ? this.state.iosdata.already_have_live_files.is_admob_enabled
                      : 0
                    :  this.state.custompackage == 1 && this.state.is_app_already_published == 0 && this.state.iosdata.custom_bundle_files
                    ?
                      this.state.iosdata.custom_bundle_files != '' && this.state.iosdata.custom_bundle_files != undefined
                      ? this.state.iosdata.custom_bundle_files.is_admob_enabled
                      : 0
                    :  this.state.custompackage == 0 && this.state.is_app_already_published == 0 && this.state.iosdata.custom_bundle_files
                      ?
                        this.state.iosdata.default_bundle_files != '' && this.state.iosdata.default_bundle_files != undefined
                        ? this.state.iosdata.default_bundle_files.is_admob_enabled
                        : 0
                    :  this.state.iosdata.already_have_live_files != '' && this.state.iosdata.already_have_live_files != undefined
                    ? this.state.iosdata.already_have_live_files.is_admob_enabled
                    : 0,
                is_google_login_enabled :
                
                  this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.iosdata.already_have_live_files 
                    ?
                      this.state.iosdata.already_have_live_files != '' && this.state.iosdata.already_have_live_files != undefined
                      ? this.state.iosdata.already_have_live_files.is_google_login_enabled
                      : 0
                    :  this.state.custompackage == 1 && this.state.is_app_already_published == 0 && this.state.iosdata.custom_bundle_files
                    ?
                      this.state.iosdata.custom_bundle_files != '' && this.state.iosdata.custom_bundle_files != undefined
                      ? this.state.iosdata.custom_bundle_files.is_google_login_enabled
                      : 0
                    :  this.state.custompackage == 0 && this.state.is_app_already_published == 0 && this.state.iosdata.custom_bundle_files
                    ?
                      this.state.iosdata.default_bundle_files != '' && this.state.iosdata.default_bundle_files != undefined
                      ? this.state.iosdata.default_bundle_files.is_google_login_enabled
                      : 0
                    :  this.state.iosdata.already_have_live_files != '' && this.state.iosdata.already_have_live_files != undefined
                    ? this.state.iosdata.already_have_live_files.is_google_login_enabled
                    : 0,
      
                  });
      }
    
        //Form submit code
    handleteamid = (e) => {
        this.setState({
        teamid: e.target.value,
        });
    };

    handlecertificate_password = (e) => {
        this.setState({
          certificate_password: e.target.value,
        });
      };
    
      handleadmob_app_id = (e) => {
        this.setState({
          admob_app_id: e.target.value,
        });
      };

      handleprovision2 = (e) => {
        let self = this;
        let reader = new FileReader();
        let file = e.target.files[0];
        if (file !== undefined) {
          if (
            this.state.provision1filename !==
            file.name
          ) {
            if (file.name.indexOf("mobileprovision") !== -1) {
              this.setState({
                provision2filename: file.name,
              });
              reader.onload = function (upload) {
                self.setState(
                  {
                    provision2: upload.target.result,
                  },
                  () => {
                    self.formprovision();
                  }
                );
              };
              reader.readAsDataURL(file);
            } else {

              errortoast("File format not supported. Upload mobileprovision file only.");
            }
          } else {
            errortoast("File already uploaded.");
          }
        }
      };
    
      handlecertificate = (e) => {
        let self = this;
        let reader = new FileReader();
        let file = e.target.files[0];
    
        if (file !== undefined) {
          if (
            this.state.pushnotificationfilename !==
            file.name
          ) {
            if (file.name.indexOf("p12") !== -1) {
              this.setState({
                certificatefilename: file.name,
              });
              reader.onload = function (upload) {
                self.setState(
                  {
                    certificate: upload.target.result,
                  },
                  () => {
                    self.formcertificate();
                  }
                );
              };
              reader.readAsDataURL(file);
            } else {
             
              errortoast("File format not supported. Upload p12 file only.");
            }
          } else {
            errortoast("File already uploaded.");
          }
        }
      };

      handlepermission_des = (e) => {
        const { name, value } = e.target;
        this.setState({
          [name]: value,
        });
      };
      handlepermission = (e) => {
        const { name, checked } = e.target;
        this.setState({
          [name]: checked,
        });
      };
      handlekeychain_url = (e) => {
        this.setState({
          keychain_url: e.target.value,
        });
      };
      handle_api_key = (e) =>
       {
        this.setState({
            api_key: e.target.value,
          });
       }
      handlekeygenerate = () => {
        if (this.state.teamid) {
          const fileData = JSON.stringify({
            webcredentials: {
              apps: [
                `${this.state.teamid}.${
                  this.state.ios_status === true
                    ? this.state.ios_bundle_id
                    : this.state.bundle_identifier
                }`,
              ],
            },
          });
          const blob = new Blob([fileData], { type: "application/File" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.download = `apple-app-site-association`;
          link.href = url;
          link.click();
        } else {
          
          errortoast('Team ID is required')
        }
      };
      handlekeychain_urlOnBlur = (e) => {
        let str = e.target.value;
        if (str.includes("https://")) {
          let updateStr = str.replace("https://", "");
          this.setState({
            keychain_url: updateStr,
          });
        }
        if (str.includes("http://")) {
          let updateStr = str.replace("http://", "");
          this.setState({
            keychain_url: updateStr,
          });
        }
      };
      handlekeychaintoggle = (e) => {
        this.setState({
          is_enable_keychain: e.target.checked,
        });
      };
      handleis_supporting_ipad = (e) => {
        this.setState({
          is_supporting_ipad: e.target.checked,
        });
      };

      handlecheck = (e) => {
        const { name, value } = e.target;
        if(name == 'bundle_id')
        {
          this.setState({ios_bundle_id:e.target.value});
        }
        this.setState({
          [name]: value,
        });
      };
      handleversionnamecheck = (e) => {
        const value = e.target.value;
        this.setState({ current_version_name: value});
      };

      formcertificate = () => {
        this.setState({
          disabledsubmitbutton: true,
        });
        const params = {
          original_path:
            localStorage.getItem("user_id") +
            "/" +
            localStorage.getItem("Appid") +
            "/ipa/certificates",
          original: this.state.certificate,
          imgname: this.state.certificatefilename,
        };
        certificate(params, this, () => {});
      };
      formprovision = () => {
        this.setState({
          disabledsubmitbutton: true,
        });
        const provision2params = {
          original_path:
            localStorage.getItem("user_id") +
            "/" +
            localStorage.getItem("Appid") +
            "/ipa/profiles",
          original: this.state.provision2,
          imgname: this.state.provision2filename,
        };
        provision2s3(provision2params, this, () => {});
      };

      iossubmit = (checker) => 
      {

        if(checker==="build"){
          let pattern = /^([a-zA-Z][a-z0-9A-Z_-]+)(\.[a-z0-9A-Z_-]+)*$/i;
          let result = pattern.test(this.state.bundle_id);

          let result1 = versionRegex.test(this.state.current_version_name?.trim());

          const isPublishedOrCustomPackage = 
                this.state.is_app_already_published_ios  ||
                this.state.custompackage ;

          if (isPublishedOrCustomPackage && !result) {
            errortoast("Enter a valid bundle id.");
            return;
          }
          if (isPublishedOrCustomPackage && !result1) {
            if (specialCharRegex.test(this.state.current_version_name?.trim())) {
              errortoast('Special characters are not allowed in the build version');
              return;
            }
            
            if (!versionRegex.test(this.state.current_version_name?.trim())) {
              errortoast('Invalid build version format. Ensure it follows X.Y.Z, without leading zeros');
              return;
            }
          }

        if (this.state.certificates3name == undefined || this.state.certificates3name == null || this.state.certificates3name === "") 
        {
         
          errortoast("iPhone distribution certificate is required");
          return;
        } 
        else if (this.state.teamid === null || this.state.teamid === ""   ||  this.state.teamid.trim() === "") 
        {
          
          errortoast("Team ID is required");
          return;
        } 
        else if (this.state.provision2s3name == null || this.state.provision2s3name == undefined || this.state.provision2s3name === "") 
        {
          
          errortoast("iPhone Provisioning Profile is required");
          return;
        } 
        else if 
        (
          this.state.is_enable_keychain === true &&
          this.state.keychain_url && this.state.keychain_url.trim() === ""
        ) 
        {
          
          errortoast("Domain name is required");
          return;
        }
        else if 
        (
          this.state.is_enable_location_permission === true && 
          this.state.location_permission_description === ""
        )
         
        {
          
          errortoast("Location description is required");

          return;
        } 
        else if 
        (
          this.state.is_enable_contacts_permission === true &&
          this.state.contacts_permission_description === ""
        )
        {
          
          errortoast("Contact description is required");

          return;
        }
        else if 
        (
          this.state.is_enable_microphone_permission === true &&
          this.state.microphone_permission_description === ""
        ) 
        {
         
          errortoast("Microphone description is required.");

          return;
        } 
        else if 
        (
          this.state.is_enable_speech_recognition_permission === true &&
          this.state.speech_recognition_permission_description === ""
        ) 
        {

          errortoast("Speech recognition description is required.");

          return;
        }
        else if 
        (
          this.state.is_enable_calender_permission === true &&
          this.state.calender_permission_description === ""
        )
        {
        
          errortoast("Calendar description is required.");

          return;
        } 
        else if 
        (
          this.state.is_enable_reminder_permission === true &&
          this.state.reminder_permission_description === ""
        ) 
        {
          errortoast("Reminders description is required.");
          return;
        } 
        else if 
        (
          this.state.is_enable_camera_and_photo_library_permission === true &&
          this.state.camera_and_photo_library_permission_description === ""
        ) 
        {
          
          errortoast("Please enter camera & media library description is required.");

          return;
        } 
        else if 
        (
          this.state.is_enable_app_tracking_permission === true &&
          this.state.app_tracking_permission_description === ""
        ) 
        {
         
          errortoast("Tracking permission description is required.");

          return;
        } 
        else if 
        (
          ( 
            this.state.is_app_already_published_ios === true ||
             this.state.is_app_already_published_ios === 1  ||
             this.state.custompackage === true ||
             this.state.custompackage === 1 
          ) &&
          ( !this.state.current_version_name ||  !this.state.current_version_name.trim() )
        ) 
        {
         
          errortoast("Build version is required.");

          return;
        } 
        else if 
        (
          ( 
            this.state.is_app_already_published_ios === true ||
             this.state.is_app_already_published_ios === 1 ||
             this.state.custompackage === true ||
             this.state.custompackage === 1 
          ) &&
          ( !this.state.bundle_id ||  !this.state.bundle_id.trim() )
        ) 
        {
         
          errortoast("Bundle ID is required.");

          return;
        } 
        else 
        {
  
          this.setState({buildclass:"new-loader",buttonbuildclass:"d-none"});
          this.UploadcertificatetoS3(checker);
        }
      } else {
        if (this.state.current_version_name?.trim() && specialCharRegex.test(this.state.current_version_name?.trim())) {
          errortoast('Special characters are not allowed in the build version');
          return;
        }
        if (this.state.current_version_name?.trim() && !versionRegex.test(this.state.current_version_name?.trim())) {
          errortoast('Invalid build version format. Ensure it follows X.Y.Z, without leading zeros');
          return;
        }
          this.UploadcertificatetoS3(checker);
          this.setState({saveclass:"new-blue-loader",buttonsaveclass:"d-none"});
      }
      };

      UploadcertificatetoS3 = (checker) => 
      {

        const iosdata = {
          is_draft:checker==="build"?0:1,
          app_id: parseInt(localStorage.getItem("Appid")),
          issuer_id : this.state.issuer_id,
          key_id : this.state.key_id,
          key_file : this.state.key_file_s3_name,
          status: this.state.custompackage ==  1 || this.state.custompackage ==  true ? 1 : 0,
          bundle_id:this.state.bundle_id,
          current_version:this.state.current_version_name,
          is_app_already_published:this.state.is_app_already_published_ios == true || this.state.is_app_already_published_ios == 1 ? 1 : 0,
          certificate: this.state.certificates3name,
          certificate_password: this.state.certificate_password,
          team_id: this.state.teamid ? this.state.teamid.trim():"",
          profile: this.state.provision2s3name,

          //permission
          is_enable_location_permission: this.state.is_enable_location_permission === true ? 1 : 0,
          location_permission_description: this.state.location_permission_description && this.state.location_permission_description !== null
                                        ? this.state.location_permission_description.trim()
                                        : "",
          is_enable_calender_permission: this.state.is_enable_calender_permission === true ? 1 : 0,
          calender_permission_description: this.state.calender_permission_description && this.state.calender_permission_description !== null
                                         ? this.state.calender_permission_description.trim()
                                         : "",
          is_enable_microphone_permission: this.state.is_enable_microphone_permission === true ? 1 : 0,
          microphone_permission_description: this.state.microphone_permission_description && this.state.microphone_permission_description !== null
                                           ? this.state.microphone_permission_description.trim()
                                           : "",
          is_enable_reminder_permission: this.state.is_enable_reminder_permission === true ? 1 : 0,
          reminder_permission_description: this.state.reminder_permission_description && this.state.reminder_permission_description !== null
                                         ? this.state.reminder_permission_description.trim()
                                         : "",
          is_enable_speech_recognition_permission: this.state.is_enable_speech_recognition_permission === true ? 1 : 0,
          speech_recognition_permission_description: this.state.speech_recognition_permission_description && this.state.speech_recognition_permission_description !== null
                                                   ? this.state.speech_recognition_permission_description.trim()
                                                   : "",
          is_enable_camera_and_photo_library_permission: this.state.is_enable_camera_and_photo_library_permission === true
                                                        ? 1
                                                        : 0,
          camera_and_photo_library_permission_description: this.state.camera_and_photo_library_permission_description && this.state.camera_and_photo_library_permission_description !== null
                                                         ? this.state.camera_and_photo_library_permission_description.trim()
                                                         : "",
          is_enable_contacts_permission: this.state.is_enable_contacts_permission === true ? 1 : 0,
          contacts_permission_description: this.state.contacts_permission_description && this.state.contacts_permission_description !== null
                                         ? this.state.contacts_permission_description.trim()
                                         : "",
          is_enable_app_tracking_permission: this.state.is_enable_app_tracking_permission === true ? 1 : 0,
          app_tracking_permission_description: this.state.app_tracking_permission_description && this.state.app_tracking_permission_description !== null
                                             ? this.state.app_tracking_permission_description.trim()
                                             : "",
          is_enable_keychain: this.state.is_enable_keychain === true ? 1 : 0,
          keychain_url: this.state.keychain_url,
          is_supporting_ipad:this.state.is_supporting_ipad === true ? 1: 0,
          api_key:this.state.api_key,
          // is_admob_enabled:this.state.is_admob_enabled ==  true || this.state.is_admob_enabled == 1 ? 1 : 0,
          // admob_app_id:this.state.admob_app_id
        };

        if(checker == "build")
        {


          if(this.state.is_success > 0 && 
            ((this.state.old_current_version_name !=  this.state.current_version_name) ||
            (this.state.old_bundle_id !=  this.state.bundle_id))  
          )
          {
            iosdata.is_reset = 1;
          }
        }
          iosbuilingapp(iosdata, this,checker);
        
      };

      handleReset = (e) => 
      {
        
        const param = {
          app_id: parseInt(localStorage.getItem("Appid")),
          type: "ios",
        };

        this.setState({resetclass:"new-loader",buttonresetclass:"d-none"});
        
        iosAndroidBuildReset(param, this);
        
      };

      closePopup = () =>
    {
        this.setState({errormodal:false});
    }

    finalbuild = () => {
        const buildparams = {
          app_id: localStorage.getItem("Appid"),
          platform: "ios",
          is_beta_release: 0,
        };
        BuildappApi(buildparams, this);
      };

      copy = (e, id) => 
      {
        
        const el = document.createElement("input");
        
        el.value = id;
        
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        sucesstoast("Copied!");
        e.stopPropagation();
      };

        //admob handling
  handleis_admob_enabled = (e) => {
    this.setState({
      is_admob_enabled: e.target.checked,
    });
  };

  removefile = (name) => 
  {
    if(name == 'certificatefilename')
    {
      this.setState({certificatefilename:'',certificates3name:''});
    }

    if(name == 'provision2filename')
    { 
      this.setState({provision2filename:'',provision2s3name:''});
    }

  };

      

    render() {
        return (
    <>
        <div>
        <Header customclass={"header-responsive-new"} />
        </div>

        <section className="dasboard_page mobile-display mt-4 mt-md-0 cms-module">
      <Leftpanel handleRightPanelExpand={this.handleRightPanelExpand} />
      <div className={`right-panel-gap ${(localStorage.getItem("appSideBarCollapse") !== undefined &&
                      localStorage.getItem("appSideBarCollapse") !== null&&
                      localStorage.getItem("appSideBarCollapse") === "true") ?
                          " right-panel-gap-expand": ""}`}>


        <div className="download-head build-configuration">
            <h1>iOS Build Configuration
            </h1>
            {this.state.spinner ?
             <></>
            :
              <div className="build-configuration-head-right">
                  {/* <button onClick={this.handleAttributePopUp} className="download-reset-btn">Reset</button> */}
                  <p className="download-updated-date">
                  {this.state.updatedAt   ? `Updated ${moment(this.state.updatedAt).format("D MMMM YYYY, HH:mm")}` : ''}
                  </p>
                  <button 
                  className={`download-save-btn ${this.state.is_generating_certification_in_process === false ?"":"download-save-btn-disabled"}`}
                  onClick={()=>this.state.is_generating_certification_in_process === false ? this.iossubmit() : null}
                  style={{
                  // backgroundColor:localStorage.getItem('button_background_color'),
                  border:'1px solid '+localStorage.getItem('button_background_color'),
                  color:localStorage.getItem('button_text_color'),
                  "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                  "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
                  "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                  }}
                  >
                      <div class="d-flex align-items-center justify-content-center h-100 w-100">
                      <div class={this.state.saveclass}>
                        
                      </div>
                      <div class={this.state.buttonsaveclass}
                        style={{
                        color:localStorage.getItem('button_background_color'),
                        }}
                      >Save</div>
                  </div>
                  </button>
                  <button 
                  className={`download-build-app-btn ${this.state.is_generating_certification_in_process === false ?"":"download-build-app-btn-disabled"}`}
                  onClick={()=>this.state.is_generating_certification_in_process === false ? this.iossubmit('build') : null}
                                  style={{
                                      backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                                    }}
                  >
                  <div class="d-flex align-items-center justify-content-center h-100 w-100">
                      <div class={this.state.buildclass}>
                        
                      </div>
                      <div class={this.state.buttonbuildclass}>Build app</div>
                  </div>
                  </button>
              </div>
            }
        </div>
        {this.state.spinner ?
                <div className="chat-main-topdiv" style={{ overflow: "hidden" }}>
                  <div className="newspinner">
                    <svg xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      style={{ margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s" }}
                      width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                      <g transform="translate(20 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <circle cx="0" cy="0" r="6" fill="#e6261f" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                          <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                            style={{ animationplaystate: "running", animationdelay: "0s" }}>
                          </animateTransform>
                        </circle>
                      </g>
                      <g transform="translate(40 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <circle cx="0" cy="0" r="6" fill="#f7d038" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                          <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                            style={{ animationplaystate: "running", animationdelay: "0s" }}>
                          </animateTransform>
                        </circle>
                      </g>
                      <g transform="translate(60 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <circle cx="0" cy="0" r="6" fill="#49da9a" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                          <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                            style={{ animationplaystate: "running", animationdelay: "0s" }}>

                          </animateTransform>
                        </circle>
                      </g>
                      <g transform="translate(80 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <circle cx="0" cy="0" r="6" fill="#4355db" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                          <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                            style={{ animationplaystate: "running", animationdelay: "0s" }}>

                          </animateTransform>
                        </circle>
                      </g>
                    </svg>
                  </div>
                </div>

                :
                <>
              <div className="android-build-config">
                  <div className="android-build-conf-left">

                  <Accordion>

                      <Accordion.Item eventKey="0" className="android-config-main">

                      <h4 className="android-id-web">Bundle ID: <span>
                              {this.state.ios_bundle_id && this.state.ios_bundle_id != '' 
                                ? this.state.ios_bundle_id
                                : this.state.bundle_identifier}</span> 
                                <svg onClick={this.state.ios_bundle_id && this.state.ios_bundle_id != '' 
                                  ? (e)=>this.copy(e, this.state.ios_bundle_id)
                                  : (e)=>this.copy(e, this.state.bundle_identifier)} id="Group_41547" data-name="Group 41547" xmlns="http://www.w3.org/2000/svg" width="14.001" height="14" viewBox="0 0 14.001 14">
                          <g id="Path_9276" data-name="Path 9276" transform="translate(-450.5 -124.5)" fill="none" stroke-miterlimit="10">
                              <path d="M461.5,125.923V128.5h-5.72a1.189,1.189,0,0,0-1.28,1.28v5.72h-2.577a1.419,1.419,0,0,1-1.423-1.423v-8.154a1.419,1.419,0,0,1,1.423-1.423h8.154A1.419,1.419,0,0,1,461.5,125.923Z" stroke="none"/>
                              <path d="M 451.9228515625 125.5000152587891 C 451.6857299804688 125.5000152587891 451.5 125.6857452392578 451.5 125.9228668212891 L 451.5 134.0771636962891 C 451.5 134.3142700195312 451.6857299804688 134.5 451.9228515625 134.5 L 453.5 134.5 L 453.5 129.7800140380859 C 453.5 128.4375762939453 454.4375610351562 127.5 455.7799682617188 127.5 L 460.5 127.5 L 460.5 125.9228668212891 C 460.5 125.6857452392578 460.3142395019531 125.5000152587891 460.0771179199219 125.5000152587891 L 451.9228515625 125.5000152587891 M 451.9228515625 124.5000152587891 L 460.0771179199219 124.5000152587891 C 460.8656616210938 124.5000152587891 461.5 125.1343078613281 461.5 125.9228668212891 L 461.5 128.5 L 455.7799682617188 128.5 C 454.9913940429688 128.5 454.5 128.991455078125 454.5 129.7800140380859 L 454.5 135.5 L 451.9228515625 135.5 C 451.13427734375 135.5 450.5 134.8657531738281 450.5 134.0771636962891 L 450.5 125.9228668212891 C 450.5 125.1343078613281 451.13427734375 124.5000152587891 451.9228515625 124.5000152587891 Z" stroke="none" fill="#bcc1ce"/>
                          </g>
                          <g id="Path_9277" data-name="Path 9277" transform="translate(-452.499 -126.5)" fill="none" stroke-miterlimit="10">
                              <path d="M466.5,130.923v8.154a1.419,1.419,0,0,1-1.423,1.423h-8.154a1.419,1.419,0,0,1-1.423-1.423v-8.154a1.419,1.419,0,0,1,1.423-1.423h8.154A1.419,1.419,0,0,1,466.5,130.923Z" stroke="none"/>
                              <path d="M 456.9228210449219 130.5000152587891 C 456.6856994628906 130.5000152587891 456.4999694824219 130.6857452392578 456.4999694824219 130.9228515625 L 456.4999694824219 139.0771484375 C 456.4999694824219 139.3142700195312 456.6856994628906 139.5000152587891 456.9228210449219 139.5000152587891 L 465.0771179199219 139.5000152587891 C 465.3142395019531 139.5000152587891 465.4999694824219 139.3142700195312 465.4999694824219 139.0771484375 L 465.4999694824219 130.9228515625 C 465.4999694824219 130.6857452392578 465.3142395019531 130.5000152587891 465.0771179199219 130.5000152587891 L 456.9228210449219 130.5000152587891 M 456.9228210449219 129.5000152587891 L 465.0771179199219 129.5000152587891 C 465.8656921386719 129.5000152587891 466.4999694824219 130.1342926025391 466.4999694824219 130.9228515625 L 466.4999694824219 139.0771484375 C 466.4999694824219 139.86572265625 465.8656921386719 140.5000152587891 465.0771179199219 140.5000152587891 L 456.9228210449219 140.5000152587891 C 456.1342163085938 140.5000152587891 455.4999694824219 139.86572265625 455.4999694824219 139.0771484375 L 455.4999694824219 130.9228515625 C 455.4999694824219 130.1342926025391 456.1342163085938 129.5000152587891 456.9228210449219 129.5000152587891 Z" stroke="none" fill="#bcc1ce"/>
                          </g>
                          </svg>
                          </h4>

                      <Accordion.Header>

                      <div className="android-config-toggle">

                      <h5><svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
                      <g id="Group_41935" data-name="Group 41935" transform="translate(-260 -170)">
                          <rect id="Rectangle_967" data-name="Rectangle 967" width="54" height="54" rx="3" transform="translate(260 170)" fill="#f8fafe"/>
                          <g id="apple-logo" transform="translate(276.116 184)">
                          <g id="Group_40146" data-name="Group 40146">
                              <path id="Path_104606" data-name="Path 104606" d="M57.988,118.489a5.949,5.949,0,0,1-1.078-3.484,5.568,5.568,0,0,1,1.015-3.234,10.124,10.124,0,0,1,1.781-1.844,8.057,8.057,0,0,0-1.625-1.562,5.713,5.713,0,0,0-3.328-1.015,8.818,8.818,0,0,0-2.7.531,7.825,7.825,0,0,1-2.234.531,10.051,10.051,0,0,1-2.156-.469,10.4,10.4,0,0,0-2.766-.469,5.394,5.394,0,0,0-4.437,2.25,9.271,9.271,0,0,0-1.766,5.844,16.019,16.019,0,0,0,2.3,7.859q2.328,4.047,4.7,4.047a5.649,5.649,0,0,0,2.062-.531,6.022,6.022,0,0,1,2.219-.516,6.909,6.909,0,0,1,2.359.5,7.224,7.224,0,0,0,2.187.5q2,0,4.016-3.062a15.082,15.082,0,0,0,1.922-3.906A4.753,4.753,0,0,1,57.988,118.489Z" transform="translate(-38.692 -101.474)" fill="#666"/>
                              <path id="Path_104607" data-name="Path 104607" d="M237,4.937a6.414,6.414,0,0,0,1.453-2.156A5.96,5.96,0,0,0,238.9.656c0-.042,0-.094-.008-.156s-.008-.114-.008-.156a1.5,1.5,0,0,1-.039-.172A1.457,1.457,0,0,0,238.81,0,6.227,6.227,0,0,0,234.9,2.313a7.126,7.126,0,0,0-1.219,4.015,8.731,8.731,0,0,0,1.625-.266A4.427,4.427,0,0,0,237,4.937Z" transform="translate(-223.014 0)" fill="#666"/>
                          </g>
                          </g>
                      </g>
                      </svg>App identity<span class="build-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                              <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none"/>
                              <circle cx="7" cy="7" r="6.4" fill="none"/>
                              </g>
                              <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                              <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                          </g>
                          </svg>
                          <span class="build-tooltipsubheader">Customize your app's identity. This step is optional. Enable only if you need to modify the key identifiers of your app.</span>
                          </span>
                          </h5>
                      

                      <div className="android-id-toggle">
                          
                          <svg className="thechatarrow" id="Group_39685" data-name="Group 39685" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                          <circle id="Ellipse_6953" data-name="Ellipse 6953" cx="11" cy="11" r="11" fill="none"/>
                          <path id="Path_81302" data-name="Path 81302" d="M-12935-18908l6-6,6,6" transform="translate(12940.501 18921.504)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
                          </svg>
                      </div>

                      </div>

                      </Accordion.Header>

                      <Accordion.Body>
                      
                      <div className="row" style={{marginBottom: "10px"}}>

                          <div className="col-md-6">

                              <div class="android-setting-toggle" style={{marginTop: "0px"}}>
                                  <h6>Migrate my live app<span class="build-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                              <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none"/>
                              <circle cx="7" cy="7" r="6.4" fill="none"/>
                              </g>
                              <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                              <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                          </g>
                          </svg>
                          <span class="build-tooltipsubheader">Enable to migrate your existing live app. This helps retain your app users.</span>
                          </span></h6>
                                  <label class="switch" for="isliveapp">
                                      <input type="checkbox" id="isliveapp"
                                            checked={this.state.is_app_already_published_ios}
                                            onClick={(e) => this.handleLiveAppToggleIOS(e)}/>
                                      <div class="slider round"></div>
                                  </label>
                              </div>


                              <div class={this.state.is_app_already_published_ios ? "android-setting-toggle download-toggle-lock" : "android-setting-toggle"}>
                                  <h6>Customize my bundle identifiers<span class="build-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                              <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none"/>
                              <circle cx="7" cy="7" r="6.4" fill="none"/>
                              </g>
                              <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                              <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                          </g>
                          </svg>
                          <span class="build-tooltipsubheader">Enable to take control of your app's identity. This helps in retaining your existing app’s identifiers or personalizing them according to your preferences.</span>
                          </span></h6>
                                  <label class="switch" for="custompackage">
                                      <input type="checkbox" id="custompackage"  name="custompackage" 
                                            checked={this.state.custompackage || this.state.is_app_already_published_ios}
                                            onClick={(e) => this.handlecustompackage(e)}
                                            readOnly={this.state.is_app_already_published_ios}
                                            />
                                      <div class="slider round"></div>
                                  </label>
                              </div>
                          </div>

                      </div>
                    {this.state.custompackage || this.state.is_app_already_published_ios ?
                      <div className="row">
                          <div className="col-md-6">
                              <div class="android-setting-input">
                                  <h5>Bundle ID<span class="build-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                              <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none"/>
                              <circle cx="7" cy="7" r="6.4" fill="none"/>
                              </g>
                              <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                              <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                          </g>
                          </svg>
                          <span class="build-tooltipsubheader">The Bundle ID serves as a unique identifier for your app on the device and within Apple’s App Store ecosystem. It is typically in reverse domain format (com.example.app)</span>
                          </span></h5>
                                  <input 
                                  type="text"
                                  name="bundle_id"
                                  required
                                  value={this.state.bundle_id}
                                  onChange={(e) => this.handlecheck(e)}
                                  />
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div class="android-setting-input">
                                  <h5>Build version<span class="build-tooltip-two">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                              <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none"/>
                              <circle cx="7" cy="7" r="6.4" fill="none"/>
                              </g>
                              <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                              <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                          </g>
                          </svg>
                          <span class="build-tooltipsubheader-two">The Build version helps track and manage different releases of your app. If you’re migrating your app, the version number you input will be automatically incremented by 1.0.0 in the next immediate build.  If your current app version is 1.2.1, the next build would be version 2.0.0.</span>
                          </span></h5>
                                  <input 
                                  type="text" 
                                  name="current_version_name"
                                  required
                                  value={this.state.current_version_name}
                                  onChange={(e) => this.handleversionnamecheck(e)}/>
                              </div>
                          </div>
                      </div>
                    : <></>
                    }

                      
                      </Accordion.Body>

                      </Accordion.Item>

                      <Accordion.Item eventKey="1" className="android-config-main">

                      <Accordion.Header>

                      <div className="android-config-toggle">

                      <h5><svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
                      <defs>
                          <linearGradient id="linear-gradient" x1="0.5" y1="1" x2="0.5" gradientUnits="objectBoundingBox">
                          <stop offset="0" stop-color="#1d76f2"/>
                          <stop offset="1" stop-color="#1ac2fb"/>
                          </linearGradient>
                      </defs>
                      <g id="Group_41934" data-name="Group 41934" transform="translate(-260 -285)">
                          <rect id="Rectangle_967" data-name="Rectangle 967" width="54" height="54" rx="3" transform="translate(260 285)" fill="#f8fafe"/>
                          <path id="Path_84796" data-name="Path 84796" d="M160.508,218.33h0l-1.38,2.39a1.824,1.824,0,0,1-3.159-1.824l1.017-1.761.1-.169a1.505,1.505,0,0,1,1.464-.6s2.024.22,2.17,1.271A1.231,1.231,0,0,1,160.508,218.33Zm19.572-6.1h-4.3a.635.635,0,0,1-.471-.185l0-.006-4.606-7.978-.006,0-.276-.4c-.453-.692-1.172,1.078-1.172,1.078-.858,1.973.122,4.216.464,4.894l6.4,11.081a1.824,1.824,0,1,0,3.159-1.824l-1.6-2.771c-.031-.067-.085-.249.243-.25h2.174a1.824,1.824,0,1,0,0-3.648Zm-8.358,2.48s.23,1.168-.659,1.168H155.156a1.824,1.824,0,0,1,0-3.648h4.09c.66-.038.817-.419.817-.419l0,0,5.338-9.246h0a.407.407,0,0,0,0-.374l-1.763-3.053a1.824,1.824,0,1,1,3.159-1.824l.818,1.416.816-1.414a1.824,1.824,0,1,1,3.159,1.824L164.166,212c-.032.078-.042.2.2.223h4.44v.043A2.983,2.983,0,0,1,171.722,214.705Z" transform="translate(119.668 102.602)" fill="url(#linear-gradient)"/>
                      </g>
                      </svg>
                      Apple app store<span class="build-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                              <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none"/>
                              <circle cx="7" cy="7" r="6.4" fill="none"/>
                              </g>
                              <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                              <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                          </g>
                          </svg>
                          <span class="build-tooltipsubheader">Configure settings for your iOS app on the Apple App Store. </span>
                          </span></h5>
                      

                      <div className="android-id-toggle">
                          
                          <svg className="thechatarrow" id="Group_39685" data-name="Group 39685" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                          <circle id="Ellipse_6953" data-name="Ellipse 6953" cx="11" cy="11" r="11" fill="none"/>
                          <path id="Path_81302" data-name="Path 81302" d="M-12935-18908l6-6,6,6" transform="translate(12940.501 18921.504)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
                          </svg>
                      </div>

                      </div>

                      </Accordion.Header>

                      <Accordion.Body>
                      
                      <div className="row">
                          <div className="col-md-6">
                              <div class="android-setting-toggle build-gap-zero">
                                  <h6>Enable iPad support<span class="build-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                              <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none"/>
                              <circle cx="7" cy="7" r="6.4" fill="none"/>
                              </g>
                              <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                              <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                          </g>
                          </svg>
                          <span class="build-tooltipsubheader">Enable to include iPad compatibility.</span>
                          </span></h6>
                                  <label class="switch" for="is_supporting_ipad">
                                      <input type="checkbox" 
                                            id="is_supporting_ipad"
                                            name="is_supporting_ipad" 
                                            checked={this.state.is_supporting_ipad}
                                            onChange={(e) =>this.handleis_supporting_ipad(e)}/>
                                      <div class="slider round"></div>
                                  </label>
                              </div>
                          </div>
                      </div>
                      { parseInt(localStorage.getItem("website_technology"))!==3 &&  
                        parseInt(localStorage.getItem("website_technology"))!==2 &&
                      <div className="row">
                          <div className="col-md-6">
                              <div class="android-setting-toggle">
                                  <h6>Enable iCloud Keychain<span class="build-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                              <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none"/>
                              <circle cx="7" cy="7" r="6.4" fill="none"/>
                              </g>
                              <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                              <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                          </g>
                          </svg>
                          <span class="build-tooltipsubheader">Enable to activate iCloud Keychain feature in your app to allow your app users to store their login credentials in their keychain.</span>
                          </span></h6>
                                  <label class="switch" for="is_enable_keychain">
                                      <input type="checkbox"  id="is_enable_keychain"
                                            checked={this.state.is_enable_keychain}
                                            onChange={(e) =>this.handlekeychaintoggle(e)}/>
                                      <div class="slider round"></div>
                                  </label>
                              </div>
                          </div>
                      </div> }

                      {this.state.is_enable_keychain && 
                      parseInt(localStorage.getItem("website_technology"))!==3 &&  
                      parseInt(localStorage.getItem("website_technology"))!==2 &&
                      (
                      <div className="row">
                          <div className="col-md-6">
                              <div className="android-setting-input domain-name-download">
                              <h5>Domain name<span className="build-tooltip">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg>
                                  <span className="build-tooltipsubheader">Enter the domain associated with your app to generate the corresponding file. App users will be prompted to use their keychain credentials when accessing this domain through a Safari browser. Upload the generated file to the root folder of your website.</span>
                                  </span></h5>
                                  <input type="text" value={this.state.keychain_url}
                                        required
                                        onBlur={(e) => this.handlekeychain_urlOnBlur(e)}
                                        onChange={(e) => this.handlekeychain_url(e)}/>
                                  {this.state.keychain_url !== null &&
                                    this.state.keychain_url.trim() !== "" && 
                                      (
                                          <a href={void(0)} onClick={(e) =>this.handlekeygenerate(e)} target="_blank" style={{color:"blue"}}>Download <svg xmlns="http://www.w3.org/2000/svg" width="11.2" height="11.2" viewBox="0 0 11.2 11.2">
                                          <g id="Group_40162" data-name="Group 40162" transform="translate(-695.4 -246.4)">
                                              <path id="Path_10239" data-name="Path 10239" d="M4,1H1A1,1,0,0,0,0,2V9a1,1,0,0,0,1,1H8A1,1,0,0,0,9,9V6" transform="translate(696 247)" fill="none" stroke="#c8cedb" stroke-width="1.2"/>
                                              <path id="Path_10240" data-name="Path 10240" d="M4,4V0H0" transform="translate(702 247)" fill="none" stroke="#c8cedb" stroke-width="1.2"/>
                                              <path id="Path_10241" data-name="Path 10241" d="M2144-23303l-6,6" transform="translate(-1438 23550)" fill="none" stroke="#c8cedb" stroke-width="1.2"/>
                                          </g>
                                          </svg>
                                          </a>
                                      
                                      )}
                              </div>
                          </div>
                      </div>
                      )}

                        <div className="build-advance-settings-fields">
                          {
                          ( this.state.is_app_already_published_ios === 0 || this.state.is_app_already_published_ios === false )?
                          
                          <>

                        <div className="row">
                            <div className="col-md-6">
                                <div class="android-setting-input">
                                  {/* Certificat & Profile generation */}
                                  <h5>Issuer ID<span class="build-tooltip">

                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">

                            <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">

                                <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">

                                <circle cx="7" cy="7" r="7" stroke="none"/>

                                <circle cx="7" cy="7" r="6.4" fill="none"/>

                                </g>

                                <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>

                                <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>

                            </g>

                            </svg>

                            <span class="build-tooltipsubheader">Enter the Issuer ID associated with your Apple Developer account. The Issuer ID is a unique identifier assigned to your Apple developer account. To obtain your Issuer ID, log in to App Store Connect > Users and Access > Integrations> your issuer ID will appear near the top of the page.</span>

                            </span></h5>

                                    <input type="text" placeholder="Paste your Issuer ID here" 
                                    // required
                                    value={this.state.issuer_id}
                                    onChange={(e)=>this.handleIssuerId(e)}
                                    />

                                </div>

                            </div>



                            <div className="col-md-6">

                                <div class="android-setting-input">

                                    <h5>Key ID<span class="build-tooltip">

                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">

                            <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">

                                <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">

                                <circle cx="7" cy="7" r="7" stroke="none"/>

                                <circle cx="7" cy="7" r="6.4" fill="none"/>

                                </g>

                                <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>

                                <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>

                            </g>

                            </svg>

                            <span class="build-tooltipsubheader">The Key ID, along with the Key File, helps create secure credentials for publishing your app. Think of it as a unique tag identifying the specific key. To obtain your Key ID, login to App Store Connect > Users and Access > Integrations > navigate to App Store Connect API from the left side bar > 
 click on the plus icon to create a new key> enter your App name and provide Admin access and click Generate. Find your new IAP Key ID under the Active keys list. Copy and paste it in the field below.
</span>

                            </span></h5>

                                    <input type="text" placeholder="Paste your Key ID here" 
                                    
                                    // required
                                    value={this.state.key_id}
                                    onChange={(e)=>this.handleKeyId(e)}
                                    
                                    />

                                </div>

                            </div>

                        </div>



                        <div className="row download-custom-row">

                            <div className="col-md-6">

                                <div class="android-setting-input">

                                    <h5>Key file

                                      <span class="build-tooltip-two">

                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">

                            <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">

                                <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">

                                <circle cx="7" cy="7" r="7" stroke="none"/>

                                <circle cx="7" cy="7" r="6.4" fill="none"/>

                                </g>

                                <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>

                                <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>

                            </g>

                            </svg>

                            <span class="build-tooltipsubheader-two">This downloadable file, paired with the Key ID, acts like a virtual key unlocking your App Store Connect account for publishing. To download it, log in to App Store Connect > Users and Access > Integrations > navigate to App Store Connect API from the left side bar > find your new IAP Key ID under the Active keys list. Download the file and upload it in the field below.</span>

                            </span></h5>

                            <div class="download-image-uploader">
                                  
                                  <input 
                                  type="file" 
                                  id="keyFileCertificate" 
                                  onChange={(e) => this.handlekeyFileCertificate(e)} 
                                  style={{ display: "none" }} 
                                  />
                                  {
                                  // this.state.key_file_name != '.mobileprovision file supported ' && 
                                  this.state.key_file_name != '' && this.state.key_file_name 
                                  // && this.state.key_file_name.indexOf(".p8") != 0 
                                  ? 
                                  <>
                                   
                                  <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                                    <label htmlFor="keyFileCertificate">
                                        <div class="d-flex align-items-center">
                                            <div class="download-bayimage" style={{backgroundImage : "url(" + imagebackground2 + ")"}}></div>
                                            <p style={{marginLeft: "15px"}} class="download-image-placeholder">{this.state.key_file_name}
                                              <a href={process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/" + localStorage.getItem("Appid")+'/'+process.env.REACT_APP_ios_publish_certificate+this.state.key_file_name} download> 
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                    <g id="Download" transform="translate(-20 -607)">
                                                        <rect id="Rectangle_25576" data-name="Rectangle 25576" width="20" height="20" transform="translate(20 607)" fill="none"/>
                                                        <path id="Union_2664" data-name="Union 2664" d="M-12099,101V96.5h1V100h13V96.5h1V101Zm3.076-7.376.847-.85,3.076,3.071V87h1v8.856l3.073-3.081.85.847-4.415,4.427Z" transform="translate(12122 523)" fill="#3064f9"/>
                                                    </g>
                                                </svg>
                                              </a>
                                            </p>
                                        </div>
                                      </label>
                                      <svg width="11.06" height="11.06" viewBox="0 0 11.06 11.06" onClick={()=>this.removeKeyFile()}>
                                        <path id="Union_1696" data-name="Union 1696" d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z" transform="translate(0.53 0.53)" fill="none" stroke="#7782a1" stroke-width="1.5"></path></svg>
                                  </div>
                                  </>
                                      :
                                      <>
                                          <label htmlFor="keyFileCertificate">
                                              <div class="d-flex align-items-center" >
                                                  <div class="download-bayimage" style={{backgroundImage : "url(" + imagebackground + ")"}}></div>
                                                  <p style={{color: "#BCC1CE"}} class="download-image-placeholder">Upload your Key file here</p>
                                              </div>
                                          </label>
                                      </>  
                                      }
                              </div>

                            {/* <div class="download-image-uploader">

                                <input type="file" 
                                  type="file"
                                  id="keyFileCertificate"
                                  onChange={(e) => this.handlekeyFileCertificate(e)}
                                  style={{ display: "none" }} 
                                
                                />

                                

                                    

                                <>

                                    <label htmlFor="keyFileCertificate">
                                      {
                                        this.state.key_file_name && this.state.key_file_name != '' ?
                                        <>
                                        <div className="accessbuild-setting-inputfirstdiv">
                                          
                                              <div className="documentdiv"><svg xmlns="http://www.w3.org/2000/svg" width="17.998" height="20" viewBox="0 0 17.998 20">
                                                    <path id="Union_3049" data-name="Union 3049" d="M0,20V0H13l5,5V20Zm2-2H16V7H11V2H2ZM13,5h2.17L13,2.83ZM5,14V12h8v2ZM5,8V6H8V8Z" fill="#7782a1"/>
                                                  </svg>
                                              </div>

                                              <p className="download-image-placeholder">{this.state.key_file_name}</p>
                                              <Media target="_blank" href={process.env.REACT_APP_Image_Path + localStorage.getItem("user_id") + "/" + localStorage.getItem("Appid") + "/" + process.env.REACT_APP_ios_publish_certificate + this.state.key_file_s3_name + (this.state.key_file_name.indexOf(".p8") !=0 ? "" : "p8") }
                                              rel="noreferrer" tag="a">
                                              <svg id="Download" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                <rect id="Rectangle_25576" data-name="Rectangle 25576" width="20" height="20" fill="none"/>
                                                <path id="Union_2664" data-name="Union 2664" d="M0,14V9.5H1V13H14V9.5h1V14ZM3.076,6.626l.847-.85L7,8.846V0H8V8.856l3.073-3.081.85.847L7.509,11.05Z" transform="translate(3 3)" fill="#3064f9"/>
                                              </svg>
                                            </Media>
                                        </div>


                                        </>

                                      :

                                        <div class="d-flex align-items-center" >

                                            <div class="download-bayimage" style={{backgroundImage : "url(" + imagebackground + ")"}}></div>

                                            <p style={{color: "#BCC1CE"}} class="download-image-placeholder">Upload

                                            </p>

                                        </div>
                                        }

                                    </label>
                                    {this.state.key_file_name && this.state.key_file_name != '' ? 
                                                   <>
                                                  <svg style={{
                                                    cursor:"pointer"
                                                  }} className='google-cross' 
                                                  onClick={()=>this.removeKeyFile()}
                                                   xmlns="http://www.w3.org/2000/svg" width="11.06" height="11.06" viewBox="0 0 11.06 11.06">
                                                         <path id="Union_3050" data-name="Union 3050" d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z" transform="translate(0.53 0.53)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
                                                       </svg>
                                                    </>

                                                       :
                                                       <>
                                                       
                                                       </>
                                    } 

                                </>

                            </div> */}

                                </div>

                            </div>



                            <div className="col-md-6">

                              <div className="generate-certificate-status">



                                <button 
                                className={`generate-certificate-btn
                                  ${
                                 ( this.state.issuer_id.trim() && this.state.key_id.trim() && this.state.key_file_name && this.state.is_generating_certification_in_process === false && this.state.buttonsaveclass !=="d-none" && this.state.buttonbuildclass !=="d-none" ) ? "" :"generate-certificate-btn-grey"} `}
                                 disabled={( this.state.issuer_id.trim() && this.state.key_id.trim() && this.state.key_file_name && this.state.is_generating_certification_in_process === false & this.state.buttonsaveclass !=="d-none" && this.state.buttonbuildclass !=="d-none" ) ? false : true }
                                  //  onClick={(e)=>this.handlegenerateCertificateProfile(e)}
                                  onClick={(e)=>this.handleOpenWarningCertificatePopUp(e)}
                                >
                                  {
                                    this.state.generateLoader &&
                                  <div 
                                  style={{
                                    display:"flex",
                                    justifyContent:"center",
                                    alignItems:"center",
                                  }}>
                                  
                                  <div className="new-grey-loader"></div> 
                                  <p style={{
                                    marginBottom:"0px",
                                    marginLeft:"11px"
                                  }}>Generating...</p>
                                  </div>
                                  
                                  }
                                   {this.state.generateText}                                 
                                  </button>


                                {

                                this.state.current_process_status === 3  &&

                                <div className="generate-certificate-status-one">

                                  <h4>Unsuccessful</h4>

                                  <p className="generate-certificate-status-error" 
                                  style={{
                                    cursor:"pointer",
                                  }}
                                  onClick={(e)=>this.handleOpenErrPopUpForGen(e)}
                                  
                                  >View error</p>

                                </div> 

                                }


                                 {

                                this.state.current_process_status === 2  &&
                                <div className="generate-certificate-status-two">

                                  <h5>Successful</h5>
                                  {
                                    this.state.certificatValidityDat && 

                                  <h6>Valid until {moment(this.state.certificatValidityDat).format("D MMMM YYYY")}</h6> }

                                </div>
                                }



                              </div>

                            </div>

                        </div> 
                         </>
                         :
                         <></>
                                }

                        {
                          ( this.state.is_app_already_published_ios === 0 || this.state.is_app_already_published_ios === false )?
                          
                        <div className="download-seprator">

                            <p>or</p>

                        </div>
                        :
                        <></>
                       }


                        {
                          ( this.state.is_app_already_published_ios === 0 || this.state.is_app_already_published_ios === false )?
                        <h6>Advanced users with access to the information required below, can provide it directly. Users who don’t have the files or details readily available can simply provide the information requested above and click Generate certificate & profile.</h6>
                         :
                         <></>
                        }


                        <div className="row">

                            <div className="col-md-6">

                                <div class="android-setting-input">
                                    <h5>Team ID<span class="build-tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                            <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                <circle cx="7" cy="7" r="7" stroke="none"/>
                                <circle cx="7" cy="7" r="6.4" fill="none"/>
                                </g>
                                <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                            </g>
                            </svg>
                            <span class="build-tooltipsubheader">Enter your Team ID associated with your Apple Developer account. To obtain your Team ID, login to your Apple Developer account, navigate to My Account > Membership > Team ID.</span>
                            </span></h5>
                                    <input type="text"
                                    required
                                    value={this.state.teamid}
                                    onChange={this.handleteamid}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div class="android-setting-input">
                                    <h5>Distribution Certificate
                                      <span class="build-tooltip-two">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                            <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                <circle cx="7" cy="7" r="7" stroke="none"/>
                                <circle cx="7" cy="7" r="6.4" fill="none"/>
                                </g>
                                <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                            </g>
                            </svg>
                            <span class="build-tooltipsubheader-two">Upload your Distribution Certificate here. To obtain your Distribution Certificate, login to your Apple Developer account, navigate to Certificates> Create new certificate (+ icon)> iOS App Development. Additional steps are required to obtain the certificate with a  .p12 extension.></span>
                            </span></h5>
                            <div class="download-image-uploader">
                                <input type="file" id="certificatefilename" 
                                onChange={(e) =>
                                {
                                  this.handlecertificate(e);
                                  e.target.value = null;
                                }

                                } style={{ display: "none" }} />
                                {this.state.certificatefilename != '' && this.state.certificatefilename && this.state.certificatefilename.indexOf(".p12") != 0 ? 
                                    <>
                                    
                                    <label htmlFor="certificatefilename">
                                        <div class="d-flex align-items-center" >
                                        <div class="download-bayimage" style={{backgroundImage : "url(" + imagebackground2 + ")"}}></div>
                                            <p style={{marginLeft: "15px"}} class="download-image-placeholder">{this.state.certificatefilename}{" "}
                                            <a href={process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/" + localStorage.getItem("Appid")+'/'+process.env.REACT_APP_ios_certificates+this.state.certificatefilename} download> 
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                <g id="Download" transform="translate(-20 -607)">
                                                    <rect id="Rectangle_25576" data-name="Rectangle 25576" width="20" height="20" transform="translate(20 607)" fill="none"/>
                                                    <path id="Union_2664" data-name="Union 2664" d="M-12099,101V96.5h1V100h13V96.5h1V101Zm3.076-7.376.847-.85,3.076,3.071V87h1v8.856l3.073-3.081.85.847-4.415,4.427Z" transform="translate(12122 523)" fill="#3064f9"/>
                                                </g>
                                                </svg>
                                            </a>
                                            </p>
                                        </div>
                                    </label>
                                    <svg width="11.06" height="11.06" viewBox="0 0 11.06 11.06" onClick={()=>this.removefile('certificatefilename')}>
                                      <path id="Union_1696" data-name="Union 1696" d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z" transform="translate(0.53 0.53)" fill="none" stroke="#7782a1" stroke-width="1.5">
                                        </path></svg>
                                    </>
                                :
                                <>
                                    <label htmlFor="certificatefilename">
                                        <div class="d-flex align-items-center" >
                                            <div class="download-bayimage" style={{backgroundImage : "url(" + imagebackground + ")"}}></div>
                                            <p style={{color: "#BCC1CE"}} class="download-image-placeholder">Upload
                                            </p>
                                        </div>
                                    </label>
                                </>  
                                }
                            </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div class="android-setting-input">
                                    <h5>Distribution Certificate password<span class="build-tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                            <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                <circle cx="7" cy="7" r="7" stroke="none"/>
                                <circle cx="7" cy="7" r="6.4" fill="none"/>
                                </g>
                                <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                            </g>
                            </svg>
                            <span class="build-tooltipsubheader">Provide the password associated with your Distribution Certificate for authentication. Skip this step if your Distribution Certificate is not password protected.</span>
                            </span></h5>
                                    <input type="password" 
                                          value={this.state.certificate_password}
                                                onChange={
                                                  this.handlecertificate_password
                                                }
                                                autoComplete="new-password"/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div class="android-setting-input">
                                    <h5>Provisioning profile<span class="build-tooltip-two">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                            <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                <circle cx="7" cy="7" r="7" stroke="none"/>
                                <circle cx="7" cy="7" r="6.4" fill="none"/>
                                </g>
                                <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                            </g>
                            </svg>
                            <span class="build-tooltipsubheader-two">Upload your provisioning profile here. This file will have a .mobileprovision extension. To obtain your Provisioning profile, login to your Apple Developer account, navigate to Certificates> Profiles > Create new profile (+ icon)> iOS App Development. Additional steps are required to obtain the Provisioning profile.</span>
                            </span></h5>
                                <div class="download-image-uploader">
                                  
                                    <input type="file" id="provision2filename" 
                                    onChange={(e) =>
                                      {
                                        this.handleprovision2(e);
                                        e.target.value = null;
                                      }
                                      } style={{ display: "none" }} />
                                    {this.state.provision2filename != '.mobileprovision file supported ' && this.state.provision2filename != '' && this.state.provision2filename && this.state.provision2filename.indexOf(".p12") != 0 ? 
                                    <>
                                     
                                    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                                      <label htmlFor="provision2filename">
                                          <div class="d-flex align-items-center">
                                              <div class="download-bayimage" style={{backgroundImage : "url(" + imagebackground2 + ")"}}></div>
                                              <p style={{marginLeft: "15px"}} class="download-image-placeholder">{this.state.provision2filename}
                                                <a href={process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/" + localStorage.getItem("Appid")+'/'+process.env.REACT_APP_ios_profiles+this.state.provision2filename} download> 
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                      <g id="Download" transform="translate(-20 -607)">
                                                          <rect id="Rectangle_25576" data-name="Rectangle 25576" width="20" height="20" transform="translate(20 607)" fill="none"/>
                                                          <path id="Union_2664" data-name="Union 2664" d="M-12099,101V96.5h1V100h13V96.5h1V101Zm3.076-7.376.847-.85,3.076,3.071V87h1v8.856l3.073-3.081.85.847-4.415,4.427Z" transform="translate(12122 523)" fill="#3064f9"/>
                                                      </g>
                                                  </svg>
                                                </a>
                                              </p>
                                          </div>
                                        </label>
                                        <svg width="11.06" height="11.06" viewBox="0 0 11.06 11.06" onClick={()=>this.removefile('provision2filename')}>
                                          <path id="Union_1696" data-name="Union 1696" d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z" transform="translate(0.53 0.53)" fill="none" stroke="#7782a1" stroke-width="1.5"></path></svg>
                                    </div>
                                    </>
                                        :
                                        <>
                                            <label htmlFor="provision2filename">
                                                <div class="d-flex align-items-center" >
                                                    <div class="download-bayimage" style={{backgroundImage : "url(" + imagebackground + ")"}}></div>
                                                    <p style={{color: "#BCC1CE"}} class="download-image-placeholder">Upload</p>
                                                </div>
                                            </label>
                                        </>  
                                        }
                                </div>
                                </div>
                            </div>
                        </div>

                        </div>

                    {/*
                      <div className="row">
                          <div className="col-md-6">
                              <div className="android-setting-input api-key-field">
                              <h5>API key<span className="build-tooltip">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg>
                                  <span className="build-tooltipsubheader">Customize the navigation of your app to enhance user experience.</span>
                                  </span></h5>
                                  <input type="text" 
                                        value={this.state.api_key}
                                        required
                                        onChange={(e) => this.handle_api_key(e)}/>
                                  <a href="#" target="_blank">Validate</a> 
                                  <a href="#" target="_blank">Invalid API key</a> 
                                  <svg className="build-tick" id="Group_27505" data-name="Group 27505" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                  <circle id="Ellipse_2306" data-name="Ellipse 2306" cx="8" cy="8" r="8" fill="#52cb86"/>
                                  <path id="Path_7161" data-name="Path 7161" d="M-19252.924-4212.33l2.656,2.656,5-5" transform="translate(19257.096 4220.174)" fill="none" stroke="#fff" stroke-width="1.5"/>
                                  </svg> 

                              </div>
                          </div>
                      </div> */}
                      
                      {/* <div className="row">
                      <div className="col-md-12">
                          <p onClick={()=>this.handelOpenSetting()} className={`advance-settings-toggle ${!this.state.openAdvanceSetting?"advance-settings-toggle": "rotate-build-setting"}`}>Advanced settings<svg xmlns="http://www.w3.org/2000/svg" width="11.063" height="6.591" viewBox="0 0 11.063 6.591">
                          <path id="Path_102807" data-name="Path 102807" d="M-17299.424-4409.885l5,5,5-5" transform="translate(17299.955 4410.416)" fill="none" stroke="#3064f9" stroke-width="1.5"/>
                          </svg>
                          </p>
                      </div>
                      </div> */}

                      {/* <div className={`${this.state.openAdvanceSetting?"build-advance-settings-fields": "hide-advance-settings-toggle"}`}>

                      <div className="row">
                          <div className="col-md-6">
                              <div class="android-setting-input">
                                  <h5>Team ID<span class="build-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                              <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none"/>
                              <circle cx="7" cy="7" r="6.4" fill="none"/>
                              </g>
                              <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                              <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                          </g>
                          </svg>
                          <span class="build-tooltipsubheader">Customize the navigation of your app to enhance user experience.</span>
                          </span></h5>
                                  <input type="text"
                                  required
                                  placeholder="Enter team ID"
                                  value={this.state.teamid}
                                  onChange={this.handleteamid}
                                  />
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div class="android-setting-input">
                                  <h5>iPhone Distribution Certificate<span class="build-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                              <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none"/>
                              <circle cx="7" cy="7" r="6.4" fill="none"/>
                              </g>
                              <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                              <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                          </g>
                          </svg>
                          <span class="build-tooltipsubheader">Customize the navigation of your app to enhance user experience.</span>
                          </span></h5>
                          <div class="download-image-uploader">
                              <input type="file" id="certificatefilename" onChange={(e) =>this.handlecertificate(e)} style={{ display: "none" }} />
                              {this.state.certificatefilename != '' && this.state.certificatefilename && this.state.certificatefilename.indexOf(".p12") != 0 ? 
                                  <>
                                  
                                  <label htmlFor="certificatefilename">
                                      <div class="d-flex align-items-center" >
                                          <p class="download-image-placeholder">{this.state.certificatefilename}{" "}</p>
                                      </div>
                                  </label>
                                  <svg width="11.06" height="11.06" viewBox="0 0 11.06 11.06"><path id="Union_1696" data-name="Union 1696" d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z" transform="translate(0.53 0.53)" fill="none" stroke="#7782a1" stroke-width="1.5"></path></svg>
                                  </>
                              :
                              <>
                                  <label htmlFor="certificatefilename">
                                      <div class="d-flex align-items-center" >
                                          <div class="download-bayimage" style={{backgroundImage : "url(" + imagebackground + ")"}}></div>
                                          <p class="download-image-placeholder">Upload</p>
                                      </div>
                                  </label>
                              </>  
                              }
                          </div>
                              </div>
                          </div>
                      </div>

                      <div className="row">
                          <div className="col-md-6">
                              <div class="android-setting-input">
                                  <h5>iPhone distribution certificate password<span class="build-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                              <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none"/>
                              <circle cx="7" cy="7" r="6.4" fill="none"/>
                              </g>
                              <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                              <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                          </g>
                          </svg>
                          <span class="build-tooltipsubheader">Customize the navigation of your app to enhance user experience.</span>
                          </span></h5>
                                  <input type="text" 
                                        value={this.state.certificate_password}
                                              onChange={
                                                this.handlecertificate_password
                                              }
                                              autoComplete="new-password"/>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div class="android-setting-input">
                                  <h5>iPhone Provisioning Profile<span class="build-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                              <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none"/>
                              <circle cx="7" cy="7" r="6.4" fill="none"/>
                              </g>
                              <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                              <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                          </g>
                          </svg>
                          <span class="build-tooltipsubheader">Customize the navigation of your app to enhance user experience.</span>
                          </span></h5>
                          <label class="download-full-img">
                              <div class="download-image-uploader">
                                  {/* <div class="download-image-uploader">
                                      <div class="d-flex align-items-center">
                                          <div class="download-bayimage" style={{backgroundImage : "url(" + imagebackground2 + ")"}}></div>
                                          <p class="download-image-placeholder">AppMySiteDist.mobileprovision <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                              <g id="Download" transform="translate(-20 -607)">
                                                  <rect id="Rectangle_25576" data-name="Rectangle 25576" width="20" height="20" transform="translate(20 607)" fill="none"/>
                                                  <path id="Union_2664" data-name="Union 2664" d="M-12099,101V96.5h1V100h13V96.5h1V101Zm3.076-7.376.847-.85,3.076,3.071V87h1v8.856l3.073-3.081.85.847-4.415,4.427Z" transform="translate(12122 523)" fill="#3064f9"/>
                                              </g>
                                              </svg>
                                              </p>
                                      </div>
                                      <svg width="11.06" height="11.06" viewBox="0 0 11.06 11.06"><path id="Union_1696" data-name="Union 1696" d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z" transform="translate(0.53 0.53)" fill="none" stroke="#7782a1" stroke-width="1.5"></path></svg>
                                  </div> 
                                  <input type="file" id="provision2filename" onChange={(e) =>this.handleprovision2(e)} style={{ display: "none" }} />
                                  {this.state.provision2filename != '' && this.state.provision2filename && this.state.provision2filename.indexOf(".p12") != 0 ? 
                                  <>
                                  
                                  <div class="download-image-uploader">
                                      <div class="d-flex align-items-center">
                                          <div class="download-bayimage" style={{backgroundImage : "url(" + imagebackground2 + ")"}}></div>
                                          <p class="download-image-placeholder">{this.state.provision2filename} <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                              <g id="Download" transform="translate(-20 -607)">
                                                  <rect id="Rectangle_25576" data-name="Rectangle 25576" width="20" height="20" transform="translate(20 607)" fill="none"/>
                                                  <path id="Union_2664" data-name="Union 2664" d="M-12099,101V96.5h1V100h13V96.5h1V101Zm3.076-7.376.847-.85,3.076,3.071V87h1v8.856l3.073-3.081.85.847-4.415,4.427Z" transform="translate(12122 523)" fill="#3064f9"/>
                                              </g>
                                              </svg>
                                              </p>
                                      </div>
                                      <svg width="11.06" height="11.06" viewBox="0 0 11.06 11.06"><path id="Union_1696" data-name="Union 1696" d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z" transform="translate(0.53 0.53)" fill="none" stroke="#7782a1" stroke-width="1.5"></path></svg>
                                  </div>
                                  </>
                                      :
                                      <>
                                          <label htmlFor="provision2filename">
                                              <div class="d-flex align-items-center" >
                                                  <div class="download-bayimage" style={{backgroundImage : "url(" + imagebackground + ")"}}></div>
                                                  <p class="download-image-placeholder">Upload</p>
                                              </div>
                                          </label>
                                      </>  
                                      }
                              </div>
                              </label>
                              </div>
                          </div>
                      </div>

                      </div> */}

                      
                      </Accordion.Body>

                      </Accordion.Item>

                      <Accordion.Item eventKey="2" className="android-config-main">

                      <Accordion.Header>

                      <div className="android-config-toggle">

                      <h5><svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
                      <g id="Group_41895" data-name="Group 41895" transform="translate(-260 -385)">
                          <rect id="Rectangle_967" data-name="Rectangle 967" width="54" height="54" rx="3" transform="translate(260 385)" fill="#f8fafe"/>
                          <path id="_3668850_insurance_protection_safety_shield_icon" data-name="3668850_insurance_protection_safety_shield_icon" d="M25.778,5.067A39.162,39.162,0,0,1,15.879,3a37.279,37.279,0,0,1-9.9,2.067A1.157,1.157,0,0,0,5,6.155V18.23a8.867,8.867,0,0,0,4.787,7.833l5.548,2.828A.654.654,0,0,0,15.77,29c.218,0,.326,0,.435-.109l5.548-2.828A8.743,8.743,0,0,0,26.54,18.23V6.155C26.757,5.611,26.322,5.176,25.778,5.067ZM20.992,24.1l-5.113,2.611V16.054h-8.7V7.243a47.677,47.677,0,0,0,8.7-1.849V16.054h8.7v2.285A6.537,6.537,0,0,1,20.992,24.1Z" transform="translate(271 396)" fill="#6089ff"/>
                      </g>
                      </svg>App permissions<span class="build-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                              <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none"/>
                              <circle cx="7" cy="7" r="6.4" fill="none"/>
                              </g>
                              <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                              <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                          </g>
                          </svg>
                          <span class="build-tooltipsubheader">Enable or disable each permission based on your app's functionality, whether native or via the in-app browser.You may be required to provide an explanation detailing how each of these permissions is utilized when submitting your app to the Apple App Store.</span>
                          </span></h5>
                      

                      <div className="android-id-toggle">
                          
                          <svg className="thechatarrow" id="Group_39685" data-name="Group 39685" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                          <circle id="Ellipse_6953" data-name="Ellipse 6953" cx="11" cy="11" r="11" fill="none"/>
                          <path id="Path_81302" data-name="Path 81302" d="M-12935-18908l6-6,6,6" transform="translate(12940.501 18921.504)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
                          </svg>
                      </div>

                      </div>

                      </Accordion.Header>

                      <Accordion.Body className="ios-app-permissions">

                      <div className="main-permission-ios">  
                      
                      <div className="main-ios-child">

                          <div className="individual-permission-ios">
                              <div class="build-permission-toggle bp-gap0">
                                  <h6>Location<span class="build-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                              <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none"/>
                              <circle cx="7" cy="7" r="6.4" fill="none"/>
                              </g>
                              <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                              <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                          </g>
                          </svg>
                          <span class="build-tooltipsubheader">This permission is crucial for apps that require location data.</span>
                                  </span></h6>
                                  <label class="switch" for="is_enable_location_permission">
                                      <input type="checkbox" id="is_enable_location_permission"
                                                name="is_enable_location_permission"
                                                checked={ this.state.is_enable_location_permission}
                                                onChange={(e) => this.handlepermission(e)}/>
                                      <div class="slider round"></div>
                                  </label>
                              </div>
                              {this.state.is_enable_location_permission === true && (
                                  <div className="android-setting-input bp-gap2">
                                  <input placeholder="Inform your users why you need this permission" 
                                          type="text"  
                                          value={this.state.location_permission_description}
                                          readOnly={!this.state.is_enable_location_permission}
                                          required={this.state.is_enable_location_permission}
                                          name="location_permission_description"
                                          onChange={(e) => this.handlepermission_des(e)}/>
                                  </div>
                              )}

                          </div>

                          <div className="individual-permission-ios">
                              <div class="build-permission-toggle bp-gap1">
                                  <h6>Contacts<span class="build-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                              <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none"/>
                              <circle cx="7" cy="7" r="6.4" fill="none"/>
                              </g>
                              <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                              <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                          </g>
                          </svg>
                          <span class="build-tooltipsubheader">This permission is crucial for apps that require access to the Contacts app on the device.</span>
                                  </span></h6>
                                  <label class="switch" for="is_enable_contacts_permission">
                                      <input type="checkbox"  id="is_enable_contacts_permission"
                                            name="is_enable_contacts_permission"
                                            checked={this.state.is_enable_contacts_permission}
                                            onChange={(e) =>this.handlepermission(e)}/>
                                      <div class="slider round"></div>
                                  </label>
                              </div>
                          
                              {this.state.is_enable_contacts_permission === true && (
                              <div className="android-setting-input bp-gap2">
                              <input placeholder="Inform your users why you need this permission" type="text" 
                                    value={this.state.contacts_permission_description}
                                    readOnly={!this.state.is_enable_contacts_permission}
                                    required={this.state.is_enable_contacts_permission}
                                    name="contacts_permission_description"
                                    onChange={(e) =>this.handlepermission_des(e)}/>
                              </div>
                              )}

                          </div>

                          <div className="individual-permission-ios">
                              <div class="build-permission-toggle bp-gap1">
                                  <h6>Microphone<span class="build-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                              <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none"/>
                              <circle cx="7" cy="7" r="6.4" fill="none"/>
                              </g>
                              <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                              <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                          </g>
                          </svg>
                          <span class="build-tooltipsubheader">This permission is crucial for apps that involve audio input functionality.</span>
                                  </span></h6>
                                  <label class="switch" for="is_enable_microphone_permission">
                                  <input type="checkbox"  id="is_enable_microphone_permission"
                                        name="is_enable_microphone_permission"
                                        checked={this.state.is_enable_microphone_permission}
                                        onChange={(e) =>this.handlepermission(e)}/>
                                      <div class="slider round"></div>
                                  </label>
                              </div>
                              {this.state.is_enable_microphone_permission === true && 
                              (
                              <div className="android-setting-input bp-gap2">
                              <input placeholder="Inform your users why you need this permission" type="text" 
                                  value={this.state.microphone_permission_description}
                                  readOnly={!this.state.is_enable_microphone_permission}
                                  required={this.state.is_enable_microphone_permission}
                                  name="microphone_permission_description"
                                  onChange={(e) => this.handlepermission_des(e)}/>
                              </div>
                              )}

                          </div>

                          <div className="individual-permission-ios">
                              <div class="build-permission-toggle bp-gap1">
                                  <h6>Speech recognition<span class="build-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                              <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none"/>
                              <circle cx="7" cy="7" r="6.4" fill="none"/>
                              </g>
                              <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                              <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                          </g>
                          </svg>
                          <span class="build-tooltipsubheader">This permission is crucial for apps that have speech recognition functionality.</span>
                                  </span></h6>
                                  <label class="switch" for="is_enable_speech_recognition_permission">
                                      <input type="checkbox" id="is_enable_speech_recognition_permission"
                                            name="is_enable_speech_recognition_permission"
                                            checked={this.state.is_enable_speech_recognition_permission}
                                            onChange={(e) =>this.handlepermission(e)}/>
                                      <div class="slider round"></div>
                                  </label>
                              </div>
                              {this.state.is_enable_speech_recognition_permission === true && (
                              <div className="android-setting-input bp-gap2">
                              <input placeholder="Inform your users why you need this permission" type="text"  
                                    value={this.state.speech_recognition_permission_description}
                                    readOnly={!this.state.is_enable_speech_recognition_permission}
                                    required={this.state.is_enable_speech_recognition_permission}
                                    name="speech_recognition_permission_description"
                                    onChange={(e) =>this.handlepermission_des(e)}/>
                              </div>
                              )}

                          </div>

                      </div>

                      <div className="main-ios-child">

                          <div className="individual-permission-ios">
                              <div class="build-permission-toggle bp-gap0">
                                  <h6>Calendar<span class="build-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                              <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none"/>
                              <circle cx="7" cy="7" r="6.4" fill="none"/>
                              </g>
                              <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                              <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                          </g>
                          </svg>
                          <span class="build-tooltipsubheader">This permission is crucial for applications that require access to the Calendar app data.</span>
                                  </span></h6>
                                  <label class="switch" for="is_enable_calender_permission">
                                      <input type="checkbox"  id="is_enable_calender_permission"
                                            name="is_enable_calender_permission"
                                            checked={this.state.is_enable_calender_permission}
                                            onChange={(e) =>this.handlepermission(e)}/>
                                      <div class="slider round"></div>
                                  </label>
                              </div>
                              {this.state.is_enable_calender_permission === true && (
                              <div className="android-setting-input bp-gap2">
                                  <input placeholder="Inform your users why you need this permission" type="text"
                                  value={this.state.calender_permission_description}
                                  readOnly={!this.state.is_enable_calender_permission}
                                  required={this.state.is_enable_calender_permission}
                                  name="calender_permission_description"
                                  onChange={(e) =>this.handlepermission_des(e)}/>
                              </div>
                              )}
                          </div>
                      
                          <div className="individual-permission-ios">
                              <div class="build-permission-toggle bp-gap1">
                                  <h6>Reminders<span class="build-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                              <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none"/>
                              <circle cx="7" cy="7" r="6.4" fill="none"/>
                              </g>
                              <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                              <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                          </g>
                          </svg>
                          <span class="build-tooltipsubheader">This permission is crucial for apps that require access to the Reminders app on the device.</span>
                                  </span></h6>
                                  <label class="switch" for="is_enable_reminder_permission">
                                      <input type="checkbox"  id="is_enable_reminder_permission"
                                            name="is_enable_reminder_permission"
                                            checked={this.state.is_enable_reminder_permission}
                                            onChange={(e) =>this.handlepermission(e)}/>
                                      <div class="slider round"></div>
                                  </label>
                              </div>
                          {this.state.is_enable_reminder_permission === true && (
                              <div className="android-setting-input bp-gap2">
                                  <input placeholder="Inform your users why you need this permission" type="text"
                                  value={this.state.reminder_permission_description}
                                  readOnly={!this.state.is_enable_reminder_permission}
                                  required={this.state.is_enable_reminder_permission}
                                  name="reminder_permission_description"
                                  onChange={(e) => this.handlepermission_des(e)}/>
                              </div>
                          )}
                          </div>
                      
                          <div className="individual-permission-ios">
                              <div class="build-permission-toggle bp-gap1">
                                  <h6>Camera & Photos<span class="build-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                              <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none"/>
                              <circle cx="7" cy="7" r="6.4" fill="none"/>
                              </g>
                              <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                              <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                          </g>
                          </svg>
                          <span class="build-tooltipsubheader">This permission is crucial for apps that require access to the Camera or the Photos app on the device.</span>
                                  </span></h6>
                                
                                  <label class="switch" for="is_enable_camera_and_photo_library_permission">
                                      <input type="checkbox"  id="is_enable_camera_and_photo_library_permission"
                                            name="is_enable_camera_and_photo_library_permission"
                                            disabled={this.state.chat_preferences !==null &&
                                            this.state.chat_preferences.some((res) => res.module_status === 1)}
                                            checked={this.state.is_enable_camera_and_photo_library_permission}
                                            onChange={(e) => this.handlepermission(e)}/>
                                      <div class="slider round"></div>
                                  </label>
                              </div>
                              {this.state.is_enable_camera_and_photo_library_permission === true && (
                              <div className="android-setting-input bp-gap2">
                                  <input placeholder="Inform your users why you need this permission"  
                                        value={this.state.camera_and_photo_library_permission_description}
                                        readOnly={!this.state.is_enable_camera_and_photo_library_permission}
                                        required={this.state.is_enable_camera_and_photo_library_permission}
                                        name="camera_and_photo_library_permission_description"
                                        onChange={(e) => this.handlepermission_des(e)}/>
                              </div>
                              )}
                          </div>
                      
                          <div className="individual-permission-ios">
                              <div class="build-permission-toggle bp-gap1">
                                  <h6>Tracking permission<span class="build-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                              <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none"/>
                              <circle cx="7" cy="7" r="6.4" fill="none"/>
                              </g>
                              <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                              <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                          </g>
                          </svg>
                          <span class="build-tooltipsubheader">Apple mandates enabling tracking for apps utilizing web views.</span>
                                  </span></h6>
                                  <label class="switch" for="is_enable_app_tracking_permission">
                                      <input type="checkbox" id="is_enable_app_tracking_permission"
                                            name="is_enable_app_tracking_permission"
                                            checked={this.state.is_enable_app_tracking_permission}
                                            onChange={(e) =>this.handlepermission(e)}/>
                                      <div class="slider round"></div>
                                  </label>
                              </div>
                              {this.state.is_enable_app_tracking_permission === true && (
                              <div className="android-setting-input bp-gap2">
                                  <input placeholder="Inform your users why you need this permission" type="text"  
                                        value={this.state.app_tracking_permission_description}
                                        readOnly={!this.state.is_enable_app_tracking_permission}
                                        required={this.state.is_enable_app_tracking_permission}
                                        name="app_tracking_permission_description"
                                        onChange={(e) =>this.handlepermission_des(e)}/>
                              </div>
                              )}
                          </div>

                      </div>

                      </div>

                      

                      

                      

                      

                      </Accordion.Body>

                      </Accordion.Item>
                    {/* 
                      <Accordion.Item eventKey="3" className="android-config-main">


                        <Accordion.Header>

                        <div className="android-config-toggle">

                        <h5><svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
                        <g id="Group_42831" data-name="Group 42831" transform="translate(-336 -367)">
                        <rect id="Rectangle_31978" data-name="Rectangle 31978" width="54" height="54" rx="3" transform="translate(336 367)" fill="#f8fafe"/>
                        <g id="Group_41750" data-name="Group 41750" transform="translate(213.314 -1025)">
                        <g id="Group_5389" data-name="Group 5389" transform="translate(137.686 1407)">
                        <path id="Path_9215" data-name="Path 9215" d="M92,8.034C92.18,8,92.889,8,93.058,8a12.214,12.214,0,0,1,11.908,11.493v9.434A3.151,3.151,0,0,1,101.757,32a3.5,3.5,0,0,1-2.992-3.068V19.5a6.089,6.089,0,0,0-5.777-5.5,6.74,6.74,0,0,0-.988.014" transform="translate(-80.966 -7.998)" fill="#fbbc04"/>
                        <path id="Path_9216" data-name="Path 9216" d="M146.2,143.1a3.1,3.1,0,1,1-3.1-3.1A3.1,3.1,0,0,1,146.2,143.1Z" transform="translate(-122.201 -122.201)" fill="#4285f4"/>
                        <path id="Path_9217" data-name="Path 9217" d="M20.017,32.1a3.136,3.136,0,0,0,3.215-3.05,3.053,3.053,0,0,0-3.249-2.908h-.034a6.085,6.085,0,0,1-5.7-6.368l0-.057a6.124,6.124,0,0,1,5.764-5.508,3.029,3.029,0,0,0,3.215-2.916,3.138,3.138,0,0,0-3.216-3.051c-.072,0-.144,0-.216,0C14.416,8.42,8.073,12.754,8.01,20.12v.045c0,7.475,6.778,11.929,11.829,11.929h.178Z" transform="translate(-8.01 -8.203)" fill="#ea4335"/>
                        </g>
                        </g>
                        </g>
                        </svg>
                        Admob<span class="build-tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                            <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                <circle cx="7" cy="7" r="7" stroke="none"/>
                                <circle cx="7" cy="7" r="6.4" fill="none"/>
                                </g>
                                <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                            </g>
                            </svg>
                            <span class="build-tooltipsubheader"> Connect your app to your Google AdMob account to display ads in your Ios app</span>
                            </span></h5>


                        <div className="android-id-toggle">
                            
                            <svg className="thechatarrow" id="Group_39685" data-name="Group 39685" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                            <circle id="Ellipse_6953" data-name="Ellipse 6953" cx="11" cy="11" r="11" fill="none"/>
                            <path id="Path_81302" data-name="Path 81302" d="M-12935-18908l6-6,6,6" transform="translate(12940.501 18921.504)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
                            </svg>
                        </div>

                        </div>

                        </Accordion.Header>

                          <Accordion.Body>

                          <div className="row" style={{marginBottom: "10px"}}>

                            <div className="col-md-6">

                            
                              <div class="android-setting-toggle">
                                  <h6>Enable App Monetization<span class="build-tooltip">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                  <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                      <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                      <circle cx="7" cy="7" r="7" stroke="none"/>
                                      <circle cx="7" cy="7" r="6.4" fill="none"/>
                                      </g>
                                      <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                      <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                  </g>
                                  </svg>
                          <span class="build-tooltipsubheader">Turn on to enable app monetization</span>
                          </span></h6>
                                  <label class="switch" for="is_admob_enabled">
                                      <input type="checkbox" id="is_admob_enabled"  name="is_admob_enabled" 
                                          checked={this.state.is_admob_enabled}
                                          onClick={(e) => this.handleis_admob_enabled(e)}
                                      />
                                      <div class="slider round"></div>
                                  </label>
                              </div>
                          </div>

                          </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div class="android-setting-input">
                                            <h5>Admob App ID<span class="build-tooltip">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                    <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                        <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                        <circle cx="7" cy="7" r="7" stroke="none"/>
                                        <circle cx="7" cy="7" r="6.4" fill="none"/>
                                        </g>
                                        <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                        <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                    </g>
                                    </svg>
                                    <span class="build-tooltipsubheader">Enter your Google AdMob App ID here.</span>
                                    </span></h5>
                                        <input type="text"  
                                        name="admob_app_id"
                                        required
                                        value={this.state.admob_app_id}
                                        onChange={(e) => this.handleadmob_app_id(e)}
                                        />
                                        </div>
                                    </div>
                                  
                                </div>
                                  
                              </Accordion.Body>

                      </Accordion.Item>
                    */}
                  </Accordion>

                  </div>

                  <div className="android-build-conf-right">
                      <h3>Features in this build</h3>

                      <div className="android-seven-steps">

                          <div className="android-steps">

                          <a href="/app/basic-notifications"></a>
                          <img src={require("../../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                          <div>
                          {(this.state.is_notification_enabled == 1 && this.state.notification_config_file != '') || 
                           (this.state.is_notification_enabled == true && this.state.notification_config_file != '') ? 
                           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                           <g id="Group_41817" data-name="Group 41817" transform="translate(-1510 -164)">
                               <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                               <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                           </g>
                           </svg>
                        : (this.state.is_rich_notification_enabled == 1 && this.state.notification_config_file != '') || 
                          (this.state.is_rich_notification_enabled == true && this.state.notification_config_file != '') ? 
                           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                           <g id="Group_41817" data-name="Group 41817" transform="translate(-1510 -164)">
                               <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                               <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                           </g>
                           </svg>
                       :
                           <svg id="Group_41846" data-name="Group 41846" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                           <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" fill="#ff4848"/>
                           <path id="Path_101776" data-name="Path 101776" d="M-16063.67,2569.475l6.416-6.416" transform="translate(16070.461 -2556.267)" fill="none" stroke="#fff" stroke-width="2"/>
                           <path id="Path_118731" data-name="Path 118731" d="M-16057.254,2569.474l-6.416-6.415" transform="translate(16070.461 -2556.267)" fill="none" stroke="#fff" stroke-width="2"/>
                           </svg>
                       }


                          </div>
                          <div>
                              <h4>Notifications</h4>
                              {(this.state.is_notification_enabled == 1 && this.state.notification_config_file != '') || 
                                     (this.state.is_notification_enabled == true && this.state.notification_config_file != '') ?
                                      <p>Notifications are configured and enabled.</p>
                                    : (this.state.is_rich_notification_enabled == 1 && this.state.notification_config_file != '') || 
                                      (this.state.is_rich_notification_enabled == true && this.state.notification_config_file != '') ?
                                      <p>Notifications are configured and enabled.</p>
                                    :
                                      <p>Notifications are not configured. </p>
                                    }
                          </div>
                          </div>

                          <div className="android-steps">

                          <a href="/app/ad-build-settings"></a>
                          <img src={require("../../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                          <div>
                          {this.state.is_admob_enabled == 1 || this.state.is_admob_enabled == true ? 
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                          <g id="Group_41817" data-name="Group 41817" transform="translate(-1510 -164)">
                              <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                              <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                          </g>
                          </svg>
                      :
                          <svg id="Group_41846" data-name="Group 41846" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                          <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" fill="#ff4848"/>
                          <path id="Path_101776" data-name="Path 101776" d="M-16063.67,2569.475l6.416-6.416" transform="translate(16070.461 -2556.267)" fill="none" stroke="#fff" stroke-width="2"/>
                          <path id="Path_118731" data-name="Path 118731" d="M-16057.254,2569.474l-6.416-6.415" transform="translate(16070.461 -2556.267)" fill="none" stroke="#fff" stroke-width="2"/>
                          </svg>
                      }

                          </div>
                          <div>
                              <h4>Advertisement</h4>
                              {this.state.is_admob_enabled == 1 || this.state.is_admob_enabled == true ?
                                      <p>Ads are configured and enabled.</p>
                                    :
                                      <p>Ads are not configured.</p>
                                    }
                          </div>
                          </div>

                          {(
                              parseInt(localStorage.getItem("website_technology")) !== 2 
                          ) &&
                              (
                                  <div className="android-steps">

                                    <a href="/app/login/build-settings"></a>
                                  <img src={require("../../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                  <div>
                                  {(this.state.is_google_login_enabled == 1 && this.state.notification_config_file != '') 
                                  || (this.state.is_google_login_enabled == true && this.state.notification_config_file != '') ? 
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                  <g id="Group_41817" data-name="Group 41817" transform="translate(-1510 -164)">
                                      <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                      <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                  </g>
                                  </svg>
                              :
                                  <svg id="Group_41846" data-name="Group 41846" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                  <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" fill="#ff4848"/>
                                  <path id="Path_101776" data-name="Path 101776" d="M-16063.67,2569.475l6.416-6.416" transform="translate(16070.461 -2556.267)" fill="none" stroke="#fff" stroke-width="2"/>
                                  <path id="Path_118731" data-name="Path 118731" d="M-16057.254,2569.474l-6.416-6.415" transform="translate(16070.461 -2556.267)" fill="none" stroke="#fff" stroke-width="2"/>
                                  </svg>
                              }
                                  </div>
                                  <div>
                                      <h4>Social login</h4>
                                      {(this.state.is_google_login_enabled == 1 && this.state.notification_config_file != '') 
                                      || (this.state.is_google_login_enabled == true && this.state.notification_config_file != '') ?
                                              <p>Social logins are configured and enabled.</p>
                                            :
                                              <p>Social logins are not configured.</p>
                                            }
                                  </div>
                                  </div>
                              )
                          }

                          <div className="android-steps-animation"></div>
                      </div>
                  </div>
              </div>
              </>
      }

        <div className="android-mob-btns">
            <button 
            className={`download-save-btn-mob ${this.state.is_generating_certification_in_process === false ?"":"download-save-btn-disabled"}`}
             onClick={()=>this.state.is_generating_certification_in_process === false ? this.iossubmit() : null}
             style={{
                // backgroundColor:localStorage.getItem('button_background_color'),
                border:'1px solid '+localStorage.getItem('button_background_color'),
                color:localStorage.getItem('button_text_color'),
                "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
                "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                }}
            >
              <div class="d-flex align-items-center justify-content-center h-100 w-100">
                      <div class={this.state.saveclass}>
                       
                       </div>
                      <div class={this.state.buttonsaveclass}   style={{
                       color:localStorage.getItem('button_background_color'),
                      }}>Save</div>
                </div>
            </button>
                <button 
                 className={`download-build-app-btn-mob ${this.state.is_generating_certification_in_process === false ?"":"download-build-app-btn-disabled"}`}
                 onClick={()=>this.state.is_generating_certification_in_process === false ? this.iossubmit('build') : null}
                                 style={{
                                    backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                                  }}
                >
                <div class="d-flex align-items-center justify-content-center h-100 w-100">
                    <div class={this.state.buildclass}>
                       
                    </div>
                    <div class={this.state.buttonbuildclass}>Build app</div>
                </div>
                </button>
                <p className="download-updated-mobdate">
                {this.state.updatedAt   ? `Updated ${moment(this.state.updatedAt).format("D MMMM YYYY, HH:mm")}` : ''}
                 </p>
        </div>

        
        


        </div>


            <Modal isOpen={this.state.openDownloadPopup} centered className="download-reset-popup">
                <ModalHeader >												
                    <div className="security-crossmodal " onClick={this.handleAttributePopUp}>
                        <img src={require("../../assets/images/dashboard/cross.png").default} />
                    </div>
                </ModalHeader>
                <ModalBody>

                        <div className="download-reset-body">
                            <h4>Reset </h4>
                            <h5>All data reset.</h5>
                            <div className="download-reset-footer">
                                <button onClick={this.handleAttributePopUp}>Cancel</button>
                                <button  onClick={this.handleReset}><div className={this.state.resetclass}></div><span className={this.state.buttonresetclass}>Reset</span></button>
                            </div>
                        </div>											
                </ModalBody>
            </Modal>

            <Modal isOpen={this.state.errormodal} onClick={this.closePopup} className="download-error-popup" centered >

            <ModalHeader >												
                    <div className="security-crossmodal " onClick={this.closePopup}>
                        <img src={require("../../assets/images/dashboard/cross.png").default} />
                    </div>
                </ModalHeader>

                <ModalBody>

                <div className="download-error-body">
                        <h4>{this.state.title}</h4>
                        <h5>{this.state.errormessage}</h5>
                        <button onClick={this.closePopup}>Okay</button>
                        
                </div>
                </ModalBody>
                </Modal>
            
                <Modal isOpen={this.state.openGenErrPopUp} 
                centered 
                className="download-error-popup"
                toggle={(e)=>this.handleOpenErrPopUpForGen(e)}
                >
                <ModalHeader >												
                    <div className="security-crossmodal " onClick={(e)=>this.handleOpenErrPopUpForGen(e)}>
                        <img src={require("../../assets/images/dashboard/cross.png").default} />
                    </div>
                </ModalHeader>
                <ModalBody>

                        <div className="download-error-body">
                            <h4><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <g id="Group_41408" data-name="Group 41408" transform="translate(-324.314 -455.386)">
                            <g id="Ellipse_2829" data-name="Ellipse 2829" transform="translate(324.314 455.386)" fill="#fff1f1" stroke="#ff4848" stroke-width="1">
                            <circle cx="8" cy="8" r="8" stroke="none"/>
                            <circle cx="8" cy="8" r="7.5" fill="none"/>
                            </g>
                            <path id="Path_8311" data-name="Path 8311" d="M0,3.438,3.438,0" transform="translate(330.187 461.902) rotate(-45)" fill="none" stroke="#ff4848" stroke-width="1"/>
                            <path id="Path_10113" data-name="Path 10113" d="M0,1.118,1.117,0" transform="translate(331.826 466.634) rotate(-45)" fill="none" stroke="#ff4848" stroke-width="1"/>
                            </g></svg>Something went wrong</h4>
                            <h5>
                              {/* It seems your app build has encountered an issue.<br/> */}
                              {this.state.genErrDescription}
                            </h5>
                            <button onClick={(e)=>this.handleOpenErrPopUpForGen(e)}>Okay</button>
                        </div>											
                </ModalBody>
            </Modal>

            <Modal
       className=" time-out-modal"
       style={{ maxWidth: "560px", maxHeight: "414px" }}
       isOpen={this.state.openWarningCertificatePopUp}
       centered
       toggle={(e) => this.handleOpenWarningCertificatePopUp(e)}
     >
       <ModalHeader>
        
         <div
           className="publish-crossmodal"
           onClick={(e) => this.handleOpenWarningCertificatePopUp(e)}
           style={{
             zIndex:"9"
           }}
         >
           <img
             src={
               require("../../assets/images/dashboard/cross.png").default
             }
           />
         </div>
       </ModalHeader>
       <ModalBody>

       <div className='publish-popup-maindiv'>
         <div className='publish-popup-head'>
             {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
               <g id="Group_41408" data-name="Group 41408" transform="translate(-324.314 -455.386)">
                 <g id="Ellipse_2829" data-name="Ellipse 2829" transform="translate(324.314 455.386)" fill="#fff1f1" stroke="#ff4848" stroke-width="1">
                   <circle cx="8" cy="8" r="8" stroke="none" />
                   <circle cx="8" cy="8" r="7.5" fill="none" />
                 </g>
                 <path id="Path_8311" data-name="Path 8311" d="M0,3.438,3.438,0" transform="translate(330.187 461.902) rotate(-45)" fill="none" stroke="#ff4848" stroke-width="1" />
                 <path id="Path_10113" data-name="Path 10113" d="M0,1.118,1.117,0" transform="translate(331.826 466.634) rotate(-45)" fill="none" stroke="#ff4848" stroke-width="1" />
               </g>
             </svg> */}
           <h2>Add your App Store Identifier</h2>
         </div>
        <p>Before you proceed, ensure that you have entered your App’s Bundle ID in your Apple Developer account. To add your Bundle ID, log in to your Apple Developer Account > Certificates, Identifier & Profiles > Identifiers > click on the + icon > select App IDs >  select App type > add your app name in the Description field and your Bundle ID, as displayed on this screen, in the Bundle ID field. </p>


        <div className='tick-for-identify'>
          <label>
          <input type='checkbox' checked={this.state.isUserAgree} onChange={(e)=>this.handleUserAgree(e)}/>
          <span></span>
          </label>
          <p style={{textAlign:"left"}}>I have added my Bundle ID to my Apple Developer account
        </p>
        </div>
         <div className='publish-popup-bottom time-required-btn'>
           <button className='publish-popup-cancel'>
            <a href={"https://developer.apple.com/account/resources/identifiers/list"}
            target="_blank"
            >
            Apple Developer account
           <svg xmlns="http://www.w3.org/2000/svg" width="11.2" height="11.2" viewBox="0 0 11.2 11.2">
  <g id="Group_43292" data-name="Group 43292" transform="translate(-695.4 -246.4)">
    <path id="Path_10239" data-name="Path 10239" d="M4,1H1A1,1,0,0,0,0,2V9a1,1,0,0,0,1,1H8A1,1,0,0,0,9,9V6" transform="translate(696 247)" fill="none" stroke="#bcc1ce" stroke-width="1.2"/>
    <path id="Path_10240" data-name="Path 10240" d="M4,4V0H0" transform="translate(702 247)" fill="none" stroke="#bcc1ce" stroke-width="1.2"/>
    <path id="Path_10241" data-name="Path 10241" d="M2144-23303l-6,6" transform="translate(-1438 23550)" fill="none" stroke="#bcc1ce" stroke-width="1.2"/>
  </g>
</svg>
</a>
           </button>
           <button className={`publish-popup-confirm ${this.state.isUserAgree?"":"disable-proceed-download-btn"}`} 
            disabled={this.state.isUserAgree ? false : true}
            onClick={(e) => this.handlegenerateCertificateProfile(e)}
            >Proceed</button>
         </div>
         <div className='timeestimated-div'>
          <p>5 minutes estimated</p>
         </div>

       </div>

       </ModalBody>
     </Modal>

            </section>
    </>

);
}
};

