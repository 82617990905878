import React, { useEffect, useReducer, useRef } from "react";
import Header from "../../../Dashboard/Header";
import Leftpanel from "../../../Dashboard/Leftpanel";
import Reactcolorpicker from "../../../Dashboard/Appearance/NewReactcolorpicker";
import moment from "moment";
import ReactSelect from "react-select";
import "./Productsetting.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Spinner from "../../../Components/commonComponents/Spinner";
import Tooltip from "../../../Components/commonIcons/Tooltip";
import ProductsPreview from "./ProductsPreview";
import { MetaTitle } from "../../../utils/commonUtils";
import {
  useProductsDataQuery,
  useSaveProductsDataMutation,
} from "../../../services/productsApiSlice";
import { errortoast, sucesstoast } from "../../../Dashboard/Toaster";
import ModuleTitleTooltip from "../../../Components/commonIcons/ModuleTitleTooltip";
import FilterProductModal from "../../../Components/Products/FilterProductModal";
import LockedToggle from "../../../Components/commonComponents/LockedToggle";
import Imageratio from "../../../Components/commonComponents/ImgRatio";
import SharpImage from "../../../Components/Products/SharpImage";
import SoftImage from "../../../Components/Products/SoftImage";
import carouselBackIcon from "../../../assets/images/dashboard/carouselBackIcon.svg";
import carouselForwardIcon from "../../../assets/images/dashboard/carouselForwardIcon.svg";
import ListView from "../../../Components/Products/ListView";
import GridView from "../../../Components/Products/GridView";
import CrossIcon from "../../../Components/commonIcons/CrossIcon.js";
const Products = () => {
  const [productState, setProductState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      showRelatedProducts: true,
      showAdditionalInformation: true,
      showProductFilterAndSorting: true,
      showOutOfStock: true,
      showProductSearch: true,
      showDiscountPercentage: true,
      fitImageHorizontally: true,
      productViewStyle: true,
      enableCartBadge: true,
      cartBadgeCounterBgColour: "#FF3D3D",
      cartBadgeCounterTextColour: "#FFFFFF",
      enableDiscountBadge: true,
      enableWebViewInterface: false,
      discountBadgeTextColour: "#FFFFFF",
      discountBadgeBgColour: "#FA4143",
      appRightPanelExpand:
        localStorage.getItem("appSideBarCollapse") === true ||
        localStorage.getItem("appSideBarCollapse") === "true"
          ? true
          : false,
      showFilterOnListingPage: true,
      showSortOnListingPage: true,
      enableWishlistOnListingPage: true,
      enableWishlistOnDetailPage: true,
      showImagesInGallery: true,
      showImagesAsThumbnailsInGallery: true,
      showAdditionalInformationAsListOnDetailPage: false,
      imageRatioOnListingPage: "1:1",
      imageRatioOnDetailPage: "1:1",
      imageShapeOnListingPage: "soft_corner",
      filterRelatedProductsByOnDetailPage: "default",
      showNativePaymentScreen: true,
      optionsForFilter: [
        { label: "Default", value: "default" },
        { label: "Categories", value: "categories" },
        { label: "Featured", value: "featured" },
      ],
      activeSlide: 0,
      openMobFilterPopUp: false,
      showAddToCartOnListingPage: true,
      showCenterCropImagesOnListingPage: true,
      showCenterCropImagesOnDetailPage: true,
      lastUpdatedTimestamp: null,
      tempShowGridView: true,
      isMobile: window.matchMedia("(max-width: 1200px)").matches,
    }
  );
  const { data: productsData, isLoading: productDataLoading } =
    useProductsDataQuery();
  const [saveProductsData, { isLoading: saveProductDataLoading }] =
    useSaveProductsDataMutation();
  const wishlistRef = useRef();
  const additionalInfoRef = useRef();
  const relatedProductsRef = useRef();
  const history = useHistory();

  const handlesliderChange = (from) => {
    if (from === "left") {
      if (productState.activeSlide > 0) {
        setProductState({
          activeSlide:
            productState.activeSlide === 3 &&
            productState.showImagesInGallery === false
              ? productState.activeSlide - 2
              : productState.activeSlide - 1,
        });
      }
    } else {
      if (productState.activeSlide < 3) {
        setProductState({
          activeSlide:
            productState.activeSlide === 1 &&
            productState.showImagesInGallery === false
              ? productState.activeSlide + 2
              : productState.activeSlide + 1,
        });
      }
    }
  };

  const handleform = (e, from) => {
    const { name, checked } = e.target;
    if (from === "list") {
      if (name === "productViewStyle") {
        setProductState({
          tempShowGridView: checked,
        });
      }
      setProductState({
        activeSlide: 0,
      });
    } else if (from === "details") {
      setProductState({
        activeSlide: 1,
      });

      if (
        name === "showAdditionalInformation" ||
        name === "showAdditionalInformationAsListOnDetailPage"
      ) {
        if (!window.matchMedia("(max-width: 1200px)").matches) {
          setTimeout(() => {
            const itemElement = additionalInfoRef.current;
            if (itemElement) {
              itemElement.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }
          }, 1000);
        }
      } else if (name === "showRelatedProducts") {
        if (!window.matchMedia("(max-width: 1200px)").matches) {
          setTimeout(() => {
            const itemElement = relatedProductsRef.current;
            if (itemElement) {
              itemElement.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }
          }, 1000);
        }
      } else if (name === "enableWishlistOnDetailPage") {
        if (!window.matchMedia("(max-width: 1200px)").matches) {
          setTimeout(() => {
            const itemElement = wishlistRef.current;
            if (itemElement) {
              itemElement.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }
          }, 1000);
        }
      } else if (name == "enableWebViewInterface") {
        if (e.target.checked) {
          setProductState({
            showImagesInGallery: false,
          });
        } else {
          setProductState({
            showImagesInGallery: productState.showImagesInGallery,
          });
        }
      }
    } else if (from === "3rdSlide") {
      if (e.target.checked) {
        setProductState({
          activeSlide:
            name === "showImagesAsThumbnailsInGallery" &&
            productState.showImagesInGallery
              ? 2
              : name === "showImagesInGallery"
              ? 2
              : 1,
        });
      } else {
        setProductState({
          activeSlide:
            name === "showImagesAsThumbnailsInGallery" &&
            productState.showImagesInGallery
              ? 2
              : 1,
        });
      }
    } else if (from === "checkout") {
      setProductState({
        activeSlide: 3,
      });
    }

    setProductState({
      [name]: checked,
    });
  };

  const saveProductToggles = (e) => {
    e.preventDefault();

    const params = {
      show_product_filter_and_sorting:
        +productState.showProductFilterAndSorting, // Deprecated
      cart_badge_counter_bg_colour: productState.cartBadgeCounterBgColour, // Deprecated
      cart_badge_counter_text_colour: productState.cartBadgeCounterTextColour, // Deprecated
      enable_cart_badge: +productState.enableCartBadge, // Deprecated
      app_id: localStorage.getItem("Appid"),
      show_discount_percentage: +productState.showDiscountPercentage,
      show_related_products: +productState.showRelatedProducts,
      show_additional_information: +productState.showAdditionalInformation,
      show_out_of_stock: +productState.showOutOfStock,
      show_product_search: +productState.showProductSearch,
      fit_image_horizontally: +productState.fitImageHorizontally,
      product_view_style: productState.productViewStyle ? "grid" : "list",
      enable_web_view_interface: +productState.enableWebViewInterface,
      discount_badge_bg_colour: productState.discountBadgeBgColour ?? "",
      discount_badge_text_colour: productState.discountBadgeTextColour ?? "",
      enable_discount_badge: +productState.enableDiscountBadge,
      show_filter_on_listing_page: +productState.showFilterOnListingPage,
      show_sort_on_listing_page: +productState.showSortOnListingPage,
      enable_wishlist_on_listing_page:
        +productState.enableWishlistOnListingPage,
      enable_wishlist_on_detail_page: +productState.enableWishlistOnDetailPage,
      image_ratio_on_listing_page: productState.imageRatioOnListingPage ?? "",
      image_ratio_on_detail_page: productState.imageRatioOnDetailPage ?? "",
      image_shape_on_listing_page: productState.imageShapeOnListingPage ?? "",
      show_images_in_gallery: +productState.showImagesInGallery,
      show_images_as_thumbnails_in_gallery:
        +productState.showImagesAsThumbnailsInGallery,
      show_additional_information_as_list_on_detail_page:
        +productState.showAdditionalInformationAsListOnDetailPage,
      filter_related_products_by_on_detail_page:
        productState.filterRelatedProductsByOnDetailPage ?? "",
      show_add_to_cart_on_listing_page:
        +productState.showAddToCartOnListingPage,
      show_center_crop_images_on_detail_page:
        +productState.showCenterCropImagesOnDetailPage,
      show_center_crop_images_on_listing_page:
        +productState.showCenterCropImagesOnListingPage,
      show_payment_methods_screen_bool: +productState.showNativePaymentScreen,
      show_update_profile_toogle_on_checkout: 1,
    };

    saveProductsData(params)
      .unwrap()
      .then((res) => {
        setProductState({
          lastUpdatedTimestamp: res.getdata.updated_at,
        });
        sucesstoast("The record has been updated.");
      })
      .catch((error) => {
        errortoast("Oops! Something went wrong.");
      });
  };

  const handleRightPanelExpand = (value) => {
    setProductState({
      appRightPanelExpand: value,
    });
  };

  const handleStateChange = (type, value, extra = null) => {
    switch (type) {
      case "toggleFilterPopUp":
        setProductState({
          openMobFilterPopUp: !productState.openMobFilterPopUp,
        });
        break;
      case "updateDiscountBadgeBgColor":
        setProductState({
          discountBadgeBgColour: value,
        });
        break;
      case "updateDiscountBadgeTextColor":
        setProductState({
          discountBadgeTextColour: value,
        });
        break;
      case "updateCartBadgeBgColor":
        setProductState({
          cartBadgeCounterBgColour: value.hex,
        });
        break;
      case "updateCartBadgeTextColor":
        setProductState({
          cartBadgeCounterTextColour: value.hex,
        });
        break;
      case "toggleListGridView":
        setProductState({
          tempShowGridView: value === "grid",
        });
        break;
      case "updateRightPanelExpand":
        setProductState({
          appRightPanelExpand: value,
        });
        break;
      case "updateImageRatio":
        setProductState({
          [extra === "details"
            ? "imageRatioOnDetailPage"
            : "imageRatioOnListingPage"]: value,
          activeSlide: extra === "details" ? 1 : 0,
        });
        break;
      case "updateImageShape":
        setProductState({
          imageShapeOnListingPage: value,
        });
        break;
      case "updateFilter":
        setProductState({
          filterRelatedProductsByOnDetailPage: value.value,
          openMobFilterPopUp: false,
        });
        break;
      default:
        console.warn("Unhandled state update type:", type);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = MetaTitle("Product settings");
    if (parseInt(localStorage.getItem("website_technology")) === 4) {
      history.push("/app/settings");
    }

    if (productsData && productsData.code === 200) {
      if (!productsData.products_data) {
        setProductState({
          showDiscountPercentage: false,
          showRelatedProducts: true,
          showAdditionalInformation: true,
          showProductFilterAndSorting: true,
          showOutOfStock: true,
          showProductSearch: true,
          fitImageHorizontally: true,
          productViewStyle: true,
          enableWebViewInterface: false,
          discountBadgeTextColour: "#FFFFFF",
          discountBadgeBgColour: "#FA4143",
          enableCartBadge: true,
          enableDiscountBadge: true,
          cartBadgeCounterBgColour: "#FF3D3D",
          cartBadgeCounterTextColour: "#FFFFFF",
        });
      } else {
        setProductState({
          showRelatedProducts:
            productsData?.products_data?.show_related_products_bool === 1,
          showAdditionalInformation:
            productsData?.products_data?.show_additional_information_bool === 1,
          showProductFilterAndSorting:
            productsData?.products_data
              ?.show_product_filter_and_sorting_bool === 1,
          showOutOfStock:
            productsData?.products_data?.show_out_of_stock_bool === 1,
          showProductSearch:
            productsData?.products_data?.show_product_search_bool === 1,
          showDiscountPercentage:
            productsData?.products_data?.show_discount_percentage_bool === 1,
          fitImageHorizontally:
            productsData?.products_data?.fit_image_horizontally === 1,

          productViewStyle:
            !productsData?.products_data?.product_view_style ||
            productsData?.products_data?.product_view_style === "grid",

          tempShowGridView:
            !productsData?.products_data?.product_view_style ||
            productsData?.products_data?.product_view_style === "grid",

          enableWebViewInterface:
            productsData?.products_data?.enable_web_view_interface_bool === 1,

          discountBadgeTextColour:
            productsData?.products_data?.discount_badge_text_colour ||
            productsData?.products_data?.discount_badge_text_colour_object
              ?.portal_data,

          discountBadgeBgColour:
            productsData?.products_data?.discount_badge_bg_colour ||
            (productsData?.products_data?.discount_badge_bg_colour_object?.portal_data?.includes(
              "gradient"
            )
              ? productsData?.products_data?.discount_badge_bg_colour_object
                  ?.app_data?.colors?.[0]?.hex
              : productsData?.products_data?.discount_badge_bg_colour_object
                  ?.portal_data),

          enableDiscountBadge:
            productsData?.products_data?.enable_discount_badge !== 0,
          enableCartBadge: productsData?.products_data?.enable_cart_badge !== 0,

          cartBadgeCounterBgColour:
            productsData?.products_data?.cart_badge_counter_bg_colour ??
            "#FF3D3D",
          cartBadgeCounterTextColour:
            productsData?.products_data?.cart_badge_counter_text_colour ??
            "#FFFFFF",
          cartUrl: productsData?.products_data?.web_view_cart_url,

          // New Keys
          showFilterOnListingPage:
            productsData?.products_data?.show_filter_on_listing_page === 1 ||
            productsData?.products_data
              ?.show_product_filter_and_sorting_bool === 1,

          showSortOnListingPage:
            productsData?.products_data?.show_sort_on_listing_page === 1 ||
            productsData?.products_data
              ?.show_product_filter_and_sorting_bool === 1,

          enableWishlistOnListingPage:
            productsData?.products_data?.enable_wishlist_on_listing_page !== 0,
          enableWishlistOnDetailPage:
            productsData?.products_data?.enable_wishlist_on_detail_page !== 0,

          imageRatioOnListingPage:
            productsData?.products_data?.image_ratio_on_listing_page ?? "1:1",
          imageRatioOnDetailPage:
            productsData?.products_data?.image_ratio_on_detail_page ?? "1:1",
          imageShapeOnListingPage:
            productsData?.products_data?.image_shape_on_listing_page ??
            "soft_corner",

          showImagesInGallery:
            productsData?.products_data?.show_images_in_gallery !== 0,
          showImagesAsThumbnailsInGallery:
            productsData?.products_data
              ?.show_images_as_thumbnails_in_gallery !== 0,

          showAdditionalInformationAsListOnDetailPage:
            productsData?.products_data
              ?.show_additional_information_as_list_on_detail_page === 1,

          filterRelatedProductsByOnDetailPage:
            productsData?.products_data
              ?.filter_related_products_by_on_detail_page ?? "default",

          showNativePaymentScreen:
            productsData?.checkout_data?.show_payment_methods_screen_bool !== 0,
          showAddToCartOnListingPage:
            productsData?.products_data?.show_add_to_cart_on_listing_page !== 0,
          showCenterCropImagesOnDetailPage:
            productsData?.products_data
              ?.show_center_crop_images_on_detail_page !== 0,
          showCenterCropImagesOnListingPage:
            productsData?.products_data
              ?.show_center_crop_images_on_listing_page !== 0,

          lastUpdatedTimestamp:
            productsData?.products_data?.last_updated_timestamp ?? null,
        });
      }
    }
  }, [productsData]);

  useEffect(() => {
    const handleResize = () => {
      setProductState({
        isMobile: window.matchMedia("(max-width: 1200px)").matches,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div>
        <Header customclass={"header-responsive-new"} />
      </div>
      <section className="new-consent-login">
        <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />

        <div
          className={`right-panel-gap ${
            localStorage.getItem("appSideBarCollapse") !== undefined &&
            localStorage.getItem("appSideBarCollapse") !== null &&
            localStorage.getItem("appSideBarCollapse") === "true"
              ? " right-panel-gap-expand"
              : ""
          }`}
        >
          <div className="new-consent-head ">
            <div className="productsetting-tophead-div">
              <h1 className="new-consent-headh">
                <ModuleTitleTooltip
                  title={"  Products"}
                  text={
                    "Customize your WooCommerce product settings for a seamless shopping experience for your customers."
                  }
                />
              </h1>
              <div className="date-and-savediv">
                {productState.lastUpdatedTimestamp && (
                  <p className="connectionupdatedate">
                    Updated{" "}
                    {moment(productState.lastUpdatedTimestamp).format(
                      "D MMMM YYYY, HH:mm"
                    )}
                  </p>
                )}
                {!productDataLoading && (
                  <button
                    style={{
                      backgroundColor: localStorage.getItem(
                        "button_background_color"
                      ),
                      border:
                        "1px solid " +
                        localStorage.getItem("button_background_color"),
                      color: localStorage.getItem("button_text_color"),
                      "--custom_color5":
                        localStorage.getItem("agencyid") == 1
                          ? "#134bec"
                          : localStorage.getItem("button_hover_bgcolor"),
                      "--custom_color4":
                        localStorage.getItem("agencyid") == 1
                          ? "white"
                          : localStorage.getItem("button_hover_txtcolor"),
                      "--custom_color6":
                        localStorage.getItem("agencyid") == 1
                          ? "#3064f9"
                          : localStorage.getItem("button_hover_bgcolor"),
                    }}
                    className="productsettingsave"
                    onClick={(e) => saveProductToggles(e)}
                    disabled={saveProductDataLoading}
                  >
                    <div className="d-flex align-items-center justify-content-center h-100 w-100">
                      {saveProductDataLoading ? (
                        <div className="new-loader"></div>
                      ) : (
                        <div>Save</div>
                      )}
                    </div>
                  </button>
                )}
              </div>
            </div>

            <div className="consent-modal-tab"></div>
            <div>
              <h3 className="product-setting-mobhead">Products</h3>
            </div>
            {productDataLoading ? (
              <div className="product-main-spinner">
                <Spinner />
              </div>
            ) : (
              <div className="setting-productdiv">
                <div className="product-setting-left">
                  <div className="product-setting-tophead">
                    <h3 className="product-setting-tophead-text">Settings</h3>
                    <p className="product-setting-tophead-subtext">
                      Fine-tune your product display preferences with these
                      settings.
                    </p>
                  </div>

                  <ul className="product-list-head-maindiv">
                    <h2 className="product-list-head-text">Product list</h2>
                    <li className="product-list-listtag">
                      <p className="setting-enable">
                        Show search icon{" "}
                        <Tooltip
                          message={
                            "Enable to display a search icon in the header for quick access to required content."
                          }
                        />
                      </p>
                      <label
                        className=" access-switch access-monitor-slider-padding"
                        htmlFor="showProductSearch"
                      >
                        <input
                          type="checkbox"
                          id="showProductSearch"
                          name="showProductSearch"
                          checked={productState.showProductSearch}
                          onChange={(e) => handleform(e, "list")}
                        />
                        <div className="access-slider round"></div>
                      </label>
                    </li>
                    <li className="product-list-listtag">
                      <p className="setting-enable">
                        Show filter{" "}
                        <Tooltip
                          message={
                            "Enable to allow your app users to filter products by attributes."
                          }
                        />
                      </p>
                      <label
                        className=" access-switch access-monitor-slider-padding"
                        htmlFor="showFilterOnListingPage"
                      >
                        <input
                          type="checkbox"
                          id="showFilterOnListingPage"
                          name="showFilterOnListingPage"
                          checked={productState.showFilterOnListingPage}
                          onChange={(e) => handleform(e, "list")}
                        />
                        <div className="access-slider round"></div>
                      </label>
                    </li>

                    <li className="product-list-listtag">
                      <p className="setting-enable">
                        Show sort{" "}
                        <Tooltip
                          message={
                            "Enabling to allow your customers to sort products based on their preferences."
                          }
                        />
                      </p>
                      <label
                        className=" access-switch access-monitor-slider-padding"
                        htmlFor="showSortOnListingPage"
                      >
                        <input
                          type="checkbox"
                          id="showSortOnListingPage"
                          name="showSortOnListingPage"
                          checked={productState.showSortOnListingPage}
                          onChange={(e) => handleform(e, "list")}
                        />
                        <div className="access-slider round"></div>
                      </label>
                    </li>

                    <li className="product-list-listtag">
                      <p className="setting-enable">
                        Show grid view{" "}
                        <Tooltip
                          message={
                            "Enable to display products in a grid view instead of a list view."
                          }
                        />
                      </p>
                      <label
                        className=" access-switch access-monitor-slider-padding"
                        htmlFor="productViewStyle"
                      >
                        <input
                          type="checkbox"
                          id="productViewStyle"
                          name="productViewStyle"
                          checked={productState.productViewStyle}
                          onChange={(e) => handleform(e, "list")}
                        />
                        <div className="access-slider round"></div>
                      </label>
                    </li>

                    <li className="product-list-listtag">
                      <p className="setting-enable">
                        Show discount badge{" "}
                        <Tooltip
                          message={
                            "Enable to display the discount badge on your products. This will appear for products that have a discounted sale price."
                          }
                        />
                      </p>
                      <label
                        className="access-switch access-monitor-slider-padding"
                        htmlFor="enableDiscountBadge"
                      >
                        <input
                          type="checkbox"
                          id="enableDiscountBadge"
                          name="enableDiscountBadge"
                          checked={productState.enableDiscountBadge}
                          onChange={(e) => handleform(e, "list")}
                        />
                        <div className="access-slider round"></div>
                      </label>
                    </li>

                    {productState.enableDiscountBadge && (
                      <div className="product-color-picker">
                        <li className="product-list-listtag">
                          <p className="setting-enable">
                            Badge background color{" "}
                            <Tooltip
                              message={
                                "Customize the background color of discount badges."
                              }
                            />
                          </p>

                          <Reactcolorpicker
                            colorname={productState.discountBadgeBgColour}
                            onChange={(e) =>
                              handleStateChange("updateDiscountBadgeBgColor", e)
                            }
                            classselection={"fixback"}
                            valuecontrol={true}
                          />
                          {productState.discountBadgeBgColour !== "#FF3D3D" && (
                            <CrossIcon
                              handleCrossClick={() =>
                                setProductState({
                                  discountBadgeBgColour: "#FF3D3D",
                                })
                              }
                            />
                          )}
                        </li>
                        <li className="product-list-listtag">
                          <p className="setting-enable bagde-icon-color-tag">
                            Badge icon color{" "}
                            <Tooltip
                              message={
                                "Customize the icon color of discount badges."
                              }
                            />
                          </p>
                          <Reactcolorpicker
                            colorname={productState.discountBadgeTextColour}
                            onChange={(e) =>
                              handleStateChange(
                                "updateDiscountBadgeTextColor",
                                e
                              )
                            }
                            classselection={"fixback"}
                            valuecontrol={true}
                          />
                          {productState.discountBadgeTextColour !==
                            "#FFFFFF" && (
                            <CrossIcon
                              handleCrossClick={() =>
                                setProductState({
                                  discountBadgeTextColour: "#FFFFFF",
                                })
                              }
                            />
                          )}
                        </li>
                      </div>
                    )}

                    {productState.enableDiscountBadge && (
                      <li className="product-list-listtag">
                        <p className="setting-enable">
                          Show discount percentage{" "}
                          <Tooltip
                            message={
                              "Enable to display the discount percentage in the discount badge."
                            }
                          />
                        </p>
                        <label
                          className="access-switch access-monitor-slider-padding"
                          htmlFor="showDiscountPercentage"
                        >
                          <input
                            type="checkbox"
                            id="showDiscountPercentage"
                            name="showDiscountPercentage"
                            checked={productState.showDiscountPercentage}
                            onChange={(e) => handleform(e, "list")}
                          />
                          <div className="access-slider round"></div>
                        </label>
                      </li>
                    )}
                    <li className="product-list-listtag">
                      <p className="setting-enable">
                        Show "Out of stock" watermark{" "}
                        <Tooltip
                          message={
                            'Enable to display the "Out of Stock" watermark. This watermark will appear as per your inventory settings.'
                          }
                        />
                      </p>
                      <label
                        className=" access-switch access-monitor-slider-padding"
                        htmlFor="showOutOfStock"
                      >
                        <input
                          type="checkbox"
                          id="showOutOfStock"
                          name="showOutOfStock"
                          checked={productState.showOutOfStock}
                          onChange={(e) => handleform(e, "list")}
                        />
                        <div className="access-slider round"></div>
                      </label>
                    </li>
                    <li className="product-list-listtag">
                      <p className="setting-enable">
                        Original images{" "}
                        <Tooltip
                          message={
                            "Enable to display originally uploaded images of your products. Original images are not optimized and will increase the load time of your app."
                          }
                        />
                      </p>
                      <label
                        className=" access-switch access-monitor-slider-padding"
                        htmlFor="height"
                      >
                        <input
                          type="checkbox"
                          id="height"
                          name="fitImageHorizontally"
                          checked={productState.fitImageHorizontally}
                          onChange={(e) => handleform(e, "list")}
                        />
                        <div className="access-slider round"></div>
                      </label>
                    </li>

                    <li className="product-list-listtag">
                      <p className="setting-enable">
                        Show add to cart button{" "}
                        <Tooltip
                          message={`Enable to display the "Add to Cart" button.`}
                        />
                      </p>
                      <label
                        className=" access-switch access-monitor-slider-padding"
                        htmlFor="showAddToCartOnListingPage"
                      >
                        <input
                          type="checkbox"
                          id="showAddToCartOnListingPage"
                          name="showAddToCartOnListingPage"
                          checked={productState.showAddToCartOnListingPage}
                          onChange={(e) => handleform(e, "list")}
                        />
                        <div className="access-slider round"></div>
                      </label>
                    </li>
                    <li className="product-list-listtag">
                      <p className="setting-enable">
                        Show add to wishlist button{" "}
                        <Tooltip
                          message={
                            "Enable to allow app users to add products to the app’s local wishlist."
                          }
                        />
                      </p>
                      <label
                        className=" access-switch access-monitor-slider-padding"
                        htmlFor="enableWishlistOnListingPage"
                      >
                        <input
                          type="checkbox"
                          id="enableWishlistOnListingPage"
                          name="enableWishlistOnListingPage"
                          checked={productState.enableWishlistOnListingPage}
                          onChange={(e) => handleform(e, "list")}
                        />
                        <div className="access-slider round"></div>
                      </label>
                    </li>

                    <li className="product-list-listtag">
                      <p className="setting-enable">
                        Image ratio{" "}
                        <Tooltip
                          message={
                            "Define the aspect ratio of your product images. Select a suitable image ratio corresponding to your product image."
                          }
                        />
                      </p>

                      <div className="product-box-size">
                        <Imageratio
                          selectedImgRatio={
                            productState.imageRatioOnListingPage
                          }
                          imgRatio={"16:9"}
                          handleRatioChange={(e) =>
                            handleStateChange("updateImageRatio", "16:9")
                          }
                          tooltipContent={
                            "Select for a widescreen image format, ideal for panoramic views and cinematic visuals."
                          }
                        />
                        <Imageratio
                          selectedImgRatio={
                            productState.imageRatioOnListingPage
                          }
                          imgRatio={"4:3"}
                          handleRatioChange={(e) =>
                            handleStateChange("updateImageRatio", "4:3")
                          }
                          tooltipContent={
                            "Opt to maintain a classic image aspect ratio, suitable for balanced compositions."
                          }
                        />
                        <Imageratio
                          selectedImgRatio={
                            productState.imageRatioOnListingPage
                          }
                          imgRatio={"1:1"}
                          handleRatioChange={(e) =>
                            handleStateChange("updateImageRatio", "1:1")
                          }
                          tooltipContent={
                            "Choose for a square image format, great for symmetrical and uniform visuals."
                          }
                        />
                        <Imageratio
                          selectedImgRatio={
                            productState.imageRatioOnListingPage
                          }
                          imgRatio={"3:4"}
                          handleRatioChange={(e) =>
                            handleStateChange("updateImageRatio", "3:4")
                          }
                          tooltipContent={
                            "Pick for a portrait-oriented image format, perfect for capturing vertical scenes and portraits."
                          }
                        />
                        <Imageratio
                          selectedImgRatio={
                            productState.imageRatioOnListingPage
                          }
                          imgRatio={"9:16"}
                          handleRatioChange={(e) =>
                            handleStateChange("updateImageRatio", "9:16")
                          }
                          tooltipContent={
                            "Opt for a vertical image format perfect for capturing tall scenes and portrait-oriented visuals."
                          }
                        />
                      </div>
                    </li>
                    <li className="product-list-listtag">
                      <p className="setting-enable">
                        Image shape{" "}
                        <Tooltip
                          message={
                            "Define the preferred shape of your product images."
                          }
                        />
                      </p>

                      <div className="shape-of-product">
                        <SharpImage
                          productState={productState}
                          handleStateChange={handleStateChange}
                        />
                        <SoftImage
                          productState={productState}
                          handleStateChange={handleStateChange}
                        />
                      </div>
                    </li>
                    <li className="product-list-listtag">
                      <p className="setting-enable">
                        Center crop images{" "}
                        <Tooltip
                          message={
                            "When enabled, your images will be centered within the image frame, for a consistent look. When disabled, images will be displayed to fit the frame while maintaining their original aspect ratios."
                          }
                        />
                      </p>
                      <label
                        className=" access-switch access-monitor-slider-padding"
                        htmlFor="showCenterCropImagesOnListingPage"
                      >
                        <input
                          type="checkbox"
                          id="showCenterCropImagesOnListingPage"
                          name="showCenterCropImagesOnListingPage"
                          checked={
                            productState.show_center_crop_images_on_listing_page
                          }
                          onChange={(e) => handleform(e, "list")}
                        />
                        <div className="access-slider round"></div>
                      </label>
                    </li>
                  </ul>

                  <ul className="product-list-head-maindiv">
                    <h2 className="product-list-head-text">Product details</h2>
                    <li className="product-list-listtag">
                      <p className="setting-enable">
                        Render as web view{" "}
                        <Tooltip
                          message={
                            "Enable to render the page exactly as displayed on the mobile browser."
                          }
                        />
                      </p>
                      <label
                        className=" access-switch access-monitor-slider-padding"
                        htmlFor="enableWebViewInterface"
                      >
                        <input
                          type="checkbox"
                          id="enableWebViewInterface"
                          name="enableWebViewInterface"
                          checked={productState.enableWebViewInterface}
                          onChange={(e) => handleform(e, "details")}
                        />
                        <div className="access-slider round"></div>
                      </label>
                    </li>
                    <li className="product-list-listtag">
                      <p className="setting-enable">
                        Show wishlist icon{" "}
                        <Tooltip
                          message={
                            "Enable to display a wishlist icon in the header for quick access to products added to the app user’s wishlist."
                          }
                        />
                      </p>
                      <label
                        className=" access-switch access-monitor-slider-padding"
                        htmlFor="enableWishlistOnDetailPage"
                      >
                        <input
                          type="checkbox"
                          id="enableWishlistOnDetailPage"
                          name="enableWishlistOnDetailPage"
                          checked={productState.enableWishlistOnDetailPage}
                          onChange={(e) => handleform(e, "details")}
                        />
                        {productState.enableWebViewInterface ? (
                          <LockedToggle />
                        ) : (
                          <div className="access-slider round"></div>
                        )}
                      </label>
                    </li>
                    <li className="product-list-listtag">
                      <p className="setting-enable">
                        Show images in full screen{" "}
                        <Tooltip
                          message={
                            "Enable to display product images in full-screen mode for a detailed view."
                          }
                        />
                      </p>
                      <label
                        className=" access-switch access-monitor-slider-padding"
                        htmlFor="showImagesInGallery"
                      >
                        <input
                          type="checkbox"
                          id="showImagesInGallery"
                          name="showImagesInGallery"
                          checked={productState.showImagesInGallery}
                          onChange={(e) => handleform(e, "3rdSlide")}
                        />
                        {productState.enableWebViewInterface ? (
                          <LockedToggle />
                        ) : (
                          <div className="access-slider round"></div>
                        )}
                      </label>
                    </li>
                    {productState.showImagesInGallery && (
                      <li className="product-list-listtag">
                        <p className="setting-enable">
                          Show images as thumbnails{" "}
                          <Tooltip
                            message={
                              "Enable to display product images as thumbnails for a quick preview."
                            }
                          />
                        </p>
                        <label
                          className=" access-switch access-monitor-slider-padding"
                          htmlFor="showImagesAsThumbnailsInGallery"
                        >
                          <input
                            type="checkbox"
                            id="showImagesAsThumbnailsInGallery"
                            name="showImagesAsThumbnailsInGallery"
                            checked={
                              productState.showImagesAsThumbnailsInGallery
                            }
                            onChange={(e) => handleform(e, "3rdSlide")}
                          />
                          {productState.enableWebViewInterface ? (
                            <LockedToggle />
                          ) : (
                            <div className="access-slider round"></div>
                          )}
                        </label>
                      </li>
                    )}
                    <li className="product-list-listtag">
                      <p className="setting-enable">
                        Show additional information{" "}
                        <Tooltip
                          message={
                            "Enable to showcase the attributes pertaining to the product."
                          }
                        />
                      </p>
                      <label
                        className=" access-switch access-monitor-slider-padding"
                        htmlFor="showAdditionalInformation"
                      >
                        <input
                          type="checkbox"
                          id="showAdditionalInformation"
                          name="showAdditionalInformation"
                          checked={productState.showAdditionalInformation}
                          onChange={(e) => handleform(e, "details")}
                        />
                        {productState.enableWebViewInterface ? (
                          <LockedToggle />
                        ) : (
                          <div className="access-slider round"></div>
                        )}
                      </label>
                    </li>
                    {productState.showAdditionalInformation && (
                      <li className="product-list-listtag">
                        <p className="setting-enable">
                          Show additional information as list{" "}
                          <Tooltip
                            message={
                              "Enable to display attributes in a list format for better readability."
                            }
                          />
                        </p>
                        <label
                          className=" access-switch access-monitor-slider-padding"
                          htmlFor="showAdditionalInformationAsListOnDetailPage"
                        >
                          <input
                            type="checkbox"
                            id="showAdditionalInformationAsListOnDetailPage"
                            name="showAdditionalInformationAsListOnDetailPage"
                            checked={
                              productState.showAdditionalInformationAsListOnDetailPage
                            }
                            onChange={(e) => handleform(e, "details")}
                          />
                          {productState.enableWebViewInterface ? (
                            <LockedToggle />
                          ) : (
                            <div className="access-slider round"></div>
                          )}
                        </label>
                      </li>
                    )}
                    <li className="product-list-listtag">
                      <p className="setting-enable">
                        Show related products{" "}
                        <Tooltip
                          message={
                            " Enable to display related products below the viewed product."
                          }
                        />
                      </p>
                      <label
                        className=" access-switch access-monitor-slider-padding"
                        htmlFor="showRelatedProducts"
                      >
                        <input
                          type="checkbox"
                          id="showRelatedProducts"
                          name="showRelatedProducts"
                          checked={productState.showRelatedProducts}
                          onChange={(e) => handleform(e, "details")}
                        />
                        {productState.enableWebViewInterface ? (
                          <LockedToggle />
                        ) : (
                          <div className="access-slider round"></div>
                        )}
                      </label>
                    </li>
                    {productState.showRelatedProducts && (
                      <li className="product-list-listtag setting-last-li">
                        <p className="setting-enable product-dropdown-label">
                          Filter related products by{" "}
                          <Tooltip
                            message={
                              "Choose what related products to show below the viewed product."
                            }
                          />
                        </p>
                        <div
                          className={`mobile-view-click ${
                            productState.enableWebViewInterface
                              ? "filter-related-post-disable"
                              : ""
                          }`}
                          onClick={(e) =>
                            productState.isMobile
                              ? handleStateChange("toggleFilterPopUp")
                              : null
                          }
                        >
                          <ReactSelect
                            options={productState.optionsForFilter}
                            onChange={(e) =>
                              handleStateChange("updateFilter", e)
                            }
                            required={true}
                            classNamePrefix="profile-role-dropdown"
                            value={productState.optionsForFilter.filter(
                              (res) =>
                                res.value ===
                                productState.filterRelatedProductsByOnDetailPage
                            )}
                            placeholder="Select...."
                            isSearchable={false}
                            isClearable={false}
                            isDisabled={
                              window.matchMedia("(max-width: 1200px)")
                                .matches || productState.enableWebViewInterface
                                ? true
                                : false
                            }
                          />
                        </div>
                      </li>
                    )}
                    <li className="product-list-listtag">
                      <p className="setting-enable">
                        Image ratio{" "}
                        <Tooltip
                          message={
                            "Define the aspect ratio of your product images. Select a suitable image ratio corresponding to your product image."
                          }
                        />
                      </p>

                      <div className="product-box-size">
                        <Imageratio
                          selectedImgRatio={productState.imageRatioOnDetailPage}
                          imgRatio={"16:9"}
                          isDisabled={productState.enableWebViewInterface}
                          handleRatioChange={(e) =>
                            handleStateChange(
                              "updateImageRatio",
                              "16:9",
                              "details"
                            )
                          }
                          tooltipContent={
                            "Select for a widescreen image format, ideal for panoramic views and cinematic visuals."
                          }
                        />
                        <Imageratio
                          selectedImgRatio={productState.imageRatioOnDetailPage}
                          imgRatio={"4:3"}
                          isDisabled={productState.enableWebViewInterface}
                          handleRatioChange={(e) =>
                            handleStateChange(
                              "updateImageRatio",
                              "4:3",
                              "details"
                            )
                          }
                          tooltipContent={
                            "Opt to maintain a classic image aspect ratio, suitable for balanced compositions."
                          }
                        />
                        <Imageratio
                          selectedImgRatio={productState.imageRatioOnDetailPage}
                          imgRatio={"1:1"}
                          isDisabled={productState.enableWebViewInterface}
                          handleRatioChange={(e) =>
                            handleStateChange(
                              "updateImageRatio",
                              "1:1",
                              "details"
                            )
                          }
                          tooltipContent={
                            "Choose for a square image format, great for symmetrical and uniform visuals."
                          }
                        />
                        <Imageratio
                          selectedImgRatio={productState.imageRatioOnDetailPage}
                          imgRatio={"3:4"}
                          isDisabled={productState.enableWebViewInterface}
                          handleRatioChange={(e) =>
                            handleStateChange(
                              "updateImageRatio",
                              "3:4",
                              "details"
                            )
                          }
                          tooltipContent={
                            "Pick for a portrait-oriented image format, perfect for capturing vertical scenes and portraits."
                          }
                        />
                        <Imageratio
                          selectedImgRatio={productState.imageRatioOnDetailPage}
                          imgRatio={"9:16"}
                          isDisabled={productState.enableWebViewInterface}
                          handleRatioChange={(e) =>
                            handleStateChange(
                              "updateImageRatio",
                              "9:16",
                              "details"
                            )
                          }
                          tooltipContent={
                            "Opt for a vertical image format perfect for capturing tall scenes and portrait-oriented visuals."
                          }
                        />
                      </div>
                    </li>

                    <li className="product-list-listtag">
                      <p className="setting-enable">
                        Center crop images{" "}
                        <Tooltip
                          message={
                            "When enabled, your images will be centered within the image frame, for a consistent look. When disabled, images will be displayed to fit the frame while maintaining their original aspect ratios."
                          }
                        />
                      </p>
                      <label
                        className=" access-switch access-monitor-slider-padding"
                        htmlFor="showCenterCropImagesOnDetailPage"
                      >
                        <input
                          type="checkbox"
                          id="showCenterCropImagesOnDetailPage"
                          name="showCenterCropImagesOnDetailPage"
                          checked={
                            productState.showCenterCropImagesOnDetailPage
                          }
                          onChange={(e) => handleform(e, "details")}
                        />
                        {productState.enableWebViewInterface ? (
                          <LockedToggle />
                        ) : (
                          <div className="access-slider round"></div>
                        )}
                      </label>
                    </li>
                  </ul>

                  <ul className="productdetails-ul product-list-head-maindiv">
                    <h2 className="product-list-head-text">Checkout</h2>
                    <li className="product-list-listtag">
                      <p className=" checkout-enable-para">
                        Show native payment screen{" "}
                        <Tooltip
                          message={
                            "Enable to display your payment options on a native screen. Disabling this option allows users to choose payment options directly on the rendered web view payment screen."
                          }
                        />
                      </p>
                      <label
                        className=" access-switch access-monitor-slider-padding"
                        htmlFor="showNativePaymentScreen"
                      >
                        <input
                          type="checkbox"
                          id="showNativePaymentScreen"
                          name="showNativePaymentScreen"
                          checked={productState.showNativePaymentScreen}
                          onChange={(e) => handleform(e, "checkout")}
                        />
                        <div className="access-slider round"></div>
                      </label>
                    </li>
                  </ul>
                </div>
                <div className="product-setting-right">
                  <div className="product-mob-previewbtn">
                    <div
                      className={`product-mob-previewtopback  ${
                        productState.activeSlide === 0
                          ? "login-mob-previewtopbackopacity"
                          : ""
                      }`}
                      onClick={() => handlesliderChange("left")}
                    >
                      <img src={carouselBackIcon} alt="back-icon" />
                    </div>
                    <div
                      className={`product-mob-previewtopnext   ${
                        productState.activeSlide === 3
                          ? "login-mob-previewtopbackopacity"
                          : ""
                      }`}
                      onClick={() => handlesliderChange("right")}
                    >
                      <img src={carouselForwardIcon} alt="forward-icon" />
                    </div>
                  </div>
                  <ProductsPreview
                    value={this}
                    productState={productState}
                    wishlistRef={wishlistRef}
                    additionalInfoRef={additionalInfoRef}
                    relatedProductsRef={relatedProductsRef}
                  />

                  {productState.productViewStyle &&
                    productState.activeSlide === 0 && (
                      <div className="product-togglegrid">
                        <ListView
                          productState={productState}
                          handleStateChange={handleStateChange}
                        />
                        <GridView
                          productState={productState}
                          handleStateChange={handleStateChange}
                        />
                      </div>
                    )}
                </div>
              </div>
            )}
            <div className="productsetting-save-mob-btn">
              {!productDataLoading && (
                <button
                  className="productSetting-save-btn"
                  onClick={(e) => saveProductToggles(e)}
                  style={{
                    backgroundColor: localStorage.getItem(
                      "button_background_color"
                    ),
                    border:
                      "1px solid " +
                      localStorage.getItem("button_background_color"),
                    color: localStorage.getItem("button_text_color"),
                    "--custom_color5":
                      localStorage.getItem("agencyid") == 1
                        ? "#134bec"
                        : localStorage.getItem("button_hover_bgcolor"),
                    "--custom_color4":
                      localStorage.getItem("agencyid") == 1
                        ? "white"
                        : localStorage.getItem("button_hover_txtcolor"),
                    "--custom_color6":
                      localStorage.getItem("agencyid") == 1
                        ? "#3064f9"
                        : localStorage.getItem("button_hover_bgcolor"),
                  }}
                  disabled={saveProductDataLoading}
                >
                  <div className="d-flex align-items-center justify-content-center h-100 w-100">
                    {saveProductDataLoading ? (
                      <div className="new-loader"></div>
                    ) : (
                      <div>Save</div>
                    )}
                  </div>
                </button>
              )}
              {productState.lastUpdatedTimestamp && (
                <p className="product-update-time">
                  Updated{" "}
                  {moment(productState.lastUpdatedTimestamp).format(
                    "D MMMM YYYY, HH:mm"
                  )}
                </p>
              )}
            </div>
          </div>
        </div>
        <FilterProductModal
          productState={productState}
          handleStateChange={handleStateChange}
        />
      </section>
    </>
  );
};

export default Products;
