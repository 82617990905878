import React from "react";
import { Modal, ModalBody } from "reactstrap";
const ProTipModal = ({
  popUpTitle,
  PopupDesc,
  protipImg,
  tipsArr,
  handleProTipPopUp,
  openProTipModal,
}) => {
  return (
    <Modal className="protip-popup-message" isOpen={openProTipModal} centered>
      <ModalBody>
        <div className="protip-popup">
          <div className="protip-popup-one">
            <h4 className="protip-popup-title">{popUpTitle}</h4>
            <h6 className="protip-popup-desc">{PopupDesc}</h6>
            <ul className="protip-popup-list">
              {tipsArr?.map((tip) => {
                return <li className="protip-popup-list-item" key={tip.text}>{tip.text}</li>;
              })}
            </ul>

            <button className="protip-popup-okay-btn" onClick={handleProTipPopUp}>Okay</button>
          </div>

          <div className="protip-popup-two">
            <img src={protipImg} className="img-fluid" alt="protip-img" />
          </div>
        </div>

        <div className="protip-popup-crossmodal">
          <img
          className="popup-cross-svg"
            onClick={handleProTipPopUp}
            src={require("../../../assets/images/dashboard/popupcross.svg").default}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ProTipModal;
