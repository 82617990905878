import React, { useState } from "react";
import { Modal as ReactstrapModal, ModalBody } from "reactstrap";

const Modal = ({
  open,
  toggle,
  title,
  description,
  cancelbutton,
  submitbutton,
  onSubmit,
  isLoading,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await onSubmit(); // Call the onSubmit function
    } catch (error) {
      console.error("Submission failed", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ReactstrapModal
      isOpen={open}
      centered
      toggle={toggle}
      style={{ maxWidth: "480px", maxHeight: "296px" }}
      className="commonModal"
      dataclassname="addonpopup"
    >
      <div className="commonModal-crossmodal" onClick={toggle}>
        <img
          src={require("../../assets/images/dashboard/popupcross.svg").default}
          alt="cross"
          className="common-cross-img"
        />
      </div>
      <ModalBody>
        <div>
          <div className="">
            <div className="commonModal-content">
              <h6 className="commonModal-title">{title}</h6>
              <h6 className="commonModal-description">{description}</h6>
            </div>
          </div>
          <div className="commonModal-footer">
            <div className="commonModal-cancel-btn" onClick={toggle}>
              {cancelbutton}
            </div>

            <div onClick={handleSubmit}>
              <div className="commonModal-submit-btn-blue">
                {isSubmitting || isLoading ? (
                  <div className="new-loader"></div>
                ) : (
                  <div>{submitbutton}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </ReactstrapModal>
  );
};

export default Modal;
