import React from "react";
import "../../assets/css/profileleftpanel.css";
import ProfileLeftPanel from "../ProfileLeftPanel";
import moment from "moment";
import newDefaultImg from "../../assets/images/dashboard/sub-overview-img.png";
import { Link } from "react-router-dom";
import "./Billings.css";
import "./Deals.css";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import {
  Media,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import {
  changePaymentStripe,
  changePaymentZoho,
  getWorkspace,
  Subscriptionlist,
  Showcredit,
  getAgencies,
  workspaceSubscriptionList,
} from "../../Api/Dashboard/DashboardApi";
import BottomScrollListener from "react-bottom-scroll-listener";
import Billingheader from "./Billingheader";
import { errortoast } from "../Toaster";
import { Listcard } from "../../Api/Paymentmethod/Paymentapi";
import { Notallowedtouser } from "../User/Usernotalllowed";
import ReactSelect from "react-select";
import userdefaultImgOnError from "../../assets/images/dashboard/accountprofile.png";
import wpdefaultImgOnError from "../../assets/images/dashboard/workspace/workspace-default-img.png";
import Header from "../Header";
import Accountleftpanel from "../Accountleftpanel";


export default class Billingpayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    billingdata: [],
    spinner: true,
    mainSpinner: true,
    pageval: 1,
    datanumber: "",
    bottomspinner: false,
    customer_type: null,
    lifetimedata: [],
    customer_billing_type: "",
    isCard: false,
    data: null,
    cardSpinner: false,
    payment_method_id: "",
    appId: "",
    cardDoneLoader: "d-none",
    cardLoaderImage: require("../../assets/images/signup/Loader_GIF.gif")
      .default,
    zohoLoader:
      require("../../assets/images/dashboard/workspace/zoho-loader.gif")
        .default,
    cardBtnText: "Done",
    paymentIdOfRow: "",
    isImage: "",
    allowBtn: true,
    menuData: [],
    userInfo: {},

    invitedWorkspaceData: [],
    open_profile_menu:false,
    user_role: "",
    subsSeacrch: "",
    creditdata: "",
    mergeArray: [],
    mergeAgencies: [],
    right_panel_expand: true,
    mobileBillingDropdown: false,
    mobileBillingDropdownTwo: false,
    profile: "",
    mobileViewLandingPage: false,
    // wpIDforDrop: localStorage.getItem("workspaceId"),
    card_count: 0,
    typedata:[],
    seachapp_id:'',
    seachworkspace_id:'',
    seachagency_id:'',
    searchAppVal:'',
    openAppDropdown:false,
    searchname:'All',
    totalmergeArray:[{value:1,label:'Pay-per-app'},{value:2,label:'Unlimited apps'},{value:3,label:'Agency'},{value:4,label:'Support'}],
    
  };
}
  componentDidMount() 
  {
    document.title = "Subscriptions | AppMySite";
    
   
    Showcredit(this);

    
    let searchstr = (this.props).history.location.search;
    let urlParams = (new URLSearchParams(searchstr));
    let type = urlParams.get('type');
    let id = urlParams.get('id');

    if(type !== undefined && type !== null && type !== "")
    {
      if(type == 'agency')
      {
        const params = {
          limit: 20,
          page: 1,
          type:3,
          workspace_id:"",
          agency_id:atob(id),
          app_id:""
        };
    
        Subscriptionlist(params, this);

        localStorage.setItem('selectedbilling',3);
        
        this.setState({selectedbillingtype:3});
      }
      if(type == 'workspace')
      {
        const params = {
          limit: 20,
          page: 1,
          type:2,
          workspace_id:atob(id),
          agency_id:"",
          app_id:""
        };
    
        Subscriptionlist(params, this);

        localStorage.setItem('selectedbilling',2);
        
        this.setState({selectedbillingtype:2});
      }
      if(type == 'apps')
      {
        const params = {
          limit: 20,
          page: 1,
          type:1,
          workspace_id:"",
          agency_id:"",
          app_id:atob(id)
        };
    
        Subscriptionlist(params, this);

        localStorage.setItem('selectedbilling',1);
        
        this.setState({selectedbillingtype:1});
      }
        
    }
    else
    {


      if(localStorage.getItem("selectedbilling") && localStorage.getItem("selectedbilling") != '' && localStorage.getItem("selectedbilling") != null && localStorage.getItem("selectedbilling") != undefined && localStorage.getItem("selectedbilling") != 'null' && localStorage.getItem("selectedbilling") != 'undefined')
      {
        
      const params = {
        limit: 20,
        page: 1,
        type:parseInt(localStorage.getItem("selectedbilling")),
        workspace_id:"",
        agency_id:"",
        app_id:""
      };
  
      Subscriptionlist(params, this);
        this.setState({selectedbillingtype:parseInt(localStorage.getItem("selectedbilling"))});
        this.setState({selectedbillingname:parseInt(localStorage.getItem("selectedbilling")) == 1 ? 'Pay-per-app': parseInt(localStorage.getItem("selectedbilling")) == 2 ? 'Unlimited apps' :'White-label'})
      }
      else
      {
        
      const params = {
        limit: 20,
        page: 1,
        type:1,
        workspace_id:"",
        agency_id:"",
        app_id:""
      };
  
      Subscriptionlist(params, this);
        localStorage.setItem("selectedbilling",1);
      }
    }

  }

  handleAppdropdown = () => {
    this.setState({
      openAppDropdown: !this.state.openAppDropdown,
    });
  };

  handleSubsSearch = (e) => {
    this.setState({
      subsSeacrch: e.target.value,
    });
  };


  movetoapppricing = (e, tech) => {
    localStorage.setItem("Appid", parseInt(e));
    localStorage.setItem("website_technology", parseInt(tech));
    window.location.href =  
    parseInt(localStorage.getItem('website_technology')) === 4 ? 
    "/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid'))
  : parseInt(localStorage.getItem('website_technology')) === 5 ? 
    "/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'))
  : parseInt(localStorage.getItem('website_technology')) === 3 ? 
    "/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'))
  : parseInt(localStorage.getItem('website_technology')) === 2 ? 
    "/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'))
  : "/app/pricing";
  };

  moveworkspacepricing = (e) => {
    localStorage.setItem("workspaceId", parseInt(atob(e)));
    window.location.href = "/workspace/pricing/" + e;
    // window.location.href =`/workspace/pricing/${parseInt(atob(e))}`
  };

  // Handle Billing dropdown workspace Data from child
  handleBillingChange = (workspaceData, from) => {
    if (workspaceData.type === "user") 
    {
      localStorage.setItem("workspaceId", "");
      localStorage.setItem("workspaceName", "");
      localStorage.setItem("workspaceImage", "");
      localStorage.setItem("logged_in_user_role_id", 1);
    } 
    else if(workspaceData.agency_name != undefined)
    {
      localStorage.setItem("workspaceId", "");
      localStorage.setItem("workspaceName", "");
      localStorage.setItem("workspaceImage", "");
      localStorage.setItem("logged_in_user_role_id", 1);
      localStorage.setItem("agency_id", workspaceData.id);

    }
    else 
    {
      if (
        workspaceData.role_id !== undefined &&
        workspaceData.role_id !== null &&
        workspaceData.role_id !== ""
      ) {
        localStorage.setItem("logged_in_user_role_id", workspaceData.role_id);

        localStorage.setItem("invited_by_user_id", workspaceData.user_id);
      } else {
        localStorage.setItem("logged_in_user_role_id", 1);
        // localStorage.setItem("invited_by_user_id", "");
      }
      localStorage.setItem("workspaceId", workspaceData.id);
      localStorage.setItem("workspaceName", workspaceData.workspace_name);
      localStorage.setItem("agency_id", "");

      localStorage.setItem(
        "workspaceImage",
        workspaceData.profile_image === null ? "" : workspaceData.profile_image
      );
    }
    window.location.href = `/subscriptions`;
  };

  setname = (value) => {
    let appshort = value.split(" ");

    let i;
    let apparray = [];

    if (appshort.length > 1) {
      for (i = 0; i < appshort.length; i++) {
        apparray.push(appshort[i].charAt(0).toUpperCase());
      }
      return apparray.join("").slice(0, 3);
    } else {
      return appshort.join("").slice(0, 2);
    }
  };

  handleBillingChangeTotal = (workspaceData, from) => {
    localStorage.setItem("selectedbilling", workspaceData.value);
  
    this.setState(prevState => ({
      selectedbillingtype: workspaceData.value,
      selectedbillingname: workspaceData.label,
      mobileBillingDropdown: false,
      mobileBillingDropdownTwo: false,
      mainSpinner: true,
      searchname: "All"
    }), () => {

      const params = {
        limit: 20,
        page: 1,
        workspace_id: "",
        agency_id: "",
        app_id: "",
        type: workspaceData.value,
      };
  
      Subscriptionlist(params, this);
    });
  };
  
  newhandleBillingChange = (workspaceData, from) => 
  {
    this.setState({typedata:[]});
    if (parseInt(localStorage.getItem("selectedbilling")) == 1) 
    {
      if(workspaceData != '')
      {
        var idapp= workspaceData.app_id;
        var idname = workspaceData.app_name;
      }
      else
      {
        var idapp= '';
        var idname = 'All';
      }
      const params = 
      {
        limit: 20,
        page: 1,
        workspace_id: "",
        agency_id: "",
        app_id:idapp ,
        type : 1,
      };
      this.setState({seachapp_id:workspaceData.app_id,searchname:idname,typedata:[],mainSpinner:true,mobileBillingDropdown:false,mobileBillingDropdownTwo:false}, () => {Subscriptionlist(params, this)});
     
    } 
    else if (parseInt(localStorage.getItem("selectedbilling")) == 2) 
    {
      if(workspaceData != '')
      {
        var idapp= workspaceData.id;
        var idname = workspaceData.workspace_name;
      }
      else
      {
        var idapp= '';
        var idname = 'All';
      }
      const params = 
      {
        limit: 20,
        page: 1,
        workspace_id: idapp,
        agency_id: "",
        app_id: "",
        type : 2,
      };
      this.setState({seachworkspace_id:workspaceData.id,searchname:idname,typedata:[],mainSpinner:true,mobileBillingDropdown:false,mobileBillingDropdownTwo:false}, () => {Subscriptionlist(params, this)});
    } 
    else if (parseInt(localStorage.getItem("selectedbilling")) == 3) 
    {
      
      if(workspaceData != '')
      {
        var idapp= workspaceData.id;
        var idname = workspaceData.agency_name;
      }
      else
      {
        var idapp= '';
        var idname = 'All';
      }
      const params = 
      {
        limit: 20,
        page: 1,
        workspace_id: "",
        agency_id: idapp,
        app_id: "",
        type : 3,
      };
      this.setState({seachagency_id:workspaceData.id,searchname:idname,typedata:[],mainSpinner:true,mobileBillingDropdown:false,mobileBillingDropdownTwo:false}, () => {Subscriptionlist(params, this)});
      
    } 
    else if (parseInt(localStorage.getItem("selectedbilling")) == 4) 
    {
     
        var idapp= '';
        var idname = 'All';
      
        const params = 
      {
        limit: 20,
        page: 1,
        workspace_id: "",
        agency_id: "",
        app_id: "",
        type : 4,
      };
      this.setState({seachworkspace_id:workspaceData.id,searchname:idname,typedata:[],mainSpinner:true,mobileBillingDropdown:false,mobileBillingDropdownTwo:false}, () => {Subscriptionlist(params, this)});
    } 
    else 
    {
      this.setState({typedata:[]});
    }
  };

  handleEditforAddon = (
    e,
    user_id,
    status,
    appname,
    id,
    version,
    appshortname,
    subscription,
    store_url,
    plugin_data,
    gateway,
    dashboard_count,
    website_type,
    is_verified,
    appicon_count,
    splash_icon_count,
    login_reg_count,
    style_count,
    plan_name,
    addon,
    res,
    from
  ) => {
    localStorage.setItem("user_id", user_id);
    if (id !== "") 
    {
      localStorage.setItem("app_icon_count", appicon_count);
      localStorage.setItem("splash_icon_count", splash_icon_count);
      localStorage.setItem("login_register_icon_count", login_reg_count);
      localStorage.setItem("style_icon_count", style_count);
      localStorage.setItem("dashboard_icon_count", dashboard_count);
      localStorage.setItem("Appid", id);
      localStorage.setItem("Appname", appname);
      localStorage.setItem("Appversion", version);
      localStorage.setItem("appshortname", appshortname);
      localStorage.setItem("store_url", store_url);
      localStorage.setItem("plugin_dataactive", parseInt(plugin_data));
      localStorage.setItem("appsettingactive", parseInt(gateway));
      localStorage.setItem("personaliseactive", parseInt(dashboard_count));
      localStorage.setItem("website_type", parseInt(website_type));
      localStorage.setItem("website_type_select", parseInt(website_type));
      localStorage.setItem("is_verified", is_verified);
      localStorage.removeItem("session");
      let subscriptionlength = subscription;

      if (subscriptionlength === null || plan_name.indexOf("preview") !== -1) {
        localStorage.setItem("subscriptionactive", "0");
        localStorage.setItem("plan_code", btoa("preview"));
        if (
          status === "duplicate" ||
          status === "deleted" ||
          status === "Cancelled"
        ) {
          this.props.history.push("/apps");
        } else {
          this.props.history.push("/app/dasboard");
        }

        if (status === "duplicate") {
          this.setState({
            hitpopup: true,
            title: "Duplicate App Suspected",
            description:
              "This app appears to be a duplicate as the same domain was used to create another app. Would you still like to proceed?",
            bothbutton: false,
            suspended_app_id: id,
          });
        }
        if (status === "deleted") {
          errortoast("App deleted");
        }
        if (status.toLowerCase() === "cancelled") {
          errortoast("App deleted");
        }
      } else {
        let subpackagename = subscriptionlength.addon_codes;
        localStorage.setItem("plan_code", btoa(subscriptionlength.plan_code));
        localStorage.setItem(
          "subscriptionactive",
          btoa(JSON.stringify(subpackagename))
        );
        if (
          status === "duplicate" ||
          status === "deleted" ||
          status === "Cancelled" ||
          status === "inactive"
        ) {
          this.props.history.push("/apps");
        } else {
          this.props.history.push("/app/addons");
          // this.props.history.push(
          //   `/app/addons/${addon.addon_name
          //     .replaceAll(" ", "_")
          //     .toLowerCase()}/${btoa(addon.addon_code)}/${btoa(
          //     res.app_id

          //     // 1249
          //     )}/${btoa(from==="newAddon"?addon.plan_code :res.plan_code)}/${btoa(addon.addon_identifier)}`
          //   // )}/${btoa(res.plan_code)}/${btoa(addon.addon_identifier)}`
          // );
        }
        if (status === "duplicate") {
          this.setState({
            hitpopup: true,
            title: "Duplicate App Suspected",
            description:
              "This app appears to be a duplicate as the same domain was used to create another app. Would you still like to proceed?",
            bothbutton: false,
            suspended_app_id: id,
          });
        }
        //For appsumo client
        if (
          status === "inactive" &&
          subscription.plan_code
            .toString()
            .indexOf(process.env.REACT_APP_Lifetime_plan) !== -1
        ) {
          this.setState({
            hitpopup: true,
            title: "Your app is inactive",
            description:
              "This app is currently inactive.  Would you like to activate it now?",
            bothbutton: true,
            appsumo_app_id: id,
          });
        }
        //For appsumo_preview inactive client
        if (
          status === "inactive" &&
          subscription.plan_code.toString() ===
            process.env.REACT_APP_Lifetime_preview
        ) {
          this.setState({
            hitpopup: true,
            title: "License",
            description: "License not found",
            bothbutton: false,
          });
        }
        if (status === "deleted") {
          errortoast("App deleted");
        }
        if (status.toLowerCase() === "cancelled") {
          errortoast("App deleted");
        }
      }
    }
  };

  handlepagination = (pagination) => {
    Subscriptionlist(pagination, this);
  };

  handleContainerOnBottom = () => {
    if (this.state.bottomspinner === false) {
      if (this.state.datanumber < 20) 
      {
      }
       
      else 
      {
        this.setState({ pageval: 1 + this.state.pageval, bottomspinner: true });
        
        const params = {
          limit: 20,
          page: this.state.pageval,
          billingdata: this.state.billingdata,
          type:parseInt(localStorage.getItem("selectedbilling")),
          workspace_id:parseInt(localStorage.getItem("selectedbilling")) == 2
                      ? this.state.seachworkspace_id
                      : "",
          agency_id:parseInt(localStorage.getItem("selectedbilling")) == 3 
                    ? this.state.seachagency_id
                    : "",
          app_id: parseInt(localStorage.getItem("selectedbilling")) == 1 
              ? this.state.seachapp_id
              : "",
        };
    
        Subscriptionlist(params, this);
        // this.handlepagination(pagination);
      }
    }
  };
  urlsub = (url, userid) => {
    localStorage.setItem("user_id", userid);
    this.props.history.push("/subscription/" + btoa(url));
  };

  handleCard = (e, payment_method_id) => {
    this.setState({
      payment_method_id: payment_method_id,
      allowBtn: false,
      paymentIdOfRow: payment_method_id,
    });
  };

  closeCardPopUp = () => {
    this.setState({
      isCard: false,
    });
  };

  isCardPopUp = (e, appId, paymentIdOfRow) => {
    this.setState({
      isCard: !this.state.isCard,
      cardSpinner: true,
      appId: appId,
      paymentIdOfRow: paymentIdOfRow,
    });

    if (this.state.card_count === 0) {
      this.props.history.push(
        `/payment-method/${
          localStorage.getItem("workspaceId") !== "" &&
          localStorage.getItem("workspaceId") !== undefined &&
          localStorage.getItem("workspaceId") !== null
            ? btoa(localStorage.getItem("workspaceId"))
            : btoa(appId)
        }/${btoa(0)}`
      );
    } else {
      Listcard(this);
    }
  };

  changePaymentForStripe = (e) => {
    this.setState({
      cardBtnText: "",
      cardDoneLoader: "d-block",
    });
    let param;
    if (
      localStorage.getItem("workspaceId") === "" ||
      localStorage.getItem("workspaceId") === undefined ||
      localStorage.getItem("workspaceId") === null
    ) {
      param = {
        app_id: this.state.appId,
        payment_method_id: this.state.payment_method_id,
      };
    } else {
      param = {
        workspace_id: parseInt(localStorage.getItem("workspaceId")),
        payment_method_id: this.state.payment_method_id,
      };
    }

    changePaymentStripe(param, this);
  };

  changePaymentMthd = (appId, i) => {
    this.setState({
      isImage: "true" + i,
    });

    const param = {
      app_id: appId,
      redirect_url: window.location.href,
    };
    changePaymentZoho(param, this);
  };
  handleRightPanelExpand = (rightpanelvalue) => {
    this.setState({
      right_panel_expand: rightpanelvalue,
    });
  };
  handleMobileBillingDropdown = () => {
    this.setState({
      mobileBillingDropdown: !this.state.mobileBillingDropdown,
      mobileBillingDropdownTwo:false,
    });
  };
  handleMobileBillingDropdowntwo = () => {
    this.setState({
      mobileBillingDropdownTwo: !this.state.mobileBillingDropdownTwo,
      mobileBillingDropdown:false,
    });
  };
  
  openProfileMenus=(e)=>{
    this.setState({
     open_profile_menu:!this.state.open_profile_menu
   })
   }

  render() {
    const capitalize = s => s && s[0].toUpperCase() + s.slice(1)
    const customStyles = {
      control: (base, state) => ({
        ...base,
        background: "#ffffff",
      }),
    };

    const statusColor = (color) => {
      if (color === "Inactive" || color === "past_due") {
        return "canceled-red";
      } else if (color === "active") {
        return "active-green";
      } else if (color === "cancelled") {
        return "canceled-red";
      }
    };

    const returnStatus = (status) => {
      if (status === "active" || status === "live" ||    status === "free") {
        return (
          <p
            className={
              "billings-viewstatus-responsive billings-viewstatus-responsive-red billings-viewstatus "
            }
            style={{
              color: "#47CE70",
              // border: "1px solid #47CE70",
              // backgroundColor: "#E5FFED",
            }}
          >
            Active
          </p>
        );
      } else if (
      
        status === "paused" ||
        status === "cancelled" ||
        status === "canceled" ||
        status === "deleted"
      ) {
        return (
          <p
            className={
              "billings-viewstatus-responsive billings-viewstatus-responsive-red billings-viewstatus "
            }
            style={{
              textTransform: "capitalize",
              color: "#7782A1",
              // border: "1px solid #7782A1",
              // backgroundColor: "#F1F3F9",
            }}
          >
            {status === "cancelled" ? "Canceled" : status}
          </p>
        );
      } else {
        return (
          <p
            className={
              "billings-viewstatus-responsive billings-viewstatus-responsive-red billings-viewstatus "
            }
            style={{
              color: "#E20000",
              // backgroundColor: "#FFF6F6",
              // border: "1px solid #E20000",
            }}
          >
            {status === "past_due" ? "Past Due" : "Unpaid"}
          </p>
        );
      }
    };

    return (
     
      <>
         <Header openProfileMenus={this.openProfileMenus} showBackArrowOnSubs={this.state.mobileViewLandingPage} />
        <div className="billling-mobile-landing-changes">

        <section className="portal-body ">
          {/* <Accountleftpanel ipadView={"initial-leftpanel-ipadview"} /> */}
          {/* {
            localStorage.getItem("workspaceId") !== "" &&
            localStorage.getItem("workspaceId") !==
            undefined &&
            localStorage.getItem("workspaceId") !==
            "undefined" &&
            localStorage.getItem("workspaceId") !== null ?
            <Accountleftpanel
            value={this}
            ipadView={"initial-leftpanel-ipadview"}
          />
            : */}
          
          <ProfileLeftPanel
            rightpanelbody={this.handleRightPanelExpand}
            profile={this.state.profile}
          />
          {/* } */}
           <BottomScrollListener
          onBottom={this.handleContainerOnBottom}
          offset={300}
        >
          {/* {parseInt(localStorage.getItem("logged_in_user_role_id")) === 4 ||
          parseInt(localStorage.getItem("logged_in_user_role_id")) === 3 ? (
            <Notallowedtouser />
          ) : ( */}
          {(scrollRef) => (
            <div
            style={{
              height: "calc(100vh - 60px)",
              overflowY:(this.state.mobileBillingDropdown || this.state.mobileBillingDropdownTwo)? "hidden" :"auto" ,
            }}
              ref={scrollRef}
              className={`${
                window.matchMedia("(max-width: 1200px)").matches &&
                this.state.mobileViewLandingPage === true
                  ? "right-panel-gap-mobil"
                  : ""
              }  ${
                (localStorage.getItem('sidebaserotate') !== null && localStorage.getItem('sidebaserotate') !== undefined &&
           localStorage.getItem('sidebaserotate') === 'true')  ? localStorage.getItem("workspaceId") !== "" &&
           localStorage.getItem("workspaceId") !==
           undefined &&
           localStorage.getItem("workspaceId") !==
           "undefined" &&
           localStorage.getItem("workspaceId") !== null ? "right-panel-gap right-panel-gap-expand" : "right-panel-gap right-panel-gap-expand"
            : "right-panel-gap"
              }`}
            >
               {  ( window.location.pathname === "/subscriptions" || window.location.pathname === "/invoices"||  window.location.pathname === "/redeem" )
        &&
       <div
       className={(localStorage.getItem('sidebaserotate') !== null && localStorage.getItem('sidebaserotate') !== undefined &&
       localStorage.getItem('sidebaserotate') === 'true')  ? "justify-content-between align-items-center billing-head-redesign right-panel-gap-expand"
        : "justify-content-between align-items-center billing-head-redesign "}>
          <div className="d-flex align-items-center billings-head-flex">
            <h1 className="d-none d-md-block">Billing</h1>
            <p className="billing-tooltip">
              <img
                src={
                  require("../../assets/images/dashboard/Billing/billingexclamation.png")
                    .default
                }
                alt="app-icon"
                className=""
              />
              <p className="billing-tooltipsubheader">
                View all your subscription and purchase activity here.
              </p>
            </p>
          </div>

          {typeof this.state.creditdata !== "string" &&
            window.location.pathname === "/subscriptions" &&
            (parseInt(localStorage.getItem("customer_billing_type")) === 1 ||
            parseInt(localStorage.getItem("customer_billing_type")) === 2 ) ? (
              <div className="billing-wallet-design d-none d-md-flex">
                <div
                  className={
                    (
                      this.state.creditdata.total_credits
                        .toString()
                        .indexOf(",") > -1
                        ? parseFloat(
                            this.state.creditdata.total_credits.replaceAll(
                              ",",
                              ""
                            )
                          ) > 50
                        : parseFloat(this.state.creditdata.total_credits) > 50
                    )
                      ? " billing_wallet  d-md-flex justify-content-between align-items-center"
                      : parseFloat(this.state.creditdata.total_credits) === 0
                      ? " billing_wallet  d-md-flex justify-content-between align-items-center "
                      : " billing_wallet  d-md-flex justify-content-between align-items-center "
                  }
                >
                  <p>
                    Wallet:{" "}
                    <span className="wallet_balence">
                      {this.state.creditdata.currency_symbol}
                      {this.state.creditdata.total_credits < 0
                        ? 0
                        : this.state.creditdata.total_credits.toLocaleString("en-US")}
                    </span>
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}
        {this.state.mainSpinner ? 
          <></>
        :
          <>
            <div className="d-flex  billing_billingsub">
              {(window.location.pathname === "/subscriptions" ||
                window.location.pathname === "/invoices") && 
              (
                <ReactSelect
                  className="billing-react-select-dropdown"
                  classNamePrefix="react-select22"
                  options={this.state.totalmergeArray}
                  onChange={(res) => {
                    this.handleBillingChangeTotal(res);
                  }}   
                  isSearchable={false}
                  formatOptionLabel={(res) => 
                  (
                    <div className="d-flex test">
                      <div className="billing-options">
                        <span className="billing-options-workspace-name">
                        {res.label}
                        </span>
                      <>
                        {res.value == 1 ?
                        <>
                        <p className="billing-options-workspace-type">
                          {(parseInt(localStorage.getItem('personal_apps_count')) + parseInt(localStorage.getItem('invited_apps_count'))) + " Apps"}
                        </p>
                        </>
                        : res.value == 2 ?
                          <>
                          <p className="billing-options-workspace-type">
                          {(parseInt(localStorage.getItem('workspace_count')) + parseInt(localStorage.getItem('invited_workspace_count'))) + " Workspaces"}
                          </p>
                          </>
                        : res.value == 3 ?
                          <>
                          <p className="billing-options-workspace-type">
                          {(parseInt(localStorage.getItem('personal_agency_count')) + parseInt(localStorage.getItem('invited_agency_count'))) + " White-label"}
                          </p>
                          </>
                        : res.value == 4 ?
                        <>
                        <p className="billing-options-workspace-type">
                        Support
                        </p>
                        </>
                        : <></>
                        }
                      </>
                      </div>
                    </div>
                  )}
                  value={this.state.totalmergeArray.filter(function (option) {
                    return (option.value ===parseInt(localStorage.getItem("selectedbilling")));
                  })}
                  isOptionDisabled={(option) => 
                    {   return (option.value === parseInt(localStorage.getItem("selectedbilling")));
                    }}
                />
              )}
            </div>
            <div className="d-flex  billing_billingsub billings-dropdown">
              {(window.location.pathname === "/subscriptions" ||
                window.location.pathname === "/invoices") && (
              <>
                <Dropdown disabled={localStorage.getItem("selectedbilling")==4}  isOpen={this.state.openAppDropdown} toggle={this.handleAppdropdown} style={{ 
                  width:'100%',
                }}
                >
                  <DropdownToggle style={{                 
                  cursor: localStorage.getItem("selectedbilling") == 4 ? 'not-allowed' : 'pointer',
                }} caret>

                  <div className="billings-all-option">
                  <span className={`search-dropdown-before `} style={{ 
                  color: localStorage.getItem("selectedbilling") == 4 ? "#BCC1CE" : '#000',
                }}>{this.state.searchname}</span>
                    {this.state.searchname != 'All' ?
                                    <svg style={{position: "relative", top: "-2px"}} xmlns="http://www.w3.org/2000/svg" width="11.06" height="11.06" viewBox="0 0 11.06 11.06" onClick={(e) => this.newhandleBillingChange('')}>
                                      <path id="Union_2970" data-name="Union 2970" d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                    </svg>
                                  : <svg xmlns="http://www.w3.org/2000/svg" width="11.063" height="6.591" viewBox="0 0 11.063 6.591">
                                  <path id="Path_108406" data-name="Path 108406" d="M-17299.426-4409.885l5,5,5-5" transform="translate(17299.957 4410.416)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                </svg>
                                
                                  }
                    </div>
                    
                  </DropdownToggle>
                  <DropdownMenu>
                    {this.state.typedata && this.state.typedata.length === 0 && 
                    (
                      <div className="no-app-dropdown">
                        <img className="img-fluid" src={require("../../assets/images/dashboard/no-apps-created.png").default} alt="No App Found"/>
                      </div>
                    )}
                    {this.state.typedata && this.state.typedata.length > 0 && 
                    (
                      <>
                      <FormGroup>
                        <div className="dropdown-search">
                          <input placeholder="Search..." type="text" value={this.state.searchAppVal} onChange={(e) => {this.setState({searchAppVal: e.target.value});}}
                          ></input>
                          {this.state.searchAppVal.trim() && (
                            <p onClick={(e) => {this.setState({searchAppVal: ""})}}>
                              Clear
                            </p>
                          )}
                        </div>
                      </FormGroup>
                      {/* <DropdownItem onClick={(e) => this.newhandleBillingChange('')}>All</DropdownItem> */}
                      </>
                    )}
                  
                    {this.state.typedata && this.state.typedata.map((res) => 
                      {
                        if(this.state.searchAppVal == '' || 
                            (parseInt(localStorage.getItem("selectedbilling")) == 1 && res.app_name.toLowerCase().includes(this.state.searchAppVal.toLowerCase())) ||
                            (parseInt(localStorage.getItem("selectedbilling")) == 2 && res.workspace_name.toLowerCase().includes(this.state.searchAppVal.toLowerCase())) ||
                            (parseInt(localStorage.getItem("selectedbilling")) == 3 && res.agency_name.toLowerCase().includes(this.state.searchAppVal.toLowerCase())) 
                          )
                        {
                          return(
                                  <DropdownItem onClick={(e) => this.newhandleBillingChange(res)}>
                                    <div className="billing-new-dropdown">
                                      <>
                                          {this.state.typedata.length > 0 && parseInt(localStorage.getItem("selectedbilling")) == 1 ? 
                                            <>
                                              {this.state.searchAppVal == '' || res.app_name.toLowerCase().includes(this.state.searchAppVal.toLowerCase()) ?
                                                <>
                                                  <div>
                                                  {res.app_icon === "" ? 
                                                    (
                                                      <img
                                                        style={{
                                                          width: "40px",
                                                          height: "40px",
                                                          backgroundRepeat: "no-repeat",
                                                          backgroundSize: "cover",
                                                          marginRight: "10px",
                                                        }}
                                                        src={require("../../assets/images/dashboard/my-account.png").default}
                                                        className="img-fluid"
                                                        alt="profile logo"
                                                      />
                                                    ) 
                                                  : 
                                                    (
                                                        <>
                                                            <img
                                                              className="headers-profil-img"
                                                              alt=""
                                                              style={{
                                                                width: "40px",
                                                                borderRadius: "14px",
                                                                height: "40px",
                                                                backgroundSize: "cover",
                                                                marginRight: "10px",
                                                              }}
                                                              src={
                                                                process.env.REACT_APP_Image_Path +
                                                                res.user_id +
                                                                "/" +
                                                                res.app_id +
                                                                process.env.REACT_APP_AppIcon_Display +
                                                                res.app_icon
                                                              }
                                                              onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null;
                                                                currentTarget.src =
                                                                  res.type==="user"
                                                                  ? userdefaultImgOnError :wpdefaultImgOnError
                                                              }}
                                                            />
                                                        </> 
                                                    )
                                                  }
                                                  </div>
                                                    <div className="billing-options">
                                                      <h5>
                                                      {res.app_name}
                                                      </h5>
                                                      <h6>
                                                        {"Pay-per-app"}
                                                      </h6>
                                                    </div>
                                                </>
                                              : <></>
                                              }
                                            </>
                                          : this.state.typedata.length > 0 &&  parseInt(localStorage.getItem("selectedbilling")) == 2 ? 
                                              <>
                                                {this.state.searchAppVal == '' || res.workspace_name.toLowerCase().includes(this.state.searchAppVal.toLowerCase()) ?
                                                <>
                                                    <div>
                                                    <>
                                                      {res.profile_image === "" ||
                                                      res.profile_image === null ||
                                                      res.profile_image === "null" ||
                                                      res.profile_image === undefined ? 
                                                      (
                                                        <div className="biiling-iconsize">
                                                          <img src={require("../../assets/images/dashboard/sub-overview-img.png").default} className=""/>
                                                        </div>
                                                      ) 
                                                      : 
                                                      (
                                                        <div className="biiling-iconsize">
                                                        <img src={process.env.REACT_APP_Image_Path + res.user_id +
                                                                  process.env.REACT_APP_Workspace_Profile + res.id +
                                                                  "/" + res.profile_image
                                                                }
                                                              onError={({currentTarget,}) => {
                                                              currentTarget.onerror = null; currentTarget.src =newDefaultImg;
                                                              }}
                                                          className=""
                                                          alt="workspace"
                                                        />
                                                        </div>
                                                      )}
                                                    </>
                                                    </div>
                                                    <div className="billing-options">
                                                      <h5>
                                                      {res.workspace_name}
                                                      </h5>
                                                      <h6>
                                                        {"Unlimited-apps"}
                                                      </h6>
                                                    </div>
                                                </>
                                                : <></>
                                                }
                                              </>
                                          : this.state.typedata.length > 0 && parseInt(localStorage.getItem("selectedbilling")) == 3 ? 
                                            <>
                                              {this.state.searchAppVal == '' || res.agency_name.toLowerCase().includes(this.state.searchAppVal.toLowerCase()) ?
                                                <>
                                                    <div>
                                                    <>
                                                      {res.favicon === "" ||
                                                      res.favicon === null ||
                                                      res.favicon === "null" ||
                                                      res.favicon === undefined ? 
                                                      (
                                                        <div className="biiling-iconsize">
                                                          <img src={require("../../assets/images/dashboard/sub-overview-img.png").default} className=""/>
                                                        </div>
                                                      ) 
                                                      : 
                                                      (
                                                        <div className="biiling-iconsize">
                                                        <img src={process.env.REACT_APP_Image_Path + "agencies/" + res.id + process.env.REACT_APP_Agency_Profile + res.favicon}
                                                              onError={({currentTarget,}) => {
                                                              currentTarget.onerror = null; currentTarget.src =newDefaultImg;
                                                              }}
                                                          className=""
                                                        />
                                                        </div>
                                                      )}
                                                    </>
                                                    </div>
                                                      <div className="billing-options">
                                                        <h5>
                                                        {res.agency_name}
                                                        </h5>
                                                        <h6>
                                                          {"White-label"}
                                                        </h6>
                                                      </div>
                                                </>
                                              : <></>
                                              }
                                            </>
                                          : <></>
                                          }
                                      </>
                                    </div>
                                  </DropdownItem>
                                );
                        }
                        
                    })}
                  </DropdownMenu>
                </Dropdown>
              </>
              )}
            </div>
          </>
        }
        </div>

                            }
              <Billingheader value={this} />
              
              

              <div className="row">
                <div className="col-md-12 billing-page-padding-r">
                  <div className="billing_social_pannel">
                    <div className=" billings_bill-pay-version">
                     
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  window.matchMedia("(max-width: 1200px)").matches &&
                  this.state.mobileViewLandingPage === true
                    ? "billing_right_panel-mobil"
                    : ""
                } billing_right_panel min-height-page`}
              >
                <div className="right_general">
                  {this.state.mainSpinner ? (
                    <div className="billingnewspinner" >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xlink="http://www.w3.org/1999/xlink"
                        style={{
                          margin: "auto",
                          background: "rgb(241, 242, 243,0)",
                          display: "block",
                          shaperendering: "auto",
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                        width="200px"
                        height="200px"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="xMidYMid"
                      >
                        <g
                          transform="translate(20 50)"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <circle
                            cx="0"
                            cy="0"
                            r="6"
                            fill="#e6261f"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <animateTransform
                              attributeName="transform"
                              type="scale"
                              begin="-0.375s"
                              calcMode="spline"
                              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                              values="0;1;0"
                              keyTimes="0;0.5;1"
                              dur="1s"
                              repeatCount="indefinite"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            ></animateTransform>
                          </circle>
                        </g>
                        <g
                          transform="translate(40 50)"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <circle
                            cx="0"
                            cy="0"
                            r="6"
                            fill="#f7d038"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <animateTransform
                              attributeName="transform"
                              type="scale"
                              begin="-0.25s"
                              calcMode="spline"
                              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                              values="0;1;0"
                              keyTimes="0;0.5;1"
                              dur="1s"
                              repeatCount="indefinite"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            ></animateTransform>
                          </circle>
                        </g>
                        <g
                          transform="translate(60 50)"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <circle
                            cx="0"
                            cy="0"
                            r="6"
                            fill="#49da9a"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <animateTransform
                              attributeName="transform"
                              type="scale"
                              begin="-0.125s"
                              calcMode="spline"
                              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                              values="0;1;0"
                              keyTimes="0;0.5;1"
                              dur="1s"
                              repeatCount="indefinite"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            ></animateTransform>
                          </circle>
                        </g>
                        <g
                          transform="translate(80 50)"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <circle
                            cx="0"
                            cy="0"
                            r="6"
                            fill="#4355db"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <animateTransform
                              attributeName="transform"
                              type="scale"
                              begin="0s"
                              calcMode="spline"
                              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                              values="0;1;0"
                              keyTimes="0;0.5;1"
                              dur="1s"
                              repeatCount="indefinite"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            ></animateTransform>
                          </circle>
                        </g>
                      </svg>
                    </div>
                  ) : (
                    <>
                     
                      {/* Billing Dropdown End */}
                      <div className="row">
                        <div className="col-md-12 billing-small-web-table">
                          {window.matchMedia("(min-width: 1201px)").matches && (
                            <section className="billings_subscriptions  my-profile-subscription subscriptions-over-new">
                              {this.state.billingdata.length === 0 ? (
                                <></>
                              ) : (
                                <div className="d-flex align-items-center justify-content-between billing_table-top">
                                  <div className="subscriptions-type">
                                    <p>Applies to</p>
                                  </div>
                                  <div className="product">
                                    <p>Product</p>
                                  </div>
                                  <div className="next-payments">
                                    <p className="next-payments-head">
                                      Renewal{" "}
                                    </p>
                                  </div>
                                  {/* {this.state.customer_billing_type === 2 ? ( */}
                                  <div className="payment-method">
                                    <p>Payment method</p>
                                  </div>
                                  {/* ) : null} */}

                                  <div className="payment-status bill-payment-status bill-payment-status">
                                    <p>Status</p>
                                  </div>
                                  <div className="action">
                                    <p>Action </p>
                                  </div>
                                </div>
                              )}
                              {this.state.billingdata.length === 0 ? (
                                <>
                                  <div className="text-center billing_nodataimg ">
                                    <img
                                      src={
                                        require("../../assets/images/dashboard/NoInvoice.png")
                                          .default
                                      }
                                      className="img-fluid noInvoice"
                                      alt="nosubscription"
                                    />
                                  </div>
                                  <div className="billing_nodatatext">
                                    {/* <h5 className="text-center">No deals are available now</h5> */}

                                    <span className=" text-center span1">
                                      Nothing here at the moment. Come back
                                      later?
                                    </span>
                                  </div>
                                  
                                </>
                              ) : (
                                this.state.billingdata.filter((row) =>
                                    ( parseInt(localStorage.getItem("selectedbilling")) == 2
                                    ? row.workspace_name
                                    : parseInt(localStorage.getItem("selectedbilling")) == 4
                                    ? row.plan_name && row.plan_name != '' ? row.plan_name.toLowerCase().includes(this.state.subsSeacrch != '' ? this.state.subsSeacrch : '') :''
                                    : parseInt(localStorage.getItem("selectedbilling")) == 3
                                    ? row.agency_name && row.agency_name != '' ? row.agency_name.toLowerCase().includes(this.state.subsSeacrch != '' ? this.state.subsSeacrch : '') :''
                                    : row.app_name && row.app_name != '' ? row.app_name.toLowerCase().includes(this.state.subsSeacrch != '' ? this.state.subsSeacrch : '') : ''
                                    )
                                      
                                  )
                                  .map((res, i) => (
                                    <>
                                      <div
                                        className="d-flex align-items-center justify-content-between common-height common-height-over"
                                        key={i}
                                      >
                                        <div className="billing_subscriptions-type subscriptions-type d-flex align-items-center">
                                          {
                                           parseInt(localStorage.getItem("selectedbilling")) == 2 ? 
                                          (
                                            <>
                                              {res.profile_image === "" ||
                                              res.profile_image === null ||
                                              res.profile_image === "null" ||
                                              res.profile_image === undefined ? 
                                              (
                                                <div className="biiling-iconsize">
                                                  <img src={require("../../assets/images/dashboard/sub-overview-img.png").default} className=""/>
                                                </div>
                                              ) 
                                              : 
                                              (
                                                <div className="biiling-iconsize">
                                                <img src={process.env.REACT_APP_Image_Path + res.user_id +
                                                          process.env.REACT_APP_Workspace_Profile + res.workspace_id +
                                                          "/" + res.profile_image
                                                        }
                                                      onError={({currentTarget,}) => {
                                                      currentTarget.onerror = null; currentTarget.src =newDefaultImg;
                                                      }}
                                                  className=""
                                                />
                                                </div>
                                              )}
                                            </>
                                          )
                                          :  parseInt(localStorage.getItem("selectedbilling")) == 3 ? 
                                            (
                                              <>
                                                {res.agency_favicon === "" ||
                                                res.agency_favicon === null ||
                                                res.agency_favicon === "null" ||
                                                res.agency_favicon === undefined ? 
                                                (
                                                  <div className="biiling-iconsize">
                                                    <img src={require("../../assets/images/dashboard/sub-overview-img.png").default} className=""/>
                                                  </div>
                                                ) 
                                                : 
                                                (
                                                  <div className="biiling-iconsize">
                                                  <img src={process.env.REACT_APP_Image_Path + "agencies/" + res.agency_id + process.env.REACT_APP_Agency_Profile + res.agency_favicon}
                                                        onError={({currentTarget,}) => {
                                                        currentTarget.onerror = null; currentTarget.src =newDefaultImg;
                                                        }}
                                                    className=""
                                                  />
                                                  </div>
                                                )}
                                              </>
                                            ) 
                                            :  parseInt(localStorage.getItem("selectedbilling")) == 4 ? 
                                            (
                                              <>
                                              {res.profile_image === "" ||
                                              res.profile_image === null ||
                                              res.profile_image === "null" ||
                                              res.profile_image === undefined ? 
                                              (
                                                <div className="biiling-iconsize">
                                                  <img src={require("../../assets/images/dashboard/sub-overview-img.png").default} className=""/>
                                                </div>
                                              ) 
                                              : 
                                             
                                                <div className="biiling-iconsize">
                                                <img 
                                                src={
                                                  (res.profile_image.includes("http")?
                                                  (res.profile_image)
                                                  :
                                                  (process.env.REACT_APP_Image_Path + res.user_id +
                                                     process.env.REACT_APP_Profile + res.profile_image)
                                                  )
                                                    }
                                                      onError={({currentTarget,}) => {
                                                      currentTarget.onerror = null; currentTarget.src = newDefaultImg;
                                                      }}
                                                  className=""
                                                />
                                                </div>
                                              }
                                            </>
                                            ) 
                                          : (
                                            <div className="biiling-iconsize">
                                              <img
                                                src={
                                                  process.env
                                                    .REACT_APP_Image_Path +
                                                  res.user_id +
                                                  "/" +
                                                  res.app_id +
                                                  process.env
                                                    .REACT_APP_AppIcon_Display +
                                                  res.app_icon
                                                }
                                                alt="app-icon"
                                                className=" img-fluid  billing_app-icon"
                                                width="60px"
                                                height="60px"
                                              />
                                            </div>
                                          )}
                                          <div className="billinghp">
                                            <h4 className="">
                                              {
                                               parseInt(localStorage.getItem("selectedbilling")) == 2
                                              ? res.workspace_name
                                              :  parseInt(localStorage.getItem("selectedbilling")) == 3
                                              ? res.agency_name
                                              :  parseInt(localStorage.getItem("selectedbilling")) == 4
                                              ? res.name
                                              : res.app_name}
                                            </h4>
                                            {res.gateway_subscription_id && res.gateway_subscription_id && res.gateway_subscription_id != '' ? 
                                            
                                              <p>{'ID:' + res.gateway_subscription_id}
                                              </p>
                                            :
                                              <></>
                                            }
                                          </div>
                                        </div>
                                        <div className="product billing_product">
                                          <p className="product-plan-over billing-product-plan first-letter-test" 
                                         >{capitalize(res.plan_name ? res.plan_name.toLowerCase() : '')}
                                            {/* {res.plan_name.toLowerCase()} */}
                                            {
                                             (res.type===2 && res.addon_array.addon_identifier == 'USER_MANAGEMENT') ||
                                             (res.type===2 && res.addon_array.addon_identifier == 'DEPRECATED_USER_MANAGEMENT') 
                                             ? ` 
                                            ( ${res.addon_array.addon_quantity} )` 
                                            :""
                                            } 
                                          </p>
                                          <p>
                                          {res.plan_code != 'paddle_preview' && res.plan_code != 'agency_preview' ? 'ID:'+res.plan_code : ''}
                                          </p>
                                         
                                        </div>
                                        {/* lifetime desktop */}
                                        <div className="next-payments">
                                          {res.plan_code.indexOf(
                                            process.env.REACT_APP_Lifetime_plan
                                          ) !== -1 ||
                                          res.package_duration_id === 4 ? (
                                            <>
                                              <h4>Lifetime</h4>
                                              <h5 className="billing-expiry-date">
                                                Never Expires
                                              </h5>
                                            </>
                                          ) : // res.total === "0.00" &&
                                          res.reseller_package_id !== null &&
                                            res.reseller_package_expiry_date !==
                                              null ? (
                                            <> 
                                              <h4>
                                            -
                                                
                                              </h4>
                                              <p>
                                                {res.package_duration_id != 0
                                                  ? moment(
                                                      res.reseller_package_expiry_date
                                                    ).format("D MMMM YYYY, HH:mm")
                                                  : null}
                                              </p>
                                            </>
                                          ) : (
                                            <>
                                              <h4>
                                                {res.currency == 'USD' ? '$' : res.currency}{res.total}
                                                <span className="billings-frequency">
                                                  /
                                                  {res.package_duration_id === 1
                                                    ? "month"
                                                    : "year"}
                                                </span>
                                              </h4>
                                              {res.downgrade_at_term_end===0?
                                                        <p>
                                                          {res.package_duration_id != 0 ? moment(res.billing_date).format("D MMMM YYYY, HH:mm") : null}
                                                        </p>
                                              : res.downgrade_request_timestamp ?
                                              <>
                                                <p style={{color:"#FF4848"}}>Cancellation{" "} 
                                                      {res.package_duration_id != 0
                                                      ? moment(res.billing_date).format("D MMMM YYYY")
                                                      : null}
                                                </p>
                                                <p style={{marginTop:"0px"}}>Requested{" "} 
                                                {res.package_duration_id != 0
                                                ? moment(res.downgrade_request_timestamp).format("D MMMM YYYY, HH:mm")
                                                : null}
                                                </p>
                                              </>
                                              :
                                                <p style={{color:"#FF4848"}}>Downgrade scheduled</p>
                                              }

                                              {parseInt(localStorage.getItem("selectedbilling")) != 3 && res && res.package_duration_id != '' && res.package_duration_id == 1 ?
                                                <>
                                                {parseInt(localStorage.getItem("selectedbilling")) == 1 && parseInt(localStorage.getItem("selectedbilling")) != 4 ?
                                                  <div className="billing-yearly-saving">
                                                      {res.localstorageData && res.localstorageData.subscription && res.localstorageData.subscription.subscriptionclient && parseInt(res.localstorageData.subscription.subscriptionclient.website_technology) == 5 ?
                                                        <>
                                                          {res.plan == 'starter' ?
                                                            <>
                                                              <h5>Save up to 34% on annual plan.</h5>
                                                            </>
                                                          :res.plan == 'pro' ?
                                                            <>
                                                            <h5>Save up to 20% on annual plan.</h5>
                                                            </>
                                                          :res.plan == 'premium' ?
                                                            <>
                                                                <h5>Save up to 20% on annual plan.</h5>
                                                            </>
                                                          :
                                                            <></>
                                                          }
                                                        </>
                                                        
                                                      :
                                                        <>
                                                          {res.plan == 'starter' ?
                                                            <>
                                                              <h5>Save up to 53% on annual plan.</h5>
                                                            </>
                                                          :res.plan == 'pro' ?
                                                            <>
                                                            <h5>Save up to 34% on annual plan.</h5>
                                                            </>
                                                          :res.plan == 'premium' ?
                                                            <>
                                                                <h5>Save up to 20% on annual plan.</h5>
                                                            </>
                                                          :
                                                            <></>
                                                          }
                                                        </>
                                                      }
                                                    
                                                  </div>
                                                : parseInt(localStorage.getItem("selectedbilling")) != 4 ?
                                                  <div className="billing-yearly-saving">
                                                    <h5>Save up to 20% on annual plan.</h5>
                                                      {/* {res.website_technology && parseInt(res.website_technology) == 5 ?
                                                        <>
                                                        {res.plan == 'starter' ?
                                                          <>
                                                            <h5>Save up to 34% on annual plan.</h5>
                                                          </>
                                                        :res.plan == 'pro' ?
                                                          <>
                                                          <h5>Save up to 20% on annual plan.</h5>
                                                          </>
                                                        :res.plan == 'premium' ?
                                                          <>
                                                              <h5>Save up to 20% on annual plan.</h5>
                                                          </>
                                                        :
                                                          <></>
                                                        }
                                                       </>
                                                      :res.website_technology && parseInt(res.website_technology) == 1 ?
                                                          <>
                                                          {res.plan == 'starter' ?
                                                            <>
                                                              <h5>Save up to 20% on annual plan.</h5>
                                                            </>
                                                          :res.plan == 'pro' ?
                                                            <>
                                                            <h5>Save up to 14% on annual plan.</h5>
                                                            </>
                                                          :res.plan == 'premium' ?
                                                            <>
                                                                <h5>Save up to 17% on annual plan.</h5>
                                                            </>
                                                          :
                                                            <></>
                                                          }
                                                        </>
                                                      :
                                                        <>
                                                            {res.plan == 'starter' ?
                                                              <>
                                                                <h5>Save up to 20% on annual plan.</h5>
                                                              </>
                                                            :res.plan == 'pro' ?
                                                              <>
                                                              <h5>Save up to 25% on annual plan.</h5>
                                                              </>
                                                            :res.plan == 'premium' ?
                                                              <>
                                                                  <h5>Save up to 17% on annual plan.</h5>
                                                              </>
                                                            :
                                                              <></>
                                                            }
                                                          </>
                                                      } */}
                                                    
                                                  </div>
                                                : <></>
                                                }
                                                </>
                                              : <></>
                                              }
                                            
                                              {/* <p>{new Date(res.billing_date).toLocaleString()}</p> */}
                                            </>
                                          )}
                                        </div>

                                        {/* {this.state.customer_billing_type === 2 ? ( */}
                                        <div className="payment-method">
                                          <p className="workspace-card-red">
                                            { 
                                              ( this.state.customer_billing_type === 2 && (parseInt(localStorage.getItem("selectedbilling")) != 3)) ||
                                              (this.state.customer_billing_type === 1 && parseInt(localStorage.getItem("selectedbilling")) == 2) &&
                                              (this.state.customer_billing_type != 3 && (parseInt(localStorage.getItem("selectedbilling")) != 3))
                                            ? 
                                            (
                                              res.payment_method_info.card_last4_digit !== undefined ? (
                                                <>
                                                  {res.downgrade_at_term_end === 1 ? 
                                                  (
                                                    <>
                                                      <p className="card-name-init">
                                                        xxxx xxxx xxxx{" "}
                                                        {res.payment_method_info.card_last4_digit + "  "}
                                                      </p>
                                                    </>
                                                  ) 
                                                  : 
                                                  (
                                                    <>
                                                      <p className="card-name-init">
                                                        xxxx xxxx xxxx{" "}
                                                        {res.payment_method_info.card_last4_digit +"  "}

                                                        { (res.localstorageData && res.localstorageData.subscription && res.localstorageData.subscription.is_new_deal===1 &&
                                                         res.localstorageData.subscription.package_info.is_deal_plan===1 )
                                                          ? <></>
                                                        :
                                                        <p className="billing_card-update" onClick={(e) =>
                                                          this.isCardPopUp(
                                                            e,
                                                            res.app_id,
                                                            res
                                                              .payment_method_info
                                                              .id
                                                          )
                                                        }>
                                                          Update
                                                          
                                                        </p>
                                                       }
                                                      </p>
                                                    </>
                                                  )}
                                                </>
                                              ) : 
                                              (
                                                <div className="d-grid">
                                                  
                                                  <div>
                                                  { (res.localstorageData && res.localstorageData.subscription && res.localstorageData.subscription.is_new_deal===1 &&
                                                     res.localstorageData.subscription.package_info.is_deal_plan===1 )

                                                          ?<p className="billing_card-update">
                                                            -
                                                            
                                                          </p>
                                                          :
                                                    <Link
                                                      className=" edit-btn-card billing_card-update"
                                                      onClick={(e) =>
                                                        this.isCardPopUp(
                                                          e,
                                                          res.app_id,
                                                          res
                                                            .payment_method_info
                                                            .id
                                                        )
                                                      }
                                                    >
                                                      Update
                                                    </Link>
                                                      }
                                                  </div>
                                                </div>
                                              )
                                            ) 
                                            :  res.status === "paused" || res.status === "cancelled" || res.status === "canceled" || res.status === "deleted" 
                                            ? <div class="payment-method"><p class="workspace-card-red"><span style={{textAlign: "center"}}>-</span></p></div>
                                            : this.state.customer_billing_type === 3 || ( parseInt(localStorage.getItem("selectedbilling")) == 3 ) ? 
                                            (
                                              <>
                                                {res.downgrade_at_term_end === 1 ? 
                                                (
                                                  <div>
                                                    {res.update_pm_link ? (
                                                      <>
                                                        xxxx xxxx xxxx xxxx{" "}
                                                        {"   "}
                                                      </>
                                                    ) : (
                                                      <span
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        -
                                                      </span>
                                                    )}
                                                  </div>
                                                ) 
                                                : 
                                                (
                                                  <>
                                                    {res.update_pm_link ? (
                                                      <div>
                                                        xxxx xxxx xxxx xxxx{" "}
                                                        {"   "}
                                                        <div>
                                                        { (res.localstorageData && res.localstorageData.subscription && res.localstorageData.subscription.is_new_deal===1 &&
                                                         res.localstorageData.subscription.package_info.is_deal_plan===1 )

                                                          ?<></>
                                                          :
                                                          <a
                                                            className="edit-btn edit-btn-card billing_card-update"
                                                            target="_blank"
                                                            href={
                                                              res.update_pm_link
                                                            }
                                                          >
                                                            Update
                                                            <img
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              src={
                                                                require("../../assets/images/dashboard/Goto.png")
                                                                  .default
                                                              }
                                                            />
                                                          </a>
                                                        }
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      <span
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        -
                                                      </span>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            ) 
                                            : 
                                            (
                                              <p>
                                                xxxx xxxx xxxx xxxx {"   "}
                                                { (res.localstorageData && res.localstorageData.subscription && res.localstorageData.subscription.is_new_deal===1 &&
                                                         res.localstorageData.subscription.package_info.is_deal_plan===1 )

                                                          ?<></>
                                                          :
                                                <p
                                                  className="billing_card-update"
                                                  onClick={(e) =>
                                                    this.changePaymentMthd(
                                                      res.app_id,
                                                      i
                                                    )
                                                  }
                                                >
                                                  Update
                                                  <img
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    src={
                                                      this.state.isImage ===
                                                      "true" + i
                                                        ? this.state.zohoLoader
                                                        : require("../../assets/images/dashboard/Goto.png")
                                                            .default
                                                    }
                                                    className={
                                                      this.state.isImage ===
                                                      "true" + i
                                                        ? "zoho-loader-img"
                                                        : "edit-btn"
                                                    }
                                                  />
                                                </p>
                                               }
                                              </p>
                                            )}
                                          </p>
                                        </div>
                                        {/* ) : null} */}

                                        <div className="payment-status payment-status-responsive bill-payment-status">
                                          {
                                            returnStatus(res.status)
                                          }
                                        </div>
                                        <div className="action action-red billing-action-red">
                                          {
                                            res.status === "paused" || res.status === "cancelled" || res.status === "canceled" || res.status === "deleted" 
                                            ? <div class="payment-method"><p class="workspace-card-red"><span style={{textAlign: "center"}}>-</span></p></div>
                                            : parseInt(localStorage.getItem("selectedbilling")) == 4 ?
                                              <Link to="/support-pricing"> {" "}
                                              Upgrade{" "}</Link>
                                            : res.type===2 ? 
                                            parseInt(localStorage.getItem("selectedbilling")) === 3 ?
                                            <Link to={`/agency/addon/${btoa(res.agency_id)}`}> {" "}
                                            Edit{" "}</Link>
                                            :
                                              <Link
                                                onClick={(e) =>
                                                  this.handleEditforAddon(
                                                    e,
                                                    res.user_id,
                                                    res.localstorageData
                                                      .subscription.status,
                                                    res.app_name,
                                                    parseInt(localStorage.getItem("selectedbilling")) == 2 ? res.workspace_id
                                                    : parseInt(localStorage.getItem("selectedbilling")) == 3 ? res.agency_id_id
                                                    : res.app_id,
                                                    res.localstorageData
                                                      .version,
                                                    this.setname(
                                                      res.app_name
                                                    ),
                                                    res.localstorageData
                                                      .subscription,
                                                    res.store_url,
                                                    res.localstorageData
                                                      .plugin_keys,
                                                    res.localstorageData
                                                      .menu_fill,
                                                    res.localstorageData
                                                      .dashboard_count,
                                                    res.localstorageData
                                                      .website_type,
                                                    res.localstorageData
                                                      .is_verified,
                                                    res.localstorageData
                                                      .appicon_count,
                                                    res.localstorageData
                                                      .splash_count,
                                                    res.localstorageData
                                                      .login_reg_count,
                                                    res.localstorageData
                                                      .style_count,
                                                    res.plan_name,
                                                    res.addon_array,
                                                    res,
                                                    "newAddon"
                                                  )
                                                }
                                              
                                              >
                                                Edit{" "}
                                              </Link> 
                                          :
                                          
                                          <>
                                          
                                          {   

                                          res.workspace_id != "" &&
                                          res.workspace_id != null &&
                                          res.workspace_id != undefined ? 
                                          (
                                            <>
                                              <Link
                                                to="#"
                                                onClick={(e) =>
                                                  this.moveworkspacepricing(
                                                    btoa(res.workspace_id)
                                                  )
                                                }
                                              >
                                                {" "}
                                                Upgrade{" "}
                                              </Link>
                                            </>
                                          )
                                          : res.agency_id != "" &&
                                            res.agency_id != null &&
                                            res.agency_id != undefined ? 
                                          (
                                              <>
                                              {
                                                res.billing_type !== 0 ?
                                              
                                              <Link
                                                to={`/agency/pricing/`+btoa(res.agency_id)}
                                              >
                                                {" "}
                                                Upgrade{" "}
                                              </Link>
                                            : "-"  
                                            }
                                            </>
                                          ) 
                                          : (
                                            <>
                                              <Link
                                                to="#"
                                                onClick={(e) =>
                                                  this.movetoapppricing(
                                                    res.app_id,
                                                    res.localstorageData
                                                      .subscription
                                                      .subscriptionclient
                                                      .website_technology
                                                  )
                                                }
                                              >
                                                {" "}
                                                Upgrade{" "}
                                              </Link>
                                            </>
                                          )}
                                          </>}
                                        </div> 
                                      </div>
                                      {/* Addons Tiles */}

                                      {this.state.billingdata[i].addon_data &&
                                        this.state.billingdata[i].addon_data
                                          .length > 0 &&
                                        this.state.billingdata[
                                          i
                                        ].addon_data.map((addon) => {
                                          return (
                                            <div
                                              className="d-flex align-items-center justify-content-between common-height common-height-over"
                                              key={i}
                                            >
                                              <div className="billing_subscriptions-type subscriptions-type d-flex align-items-center">
                                                <div className="biiling-iconsize">
                                                  <img
                                                    src={
                                                      process.env
                                                        .REACT_APP_Image_Path +
                                                      res.user_id +
                                                      "/" +
                                                      res.app_id +
                                                      process.env
                                                        .REACT_APP_AppIcon_Display +
                                                      res.app_icon
                                                    }
                                                    alt="app-icon"
                                                    className=" img-fluid billing_app-icon"
                                                    width="60px"
                                                    height="60px"
                                                  />
                                                </div>
                                                <div className="billinghp">
                                                  <h4>{res.app_name}</h4>
                                                  {/* <p>{addon.addon_name}</p> */}
                                                  <p>
                                                    {res?.store_url?.replace(
                                                      "https://",
                                                      ""
                                                    )}
                                                  </p>
                                                  {/* <p>
                                                  {res.plan_name === ""
                                                    ? res.plan_code.replace(
                                                        "_",
                                                        " "
                                                      )
                                                    : res.plan_name}{" "}
                                                </p> */}
                                                </div>
                                              </div>
                                              <div className="product billing_product">
                                                <p className="billing-product-plan">
                                                  {addon.addon_name}
                                                </p>
                                                <p>
                                                  Addon ( {addon.addon_quantity > 1 ? addon.addon_quantity : 1}{" "}
                                                  )
                                                </p>
                                              </div>
                                              <div className="next-payments">
                                                <h4>
                                                  {addon.package_currency}{addon.addon_amount}
                                                  <span className="billings-frequency">
                                                    /
                                                    {addon.package_duration ===
                                                    1
                                                      ? "month"
                                                      : "year"}
                                                  </span>
                                                </h4>
                                                <p>
                                                  {moment(
                                                    res.billing_date
                                                  ).format("D MMMM YYYY, HH:mm")}
                                                </p>
                                              </div>

                                              {/* {this.state
                                              .customer_billing_type === 2 ? ( */}
                                              <div className="payment-method">
                                                <p>-</p>
                                                {/* <p>
                                                {this.state
                                                  .customer_billing_type ===
                                                  2 ||
                                                (this.state
                                                  .customer_billing_type ===
                                                  1 &&
                                                  localStorage.getItem(
                                                    "workspaceId"
                                                  ) !== "" &&
                                                  localStorage.getItem(
                                                    "workspaceId"
                                                  ) !== undefined &&
                                                  localStorage.getItem(
                                                    "workspaceId"
                                                  ) !== null) ? (
                                                  res.payment_method_info
                                                    .card_last4_digit !==
                                                  undefined ? (
                                                    <>
                                                      <p>
                                                        xxxx xxxx{" "}
                                                        {res.payment_method_info
                                                          .card_last4_digit +
                                                          "  "}
                                                        <img
                                                          src={
                                                            require("../../assets/images/dashboard/edit-icon.png")
                                                              .default
                                                          }
                                                          className="edit-btn"
                                                          onClick={(e) =>
                                                            this.isCardPopUp(
                                                              e,
                                                              res.app_id,
                                                              res
                                                                .payment_method_info
                                                                .id
                                                            )
                                                          }
                                                        />
                                                      </p>
                                                    </>
                                                  ) : (
                                                    "--"
                                                  )
                                                ) : (
                                                  <p>
                                                    xxxx xxxx xxxx {"   "}
                                                    <img
                                                      src={
                                                        this.state.isImage ===
                                                        true
                                                          ? this.state
                                                              .cardLoaderImage
                                                          : require("../../assets/images/dashboard/edit-icon.png")
                                                              .default
                                                      }
                                                      className="edit-btn"
                                                      onClick={(e) =>
                                                        this.changePaymentMthd(
                                                          res.app_id,
                                                          i
                                                        )
                                                      }
                                                    />
                                                  </p>
                                                )}
                                              </p> */}
                                              </div>
                                              {/* ) : null} */}

                                              <div className="payment-status payment-status-responsive bill-payment-status">
                                                {returnStatus(res.status)}
                                              </div>
                                              <div className="action action-red billing-action-red">
                                                <Link
                                                  onClick={(e) =>
                                                    this.handleEditforAddon(
                                                      e,
                                                      res.user_id,
                                                      res.localstorageData
                                                        .subscription.status,
                                                      res.app_name,

                                                      localStorage.getItem(
                                                        "workspaceId"
                                                      ) !== ""
                                                        ? res.workspace_id
                                                        : res.app_id,
                                                      res.localstorageData
                                                        .version,
                                                      this.setname(
                                                        res.app_name
                                                      ),
                                                      res.localstorageData
                                                        .subscription,
                                                      res.store_url,
                                                      res.localstorageData
                                                        .plugin_keys,
                                                      res.localstorageData
                                                        .menu_fill,
                                                      res.localstorageData
                                                        .dashboard_count,
                                                      res.localstorageData
                                                        .website_type,
                                                      res.localstorageData
                                                        .is_verified,
                                                      res.localstorageData
                                                        .appicon_count,
                                                      res.localstorageData
                                                        .splash_count,
                                                      res.localstorageData
                                                        .login_reg_count,
                                                      res.localstorageData
                                                        .style_count,
                                                      res.plan_name,
                                                      addon,
                                                      res,
                                                      "",
                                                    )
                                                  }
                                                  // to={`/app/addons/${addon.addon_name
                                                  //   .replaceAll(" ", "_")
                                                  //   .toLowerCase()}/${btoa(
                                                  //   addon.addon_code
                                                  // )}/${btoa(res.app_id)}/${btoa(
                                                  //   res.plan_code
                                                  // )}/${btoa(
                                                  //   addon.addon_identifier
                                                  // )}`}
                                                >
                                                  Edit{" "}
                                                </Link>
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </>
                                  ))
                              )}
                            </section>
                          )}

                          {/* Mobile View */}
                          {window.matchMedia("(max-width: 1200px)").matches &&
                            this.state.mobileViewLandingPage === false && (
                              <section className="d-block   billings-mob-subscription mt-3">
                                <div className="subscription-mob-page-head">
                                  Subscriptions
                                </div>

                                <div className="billing-mob-drop-section">

                                <div className="billing-mob-select-option billing-mob-drop-one"
                                  onClick={(e) =>
                                    this.handleMobileBillingDropdown(e)
                                  }
                                >
                                  <ReactSelect
                                    styles={customStyles}
                                    className="billing-react-select-dropdown"
                                    isSearchable={false}
                                    isDisabled={true}
                                    value={this.state.totalmergeArray.filter(function (option) {
                                      return (option.value ===parseInt(localStorage.getItem("selectedbilling")));
                                    })}
                                    
                                    formatOptionLabel={(res) => 
                                      (
                                        <div className="d-flex test">
                                          <div className="billing-options">
                                            <span className="billing-options-workspace-name">
                                            {res.label}
                                            </span>
                                          <>
                                            {res.value == 1 ?
                                             <>
                                             <p className="billing-options-workspace-type">
                                              {(parseInt(localStorage.getItem('personal_apps_count')) + parseInt(localStorage.getItem('invited_apps_count'))) + " Apps"}
                                             </p>
                                             </>
                                            : res.value == 2 ?
                                              <>
                                              <p className="billing-options-workspace-type">
                                              {(parseInt(localStorage.getItem('workspace_count')) + parseInt(localStorage.getItem('invited_workspace_count'))) + " Unlimited apps"}
                                              </p>
                                              </>
                                            : res.value == 3 ?
                                              <>
                                              <p className="billing-options-workspace-type">
                                              {(parseInt(localStorage.getItem('personal_agency_count')) + parseInt(localStorage.getItem('invited_agency_count'))) + " White label"}
                                              </p>
                                              </>
                                            : <></>
                                            }
                                          </>
                                          </div>
                                        </div>
                                      )}
                                  />
                                 
                                </div>

                                <div className="billing-mob-select-option billing-mob-drop-two"
                                style={{                 
                                  cursor: localStorage.getItem("selectedbilling") == 4 ? 'not-allowed' : 'pointer',
                                  }}
                                onClick={(e) => localStorage.getItem("selectedbilling") == 4 ? null : this. handleMobileBillingDropdowntwo(e) } >
                                  <div>
                                    {/* <img src={require("../../assets/images/dashboard/accountprofile.png") .default} className="img-fluid"/> */}
                                    <p  style={{                 
                                     color: localStorage.getItem("selectedbilling") == 4 ? "#BCC1CE" : '#000',
                                       }}>{this.state.searchname}</p>
                                  </div>
                                {this.state.searchname != 'All' ?
                                  <svg xmlns="http://www.w3.org/2000/svg" width="11.06" height="11.06" viewBox="0 0 11.06 11.06" onClick={(e) => this.newhandleBillingChange('')}>
                                    <path id="Union_2970" data-name="Union 2970" d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                  </svg>
                                : <svg xmlns="http://www.w3.org/2000/svg" width="11.063" height="6.591" viewBox="0 0 11.063 6.591">
                                <path id="Path_108406" data-name="Path 108406" d="M-17299.426-4409.885l5,5,5-5" transform="translate(17299.957 4410.416)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                              </svg>
                              
                                }

                                </div>

                                </div>

                                <div
                                  className={
                                    this.state.mobileBillingDropdown
                                      ? "modal-mob-select-div"
                                      : ""
                                  }
                                >
                                  {this.state.mobileBillingDropdown && (
                                    <div className="billing-mob-select-div billing-mob-one">
                                       <div className="billing-mob-select-divtwo">
                                      {this.state.totalmergeArray && this.state.totalmergeArray.map(
                                          (res, indx) => {
                                            return (
                                              <div className="d-flex align-items-center billing-mob-select-div-user"  onClick={()=>this.handleBillingChangeTotal(res,'')}>
                                                
                                                <div className="d-grid justify-content-center align-items-center">
                                                  <p className="billing-select-name">
                                                  {res.label}
                                                  </p>
                                                  <>
                                                    {res.value == 1 ?
                                                    <>
                                                    <p className="billing-select-type">
                                                      {(parseInt(localStorage.getItem('personal_apps_count')) + parseInt(localStorage.getItem('invited_apps_count'))) + " Apps"}
                                                    </p>
                                                    </>
                                                    : res.value == 2 ?
                                                      <>
                                                      <p className="billing-select-type">
                                                      {(parseInt(localStorage.getItem('workspace_count')) + parseInt(localStorage.getItem('invited_workspace_count'))) + " Unlimited apps"}
                                                      </p>
                                                      </>
                                                    : res.value == 3 ?
                                                      <>
                                                      <p className="billing-select-type">
                                                      {(parseInt(localStorage.getItem('personal_agency_count')) + parseInt(localStorage.getItem('invited_agency_count'))) + " White label"}
                                                      </p>
                                                      </>
                                                    : <></>
                                                    }
                                                  </>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                        </div>
                                    </div>
                                  )}
                                </div>


                                <div>
                                  <div className={this.state.mobileBillingDropdownTwo ? "modal-mob-select-div" : ""}>
                                  {this.state.mobileBillingDropdownTwo && (
                                    <div className="billing-mob-select-div">
                                        <div className="billing-mob-select-divtwo billing-new-dropbdown-mob">

                                          <div className="billing-dropdown-two-search">
                                            <input placeholder="Search..." type="text" value={this.state.searchAppVal} onChange={(e) => {this.setState({searchAppVal: e.target.value});}}/>
                                            {this.state.searchAppVal.trim() && (
                                              <p onClick={(e) => {this.setState({searchAppVal: ""})}}>
                                                Clear
                                              </p>
                                            )}
                                          </div>
                                          {this.state.typedata && this.state.typedata.length === 0 ?
                                          (
                                            <div className="text-center billing_nodataimg" style={{margin: "70px 0px"}}>
                                            <img src={ require("../../assets/images/dashboard/NoInvoice.png") .default }
                                              className="img-fluid"
                                              alt="nosubscription"
                                            />

                                            <span className=" billing-mobblank-screen span1">
                                              There is nothing here at the moment.
                                              Come back later?
                                            </span>
                                          </div>
                                          )
                                        :
                                          <div className="billing-dropdown-two-list">
                                          {this.state.typedata && this.state.typedata.map((res) => 
                                          {
                                            if(this.state.searchAppVal == '' || 
                                                (parseInt(localStorage.getItem("selectedbilling")) == 1 && res.app_name.toLowerCase().includes(this.state.searchAppVal.toLowerCase())) ||
                                                (parseInt(localStorage.getItem("selectedbilling")) == 2 && res.workspace_name.toLowerCase().includes(this.state.searchAppVal.toLowerCase())) ||
                                                (parseInt(localStorage.getItem("selectedbilling")) == 3 && res.agency_name.toLowerCase().includes(this.state.searchAppVal.toLowerCase())) 
                                              )
                                            {
                                              return(
                                                <>
                                                {this.state.typedata.length > 0 && parseInt(localStorage.getItem("selectedbilling")) == 1 ? 
                                                  <>
                                                    {this.state.searchAppVal == '' || res.app_name.toLowerCase().includes(this.state.searchAppVal.toLowerCase()) ?
                                                      <>
                                                         <div className="billing-dropdown-two-entry" onClick={(e) => this.newhandleBillingChange(res)}>
                                                              {res.app_icon === "" ? 
                                                                (
                                                                  <img
                                                                    src={require("../../assets/images/dashboard/my-account.png").default}
                                                                    className="img-fluid"
                                                                    alt="profile logo"
                                                                  />
                                                                ) 
                                                              : 
                                                                (
                                                                    <>
                                                                        <img
                                                                          className="img-fluid"
                                                                          alt=""
                                                                          src={
                                                                            process.env.REACT_APP_Image_Path +
                                                                            res.user_id +
                                                                            "/" +
                                                                            res.app_id +
                                                                            process.env.REACT_APP_AppIcon_Display +
                                                                            res.app_icon
                                                                          }
                                                                          onError={({ currentTarget }) => {
                                                                            currentTarget.onerror = null;
                                                                            currentTarget.src =
                                                                              res.type==="user"
                                                                              ? userdefaultImgOnError :wpdefaultImgOnError
                                                                          }}
                                                                        />
                                                                    </> 
                                                                )
                                                              }

                                                            <div>
                                                              <h5>{res.app_name}</h5>
                                                              <h6>{"ID: " +res.app_id}</h6>
                                                            </div>
                                                        </div>
                                                      </>
                                                    : <></>
                                                    }
                                                  </>
                                                : this.state.typedata.length > 0 &&  parseInt(localStorage.getItem("selectedbilling")) == 2 ? 
                                                    <>
                                                      {this.state.searchAppVal == '' || res.workspace_name.toLowerCase().includes(this.state.searchAppVal.toLowerCase()) ?
                                                      <>
                                                          <div className="billing-dropdown-two-entry" onClick={(e) => this.newhandleBillingChange(res)}>
                                                          <>
                                                            {res.profile_image === "" ||
                                                            res.profile_image === null ||
                                                            res.profile_image === "null" ||
                                                            res.profile_image === undefined ? 
                                                            (
                                                                <img src={require("../../assets/images/dashboard/sub-overview-img.png").default}  className="img-fluid"/>
                                                            ) 
                                                            : 
                                                            (
                                                              <img src={process.env.REACT_APP_Image_Path + res.user_id +
                                                                        process.env.REACT_APP_Workspace_Profile + res.workspace_id +
                                                                        "/" + res.profile_image
                                                                      }
                                                                    onError={({currentTarget,}) => {
                                                                    currentTarget.onerror = null; currentTarget.src =newDefaultImg;
                                                                    }}
                                                                className="img-fluid"
                                                              />
                                                            )}
                                                          </>

                                                          <div>
                                                            <h5>
                                                            {res.workspace_name}
                                                            </h5>
                                                            <h6>
                                                              {"Unlimited-apps"}
                                                            </h6>
                                                          </div>
                                                        </div>
                                                      </>
                                                      : <></>
                                                      }
                                                    </>
                                                : this.state.typedata.length > 0 && parseInt(localStorage.getItem("selectedbilling")) == 3 ? 
                                                  <>
                                                    {this.state.searchAppVal == '' || res.agency_name.toLowerCase().includes(this.state.searchAppVal.toLowerCase()) ?
                                                      <>
                                                          <div className="billing-dropdown-two-entry" onClick={(e) => this.newhandleBillingChange(res)}>
                                                          <>
                                                            {res.favicon === "" ||
                                                            res.favicon === null ||
                                                            res.favicon === "null" ||
                                                            res.favicon === undefined ? 
                                                            (
                                                                <img src={require("../../assets/images/dashboard/sub-overview-img.png").default} className="img-fluid"/>
                                                            ) 
                                                            : 
                                                            (
                                                              <img src={process.env.REACT_APP_Image_Path + "agencies/" + res.id + process.env.REACT_APP_Agency_Profile + res.favicon}
                                                                    onError={({currentTarget,}) => {
                                                                    currentTarget.onerror = null; currentTarget.src =newDefaultImg;
                                                                    }}
                                                                className="img-fluid"
                                                              />
                                                            )}
                                                          </>

                                                            <div>
                                                              <h5>
                                                              {res.agency_name}
                                                              </h5>
                                                              <h6>
                                                                {"White-label"}
                                                              </h6>
                                                            </div>
                                                        </div>
                                                      </>
                                                    : <></>
                                                    }
                                                  </>
                                                : <></>
                                                }
                                            </>
                                              )}
                                          })}


                                          </div>
                                        }
                                        </div>
                                    </div>
                                  )}
                                  </div>
                                </div>


                                {this.state.billingdata.length === 0 ? (
                                  <>
                                    <div className="text-center billing_nodataimg ">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/NoInvoice.png")
                                            .default
                                        }
                                        className="img-fluid noInvoice"
                                        alt="nosubscription"
                                      />

                                      <span className=" billing-mobblank-screen span1">
                                        There is nothing here at the moment.
                                        Come back later?
                                      </span>
                                    </div>
                                    
                                  </>
                                ) : (
                                  <div>
                                    {this.state.billingdata.filter((row) =>
                                        ( parseInt(localStorage.getItem("selectedbilling")) == 2
                                        ? row.workspace_name
                                        : parseInt(localStorage.getItem("selectedbilling")) == 3
                                        ? row.agency_name && row.agency_name != '' ? row.agency_name.toLowerCase().includes(this.state.subsSeacrch != '' ? this.state.subsSeacrch : '') :''
                                        : parseInt(localStorage.getItem("selectedbilling")) == 4
                                        ? row.plan_name && row.plan_name != '' ? row.plan_name.toLowerCase().includes(this.state.subsSeacrch != '' ? this.state.subsSeacrch : '') :''
                                        : row.app_name && row.app_name != '' ? row.app_name.toLowerCase().includes(this.state.subsSeacrch != '' ? this.state.subsSeacrch : '') : ''
                                        )
                                      )
                                      .map((res, i) => (
                                          <>
                                          <div className="  billing_mobile-payment">
                                            <div className="d-flex  flex-column ">
                                              <div className="d-flex justify-content-between align-items-center billing-mob-firstdiv">
                                                <div className=" d-flex w-75 mobile-url">
                                                
                                                  <div className="d-flex cus-text">
                                                    <div className="billinghp">
                                                      <h4 className="mr-2 cus-text-inner d-flex">
                                                      {this.state.typedata.length > 0 && parseInt(localStorage.getItem("selectedbilling")) == 1 ?
                                                      res.app_name
                                                      : this.state.typedata.length > 0 && parseInt(localStorage.getItem("selectedbilling")) == 2 ?
                                                      res.workspace_name
                                                      : this.state.typedata.length > 0 && parseInt(localStorage.getItem("selectedbilling")) == 3 ?
                                                      res.agency_name
                                                      : parseInt(localStorage.getItem("selectedbilling")) == 4 ?
                                                      res.name
                                                      : ''
                                                      } 
                                                        
                                                      </h4>
                                                      {res.gateway_subscription_id && res.gateway_subscription_id && res.gateway_subscription_id != '' ? 
                                            
                                                        <p>{'ID:' + res.gateway_subscription_id}
                                                        </p>
                                                      :
                                                        <></>
                                                      }

                                                      
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className=" edit-link-redesign">
                                                 {
                                                  parseInt(localStorage.getItem("selectedbilling")) == 4?
                                                  <>
                                                      {res.profile_image === "" ||
                                                      res.profile_image === null ||
                                                      res.profile_image === "null" ||
                                                      res.profile_image === undefined ? 
                                                      (
                                                        <img src={require("../../assets/images/dashboard/sub-overview-img.png").default} onError={({currentTarget,}) => {
                                                          currentTarget.onerror =null;
                                                          currentTarget.src =newDefaultImg;
                                                        }} className="mr-3"/>
                                                      ) 
                                                      : 
                                                      (
                                                        <div className="biiling-iconsize">
                                                        <img src={
                                                         (
                                                          res.profile_image.includes("http") ?
                                                           (res.profile_image)
                                                          :
                                                          (process.env.REACT_APP_Image_Path +
                                                                  res.user_id +
                                                                  process.env.REACT_APP_Profile +
                                                                  res.profile_image)
                                                          )
                                                          }
                                                          onError={({currentTarget,}) => {
                                                            currentTarget.onerror =null;
                                                            currentTarget.src = newDefaultImg;
                                                          }}
                                                          className="vvvv"
                                                        />
                                                        </div>
                                                      )}
                                                    </>
                                                 
                                                  :
                                                  <>
                                                  {
                                                  localStorage.getItem("workspaceId") !== "" &&
                                                  localStorage.getItem("workspaceId") !== undefined &&
                                                  localStorage.getItem("workspaceId") !== "undefined" &&
                                                  localStorage.getItem("workspaceId") !== null ? 
                                                  (
                                                    <>
                                                      {res.profile_image === "" ||
                                                      res.profile_image === null ||
                                                      res.profile_image === "null" ||
                                                      res.profile_image === undefined ? 
                                                      (
                                                        <img src={require("../../assets/images/dashboard/sub-overview-img.png").default} onError={({currentTarget,}) => {
                                                          currentTarget.onerror =null;
                                                          currentTarget.src =newDefaultImg;
                                                        }} className="mr-3"/>
                                                      ) 
                                                      : 
                                                      (
                                                        <div className="biiling-iconsize">
                                                        <img src={process.env.REACT_APP_Image_Path +
                                                                  res.user_id +
                                                                  process.env.REACT_APP_Workspace_Profile +
                                                                  res.workspace_id +
                                                                  "/" +
                                                                  res.profile_image
                                                          }
                                                          onError={({currentTarget,}) => {
                                                            currentTarget.onerror =null;
                                                            currentTarget.src =newDefaultImg;
                                                          }}
                                                          className="vvvv"
                                                        />
                                                        </div>
                                                      )}
                                                    </>
                                                  )
                                                  : localStorage.getItem("agency_id") !== "" &&
                                                  localStorage.getItem("agency_id") !== undefined &&
                                                  localStorage.getItem("agency_id") !== "undefined" &&
                                                  localStorage.getItem("agency_id") !== null &&
                                                  localStorage.getItem("agency_id") != '1' ? 
                                                  (
                                                    <>
                                                      {res.profile_image === "" ||
                                                      res.profile_image === null ||
                                                      res.profile_image === "null" ||
                                                      res.profile_image === undefined ? 
                                                      (
                                                        <img src={require("../../assets/images/dashboard/sub-overview-img.png").default} className="mr-3"/>
                                                      ) 
                                                      : 
                                                      (
                                                        <div className="biiling-iconsize">
                                                        <img src={process.env.REACT_APP_Image_Path + "agencies/" + res.agency_id + process.env.REACT_APP_Agency_Profile + res.profile_image}
                                                          onError={({currentTarget,}) => {
                                                            currentTarget.onerror =null;
                                                            currentTarget.src =newDefaultImg;
                                                          }}
                                                          className=""
                                                        />
                                                        </div>
                                                      )}
                                                    </>
                                                  ) 
                                                  : (
                                                    <img
                                                      src={
                                                        process.env
                                                          .REACT_APP_Image_Path +
                                                        res.user_id +
                                                        "/" +
                                                        res.app_id +
                                                        process.env
                                                          .REACT_APP_AppIcon_Display +
                                                        res.app_icon
                                                      }
                                                      alt="app-icon 2"
                                                      className=" img-fluid mob-rounded rounded-red"
                                                      width="60px"
                                                      height="60px"
                                                      onError={({currentTarget,}) => {
                                                        currentTarget.onerror =null;
                                                        currentTarget.src =newDefaultImg;
                                                      }}
                                                    />
                                                  )}
                                                  </>
                                                  }
                                                </div>
                                              </div>

                                              <div className=" billing-planbox">
                                                <div className="d-flex justify-content-between billings-mob-product">
                                                  <div
                                                    style={{ display: "grid" }}
                                                  >
                                                    <div className="">
                                                      <h6>Product</h6>
                                                    </div>
                                                    <div className=" plan-text-responsive">
                                                      <p
                                                        style={{
                                                          lineHeight: "21px",
                                                        }}
                                                      >
                                                        { capitalize(res.plan_name.toLowerCase())}
                                                      </p>
                                                      <p>
                                                        {res.plan_code != 'paddle_preview' && res.plan_code != 'agency_preview' ? 'ID:'+res.plan_code : ''}
                                                      </p>
                                                      {/* <span class="plan-tooltip">
                                                      {
                                            res.type===2 ? `Addon ( ${res.addon_array.addon_quantity} )` :"Plan"
                                           } 
                                            <span class="plan-tooltiptext">
                                              {res.plan_code}
                                              {res.localstorageData && res.localstorageData.subscription &&
                                            res.localstorageData.subscription.gateway_subscription_id &&
                                          
                                          <p className="billing-web-paymentgetway-id">
                                          {res.localstorageData.subscription.gateway_subscription_id}
                                          </p>
                                         }
                                            </span> 
                                          </span>*/}
                                                    </div>
                                                  </div>
                                                  {
                                            res.type===2 ? 
                                            parseInt(localStorage.getItem("selectedbilling")) === 3 ?
                                            <Link to={`/agency/addon/${btoa(res.agency_id)}`}> {" "}
                                            Edit{" "}</Link>
                                            :
                                            <Link
                                            onClick={(e) =>
                                              this.handleEditforAddon(
                                                e,
                                                res.user_id,
                                                res.localstorageData
                                                  .subscription.status,
                                                res.app_name,
                                                localStorage.getItem(
                                                  "workspaceId"
                                                ) !== ""
                                                  ? res.workspace_id
                                                  : res.app_id,
                                                res.localstorageData
                                                  .version,
                                                this.setname(
                                                  res.app_name
                                                ),
                                                res.localstorageData
                                                  .subscription,
                                                res.store_url,
                                                res.localstorageData
                                                  .plugin_keys,
                                                res.localstorageData
                                                  .menu_fill,
                                                res.localstorageData
                                                  .dashboard_count,
                                                res.localstorageData
                                                  .website_type,
                                                res.localstorageData
                                                  .is_verified,
                                                res.localstorageData
                                                  .appicon_count,
                                                res.localstorageData
                                                  .splash_count,
                                                res.localstorageData
                                                  .login_reg_count,
                                                res.localstorageData
                                                  .style_count,
                                                res.plan_name,
                                                res.addon_array,
                                                res,
                                                "newAddon"
                                              )
                                            }
                                            // to={`/app/addons/${addon.addon_name
                                            //   .replaceAll(" ", "_")
                                            //   .toLowerCase()}/${btoa(
                                            //   addon.addon_code
                                            // )}/${btoa(res.app_id)}/${btoa(
                                            //   res.plan_code
                                            // )}/${btoa(
                                            //   addon.addon_identifier
                                            // )}`}
                                          >
                                            Edit{" "}
                                          </Link> :
                                                  <Link
                                                    to={
                                                      localStorage.getItem("workspaceId") !== "" &&
                                                      localStorage.getItem("workspaceId") !== undefined &&
                                                      localStorage.getItem("workspaceId") !== "undefined" &&
                                                      localStorage.getItem("workspaceId") !== null
                                                      ? `pricing/${btoa(res.workspace_id)}`
                                                      : parseInt(localStorage.getItem("selectedbilling")) == 4 ?
                                                        `/support-pricing`
                                                      : `subscription/${btoa(res.app_id)}`
                                                    }
                                                  >
                                                    Upgrade{" "}
                                                  </Link> }
                                                </div>
                                                {/* Lifetime */}
                                                {/* {res.plan_code.indexOf(
                                            process.env.REACT_APP_Lifetime_plan
                                          ) === -1 &&
                                            res.package_duration_id !== 4 && ( */}
                                                <div className="d-grid billings-mob-nextpayment">
                                                  <div style={{display: "flex" , alignItems : "center" , justifyContent: "space-between"}}>
                                                  <div>
                                                    <div className="">
                                                      <h6>Renewal</h6>
                                                    </div>
                                                    <div className="  plan-text-responsive">
                                                      {res.plan_code.indexOf(
                                                        process.env
                                                          .REACT_APP_Lifetime_plan
                                                      ) !== -1 ||
                                                      res.package_duration_id ===
                                                        4 ? (
                                                        <>
                                                          <p>Lifetime</p>
                                                          <h5 className="billing-expiry-date">
                                                            Never Expires
                                                          </h5>
                                                        </>
                                                      ) : // res.total === "0.00" &&
                                                      res.reseller_package_id !==
                                                          null &&
                                                        res.reseller_package_expiry_date !==
                                                          null ? (
                                                        <> 
                                                          <p>
                                                           -
                                                            
                                                          </p>
                                                          <h5 className="billing-expiry-date">
                                                            {moment(
                                                              res.reseller_package_expiry_date
                                                            ).format(
                                                              "D MMMM YYYY, HH:mm"
                                                            )}
                                                          </h5>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <p className="billing-plan-frequency">
                                                            {res.currency}{res.total}
                                                            <span className="billings-frequency">
                                                              /
                                                              {res.package_duration_id ===
                                                              1
                                                                ? "month"
                                                                : "year"}
                                                            </span>
                                                          </p>
                                                          {
                                                            res.downgrade_at_term_end===0?<h5 className="billing-expiry-date">
                                                            {res.package_duration_id != 0
                                                              ? moment(
                                                                  res.billing_date
                                                                ).format("D MMMM YYYY, HH:mm")
                                                              : null}
                                                          </h5>
                                                          :
                                                          res.downgrade_request_timestamp ?
                                                          <>
                                                          <h5 className="billing-expiry-date billing-expiry-date-mob" style={{color:"#FF4848", fontSize: "12px"}}>Cancellation 
                                                              {" "} {res.package_duration_id != 0
                                                               ? moment(
                                                                res.billing_date
                                                              ).format("D MMMM YYYY")
                                                               : null}
                                                          </h5>
                                                           <h5 style={{margin:"0px" , color: "#7782a1" , fontSize: "12px"}}>Requested 
                                                           {" "} {res.package_duration_id != 0
                                                            ? moment(
                                                             res.downgrade_request_timestamp
                                                           ).format("D MMMM YYYY, HH:mm")
                                                            : null}
                                                          </h5>
                                                          </>
                                                          :
                                                          <h5 className="billing-expiry-date" style={{color:"#FF4848"}}>Downgrade scheduled</h5>
                                                          }
                                                          
                                                          {/* <p>{new Date(res.billing_date).toLocaleString()}</p> */}
                                                        </>
                                                      )}
                                                      
                                                      
                                                    </div>
                                                  </div>
                                                  {returnStatus(res.status)}
                                                  </div>
                                                {parseInt(localStorage.getItem("selectedbilling")) != 3 && res && res.package_duration_id != '' && res.package_duration_id == 1 ?
                                                    <>
                                                    {parseInt(localStorage.getItem("selectedbilling")) == 1 ?
                                                      <div className="billing-yearly-saving">
                                                          {res.localstorageData && res.localstorageData.subscription && res.localstorageData.subscription.subscriptionclient && parseInt(res.localstorageData.subscription.subscriptionclient.website_technology) == 5 ?
                                                            <>
                                                              {res.plan == 'starter' ?
                                                                <>
                                                                  <h5>Save up to 34% on annual plan.</h5>
                                                                </>
                                                              :res.plan == 'pro' ?
                                                                <>
                                                                <h5>Save up to 20% on annual plan.</h5>
                                                                </>
                                                              :res.plan == 'premium' ?
                                                                <>
                                                                    <h5>Save up to 20% on annual plan.</h5>
                                                                </>
                                                              :
                                                                <></>
                                                              }
                                                            </>
                                                            
                                                          :
                                                            <>
                                                              {res.plan == 'starter' ?
                                                                <>
                                                                  <h5>Save up to 53% on annual plan.</h5>
                                                                </>
                                                              :res.plan == 'pro' ?
                                                                <>
                                                                <h5>Save up to 34% on annual plan.</h5>
                                                                </>
                                                              :res.plan == 'premium' ?
                                                                <>
                                                                    <h5>Save up to 20% on annual plan.</h5>
                                                                </>
                                                              :
                                                                <></>
                                                              }
                                                            </>
                                                          }
                                                        
                                                      </div>
                                                    :
                                                      <>
                                                      <div className="billing-yearly-saving">
                                                            <h5>Save up to 20% on annual plan.</h5>
                                                            </div>
                                                        {/* {res.website_technology && parseInt(res.website_technology) == 5 ?
                                                          <>
                                                          {res.plan == 'starter' ?
                                                            <>
                                                            <div className="billing-yearly-saving">
                                                              <h5>Save up to 34% on annual plan.</h5>
                                                              </div>
                                                            </>
                                                          :res.plan == 'pro' ?
                                                            <>
                                                            <div className="billing-yearly-saving">
                                                            <h5>Save up to 20% on annual plan.</h5>
                                                            </div>
                                                            </>
                                                          :res.plan == 'premium' ?
                                                            <>
                                                            <div className="billing-yearly-saving">
                                                                <h5>Save up to 20% on annual plan.</h5>
                                                            </div>
                                                            </>
                                                          :
                                                            <></>
                                                          }
                                                        </>
                                                        :res.website_technology && parseInt(res.website_technology) == 1 ?
                                                            <>
                                                            {res.plan == 'starter' ?
                                                              <>
                                                              <div className="billing-yearly-saving"><h5>Save up to 20% on annual plan.</h5></div>
                                                                
                                                              </>
                                                            :res.plan == 'pro' ?
                                                              <>
                                                              <div className="billing-yearly-saving"><h5>Save up to 14% on annual plan.</h5></div>
                                                              
                                                              </>
                                                            :res.plan == 'premium' ?
                                                              <>
                                                              <div className="billing-yearly-saving"><h5>Save up to 17% on annual plan.</h5></div>
                                                                  
                                                              </>
                                                            :
                                                              <></>
                                                            }
                                                          </>
                                                        :
                                                          <>
                                                              {res.plan == 'starter' ?
                                                                <>
                                                                <div className="billing-yearly-saving"><h5>Save up to 20% on annual plan.</h5></div>
                                                                  
                                                                </>
                                                              :res.plan == 'pro' ?
                                                                <>
                                                                <div className="billing-yearly-saving"><h5>Save up to 25% on annual plan.</h5></div>
                                                                
                                                                </>
                                                              :res.plan == 'premium' ?
                                                                <>
                                                                <div className="billing-yearly-saving"><h5>Save up to 17% on annual plan.</h5></div>
                                                                    
                                                                </>
                                                              :
                                                                <></>
                                                              }
                                                            </>
                                                        } */}
                                                      </>
                                                    }
                                                    </>
                                                :
                                                    <></>
                                                }
                                                 
                                                </div>
                                                {/* )} */}
                                                {/* payment method */}

                                                <div className=" d-grid  billings-mob-paymentmethod">
                                                  <div className="">
                                                    <h6>Payment Method</h6>
                                                  </div>
                                                  <div className="   plan-text-responsive">
                                                    <p className=" link-update-card ">
                                                      {this.state.customer_billing_type === 2 ||
                                                      (this.state.customer_billing_type === 1 &&
                                                      localStorage.getItem( "workspaceId") !== "" &&
                                                      localStorage.getItem("workspaceId") !== undefined &&
                                                      localStorage.getItem("workspaceId") !== null) &&  
                                                      (this.state.customer_billing_type != 3 &&
                                                        (
                                                        localStorage.getItem("agency_id") == "" ||
                                                        localStorage.getItem("agency_id") == undefined ||
                                                        localStorage.getItem("agency_id") == "undefined" ||
                                                        localStorage.getItem("agency_id") == null ||
                                                        localStorage.getItem("agency_id") == "null"
                                                        )
                                                      )
                                                      ? 
                                                      (
                                                        res.payment_method_info
                                                          .card_last4_digit !==
                                                        undefined ? (
                                                          <div className="billing-mob-cardupdate">
                                                            <div className="billing-mob-card">
                                                              <p>
                                                                xxxx xxxx xxxx{" "}
                                                                {res
                                                                  .payment_method_info
                                                                  .card_last4_digit +
                                                                  "  "}
                                                                {/* <img style={{cursor:"pointer"}}
                                                              src={
                                                                require("../../assets/images/dashboard/edit-icon.png")
                                                                  .default
                                                              }
                                                              className="edit-btn"
                                                              onClick={(e) =>
                                                                this.isCardPopUp(
                                                                  e,
                                                                  res.app_id,
                                                                  res
                                                                    .payment_method_info
                                                                    .id
                                                                )
                                                              }
                                                            /> */}
                                                              </p>
                                                              {/* <span>
                                                            {res
                                                              .payment_method_info
                                                              .expiry_month > 9
                                                              ? res
                                                                  .payment_method_info
                                                                  .expiry_month
                                                              : `0${res.payment_method_info.expiry_month}`}
                                                            /
                                                            {
                                                              res
                                                                .payment_method_info
                                                                .expiry_year
                                                            }
                                                          </span> */}
                                                            </div>
                                                            <div className="d-grid billing-upgrade-type">
                                                            { (res.localstorageData && res.localstorageData.subscription &&  res.localstorageData.subscription.is_new_deal===1 &&
                                                         res.localstorageData.subscription.package_info.is_deal_plan===1 )

                                                          ?<></>
                                                          :
                                                              <Link
                                                                className="edit-btn edit-btn-card"
                                                                onClick={(e) =>
                                                                  this.isCardPopUp(
                                                                    e,
                                                                    res.app_id,
                                                                    res
                                                                      .payment_method_info
                                                                      .id
                                                                  )
                                                                }
                                                              >
                                                                Update
                                                              </Link>
                                                            }
                                                              
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          (res.localstorageData && res.localstorageData.subscription && res.localstorageData.subscription.is_new_deal===1 &&
                                                            res.localstorageData.subscription.package_info.is_deal_plan===1 )
   
                                                             ?<p className="billing_card-update">
                                                               -
                                                               
                                                             </p>
                                                             :
                                                          <Link
                                                            className="edit-btn edit-btn-card billing-link-card"
                                                            onClick={(e) =>
                                                              this.isCardPopUp(
                                                                e,
                                                                res.app_id,
                                                                res
                                                                  .payment_method_info
                                                                  .id
                                                              )
                                                            }
                                                          >
                                                            Update
                                                          </Link>
                                                        )
                                                      ) : this.state.customer_billing_type === 3 || 
                                                          (localStorage.getItem("agency_id") !== "" &&
                                                          localStorage.getItem("agency_id") !== undefined &&
                                                          localStorage.getItem("agency_id") !== "undefined" &&
                                                          localStorage.getItem("agency_id") !== null &&
                                                          localStorage.getItem("agency_id") !== "null" ) ? 
                                                      (
                                                          <>
                                                            {res.downgrade_at_term_end ===
                                                            1 ? 
                                                            (
                                                            <div>
                                                              {res.update_pm_link ? (
                                                                  <>
                                                                    xxxx xxxx xxxx xxxx{" "}
                                                                    {"   "}
                                                                  </>
                                                                ) 
                                                                : 
                                                                (
                                                                  <span
                                                                    style={{
                                                                      textAlign: "center",
                                                                    }}
                                                                  >
                                                                    -
                                                                  </span>
                                                                ) 
                                                              }
                                                            </div>
                                                       ) 
                                                       : (
                                                  <>
                                                    {res.update_pm_link && res.update_pm_link ? (
                                                      <>
                                                      xxxx xxxx xxxx xxxx{" "}
                                                      { (res.localstorageData && res.localstorageData.subscription && res.localstorageData.subscription.is_new_deal===1 && res.localstorageData.subscription.package_info.is_deal_plan===1 )

                                                          ?<></>
                                                          :
                                                      <a
                                                        href={
                                                          res.update_pm_link
                                                        }
                                                        target="_blank"
                                                      >
                                                        Update
                                                        <img
                                                          style={{
                                                            cursor:
                                                              "pointer",
                                                          }}
                                                          src={
                                                            require("../../assets/images/dashboard/Goto.png")
                                                              .default
                                                          }
                                                        />
                                                      </a>
                                                    }
                                                    </>
                                                    ) : (
                                                      <span
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        -
                                                      </span>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                                        
                                                      ): (
                                                        <p className="billing-subs-paddle-update">
                                                        <p>
                                                        xxxx xxxx xxxx xxxx{" "}
                                                        {"   "}
                                                        </p>
                                                        { (res.localstorageData && res.localstorageData.subscription && res.localstorageData.subscription.is_new_deal===1 &&
                                                         res.localstorageData.subscription.package_info.is_deal_plan===1 )

                                                          ?<></>
                                                          :
                                                          <p
                                                            className="billing_card-update"
                                                            onClick={(e) =>
                                                              this.changePaymentMthd(
                                                                res.app_id,
                                                                i
                                                              )
                                                            }
                                                          >
                                                            Update
                                                            <img
                                                              width="20px"
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              src={
                                                                this.state
                                                                  .isImage ===
                                                                "true" + i
                                                                  ? this.state
                                                                      .zohoLoader
                                                                  : require("../../assets/images/dashboard/Goto.png")
                                                                      .default
                                                              }
                                                              className={
                                                                this.state
                                                                  .isImage ===
                                                                "true" + i
                                                                  ? "zoho-loader-img"
                                                                  : "edit-btn"
                                                              }
                                                            />
                                                          </p>
                                                          }
                                                        </p>
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {this.state.billingdata[i].addon_data
                                            .length > 0 &&
                                            this.state.billingdata[
                                              i
                                            ].addon_data.map((addon) => {
                                              return (
                                                <div className=" billing_mobile-payment">
                                                  <div className="d-flex  flex-column ">
                                                    <div className="d-flex justify-content-between align-items-center billing-mob-firstdiv">
                                                      <div className=" d-flex w-70">
                                                        
                                                        <div className="d-flex cus-text">
                                                          <div>
                                                            <h4 className="mr-2 d-flex">
                                                              {res.app_name}
                                                              
                                                            </h4>
                                                            {localStorage.getItem(
                                                              "workspaceId"
                                                            ) === "" ||
                                                            localStorage.getItem(
                                                              "workspaceId"
                                                            ) === undefined ||
                                                            localStorage.getItem(
                                                              "workspaceId"
                                                            ) === "undefined" ||
                                                            localStorage.getItem(
                                                              "workspaceId"
                                                            ) === null ? (
                                                              <p className="sm-mt-0 billings-sm-hidden">
                                                                {res.store_url.substr(
                                                                  0,
                                                                  35
                                                                ) +
                                                                  (res.store_url
                                                                    .length >=
                                                                  35
                                                                    ? "..."
                                                                    : "")}
                                                              </p>
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="edit-link-redesign edit-link">
                                                        
                                                        <img
                                                          src={
                                                            process.env
                                                              .REACT_APP_Image_Path +
                                                            res.user_id +
                                                            "/" +
                                                            res.app_id +
                                                            process.env
                                                              .REACT_APP_AppIcon_Display +
                                                            res.app_icon
                                                          }
                                                          alt="app-icon"
                                                          className=" img-fluid mob-rounded"
                                                          width="60px"
                                                          height="60px"
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className=" billing-planbox ">
                                                      <div className="d-flex justify-content-between billings-mob-product">
                                                        <div className="d-grid">
                                                          <div className="">
                                                            <h6>Product</h6>
                                                          </div>
                                                          <div className="  plan-text-responsive">
                                                            <p
                                                              style={{
                                                                lineHeight:
                                                                  "21px",
                                                              }}
                                                            >
                                                              {addon.addon_name}
                                                            </p>
                                                            <span>
                                                              Addon ({" "}
                                                              {addon.addon_quantity > 1 ? addon.addon_quantity : 1}
                                                              {" "}
                                                              )
                                                            </span>
                                                          </div>
                                                        </div>
                                                        <Link
                                                          onClick={(e) =>
                                                            this.handleEditforAddon(
                                                              e,
                                                              res.user_id,
                                                              res
                                                                .localstorageData
                                                                .subscription
                                                                .status,
                                                              res.app_name,
                                                              // 1249,
                                                              localStorage.getItem(
                                                                "workspaceId"
                                                              ) !== ""
                                                                ? res.workspace_id
                                                                : res.app_id,
                                                              res
                                                                .localstorageData
                                                                .version,
                                                              this.setname(
                                                                res.app_name
                                                              ),
                                                              res
                                                                .localstorageData
                                                                .subscription,
                                                              res.store_url,
                                                              res
                                                                .localstorageData
                                                                .plugin_keys,
                                                              res
                                                                .localstorageData
                                                                .menu_fill,
                                                              res
                                                                .localstorageData
                                                                .dashboard_count,
                                                              res
                                                                .localstorageData
                                                                .website_type,
                                                              res
                                                                .localstorageData
                                                                .is_verified,
                                                              res
                                                                .localstorageData
                                                                .appicon_count,
                                                              res
                                                                .localstorageData
                                                                .splash_count,
                                                              res
                                                                .localstorageData
                                                                .login_reg_count,
                                                              res
                                                                .localstorageData
                                                                .style_count,
                                                              res.plan_name,
                                                              addon,
                                                              res,
                                                              "",
                                                            )
                                                          }
                                                        >
                                                          Edit{" "}
                                                        </Link>
                                                      </div>
                                                      <div className="d-grid justify-content-between billings-mob-nextpayment">
                                                        <div>
                                                          <div className="">
                                                            <h6>
                                                              Next Payment
                                                            </h6>
                                                          </div>
                                                          <div className=" mob-paid-link">
                                                            <h4>
                                                              {addon.package_currency}{addon.addon_amount}
                                                              <span className="billings-frequency">
                                                                /
                                                                {addon.package_duration ===
                                                                1
                                                                  ? "month"
                                                                  : "year"}
                                                              </span>
                                                            </h4>
                                                            <h5 className="billing-expiry-date">
                                                              {moment(
                                                                res.billing_date
                                                              ).format(
                                                                "D MMMM YYYY, HH:mm"
                                                              )}
                                                            </h5>
                                                          </div>
                                                        </div>
                                                        {returnStatus(
                                                          res.status
                                                        )}
                                                      </div>
                                                      <div className="d-grid justify-content-between billings-mob-paymentmethod">
                                                        <div className="">
                                                          <h6>
                                                            Payment Method
                                                          </h6>
                                                        </div>
                                                        <div className="  plan-text-responsive billing-plan-text">
                                                          <p>-</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            })}
                                        </>
                                      ))}
                                  </div>
                                )}
                              </section>
                            )}
                          {/* Mobile View End */}

                         
                        </div>

                        {this.state.bottomspinner && (
                             <div className={`form-group`} style={{ background: "none", margin: "auto" }}>

                             <svg xmlns="http://www.w3.org/2000/svg"
                               xlink="http://www.w3.org/1999/xlink"
                               style={{ margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s" }}
                               width="200px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                               <g transform="translate(20 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                 <circle cx="0" cy="0" r="6" fill="#e6261f" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                   <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                     style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                   </animateTransform>
                                 </circle>
                               </g>
                               <g transform="translate(40 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                 <circle cx="0" cy="0" r="6" fill="#f7d038" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                   <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                     style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                   </animateTransform>
                                 </circle>
                               </g>
                               <g transform="translate(60 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                 <circle cx="0" cy="0" r="6" fill="#49da9a" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                   <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                     style={{ animationplaystate: "running", animationdelay: "0s" }}>
 
                                   </animateTransform>
                                 </circle>
                               </g>
                               <g transform="translate(80 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                 <circle cx="0" cy="0" r="6" fill="#4355db" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                   <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                     style={{ animationplaystate: "running", animationdelay: "0s" }}>
 
                                   </animateTransform>
                                 </circle>
                               </g>
                             </svg>
                           </div>
                          // <div className="text-center  m-3">
                          //   <Spinner animation="grow" variant="primary" />
                          // </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              {/* {InnerFoot()} */}
            </div>)}
            </BottomScrollListener>
          {/* )} */}
        </section>
        </div>

        <Modal
          isOpen={this.state.isCard}
          centered
          className=" billing-card-model"
          style={{ maxWidth: "600px !important" }}
        >
          <div
            className="billing-mob-cardpopuphead"
            onClick={this.closeCardPopUp}
          >
            <img
              src={
                require("../../assets/images/dashboard/goBackicon.png").default
              }
              className="img-fluid"
              alt="nosubscription"
            />
          </div>
          <ModalHeader className=" billing-card-head">
            Select Payment Method
            <div className="crossmodal-card " onClick={this.closeCardPopUp}>
              <img
                src={require("../../assets/images/dashboard/cross.png").default}
                alt="alert"
              />
            </div>
          </ModalHeader>
          <ModalBody className=" popup-spec-view billing-card-body">
            {this.state.cardSpinner ? (
              <div className="newspinner">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  style={{
                    margin: "auto",
                    background: "rgb(241, 242, 243,0)",
                    display: "block",
                    shaperendering: "auto",
                    animationplaystate: "running",
                    animationdelay: "0s",
                  }}
                  width="200px"
                  height="200px"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="xMidYMid"
                >
                  <g
                    transform="translate(20 50)"
                    style={{
                      animationplaystate: "running",
                      animationdelay: "0s",
                    }}
                  >
                    <circle
                      cx="0"
                      cy="0"
                      r="6"
                      fill="#e6261f"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <animateTransform
                        attributeName="transform"
                        type="scale"
                        begin="-0.375s"
                        calcMode="spline"
                        keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                        values="0;1;0"
                        keyTimes="0;0.5;1"
                        dur="1s"
                        repeatCount="indefinite"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      ></animateTransform>
                    </circle>
                  </g>
                  <g
                    transform="translate(40 50)"
                    style={{
                      animationplaystate: "running",
                      animationdelay: "0s",
                    }}
                  >
                    <circle
                      cx="0"
                      cy="0"
                      r="6"
                      fill="#f7d038"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <animateTransform
                        attributeName="transform"
                        type="scale"
                        begin="-0.25s"
                        calcMode="spline"
                        keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                        values="0;1;0"
                        keyTimes="0;0.5;1"
                        dur="1s"
                        repeatCount="indefinite"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      ></animateTransform>
                    </circle>
                  </g>
                  <g
                    transform="translate(60 50)"
                    style={{
                      animationplaystate: "running",
                      animationdelay: "0s",
                    }}
                  >
                    <circle
                      cx="0"
                      cy="0"
                      r="6"
                      fill="#49da9a"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <animateTransform
                        attributeName="transform"
                        type="scale"
                        begin="-0.125s"
                        calcMode="spline"
                        keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                        values="0;1;0"
                        keyTimes="0;0.5;1"
                        dur="1s"
                        repeatCount="indefinite"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      ></animateTransform>
                    </circle>
                  </g>
                  <g
                    transform="translate(80 50)"
                    style={{
                      animationplaystate: "running",
                      animationdelay: "0s",
                    }}
                  >
                    <circle
                      cx="0"
                      cy="0"
                      r="6"
                      fill="#4355db"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <animateTransform
                        attributeName="transform"
                        type="scale"
                        begin="0s"
                        calcMode="spline"
                        keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                        values="0;1;0"
                        keyTimes="0;0.5;1"
                        dur="1s"
                        repeatCount="indefinite"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      ></animateTransform>
                    </circle>
                  </g>
                </svg>
              </div>
            ) : (
              <div className="modals-card-list">
                {this.state.data !== null ? (
                  this.state.data.map((row, i) => {
                    return (
                      <div
                        onClick={(e) => this.handleCard(e, row.id)}
                        className={`${
                          row.id === this.state.paymentIdOfRow
                            ? "billing-card-listback"
                            : ""
                        } form-check form-check-inline  billing-custom-radio-btn   billing-card-list`}
                      >
                        <img
                          src={
                            require("../../assets/images/dashboard/Billing/card-added.png")
                              .default
                          }
                          className="img-fluid card-add-img"
                          alt="nosubscription"
                        />
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id={`flexRadioDefault${i}`}
                          checked={
                            row.id === this.state.paymentIdOfRow ? true : false
                          }
                          // value={row.id}
                        />
                        <label
                          className="form-check-label form-credit-label billing-form-check-label"
                          for={`flexRadioDefault${i}`}
                        >
                          <div className=" billing-card-num">
                            {" "}
                            <p className="">
                              XXXX XXXX XXXX {row.card_last4_digit}
                            </p>
                            <span className="card-expire-date">
                              Expiry{" "}
                              {row.expiry_month > 9
                                ? row.expiry_month
                                : `0${row.expiry_month}`}
                              /{row.expiry_year}
                            </span>
                          </div>
                        </label>
                      </div>
                    );
                    // }
                  })
                ) : (
                  <div className="d-flex justify-content-center align-items-center no-card">
                    <img
                      src={
                        require("../../assets/images/dashboard/no-card.png")
                          .default
                      }
                      alt="no-card"
                    />
                  </div>
                )}
              </div>
            )}
            <button
              className={`btn secondary cus-secondary cus-secondary-redesign mob-done-button ${
                this.state.allowBtn === true ? "disable-card" : "disable-card"
              }`}
              disabled={this.state.allowBtn}
              onClick={(e) => this.changePaymentForStripe(e)}
            >
              <div className={this.state.cardDoneLoader}>
                <img src={this.state.cardLoaderImage} />
              </div>
              {this.state.cardBtnText}
            </button>
          </ModalBody>
          <ModalFooter className="justify-content-between cus-border-top  billing-card-footer">
            <button
              className="btn secondary  cus-secondary-redesign billing-cus-button"
              onClick={this.closeCardPopUp}
            >
              Cancel
            </button>
            <button
              className={`btn secondary  cus-secondary-redesign ${
                this.state.allowBtn === true ? "disable-card" : "disable-card"
              }`}
              disabled={this.state.allowBtn}
              onClick={(e) => this.changePaymentForStripe(e)}
            >
              <div className={this.state.cardDoneLoader}>
                <img src={this.state.cardLoaderImage} />
              </div>
              {this.state.cardBtnText}
            </button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
