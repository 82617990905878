import React from 'react'
//
const ProductDefaultImage = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="16"
    viewBox="0 0 22 16"
  >
    <g
      id="Group_42041"
      data-name="Group 42041"
      transform="translate(0.261 0.218)"
    >
      <path
        id="Polygon_58"
        data-name="Polygon 58"
        d="M5.5,0,11,7H0Z"
        transform="translate(-0.261 8.782)"
        fill="#cfcfcf"
        opacity="0.5"
      />
      <path
        id="Polygon_59"
        data-name="Polygon 59"
        d="M7.5,0,15,9H0Z"
        transform="translate(6.739 6.782)"
        fill="#cfcfcf"
      />
      <circle
        id="Ellipse_5321"
        data-name="Ellipse 5321"
        cx="2.5"
        cy="2.5"
        r="2.5"
        transform="translate(5.739 -0.218)"
        fill="#cfcfcf"
      />
    </g>
  </svg>
  )
}

export default ProductDefaultImage