import React, { lazy, Suspense, useEffect, useReducer } from "react";
import "./menuBuilder.css";
import { useDispatch } from "react-redux";
import Connectpopup from "../../../Dashboard/Reusable/Connectpopup.js";
import { errortoast, sucesstoast } from "../../../Dashboard/Toaster.js";
import Header from "../../../Dashboard/Header.js";
import Leftpanel from "../../../Dashboard/Leftpanel.js";
import {
  accordionDataUpdatation,
  MetaTitle,
} from "../../../utils/commonUtils.js";
import {
  useMenuDataQuery,
  useSaveMenuDataMutation,
} from "../../../services/menuApiSlice.js";
import {
  useTaxonomyDataQuery,
  useTypeDataQuery,
  useWebsiteMenuQuery,
} from "../../../services/userWebsiteApiSlice.js";
import ItemAccordian from "../../../Components/commonComponents/MenuBuilder/ItemAccordian.js";
import ModuleTitleTooltip from "../../../Components/commonIcons/ModuleTitleTooltip.js";
import {
  setTaxonomyData,
  setTypeData,
  setUserWebsiteMenuData,
} from "../../../Redux/slices/userWebsiteDataSlice.js";
import WebsiteMenu from "../../../Components/commonComponents/MenuBuilder/WebsiteMenu.js";
import AmsSpinner from "../../../Components/commonIcons/AmsSpinner.js";
import Tooltip from "../../../Components/commonIcons/Tooltip.js";
import ModuleNavigations from "../../../Components/commonComponents/ModuleNavigation/ModuleNavigations.js";
import SortingIcon from "../../../Components/commonIcons/SortingIcon.js";
import SettingIcon from "../../../Components/commonIcons/SettingIcon.js";
import HomeIcon from "../../../Components/commonIcons/HomeIcon.js";
import MenuPreview from "./MenuPreview.js";
import SaveButton from "../../../Components/commonComponents/SaveButton.js";
import UpdatedAt from "../../../Components/commonComponents/UpdatedAt.js";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import MenuSetting from "./MenuSetting.js";
import { useBottomDataQuery } from "../../../services/bottomApiSlice.js";
const MenuSorting = lazy(() => import("./MenuSorting.js"));

const MenuBuilder = () => {
  const dispatch = useDispatch();

  const [menuState, setMenuState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      spinner: true,
      loader: false,
      is_chat_enabled: 0,
      primaryMenuListingData: [],
      secondaryMenuListingData: [],
      wpMenuListingData: [],
      primarySelectedWPMenuValue: [],
      secondarySelectedWPMenuValue: [],
      openConnFailedPopUp: false,
      isMainMenuEnabled: true,
      isPrimaryMenuEnabled: false,
      isSecondaryMenuEnabled: false,
      isPrimaryMenuSyncWithWP: false,
      isSecondaryMenuSyncWithWP: false,
      is_verified: "",
      storeUrl: "",
      typesData: [],
      taxonomyData: [],
      defaultOptionData: [],
      openMenuSetting: false,
      openMenuSorting: false,
      updated_at: "",
      wordpressOptions: [
        {
          id: "home",
          item_name: "Home Screen",
          item_type: "home",
          is_tree: "",
        },
        {
          id: 0,
          item_name: "Pages",
          item_type: "pages",
          is_tree: "",
        },
        {
          id: 1,
          item_name: "Post Categories",
          item_type: "post_categories",
          is_tree: "",
        },
        {
          id: 2,
          item_name: "Profile",
          item_type: "profile",
          is_tree: "",
        },
        {
          id: 3,
          item_name: "Settings",
          item_type: "settings",
          is_tree: "",
        },
        {
          id: 4,
          item_name: "Email",
          item_type: "email",
          is_tree: "",
        },
        {
          id: 5,
          item_name: "Phone",
          item_type: "phone",
          is_tree: "",
        },
        {
          id: 6,
          item_name: "Share",
          item_type: "share",
          is_tree: "",
        },
        {
          id: 7,
          item_name: "Web View",
          item_type: "web_view",
          is_tree: "",
        },
      ],
      wooCommerceOptions: [
        {
          id: 1,
          item_name: "Cart",
          item_type: "cart",
          is_tree: "",
        },
        {
          id: 2,
          item_name: "Home Screen",
          item_type: "home",
          is_tree: "",
        },
        {
          id: 3,
          item_name: "Orders",
          item_type: "orders",
          is_tree: "",
        },
        {
          id: 4,
          item_name: "Profile",
          item_type: "profile",
          is_tree: "",
        },
        {
          id: 5,
          item_name: "Settings",
          item_type: "settings",
          is_tree: "",
        },
        {
          id: 6,
          item_name: "Email",
          item_type: "email",
          is_tree: "",
        },
        {
          id: 7,
          item_name: "Phone",
          item_type: "phone",
          is_tree: "",
        },
        {
          id: 8,
          item_name: "Share",
          item_type: "share",
          is_tree: "",
        },
        {
          id: 9,
          item_name: "Web View",
          item_type: "web_view",
          is_tree: "",
        },
      ],
      customAppOptions: [
        {
          id: 1,
          name: "Chat",
          type: "chat",
          post_type: "",
          rest_base: "",
          slug: "",

          is_tree: "",
        },
        {
          id: 2,
          name: "Profile",
          type: "profile",
          post_type: "",
          rest_base: "",
          slug: "",
          is_tree: "",
        },
        {
          id: 3,
          name: "Settings",
          type: "settings",
          post_type: "",
          rest_base: "",
          slug: "",

          is_tree: "",
        },
        {
          id: 4,
          name: "Email",
          type: "email",
          post_type: "",
          rest_base: "",
          slug: "",

          is_tree: "",
        },
        {
          id: 5,
          name: "Phone",
          type: "phone",
          post_type: "",
          rest_base: "",
          slug: "",

          is_tree: "",
        },
        {
          id: 6,
          name: "Share",
          type: "share",
          post_type: "",
          rest_base: "",
          slug: "",

          is_tree: "",
        },
        {
          id: 7,
          name: "Web View",
          type: "web_view",
          post_type: "",
          rest_base: "",
          slug: "",

          is_tree: "",
        },
      ],
      w2AppOptions: [
        {
          id: 0,
          item_name: "Home screen",
          item_type: "home",
          is_tree: "",
        },
        {
          id: 1,
          item_name: "Settings",
          item_type: "settings",
          is_tree: "",
        },
        {
          id: 2,
          item_name: "Web View",
          item_type: "web_view",
          is_tree: "",
        },
        {
          id: 3,
          item_name: "Chat",
          item_type: "chat",
          is_tree: "",
        },
        {
          id: 4,
          item_name: "Email",
          item_type: "email",
          is_tree: "",
        },
        {
          id: 5,
          item_name: "Phone",
          item_type: "phone",
          is_tree: "",
        },
        {
          id: 6,
          item_name: "Share",
          item_type: "share",
          is_tree: "",
        },
      ],
      linkArr: [
        {
          link: "/app/home",
          name: "Home screen",
          is_active: false,
          is_show:
            parseInt(localStorage.getItem("website_technology")) === 4 ||
            parseInt(localStorage.getItem("website_technology")) === 5
              ? true
              : false,
        },
        {
          link: "/app/bottom-bar",
          name: "Bottom bar",
          is_active: false,
          is_show: true,
        },
        {
          link: "/app/menu",
          name: "Menu",
          is_active: true,
          is_show: true,
        },
      ],
      isBottombarEnable: false,
      isAnyDefaultIteminBottombar: false,
      openDefaultItemValidationPopUp: false,
      openMainModuleValidationPopUp: false,
      openDefaultItemPopUp: false,
      menuIdForPopUp: 0,
      menuTypeForDefaultPopUp: "primary",
      appRightPanelExpand:
        localStorage.getItem("appSideBarCollapse") === true ||
        localStorage.getItem("appSideBarCollapse") === "true"
          ? true
          : false,

      settings: {
        isHapticEnabled: true,
        useLogoOrText: "text",
        headerLogo: "",
        headerTitle: "Menu",
        headerTextColor: "rgba(255,255,255,1)",
        showPrimaryMenuItemsIcon: true,
        showSecondaryMenuItemsIcon: true,
        showPrimaryMenuItemsAsGrid: false,
        showSecondaryMenuItemsAsGrid: false,
        showPrimaryMenuItemsText: true,
        showSecondaryMenuItemsText: true,
        primaryMenuItemsShape: "reactangular",
        secondaryMenuItemsShape: "reactangular",
        primaryMenuItemsGridCol: 2,
        secondaryMenuItemsGridCol: 2,
        primaryMenuItemsIconColor: "rgba(255,255,255,1)",
        secondaryMenuItemsIconColor: "rgba(255,255,255,1)",
        primaryMenuItemsIconBorderColor: "rgba(116,164,255,1)",
        secondaryMenuItemsIconBorderColor: "rgba(116,164,255,1)",
        primaryMenuItemsTextColor: "rgba(255,255,255,1)",
        secondaryMenuItemsTextColor: "rgba(255,255,255,1)",
        backgroundColor:
          parseInt(localStorage.getItem("website_technology")) === 2
            ? "linear-gradient(129deg, rgba(86, 158, 253,1) 0%, rgba(86, 132, 253,1) 50%, rgba(86, 119, 253,1) 100%)"
            : parseInt(localStorage.getItem("website_technology")) === 3
            ? "linear-gradient(129deg, rgba(75, 187, 245,1) 0%, rgba(94, 100, 255,1) 50%, rgba(165, 87, 255,1) 100%)"
            : parseInt(localStorage.getItem("website_technology")) === 4
            ? "linear-gradient(129deg, rgba(34, 176, 213,1) 0%, rgba(34, 157, 213,1) 50%, rgba(28, 146, 207,1) 100%)"
            : parseInt(localStorage.getItem("website_technology")) === 5
            ? "linear-gradient(129deg, rgba(153, 72, 207,1) 0%, rgba(142, 83, 214,1) 50%, rgba(135, 83, 214,1) 100%)"
            : "linear-gradient(129deg, rgba(50, 167, 255,1) 0%, rgba(90, 111, 251,1) 50%, rgba(119, 87, 255,1) 100%)",
        primaryMenuItemsIconBGcolor:
          "linear-gradient(129deg, rgba(50, 167, 255,1) 0%, rgba(90, 111, 251,1) 50%, rgba(119, 87, 255,1) 100%)",
        secondaryMenuItemsIconBGcolor:
          "linear-gradient(129deg, rgba(50, 167, 255,1) 0%, rgba(90, 111, 251,1) 50%, rgba(119, 87, 255,1) 100%)",
        separatorColor:
          parseInt(localStorage.getItem("website_technology")) === 2
            ? "rgba(141,177,255,1)"
            : parseInt(localStorage.getItem("website_technology")) === 3
            ? "rgba(163,156,255,1)"
            : parseInt(localStorage.getItem("website_technology")) === 4
            ? "rgba(70,184,238,1)"
            : parseInt(localStorage.getItem("website_technology")) === 5
            ? "rgba(176,118,241,1)"
            : "rgba(116,164,255,1)",
      },
    }
  );

  const { data: menuApiData, refetch: menuGetApiReftech } = useMenuDataQuery();
  const { data: bottomBarApiData, refetch: bottomBarGetApiReftech } =
    useBottomDataQuery();

  const { data: apiTypeData } = useTypeDataQuery(
    { storeUrl: menuState.storeUrl },
    {
      skip:
        !menuState.storeUrl ||
        parseInt(localStorage.getItem("website_technology")) === 2,
    }
  );

  const { data: apiTaxonomyData } = useTaxonomyDataQuery(
    { storeUrl: menuState.storeUrl },
    {
      skip:
        !menuState.storeUrl ||
        parseInt(localStorage.getItem("website_technology")) === 2,
    }
  );

  const { data: apiWebsiteMenuList } = useWebsiteMenuQuery(
    { storeUrl: menuState.storeUrl },
    { skip: !menuState.storeUrl }
  );

  useEffect(() => {
    if (apiTaxonomyData) {
      let taxnomyArray = [];

      if (typeof apiTaxonomyData === "object" && apiTaxonomyData) {
        taxnomyArray = Object.keys(apiTaxonomyData).map(
          (key) => apiTaxonomyData[key]
        );
      }

      let currObj = { ...menuState };
      currObj.taxonomyData = taxnomyArray;
      setMenuState(currObj);

      dispatch(setTaxonomyData(taxnomyArray));
    }
  }, [apiTaxonomyData]);

  useEffect(() => {
    if (apiTypeData) {
      let typeArray = [];

      if (typeof apiTypeData === "object" && apiTypeData !== null) {
        typeArray = Object.keys(apiTypeData).map((key) => apiTypeData[key]);
      }

      let currObj = { ...menuState };
      currObj.typesData = typeArray;
      setMenuState(currObj);
      dispatch(setTypeData(typeArray));
    }
  }, [apiTypeData]);

  useEffect(() => {
    if (apiWebsiteMenuList) {
      let currObj = { ...menuState };
      currObj.wpMenuListingData = apiWebsiteMenuList;
      setMenuState(currObj);
      dispatch(setUserWebsiteMenuData(apiWebsiteMenuList));
    }
  }, [apiWebsiteMenuList]);

  const [createMenuData, { menuDataData }] = useSaveMenuDataMutation();

  const saveMenuPostApi = (params) => {
    createMenuData(params)
      .unwrap()
      .then((res) => {
        if (res.code === 200) {
          setMenuState({
            loader: false,
            updated_at: res.data.updated_at,
          });
          sucesstoast("The record has been saved.");
          menuGetApiReftech();
          bottomBarGetApiReftech();
        } else {
          errortoast("Oops! Something went wrong.");
          setMenuState({
            loader: false,
          });
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = MetaTitle("Menu");
    settingMenuListingData();
    if (parseInt(localStorage.getItem("website_technology")) === 3) {
      setMenuState({ defaultOptionData: menuState.customAppOptions });
    } else if (parseInt(localStorage.getItem("website_technology")) === 2) {
      setMenuState({ defaultOptionData: menuState.w2AppOptions });
    } else {
      if (parseInt(localStorage.getItem("website_technology")) === 5) {
        setMenuState({ defaultOptionData: menuState.wooCommerceOptions });
      } else if (parseInt(localStorage.getItem("website_technology")) === 4) {
        setMenuState({ defaultOptionData: menuState.wordpressOptions });
      }
    }
  }, [menuApiData]);

  const handleRightPanelExpand = (value) => {
    setMenuState({
      appRightPanelExpand: value,
    });
  };

  const traverseMenuTreeStructure = (menuArr, from, startId = 0) => {
    let currentId = startId;

    const recursTraverse = (arr) => {
      return (
        arr &&
        arr.map((item) => {
          const newItem = {
            ...item,
            id: Math.floor(Math.random() * 2000) + currentId,
            open_item: false,
            item_position: currentId,
            breadcrumbArr: [],
            module_label: "",
            showL1Options: true,
            subData: [],
            subdropdownData: [],
            subdropdownSpinner: false,
            item_is_default: item.item_is_default || 0,
            temp_breadcrumbArr: [],
            temp_individual_selected_item_id: item.individual_selected_item_id,
            temp_item_type: item.item_type,
            temp_rest_base: item.rest_base,
            temp_slug: item.slug,
            temp_post_type: item.post_type,
            temp_module_label: "",
            temp_showL1Options: true,
            temp_subData: [],
            text: item.item_name,
          };
          currentId += 1;

          if (from === "custom_pro") {
            newItem.children = [];
            newItem.text = newItem.item_name;
            newItem.id = Math.floor(Math.random() * 2000) + currentId;
            newItem.item_icon_flaticon_color = "";
            newItem.item_icon_newtype = "";
          }

          if (newItem.children && newItem.children.length) {
            newItem.children = recursTraverse(newItem.children);
          }

          return newItem;
        })
      );
    };

    return recursTraverse(menuArr);
  };

  const addingSecondaryDefaultMenu = (
    currSecondaryMenuData,
    status,
    item_name,
    value,
    item_icon,
    position,
    type
  ) => {
    let defaultItem = {
      selected_ids: [],
      status: status,
      is_default: 0,
      item_position: position,
      id: Math.floor(Math.random() * 2000) + position,
      item_name: item_name,
      item_type: type,
      item_icon: item_icon,
      item_icon_type: "",
      item_icon_flaticon_color: "",
      item_icon_newtype: "",
      rest_base: "",
      slug: "",
      post_type: "",
      item_title: item_name,
      individual_selected_item_id: "",
      selected_item_name: "",
      last_item: "",
      value: value,
      children: [],
      text: item_name,
      open_item: false,
      breadcrumbArr: [],
      module_label: "",
      showL1Options: true,
      subData: [],
      subdropdownData: [],
      subdropdownSpinner: false,
      temp_breadcrumbArr: [],
      temp_individual_selected_item_id: "",
      temp_item_type: type,
      temp_rest_base: "",
      temp_slug: "",
      temp_post_type: "",
      temp_module_label: "",
      temp_showL1Options: true,
      temp_subData: [],
    };
    currSecondaryMenuData.push(defaultItem);
  };

  const settingMenuListingData = () => {
    let currObj = { ...menuState };
    let currSettings = { ...menuState.settings };
    if (menuApiData) {
      if (menuApiData.code === 200) {
        currSettings.isHapticEnabled =
          menuApiData?.data?.settings &&
          (menuApiData?.data?.settings?.enable_haptic_on_touch === 0 ||
            menuApiData?.data?.settings?.enable_haptic_on_touch === 1)
            ? !!menuApiData?.data?.settings?.enable_haptic_on_touch
            : false; // Default value is OFF for old apps where enable_haptic_on_touch key is not present

        currObj.is_chat_enabled = menuApiData.is_chat_enabled;
        currObj.isBottombarEnable = menuApiData.enable_bottom_menu;
        currObj.isAnyDefaultIteminBottombar =
          menuApiData.is_any_default_item_in_bottom_menu;
        if (menuApiData.data.primary_menu || menuApiData.data.secondary_menu) {
          let currPrimaryMenuData = menuApiData.data.primary_menu;

          currPrimaryMenuData = traverseMenuTreeStructure(
            currPrimaryMenuData,
            ""
          );
          currObj.primaryMenuListingData = currPrimaryMenuData || [];

          let currSecondaryMenuData = menuApiData.data.secondary_menu;

          currSecondaryMenuData = traverseMenuTreeStructure(
            currSecondaryMenuData,
            ""
          );
          currObj.secondaryMenuListingData = currSecondaryMenuData || [];

          currObj.isPrimaryMenuSyncWithWP =
            menuApiData.data.menu_type === "default" ? true : false;
          currObj.isPrimaryMenuEnabled = menuApiData.data.primary_menu_status
            ? true
            : false;
          currObj.isSecondaryMenuEnabled = menuApiData.data
            .secondary_menu_status
            ? true
            : false;
          currObj.default_menus =
            menuApiData.data.menu_type === "default"
              ? menuApiData.data.selected_default_menu
              : [];
          currObj.primarySelectedWPMenuValue =
            menuApiData.data.selected_default_menu;

          currObj.isSecondaryMenuSyncWithWP =
            menuApiData.data.secondary_menu_type === "default" ? true : false;
          currObj.secondarySelectedWPMenuValue = menuApiData.data
            .default_secondary_menu
            ? menuApiData.data.default_secondary_menu.filter((item) => item)
            : "";
          currObj.spinner = false;
          currObj.isMainMenuEnabled = menuApiData.data.enable_menu
            ? true
            : false;
          currObj.is_verified = menuApiData.app_data.is_verified;
          currObj.plugin_dataactive = menuApiData.app_data.is_connected;
          currObj.storeUrl = menuApiData.app_data.website_url;
          currObj.updated_at = menuApiData.data.updated_at;
          currSettings.headerTitle = menuApiData.data.settings?.menu_text || "";
          currSettings.headerLogo = menuApiData.data.settings?.menu_logo || "";
          currSettings.useLogoOrText =
            menuApiData.data.settings?.menu_bar_type || "";

          currSettings.headerTextColor =
            menuApiData.data.settings?.menu_text_colour_object?.portal_data ||
            "rgba(255,255,255,1)";
          currSettings.separatorColor =
            menuApiData.data.settings?.menu_border_colour_object?.portal_data ||
            "rgba(60,153,254,1)";
          currSettings.backgroundColor =
            menuApiData.data.settings?.menu_bg_colour_object?.portal_data ||
            "linear-gradient(129deg, rgba(50, 167, 255,1) 0%, rgba(90, 111, 251,1) 50%, rgba(119, 87, 255,1) 100%)";
          currSettings.primaryMenuItemsIconBGcolor =
            menuApiData.data.settings?.primary_menu_grid_bg_colour_object
              ?.portal_data ||
            "linear-gradient(129deg, rgba(50, 167, 255,1) 0%, rgba(90, 111, 251,1) 50%, rgba(119, 87, 255,1) 100%)";
          currSettings.primaryMenuItemsIconBorderColor =
            menuApiData.data.settings?.primary_menu_grid_border_colour_object
              ?.portal_data || "rgba(60,153,254,1)";
          currSettings.primaryMenuItemsGridCol =
            menuApiData.data.settings?.primary_menu_grid_column || 2;
          currSettings.primaryMenuItemsShape =
            menuApiData.data.settings?.primary_menu_icon_shape || "circle";

          currSettings.showPrimaryMenuItemsAsGrid = menuApiData.data.settings
            ? menuApiData.data.settings.primary_menu_show_grid_layout === 1
            : false;
          currSettings.showPrimaryMenuItemsIcon = menuApiData.data.settings
            ? menuApiData.data.settings.primary_menu_show_icons === 1
            : true;
          currSettings.showPrimaryMenuItemsText = menuApiData.data.settings
            ? menuApiData.data.settings.primary_menu_show_text === 1
            : true;

          currSettings.primaryMenuItemsIconColor =
            menuApiData.data.settings?.primary_menu_icon_color_object
              ?.portal_data || "rgba(255,255,255,1)";
          currSettings.primaryMenuItemsTextColor =
            menuApiData.data.settings?.primary_menu_text_color_object
              ?.portal_data || "rgba(255,255,255,1)";
          currSettings.secondaryMenuItemsIconBGcolor =
            menuApiData.data.settings?.secondary_menu_grid_bg_color_object
              ?.portal_data ||
            "linear-gradient(129deg, rgba(50, 167, 255,1) 0%, rgba(90, 111, 251,1) 50%, rgba(119, 87, 255,1) 100%)";
          currSettings.secondaryMenuItemsIconBorderColor =
            menuApiData.data.settings?.secondary_menu_grid_border_color_object
              ?.portal_data || "rgba(60,153,254,1)";
          currSettings.secondaryMenuItemsIconColor =
            menuApiData.data.settings?.secondary_menu_icon_color_object
              ?.portal_data || "rgba(255,255,255,1)";
          currSettings.secondaryMenuItemsTextColor =
            menuApiData.data.settings?.secondary_menu_text_color_object
              ?.portal_data || "rgba(255,255,255,1)";
          currSettings.secondaryMenuItemsGridCol =
            menuApiData.data.settings?.secondary_menu_grid_column || 2;
          currSettings.secondaryMenuItemsShape =
            menuApiData.data.settings?.secondary_menu_icon_shape || "circle";

          currSettings.showSecondaryMenuItemsAsGrid = menuApiData.data.settings
            ? menuApiData.data.settings.secondary_menu_show_grid_layout === 1
            : false;

          currSettings.showSecondaryMenuItemsIcon = menuApiData.data.settings
            ? menuApiData.data.settings.secondary_menu_show_icons === 1
            : true;

          currSettings.showSecondaryMenuItemsText = menuApiData.data.settings
            ? menuApiData.data.settings.secondary_menu_show_text === 1
            : true;
        } else {
          currObj.wpMenuListingData = !menuApiData.data.menus
            ? []
            : menuApiData.data.menus;

          let currPrimaryMenuData = menuApiData.data.selected_custom_pro_menu;

          currPrimaryMenuData = traverseMenuTreeStructure(
            currPrimaryMenuData,
            "custom_pro"
          );
          currObj.primaryMenuListingData = currPrimaryMenuData || [];
          currObj.primarySelectedWPMenuValue =
            menuApiData.data.selected_default_menu;
          currObj.isPrimaryMenuEnabled =
            menuApiData.data.menu_type === "custom_pro" ||
            menuApiData.data.menu_type === "default"
              ? true
              : false;
          currObj.isPrimaryMenuSyncWithWP =
            menuApiData.data.menu_type === "default" ? true : false;
          currObj.isMainMenuEnabled = menuApiData.data.menu_type ? true : false;
          currObj.spinner = false;
          currObj.is_verified = menuApiData.app_data.is_verified;
          currObj.plugin_dataactive = menuApiData.app_data.is_connected;
          currObj.storeUrl = menuApiData.app_data.website_url;
          currObj.updated_at = menuApiData.data.updated_at;
          currObj.isSecondaryMenuEnabled = menuApiData.general_data
            ? menuApiData.general_data.enable_customer_information_bool === 1
            : true;

          //setting secondary menu default options
          if (
            menuApiData.general_data?.enable_business_email_bool &&
            menuApiData.general_data?.business_email_title
          ) {
            addingSecondaryDefaultMenu(
              currObj.secondaryMenuListingData,
              menuApiData.general_data.enable_business_email_bool,
              menuApiData.general_data.business_email_title,
              menuApiData.general_data.business_email_value ||
                menuApiData.app_data.business_email,
              menuApiData.general_data.business_email_icon,
              0,
              "email"
            );
          }
          if (
            menuApiData.general_data?.enable_business_phone_bool &&
            menuApiData.general_data?.business_phone_title
          ) {
            addingSecondaryDefaultMenu(
              currObj.secondaryMenuListingData,
              menuApiData.general_data.enable_business_phone_bool,
              menuApiData.general_data.business_phone_title,
              menuApiData.general_data.business_phone_value ||
                menuApiData.app_data.business_phone,
              menuApiData.general_data.business_phone_icon,
              1,
              "phone"
            );
          }
          if (
            menuApiData.general_data?.enable_share_app_bool &&
            menuApiData.general_data?.share_app_title
          ) {
            addingSecondaryDefaultMenu(
              currObj.secondaryMenuListingData,
              menuApiData.general_data.enable_share_app_bool,
              menuApiData.general_data.share_app_title,
              menuApiData.general_data.share_app_value,
              menuApiData.general_data.share_app_icon,
              2,
              "share"
            );
          }
        }
      } else if (menuApiData.code === 201) {
        currObj.spinner = false;
        currObj.is_verified = menuApiData.app_data.is_verified;
        currObj.plugin_dataactive = menuApiData.app_data.plugin_data;
      }
    }
    currObj.settings = currSettings;
    setMenuState(currObj);
  };

  const handleAddItem = (e, type) => {
    let currMenuListingData = [];

    if (type === "primary") {
      currMenuListingData = [...menuState.primaryMenuListingData];
    } else {
      currMenuListingData = [...menuState.secondaryMenuListingData];
    }

    let item = {
      children: [],
      selected_ids: [],
      status: 1,
      is_default: 1,
      is_tree: 0,
      item_position: 0,
      item_name: "Menu Item",
      text: "Menu Item",
      item_type: "",
      item_icon: "",
      item_icon_type: "",
      item_icon_flaticon_color: "",
      item_icon_newtype: "",
      rest_base: "",
      slug: "",
      temp_rest_base: "",
      temp_slug: "",
      post_type: "",
      temp_post_type: "",
      item_title: "",
      individual_selected_item_id: "",
      selected_item_name: "",
      last_item: "",
      id: 0,
      temp_item_type: "",
      module_label: "",
      temp_module_label: "",
      temp_individual_selected_item_id: "",
      temp_subData: [],
      subData: [],
      temp_breadcrumbArr: [],
      breadcrumbArr: [],
      showL1Options: true,
      temp_showL1Options: true,
      subdropdownSpinner: false,
      subdropdownData: [],
    };

    currMenuListingData.push(item);

    currMenuListingData = traverseMenuTreeStructure(currMenuListingData, "");
    if (type === "primary") {
      setMenuState({
        primaryMenuListingData: currMenuListingData,
      });
    } else {
      setMenuState({
        secondaryMenuListingData: currMenuListingData,
      });
    }
  };

  const handlePrimarySyncWebsiteToggle = (e) => {
    if (!menuState.is_verified) {
      setMenuState({ openConnFailedPopUp: true });
    } else {
      setMenuState({
        isPrimaryMenuSyncWithWP: e.target.checked,
      });
      unCheckedDefaultItems("primary");
    }
  };

  const handleSecondrySyncWebsiteToggle = (e) => {
    if (!menuState.is_verified) {
      setMenuState({ openConnFailedPopUp: true });
    } else {
      setMenuState({
        isSecondaryMenuSyncWithWP: e.target.checked,
      });
      unCheckedDefaultItems("secondary");
    }
  };

  const unCheckedDefaultItems = (menuType) => {
    function recursForMenuData(arr) {
      arr.map((traverseItem) => {
        traverseItem.item_is_default = 0;
        if (traverseItem.children && traverseItem.children.length) {
          recursForMenuData(traverseItem.children);
        }
      });
    }
    if (menuType === "primary") {
      let currArr = [...menuState.primaryMenuListingData];
      recursForMenuData(currArr);
      setMenuState({
        primaryMenuListingData: currArr,
      });
    } else {
      let currArr = [...menuState.secondaryMenuListingData];
      recursForMenuData(currArr);
      setMenuState({
        secondaryMenuListingData: currArr,
      });
    }
  };

  const handlePrimaryMenuToggleChange = (e) => {
    setMenuState({
      isPrimaryMenuEnabled: e.target.checked,
    });
    unCheckedDefaultItems("primary");
    if (!e.target.checked) {
      setMenuState({
        isMainMenuEnabled: !menuState.isSecondaryMenuEnabled
          ? false
          : menuState.isMainMenuEnabled,
      });
    }
  };

  const handleSecondaryMenuToggleChange = (e) => {
    setMenuState({
      isSecondaryMenuEnabled: e.target.checked,
    });
    if (!e.target.checked) {
      setMenuState({
        isSecondaryMenuSyncWithWP: false,
        isMainMenuEnabled: !menuState.isPrimaryMenuEnabled
          ? false
          : menuState.isMainMenuEnabled,
      });
    }
    unCheckedDefaultItems("secondary");
  };

  const handlePrimaryWebsiteMenuChange = (e, key) => {
    const currVal = [key];

    setMenuState({
      primarySelectedWPMenuValue: currVal,
    });
  };

  const handleSecondaryWebsiteMenuChange = (e, key) => {
    const currVal = [key];

    setMenuState({
      secondarySelectedWPMenuValue: currVal,
    });
  };

  const handleEnableMainMenu = (e) => {
    // if (!menuState.isAnyDefaultIteminBottombar && !e.target.checked) {
    //   setMenuState({
    //     openMainModuleValidationPopUp: !menuState.openMainModuleValidationPopUp,
    //   });
    // } else {
      setMenuState({ isMainMenuEnabled: e.target.checked });

      if (!e.target.checked) {
        unCheckedDefaultItems("primary");
        unCheckedDefaultItems("secondary");

        setMenuState({
          isPrimaryMenuEnabled: false,
          isPrimaryMenuSyncWithWP: false,
          isSecondaryMenuEnabled: false,
        });
      } else {
        setMenuState({
          isPrimaryMenuEnabled: true,
          // isSecondaryMenuEnabled: true,
        });
      }
    // }
  };

  const handleSettingBtn = () => {
    setMenuState({
      openMenuSetting: !menuState.openMenuSetting,
      openMenuSorting: false,
    });
  };

  const handleHomeBtn = () => {
    setMenuState({
      openMenuSetting: false,
      openMenuSorting: false,
    });
  };

  const handleSortingBtn = () => {
    setMenuState({
      openMenuSorting: !menuState.openMenuSorting,
      openMenuSetting: false,
    });
  };

  const handleSaveMenu = (e) => {
    e.preventDefault();

    const recursiveValidationTraverse = (currArr, from) => {
      return (
        currArr &&
        currArr.map((item) => {
          if (from === "primary") {
            if (!item.item_type) {
              isItemTypeErrorForPrMenu = true;
            }
            if (item.item_is_default === 1) {
              isDefaultItemPresent = true;
            }
            if (!item.item_name?.trim()) {
              isItemNameError = true;
            }
            if (item.status) {
              isAllItemsHiddenInPriMenu = false;
            }
            if (
              (item.item_type === "email" ||
                item.item_type === "phone" ||
                item.item_type === "share") &&
              !item.value?.trim()
            ) {
              emailCallShareErrForPriMenu = true;
            }
            if (
              menuState.isPrimaryMenuEnabled &&
              item.item_type === "web_view"
            ) {
              if (!item.web_view_url) {
                webViewMtUrlErr = true;
              }
              if (!recheck.test("https://" + item.web_view_url)) {
                inValidUrl = true;
              }
            }
            if (item.status && item.item_type !== "web_view") {
              if (item.web_view_settings) {
                delete item["web_view_settings"];
              }
              if (item.web_view_url) {
                delete item["web_view_url"];
              }
            }
          } else {
            if (!item.item_type) {
              isItemTypeErrorForScMenu = true;
            }
            if (item.item_is_default === 1) {
              isDefaultItemPresent = true;
            }
            if (!item.item_name?.trim()) {
              isItemNameError = true;
            }
            if (item.status) {
              isAllItemsHiddenInSecMenu = false;
            }
            if (
              (item.item_type === "email" ||
                item.item_type === "phone" ||
                item.item_type === "share") &&
              !item.value?.trim()
            ) {
              emailCallShareErrForSecMenu = true;
            }
            if (
              menuState.isSecondaryMenuEnabled &&
              item.item_type === "web_view"
            ) {
              if (!item.web_view_url) {
                webViewMtUrlErr = true;
              }
              if (!recheck.test("https://" + item.web_view_url)) {
                inValidUrl = true;
              }
            }
            if (item.status && item.item_type !== "web_view") {
              if (item.web_view_settings) {
                delete item["web_view_settings"];
              }
              if (item.web_view_url) {
                delete item["web_view_url"];
              }
            }
          }

          if (item.children && item.children.length) {
            recursiveValidationTraverse(item.children, from);
          }
          return item;
        })
      );
    };

    let isItemTypeErrorForPrMenu = false;
    let isItemTypeErrorForScMenu = false;
    let isItemNameError = false;
    let webViewMtUrlErr = false;
    let inValidUrl = false;
    let emailCallShareErrForPriMenu = false;
    let emailCallShareErrForSecMenu = false;
    let isDefaultItemPresent = false;
    let isAllItemsHiddenInPriMenu = true;
    let isAllItemsHiddenInSecMenu = true;
    let recheck =
      /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;

    setMenuState({
      primaryMenuListingData: recursiveValidationTraverse(
        menuState.primaryMenuListingData,
        "primary"
      ),
    });

    setMenuState({
      secondaryMenuListingData: recursiveValidationTraverse(
        menuState.secondaryMenuListingData,
        "secondary"
      ),
    });

    if (
      isItemTypeErrorForPrMenu &&
      menuState.isPrimaryMenuEnabled &&
      !menuState.isPrimaryMenuSyncWithWP
    ) {
      errortoast("Link is a required field.");
    } else if (
      isItemTypeErrorForScMenu &&
      menuState.isSecondaryMenuEnabled &&
      !menuState.isSecondaryMenuSyncWithWP
    ) {
      errortoast("Link is a required field.");
    } else if (isItemNameError) {
      errortoast("Text is a required field.");
    } else if (webViewMtUrlErr) {
      errortoast("URL is a required field.");
    } else if (inValidUrl) {
      errortoast("Enter a valid URL.");
    } else if (
      menuState.isPrimaryMenuEnabled &&
      menuState.isPrimaryMenuSyncWithWP &&
      !menuState.primarySelectedWPMenuValue.length
    ) {
      errortoast("Please select atleast one Primary Menu Option to proceed");
    } else if (
      menuState.isSecondaryMenuEnabled &&
      menuState.isSecondaryMenuSyncWithWP &&
      !menuState.secondarySelectedWPMenuValue.length
    ) {
      errortoast("Please select atleast one Secondary Menu Option to proceed");
    } else if (
      menuState.isPrimaryMenuSyncWithWP &&
      menuState.isPrimaryMenuEnabled &&
      !menuState.primarySelectedWPMenuValue.length &&
      !menuState.primaryMenuListingData.length
    ) {
      errortoast("Please select atleast one Menu Option to proceed");
    } else if (
      menuState.isSecondaryMenuSyncWithWP &&
      menuState.isSecondaryMenuEnabled &&
      !menuState.secondarySelectedWPMenuValue.length &&
      !menuState.secondaryMenuListingData.length
    ) {
      errortoast("Please select atleast one Menu Option to proceed");
    } else if (
      menuState.isPrimaryMenuEnabled &&
      (!menuState.primaryMenuListingData.length || isAllItemsHiddenInPriMenu) &&
      !menuState.isPrimaryMenuSyncWithWP
    ) {
      errortoast("Please add atleast one Primary Menu Item to proceed");
    } else if (
      menuState.isSecondaryMenuEnabled &&
      (!menuState.secondaryMenuListingData.length ||
        isAllItemsHiddenInSecMenu) &&
      !menuState.isSecondaryMenuSyncWithWP
    ) {
      errortoast("Please add atleast one Secondary Menu Item to proceed");
    } else if (
      emailCallShareErrForPriMenu &&
      menuState.isMainMenuEnabled &&
      menuState.isPrimaryMenuEnabled &&
      !menuState.isPrimaryMenuSyncWithWP
    ) {
      errortoast("Value is a required field.");
    } else if (
      emailCallShareErrForSecMenu &&
      menuState.isMainMenuEnabled &&
      menuState.isSecondaryMenuEnabled &&
      !menuState.isSecondaryMenuSyncWithWP
    ) {
      errortoast("Value is a required field.");
    } else if (
      !isDefaultItemPresent &&
      !menuState.isAnyDefaultIteminBottombar
      //  &&
      // ( (!menuState.isPrimaryMenuSyncWithWP && menuState.isPrimaryMenuEnabled) ||
      //   (!menuState.isSecondaryMenuSyncWithWP && menuState.isSecondaryMenuEnabled)
      // )
    ) {
      setMenuState({
        openDefaultItemPopUp: !menuState.openDefaultItemPopUp,
      });
    } else {
      const settings = {
        menu_type: menuState.settings.useLogoOrText || "",
        menu_logo: menuState.settings.headerLogo || "",
        menu_text: menuState.settings.headerTitle || "",
        menu_bar_type: menuState.settings.useLogoOrText || "",
        menu_text_color:
          menuState.settings.headerTextColor || "rgba(255,255,255,1)",
        menu_background_color:
          menuState.settings.backgroundColor ||
          "linear-gradient(129deg, rgba(50, 167, 255,1) 0%, rgba(90, 111, 251,1) 50%, rgba(119, 87, 255,1) 100%)",

        menu_border_color:
          menuState.settings.separatorColor || "rgba(116,164,255,1)",

        primary_menu_show_grid_layout: menuState.settings
          .showPrimaryMenuItemsAsGrid
          ? 1
          : 0,
        primary_menu_show_icons: menuState.settings.showPrimaryMenuItemsIcon
          ? 1
          : 0,
        primary_menu_show_text: menuState.settings.showPrimaryMenuItemsText
          ? 1
          : 0,
        primary_menu_icon_shape:
          menuState.settings.primaryMenuItemsShape || "circle",

        primary_menu_grid_column:
          menuState.settings.primaryMenuItemsGridCol || 2,

        primary_menu_icon_color:
          menuState.settings.primaryMenuItemsIconColor || "rgba(255,255,255,1)",
        primary_menu_text_color:
          menuState.settings.primaryMenuItemsTextColor || "rgba(255,255,255,1)",

        primary_menu_grid_background_color:
          menuState.settings.primaryMenuItemsIconBGcolor ||
          "linear-gradient(129deg, rgba(50, 167, 255,1) 0%, rgba(90, 111, 251,1) 50%, rgba(119, 87, 255,1) 100%)",
        primary_menu_grid_border_color:
          menuState.settings.primaryMenuItemsIconBorderColor ||
          "rgba(116,164,255,1)",
        secondary_menu_show_grid_layout: menuState.settings
          .showSecondaryMenuItemsAsGrid
          ? 1
          : 0,
        secondary_menu_show_icons: menuState.settings.showSecondaryMenuItemsIcon
          ? 1
          : 0,
        secondary_menu_show_text: menuState.settings.showSecondaryMenuItemsText
          ? 1
          : 0,
        secondary_menu_icon_shape:
          menuState.settings.secondaryMenuItemsShape || "circle",
        secondary_menu_grid_column:
          menuState.settings.secondaryMenuItemsGridCol || 2,
        secondary_menu_icon_color:
          menuState.settings.secondaryMenuItemsIconColor ||
          "rgba(255,255,255,1)",
        secondary_menu_text_color:
          menuState.settings.secondaryMenuItemsTextColor ||
          "rgba(255,255,255,1)",
        secondary_menu_grid_background_color:
          menuState.settings.secondaryMenuItemsIconBGcolor ||
          "linear-gradient(129deg, rgba(50, 167, 255,1) 0%, rgba(90, 111, 251,1) 50%, rgba(119, 87, 255,1) 100%)",
        secondary_menu_grid_border_color:
          menuState.settings.secondaryMenuItemsIconBorderColor ||
          "rgba(116,164,255,1)",
        enable_haptic_on_touch: +menuState.settings.isHapticEnabled,
      };

      const params = {
        app_id: localStorage.getItem("Appid"),
        menu_type: menuState.isPrimaryMenuSyncWithWP ? "default" : "custom_pro",
        secondary_menu_type: menuState.isSecondaryMenuSyncWithWP
          ? "default"
          : "custom_pro",
        primary_menu_status: menuState.isPrimaryMenuEnabled ? 1 : 0,
        secondary_menu_status: menuState.isSecondaryMenuEnabled ? 1 : 0,
        default_menus: menuState.isPrimaryMenuSyncWithWP
          ? menuState.primarySelectedWPMenuValue
          : [],
        default_secondary_menu: menuState.isSecondaryMenuSyncWithWP
          ? menuState.secondarySelectedWPMenuValue
          : [],
        primary_menus: menuState.primaryMenuListingData,
        secondary_menus: menuState.secondaryMenuListingData,
        enable_menu: menuState.isMainMenuEnabled ? 1 : 0,
        settings: settings,
      };

      let pMenu = JSON.parse(JSON.stringify(params.primary_menus));
      let sMenu = JSON.parse(JSON.stringify(params.secondary_menus));
      function recursForMenuData(arr) {
        arr.map((eachPage) => {
          delete eachPage["breadcrumbArr"];
          delete eachPage["module_label"];
          delete eachPage["showL1Options"];
          delete eachPage["subData"];
          delete eachPage["subdropdownData"];
          delete eachPage["subdropdownSpinner"];
          delete eachPage["temp_breadcrumbArr"];
          delete eachPage["temp_individual_selected_item_id"];
          delete eachPage["temp_item_type"];
          delete eachPage["temp_module_label"];
          delete eachPage["temp_showL1Options"];
          delete eachPage["temp_subData"];
          delete eachPage["temp_rest_base"];
          delete eachPage["temp_slug"];
          delete eachPage["temp_post_type"];
          delete eachPage["open_item"];

          if (eachPage.children && eachPage.children.length) {
            recursForMenuData(eachPage.children);
          }

          // return arr;
        });
      }

      recursForMenuData(pMenu);

      recursForMenuData(sMenu);

      params.primary_menus = pMenu;
      params.secondary_menus = sMenu;

      setMenuState({
        loader: true,
      });
      saveMenuPostApi(params);
    }
  };

  const handleConnFailedPopup = (e) => {
    setMenuState({ openConnFailedPopUp: false });
  };

  const settingDefaultItemVal = (value, menuId, from) => {
    let primaryMenuId = "";
    let secondaryMenuId = "";
    let currPrimaryArr = [...menuState.primaryMenuListingData];
    let currSecondaryArr = [...menuState.secondaryMenuListingData];

    setMenuState({
      isAnyDefaultIteminBottombar: false,
    });

    if (from === "primary") {
      primaryMenuId = menuId;
      secondaryMenuId = "";
      currPrimaryArr = accordionDataUpdatation(
        currPrimaryArr,
        primaryMenuId,
        "updating_default_item",
        value
      );
      if (value) {
        currSecondaryArr = accordionDataUpdatation(
          currSecondaryArr,
          secondaryMenuId,
          "updating_default_item",
          false
        );
      }
    } else {
      primaryMenuId = "";
      secondaryMenuId = menuId;
      currSecondaryArr = accordionDataUpdatation(
        currSecondaryArr,
        secondaryMenuId,
        "updating_default_item",
        value
      );
      if (value) {
        currPrimaryArr = accordionDataUpdatation(
          currPrimaryArr,
          primaryMenuId,
          "updating_default_item",
          false
        );
      }
    }

    setMenuState({
      primaryMenuListingData: currPrimaryArr,
      secondaryMenuListingData: currSecondaryArr,
    });
  };

  const handleDefaultItem = (e, menuId, from) => {
    if (e.target.checked && menuState.isAnyDefaultIteminBottombar) {
      setMenuState({
        openDefaultItemValidationPopUp:
          !menuState.openDefaultItemValidationPopUp,
      });
    } else {
      settingDefaultItemVal(e.target.checked, menuId, from);
    }

    setMenuState({
      menuIdForPopUp: menuId,
      menuTypeForDefaultPopUp: from,
    });
  };

  const handleOpenDefaultItemPopUp = (e) => {
    setMenuState({
      openDefaultItemPopUp: !menuState.openDefaultItemPopUp,
    });
  };

  const handleOpenItemValidationPopUp = (e, from) => {
    if (from === "confirm") {
      settingDefaultItemVal(
        true,
        menuState.menuIdForPopUp,
        menuState.menuTypeForDefaultPopUp
      );
    }

    setMenuState({
      openDefaultItemValidationPopUp: !menuState.openDefaultItemValidationPopUp,
    });
  };

  const handleOpenMainModuleValidationPopUp = (e) => {
    setMenuState({
      openMainModuleValidationPopUp: !menuState.openMainModuleValidationPopUp,
    });
  };

  return (
    <>
      <Header customclass={"header-responsive-new"} />
      <section className="dasboard_page bottombar-module mobile-display">
        <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />

        <>
          <div
            className={`right-panel-gap ${
              localStorage.getItem("appSideBarCollapse") === "true"
                ? " right-panel-gap-expand"
                : ""
            }`}
          >
            <div className="menu-parentDiv">
              {window.matchMedia("(min-width: 1200px)").matches && (
                <ModuleTitleTooltip
                  title={"Navigation"}
                  text={
                    "Customize the navigation of your app to enhance user experience."
                  }
                />
              )}
              <h1 className="menuhead-formob">Menu</h1>

              <div className="menu-builder-head ">
                <ModuleNavigations linkArr={menuState.linkArr} />
                <div className="menu-header-rightdiv ">
                  {menuState.updated_at && (
                    <UpdatedAt
                      timestamp={menuState.updated_at}
                      format={"D MMMM YYYY, HH:mm"}
                    />
                  )}

                  {!menuState.openMenuSorting && !menuState.openMenuSetting ? (
                    <></>
                  ) : (
                    <div
                      className="common-navigation-btn "
                      onClick={(e) => handleHomeBtn(e)}
                    >
                      <HomeIcon />
                    </div>
                  )}

                  {((menuState.isPrimaryMenuEnabled &&
                    !menuState.isPrimaryMenuSyncWithWP) ||
                    (menuState.isSecondaryMenuEnabled &&
                      !menuState.isSecondaryMenuSyncWithWP)) &&
                  window.matchMedia("(min-width: 1200px)").matches ? (
                    <div
                      className={`common-navigation-btn ${
                        menuState.openMenuSorting
                          ? "common-navigation-btn-clicked"
                          : ""
                      }`}
                      onClick={(e) => handleSortingBtn(e)}
                    >
                      {!menuState.openMenuSorting ? (
                        <SortingIcon strokeWidth={"1.5"} />
                      ) : (
                        <SortingIcon strokeWidth={"3"} />
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                  {menuState.spinner ? (
                    <></>
                  ) : (
                    <div onClick={(e) => handleSettingBtn(e)}>
                      <SettingIcon
                       
                        isActive={menuState.openMenuSetting}
                      />
                    </div>
                  )}
                  {menuState.spinner ? (
                    <></>
                  ) : (
                    <div className="menu-web-save-btn-parent">
                      <SaveButton
                        handleBtnClick={(e) => handleSaveMenu(e)}
                        isBtnLoader={menuState.loader}
                        btnText={"Save"}
                        isDisable={false}
                        variant={"primary-submit-web-btn"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className=" menubuilderbg">
              {menuState.spinner ? (
                <div className="menubuilderbgspinner">
                  <AmsSpinner spinWidth={"200px"} spinHeight={"200px"} />
                </div>
              ) : (
                <div className="menubuilder-main">
                  {/* Menu save */}

                  {!menuState.openMenuSetting && !menuState.openMenuSorting ? (
                    <div className={"menubuilder-left"}>
                      <div className="menubuild-master-toggle">
                        <h4 className="menuBuilder-enable-head">
                          Enable menu
                          <Tooltip
                            message={
                              "Enable to activate a hamburger menu in your app bar. A menu provides quick access for your app users to navigate through the app."
                            }
                          />
                        </h4>

                        <label className=" common-toogle-switch ">
                          <input
                            type="checkbox"
                            className="common-switch-input"
                            id="mainMenuToggle"
                            name="mainMenuToggle"
                            checked={menuState.isMainMenuEnabled}
                            onChange={handleEnableMainMenu}
                          />
                          <div className="switch-slider round"></div>
                        </label>
                      </div>
                      <div
                        className={
                          menuState.isMainMenuEnabled ? "" : "disable-menu"
                        }
                      >
                        <>
                          <div className="menubuilder-primary">
                            <div className="menuaccordian-Maindiv">
                              <h3 className="menuBuilder-primary-head">
                                Primary menu
                                <Tooltip
                                  message={
                                    "Enable to activate a primary menu in your app. The primary menu should direct users to important content and essential features within your app."
                                  }
                                />
                              </h3>

                              <label
                                className=" common-toogle-switch "
                                htmlFor="primaryMenuToggle"
                              >
                                <input
                                  type="checkbox"
                                  className="common-switch-input"
                                  id="primaryMenuToggle"
                                  name="primaryMenuToggle"
                                  onChange={(e) =>
                                    menuState.isMainMenuEnabled
                                      ? handlePrimaryMenuToggleChange(e)
                                      : null
                                  }
                                  checked={
                                    menuState.isMainMenuEnabled &&
                                    menuState.isPrimaryMenuEnabled
                                      ? true
                                      : false
                                  }
                                />
                                <div className="switch-slider round"></div>
                              </label>
                            </div>
                            <WebsiteMenu
                              isMenuModuleEnable={menuState.isMainMenuEnabled}
                              websiteMenuListingData={
                                menuState.wpMenuListingData
                              }
                              isParentMenuEnable={
                                menuState.isPrimaryMenuEnabled
                              }
                              syncWebsiteToggleVal={
                                menuState.isPrimaryMenuSyncWithWP
                              }
                              handleSyncWebsiteToggle={
                                handlePrimarySyncWebsiteToggle
                              }
                              selectedMenuVal={
                                menuState.primarySelectedWPMenuValue
                              }
                              handleMenuValChange={
                                handlePrimaryWebsiteMenuChange
                              }
                              from={"primary"}
                            />
                          </div>
                          {menuState.isPrimaryMenuEnabled ? (
                            <>
                              {menuState.isPrimaryMenuSyncWithWP ? (
                                <></>
                              ) : (
                                <div className="menu-iconcreatediv ">
                                  {" "}
                                  <div>
                                    {menuState.primaryMenuListingData &&
                                      menuState.primaryMenuListingData.map(
                                        (item, indx) => {
                                          return (
                                            <ItemAccordian
                                              key={item.id}
                                              item={item}
                                              itemListingData={
                                                menuState.primaryMenuListingData
                                              }
                                              itemListingArrName={
                                                "primaryMenuListingData"
                                              }
                                              setAccordianState={setMenuState}
                                              defaultOptionData={
                                                menuState.defaultOptionData
                                              }
                                              is_chat_enabled={
                                                menuState.is_chat_enabled
                                              }
                                              handleDefaultItem={
                                                handleDefaultItem
                                              }
                                              menuType={"primary"}
                                            />
                                          );
                                        }
                                      )}
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            <></>
                          )}

                          {menuState.isPrimaryMenuEnabled &&
                          !menuState.isPrimaryMenuSyncWithWP ? (
                            <div
                              className="addmoreitem"
                              onClick={(e) => handleAddItem(e, "primary")}
                            >
                              <img
                                className="mr-2"
                                src={
                                  require("../../../assets/images/dashboard/cms/blue-plus.png")
                                    .default
                                }
                                alt="blue plus"
                              />
                              Add item
                            </div>
                          ) : (
                            <></>
                          )}

                          <div className="menubuild-second-toggle">
                            <div className="secondry-menu-toggle">
                              <h4 className="menubuild-second-toggle-head">
                                Secondary menu
                                <Tooltip
                                  message={
                                    "Enable to add a secondary menu below your app's primary menu. A secondary menu can help you display additional navigation options."
                                  }
                                />
                              </h4>

                              <label
                                className=" common-toogle-switch "
                                htmlFor="secondaryMenuToggle"
                              >
                                <input
                                  type="checkbox"
                                  className="common-switch-input"
                                  id="secondaryMenuToggle"
                                  name="secondaryMenuToggle"
                                  checked={
                                    menuState.isMainMenuEnabled &&
                                    menuState.isSecondaryMenuEnabled
                                      ? true
                                      : false
                                  }
                                  onChange={
                                    menuState.isMainMenuEnabled
                                      ? (e) =>
                                          handleSecondaryMenuToggleChange(e)
                                      : null
                                  }
                                />
                                <div className="switch-slider round"></div>
                              </label>
                            </div>
                            <WebsiteMenu
                              isMenuModuleEnable={menuState.isMainMenuEnabled}
                              websiteMenuListingData={
                                menuState.wpMenuListingData
                              }
                              isParentMenuEnable={
                                menuState.isSecondaryMenuEnabled
                              }
                              syncWebsiteToggleVal={
                                menuState.isSecondaryMenuSyncWithWP
                              }
                              handleSyncWebsiteToggle={
                                handleSecondrySyncWebsiteToggle
                              }
                              selectedMenuVal={
                                menuState.secondarySelectedWPMenuValue
                              }
                              handleMenuValChange={
                                handleSecondaryWebsiteMenuChange
                              }
                              from={"secondary"}
                            />
                          </div>

                          {menuState.isSecondaryMenuEnabled ? (
                            <>
                              {menuState.isSecondaryMenuSyncWithWP ? (
                                <></>
                              ) : (
                                <div className="menu-iconcreatediv ">
                                  {" "}
                                  <div>
                                    {menuState.secondaryMenuListingData &&
                                      menuState.secondaryMenuListingData.map(
                                        (item, indx) => {
                                          return (
                                            <ItemAccordian
                                              key={item.id}
                                              item={item}
                                              itemListingData={
                                                menuState.secondaryMenuListingData
                                              }
                                              itemListingArrName={
                                                "secondaryMenuListingData"
                                              }
                                              setAccordianState={setMenuState}
                                              defaultOptionData={
                                                menuState.defaultOptionData
                                              }
                                              is_chat_enabled={
                                                menuState.is_chat_enabled
                                              }
                                              handleDefaultItem={
                                                handleDefaultItem
                                              }
                                              menuType={"secondary"}
                                            />
                                          );
                                        }
                                      )}
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            <></>
                          )}

                          {menuState.isSecondaryMenuEnabled &&
                          !menuState.isSecondaryMenuSyncWithWP ? (
                            <div
                              className="addmoreitem"
                              onClick={(e) => handleAddItem(e, "secondary")}
                            >
                              <img
                                className="mr-2"
                                src={
                                  require("../../../assets/images/dashboard/cms/blue-plus.png")
                                    .default
                                }
                                alt="blue plus"
                              />
                              Add item
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* Menu Setting */}

                  <Suspense fallback={""}>
                    <MenuSetting
                      menuState={menuState}
                      setMenuState={setMenuState}
                    />
                  </Suspense>

                  {/* Menu Sorting */}
                  <Suspense fallback={""}>
                    <MenuSorting
                      menuState={menuState}
                      setMenuState={setMenuState}
                    />
                  </Suspense>

                  {/* Menu preview */}

                  <MenuPreview menuState={menuState} />
                </div>
              )}
            </div>
            {!menuState.spinner && (
              <div className="menu-mob-btn">
                <SaveButton
                  handleBtnClick={(e) => handleSaveMenu(e)}
                  isBtnLoader={menuState.loader}
                  btnText={"Save"}
                  isDisable={false}
                  variant={"primary-submit-mob-btn"}
                />
                {menuState.updated_at && (
                  <UpdatedAt
                    timestamp={menuState.updated_at}
                    format={"D MMMM YYYY, HH:mm"}
                  />
                )}
              </div>
            )}
          </div>
        </>
      </section>

      <Connectpopup
        limitreached={menuState.openConnFailedPopUp}
        closefunction={handleConnFailedPopup}
      />

      {/* Choose default item alert */}
      <Modal
        className="notification-conf-secondmodal"
        style={{ maxWidth: "480px", maxHeight: "280px" }}
        isOpen={menuState.openDefaultItemPopUp}
        toggle={() => handleOpenDefaultItemPopUp()}
        centered
      >
        <ModalHeader>
          <div className="notification-conf-secondmodal-crossmodal menuBuilder-secondmodal-cross">
            <img
              onClick={() => handleOpenDefaultItemPopUp()}
              src={
                require("../../../assets/images/dashboard/cross.png").default
              }
              alt="cross"
            />
          </div>
        </ModalHeader>

        <ModalBody>
          <div className="notification-conf-popup-maindiv">
            <div className="notification-conf-popup-head">
              <h2>Choose a default item</h2>
            </div>

            <p>
              You must select a default item. The default item will appear every
              time your app is launched, providing quick access to your most
              important feature.
            </p>

            <div className="notification-conf-popup-bottom">
              <button
                className="notification-conf-popup-confirm"
                onClick={() => handleOpenDefaultItemPopUp()}
              >
                Okay
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* When user changed default item */}
      <Modal
        className="notification-conf-secondmodal"
        style={{ maxWidth: "480px", maxHeight: "280px" }}
        isOpen={menuState.openDefaultItemValidationPopUp}
        toggle={(e) => handleOpenItemValidationPopUp(e, "cancel")}
        centered
      >
        <ModalHeader>
          <div className="notification-conf-secondmodal-crossmodal menuBuilder-secondmodal-cross">
            <img
              onClick={(e) => handleOpenItemValidationPopUp(e, "cancel")}
              src={
                require("../../../assets/images/dashboard/cross.png").default
              }
              alt="cross"
            />
          </div>
        </ModalHeader>

        <ModalBody>
          <div className="notification-conf-popup-maindiv">
            <div className="notification-conf-popup-head">
              <h2>Change default landing screen?</h2>
            </div>

            <p>
              You are about to change the default app landing screen. This will
              update the screen that currently appears when the app launches.
              Are you sure you want to proceed?
            </p>

            <div className="notification-conf-popup-bottom">
              <button
                className="notification-conf-popup-cancel"
                onClick={(e) => handleOpenItemValidationPopUp(e, "cancel")}
              >
                Cancel
              </button>

              <button
                className="notification-conf-popup-confirm"
                onClick={(e) => handleOpenItemValidationPopUp(e, "confirm")}
              >
                Confirm
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* When user try to off both menu and bottom bar */}
      <Modal
        className="notification-conf-secondmodal"
        style={{ maxWidth: "480px", maxHeight: "280px" }}
        isOpen={menuState.openMainModuleValidationPopUp}
        toggle={() => handleOpenMainModuleValidationPopUp()}
        centered
      >
        <ModalHeader>
          <div className="notification-conf-secondmodal-crossmodal menuBuilder-secondmodal-cross">
            <img
              onClick={() => handleOpenMainModuleValidationPopUp()}
              src={
                require("../../../assets/images/dashboard/cross.png").default
              }
              alt="cross"
            />
          </div>
        </ModalHeader>

        <ModalBody>
          <div className="notification-conf-popup-maindiv">
            <div className="notification-conf-popup-head">
              <h2>Default landing screen not selected</h2>
            </div>

            <p>
              You cannot disable both navigation options. Please ensure either
              the Bottom Bar or the Menu is enabled. At least one must
              navigation option must be enabled.
            </p>

            <div className="notification-conf-popup-bottom">
              <button
                className="notification-conf-popup-confirm"
                onClick={() => handleOpenMainModuleValidationPopUp()}
              >
                Okay
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default MenuBuilder;
