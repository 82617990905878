import axios from "axios";
import React from "react";
import toast from "toasted-notes";
import S3 from "react-aws-s3";
import { hotjar } from "react-hotjar";

import "toasted-notes/src/styles.css";
import "../../assets/css/newtoaster.css";
import { errortoast, sucesstoast } from "../../Dashboard/Toaster";
import { CustomS3Client } from "../../utils/S3utils";
import { homePageEvent, myAppsEvent } from "../../utils/datalayerEvents";

const Url = process.env.REACT_APP_API_URL + "api/user/";
const saveceritificate = process.env.REACT_APP_API_URL + "api/app/";

const BuildUrl = process.env.REACT_APP_API_URL + "api/automation/bitbucket/";
const Cancelurl = process.env.REACT_APP_API_URL + "api/automation/bitrise/";
const dashboardurl = process.env.REACT_APP_API_URL + "api/";
const appsumourl = process.env.REACT_APP_API_URL;
const RedirectUrl = process.env.REACT_APP_cors_api;
const Errorurl = process.env.REACT_APP_API_URL + "api/frontend-log";
let clientid = "";
let awspath = "";
let appicon = "";
let applogo = "";
let splashimg = "";
let loginbackground = "";
let loginlogo = "";
let dashboard_banner = "";

const changeurl = () => {
  awspath =
    process.env.REACT_APP_Image_Path + clientid + process.env.REACT_APP_Profile;
  appicon = process.env.REACT_APP_Image_Path + clientid + "/";
  applogo = process.env.REACT_APP_Image_Path + clientid + "/";
  splashimg = process.env.REACT_APP_Image_Path + clientid + "/";
  loginbackground = process.env.REACT_APP_Image_Path + clientid + "/";
  loginlogo = process.env.REACT_APP_Image_Path + clientid + "/";
  dashboard_banner = process.env.REACT_APP_Image_Path + clientid + "/";
};
const errorHandling = (error, state) => {
  if (error.response === undefined) {
    state.setState({
      plugincontent:
        "Either your domain is incorrect, your website did not respond or your server does not allow external domains to connect and CORS must be enabled.",
    });
  } else if (error.response.status === 500) {
    state.setState({
      plugincontent:
        "Either your domain is incorrect, your website did not respond or your server does not allow external domains to connect and CORS must be enabled.",
    });
  } else if (error.response.status === 404) {
    if (error.response.data.code === "rest_no_route") {
      state.setState({
        plugincontent:
          "It appears that the AppMySite plugin is not installed on your WordPress website.",
      });
    } else {
      state.setState({
        plugincontent:
          "Either your domain is incorrect, your website did not respond or your server does not allow external domains to connect and CORS must be enabled.",
      });
    }
  } else {
    state.setState({
      plugincontent:
        "It appears that the AppMySite plugin is not installed on your WordPress website.",
    });
  }
};
const Loader = (state) => {
  state.setState({
    submitval: "",
    loader: "loader",
  });
};
/*const sucesstoast = (message) => {
  toast.notify(
    <div
      style={{
        borderLeft: "5px solid #01C99B",
        height: "100px",
        width: "420px",
      }}
      className="mobiletoaster"
    >
      <div className="d-flex align-items-center h-100">
        <div className="mr-3 ml-3">
          <svg width="35" height="35" viewBox="0 0 35 35">
            <circle
              id="Ellipse_1736"
              data-name="Ellipse 1736"
              cx="17.5"
              cy="17.5"
              r="17.5"
              fill="#01c99b"
            />
            <path
              id="Path_6845"
              data-name="Path 6845"
              d="M801.834,128.881l4.166,3.8,8.015-8.015"
              transform="translate(-790.333 -111.171)"
              fill="none"
              stroke="#fff"
              strokeWidth="2"
            />
          </svg>
        </div>
        <div>
          <h6 className="text-left mb-1">Success</h6>
          <p className="p-0 m-0 text-left">{message}</p>
        </div>
      </div>
    </div>,

    {
      position: "top", // top-left, top, top-right, bottom-left, bottom, bottom-right
      duration: 6000, // This notification will not automatically close
    }
  );
};

const errortoast = (message, errormssg) => {
  const handleSeeMore = (e) => {
    let seeMoreButton = document.getElementById("seeMoreButton");
    let showMoreText = document.getElementById("showMoreText");
    if (showMoreText.style.display === "none") {
      showMoreText.style.display = "block";
      seeMoreButton.innerHTML = "See Less";
    } else {
      showMoreText.style.display = "none";
      seeMoreButton.innerHTML = "See More";
    }
  };
  toast.notify(
    <div
      style={{
        borderLeft: "5px solid #E20000",
        height:
          errormssg === undefined || errormssg === null || errormssg === ""
            ? "100px"
            : "fit-content",
        // overflow:"auto",
        width: "420px",
      }}
      className="mobiletoaster"
    >
      <div className="d-flex align-items-center h-100">
        <div className="mr-3 ml-3">
          <svg width="35" height="35" viewBox="0 0 35 35">
            <circle
              id="Ellipse_1736"
              data-name="Ellipse 1736"
              cx="17.5"
              cy="17.5"
              r="17.5"
              fill="#e20000"
            />
            <g
              id="Group_3401"
              data-name="Group 3401"
              transform="translate(-673.175 -268.33)"
            >
              <g
                id="Polygon_10"
                data-name="Polygon 10"
                transform="translate(682.175 275.33)"
                fill="none"
              >
                <path
                  d="M6.734,3.325a2,2,0,0,1,3.532,0l5.173,9.737A2,2,0,0,1,13.673,16H3.327a2,2,0,0,1-1.766-2.938Z"
                  stroke="none"
                />
                <path
                  d="M 8.5 2.262979507446289 C 7.804892539978027 2.262979507446289 7.109785079956055 2.616877555847168 6.733770370483398 3.324671745300293 L 1.560979843139648 13.061692237854 C 0.8533000946044922 14.39379215240479 1.81879997253418 16.00000190734863 3.327210426330566 16.00000190734863 L 13.67279052734375 16.00000190734863 C 15.18120002746582 16.00000190734863 16.14669990539551 14.39379215240479 15.43902015686035 13.061692237854 L 10.2662296295166 3.324671745300293 C 9.890214920043945 2.616877555847168 9.195107460021973 2.262979507446289 8.5 2.262979507446289 M 8.5 0.2629814147949219 C 9.983099937438965 0.2629814147949219 11.33664989471436 1.07661247253418 12.03246021270752 2.386362075805664 L 17.20524978637695 12.12338256835938 C 17.86993980407715 13.37456226348877 17.83099937438965 14.8464822769165 17.10108947753906 16.06076240539551 C 16.37117958068848 17.27505302429199 15.08957004547119 18.00000190734863 13.67279052734375 18.00000190734863 L 3.327210426330566 18.00000190734863 C 1.910429954528809 18.00000190734863 0.6288204193115234 17.27505302429199 -0.1010894775390625 16.06076240539551 C -0.8309993743896484 14.8464822769165 -0.8699398040771484 13.37456226348877 -0.2052497863769531 12.12338256835938 L 4.96753978729248 2.386362075805664 C 5.66331958770752 1.076622009277344 7.016889572143555 0.2629814147949219 8.5 0.2629814147949219 Z"
                  stroke="none"
                  fill="#fff"
                />
              </g>
              <g
                id="Group_3400"
                data-name="Group 3400"
                transform="translate(690.675 282.866)"
              >
                <path
                  id="Path_6846"
                  data-name="Path 6846"
                  d="M0-1.077V1.9"
                  transform="translate(0 0.587)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <path
                  id="Path_6847"
                  data-name="Path 6847"
                  d="M0,.324V.346"
                  transform="translate(0 5.346)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
              </g>
            </g>
          </svg>
        </div>
        <div className="content">
          <h6 className="text-left mb-1 ">Error</h6>
          <p className="p-0 m-0 text-left">{message}</p>
          {errormssg === undefined || errormssg === null || errormssg === "" ? (
            <></>
          ) : (
            <button
              id="seeMoreButton"
              className="see-more"
              onClick={(e) => handleSeeMore(e)}
            >
              See more
            </button>
          )}
        </div>
      </div>
      <p
        id="showMoreText"
        style={{ display: "none", transition: "all ease 1s" }}
      >
        {errormssg}
      </p>
    </div>,

    {
      position: "top", // top-left, top, top-right, bottom-left, bottom, bottom-right
      duration: 6000, // This notification will not automatically close
    }
  );
};*/

const errorLoader = (state) => {
  state.setState({
    submitval: "BUILD APP",
    loader: "d-none",
  });
};

const errorLoader1 = (state) => {
  state.setState({
    submitval: "Create",
    loader: "d-none",
  });
};

const profileloader = (state) => {
  state.setState({
    submitval: "SAVE",
    loader: "d-none",
  });
};

const errorchangepassLoader = (state) => {
  state.setState({
    submitval: "SAVE PASSWORD",
    loader: "d-none",
  });
};
//Versionapi
export const Versionapi = () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(Url + "version-refresh", {
      headers: headers,
    })
    .then((res) => {
      if (localStorage.getItem("Apiversion") === null) {
        localStorage.setItem("Apiversion", res.data.data.version.toString());
      }

      if (localStorage.getItem("Apiversion") !== null) {
        if (
          localStorage.getItem("Apiversion") !==
          res.data.data.version.toString()
        ) {
          window.location.reload(true);
          localStorage.setItem("Apiversion", res.data.data.version.toString());
        }
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};
//Buildapi
export const Buildstatusapi = (state, setPresetColorForFunctionalComponent) => {
  let defaultColorArr = [
    "rgba(255, 255, 255, 1)",
    "rgba(217, 217, 217, 1)",
    "rgba(115, 115, 115, 1)",
    "rgba(0, 0, 0, 1)",
    "rgba(255, 22, 22, 1)",
    "rgba(255, 87, 87, 1)",
    "rgba(255, 102, 196, 1)",
    "rgba(124, 62, 246, 1)",
    "rgba(82, 113, 255, 1)",
    "rgba(0, 74, 173, 1)",
    "rgba(56, 182, 255, 1)",
    "rgba(0, 194, 203, 1)",
    "rgba(3, 152, 158, 1)",
    "rgba(16, 165, 80, 1)",
    "rgba(126, 217, 87, 1)",
    "rgba(255, 222, 89, 1)",
    "rgba(255, 189, 89, 1)",
    "rgba(255, 145, 77, 1)",
  ];

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  function convertHexToRgbA(hexVal) {
    let digit6 = hexVal.slice(0, 7);
    var ret;

    // If the hex value is valid.
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(digit6)) {
      // Getting the content after '#',
      // eg. 'ffffff' in case of '#ffffff'
      ret = digit6.slice(1);

      // Splitting each character
      ret = ret.split("");

      // Checking if the length is 3
      // then make that 6
      if (ret.length == 3) {
        var ar = [];
        ar.push(ret[0]);
        ar.push(ret[0]);
        ar.push(ret[1]);
        ar.push(ret[1]);
        ar.push(ret[2]);
        ar.push(ret[2]);
        ret = ar;
      }

      // Starts with '0x'(in hexadecimal)
      ret = "0x" + ret.join("");

      // Converting the first 2 characters
      // from hexadecimal to r value
      var r = (ret >> 16) & 255;

      // Converting the second 2 characters
      // to hexadecimal to g value
      var g = (ret >> 8) & 255;

      // Converting the last 2 characters
      // to hexadecimal to b value
      var b = ret & 255;

      // Appending all of them to make
      // the RGBA value
      return "rgba(" + [r, g, b].join(",") + ",1)";
    }
  }

  let finalRGBA = [];
  let uniqueArr = [];
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(Url + "app/build-status/" + btoa(localStorage.getItem("Appid")), {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        if (res.data.data.website_colors) {
          res.data.data.website_colors.map((eachColor) => {
            let singleRGBA = convertHexToRgbA(eachColor);

            if (singleRGBA !== "rgba(255,255,255,1)") {
              finalRGBA.push(singleRGBA);
            }
          });
          uniqueArr = finalRGBA.filter(onlyUnique);
        }
        if (state.props.value) {
          state.props.value.setState({
            presetColors:
              // res.data.data.website_colors === null ||
              // res.data.data.website_colors === undefined
              //   ? defaultColorArr
              //   : res.data.data.website_colors,
              uniqueArr,
          });
        }
        if (setPresetColorForFunctionalComponent) {
          setPresetColorForFunctionalComponent(uniqueArr);
        }

        state.setState({
          androidstatus: res.data.data.android,
          iosstatus: res.data.data.ios,
          review_plan_code: res.data.data.plan_code,
          is_android_rebuild_required:
            res.data.data.is_android_rebuild_required,
          is_ios_rebuild_required: res.data.data.is_ios_rebuild_required,
          plancheck: res.data.data.plan,
        });
        if (localStorage.getItem("Apiversion") === null) {
          localStorage.setItem(
            "Apiversion",
            res.data.data.frontend_version.toString()
          );
        }
        if (localStorage.getItem("Apiversion") !== null) {
          if (
            localStorage.getItem("Apiversion") !==
            res.data.data.frontend_version.toString()
          ) {
            window.location.reload(true);
            localStorage.setItem(
              "Apiversion",
              res.data.data.frontend_version.toString()
            );
          }
        }
      } else if (res.data.code === 401) {
        if (res.data.data === "App does not exits.") {
          state.props.history.push("/apps");
        }
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

export const HeaderBuildstatusapi = (
  props,
  setPresetColorForFunctionalComponent,
  setBuildstatusapi,
  setAppUserRoleId
) => {
  // 
  let defaultColorArr = [
    "rgba(255, 255, 255, 1)",
    "rgba(217, 217, 217, 1)",
    "rgba(115, 115, 115, 1)",
    "rgba(0, 0, 0, 1)",
    "rgba(255, 22, 22, 1)",
    "rgba(255, 87, 87, 1)",
    "rgba(255, 102, 196, 1)",
    "rgba(124, 62, 246, 1)",
    "rgba(82, 113, 255, 1)",
    "rgba(0, 74, 173, 1)",
    "rgba(56, 182, 255, 1)",
    "rgba(0, 194, 203, 1)",
    "rgba(3, 152, 158, 1)",
    "rgba(16, 165, 80, 1)",
    "rgba(126, 217, 87, 1)",
    "rgba(255, 222, 89, 1)",
    "rgba(255, 189, 89, 1)",
    "rgba(255, 145, 77, 1)",
  ];

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  function convertHexToRgbA(hexVal) {
    let digit6 = hexVal.slice(0, 7);
    var ret;

    // If the hex value is valid.
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(digit6)) {
      // Getting the content after '#',
      // eg. 'ffffff' in case of '#ffffff'
      ret = digit6.slice(1);

      // Splitting each character
      ret = ret.split("");

      // Checking if the length is 3
      // then make that 6
      if (ret.length == 3) {
        var ar = [];
        ar.push(ret[0]);
        ar.push(ret[0]);
        ar.push(ret[1]);
        ar.push(ret[1]);
        ar.push(ret[2]);
        ar.push(ret[2]);
        ret = ar;
      }

      // Starts with '0x'(in hexadecimal)
      ret = "0x" + ret.join("");

      // Converting the first 2 characters
      // from hexadecimal to r value
      var r = (ret >> 16) & 255;

      // Converting the second 2 characters
      // to hexadecimal to g value
      var g = (ret >> 8) & 255;

      // Converting the last 2 characters
      // to hexadecimal to b value
      var b = ret & 255;

      // Appending all of them to make
      // the RGBA value
      return "rgba(" + [r, g, b].join(",") + ",1)";
    }
  }

  let finalRGBA = [];
  let uniqueArr = [];
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(Url + "app/build-status/" + btoa(localStorage.getItem("Appid")), {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        if (res.data.data.website_colors) {
          res.data.data.website_colors.map((eachColor) => {
            let singleRGBA = convertHexToRgbA(eachColor);

            if (singleRGBA !== "rgba(255,255,255,1)") {
              finalRGBA.push(singleRGBA);
            }
          });
          uniqueArr = finalRGBA.filter(onlyUnique);
        }
        if (props.value) {
          props.value.setState({ presetColors: uniqueArr });
        }
        if (setPresetColorForFunctionalComponent) {
          setPresetColorForFunctionalComponent(uniqueArr);
        }

        setBuildstatusapi(res.data.data);
        setAppUserRoleId(res.data.data.appuser_role_id);
        localStorage.setItem("app_plan", res.data.data.plan);
        if (localStorage.getItem("Apiversion") === null) {
          localStorage.setItem(
            "Apiversion",
            res.data.data.frontend_version.toString()
          );
        }
        if (localStorage.getItem("Apiversion") !== null) {
          if (
            localStorage.getItem("Apiversion") !==
            res.data.data.frontend_version.toString()
          ) {
            window.location.reload(true);
            localStorage.setItem(
              "Apiversion",
              res.data.data.frontend_version.toString()
            );
          }
        }
      } else if (res.data.code === 401) {
        if (res.data.data === "App does not exits.") {
          props.history.push("/apps");
        }
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

export const Buildstatusapiformobilemenu = (setreview_plan_code, setPlan) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(Url + "app/build-status/" + btoa(localStorage.getItem("Appid")), {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        setreview_plan_code(res.data.data.plan_code);
        setPlan(res.data.data.plan);
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};
//Buildapi
export const Analyticsapi = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(
      dashboardurl +
        "app/build-information/" +
        btoa(localStorage.getItem("Appid")) +
        "?page=1",
      {
        headers: headers,
      }
    )
    .then((res) => {
      state.setState({
        data: res.data.data.history_data,
        versiondata: res.data.data.version_data,
        plan_code: res.data.data.subscription.plan_code,
        build_trigger_count: res.data.build_trigger_count,
        versionstatus: false,
        urlvar:
          localStorage.getItem("workspaceId") !== null &&
          localStorage.getItem("workspaceId") !== undefined &&
          localStorage.getItem("workspaceId") !== ""
            ? `/workspace/pricing/${btoa(localStorage.getItem("workspaceId"))}`
            : parseInt(localStorage.getItem("website_technology")) === 4
            ? "/app/wordpress-pricing/" + btoa(localStorage.getItem("Appid"))
            : parseInt(localStorage.getItem("website_technology")) === 5
            ? "/app/woocommerce-pricing/" + btoa(localStorage.getItem("Appid"))
            : parseInt(localStorage.getItem("website_technology")) === 3
            ? "/app/custom-app-pricing/" + btoa(localStorage.getItem("Appid"))
            : parseInt(localStorage.getItem("website_technology")) === 2
            ? "/app/web-to-app-pricing/" + btoa(localStorage.getItem("Appid"))
            : "/app/pricing",
      });
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

export const ScrollAnalyticsapi = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(
      dashboardurl +
        "app/build-information/" +
        btoa(localStorage.getItem("Appid")) +
        "?page=" +
        params.page,
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.data.history_data.length > 0) {
        var newvar = state.state.data;
        
        var newvar1 = res.data.data.history_data;
        
        var newvar2 = newvar.concat(newvar1);
        state.setState({ data: newvar2, nextpageicon_spinner: false });
      } else {
        state.setState({ setIsPushed: false, nextpageicon_spinner: false });
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};
export const Analyticsdata = (state, callback) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(
      dashboardurl + "app/dashboard/" + btoa(localStorage.getItem("Appid")),
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        if (state.stopmounting === true) {
          state.setState({
            dashboardspinner: false,
            appuser_role_id: res.data.data.appuser_role_id,
            data: res.data.data,
            website_technology: res.data.data.website_technology,
            recurring_addons_purchased_json:
              res.data.data.subscription === null ||
              res.data.data.subscription.recurring_addons_purchased_json ===
                "" ||
              res.data.data.subscription.recurring_addons_purchased_json ===
                null
                ? 0
                : JSON.parse(
                    res.data.data.subscription.recurring_addons_purchased_json
                  ),
            onetime_addons_purchased_json:
              res.data.data.subscription === null ||
              res.data.data.subscription.onetime_addons_purchased_json === "" ||
              res.data.data.subscription.onetime_addons_purchased_json === null
                ? 0
                : JSON.parse(
                    res.data.data.subscription.onetime_addons_purchased_json
                  ),
            auto_addons:
              res.data.data.subscription === null
                ? []
                : res.data.data.subscription.auto_addons,
            android_build_info: res.data.data.android_build_info,
            ios_build_info: res.data.data.ios_build_info,
            android_build: res.data.data.build_versions.android.build_version,
            android_build_version:
              res.data.data.build_versions.android.build_version,
            android_latest: res.data.data.build_versions.android.latest_version,
            ios_build: res.data.data.build_versions.ios.build_version,
            ios_build_version: res.data.data.build_versions.ios.build_version,
            ios_latest: res.data.data.build_versions.ios.latest_version,
            workspace_customer_id: res.data.data.workspace_customer_id,
            stripe_customer_id: res.data.data?.stripe_customer_id,
            customer_billing_type: res.data.data.customer_billing_type,
            customer_id: res.data.data.customer_id,
            owner_data: res.data.data.owner_data,
            openUpgradePopUp:
              (res.data.data.subscription.plan_name == "Free" ||
                res.data.data.subscription.plan_name == "free" ||
                res.data.data.subscription.plan == "preview" ||
                res.data.data.subscription.plan_name == "preview" ||
                res.data.data.subscription.plan_name == "Preview") &&
              (localStorage.getItem("workspaceId") === "undefined" ||
                localStorage.getItem("workspaceId") === undefined ||
                localStorage.getItem("workspaceId") === "" ||
                localStorage.getItem("workspaceId") === null) &&
              localStorage.getItem("agencyid") &&
              localStorage.getItem("agencyid") == 1
                ? true
                : false,
          });
          localStorage.setItem(
            "appuser_role_id",
            res.data.data.appuser_role_id
          );
          callback();
        }
      } else {
        state.setState({
          dashboardspinner: false,
        });
        // errortoast("HIII");
        // state.setState({
        //   spinner:false
        // })
        // 
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};
//Review

export const ExtractColor = (state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  const params = {
    app_id: parseInt(localStorage.getItem("Appid")),
    force_update: 0,
  };

  axios
    .post(saveceritificate + "extract-website-colors", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
      } else {
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

export const GeneralExtractColor = (state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  const params = {
    app_id: parseInt(localStorage.getItem("Appid")),
    force_update: 1,
  };

  axios
    .post(saveceritificate + "extract-website-colors", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
      } else {
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

export const saveReview = (params, state) => {
  Loader(state);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "save-review", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          submitval: "Submit",
          loader: "d-none",
          loaderimage: require("../../assets/images/signup/Loader_GIF.gif")
            .default,
          reviewdetail: true,
          reviewmodal: false,
          disabledbutton: false,
        });
      } else {
        state.setState({
          submitval: "Submit",
          loader: "d-none",
          loaderimage: require("../../assets/images/signup/Loader_GIF.gif")
            .default,

          disabledbutton: false,
        });
        errortoast(res.data.data);
      }
    })
    .catch((error) => {
      state.setState({
        submitval: "Submit",
        loader: "d-none",
        loaderimage: require("../../assets/images/signup/Loader_GIF.gif")
          .default,
      });
      errortoast("Something went wrong. Try again after sometime.");
    });
};
//reviewgetapi
export const reviewapi = (state) => {
  if (localStorage.getItem("amstoken") !== "") {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("amstoken"),
    };

    axios
      .get(Url + "review", {
        headers: headers,
      })
      .then((res) => {
        if (res.data.code === 200) {
          state.setState({
            spinner: false,
            data:
              res.data.data === undefined || res.data.data === null
                ? null
                : res.data.data,
            reviewsubmitted: res.data.data !== null ? true : false,
          });
        }
      })
      .catch((error) => {
        // errortoast("Something went wrong. Try again after sometime.");
      });
  }
};
//Appinfo at emailsupport screen
export const Getversion = async (store_url, state) => {
  try {
    const apihit = await axios.get(
      `${store_url}/wp-json/wc/v3/ams-get-plugin-info`
    );
    const response = apihit;

    if (response.status === 200) {
      state.setState({
        plugin_version:
          response.data.AMS_PLUGIN_CURRENT_VERSION === undefined
            ? ""
            : response.data.AMS_PLUGIN_CURRENT_VERSION,
        latest_version:
          response.data.AMS_PLUGIN_LATEST_VERSION === undefined
            ? ""
            : response.data.AMS_PLUGIN_LATEST_VERSION,
      });
    } else {
      state.setState({
        plugin_version: "",
        latest_version: "",
      });
    }
  } catch (error) {
    state.setState({
      plugin_version: "",
      latest_version: "",
    });
  }
};

//get profile header data
export const GetUserData = (state, callback) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  if (localStorage.getItem("sidebaserotate") === "true") {
    var sidebarrortate = 1;
  } else {
    var sidebarrortate = 0;
  }

  axios
    .get(dashboardurl + "get-portal-menu" + "?sidebarmenu=" + sidebarrortate, {
      headers: headers,
    })
    .then((res) => {
      if(res?.data?.user_info) {
        homePageEvent({
          user_email: res.data.user_info.email,
          user_name: res.data.user_info.name,
          user_id: res.data.user_info.id
        })
      }
      state.setState({
        apps_count: res.data.personal_app_count,
        apps: res.data.personal_apps,
        workspaces_count: res.data.personal_workspace_count,
        workspaces: res.data.personal_workspace,
        agencies: res.data.personal_agency,
        agencycount: res.data.personal_agency_count,
        invited_apps_count: res.data.invited_app_count,
        invited_apps: res.data.invited_apps,
        invited_workspaces_count: res.data.invited_workspace_count,
        invited_workspaces: res.data.invited_workspace,
        invited_agencies: res.data.invited_agency,
        invited_agency_count: res.data.invited_agency_count,
        userinfo: res.data.user_info,
        email: res.data.user_info.email,
        spinner: false,
      });

      callback();

      localStorage.setItem("user_profile", res.data.user_info.profile_image);
      localStorage.setItem("user_info", JSON.stringify(res.data.user_info));
      localStorage.setItem("originalid", res.data.user_info.id);
      localStorage.setItem("user_name", res.data.user_info.name);
      localStorage.setItem(
        "personal_apps",
        JSON.stringify(res.data.personal_apps)
      );
      localStorage.setItem("personal_apps_count", res.data.personal_app_count);
      localStorage.setItem(
        "invited_apps",
        JSON.stringify(res.data.invited_apps)
      );
      localStorage.setItem("invited_apps_count", res.data.invited_app_count);
      localStorage.setItem(
        "workspaces",
        JSON.stringify(res.data.personal_workspace)
      );
      localStorage.setItem(
        "workspace_count",
        res.data.personal_workspace_count
      );
      localStorage.setItem(
        "invited_wrokspaces",
        JSON.stringify(res.data.invited_workspace)
      );
      localStorage.setItem(
        "invited_workspace_count",
        res.data.invited_workspace_count
      );
      localStorage.setItem(
        "personal_agency",
        JSON.stringify(res.data.personal_agency)
      );
      localStorage.setItem(
        "personal_agency_count",
        res.data.personal_agency_count
      );
      localStorage.setItem(
        "invited_agency",
        JSON.stringify(res.data.invited_agency)
      );
      localStorage.setItem(
        "invited_agency_count",
        res.data.invited_agency_count
      );
      localStorage.setItem(
        "customer_billing_type",
        res.data.user_info.customer_billing_type
      );
      localStorage.removeItem("loginasclient");
    })
    .catch((error) => {
      
      if (
        error.response !== undefined &&
        error.response.status !== undefined &&
        error.response.status === 401
      ) {
        let myItem = localStorage.getItem("buildversion");
        let myItemversion = localStorage.getItem("Apiversion");
        let previous_location = localStorage.getItem("Previouslocation");

        localStorage.clear();
        localStorage.setItem("Previouslocation", previous_location);
        localStorage.setItem("buildversion", myItem);
        localStorage.setItem("Apiversion", myItemversion);

        window.location.href = "/";
      }
    });
};

//get profile header data
// export const GetReviewData = (state) =>
// {
//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: "Bearer " + localStorage.getItem("amstoken"),
//   };

//   axios
//     .get(
//       dashboardurl +'get_review_data', {
//         headers: headers,
//       }
//     )
//     .then((res) =>
//     {
//        if(res.data.allow_review == 1)
//        {
//         state.setState({
//           g2reviewstate:JSON.parse(res.data.g2resp).state,
//           // openG2popUp:true,
//         });
//      }
//     })
//     .catch((error) =>
//     {
//       
//     });
// };

//get profile header data
export const Getprofileheader = (
  setprofileid,
  setprofileimage,
  setprofilename,
  setmyappscount,
  setmyapps,
  setinvitedappscount,
  setinvitedapps,
  setinvitedworkspacescount,
  setinvitedworkspaces,
  setworkspaces,
  setworkspacescount,
  setuserrole,
  setagency,
  setagencycount,
  setinvitedagency,
  setinvitedagencycount,
  setprofileinfo,
  setprofilenumber,
  settotalprofilenumber,
  setsupportplan,
  setUserAppRole,
  setLastAccessPersonalAppRole,
  setLastAccessWorkspaceRole,
  setLastAccessAgencyRole
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  if (localStorage.getItem("sidebaserotate") === "true") {
    var sidebarrortate = 1;
  } else {
    var sidebarrortate = 0;
  }

  axios
    .get(dashboardurl + "get-portal-menu" + "?sidebarmenu=" + sidebarrortate, {
      headers: headers,
    })
    .then((res) => {


      localStorage.setItem("user_profile", res.data.user_info.profile_image);
      localStorage.setItem("user_info", JSON.stringify(res.data.user_info));
      localStorage.setItem("originalid", res.data.user_info.id);
      localStorage.setItem("user_name", res.data.user_info.name);
      localStorage.setItem(
        "personal_apps",
        JSON.stringify(res.data.personal_apps)
      );
      localStorage.setItem("personal_apps_count", res.data.personal_app_count);
      localStorage.setItem(
        "invited_apps",
        JSON.stringify(res.data.invited_apps)
      );
      localStorage.setItem("invited_apps_count", res.data.invited_app_count);
      localStorage.setItem(
        "workspaces",
        JSON.stringify(res.data.personal_workspace)
      );
      localStorage.setItem(
        "workspace_count",
        res.data.personal_workspace_count
      );
      localStorage.setItem(
        "invited_wrokspaces",
        JSON.stringify(res.data.invited_workspace)
      );
      localStorage.setItem(
        "invited_workspace_count",
        res.data.invited_workspace_count
      );
      localStorage.setItem(
        "personal_agency",
        JSON.stringify(res.data.personal_agency)
      );
      localStorage.setItem(
        "personal_agency_count",
        res.data.personal_agency_count
      );
      localStorage.setItem(
        "invited_agency",
        JSON.stringify(res.data.invited_agency)
      );
      localStorage.setItem(
        "invited_agency_count",
        res.data.invited_agency_count
      );
      localStorage.setItem(
        "customer_billing_type",
        res.data.user_info.customer_billing_type
      );
      localStorage.setItem(
        "supportplan",
        JSON.stringify(res.data.support_subscription)
      );
      localStorage.setItem("isPremiumSubscriber",res.data.is_premium_subscriber);
      localStorage.removeItem("loginasclient");

      if (
        localStorage.getItem("personal_apps") === null ||
        localStorage.getItem("personal_apps") === undefined ||
        localStorage.getItem("loginasclient") == "1"
      ) {
        setmyappscount(res.data.personal_app_count);
        setmyapps(res.data.personal_apps);
      }

      if (
        localStorage.getItem("invited_apps") === null ||
        localStorage.getItem("invited_apps") === undefined ||
        localStorage.getItem("loginasclient") == "1"
      ) {
        setinvitedappscount(res.data.invited_app_count);
        setinvitedapps(res.data.invited_apps);
      }

      if (
        localStorage.getItem("invited_wrokspaces") === null ||
        localStorage.getItem("invited_wrokspaces") === undefined ||
        localStorage.getItem("loginasclient") == "1"
      ) {
        setinvitedworkspacescount(res.data.invited_workspace_count);
        setinvitedworkspaces(res.data.invited_workspace);
      }

      if (
        localStorage.getItem("workspaces") === null ||
        localStorage.getItem("workspaces") === undefined ||
        localStorage.getItem("loginasclient") == "1"
      ) {
        setworkspaces(res.data.personal_workspace);
        setworkspacescount(res.data.personal_workspace_count);
      }

      if (
        localStorage.getItem("personal_agency") === null ||
        localStorage.getItem("personal_agency") === undefined ||
        localStorage.getItem("loginasclient") == "1"
      ) {
        setagency(res.data.personal_agency);
        setagencycount(res.data.personal_agency_count);
      }

      if (
        localStorage.getItem("invited_agency") === null ||
        localStorage.getItem("invited_agency") === undefined ||
        localStorage.getItem("loginasclient") == "1"
      ) {
        setinvitedagency(res.data.invited_agency);
        setinvitedagencycount(res.data.invited_agency_count);
      }

      setsupportplan(res.data.support_subscription);
      setprofileinfo(res.data.user_info);
      setprofileimage(res.data.user_info.profile_image);
      setprofilename(res.data.user_info.name);
      setprofileid(res.data.user_info.id);
      setuserrole(res.data.user_role);
      setUserAppRole(res.data.invited_app_role);
      setLastAccessPersonalAppRole(res.data.last_accessed_personal_app_role);
      setLastAccessWorkspaceRole(res.data.last_accessed_workspace_role);
      setLastAccessAgencyRole(res.data.last_accessed_agency_role);

      if (
        res.data.user_info &&
        res.data.user_info !== "null" &&
        res.data.user_info !== "undefined"
      ) {
        let number = 0;
        let totalnumber = 0;
        const userinfo = res.data.user_info;
        if (userinfo.phone && userinfo.phone != "") {
          number = number + 1;
        }
        if (userinfo.user_company_size && userinfo.user_company_size != "") {
          number = number + 1;
        }
        if (userinfo.user_industry && userinfo.user_industry != "") {
          number = number + 1;
        }
        if (userinfo.user_job_role && userinfo.user_job_role != "") {
          number = number + 1;
        }
        if (userinfo.name && userinfo.name != "") {
          number = number + 1;
        }

        if (userinfo.status && userinfo.status == 1) {
          totalnumber = totalnumber + 33;
        }

        if (
          userinfo.two_factor_status &&
          (userinfo.two_factor_status == 1 ||
            userinfo.two_factor_status == true)
        ) {
          totalnumber = totalnumber + 33;
        }

        if (number == 5) {
          setprofilenumber(34);
          settotalprofilenumber(parseInt(totalnumber) + parseInt(34));
        } else {
          setprofilenumber(0);
          settotalprofilenumber(parseInt(totalnumber) + parseInt(0));
        }
      }
      if (
        res.data.user_info.status === 0 &&
        window.location.pathname !== "/verify-email" &&
        window.location.pathname !== "/profile" &&
        window.location.pathname !== "/apps"
      ) {
        
        window.location.href = "/verify-email";
      }
    })
    .catch((error) => {
      
      if (
        error.response !== undefined &&
        error.response.status !== undefined &&
        error.response.status === 401
      ) {
        let myItem = localStorage.getItem("buildversion");
        let myItemversion = localStorage.getItem("Apiversion");
        let previous_location = localStorage.getItem("Previouslocation");
        localStorage.clear();
        localStorage.setItem("Previouslocation", previous_location);
        localStorage.setItem("buildversion", myItem);
        localStorage.setItem("Apiversion", myItemversion);
      }
    });
};

//Appinfo at emailsupport screen
export const Appinfo = (pagination, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(
      Url +
        "apps-info?limit=" +
        pagination.limit +
        "&page=" +
        pagination.page +
        "&search_field=" +
        pagination.search_field +
        "&timestamp=" +
        new Date().getTime(),
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        // const options = res.data.data.map((d) => ({
        //   value: d.id,
        //   label: d.app_name,
        //   created_days: d.app_created_hours,
        //   plan_code: d.plan_code,
        //   app_billing_date: d.app_billing_date,
        //   last_support_date: d.last_support_date,
        //   plan_name: d.plan_name,
        //   store_url: d.store_url,
        //   reseller_package_id: d.reseller_package_id,
        //   package_duration_id: d.package_duration_id,
        //   currency:d.currency,
        //   total:d.total,
        //   status:d.status,
        //   create_json:JSON.parse(d.create_json)
        // }));
        state.setState({
          userdata: res.data.data === null ? [] : res.data.data,
          appOrWpData: res.data.data === null ? [] : res.data.data,
          spinner: false,
          // selectOptions: options,
          userStatus: res.data.page_data.user_status,
        });
      } else if (res.data.code === 203) {
        if (res.data.status === "Token Expired") {
          // state.props.history.push("/");
          let myItem = localStorage.getItem("buildversion");
          let myItemversion = localStorage.getItem("Apiversion");
          let previous_location = localStorage.getItem("Previouslocation");
          localStorage.clear();
          localStorage.setItem("Previouslocation", previous_location);
          localStorage.setItem("buildversion", myItem);
          localStorage.setItem("Apiversion", myItemversion);
        }
      }
    })
    .catch((error) => {
      
      state.setState({
        spinner: false,
      });
    });
};

export const emailSupportWorkspaces = (state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(dashboardurl + `get-workspaces?limit=all`, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        let mergeArr = [];
        const option1 = res.data.data.map((d) => ({
          id: d.id,
          app_name: d.workspace_name,
        }));
        // mergeArr=options;
        const option2 = res.data.invited_workspace.map((d) => ({
          id: d.id,
          app_name: d.workspace_name,
        }));

        mergeArr = option1.concat(option2);
        state.setState({
          workspaceData: mergeArr,
        });
      }
    })
    .catch((err) => {
      
    });
};

// export const sendinblue = (res) => {
//   const params = {
//     contact: {
//       FIRSTNAME: res.name,
//       AMS_CUSTOMER_ID: res.ams_customer_id,
//       PAYMENT_CUSTOMER_ID: res.zoho_customer_id,
//       EMAIL_VERIFIED: res.email_verified,
//       CUSTOMER_TYPE: res.customer_type.toString(),
//       APP_PLAN: res.appsumo_plan,
//       FREE_OR_PAID: res.free_or_paid,
//       OWNER_TYPE: res.owner_type,
//       LOGIN_COUNTER: res.login_counter,
//       COUNTRY_CODE: res.country_code,
//       TOTAL_APPS: res.total_apps,
//     },
//     cuid: res.ams_customer_id.toString(),
//     email_id: res.email,
//     key: process.env.REACT_APP_SENDINBLUE_SECRET,
//     ma_url: window.location.href,
//     sib_type: "identify",
//   };
//   axios
//     .post("https://in-automate.sendinblue.com/p", params)
//     .then((res) => {
//       if(res.status === 200){
//       }
//     })
//     .catch((error) => {});
// };

//Sendgrid Api

export const sendGrid = (res) => {
  const param = {
    w34_N: res.ams_customer_id, //ams_customer_id
    w21_T: res.appsumo_plan, //app_plan
    w19_T: res.country_code, //country_code
    w35_N: res.customer_type, //customer_type

    w33_N: res.email_verified, //email_verified
    w24_T: res.free_or_paid, //free_or_paid
    w32_N: res.login_counter, //login_counter

    w26_T: res.owner_type, //owner_type
    w31_N: res.total_apps, //total_apps
    w27_T: res.zoho_customer_id, //PAYMENT_CUSTOMER_ID
  };
  const contact = {
    // address_line_1: "",
    // address_line_2: "abc",
    // alternate_emails: ["abc@gg.com"],
    // city: "abc",
    // country: "SG",
    email: res.email,
    first_name: res.name,
    // last_name: "",
    // postal_codes: "22",
    // state_province_region: "abc",
    custom_fields: param,
  };

  const data = {
    list_ids: [process.env.REACT_APP_SENDGRID_LIST_ID],
    contacts: [contact],
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + process.env.REACT_APP_SENDGRID_SECRET,
  };

  axios
    .put(process.env.REACT_APP_SENDGRID_API_URL, data, {
      headers: headers,
    })
    .then((res) => {
      
    })
    .catch((err) => {
      
    });
};

//Getiuserdata at myapps screen
export const Getuserdataadvaced = (state, pagination) => {
  // 
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  let finalvale;
  if (state.state.applyhit === true) {
    finalvale =
      "&page=" +
      pagination.page +
      "&search_field=" +
      pagination.search_field +
      "&advance_search_array[name]=" +
      pagination.name +
      "&advance_search_array[email]=" +
      pagination.email +
      "&advance_search_array[domain]=" +
      pagination.domain +
      "&advance_search_array[plan_code]=" +
      pagination.plan_code +
      "&advance_search_array[app_name]=" +
      pagination.app_name +
      "&advance_search_array[app_id]=" +
      pagination.app_id +
      "&advance_search_array[client_id]=" +
      pagination.client_id +
      "&advance_search_array[user_id]=" +
      pagination.user_id +
      "&advance_search_array[technology]=" +
      pagination.technology +
      "&advance_search_array[reseller]=" +
      pagination.reseller +
      "&advance_search_array[payment_gateway]=" +
      pagination.payment_gateway +
      "&timestamp=" +
      new Date().getTime() +
      "&workspace_id=" +
      pagination.workspace_id;
  } else {
    finalvale =
      "&page=" +
      pagination.page +
      "&search_field=" +
      pagination.search_field +
      "&advance_search_array=" +
      [] +
      "&timestamp=" +
      new Date().getTime() +
      "&workspace_id=" +
      pagination.workspace_id;
  }
  axios
    .get(Url + "user_apps?limit=" + pagination.limit + finalvale, {
      headers: headers,
    })

    .then((res) => {
      document.body.classList.remove("opec");
      state.setState({ searchvalue: pagination.search_field });
      if (res.data.code === 200) {
        if(pagination.page === 1 && res?.data?.email_marketing) {
          // hit datalayer once during first load not every load
          myAppsEvent({
            user_email: res.data.email_marketing.email,
            user_name: res.data.email_marketing.name,
            user_id: res.data.email_marketing.ams_customer_id
          })
        }
        // sucesstoast("Your changes have been saved.");
        if (
          pagination.workspace_id != "" &&
          pagination.workspace_id != null &&
          res.data.workspace_permission != undefined &&
          res.data.workspace_permission != null &&
          res.data.workspace_permission != 1
        ) {
          window.location.href = "/apps";
        }
        if (state.state.applyhit === true) {
          let array2ForSrchTag = JSON.parse(
            JSON.stringify(state.state.arrayForSrchTag)
          );
          state.setState({
            // showSrchTag: true,
            array2ForSrchTag: array2ForSrchTag,
          });
        }

        if (pagination.page > 1) {
          let userdata = pagination.userdata;
          Array.prototype.push.apply(userdata, res.data.data);

          state.setState({
            userdata: userdata,
            searchspinner: false,
            spinner: false,
            bottomspinner: false,
            roledata: res.data.page_data,
            handlepage: res.data.data,
            datanumber: res.data.data.length,
            user_status: res.data.user_status,
            status_code: res.data.code,
            appsumodata:
              res.data.app_sumo_data === undefined
                ? { data: [] }
                : res.data.app_sumo_data,
            customer_type: res.data.customer_type,
            is_appsumo_user: res.data.is_appsumo_user,
            workspace_subscription: res.data.data.workspace_subscription,
          });
        } else {
          state.setState({
            cloneBtnText: "Clone",
            cloneBtnLoader: "d-none",
            openClonePopUp: false,
            isShareWithSameTeam: false,
            userdata: res.data.data,
            showappname: res.data.data.length === 0 ? false : true,
            searchspinner: false,
            spinner: false,
            roledata: res.data.page_data,
            datanumber: res.data.data.length,
            user_status: res.data.user_status,
            status_code: res.data.code,
            appsumodata:
              res.data.app_sumo_data === undefined
                ? { data: [] }
                : res.data.app_sumo_data,
            customer_type: res.data.customer_type,
            is_appsumo_user: res.data.is_appsumo_user,
            advancesearch: "Search",
            advancesearchloader: "d-none",
            advancesearchimage:
              require("../../assets/images/signup/Loader_GIF.gif").default,
            advanceMenuSearch: false,
            workspace_subscription: res.data.workspace_subscription,
          });

          if (res.data.email_marketing.email_verified === 1) {
            // sendinblue(res.data.email_marketing);
            // sendGrid(res.data.email_marketing);
            // Check if Hotjar has been initialized before calling its methods
            // sendGrid(res.data.email_marketing);
          }

          const params = {
            FIRSTNAME: res.data.email_marketing.name,
            AMS_CUSTOMER_ID: res.data.email_marketing.ams_customer_id,
            PAYMENT_CUSTOMER_ID: res.data.email_marketing.zoho_customer_id,
            EMAIL_VERIFIED: res.data.email_marketing.email_verified,
            CUSTOMER_TYPE: res.data.email_marketing.customer_type.toString(),
            APP_PLAN: res.data.email_marketing.appsumo_plan,
            FREE_OR_PAID: res.data.email_marketing.free_or_paid,
            OWNER_TYPE: res.data.email_marketing.owner_type,
            LOGIN_COUNTER: res.data.email_marketing.login_counter,
            COUNTRY_CODE: res.data.email_marketing.country_code,
            TOTAL_APPS: res.data.email_marketing.total_apps,
            cuid: res.data.email_marketing.ams_customer_id.toString(),
            email_id: res.data.email_marketing.email,
            sib_type: "identify",
          };

          hotjar.initialize("3221297", "6");

          if (hotjar.initialized()) {
            
            // Identify the user
            hotjar.identify(res.data.email_marketing.ams_customer_id, {
              userProperty: "value",
            });

            // Add an event
            hotjar.event("button-click");
        
            hotjar.identify(res.data.email_marketing.ams_customer_id, params);
          }
        }
      } else if (res.data.code === 203) {
        if (res.data.status === "Token Expired") {
          // state.props.history.push("/");
          let myItem = localStorage.getItem("buildversion");
          let myItemversion = localStorage.getItem("Apiversion");
          let previous_location = localStorage.getItem("Previouslocation");
          localStorage.clear();
          localStorage.setItem("Previouslocation", previous_location);
          localStorage.setItem("buildversion", myItem);
          localStorage.setItem("Apiversion", myItemversion);
        }
      } else if (res.data.code === 401) {
        let myItem = localStorage.getItem("buildversion");
        let myItemversion = localStorage.getItem("Apiversion");
        let previous_location = localStorage.getItem("Previouslocation");
        localStorage.clear();
        localStorage.setItem("Previouslocation", previous_location);
        localStorage.setItem("buildversion", myItem);
        localStorage.setItem("Apiversion", myItemversion);
      } else if (res.data.code === 204) {
        state.setState({
          status_code: res.data.code,
          searchspinner: false,
          spinner: false,
          user_status: 0,
          advancesearch: "Search",
          advancesearchloader: "d-none",
          advancesearchimage:
            require("../../assets/images/signup/Loader_GIF.gif").default,
          selection_array: [],
        });
      }
    })
    .catch((error) => {
      document.body.classList.remove("opec");
      
      state.setState({
        searchspinner: false,
        spinner: false,
      });
    });
};

//Save default data
export const Savedefaultdata = (params) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "save-defaults", params, { headers: headers })

    .then((res) => {
      if (res.data.code === 200) {
      } else if (res.data.code === 203) {
        
      }
    })
    .catch((error) => {
      
    });
};

const currencyget = () => {
  let headers = {
    "Content-Type": "application/json",
  };

  axios
    .get(process.env.REACT_APP_PADDLE_VENDOR_URL, {
      headers: headers,
    })
    .then((res) => {})
    .catch((error) => {});
};

//Getappdetailsapi at publishapp screen
export const Getappdetailsapi = (state, callback) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Url + "get_app_details/" + btoa(localStorage.getItem("Appid")), {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          spinner: false,
          plan_code: res.data.data.subscription.plan_code[0],
          personalisation: res.data.data.dashboard_icon_count,
          app_icon_count: res.data.data.app_icon_count,
          splash_count: res.data.data.splash_icon_count,
          style_count: res.data.data.style_icon_count,
          login_register_count: res.data.data.login_register_icon_count,
          paymentgateway: parseInt(res.data.data.menu_fill),
          connectivity:
            parseInt(localStorage.getItem("website_technology")) === 5
              ? parseInt(res.data.data.is_verified) === 1 &&
                parseInt(res.data.data.plugin_data) > 0
                ? 1
                : 0
              : parseInt(res.data.data.is_verified) === 1
              ? 1
              : 0,

          website_type: res.data.data.website_type.toString(),
          website_technology: res.data.data.website_technology,
          is_verified: parseInt(res.data.data.is_verified),
          user_status: parseInt(res.data.data.user_status.status),
          customer_type: parseInt(res.data.data.user_status.customer_type),
          plugin_data: parseInt(res.data.data.plugin_data),
          zoho_plan_code:
            res.data.data.subscription === null
              ? "preview"
              : res.data.data.subscription.plan_code,
          version:
            parseInt(res.data.data.version) === 0
              ? 0
              : parseInt(res.data.data.version) - 1,
        });
        if (res.data.data.subscription === null) {
          errortoast("Something went wrong. Try again after sometime.");
        }
        

        if (
          state.state.zoho_plan_code
            .toString()
            .indexOf(process.env.REACT_APP_Lifetime_plan) > -1 ||
          state.state.zoho_plan_code
            .toString()
            .indexOf(process.env.REACT_APP_Lifetime_preview) > -1
        ) {
          state.handlefilter(2, 0);
        }
        res.data.data.subscription !== null && callback();
      }
    })
    .catch((error) => {
      state.setState({
        spinner: false,
      });
    });
};

//GetDownloadappdetailsapi at publishapp screen
export const GetDownloadappdetailsapi = (state, callback) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Url + "get_app_details/" + btoa(localStorage.getItem("Appid")), {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          spinner: false,
          plan_code: res.data.data.subscription.plan_code[0],
          personalisation: res.data.data.dashboard_icon_count,
          app_icon_count: res.data.data.app_icon_count,
          splash_count: res.data.data.splash_icon_count,
          style_count: res.data.data.style_icon_count,
          login_register_count: res.data.data.login_register_icon_count,
          paymentgateway: parseInt(res.data.data.menu_fill),
          connectivity:
            parseInt(localStorage.getItem("website_technology")) === 5
              ? parseInt(res.data.data.is_verified) === 1 &&
                parseInt(res.data.data.plugin_data) > 0
                ? 1
                : 0
              : parseInt(res.data.data.is_verified) === 1
              ? 1
              : 0,

          website_type: res.data.data.website_type.toString(),
          website_technology: res.data.data.website_technology,
          is_verified: parseInt(res.data.data.is_verified),
          user_status: parseInt(res.data.data.user_status.status),
          customer_type: parseInt(res.data.data.user_status.customer_type),
          plugin_data: parseInt(res.data.data.plugin_data),
          zoho_plan_code:
            res.data.data.subscription === null
              ? "preview"
              : res.data.data.subscription.plan_code,
          version:
            parseInt(res.data.data.version) === 0
              ? 0
              : parseInt(res.data.data.version) - 1,
        });
        if (res.data.data.subscription === null) {
          errortoast("Something went wrong. Try again after sometime.");
        }
        if (res.data.data.subscription !== null) {
          callback();
        } else {
          callback();
        }
      }
    })
    .catch((error) => {
      state.setState({
        spinner: false,
      });
    });
};

//DeleteApp
export const DeleteApp = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  state.setState({
    modal: false,
    spinner: true,
    submitval_confirmpop: "",
    loader_remove: "loader",
  });

  axios
    .post(Url + "delete_app", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        sucesstoast("App successfully deleted");
        state.props.history.push(
          `/apps${
            localStorage.getItem("workspaceId") !== "" &&
            localStorage.getItem("workspaceId") !== "undefined" &&
            localStorage.getItem("workspaceId") !== undefined &&
            localStorage.getItem("workspaceId") !== null
              ? "/" + btoa(localStorage.getItem("workspaceId"))
              : ""
          }`
        );
      } else if (res.data.code === 204) {
        state.setState({
          paidsubscriptiondetected: true,
          delete_app: false,
          submitval_confirmpop: "Yes",
          loader_remove: "d-none",
        });
      }
    })
    .catch((error) => {
      errortoast("Something went wrong. Try again after sometime.");
    });
};

//LeaveApp
export const LeaveApp = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  state.setState({
    modal: false,
    spinner: true,
    submitval_confirmpop1: "",
    loader_remove: "loader",
  });

  axios
    .post(saveceritificate + "user-management/leave-app", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        sucesstoast("You have successfully been removed from the app.");
        state.props.history.push(
          `/apps${
            localStorage.getItem("workspaceId") !== "" &&
            localStorage.getItem("workspaceId") !== "undefined" &&
            localStorage.getItem("workspaceId") !== undefined &&
            localStorage.getItem("workspaceId") !== null
              ? "/" + btoa(localStorage.getItem("workspaceId"))
              : ""
          }`
        );
      } else if (res.data.code === 204) {
        state.setState({
          delete_app: true,
          deletemodal: false,
          submitval_confirmpop1: "Yes",
          loader_remove: "d-none",
        });
      }
    })
    .catch((error) => {
      errortoast("Something went wrong. Try again after sometime.");
    });
};
//DeleteUser
export const DeleteUser = (params, state) => {
  const headers = {
    "Content-Type": "text/plain",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "delete_account", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          deleteLoader: false,
        });
        let myItem = localStorage.getItem("buildversion");
        let myItemversion = localStorage.getItem("Apiversion");
        let previous_location = localStorage.getItem("Previouslocation");
        localStorage.clear();
        localStorage.setItem("Previouslocation", previous_location);
        localStorage.setItem("buildversion", myItem);
        localStorage.setItem("Apiversion", myItemversion);
        state.props.history.push("/");
      } else if (res.data.code === 401) {
        if (res.data.status === "Token Expired") {
          // state.props.history.push("/");
          let myItem = localStorage.getItem("buildversion");
          let myItemversion = localStorage.getItem("Apiversion");
          let previous_location = localStorage.getItem("Previouslocation");
          localStorage.clear();
          localStorage.setItem("Previouslocation", previous_location);
          localStorage.setItem("buildversion", myItem);
          localStorage.setItem("Apiversion", myItemversion);
        }
        state.setState({
          deleteLoader:false,
          userdeletemodal: false,
        });
      } else if (res.data.code === 500) {
        errortoast("Please delete all workspaces before deleting account");
        state.setState({
          deleteLoader:false,
          userdeletemodal: false,
        });
      } else {
        state.setState({
          deleteLoader:false,
          userdeletemodal: false,
        });
        errortoast("Something Went Wrong");
      }
    })
    .catch((err) => {
      
      state.setState({
        deleteLoader: false,
        userdeletemodal: false,
      });
      errortoast("Something Went Wrong");
    });
};
//CreateApp APi
export const Createapi = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  Loader(state);

  axios
    .post(Url + "app_details", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        let original_id =
          parseInt(localStorage.getItem("logged_in_user_role_id")) === 1
            ? localStorage.getItem("originalid")
            : localStorage.getItem("invited_by_user_id");

        //Change save
        localStorage.setItem("app_icon_count", 0);
        localStorage.setItem("splash_icon_count", 0);
        localStorage.setItem("login_register_icon_count", 0);
        localStorage.setItem("style_icon_count", 0);
        localStorage.setItem("dashboard_icon_count", 0);
        //Change save end
        localStorage.setItem("user_id", original_id);
        localStorage.setItem("Appcount", res.data.data.appcount);
        localStorage.setItem("Appname", res.data.data.app_name);
        localStorage.setItem("Appversion", res.data.data.version);
        localStorage.setItem("store_url", res.data.data.store_url);
        localStorage.setItem("Appid", res.data.data.id);
        localStorage.setItem("plugin_dataactive", parseInt("0"));
        localStorage.setItem("subscriptionactive", "0");
        localStorage.setItem("appsettingactive", parseInt(0));
        localStorage.setItem("personaliseactive", parseInt(0));
        localStorage.setItem("is_verified", "0");
        localStorage.removeItem("session");
        if (params.emailverified === 0) {
          state.props.history.push("/apps");
        } else {
          const userinfo = JSON.parse(localStorage.getItem("user_info"));

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "create_app",
            user_email: userinfo.email,
            user_name: userinfo.name,
            user_id: original_id,
            app_id: res.data.data.id,
            agency_id: localStorage.getItem("agency_id")
              ? parseInt(localStorage.getItem("agency_id"))
              : 1,
          });
          state.props.history.push("/app/dashboard");
        }

        localStorage.setItem("plan_code", btoa("preview"));
      } else if (res.data.code === 400) {
        errorLoader1(state);
        if (Object.keys(res.data.data).indexOf("store_url") === -1) {
          state.setState({
            store_urlerror: null,
          });
        } else {
          state.setState({
            store_urlerror: res.data.data.store_url[0],
          });
        }
        if (Object.keys(res.data.data).indexOf("app_name") === -1) {
          state.setState({
            app_nameerror: null,
          });
        } else {
          state.setState({
            app_nameerror: res.data.data.app_name[0],
          });
        }
      } else if (res.data.code === 401) {
        if (res.data.status === "Token Expired") {
          // state.props.history.push("/");
          let myItem = localStorage.getItem("buildversion");
          let myItemversion = localStorage.getItem("Apiversion");
          let previous_location = localStorage.getItem("Previouslocation");
          localStorage.clear();
          localStorage.setItem("Previouslocation", previous_location);
          localStorage.setItem("buildversion", myItem);
          localStorage.setItem("Apiversion", myItemversion);
        }
      } else if (res.data.code === 402) {
        state.setState({
          title: "Upgrade required",
          description: res.data.data,
          hitpopup: true,
          bothbutton: false,
        });
        errorLoader(state);
      } else if (res.data.code === 500) {
        state.setState({
          title: "License",
          description: res.data.data,
          hitpopup: true,
          bothbutton: false,
        });
        errorLoader(state);
      }
    })
    .catch((error) => {
      errortoast("Something went wrong. Try again after sometime.");
    });
};

//Change password
export const Changepasswordapi = (
  params,
  setOldPassword,
  setOldPasswordError,
  setNewPassword,
  setNewPasswordError,
  setConfirmPassword,
  setConfirmPasswordError,
  setIsPassLoader,
  setLabelPassSave,
  setPassProgress,
  setIsEyePassInput1,
  setIsEyePassInput2,
  setIsEyePassInput3,
  setPassUpdateTimeTxt,
  setShowPassUpdateTime
) => {
  //Loader(state);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "change_password", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        //errorchangepassLoader(state);
        sucesstoast("Password successfully updated.");
        /*state.setState({
          old_password: "",
          new_password: "",
          confirm_password: "",
          new_passworderror: null,
          confirm_passworderror: null,
          old_passworderror: null,
        });*/
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setOldPasswordError(null);
        setNewPasswordError(null);
        setConfirmPasswordError(null);
        setIsPassLoader("d-none");
        setLabelPassSave("Save");
        setPassProgress(null);
        setIsEyePassInput1(false);
        setIsEyePassInput2(false);
        setIsEyePassInput3(false);

        let previous_location = localStorage.getItem("Previouslocation");
        localStorage.clear();
        localStorage.setItem("Previouslocation", previous_location);
        window.location.href = "/sign-in";

        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
      } else if (res.data.code === 401) {
        //errorchangepassLoader(state);
        setIsPassLoader("d-none");
        setLabelPassSave("Save");

        if (res.data.data.length === 0) {
          //errortoast(res.data.status);
          setOldPasswordError(res.data.status);
        } else {
          //errortoast(res.data.data);
          setOldPasswordError(res.data.data);
        }
      } else if (res.data.code === 400) {
        //Remove Pleasewait function
        //errorchangepassLoader(state);
        // errortoast(res.data.status)
        setIsPassLoader("d-none");
        setLabelPassSave("Save");

        if (Object.keys(res.data.data).indexOf("old_password") === -1) {
          /*state.setState({
            old_passworderror: null,
          });*/
          setOldPasswordError(null);
        } else {
          /*state.setState({
            old_passworderror: res.data.data.old_password[0],
          });*/
          setOldPasswordError(res.data.data.old_password[0]);
        }
        if (Object.keys(res.data.data).indexOf("new_password") === -1) {
          /*state.setState({
            new_passworderror: null,
          });*/
          setNewPasswordError(null);
        } else {
          /*state.setState({
            new_passworderror: res.data.data.new_password[0],
          });*/
          setNewPasswordError(res.data.data.new_password[0]);
        }
        if (Object.keys(res.data.data).indexOf("confirm_password") === -1) {
          /*state.setState({
            confirm_passworderror: null,
          });*/
          setConfirmPasswordError(null);
        } else {
          /*state.setState({
            confirm_passworderror: res.data.data.confirm_password[0],
          });*/
          setConfirmPasswordError(res.data.data.confirm_password[0]);
        }
      } else if (res.data.code === 401) {
        //errorchangepassLoader(state);
        setIsPassLoader("d-none");
        setLabelPassSave("Save");
        errortoast(res.data.status);
        if (res.data.status === "Token Expired") {
          // state.props.history.push("/");
          let myItem = localStorage.getItem("buildversion");
          let myItemversion = localStorage.getItem("Apiversion");
          let previous_location = localStorage.getItem("Previouslocation");
          localStorage.clear();
          localStorage.setItem("Previouslocation", previous_location);
          localStorage.setItem("buildversion", myItem);
          localStorage.setItem("Apiversion", myItemversion);
        }
      } else {
        let previous_location = localStorage.getItem("Previouslocation");
        localStorage.clear();
        localStorage.setItem("Previouslocation", previous_location);
        // state.props.history.push("/");
      }
    })
    .catch((error) =>{});
};

export const UpdateSocialStatus = () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(Url + "update-socialstatus", { headers: headers })
    .then((res) => {
      if (res.data.code === 200) {
        
      }
    })
    .catch((err) => {
      
    });
};

//show user profileAPi
export const GetuserprofileApi = (state) => {
  clientid =
    localStorage.getItem("roleid") === "1"
      ? localStorage.getItem("originalid")
      : localStorage.getItem("user_id");
  changeurl();
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Url + "user_profile" , {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        let proimage = "";
        if (res.data.data.profile_image !== "") {
          if (
            res.data.data.profile_image === "null" ||
            res.data.data.profile_image === null ||
            res.data.data.profile_image === ""
          ) {
            proimage = "";
          } else if (res.data.data.profile_image.indexOf("https") === -1) {
            proimage = awspath + res.data.data.profile_image;
          } else {
            proimage = res.data.data.profile_image;
          }
        }
        state.setState({
          fb_spinner: false,
          google_spinner: false,
          updated_at: res.data.data.updated_at,
          customer_type: res.data.data.customer_type,
          latest_updated_at: res.data.data.billing_updated_date
            ? res.data.data.billing_updated_date
            : null,
          name: res.data.data.name,
          email: res.data.data.email,
          oldemail: res.data.data.email,
          profile_image: proimage,
          spinner: false,
          status: res.data.data.status,
          role_id:
            res.data.data.role_id !== undefined ? res.data.data.role_id : 2,
          //billing
          first_name: res.data.data.billing_first_name,
          last_name: res.data.data.billing_last_name,
          company_name: res.data.data.company_name,
          billing_address: res.data.data.billing_address,
          billing_zip: res.data.data.billing_zip_code,
          billing_city: res.data.data.billing_city,
          billing_country:
            res.data.data.country_id === null ? "" : res.data.data.country_id,

          billing_state: res.data.data.billing_state,
          tax_number: res.data.data.tax_number,
          apps_count: res.data.apps_count,
          total_app_count: res.data.total_app_count,
          country_code: res.data.data.country_code === "IN" ? "IN" : "USD",
          customer_billing_type: res.data.data.customer_billing_type,
          user_type: res.data.data.owner,
          linked_google: res.data.linked_social_logins.google,
          linked_facebook: res.data.linked_social_logins.facebook,
          linked_apple: res.data.linked_social_logins.apple,
          user_roles: res.data.data.user_job_role,
          user_industry: res.data.data.user_industry,
          company_size: res.data.data.user_company_size,
          updated_at: res.data.data.updated_at,
        });

        if (res.data.linked_social_logins.google == 3) {
          UpdateSocialStatus();
          errortoast(
            "Your social email address must match your profile email address"
          );
        }

        if (res.data.linked_social_logins.google === 1) {
          state.setState({
            txtgoogle: res.data.data.email,
          });
        } else {
          state.setState({
            txtgoogle: "Google account",
          });
        }

        if (res.data.linked_social_logins.facebook === 1) {
          state.setState({
            txtfacebook: res.data.data.email,
          });
        } else {
          state.setState({
            txtfacebook: "Facebook account",
          });
        }

        //gender
        if (res.data.data.gender === null) {
          state.setState({
            gender: "M",
          });
        } else {
          state.setState({
            gender: res.data.data.gender,
          });
        }
        //phone
        if (res.data.data.phone === null) {
          state.setState({
            phone: "",
          });
        } else {
          state.setState({
            phone: res.data.data.phone,
          });
        }
        //phonecode
        // if (
        //   res.data.data.phone_code === null ||
        //   res.data.data.phone_code === 0
        // ) {
        //   state.setState({
        //     phone_code: "1",
        //     country_name_prefix: "US",
        //   });
        // } else {
        if (res.data.data.phone_code) {
          state.setState({
            phone_code: res.data.data.phone_code,
            // country_name_prefix: res.data.data.country_iso,
            country_name_prefix: res.data.data.phone_code_country_iso,
          });
        }

        if (res.data.data.dateofbirth === null) {
          state.setState({
            date: new Date(Date.now() - 864e5),
          });
        } else {
          let dateofbirth = res.data.data.dateofbirth.split("-");
          state.setState({
            date: new Date(dateofbirth[2], dateofbirth[1] - 1, dateofbirth[0]),
          });
        }
      } else if (res.data.code === 203) {
        errortoast("Session expired ! login again");
        setTimeout(() => {
          let myItem = localStorage.getItem("buildversion");
          let myItemversion = localStorage.getItem("Apiversion");
          let previous_location = localStorage.getItem("Previouslocation");
          localStorage.clear();
          localStorage.setItem("Previouslocation", previous_location);
          localStorage.setItem("buildversion", myItem);
          localStorage.setItem("Apiversion", myItemversion);
          // return (window.location.href = "/");
        }, 2000);
      } else if (res.data.code === 401) {
        errortoast("Session expired ! login again");
        setTimeout(() => {
          let myItem = localStorage.getItem("buildversion");
          let myItemversion = localStorage.getItem("Apiversion");
          let previous_location = localStorage.getItem("Previouslocation");
          localStorage.clear();
          localStorage.setItem("Previouslocation", previous_location);
          localStorage.setItem("buildversion", myItem);
          localStorage.setItem("Apiversion", myItemversion);
          return (window.location.href = "/");
        }, 2000);
      } else {
        setTimeout(() => {
          let myItem = localStorage.getItem("buildversion");
          let myItemversion = localStorage.getItem("Apiversion");
          let previous_location = localStorage.getItem("Previouslocation");
          localStorage.clear();
          localStorage.setItem("Previouslocation", previous_location);
          localStorage.setItem("buildversion", myItem);
          localStorage.setItem("Apiversion", myItemversion);
          return (window.location.href = "/");
        }, 2000);
      }
    })
    .catch((error) => {});
};

//show user profileAPi
export const GetverifyuserprofileApi = (state) => {
  clientid =
    localStorage.getItem("roleid") === "1"
      ? localStorage.getItem("originalid")
      : localStorage.getItem("user_id");
  changeurl();
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Url + "user_profile", {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        if (res.data.data.status == 1) {
          window.location.href = "/apps";
        }
      } else {
        setTimeout(() => {
          let myItem = localStorage.getItem("buildversion");
          let myItemversion = localStorage.getItem("Apiversion");
          let previous_location = localStorage.getItem("Previouslocation");
          localStorage.clear();
          localStorage.setItem("Previouslocation", previous_location);
          localStorage.setItem("buildversion", myItem);
          localStorage.setItem("Apiversion", myItemversion);
          return (window.location.href = "/");
        }, 2000);
      }
    })
    .catch((error) => {});
};

//ProfileApi
export const ProfileApi = (params, state) => {
  Loader(state);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "edit_profile", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        localStorage.setItem("user_profile", res.data.data.profile_image);
        localStorage.setItem("user_name", res.data.data.name);
        state.setState({
          submitval: "Save",
          loader: "d-none",
          updated_at: res.data.data.updated_at,
        });

        let previous_location = localStorage.getItem("Previouslocation");
        if (res.data.is_change_in_email === 1) {
          sucesstoast("Profile successfully updated, Please login again.");
          localStorage.clear();
          state.props.history.push("/");
          // setTimeout(() => {
          //   window.location.reload();
          // }, 2000);
        } else {
          sucesstoast("Profile successfully updated.");
        }
        localStorage.setItem("Previouslocation", previous_location);
        // state.props.history.push("/");
      } else if (res.data.code === 400) {
        if (Object.keys(res.data.data).indexOf("dob") === -1) {
        } else {
          profileloader(state);
          errortoast("Enter a date of birth");
        }
        if (Object.keys(res.data.data).indexOf("phone") === -1) {
        } else {
          profileloader(state);
          errortoast(res.data.data.phone[0]);
        }

        if (Object.keys(res.data.data).indexOf("email") === -1) {
        } else {
          profileloader(state);
          errortoast(res.data.data.email[0]);
        }
        if (Object.keys(res.data.data).indexOf("phonecode") === -1) {
        } else {
          profileloader(state);
          errortoast(res.data.data.phone[0]);
        }

        if (Object.keys(res.data.data).indexOf("user_job_role") === -1) {
        } else {
          profileloader(state);
          errortoast("You missed job role field.");
        }
        if (Object.keys(res.data.data).indexOf("user_industry") === -1) {
        } else {
          profileloader(state);
          errortoast("You missed industry type field.");
        }
        if (Object.keys(res.data.data).indexOf("user_company_size") === -1) {
        } else {
          profileloader(state);
          errortoast("You missed company size field.");
        }
      } else if (res.data.code === 403) {
        profileloader(state);
        errortoast(res.data.data);
      }
    })
    .catch((error) =>{});
};

//Show All country

export const Countrylist = (state, setCountryselectfield) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(Url + "country", {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        if (state === undefined || state === "" || state === null) {
          setCountryselectfield(res.data.data);
        } else {
          state.setState({
            countryselectfield: res.data.data,
            bodyspinner: false,
          });
        }
      }
    })
    .catch((err) => {
    });
};

export const Getdata = (setcountryNamePrefix, setPhoneCode) => {
  axios
    .get(
      "https://api.ipstack.com/check?access_key=" +
        process.env.REACT_APP_ipstack_key +
        "&timestamp=" +
        new Date().getTime()
    )
    .then((res) => {
      
      if (res.data.error === undefined) {
        
        setPhoneCode(res.data.location.calling_code);
        setcountryNamePrefix(res.data.country_code.toLowerCase());
      }
    })
    .catch((error) => {});
};

export const ContactCountrylist = (state, setCountryselectfield) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(Url + "country", {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        if (state === undefined || state === "" || state === null) {
          setCountryselectfield(res.data.data);
          
        } else {
          state.setState({
            countryselectfield: res.data.data,
            bodyspinner: false,
          });
        }
      }
    })
    .catch((err) => {
      
    });
};

//Billing APi
export const BillingApi = (params, state, callback) => {
  Loader(state);
  state.setState({
    submitbillival: "",
    billingloader: "d-block",
    disabledsubmitbutton: true,
  });
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "save-billing-details", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200 || res.data.code === 201) {
        state.setState({
          submitbillival: "Save",
          billingloader: "d-none",
          disabledsubmitbutton: false,
        });
        if (params.page === "billing") {
          sucesstoast("Billing details saved successfully");
        }
        callback();
      } else if (res.data.code === 400) {
        errortoast("Please fill all the billing details");

        state.setState({
          submitbillival: "Save",
          billingloader: "d-none",
          addCardLoader: "d-none",
          addCardText: "Add a card",
          loader: "d-none",
          formbuttondisabled: false,
          disabledsubmitbutton: false,
          submitval: "Make payment",
        });
      } else {
        state.setState({
          submitbillival: "Save",
          billingloader: "d-none",
          disabledsubmitbutton: false,
        });
      }
    })
    .catch((error) => {
      
      state.setState({
        submitbillival: "Save",
        billingloader: "d-none",
        disabledsubmitbutton: false,
      });
      errortoast("Something went wrong. Try again after sometime.");
    });
};

//Show Android Store detail
export const Getandroidstoredetail = (state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(
      Url +
        "get_android_store_credential/" +
        btoa(localStorage.getItem("Appid")),
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          spinner: false,
          shortdescription: res.data.data.app_title,
          privacypolicyurl: res.data.data.privacy_policy_link,
          fulldescription: res.data.data.full_description,

          apptitle: res.data.data.app_title,
        });
        if (res.data.data.privacy_policy_bool === 0) {
          state.setState({
            privacypolicyboolen: false,
          });
        } else {
          state.setState({
            privacypolicyboolen: true,
          });
        }
      }
    })
    .catch((error) => {
      
      errortoast("Something went wrong. Try again after sometime.");
    });
};

//Show Ios Store detail
export const Getiosstoredetail = (state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(
      Url + "get_iosstore_credential/" + btoa(localStorage.getItem("Appid")),
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          spinner: false,
          appname: res.data.data.name,
          subtitle: res.data.data.subtitle,
          keywords: res.data.data.keywords,
          appcategory: res.data.data.category,
          promotionaltext: res.data.data.promotional_text,
          description: res.data.data.description,
          privacypolicyurl: res.data.data.privacy_policy_url,
          supporturl: res.data.data.support_url,
          marketingurl: res.data.data.marketing_url,
        });
      }
    })
    .catch((error) => {
      
      errortoast("Something went wrong. Try again after sometime.");
    });
};

//Android Store Detail Api
export const Androidstoreapi = (params, state) => {
  Loader(state);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "android_store_credential", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        sucesstoast("Android App submission done successfully");
        state.setState({
          submitval: "SUBMIT",
          loader: "d-none",
        });
      } else if (res.data.code === 400) {
        state.setState({
          submitval: "SUBMIT",
          loader: "d-none",
        });
        //short_description Error
        if (Object.keys(res.data.data).indexOf("short_description") === -1) {
          state.setState({
            shortdescriptionerror: null,
          });
        } else {
          state.setState({
            shortdescriptionerror: res.data.data.short_description[0],
          });
        }

        //short_description Error
        if (Object.keys(res.data.data).indexOf("full_description") === -1) {
          state.setState({
            fulldescriptionerror: null,
          });
        } else {
          state.setState({
            fulldescriptionerror: res.data.data.full_description[0],
          });
        }
        //privacypolicyurl Error
        if (Object.keys(res.data.data).indexOf("privacy_policy_link") === -1) {
          state.setState({
            privacypolicyurlerror: null,
          });
        } else {
          state.setState({
            privacypolicyurlerror: res.data.data.privacy_policy_link[0],
          });
        }
      }
    })
    .catch((error) => {
      
      errortoast("Something went wrong. Try again after sometime.");
    });
};

//Ios Store Detail Api
export const Iosstoreapi = (params, state) => {
  Loader(state);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "ios_store_credential", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        sucesstoast("iOS App submission done successfully");
        state.setState({
          submitval: "SUBMIT",
          loader: "d-none",
        });
      } else if (res.data.code === 400) {
        state.setState({
          submitval: "SUBMIT",
          loader: "d-none",
        });

        //appcategoryerror Error
        if (Object.keys(res.data.data).indexOf("category") === -1) {
          state.setState({
            appcategoryerror: null,
          });
        } else {
          state.setState({
            appcategoryerror: res.data.data.category[0],
          });
        }

        //name Error
        if (Object.keys(res.data.data).indexOf("name") === -1) {
          state.setState({
            appnameerror: null,
          });
        } else {
          state.setState({
            appnameerror: res.data.data.name[0],
          });
        }

        //description Error
        if (Object.keys(res.data.data).indexOf("description") === -1) {
          state.setState({
            descriptionerror: null,
          });
        } else {
          state.setState({
            descriptionerror: res.data.data.description[0],
          });
        }
        //keywords Error
        if (Object.keys(res.data.data).indexOf("keywords") === -1) {
          state.setState({
            keywordserror: null,
          });
        } else {
          state.setState({
            keywordserror: res.data.data.keywords[0],
          });
        }

        //marketingurlerror Error
        if (Object.keys(res.data.data).indexOf("marketing_url") === -1) {
          state.setState({
            marketingurlerror: null,
          });
        } else {
          state.setState({
            marketingurlerror: res.data.data.marketing_url[0],
          });
        }

        //privacypolicyurlerror Error
        if (Object.keys(res.data.data).indexOf("privacy_policy_url") === -1) {
          state.setState({
            privacypolicyurlerror: null,
          });
        } else {
          state.setState({
            privacypolicyurlerror: res.data.data.privacy_policy_url[0],
          });
        }
        //promotional_text Error
        if (Object.keys(res.data.data).indexOf("promotional_text") === -1) {
          state.setState({
            promotionaltexterror: null,
          });
        } else {
          state.setState({
            promotionaltexterror: res.data.data.promotional_text[0],
          });
        }

        //subtitle Error
        if (Object.keys(res.data.data).indexOf("subtitle") === -1) {
          state.setState({
            subtitleerror: null,
          });
        } else {
          state.setState({
            subtitleerror: res.data.data.subtitle[0],
          });
        }
        //support_url Error
        if (Object.keys(res.data.data).indexOf("support_url") === -1) {
          state.setState({
            supporturlerror: null,
          });
        } else {
          state.setState({
            supporturlerror: res.data.data.support_url[0],
          });
        }
      }
    })
    .catch((error) => {
      
      errortoast("Something went wrong. Try again after sometime.");
    });
};

//Appcredentailapi Getapi
export const Getappcredentailapi = (state, fromurl, callback) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Url + "get_credentials/" + btoa(localStorage.getItem("Appid")), {
      headers: headers,
    })
    .then((res) => {
      let sendwrl = res.data.data.websiteurl;

      if (
        res.data.data.websiteurl[res.data.data.websiteurl.length - 1] === "/"
      ) {
        sendwrl = res.data.data.websiteurl;
      } else {
        sendwrl = res.data.data.websiteurl + "/";
      }

      if (res.data.code === 200) {
        if (fromurl === "download") {
          state.setState({
            ck: res.data.data.client_key,
            cs: res.data.data.client_secret,
            sendwrl: sendwrl,
            allowbutton: true,
            troubleshooturl: res.data.data.websiteurl,
            is_verified: res.data.data.is_verified.toString(),
            spinner: false,
            submitval: "Verify",

            finalimg:
              res.data.data.is_verified.toString() === "0"
                ? require("../../assets/images/dashboard/firstwordpress.png")
                    .default
                : require("../../assets/images/dashboard/successwordpress.png")
                    .default,
            is_connected: res.data.data.is_connected,
          });
        } else {
          state.setState({
            spinner: false,
            apisecret: res.data.data.client_key,
            apikey: res.data.data.client_secret,
            verifykey: res.data.data.client_key,
            success: res.data.data.is_connected === 1 ? true : "null",
            websiteurl: res.data.data.websiteurl,
            securetoggle:
              res.data.data.connection_to_establish === 1 ? true : false,
          });
          callback();
        }
      } else if (res.data.code === 203) {
        if (res.data.status === "Token Expired") {
          // state.props.history.push("/");
          let myItem = localStorage.getItem("buildversion");
          let myItemversion = localStorage.getItem("Apiversion");
          let previous_location = localStorage.getItem("Previouslocation");
          localStorage.clear();
          localStorage.setItem("Previouslocation", previous_location);
          localStorage.setItem("buildversion", myItem);
          localStorage.setItem("Apiversion", myItemversion);
        }
        state.setState({
          spinner: false,
          apikey: "",
          apisecret: "",
        });
      }
    })
    .catch((error) => {
      state.setState({
        spinner: false,
      });
      
      errortoast("Something went wrong. Try again after sometime.");
    });
};
//In loop
export const Getappcredentailapiloop = (state, fromurl) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Url + "get_credentials/" + btoa(localStorage.getItem("Appid")), {
      headers: headers,
    })
    .then((res) => {
      let sendwrl = res.data.data.websiteurl;

      if (
        res.data.data.websiteurl[res.data.data.websiteurl.length - 1] === "/"
      ) {
        sendwrl = res.data.data.websiteurl;
      } else {
        sendwrl = res.data.data.websiteurl + "/";
      }

      if (res.data.code === 200) {
        if (fromurl === "download") {
          state.setState({
            ck: res.data.data.client_key,
            cs: res.data.data.client_secret,
            sendwrl: sendwrl,
          });
          const topheaders = {
            "Content-Type": "application/json",
          };

          axios
            .get(
              process.env.REACT_APP_cors_api +
                sendwrl.replace("www.", "") +
                "wp-json/wc/v3/system_status?consumer_secret=" +
                res.data.data.client_secret +
                "%26consumer_key=" +
                res.data.data.client_key +
                "%&timestamp=" +
                new Date().getTime(),
              {
                headers: topheaders,
              }
            )
            .then((res) => {
              let i = 0;
              let pluginname = [];
              let realurl = res.data.environment.site_url;

              for (i = 0; i < res.data.active_plugins.length; i++) {
                pluginname.push(res.data.active_plugins[i].name);
              }
              if (pluginname.indexOf("AppMySite") === -1) {
                state.setState({
                  puginbutton: "INSTALL PLUGIN",
                  spinner: false,
                  finalimg:
                    require("../../assets/images/dashboard/installed-plugin.png")
                      .default,
                  storeurl:
                    realurl +
                    "/wp-admin/plugin-install.php?s=appmysite&tab=search&type=term",
                });
              } else {
                state.setState({
                  puginbutton: "INSTALLED",
                  spinner: false,
                  finalimg:
                    require("../../assets/images/dashboard/successfulinstalled.png")
                      .default,
                  storeurl:
                    realurl +
                    "/wp-admin/plugin-install.php?s=appmysite&tab=search&type=term",
                });
              }
            })
            .catch((error) => {
              
              errortoast("Something went wrong. Try again after sometime.");
              state.setState({
                spinner: false,
              });
            });
        } else {
          state.setState({
            spinner: false,
            apisecret: res.data.data.client_key,
            apikey: res.data.data.client_secret,
            success: res.data.data.client_key !== "" ? true : "null",
          });
        }
      }
    })
    .catch((error) => {
      
      errortoast("Something went wrong. Try again after sometime.");
      state.setState({
        spinner: false,
      });
    });
};

export const UploadAppIcon = (params, state) => {
  // Loader(state);
  state.setState({
    disabledsubmitbutton: true,
  });
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "app_icons", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          submitval:
            localStorage.getItem("app_icon_count") === "0" ? "Next" : "Save",
          loader: "d-none",
          disabledsubmitbutton: false,
          hit: true,
          savespinner: false,
        });
        sucesstoast("App Icon designed successfully.");
        // localStorage.getItem("app_icon_count") === "0"
        //   ? state.props.history.push("/app/appearance/launch-screen")
        //   : sucesstoast("App Icon designed successfully.");
      } else if (res.data.code === 400) {
        state.setState({
          submitval:
            localStorage.getItem("app_icon_count") === "0" ? "Next" : "Save",
          loader: "d-none",
          disabledsubmitbutton: false,
        });
        //appicontxt Error
        if (Object.keys(res.data.data).indexOf("appicontxt") === -1) {
          state.setState({
            appicontxterror: null,
            disabledsubmitbutton: false,
          });
        } else {
          //toast
          errortoast(res.data.data.appicontxt[0]);
        }
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

export const SaveUploadAppIcon = (params, state) => {
  // Loader(state);
  state.setState({
    disabledsubmitbutton: true,
  });
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(saveceritificate + "save-app-icon", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          savespinner: false,
          last_updated_timestamp: res.data.data.updated_at,
        });
        sucesstoast("Your changes have been saved.");
      } else if (res.data.code === 400) {
        state.setState({
          savespinner: false,
        });
        if (Object.keys(res.data.data).indexOf("appicontxt") === -1) {
          state.setState({
            appicontxterror: null,
            disabledsubmitbutton: false,
          });
          errortoast(res.data.status);
        } else {
          errortoast(res.data.status);
        }
      }
    })
    .catch((error) => {});
};

//General App Icon get api
export const GetuploadAppIcon = (state) => {
  clientid = localStorage.getItem("user_id");
  changeurl();
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Url + "get_app_icons/" + btoa(localStorage.getItem("Appid")), {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        if (state.stopmounting === true) {
          let back = "";
          let activetab = "1";
          if (res.data.data.app_icon) {
            back =
              "url(" +
              appicon +
              localStorage.getItem("Appid") +
              process.env.REACT_APP_AppIcon_Display +
              res.data.data.app_icon +
              ")";
            activetab = "2";
          }
          state.setState({
            plan_code: res.data.data.app_plan,
            spinner: false,
            //original image
            app_original_icon:
              res.data.data.app_original_icon === ""
                ? ""
                : appicon +
                  localStorage.getItem("Appid") +
                  "/app_icon/original_image/" +
                  res.data.data.app_original_icon,
            finalfile: res.data.data.app_icon,
            //App name
            appIconText:
              res.data.data.app_icon_text === ""
                ? localStorage.getItem("appshortname")
                : res.data.data.app_icon_text,
            //App Font Family
            fontFamily:
              res.data.data.app_icon_font_name === ""
                ? "Poppins-SemiBold"
                : res.data.data.app_icon_font_name.indexOf("hitmo") > -1
                ? res.data.data.app_icon_font_name.replace(".", "")
                : res.data.data.app_icon_font_name,
            //App Font Size
            fontSize:
              res.data.data.app_icon_font_size === ""
                ? "14px"
                : res.data.data.app_icon_font_size,
            //App Font Color
            fontColor:
              res.data.data.app_icon_text_color === ""
                ? "#ffffff"
                : res.data.data.app_icon_text_color,
            initialfontcolor:
              res.data.data.app_icon_text_color === ""
                ? "#ffffff"
                : res.data.data.app_icon_text_color,

            //App background Color
            appbackgroundColor:
              res.data.data.app_icon_bg_color === ""
                ? "#3064f9"
                : res.data.data.app_icon_bg_color,
            defaultbackgroundColor: back,
            initial:
              res.data.data.app_icon_bg_color === ""
                ? "#3064f9"
                : res.data.data.app_icon_bg_color,

            defaultimage:
              back === ""
                ? "url(" +
                  require("../../assets/images/dashboard/storage.png").default +
                  ")"
                : back,
            uploadtext: back === "" ? "" : "Click here to upload an image.",
            //Active Tab
            activeTab: activetab,
            //Publish Boolen
            is_publish: res.data.data.build_status,
            disabledsubmitbutton:
              res.data.data.is_build_processing === 0 ? false : true,
            hit:
              res.data.data.build_status.android_success_build_count > 0 ||
              res.data.data.build_status.ios_success_build_count > 0
                ? false
                : true,
            //Image send in API
            iconImage:
              res.data.data.app_icon === ""
                ? ""
                : appicon +
                  localStorage.getItem("Appid") +
                  process.env.REACT_APP_AppIcon_Display +
                  res.data.data.app_icon,
            // presetColors:
            //   res.data.website_colors !== null
            //     ? res.data.website_colors
            //     : undefined,
            setcolor: true,
            // defaultimage:res.data.data.uploaded_icon === "" ? 'url(' + require("../../assets/images/dashboard/storage.png").default + ')' : appicon + res.data.data.uploaded_icon
          });
        }
      } else if (res.data.code === 401) {
        if (res.data.data === "App does not exists.") {
          state.props.history.push("/apps");
          errortoast(res.data.data);
        }
      } else {
        errortoast("Something went wrong. Try again after sometime.");
        state.setState({
          spinner: false,
        });
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

const getBase64FromUrl = async (useUrl) => {
  try {
    const data = await fetch(useUrl);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  } catch {
    errortoast("Something went wrong please try again");
  }
};

//General App Icon get api
export const GetNewuploadAppIcon = (state) => {
  clientid = localStorage.getItem("user_id");
  changeurl();
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(
      saveceritificate + "get-app-icon/" + btoa(localStorage.getItem("Appid")),
      {
        headers: headers,
      }
    )
    .then(async (res) => {
      if (res.data.code === 200) {
        if (
          res.data.data.upload_icon === undefined &&
          (res.data.data.app_icon === undefined ||
            res.data.data.app_icon === "")
        ) {
          
          var urlToFile =
            process.env.REACT_APP_Image_Path +
            localStorage.getItem("user_id") +
            "/" +
            localStorage.getItem("Appid") +
            "/app_icon/iOS/" +
            res.data.cropped_app_icon;
          var uploadPath =
            localStorage.getItem("user_id") +
            "/" +
            localStorage.getItem("Appid") +
            process.env.REACT_APP_AppIcon_Crop;

          const filename =
            "ams_" +
            new Date().getTime() +
            "_" +
            Math.floor(1000 + Math.random() * 9000);

          try {
            

            //send to s3
            let base64Image = await getBase64FromUrl(urlToFile);

            var arr = base64Image.split(","),
              mime = "image/png",
              bstr = atob(arr[1]),
              n = bstr.length,
              u8arr = new Uint8Array(n);

            while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
            }
            var imagefiledatavalue = new Blob([u8arr], { type: mime });

            const config = {
              bucketName: process.env.REACT_APP_bucketName,
              dirName: uploadPath.replace(/\/$/, "") /* optional */,
              region: process.env.REACT_APP_region,
              accessKeyId: process.env.REACT_APP_accessKeyId,
              secretAccessKey: process.env.REACT_APP_secretAccessKey,
              s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(
                /\/$/,
                ""
              ) /* optional */,
            };
            // const ReactS3Client = new CustomS3Client(config);
            const customS3Client = new CustomS3Client(config);
            customS3Client
              .uploadFile(imagefiledatavalue, filename)
              .then((data) => {
                if (data.status === 204) {
                  state.setState({
                    is_default: res.data.is_default,
                    finalfile: filename.includes('.png') ? filename : filename + ".png",
                    original_icon:
                      res.data.data.app_original_icon !== undefined &&
                      res.data.data.app_original_icon !== ""
                        ? res.data.data.app_original_icon
                        : res.data.data.original_icon !== undefined &&
                          res.data.data.original_icon !== ""
                        ? res.data.data.original_icon
                        : res.data.data.app_frontend_display_icon !==
                            undefined && res.data.data.original_icon !== ""
                        ? res.data.data.app_frontend_display_icon
                        : res.data.data.upload_icon,
                    designid: res.data.data.design_id,
                    spinner: false,
                    is_publish: res.data.build_status,
                    last_updated_timestamp:
                      res.data.data.last_updated_timestamp,
                  });
                }
              })
              .catch((err) => {
                
                state.setState({
                  is_default: res.data.is_default,
                  finalfile:
                    res.data.data.upload_icon !== undefined
                      ? res.data.data.upload_icon
                      : res.data.data.app_icon !== undefined &&
                        res.data.data.app_icon !== ""
                      ? res.data.data.app_icon
                      : res.data.data.app_frontend_display_icon !== undefined &&
                        res.data.data.app_frontend_display_icon !== ""
                      ? res.data.data.app_frontend_display_icon
                      : "",
                  original_icon:
                    res.data.data.app_original_icon !== undefined &&
                    res.data.data.app_original_icon !== ""
                      ? res.data.data.app_original_icon
                      : res.data.data.original_icon !== undefined &&
                        res.data.data.original_icon !== ""
                      ? res.data.data.original_icon
                      : res.data.data.app_frontend_display_icon !== undefined &&
                        res.data.data.original_icon !== ""
                      ? res.data.data.app_frontend_display_icon
                      : res.data.data.upload_icon,
                  designid: res.data.data.design_id,
                  is_publish: res.data.build_status,
                  spinner: false,
                  last_updated_timestamp: res.data.data.last_updated_timestamp,
                });
              });
          } catch (error) {
            state.setState({
              is_default: res.data.is_default,
              finalfile:
                res.data.data.upload_icon !== undefined
                  ? res.data.data.upload_icon
                  : res.data.data.app_icon !== undefined &&
                    res.data.data.app_icon !== ""
                  ? res.data.data.app_icon
                  : res.data.data.app_frontend_display_icon !== undefined &&
                    res.data.data.app_frontend_display_icon !== ""
                  ? res.data.data.app_frontend_display_icon
                  : "",
              original_icon:
                res.data.data.app_original_icon !== undefined &&
                res.data.data.app_original_icon !== ""
                  ? res.data.data.app_original_icon
                  : res.data.data.original_icon !== undefined &&
                    res.data.data.original_icon !== ""
                  ? res.data.data.original_icon
                  : res.data.data.app_frontend_display_icon !== undefined &&
                    res.data.data.original_icon !== ""
                  ? res.data.data.app_frontend_display_icon
                  : res.data.data.upload_icon,
              designid: res.data.data.design_id,
              spinner: false,
              is_publish: res.data.build_status,
              last_updated_timestamp: res.data.data.last_updated_timestamp,
            });
          }
        } else {
          state.setState({
            is_default: res.data.is_default,
            finalfile:
              res.data.data.upload_icon !== undefined
                ? res.data.data.upload_icon
                : res.data.data.app_icon !== undefined &&
                  res.data.data.app_icon !== ""
                ? res.data.data.app_icon
                : res.data.data.app_frontend_display_icon !== undefined &&
                  res.data.data.app_frontend_display_icon !== ""
                ? res.data.data.app_frontend_display_icon
                : "",
            original_icon:
              res.data.data.app_original_icon !== undefined &&
              res.data.data.app_original_icon !== ""
                ? res.data.data.app_original_icon
                : res.data.data.original_icon !== undefined &&
                  res.data.data.original_icon !== ""
                ? res.data.data.original_icon
                : res.data.data.app_frontend_display_icon !== undefined &&
                  res.data.data.original_icon !== ""
                ? res.data.data.app_frontend_display_icon
                : res.data.data.upload_icon,
            designid: res.data.data.design_id,
            spinner: false,
            is_publish: res.data.build_status,
            last_updated_timestamp: res.data.data.last_updated_timestamp,
          });
        }
      } else if (res.data.code === 401) {
        if (res.data.data === "App does not exists.") {
          state.props.history.push("/apps");
          errortoast(res.data.data);
          state.setState({
            spinner: false,
          });
        }
      } else {
        errortoast("Something went wrong. Try again after sometime.");
        state.setState({
          spinner: false,
        });
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

//General App Icon get api
export const GetsplashApi = (state, callback) => {
  clientid = localStorage.getItem("user_id");
  changeurl();
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Url + "get_splash/" + btoa(localStorage.getItem("Appid")), {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        let activetab = "1";
        let checkApplogo = false;
        let selectbackgroundorcolor = true;
        if (res.data.data.splash_screen_bg_image) {
          selectbackgroundorcolor = false;
        }

        if (
          res.data.data.splash_screen_bg_image === "" &&
          res.data.data.splash_screen_bg_color === ""
        ) {
          selectbackgroundorcolor = false;
        }

        if (res.data.data.splash_screen_app_logo_image) {
          activetab = "2";
          checkApplogo = true;
        }
        state.setState({
          plan_code: res.data.data.app_plan,
          splashBackground: res.data.data.splash_screen_bg_color,
          initialsplash:
            res.data.data.splash_screen_bg_color === ""
              ? "#3064f9"
              : res.data.data.splash_screen_bg_color,
          selectpixabayimage:
            res.data.data.splash_screen_bg_image !== ""
              ? splashimg +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_SplashScreen_Crop +
                res.data.data.splash_screen_bg_image
              : res.data.data.splash_screen_bg_image === "" &&
                res.data.data.splash_screen_bg_color === ""
              ? process.env.REACT_APP_Image_Path +
                "assets/images/default/floating-unicorn.jpg"
              : "",

          uploadedThumbnail:
            res.data.data.splash_screen_bg_image !== ""
              ? splashimg +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_SplashScreen_Crop +
                res.data.data.splash_screen_bg_image
              : res.data.data.splash_screen_bg_image === "" &&
                res.data.data.splash_screen_bg_color === ""
              ? process.env.REACT_APP_Image_Path +
                "assets/images/default/floating-unicorn.jpg"
              : "",

          low_resolution_image:
            res.data.data.splash_screen_bg_image !== ""
              ? splashimg +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_SplashScreen_Crop +
                res.data.data.splash_screen_bg_image
              : res.data.data.splash_screen_bg_image === "" &&
                res.data.data.splash_screen_bg_color === ""
              ? process.env.REACT_APP_Image_Path +
                "assets/images/default/floating-unicorn.jpg"
              : "assets/images/dashboard/ams_1710148542_9783.png",
          imagename:
            res.data.data.splash_screen_bg_image !== ""
              ? res.data.data.splash_screen_bg_image
              : res.data.data.splash_screen_bg_image === "" &&
                res.data.data.splash_screen_bg_color === ""
              ? "floating-unicorn.jpg"
              : "",
          uploadcustomdefaultimageApi:
            res.data.data.splash_screen_bg_image !== ""
              ? splashimg +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_SplashScreen_Crop +
                res.data.data.splash_screen_bg_image
              : res.data.data.splash_screen_bg_image === "" &&
                res.data.data.splash_screen_bg_color === ""
              ? process.env.REACT_APP_Image_Path +
                "assets/images/default/floating-unicorn.jpg"
              : "",
          original_bgimg:
            res.data.data.splash_screen_bg_image !== ""
              ? splashimg +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_SplashScreen_Crop +
                res.data.data.splash_screen_bg_image
              : res.data.data.splash_screen_bg_image === "" &&
                res.data.data.splash_screen_bg_color === ""
              ? process.env.REACT_APP_Image_Path +
                "assets/images/default/floating-unicorn.jpg"
              : "",

          chooseImage:
            res.data.data.splash_screen_bg_image !== ""
              ? splashimg +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_SplashScreen_Crop +
                res.data.data.splash_screen_bg_image
              : res.data.data.splash_screen_bg_image === "" &&
                res.data.data.splash_screen_bg_color === ""
              ? process.env.REACT_APP_Image_Path +
                "assets/images/default/floating-unicorn.jpg"
              : "",

          //App logo logotext
          logoText:
            res.data.data.splash_screen_app_logo_text === ""
              ? ""
              : res.data.data.splash_screen_app_logo_text.slice(0, 15),
          //App logo fontcolor
          fontColor:
            res.data.data.splash_screen_app_logo_text_color === ""
              ? "#FFFFFF"
              : res.data.data.splash_screen_app_logo_text_color,
          initialfontcolor:
            res.data.data.splash_screen_app_logo_text_color === ""
              ? "#FFFFFF"
              : res.data.data.splash_screen_app_logo_text_color,
          //App logo fontfamily
          fontFamily:
            res.data.data.splash_screen_app_logo_text_font === ""
              ? "Poppins-SemiBold"
              : res.data.data.splash_screen_app_logo_text_font.indexOf(
                  "hitmo"
                ) > -1
              ? res.data.data.splash_screen_app_logo_text_font.replace(".", "")
              : res.data.data.splash_screen_app_logo_text_font,
          //App logo fontsize
          fontSize:
            res.data.data.splash_screen_app_logo_text_font_size === ""
              ? "24px"
              : res.data.data.splash_screen_app_logo_text_font_size,
          //Alignment
          alignLogo: res.data.data.splash_screen_app_logo_align,
          //App logo Image
          iconImage:
            res.data.data.splash_screen_app_logo_image === ""
              ? ""
              : applogo +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_SplashLogo_Crop +
                res.data.data.splash_screen_app_logo_image,
          defaultimage:
            res.data.data.splash_screen_app_logo_image === ""
              ? require("../../assets/images/dashboard/storage.png").default
              : applogo +
                +localStorage.getItem("Appid") +
                process.env.REACT_APP_SplashLogo_Crop +
                res.data.data.splash_screen_app_logo_image,
          original_logo:
            res.data.data.splash_screen_app_logo_image === ""
              ? ""
              : applogo +
                +localStorage.getItem("Appid") +
                process.env.REACT_APP_SplashLogo_Original +
                res.data.data.splash_screen_original_app_logo,
          uploadtext:
            res.data.data.splash_screen_app_logo_image === ""
              ? ""
              : "Click here to upload an image.",
          checkApplogo: checkApplogo,
          //Published
          is_publish: res.data.data.build_status,
          hit:
            res.data.data.build_status.android_success_build_count > 0 ||
            res.data.data.build_status.ios_success_build_count > 0
              ? false
              : true,
          //Tab
          activeTab: activetab,
          //Loader
          spinner: false,
          selectbackgroundorcolor: selectbackgroundorcolor,

          //customlogo
          customlogo:
            res.data.data.customlogo === ""
              ? ""
              : applogo +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_SplashLogo_Crop +
                res.data.data.customlogo,
          //customlogo:res.data.data.customlogo,
          customlogotext: res.data.data.splash_screen_app_logo_text.trim(),
          customlogocolor: res.data.data.splash_screen_app_logo_text_color,
          customfontfamily: res.data.data.splash_screen_app_logo_text_font,
          customfontsize: res.data.data.splash_screen_app_logo_text_font_size,
          //tint
          tabselection:
            res.data.data.splash_screen_bg_color !== ""
              ? "or"
              : res.data.data.opacity_color === undefined ||
                res.data.data.opacity_color === ""
              ? "or"
              : "and",
          oractive:
            res.data.data.splash_screen_bg_color !== ""
              ? "active_tab"
              : res.data.data.opacity_color === undefined ||
                res.data.data.opacity_color === ""
              ? "active_tab"
              : "or_not_active_tab",
          andactive:
            res.data.data.splash_screen_bg_color !== ""
              ? "not_active_tab"
              : res.data.data.opacity_color === undefined ||
                res.data.data.opacity_color === ""
              ? "not_active_tab"
              : "active_tab",
          tintcolor:
            res.data.data.opacity_color === undefined ||
            res.data.data.opacity_color === ""
              ? "#636161"
              : res.data.data.opacity_color,
          range:
            res.data.data.opacity_percent === undefined ||
            res.data.data.opacity_percent === ""
              ? 20
              : parseInt(res.data.data.opacity_percent),
          disabledsubmitbutton:
            res.data.data.is_build_processing === 0 ? false : true,
        });

        let url =
          res.data.data.splash_screen_app_logo_image === ""
            ? ""
            : applogo +
              localStorage.getItem("Appid") +
              process.env.REACT_APP_SplashLogo_Crop +
              res.data.data.splash_screen_app_logo_image;

        let url1 =
          res.data.data.splash_screen_bg_image !== ""
            ? splashimg +
              localStorage.getItem("Appid") +
              process.env.REACT_APP_SplashScreen_Crop +
              res.data.data.splash_screen_bg_image
            : res.data.data.splash_screen_bg_image === "" &&
              res.data.data.splash_screen_bg_color === ""
            ? process.env.REACT_APP_Image_Path +
              "assets/images/floating-unicorn.jpg"
            : "";

        if (url) {
          let base64data = state.getBase64FromUrl(url);
        }

        if (url1) {
          let base64data1 = state.getBase64FromUrl1(url1);
        }

        

        callback();
      } else if (res.data.code === 401) {
        if (res.data.data === "App does not exists.") {
          state.props.history.push("/apps");
          errortoast(res.data.data);
        }
      } else {
        errortoast("Something went wrong. Try again after sometime.");
        state.setState({
          spinner: false,
        });
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

//General App Icon get api
export const NewGetsplashApi = (state, callback) => {
  clientid = localStorage.getItem("user_id");
  changeurl();
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Url + "get_splash/" + btoa(localStorage.getItem("Appid")), {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          is_default: res.data.is_default,
          designid: res.data.data.design_id,
          is_publish: res.data.build_status,
          spinner: false,
          last_updated_timestamp: res.data.data.last_updated_timestamp,
          finalfile:
            res.data.data.upload_image !== undefined &&
            res.data.data.upload_image !== ""
              ? res.data.data.upload_image
              : res.data.data.splash_screen !== undefined &&
                res.data.data.splash_screen !== ""
              ? res.data.data.splash_screen
              : res.data.data.splash_screen_bg_image,
        });
      } else if (res.data.code === 401) {
        if (res.data.data === "App does not exists.") {
          state.props.history.push("/apps");
          errortoast(res.data.data);
        }
      } else {
        errortoast("Something went wrong. Try again after sometime.");
        state.setState({
          spinner: false,
        });
      }
    })
    .catch((error) => {
      
      state.setState({
        spinner: false,
      });
      // errortoast("Something went wrong. Try again after sometime.");
    });
};
//SplashAPI
export const SplashApi = (params, state) => {
  Loader(state);
  state.setState({
    disabledsubmitbutton: true,
  });
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "splash", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          submitval:
            localStorage.getItem("splash_icon_count") === "0" ? "Save" : "Save",
          loader: "d-none",
          disabledsubmitbutton: false,
          savespinner: false,
          last_updated_timestamp: res.data.data.updated_at,
        });
        if (
          parseInt(localStorage.getItem("website_technology")) === 2 ||
          parseInt(localStorage.getItem("website_technology")) === 3
        ) {
          sucesstoast("Your changes have been saved.");
          // state.props.history.push("/app/theme");
        } else {
          sucesstoast("Your changes have been saved.");
          // localStorage.getItem("splash_icon_count") === "0"
          //   ? state.props.history.push("/app/appearance/login-screen")
          //   : sucesstoast("Launch Screen designed successfully.");
        }
        if (localStorage.getItem("splash_icon_count") === "1") {
          state.loadFirst();
        }
      } else {
        errortoast("Something went wrong. Try again after sometime.");
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

//LoginsignApi  Icon get api
export const GetLoginsignupAPI = (state, callback) => {
  clientid = localStorage.getItem("user_id");
  changeurl();
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Url + "get_login_signup/" + btoa(localStorage.getItem("Appid")), {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200 && res.data.data !== null) {
        let checkApplogo = false;
        let selectbackgroundorcolor = true;
        if (res.data.data.login_screen_app_logo_image) {
          checkApplogo = true;
        }
        if (res.data.data.login_screen_bg_image || res.data.is_default === 1) {
          selectbackgroundorcolor = false;
        }

        state.setState({
          //Background Color
          default: res.data.is_default,
          splashBackground:
            res.data.data.login_screen_bg_color_object !== undefined &&
            res.data.data.login_screen_bg_color_object !== undefined
              ? res.data.data.login_screen_bg_color_object.portal_data
              : res.data.data.login_screen_bg_color,

          initial:
            res.data.data.login_screen_bg_color_object !== undefined &&
            res.data.data.login_screen_bg_color_object !== undefined
              ? res.data.data.login_screen_bg_color_object.portal_data
              : res.data.data.login_screen_bg_color === ""
              ? "#3064f9"
              : res.data.data.login_screen_bg_color,
          //Background Color Image
          selectpixabayimage:
            res.data.is_default === 1
              ? process.env.REACT_APP_Image_Path +
                "assets/images/default/hearts-beeds.jpg"
              : res.data.data.login_screen_bg_image === ""
              ? ""
              : res.data.data.checkimage === "splash"
              ? splashimg +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_SplashScreen_Crop +
                res.data.data.login_screen_bg_image
              : loginbackground +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_LoginBackground_Crop +
                res.data.data.login_screen_bg_image,
          low_resolution_image:
            res.data.is_default === 1
              ? process.env.REACT_APP_Image_Path +
                "assets/images/default/hearts-beeds.jpg"
              : res.data.data.login_screen_bg_image === ""
              ? "assets/images/dashboard/ams_1710148542_9783.png"
              : res.data.data.checkimage === "splash"
              ? splashimg +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_SplashScreen_Crop +
                res.data.data.login_screen_bg_image
              : loginbackground +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_LoginBackground_Crop +
                res.data.data.login_screen_bg_image,
          uploadcustomdefaultimageApi:
            res.data.is_default === 1
              ? process.env.REACT_APP_Image_Path +
                "assets/images/default/hearts-beeds.jpg"
              : res.data.data.login_screen_bg_image === ""
              ? ""
              : res.data.data.checkimage === "splash"
              ? splashimg +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_SplashScreen_Crop +
                res.data.data.login_screen_bg_image
              : loginbackground +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_LoginBackground_Crop +
                res.data.data.login_screen_bg_image,
          original_bgimg:
            res.data.is_default === 1
              ? process.env.REACT_APP_Image_Path +
                "assets/images/default/hearts-beeds.jpg"
              : res.data.data.login_screen_bg_image === ""
              ? ""
              : res.data.data.checkimage === "splash"
              ? splashimg +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_SplashScreen_Crop +
                res.data.data.login_screen_original_bg_image
              : res.data.data.login_screen_original_bg_image === ""
              ? loginbackground +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_LoginBackground_Crop +
                res.data.data.login_screen_bg_image
              : loginbackground +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_LoginBackground_Crop +
                res.data.data.login_screen_original_bg_image,
          sendimagedataapi: res.data.data.login_screen_bg_image,
          imagename:
            res.data.is_default === 1
              ? "hearts-beeds.jpg"
              : res.data.data.login_screen_bg_image,

          chooseImage:
            res.data.is_default === 1
              ? process.env.REACT_APP_Image_Path +
                "assets/images/default/hearts-beeds.jpg"
              : res.data.data.login_screen_bg_image === ""
              ? ""
              : res.data.data.checkimage === "splash"
              ? splashimg +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_SplashScreen_Crop +
                res.data.data.login_screen_bg_image
              : loginbackground +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_LoginBackground_Crop +
                res.data.data.login_screen_bg_image,

          // iconImage:res.data.data.logo_background_img === "" ? "" : loginlogo + res.data.data.logo_background_img,
          checkimage: res.data.data.checkimage,
          checkapp_logo: res.data.data.checkapp_logo,

          primaryColor:
            res.data.data.login_screen_primary_color_object !== undefined &&
            res.data.data.login_screen_primary_color_object.portal_data !==
              undefined
              ? res.data.data.login_screen_primary_color_object.portal_data
              : res.data.data.login_screen_primary_color_v2 !== undefined &&
                res.data.data.login_screen_primary_color_v2 !== ""
              ? res.data.data.login_screen_primary_color_v2
              : res.data.data.login_screen_primary_color,

          secondaryColor:
            res.data.data.login_screen_secondary_color_object !== undefined &&
            res.data.data.login_screen_secondary_color_object.portal_data !==
              undefined
              ? res.data.data.login_screen_secondary_color_object.portal_data
              : res.data.data.login_screen_secondary_color_v2 !== undefined &&
                res.data.data.login_screen_secondary_color_v2 !== ""
              ? res.data.data.login_screen_secondary_color_v2
              : res.data.data.login_screen_secondary_color,

          buttonColor:
            res.data.data.login_screen_button_color_object !== undefined &&
            res.data.data.login_screen_button_color_object.portal_data !==
              undefined
              ? res.data.data.login_screen_button_color_object.portal_data
              : res.data.data.login_screen_button_color,

          buttonTextColor:
            res.data.data.login_screen_button_text_color_object !== undefined &&
            res.data.data.login_screen_button_text_color_object.portal_data !==
              undefined
              ? res.data.data.login_screen_button_text_color_object.portal_data
              : res.data.data.login_screen_button_text_color,

          consent_form_purchased:
            res.data.data.consent_form_purchased === undefined
              ? 0
              : res.data.data.consent_form_purchased,

          spinner: false,

          is_logo_image: res.data.data.login_screen_app_logo_text,

          defaultimage:
            res.data.data.login_screen_app_logo_image === ""
              ? ""
              : res.data.data.checkapp_logo === "splash_logo"
              ? applogo +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_SplashLogo_Crop +
                res.data.data.login_screen_app_logo_image
              : loginlogo +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_LoginLogo_Crop +
                res.data.data.login_screen_app_logo_image,

          iconImage:
            res.data.data.login_screen_app_logo_image === ""
              ? ""
              : res.data.data.checkapp_logo === "splash_logo"
              ? applogo +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_SplashLogo_Crop +
                res.data.data.login_screen_app_logo_image
              : loginlogo +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_LoginLogo_Crop +
                res.data.data.login_screen_app_logo_image,

          original_app_icon:
            res.data.data.login_screen_app_logo_image === ""
              ? ""
              : res.data.data.checkapp_logo === "splash_logo"
              ? applogo +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_SplashLogo_Crop +
                res.data.data.login_screen_original_app_logo
              : res.data.data.login_screen_original_app_logo === ""
              ? loginlogo +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_LoginLogo_Crop +
                res.data.data.login_screen_app_logo_image
              : loginlogo +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_LoginLogo_Crop +
                res.data.data.login_screen_original_app_logo,

          original_logo:
            res.data.data.login_screen_app_logo_image === ""
              ? ""
              : res.data.data.login_screen_original_app_logo === ""
              ? applogo +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_LoginLogo_Crop +
                res.data.data.login_screen_app_logo_image
              : applogo +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_LoginLogo_Crop +
                res.data.data.login_screen_original_app_logo,

          applogodefaultimage:
            res.data.data.login_screen_app_logo_image === ""
              ? require("../../assets/images/dashboard/storage.png").default
              : res.data.data.checkapp_logo === "splash_logo"
              ? applogo +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_SplashLogo_Crop +
                res.data.data.login_screen_app_logo_image
              : loginlogo +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_LoginLogo_Crop +
                res.data.data.login_screen_app_logo_image,

          uploadtext:
            res.data.data.login_screen_app_logo_image === ""
              ? ""
              : "Click here to upload an image.",

          activeTab:
            res.data.data.login_screen_app_logo_image === "" ? "1" : "2",

          checkApplogo: checkApplogo,

          selectbackgroundorcolor: selectbackgroundorcolor,

          applogotext:
            res.data.data.login_screen_app_logo_text !== null
              ? res.data.data.login_screen_app_logo_text.slice(0, 15)
              : "",

          appfontsize:
            res.data.data.login_screen_app_logo_text_font_size === ""
              ? "28px"
              : res.data.data.login_screen_app_logo_text_font_size,

          appfontfamily:
            res.data.data.login_screen_app_logo_text_font === ""
              ? "Poppins-SemiBold"
              : res.data.data.login_screen_app_logo_text_font.indexOf("hitmo") >
                -1
              ? res.data.data.login_screen_app_logo_text_font.replace(".", "")
              : res.data.data.login_screen_app_logo_text_font,

          applogocolor:
            res.data.data.login_screen_app_logo_text_color_object !==
              undefined &&
            res.data.data.login_screen_app_logo_text_color_object
              .portal_data !== undefined
              ? res.data.data.login_screen_app_logo_text_color_object
                  .portal_data
              : res.data.data.login_screen_app_logo_text_color === ""
              ? "#3064F9"
              : res.data.data.login_screen_app_logo_text_color,

          //customlogo
          customlogo:
            res.data.data.customlogo === ""
              ? ""
              : applogo +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_LoginLogo_Crop +
                res.data.data.customlogo,
          customlogotext:
            res.data.data.login_screen_app_logo_text !== null
              ? res.data.data.login_screen_app_logo_text.trim().slice(0, 15)
              : "",
          customlogocolor:
            res.data.data.login_screen_app_logo_text_color_object !== undefined
              ? res.data.data.login_screen_app_logo_text_color_object
                  .portal_data
              : res.data.data.login_screen_app_logo_text_color,

          customfontfamily: res.data.data.login_screen_app_logo_text_font,

          customfontsize: res.data.data.login_screen_app_logo_text_font_size,

          customlogoalign:
            res.data.data.login_screen_app_logo_alignment !== undefined
              ? res.data.data.login_screen_app_logo_alignment
              : "center",

          //tint
          tabselection:
            res.data.data.opacity_color_object !== undefined &&
            res.data.data.opacity_color_object.portal_data !== undefined &&
            res.data.data.opacity_color_object.portal_data !== ""
              ? "and"
              : res.data.data.opacity_color_object !== undefined &&
                res.data.data.opacity_color_object.portal_data !== ""
              ? "and"
              : res.data.data.login_screen_bg_color_object !== undefined &&
                res.data.data.login_screen_bg_color_object.portal_data !== ""
              ? "or"
              : res.data.data.login_screen_bg_color_object !== undefined &&
                res.data.data.login_screen_bg_color_object.portal_data === ""
              ? "or"
              : res.data.data.login_screen_bg_color !== ""
              ? "or"
              : res.data.data.opacity_color === undefined ||
                res.data.data.opacity_color === ""
              ? "or"
              : "and",
          oractive:
            res.data.data.opacity_color_object !== undefined &&
            res.data.data.opacity_color_object.portal_data !== undefined &&
            res.data.data.opacity_color_object.portal_data !== ""
              ? "or_not_active_tab"
              : res.data.data.login_screen_bg_color_object !== undefined &&
                res.data.data.login_screen_bg_color_object.portal_data === ""
              ? "active_tab"
              : res.data.data.login_screen_bg_color_object !== undefined &&
                res.data.data.login_screen_bg_color_object.portal_data !== ""
              ? "active_tab"
              : res.data.data.login_screen_bg_color !== ""
              ? "active_tab"
              : res.data.data.opacity_color === undefined ||
                res.data.data.opacity_color === ""
              ? "active_tab"
              : "or_not_active_tab",
          andactive:
            res.data.data.opacity_color_object !== undefined &&
            res.data.data.opacity_color_object.portal_data !== undefined &&
            res.data.data.opacity_color_object.portal_data !== ""
              ? "active_tab"
              : res.data.data.splash_screen_bg_color !== ""
              ? "not_active_tab"
              : res.data.data.opacity_color === undefined ||
                res.data.data.opacity_color === ""
              ? "not_active_tab"
              : "active_tab",
          tintcolor:
            res.data.data.opacity_color_object !== undefined &&
            res.data.data.opacity_color_object.portal_data !== undefined &&
            res.data.data.opacity_color_object.portal_data !== ""
              ? res.data.data.opacity_color_object.portal_data
              : res.data.data.opacity_color_object !== undefined &&
                res.data.data.opacity_color_object.portal_data !== undefined &&
                res.data.data.opacity_color_object.portal_data === ""
              ? ""
              : res.data.data.opacity_color === undefined ||
                res.data.data.opacity_color === ""
              ? "#636161"
              : res.data.data.opacity_color,
          range:
            res.data.data.opacity_percent === undefined ||
            res.data.data.opacity_percent === ""
              ? 20
              : res.data.data.opacity_color_object !== undefined
              ? 100
              : parseInt(res.data.data.opacity_percent),
          textfontfamily:
            res.data.data.login_signup_screen_text_font_family === undefined ||
            res.data.data.login_signup_screen_text_font_family
              ? "Poppins-SemiBold"
              : res.data.data.login_signup_screen_text_font_family.indexOf(
                  "hitmo"
                ) > -1
              ? res.data.data.login_signup_screen_text_font_family.replace(
                  ".",
                  ""
                )
              : res.data.data.login_signup_screen_text_font_family,
          welcometextcolor:
            res.data.data.login_signup_screen_text_color_object !== undefined &&
            res.data.data.login_signup_screen_text_color_object.portal_data !==
              undefined
              ? res.data.data.login_signup_screen_text_color_object.portal_data
              : res.data.data.login_signup_screen_text_color !== undefined
              ? res.data.data.login_signup_screen_text_color
              : "#000000",
          welcometext:
            res.data.data.login_signup_screen_text !== undefined
              ? res.data.data.login_signup_screen_text
              : "Welcome to AppMySite!",
          textfontsize:
            res.data.data.login_signup_screen_text_font_size !== undefined
              ? res.data.data.login_signup_screen_text_font_size
              : "22px",
          logo_align:
            res.data.data.login_screen_app_logo_alignment !== undefined
              ? res.data.data.login_screen_app_logo_alignment
              : "center",
          social_shape:
            res.data.data.social_icons_button_shape !== undefined
              ? res.data.data.social_icons_button_shape
              : "circle",
        });
        callback();
      } else if (res.data.code == 400) {
        errortoast("Some Field missing!");
      } else if (res.data.code === 401) {
        if (res.data.data === "App does not exists.") {
          state.props.history.push("/apps");
          errortoast(res.data.data);
        }
      } else {
        // errortoast("Something went wrong. Try again after sometime.");
        state.setState({
          spinner: false,
        });
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

//NewLoginsignApi  Icon get api
export const GetNewLoginsignupAPI = (state, callback) => {
  clientid = localStorage.getItem("user_id");
  changeurl();
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(
      saveceritificate +
        "get-login-screen/" +
        btoa(localStorage.getItem("Appid")),
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200 && res.data.data !== null) {
        state.setState({
          android_build_version: res.data.android_build_version,
          android_supported_version: res.data.android_supported_version,
          ios_build_version: res.data.ios_build_version,
          ios_supported_version: res.data.ios_supported_version,
          //Background Color
          default: res.data.is_default,
          designid: res.data.data.design_id ? res.data.data.design_id : "",
          android_bundle_id: res.data.android_bundle_id,
          ios_bundle_id: res.data.ios_bundle_id,
          is_app_already_published:
            res.data.android_signing_data &&
            res.data.android_signing_data !== undefined &&
            res.data.android_signing_data != null &&
            res.data.android_signing_data.is_app_already_published == 1
              ? 1
              : 0,
          android_pushtoggle:
            res.data.android_signing_data &&
            res.data.android_signing_data !== undefined &&
            res.data.android_signing_data != null &&
            res.data.android_signing_data.is_google_login_enabled == 1
              ? true
              : false,
          pushtoggle:
            res.data.ios_signing_data &&
            res.data.ios_signing_data !== undefined &&
            res.data.ios_signing_data != null &&
            res.data.ios_signing_data.is_google_login_enabled == 1
              ? true
              : false,
          androidpushnotificationfilename:
            res.data.android_signing_data &&
            res.data.android_signing_data !== undefined &&
            res.data.android_signing_data != null &&
            res.data.android_signing_data.notification_config_file
              ? res.data.android_signing_data.notification_config_file.replaceAll(
                  ".json",
                  ""
                )
              : "",
          pushnotificationfilename:
            res.data.ios_signing_data &&
            res.data.ios_signing_data !== undefined &&
            res.data.ios_signing_data != null &&
            res.data.ios_signing_data.notification_config_file
              ? res.data.ios_signing_data.notification_config_file.replaceAll(
                  ".plist",
                  ""
                )
              : "",
          in_process:
            res.data.android_signing_data &&
            res.data.android_signing_data !== undefined &&
            res.data.android_signing_data != null &&
            res.data.android_signing_data.in_process != ""
              ? res.data.android_signing_data.in_process
              : "",
          sha1:
            res.data.android_signing_data &&
            res.data.android_signing_data !== undefined &&
            res.data.android_signing_data != null &&
            res.data.android_signing_data.sha1 != ""
              ? res.data.android_signing_data.sha1
              : "",

          //Background Color Image

          low_resolution_image:
            res.data.data == null
              ? ""
              : res.data.data.login_screen_bg_image &&
                res.data.data.login_screen_bg_image === ""
              ? ""
              : res.data.data.checkimage === "splash"
              ? splashimg +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_SplashScreen_Crop +
                res.data.data.login_screen_bg_image
              : res.data.data.login_screen_bg_image
              ? loginbackground +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_LoginBackground_Crop +
                res.data.data.login_screen_bg_image
              : res.data.data.bg_image && res.data.data.bg_image == ""
              ? ""
              : res.data.data.bg_image &&
                res.data.data.bg_image != "hearts-beeds.jpg"
              ? process.env.REACT_APP_Image_Path +
                localStorage.getItem("user_id") +
                "/" +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_LoginBackground_Crop +
                res.data.data.bg_image
              : "",

          imagename:
            res.data.data == null
              ? ""
              : res.data.data.login_screen_bg_image &&
                res.data.data.login_screen_bg_image === ""
              ? ""
              : res.data.data.checkimage === "splash"
              ? res.data.data.login_screen_bg_image
              : res.data.data.login_screen_bg_image
              ? res.data.data.login_screen_bg_image
              : res.data.data.bg_image && res.data.data.bg_image == ""
              ? ""
              : res.data.data.bg_image &&
                res.data.data.bg_image != "hearts-beeds.jpg"
              ? res.data.data.bg_image
              : "",

          primaryColor:
            res.data.data.login_screen_primary_color_object !== undefined &&
            res.data.data.login_screen_primary_color_object.portal_data !==
              undefined
              ? res.data.data.login_screen_primary_color_object.portal_data
              : res.data.data.primary_text_color_object !== undefined &&
                res.data.data.primary_text_color_object.portal_data !==
                  undefined
              ? res.data.data.primary_text_color_object.portal_data
              : res.data.data.login_screen_primary_text_color_object !==
                  undefined &&
                res.data.data.login_screen_primary_text_color_object
                  .portal_data !== undefined
              ? res.data.data.login_screen_primary_text_color_object.portal_data
              : res.data.data.login_screen_primary_color_v2 !== undefined &&
                res.data.data.login_screen_primary_color_v2 !== ""
              ? res.data.data.login_screen_primary_color_v2
              : res.data.data.primary_text_color
              ? res.data.data.primary_text_color
              : res.data.data.login_screen_primary_color,

          secondaryColor:
            res.data.data.login_screen_secondary_color_object !== undefined &&
            res.data.data.login_screen_secondary_color_object.portal_data !==
              undefined
              ? res.data.data.login_screen_secondary_color_object.portal_data
              : res.data.data.secondary_text_color_object !== undefined &&
                res.data.data.secondary_text_color_object.portal_data !==
                  undefined
              ? res.data.data.secondary_text_color_object.portal_data
              : res.data.data.login_screen_secondary_text_color_object !==
                  undefined &&
                res.data.data.login_screen_secondary_text_color_object
                  .portal_data !== undefined
              ? res.data.data.login_screen_secondary_text_color_object
                  .portal_data
              : res.data.data.login_screen_secondary_color_v2 !== undefined &&
                res.data.data.login_screen_secondary_color_v2 !== ""
              ? res.data.data.login_screen_secondary_color_v2
              : res.data.data.secondary_text_color
              ? res.data.data.secondary_text_color
              : res.data.data.login_screen_secondary_color,

          buttonColor:
            res.data.data.login_screen_button_color_object !== undefined &&
            res.data.data.login_screen_button_color_object.portal_data !==
              undefined
              ? res.data.data.login_screen_button_color_object.portal_data
              : res.data.data.button_bg_color_object !== undefined &&
                res.data.data.button_bg_color_object.portal_data !== undefined
              ? res.data.data.button_bg_color_object.portal_data
              : res.data.data.button_bg_color
              ? res.data.data.button_bg_color
              : res.data.data.login_screen_button_color,

          buttonTextColor:
            res.data.data.login_screen_button_text_color_object !== undefined &&
            res.data.data.login_screen_button_text_color_object.portal_data !==
              undefined
              ? res.data.data.login_screen_button_text_color_object.portal_data
              : res.data.data.button_text_color_object !== undefined &&
                res.data.data.button_text_color_object.portal_data !== undefined
              ? res.data.data.button_text_color_object.portal_data
              : res.data.data.button_text_color
              ? res.data.data.button_text_color
              : res.data.data.login_screen_button_text_color,

          spinner: false,

          layout:
            res.data.data.login_screen_layout == "middle"
              ? "center"
              : res.data.data.login_screen_layout !== undefined &&
                res.data.data.login_screen_layout !== null
              ? res.data.data.login_screen_layout
              : "center",

          toggle:
            res.data.toggles_data.disable_login_signup_module &&
            res.data.toggles_data.disable_login_signup_module == 1
              ? false
              : true,

          google_login:
            res.data.toggles_data.social_login_google_allowed_bool &&
            res.data.toggles_data.social_login_google_allowed_bool == 1
              ? true
              : false,

          apple_login:
            res.data.toggles_data.social_login_apple_allowed_bool &&
            res.data.toggles_data.social_login_apple_allowed_bool == 1
              ? true
              : false,

          facebooklogin:
            res.data.toggles_data.social_login_fb_allowed_bool &&
            res.data.toggles_data.social_login_fb_allowed_bool == 1
              ? true
              : false,

          guestlogin:
            res.data.toggles_data.guest_browsing_allowed_bool &&
            res.data.toggles_data.guest_browsing_allowed_bool == 1
              ? true
              : false,

          skip_login:
            res.data.toggles_data.is_login_first_screen &&
            res.data.toggles_data.is_login_first_screen == 1
              ? true
              : false,

          user_registration:
            res.data.toggles_data?.user_registration_allowed_bool &&
            res.data.toggles_data?.user_registration_allowed_bool == 1
              ? true
              : false,

          login_screen_social_icons_stack_status:
            res.data.data.login_screen_social_icons_stack_status == true ||
            res.data.data.login_screen_social_icons_stack_status == 1
              ? 1
              : 0,

          loginemail:
            res.data.toggles_data &&
            res.data.toggles_data.enable_login_with_email == 1
              ? true
              : res.data.toggles_data &&
                res.data.toggles_data.enable_login_with_email == 0
              ? false
              : true,

          last_updated_timestamp: res.data.data.last_updated_timestamp
            ? res.data.data.last_updated_timestamp
            : "",

          social_shape:
            res.data.data.social_icons_button_shape !== undefined
              ? res.data.data.social_icons_button_shape
              : res.data.data.login_screen_social_icons_shape
              ? res.data.data.login_screen_social_icons_shape
              : "circle",
        });
        callback();
      } else if (res.data.code == 400) {
        errortoast("Some Field missing!");
      } else if (res.data.code === 401) {
        if (res.data.data === "App does not exists.") {
          state.props.history.push("/apps");
          errortoast(res.data.data);
        }
      } else {
        // errortoast("Something went wrong. Try again after sometime.");
        state.setState({
          spinner: false,
        });
      }
    })
    .catch((error) => {
      
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

//NewLoginsignApi  Icon get api
export const GetNewLoginsignupAPI1 = (state, callback) => {
  clientid = localStorage.getItem("user_id");
  changeurl();
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(
      saveceritificate +
        "get-login-screen/" +
        btoa(localStorage.getItem("Appid")),
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200 && res.data.data !== null) {
        state.setState({
          in_process:
            res.data.android_signing_data &&
            res.data.android_signing_data !== undefined &&
            res.data.android_signing_data != null &&
            res.data.android_signing_data.in_process != ""
              ? res.data.android_signing_data.in_process
              : "",
          sha1:
            res.data.android_signing_data &&
            res.data.android_signing_data !== undefined &&
            res.data.android_signing_data != null &&
            res.data.android_signing_data.sha1 != ""
              ? res.data.android_signing_data.sha1
              : "",
          saveloader1: false,
        });
        callback();
      } else if (res.data.code == 400) {
        // errortoast('Some Field missing!');
      } else if (res.data.code === 401) {
        if (res.data.data === "App does not exists.") {
          // state.props.history.push("/apps");
          // errortoast(res.data.data);
        }
      } else {
        // errortoast("Something went wrong. Try again after sometime.");
        // state.setState({
        //   spinner: false,
        // });
      }
    })
    .catch((error) => {
      
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

//NewBuildsignApi  Icon get api
export const GetBuildLoginsignupAPI = (state, callback) => {
  clientid = localStorage.getItem("user_id");
  changeurl();
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  state.setState({
    disabledsubmitbutton: true,
  });
  axios
    .get(
      saveceritificate +
        "get-login-screen/" +
        btoa(localStorage.getItem("Appid")),
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          android_build_version: res.data.android_build_version,
          android_supported_version: res.data.android_supported_version,
          ios_build_version: res.data.ios_build_version,
          ios_supported_version: res.data.ios_supported_version,
          //Background Color
          default: res.data.is_default,
          android_bundle_id: res.data.android_bundle_id,
          ios_bundle_id: res.data.ios_bundle_id,
          is_app_already_published:
            res.data.android_signing_data &&
            res.data.android_signing_data !== undefined &&
            res.data.android_signing_data != null &&
            res.data.android_signing_data.is_app_already_published == 1
              ? 1
              : 0,
          android_pushtoggle:
            res.data.android_signing_data &&
            res.data.android_signing_data !== undefined &&
            res.data.android_signing_data != null &&
            res.data.android_signing_data.is_google_login_enabled == 1
              ? true
              : false,
          pushtoggle:
            res.data.ios_signing_data &&
            res.data.ios_signing_data !== undefined &&
            res.data.ios_signing_data != null &&
            res.data.ios_signing_data.is_google_login_enabled == 1
              ? true
              : false,
          androidpushnotificationfilename:
            res.data.android_signing_data &&
            res.data.android_signing_data !== undefined &&
            res.data.android_signing_data != null &&
            res.data.android_signing_data.notification_config_file
              ? res.data.android_signing_data.notification_config_file.replaceAll(
                  ".json",
                  ""
                )
              : "",
          pushnotificationfilename:
            res.data.ios_signing_data &&
            res.data.ios_signing_data !== undefined &&
            res.data.ios_signing_data != null &&
            res.data.ios_signing_data.notification_config_file
              ? res.data.ios_signing_data.notification_config_file.replaceAll(
                  ".plist",
                  ""
                )
              : "",
          in_process:
            res.data.android_signing_data &&
            res.data.android_signing_data !== undefined &&
            res.data.android_signing_data != null &&
            res.data.android_signing_data.in_process != ""
              ? res.data.android_signing_data.in_process
              : "",
          sha1:
            res.data.android_signing_data &&
            res.data.android_signing_data !== undefined &&
            res.data.android_signing_data != null &&
            res.data.android_signing_data.sha1 != ""
              ? res.data.android_signing_data.sha1
              : "",

          //Background Color Image

          low_resolution_image:
            res.data.data == null
              ? require("../../assets/images/dashboard/ams_1710148542_9783.png")
                  .default
              : res.data.data.login_screen_bg_image &&
                res.data.data.login_screen_bg_image === ""
              ? require("../../assets/images/dashboard/ams_1710148542_9783.png")
                  .default
              : res.data.data.checkimage === "splash"
              ? splashimg +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_SplashScreen_Crop +
                res.data.data.login_screen_bg_image
              : res.data.data.login_screen_bg_image
              ? loginbackground +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_LoginBackground_Crop +
                res.data.data.login_screen_bg_image
              : res.data.data.bg_image && res.data.data.bg_image == ""
              ? require("../../assets/images/dashboard/ams_1710148542_9783.png")
                  .default
              : res.data.data.bg_image &&
                res.data.data.bg_image != "hearts-beeds.jpg"
              ? process.env.REACT_APP_Image_Path +
                localStorage.getItem("user_id") +
                "/" +
                localStorage.getItem("Appid") +
                process.env.REACT_APP_LoginBackground_Crop +
                res.data.data.bg_image
              : require("../../assets/images/dashboard/ams_1710148542_9783.png")
                  .default,

          imagename:
            res.data.data == null
              ? "ams_1710148542_9783.png"
              : res.data.data.login_screen_bg_image &&
                res.data.data.login_screen_bg_image === ""
              ? "ams_1710148542_9783.png"
              : res.data.data.checkimage === "splash"
              ? res.data.data.login_screen_bg_image
              : res.data.data.login_screen_bg_image
              ? res.data.data.login_screen_bg_image
              : res.data.data.bg_image && res.data.data.bg_image == ""
              ? "ams_1710148542_9783.png"
              : res.data.data.bg_image &&
                res.data.data.bg_image != "hearts-beeds.jpg"
              ? res.data.data.bg_image
              : "ams_1710148542_9783.png",

          spinner: false,

          toggle:
            res.data.toggles_data.disable_login_signup_module &&
            res.data.toggles_data.disable_login_signup_module == 1
              ? false
              : true,

          google_login:
            res.data.toggles_data.social_login_google_allowed_bool &&
            res.data.toggles_data.social_login_google_allowed_bool == 1
              ? true
              : false,

          apple_login:
            res.data.toggles_data.social_login_apple_allowed_bool &&
            res.data.toggles_data.social_login_apple_allowed_bool == 1
              ? true
              : false,

          facebooklogin:
            res.data.toggles_data.social_login_fb_allowed_bool &&
            res.data.toggles_data.social_login_fb_allowed_bool == 1
              ? true
              : false,

          guestlogin:
            res.data.toggles_data.guest_browsing_allowed_bool &&
            res.data.toggles_data.guest_browsing_allowed_bool == 1
              ? true
              : false,

          skip_login:
            res.data.toggles_data.is_login_first_screen &&
            res.data.toggles_data.is_login_first_screen == 1
              ? true
              : false,

          user_registration:
            res.data.toggles_data?.user_registration_allowed_bool &&
            res.data.toggles_data?.user_registration_allowed_bool == 1
              ? true
              : false,

          loginemail:
            res.data.toggles_data &&
            res.data.toggles_data.enable_login_with_email == 1
              ? true
              : res.data.toggles_data &&
                res.data.toggles_data.enable_login_with_email == 0
              ? false
              : true,
        });
        callback();
      } else if (res.data.code == 400) {
        errortoast("Some Field missing!");
      } else if (res.data.code === 401) {
        if (res.data.data === "App does not exists.") {
          state.props.history.push("/apps");
          errortoast(res.data.data);
        }
      } else {
        // errortoast("Something went wrong. Try again after sometime.");
        state.setState({
          spinner: false,
        });
      }
    })
    .catch((error) => {
      
      // errortoast("Something went wrong. Try again after sometime.");
    })
    .finally(()=> {
      state.setState({
        disabledsubmitbutton: false,
      });
    });
};

//NewBuildsignApi  Icon get api
export const GetBuildLoginsignupAPI1 = (state, callback) => {
  clientid = localStorage.getItem("user_id");
  changeurl();
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(
      saveceritificate +
        "get-login-screen/" +
        btoa(localStorage.getItem("Appid")),
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          in_process:
            res.data.android_signing_data &&
            res.data.android_signing_data !== undefined &&
            res.data.android_signing_data != null &&
            res.data.android_signing_data.in_process != ""
              ? res.data.android_signing_data.in_process
              : "",
          sha1:
            res.data.android_signing_data &&
            res.data.android_signing_data !== undefined &&
            res.data.android_signing_data != null &&
            res.data.android_signing_data.sha1 != ""
              ? res.data.android_signing_data.sha1
              : "",
          saveloader1: false,
        });
        callback();
      } else if (res.data.code == 400) {
        // errortoast('Some Field missing!');
      } else if (res.data.code === 401) {
        if (res.data.data === "App does not exists.") {
          // state.props.history.push("/apps");
          // errortoast(res.data.data);
        }
      } else {
        // errortoast("Something went wrong. Try again after sometime.");
        // state.setState({
        //   spinner: false,
        // });
      }
    })
    .catch((error) => {
      
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

//LoginsignApi
export const LoginsignupAPI = (params, state) => {
  Loader(state);
  state.setState({
    disabledsubmitbutton: true,
  });
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "login_signup", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          submitval:
            localStorage.getItem("login_register_icon_count") === "0"
              ? "Save"
              : "Save",
          loader: "d-none",
          disabledsubmitbutton: false,
        });
        sucesstoast("Login & Sign Up screens designed successfully.");
        // localStorage.getItem("login_register_icon_count") === "0"
        //   ? state.props.history.push("/app/theme")
        //   : sucesstoast("Login & Sign Up screens designed successfully.");
        if (localStorage.getItem("login_register_icon_count") === "1") {
          state.loadFirst();
        }
      } else if (res.data.code == 400) {
        errortoast("Some Field missing!");
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

//NewLoginsignApi
export const NewLoginsignupAPI = (params, state, from) => {
  Loader(state);
  state.setState({
    disabledsubmitbutton: true,
  });
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  
  axios
    .post(saveceritificate + "save-login-screen", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          saveloader: false,
          disabledsubmitbutton: false,
          last_updated_timestamp: res.data.data.updated_at,
        });
        state.loadFirst();

        if (from !== "sha") {
          sucesstoast("The record has been saved.");
        }
      } else if (res.data.code == 400) {
        errortoast("Some Field missing!");
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

//DashboardApi
//DashboardApi
export const DashboardApi = (state, callback) => {
  clientid = localStorage.getItem("user_id");
  changeurl();
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(
      saveceritificate +
        "get-home-screen/" +
        btoa(localStorage.getItem("Appid")),
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          android_build_version: res.data.android_build_version,
          android_supported_version: res.data.android_supported_version,
          ios_build_version: res.data.ios_build_version,
          ios_supported_version: res.data.ios_supported_version,
        });

        if (res.data.home_screen_data !== null) {
          let bannerCheckbox = 1;
          let saleitemscheckbox = 1;
          let newarrivalscheckbox = 1;
          let web_view_display_bool = 1;
          let Cartproductcheckbox = 1;
          let featuredproductcheckbox = 1;
          let recentviewcheckbox = 1;
          let categoriesCheckbox = 1;
          let blog_display = 1;
          let blog_banner_display = 1;
          let blog_categories_display = 1;
          let sticky_blog_display = 1;
          let new_pages_display = 1;
          

          if (res.data.home_screen_data.main_banner_display_bool === 1) {
            bannerCheckbox = true;
          } else {
            bannerCheckbox = false;
          }
          if (res.data.home_screen_data.blog_categories_display_bool === 1) {
            blog_categories_display = true;
          } else {
            blog_categories_display = false;
          }
          if (res.data.home_screen_data.sticky_blogs_display_bool === 1) {
            sticky_blog_display = true;
          } else {
            sticky_blog_display = false;
          }
          if (res.data.home_screen_data.new_pages_display_bool === 1) {
            new_pages_display = true;
          } else {
            new_pages_display = false;
          }
          if (res.data.home_screen_data.product_categories_display_bool === 1) {
            categoriesCheckbox = true;
          } else {
            categoriesCheckbox = false;
          }
          if (res.data.home_screen_data.sale_products_display_bool === 1) {
            saleitemscheckbox = true;
          } else {
            saleitemscheckbox = false;
          }
          if (res.data.home_screen_data.new_products_display_bool === 1) {
            newarrivalscheckbox = true;
          } else {
            newarrivalscheckbox = false;
          }
          if (
            res.data.home_screen_data.web_view_display_bool !== undefined &&
            res.data.home_screen_data.web_view_display_bool === 1
          ) {
            web_view_display_bool = true;
          } else {
            web_view_display_bool = false;
          }

          if (res.data.home_screen_data.incart_products_display_bool === 1) {
            Cartproductcheckbox = true;
          } else {
            Cartproductcheckbox = false;
          }
          if (res.data.home_screen_data.featured_products_display_bool === 1) {
            featuredproductcheckbox = true;
          } else {
            featuredproductcheckbox = false;
          }
          if (
            res.data.home_screen_data.recently_viewed_products_display_bool ===
            1
          ) {
            recentviewcheckbox = true;
          } else {
            recentviewcheckbox = false;
          }
          if (res.data.home_screen_data.new_blogs_display_bool === 1) {
            blog_display = true;
          } else {
            blog_display = false;
          }
          if (res.data.home_screen_data.blog_banner_display_bool === 1) {
            blog_banner_display = true;
          } else {
            blog_banner_display = false;
          }
          let selectbackgroundorcolor =
            res.data.home_screen_data.main_banner_solid_color_object !==
              undefined &&
            res.data.home_screen_data.main_banner_solid_color_object === ""
              ? false
              : res.data.home_screen_data.main_banner_solid_color_object !==
                  undefined &&
                res.data.home_screen_data.main_banner_solid_color_object
                  .portal_data !== ""
              ? true
              : res.data.home_screen_data.main_banner_solid_color_object !==
                  undefined &&
                res.data.home_screen_data.main_banner_solid_color_object
                  .portal_data === ""
              ? false
              : res.data.home_screen_data.main_banner_solid_color !== ""
              ? true
              : false;
          let store_url = "";
          if (res.data.store_url[res.data.store_url.length - 1] === "/") {
            store_url = res.data.store_url;
          } else {
            store_url = res.data.store_url + "/";
          }
          state.setState({
            show_web_view_feature:
              res.data.home_screen_data.show_web_view_feature === 0 ? 0 : 1,
            //typeselection
            typeselection: res.data.website_type.toString(),
            spinner: false,
            bottommenuColor:
              res.data.bottom_menu !== null
                ? res.data.bottom_menu.bottom_menu_style.bottom_menu_bg_colour
                : "#FFFFFF",
            bottommenuTexticoncolor:
              res.data.bottom_menu !== null
                ? res.data.bottom_menu.bottom_menu_style
                    .bottom_menu_icon_and_text_colour
                : "#3064F9",
            bottommenuBordercolor:
              res.data.bottom_menu !== null
                ? res.data.bottom_menu.bottom_menu_style
                    .bottom_menu_border_colour
                : "#3064F9",
            primaryColor:
              res.data.style_data.header_primary_color_object !== undefined
                ? res.data.style_data.header_primary_color_object.portal_data
                : res.data.style_data.header_primary_color,
            secondaryColor:
              res.data.style_data.header_secondary_color_object !== undefined
                ? res.data.style_data.header_secondary_color_object.portal_data
                : res.data.style_data.header_secondary_color,
            appName: res.data.home_screen_data.header_app_name,
            selectbackgroundorcolor: selectbackgroundorcolor,
            sectionorder: res.data.home_screen_data.dashboard_items_sequence,
            store_url: store_url,
            //banner
            bannertext:
              res.data.home_screen_data.main_banner_text !== "" &&
              res.data.home_screen_data.main_banner_text !== null
                ? res.data.home_screen_data.main_banner_text.slice(0, 30)
                : "",
            splashBackground:
              res.data.home_screen_data.main_banner_solid_color_object !==
              undefined
                ? res.data.home_screen_data.main_banner_solid_color_object
                    .portal_data
                : res.data.home_screen_data.main_banner_solid_color,
            initialSolidColor:
              res.data.home_screen_data.main_banner_solid_color_object !==
              undefined
                ? res.data.home_screen_data.main_banner_solid_color_object
                    .portal_data
                : res.data.home_screen_data.main_banner_solid_color === ""
                ? "#F8FAFE"
                : res.data.home_screen_data.main_banner_solid_color,

            selectpixabayimage:
              res.data.home_screen_data.main_banner_image === ""
                ? ""
                : dashboard_banner +
                  localStorage.getItem("Appid") +
                  process.env.REACT_APP_Dashboard_Crop +
                  res.data.home_screen_data.main_banner_image,
            uploadcustomdefaultimageApi:
              res.data.home_screen_data.main_banner_image === ""
                ? ""
                : dashboard_banner +
                  localStorage.getItem("Appid") +
                  process.env.REACT_APP_Dashboard_Crop +
                  res.data.home_screen_data.main_banner_image,
            original_banner:
              res.data.home_screen_data.main_banner_image === ""
                ? ""
                : dashboard_banner +
                  localStorage.getItem("Appid") +
                  process.env.REACT_APP_Dashboard_Crop +
                  res.data.home_screen_data.main_banner_image,
            chooseImage:
              res.data.home_screen_data.main_banner_image === ""
                ? ""
                : dashboard_banner +
                  localStorage.getItem("Appid") +
                  process.env.REACT_APP_Dashboard_Crop +
                  res.data.home_screen_data.main_banner_image,
            bannertextfontfamily:
              res.data.home_screen_data.main_banner_font_name.indexOf("hitmo") >
              -1
                ? res.data.home_screen_data.main_banner_font_name.replace(
                    ".",
                    ""
                  )
                : res.data.home_screen_data.main_banner_font_name,
            bannerfontSize: res.data.home_screen_data.main_banner_font_size,
            bannertextColor:
              res.data.home_screen_data.main_banner_font_color_object !==
              undefined
                ? res.data.home_screen_data.main_banner_font_color_object
                    .portal_data
                : res.data.home_screen_data.main_banner_font_color,

            initialbannertextColor:
              res.data.home_screen_data.main_banner_font_color_object !==
              undefined
                ? res.data.home_screen_data.main_banner_font_color_object
                    .portal_data
                : res.data.home_screen_data.main_banner_font_color === ""
                ? "#000000"
                : res.data.home_screen_data.main_banner_font_color,
            bannertextAlign:
              res.data.home_screen_data.main_banner_text_align === ""
                ? "center"
                : res.data.home_screen_data.main_banner_text_align,
            bannerCheckbox: bannerCheckbox,
            imagename:
              res.data.home_screen_data.main_banner_image !== ""
                ? res.data.home_screen_data.main_banner_image
                : "",
            //section
            sectionBackgroundcolor:
              res.data.home_screen_data.section_bg_color_object !== undefined
                ? res.data.home_screen_data.section_bg_color_object.portal_data
                : res.data.home_screen_data.section_bg_color,

            initialsectionBackgroundcolor:
              res.data.home_screen_data.section_bg_color_object !== undefined
                ? res.data.home_screen_data.section_bg_color_object.portal_data
                : res.data.home_screen_data.section_bg_color === ""
                ? "#000000"
                : res.data.home_screen_data.section_bg_color,

            sectionHeadingcolor:
              res.data.home_screen_data.section_heading_color_object !==
              undefined
                ? res.data.home_screen_data.section_heading_color_object
                    .portal_data
                : res.data.home_screen_data.section_heading_color,
            initialsectionHeadingcolor:
              res.data.home_screen_data.section_heading_color === ""
                ? "#000000"
                : res.data.home_screen_data.section_heading_color,

            sectionbuttoncolor:
              res.data.home_screen_data.section_button_color_object !==
              undefined
                ? res.data.home_screen_data.section_button_color_object
                    .portal_data
                : res.data.home_screen_data.section_button_color,
            initialsectionbuttoncolor:
              res.data.home_screen_data.section_button_color_object !==
              undefined
                ? res.data.home_screen_data.section_button_color_object
                    .portal_data
                : res.data.home_screen_data.section_button_color === ""
                ? "#FFFFFF"
                : res.data.home_screen_data.section_button_color,

            sectionbuttontextcolor:
              res.data.home_screen_data.section_button_text_color_object !==
              undefined
                ? res.data.home_screen_data.section_button_text_color_object
                    .portal_data
                : res.data.home_screen_data.section_button_text_color,
            initialsectionbuttontextcolor:
              res.data.home_screen_data.section_button_text_color === ""
                ? "#000000"
                : res.data.home_screen_data.section_button_text_color,

            //sell_items_bool
            saleitemscheckbox: saleitemscheckbox,

            //newarrivalscheckbox
            newarrivalscheckbox: newarrivalscheckbox,
            //webview
            web_view_display_bool: web_view_display_bool,
            web_view_target_url: res.data.home_screen_data.web_view_target_url,
            //Category
            categoriesCheckbox: categoriesCheckbox,
            // cattextbackgroundcolor:res.data.home_screen_data.categories_text_bg_color === "" ? "#000000" : res.data.home_screen_data.categories_text_bg_color,
            // initialcattextbackgroundcolor:res.data.home_screen_data.categories_text_bg_color === "" ? "#000000" : res.data.home_screen_data.categories_text_bg_color ,
            cattextbackgroundcolor: "#ffffff",
            initialcattextbackgroundcolor: "#ffffff",
            cattextcolor:
              res.data.home_screen_data.categories_text_font_color_object !==
                "" &&
              res.data.home_screen_data.categories_text_font_color_object !==
                undefined &&
              res.data.home_screen_data.categories_text_font_color_object
                .portal_data !== ""
                ? res.data.home_screen_data.categories_text_font_color_object
                    .portal_data
                : res.data.home_screen_data
                    .categories_text_font_color_object !== "" &&
                  res.data.home_screen_data
                    .categories_text_font_color_object !== undefined &&
                  res.data.home_screen_data.categories_text_font_color_object
                    .portal_data === ""
                ? "#000000"
                : res.data.home_screen_data.categories_text_font_color === ""
                ? "#000000"
                : res.data.home_screen_data.categories_text_font_color,
            initialcattextcolor:
              res.data.home_screen_data.categories_text_font_color === ""
                ? "#000000"
                : res.data.home_screen_data.categories_text_font_color,

            catbackgroundcolor:
              res.data.home_screen_data.categories_solid_bg_color_object !==
                undefined &&
              res.data.home_screen_data.categories_solid_bg_color_object
                .portal_data !== ""
                ? res.data.home_screen_data.categories_solid_bg_color_object
                    .portal_data
                : res.data.home_screen_data.categories_solid_bg_color_object !==
                    undefined &&
                  res.data.home_screen_data.categories_solid_bg_color_object
                    .portal_data === ""
                ? "#F8FAFE"
                : res.data.home_screen_data.categories_solid_bg_color === ""
                ? "#F8FAFE"
                : res.data.home_screen_data.categories_solid_bg_color,
            initialcatbackgroundcolor:
              res.data.home_screen_data.categories_solid_bg_color_object !==
                undefined &&
              res.data.home_screen_data.categories_solid_bg_color_object
                .portal_data !== ""
                ? res.data.home_screen_data.categories_solid_bg_color_object
                    .portal_data
                : res.data.home_screen_data.categories_solid_bg_color_object !==
                    undefined &&
                  res.data.home_screen_data.categories_solid_bg_color_object
                    .portal_data === ""
                ? "#F8FAFE"
                : res.data.home_screen_data.categories_solid_bg_color === ""
                ? "#F8FAFE"
                : res.data.home_screen_data.categories_solid_bg_color,

            catbackgroundtextcolor:
              res.data.home_screen_data.categories_solid_text_color_object !==
                undefined &&
              res.data.home_screen_data.categories_solid_text_color_object
                .portal_data !== ""
                ? res.data.home_screen_data.categories_solid_text_color_object
                    .portal_data
                : res.data.home_screen_data
                    .categories_solid_text_color_object !== undefined &&
                  res.data.home_screen_data.categories_solid_text_color_object
                    .portal_data === ""
                ? "#000000"
                : res.data.home_screen_data.categories_solid_text_color === ""
                ? "#000000"
                : res.data.home_screen_data.categories_solid_text_color,

            initialcatbackgroundtextcolor:
              res.data.home_screen_data.categories_solid_text_color_object !==
                undefined &&
              res.data.home_screen_data.categories_solid_text_color_object
                .portal_data !== ""
                ? res.data.home_screen_data.categories_solid_text_color_object
                    .portal_data
                : res.data.home_screen_data
                    .categories_solid_text_color_object !== undefined &&
                  res.data.home_screen_data.categories_solid_text_color_object
                    .portal_data === ""
                ? "#000000"
                : res.data.home_screen_data.categories_solid_text_color === ""
                ? "#000000"
                : res.data.home_screen_data.categories_solid_text_color,

            activeTab:
              res.data.home_screen_data.product_categories_type.toString(),
            activestate:
              res.data.home_screen_data.product_categories_type === 1
                ? true
                : false,
            categories_columns:
              res.data.home_screen_data.product_categories_columns ===
                undefined ||
              res.data.home_screen_data.product_categories_columns === ""
                ? "3"
                : res.data.home_screen_data.product_categories_columns.toString(),
            categories_shape:
              res.data.home_screen_data.product_categories_shape ===
                undefined ||
              res.data.home_screen_data.product_categories_shape === ""
                ? "round_corner"
                : res.data.home_screen_data.product_categories_shape,
            categories_sorting:
              res.data.home_screen_data.product_categories_sorting ===
                undefined ||
              res.data.home_screen_data.product_categories_sorting === ""
                ? "name"
                : res.data.home_screen_data.product_categories_sorting,
            showremaincate:
              res.data.home_screen_data.product_categories_columns === "4"
                ? true
                : false,
            categories_selection_type:
              res.data.home_screen_data.product_categories_selection_type ===
                undefined ||
              res.data.home_screen_data.product_categories_selection_type === ""
                ? "parent_categories"
                : res.data.home_screen_data.product_categories_selection_type,
            categoryname:
              res.data.home_screen_data.show_product_category_name ===
                undefined ||
              res.data.home_screen_data.show_product_category_name === 1
                ? true
                : false,

            //blogcategory
            blog_categories_columns:
              res.data.home_screen_data.blog_categories_columns === undefined ||
              res.data.home_screen_data.blog_categories_columns === ""
                ? "3"
                : res.data.home_screen_data.blog_categories_columns.toString(),
            blog_categories_shape:
              res.data.home_screen_data.blog_categories_shape === undefined ||
              res.data.home_screen_data.blog_categories_shape === ""
                ? "round_corner"
                : res.data.home_screen_data.blog_categories_shape,
            blog_categories_bg_color:
              res.data.home_screen_data.blog_categories_bg_color_object !==
                "" &&
              res.data.home_screen_data.blog_categories_bg_color_object !==
                undefined
                ? res.data.home_screen_data.blog_categories_bg_color_object
                    .portal_data
                : res.data.home_screen_data.blog_categories_bg_color === "" ||
                  res.data.home_screen_data.blog_categories_bg_color ===
                    undefined
                ? "#F8FAFE"
                : res.data.home_screen_data.blog_categories_bg_color,
            blog_categories_text_color:
              res.data.home_screen_data.blog_categories_text_color_object !==
                "" &&
              res.data.home_screen_data.blog_categories_text_color_object !==
                undefined
                ? res.data.home_screen_data.blog_categories_text_color_object
                    .portal_data
                : res.data.home_screen_data.blog_categories_text_color === "" ||
                  res.data.home_screen_data.blog_categories_text_color ===
                    undefined
                ? "#000000"
                : res.data.home_screen_data.blog_categories_text_color,
            blog_categories_selection_type:
              res.data.home_screen_data.blog_categories_selection_type ===
                undefined ||
              res.data.home_screen_data.blog_categories_selection_type === ""
                ? "parent_categories"
                : res.data.home_screen_data.blog_categories_selection_type,
            blog_categories_sorting:
              res.data.home_screen_data.blog_categories_sorting === undefined ||
              res.data.home_screen_data.blog_categories_sorting === ""
                ? "name"
                : res.data.home_screen_data.blog_categories_sorting,
            //Cartproductcheckbox
            Cartproductcheckbox: Cartproductcheckbox,
            //featuredproductcheckbox
            featuredproductcheckbox: featuredproductcheckbox,
            //recentviewcheckbox
            recentviewcheckbox: recentviewcheckbox,
            blogcheckbox: blog_display,
            blog_banner_display: blog_banner_display,
            blog_categories_display: blog_categories_display,
            sticky_blog_display: sticky_blog_display,

            //pages
            new_pages_display: new_pages_display,

            //Style data
            buttondata: res.data.style_data,
            show_web_view_header_footer_bool:
              res.data.home_screen_data.show_web_view_header_footer ===
                undefined ||
              res.data.home_screen_data.show_web_view_header_footer === 1
                ? true
                : false,
            show_web_view_header:
              res.data.home_screen_data.show_web_view_header === undefined ||
              res.data.home_screen_data.show_web_view_header === 1
                ? true
                : false,
            show_web_view_footer:
              res.data.home_screen_data.show_web_view_footer === undefined ||
              res.data.home_screen_data.show_web_view_footer === 1
                ? true
                : false,

            //label
            sale_products_label:
              res.data.home_screen_data.sale_products_label === undefined
                ? "Sale Products"
                : res.data.home_screen_data.sale_products_label,
            recently_viewed_products_label:
              res.data.home_screen_data.recently_viewed_products_label ===
              undefined
                ? "Recently Viewed"
                : res.data.home_screen_data.recently_viewed_products_label,
            featured_products_label:
              res.data.home_screen_data.featured_products_label === undefined
                ? "Featured Products"
                : res.data.home_screen_data.featured_products_label,
            incart_products_label:
              res.data.home_screen_data.incart_products_label === undefined
                ? "In Cart Products"
                : res.data.home_screen_data.incart_products_label,
            new_products_label:
              res.data.home_screen_data.new_products_label === undefined
                ? "New Products"
                : res.data.home_screen_data.new_products_label,
            sticky_blogs_label:
              res.data.home_screen_data.sticky_blogs_label === undefined
                ? "Popular Blogs"
                : res.data.home_screen_data.sticky_blogs_label,
            blog_categories_label:
              res.data.home_screen_data.blog_categories_label === undefined
                ? "Blogs Categories"
                : res.data.home_screen_data.blog_categories_label,
            new_blogs_label:
              res.data.home_screen_data.new_blogs_label === undefined
                ? "Recent Blogs"
                : res.data.home_screen_data.new_blogs_label,
            product_categories_label:
              res.data.home_screen_data.product_categories_label === undefined
                ? "Product categories"
                : res.data.home_screen_data.product_categories_label,
            main_banner_label: "Main Banner",
            new_pages_label: "Pages",
            web_view_label: "Web View",
            blog_banner_label:
              res.data.home_screen_data.blog_banner_label === undefined
                ? "Blog Banner"
                : res.data.home_screen_data.blog_banner_label,
            is_enable_posts_search:
              res.data.home_screen_data.is_enable_posts_search === undefined
                ? false
                : res.data.home_screen_data.is_enable_posts_search === 1
                ? true
                : false,
            is_enable_products_search:
              res.data.home_screen_data.is_enable_products_search === undefined
                ? false
                : res.data.home_screen_data.is_enable_products_search === 1
                ? true
                : false,
            topheadercheckbox:
              res.data.home_screen_data.is_enable_header === undefined
                ? false
                : res.data.home_screen_data.is_enable_header === 1
                ? true
                : false,
            get_all_data: res.data.home_screen_data.bottom_menu_data,
            hide_html_element_by_class:
              res.data.home_screen_data.hide_html_element_by_class === undefined
                ? ""
                : res.data.home_screen_data.hide_html_element_by_class,
            hide_html_element_by_id:
              res.data.home_screen_data.hide_html_element_by_id === undefined
                ? ""
                : res.data.home_screen_data.hide_html_element_by_id,
          });
          
          callback();
        } else {
          state.setState({
            spinner: false,
          });
        }
      } else {
        errortoast("Something went wrong. Try again after sometime.");
        state.setState({
          spinner: false,
        });
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};
//Dashboard post
export const Dashboardpost = (
  params,
  state,
  typeselection,
  submitdirection,
  modalredirect
) => {
  if (submitdirection === "realform") {
    Loader(state);
  } else {
    state.setState({
      popsubmitval: "",
      poploader: "loader",
    });
  }
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  const redirection = () => {
    if (modalredirect === "blogmodal") {
      state.props.history.push("/app/blogcategoryselection");
    }
    if (modalredirect === "catmodal") {
      state.props.history.push("/app/categoryselection");
    }
    if (modalredirect === "pagemodal") {
      state.props.history.push("/app/pageselection");
    }
  };
  axios
    .post(Url + "dashboard", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          submitval:
            localStorage.getItem("dashboard_icon_count") === "0"
              ? "Save"
              : "Save",
          loader: "d-none",
        });

        localStorage.getItem("dashboard_icon_count") === "0"
          ? typeselection === "1" ||
            typeselection === "2" ||
            typeselection === "3"
            ? submitdirection === "realform"
              ? // state.props.history.push("/app/connect/wp-plugin")
                sucesstoast("App Dashboard designed successfully.")
              : redirection()
            : sucesstoast("App Dashboard designed successfully.")
          : // state.props.history.push("/app/connect/wp-plugin")
            // : state.setState({
            //     spinner: true,
            //     hideoverlay: false,
            //   });
            sucesstoast("App Dashboard designed successfully.");

        if (localStorage.getItem("dashboard_icon_count") === "1") {
          if (submitdirection === "realform") {
            state.loaddatafromapi();
          } else {
            redirection();
          }
        }
      } else {
        state.setState({
          submitval:
            localStorage.getItem("dashboard_icon_count") === "0"
              ? "Save"
              : "Save",
          loader: "d-none",
          poploader: "d-none",
          categorychange: false,
        });
        errortoast("Something went wrong. Try again after sometime.");
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};
//Styleapi get api
export const GetStyleapi = (state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Url + "get_style/" + btoa(localStorage.getItem("Appid")), {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        if (res.data.data !== null) {
          state.setState({
            android_build_version: res.data.android_build_version,
            android_supported_version: res.data.android_supported_version,
            ios_build_version: res.data.ios_build_version,
            ios_supported_version: res.data.ios_supported_version,
            buttoncolor:
              res.data.data.button_color_object !== undefined
                ? res.data.data.button_color_object.portal_data
                : res.data.data.button_color,

            initialbuttoncolor:
              res.data.data.button_color_object !== undefined
                ? res.data.data.button_color_object.portal_data
                : res.data.data.button_color === ""
                ? "#F8D31E"
                : res.data.data.button_color,

            buttontextcolor:
              res.data.data.button_text_color_object !== undefined
                ? res.data.data.button_text_color_object.portal_data
                : res.data.data.button_text_color,

            initialbuttontextcolor:
              res.data.data.button_text_color_object !== undefined
                ? res.data.data.button_text_color_object.portal_data
                : res.data.data.button_text_color === ""
                ? "#000"
                : res.data.data.button_text_color,

            spinner: false,
            primaryColor:
              res.data.data.header_primary_color_object !== undefined
                ? res.data.data.header_primary_color_object.portal_data
                : res.data.data.header_primary_color,
            initialprimarycolor:
              res.data.data.header_primary_color_object !== undefined
                ? res.data.data.header_primary_color_object.portal_data
                : res.data.data.header_primary_color === ""
                ? "#000"
                : res.data.data.header_primary_color,
            secondaryColor:
              res.data.data.header_secondary_color_object !== undefined
                ? res.data.data.header_secondary_color_object.portal_data
                : res.data.data.header_secondary_color,
            initialsecondaryColor:
              res.data.data.header_secondary_color_object !== undefined
                ? res.data.data.header_secondary_color_object.portal_data
                : res.data.data.header_secondary_color === ""
                ? "#fff"
                : res.data.data.header_secondary_color,
            appFontFamily:
              res.data.data.header_font_name.indexOf("hitmo") > -1
                ? res.data.data.header_font_name.replace(".", "")
                : res.data.data.header_font_name,
          });
        } else {
          state.setState({ spinner: false });
        }
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

export const getUserThemeInfo = async (
  setHeaderBackgroundColor,
  setSeperatorColor,
  setTextColor,
  setIconColor,
  setSelectedShape,
  setButtonTextColor,
  setButtonBackgroundColor,
  setLoading,
  setIconData,
  setUpdatedDate,
  setUpdatedHeaderPayload,
  setUpdatedListingPayload,
  setUpdatedProfilePayload,
  setUpdatedSettingsPayload,
  setIconFilter,
  applyingFilter
) => {
  setLoading(true);
  try {
    const { data } = await axios.get(
      Url + "get_style/" + btoa(localStorage.getItem("Appid")),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("amstoken"),
        },
      }
    );
    if (data.code === 200) {
      setLoading(false);
      if (data.data !== null) {
        setHeaderBackgroundColor(
          data.data.header_primary_color_object?.portal_data ||
            data.data.header_background_color_object?.portal_data
        );
        setSeperatorColor(
          data.data.header_seperator_color_object?.portal_data || "#DEDEDE"
        );
        setTextColor(
          data.data.header_secondary_color_object?.portal_data ||
            data.data.header_text_color_object?.portal_data
        );
        setIconColor(
          data.data.header_icon_color_object?.portal_data || "#1a1a1a"
        );
        setIconFilter(applyingFilter( data.data.header_icon_color_object?.portal_data))
        
        setButtonTextColor(data.data.button_text_color_object?.portal_data);
        setButtonBackgroundColor(
          data.data.button_color_object?.portal_data ||
            data.data.button_background_color_object?.portal_data
        );
        setSelectedShape(data.data.button_shape || "midround");
        setIconData(data.data);
        setUpdatedDate(data.updated_at);
        setUpdatedHeaderPayload({
          header_hamburger_menu_icon: data?.data.header_hamburger_menu_icon,
          header_back_icon: data?.data.header_back_icon,
          header_search_icon: data?.data.header_search_icon,
          header_share_icon: data?.data.header_share_icon,
          header_wishlist_icon: data?.data.header_wishlist_icon,
          header_bookmark_icon: data?.data.header_bookmark_icon,
          header_cart_icon: data?.data.header_cart_icon,
          header_profile_icon: data?.data.header_profile_icon,
        });
        setUpdatedListingPayload({
          listing_sort_icon: data?.data.listing_sort_icon,
          listing_filter_icon: data?.data.listing_filter_icon,
          listing_list_view_icon: data?.data.listing_list_view_icon,
          listing_grid_view_icon: data?.data.listing_grid_view_icon,
        });
        setUpdatedProfilePayload({
          profile_reward_icon: data?.data.profile_reward_icon,
          profile_bookmark_icon: data?.data.profile_bookmark_icon,
          profile_wishlist_icon: data?.data.profile_wishlist_icon,
          profile_order_history_icon: data?.data.profile_order_history_icon,
          profile_saved_address_icon: data?.data.profile_saved_address_icon,
          profile_change_password_icon: data?.data.profile_change_password_icon,
          profile_chat_icon: data?.data.profile_chat_icon,
          profile_settings_icon: data?.data.profile_settings_icon,
          profile_logout_icon: data?.data.profile_logout_icon,
          profile_delete_account_icon: data?.data.profile_delete_account_icon,
        });
        setUpdatedSettingsPayload({
          setting_push_notifications_icon:
            data?.data.setting_push_notifications_icon,
          setting_notification_icon: data?.data.setting_notification_icon,
          setting_site_icon: data?.data.setting_site_icon,
          setting_currency_icon: data?.data.setting_currency_icon,
          setting_language_icon: data?.data.setting_language_icon,
          setting_terms_and_conditions_icon:
            data?.data.setting_terms_and_conditions_icon,
          setting_chat_icon: data?.data.setting_chat_icon,
        });
      } else {
        setLoading(false);
      }
    }
  } catch (error) {
    setLoading(false);
    errortoast("Something went wrong. Try again after sometime.");
  }
};
//Styleapi
export const Styleapi = (params, state) => {
  Loader(state);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "style", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          submitval:
            localStorage.getItem("style_icon_count") === "0" ? "Save" : "Save",
          loader: "d-none",
        });
        sucesstoast("App Layout Theme designed successfully.");
      }
    })
    .catch((error) => {
      
      errortoast("Something went wrong. Try again after sometime.");
    });
};

export const saveThemeData = (
  params,
  state,
  setSaveButtonLoading,
  setUpdatedDate
) => {
  // Loader(state);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  setSaveButtonLoading(true);
  axios
    .post(Url + "style", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        setSaveButtonLoading(false);
        setUpdatedDate(res.data.updated_at);

        sucesstoast("App Layout Theme designed successfully.");
      }
    })
    .catch((error) => {
      setSaveButtonLoading(false);

      errortoast("Something went wrong. Try again after sometime.");
    });
};

// Getpackageaddon get API
export const Getpackageaddon = (
  params,
  isPlan,
  planCode,
  state,
  workspaceId,
  callback
) => {
  let appId;
  let wpID;
  let agencyId;

  if (state.state.appOrWpOrAgency === "agency") {
    appId = btoa(null);
    wpID = btoa(null);
    agencyId = btoa(parseInt(localStorage.getItem("agency_id")));
  } else {
    if (
      workspaceId !== "" &&
      workspaceId !== null &&
      workspaceId !== undefined
    ) {
      wpID = btoa(workspaceId);
      appId = btoa(null);
      agencyId = btoa(null);
    } else {
      appId = btoa(parseInt(localStorage.getItem("Appid")));
      wpID = btoa(null);
      agencyId = btoa(null);
    }
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(
      Url +
        "get_package_addons/" +
        appId +
        "/" +
        wpID +
        "/" +
        agencyId +
        "/" +
        planCode +
        "/" +
        isPlan,
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        localStorage.setItem("emaffia", btoa(res.data.data.user_data.email));
        localStorage.setItem(
          "addon_identifier",
          res.data.data.addon_data.addon_identifier
        );
        state.setState(
          {
            spinner: false,
            gateway_subscription_id:
              res.data.data.subscription.gateway_subscription_id,
            addondata: res.data.data.addons,
            addon_quantity: res.data.addon_quantity,
            multisite: res.data.data.multisite,
            plancode: res.data.data.subscription.plan_code[0],
            planCode2: res.data.data.subscription.plan_code[0],
            userDiffType: res.data.data.subscription.type,
            subscriptioninfo: res.data.data.subscription,
            subscriptionToCheck: res.data.data.subscription,
            plandata:
              parseInt(isPlan) === 0
                ? res.data.data.addon_data
                : res.data.data.plan_data,
            subscription_id: res.data.data.subscription.zoho_subscription_id,
            subscription_plan: res.data.data.subscription_plan,
            addon_code: res.data.data.subscription.addon_codes,
            add_user_quantity:
              res.data.data.user_data.customer_billing_type !== 2 &&
              (res.data.data.subscription === null ||
              res.data.data.subscription.recurring_addons_purchased_json ===
                "" ||
              res.data.data.subscription.recurring_addons_purchased_json ===
                null ||
              res.data.data.subscription.recurring_addons_purchased_json ===
                "[]" ||
              res.data.data.subscription.recurring_addons_purchased_json[
                "add_user"
              ] === undefined
                ? 1
                : JSON.parse(
                    res.data.data.subscription.recurring_addons_purchased_json
                  )["add_user"].quantity === 0
                ? 1
                : JSON.parse(
                    res.data.data.subscription.recurring_addons_purchased_json
                  )["add_user"].quantity),
            recurring_price:
              parseInt(isPlan) === 0
                ? res.data.data.addon_data.amount
                : res.data.data.plan_data.amount,
            subscriptiondata: res.data.data.subscription.addon_codes,
            onetimeaddon: res.data.data.subscription.one_time_addons,
            currency: res.data.data.subscription.currency,
            discountdata:
              res.data.data.discount === undefined
                ? null
                : res.data.data.discount,
            recurring_addons_purchased_json:
              res.data.data.subscription === null ||
              res.data.data.subscription.recurring_addons_purchased_json ===
                "" ||
              res.data.data.subscription.recurring_addons_purchased_json ===
                null
                ? 0
                : JSON.parse(
                    res.data.data.subscription.recurring_addons_purchased_json
                  ),
            onetime_addons_purchased_json:
              res.data.data.subscription === null ||
              res.data.data.subscription.onetime_addons_purchased_json === "" ||
              res.data.data.subscription.onetime_addons_purchased_json === null
                ? 0
                : JSON.parse(
                    res.data.data.subscription.onetime_addons_purchased_json
                  ),

            //billing
            customer_billing_type:
              res.data.data.user_data.customer_billing_type,
            first_name: res.data.data.user_data.billing_first_name,
            userid: res.data.data.user_data.id,
            customer_id: res.data.data.user_data.customer_id,
            last_name: res.data.data.user_data.billing_last_name,
            company_name: res.data.data.user_data.company_name,
            billing_address: res.data.data.user_data.billing_address,
            billing_zip: res.data.data.user_data.billing_zip_code,
            billing_city: res.data.data.user_data.billing_city,
            addon_package_duration_id:
              res.data.data.addon_data !== undefined &&
              res.data.data.addon_data !== null
                ? res.data.data.addon_data.package_duration_id
                : 3,
            billing_country:
              res.data.data.user_data.country_id === null
                ? ""
                : res.data.data.user_data.country_id,
            billing_state: res.data.data.user_data.billing_state,
            tax_number: res.data.data.user_data.tax_number,
            email: res.data.data.user_data.email,
            selected_addon_data: res.data.data.addon_data
              ? res.data.data.addon_data
              : null,
          },
          () => {
            callback();
          }
        );
      }
    })
    .catch((err) => {
      
    });
};

//GetwebsiteData
export const GetwebsiteData = (params, state) => {
  Loader(state);
  const protocol = "https://";
  const headers = {
    "Content-Type": "application/json",
  };

  axios
    .get(
      process.env.REACT_APP_cors_api +
        protocol +
        params.websiteurl.replace("www.", "") +
        "wp-json/wc/v3/system_status?consumer_secret=" +
        params.consumer_secret +
        "%26consumer_key=" +
        params.consumer_key +
        "&timestamp=" +
        new Date().getTime(),
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.status === 200) {
        if (typeof res.data === "object") {
          state.setState({
            submitval: "Submit",
            loader: "d-none",
            success: null,
          });
          let i = 0;
          let pluginname = [];

          for (i = 0; i < res.data.active_plugins.length; i++) {
            pluginname.push(res.data.active_plugins[i].name);
          }

          if (res.data.environment.version > "2.6.0") {
            if (pluginname.indexOf("AppMySite") === -1) {
              const paramsdata = {
                app_id: parseInt(params.appid),
                authorization_type: params.authorization_type,
                consumer_key: params.consumer_key,
                consumer_secret: params.consumer_secret,
                wp_admin_username: params.wp_admin_username,
                wp_admin_application_password:
                  params.wp_admin_application_password,
                is_verified: "",
                connection_to_establish: params.connection_to_establish,
                is_connected: 1,
              };
              Appcredentailapi(paramsdata, state, "redirect");
            } else {
              const paramsdata = {
                app_id: parseInt(params.appid),
                authorization_type: params.authorization_type,
                consumer_key: params.consumer_key,
                consumer_secret: params.consumer_secret,
                wp_admin_username: params.wp_admin_username,
                wp_admin_application_password:
                  params.wp_admin_application_password,
                is_verified: "",
                connection_to_establish: params.connection_to_establish,
                is_connected: 1,
              };
              Appcredentailapi(paramsdata, state, "samepage");
            }
          } else {
            state.setState({
              modalWooPlugin: true,
              plugincontent:
                "Your WooCommerce store could not connect with the mobile app.",
            });
            // errortoast("Please update your WooCommerce Plugin")
          }
        } else {
          state.setState({
            plugincontent:
              "Your WooCommerce store could not connect with the mobile app.",
          });
          const paramsdata = {
            app_id: parseInt(params.appid),
            authorization_type: params.authorization_type,
            consumer_key: params.consumer_key,
            consumer_secret: params.consumer_secret,
            wp_admin_username: params.wp_admin_username,
            wp_admin_application_password: params.wp_admin_application_password,
            is_verified: "",
            connection_to_establish: params.connection_to_establish,
            is_connected: 0,
          };
          Appcredentailapi(paramsdata, state, "errorcase");
        }
      }
    })
    .catch((error) => {
      if (error.response === undefined) {
        state.setState({
          plugincontent:
            "Either your domain is incorrect, your website did not respond or your server does not allow external domains to connect and CORS must be enabled.",
        });
      } else if (error.response.status === 500) {
        state.setState({
          plugincontent:
            "Either your domain is incorrect, your website did not respond or your server does not allow external domains to connect and CORS must be enabled.",
        });
      } else if (error.response.status === 404) {
        state.setState({
          plugincontent:
            "Your WooCommerce store could not connect with the mobile app.",
        });
      } else {
        state.setState({
          plugincontent:
            "Your WooCommerce store could not connect with the mobile app.",
        });
      }

      const paramsdata = {
        app_id: parseInt(params.appid),
        authorization_type: params.authorization_type,
        consumer_key: params.consumer_key,
        consumer_secret: params.consumer_secret,
        wp_admin_username: params.wp_admin_username,
        wp_admin_application_password: params.wp_admin_application_password,
        is_verified: "",
        connection_to_establish: params.connection_to_establish,
        is_connected: 0,
      };
      Appcredentailapi(paramsdata, state, "errorcase");

      // errortoast("Enter the valid CS and Ck")
    });
};

//GetconsetData
//GetconsetData
export const GetconsentData = (
  setspinner,
  setHeading,
  setParagraph,
  setbuttontext,
  setexitbutton,
  setToggle,
  setprimaryColor,
  setsecondaryColor,
  setbackgroundColor,
  setbuttontextColor,
  setfinalfile,
  setLayout,
  setlastdate,
  setdesignid,
  setupgradeconset,
  setandroidbuildversion,
  setandroidsupportedversion,
  setiosbuildversion,
  setiossupportedversion,
  setAppUserRoleId
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(
      saveceritificate +
        "get-consent-form/" +
        btoa(localStorage.getItem("Appid")),
      {
        headers: headers,
      }
    )
    .then((res) => {
      setandroidbuildversion(res.data.android_build_version);
      setandroidsupportedversion(res.data.android_supported_version);
      setiosbuildversion(res.data.ios_build_version);
      setiossupportedversion(res.data.ios_supported_version);
      setdesignid(
        res.data.consent_form_data.design_id
          ? res.data.consent_form_data.design_id
          : ""
      );
      setupgradeconset(res.data.consent_form_purchased);
      setAppUserRoleId(res.data.appuser_role_id);
      if (res.data.consent_form_data.form_description !== undefined) {
        
        setToggle(res.data.consent_form_data.form_bool === 0 ? false : true);
        setHeading(res.data.consent_form_data.form_title);
        setParagraph(res.data.consent_form_data.form_description);
      }

      if (
        res.data.consent_form_data.consent_form_heading_text !== undefined &&
        res.data.consent_form_data.consent_form_heading_text !== null
      ) {
        
        setToggle(res.data.consent_form_data.form_bool === 0 ? false : true);
        setHeading(res.data.consent_form_data.consent_form_heading_text);
        setParagraph(res.data.consent_form_data.consent_form_sub_text);
        setbuttontext(
          res.data.consent_form_data.consent_form_accept_button_text
        );
        setexitbutton(
          res.data.consent_form_data.consent_form_close_button_text
        );
        if (
          res.data.consent_form_data.consent_form_primary_text_colour_object
        ) {
          setprimaryColor(
            res.data.consent_form_data.consent_form_primary_text_colour_object
              .portal_data
          );
        } else if (res.data.consent_form_data.primary_text_colour) {
          setprimaryColor(res.data.consent_form_data.primary_text_colour);
        }

        if (
          res.data.consent_form_data.consent_form_secondary_text_colour_object
        ) {
          setsecondaryColor(
            res.data.consent_form_data.consent_form_secondary_text_colour_object
              .portal_data
          );
        } else if (res.data.consent_form_data.secondary_text_colour) {
          setsecondaryColor(res.data.consent_form_data.secondary_text_colour);
        }

        if (res.data.consent_form_data.consent_form_button_colour_object) {
          setbackgroundColor(
            res.data.consent_form_data.consent_form_button_colour_object
              .portal_data
          );
        } else if (res.data.consent_form_data.button_bg_colour) {
          setbackgroundColor(res.data.consent_form_data.button_bg_colour);
        }

        if (res.data.consent_form_data.consent_form_button_text_colour_object) {
          setbuttontextColor(
            res.data.consent_form_data.consent_form_button_text_colour_object
              .portal_data
          );
        } else if (res.data.consent_form_data.button_text_colour) {
          setbuttontextColor(res.data.consent_form_data.button_text_colour);
        }

        setfinalfile(res.data.consent_form_data.consent_form_bg_image);
        setLayout(
          res.data.consent_form_data.consent_form_layout == "middle" ||
            res.data.consent_form_data.consent_form_layout == "center"
            ? "center"
            : res.data.consent_form_data.consent_form_layout
        );
      }

      setlastdate(res.data.consent_form_data.last_updated_timestamp);
      setspinner(false);
    })
    .catch((error) => {});
};

//post Conset data Api
export const PostConsetData = (paramsdata, setsaveloader, setlastdate) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(saveceritificate + "save-consent-form", paramsdata, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        setsaveloader(false);
        setlastdate(res.data.data.updated_at);

        sucesstoast("Data saved successfully");
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

//Appcredentailapi Api
export const Appcredentailapi = (paramsdata, state, direction) => {
  Loader(state);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "app_credentials", paramsdata, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        if (direction === "errorcase") {
          if (state.state.wordpress_display_setting) {
            state.setState({
              showRedInputWP: true,
            });
          } else {
            state.setState({
              showRedInputWOO: true,
            });
          }
          state.setState({
            submitval: "Submit",
            loader: "d-none",
            success: false,
            modalError: true,
            supporterror: true,
            handlerender: false,
          });
          localStorage.setItem("plugin_dataactive", parseInt(0));
          localStorage.setItem("is_verified", "0");
        } else if (direction === "redirect") {
          state.props.history.push("/app/connect/wp-plugin");
          localStorage.setItem("plugin_dataactive", parseInt(1));
        } else {
          sucesstoast("The record has been saved.");
          state.props.history.push("/app/connect/wp-plugin");
          state.setState({
            submitval: "SUBMIT",
            loader: "d-none",
          });
        }
      } else if (res.data.code === 400) {
        errortoast(
          "Please enter a valid CK and CS or your website is not ssl certified."
        );
        state.setState({
          success: true,
        });
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};
//versionforemail
export const Versionforemail = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "app/download-link", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        let versionDataArr = [];
        if (
          res.data.data === null ||
          res.data.data === undefined ||
          res.data.data.ios === undefined
        ) {
          versionDataArr = null;
        } else {
          let androidVersions = [];
          res.data.data.android.versions.all_build_versions &&
            res.data.data.android.versions.all_build_versions.map(
              (eachVersion) => {
                let obj = {
                  name: "mobile_app_version",
                  label: "Version " + eachVersion,
                  value: eachVersion,
                };
                androidVersions.push(obj);
              }
            );

          let iosVersions = [];
          res.data.data.ios.versions.all_build_versions &&
            res.data.data.ios.versions.all_build_versions.map((eachVersion) => {
              let obj = {
                name: "mobile_app_version",
                label: "Version " + eachVersion,
                value: eachVersion,
              };
              iosVersions.push(obj);
            });
          versionDataArr = androidVersions.concat(iosVersions);
        }
        state.setState({
          android_build:
            res.data.data.android && res.data.data.android.versions
              ? res.data.data.android.versions.build_version
              : null,
          android_latest:
            res.data.data.android && res.data.data.android.versions
              ? res.data.data.android.versions.latest_version
              : null,
          ios_build:
            res.data.data.ios && res.data.data.ios.versions
              ? res.data.data.ios.versions.build_version
              : null,
          ios_latest:
            res.data.data.ios && res.data.data.ios.versions
              ? res.data.data.ios.versions.latest_version
              : null,
          versiondata: versionDataArr,
          role_id: res.data.appuser_role_id,
          // res.data.data === null || res.data.data === undefined ||  res.data.data.ios===undefined
          //   ? null
          //   : res.data.data.android.versions.all_build_versions.concat(
          //       res.data.data.ios.versions.all_build_versions
          //     ),
          reseller_package_expiry_date:
            res.data.data === null || res.data.data === undefined
              ? null
              : "Your subscription expires on " +
                res.data.data.module_data.reseller_package_expiry_date,
        });
      } else {
        state.setState({
          role_id: res.data.appuser_role_id,
          versiondata: null,
          mobile_app_version: "",
          reseller_package_expiry_date: "",
        });
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

// Getalladdons get API
export const NewGetallpackageaddon = (state, params, from, setprice) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  const getPricing = async (code) => {
    return new Promise((resolve) => {
      var pricevalue = "";

      window.Paddle.Product.Prices(
        parseInt(code),
        (prices) => (pricevalue = prices.price.gross)
      );
      setTimeout(() => {
        resolve(pricevalue);
      }, 2000);
    });
  };

  axios
    .get(
      Url +
        "get_package_addons/" +
        (from === "workspace"
          ? btoa(null)
          : btoa(localStorage.getItem("Appid"))) +
        "/" +
        (from === "workspace"
          ? btoa(localStorage.getItem("workspaceId"))
          : btoa(null)) +
        "/" +
        btoa(null) +
        "/" +
        btoa(params.plan_code),
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        var setpricevalue = [];

        res.data.data.addons.map(
          async (res1, i) =>
            (setpricevalue[res1.addon_code] = await getPricing(res1.addon_code))
        );

        

        setTimeout(() => {
          if (
            res.data.data.plan_data &&
            res.data.data.plan_data.is_deal_plan == 1
          ) {
            state.handlefilter(2, 0);
          }
          state.setState({
            spinner: false,
            isWorkspaceApp:res.data.data.is_workspace_app,
            paddleaddonprice: setpricevalue,
            addondata:
              params.plan_code === "preview" ||
              params.plan_code === process.env.REACT_APP_Lifetime_preview
                ? res.data.data.addons
                : res.data.data.addons,
            selected_addon_code:
              res.data.data.subscription === null
                ? []
                : res.data.data.subscription.addon_codes,
            zoho_subscription_id:
              res.data.data.subscription === null
                ? ""
                : res.data.data.subscription.zoho_subscription_id,
            auto_addons:
              res.data.data.subscription === null
                ? []
                : res.data.data.subscription.auto_addons,
            app_id:
              res.data.data.subscription === null
                ? localStorage.getItem("Appid")
                : res.data.data.subscription.app_id,
            onetimeaddon: res.data.data.subscription.one_time_addons,
            addon_code: res.data.data.subscription.addon_codes,
            appuser_role_id: res.data.data.appuser_role_id,
            addon_info: res.data.data.addon_info,
            allowed_module: res.data.data.subscription.device_type,
            customer_billing_type:
              res.data.data.user_data.customer_billing_type,
            is_deal_plan: res.data.data.plan_data.is_deal_plan,
            plan:
              res.data.data.subscription === null
                ? "preview"
                : res.data.data.subscription.plan,
            onetime_addons_purchased_json:
              res.data.data.subscription === null ||
              res.data.data.subscription.onetime_addons_purchased_json === "" ||
              res.data.data.subscription.onetime_addons_purchased_json === null
                ? 0
                : JSON.parse(
                    res.data.data.subscription.onetime_addons_purchased_json
                  ),
            recurring_addons_purchased_json:
              res.data.data.subscription === null ||
              res.data.data.subscription.recurring_addons_purchased_json ===
                null ||
              res.data.data.subscription.recurring_addons_purchased_json === ""
                ? 0
                : JSON.parse(
                    res.data.data.subscription.recurring_addons_purchased_json
                  ),
            setprice: setpricevalue,
            addon_json_for_paddle: res.data.data.subscription.addon_json,
            downgrade_at_term_end:
              res.data.data.subscription.downgrade_at_term_end,
            addon_quantity:
              res.data.addon_quantity === undefined
                ? 0
                : res.data.addon_quantity,
            add_user_quantity:
              res.data.data.user_data.customer_billing_type !== 2 &&
              (res.data.data.subscription === null ||
              res.data.data.subscription.recurring_addons_purchased_json ===
                "" ||
              res.data.data.subscription.recurring_addons_purchased_json ===
                null ||
              res.data.data.subscription.recurring_addons_purchased_json ===
                "[]" ||
              res.data.data.subscription.recurring_addons_purchased_json[
                "add_user"
              ] === undefined
                ? 1
                : JSON.parse(
                    res.data.data.subscription.recurring_addons_purchased_json
                  )["add_user"].quantity === 0
                ? 1
                : JSON.parse(
                    res.data.data.subscription.recurring_addons_purchased_json
                  )["add_user"].quantity),
            new_add_user_quantity:
              res.data.data.user_data.customer_billing_type !== 2
                ? res.data.data.subscription === null ||
                  res.data.data.subscription.recurring_addons_purchased_json ===
                    "" ||
                  res.data.data.subscription.recurring_addons_purchased_json ===
                    null
                  ? res.data.data.user_data.customer_billing_type === 3
                    ? res.data.addon_quantity === 0
                      ? 1
                      : res.data.addon_quantity
                    : 1
                  : JSON.parse(
                      res.data.data.subscription.recurring_addons_purchased_json
                    )[state.state.addoncode] === undefined
                  ? res.data.addon_quantity === 0
                    ? 1
                    : res.data.addon_quantity
                  : JSON.parse(
                      res.data.data.subscription.recurring_addons_purchased_json
                    )[state.state.addoncode].quantity === 0
                  ? 1
                  : JSON.parse(
                      res.data.data.subscription.recurring_addons_purchased_json
                    )[state.state.addoncode].quantity
                : res.data.addon_quantity === 0
                ? 1
                : res.data.addon_quantity,
          });
        }, 3000);
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};
// Getalladdons get API
export const Getallpackageaddon = (state, params, from) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(
      Url +
        "get_package_addons/" +
        (from === "workspace"
          ? btoa(null)
          : btoa(localStorage.getItem("Appid"))) +
        "/" +
        (from === "workspace"
          ? btoa(localStorage.getItem("workspaceId"))
          : btoa(null)) +
        "/" +
        btoa(null) +
        "/" +
        btoa(params.plan_code),
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          spinner: false,
          addondata:
            params.plan_code === "preview" ||
            params.plan_code === process.env.REACT_APP_Lifetime_preview
              ? res.data.data.addons
              : res.data.data.addons,
          selected_addon_code:
            res.data.data.subscription === null
              ? []
              : res.data.data.subscription.addon_codes,
          zoho_subscription_id:
            res.data.data.subscription === null
              ? ""
              : res.data.data.subscription.zoho_subscription_id,
          auto_addons:
            res.data.data.subscription === null
              ? []
              : res.data.data.subscription.auto_addons,
          app_id:
            res.data.data.subscription === null
              ? localStorage.getItem("Appid")
              : res.data.data.subscription.app_id,
          onetimeaddon: res.data.data.subscription.one_time_addons,
          addon_code: res.data.data.subscription.addon_codes,
          appuser_role_id: res.data.data.appuser_role_id,
          addon_info: res.data.data.addon_info,
          allowed_module: res.data.data.subscription.device_type,
          customer_billing_type: res.data.data.user_data.customer_billing_type,
          is_deal_plan: res.data.data.plan_data.is_deal_plan,
          plan:
            res.data.data.subscription === null
              ? "preview"
              : res.data.data.subscription.plan,
          onetime_addons_purchased_json:
            res.data.data.subscription === null ||
            res.data.data.subscription.onetime_addons_purchased_json === "" ||
            res.data.data.subscription.onetime_addons_purchased_json === null
              ? 0
              : JSON.parse(
                  res.data.data.subscription.onetime_addons_purchased_json
                ),
          recurring_addons_purchased_json:
            res.data.data.subscription === null ||
            res.data.data.subscription.recurring_addons_purchased_json ===
              null ||
            res.data.data.subscription.recurring_addons_purchased_json === ""
              ? 0
              : JSON.parse(
                  res.data.data.subscription.recurring_addons_purchased_json
                ),
        });
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

//Social Detail

export const Socialdetail = (params, setSocialState) => {
  // Loader(state);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "app_setting_social", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        setSocialState({
          submitval: "Save",
          loader: "d-none",
        });
        sucesstoast("Social settings updated successfully");
        // state.props.history.push("/app/preview");
      } else if (res.data.code === 400) {
        setSocialState({
          submitval: "Save",
          loader: "d-none",
        });
        if (Object.keys(res.data.data).indexOf("facebook") === -1) {
        } else {
          errortoast(res.data.data.facebook[0]);
        }
        if (Object.keys(res.data.data).indexOf("google") === -1) {
        } else {
          errortoast(res.data.data.google[0]);
        }
        if (Object.keys(res.data.data).indexOf("instagram") === -1) {
        } else {
          errortoast(res.data.data.instagram[0]);
        }
        if (Object.keys(res.data.data).indexOf("linkedin") === -1) {
        } else {
          errortoast(res.data.data.linkedin[0]);
        }
        if (Object.keys(res.data.data).indexOf("pintrest") === -1) {
        } else {
          errortoast(res.data.data.pintrest[0]);
        }
        if (Object.keys(res.data.data).indexOf("tumblr") === -1) {
        } else {
          errortoast(res.data.data.tumblr[0]);
        }
        if (Object.keys(res.data.data).indexOf("twitter") === -1) {
        } else {
          errortoast(res.data.data.twitter[0]);
        }
        if (Object.keys(res.data.data).indexOf("youtube") === -1) {
        } else {
          errortoast(res.data.data.youtube[0]);
        }
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

//Get App Detail

export const Getappsetting = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(Url + "get_app_setting/" + btoa(localStorage.getItem("Appid")), {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        if (params === "social") {
          state.setState({
            spinner: false,
            facebook: res.data.data.social.facebook,
            google: res.data.data.social.google,

            pintrest: res.data.data.social.pintrest,
            youtube: res.data.data.social.youtube,
            twitter: res.data.data.social.twitter,
            instagram: res.data.data.social.instagram,
            linkedin: res.data.data.social.linkedin,
            tumblr: res.data.data.social.tumblr,
          });
        }
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

//Zohosubscription

export const ZohosubscriptionApi = (state, callback) => {
  Loader(state);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(Url + "subscription/" + btoa(localStorage.getItem("Appid")), {
      headers: headers,
    })
    .then((res) => {
      state.setState(
        {
          zoho_plan_code: res.data.data.plan_code,
          subscription_id: res.data.data.subscription_id,
          addon_code: res.data.data.addon_code,
        },
        () => {
          localStorage.setItem("plan_code", btoa(res.data.data.plan_code));
          callback();
        }
      );
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};
//Savesubscriptionapi
export const Savesubscriptionapi = (state, params, callback) => {
  Loader(state);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "save-subscription", params, {
      headers: headers,
    })
    .then((res) => {
      callback();
      if (res.data.code === 200) {
        
        callback();
        // setTimeout(function () {
        //   callback();
        //   window.location.href = "/app/dashboard";
        // }, 12000);
      }
    })
    .catch((error) => {
      
      // errortoast("Something went wrong. Try again after sometime.");
    });
};
//Getpackage
//Showcredit API
export const Showcredit = (state, from, setCreditData) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(
      Url + "credits?workspace_id=" + btoa(localStorage.getItem("workspaceId")),
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        if (from === "functionalCompo") {
          setCreditData(res.data.data);
        } else {
          state.setState({
            creditdata: res.data.data,
          });
        }
      } else if (res.data.code === 201) {
        if (from === "functionalCompo") {
          setCreditData(res.data.data);
        } else {
          state.setState({
            creditdata: res.data.data,
          });
        }
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};
export const GetpackageAll = (state, params, callback) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(Url + "packages/" + params.appid + "/" + params.tech, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          spinner: false,
          package_duration: res.data.data.package_duration,
          monthlydata: res.data.data.month,
          yearlydata: res.data.data.year,
          one_time: res.data.data.one_time,
          lifetimedata: res.data.data.lifetime,
          lifetimecheck: res.data.data.lifetime ? 1 : 0,
          subscription_id: res.data.data.subscription,
          subscriptionToCheck: res.data.data.subscription,
          subscription_plan: res.data.data.subscription_plan,
          build_process_status: res.data.data.build_process_status,
          discount: res.data.data.discount,
          appuser_role_id: res.data.data.appuser_role_id,
          customer_billing_type: res.data.data.customer_billing_type,
          current_package_status: res.data.data.current_package_status,
          payment_methods: res.data.data.payment_methods,
          customer_type: res.data.data.customer_type,
          reseller_licence_id: res.data.data.reseller_licence_id,
          stripe_customer_id: res.data.data?.stripe_customer_id,
          currentplanname: res.data.data.plan_name,
          currentplanrpice:
            res.data.data.package_duration === 1
              ? res.data.data.total
              : res.data.data.package_duration === 2
              ? res.data.data.total / 12
              : res.data.data.total,
          currency: res.data.data.currency,
          endcycledata: res.data.data.billing_date,
          currentplanduration: res.data.data.package_duration,
          downgrade_at_term_end: res.data.data.downgrade_at_term_end,
          is_android_rebuild_required:
            res.data.data.is_android_rebuild_required,
          is_ios_rebuild_required: res.data.data.is_ios_rebuild_required,
          is_deal_plan: res.data.data.is_deal_plan,
        });
        // checkedtab : res.data.data.package_duration === 1 ? 'monthly'
        //              : res.data.data.package_duration === 2 ? 'yearly'
        //              : res.data.data.package_duration === 4 ? 'lifetime'
        //              : 'yearly',
        callback();
      } else if (res.data.code === 401) {
        if (res.data.status === "Token Expired") {
          // state.props.history.push("/");
          let myItem = localStorage.getItem("buildversion");
          let myItemversion = localStorage.getItem("Apiversion");
          let previous_location = localStorage.getItem("Previouslocation");
          localStorage.clear();
          localStorage.setItem("Previouslocation", previous_location);
          localStorage.setItem("buildversion", myItem);
          localStorage.setItem("Apiversion", myItemversion);
        }
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

//Woocommerse User Change Plan
export const wooChangePlan = (state, params) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(dashboardurl + "woocommerce/modify-subscription", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        window.location.href = res.data.payment_url;
      } else if (res.data.code === 201) {
        state.setState({
          loader_remove: "",
          submitval_confirmpop: "Yes",
        });
        state.props.history.push("/transaction-successful");
      } else if (res.data.code === 401) {
        if (res.data.status === "Token Expired") {
          // state.props.history.push("/");
          let myItem = localStorage.getItem("buildversion");
          let myItemversion = localStorage.getItem("Apiversion");
          let previous_location = localStorage.getItem("Previouslocation");
          localStorage.clear();
          localStorage.setItem("Previouslocation", previous_location);
          localStorage.setItem("buildversion", myItem);
          localStorage.setItem("Apiversion", myItemversion);
        }
      }
    })
    .catch((err) => {
      
      errortoast("Something went wrong. Try again after sometime.");
    });
};

//Paymentgatway
export const Paymentsent = (params, state) => {
  if (params.check !== 3) {
    Loader(state);
  }
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  state.setState({
    spinner: true,
  });
  axios
    .post(Url + "payment-gateway", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        if (params.check === 3) {
          state.setState({
            modal: false,
            requestmodal: false,
          });

          Getappsetting("payment", state);
        } else {
          sucesstoast("Gateway request has been submitted successfully");
          state.setState({
            submitval: "Save and Enable",
            loader: "d-none",
            modal: false,
            requestmodal: false,
          });
          Getappsetting("payment", state);
        }
      } else if (res.data.code === 400) {
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};
//DowngradeApi
export const DowngradeApi = (params, state) => {
  if (params.check !== 3) {
    Loader(state);
  }
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "downgrade-plan", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.props.history.push(
          `/transaction-successful/${btoa("zoho")}?product=${
            parseInt(params.isPlan) === 0 ? "addon" : "plan"
          }&action=downgrade`
        );
      } else if (res.data.code === 203) {
        state.setState({
          deluser: true,
          popupdata: res.data.data,
        });
      } else {
        errortoast(res.data.data);
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

//Paymentgatway Default
export const Paymentsentdefault = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  state.setState({
    spinner: true,
  });
  axios
    .post(Url + "default-payment-gateway", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        Getappsetting("payment", state);
      } else if (res.data.code === 400) {
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};
//Getcreditcard
export const Getcreditcard = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  // old api modifySubscription
  axios
    .post(Url + "get-credit-cards", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          carddata: res.data.data,
          showform: res.data.data.length === 0 ? true : false,
          card_id: res.data.data[0].card_id,
        });
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};
//Makepayment
export const Makepayment = (params, state, state2) => {
  Loader(state);
  // 
  let checkerForqueryString;
  let subscription_plan;
  if (parseInt(state.state.isPlan) === 0) {
    params.product = "addon";
    checkerForqueryString = "upgrade";
    params.action = "upgrade";
  } else {
    params.product = "plan";
    subscription_plan = state.state.subscription_plan;
    if (params.app_plan !== undefined) {
      subscription_plan = params.app_plan;
    }
    if (
      subscription_plan === process.env.REACT_APP_Lifetime_preview ||
      subscription_plan === process.env.REACT_APP_STRIPE_PREVIEW ||
      subscription_plan === process.env.REACT_APP_zoho_preview
    ) {
      checkerForqueryString = "upgrade";
      params.action = "upgrade";
    } else if (params.payment_method_id === "downgrade") {
      checkerForqueryString = "downgrade";
      params.action = "downgrade";
    } else {
      checkerForqueryString = "change-plan ";
      params.action = "change-plan";
    }
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  // old api modifySubscription
  axios
    .post(Url + "modifySubscription", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        window.open(res.data.data.url, "_self");
      } else if (res.data.code === 202) {
        state2.props.history.push(
          `/transaction-successful/${btoa("zoho")}?product=${
            parseInt(state.state.isPlan) === 0 ? "addon" : "plan"
          }&action=${checkerForqueryString}`
        );
      } else {
        state.setState({
          submitval: "Make payment",
          loader: "d-none",
          submitval_update: "UPDATE",
          formbuttondisabled: false,
        });
      }
    })
    .catch((error) => {
      state.setState({
        submitval: "Make payment",
        loader: "d-none",
        formbuttondisabled: false,
      });
      errortoast("Something went wrong. Try again after sometime.");
    });
};

//Makepaymentstrip
export const Makestrippayment = (params, state, way, callback) => {
  Loader(state);
  let checkerForqueryString;
  let subscription_plan;
  if (parseInt(state.state.isPlan) === 0) {
    params.product = "addon";
    checkerForqueryString = "upgrade";
    params.action = "upgrade";
  } else {
    params.product = "plan";
    subscription_plan = state.state.subscription_plan;
    if (params.app_plan !== undefined) {
      subscription_plan = params.app_plan;
    }
    if (
      subscription_plan === process.env.REACT_APP_Lifetime_preview ||
      subscription_plan === process.env.REACT_APP_STRIPE_PREVIEW ||
      subscription_plan === process.env.REACT_APP_zoho_preview
    ) {
      checkerForqueryString = "upgrade";
      params.action = "upgrade";
    } else if (params.payment_method_id === "downgrade") {
      checkerForqueryString = "downgrade";
      params.action = "downgrade";
    } else {
      checkerForqueryString = "change-plan ";
      params.action = "change-plan";
    }
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  // old api modifySubscription
  
  if (way === "addon") {
    var url = dashboardurl + "one-time-payment";
  } else {
    var url = saveceritificate + "update-subscription";
    if(way === "addon") {
      params.success_path = `/transaction-successful/${btoa("paddle")}?product=addon&action=upgrade`
    } else {
      params.success_path = `/transaction-successful/${btoa("stripe")}?product=${parseInt(state.state.isPlan) === 0 ? "addon" : "plan" }&action=${checkerForqueryString}`
    }
    params.cancel_path = state.props?.location?.pathname;
  }
  axios
    .post(url, params, {
      headers: headers,
    })
    .then((res) => {
      if(+res.data.code === 204 && res?.data?.url) {
        window.location.href = res?.data?.url;
      }
      if (res.data.code === 200) {
        
        if (way === "addon") {
          // sucesstoast("Addon purchased successfully.");
          // state.props.history.push("/app/addons");

          state.props.history.push(
            `/transaction-successful/${btoa(
              "paddle"
            )}?product=addon&action=upgrade`
          );

          const userinfo = JSON.parse(localStorage.getItem("user_info"));

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "checkout_successful",
            user_email: userinfo.email,
            user_name: userinfo.name,
            user_id: userinfo.id,
            agency_id: localStorage.getItem("agency_id")
              ? parseInt(localStorage.getItem("agency_id"))
              : 1,
            transactionDetails: [
              {
                product_type: "addon",
                product_action: params.action,
                plan_type: "pay-per-app",
                price: res.data.amount,
                currency: state.state.currency1,
              },
            ],
          });
        } else {
          localStorage.setItem("checkoutpageamount", res.data.amount);
          localStorage.setItem(
            "checkoutsubscriptionid",
            res.data.subscription_id
          );
          localStorage.setItem("checkoutcustomerid", res.data.customer_id);

          const userinfo = JSON.parse(localStorage.getItem("user_info"));

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "checkout_successful",
            user_email: userinfo.email,
            user_name: userinfo.name,
            user_id: userinfo.id,
            agency_id: localStorage.getItem("agency_id")
              ? parseInt(localStorage.getItem("agency_id"))
              : 1,
            transactionDetails: [
              {
                product_type: "plan",
                product_action: params.action,
                plan_type: "pay-per-app",
                price: res.data.amount,
                currency: state.state.currency1,
              },
            ],
          });

          var url = `/transaction-successful/${btoa("stripe")}?product=${
            parseInt(state.state.isPlan) === 0 ? "addon" : "plan"
          }&action=${checkerForqueryString}`;

          window.open(url, "_self");
        }
        // window.open(res.data.data.url,"_self");
      } else {
        if (callback != undefined && callback != null) {
          state.setState(
            {
              code: res.data.code,
            },
            () => callback()
          );
        }
        if (res.data.code == 501 || res.data.code == 204) {
        } else if (res.data.status !== undefined) {
          if (!window.location.pathname.includes("pricing")) {
            errortoast(res.data.status);
          }
        } else {
          if (!window.location.pathname.includes("pricing")) {
            errortoast("something went wrong");
          }
        }

        state.setState({
          submitval: way === "addon" ? "Buy this addon" : "Make payment",
          loader: "d-none",
          submitval_update: "UPDATE",
          formbuttondisabled: false,
        });
      }
    })
    .catch((error) => {
      state.setState({
        submitval: way === "addon" ? "Buy this addon" : "Make payment",
        loader: "d-none",
        formbuttondisabled: false,
      });
      errortoast("Something went wrong. Try again after sometime.");
    });
};

//

//Makepaymentstrip
export const WorkspaceAddon = (params, state, way) => {
  Loader(state);
  let checkerForqueryString;
  let subscription_plan;
  if (parseInt(state.state.isPlan) === 0) {
    params.product = "addon";
    checkerForqueryString = "upgrade";
    params.action = "upgrade";
  } else {
    params.product = "plan";
    subscription_plan = state.state.subscription_plan;
    if (params.app_plan !== undefined) {
      subscription_plan = params.app_plan;
    }
    if (
      subscription_plan === process.env.REACT_APP_Lifetime_preview ||
      subscription_plan === process.env.REACT_APP_STRIPE_PREVIEW ||
      subscription_plan === process.env.REACT_APP_zoho_preview
    ) {
      checkerForqueryString = "upgrade";
      params.action = "upgrade";
    } else if (params.payment_method_id === "downgrade") {
      checkerForqueryString = "downgrade";
      params.action = "downgrade";
    } else {
      checkerForqueryString = "change-plan ";
      params.action = "change-plan";
    }
  }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  // old api modifySubscription
  axios
    .post(dashboardurl + "one-time-payment", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        if (way === "addon") {
          // sucesstoast("Addon purchased successfully.");
          // state.props.history.push("/app/addons");
          state.props.history.push(
            `/transaction-successful/${btoa(
              "paddle"
            )}?product=addon&action=upgrade`
          );
        } else {
          state.props.history.push(
            `/transaction-successful/${btoa("stripe")}?product=${
              parseInt(state.state.isPlan) === 0 ? "addon" : "plan"
            }&action=${checkerForqueryString}`
          );
        }
        // window.open(res.data.data.url,"_self");
      } else {
        state.setState({
          submitval: way === "addon" ? "Buy this addon" : "Make payment",
          loader: "d-none",
          submitval_update: "UPDATE",
          formbuttondisabled: false,
        });
      }
    })
    .catch((error) => {
      state.setState({
        submitval: way === "addon" ? "Buy this addon" : "Make payment",
        loader: "d-none",
        formbuttondisabled: false,
      });
 
      errortoast("Something went wrong. Try again after sometime.");
    });
};

//

export const Makestrippayment2 = (params, state, way, state2) => {

  // Loader(state);

  let checkerForqueryString;
  let subscription_plan;
  if (parseInt(state2.state.isPlan) === 0) {
    params.product = "addon";
    checkerForqueryString = "upgrade";
    params.action = "upgrade";
  } else {
    params.product = "plan";
    subscription_plan = state2.state.subscription_plan;
    if (params.app_plan !== undefined) {
      subscription_plan = params.app_plan;
    }
    if (
      subscription_plan === process.env.REACT_APP_Lifetime_preview ||
      subscription_plan === process.env.REACT_APP_STRIPE_PREVIEW ||
      subscription_plan === process.env.REACT_APP_zoho_preview
    ) {
      checkerForqueryString = "upgrade";
      params.action = "upgrade";
    } else if (params.payment_method_id === "downgrade") {
      checkerForqueryString = "downgrade";
      params.action = "downgrade";
    } else {
      checkerForqueryString = "change-plan ";
      params.action = "change-plan";
    }
  }
  params.success_path = `/transaction-successful/${btoa("stripe")}?product=${parseInt(state?.state?.isPlan) === 0 ? "addon" : "plan"}&action=${checkerForqueryString}`
  params.cancel_path = state.props?.location?.pathname;

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  // old api modifySubscription
  axios
    .post(saveceritificate + "update-subscription", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code == 200) {
        
        if (way === "addon") {
          sucesstoast("Addon purchased successfully.");
        } else {
          state.props.history.push(
            `/transaction-successful/${btoa("stripe")}?product=${
              parseInt(state2.state.isPlan) === 0 ? "addon" : "plan"
            }&action=${checkerForqueryString}`
          );
        }
      } else if (res.data.code === 204 && res?.data?.url) {
        window.location.href = res?.data?.url;
      } else {
        if (res.data.status !== undefined) {
          errortoast(res.data.status);
        } else {
          errortoast("something went wrong");
        }
        state2.setState({
          submitval: way === "addon" ? "Buy this addon" : "Make payment",
          loader: "d-none",
          index_checker: "",
          submitval_update: "UPDATE",
          formbuttondisabled: false,
        });
      }
    })
    .catch((error) => {
      state2.setState({
        submitval: way === "addon" ? "Buy this addon" : "Make payment",
        loader: "d-none",
        index_checker: "",
        formbuttondisabled: false,
      });
      // 
      errortoast("Something went wrong. Try again after sometime.");
      
    });
};

//SendpaymentinfotoserverApi
export const SendpaymentinfotoserverApi = (params, state, callback) => {
  Loader(state);
  state.setState({
    formbuttondisabled: true,
    submitval_update: "",
  });
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  var actionparam = params.downgrade_at_term_end == 1 ? "downgrade" : "upgrade";

  axios
    .post(Url + "payment", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        const userinfo = JSON.parse(localStorage.getItem("user_info"));

        if (
          localStorage.getItem("workspaceId") !== "" &&
          localStorage.getItem("workspaceId") !== "undefined" &&
          localStorage.getItem("workspaceId") !== undefined &&
          localStorage.getItem("workspaceId") !== null
        ) {
          var type = "workspace";
        } else {
          var type = "pay-per-app";
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "checkout_successful",
          user_email: userinfo.email,
          user_name: userinfo.name,
          user_id: userinfo.id,
          agency_id: localStorage.getItem("agency_id")
            ? parseInt(localStorage.getItem("agency_id"))
            : 1,
          transactionDetails: [
            {
              product_type: "plan",
              product_action: actionparam,
              plan_type: type,
              price: res.data.total,
              currency: "USD",
            },
          ],
        });
        state.setState(
          {
            code: res.data.code,
          },
          () => callback()
        );
      }
      if (res.data.code === 501) {
        state.setState(
          {
            code: res.data.code,
          },
          () => callback()
        );
      } else {
        state.setState({
          code: res.data.code,
          formbuttondisabled: false,
          submitval_update: "UPDATE",
        });
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

//Pixabay API on load
export const Pixalbay = (params, state) => {
  state.setState({
    pixelspinner: true,
  });
  const pixalbayimage = [];
  const perpage = params.orientation === "vertical" ? "14" : "28";
  axios
    .get(
      "https://pixabay.com/api/?key=" +
        process.env.REACT_APP_Pixabay_key +
        "&q=solid-black&image_type=photo&per_page=" +
        perpage +
        "&max_width=640&orientation=" +
        params.orientation +
        "&safesearch=true&timestamp=" +
        new Date().getTime()
    )
    .then((res) => {
      if (res.data.totalHits > 0) {
        res.data.hits.map((number) => pixalbayimage.push(number.largeImageURL));
        state.setState({
          pixalbayimage: pixalbayimage,
          pixalimagenotavailable: false,
          pixelspinner: false,
        });
      } else {
        state.setState({
          pixalimagenotavailable: true,
          pixelspinner: false,
        });
      }
    })
    .catch((error) => {});
};

//Pixabay API on search
export const Pixalbaysearch = (params, state) => {
  const pixalbayimage = [];
  const perpage = params.orientation === "vertical" ? "14" : "28";
  state.setState({
    pixelspinner: true,
  });

  axios
    .get(
      "https://pixabay.com/api/?key=" +
        process.env.REACT_APP_Pixabay_key +
        "&q=" +
        params.pixalsearchvalue +
        "&image_type=photo&per_page=" +
        perpage +
        "&page=1&max_width=640&orientation=" +
        params.orientation +
        "&safesearch=true&timestamp=" +
        new Date().getTime()
    )
    .then((res) => {
      if (res.data.totalHits > 0) {
        res.data.hits.map((number) => pixalbayimage.push(number.largeImageURL));
        state.setState({
          pixalbayimage,
          pixalimagenotavailable: false,
          pixelspinner: false,
        });
      } else {
        state.setState({
          pixalimagenotavailable: true,
          pixelspinner: false,
        });
      }
    })
    .catch((error) => {});
};

//Pixabay API on scroll
export const Pixalbayscroll = (params, state) => {
  const pixalbayimage = params.pixalbayimage;
  state.setState({
    pixelspinner: true,
  });
  const perpage = params.orientation === "vertical" ? "14" : "28";
  if (params.pageval < 19) {
    axios
      .get(
        "https://pixabay.com/api/?key=" +
          process.env.REACT_APP_Pixabay_key +
          "&q=" +
          params.pixalsearchvalue +
          "&image_type=photo&per_page=" +
          perpage +
          "&max_width=640&orientation=" +
          params.orientation +
          "&page=" +
          params.pageval +
          "&safesearch=true&timestamp=" +
          new Date().getTime()
      )
      .then((res) => {
        if (res.data.totalHits > 0) {
          res.data.hits.map((number) =>
            pixalbayimage.push(number.largeImageURL)
          );
          state.setState({
            pixalbayimage,
            pixalimagenotavailable: false,
            pixelspinner: false,
          });
        } else {
          state.setState({
            pixalimagenotavailable: true,
            pixelspinner: false,
          });
        }
      })
      .catch((error) => {});
  }
};

//Subscriptionlist API
export const Subscriptionlist = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  if (!params.page || params.page <= 1) {
    state.setState({
      typedata: [],
    });
  }

  axios
    .get(
      Url +
        "user-subscription?limit=" +
        params.limit +
        "&page=" +
        params.page +
        "&type=" +
        params.type +
        "&app_id=" +
        params.app_id +
        "&workspace_id=" +
        params.workspace_id +
        "&agency_id=" +
        params.agency_id,
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        
        state.setState({
          customer_type: res.data.customer_type,
          card_count: res.data.card_count,
        });
        
        if (params.type == 3 && params.agency_id != "") {
          state.setState({
            searchname: res.data.data[0].agency_name,
          });
        }
        if (params.type == 2 && params.workspace_id != "") {
          state.setState({
            searchname: res.data.data[0].workspace_name,
          });
        }
        if (params.type == 1 && params.app_id != "") {
          state.setState({
            searchname: res.data.data[0].app_name,
          });
        }
        if (params.page > 1) {
          
          let billingdata = params.billingdata;
          Array.prototype.push.apply(billingdata, res.data.data);

          state.setState({
            billingdata: billingdata,
            customer_billing_type: res.data.customer_billing_type,
            // spinner: false,
            mainSpinner: false,
            bottomspinner: false,
            handlepage: res.data.data,
            datanumber: res.data.data.length,
            customer_type: res.data.customer_type,
            customer_billing_type: res.data.customer_billing_type,
            typedata: res.data.type,
          });
        } else {
          state.setState({
            // spinner: false,
            mainSpinner: false,
            bottomspinner: false,
            billingdata: res.data.data,
            datanumber: res.data.data.length,
            customer_type: res.data.customer_type,
            customer_billing_type: res.data.customer_billing_type,
            typedata: res.data.type,
          });
        }
      } else if (res.data.code === 203) {
        if (res.data.status === "Token Expired") {
          // state.props.history.push("/");
          let myItem = localStorage.getItem("buildversion");
          let myItemversion = localStorage.getItem("Apiversion");
          let previous_location = localStorage.getItem("Previouslocation");
          localStorage.clear();
          localStorage.setItem("Previouslocation", previous_location);
          localStorage.setItem("buildversion", myItem);
          localStorage.setItem("Apiversion", myItemversion);
        }
      }
    })
    .catch((error) => {});
};

//New Billing history API
export const NewBillinghistoryApi = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  state.setState({
    typedata: [],
  });
  axios
    .get(
      Url +
        "invoice?limit=" +
        params.limit +
        "&page=" +
        params.page +
        "&type=" +
        params.type +
        "&app_id=" +
        params.app_id +
        "&workspace_id=" +
        params.workspace_id +
        "&agency_id=" +
        params.agency_id,
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        
        state.setState({
          customer_type: res.data.customer_type,
          card_count: res.data.card_count,
        });
        
        if (
          params.type == 3 &&
          params.agency_id != "" &&
          res.data.data.length > 0
        ) {
          state.setState({
            searchname: res.data.data[0].agency_name,
          });
        }
        if (
          params.type == 2 &&
          params.workspace_id != "" &&
          res.data.data.length > 0
        ) {
          state.setState({
            searchname: res.data.data[0].workspace_name,
          });
        }
        if (
          params.type == 1 &&
          params.app_id != "" &&
          res.data.data.length > 0
        ) {
          state.setState({
            searchname: res.data.data[0].app_name,
          });
        }
        if (params.page > 1) {
          
          let billinghistory = params.billinghistory;
          Array.prototype.push.apply(billinghistory, res.data.data);

          state.setState({
            billinghistory: billinghistory,
            customer_billing_type: res.data.customer_billing_type,
            mainSpinner: false,
            bottomspinner: false,
            handlepage: res.data.data,
            datanumber: res.data.data.length,
            customer_type: res.data.customer_type,
            customer_billing_type: res.data.customer_billing_type,
            typedata: res.data.type,
            hitNextPagination:
              params.limit === res.data.data.length + res.data.removed_records,
          });
        } else {
          state.setState({
            // spinner: false,
            mainSpinner: false,
            bottomspinner: false,
            billinghistory: res.data.data,
            datanumber: res.data.data.length,
            customer_type: res.data.customer_type,
            customer_billing_type: res.data.customer_billing_type,
            typedata: res.data.type,
            hitNextPagination:
              params.limit === res.data.data.length + res.data.removed_records,
          });
        }
      } else if (res.data.code === 203) {
        if (res.data.status === "Token Expired") {
          // state.props.history.push("/");
          let myItem = localStorage.getItem("buildversion");
          let myItemversion = localStorage.getItem("Apiversion");
          let previous_location = localStorage.getItem("Previouslocation");
          localStorage.clear();
          localStorage.setItem("Previouslocation", previous_location);
          localStorage.setItem("buildversion", myItem);
          localStorage.setItem("Apiversion", myItemversion);
        }
      }
    })
    .catch((error) => {});
};
//Lifetime pricing
export const Lifetimepricing = (state, callback, appId) => {
  if (appId !== "" && appId !== undefined) {
    appId = "/" + appId;
  } else {
    appId = "";
  }
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(Url + `reseller/packages${appId}`, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        
        state.setState({
          lifetimedata: res.data.data,
          spinner: false,
          dealsData: res.data.data === null ? [] : res.data.data,
          customer_type: res.data.customer_type,
          customer_billing_type: res.data.customer_billing_type,
          // subscription_plan: res.data.data.subscription_plan,
        });
        callback();
      } else if (res.data.code === 203) {
        if (res.data.status === "Token Expired") {
          // state.props.history.push("/");
          let myItem = localStorage.getItem("buildversion");
          let myItemversion = localStorage.getItem("Apiversion");
          let previous_location = localStorage.getItem("Previouslocation");
          localStorage.clear();
          localStorage.setItem("Previouslocation", previous_location);
          localStorage.setItem("buildversion", myItem);
          localStorage.setItem("Apiversion", myItemversion);
        }
      }
    })
    .catch((error) =>{});
};

//Lifetime pricing
export const PricingLifetimepricing = (state, callback, appId) => {
  if (appId !== "" && appId !== undefined) {
    appId = "/" + appId;
  } else {
    appId = "";
  }
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(Url + `reseller/packages${appId}`, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          // lifetimedata: res.data.data,
          // spinner: false,
          dealsData: res.data.data === null ? [] : res.data.data,
          // customer_type: res.data.customer_type,
          // customer_billing_type: res.data.customer_billing_type,
          // subscription_plan: res.data.data.subscription_plan,
        });
        callback();
      } else if (res.data.code === 203) {
        if (res.data.status === "Token Expired") {
          // state.props.history.push("/");
          let myItem = localStorage.getItem("buildversion");
          let myItemversion = localStorage.getItem("Apiversion");
          let previous_location = localStorage.getItem("Previouslocation");
          localStorage.clear();
          localStorage.setItem("Previouslocation", previous_location);
          localStorage.setItem("buildversion", myItem);
          localStorage.setItem("Apiversion", myItemversion);
        }
      }
    })
    .catch((error) => {});
};

export const MobileLifetimepricing = (state, callback, appId) => {
  if (appId !== "" && appId !== undefined) {
    appId = "/" + appId;
  } else {
    appId = "";
  }
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(Url + `reseller/packages${appId}`, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          lifetimedata: res.data.data,
          spinner1: false,
          dealsData1: res.data.data === null ? [] : res.data.data,
          customer_type: res.data.customer_type,
          customer_billing_type: res.data.customer_billing_type,
          // subscription_plan: res.data.data.subscription_plan,
        });
        callback();
      } else if (res.data.code === 203) {
        if (res.data.status === "Token Expired") {
          // state.props.history.push("/");
          let myItem = localStorage.getItem("buildversion");
          let myItemversion = localStorage.getItem("Apiversion");
          let previous_location = localStorage.getItem("Previouslocation");
          localStorage.clear();
          localStorage.setItem("Previouslocation", previous_location);
          localStorage.setItem("buildversion", myItem);
          localStorage.setItem("Apiversion", myItemversion);
        }
      }
    })
    .catch((error) =>{});
};

//BillingHistory API
export const BillinghistoryApi = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(
      Url +
        "invoice?limit=" +
        params.limit +
        "&page=" +
        params.page +
        "&workspace_id=" +
        params.workspace_id +
        "&agency_id=" +
        params.agency_id,
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        if (params.page > 1) {
          let billinghistory = params.billinghistory;
          Array.prototype.push.apply(billinghistory, res.data.data);

          state.setState({
            billinghistory: billinghistory,
            // spinner: false,
            mainSpinner: false,
            bottomspinner: false,
            handlepage: res.data.data,
            datanumber: res.data.data.length,
            customer_type: res.data.customer_type,
            customer_billing_type: res.data.customer_billing_type,
          });
        } else {
          state.setState({
            // spinner: false,
            mainSpinner: false,
            billinghistory: res.data.data,
            datanumber: res.data.data.length,
            customer_type: res.data.customer_type,
            customer_billing_type: res.data.customer_billing_type,
          });
        }
      } else if (res.data.code === 203) {
        if (res.data.status === "Token Expired") {
          // state.props.history.push("/");
          let myItem = localStorage.getItem("buildversion");
          let myItemversion = localStorage.getItem("Apiversion");
          let previous_location = localStorage.getItem("Previouslocation");
          localStorage.clear();
          localStorage.setItem("Previouslocation", previous_location);
          localStorage.setItem("buildversion", myItem);
          localStorage.setItem("Apiversion", myItemversion);
        }
      }
    })
    .catch((error) =>{});
};

export const EmailsupportApi = (params, state) => {
  Loader(state);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "email-support", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.props.history.push("/request-submitted");
      } else if (res.data.code === 205) {
        errortoast(res.data.status);
      } else if (res.data.code === 401) {
        errortoast(res.data.data);
      } else {
        state.setState({
          submitval: "Send",
          loader: "d-none",
        });
        errortoast("Oh no! Something’s not right.");
      }
    })
    .catch((error) => {
      state.setState({
        submitval: "Send",
        loader: "d-none",
      });
      errortoast("Oh no! Something’s not right.");
      
    });
};
//Cancel Build
export const cancelBuild = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Cancelurl + "cancel-build", params, {
      headers: headers,
    })
    .then((res) => {
      state.setState({
        loader_remove: "d-none",
        submitval_confirmpop: "d-block",
        cancelmodal: false,
      });
      if (res.data.code === 200) {
        const builparams = {
          app_id: localStorage.getItem("Appid"),
        };
        NewDownloadBuildApi(builparams, state);
        state.hitheader();
      } else {
        state.setState({
          loader_remove: "d-none",
          submitval_confirmpop: "d-block",
          cancelmodal: false,
        });
        errortoast(res.data.data);
      }
    })
    .catch((error) => {});
};
//Pagelist API
export const GetmenuitemsAPI = (params, state, callback) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(Url + "menus/" + params.app_id, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        
        state.setState({
          menulist:
            res.data.data.menus === undefined ? [] : res.data.data.menus,
          menuData: res.data.data.selected_custom_pro_menu,
          menuAllData: res.data.data.selected_custom_pro_menu,
          selected_default_menu: res.data.data.selected_default_menu,
          menu_type: res.data.data.menu_type,
          defaultfinalvalue: res.data.data.selected_default_menu,
          // menuType: res.data.data.menu_type === "custom_pro" ? true : false,
          selected_custom_menu: res.data.data.selected_custom_menu,
          spinner: false,
          is_verified: res.data.app_data.is_verified,
          plugin_dataactive: res.data.app_data.is_connected,
          website_type: res.data.app_data.website_type.toString(),
          website_menu_type:
            res.data.data.menu_type === "default" ? true : false,
          own_menu_type: res.data.data.menu_type === "custom" ? true : false,
          storeUrl: res.data.app_data.website_url,

          enable_business_email_bool:
            res.data.general_data.enable_business_email_bool !== undefined
              ? res.data.general_data.enable_business_email_bool
              : 1,
          enable_business_phone_bool:
            res.data.general_data.enable_business_phone_bool !== undefined
              ? res.data.general_data.enable_business_phone_bool
              : 1,
          enable_share_app_bool:
            res.data.general_data.enable_share_app_bool !== undefined
              ? res.data.general_data.enable_share_app_bool
              : 1,
          enable_customer_information_bool:
            res.data.general_data.enable_customer_information_bool !== undefined
              ? res.data.general_data.enable_customer_information_bool
              : 1,
          business_email_title:
            res.data.general_data.business_email_title !== "" &&
            res.data.general_data.business_email_title !== undefined
              ? res.data.general_data.business_email_title
              : "Email",
          business_phone_title:
            res.data.general_data.business_phone_title !== "" &&
            res.data.general_data.business_phone_title !== undefined
              ? res.data.general_data.business_phone_title
              : "Call",
          share_app_title:
            res.data.general_data.share_app_title !== "" &&
            res.data.general_data.share_app_title !== undefined
              ? res.data.general_data.share_app_title
              : "Share",
          business_email_value:
            // res.data.is_default_general_data == 0
            // ?
            // :
            res.data.general_data.business_email_value !== undefined
              ? res.data.general_data.business_email_value
              : res.data.app_data.business_email,

          business_phone_value:
            // res.data.is_default_general_data == 0
            // ?

            res.data.general_data.business_phone_value !== undefined
              ? res.data.general_data.business_phone_value
              : res.data.app_data.business_phone,
          share_app_value:
            // res.data.is_default_general_data == 0
            // ?
            res.data.general_data.share_app_value
              ? res.data.general_data.share_app_value
              : "",

          share_app_icon: res.data.general_data.share_app_icon,
          business_email_icon: res.data.general_data.business_email_icon,
          business_phone_icon: res.data.general_data.business_phone_icon,
        });
        
        callback();
      } else if (res.data.code === 201) {
        // if (res.data.data.menu_type === "custom") {
        //   state.setState({
        //     menu_type: res.data.data.menu_type,
        //     defaultmenutype: res.data.data.menu_type,
        //     website_menu_type:
        //       res.data.data.menu_type === "default" ? true : false,
        //     own_menu_type: res.data.data.menu_type === "custom" ? true : false,
        //     selected_custom_menu: res.data.data.selected_custom_menu,
        //     storeUrl: res.data.app_data.website_url,
        //   });
        // } else {
        //   //errortoast("Something went wrong. Try again after sometime.")
        // }
        state.setState({
          spinner: false,
          is_verified: res.data.app_data.is_verified,
          plugin_dataactive: res.data.app_data.plugin_data,
          website_type: res.data.app_data.website_type.toString(),
        });
        callback();
      }
    })
    .catch((error) => {
      
      state.setState({
        spinner: false,
      });
    });
};

export const GetnewmenuitemsAPI = (params, state, callback) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(Url + "menus/" + params.app_id, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          OpenItemIndex: null,
          OpenSecMenuItemIndex: null,
          is_chat_enabled: res.data.is_chat_enabled,
          // isBottombarEnable:res.data.enable_bottom_menu,
          // isAnyDefaultIteminBottombar:res.data.is_any_default_item_in_bottom_menu,
        });

        if (
          (res.data.data.primary_menu && res.data.data.primary_menu != null) ||
          (res.data.data.secondary_menu && res.data.data.secondary_menu != null)
        ) {
          state.setState({
            menu_type: res.data.data.menu_type === "default" ? true : false,
            primarymenu_type: res.data.data.primary_menu_status,
            enable_customer_information_bool:
              res.data.data.secondary_menu_status,
            default_menus:
              res.data.data.menu_type === "default"
                ? res.data.data.selected_default_menu
                : [],
            selected_default_menu:
              res.data.data.menu_type === "default"
                ? res.data.data.selected_default_menu
                : [],
            defaultfinalvalue: res.data.data.selected_default_menu,
            childrendata: res.data.data.primary_menu
              ? res.data.data.primary_menu
              : [],
            secmenuData:
              res.data.data.secondary_menu !== null &&
              res.data.data.secondary_menu !== undefined
                ? res.data.data.secondary_menu
                : [],
            secondary_menu_type:
              res.data.data.secondary_menu_type !== null &&
              res.data.data.secondary_menu_type !== undefined &&
              res.data.data.secondary_menu_type == "default"
                ? true
                : false,
            secdefaultfinalvalue:
              res.data.data.default_secondary_menu !== null &&
              res.data.data.default_secondary_menu !== undefined
                ? res.data.data.default_secondary_menu.filter((item) => item)
                : "",
            spinner: false,
            toggle: res.data.data.enable_menu,
            is_verified: res.data.app_data.is_verified,
            plugin_dataactive: res.data.app_data.is_connected,
            website_type: res.data.app_data.website_type.toString(),
            website_menu_type:
              res.data.data.menu_type === "default" ? true : false,
            own_menu_type: res.data.data.menu_type === "custom" ? true : false,
            storeUrl: res.data.app_data.website_url,
            updated_at: res.data.data.updated_at,
            menuname: res.data.data.settings.menu_text
              ? res.data.data.settings.menu_text
              : "",
            menuicon: res.data.data.settings.menu_logo
              ? res.data.data.settings.menu_logo
              : "",
            checkedvalue: res.data.data.settings.menu_bar_type
              ? res.data.data.settings.menu_bar_type
              : "",
            textcolor:
              res.data.data.settings.menu_text_colour_object !== undefined
                ? res.data.data.settings.menu_text_colour_object.portal_data
                : "rgba(255,255,255,1)",
            borderColor:
              res.data.data.settings.menu_border_colour_object !== undefined
                ? res.data.data.settings.menu_border_colour_object.portal_data
                : "rgba(60,153,254,1)",
            backgroundColor:
              res.data.data.settings.menu_bg_colour_object !== undefined
                ? res.data.data.settings.menu_bg_colour_object.portal_data
                : "linear-gradient(129deg, rgba(50, 167, 255,1) 0%, rgba(90, 111, 251,1) 50%, rgba(119, 87, 255,1) 100%)",
            prebackgroundColor:
              res.data.data.settings.primary_menu_grid_bg_colour_object !==
              undefined
                ? res.data.data.settings.primary_menu_grid_bg_colour_object
                    .portal_data
                : "linear-gradient(129deg, rgba(50, 167, 255,1) 0%, rgba(90, 111, 251,1) 50%, rgba(119, 87, 255,1) 100%)",
            preborderColor:
              res.data.data.settings.primary_menu_grid_border_colour_object !==
              undefined
                ? res.data.data.settings.primary_menu_grid_border_colour_object
                    .portal_data
                : "rgba(60,153,254,1)",
            pregridcolumn:
              res.data.data.settings.primary_menu_grid_column !== undefined
                ? res.data.data.settings.primary_menu_grid_column
                : 2,
            preiconshape:
              res.data.data.settings.primary_menu_icon_shape !== undefined
                ? res.data.data.settings.primary_menu_icon_shape
                : "circle",
            pregridlayout:
              res.data.data.settings.primary_menu_show_grid_layout !== undefined
                ? res.data.data.settings.primary_menu_show_grid_layout
                : false,
            preiconview:
              res.data.data.settings.primary_menu_show_icons !== undefined
                ? res.data.data.settings.primary_menu_show_icons
                : true,
            preicontext:
              res.data.data.settings.primary_menu_show_text !== undefined
                ? res.data.data.settings.primary_menu_show_text
                : true,
            preiconcolor:
              res.data.data.settings.primary_menu_icon_color_object !==
              undefined
                ? res.data.data.settings.primary_menu_icon_color_object
                    .portal_data
                : "rgba(255,255,255,1)",
            pretextcolor:
              res.data.data.settings.primary_menu_text_color_object !==
              undefined
                ? res.data.data.settings.primary_menu_text_color_object
                    .portal_data
                : "rgba(255,255,255,1)",
            secbackgroundColor:
              res.data.data.settings.secondary_menu_grid_bg_color_object !==
              undefined
                ? res.data.data.settings.secondary_menu_grid_bg_color_object
                    .portal_data
                : "linear-gradient(129deg, rgba(50, 167, 255,1) 0%, rgba(90, 111, 251,1) 50%, rgba(119, 87, 255,1) 100%)",
            secborderColor:
              res.data.data.settings.secondary_menu_grid_border_color_object !==
              undefined
                ? res.data.data.settings.secondary_menu_grid_border_color_object
                    .portal_data
                : "rgba(60,153,254,1)",
            seciconcolor:
              res.data.data.settings.secondary_menu_icon_color_object !==
              undefined
                ? res.data.data.settings.secondary_menu_icon_color_object
                    .portal_data
                : "rgba(255,255,255,1)",
            sectextcolor:
              res.data.data.settings.secondary_menu_text_color_object !==
              undefined
                ? res.data.data.settings.secondary_menu_text_color_object
                    .portal_data
                : "rgba(255,255,255,1)",
            secgridcolumn:
              res.data.data.settings.secondary_menu_grid_column !== undefined
                ? res.data.data.settings.secondary_menu_grid_column
                : 2,
            seciconshape:
              res.data.data.settings.secondary_menu_icon_shape !== undefined
                ? res.data.data.settings.secondary_menu_icon_shape
                : "circle",
            secgridlayout:
              res.data.data.settings.secondary_menu_show_grid_layout !==
              undefined
                ? res.data.data.settings.secondary_menu_show_grid_layout
                : true,
            seciconview:
              res.data.data.settings.secondary_menu_show_icons !== undefined
                ? res.data.data.settings.secondary_menu_show_icons
                : true,
            secicontext:
              res.data.data.settings.secondary_menu_show_text !== undefined
                ? res.data.data.settings.secondary_menu_show_text
                : true,

            newdata: 1,
          });
        } else {
          state.setState({
            menulist:
              res.data.data.menus === undefined ? [] : res.data.data.menus,
            menuData: res.data.data.selected_custom_pro_menu,
            menuAllData: res.data.data.selected_custom_pro_menu,
            selected_default_menu: res.data.data.selected_default_menu,
            menu_type: res.data.data.menu_type,
            defaultfinalvalue: res.data.data.selected_default_menu,
            primarymenu_type:
              res.data.data.menu_type === "custom_pro" ||
              res.data.data.menu_type === "default"
                ? true
                : false,
            menu_type: res.data.data.menu_type === "default" ? true : false,
            toggle: res.data.data.menu_type === "" ? false : true,
            selected_custom_menu: res.data.data.selected_custom_menu,
            spinner: false,
            is_verified: res.data.app_data.is_verified,
            plugin_dataactive: res.data.app_data.is_connected,
            website_type: res.data.app_data.website_type.toString(),
            website_menu_type:
              res.data.data.menu_type === "default" ? true : false,
            own_menu_type: res.data.data.menu_type === "custom" ? true : false,
            storeUrl: res.data.app_data.website_url,
            newdata: 2,
            updated_at: res.data.data.updated_at,
            enable_business_email_bool:
              res.data.general_data &&
              res.data.general_data.enable_business_email_bool !== undefined
                ? res.data.general_data &&
                  res.data.general_data.business_email_title != "" &&
                  res.data.general_data.enable_business_email_bool != 0
                  ? state.mergesecmenu(
                      res.data.general_data.enable_business_email_bool,
                      res.data.general_data.business_email_title,
                      res.data.general_data.business_email_value !== undefined
                        ? res.data.general_data.business_email_value
                        : res.data.app_data.business_email,
                      res.data.general_data.business_email_icon,
                      0,
                      "email"
                    )
                  : ""
                : "",
            enable_business_phone_bool:
              res.data.general_data &&
              res.data.general_data.enable_business_phone_bool !== undefined
                ? res.data.general_data &&
                  res.data.general_data.business_phone_title != "" &&
                  res.data.general_data.enable_business_phone_bool != 0
                  ? state.mergesecmenu(
                      res.data.general_data.enable_business_phone_bool,
                      res.data.general_data.business_phone_title,
                      res.data.general_data.business_phone_value !== undefined
                        ? res.data.general_data.business_phone_value
                        : res.data.app_data.business_phone,
                      res.data.general_data.business_phone_icon,
                      1,
                      "phone"
                    )
                  : ""
                : "",
            enable_share_app_bool:
              res.data.general_data &&
              res.data.general_data.enable_share_app_bool !== undefined
                ? res.data.general_data &&
                  res.data.general_data.share_app_title != "" &&
                  res.data.general_data.enable_share_app_bool != 0
                  ? state.mergesecmenu(
                      res.data.general_data.enable_share_app_bool,
                      res.data.general_data.share_app_title,
                      res.data.general_data.share_app_value,
                      res.data.general_data.share_app_icon,
                      2,
                      "share"
                    )
                  : ""
                : 1,
            enable_customer_information_bool:
              res.data.general_data &&
              res.data.general_data.enable_customer_information_bool !==
                undefined
                ? res.data.general_data.enable_customer_information_bool
                : 1,
          });
        }
        callback();
      } else if (res.data.code === 201) {
        state.setState({
          spinner: false,
          is_verified: res.data.app_data.is_verified,
          plugin_dataactive: res.data.app_data.plugin_data,
          website_type: res.data.app_data.website_type.toString(),
        });
        callback();
      }
    })
    .catch((error) => {
      
      state.setState({
        spinner: false,
        OpenItemIndex: null,
        OpenSecMenuItemIndex: null,
      });
    });
};
export const Unsuspend = (params, state) => {
  state.setState({
    submitval_verifypopup: "",
    loader_remove_verify: "loader",
  });
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(saveceritificate + "unsuspend", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState(
          {
            hitpopup: false,
            suspended_app_id: "",
            applyhit: false,
            submitval_verifypopup: "I’m sure",
            loader_remove_verify: "d-none",
            loaderverify_remove:
              require("../../assets/images/dashboard/loader.gif").default,
          },
          () => {
            const pagination = {
              page: state.state.pageval,
              limit: 29,
              search_field: "",
            };
            state.handlepagination(pagination);
          }
        );
        sucesstoast("Successfully reverified.");
      } else {
        state.setState({
          hitpopup: false,
          suspended_app_id: "",
          submitval_verifypopup: "Verify",
          loader_remove_verify: "d-none",
          loaderverify_remove:
            require("../../assets/images/dashboard/loader.gif").default,
        });
        errortoast("Something went wrong. Try again after sometime.");
      }
    })
    .catch((error) => {});
};
//sndpagemenu
export const Sndpagemenu = (params, state, callback) => {
  // Loader(state);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "saveMenus", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          submitval: "Save",
          loader: "d-none",
        });

        callback();
        if (localStorage.getItem("appsettingactive") !== "0") {
          state.Getdata();
        }
        sucesstoast("App Menu successfully updated.");
      } else {
        state.setState({
          submitval: "Save",

          loader: "d-none",
        });
        errortoast("Something went wrong. Try again after sometime.");
        callback();
      }
    })
    .catch((error) => {
      
      state.setState({
        submitval: "Save",

        loader: "d-none",
      });
      errortoast("Something went wrong. Try again after sometime.");
    });
};

//Savepagemenu
export const Savepagemenu = (params, state, callback) => {
  // Loader(state);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "save-menu-builder", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          submitval: "Save",
          loader: "d-none",
          updated_at: res.data.data.updated_at,
        });

        callback();
        if (localStorage.getItem("appsettingactive") !== "0") {
          state.Getdata();
        }
        sucesstoast("The record has been saved.");
      } else {
        state.setState({
          submitval: "Save",

          loader: "d-none",
        });
        errortoast("Something went wrong. Try again after sometime.");
        callback();
      }
    })
    .catch((error) => {
      
      state.setState({
        submitval: "Save",

        loader: "d-none",
      });
      errortoast("Something went wrong. Try again after sometime.");
    });
};

//ShowtecApi
export const ShowtecApi = (state, workspaceId) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(Url + "technology/" + workspaceId, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          spinner: false,
          technologydata: res.data.data,
          website_platform:
            atob(workspaceId) === null || atob(workspaceId) === "null"
              ? state.state.website_platform
              : res.data.workspace_technology === 1
              ? 2
              : res.data.workspace_technology === 0
              ? 2
              : res.data.workspace_technology === null
              ? 2
              : res.data.workspace_technology,
          website_type_select:
            atob(workspaceId) === null || atob(workspaceId) === "null"
              ? state.state.website_type_select
              : res.data.workspace_website_type,
          workspaceWebTechnology: res.data.workspace_technology,
          workspace_plan: res.data.workspace_plan_code,
        });
      } else if (res.data.code === 401) {
        if (res.data.status === "Token Expired") {
          // state.props.history.push("/");
          let myItem = localStorage.getItem("buildversion");
          let myItemversion = localStorage.getItem("Apiversion");
          let previous_location = localStorage.getItem("Previouslocation");
          localStorage.clear();
          localStorage.setItem("Previouslocation", previous_location);
          localStorage.setItem("buildversion", myItem);
          localStorage.setItem("Apiversion", myItemversion);
        }
      } else if (res.data.code === 201) {
        errortoast("Something went wrong. Try again after sometime.");
        state.setState({
          spinner: false,
        });
      }
    })
    .catch((err) => {
      
    });
};
export const Invoicelink = async (params, state) => {
  state.setState({
    spinner: true,
  });
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  const apiurl = axios.post(
    saveceritificate + "update-expired-invoice-link",
    params,
    { headers: headers }
  );
  const response = await apiurl;

  if (response.data.code === 200) {
    window.open(response.data.invoice_url, "_blank");
    window.location.reload();
    state.setState({
      spinner: false,
    });
  } else {
    state.setState({
      spinner: false,
    });
  }
};
//post setting
export const Getpostapi = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(saveceritificate + "posts-toggle/" + params.app_id, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        if (res.data.data === null) {
          state.setState({
            bodyspinner: false,
            show_related_posts: true,
            enable_post_search: true,
            enable_web_view_interface_bool: true,
            posts_view_style: true,
            show_additional_information: true,
          });
        } else {
          state.setState({
            bodyspinner: false,
            show_related_posts:
              res.data.data.show_related_posts === 1 ? true : false,
            enable_post_search:
              res.data.data.enable_post_search === 1 ? true : false,
            show_additional_information:
              res.data.data.show_additional_information_bool === 1
                ? true
                : false,
            enable_web_view_interface_bool:
              res.data.data.enable_web_view_interface_bool === 1 ? true : false,
            posts_view_style:
              res.data.data.posts_view_style === "grid" ? true : false,
          });
        }
      } else {
        errortoast("Something went wrong. Try again after sometime.");
      }
    })
    .catch((error) => {});
};
export const Postapi = (params, state) => {
  Loader(state);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(saveceritificate + "save-posts-toggle", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          submitval: "Save",
          loader: "d-none",
        });
        sucesstoast("Submitted Successfully");
      } else {
        errortoast("Something went wrong. Try again after sometime.");
      }
    })
    .catch((error) => {
      errortoast("Something went wrong. Try again after sometime.");
      state.setState({
        submitval: "Save",
        loader: "d-none",
      });
    });
};
//ShowtypeApi
export const ShowtypeApi = (state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(
      Url + "websitetype?agency_id=" + btoa(localStorage.getItem("agencyid")),
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          spinner: false,
          typeselection: res.data.data,
          licence_type: res.data.user_licences,
          customer_type: res.data.customer_type,
          bodyspinner: false,
          app_count: res.data.app_count,
          // user_country: res.data.user_country === "IN" ? "IN" : "US",
          //Setting US as default to make it consistent with merge apps
          user_country: "US",
          customer_billing_type: res.data.customer_billing_type,
          emailverified: res.data.email_marketing.email_verified,
        });
        if (window.location.pathname !== "/app/settings") {
          // sendinblue(res.data.email_marketing);
          // sendGrid(res.data.email_marketing);

          // Check if Hotjar has been initialized before calling its methods
          const params = {
            contact: {
              FIRSTNAME: res.data.email_marketing.name,
              AMS_CUSTOMER_ID: res.data.email_marketing.ams_customer_id,
              PAYMENT_CUSTOMER_ID: res.data.email_marketing.zoho_customer_id,
              EMAIL_VERIFIED: res.data.email_marketing.email_verified,
              CUSTOMER_TYPE: res.data.email_marketing.customer_type.toString(),
              APP_PLAN: res.data.email_marketing.appsumo_plan,
              FREE_OR_PAID: res.data.email_marketing.free_or_paid,
              OWNER_TYPE: res.data.email_marketing.owner_type,
              LOGIN_COUNTER: res.data.email_marketing.login_counter,
              COUNTRY_CODE: res.data.email_marketing.country_code,
              TOTAL_APPS: res.data.email_marketing.total_apps,
            },
            cuid: res.data.email_marketing.ams_customer_id.toString(),
            email_id: res.data.email_marketing.email,
            sib_type: "identify",
          };

          
          if (hotjar.initialized()) {
            hotjar.identify("USER_ID", params);
          }
        }
      } else if (res.data.code === 401) {
        if (res.data.status === "Token Expired") {
          // state.props.history.push("/");
          let myItem = localStorage.getItem("buildversion");
          let myItemversion = localStorage.getItem("Apiversion");
          let previous_location = localStorage.getItem("Previouslocation");
          localStorage.clear();
          localStorage.setItem("Previouslocation", previous_location);
          localStorage.setItem("buildversion", myItem);
          localStorage.setItem("Apiversion", myItemversion);
        }
      } else if (res.data.code === 201) {
        errortoast("Something went wrong. Try again after sometime.");
        state.setState({
          spinner: false,
        });
      }
    })
    .catch((error) =>{});
};

export const parseCoordinates = (coordinates) => {
  try {
    return JSON.parse(coordinates) || { latitude: 0, longitude: 0 };
  } catch {
    return { latitude: 0, longitude: 0 };
  }
};

//Appgeneral info API
export const Appgeneralinfo = (params, state, callback) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(
      Url +
        "guest-login/" +
        params.app_id +
        "?workspace_id=" +
        params.workspace_id,
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        // 
        const { latitude = 0, longitude = 0 } = parseCoordinates(
          res.data.data.general_data?.map_coordinates
        );
        state.setState({
          is_social_login_enabled_android:
            res.data.data.is_social_login_enabled_android,
          is_social_login_enabled_ios:
            res.data.data.is_social_login_enabled_ios,
          plan_code: res.data.data.app_plan,
          lastdate: res.data.data.updated_at,
          app_country:
            res.data.data.general_data &&
            res.data.data.general_data !== null &&
            res.data.data.general_data.app_country !== undefined &&
            res.data.data.general_data.app_country !== null
              ? res.data.data.general_data.app_country
              : "",
          force_app_to_update_latest_version:
            res.data.data.general_data &&
            res.data.data.general_data !== null &&
            res.data.data.general_data.force_app_to_update_latest_version !==
              undefined &&
            res.data.data.general_data.force_app_to_update_latest_version == 1
              ? 1
              : 0,
          defaultlanguagecode:
            res.data.data.general_data &&
            res.data.data.general_data !== null &&
            res.data.data.general_data.default_language !== undefined &&
            res.data.data.general_data.default_language != ""
              ? res.data.data.general_data.default_language
              : "en",
          guestlogin:
            res.data.data.general_settings !== null &&
            res.data.data.general_settings.guest_browsing_allowed_bool !==
              undefined &&
            res.data.data.general_settings.guest_browsing_allowed_bool === 1
              ? true
              : false,
          registerallow:
            res.data.data.general_settings !== null &&
            res.data.data.general_settings.user_registration_allowed_bool !==
              undefined &&
            res.data.data.general_settings.user_registration_allowed_bool === 1
              ? true
              : false,
          appname: res.data.data.appname,
          oldappname: res.data.data.appname,
          appplatform: res.data.data.website_type.toString(),
          authorization_type: res.data.data.authorization_type,
          is_connected: res.data.data.is_connected,
          platformtype: res.data.data.website_type.toString(),
          facebooklogin:
            res.data.data.general_settings !== null &&
            res.data.data.general_settings.social_login_fb_allowed_bool !==
              undefined &&
            res.data.data.general_settings.social_login_fb_allowed_bool === 1
              ? true
              : false,
          google_login:
            res.data.data.general_settings !== null &&
            res.data.data.general_settings.social_login_google_allowed_bool !==
              undefined &&
            res.data.data.general_settings.social_login_google_allowed_bool ===
              1
              ? true
              : false,
          apple_login:
            res.data.data.general_settings !== null &&
            res.data.data.general_settings.social_login_apple_allowed_bool !==
              undefined &&
            res.data.data.general_settings.social_login_apple_allowed_bool === 1
              ? true
              : false,
          //facebooklogin:false,
          //google_login:false,
          termandcondition:
            res.data.data.general_settings === null ||
            res.data.data.general_settings.website_terms_conditions_page_url ===
              undefined
              ? ""
              : res.data.data.general_settings
                  .website_terms_conditions_page_url,
          readstatus: res.data.data.build_status,
          websiteUrl:
            res.data.data.store_url !== null
              ? res.data.data.store_url.replace(/^https?:\/\//i, "")
              : null,
          comparewebsiteUrl:
            res.data.data.store_url !== null
              ? res.data.data.store_url.replace(/^https?:\/\//i, "")
              : null,
          is_verified: res.data.data.is_verified,
          build_status: res.data.data.build_status,
          skip_login:
            res.data.data.general_settings !== null &&
            res.data.data.general_settings.is_login_first_screen !==
              undefined &&
            res.data.data.general_settings.is_login_first_screen === 1
              ? true
              : false,
          loginbutton:
            res.data.data.general_settings !== null &&
            res.data.data.general_settings.disable_login_signup_module === 0 &&
            res.data.data.general_settings.disable_login_signup_module !==
              undefined
              ? true
              : false,
          bodyspinner: false,
          business_email: res.data.data.business_email,
          business_phone: res.data.data.business_phone,
          share_app:
            res.data.data.general_settings === null ||
            res.data.data.general_settings.app_share_allowed_bool ===
              undefined ||
            res.data.data.general_settings.app_share_allowed_bool === 0
              ? false
              : true,
          share_app_url:
            res.data.data.general_settings === null ||
            res.data.data.general_settings.app_share_url === undefined
              ? ""
              : res.data.data.general_settings.app_share_url,
          consent_form:
            res.data.data.consent_form === undefined ||
            res.data.data.consent_form.form_bool === 0
              ? false
              : true,
          consent_title:
            res.data.data.consent_form === undefined ||
            res.data.data.consent_form.form_title === ""
              ? ""
              : res.data.data.consent_form.form_title,
          consent_description:
            res.data.data.consent_form === undefined ||
            res.data.data.consent_form.form_description === ""
              ? ""
              : res.data.data.consent_form.form_description,
          consent_form_purchased:
            res.data.data.consent_form_purchased === undefined ||
            res.data.data.consent_form_purchased === 0
              ? 0
              : 1,
          appuser_role_id: res.data.data.appuser_role_id,
          allowhitforappmysiteapp:
            res.data.data.is_verified === 1 &&
            res.data.data.store_url === "https://shop.appmysite.com"
              ? 1
              : 0,
          store_api_version:
            res.data.data.general_settings === null ||
            res.data.data.general_settings.store_api_version === undefined
              ? 0
              : res.data.data.general_settings.store_api_version,
          workspaceWebTechnology: res.data.workspace_technology,
          appTechnology: res.data.data.app_technology,
          allow_changing_technology: res.data.allow_changing_technology,
          appspinner: false,
          lat: latitude,
          lng: longitude,
          zoom: res.data.data?.general_data?.map_coordinates ? 13 : 10,
          mapSelectedLocation: {
            lat: latitude,
            lng: longitude,
          },
          enableLanguage:
            res.data.data?.general_data?.enable_language_selection || 0,
          enableOfflineMode:
            res.data.data?.general_data?.enable_offline_mode || 0,
          enable_prompt_on_exit: res.data.data?.general_data
            ?.enable_prompt_on_exit
            ? true
            : false,
          // isOwner: res.data.data.is_owner,
        });

        callback();
      } else if (res.data.code === 401) {
        if (res.data.status === "Token Expired") {
          // state.props.history.push("/");
          let myItem = localStorage.getItem("buildversion");
          let myItemversion = localStorage.getItem("Apiversion");
          let previous_location = localStorage.getItem("Previouslocation");
          localStorage.clear();
          localStorage.setItem("Previouslocation", previous_location);
          localStorage.setItem("buildversion", myItem);
          localStorage.setItem("Apiversion", myItemversion);
        }
      } else if (res.data.code === 201) {
        errortoast("Something went wrong. Try again after sometime.");
      }
    })
    .catch((error) => {});
};
//Checkout tab
export const GetCheckoutapi = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(Url + "checkout-toggle/" + params.app_id, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        if (res.data.data === null) {
          state.setState({
            bodyspinner: false,
            show_payment_methods: true,
            show_update_profile_toogle: true,
          });
        } else {
          state.setState({
            bodyspinner: false,
            show_payment_methods:
              res.data.data.show_payment_methods_screen_bool === 1
                ? true
                : false,
            show_update_profile_toogle:
              res.data.data.show_update_profile_toogle_on_checkout ===
                undefined ||
              res.data.data.show_update_profile_toogle_on_checkout === 1
                ? true
                : false,
          });
        }
      } else {
        errortoast("Something went wrong. Try again after sometime.");
      }
    })
    .catch((error) =>{});
};
export const Checkoutapi = (params, state) => {
  Loader(state);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "save-checkout-toggle", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          submitval: "Save",
          loader: "d-none",
        });
        sucesstoast("Submitted Successfully");
      } else {
        errortoast("Something went wrong. Try again after sometime.");
      }
    })
    .catch((error) => {
      errortoast("Something went wrong. Try again after sometime.");
      state.setState({
        submitval: "Save",
        loader: "d-none",
      });
    });
};
//Pagelist API
export const Pagenameapi = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(
      Url +
        "pages/" +
        params.app_id +
        "?api_check=menu&page=" +
        params.page +
        "&limit=" +
        params.limit,
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        if (params.page > 1) {
          let pageData = params.pageData;
          Array.prototype.push.apply(pageData, res.data.data.pages);

          state.setState({
            pageData: pageData,
            datanumber: res.data.data.pages.length,
            saved_pages: res.data.data.data,
            spinner: false,
            // selectall:res.data.data.select_all === 0 ? false : true,
            // nottoshow:res.data.data.select_all === 0 ? false : true,
            errorstatus: false,
            errordata: "",
            bottomspinner: false,
          });
        } else {
          state.setState({
            pageData: res.data.data.pages,
            datanumber: res.data.data.pages.length,
            spinner: false,
            saved_pages: res.data.data.data,
            selectall: res.data.data.select_all === 0 ? false : true,
            nottoshow: res.data.data.select_all === 0 ? false : true,
            errorstatus: false,
            errordata: "",
            bottomspinner: false,
          });
        }
      } else if (res.data.code === 201) {
        if (params.page > 1) {
          state.setState({
            bottomspinner: false,
            datanumber: 9,
          });
        } else {
          state.setState({
            spinner: false,
            errorstatus: true,
            errordata:
              "We were not able to connect to your WordPress page API.",
            bottomspinner: false,
          });
        }
      } else if (res.data.code === 500) {
        state.setState({
          spinner: false,
          errorstatus: true,
          errordata:
            "Your website did not respond as expected as a valid JSON response was not received. Please ensure REST APIs are enabled for your WordPress website.",
        });
      }
    })
    .catch((error) => {
      errortoast("Something went wrong. Try again after sometime.");
      state.setState({
        spinner: false,
        errorstatus: false,
      });
    });
};
//Users
export const Userapi = (params, state) => {
  Loader(state);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "save-user-toggle", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          submitval: "Save",
          loader: "d-none",
        });
        sucesstoast("Submitted Successfully");
      } else {
        errortoast("Something went wrong. Try again after sometime.");
      }
    })
    .catch((error) => {});
};
export const GetPagesapi = (
  params,
  state,
  updatePageOption,
  setPageHeaderBackgroundColor,
  setPageTextColor,
  setTileBackgroundColor,
  setShowShare,
  setShowSearch,
  setImageShape,
  setSectionMargins,
  setImgCenterForListing,
  setPageImgRatio,
  setShowMediaFullScreen,
  setShowMediaThumbnails,
  setEnableWebView,
  setUpdatedAt,
  setPageLayout,
  from
) => {
  const removePx = (value) => parseFloat(value.replace("px", "")) || 0;
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(Url + "pages-toggle/" + params.app_id, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        if (from == "WP/WOO") {
          state.setState({ lastdate: res.data.updated_at });
        }
        if (res.data.data === null && from == "WP/WOO") {
          state.setState({
            bodyspinner: false,
            enable_web_view: true,
            enable_hyperlinks: true,
            enable_form_inputs: true,
            enable_header_in_web_view: true,
            show_additional_information: true,
            enable_share_app_bool:false,
          });
        } else if (res.data.data === null && from == "Custom") {
          updatePageOption("background_color_on_pages_listing_page", "#f6f6f6");

          updatePageOption("image_ratio_on_pages_detail_page", "4:3");
          updatePageOption("image_shape_on_pages_detail_page", "sharp_corner");

          updatePageOption("section_margin_bottom_on_pages_detail_page", "7.5");
          updatePageOption("section_margin_left_on_pages_detail_page", "15");
          updatePageOption("section_margin_right_on_pages_detail_page", "15");
          updatePageOption("section_margin_top_on_pages_detail_page", "7.5");
          updatePageOption("show_center_crop_images_on_pages_detail_page", 0);
          updatePageOption("show_media_as_thumbnail_on_pages_detail_page", 0);
          updatePageOption("show_media_in_full_screen_on_pages_detail_page", 0);
          updatePageOption("show_search_icon_on_pages_listing_page", 0);
          updatePageOption("text_color_on_pages_listing_page", "#1a1a1a");
          updatePageOption(
            "tile_background_color_on_pages_listing_page",
            "#ffffff"
          );
          setPageHeaderBackgroundColor("#f6f6f6");
          setPageTextColor("#1a1a1a");
          setTileBackgroundColor("#ffffff");
          setShowShare(false);
          setShowSearch(false);
          setImageShape("sharp_corner");
          setSectionMargins({
            bottom: removePx("7.5"),
            top: removePx("7.5"),
            left: removePx("15"),
            right: removePx("15"),
          });
          setImgCenterForListing(false);
          setPageImgRatio("4:3");

          setShowMediaFullScreen(false);
          setShowMediaThumbnails(false);
          setEnableWebView(false);
        } else {
          // state.setState({
          //   bodyspinner: false})
          if (from == "WP/WOO") {
            state.setState({
              bodyspinner: false,

              enable_web_view:
                res.data.data.enable_web_view_interface_bool === 1
                  ? true
                  : false,
              show_additional_information:
                res.data.data.show_additional_information_bool === 1
                  ? true
                  : false,
              enable_hyperlinks:
                res.data.data.enable_hyperlinks_on_web_view_bool === 1
                  ? true
                  : false,
              enable_form_inputs:
                res.data.data.enable_form_inputs_on_web_view_bool === 1
                  ? true
                  : false,
              enable_header_in_web_view:
                res.data.data.enable_header_in_web_view === 1 ? true : false,
              pageLayout: res.data.data.pages_layout,
              pageOptions: {
                background_color_on_pages_listing_page:
                  res.data.data?.background_color_object_on_pages_listing_page
                    ?.portal_data || "#f6f6f6",
                enable_share_feature_on_pages_detail_page:
                  res.data.data?.enable_share_feature_on_pages_detail_page || 0,
                image_ratio_on_pages_detail_page:
                  res.data.data?.image_ratio_on_pages_detail_page || "4:3",
                image_shape_on_pages_detail_page:
                  res.data.data?.image_shape_on_pages_detail_page ||
                  "sharp_corner",
                section_margin_bottom_on_pages_detail_page:
                  res.data.data?.section_margin_bottom_on_pages_detail_page ||
                  "7.5",
                section_margin_left_on_pages_detail_page:
                  res.data.data?.section_margin_left_on_pages_detail_page ||
                  "15",
                section_margin_right_on_pages_detail_page:
                  res.data.data?.section_margin_right_on_pages_detail_page ||
                  "15",
                section_margin_top_on_pages_detail_page:
                  res.data.data?.section_margin_top_on_pages_detail_page ||
                  "7.5",
                show_center_crop_images_on_pages_detail_page:
                  res.data.data?.show_center_crop_images_on_pages_detail_page ||
                  0,
                show_media_as_thumbnail_on_pages_detail_page:
                  res.data.data?.show_media_as_thumbnail_on_pages_detail_page ||
                  0,
                show_media_in_full_screen_on_pages_detail_page:
                  res.data.data
                    ?.show_media_in_full_screen_on_pages_detail_page || 0,
                show_search_icon_on_pages_listing_page:
                  res.data.data?.show_search_icon_on_pages_listing_page || 0,
                text_color_on_pages_listing_page:
                  res.data.data?.text_color_object_on_pages_listing_page
                    ?.portal_data || "#1a1a1a",
                tile_background_color_on_pages_listing_page:
                  res.data.data
                    ?.tile_background_color_object_on_pages_listing_page
                    ?.portal_data || "#ffffff",
              },
            });
          } else if (from == "Custom") {
            updatePageOption(
              "background_color_on_pages_listing_page",
              res.data.data?.background_color_object_on_pages_listing_page
                ?.portal_data || "#f6f6f6"
            );
            // updatePageOption(
            //   "enable_share_feature_on_pages_detail_page",
            //   res.data.data?.enable_share_feature_on_pages_detail_page
            // );

            updatePageOption(
              "image_ratio_on_pages_detail_page",
              res.data.data?.image_ratio_on_pages_detail_page || "4:3"
            );
            updatePageOption(
              "image_shape_on_pages_detail_page",
              res.data.data?.image_shape_on_pages_detail_page || "sharp_corner"
            );

            updatePageOption(
              "section_margin_bottom_on_pages_detail_page",
              res.data.data?.section_margin_bottom_on_pages_detail_page || "7.5"
            );
            updatePageOption(
              "section_margin_left_on_pages_detail_page",
              res.data.data?.section_margin_left_on_pages_detail_page || "15"
            );
            updatePageOption(
              "section_margin_right_on_pages_detail_page",
              res.data.data?.section_margin_right_on_pages_detail_page || "15"
            );
            updatePageOption(
              "section_margin_top_on_pages_detail_page",
              res.data.data?.section_margin_top_on_pages_detail_page || "7.5"
            );
            updatePageOption(
              "show_center_crop_images_on_pages_detail_page",
              res.data.data?.show_center_crop_images_on_pages_detail_page || 0
            );
            updatePageOption(
              "show_media_as_thumbnail_on_pages_detail_page",
              res.data.data?.show_media_as_thumbnail_on_pages_detail_page || 0
            );
            updatePageOption(
              "show_media_in_full_screen_on_pages_detail_page",
              res.data.data?.show_media_in_full_screen_on_pages_detail_page || 0
            );
            updatePageOption(
              "show_search_icon_on_pages_listing_page",
              res.data.data?.show_search_icon_on_pages_listing_page || 0
            );
            updatePageOption(
              "text_color_on_pages_listing_page",
              res.data.data?.text_color_object_on_pages_listing_page
                ?.portal_data || "#1a1a1a"
            );
            updatePageOption(
              "tile_background_color_on_pages_listing_page",
              res.data.data?.tile_background_color_object_on_pages_listing_page
                ?.portal_data || "#ffffff"
            );
            setUpdatedAt(res?.data.updated_at);
          }

          setPageHeaderBackgroundColor(
            res.data.data?.background_color_object_on_pages_listing_page
              ?.portal_data || "#f6f6f6"
          );
          setPageTextColor(
            res.data.data?.text_color_object_on_pages_listing_page
              ?.portal_data || "#1a1a1a"
          );
          setTileBackgroundColor(
            res.data.data?.tile_background_color_object_on_pages_listing_page
              ?.portal_data || "#ffffff"
          );
          setShowShare(
            res.data.data?.enable_share_feature_on_pages_detail_page == 1
              ? true
              : false || false
          );
          setShowSearch(
            res.data.data?.show_search_icon_on_pages_listing_page == 1
              ? true
              : false || false
          );
          setImageShape(
            res.data.data?.image_shape_on_pages_detail_page || "sharp_corner"
          );
          setSectionMargins({
            bottom: removePx(
              res.data.data?.section_margin_bottom_on_pages_detail_page || "7.5"
            ),
            top: removePx(
              res.data.data?.section_margin_top_on_pages_detail_page || "7.5"
            ),
            left: removePx(
              res.data.data?.section_margin_left_on_pages_detail_page || "15"
            ),
            right: removePx(
              res.data.data?.section_margin_right_on_pages_detail_page || "15"
            ),
          });
          setImgCenterForListing(
            res.data.data?.show_center_crop_images_on_pages_detail_page == 1
              ? true
              : false || false
          );
          setPageImgRatio(
            res.data.data?.image_ratio_on_pages_detail_page || "4:3"
          );

          setShowMediaFullScreen(
            res.data.data?.show_media_in_full_screen_on_pages_detail_page == 1
              ? true
              : false || false
          );
          setShowMediaThumbnails(
            res.data.data?.show_media_as_thumbnail_on_pages_detail_page == 1
              ? true
              : false || false
          );
          setEnableWebView(
            res.data.data?.enable_web_view_interface_bool == 1
              ? true
              : false || false
          );
          setPageLayout(res.data.data.pages_layout || "layout-0");
        }
      } else {
        errortoast("Something went wrong. Try again after sometime.");
      }
    })
    .catch((error) => {});
};

export const GetPageSettings = async (params,setSectionTopMargin,setSectionBottomMargin,setSectionLeftMargin,setSectionRightMargin,setImgCenterForDetails,setImgShapeForDetails) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  try {
    const response = await axios.get(Url + "pages-toggle/" + params.app_id, {
      headers: headers,
    });

const settingsData = response?.data?.data
  setSectionTopMargin(settingsData?.section_margin_top_on_pages_detail_page?settingsData?.section_margin_top_on_pages_detail_page:"7.5");
  setSectionBottomMargin(settingsData?.section_margin_bottom_on_pages_detail_page?settingsData?.section_margin_bottom_on_pages_detail_page:"7.5");
  setSectionLeftMargin(settingsData?.section_margin_left_on_pages_detail_page?settingsData?.section_margin_left_on_pages_detail_page:"15");
  setSectionRightMargin(settingsData?.section_margin_right_on_pages_detail_page?settingsData?.section_margin_right_on_pages_detail_page:"15");
  setImgCenterForDetails(settingsData?.show_center_crop_images_on_pages_detail_page?true:false);
  setImgShapeForDetails(settingsData?.image_shape_on_pages_detail_page?settingsData?.image_shape_on_pages_detail_page:"sharp_corner")

  } catch (error) {
    console.error("Error fetching page settings:", error.response?.data || error.message);
    throw error;
  }
};

export const Pagesapi = (params, state, setSavePageSettingLoading) => {
  // Loader(state);
  if (localStorage.getItem("website_technology") == 3) {
    setSavePageSettingLoading(true);
  } else {
    state.setState({
      saveSettingsLoading: true,
    });
  }
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "save-pages-toggle", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        // state.setState({
        //   submitval: "Save",
        //   loader: "d-none",
       
        // });
        if (localStorage.getItem("website_technology") == 3) {
          setSavePageSettingLoading(false);
        }
        else {
          state.setState({
            lastdate: res.data.getdata.updated_at,
            saveSettingsLoading: false,
          })
        }
        sucesstoast("Submitted Successfully");
      } else {
        errortoast("Something went wrong. Try again after sometime.");
        if (localStorage.getItem("website_technology") == 3) {
          setSavePageSettingLoading(false);
        }
        else {
          state.setState({
            saveSettingsLoading: false,
          })
        }
      }
    })
    .catch((error) => {
      errortoast("Something went wrong. Try again after sometime.");
      if (localStorage.getItem("website_technology") == 3) {
        setSavePageSettingLoading(false);
      }
      else {
        state.setState({
          saveSettingsLoading: false,
        })
      }
      state.setState({
        submitval: "Save",
        loader: "d-none",
      });
    });
};

export const GetProductapi = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(Url + "product-toggle/" + params.app_id, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        if (res.data.data === null) {
          state.setState({
            bodyspinner: false,
            show_discount_percentage: false,
            show_related_products: true,
            show_additional_information: true,
            show_product_filter_and_sorting: true,
            show_out_of_stock: true,
            show_product_search: true,
            fit_image_horizontally: true,
            product_view_style: true,
            enable_web_view_interface: false,
            discount_badge_text_colour: "#FFFFFF",
            discount_badge_bg_colour: "#FF3D3D",
            enable_cart_badge: true,
            enable_discount_badge: true,
            cart_badge_counter_bg_colour: "#FF3D3D",
            cart_badge_counter_text_colour: "#FFFFFF",
          });
        } else {
          state.setState({
            bodyspinner: false,
            show_related_products:
              res.data.data.show_related_products_bool === 1 ? true : false,
            show_additional_information:
              res.data.data.show_additional_information_bool === 1
                ? true
                : false,
            show_product_filter_and_sorting:
              res.data.data.show_product_filter_and_sorting_bool === 1
                ? true
                : false,
            show_out_of_stock:
              res.data.data.show_out_of_stock_bool === 1 ? true : false,
            show_product_search:
              res.data.data.show_product_search_bool === 1 ? true : false,
            show_discount_percentage:
              res.data.data.show_discount_percentage_bool === 1 ? true : false,
            fit_image_horizontally:
              res.data.data.fit_image_horizontally === 1 ? true : false,
            product_view_style:
              res.data.data.product_view_style === undefined
                ? true
                : res.data.data.product_view_style === "grid"
                ? true
                : false,
            enable_web_view_interface:
              res.data.data.enable_web_view_interface_bool === undefined
                ? false
                : res.data.data.enable_web_view_interface_bool === 1
                ? true
                : false,
            discount_badge_text_colour:
              res.data.data.discount_badge_text_colour === undefined
                ? "#FFFFFF"
                : res.data.data.discount_badge_text_colour,
            discount_badge_bg_colour:
              res.data.data.discount_badge_bg_colour === undefined
                ? "#FF3D3D"
                : res.data.data.discount_badge_bg_colour,
            enable_discount_badge:
              res.data.data.enable_discount_badge === undefined
                ? true
                : res.data.data.enable_discount_badge === 1
                ? true
                : false,
            enable_cart_badge:
              res.data.data.enable_cart_badge === undefined
                ? true
                : res.data.data.enable_cart_badge === 1
                ? true
                : false,
            cart_badge_counter_bg_colour:
              res.data.data.cart_badge_counter_bg_colour === undefined
                ? "#FF3D3D"
                : res.data.data.cart_badge_counter_bg_colour,
            cart_badge_counter_text_colour:
              res.data.data.cart_badge_counter_text_colour === undefined
                ? "#FFFFFF"
                : res.data.data.cart_badge_counter_text_colour,
            cartUrl: res.data.data.web_view_cart_url,
          });
        }
      } else {
        errortoast("Something went wrong. Try again after sometime.");
      }
    })
    .catch((error) => {});
};

export const Productapi = (params, state) => {
  Loader(state);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "save-product-toggle", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          submitval: "Save",
          loader: "d-none",
        });
        sucesstoast("Submitted Successfully");
      } else {
        errortoast("Something went wrong. Try again after sometime.");
      }
    })
    .catch((error) => {
      errortoast("Something went wrong. Try again after sometime.");
      state.setState({
        submitval: "Save",
        loader: "d-none",
      });
    });
};
//Appsumo app status

export const Appsumoappstatus = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(appsumourl + "api/app/appsumo/status-update", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          hitpopup: false,
          submitval_confirmpop: "Yes",
          loader_remove: "d-none",
          loaderimage_remove:
            require("../../assets/images/dashboard/loader.gif").default,
        });
        const pagination = {
          page: 1,
          limit: 29,
          search_field: "",
        };
        state.handlepagination(pagination);
      } else {
        state.setState(
          {
            hitpopup: false,

            submitval_confirmpop: "Yes",
            loader_remove: "d-none",
            loaderimage_remove:
              require("../../assets/images/dashboard/loader.gif").default,
          },
          () => {
            state.setState({
              hitpopup: true,
              title: "Upgrade required",
              description: res.data.data,
              bothbutton: false,
            });
          }
        );
        //errortoast("Something went wrong. Try again after sometime.")
      }
    })
    .catch((error) => {
      state.setState({
        hitpopup: false,
      });
      errortoast("Something went wrong. Try again after sometime.");
    });
};
//SetpageApi
export const SetpageApi = (params, state) => {
  Loader(state);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "custom-menu", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          submitval: "Save",
          loader: "d-none",
        });

        sucesstoast("Webpages added to App Menu");
        // window.location.reload()
      } else {
        errortoast("Something went wrong. Try again after sometime.");
      }
    })
    .catch((error) => {});
};

//Validatepluginurl
export const Validatepluginurl = (params, state) => {
  state.setState({
    spinner: true,
  });
  const headers = {
    "Content-Type": "application/json",
    // Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "validate-url", params, {
      headers: headers,
    })
    .then((res) => {
      window.open(
        res.data +
          "wp-admin/plugin-install.php?s=appmysite&tab=search&type=term",
        "_blank"
      );
      state.setState({
        spinner: false,
      });
    })
    .catch((error) => {
      errortoast("Something went wrong. Try again after sometime.");
      state.setState({
        spinner: false,
      });
    });
};
//Appnameapi
export const Appnameapi = (params, state) => {
  Loader(state);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "updateApp", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        localStorage.setItem("website_technology", params.website_technology);
        state.setState({
          submitval: "Save",
          loader: "d-none",
          lastdate: res.data.updated_at,
          hitapi: false,
        });
        localStorage.setItem("store_url", "https://" + params.store_url);
        localStorage.setItem("website_type_select", params.website_type);
        if (
          res.data.is_change_in_url === 1 &&
          parseInt(localStorage.getItem("website_technology")) !== 3
        ) {
          GeneralExtractColor(state);
        }

        if (
          res.data.is_change_in_url === 1 &&
          (res.data.website_technology === 4 ||
            res.data.website_technology === 5)
        ) {
          //window.location.reload()
          state.loadapi();
          state.setState({
            verifypop: true,
            verifypopmodal: true,
            verifyhiturl: "/app/connect/wp-plugin",
            handlerender: false,
          });
        } else {
          // window.location.reload();
          sucesstoast("Updated successfully.");
        }
      } else if (res.data.code === 400) {
        state.setState({
          submitval: "Save",
          loader: "d-none",
          hitapi: false,
        });
        if (res.data.data.store_url !== undefined) {
          errortoast(res.data.data.store_url[0]);
          state.setState({
            submitval: "Save",
            loader: "d-none",
            hitapi: false,
          });
        }
        if (res.data.data.default_language !== undefined) {
          errortoast(res.data.data.default_language[0]);
          state.setState({
            submitval: "Save",
            loader: "d-none",
            hitapi: false,
          });
        }
        if (res.data.data.force_app_to_update_latest_version !== undefined) {
          errortoast(res.data.data.force_app_to_update_latest_version[0]);
          state.setState({
            submitval: "Save",
            loader: "d-none",
            hitapi: false,
          });
        }
        if (res.data.data.app_name !== undefined) {
          errortoast(res.data.data.app_name[0]);
          state.setState({
            submitval: "Save",
            loader: "d-none",
            hitapi: false,
          });
        }
      } else if (res.data.code === 500) {
        errortoast(res.data.data);
        state.setState({
          submitval: "SUBMIT",
          loader: "d-none",
          hitapi: false,
        });
      }
    })
    .catch((error) => {});
};

//Verified Api
export const VerifiedApi = (params, state) => {
  Loader(state);
  const headers = {
    "Content-Type": "application/json",
  };

  axios
    .get(
      process.env.REACT_APP_cors_api +
        params.sendwrl.replace("www.", "") +
        "wp-json/wc/v3/system_status?consumer_secret=" +
        params.client_secret +
        "%26consumer_key=" +
        params.client_key +
        "&timestamp=" +
        new Date().getTime(),
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.status === 200) {
        if (typeof res.data === "object") {
          let i = 0;
          let pluginname = [];
          for (i = 0; i < res.data.active_plugins.length; i++) {
            pluginname.push(res.data.active_plugins[i].name);
          }
          if (pluginname.indexOf("AppMySite") === -1) {
            const paramsdata = {
              app_id: params.appid,
              is_verified: 0,
              plugincontent:
                "It appears that the AppMySite plugin is not installed on your WordPress website.",
            };
            Sendverified(paramsdata, state);
          } else {
            const paramsdata = {
              app_id: params.appid,
              is_verified: 1,
            };
            Sendverified(paramsdata, state);
          }
        } else {
          const paramsdata = {
            app_id: params.appid,
            is_verified: 0,
            plugincontent:
              "It appears that the AppMySite plugin is not installed on your WordPress website.",
          };
          Sendverified(paramsdata, state);
        }
      }
    })
    .catch((error) => {
      errorHandling(error, state);
      state.setState({
        spinner: false,
      });
      const paramsdata = {
        app_id: params.appid,
        is_verified: 0,
      };
      Sendverified(paramsdata, state);
    });
};

//Verified Api incase of blog and pages
export const VerifiedBlogApi = (params, state,cb,refetchBottomBarData,refetchMenuData) => {
  Loader(state);

  const headers = {
    "Content-Type": "application/json",
    "X-App-Device-Type": "ios",
    "X-App-Code-Ver": localStorage.getItem("Apiversion"),
  };

  axios
    .get(
      process.env.REACT_APP_cors_api +
        params.sendwrl.replace("www.", "") +
        "wp-json/wc/v3/ams-menu-names&timestamp=" +
        new Date().getTime(),
      {
        headers: headers,
        data: {},
      }
    )
    .then((res) => {
      if (res.status === 200) {
        if (typeof res.data === "object") {
          const paramsdata = {
            app_id: params.appid,
            is_verified: 1,
          };
          Sendverified(paramsdata, state,cb,refetchBottomBarData,refetchMenuData);
        } else if (res.data === false) {
          const paramsdata = {
            app_id: params.appid,
            is_verified: 1,
          };
          Sendverified(paramsdata, state,cb,refetchBottomBarData,refetchMenuData);
        } else if (res.data === true) {
          const paramsdata = {
            app_id: params.appid,
            is_verified: 1,
          };
          Sendverified(paramsdata, state,cb,refetchBottomBarData,refetchMenuData);
        } else {
          state.setState({
            puginbutton: "INSTALL PLUGIN",
            spinner: false,
            modal: true,
            submitval: "Verify",
            loader: "d-none",
            finalimg:
              require("../../assets/images/dashboard/installed-plugin.png")
                .default,
            plugincontent:
              "It appears that the AppMySite plugin is not installed on your WordPress website.",
          });
          throw new Error(
            "It appears that the AppMySite plugin is not installed on your WordPress website."
          );
        }
      } else {
        state.setState({
          puginbutton: "INSTALL PLUGIN",
          spinner: false,
          modal: true,
          submitval: "Verify",
          loader: "d-none",
          finalimg:
            require("../../assets/images/dashboard/installed-plugin.png")
              .default,
          plugincontent:
            "It appears that the AppMySite plugin is not installed on your WordPress website.",
        });
        throw new Error(
          "It appears that the AppMySite plugin is not installed on your WordPress website."
        );
      }
    })
    .catch((error) => {
      errorHandling(error, state);

      const paramsdata = {
        app_id: params.appid,
        is_verified: 0,
      };
      Sendverified(paramsdata, state,cb,refetchBottomBarData,refetchMenuData);
    })

    ;
};
//Send Verified API
//Appcredentailapi Api
export const Sendverified = (paramsdata, state,cb,refetchBottomBarData,refetchMenuData) => {
  Loader(state);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "verify-app", paramsdata, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        if (paramsdata.is_verified === 0) {
          // errortoast("We couldn't find our plugin on your website.")
          state.setState({
            puginbutton: "INSTALL PLUGIN",
            spinner: false,
            modal: true,
            submitval: "Verify",
            loader: "d-none",
            handlerender: false,
            finalimg:
              require("../../assets/images/dashboard/installed-plugin.png")
                .default,
            is_verified: "0",
            modalError: true,
          });
          localStorage.setItem("is_verified", "0");
        } else {
          sucesstoast("We found the plugin on your website.");
          // state.props.history.push("/app/connect/wp-grant-access");
          state.setState({
            submitval: "Verify",
            loader: "d-none",
            is_verified: "1",
            handlerender: false,
            finalimg:
              require("../../assets/images/dashboard/successfulinstalled.png")
                .default,
          });
          localStorage.setItem("is_verified", "1");
        }
        cb();
        refetchBottomBarData();
        refetchMenuData();

      } else if (res.data.code === 401) {
        if (res.data.data === "App is already verified.") {
          sucesstoast(res.data.data);
        } else {
          errortoast(res.data?.data || "Oh no! Something’s not right.");
        }
        state.setState({
          success: false,
          submitval: "Verify",
          loader: "d-none",
          finalimg:
            require("../../assets/images/dashboard/successfulinstalled.png")
              .default,
        });
      } else {
        errortoast("Oh no! Something’s not right.");
      }
    })
    .catch((error) =>{});
};
//imagebusketApi
//Getsystemdata
export const Getsystemdata = (params, state) => {
  let query = {
    access_key: process.env.REACT_APP_userstack_accesskey,
    ua: navigator.userAgent,
  };
  axios
    .get(
      process.env.REACT_APP_userstack_path +
        "access_key=" +
        query.access_key +
        "&ua=" +
        query.ua
    )
    .then((res) => {
      if (res.data.error !== undefined) {
        state.setState({
          browser: res.data.error.info,
        });
      } else {
        state.setState({
          browser: JSON.stringify(res.data),
        });
      }
    })
    .catch((error) => {
      state.setState({
        browser: "Userstack api is not working.",
      });
    });
};
export const ProfileImagebusketApi = (params, state) => {
  //convert into image
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    //return new File([u8arr], filename, {type:mime});
    return new Blob([u8arr], { type: mime });
  }

  //Usage example:
  let file = dataURLtoFile(params.data, "hello.png");

  //send to s3
  const config = {
    bucketName: process.env.REACT_APP_bucketName,
    dirName: params.path.replace(/\/$/, "") /* optional */,
    region: process.env.REACT_APP_region,
    accessKeyId: process.env.REACT_APP_accessKeyId,
    secretAccessKey: process.env.REACT_APP_secretAccessKey,
    s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
  };
  // const ReactS3Client = new CustomS3Client(config);
  const customS3Client = new CustomS3Client(config);

  const newFileName =
    "ams_" +
    new Date().getTime() +
    "_" +
    Math.floor(1000 + Math.random() * 9000);
  customS3Client
    .uploadFile(file, newFileName)
    .then((data) => {
      if (data.status === 204) {
        state.setState({
          profile: newFileName + ".png",
          // profileimage: newFileName + ".png",
          profile_image: params.data,
          croppedImage: params.data,
          modal: false,
          buttontext: "CROP",
        });

        if (state.state.childState !== undefined) {
          state.state.childState.setState({
            profile: newFileName + ".png",
          });
        }
        // if (state.state.finalImgArr !== undefined) {
        //   let obj={
        //     index:params.index,
        //     filename:newFileName + "." + params.filetype
        //   }
        //   state.state.finalImgArr.push(obj);
        //   state.setState({
        //     count: state.state.count + 1,
        //   });
        // }
      }
    })
    .catch((err) => {
      console.error(err);
      errortoast("Something went wrong. Try again after sometime.");
    });
};

export const emailSupportImgsUploadonS3 = (params, filename, appId, related_to) => {
  //convert into image
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    //return new File([u8arr], filename, {type:mime});
    return new Blob([u8arr], { type: mime });
  }

  //Usage example:
  let file = dataURLtoFile(params.data, "hello.png");

  //send to s3
  const config = {
    bucketName: process.env.REACT_APP_bucketName,
    dirName: params.path.replace(/\/$/, "") /* optional */,
    region: process.env.REACT_APP_region,
    accessKeyId: process.env.REACT_APP_accessKeyId,
    secretAccessKey: process.env.REACT_APP_secretAccessKey,
    s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
    from: 'support',
    appId: appId,
    related_to
  };
  const ReactS3Client = new CustomS3Client(config);

  ReactS3Client.uploadFile(file, filename)
    .then((data) => {
      if (data.status === 204) {
      }
    })
    .catch((err) => {
      console.error(err);
      errortoast("Something went wrong. Try again after sometime.");
    });
};

//Review image
export const Reviewimagebucket = (params, state) => {
  //convert into image
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    //return new File([u8arr], filename, {type:mime});
    return new Blob([u8arr], { type: mime });
  }

  //Usage example:
  let file = dataURLtoFile(params.data, "hello.png");

  //send to s3
  const config = {
    bucketName: process.env.REACT_APP_bucketName,
    dirName: params.path.replace(/\/$/, "") /* optional */,
    region: process.env.REACT_APP_region,
    accessKeyId: process.env.REACT_APP_accessKeyId,
    secretAccessKey: process.env.REACT_APP_secretAccessKey,
    s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
  };
  const ReactS3Client = new CustomS3Client(config);
  const newFileName =
    "ams_" +
    new Date().getTime() +
    "_" +
    Math.floor(1000 + Math.random() * 9000);
  ReactS3Client.uploadFile(file, newFileName)
    .then((data) => {
      if (data.status === 204) {
        state.setState({
          reference: newFileName + "." + params.filetype,
          imagename: newFileName + "." + params.filetype,
          disabledbutton: false,
        });
      }
    })
    .catch((err) => {
      state.setState({
        disabledbutton: false,
      });
      errortoast("Something went wrong. Try again after sometime.");
    });
};
//Support image
// export const SupportImagebusketApi = (imageparams, state, callback) => {
//   Loader(state);
//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: "Bearer " + localStorage.getItem("amstoken"),
//   };
//   if (imageparams.data === "") {
//     state.setState({
//       screenshot: "",
//     });
//     callback();
//   } else {
//     axios
//       .post(Url + "uploadImage", imageparams, {
//         headers: headers,
//       })
//       .then((res) => {
//         if (res.status === 200) {
//           state.setState({
//             screenshot: res.data.cropped,
//           });
//           callback();
//         } else if (res.data.code === 401) {
//           if (res.data.status === "Token Expired") {
//             state.props.history.push("/");
//             let myItem = localStorage.getItem("buildversion");
//             let myItemversion = localStorage.getItem("Apiversion");
//             localStorage.clear();
//             localStorage.setItem("buildversion", myItem);
//             localStorage.setItem("Apiversion", myItemversion);
//           }
//         } else if (res.data.code === 403) {
//           errortoast(res.data.data);
//         } else {
//           errortoast("Something went wrong. Try again after sometime.");
//         }
//       });
//   }
// };

export const ImagebusketApi = (params, state, original) => {
  let originalimagename = "";
  //original convert into image
  let setarray = [];
  function dataURLtoFileoriginal(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
  //crop convert into image

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  //Send original image
  // let originalfile = dataURLtoFileoriginal(params.original, "hello.png");
  // const oriconfig = {
  //   bucketName: process.env.REACT_APP_bucketName,
  //   dirName: params.original_path.replace(/\/$/, "") /* optional */,
  //   region: process.env.REACT_APP_region,
  //   accessKeyId: process.env.REACT_APP_accessKeyId,
  //   secretAccessKey: process.env.REACT_APP_secretAccessKey,
  //   s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
  // };
  // const ReactS3original = new CustomS3Client(oriconfig);
  // const originalFileName =
  //   "ams_" +
  //   new Date().getTime() +
  //   "_" +
  //   Math.floor(1000 + Math.random() * 9000);
  // ReactS3original.uploadFile(originalfile, originalFileName)
  //   .then((data) => {
  //     if (data.status === 204) {
  //       setarray.push("1");
  //       originalimagename = data.key.split("/").pop();
  //     }
  //   })
  //   .catch((err) => {
  //     console.error(err);
  //     state.setState({
  //       disabledsubmitbutton: false,
  //     });
  //     let checkclass = document
  //       .querySelector("body")
  //       .classList.contains("modal-open");
  //     if (checkclass === true) {
  //       document.querySelector("body").classList.remove("modal-open");
  //       document.querySelector("body").removeAttribute("style");
  //     }

  //     errortoast("Something went wrong. Try again after sometime.");
  //   });

  //send crop image
  let file = dataURLtoFile(params.data, "hello.png");
  const config = {
    bucketName: process.env.REACT_APP_bucketName,
    dirName: params.path.replace(/\/$/, "") /* optional */,
    region: process.env.REACT_APP_region,
    accessKeyId: process.env.REACT_APP_accessKeyId,
    secretAccessKey: process.env.REACT_APP_secretAccessKey,
    s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
  };
  const ReactS3Client = new CustomS3Client(config);
  const newFileName =
    "ams_" +
    new Date().getTime() +
    "_" +
    Math.floor(1000 + Math.random() * 9000);
  ReactS3Client.uploadFile(file, newFileName)
    .then((data) => {
      if (data.status === 204) {
        setarray.push("2");
      }
    })
    .catch((err) => {
      console.error(err);
      state.setState({
        disabledsubmitbutton: false,
      });
      let checkclass = document
        .querySelector("body")
        .classList.contains("modal-open");
      if (checkclass === true) {
        document.querySelector("body").classList.remove("modal-open");
        document.querySelector("body").removeAttribute("style");
      }

      errortoast("Something went wrong. Try again after sometime.");
    });

  //Send to component

  state.countdown = setInterval(function () {
    setarray.length === 1 &&
      state.setState(
        {
          croppedImage: newFileName + ".png",
          buttontext: "CROP & SAVE",
          modal: false,
        },
        () => {
          let checkclass = document
            .querySelector("body")
            .classList.contains("modal-open");
          if (checkclass === true) {
            document.querySelector("body").classList.remove("modal-open");
            document.querySelector("body").removeAttribute("style");
          }
          state.props.finalcrop(
            state.state.croppedImage,
            params.data,
            originalimagename
          );
        }
      );
  }, 1000);
};
export const Customlogoupload = (
  params,
  sendtoapi,
  customlogo,
  logoText,
  fontColor,
  state,
  callback
) => {
  Loader(state);
  if (logoText === "" || fontColor === "") {
    state.setState({
      app_icon_image: "",
    });

    callback();
  } else {
    if (sendtoapi === "same") {
      state.setState({
        app_icon_image: customlogo,
      });
      callback();
    } else {
      let setarray = [];
      function dataURLtoFileoriginal(dataurl, filename) {
        var arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new Blob([u8arr], { type: mime });
      }
      //crop convert into image

      function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new Blob([u8arr], { type: mime });
      }

      //Send original image
      let originalfile = dataURLtoFileoriginal(params.original, "hello.png");
      const oriconfig = {
        bucketName: process.env.REACT_APP_bucketName,
        dirName: params.original_path.replace(/\/$/, "") /* optional */,
        region: process.env.REACT_APP_region,
        accessKeyId: process.env.REACT_APP_accessKeyId,
        secretAccessKey: process.env.REACT_APP_secretAccessKey,
        s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(
          /\/$/,
          ""
        ) /* optional */,
      };
      const ReactS3original = new CustomS3Client(oriconfig);
      const originalFileName =
        "ams_" +
        new Date().getTime() +
        "_" +
        Math.floor(1000 + Math.random() * 9000);
      ReactS3original.uploadFile(originalfile, originalFileName)
        .then((data) => {
          if (data.status === 204) {
            setarray.push("1");
          }
        })
        .catch((err) => {
          console.error(err);
          errortoast("Something went wrong. Try again after sometime.");
        });

      //send crop image
      let file = dataURLtoFile(params.data, "hello.png");
      const config = {
        bucketName: process.env.REACT_APP_bucketName,
        dirName: params.path.replace(/\/$/, "") /* optional */,
        region: process.env.REACT_APP_region,
        accessKeyId: process.env.REACT_APP_accessKeyId,
        secretAccessKey: process.env.REACT_APP_secretAccessKey,
        s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(
          /\/$/,
          ""
        ) /* optional */,
      };
      const ReactS3Client = new CustomS3Client(config);
      const newFileName =
        "ams_" +
        new Date().getTime() +
        "_" +
        Math.floor(1000 + Math.random() * 9000);
      ReactS3Client.uploadFile(file, newFileName)
        .then((data) => {
          if (data.status === 204) {
            setarray.push("2");
          }
        })
        .catch((err) => {
          console.error(err);
          errortoast("Something went wrong. Try again after sometime.");
        });

      //Send to component

      state.countdown = setInterval(hitapi, 1000);
      function hitapi() {
        setarray.length === 2 &&
          state.setState(
            {
              app_icon_image: newFileName + ".png",
            },
            () => {
              callback();
              clearInterval(state.countdown);
            }
          );
      }
    }
  }
};

//DownloadBuildApi
export const DownloadDashboardBuildApi = (params, state) => {
  clearInterval(state.interval);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "app/download-link", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        //check app is connected to other app
        state.versiontable();
        //To pass data to publishapp component
        state.setState({
          multisite: res.data.multisite,
          builder_status: res.data.builder_status,
          allowed_module: res.data.data.module_data.device_type,
        });
        //Android build condition
        if (res.data.data.android !== undefined) {
          if (res.data.data.android === null) {
            state.setState({
              hideandroidbuildappbutton: false,
              androidbuildspinner: false,
              downloadbuild: false,
              rebuildapp: false,
            });
          } else {
            state.setState({
              android_error_description:
                res.data.data.android.data.description === undefined
                  ? null
                  : res.data.data.android.data.description,
              androidstatus: res.data.data.android.data,
              is_android_build_on_hold:
                res.data.data.android.data.is_build_on_hold,
              andorid_step_number: res.data.data.android.data.step_number,
              android_bitrise_build_slug:
                res.data.data.android.data.bitrise_build_slug,
              triggerautomatic: false,
              android_build_version:
                res.data.data.android.versions.build_version === null
                  ? null
                  : res.data.data.android.versions.build_version,
              android_latest_version:
                res.data.data.android.versions.latest_version === null
                  ? null
                  : res.data.data.android.versions.latest_version,
            });
            if (res.data.data.android.data.app_in_process === 1) {
              //  setInterval(() => { state.waitfunction() }, 3000);
              state.setState({
                rebuildapp: false,
                androidbuildspinner: true,
                downloadbuild: false,
                hideandroidbuildappbutton: true,
              });
            }
            if (res.data.data.android.data.app_in_process === 0) {
              if (
                res.data.data.android.data.build_status === "1" &&
                res.data.data.android.data.bitrise_success_step === "success" &&
                res.data.data.android.data.bitbucket_success_step === "success"
              ) {
                //When App is available to Download
                state.setState({
                  rebuildapp: false,
                  androidbuildspinner: false,
                  downloadbuild: true,
                  hideandroidbuildappbutton: true,
                  buildurl: res.data.data.android.data.build_url,
                  aabfile:
                    res.data.data.android.data.zip_path !== undefined
                      ? res.data.data.android.data.zip_path
                      : null,
                });
              }
              if (
                res.data.data.android.data.build_status === "2" &&
                res.data.data.android.success_status === 0
              ) {
                //Rebuild when failed
                state.setState({
                  rebuildapp: true,
                  androidbuildspinner: false,
                  downloadbuild: false,
                  hideandroidbuildappbutton: true,
                });
              }
              if (
                res.data.data.android.data.build_status === "0" &&
                res.data.data.android.success_status === 0
              ) {
                //Build app condition after payment
                state.setState({
                  hideandroidbuildappbutton: false,
                  androidbuildspinner: false,
                  downloadbuild: false,
                  rebuildapp: false,
                });
              }
              //Condition after purchasing addon
              if (
                res.data.data.android.data.build_status === "0" &&
                res.data.data.android.success_status !== 0
              ) {
                state.setState({
                  addonrebuildapp: true,
                  hideandroidbuildappbutton: true,
                  androidbuildspinner: false,
                  downloadbuild: false,
                  rebuildapp: false,
                });
              }
              if (
                res.data.data.android.data.build_status === "2" &&
                res.data.data.android.success_status !== 0
              ) {
                state.setState({
                  addonrebuildapp: true,
                  hideandroidbuildappbutton: true,
                  androidbuildspinner: false,
                  downloadbuild: false,
                  rebuildapp: false,
                });
              }
              //Condition after purchasing addon end
            }
          }
        }

        //iOS build condition
        if (res.data.data.ios !== undefined) {
          if (res.data.data.ios === null) {
            state.setState({
              hideiosbuildappbutton: false,
              iosbuildspinner: false,
              downloadiosbuild: false,
              rebuildiosapp: false,
            });
          } else {
            state.setState({
              buildiosstatus: res.data.data.ios.data,
              is_ios_build_on_hold: res.data.data.ios.data.is_build_on_hold,
              ios_step_number: res.data.data.ios.data.step_number,
              ios_bitrise_build_slug: res.data.data.ios.data.bitrise_build_slug,
              iostriggerautomatic: false,
              ios_build_version:
                res.data.data.ios.versions.build_version === null
                  ? null
                  : res.data.data.ios.versions.build_version,
              ios_latest_version:
                res.data.data.ios.versions.latest_version === null
                  ? null
                  : res.data.data.ios.versions.latest_version,
              file_check:
                res.data.data.ios.file_check === undefined
                  ? 0
                  : res.data.data.ios.file_check,
              error_description:
                res.data.data.ios.data.description === undefined
                  ? null
                  : res.data.data.ios.data.description,
            });
            if (res.data.data.ios.data.app_in_process === 1) {
              //Wait code
              state.waitfunction();
              state.setState({
                rebuildiosapp: false,
                iosbuildspinner: true,
                downloadiosbuild: false,
                hideiosbuildappbutton: true,
              });
            }
            if (res.data.data.ios.data.app_in_process === 0) {
              if (
                res.data.data.ios.data.build_status === "1" &&
                res.data.data.ios.data.bitrise_success_step === "success" &&
                res.data.data.ios.data.bitbucket_success_step === "success"
              ) {
                //When App is available to Download

                state.setState({
                  rebuildiosapp: false,
                  iosbuildspinner: false,
                  downloadiosbuild: true,
                  hideiosbuildappbutton: true,
                  buildiosurl: res.data.data.ios.data.build_url,
                });
              }
              if (
                res.data.data.ios.data.build_status === "2" &&
                res.data.data.ios.success_status === 0
              ) {
                //Rebuild when failed
                state.setState({
                  rebuildiosapp: true,
                  iosbuildspinner: false,
                  downloadiosbuild: false,
                  hideiosbuildappbutton: true,
                });
              }
              if (
                res.data.data.ios.data.build_status === "0" &&
                res.data.data.ios.success_status === 0
              ) {
                //Build app after payment
                state.setState({
                  hideiosbuildappbutton: false,
                  iosbuildspinner: false,
                  downloadiosbuild: false,
                  rebuildiosapp: false,
                });
              }
              //Rebuild condition after addon
              if (
                res.data.data.ios.data.build_status === "2" &&
                res.data.data.ios.success_status !== 0
              ) {
                //Rebuild
                state.setState({
                  rebuildiosapp: false,
                  iosbuildspinner: false,
                  downloadiosbuild: false,
                  hideiosbuildappbutton: true,
                  addoniosbuild: true,
                });
              }
              if (
                res.data.data.ios.data.build_status === "0" &&
                res.data.data.ios.success_status !== 0
              ) {
                state.setState({
                  hideiosbuildappbutton: true,
                  iosbuildspinner: false,
                  downloadiosbuild: false,
                  rebuildiosapp: false,
                  addoniosbuild: true,
                });
              }
            }
          }
        }
      } else if (res.data.code === 201) {
        state.versiontable();
        //check app is connected to other app
        let multisite = res.data.multisite;

        state.setState({
          multisite: res.data.multisite,
          allowed_module: res.data.module_data.device_type,
        });
      } else if (res.data.code === 401) {
        state.versiontable();
        // state.props.getthis.props.history.push("/apps");
      }
    })
    .catch((error) => {});
};
//DownloadBuildApi
export const DownloadBuildApi = (params, state) => {
  clearInterval(state.interval);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "app/download-link", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        //check app is connected to other app
        let multisite = res.data.multisite;
        multisite.is_app_link_to_multisite === 0 && state.versiontable();
        //To pass data to publishapp component
        state.props.getthis.setState({
          multisite: multisite,
          builder_status: res.data.builder_status,
          allowed_module: res.data.data.module_data.device_type,
        });

        state.setState({
          multisite: res.data.multisite,
          builder_status: res.data.builder_status,
          allowed_module: res.data.data.module_data.device_type,
        });
        //Android build condition
        if (res.data.data.android !== undefined) {
          if (res.data.data.android === null) {
            state.setState({
              hideandroidbuildappbutton: false,
              androidbuildspinner: false,
              downloadbuild: false,
              rebuildapp: false,
            });
          } else {
            state.setState({
              android_error_description:
                res.data.data.android.data.description === undefined
                  ? null
                  : res.data.data.android.data.description,
              androidstatus: res.data.data.android.data,
              is_android_build_on_hold:
                res.data.data.android.data.is_build_on_hold,
              andorid_step_number: res.data.data.android.data.step_number,
              android_bitrise_build_slug:
                res.data.data.android.data.bitrise_build_slug,
              triggerautomatic: false,
              android_build_version:
                res.data.data.android.versions.build_version === null
                  ? null
                  : res.data.data.android.versions.build_version,
              android_latest_version:
                res.data.data.android.versions.latest_version === null
                  ? null
                  : res.data.data.android.versions.latest_version,
            });
            if (res.data.data.android.data.app_in_process === 1) {
              //  setInterval(() => { state.waitfunction() }, 3000);
              state.waitfunction();
              state.setState({
                rebuildapp: false,
                androidbuildspinner: true,
                downloadbuild: false,
                hideandroidbuildappbutton: true,
              });
            }
            if (res.data.data.android.data.app_in_process === 0) {
              if (
                res.data.data.android.data.build_status === "1" &&
                res.data.data.android.data.bitrise_success_step === "success" &&
                res.data.data.android.data.bitbucket_success_step === "success"
              ) {
                //When App is available to Download
                state.setState({
                  rebuildapp: false,
                  androidbuildspinner: false,
                  downloadbuild: true,
                  hideandroidbuildappbutton: true,
                  buildurl: res.data.data.android.data.build_url,
                  aabfile:
                    res.data.data.android.data.zip_path !== undefined
                      ? res.data.data.android.data.zip_path
                      : null,
                });
              }
              if (
                res.data.data.android.data.build_status === "2" &&
                res.data.data.android.success_status === 0
              ) {
                //Rebuild when failed
                state.setState({
                  rebuildapp: true,
                  androidbuildspinner: false,
                  downloadbuild: false,
                  hideandroidbuildappbutton: true,
                });
              }
              if (
                res.data.data.android.data.build_status === "0" &&
                res.data.data.android.success_status === 0
              ) {
                //Build app condition after payment
                state.setState({
                  hideandroidbuildappbutton: false,
                  androidbuildspinner: false,
                  downloadbuild: false,
                  rebuildapp: false,
                });
              }
              //Condition after purchasing addon
              if (
                res.data.data.android.data.build_status === "0" &&
                res.data.data.android.success_status !== 0
              ) {
                state.setState({
                  addonrebuildapp: true,
                  hideandroidbuildappbutton: true,
                  androidbuildspinner: false,
                  downloadbuild: false,
                  rebuildapp: false,
                });
              }
              if (
                res.data.data.android.data.build_status === "2" &&
                res.data.data.android.success_status !== 0
              ) {
                state.setState({
                  addonrebuildapp: true,
                  hideandroidbuildappbutton: true,
                  androidbuildspinner: false,
                  downloadbuild: false,
                  rebuildapp: false,
                });
              }
              //Condition after purchasing addon end
            }
          }
        }

        //iOS build condition
        if (res.data.data.ios !== undefined) {
          if (res.data.data.ios === null) {
            state.setState({
              hideiosbuildappbutton: false,
              iosbuildspinner: false,
              downloadiosbuild: false,
              rebuildiosapp: false,
            });
          } else {
            state.setState({
              buildiosstatus: res.data.data.ios.data,
              is_ios_build_on_hold: res.data.data.ios.data.is_build_on_hold,
              ios_step_number: res.data.data.ios.data.step_number,
              ios_bitrise_build_slug: res.data.data.ios.data.bitrise_build_slug,
              iostriggerautomatic: false,
              ios_build_version:
                res.data.data.ios.versions.build_version === null
                  ? null
                  : res.data.data.ios.versions.build_version,
              ios_latest_version:
                res.data.data.ios.versions.latest_version === null
                  ? null
                  : res.data.data.ios.versions.latest_version,
              file_check:
                res.data.data.ios.file_check === undefined
                  ? 0
                  : res.data.data.ios.file_check,
              error_description:
                res.data.data.ios.data.description === undefined
                  ? null
                  : res.data.data.ios.data.description,
            });
            if (res.data.data.ios.data.app_in_process === 1) {
              //Wait code
              state.waitfunction();
              state.setState({
                rebuildiosapp: false,
                iosbuildspinner: true,
                downloadiosbuild: false,
                hideiosbuildappbutton: true,
              });
            }
            if (res.data.data.ios.data.app_in_process === 0) {
              if (
                res.data.data.ios.data.build_status === "1" &&
                res.data.data.ios.data.bitrise_success_step === "success" &&
                res.data.data.ios.data.bitbucket_success_step === "success"
              ) {
                //When App is available to Download

                state.setState({
                  rebuildiosapp: false,
                  iosbuildspinner: false,
                  downloadiosbuild: true,
                  hideiosbuildappbutton: true,
                  buildiosurl: res.data.data.ios.data.build_url,
                });
              }
              if (
                res.data.data.ios.data.build_status === "2" &&
                res.data.data.ios.success_status === 0
              ) {
                //Rebuild when failed
                state.setState({
                  rebuildiosapp: true,
                  iosbuildspinner: false,
                  downloadiosbuild: false,
                  hideiosbuildappbutton: true,
                });
              }
              if (
                res.data.data.ios.data.build_status === "0" &&
                res.data.data.ios.success_status === 0
              ) {
                //Build app after payment
                state.setState({
                  hideiosbuildappbutton: false,
                  iosbuildspinner: false,
                  downloadiosbuild: false,
                  rebuildiosapp: false,
                });
              }
              //Rebuild condition after addon
              if (
                res.data.data.ios.data.build_status === "2" &&
                res.data.data.ios.success_status !== 0
              ) {
                //Rebuild
                state.setState({
                  rebuildiosapp: false,
                  iosbuildspinner: false,
                  downloadiosbuild: false,
                  hideiosbuildappbutton: true,
                  addoniosbuild: true,
                });
              }
              if (
                res.data.data.ios.data.build_status === "0" &&
                res.data.data.ios.success_status !== 0
              ) {
                state.setState({
                  hideiosbuildappbutton: true,
                  iosbuildspinner: false,
                  downloadiosbuild: false,
                  rebuildiosapp: false,
                  addoniosbuild: true,
                });
              }
            }
          }
        }
      } else if (res.data.code === 201) {
        //check app is connected to other app
        let multisite = res.data.multisite;
        //To pass data to publishapp component
        state.props.getthis.setState({
          multisite: multisite,
          allowed_module: res.data.module_data.device_type,
        });
        state.setState({
          multisite: res.data.multisite,
          allowed_module: res.data.module_data.device_type,
        });
      } else if (res.data.code === 401) {
        state.props.getthis.props.history.push("/apps");
      }
    })
    .catch((error) => {});
};

//DownloadBuildApi
export const NewDownloadBuildApi = (params, state) => {
  clearInterval(state.interval);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "app/download-link", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        //check app is connected to other app
        let multisite = res.data.multisite;
        if (multisite.is_app_link_to_multisite === 0) {
          state.versionTable();
        }

        state.setState({
          multisite: res.data.multisite,
          builder_status: res.data.builder_status,
          allowed_module1: res.data.data.module_data.device_type,
        });
        //Android build condition
        if (res.data.data.android !== undefined) {
          if (res.data.data.android === null) {
            state.setState({
              hideandroidbuildappbutton: false,
              androidbuildspinner: false,
              downloadbuild: false,
              rebuildapp: false,
            });
          } else {
            state.setState({
              android_error_description:
                res.data.data.android.data.description === undefined
                  ? null
                  : res.data.data.android.data.description,
              androidstatus: res.data.data.android.data,
              is_android_build_on_hold:
                res.data.data.android.data.is_build_on_hold,
              andorid_step_number: res.data.data.android.data.step_number,
              android_bitrise_build_slug:
                res.data.data.android.data.bitrise_build_slug,
              triggerautomatic: false,
              android_build_version:
                res.data.data.android.versions.build_version === null
                  ? null
                  : res.data.data.android.versions.build_version,
              android_latest_version:
                res.data.data.android.versions.latest_version === null
                  ? null
                  : res.data.data.android.versions.latest_version,
              total_build_count: res.data.data.android.total_build_count,
              success_build_count: res.data.data.android.success_build_count,
            });
            if (res.data.data.android.data.app_in_process === 1) {
              //  setInterval(() => { state.waitfunction() }, 3000);
              state.waitfunction();
              state.setState({
                rebuildapp: false,
                androidbuildspinner: true,
                downloadbuild: false,
                hideandroidbuildappbutton: true,
              });
            }
            if (res.data.data.android.data.app_in_process === 0) {
              if (
                res.data.data.android.data.build_status === "1" &&
                res.data.data.android.data.bitrise_success_step === "success" &&
                res.data.data.android.data.bitbucket_success_step === "success"
              ) {
                //When App is available to Download
                
                state.setState({
                  rebuildapp: false,
                  androidbuildspinner: false,
                  downloadbuild: true,
                  hideandroidbuildappbutton: true,
                  buildurl: res.data.data.android.data.build_url,
                  aabfile:
                    res.data.data.android.data.zip_path !== undefined
                      ? res.data.data.android.data.zip_path
                      : null,
                });
              }
              if (
                res.data.data.android.data.build_status === "2" &&
                res.data.data.android.success_status === 0
              ) {
                //Rebuild when failed
                state.setState({
                  rebuildapp: true,
                  androidbuildspinner: false,
                  downloadbuild: false,
                  hideandroidbuildappbutton: true,
                });
              }
              if (
                res.data.data.android.data.build_status === "0" &&
                res.data.data.android.success_status === 0
              ) {
                //Build app condition after payment
                state.setState({
                  hideandroidbuildappbutton: false,
                  androidbuildspinner: false,
                  downloadbuild: false,
                  rebuildapp: false,
                });
              }
              //Condition after purchasing addon
              if (
                res.data.data.android.data.build_status === "0" &&
                res.data.data.android.success_status !== 0
              ) {
                state.setState({
                  addonrebuildapp: true,
                  hideandroidbuildappbutton: true,
                  androidbuildspinner: false,
                  downloadbuild: false,
                  rebuildapp: false,
                });
              }
              if (
                res.data.data.android.data.build_status === "2" &&
                res.data.data.android.success_status !== 0
              ) {
                state.setState({
                  addonrebuildapp: true,
                  hideandroidbuildappbutton: true,
                  androidbuildspinner: false,
                  downloadbuild: false,
                  rebuildapp: false,
                });
              }
              //Condition after purchasing addon end
            }
          }
        }

        //iOS build condition
        if (res.data.data.ios !== undefined) {
          if (res.data.data.ios === null) {
            state.setState({
              hideiosbuildappbutton: false,
              iosbuildspinner: false,
              downloadiosbuild: false,
              rebuildiosapp: false,
            });
          } else {
            state.setState({
              buildiosstatus: res.data.data.ios.data,
              is_ios_build_on_hold: res.data.data.ios.data.is_build_on_hold,
              ios_step_number: res.data.data.ios.data.step_number,
              ios_bitrise_build_slug: res.data.data.ios.data.bitrise_build_slug,
              iostriggerautomatic: false,
              ios_build_version:
                res.data.data.ios.versions.build_version === null
                  ? null
                  : res.data.data.ios.versions.build_version,
              ios_latest_version:
                res.data.data.ios.versions.latest_version === null
                  ? null
                  : res.data.data.ios.versions.latest_version,
              file_check:
                res.data.data.ios.file_check === undefined
                  ? 0
                  : res.data.data.ios.file_check,
              error_description:
                res.data.data.ios.data.description === undefined
                  ? null
                  : res.data.data.ios.data.description,
            });
            if (res.data.data.ios.data.app_in_process === 1) {
              //Wait code
              state.waitfunction();
              state.setState({
                rebuildiosapp: false,
                iosbuildspinner: true,
                downloadiosbuild: false,
                hideiosbuildappbutton: true,
              });
            }
            if (res.data.data.ios.data.app_in_process === 0) {
              if (
                res.data.data.ios.data.build_status === "1" &&
                res.data.data.ios.data.bitrise_success_step === "success" &&
                res.data.data.ios.data.bitbucket_success_step === "success"
              ) {
                //When App is available to Download

                state.setState({
                  rebuildiosapp: false,
                  iosbuildspinner: false,
                  downloadiosbuild: true,
                  hideiosbuildappbutton: true,
                  buildiosurl: res.data.data.ios.data.build_url,
                });
              }
              if (
                res.data.data.ios.data.build_status === "2" &&
                res.data.data.ios.success_status === 0
              ) {
                //Rebuild when failed
                state.setState({
                  rebuildiosapp: true,
                  iosbuildspinner: false,
                  downloadiosbuild: false,
                  hideiosbuildappbutton: true,
                });
              }
              if (
                res.data.data.ios.data.build_status === "0" &&
                res.data.data.ios.success_status === 0
              ) {
                //Build app after payment
                state.setState({
                  hideiosbuildappbutton: false,
                  iosbuildspinner: false,
                  downloadiosbuild: false,
                  rebuildiosapp: false,
                });
              }
              //Rebuild condition after addon
              if (
                res.data.data.ios.data.build_status === "2" &&
                res.data.data.ios.success_status !== 0
              ) {
                //Rebuild
                state.setState({
                  rebuildiosapp: false,
                  iosbuildspinner: false,
                  downloadiosbuild: false,
                  hideiosbuildappbutton: true,
                  addoniosbuild: true,
                });
              }
              if (
                res.data.data.ios.data.build_status === "0" &&
                res.data.data.ios.success_status !== 0
              ) {
                state.setState({
                  hideiosbuildappbutton: true,
                  iosbuildspinner: false,
                  downloadiosbuild: false,
                  rebuildiosapp: false,
                  addoniosbuild: true,
                });
              }
            }
          }
        }
      } else if (res.data.code === 201) {
        //check app is connected to other app
        let multisite = res.data.multisite;

        state.setState({
          multisite: res.data.multisite,
          allowed_module1: res.data.module_data.device_type,
        });
      } else if (res.data.code === 401) {
        state.props.history.push("/apps");
      }
    })
    .catch((error) => {});
};
//BuildappApi
export const BuildappApi = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  setTimeout(function () {
    state.props.history.push("/app/download");
  }, 5000);

  axios
    .post(BuildUrl + "automate-build-app", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({ buildclass: "d-none", buttonbuildclass: "d-block" });
        state.setState({
          allowSaveBtn: true,
          allowResetBtn: true,
        });
        if (params.platform === "android") {
          state.setState({
            androidsubmitval: "Build App",
            androidloader: "d-none",
            androidloaderimage:
              require("../../assets/images/signup/Loader_GIF.gif").default,
          });
        }
        if (params.platform === "ios") {
          state.setState({
            submitval: "Build App",
            loader: "d-none",
            loaderimage: require("../../assets/images/signup/Loader_GIF.gif")
              .default,
          });
        }
      } else {
        state.setState({ buildclass: "d-none", buttonbuildclass: "d-block" });
        if (params.platform === "android") {
          state.setState({
            androidsubmitval: "Build App",
            androidloader: "d-none",
            androidloaderimage:
              require("../../assets/images/signup/Loader_GIF.gif").default,
          });
        }
        if (params.platform === "ios") {
          state.setState({
            submitval: "Build App",
            loader: "d-none",
            loaderimage: require("../../assets/images/signup/Loader_GIF.gif")
              .default,
          });
        }
      }
    })
    .catch((error) => {
      state.setState({ buildclass: "d-none", buttonbuildclass: "d-block" });
      state.setState({
        allowSaveBtn: true,
        allowResetBtn: true,
        allowBuildBtn: true,
      });
    });
};

export const pushcertificatefunction = (params, state, callback) => {
  if (params.original !== "") {
    function dataURLtoFileoriginal(dataurl, filename) {
      var arr = dataurl.split(","),
        //mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: "application/plist" });
    }
    let originalfile = dataURLtoFileoriginal(params.original, "hello.plist");
    const oriconfig = {
      bucketName: process.env.REACT_APP_bucketName,
      dirName: params.original_path.replace(/\/$/, "") /* optional */,
      region: process.env.REACT_APP_region,
      accessKeyId: process.env.REACT_APP_accessKeyId,
      secretAccessKey: process.env.REACT_APP_secretAccessKey,
      s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
    };
    const ReactS3original = new CustomS3Client(oriconfig);
    const originalFileName = params.imgname;
    ReactS3original.uploadFile(originalfile, originalFileName)
      .then((data) => {
        if (data.status === 204) {
          state.setState({
            pushnotifications3name: data.key.split("/").pop(),
            disabledsubmitbutton: false,
            original_plist_file: data.location,
          });
          callback();
        }
      })
      .catch((err) => {
        errortoast("Something went wrong. Try again after sometime.");
      });
  } else {
    callback();
  }
};
export const androidpushcertificatefunction = (params, state, callback) => {
  if (params.original !== "") {
    function dataURLtoFileoriginal(dataurl, filename) {
      var arr = dataurl.split(","),
        //mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: "application/json" });
    }
    let originalfile = dataURLtoFileoriginal(params.original, "hello.json");
    const oriconfig = {
      bucketName: process.env.REACT_APP_bucketName,
      dirName: params.original_path.replace(/\/$/, "") /* optional */,
      region: process.env.REACT_APP_region,
      accessKeyId: process.env.REACT_APP_accessKeyId,
      secretAccessKey: process.env.REACT_APP_secretAccessKey,
      s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
    };
    const ReactS3original = new CustomS3Client(oriconfig);
    const originalFileName = params.imgname;
    ReactS3original.uploadFile(originalfile, originalFileName)
      .then((data) => {
        if (data.status === 204) {
          state.setState({
            androidpushnotifications3name: data.key.split("/").pop(),
            androiddisabledsubmitbutton: false,
            original_json_file: data.location,
          });
          callback();
        }
      })
      .catch((err) => {
        errortoast("Something went wrong. Try again after sometime.");
      });
  } else {
    callback();
  }
};
export const certificate = (params, state, callback) => {
  function dataURLtoFileoriginal(dataurl, filename) {
    var arr = dataurl.split(","),
      //mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: "application/p12" });
  }
  let originalfile = dataURLtoFileoriginal(params.original, "hello.p12");
  const oriconfig = {
    bucketName: process.env.REACT_APP_bucketName,
    dirName: params.original_path.replace(/\/$/, "") /* optional */,
    region: process.env.REACT_APP_region,
    accessKeyId: process.env.REACT_APP_accessKeyId,
    secretAccessKey: process.env.REACT_APP_secretAccessKey,
    s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
  };
  const ReactS3original = new CustomS3Client(oriconfig);
  const originalFileName = params.imgname;
  ReactS3original.uploadFile(originalfile, originalFileName)
    .then((data) => {
      if (data.status === 204) {
        state.setState({
          certificates3name: data.key.split("/").pop(),
          disabledsubmitbutton: false,
          original_cert_file: data.location,
        });
        callback();
      }
    })
    .catch((err) => {
      errortoast("Something went wrong. Try again after sometime.");
    });
};
export const provision1s3 = (params, state, callback) => {
  function dataURLtoFileoriginal(dataurl, filename) {
    var arr = dataurl.split(","),
      //mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: "application/mobileprovision" });
  }
  let originalfile = dataURLtoFileoriginal(params.original, "hello.p12");
  const oriconfig = {
    bucketName: process.env.REACT_APP_bucketName,
    dirName: params.original_path.replace(/\/$/, "") /* optional */,
    region: process.env.REACT_APP_region,
    accessKeyId: process.env.REACT_APP_accessKeyId,
    secretAccessKey: process.env.REACT_APP_secretAccessKey,
    s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
  };
  const ReactS3original = new CustomS3Client(oriconfig);
  const originalFileName = params.imgname;
  ReactS3original.uploadFile(originalfile, originalFileName)
    .then((data) => {
      if (data.status === 204) {
        state.setState({
          provision1s3name: data.key.split("/").pop(),
        });
        callback();
      }
    })
    .catch((err) => {
      errortoast("Something went wrong. Try again after sometime.");
    });
};
export const provision2s3 = (params, state, callback) => {
  function dataURLtoFileoriginal(dataurl, filename) {
    var arr = dataurl.split(","),
      //mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: "application/mobileprovision" });
  }
  let originalfile = dataURLtoFileoriginal(params.original, "hello.p12");
  const oriconfig = {
    bucketName: process.env.REACT_APP_bucketName,
    dirName: params.original_path.replace(/\/$/, "") /* optional */,
    region: process.env.REACT_APP_region,
    accessKeyId: process.env.REACT_APP_accessKeyId,
    secretAccessKey: process.env.REACT_APP_secretAccessKey,
    s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
  };
  const ReactS3original = new CustomS3Client(oriconfig);
  const originalFileName = params.imgname;
  ReactS3original.uploadFile(originalfile, originalFileName).then((data) => {
    if (data.status === 204) {
      state.setState({
        provision2s3name: data.key.split("/").pop(),
        disabledsubmitbutton: false,
        original_provision_file: data.location,
      });
      callback();
    }
  });
};

//iosbuilingapp Api
export const iosbuilingapp = (iosdata, state, checker) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(saveceritificate + "ios/save-signing-files", iosdata, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          submitval2: "Save",
          loader2: "d-none",
        });
        state.setState({
          saveclass: "d-none",
          buttonsaveclass: "d-block",
          updatedAt: res.data?.updated_at,
        });

        if (checker === "build") {
          state.finalbuild();
        } else {
          state.setState({ buildclass: "d-none", buttonbuildclass: "d-block" });
          if (checker === "generate-certificate") {
          } else {
            sucesstoast("The record has been saved.");
          }
          state.setState({
            allowBuildBtn: true,
            allowResetBtn: true,
          });
        }
      } else if (res.data.code === 400) {
        state.setState({ saveclass: "d-none", buttonsaveclass: "d-block" });
        state.setState({ buildclass: "d-none", buttonbuildclass: "d-block" });
        //admob app id validation
        if (res.data.data.admob_app_id !== undefined) {
          state.setState({
            allowSaveBtn: true,
            allowResetBtn: true,
            allowBuildBtn: true,

            errormodal: true,
            title: "Admob App ID",
            errormessage: res.data.data.admob_app_id,
            submitval: "Build App",

            loader: "d-none",
            loaderimage: require("../../assets/images/signup/Loader_GIF.gif")
              .default,
          });
        } else {
          state.setState({
            submitval2: "Save",
            loader2: "d-none",
          });
          state.setState({ buildclass: "d-none", buttonbuildclass: "d-block" });
          state.setState({ saveclass: "d-none", buttonsaveclass: "d-block" });
          state.setState({
            allowSaveBtn: true,
            allowResetBtn: true,
            allowBuildBtn: true,
          });
          errortoast("Oops! Something went wrong.");
        }
      } else {
        errortoast(res.data.data);
        state.setState({
          submitval2: "Save",
          loader2: "d-none",
        });
        state.setState({ buildclass: "d-none", buttonbuildclass: "d-block" });
        state.setState({ saveclass: "d-none", buttonsaveclass: "d-block" });
        state.setState({
          allowSaveBtn: true,
          allowResetBtn: true,
          allowBuildBtn: true,
        });
      }
    })
    .catch((err) => {
      
      state.setState({
        allowSaveBtn: true,
        allowResetBtn: true,
        allowBuildBtn: true,
      });
      state.setState({ saveclass: "d-none", buttonsaveclass: "d-block" });
      state.setState({ buildclass: "d-none", buttonbuildclass: "d-block" });
      // errortoast("Something went wrong");
    });
};
//androidbuilingapp
export const androidbuilingapp = (androiddata, state, checker) => {
  if (androiddata.instant_generate_signing_files === 1)
    clearInterval(state.interval);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(saveceritificate + "android/save-signing-files", androiddata, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        if (
          androiddata.instant_generate_signing_files === 0 &&
          (checker === undefined || checker === "build")
        ) {
          
          state.setState({
            submitval2: "Save",
            loader2: "d-none",
          });
          state.finalbuild();
        }
        if (checker === 2) {
          state.setState({
            rebuildclass: "d-none",
            buttonbuildclass: "d-block",
            allowBuildBtn: true,
            allowResetBtn: true,
            submitval2: "Save",
            loader2: "d-none",
          });
          sucesstoast("Saved Successfully");
        }
        if (
          androiddata.instant_generate_signing_files === 1 &&
          checker === undefined
        ) {
          state.setState({
            submitval2: "Save",
            loader2: "d-none",
            rebuildclass: "d-none",
            buttonbuildclass: "d-block",
          });
          state.waitfunction();
        }
      } else if (res.data.code === 400) {
        //admob app id validation
        if (res.data.data.admob_app_id !== undefined) {
          state.setState({
            allowSaveBtn: true,
            allowResetBtn: true,
            allowBuildBtn: true,
            rebuildclass: "d-none",
            buttonbuildclass: "d-block",

            errormodal: true,
            title: "Admob App ID",
            errormessage: res.data.data.admob_app_id,
            androidsubmitval: "Build App",
            androidloader: "d-none",
            androidloaderimage:
              require("../../assets/images/signup/Loader_GIF.gif").default,
            loader2: "d-none",

            submitval2: "Save",
          });
        } else {
          state.setState({
            allowSaveBtn: true,
            allowResetBtn: true,
            allowBuildBtn: true,
            rebuildclass: "d-none",
            buttonbuildclass: "d-block",
            androidsubmitval: "Build App",
            androidloader: "d-none",
            androidloaderimage:
              require("../../assets/images/signup/Loader_GIF.gif").default,
            loader2: "d-none",
            submitval2: "Save",
          });
          errortoast("Oops! Something went wrong.");
        }
      } else if (res.data.code === 405) {
        state.setState({
          allowSaveBtn: true,
          allowResetBtn: true,
          allowBuildBtn: true,
          rebuildclass: "d-none",
          buttonbuildclass: "d-block",
          androidsubmitval: "Build App",
          androidloader: "d-none",
          androidloaderimage:
            require("../../assets/images/signup/Loader_GIF.gif").default,
          loader2: "d-none",
          submitval2: "Save",
        });
        errortoast(res.data.data);
      }
    })
    .catch((err) => {
      state.setState({
        submitval2: "Save",
        loader2: "d-none",
        rebuildclass: "d-none",
        buttonbuildclass: "d-block",
      });
      state.setState({
        allowSaveBtn: true,
        allowResetBtn: true,
        allowBuildBtn: true,
      });
      
      // errortoast("Something went wrong");
    });
};

//Ios build Reset button

export const iosAndroidBuildReset = (param, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(saveceritificate + "delete-signing-files", param, {
      headers: headers,
    })
    .then((res) => {
      if (res.status === 200) {
        sucesstoast("Reset Success");
        // window.location.reload();
        state.setState({
          resetclass: "d-none",
          buttonresetclass: "d-block",
          openDownloadPopup: false,
        });

        state.setState({
          submitval: "Build App",
          loader: "d-none",
          resetclass: "d-none",
          buttonresetclass: "d-block",
          allowSaveBtn: true,

          allowBuildBtn: true,
        });
      } else {
        state.setState({
          resetclass: "d-none",
          buttonresetclass: "d-block",
          openDownloadPopup: false,
        });
        state.setState({
          allowSaveBtn: true,
          allowResetBtn: true,
          allowBuildBtn: true,
          resetclass: "d-none",
          buttonresetclass: "d-block",
          submitval3: "Reset",
          loader3: "d-none",
        });
        errortoast("Something went wrong");
      }
    })
    .catch((err) => {
      state.setState({
        resetclass: "d-none",
        buttonresetclass: "d-block",
        openDownloadPopup: false,
      });
      state.setState({
        allowSaveBtn: true,
        allowResetBtn: true,
        allowBuildBtn: true,
        resetclass: "d-none",
        buttonresetclass: "d-block",
        submitval3: "Reset",
        loader3: "d-none",
      });
      errortoast("Something went wrong");
    });
};

//userLogout

export const userLogout = (param, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "logout", param, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        let myItem = localStorage.getItem("buildversion");
        let myItemversion = localStorage.getItem("Apiversion");
        let previous_location = localStorage.getItem("Previouslocation");
        let agencyname = localStorage.getItem("agencyname");
        localStorage.clear();
        localStorage.setItem("Previouslocation", previous_location);
        localStorage.setItem("buildversion", myItem);
        localStorage.setItem("Apiversion", myItemversion);
        localStorage.setItem("agencyname", agencyname);
        window.location.pathname = "/sign-in";
      }
    })
    .catch((error) =>{});
};
//userLogout

export const headeruserLogout = (param, setlogoutspinner) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "logout", param, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        // setlogoutspinner(false);
        let myItem = localStorage.getItem("buildversion");
        let myItemversion = localStorage.getItem("Apiversion");
        let previous_location = localStorage.getItem("Previouslocation");
        localStorage.clear();
        localStorage.setItem("Previouslocation", previous_location);
        localStorage.setItem("buildversion", myItem);
        localStorage.setItem("Apiversion", myItemversion);
        window.location.pathname = "/sign-in";
      }
    })
    .catch((error) =>{});
};

//Premium Notificatons Get Request

export const premiumNotificationApi = (state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(process.env.REACT_APP_API_URL + "api/notifications", {
      headers: headers,
    })
    .then((res) => {
      
      if (res.data.code === 200) {
        if (res.data.data.length === 0) {
          state.setState({
            premium_notification: false,
          });
          return;
        }
        state.setState({
          title: res.data.data[0].title,
          description: res.data.data[0].description,
          hyperlink: res.data.data[0].hyperlink,
          notification_id: res.data.data[0].id,
          call_to_action: res.data.data[0].call_to_action,
          spinner: false,
        });
      }
    })
    .catch((err) => {
      if (err.response != undefined && err.response.status === 401) {
        let previous_location = localStorage.getItem("Previouslocation");
        localStorage.clear();
        localStorage.setItem("Previouslocation", previous_location);
        // window.location.href = "/";
      }
      if (err.message != undefined && err.message == "Network Error") {
        let previous_location = localStorage.getItem("Previouslocation");
        localStorage.clear();
        localStorage.setItem("Previouslocation", previous_location);
        // window.location.href = "/";
      }
    });
};

//Premium Notificatons Post Request

export const premiumNotificationPost = (state, id) => {
  const param = {
    notification_id: id,
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(process.env.REACT_APP_API_URL + "api/hide-notifications", param, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        // window.location.reload();
        state.setState({
          premium_notification: false,
        });
      } else {
        throw new Error("Something went wrong");
      }
    })
    .catch((err) => {
      errortoast("Something went wrong");
    });
};

//Login Admin as a Client

export const allowAdminAsClient = (state, email, loginData) => {
  let token = "";
  const param = {
    email: email,
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(process.env.REACT_APP_API_URL + `api/app/login-as-client`, param, {
      headers: headers,
    })
    .then((res1) => {
      if (res1.data.code === 200) {
        token = res1.data.token.access_token;

        const param2 = {
          check: "",
        };

        axios
          .post(Url + "logout", param2, {
            headers: headers,
          })
          .then((res) => {
            if (res.status === 200) {
              state.setState({
                loggedspin: false,
              });

              localStorage.setItem(
                "roleid",
                loginData.role_id !== undefined ? loginData.role_id : 2
              );

              localStorage.setItem("amstoken", token);
              localStorage.setItem("customer_type", loginData.customer_type);
              localStorage.setItem("user_profile", loginData.profile_image);
              localStorage.setItem("workspaceId", "");
              localStorage.setItem("workspaceName", "");
              localStorage.setItem("loginasclient", "1");
              localStorage.setItem("logged_in_user_role_id", 1);
              localStorage.setItem("user_name", loginData.name);
              localStorage.setItem("user_id", loginData.id);
              localStorage.setItem("logged_in_user_id", loginData.id);
              localStorage.setItem("originalid", loginData.id);
              window.location.href = "/apps";
            }
          });
      } else {
        errortoast("Something went wrong");
      }
    })
    .catch((err) => {
      errortoast("Something went wrong");
    });
};

//Login Admin as a Client
export const headerallowAdminAsClient = (
  e,
  email,
  loginData,
  setloggedspin
) => {
  let token = "";
  const param = {
    email: email,
    id: loginData.id,
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(process.env.REACT_APP_API_URL + `api/app/login-as-client`, param, {
      headers: headers,
    })
    .then((res1) => {
      if (res1.data.code === 200) {
        token = res1.data.token.access_token;
        const param2 = {
          check: "",
        };
        axios
          .post(Url + "logout", param2, {
            headers: headers,
          })
          .then((res) => {
            if (res.status === 200) {
              localStorage.setItem(
                "roleid",
                loginData.role_id !== undefined ? loginData.role_id : 2
              );
              // setloggedspin(false);
              localStorage.setItem("amstoken", token);
              localStorage.setItem("customer_type", loginData.customer_type);
              localStorage.setItem("user_profile", loginData.profile_image);
              localStorage.setItem("workspaceId", "");
              localStorage.setItem("workspaceName", "");
              localStorage.setItem("workspaceImage", "");
              localStorage.setItem("loginasclient", 1);
              localStorage.setItem("logged_in_user_role_id", 1);
              localStorage.setItem("user_name", loginData.name);
              localStorage.setItem("user_id", loginData.id);
              localStorage.setItem("logged_in_user_id", loginData.id);
              localStorage.setItem("originalid", loginData.id);
              window.location.href = "/home";
            }
          })
          .catch((error) => {
            // errortoast("Something went wrong. Try again after sometime.");
          });
      } else {
        errortoast("Something went wrong");
      }
    })
    .catch((err) => {
      errortoast("Something went wrong");
    });
};

// Connectivity Monitor Post

export const connectivityMonitor = (param, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(dashboardurl + "save-connectivity-monitor", param, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          loader2: "d-none",
          submitval: "Save",
          updated_at: res.data.data.updated_at,
        });
        sucesstoast("The record has been saved.");
      } else {
        errortoast("Oh no! Something’s not right.");
      }
    })
    .catch((err) => {
      errortoast("Oh no! Something’s not right.");
    });
};

// Connectivity Monitor Get

export const getConnectivityMonitor = (state, appId, callback) => {
  let returnSelectedGMTHours = (selectedHour) => {
    const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
    const gmtHour = (selectedHour - timezoneOffsetInMinutes / 60 + 24) % 24;

    return Math.ceil(gmtHour).toString() == "24"
      ? "0"
      : Math.ceil(gmtHour).toString();
  };

  let returnDay = (day) => {
    let finalDay = "";

    switch (day) {
      case "0":
        finalDay = "Monday";
        break;
      case "1":
        finalDay = "Tuesday";
        break;
      case "2":
        finalDay = "Wednesday";
        break;
      case "3":
        finalDay = "Thursday";
        break;
      case "4":
        finalDay = "Friday";
        break;
      case "5":
        finalDay = "Saturday";
        break;
      case "6":
        finalDay = "Sunday";
        break;
    }
    return finalDay;
  };
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(dashboardurl + "get-connectivity-monitor/" + appId, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        if (res.data.data !== null) {
          let finalTimeSelectionArr = [];
          let finalDaySelectionArr = [];
          let finalUserSelectionArr = [];
          let finalTimeCheckedArr = new Array(
            state.state.timeOptions.length
          ).fill(false);
          let finalDayCheckedArr = new Array(
            state.state.dayOptions.length
          ).fill(false);
          let finalUserCheckedArr = [];
          if (res.data.data.time) {
            finalTimeSelectionArr =
              res.data.data.time &&
              res.data.data.time.map((res) => {
                let finalObj = {
                  label: "",
                  value: "",
                };
                for (let k = 0; k < state.state.timeOptions.length; k++) {
                  if (
                    returnSelectedGMTHours(parseInt(res)) ==
                    state.state.timeOptions[k].value
                  ) {
                    finalObj.label = state.state.timeOptions[k].label;
                    finalObj.value = state.state.timeOptions[k].value;
                  }
                }

                return finalObj;
              });

            state.state.timeOptions &&
              state.state.timeOptions.map((res, indx) => {
                for (let i = 0; i < finalTimeSelectionArr.length; i++) {
                  if (res.value === finalTimeSelectionArr[i].value) {
                    finalTimeCheckedArr[indx] = true;
                  }
                }
              });
          } else {
            finalTimeCheckedArr[8] = true;
          }
          if (res.data.data.notify_users !== undefined) {
            finalUserCheckedArr = new Array(
              res.data.data.notify_users.length
            ).fill(false);
            finalUserSelectionArr =
              res.data.data.notify_users &&
              res.data.data.notify_users.map((row) => {
                let newObj = { profile_name: "", id: "", profile_image: "" };
                newObj.profile_name = row.name;
                newObj.id = row.id;
                return newObj;
              });
            

            //   state.state.usersData && state.state.usersData.map((res,indx)=>{
            //    for(let i=0; i<finalUserSelectionArr.length; i++) {
            //   if(res.user_id==finalUserSelectionArr[i].id){
            //    finalUserCheckedArr[indx]=true;
            //   }
            //  }
            //  })
          }

          state.setState({
            updated_at: res.data.data.updated_at,
            newSpinner: false,
            isSelectedUser:
              res.data.data.notify_failures_to_user_check === undefined
                ? false
                : res.data.data.notify_failures_to_user_check === 1
                ? true
                : false,
            dropDownVal:
              res.data.data.frequency === 0
                ? "Daily"
                : res.data.data.frequency === 1
                ? "Weekly"
                : "Monthly",
            toggle: res.data.data.status,
            timeSelectionArr: res.data.data.time
              ? finalTimeSelectionArr
              : [{ label: "9:00 AM", value: "9" }],
            isEmail:
              res.data.data.notify_failures_on_email === undefined
                ? false
                : res.data.data.notify_failures_on_email === 1
                ? true
                : false,
            userSelectionArr: finalUserSelectionArr,
            timeCheckedArr: finalTimeCheckedArr,
            userCheckedArr: finalUserCheckedArr,
          });

          if (res.data.data.frequency === 1) {
            if (Array.isArray(res.data.data.day)) {
              finalDaySelectionArr =
                res.data.data.day &&
                res.data.data.day.map((day) => {
                  return returnDay(day);
                });
            } else {
              finalDaySelectionArr = [returnDay(res.data.data.day.toString())];
            }
            
            state.state.dayOptions &&
              state.state.dayOptions.map((res, indx) => {
                for (let i = 0; i < finalDaySelectionArr.length; i++) {
                  if (res === finalDaySelectionArr[i]) {
                    finalDayCheckedArr[indx] = true;
                  }
                }
              });
            state.setState({
              dayCheckedArr: finalDayCheckedArr,
              // day: res.data.data.day,
              daySelectionArr: finalDaySelectionArr,
            });
          }
          if (res.data.data.frequency === 2) {
            state.setState({
              date: Array.isArray(res.data.data.day)
                ? res.data.data.day
                : [res.data.data.day.toString()],
            });
          }
        } else {
          state.setState({
            newSpinner: false,
            timeCheckedArr: state.state.timeOptions.map((val) =>
              val.value === "9" ? true : false
            ),
            dayCheckedArr: state.state.dayOptions.map((val) =>
              val === "Monday" ? true : false
            ),
          });
        }
        callback();
      }
    })
    .catch((err) => {
      state.setState({
        // newSpinner: false,
        timeCheckedArr: state.state.timeOptions.map((val) =>
          val === "9" ? true : false
        ),
        dayCheckedArr: state.state.dayOptions.map((val) =>
          val === "Monday" ? true : false
        ),
      });
      
    });
};
// prorated calculation
export const proratedCalculation = (param, state, runSpinner) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(dashboardurl + "app/prorated-price", param, {
      headers: headers,
    })
    .then((res) => {
      if (res.status === 200) {
        if (runSpinner && param.is_support_plan === 0) {
          state.setState({
            checkspinner: false,
          });
        }
        state.setState({
          spinner: false,
          amount_to_pay:
            res.data.amount_to_pay < 0 ? 0 : res.data.amount_to_pay,
          // prorated_credits: res.data.prorated_credits,
          purchase_amount: res.data.purchase_amount,
          applied_credits:
            res.data.applied_credits < 0 ? 0 : res.data.applied_credits,
          currency1: res.data.currency,
          // user_credits: res.data.user_credits,
        });
        // sucesstoast("Your data added successfully");
      } else {
        // errortoast("Something went wrong");
      }
    })
    .catch((err) => {
      
    });
};

const returnSelectedModuleName = (module_name, row, state) => {
  let label = "";
  if (module_name === "chat") {
    label = "Chat";
  } else if (module_name === "main_menu") {
    label = "Menu";
  } else if (module_name === "settings") {
    label = "Settings";
  } else if (module_name === "web_view") {
    label = "Web view";
  } else if (module_name === "home") {
    label = "Home screen";
  } else if (module_name === "profile") {
    label = "Profile";
  } else if (module_name === "product_categories") {
    label = "Product categories";
  } else if (module_name === "product_search") {
    label = "Product search";
  } else if (module_name === "cart") {
    label = "Cart";
  } else if (module_name === "pages") {
    label = "Pages";
  } else if (module_name === "orders") {
    label = "Orders";
  } else if (module_name === "cms_post") {
    label = "Posts";
  } else if (module_name === "cms_page") {
    label = "Pages";
  } else if (module_name === "cms_category") {
    label = "Post categories";
  } else if (module_name === "cms_tag") {
    label = "Post Tags";
  } else if (module_name === "email") {
    label = "Email";
  } else if (module_name === "phone") {
    label = "Phone";
  } else if (module_name === "share") {
    label = "Share";
  } else {
    state.state.mergeArray &&
      state.state.mergeArray.length > 0 &&
      state.state.mergeArray.map((value, k) => {
        if (
          row.temp_item_type +
            row.temp_slug +
            row.temp_rest_base +
            row.temp_post_type ===
          value.type + value.slug + value.rest_base + value.post_type
        ) {
          label = value.name;
        }
      });
  }

  return label;
};

//Custom post and taxonomy based on Rest Base

//Custom post and taxonomy based on Rest Base

export const restBaseTaxonomyapi = (rest_base, state, indx, page) => {
  state.setState({
    pageNo: page ? page : 1,
    paginationSpinner: page ? true : false,
    isAllowPagination: page ? state.state.isAllowPagination : true,
  });
  let newBottomMenuArr = [...state.state.bottom_menu_items];
  let newData = [];
  const headers = {
    "Content-Type": "application/json",
    "X-App-Device-Type": "ios",
    "X-App-Code-Ver": localStorage.getItem("Apiversion"),
  };

  axios
    .get(
      `${RedirectUrl}${state.state.storeUrl.replace(
        "www.",
        ""
      )}/wp-json/wp/v2/${rest_base}?page=${page ? page : 1}%26per_page=99`,

      {
        headers: headers,
        data: {},
      }
    )
    .then((res) => {
      if (res.status === 200) {
        state.setState({
          paginationSpinner: false,
          isAllowPagination:
            res.data && res.data.length > 0 && res.data.length >= 99
              ? true
              : false,
        });
        newData = res.data;
        newBottomMenuArr[indx].subdropdownData =
          page && page > 1
            ? newBottomMenuArr[indx].subdropdownData.concat(newData)
            : newData;
        newBottomMenuArr[indx].subData =
          page && page > 1
            ? newBottomMenuArr[indx].subData.concat(newData)
            : newData;
        newBottomMenuArr[indx].temp_subData =
          page && page > 1
            ? newBottomMenuArr[indx].temp_subData.concat(newData)
            : newData;
        newBottomMenuArr[indx].temp_breadcrumbArr = [];
        newBottomMenuArr[indx].breadcrumbArr = [];
        newBottomMenuArr[indx].subdropdownSpinner = false;
        newBottomMenuArr[indx].temp_showL1Options = false;

        newBottomMenuArr[indx].temp_module_label = returnSelectedModuleName(
          newBottomMenuArr[indx].temp_item_type,
          newBottomMenuArr[indx],
          state
        );

        newBottomMenuArr[indx].module_label = returnSelectedModuleName(
          newBottomMenuArr[indx].item_type,
          newBottomMenuArr[indx],
          state
        );
        state.setState({
          bottom_menu_items: newBottomMenuArr,
          spinnerForsubDrop: false,
        });
      } else {
        newBottomMenuArr[indx].subdropdownData = newData;
        newBottomMenuArr[indx].subData = newData;
        newBottomMenuArr[indx].temp_subData = newData;
        newBottomMenuArr[indx].temp_breadcrumbArr = [];
        newBottomMenuArr[indx].breadcrumbArr = [];
        newBottomMenuArr[indx].subdropdownSpinner = false;
        newBottomMenuArr[indx].showL1Options = true;
        newBottomMenuArr[indx].temp_showL1Options = true;
        newBottomMenuArr[indx].temp_module_label = returnSelectedModuleName(
          newBottomMenuArr[indx].temp_item_type,
          newBottomMenuArr[indx],
          state
        );
        newBottomMenuArr[indx].module_label = returnSelectedModuleName(
          newBottomMenuArr[indx].item_type,
          newBottomMenuArr[indx],
          state
        );

        state.setState({
          bottom_menu_items: newBottomMenuArr,
          spinnerForsubDrop: false,
          paginationSpinner: false,
          isAllowPagination: false,
        });
      }
    })
    .catch((err) => {
      newBottomMenuArr[indx].subdropdownData = newData;
      newBottomMenuArr[indx].subData = newData;
      newBottomMenuArr[indx].temp_subData = newData;
      newBottomMenuArr[indx].temp_breadcrumbArr = [];
      newBottomMenuArr[indx].breadcrumbArr = [];
      newBottomMenuArr[indx].subdropdownSpinner = false;
      // newBottomMenuArr[indx].showL1Options = true;
      // newBottomMenuArr[indx].temp_showL1Options = true;
      newBottomMenuArr[indx].temp_module_label = returnSelectedModuleName(
        newBottomMenuArr[indx].temp_item_type,
        newBottomMenuArr[indx],
        state
      );
      newBottomMenuArr[indx].module_label = returnSelectedModuleName(
        newBottomMenuArr[indx].item_type,
        newBottomMenuArr[indx],
        state
      );

      state.setState({
        bottom_menu_items: newBottomMenuArr,
        spinnerForsubDrop: false,
        paginationSpinner: false,
        isAllowPagination: false,
      });
    });
  // .then((res) => {

  //   var typedata = (typeof res.data);
  //   if (res.status === 200)
  //   {
  //     
  //     state.setState({
  //       isAll: false,
  //       spinnerForsubDrop: false,
  //       isAllForAddItem: false,
  //     });
  //     if (res.data.length === 0)
  //     {
  //       state.setState({
  //         // subdropdownCheck: false,
  //       });
  //     }
  //     //sucesstoast("Your data added successfully");
  //     if (i === undefined)
  //     {
  //       if (from === "extrasetting") {
  //         state.setState({
  //           extraSetting: res.data,
  //         });
  //       }

  //       if(typedata != 'string')
  //       {
  //         state.setState({
  //           subdropdowndata: res.data,
  //           setting4: res.data,
  //           spinnerForAddItem: false,
  //           spinnerForsubDrop: false,
  //         });
  //       }
  //       else
  //       {
  //         state.setState({
  //           setting4: res.data,
  //           spinnerForAddItem: false,
  //           spinnerForsubDrop: false,
  //         });
  //       }
  //     }
  //     else
  //     {
  //       state.setState({
  //         spinnerForsubDrop: false,
  //       });
  //       if (i === 0)
  //       {
  //         if(typedata != 'string')
  //         {
  //             state.setState({
  //             setting0: res.data,
  //             subdropdowndata: res.data,
  //           });
  //         }
  //       }
  //       else if (i === 1)
  //       {
  //         if(typedata != 'string')
  //         {
  //           state.setState({
  //             setting1: res.data,
  //             subdropdowndata: res.data,
  //           });
  //         }
  //       } else if (i === 2) {
  //         if(typedata != 'string')
  //         {
  //           state.setState({
  //             setting2: res.data,
  //             subdropdowndata: res.data,
  //           });
  //         }
  //       } else if (i === 3) {
  //         if(typedata != 'string')
  //         {
  //           state.setState({
  //             setting3: res.data,
  //             subdropdowndata: res.data,
  //           });
  //         }
  //       } else if (i === 4) {
  //         if(typedata != 'string')
  //         {
  //           state.setState({
  //             setting4: res.data,
  //             subdropdowndata: res.data,
  //           });
  //         }
  //       }
  //       else{
  //         if(typedata != 'string')
  //         {
  //           state.setState({
  //             // setting4: res.data,
  //             subdropdowndata: res.data,
  //           });
  //         }
  //       }
  //     }
  //   }
  // })
  // .catch((err) => {
  //   state.setState({
  //     // subdropdownCheck: false,
  //     spinnerForsubDrop: false,
  //     isAll: false,
  //     subdropdownCheck: false,
  //     isAllForAddItem: false,
  //   });
  //   // }, 2000);
  // });
};

export const productCategoriesTreeAPIForBtm = (state, indx, from) => {
  let newArrForPreview = [];
  let newBreadcrumb = [];
  let newSubData = [];
  let parentId = "";
  let allPages = [];
  let newBottomMenuArr = [...state.state.bottom_menu_items];

  function recursForBreadcrumb(arr) {
    arr.map((eachPage) => {
      let obj = {
        name: "",
        id: "",
      };

      if (eachPage.term_id == parentId) {
        parentId = eachPage.parent;
        obj.id = eachPage.term_id.toString();
        obj.name = eachPage.name;
        newBreadcrumb.push(obj);
      }

      if (eachPage.children !== undefined && eachPage.children.length > 0) {
        // if parent has childrenrens
        recursForBreadcrumb(eachPage.children);
      }

      // return arr;
    });
  }

  function recursData(arr) {
    arr.map((eachPage) => {
      if (
        newBreadcrumb.length > 0 &&
        eachPage.parent == newBreadcrumb[newBreadcrumb.length - 1].id
      ) {
        newSubData.push(eachPage);
      }

      if (eachPage.children !== undefined && eachPage.children.length > 0) {
        // if parent has childrenrens
        recursData(eachPage.children);
      }

      // return arr;
    });
  }

  function recurs(arr) {
    arr &&
      arr.map((eachPage) => {
        recursForBreadcrumb(allPages);
        if (eachPage.children !== undefined && eachPage.children.length > 0) {
          recurs(eachPage.children);
        }
      });
  }
  const headers = {
    "Content-Type": "application/json",
    // Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(`${state.state.storeUrl}/wp-json/wc/v3/ams-categories`, {
      headers: headers,
    })
    .then((res) => {
      if (res.status === 200) {
        allPages = res.data;

        parentId = newBottomMenuArr[indx].invidual_selected_item_id;
        recurs(allPages);

        newBreadcrumb.splice(0, 1);
        newBreadcrumb.reverse();

        recursData(allPages);
        newBottomMenuArr[indx].subdropdownData = allPages;
        newBottomMenuArr[indx].subData =
          newSubData.length === 0 ? allPages : newSubData;
        newBottomMenuArr[indx].temp_subData =
          newSubData.length === 0 ? allPages : newSubData;
        newBottomMenuArr[indx].temp_breadcrumbArr = newBreadcrumb;
        newBottomMenuArr[indx].breadcrumbArr = newBreadcrumb;
        newBottomMenuArr[indx].customAppPagedData = newArrForPreview;
        newBottomMenuArr[indx].subdropdownSpinner = false;
        // newBottomMenuArr[indx].showL1Options =false;
        // from==="not-initial"?false:
        // ( newBottomMenuArr[indx].breadcrumbArr.length > 0 || (newBottomMenuArr[indx].item &&newBottomMenuArr[indx].item!=="all" ) )?false : true;
        newBottomMenuArr[indx].temp_showL1Options = false;
        // from==="not-initial"?false:
        // ( newBottomMenuArr[indx].breadcrumbArr.length > 0 || (newBottomMenuArr[indx].item &&newBottomMenuArr[indx].item!=="all" ) )?false : true;
        newBottomMenuArr[indx].temp_module_label = returnSelectedModuleName(
          newBottomMenuArr[indx].temp_item_type,
          newBottomMenuArr[indx],
          state
        );

        newBottomMenuArr[indx].module_label = returnSelectedModuleName(
          newBottomMenuArr[indx].item_type,
          newBottomMenuArr[indx],
          state
        );
        state.setState({
          bottom_menu_items: newBottomMenuArr,
          spinnerForsubDrop: false,
        });
      } else {
        let newBottomMenuArr = [...state.state.bottom_menu_items];
        newBottomMenuArr[indx].subdropdownData = [];
        newBottomMenuArr[indx].subData = [];
        newBottomMenuArr[indx].temp_subData = [];
        newBottomMenuArr[indx].temp_breadcrumbArr = [];
        newBottomMenuArr[indx].breadcrumbArr = [];
        newBottomMenuArr[indx].subdropdownSpinner = false;
        newBottomMenuArr[indx].showL1Options = true;
        newBottomMenuArr[indx].temp_showL1Options = true;
        newBottomMenuArr[indx].temp_module_label = returnSelectedModuleName(
          newBottomMenuArr[indx].temp_item_type,
          newBottomMenuArr[indx],
          state
        );
        newBottomMenuArr[indx].module_label = returnSelectedModuleName(
          newBottomMenuArr[indx].item_type,
          newBottomMenuArr[indx],
          state
        );
        state.setState({
          bottom_menu_items: newBottomMenuArr,
          spinnerForsubDrop: false,
        });
      }
    })
    .catch((err) => {
      // errortoast("Oh no! Something’s not right.");
      let newBottomMenuArr = [...state.state.bottom_menu_items];
      newBottomMenuArr[indx].subdropdownData = [];
      newBottomMenuArr[indx].subData = [];
      newBottomMenuArr[indx].temp_subData = [];
      newBottomMenuArr[indx].subdropdownSpinner = false;
      newBottomMenuArr[indx].showL1Options = true;

      newBottomMenuArr[indx].temp_showL1Options = true;

      newBottomMenuArr[indx].temp_breadcrumbArr = [];
      newBottomMenuArr[indx].breadcrumbArr = [];
      newBottomMenuArr[indx].temp_module_label = returnSelectedModuleName(
        newBottomMenuArr[indx].temp_module_name
      );
      newBottomMenuArr[indx].module_label = returnSelectedModuleName(
        newBottomMenuArr[indx].item_type,
        newBottomMenuArr[indx],
        state
      );
      state.setState({
        bottom_menu_items: newBottomMenuArr,
        spinnerForsubDrop: false,
      });
      
    });
};

export const restBaseTaxonomyapiForMenu = (
  rest_base,
  state,
  menuId,
  menuType,
  page
) => {
  state.setState({
    pageNo: page ? page : 1,
    paginationSpinner: page ? true : false,
    isAllowPagination: page ? state.state.isAllowPagination : true,
  });
  let newBottomMenuArr = [];
  if (menuType === "pre") {
    newBottomMenuArr = [...state.state.childrendata];
  } else {
    newBottomMenuArr = [...state.state.secmenuData];
  }
  let newData = [];

  function recursForMenuData(arr, apiData) {
    arr.map((eachPage) => {
      if (eachPage.id == menuId) {
        // eachPage.subdropdownData = page?eachPage.subdropdownData.concat(newData) :newData;
        // eachPage.subData = page?eachPage.subData.concat(newData) :newData;
        // eachPage.temp_subData = page?eachPage.temp_subData.concat(newData) :newData;

        eachPage.subdropdownData =
          page && page > 1 ? eachPage.subdropdownData.concat(newData) : newData;
        eachPage.subData =
          page && page > 1 ? eachPage.subData.concat(newData) : newData;
        eachPage.temp_subData =
          page && page > 1 ? eachPage.temp_subData.concat(newData) : newData;

        eachPage.temp_breadcrumbArr = [];
        eachPage.breadcrumbArr = [];
        eachPage.subdropdownSpinner = false;
        eachPage.temp_showL1Options = false;

        eachPage.temp_module_label = returnSelectedModuleName(
          eachPage.temp_item_type,
          eachPage,
          state
        );

        eachPage.module_label = returnSelectedModuleName(
          eachPage.item_type,
          eachPage,
          state
        );
      }

      if (eachPage.children !== undefined && eachPage.children.length > 0) {
        recursForMenuData(eachPage.children, apiData);
      }

      // return arr;
    });
  }

  const headers = {
    "Content-Type": "application/json",
    "X-App-Device-Type": "ios",
    "X-App-Code-Ver": localStorage.getItem("Apiversion"),
  };

  axios
    .get(
      `${RedirectUrl}${state.state.storeUrl.replace(
        "www.",
        ""
      )}/wp-json/wp/v2/${rest_base}?page=${page ? page : 1}%26per_page=99`,
      {
        headers: headers,
        data: {},
      }
    )
    .then((res) => {
      if (res.status === 200) {
        newData = res.data;
        state.setState({
          paginationSpinner: false,
          isAllowPagination:
            res.data && res.data.length > 0 && res.data.length > 98
              ? true
              : false,
        });
        recursForMenuData(
          menuType === "pre"
            ? state.state.childrendata
            : state.state.secmenuData,
          newData
        );
        if (menuType === "pre") {
          state.setState({
            childrendata: newBottomMenuArr,
            spinnerForL2: false,
          });
        } else {
          state.setState({
            secmenuData: newBottomMenuArr,
            spinnerForL2: false,
          });
        }
      } else {
        recursForMenuData(
          menuType === "pre"
            ? state.state.childrendata
            : state.state.secmenuData,
          newData
        );
        state.setState({
          paginationSpinner: false,
          isAllowPagination: false,
        });
        if (menuType === "pre") {
          state.setState({
            childrendata: newBottomMenuArr,
            spinnerForL2: false,
          });
        } else {
          state.setState({
            secmenuData: newBottomMenuArr,
            spinnerForL2: false,
          });
        }
      }
    })
    .catch((err) => {
      recursForMenuData(
        menuType === "pre" ? state.state.childrendata : state.state.secmenuData,
        newData
      );
      state.setState({
        paginationSpinner: false,
        isAllowPagination: false,
      });
      if (menuType === "pre") {
        state.setState({
          childrendata: newBottomMenuArr,
          spinnerForL2: false,
        });
      } else {
        state.setState({
          secmenuData: newBottomMenuArr,
          spinnerForL2: false,
        });
      }
      
    });
};

export const postCategoriesTreeAPI = (state) => {
  // let subdropdowndata=[];
  const headers = {
    "Content-Type": "application/json",
    // Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(`${state.state.storeUrl}/wp-json/wc/v3/ams-post-categories`, {
      headers: headers,
    })
    .then((res) => {
      if (res.status === 200) {
        let mapData = res.data;
        function recurs(mapData) {
          mapData.map((row) => {
            row["status"] = 0;
            delete row["term_taxonomy_id"];
            delete row["term_group"];
            delete row["count"];
            delete row["depth"];
            delete row["filter"];
            if (row.children.length > 0) {
              mapData = row.children;
              recurs(mapData);
            }
          });
        }
        recurs(mapData);
        state.setState({
          L2Data: res.data,
          sortedData: mapData,
          sortedData2: res.data,
          spinnerForL2: false,
          breadcrumbReserveData: [],
        });
      }
      // 
    })
    .catch((err) => {
      
    });
};

// export const productCategoriesTreeAPI = (state) => {
//   // let subdropdowndata=[];
//   const headers = {
//     "Content-Type": "application/json",
//     // Authorization: "Bearer " + localStorage.getItem("amstoken"),
//   };
//   axios
//     .get(`${state.state.storeUrl}/wp-json/wc/v3/ams-categories`, {
//       headers: headers,
//     })
//     .then((res) => {
//       if (res.status === 200) {
//         let mapData = res.data;
//         function recurs(mapData) {
//           mapData.map((row) => {
//             row["status"] = 0;
//             delete row["term_taxonomy_id"];
//             delete row["term_group"];
//             delete row["count"];
//             delete row["depth"];
//             delete row["filter"];
//             if (row.children.length > 0) {
//               mapData = row.children;
//               recurs(mapData);
//             }
//           });
//         }
//         recurs(mapData);
//         state.setState({
//           L2Data: res.data,
//           spinnerForL2: false,
//           sortedData: mapData,
//           sortedData2: res.data,
//           breadcrumbReserveData: [],
//         });
//       }
//     })
//     .catch((err) => {
//       
//     });
// };

export const productCategoriesTreeAPI = (state, menuId, indiID, menuType) => {
  state.setState({
    isAllowPagination: false,
  });
  let newArrForPreview = [];
  let newBreadcrumb = [];
  let newSubData = [];
  let parentId = "";
  let allPages = [];
  let newBottomMenuArr = [];
  if (menuType === "pre") {
    newBottomMenuArr = [...state.state.childrendata];
  } else {
    newBottomMenuArr = [...state.state.secmenuData];
  }

  function recursForMenuData(arr, apiData) {
    arr.map((eachPage) => {
      if (eachPage.id == menuId) {
        eachPage.subdropdownData = allPages;
        eachPage.subData = newSubData.length === 0 ? allPages : newSubData;
        eachPage.temp_subData = newSubData.length === 0 ? allPages : newSubData;
        eachPage.temp_breadcrumbArr = newBreadcrumb;
        eachPage.breadcrumbArr = newBreadcrumb;
        eachPage.subdropdownSpinner = false;
        // eachPage.showL1Options =false;
        // from==="not-initial"?false:
        // ( eachPage.breadcrumbArr.length > 0 || (eachPage.item &&eachPage.item!=="all" ) )?false : true;
        eachPage.temp_showL1Options = false;
        // from==="not-initial"?false:
        // ( eachPage.breadcrumbArr.length > 0 || (eachPage.item &&eachPage.item!=="all" ) )?false : true;

        eachPage.temp_module_label = returnSelectedModuleName(
          eachPage.temp_item_type,
          eachPage,
          state
        );

        eachPage.module_label = returnSelectedModuleName(
          eachPage.item_type,
          eachPage,
          state
        );
      }

      if (eachPage.children !== undefined && eachPage.children.length > 0) {
        recursForMenuData(eachPage.children, apiData);
      }

      // return arr;
    });
  }

  function recursForBreadcrumb(arr) {
    arr.map((eachPage) => {
      let obj = {
        name: "",
        id: "",
      };

      if (eachPage.term_id == parentId) {
        parentId = eachPage.parent;
        obj.id = eachPage.term_id.toString();
        obj.name = eachPage.name;
        newBreadcrumb.push(obj);
      }

      if (eachPage.children !== undefined && eachPage.children.length > 0) {
        // if parent has childrenrens
        recursForBreadcrumb(eachPage.children);
      }

      // return arr;
    });
  }

  function recursData(arr) {
    arr.map((eachPage) => {
      if (
        newBreadcrumb.length > 0 &&
        eachPage.parent == newBreadcrumb[newBreadcrumb.length - 1].id
      ) {
        newSubData.push(eachPage);
      }

      if (eachPage.children !== undefined && eachPage.children.length > 0) {
        // if parent has childrenrens
        recursData(eachPage.children);
      }

      // return arr;
    });
  }

  function recurs(arr) {
    arr &&
      arr.map((eachPage) => {
        recursForBreadcrumb(allPages);
        if (eachPage.children !== undefined && eachPage.children.length > 0) {
          recurs(eachPage.children);
        }
      });
  }
  const headers = {
    "Content-Type": "application/json",
    // Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(`${state.state.storeUrl}/wp-json/wc/v3/ams-categories`, {
      headers: headers,
    })
    .then((res) => {
      if (res.status === 200) {
        allPages = res.data;

        parentId = indiID;
        recurs(allPages);
        newBreadcrumb.splice(0, 1);
        newBreadcrumb.reverse();
        recursData(allPages);
        recursForMenuData(newBottomMenuArr, allPages);

        if (menuType === "pre") {
          state.setState({
            childrendata: newBottomMenuArr,
            spinnerForL2: false,
          });
        } else {
          state.setState({
            secmenuData: newBottomMenuArr,
            spinnerForL2: false,
          });
        }
      } else {
        recursForMenuData(newBottomMenuArr, allPages);
        if (menuType === "pre") {
          state.setState({
            childrendata: newBottomMenuArr,
            spinnerForL2: false,
          });
        } else {
          state.setState({
            secmenuData: newBottomMenuArr,
            spinnerForL2: false,
          });
        }
      }
    })
    .catch((err) => {
      // errortoast("Oh no! Something’s not right.");
      recursForMenuData(newBottomMenuArr, allPages);
      if (menuType === "pre") {
        state.setState({
          childrendata: newBottomMenuArr,
          spinnerForL2: false,
        });
      } else {
        state.setState({
          secmenuData: newBottomMenuArr,
          spinnerForL2: false,
        });
      }
      
    });
};

//create workspace

export const createWorkspace = (param, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(dashboardurl + "save-workspace", param, {
      headers: headers,
    })
    .then((res) => {
      if (res.status === 200) {
        // state.setState({
        //   submitVal: "CREATE WORKSPACE",
        //   loader: "d-none",
        // });
        const userinfo = JSON.parse(localStorage.getItem("user_info"));

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "create_workspace",
          user_email: userinfo.email,
          user_name: userinfo.name,
          user_id: userinfo.id,
          workspace_id: res.data.data.id,
          agency_id: localStorage.getItem("agency_id")
            ? parseInt(localStorage.getItem("agency_id"))
            : 1,
        });

        localStorage.setItem("workspaceName", res.data.data.workspace_name);
        localStorage.setItem("workspaceId", res.data.data.id);
        localStorage.setItem("workspaceImage", "");
        localStorage.setItem("logged_in_user_role_id", 1);
        // sucesstoast("Your workspace created successfully");

        window.location.href = `/workspace/dashboard/${btoa(res.data.data.id)}`;
      }
    })
    .catch((err) => {
      state.setState({
        submitVal: "CREATE WORKSPACE",
        loader: "d-none",
      });
      errortoast("Something went wrong");
    });
};

//update Workspace

export const updateWorkspace = (param, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(dashboardurl + "update-workspace", param, {
      headers: headers,
    })
    .then((res) => {
      if (res.status === 200) {
        state.setState({
          loader: "d-none",
          submitval: "Save",
        });
        localStorage.setItem("workspaceName", param.workspace_name);
        localStorage.setItem("workspaceId", param.workspace_id);
        localStorage.setItem("workspaceImage", param.profile);
        sucesstoast("Your workspace updated successfully");
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
      }
    })
    .catch((err) => {
      errortoast("Something went wrong");
      state.setState({
        loader: "d-none",
        submitval: "Save",
      });
    });
};

//leave Workspace

export const leaveWorkspace = (param, state) => {
  // 
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(dashboardurl + "workspace/user-management/leave-workspace", param, {
      headers: headers,
    })
    .then((res) => {
      if (res.status === 200) {
        state.setState({
          loader: "d-none",
          submitval: "Save",
        });
        sucesstoast("You Leave workspace successfully");
        setTimeout(() => {
          window.location.href = "/workspaces";
        }, 500);
      }
    })
    .catch((err) => {
      errortoast("Something went wrong");
      state.setState({
        loader: "d-none",
        submitval: "Save",
      });
    });
};

//Delete Workspace

export const deleteWorkspace = (param, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(dashboardurl + "delete-workspace", param, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          deleteLoader: "d-none",
          deleteLoaderimage: "d-none",
        });
        localStorage.removeItem("workspaceName");
        localStorage.removeItem("workspaceId");

        sucesstoast("Your workspace deleted successfully");
        setTimeout(() => {
          window.location.href = "/home";
        }, 2000);
      } else if (res.data.code === 204) {
        errortoast("Please downgrade your subscription");
        state.setState({
          deleteLoader: "d-none",
          deleteLoaderimage: "d-none",
        });
      } else {
        errortoast("Something went wrong");
        state.setState({
          deleteLoader: "d-none",
          deleteLoaderimage: "d-none",
        });
      }
    })
    .catch((err) => {
      state.setState({
        deleteLoader: "d-none",
        deleteLoaderimage: "d-none",
      });
      errortoast("Something went wrong");
      
    });
};

//get workspace

export const getWorkspace = (
  state,
  setData,
  setCheckWorkspaceData,
  limit,
  nextFrom,
  mergeAgencies
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(dashboardurl + `get-workspaces?limit=${limit}`, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        localStorage.setItem(
          "customer_billing_type",
          res.data.user_info.customer_billing_type
        );
        if (nextFrom === "billingDropdown" || nextFrom === "appsetting") {
          let userinfo = [];
          let useInfoObj = {
            id: res.data.user_info.id,
            workspace_name: res.data.user_info.name,
            profile_image: res.data.user_info.profile_image,
            type: "user",
          };

          userinfo.push(useInfoObj);

          let wpData2 = userinfo.concat(res.data.data);

          let wpData = res.data.data.filter((wp) => {
            if (
              parseInt(localStorage.getItem("website_technology")) === 4 ||
              parseInt(localStorage.getItem("website_technology")) === 5
            ) {
              if (
                wp.subscription.package_info.website_technology ===
                  parseInt(localStorage.getItem("website_technology")) ||
                wp.subscription.plan === "preview"
              ) {
                return wp;
              }
            } else if (
              parseInt(localStorage.getItem("website_technology")) === 2
            ) {
              if (wp.subscription.package_info.website_technology !== 3) {
                return wp;
              }
            } else if (
              parseInt(localStorage.getItem("website_technology")) === 3
            ) {
              if (
                wp.subscription.package_info.website_technology ===
                  parseInt(localStorage.getItem("website_technology")) ||
                wp.subscription.plan === "preview"
              ) {
                return wp;
              }
            }
          });
          // res.data.data;
          let invited_workspace = res.data.invited_workspace;

          if (useInfoObj.id === parseInt(localStorage.getItem("user_id"))) {
            wpData.push(useInfoObj);
          }

          wpData = wpData.concat(invited_workspace);

          wpData = wpData.filter((wp) => {
            if (
              (wp.role_id === 1 || wp.role_id === 2) &&
              wp.user_id === parseInt(localStorage.getItem("user_id"))
            ) {
              return wp;
            }
          });

          if (state.state.mergeArray !== undefined) {
            var wpData1 = wpData.concat(mergeAgencies);
            state.setState({
              mergeArray: wpData1,
            });
          }

          if (nextFrom !== "appsetting") {
            let ownerWorkspaces = invited_workspace.filter(
              (res) => res.role_id !== 3 && res.role_id !== 4
            );
            let children = wpData2.concat(ownerWorkspaces);
            if (state.state.mergeArray !== undefined) {
              var wpData21 = children.concat(mergeAgencies);
              state.setState({
                mergeArray: wpData21,
              });
            }
          }
        }
        if (state === "Mobilemenu") {
          setData(res.data);
          if (res.data.data.length === 0) {
            setCheckWorkspaceData(null);
          } else {
            setCheckWorkspaceData(res.data.data);
          }
        } else {
          state.setState({
            spinner: false,
            menuData: res.data.data.length === 0 ? [] : res.data.data,
            userInfo: res.data.user_info,
            // role_id: res.data.invited_workspace.role_id,
            invitedWorkspaceData: res.data.invited_workspace,
            total_workspace_count: res.data.total_workspace_count,
            total_personal_app_count: res.data.total_personal_app_count,
            total_personal_invited_app_count:
              res.data.total_personal_invited_app_count,
            user_role: res.data.user_role,
          });
        }
      }
      // else if (res.data.code === 203) {
      // errortoast("Session expired ! login again");

      // setTimeout(() => {
      // state.props.history.push("/sign-in");
      // window.location.href = "/sign-in";
      // }, 2000);
      // }
    })
    .catch((err) => {
      
      if (err.response != undefined && err.response.status === 401) {
        let previous_location = localStorage.getItem("Previouslocation");
        localStorage.clear();
        localStorage.setItem("Previouslocation", previous_location);
        // window.location.href = "/";
      }
      if (err.message != undefined && err.message == "Network Error") {
        let previous_location = localStorage.getItem("Previouslocation");
        localStorage.clear();
        localStorage.setItem("Previouslocation", previous_location);
        // window.location.href = "/";
      }
    });
};

//getagency

/********Get All Agency List******/
export const getAgencies = (state, callback) => {
  //Loader(state);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(dashboardurl + "get-agencies", {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          mergeAgencies: res.data.ownner_data,
        });
        callback();
      }
    })
    .catch((err) => {
      
    });
};

export const getmyappsWorkspace = (
  state,
  setData,
  setCheckWorkspaceData,
  limit,
  nextFrom
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(dashboardurl + `get-workspaces?limit=${limit}`, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          menuData: res.data.data.length === 0 ? null : res.data.data,
          invitedWorkspaceData: res.data.invited_workspace,
        });
      }
    })
    .catch((err) => {
      if (err.response != undefined && err.response.status === 401) {
        let previous_location = localStorage.getItem("Previouslocation");
        localStorage.clear();
        localStorage.setItem("Previouslocation", previous_location);
        // window.location.href = "/";
      }
      if (err.message != undefined && err.message == "Network Error") {
        let previous_location = localStorage.getItem("Previouslocation");
        localStorage.clear();
        localStorage.setItem("Previouslocation", previous_location);
        // window.location.href = "/";
      }
    });
};
//transferApp

export const getWorkspaces = (
  state,
  setData,
  setCheckWorkspaceData,
  limit,
  nextFrom
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(dashboardurl + `get-workspaces?limit=${limit}`, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        if (nextFrom === "billingDropdown" || nextFrom === "appsetting") {
          let wpData2 = res.data.data;
          let wpData = [];
          state.setState({
            agencies: res.data.agencylist,
          });
          res.data.data.map(function (wp2, i) {
            if (
              parseInt(localStorage.getItem("website_technology")) === 1 ||
              parseInt(localStorage.getItem("website_technology")) === 4 ||
              parseInt(localStorage.getItem("website_technology")) === 5
            ) {
              if (
                wp2.subscription.package_info.website_technology ===
                  parseInt(localStorage.getItem("website_technology")) ||
                wp2.subscription.plan === "preview" ||
                wp2.subscription.package_info.website_technology === parseInt(1)
              ) {
                wpData.push(wp2);
              }
            } else if (
              parseInt(localStorage.getItem("website_technology")) === 2
            ) {
              if (
                wp2.subscription.package_info.website_technology === 1 ||
                wp2.subscription.package_info.website_technology === 2 ||
                wp2.subscription.plan === "preview"
              ) {
                wpData.push(wp2);
              }
            } else if (
              parseInt(localStorage.getItem("website_technology")) === 3
            ) {
              if (
                wp2.subscription.package_info.website_technology === 1 ||
                wp2.subscription.package_info.website_technology ===
                  parseInt(localStorage.getItem("website_technology")) ||
                wp2.subscription.plan === "preview"
              ) {
                wpData.push(wp2);
              }
            }
          });
          // res.data.data;

          let invited_workspace = res.data.invited_workspace;
          // let useInfoObj = {
          //   id: res.data.user_info.id,
          //   workspace_name: res.data.user_info.name,
          //   profile_image: res.data.user_info.profile_image,
          //   type: "user",
          // };

          // wpData2.push(useInfoObj);

          wpData = wpData.concat(invited_workspace);
          let newwpData = [];

          wpData.map(function (wp2, i) {
            
            if (
              (wp2.role_id === 1 || wp2.role_id === 2) &&
              wp2.user_id === parseInt(localStorage.getItem("user_id"))
            ) {
              newwpData.push(wp2);
            }
          });

          // if (useInfoObj.id === parseInt(localStorage.getItem("user_id"))) {
          //   newwpData.push(useInfoObj);
          // }

          

          if (state.state.mergeArray1 !== undefined) {
            state.setState({
              mergeArray1: newwpData,
            });
          }
          

          if (nextFrom !== "appsetting") {
            let children = newwpData.concat(invited_workspace);
            if (state.state.mergeArray1 !== undefined) {
              state.setState({
                mergeArray1: children,
              });
            }
          }
        }
        if (state === "Mobilemenu") {
          setData(res.data);
          if (res.data.data.length === 0) {
            setCheckWorkspaceData(null);
          } else {
            setCheckWorkspaceData(res.data.data);
          }
        } else {
          state.setState({
            spinner: false,
            menuData: res.data.data.length === 0 ? null : res.data.data,
            userInfo: res.data.user_info,
            // role_id: res.data.invited_workspace.role_id,
            invitedWorkspaceData: res.data.invited_workspace,
            total_workspace_count: res.data.total_workspace_count,
            total_personal_app_count: res.data.total_personal_app_count,
            total_personal_invited_app_count:
              res.data.total_personal_invited_app_count,
            user_role: res.data.user_role,
          });
        }
      }
      // else if (res.data.code === 203) {
      // errortoast("Session expired ! login again");

      // setTimeout(() => {
      // state.props.history.push("/sign-in");
      // window.location.href = "/sign-in";
      // }, 2000);
      // }
    })
    .catch((err) => {
      if (err.response != undefined && err.response.status === 401) {
        let previous_location = localStorage.getItem("Previouslocation");
        localStorage.clear();
        localStorage.setItem("Previouslocation", previous_location);
        // window.location.href = "/";
      }
      if (err.message != undefined && err.message == "Network Error") {
        let previous_location = localStorage.getItem("Previouslocation");
        localStorage.clear();
        localStorage.setItem("Previouslocation", previous_location);
        // window.location.href = "/";
      }
    });
};
//transferApp

export const transferApp = (param, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(dashboardurl + `app/transfer-app`, param, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        sucesstoast("Your changes have been saved");
        state.setState({
          tfLoader: "d-none",
          tfSubmitText: "Okay",
          apptrans: false,
        });
        if (
          param.workspace_id === null ||
          param.workspace_id === "null" ||
          param.workspace_id === undefined
        ) {
          setTimeout(() => {
            localStorage.setItem("workspaceId", "");
            localStorage.setItem("workspaceName", "");
            localStorage.setItem("workspaceImage", "");
            localStorage.setItem("logged_in_user_role_id", 1);
            window.location.href = `/apps`;
          }, 2000);
        } else {
          setTimeout(() => {
            localStorage.setItem("logged_in_user_role_id", 1);
            localStorage.setItem("workspaceId", state.state.appTfDropData.id);
            localStorage.setItem(
              "workspaceName",
              state.state.appTfDropData.workspace_name
            );

            localStorage.setItem(
              "workspaceImage",
              state.state.appTfDropData.profile_image
            );

            window.location.href = `/apps${"/" + btoa(param.workspace_id)}`;
          }, 2000);
        }
      } else if (res.data.code === 204) {
        state.setState({
          apptrans: false,
          paidsubscriptiondetected: true,
          delete_app: false,
          websitetype: false,
          transfermodal: false,
          tfLoader: "d-none",
          tfSubmitText: "Okay",
        });
      } else {
        state.setState({
          apptrans: false,
          paidsubscriptiondetected: false,
          delete_app: false,
          websitetype: false,
          transfermodal: false,
          tfLoader: "d-none",
          tfSubmitText: "Okay",
        });
        errortoast("Something went wrong");
      }
    })
    .catch((err) => {
      state.setState({
        apptrans: false,
        tfLoader: "d-none",
        tfSubmitText: "Okay",
      });
      
      errortoast("Something went wrong");
    });
};

// // get workspace apps API

// export const getWorkspaceApps = (state) => {
//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: "Bearer " + localStorage.getItem("amstoken"),
//   };

//   axios
//     .get(dashboardurl + "get-workspace-apps", {
//       headers: headers,
//     })
//     .then((res) => {
//       if (res.status === 200) {
//         // 
//         state.setState({
//           menuData: res.data.data,
//           userInfo: res.data.user_info,
//         });
//       }
//     })
//     .catch((err) => {
//       errortoast("Something went wrong");
//     });
// };

// Change Payment Method for ZOHO

export const changePaymentZoho = (param, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(saveceritificate + "zoho/change-payment-method", param, {
      headers: headers,
    })
    .then((res) => {
      if (res.status === 200) {
        // localStorage.removeItem("workspaceName");
        // localStorage.removeItem("workspaceId");
        // window.location.href = "/workspace";
        // sucesstoast("Your workspace deleted successfully");
        window.location.href = res.data.data.url;
        // 
      }
    })
    .catch((err) => {
      errortoast("Something went wrong");
    });
};

// Change Paymet method for Stripe

export const changePaymentStripe = (param, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(saveceritificate + "stripe/change-payment-method", param, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        sucesstoast("Card successfully updated");
        setTimeout(() => {
          if (window.location.pathname.includes("/payment-method")) {
            state.props.history.push("/subscriptions");
          } else {
            window.location.reload();
          }
        }, 2000);
        state.setState({
          isCard: false,
          cardBtnText: "Done",
          cardDoneLoader: "d-none",
        });
      } else {
        errortoast("Something went wrong");
        state.setState({
          isCard: false,
          cardBtnText: "Done",
          cardDoneLoader: "d-none",
        });
      }
    })
    .catch((err) => {
      errortoast("Something went wrong");
      state.setState({
        isCard: false,
        cardBtnText: "Done",
        cardDoneLoader: "d-none",
      });
    });
};

// workspace Plan listing

export const workspacePlanListing = (state, web_technology, callback) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  var isPlan = state.state.isPlan;
  axios
    .get(
      dashboardurl +
        `workspace-packages/${btoa(
          parseInt(localStorage.getItem("workspaceId"))
        )}/${btoa(
          web_technology !== undefined &&
            web_technology !== "" &&
            web_technology !== null
            ? web_technology
            : null
        )}/${isPlan}`,
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          checkspinner: false,
          spinner: false,
          monthlydata: res.data.data.month,
          yearlydata: res.data.data.year,
          one_time: res.data.data.one_time,
          lifetimedata: res.data.data.lifetime,
          lifetimecheck: res.data.data.lifetime ? 1 : 0,
          subscription_id: res.data.data.subscription,
          subscriptionToCheck: res.data.data.subscription,
          subscription_plan: res.data.data.subscription_plan,
          // build_process_status: res.data.data.build_process_status,
          // discount: res.data.data.discount,
          // appuser_role_id: res.data.data.appuser_role_id,
          customer_billing_type: res.data.data.customer_billing_type,
          payment_methods: res.data.data.payment_methods,
          plan_switching: "ok",
          customer_type: res.data.data.customer_type,
          customer_billing_type: res.data.data.customer_billing_type,
          reseller_licence_id: res.data.data.reseller_licence_id,
          checkedtab:
            res.data.data.package_duration === 1
              ? "monthly"
              : res.data.data.package_duration === 2
              ? "yearly"
              : res.data.data.package_duration === 4
              ? "lifetime"
              : "yearly",
          currentplanname: res.data.data.plan_name,
          currentplanrpice:
            res.data.data.package_duration === 1
              ? res.data.data.total
              : res.data.data.package_duration === 2
              ? res.data.data.total / 12
              : res.data.data.total,
          currency: res.data.data.currency,
          endcycledata: res.data.data.billing_date,
          currentplanduration: res.data.data.package_duration,
          downgrade_at_term_end: res.data.data.downgrade_at_term_end,
          is_deal_plan: res.data.data.is_deal_plan,
          appuser_role_id: res.data.data.workspace_role_id,
        });
        if (callback !== undefined) {
          callback();
        }
      } else if (res.data.code === 502) {
        state.setState({
          allowPopUp: true,
        });
        // localStorage.setItem("switch_plan_restrict", "yes");
        // window.location.pathname = `/pricing/${btoa(
        //   parseInt(localStorage.getItem("workspaceId"))
        // )}`;
      }
    })
    .catch((err) => {
      
      errortoast("Something went wrong");
    });
};

// Workspace Stripe Payment API

export const MakestripWorkspacePayment = (params, state, way) => {
  Loader(state);
  let checkerForqueryString;
  let subscription_plan;
  if (parseInt(state.state.isPlan) === 0) {
    params.product = "addon";
    checkerForqueryString = "upgrade";
    params.action = "upgrade";
  } else {
    params.product = "plan";
    subscription_plan = state.state.subscription_plan;
    // if (way === "workspace") {
    //   subscription_plan = params.plan_array[0].price_id;
    // }
    if (params.app_plan !== undefined) {
      subscription_plan = params.app_plan;
    }
    if (
      subscription_plan === process.env.REACT_APP_Lifetime_preview ||
      subscription_plan === process.env.REACT_APP_STRIPE_PREVIEW ||
      subscription_plan === process.env.REACT_APP_zoho_preview
    ) {
      checkerForqueryString = "upgrade";
      params.action = "upgrade";
    } else if (params.payment_method_id === "downgrade") {
      checkerForqueryString = "downgrade";
      params.action = "downgrade";
    } else {
      checkerForqueryString = "change-plan ";
      params.action = "change-plan";
    }
  }
  if (way === "addon") {
    params.success_path = `/transaction-successful/${btoa("paddle")}?product=addon&action=upgrade`;
  } else {
    params.success_path =`/transaction-successful/${btoa("stripe")}?product=${parseInt(state.state.isPlan) === 0 ? "addon" : "plan"}&action=${checkerForqueryString}`
  }
  params.cancel_path = state.props?.location?.pathname;
  // return;
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(dashboardurl + "upgrade-workspace-plan", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          loader_remove: "d-none",
          submitval_confirmpop: "Yes",
        });
        if (way === "addon") {
          const userinfo = JSON.parse(localStorage.getItem("user_info"));

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "checkout_successful",
            user_email: userinfo.email,
            user_name: userinfo.name,
            user_id: userinfo.id,
            agency_id: localStorage.getItem("agency_id")
              ? parseInt(localStorage.getItem("agency_id"))
              : 1,
            transactionDetails: [
              {
                product_type: "addon",
                product_action: "upgrade",
                plan_type: "workspace",
                price: res.data.amount,
                currency: state.state.currency1,
              },
            ],
          });

          state.props.history.push(
            `/transaction-successful/${btoa(
              "paddle"
            )}?product=addon&action=upgrade`
          );
        } else {
          localStorage.setItem("checkoutpageamount", res.data.amount);
          localStorage.setItem(
            "checkoutsubscriptionid",
            res.data.subscription_id
          );
          localStorage.setItem("checkoutcustomerid", res.data.customer_id);
          var url = `/transaction-successful/${btoa("stripe")}?product=${
            parseInt(state.state.isPlan) === 0 ? "addon" : "plan"
          }&action=${checkerForqueryString}`;

          const userinfo = JSON.parse(localStorage.getItem("user_info"));

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "checkout_successful",
            user_email: userinfo.email,
            user_name: userinfo.name,
            user_id: userinfo.id,
            agency_id: localStorage.getItem("agency_id")
              ? parseInt(localStorage.getItem("agency_id"))
              : 1,
            transactionDetails: [
              {
                product_type: "plan",
                product_action: "upgrade",
                plan_type: "Workspace",
                price: res.data.amount,
                currency: state.state.currency1,
              },
            ],
          });

          window.open(url, "_self");
          // state.props.history.push(
          //   `/transaction-successful/${btoa("stripe")}?product=${
          //     parseInt(state.state.isPlan) === 0 ? "addon" : "plan"
          //   }&action=${checkerForqueryString}`
          // );
        }
      } else if (+res.data.code === 204 && res?.data?.url) {
        window.location.href = res?.data?.url;
      } else {
        errortoast("Something went wrong. Try again after sometime.");
        state.setState({
          submitval: way === "addon" ? "Buy this addon" : "Make payment",
          loader: "d-none",
          submitval_update: "UPDATE",
          formbuttondisabled: false,
        });
      }
    })
    .catch((error) => {
      state.setState({
        submitval: way === "addon" ? "Buy this addon" : "Make payment",
        loader: "d-none",
        formbuttondisabled: false,
        loader_remove: "d-none",
        submitval_confirmpop: "Yes",
      });
      
      errortoast("Something went wrong. Try again after sometime.");
    });
};

// Workspace Subscription List

// export const workspaceSubscriptionList = (params, state) => {
//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: "Bearer " + localStorage.getItem("amstoken"),
//   };

//   axios
//     .get(dashboardurl + "workspace-subscription", {
//       headers: headers,
//     })
//     .then((res) => {
//       if (res.data.code === 200) {
//         state.setState({
//           customer_type: res.data.customer_type,
//         });
//         if (params.page > 1) {
//           let billingdata = params.billingdata;
//           Array.prototype.push.apply(billingdata, res.data.data);

//           state.setState({
//             billingdata: billingdata,
//             customer_billing_type: res.data.customer_billing_type,
//             spinner: false,
//             bottomspinner: false,
//             handlepage: res.data.data,
//             datanumber: res.data.data.length,
//             customer_type: res.data.customer_type,
//             customer_billing_type: res.data.customer_billing_type,
//           });
//         } else {
//           state.setState({
//             spinner: false,
//             billingdata: res.data.data,
//             datanumber: res.data.data.length,
//             customer_type: res.data.customer_type,
//             customer_billing_type: res.data.customer_billing_type,
//           });
//         }
//       } else if (res.data.code === 203) {
//         if (res.data.status === "Token Expired") {
//           state.props.history.push("/");
//           let myItem = localStorage.getItem("buildversion");
//           let myItemversion = localStorage.getItem("Apiversion");
//           let previous_location = localStorage.getItem("Previouslocation");
// localStorage.clear();
// localStorage.setItem("Previouslocation", previous_location);
//           localStorage.setItem("buildversion", myItem);
//           localStorage.setItem("Apiversion", myItemversion);
//         }
//       }
//     })
//     .catch((err) => {
//       errortoast("Something went wrong");
//     });
// };

// Get workspace by ID

export const getWorkspaceById = (state, workspace_id, callback) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(dashboardurl + `get-workspace-detail/${workspace_id}`, {
      headers: headers,
    })
    .then((res) => {
      if (res.status === 200) {
        // 
        localStorage.setItem("workspaceId", res.data.workspace_details.id);
        localStorage.setItem(
          "workspaceName",
          res.data.workspace_details.workspace_name
        );
        localStorage.setItem(
          "workspaceImage",
          res.data.workspace_details.profile_image
        );
        localStorage.setItem(
          "invited_by_user_id",
          res.data.workspace_details.user_id
        );
        if (res.data.workspace_user_role === null) {
          localStorage.setItem("logged_in_user_role_id", 1);
        } else {
          localStorage.setItem(
            "logged_in_user_role_id",
            res.data.workspace_user_role
          );
        }
        // localStorage.setItem("logged_in_user_role_id",res.data.workspace_user_role);
        state.setState({
          WorkspaceDataById: res.data.workspace_details,
          workspaceName: res.data.workspace_details.workspace_name,
          workspace_app_count: res.data.workspace_app_count,
          zoho_plan_code: res.data.workspace_details.subscription.plan_code,
          website_technology: res.data.website_technology,
          workspaceImage: res.data.workspace_details.profile_image,
          profile: res.data.workspace_details.profile_image,
          workspaceroleid: res.data.workspace_user_role,
          workspace_technology: res.data.website_technology,
          workspacespinner: false,
          is_free: res.data.is_free,
          has_multiple_technology_apps: res.data.has_multiple_technology_apps,
          settingspinner: false,

          // userInfo: res.data.user_info,
        });
        if (callback !== undefined) {
          callback();
        }
      }
    })
    .catch((err) => {
      state.setState({ spinner: false });
      errortoast("Something went wrong ");
      
    });
};

export const WorkspaceInfoById = (workspace_id) => {
  localStorage.removeItem("gateway_subscription_id");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  

  axios
    .get(dashboardurl + `get-workspace-detail/${workspace_id}`, {
      headers: headers,
    })
    .then((res) => {
      if (res.status === 200) {
        localStorage.setItem("workspaceId", res.data.workspace_details.id);
        localStorage.setItem("gateway_subscription_id", res?.data?.workspace_details?.subscription?.gateway_subscription_id);
        localStorage.setItem(
          "workspaceImage",
          res.data.workspace_details.profile_image
        );
        localStorage.setItem(
          "workspaceName",
          res.data.workspace_details.workspace_name
        );
        localStorage.setItem(
          "invited_by_user_id",
          res.data.workspace_details.user_id
        );
        if (res.data.workspace_user_role === null) {
          localStorage.setItem("logged_in_user_role_id", 1);
        } else {
          localStorage.setItem(
            "logged_in_user_role_id",
            res.data.workspace_user_role
          );
        }
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

//Get Workspace Dashboard data
export const getWorkspaceDataGraph = (state, workspaceid, datefilter) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  const params = {
    workspace_id: parseInt(workspaceid),
    datefilter: datefilter,
  };

  axios
    .post(dashboardurl + "get-workspace-dashboard-graph", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        const labels = res.data.period;
        const data = {
          labels,
          datasets: [
            {
              data: res.data.graphdata,
              backgroundColor: "rgba(110, 148, 255, 1)",
              fontFamily: "Axiforma-Regular",
            },
          ],
        };
        state.setState({
          periods: res.data.period,
          graphdata: res.data.graphdata,
          data: data,
          graphload: false,
        });
      }
    })
    .catch((error) => {
      
    });
};

/********Get All Workspace Apps List******/
export const getWorkspaceAppsDetail = (
  workspaceid,
  setSpinner,
  apps,
  setApps,
  setpageCount,
  setSpinner1,
  setInitialAppsCount
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(dashboardurl + "get-workspace-apps/" + workspaceid, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200 && res.data.status != "error") {
        setSpinner(false);
        setSpinner1(false);
        setApps(res.data.data.apps);
        setInitialAppsCount(res.data.data.apps?.length);
        setpageCount(Math.ceil(res.data.data.appscount / 15));
      } else {
        setSpinner1(false);
      }
    })
    .catch((err) => {
      // errortoast("Something went wrong");
      
    });
};

export const getWorkspacesearchappsdata = (
  searchvalue,
  workspaceid,
  setSpinner,
  setApps,
  setsearcheable
) => {
  //Loader(state);
  setSpinner(true);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(
      dashboardurl +
        "get-workspace-apps/" +
        workspaceid +
        "?searchquery=" +
        searchvalue,
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200 && res.data.status != "error") {
        setSpinner(false);
        setApps(res.data.data.apps);
        setsearcheable(true);
      } else {
        setSpinner(false);
      }
    })
    .catch((err) => {
      // errortoast("Something went wrong");
      
    });
};

export const WorkspacereceivedData = (
  selectedPage,
  workspaceid,
  setSpinner1,
  dataapps,
  setApps,
  setpageCount,
  setstoplength
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(
      dashboardurl + "get-workspace-apps/" + workspaceid + "/" + selectedPage,
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200 && res.data.status != "error") {
        if (res.data.data.apps.length == 0) {
          setstoplength(1);
        }
        var data1 = dataapps.concat(res.data.data.apps);
        setSpinner1(false);
        setApps(data1);
        setpageCount(Math.ceil(res.data.data.appscount / 15));
      } else {
        setSpinner1(false);
      }
    })
    .catch((err) => {
      // errortoast("Something went wrong");
      
    });
};

export const WorkspaceDashboardAPI = (state, workspace_id, callback) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  

  axios
    .get(dashboardurl + `get-workspace-dashboard-data/${workspace_id}`, {
      headers: headers,
    })
    .then((res) => {
      if (res.status === 200) {
        
        state.setState({
          workspacedata: res.data.data.workspacedata,
          management_users: res.data.data.management_users,
          owner_data: res.data.data.owner_data,
          subscription: res.data.data.subscription,
          show_management_users: res.data.data.show_management_users,
          appscount: res.data.data.appscount,
          spinner: false,
        });
        callback();
        localStorage.setItem(
          "workspace_web_technology",
          res.data.data.subscription.website_technology
        );
        // localStorage.setItem("workspaceId", res.data.data.workspacedata.id);
        // localStorage.setItem("workspaceImage",res.data.data.workspacedata.profile_image);
        // localStorage.setItem("workspaceName",res.data.data.workspacedata.workspace_name);
        // localStorage.setItem("invited_by_user_id",res.data.data.workspacedata.user_id);
        // if (res.data.role_id === null)
        // {
        //   localStorage.setItem("logged_in_user_role_id", 1);
        // }
        // else
        // {
        //   localStorage.setItem("logged_in_user_role_id",res.data.role_id);
        // }
      }
    })
    .catch((error) => {
      
      state.setState({
        spinner: false,
      });
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

export const headeradminSearch = (
  param,
  setsearchdata,
  setsearchSpinner,
  setsearchdatavalue
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(
      dashboardurl +
        `login-as-client-search?search_field=${param.search_field}`,
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.status === 200) {
        // 
        setsearchdata(res.data.data);
        setsearchSpinner(false);
        
        if (res.data.data.length == 0) {
          setsearchdatavalue("1");
        }
      }
    })
    .catch((err) => {
      errortoast("Something went wrong ");
      
    });
};

export const adminSearch = (param, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(
      dashboardurl +
        `login-as-client-search?search_field=${param.search_field}`,
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.status === 200) {
        state.setState({
          searchData: res.data.data,
          searchSpinner: false,
        });
      }
    })
    .catch((err) => {
      errortoast("Something went wrong ");
      
    });
};

//Website Menu Toggle API

// export const websiteMenuToggleAPI = (state) => {
//   const headers = {
//     "Content-Type": "application/json",
//     // Authorization: "Bearer " + localStorage.getItem("amstoken"),
//   };
//   axios
//     .get(state.state.storeUrl + "/wp-json/wc/v3/ams-menu-names", {
//       headers: headers,
//     })
//     .then((res) => {
//       if (res.status === 200) {
//         state.setState({
//           // spinner: false,
//           menulist: res.data,

//           spinnerForDefaultToggle: false,
//         });
//       }
//     })

//     .catch((err) => {
//       
//       state.setState({
//         // spinner: false,

//         spinnerForDefaultToggle: false,
//       });
//     });
// };

export const websiteMenuToggleAPI = (state) => {
  const headers = {
    "Content-Type": "application/json",
    "X-App-Device-Type": "ios",
    "X-App-Code-Ver": localStorage.getItem("Apiversion"),
  };
  fetch(
    RedirectUrl +
      state.state.storeUrl.replace("www.", "") +
      "/wp-json/wc/v3/ams-menu-names/",
    {
      method: "GET",
      headers: headers,
      redirect: "follow",
    }
  )
    .then((res) => res.json())
    .then((json) => {
      
      state.setState({
        // spinner: false,
        menulist: json,

        spinnerForDefaultToggle: false,
      });
    })
    .catch((err) => {
      
      state.setState({
        // spinner: false,

        spinnerForDefaultToggle: false,
      });
    });
};

// Social Profile login on Profile Page
export const ConnectGoogleProfile = (params, state) => {
  
  if (params) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("amstoken"),
    };
    axios
      .post(Url + "authenticate-google-login", params, {
        headers: headers,
      })
      .then((res) => {
        if (res.data.code === 200) {
          // state.setState({ google_spinner: false });
          GetuserprofileApi(state);
          // window.location.reload();
        } else if (res.data.code === 401) {
          state.setState({ google_spinner: false });
          
          errortoast(
            "Your social email address must match your profile email address"
          );
        }
      })
      .catch((error) => {
        
        state.setState({ google_spinner: false });
      });
  }
};

//Social Profile login on profile page
export const ConnectFacebookProfile = (params, state) => {
  if (params) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("amstoken"),
    };
    axios
      .post(Url + "authenticate-facebook-login", params, {
        headers: headers,
      })
      .then((res) => {
        if (res.data.code === 200) {
          // state.setState({ fb_spinner: false });
          GetuserprofileApi(state);
          // window.location.reload();
        } else if (res.data.code === 400) {
          state.setState({ fb_spinner: false });
          
          errortoast(
            "Your social email address must match your profile email address"
          );
        } else if (res.data.code === 401) {
          state.setState({ fb_spinner: false });
          
          errortoast(
            "Your social email address must match your profile email address"
          );
        }
      })
      .catch((error) => {
        
        state.setState({ fb_spinner: false });
      });
  }
};

//Remove Google profile from profile page
export const RemoveSocialProfile = (params, state) => {
  profileloader(state);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "remove-social-logins", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        GetuserprofileApi(state);
        if (params.platform === "google") {
          // state.setState({ google_spinner: false });
        } else {
          // state.setState({ fb_spinner: false });
        }
        //state.props.history.push("/profile");
        // window.location.reload();
      } else if (res.data.code === 400) {
        if (params.platform === "google") {
          state.setState({ google_spinner: false });
        } else {
          state.setState({ fb_spinner: false });
        }
      }
    })
    .catch((error) => {
      
      if (params.platform === "google") {
        state.setState({ google_spinner: false });
      } else {
        state.setState({ fb_spinner: false });
      }
    });
};

//error logs
export const ProfileErrorlogs = (params) => {
  axios
    .post(Errorurl, params)
    .then((res) => {
      
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

//Country code
export const ProfileCountrycode = (state, callback) => {
  //Loader(state);

  axios
    .get(
      "https://api.ipstack.com/check?access_key=" +
        process.env.REACT_APP_ipstack_key +
        "&timestamp=" +
        new Date().getTime()
    )
    .then((res) => {
      if (res.data.error === undefined) {
        state.setState({
          user_country: res.data.country_code,
          login_ip: res.data.ip,
          phone_code: res.data.location.calling_code,
          country_name_prefix: res.data.location.country_flag_emoji,
        });
        callback();
      } else {
        state.setState({
          user_country: "",
          login_ip: "",
          calling_Code: "",
        });
        const params = {
          code: res.data.error.code,
          type: res.data.error.type,
          info: res.data.error.info,
        };
        ProfileErrorlogs(params);
        callback();
      }
    })
    .catch((error) => {
      state.setState({
        user_country: "",
        login_ip: "",
      });
      callback();
    });
};

//Country code
export const CallProfileCountrycode = (state) => {
  //Loader(state);

  axios
    .get(
      "https://api.ipstack.com/check?access_key=" +
        process.env.REACT_APP_ipstack_key +
        "&timestamp=" +
        new Date().getTime()
    )
    .then((res) => {
      if (res.data.error === undefined) {
        
        state.setState({
          user_country: res.data.country_code,
          login_ip: res.data.ip,
          phone_code: res.data.location.calling_code,
          country_name_prefix: res.data.country_code.toLowerCase(),
        });
      } else {
        state.setState({
          user_country: "",
          login_ip: "",
          calling_Code: "",
        });
        const params = {
          code: res.data.error.code,
          type: res.data.error.type,
          info: res.data.error.info,
        };
        ProfileErrorlogs(params);
      }
    })
    .catch((error) => {
      state.setState({
        user_country: "",
        login_ip: "",
      });
    });
};

export const Savetogglestatus = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(saveceritificate + "web-view-toggle", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          bodyspinner: false,
          submitval: "Save",
        });
        state.setState({
          loader1: "d-block",
          loader: "d-none",
          updated_at: res.data.data.data.last_updated_timestamp
            ? res.data.data.data.last_updated_timestamp
            : null,
        });

        sucesstoast("Your changes have been saved.");
        // window.location.reload();
      } else {
        state.setState({
          bodyspinner: false,
          submitval: "Save",
          loader: "d-none",
        });
        // errortoast(res.data.data);
        errortoast("Oh no! Something’s not right.");
      }
    })
    .catch((error) => {
      state.setState({
        bodyspinner: false,
        submitval: "Save",
        loader: "d-none",
      });
      // errortoast(res.data.data);
      errortoast("Oh no! Something’s not right.");
      
    });
};
// Upload any image to s3
export const uploadAnyBase64Imagetos3 = (
  params,
  orgFileName,
  callback,
  selectediconcolor,
  selectedchangetype
) => {
  //convert into image(base64 image to file object)
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      // mime = arr[0].match(/:(.*?);/)[1],
      mime = "image/png",
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }

  //Usage example:
  let file = dataURLtoFile(params.data, "hello.png");

  //send to s3
  const config = {
    bucketName: process.env.REACT_APP_bucketName,
    dirName: params.path.replace(/\/$/, "") /* optional */,
    region: process.env.REACT_APP_region,
    accessKeyId: process.env.REACT_APP_accessKeyId,
    secretAccessKey: process.env.REACT_APP_secretAccessKey,
    s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
  };
  const ReactS3Client = new CustomS3Client(config);

  const newFileName = orgFileName;

  ReactS3Client.uploadFile(file, newFileName)
    .then((data) => {
      if (data.status === 204) {
        // settingImgtoArray(newFileName + ".png", "original");
        callback(newFileName + ".png", selectediconcolor, selectedchangetype);
      }
    })
    .catch((err) => {
      

      // errortoast("Something went wrong. Try again after sometime.");
    });
};


export const GetPagesettingapi = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  

  axios
    .get(saveceritificate + "web-view-toggle/" + params.app_id, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        if (res.data.data !== null) {
          state.setState({
            bodyspinner: false,
            show_domain: res.data.data.show_domain,
            show_back_and_forth_arrows:
              res.data.data.show_back_and_forth_arrows,
            show_options_shortcut: res.data.data.show_options_shortcut,
            show_appheader: res.data.data.show_app_header_bool,
            show_websiteheader: res.data.data.show_web_view_header_bool,
            show_websitefooter: res.data.data.show_web_view_footer_bool,
            show_websitesidebar: res.data.data.show_web_view_sidebar_bool,
            hide_html_element_by_class:
              res.data.data.hide_html_element_by_class,
            hide_html_element_by_id: res.data.data.hide_html_element_by_id,
            enable_pinch_to_zoom_bool: res.data.data.enable_pinch_to_zoom_bool,
            remember_cookies_bool: res.data.data.remember_cookies_bool,
            show_native_loader:
              res.data.data.show_native_loader !== undefined &&
              res.data.data.show_native_loader !== null
                ? res.data.data.show_native_loader === 1
                  ? true
                  : false
                : false,

            // New Key
            show_website_title:
              res.data.data.show_website_title !== undefined &&
              res.data.data.show_website_title !== null
                ? res.data.data.show_website_title === 1
                  ? true
                  : false
                : true,
            show_custom_title:
              res.data.data.show_custom_title !== undefined &&
              res.data.data.show_custom_title !== null
                ? res.data.data.show_custom_title === 1
                  ? true
                  : false
                : false,
            custom_title: res.data.data.custom_title
              ? res.data.data.custom_title
              : "",
            title_alignment: res.data.data.title_alignment
              ? res.data.data.title_alignment
              : "left",

            background_color: res.data.data.background_color
              ? res.data.data.background_color.portal_data
              : "linear-gradient(90deg, rgba(51, 199, 255, 1) 0%, rgba(72, 90, 254, 1) 50%, rgba(146, 65, 239, 1) 100%)",
            border_color: res.data.data.border_color
              ? res.data.data.border_color.portal_data
              : "#74A4FF",
            icon_color: res.data.data.icon_color
              ? res.data.data.icon_color.portal_data
              : "#FFFFFF",
            text_color: res.data.data.text_color
              ? res.data.data.text_color.portal_data
              : "#FFFFFF",
            native_loader_duration: res.data.data.native_loader_duration
              ? res.data.data.native_loader_duration
              : "2",
            updated_at: res.data.data.last_updated_timestamp
              ? res.data.data.last_updated_timestamp
              : null,
            enable_pull_to_refresh:
              res.data.data.enable_pull_to_refresh === undefined ||
              res.data.data.enable_pull_to_refresh === null
                ? false
                : res.data.data.enable_pull_to_refresh === 1
                ? true
                : false,
            append_app_code_version_in_user_agent:
              res.data.data.append_app_code_version_in_user_agent ===
                undefined ||
              res.data.data.append_app_code_version_in_user_agent === null
                ? false
                : res.data.data.append_app_code_version_in_user_agent === 1
                ? true
                : false,
            enable_override_css_feature: res.data.data
              .enable_override_css_feature
              ? true
              : false,
            css_to_override: res.data.data.css_to_override || "",
            enable_website_cache_policy: res.data.data?.enable_website_cache_policy === 0 ? false : true,
            enable_forceful_cache_webpages: Boolean(res.data.data?.enable_forceful_cache_webpages),
            number_of_days_to_cache: res.data.data?.number_of_days_to_cache || 2,
            number_of_days_to_cache_format: res.data.data?.number_of_days_to_cache_format || "days",
          });
        } else {
          state.setState({
            bodyspinner: false,
          });
        }
      } else {
        // errortoast(res.data.data);
      }
    })
    .catch((error) => {});
};

