import React from "react";

const CrossIcon = ({ handleCrossClick }) => {
  return (
    <svg
      onClick={() => handleCrossClick()}
      className="menu-crosssvg"
      xmlns="http://www.w3.org/2000/svg"
      width="11.06"
      height="11.06"
      viewBox="0 0 11.06 11.06"
    >
      <path
        id="Union_1695"
        data-name="Union 1695"
        d="M13951,4311l-5,5,5-5-5-5,5,5,5-5-5,5,5,5Z"
        transform="translate(-13945.471 -4305.469)"
        fill="none"
        stroke="#7782a1"
        stroke-width="1.5"
      />
    </svg>
  );
};

export default CrossIcon;
