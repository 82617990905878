import React from "react";
import { Link } from "react-router-dom";
import { errortoast } from "./Toaster";
import userdefaultImgOnError from "../assets/images/dashboard/accountprofile.png";
import wpdefaultImgOnError from "../assets/images/dashboard/workspace/workspace-default-img.png";

const closemenu = () => {
  document.body.style.overflow = "auto";
  document
    .querySelector(".left_panel")
    .classList.remove("leftpanel-ipadview-dashboard");
  document
    .querySelector(".left_panel")
    .classList.remove("leftpanel-ipadview-landscape");
  //document.querySelector(".phone-menu").classList.remove("backcolor");
};

export default class Accountleftpanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: "",
      allowEditImage: true,

      profilepath:
        localStorage.getItem("workspaceId") !== "" &&
        localStorage.getItem("workspaceId") !== undefined &&
        localStorage.getItem("workspaceId") !== null
          ? process.env.REACT_APP_Image_Path +
              (
                parseInt(localStorage.getItem("logged_in_user_role_id")) === 1
                ? localStorage.getItem("logged_in_user_id")
                : parseInt(localStorage.getItem("logged_in_user_role_id")) === 2 
                ? localStorage.getItem("invited_by_user_id")
                : localStorage.getItem("invited_by_user_id")
              ) 
            + process.env.REACT_APP_Workspace_Profile +
            localStorage.getItem("workspaceId") +
            "/"
          : process.env.REACT_APP_Image_Path +
            // (localStorage.getItem("roleid") === "1"
            //   ? localStorage.getItem("originalid")
            //   : localStorage.getItem("user_id")) +
            localStorage.getItem("logged_in_user_id") +
            process.env.REACT_APP_Profile,
    };
  }
  componentDidMount() {

    if (window.location.pathname === "/profile") 
    {
      this.setState({
        profile: this.props.value.state.profile,
        profilepath:
          process.env.REACT_APP_Image_Path +
          localStorage.getItem("logged_in_user_id") +
          process.env.REACT_APP_Profile,
      });
    } 
    else if (window.location.pathname.indexOf("/workspace/") > -1 && window.location.pathname.indexOf("/addons/") === -1
    && window.location.pathname.indexOf("/workspace/team") === -1 
    ) 
    {
      this.setState({
        profile: this.props.value.state.profile,
        profilepath:
          process.env.REACT_APP_Image_Path +
          (
            parseInt(localStorage.getItem("logged_in_user_role_id")) === 1
            ? localStorage.getItem("logged_in_user_id")
            : parseInt(localStorage.getItem("logged_in_user_role_id")) === 2 
            ? localStorage.getItem("invited_by_user_id")
            : localStorage.getItem("invited_by_user_id")
          ) 
          + process.env.REACT_APP_Workspace_Profile +
          localStorage.getItem("workspaceId") +
          "/",
        // localStorage.getItem("workspaceImage"),
      });
      if (parseInt(localStorage.getItem("logged_in_user_role_id")) !== 1 && parseInt(localStorage.getItem("logged_in_user_role_id")) !== 2) 
      {
        this.setState({
          allowEditImage: false,
        });
      } else {
        this.setState({
          allowEditImage: true,
        });
      }
    } else {
      if (
        localStorage.getItem("workspaceId") !== "" &&
        localStorage.getItem("workspaceId") !== null &&
        localStorage.getItem("workspaceId") !== undefined
      ) {
        this.setState({
          profile: localStorage.getItem("workspaceImage"),
        });
      } else {
        this.setState({
          profile: localStorage.getItem("user_profile"),
        });
      }

      this.setState({
        allowEditImage: false,
      });
    }
  }

  render() {
    if(localStorage.getItem("logged_in_user_id") === null  && localStorage.getItem("workspaceId") === null)
    {
      
      
      window.location.href = "/sign-in";
    }

    return (
      <>
     
     
        <div className={"second-left-panel left_panel d-none d-md-block " + this.props.ipadView}>
          <div
            onClick={closemenu}
            className="ipad-view-cross profile-ipadview-cross"
          >
            <svg width="13.5" height="13.5" viewBox="0 0 13.5 13.5">
              <path
                id="Union_5"
                data-name="Union 5"
                d="M-1984,1046l-6,6,6-6-6-6,6,6,6-6-6,6,6,6Z"
                transform="translate(1990.75 -1039.25)"
                fill="none"
                stroke="#7782a1"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
            </svg>
          </div>
          <div className="leftpanel-top workspace-profile">
            <div className="profile-image-mobileview text-center">
              <label className="my-profile-picture" htmlFor="profilepic">
                {this.state.profile === "" ||
                this.state.profile === null ||
                this.state.profile === "null" ||
                this.state.profile === undefined ||
                this.state.profile === "undefined" ? (
                  <img
                    src={
                      localStorage.getItem("workspaceId") !== "" &&
                      localStorage.getItem("workspaceId") !== "undefined" &&
                      localStorage.getItem("workspaceId") !== undefined &&
                      localStorage.getItem("workspaceId") !== null
                        ? require("../assets/images/dashboard/workspace/workspace-default-img.png")
                            .default
                        : require("../assets/images/dashboard/accountprofile.png")
                            .default
                    }
                    className={`proimg  ${
                      (window.location.pathname.indexOf("/workspace/") > -1 &&
                        (parseInt(localStorage.getItem("logged_in_user_role_id")) === 1 
                         || parseInt(localStorage.getItem("logged_in_user_role_id")) === 2)) 
                        || window.location.pathname === "/profile"
                        ? "hover-effect"
                        : ""
                    } `}
                    width="120px"
                    height="120px"
                    alt="profileimg "
                  />
                ) : (
                  <>
                    {this.state.profile.indexOf("https") === -1 ? (
                      <img
                      className={`proimg  ${
                        (window.location.pathname.indexOf("/workspace/") > -1 &&
                          (parseInt(localStorage.getItem("logged_in_user_role_id")) === 1 
                           || parseInt(localStorage.getItem("logged_in_user_role_id")) === 2)) 
                          || window.location.pathname === "/profile"
                          ? "hover-effect"
                          : ""
                      } `}
                        style={{
                          borderRadius: "50%",
                        }}
                        src={this.state.profilepath + this.state.profile}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src =
                            localStorage.getItem("workspaceId") !== "" &&
                            localStorage.getItem("workspaceId") !==
                              "undefined" &&
                            localStorage.getItem("workspaceId") !== undefined &&
                            localStorage.getItem("workspaceId") !== "null" &&
                            localStorage.getItem("workspaceId") !== null
                              ? wpdefaultImgOnError
                              : userdefaultImgOnError;
                        }}
                        width="120px"
                        height="120px"
                      />
                    ) : (
                      <img
                        src={this.state.profile}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src =
                            localStorage.getItem("workspaceId") !== "" &&
                            localStorage.getItem("workspaceId") !==
                              "undefined" &&
                            localStorage.getItem("workspaceId") !== undefined &&
                            localStorage.getItem("workspaceId") !== "null" &&
                            localStorage.getItem("workspaceId") !== null
                              ? wpdefaultImgOnError
                              : userdefaultImgOnError;
                        }}
                        width="120px"
                        height="120px"
                        style={{
                          borderRadius: "50%",
                        }}
                      />
                    )}
                  </>
                )}

                {this.state.allowEditImage === true && (
                  
                  <input
                    // type="file"
                    onClick={(e)=>this.props.value.handleprofileimage(e,this)}
                    id="profilepic"
                    className="d-none"
                    // multiple
                  />
                )}
              </label>

              <p
                title={
                  localStorage.getItem("workspaceId") !== "" &&
                  localStorage.getItem("workspaceId") !== "undefined" &&
                  localStorage.getItem("workspaceId") !== undefined &&
                  localStorage.getItem("workspaceId") !== null
                    ? localStorage.getItem("workspaceName")
                    : localStorage.getItem("user_name")
                }
              >
                {localStorage.getItem("workspaceId") !== "" &&
                localStorage.getItem("workspaceId") !== "undefined" &&
                localStorage.getItem("workspaceId") !== undefined &&
                localStorage.getItem("workspaceId") !== null
                ? localStorage.getItem("workspaceName") !== null && localStorage.getItem("workspaceName") !== undefined && localStorage.getItem("workspaceName").length < 15
                    ? localStorage.getItem("workspaceName")
                    : localStorage.getItem("workspaceName").slice(0, 15) + "..."
                  : localStorage.getItem("user_name") !== null && localStorage.getItem("user_name") !== undefined && localStorage.getItem("user_name").length < 15
                  ? localStorage.getItem("user_name")
                  : localStorage.getItem("user_name").slice(0, 15) + "..."}
              </p>      

              {this.state.allowEditImage === false ||
              this.state.profile === "" ||
              this.state.profile === null ||
              this.state.profile === "null" ||
              this.state.profile === undefined ||
              this.state.profile === "undefined" ||
              (parseInt(localStorage.getItem("logged_in_user_role_id")) !== 1 && parseInt(localStorage.getItem("logged_in_user_role_id")) !== 2 ) ? (
                <></>
              ) : (
                <div
                  className="removeprofile removeprofile-mobile"
                  onClick={(e) => this.props.value.removeimage(e, this)}
                >
                  <p>Remove</p>
                </div>
              )}
              
            </div>
          </div>
          {localStorage.getItem("workspaceName") === "" && (
            <div className={"new-profile-panel panel panel-default " + this.props.userCondition}>
              <div className="panel-heading" role="tab" id="headingFour">
                <div className="d-flex align-items-center common-height setting">
                  <Link to="/profile"></Link>
                  <div className="web_logo">
                    <img
                      className="option-inactive"
                      src={
                        window.location.pathname === "/profile"
                          ? require("../assets/images/dashboard/workspace/overview-active.png")
                              .default
                          : require("../assets/images/dashboard/workspace/overview-inactive.png")
                              .default
                      }
                    />
                  </div>
                  <div className="pannel_title">
                    <h4
                      style={{
                        color:
                          window.location.pathname === "/profile"
                            ? "#3064f9"
                            : "#1B2952",
                        fontFamily:
                          window.location.pathname === "/profile"
                            ? "Axiforma-Bold"
                            : "Axiforma-Regular",
                      }}
                      className="panel-title"
                    >
                      Profile
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          )}
          {localStorage.getItem("workspaceName") === "" && (
            <div className={"new-profile-panel panel panel-default " + this.props.userCondition}>
              <div className="panel-heading" role="tab" id="headingFive">
                <div className="d-flex align-items-center common-height website">
                  <Link to="/changepassword"></Link>
                  <div className="web_logo">
                    <img
                      className="option-inactive"
                      src={
                        window.location.pathname === "/changepassword"
                          ? require("../assets/images/dashboard/password-active.png")
                              .default
                          : require("../assets/images/dashboard/password-inactive.png")
                              .default
                      }
                    />
                  </div>
                  <div className="pannel_title">
                    <h4
                      style={{
                        color:
                          window.location.pathname === "/changepassword"
                            ? "#3064f9"
                            : "#1B2952",
                        fontFamily:
                          window.location.pathname === "/changepassword"
                            ? "Axiforma-Bold"
                            : "Axiforma-Regular",
                      }}
                      className="panel-title"
                    >
                      Password
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          )}
          
          {localStorage.getItem("workspaceName") === "" && (
            <div className={"new-profile-panel panel panel-default " + this.props.userCondition}>
              <div className="panel-heading" role="tab" id="headingFive">
                <div className="d-flex align-items-center common-height website">
                  <Link to="/security"></Link>
                  <div className="web_logo">
                    <img
                      className="option-inactive"
                      src={
                        window.location.pathname === "/security"
                          ? require("../assets/images/dashboard/security-active.png")
                              .default
                          : require("../assets/images/dashboard/security-inactive.png")
                              .default
                      }
                    />
                  </div>
                  <div className="pannel_title">
                    <h4
                      style={{
                        color:
                          window.location.pathname === "/security"
                            ? "#3064f9"
                            : "#1B2952",
                        fontFamily:
                          window.location.pathname === "/security"
                            ? "Axiforma-Bold"
                            : "Axiforma-Regular",
                      }}
                      className="panel-title"
                    >
                      Security
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          )}
          {localStorage.getItem("workspaceName") !== "" && (
            <div className={"new-profile-panel panel panel-default " + this.props.teamCondition}>
              <div className="panel-heading" role="tab" id="headingFive">
                <div className="d-flex align-items-center common-height website common-height-new mt-4">
                  <Link
                    to={`/workspace/dashboard/${btoa(
                      localStorage.getItem("workspaceId")
                    )}`}
                  ></Link>
                  <div className="web_logo">
                    <img
                      className="option-inactive"
                      src={
                        window.location.pathname.indexOf("/workspace/") > -1 && window.location.pathname.indexOf("/workspace/team") === -1 
                          ? require("../assets/images/dashboard/workspace/overview-active1.png")
                              .default
                          : require("../assets/images/dashboard/workspace/overview-inactive1.png")
                              .default
                      }
                    />
                  </div>
                  <div className="pannel_title">
                    <h4
                      style={{
                        color:
                          window.location.pathname.indexOf("/workspace/") > -1 && window.location.pathname.indexOf("/workspace/team") === -1 
                            ? "#3064f9"
                            : "#1B2952",
                        fontFamily:
                          window.location.pathname.indexOf("/workspace/") > -1 && window.location.pathname.indexOf("/workspace/team") === -1 
                            ? "Axiforma-Bold"
                            : "Axiforma-Regular",
                      }}
                      className="panel-title"
                    >
                      Overview
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          )}
          {localStorage.getItem("workspaceName") !== "" && (
            <div className={"new-profile-panel panel panel-default " + this.props.teamCondition}>
              <div className="panel-heading" role="tab" id="headingFive">
                <div className="d-flex align-items-center common-height website common-height-new">
                  <Link to="/workspace/team"></Link>
                  <div className="web_logo">
                    <img
                      className="option-inactive"
                      src={
                        window.location.pathname === "/workspace/team"
                          ? require("../assets/images/dashboard/workspace/team-active.png")
                              .default
                          : require("../assets/images/dashboard/workspace/team-inactive.png")
                              .default
                      }
                    />
                  </div>
                  <div className="pannel_title">
                    <h4
                      style={{
                        color:
                          window.location.pathname === "/workspace/team"
                            ? "#3064f9"
                            : "#1B2952",
                        fontFamily:
                          window.location.pathname === "/workspace/team"
                            ? "Axiforma-Bold"
                            : "Axiforma-Regular",
                      }}
                      className="panel-title"
                    >
                      Team
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          )}

          {parseInt(localStorage.getItem("customer_type")) === 4 ? (
            <div className={"new-profile-panel panel panel-default " + this.props.teamCondition}>
              <div className="panel-heading" role="tab" id="headingSix">
                <div className="d-flex align-items-center common-height download common-height-new">
                  <a
                    href={`${process.env.REACT_APP_WOOCOMMERCE_BILLING_URL}`}
                    target="_blank"
                  ></a>
                  <div className="web_logo">
                    <img
                      className="option-inactive"
                      src={
                        window.location.pathname === "/subscriptions"
                          ? require("../assets/images/dashboard/workspace/billingandpayments-active.png")
                              .default
                          : require("../assets/images/dashboard/workspace/billingandpayments-inactive.png")
                              .default
                      }
                    />
                  </div>
                  <div className="pannel_title">
                    <h4
                      style={{
                        color:
                          window.location.pathname === "/subscriptions"
                            ? "#3064f9"
                            : "#1B2952",
                        fontFamily:
                          window.location.pathname === "/subscriptions"
                            ? "Axiforma-Bold"
                            : "Axiforma-Regular",
                      }}
                      className="panel-title"
                    >
                      Billing{" "}
                      <img
                        src={
                          require("../assets/images/dashboard/Goto.png").default
                        }
                        className="gotoappicon appear-spacing"
                        alt="Goto"
                      />
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            parseInt(localStorage.getItem("logged_in_user_role_id"))!==3 &&   parseInt(localStorage.getItem("logged_in_user_role_id"))!==4 && 
            <div className={"new-profile-panel panel panel-default " + this.props.teamCondition}>
              <div className="panel-heading" role="tab" id="headingSix">
                <div className="d-flex align-items-center common-height download common-height-new">
                  <Link to={"/subscriptions?type=workspace&id="+btoa(localStorage.getItem("workspaceId"))}></Link>
                  <div className="web_logo">
                    <img
                      className="option-inactive"
                      src={
                        window.location.pathname === "/subscriptions"
                          ? require("../assets/images/dashboard/workspace/billingandpayments-active.png")
                              .default
                          : require("../assets/images/dashboard/workspace/billingandpayments-inactive.png")
                              .default
                      }
                    />
                  </div>
                  <div className="pannel_title">
                    <h4
                      style={{
                        color:
                          window.location.pathname === "/subscriptions"
                            ? "#3064f9"
                            : "#1B2952",
                        fontFamily:
                          window.location.pathname === "/subscriptions"
                            ? "Axiforma-Bold"
                            : "Axiforma-Regular",
                      }}
                      className="panel-title"
                    >
                      Billing
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
