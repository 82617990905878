import React from "react";

const ThemeBookmarkIcon = ({iconColor,allBlack}) => {
  return (
    <svg
     width="15.354"
    height="15.353"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.2585 58C48.4178 57.9965 47.6063 57.6913 46.9715 57.14L29.9985 42.521L13.0245 57.14C12.3898 57.6913 11.5783 57.9965 10.7375 58C9.80035 57.9952 8.90255 57.6224 8.23755 56.962C7.89483 56.6198 7.6237 56.2128 7.43999 55.7646C7.25628 55.3165 7.16366 54.8363 7.16755 54.352V9.331C7.13513 7.42296 7.86047 5.5799 9.1846 4.20573C10.5087 2.83155 12.3236 2.03837 14.2315 2H45.7675C47.6756 2.03811 49.4908 2.83118 50.8151 4.20538C52.1395 5.57958 52.865 7.42279 52.8325 9.331V54.352C52.8416 55.3095 52.4703 56.2314 51.8002 56.9154C51.1301 57.5994 50.216 57.9894 49.2585 58ZM29.9985 37.242L48.8295 53.461V9.331C48.8624 8.48398 48.5588 7.65836 47.9852 7.03431C47.4115 6.41026 46.6143 6.03845 45.7675 6H14.2315C13.3844 6.03793 12.5867 6.40953 12.0126 7.03364C11.4385 7.65774 11.1347 8.48365 11.1675 9.331V53.461L29.9985 37.242Z"
        fill={
            iconColor && allBlack ? iconColor : "#1a1a1a"
          }
      />
    </svg>
  );
};

export default ThemeBookmarkIcon;
