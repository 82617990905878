import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import gotoIc from "../../../assets/images/dashboard/Goto.png";
import iosDarkIc from "../../../assets/images/dashboard/iosDark.svg";
import androidDarkIc from "../../../assets/images/dashboard/androidDark.svg";
import iosIc from "../../../Agency/assets/images/ios.png";
import crossIc from "../../../assets/images/signup/cross.png";
import androidIc from "../../../Agency/assets/images/android.png";

import chartImg from "../../../assets/images/dashboard/notificationmodal.png";
const PerformaceReportModal = ({ notificationData, setNotificationData }) => {
  const FIREBASE_URL = `https://console.firebase.google.com/u/0/project/${notificationData.androidNotifyId}/notification/reporting`;
  return (
    <Modal
      isOpen={notificationData.isFirebaseModalOpen}
      centered
      dataclass="newnotification"
      className="createdNotification-review"
      toggle={() =>
        setNotificationData({
          isFirebaseModalOpen: !notificationData.isFirebaseModalOpen,
        })
      }
    >
      <ModalHeader
        toggle={() =>
          setNotificationData({
            isFirebaseModalOpen: !notificationData.isFirebaseModalOpen,
          })
        }
        className="secondnotificationmodal"
      >
        <div className="d-md-flex new-pus_notification_head align-items-center justify-content-between ">
          <div
            className="newcrossmodaltwo"
            onClick={() =>
              setNotificationData({
                isFirebaseModalOpen: !notificationData.isFirebaseModalOpen,
              })
            }
          >
            <img src={crossIc} alt="cross" />
          </div>
        </div>
      </ModalHeader>

      <ModalBody
        toggle={() =>
          setNotificationData({
            isFirebaseModalOpen: !notificationData.isFirebaseModalOpen,
          })
        }
        className="secondnewpush-notification-body"
      >
        <div className="">
          <div className="d-flex align-items-center justify-content-center">
            <img src={chartImg} className="firebasechartimg" alt="Goto" />
          </div>
        </div>
        <div className="secondmodal-texts">
          <h2 className="pushnotification-firebase-head-text">
            See how your notifications performed
          </h2>

          <p className="pushnotification-longtext">
            Get a report of your notification campaigns from Google Firebase.
          </p>

          <div className="secondmodal-pushbutton">
            {notificationData.androidNotifyId ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={FIREBASE_URL}
                className="pushfirstbutton"
              >
                <img src={androidIc} alt="android" />
                <p>Android</p>
                <img
                  src={gotoIc}
                  className="gotoappicon appear-spacing"
                  alt="Goto"
                />
              </a>
            ) : (
              <span className="firebase_button_disable">
                <img src={androidDarkIc} alt="android dark " />
                <p>Android</p>
              </span>
            )}
            {notificationData.iosNotifyId ? (
              <span
                target="_blank"
                rel="noreferrer"
                href={""}
                className="pushsecondbutton"
              >
                <img src={iosIc} alt="ios" />
                <p>ios</p>
                <img
                  src={gotoIc}
                  className="gotoappicon appear-spacing"
                  alt="Goto"
                />
              </span>
            ) : (
              <span className="firebase_button_disable">
                <img src={iosDarkIc} alt="iosdark" />
                <p>iOS</p>
              </span>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PerformaceReportModal;
