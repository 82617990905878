import React, { useState } from 'react';
import Header from '../Header';
import Leftpanel from '../Leftpanel';
import "./errorLogs.css"
import "./Emptylogs.css"
const EmptyLogs = () => {

    return(
		<>

        <div>
            <section className="  mobile-display log-nothingt-here mt-0 d-flex" style={{margin: "0 auto"}}>
                <div className="d-flex flex-column align-items-center justify-content-center mx-auto">
                <img className="logimage" src={require("../../assets/images/dashboard/logs/void-logs.png").default} />
                {/* <p className="logtext logtext-new">No logs found</p> */}
                <p className="log-subtext log-subtext-new">Nothing here at the moment. Come back later?</p>
                </div>
            </section>
            </div>
        </>
    )
}

export default EmptyLogs