import React from "react";
import {
  Getpackageaddon,
  Makepayment,
  SendpaymentinfotoserverApi,
  Countrylist,
  BillingApi,
  GetpackageAll,
  Makestrippayment,
  WorkspaceAddon,
  proratedCalculation,
  MakestripWorkspacePayment,
  workspacePlanListing,
} from "../../Api/Dashboard/DashboardApi";
import styles from "./checkout.css";
import Header from "../Header";
import { Spinner } from "reactstrap";
import Leftpanel from "../Leftpanel";
import { Link } from "react-router-dom";
import { Addcard } from "../../Api/Paymentmethod/Paymentapi";
import {
  newAddonStripeApi,
  paddleChangePlan,
  paddleGeneratePayLink,
  Purchasenewversion,
  zohoAddonPurchaseAPI,
} from "../../Api/Addons/Addonsapi";
import { errortoast } from "../Toaster";
import {
  Media,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  FormGroup,
} from "reactstrap";

export default class Alladdons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addondata: [],
      checkspinner: true,
      packagedetail: [],
      plan_code: "",
      submitval: "Make payment",
      loader: "d-none",
      loaderimage: require("../../assets/images/signup/Loader_GIF.gif").default,
      gatwayresponse: "",
      finalamount: "",
      packageid: [],
      lifetimepackage: [],
      yearlypackage: [],
      monthlypackage: [],
      subscriptionbool: "",
      recurring_price: "",
      interval_unit: "",
      package_name: "",
      zoho_plan_code: "",
      subscription_id: "",
      addon_code: "",
      plandata: "",
      subscriptiondata: "",
      subscriptioninfo:"",
      searchAppVal:"",
      paddlecountry:"US",
      customer_billing_type: null,
      searchAppVal:"",
      app_id: "",
      openAppDropdown:false,
      openAppDropdown1:false,
      modaladdoncode: "",
      modaladdonname: "",
      coupanamount: 0,
      paymentstateforsend: "",
      currency: "",
      discountdata: [],
      countryselectfield: [],
      showform: undefined,
      formbuttondisabled: false,
      agree: false,
      //billing code
      first_name: "",
      last_name: "",
      userid:"",
      customer_id:"",
      company_name: "",
      billing_address: "",
      billing_city: "",
      billing_state: "",
      billing_zip: "",
      billing_country: "",
      billing_country_code:"",
      email: "",
      tax_number: "",
      recurring_addons_purchased_json: null,
      onetime_addons_purchased_json: null,
      monthlydata: null,
      yearlydata: null,

      lifetimedata: null,
      new_package_list: null,
      payment_methods: [],
      payment_method_id: "",
      isPlan: this.props?.match?.params?.isPlan,
      website_technology: this.props.match.params.website_technology,
      amount_to_pay: "",
      prorated_credits: "",
      purchase_amount: "",
      newpurchase_amount: "",
      newpurchasetax_amount:"",
      newpurchasegross_amount:"",
      applied_credits: "",
      addon_quantity: "",
      multisite: "",
      planCode2: "",
      selected_addon_code: [],
      new_add_user_quantity: parseInt(atob(this.props.match.params.quantity)),
      add_user_quantity: null,
      addCardLoader: "d-none",
      addCardText: "Add a card",
      paddleTab: "checkout",
      ams_order_id: "",
      paddleNet: "",
      paddleGross: "",
      paddleTaxrate:"",
      paddleTax: "",
      gateway_subscription_id: "",
      subscription_plan: "",
      currency:"$",
      currency1:"",
      reloadvalue:1,
      appOrWpOrAgency:this.props.match.params.appOrWpOrAgency,
      workspaceId:
        localStorage.getItem("workspaceId") !== "" &&
        localStorage.getItem("workspaceId") !== "undefined" &&
        localStorage.getItem("workspaceId") !== undefined &&
        localStorage.getItem("workspaceId") !== null
          ? localStorage.getItem("workspaceId")
          : null,
      checker_web_technology:
        this.props.match.params.website_technology !== "" &&
        this.props.match.params.website_technology !== "undefined" &&
        this.props.match.params.website_technology !== undefined &&
        this.props.match.params.website_technology !== null
          ? this.props.match.params.website_technology
          : localStorage.getItem("webWorkspace_technology"),
      addon_package_duration_id:3,
      userDiffType:0 ,
      selected_addon_data:null ,
      grantPermission: ( parseInt(this.props?.match?.params?.isPlan) === 0 && this.props?.location?.state?.addon_type === 1 ) ? false : true,
      subscriptionToCheck: null
    };
    this._isMounted = false;
   
  }

  

  addcardbutton = (e) => {
    // return;
    if(this.state.tax_number !== '')
    {
      if(this.state.company_name === '')
      {
        errortoast('Please enter company name');
        return false;
      }
    }

    if(this.state.billing_country === '')
    {
        errortoast('Please select country');
        return false;
    }

    e.preventDefault();

    const params = {
      app_id:
        localStorage.getItem("workspaceId") === "" ||
        localStorage.getItem("workspaceId") === undefined ||
        localStorage.getItem("workspaceId") === null
          ? parseInt(this.state.app_id)
          : "",
      workspace_id:
        localStorage.getItem("workspaceId") === "" ||
        localStorage.getItem("workspaceId") === undefined ||
        localStorage.getItem("workspaceId") === null
          ? ""
          : parseInt(localStorage.getItem("workspaceId")),
      path:
        parseInt(this.state.isPlan) === 0
          ? this.state.payment_methods.length > 0
            ? window.location.pathname
            : `/transaction-successful/${btoa("paddle")}?product=addon&action=upgrade`
          : window.location.pathname,
    };

    let billingparam;
    if (
      localStorage.getItem("workspaceId") !== "" &&
      localStorage.getItem("workspaceId") !== "undefined" &&
      localStorage.getItem("workspaceId") !== undefined &&
      localStorage.getItem("workspaceId") !== null
    ) {
      billingparam = {
        workspace_id: parseInt(localStorage.getItem("workspaceId")),
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        company_name: this.state.company_name,
        billing_address: this.state.billing_address,
        billing_city: this.state.billing_city,
        billing_state: this.state.billing_state,
        billing_zip: this.state.billing_zip,
        billing_country: this.state.billing_country,
        tax_number: this.state.tax_number,
      };
    } else {
      billingparam = {
        app_id: parseInt(this.state.app_id),
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        company_name: this.state.company_name,
        billing_address: this.state.billing_address,
        billing_city: this.state.billing_city,
        billing_state: this.state.billing_state,
        billing_zip: this.state.billing_zip,
        billing_country: this.state.billing_country,
        tax_number: this.state.tax_number,
      };
    }

    this.setState({
      addCardLoader: "d-block",
      addCardText: "",
    });
    BillingApi(billingparam, this, () => {
      Addcard(params, this);
    });
  };

  handleAppdropdown = () => {
   
    this.setState({
      openAppDropdown: !this.state.openAppDropdown,
    });
  };
  handleAppdropdown1 = () => {
   
    this.setState({
      openAppDropdown1: !this.state.openAppDropdown1,
    });
  };
  componentDidMount() {
    var isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') == -1 &&
    navigator.userAgent.indexOf('FxiOS') == -1;

    if(localStorage.getItem('reload') === null && (!(isSafari)))
    {
      localStorage.setItem('reload',true);
      window.location.reload();
    }
    const Paddle = window.Paddle;

    if 
    (
      window.location.host.indexOf("localhost") > -1 ||
      window.location.host.indexOf("test.app") > -1 ||
      window.location.host.indexOf("stage.app") > -1 ||
      window.location.host.indexOf("dev.app") > -1
    ) 
    {
      window.Paddle.Environment.set("sandbox");
    }
    
    Paddle.Setup({
      vendor: parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID), 
      eventCallback: (data) => this.newloadPaddleCheckout(data),

    });
    


    Countrylist(this);
    this.setState({
      interval_unit: atob(this.props.match.params.interval_unit),
      package_name: atob(this.props.match.params.package_name),
      plan_code: this.props.match.params.planname,
      app_id: localStorage.getItem("Appid"),
    });
    const params = this.props.match.params.id;

    const planCode = this.props.match.params.package_name;
    Getpackageaddon(
      params,
      this.state.isPlan,
      planCode,
      this,
      this.state.workspaceId,
      () => 
      {

        localStorage.removeItem('checkoutpageamount');
        localStorage.removeItem('checkoutsubscriptionid');
        localStorage.removeItem('checkoutcustomerid');
        
        let allPlanObject;
        const params = {
          appid: btoa(this.state.app_id),
          tech : localStorage.getItem("website_technology"),
        };
        
        if(this.state.customer_billing_type == 3)
        {
            var script = document.createElement("script");
            script.innerHTML =
              '(function(t,a,p){t.TapfiliateObject=a;t[a]=t[a]||function(){ (t[a].q=t[a].q||[]).push(arguments)}})(window,"tap");tap("create", "22877-d64cea", { integration: "paddle" });tap("detect");'
            script.type = "text/javascript";
            document.body.appendChild(script);
        }
        else if(this.state.customer_billing_type == 2)
        {
          var script = document.createElement("script");
          script.innerHTML =
            '(function(t,a,p){t.TapfiliateObject=a;t[a]=t[a]||function(){ (t[a].q=t[a].q||[]).push(arguments)}})(window,"tap");tap("create", "22877-d64cea", { integration: "stripe" });tap("detect");'
          script.type = "text/javascript";
          document.body.appendChild(script);
        }

        if ((this.state.customer_billing_type === 3 && this.state.appOrWpOrAgency !== 'agency' ) || (this.state.appOrWpOrAgency === 'agency' && parseInt(this.state.isPlan)=== 1 ) )
        {
          // if(parseInt(this.state.isPlan)===0 && this.props.location.state.addon_identifier==="USER_MANAGEMENT" && this.state.addon_quantity > 0){

          // }else{
            Paddle.Product.Prices(parseInt(this.props.match.params.planname),
            ( (parseInt(this.state.isPlan)===0 && (  localStorage.getItem("addon_identifier") ==="USER_MANAGEMENT" ) )
            ? this.state.new_add_user_quantity:1), (prices) =>{
            this.settingPaddlesetPrices(prices) }
            );
        }
        // }
        
        if 
        (
          localStorage.getItem("workspaceId") !== "" &&
          localStorage.getItem("workspaceId") !== null &&
          localStorage.getItem("workspaceId") !== undefined
        ) 
        {
          workspacePlanListing(this, this.state.checker_web_technology, () => {
            let planobject = [];
            if (this.state.monthlydata !== undefined) 
            {
                 planobject = this.state.monthlydata.concat(
                  this.state.yearlydata
                );
            }

            if (this.state.lifetimedata !== undefined) {
              planobject = planobject.concat(this.state.lifetimedata);
            }

            this.setState({
              new_package_list: planobject.filter(
                (res) => res.plan_code === this.props.match.params.planname
              ),
              payment_method_id:
                this.state.payment_methods.length === 0
                  ? ""
                  : parseInt(this.state.payment_methods[0].id),
            });
            this.setState({
              checkspinner: false,
            });
            // if (this.state.customer_billing_type === 2) {
            //   if (this.state.payment_methods.length === 0) {
            //     const params = {
            //       app_id: parseInt(this.state.app_id),
            //       path: window.location.pathname,
            //     };
            //     Addcard(params, this);
            //   } else {
            //     this.setState({
            //       checkspinner: false,
            //     });
            //   }
            // } else {
            //   this.setState({
            //     checkspinner: false,
            //   });
            // }

            if (this.props.match.params.planname.indexOf("yearly") > -1) {
              this.setState({
                new_package_list: this.state.yearlydata.filter(
                  (res) => res.plan_code === this.props.match.params.planname
                ),
              });
            }
            if (this.props.match.params.planname.indexOf("monthly") > -1) {
              this.setState({
                new_package_list: this.state.monthlydata.filter(
                  (res) => res.plan_code === this.props.match.params.planname
                ),
              });
            }
          });
        } 
        else 
        {
          if(this.state.appOrWpOrAgency!=="agency")
          {
            GetpackageAll(this, params, () => 
            {
              var planobject = [];

              if(this.state.monthlydata !== undefined)
              {
                planobject = this.state.monthlydata.concat(
                  this.state.yearlydata
                );
              }
              

              if (this.state.lifetimedata !== undefined) {
                planobject = planobject.concat(this.state.lifetimedata);
              }
              // let allPlanObject = planobject.concat(this.state.lifetimedata);
            
              this.setState({
                new_package_list: planobject.filter(
                  (res) => res.plan_code === this.props.match.params.planname
                ),
                payment_method_id:
                  this.state.payment_methods.length === 0
                    ? ""
                    : parseInt(this.state.payment_methods[0].id),
              });
              this.setState({
                checkspinner: false,
              });
              // if (this.state.customer_billing_type === 2) {
              //   if (this.state.payment_methods.length === 0) {
              //     const params = {
              //       app_id: parseInt(this.state.app_id),
              //       path: window.location.pathname,
              //     };
              //     Addcard(params, this);
              //   } else {
              //     this.setState({
              //       checkspinner: false,
              //     });
              //   }
              // } else {
              //   this.setState({
              //     checkspinner: false,
              //   });
              // }

              if (this.props.match.params.planname.indexOf("yearly") > -1) 
              {
                if(this.state.yearlydata !== undefined)
                {
                  this.setState({
                    new_package_list: this.state.yearlydata.filter(
                      (res) => res.plan_code === this.props.match.params.planname
                    ),
                  });
                }
                else
                {
                  this.setState({new_package_list: this.props.match.params.planname});
                }
              }
              if (this.props.match.params.planname.indexOf("monthly") > -1) 
              {
                if(this.state.monthlydata !== undefined)
                {
                  this.setState({
                    new_package_list: this.state.monthlydata.filter(
                      (res) => res.plan_code === this.props.match.params.planname
                    ),
                  });
                }
                else{
                  this.setState({new_package_list: this.props.match.params.planname});
                }
              }
            });
          }
          else
          {
            this.setState({
              checkspinner: (this.state.appOrWpOrAgency === "agency")? this.state.checkspinner :false,
            });
          }
        }
        let paymentstateforsend = {};
        paymentstateforsend.zoho_plan_code = this.props.match.params.planname;
        paymentstateforsend.type = 1;
        paymentstateforsend.amount = Number(this.state.plandata.amount);
        paymentstateforsend.quantity = 1;
        this.setState({
          paymentstateforsend: paymentstateforsend,
        });
           //new code
    const paramforprorated = {
      app_id:
      this.state.appOrWpOrAgency==="agency" ? null :
        localStorage.getItem("workspaceId") === "" ||
        localStorage.getItem("workspaceId") === undefined ||
        localStorage.getItem("workspaceId") === null
          ? parseInt(localStorage.getItem("Appid"))
          : "",
      workspace_id:
      this.state.appOrWpOrAgency==="agency" ? null :
        localStorage.getItem("workspaceId") === "" ||
        localStorage.getItem("workspaceId") === undefined ||
        localStorage.getItem("workspaceId") === null
          ? ""
          : parseInt(localStorage.getItem("workspaceId")),
      agency_id:this.state.appOrWpOrAgency==="agency" ? parseInt(localStorage.getItem("agency_id") ) : null,
      plan_code:
        parseInt(this.state.isPlan) === 1
          ? this.props.match.params.planname
          : "",
      addon_code:
        parseInt(this.state.isPlan) === 0
          ? this.props.match.params.planname
          : "",
      addon_quantity: 
          parseInt(this.state.isPlan) === 0
          ? (localStorage.getItem("addon_identifier") ==="USER_MANAGEMENT" && parseInt(this.state.isPlan)===0 ) 
          // this.props.match.params.planname === "appsumo_team_member_yearly"
            ? this.state.new_add_user_quantity
            : 1
          : "",
      app_count:parseInt(this.props.match.params.agencyAppCount) ,
      user_count:parseInt(this.props.match.params.agencyUserCount),
      is_support_plan:0,
    };

    proratedCalculation(paramforprorated, this,(this.state.appOrWpOrAgency === "agency"?true:false));
      }
    );
 
  }

  componentWillUnmount() {
    this._isMounted = false;
    
    window.Paddle.Setup({
      vendor: parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID), 
      eventCallback: (data) => this.newloadPaddleCheckout(data),

    });
  }

  settingPaddlesetPrices = (prices) => {
    this.setState({
      paddlecountry:prices.country,
      newpurchase_amount: prices.price.net,
      newpurchasetax_amount: prices.price.tax,
      newpurchasegross_amount: prices.price.gross,
    });
        
          //  this.state.countryselectfield.map(
          //   (res, i) => (
          //     this.state.billing_country_code === '' && res.country_code === prices.country ?
          //     this.setState({billing_country:res.id,billing_country_code:res.country_code})
          //     : res.country_code === prices.country &&  this.state.billing_country_code !== ''  && res.id === this.state.billing_country_code ?
          //     this.setState({billing_country:res.id,billing_country_code:res.country_code})
          //     : null
          //   )
          //  )
       
  };


  settingPaddlePrices = (prices) => {
    this.setState({
      paddleNet: prices.price.net,
      paddleGross: prices.price.gross,
      paddleTax: prices.price.tax,
    });
  };

  newloadPaddleCheckout = (data) =>
    {

      if(data.event == 'Checkout.Coupon.Remove' || data.event == 'Checkout.Loaded' || data.event == 'Checkout.Payment.Selection' || data.event == 'Checkout.Vat.Applied' || data.event == 'Checkout.Vat.Remove')
      {
        
        if(data.eventData !== undefined && data.eventData !== null)
        {
          var taxrate1 = (data.eventData.checkout.prices.customer.items[0].tax_rate.toFixed(2));

          var taxrate = '('+(taxrate1*100).toFixed(2)+'%)';
         
          var gross;
          var net;
          var tax;
          var coupanamount = 0;
          if(data.eventData.checkout.prices.customer.items[0].recurring!==null)
          {
            net = this.newcurrencysymbolFormater((data.eventData.checkout.prices.customer.items[0].recurring.line_price.gross - data.eventData.checkout.prices.customer.items[0].recurring.line_price.tax).toFixed(2),data.eventData.checkout.prices.customer.currency);
            if(data.eventData.checkout.prices.customer.items[0] && data.eventData.checkout.prices.customer.items[0].discounts.length > 0)
            {
              coupanamount = this.newcurrencysymbolFormater((data.eventData.checkout.prices.customer.items[0].display_price.line_price.net_discount),data.eventData.checkout.prices.customer.currency);

              gross = this.newcurrencysymbolFormater((data.eventData.checkout.prices.customer.items[0].display_price.line_price.gross_after_discount),data.eventData.checkout.prices.customer.currency);
              tax = this.newcurrencysymbolFormater((data.eventData.checkout.prices.customer.items[0].display_price.line_price.tax_after_discount),data.eventData.checkout.prices.customer.currency);
            
            }
            else
            {
              gross = this.newcurrencysymbolFormater((data.eventData.checkout.prices.customer.items[0].recurring.line_price.gross),data.eventData.checkout.prices.customer.currency);
              tax = this.newcurrencysymbolFormater((data.eventData.checkout.prices.customer.items[0].recurring.line_price.tax),data.eventData.checkout.prices.customer.currency);
            
            }
            
          }
          else
          {
             net = this.newcurrencysymbolFormater((data.eventData.checkout.prices.customer.unit - data.eventData.checkout.prices.customer.unit_tax).toFixed(2),data.eventData.checkout.prices.customer.currency);
             tax = this.newcurrencysymbolFormater((data.eventData.checkout.prices.customer.unit_tax),data.eventData.checkout.prices.customer.currency);
             if(data.eventData.checkout.prices.customer.items[0] &&  data.eventData.checkout.prices.customer.items[0].discounts.length > 0)
             {
               coupanamount = this.newcurrencysymbolFormater((data.eventData.checkout.prices.customer.items[0].display_price.line_price.net_discount),data.eventData.checkout.prices.customer.currency);
               gross = this.newcurrencysymbolFormater((data.eventData.checkout.prices.customer.items[0].display_price.line_price.gross_after_discount),data.eventData.checkout.prices.customer.currency);
               tax = this.newcurrencysymbolFormater((data.eventData.checkout.prices.customer.items[0].display_price.line_price.tax_after_discount),data.eventData.checkout.prices.customer.currency);
            
              }
             else
             {
                gross = this.newcurrencysymbolFormater((data.eventData.checkout.prices.customer.total),data.eventData.checkout.prices.customer.currency);
                if(data.eventData.checkout.prices.customer.items[0].recurring)
                  {
                    tax = this.newcurrencysymbolFormater((data.eventData.checkout.prices.customer.items[0].recurring.line_price.tax),data.eventData.checkout.prices.customer.currency);
                  }
                  else
                  {
                    tax = this.newcurrencysymbolFormater((data.eventData.checkout.prices.customer.items[0].line_price.tax),data.eventData.checkout.prices.customer.currency);

                  }

            
             }
  

          }

         
          this.setState({
            paddleGross: gross,
            paddleNet: net,
            paddleTax: tax,
            paddleTaxrate: taxrate,
            coupanamount:coupanamount
          }, () => this.loadEvents(gross, net,tax,taxrate,coupanamount));
       
        }
       
      }

      
    }

    loadEvents(paddleGross,paddleNet,paddleTax,paddleTaxrate,coupanamount) 
    {
   
      if(paddleNet !== this.state.paddleNet) 
      {
        this.setState({
                paddleNet: paddleNet,
                paddleGross: paddleGross,
                paddleTax: paddleTax,
                paddleTaxrate: paddleTaxrate,
                coupanamount:coupanamount,
                })
      }
    }

  loadPaddleCheckout = (data) => {
    window.Paddle.Product.Prices(parseInt(this.state.plan_code), (prices) =>
      this.settingPaddlePrices(prices)
    );

    this.setState({
      paddleTab: "payment",
      loader: "d-none",
      submitval: "Make payment",
      // disabledsubmitbutton: false,
    });
  };

  openPaddleCheckout = (overrideURL, ams_order_id) => {
    // // window.Paddle.Spinner.hide();
    // this.setState({
    //   paddleTab: "payment",
    //   loader: "d-none",
    //   submitval: "Make payment",
    // });
    let passthroughObj;
    if(this.state.appOrWpOrAgency==="agency"){
      passthroughObj = {
    
        agency_id:parseInt(localStorage.getItem("agency_id")),
        type:  parseInt(this.state.isPlan) === 1 ? "plan" :
        ( this.state.plandata.package_duration_id===1 || this.state.plandata.package_duration_id===2 )
        ? "recurring-addon":  "onetime-addon",
        ams_order_id: ams_order_id,
        // app_count:parseInt(this.props.match.params.agencyAppCount) ,
        // user_count:parseInt(this.props.match.params.agencyUserCount),
      };
    }else{

    
    if (
      localStorage.getItem("workspaceId") !== "" &&
      localStorage.getItem("workspaceId") !== "undefined" &&
      localStorage.getItem("workspaceId") !== undefined &&
      localStorage.getItem("workspaceId") !== null
    ) {
      if (parseInt(this.state.isPlan) === 1) 
      {
        passthroughObj = {
          workspace_id: parseInt(localStorage.getItem("workspaceId")),
          
          type: "plan",
          ams_order_id: ams_order_id,
     
        };
      } else {
       
        passthroughObj = {
          app_id: parseInt(localStorage.getItem("Appid")),
     
          // type:"addon" ,
         type: (this.state.plandata.package_duration_id===1 || this.state.plandata.package_duration_id===2 ) ? 
          "recurring-addon":  "onetime-addon",
          ams_order_id: ams_order_id,
        
        };
      }
    } else {
      passthroughObj = {
        app_id: parseInt(localStorage.getItem("Appid")),
     
        type: 
        parseInt(this.state.isPlan) === 1 ? "plan" :
         ( this.state.plandata.package_duration_id===1 || this.state.plandata.package_duration_id===2 )
         ? "recurring-addon":  "onetime-addon",
        ams_order_id: ams_order_id,
  
      };
    }
  }
    window.Paddle.Checkout.open({
      // email: this.state.email, //prefill email
      // product: parseInt(this.state.plan_code), //subscription_id
      country: this.state.countryselectfield
        ? this.state.countryselectfield[
            parseInt(this.state.billing_country) - 1
          ].country_code
        : "",
      postcode: this.state.billing_zip,
      passthrough: passthroughObj,
      override: overrideURL,
      successCallback: (data) => this.checkoutComplete(data, this.state.isPlan),
      closeCallback: this.checkoutClosed,
      method: "inline",
      loadCallback: this.loadPaddleCheckout(),
      frameTarget: "paddle-payment", // The className of your checkout <div>
      frameInitialHeight: 416,
      inputStyle: "background-color: #FF0000;",
      frameStyle:
        "width:100%; min-width:312px; background-color: transparent; border: none;", // Please ensure the minimum width is kept at or above 286px with checkout padding disabled, or 312px with checkout padding enabled. See "General" section under "Branded Inline Checkout" below for more information on checkout padding.
    });
  };

  checkoutClosed(data) {
    // this.setState({
    //   loader: "d-none",
    //   submitval: "Make Payment",
    // });
    // errortoast(
    //   "Your purchase has been cancelled, we hope to see you again soon!"
    // );

    // alert("Your purchase has been cancelled, we hope to see you again soon!");
  }

  checkoutComplete(data, isPlan) {
    // this.setState({
    //   loader: "d-none",
    //   submitval: "Make Payment",
    // });

    var metaData = {
      customer_id: this.state.customer_id,
      email: this.state.email,
      userid: this.state.userid,
    };
    
    var order_amount = (data.checkout.prices.vendor.total - data.checkout.prices.vendor.total_tax).toFixed(2);
  if(this.state.appOrWpOrAgency==="agency")
  {
          localStorage.setItem('checkoutpageamount',order_amount);
          localStorage.setItem('checkoutsubscriptionid',data.checkout.id);
        if(this.state.customer_id &&  this.state.customer_id != '' && this.state.customer_id != null && this.state.customer_id != 'null')
        {
          localStorage.setItem('checkoutcustomerid',this.state.customer_id);
          window.tap('conversion', (data.checkout.id).toString(), order_amount , {meta_data: metaData,customer_id:(this.state.customer_id).toString()},'agency');
        }
        else
        {
          localStorage.setItem('checkoutcustomerid',this.state.userid);
          window.tap('conversion', (data.checkout.id).toString(), order_amount , {meta_data: metaData,customer_id:(this.state.userid).toString()},'agency');
        }
          
       
  }
  else
  {
          localStorage.setItem('checkoutpageamount',order_amount);
          localStorage.setItem('checkoutsubscriptionid',data.checkout.id);
          localStorage.setItem('checkoutcustomerid',this.state.customer_id);
          if(this.state.customer_id &&  this.state.customer_id != '' && this.state.customer_id != null && this.state.customer_id != 'null')
        {
          localStorage.setItem('checkoutcustomerid',this.state.customer_id);
          window.tap('conversion', (data.checkout.id).toString(), order_amount , {meta_data: metaData,customer_id:(this.state.customer_id).toString()});
        }
        else
        {
          localStorage.setItem('checkoutcustomerid',this.state.userid);
          window.tap('conversion', (data.checkout.id).toString(), order_amount , {meta_data: metaData,customer_id:(this.state.userid).toString()});
        }
       
  }
    

    if (parseInt(isPlan) === 1) 
    {
      const userinfo  = JSON.parse(localStorage.getItem("user_info"));
      if(this.state.appOrWpOrAgency==="agency")
      {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
        'event': 'checkout_successful',
        'user_email': userinfo.email,
        'user_name' : userinfo.name,
        'user_id': userinfo.id,
        'agency_id':localStorage.getItem('agency_id') ? parseInt(localStorage.getItem('agency_id')) : 1,
        'transactionDetails': [{
              'product_type': 'plan',
              'product_action':'upgrade',
              'plan_type': 'agency',       
              'price': order_amount ,
              'currency': this.state.currency1
        }]
        });
      }
      else
      {
                if(localStorage.getItem("workspaceId") !== "" &&
                localStorage.getItem("workspaceId") !== "undefined" &&
                localStorage.getItem("workspaceId") !== undefined &&
                localStorage.getItem("workspaceId") !== null)
                {
                  var type = 'workspace';
                }
                else
                {
                  var type = 'pay-per-app';
                }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
        'event': 'checkout_successful',
        'user_email': userinfo.email,
        'user_name' : userinfo.name,
        'user_id': userinfo.id,
        'agency_id':localStorage.getItem('agency_id') ? parseInt(localStorage.getItem('agency_id')) : 1,
        'transactionDetails': [{
              'product_type': 'plan',
              'product_action':'upgrade',
              'plan_type': type,       
              'price': order_amount ,
              'currency': this.state.currency1
        }]
        });
      }
     
      window.location.href =`/transaction-successful/${btoa("paddle")}?product=${parseInt(isPlan) === 1 ?  "plan" :  "addon"}&action=upgrade`;

    } 
    else 
    {
      if(this.state.appOrWpOrAgency==="agency"){
        window.location.href =`/transaction-successful/${btoa("paddle")}?product=${parseInt(isPlan) === 1 ?  "plan" :  "addon"}&action=upgrade`;
      }else{
      window.location.href =`/transaction-successful/${btoa("paddle")}?product=${parseInt(isPlan) === 1 ?  "plan" :  "addon"}&action=upgrade`;
      }
    }
  }
  handleagree = (e) => {
    this.setState({
      agree: e.target.value,
    });
  };
  makepayment = (e) => 
  {
    // if(!this.state.agree)
    // {
    //   errortoast('Please agree term condition and privacy policy');
    //   return false;
    // }

    if(!this.state.formbuttondisabled)
    {
        e.preventDefault();
        if(this.state.tax_number !== '')
        {
          if(this.state.company_name === '')
          {
            errortoast('Please enter company name');
            return false;
          }
        }

        if(this.state.billing_country === '')
        {
            errortoast('Please select country');
            return false;
        }

        this.setState({
          formbuttondisabled: true,
        });

        let addonandpaymnet = [this.state.paymentstateforsend];
        const sendparam = {
          app_id: this.state.app_id,
          total: this.state.recurring_price,
          plan_array: addonandpaymnet,
          currency: "$",
          order_type: "upgrade",
        };

        let billingparam;
        if (
          localStorage.getItem("workspaceId") !== "" &&
          localStorage.getItem("workspaceId") !== "undefined" &&
          localStorage.getItem("workspaceId") !== undefined &&
          localStorage.getItem("workspaceId") !== null
        ) {
          billingparam = {
            workspace_id:this.state.appOrWpOrAgency==="agency"?null: parseInt(localStorage.getItem("workspaceId")),
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            company_name: this.state.company_name,
            billing_address: this.state.billing_address,
            billing_city: this.state.billing_city,
            billing_state: this.state.billing_state,
            billing_zip: this.state.billing_zip,
            billing_country: this.state.billing_country,
            tax_number: this.state.tax_number,
            agency_id:localStorage.getItem('agency_id') ? parseInt(localStorage.getItem('agency_id')) : 1
          };
        } else {
          billingparam = {
            app_id:this.state.appOrWpOrAgency==="agency"?null: parseInt(this.state.app_id),
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            company_name: this.state.company_name,
            billing_address: this.state.billing_address,
            billing_city: this.state.billing_city,
            billing_state: this.state.billing_state,
            billing_zip: this.state.billing_zip,
            billing_country: this.state.billing_country,
            tax_number: this.state.tax_number,
            agency_id:localStorage.getItem('agency_id') ? parseInt(localStorage.getItem('agency_id')) : 1
          };
        }

        const params = {
          app_id: this.state.app_id,
          plan_code: this.state.plan_code,
          addon_payment: 0,
          // addon_array: this.jsonarray(this.state.packageid),
          addon_array: [],
          subscription_id: this.state.subscription_id,
          deal_id: null,
          action: "",
          product: "",
        };

        

       
        //Payment Api
        BillingApi(billingparam, this, () => {
          if (
            localStorage.getItem("workspaceId") !== "" &&
            localStorage.getItem("workspaceId") !== "undefined" &&
            localStorage.getItem("workspaceId") !== undefined &&
            localStorage.getItem("workspaceId") !== null && this.state.appOrWpOrAgency !=="agency" 
          ) {
            const workspaceParam = {
              workspace_id: parseInt(localStorage.getItem("workspaceId")),
              agency_id:null ,
              plan_array: [
                {
                  price_id: this.props.match.params.planname,
                  // this.state.new_package_list.length > 0
                  //   ? this.state.new_package_list[0].plan_code
                  //   : "",
                  quantity: 1,
                  type: 1,
                },
              ],

              payment_method_id: parseInt(this.state.payment_method_id) || "payment_method_id",
              order_type: 1,
              deal_id: "",
              // action: this.state.payment_method_id,
            };
            const paddleParam = {
              workspace_id:
                localStorage.getItem("workspaceId") !== "" &&
                localStorage.getItem("workspaceId") !== "undefined" &&
                localStorage.getItem("workspaceId") !== undefined &&
                localStorage.getItem("workspaceId") !== null
                  ? parseInt(localStorage.getItem("workspaceId"))
                  : null,
              app_id:
                localStorage.getItem("workspaceId") !== "" &&
                localStorage.getItem("workspaceId") !== "undefined" &&
                localStorage.getItem("workspaceId") !== undefined &&
                localStorage.getItem("workspaceId") !== null
                  ? null
                  : parseInt(this.state.app_id),
              agency_id:null ,
              deal_id: null,
              order_type: 1,
              price_id: this.state.plan_code,
              quantity: 1,
              type: 1,
            };
            const linkGenerateParam = {
              workspace_id:
                localStorage.getItem("workspaceId") !== "" &&
                localStorage.getItem("workspaceId") !== "undefined" &&
                localStorage.getItem("workspaceId") !== undefined &&
                localStorage.getItem("workspaceId") !== null
                  ? parseInt(localStorage.getItem("workspaceId"))
                  : null,
              app_id:
                localStorage.getItem("workspaceId") !== "" &&
                localStorage.getItem("workspaceId") !== "undefined" &&
                localStorage.getItem("workspaceId") !== undefined &&
                localStorage.getItem("workspaceId") !== null
                  ? null
                  : parseInt(this.state.app_id),
              agency_id:null ,
              price_id: this.state.plan_code,
              quantity: 1,
              type: 1,
          
            };
            if (this.state.customer_billing_type === 3) {
              if (
                this.state.gateway_subscription_id === "" ||
                this.state.plandata.package_duration_id === 4 
                ||   parseInt(this.state.isPlan) === 0
              ) {
                paddleGeneratePayLink(linkGenerateParam, this);
                // this.openPaddleCheckout();
              } else {
                paddleChangePlan(paddleParam, this);
              }
            } else {
              MakestripWorkspacePayment(workspaceParam, this);
            }
          } else {
            const sendtostrip = {
              app_id: parseInt(this.state.app_id),
              agency_id:null ,
              plan_array: [
                {
                  price_id:this.state.plan_code,
                  quantity: 1,
                  type: 1,
                },
              ],
              payment_method_id: parseInt(this.state.payment_method_id) || "payment_method_id",
              order_type: 1,
              deal_id: null,
              // action: this.state.payment_method_id,
            };
            const paddleParam = {
              workspace_id:this.state.appOrWpOrAgency==="agency" ? null :
                localStorage.getItem("workspaceId") !== "" &&
                localStorage.getItem("workspaceId") !== "undefined" &&
                localStorage.getItem("workspaceId") !== undefined &&
                localStorage.getItem("workspaceId") !== null
                  ? parseInt(localStorage.getItem("workspaceId"))
                  : null,
              app_id: this.state.appOrWpOrAgency==="agency" ? null :
                localStorage.getItem("workspaceId") !== "" &&
                localStorage.getItem("workspaceId") !== "undefined" &&
                localStorage.getItem("workspaceId") !== undefined &&
                localStorage.getItem("workspaceId") !== null
                  ? null
                  : parseInt(this.state.app_id),
              agency_id:this.state.appOrWpOrAgency==="agency" ? parseInt(localStorage.getItem("agency_id") ) : null ,
              deal_id: null,
              order_type: 1,
              price_id: this.state.plan_code,
              quantity: 1,
              type: 1,
           
              app_count:parseInt(this.props.match.params.agencyAppCount) ,
              user_count:parseInt(this.props.match.params.agencyUserCount),
            };
            const linkGenerateParam = 
            {
              workspace_id: this.state.appOrWpOrAgency==="agency" ? null :
                localStorage.getItem("workspaceId") !== "" &&
                localStorage.getItem("workspaceId") !== "undefined" &&
                localStorage.getItem("workspaceId") !== undefined &&
                localStorage.getItem("workspaceId") !== null
                  ? parseInt(localStorage.getItem("workspaceId"))
                  : null,
              app_id: this.state.appOrWpOrAgency==="agency" ? null :
                localStorage.getItem("workspaceId") !== "" &&
                localStorage.getItem("workspaceId") !== "undefined" &&
                localStorage.getItem("workspaceId") !== undefined &&
                localStorage.getItem("workspaceId") !== null
                  ? null
                  : parseInt(this.state.app_id),
              agency_id:this.state.appOrWpOrAgency==="agency" ? parseInt(localStorage.getItem("agency_id")) :null ,
           
              app_count:parseInt(this.props.match.params.agencyAppCount) ,
              user_count:parseInt(this.props.match.params.agencyUserCount),
              price_id: this.state.plan_code,
              quantity: 1,
              type: 1,
            };

            if(this.state.customer_billing_type === 2 && this.state.appOrWpOrAgency !=="agency" ) 
            { 
            
                Makestrippayment(sendtostrip, this, "plan")
            }
            else if( this.state.customer_billing_type === 3 || this.state.appOrWpOrAgency ==="agency" ) 
            { 
                if(this.state.gateway_subscription_id === "" || this.state.plandata.package_duration_id === 4  || parseInt(this.state.isPlan) === 0
                || (this.state.subscription_id && this.state.subscriptioninfo?.currency!=='USD' && this.state.subscriptioninfo?.currency!=='$') 
              )
                {
                  paddleGeneratePayLink(linkGenerateParam, this)
                }
                else
                {
                  paddleChangePlan(paddleParam, this)
                }
            }
            else
            {
              SendpaymentinfotoserverApi(sendparam, this, () => {Makepayment(params, this);});
            }
          }
        });
    }
  };
  //addon payment quantity
  paymentapiquantity = (addonlist) => {
    if (addonlist === "add_user") {
      let quantity =
        this.state.recurring_addons_purchased_json === 0
          ? this.state.add_user_quantity
          : this.state.recurring_addons_purchased_json[addonlist].quantity === 0
          ? this.state.add_user_quantity
          : this.state.add_user_quantity;

      return quantity;
    } else if (addonlist === "appsumo_team_member_yearly") {
      let quantity =
        this.state.recurring_addons_purchased_json === 0
          ? this.state.new_add_user_quantity
          : this.state.recurring_addons_purchased_json[addonlist] === undefined
          ? this.state.new_add_user_quantity
          : this.state.new_add_user_quantity;
      return quantity;
    } else if (addonlist === "language_support") {
      let quantity =
        this.state.recurring_addons_purchased_json === 0
          ? 1
          : this.state.recurring_addons_purchased_json[addonlist].quantity === 0
          ? 1
          : this.state.recurring_addons_purchased_json[addonlist].quantity;
      return quantity;
    } else if (addonlist === "appsumo_user_consent_yearly") {
      let quantity =
        this.state.recurring_addons_purchased_json === 0 ||
        this.state.recurring_addons_purchased_json[addonlist] === undefined
          ? 1
          : this.state.recurring_addons_purchased_json[addonlist].quantity === 0
          ? 1
          : this.state.recurring_addons_purchased_json[addonlist].quantity;
      return quantity;
    } else if (addonlist === "appsumo_customer_chat_yearly") {
      let quantity =
        this.state.recurring_addons_purchased_json === 0 ||
        this.state.recurring_addons_purchased_json[addonlist] === undefined
          ? 1
          : this.state.recurring_addons_purchased_json[addonlist].quantity === 0
          ? 1
          : this.state.recurring_addons_purchased_json[addonlist].quantity;
      return quantity;
    } else if (addonlist === "appsumo_analytics_yearly") {
      let quantity =
        this.state.recurring_addons_purchased_json === 0 ||
        this.state.recurring_addons_purchased_json[addonlist] === undefined
          ? 1
          : this.state.recurring_addons_purchased_json[addonlist].quantity === 0
          ? 1
          : this.state.recurring_addons_purchased_json[addonlist].quantity;
      return quantity;
    } else if (addonlist === "appsumo_multisite_yearly") {
      let quantity =
        this.state.recurring_addons_purchased_json === 0 ||
        this.state.recurring_addons_purchased_json[addonlist] === undefined
          ? 1
          : this.state.recurring_addons_purchased_json[addonlist].quantity === 0
          ? 1
          : this.state.recurring_addons_purchased_json[addonlist].quantity;
      return quantity;
    } else if (addonlist === "appsumo_publish_yearly") {
      let quantity =
        this.state.recurring_addons_purchased_json === 0 ||
        this.state.recurring_addons_purchased_json[addonlist] === undefined
          ? 1
          : this.state.recurring_addons_purchased_json[addonlist].quantity === 0
          ? 1
          : this.state.recurring_addons_purchased_json[addonlist].quantity;
      return quantity;
    } else if (addonlist === "appsumo_white_label_yearly") {
      let quantity =
        this.state.recurring_addons_purchased_json === 0 ||
        this.state.recurring_addons_purchased_json[addonlist] === undefined
          ? 1
          : this.state.recurring_addons_purchased_json[addonlist].quantity === 0
          ? 1
          : this.state.recurring_addons_purchased_json[addonlist].quantity;
      return quantity;
    } else {
      return 1;
    }
  };

  // payment function for addon
  addonpaymentfunction = (addon_code, price, duration, ordertype, callback) => {
    //Payment Api
    let paymentstateforsend = {};
    paymentstateforsend.zoho_plan_code = addon_code;
    paymentstateforsend.type = 2;
    paymentstateforsend.amount =
      (this.state.planCode2.indexOf(process.env.REACT_APP_Lifetime_plan) > -1 ||
        this.state.planCode2 === "pro_yearly" ||
        this.state.planCode2 === "starter_yearly") &&
      duration === "monthly"
        ? Number(price) * 12
        : Number(price);
    paymentstateforsend.quantity = this.paymentapiquantity(addon_code);
    this.setState(
      {
        paymentstateforsend: paymentstateforsend,
      },
      () => {
        let addonandpaymnet = [this.state.paymentstateforsend];
        const sendparam = {
          app_id: this.state.app_id,
          total:
            (this.state.planCode2.indexOf(process.env.REACT_APP_Lifetime_plan) >
              -1 ||
              this.state.planCode2 === "pro_yearly" ||
              this.state.planCode2 === "starter_yearly") &&
            duration === "monthly"
              ? Number(price) * 12
              : Number(price),
          plan_array: addonandpaymnet,
          currency: "$",
          order_type: ordertype,
        };
        SendpaymentinfotoserverApi(sendparam, this, () => {
          callback();
        });
      }
    );
  };

  //Addon Payment json
  //ArrayObject
  jsonarray2 = (addonlist) => {
    let selected_addon_list = this.state.addon_code;

    //for removing appsumo language support lifetime and multisite lifetime end
    let convert_string_to_array = addonlist.split();
    let finalarray =
      selected_addon_list.length === 0
        ? convert_string_to_array
        : selected_addon_list.concat(convert_string_to_array);
    // Remove duplicate value from array

    finalarray = finalarray.filter(function (item, index, inputArray) {
      return inputArray.indexOf(item) === index;
    });

    let addonvalue = (item) => {
      if (item === "add_user") {
        let quantity =
          this.state.recurring_addons_purchased_json === 0
            ? 1
            : this.state.recurring_addons_purchased_json[item].quantity === 0
            ? 1
            : this.state.recurring_addons_purchased_json[item].quantity;

        return quantity;
      } else if (item === "appsumo_team_member_yearly") {
        let quantity =
          this.state.recurring_addons_purchased_json === 0
            ? this.state.new_add_user_quantity
            : this.state.recurring_addons_purchased_json[item] === undefined
            ? this.state.new_add_user_quantity
            : this.state.new_add_user_quantity;
        return quantity;
      } else if (item === "appsumo_user_consent_yearly") {
        let quantity =
          this.state.recurring_addons_purchased_json === 0 ||
          this.state.recurring_addons_purchased_json[item] === undefined
            ? 1
            : this.state.recurring_addons_purchased_json[item].quantity === 0
            ? 1
            : this.state.recurring_addons_purchased_json[item].quantity;
        return quantity;
      } else if (item === "appsumo_customer_chat_yearly") {
        let quantity =
          this.state.recurring_addons_purchased_json === 0 ||
          this.state.recurring_addons_purchased_json[item] === undefined
            ? 1
            : this.state.recurring_addons_purchased_json[item].quantity === 0
            ? 1
            : this.state.recurring_addons_purchased_json[item].quantity;
        return quantity;
      } else if (item === "appsumo_analytics_yearly") {
        let quantity =
          this.state.recurring_addons_purchased_json === 0 ||
          this.state.recurring_addons_purchased_json[item] === undefined
            ? 1
            : this.state.recurring_addons_purchased_json[item].quantity === 0
            ? 1
            : this.state.recurring_addons_purchased_json[item].quantity;
        return quantity;
      } else if (item === "appsumo_multisite_yearly") {
        let quantity =
          this.state.recurring_addons_purchased_json === 0 ||
          this.state.recurring_addons_purchased_json[item] === undefined
            ? 1
            : this.state.recurring_addons_purchased_json[item].quantity === 0
            ? 1
            : this.state.recurring_addons_purchased_json[item].quantity;
        return quantity;
      } else if (item === "appsumo_publish_yearly") {
        let quantity =
          this.state.recurring_addons_purchased_json === 0 ||
          this.state.recurring_addons_purchased_json[item] === undefined
            ? 1
            : this.state.recurring_addons_purchased_json[item].quantity === 0
            ? 1
            : this.state.recurring_addons_purchased_json[item].quantity;
        return quantity;
      } else if (item === "appsumo_white_label_yearly") {
        let quantity =
          this.state.recurring_addons_purchased_json === 0 ||
          this.state.recurring_addons_purchased_json[item] === undefined
            ? 1
            : this.state.recurring_addons_purchased_json[item].quantity === 0
            ? 1
            : this.state.recurring_addons_purchased_json[item].quantity;
        return quantity;
      } else if (item === "language_support") {
        let quantity =
          this.state.recurring_addons_purchased_json === 0
            ? 1
            : this.state.recurring_addons_purchased_json[item].quantity === 0
            ? 1
            : this.state.recurring_addons_purchased_json[item].quantity;
        return quantity;
      } else {
        return 1;
      }
    };

    let makeobg = (addon_code) => {
      let final = [];
      for (let i in addon_code) {
        let item = addon_code[i];
        if (
          item !== "language_support_lifetime" &&
          item !== "multisite_support_lifetime"
        ) {
          final.push({
            addon_code: item,
            quantity: addonvalue(item),
          });
        }
      }

      return final;
    };

    return makeobg(finalarray);
  };

  //Addon Makepayment When isPlan is 0
  addonmakepayment = (e, addon_code, price, duration, addon_identifier) => 
  {
    // if(!this.state.agree)
    // {
    //   errortoast('Please agree term condition and privacy policy');
    //   return false;
    // }
    if(!this.state.formbuttondisabled)
    {
      e.preventDefault();
      if(this.state.tax_number !== '')
      {
        if(this.state.company_name === '')
        {
          errortoast('Please enter company name');
          return false;
        }
      }
      if(this.state.billing_country === '')
      {
          errortoast('Please select country');
          return false;
      }

      this.setState({
        formbuttondisabled: true,
      });

      if (this.state.multisite.is_app_link_to_multisite !== 0 && addon_identifier === "MULTISITE_SUPPORT") 
      {
        
      } 
      else 
      {
      
        if (addon_code === "update_app") 
        {
          const params = {app_id: this.state.app_id};
          
          Purchasenewversion(params, this);
        } 
        else 
        {
          
          let billingparam;
          if (
            localStorage.getItem("workspaceId") !== "" &&
            localStorage.getItem("workspaceId") !== "undefined" &&
            localStorage.getItem("workspaceId") !== undefined &&
            localStorage.getItem("workspaceId") !== null
          ) 
          {
            billingparam = {
              workspace_id:this.state.appOrWpOrAgency==="agency"?null: parseInt(localStorage.getItem("workspaceId")),
              first_name: this.state.first_name,
              last_name: this.state.last_name,
              company_name: this.state.company_name,
              billing_address: this.state.billing_address,
              billing_city: this.state.billing_city,
              billing_state: this.state.billing_state,
              billing_zip: this.state.billing_zip,
              billing_country: this.state.billing_country,
              tax_number: this.state.tax_number,
            };
          } 
          else 
          {
            billingparam = {
              app_id:this.state.appOrWpOrAgency==="agency"?null:parseInt(this.state.app_id),
              first_name: this.state.first_name,
              last_name: this.state.last_name,
              company_name: this.state.company_name,
              billing_address: this.state.billing_address,
              billing_city: this.state.billing_city,
              billing_state: this.state.billing_state,
              billing_zip: this.state.billing_zip,
              billing_country: this.state.billing_country,
              tax_number: this.state.tax_number,
              agency_id:this.state.appOrWpOrAgency==="agency"?parseInt(localStorage.getItem("agency_id")):null,
            };
          }
          BillingApi(billingparam, this, () => {
            if (
              localStorage.getItem("workspaceId") !== "" &&
              localStorage.getItem("workspaceId") !== "undefined" &&
              localStorage.getItem("workspaceId") !== undefined &&
              localStorage.getItem("workspaceId") !== null
            ) 
            {

              if (this.state.customer_billing_type === 3) 
              {

                const linkGenerateParam = {
                  workspace_id:
                    localStorage.getItem("workspaceId") !== "" &&
                    localStorage.getItem("workspaceId") !== "undefined" &&
                    localStorage.getItem("workspaceId") !== undefined &&
                    localStorage.getItem("workspaceId") !== null
                      ? parseInt(localStorage.getItem("workspaceId"))
                      : null,
                  app_id:
                    localStorage.getItem("workspaceId") !== "" &&
                    localStorage.getItem("workspaceId") !== "undefined" &&
                    localStorage.getItem("workspaceId") !== undefined &&
                    localStorage.getItem("workspaceId") !== null
                      ? null
                      : parseInt(this.state.app_id),

                  price_id: this.state.plan_code,
                  quantity: 1 ,
                  agency_id:null ,
                  // quantity :
                  // parseInt(this.state.new_add_user_quantity),
                  type:
                  // 3 ,
                  this.state.plandata.package_duration_id===1 || this.state.plandata.package_duration_id===2 ? 2 : 3,
                };
               
                  paddleGeneratePayLink(linkGenerateParam, this);
                
              } 
              else 
              {
                if((!this.state.addon_quantity || addon_identifier !== "USER_MANAGEMENT") // It will satisfy in all condition until and unless the user management has quantity more than 0
                && this.state.userDiffType === 1 && duration !=="one time" // For new Users & Recurring Addons
                )
              {

                const paramsForNewApi ={
                  app_id:parseInt(this.state.app_id) ,
                  plan_array  :
                   [
                      {
                      price_id : addon_code ,
                      quantity :addon_identifier==="USER_MANAGEMENT" ? parseInt(this.state.new_add_user_quantity) : 1
                     }
                    ] ,
                  payment_method_id:
                  this.state.payment_method_id === ""
                    ? parseInt(this.state.payment_methods[0].id)
                    : parseInt(this.state.payment_method_id), 
                  order_type :1 ,
                }
                newAddonStripeApi(paramsForNewApi,this)
              }
              else
              {

              const sendtostrip = {
                app_id: parseInt(this.state.app_id),
                agency_id:null ,
                plan_array: [
                  {
                    price_id: addon_code,
                    quantity: addon_identifier==="USER_MANAGEMENT" ?
                    // 1,
                    parseInt(this.state.new_add_user_quantity) : 1,
                    type: duration === "one time" ? 3 : 2,
                  },
                ],
                payment_method_id:
                  ((this.state.payment_method_id === "" ? parseInt(this.state.payment_methods[0].id) : parseInt(this.state.payment_method_id))) || "payment_method_id",
                order_type: 1,
                deal_id: null,
                // action: this.state.payment_method_id,
              };

              if(this.state.payment_methods.length !== 0 && this.state.addon_package_duration_id === 3)
              {

                Makestrippayment(sendtostrip, this, "addon");
              }
              else{

                Makestrippayment(sendtostrip, this, "recurring");
              }
            }
              }
            } 
            else 
            {

              if (this.state.customer_billing_type === 2 && this.state.appOrWpOrAgency !=="agency") 
              {

                if((!this.state.addon_quantity || addon_identifier !=="USER_MANAGEMENT") // It will satisfy in all condition until and unless the user management has quantity more than 0
                  && this.state.userDiffType===1 && duration!=="one time" // For new Users & Recurring Addons
                  )
                {
                  const paramsForNewApi ={
                    app_id:parseInt(this.state.app_id) ,
                    plan_array  :
                     [
                        {
                        price_id : addon_code ,
                        quantity :addon_identifier==="USER_MANAGEMENT" ? parseInt(this.state.new_add_user_quantity) : 1
                       }
                      ] ,
                    payment_method_id:
                    this.state.payment_method_id === ""
                      ? parseInt(this.state.payment_methods[0].id)
                      : parseInt(this.state.payment_method_id), 
                    order_type :1 ,
                  }
                  newAddonStripeApi(paramsForNewApi,this)
                }
                else
                {
                const sendtostrip = {
                  app_id: parseInt(this.state.app_id),
                  agency_id:null ,
                  plan_array: [
                    {
                      price_id: addon_code,
                      quantity: addon_identifier==="USER_MANAGEMENT" ?
                      // 1,
                      parseInt(this.state.new_add_user_quantity) : 1,
                      type: duration === "one time" ? 3 : 2,
                    },
                  ],
                  payment_method_id:
                    ((this.state.payment_method_id === "" ? parseInt(this.state.payment_methods[0].id) : parseInt(this.state.payment_method_id))) || "payment_method_id",
                  order_type: 1,
                  deal_id: null,
                  // action: this.state.payment_method_id,
                };

                if(this.state.payment_methods.length !== 0 && this.state.addon_package_duration_id === 3)
                {
  
                  Makestrippayment(sendtostrip, this, "addon");
                }
                else{

                  Makestrippayment(sendtostrip, this, "recurring");
                }
              }
                  
              } 
              else if (this.state.customer_billing_type === 3 || this.state.appOrWpOrAgency ==="agency") 
              {

                const paddleParam = {
                  workspace_id:
                    localStorage.getItem("workspaceId") !== "" &&
                    localStorage.getItem("workspaceId") !== "undefined" &&
                    localStorage.getItem("workspaceId") !== undefined &&
                    localStorage.getItem("workspaceId") !== null
                      ? parseInt(localStorage.getItem("workspaceId"))
                      : null,
                  app_id:
                    localStorage.getItem("workspaceId") !== "" &&
                    localStorage.getItem("workspaceId") !== "undefined" &&
                    localStorage.getItem("workspaceId") !== undefined &&
                    localStorage.getItem("workspaceId") !== null
                      ? null
                      : parseInt(this.state.app_id),
                  agency_id:null ,
                  deal_id: null,
                  order_type: 1,
                  price_id: this.state.plan_code,
                  quantity:addon_identifier==="USER_MANAGEMENT" ?  parseInt(this.state.new_add_user_quantity):1,
                  type: this.state.plandata.package_duration_id===1 || this.state.plandata.package_duration_id===2 ? 2 : 3,
              
                };
                const linkGenerateParam = 
                {
                  workspace_id:this.state.appOrWpOrAgency==="agency"?null:
                    localStorage.getItem("workspaceId") !== "" &&
                    localStorage.getItem("workspaceId") !== "undefined" &&
                    localStorage.getItem("workspaceId") !== undefined &&
                    localStorage.getItem("workspaceId") !== null
                      ? parseInt(localStorage.getItem("workspaceId"))
                      : null,
                  app_id:this.state.appOrWpOrAgency==="agency"?null:
                    localStorage.getItem("workspaceId") !== "" &&
                    localStorage.getItem("workspaceId") !== "undefined" &&
                    localStorage.getItem("workspaceId") !== undefined &&
                    localStorage.getItem("workspaceId") !== null
                      ? null
                      : parseInt(this.state.app_id),

                  price_id: this.state.plan_code,
                  // agency_id:null,
                  agency_id:this.state.appOrWpOrAgency==="agency"?parseInt(localStorage.getItem("agency_id")):null,
                  quantity: addon_identifier==="USER_MANAGEMENT"?
                  parseInt(this.state.new_add_user_quantity):1,
                  type:this.state.plandata.package_duration_id===1 || this.state.plandata.package_duration_id===2 ? 2 : 3,
                };
                {
                  // !this.state.addon_quantity && 
                  if (this.state.plandata.package_duration_id===3 || !this.state.addon_quantity || addon_identifier !=="USER_MANAGEMENT" ) 
                  {
             
                    paddleGeneratePayLink(linkGenerateParam, this);

                  }
                  else 
                  {
           
                    paddleChangePlan(paddleParam, this);
                  }
                }
              } 
              else 
              {
        
                this.addonpaymentfunction(
                  addon_code,
                  price,
                  duration,
                  "upgrade",
                  () => {
                    // userDiffType = 0 - old zoho user (Addon attached with subscription)
                    // userDiffType = 1 - new zoho user (Addon is seperate from subscription)
                    const paramsForMakePayment1 = {
                      app_id: this.state.app_id,
                      plan_code: this.state.planCode2,
                      addon_array: this.jsonarray2(addon_code),	
                      subscription_id: this.state.zoho_id,	
                      addon_payment: 1,	
                      deal_id: null,	
                      action: "",	
                      product: "",
                      plan_quantity:1 ,
                    };

                    const paramsForNewZohoApi = {
                      app_id: parseInt(this.state.app_id),
                      plan_code: this.props.match.params.planname,
                      action: "upgrade",
                      product: "addon",
                      quantity:addon_identifier==="USER_MANAGEMENT"?parseInt(this.state.new_add_user_quantity):1
                    }
                  if(this.state.userDiffType===0)
                  {
  
                   Makepayment(paramsForMakePayment1, this);
                  } else if ( this.state.plandata.package_duration_id===3 &&this.state.userDiffType===1  ){
                    const paramsForMakePayment3 = {
                      app_id: this.state.app_id,
                      plan_code: this.state.planCode2,
                      addon_array: [{addon_code:addon_code,quantity:1}],	
                      subscription_id: this.state.zoho_id,	
                      addon_payment: 1,	
                      deal_id: null,	
                      action: "",	
                      product: "",
                      plan_quantity:1 ,
                    };
                    Makepayment(paramsForMakePayment3, this);
                  }
                  else
                  {
                    const paramsForMakePayment2 = {
                      app_id: this.state.app_id,
                      plan_code: this.props.match.params.planname,
                      addon_array: [],	
                      subscription_id: this.state.zoho_id,	
                      addon_payment: 1,	
                      deal_id: null,	
                      action: "",	
                      product: "",
                      plan_quantity:addon_identifier==="USER_MANAGEMENT" ? parseInt(this.state.new_add_user_quantity):1
                    };
                    if(( (!this.state.addon_quantity || addon_identifier !=="USER_MANAGEMENT" ) && duration !=="one time" ))
                    {

       
                      zohoAddonPurchaseAPI(paramsForNewZohoApi, this);
                    }
                    else
                    {
                      Makepayment(paramsForMakePayment2, this);
                    }
                
            

                  }

                  }
                );
              }
            }
          });
        }
      }
    }
  };

  //ArrayObject
  jsonarray = (addonlist) => {
  
    let add_recurring_addon =
      addonlist.length === 0 ? this.state.addon_code : addonlist;
    // Remove duplicate value from array
    add_recurring_addon = add_recurring_addon.filter(function (
      item,
      index,
      inputArray
    ) {
      return inputArray.indexOf(item) === index;
    });
    let addonvalue = (item) => {
      if (
        item === "add_user" ||
        item === "language_support" ||
        item === "wordpress_website_hosting"
      ) {
        let quantity =
          this.state.recurring_addons_purchased_json === 0
            ? 1
            : this.state.recurring_addons_purchased_json[item].quantity === 0
            ? 1
            : this.state.recurring_addons_purchased_json[item].quantity;
        return quantity;
      } else {
        return 1;
      }
    };

    let makeobg = (addon_code) => {
      let final = [];
      for (let i in addon_code) {
        let item = addon_code[i];
        if (this.props.match.params.planname.indexOf("monthly") === -1) {
          final.push({
            addon_code: item,
            quantity: addonvalue(item),
          });
        } else {
          if (item !== "wordpress_website_hosting_yearly") {
            final.push({
              addon_code: item,
              quantity: addonvalue(item),
            });
          }
        }
      }
      return final;
    };
    return makeobg(add_recurring_addon);
  };

  handlecountryname = (isovalue,id) =>
  {
    this.setState({
      billing_country_code: isovalue,
      billing_country:id,
      paddleTaxrate:'',
      searchAppVal:""
    });
  }

  handleformfunction = (e) => {
    const { name, value} = e.target;
    if (name === "tax_number") {
      this.setState({
        [name]: value
          .replace(/[`~!@#$%^&*(){}_|+/[\-=?;:'",.<>\\]/gi, "")
          .replace("]", ""),
      });
    } 
    else
     {
      if(name === 'billing_country')
      {
        this.getcountryname();
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index];
        var isovalue =  optionElement.getAttribute('isovalue');
     
        this.setState({
          billing_country_code: isovalue,
          paddleTaxrate:'',
        });
      }
      this.setState({
        [name]: value,
      });
    }
  };
  handlebillingstate = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value.replace(/[^a-zA-Z0-9 ]/g, ""),
    });
  };

  currencyFormater = (number, country_name, currency) => 
  {
   
    let formatedNum = new Intl.NumberFormat(country_name, {
      style: "currency",
      currency: currency,
    }).format(number);
    return formatedNum;
  };

  newcurrencyFormater = (number, country_name) => {
    let formatedNum = new Intl.NumberFormat(country_name,{minimumFractionDigits: 2}).format(number);
    return formatedNum;
  };

  newcurrencysymbolFormater = (number, country_name) => 
  {
    let formatedNum = new Intl.NumberFormat('en', { style: 'currency',currency:country_name,minimumFractionDigits: 2}).format(number);
    return formatedNum;
  };

  getcountryname = (countryid) =>
  {

    var lop = this.state.countryselectfield.filter((item)=>
    {

        if(parseInt(item.id) == parseInt(countryid))
        {
        
          return item.country_name
        }
    
    })

    if(lop && lop != '' && lop.length > 0)
    {
      return lop[0].country_name;
    }
    else
    {
      return '';
    }
    

  }

  getpaymentmethod = (paymentmethodid) =>
  {
  
    var lop = this.state.payment_methods.filter((item)=>
    {
       
        if(parseInt(item.id) == parseInt(paymentmethodid))
        {
         
          return item
        }
    
    })

   
    return `XXXX XXXX XXXX `+lop[0].card_last4_digit;

  }

  setpaymentmethodid = (i, id, cerddigit, expiry_month, expiry_year) =>
  {


    this.setState({payment_method_id:id});
    this.comparedate(i, id, cerddigit, expiry_month, expiry_year);

  }  

  comparedate = (i, id, cerddigit, expiry_month, expiry_year) => {
    let today = new Date();
    let someday = new Date().setFullYear(expiry_year, expiry_month, 1);

    if (someday < today) 
    {
      return (
        <DropdownItem  style={{ color: "#FF4848" }} disabled>
          {" "}
          {`*****${cerddigit} Expired ${expiry_month}/${expiry_year}`}{" "}
        </DropdownItem>
      );
    }
    else if(id == this.state.payment_method_id)
    {
      return (
        <DropdownItem key={i} value={id} disabled>
          {" "}
          {`*****${cerddigit} Expires ${expiry_month}/${expiry_year}`}{" "}
        </DropdownItem>
      );
    } 
    else 
    {
      return (
        <DropdownItem key={i} value={id}  onClick={(e) =>
          this.setpaymentmethodid(i, id, cerddigit, expiry_month, expiry_year)
        }>
          {" "}
          {`*****${cerddigit} Expires ${expiry_month}/${expiry_year}`}{" "}
        </DropdownItem>
      );
    }
  };



  render() 
  {
    if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
		{
      document.title = this.state.paddleTab === "checkout"? "Checkout | AppMySite" : "Payment | AppMySite";

		}
		else
		{
      document.title = "Checkout |"+ ' ' +localStorage.getItem('agencyname');
		}
   

    return (
      <>
        <div className=" ">
          <Header />
        </div>
        {/* <Mobiletop name={"Checkout"} imgpath={null} /> */}

        
        <section className="dasboard_page mobile-display mt-4 mt-md-0 new-checkout-page" style={{maxWidth: "100%"}}>
        {this.state.checkspinner ? (
            <div className="newspinner">
            <svg xmlns="http://www.w3.org/2000/svg" 
      xlink="http://www.w3.org/1999/xlink" 
      style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
      width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
      <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
        style={{animationplaystate: "running", animationdelay: "0s"}}>
        </animateTransform>
      </circle>
      </g>
      <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
      <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
      <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
      style={{animationplaystate: "running", animationdelay: "0s"}}>
      </animateTransform>
      </circle>
      </g>
      <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
      <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
      <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
      style={{animationplaystate: "running", animationdelay: "0s"}}>

      </animateTransform>
      </circle>
      </g>
      <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
      <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
      <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
      style={{animationplaystate: "running", animationdelay: "0s"}}>

      </animateTransform>
      </circle>
      </g>
      </svg>
          </div>
          ) : (
            <>
              {/* <div className="d-md-none d-block">
                <Leftpanel />
              </div> */}
              <div className="transparent_background addonpage payment-last-step">

                <div className="checkout-page-head">
                    {(this.state.gateway_subscription_id === '' && this.state.customer_billing_type === 3 && parseInt(this.state.isPlan) === 1) ?
                      <h1>
                        {this.state.paddleTab === "checkout"
                        ? "Checkout"
                        : "Payment"}
                      </h1>
                    : (this.state.customer_billing_type === 3 && parseInt(this.state.isPlan) === 0) && this.state.addon_quantity===0 ?
                      <h1>
                      {this.state.paddleTab === "checkout"
                      ? "Checkout"
                      : "Payment"}
                      </h1>
                    :  <h1>
                        {this.state.paddleTab === "checkout"
                        ? "Checkout"
                        : "Payment"}
                      </h1>
                    }
                  { (((this.state.gateway_subscription_id === '' && parseInt(this.state.isPlan) === 1 ) 
                  || (parseInt(this.state.isPlan) === 0 && this.state.addon_quantity===0)
                  ) ) && this.state.customer_billing_type === 3 && (
                    <h2><span className={this.state.paddleTab == "checkout"? "checkout-active-underline" : "checkout-active"}  onClick={() =>
                      this.setState({
                        paddleTab: "checkout",
                      })
                    }>Checkout</span> {'>'} <span className={this.state.paddleTab == "checkout"? "" : "checkout-active-underline"}>Payment</span></h2>
                  )}
                </div>
                {this.state.paddleTab === "checkout" ? (
                <form  id="myform">
                <div className="checkout-page">
               
                  <div className="checkout-billing-details">
                    <h4>Billing details</h4>
                    <h5>Please provide your details to complete the checkout process.</h5>

                    <div className="checkout-double">
                      <div className="checkout-half">
                        <h6>First name*</h6>
                        <input type="text"  required name="first_name" value={this.state.first_name}
                            onChange={(e) =>
                              this.handleformfunction(e)
                            }
                            readOnly={this.state.gateway_subscription_id !== '' && this.state.customer_billing_type === 3 ? true : false} 
                            />
                      </div>

                      <div className="checkout-half">
                        <h6>Last name*</h6>
                        <input type="text" value={this.state.last_name}
                                        required
                                        name="last_name"
                                        onChange={(e) =>
                                          this.handleformfunction(e)
                                        }
                                        readOnly={this.state.gateway_subscription_id !== '' && this.state.customer_billing_type === 3 ? true : false} 
                                        />
                      </div>
                    </div>

                    <div className="checkout-single">
                      <div className="checkout-full">
                        <h6>Company</h6>
                        <input type="text"
                                        maxLength="30"
                                        name="company_name"
                                        value={this.state.company_name}
                                        onChange={(e) =>
                                          this.handleformfunction(e)
                                        }
                                        readOnly={this.state.gateway_subscription_id !== '' && this.state.customer_billing_type === 3 ? true : false} 
                                        />
                      </div>
                    </div>

                    <div className="checkout-single">
                      <div className="checkout-full">
                        <h6>Tax ID</h6>
                        <input type="text" maxLength="30"
                                        name="tax_number"
                                        value={this.state.tax_number}
                                        onChange={(e) =>
                                          this.handleformfunction(e)
                                        }
                                        readOnly={this.state.gateway_subscription_id !== '' && this.state.customer_billing_type === 3 ? true : false} 
                                        />
                      </div>
                    </div>

                    <div className="checkout-single">
                      <div className="checkout-full">
                        <h6>Address*</h6>
                        <input type="text" required
                                      name="billing_address"
                                      value={this.state.billing_address}
                                      onChange={(e) => this.handleformfunction(e)}
                                      readOnly={this.state.gateway_subscription_id !== '' && this.state.customer_billing_type === 3 ? true : false} 
                                      />
                      </div>
                    </div>

                    <div className="checkout-double">
                      <div className="checkout-half">
                        <h6>City*</h6>
                        <input type="text" required
                                        name="billing_city"
                                        value={this.state.billing_city}
                                        onChange={(e) =>
                                          this.handlebillingstate(e)
                                        }
                                        readOnly={this.state.gateway_subscription_id !== '' && this.state.customer_billing_type === 3 ? true : false} 
                                        />
                      </div>

                      <div className="checkout-half">
                        <h6>State/Region*</h6>
                        <input type="text" required
                                        name="billing_state"
                                        value={this.state.billing_state}
                                        onChange={(e) =>
                                          this.handlebillingstate(e)
                                        }
                                        readOnly={this.state.gateway_subscription_id !== '' && this.state.customer_billing_type === 3 ? true : false} 
                                        />
                      </div>
                    </div>

                    <div className="checkout-single postal-code">
                      <div className="checkout-full">
                        <h6>Postal code*</h6>
                        <input type="text" required
                                        name="billing_zip"
                                        maxLength="10"
                                        value={this.state.billing_zip}
                                        onChange={(e) =>
                                          this.handleformfunction(e)
                                        }
                                        readOnly={this.state.gateway_subscription_id !== '' && this.state.customer_billing_type === 3 ? true : false} 
                                        />
                      </div>
                    </div>

                    <div className="checkout-single">
                      <div className="checkout-full">
                        <h6>Country*</h6>
                        
                        <div className="checkout-country">
                        <Dropdown isOpen={this.state.openAppDropdown}
                            onClick={this.handleMobileAppDropdown}
                              toggle={this.handleAppdropdown}
                            >
                              <DropdownToggle caret disabled={this.state.gateway_subscription_id !== '' && this.state.customer_billing_type === 3 ? true : false}>
                              {this.state.billing_country != '' ?   
                                this.getcountryname(this.state.billing_country)
                              :'Select Country'}
                              </DropdownToggle>
                              <DropdownMenu >
                              

                              <FormGroup>
                              <div className="dropdown-search">
                                <input placeholder="Search..." type="text"  name="billing_country" 
                                  disabled={this.state.gateway_subscription_id !== '' && this.state.customer_billing_type === 3 ? true : false}
                                  value={this.state.searchAppVal}
                                  onChange={(e)=> {
                                    this.setState({
                                      searchAppVal:e.target.value
                                    })
                                  }}
                                />
                                {this.state.searchAppVal.trim() && <p onClick={(e)=>{
                                this.setState({
                                  searchAppVal:""
                                })
                              }}>Clear</p>}
                              </div>
                            </FormGroup>

                            {/* {this.state.countryselectfield.map(
                                        (res, i) => (
                                          <DropdownItem disabled={this.state.gateway_subscription_id !== '' && this.state.customer_billing_type === 3 ? true : false}  key={i}  onClick={(e) => this.handlecountryname(res.country_code,res.id)} value={res.id} isovalue={res.country_code}> {" "}
                                          {res.country_name}</DropdownItem>
                                        )
                                      )} */}
                            {
                                    this.state.countryselectfield && this.state.countryselectfield.filter((row1) =>
                                    row1.country_name
                                      .toLowerCase()
                                      .includes(this.state.searchAppVal.toLowerCase())
                                  ).map((res)=>{
                                      return(
                                        <DropdownItem  disabled={this.state.gateway_subscription_id !== '' && this.state.customer_billing_type === 3 ? true : false}  onClick={(e) => this.handlecountryname(res.country_code,res.id)} value={res.id} isovalue={res.country_code} >{res.country_name}</DropdownItem>
                                      )
                                    })
                                  }
                            
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                      </div>
                    </div>

                  </div>

                  <div className="checkout-purchase-detail">
                  
                  {this.state.customer_billing_type === 2 && this.state.appOrWpOrAgency!=="agency" && (
                    <>
                    { this.props?.location?.state?.addon_type ? (this.props?.location?.state?.addon_type === 2 && this.props?.location?.state?.is_active === 0) ?
                    null
                     : (
                      <>
                      <div className="checkout-payment-method">
                        <div className="checkout-payment-method-head">
                          <h4>Purchase method</h4>
                        {this.state.customer_billing_type === 2 && (
                          <>
                              {this.state.payment_methods.length > 0 && (
                                <>
                              <h6 onClick={(e) => this.addcardbutton(e)}><svg id="Group_3055" data-name="Group 3055" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                              <g id="Group_1664" data-name="Group 1664" transform="translate(0 6.001)">
                                <line id="Line_85" data-name="Line 85" y2="12" transform="translate(12 0) rotate(90)" fill="none" stroke="#3064f9" stroke-width="1.5"/>
                              </g>
                              <g id="Group_1665" data-name="Group 1665" transform="translate(5.959) rotate(90)">
                                <line id="Line_85-2" data-name="Line 85" y2="12" transform="translate(12) rotate(90)" fill="none" stroke="#3064f9" stroke-width="1.5"/>
                              </g>
                            </svg>
                            Add card <img className="img-fluid" src={require("../../assets/images/dashboard/link.png").default} alt="link" /></h6>
                            </>
                            )}
                          </>
                        )}
                        </div>
                        {this.state.customer_billing_type === 2 && (
                        <div className="checkout-country">
                          <Dropdown isOpen={this.state.openAppDropdown1}
                              // onClick={window.matchMedia("(max-width: 1200px)").matches?this.handleMobileAppDropdown:null}
                                toggle={this.handleAppdropdown1}
                              >
                                <DropdownToggle caret>
                                {this.state.payment_methods.length > 0 && this.state.payment_method_id != '' ?   
                                  this.getpaymentmethod(this.state.payment_method_id)
                                :'Select Payment Method'}
                                </DropdownToggle>
                                <DropdownMenu >
                                {this.state.payment_methods.length === 0 && (
                                        <DropdownItem>No card to select </DropdownItem>
                                    )}
                              {this.state.payment_methods.map((res, i) =>
                                    this.comparedate(i,res.id,res.card_last4_digit,res.expiry_month,res.expiry_year))}  
                                                                
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                        )}
                      </div>
                      </>
                    ) :  (process.env.REACT_APP_STRIPE_PREVIEW === this.state.subscription_plan  || !this.state.subscriptionToCheck) ?
                    null
                     : (
                      <>
                      <div className="checkout-payment-method">
                        <div className="checkout-payment-method-head">
                          <h4>Purchase method</h4>
                        {this.state.customer_billing_type === 2 && (
                          <>
                              {this.state.payment_methods.length > 0 && (
                                <>
                              <h6 onClick={(e) => this.addcardbutton(e)}><svg id="Group_3055" data-name="Group 3055" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                              <g id="Group_1664" data-name="Group 1664" transform="translate(0 6.001)">
                                <line id="Line_85" data-name="Line 85" y2="12" transform="translate(12 0) rotate(90)" fill="none" stroke="#3064f9" stroke-width="1.5"/>
                              </g>
                              <g id="Group_1665" data-name="Group 1665" transform="translate(5.959) rotate(90)">
                                <line id="Line_85-2" data-name="Line 85" y2="12" transform="translate(12) rotate(90)" fill="none" stroke="#3064f9" stroke-width="1.5"/>
                              </g>
                            </svg>
                            Add card <img className="img-fluid" src={require("../../assets/images/dashboard/link.png").default} alt="link" /></h6>
                            </>
                            )}
                          </>
                        )}
                        </div>
                        {this.state.customer_billing_type === 2 && (
                        <div className="checkout-country">
                          <Dropdown isOpen={this.state.openAppDropdown1}
                              // onClick={window.matchMedia("(max-width: 1200px)").matches?this.handleMobileAppDropdown:null}
                                toggle={this.handleAppdropdown1}
                              >
                                <DropdownToggle caret>
                                {this.state.payment_methods.length > 0 && this.state.payment_method_id != '' ?   
                                  this.getpaymentmethod(this.state.payment_method_id)
                                :'Select Payment Method'}
                                </DropdownToggle>
                                <DropdownMenu >
                                {this.state.payment_methods.length === 0 && (
                                        <DropdownItem>No card to select </DropdownItem>
                                    )}
                              {this.state.payment_methods.map((res, i) =>
                                    this.comparedate(i,res.id,res.card_last4_digit,res.expiry_month,res.expiry_year))}  
                                                                
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                        )}
                      </div>
                      </>
                    )}
                    </>
                  )}

                    {this.state.customer_billing_type === 3 && this.state.subscriptioninfo && this.state.subscriptioninfo != '' && this.state.subscriptioninfo.meta_data && this.state.subscriptioninfo.meta_data != '' ?
                      <div className="checkout-payment-method">
                          <div className="checkout-payment-method-head">
                            <h4>Purchase method</h4>
                            <>
                              {this.state.subscriptioninfo && this.state.subscriptioninfo != '' && this.state.subscriptioninfo.meta_data && this.state.subscriptioninfo.meta_data != '' ?
                                  <>
                                  <a href= {(JSON.parse(this.state.subscriptioninfo.meta_data)).update_pm_link} target="_blank">
                                      <h6>Update <img className="img-fluid" src={require("../../assets/images/dashboard/link.png").default} alt="link" /></h6>
                                  </a>
                                  </>
                              : 
                              <></>
                              }
                            </>
                          </div>

                          <div className="show-card-number-preview">
                              <h3>XXXX  XXXX  XXXX  XXXX</h3>
                          </div>
                    
                      </div>
                    : <></>
                    }

                    <div className="checkout-purchase-summary 1">
                    {this.state.customer_billing_type === 3 ? 
                        <>
                          <h4>Purchase details</h4>

                          
                            {this.state.gateway_subscription_id === '' && parseInt(this.state.isPlan) === 1 ?
                              <>
                              <div className="purchase-summary-sections 1">
                                <h2>{parseInt(this.state.isPlan) === 0
                                              ? this.state.plandata.addon_name
                                              : this.state.plandata.plan_name}
                                </h2>
                                <h3>{ this.state.newpurchase_amount !== '' ?
                                      this.state.newpurchase_amount.replace('US',' ')
                                    :
                                      <>
                                        {

                                            this.state.currency === "Rs." ? this.currencyFormater(
                                                  this.state.purchase_amount,
                                                  "en-IN",
                                                  "INR"
                                                )
                                              : this.currencyFormater(
                                                  this.state.purchase_amount,
                                                  "en-US",
                                                  "USD"
                                                )}
                                                </>
                                           
                                      }
                                </h3>
                              </div>
                             
                          
                              </>
                            : parseInt(this.state.isPlan) === 1 ?
                              <>
                              {this.state.appOrWpOrAgency ==="agency" ?
                                  <div className="purchase-summary-sections 2">
                                      <h2>
                                        {parseInt(this.state.isPlan) === 0
                                          ? this.state.plandata.addon_name
                                          : this.state.plandata.plan_name}
                                      </h2>
                              
                                      <h3>
                                      ${
                                        this.state.amount_to_pay
                                      }
                                      </h3> 
                                  </div>
                              :
                                  <div className="purchase-summary-sections 3">
                                      <h2>
                                        {parseInt(this.state.isPlan) === 0
                                          ? this.state.plandata.addon_name
                                          : this.state.plandata.plan_name}
                                      </h2>
                                  
                                      <h3>
                                        {this.state.newpurchase_amount !== '' && parseInt(this.state.plandata.package_duration_id) === 4 ?
                                          this.state.newpurchase_amount.replace('US',' ')
                                        : this.state.currency1.replace('US',' ') == ((this.state.newpurchase_amount.replace(/[0-9]/g, '')).replace(/[`~!@#%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')).replace('US',' ') ?
                                            <>
                                              { this.state.newpurchase_amount !== '' && parseInt(this.state.plandata.package_duration_id) !== 4 ?
                                                this.state.newpurchasegross_amount.replace('US',' ')
                                              : this.state.newpurchase_amount !== '' && parseInt(this.state.plandata.package_duration_id) === 4 ?
                                                this.state.newpurchase_amount.replace('US',' ')
                                              :
                                                <>
                                                {this.state.currency1 === "" ? '$' : this.state.currency1}
                                                {" "}
                                                {this.state.amount_to_pay}
                                                </>
                                              }
                                            </>
                                        :
                                          <> 
                                            {this.state.currency1 + this.state.purchase_amount}
                                          </>
                                        }   
                                      </h3> 
                                      {/* } */}
                                  </div>
                              }
                                      
                                      { this.state.newpurchase_amount !== '' ?
                                            <>
                                            {parseInt(this.state.plandata.package_duration_id) !== 4 ?
                                              ((((this.state.newpurchasegross_amount.replace(',','')).replace(/^\D+/g, '')) - ((this.state.amount_to_pay.replace(',','')).replace(/^\D+/g, ''))) > 0) ?
                                                  <div className="purchase-summary-sections 4">
                                                        <h2>Applied credits </h2>
                                                        <h3>
                                                        {this.state.currency1.replace('US',' ') == ((this.state.newpurchase_amount.replace(/[0-9]/g, '')).replace(/[`~!@#%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')).replace('US',' ') ?
                                                          <>
                                                            { ((this.state.newpurchase_amount.replace(/[0-9]/g, '')).replace(/[`~!@#%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')).replace('US',' ') +
                                                              this.newcurrencyFormater((((this.state.newpurchasegross_amount.replace(',','')).replace(/^\D+/g, '')) - ((this.state.amount_to_pay.replace(',','')).replace(/^\D+/g, ''))).toFixed(2),
                                                                "en-"+this.state.paddlecountry)
                                                            }
                                                          </>
                                                        : <> 
                                                          {this.state.currency1 + this.state.applied_credits}
                                                        </>
                                                        } 
                                                        </h3>
                                                  </div>
                                              : <></>

                                            :  <div className="purchase-summary-sections 5">
                                                  <h2>Tax </h2>
                                                  <h3>
                                                { this.state.newpurchasetax_amount !== '' && this.state.paddlecountry === this.state.billing_country_code ?
                                                  this.state.newpurchasetax_amount.replace('US',' ')
                                                : 'Calculated at next step'
                                                }
                                                </h3>
                                                </div>
                                            }
                                            <div className="purchase-summary-total 1">
                                              <h2>Total</h2>
                                              <h3>
                                              { parseInt(this.state.plandata.package_duration_id) == 4 ?
                                                this.state.newpurchase_amount.replace('US',' ')
                                              : this.state.currency1.replace('US',' ') != ((this.state.newpurchase_amount.replace(/[0-9]/g, '')).replace(/[`~!@#%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')).replace('US',' ') ?
                                                this.state.currency1.replace('US',' ') + this.state.amount_to_pay
                                              :
                                                parseInt(this.state.plandata.package_duration_id) !== 4 ?
                                                ((this.state.newpurchase_amount.replace(/[0-9]/g, '')).replace(/[`~!@#%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')).replace('US',' ') + 
                                                  this.newcurrencyFormater(((this.state.amount_to_pay.replace(',','')).replace(/^\D+/g, '')),"en-"+this.state.paddlecountry, )
                                                : this.state.newpurchasegross_amount  !== '' ?
                                                  this.state.newpurchasegross_amount.replace('US',' ')
                                                : this.state.currency1.replace('US',' ') + this.state.amount_to_pay
                                              
                                              }
                                              </h3>
                                            </div>
                                          </>
                                        :
                                        null
                                        }
                                  
                              </>
                            : parseInt(this.state.isPlan) === 0 ?
                            this.props.location.state.addon_identifier==="USER_MANAGEMENT" || localStorage.getItem("addon_identifier")==="USER_MANAGEMENT"?
                              <>
                             
                                      { 
                                        this.state.addon_quantity > 0 ?
                                        <>
                                        <div className="purchase-summary-sections 6">
                                      <h2>
                                        {parseInt(this.state.isPlan) === 0
                                          ? this.state.plandata.addon_name
                                          : this.state.plandata.plan_name}
                                      </h2>
                                  
                                      <h3>
                                      { this.state.newpurchase_amount !== '' && parseInt(this.state.plandata.package_duration_id) !== 4 ?
                                        this.state.newpurchasegross_amount.replace('US',' ')
                                      : this.state.newpurchase_amount !== '' && parseInt(this.state.plandata.package_duration_id) === 4 ?
                                        this.state.newpurchase_amount.replace('US',' ')
                                      :
                                        <>
                                        {this.state.currency1 === "" ? '$' : this.state.currency1}
                                        {" "}
                                        {this.state.amount_to_pay}
                                        </>
                                      }   
                                      </h3> 
                                      {/* } */}
                                  </div>
                              
                                      
                                      { this.state.newpurchase_amount !== '' ?
                                            <>
                                          {parseInt(this.state.plandata.package_duration_id) !== 4 ?
                                              ((((this.state.newpurchasegross_amount.replace(',','')).replace(/^\D+/g, '')) - ((this.state.amount_to_pay.replace(',','')).replace(/^\D+/g, ''))) > 0) ?
                                                  <div className="purchase-summary-sections 7">
                                                        <h2>Applied credits </h2>
                                                        <h3>
                                                        { ((this.state.newpurchase_amount.replace(/[0-9]/g, '')).replace(/[`~!@#%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')).replace('US',' ') +
                                                          this.newcurrencyFormater((((this.state.newpurchasegross_amount.replace(',','')).replace(/^\D+/g, '')) - ((this.state.amount_to_pay.replace(',','')).replace(/^\D+/g, ''))).toFixed(2),
                                                            "en-"+this.state.paddlecountry)
                                                        }
                                                        </h3>
                                                  </div>
                                              : <></>

                                            :  <div className="purchase-summary-sections 8">
                                                  <h2>Tax </h2>
                                                  <h3>
                                                { this.state.newpurchasetax_amount !== '' && this.state.paddlecountry === this.state.billing_country_code ?
                                                  this.state.newpurchasetax_amount.replace('US',' ')
                                                : 'Calculated at next step'
                                                }
                                                </h3>
                                                </div>
                                            }
                                            <div className="purchase-summary-total 2">
                                              <h2>Total</h2>
                                              <h3>
                                              {
                                                this.state.currency1.replace('US',' ') != ((this.state.newpurchase_amount.replace(/[0-9]/g, '')).replace(/[`~!@#%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')).replace('US',' ') ?
                                                this.state.currency1.replace('US',' ') + this.state.amount_to_pay
                                              :
                                                parseInt(this.state.plandata.package_duration_id) !== 4 ?
                                                  ((this.state.newpurchase_amount.replace(/[0-9]/g, '')).replace(/[`~!@#%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')).replace('US',' ') + 
                                                  this.newcurrencyFormater(((this.state.amount_to_pay.replace(',','')).replace(/^\D+/g, '')),"en-"+this.state.paddlecountry, )
                                                : this.state.newpurchasegross_amount  !== '' ?
                                                  this.state.newpurchasegross_amount.replace('US',' ')
                                                : this.state.currency1.replace('US',' ') + this.state.amount_to_pay
                                              
                                              }
                                              </h3>
                                            </div>
                                          </>
                                        :
                                        null
                                        }
                                        </>
                                        :
                                        <div className="purchase-summary-sections 9">
                                        <h2>{this.state.plandata.addon_name}</h2>
                                        <h3>
                                        { this.state.newpurchase_amount  ?
                                          this.state.newpurchase_amount.replace('US',' ')
                                        :
                                        this.state.currency === "Rs." ? this.currencyFormater(
                                          this.state.purchase_amount,
                                          "en-IN",
                                          "INR"
                                        )
                                      : this.currencyFormater(
                                          this.state.purchase_amount,
                                          "en-US",
                                          "USD"
                                        )
                                          // <>
                                          // {this.state.currency1 === "" ? '$' : this.state.currency1}
                                          // {" "}
                                          // {this.state.amount_to_pay}
                                          // </>
                                        
                                      }
                                      </h3>
                                      </div> }
                              </>
                             :
                             <>
                                 <div className="purchase-summary-sections 2">
                                   <h2>{this.state.plandata.addon_name}</h2>
                                   <h3>
                                     {this.state.newpurchase_amount ? (
                                       this.state.newpurchase_amount.replace('US',' ')
                                     ) : (
                                       <>
                                         {this.state.currency === "Rs."
                                           ? this.currencyFormater(
                                               this.state.purchase_amount,
                                               "en-IN",
                                               "INR"
                                             )
                                           : this.currencyFormater(
                                               this.state.purchase_amount,
                                               "en-US",
                                               "USD"
                                             )}
                                       </>
                                     )}
                                   </h3>
                                 </div>
                               </>


                              :null
                            }
                        
                          {this.state.gateway_subscription_id === '' && (parseInt(this.state.isPlan) === 1) ?
                                  <>
                                        <div className="purchase-summary-sections 10">
                                          <h2>Tax </h2>
                                        <h3>
                                        { this.state.newpurchasetax_amount  && this.state.paddlecountry === this.state.billing_country_code ?
                                          this.state.newpurchasetax_amount.replace('US',' ')
                                        : 'Calculated at next step'
                                        }
                                        </h3>
                                        </div>
                                    
                                      
                                          <div className="purchase-summary-total 4">
                                            <h2>Total</h2>
                                            <h3>
                                            { this.state.newpurchasegross_amount !== '' ?
                                              this.state.newpurchasegross_amount.replace('US',' ')
                                            :
                                              <>
                                              {this.state.currency === "Rs."
                                                ? this.currencyFormater(
                                                    this.state.amount_to_pay,
                                                    "en-IN",
                                                    "INR"
                                                  )
                                                : this.currencyFormater(
                                                    this.state.amount_to_pay,
                                                    "en-US",
                                                    "USD"
                                                  )}
                                              </>
                                            }
                                            </h3>
                                          </div>
                                        
                                  </>
                                :
                                
                                parseInt(this.state.isPlan) === 0 && (
                                  this.props.location.state.addon_identifier==="USER_MANAGEMENT" || localStorage.getItem("addon_identifier")==="USER_MANAGEMENT"?
                              
                                


                               ( this.state.addon_quantity === 0 ?
                                    <>
                                    <div className="purchase-summary-sections 11">
                                      <h2>Tax </h2>
                                    <h3>
                                    { this.state.newpurchasetax_amount  ?
                                      this.state.newpurchasetax_amount.replace('US',' ')
                                    : 'Calculated at next step'
                                    }
                                    </h3>
                                    </div>
                        
                                    <div className="purchase-summary-total 5">
                                      <h2>Total</h2>
                                     
                                      <h3>
                                      { this.state.newpurchasegross_amount  ?
                                        this.state.newpurchasegross_amount.replace('US',' ')
                                      :
                                        <>
                                 
                                        {this.state.currency === "Rs."
                                          ? this.currencyFormater(
                                              this.state.amount_to_pay,
                                              "en-IN",
                                              "INR"
                                            )
                                          : this.currencyFormater(
                                              this.state.amount_to_pay,
                                              "en-US",
                                              "USD"
                                            )}
                                        </>
                                      }
                                      </h3>
                                    </div>
                                </>
                                : null)
                                :
                                <>
                                <div className="purchase-summary-sections">
                                  <h2>Tax </h2>
                                  <h3>
                                    {this.state.newpurchasetax_amount 
                                      ? this.state.newpurchasetax_amount.replace('US',' ')
                                      : "Calculated at next step"}
                                  </h3>
                                </div>
   
                                <div className="purchase-summary-total 4 88">
                                  <h2>Total</h2>
                                  <h3>
                                    {this.state.newpurchasegross_amount 
                                     ? (
                                      this.state.newpurchasegross_amount.replace('US',' ')
                                    ) : (
                                      <>
                                        {this.state.currency === "Rs."
                                          ? this.currencyFormater(
                                              this.state.amount_to_pay,
                                              "en-IN",
                                              "INR"
                                            )
                                          : this.currencyFormater(
                                              this.state.amount_to_pay,
                                              "en-US",
                                              "USD"
                                            )}
                                      </>
                                    )}
                                  </h3>
                                </div>
                              </>
   
   
                               
   
                                   
                                   )
                                }

                         
                        </> 

                        // Agency payment (Addon/Plan) for zoho and stripe
                    // : 
                    // this.state.appOrWpOrAgency ==="agency" ? 
                    // <>
                    
                    //   <h4>Purchase details</h4>

                          
                    //     {this.state.gateway_subscription_id === '' && parseInt(this.state.isPlan) === 1 ?
                          
                    //       <div className="purchase-summary-sections">
                    //         <h2>{parseInt(this.state.isPlan) === 0
                    //                       ? this.state.plandata.addon_name
                    //                       : this.state.plandata.plan_name}
                    //         </h2>
                    //         <h3>
                    //                     {/* {this.state.currency === "Rs." ? this.currencyFormater(
                    //                           ((parseInt(this.props.match.params.agencyAppCount) * 99)
                    //                           +( parseInt(this.props.match.params.agencyUserCount) * 49)),
                    //                           "en-IN",
                    //                           "INR"
                    //                         )
                    //                       : this.currencyFormater(
                    //                           ((parseInt(this.props.match.params.agencyAppCount) * 99)
                    //                           +( parseInt(this.props.match.params.agencyUserCount) * 49)),
                    //                           "en-US",
                    //                           "USD"
                    //                         )
                    //                         } */}
                    //               $249.00
                                      
                    //         </h3>
                    //       </div>
                        
                                        
                          
                    //     : parseInt(this.state.isPlan) === 1 &&
                    //         <div className="purchase-summary-sections 14">
                    //             <h2>
                    //               {parseInt(this.state.isPlan) === 0
                    //                 ? this.state.plandata.addon_name
                    //                 : this.state.plandata.plan_name}
                    //             </h2>

                    //             <h3>
                    //                 ${ this.state.amount_to_pay}
                    //             </h3> 
                    //         </div>
                    //       }
                    //               <div className="purchase-summary-total 6">
                    //                 <h2>Total</h2>
                    //                 {
                    //                   this.state.gateway_subscription_id === '' && parseInt(this.state.isPlan) === 1 ?
                                    
                    //                 <h3>
                    //                           {/* { this.state.newpurchasegross_amount !== '' ?
                    //                             ((parseInt(this.props.match.params.agencyAppCount) * 99 + parseInt(this.props.match.params.agencyUserCount) * 49)) 
                    //                           :
                    //                             <>
                    //                             {this.state.currency === "Rs." ? 
                    //                             this.currencyFormater
                    //                                 (
                    //                                   ((parseInt(this.props.match.params.agencyAppCount) * 99 + parseInt(this.props.match.params.agencyUserCount) * 49)) ,
                    //                                   "en-IN",
                    //                                   "INR"
                    //                                 )
                    //                             : this.currencyFormater
                    //                                 (
                    //                                   ((parseInt(this.props.match.params.agencyAppCount)*99+parseInt(this.props.match.params.agencyUserCount)*49)) ,
                    //                                   "en-US",
                    //                                   "USD"
                    //                                 )}
                    //                             </>
                    //                           } */}
                    //                           $249.00
                    //                           </h3> :
                    //                           <h3>${this.state.amount_to_pay}</h3> }
                    //               </div>

                    // </>
                    // App/Workspace payment (Addon/Plan) for zoho and stripe

                      :
                        <>
                            <h4>Purchase details</h4>
                            
                                <div className="purchase-summary-sections">
                                    <h2>
                                      {parseInt(this.state.isPlan) === 0
                                        ? this.state.plandata.addon_name
                                        : this.state.plandata.plan_name}
                                    </h2>
                                    <h3>
                                      {this.state.currency === "Rs."
                                        ? this.currencyFormater(
                                            this.state.purchase_amount,
                                            "en-IN",
                                            "INR"
                                          )
                                        : this.currencyFormater(
                                            this.state.purchase_amount,
                                            "en-US",
                                            "USD"
                                          )}
                                    </h3>
                                  </div>
                                  
                                  <div className="purchase-summary-sections">
                                    <h2>Applied credits </h2>
                                    <h3>
                                      {this.state.currency === "Rs."
                                        ? this.currencyFormater(
                                            this.state.applied_credits,
                                            "en-IN",
                                            "INR"
                                          )
                                        : this.currencyFormater(
                                            this.state.applied_credits,
                                            "en-US",
                                            "USD"
                                          )}
                                    </h3>
                                  </div>
                                  <div className="purchase-summary-total 7">
                                    <h2>Total</h2>
                                    <h3>
                                      {this.state.currency === "Rs."
                                        ? this.currencyFormater(
                                            this.state.amount_to_pay,
                                            "en-IN",
                                            "INR"
                                          )
                                        : this.currencyFormater(
                                            this.state.amount_to_pay,
                                            "en-US",
                                            "USD"
                                          )}
                                    </h3>
                                  </div>
                        </>
                    }
                    {
                      parseInt(this.state.isPlan) === 0 && this.props?.location?.state?.addon_type === 1
                    ?
                    <div className="checkout-checkbox">
                        <label className="container1 container1-red">
                          <h5 className="checkout-terms">I grant support personnel permission to access my account and make necessary changes.
                          </h5>
                          <input disabled={this.state.formbuttondisabled} type="checkbox" value={this.state.grantPermission} onChange={(e) => this.setState({ grantPermission: e.target.checked })}/>
                          <span className="checkmark"></span>
                        </label>
                      </div>
                     :
                     <></>
                    }

                    {/* {this.state.payment_methods.length === 0 &&  this.state.appOrWpOrAgency!=="agency" &&
                              this.state.customer_billing_type === 2 ? (
                      <button type="button" onClick={(e) => this.addcardbutton(e)}> <div className="d-flex align-items-center justify-content-center h-100 w-100 1">
                      <div className={this.state.addCardLoader}>
                       <div className="new-loader"></div>
                      </div>
                      <div>{this.state.addCardText}</div>
                    </div></button>
                      ) : ( */}
                        <button type="button"
                        form="myform"
                        disabled={this.state.formbuttondisabled || !this.state.grantPermission}
                        onClick={(e) => {
                          parseInt(this.state.isPlan) === 0
                            ? this.addonmakepayment(
                                e,
                                this.props.location.state ? this.props.location.state.addon_code:this.props.match.params.planname,
                                this.props.location.state ? this.props.location.state.amount:this.state.selected_addon_data.amount ,
                                this.props.location.state? this.props.location.state.package_duration.name.toLowerCase():this.state.selected_addon_data.package_duration.name.toLowerCase(),
                                this.props.location.state? this.props.location.state.addon_identifier:this.state.selected_addon_data.addon_identifier
                              )
                            : this.makepayment(e);
                        }}><div className="d-flex align-items-center justify-content-center h-100 w-100 2">
                        <div className={this.state.loader}>
                        <div className="new-loader"></div>
                        </div>
                        <div>{this.state.submitval}</div>
                      </div></button>
                      {/* )} */}

                      

                      <p>By proceeding, you agree to our <a href="https://www.appmysite.com/terms-conditions/" rel="noopener noreferrer" target="_blank">Terms & Conditions</a> and <a href="https://www.appmysite.com/privacy-policy/"
                                  rel="noopener noreferrer"
                                  target="_blank">Privacy Policy</a>.</p>

                    </div>

                  </div>
                

                </div>
                </form>
                 ) 
                 : (
                   <>
                     <div className="checkout-page">
                      <div className="paddle-payment checkout-billing-details">
                      </div>
                     
                       <div className="checkout-purchase-detail">

                         <div className="checkout-purchase-summary 2">
                           <h4>Purchase details</h4>
                           <div className="purchase-summary-sections">
                             <h2>
                               {parseInt(this.state.isPlan) === 0
                                 ? this.state.plandata.addon_name
                                 : this.state.plandata.plan_name}
                             </h2>
                             <h3>{this.state.paddleTaxrate !== '' ? this.state.paddleNet.replace('US',' ') : '-'}</h3>
                           </div>
                          
                           {this.state.coupanamount !== '' && this.state.coupanamount !== 0 ?
                           <div className="purchase-summary-sections">
                             <h2>Coupon applied </h2>
                             <h3>{this.state.coupanamount !== '' && this.state.coupanamount !== 0 
                                  ? 
                                  <>{'-' + this.state.coupanamount.replace('US',' ') }</>
                                  :  
                                  '-'
                                 }
                                 </h3>
                           </div>
                           : <></>
                          }
                           <div className="purchase-summary-sections">
                             <h2>Tax {this.state.paddleTaxrate !== '' ? (this.state.paddleTaxrate.replace('US',' ')) : ''}</h2>
                             <h3>{this.state.paddleTaxrate !== '' ? this.state.paddleTax.replace('US',' ')
                                  :  
                                   <div className="new-blue-loader"> </div>
                                 }
                                 </h3>
                           </div>
                           
                           <div className="purchase-summary-total 8">
                             <h2 className="h6 font-weight-bold">Total</h2>
                             <h3 className="h6 font-weight-bold">
                               {this.state.paddleTaxrate !== '' ? this.state.paddleGross.replace('US',' ') : '-'}
                             </h3>
                           </div>

                           {this.state.discountdata !== null && (
                             <>
                               <hr></hr>
                               <div className="d-flex align-items-end justify-content-between">
                                 <h2 className="mt-0">
                                   {this.state.discountdata.name}
                                 </h2>
                                 {this.state.discountdata.type === 1 ? (
                                   <h4 className="mt-0">
                                     {this.state.interval_unit}{" "}
                                     {this.state.discountdata.discount_price}
                                   </h4>
                                 ) : (
                                   <h4 className="mt-0">
                                     {this.state.discountdata.discount_price} %
                                   </h4>
                                 )}
                               </div>
                             </>
                           )}
                         </div>
                       </div>
                     
                     </div>
                   </>
                 )}

             <div className="reviewsdiv">
             <div className="rattingdiv">
              <div className="customersratting">
                <h2>Here’s what our customers are saying.</h2>
                <p>More than 500,000 customers love and trust AppMySite. Partner with the best app builder software and reach your business goals faster.</p>
              </div>
              <div className="rattingimg">
                   <img
                          className="webrattingimg"
                          
                         
                          src={
                            require("../../assets/images/dashboard/rattingimg.png")
                              .default
                          }
                        />
                         <img
                          className="mobrattingimg"
                          
                         
                          src={
                            require("../../assets/images/dashboard/mobrattingimg.png")
                              .default
                          }
                        />
                </div>
             </div>
             <div className="certificateimg">
             <img
                          className="certificateimgdiv"
                          
                         
                          src={
                            require("../../assets/images/dashboard/certificateimg.png")
                              .default
                          }
                        />
                         <img
                          className="mobcertificateimg"
                          
                         
                          src={
                            require("../../assets/images/dashboard/mobcertificateimg.png")
                              .default
                          }
                        />
                        </div>
             </div>





                {/* <div className="container add-ons-plan">
                  <div className="d-flex align-items-center justify-content-between">
                    {(this.state.gateway_subscription_id === '' && this.state.customer_billing_type === 3 && parseInt(this.state.isPlan) === 1) ?
                      <h1 className="d-none d-md-block">
                        {this.state.paddleTab === "checkout"
                        ? "Checkout"
                        : "Payment"}
                      </h1>
                    : (this.state.customer_billing_type === 3 && parseInt(this.state.isPlan) === 0) ?
                      <h1 className="d-none d-md-block">
                      {this.state.paddleTab === "checkout"
                      ? "Checkout"
                      : "Payment"}
                      </h1>
                    :  <h1 className="d-none d-md-block">
                        {this.state.paddleTab === "checkout"
                        ? "Checkout"
                        : "Payment"}
                    </h1>
                    }
                    {this.state.customer_billing_type === 3 && (
                      <div className="d-flex align-items-center">
                        <p
                          style={{
                            fontSize: "13px",
                            cursor: this.state.paddleTab === "checkout"
                            ? "text"
                            : "pointer",
                            color:
                              this.state.paddleTab === "checkout"
                                ? "#596586"
                                : "#bcc2d3",
                          }}
                          className=""
                          onClick={() =>
                            this.setState({
                              paddleTab: "checkout",
                            })
                          }
                        >
                          Checkout
                        </p>
                        <img
                          className="new-arrw-img ml-2"
                          
                          style={{
                            marginBottom: "18px",
                          }}
                          src={
                            require("../../assets/images/dashboard/breadcrumb-arrow.png")
                              .default
                          }
                        />
                        <p
                          className="ml-2"
                          style={{
                            fontSize: "13px",
                            color:
                              this.state.paddleTab === "payment"
                                ? "#596586"
                                : "#bcc2d3",
                          }}
                        >
                          Payment
                        </p>
                      </div>
                    )}
                  </div>
                  {this.state.paddleTab === "checkout" ? (
                    <>
                    {this.state.customer_billing_type === 3 && this.state.gateway_subscription_id !== '' ?
                      <div className="row p-0 m-0">
                      
                      <div className="col-md-7 col-xl-4 p-md-0 payment-center">
                        <div className="pt-md-5 pr-md-5 pb-md-5 pl-md-5 payment-border">
                          <div>
                          {this.state.customer_billing_type === 3 ? 
                            <>
                              <h3 style={{fontFamily: "Axiforma-Bold", fontSize: "18px", marginBottom: "20px"}}>Purchase summary</h3>
                              {this.state.gateway_subscription_id === '' && parseInt(this.state.isPlan) === 1 ?
                                <div className="d-flex align-items-end justify-content-between mb-3">
                                  <h5>
                                    {parseInt(this.state.isPlan) === 0
                                      ? this.state.plandata.addon_name
                                      : this.state.plandata.plan_name}
                                  </h5>
                                  <h6>
                                  { this.state.newpurchase_amount !== '' ?
                                    this.state.newpurchase_amount
                                  :
                                    <>
                                    {this.state.currency === "Rs." ? this.currencyFormater(
                                          this.state.purchase_amount,
                                          "en-IN",
                                          "INR"
                                        )
                                      : this.currencyFormater(
                                          this.state.purchase_amount,
                                          "en-US",
                                          "USD"
                                        )}
                                    </>
                                  }
                                  </h6>
                                </div>
                                 
                              : parseInt(this.state.isPlan) === 1 ?
                              <>
                                <div className="d-flex align-items-end justify-content-between mb-3">
                                  <h5>
                                    {parseInt(this.state.isPlan) === 0
                                      ? this.state.plandata.addon_name
                                      : this.state.plandata.plan_name}
                                  </h5>
                                  <h6>
                                  { this.state.newpurchase_amount !== '' && parseInt(this.state.plandata.package_duration_id) !== 4 ?
                                    this.state.newpurchasegross_amount
                                  : this.state.newpurchase_amount !== '' && parseInt(this.state.plandata.package_duration_id) === 4 ?
                                    this.state.newpurchase_amount
                                  :
                                    <>
                                    {this.state.currency1 === "" ? '$' : this.state.currency1}
                                    {" "}
                                    {this.state.amount_to_pay}
                                    </>
                                  }   
                                  </h6>
                                  </div>
                                  
                                  { this.state.newpurchase_amount !== '' ?
                                        <>
                                       {parseInt(this.state.plandata.package_duration_id) !== 4 ?
                                        <div className="d-flex align-items-end justify-content-between mb-3">
                                          <h5>Applied credits </h5>
                                          <h6>
                                          { ((this.state.newpurchase_amount.replace(/[0-9]/g, '')).replace(/[`~!@#%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')) +
                                            this.newcurrencyFormater((((this.state.newpurchasegross_amount.replace(',','')).replace(/^\D+/g, '')) - ((this.state.amount_to_pay.replace(',','')).replace(/^\D+/g, ''))).toFixed(2),
                                              "en-"+this.state.paddlecountry)
                                          }
                                          </h6>
                                          </div>
                                        :  <div className="d-flex align-items-end justify-content-between mb-3">
                                              <h5>Tax </h5>
                                              <h6>
                                            { this.state.newpurchasetax_amount !== '' && this.state.paddlecountry === this.state.billing_country_code ?
                                              this.state.newpurchasetax_amount
                                            : 'Calculated at next step'
                                            }
                                            </h6>
                                            </div>
                                        }
                                        <div className="d-flex align-items-end justify-content-between mb-3">
                                          <p className="h6 font-weight-bold">Total</p>
                                          <p className="h6 font-weight-bold">
                                          {parseInt(this.state.plandata.package_duration_id) !== 4 ?
                                           ((this.state.newpurchase_amount.replace(/[0-9]/g, '')).replace(/[`~!@#%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ''))+ this.newcurrencyFormater(((this.state.amount_to_pay.replace(',','')).replace(/^\D+/g, '')),
                                              "en-"+this.state.paddlecountry,
                                            )
                                          : this.state.newpurchasegross_amount  !== '' ?
                                            this.state.newpurchasegross_amount
                                          : this.state.currency1 + this.state.amount_to_pay
                                           }
                                          </p>
                                        </div>
                                      </>
                                    :
                                    null
                                    }
                                  </>
                              :
                                  <div className="d-flex align-items-end justify-content-between mb-4">
                                  <h5>{this.state.plandata.addon_name}</h5>
                                  <h6>
                                  { this.state.newpurchase_amount !== '' ?
                                    this.state.newpurchase_amount
                                  :
                                    <>
                                    {this.state.currency === "Rs." ? this.currencyFormater(
                                          this.state.purchase_amount,
                                          "en-IN",
                                          "INR"
                                        )
                                      : this.currencyFormater(
                                          this.state.purchase_amount,
                                          "en-US",
                                          "USD"
                                        )}
                                    </>
                                  }
                                  </h6>
                                </div>
                              }
                                
                              {this.state.gateway_subscription_id === '' && (parseInt(this.state.isPlan) === 1) ?
                                <>
                                      <div className="d-flex align-items-end justify-content-between mb-4">
                                        <h5>Tax </h5>
                                      <h6>
                                      { this.state.newpurchasetax_amount !== '' && this.state.paddlecountry === this.state.billing_country_code ?
                                        this.state.newpurchasetax_amount
                                      : 'Calculated at next step'
                                      }
                                      </h6>
                                      </div>
                          
                                      <div className="d-flex align-items-end justify-content-between mb-4">
                                        <p className="h6 font-weight-bold">Total</p>
                                        <p className="h6 font-weight-bold">
                                        { this.state.newpurchasegross_amount !== '' ?
                                          this.state.newpurchasegross_amount
                                        :
                                          <>
                                          {this.state.currency === "Rs."
                                            ? this.currencyFormater(
                                                this.state.amount_to_pay,
                                                "en-IN",
                                                "INR"
                                              )
                                            : this.currencyFormater(
                                                this.state.amount_to_pay,
                                                "en-US",
                                                "USD"
                                              )}
                                          </>
                                        }
                                        </p>
                                      </div>
                                </>
                              : parseInt(this.state.isPlan) === 0 ?
                                  <>
                                  <div className="d-flex align-items-end justify-content-between mb-4">
                                    <h5>Tax </h5>
                                  <h6>
                                  { this.state.newpurchasetax_amount !== '' ?
                                    this.state.newpurchasetax_amount
                                  : 'Calculated at next step'
                                  }
                                  </h6>
                                  </div>
                      
                                  <div className="d-flex align-items-end justify-content-between mb-4">
                                    <p className="h6 font-weight-bold">Total</p>
                                    <p className="h6 font-weight-bold">
                                    { this.state.newpurchasegross_amount !== '' ?
                                      this.state.newpurchasegross_amount
                                    :
                                      <>
                                      {this.state.currency === "Rs."
                                        ? this.currencyFormater(
                                            this.state.amount_to_pay,
                                            "en-IN",
                                            "INR"
                                          )
                                        : this.currencyFormater(
                                            this.state.amount_to_pay,
                                            "en-US",
                                            "USD"
                                          )}
                                      </>
                                    }
                                    </p>
                                  </div>
                              </>
                              : null
                              }
                              
                            </>
                          :
                          <>
                           <h3>Purchase summary</h3>
                              <div className="d-flex align-items-end justify-content-between mb-4">
                                <h5>
                                  {parseInt(this.state.isPlan) === 0
                                    ? this.state.plandata.addon_name
                                    : this.state.plandata.plan_name}
                                </h5>
                                <h6>
                                  {this.state.currency === "Rs."
                                    ? this.currencyFormater(
                                        this.state.purchase_amount,
                                        "en-IN",
                                        "INR"
                                      )
                                    : this.currencyFormater(
                                        this.state.purchase_amount,
                                        "en-US",
                                        "USD"
                                      )}
                                </h6>
                              </div>
                              
                              <div className="d-flex align-items-end justify-content-between mb-4">
                                <h5>Applied credits </h5>
                                <h6>
                                  {this.state.currency === "Rs."
                                    ? this.currencyFormater(
                                        this.state.applied_credits,
                                        "en-IN",
                                        "INR"
                                      )
                                    : this.currencyFormater(
                                        this.state.applied_credits,
                                        "en-US",
                                        "USD"
                                      )}
                                </h6>
                              </div>
                              <div className="d-flex align-items-end justify-content-between mb-4">
                                <p className="h6 font-weight-bold">Total</p>
                                <p className="h6 font-weight-bold">
                                  {this.state.currency === "Rs."
                                    ? this.currencyFormater(
                                        this.state.amount_to_pay,
                                        "en-IN",
                                        "INR"
                                      )
                                    : this.currencyFormater(
                                        this.state.amount_to_pay,
                                        "en-US",
                                        "USD"
                                      )}
                                </p>
                              </div>
                          </>
                          }

                    

                            {this.state.discountdata !== null && (
                              <>
                                <hr></hr>
                                <div className="d-flex align-items-end justify-content-between">
                                  <h2 className="mt-0">
                                    {this.state.discountdata.name}
                                  </h2>
                                  {this.state.discountdata.type === 1 ? (
                                    <h4 className="mt-0">
                                      {this.state.interval_unit}{" "}
                                      {this.state.discountdata.discount_price}
                                    </h4>
                                  ) : (
                                    <h4 className="mt-0">
                                      {this.state.discountdata.discount_price} %
                                    </h4>
                                  )}
                                </div>
                              </>
                            )}

                            <hr></hr>
                          </div>
                          {this.state.customer_billing_type === 2 && (
                            <div className="three-input mt-3 w-100">
                              <div className="module_title d-flex justify-content-between select_payment align-items-center">
                                <h4 className="mb-0">Select payment method</h4>
                                {this.state.payment_methods.length > 0 && (
                                  <Link
                                    to="#"
                                    onClick={(e) => this.addcardbutton(e)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="12"
                                      height="12"
                                      viewBox="0 0 12 12"
                                      className="mr-1"
                                    >
                                      <path
                                        id="Union_406"
                                        data-name="Union 406"
                                        d="M149.25,16363h1.5v5.25H156v1.5h-5.25v5.25h-1.5v-5.25H144v-1.5h5.25Z"
                                        transform="translate(-144 -16363)"
                                        fill="#3064f9"
                                      />
                                    </svg>
                                    Add a card
                                  </Link>
                                )}
                              </div>
                              <div className="color_picker_full mt-2">
                                <select
                                  style={{ color: "#7782A1", fontSize: "14px" }}
                                  required
                                  name="payment_method_id"
                                  value={parseInt(this.state.payment_method_id)}
                                  onChange={(e) => this.handleformfunction(e)}
                                >
                                  {this.state.payment_methods.length === 0 && (
                                    <option value="">No card to select </option>
                                  )}
                                  {this.state.payment_methods.map((res, i) =>
                                    comparedate(
                                      i,
                                      res.id,
                                      res.card_last4_digit,
                                      res.expiry_month,
                                      res.expiry_year
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                          )}
                          <div></div>
                          <div className="">
                            <div className="d-md-flex justify-content-between forgot_pw agree-terms">
                              <label className="container1 mb-0">
                                I agreed to the{" "}
                                <a
                                  href="https://www.appmysite.com/terms-conditions/"
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  Terms of Services
                                </a>{" "}
                                and the{" "}
                                <a
                                  href="https://www.appmysite.com/privacy-policy/"
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  Privacy Policy
                                </a>
                                .
                                <input
                                  type="checkbox"
                                  form="myform"
                                  required
                                  defaultValue={this.state.agree}
                                  onClick={(e) => this.handleagree(e)}
                                  style={{
                                    left: "0",
                                    height: "inherit",
                                    width: "inherit",
                                  }}
                                />{" "}
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="fixed-button">
                              {this.state.payment_methods.length === 0 &&
                              this.state.customer_billing_type === 2 ? (
                                <Link
                                  to="#"
                                  className="addpackage"
                                  onClick={(e) => this.addcardbutton(e)}
                                >
                                  <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                    <div className={this.state.addCardLoader}>
                                      <img
                                        src={this.state.loaderimage}
                                        alt="loader"
                                      />
                                    </div>
                                    <div>{this.state.addCardText}</div>
                                  </div>
                                </Link>
                              ) : (
                                <button
                                  type="button"
                                  className="addpackage"
                                  form="myform"
                                  disabled={this.state.formbuttondisabled}
                                  onClick={(e) => {
                                    parseInt(this.state.isPlan) === 0
                                      ? this.addonmakepayment(
                                          e,
                                          this.props.location.state.addon_code,
                                          this.props.location.state.amount,
                                          this.props.location.state.package_duration.name.toLowerCase(),
                                          this.props.location.state.addon_identifier
                                        )
                                      : this.makepayment(e);
                                  }}
                               >
                                  <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                    <div className={this.state.loader}>
                                      <img
                                        src={this.state.loaderimage}
                                        alt="loader"
                                      />
                                    </div>
                                    <div>{this.state.submitval}</div>
                                  </div>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                      <div className="row  addonpayment p-0 m-0">
                        <div className="col-md-7 col-xl-8 pl-md-0 pr-md-0">
                          <form
                            className="pt-md-5 pl-md-5 pb-md-5 pr-md-5"
                            id="myform"
                          >
                            <h2
                              className="mt-0 pt-0 mb-4"
                              style={{ fontSize: "20px" }}
                            >
                              Billing Details
                            </h2>
                            <div className="payment-info border-0 pt-0">
                              <div className="d-md-flex copy align-items-center justify-content-between flex-wrap mb-3">
                                <div className="three-input mb-3 mb-md-0">
                                  <div className="module_title">
                                    <h4 className="mb-0 mt-0">First Name</h4>
                                  </div>
                                  <div className="color_picker_full mt-2">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        value={this.state.first_name}
                                        required
                                        className="form-control form-name"
                                        name="first_name"
                                        onChange={(e) =>
                                          this.handleformfunction(e)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="three-input">
                                  <div className="module_title">
                                    <h4 className="mb-0 mt-md-0">Last Name</h4>
                                  </div>
                                  <div className="color_picker_full mt-2">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        value={this.state.last_name}
                                        required
                                        className="form-control form-name"
                                        name="last_name"
                                        onChange={(e) =>
                                          this.handleformfunction(e)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="d-md-flex copy align-items-center justify-content-between flex-wrap mb-3">
                                <div className="three-input mb-3 mb-md-0">
                                  <div className="module_title">
                                    <h4 className="mb-0">Company</h4>
                                  </div>
                                  <div className="color_picker_full mt-2">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control form-name"
                                        maxLength="30"
                                        name="company_name"
                                        value={this.state.company_name}
                                        onChange={(e) =>
                                          this.handleformfunction(e)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="three-input mb-3 mb-md-0">
                                  <div className="module_title">
                                    <h4 className="mb-0">Tax ID</h4>
                                  </div>
                                  <div className="color_picker_full mt-2">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control form-name"
                                        maxLength="30"
                                        name="tax_number"
                                        value={this.state.tax_number}
                                        onChange={(e) =>
                                          this.handleformfunction(e)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="d-md-flex copy align-items-center justify-content-between flex-wrap mb-3">
                                <div className="module_title">
                                  <h4 className="mb-0">Street Address</h4>
                                </div>
                                <div className="color_picker_full mt-2">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control form-name"
                                      required
                                      name="billing_address"
                                      value={this.state.billing_address}
                                      onChange={(e) => this.handleformfunction(e)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="d-md-flex copy align-items-center justify-content-between flex-wrap mb-3">
                                <div className="three-input mb-3 mb-md-0">
                                  <div className="module_title">
                                    <h4 className="mb-0">City</h4>
                                  </div>
                                  <div className="color_picker_full mt-2">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control form-name"
                                        required
                                        name="billing_city"
                                        value={this.state.billing_city}
                                        onChange={(e) =>
                                          this.handlebillingstate(e)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="three-input">
                                  <div className="module_title">
                                    <h4 className="mb-0">State</h4>
                                  </div>
                                  <div className="color_picker_full mt-2">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control form-name"
                                        required
                                        name="billing_state"
                                        value={this.state.billing_state}
                                        onChange={(e) =>
                                          this.handlebillingstate(e)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="d-md-flex copy align-items-center justify-content-between flex-wrap mb-3">
                                <div className="three-input mb-3 mb-md-0">
                                  <div className="module_title">
                                    <h4 className="mb-0">Zip</h4>
                                  </div>
                                  <div className="color_picker_full mt-2">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control form-name"
                                        required
                                        name="billing_zip"
                                        maxLength="10"
                                        value={this.state.billing_zip}
                                        onChange={(e) =>
                                          this.handleformfunction(e)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="three-input">
                                  <div className="module_title">
                                    <h4 className="mb-0">Country</h4>
                                  </div>
                                  <div className="color_picker_full mt-2">
                                    <select
                                      name="billing_country"
                                      value={this.state.billing_country}
                                      onChange={(e) => this.handleformfunction(e)}
                                    >
                                      <option value="">Select Country</option>
                                      {this.state.countryselectfield.map(
                                        (res, i) => (
                                          <option key={i} value={res.id} isovalue={res.country_code} >
                                            {" "}
                                            {res.country_name}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </form>
                        </div>
                        <div className="col-md-5 col-xl-4 p-md-0">
                          <div className="pt-md-5 pr-md-5 pb-md-5 pl-md-5 payment-border">
                            <div>
                            {this.state.customer_billing_type === 3 ? 
                              <>
                                <h3>Purchase summary</h3>
                                {this.state.gateway_subscription_id === '' && parseInt(this.state.isPlan) === 1 ?
                                  <div className="d-flex align-items-end justify-content-between mb-4">
                                    <h5>
                                      {parseInt(this.state.isPlan) === 0
                                        ? this.state.plandata.addon_name
                                        : this.state.plandata.plan_name}
                                    </h5>
                                    <h6>
                                    { this.state.newpurchase_amount !== '' ?
                                      this.state.newpurchase_amount
                                    :
                                      <>
                                      {this.state.currency === "Rs." ? this.currencyFormater(
                                            this.state.purchase_amount,
                                            "en-IN",
                                            "INR"
                                          )
                                        : this.currencyFormater(
                                            this.state.purchase_amount,
                                            "en-US",
                                            "USD"
                                          )}
                                      </>
                                    }
                                    </h6>
                                  </div>
                                  
                                : parseInt(this.state.isPlan) === 1 ?
                                <>
                                  <div className="d-flex align-items-end justify-content-between mb-4">
                                    <h5>
                                      {parseInt(this.state.isPlan) === 0
                                        ? this.state.plandata.addon_name
                                        : this.state.plandata.plan_name}
                                    </h5>
                                    <h6>
                                    { this.state.newpurchase_amount !== '' ?
                                      this.state.newpurchase_amount
                                    :
                                      <>
                                      {this.state.currency1 === "" ? '$' : this.state.currency1}
                                      {" "}
                                      {this.state.amount_to_pay}
                                      </>
                                    }   
                                    </h6>
                                    </div>
                                    
                                    { this.state.newpurchase_amount !== '' ?
                                          <>
                                        {parseInt(this.state.plandata.package_duration_id) !== 4 ?
                                          <div className="d-flex align-items-end justify-content-between mb-4">
                                            <h5>Applied credits </h5>
                                            <h6>
                                            { ((this.state.newpurchase_amount.replace(/[0-9]/g, '')).replace(/[`~!@#%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')) +
                                              this.newcurrencyFormater(
                                                (((this.state.newpurchase_amount.replace(',','')).replace(/^\D+/g, '')) - ((this.state.amount_to_pay.replace(',','')).replace(/^\D+/g, ''))).toFixed(2),
                                                "en-"+this.state.paddlecountry,
                                              )
                                            }
                                            </h6>
                                            </div>
                                          :  <div className="d-flex align-items-end justify-content-between mb-4">
                                                <h5>Tax </h5>
                                                <h6>
                                              { this.state.newpurchasetax_amount !== '' && this.state.paddlecountry === this.state.billing_country_code ?
                                                this.state.newpurchasetax_amount
                                              : 'Calculated at next step'
                                              }
                                              </h6>
                                              </div>
                                          }
                                          <div className="d-flex align-items-end justify-content-between mb-4">
                                            <p className="h6 font-weight-bold">Total</p>
                                            <p className="h6 font-weight-bold">
                                            {parseInt(this.state.plandata.package_duration_id) !== 4 ?
                                            ((this.state.newpurchase_amount.replace(/[0-9]/g, '')).replace(/[`~!@#%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ''))+ this.newcurrencyFormater(((this.state.amount_to_pay.replace(',','')).replace(/^\D+/g, '')),
                                                "en-"+this.state.paddlecountry,
                                              )
                                            : this.state.newpurchasegross_amount  !== '' ?
                                              this.state.newpurchasegross_amount
                                            : this.state.currency1 + this.state.amount_to_pay
                                            }
                                            </p>
                                          </div>
                                        </>
                                      :
                                      null
                                      }
                                    </>
                                :
                                    <div className="d-flex align-items-end justify-content-between mb-4">
                                    <h5>{this.state.plandata.addon_name}</h5>
                                    <h6>
                                    { this.state.newpurchase_amount !== '' ?
                                      this.state.newpurchase_amount
                                    :
                                      <>
                                      {this.state.currency === "Rs." ? this.currencyFormater(
                                            this.state.purchase_amount,
                                            "en-IN",
                                            "INR"
                                          )
                                        : this.currencyFormater(
                                            this.state.purchase_amount,
                                            "en-US",
                                            "USD"
                                          )}
                                      </>
                                    }
                                    </h6>
                                  </div>
                                }
                                  
                                {this.state.gateway_subscription_id === '' && (parseInt(this.state.isPlan) === 1) ?
                                  <>
                                        <div className="d-flex align-items-end justify-content-between mb-4">
                                          <h5>Tax </h5>
                                        <h6>
                                        { this.state.newpurchasetax_amount !== '' && this.state.paddlecountry === this.state.billing_country_code ?
                                          this.state.newpurchasetax_amount
                                        : 'Calculated at next step'
                                        }
                                        </h6>
                                        </div>
                            
                                        <div className="d-flex align-items-end justify-content-between mb-4">
                                          <p className="h6 font-weight-bold">Total</p>
                                          <p className="h6 font-weight-bold">
                                          { this.state.newpurchasegross_amount !== '' ?
                                            this.state.newpurchasegross_amount
                                          :
                                            <>
                                            {this.state.currency === "Rs."
                                              ? this.currencyFormater(
                                                  this.state.amount_to_pay,
                                                  "en-IN",
                                                  "INR"
                                                )
                                              : this.currencyFormater(
                                                  this.state.amount_to_pay,
                                                  "en-US",
                                                  "USD"
                                                )}
                                            </>
                                          }
                                          </p>
                                        </div>
                                  </>
                                : parseInt(this.state.isPlan) === 0 ?
                                    <>
                                    <div className="d-flex align-items-end justify-content-between mb-4">
                                      <h5>Tax </h5>
                                    <h6>
                                    { this.state.newpurchasetax_amount !== '' ?
                                      this.state.newpurchasetax_amount
                                    : 'Calculated at next step'
                                    }
                                    </h6>
                                    </div>
                        
                                    <div className="d-flex align-items-end justify-content-between mb-4">
                                      <p className="h6 font-weight-bold">Total</p>
                                      <p className="h6 font-weight-bold">
                                      { this.state.newpurchasegross_amount !== '' ?
                                        this.state.newpurchasegross_amount
                                      :
                                        <>
                                        {this.state.currency === "Rs."
                                          ? this.currencyFormater(
                                              this.state.amount_to_pay,
                                              "en-IN",
                                              "INR"
                                            )
                                          : this.currencyFormater(
                                              this.state.amount_to_pay,
                                              "en-US",
                                              "USD"
                                            )}
                                        </>
                                      }
                                      </p>
                                    </div>
                                </>
                                : null
                                }
                                
                              </>
                            :
                            <>
                            <h3>Purchase summary</h3>
                                <div className="d-flex align-items-end justify-content-between mb-4">
                                  <h5>
                                    {parseInt(this.state.isPlan) === 0
                                      ? this.state.plandata.addon_name
                                      : this.state.plandata.plan_name}
                                  </h5>
                                  <h6>
                                    {this.state.currency === "Rs."
                                      ? this.currencyFormater(
                                          this.state.purchase_amount,
                                          "en-IN",
                                          "INR"
                                        )
                                      : this.currencyFormater(
                                          this.state.purchase_amount,
                                          "en-US",
                                          "USD"
                                        )}
                                  </h6>
                                </div>
                                
                                <div className="d-flex align-items-end justify-content-between mb-4">
                                  <h5>Applied credits </h5>
                                  <h6>
                                    {this.state.currency === "Rs."
                                      ? this.currencyFormater(
                                          this.state.applied_credits,
                                          "en-IN",
                                          "INR"
                                        )
                                      : this.currencyFormater(
                                          this.state.applied_credits,
                                          "en-US",
                                          "USD"
                                        )}
                                  </h6>
                                </div>
                                <div className="d-flex align-items-end justify-content-between mb-4">
                                  <p className="h6 font-weight-bold">Total</p>
                                  <p className="h6 font-weight-bold">
                                    {this.state.currency === "Rs."
                                      ? this.currencyFormater(
                                          this.state.amount_to_pay,
                                          "en-IN",
                                          "INR"
                                        )
                                      : this.currencyFormater(
                                          this.state.amount_to_pay,
                                          "en-US",
                                          "USD"
                                        )}
                                  </p>
                                </div>
                            </>
                            }

                      

                              {this.state.discountdata !== null && (
                                <>
                                  <hr></hr>
                                  <div className="d-flex align-items-end justify-content-between">
                                    <h2 className="mt-0">
                                      {this.state.discountdata.name}
                                    </h2>
                                    {this.state.discountdata.type === 1 ? (
                                      <h4 className="mt-0">
                                        {this.state.interval_unit}{" "}
                                        {this.state.discountdata.discount_price}
                                      </h4>
                                    ) : (
                                      <h4 className="mt-0">
                                        {this.state.discountdata.discount_price} %
                                      </h4>
                                    )}
                                  </div>
                                </>
                              )}

                              <hr></hr>
                            </div>
                            {this.state.customer_billing_type === 2 && (
                              <div className="three-input mt-3 w-100">
                                <div className="module_title d-flex justify-content-between select_payment align-items-center">
                                  <h4 className="mb-0">Select payment method</h4>
                                  {this.state.payment_methods.length > 0 && (
                                    <Link
                                      to="#"
                                      onClick={(e) => this.addcardbutton(e)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        height="12"
                                        viewBox="0 0 12 12"
                                        className="mr-1"
                                      >
                                        <path
                                          id="Union_406"
                                          data-name="Union 406"
                                          d="M149.25,16363h1.5v5.25H156v1.5h-5.25v5.25h-1.5v-5.25H144v-1.5h5.25Z"
                                          transform="translate(-144 -16363)"
                                          fill="#3064f9"
                                        />
                                      </svg>
                                      Add a card
                                    </Link>
                                  )}
                                </div>
                                <div className="color_picker_full mt-2">
                                  <select
                                    style={{ color: "#7782A1", fontSize: "14px" }}
                                    required
                                    name="payment_method_id"
                                    value={parseInt(this.state.payment_method_id)}
                                    onChange={(e) => this.handleformfunction(e)}
                                  >
                                    {this.state.payment_methods.length === 0 && (
                                      <option value="">No card to select </option>
                                    )}
                                    {this.state.payment_methods.map((res, i) =>
                                      comparedate(
                                        i,
                                        res.id,
                                        res.card_last4_digit,
                                        res.expiry_month,
                                        res.expiry_year
                                      )
                                    )}
                                  </select>
                                </div>
                              </div>
                            )}
                            <div></div>
                            <div className="">
                              <div className="d-md-flex justify-content-between forgot_pw agree-terms">
                                <label className="container1 mb-0">
                                  I agreed to the{" "}
                                  <a
                                    href="https://www.appmysite.com/terms-conditions/"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    Terms of Services
                                  </a>{" "}
                                  and the{" "}
                                  <a
                                    href="https://www.appmysite.com/privacy-policy/"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    Privacy Policy
                                  </a>
                                  .
                                  <input
                                    type="checkbox"
                                    form="myform"
                                    required
                                    defaultValue={this.state.agree}
                                    onClick={(e) => this.handleagree(e)}
                                    style={{
                                      left: "0",
                                      height: "inherit",
                                      width: "inherit",
                                    }}
                                  />{" "}
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                              <div className="fixed-button">
                                {this.state.payment_methods.length === 0 &&
                                this.state.customer_billing_type === 2 ? (
                                  <Link
                                    to="#"
                                    className="addpackage"
                                    onClick={(e) => this.addcardbutton(e)}
                                  >
                                    <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                      <div className={this.state.addCardLoader}>
                                        <img
                                          src={this.state.loaderimage}
                                          alt="loader"
                                        />
                                      </div>
                                      <div>{this.state.addCardText}</div>
                                    </div>
                                  </Link>
                                ) : (
                                  <button
                                    type="button"
                                    className="addpackage"
                                    form="myform"
                                    disabled={this.state.formbuttondisabled}
                                    onClick={(e) => {
                                      parseInt(this.state.isPlan) === 0
                                        ? this.addonmakepayment(
                                            e,
                                            this.props.location.state.addon_code,
                                            this.props.location.state.amount,
                                            this.props.location.state.package_duration.name.toLowerCase(),
                                            this.props.location.state.addon_identifier
                                          )
                                        : this.makepayment(e);
                                    }}
                                >
                                    <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                      <div className={this.state.loader}>
                                        <img
                                          src={this.state.loaderimage}
                                          alt="loader"
                                        />
                                      </div>
                                      <div>{this.state.submitval}</div>
                                    </div>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    </>
                  ) 
                  : (
                    <>
                      <div className="row  addonpayment p-0 m-0">
                        <div className="col-md-7 col-xl-7 pl-md-0 pr-md-0">
                          <div
                            className="paddle-payment pt-md-5 pl-md-5 pb-md-5 pr-md-5"
                            style={{
                              borderRight: "1px solid #e8edf8",
                              height: "100%",
                            }}
                          ></div>
                        </div>
                      
                        <div className="col-md-5 col-xl-4 p-md-0">
                          <div className="pt-md-5 pr-md-5 pb-md-5 pl-md-5 payment-border">
                            <h3>Purchase summary </h3>
                            <div className="d-flex align-items-end justify-content-between mb-4">
                              <h5>
                                {parseInt(this.state.isPlan) === 0
                                  ? this.state.plandata.addon_name
                                  : this.state.plandata.plan_name}
                              </h5>
                              <h6>{this.state.paddleNet}</h6>
                            </div>

                            <div className="d-flex align-items-end justify-content-between mb-4">
                              <h5>Tax {this.state.paddleTaxrate !== '' ? (this.state.paddleTaxrate) : ''}</h5>
                              <h6>{this.state.paddleTaxrate !== '' ? this.state.paddleTax :  
                                    <div className="new-blue-loader"> </div>
                                    }
                                  </h6>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mb-4">
                              <p className="h6 font-weight-bold">Total</p>
                              <p className="h6 font-weight-bold">
                                {this.state.paddleGross}
                              </p>
                            </div>

                            {this.state.discountdata !== null && (
                              <>
                                <hr></hr>
                                <div className="d-flex align-items-end justify-content-between">
                                  <h2 className="mt-0">
                                    {this.state.discountdata.name}
                                  </h2>
                                  {this.state.discountdata.type === 1 ? (
                                    <h4 className="mt-0">
                                      {this.state.interval_unit}{" "}
                                      {this.state.discountdata.discount_price}
                                    </h4>
                                  ) : (
                                    <h4 className="mt-0">
                                      {this.state.discountdata.discount_price} %
                                    </h4>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      
                      </div>
                    </>
                  )}
                </div> */}


              </div>
            </>
          )}
        </section>
        

      </>
    );
  }
}
