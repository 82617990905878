import React from "react";
import { Link } from "react-router-dom";

const PlanDetailsCard = ({
  planTitle,
  planDescription,
  planName,
  plan,
  gatewaySubscriptionId,
  subscriptionStatus,
  downgradeAtTermEnd,
  webTechnology,
  packageDurationId,
}) => {
  const returnPlanImg = (currPlan) => {
    switch (currPlan) {
      case "preview":
        return "plan-preview.png";
      case "starter":
        return "plan-starter.png";
      case "pro":
        return "plan-pro.png";
      case "premium":
        return "plan-premium.png";
      case "workspace":
        return "plan-pro.png";
      default:
        return "plan-preview.png";
    }
  };

  const utmSrc =
    "?utm_source=app-dashboard&utm_medium=app-builder&utm_campaign=plan-card";

  const returnRedirectionUrl = () => {
    switch (parseInt(localStorage.getItem("website_technology"))) {
      case 2:
        return `/app/web-to-app-pricing/${btoa(
          localStorage.getItem("Appid")
        )}${utmSrc}`;
      case 3:
        return `/app/custom-app-pricing/${btoa(
          localStorage.getItem("Appid")
        )}${utmSrc}`;
      case 4:
        return `/app/wordpress-pricing/${btoa(
          localStorage.getItem("Appid")
        )}${utmSrc}`;
      case 5:
        return `/app/woocommerce-pricing/${btoa(
          localStorage.getItem("Appid")
        )}${utmSrc}`;
      default:
        return `/app/pricing/${btoa(localStorage.getItem("Appid"))}${utmSrc}`;
    }
  };

  return (
    <div className="app-plan-detail">
      <div className="app-plan-alignment">
        <div className="app-plan-detail-one">
          <h3 style={{ textTransform: "capitalize" }}>{planTitle || ""}</h3>
          <p>{planDescription || ""}</p>
          <h6>
            Plan:{" "}
            <span style={{ textTransform: "capitalize" }}>
              {planName || ""}
            </span>
          </h6>
          {gatewaySubscriptionId ? <h6>ID: {gatewaySubscriptionId}</h6> : <></>}
        </div>

        <div className="app-plan-detail-two">
          <img
            src={
              require(`../../assets/images/dashboard/${returnPlanImg(plan)}`)
                .default
            }
            className="img-fluid"
            alt={returnPlanImg(plan)}
          />
        </div>
      </div>

      <div className="app-status-upgrade">
        <p style={{ textTransform: "capitalize" }}>
          {subscriptionStatus || ""}
        </p>
        {localStorage.getItem("agencyid") &&
        localStorage.getItem("agencyid") != 1 ? null : (
          <>
            {localStorage.getItem("workspaceId") === "undefined" ||
            localStorage.getItem("workspaceId") === undefined ||
            localStorage.getItem("workspaceId") === "" ||
            localStorage.getItem("workspaceId") === null ? (
              <>
                <Link
                  style={{
                    color: downgradeAtTermEnd === 1 ? "#BCC1CE" : "",
                    cursor: downgradeAtTermEnd === 1 ? "pointer" : "",
                    fontSize: downgradeAtTermEnd === 1 ? "13px" : "",
                  }}
                  to={returnRedirectionUrl()}
                >
                  {downgradeAtTermEnd === 1 ? "Reactivate" : "Upgrade"}
                </Link>
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </div>

      {packageDurationId === 1 ? (
        <div className="dash-app-upgrade">
          {webTechnology === 5 ? (
            <p>
              Save up to 34% <span>on annual plan.</span>
            </p>
          ) : (
            <p>
              Save up to 53% <span>on annual plan.</span>
            </p>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PlanDetailsCard;
