import React from "react";

const HomeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
    >
      <g
        id="Group_42975"
        data-name="Group 42975"
        transform="translate(-20 -527)"
      >
        <rect
          id="Rectangle_25572"
          data-name="Rectangle 25572"
          width="26"
          height="26"
          transform="translate(20 527)"
          fill="none"
        />
        <path
          id="Path_119115"
          data-name="Path 119115"
          d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z"
          transform="translate(22.5 529.36)"
          fill="none"
          stroke="#7782a1"
          stroke-miterlimit="10"
          stroke-width="1.5"
        />
      </g>
    </svg>
  );
};

export default HomeIcon;
