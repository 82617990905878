import React, { useEffect } from "react";

const VistaCreatePopUp = ({
  artworkWidth,
  artworkHeight,
  moduleName,
  downloadFormat,
  formatKey,
  designId,
  submitFn,
}) => {
  useEffect(() => {
  const api = window.CrelloEditor.init({
    apiKey: process.env.REACT_APP_VISTA_API_KEY,
    customDimensions: {
      width: artworkWidth,
      height: artworkHeight,
      measureUnits: "px",
    },
    user: {
      externalUserId: localStorage.getItem("user_id"),
    },
    projectName: moduleName,
    downloadFormat,
    onPublishAction: submitFn,
    scaleRatio: 1,
    sidebarDesignType: formatKey,
    exportQuality: 1,
    designId,
    activeSidebarTab: "designs",
    searchQueryPhotos: "Abstract design",
    sidebarTabs: [
      "photos",
      "designs",
      "text",
      "styles",
      "objects",
      "background",
      "library",
      "brandkit"
    ],
  });

  }, []);
  return <></>;
};

export default VistaCreatePopUp;
