import React, { Component } from 'react'
import { Carousel } from 'react-responsive-carousel';

export class ProductPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
          submitval: "Save",
          
        };
      }
  render() {
    const calculateHeight = (width,ratio) => {
      let height = (width * 3) / 4;
  
      switch (ratio) {
        case "4:3":
          height = (width * 3) / 4;
          break;
        case "3:4":
          height = (width * 4) / 3;
          break;
        case "1:1":
          height = (width * 1) / 1;
          break;
        case "16:9":
          height = (width * 9) / 16;
          break;
        case "9:16":
            height = (width * 16) / 9;
            break;
      }
      return height;
    };
    
  
    return (
        <div className='productsetting-mobile-screen '>
        <Carousel
          selectedItem={this.props.value.state.active_slide}
          // onChange={(e) =>this.props.value.handlesliderChange(e)}
        showArrows={false}
        swipeable={true}
        showThumbs={false}
        >  
        {/* First Slide */}
        <div className='w-100 pro-first-screen-scroll'>
            <div className='product-preview-header'>
              <h2>
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11">
                  <g id="Group_41217" data-name="Group 41217" transform="translate(-1329 -262.999)">
                    <line id="Line_381" data-name="Line 381" x1="14" transform="translate(1329 263.499)" fill="none" stroke="#333" stroke-miterlimit="10" stroke-width="1" />
                    <line id="Line_382" data-name="Line 382" x1="14" transform="translate(1329 268.499)" fill="none" stroke="#333" stroke-miterlimit="10" stroke-width="1" />
                    <line id="Line_383" data-name="Line 383" x1="14" transform="translate(1329 273.499)" fill="none" stroke="#333" stroke-miterlimit="10" stroke-width="1" />
                  </g>
                </svg> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="15.733" height="14.433" viewBox="0 0 15.733 14.433">
  <g id="Group_42334" data-name="Group 42334" transform="translate(0.707 1.364)">
    <path id="Path_118934" data-name="Path 118934" d="M-377.533-3200.5l-6.863,6.863,6.863,6.863" transform="translate(384.396 3199.49)" fill="none" stroke="#1a1a1a" stroke-width="1"/>
    <path id="Path_118935" data-name="Path 118935" d="M-654.5-3185.5h15.026" transform="translate(654.5 3191.336)" fill="none" stroke="#1a1a1a" stroke-width="1"/>
  </g>
</svg>
                <p>Product list</p>
              </h2>
              <h3>
                {
                  ( this.props.value.state.show_product_search===true ||
                     this.props.value.state.show_product_search===1 ) &&
                
                <svg xmlns="http://www.w3.org/2000/svg" width="15.354" height="15.353" viewBox="0 0 15.354 15.353">
                  <path id="Union_2934" data-name="Union 2934" d="M10.686,10.5l4,4ZM.185,6a6,6,0,1,1,6,6A6,6,0,0,1,.185,6Z" transform="translate(0.315 0.5)" fill="rgba(0,0,0,0)" stroke="#333" stroke-miterlimit="10" stroke-width="1" />
                </svg>
                }
{/* <svg xmlns="http://www.w3.org/2000/svg" width="15.591" height="13.228" viewBox="0 0 15.591 13.228">
  <path id="Path_103443" data-name="Path 103443" d="M4.273,1.073A3.126,3.126,0,0,1,6.481,2L7,2.518l.76.763.76-.762.425-.427,0,0,.005-.005a3.272,3.272,0,0,1,4.29-.355,3.229,3.229,0,0,1,.578,4.508,3.172,3.172,0,0,1-.271.31L8.1,12.013a.489.489,0,0,1-.69,0L2.072,6.658a3.494,3.494,0,0,1-1-2.488,3.123,3.123,0,0,1,3.108-3.1h.09ZM4.273,0h-.09A4.188,4.188,0,0,0,0,4.161,4.543,4.543,0,0,0,1.308,7.412l5.346,5.358a1.553,1.553,0,0,0,1.1.458h.008a1.555,1.555,0,0,0,1.1-.458L14.313,7.3a4.281,4.281,0,0,0,.362-.414,4.572,4.572,0,0,0,.858-2.106A4.171,4.171,0,0,0,13.893.87a4.344,4.344,0,0,0-5.708.463L7.76,1.76l-.519-.521A4.177,4.177,0,0,0,4.273,0" transform="translate(0)" fill="#1a1a1a"/>
</svg> */}
                <svg id="Group_41217" data-name="Group 41217" xmlns="http://www.w3.org/2000/svg" width="17.519" height="15.387" viewBox="0 0 17.519 15.387">
                  <path id="Path_8614" data-name="Path 8614" d="M-4169-1583.41h3.021l2.311,10.064h9.237l2.289-8.064h-13.049" transform="translate(4169.001 1583.91)" fill="none" stroke="#333" stroke-width="1" />
                  <circle id="Ellipse_3030" data-name="Ellipse 3030" cx="1.315" cy="1.315" r="1.315" transform="translate(5.358 12.257)" fill="none" stroke="#333" stroke-width="1" />
                  <circle id="Ellipse_3031" data-name="Ellipse 3031" cx="1.315" cy="1.315" r="1.315" transform="translate(11.929 12.257)" fill="none" stroke="#333" stroke-width="1" />
                </svg>
                <p className='cartitem-save'>2</p>
              </h3>
            </div>
            <div className=''>
              <div className='toggleswitch-flex'>
                <p className='product-filterswitch'>
                {
                    this.props.value.state.show_sort_on_listing_page &&
                    <svg xmlns="http://www.w3.org/2000/svg" width="14.424" height="13.72" viewBox="0 0 14.424 13.72">
                    <g id="Group_42758" data-name="Group 42758" transform="translate(1.304 0.712)">
                      <path id="Path_106643" data-name="Path 106643" d="M6.345,5.2l-2.5-2.5-2.5,2.5" transform="translate(-2.225 -2.566)" fill="none" stroke="#6f6f6f" stroke-miterlimit="10" stroke-width="1.2"/>
                      <path id="Path_119096" data-name="Path 119096" d="M0,1V13" transform="translate(1.62 -0.864)" fill="none" stroke="#6f6f6f" stroke-width="1.2"/>
                      <line id="Line_437" data-name="Line 437" x2="5" transform="translate(6.12 6.636)" fill="none" stroke="#6f6f6f" stroke-miterlimit="10" stroke-width="1.2"/>
                      <line id="Line_438" data-name="Line 438" x2="4" transform="translate(6.12 10.636)" fill="none" stroke="#6f6f6f" stroke-miterlimit="10" stroke-width="1.2"/>
                      <line id="Line_439" data-name="Line 439" x2="7" transform="translate(6.12 2.636)" fill="none" stroke="#6f6f6f" stroke-miterlimit="10" stroke-width="1.2"/>
                      <path id="Path_106644" data-name="Path 106644" d="M6.345,2.7l-2.5,2.5-2.5-2.5" transform="translate(-2.225 6.957)" fill="none" stroke="#6f6f6f" stroke-miterlimit="10" stroke-width="1.2"/>
                    </g>
                  </svg> }
                  {
                    this.props.value.state.show_filter_on_listing_page &&
                  <div style={{position: "relative"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12.2" viewBox="0 0 14 12.2">
  <g id="Group_42759" data-name="Group 42759" transform="translate(0.445 1.001)">
    <line id="Line_440" data-name="Line 440" x2="6" transform="translate(7.555 1.099)" fill="none" stroke="#6f6f6f" stroke-miterlimit="10" stroke-width="1.2"/>
    <line id="Line_441" data-name="Line 441" x2="3" transform="translate(-0.445 1.099)" fill="none" stroke="#6f6f6f" stroke-miterlimit="10" stroke-width="1.2"/>
    <circle id="Ellipse_8849" data-name="Ellipse 8849" cx="1.5" cy="1.5" r="1.5" transform="translate(2.555 -0.401)" fill="none" stroke="#6f6f6f" stroke-miterlimit="10" stroke-width="1.2"/>
    <line id="Line_443" data-name="Line 443" x2="2" transform="translate(11.555 9.099)" fill="none" stroke="#6f6f6f" stroke-miterlimit="10" stroke-width="1.2"/>
    <line id="Line_445" data-name="Line 445" x2="6" transform="translate(-0.445 9.099)" fill="none" stroke="#6f6f6f" stroke-miterlimit="10" stroke-width="1.2"/>
    <circle id="Ellipse_8850" data-name="Ellipse 8850" cx="1.5" cy="1.5" r="1.5" transform="translate(5.555 7.599)" fill="none" stroke="#6f6f6f" stroke-miterlimit="10" stroke-width="1.2"/>
  </g>
</svg>
                    <div className='red-dot-filter'></div>
                  </div> }
                </p>
              {/* {
                this.props.value.state.product_view_style &&
                <p className='product-alignmenttoggle'>
                 
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13">
                    <g id="Group_41223" data-name="Group 41223" opacity="0.5">
                      <g id="Rectangle_4591" data-name="Rectangle 4591" fill="#fff" stroke="#7a7a7a" stroke-width="1">
                        <rect width="6" height="6" rx="1" stroke="none" />
                        <rect x="0.5" y="0.5" width="5" height="5" rx="0.5" fill="none" />
                      </g>
                      <g id="Rectangle_4596" data-name="Rectangle 4596" transform="translate(0 7)" fill="#fff" stroke="#7a7a7a" stroke-width="1">
                        <rect width="6" height="6" rx="1" stroke="none" />
                        <rect x="0.5" y="0.5" width="5" height="5" rx="0.5" fill="none" />
                      </g>
                      <path id="Path_9130" data-name="Path 9130" d="M954.017,177.785h8" transform="translate(-946.017 -176.285)" fill="#fff" stroke="#7a7a7a" stroke-width="1" />
                      <path id="Path_9133" data-name="Path 9133" d="M954.017,177.785h8" transform="translate(-946.017 -169.285)" fill="#fff" stroke="#7a7a7a" stroke-width="1" />
                      <path id="Path_9131" data-name="Path 9131" d="M954.017,177.785h5" transform="translate(-946.017 -173.285)" fill="#fff" stroke="#7a7a7a" stroke-width="1" />
                      <path id="Path_9132" data-name="Path 9132" d="M954.017,177.785h5" transform="translate(-946.017 -166.285)" fill="#fff" stroke="#7a7a7a" stroke-width="1" />
                    </g>
                  </svg> 
           
                  <svg id="Group_41224" data-name="Group 41224" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                    <g id="Rectangle_4592" data-name="Rectangle 4592" fill="#fff" stroke="#7a7a7a" stroke-width="1">
                      <rect width="6" height="6" rx="1" stroke="none" />
                      <rect x="0.5" y="0.5" width="5" height="5" rx="0.5" fill="none" />
                    </g>
                    <g id="Rectangle_4595" data-name="Rectangle 4595" transform="translate(0 7)" fill="#fff" stroke="#7a7a7a" stroke-width="1">
                      <rect width="6" height="6" rx="1" stroke="none" />
                      <rect x="0.5" y="0.5" width="5" height="5" rx="0.5" fill="none" />
                    </g>
                    <g id="Rectangle_4593" data-name="Rectangle 4593" transform="translate(7)" fill="#fff" stroke="#7a7a7a" stroke-width="1">
                      <rect width="6" height="6" rx="1" stroke="none" />
                      <rect x="0.5" y="0.5" width="5" height="5" rx="0.5" fill="none" />
                    </g>
                    <g id="Rectangle_4594" data-name="Rectangle 4594" transform="translate(7 7)" fill="#fff" stroke="#7a7a7a" stroke-width="1">
                      <rect width="6" height="6" rx="1" stroke="none" />
                      <rect x="0.5" y="0.5" width="5" height="5" rx="0.5" fill="none" />
                    </g>
                  </svg> 
                </p> } */}
<p className='product-alignmenttoggle'>
                 
                 {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13">
                   <g id="Group_41223" data-name="Group 41223" opacity="0.5">
                     <g id="Rectangle_4591" data-name="Rectangle 4591" fill="#fff" stroke="#7a7a7a" stroke-width="1">
                       <rect width="6" height="6" rx="1" stroke="none" />
                       <rect x="0.5" y="0.5" width="5" height="5" rx="0.5" fill="none" />
                     </g>
                     <g id="Rectangle_4596" data-name="Rectangle 4596" transform="translate(0 7)" fill="#fff" stroke="#7a7a7a" stroke-width="1">
                       <rect width="6" height="6" rx="1" stroke="none" />
                       <rect x="0.5" y="0.5" width="5" height="5" rx="0.5" fill="none" />
                     </g>
                     <path id="Path_9130" data-name="Path 9130" d="M954.017,177.785h8" transform="translate(-946.017 -176.285)" fill="#fff" stroke="#7a7a7a" stroke-width="1" />
                     <path id="Path_9133" data-name="Path 9133" d="M954.017,177.785h8" transform="translate(-946.017 -169.285)" fill="#fff" stroke="#7a7a7a" stroke-width="1" />
                     <path id="Path_9131" data-name="Path 9131" d="M954.017,177.785h5" transform="translate(-946.017 -173.285)" fill="#fff" stroke="#7a7a7a" stroke-width="1" />
                     <path id="Path_9132" data-name="Path 9132" d="M954.017,177.785h5" transform="translate(-946.017 -166.285)" fill="#fff" stroke="#7a7a7a" stroke-width="1" />
                   </g>
                 </svg>  */}
                 <p>245</p>
          
                 <svg xmlns="http://www.w3.org/2000/svg" width="15.44" height="15" viewBox="0 0 15.44 15">
  <g id="Group_42332" data-name="Group 42332" transform="translate(0.57 0.864)">
    <g id="Rectangle_31756" data-name="Rectangle 31756" transform="translate(0.43 0.136)" fill="none" stroke="#6f6f6f" stroke-miterlimit="10" stroke-width="1">
      <rect width="5" height="5" rx="1" stroke="none"/>
      <rect x="-0.5" y="-0.5" width="6" height="6" rx="1.5" fill="none"/>
    </g>
    <g id="Rectangle_31757" data-name="Rectangle 31757" transform="translate(0.43 8.136)" fill="none" stroke="#6f6f6f" stroke-miterlimit="10" stroke-width="1">
      <rect width="5" height="5" rx="1" stroke="none"/>
      <rect x="-0.5" y="-0.5" width="6" height="6" rx="1.5" fill="none"/>
    </g>
    <path id="Path_118930" data-name="Path 118930" d="M0,0H6.44" transform="translate(8.43 0.636)" fill="none" stroke="#6f6f6f" stroke-width="1"/>
    <path id="Path_118931" data-name="Path 118931" d="M0,0H3.22" transform="translate(8.43 3.636)" fill="none" stroke="#6f6f6f" stroke-width="1"/>
    <path id="Path_118932" data-name="Path 118932" d="M0,0H6.44" transform="translate(8.43 8.636)" fill="none" stroke="#6f6f6f" stroke-width="1"/>
    <path id="Path_118933" data-name="Path 118933" d="M0,0H3.22" transform="translate(8.43 11.636)" fill="none" stroke="#6f6f6f" stroke-width="1"/>
  </g>
</svg>
               </p>
              </div>
               {/* List view */}
                   {
                    ( !this.props.value.state.product_view_style || !this.props.value.state.temp_show_grid_view  )&&
              
              <>
              <div className='product-preview-list-page'>
                <ul 
                style={{
                 borderRadius:
                 (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                 this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                }}
                >
                  <li className='product-preview-img' 
                  style={{borderRadius:this.props.value.state.image_shape_on_listing_page==="soft_corner"?"10px":"",
                  height: calculateHeight(100,this.props.value.state.image_ratio_on_listing_page),}}
                  >
                    <h3 className='d-flex product-img-head'>
                     {
                      this.props.value.state.enable_wishlist_on_listing_page &&
                      <p className="product-wishlist">
                        <svg id="Layer_54" data-name="Layer 54" xmlns="http://www.w3.org/2000/svg" width="9.681" height="8.21" viewBox="0 0 9.681 8.21">
  <path id="Path_8950" data-name="Path 8950" d="M5.876,11.5a.968.968,0,0,1-.688-.284L1.863,7.89a2.815,2.815,0,0,1-.814-2.018A2.6,2.6,0,0,1,3.677,3.29a2.6,2.6,0,0,1,1.876.772l.323.323.265-.265h0a2.709,2.709,0,0,1,3.551-.287,2.663,2.663,0,0,1,.261,3.99l-3.39,3.393A.968.968,0,0,1,5.876,11.5Z" transform="translate(-1.049 -3.29)" fill="#cfcfcf"/>
</svg>
                      </p> }
                      {/* {this.props.value.state.enable_discount_badge &&

                      
                      <p className={`${this.props.value.state.show_discount_percentage ?" product-percentage-with-text " : "product-percentage"}`} style={{
                        background:this.props.value.state.discount_badge_bg_colour,
                        color:this.props.value.state.discount_badge_text_colour,
                      }}>{this.props.value.state.show_discount_percentage?"12":""}
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11">
                          <text id="_" data-name="%" transform="translate(4 8)" fill={this.props.value.state.discount_badge_text_colour} font-size="8" font-family="Poppins-Medium, Poppins" font-weight="500" letter-spacing="0.05em"><tspan x="-3.176" y="0">%</tspan></text>
                        </svg>
                      </p> } */}

                    {this.props.value.state.enable_discount_badge &&
                    <>
                    {
                      !this.props.value.state.show_discount_percentage ?

                                          <div className='product-percentage-div'>
                    <svg 
                    
                    xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18">
                      <path id="Path_119102" data-name="Path 119102" d="M0,0V18l3.25-2.057L6.5,18l3.25-2.057L13,18V0Z" transform="translate(0 0)" 
                      fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>%</p>
                    </div>
                    :
                    <div className='product-percentage-text-div'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                      <path id="Path_119101" data-name="Path 119101" d="M122.691,0V18l3.334-2.057L129.357,18l3.333-2.057L136.024,18l3.333-2.057L142.691,18V0Z" transform="translate(-122.691 0)" 
                        fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>12%</p>
                    </div>
                      }
                    </>
                    }
                    </h3>
                    <h3 className='product-prev-image'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
  <g id="Group_42041" data-name="Group 42041" transform="translate(0.261 0.218)">
    <path id="Polygon_58" data-name="Polygon 58" d="M5.5,0,11,7H0Z" transform="translate(-0.261 8.782)" fill="#cfcfcf" opacity="0.5"/>
    <path id="Polygon_59" data-name="Polygon 59" d="M7.5,0,15,9H0Z" transform="translate(6.739 6.782)" fill="#cfcfcf"/>
    <circle id="Ellipse_5321" data-name="Ellipse 5321" cx="2.5" cy="2.5" r="2.5" transform="translate(5.739 -0.218)" fill="#cfcfcf"/>
  </g>
</svg>
                    </h3>
                  </li>
                  <li className='product-detailslist grid-product-detailslist'>
                    <ul className='product-preview-prdetails'
                    style={{
                      borderRadius:
                      (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                      this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                     }}
                    >
                      <li>
                        <p className='product-preview-name'>Woman yellow solid round neck longline t-shirt</p>
                      </li>
                      <li className='product-preview-rating'>
                      <svg id="iconfinder_star_216411_2_" data-name="iconfinder_star_216411 (2)" xmlns="http://www.w3.org/2000/svg" width="11.806" height="11.27" viewBox="0 0 11.806 11.27">
  <g id="Group_115" data-name="Group 115" transform="translate(0)">
    <path id="Path_56" data-name="Path 56" d="M7.687,7.659l-3.724.413a.356.356,0,0,0-.3.243.35.35,0,0,0,.1.369c1.107,1.011,2.77,2.525,2.77,2.525s-.457,2.2-.759,3.671a.357.357,0,0,0,.139.358.351.351,0,0,0,.38.02C7.6,14.516,9.551,13.4,9.551,13.4l3.255,1.857a.352.352,0,0,0,.52-.378c-.3-1.47-.757-3.672-.757-3.672l2.77-2.523a.357.357,0,0,0,.1-.371.353.353,0,0,0-.3-.241c-1.49-.167-3.725-.415-3.725-.415L9.873,4.242a.357.357,0,0,0-.322-.208.352.352,0,0,0-.32.208C8.613,5.609,7.687,7.659,7.687,7.659Z" transform="translate(-3.649 -4.034)" fill="#ffca2a"/>
  </g>
</svg>
<p>4.2<span style={{marginLeft:"4px"}}>(53)</span></p>

                      </li>
                      <li className='d-flex product-prev-pricing'>
                        
                        <h3>$59</h3>
                        <h2>$67</h2>
                       
                      </li>
                      <div className='grid-add-tocart'>
                        <div>
                      {
                      this.props.value.state.show_out_of_stock && <p>10 in Stock</p> }
                       </div>
                      {this.props.value.state.show_add_to_cart_on_listing_page &&                       
                      <li className='product-save-cart'>
                        {/* <svg id="Group_41218" data-name="Group 41218" xmlns="http://www.w3.org/2000/svg" width="17.18" height="14.9" viewBox="0 0 17.18 14.9">
                          <path id="Path_8614" data-name="Path 8614" d="M-4169-1583.91h2.541l1.944,8.885h7.769l1.925-6.439H-4165.8" transform="translate(4169.001 1585.789)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <circle id="Ellipse_3030" data-name="Ellipse 3030" cx="1.106" cy="1.106" r="1.106" transform="translate(4.506 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <circle id="Ellipse_3031" data-name="Ellipse 3031" cx="1.106" cy="1.106" r="1.106" transform="translate(10.033 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <ellipse id="Ellipse_8319" data-name="Ellipse 8319" cx="4.161" cy="4.161" rx="4.161" ry="4.161" transform="translate(8.859 0)" fill="#cfcfcf" />
                          <path id="Union_334" data-name="Union 334" d="M2.148,2.148h0v0h0v0Z" transform="translate(10.957 2.027)" fill="none" stroke="#fff" stroke-width="1" />
                        </svg> */}
                        +
                      </li> }

                      </div>
                    </ul>

                  </li>
                </ul>

              </div>
              <div className='product-preview-list-page'>
                <ul
                                style={{
                                  borderRadius:
                                  (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                                  this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                                 }}
                >
                  <li className='product-preview-img' 
                  style={{borderRadius:this.props.value.state.image_shape_on_listing_page==="soft_corner"?"10px":"",
                  height: calculateHeight(100,this.props.value.state.image_ratio_on_listing_page),}}
                  >
                    <h3 className='d-flex product-img-head'>
                     {
                      this.props.value.state.enable_wishlist_on_listing_page &&
                      <p className="product-wishlist">
                        <svg id="Layer_54" data-name="Layer 54" xmlns="http://www.w3.org/2000/svg" width="9.681" height="8.21" viewBox="0 0 9.681 8.21">
  <path id="Path_8950" data-name="Path 8950" d="M5.876,11.5a.968.968,0,0,1-.688-.284L1.863,7.89a2.815,2.815,0,0,1-.814-2.018A2.6,2.6,0,0,1,3.677,3.29a2.6,2.6,0,0,1,1.876.772l.323.323.265-.265h0a2.709,2.709,0,0,1,3.551-.287,2.663,2.663,0,0,1,.261,3.99l-3.39,3.393A.968.968,0,0,1,5.876,11.5Z" transform="translate(-1.049 -3.29)" fill="#cfcfcf"/>
</svg>
                      </p> }
                      {/* {this.props.value.state.enable_discount_badge &&

                      
                      <p className={`${this.props.value.state.show_discount_percentage ?" product-percentage-with-text " : "product-percentage"}`} style={{
                        background:this.props.value.state.discount_badge_bg_colour,
                        color:this.props.value.state.discount_badge_text_colour,
                      }}>{this.props.value.state.show_discount_percentage?"12":""}
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11">
                          <text id="_" data-name="%" transform="translate(4 8)" fill={this.props.value.state.discount_badge_text_colour} font-size="8" font-family="Poppins-Medium, Poppins" font-weight="500" letter-spacing="0.05em"><tspan x="-3.176" y="0">%</tspan></text>
                        </svg>
                      </p> } */}
 {this.props.value.state.enable_discount_badge &&
                    <>
                    {
                      !this.props.value.state.show_discount_percentage ?

                                          <div className='product-percentage-div'>
                    <svg 
                    
                    xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18">
                      <path id="Path_119102" data-name="Path 119102" d="M0,0V18l3.25-2.057L6.5,18l3.25-2.057L13,18V0Z" transform="translate(0 0)" 
                      fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>%</p>
                    </div>
                    :
                    <div className='product-percentage-text-div'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                      <path id="Path_119101" data-name="Path 119101" d="M122.691,0V18l3.334-2.057L129.357,18l3.333-2.057L136.024,18l3.333-2.057L142.691,18V0Z" transform="translate(-122.691 0)" 
                        fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>12%</p>
                    </div>
                      }
                    </>
                    }
                    </h3>
                    <h3 className='product-prev-image'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
  <g id="Group_42041" data-name="Group 42041" transform="translate(0.261 0.218)">
    <path id="Polygon_58" data-name="Polygon 58" d="M5.5,0,11,7H0Z" transform="translate(-0.261 8.782)" fill="#cfcfcf" opacity="0.5"/>
    <path id="Polygon_59" data-name="Polygon 59" d="M7.5,0,15,9H0Z" transform="translate(6.739 6.782)" fill="#cfcfcf"/>
    <circle id="Ellipse_5321" data-name="Ellipse 5321" cx="2.5" cy="2.5" r="2.5" transform="translate(5.739 -0.218)" fill="#cfcfcf"/>
  </g>
</svg>
                    </h3>
                  </li>
                  <li className='product-detailslist grid-product-detailslist'>
                    <ul className='product-preview-prdetails'
                       style={{
                        borderRadius:
                        (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                        this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                       }}
                    >
                      <li>
                        <p className='product-preview-name'>Woman yellow solid round neck
longline t-shirt</p>
                      </li>
                      <li className='product-preview-rating'>
                      <svg id="iconfinder_star_216411_2_" data-name="iconfinder_star_216411 (2)" xmlns="http://www.w3.org/2000/svg" width="11.806" height="11.27" viewBox="0 0 11.806 11.27">
  <g id="Group_115" data-name="Group 115" transform="translate(0)">
    <path id="Path_56" data-name="Path 56" d="M7.687,7.659l-3.724.413a.356.356,0,0,0-.3.243.35.35,0,0,0,.1.369c1.107,1.011,2.77,2.525,2.77,2.525s-.457,2.2-.759,3.671a.357.357,0,0,0,.139.358.351.351,0,0,0,.38.02C7.6,14.516,9.551,13.4,9.551,13.4l3.255,1.857a.352.352,0,0,0,.52-.378c-.3-1.47-.757-3.672-.757-3.672l2.77-2.523a.357.357,0,0,0,.1-.371.353.353,0,0,0-.3-.241c-1.49-.167-3.725-.415-3.725-.415L9.873,4.242a.357.357,0,0,0-.322-.208.352.352,0,0,0-.32.208C8.613,5.609,7.687,7.659,7.687,7.659Z" transform="translate(-3.649 -4.034)" fill="#ffca2a"/>
  </g>
</svg>
<p>4.2<span style={{marginLeft:"4px"}}>(53)</span></p>

                      </li>
                      <li className='d-flex product-prev-pricing'>
                        
                        <h3>$59</h3>
                        <h2>$67</h2>
                       
                      </li>
                      <div className='grid-add-tocart'>
                        <div>
                      {
                      this.props.value.state.show_out_of_stock && <p>10 in Stock</p> }
                       </div>
                      {this.props.value.state.show_add_to_cart_on_listing_page &&                       
                      <li className='product-save-cart'>
                        {/* <svg id="Group_41218" data-name="Group 41218" xmlns="http://www.w3.org/2000/svg" width="17.18" height="14.9" viewBox="0 0 17.18 14.9">
                          <path id="Path_8614" data-name="Path 8614" d="M-4169-1583.91h2.541l1.944,8.885h7.769l1.925-6.439H-4165.8" transform="translate(4169.001 1585.789)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <circle id="Ellipse_3030" data-name="Ellipse 3030" cx="1.106" cy="1.106" r="1.106" transform="translate(4.506 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <circle id="Ellipse_3031" data-name="Ellipse 3031" cx="1.106" cy="1.106" r="1.106" transform="translate(10.033 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <ellipse id="Ellipse_8319" data-name="Ellipse 8319" cx="4.161" cy="4.161" rx="4.161" ry="4.161" transform="translate(8.859 0)" fill="#cfcfcf" />
                          <path id="Union_334" data-name="Union 334" d="M2.148,2.148h0v0h0v0Z" transform="translate(10.957 2.027)" fill="none" stroke="#fff" stroke-width="1" />
                        </svg> */}
                        +
                      </li> }

                      </div>
                    </ul>

                  </li>
                </ul>

              </div>
              <div className='product-preview-list-page'>
                <ul
                                style={{
                                  borderRadius:
                                  (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                                  this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                                 }}
                >
                  <li className='product-preview-img' 
                  style={{borderRadius:this.props.value.state.image_shape_on_listing_page==="soft_corner"?"10px":"",
                  height: calculateHeight(100,this.props.value.state.image_ratio_on_listing_page),}}>
                    <h3 className='d-flex product-img-head'>
                    {
                      this.props.value.state.enable_wishlist_on_listing_page &&
                      <p className="product-wishlist">
                       <svg id="Layer_54" data-name="Layer 54" xmlns="http://www.w3.org/2000/svg" width="9.681" height="8.21" viewBox="0 0 9.681 8.21">
  <path id="Path_8950" data-name="Path 8950" d="M5.876,11.5a.968.968,0,0,1-.688-.284L1.863,7.89a2.815,2.815,0,0,1-.814-2.018A2.6,2.6,0,0,1,3.677,3.29a2.6,2.6,0,0,1,1.876.772l.323.323.265-.265h0a2.709,2.709,0,0,1,3.551-.287,2.663,2.663,0,0,1,.261,3.99l-3.39,3.393A.968.968,0,0,1,5.876,11.5Z" transform="translate(-1.049 -3.29)" fill="#cfcfcf"/>
</svg>
                      </p> }
                      {/* {this.props.value.state.enable_discount_badge &&

                      
<p className={`${this.props.value.state.show_discount_percentage ?" product-percentage-with-text " : "product-percentage"}`} style={{
                        background:this.props.value.state.discount_badge_bg_colour,
                        color:this.props.value.state.discount_badge_text_colour,
                      }}>{this.props.value.state.show_discount_percentage?"12":""}
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11">
    <text id="_" data-name="%" transform="translate(4 8)" fill={this.props.value.state.discount_badge_text_colour} font-size="8" font-family="Poppins-Medium, Poppins" font-weight="500" letter-spacing="0.05em"><tspan x="-3.176" y="0">%</tspan></text>
  </svg>
</p> } */}
 {this.props.value.state.enable_discount_badge &&
                    <>
                    {
                      !this.props.value.state.show_discount_percentage ?

                                          <div className='product-percentage-div'>
                    <svg 
                    
                    xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18">
                      <path id="Path_119102" data-name="Path 119102" d="M0,0V18l3.25-2.057L6.5,18l3.25-2.057L13,18V0Z" transform="translate(0 0)" 
                      fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>%</p>
                    </div>
                    :
                    <div className='product-percentage-text-div'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                      <path id="Path_119101" data-name="Path 119101" d="M122.691,0V18l3.334-2.057L129.357,18l3.333-2.057L136.024,18l3.333-2.057L142.691,18V0Z" transform="translate(-122.691 0)" 
                        fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>12%</p>
                    </div>
                      }
                    </>
                    }
                    </h3>
                    <h3 className='product-prev-image'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
  <g id="Group_42041" data-name="Group 42041" transform="translate(0.261 0.218)">
    <path id="Polygon_58" data-name="Polygon 58" d="M5.5,0,11,7H0Z" transform="translate(-0.261 8.782)" fill="#cfcfcf" opacity="0.5"/>
    <path id="Polygon_59" data-name="Polygon 59" d="M7.5,0,15,9H0Z" transform="translate(6.739 6.782)" fill="#cfcfcf"/>
    <circle id="Ellipse_5321" data-name="Ellipse 5321" cx="2.5" cy="2.5" r="2.5" transform="translate(5.739 -0.218)" fill="#cfcfcf"/>
  </g>
</svg>
                    </h3>
                  </li>
                  <li className='product-detailslist grid-product-detailslist'>
                    <ul className='product-preview-prdetails'
                       style={{
                        borderRadius:
                        (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                        this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                       }}
                    >
                      <li>
                        <p className='product-preview-name'>Woman yellow solid round neck
longline t-shirt</p>
                      </li>
                      <li className='product-preview-rating'>
                      <svg id="iconfinder_star_216411_2_" data-name="iconfinder_star_216411 (2)" xmlns="http://www.w3.org/2000/svg" width="11.806" height="11.27" viewBox="0 0 11.806 11.27">
  <g id="Group_115" data-name="Group 115" transform="translate(0)">
    <path id="Path_56" data-name="Path 56" d="M7.687,7.659l-3.724.413a.356.356,0,0,0-.3.243.35.35,0,0,0,.1.369c1.107,1.011,2.77,2.525,2.77,2.525s-.457,2.2-.759,3.671a.357.357,0,0,0,.139.358.351.351,0,0,0,.38.02C7.6,14.516,9.551,13.4,9.551,13.4l3.255,1.857a.352.352,0,0,0,.52-.378c-.3-1.47-.757-3.672-.757-3.672l2.77-2.523a.357.357,0,0,0,.1-.371.353.353,0,0,0-.3-.241c-1.49-.167-3.725-.415-3.725-.415L9.873,4.242a.357.357,0,0,0-.322-.208.352.352,0,0,0-.32.208C8.613,5.609,7.687,7.659,7.687,7.659Z" transform="translate(-3.649 -4.034)" fill="#ffca2a"/>
  </g>
</svg>
<p>4.2<span style={{marginLeft:"4px"}}>(53)</span></p>
                      </li>
                      <li className='d-flex product-prev-pricing'>
                       
                        <h3>$59</h3>
                        <h2>$67</h2>
                       
                      </li>
                      <div className='grid-add-tocart'>
                        <div>
                      {
                      this.props.value.state.show_out_of_stock &&  <p>In Stock</p> }
                      </div>
                      {this.props.value.state.show_add_to_cart_on_listing_page && 
                      <li className='product-save-cart'>
                        {/* <svg id="Group_41218" data-name="Group 41218" xmlns="http://www.w3.org/2000/svg" width="17.18" height="14.9" viewBox="0 0 17.18 14.9">
                          <path id="Path_8614" data-name="Path 8614" d="M-4169-1583.91h2.541l1.944,8.885h7.769l1.925-6.439H-4165.8" transform="translate(4169.001 1585.789)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <circle id="Ellipse_3030" data-name="Ellipse 3030" cx="1.106" cy="1.106" r="1.106" transform="translate(4.506 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <circle id="Ellipse_3031" data-name="Ellipse 3031" cx="1.106" cy="1.106" r="1.106" transform="translate(10.033 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <ellipse id="Ellipse_8319" data-name="Ellipse 8319" cx="4.161" cy="4.161" rx="4.161" ry="4.161" transform="translate(8.859 0)" fill="#cfcfcf" />
                          <path id="Union_334" data-name="Union 334" d="M2.148,2.148h0v0h0v0Z" transform="translate(10.957 2.027)" fill="none" stroke="#fff" stroke-width="1" />
                        </svg> */}
                        +
                      </li>}
                      </div>
                    </ul>

                  </li>
                </ul>

              </div>
              <div className='product-preview-list-page'>
                <ul
                                style={{
                                  borderRadius:
                                  (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                                  this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                                 }}
                >
                  <li className='product-preview-img' style={{borderRadius:this.props.value.state.image_shape_on_listing_page==="soft_corner"?"10px":"",
                  height: calculateHeight(100,this.props.value.state.image_ratio_on_listing_page),}}>
                    <h3 className='d-flex product-img-head'>
                    {
                      this.props.value.state.enable_wishlist_on_listing_page &&
                      <p className="product-wishlist">
                       <svg id="Layer_54" data-name="Layer 54" xmlns="http://www.w3.org/2000/svg" width="9.681" height="8.21" viewBox="0 0 9.681 8.21">
  <path id="Path_8950" data-name="Path 8950" d="M5.876,11.5a.968.968,0,0,1-.688-.284L1.863,7.89a2.815,2.815,0,0,1-.814-2.018A2.6,2.6,0,0,1,3.677,3.29a2.6,2.6,0,0,1,1.876.772l.323.323.265-.265h0a2.709,2.709,0,0,1,3.551-.287,2.663,2.663,0,0,1,.261,3.99l-3.39,3.393A.968.968,0,0,1,5.876,11.5Z" transform="translate(-1.049 -3.29)" fill="#cfcfcf"/>
</svg>
                      </p> }
                      {/* {this.props.value.state.enable_discount_badge &&

                      
<p className={`${this.props.value.state.show_discount_percentage ?" product-percentage-with-text " : "product-percentage"}`} style={{
                        background:this.props.value.state.discount_badge_bg_colour,
                        color:this.props.value.state.discount_badge_text_colour,
                      }}>{this.props.value.state.show_discount_percentage?"12":""}
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11">
    <text id="_" data-name="%" transform="translate(4 8)" fill={this.props.value.state.discount_badge_text_colour} font-size="8" font-family="Poppins-Medium, Poppins" font-weight="500" letter-spacing="0.05em"><tspan x="-3.176" y="0">%</tspan></text>
  </svg>
</p> } */}
 {this.props.value.state.enable_discount_badge &&
                    <>
                    {
                      !this.props.value.state.show_discount_percentage ?

                                          <div className='product-percentage-div'>
                    <svg 
                    
                    xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18">
                      <path id="Path_119102" data-name="Path 119102" d="M0,0V18l3.25-2.057L6.5,18l3.25-2.057L13,18V0Z" transform="translate(0 0)" 
                      fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>%</p>
                    </div>
                    :
                    <div className='product-percentage-text-div'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                      <path id="Path_119101" data-name="Path 119101" d="M122.691,0V18l3.334-2.057L129.357,18l3.333-2.057L136.024,18l3.333-2.057L142.691,18V0Z" transform="translate(-122.691 0)" 
                        fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>12%</p>
                    </div>
                      }
                    </>
                    }
                    </h3>
                    <h3 className='product-prev-image'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
  <g id="Group_42041" data-name="Group 42041" transform="translate(0.261 0.218)">
    <path id="Polygon_58" data-name="Polygon 58" d="M5.5,0,11,7H0Z" transform="translate(-0.261 8.782)" fill="#cfcfcf" opacity="0.5"/>
    <path id="Polygon_59" data-name="Polygon 59" d="M7.5,0,15,9H0Z" transform="translate(6.739 6.782)" fill="#cfcfcf"/>
    <circle id="Ellipse_5321" data-name="Ellipse 5321" cx="2.5" cy="2.5" r="2.5" transform="translate(5.739 -0.218)" fill="#cfcfcf"/>
  </g>
</svg>
                    </h3>
                  </li>
                  <li className='product-detailslist grid-product-detailslist'>
                    <ul className='product-preview-prdetails'
                       style={{
                        borderRadius:
                        (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                        this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                       }}
                    >
                      <li>
                        <p className='product-preview-name'>Woman yellow solid round neck
longline t-shirt</p>
                      </li>
                      <li className='product-preview-rating'>
                      <svg id="iconfinder_star_216411_2_" data-name="iconfinder_star_216411 (2)" xmlns="http://www.w3.org/2000/svg" width="11.806" height="11.27" viewBox="0 0 11.806 11.27">
  <g id="Group_115" data-name="Group 115" transform="translate(0)">
    <path id="Path_56" data-name="Path 56" d="M7.687,7.659l-3.724.413a.356.356,0,0,0-.3.243.35.35,0,0,0,.1.369c1.107,1.011,2.77,2.525,2.77,2.525s-.457,2.2-.759,3.671a.357.357,0,0,0,.139.358.351.351,0,0,0,.38.02C7.6,14.516,9.551,13.4,9.551,13.4l3.255,1.857a.352.352,0,0,0,.52-.378c-.3-1.47-.757-3.672-.757-3.672l2.77-2.523a.357.357,0,0,0,.1-.371.353.353,0,0,0-.3-.241c-1.49-.167-3.725-.415-3.725-.415L9.873,4.242a.357.357,0,0,0-.322-.208.352.352,0,0,0-.32.208C8.613,5.609,7.687,7.659,7.687,7.659Z" transform="translate(-3.649 -4.034)" fill="#ffca2a"/>
  </g>
</svg>
<p>4.2<span style={{marginLeft:"4px"}}>(53)</span></p>
                      </li>
                      <li className='d-flex product-prev-pricing'>
                       
                        <h3>$59</h3>
                        <h2>$67</h2>
                        
                      </li>
                      <div className='grid-add-tocart'>
                      <div>
                      {
                      this.props.value.state.show_out_of_stock &&<p style={{color: "#FF0000"}}>Out of stock</p>}
                      </div>
                      {this.props.value.state.show_add_to_cart_on_listing_page && 
                      <li className='product-save-cart'>
                        {/* <svg id="Group_41218" data-name="Group 41218" xmlns="http://www.w3.org/2000/svg" width="17.18" height="14.9" viewBox="0 0 17.18 14.9">
                          <path id="Path_8614" data-name="Path 8614" d="M-4169-1583.91h2.541l1.944,8.885h7.769l1.925-6.439H-4165.8" transform="translate(4169.001 1585.789)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <circle id="Ellipse_3030" data-name="Ellipse 3030" cx="1.106" cy="1.106" r="1.106" transform="translate(4.506 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <circle id="Ellipse_3031" data-name="Ellipse 3031" cx="1.106" cy="1.106" r="1.106" transform="translate(10.033 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <ellipse id="Ellipse_8319" data-name="Ellipse 8319" cx="4.161" cy="4.161" rx="4.161" ry="4.161" transform="translate(8.859 0)" fill="#cfcfcf" />
                          <path id="Union_334" data-name="Union 334" d="M2.148,2.148h0v0h0v0Z" transform="translate(10.957 2.027)" fill="none" stroke="#fff" stroke-width="1" />
                        </svg> */}
                        +
                      </li> }
                      </div>
                    </ul>

                  </li>
                </ul>

              </div>
              <div className='product-preview-list-page'>
                <ul
                                style={{
                                  borderRadius:
                                  (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                                  this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                                 }}
                >
                  <li className='product-preview-img' style={{borderRadius:this.props.value.state.image_shape_on_listing_page==="soft_corner"?"10px":"",
                  height: calculateHeight(100,this.props.value.state.image_ratio_on_listing_page),}}>
                    <h3 className='d-flex product-img-head'>
                    {
                      this.props.value.state.enable_wishlist_on_listing_page &&
                      <p className="product-wishlist">
                      <svg id="Layer_54" data-name="Layer 54" xmlns="http://www.w3.org/2000/svg" width="9.681" height="8.21" viewBox="0 0 9.681 8.21">
  <path id="Path_8950" data-name="Path 8950" d="M5.876,11.5a.968.968,0,0,1-.688-.284L1.863,7.89a2.815,2.815,0,0,1-.814-2.018A2.6,2.6,0,0,1,3.677,3.29a2.6,2.6,0,0,1,1.876.772l.323.323.265-.265h0a2.709,2.709,0,0,1,3.551-.287,2.663,2.663,0,0,1,.261,3.99l-3.39,3.393A.968.968,0,0,1,5.876,11.5Z" transform="translate(-1.049 -3.29)" fill="#cfcfcf"/>
</svg>
                      </p> }
                      {/* {this.props.value.state.enable_discount_badge &&

                      
<p className={`${this.props.value.state.show_discount_percentage ?" product-percentage-with-text " : "product-percentage"}`} style={{
                        background:this.props.value.state.discount_badge_bg_colour,
                        color:this.props.value.state.discount_badge_text_colour,
                      }}>{this.props.value.state.show_discount_percentage?"12":""}
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11">
    <text id="_" data-name="%" transform="translate(4 8)" fill={this.props.value.state.discount_badge_text_colour} font-size="8" font-family="Poppins-Medium, Poppins" font-weight="500" letter-spacing="0.05em"><tspan x="-3.176" y="0">%</tspan></text>
  </svg>
</p> } */}
 {this.props.value.state.enable_discount_badge &&
                    <>
                    {
                      !this.props.value.state.show_discount_percentage ?

                                          <div className='product-percentage-div'>
                    <svg 
                    
                    xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18">
                      <path id="Path_119102" data-name="Path 119102" d="M0,0V18l3.25-2.057L6.5,18l3.25-2.057L13,18V0Z" transform="translate(0 0)" 
                      fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>%</p>
                    </div>
                    :
                    <div className='product-percentage-text-div'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                      <path id="Path_119101" data-name="Path 119101" d="M122.691,0V18l3.334-2.057L129.357,18l3.333-2.057L136.024,18l3.333-2.057L142.691,18V0Z" transform="translate(-122.691 0)" 
                        fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>12%</p>
                    </div>
                      }
                    </>
                    }
                    </h3>
                    <h3 className='product-prev-image'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
  <g id="Group_42041" data-name="Group 42041" transform="translate(0.261 0.218)">
    <path id="Polygon_58" data-name="Polygon 58" d="M5.5,0,11,7H0Z" transform="translate(-0.261 8.782)" fill="#cfcfcf" opacity="0.5"/>
    <path id="Polygon_59" data-name="Polygon 59" d="M7.5,0,15,9H0Z" transform="translate(6.739 6.782)" fill="#cfcfcf"/>
    <circle id="Ellipse_5321" data-name="Ellipse 5321" cx="2.5" cy="2.5" r="2.5" transform="translate(5.739 -0.218)" fill="#cfcfcf"/>
  </g>
</svg>
                    </h3>
                  </li>
                  <li className='product-detailslist grid-product-detailslist'>
                    <ul className='product-preview-prdetails'
                       style={{
                        borderRadius:
                        (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                        this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                       }}
                    >
                      <li>
                        <p className='product-preview-name'>Woman yellow solid round neck
longline t-shirt</p>
                      </li>
                      <li className='product-preview-rating'>
                      <svg id="iconfinder_star_216411_2_" data-name="iconfinder_star_216411 (2)" xmlns="http://www.w3.org/2000/svg" width="11.806" height="11.27" viewBox="0 0 11.806 11.27">
  <g id="Group_115" data-name="Group 115" transform="translate(0)">
    <path id="Path_56" data-name="Path 56" d="M7.687,7.659l-3.724.413a.356.356,0,0,0-.3.243.35.35,0,0,0,.1.369c1.107,1.011,2.77,2.525,2.77,2.525s-.457,2.2-.759,3.671a.357.357,0,0,0,.139.358.351.351,0,0,0,.38.02C7.6,14.516,9.551,13.4,9.551,13.4l3.255,1.857a.352.352,0,0,0,.52-.378c-.3-1.47-.757-3.672-.757-3.672l2.77-2.523a.357.357,0,0,0,.1-.371.353.353,0,0,0-.3-.241c-1.49-.167-3.725-.415-3.725-.415L9.873,4.242a.357.357,0,0,0-.322-.208.352.352,0,0,0-.32.208C8.613,5.609,7.687,7.659,7.687,7.659Z" transform="translate(-3.649 -4.034)" fill="#ffca2a"/>
  </g>
</svg>
<p>4.2<span style={{marginLeft:"4px"}}>(53)</span></p>
                      </li>
                      <li className='d-flex product-prev-pricing'>
                        
                        <h3>$59</h3>
                        <h2>$67</h2>
                       
                      </li>
                      <div className='grid-add-tocart'>
                      <div>
                      {
                      this.props.value.state.show_out_of_stock && <p>Only 2 left</p> }
                      </div>
                      {this.props.value.state.show_add_to_cart_on_listing_page && 
                      <li className='product-save-cart'>
                        {/* <svg id="Group_41218" data-name="Group 41218" xmlns="http://www.w3.org/2000/svg" width="17.18" height="14.9" viewBox="0 0 17.18 14.9">
                          <path id="Path_8614" data-name="Path 8614" d="M-4169-1583.91h2.541l1.944,8.885h7.769l1.925-6.439H-4165.8" transform="translate(4169.001 1585.789)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <circle id="Ellipse_3030" data-name="Ellipse 3030" cx="1.106" cy="1.106" r="1.106" transform="translate(4.506 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <circle id="Ellipse_3031" data-name="Ellipse 3031" cx="1.106" cy="1.106" r="1.106" transform="translate(10.033 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <ellipse id="Ellipse_8319" data-name="Ellipse 8319" cx="4.161" cy="4.161" rx="4.161" ry="4.161" transform="translate(8.859 0)" fill="#cfcfcf" />
                          <path id="Union_334" data-name="Union 334" d="M2.148,2.148h0v0h0v0Z" transform="translate(10.957 2.027)" fill="none" stroke="#fff" stroke-width="1" />
                        </svg> */}
                        +
                      </li>}
                      </div>
                    </ul>

                  </li>
                </ul>

              </div>
              <div className='product-preview-list-page'>
                <ul
                                style={{
                                  borderRadius:
                                  (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                                  this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                                 }}
                >
                  <li className='product-preview-img' style={{borderRadius:this.props.value.state.image_shape_on_listing_page==="soft_corner"?"10px":"",
                  height: calculateHeight(100,this.props.value.state.image_ratio_on_listing_page),}}>
                    <h3 className='d-flex product-img-head'>
                    {
                      this.props.value.state.enable_wishlist_on_listing_page &&
                      <p className="product-wishlist">
                        <svg id="Layer_54" data-name="Layer 54" xmlns="http://www.w3.org/2000/svg" width="9.681" height="8.21" viewBox="0 0 9.681 8.21">
  <path id="Path_8950" data-name="Path 8950" d="M5.876,11.5a.968.968,0,0,1-.688-.284L1.863,7.89a2.815,2.815,0,0,1-.814-2.018A2.6,2.6,0,0,1,3.677,3.29a2.6,2.6,0,0,1,1.876.772l.323.323.265-.265h0a2.709,2.709,0,0,1,3.551-.287,2.663,2.663,0,0,1,.261,3.99l-3.39,3.393A.968.968,0,0,1,5.876,11.5Z" transform="translate(-1.049 -3.29)" fill="#cfcfcf"/>
</svg>
                      </p> }
                      {/* {this.props.value.state.enable_discount_badge &&

                      
<p className={`${this.props.value.state.show_discount_percentage ?" product-percentage-with-text " : "product-percentage"}`} style={{
                        background:this.props.value.state.discount_badge_bg_colour,
                        color:this.props.value.state.discount_badge_text_colour,
                      }}>{this.props.value.state.show_discount_percentage?"12":""}
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11">
    <text id="_" data-name="%" transform="translate(4 8)" fill={this.props.value.state.discount_badge_text_colour} font-size="8" font-family="Poppins-Medium, Poppins" font-weight="500" letter-spacing="0.05em"><tspan x="-3.176" y="0">%</tspan></text>
  </svg>
</p> } */}
 {this.props.value.state.enable_discount_badge &&
                    <>
                    {
                      !this.props.value.state.show_discount_percentage ?

                                          <div className='product-percentage-div'>
                    <svg 
                    
                    xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18">
                      <path id="Path_119102" data-name="Path 119102" d="M0,0V18l3.25-2.057L6.5,18l3.25-2.057L13,18V0Z" transform="translate(0 0)" 
                      fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>%</p>
                    </div>
                    :
                    <div className='product-percentage-text-div'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                      <path id="Path_119101" data-name="Path 119101" d="M122.691,0V18l3.334-2.057L129.357,18l3.333-2.057L136.024,18l3.333-2.057L142.691,18V0Z" transform="translate(-122.691 0)" 
                        fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>12%</p>
                    </div>
                      }
                    </>
                    }
                    </h3>
                    <h3 className='product-prev-image'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
  <g id="Group_42041" data-name="Group 42041" transform="translate(0.261 0.218)">
    <path id="Polygon_58" data-name="Polygon 58" d="M5.5,0,11,7H0Z" transform="translate(-0.261 8.782)" fill="#cfcfcf" opacity="0.5"/>
    <path id="Polygon_59" data-name="Polygon 59" d="M7.5,0,15,9H0Z" transform="translate(6.739 6.782)" fill="#cfcfcf"/>
    <circle id="Ellipse_5321" data-name="Ellipse 5321" cx="2.5" cy="2.5" r="2.5" transform="translate(5.739 -0.218)" fill="#cfcfcf"/>
  </g>
</svg>
                    </h3>
                  </li>
                  <li className='product-detailslist grid-product-detailslist'>
                    <ul className='product-preview-prdetails'
                     style={{
                      borderRadius:
                      (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                      this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                     }}
                    >
                      <li>
                        <p className='product-preview-name'>Woman yellow solid round neck
longline t-shirt</p>
                      </li>
                      <li className='product-preview-rating'>
                            <svg id="iconfinder_star_216411_2_" data-name="iconfinder_star_216411 (2)" xmlns="http://www.w3.org/2000/svg" width="11.806" height="11.27" viewBox="0 0 11.806 11.27">
  <g id="Group_115" data-name="Group 115" transform="translate(0)">
    <path id="Path_56" data-name="Path 56" d="M7.687,7.659l-3.724.413a.356.356,0,0,0-.3.243.35.35,0,0,0,.1.369c1.107,1.011,2.77,2.525,2.77,2.525s-.457,2.2-.759,3.671a.357.357,0,0,0,.139.358.351.351,0,0,0,.38.02C7.6,14.516,9.551,13.4,9.551,13.4l3.255,1.857a.352.352,0,0,0,.52-.378c-.3-1.47-.757-3.672-.757-3.672l2.77-2.523a.357.357,0,0,0,.1-.371.353.353,0,0,0-.3-.241c-1.49-.167-3.725-.415-3.725-.415L9.873,4.242a.357.357,0,0,0-.322-.208.352.352,0,0,0-.32.208C8.613,5.609,7.687,7.659,7.687,7.659Z" transform="translate(-3.649 -4.034)" fill="#ffca2a"/>
  </g>
</svg>
<p>4.2<span style={{marginLeft:"4px"}}>(53)</span></p>
                      </li>
                      <li className='d-flex product-prev-pricing'>
                     
                        <h3>$59</h3>
                        <h2>$67</h2>
                        {/* {
                      this.props.value.state.show_out_of_stock &&<p>10 in Stock</p>} */}
                      </li>
                      <div className='grid-add-tocart'>
                      <div>
                      {
                      this.props.value.state.show_out_of_stock && <p style={{color: "#FF4D5F"}}>Out of stock</p> }
                      </div>
                      {this.props.value.state.show_add_to_cart_on_listing_page && 
                      <li className='product-save-cart'>
                        {/* <svg id="Group_41218" data-name="Group 41218" xmlns="http://www.w3.org/2000/svg" width="17.18" height="14.9" viewBox="0 0 17.18 14.9">
                          <path id="Path_8614" data-name="Path 8614" d="M-4169-1583.91h2.541l1.944,8.885h7.769l1.925-6.439H-4165.8" transform="translate(4169.001 1585.789)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <circle id="Ellipse_3030" data-name="Ellipse 3030" cx="1.106" cy="1.106" r="1.106" transform="translate(4.506 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <circle id="Ellipse_3031" data-name="Ellipse 3031" cx="1.106" cy="1.106" r="1.106" transform="translate(10.033 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <ellipse id="Ellipse_8319" data-name="Ellipse 8319" cx="4.161" cy="4.161" rx="4.161" ry="4.161" transform="translate(8.859 0)" fill="#cfcfcf" />
                          <path id="Union_334" data-name="Union 334" d="M2.148,2.148h0v0h0v0Z" transform="translate(10.957 2.027)" fill="none" stroke="#fff" stroke-width="1" />
                        </svg> */}
                        +
                      </li>}
                      </div>
                    </ul>

                  </li>
                </ul>

              </div>
              <div className='product-preview-list-page'>
                <ul
                                style={{
                                  borderRadius:
                                  (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                                  this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                                 }}
                >
                  <li className='product-preview-img' style={{borderRadius:this.props.value.state.image_shape_on_listing_page==="soft_corner"?"10px":"",
                  height: calculateHeight(100,this.props.value.state.image_ratio_on_listing_page),}}>
                    <h3 className='d-flex product-img-head'>
                    {
                      this.props.value.state.enable_wishlist_on_listing_page &&
                      <p className="product-wishlist">
                       <svg id="Layer_54" data-name="Layer 54" xmlns="http://www.w3.org/2000/svg" width="9.681" height="8.21" viewBox="0 0 9.681 8.21">
  <path id="Path_8950" data-name="Path 8950" d="M5.876,11.5a.968.968,0,0,1-.688-.284L1.863,7.89a2.815,2.815,0,0,1-.814-2.018A2.6,2.6,0,0,1,3.677,3.29a2.6,2.6,0,0,1,1.876.772l.323.323.265-.265h0a2.709,2.709,0,0,1,3.551-.287,2.663,2.663,0,0,1,.261,3.99l-3.39,3.393A.968.968,0,0,1,5.876,11.5Z" transform="translate(-1.049 -3.29)" fill="#cfcfcf"/>
</svg>
                      </p> }
                      {/* {this.props.value.state.enable_discount_badge &&

                      
<p className={`${this.props.value.state.show_discount_percentage ?" product-percentage-with-text " : "product-percentage"}`} style={{
                        background:this.props.value.state.discount_badge_bg_colour,
                        color:this.props.value.state.discount_badge_text_colour,
                      }}>{this.props.value.state.show_discount_percentage?"12":""}
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11">
    <text id="_" data-name="%" transform="translate(4 8)" fill={this.props.value.state.discount_badge_text_colour} font-size="8" font-family="Poppins-Medium, Poppins" font-weight="500" letter-spacing="0.05em"><tspan x="-3.176" y="0">%</tspan></text>
  </svg>
</p> } */}
 {this.props.value.state.enable_discount_badge &&
                    <>
                    {
                      !this.props.value.state.show_discount_percentage ?

                                          <div className='product-percentage-div'>
                    <svg 
                    
                    xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18">
                      <path id="Path_119102" data-name="Path 119102" d="M0,0V18l3.25-2.057L6.5,18l3.25-2.057L13,18V0Z" transform="translate(0 0)" 
                      fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>%</p>
                    </div>
                    :
                    <div className='product-percentage-text-div'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                      <path id="Path_119101" data-name="Path 119101" d="M122.691,0V18l3.334-2.057L129.357,18l3.333-2.057L136.024,18l3.333-2.057L142.691,18V0Z" transform="translate(-122.691 0)" 
                        fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>12%</p>
                    </div>
                      }
                    </>
                    }
                    </h3>
                    <h3 className='product-prev-image'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
  <g id="Group_42041" data-name="Group 42041" transform="translate(0.261 0.218)">
    <path id="Polygon_58" data-name="Polygon 58" d="M5.5,0,11,7H0Z" transform="translate(-0.261 8.782)" fill="#cfcfcf" opacity="0.5"/>
    <path id="Polygon_59" data-name="Polygon 59" d="M7.5,0,15,9H0Z" transform="translate(6.739 6.782)" fill="#cfcfcf"/>
    <circle id="Ellipse_5321" data-name="Ellipse 5321" cx="2.5" cy="2.5" r="2.5" transform="translate(5.739 -0.218)" fill="#cfcfcf"/>
  </g>
</svg>
                    </h3>
                  </li>
                  <li className='product-detailslist grid-product-detailslist'>
                    <ul className='product-preview-prdetails'
                     style={{
                      borderRadius:
                      (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                      this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                     }}
                    >
                      <li>
                        <p className='product-preview-name'>Woman yellow solid round neck
longline t-shirt</p>
                      </li>
                      <li className='product-preview-rating'>
                      <svg id="iconfinder_star_216411_2_" data-name="iconfinder_star_216411 (2)" xmlns="http://www.w3.org/2000/svg" width="11.806" height="11.27" viewBox="0 0 11.806 11.27">
  <g id="Group_115" data-name="Group 115" transform="translate(0)">
    <path id="Path_56" data-name="Path 56" d="M7.687,7.659l-3.724.413a.356.356,0,0,0-.3.243.35.35,0,0,0,.1.369c1.107,1.011,2.77,2.525,2.77,2.525s-.457,2.2-.759,3.671a.357.357,0,0,0,.139.358.351.351,0,0,0,.38.02C7.6,14.516,9.551,13.4,9.551,13.4l3.255,1.857a.352.352,0,0,0,.52-.378c-.3-1.47-.757-3.672-.757-3.672l2.77-2.523a.357.357,0,0,0,.1-.371.353.353,0,0,0-.3-.241c-1.49-.167-3.725-.415-3.725-.415L9.873,4.242a.357.357,0,0,0-.322-.208.352.352,0,0,0-.32.208C8.613,5.609,7.687,7.659,7.687,7.659Z" transform="translate(-3.649 -4.034)" fill="#ffca2a"/>
  </g>
</svg>
<p>4.2<span style={{marginLeft:"4px"}}>(53)</span></p>
                      </li>
                      <li className='d-flex product-prev-pricing'>
                       
                        <h3>$59</h3>
                        <h2>$67</h2>
                        {/* {
                      this.props.value.state.show_out_of_stock &&  <p>In Stock</p> } */}
                      </li>
                      <div className='grid-add-tocart'>
                      <div>
                      {
                      this.props.value.state.show_out_of_stock &&  <p>In Stock</p> }
                      </div>
                      {this.props.value.state.show_add_to_cart_on_listing_page && 
                      <li className='product-save-cart'>
                        {/* <svg id="Group_41218" data-name="Group 41218" xmlns="http://www.w3.org/2000/svg" width="17.18" height="14.9" viewBox="0 0 17.18 14.9">
                          <path id="Path_8614" data-name="Path 8614" d="M-4169-1583.91h2.541l1.944,8.885h7.769l1.925-6.439H-4165.8" transform="translate(4169.001 1585.789)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <circle id="Ellipse_3030" data-name="Ellipse 3030" cx="1.106" cy="1.106" r="1.106" transform="translate(4.506 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <circle id="Ellipse_3031" data-name="Ellipse 3031" cx="1.106" cy="1.106" r="1.106" transform="translate(10.033 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <ellipse id="Ellipse_8319" data-name="Ellipse 8319" cx="4.161" cy="4.161" rx="4.161" ry="4.161" transform="translate(8.859 0)" fill="#cfcfcf" />
                          <path id="Union_334" data-name="Union 334" d="M2.148,2.148h0v0h0v0Z" transform="translate(10.957 2.027)" fill="none" stroke="#fff" stroke-width="1" />
                        </svg> */}
                        +
                      </li>}
                      </div>
                    </ul>

                  </li>
                </ul>

              </div>
              </> }
            </div>
              {/* Grid view */}
            {
                   ( this.props.value.state.product_view_style && this.props.value.state.temp_show_grid_view ) && <div className=''>
             
              <div className='product-flex-view'>
                <div className='product-flex-preview-list-page'>
                  <ul
                   style={{
                    borderRadius:
                    (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                    this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                   }}
                  >
                    <li className='product-preview-img' 
                    style={{borderRadius:this.props.value.state.image_shape_on_listing_page==="soft_corner"?"10px":"",
                    height: calculateHeight(136,this.props.value.state.image_ratio_on_listing_page), }}
                    >
                      <h3 className='d-flex product-img-head'>
                        {
                      this.props.value.state.show_out_of_stock &&  <p className='stocklist-flex-page'>10 in Stock</p> }
                        {/* {this.props.value.state.enable_discount_badge &&

                      
<p className={`${this.props.value.state.show_discount_percentage ?" product-percentage-with-text " : "product-percentage"}`} style={{
                        background:this.props.value.state.discount_badge_bg_colour,
                        color:this.props.value.state.discount_badge_text_colour,
                      }}>{this.props.value.state.show_discount_percentage?"12":""}
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11">
    <text id="_" data-name="%" transform="translate(4 8)" fill={this.props.value.state.discount_badge_text_colour} font-size="8" font-family="Poppins-Medium, Poppins" font-weight="500" letter-spacing="0.05em"><tspan x="-3.176" y="0">%</tspan></text>
  </svg>
</p> } */}
 {this.props.value.state.enable_discount_badge &&
                    <>
                    {
                      !this.props.value.state.show_discount_percentage ?

                                          <div className='product-percentage-div'>
                    <svg 
                    
                    xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18">
                      <path id="Path_119102" data-name="Path 119102" d="M0,0V18l3.25-2.057L6.5,18l3.25-2.057L13,18V0Z" transform="translate(0 0)" 
                      fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>%</p>
                    </div>
                    :
                    <div className='product-percentage-text-div'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                      <path id="Path_119101" data-name="Path 119101" d="M122.691,0V18l3.334-2.057L129.357,18l3.333-2.057L136.024,18l3.333-2.057L142.691,18V0Z" transform="translate(-122.691 0)" 
                        fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>12%</p>
                    </div>
                      }
                    </>
                    }


                      </h3>
                      <h3 className='product-prev-image'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
  <g id="Group_42041" data-name="Group 42041" transform="translate(0.261 0.218)">
    <path id="Polygon_58" data-name="Polygon 58" d="M5.5,0,11,7H0Z" transform="translate(-0.261 8.782)" fill="#cfcfcf" opacity="0.5"/>
    <path id="Polygon_59" data-name="Polygon 59" d="M7.5,0,15,9H0Z" transform="translate(6.739 6.782)" fill="#cfcfcf"/>
    <circle id="Ellipse_5321" data-name="Ellipse 5321" cx="2.5" cy="2.5" r="2.5" transform="translate(5.739 -0.218)" fill="#cfcfcf"/>
  </g>
</svg>
                      </h3>
                      {/* {
                      this.props.value.state.show_out_of_stock &&  <p className='stocklist-flex-page'>10 in Stock</p> } */}
                       <p className='product-multi-img'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="5" viewBox="0 0 26 5">
  <g id="Group_42024" data-name="Group 42024" transform="translate(2.008 0.883)">
    <g id="Group_39702" data-name="Group 39702" transform="translate(0)">
      <g id="Ellipse_8337" data-name="Ellipse 8337" transform="translate(-1.008 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8340" data-name="Ellipse 8340" transform="translate(12.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8338" data-name="Ellipse 8338" transform="translate(5.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8339" data-name="Ellipse 8339" transform="translate(19.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
    </g>
  </g>
</svg>
</p>
                       {
                      this.props.value.state.enable_wishlist_on_listing_page &&
                      <p className="product-wishlist">
                        <svg id="Layer_54" data-name="Layer 54" xmlns="http://www.w3.org/2000/svg" width="9.681" height="8.21" viewBox="0 0 9.681 8.21">
  <path id="Path_8950" data-name="Path 8950" d="M5.876,11.5a.968.968,0,0,1-.688-.284L1.863,7.89a2.815,2.815,0,0,1-.814-2.018A2.6,2.6,0,0,1,3.677,3.29a2.6,2.6,0,0,1,1.876.772l.323.323.265-.265h0a2.709,2.709,0,0,1,3.551-.287,2.663,2.663,0,0,1,.261,3.99l-3.39,3.393A.968.968,0,0,1,5.876,11.5Z" transform="translate(-1.049 -3.29)" fill="#cfcfcf"/>
</svg>
                      </p> }
                    </li>
                    <li className='product-detailslist'>
                      <ul className='product-preview-prdetails'
                       style={{
                        borderRadius:
                        (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                        this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                       }}
                      >
                        <li>
                          <p className='product-preview-name'>Woman yellow solid round neck
longline t-shirt</p>
                        </li>
                        <li className='product-preview-rating'>
                        <svg id="iconfinder_star_216411_2_" data-name="iconfinder_star_216411 (2)" xmlns="http://www.w3.org/2000/svg" width="11.806" height="11.27" viewBox="0 0 11.806 11.27">
  <g id="Group_115" data-name="Group 115" transform="translate(0)">
    <path id="Path_56" data-name="Path 56" d="M7.687,7.659l-3.724.413a.356.356,0,0,0-.3.243.35.35,0,0,0,.1.369c1.107,1.011,2.77,2.525,2.77,2.525s-.457,2.2-.759,3.671a.357.357,0,0,0,.139.358.351.351,0,0,0,.38.02C7.6,14.516,9.551,13.4,9.551,13.4l3.255,1.857a.352.352,0,0,0,.52-.378c-.3-1.47-.757-3.672-.757-3.672l2.77-2.523a.357.357,0,0,0,.1-.371.353.353,0,0,0-.3-.241c-1.49-.167-3.725-.415-3.725-.415L9.873,4.242a.357.357,0,0,0-.322-.208.352.352,0,0,0-.32.208C8.613,5.609,7.687,7.659,7.687,7.659Z" transform="translate(-3.649 -4.034)" fill="#ffca2a"/>
  </g>
</svg>
<p>4.2<span style={{marginLeft:"4px"}}>(53)</span></p>
                        </li>
                        <li className='d-flex justify-content-between product-prev-pricing'>
                          <div>
                          <h3>$59</h3>
                          <h2>$67</h2>
                          
                          </div>
                          {this.props.value.state.show_add_to_cart_on_listing_page && 
                        <div className='product-save-cart'>
                          {/* <svg id="Group_41218" data-name="Group 41218" xmlns="http://www.w3.org/2000/svg" width="17.18" height="14.9" viewBox="0 0 17.18 14.9">
                            <path id="Path_8614" data-name="Path 8614" d="M-4169-1583.91h2.541l1.944,8.885h7.769l1.925-6.439H-4165.8" transform="translate(4169.001 1585.789)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <circle id="Ellipse_3030" data-name="Ellipse 3030" cx="1.106" cy="1.106" r="1.106" transform="translate(4.506 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <circle id="Ellipse_3031" data-name="Ellipse 3031" cx="1.106" cy="1.106" r="1.106" transform="translate(10.033 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <ellipse id="Ellipse_8319" data-name="Ellipse 8319" cx="4.161" cy="4.161" rx="4.161" ry="4.161" transform="translate(8.859 0)" fill="#cfcfcf" />
                            <path id="Union_334" data-name="Union 334" d="M2.148,2.148h0v0h0v0Z" transform="translate(10.957 2.027)" fill="none" stroke="#fff" stroke-width="1" />
                          </svg> */}
                          +
                        </div>}
                        </li>
                      </ul>

                    </li>
                  </ul>

                </div>
                <div className='product-flex-preview-list-page'>
                  <ul
                   style={{
                    borderRadius:
                    (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                    this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                   }}
                  >
                    <li className='product-preview-img' 
                    style={{borderRadius:this.props.value.state.image_shape_on_listing_page==="soft_corner"?"10px":"",
                    height: calculateHeight(136,this.props.value.state.image_ratio_on_listing_page), }}
                    >
                      <h3 className='d-flex product-img-head'>
                         {
                      this.props.value.state.show_out_of_stock &&  <p className='stocklist-flex-page'>10 in Stock</p> }
                   {/* {this.props.value.state.enable_discount_badge &&

                      
<p className={`${this.props.value.state.show_discount_percentage ?" product-percentage-with-text " : "product-percentage"}`} style={{
                        background:this.props.value.state.discount_badge_bg_colour,
                        color:this.props.value.state.discount_badge_text_colour,
                      }}>{this.props.value.state.show_discount_percentage?"12":""}
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11">
    <text id="_" data-name="%" transform="translate(4 8)" fill={this.props.value.state.discount_badge_text_colour} font-size="8" font-family="Poppins-Medium, Poppins" font-weight="500" letter-spacing="0.05em"><tspan x="-3.176" y="0">%</tspan></text>
  </svg>
</p> } */}
 {this.props.value.state.enable_discount_badge &&
                    <>
                    {
                      !this.props.value.state.show_discount_percentage ?

                                          <div className='product-percentage-div'>
                    <svg 
                    
                    xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18">
                      <path id="Path_119102" data-name="Path 119102" d="M0,0V18l3.25-2.057L6.5,18l3.25-2.057L13,18V0Z" transform="translate(0 0)" 
                      fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>%</p>
                    </div>
                    :
                    <div className='product-percentage-text-div'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                      <path id="Path_119101" data-name="Path 119101" d="M122.691,0V18l3.334-2.057L129.357,18l3.333-2.057L136.024,18l3.333-2.057L142.691,18V0Z" transform="translate(-122.691 0)" 
                        fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>12%</p>
                    </div>
                      }
                    </>
                    }
                      </h3>
                      <h3 className='product-prev-image'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
  <g id="Group_42041" data-name="Group 42041" transform="translate(0.261 0.218)">
    <path id="Polygon_58" data-name="Polygon 58" d="M5.5,0,11,7H0Z" transform="translate(-0.261 8.782)" fill="#cfcfcf" opacity="0.5"/>
    <path id="Polygon_59" data-name="Polygon 59" d="M7.5,0,15,9H0Z" transform="translate(6.739 6.782)" fill="#cfcfcf"/>
    <circle id="Ellipse_5321" data-name="Ellipse 5321" cx="2.5" cy="2.5" r="2.5" transform="translate(5.739 -0.218)" fill="#cfcfcf"/>
  </g>
</svg>
                      </h3>
                      {/* {
                      this.props.value.state.show_out_of_stock &&  <p className='stocklist-flex-page'>10 in Stock</p> } */}
                       <p className='product-multi-img'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="5" viewBox="0 0 26 5">
  <g id="Group_42024" data-name="Group 42024" transform="translate(2.008 0.883)">
    <g id="Group_39702" data-name="Group 39702" transform="translate(0)">
      <g id="Ellipse_8337" data-name="Ellipse 8337" transform="translate(-1.008 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8340" data-name="Ellipse 8340" transform="translate(12.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8338" data-name="Ellipse 8338" transform="translate(5.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8339" data-name="Ellipse 8339" transform="translate(19.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
    </g>
  </g>
</svg>
</p>
                       {
                      this.props.value.state.enable_wishlist_on_listing_page &&
                      <p className="product-wishlist">
                       <svg id="Layer_54" data-name="Layer 54" xmlns="http://www.w3.org/2000/svg" width="9.681" height="8.21" viewBox="0 0 9.681 8.21">
  <path id="Path_8950" data-name="Path 8950" d="M5.876,11.5a.968.968,0,0,1-.688-.284L1.863,7.89a2.815,2.815,0,0,1-.814-2.018A2.6,2.6,0,0,1,3.677,3.29a2.6,2.6,0,0,1,1.876.772l.323.323.265-.265h0a2.709,2.709,0,0,1,3.551-.287,2.663,2.663,0,0,1,.261,3.99l-3.39,3.393A.968.968,0,0,1,5.876,11.5Z" transform="translate(-1.049 -3.29)" fill="#cfcfcf"/>
</svg>
                      </p> }
                    </li>
                    <li className='product-detailslist'>
                      <ul className='product-preview-prdetails'
                       style={{
                        borderRadius:
                        (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                        this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                       }}
                      >
                        <li>
                          <p className='product-preview-name'>Woman yellow solid round neck
longline t-shirt</p>
                        </li>
                        <li className='product-preview-rating'>
                        <svg id="iconfinder_star_216411_2_" data-name="iconfinder_star_216411 (2)" xmlns="http://www.w3.org/2000/svg" width="11.806" height="11.27" viewBox="0 0 11.806 11.27">
  <g id="Group_115" data-name="Group 115" transform="translate(0)">
    <path id="Path_56" data-name="Path 56" d="M7.687,7.659l-3.724.413a.356.356,0,0,0-.3.243.35.35,0,0,0,.1.369c1.107,1.011,2.77,2.525,2.77,2.525s-.457,2.2-.759,3.671a.357.357,0,0,0,.139.358.351.351,0,0,0,.38.02C7.6,14.516,9.551,13.4,9.551,13.4l3.255,1.857a.352.352,0,0,0,.52-.378c-.3-1.47-.757-3.672-.757-3.672l2.77-2.523a.357.357,0,0,0,.1-.371.353.353,0,0,0-.3-.241c-1.49-.167-3.725-.415-3.725-.415L9.873,4.242a.357.357,0,0,0-.322-.208.352.352,0,0,0-.32.208C8.613,5.609,7.687,7.659,7.687,7.659Z" transform="translate(-3.649 -4.034)" fill="#ffca2a"/>
  </g>
</svg>
<p>4.2<span style={{marginLeft:"4px"}}>(53)</span></p>
                        </li>
                        <li className='d-flex justify-content-between product-prev-pricing'>
                          <div>
                          <h3>$59</h3>
                          <h2>$67</h2>
                          
                          </div>
                          {this.props.value.state.show_add_to_cart_on_listing_page && 
                        <div className='product-save-cart'>
                          {/* <svg id="Group_41218" data-name="Group 41218" xmlns="http://www.w3.org/2000/svg" width="17.18" height="14.9" viewBox="0 0 17.18 14.9">
                            <path id="Path_8614" data-name="Path 8614" d="M-4169-1583.91h2.541l1.944,8.885h7.769l1.925-6.439H-4165.8" transform="translate(4169.001 1585.789)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <circle id="Ellipse_3030" data-name="Ellipse 3030" cx="1.106" cy="1.106" r="1.106" transform="translate(4.506 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <circle id="Ellipse_3031" data-name="Ellipse 3031" cx="1.106" cy="1.106" r="1.106" transform="translate(10.033 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <ellipse id="Ellipse_8319" data-name="Ellipse 8319" cx="4.161" cy="4.161" rx="4.161" ry="4.161" transform="translate(8.859 0)" fill="#cfcfcf" />
                            <path id="Union_334" data-name="Union 334" d="M2.148,2.148h0v0h0v0Z" transform="translate(10.957 2.027)" fill="none" stroke="#fff" stroke-width="1" />
                          </svg> */}
                          +
                        </div>}
                        </li>
                      </ul>

                    </li>
                  </ul>

                </div>
                <div className='product-flex-preview-list-page'>
                  <ul
                   style={{
                    borderRadius:
                    (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                    this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                   }}
                  >
                    <li className='product-preview-img' 
                    style={{borderRadius:this.props.value.state.image_shape_on_listing_page==="soft_corner"?"10px":"",
                    height: calculateHeight(140,this.props.value.state.image_ratio_on_listing_page), }}
                    >
                      <h3 className='d-flex product-img-head'>
                      
                         {
                      this.props.value.state.show_out_of_stock &&  <p className='stocklist-flex-page'>10 in Stock</p> }
                      {/* {this.props.value.state.enable_discount_badge &&

                      
<p className={`${this.props.value.state.show_discount_percentage ?" product-percentage-with-text " : "product-percentage"}`} style={{
                        background:this.props.value.state.discount_badge_bg_colour,
                        color:this.props.value.state.discount_badge_text_colour,
                      }}>{this.props.value.state.show_discount_percentage?"12":""}
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11">
    <text id="_" data-name="%" transform="translate(4 8)" fill={this.props.value.state.discount_badge_text_colour} font-size="8" font-family="Poppins-Medium, Poppins" font-weight="500" letter-spacing="0.05em"><tspan x="-3.176" y="0">%</tspan></text>
  </svg>
</p> } */}
 {this.props.value.state.enable_discount_badge &&
                    <>
                    {
                      !this.props.value.state.show_discount_percentage ?

                                          <div className='product-percentage-div'>
                    <svg 
                    
                    xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18">
                      <path id="Path_119102" data-name="Path 119102" d="M0,0V18l3.25-2.057L6.5,18l3.25-2.057L13,18V0Z" transform="translate(0 0)" 
                      fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>%</p>
                    </div>
                    :
                    <div className='product-percentage-text-div'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                      <path id="Path_119101" data-name="Path 119101" d="M122.691,0V18l3.334-2.057L129.357,18l3.333-2.057L136.024,18l3.333-2.057L142.691,18V0Z" transform="translate(-122.691 0)" 
                        fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>12%</p>
                    </div>
                      }
                    </>
                    }
                      </h3>
                      <h3 className='product-prev-image'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
  <g id="Group_42041" data-name="Group 42041" transform="translate(0.261 0.218)">
    <path id="Polygon_58" data-name="Polygon 58" d="M5.5,0,11,7H0Z" transform="translate(-0.261 8.782)" fill="#cfcfcf" opacity="0.5"/>
    <path id="Polygon_59" data-name="Polygon 59" d="M7.5,0,15,9H0Z" transform="translate(6.739 6.782)" fill="#cfcfcf"/>
    <circle id="Ellipse_5321" data-name="Ellipse 5321" cx="2.5" cy="2.5" r="2.5" transform="translate(5.739 -0.218)" fill="#cfcfcf"/>
  </g>
</svg>
                      </h3>
                      {/* {
                      this.props.value.state.show_out_of_stock &&  <p className='stocklist-flex-page'>10 in Stock</p> } */}
                       <p className='product-multi-img'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="5" viewBox="0 0 26 5">
  <g id="Group_42024" data-name="Group 42024" transform="translate(2.008 0.883)">
    <g id="Group_39702" data-name="Group 39702" transform="translate(0)">
      <g id="Ellipse_8337" data-name="Ellipse 8337" transform="translate(-1.008 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8340" data-name="Ellipse 8340" transform="translate(12.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8338" data-name="Ellipse 8338" transform="translate(5.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8339" data-name="Ellipse 8339" transform="translate(19.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
    </g>
  </g>
</svg>
</p>
                       {
                      this.props.value.state.enable_wishlist_on_listing_page &&
                      <p className="product-wishlist">
                       <svg id="Layer_54" data-name="Layer 54" xmlns="http://www.w3.org/2000/svg" width="9.681" height="8.21" viewBox="0 0 9.681 8.21">
  <path id="Path_8950" data-name="Path 8950" d="M5.876,11.5a.968.968,0,0,1-.688-.284L1.863,7.89a2.815,2.815,0,0,1-.814-2.018A2.6,2.6,0,0,1,3.677,3.29a2.6,2.6,0,0,1,1.876.772l.323.323.265-.265h0a2.709,2.709,0,0,1,3.551-.287,2.663,2.663,0,0,1,.261,3.99l-3.39,3.393A.968.968,0,0,1,5.876,11.5Z" transform="translate(-1.049 -3.29)" fill="#cfcfcf"/>
</svg>
                      </p> }
                    </li>
                    <li className='product-detailslist'>
                      <ul className='product-preview-prdetails'
                       style={{
                        borderRadius:
                        (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                        this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                       }}
                      >
                        <li>
                          <p className='product-preview-name'>Woman yellow solid round neck longline t-shirt</p>
                        </li>
                        <li className='product-preview-rating'>
                        <svg id="iconfinder_star_216411_2_" data-name="iconfinder_star_216411 (2)" xmlns="http://www.w3.org/2000/svg" width="11.806" height="11.27" viewBox="0 0 11.806 11.27">
  <g id="Group_115" data-name="Group 115" transform="translate(0)">
    <path id="Path_56" data-name="Path 56" d="M7.687,7.659l-3.724.413a.356.356,0,0,0-.3.243.35.35,0,0,0,.1.369c1.107,1.011,2.77,2.525,2.77,2.525s-.457,2.2-.759,3.671a.357.357,0,0,0,.139.358.351.351,0,0,0,.38.02C7.6,14.516,9.551,13.4,9.551,13.4l3.255,1.857a.352.352,0,0,0,.52-.378c-.3-1.47-.757-3.672-.757-3.672l2.77-2.523a.357.357,0,0,0,.1-.371.353.353,0,0,0-.3-.241c-1.49-.167-3.725-.415-3.725-.415L9.873,4.242a.357.357,0,0,0-.322-.208.352.352,0,0,0-.32.208C8.613,5.609,7.687,7.659,7.687,7.659Z" transform="translate(-3.649 -4.034)" fill="#ffca2a"/>
  </g>
</svg>
<p>4.2<span style={{marginLeft:"4px"}}>(53)</span></p>
                        </li>
                        <li className='d-flex justify-content-between product-prev-pricing'>
                          <div>
                          <h3>$59</h3>
                          <h2>$67</h2>
                          
                          </div>
                          {this.props.value.state.show_add_to_cart_on_listing_page && 
                        <div className='product-save-cart'>
                          {/* <svg id="Group_41218" data-name="Group 41218" xmlns="http://www.w3.org/2000/svg" width="17.18" height="14.9" viewBox="0 0 17.18 14.9">
                            <path id="Path_8614" data-name="Path 8614" d="M-4169-1583.91h2.541l1.944,8.885h7.769l1.925-6.439H-4165.8" transform="translate(4169.001 1585.789)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <circle id="Ellipse_3030" data-name="Ellipse 3030" cx="1.106" cy="1.106" r="1.106" transform="translate(4.506 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <circle id="Ellipse_3031" data-name="Ellipse 3031" cx="1.106" cy="1.106" r="1.106" transform="translate(10.033 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <ellipse id="Ellipse_8319" data-name="Ellipse 8319" cx="4.161" cy="4.161" rx="4.161" ry="4.161" transform="translate(8.859 0)" fill="#cfcfcf" />
                            <path id="Union_334" data-name="Union 334" d="M2.148,2.148h0v0h0v0Z" transform="translate(10.957 2.027)" fill="none" stroke="#fff" stroke-width="1" />
                          </svg> */}
                          +
                        </div>}
                        </li>
                      </ul>

                    </li>
                  </ul>

                </div>
                <div className='product-flex-preview-list-page'>
                  <ul
                   style={{
                    borderRadius:
                    (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                    this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                   }}
                  >
                    <li className='product-preview-img' 
                     style={{borderRadius:this.props.value.state.image_shape_on_listing_page==="soft_corner"?"10px":"",
                     height: calculateHeight(140,this.props.value.state.image_ratio_on_listing_page), }}
                    >
                      <h3 className='d-flex product-img-head'>
                         {
                      this.props.value.state.show_out_of_stock &&  <p className='stocklist-flex-page'>10 in Stock</p> }
                     {/* {this.props.value.state.enable_discount_badge &&

                      
<p className={`${this.props.value.state.show_discount_percentage ?" product-percentage-with-text " : "product-percentage"}`} style={{
                        background:this.props.value.state.discount_badge_bg_colour,
                        color:this.props.value.state.discount_badge_text_colour,
                      }}>{this.props.value.state.show_discount_percentage?"12":""}
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11">
    <text id="_" data-name="%" transform="translate(4 8)" fill={this.props.value.state.discount_badge_text_colour} font-size="8" font-family="Poppins-Medium, Poppins" font-weight="500" letter-spacing="0.05em"><tspan x="-3.176" y="0">%</tspan></text>
  </svg>
</p> } */}
 {this.props.value.state.enable_discount_badge &&
                    <>
                    {
                      !this.props.value.state.show_discount_percentage ?

                                          <div className='product-percentage-div'>
                    <svg 
                    
                    xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18">
                      <path id="Path_119102" data-name="Path 119102" d="M0,0V18l3.25-2.057L6.5,18l3.25-2.057L13,18V0Z" transform="translate(0 0)" 
                      fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>%</p>
                    </div>
                    :
                    <div className='product-percentage-text-div'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                      <path id="Path_119101" data-name="Path 119101" d="M122.691,0V18l3.334-2.057L129.357,18l3.333-2.057L136.024,18l3.333-2.057L142.691,18V0Z" transform="translate(-122.691 0)" 
                        fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>12%</p>
                    </div>
                      }
                    </>
                    }
                      </h3>
                      <h3 className='product-prev-image'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
  <g id="Group_42041" data-name="Group 42041" transform="translate(0.261 0.218)">
    <path id="Polygon_58" data-name="Polygon 58" d="M5.5,0,11,7H0Z" transform="translate(-0.261 8.782)" fill="#cfcfcf" opacity="0.5"/>
    <path id="Polygon_59" data-name="Polygon 59" d="M7.5,0,15,9H0Z" transform="translate(6.739 6.782)" fill="#cfcfcf"/>
    <circle id="Ellipse_5321" data-name="Ellipse 5321" cx="2.5" cy="2.5" r="2.5" transform="translate(5.739 -0.218)" fill="#cfcfcf"/>
  </g>
</svg>
                      </h3>
                      {/* {
                      this.props.value.state.show_out_of_stock &&  <p className='stocklist-flex-page'>10 in Stock</p> } */}
                       <p className='product-multi-img'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="5" viewBox="0 0 26 5">
  <g id="Group_42024" data-name="Group 42024" transform="translate(2.008 0.883)">
    <g id="Group_39702" data-name="Group 39702" transform="translate(0)">
      <g id="Ellipse_8337" data-name="Ellipse 8337" transform="translate(-1.008 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8340" data-name="Ellipse 8340" transform="translate(12.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8338" data-name="Ellipse 8338" transform="translate(5.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8339" data-name="Ellipse 8339" transform="translate(19.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
    </g>
  </g>
</svg>
</p>
                       {
                      this.props.value.state.enable_wishlist_on_listing_page &&
                      <p className="product-wishlist">
                        <svg id="Layer_54" data-name="Layer 54" xmlns="http://www.w3.org/2000/svg" width="9.681" height="8.21" viewBox="0 0 9.681 8.21">
  <path id="Path_8950" data-name="Path 8950" d="M5.876,11.5a.968.968,0,0,1-.688-.284L1.863,7.89a2.815,2.815,0,0,1-.814-2.018A2.6,2.6,0,0,1,3.677,3.29a2.6,2.6,0,0,1,1.876.772l.323.323.265-.265h0a2.709,2.709,0,0,1,3.551-.287,2.663,2.663,0,0,1,.261,3.99l-3.39,3.393A.968.968,0,0,1,5.876,11.5Z" transform="translate(-1.049 -3.29)" fill="#cfcfcf"/>
</svg>
                      </p> }
                    </li>
                    <li className='product-detailslist'>
                      <ul className='product-preview-prdetails'
                       style={{
                        borderRadius:
                        (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                        this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                       }}
                      >
                        <li>
                          <p className='product-preview-name'>Woman yellow solid round neck longline t-shirt</p>
                        </li>
                        <li className='product-preview-rating'>
                        <svg id="iconfinder_star_216411_2_" data-name="iconfinder_star_216411 (2)" xmlns="http://www.w3.org/2000/svg" width="11.806" height="11.27" viewBox="0 0 11.806 11.27">
  <g id="Group_115" data-name="Group 115" transform="translate(0)">
    <path id="Path_56" data-name="Path 56" d="M7.687,7.659l-3.724.413a.356.356,0,0,0-.3.243.35.35,0,0,0,.1.369c1.107,1.011,2.77,2.525,2.77,2.525s-.457,2.2-.759,3.671a.357.357,0,0,0,.139.358.351.351,0,0,0,.38.02C7.6,14.516,9.551,13.4,9.551,13.4l3.255,1.857a.352.352,0,0,0,.52-.378c-.3-1.47-.757-3.672-.757-3.672l2.77-2.523a.357.357,0,0,0,.1-.371.353.353,0,0,0-.3-.241c-1.49-.167-3.725-.415-3.725-.415L9.873,4.242a.357.357,0,0,0-.322-.208.352.352,0,0,0-.32.208C8.613,5.609,7.687,7.659,7.687,7.659Z" transform="translate(-3.649 -4.034)" fill="#ffca2a"/>
  </g>
</svg>
<p>4.2<span style={{marginLeft:"4px"}}>(53)</span></p>
                        </li>
                        <li className='d-flex justify-content-between product-prev-pricing'>
                          <div>
                          <h3>$59</h3>
                          <h2>$67</h2>
                          
                          </div>
                          {this.props.value.state.show_add_to_cart_on_listing_page && 
                        <div className='product-save-cart'>
                          {/* <svg id="Group_41218" data-name="Group 41218" xmlns="http://www.w3.org/2000/svg" width="17.18" height="14.9" viewBox="0 0 17.18 14.9">
                            <path id="Path_8614" data-name="Path 8614" d="M-4169-1583.91h2.541l1.944,8.885h7.769l1.925-6.439H-4165.8" transform="translate(4169.001 1585.789)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <circle id="Ellipse_3030" data-name="Ellipse 3030" cx="1.106" cy="1.106" r="1.106" transform="translate(4.506 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <circle id="Ellipse_3031" data-name="Ellipse 3031" cx="1.106" cy="1.106" r="1.106" transform="translate(10.033 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <ellipse id="Ellipse_8319" data-name="Ellipse 8319" cx="4.161" cy="4.161" rx="4.161" ry="4.161" transform="translate(8.859 0)" fill="#cfcfcf" />
                            <path id="Union_334" data-name="Union 334" d="M2.148,2.148h0v0h0v0Z" transform="translate(10.957 2.027)" fill="none" stroke="#fff" stroke-width="1" />
                          </svg> */}
                          +
                        </div>}
                        </li>
                      </ul>

                    </li>
                  </ul>

                </div>
                <div className='product-flex-preview-list-page'>
                  <ul
                   style={{
                    borderRadius:
                    (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                    this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                   }}
                  >
                    <li className='product-preview-img' 
               style={{borderRadius:this.props.value.state.image_shape_on_listing_page==="soft_corner"?"10px":"",
               height: calculateHeight(140,this.props.value.state.image_ratio_on_listing_page), }}
                    >
                      <h3 className='d-flex product-img-head'>
                         {
                      this.props.value.state.show_out_of_stock &&  <p className='stocklist-flex-page'>10 in Stock</p> }
                        {/* {this.props.value.state.enable_discount_badge &&

                      
<p className={`${this.props.value.state.show_discount_percentage ?" product-percentage-with-text " : "product-percentage"}`} style={{
                        background:this.props.value.state.discount_badge_bg_colour,
                        color:this.props.value.state.discount_badge_text_colour,
                      }}>{this.props.value.state.show_discount_percentage?"12":""}
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11">
    <text id="_" data-name="%" transform="translate(4 8)" fill={this.props.value.state.discount_badge_text_colour} font-size="8" font-family="Poppins-Medium, Poppins" font-weight="500" letter-spacing="0.05em"><tspan x="-3.176" y="0">%</tspan></text>
  </svg>
</p> } */}
 {this.props.value.state.enable_discount_badge &&
                    <>
                    {
                      !this.props.value.state.show_discount_percentage ?

                                          <div className='product-percentage-div'>
                    <svg 
                    
                    xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18">
                      <path id="Path_119102" data-name="Path 119102" d="M0,0V18l3.25-2.057L6.5,18l3.25-2.057L13,18V0Z" transform="translate(0 0)" 
                      fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>%</p>
                    </div>
                    :
                    <div className='product-percentage-text-div'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                      <path id="Path_119101" data-name="Path 119101" d="M122.691,0V18l3.334-2.057L129.357,18l3.333-2.057L136.024,18l3.333-2.057L142.691,18V0Z" transform="translate(-122.691 0)" 
                        fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>12%</p>
                    </div>
                      }
                    </>
                    }
                      </h3>
                      <h3 className='product-prev-image'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
  <g id="Group_42041" data-name="Group 42041" transform="translate(0.261 0.218)">
    <path id="Polygon_58" data-name="Polygon 58" d="M5.5,0,11,7H0Z" transform="translate(-0.261 8.782)" fill="#cfcfcf" opacity="0.5"/>
    <path id="Polygon_59" data-name="Polygon 59" d="M7.5,0,15,9H0Z" transform="translate(6.739 6.782)" fill="#cfcfcf"/>
    <circle id="Ellipse_5321" data-name="Ellipse 5321" cx="2.5" cy="2.5" r="2.5" transform="translate(5.739 -0.218)" fill="#cfcfcf"/>
  </g>
</svg>
                      </h3>
                      {/* <p className='stocklist-flex-page'>10 in Stock</p> */}
                      <p className='product-multi-img'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="5" viewBox="0 0 26 5">
  <g id="Group_42024" data-name="Group 42024" transform="translate(2.008 0.883)">
    <g id="Group_39702" data-name="Group 39702" transform="translate(0)">
      <g id="Ellipse_8337" data-name="Ellipse 8337" transform="translate(-1.008 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8340" data-name="Ellipse 8340" transform="translate(12.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8338" data-name="Ellipse 8338" transform="translate(5.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8339" data-name="Ellipse 8339" transform="translate(19.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
    </g>
  </g>
</svg>
</p>
                      {
                      this.props.value.state.enable_wishlist_on_listing_page &&
                      <p className="product-wishlist">
                       <svg id="Layer_54" data-name="Layer 54" xmlns="http://www.w3.org/2000/svg" width="9.681" height="8.21" viewBox="0 0 9.681 8.21">
  <path id="Path_8950" data-name="Path 8950" d="M5.876,11.5a.968.968,0,0,1-.688-.284L1.863,7.89a2.815,2.815,0,0,1-.814-2.018A2.6,2.6,0,0,1,3.677,3.29a2.6,2.6,0,0,1,1.876.772l.323.323.265-.265h0a2.709,2.709,0,0,1,3.551-.287,2.663,2.663,0,0,1,.261,3.99l-3.39,3.393A.968.968,0,0,1,5.876,11.5Z" transform="translate(-1.049 -3.29)" fill="#cfcfcf"/>
</svg>
                      </p> }
                    </li>
                    <li className='product-detailslist'>
                      <ul className='product-preview-prdetails'
                       style={{
                        borderRadius:
                        (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                        this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                       }}
                      >
                        <li>
                          <p className='product-preview-name'>Woman yellow solid round neck longline t-shirt</p>
                        </li>
                        <li className='product-preview-rating'>
                        <svg id="iconfinder_star_216411_2_" data-name="iconfinder_star_216411 (2)" xmlns="http://www.w3.org/2000/svg" width="11.806" height="11.27" viewBox="0 0 11.806 11.27">
  <g id="Group_115" data-name="Group 115" transform="translate(0)">
    <path id="Path_56" data-name="Path 56" d="M7.687,7.659l-3.724.413a.356.356,0,0,0-.3.243.35.35,0,0,0,.1.369c1.107,1.011,2.77,2.525,2.77,2.525s-.457,2.2-.759,3.671a.357.357,0,0,0,.139.358.351.351,0,0,0,.38.02C7.6,14.516,9.551,13.4,9.551,13.4l3.255,1.857a.352.352,0,0,0,.52-.378c-.3-1.47-.757-3.672-.757-3.672l2.77-2.523a.357.357,0,0,0,.1-.371.353.353,0,0,0-.3-.241c-1.49-.167-3.725-.415-3.725-.415L9.873,4.242a.357.357,0,0,0-.322-.208.352.352,0,0,0-.32.208C8.613,5.609,7.687,7.659,7.687,7.659Z" transform="translate(-3.649 -4.034)" fill="#ffca2a"/>
  </g>
</svg>
<p>4.2<span style={{marginLeft:"4px"}}>(53)</span></p>
                        </li>
                        <li className='d-flex justify-content-between product-prev-pricing'>
                          <div>
                          <h3>$59</h3>
                          <h2>$67</h2>
                          
                          </div>
                          {this.props.value.state.show_add_to_cart_on_listing_page && 
                        <div className='product-save-cart'>
                          {/* <svg id="Group_41218" data-name="Group 41218" xmlns="http://www.w3.org/2000/svg" width="17.18" height="14.9" viewBox="0 0 17.18 14.9">
                            <path id="Path_8614" data-name="Path 8614" d="M-4169-1583.91h2.541l1.944,8.885h7.769l1.925-6.439H-4165.8" transform="translate(4169.001 1585.789)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <circle id="Ellipse_3030" data-name="Ellipse 3030" cx="1.106" cy="1.106" r="1.106" transform="translate(4.506 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <circle id="Ellipse_3031" data-name="Ellipse 3031" cx="1.106" cy="1.106" r="1.106" transform="translate(10.033 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <ellipse id="Ellipse_8319" data-name="Ellipse 8319" cx="4.161" cy="4.161" rx="4.161" ry="4.161" transform="translate(8.859 0)" fill="#cfcfcf" />
                            <path id="Union_334" data-name="Union 334" d="M2.148,2.148h0v0h0v0Z" transform="translate(10.957 2.027)" fill="none" stroke="#fff" stroke-width="1" />
                          </svg> */}
                          +
                        </div>}
                        </li>
                      </ul>

                    </li>
                  </ul>

                </div>
                <div className='product-flex-preview-list-page'>
                  <ul
                   style={{
                    borderRadius:
                    (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                    this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                   }}
                  >
                    <li className='product-preview-img' 
                style={{borderRadius:this.props.value.state.image_shape_on_listing_page==="soft_corner"?"10px":"",
                height: calculateHeight(140,this.props.value.state.image_ratio_on_listing_page), }}
                    >
                      <h3 className='d-flex product-img-head'>
                         {
                      this.props.value.state.show_out_of_stock &&  <p className='stocklist-flex-page'>10 in Stock</p> }
                      {/* {this.props.value.state.enable_discount_badge &&

                      
<p className={`${this.props.value.state.show_discount_percentage ?" product-percentage-with-text " : "product-percentage"}`} style={{
                        background:this.props.value.state.discount_badge_bg_colour,
                        color:this.props.value.state.discount_badge_text_colour,
                      }}>{this.props.value.state.show_discount_percentage?"12":""}
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11">
    <text id="_" data-name="%" transform="translate(4 8)" fill={this.props.value.state.discount_badge_text_colour} font-size="8" font-family="Poppins-Medium, Poppins" font-weight="500" letter-spacing="0.05em"><tspan x="-3.176" y="0">%</tspan></text>
  </svg>
</p> } */}
 {this.props.value.state.enable_discount_badge &&
                    <>
                    {
                      !this.props.value.state.show_discount_percentage ?

                                          <div className='product-percentage-div'>
                    <svg 
                    
                    xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18">
                      <path id="Path_119102" data-name="Path 119102" d="M0,0V18l3.25-2.057L6.5,18l3.25-2.057L13,18V0Z" transform="translate(0 0)" 
                      fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>%</p>
                    </div>
                    :
                    <div className='product-percentage-text-div'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                      <path id="Path_119101" data-name="Path 119101" d="M122.691,0V18l3.334-2.057L129.357,18l3.333-2.057L136.024,18l3.333-2.057L142.691,18V0Z" transform="translate(-122.691 0)" 
                        fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>12%</p>
                    </div>
                      }
                    </>
                    }
                      </h3>
                      <h3 className='product-prev-image'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
  <g id="Group_42041" data-name="Group 42041" transform="translate(0.261 0.218)">
    <path id="Polygon_58" data-name="Polygon 58" d="M5.5,0,11,7H0Z" transform="translate(-0.261 8.782)" fill="#cfcfcf" opacity="0.5"/>
    <path id="Polygon_59" data-name="Polygon 59" d="M7.5,0,15,9H0Z" transform="translate(6.739 6.782)" fill="#cfcfcf"/>
    <circle id="Ellipse_5321" data-name="Ellipse 5321" cx="2.5" cy="2.5" r="2.5" transform="translate(5.739 -0.218)" fill="#cfcfcf"/>
  </g>
</svg>
                      </h3>
                      {/* {
                      this.props.value.state.show_out_of_stock &&   <p className='stocklist-flex-page'>10 in Stock</p> } */}
                       <p className='product-multi-img'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="5" viewBox="0 0 26 5">
  <g id="Group_42024" data-name="Group 42024" transform="translate(2.008 0.883)">
    <g id="Group_39702" data-name="Group 39702" transform="translate(0)">
      <g id="Ellipse_8337" data-name="Ellipse 8337" transform="translate(-1.008 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8340" data-name="Ellipse 8340" transform="translate(12.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8338" data-name="Ellipse 8338" transform="translate(5.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8339" data-name="Ellipse 8339" transform="translate(19.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
    </g>
  </g>
</svg>
</p>
                       {
                      this.props.value.state.enable_wishlist_on_listing_page &&
                      <p className="product-wishlist">
                       <svg id="Layer_54" data-name="Layer 54" xmlns="http://www.w3.org/2000/svg" width="9.681" height="8.21" viewBox="0 0 9.681 8.21">
  <path id="Path_8950" data-name="Path 8950" d="M5.876,11.5a.968.968,0,0,1-.688-.284L1.863,7.89a2.815,2.815,0,0,1-.814-2.018A2.6,2.6,0,0,1,3.677,3.29a2.6,2.6,0,0,1,1.876.772l.323.323.265-.265h0a2.709,2.709,0,0,1,3.551-.287,2.663,2.663,0,0,1,.261,3.99l-3.39,3.393A.968.968,0,0,1,5.876,11.5Z" transform="translate(-1.049 -3.29)" fill="#cfcfcf"/>
</svg>
                      </p> }
                    </li>
                    <li className='product-detailslist'>
                      <ul className='product-preview-prdetails'
                       style={{
                        borderRadius:
                        (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                        this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                       }}
                      >
                        <li>
                          <p className='product-preview-name'>Woman yellow solid round neck longline t-shirt</p>
                        </li>
                        <li className='product-preview-rating'>
                        <svg id="iconfinder_star_216411_2_" data-name="iconfinder_star_216411 (2)" xmlns="http://www.w3.org/2000/svg" width="11.806" height="11.27" viewBox="0 0 11.806 11.27">
  <g id="Group_115" data-name="Group 115" transform="translate(0)">
    <path id="Path_56" data-name="Path 56" d="M7.687,7.659l-3.724.413a.356.356,0,0,0-.3.243.35.35,0,0,0,.1.369c1.107,1.011,2.77,2.525,2.77,2.525s-.457,2.2-.759,3.671a.357.357,0,0,0,.139.358.351.351,0,0,0,.38.02C7.6,14.516,9.551,13.4,9.551,13.4l3.255,1.857a.352.352,0,0,0,.52-.378c-.3-1.47-.757-3.672-.757-3.672l2.77-2.523a.357.357,0,0,0,.1-.371.353.353,0,0,0-.3-.241c-1.49-.167-3.725-.415-3.725-.415L9.873,4.242a.357.357,0,0,0-.322-.208.352.352,0,0,0-.32.208C8.613,5.609,7.687,7.659,7.687,7.659Z" transform="translate(-3.649 -4.034)" fill="#ffca2a"/>
  </g>
</svg>
<p>4.2<span style={{marginLeft:"4px"}}>(53)</span></p>
                        </li>
                        <li className='d-flex justify-content-between product-prev-pricing'>
                          <div>
                          <h3>$59</h3>
                          <h2>$67</h2>
                          
                          </div>
                          {this.props.value.state.show_add_to_cart_on_listing_page && 
                        <div className='product-save-cart'>
                          {/* <svg id="Group_41218" data-name="Group 41218" xmlns="http://www.w3.org/2000/svg" width="17.18" height="14.9" viewBox="0 0 17.18 14.9">
                            <path id="Path_8614" data-name="Path 8614" d="M-4169-1583.91h2.541l1.944,8.885h7.769l1.925-6.439H-4165.8" transform="translate(4169.001 1585.789)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <circle id="Ellipse_3030" data-name="Ellipse 3030" cx="1.106" cy="1.106" r="1.106" transform="translate(4.506 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <circle id="Ellipse_3031" data-name="Ellipse 3031" cx="1.106" cy="1.106" r="1.106" transform="translate(10.033 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <ellipse id="Ellipse_8319" data-name="Ellipse 8319" cx="4.161" cy="4.161" rx="4.161" ry="4.161" transform="translate(8.859 0)" fill="#cfcfcf" />
                            <path id="Union_334" data-name="Union 334" d="M2.148,2.148h0v0h0v0Z" transform="translate(10.957 2.027)" fill="none" stroke="#fff" stroke-width="1" />
                          </svg> */}
                          +
                        </div>}
                        </li>
                      </ul>

                    </li>
                  </ul>

                </div>
                <div className='product-flex-preview-list-page'>
                  <ul
                   style={{
                    borderRadius:
                    (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                    this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                   }}
                  >
                    <li className='product-preview-img' 
                    style={{borderRadius:this.props.value.state.image_shape_on_listing_page==="soft_corner"?"10px":"",
                    height: calculateHeight(140,this.props.value.state.image_ratio_on_listing_page), }}
                    >
                      <h3 className='d-flex product-img-head'>
                         {
                      this.props.value.state.show_out_of_stock &&  <p className='stocklist-flex-page' style={{backgroundColor: "#FFF1F2", color: "#FF4D5F"}}>Out of stock</p> }
                        {/* {this.props.value.state.enable_discount_badge &&

                      
<p className={`${this.props.value.state.show_discount_percentage ?" product-percentage-with-text " : "product-percentage"}`} style={{
                        background:this.props.value.state.discount_badge_bg_colour,
                        color:this.props.value.state.discount_badge_text_colour,
                      }}>{this.props.value.state.show_discount_percentage?"12":""}
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11">
    <text id="_" data-name="%" transform="translate(4 8)" fill={this.props.value.state.discount_badge_text_colour} font-size="8" font-family="Poppins-Medium, Poppins" font-weight="500" letter-spacing="0.05em"><tspan x="-3.176" y="0">%</tspan></text>
  </svg>
</p> } */}
 {this.props.value.state.enable_discount_badge &&
                    <>
                    {
                      !this.props.value.state.show_discount_percentage ?

                                          <div className='product-percentage-div'>
                    <svg 
                    
                    xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18">
                      <path id="Path_119102" data-name="Path 119102" d="M0,0V18l3.25-2.057L6.5,18l3.25-2.057L13,18V0Z" transform="translate(0 0)" 
                      fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>%</p>
                    </div>
                    :
                    <div className='product-percentage-text-div'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                      <path id="Path_119101" data-name="Path 119101" d="M122.691,0V18l3.334-2.057L129.357,18l3.333-2.057L136.024,18l3.333-2.057L142.691,18V0Z" transform="translate(-122.691 0)" 
                        fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>12%</p>
                    </div>
                      }
                    </>
                    }
                      </h3>
                      <h3 className='product-prev-image'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
  <g id="Group_42041" data-name="Group 42041" transform="translate(0.261 0.218)">
    <path id="Polygon_58" data-name="Polygon 58" d="M5.5,0,11,7H0Z" transform="translate(-0.261 8.782)" fill="#cfcfcf" opacity="0.5"/>
    <path id="Polygon_59" data-name="Polygon 59" d="M7.5,0,15,9H0Z" transform="translate(6.739 6.782)" fill="#cfcfcf"/>
    <circle id="Ellipse_5321" data-name="Ellipse 5321" cx="2.5" cy="2.5" r="2.5" transform="translate(5.739 -0.218)" fill="#cfcfcf"/>
  </g>
</svg>
                      </h3>
                      {/* {
                      this.props.value.state.show_out_of_stock && <p className='stocklist-flex-page'>10 in Stock</p>} */}
                       <p className='product-multi-img'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="5" viewBox="0 0 26 5">
  <g id="Group_42024" data-name="Group 42024" transform="translate(2.008 0.883)">
    <g id="Group_39702" data-name="Group 39702" transform="translate(0)">
      <g id="Ellipse_8337" data-name="Ellipse 8337" transform="translate(-1.008 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8340" data-name="Ellipse 8340" transform="translate(12.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8338" data-name="Ellipse 8338" transform="translate(5.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8339" data-name="Ellipse 8339" transform="translate(19.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
    </g>
  </g>
</svg>
</p>
                       {
                      this.props.value.state.enable_wishlist_on_listing_page &&
                      <p className="product-wishlist">
                       <svg id="Layer_54" data-name="Layer 54" xmlns="http://www.w3.org/2000/svg" width="9.681" height="8.21" viewBox="0 0 9.681 8.21">
  <path id="Path_8950" data-name="Path 8950" d="M5.876,11.5a.968.968,0,0,1-.688-.284L1.863,7.89a2.815,2.815,0,0,1-.814-2.018A2.6,2.6,0,0,1,3.677,3.29a2.6,2.6,0,0,1,1.876.772l.323.323.265-.265h0a2.709,2.709,0,0,1,3.551-.287,2.663,2.663,0,0,1,.261,3.99l-3.39,3.393A.968.968,0,0,1,5.876,11.5Z" transform="translate(-1.049 -3.29)" fill="#cfcfcf"/>
</svg>
                      </p> }
                    </li>
                    <li className='product-detailslist'>
                      <ul className='product-preview-prdetails'
                       style={{
                        borderRadius:
                        (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                        this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                       }}
                      >
                        <li>
                          <p className='product-preview-name'>Woman yellow solid round neck longline t-shirt</p>
                        </li>
                        <li className='product-preview-rating'>
                        <svg id="iconfinder_star_216411_2_" data-name="iconfinder_star_216411 (2)" xmlns="http://www.w3.org/2000/svg" width="11.806" height="11.27" viewBox="0 0 11.806 11.27">
  <g id="Group_115" data-name="Group 115" transform="translate(0)">
    <path id="Path_56" data-name="Path 56" d="M7.687,7.659l-3.724.413a.356.356,0,0,0-.3.243.35.35,0,0,0,.1.369c1.107,1.011,2.77,2.525,2.77,2.525s-.457,2.2-.759,3.671a.357.357,0,0,0,.139.358.351.351,0,0,0,.38.02C7.6,14.516,9.551,13.4,9.551,13.4l3.255,1.857a.352.352,0,0,0,.52-.378c-.3-1.47-.757-3.672-.757-3.672l2.77-2.523a.357.357,0,0,0,.1-.371.353.353,0,0,0-.3-.241c-1.49-.167-3.725-.415-3.725-.415L9.873,4.242a.357.357,0,0,0-.322-.208.352.352,0,0,0-.32.208C8.613,5.609,7.687,7.659,7.687,7.659Z" transform="translate(-3.649 -4.034)" fill="#ffca2a"/>
  </g>
</svg>
<p>4.2<span style={{marginLeft:"4px"}}>(53)</span></p>
                        </li>
                        <li className='d-flex justify-content-between product-prev-pricing'>
                          <div>
                          <h3>$59</h3>
                          <h2>$67</h2>
                          
                          </div>
                          {this.props.value.state.show_add_to_cart_on_listing_page && 
                        <div className='product-save-cart'>
                          {/* <svg id="Group_41218" data-name="Group 41218" xmlns="http://www.w3.org/2000/svg" width="17.18" height="14.9" viewBox="0 0 17.18 14.9">
                            <path id="Path_8614" data-name="Path 8614" d="M-4169-1583.91h2.541l1.944,8.885h7.769l1.925-6.439H-4165.8" transform="translate(4169.001 1585.789)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <circle id="Ellipse_3030" data-name="Ellipse 3030" cx="1.106" cy="1.106" r="1.106" transform="translate(4.506 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <circle id="Ellipse_3031" data-name="Ellipse 3031" cx="1.106" cy="1.106" r="1.106" transform="translate(10.033 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <ellipse id="Ellipse_8319" data-name="Ellipse 8319" cx="4.161" cy="4.161" rx="4.161" ry="4.161" transform="translate(8.859 0)" fill="#cfcfcf" />
                            <path id="Union_334" data-name="Union 334" d="M2.148,2.148h0v0h0v0Z" transform="translate(10.957 2.027)" fill="none" stroke="#fff" stroke-width="1" />
                          </svg> */}
                          +
                        </div>}
                        </li>
                      </ul>

                    </li>
                  </ul>

                </div>
                <div className='product-flex-preview-list-page'>
                  <ul
                   style={{
                    borderRadius:
                    (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                    this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                   }}
                  >
                    <li className='product-preview-img ' 
         style={{borderRadius:this.props.value.state.image_shape_on_listing_page==="soft_corner"?"10px":"",
         height: calculateHeight(140,this.props.value.state.image_ratio_on_listing_page), }}
                    >
                      <h3 className='d-flex product-img-head'>
                         {
                      this.props.value.state.show_out_of_stock &&  <p className='stocklist-flex-page'>10 in Stock</p> }
                     {/* {this.props.value.state.enable_discount_badge &&

                      
<p className={`${this.props.value.state.show_discount_percentage ?" product-percentage-with-text " : "product-percentage"}`} style={{
                        background:this.props.value.state.discount_badge_bg_colour,
                        color:this.props.value.state.discount_badge_text_colour,
                      }}>{this.props.value.state.show_discount_percentage?"12":""}
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11">
    <text id="_" data-name="%" transform="translate(4 8)" fill={this.props.value.state.discount_badge_text_colour} font-size="8" font-family="Poppins-Medium, Poppins" font-weight="500" letter-spacing="0.05em"><tspan x="-3.176" y="0">%</tspan></text>
  </svg>
</p> } */}
 {this.props.value.state.enable_discount_badge &&
                    <>
                    {
                      !this.props.value.state.show_discount_percentage ?

                                          <div className='product-percentage-div'>
                    <svg 
                    
                    xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18">
                      <path id="Path_119102" data-name="Path 119102" d="M0,0V18l3.25-2.057L6.5,18l3.25-2.057L13,18V0Z" transform="translate(0 0)" 
                      fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>%</p>
                    </div>
                    :
                    <div className='product-percentage-text-div'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                      <path id="Path_119101" data-name="Path 119101" d="M122.691,0V18l3.334-2.057L129.357,18l3.333-2.057L136.024,18l3.333-2.057L142.691,18V0Z" transform="translate(-122.691 0)" 
                        fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>12%</p>
                    </div>
                      }
                    </>
                    }
                      </h3>
                      <h3 className='product-prev-image'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
  <g id="Group_42041" data-name="Group 42041" transform="translate(0.261 0.218)">
    <path id="Polygon_58" data-name="Polygon 58" d="M5.5,0,11,7H0Z" transform="translate(-0.261 8.782)" fill="#cfcfcf" opacity="0.5"/>
    <path id="Polygon_59" data-name="Polygon 59" d="M7.5,0,15,9H0Z" transform="translate(6.739 6.782)" fill="#cfcfcf"/>
    <circle id="Ellipse_5321" data-name="Ellipse 5321" cx="2.5" cy="2.5" r="2.5" transform="translate(5.739 -0.218)" fill="#cfcfcf"/>
  </g>
</svg>
                      </h3>
                      {/* {
                      this.props.value.state.show_out_of_stock &&   <p className='stocklist-flex-page'>10 in Stock</p> } */}
                      <p className='product-multi-img'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="5" viewBox="0 0 26 5">
  <g id="Group_42024" data-name="Group 42024" transform="translate(2.008 0.883)">
    <g id="Group_39702" data-name="Group 39702" transform="translate(0)">
      <g id="Ellipse_8337" data-name="Ellipse 8337" transform="translate(-1.008 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8340" data-name="Ellipse 8340" transform="translate(12.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8338" data-name="Ellipse 8338" transform="translate(5.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8339" data-name="Ellipse 8339" transform="translate(19.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
    </g>
  </g>
</svg>
</p>
                       {
                      this.props.value.state.enable_wishlist_on_listing_page &&
                      <p className="product-wishlist">
                        <svg id="Layer_54" data-name="Layer 54" xmlns="http://www.w3.org/2000/svg" width="9.681" height="8.21" viewBox="0 0 9.681 8.21">
  <path id="Path_8950" data-name="Path 8950" d="M5.876,11.5a.968.968,0,0,1-.688-.284L1.863,7.89a2.815,2.815,0,0,1-.814-2.018A2.6,2.6,0,0,1,3.677,3.29a2.6,2.6,0,0,1,1.876.772l.323.323.265-.265h0a2.709,2.709,0,0,1,3.551-.287,2.663,2.663,0,0,1,.261,3.99l-3.39,3.393A.968.968,0,0,1,5.876,11.5Z" transform="translate(-1.049 -3.29)" fill="#cfcfcf"/>
</svg>
                      </p> }
                    </li>
                    <li className='product-detailslist'>
                      <ul className='product-preview-prdetails'
                       style={{
                        borderRadius:
                        (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                        this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                       }}
                      >
                        <li>
                          <p className='product-preview-name'>Woman yellow solid round neck longline t-shirt</p>
                        </li>
                        <li className='product-preview-rating'>
                        <svg id="iconfinder_star_216411_2_" data-name="iconfinder_star_216411 (2)" xmlns="http://www.w3.org/2000/svg" width="11.806" height="11.27" viewBox="0 0 11.806 11.27">
  <g id="Group_115" data-name="Group 115" transform="translate(0)">
    <path id="Path_56" data-name="Path 56" d="M7.687,7.659l-3.724.413a.356.356,0,0,0-.3.243.35.35,0,0,0,.1.369c1.107,1.011,2.77,2.525,2.77,2.525s-.457,2.2-.759,3.671a.357.357,0,0,0,.139.358.351.351,0,0,0,.38.02C7.6,14.516,9.551,13.4,9.551,13.4l3.255,1.857a.352.352,0,0,0,.52-.378c-.3-1.47-.757-3.672-.757-3.672l2.77-2.523a.357.357,0,0,0,.1-.371.353.353,0,0,0-.3-.241c-1.49-.167-3.725-.415-3.725-.415L9.873,4.242a.357.357,0,0,0-.322-.208.352.352,0,0,0-.32.208C8.613,5.609,7.687,7.659,7.687,7.659Z" transform="translate(-3.649 -4.034)" fill="#ffca2a"/>
  </g>
</svg>
<p>4.2<span style={{marginLeft:"4px"}}>(53)</span></p>
                        </li>
                        <li className='d-flex justify-content-between product-prev-pricing'>
                          <div>
                          <h3>$59</h3>
                          <h2>$67</h2>
                          
                          </div>
                          {this.props.value.state.show_add_to_cart_on_listing_page && 
                        <div className='product-save-cart'>
                          {/* <svg id="Group_41218" data-name="Group 41218" xmlns="http://www.w3.org/2000/svg" width="17.18" height="14.9" viewBox="0 0 17.18 14.9">
                            <path id="Path_8614" data-name="Path 8614" d="M-4169-1583.91h2.541l1.944,8.885h7.769l1.925-6.439H-4165.8" transform="translate(4169.001 1585.789)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <circle id="Ellipse_3030" data-name="Ellipse 3030" cx="1.106" cy="1.106" r="1.106" transform="translate(4.506 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <circle id="Ellipse_3031" data-name="Ellipse 3031" cx="1.106" cy="1.106" r="1.106" transform="translate(10.033 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <ellipse id="Ellipse_8319" data-name="Ellipse 8319" cx="4.161" cy="4.161" rx="4.161" ry="4.161" transform="translate(8.859 0)" fill="#cfcfcf" />
                            <path id="Union_334" data-name="Union 334" d="M2.148,2.148h0v0h0v0Z" transform="translate(10.957 2.027)" fill="none" stroke="#fff" stroke-width="1" />
                          </svg> */}
                          +
                        </div>}
                        </li>
                        
                      </ul>

                    </li>
                  </ul>

                </div>
              </div> 
            </div> }
          </div>
           {/* Second Slide */}
          <div className='product-firstscreen'>

            {/* Product details div */}
            {/* {
                !this.props.value.state.show_images_in_gallery ?  */}
          <div className='w-100 '>
            <div className='selected-product' 
            
            style={{
             height:calculateHeight(294,this.props.value.state.image_ratio_on_detail_page)
            }}>
              <div className='productimg-topdiv' ref={this.props.wishlist_ref}>
                <div>
                  <p className='productimag-backside'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12.514" height="12.515" viewBox="0 0 12.514 12.515">
                      <g id="Group_41519" data-name="Group 41519" transform="translate(0.707 0.354)">
                        <g id="Group_40332" data-name="Group 40332" transform="translate(0)">
                          <path id="Path_107551" data-name="Path 107551" d="M0,5.9,5.9,0l5.9,5.9" transform="translate(0 11.808) rotate(-90)" fill="none" stroke="#333" stroke-width="1" />
                        </g>
                        <path id="Path_107552" data-name="Path 107552" d="M-13838.825-23465.541h11.808" transform="translate(13838.825 23471.445)" fill="none" stroke="#333" stroke-width="1" />
                      </g>
                    </svg>
                  </p>


                </div>
                <div className='d-flex'>
                <p className='productimag-backside' style={{ marginRight: "10px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15.395 15">
  <g id="Group_42128" data-name="Group 42128" transform="translate(0.5 0.618)">
    <path id="Path_106591" data-name="Path 106591" d="M6.827,23.414a2.364,2.364,0,0,1-.207.974,2.4,2.4,0,1,1,.207-.974Z" transform="translate(-2 -15.812)" fill="none" stroke="#1a1a1a" stroke-linejoin="round" stroke-width="1"/>
    <circle id="Ellipse_8787" data-name="Ellipse 8787" cx="2.5" cy="2.5" r="2.5" transform="translate(9.395 -0.118)" fill="none" stroke="#1a1a1a" stroke-linejoin="round" stroke-width="1"/>
    <circle id="Ellipse_8788" data-name="Ellipse 8788" cx="2.5" cy="2.5" r="2.5" transform="translate(9.395 8.882)" fill="none" stroke="#1a1a1a" stroke-linejoin="round" stroke-width="1"/>
    <line id="Line_412" data-name="Line 412" x1="4.704" y1="2.075" transform="translate(4.621 8.575)" fill="none" stroke="#1a1a1a" stroke-linejoin="round" stroke-width="1"/>
    <line id="Line_413" data-name="Line 413" x1="4.907" y2="2.741" transform="translate(4.519 3.683)" fill="none" stroke="#1a1a1a" stroke-linejoin="round" stroke-width="1"/>
  </g>
</svg>
                  </p>
                  {this.props.value.state.enable_wishlist_on_detail_page &&
                  <p className='productimag-backside' style={{ marginRight: "10px" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13.222" viewBox="0 0 15.555 13.222">
  <path id="Path_118834" data-name="Path 118834" d="M4.27,1.073A3.124,3.124,0,0,1,6.478,2L7,2.516l.76.762.76-.762.425-.426,0,0,.005-.005a3.27,3.27,0,0,1,4.288-.355,3.227,3.227,0,0,1,.578,4.506,3.171,3.171,0,0,1-.271.309L8.1,12.007a.489.489,0,0,1-.689,0L2.071,6.655a3.492,3.492,0,0,1-1-2.487A3.121,3.121,0,0,1,4.18,1.073h.09ZM4.27,0H4.18A4.186,4.186,0,0,0,0,4.159,4.541,4.541,0,0,0,1.308,7.409l5.344,5.356a1.552,1.552,0,0,0,1.1.458h.008a1.554,1.554,0,0,0,1.1-.458L14.306,7.3A4.3,4.3,0,0,0,13.886.87a4.342,4.342,0,0,0-5.7.463l-.425.426-.518-.52A4.175,4.175,0,0,0,4.27,0" transform="translate(0)" fill="#1a1a1a"/>
</svg>
                  </p>}
                  <p className='productimag-backside' style={{ marginBottom: "10px" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17.519" height="15.387" viewBox="0 0 17.519 15.387">
                      <g id="Group_41520" data-name="Group 41520" transform="translate(0)">
                        <path id="Path_107576" data-name="Path 107576" d="M-4169-1583.41h3.021l2.311,10.064h9.237l2.289-8.064h-13.049" transform="translate(4169.001 1583.91)" fill="none" stroke="#333" stroke-width="1" />
                        <circle id="Ellipse_8908" data-name="Ellipse 8908" cx="1.315" cy="1.315" r="1.315" transform="translate(5.358 12.257)" fill="none" stroke="#333" stroke-width="1" />
                        <circle id="Ellipse_8909" data-name="Ellipse 8909" cx="1.315" cy="1.315" r="1.315" transform="translate(11.929 12.257)" fill="none" stroke="#333" stroke-width="1" />
                      </g>
                    </svg>
                  </p>
                  {/* {
                    this.props.value.state.enable_wishlist_on_detail_page &&
                  
                  <p className='productimag-backside' >
                    <svg id="Layer_54" data-name="Layer 54" xmlns="http://www.w3.org/2000/svg" width="17.475" height="14.82" viewBox="0 0 17.475 14.82">
                      <path id="Path_8950" data-name="Path 8950" d="M9.762,18.109A1.748,1.748,0,0,1,8.521,17.6l-6-6A5.082,5.082,0,0,1,1.05,7.952,4.7,4.7,0,0,1,5.793,3.29,4.7,4.7,0,0,1,9.179,4.683l.583.583.478-.478h0a4.889,4.889,0,0,1,6.41-.519,4.808,4.808,0,0,1,.472,7.2L11,17.6a1.748,1.748,0,0,1-1.241.513Z" transform="translate(-1.049 -3.29)" fill="#cfcfcf" />
                    </svg>
                  </p> } */}
                </div>
              </div>
              <div className='productimg-view'>
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
  <g id="Group_42140" data-name="Group 42140" transform="translate(0.065 0.465)">
    <path id="Polygon_58" data-name="Polygon 58" d="M5.5,0,11,6H0Z" transform="translate(-0.065 9.535)" fill="#cfcfcf" opacity="0.5"/>
    <path id="Polygon_59" data-name="Polygon 59" d="M7.5,0,15,9H0Z" transform="translate(6.935 6.535)" fill="#cfcfcf"/>
    <circle id="Ellipse_5321" data-name="Ellipse 5321" cx="2.5" cy="2.5" r="2.5" transform="translate(5.935 -0.465)" fill="#cfcfcf"/>
  </g>
</svg>
              </div>
              <div className='product-details-images'>
              <svg xmlns="http://www.w3.org/2000/svg" width="42" height="6" viewBox="0 0 42 6">
  <g id="Group_42141" data-name="Group 42141" transform="translate(1.211 1.239)">
    <g id="Group_39702" data-name="Group 39702" transform="translate(0 0)">
      <g id="Ellipse_8337" data-name="Ellipse 8337" transform="translate(8.789 -0.239)" fill="#fff" stroke="#cfcfcf" stroke-width="1">
        <circle cx="2" cy="2" r="2" stroke="none"/>
        <circle cx="2" cy="2" r="2.5" fill="none"/>
      </g>
      <g id="Ellipse_8340" data-name="Ellipse 8340" transform="translate(26.789 -0.239)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="2" cy="2" r="2" stroke="none"/>
        <circle cx="2" cy="2" r="2.5" fill="none"/>
      </g>
      <g id="Ellipse_8338" data-name="Ellipse 8338" transform="translate(17.789 -0.239)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="2" cy="2" r="2" stroke="none"/>
        <circle cx="2" cy="2" r="2.5" fill="none"/>
      </g>
      <g id="Ellipse_8339" data-name="Ellipse 8339" transform="translate(35.789 -0.239)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="2" cy="2" r="2" stroke="none"/>
        <circle cx="2" cy="2" r="2.5" fill="none"/>
      </g>
      <g id="Ellipse_8659" data-name="Ellipse 8659" transform="translate(-0.211 -0.239)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="2" cy="2" r="2" stroke="none"/>
        <circle cx="2" cy="2" r="2.5" fill="none"/>
      </g>
    </g>
  </g>
</svg>
              </div>

            </div>
            <div className='product-descripton-firstdiv'>
              {/* <p className='d-flex justify-content-center' style={{ marginBottom: "0px" }}>
                <img
                  src={
                    require("../../assets/images/dashboard/changeimg.png")
                      .default
                  }
                  className=""
                  style={{width: "unset"}}
                  alt="Goto"
                />
              </p> */}
              <ul>
                <li className='productname-text'>Woman yellow solid round neck t-shirt</li>
                
                <li className='d-flex justify-content-between user-total-ratting'>
                  <div>
                  <svg id="iconfinder_star_216411_2_" data-name="iconfinder_star_216411 (2)" xmlns="http://www.w3.org/2000/svg" width="11.812" height="11.275" viewBox="0 0 11.812 11.275">
  <g id="Group_115" data-name="Group 115" transform="translate(0)">
    <path id="Path_56" data-name="Path 56" d="M7.689,7.66l-3.726.413a.356.356,0,0,0-.3.243.35.35,0,0,0,.1.369C4.872,9.7,6.536,11.211,6.536,11.211s-.457,2.2-.76,3.673a.357.357,0,0,0,.139.358.351.351,0,0,0,.38.02C7.6,14.52,9.553,13.4,9.553,13.4l3.256,1.858a.353.353,0,0,0,.521-.378c-.3-1.471-.757-3.674-.757-3.674l2.772-2.524a.357.357,0,0,0,.1-.371.353.353,0,0,0-.3-.241c-1.491-.167-3.726-.415-3.726-.415L9.876,4.242a.358.358,0,0,0-.322-.208.353.353,0,0,0-.32.208C8.615,5.609,7.689,7.66,7.689,7.66Z" transform="translate(-3.649 -4.034)" fill="#ffca2a"/>
  </g>
</svg>
4.5
                    <span className='rating-count'>(245 reviews)</span>
                  </div>
                  {/* <div className='see-reviewdiv'>
                    <p>See reviews</p>
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="4.561" height="7.707" viewBox="0 0 4.561 7.707">
                        <path id="Path_118622" data-name="Path 118622" d="M0,3.5,3.5,0,7,3.5" transform="translate(3.854 0.354) rotate(90)" fill="none" stroke="#a1a1a1" stroke-width="1" />
                      </svg>
                    </span>
                  </div> */}
                </li>

                <li>
                  <div className='d-flex'>
                  <p className='currentprice'>$57</p>
                    <p className='previousprice'>$67</p>
                    <div className='product-percentege-div'>
                      <p>12%</p>
                    </div>
                  </div>
                  <p className='inventorydata'>In Stock</p>
                </li>

               
              </ul>
              <ul>
              <li className='products-detilas'>Yellow light wash 5-pocket mid-rise cropped jeans, clean look with no fade, has a button and zip closure, waistband with belt loops.</li>
                <li className='products-detilasnumber'>SKU: 08000749670</li>
              </ul>
            </div>
            <div className='product-descripton-seconddiv'>
              <ul>
                <li className='product-points'>
                <svg xmlns="http://www.w3.org/2000/svg" width="19.631" height="20.327" viewBox="0 0 19.631 20.327">
  <g id="Group_42912" data-name="Group 42912" transform="translate(-4.43 -3.976)">
    <g id="iconfinder_00-ELASTOFONT-STORE-READY_star_2703064" transform="translate(2.861 1.883)" fill="none">
      <path d="M15.443,13.447a.6.6,0,0,0-.172.528l.852,4.97L11.663,16.6a.6.6,0,0,0-.556,0L6.646,18.946l.852-4.97a.6.6,0,0,0-.172-.528L3.717,9.927,8.7,9.2a.6.6,0,0,0,.45-.326l2.23-4.522,2.23,4.522a.6.6,0,0,0,.45.326l4.987.725Z" stroke="none"/>
      <path d="M 6.645847797393799 18.94584846496582 L 11.10648822784424 16.59900856018066 C 11.28050708770752 16.50732612609863 11.48861789703369 16.50733757019043 11.66273784637451 16.59897804260254 L 16.12337684631348 18.94583702087402 L 15.27117824554443 13.97557735443115 C 15.23777770996094 13.78214740753174 15.3022575378418 13.5848274230957 15.44286823272705 13.44732761383057 L 19.05204772949219 9.926648139953613 L 14.06470775604248 9.201848030090332 C 13.86972808837891 9.173888206481934 13.70192813873291 9.051137924194336 13.61491775512695 8.875577926635742 L 11.38459777832031 4.353567600250244 L 9.154277801513672 8.875617980957031 C 9.067277908325195 9.051187515258789 8.899477958679199 9.173928260803223 8.704487800598145 9.201897621154785 L 3.717147827148438 9.926688194274902 L 7.326367855072021 13.44734764099121 C 7.466977596282959 13.58484745025635 7.531457901000977 13.78216743469238 7.498047828674316 13.97560787200928 L 6.645847797393799 18.94584846496582 M 17.45152854919434 20.77456855773926 L 11.3846378326416 17.58262634277344 L 5.317698001861572 20.77456855773926 L 6.476624965667725 14.01542949676514 L 1.569127798080444 9.228347778320312 L 8.351426124572754 8.242706298828125 L 11.38459777832031 2.09284782409668 L 14.4177713394165 8.242658615112305 L 21.20003700256348 9.228307723999023 L 16.2926025390625 14.01541709899902 L 17.45152854919434 20.77456855773926 Z" stroke="none" fill="#b0b0b0"/>
    </g>
    <g id="Ellipse_8861" data-name="Ellipse 8861" transform="translate(13.493 14.283)" fill="#b0b0b0" stroke="#fff" stroke-width="1">
      <circle cx="4.51" cy="4.51" r="4.51" stroke="none"/>
      <circle cx="4.51" cy="4.51" r="5.01" fill="none"/>
    </g>
    <path id="Path_106717" data-name="Path 106717" d="M48.579,38.07v4.811" transform="translate(-30.576 -21.611)" fill="none" stroke="#fff" stroke-width="1"/>
    <path id="Path_106718" data-name="Path 106718" d="M0,0V4.811" transform="translate(20.559 18.865) rotate(90)" fill="none" stroke="#fff" stroke-width="1"/>
  </g>
</svg>
<p>Complete your order and earn <span>202 Points</span> for a discount on a future purchase.</p>

                 </li>
                 </ul>
                 <ul>
                <li className='sizedropdown'>
                  <p className='sizedropdownhead'>Size</p>
                  <div>
                  <p className='sizedetails'>Small (S)</p>
                  <p className='sizedetails'>Medium (M) </p>
                  <p className='sizedetails'>Large (L) </p>
                  <p className='sizedetails'>Extra large (XL) </p>
                  </div>
                  

                </li>
                <li className='sizedropdown'>
                  <p className='sizedropdownhead'>Color</p>
                  <div>
                  <p className='sizedetails'>Red</p>
                  <p className='sizedetails'>Yellow</p>
                  <p className='sizedetails'>Blue</p>
                  </div>
                  

                </li>
                <li className='d-flex justify-content-between'>
                  {/* <div className='product-addtocart'><span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <path id="Union_3005" data-name="Union 3005" d="M5,14V8H-1V6H5V0H7V6h6V8H7v6Z" transform="translate(1)" fill="#a1a1a1" />
                  </svg></span>1<span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="2" viewBox="0 0 14 2">
                    <rect id="Rectangle_31170" data-name="Rectangle 31170" width="2" height="14" transform="translate(14) rotate(90)" fill="#a1a1a1" />
                  </svg></span></div> */}
                  <div className='addtocartbluebtn'>Add to cart</div>
                </li>
              </ul>
            </div>
              <div className='product-flex-view product-detial-flex-view'>
              <div className='product-flex-preview-list-page'>
                  <ul
                   style={{
                    borderRadius:
                    (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                    this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                   }}
                  >
                    <li className='product-preview-img' 
                    style={{borderRadius:this.props.value.state.image_shape_on_listing_page==="soft_corner"?"10px":"",
                    height: calculateHeight(136,this.props.value.state.image_ratio_on_listing_page), }}
                    >
                      <h3 className='d-flex product-img-head'>
                        {
                      this.props.value.state.show_out_of_stock &&  <p className='stocklist-flex-page'>10 in Stock</p> }
                        {/* {this.props.value.state.enable_discount_badge &&

                      
<p className={`${this.props.value.state.show_discount_percentage ?" product-percentage-with-text " : "product-percentage"}`} style={{
                        background:this.props.value.state.discount_badge_bg_colour,
                        color:this.props.value.state.discount_badge_text_colour,
                      }}>{this.props.value.state.show_discount_percentage?"12":""}
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11">
    <text id="_" data-name="%" transform="translate(4 8)" fill={this.props.value.state.discount_badge_text_colour} font-size="8" font-family="Poppins-Medium, Poppins" font-weight="500" letter-spacing="0.05em"><tspan x="-3.176" y="0">%</tspan></text>
  </svg>
</p> } */}
 {this.props.value.state.enable_discount_badge &&
                    <>
                    {
                      !this.props.value.state.show_discount_percentage ?

                                          <div className='product-percentage-div'>
                    <svg 
                    
                    xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18">
                      <path id="Path_119102" data-name="Path 119102" d="M0,0V18l3.25-2.057L6.5,18l3.25-2.057L13,18V0Z" transform="translate(0 0)" 
                      fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>%</p>
                    </div>
                    :
                    <div className='product-percentage-text-div'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                      <path id="Path_119101" data-name="Path 119101" d="M122.691,0V18l3.334-2.057L129.357,18l3.333-2.057L136.024,18l3.333-2.057L142.691,18V0Z" transform="translate(-122.691 0)" 
                        fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>12%</p>
                    </div>
                      }
                    </>
                    }


                      </h3>
                      <h3 className='product-prev-image'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
  <g id="Group_42041" data-name="Group 42041" transform="translate(0.261 0.218)">
    <path id="Polygon_58" data-name="Polygon 58" d="M5.5,0,11,7H0Z" transform="translate(-0.261 8.782)" fill="#cfcfcf" opacity="0.5"/>
    <path id="Polygon_59" data-name="Polygon 59" d="M7.5,0,15,9H0Z" transform="translate(6.739 6.782)" fill="#cfcfcf"/>
    <circle id="Ellipse_5321" data-name="Ellipse 5321" cx="2.5" cy="2.5" r="2.5" transform="translate(5.739 -0.218)" fill="#cfcfcf"/>
  </g>
</svg>
                      </h3>
                      {/* {
                      this.props.value.state.show_out_of_stock &&  <p className='stocklist-flex-page'>10 in Stock</p> } */}
                       <p className='product-multi-img'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="5" viewBox="0 0 26 5">
  <g id="Group_42024" data-name="Group 42024" transform="translate(2.008 0.883)">
    <g id="Group_39702" data-name="Group 39702" transform="translate(0)">
      <g id="Ellipse_8337" data-name="Ellipse 8337" transform="translate(-1.008 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8340" data-name="Ellipse 8340" transform="translate(12.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8338" data-name="Ellipse 8338" transform="translate(5.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8339" data-name="Ellipse 8339" transform="translate(19.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
    </g>
  </g>
</svg>
</p>
                       {
                      this.props.value.state.enable_wishlist_on_listing_page &&
                      <p className="product-wishlist">
                        <svg id="Layer_54" data-name="Layer 54" xmlns="http://www.w3.org/2000/svg" width="9.681" height="8.21" viewBox="0 0 9.681 8.21">
  <path id="Path_8950" data-name="Path 8950" d="M5.876,11.5a.968.968,0,0,1-.688-.284L1.863,7.89a2.815,2.815,0,0,1-.814-2.018A2.6,2.6,0,0,1,3.677,3.29a2.6,2.6,0,0,1,1.876.772l.323.323.265-.265h0a2.709,2.709,0,0,1,3.551-.287,2.663,2.663,0,0,1,.261,3.99l-3.39,3.393A.968.968,0,0,1,5.876,11.5Z" transform="translate(-1.049 -3.29)" fill="#cfcfcf"/>
</svg>
                      </p> }
                    </li>
                    <li className='product-detailslist'>
                      <ul className='product-preview-prdetails'
                       style={{
                        borderRadius:
                        (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                        this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                       }}
                      >
                        <li>
                          <p className='product-preview-name'>Woman yellow solid round neck
longline t-shirt</p>
                        </li>
                        <li className='product-preview-rating'>
                        <svg id="iconfinder_star_216411_2_" data-name="iconfinder_star_216411 (2)" xmlns="http://www.w3.org/2000/svg" width="11.806" height="11.27" viewBox="0 0 11.806 11.27">
  <g id="Group_115" data-name="Group 115" transform="translate(0)">
    <path id="Path_56" data-name="Path 56" d="M7.687,7.659l-3.724.413a.356.356,0,0,0-.3.243.35.35,0,0,0,.1.369c1.107,1.011,2.77,2.525,2.77,2.525s-.457,2.2-.759,3.671a.357.357,0,0,0,.139.358.351.351,0,0,0,.38.02C7.6,14.516,9.551,13.4,9.551,13.4l3.255,1.857a.352.352,0,0,0,.52-.378c-.3-1.47-.757-3.672-.757-3.672l2.77-2.523a.357.357,0,0,0,.1-.371.353.353,0,0,0-.3-.241c-1.49-.167-3.725-.415-3.725-.415L9.873,4.242a.357.357,0,0,0-.322-.208.352.352,0,0,0-.32.208C8.613,5.609,7.687,7.659,7.687,7.659Z" transform="translate(-3.649 -4.034)" fill="#ffca2a"/>
  </g>
</svg>
<p>4.2<span style={{marginLeft:"4px"}}>(53)</span></p>
                        </li>
                        <li className='d-flex justify-content-between product-prev-pricing'>
                          <div>
                          <h3>$59</h3>
                          <h2>$67</h2>
                          
                          </div>
                          {this.props.value.state.show_add_to_cart_on_listing_page && 
                        <div className='product-save-cart'>
                          {/* <svg id="Group_41218" data-name="Group 41218" xmlns="http://www.w3.org/2000/svg" width="17.18" height="14.9" viewBox="0 0 17.18 14.9">
                            <path id="Path_8614" data-name="Path 8614" d="M-4169-1583.91h2.541l1.944,8.885h7.769l1.925-6.439H-4165.8" transform="translate(4169.001 1585.789)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <circle id="Ellipse_3030" data-name="Ellipse 3030" cx="1.106" cy="1.106" r="1.106" transform="translate(4.506 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <circle id="Ellipse_3031" data-name="Ellipse 3031" cx="1.106" cy="1.106" r="1.106" transform="translate(10.033 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <ellipse id="Ellipse_8319" data-name="Ellipse 8319" cx="4.161" cy="4.161" rx="4.161" ry="4.161" transform="translate(8.859 0)" fill="#cfcfcf" />
                            <path id="Union_334" data-name="Union 334" d="M2.148,2.148h0v0h0v0Z" transform="translate(10.957 2.027)" fill="none" stroke="#fff" stroke-width="1" />
                          </svg> */}
                          +
                        </div>}
                        </li>
                      </ul>

                    </li>
                  </ul>

                </div>
                <div className='product-flex-preview-list-page'>
                  <ul
                   style={{
                    borderRadius:
                    (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                    this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                   }}
                  >
                    <li className='product-preview-img' 
                    style={{borderRadius:this.props.value.state.image_shape_on_listing_page==="soft_corner"?"10px":"",
                    height: calculateHeight(136,this.props.value.state.image_ratio_on_listing_page), }}
                    >
                      <h3 className='d-flex product-img-head'>
                        {
                      this.props.value.state.show_out_of_stock &&  <p className='stocklist-flex-page'>10 in Stock</p> }
                        {/* {this.props.value.state.enable_discount_badge &&

                      
<p className={`${this.props.value.state.show_discount_percentage ?" product-percentage-with-text " : "product-percentage"}`} style={{
                        background:this.props.value.state.discount_badge_bg_colour,
                        color:this.props.value.state.discount_badge_text_colour,
                      }}>{this.props.value.state.show_discount_percentage?"12":""}
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11">
    <text id="_" data-name="%" transform="translate(4 8)" fill={this.props.value.state.discount_badge_text_colour} font-size="8" font-family="Poppins-Medium, Poppins" font-weight="500" letter-spacing="0.05em"><tspan x="-3.176" y="0">%</tspan></text>
  </svg>
</p> } */}
 {this.props.value.state.enable_discount_badge &&
                    <>
                    {
                      !this.props.value.state.show_discount_percentage ?

                                          <div className='product-percentage-div'>
                    <svg 
                    
                    xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18">
                      <path id="Path_119102" data-name="Path 119102" d="M0,0V18l3.25-2.057L6.5,18l3.25-2.057L13,18V0Z" transform="translate(0 0)" 
                      fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>%</p>
                    </div>
                    :
                    <div className='product-percentage-text-div'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                      <path id="Path_119101" data-name="Path 119101" d="M122.691,0V18l3.334-2.057L129.357,18l3.333-2.057L136.024,18l3.333-2.057L142.691,18V0Z" transform="translate(-122.691 0)" 
                        fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>12%</p>
                    </div>
                      }
                    </>
                    }


                      </h3>
                      <h3 className='product-prev-image'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
  <g id="Group_42041" data-name="Group 42041" transform="translate(0.261 0.218)">
    <path id="Polygon_58" data-name="Polygon 58" d="M5.5,0,11,7H0Z" transform="translate(-0.261 8.782)" fill="#cfcfcf" opacity="0.5"/>
    <path id="Polygon_59" data-name="Polygon 59" d="M7.5,0,15,9H0Z" transform="translate(6.739 6.782)" fill="#cfcfcf"/>
    <circle id="Ellipse_5321" data-name="Ellipse 5321" cx="2.5" cy="2.5" r="2.5" transform="translate(5.739 -0.218)" fill="#cfcfcf"/>
  </g>
</svg>
                      </h3>
                      {/* {
                      this.props.value.state.show_out_of_stock &&  <p className='stocklist-flex-page'>10 in Stock</p> } */}
                       <p className='product-multi-img'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="5" viewBox="0 0 26 5">
  <g id="Group_42024" data-name="Group 42024" transform="translate(2.008 0.883)">
    <g id="Group_39702" data-name="Group 39702" transform="translate(0)">
      <g id="Ellipse_8337" data-name="Ellipse 8337" transform="translate(-1.008 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8340" data-name="Ellipse 8340" transform="translate(12.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8338" data-name="Ellipse 8338" transform="translate(5.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8339" data-name="Ellipse 8339" transform="translate(19.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
    </g>
  </g>
</svg>
</p>
                       {
                      this.props.value.state.enable_wishlist_on_listing_page &&
                      <p className="product-wishlist">
                        <svg id="Layer_54" data-name="Layer 54" xmlns="http://www.w3.org/2000/svg" width="9.681" height="8.21" viewBox="0 0 9.681 8.21">
  <path id="Path_8950" data-name="Path 8950" d="M5.876,11.5a.968.968,0,0,1-.688-.284L1.863,7.89a2.815,2.815,0,0,1-.814-2.018A2.6,2.6,0,0,1,3.677,3.29a2.6,2.6,0,0,1,1.876.772l.323.323.265-.265h0a2.709,2.709,0,0,1,3.551-.287,2.663,2.663,0,0,1,.261,3.99l-3.39,3.393A.968.968,0,0,1,5.876,11.5Z" transform="translate(-1.049 -3.29)" fill="#cfcfcf"/>
</svg>
                      </p> }
                    </li>
                    <li className='product-detailslist'>
                      <ul className='product-preview-prdetails'
                       style={{
                        borderRadius:
                        (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                        this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                       }}
                      >
                        <li>
                          <p className='product-preview-name'>Woman yellow solid round neck
longline t-shirt</p>
                        </li>
                        <li className='product-preview-rating'>
                        <svg id="iconfinder_star_216411_2_" data-name="iconfinder_star_216411 (2)" xmlns="http://www.w3.org/2000/svg" width="11.806" height="11.27" viewBox="0 0 11.806 11.27">
  <g id="Group_115" data-name="Group 115" transform="translate(0)">
    <path id="Path_56" data-name="Path 56" d="M7.687,7.659l-3.724.413a.356.356,0,0,0-.3.243.35.35,0,0,0,.1.369c1.107,1.011,2.77,2.525,2.77,2.525s-.457,2.2-.759,3.671a.357.357,0,0,0,.139.358.351.351,0,0,0,.38.02C7.6,14.516,9.551,13.4,9.551,13.4l3.255,1.857a.352.352,0,0,0,.52-.378c-.3-1.47-.757-3.672-.757-3.672l2.77-2.523a.357.357,0,0,0,.1-.371.353.353,0,0,0-.3-.241c-1.49-.167-3.725-.415-3.725-.415L9.873,4.242a.357.357,0,0,0-.322-.208.352.352,0,0,0-.32.208C8.613,5.609,7.687,7.659,7.687,7.659Z" transform="translate(-3.649 -4.034)" fill="#ffca2a"/>
  </g>
</svg>
<p>4.2<span style={{marginLeft:"4px"}}>(53)</span></p>
                        </li>
                        <li className='d-flex justify-content-between product-prev-pricing'>
                          <div>
                          <h3>$59</h3>
                          <h2>$67</h2>
                          
                          </div>
                          {this.props.value.state.show_add_to_cart_on_listing_page && 
                        <div className='product-save-cart'>
                          {/* <svg id="Group_41218" data-name="Group 41218" xmlns="http://www.w3.org/2000/svg" width="17.18" height="14.9" viewBox="0 0 17.18 14.9">
                            <path id="Path_8614" data-name="Path 8614" d="M-4169-1583.91h2.541l1.944,8.885h7.769l1.925-6.439H-4165.8" transform="translate(4169.001 1585.789)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <circle id="Ellipse_3030" data-name="Ellipse 3030" cx="1.106" cy="1.106" r="1.106" transform="translate(4.506 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <circle id="Ellipse_3031" data-name="Ellipse 3031" cx="1.106" cy="1.106" r="1.106" transform="translate(10.033 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <ellipse id="Ellipse_8319" data-name="Ellipse 8319" cx="4.161" cy="4.161" rx="4.161" ry="4.161" transform="translate(8.859 0)" fill="#cfcfcf" />
                            <path id="Union_334" data-name="Union 334" d="M2.148,2.148h0v0h0v0Z" transform="translate(10.957 2.027)" fill="none" stroke="#fff" stroke-width="1" />
                          </svg> */}
                          +
                        </div>}
                        </li>
                      </ul>

                    </li>
                  </ul>

                </div>
                <div className='product-flex-preview-list-page'>
                  <ul
                   style={{
                    borderRadius:
                    (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                    this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                   }}
                  >
                    <li className='product-preview-img' 
                    style={{borderRadius:this.props.value.state.image_shape_on_listing_page==="soft_corner"?"10px":"",
                    height: calculateHeight(136,this.props.value.state.image_ratio_on_listing_page), }}
                    >
                      <h3 className='d-flex product-img-head'>
                        {
                      this.props.value.state.show_out_of_stock &&  <p className='stocklist-flex-page'>10 in Stock</p> }
                        {/* {this.props.value.state.enable_discount_badge &&

                      
<p className={`${this.props.value.state.show_discount_percentage ?" product-percentage-with-text " : "product-percentage"}`} style={{
                        background:this.props.value.state.discount_badge_bg_colour,
                        color:this.props.value.state.discount_badge_text_colour,
                      }}>{this.props.value.state.show_discount_percentage?"12":""}
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11">
    <text id="_" data-name="%" transform="translate(4 8)" fill={this.props.value.state.discount_badge_text_colour} font-size="8" font-family="Poppins-Medium, Poppins" font-weight="500" letter-spacing="0.05em"><tspan x="-3.176" y="0">%</tspan></text>
  </svg>
</p> } */}
 {this.props.value.state.enable_discount_badge &&
                    <>
                    {
                      !this.props.value.state.show_discount_percentage ?

                                          <div className='product-percentage-div'>
                    <svg 
                    
                    xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18">
                      <path id="Path_119102" data-name="Path 119102" d="M0,0V18l3.25-2.057L6.5,18l3.25-2.057L13,18V0Z" transform="translate(0 0)" 
                      fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>%</p>
                    </div>
                    :
                    <div className='product-percentage-text-div'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                      <path id="Path_119101" data-name="Path 119101" d="M122.691,0V18l3.334-2.057L129.357,18l3.333-2.057L136.024,18l3.333-2.057L142.691,18V0Z" transform="translate(-122.691 0)" 
                        fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>12%</p>
                    </div>
                      }
                    </>
                    }


                      </h3>
                      <h3 className='product-prev-image'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
  <g id="Group_42041" data-name="Group 42041" transform="translate(0.261 0.218)">
    <path id="Polygon_58" data-name="Polygon 58" d="M5.5,0,11,7H0Z" transform="translate(-0.261 8.782)" fill="#cfcfcf" opacity="0.5"/>
    <path id="Polygon_59" data-name="Polygon 59" d="M7.5,0,15,9H0Z" transform="translate(6.739 6.782)" fill="#cfcfcf"/>
    <circle id="Ellipse_5321" data-name="Ellipse 5321" cx="2.5" cy="2.5" r="2.5" transform="translate(5.739 -0.218)" fill="#cfcfcf"/>
  </g>
</svg>
                      </h3>
                      {/* {
                      this.props.value.state.show_out_of_stock &&  <p className='stocklist-flex-page'>10 in Stock</p> } */}
                       <p className='product-multi-img'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="5" viewBox="0 0 26 5">
  <g id="Group_42024" data-name="Group 42024" transform="translate(2.008 0.883)">
    <g id="Group_39702" data-name="Group 39702" transform="translate(0)">
      <g id="Ellipse_8337" data-name="Ellipse 8337" transform="translate(-1.008 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8340" data-name="Ellipse 8340" transform="translate(12.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8338" data-name="Ellipse 8338" transform="translate(5.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
      <g id="Ellipse_8339" data-name="Ellipse 8339" transform="translate(19.992 0.117)" fill="#fff" stroke="#cfcfcf" stroke-width="1" opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" stroke="none"/>
        <circle cx="1.5" cy="1.5" r="2" fill="none"/>
      </g>
    </g>
  </g>
</svg>
</p>
                       {
                      this.props.value.state.enable_wishlist_on_listing_page &&
                      <p className="product-wishlist">
                        <svg id="Layer_54" data-name="Layer 54" xmlns="http://www.w3.org/2000/svg" width="9.681" height="8.21" viewBox="0 0 9.681 8.21">
  <path id="Path_8950" data-name="Path 8950" d="M5.876,11.5a.968.968,0,0,1-.688-.284L1.863,7.89a2.815,2.815,0,0,1-.814-2.018A2.6,2.6,0,0,1,3.677,3.29a2.6,2.6,0,0,1,1.876.772l.323.323.265-.265h0a2.709,2.709,0,0,1,3.551-.287,2.663,2.663,0,0,1,.261,3.99l-3.39,3.393A.968.968,0,0,1,5.876,11.5Z" transform="translate(-1.049 -3.29)" fill="#cfcfcf"/>
</svg>
                      </p> }
                    </li>
                    <li className='product-detailslist'>
                      <ul className='product-preview-prdetails'
                       style={{
                        borderRadius:
                        (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                        this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                       }}
                      >
                        <li>
                          <p className='product-preview-name'>Woman yellow solid round neck
longline t-shirt</p>
                        </li>
                        <li className='product-preview-rating'>
                        <svg id="iconfinder_star_216411_2_" data-name="iconfinder_star_216411 (2)" xmlns="http://www.w3.org/2000/svg" width="11.806" height="11.27" viewBox="0 0 11.806 11.27">
  <g id="Group_115" data-name="Group 115" transform="translate(0)">
    <path id="Path_56" data-name="Path 56" d="M7.687,7.659l-3.724.413a.356.356,0,0,0-.3.243.35.35,0,0,0,.1.369c1.107,1.011,2.77,2.525,2.77,2.525s-.457,2.2-.759,3.671a.357.357,0,0,0,.139.358.351.351,0,0,0,.38.02C7.6,14.516,9.551,13.4,9.551,13.4l3.255,1.857a.352.352,0,0,0,.52-.378c-.3-1.47-.757-3.672-.757-3.672l2.77-2.523a.357.357,0,0,0,.1-.371.353.353,0,0,0-.3-.241c-1.49-.167-3.725-.415-3.725-.415L9.873,4.242a.357.357,0,0,0-.322-.208.352.352,0,0,0-.32.208C8.613,5.609,7.687,7.659,7.687,7.659Z" transform="translate(-3.649 -4.034)" fill="#ffca2a"/>
  </g>
</svg>
<p>4.2<span style={{marginLeft:"4px"}}>(53)</span></p>
                        </li>
                        <li className='d-flex justify-content-between product-prev-pricing'>
                          <div>
                          <h3>$59</h3>
                          <h2>$67</h2>
                          
                          </div>
                          {this.props.value.state.show_add_to_cart_on_listing_page && 
                        <div className='product-save-cart'>
                          {/* <svg id="Group_41218" data-name="Group 41218" xmlns="http://www.w3.org/2000/svg" width="17.18" height="14.9" viewBox="0 0 17.18 14.9">
                            <path id="Path_8614" data-name="Path 8614" d="M-4169-1583.91h2.541l1.944,8.885h7.769l1.925-6.439H-4165.8" transform="translate(4169.001 1585.789)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <circle id="Ellipse_3030" data-name="Ellipse 3030" cx="1.106" cy="1.106" r="1.106" transform="translate(4.506 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <circle id="Ellipse_3031" data-name="Ellipse 3031" cx="1.106" cy="1.106" r="1.106" transform="translate(10.033 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                            <ellipse id="Ellipse_8319" data-name="Ellipse 8319" cx="4.161" cy="4.161" rx="4.161" ry="4.161" transform="translate(8.859 0)" fill="#cfcfcf" />
                            <path id="Union_334" data-name="Union 334" d="M2.148,2.148h0v0h0v0Z" transform="translate(10.957 2.027)" fill="none" stroke="#fff" stroke-width="1" />
                          </svg> */}
                          +
                        </div>}
                        </li>
                      </ul>

                    </li>
                  </ul>

                </div>
              </div>



            <div className='product-descripton-thirddiv'>
              <ul>
                <li className='products-detilas'>Yellow light wash 5-pocket mid-rise cropped jeans, clean look with no fade, has a button and zip closure, waistband with belt loops Blue light wash 5-pocket mid-rise cropped jeans, clean look with no fade, has a button and zip closure, waistband with belt loops</li>
                <li className='producttype-img'> <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
  <g id="Group_42140" data-name="Group 42140" transform="translate(0.065 0.465)">
    <path id="Polygon_58" data-name="Polygon 58" d="M5.5,0,11,6H0Z" transform="translate(-0.065 9.535)" fill="#cfcfcf" opacity="0.5"/>
    <path id="Polygon_59" data-name="Polygon 59" d="M7.5,0,15,9H0Z" transform="translate(6.935 6.535)" fill="#cfcfcf"/>
    <circle id="Ellipse_5321" data-name="Ellipse 5321" cx="2.5" cy="2.5" r="2.5" transform="translate(5.935 -0.465)" fill="#cfcfcf"/>
  </g>
</svg></li>
                <li ref={this.props.additional_info_ref} className='products-detilas'>Yellow light wash 5-pocket mid-rise cropped jeans, clean look with no fade, has a button and zip.</li>
              </ul>
            </div>


           
            {this.props.value.state.show_additional_information &&
            <div className='product-descripton-fourthdiv' >
              <ul>
                {/* <li className='additional-information'>Additional Information</li> */}
                <li className='fit-type'>
                  <h1>Fit Type</h1>
                  {
                    this.props.value.state.show_additional_information_as_list_on_detail_page ? 
                    <p>
                    <li style={{lineHeight: "15px"}}>
                      Classic Fit
                    </li>
                    <li style={{lineHeight: "15px"}}>
                      Regular fit
                    </li>
                    <li style={{lineHeight: "15px"}}>
                      Slim fit
                    </li>
                  </p>
                  :
                  <p>
                    <li>
                      Classic Fit, Regular fit, Slim fit
                    </li>
                  </p>
               }
                </li>
                <li className='fit-type'>
                  <h1>Color</h1>
                  {
                    this.props.value.state.show_additional_information_as_list_on_detail_page ? 
                    <p>
                    <li style={{lineHeight: "15px"}}>
                      Orange
                    </li>
                    <li style={{lineHeight: "15px"}}>
                      Green
                    </li>
                    <li style={{lineHeight: "15px"}}>
                      Blue
                    </li>
                    <li style={{lineHeight: "15px"}}>
                      White
                    </li>
                  </p> :
                  <p>
                    <li>
                      Orange, Green, Blue, White
                    </li>
                  </p> }
                  
                </li>
                <li className='material'>
                  <h1>Material</h1>
                  {
                    this.props.value.state.show_additional_information_as_list_on_detail_page ? 
                    <p>
                    <li style={{lineHeight: "15px"}}>
                      Cotton
                    </li>
                    <li style={{lineHeight: "15px"}}>
                      Silk
                    </li>
                    <li style={{lineHeight: "15px"}}>
                      Wool
                    </li>
                  </p>
                 : <p>
                    <li>
                      Cotton, Silk, Wool
                    </li>
                  </p>
                  }
                </li>
              </ul> 
            </div> }


            <div className='product-descripton-thirddiv'>
              <ul>
                
                <li className='producttype-img' style={{marginTop: "0px"}}> <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
  <g id="Group_42140" data-name="Group 42140" transform="translate(0.065 0.465)">
    <path id="Polygon_58" data-name="Polygon 58" d="M5.5,0,11,6H0Z" transform="translate(-0.065 9.535)" fill="#cfcfcf" opacity="0.5"/>
    <path id="Polygon_59" data-name="Polygon 59" d="M7.5,0,15,9H0Z" transform="translate(6.935 6.535)" fill="#cfcfcf"/>
    <circle id="Ellipse_5321" data-name="Ellipse 5321" cx="2.5" cy="2.5" r="2.5" transform="translate(5.935 -0.465)" fill="#cfcfcf"/>
  </g>
</svg></li>
<li className='producttype-img'> <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
  <g id="Group_42140" data-name="Group 42140" transform="translate(0.065 0.465)">
    <path id="Polygon_58" data-name="Polygon 58" d="M5.5,0,11,6H0Z" transform="translate(-0.065 9.535)" fill="#cfcfcf" opacity="0.5"/>
    <path id="Polygon_59" data-name="Polygon 59" d="M7.5,0,15,9H0Z" transform="translate(6.935 6.535)" fill="#cfcfcf"/>
    <circle id="Ellipse_5321" data-name="Ellipse 5321" cx="2.5" cy="2.5" r="2.5" transform="translate(5.935 -0.465)" fill="#cfcfcf"/>
  </g>
</svg></li>
<li className='producttype-img' style={{margin: "0px"}}> <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
  <g id="Group_42140" data-name="Group 42140" transform="translate(0.065 0.465)">
    <path id="Polygon_58" data-name="Polygon 58" d="M5.5,0,11,6H0Z" transform="translate(-0.065 9.535)" fill="#cfcfcf" opacity="0.5"/>
    <path id="Polygon_59" data-name="Polygon 59" d="M7.5,0,15,9H0Z" transform="translate(6.935 6.535)" fill="#cfcfcf"/>
    <circle id="Ellipse_5321" data-name="Ellipse 5321" cx="2.5" cy="2.5" r="2.5" transform="translate(5.935 -0.465)" fill="#cfcfcf"/>
  </g>
</svg></li>
        
              </ul>
            </div>
            {/* </div> */}
            <div className='product-descripton-fifthdiv'>
              <ul>
                <li className='d-flex justify-content-between user-total-ratting'>
                  <div>
                  Customer reviews (42)
                    
                  </div>
                  <div className='see-reviewdiv'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="13.946" height="10.725" viewBox="0 0 13.946 10.725">
  <g id="Group_42923" data-name="Group 42923" transform="translate(0 0.53)">
    <path id="Path_103411" data-name="Path 103411" d="M-384.4-3200.5l4.832,4.832-4.832,4.832" transform="translate(392.449 3200.5)" fill="none" stroke="#b0b0b0" stroke-width="1.5"/>
    <path id="Path_103412" data-name="Path 103412" d="M-641.615-3185.5H-654.5" transform="translate(654.5 3190.332)" fill="none" stroke="#b0b0b0" stroke-width="1.5"/>
  </g>
</svg>

                  </div>
                </li>
                <li className='user-comment'>
                <li className='product-username'>Zachary Young
                    <span>Verfied</span>
                  </li>
                  <li className='d-flex justify-content-between dateof-comment'>
                    <div className='ratting-count'>
                    <svg id="Group_41525" data-name="Group 41525" xmlns="http://www.w3.org/2000/svg" width="76.039" height="11.808" viewBox="0 0 76.039 11.808">
                    <g id="iconfinder_star_216411_2_" data-name="iconfinder_star_216411 (2)" transform="translate(0 0)">
                      <g id="Group_40369" data-name="Group 40369">
                        <path id="Path_107584" data-name="Path 107584" d="M7.89,7.832l-3.911.433a.374.374,0,0,0-.313.254.366.366,0,0,0,.1.386C4.933,9.964,6.68,11.55,6.68,11.55s-.48,2.307-.8,3.847a.373.373,0,0,0,.146.375.369.369,0,0,0,.4.021c1.369-.777,3.419-1.945,3.419-1.945l3.418,1.946a.37.37,0,0,0,.546-.4c-.318-1.54-.8-3.848-.8-3.848l2.909-2.643a.369.369,0,0,0-.208-.641c-1.565-.175-3.912-.434-3.912-.434l-1.621-3.58a.375.375,0,0,0-.339-.218.37.37,0,0,0-.336.218C8.862,5.684,7.89,7.832,7.89,7.832Z" transform="translate(-3.649 -4.034)" fill="#ffca2a" />
                      </g>
                    </g>
                    <g id="iconfinder_star_216411_2_2" data-name="iconfinder_star_216411 (2)" transform="translate(15.91 0)">
                      <g id="Group_40370" data-name="Group 40370" transform="translate(0)">
                        <path id="Path_107585" data-name="Path 107585" d="M7.89,7.832l-3.911.433a.374.374,0,0,0-.313.254.366.366,0,0,0,.1.386C4.933,9.964,6.68,11.55,6.68,11.55s-.48,2.307-.8,3.847a.373.373,0,0,0,.146.375.369.369,0,0,0,.4.021c1.369-.777,3.419-1.945,3.419-1.945l3.418,1.946a.37.37,0,0,0,.546-.4c-.318-1.54-.8-3.848-.8-3.848l2.909-2.643a.369.369,0,0,0-.208-.641c-1.565-.175-3.912-.434-3.912-.434l-1.621-3.58a.375.375,0,0,0-.339-.218.37.37,0,0,0-.336.218C8.862,5.684,7.89,7.832,7.89,7.832Z" transform="translate(-3.649 -4.034)" fill="#ffca2a" />
                      </g>
                    </g>
                    <g id="iconfinder_star_216411_2_3" data-name="iconfinder_star_216411 (2)" transform="translate(31.82 0)">
                      <g id="Group_40371" data-name="Group 40371" transform="translate(0)">
                        <path id="Path_107586" data-name="Path 107586" d="M7.89,7.832l-3.911.433a.374.374,0,0,0-.313.254.366.366,0,0,0,.1.386C4.933,9.964,6.68,11.55,6.68,11.55s-.48,2.307-.8,3.847a.373.373,0,0,0,.146.375.369.369,0,0,0,.4.021c1.369-.777,3.419-1.945,3.419-1.945l3.418,1.946a.37.37,0,0,0,.546-.4c-.318-1.54-.8-3.848-.8-3.848l2.909-2.643a.369.369,0,0,0-.208-.641c-1.565-.175-3.912-.434-3.912-.434l-1.621-3.58a.375.375,0,0,0-.339-.218.37.37,0,0,0-.336.218C8.862,5.684,7.89,7.832,7.89,7.832Z" transform="translate(-3.649 -4.034)" fill="#ffca2a" />
                      </g>
                    </g>
                    <g id="iconfinder_star_216411_2_4" data-name="iconfinder_star_216411 (2)" transform="translate(47.73 0)">
                      <g id="Group_40372" data-name="Group 40372" transform="translate(0)">
                        <path id="Path_107587" data-name="Path 107587" d="M7.89,7.832l-3.911.433a.374.374,0,0,0-.313.254.366.366,0,0,0,.1.386C4.933,9.964,6.68,11.55,6.68,11.55s-.48,2.307-.8,3.847a.373.373,0,0,0,.146.375.369.369,0,0,0,.4.021c1.369-.777,3.419-1.945,3.419-1.945l3.418,1.946a.37.37,0,0,0,.546-.4c-.318-1.54-.8-3.848-.8-3.848l2.909-2.643a.369.369,0,0,0-.208-.641c-1.565-.175-3.912-.434-3.912-.434l-1.621-3.58a.375.375,0,0,0-.339-.218.37.37,0,0,0-.336.218C8.862,5.684,7.89,7.832,7.89,7.832Z" transform="translate(-3.649 -4.034)" fill="#ffca2a" />
                      </g>
                    </g>
                    <g id="iconfinder_star_216411_2_5" data-name="iconfinder_star_216411 (2)" transform="translate(63.64 0)">
                      <g id="Group_40373" data-name="Group 40373" transform="translate(0)">
                        <path id="Path_107588" data-name="Path 107588" d="M7.89,7.832l-3.911.433a.374.374,0,0,0-.313.254.366.366,0,0,0,.1.386C4.933,9.964,6.68,11.55,6.68,11.55s-.48,2.307-.8,3.847a.373.373,0,0,0,.146.375.369.369,0,0,0,.4.021c1.369-.777,3.419-1.945,3.419-1.945l3.418,1.946a.37.37,0,0,0,.546-.4c-.318-1.54-.8-3.848-.8-3.848l2.909-2.643a.369.369,0,0,0-.208-.641c-1.565-.175-3.912-.434-3.912-.434l-1.621-3.58a.375.375,0,0,0-.339-.218.37.37,0,0,0-.336.218C8.862,5.684,7.89,7.832,7.89,7.832Z" transform="translate(-3.649 -4.034)" fill="#e4e4e4" />
                      </g>
                    </g>
                  </svg>
                  4.5
                  </div>
                    <p>12 August, 2018</p>
                  </li>
                  <li className='user-comment-text'>Bought this lovely dress and paired it with white sneakers to complete the look. Super happy with this look!</li>
                </li>
                <li className='user-comment'>
                  <li className='product-username'>Zachary Young
                    <span>Verfied</span>
                  </li>
                  <li className='d-flex justify-content-between dateof-comment'>
                    <div className='ratting-count'>
                    <svg id="Group_41525" data-name="Group 41525" xmlns="http://www.w3.org/2000/svg" width="76.039" height="11.808" viewBox="0 0 76.039 11.808">
                    <g id="iconfinder_star_216411_2_" data-name="iconfinder_star_216411 (2)" transform="translate(0 0)">
                      <g id="Group_40369" data-name="Group 40369">
                        <path id="Path_107584" data-name="Path 107584" d="M7.89,7.832l-3.911.433a.374.374,0,0,0-.313.254.366.366,0,0,0,.1.386C4.933,9.964,6.68,11.55,6.68,11.55s-.48,2.307-.8,3.847a.373.373,0,0,0,.146.375.369.369,0,0,0,.4.021c1.369-.777,3.419-1.945,3.419-1.945l3.418,1.946a.37.37,0,0,0,.546-.4c-.318-1.54-.8-3.848-.8-3.848l2.909-2.643a.369.369,0,0,0-.208-.641c-1.565-.175-3.912-.434-3.912-.434l-1.621-3.58a.375.375,0,0,0-.339-.218.37.37,0,0,0-.336.218C8.862,5.684,7.89,7.832,7.89,7.832Z" transform="translate(-3.649 -4.034)" fill="#ffca2a" />
                      </g>
                    </g>
                    <g id="iconfinder_star_216411_2_2" data-name="iconfinder_star_216411 (2)" transform="translate(15.91 0)">
                      <g id="Group_40370" data-name="Group 40370" transform="translate(0)">
                        <path id="Path_107585" data-name="Path 107585" d="M7.89,7.832l-3.911.433a.374.374,0,0,0-.313.254.366.366,0,0,0,.1.386C4.933,9.964,6.68,11.55,6.68,11.55s-.48,2.307-.8,3.847a.373.373,0,0,0,.146.375.369.369,0,0,0,.4.021c1.369-.777,3.419-1.945,3.419-1.945l3.418,1.946a.37.37,0,0,0,.546-.4c-.318-1.54-.8-3.848-.8-3.848l2.909-2.643a.369.369,0,0,0-.208-.641c-1.565-.175-3.912-.434-3.912-.434l-1.621-3.58a.375.375,0,0,0-.339-.218.37.37,0,0,0-.336.218C8.862,5.684,7.89,7.832,7.89,7.832Z" transform="translate(-3.649 -4.034)" fill="#ffca2a" />
                      </g>
                    </g>
                    <g id="iconfinder_star_216411_2_3" data-name="iconfinder_star_216411 (2)" transform="translate(31.82 0)">
                      <g id="Group_40371" data-name="Group 40371" transform="translate(0)">
                        <path id="Path_107586" data-name="Path 107586" d="M7.89,7.832l-3.911.433a.374.374,0,0,0-.313.254.366.366,0,0,0,.1.386C4.933,9.964,6.68,11.55,6.68,11.55s-.48,2.307-.8,3.847a.373.373,0,0,0,.146.375.369.369,0,0,0,.4.021c1.369-.777,3.419-1.945,3.419-1.945l3.418,1.946a.37.37,0,0,0,.546-.4c-.318-1.54-.8-3.848-.8-3.848l2.909-2.643a.369.369,0,0,0-.208-.641c-1.565-.175-3.912-.434-3.912-.434l-1.621-3.58a.375.375,0,0,0-.339-.218.37.37,0,0,0-.336.218C8.862,5.684,7.89,7.832,7.89,7.832Z" transform="translate(-3.649 -4.034)" fill="#ffca2a" />
                      </g>
                    </g>
                    <g id="iconfinder_star_216411_2_4" data-name="iconfinder_star_216411 (2)" transform="translate(47.73 0)">
                      <g id="Group_40372" data-name="Group 40372" transform="translate(0)">
                        <path id="Path_107587" data-name="Path 107587" d="M7.89,7.832l-3.911.433a.374.374,0,0,0-.313.254.366.366,0,0,0,.1.386C4.933,9.964,6.68,11.55,6.68,11.55s-.48,2.307-.8,3.847a.373.373,0,0,0,.146.375.369.369,0,0,0,.4.021c1.369-.777,3.419-1.945,3.419-1.945l3.418,1.946a.37.37,0,0,0,.546-.4c-.318-1.54-.8-3.848-.8-3.848l2.909-2.643a.369.369,0,0,0-.208-.641c-1.565-.175-3.912-.434-3.912-.434l-1.621-3.58a.375.375,0,0,0-.339-.218.37.37,0,0,0-.336.218C8.862,5.684,7.89,7.832,7.89,7.832Z" transform="translate(-3.649 -4.034)" fill="#ffca2a" />
                      </g>
                    </g>
                    <g id="iconfinder_star_216411_2_5" data-name="iconfinder_star_216411 (2)" transform="translate(63.64 0)">
                      <g id="Group_40373" data-name="Group 40373" transform="translate(0)">
                        <path id="Path_107588" data-name="Path 107588" d="M7.89,7.832l-3.911.433a.374.374,0,0,0-.313.254.366.366,0,0,0,.1.386C4.933,9.964,6.68,11.55,6.68,11.55s-.48,2.307-.8,3.847a.373.373,0,0,0,.146.375.369.369,0,0,0,.4.021c1.369-.777,3.419-1.945,3.419-1.945l3.418,1.946a.37.37,0,0,0,.546-.4c-.318-1.54-.8-3.848-.8-3.848l2.909-2.643a.369.369,0,0,0-.208-.641c-1.565-.175-3.912-.434-3.912-.434l-1.621-3.58a.375.375,0,0,0-.339-.218.37.37,0,0,0-.336.218C8.862,5.684,7.89,7.832,7.89,7.832Z" transform="translate(-3.649 -4.034)" fill="#e4e4e4" />
                      </g>
                    </g>
                  </svg>
                  4.5
                  </div>
                    <p>12 August, 2018</p>
                  </li>
                  <li className='user-comment-text'>Picked this trendy dress for my next beach party look. Love the colors and perfectly fits on me. </li>
                </li>
                <li className='user-comment'>
                <li className='product-username'>Zachary Young
                    <span>Verfied</span>
                  </li>
                  <li   className='d-flex justify-content-between dateof-comment'>
                    <div className='ratting-count'>
                    <svg id="Group_41525" data-name="Group 41525" xmlns="http://www.w3.org/2000/svg" width="76.039" height="11.808" viewBox="0 0 76.039 11.808">
                    <g id="iconfinder_star_216411_2_" data-name="iconfinder_star_216411 (2)" transform="translate(0 0)">
                      <g id="Group_40369" data-name="Group 40369">
                        <path id="Path_107584" data-name="Path 107584" d="M7.89,7.832l-3.911.433a.374.374,0,0,0-.313.254.366.366,0,0,0,.1.386C4.933,9.964,6.68,11.55,6.68,11.55s-.48,2.307-.8,3.847a.373.373,0,0,0,.146.375.369.369,0,0,0,.4.021c1.369-.777,3.419-1.945,3.419-1.945l3.418,1.946a.37.37,0,0,0,.546-.4c-.318-1.54-.8-3.848-.8-3.848l2.909-2.643a.369.369,0,0,0-.208-.641c-1.565-.175-3.912-.434-3.912-.434l-1.621-3.58a.375.375,0,0,0-.339-.218.37.37,0,0,0-.336.218C8.862,5.684,7.89,7.832,7.89,7.832Z" transform="translate(-3.649 -4.034)" fill="#ffca2a" />
                      </g>
                    </g>
                    <g id="iconfinder_star_216411_2_2" data-name="iconfinder_star_216411 (2)" transform="translate(15.91 0)">
                      <g id="Group_40370" data-name="Group 40370" transform="translate(0)">
                        <path id="Path_107585" data-name="Path 107585" d="M7.89,7.832l-3.911.433a.374.374,0,0,0-.313.254.366.366,0,0,0,.1.386C4.933,9.964,6.68,11.55,6.68,11.55s-.48,2.307-.8,3.847a.373.373,0,0,0,.146.375.369.369,0,0,0,.4.021c1.369-.777,3.419-1.945,3.419-1.945l3.418,1.946a.37.37,0,0,0,.546-.4c-.318-1.54-.8-3.848-.8-3.848l2.909-2.643a.369.369,0,0,0-.208-.641c-1.565-.175-3.912-.434-3.912-.434l-1.621-3.58a.375.375,0,0,0-.339-.218.37.37,0,0,0-.336.218C8.862,5.684,7.89,7.832,7.89,7.832Z" transform="translate(-3.649 -4.034)" fill="#ffca2a" />
                      </g>
                    </g>
                    <g id="iconfinder_star_216411_2_3" data-name="iconfinder_star_216411 (2)" transform="translate(31.82 0)">
                      <g id="Group_40371" data-name="Group 40371" transform="translate(0)">
                        <path id="Path_107586" data-name="Path 107586" d="M7.89,7.832l-3.911.433a.374.374,0,0,0-.313.254.366.366,0,0,0,.1.386C4.933,9.964,6.68,11.55,6.68,11.55s-.48,2.307-.8,3.847a.373.373,0,0,0,.146.375.369.369,0,0,0,.4.021c1.369-.777,3.419-1.945,3.419-1.945l3.418,1.946a.37.37,0,0,0,.546-.4c-.318-1.54-.8-3.848-.8-3.848l2.909-2.643a.369.369,0,0,0-.208-.641c-1.565-.175-3.912-.434-3.912-.434l-1.621-3.58a.375.375,0,0,0-.339-.218.37.37,0,0,0-.336.218C8.862,5.684,7.89,7.832,7.89,7.832Z" transform="translate(-3.649 -4.034)" fill="#ffca2a" />
                      </g>
                    </g>
                    <g id="iconfinder_star_216411_2_4" data-name="iconfinder_star_216411 (2)" transform="translate(47.73 0)">
                      <g id="Group_40372" data-name="Group 40372" transform="translate(0)">
                        <path id="Path_107587" data-name="Path 107587" d="M7.89,7.832l-3.911.433a.374.374,0,0,0-.313.254.366.366,0,0,0,.1.386C4.933,9.964,6.68,11.55,6.68,11.55s-.48,2.307-.8,3.847a.373.373,0,0,0,.146.375.369.369,0,0,0,.4.021c1.369-.777,3.419-1.945,3.419-1.945l3.418,1.946a.37.37,0,0,0,.546-.4c-.318-1.54-.8-3.848-.8-3.848l2.909-2.643a.369.369,0,0,0-.208-.641c-1.565-.175-3.912-.434-3.912-.434l-1.621-3.58a.375.375,0,0,0-.339-.218.37.37,0,0,0-.336.218C8.862,5.684,7.89,7.832,7.89,7.832Z" transform="translate(-3.649 -4.034)" fill="#ffca2a" />
                      </g>
                    </g>
                    <g id="iconfinder_star_216411_2_5" data-name="iconfinder_star_216411 (2)" transform="translate(63.64 0)">
                      <g id="Group_40373" data-name="Group 40373" transform="translate(0)">
                        <path id="Path_107588" data-name="Path 107588" d="M7.89,7.832l-3.911.433a.374.374,0,0,0-.313.254.366.366,0,0,0,.1.386C4.933,9.964,6.68,11.55,6.68,11.55s-.48,2.307-.8,3.847a.373.373,0,0,0,.146.375.369.369,0,0,0,.4.021c1.369-.777,3.419-1.945,3.419-1.945l3.418,1.946a.37.37,0,0,0,.546-.4c-.318-1.54-.8-3.848-.8-3.848l2.909-2.643a.369.369,0,0,0-.208-.641c-1.565-.175-3.912-.434-3.912-.434l-1.621-3.58a.375.375,0,0,0-.339-.218.37.37,0,0,0-.336.218C8.862,5.684,7.89,7.832,7.89,7.832Z" transform="translate(-3.649 -4.034)" fill="#e4e4e4" />
                      </g>
                    </g>
                  </svg>
                  4.5
                  </div>
                    <p >12 August, 2018</p>
                  </li>
                  <li className='user-comment-text'>I am so happy to have this cute chic dress in my wardrobe. I paired this dress with a criss-cross bag and white sneakers. The colors and quality is perfect!</li>
                </li>
                <li 
               
                
                className='writereview-btn'>
                  {/* <svg   id="Layer_42" data-name="Layer 42" xmlns="http://www.w3.org/2000/svg" width="11.542" height="11.54" viewBox="0 0 11.542 11.54">
                    <path id="Path_107570" data-name="Path 107570" d="M1.44,14.54A.444.444,0,0,1,1,14.047l.342-3.107a.444.444,0,0,1,.129-.262L8.728,3.417a1.42,1.42,0,0,1,2.011,0l1.38,1.38a1.42,1.42,0,0,1,0,2.011L4.862,14.065a.444.444,0,0,1-.262.129l-3.107.342Zm3.107-.79ZM2.208,11.189l-.266,2.406,2.406-.266,7.146-7.146a.533.533,0,0,0,0-.755L10.109,4.043a.533.533,0,0,0-.755,0Z" transform="translate(-0.994 -3)" fill="#a1a1a1" />
                    <path id="Path_107571" data-name="Path 107571" d="M18.988,9.631a.444.444,0,0,1-.315-.129L15.916,6.737a.446.446,0,1,1,.63-.63l2.765,2.765a.444.444,0,0,1-.324.759Z" transform="translate(-9.221 -4.655)" fill="#a1a1a1" />
                  </svg> */}

                  Write a review
                </li>
              </ul>
            </div >
       
            <div ref={this.props.related_products_ref}>
          {
            this.props.value.state.show_related_products && 
            <div 
            // ref={this.props.related_products_ref}
             className='product-descripton-sixdiv' 
            // style={{
            //   display:!this.props.value.state.show_related_products?"none":""
            // }}
            >
<div className='product-preview-list-page'>
                <ul
                                style={{
                                  borderRadius:
                                  (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                                  this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                                 }}
                >
                  <li className='product-preview-img' 
                  style={{borderRadius:this.props.value.state.image_shape_on_listing_page==="soft_corner"?"10px":"",
                  height: calculateHeight(100,this.props.value.state.image_ratio_on_listing_page),}}
                  >
                    <h3 className='d-flex product-img-head'>
                     {
                      this.props.value.state.enable_wishlist_on_listing_page &&
                      <p className="product-wishlist">
                        <svg id="Layer_54" data-name="Layer 54" xmlns="http://www.w3.org/2000/svg" width="9.681" height="8.21" viewBox="0 0 9.681 8.21">
  <path id="Path_8950" data-name="Path 8950" d="M5.876,11.5a.968.968,0,0,1-.688-.284L1.863,7.89a2.815,2.815,0,0,1-.814-2.018A2.6,2.6,0,0,1,3.677,3.29a2.6,2.6,0,0,1,1.876.772l.323.323.265-.265h0a2.709,2.709,0,0,1,3.551-.287,2.663,2.663,0,0,1,.261,3.99l-3.39,3.393A.968.968,0,0,1,5.876,11.5Z" transform="translate(-1.049 -3.29)" fill="#cfcfcf"/>
</svg>
                      </p> }
                      {/* {this.props.value.state.enable_discount_badge &&

                      
                      <p className={`${this.props.value.state.show_discount_percentage ?" product-percentage-with-text " : "product-percentage"}`} style={{
                        background:this.props.value.state.discount_badge_bg_colour,
                        color:this.props.value.state.discount_badge_text_colour,
                      }}>{this.props.value.state.show_discount_percentage?"12":""}
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11">
                          <text id="_" data-name="%" transform="translate(4 8)" fill={this.props.value.state.discount_badge_text_colour} font-size="8" font-family="Poppins-Medium, Poppins" font-weight="500" letter-spacing="0.05em"><tspan x="-3.176" y="0">%</tspan></text>
                        </svg>
                      </p> } */}
 {this.props.value.state.enable_discount_badge &&
                    <>
                    {
                      !this.props.value.state.show_discount_percentage ?

                                          <div className='product-percentage-div'>
                    <svg 
                    
                    xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18">
                      <path id="Path_119102" data-name="Path 119102" d="M0,0V18l3.25-2.057L6.5,18l3.25-2.057L13,18V0Z" transform="translate(0 0)" 
                      fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>%</p>
                    </div>
                    :
                    <div className='product-percentage-text-div'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                      <path id="Path_119101" data-name="Path 119101" d="M122.691,0V18l3.334-2.057L129.357,18l3.333-2.057L136.024,18l3.333-2.057L142.691,18V0Z" transform="translate(-122.691 0)" 
                        fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>12%</p>
                    </div>
                      }
                    </>
                    }
                    </h3>
                    <h3 className='product-prev-image'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
  <g id="Group_42041" data-name="Group 42041" transform="translate(0.261 0.218)">
    <path id="Polygon_58" data-name="Polygon 58" d="M5.5,0,11,7H0Z" transform="translate(-0.261 8.782)" fill="#cfcfcf" opacity="0.5"/>
    <path id="Polygon_59" data-name="Polygon 59" d="M7.5,0,15,9H0Z" transform="translate(6.739 6.782)" fill="#cfcfcf"/>
    <circle id="Ellipse_5321" data-name="Ellipse 5321" cx="2.5" cy="2.5" r="2.5" transform="translate(5.739 -0.218)" fill="#cfcfcf"/>
  </g>
</svg>
                    </h3>
                  </li>
                  <li className='product-detailslist grid-product-detailslist'>
                    <ul className='product-preview-prdetails'
                     style={{
                      borderRadius:
                      (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                      this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                     }}
                    >
                      <li>
                        <p className='product-preview-name'>Woman yellow solid round neck
longline t-shirt</p>
                      </li>
                      <li className='product-preview-rating'>
                      <svg id="iconfinder_star_216411_2_" data-name="iconfinder_star_216411 (2)" xmlns="http://www.w3.org/2000/svg" width="11.806" height="11.27" viewBox="0 0 11.806 11.27">
  <g id="Group_115" data-name="Group 115" transform="translate(0)">
    <path id="Path_56" data-name="Path 56" d="M7.687,7.659l-3.724.413a.356.356,0,0,0-.3.243.35.35,0,0,0,.1.369c1.107,1.011,2.77,2.525,2.77,2.525s-.457,2.2-.759,3.671a.357.357,0,0,0,.139.358.351.351,0,0,0,.38.02C7.6,14.516,9.551,13.4,9.551,13.4l3.255,1.857a.352.352,0,0,0,.52-.378c-.3-1.47-.757-3.672-.757-3.672l2.77-2.523a.357.357,0,0,0,.1-.371.353.353,0,0,0-.3-.241c-1.49-.167-3.725-.415-3.725-.415L9.873,4.242a.357.357,0,0,0-.322-.208.352.352,0,0,0-.32.208C8.613,5.609,7.687,7.659,7.687,7.659Z" transform="translate(-3.649 -4.034)" fill="#ffca2a"/>
  </g>
</svg>
<p>4.2<span style={{marginLeft:"4px"}}>(53)</span></p>

                      </li>
                      <li className='d-flex product-prev-pricing'>
                        
                        <h3>$59</h3>
                        <h2>$67</h2>
                       
                      </li>
                      <div className='grid-add-tocart'>
                        <div>
                      {
                      this.props.value.state.show_out_of_stock && <p>10 in Stock</p> }
                       </div>
                      {this.props.value.state.show_add_to_cart_on_listing_page &&                       
                      <li className='product-save-cart'>
                        {/* <svg id="Group_41218" data-name="Group 41218" xmlns="http://www.w3.org/2000/svg" width="17.18" height="14.9" viewBox="0 0 17.18 14.9">
                          <path id="Path_8614" data-name="Path 8614" d="M-4169-1583.91h2.541l1.944,8.885h7.769l1.925-6.439H-4165.8" transform="translate(4169.001 1585.789)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <circle id="Ellipse_3030" data-name="Ellipse 3030" cx="1.106" cy="1.106" r="1.106" transform="translate(4.506 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <circle id="Ellipse_3031" data-name="Ellipse 3031" cx="1.106" cy="1.106" r="1.106" transform="translate(10.033 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <ellipse id="Ellipse_8319" data-name="Ellipse 8319" cx="4.161" cy="4.161" rx="4.161" ry="4.161" transform="translate(8.859 0)" fill="#cfcfcf" />
                          <path id="Union_334" data-name="Union 334" d="M2.148,2.148h0v0h0v0Z" transform="translate(10.957 2.027)" fill="none" stroke="#fff" stroke-width="1" />
                        </svg> */}
                        +
                      </li> }

                      </div>
                    </ul>

                  </li>
                </ul>

              </div>
              <div className='product-preview-list-page'>
                <ul
                                style={{
                                  borderRadius:
                                  (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                                  this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                                 }}
                >
                  <li className='product-preview-img' 
                  style={{borderRadius:this.props.value.state.image_shape_on_listing_page==="soft_corner"?"10px":"",
                  height: calculateHeight(100,this.props.value.state.image_ratio_on_listing_page),}}
                  >
                    <h3 className='d-flex product-img-head'>
                     {
                      this.props.value.state.enable_wishlist_on_listing_page &&
                      <p className="product-wishlist">
                        <svg id="Layer_54" data-name="Layer 54" xmlns="http://www.w3.org/2000/svg" width="9.681" height="8.21" viewBox="0 0 9.681 8.21">
  <path id="Path_8950" data-name="Path 8950" d="M5.876,11.5a.968.968,0,0,1-.688-.284L1.863,7.89a2.815,2.815,0,0,1-.814-2.018A2.6,2.6,0,0,1,3.677,3.29a2.6,2.6,0,0,1,1.876.772l.323.323.265-.265h0a2.709,2.709,0,0,1,3.551-.287,2.663,2.663,0,0,1,.261,3.99l-3.39,3.393A.968.968,0,0,1,5.876,11.5Z" transform="translate(-1.049 -3.29)" fill="#cfcfcf"/>
</svg>
                      </p> }
                      {/* {this.props.value.state.enable_discount_badge &&

                      
                      <p className={`${this.props.value.state.show_discount_percentage ?" product-percentage-with-text " : "product-percentage"}`} style={{
                        background:this.props.value.state.discount_badge_bg_colour,
                        color:this.props.value.state.discount_badge_text_colour,
                      }}>{this.props.value.state.show_discount_percentage?"12":""}
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11">
                          <text id="_" data-name="%" transform="translate(4 8)" fill={this.props.value.state.discount_badge_text_colour} font-size="8" font-family="Poppins-Medium, Poppins" font-weight="500" letter-spacing="0.05em"><tspan x="-3.176" y="0">%</tspan></text>
                        </svg>
                      </p> } */}
 {this.props.value.state.enable_discount_badge &&
                    <>
                    {
                      !this.props.value.state.show_discount_percentage ?

                                          <div className='product-percentage-div'>
                    <svg 
                    
                    xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18">
                      <path id="Path_119102" data-name="Path 119102" d="M0,0V18l3.25-2.057L6.5,18l3.25-2.057L13,18V0Z" transform="translate(0 0)" 
                      fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>%</p>
                    </div>
                    :
                    <div className='product-percentage-text-div'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                      <path id="Path_119101" data-name="Path 119101" d="M122.691,0V18l3.334-2.057L129.357,18l3.333-2.057L136.024,18l3.333-2.057L142.691,18V0Z" transform="translate(-122.691 0)" 
                        fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>12%</p>
                    </div>
                      }
                    </>
                    }
                    </h3>
                    <h3 className='product-prev-image'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
  <g id="Group_42041" data-name="Group 42041" transform="translate(0.261 0.218)">
    <path id="Polygon_58" data-name="Polygon 58" d="M5.5,0,11,7H0Z" transform="translate(-0.261 8.782)" fill="#cfcfcf" opacity="0.5"/>
    <path id="Polygon_59" data-name="Polygon 59" d="M7.5,0,15,9H0Z" transform="translate(6.739 6.782)" fill="#cfcfcf"/>
    <circle id="Ellipse_5321" data-name="Ellipse 5321" cx="2.5" cy="2.5" r="2.5" transform="translate(5.739 -0.218)" fill="#cfcfcf"/>
  </g>
</svg>
                    </h3>
                  </li>
                  <li className='product-detailslist grid-product-detailslist'>
                    <ul className='product-preview-prdetails'
                     style={{
                      borderRadius:
                      (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                      this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                     }}
                    >
                      <li>
                        <p className='product-preview-name'>Woman yellow solid round neck
longline t-shirt</p>
                      </li>
                      <li className='product-preview-rating'>
                      <svg id="iconfinder_star_216411_2_" data-name="iconfinder_star_216411 (2)" xmlns="http://www.w3.org/2000/svg" width="11.806" height="11.27" viewBox="0 0 11.806 11.27">
  <g id="Group_115" data-name="Group 115" transform="translate(0)">
    <path id="Path_56" data-name="Path 56" d="M7.687,7.659l-3.724.413a.356.356,0,0,0-.3.243.35.35,0,0,0,.1.369c1.107,1.011,2.77,2.525,2.77,2.525s-.457,2.2-.759,3.671a.357.357,0,0,0,.139.358.351.351,0,0,0,.38.02C7.6,14.516,9.551,13.4,9.551,13.4l3.255,1.857a.352.352,0,0,0,.52-.378c-.3-1.47-.757-3.672-.757-3.672l2.77-2.523a.357.357,0,0,0,.1-.371.353.353,0,0,0-.3-.241c-1.49-.167-3.725-.415-3.725-.415L9.873,4.242a.357.357,0,0,0-.322-.208.352.352,0,0,0-.32.208C8.613,5.609,7.687,7.659,7.687,7.659Z" transform="translate(-3.649 -4.034)" fill="#ffca2a"/>
  </g>
</svg>
<p>4.2<span style={{marginLeft:"4px"}}>(53)</span></p>

                      </li>
                      <li className='d-flex product-prev-pricing'>
                        
                        <h3>$59</h3>
                        <h2>$67</h2>
                       
                      </li>
                      <div className='grid-add-tocart'>
                        <div>
                      {
                      this.props.value.state.show_out_of_stock && <p>10 in Stock</p> }
                       </div>
                      {this.props.value.state.show_add_to_cart_on_listing_page &&                       
                      <li className='product-save-cart'>
                        {/* <svg id="Group_41218" data-name="Group 41218" xmlns="http://www.w3.org/2000/svg" width="17.18" height="14.9" viewBox="0 0 17.18 14.9">
                          <path id="Path_8614" data-name="Path 8614" d="M-4169-1583.91h2.541l1.944,8.885h7.769l1.925-6.439H-4165.8" transform="translate(4169.001 1585.789)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <circle id="Ellipse_3030" data-name="Ellipse 3030" cx="1.106" cy="1.106" r="1.106" transform="translate(4.506 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <circle id="Ellipse_3031" data-name="Ellipse 3031" cx="1.106" cy="1.106" r="1.106" transform="translate(10.033 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <ellipse id="Ellipse_8319" data-name="Ellipse 8319" cx="4.161" cy="4.161" rx="4.161" ry="4.161" transform="translate(8.859 0)" fill="#cfcfcf" />
                          <path id="Union_334" data-name="Union 334" d="M2.148,2.148h0v0h0v0Z" transform="translate(10.957 2.027)" fill="none" stroke="#fff" stroke-width="1" />
                        </svg> */}
                        +
                      </li> }

                      </div>
                    </ul>

                  </li>
                </ul>

              </div>
              <div className='product-preview-list-page'>
                <ul
                                style={{
                                  borderRadius:
                                  (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                                  this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                                 }}
                >
                  <li className='product-preview-img' 
                  style={{borderRadius:this.props.value.state.image_shape_on_listing_page==="soft_corner"?"10px":"",
                  height: calculateHeight(100,this.props.value.state.image_ratio_on_listing_page),}}
                  >
                    <h3 className='d-flex product-img-head'>
                     {
                      this.props.value.state.enable_wishlist_on_listing_page &&
                      <p className="product-wishlist">
                        <svg id="Layer_54" data-name="Layer 54" xmlns="http://www.w3.org/2000/svg" width="9.681" height="8.21" viewBox="0 0 9.681 8.21">
  <path id="Path_8950" data-name="Path 8950" d="M5.876,11.5a.968.968,0,0,1-.688-.284L1.863,7.89a2.815,2.815,0,0,1-.814-2.018A2.6,2.6,0,0,1,3.677,3.29a2.6,2.6,0,0,1,1.876.772l.323.323.265-.265h0a2.709,2.709,0,0,1,3.551-.287,2.663,2.663,0,0,1,.261,3.99l-3.39,3.393A.968.968,0,0,1,5.876,11.5Z" transform="translate(-1.049 -3.29)" fill="#cfcfcf"/>
</svg>
                      </p> }
                      {/* {this.props.value.state.enable_discount_badge &&

                      
                      <p className={`${this.props.value.state.show_discount_percentage ?" product-percentage-with-text " : "product-percentage"}`} style={{
                        background:this.props.value.state.discount_badge_bg_colour,
                        color:this.props.value.state.discount_badge_text_colour,
                      }}>{this.props.value.state.show_discount_percentage?"12":""}
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="11" viewBox="0 0 8 11">
                          <text id="_" data-name="%" transform="translate(4 8)" fill={this.props.value.state.discount_badge_text_colour} font-size="8" font-family="Poppins-Medium, Poppins" font-weight="500" letter-spacing="0.05em"><tspan x="-3.176" y="0">%</tspan></text>
                        </svg>
                      </p> } */}
 {this.props.value.state.enable_discount_badge &&
                    <>
                    {
                      !this.props.value.state.show_discount_percentage ?

                                          <div className='product-percentage-div'>
                    <svg 
                    
                    xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18">
                      <path id="Path_119102" data-name="Path 119102" d="M0,0V18l3.25-2.057L6.5,18l3.25-2.057L13,18V0Z" transform="translate(0 0)" 
                      fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>%</p>
                    </div>
                    :
                    <div className='product-percentage-text-div'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                      <path id="Path_119101" data-name="Path 119101" d="M122.691,0V18l3.334-2.057L129.357,18l3.333-2.057L136.024,18l3.333-2.057L142.691,18V0Z" transform="translate(-122.691 0)" 
                        fill={this.props.value.state.discount_badge_bg_colour}
                      />
                    </svg>
                    <p style={{
                                            
                                            color:this.props.value.state.discount_badge_text_colour,
                                          }}>12%</p>
                    </div>
                      }
                    </>
                    }
                    </h3>
                    <h3 className='product-prev-image'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16">
  <g id="Group_42041" data-name="Group 42041" transform="translate(0.261 0.218)">
    <path id="Polygon_58" data-name="Polygon 58" d="M5.5,0,11,7H0Z" transform="translate(-0.261 8.782)" fill="#cfcfcf" opacity="0.5"/>
    <path id="Polygon_59" data-name="Polygon 59" d="M7.5,0,15,9H0Z" transform="translate(6.739 6.782)" fill="#cfcfcf"/>
    <circle id="Ellipse_5321" data-name="Ellipse 5321" cx="2.5" cy="2.5" r="2.5" transform="translate(5.739 -0.218)" fill="#cfcfcf"/>
  </g>
</svg>
                    </h3>
                  </li>
                  <li className='product-detailslist grid-product-detailslist'>
                    <ul className='product-preview-prdetails'
                     style={{
                      borderRadius:
                      (this.props.value.state.image_shape_on_listing_page ==="soft_corner" || 
                      this.props.value.state.image_shape_on_listing_page ==="circle") ? "10px" : "",
                     }}
                    >
                      <li>
                        <p className='product-preview-name'>Woman yellow solid round neck
longline t-shirt</p>
                      </li>
                      <li className='product-preview-rating'>
                      <svg id="iconfinder_star_216411_2_" data-name="iconfinder_star_216411 (2)" xmlns="http://www.w3.org/2000/svg" width="11.806" height="11.27" viewBox="0 0 11.806 11.27">
  <g id="Group_115" data-name="Group 115" transform="translate(0)">
    <path id="Path_56" data-name="Path 56" d="M7.687,7.659l-3.724.413a.356.356,0,0,0-.3.243.35.35,0,0,0,.1.369c1.107,1.011,2.77,2.525,2.77,2.525s-.457,2.2-.759,3.671a.357.357,0,0,0,.139.358.351.351,0,0,0,.38.02C7.6,14.516,9.551,13.4,9.551,13.4l3.255,1.857a.352.352,0,0,0,.52-.378c-.3-1.47-.757-3.672-.757-3.672l2.77-2.523a.357.357,0,0,0,.1-.371.353.353,0,0,0-.3-.241c-1.49-.167-3.725-.415-3.725-.415L9.873,4.242a.357.357,0,0,0-.322-.208.352.352,0,0,0-.32.208C8.613,5.609,7.687,7.659,7.687,7.659Z" transform="translate(-3.649 -4.034)" fill="#ffca2a"/>
  </g>
</svg>
<p>4.2<span style={{marginLeft:"4px"}}>(53)</span></p>

                      </li>
                      <li className='d-flex product-prev-pricing'>
                        
                        <h3>$59</h3>
                        <h2>$67</h2>
                       
                      </li>
                      <div className='grid-add-tocart'>
                        <div>
                      {
                      this.props.value.state.show_out_of_stock && <p>10 in Stock</p> }
                       </div>
                      {this.props.value.state.show_add_to_cart_on_listing_page &&                       
                      <li className='product-save-cart'>
                        {/* <svg id="Group_41218" data-name="Group 41218" xmlns="http://www.w3.org/2000/svg" width="17.18" height="14.9" viewBox="0 0 17.18 14.9">
                          <path id="Path_8614" data-name="Path 8614" d="M-4169-1583.91h2.541l1.944,8.885h7.769l1.925-6.439H-4165.8" transform="translate(4169.001 1585.789)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <circle id="Ellipse_3030" data-name="Ellipse 3030" cx="1.106" cy="1.106" r="1.106" transform="translate(4.506 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <circle id="Ellipse_3031" data-name="Ellipse 3031" cx="1.106" cy="1.106" r="1.106" transform="translate(10.033 12.188)" fill="none" stroke="#cfcfcf" stroke-width="1" />
                          <ellipse id="Ellipse_8319" data-name="Ellipse 8319" cx="4.161" cy="4.161" rx="4.161" ry="4.161" transform="translate(8.859 0)" fill="#cfcfcf" />
                          <path id="Union_334" data-name="Union 334" d="M2.148,2.148h0v0h0v0Z" transform="translate(10.957 2.027)" fill="none" stroke="#fff" stroke-width="1" />
                        </svg> */}
                        +
                      </li> }

                      </div>
                    </ul>

                  </li>
                </ul>

              </div>


            
             
            </div>  }
           
          </div>
          </div>
         
          
          </div>
           {/* Third Slide */}
           {
         this.props.value.state.show_images_in_gallery ?
          
          <div className='w-100 ' style={{
            height:"644px"
          }}>
           {
            this.props.value.state.show_images_as_thumbnails_in_gallery ?
            <div className=' product-img'>
             
            </div> :
            <div className='product-imagetwo'>

            </div> }
          </div>:null }
         
          {/* Fourth Slide */}
          <div className='' style={{height: "635px"}}>
               {
                this.props.value.state.show_native_payment_screen ? 
                <div className='checkout-prev-secondimg'>
            
                </div> :
                <div className='checkout-prev-firstimg'>
            
                </div> 
                 }
          </div>
          </Carousel>
        </div>
    )
  }
}

export default ProductPreview