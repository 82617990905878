import React from "react";
import CommonInput from "./CommonInput";
import Tooltip from "../../commonIcons/Tooltip";
import Newfeatureicon from "../../commonIcons/Newfeatureicon";

const CommonToggle = ({
  title,
  message,
  toggleName,
  handleStateChange,
  webViewState,
  showNew,
}) => {
  return (
    <div>
      <div className="webview-settings-toggle">
        <p className="webview-settings-toggle-title">
          {title}
          <Tooltip message={message} />
          {showNew && <Newfeatureicon />}
        </p>

        <CommonInput
          toggleName={toggleName}
          handleStateChange={handleStateChange}
          webViewState={webViewState}
        />
      </div>
    </div>
  );
};

export default CommonToggle;
