import React from 'react';  
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

export default class Errormessage extends React.Component{
   
    render(){
        return(
            <Modal isOpen={this.props.value.state.errormodal}  centered toggle={e => this.props.value.errormodal(e)} style={{maxWidth:"506px",maxHeight:"247px"}} className={this.props.className} dataclass="addonpopup">
                <ModalHeader toggle={e => this.props.value.errormodal(e)} className="modal-xxl-content confirmpopup" >
                    <p>{this.props.value.state.title}</p>
                    
                </ModalHeader>
                <ModalBody className="">
                <div>
                    <div className="d-flex mt-2 mb-3">
                        <div className="alertconfirm">
                        <svg  width="52" height="44" viewBox="0 0 52 44">
                            <g id="Group_3260" data-name="Group 3260" transform="translate(-748 -456)">
                            <path id="Polygon_11" data-name="Polygon 11" d="M23.417,4.371a3,3,0,0,1,5.166,0l20.743,35.1A3,3,0,0,1,46.743,44H5.257a3,3,0,0,1-2.583-4.526Z" transform="translate(748 456)" fill="#c8cedb"/>
                            <path id="Path_6764" data-name="Path 6764" d="M-.712-.1V13.655" transform="translate(774.5 472.5)" fill="none" stroke="#fff" strokeWidth="3"/>
                            <path id="Path_6765" data-name="Path 6765" d="M-.712-.1V3.538" transform="translate(774.5 489.973)" fill="none" stroke="#fff" strokeWidth="3"/>
                            </g>
                        </svg>
                        </div>
                        <div className="confirmtext pl-2">
                            <h6>{this.props.value.state.errormessage}</h6>
                            {/* <h6>You cannot undo this action.</h6> */}
                        </div>
                    </div>
                    <div className="d-flex justify-content-end finalselection">
                        <div onClick = {e => this.props.value.errormodal(e)}>
                           <p>OKAY</p>
                        </div>
                    </div>
                </div>
                </ModalBody>
            </Modal> 
        )
    }
}
