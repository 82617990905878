import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./appPreview.css";
import {DownApp}  from "./DownApp";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
  Button,
  ModalFooter,
} from "reactstrap";
import { iframeApi, Verifyapistatus,GetsplashApi } from "../../Api/Preview/PreviewApi";
import Connectpopup from "../Reusable/Connectpopup.js";
import { errortoast, sucesstoast } from "../../Dashboard/Toaster";
import moment from "moment";
import { Errorstatuscon } from "./Context";
import { Error } from "../Reusable/Connectivityerror";
import { userLogout } from "../../Api/Dashboard/DashboardApi";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import Newfeatureicon from "../../Components/commonIcons/Newfeatureicon.js";
const Url = process.env.REACT_APP_API_URL + "api/user/";

class Previewsplash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copiedLeft: false,
      copiedRight: false,
      spinner: true,
      data: null,
      iosdata: null,
      androidlanguage: "en",
      ioslanguage: "en",
      devicename: "iphone14promax",
      androiddevicename: "pixel7pro",
      androiddeviceOSname: "14.0",
      iosscale: "60",
      osVersion:"17",
      androidscale: "53",
      limitreached:false,
      ios: true,
      android: true,
      screenshot: false,
      iosspinner: false,
      androidspinner: false,
      error: false,
      connectivity_log: null,
      appplan: atob(localStorage.getItem("plan_code")),
      is_connected: null,
      is_verified: null,
      website_type: null,
      simulator_status: null,
      ItemAppPopup:false,
      ItemMobAppPopup:false,
      ItemAppAndoridPopup:false,
      ItemMobAppAndoridPopup:false,
      modal: false,
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  static contextType = Errorstatuscon;
  componentDidMount() {
    if (window.matchMedia("(max-width: 960px)").matches) {
      this.setState({
        ios: false,
        android: true,
      });
    }

    GetsplashApi(this, () => {
      // if (parseInt(localStorage.getItem("website_technology")) === 5 || parseInt(localStorage.getItem("website_technology")) === 4) 
      // {
      //   Verifyapistatus(this, () => {
      //     this.context.handlefunerror(this.state.error);
      //   });
      // }
      //  else 
      //  {
      //   this.setState({
      //     error: false,
      //     spinner: false,
      //   });
      //   this.context.handlefunerror(this.state.error);
      // }
      //   if( this.state.is_verified == 0)
      //   {
      //       this.setState({limitreached:true});
      //   }

        this.setState({
          error: false,
          spinner: false,
        });
        this.context.handlefunerror(this.state.error);

       if(
          parseInt(localStorage.getItem("website_technology")) === 4 ||
          parseInt(localStorage.getItem("website_technology")) === 5 ||
          parseInt(localStorage.getItem("website_technology")) === 2 ||
          parseInt(localStorage.getItem("website_technology")) === 3
         )
        {
          const params = {
            app_id: btoa(localStorage.getItem("Appid")),
          };
            iframeApi(params, this, () => {
              this.context.handleresult(
                this.state.connectivity_log,
                this.state.is_verified,
                this.state.is_connected,
                this.state.website_type,
                this.state.simulator_status
              );
            });
                
        }
    });

  }

  componentWillUnmount() {
    //clearInterval(this.interval);
  }
  selectionmodel = (e) => {
    e.preventDefault();
    this.setState({
      screenshot: !this.state.screenshot,
    });
  };
  handleImageLoaded() {
    this.setState({ spinner: false });
  }
  saveScreenshot = (e) => {
    let iframe = document.querySelector(e);
    iframe.contentWindow.postMessage("saveScreenshot", "*");
    let elementios = document.getElementById("preview-screnbtneffect-ios");
    let elementiosmob = document.getElementById("preview-screnbtneffect-ios-mob"); 
   let elementandroid = document.getElementById("preview-screnbtneffect-android-mob"); 

    if(e === "#iosframe"){

      if(elementios){
        elementios.classList.add("preview-btntransform");
      }
      setTimeout(()=>{
        if(elementios){
          elementios.classList.remove("preview-btntransform");
        }
      },100)
    
      if(elementiosmob){
        elementiosmob.classList.add("preview-btntransform");
      }
      setTimeout(()=>{
        if(elementiosmob){
          elementiosmob.classList.remove("preview-btntransform");
        }
      },100)
    }
else{

  if(elementandroid){
    elementandroid.classList.add("preview-btntransform");
  }
  setTimeout(()=>{
    if(elementandroid){
      elementandroid.classList.remove("preview-btntransform");
    }
  },100)
}
    this.setState({
      screenshot: false,
    });
  };



  restartApp = (e) => {
    let iframe = document.querySelector(e);
    iframe.contentWindow.postMessage("restartApp", "*");
    let elementios = document.getElementById("preview-btneffect-ios");
    let elementandroid = document.getElementById("preview-btneffect-android");
    let elementiosmob = document.getElementById("preview-btneffect-ios-mob");


if(e === "#iosframe"){

  if(elementios){
    elementios.classList.add("preview-btntransform");
  }
  setTimeout(()=>{
    if(elementios){
      elementios.classList.remove("preview-btntransform");
    }
  },100)

  if(elementiosmob){
    elementiosmob.classList.add("preview-btntransform");
  }
  setTimeout(()=>{
    if(elementiosmob){
      elementiosmob.classList.remove("preview-btntransform");
    }
  },100)
}
else{
  if(elementandroid){
    elementandroid.classList.add("preview-btntransform");
  }
  setTimeout(()=>{
    if(elementandroid){
      elementandroid.classList.remove("preview-btntransform");
    }
  },100)
}

    

    this.setState({
      screenshot: false,
    });
  };

  onMyFrameLoad = () => {
    this.setState({
      spinner: false,
      iosspinner: false,
      androidspinner: false,
    });
  };

  handleiospreview = (val) => {
    
    if (val === "iphone11pro") 
    {
      this.setState({
        iosscale: "76",
        osVersion:"15",
        screenshot: false,
        iosspinner: true,
      });
    }
    if (val === "iphone14promax") 
    {
      this.setState({
        iosscale: "67",
        osVersion:"17",
        screenshot: false,
        iosspinner: true,
      });
    } 
    else if (val === "iphone8") {
      this.setState({
        iosscale: "73",
        osVersion:"15",
        screenshot: false,
        iosspinner: true,
      });
    } else {
      this.setState({
        iosscale: "67",
        osVersion:"15",
        screenshot: false,
        iosspinner: true,
      });
    }

    this.setState({
      devicename: val,
    });
  };

  handleandroiddevicename = (e) => {
    if (e === "pixel6pro") 
    {
      this.setState({
        androiddevicename: e,
        androiddeviceOSname: "14.0",
        androidspinner: true,
        screenshot: false,
        androidscale: "53",
      });
    }
    else if (e === "pixel7pro") 
    {
      this.setState({
        androiddevicename: e,
        androiddeviceOSname: "14.0",
        androidspinner: true,
        screenshot: false,
        androidscale: "53",
      });
    } else if (e === "pixel6") 
      {
        this.setState({
          androiddevicename: e,
          androiddeviceOSname: "14.0",
          androidspinner: true,
          screenshot: false,
          androidscale: "68",
        });
      }
      else if (e === "pixel7") 
      {
        this.setState({
          androiddevicename: e,
          androiddeviceOSname: "14.0",
          androidspinner: true,
          screenshot: false,
          androidscale: "68",
        });
      } 
  };

  handleAndroid = () => {
    this.setState({
      android: true,
      ios: false,
    });
  };
  handleIos = () => {
    this.setState({
      ios: true,
      android: false,
    });
  };
  copyios = () => {
    this.setState({
      copiedLeft: true,
      copiedRight: false,
    });
    const el = document.createElement("input");
    if(localStorage.getItem("website_technology") == 3)
    {
      el.value = "https://testflight.apple.com/join/DKgriDVM";
    }
    else if(localStorage.getItem("website_technology") == 2)
    {
      el.value = "https://testflight.apple.com/join/1cvKgAXc";
    }
    else if(localStorage.getItem("website_technology") == 4)
    {
      el.value = "https://testflight.apple.com/join/OLPs2xOB";
    }
    else if(localStorage.getItem("website_technology") == 5)
    {
      el.value = "https://testflight.apple.com/join/cq3qO8y7"
    }  
    else
    {
      el.value = "";
    }                  
    // el.value = "https://testflight.apple.com/join/I7Xx3gMA";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };
  copygplay = () => {
    this.setState({
      copiedRight: true,
      copiedLeft: false,
    });
    const el = document.createElement("input");
    // el.value =
    //   "https://play.google.com/store/apps/details?id=com.appmysite.shop";
    if(localStorage.getItem("website_technology") == 3)
    {
      el.value = "https://play.google.com/store/apps/details?id=com.appmysite.custom";
    }
    else if(localStorage.getItem("website_technology") == 2 )
    {
      el.value = "https://play.google.com/store/apps/details?id=com.appmysite.web";
    }
    else if(localStorage.getItem("website_technology") == 4)
    {
      el.value = "https://play.google.com/store/apps/details?id=com.appmysite.wordpress";
    }
    else if(localStorage.getItem("website_technology") == 5)
    {
      el.value = "https://play.google.com/store/apps/details?id=com.appmysite.shop";
    }
    else 
    {
      el.value = "";
    }
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };
  handleAppdropdown = (e) =>
  {
    this.setState({ItemAppPopup:!this.state.ItemAppPopup});
  }

  mobhandleAppdropdown = (e) =>
  {
    this.setState({ItemMobAppPopup:!this.state.ItemMobAppPopup});
  }

  handleAppAndoriddropdown = (e) =>
  {
    this.setState({ItemAppAndoridPopup:!this.state.ItemAppAndoridPopup});
  }

  mobhandleAppAndoriddropdown = (e) =>
  {
    this.setState({ItemMobAppAndoridPopup:!this.state.ItemMobAppAndoridPopup});
  }

  closeLimitReachReachPopup = (e) =>
	  {
	  
		this.setState({limitreached:false});
	  }
 
  render() {
    const Errordata = {
      heading_first: "Connect website with app",
      heading_second: "You have connectivity issue with your website",
      heading_third:
        "You have to check your website connectivity to check app preview",
      button_text: "Run Troubleshooter",
      button_link: "/app/connect/troubleshoot-logs",
    };
    const Errordata_plugin = {
      heading_first: "Connect website with app",
      heading_second:
        "This step is mandatory to sync your website with the mobile app.",
      heading_third:
        "Install the AppMySite WP plugin, enter the API details and verify it to connect website with app.",
      button_text: "Connect App",
      button_link: "/app/connect/wp-plugin",
    };
    const Errordata_api = {
      heading_first: "Connect website with app",
      heading_second:
        "This step is mandatory to sync your website with the mobile app.",
      heading_third:
        "Enter the API details and verify it to connect website with app.",
      button_text: "Connect App",
      button_link: "/app/connect/wp-grant-access",
    };
    const Errordata_for_wordpress = {
      heading_first: "Connect website with app",
      heading_second:
        "This step is mandatory to sync your website with the mobile app.",
      heading_third:
        "Install the AppMySite WP plugin and verify it in to connect website with app.",
      button_text: "Connect App",
      button_link: "/app/connect/wp-plugin",
    };
    const checkurl = () => {
    if((parseInt(localStorage.getItem('website_technology')) == 4) || (parseInt(localStorage.getItem('website_technology')) == 5))
    {
      return (
        "https://appetize.io/embed/ddpy0v4qdy457rkmbuahg9xh3r?device=" +
        this.state.devicename +
        "&scale=" +
        this.state.iosscale +
        "&autoplay=false&orientation=portrait&osVersion="+this.state.osVersion+
        "&deviceColor=white&xdocMsg=true&centered=true&params=" +
        this.state.iosdata
      );
    }
    else
    {
      return (
        "https://appetize.io/embed/nhsibwbvlipicuyogr2i63nd3m?device=" +
        this.state.devicename +
        "&scale=" +
        this.state.iosscale +
        "&autoplay=false&orientation=portrait&osVersion="+this.state.osVersion+
        "&deviceColor=white&xdocMsg=true&centered=true&params=" +
        this.state.iosdata
      )
    }

    };
    const androidcheckurl = () => {
      if((parseInt(localStorage.getItem('website_technology')) == 4))
      {
        return (
          "https://appetize.io/embed/kjcqxrq7z22wg5qcvp5p8t0u6g?device=" +
          this.state.androiddevicename +
          "&scale=" +
          this.state.androidscale +
          "&autoplay=false&orientation=portrait&osVersion="+this.state.androiddeviceOSname+"&deviceColor=white&xdocMsg=true&centered=true&params=" +
          this.state.data
        );
      }
      else if((parseInt(localStorage.getItem('website_technology')) == 5))
      {
        return (
          "https://appetize.io/embed/kjcqxrq7z22wg5qcvp5p8t0u6g?device=" +
          this.state.androiddevicename +
          "&scale=" +
          this.state.androidscale +
          "&autoplay=false&orientation=portrait&osVersion="+this.state.androiddeviceOSname+"&deviceColor=white&xdocMsg=true&centered=true&params=" +
          this.state.data
        );
      }
      else
      {
        return (
          "https://appetize.io/embed/z34iqjlpv6h4xxyeq3qg6cvkla?device=" +
          this.state.androiddevicename +
          "&scale=" +
          this.state.androidscale +
          "&autoplay=false&orientation=portrait&osVersion="+this.state.androiddeviceOSname+"&deviceColor=white&xdocMsg=true&centered=true&params=" +
          this.state.data
        );
      }
    };
    
    return (
      <>
        {this.state.spinner ? (
          <div className="newspinner previewspinner">
          <svg xmlns="http://www.w3.org/2000/svg" 
    xlink="http://www.w3.org/1999/xlink" 
    style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
    width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
    <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
    <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
      <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
      style={{animationplaystate: "running", animationdelay: "0s"}}>
      </animateTransform>
    </circle>
    </g>
    <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
    <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
    <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
    style={{animationplaystate: "running", animationdelay: "0s"}}>
    </animateTransform>
    </circle>
    </g>
    <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
    <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
    <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
    style={{animationplaystate: "running", animationdelay: "0s"}}>

    </animateTransform>
    </circle>
    </g>
    <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
    <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
    <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
    style={{animationplaystate: "running", animationdelay: "0s"}}>

    </animateTransform>
    </circle>
    </g>
    </svg>
        </div>
        ) : (
          <>
            {(() => {
                  return (
                    <>
                     {/* { 
                        (this.state.is_verified === 0 &&  parseInt(localStorage.getItem('website_technology')) === 4 ) ||
                        (this.state.is_verified === 0 &&  parseInt(localStorage.getItem('website_technology')) === 5 )
                      ?
                        <Connectpopup limitreached={this.state.limitreached} closefunction={this.closeLimitReachReachPopup}/>
                      : <></>
                      } */}
                      <div className="  mob-ios-android-tabs mob-preview-splash-head">
                      <div
                          className=" preview-androidmob"
                          onClick={(e) => this.handleAndroid()}
                        >
                          <Dropdown className="appPreview-dropdown"
                                    isOpen={this.state.ItemMobAppAndoridPopup}
                                    toggle={this.mobhandleAppAndoriddropdown}
                                    >
                                      <div className={this.state.ItemMobAppAndoridPopup? "report-aerotop-androidone": "report-aerohidden"}>
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/dropdownAero.png")
                                            .default
                                        }
                                        className=""
                                        alt=" "
                                      />
                                      </div>
                                    <DropdownToggle caret>
                                      {this.state.androiddevicename .replace(/(\d)([A-Za-z])/g, '$1 $2')
                                        .replace(/([A-Za-z])(\d)/g, '$1 $2')  
                                        .replace(/\b\w/g, c => c.toUpperCase()) 
                                        .replace(/\s+/g, ' ')                   
                                        .trim()
                                      }
                                    </DropdownToggle>
                                    <DropdownMenu end={true}>
                                      
                                    <DropdownItem active={this.state.androiddevicename === 'pixel6'} onClick={() =>this.handleandroiddevicename('pixel6')}>Pixel 6</DropdownItem>
                                      <DropdownItem active={this.state.androiddevicename === 'pixel6pro'} onClick={() =>this.handleandroiddevicename('pixel6pro')}>Pixel 6 pro</DropdownItem>
                                      <DropdownItem active={this.state.androiddevicename === 'pixel7'} onClick={() =>this.handleandroiddevicename('pixel7')}>Pixel 7</DropdownItem>
                                      <DropdownItem active={this.state.androiddevicename === 'pixel7pro'} onClick={() =>this.handleandroiddevicename('pixel7pro')}>Pixel 7 pro</DropdownItem>
                                     
                                    </DropdownMenu>
                                  </Dropdown>
                          {this.state.android === true ? (
                            <>
                              <img
                                src={
                                  require("../../assets/images/dashboard/android-previewon.png")
                                    .default
                                }
                                alt="preview-android"
                                className="img-fluid "
                              />
                            
                            </>
                          ) : (
                            <>
                              <img
                                src={
                                  require("../../assets/images/dashboard/android-preview-off.png")
                                    .default
                                }
                                alt="preview-android"
                                className="img-fluid"
                              />
                             
                            </>
                          )}

                          {/* <div className="android-preview-icon-new-tag">
                            <Newfeatureicon/>
                          </div> */}
                                               
                        </div>
                        <div
                          className="preview-iosmob"
                          onClick={(e) => this.handleIos()}
                        >
                         <Dropdown className=" appPreview-dropdown"
                                    isOpen={this.state.ItemMobAppPopup}
                                    toggle={this.mobhandleAppdropdown}
                                    >
                                      <div className={this.state.ItemMobAppPopup? "report-aerotop-iosone": "report-aerohidden"}>
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/dropdownAero.png")
                                            .default
                                        }
                                        className=""
                                        alt=" "
                                      />
                                      </div>
                                    <DropdownToggle caret>
                                      {this.state.devicename == 'iphone14promax' ?
                                        "iPhone 14 Pro Max"
                                      :this.state.devicename == 'iphone11promax' ?
                                      "iPhone 11 Pro Max"
                                      :this.state.devicename == 'iphone11pro' ?
                                      "iPhone 11 Pro"
                                      :this.state.devicename == 'iphone8' ?
                                      "iPhone 8"
                                      :this.state.devicename == 'iphone8plus' ?
                                      "iPhone 8 Plus"
                                      : ""
                                      }
                                    
                                    </DropdownToggle>
                                    <DropdownMenu end={true}>
                                      
                                      <DropdownItem active={this.state.devicename == 'iphone14promax' ? true: ""} 
                                                    onClick={() => this.handleiospreview('iphone14promax')}>iPhone 14 Pro Max</DropdownItem>
                                      <DropdownItem active={this.state.devicename == 'iphone11promax' ? true: ""} 
                                                    onClick={() => this.handleiospreview('iphone11promax')}>iPhone 11 Pro Max</DropdownItem>
                                      <DropdownItem active={this.state.devicename == 'iphone11pro' ? true: ""} 
                                                    onClick={() => this.handleiospreview('iphone11pro')}>iPhone 11 Pro</DropdownItem>
                                      <DropdownItem active={this.state.devicename == 'iphone8' ? true: ""} 
                                                    onClick={() => this.handleiospreview('iphone8')}>iPhone 8</DropdownItem>
                                      <DropdownItem active={this.state.devicename == 'iphone8plus' ? true: ""}  
                                                    onClick={() => this.handleiospreview('iphone8plus')}>iPhone 8 Plus</DropdownItem>
                                     
                                    </DropdownMenu>
                                  </Dropdown>
                          {this.state.ios === true ? (
                            <>
                              <img
                                src={
                                  require("../../assets/images/dashboard/apple-previreon.png")
                                    .default
                                }
                                alt="preview-ios"
                                className="img-fluid"
                              />
                              {/* <p
                                className="colour-blue-bottom-ios"
                                style={{
                                  color: " #1B2952",
                                  fontSize: "14px",
                                  fontFamily: "Proxima-Bold",
                                  marginBottom: "0px",
                                }}
                              >
                                iOS
                              </p> */}
                            </>
                          ) : (
                            <>
                              <img
                                src={
                                  require("../../assets/images/dashboard/apple-preview-off.png")
                                    .default
                                }
                                alt="preview-ios"
                                className="img-fluid"
                              />
                              {/* <p
                                style={{
                                  color: "#7782A1",
                                  fontSize: "14px",
                                  fontFamily: "Proxima-Regular",
                                  marginBottom: "0px",
                                }}
                              >
                                iOS
                              </p> */}
                            </>
                          )}
                        </div>

                     
                      </div>
                      <div
                        className=" android-preview-divmain justify-content-center text-center  align-items-center"
                        style={{ position: "relative", minHeight: "400px" }}
                      >
                        {/* {this.state.error === false ?  */}
                        {/* ( */}
                          
                          <>
                            {this.state.iosdata !== null && (
                              <div className="preview-left-pannel">
                                 <div className="android-frame  ">
                                    <div className="d-flex align-items-center justify-content-center top_screenshot">
                                      <div className="preview-new-tab d-flex justify-content-center align-items-center">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/android_active.png")
                                              .default
                                          }
                                          alt="preview-android"
                                          className="img-fluid mr-2"
                                        />
                                        <p></p>
                                      </div>
                                      <div className="appPreview-new-button">
                                        <div className="">
                                              
                                        <div className="">
                                    <Dropdown className="appPreview-dropdown"
                                    isOpen={this.state.ItemAppAndoridPopup}
                                    toggle={this.handleAppAndoriddropdown}
                                    >
                                      <div className={this.state.ItemAppAndoridPopup? "report-aerotop-androidone": "report-aerohidden"}>
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/dropdownAero.png")
                                            .default
                                        }
                                        className=""
                                        alt=" "
                                      />
                                      </div>
                                    <DropdownToggle caret>
                                    {this.state.androiddevicename .replace(/(\d)([A-Za-z])/g, '$1 $2')
                                        .replace(/([A-Za-z])(\d)/g, '$1 $2')  
                                        .replace(/\b\w/g, c => c.toUpperCase()) 
                                        .replace(/\s+/g, ' ')                   
                                        .trim()
                                      }
                                    
                                    </DropdownToggle>
                                    <DropdownMenu end={true}>
                                      
                                      <DropdownItem active={this.state.androiddevicename === 'pixel6'} onClick={() =>this.handleandroiddevicename('pixel6')}>Pixel 6</DropdownItem>
                                      <DropdownItem active={this.state.androiddevicename === 'pixel6pro'} onClick={() =>this.handleandroiddevicename('pixel6pro')}>Pixel 6 pro</DropdownItem>
                                      <DropdownItem active={this.state.androiddevicename === 'pixel7'} onClick={() =>this.handleandroiddevicename('pixel7')}>Pixel 7</DropdownItem>
                                      <DropdownItem active={this.state.androiddevicename === 'pixel7pro'} onClick={() =>this.handleandroiddevicename('pixel7pro')}>Pixel 7 pro</DropdownItem>
                                     
                                    </DropdownMenu>
                                  </Dropdown>
                                  </div>

                                          {/* <select
                                            data-placeholder="Choose a Language..."
                                            value={this.state.androiddevicename}
                                            onChange={(e) =>
                                              this.handleandroiddevicename(e)
                                            }
                                          >
                                            <option value="pixel5">
                                              Pixel 5
                                            </option>
                                            <option value="nexus5">
                                              Nexus 5
                                            </option>
                                          </select> */}
                                        </div>
                                      </div>
                                      {/* <Newfeatureicon/> */}
                                    </div>
                                    
                                  </div>
                                  <div className="apple-preview-div">
                                  <div className="apple-preview-div-left ">
                                    {this.state.android === true ?
                                     <>
                                      <div
                                        className="apple-preview-div-left-top apple-preview-div-left-top-left "
                                        id="preview-btneffect-android"
                                      >
                                       
                                        <svg   onClick={(e) =>
                                            this.restartApp("#androidiframe")
                                          } xmlns="http://www.w3.org/2000/svg" width="27.443" height="27.444" viewBox="0 0 27.443 27.444">
                                          <g id="Group_40115" data-name="Group 40115" transform="translate(-1259.077 -211.687)">
                                            <path id="Path_103621" data-name="Path 103621" d="M9.094,0a9.094,9.094,0,1,0,9.094,9.094,9.206,9.206,0,0,0-2.259-6" transform="matrix(0.485, 0.875, -0.875, 0.485, 1276.343, 213.047)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                            <path id="Path_103620" data-name="Path 103620" d="M5.871,0V5.871H0" transform="translate(1275.699 215.701)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                          </g>
                                        </svg>
                                        <p className="reloadtooltip">Reset</p>
                                      </div>
                                   
                                      <div
                                        className="apple-preview-div-left-bottom apple-preview-div-left-bottom-left"
                                        id="preview-screnbtneffect-android-mob"
                                      >
                                        {/* <img
                                          src={
                                            require("../../assets/images/dashboard/screenshot.png")
                                              .default
                                          }
                                          alt="screenshot"
                                          className="img-fluid "
                                          onClick={(e) =>
                                            this.saveScreenshot("#androidiframe")
                                          }
                                          
                                        /> */}
                                        <svg  onClick={(e) =>
                                            this.saveScreenshot("#androidiframe")
                                          } xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                          <g id="Group_40111" data-name="Group 40111" transform="translate(-1333 -525)">
                                            <path id="Path_103616" data-name="Path 103616" d="M10,4V8a2,2,0,0,1-2,2H4" transform="translate(1342 534)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                            <path id="Path_103617" data-name="Path 103617" d="M10,8V4A2,2,0,0,0,8,2H4" transform="translate(1342 524)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                            <path id="Path_103618" data-name="Path 103618" d="M2,4V8a2,2,0,0,0,2,2H8" transform="translate(1332 534)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                            <path id="Path_103619" data-name="Path 103619" d="M2,8V4A2,2,0,0,1,4,2H8" transform="translate(1332 524)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                            <rect id="Rectangle_30022" data-name="Rectangle 30022" width="8" height="8" rx="1" transform="translate(1339 531)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                          </g>
                                        </svg>
                                        <p className="reloadtooltip">Screenshot</p>
                                      </div>
                                      </>
                                    : <></>
                                    }
                                  </div>
                                  <div
                                    style={{
                                      display:
                                        this.state.android === true
                                          ? "block"
                                          : "none",
                                      position: "relative",
                                    }}
                                  >
                                    {this.state.androidspinner && (
                                      <div className="spinn">
                                                                              {/* <div className="new-loader" style={{
                                                                                borderColor: " #1B2952",
                                                                              }}></div> */}

                                      </div>
                                    )}
                                    <iframe
                                      src={androidcheckurl()}
                                      width="340px"
                                      height="700px"
                                      frameBorder="0"
                                      scrolling="no"
                                      id="androidiframe"
                                      className="ml-0"
                                      onLoad={(e) => this.onMyFrameLoad()}
                                      title="android"
                                    ></iframe>
                                  </div>
                                
                                  </div>

                              </div>
                            )}
                          
                            {this.state.data !== null && (
                              <>
                                <div className="previeww-right-pannel">
                                <div className="android-frame ">
                                  <div className="d-flex align-items-center justify-content-center top_screenshot">
                                    <div className="preview-new-tab d-flex justify-content-center align-items-center">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/apple-logs.png")
                                            .default
                                        }
                                        alt="preview-android"
                                        className="img-fluid mr-2"
                                      />
                                      <p></p>
                                    </div>
                                    <div className="appPreview-new-button">

                                  <div className="">
                                    <Dropdown className=" appPreview-dropdown"
                                    isOpen={this.state.ItemAppPopup}
                                    toggle={this.handleAppdropdown}
                                    >
                                      <div className={this.state.ItemAppPopup? "report-aerotop-iosone": "report-aerohidden"}>
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/dropdownAero.png")
                                            .default
                                        }
                                        className=""
                                        alt=" "
                                      />
                                      </div>
                                    <DropdownToggle caret>
                                      {this.state.devicename == 'iphone14promax' ? "iPhone 14 Pro Max"
                                      :this.state.devicename == 'iphone11promax' ? "iPhone 11 Pro Max"
                                      :this.state.devicename == 'iphone11pro' ? "iPhone 11 Pro"
                                      :this.state.devicename == 'iphone8' ? "iPhone 8"
                                      :this.state.devicename == 'iphone8plus' ? "iPhone 8 Plus"
                                      : ""
                                      }
                                    
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      
                                      <DropdownItem active={this.state.devicename == 'iphone14promax' ? true: ""} 
                                                    onClick={() => this.handleiospreview('iphone14promax')}>iPhone 14 Pro Max</DropdownItem>
                                      <DropdownItem active={this.state.devicename == 'iphone11promax' ? true: ""} 
                                                    onClick={() => this.handleiospreview('iphone11promax')}>iPhone 11 Pro Max</DropdownItem>
                                      <DropdownItem active={this.state.devicename == 'iphone11pro' ? true: ""} 
                                                    onClick={() => this.handleiospreview('iphone11pro')}>iPhone 11 Pro</DropdownItem>
                                      <DropdownItem active={this.state.devicename == 'iphone8' ? true: ""} 
                                                    onClick={() => this.handleiospreview('iphone8')}>iPhone 8</DropdownItem>
                                      <DropdownItem active={this.state.devicename == 'iphone8plus' ? true: ""}  
                                                    onClick={() => this.handleiospreview('iphone8plus')}>iPhone 8 Plus</DropdownItem>
                                     
                                    </DropdownMenu>
                                  </Dropdown>
                                  </div>
                                     
                                    </div>
                                  </div>
                                </div>
                                <div className="apple-preview-div">

                               
                                <div
                                  style={{
                                    display:
                                      this.state.ios === true
                                        ? "block"
                                        : "none",
                                    position: "relative",
                                  }}
                                >
                                  {this.state.iosspinner && (
                                    <div className="spinn">
                                      {/* <Spinner
                                        animation="border"
                                        variant="primary"
                                      /> */}
                                      {/* <div className="new-loader"></div> */}
                                    </div>
                                  )}
                                  <iframe
                                    src={checkurl()}
                                    width="340px"
                                    height="700px"
                                    frameBorder="0"
                                    scrolling="no"
                                    id="iosframe"
                                    className="mr-0"
                                    onLoad={(e) => this.onMyFrameLoad()}
                                    title="ios"
                                  ></iframe>
                                </div>

                                {
                                  ( window.matchMedia("(min-width: 1200px)").matches || 
                                  (window.matchMedia("(max-width: 1200px)").matches && this.state.android !== true )

                                )
                                  ?
                                  <div className=" android-preview-div-right web-mob-ios">
                                
                                  <div
                                    className=" apple-preview-div-left-top apple-preview-div-left-top-right"
                                   id="preview-btneffect-ios"
                                  >
                                    {/* <img
                                      src={
                                        require("../../assets/images/dashboard/restart.png")
                                          .default
                                      }
                                      alt="restart"
                                      className="img-fluid"
                                      onClick={(e) =>
                                        this.restartApp("#iosframe")
                                      }
                                    /> */}
  
  
                                      <svg  onClick={(e) =>
                                        this.restartApp("#iosframe")
                                      } xmlns="http://www.w3.org/2000/svg" width="27.443" height="27.444" viewBox="0 0 27.443 27.444">
                                      <g id="Group_40115" data-name="Group 40115" transform="translate(-1259.077 -211.687)">
                                        <path id="Path_103621" data-name="Path 103621" d="M9.094,0a9.094,9.094,0,1,0,9.094,9.094,9.206,9.206,0,0,0-2.259-6" transform="matrix(0.485, 0.875, -0.875, 0.485, 1276.343, 213.047)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                        <path id="Path_103620" data-name="Path 103620" d="M5.871,0V5.871H0" transform="translate(1275.699 215.701)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                      </g>
                                      </svg>
                                     <p className="reloadtooltip">Reset</p>
                                  </div>
                                 
                                 
                                  <div
                                    className=" apple-preview-div-left-bottom apple-preview-div-left-bottom-right 1"
                                    id="preview-screnbtneffect-ios"
                                  >
                                    {/* <img
                                      src={
                                        require("../../assets/images/dashboard/screenshot.png")
                                          .default
                                      }
                                      alt="screenshot"
                                      className="img-fluid"
                                      onClick={(e) =>
                                        this.saveScreenshot("#iosframe")
                                      }
                                    /> */}
                                    <svg  onClick={(e) =>
                                        this.saveScreenshot("#iosframe")
                                      } xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                    <g id="Group_40111" data-name="Group 40111" transform="translate(-1333 -525)">
                                      <path id="Path_103616" data-name="Path 103616" d="M10,4V8a2,2,0,0,1-2,2H4" transform="translate(1342 534)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                      <path id="Path_103617" data-name="Path 103617" d="M10,8V4A2,2,0,0,0,8,2H4" transform="translate(1342 524)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                      <path id="Path_103618" data-name="Path 103618" d="M2,4V8a2,2,0,0,0,2,2H8" transform="translate(1332 534)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                      <path id="Path_103619" data-name="Path 103619" d="M2,8V4A2,2,0,0,1,4,2H8" transform="translate(1332 524)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                      <rect id="Rectangle_30022" data-name="Rectangle 30022" width="8" height="8" rx="1" transform="translate(1339 531)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                    </g>
                                    </svg>
                                    <p className="reloadtooltip">Screenshot</p>
                                  </div>
                                </div>
                                :<></>
                                }
                           
                                {this.state.android === true ?
                                <div className=" mobapple-preview-div-left">
                                  <div
                                    className=" apple-preview-div-left-top apple-preview-div-left-top-right "
                                    id="preview-btneffect-ios-mob"
                                  >
                                    {/* <img
                                      src={
                                        require("../../assets/images/dashboard/restart.png")
                                          .default
                                      }
                                      alt="restart"
                                      className="img-fluid"
                                      onClick={(e) =>
                                        this.restartApp("#iosframe")
                                      }
                                    /> */}
                                    <svg  onClick={(e) =>
                                        this.restartApp("#androidiframe")
                                      } xmlns="http://www.w3.org/2000/svg" width="27.443" height="27.444" viewBox="0 0 27.443 27.444">
                                    <g id="Group_40115" data-name="Group 40115" transform="translate(-1259.077 -211.687)">
                                      <path id="Path_103621" data-name="Path 103621" d="M9.094,0a9.094,9.094,0,1,0,9.094,9.094,9.206,9.206,0,0,0-2.259-6" transform="matrix(0.485, 0.875, -0.875, 0.485, 1276.343, 213.047)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                      <path id="Path_103620" data-name="Path 103620" d="M5.871,0V5.871H0" transform="translate(1275.699 215.701)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                    </g>
                                  </svg>
                                  <p className="reloadtooltip">Reset</p>
                                  </div>
                                  <div
                                    className=" apple-preview-div-left-bottom apple-preview-div-left-bottom-right 2"
                                    id="preview-screnbtneffect-ios-mob"
                                  >
                                    {/* <img
                                      src={
                                        require("../../assets/images/dashboard/screenshot.png")
                                          .default
                                      }
                                      alt="screenshot"
                                      className="img-fluid"
                                      onClick={(e) =>
                                        this.saveScreenshot("#iosframe")
                                      }
                                    /> */}
                                    <svg  onClick={(e) =>
                                        this.saveScreenshot("#androidiframe")
                                      } xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                    <g id="Group_40111" data-name="Group 40111" transform="translate(-1333 -525)">
                                      <path id="Path_103616" data-name="Path 103616" d="M10,4V8a2,2,0,0,1-2,2H4" transform="translate(1342 534)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                      <path id="Path_103617" data-name="Path 103617" d="M10,8V4A2,2,0,0,0,8,2H4" transform="translate(1342 524)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                      <path id="Path_103618" data-name="Path 103618" d="M2,4V8a2,2,0,0,0,2,2H8" transform="translate(1332 534)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                      <path id="Path_103619" data-name="Path 103619" d="M2,8V4A2,2,0,0,1,4,2H8" transform="translate(1332 524)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                      <rect id="Rectangle_30022" data-name="Rectangle 30022" width="8" height="8" rx="1" transform="translate(1339 531)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                    </g>
                                  </svg>
                                    <p className="reloadtooltip">Screenshot</p>
                                  </div>
                                </div>
                                :<></>
                                }

                                </div>
                                </div>
                              </>
                            )}
                          </>
                        
                         {/* ) 
                         : (
                           <Error errordata={Errordata} />
                         )
                        } */}
                        <DownApp
                          togglemodalapp={(e) => this.selectionmodel(e)}
                        />
                      </div>
           

                    </>
                  );
               
            }
            )()
            }
          </>
        )}

        <Modal
          isOpen={this.state.screenshot}
          toggle={this.selectionmodel}
          style={{ maxWidth: "740px", maxHeight: "350px" }}
          className="mobile-download-app"
        >
          <ModalHeader
            toggle={this.selectionmodel}
            className="modal-xxl-content modal-categories"
          >
            <p className="ml-0" style={{ fontWeight: "bold" }}>
              Options
            </p>
            <div
              className="crossmodal mt-1"
              onClick={(e) => this.selectionmodel(e)}
            >
              <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                <path
                  id="Union_18"
                  data-name="Union 18"
                  d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                  transform="translate(0.529 0.531)"
                  fill="#1b2952"
                  stroke="#1b2952"
                  strokeWidth="1.5"
                />
              </svg>
            </div>
          </ModalHeader>
          <ModalBody className="update-woo-plugin">
            <div className="android-frame android-form-mobile">
              <div
                className="preview-new-button d-flex justify-content-between align-items-center pl-md-2"
                onClick={(e) =>
                  this.state.ios === true
                    ? this.saveScreenshot("#iosframe")
                    : this.saveScreenshot("#androidiframe")
                }
              >
                <p>Screenshot</p>
                <img
                  src={
                    require("../../assets/images/dashboard/screenshot.png")
                      .default
                  }
                  alt="screenshot"
                  className="img-fluid mr-2"
                />
              </div>
              <div
                className="preview-new-button d-flex justify-content-between align-items-center pl-md-2"
                onClick={(e) =>
                  this.state.ios === true
                    ? this.restartApp("#iosframe")
                    : this.restartApp("#androidiframe")
                }
              >
                <p>Restart</p>
                <img
                  src={
                    require("../../assets/images/dashboard/restart.png").default
                  }
                  alt="restart"
                  className="img-fluid mr-2"
                />
              </div>
              <div className="preview-new-button d-flex form-group justify-content-center align-items-center">
                <div className="http ">
                  {this.state.ios === true ? (
                    <select
                      value={this.state.devicename}
                      onChange={(e) => this.handleiosdevice(e)}
                    >
                      <option value="iphone11promax">iPhone 11 Pro Max</option>
                      <option value="iphone11pro">iPhone 11 Pro</option>
                      <option value="iphone8">iPhone 8</option>
                      <option value="iphone8plus">iPhone 8 Plus</option>
                    </select>
                  ) : (
                    <select
                      value={this.state.androiddevicename}
                      onChange={(e) => this.handleandroiddevicename(e)}
                    >
                      <option value="pixel5">Pixel 5</option>
                      <option value="nexus5">Nexus 5</option>
                    </select>
                  )}
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/* <div className="d-none ipad-test-popup">
        {(localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || 
          localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || 
          localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1) ?
          <button
            className="btn btn-outline-primary test-button"
            onClick={this.toggle}
          >
            TEST APP ON REAL DEVICE
          </button>
        : <></>
        }
          <Modal
            isOpen={this.state.modal}
            fade={false}
            toggle={this.toggle}
            className={
              "modal-dialog-centered modal-dialog-scrollable testmodal-dialog"
            }
          >
            <ModalHeader toggle={this.toggle} className={"testmodal-header"}>
             
            </ModalHeader>
            <ModalBody>
              <div className="test-header">
                <h5>Download the app</h5>
                <p>
                  Test your app on a real device before publishing on the app
                  stores.
                </p>
              </div>
              <div className="test-popup-links d-flex">
                <a
                  target="_blank"
                  href={localStorage.getItem("website_technology") == 3 
                  ? "https://testflight.apple.com/join/DKgriDVM"
                  : localStorage.getItem("website_technology") == 2 
                  ? "https://testflight.apple.com/join/1cvKgAXc"
                  : localStorage.getItem("website_technology") == 4
                  ? "https://testflight.apple.com/join/OLPs2xOB"
                  : localStorage.getItem("website_technology") == 5
                  ? "https://testflight.apple.com/join/cq3qO8y7"
                  : ""
                  }
                >
                  <div className="apple-test-link">
                    <img
                      src={
                        require("../../assets/images/dashboard/apple-test-logo.png")
                          .default
                      }
                      className="apple-test-img"
                      alt="ios-store-icon"
                    ></img>
                    <div className="apple-test-linktext">
                      <p>Download in</p>
                      <h5 className="apple-link-subtext">TestFlight</h5>
                    </div>
                  </div>
                </a>
                <a
                  target="_blank"
                  href={ localStorage.getItem("website_technology") == 3 
                  ? "https://play.google.com/store/apps/details?id=com.appmysite.custom"
                  : localStorage.getItem("website_technology") == 2 
                  ? "https://play.google.com/store/apps/details?id=com.appmysite.web"
                  : localStorage.getItem("website_technology") == 4 
                  ? "https://play.google.com/store/apps/details?id=com.appmysite.wordpress"
                  : localStorage.getItem("website_technology") == 5 
                  ? "https://play.google.com/store/apps/details?id=com.appmysite.shop"
                  : ""
                  }
                >
                  <div className="apple-test-link gplay-test-link">
                    <img
                      src={
                        require("../../assets/images/dashboard/gplay-test-logo.png")
                          .default
                      }
                      className="apple-test-img"
                      alt="gplay-store-icon"
                    ></img>
                    <div className="apple-test-linktext gplay-test-linktext">
                      <p>Get it on</p>
                      <h5 className="gplay-link-subtext">Google Play</h5>
                    </div>
                  </div>
                </a>
              </div>
              <div className="d-flex  justify-content-around align-items-center">
                <div className="apple-qr">
                 
                  { localStorage.getItem("website_technology") == 3 
                  ? 
                      <img
                      src={
                        require("../../assets/images/dashboard/download/customios.png")
                          .default
                      }
                      className="apple-qr-code"
                      alt="gplay-store-icon"
                    ></img>
                  : localStorage.getItem("website_technology") == 2 
                  ? 
                      <img
                      src={
                        require("../../assets/images/dashboard/download/webtoappios.png")
                          .default
                      }
                      className="apple-qr-code"
                      alt="gplay-store-icon"
                    ></img>
                  : localStorage.getItem("website_technology") == 4 
                  ?  <img
                        src={
                          require("../../assets/images/dashboard/download/wordpressios.png")
                            .default
                        }
                        className="apple-qr-code"
                        alt="gplay-store-icon"
                      ></img>
                  : localStorage.getItem("website_technology") == 5 
                  ?  <img
                        src={
                          require("../../assets/images/dashboard/download/woocomerceios.png")
                            .default
                        }
                        className="apple-qr-code"
                        alt="gplay-store-icon"
                      ></img>
                        : ""
                  }
                </div>
                <div className="gplay-qr">
                { localStorage.getItem("website_technology") == 3 
                                                    ? 
                                                    <img
                                                    src={
                                                      require("../../assets/images/dashboard/download/customandroid.png")
                                                        .default
                                                    }
                                                    className="gplay-qr-code"
                                                    alt="gplay-store-icon"
                                                  ></img>
                                                    : localStorage.getItem("website_technology") == 2 
                                                    ? 
                                                    <img
                                                    src={
                                                      require("../../assets/images/dashboard/download/webtoappandroid.png")
                                                        .default
                                                    }
                                                    className="gplay-qr-code"
                                                    alt="gplay-store-icon"
                                                  ></img>
                                                    : localStorage.getItem("website_technology") == 4 
                                                    ?  <img
                                                    src={
                                                      require("../../assets/images/dashboard/download/wordpressandroid.png")
                                                        .default
                                                    }
                                                    className="gplay-qr-code"
                                                    alt="gplay-store-icon"
                                                  ></img>
                                                    : localStorage.getItem("website_technology") == 5 
                                                    ?  <img
                                                    src={
                                                      require("../../assets/images/dashboard/download/woocomerceandroid.png")
                                                        .default
                                                    }
                                                    className="gplay-qr-code"
                                                    alt="gplay-store-icon"
                                                  ></img>
                                                    : ""
                                                    }
                </div>
              </div>
              <div className="d-flex  justify-content-around align-items-center">
                <div className="left-link-copy">
                  <button className="btn" onClick={this.copyios}>
                    {!this.state.copiedLeft ? (
                      <>
                        {" "}
                        <p className="copy-redirect-linktext">Copy Link</p>{" "}
                        <img
                          className="ml-2 copy-redirect-link"
                          src={
                            require("../../assets/images/dashboard/config/build/copy.png")
                              .default
                          }
                          alt="copy"
                        />{" "}
                      </>
                    ) : (
                      <p className="copy-redirect-linktext">Copied!</p>
                    )}
                  </button>
                </div>
                <div className="right-link-copy">
                  <button className="btn" onClick={this.copygplay}>
                    {!this.state.copiedRight ? (
                      <>
                        <p className="copy-redirect-linktext">Copy Link</p>{" "}
                        <img
                          className="ml-2 copy-redirect-link"
                          src={
                            require("../../assets/images/dashboard/config/build/copy.png")
                              .default
                          }
                          alt="copy"
                        />
                      </>
                    ) : (
                      <p className="copy-redirect-linktext">Copied!</p>
                    )}
                  </button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div> */}
      </>
    );
  }
}

export { Previewsplash };
