import React from 'react'

const WhatsNew = () => {
  return (
    <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="19.611"
                              viewBox="0 0 20 19.611"
                            >
                              <g
                                id="Group_35094"
                                data-name="Group 35094"
                                transform="translate(-1551 -342.389)"
                              >
                                <path
                                  id="Path_98187"
                                  data-name="Path 98187"
                                  d="M9,6.611v5H1.936A.937.937,0,0,1,1,10.675V7.547a.937.937,0,0,1,.936-.936Zm1-1H1.936A1.936,1.936,0,0,0,0,7.547v3.128a1.936,1.936,0,0,0,1.936,1.936H10Z"
                                  transform="translate(1551 342.389)"
                                  fill="#1b2952"
                                />
                                <path
                                  id="Path_98188"
                                  data-name="Path 98188"
                                  d="M19,1.3V16.927l-9-4.909V6.2ZM19.431,0a.568.568,0,0,0-.272.07L9,5.611v7l10.159,5.542a.568.568,0,0,0,.841-.5V.57A.569.569,0,0,0,19.431,0"
                                  transform="translate(1551 342.389)"
                                  fill="#1b2952"
                                />
                                <path
                                  id="Path_98189"
                                  data-name="Path 98189"
                                  d="M5.81,12.611l.75,6H4.576l-.75-6Zm.883-1h-4l.945,7.556a.506.506,0,0,0,.5.444H7.119a.506.506,0,0,0,.5-.569Z"
                                  transform="translate(1551.307 342.389)"
                                  fill="#1b2952"
                                />
                                <line
                                  id="Line_350"
                                  data-name="Line 350"
                                  y1="2.673"
                                  x2="4.812"
                                  transform="translate(1563.094 346.664)"
                                  fill="none"
                                  stroke="#1b2952"
                                  stroke-miterlimit="10"
                                  stroke-width="1"
                                />
                              </g>
                            </svg>
  )
}

export default WhatsNew