import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import DeleteNotifModal from "./DeleteNotifModal";
import Spinner from "../../commonComponents/Spinner";
import androidIc from "../../../Agency/assets/images/android.png";
import iosIc from "../../../Agency/assets/images/ios.png";
import deleteIc from "../../../assets/images/dashboard/deletemultiapp.png";
import altIc from "../../../assets/images/dashboard/edit-skeletal.svg";
import noNotifImg from "../../../assets/images/dashboard/no-notification.png";
import placeholderImg from "../../../assets/images/dashboard/no-img-category.png";
import { DEVICE_TYPE } from "../../../Constants/notificationConsts";

const TableList = ({
  displayTable,
  notificationData,
  setNotificationData,
  buildFilters,
}) => {
  const notifImg = (data) =>
    data
      ? process.env.REACT_APP_Image_Path +
        localStorage.getItem("user_id") +
        "/" +
        localStorage.getItem("Appid") +
        process.env.REACT_APP_Plain_Notification_Media +
        data
      : placeholderImg;

  const formatDate = (date) =>
    date ? moment(date).format("D MMMM YYYY, HH:mm").toString() : "";

  const deleteItem = (id) =>
    setNotificationData({ isDeleteNotifModalOpen: true, toDeleteNotifId: id });

  return (
    <div className="">
      <div className="">
        {notificationData.isDeleteNotifModalOpen && (
          <DeleteNotifModal
            notificationData={notificationData}
            setNotificationData={setNotificationData}
            buildFilters={buildFilters}
          />
        )}
        {displayTable.length !== null && displayTable?.length > 0 ? (
          <div className="newsocial_pannel">
            {/* Filter end */}
            <div className="notification-table">
              <p className="notification-message">Message</p>
              <p className="notification-platform">Device</p>
              <p className="newnotification-status">Status</p>
              <p className="notification-blank">Edit</p>
            </div>
            <section className="create-notify">
              {displayTable
                // .filter((res) => (filter === 5 ? res : res.status === filter))
                .filter(
                  (row, idx) =>
                    row.title
                      .toLowerCase()
                      .includes(notificationData.searchVal?.toLowerCase()) ||
                    row.description
                      .toLowerCase()
                      .includes(notificationData.searchVal?.toLowerCase())
                )
                .map((item, i) => (
                  <div
                    className="justify-content-between newpushcommon-height"
                    key={i}
                  >
                    <div
                      className="d-flex newimage-noftication notification-img-gap"
                      style={{
                        flexDirection: window.matchMedia("(max-width: 1200px)")
                          .matches
                          ? "row-reverse"
                          : "",
                      }}
                    >
                      <div
                        className={` ${
                          window.matchMedia("(max-width: 1200px)").matches
                            ? ""
                            : ""
                        }`}
                      >
                        <div className="planenotification-image-div">
                          <img
                            src={notifImg(item.image)}
                            width={"64px"}
                            height={"64px"}
                            alt="Appicon"
                            className="plane-notification-img"
                          />
                        </div>
                      </div>

                      <div className="newnotification-heading w-100">
                        <h3 className="notification-title">{item.title}</h3>
                        <p className="notification-description">
                          {item.description}
                        </p>
                      </div>
                    </div>
                    <div className=" align-items-center justify-content-start newnotification-status mob-platform-status">
                      <p className="mob-notification-label">Platform</p>
                      <p className="pushnotification-selecttech">
                        {item.device_type === DEVICE_TYPE.android ? (
                          <img src={androidIc} alt="android" />
                        ) : item.device_type === DEVICE_TYPE.ios ? (
                          <img src={iosIc} alt="iOS" />
                        ) : item.device_type === DEVICE_TYPE.both ? (
                          <>
                            <img src={androidIc} alt="android" />
                            <img src={iosIc} alt="iOS" />
                          </>
                        ) : null}
                      </p>
                    </div>

                    <div className="pushnotificationstatus-edit">
                      <div className=" newnotification-status mob-status-table statusdate-table">
                        <p className="mob-notification-label">Status</p>
                        {item.status === 0 ? (
                          <p className="newscheduled mr-3"> Draft </p>
                        ) : item.status === 1 ? (
                          <p className="newscheduled mr-3 notification-sent-color">
                            Sent
                          </p>
                        ) : item.status === 2 ? (
                          <p className="newscheduled mr-3">Scheduled</p>
                        ) : item.status === 3 ? (
                          <p className="newscheduled mr-3 notification-failed-color">
                            Failed
                          </p>
                        ) : null}

                        {item.status === 0 ? (
                          <p className="newnotification-time">
                            {formatDate(item.created_at)}
                          </p>
                        ) : item.status === 1 ? (
                          <p className="newnotification-time">
                            {formatDate(item.delivery_date)}
                          </p>
                        ) : item.status === 2 ? (
                          <p className="newnotification-time">
                            {formatDate(item.delivery_date)}
                          </p>
                        ) : item.status === 3 ? (
                          <p className="newnotification-time">
                            {formatDate(item.created_at)}
                          </p>
                        ) : null}
                      </div>
                      <div className=" d-flex align-items-center justify-content-end message-delete">
                        {item.status !== 1 && item.status !== 3 && (
                          <Link
                            to={"/app/basic-notification/" + btoa(item.id)}
                            className="pointcursor notification-delete"
                          >
                            <img src={altIc} alt="edit icon" />
                          </Link>
                        )}

                        <span onClick={() => deleteItem(item.id)}>
                          <img
                            src={deleteIc}
                            alt="delete"
                            className="deletemsg-icon"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              {notificationData.bottomSpinner ? (
                <div className={`form-group notification-bottom-spinner`}>
                  <Spinner />
                </div>
              ) : null}
            </section>
          </div>
        ) : (
          <div className="w-100 text-center  pushnotification-notavalable">
            <img
              src={noNotifImg}
              alt="nopushnotification"
              className="img-fluid"
            />
            <p>Nothing here at the moment. Come back later?</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TableList;
