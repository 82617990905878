import React from 'react'

const Community = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g
      id="Group_41126"
      data-name="Group 41126"
      transform="translate(-1549 -598)"
    >
      <rect
        id="Rectangle_25572"
        data-name="Rectangle 25572"
        width="24"
        height="24"
        transform="translate(1549 598)"
        fill="none"
      />
      <g id="Group_35097" data-name="Group 35097">
        <g
          id="Path_98190"
          data-name="Path 98190"
          transform="translate(1549.736 598.5)"
          fill="none"
          stroke-miterlimit="10"
        >
          <path
            d="M15.264,4a3.085,3.085,0,0,1-.056.63,3.5,3.5,0,0,1-6.887,0A3.085,3.085,0,0,1,8.264,4a3.5,3.5,0,0,1,7,0Z"
            stroke="none"
          />
          <path
            d="M 11.76453971862793 1.499999523162842 C 10.38574028015137 1.499999523162842 9.263999938964844 2.621249675750732 9.263999938964844 3.999459505081177 C 9.263999938964844 4.227429389953613 9.285189628601074 4.359789848327637 9.297809600830078 4.418399810791016 L 9.304490089416504 4.45220947265625 C 9.517560005187988 5.63815975189209 10.55216026306152 6.498919486999512 11.76453971862793 6.498919486999512 C 12.97630023956299 6.498919486999512 14.0104398727417 5.63815975189209 14.22350978851318 4.45220947265625 L 14.23019027709961 4.418399810791016 C 14.24281024932861 4.359789848327637 14.26399993896484 4.227429389953613 14.26399993896484 3.999459505081177 C 14.26399993896484 2.621249675750732 13.14274978637695 1.499999523162842 11.76453971862793 1.499999523162842 M 11.76453971862793 0.4999995231628418 C 13.6965503692627 0.4999995231628418 15.26399993896484 2.067459583282471 15.26399993896484 3.999459505081177 C 15.26399993896484 4.209319591522217 15.24993991851807 4.433239459991455 15.20775032043457 4.629039764404297 C 14.9135103225708 6.266809463500977 13.48559951782227 7.498919486999512 11.76453971862793 7.498919486999512 C 10.04240036010742 7.498919486999512 8.614489555358887 6.266809463500977 8.320249557495117 4.629039764404297 C 8.278059959411621 4.433239459991455 8.263999938964844 4.209319591522217 8.263999938964844 3.999459505081177 C 8.263999938964844 2.067459583282471 9.832539558410645 0.4999995231628418 11.76453971862793 0.4999995231628418 Z"
            stroke="none"
            fill="#1b2952"
          />
        </g>
        <g
          id="Path_98196"
          data-name="Path 98196"
          transform="translate(1541.736 612.5)"
          fill="none"
          stroke-miterlimit="10"
        >
          <path
            d="M15.264,4a3.085,3.085,0,0,1-.056.63,3.5,3.5,0,0,1-6.887,0A3.085,3.085,0,0,1,8.264,4a3.5,3.5,0,0,1,7,0Z"
            stroke="none"
          />
          <path
            d="M 11.76453971862793 1.499999523162842 C 10.38574028015137 1.499999523162842 9.263999938964844 2.621249675750732 9.263999938964844 3.999459505081177 C 9.263999938964844 4.227429389953613 9.285189628601074 4.359789848327637 9.297809600830078 4.418399810791016 L 9.304490089416504 4.45220947265625 C 9.517560005187988 5.63815975189209 10.55216026306152 6.498919486999512 11.76453971862793 6.498919486999512 C 12.97630023956299 6.498919486999512 14.0104398727417 5.63815975189209 14.22350978851318 4.45220947265625 L 14.23019027709961 4.418399810791016 C 14.24281024932861 4.359789848327637 14.26399993896484 4.227429389953613 14.26399993896484 3.999459505081177 C 14.26399993896484 2.621249675750732 13.14274978637695 1.499999523162842 11.76453971862793 1.499999523162842 M 11.76453971862793 0.4999995231628418 C 13.6965503692627 0.4999995231628418 15.26399993896484 2.067459583282471 15.26399993896484 3.999459505081177 C 15.26399993896484 4.209319591522217 15.24993991851807 4.433239459991455 15.20775032043457 4.629039764404297 C 14.9135103225708 6.266809463500977 13.48559951782227 7.498919486999512 11.76453971862793 7.498919486999512 C 10.04240036010742 7.498919486999512 8.614489555358887 6.266809463500977 8.320249557495117 4.629039764404297 C 8.278059959411621 4.433239459991455 8.263999938964844 4.209319591522217 8.263999938964844 3.999459505081177 C 8.263999938964844 2.067459583282471 9.832539558410645 0.4999995231628418 11.76453971862793 0.4999995231628418 Z"
            stroke="none"
            fill="#1b2952"
          />
        </g>
        <g
          id="Path_98197"
          data-name="Path 98197"
          transform="translate(1557.736 612.5)"
          fill="none"
          stroke-miterlimit="10"
        >
          <path
            d="M15.264,4a3.085,3.085,0,0,1-.056.63,3.5,3.5,0,0,1-6.887,0A3.085,3.085,0,0,1,8.264,4a3.5,3.5,0,0,1,7,0Z"
            stroke="none"
          />
          <path
            d="M 11.76453971862793 1.499999523162842 C 10.38574028015137 1.499999523162842 9.263999938964844 2.621249675750732 9.263999938964844 3.999459505081177 C 9.263999938964844 4.227429389953613 9.285189628601074 4.359789848327637 9.297809600830078 4.418399810791016 L 9.304490089416504 4.45220947265625 C 9.517560005187988 5.63815975189209 10.55216026306152 6.498919486999512 11.76453971862793 6.498919486999512 C 12.97630023956299 6.498919486999512 14.0104398727417 5.63815975189209 14.22350978851318 4.45220947265625 L 14.23019027709961 4.418399810791016 C 14.24281024932861 4.359789848327637 14.26399993896484 4.227429389953613 14.26399993896484 3.999459505081177 C 14.26399993896484 2.621249675750732 13.14274978637695 1.499999523162842 11.76453971862793 1.499999523162842 M 11.76453971862793 0.4999995231628418 C 13.6965503692627 0.4999995231628418 15.26399993896484 2.067459583282471 15.26399993896484 3.999459505081177 C 15.26399993896484 4.209319591522217 15.24993991851807 4.433239459991455 15.20775032043457 4.629039764404297 C 14.9135103225708 6.266809463500977 13.48559951782227 7.498919486999512 11.76453971862793 7.498919486999512 C 10.04240036010742 7.498919486999512 8.614489555358887 6.266809463500977 8.320249557495117 4.629039764404297 C 8.278059959411621 4.433239459991455 8.263999938964844 4.209319591522217 8.263999938964844 3.999459505081177 C 8.263999938964844 2.067459583282471 9.832539558410645 0.4999995231628418 11.76453971862793 0.4999995231628418 Z"
            stroke="none"
            fill="#1b2952"
          />
        </g>
        <path
          id="Path_98193"
          data-name="Path 98193"
          d="M14.269,21.42a8.982,8.982,0,0,1-2.769.433c-2.006,0-4.623-.887-5.812-2.373"
          transform="translate(1550 599)"
          fill="none"
          stroke="#1b2952"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <path
          id="Path_98194"
          data-name="Path 98194"
          d="M17.234,5.78a9.025,9.025,0,0,1,3.325,7.014A8.72,8.72,0,0,1,20.3,14.9"
          transform="translate(1550 599)"
          fill="none"
          stroke="#1b2952"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <path
          id="Path_98195"
          data-name="Path 98195"
          d="M2.469,12.076A9.03,9.03,0,0,1,8.316,4.318"
          transform="translate(1550 599)"
          fill="none"
          stroke="#1b2952"
          stroke-miterlimit="10"
          stroke-width="1"
        />
      </g>
    </g>
  </svg>
  )
}

export default Community