import React from "react";

import { Media, Spinner } from "reactstrap";
import Tooltip from "../Tooltip";
import Leftpanel from "../Leftpanel";
import Header from "../Header";
import styles from "./build.module.css";
import Gotosvg from "./Gotosvg.jsx";

import {
  BuildappApi,
  certificate,
  provision2s3,
  iosbuilingapp,
  pushcertificatefunction,
  iosBuildReset,
  iosAndroidBuildReset,
} from "../../Api/Dashboard/DashboardApi";
import { getiosformdata } from "../../Api/Custombundle/Custombundle";
import Errormessage from "../Reusable/Errormessage";
import { InnerFoot } from "../Foot";
const Url = process.env.REACT_APP_Image_Path;
const altUrl = process.env.REACT_APP_Image_Path;
//const attrUrl = process.env.REACT_APP_fileAttribute
const titleUrl = process.env.REACT_APP_firebase;
const iosUrl = process.env.REACT_APP_ios_profiles;
const newUrl = process.env.REACT_APP_ios_certificates;
const Topsection = () => {
  return (
    <div className="d-md-flex d-none align-items-start justify-content-between mb-3 ipad-margin-top">
      <div className="dasboard-page-title">
        <div className="app_iconname">
          <div className="d-flex align-items-center">
            <h3 className="mt-2">iOS Build Configuration</h3>
          </div>
          <h5 className="mt-2">
            Configure your iOS app build. Changes made will reflect once your
            build is generated.{" "}
            {/* <Media
              href="https://www.appmysite.com/support/category/download-app/download-ios-app/ios-build-configuration/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More{" "}
              <img
                src={require("../../assets/images/dashboard/Goto.png").default}
                className="gotoappicon appear-spacing"
                alt="Goto"
              />
            </Media> */}
            {" "}
          </h5>
        </div>
      </div>
    </div>
  );
};
export default class BuildIos extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      spinner: true,
      modalspinner: true,

      user_status: 0,
      pointsmodal: false,
      errormodal: false,
      title: "",
      errormessage: "",

      rebuildapp: false,
      downloadbuild: false,
      addonrebuildapp: false,
      buildurl: "",

      addoniosbuild: false,
      buildplatform: "",

      buildiosurl: "",
      zip_path: "",
      info_plist_path: "",

      pushtoggle: false,
      google_enable: false,
      //Form Data
      teamid: "",
      certificate: "",
      pushcertificate: "",
      provision1: "",
      provision2: "",
      certificatefilename: ".p12 file supported ",
      pushnotificationfilename: ".plist file supported",
      provision2filename: ".mobileprovision file supported",
      provision1filename: ".mobileprovision file supported",
      allowBuildBtn: true,
      allowSaveBtn: true,
      allowResetBtn: true,
      submitval: "Build App",
      submitval2: "Save",
      submitval3: "Reset",
      loader: "d-none",
      loaderimage: require("../../assets/images/signup/Loader_GIF.gif").default,
      loader2: "d-none",
      loaderimage2: require("../../assets/images/signup/Loader_GIF.gif")
        .default,
      loader3: "d-none",
      loaderimage3: require("../../assets/images/signup/Loader_GIF.gif")
        .default,
      disabledsubmitbutton: false,
      provision1s3name: "",
      provision2s3name: "",
      certificates3name: "",
      pushnotifications3name: "",
      userid: parseInt(localStorage.getItem("user_id")),
      appid: parseInt(localStorage.getItem("Appid")),
      app_icon_count: 0,
      ios_bundle_id: null,
      ios_status: false,
      //ios
      ios_build_version: null,
      ios_latest_version: null,
      is_supporting_ipad: false,
      bundle_identifier: null,
      file_check: 0,
      androidstatus: null,
      buildiosstatus: null,
      certificate_password: "",
      serverkey: "",
      original_plist_file: "",
      original_cert_file: "",
      original_provision_file: "",
      //admob
      admob_app_id: "",
      is_admob_enabled: false,
      ios_admob_app_id: "",
      ios_is_admob_enabled: false,
      build_process: null,
      //permission
      is_enable_location_permission: false,
      location_permission_description: "",
      is_enable_calender_permission: false,
      calender_permission_description: "",
      is_enable_microphone_permission: false,
      microphone_permission_description: "",
      is_enable_reminder_permission: false,
      reminder_permission_description: "",
      is_enable_speech_recognition_permission: false,
      speech_recognition_permission_description: "",
      is_enable_camera_and_photo_library_permission: false,
      camera_and_photo_library_permission_description: "",
      is_enable_contacts_permission: false,
      contacts_permission_description: "",
      is_enable_app_tracking_permission: false,
      app_tracking_permission_description: "",
      chat_preferences: null,
      //Redirect to parent page
      app_data: null,
      is_enable_keychain: false,
      keychain_url: "",
      key_spinner: false,
      is_social_login_enabled: "",
      appRightPanelExpand: localStorage.getItem("appSideBarCollapse") === true ||
			localStorage.getItem("appSideBarCollapse") === "true"
			  ? true
			  : false,
    };
  }
  interval;

  componentDidMount() {
    window.scrollTo(0, 0);
    const androidparams = {
      type: "ios",
    };
    getiosformdata(androidparams, this, () => {});
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }
  //admob handling

  handleios_is_admob_enabled = (e) => {
    this.setState({
      ios_is_admob_enabled: e.target.checked,
    });
  };
  handlekeychain_url = (e) => {
    this.setState({
      keychain_url: e.target.value,
    });
  };
  handlekeychain_urlOnBlur = (e) => {
    let str = e.target.value;
    if (str.includes("https://")) {
      let updateStr = str.replace("https://", "");
      this.setState({
        keychain_url: updateStr,
      });
    }
    if (str.includes("http://")) {
      let updateStr = str.replace("http://", "");
      this.setState({
        keychain_url: updateStr,
      });
    }
  };
  handleios_admob_app_id = (e) => {
    this.setState({
      ios_admob_app_id: e.target.value,
    });
  };

  finalbuild = () => {
    const buildparams = {
      app_id: localStorage.getItem("Appid"),
      platform: "ios",
      is_beta_release: 0,
    };
    BuildappApi(buildparams, this);
  };
  errormodal = () => {
    this.setState({
      errormodal: !this.state.errormodal,
    });
  };

  //Form submit code
  handleteamid = (e) => {
    this.setState({
      teamid: e.target.value,
    });
  };
  handlecertificate_password = (e) => {
    this.setState({
      certificate_password: e.target.value,
    });
  };
  handleserver_key = (e) => {
    this.setState({
      serverkey: e.target.value,
    });
  };

  handlecertificate = (e) => {
    let self = this;
    let reader = new FileReader();
    let file = e.target.files[0];

    if (file !== undefined) {
      if (
        this.state.pushnotificationfilename !==
        file.name.split(".").slice(0, -1).join(".")
      ) {
        if (file.name.indexOf("p12") !== -1) {
          this.setState({
            certificatefilename: file.name.split(".").slice(0, -1).join("."),
          });
          reader.onload = function (upload) {
            self.setState(
              {
                certificate: upload.target.result,
              },
              () => {
                self.formcertificate();
              }
            );
          };
          reader.readAsDataURL(file);
        } else {
          this.setState({
            errormodal: true,
            title: "File format",
            errormessage: "File format not supported. Upload p12 file only.",
          });
        }
      } else {
        this.setState({
          errormodal: true,
          title: "File",
          errormessage: "File already uploaded.",
        });
      }
    }
  };
  handlepushnotification = (e) => {
    let self = this;
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file !== undefined) {
      if (
        this.state.certificatefilename !==
        file.name.split(".").slice(0, -1).join(".")
      ) {
        if (file.name.indexOf("plist") !== -1) {
          this.setState({
            pushnotificationfilename: file.name
              .split(".")
              .slice(0, -1)
              .join("."),
          });
          reader.onload = function (upload) {
            self.setState(
              {
                pushcertificate: upload.target.result,
              },
              () => {
                self.formpushnotification();
              }
            );
          };
          reader.readAsDataURL(file);
        } else {
          this.setState({
            errormodal: true,
            title: "File",
            errormessage: "File format not supported. Upload plist file only.",
          });
        }
      } else {
        this.setState({
          errormodal: true,
          title: "File",
          errormessage: "File already uploaded.",
        });
      }
    }
  };

  handlepushnotificationtoggle = (e) => {
    this.setState({
      pushtoggle: e.target.checked,
    });
  };
  handlekeychaintoggle = (e) => {
    this.setState({
      is_enable_keychain: e.target.checked,
    });
  };

  handlegoogle_enable = (e) => {
    this.setState({
      google_enable: e.target.checked,
    });
  };
  handlepermission = (e) => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked,
    });
  };
  handlepermission_des = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  handlekeygenerate = () => {
    if (this.state.teamid) {
      const fileData = JSON.stringify({
        webcredentials: {
          apps: [
            `${this.state.teamid}.${
              this.state.ios_status === true
                ? this.state.ios_bundle_id
                : this.state.bundle_identifier
            }`,
          ],
        },
      });
      const blob = new Blob([fileData], { type: "application/File" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.download = `apple-app-site-association`;
      link.href = url;
      link.click();
    } else {
      this.setState({
        errormodal: true,
        title: "Team ID",
        errormessage: "Please fill the Team ID",
      });
    }
  };
  handleprovision2 = (e) => {
    let self = this;
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file !== undefined) {
      if (
        this.state.provision1filename !==
        file.name.split(".").slice(0, -1).join(".")
      ) {
        if (file.name.indexOf("mobileprovision") !== -1) {
          this.setState({
            provision2filename: file.name.split(".").slice(0, -1).join("."),
          });
          reader.onload = function (upload) {
            self.setState(
              {
                provision2: upload.target.result,
              },
              () => {
                self.formprovision();
              }
            );
          };
          reader.readAsDataURL(file);
        } else {
          this.setState({
            errormodal: true,
            title: "File",
            errormessage:
              "File format not supported. Upload mobileprovision file only.",
          });
        }
      } else {
        this.setState({
          errormodal: true,
          title: "File",
          errormessage: "File already uploaded.",
        });
      }
    }
  };
  formpushnotification = () => {
    this.setState({
      disabledsubmitbutton: true,
    });
    const params = {
      original_path:
        localStorage.getItem("user_id") +
        "/" +
        localStorage.getItem("Appid") +
        "/firebase",
      original: this.state.pushcertificate,
      imgname: this.state.pushnotificationfilename,
    };

    pushcertificatefunction(params, this, () => {});
  };

  formcertificate = () => {
    this.setState({
      disabledsubmitbutton: true,
    });
    const params = {
      original_path:
        localStorage.getItem("user_id") +
        "/" +
        localStorage.getItem("Appid") +
        "/ipa/certificates",
      original: this.state.certificate,
      imgname: this.state.certificatefilename,
    };
    certificate(params, this, () => {});
  };
  formprovision = () => {
    this.setState({
      disabledsubmitbutton: true,
    });
    const provision2params = {
      original_path:
        localStorage.getItem("user_id") +
        "/" +
        localStorage.getItem("Appid") +
        "/ipa/profiles",
      original: this.state.provision2,
      imgname: this.state.provision2filename,
    };
    provision2s3(provision2params, this, () => {});
  };
  UploadcertificatetoS3 = (checker) => {
    const iosdata = {
      app_id: parseInt(localStorage.getItem("Appid")),
      certificate: this.state.certificates3name,
      certificate_password: this.state.certificate_password,
      team_id: this.state.teamid.trim(),
      profile: this.state.provision2s3name,
      notification_config_file: this.state.pushnotifications3name,
      is_supporting_ipad: this.state.is_supporting_ipad === true ? 1 : 0,

      is_notification_enabled: this.state.pushtoggle === true ? 1 : 0,
      is_google_login_enabled: this.state.google_enable === true ? 1 : 0,
      notification_key: this.state.serverkey.trim(),
      is_admob_enabled: this.state.ios_is_admob_enabled === true ? 1 : 0,
      admob_app_id: this.state.ios_admob_app_id.trim(),
      //permission
      is_enable_location_permission:
        this.state.is_enable_location_permission === true ? 1 : 0,
      location_permission_description:
        this.state.location_permission_description !== null
          ? this.state.location_permission_description.trim()
          : "",
      is_enable_calender_permission:
        this.state.is_enable_calender_permission === true ? 1 : 0,
      calender_permission_description:
        this.state.calender_permission_description !== null
          ? this.state.calender_permission_description.trim()
          : "",
      is_enable_microphone_permission:
        this.state.is_enable_microphone_permission === true ? 1 : 0,
      microphone_permission_description:
        this.state.microphone_permission_description !== null
          ? this.state.microphone_permission_description.trim()
          : "",
      is_enable_reminder_permission:
        this.state.is_enable_reminder_permission === true ? 1 : 0,
      reminder_permission_description:
        this.state.reminder_permission_description !== null
          ? this.state.reminder_permission_description.trim()
          : "",
      is_enable_speech_recognition_permission:
        this.state.is_enable_speech_recognition_permission === true ? 1 : 0,
      speech_recognition_permission_description:
        this.state.speech_recognition_permission_description !== null
          ? this.state.speech_recognition_permission_description.trim()
          : "",
      is_enable_camera_and_photo_library_permission:
        this.state.is_enable_camera_and_photo_library_permission === true
          ? 1
          : 0,
      camera_and_photo_library_permission_description:
        this.state.camera_and_photo_library_permission_description !== null
          ? this.state.camera_and_photo_library_permission_description.trim()
          : "",
      is_enable_contacts_permission:
        this.state.is_enable_contacts_permission === true ? 1 : 0,
      contacts_permission_description:
        this.state.contacts_permission_description !== null
          ? this.state.contacts_permission_description.trim()
          : "",
      is_enable_app_tracking_permission:
        this.state.is_enable_app_tracking_permission === true ? 1 : 0,
      app_tracking_permission_description:
        this.state.app_tracking_permission_description !== null
          ? this.state.app_tracking_permission_description.trim()
          : "",
      is_enable_keychain: this.state.is_enable_keychain === true ? 1 : 0,
      keychain_url: this.state.keychain_url,
    };
    if (this.state.allowBuildBtn === true || checker === 2) {
      iosbuilingapp(iosdata, this, checker);
    }
  };

  iossubmit = (e, checker) => {
    e.preventDefault();

    if (checker === undefined && this.state.allowBuildBtn === true) {
      this.setState({
        allowSaveBtn: false,
      });
    }
    if (checker === 2) {
      this.setState({
        allowBuildBtn: false,
      });
    }

    if (this.state.certificates3name === "") {
      this.setState({
        errormodal: true,
        title: "Certificate",
        errormessage: "Please upload iPhone Distribution Certificate",
      });
    } else if (this.state.teamid.trim() === "") {
      this.setState({
        errormodal: true,
        title: "Team ID",
        errormessage: "Please enter valid Team ID",
      });
    } else if (this.state.provision2s3name === "") {
      this.setState({
        errormodal: true,
        title: "Provisioning Profile",
        errormessage: "Please upload iPhone Provisioning Profile",
      });
    } else if (
      this.state.is_enable_keychain === true &&
      this.state.keychain_url.trim() === ""
    ) {
      this.setState({
        errormodal: true,
        title: "Domain name",
        errormessage: "Please Enter valid Domain name",
      });
    } else if (
      this.state.pushtoggle === true &&
      this.state.pushnotifications3name === ""
    ) {
      this.setState({
        errormodal: true,
        title: "Firebase Configuration",
        errormessage: "Please upload Google Firebase Configuration",
      });
    } else if (
      this.state.google_enable === true &&
      this.state.pushnotifications3name === ""
    ) {
      this.setState({
        errormodal: true,
        title: "Firebase Configuration",
        errormessage: "Please upload Google Firebase Configuration",
      });
    } else if (this.state.pushtoggle === true && this.state.serverkey === "") {
      this.setState({
        errormodal: true,
        title: "Firebase Server Key",
        errormessage: "Please upload Google Firebase Server Key",
      });
    } else if (
      this.state.ios_is_admob_enabled === true &&
      this.state.ios_admob_app_id.trim() === ""
    ) {
      this.setState({
        errormodal: true,
        title: "Admob App ID",
        errormessage: "Please Enter valid Admob App ID",
      });
    } else if (
      this.state.is_enable_location_permission === true &&
      this.state.location_permission_description.trim() === ""
    ) {
      this.setState({
        errormodal: true,
        title: "Location description",
        errormessage: "Please Enter Location description",
      });
    } else if (
      this.state.is_enable_contacts_permission === true &&
      this.state.contacts_permission_description.trim() === ""
    ) {
      this.setState({
        errormodal: true,
        title: "Contact description",
        errormessage: "Please Enter Contact description",
      });
    } else if (
      this.state.is_enable_microphone_permission === true &&
      this.state.microphone_permission_description.trim() === ""
    ) {
      this.setState({
        errormodal: true,
        title: "Microphone description",
        errormessage: "Please Enter Microphone description",
      });
    } else if (
      this.state.is_enable_speech_recognition_permission === true &&
      this.state.speech_recognition_permission_description.trim() === ""
    ) {
      this.setState({
        errormodal: true,
        title: "Speech recognition description",
        errormessage: "Please Enter Speech recognition description",
      });
    } else if (
      this.state.is_enable_calender_permission === true &&
      this.state.calender_permission_description.trim() === ""
    ) {
      this.setState({
        errormodal: true,
        title: "Calendar description",
        errormessage: "Please Enter Calendar description",
      });
    } else if (
      this.state.is_enable_reminder_permission === true &&
      this.state.reminder_permission_description.trim() === ""
    ) {
      this.setState({
        errormodal: true,
        title: "Reminders description",
        errormessage: "Please Enter valid Reminders description",
      });
    } else if (
      this.state.is_enable_camera_and_photo_library_permission === true &&
      this.state.camera_and_photo_library_permission_description.trim() === ""
    ) {
      this.setState({
        errormodal: true,
        title: "Camera & Media library description",
        errormessage: "Please Enter Camera & Media library description",
      });
    } else if (
      this.state.is_enable_app_tracking_permission === true &&
      this.state.app_tracking_permission_description.trim() === ""
    ) {
      this.setState({
        errormodal: true,
        title: "Tracking Permission description",
        errormessage: "Please Enter Tracking Permission description",
      });
    } else {
      if (checker === 2) {
        this.setState({
          submitval2: "",
          loader2: "d-block",
        });
      } else if (checker === undefined && this.state.allowBuildBtn === true) {
        this.setState({
          submitval: "",
          loader: "d-block",
        });
      }
      this.setState({
        allowResetBtn: false,
      });
      this.UploadcertificatetoS3(checker);
    }
  };

  handleSave = (e) => {
    if (this.state.allowSaveBtn === true) {
      this.iossubmit(e, 2);
    }
  };
  handleReset = (e) => {
    // this.setState({

    // });

    const param = {
      app_id: parseInt(localStorage.getItem("Appid")),
      type: "ios",
    };
    if (this.state.allowResetBtn === true) {
      this.setState({
        submitval3: "",
        loader3: "d-block",
        allowSaveBtn: false,
        allowBuildBtn: false,
      });
      iosAndroidBuildReset(param, this);
    }
  };

  handleis_supporting_ipad = (e) => {
    this.setState({
      is_supporting_ipad: e.target.checked,
    });
  };
  handleselection = (id) => {
    var copyText = document.getElementById(id);
    var textArea = document.createElement("textarea");
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
  
  };

  handleRightPanelExpand = (value) => {

    this.setState({
      appRightPanelExpand: value
    })
    }

  render() {
    if (this.state.app_data !== null) {
      if (
        (this.state.build_process === 1 ||
          // this.state.app_data.dashboard_screen_count === 0 ||
          this.state.app_data.multisite.is_app_link_to_multisite === 1 ||
          this.state.app_data.user_status !== 1 ||
          this.state.app_data.plan === "preview" ||
          this.state.app_data.plan_code === "starter_monthly" ||
          this.state.app_data.plan_code === "starter_yearly" ||
          this.state.app_data.plan_code === "webtoapp_starter_yearly" ||
          this.state.app_data.plan_code === "webtoapp_starter_monthly") 
          &&
        (parseInt(localStorage.getItem("website_technology")) === 4 || parseInt(localStorage.getItem("website_technology")) === 5)
      ) {
        this.props.history.push("/download-app");
      }
      // if (
      //   parseInt(localStorage.getItem("website_technology")) === 4
      // ) {
      //   if (this.state.app_data.is_verified === 0) {
      //     this.props.history.push("/download-app");
      //   }
      // }
      // if (
      //   parseInt(localStorage.getItem("website_technology")) === 5
      // ) {
      //   if (
      //     this.state.app_data.is_verified === 0 ||
      //     this.state.app_data.is_connected !== 1
      //   ) {
      //     this.props.history.push("/download-app");
      //   }
      // }
    }

    return (
      <>
        {this.state.errormodal === true && <Errormessage value={this} />}
        <div className="d-md-block">
          <Header customclass={"header-responsive-new"} />
        </div>
        <section className="dasboard_page mobile-display mobile-view-download">
        <Leftpanel handleRightPanelExpand={this.handleRightPanelExpand} />
          <div className="transparent_background">
            <div className="right_panel custon_gap2 pt-md-4 pt-0 mb-md-0">
              <div className="right_general">
                <div
                  className="d-flex justify-content-between pb-md-3 mobile-view-ios-build"
                  style={{ borderBottom: "1px solid #ced4da" }}
                >
                  <Topsection />
                  <div className="d-flex">
                    {this.state.modalspinner === false && (
                      <div className="d-flex justify-content-end align-items-center ios-modal-bottom  p-0 border-0 fixed-button cus-btn-mobile cus-reset">
                        <button
                          type="button"
                          className={styles.buttonfixed + " next_btn buildbtn"}
                          form="iosform"
                          disabled={this.state.disabledsubmitbutton}
                          style={{
                            background:
                              this.state.disabledsubmitbutton === true
                                ? "rgb(112, 155, 250)"
                                : "#3064F9",
                            borderColor:
                              this.state.disabledsubmitbutton === true
                                ? "rgb(112, 155, 250)"
                                : "#3064F9",
                          }}
                          onClick={(e) => this.handleReset(e)}
                        >
                          <div className="d-flex align-items-center justify-content-center h-100 w-100">
                            <div className={this.state.loader3}>
                              <img src={this.state.loaderimage3} alt="loader" />
                            </div>
                            <div>{this.state.submitval3}</div>
                          </div>
                        </button>
                      </div>
                    )}
                    {this.state.modalspinner === false && (
                      <div className="d-flex justify-content-end align-items-center ios-modal-bottom  p-0 border-0 fixed-button cus-btn-mobile cus-save">
                        <button
                          type="button"
                          className={styles.buttonfixed + " next_btn buildbtn"}
                          form="iosform"
                          disabled={this.state.disabledsubmitbutton}
                          style={{
                            background:
                              this.state.disabledsubmitbutton === true
                                ? "rgb(112, 155, 250)"
                                : "#3064F9",
                            borderColor:
                              this.state.disabledsubmitbutton === true
                                ? "rgb(112, 155, 250)"
                                : "#3064F9",
                          }}
                          onClick={(e) => this.handleSave(e)}
                        >
                          <div className="d-flex align-items-center justify-content-center h-100 w-100">
                            <div className={this.state.loader2}>
                              <img src={this.state.loaderimage2} alt="loader" />
                            </div>
                            <div>{this.state.submitval2}</div>
                          </div>
                        </button>
                      </div>
                    )}

                    {this.state.modalspinner === false && (
                      <div className="d-flex justify-content-end align-items-center ios-modal-bottom  p-0 border-0 fixed-button cus-btn-mobile cus-build">
                        <button
                          type="submit"
                          className={styles.buttonfixed + " next_btn buildbtn"}
                          form="iosform"
                          disabled={this.state.disabledsubmitbutton}
                          style={{
                            background:
                              this.state.disabledsubmitbutton === true
                                ? "rgb(112, 155, 250)"
                                : localStorage.getItem('button_background_color'),
                            borderColor:
                              this.state.disabledsubmitbutton === true
                                ? "rgb(112, 155, 250)"
                                : localStorage.getItem('button_background_color'),
                            color:localStorage.getItem('button_text_color'),
                            "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                            "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
                            "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-center h-100 w-100">
                            <div className={this.state.loader}>
                              <img src={this.state.loaderimage} alt="loader" />
                            </div>
                            <div>{this.state.submitval}</div>
                          </div>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-3 pb-3 d-md-flex align-items-center justify-content-end">
                  <div
                    className={
                      styles.bundleId +
                      " d-flex  align-items-center " +
                      styles.mobile_ios_view
                    }
                  >
                    <p className="mb-0">
                      {" "}
                      <span>Your Bundle ID:</span>{" "}
                      <span id="ios_bundle" style={{ color: "#1B2952" }}>
                        {this.state.ios_status === true
                          ? this.state.ios_bundle_id
                          : this.state.bundle_identifier}
                      </span>
                    </p>
                    <img
                      className="ml-2"
                      src={
                        require("../../assets/images/dashboard/config/build/copy.png")
                          .default
                      }
                      alt="copy"
                      onClick={(e) => this.handleselection("ios_bundle")}
                    />
                  </div>
                </div>

                <div>
                  {this.state.modalspinner ? (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ height: "200px" }}
                    >
                       <div className="newspinner">
                <svg xmlns="http://www.w3.org/2000/svg" 
          xlink="http://www.w3.org/1999/xlink" 
          style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
          width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
            style={{animationplaystate: "running", animationdelay: "0s"}}>
            </animateTransform>
          </circle>
          </g>
          <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
          style={{animationplaystate: "running", animationdelay: "0s"}}>
          </animateTransform>
          </circle>
          </g>
          <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
          style={{animationplaystate: "running", animationdelay: "0s"}}>

          </animateTransform>
          </circle>
          </g>
          <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
          style={{animationplaystate: "running", animationdelay: "0s"}}>

          </animateTransform>
          </circle>
          </g>
          </svg>
              </div>
                    </div>
                  ) : (
                    <>
                      <form
                        id="iosform"
                        onSubmit={this.iossubmit}
                        className="d-md-flex justify-content-between provision-certificate pt-md-0 mobile-ios-rebuild-padding"
                        style={{ height: "auto" }}
                      >
                        <div className="w-100 new_width_input">
                          <div className="mb-md-4">
                            <div className="d-flex align-items-center justify-content-start mb-md-3">
                              <div>
                                <img
                                  className="mr-2 mr-md-3"
                                  src={
                                    require("../../assets/images/dashboard/config/app-store-1.png")
                                      .default
                                  }
                                  alt="app-store"
                                />
                              </div>
                              <div className="heading_config">
                                <h4 className="m-0">Apple App Store</h4>
                                <h5 className="mt-2 align-items-center justify-content-start mb-0">
                                  Connect your app to an associated
                                  Apple Developer account.
                                  {/* <Media
                                    style={{
                                      marginLeft: "5px",
                                      textDecoration: "none",
                                      display: "inline",
                                    }}
                                    href="https://www.appmysite.com/support/category/certificates-required/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Learn More{" "}
                                    <img
                                      src={
                                        require("../../assets/images/dashboard/Goto.png")
                                          .default
                                      }
                                      className="gotoappicon appear-spacing"
                                      alt="Goto"
                                    />
                                  </Media> */}
                                </h5>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-start flex-flow-row">
                              <div className="new_division">
                                <div className="grey-content upload-provision">
                                  <div className="">
                                    <div className="">
                                      {/* Tool tip */}
                                      <Tooltip
                                        firsttag={"h3"}
                                        tagclass={"mb-0 mr-2"}
                                        classdata={
                                          "d-flex justify-content-start align-items-center"
                                        }
                                        heading={"Team ID"}
                                        content={
                                          "Go to your Apple Developer account, navigate to My Account > Membership, and copy your Team ID. "
                                        }
                                      />
                                      {/* Tool tip */}
                                    </div>
                                    <div className="upload-btn text-right custom-class-add pt-md-0 mt-md-1">
                                      <input
                                        type="text"
                                        className="w-100 form-control form-name certificate-file"
                                        required
                                        placeholder="Enter team ID"
                                        value={this.state.teamid}
                                        onChange={this.handleteamid}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="grey-content upload-provision">
                                  <div className="">
                                    <div className="">
                                      {/* Tool tip */}
                                      <Tooltip
                                        firsttag={"h3"}
                                        tagclass={"mb-0 mr-2"}
                                        classdata={
                                          "d-flex justify-content-start align-items-center"
                                        }
                                        heading={
                                          "iPhone distribution certificate password"
                                        }
                                        content={
                                          "Enter the password of your iPhone Distribution certificate. Leave the field blank if the certificate is not password-protected.  "
                                        }
                                      />
                                      {/* Tool tip */}
                                    </div>
                                    <div className="upload-btn text-right custom-class-add pt-md-0 mt-md-1">
                                      <input
                                        type="password"
                                        className="w-100 form-control form-name certificate-file"
                                        placeholder="Password"
                                        value={this.state.certificate_password}
                                        onChange={
                                          this.handlecertificate_password
                                        }
                                        autoComplete="new-password"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="new_division">
                                <div className="grey-content upload-provision">
                                  <div className="">
                                    <div className="d-block d-md-flex justify-content-between align-items-center mb-2 mb-md-0">
                                      {/* Tool tip */}
                                      <Tooltip
                                        firsttag={"h3"}
                                        tagclass={"mb-0 mr-2"}
                                        classdata={
                                          "d-flex justify-content-start align-items-center"
                                        }
                                        heading={
                                          "iPhone Distribution Certificate"
                                        }
                                        content={
                                          "In your Apple Developer account, go to Certificates, Identifiers and Profiles > Certificates > Create a new certificate.   "
                                        }
                                      />
                                    </div>
                                    <div className=" upload-btn text-right ios-buid-width-fix new-button-upload justify-content-between mt-1">
                                      <input
                                        type="file"
                                        id="certificate"
                                        onChange={(e) =>
                                          this.handlecertificate(e)
                                        }
                                        style={{ display: "none" }}
                                      />
                                      <label
                                        htmlFor="certificate"
                                        className={
                                          this.state.certificatefilename.indexOf(
                                            "supported"
                                          ) > -1
                                            ? "notuploaded"
                                            : "fileuploaded"
                                        }
                                      >
                                        {this.state.certificatefilename.indexOf(
                                          "supported"
                                        ) > -1 && (
                                          <svg
                                            className="mr-2"
                                            style={{ marginTop: "-4px" }}
                                            width="9.944"
                                            height="13.214"
                                            viewBox="0 0 9.944 13.214"
                                          >
                                            <g
                                              id="Group_2921"
                                              data-name="Group 2921"
                                              transform="translate(0.53 1.061)"
                                            >
                                              <path
                                                id="Path_6311"
                                                data-name="Path 6311"
                                                d="M14161.5-3240.188v-12.1"
                                                transform="translate(-14157.059 3252.341)"
                                                fill="none"
                                                stroke="#7782A1"
                                                strokeWidth="1.5"
                                              />
                                              <path
                                                id="Path_6312"
                                                data-name="Path 6312"
                                                d="M6.281,6.281V0H0"
                                                transform="translate(0 4.441) rotate(-45)"
                                                fill="none"
                                                stroke="#7782A1"
                                                strokeWidth="1.5"
                                              />
                                            </g>
                                          </svg>
                                        )}
                                        {this.state.certificatefilename.indexOf(
                                          "supported"
                                        ) > -1
                                          ? "Upload File"
                                          : "Uploaded"}
                                      </label>
                                      <div className="d-flex justify-content-end align-items-center uploaded-file ">
                                        {this.state.appid !== "" ? (
                                          <Media
                                            className="align-items-center text-decoration-none"
                                            target="_blank"
                                            href={
                                              Url +
                                              this.state.userid +
                                              "/" +
                                              this.state.appid +
                                              "/" +
                                              newUrl +
                                              (this.state.certificatefilename.indexOf(
                                                ".p12"
                                              ) === -1
                                                ? this.state
                                                    .certificatefilename +
                                                  ".p12"
                                                : this.state
                                                    .certificatefilename)
                                            }
                                            rel="noopener noreferrer"
                                            tag={
                                              this.state.certificatefilename.indexOf(
                                                "supported"
                                              ) === -1
                                                ? "a"
                                                : "p"
                                            }
                                          >
                                            {" "}
                                            {this.state.certificatefilename.indexOf(
                                              ".p12"
                                            ) === -1
                                              ? this.state.certificatefilename +
                                                ".p12"
                                              : this.state
                                                  .certificatefilename}{" "}
                                            <img
                                              src={
                                                require("../../assets/images/dashboard/Goto.png")
                                                  .default
                                              }
                                              className="gotoappicon appear-spacing"
                                              alt="Goto"
                                            />
                                          </Media>
                                        ) : (
                                          this.state.certificatefilename
                                        )}

                                        {this.state.certificatefilename.indexOf(
                                          "supported"
                                        ) === -1 && (
                                          <label
                                            htmlFor="certificate"
                                            className="labcur"
                                          >
                                            <img
                                              src={
                                                require("../../assets/images/dashboard/red-cross.png")
                                                  .default
                                              }
                                              style={{ marginRight: "12px" }}
                                              alt="step"
                                              className="ml-3"
                                              width="11.5px"
                                              height="11.5px"
                                            />
                                          </label>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="grey-content upload-provision">
                                  <div className="">
                                    <div className="d-block d-md-flex justify-content-between align-items-center mb-2 mb-md-0">
                                      {/* Tool tip */}
                                      <Tooltip
                                        firsttag={"h3"}
                                        tagclass={"mb-0 mr-2"}
                                        classdata={
                                          "d-flex justify-content-start align-items-center"
                                        }
                                        heading={"iPhone Provisioning Profile"}
                                        content={
                                          "In your Apple Developer account, go to Certificates, Identifiers and Profiles > Profiles. Download the corresponding app ID and distribution certificate.  "
                                        }
                                      />
                                      {/* Tool tip */}
                                    </div>
                                    <div className="upload-btn text-right ios-buid-width-fix new-button-upload mt-1 justify-content-between">
                                      <input
                                        type="file"
                                        id="provision2"
                                        onChange={(e) =>
                                          this.handleprovision2(e)
                                        }
                                        style={{ display: "none" }}
                                      />
                                      <label
                                        htmlFor="provision2"
                                        className={
                                          this.state.provision2filename.indexOf(
                                            "supported"
                                          ) > -1
                                            ? "notuploaded"
                                            : "fileuploaded"
                                        }
                                      >
                                        {this.state.provision2filename.indexOf(
                                          "supported"
                                        ) > -1 && (
                                          <svg
                                            className="mr-2"
                                            style={{ marginTop: "-4px" }}
                                            width="9.944"
                                            height="13.214"
                                            viewBox="0 0 9.944 13.214"
                                          >
                                            <g
                                              id="Group_2921"
                                              data-name="Group 2921"
                                              transform="translate(0.53 1.061)"
                                            >
                                              <path
                                                id="Path_6311"
                                                data-name="Path 6311"
                                                d="M14161.5-3240.188v-12.1"
                                                transform="translate(-14157.059 3252.341)"
                                                fill="none"
                                                stroke="#7782A1"
                                                strokeWidth="1.5"
                                              />
                                              <path
                                                id="Path_6312"
                                                data-name="Path 6312"
                                                d="M6.281,6.281V0H0"
                                                transform="translate(0 4.441) rotate(-45)"
                                                fill="none"
                                                stroke="#7782A1"
                                                strokeWidth="1.5"
                                              />
                                            </g>
                                          </svg>
                                        )}
                                        {this.state.provision2filename.indexOf(
                                          "supported"
                                        ) > -1
                                          ? "Upload File"
                                          : "Uploaded"}
                                      </label>
                                      <div className="d-flex justify-content-end align-items-center uploaded-file">
                                        {this.state.appid !== "" ? (
                                          <Media
                                            className="align-items-center text-decoration-none"
                                            target="_blank"
                                            href={
                                              Url +
                                              this.state.userid +
                                              "/" +
                                              this.state.appid +
                                              "/" +
                                              iosUrl +
                                              (this.state.provision2filename.indexOf(
                                                ".mobileprovision"
                                              ) === -1
                                                ? this.state
                                                    .provision2filename +
                                                  ".mobileprovision"
                                                : this.state.provision2filename)
                                            }
                                            rel="noopener noreferrer"
                                            tag={
                                              this.state.provision2filename.indexOf(
                                                "supported"
                                              ) === -1
                                                ? "a"
                                                : "p"
                                            }
                                          >
                                            {" "}
                                            {this.state.provision2filename.indexOf(
                                              ".mobileprovision"
                                            ) === -1
                                              ? this.state.provision2filename +
                                                ".mobileprovision"
                                              : this.state
                                                  .provision2filename}{" "}
                                            <img
                                              src={
                                                require("../../assets/images/dashboard/Goto.png")
                                                  .default
                                              }
                                              className="gotoappicon appear-spacing"
                                              alt="Goto"
                                            />
                                          </Media>
                                        ) : (
                                          this.state.provision2filename
                                        )}
                                        {this.state.provision2filename.indexOf(
                                          "supported"
                                        ) === -1 && (
                                          <label
                                            htmlFor="provision2"
                                            className="labcur"
                                          >
                                            <img
                                              src={
                                                require("../../assets/images/dashboard/red-cross.png")
                                                  .default
                                              }
                                              style={{ marginRight: "12px" }}
                                              alt="step"
                                              className="ml-3"
                                              width="11.5px"
                                              height="11.5px"
                                            />
                                          </label>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-start flex-flow-row">
                              <div className="new_division">
                                <div className="grey-content upload-provision">
                                  <div className=" d-flex justify-content-between align-items-center mobile_padd_issue">
                                    <div className="ios-buid-width-change w-100">
                                      {/* Tool tip */}
                                      <Tooltip
                                        firsttag={"h3"}
                                        tagclass={"mb-1 mr-2"}
                                        classdata={
                                          "d-flex justify-content-start align-items-center"
                                        }
                                        heading={"Enable keychain? "}
                                        content={""}
                                      />
                                      {/* Tool tip */}
                                    </div>
                                    <div className="upload-btn text-right ios-buid-width-fix w-25">
                                      <div className="on_off d-flex  align-items-center justify-content-end">
                                        <label
                                          className="switch"
                                          htmlFor="is_enable_keychain"
                                        >
                                          <input
                                            type="checkbox"
                                            id="is_enable_keychain"
                                            checked={
                                              this.state.is_enable_keychain
                                            }
                                            onChange={(e) =>
                                              this.handlekeychaintoggle(e)
                                            }
                                          />
                                          <div className="slider round"></div>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="heading_config keychain-subtext-space">
                                    <h5
                                      style={{ fontSize: "13px" }}
                                      className="align-items-center justify-content-start mb-0"
                                    >
                                      With Keychain, your app users can keep
                                      their login credentials and other secure
                                      information updated across your devices,
                                      enabling a better login experience.
                                    </h5>
                                  </div>
                                </div>

                                {this.state.is_enable_keychain && (
                                  <>
                                    <div className="grey-content upload-provision ">
                                      <div className="">
                                        {/* Tool tip */}
                                        <Tooltip
                                          firsttag={"h3"}
                                          tagclass={"mb-0 mr-2"}
                                          classdata={
                                            "d-flex justify-content-start align-items-center"
                                          }
                                          heading={"Domain name"}
                                          content={
                                            'By default the uploaded iPhone Provisioning Profile does not include the "Associated Domains" and "AutoFill Credential Provider" permissions. You will need to regenerate your iPhone Provisioning Profile after adding these permissions.'
                                          }
                                        />
                                        {/* Tool tip */}
                                      </div>
                                      <div
                                        className="upload-btn text-right custom-class-add pt-md-0  mt-md-1"
                                        style={{ position: "relative" }}
                                      >
                                        <div className="d-flex justify-content-between">
                                          <input
                                            type="text"
                                            className="w-100 form-control form-name certificate-file"
                                            placeholder="example.com"
                                            value={this.state.keychain_url}
                                            required
                                            onBlur={(e) =>
                                              this.handlekeychain_urlOnBlur(e)
                                            }
                                            onChange={(e) =>
                                              this.handlekeychain_url(e)
                                            }
                                          />
                                          <div
                                            className="d-flex justify-content-end align-items-center uploaded-file"
                                            style={{
                                              position: "absolute",
                                              right: "0px",
                                              top: "0px",
                                              bottom: "0px",
                                              margin: "auto",
                                            }}
                                          >
                                            {this.state.keychain_url !== null &&
                                              this.state.keychain_url.trim() !==
                                                "" && (
                                                <p
                                                  className="file-supported pr-3"
                                                  style={{
                                                    color: "#3064F9",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={(e) =>
                                                    this.handlekeygenerate(e)
                                                  }
                                                >
                                                  Download{" "}
                                                  <img
                                                    src={
                                                      require("../../assets/images/dashboard/Goto.png")
                                                        .default
                                                    }
                                                    className="gotoappicon appear-spacing"
                                                    alt="Goto"
                                                  />
                                                </p>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="new_division">
                                <div className="grey-content upload-provision">
                                  <div className=" d-flex justify-content-between align-items-center mobile_padd_issue">
                                    <div className="ios-buid-width-change w-100">
                                      {/* Tool tip */}
                                      <Tooltip
                                        firsttag={"h3"}
                                        tagclass={"mb-1 mr-2"}
                                        classdata={
                                          "d-flex justify-content-start align-items-center"
                                        }
                                        heading={"Support for iPad"}
                                        content={""}
                                      />
                                      {/* Tool tip */}
                                    </div>
                                    <div className="upload-btn text-right ios-buid-width-fix w-25">
                                      <div className="on_off d-flex  align-items-center justify-content-end">
                                        <label className="switch">
                                          <input
                                            type="checkbox"
                                            checked={
                                              this.state.is_supporting_ipad
                                            }
                                            onChange={(e) =>
                                              this.handleis_supporting_ipad(e)
                                            }
                                          />
                                          <div className="slider round"></div>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="heading_config keychain-subtext-space">
                                    <h5
                                      style={{ fontSize: "13px" }}
                                      className="align-items-center justify-content-start mb-0"
                                    >
                                      Enable iPad support if your previously
                                      published iOS app supported iPad devices.
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mt-md-3 mb-md-4">
                            <div className=" ad-mob-div d-flex align-items-center justify-content-start mt-3 mb-md-2">
                              <div>
                                <img
                                  className="mr-2 mr-md-3"
                                  src={
                                    require("../../assets/images/dashboard/config/fire.png")
                                      .default
                                  }
                                  alt="app-store"
                                />
                              </div>
                              <div className="heading_config">
                                <h4 className="m-0">Google Firebase</h4>
                                <h5 className="mt-2 align-items-center justify-content-start mb-0">
                                  Connect your app to your Firebase
                                  project to enable push notifications and
                                  social login
                                  {/* <Media
                                    style={{
                                      marginLeft: "5px",
                                      textDecoration: "none",
                                      display: "inline",
                                    }}
                                    href="https://www.appmysite.com/support/how-to-configure-push-notifications-for-ios/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Learn More{" "}
                                    <img
                                      src={
                                        require("../../assets/images/dashboard/Goto.png")
                                          .default
                                      }
                                      className="gotoappicon appear-spacing"
                                      alt="Goto"
                                    />
                                  </Media> */}
                                </h5>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-start flex-flow-row">
                              <div className="new_division">
                                <div className="grey-content upload-provision">
                                  <div className=" d-flex justify-content-between align-items-center mobile_padd_issue">
                                    <div className="ios-buid-width-change w-100">
                                      {/* Tool tip */}
                                      <Tooltip
                                        firsttag={"h3"}
                                        tagclass={"mb-1 mr-2"}
                                        classdata={
                                          "d-flex justify-content-start align-items-center"
                                        }
                                        heading={"Enable Push Notifications? "}
                                        content={""}
                                      />
                                      {/* Tool tip */}
                                    </div>
                                    <div className="upload-btn text-right ios-buid-width-fix w-25">
                                      <div className="on_off d-flex  align-items-center justify-content-end">
                                        <label
                                          className="switch"
                                          htmlFor="ios_rebuild"
                                        >
                                          <input
                                            type="checkbox"
                                            id="ios_rebuild"
                                            checked={this.state.pushtoggle}
                                            onChange={(e) =>
                                              this.handlepushnotificationtoggle(
                                                e
                                              )
                                            }
                                          />
                                          <div className="slider round"></div>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="heading_config">
                                    <h5
                                      style={{ fontSize: "13px" }}
                                      className="align-items-center justify-content-start mb-0"
                                    >
                                      Turn on to enable push notifications in
                                      your app.
                                    </h5>
                                  </div>
                                </div>

                                {this.state.pushtoggle && (
                                  <>
                                    <div className="grey-content upload-provision ">
                                      <div>
                                        <div className="">
                                          {/* Tool tip */}
                                          <Tooltip
                                            firsttag={"h3"}
                                            tagclass={"mb-0 mr-2"}
                                            classdata={
                                              "d-flex justify-content-start align-items-center"
                                            }
                                            heading={
                                              "Google Firebase Server Key"
                                            }
                                            content={
                                              " Go to Project Settings > Cloud Messaging > Server key in your Firebase account."
                                            }
                                          />
                                          {/* Tool tip */}
                                        </div>
                                        <div className="upload-btn text-right custom-class-add pt-md-0  mt-md-1">
                                          <input
                                            type="text"
                                            className="w-100 form-control form-name certificate-file"
                                            placeholder="AAAAO6UeSfU:APA91bHNXci_xS5D"
                                            value={this.state.serverkey}
                                            onChange={this.handleserver_key}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="new_division">
                                {parseInt(
                                  localStorage.getItem("website_technology")
                                ) !== 3 && (
                                  <div className="grey-content upload-provision ">
                                    <div className=" d-flex justify-content-between align-items-center mobile_padd_issue">
                                      <div className="ios-buid-width-change w-75">
                                        {/* Tool tip */}
                                        <Tooltip
                                          firsttag={"h3"}
                                          tagclass={"mb-1 mr-2"}
                                          classdata={
                                            "d-flex justify-content-start align-items-center"
                                          }
                                          heading={"Enable Social Logins?"}
                                          content={""}
                                        />
                                        {/* Tool tip */}
                                      </div>
                                      {this.state.is_social_login_enabled ===
                                        0 &&
                                        this.state.google_enable === true && (
                                          <div className="new-hover-element mt-1 new-hover-social">
                                            <img
                                              className="ml-2 mt-0"
                                              src={
                                                require("../../assets/images/dashboard/socialwarning.png")
                                                  .default
                                              }
                                            />
                                            <span className="new-hover-element-text custom-hover-btn cus-ischat">
                                              Please enable social login in user
                                              setting
                                            </span>
                                          </div>
                                        )}
                                      <div className="upload-btn text-right ios-buid-width-fix w-25 ">
                                        <div className="on_off d-flex  align-items-center justify-content-end">
                                          <label
                                            className="switch"
                                            htmlFor="google_enable"
                                          >
                                            <input
                                              type="checkbox"
                                              id="google_enable"
                                              checked={this.state.google_enable}
                                              onChange={(e) =>
                                                this.handlegoogle_enable(e)
                                              }
                                            />
                                            <div className="slider round"></div>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="heading_config">
                                      <h5
                                        style={{ fontSize: "13px" }}
                                        className=" align-items-center justify-content-start mb-0"
                                      >
                                        Turn on to enable social login in your
                                        app.
                                        {/* <Media
                            style={{
                              marginLeft: "5px",
                              textDecoration: "none",
                              display:"inline"
                            }}
                            href="https://www.appmysite.com/support/category/download-ios-app/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Learn More
                          </Media> */}
                                      </h5>
                                    </div>
                                  </div>
                                )}
                                {(this.state.pushtoggle ||
                                  this.state.google_enable) && (
                                  <div className="grey-content upload-provision">
                                    <div className="">
                                      <div className="d-block d-md-flex justify-content-between align-items-center mb-2 mb-md-0">
                                        {/* Tool tip */}
                                        <Tooltip
                                          firsttag={"h3"}
                                          tagclass={"mb-0 mr-2"}
                                          classdata={
                                            "d-flex justify-content-start align-items-center"
                                          }
                                          heading={
                                            "Google Firebase Configuration"
                                          }
                                          content={
                                            "To obtain your Firebase configuration, go to Project Settings > General > Your Apps > GoogleService-Info.plist in your Firebase account. "
                                          }
                                        />
                                        {/* Tool tip */}

                                        {/* <p>
                              <span>Upload googleService-info.plist</span>
                            </p> */}
                                      </div>
                                      <div className="upload-btn text-right ios-buid-width-fix new-button-upload justify-content-between mt-1">
                                        <input
                                          type="file"
                                          id="ios_pushnotification"
                                          onChange={(e) =>
                                            this.handlepushnotification(e)
                                          }
                                          style={{ display: "none" }}
                                        />

                                        <label
                                          htmlFor="ios_pushnotification"
                                          className={
                                            this.state.pushnotificationfilename.indexOf(
                                              "supported"
                                            ) > -1
                                              ? "notuploaded"
                                              : "fileuploaded"
                                          }
                                        >
                                          {this.state.pushnotificationfilename.indexOf(
                                            "supported"
                                          ) > -1 && (
                                            <svg
                                              className="mr-2"
                                              style={{ marginTop: "-4px" }}
                                              width="9.944"
                                              height="13.214"
                                              viewBox="0 0 9.944 13.214"
                                            >
                                              <g
                                                id="Group_2921"
                                                data-name="Group 2921"
                                                transform="translate(0.53 1.061)"
                                              >
                                                <path
                                                  id="Path_6311"
                                                  data-name="Path 6311"
                                                  d="M14161.5-3240.188v-12.1"
                                                  transform="translate(-14157.059 3252.341)"
                                                  fill="none"
                                                  stroke="#7782A1"
                                                  strokeWidth="1.5"
                                                />
                                                <path
                                                  id="Path_6312"
                                                  data-name="Path 6312"
                                                  d="M6.281,6.281V0H0"
                                                  transform="translate(0 4.441) rotate(-45)"
                                                  fill="none"
                                                  stroke="#7782A1"
                                                  strokeWidth="1.5"
                                                />
                                              </g>
                                            </svg>
                                          )}
                                          {this.state.pushnotificationfilename.indexOf(
                                            "supported"
                                          ) > -1
                                            ? "Upload File"
                                            : "Uploaded"}
                                        </label>
                                        <div className="d-flex justify-content-end align-items-center uploaded-file">
                                          {this.state.appid !== "" ? (
                                            <Media
                                              className="align-items-center text-decoration-none"
                                              target="_blank"
                                              href={
                                                Url +
                                                this.state.userid +
                                                "/" +
                                                this.state.appid +
                                                "/" +
                                                titleUrl +
                                                (this.state.pushnotificationfilename.indexOf(
                                                  ".plist"
                                                ) === -1
                                                  ? this.state
                                                      .pushnotificationfilename +
                                                    ".plist"
                                                  : this.state
                                                      .pushnotificationfilename)
                                              }
                                              rel="noopener noreferrer"
                                              tag={
                                                this.state.pushnotificationfilename.indexOf(
                                                  "supported"
                                                ) === -1
                                                  ? "a"
                                                  : "p"
                                              }
                                            >
                                              {" "}
                                              {this.state.pushnotificationfilename.indexOf(
                                                ".plist"
                                              ) === -1
                                                ? this.state
                                                    .pushnotificationfilename +
                                                  ".plist"
                                                : this.state
                                                    .pushnotificationfilename}{" "}
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/Goto.png")
                                                    .default
                                                }
                                                className="gotoappicon appear-spacing"
                                                alt="Goto"
                                              />
                                            </Media>
                                          ) : (
                                            this.state.pushnotificationfilename
                                          )}
                                          {this.state.pushnotificationfilename.indexOf(
                                            "supported"
                                          ) === -1 && (
                                            <label
                                              htmlFor="ios_pushnotification"
                                              className="labcur"
                                            >
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/red-cross.png")
                                                    .default
                                                }
                                                style={{ marginRight: "12px" }}
                                                alt="step"
                                                className="ml-3"
                                                width="11.5px"
                                                height="11.5px"
                                              />
                                            </label>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="flex-flow-row">
                            <div className="d-flex align-items-center justify-content-start mt-md-3 mb-md-2 ad-mob-div">
                              <div>
                                <img
                                  className="mr-2 mr-md-3"
                                  src={
                                    require("../../assets/images/dashboard/config/admob.png")
                                      .default
                                  }
                                  alt="app-store"
                                />
                              </div>
                              <div className="heading_config">
                                <h4 className="m-0">Admob</h4>
                                <h5 className="mt-2 align-items-center justify-content-start mb-0">
                                  Connect your app to your Google
                                  AdMob account to display ads in your iOS app.
                                  {/* <Media
                                    style={{
                                      marginLeft: "5px",
                                      textDecoration: "none",
                                      display: "inline",
                                    }}
                                    href="https://www.appmysite.com/support/configure-ios-build-for-google-admob/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Learn More{" "}
                                    <img
                                      src={
                                        require("../../assets/images/dashboard/Goto.png")
                                          .default
                                      }
                                      className="gotoappicon appear-spacing"
                                      alt="Goto"
                                    />
                                  </Media> */}
                                </h5>
                              </div>
                            </div>
                            <div className="new_division">
                              <div className="grey-content upload-provision border-0">
                                <div className=" d-flex justify-content-between align-items-center mobile_padd_issue">
                                  <div className="ios-buid-width-change w-100">
                                    {/* Tool tip */}
                                    <Tooltip
                                      firsttag={"h3"}
                                      tagclass={"mb-1 mr-2"}
                                      classdata={
                                        "d-flex justify-content-start align-items-center"
                                      }
                                      heading={" Enable App Monetization?"}
                                      content={""}
                                    />
                                    {/* Tool tip */}
                                  </div>
                                  <div className="upload-btn text-right ios-buid-width-fix w-25">
                                    <div className="on_off d-flex  align-items-center justify-content-end">
                                      <label
                                        className="switch"
                                        htmlFor="ios_is_admob_enabled"
                                      >
                                        <input
                                          type="checkbox"
                                          id="ios_is_admob_enabled"
                                          checked={
                                            this.state.ios_is_admob_enabled
                                          }
                                          onChange={(e) =>
                                            this.handleios_is_admob_enabled(e)
                                          }
                                        />
                                        <div className="slider round"></div>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="heading_config">
                                  <h5
                                    style={{ fontSize: "13px" }}
                                    className="align-items-center justify-content-start mb-0"
                                  >
                                    Turn on to enable app monetization
                                    {/* <Media
                          style={{
                            marginLeft: "5px",
                            textDecoration: "none",display:"inline"
                          }}
                          href="https://www.appmysite.com/support/category/download-ios-app/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Learn More
                        </Media> */}
                                  </h5>
                                </div>
                              </div>
                              {this.state.ios_is_admob_enabled && (
                                <div className="grey-content upload-provision  border-0">
                                  <div className="mt-1">
                                    <div className="">
                                      {/* Tool tip */}
                                      <Tooltip
                                        firsttag={"h3"}
                                        tagclass={"mb-0 mr-2"}
                                        classdata={
                                          "d-flex justify-content-start align-items-center"
                                        }
                                        heading={"Admob App ID"}
                                        content={
                                          "Enter your Google AdMob App ID here. "
                                        }
                                      />
                                      {/* Tool tip */}
                                    </div>
                                    <div className="upload-btn text-right custom-class-add pt-md-0 mt-1">
                                      <input
                                        type="text"
                                        className="w-100 form-control form-name certificate-file"
                                        placeholder="ca-app-pub-7382648443445~23"
                                        required
                                        value={this.state.ios_admob_app_id}
                                        onChange={(e) =>
                                          this.handleios_admob_app_id(e)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="d-flex align-items-start justify-content-start mt-md-3 mb-md-2 flex-flow-row ad-mob-div">
                            <div className="d-flex align-items-center justify-content-start mt-1 mt-md-0 mb-md-2 mb-3">
                              <div>
                                <img
                                  className="mr-2 mr-md-3"
                                  src={
                                    require("../../assets/images/dashboard/config/build/lock.png")
                                      .default
                                  }
                                  alt="app-store"
                                />
                              </div>
                              <div className="heading_config">
                                <h4 className="m-0">App Permissions</h4>
                                <h5 className="mt-2 align-items-center justify-content-start mb-0">
                                  If you’re using web views, and your website
                                  requires access to native device features, you
                                  must enable them from here to avoid getting
                                  rejected by the app stores.
                                  {/* <Media
                          style={{
                            marginLeft: "5px",
                            textDecoration: "none",
                            display:"inline"
                          }}
                          href="https://www.appmysite.com/support/category/download-ios-app/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Learn More
                        </Media> */}
                                </h5>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-start flex-flow-row mb-md-3">
                            <div className="new_division">
                              <div className="grey-content upload-provision">
                                <div className=" d-flex justify-content-between align-items-center mobile_padd_issue">
                                  <div>
                                    <img
                                      className="mr-2 mr-md-3"
                                      src={
                                        require("../../assets/images/dashboard/config/build/map.png")
                                          .default
                                      }
                                      alt="app-store"
                                    />
                                  </div>
                                  <div className="ios-buid-width-change w-100">
                                    <h3>Location</h3>
                                    <div className="heading_config">
                                      <h5
                                        style={{ fontSize: "13px" }}
                                        className="d-flex align-items-center justify-content-start mb-0 mt-1"
                                      >
                                        Turn on if your app requires access to a
                                        user’s location.
                                      </h5>
                                    </div>
                                  </div>
                                  <div className="upload-btn text-right ios-buid-width-fix w-25">
                                    <div className="on_off d-flex  align-items-center justify-content-end">
                                      <label
                                        className="switch"
                                        htmlFor="is_enable_location_permission"
                                      >
                                        <input
                                          type="checkbox"
                                          id="is_enable_location_permission"
                                          name="is_enable_location_permission"
                                          checked={
                                            this.state
                                              .is_enable_location_permission
                                          }
                                          onChange={(e) =>
                                            this.handlepermission(e)
                                          }
                                        />
                                        <div className="slider round"></div>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {this.state.is_enable_location_permission ===
                                true && (
                                <div className="grey-content upload-provision pt-0">
                                  <div className=" mt-1">
                                    <div className="d-flex align-items-center">
                                      <h3
                                        style={{
                                          color:
                                            this.state
                                              .is_enable_location_permission ===
                                            true
                                              ? "#1B2952"
                                              : "#7782A1",
                                        }}
                                      >
                                        Alert message
                                      </h3>
                                    </div>
                                    <div className="upload-btn text-right custom-class-add pt-md-0 mt-md-2">
                                      <input
                                        type="text"
                                        className="w-100 form-control form-name certificate-file"
                                        value={
                                          this.state
                                            .location_permission_description
                                        }
                                        readOnly={
                                          !this.state
                                            .is_enable_location_permission
                                        }
                                        required={
                                          this.state
                                            .is_enable_location_permission
                                        }
                                        name="location_permission_description"
                                        onChange={(e) =>
                                          this.handlepermission_des(e)
                                        }
                                        placeholder="Tell your users why you need this permission?"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="grey-content upload-provision">
                                <div className=" d-flex justify-content-between align-items-center mobile_padd_issue">
                                  <div>
                                    <img
                                      className="mr-2 mr-md-3"
                                      src={
                                        require("../../assets/images/dashboard/config/build/profile.png")
                                          .default
                                      }
                                      alt="profile"
                                    />
                                  </div>
                                  <div className="ios-buid-width-change w-100">
                                    <h3>Contacts</h3>
                                    <div className="heading_config">
                                      <h5
                                        style={{ fontSize: "13px" }}
                                        className="d-flex align-items-center justify-content-start mb-0 mt-1"
                                      >
                                        Turn on if your app requires access to a
                                        user’s contacts.
                                      </h5>
                                    </div>
                                  </div>

                                  <div className="upload-btn text-right ios-buid-width-fix w-25">
                                    <div className="on_off d-flex  align-items-center justify-content-end">
                                      <label
                                        className="switch"
                                        htmlFor="is_enable_contacts_permission"
                                      >
                                        <input
                                          type="checkbox"
                                          id="is_enable_contacts_permission"
                                          name="is_enable_contacts_permission"
                                          checked={
                                            this.state
                                              .is_enable_contacts_permission
                                          }
                                          onChange={(e) =>
                                            this.handlepermission(e)
                                          }
                                        />
                                        <div className="slider round"></div>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {this.state.is_enable_contacts_permission ===
                                true && (
                                <div className="grey-content upload-provision pt-0">
                                  <div className=" mt-1">
                                    <div className="d-flex align-items-center">
                                      <h3
                                        style={{
                                          color:
                                            this.state
                                              .is_enable_contacts_permission ===
                                            true
                                              ? "#1B2952"
                                              : "#7782A1",
                                        }}
                                      >
                                        Alert message
                                      </h3>
                                    </div>
                                    <div className="upload-btn text-right custom-class-add pt-md-0 mt-md-2">
                                      <input
                                        type="text"
                                        className="w-100 form-control form-name certificate-file"
                                        value={
                                          this.state
                                            .contacts_permission_description
                                        }
                                        readOnly={
                                          !this.state
                                            .is_enable_contacts_permission
                                        }
                                        required={
                                          this.state
                                            .is_enable_contacts_permission
                                        }
                                        name="contacts_permission_description"
                                        onChange={(e) =>
                                          this.handlepermission_des(e)
                                        }
                                        placeholder="Tell your users why you need this permission?"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="grey-content upload-provision">
                                <div className=" d-flex justify-content-between align-items-center mobile_padd_issue">
                                  <div>
                                    <img
                                      className="mr-2 mr-md-3"
                                      src={
                                        require("../../assets/images/dashboard/config/build/micro.png")
                                          .default
                                      }
                                      alt="micro"
                                    />
                                  </div>
                                  <div className="ios-buid-width-change w-100">
                                    <h3>Microphone</h3>
                                    <div className="heading_config">
                                      <h5
                                        style={{ fontSize: "13px" }}
                                        className="d-flex align-items-center justify-content-start mb-0 mt-1"
                                      >
                                        Turn on if your app requires access to a
                                        user’s microphone.
                                      </h5>
                                    </div>
                                  </div>

                                  <div className="upload-btn text-right ios-buid-width-fix w-25">
                                    <div className="on_off d-flex  align-items-center justify-content-end">
                                      <label
                                        className="switch"
                                        htmlFor="is_enable_microphone_permission"
                                      >
                                        <input
                                          type="checkbox"
                                          id="is_enable_microphone_permission"
                                          name="is_enable_microphone_permission"
                                          checked={
                                            this.state
                                              .is_enable_microphone_permission
                                          }
                                          onChange={(e) =>
                                            this.handlepermission(e)
                                          }
                                        />
                                        <div className="slider round"></div>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {this.state.is_enable_microphone_permission ===
                                true && (
                                <div className="grey-content upload-provision pt-0">
                                  <div className=" mt-1">
                                    <div className="d-flex align-items-center">
                                      <h3
                                        style={{
                                          color:
                                            this.state
                                              .is_enable_microphone_permission ===
                                            true
                                              ? "#1B2952"
                                              : "#7782A1",
                                        }}
                                      >
                                        Alert message
                                      </h3>
                                    </div>
                                    <div className="upload-btn text-right custom-class-add pt-md-0 mt-md-2">
                                      <input
                                        type="text"
                                        className="w-100 form-control form-name certificate-file"
                                        value={
                                          this.state
                                            .microphone_permission_description
                                        }
                                        readOnly={
                                          !this.state
                                            .is_enable_microphone_permission
                                        }
                                        required={
                                          this.state
                                            .is_enable_microphone_permission
                                        }
                                        name="microphone_permission_description"
                                        onChange={(e) =>
                                          this.handlepermission_des(e)
                                        }
                                        placeholder="Tell your users why you need this permission?"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="grey-content upload-provision">
                                <div className=" d-flex justify-content-between align-items-center mobile_padd_issue">
                                  <div>
                                    <img
                                      className="mr-2 mr-md-3"
                                      src={
                                        require("../../assets/images/dashboard/config/build/radar.png")
                                          .default
                                      }
                                      alt="radar"
                                    />
                                  </div>
                                  <div className="ios-buid-width-change w-100">
                                    <h3>Speech recognition</h3>
                                    <div className="heading_config">
                                      <h5
                                        style={{ fontSize: "13px" }}
                                        className="d-flex align-items-center justify-content-start mb-0 mt-1"
                                      >
                                        Turn on if your app requires access to
                                        speech recognition.
                                      </h5>
                                    </div>
                                  </div>

                                  <div className="upload-btn text-right ios-buid-width-fix w-25">
                                    <div className="on_off d-flex  align-items-center justify-content-end">
                                      <label
                                        className="switch"
                                        htmlFor="is_enable_speech_recognition_permission"
                                      >
                                        <input
                                          type="checkbox"
                                          id="is_enable_speech_recognition_permission"
                                          name="is_enable_speech_recognition_permission"
                                          checked={
                                            this.state
                                              .is_enable_speech_recognition_permission
                                          }
                                          onChange={(e) =>
                                            this.handlepermission(e)
                                          }
                                        />
                                        <div className="slider round"></div>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {this.state
                                .is_enable_speech_recognition_permission ===
                                true && (
                                <div className="grey-content upload-provision pt-0">
                                  <div className=" mt-1">
                                    <div className="d-flex align-items-center">
                                      <h3
                                        style={{
                                          color:
                                            this.state
                                              .is_enable_speech_recognition_permission ===
                                            true
                                              ? "#1B2952"
                                              : "#7782A1",
                                        }}
                                      >
                                        Alert message
                                      </h3>
                                    </div>
                                    <div className="upload-btn text-right custom-class-add pt-md-0 mt-md-2">
                                      <input
                                        type="text"
                                        className="w-100 form-control form-name certificate-file"
                                        value={
                                          this.state
                                            .speech_recognition_permission_description
                                        }
                                        readOnly={
                                          !this.state
                                            .is_enable_speech_recognition_permission
                                        }
                                        required={
                                          this.state
                                            .is_enable_speech_recognition_permission
                                        }
                                        name="speech_recognition_permission_description"
                                        onChange={(e) =>
                                          this.handlepermission_des(e)
                                        }
                                        placeholder="Tell your users why you need this permission?"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="new_division">
                              <div className="grey-content upload-provision">
                                <div className=" d-flex justify-content-between align-items-center mobile_padd_issue">
                                  <div>
                                    <img
                                      className="mr-2 mr-md-3"
                                      src={
                                        require("../../assets/images/dashboard/config/build/calendar.png")
                                          .default
                                      }
                                      alt="app-store"
                                    />
                                  </div>
                                  <div className="ios-buid-width-change w-100">
                                    <h3>Calendar</h3>
                                    <div className="heading_config">
                                      <h5
                                        style={{ fontSize: "13px" }}
                                        className="d-flex align-items-center justify-content-start mb-0 mt-1"
                                      >
                                        Turn on if your app requires access to a
                                        user’s calendar.
                                      </h5>
                                    </div>
                                  </div>

                                  <div className="upload-btn text-right ios-buid-width-fix w-25">
                                    <div className="on_off d-flex  align-items-center justify-content-end">
                                      <label
                                        className="switch"
                                        htmlFor="is_enable_calender_permission"
                                      >
                                        <input
                                          type="checkbox"
                                          id="is_enable_calender_permission"
                                          name="is_enable_calender_permission"
                                          checked={
                                            this.state
                                              .is_enable_calender_permission
                                          }
                                          onChange={(e) =>
                                            this.handlepermission(e)
                                          }
                                        />
                                        <div className="slider round"></div>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {this.state.is_enable_calender_permission ===
                                true && (
                                <div className="grey-content upload-provision pt-0">
                                  <div className=" mt-1">
                                    <div className="d-flex align-items-center">
                                      <h3
                                        style={{
                                          color:
                                            this.state
                                              .is_enable_calender_permission ===
                                            true
                                              ? "#1B2952"
                                              : "#7782A1",
                                        }}
                                      >
                                        Alert message
                                      </h3>
                                    </div>
                                    <div className="upload-btn text-right custom-class-add pt-md-0 mt-md-2">
                                      <input
                                        type="text"
                                        className="w-100 form-control form-name certificate-file"
                                        value={
                                          this.state
                                            .calender_permission_description
                                        }
                                        readOnly={
                                          !this.state
                                            .is_enable_calender_permission
                                        }
                                        required={
                                          this.state
                                            .is_enable_calender_permission
                                        }
                                        name="calender_permission_description"
                                        onChange={(e) =>
                                          this.handlepermission_des(e)
                                        }
                                        placeholder="Tell your users why you need this permission?"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="grey-content upload-provision">
                                <div className=" d-flex justify-content-between align-items-center mobile_padd_issue">
                                  <div>
                                    <img
                                      className="mr-2 mr-md-3"
                                      src={
                                        require("../../assets/images/dashboard/config/build/bell.png")
                                          .default
                                      }
                                      alt="profile"
                                    />
                                  </div>
                                  <div className="ios-buid-width-change w-100">
                                    <h3>Reminders</h3>
                                    <div className="heading_config">
                                      <h5
                                        style={{ fontSize: "13px" }}
                                        className="d-flex align-items-center justify-content-start mb-0 mt-1"
                                      >
                                        Turn on if your app requires access to a
                                        user’s reminders.
                                      </h5>
                                    </div>
                                  </div>

                                  <div className="upload-btn text-right ios-buid-width-fix w-25">
                                    <div className="on_off d-flex  align-items-center justify-content-end">
                                      <label
                                        className="switch"
                                        htmlFor="is_enable_reminder_permission"
                                      >
                                        <input
                                          type="checkbox"
                                          id="is_enable_reminder_permission"
                                          name="is_enable_reminder_permission"
                                          checked={
                                            this.state
                                              .is_enable_reminder_permission
                                          }
                                          onChange={(e) =>
                                            this.handlepermission(e)
                                          }
                                        />
                                        <div className="slider round"></div>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {this.state.is_enable_reminder_permission ===
                                true && (
                                <div className="grey-content upload-provision pt-0">
                                  <div className=" mt-1">
                                    <div className="d-flex align-items-center">
                                      <h3
                                        style={{
                                          color:
                                            this.state
                                              .is_enable_reminder_permission ===
                                            true
                                              ? "#1B2952"
                                              : "#7782A1",
                                        }}
                                      >
                                        Alert message
                                      </h3>
                                    </div>
                                    <div className="upload-btn text-right custom-class-add pt-md-0 mt-md-2">
                                      <input
                                        type="text"
                                        className="w-100 form-control form-name certificate-file"
                                        value={
                                          this.state
                                            .reminder_permission_description
                                        }
                                        readOnly={
                                          !this.state
                                            .is_enable_reminder_permission
                                        }
                                        required={
                                          this.state
                                            .is_enable_reminder_permission
                                        }
                                        name="reminder_permission_description"
                                        onChange={(e) =>
                                          this.handlepermission_des(e)
                                        }
                                        placeholder="Tell your users why you need this permission?"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="grey-content upload-provision">
                                <div className=" d-flex justify-content-between align-items-center mobile_padd_issue">
                                  <div>
                                    <img
                                      className="mr-2 mr-md-3"
                                      src={
                                        require("../../assets/images/dashboard/config/build/camera.png")
                                          .default
                                      }
                                      alt="camera"
                                    />
                                  </div>
                                  <div className="ios-buid-width-change w-100">
                                    <h3>Camera &amp; Media library</h3>
                                    <div className="heading_config">
                                      <h5
                                        style={{ fontSize: "13px" }}
                                        className="d-flex align-items-center justify-content-start mb-0 mt-1"
                                      >
                                        Turn on if your app requires access to a
                                        user’s camera or media library.
                                      </h5>
                                    </div>
                                  </div>
                                  <div className="upload-btn text-right ios-buid-width-fix w-25">
                                    <div className="on_off d-flex  align-items-center justify-content-end">
                                      <label
                                        className="switch"
                                        htmlFor="is_enable_camera_and_photo_library_permission"
                                      >
                                        <input
                                          type="checkbox"
                                          id="is_enable_camera_and_photo_library_permission"
                                          name="is_enable_camera_and_photo_library_permission"
                                          disabled={
                                            this.state.chat_preferences !==
                                              null &&
                                            this.state.chat_preferences.some(
                                              (res) =>
                                                res.module_status === 1
                                            )
                                          }
                                          checked={
                                            this.state
                                              .is_enable_camera_and_photo_library_permission
                                          }
                                          onChange={(e) =>
                                            this.handlepermission(e)
                                          }
                                        />
                                        <div className="slider round"></div>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {this.state
                                .is_enable_camera_and_photo_library_permission ===
                                true && (
                                <div className="grey-content upload-provision pt-0">
                                  <div className=" mt-1">
                                    <div className="d-flex align-items-center">
                                      <h3
                                        style={{
                                          color:
                                            this.state
                                              .is_enable_camera_and_photo_library_permission ===
                                            true
                                              ? "#1B2952"
                                              : "#7782A1",
                                        }}
                                      >
                                        Alert message
                                      </h3>
                                    </div>
                                    <div className="upload-btn text-right custom-class-add pt-md-0 mt-md-2">
                                      <input
                                        type="text"
                                        className="w-100 form-control form-name certificate-file"
                                        value={
                                          this.state
                                            .camera_and_photo_library_permission_description
                                        }
                                        readOnly={
                                          !this.state
                                            .is_enable_camera_and_photo_library_permission
                                        }
                                        required={
                                          this.state
                                            .is_enable_camera_and_photo_library_permission
                                        }
                                        name="camera_and_photo_library_permission_description"
                                        onChange={(e) =>
                                          this.handlepermission_des(e)
                                        }
                                        placeholder="Tell your users why you need this permission?"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="grey-content upload-provision">
                                <div className=" d-flex justify-content-between align-items-center mobile_padd_issue">
                                  <div>
                                    <img
                                      className="mr-2 mr-md-3"
                                      src={
                                        require("../../assets/images/dashboard/config/build/track.png")
                                          .default
                                      }
                                      alt="track"
                                    />
                                  </div>
                                  <div className="ios-buid-width-change w-100">
                                    <h3>Tracking Permission</h3>
                                    <div className="heading_config">
                                      <h5
                                        style={{ fontSize: "13px" }}
                                        className="d-flex align-items-center justify-content-start mb-0 mt-1"
                                      >
                                        Turn on if your website uses cookies to
                                        track user data.
                                      </h5>
                                    </div>
                                  </div>
                                  <div className="upload-btn text-right ios-buid-width-fix w-25">
                                    <div className="on_off d-flex  align-items-center justify-content-end">
                                      <label
                                        className="switch"
                                        htmlFor="is_enable_app_tracking_permission"
                                      >
                                        <input
                                          type="checkbox"
                                          id="is_enable_app_tracking_permission"
                                          name="is_enable_app_tracking_permission"
                                          checked={
                                            this.state
                                              .is_enable_app_tracking_permission
                                          }
                                          onChange={(e) =>
                                            this.handlepermission(e)
                                          }
                                        />
                                        <div className="slider round"></div>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {this.state.is_enable_app_tracking_permission ===
                                true && (
                                <div className="grey-content upload-provision pt-0">
                                  <div className=" mt-1">
                                    <div className="d-flex align-items-center">
                                      <h3
                                        style={{
                                          color:
                                            this.state
                                              .is_enable_app_tracking_permission ===
                                            true
                                              ? "#1B2952"
                                              : "#7782A1",
                                        }}
                                      >
                                        Alert message
                                      </h3>
                                    </div>
                                    <div className="upload-btn text-right custom-class-add pt-md-0 mt-md-2">
                                      <input
                                        type="text"
                                        className="w-100 form-control form-name certificate-file"
                                        value={
                                          this.state
                                            .app_tracking_permission_description
                                        }
                                        readOnly={
                                          !this.state
                                            .is_enable_app_tracking_permission
                                        }
                                        required={
                                          this.state
                                            .is_enable_app_tracking_permission
                                        }
                                        name="app_tracking_permission_description"
                                        onChange={(e) =>
                                          this.handlepermission_des(e)
                                        }
                                        placeholder="Tell your users why you need this permission?"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* {InnerFoot()} */}
          </div>
        </section>
      </>
    );
  }
}
