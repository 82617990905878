import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import "./ModuleNavigation.css";
const ModuleNavigations = ({ linkArr }) => {
  return (
    <div className=" moduleNavigations-main-div">
      {linkArr &&
        linkArr.map((item) => {
          return item.is_show ? (
            <Link
              key={item.link}
              to={item.link}
              className={item.is_active ? "moduleNavigation-active moduleNavigation " : "moduleNavigation"}
              style={{
                borderBottom: item.is_active
                  ? localStorage.getItem("agencyid") == 1
                    ? "2px solid #3064f9"
                    : "2px solid " +
                      localStorage.getItem("agency_primary_color")
                  : "",
                color: item.is_active
                  ? localStorage.getItem("agencyid") == 1
                    ? "#3064f9"
                    : localStorage.getItem("agency_primary_color")
                  : "",
                "--custom_color4":
                  localStorage.getItem("agencyid") == 1
                    ? "#3064f9"
                    : localStorage.getItem("agency_primary_color"),
                "--custom_color5":
                  localStorage.getItem("agencyid") == 1
                    ? "#3064f9"
                    : localStorage.getItem("agency_primary_color"),
              }}
            >
              {item.name}
            </Link>
          ) : null;
        })}
    </div>
  );
};

export default ModuleNavigations;
