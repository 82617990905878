import React from "react";
import Header from "../Header";
import DealList from "./Deal-list";
import { useParams } from "react-router-dom";
// import DealList from "./Deal-list";
import "./Billings.css";
function Dealselection(props) {
  const { appid } = useParams();

  const handleMyDeals = () => {
    window.location.href =  parseInt(localStorage.getItem('website_technology')) === 4 ? 
    "/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid'))
  : parseInt(localStorage.getItem('website_technology')) === 5 ? 
    "/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'))
  : parseInt(localStorage.getItem('website_technology')) === 3 ? 
    "/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'))
  : parseInt(localStorage.getItem('website_technology')) === 2 ? 
    "/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'))
  : "/app/pricing";;
  };
  return (
    <div>
      <div className="d-md-block">
        <Header customclass={"header-responsive-new"} />
      </div>
      {/* Mobile View */}
      {/* Mobile View End*/}
      <section className="dasboard_page mobile-view-download  container-xlg deals-page">
        <div className="transparent_background dealselect_background">
          <div className="d-flex justify-content-between align-items-center dealselect-heading">
            <h3>Select a deal</h3>
            <div
              className="dealselect-page-direct-btn"
              onClick={(e) => handleMyDeals()}
            >
              {/* <a className="deals-btn" href=""> */}
              <img
                src={
                  require("../../assets/images/dashboard/deals-arrow-grey.png")
                    .default
                }
                className="dealselect-arrow-img"
              ></img>{" "}
              <span>back to pricing plans</span>
              {/* </a> */}
            </div>
          </div>

          <DealList btnCheck={1} appId={appid} props={props} />
        </div>
      </section>
    </div>
  );
}

export default Dealselection;
