import React, { useEffect, useState } from "react";

const Title = ({
  indx,
  cmsArray,
  setcmsData,
  isRowOpen,
  handleIsRowOpen,
  // eachBlock,
  provided,
  from,
  title,
  setTitle,
}) => {
  const handleTitle = (e) => {
    setTitle(e.target.value);
    let newArr = [...cmsArray];
    newArr[indx].value.title.text = e.target.value;
    setcmsData(newArr);
  };


  return (
    <div 
    className={`accordionItem`}
    >
      {from === "post" ? (
        <h2
          class="accordionItemHeading allflex"
          draggableId={indx.toString()}
          {...provided.dragHandleProps}
        >
          Title
         <p className="cms-accordian-p"
            onClick={(e) => {
              handleIsRowOpen(e, indx);
            }}
          >
            <img
              src={
                isRowOpen[indx] === true
                  ? require("../../assets/images/dashboard/cms/accordian-open.png")
                      .default
                  : require("../../assets/images/dashboard/cms/accordian-icon.png")
                      .default
              }
            />
          </p>
        </h2>
      ) : (
        <h2 class="accordionItemHeading allflex non-draggable">
          Title
         <p className="cms-accordian-p"
            onClick={(e) => {
              handleIsRowOpen(e, indx);
            }}
          >
            <img
              src={
                isRowOpen[indx] === true
                  ? require("../../assets/images/dashboard/cms/accordian-open.png")
                      .default
                  : require("../../assets/images/dashboard/cms/accordian-icon.png")
                      .default
              }
            />
          </p>
        </h2>
      )}

      {isRowOpen[indx] === true && (
        <div class="accordionItemContent">
          <input
            type="text"
            placeholder={from !== "post"? "Enter your bold and clear title here." : "Enter title here"}
            maxLength={100}
            onChange={(e) => handleTitle(e)}
            value={cmsArray[indx].value.title.text}
          />
        </div>
      )}
    </div>
  );
};

export default Title;
