import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiEndPoints from "../../../utils/apiEndpoints";
import { prepareAuthHeaders } from "../../../utils/commonUtils";

export const basicNotificationSlice = createApi({
  reducerPath: "basicNotificationData",
  baseQuery: fetchBaseQuery({
    baseUrl: apiEndPoints.appUrl,
    prepareHeaders: (headers) => prepareAuthHeaders(headers),
  }),
  tagTypes: ["draft-update", "plainNotif-listing-update"],

  endpoints: (builder) => ({
    getPlainNotificationDetails: builder.query({
      query: () =>
        `${"get-plain-notification-data/"}${btoa(
          localStorage.getItem("Appid")
        )}`,
    }),
    getNotificationDetails: builder.query({
      query: (id) =>
        "get-notification-detail/" +
        btoa(localStorage.getItem("Appid")) +
        "/" +
        id,
    }),
    getAllPlainNotifications: builder.query({
      query: (data) =>
        `list-notifications/${btoa(localStorage.getItem("Appid"))}?page=${
          data.page ? data.page : 1
        }&limit=${data.limit ? data.limit : 20}${
          data.finalFilterVal ? data.finalFilterVal : ""
        }`,
      providesTags: ["plainNotif-listing-update", "draft-update"],
    }),
    saveDraft: builder.mutation({
      query: (body) => ({
        url: "save-draft-notification",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["draft-update"],
    }),
    sendNotification: builder.mutation({
      query: (body) => ({
        url: "send-notification",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["plainNotif-listing-update"],
    }),
    deleteNotification: builder.mutation({
      query: (body) => ({
        url: "delete-notification",
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useGetPlainNotificationDetailsQuery,
  useGetAllPlainNotificationsQuery,
  useLazyGetAllPlainNotificationsQuery,
  useLazyGetNotificationDetailsQuery,
  useSaveDraftMutation,
  useSendNotificationMutation,
  useDeleteNotificationMutation,
} = basicNotificationSlice;
