import React from 'react'

const Newfeatureicon = () => {
  return (
    <div className='newfeatureicon-div'>
        <p className='newfeatureicon-text'>New</p>
        <svg className="newfeatureicon-svg" width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M5.50586 0C5.50586 0 5.645 4.63325 11 5.53125C11 5.53125 6.13286 6.097 5.50586 11C5.50586 11 5.342 6.436 0 5.5C1.4187 5.37897 2.7485 4.76017 3.75586 3.75391C4.76322 2.74764 5.38329 1.41857 5.50586 0Z" fill="#3064F9"/>
        </svg>
    </div>
  )
}

export default Newfeatureicon