import React from 'react'

const MiddleAlignment = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="13.999"
    viewBox="0 0 16 13.999"
  >
    <g
      id="Group_38166"
      data-name="Group 38166"
      transform="translate(-0.004 1.227)"
    >
      <path
        id="Path_6061"
        data-name="Path 6061"
        d="M-14517.724-3751.961h16"
        transform="translate(14517.728 3751.735)"
        fill="none"
        stroke=""
        stroke-width="2"
      />
      <path
        id="Path_9224"
        data-name="Path 9224"
        d="M-14517.729-3751.961h12"
        transform="translate(14519.729 3755.733)"
        fill="none"
        stroke=""
        stroke-width="2"
      />
      <path
        id="Path_9225"
        data-name="Path 9225"
        d="M-14517.724-3751.961h16"
        transform="translate(14517.728 3759.734)"
        fill="none"
        stroke=""
        stroke-width="2"
      />
      <path
        id="Path_9226"
        data-name="Path 9226"
        d="M-14517.729-3751.961h12"
        transform="translate(14519.729 3763.733)"
        fill="none"
        stroke=""
        stroke-width="2"
      />
    </g>
  </svg>
  )
}

export default MiddleAlignment