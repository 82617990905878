export const roleOptions = [
  { value: "Business Owner", label: "Business Owner", name: "userroles" },
  {
    value: "Customer Success",
    label: "Customer Success",
    name: "userroles"
  },
  { value: "Engineering", label: "Engineering", name: "userroles" },
  { value: "Leadership", label: "Leadership", name: "userroles" },
  { value: "Marketing", label: "Marketing", name: "userroles" },
  { value: "Operations", label: "Operations", name: "userroles" },
  { value: "Sales", label: "Sales", name: "userroles" },
  { value: "Support", label: "Support", name: "userroles" },
  { value: "Other", label: "Other", name: "userroles" }
];

export const companySizeOptions = [
  {
    value: "1-19 employees",
    label: "1-19 employees",
    name: "company_size"
  },
  {
    value: "20-49 employees",
    label: "20-49 employees",
    name: "company_size"
  },
  {
    value: "50-249 employees",
    label: "50-249 employees",
    name: "company_size"
  },
  {
    value: "250-999 employees",
    label: "250-999 employees",
    name: "company_size"
  },
  {
    value: "1,000+ employees",
    label: "1,000+ employees",
    name: "company_size"
  }
];

export const industryOptions = [
  { value: "Arts", label: "Arts", name: "userindustry" },
  { value: "Automotive", label: "Automotive", name: "userindustry" },

  {
    value: "Constructions & Engineering",
    label: "Constructions & Engineering",
    name: "userindustry"
  },
  { value: "Consulting", label: "Consulting", name: "userindustry" },
  {
    value: "Consumer Goods",
    label: "Consumer Goods",
    name: "userindustry"
  },
  { value: "Education", label: "Education", name: "userindustry" },
  { value: "Energy", label: "Energy", name: "userindustry" },
  {
    value: "Entertainment",
    label: "Entertainment",
    name: "userindustry"
  },
  { value: "Finance", label: "Finance", name: "userindustry" },
  { value: "Fintech", label: "Fintech", name: "userindustry" },
  {
    value: "Food & Beverages",
    label: "Food & Beverages",
    name: "userindustry"
  },
  { value: "Government", label: "Government", name: "userindustry" },
  { value: "Healthcare", label: "Healthcare", name: "userindustry" },
  {
    value: "Human Resources",
    label: "Human Resources",
    name: "userindustry"
  },
  { value: "IT", label: "IT", name: "userindustry" },
  { value: "Insurance", label: "Insurance", name: "userindustry" },
  { value: "Logistics", label: "Logistics", name: "userindustry" },
  {
    value: "Manufacturing",
    label: "Manufacturing",
    name: "userindustry"
  },
  { value: "Marketing", label: "Marketing", name: "userindustry" },
  { value: "Media", label: "Media", name: "userindustry" },
  { value: "NGO", label: "NGO", name: "userindustry" },
  {
    value: "Professional Services",
    label: "Professional Services",
    name: "userindustry"
  },
  { value: "Real Estate", label: "Real Estate", name: "userindustry" },
  { value: "Restaurants", label: "Restaurants", name: "userindustry" },
  { value: "Retail", label: "Retail", name: "userindustry" },
  { value: "Software", label: "Software", name: "userindustry" },
  { value: "Sports", label: "Sports", name: "userindustry" },
  { value: "Telecom", label: "Telecom", name: "userindustry" },
  {
    value: "Travel & Leisure",
    label: "Travel & Leisure",
    name: "userindustry"
  }
];
