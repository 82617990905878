import React, {useState, useEffect} from "react";
import "./assets/css/common.css";
//import "./header.css";
import Header from "./../Dashboard/Header";
import { Link } from "react-router-dom";
import LeftPanel from "./LeftPanel";
import {
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import {
    getAgencyList,
} from "../Api/Agency/AgencyApi";
import moment from "moment";
import AgencySpinner from "./AgencySpinner";


const AgencyList = (props) =>{

    const [spinner, setSpinner] = useState(true);
    const [searchorgs, setSearchOrgs] = useState("");
    const [agencylist, setAgencyList] = useState([]);
    const [invitedorglist, setInvitedOrgList] = useState([]);
    const localuserid = localStorage.getItem("user_id");
	const agencyimgpath = process.env.REACT_APP_Image_Path + "agencies/";


    useEffect(()=>{

        getAgencyList(setAgencyList, setSpinner, setInvitedOrgList);

        document.title = "Agencies | AppMySite";

    }, []);

    const handleSendAgencyDashboard = (event, agdata) =>{
        localStorage.setItem("agency_id", agdata.id);
        localStorage.setItem("agency_name", agdata.agency_name);

        window.location.href="/agency/dashboard/" + btoa(agdata.id);

    }

    const handleSendGeneralSetting = (event, agdata) =>{
        localStorage.setItem("agency_id", agdata.id);
        localStorage.setItem("agency_name", agdata.agency_name);

        window.location.href="/agency/settings/" + btoa(agdata.id);

    }

    const handleSearchAgency = (event) =>{
        setSearchOrgs(event.target.value);
    }

    const handleCreateAgency = (e) =>{
        window.location.href="/agency/create";
    }

    return (
    <div className="org-module agency-module-list">
        <Header />

        <section>
            {spinner ?
                <AgencySpinner />
            :
                <>
                    { agencylist.length <= 0 && invitedorglist.length <= 0 ?
                        <>
                            <div className="agency-list-head">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <h1>My agencies</h1>
                                        <h2>White-labels</h2>
                                    </div>
                                </div>
                            </div>


                            <div className="start-with-agency">
                                <div>
                                    <img src={require("./assets/images/agency-no-app.png").default} alt="agency-no-app"/>

                                    <h3>Get your white-labeled agency</h3>

                                    <h6>It’ll take only a few minutes to set up your branded agency. Your customers will be building apps in no time!</h6>

                                    <div className="agency-getstarted" onClick={handleCreateAgency}>Get started</div>

                                </div>
                            </div>
                        </>
                    :
                        <>  
                            <div className="agency-background">
                                <div className="agency-list-head">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <h1>My agencies</h1>
                                            <h2>White-labels</h2>
                                        </div>
                                        <Link to="/agency/create"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                            <g id="Group_3055" data-name="Group 3055" transform="translate(-0.223)">
                                                <g id="Group_1664" data-name="Group 1664" transform="translate(0.223 6.999)">
                                                <line id="Line_85" data-name="Line 85" y2="14" transform="translate(14) rotate(90)" fill="none" stroke="#3064f9" stroke-width="2"/>
                                                </g>
                                                <g id="Group_1665" data-name="Group 1665" transform="translate(7.223 0) rotate(90)">
                                                <line id="Line_85-2" data-name="Line 85" y2="14" transform="translate(14) rotate(90)" fill="none" stroke="#3064f9" stroke-width="2"/>
                                                </g>
                                            </g>
                                            </svg>
                                            Create
                                        </Link> 
                                    </div>
                                    
                                    { agencylist.length > 0 && 
                                    <div className="d-flex">

                                        <div className="agency-search d-none">
                                            <svg id="Group_37943" data-name="Group 37943" xmlns="http://www.w3.org/2000/svg" width="16.53" height="16.53" viewBox="0 0 16.53 16.53">
                                                <g id="Ellipse_246" data-name="Ellipse 246" transform="translate(14.675) rotate(90)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                                                    <ellipse cx="7.337" cy="7.337" rx="7.337" ry="7.337" stroke="none"/>
                                                    <ellipse cx="7.337" cy="7.337" rx="6.587" ry="6.587" fill="none"/>
                                                </g>
                                                <path id="Path_6064" data-name="Path 6064" d="M0,5.347V0" transform="translate(16 16) rotate(135)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                            </svg>                                
                                            <input type="text" placeholder="Search" id="searchorgs" onChange={handleSearchAgency} value={searchorgs} />

                                        </div>

                                        <div className="create-org-btn">
                                            <Link to="/agency/create"></Link> 
                                            <img src={require("./assets/images/white-plus.png").default} alt="white-plus"/><p>Create agency</p>                    
                                        </div> 
                                    </div>
                                    }
                                </div>

                                <div className="agency-list">
                                { (agencylist !== null && agencylist !== '' && agencylist !== undefined) &&
                                        agencylist.map((aglist, agidx)=>{
                                            return(
                                    <div className="agency-single">
                                        <p className="agency-single-link-cursor" onClick={ (e)=>handleSendAgencyDashboard(e, aglist) }></p>
                                        <div className="agency-single-left">
                                            <div className="agency-single-placeholder">
                                                    { aglist.agency_logo !== '' && aglist.agency_logo !== null ?
                                                        <img src={ agencyimgpath + aglist.id + process.env.REACT_APP_Agency_Profile + aglist.agency_logo } className="img-fluid" alt="agency-logo" />
                                                    :
                                                    <h2>Your logo</h2>
                                                    }
                                                {/* <img src={require("./assets/images/logo123.png").default} className="img-fluid" alt="logo123"/> */}
                                            </div>
                                            <div className="agency-single-details">
                                                <h2>{ aglist.agency_name }</h2>
                                                {aglist.domain && aglist.domain != null && aglist.domain != '' ?  
                                                    <h3><a href={'https://'+aglist.domain} target="_blank">{aglist.domain} <img src={require("./assets/images/Goto.png").default} className="img-fluid" alt="goto"/></a></h3>
                                                : <></>
                                                }
                                                <h4>ID: {aglist.id}</h4>
                                            </div>
                                        </div>
                                        <div className="agency-single-right">
                                            <h5>{ moment( aglist.created_at ).format("D MMMM YYYY") }</h5>
                                        </div>
                                    </div>
                                )})
                                }

                                { (invitedorglist !== null && invitedorglist !== '' && invitedorglist !== undefined) &&

                                invitedorglist.filter((invres) => searchorgs === '' ? invres.agency_name : invres.agency_name.toLowerCase().indexOf( searchorgs.toLowerCase() ) != -1 )
                                .map((invitedlist, invitedidx)=>{
                                return(
                                    <div className="agency-single agency-single-link-cursor" onClick={ (e)=>handleSendAgencyDashboard(e, invitedlist) } >
                                    {/* <a href=""></a> */}
                                        <div className="agency-single-left">
                                            <div className="agency-single-placeholder">
                                                    { invitedlist.agency_logo !== '' && invitedlist.agency_logo !== null ?
                                                        <img src={ process.env.REACT_APP_Image_Path + "agencies/" + invitedlist.id + process.env.REACT_APP_Agency_Profile + invitedlist.agency_logo } className="img-fluid" alt="agency-logo" />
                                                    :
                                                        <h2>Your logo</h2>
                                                    }
                                                
                                            </div>
                                            <div className="agency-single-details">
                                                <h2>{invitedlist.agency_name}</h2>
                                                {invitedlist.domain && invitedlist.domain != null && invitedlist.domain != '' ?  
                                                    <h3><a href={'https://'+invitedlist.domain} target="_blank">{invitedlist.domain} <img src={require("./assets/images/Goto.png").default} className="img-fluid" alt="goto"/></a></h3>
                                                : <></>
                                                }
                                                <h4>ID: {invitedlist.id}</h4>
                                            </div>
                                        </div>
                                        <div className="agency-single-right">
                                            <h5>{ moment( invitedlist.created_at ).format("D MMMM YYYY") }</h5>
                                        </div>
                                    </div>
                                )})
                            }

                                    {/* <div className="agency-single">
                                    <a href=""></a>
                                        <div className="agency-single-left">
                                            <div className="agency-single-placeholder">
                                            <h2>Your logo</h2>
                                            </div>
                                            <div className="agency-single-details">
                                                <h2>Digilapp Inc</h2>
                                                <h3>app.digilapp.com <img src={require("./assets/images/Goto.png").default} className="img-fluid" alt="goto"/></h3>
                                                <h4>ID: 218946</h4>
                                            </div>
                                        </div>
                                        <div className="agency-single-right">
                                            <h5>12 December 2022</h5>
                                        </div>
                                    </div>

                                    <div className="agency-single">
                                    <a href=""></a>
                                        <div className="agency-single-left">
                                            <div className="agency-single-placeholder">
                                            <h2>Your logo</h2>
                                            </div>
                                            <div className="agency-single-details">
                                                <h2>Digilapp Inc</h2>
                                                <h3>app.digilapp.com <img src={require("./assets/images/Goto.png").default} className="img-fluid" alt="goto"/></h3>
                                                <h4>ID: 218946</h4>
                                            </div>
                                        </div>
                                        <div className="agency-single-right">
                                            <h5>12 December 2022</h5>
                                        </div>
                                    </div> */}


                                </div>
                            </div>
                        </>
                    }
                </>
            }
        </section>
    </div>

    );
}

export default AgencyList;