import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { hotjar } from "react-hotjar";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { parseCoordinates } from "../../../Api/Dashboard/DashboardApi";
import ChangeProductModal from "../../../Components/commonComponents/AppSettings/ChangeProductModal.js";
import CountryDropdown from "../../../Components/commonComponents/AppSettings/CountryDropdown.js";
import DeleteAppModal from "../../../Components/commonComponents/AppSettings/DeleteAppModal.js";
import LanguageDropdown from "../../../Components/commonComponents/AppSettings/LanguageDropdown.js";
import LeaveAppModal from "../../../Components/commonComponents/AppSettings/LeaveAppModal.js";
import LocationModal from "../../../Components/commonComponents/AppSettings/LocationModal.js";
import MoveAppModal from "../../../Components/commonComponents/AppSettings/MoveAppModal.js";
import PaidSubscriptionModal from "../../../Components/commonComponents/AppSettings/PaidSubscriptionModal.js";
import ProductTechnologyModal from "../../../Components/commonComponents/AppSettings/ProductTechnologyModal.js";
import TransferModal from "../../../Components/commonComponents/AppSettings/TransferModal.js";
import VerifyPopup from "../../../Components/commonComponents/AppSettings/VerifyPopup.js";
import Spinner from "../../../Components/commonComponents/Spinner";
import UpdatedAt from "../../../Components/commonComponents/UpdatedAt.js";
import GreenTick from "../../../Components/commonIcons/GreenTick";
import ModuleTitleTooltip from "../../../Components/commonIcons/ModuleTitleTooltip.js";
import MoveIcon from "../../../Components/commonIcons/MoveIcon";
import {
  default as Tooltip,
  default as Tooltipicon,
} from "../../../Components/commonIcons/Tooltip";
import Header from "../../../Dashboard/Header";
import Leftpanel from "../../../Dashboard/Leftpanel";
import { Deletesvg, Leavesvg, LocationSvg } from "../../../Dashboard/Svg";
import { errortoast, sucesstoast } from "../../../Dashboard/Toaster";
import { setUserAppName } from "../../../Redux/slices/userWebsiteDataSlice.js";
import "../../../assets/css/Generalsetting.css";
import appType1 from "../../../assets/images/dashboard/app-type1.png";
import appType2 from "../../../assets/images/dashboard/app-type2.png";
import appType3 from "../../../assets/images/dashboard/app-type3.png";
import appType4 from "../../../assets/images/dashboard/app-type4.png";
import crownIcon from "../../../assets/images/dashboard/crownIcon.svg";
import {
  useDeleteAppMutation,
  useExtractWebsiteColorsMutation,
  useGetCountryListQuery,
  useGetGeneralSettingsQuery,
  useGetWebsiteTypeQuery,
  useGetWorkspacesQuery,
  useLeaveAppMutation,
  useMoveAppMutation,
  useSaveGeneralSettingsDataMutation,
} from "../../../services/generalSettingsApiSlice";
import { languagesArray } from "../../../utils/Languages";
import { MetaTitle } from "../../../utils/commonUtils";
import GeneralSettingsPreview from "./GeneralSettingsPreview.js";
import { useMenuDataQuery } from "../../../services/menuApiSlice.js";
import { useBottomDataQuery } from "../../../services/bottomApiSlice.js";
import Newfeatureicon from "../../../Components/commonIcons/Newfeatureicon.js";

const GeneralSettings = () => {
  const wrapperRef = useRef(null);
  const mapRef = useRef(null);
  const searchRef = useRef(null);
  const inputRef = useRef(null);
  const history = useHistory();
  const languageTimeout = useRef(null);
  const offlineTimeout = useRef(null);

  const [settingsState, setSettingsState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      plan_code: "",
      handlerender: true,
      verifypop: false,
      verifypopmodal: false,
      verifyhiturl: "",
      spinner: false,
      websitetype: false,
      websiteUrl: "",
      comparewebsiteUrl: "",
      appname: "",
      oldappname: "",
      appplatform: "",
      appurl: "",
      hitapi: false,
      cancelpopup: false,
      typevalue: "",
      typeselection: [],
      dropdownSearchVal: "",
      bodyspinner: true,
      termandcondition: "",
      appnameid: "",
      transfermodal: false,
      changetypemodal: false,
      readstatus: false,
      websiteLocator: "1",
      is_verified: 0,
      build_status: 0,
      platformtype: "",
      business_phone: "",
      business_email: "",
      share_app: false,
      share_app_url: "",
      consent_form: false,
      consent_title: "",
      consent_description: "",
      consent_form_purchased: 0,
      appuser_role_id: null,
      allowdelete: false,
      appspinner: false,
      delete_app: false,
      paidsubscriptionpopup: false,
      openCountryList: false,
      countryselectfield: [],
      country_name_prefix: "",
      country_name: "",
      phonecodemodal: false,
      deleteappname: "",
      submitval_confirmpop1: "Leave",
      store_api_version: "",
      website_technology: parseInt(localStorage.getItem("website_technology")),
      website_platform: parseInt(localStorage.getItem("website_technology")),
      radioCheck:
        parseInt(localStorage.getItem("website_technology")) === 4 ||
        parseInt(localStorage.getItem("website_technology")) === 5
          ? true
          : false,
      radioCheck2:
        parseInt(localStorage.getItem("website_technology")) === 2
          ? true
          : false,
      workspaceWebTechnology: "",
      appTechnology: "",
      wooToggleCount: 0,
      mergeArray1: [],
      appTransfer: localStorage.getItem("workspaceId")
        ? parseInt(localStorage.getItem("workspaceId"))
        : parseInt(localStorage.getItem("user_id")),
      appType: null,
      apptrans: false,
      isOwner: 0,
      isPaid: false,
      appTfDropData: null,
      leavemodal: false,
      force_app_to_update_latest_version: 0,
      forceAppUpdateModalOpen: false,
      allow_changing_technology: 0,
      openMobOperateDropdown: false,
      userInfo: "",
      lastdate: "",
      sameselected: true,
      defaultlanguage: "English (United States)",
      defaultlanguagecode: "en",
      user_country: "",
      app_country: "US",
      website_technology1: parseInt(localStorage.getItem("website_technology")),
      appRightPanelExpand:
        localStorage.getItem("appSideBarCollapse") === true ||
        localStorage.getItem("appSideBarCollapse") === "true"
          ? true
          : false,
      optionsForDefaultLanguage: languagesArray,
      locationModal: false,
      lat: null,
      lng: null,
      zoom: 10,
      mapSelectedLocation: null,
      enableLanguage: 0,
      openEnableLanguageToggle: 0,
      enableOfflineMode: 0,
      enable_prompt_on_exit: true,
      enable_auto_translate: 1,
      promptOnExitDrawerOpen: false,
      rerender: false,
      userInputTNC: false,
      userInputWebsiteUrl: false,
      isBeaconBlinkingOffline: false,
      isBeaconBlinkingLanguage: false,
      isBeaconBlinkingTnc: false,
      isBeaconBlinkingWebsiteUrl: false,
    }
  );
  const dispatch = useDispatch();
  const [mapSearchInput, setMapSearchInput] = useState("");
  const latestMapSearchInput = useRef(mapSearchInput);
  const { data: websiteTypeData } = useGetWebsiteTypeQuery();
  const { data: countryListData } = useGetCountryListQuery();
  const [activeSlide, setActiveSlide] = useState(0);

  const settingParams = {
    app_id: btoa(localStorage.getItem("Appid")),
    workspace_id:
      localStorage.getItem("workspaceId") &&
      localStorage.getItem("workspaceId") !== "undefined" &&
      localStorage.getItem("workspaceId") !== "null"
        ? btoa(localStorage.getItem("workspaceId"))
        : btoa(null),
  };

  const { data: generalSettingsData, isLoading: isGeneralSettingsDataLoading } =
    useGetGeneralSettingsQuery(settingParams);

  const { data: workspaceData } = useGetWorkspacesQuery("all");
  const { refetch: refetchMenu } = useMenuDataQuery();
  const { refetch: bottomBarGetApiReftech } = useBottomDataQuery();
  const [
    saveGeneralSettingsData,
    { isLoading: isSaveGeneralSettingsDataLoading },
  ] = useSaveGeneralSettingsDataMutation();
  const [deleteApp, { isLoading: deleteAppDataLoading }] =
    useDeleteAppMutation();
  const [leaveApp, { isLoading: leaveAppDataLoading }] = useLeaveAppMutation();
  const [saveWebsiteColors] = useExtractWebsiteColorsMutation();
  const [moveApp, { isLoading: moveAppDataLoading }] = useMoveAppMutation();

  const handleAppname = (e) => {
    var setvalue = e.target.value.replace(/^\s+/, "");
    setvalue = setvalue.replace(/[`~_|?"<>\[\]\\]/gi, "");

    setSettingsState({
      appname: setvalue,
      hitapi: true,
    });
  };

  const handlePaidSubs = () => {
    setActiveSlide(0);
    clearAllBeacons();
    setSettingsState({
      promptOnExitDrawerOpen: false,
      forceAppUpdateModalOpen: false,
    });
    if (
      parseInt(localStorage.getItem("appuser_role_id")) < 3 &&
      localStorage.getItem("agencyid") == 1
    ) {
      setSettingsState({
        isPaid: !settingsState.isPaid,
        website_technology1: settingsState.website_technology,
      });
    }
  };

  const handletypetab = (e) => {
    setSettingsState({ website_technology1: e });
  };

  const handletypechangepopup = (e) => {
    if (
      parseInt(settingsState.website_technology) ==
      parseInt(settingsState.website_technology1)
    ) {
      setSettingsState({ changetypemodal: false, isPaid: false });
    } else {
      setSettingsState({
        changetypemodal: !settingsState.changetypemodal,
        isPaid: !settingsState.isPaid,
      });
    }
  };

  const togglephonecodemodal = () => {
    setSettingsState({
      phonecodemodal: !settingsState.phonecodemodal,
    });
  };
  const handleOpenCoutryList = () => {
    setActiveSlide(2);
    setSettingsState({
      openCountryList: !settingsState.openCountryList,
    });
  };

  const handleCountryDropdown = (e, res) => {
    setSettingsState({
      phone_code: res.phone_code,
      country_name_prefix: res.country_code,
      country_name: res.country_name,
      app_country: res.country_code,
      openCountryList: window.matchMedia("(max-width: 1200px)").matches
        ? settingsState.openCountryList
        : !settingsState.openCountryList,
      phonecodemodal: window.matchMedia("(max-width: 1200px)").matches
        ? !settingsState.phonecodemodal
        : settingsState.phonecodemodal,
    });
  };

  const handleLocationToggle = () => {
    setActiveSlide(2);
    clearAllBeacons();
    setSettingsState({
      promptOnExitDrawerOpen: false,
      forceAppUpdateModalOpen: false,
    });
    !settingsState.locationModal &&
      settingsState.mapSelectedLocation?.lat === 0 &&
      handleLocationClick();
    setSettingsState({
      locationModal: !settingsState.locationModal,
    });
  };

  const handleLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setSettingsState({
            mapSelectedLocation: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
            zoom: 14,
          });
        },
        (error) => {}
      );
    } else {
      errortoast("Geolocation is not supported by this browser.");
    }
  };

  const handleLocationSelected = (address) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.results && data.results.length > 0) {
          const { lat, lng } = data.results[0].geometry.location;
          const locationData = { address, lat, lng };
          setSettingsState({ mapSelectedLocation: locationData, zoom: 14 });
        } else {
          // errortoast("No location results found.");
        }
      })
      .catch((error) => errortoast("Error fetching location coordinates"));
  };

  function handleMapClick(event) {
    const newCoords = event.latLng;
    setSettingsState({
      mapSelectedLocation: { lat: newCoords.lat(), lng: newCoords.lng() },
    });
  }

  const handleDefaultLanguageChange = (e) => {
    if (languageTimeout.current) {
      clearTimeout(languageTimeout.current);
      languageTimeout.current = null;
    }

    setSettingsState({
      defaultlanguage: e.label,
      defaultlanguagecode: e.value,
      openMobOperateDropdown: false,
      isBeaconBlinkingLanguage: true,
    });

    languageTimeout.current = setTimeout(() => {
      setSettingsState({
        isBeaconBlinkingLanguage: false, // Remove beacon after 5 seconds
      });
    }, 5000); // Clear timeout after 5 sec on turning the toggle ON
  };

  const handleforcetoggle = (e) => {
    setSettingsState({
      force_app_to_update_latest_version: e.target.checked === true ? 1 : 0,
      hitapi: true,
      forceAppUpdateModalOpen: e.target.checked,
    });
    toggleHandling(e);
    clearAllBeacons();
  };

  const handleAllSettingToggles = (e) => {
    !settingsState.enable_prompt_on_exit
      ? setActiveSlide(3)
      : setActiveSlide(0);

    const { name, checked } = e.target;

    if (name === "enable_prompt_on_exit") {
      setSettingsState({ promptOnExitDrawerOpen: checked });
    }

    setSettingsState({
      [name]: checked,
    });
    toggleHandling(e);
    clearAllBeacons();
  };

  const toggleHandling = (e) => {
    const { name } = e.target;
    if (name !== "enable_prompt_on_exit") {
      setSettingsState({ promptOnExitDrawerOpen: false });
    }
    if (name !== "force_app_to_update_latest_version") {
      setSettingsState({ forceAppUpdateModalOpen: false });
    }
  };

  const deleteapps = (e) => {
    e.preventDefault();
    clearAllBeacons();
    setSettingsState({
      delete_app: !settingsState.delete_app,
      deleteappname: "",
    });
  };

  const handleDeleteAppname = (e) => {
    var setvalue = e.target.value.replace(/^\s+/, "");
    setvalue.replace(/[`~_|?"<>\[\]\\\/]/gi, "");

    setSettingsState({
      deleteappname: setvalue,
    });
  };

  const deletapp = (e, id) => {
    if (
      settingsState.appname != settingsState.deleteappname ||
      settingsState.deleteappname == ""
    ) {
      errortoast("Please enter valid app name");
      return;
    }
    e.preventDefault();
    const params = {
      app_id: btoa(id),
    };
    deleteApp(params)
      .unwrap()
      .then((res) => {
        if (res.code === 200) {
          sucesstoast("App successfully deleted");
          history.push(
            `/apps${
              localStorage.getItem("workspaceId") !== "" &&
              localStorage.getItem("workspaceId") !== "undefined" &&
              localStorage.getItem("workspaceId")
                ? "/" + btoa(localStorage.getItem("workspaceId"))
                : ""
            }`
          );
        } else if (res.code === 204) {
          setSettingsState({
            paidsubscriptiondetected: true,
            delete_app: false,
          });
        }
      })
      .catch((error) => {
        errortoast("Something went wrong. Try again after sometime.");
      });
  };
  const handlewebsiteplatform1 = (e) => {
    clearAllBeacons();
    setSettingsState({
      websitetype: true,
      sameselected: true,
    });
  };

  const leavetoggle = (e, appid) => {
    e.persist();
    setSettingsState({
      leavemodal: !settingsState.leavemodal,
    });
  };

  const leaveapp = (e, id) => {
    e.preventDefault();
    const params = {
      app_id: parseInt(id),
    };
    leaveApp(params)
      .unwrap()
      .then((res) => {
        if (res.code === 200) {
          sucesstoast("You have successfully been removed from the app.");
          history.push(
            `/apps${
              localStorage.getItem("workspaceId") &&
              localStorage.getItem("workspaceId") !== "undefined" &&
              localStorage.getItem("workspaceId") !== null
                ? "/" + btoa(localStorage.getItem("workspaceId"))
                : ""
            }`
          );
        } else if (res.code === 204) {
          setSettingsState({
            delete_app: true,
            deletemodal: false,
            submitval_confirmpop1: "Yes",
          });
        }
      })
      .catch((error) => {
        errortoast("Something went wrong. Try again after sometime.");
      });
  };
  const handleWebsiteUrl = (e) => {
    setSettingsState({
      websiteUrl: e.target.value
        .replace(/ /g, "+")
        .replace(/^https?:\/\//i, ""),
      hitapi: true,
      userInputWebsiteUrl: true,
    });
    if (e.target.value.indexOf("https://") > -1) {
      setSettingsState({
        websiteLocator: "1",
      });
    }
    if (e.target.value.indexOf("http://") > -1) {
      setSettingsState({
        websiteLocator: "0",
      });
    }
  };

  const handleterm = (e) => {
    setSettingsState({
      userInputTNC: true,
      termandcondition: e.target.value
        .replace(/ /g, "+")
        .replace(/^https?:\/\//i, ""),
      hitapi: true,
    });
  };

  const photoggle = (e, appid) => {
    e.persist();
    setSettingsState({
      websitetype: false,
      transfermodal: !settingsState.transfermodal,
    });
  };

  const handleDropdownSearch = (e) => {
    setSettingsState({
      dropdownSearchVal: e.target.value,
    });
  };

  const handleOpenMobDefaultLanguageDropdown = () => {
    setActiveSlide(2);
    setSettingsState({
      openMobOperateDropdown: !settingsState.openMobOperateDropdown,
    });
  };

  const handleClickOutside = (event) => {
    try {
      if (
        wrapperRef &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        setSettingsState({
          openCountryList: false,
        });
      }
    } catch (err) {
      // setState({
      //   openCountryList:!state.openCountryList
      // })
    }
  };

  const handleTransfer = (data, type) => {
    var previousvalue = "";

    if (localStorage.getItem("workspaceId")) {
      previousvalue = parseInt(localStorage.getItem("workspaceId"));
    } else {
      previousvalue = parseInt(localStorage.getItem("user_id"));
    }

    if (previousvalue === data.id) {
      setSettingsState({ sameselected: true });
      return;
    } else {
      setSettingsState({ sameselected: false });
      setSettingsState({
        appTransfer: data.id,
        appType: type,
        apptrans: true,
        appTfDropData: data,
      });
    }
  };

  const updateTransfer = () => {
    let param = {};

    if (settingsState.appType === "user") {
      param = {
        app_id: parseInt(localStorage.getItem("Appid")),
        workspace_id: null,
      };
    } else {
      param = {
        app_id: parseInt(localStorage.getItem("Appid")),
        workspace_id: settingsState.appTransfer,
      };
    }
    moveApp(param)
      .unwrap()
      .then((res) => {
        if (res.code === 200) {
          sucesstoast("Your changes have been saved");
          setSettingsState({
            apptrans: false,
          });
          if (!param.workspace_id) {
            setTimeout(() => {
              localStorage.setItem("workspaceId", "");
              localStorage.setItem("workspaceName", "");
              localStorage.setItem("workspaceImage", "");
              localStorage.setItem("logged_in_user_role_id", 1);
              window.location.href = `/apps`;
            }, 2000);
          } else {
            setTimeout(() => {
              localStorage.setItem("logged_in_user_role_id", 1);
              localStorage.setItem(
                "workspaceId",
                settingsState.appTfDropData.id
              );
              localStorage.setItem(
                "workspaceName",
                settingsState.appTfDropData.workspace_name
              );

              localStorage.setItem(
                "workspaceImage",
                settingsState.appTfDropData.profile_image
              );

              window.location.href = `/apps${"/" + btoa(param.workspace_id)}`;
            }, 2000);
          }
        } else if (res.code === 204) {
          setSettingsState({
            apptrans: false,
            paidsubscriptiondetected: true,
            delete_app: false,
            websitetype: false,
            transfermodal: false,
          });
        } else {
          setSettingsState({
            apptrans: false,
            paidsubscriptiondetected: false,
            delete_app: false,
            websitetype: false,
            transfermodal: false,
          });
          errortoast("Something went wrong");
        }
      })
      .catch((err) => {
        setSettingsState({
          apptrans: false,
        });
        errortoast("Something went wrong");
      });
  };

  const handleRightPanelExpand = (value) => {
    setSettingsState({
      appRightPanelExpand: value,
    });
  };

  const settingsSubmit = (e) => {
    e.preventDefault();

    const params = {
      workspace_id: localStorage.getItem("workspaceId")
        ? parseInt(localStorage.getItem("workspaceId"))
        : "",
      app_id: parseInt(localStorage.getItem("Appid")),
      app_name: settingsState.appname.trim(),
      default_language: settingsState.defaultlanguagecode,
      force_app_to_update_latest_version:
        settingsState.force_app_to_update_latest_version,
      website_type: parseInt(settingsState.appplatform),
      web_terms_condition_url:
        settingsState.termandcondition !== ""
          ? "https://" +
            settingsState.termandcondition
              .replace(/ /g, "+")
              .replace(/^https?:\/\//i, "")
          : "",
      store_url:
        settingsState.websiteUrl && settingsState.website_technology1 !== "3"
          ? settingsState.websiteUrl.replace(/^https?:\/\//i, "")
          : "",
      httpprotocol: settingsState.websiteLocator,
      business_phone: settingsState.business_phone,
      business_email: settingsState.business_email,
      share_app_bool: settingsState.share_app === true ? 1 : 0,
      share_app_url: settingsState.share_app_url,
      consent_form_bool: settingsState.consent_form === true ? 1 : 0,
      consent_form_description: settingsState.consent_description,
      consent_form_title: settingsState.consent_title,
      app_country: settingsState.app_country,
      is_android_rebuild_required:
        settingsState.readstatus.android_success_build_count > 0 &&
        settingsState.appname.trim() !== settingsState.oldappname.trim()
          ? 1
          : 0,
      is_ios_rebuild_required:
        settingsState.readstatus.ios_success_build_count > 0 &&
        settingsState.appname.trim() !== settingsState.oldappname.trim()
          ? 1
          : 0,
      store_api_version: settingsState.store_api_version,

      website_technology: settingsState.website_technology1,
      map_coordinates: {
        latitude: settingsState.lat,
        longitude: settingsState.lng,
      },
      enable_language_selection: settingsState.enableLanguage,
      enable_offline_mode: settingsState.enableOfflineMode,
      enable_prompt_on_exit: settingsState.enable_prompt_on_exit ? 1 : 0,
      enable_auto_translate: settingsState.enable_auto_translate,
    };
    if (params.app_name.length < 3) {
      errortoast("The app name must be atleast 3 characters");
    } else {
      params.httpprotocol === "0" &&
        errortoast("We support only HTTPS Website");
      params.httpprotocol === "1" &&
        saveGeneralSettingsData(params, setSettingsState)
          .unwrap()
          .then((res) => {
            if (res.code === 200) {
              setSettingsState({
                promptOnExitDrawerOpen: false,
                forceAppUpdateModalOpen: false,
              });
              dispatch(setUserAppName(settingsState.appname));
              bottomBarGetApiReftech();
              refetchMenu();
              localStorage.setItem(
                "website_technology",
                params.website_technology
              );
              setSettingsState({
                lastdate: res.updated_at,
                hitapi: false,
              });
              localStorage.setItem("store_url", "https://" + params.store_url);
              localStorage.setItem("website_type_select", params.website_type);
              if (
                res.is_change_in_url === 1 &&
                parseInt(localStorage.getItem("website_technology")) !== 3
              ) {
                const data = {
                  app_id: parseInt(localStorage.getItem("Appid")),
                  force_update: 1,
                };
                saveWebsiteColors(data);
              }

              if (
                res.is_change_in_url === 1 &&
                (res.website_technology === 4 || res.website_technology === 5)
              ) {
                setSettingsState({
                  verifypop: true,
                  verifypopmodal: true,
                  verifyhiturl: "/app/connect/wp-plugin",
                  handlerender: false,
                });
              } else {
                sucesstoast("App updated successfully");
              }
            } else if (res.code === 400) {
              setSettingsState({
                hitapi: false,
              });
              errortoast("Oops! Something went wrong.");
              if (res.data?.data?.store_url?.length > 0) {
                setSettingsState({
                  hitapi: false,
                });
              }
              if (res.data.default_language) {
                errortoast(res.data.default_language[0]);
                setSettingsState({
                  hitapi: false,
                });
              }
              if (res.data.force_app_to_update_latest_version) {
                errortoast(res.data.force_app_to_update_latest_version[0]);
                setSettingsState({
                  hitapi: false,
                });
              }
              if (res.data.app_name) {
                errortoast(res.data.app_name[0]);
                setSettingsState({
                  hitapi: false,
                });
              }
            } else if (res.code === 500) {
              errortoast(res.data);
              setSettingsState({
                // submitval: "SUBMIT",
                hitapi: false,
              });
            }
          })
          .catch(() => {
            errortoast("Oops! Something went wrong.");
          })
          .finally(() => {
            clearAllBeacons();
          });
    }
  };

  const cancelpopup = () => {
    if (settingsState.wooToggleCount > 0) {
      setSettingsState({
        websitetype: false,
      });
    } else {
      setSettingsState({
        website_platform: !settingsState.radioCheck ? 1 : 2,
        appplatform: settingsState.appplatform,
        websitetype: false,
        radioCheck: !settingsState.radioCheck,
        radioCheck2: !settingsState.radioCheck2,
        website_technology1: settingsState.website_technology,
      });
    }
  };

  const clearAllTimeouts = () => {
    clearTimeout(languageTimeout.current);
    languageTimeout.current = null;
    clearTimeout(offlineTimeout.current);
    offlineTimeout.current = null;
  };

  const handletypechangepopupclose = (e) => {
    setSettingsState({ changetypemodal: false });
  };

  const handletypechangepopupsubmit = (e) => {
    setSettingsState({ rerender: !settingsState.rerender });
    if (
      localStorage.getItem("agencyid") &&
      localStorage.getItem("agencyid") != 1
    ) {
      return;
    } else if (
      localStorage.getItem("workspaceId") &&
      localStorage.getItem("logged_in_user_role_id") &&
      parseInt(settingsState.allow_changing_technology) === 0 &&
      parseInt(localStorage.getItem("appuser_role_id")) < 3
    ) {
      setSettingsState({ changetypemodal: false });
      window.location.href =
        "/workspace/pricing/" +
        btoa(localStorage.getItem("workspaceId")) +
        "/" +
        localStorage.getItem("website_technology");
    } else if (
      parseInt(settingsState.allow_changing_technology) === 0 &&
      parseInt(localStorage.getItem("appuser_role_id")) < 3
    ) {
      setSettingsState({ changetypemodal: false });

      window.location.href =
        parseInt(settingsState.website_technology1) === 4
          ? "/app/wordpress-pricing/" + btoa(localStorage.getItem("Appid"))
          : parseInt(settingsState.website_technology1) === 5
          ? "/app/woocommerce-pricing/" + btoa(localStorage.getItem("Appid"))
          : parseInt(settingsState.website_technology1) === 3
          ? "/app/custom-app-pricing/" + btoa(localStorage.getItem("Appid"))
          : parseInt(settingsState.website_technology1) === 2
          ? "/app/web-to-app-pricing/" + btoa(localStorage.getItem("Appid"))
          : "/app/pricing";
    } else if (
      localStorage.getItem("agencyid") &&
      localStorage.getItem("agencyid") == 1 &&
      parseInt(settingsState.allow_changing_technology) === 1
    ) {
      setSettingsState({
        changetypemodal: false,
        website_technology: settingsState.website_technology1,
      });
    } else {
      return;
    }
  };

  const TECHNOLOGY =
    +localStorage.getItem("website_technology") !==
    settingsState.website_technology
      ? settingsState.website_technology
      : +localStorage.getItem("website_technology");

  const handleverifymodal = () => {
    clearAllBeacons();
    setSettingsState({
      verifypopmodal: !settingsState.verifypopmodal,
    });
  };

  const paidsubscriptionpopup = (e) => {
    setSettingsState({
      paidsubscriptiondetected: false,
    });
  };
  const clearAllBeacons = () => {
    setSettingsState({
      isBeaconBlinkingOffline: false,
      isBeaconBlinkingLanguage: false,
      isBeaconBlinkingTnc: false,
      isBeaconBlinkingWebsiteUrl: false,
    });
  };
  useEffect(() => {
    document.title = MetaTitle("Settings");
    document.addEventListener("mousedown", handleClickOutside);

    setSettingsState({
      appurl: localStorage.getItem("store_url"),
      appnameid: localStorage.getItem("Appid"),
      appplatform: localStorage.getItem("website_type_select"),
      typevalue: localStorage.getItem("website_type_select"),
    });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (inputRef.current) {
        const value = inputRef.current.value;
        if (value !== latestMapSearchInput.current) {
          setMapSearchInput(value);
          handleLocationSelected(value);
        }
      }
    }, 500);

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, []);

  useEffect(() => {
    if (websiteTypeData && websiteTypeData?.code === 200) {
      setSettingsState({
        spinner: false,
        typeselection: websiteTypeData.data.data,
        licence_type: websiteTypeData.user_licences,
        customer_type: websiteTypeData.customer_type,
        bodyspinner: false,
        app_count: websiteTypeData.app_count,
        user_country: "US",
        customer_billing_type: websiteTypeData.customer_billing_type,
        emailverified: websiteTypeData.email_marketing.email_verified,
      });
      if (window.location.pathname !== "/app/settings") {
        const params = {
          contact: {
            FIRSTNAME: websiteTypeData.email_marketing.name,
            AMS_CUSTOMER_ID: websiteTypeData.email_marketing.ams_customer_id,
            PAYMENT_CUSTOMER_ID:
              websiteTypeData.email_marketing.zoho_customer_id,
            EMAIL_VERIFIED: websiteTypeData.email_marketing.email_verified,
            CUSTOMER_TYPE:
              websiteTypeData.email_marketing.customer_type.toString(),
            APP_PLAN: websiteTypeData.email_marketing.appsumo_plan,
            FREE_OR_PAID: websiteTypeData.email_marketing.free_or_paid,
            OWNER_TYPE: websiteTypeData.email_marketing.owner_type,
            LOGIN_COUNTER: websiteTypeData.email_marketing.login_counter,
            COUNTRY_CODE: websiteTypeData.email_marketing.country_code,
            TOTAL_APPS: websiteTypeData.email_marketing.total_apps,
          },
          cuid: websiteTypeData.email_marketing.ams_customer_id.toString(),
          email_id: websiteTypeData.email_marketing.email,
          sib_type: "identify",
        };

        if (hotjar.initialized()) {
          hotjar.identify("USER_ID", params);
        }
      }
    } else if (websiteTypeData?.code === 401) {
      if (websiteTypeData.status === "Token Expired") {
        let myItem = localStorage.getItem("buildversion");
        let myItemversion = localStorage.getItem("Apiversion");
        let previous_location = localStorage.getItem("Previouslocation");
        localStorage.clear();
        localStorage.setItem("Previouslocation", previous_location);
        localStorage.setItem("buildversion", myItem);
        localStorage.setItem("Apiversion", myItemversion);
      }
    } else if (websiteTypeData?.code === 201) {
      errortoast("Something went wrong. Try again after sometime.");
      setSettingsState({
        spinner: false,
      });
    }
  }, [websiteTypeData]);

  useEffect(() => {
    if (countryListData && countryListData?.code === 200) {
      setSettingsState({
        countryselectfield: countryListData.data,
        bodyspinner: false,
      });
    }
  }, [countryListData]);

  useEffect(() => {
    if (generalSettingsData && generalSettingsData?.code === 200) {
      localStorage.setItem("Appname", generalSettingsData.data.appname);

      const { latitude = 0, longitude = 0 } = parseCoordinates(
        generalSettingsData.data.general_data?.map_coordinates
      );
      setSettingsState({
        is_social_login_enabled_android:
          generalSettingsData.data.is_social_login_enabled_android,
        is_social_login_enabled_ios:
          generalSettingsData.data.is_social_login_enabled_ios,
        plan_code: generalSettingsData.data.app_plan,
        lastdate: generalSettingsData.data.updated_at,
        app_country: generalSettingsData.data.general_data?.app_country
          ? generalSettingsData.data.general_data?.app_country
          : "US",
        force_app_to_update_latest_version:
          generalSettingsData.data.general_data &&
          generalSettingsData.data.general_data
            .force_app_to_update_latest_version &&
          generalSettingsData.data.general_data
            .force_app_to_update_latest_version == 1
            ? 1
            : 0,
        defaultlanguagecode:
          generalSettingsData.data.general_data &&
          generalSettingsData.data.general_data.default_language
            ? generalSettingsData.data.general_data.default_language
            : "en",
        guestlogin:
          generalSettingsData.data.general_settings &&
          generalSettingsData.data.general_settings
            .guest_browsing_allowed_bool &&
          generalSettingsData.data.general_settings
            .guest_browsing_allowed_bool === 1
            ? true
            : false,
        registerallow:
          generalSettingsData.data.general_settings &&
          generalSettingsData.data.general_settings
            .user_registration_allowed_bool &&
          generalSettingsData.data.general_settings
            .user_registration_allowed_bool === 1
            ? true
            : false,
        appname: generalSettingsData.data.appname,
        oldappname: generalSettingsData.data.appname,
        appplatform: generalSettingsData.data.website_type.toString(),
        authorization_type: generalSettingsData.data.authorization_type,
        is_connected: generalSettingsData.data.is_connected,
        platformtype: generalSettingsData.data.website_type.toString(),
        facebooklogin:
          generalSettingsData.data.general_settings &&
          generalSettingsData.data.general_settings
            .social_login_fb_allowed_bool &&
          generalSettingsData.data.general_settings
            .social_login_fb_allowed_bool === 1
            ? true
            : false,
        google_login:
          generalSettingsData.data.general_settings &&
          generalSettingsData.data.general_settings
            .social_login_google_allowed_bool &&
          generalSettingsData.data.general_settings
            .social_login_google_allowed_bool === 1
            ? true
            : false,
        apple_login:
          generalSettingsData.data.general_settings &&
          generalSettingsData.data.general_settings
            .social_login_apple_allowed_bool &&
          generalSettingsData.data.general_settings
            .social_login_apple_allowed_bool === 1
            ? true
            : false,
        termandcondition:
          !generalSettingsData.data.general_settings ||
          !generalSettingsData.data.general_settings
            .website_terms_conditions_page_url
            ? ""
            : generalSettingsData.data.general_settings
                .website_terms_conditions_page_url,
        readstatus: generalSettingsData.data.build_status,
        websiteUrl: generalSettingsData.data.store_url
          ? generalSettingsData.data.store_url.replace(/^https?:\/\//i, "")
          : null,
        comparewebsiteUrl: generalSettingsData.data.store_url
          ? generalSettingsData.data.store_url.replace(/^https?:\/\//i, "")
          : null,
        is_verified: generalSettingsData.data.is_verified,
        build_status: generalSettingsData.data.build_status,
        skip_login:
          generalSettingsData.data.general_settings &&
          generalSettingsData.data.general_settings.is_login_first_screen &&
          generalSettingsData.data.general_settings.is_login_first_screen === 1
            ? true
            : false,
        loginbutton:
          generalSettingsData.data.general_settings &&
          generalSettingsData.data.general_settings
            .disable_login_signup_module === 0 &&
          generalSettingsData.data.general_settings.disable_login_signup_module
            ? true
            : false,
        bodyspinner: false,
        business_email: generalSettingsData.data.business_email,
        business_phone: generalSettingsData.data.business_phone,
        share_app:
          !generalSettingsData.data.general_settings ||
          !generalSettingsData.data.general_settings.app_share_allowed_bool ||
          generalSettingsData.data.general_settings.app_share_allowed_bool === 0
            ? false
            : true,
        share_app_url:
          !generalSettingsData.data.general_settings ||
          !generalSettingsData.data.general_settings.app_share_url
            ? ""
            : generalSettingsData.data.general_settings.app_share_url,
        consent_form:
          !generalSettingsData.data.consent_form ||
          generalSettingsData.data.consent_form.form_bool === 0
            ? false
            : true,
        consent_title:
          !generalSettingsData.data.consent_form ||
          generalSettingsData.data.consent_form.form_title
            ? ""
            : generalSettingsData.data.consent_form.form_title,
        consent_description:
          !generalSettingsData.data.consent_form ||
          !generalSettingsData.data.consent_form.form_description
            ? ""
            : generalSettingsData.data.consent_form.form_description,
        consent_form_purchased:
          !generalSettingsData.data.consent_form_purchased ||
          generalSettingsData.data.consent_form_purchased === 0
            ? 0
            : 1,
        appuser_role_id: generalSettingsData.data.appuser_role_id,
        allowhitforappmysiteapp:
          generalSettingsData.data.is_verified === 1 &&
          generalSettingsData.data.store_url === "https://shop.appmysite.com"
            ? 1
            : 0,
        store_api_version:
          !generalSettingsData.data.general_settings ||
          !generalSettingsData.data.general_settings.store_api_version
            ? 0
            : generalSettingsData.data.general_settings.store_api_version,
        workspaceWebTechnology: generalSettingsData.workspace_technology,
        appTechnology: generalSettingsData.data.app_technology,
        allow_changing_technology:
          generalSettingsData.allow_changing_technology,
        appspinner: false,
        lat: latitude,
        lng: longitude,
        zoom: generalSettingsData.data?.general_data?.map_coordinates ? 13 : 10,
        mapSelectedLocation: {
          lat: latitude,
          lng: longitude,
        },
        enableLanguage:
          generalSettingsData.data?.general_data?.enable_language_selection ||
          0,
        openEnableLanguageToggle:
          generalSettingsData.data?.is_language_addon_purchased || 0,
        enableOfflineMode:
          generalSettingsData.data?.general_data?.enable_offline_mode || 0,
        enable_prompt_on_exit: generalSettingsData.data?.general_data
          ?.enable_prompt_on_exit
          ? true
          : false,
        enable_auto_translate:
          generalSettingsData.data?.general_data?.enable_auto_translate ?? 1,
      });
      const renData = settingsState.optionsForDefaultLanguage.map(
        (data, idx) => {
          return data.value ==
            generalSettingsData.data?.general_data?.default_language
            ? data.label
            : "";
        }
      );

      setSettingsState({ defaultlanguage: renData });
    } else if (generalSettingsData?.code === 401) {
      if (generalSettingsData.status === "Token Expired") {
        let myItem = localStorage.getItem("buildversion");
        let myItemversion = localStorage.getItem("Apiversion");
        let previous_location = localStorage.getItem("Previouslocation");
        localStorage.clear();
        localStorage.setItem("Previouslocation", previous_location);
        localStorage.setItem("buildversion", myItem);
        localStorage.setItem("Apiversion", myItemversion);
      }
    } else if (generalSettingsData?.code === 201) {
      errortoast("Something went wrong. Try again after sometime.");
    }
  }, [generalSettingsData]);

  useEffect(() => {
    if (workspaceData && workspaceData?.code === 200) {
      let nextFrom = "appsetting";
      if (nextFrom === "billingDropdown" || nextFrom === "appsetting") {
        let wpData2 = workspaceData.data;
        let wpData = [];
        setSettingsState({
          agencies: workspaceData.agencylist,
        });
        wpData2.map(function (elem, i) {
          if (
            parseInt(localStorage.getItem("website_technology")) === 1 ||
            parseInt(localStorage.getItem("website_technology")) === 4 ||
            parseInt(localStorage.getItem("website_technology")) === 5
          ) {
            if (
              elem.subscription.package_info.website_technology ===
                parseInt(localStorage.getItem("website_technology")) ||
              elem.subscription.plan === "preview" ||
              elem.subscription.plan_code ===
                process.env.REACT_APP_STRIPE_PREVIEW ||
              elem.subscription.plan_code ===
                process.env.REACT_APP_PADDLE_PREVIEW ||
              elem.subscription.package_info.website_technology === parseInt(1)
            ) {
              wpData.push(elem);
            }
          } else if (
            parseInt(localStorage.getItem("website_technology")) === 2
          ) {
            if (
              elem.subscription.package_info.website_technology === 1 ||
              elem.subscription.package_info.website_technology === 2 ||
              elem.subscription.plan === "preview" ||
              elem.subscription.plan_code ===
                process.env.REACT_APP_STRIPE_PREVIEW ||
              elem.subscription.plan_code ===
                process.env.REACT_APP_PADDLE_PREVIEW
            ) {
              wpData.push(elem);
            }
          } else if (
            parseInt(localStorage.getItem("website_technology")) === 3
          ) {
            if (
              elem.subscription.package_info.website_technology === 1 ||
              elem.subscription.package_info.website_technology ===
                parseInt(localStorage.getItem("website_technology")) ||
              elem.subscription.plan === "preview" ||
              elem.subscription.plan_code ===
                process.env.REACT_APP_STRIPE_PREVIEW ||
              elem.subscription.plan_code ===
                process.env.REACT_APP_PADDLE_PREVIEW
            ) {
              wpData.push(elem);
            }
          }
        });

        let invited_workspace = workspaceData.invited_workspace;
        wpData = wpData.concat(invited_workspace);
        let newwpData = [];

        wpData.map(function (wp2, i) {
          if (
            (wp2.role_id === 1 || wp2.role_id === 2) &&
            wp2.user_id === parseInt(localStorage.getItem("user_id"))
          ) {
            newwpData.push(wp2);
          }
        });

        if (settingsState.mergeArray1 !== undefined) {
          setSettingsState({
            mergeArray1: newwpData,
          });
        }

        if (nextFrom !== "appsetting") {
          let children = newwpData.concat(invited_workspace);
          if (settingsState.mergeArray1 !== undefined) {
            setSettingsState({
              mergeArray1: children,
            });
          }
        }
      }
      if (
        // state === "Mobilemenu"
        "Mobilemenu"
      ) {
        // setData(workspaceData);
        if (workspaceData.data.length === 0) {
          // setCheckWorkspaceData(null);
        } else {
          // setCheckWorkspaceData(workspaceData.data);
        }
      } else {
        setSettingsState({
          spinner: false,
          menuData: workspaceData.data.length === 0 ? null : workspaceData.data,
          userInfo: workspaceData.user_info,
          invitedWorkspaceData: workspaceData.invited_workspace,
          total_workspace_count: workspaceData.total_workspace_count,
          total_personal_app_count: workspaceData.total_personal_app_count,
          total_personal_invited_app_count:
            workspaceData.total_personal_invited_app_count,
          user_role: workspaceData.user_role,
        });
      }
    }
  }, [workspaceData]);

  useEffect(() => {
    latestMapSearchInput.current = mapSearchInput;
  }, [mapSearchInput]);

  const renderPremiumicon = useCallback(() => {
    const workSpaceID = localStorage.getItem("workspaceId");
    const websiteTechnology = parseInt(
      localStorage.getItem("website_technology")
    );

    const premiumContent = (
      <>
        <img className="general-crown-icon" src={crownIcon} alt="crownIcon" />
        <p className="general-setting-premium-tooltip">Premium feature</p>
      </>
    );

    const appPlan = localStorage.getItem("app_plan");
    if (appPlan === "premium") {
      return premiumContent;
    }

    let pricingPath = "";
    if (workSpaceID) {
      pricingPath = `/workspace/pricing/${btoa(workSpaceID)}`;
    } else {
      const appId = btoa(localStorage.getItem("Appid"));
      switch (websiteTechnology) {
        case 4:
          pricingPath = `/app/wordpress-pricing/${appId}`;
          break;
        case 2:
          pricingPath = `/app/web-to-app-pricing/${appId}`;
          break;
        case 3:
          pricingPath = `/app/custom-app-pricing/${appId}`;
          break;
        default:
          pricingPath = `/app/woocommerce-pricing/${appId}`;
          break;
      }
    }
    return <Link to={pricingPath}>{premiumContent}</Link>;
  }, []);

  return (
    <div>
      {settingsState.verifypop === true && (
        <VerifyPopup
          settingsState={settingsState}
          handleverifymodal={handleverifymodal}
        />
      )}
      <Header customclass={"header-responsive-new"} />
      <section className="dasboard_page bottombar-module mobile-display">
        <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
        <div
          className={`right-panel-gap ${
            localStorage.getItem("appSideBarCollapse") !== undefined &&
            localStorage.getItem("appSideBarCollapse") !== null &&
            localStorage.getItem("appSideBarCollapse") === "true"
              ? " right-panel-gap-expand"
              : ""
          }`}
        >
          <div className="generalsetting-head">
            <ModuleTitleTooltip
              title={" Settings"}
              text={"Take control of your app's settings."}
            />

            {isGeneralSettingsDataLoading ? (
              <></>
            ) : (
              <>
                <div className="generalsetting-head-right-mob">
                  {settingsState.appuser_role_id === 1 &&
                    (localStorage.getItem("agencyid") == "" ||
                      !localStorage.getItem("agencyid") ||
                      localStorage.getItem("agencyid") == "undefined" ||
                      localStorage.getItem("agencyid") == "null" ||
                      localStorage.getItem("agencyid") == 1) && (
                      <div
                        className="d-flex align-items-center justify-content-center "
                        onClick={(e) => handlewebsiteplatform1(e)}
                      >
                        <MoveIcon />
                      </div>
                    )}
                  {localStorage.getItem("workspaceId") &&
                  localStorage.getItem("logged_in_user_role_id") &&
                  parseInt(localStorage.getItem("logged_in_user_role_id")) ===
                    1 ? (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      onClick={(e) => deleteapps(e)}
                    >
                      <Deletesvg />
                    </div>
                  ) : localStorage.getItem("appuser_role_id") &&
                    localStorage.getItem("appuser_role_id") &&
                    parseInt(localStorage.getItem("appuser_role_id")) === 1 ? (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      onClick={(e) => deleteapps(e)}
                    >
                      <Deletesvg />
                    </div>
                  ) : null}
                  {localStorage.getItem("appuser_role_id") &&
                  localStorage.getItem("appuser_role_id") &&
                  window.matchMedia("(max-width: 1200px)").matches &&
                  (parseInt(localStorage.getItem("appuser_role_id")) === 2 ||
                    parseInt(localStorage.getItem("appuser_role_id")) === 3 ||
                    parseInt(localStorage.getItem("appuser_role_id")) === 4) ? (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      onClick={(e) => leavetoggle(e, settingsState.appnameid)}
                    >
                      <Leavesvg />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="generalsetting-head-right">
                  {settingsState.lastdate &&
                  settingsState.lastdate !== "" &&
                  settingsState.lastdate !== undefined ? (
                    <UpdatedAt
                      timestamp={settingsState.lastdate}
                      format={"D MMMM YYYY, HH:mm"}
                    />
                  ) : null}

                  {settingsState.appuser_role_id === 1 &&
                    localStorage.getItem("agencyid") <= 1 && (
                      <button
                        className="generalsetting-delete-btn general-setting-move-btn"
                        onClick={(e) => handlewebsiteplatform1(e)}
                      >
                        <div className="d-flex align-items-center justify-content-center h-100 w-100">
                          <div className="icon-text-btndiv">
                            <MoveIcon />
                            Move
                          </div>
                        </div>
                      </button>
                    )}

                  {localStorage.getItem("appuser_role_id") &&
                  localStorage.getItem("appuser_role_id") &&
                  (parseInt(localStorage.getItem("appuser_role_id")) === 2 ||
                    parseInt(localStorage.getItem("appuser_role_id")) === 3 ||
                    parseInt(localStorage.getItem("appuser_role_id")) === 4) ? (
                    <button
                      className="generalsetting-delete-btn"
                      onClick={(e) => leavetoggle(e, settingsState.appnameid)}
                    >
                      <div className="d-flex align-items-center justify-content-center h-100 w-100">
                        <div className="icon-text-btndiv">
                          <Leavesvg />
                          Leave
                        </div>
                      </div>
                    </button>
                  ) : (
                    ""
                  )}
                  {localStorage.getItem("workspaceId") &&
                  localStorage.getItem("workspaceId") &&
                  localStorage.getItem("workspaceId") &&
                  localStorage.getItem("logged_in_user_role_id") &&
                  parseInt(localStorage.getItem("logged_in_user_role_id")) ===
                    1 ? (
                    <button
                      className="generalsetting-delete-btn"
                      onClick={(e) => deleteapps(e)}
                    >
                      <div className="d-flex align-items-center justify-content-center h-100 w-100">
                        <div className="icon-text-btndiv">
                          <Deletesvg />
                          Delete
                        </div>
                      </div>
                    </button>
                  ) : localStorage.getItem("appuser_role_id") &&
                    localStorage.getItem("appuser_role_id") &&
                    parseInt(localStorage.getItem("appuser_role_id")) === 1 ? (
                    <button
                      className="generalsetting-delete-btn"
                      onClick={(e) => deleteapps(e)}
                    >
                      <div className="d-flex align-items-center justify-content-center h-100 w-100">
                        <div>
                          <Deletesvg />
                          Delete
                        </div>
                      </div>
                    </button>
                  ) : null}

                  <button
                    className="generalsetting-save-btn"
                    form="pluginsub"
                    style={{
                      backgroundColor: localStorage.getItem(
                        "button_background_color"
                      ),
                      color: localStorage.getItem("button_text_color"),
                      "--custom_color5":
                        localStorage.getItem("agencyid") == 1
                          ? "#134bec"
                          : localStorage.getItem("button_hover_bgcolor"),
                      "--custom_color4":
                        localStorage.getItem("agencyid") == 1
                          ? "white"
                          : localStorage.getItem("button_hover_txtcolor"),
                      "--custom_color6":
                        localStorage.getItem("agencyid") == 1
                          ? "#3064f9"
                          : localStorage.getItem("button_hover_bgcolor"),
                    }}
                    disabled={isSaveGeneralSettingsDataLoading}
                  >
                    <div className="d-flex align-items-center justify-content-center h-100 w-100">
                      {isSaveGeneralSettingsDataLoading ? (
                        <div className="new-loader"></div>
                      ) : (
                        <div>Save</div>
                      )}
                    </div>
                  </button>
                </div>
              </>
            )}
          </div>

          {isGeneralSettingsDataLoading ? (
            <div className="generalSetting-spinner">
              <Spinner />
            </div>
          ) : (
            <>
              <form
                onSubmit={(e) => settingsSubmit(e)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevent form submission on Enter
                  }
                }}
                id="pluginsub"
              >
                <div className="generalsettingbg">
                  <div className="generalsetting-width">
                    <div className="generalsetting-settings">
                      <div className="general-setting-head">
                        <h3 className="generalsetting-head-title">General</h3>
                        <p className="generalsetting-head-description">
                          This is your app’s central control hub for customizing
                          key configurations.
                        </p>
                      </div>

                      <div className="general-setting-body">
                        <div>
                          <h6 className="general-setting-body-label">
                            App name
                            <Tooltip
                              message={"Specify a unique name for your app."}
                            />
                          </h6>

                          <input
                            className="general-setting-body-input-app-name"
                            type="text"
                            maxLength="30"
                            minLength="3"
                            value={settingsState.appname}
                            onChange={(e) => handleAppname(e)}
                            onFocus={() => {
                              clearAllBeacons();
                              setSettingsState({
                                promptOnExitDrawerOpen: false,
                                forceAppUpdateModalOpen: false,
                              });
                            }}
                          />
                        </div>

                        <div className="general-setting-gap1">
                          <h6 className="general-setting-body-label">
                            Product
                            <Tooltip
                              message={
                                "Choose the product that best suits your app development needs."
                              }
                            />
                          </h6>

                          <div className="app-type-general-settings">
                            <div className="app-settingtype-select-div">
                              {settingsState.website_technology === 2 ? (
                                <>
                                  <img
                                    className="img-fluid"
                                    src={appType1}
                                    alt="app-type1"
                                  />
                                  <p className="app-setting-type-text">
                                    Web to app
                                  </p>
                                </>
                              ) : settingsState.website_technology === 5 ? (
                                <>
                                  <img
                                    className="img-fluid"
                                    src={appType3}
                                    alt="app-type2"
                                  />
                                  <p className="app-setting-type-text">
                                    WooCommerce app
                                  </p>
                                </>
                              ) : settingsState.website_technology === 3 ? (
                                <>
                                  <img
                                    className="img-fluid"
                                    src={appType4}
                                    alt="app-type3"
                                  />
                                  <p className="app-setting-type-text">
                                    Custom app
                                  </p>
                                </>
                              ) : settingsState.website_technology === 4 ? (
                                <>
                                  <img
                                    className="img-fluid"
                                    src={appType2}
                                    alt="app-type4"
                                  />
                                  <p className="app-setting-type-text">
                                    Wordpress app
                                  </p>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                            {localStorage.getItem("agencyid") &&
                            localStorage.getItem("agencyid") != 1 ? (
                              <></>
                            ) : (
                              (settingsState.appuser_role_id == 1 ||
                                settingsState.appuser_role_id == 2) && (
                                <h4
                                  className="app-type-change-btn"
                                  onClick={(e) => handlePaidSubs(e)}
                                >
                                  Change
                                </h4>
                              )
                            )}
                          </div>
                        </div>
                        {parseInt(settingsState.website_technology) !== 3 && (
                          <div className="general-setting-gap1 general-setting-url">
                            <h6 className="general-setting-body-label">
                              Website
                              <Tooltip
                                message={
                                  "Provide your website’s link here. This will establish a vital connection between your website and app."
                                }
                              />
                            </h6>

                            <div className="app-setting-website-div">
                              <div className="general-setting-link">
                                <span>https://</span>
                              </div>
                              <input
                                className="general-setting-body-input"
                                placeholder="www.yourwebsite.com"
                                type="text"
                                name="hello"
                                id="hello"
                                value={settingsState.websiteUrl}
                                required
                                onChange={(e) => handleWebsiteUrl(e)}
                                onClick={() => setActiveSlide(1)}
                                onFocus={() => {
                                  clearAllTimeouts();
                                  setSettingsState({
                                    isBeaconBlinkingWebsiteUrl: true,
                                    isBeaconBlinkingTnc: false, // remove beacon from other field
                                    isBeaconBlinkingOffline: false, // remove beacon from other field
                                    isBeaconBlinkingLanguage: false, // remove beacon from other field
                                    promptOnExitDrawerOpen: false, // remove prompt on exit ui
                                    forceAppUpdateModalOpen: false, // remove app update modal
                                  });
                                }}
                                onBlur={() => {
                                  if (
                                    settingsState.isBeaconBlinkingWebsiteUrl
                                  ) {
                                    setSettingsState({
                                      isBeaconBlinkingWebsiteUrl: false,
                                    });
                                  }
                                }}
                              />

                              <div>
                                {parseInt(
                                  localStorage.getItem("website_technology")
                                ) !== 2 &&
                                parseInt(
                                  localStorage.getItem("website_technology")
                                ) !== 3 ? (
                                  <>
                                    {settingsState.is_verified === 1 ? (
                                      <div className="app-setting-varify-tick">
                                        <GreenTick />
                                      </div>
                                    ) : (
                                      <Link to="/app/connect/wp-plugin">
                                        <h4 className="app-setting-varify-btn">
                                          Verify now
                                        </h4>
                                      </Link>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        )}

                        {(parseInt(settingsState.website_technology) == 5 ||
                          parseInt(settingsState.website_technology) == 4) && (
                          <div className="general-setting-gap1">
                            <h6 className="general-setting-body-label">
                              Terms and conditions
                              <Tooltip
                                message={
                                  "Add a link to your terms and conditions page.It’ll appear on your app’s user registration page if enabled."
                                }
                              />
                            </h6>

                            <div className="app-setting-website-div">
                              <div className="general-setting-link">
                                <span>https://</span>
                              </div>
                              <input
                                className="general-setting-body-input"
                                type="text"
                                placeholder="www.yourwebsite.com/terms-and-conditions"
                                title="We support only HTTPS"
                                value={settingsState.termandcondition.replace(
                                  /^https?:\/\//i,
                                  ""
                                )}
                                onChange={(e) => handleterm(e)}
                                onClick={() => setActiveSlide(1)}
                                onFocus={() => {
                                  clearAllTimeouts();
                                  setSettingsState({
                                    isBeaconBlinkingTnc: true,
                                    isBeaconBlinkingWebsiteUrl: false, // Remove beacon from url field
                                    isBeaconBlinkingOffline: false, // Remove beacon from url field
                                    isBeaconBlinkingLanguage: false, // remove beacon from other field
                                    promptOnExitDrawerOpen: false, // remove prompt on exit ui
                                    forceAppUpdateModalOpen: false, // remove app update modal
                                  });
                                }}
                                onBlur={() => {
                                  if (settingsState.isBeaconBlinkingTnc) {
                                    setSettingsState({
                                      isBeaconBlinkingTnc: false,
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                        )}

                        <div className="general-setting-gap1">
                          <h6 className="general-setting-body-label">
                            Country
                            <Tooltip
                              message={
                                " Select the country associated to your app."
                              }
                            />
                          </h6>
                          <CountryDropdown
                            wrapperRef={wrapperRef}
                            settingsState={settingsState}
                            setSettingsState={setSettingsState}
                            handleCountryDropdown={handleCountryDropdown}
                            togglephonecodemodal={togglephonecodemodal}
                            handleOpenCoutryList={handleOpenCoutryList}
                            handleDropdownSearch={handleDropdownSearch}
                            clearAllBeacons={clearAllBeacons}
                          />
                        </div>

                        <div className="general-setting-gap1">
                          <h6 className="general-setting-body-label">
                            Map coordinates
                            <Tooltip
                              message={
                                " Specify the primary location of your app. Some features may require this information."
                              }
                            />
                          </h6>
                          <div
                            className="setting-map-cordinate-main-div"
                            onClick={() => handleLocationToggle()}
                          >
                            <div className="generalsetting-parent-input-popup">
                              <p
                                className="generalsetting-popup-data-name"
                                style={{
                                  color: settingsState.lat
                                    ? "#1B2952"
                                    : "#bcc1ce",
                                }}
                              >
                                {settingsState.lat
                                  ? settingsState.lat +
                                    " " +
                                    "," +
                                    " " +
                                    settingsState.lng
                                  : "Select"}
                              </p>
                              <LocationSvg />
                            </div>
                          </div>
                        </div>

                        {(parseInt(settingsState.website_technology) == 3 ||
                          parseInt(settingsState.website_technology) == 2) && (
                          <>
                            <div className="general-setting-gap1">
                              <div className="premiun-setting-div">
                                <h6 className="general-setting-body-label">
                                  Default language
                                  <Tooltip message="Select the default language of your app. This is the original language in which your app’s content is created." />{" "}
                                </h6>
                              </div>
                              <LanguageDropdown
                                handleOpenMobDefaultLanguageDropdown={
                                  handleOpenMobDefaultLanguageDropdown
                                }
                                handleDefaultLanguageChange={
                                  handleDefaultLanguageChange
                                }
                                settingsState={settingsState}
                              />
                            </div>
                          </>
                        )}

                        <div className="general-setting-toggle">
                          <h6 className="d-flex items-center general-setting-toggle-label">
                            Enable auto-translate
                            <Tooltip
                              message={
                                "Enable to translate all app content from other languages into the default language. Disabling this will keep all content in its original language."
                              }
                            />
                            <div>
                              <Newfeatureicon />
                            </div>
                          </h6>

                          <div className="general-setting-toggle-right">
                            <span className="generalsetting-crown-icon-div">
                              {renderPremiumicon()}
                            </span>

                            <label className="switch" for="auto_translate">
                              <input
                                type="checkbox"
                                id="auto_translate"
                                name="auto_translate"
                                checked={
                                  settingsState.enable_auto_translate === 1
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  if (!settingsState.openEnableLanguageToggle) {
                                    clearAllBeacons();
                                    return;
                                  }
                                  toggleHandling(e);
                                  clearAllBeacons();
                                  setSettingsState({
                                    enable_auto_translate: e.target.checked
                                      ? 1
                                      : 0,
                                  });
                                }}
                              />
                              {settingsState.openEnableLanguageToggle && (
                                <div className="slider round"></div>
                              )}
                              {!settingsState.openEnableLanguageToggle && (
                                <div className="notification-access-slider round"></div>
                              )}
                            </label>
                          </div>
                        </div>

                        {TECHNOLOGY !== 2 && (
                          <div className="general-setting-toggle">
                            <div className="premiun-setting-div">
                              <h6 className="general-setting-toggle-label">
                                Enable language selection
                                <Tooltip
                                  message="Enabling this toggle will allow app users to
                                choose their preferred language. Disabling this
                                toggle will hide the language selection option,
                                restricting users to a single language."
                                />
                              </h6>
                            </div>

                            <div className="general-setting-toggle-right">
                              <div className="generalsetting-crown-icon-div">
                                {renderPremiumicon()}
                              </div>
                              <label
                                className="switch"
                                for="generalLangSettings"
                              >
                                <input
                                  type="checkbox"
                                  id="generalLangSettings"
                                  name="generalsettings1"
                                  checked={settingsState.enableLanguage}
                                  onChange={(e) => {
                                    if (
                                      languageTimeout.current ||
                                      !e.target.checked
                                    ) {
                                      clearTimeout(languageTimeout.current);
                                      languageTimeout.current = null;
                                    }
                                    if (
                                      !settingsState.openEnableLanguageToggle
                                    ) {
                                      if (
                                        localStorage.getItem("app_plan") &&
                                        localStorage.getItem("app_plan") !==
                                          "premium"
                                      ) {
                                        setSettingsState({
                                          isBeaconBlinkingLanguage: true,
                                          isBeaconBlinkingOffline: false,
                                          isBeaconBlinkingTnc: false, // Remove beacon from tnc field
                                          isBeaconBlinkingWebsiteUrl: false, // Remove beacon from url field
                                          promptOnExitDrawerOpen: false,
                                          forceAppUpdateModalOpen: false,
                                        });

                                        languageTimeout.current = setTimeout(
                                          () => {
                                            setSettingsState({
                                              isBeaconBlinkingLanguage: false, // Remove beacon after 5 seconds
                                            });
                                          },
                                          5000
                                        ); // Clear timeout after 5 sec on turning the toggle ON
                                      }
                                      return;
                                    } else {
                                      !settingsState.enableLanguage
                                        ? setActiveSlide(5)
                                        : setActiveSlide(0);
                                      setSettingsState({
                                        isBeaconBlinkingLanguage: true,
                                        isBeaconBlinkingOffline: false,
                                        isBeaconBlinkingTnc: false, // Remove beacon from tnc field
                                        isBeaconBlinkingWebsiteUrl: false, // Remove beacon from url field
                                        promptOnExitDrawerOpen: false,
                                        forceAppUpdateModalOpen: false,
                                        enableLanguage:
                                          !settingsState.enableLanguage ? 1 : 0,
                                      });
                                      toggleHandling(e);

                                      languageTimeout.current = setTimeout(
                                        () => {
                                          setSettingsState({
                                            isBeaconBlinkingLanguage: false, // Remove beacon after 5 seconds
                                          });
                                        },
                                        5000
                                      ); // Clear timeout after 5 sec on turning the toggle ON
                                    }
                                  }}
                                />
                                {settingsState.openEnableLanguageToggle && (
                                  <div className="slider round"></div>
                                )}
                                {!settingsState.openEnableLanguageToggle && (
                                  <div className="notification-access-slider round"></div>
                                )}
                              </label>
                            </div>
                          </div>
                        )}

                        <div className="general-setting-toggle">
                          <h6 className="general-setting-toggle-label">
                            Force app update
                            <Tooltip
                              message={
                                "Display a prompt to your app users to update the  app when you release a new version on the app stores."
                              }
                            />
                          </h6>

                          <label
                            className="switch"
                            for="force_app_to_update_latest_version"
                          >
                            <input
                              type="checkbox"
                              id="force_app_to_update_latest_version"
                              name="force_app_to_update_latest_version"
                              checked={
                                settingsState.force_app_to_update_latest_version ===
                                1
                                  ? true
                                  : false
                              }
                              onChange={(e) => handleforcetoggle(e)}
                            />
                            <div className="slider round"></div>
                          </label>
                        </div>
                        {TECHNOLOGY !== 2 && (
                          <div className="general-setting-toggle">
                            <div className="premiun-setting-div">
                              <h6 className="general-setting-toggle-label">
                                Enable offline mode
                                <Tooltipicon
                                  message={
                                    "Enable to allow users to access your app offline."
                                  }
                                />
                              </h6>
                            </div>

                            <div className="general-setting-toggle-right">
                              <div className="generalsetting-crown-icon-div">
                                {renderPremiumicon()}
                              </div>
                              <label
                                className="switch"
                                for="generalSettingsOfflineMode"
                              >
                                <input
                                  type="checkbox"
                                  id="generalSettingsOfflineMode"
                                  name="generalSettingsOfflineMode"
                                  checked={settingsState.enableOfflineMode}
                                  onChange={(e) => {
                                    if (offlineTimeout.current) {
                                      clearTimeout(offlineTimeout.current);
                                      offlineTimeout.current = null;
                                    }
                                    setActiveSlide(1);
                                    if (
                                      localStorage.getItem("app_plan") &&
                                      localStorage.getItem("app_plan") !==
                                        "premium"
                                    ) {
                                      setSettingsState({
                                        isBeaconBlinkingOffline: true,
                                        isBeaconBlinkingTnc: false, // Remove beacon from tnc field
                                        isBeaconBlinkingWebsiteUrl: false, // Remove beacon from url field
                                        isBeaconBlinkingLanguage: false, // Remove beacon from other field
                                        promptOnExitDrawerOpen: false,
                                        forceAppUpdateModalOpen: false,
                                      });

                                      offlineTimeout.current = setTimeout(
                                        () => {
                                          setSettingsState({
                                            isBeaconBlinkingOffline: false,
                                          });
                                        },
                                        5000
                                      ); // Clear timeout after 5 sec on turning the toggle ON

                                      return;
                                    } else {
                                      setSettingsState({
                                        isBeaconBlinkingOffline: true,
                                        isBeaconBlinkingTnc: false, // Remove beacon from tnc field
                                        isBeaconBlinkingWebsiteUrl: false, // Remove beacon from url field
                                        isBeaconBlinkingLanguage: false, // Remove beacon from other field
                                        promptOnExitDrawerOpen: false,
                                        forceAppUpdateModalOpen: false,
                                        enableOfflineMode:
                                          !settingsState.enableOfflineMode
                                            ? 1
                                            : 0,
                                      });

                                      offlineTimeout.current = setTimeout(
                                        () => {
                                          setSettingsState({
                                            isBeaconBlinkingOffline: false,
                                          });
                                        },
                                        5000
                                      ); // Clear timeout after 5 sec on turning the toggle ON

                                      toggleHandling(e);
                                    }
                                  }}
                                />
                                {localStorage.getItem("app_plan") &&
                                localStorage.getItem("app_plan") ==
                                  "premium" ? (
                                  <div className="slider round"></div>
                                ) : (
                                  <div className="notification-access-slider round"></div>
                                )}
                              </label>
                            </div>
                          </div>
                        )}

                        <div className="general-setting-toggle">
                          <h6 className="general-setting-toggle-label">
                            Prompt on exit{" "}
                            <Tooltip
                              message={
                                "Enable to prompt Android device users with a confirmation pop-up message before exiting the app preventing accidental exits."
                              }
                            />
                          </h6>

                          <label className="switch" for="enable_prompt_on_exit">
                            <input
                              type="checkbox"
                              id="enable_prompt_on_exit"
                              name="enable_prompt_on_exit"
                              checked={settingsState.enable_prompt_on_exit}
                              onChange={(e) => handleAllSettingToggles(e)}
                            />
                            <div className="slider round"></div>
                          </label>
                        </div>
                      </div>
                    </div>

                    <GeneralSettingsPreview
                      settingsState={settingsState}
                      activeSlide={activeSlide}
                      setActiveSlide={setActiveSlide}
                    />
                  </div>
                </div>

                <button
                  className="general-mobile-btns"
                  form="pluginsub"
                  style={{
                    backgroundColor: localStorage.getItem(
                      "button_background_color"
                    ),
                    border:
                      "1px solid " +
                      localStorage.getItem("button_background_color"),
                    color: localStorage.getItem("button_text_color"),
                    "--custom_color5":
                      localStorage.getItem("agencyid") == 1
                        ? "#134bec"
                        : localStorage.getItem("button_hover_bgcolor"),
                    "--custom_color4":
                      localStorage.getItem("agencyid") == 1
                        ? "white"
                        : localStorage.getItem("button_hover_txtcolor"),
                    "--custom_color6":
                      localStorage.getItem("agencyid") == 1
                        ? "#3064f9"
                        : localStorage.getItem("button_hover_bgcolor"),
                  }}
                  disabled={isSaveGeneralSettingsDataLoading}
                >
                  <div className="general-mobile-btns-blue">
                    {isSaveGeneralSettingsDataLoading ? (
                      <div className="new-loader"></div>
                    ) : (
                      <div>Save</div>
                    )}
                  </div>

                  {settingsState.lastdate &&
                  settingsState.lastdate !== "" &&
                  settingsState.lastdate !== undefined ? (
                    <h5>
                      Updated{" "}
                      {moment(settingsState.lastdate).format(
                        "D MMMM YYYY, HH:mm"
                      )}{" "}
                    </h5>
                  ) : null}
                </button>
              </form>
              <MoveAppModal
                settingsState={settingsState}
                cancelpopup={cancelpopup}
                handleTransfer={handleTransfer}
                photoggle={photoggle}
              />
              <PaidSubscriptionModal
                settingsState={settingsState}
                paidsubscriptionpopup={paidsubscriptionpopup}
              />

              <LeaveAppModal
                settingsState={settingsState}
                leavetoggle={leavetoggle}
                leaveapp={leaveapp}
                leaveAppDataLoading={leaveAppDataLoading}
              />
              <ChangeProductModal
                settingsState={settingsState}
                handlePaidSubs={handlePaidSubs}
                handletypetab={handletypetab}
                handletypechangepopup={handletypechangepopup}
              />
              <DeleteAppModal
                settingsState={settingsState}
                deletapp={deletapp}
                deleteapps={deleteapps}
                deleteAppDataLoading={deleteAppDataLoading}
                handleDeleteAppname={handleDeleteAppname}
              />
              <ProductTechnologyModal
                settingsState={settingsState}
                handletypechangepopupclose={handletypechangepopupclose}
                handletypechangepopupsubmit={handletypechangepopupsubmit}
              />
            </>
          )}
        </div>

        <TransferModal
          photoggle={photoggle}
          settingsState={settingsState}
          updateTransfer={updateTransfer}
          moveAppDataLoading={moveAppDataLoading}
        />
        <LocationModal
          mapRef={mapRef}
          searchRef={searchRef}
          inputRef={inputRef}
          mapSearchInput={mapSearchInput}
          setMapSearchInput={setMapSearchInput}
          handleLocationClick={handleLocationClick}
          handleMapClick={handleMapClick}
          handleLocationSelected={handleLocationSelected}
          handleLocationToggle={handleLocationToggle}
          setSettingsState={setSettingsState}
          settingsState={settingsState}
        />
      </section>
    </div>
  );
};

export default GeneralSettings;
