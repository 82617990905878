import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const WithoutCancelModal = ({
  open,
  toggle,
  title,
  description,
  submitbutton,
  onSubmit,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await onSubmit(); // Call the onSubmit function
    } catch (error) {
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
     <Modal
          className="notification-conf-secondmodal"
          style={{ maxWidth: "480px", maxHeight: "280px" }}
          isOpen={open}
          toggle={toggle}
          centered
        >
          <ModalHeader>
            <div
              className="notification-conf-secondmodal-crossmodal"
              style={{ zIndex: "9" }}
            >
              <img
                onClick={toggle}
                src={require("../../assets/images/dashboard/cross.png").default}
              />
            </div>
          </ModalHeader>

          <ModalBody>
            <div className="notification-conf-popup-maindiv">
              <div className="notification-conf-popup-head">
                <h2>{title}</h2>
              </div>

              <p>
                {description}
              </p>

              <div className="notification-conf-popup-bottom">
                <button
                  className="notification-conf-popup-confirm"
                  onClick={handleSubmit}
                >
                  {submitbutton}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
  );
};

export default WithoutCancelModal;
