import React from "react";
import { Link } from "react-router-dom";
import "./Notactiveaddon.css";
const Notaddonactive = (props) => {
  return (
    <div className="notactivate_upgrade-div">

      
        <div className="notactivate_upgrade-colone">
          <div>
          <h3>
            Analytics for your app
          </h3>
          <p>
          Understand your customers and their app usage better to make informed decisions.
          </p>
          <ul >
            <li>Track your app downloads and unique users. </li>
            <li>Analyze your app’s usage with date range filters. </li>
            <li>Compare your Android vs iOS performance. </li>
            <li>Get insights into trending searches and popular screens.</li>
          </ul>
          {
            props.value.state.trackDataFinalVal === false ?
            <button
            onClick={(e)=>props.value.handleEnableTrackDataBtn(e)}
            className="notactive_purchagebtn"
            >
            Enable analytics
          </button>
            :
          
          <>
          {props.value.state.plan_code.indexOf(
            process.env.REACT_APP_Lifetime_plan
          ) > -1 ||
          props.value.state.plan_code.indexOf(
            process.env.REACT_APP_Lifetime_preview
          ) > -1 || props.value.state.reseller_package_id ? (
            // || props.value.state.reseller_package_id !== null
            (props.value.state.appuser_role_id == 3 || props.value.state.appuser_role_id == 4 
              ||
              parseInt(localStorage.getItem("logged_in_user_role_id")) === 3 ||
              parseInt(localStorage.getItem("logged_in_user_role_id")) === 4
              )? <button className="disable-role-btn" disabled>Contact your admin</button> :
            <Link
              className="notactive_purchagebtn"
              to={"/app/addons"
                // "/app/addons/analytics/" +
                // btoa(props.value.state.addon_code) +
                // "/" +
                // btoa(localStorage.getItem("Appid")) +
                // "/" +
                // btoa(props.value.state.plan_code) +
                // "/" +
                // btoa("ANALYTICS")
              }
            >
               Buy addon
            </Link>
            
          )
          :(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") != 1) ?  
              null
          : 
           (
            //     <Link className="next_btn d-block"  to="#">
            //     Coming soon
            // </Link>
            // <Link
            //   className="next_btn d-block"
            //   to={
            //     localStorage.getItem("workspaceId") === undefined ||
            //     localStorage.getItem("workspaceId") === "undefined" ||
            //     localStorage.getItem("workspaceId") === null ||
            //     localStorage.getItem("workspaceId") === "null" ||
            //     localStorage.getItem("workspaceId") === ""
            //       ? "/subscription/" + btoa(localStorage.getItem("Appid"))
            //       : "/pricing/" + btoa(localStorage.getItem("workspaceId"))
            //   }
            // >
            //   Upgrade Plan
            // </Link>
            (props.value.state.appuser_role_id == 3 || props.value.state.appuser_role_id == 4 ||
             parseInt(localStorage.getItem("logged_in_user_role_id")) === 3 || parseInt(localStorage.getItem("logged_in_user_role_id")) === 4) ? 
              <button className="disable-role-btn" disabled>Contact your admin</button> 
            : props.value.state.addon_code != '' && props.value.state.addon_code && props.value.state.addon_code != null
           && props.value.state.reseller_package_id 
            ?
            <Link
            className="notactive_purchagebtn"
            to={"/app/addons"
              // "/app/addons/analytics/" +
              // btoa(props.value.state.addon_code) +
              // "/" +
              // btoa(localStorage.getItem("Appid")) +
              // "/" +
              // btoa(props.value.state.plan_code) +
              // "/" +
              // btoa("ANALYTICS")
            }
          >
            Buy addon
          </Link>
            :
            
            <Link
            className="notactive_purchagebtn"
            to={
              localStorage.getItem("workspaceId") === undefined ||
              localStorage.getItem("workspaceId") === "undefined" ||
              localStorage.getItem("workspaceId") === null ||
              localStorage.getItem("workspaceId") === "null" ||
              localStorage.getItem("workspaceId") === ""
                ? parseInt(localStorage.getItem('website_technology')) === 4 ? "/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid'))
                : parseInt(localStorage.getItem('website_technology')) === 5 ? "/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'))
                : parseInt(localStorage.getItem('website_technology')) === 3 ? "/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'))
                : parseInt(localStorage.getItem('website_technology')) === 2 ? "/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'))
                : "/app/pricing"
                : "/workspace/pricing/" + btoa(localStorage.getItem("workspaceId"))
            }
          >
            Upgrade
          </Link>
          )}
          </>
         }
        </div>
        </div>

      <div className="notactivate_upgrade-coltwo">
       
          <img
            src={
              require("../../assets/images/dashboard/analytics/analytics-upgrade.png")
                .default
            }
            alt="analytics"
            className="img-fluid "
          />
       
      </div>
      
    </div>
  );
};
export { Notaddonactive as default };
