import React, {useEffect, useState} from 'react'
import Leftpanel from "../Leftpanel";
import Header from "../Header";
import { Link, useHistory } from "react-router-dom";
import {
    GetAllUsers,
} from "../../Api/RichNotification/RichNotificationApi";
import NewSpinner from "./NewSpinner";
import moment from "moment";
import SkipBtn from '../../Components/commonIcons/SkipBtn';
import PremiumTag from "../../Components/commonIcons/PremiumTag";

const Users = () => {
    const history = useHistory();
    
    const [appid, setAppID] = useState(localStorage.getItem('Appid'));
    const [allusers, setAllUsers] = useState([]);
    const [spinner, setSpinner] = useState(true);
    const [planname, setPlanName] = useState("");
    const [code, setCode] = useState(203);
    const [plan_code, setplan_code] = useState("");
    const [reseller_package_id, setreseller_package_id] = useState(null);
    const [appRightPanelExpand, setAppRightPanelExpand] = useState( localStorage.getItem("appSideBarCollapse") === true ||
	localStorage.getItem("appSideBarCollapse") === "true"
	  ? true
	  : false);
      const [skipButtonActive, setSkipButtonActive] = useState(false);

    useEffect(()=>{

        if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
            {
              document.title = "Notification audience | AppMySite";
            }
            else 
            {
              document.title = "Notification audience |"+ ' ' +localStorage.getItem('agencyname');
            }


		GetAllUsers( 
            setSpinner,
             appid,
             setAllUsers,
             setPlanName,
             setCode,
             setreseller_package_id,
             setplan_code,
            );

    }, []);

    const RedirectPlanPage = (props) =>{

        
		let redirectpath = '';

		if(
            localStorage.getItem("workspaceId") !== "" && 
            localStorage.getItem("workspaceId") !== undefined && 
            localStorage.getItem("workspaceId") !== "undefined" && 
            localStorage.getItem("workspaceId") !== null
        )
		{
			// redirectpath = "/pricing/" + btoa(localStorage.getItem("workspaceId"));
            redirectpath = `/workspace/pricing/${btoa(localStorage.getItem("workspaceId"))}`;
		}
        else if(reseller_package_id || (plan_code && plan_code.includes("appsumo") )){
            redirectpath = "/app/addons";
        }
		else if(atob(localStorage.getItem("plan_code")) === process.env.REACT_APP_Lifetime_preview)
		{
			redirectpath = "/appsumo-plan-detail";
		}
		else
		{
			// redirectpath = "/subscription/" + btoa(localStorage.getItem("Appid"));
            parseInt(localStorage.getItem('website_technology')) === 4 ? 
						  redirectpath = "/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid'))
                          : parseInt(localStorage.getItem('website_technology')) === 5 ? 
						  redirectpath = "/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'))
                          : parseInt(localStorage.getItem('website_technology')) === 3 ? 
						  redirectpath = "/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'))
                          : parseInt(localStorage.getItem('website_technology')) === 2 ? 
						  redirectpath =  "/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'))
                          : 
						  redirectpath =  "/app/pricing" ;
		}
        
		//props.history.push(redirectpath);
		history.push(redirectpath);
															

	}

    const handleRightPanelExpand = (value) => {

		setAppRightPanelExpand(value);
	}

    const handleSkipClick = () => {
        setSkipButtonActive(true);
      };



    return (
    <>
    <Header customclass={"header-responsive-new"} />

    <section className="dasboard_page newmobile-display">


        <div
            className={`user-spacing right-panel-gap pushNotification-right-panel ${
              localStorage.getItem("appSideBarCollapse") !== undefined &&
              localStorage.getItem("appSideBarCollapse") !== null &&
              localStorage.getItem("appSideBarCollapse") === "true"
                ? " right-panel-gap-expand"
                : ""
            }`}
        >
            <div className="mob-upgradenotificationpage">
                <div className='web-notification-head' style={{marginBottom: "0px"}}>
                    <h1>Advanced notifications
                        <p className=" consent-tooltip">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                                    <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                                        <circle cx="9" cy="9" r="9" stroke="none" />
                                        <circle cx="9" cy="9" r="8.25" fill="none" />
                                    </g>
                                    <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5" />
                                    <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5" />
                                </g>
                            </svg>
                            <p className="consent-tooltipsubheader">
                            Send out updates and engaging messages to your app users.{" "}
                            </p>
                        </p>
                    </h1>
                </div>

                <div className="notification-modal-tab">
                    <div className="notificationlink notificationlink-list" style={{ height: "33px" }}>
                        <Link to="/app/advanced-notifications">Notifications</Link>
                        <Link to="/app/advanced-notifications/subsets">Group</Link>
                        <Link className="active" to="/app/advanced-notifications/audience">Users</Link>
                    </div>

                    {
                      (localStorage.getItem("agencyid") && localStorage.getItem("agencyid") != 1) &&
                      window.matchMedia("(min-width: 1200px)").matches && code !== 200 && spinner === false &&
                    <div style={{marginBottom: "0px"}} className="upgrade-feature">
                      <h4 style={{textAlign: "unset"}} className="upgrade-unavailable">This feature is currently unavailable</h4>
                      <p style={{textAlign: "unset"}} className="upgrade-support">Please contact Support for assistance.</p>
                    </div>
                    }

                    { (code === 200 || skipButtonActive) &&
                    <div className="notificationcreate-new ">
                        {/*<div className="pushnotification-Search" style={{width: "100%"}}>
                            <form>
                                <button>
                                    <svg id="Group_3669" data-name="Group 3669" xmlns="http://www.w3.org/2000/svg" width="16.53" height="16.53" viewBox="0 0 16.53 16.53">
                                        <g id="Ellipse_246" data-name="Ellipse 246" transform="translate(14.675) rotate(90)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                                            <ellipse cx="7.337" cy="7.337" rx="7.337" ry="7.337" stroke="none" />
                                            <ellipse cx="7.337" cy="7.337" rx="6.587" ry="6.587" fill="none" />
                                        </g>
                                        <path id="Path_6064" data-name="Path 6064" d="M0,5.347V0" transform="translate(16 16) rotate(135)" fill="none" stroke="#bcc1ce" stroke-width="1.5" />
                                    </svg>
                                </button>
                                <input placeholder="Search"></input>
                            </form>
                            
                            <svg style={{position: "relative", cursor:"pointer",left:"-15px", top:"14px"}} xmlns="http://www.w3.org/2000/svg" width="11.06" height="11.06" viewBox="0 0 11.06 11.06" onClick={(e) => this.context.state.handlecancelsearch(e)}>
                                <path id="Union_2970" data-name="Union 2970" d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                            </svg>
                        </div> */}
                        {/* <div className="consent-setting-clicked" style={{marginRight: "0px",marginLeft:"20px" ,cursor:"default"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                       <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                    <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                    <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="#7782a1" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                  </g>
                </svg>
                            </div> */}
                                                     { skipButtonActive && <PremiumTag/> }

                        <div className="consent-setting user-consent-setting">
                        

                                <Link to={"/app/notifications/build-settings"} className="pointcursor" style={{border: "unset", margin: "0px"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24">
                                                        <g id="Group_43299" data-name="Group 43299" transform="translate(-1718 -125)">
                                                            <rect id="Rectangle_32508" data-name="Rectangle 32508" width="26" height="24" transform="translate(1718 125)" fill="none" />
                                                            <g id="Rectangle_31502" data-name="Rectangle 31502" transform="translate(1722 125)" fill="#f8fafe" stroke="#7782a1" stroke-width="1.5">
                                                                <rect width="15" height="23" rx="3" stroke="none" />
                                                                <rect x="0.75" y="0.75" width="13.5" height="21.5" rx="2.25" fill="none" />
                                                            </g>
                                                            <g id="Group_38341" data-name="Group 38341" transform="translate(1708.245 -393.755)">
                                                                <rect id="Rectangle_25572" data-name="Rectangle 25572" width="16" height="16" transform="translate(19.755 526.755)" fill="none" />
                                                                <path id="Path_99646" data-name="Path 99646" d="M7.234,13.953a6.622,6.622,0,0,0,.774-.045.376.376,0,0,0,.331-.393,1.49,1.49,0,0,1,.91-1.461,1.393,1.393,0,0,1,.542-.1,1.546,1.546,0,0,1,1.134.482.377.377,0,0,0,.512.041A6.708,6.708,0,0,0,12.5,11.4a.376.376,0,0,0-.04-.507A1.487,1.487,0,0,1,13.436,8.3h.034a.446.446,0,0,0,.441-.331,6.727,6.727,0,0,0,0-1.528.362.362,0,0,0-.375-.33H13.52a1.555,1.555,0,0,1-1.464-.91,1.491,1.491,0,0,1,.375-1.678.375.375,0,0,0,.04-.511,6.678,6.678,0,0,0-1.087-1.075.37.37,0,0,0-.23-.08.376.376,0,0,0-.277.125,1.549,1.549,0,0,1-1.134.48,1.381,1.381,0,0,1-.531-.1A1.5,1.5,0,0,1,8.29.928.375.375,0,0,0,7.957.543a6.741,6.741,0,0,0-1.513,0,.376.376,0,0,0-.33.394A1.494,1.494,0,0,1,5.205,2.4a1.418,1.418,0,0,1-.544.105,1.544,1.544,0,0,1-1.134-.482.376.376,0,0,0-.512-.042A6.707,6.707,0,0,0,1.952,3.054a.376.376,0,0,0,.04.507A1.486,1.486,0,0,1,.976,6.146a.439.439,0,0,0-.433.33,6.75,6.75,0,0,0,0,1.53.377.377,0,0,0,.367.331H1.02A1.507,1.507,0,0,1,2.4,9.25a1.491,1.491,0,0,1-.376,1.678.376.376,0,0,0-.04.512,6.73,6.73,0,0,0,1.086,1.074.376.376,0,0,0,.508-.042,1.55,1.55,0,0,1,1.134-.48,1.406,1.406,0,0,1,.532.1,1.494,1.494,0,0,1,.921,1.435.375.375,0,0,0,.333.384A6.794,6.794,0,0,0,7.234,13.953Z" transform="translate(20.669 528.038)" fill="#f8fafe" stroke="#7782a1" stroke-linejoin="round" stroke-width="1.5" />
                                                                <path id="Path_99647" data-name="Path 99647" d="M8.082,5.846A2.264,2.264,0,1,1,5.818,8.111,2.265,2.265,0,0,1,8.082,5.846Z" transform="translate(19.826 527.202)" fill="#f8fafe" stroke="#7782a1" stroke-linejoin="round" stroke-width="1.5" />
                                                            </g>
                                                            <path id="Rectangle_31503" data-name="Rectangle 31503" d="M0,0H5A0,0,0,0,1,5,0V1A1,1,0,0,1,4,2H1A1,1,0,0,1,0,1V0A0,0,0,0,1,0,0Z" transform="translate(1727 126)" fill="#7782a1" />
                                                        </g>
                                                    </svg>
                                                    <span className="build-settings-text">Build Settings</span>
                                </Link>
                            </div>
                    </div>
                    }
                </div>
            </div>

            <div className="update-mob-upgadehead">
                <p>Users</p>
                <div className='update-mob-upgadehead-right'>
                    <PremiumTag/>

                {/* <div className="consent-setting-clicked" style={{marginRight: "0px",marginLeft:"20px" ,cursor:"default"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                       <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                    <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                    <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="#7782a1" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                  </g>
                </svg>
                            </div> */}
             {(code == 200 || skipButtonActive) && <div className="consent-setting user-consent-setting" style={{marginTop: "-10px"}}>
                                <Link to={"/app/notifications/build-settings"} className="pointcursor" style={{border: "unset", margin: "0px"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24">
                                                        <g id="Group_43299" data-name="Group 43299" transform="translate(-1718 -125)">
                                                            <rect id="Rectangle_32508" data-name="Rectangle 32508" width="26" height="24" transform="translate(1718 125)" fill="none" />
                                                            <g id="Rectangle_31502" data-name="Rectangle 31502" transform="translate(1722 125)" fill="#f8fafe" stroke="#7782a1" stroke-width="1.5">
                                                                <rect width="15" height="23" rx="3" stroke="none" />
                                                                <rect x="0.75" y="0.75" width="13.5" height="21.5" rx="2.25" fill="none" />
                                                            </g>
                                                            <g id="Group_38341" data-name="Group 38341" transform="translate(1708.245 -393.755)">
                                                                <rect id="Rectangle_25572" data-name="Rectangle 25572" width="16" height="16" transform="translate(19.755 526.755)" fill="none" />
                                                                <path id="Path_99646" data-name="Path 99646" d="M7.234,13.953a6.622,6.622,0,0,0,.774-.045.376.376,0,0,0,.331-.393,1.49,1.49,0,0,1,.91-1.461,1.393,1.393,0,0,1,.542-.1,1.546,1.546,0,0,1,1.134.482.377.377,0,0,0,.512.041A6.708,6.708,0,0,0,12.5,11.4a.376.376,0,0,0-.04-.507A1.487,1.487,0,0,1,13.436,8.3h.034a.446.446,0,0,0,.441-.331,6.727,6.727,0,0,0,0-1.528.362.362,0,0,0-.375-.33H13.52a1.555,1.555,0,0,1-1.464-.91,1.491,1.491,0,0,1,.375-1.678.375.375,0,0,0,.04-.511,6.678,6.678,0,0,0-1.087-1.075.37.37,0,0,0-.23-.08.376.376,0,0,0-.277.125,1.549,1.549,0,0,1-1.134.48,1.381,1.381,0,0,1-.531-.1A1.5,1.5,0,0,1,8.29.928.375.375,0,0,0,7.957.543a6.741,6.741,0,0,0-1.513,0,.376.376,0,0,0-.33.394A1.494,1.494,0,0,1,5.205,2.4a1.418,1.418,0,0,1-.544.105,1.544,1.544,0,0,1-1.134-.482.376.376,0,0,0-.512-.042A6.707,6.707,0,0,0,1.952,3.054a.376.376,0,0,0,.04.507A1.486,1.486,0,0,1,.976,6.146a.439.439,0,0,0-.433.33,6.75,6.75,0,0,0,0,1.53.377.377,0,0,0,.367.331H1.02A1.507,1.507,0,0,1,2.4,9.25a1.491,1.491,0,0,1-.376,1.678.376.376,0,0,0-.04.512,6.73,6.73,0,0,0,1.086,1.074.376.376,0,0,0,.508-.042,1.55,1.55,0,0,1,1.134-.48,1.406,1.406,0,0,1,.532.1,1.494,1.494,0,0,1,.921,1.435.375.375,0,0,0,.333.384A6.794,6.794,0,0,0,7.234,13.953Z" transform="translate(20.669 528.038)" fill="#f8fafe" stroke="#7782a1" stroke-linejoin="round" stroke-width="1.5" />
                                                                <path id="Path_99647" data-name="Path 99647" d="M8.082,5.846A2.264,2.264,0,1,1,5.818,8.111,2.265,2.265,0,0,1,8.082,5.846Z" transform="translate(19.826 527.202)" fill="#f8fafe" stroke="#7782a1" stroke-linejoin="round" stroke-width="1.5" />
                                                            </g>
                                                            <path id="Rectangle_31503" data-name="Rectangle 31503" d="M0,0H5A0,0,0,0,1,5,0V1A1,1,0,0,1,4,2H1A1,1,0,0,1,0,1V0A0,0,0,0,1,0,0Z" transform="translate(1727 126)" fill="#7782a1" />
                                                        </g>
                                                    </svg>
                                </Link>
                            </div>}  
                            </div>
            </div>

            {
                      (localStorage.getItem("agencyid") && localStorage.getItem("agencyid") != 1) &&
                      window.matchMedia("(max-width: 1200px)").matches && code !== 200 && spinner === false &&
                    <div style={{marginBottom: "15px"}} className="upgrade-feature">
                      <h4 style={{textAlign: "unset"}} className="upgrade-unavailable">This feature is currently unavailable</h4>
                      <p style={{textAlign: "unset"}} className="upgrade-support">Please contact Support for assistance.</p>
                    </div>
                }   

            {/* { code === 200 &&
            <div className="pushnotification-mobfilterdiv">
                <div className="pushnotification-Search" style={{width: "100%", marginTop:"-6px"}}>
                    <form>
                        <button>
                            <svg id="Group_3669" data-name="Group 3669" xmlns="http://www.w3.org/2000/svg" width="16.53" height="16.53" viewBox="0 0 16.53 16.53">
                                <g id="Ellipse_246" data-name="Ellipse 246" transform="translate(14.675) rotate(90)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                                    <ellipse cx="7.337" cy="7.337" rx="7.337" ry="7.337" stroke="none" />
                                    <ellipse cx="7.337" cy="7.337" rx="6.587" ry="6.587" fill="none" />
                                </g>
                                <path id="Path_6064" data-name="Path 6064" d="M0,5.347V0" transform="translate(16 16) rotate(135)" fill="none" stroke="#bcc1ce" stroke-width="1.5" />
                            </svg>
                        </button>
                        <input placeholder="Search"></input>
                    </form>

                    
                    <svg style={{position: "relative", cursor:"pointer",left:"-15px", top:"14px"}} xmlns="http://www.w3.org/2000/svg" width="11.06" height="11.06" viewBox="0 0 11.06 11.06" onClick={(e) => this.context.state.handlecancelsearch(e)}>
                    <path id="Union_2970" data-name="Union 2970" d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                  </svg>
                </div>
            </div>
            } */}

            <div className='notification-main-page'>

                {spinner ?
                <div className='usermob-spinner'>
                    <NewSpinner />
                </div>
                :
                    ( code !== 200 && !skipButtonActive ?
                        <div className='notification-userupgate-page'>
                            <div className="pushnot-upgradetext">
                                <div className="">
                                    <h3 className="">Users</h3>
                                    <p> {" "}Unlock the power of real-time user data.{" "} </p>
                                    <ul>
                                        <li>Monitor user engagement.</li>
                                        <li>Utilize user data to segment audiences.</li>
                                        <li>Gain insights into user demographics.</li>
                                        <li>Identify subscribers for targeted messaging.</li>
                                        <li>Leverage data to enhance user experience. </li>
                                    </ul>
                                    {/*<a href="/app/pricing" className='notification_upgradebtn'>{
                                ( reseller_package_id || (plan_code && plan_code.includes("appsumo") ))?"Buy addon":"Upgrade"
                              }</a>*/}
                              <div className="upgrade-skip-notification">
                                    {(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") != 1) ?  
                                        null
                                    :
                                        <>
                                        { localStorage.getItem("workspaceId") !== null &&
                                            localStorage.getItem("workspaceId") !== "" &&
                                            localStorage.getItem("workspaceId") !== undefined &&
                                            localStorage.getItem("workspaceId") !== "undefined" ?
                                            null
                                        :
                                            <button onClick={RedirectPlanPage} className='notification_upgradebtn'>{
                                                ( reseller_package_id || (plan_code && plan_code.includes("appsumo") ))?"Buy addon":"Upgrade"
                                              }</button>
                                        }
                                        </>
                                    }
                                               <div className="skip-btn-head" onClick={handleSkipClick}>
						                              <SkipBtn/>
						                        </div>
                                                </div>
                                </div>
                            </div>

                            <div className="pushnot-upgradeimg user-mobupgradeimg">
                                <img src={ require("../../assets/images/dashboard/userupgrade.png").default } alt="addmobpre" className="img-fluid " />
                            </div>
                        </div>
                    :
                        <div className='notificationusertable'>
                            { allusers.length <= 0 ?
                                <div className='nodatintable'>
                                    <img src={ require("../../assets/images/dashboard/usertablenodata.png").default } alt="addmobpre" className="img-fluid " />
                                    <p>Nothing here at the moment. Come back later?</p>
                                </div>
                            :
                                <div>
                                    <div className='usertableheader'>
                                        <p>Subscribed</p>
                                        <p className='user-lastactivedate'>Last active</p>
                                        <p>Usage duration</p>
                                        <p>Sessions</p>
                                        <p className='user-lastactivedate'>First session</p>
                                        <p>Device</p>
                                        {/* <p>Country</p> */}
                                        <p className='user-languagecode'>Language</p>
                                    </div>

                                    { 
                                        allusers.map((userlist, index) =>{
                                        return (
                                        <div className='usertabledata'>
                                            <p className='first-usertabledata'>
                                                { userlist.invalid_identifier ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="19.797" height="19.797" viewBox="0 0 19.797 19.797">
                                                        <path id="Union_2896" data-name="Union 2896" d="M16588-5147v-10h3v7h15v3Z" transform="translate(-8082.938 15388.764) rotate(-45)" fill="#e3e6f1" />
                                                    </svg>
                                                :
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="19.797" height="19.797" viewBox="0 0 19.797 19.797">
                                                        <path id="Union_2895" data-name="Union 2895" d="M16588-5147v-10h3v7h15v3Z" transform="translate(-8082.938 15388.764) rotate(-45)" fill="#7782a1" />
                                                    </svg>
                                                }
                                            </p>
                                            <div className='mob-user-lastactive'>
                                                <div>
                                                    <h2>Last active</h2>
                                                    <p className=''>{moment.unix(userlist.last_active).format("Do MMM YYYY, hh:mm a")}</p>
                                                </div>

                                                <svg xmlns="http://www.w3.org/2000/svg" width="19.797" height="19.797" viewBox="0 0 19.797 19.797">
                                                    <path id="Union_2895" data-name="Union 2895" d="M16588-5147v-10h3v7h15v3Z" transform="translate(-8082.938 15388.764) rotate(-45)" fill="#7782a1" />
                                                </svg>
                                            </div>

                                            <p className='user-lastactivedate'>{moment.unix(userlist.last_active).format("Do MMM YYYY, hh:mm a")}</p>
                                            <div className='mob-user-duration'>
                                                <h2>Usage duration</h2>
                                                <p>{ userlist.playtime }</p>
                                            </div>

                                            <p className='web-users-duration'>{ userlist.playtime }</p>

                                            <div className='mob-user-duration'>
                                                <h2>Sessions</h2>
                                                <p>{ userlist.session_count }</p>
                                            </div>

                                            <p className='web-users-duration'>{ userlist.session_count }</p>

                                            <div className='mob-user-duration'>
                                                <h2>First session</h2>
                                                <p>{moment.unix(userlist.created_at).format("Do MMM YYYY, hh:mm a")}</p>
                                            </div>

                                            <p className='user-lastactivedate'>{moment.unix(userlist.created_at).format("Do MMM YYYY, hh:mm a")}</p>

                                            <div className='mob-user-duration mob-user-device'>
                                                <h2>Device</h2>
                                                <p>
                                                    { userlist.device_type === 1 ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.591" height="22" viewBox="0 0 18.591 22">
                                                        <path id="Path_7126" data-name="Path 7126" d="M1.376,15.116A1.3,1.3,0,0,1,0,13.912V8.761A1.3,1.3,0,0,1,1.376,7.556,1.3,1.3,0,0,1,2.753,8.761v5.151A1.3,1.3,0,0,1,1.376,15.116Zm10.411,5.037,1.2,1.2a.379.379,0,0,1-.537.536l-1.48-1.478a7.024,7.024,0,0,1-3.32,0L6.175,21.889a.379.379,0,1,1-.537-.536l1.2-1.193A4.85,4.85,0,0,1,3.45,15.8c0-.023,11.706,0,11.706,0A4.843,4.843,0,0,1,11.788,20.153ZM7.582,17.178a.687.687,0,1,0,.689.687A.687.687,0,0,0,7.582,17.178Zm3.444,0a.687.687,0,1,0,.689.687A.687.687,0,0,0,11.025,17.178Zm6.19-2.062a1.3,1.3,0,0,1-1.377-1.2V8.761a1.389,1.389,0,0,1,2.753,0v5.151A1.3,1.3,0,0,1,17.216,15.116ZM3.45,6.875a2.063,2.063,0,0,1,2.059-2.06V1.205A1.3,1.3,0,0,1,6.886,0,1.3,1.3,0,0,1,8.261,1.205V4.814H10.33V1.205A1.3,1.3,0,0,1,11.706,0a1.3,1.3,0,0,1,1.376,1.205V4.814h.009a2.063,2.063,0,0,1,2.066,2.06v8.243H3.451V6.875Z" transform="translate(18.591 22) rotate(180)" fill="#3ddb86" />
                                                    </svg>
                                                    : userlist.device_type === 11 ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.417" height="22" viewBox="0 0 18.417 22">
                                                        <g id="apple-logo" transform="translate(0)">
                                                            <g id="Group_1428" data-name="Group 1428" transform="translate(0 0)">
                                                                <path id="Path_668" data-name="Path 668" d="M55.02,116.775a5.034,5.034,0,0,1-.912-2.948,4.711,4.711,0,0,1,.859-2.737,8.568,8.568,0,0,1,1.507-1.56,6.818,6.818,0,0,0-1.375-1.322,4.834,4.834,0,0,0-2.816-.859A7.461,7.461,0,0,0,50,107.8a6.621,6.621,0,0,1-1.891.45,8.505,8.505,0,0,1-1.825-.4,8.8,8.8,0,0,0-2.34-.4,4.564,4.564,0,0,0-3.755,1.9,7.844,7.844,0,0,0-1.494,4.945,13.554,13.554,0,0,0,1.944,6.65q1.97,3.424,3.979,3.424a4.779,4.779,0,0,0,1.745-.45,5.1,5.1,0,0,1,1.878-.436,5.846,5.846,0,0,1,2,.423,6.112,6.112,0,0,0,1.851.423q1.692,0,3.4-2.591a12.762,12.762,0,0,0,1.626-3.305A4.022,4.022,0,0,1,55.02,116.775Z" transform="translate(-38.692 -102.378)" fill="#666" />
                                                                <path id="Path_669" data-name="Path 669" d="M236.488,4.178a5.427,5.427,0,0,0,1.23-1.824,5.044,5.044,0,0,0,.383-1.8c0-.035,0-.079-.007-.132s-.006-.1-.006-.132a1.28,1.28,0,0,1-.033-.145A1.224,1.224,0,0,0,238.021,0a5.269,5.269,0,0,0-3.305,1.957,6.03,6.03,0,0,0-1.031,3.4,7.388,7.388,0,0,0,1.375-.225A3.747,3.747,0,0,0,236.488,4.178Z" transform="translate(-224.655 0)" fill="#666" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                    : <></>
                                                    }
                                                </p>
                                            </div>

                                            <p className='web-users-duration'>
                                                { userlist.device_type === 1 ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.591" height="22" viewBox="0 0 18.591 22">
                                                        <path id="Path_7126" data-name="Path 7126" d="M1.376,15.116A1.3,1.3,0,0,1,0,13.912V8.761A1.3,1.3,0,0,1,1.376,7.556,1.3,1.3,0,0,1,2.753,8.761v5.151A1.3,1.3,0,0,1,1.376,15.116Zm10.411,5.037,1.2,1.2a.379.379,0,0,1-.537.536l-1.48-1.478a7.024,7.024,0,0,1-3.32,0L6.175,21.889a.379.379,0,1,1-.537-.536l1.2-1.193A4.85,4.85,0,0,1,3.45,15.8c0-.023,11.706,0,11.706,0A4.843,4.843,0,0,1,11.788,20.153ZM7.582,17.178a.687.687,0,1,0,.689.687A.687.687,0,0,0,7.582,17.178Zm3.444,0a.687.687,0,1,0,.689.687A.687.687,0,0,0,11.025,17.178Zm6.19-2.062a1.3,1.3,0,0,1-1.377-1.2V8.761a1.389,1.389,0,0,1,2.753,0v5.151A1.3,1.3,0,0,1,17.216,15.116ZM3.45,6.875a2.063,2.063,0,0,1,2.059-2.06V1.205A1.3,1.3,0,0,1,6.886,0,1.3,1.3,0,0,1,8.261,1.205V4.814H10.33V1.205A1.3,1.3,0,0,1,11.706,0a1.3,1.3,0,0,1,1.376,1.205V4.814h.009a2.063,2.063,0,0,1,2.066,2.06v8.243H3.451V6.875Z" transform="translate(18.591 22) rotate(180)" fill="#3ddb86" />
                                                    </svg>
                                                : userlist.device_type === 11 ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.417" height="22" viewBox="0 0 18.417 22">
                                                        <g id="apple-logo" transform="translate(0)">
                                                            <g id="Group_1428" data-name="Group 1428" transform="translate(0 0)">
                                                                <path id="Path_668" data-name="Path 668" d="M55.02,116.775a5.034,5.034,0,0,1-.912-2.948,4.711,4.711,0,0,1,.859-2.737,8.568,8.568,0,0,1,1.507-1.56,6.818,6.818,0,0,0-1.375-1.322,4.834,4.834,0,0,0-2.816-.859A7.461,7.461,0,0,0,50,107.8a6.621,6.621,0,0,1-1.891.45,8.505,8.505,0,0,1-1.825-.4,8.8,8.8,0,0,0-2.34-.4,4.564,4.564,0,0,0-3.755,1.9,7.844,7.844,0,0,0-1.494,4.945,13.554,13.554,0,0,0,1.944,6.65q1.97,3.424,3.979,3.424a4.779,4.779,0,0,0,1.745-.45,5.1,5.1,0,0,1,1.878-.436,5.846,5.846,0,0,1,2,.423,6.112,6.112,0,0,0,1.851.423q1.692,0,3.4-2.591a12.762,12.762,0,0,0,1.626-3.305A4.022,4.022,0,0,1,55.02,116.775Z" transform="translate(-38.692 -102.378)" fill="#666" />
                                                                <path id="Path_669" data-name="Path 669" d="M236.488,4.178a5.427,5.427,0,0,0,1.23-1.824,5.044,5.044,0,0,0,.383-1.8c0-.035,0-.079-.007-.132s-.006-.1-.006-.132a1.28,1.28,0,0,1-.033-.145A1.224,1.224,0,0,0,238.021,0a5.269,5.269,0,0,0-3.305,1.957,6.03,6.03,0,0,0-1.031,3.4,7.388,7.388,0,0,0,1.375-.225A3.747,3.747,0,0,0,236.488,4.178Z" transform="translate(-224.655 0)" fill="#666" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                : <></>
                                                }
                                            </p>

                                            {/* <div className='mob-user-duration mob-user-country'>
                                                <h2>Country</h2>
                                                <p>Us</p>
                                            </div> */}
                                            {/* <p className='web-users-duration'>US</p> */}
                                            <div className='mob-user-duration mob-user-device'>
                                                <h2>Language</h2>
                                                <p>{ userlist.language }</p>
                                            </div>
                                            <p className='user-languagecode web-users-duration'>{ userlist.language }</p>
                                        </div>
                                        )
                                        })
                                    }

                                    {/*<div className='usertabledata'>
                                        <p className='first-usertabledata'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="19.797" height="19.797" viewBox="0 0 19.797 19.797">
                                                <path id="Union_2896" data-name="Union 2896" d="M16588-5147v-10h3v7h15v3Z" transform="translate(-8082.938 15388.764) rotate(-45)" fill="#e3e6f1" />
                                            </svg>
                                        </p>

                                        <div className='mob-user-lastactive'>
                                            <div>
                                                <h2>Last Active</h2>
                                                <p className=''>5 Jul 2022, 16:35</p>
                                            </div>

                                            <svg xmlns="http://www.w3.org/2000/svg" width="19.797" height="19.797" viewBox="0 0 19.797 19.797">
                                                <path id="Union_2896" data-name="Union 2896" d="M16588-5147v-10h3v7h15v3Z" transform="translate(-8082.938 15388.764) rotate(-45)" fill="#e3e6f1" />
                                            </svg>
                                        </div>

                                        <p className='user-lastactivedate'>5 Jul 2022, 16:35</p>
                                        <div className='mob-user-duration'>
                                            <h2>Usage Duration</h2>
                                            <p>0</p>
                                        </div>

                                        <p className='web-users-duration'>0</p>
                                        <div className='mob-user-duration'>
                                            <h2>Sessions</h2>
                                            <p>1</p>
                                        </div>

                                        <p className='web-users-duration'>1</p>
                                        <div className='mob-user-duration'>
                                            <h2>First Session</h2>
                                            <p>1 Jul 2022, 16:35</p>
                                        </div>
                                        <p className='user-lastactivedate'>1 Jul 2022, 16:35</p>
                                        
                                        <div className='mob-user-duration'>
                                            <h2>Device</h2>
                                            <p>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18.417" height="22" viewBox="0 0 18.417 22">
                                                    <g id="apple-logo" transform="translate(0)">
                                                        <g id="Group_1428" data-name="Group 1428" transform="translate(0 0)">
                                                            <path id="Path_668" data-name="Path 668" d="M55.02,116.775a5.034,5.034,0,0,1-.912-2.948,4.711,4.711,0,0,1,.859-2.737,8.568,8.568,0,0,1,1.507-1.56,6.818,6.818,0,0,0-1.375-1.322,4.834,4.834,0,0,0-2.816-.859A7.461,7.461,0,0,0,50,107.8a6.621,6.621,0,0,1-1.891.45,8.505,8.505,0,0,1-1.825-.4,8.8,8.8,0,0,0-2.34-.4,4.564,4.564,0,0,0-3.755,1.9,7.844,7.844,0,0,0-1.494,4.945,13.554,13.554,0,0,0,1.944,6.65q1.97,3.424,3.979,3.424a4.779,4.779,0,0,0,1.745-.45,5.1,5.1,0,0,1,1.878-.436,5.846,5.846,0,0,1,2,.423,6.112,6.112,0,0,0,1.851.423q1.692,0,3.4-2.591a12.762,12.762,0,0,0,1.626-3.305A4.022,4.022,0,0,1,55.02,116.775Z" transform="translate(-38.692 -102.378)" fill="#666" />
                                                            <path id="Path_669" data-name="Path 669" d="M236.488,4.178a5.427,5.427,0,0,0,1.23-1.824,5.044,5.044,0,0,0,.383-1.8c0-.035,0-.079-.007-.132s-.006-.1-.006-.132a1.28,1.28,0,0,1-.033-.145A1.224,1.224,0,0,0,238.021,0a5.269,5.269,0,0,0-3.305,1.957,6.03,6.03,0,0,0-1.031,3.4,7.388,7.388,0,0,0,1.375-.225A3.747,3.747,0,0,0,236.488,4.178Z" transform="translate(-224.655 0)" fill="#666" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </p>
                                        </div>

                                        <p className='web-users-duration'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18.417" height="22" viewBox="0 0 18.417 22">
                                                <g id="apple-logo" transform="translate(0)">
                                                    <g id="Group_1428" data-name="Group 1428" transform="translate(0 0)">
                                                        <path id="Path_668" data-name="Path 668" d="M55.02,116.775a5.034,5.034,0,0,1-.912-2.948,4.711,4.711,0,0,1,.859-2.737,8.568,8.568,0,0,1,1.507-1.56,6.818,6.818,0,0,0-1.375-1.322,4.834,4.834,0,0,0-2.816-.859A7.461,7.461,0,0,0,50,107.8a6.621,6.621,0,0,1-1.891.45,8.505,8.505,0,0,1-1.825-.4,8.8,8.8,0,0,0-2.34-.4,4.564,4.564,0,0,0-3.755,1.9,7.844,7.844,0,0,0-1.494,4.945,13.554,13.554,0,0,0,1.944,6.65q1.97,3.424,3.979,3.424a4.779,4.779,0,0,0,1.745-.45,5.1,5.1,0,0,1,1.878-.436,5.846,5.846,0,0,1,2,.423,6.112,6.112,0,0,0,1.851.423q1.692,0,3.4-2.591a12.762,12.762,0,0,0,1.626-3.305A4.022,4.022,0,0,1,55.02,116.775Z" transform="translate(-38.692 -102.378)" fill="#666" />
                                                        <path id="Path_669" data-name="Path 669" d="M236.488,4.178a5.427,5.427,0,0,0,1.23-1.824,5.044,5.044,0,0,0,.383-1.8c0-.035,0-.079-.007-.132s-.006-.1-.006-.132a1.28,1.28,0,0,1-.033-.145A1.224,1.224,0,0,0,238.021,0a5.269,5.269,0,0,0-3.305,1.957,6.03,6.03,0,0,0-1.031,3.4,7.388,7.388,0,0,0,1.375-.225A3.747,3.747,0,0,0,236.488,4.178Z" transform="translate(-224.655 0)" fill="#666" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </p>

                                        <div className='mob-user-duration'>
                                            <h2>Country</h2>
                                            <p>Us</p>
                                        </div>

                                        <p className='web-users-duration'>US</p>
                                        <div className='mob-user-duration'>
                                            <h2>Language Code</h2>
                                            <p>en</p>
                                        </div>
                                        <p className='user-languagecode web-users-duration'>en</p>
                                    </div>*/}
                                </div>
                            }
                        </div>
                    )
                }
            </div>

        </div>
        <Leftpanel  handleRightPanelExpand={handleRightPanelExpand} />
    </section>


    </>

  )
}

export default Users;
