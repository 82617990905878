import React, { Suspense, useEffect, useMemo, useState } from "react";
import "../../../assets/css/Dashboard.css";
import Header from "../../Header";
import Leftpanel from "../../Leftpanel";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import moment from "moment";
import { InnerFoot } from "../../Foot";
import Slider from "../../Reusable/ImageSlider";
import "../../../assets/css/newdashboard.css";
// import {toPng} from "html-to-image";
import styles from "./newHomescreen.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import html2canvas from "html2canvas";
import S3 from "react-aws-s3";
import emailValidator from "email-validator";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import { FormGroup, Label, Input } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import imagebackground from "../../../assets/images/dashboard/homescreen-banner-default.png";
import uploadimagebackground from "../../../assets/images/dashboard/cms/uploadbackground.png";
import accordianicon from "../../../assets/images/dashboard/cms/accordian-icon.png";
import accordianopen from "../../../assets/images/dashboard/cms/accordian-open.png";
import catimg from "../../../assets/images/dashboard/appicon.png";
import HMSpreview from "./HMSpreview";
import HMSmainBanner from "./HMSmainBanner";
import HMScategories from "./HMSproductcategories";
import { Dashboardpost } from "../../../Api/Dashboard/DashboardApi";
import {
  finalImgUploadOnS3,
  homescreenGetAPI,
  homescreenSaveAPI,
} from "../../../Api/NewHomescreen/NewHomescreenApi";
import { errortoast, sucesstoast } from "../../Toaster";
import HMSproductcategories from "./HMSproductcategories";
import HMSfeaturedProducts from "./HMSfeaturedProducts";
import HMSsaleProducts from "./HMSsaleProducts";
import HMSnewProducts from "./HMSnewProducts";
import HMSrecentlyViewedProducts from "./HMSrecentlyViewedProducts";
import HMScart from "./HMScart";
import HMSpopularBlog from "./HMSpopularBlog";
import HMSrecentBlog from "./HMSrecentBlog";
import HMSblogBanner from "./HMSblogBanner";
import HMSblogCategories from "./HMSblogCategories";
import HMSWebView from "./HMSWebView";
import HMSpages from "./HMSpages";
import axios from "axios";
import HeadingBlock from "../../CmsBuilder/HeadingBlock";
import ParagraphBlock from "../../CmsBuilder/ParagraphBlock";
import SpacerBlock from "../../CmsBuilder/SpacerBlock";
import SeparatorBlock from "../../CmsBuilder/SeparatorBlock";
import ButtonBlock from "../../CmsBuilder/ButtonBlock";
import GalleryBlock from "../../CmsBuilder/GalleryBlock";
import CodeBlock from "../../CmsBuilder/CodeBlock";
import ImageBlock from "../../CmsBuilder/ImageBlock";

const Newhomescreen = () => {
  const [isAddBlock, setIsAddBlock] = useState(false);

  const [openRowIndex, setOpenRowIndex] = useState(null);
  const [blockType, setBlockType] = useState("");
  const [isRowOpen, setIsRowOpen] = useState([]);

  const [spinner, setSpinner] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [isLoader, setIsLoader] = useState("d-none");
  const [submitval, setSubmitval] = useState("SAVE");

  const [isHomescreenReload, setIsHomescreenReload] = useState(false);
  const [homescreenData, setHomescreenData] = useState([]);

  const [openBlockIndx, setOpenBlockIndx] = useState(null);
  const [value, setValue] = useState("");
  const [allPage, setAllPage] = useState([]);
  const [galleryToggle, setgalleryToggle] = useState("6");
  const [galleryTab, setgalleryTab] = useState("6");
  const [bottomMenuData, setBottomMenuData] = useState(null);
  const [colorThemeData, setColorThemeData] = useState(null);
  const [showMobilePreview, setShowMobilePreview] = useState(false);
  const [draggedElemenIndx, setDraggedElemenIndx] = useState(null);
  const [defaultDataForComaprision, setDefaultDataForComaprision] = useState({
    original_image: "",
    image: "",
    thumb_image: "",
    original_path: "",
    path: "",
    hyperlink_option: "url",
    hyperlink_value: "www.test.com",

    style: {
      color:
        "linear-gradient(160deg,   rgba(51,199,255,0.6) 0%, rgba(71,95,254,0.6) 50%, rgba(107,72,254,0.6) 100%)",
      text: "Text 1",
      text_color: "#FFFFFF",
      font_size: "20px",
      font_family: "Poppins-SemiBold",
      text_align: "left",
      vertical_text_position: "p-center",
      horizontal_text_position: "p-r-right",
      text_style: "",
      is_bold: 1,
      is_italic: 1,
      is_underline: 0,
    },
  });
  const [presetColors, setPresetColors] = useState("");
  const [appRightPanelExpand, setAppRightPanelExpand] = useState( localStorage.getItem("appSideBarCollapse") === true ||
  localStorage.getItem("appSideBarCollapse") === "true"
    ? true
    : false);
  useEffect(() => {
    document.title = `Homescreen`;
    loadData();

    homescreenGetAPI(
      setSpinner,
      setHomescreenData,
      setBottomMenuData,
      setColorThemeData
    );
  }, []);

  const loadData = (from, dragDstIndx, val) => {
    let isRowOpen = [];

    for (let i = 0; i < homescreenData.length; i++) {
      if (from !== undefined && i === dragDstIndx) {
        isRowOpen.push(val);
      } else {
        isRowOpen.push(false);
      }
    }

    setIsRowOpen(isRowOpen);
  };

  const handleBlockType = (e) => {
    setBlockType(e.target.value);
  };

  const galleryToggleUpdate = (gtab) => {
    if (galleryTab !== gtab) {
      setgalleryTab(gtab);
    }
  };

  const handleMobilePreviewBtn=()=>{
    setShowMobilePreview(!showMobilePreview)
  }

  const handleBlockPopUp = (e, checker) => {
    if (checker === "add") {
      let newArr = [...homescreenData];

      let obj = {};
      switch (blockType) {
        case "main_banner":
          obj = {
            position: homescreenData.length,
            item_type: blockType,

            enable: 1,

            homescreen_selected_image_index: 0,
            homescreen_prev_image_index: 0,
            value: {
              main_banner: [
                {
                  original_image: "",
                  image: "",
                  thumb_image: "",
                  original_path: "",
                  path: "",
                  hyperlink_option: "url",
                  hyperlink_value: "www.test.com",
                  hyperlink_alias: "",
                  is_data_change: false,

                  style: {
                    color:
                      "linear-gradient(160deg,   rgba(51,199,255,0.6) 0%, rgba(71,95,254,0.6) 50%, rgba(107,72,254,0.6) 100%)",
                    text: "Text 1",
                    text_color: "#FFFFFF",
                    font_size: "20px",
                    font_family: "Poppins-SemiBold",
                    text_align: "center",
                    vertical_text_position: "center",
                    horizontal_text_position: "center",
                    text_style: "",
                    is_bold: 1,
                    is_italic: 1,
                    is_underline: 0,
                  },
                },
              ],
            },
            style: {
              layout: {
                name: "",
                text_alignment: "", // left/right/center
                text_position: "", //outside_top/bottom/right/left  ||  inside_top/bottom/right/left
                image_aspect_ratio: "4:3",
              },
            },
          };
          break;
        case "blog_banner":
          obj = {
            position: homescreenData.length,

            item_type: blockType,

            enable: 1,

            value: {},
            style: {
              layout: {
                name: "",
                text_alignment: "left", // left/right/center
                text_position: "outside_bottom", //outside_top/bottom/right/left  ||  inside_top/bottom/right/left
                image_aspect_ratio: "4:3",
              },
              column: 2,
            },
          };
          break;
        case "blog_categories":
          obj = {
            position: homescreenData.length,
            apply_to_all_section_bool: 0,
            item_type: blockType,

            enable: 1,

            value: {
              sorting_order: "alphabetical",
              selection_array: [],
              label: "",
            },
            style: {
              layout: {
                name: "category_n*2",
                text_alignment: "center", // left/right/center
                text_position: "inside_center", //outside_top/bottom/right/left  ||  inside_top/bottom/right/left
                image_aspect_ratio: "4:3",
              },
              column: 2,
              row: 2,
              shape: "sharp_corner",
              show_image: 1,

              show_heading: 1,
              heading_color: "#050000",
              heading_icon_color: "#ffffff",
              heading_icon_background_color: "#c0c0c0",
              section_background_color: "#ffffff",
              background_color:
                "linear-gradient(160deg,   rgba(51,199,255,0.6) 0%, rgba(71,95,254,0.6) 50%, rgba(107,72,254,0.6) 100%)",
              text_color: "#050000",
            },
          };
          break;
        case "product_categories":
          obj = {
            position: homescreenData.length,
            apply_to_all_section_bool: 0,
            item_type: blockType,

            enable: 1,

            value: {
              sorting_order: "alphabetical",
              selection_array: [],
              label: "",
            },
            style: {
              layout: {
                name: "category_n*2",
                text_alignment: "center", // left/right/center
                text_position: "outside_bottom", //outside_top/bottom/right/left  ||  inside_top/bottom/right/left
                image_aspect_ratio: "4:3",
              },
              column: 2,
              row: 2,
              shape: "sharp_corner",
              show_image: 1,

              show_heading: 1,
              heading_color: "#050000",
              heading_icon_color: "#ffffff",
              heading_icon_background_color: "#c0c0c0",
              section_background_color: "#ffffff",
              background_color:
                "linear-gradient(160deg,   rgba(51,199,255,0.6) 0%, rgba(71,95,254,0.6) 50%, rgba(107,72,254,0.6) 100%)",
              text_color: "#050000",
            },
          };
          break;
        case "featured_products":
          obj = {
            position: homescreenData.length,
            apply_to_all_section_bool: 0,
            item_type: blockType,

            enable: 1,

            value: { label: "" },
            style: {
              layout: {
                name: "product_n*2",
                text_alignment: "left", // left/right/center
                text_position: "outside_bottom", //outside_top/bottom/right/left  ||  inside_top/bottom/right/left
                image_aspect_ratio: "4:3",
              },
              column: 2,
              row: 2,
              shape: "sharp_corner",

              show_heading: 1,

              // setting keys
              heading_color: "#050000",
              heading_icon_color: "#ffffff",
              heading_icon_background_color: "#c0c0c0",
              section_background_color: "#ffffff",
              // setting keys
            },
          };
          break;
        case "sale_products":
          obj = {
            position: homescreenData.length,
            apply_to_all_section_bool: 0,
            item_type: blockType,

            enable: 1,

            value: { label: "" },
            style: {
              layout: {
                name: "product_n*2",
                text_alignment: "left", // left/right/center
                text_position: "outside_bottom", //outside_top/bottom/right/left  ||  inside_top/bottom/right/left
                image_aspect_ratio: "4:3",
              },
              column: 2,
              row: 2,
              shape: "sharp_corner",

              show_heading: 1,

              // setting keys
              heading_color: "#050000",
              heading_icon_color: "#ffffff",
              heading_icon_background_color: "#c0c0c0",
              section_background_color: "#ffffff",
              // setting keys
            },
          };
          break;
        case "new_products":
          obj = {
            position: homescreenData.length,
            apply_to_all_section_bool: 0,
            item_type: blockType,

            enable: 1,

            value: { label: "" },
            style: {
              layout: {
                name: "product_n*2",
                text_alignment: "left", // left/right/center
                text_position: "outside_bottom", //outside_top/bottom/right/left  ||  inside_top/bottom/right/left
                image_aspect_ratio: "4:3",
              },
              column: 2,
              row: 2,
              shape: "sharp_corner",

              show_heading: 1,

              // setting keys
              heading_color: "#050000",
              heading_icon_color: "#ffffff",
              heading_icon_background_color: "#c0c0c0",
              section_background_color: "#ffffff",
              // setting keys
            },
          };
          break;
        case "recently_viewed_products":
          obj = {
            position: homescreenData.length,
            apply_to_all_section_bool: 0,
            item_type: blockType,

            enable: 1,

            value: { label: "" },
            style: {
              layout: {
                name: "product_n*2",
                text_alignment: "left", // left/right/center
                text_position: "outside_bottom", //outside_top/bottom/right/left  ||  inside_top/bottom/right/left
                image_aspect_ratio: "4:3",
              },
              column: 2,
              row: 2,
              shape: "sharp_corner",

              show_heading: 1,

              // setting keys
              heading_color: "#050000",
              heading_icon_color: "#ffffff",
              heading_icon_background_color: "#c0c0c0",
              section_background_color: "#ffffff",
              // setting keys
            },
          };
          break;
        case "cart":
          obj = {
            position: homescreenData.length,
            apply_to_all_section_bool: 0,
            item_type: blockType,

            enable: 1,

            value: { label: "" },
            style: {
              layout: {
                name: "product_n*2",
                text_alignment: "left", // left/right/center
                text_position: "outside_bottom", //outside_top/bottom/right/left  ||  inside_top/bottom/right/left
                image_aspect_ratio: "4:3",
              },
              column: 2,
              row: 2,
              shape: "sharp_corner",

              show_heading: 1,

              // setting keys
              heading_color: "#050000",
              heading_icon_color: "#ffffff",
              heading_icon_background_color: "#c0c0c0",
              section_background_color: "#ffffff",
              // setting keys
            },
          };
          break;
        case "sticky_blogs":
          obj = {
            position: homescreenData.length,
            apply_to_all_section_bool: 0,
            item_type: blockType,

            enable: 1,

            value: { label: "" },
            style: {
              layout: {
                name: "blog_n*2",
                text_alignment: "left",
                text_position: "outside_bottom",
                image_aspect_ratio: "1:1",
              },
              column: 2,
              row: 2,
              shape: "sharp_corner",

              show_heading: 1,

              // setting keys
              heading_color: "#050000",
              heading_icon_color: "#ffffff",
              heading_icon_background_color: "#c0c0c0",
              section_background_color: "#ffffff",
              // setting keys
            },
          };
          break;
        case "new_blogs":
          obj = {
            position: homescreenData.length,
            apply_to_all_section_bool: 0,
            item_type: blockType,

            enable: 1,

            value: { label: "" },
            style: {
              layout: {
                name: "blog_n*2",
                text_alignment: "left", // left/right/center
                text_position: "outside_bottom", //outside_top/bottom/right/left  ||  inside_top/bottom/right/left
                image_aspect_ratio: "4:3",
              },
              column: 2,
              row: 2,
              shape: "sharp_corner",

              show_heading: 1,

              // setting keys
              heading_color: "#050000",
              heading_icon_color: "#ffffff",
              heading_icon_background_color: "#c0c0c0",
              section_background_color: "#ffffff",
              // setting keys
            },
          };
          break;
        case "web_view":
          obj = {
            position: homescreenData.length,

            item_type: blockType,

            enable: 1,

            value: {
              web_view_url: "",
              // show_website_header: 1,
              // show_website_footer: 1,
              // hide_html_by_id: 1,
              // hide_html_by_class: 1,
            },
            style: {},
          };
          break;
        case "pages":
          obj = {
            position: homescreenData.length,

            item_type: blockType,

            enable: 1,

            value: { selection_array: [], label: "" },
            style: {
              row: 2,
              shape: "sharp_corner",

              show_heading: 1,
              text_color: "#050000",
              icon_color: "rgba(255,0,0,1)",
              // setting keys
              heading_color: "#050000",
              heading_icon_color: "#ffffff",
              heading_icon_background_color: "#c0c0c0",
              section_background_color: "#ffffff",
              // setting keys
            },
          };
          break;

        case "image":
          obj = {
            item_type: blockType,
            value: {
              // image: "",
              // source_file: "",
              // image_url: "",
            },
            portal_value: {
              type: 1,
              source_file: "",
              image_link: "",
              resize_image_link: "",
            },

            position: homescreenData.length,
            style: {
              aspect_ratio: "4:3",
            },
          };

          break;
        case "gallery":
          obj = {
            item_type: blockType,
            value: {},
            portal_value: [
              {
                source_file: null,
                image_link: "",
                resize_image_link: "",
                type: 1,
              },
              {
                source_file: null,
                image_link: "",
                resize_image_link: "",
                type: 1,
              },
              {
                source_file: null,
                image_link: "",
                resize_image_link: "",
                type: 1,
              },
              {
                source_file: null,
                image_link: "",
                resize_image_link: "",
                type: 1,
              },
            ],
            showOnlyArr: ["", "", "", ""],
            position: homescreenData.length,
            style: { row: 2, column: 2, aspect_ratio: "4:3" },
          };
          break;
        case "heading":
          obj = {
            item_type: blockType,
            value: { heading: { text: "" } },
            position: homescreenData.length,
            style: {
              text_color: "",
              text_align: "left",

              bold_text: null,
              italic_text: null,
              underline: null,
            },
          };
          break;
        case "separator":
          obj = {
            item_type: blockType,
            position: homescreenData.length,
            value: {
              separator: "",
            },
            style: {
              color: "#000",
              height: "1",
            },
            // value: [],
          };
          break;
        case "spacer":
          obj = {
            item_type: blockType,
            position: homescreenData.length,
            value: {
              spacer: "",
            },
            style: { height: "5" },
            // value: [],
          };
          break;
        case "button":
          obj = {
            item_type: blockType,
            value: {
              button: {
                text: "Button",
                link: "url",
                target: "",
                target_alias: "",
                // phone_code: "91",
              },
            },
            position: homescreenData.length,
            style: {
              text_color: "#fff",
              button_color: "#3064f9",

              text_align: "",
            },
          };
          break;
        case "code":
          obj = {
            item_type: blockType,
            value: {
              code: {
                text: "",
              },
            },
            position: homescreenData.length,
            style: null,
          };
          break;
        case "paragraph":
          obj = {
            item_type: blockType,
            value: { paragraph: { text: "" } },
            position: homescreenData.length,
            style: { text_align: "", text_color: "" },
          };
          break;
      }

      newArr.push(obj);

      setHomescreenData(newArr);
    }

    setIsAddBlock(!isAddBlock);
  };
  const handleIsRowOpen = (e, index,setShowHeadingNameInput) => {
    if(setShowHeadingNameInput){
     setShowHeadingNameInput(false)
    }
     setOpenBlockIndx(index);
 
 
     let newArr = [...isRowOpen];
     newArr = newArr.map((val, indx) => (index === indx ? !val : false));
 
     setIsRowOpen(newArr);
   };

  const handleStatus = (e, i) => {
    let newArr = [...homescreenData];
    newArr[i].enable = e.target.checked === true ? 1 : 0;
    setHomescreenData(newArr);
  };

  const handleDeleteItem = (e, index) => {
    let newArr = [...homescreenData];

    newArr.splice(index, 1);
    newArr.map((row, i) => {
      row.position = i;
    });

    setHomescreenData(newArr);
  };

  const handleDuplicateItem = (e, eachBlockOriginal, eachBlockCopied) => {
    let newArr = JSON.parse(JSON.stringify(homescreenData));

    const obj = JSON.parse(JSON.stringify(eachBlockOriginal));
    let copyObj;
    if (eachBlockCopied) {
      copyObj = JSON.parse(JSON.stringify(eachBlockCopied));
    } else {
      copyObj = JSON.parse(JSON.stringify(eachBlockOriginal));
    }

    newArr.push(obj);

    setHomescreenData(newArr);
  };

  //Rendering specific(selected) block
  const handleSwitch = (eachBlock, blockIndx, provided) => {
    switch (eachBlock.item_type) {
      case "main_banner":
        return (
          <HMSmainBanner
            homescreenData={homescreenData}
            setHomescreenData={setHomescreenData}
            blockIndx={blockIndx}
            handleStatus={handleStatus}
            handleIsRowOpen={handleIsRowOpen}
            isRowOpen={isRowOpen}
            setIsRowOpen={setIsRowOpen}
            handleDeleteItem={handleDeleteItem}
            eachBlock={eachBlock}
            handleDuplicateItem={handleDuplicateItem}
            uploadFinalCustomizeImgForApp={uploadFinalCustomizeImgForApp}
            defaultDataForComaprision={defaultDataForComaprision}
            presetColors={presetColors}
            provided={provided}
          />
        );
      case "blog_banner":
        return (
          <HMSblogBanner
            homescreenData={homescreenData}
            setHomescreenData={setHomescreenData}
            blockIndx={blockIndx}
            handleStatus={handleStatus}
            handleIsRowOpen={handleIsRowOpen}
            isRowOpen={isRowOpen}
            setIsRowOpen={setIsRowOpen}
            handleDeleteItem={handleDeleteItem}
            eachBlock={eachBlock}
            handleDuplicateItem={handleDuplicateItem}
            uploadFinalCustomizeImgForApp={uploadFinalCustomizeImgForApp}
            presetColors={presetColors}
            provided={provided}
          />
        );
      case "blog_categories":
        return (
          <HMSblogCategories
            homescreenData={homescreenData}
            setHomescreenData={setHomescreenData}
            blockIndx={blockIndx}
            handleStatus={handleStatus}
            handleIsRowOpen={handleIsRowOpen}
            isRowOpen={isRowOpen}
            setIsRowOpen={setIsRowOpen}
            handleDeleteItem={handleDeleteItem}
            eachBlock={eachBlock}
            handleDuplicateItem={handleDuplicateItem}
            presetColors={presetColors}
            provided={provided}
          />
        );
      case "product_categories":
        return (
          <HMSproductcategories
            homescreenData={homescreenData}
            setHomescreenData={setHomescreenData}
            blockIndx={blockIndx}
            handleStatus={handleStatus}
            handleIsRowOpen={handleIsRowOpen}
            isRowOpen={isRowOpen}
            setIsRowOpen={setIsRowOpen}
            handleDeleteItem={handleDeleteItem}
            eachBlock={eachBlock}
            handleDuplicateItem={handleDuplicateItem}
            presetColors={presetColors}
            provided={provided}
          />
        );
      case "featured_products":
        return (
          <HMSfeaturedProducts
            homescreenData={homescreenData}
            setHomescreenData={setHomescreenData}
            blockIndx={blockIndx}
            handleStatus={handleStatus}
            handleIsRowOpen={handleIsRowOpen}
            isRowOpen={isRowOpen}
            setIsRowOpen={setIsRowOpen}
            handleDeleteItem={handleDeleteItem}
            eachBlock={eachBlock}
            handleDuplicateItem={handleDuplicateItem}
            presetColors={presetColors}
            provided={provided}
          />
        );
      case "sale_products":
        return (
          <HMSsaleProducts
            homescreenData={homescreenData}
            setHomescreenData={setHomescreenData}
            blockIndx={blockIndx}
            handleStatus={handleStatus}
            handleIsRowOpen={handleIsRowOpen}
            isRowOpen={isRowOpen}
            setIsRowOpen={setIsRowOpen}
            handleDeleteItem={handleDeleteItem}
            eachBlock={eachBlock}
            handleDuplicateItem={handleDuplicateItem}
            presetColors={presetColors}
            provided={provided}
          />
        );
      case "new_products":
        return (
          <HMSnewProducts
            homescreenData={homescreenData}
            setHomescreenData={setHomescreenData}
            blockIndx={blockIndx}
            handleStatus={handleStatus}
            handleIsRowOpen={handleIsRowOpen}
            isRowOpen={isRowOpen}
            setIsRowOpen={setIsRowOpen}
            handleDeleteItem={handleDeleteItem}
            eachBlock={eachBlock}
            handleDuplicateItem={handleDuplicateItem}
            presetColors={presetColors}
            provided={provided}
          />
        );
      case "recently_viewed_products":
        return (
          <HMSrecentlyViewedProducts
            homescreenData={homescreenData}
            setHomescreenData={setHomescreenData}
            blockIndx={blockIndx}
            handleStatus={handleStatus}
            handleIsRowOpen={handleIsRowOpen}
            isRowOpen={isRowOpen}
            setIsRowOpen={setIsRowOpen}
            handleDeleteItem={handleDeleteItem}
            eachBlock={eachBlock}
            handleDuplicateItem={handleDuplicateItem}
            presetColors={presetColors}
            provided={provided}
          />
        );
      case "cart":
        return (
          <HMScart
            homescreenData={homescreenData}
            setHomescreenData={setHomescreenData}
            blockIndx={blockIndx}
            handleStatus={handleStatus}
            handleIsRowOpen={handleIsRowOpen}
            isRowOpen={isRowOpen}
            setIsRowOpen={setIsRowOpen}
            handleDeleteItem={handleDeleteItem}
            eachBlock={eachBlock}
            handleDuplicateItem={handleDuplicateItem}
            presetColors={presetColors}
            provided={provided}
          />
        );
      case "sticky_blogs":
        return (
          <HMSpopularBlog
            homescreenData={homescreenData}
            setHomescreenData={setHomescreenData}
            blockIndx={blockIndx}
            handleStatus={handleStatus}
            handleIsRowOpen={handleIsRowOpen}
            isRowOpen={isRowOpen}
            setIsRowOpen={setIsRowOpen}
            handleDeleteItem={handleDeleteItem}
            eachBlock={eachBlock}
            handleDuplicateItem={handleDuplicateItem}
            presetColors={presetColors}
            provided={provided}
          />
        );
      case "new_blogs":
        return (
          <HMSrecentBlog
            homescreenData={homescreenData}
            setHomescreenData={setHomescreenData}
            blockIndx={blockIndx}
            handleStatus={handleStatus}
            handleIsRowOpen={handleIsRowOpen}
            isRowOpen={isRowOpen}
            setIsRowOpen={setIsRowOpen}
            handleDeleteItem={handleDeleteItem}
            eachBlock={eachBlock}
            handleDuplicateItem={handleDuplicateItem}
            presetColors={presetColors}
            provided={provided}
          />
        );
      case "web_view":
        return (
          <HMSWebView
            homescreenData={homescreenData}
            setHomescreenData={setHomescreenData}
            blockIndx={blockIndx}
            handleStatus={handleStatus}
            handleIsRowOpen={handleIsRowOpen}
            isRowOpen={isRowOpen}
            setIsRowOpen={setIsRowOpen}
            handleDeleteItem={handleDeleteItem}
            eachBlock={eachBlock}
            handleDuplicateItem={handleDuplicateItem}
            uploadFinalCustomizeImgForApp={uploadFinalCustomizeImgForApp}
            presetColors={presetColors}
            provided={provided}
          />
        );
      case "pages":
        return (
          <HMSpages
            homescreenData={homescreenData}
            setHomescreenData={setHomescreenData}
            blockIndx={blockIndx}
            handleStatus={handleStatus}
            handleIsRowOpen={handleIsRowOpen}
            isRowOpen={isRowOpen}
            setIsRowOpen={setIsRowOpen}
            handleDeleteItem={handleDeleteItem}
            eachBlock={eachBlock}
            handleDuplicateItem={handleDuplicateItem}
            uploadFinalCustomizeImgForApp={uploadFinalCustomizeImgForApp}
            presetColors={presetColors}
            provided={provided}
          />
        );

      case "heading":
        return (
          <HeadingBlock
            indx={blockIndx}
            cmsArray={homescreenData}
            setcmsData={setHomescreenData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={handleDeleteItem}
            duplicateItem={handleDuplicateItem}
            eachBlock={eachBlock}
            provided={provided}
          />
        );

      case "paragraph":
        return (
          <ParagraphBlock
            indx={blockIndx}
            cmsArray={homescreenData}
            setcmsData={setHomescreenData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={handleDeleteItem}
            duplicateItem={handleDuplicateItem}
            value={value}
            setValue={setValue}
            eachBlock={eachBlock}
            provided={provided}
          />
        );

      case "spacer":
        return (
          <SpacerBlock
            indx={blockIndx}
            cmsArray={homescreenData}
            setcmsData={setHomescreenData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={handleDeleteItem}
            duplicateItem={handleDuplicateItem}
            eachBlock={eachBlock}
            provided={provided}
          />
        );

      case "separator":
        return (
          <SeparatorBlock
            indx={blockIndx}
            cmsArray={homescreenData}
            setcmsData={setHomescreenData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={handleDeleteItem}
            duplicateItem={handleDuplicateItem}
            eachBlock={eachBlock}
            provided={provided}
          />
        );

      case "button":
        return (
          <ButtonBlock
            indx={blockIndx}
            cmsArray={homescreenData}
            setcmsData={setHomescreenData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={handleDeleteItem}
            duplicateItem={handleDuplicateItem}
            eachBlock={eachBlock}
            provided={provided}
          />
        );

      case "gallery":
        return (
          <GalleryBlock
            indx={blockIndx}
            cmsArray={homescreenData}
            setcmsData={setHomescreenData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={handleDeleteItem}
            duplicateItem={handleDuplicateItem}
            galleryToggle={galleryToggle}
            setgalleryToggle={setgalleryToggle}
            galleryTab={galleryTab}
            setgalleryTab={setgalleryTab}
            eachBlock={eachBlock}
            galleryToggleUpdate={galleryToggleUpdate}
            // dummyArr={dummyArr}
            // setDummyArr={setDummyArr}
            provided={provided}
          />
        );

      case "code":
        return (
          <CodeBlock
            indx={blockIndx}
            cmsArray={homescreenData}
            setcmsData={setHomescreenData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={handleDeleteItem}
            duplicateItem={handleDuplicateItem}
            eachBlock={eachBlock}
            provided={provided}
          />
        );
      case "image":
        return (
          <ImageBlock
            indx={blockIndx}
            cmsArray={homescreenData}
            setcmsData={setHomescreenData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={handleDeleteItem}
            duplicateItem={handleDuplicateItem}
            eachBlock={eachBlock}
            provided={provided}
            from={"page"}
          />
        );
    }
  };

  //Drag n Drop

  // Drag an drop
  const reorderList = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.map((row, indx) => {
      row.position = indx;
    });
    return result;
  };

  const handleDragEnd = (result) => {
    let element=document.getElementById("drag-start-box");
    element.classList.remove("homescreen-section-move");
   if (
      result.destination === null ||
      result.destination === undefined ||
      result.source === undefined
    ) {
      return;
    } else {
      setDraggedElemenIndx(null);
      setHomescreenData(
        reorderList(
          homescreenData,
          result.source.index,
          result.destination.index
        )
      );
      loadData(
        "drag",
        result.destination.index,
        isRowOpen[result.source.index]
      );
    }
  };
  
  const handleDragStart=(result)=>{
if(result.source){

  setDraggedElemenIndx(result.source.index);
}
    // let element=document.getElementById("drag-start-box");
    // element.classList.add("homescreen-section-move");

  }

  //Uploading final customize image for App team

  const uploadFinalCustomizeImgForApp = (imageNo, blockIndx, newArr) => {
    let newFileName;

    let node = document.getElementById(
      `clocksel${blockIndx}Original${imageNo}`
    );

    newFileName =
      newArr[blockIndx].value.main_banner[imageNo].image.includes(
        process.env.REACT_APP_Dashboard_Crop
      ) || !newArr[blockIndx].value.main_banner[imageNo].image
        ? "ams_" +
          new Date().getTime() +
          "_" +
          Math.floor(1000 + Math.random() * 9000)
        : newArr[blockIndx].value.main_banner[imageNo].image.replace(
            ".png",
            ""
          );
    newArr[blockIndx].value.main_banner[imageNo].image = newFileName + ".png";
    newArr[blockIndx].value.main_banner[imageNo].is_data_change = false;
    html2canvas(node, {
      scale: 1,
      useCORS: true,
      backgroundColor: null,
      allowTaint: true,
      imageTimeout: 0,
      logging: true,
    })
      .then((canvas) => {
        let dataimage = canvas.toDataURL("image/png");

        var binary = atob(dataimage.split(",")[1]);
        var array = [];
        for (var i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
        }
        let file = new Blob([new Uint8Array(array)], {
          type: "image/png",
        });

        //send to s3
        const config = {
          bucketName: process.env.REACT_APP_bucketName,
          dirName:
            localStorage.getItem("user_id") +
            "/" +
            localStorage.getItem("Appid") +
            process.env.REACT_APP_MAIN_BANNER_IMG.replace(
              /\/$/,
              ""
            ) /* optional */,
          region: process.env.REACT_APP_region,
          accessKeyId: process.env.REACT_APP_accessKeyId,
          secretAccessKey: process.env.REACT_APP_secretAccessKey,
          s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(
            /\/$/,
            ""
          ) /* optional */,
        };

        const ReactS3Client = new S3(config);

        // ReactS3Client.uploadFile(file, newFileName)
        //   .then((data) => {
        //     if (data.status === 204) {
        //       dataimage = canvas.toDataURL();
        //     }
        //   })
        //   .catch((err) => {
        //     errortoast("Something went wrong");
        //   });

        const a = document.createElement("a");
        a.href = dataimage;
        a.download = "slash.png";
        a.click();
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  const handleSubmitHMSData = () => {
    setIsLoader("d-block");
    setSubmitval("");
    let newArr = [...homescreenData];
    let productCatErr = false;
    let blogCatErr = false;
    let newProductErr = false;
    let featuredProductErr = false;
    let saleProductErr = false;
    let recentlyViewedProductErr = false;
    let cartErr = false;
    let popularBlogErr = false;
    let recentBlogErr = false;
    let blogBannerErr = false;
    let pageErr = false;
    let isDisableSaveBtn = 0;
    let error = false;
    let error2 = false;
    let emailErr = false;

    for (let i = 0; i < newArr.length; i++) {
      if (newArr[i].item_type === "main_banner") {
        if (
          (newArr[i].value.main_banner[
            newArr[i].homescreen_selected_image_index
          ].original_image.includes("http") &&
            newArr[i].value.main_banner[
              newArr[i].homescreen_selected_image_index
            ].original_image.indexOf(process.env.REACT_APP_Dashboard_Crop) ===
              -1) ||
          newArr[i].value.main_banner[
            newArr[i].homescreen_selected_image_index
          ].original_image.includes("base64")
        ) {
          isDisableSaveBtn++;
        } else {
          if (
            newArr[i].value.main_banner[
              newArr[i].homescreen_selected_image_index
            ].is_data_change
          ) {
            uploadFinalCustomizeImgForApp(
              newArr[i].homescreen_selected_image_index,
              i,
              newArr
            );
          }
        }
      } else if (newArr[i].item_type === "product_categories") {
        if (newArr[i].style.row === "" || newArr[i].style.column === "") {
          productCatErr = true;
          break;
        }
        if (newArr[i].value.label === "") {
          newArr[i].value.label = "Product Categories";
        }
      } else if (newArr[i].item_type === "blog_categories") {
        if (newArr[i].style.row === "" || newArr[i].style.column === "") {
          blogCatErr = true;
          break;
        }
        if (newArr[i].value.label === "") {
          newArr[i].value.label = "Blog Categories";
        }
      } else if (newArr[i].item_type === "new_products") {
        if (newArr[i].style.row === "" || newArr[i].style.column === "") {
          newProductErr = true;

          break;
        }
        if (newArr[i].value.label === "") {
          newArr[i].value.label = "New Product";
        }
      } else if (newArr[i].item_type === "featured_products") {
        if (newArr[i].style.row === "" || newArr[i].style.column === "") {
          featuredProductErr = true;
          break;
        }
        if (newArr[i].value.label === "") {
          newArr[i].value.label = "Featured Product";
        }
      } else if (newArr[i].item_type === "sale_products") {
        if (newArr[i].style.row === "" || newArr[i].style.column === "") {
          saleProductErr = true;
          break;
        }
        if (newArr[i].value.label === "") {
          newArr[i].value.label = "Sale Product";
        }
      } else if (newArr[i].item_type === "recently_viewed_products") {
        if (newArr[i].style.row === "" || newArr[i].style.column === "") {
          recentlyViewedProductErr = true;
          break;
        }
        if (newArr[i].value.label === "") {
          newArr[i].value.label = "Recently Viewed Product";
        }
      } else if (newArr[i].item_type === "cart") {
        if (newArr[i].style.row === "" || newArr[i].style.column === "") {
          cartErr = true;
          break;
        }
        if (newArr[i].value.label === "") {
          newArr[i].value.label = "Cart";
        }
      } else if (newArr[i].item_type === "sticky_blogs") {
        if (newArr[i].style.row === "" || newArr[i].style.column === "") {
          popularBlogErr = true;
          break;
        }
        if (newArr[i].value.label === "") {
          newArr[i].value.label = "Popular Blog";
        }
      } else if (newArr[i].item_type === "new_blogs") {
        if (newArr[i].style.row === "" || newArr[i].style.column === "") {
          recentBlogErr = true;
          break;
        }
        if (newArr[i].value.label === "") {
          newArr[i].value.label = "Recent Blog";
        }
      } else if (newArr[i].item_type === "blog_banner") {
        if (newArr[i].style.column === "") {
          blogBannerErr = true;
          break;
        }
      } else if (newArr[i].item_type === "pages") {
        if (newArr[i].style.row === "") {
          pageErr = true;
          break;
        }
        if (newArr[i].value.label === "") {
          newArr[i].value.label = "Pages";
        }
      } else if (newArr[i].item_type === "gallery") {
        if (
          newArr[i].style.row === "" ||
          newArr[i].style.row === undefined ||
          newArr[i].style.row === null ||
          newArr[i].style.column === "" ||
          newArr[i].style.column === undefined ||
          newArr[i].style.column === null
        ) {
          error = true;
        }
      } else if (newArr[i].item_type === "separator") {
        if (
          newArr[i].style.height === undefined ||
          newArr[i].style.height === "" ||
          newArr[i].style.height === null
        ) {
          error2 = true;
        }
      } else if (newArr[i].item_type === "button") {
        if (newArr[i].value.button.link === "email") {
          if (!emailValidator.validate(newArr[i].value.button.target)) {
            emailErr = true;
          }
        }
      }
    }

    if (blogBannerErr) {
      errortoast("Blog banner column feild can't be empty");
      setIsLoader("d-none");
      setSubmitval("SAVE");
    } else if (productCatErr) {
      errortoast("Product category row or column feild can't be empty");
      setIsLoader("d-none");
      setSubmitval("SAVE");
    } else if (blogCatErr) {
      errortoast("Blog category row or column feild can't be empty");
      setIsLoader("d-none");
      setSubmitval("SAVE");
    } else if (newProductErr) {
      errortoast("New product row or column feild can't be empty");
      setIsLoader("d-none");
      setSubmitval("SAVE");
    } else if (featuredProductErr) {
      errortoast("Featured product  row or column feild can't be empty");
      setIsLoader("d-none");
      setSubmitval("SAVE");
    } else if (saleProductErr) {
      errortoast("Sale product  row or column feild can't be empty");
      setIsLoader("d-none");
      setSubmitval("SAVE");
    } else if (recentlyViewedProductErr) {
      errortoast("Recently viewed product row or column feild can't be empty");
      setIsLoader("d-none");
      setSubmitval("SAVE");
    } else if (cartErr) {
      errortoast("Cart row or column feild can't be empty");
      setIsLoader("d-none");
      setSubmitval("SAVE");
    } else if (popularBlogErr) {
      errortoast("Popular blog row or column feild can't be empty");
      setIsLoader("d-none");
      setSubmitval("SAVE");
    } else if (recentBlogErr) {
      errortoast("Recent blog row or column feild can't be empty");
      setIsLoader("d-none");
      setSubmitval("SAVE");
    } else if (pageErr) {
      errortoast("Page row feild can't be empty");
      setIsLoader("d-none");
      setSubmitval("SAVE");
    } else if (isDisableSaveBtn > 0) {
      setIsLoader("d-none");
      setSubmitval("SAVE");
      errortoast("Image is processing ...");
    } else if (error) {
      errortoast("Select atleast one row and one column from gallery");
      setIsLoader("d-none");
      setSubmitval("SAVE");
    } else if (error2) {
      errortoast("Please enter valid seprator height");
      setIsLoader("d-none");
      setSubmitval("SAVE");
    } else if (emailErr) {
      errortoast("Please enter valid email");
      setIsLoader("d-none");
      setSubmitval("SAVE");
    } else {
      const params = {
        app_id: parseInt(localStorage.getItem("Appid")),
        home_screen_array: newArr,
        // new_key: "NEW",
      };

      setHomescreenData(newArr);

      homescreenSaveAPI(
        params,
        setIsLoader,
        setSpinner,
        setSubmitval,
        isHomescreenReload,
        setIsHomescreenReload
      );
    }
  };


  const handleRightPanelExpand = (value) => {

    setAppRightPanelExpand(value);
  }

  // calling loadData function whenever the length of homsecreenData will change (for open each and every block on row onclick purpose)
  useMemo(() => loadData(), [homescreenData.length]);

  return (
    <>

      <div className="d-md-block">
        <Header customclass={"header-responsive-new"} setPresetColorForFunctionalComponent={setPresetColors}/>
      </div>
      {/* Mobile View */}
      {/* <Mobiletop
        name={"Newhomescreen"}
        imgpath={
          require("../../../assets/images/dashboard/app-dashboard.png").default
        }
      /> */}

      {/* Mobile View End*/}
      <section className="dasboard_page mobile-display mt-4 mt-md-0 main-homescreen">
      <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
      <div className={`right-panel-gap ${(localStorage.getItem("appSideBarCollapse") !== undefined &&
                      localStorage.getItem("appSideBarCollapse") !== null&&
                      localStorage.getItem("appSideBarCollapse") === "true") ?
                          " right-panel-gap-expand": ""}`}>
        {spinner === true ? (
                    
                    <div className="newspinner">
                  {/* <Spinner animation="border" variant="primary" /> */}
                  <div className="newspinner">
              <svg xmlns="http://www.w3.org/2000/svg" 
        xlink="http://www.w3.org/1999/xlink" 
        style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
        width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
          style={{animationplaystate: "running", animationdelay: "0s"}}>
          </animateTransform>
        </circle>
        </g>
        <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
        style={{animationplaystate: "running", animationdelay: "0s"}}>
        </animateTransform>
        </circle>
        </g>
        <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
        style={{animationplaystate: "running", animationdelay: "0s"}}>

        </animateTransform>
        </circle>
        </g>
        <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
        style={{animationplaystate: "running", animationdelay: "0s"}}>

        </animateTransform>
        </circle>
        </g>
        </svg>
            </div>
                </div>
                  
                      ) : ( <div className="whitebg">
            <div className="homescreenhead">
              <div className="d-none d-lg-block">
                <h1>Home Screen</h1>
                <p>
                  Select & design sections for your app home screen.{" "}
                  {/* <a href="">
                    Learn More{" "}
                    <img
                      src={
                        require("../../../assets/images/dashboard/Goto.png")
                          .default
                      }
                    />
                  </a> */}
                </p>
              </div>
              <div className="createhomescreen">
                <div className="undosection">
                  <a href="">
                    <img
                      className="mr-2"
                      src={
                        require("../../../assets/images/dashboard/cms/undo.png")
                          .default
                      }
                    />{" "}
                  </a>
                  <a href="">
                    <img
                      className="mr-2"
                      src={
                        require("../../../assets/images/dashboard/cms/redo.png")
                          .default
                      }
                    />{" "}
                  </a>
                </div>
                <div>
                  <p className="homescreen-preview-btn d-flex d-lg-none" onClick={(e)=>handleMobilePreviewBtn(e)}>
                    {" "}
                    Preview
                  </p>
                  <button
                    className="createnew  d-xl-flex"
                    onClick={(e) => handleSubmitHMSData(e)}
                  >
                    <div className={isLoader}>
                      <div className="new-loader"></div>
                    </div>
                    {submitval}
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              {
                showMobilePreview===true && window.matchMedia("(max-width: 1200px)").matches?<></>:
              
              <div className="col-md-12 col-lg-6 homescreen-edits">
                <div className="homescreen-options">
                  <Link
                    onClick={(e) => handleBlockPopUp(e)}
                    className="addmoreblock"
                  >
                    <img
                      className="mr-2"
                      src={
                        require("../../../assets/images/dashboard/cms/blue-plus.png")
                          .default
                      }
                    />
                    Add Block
                  </Link>
                </div>

                {/* <div className="">
                  <div className="accordionWrapper homescreen-fields">
                    {homescreenData &&
                      homescreenData.map((eachBlock, blockIndx) => {
                        return (
                          <Draggable
                            draggableId={indx.toString()}
                            key={indx}
                            index={indx}
                          >
                            {(provided) => (
                              <div
                                className="draggable-container"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                {handleSwitch(cmsRow, indx, provided)}
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                  </div>
                </div> */}
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      {/* {spinner === true ? (
                        <div className="spinn">
                          <Spinner animation="border" variant="primary" />
                        </div>
                      ) : ( */}
                        <div class="accordionWrapper homescreen-fields">
                          <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
                            <Droppable droppableId="1234567">
                              {(provided) => (
                                <div ref={provided.innerRef}>
                                  {homescreenData &&
                                    homescreenData.map(
                                      (eachBlock, blockIndx) => {
                                        return (
                                          <>
                                            <Draggable
                                              draggableId={blockIndx.toString()}
                                              key={blockIndx}
                                              index={blockIndx}
                                            >

                                              {(provided) => (
                                                <div id="drag-start-box"
                                                  className={`draggable-container ${draggedElemenIndx===blockIndx?"homescreen-section-move":""}`}
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                >
                                                  {handleSwitch(
                                                    eachBlock,
                                                    blockIndx,
                                                    provided
                                                  )}
                                                </div>
                                              )}
                                            </Draggable>
                                          </>
                                        );
                                      }
                                    )}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </div>
                      {/* )} */}
                    </Col>
                  </Row>
                </TabPane>
              </div>}
              <div className="col-md-12 col-lg-6 homescreen-preview-box">
               {
                showMobilePreview===false && window.matchMedia("(max-width: 1200px)").matches?<></>:
                <div className="create-homescreen-preview">
                  {bottomMenuData !== null && colorThemeData !== null && (
                    <Suspense fallback="">
                      <HMSpreview
                        homescreenData={homescreenData}
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        openBlockIndx={openBlockIndx}
                        bottomMenuData={bottomMenuData}
                        colorThemeData={colorThemeData}
                      />
                    </Suspense>
                  )}
                </div>
                }
                <div className="">
                  <Modal
                    isOpen={isAddBlock}
                    toggle={(e) => handleBlockPopUp(e)}
                    // className={this.props.className}
                    className="homescreenaddblock modal-dialog-centered"
                  >
                    <ModalHeader toggle={(e) => handleBlockPopUp(e)}>
                      Add Block
                    </ModalHeader>
                    <ModalBody>
                      <h4>Banner</h4>
                      <FormGroup
                        tag="fieldset"
                        className="select-homescreen-block"
                      >
                        <FormGroup>
                          <Label
                            className={`selecthometype ${
                              blockType === "main_banner" ? "checked-block" : ""
                            }`}
                          >
                            <Input
                              type="radio"
                              name="selecthomeblock"
                              value="main_banner"
                              checked={blockType === "main_banner"}
                              onChange={(e) => handleBlockType(e)}
                            />
                            <div className="image">
                              <div className="d-block text-center">
                                <img
                                  className="img-fluid"
                                  src={
                                    require("../../../assets/images/dashboard/mainbanner-icon.png")
                                      .default
                                  }
                                />
                                <p>Main Banner</p>
                              </div>
                            </div>
                          </Label>
                        </FormGroup>
                        <FormGroup>
                          <Label
                            className={`selecthometype ${
                              blockType === "blog_banner" ? "checked-block" : ""
                            }`}
                          >
                            <Input
                              type="radio"
                              name="selecthomeblock"
                              value="blog_banner"
                              checked={blockType === "blog_banner"}
                              onChange={(e) => handleBlockType(e)}
                            />
                            <div className="image">
                              <div className="d-block text-center">
                                <img
                                  className="img-fluid"
                                  src={
                                    require("../../../assets/images/dashboard/blogbanner-icon.png")
                                      .default
                                  }
                                />
                                <p>Blog Banner</p>
                              </div>
                            </div>
                          </Label>
                        </FormGroup>
                      </FormGroup>

                      <h4>
                        {parseInt(
                          localStorage.getItem("website_technology")
                        ) === 3
                          ? "Blogs"
                          : "WordPress"}
                      </h4>
                      <FormGroup
                        tag="fieldset"
                        className="select-homescreen-block"
                      >
                        <FormGroup>
                          <Label
                            className={`selecthometype ${
                              blockType === "blog_categories"
                                ? "checked-block"
                                : ""
                            }`}
                          >
                            <Input
                              type="radio"
                              name="selecthomeblock"
                              value="blog_categories"
                              checked={blockType === "blog_categories"}
                              onChange={(e) => handleBlockType(e)}
                            />
                            <div className="image">
                              <div className="d-block text-center">
                                <img
                                  className="img-fluid"
                                  src={
                                    require("../../../assets/images/dashboard/blogscategories-icon.png")
                                      .default
                                  }
                                />
                                <p>Blogs Categories</p>
                              </div>
                            </div>
                          </Label>
                        </FormGroup>
                        <FormGroup>
                          <Label
                            className={`selecthometype ${
                              blockType === "new_blogs" ? "checked-block" : ""
                            }`}
                          >
                            <Input
                              type="radio"
                              name="selecthomeblock"
                              value="new_blogs"
                              checked={blockType === "new_blogs"}
                              onChange={(e) => handleBlockType(e)}
                            />
                            <div className="image">
                              <div className="d-block text-center">
                                <img
                                  className="img-fluid"
                                  src={
                                    require("../../../assets/images/dashboard/recentblogs-icon.png")
                                      .default
                                  }
                                />
                                <p>Recent Blogs</p>
                              </div>
                            </div>
                          </Label>
                        </FormGroup>
                        <FormGroup>
                          <Label
                            className={`selecthometype ${
                              blockType === "sticky_blogs"
                                ? "checked-block"
                                : ""
                            }`}
                          >
                            <Input
                              type="radio"
                              name="selecthomeblock"
                              value="sticky_blogs"
                              checked={blockType === "sticky_blogs"}
                              onChange={(e) => handleBlockType(e)}
                            />
                            <div className="image">
                              <div className="d-block text-center">
                                <img
                                  className="img-fluid"
                                  src={
                                    require("../../../assets/images/dashboard/popularblogs-icon.png")
                                      .default
                                  }
                                />
                                <p>Popular Blogs</p>
                              </div>
                            </div>
                          </Label>
                        </FormGroup>
                      </FormGroup>

                      {parseInt(localStorage.getItem("website_technology")) ===
                        5 && <h4>WooCommerce</h4>}
                      {parseInt(localStorage.getItem("website_technology")) ===
                        5 && (
                        <FormGroup
                          tag="fieldset"
                          className="select-homescreen-block"
                        >
                          <FormGroup>
                            <Label
                              className={`selecthometype ${
                                blockType === "product_categories"
                                  ? "checked-block"
                                  : ""
                              }`}
                            >
                              <Input
                                type="radio"
                                name="selecthomeblock"
                                value="product_categories"
                                checked={blockType === "product_categories"}
                                onChange={(e) => handleBlockType(e)}
                              />
                              <div className="image">
                                <div className="d-block text-center">
                                  <img
                                    className="img-fluid"
                                    src={
                                      require("../../../assets/images/dashboard/blogscategories-icon.png")
                                        .default
                                    }
                                  />
                                  <p>Products categories</p>
                                </div>
                              </div>
                            </Label>
                          </FormGroup>
                          <FormGroup>
                            <Label
                              className={`selecthometype ${
                                blockType === "featured_products"
                                  ? "checked-block"
                                  : ""
                              }`}
                            >
                              <Input
                                type="radio"
                                name="selecthomeblock"
                                value="featured_products"
                                checked={blockType === "featured_products"}
                                onChange={(e) => handleBlockType(e)}
                              />
                              <div className="image">
                                <div className="d-block text-center">
                                  <img
                                    className="img-fluid"
                                    src={
                                      require("../../../assets/images/dashboard/featuredproducts-icon.png")
                                        .default
                                    }
                                  />
                                  <p>Featured Products</p>
                                </div>
                              </div>
                            </Label>
                          </FormGroup>
                          <FormGroup>
                            <Label
                              className={`selecthometype ${
                                blockType === "sale_products"
                                  ? "checked-block"
                                  : ""
                              }`}
                            >
                              <Input
                                type="radio"
                                name="selecthomeblock"
                                value="sale_products"
                                checked={blockType === "sale_products"}
                                onChange={(e) => handleBlockType(e)}
                              />
                              <div className="image">
                                <div className="d-block text-center">
                                  <img
                                    className="img-fluid"
                                    src={
                                      require("../../../assets/images/dashboard/saleproducts-icon.png")
                                        .default
                                    }
                                  />
                                  <p>Sale Products</p>
                                </div>
                              </div>
                            </Label>
                          </FormGroup>
                          <FormGroup>
                            <Label
                              className={`selecthometype ${
                                blockType === "new_products"
                                  ? "checked-block"
                                  : ""
                              }`}
                            >
                              <Input
                                type="radio"
                                name="selecthomeblock"
                                value="new_products"
                                checked={blockType === "new_products"}
                                onChange={(e) => handleBlockType(e)}
                              />
                              <div className="image">
                                <div className="d-block text-center">
                                  <img
                                    className="img-fluid"
                                    src={
                                      require("../../../assets/images/dashboard/newproducts-icon.png")
                                        .default
                                    }
                                  />
                                  <p>New Products</p>
                                </div>
                              </div>
                            </Label>
                          </FormGroup>
                          <FormGroup>
                            <Label
                              className={`selecthometype ${
                                blockType === "recently_viewed_products"
                                  ? "checked-block"
                                  : ""
                              }`}
                            >
                              <Input
                                type="radio"
                                name="selecthomeblock"
                                value="recently_viewed_products"
                                checked={
                                  blockType === "recently_viewed_products"
                                }
                                onChange={(e) => handleBlockType(e)}
                              />
                              <div className="image">
                                <div className="d-block text-center">
                                  <img
                                    className="img-fluid"
                                    src={
                                      require("../../../assets/images/dashboard/recentlyviewedproducts-icon.png")
                                        .default
                                    }
                                  />
                                  <p>Recently Viewed Products</p>
                                </div>
                              </div>
                            </Label>
                          </FormGroup>
                          <FormGroup>
                            <Label
                              className={`selecthometype ${
                                blockType === "cart" ? "checked-block" : ""
                              }`}
                            >
                              <Input
                                type="radio"
                                name="selecthomeblock"
                                value="cart"
                                checked={blockType === "cart"}
                                onChange={(e) => handleBlockType(e)}
                              />
                              <div className="image">
                                <div className="d-block text-center">
                                  <img
                                    className="img-fluid"
                                    src={
                                      require("../../../assets/images/dashboard/cart-icon.png")
                                        .default
                                    }
                                  />
                                  <p>Cart</p>
                                </div>
                              </div>
                            </Label>
                          </FormGroup>
                        </FormGroup>
                      )}
                      <h4>Elements</h4>
                      <FormGroup
                        tag="fieldset"
                        className="select-homescreen-block"
                      >
                        <FormGroup>
                          <Label
                            className={`selecthometype ${
                              blockType === "pages" ? "checked-block" : ""
                            }`}
                          >
                            <Input
                              type="radio"
                              name="selecthomeblock"
                              value="pages"
                              checked={blockType === "pages"}
                              onChange={(e) => handleBlockType(e)}
                            />
                            <div className="image">
                              <div className="d-block text-center">
                                <img
                                  className="img-fluid"
                                  src={
                                    require("../../../assets/images/dashboard/homescreen/HMSpage.png")
                                      .default
                                  }
                                />
                                <p>Pages</p>
                              </div>
                            </div>
                          </Label>
                        </FormGroup>
                        <FormGroup>
                          <Label
                            className={`selecthometype ${
                              blockType === "web_view" ? "checked-block" : ""
                            }`}
                          >
                            <Input
                              type="radio"
                              name="selecthomeblock"
                              value="web_view"
                              checked={blockType === "web_view"}
                              onChange={(e) => handleBlockType(e)}
                            />
                            <div className="image">
                              <div className="d-block text-center">
                                <img
                                  className="img-fluid"
                                  src={
                                    require("../../../assets/images/dashboard/homescreen/HMSwebview.png")
                                      .default
                                  }
                                />
                                <p>Web View</p>
                              </div>
                            </div>
                          </Label>
                        </FormGroup>

                        {parseInt(
                          localStorage.getItem("website_technology")
                        ) === 3 && (
                          <FormGroup>
                            <Label className="addblock-ratio">
                              <Input
                                type="radio"
                                name="addblockradio"
                                value="heading"
                                checked={blockType === "heading"}
                                onChange={(e) => handleBlockType(e)}
                              />

                              <div className="image">
                                <div className="d-block text-center">
                                  <img
                                    src={
                                      require("../../../assets/images/dashboard/cms/add-heading.png")
                                        .default
                                    }
                                  />
                                  <p>Heading</p>
                                </div>
                              </div>
                            </Label>
                          </FormGroup>
                        )}
                        {parseInt(
                          localStorage.getItem("website_technology")
                        ) === 3 && (
                          <FormGroup>
                            <Label className="addblock-ratio">
                              <Input
                                type="radio"
                                name="addblockradio"
                                value="paragraph"
                                checked={blockType === "paragraph"}
                                onChange={(e) => handleBlockType(e)}
                              />
                              <div className="image">
                                <div className="d-block text-center">
                                  <img
                                    src={
                                      require("../../../assets/images/dashboard/cms/add-text.png")
                                        .default
                                    }
                                  />
                                  <p>Text</p>
                                </div>
                              </div>
                            </Label>
                          </FormGroup>
                        )}
                        {parseInt(
                          localStorage.getItem("website_technology")
                        ) === 3 && (
                          <FormGroup>
                            <Label className="addblock-ratio">
                              <Input
                                type="radio"
                                name="addblockradio"
                                value="image"
                                checked={blockType === "image"}
                                onChange={(e) => handleBlockType(e)}
                              />
                              <div className="image">
                                <div className="d-block text-center">
                                  <img
                                    src={
                                      require("../../../assets/images/dashboard/cms/add-image.png")
                                        .default
                                    }
                                  />
                                  <p>Image</p>
                                </div>
                              </div>
                            </Label>
                          </FormGroup>
                        )}
                        {parseInt(
                          localStorage.getItem("website_technology")
                        ) === 3 && (
                          <FormGroup>
                            <Label className="addblock-ratio">
                              <Input
                                type="radio"
                                name="addblockradio"
                                value="gallery"
                                checked={blockType === "gallery"}
                                onChange={(e) => handleBlockType(e)}
                              />
                              <div className="image">
                                <div className="d-block text-center">
                                  <img
                                    src={
                                      require("../../../assets/images/dashboard/cms/add-gallery.png")
                                        .default
                                    }
                                  />
                                  <p>Gallery</p>
                                </div>
                              </div>
                            </Label>
                          </FormGroup>
                        )}
                        {parseInt(
                          localStorage.getItem("website_technology")
                        ) === 3 && (
                          <FormGroup>
                            <Label className="addblock-ratio">
                              <Input
                                type="radio"
                                name="addblockradio"
                                value="code"
                                checked={blockType === "code"}
                                onChange={(e) => handleBlockType(e)}
                              />
                              <div className="image">
                                <div className="d-block text-center">
                                  <img
                                    src={
                                      require("../../../assets/images/dashboard/cms/add-code.png")
                                        .default
                                    }
                                  />
                                  <p>Code</p>
                                </div>
                              </div>
                            </Label>
                          </FormGroup>
                        )}
                        {parseInt(
                          localStorage.getItem("website_technology")
                        ) === 3 && (
                          <FormGroup>
                            <Label className="addblock-ratio">
                              <Input
                                type="radio"
                                name="addblockradio"
                                value="button"
                                checked={blockType === "button"}
                                onChange={(e) => handleBlockType(e)}
                              />
                              <div className="image">
                                <div className="d-block text-center">
                                  <img
                                    src={
                                      require("../../../assets/images/dashboard/cms/add-button.png")
                                        .default
                                    }
                                  />
                                  <p>Button</p>
                                </div>
                              </div>
                            </Label>
                          </FormGroup>
                        )}
                        {parseInt(
                          localStorage.getItem("website_technology")
                        ) === 3 && (
                          <FormGroup>
                            <Label className="addblock-ratio">
                              <Input
                                type="radio"
                                name="addblockradio"
                                value="spacer"
                                checked={blockType === "spacer"}
                                onChange={(e) => handleBlockType(e)}
                              />
                              <div className="image">
                                <div className="d-block text-center">
                                  <img
                                    src={
                                      require("../../../assets/images/dashboard/cms/add-spacer.png")
                                        .default
                                    }
                                  />
                                  <p>Spacer</p>
                                </div>
                              </div>
                            </Label>
                          </FormGroup>
                        )}
                        {parseInt(
                          localStorage.getItem("website_technology")
                        ) === 3 && (
                          <FormGroup>
                            <Label className="addblock-ratio">
                              <Input
                                type="radio"
                                name="addblockradio"
                                value="separator"
                                checked={blockType === "separator"}
                                onChange={(e) => handleBlockType(e)}
                              />
                              <div className="image">
                                <div className="d-block text-center">
                                  <img
                                    src={
                                      require("../../../assets/images/dashboard/cms/add-separator.png")
                                        .default
                                    }
                                  />
                                  <p>Separator</p>
                                </div>
                              </div>
                            </Label>
                          </FormGroup>
                        )}
                      </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                      <Button onClick={(e) => handleBlockPopUp(e, "cancel")}>
                        Cancel
                      </Button>
                      <Button
                        disabled={
                          blockType === "" ||
                          blockType === undefined ||
                          blockType === null
                        }
                        onClick={(e) => handleBlockPopUp(e, "add")}
                      >
                        DONE
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>

               {
                showMobilePreview===true&&
                <div className="hide-homescreen-preview" onClick={(e)=>handleMobilePreviewBtn(e)}>        
                <img className="img-fluid" src={require("../../../assets/images/dashboard/preview-cross.png") .default} />        
                </div>}
              </div>
            </div>
          </div>)}
        </div>
      </section>
    </>
  );
};

export default Newhomescreen;
