import React from "react";
import Nestable from "react-nestable";
import "react-nestable/dist/styles/index.css";

const renderItem = ({ item }) => item.text;

const ItemsSorting = ({ itemsArr, handleSorting }) => {
  return (
    <Nestable
      items={itemsArr}
      maxDepth={4}
      renderItem={renderItem}
      onChange={(sortingObj) => handleSorting(sortingObj)}
      className="menuNested"
      // style={{
      //   padding: "12px",
      //   margin: "6px",
      //   // backgroundColor: isDragging ? 'lightgreen' : 'lightgrey',
      //   border: "1px solid black",
      //   // marginLeft: depth * 20,
      //   cursor: "grab",
      //   touchAction: "none",
      // }}
    />
  );
};

export default ItemsSorting;
