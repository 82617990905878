import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiEndPoints from "../utils/apiEndpoints";

export const menuApiSlice = createApi({
  reducerPath: "menu",
  keepUnusedDataFor: 30 * 60,
  baseQuery: fetchBaseQuery({
    baseUrl: apiEndPoints.generalUrl,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("amstoken")}`
      );
      return headers;
    },
  }),
  endpoints: (builder) => ({
    menuData: builder.query({
      query: () => `user/menus/${btoa(localStorage.getItem("Appid"))}`,
    }),
    saveMenuData: builder.mutation({
      query: (newData) => ({
        url: "user/save-menu-builder",
        method: "POST",
        body: newData,
      }),
    }),
  }),
});

export const { useMenuDataQuery, useSaveMenuDataMutation } = menuApiSlice;
