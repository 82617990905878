import React, { Suspense, useEffect, useReducer, useRef } from "react";
import "../../../assets/css/pushnotification.css";
import moment from "moment";
import Header from "../../../Dashboard/Header";
import Leftpanel from "../../../Dashboard/Leftpanel";
import PerformaceReportModal from "../../../Components/NotificationComp/PlaneNotificationListing/PerformaceReportModal";
import MobDeviceFilterModal from "../../../Components/NotificationComp/PlaneNotificationListing/MobDeviceFilterModal";
import MobStatusFilterModal from "../../../Components/NotificationComp/PlaneNotificationListing/MobStatusFilterModal";
import FilterDrawer from "../../../Components/NotificationComp/PlaneNotificationListing/FilterDrawer";
import Spinner from "../../../Components/commonComponents/Spinner";
import BottomScrollListener from "react-bottom-scroll-listener";
import TableList from "../../../Components/NotificationComp/PlaneNotificationListing/TableList";
import SubHeader from "../../../Components/NotificationComp/PlaneNotificationListing/SubHeader";
import MobSubHeader from "../../../Components/NotificationComp/PlaneNotificationListing/MobSubHeader";
import MultiSiteAlert from "../../../Components/NotificationComp/PlaneNotificationListing/MultiSiteAlert";
import { MetaTitle } from "../../../utils/commonUtils";
import {
  useGetAllPlainNotificationsQuery,
  useLazyGetAllPlainNotificationsQuery,
} from "../../../Redux/slices/Notification/basicNotificationSlice";
import {
  DEFAULT_FILTER_OPTIONS,
  FILTER_STATUS,
} from "../../../Constants/notificationConsts";

const PlainNotificationListing = () => {
  const [getNotifDetails] = useLazyGetAllPlainNotificationsQuery();
  const appId = localStorage.getItem("Appid");
  const agencyId = localStorage.getItem("agencyid");

  const [notificationData, setNotificationData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      ntfnFilterShow: false,
      spinner: false,
      multisiteData: {},
      isEnableScreen: 0,
      androidNotifyId: null,
      iosNotifyId: null,
      hitNextPagination: false,
      limit: 20,
      page: 1,
      bottomSpinner: false,
      displayTable: [],
      searchVal: "",
      isDeleteNotifModalOpen: false,
      isNotifFilterOpen: false,
      isFirebaseModalOpen: false,
      toDeleteNotifId: "",
      redDotOnFilter: false,
      openMobParentFilter1: false,
      openMobParentFilter2: false,
      openMobParentFilter3: false,
      filterSelectionArr: [
        {
          index: 0,
          value: "",
        },
      ],
      startDate: new Date(),
      endDate: null,
      deviceFilterVal: null,
      statusFilterVal: null,
      openStatusFilter: false,
      openMobStatusFilter: false,
      openDeviceFilter: false,
      openMobDeviceFilter: false,
      optionForMainFilter: DEFAULT_FILTER_OPTIONS,
      appRightPanelExpand: JSON.parse(
        localStorage.getItem("appSideBarCollapse")
      ),
    }
  );

  const stateRef = useRef(notificationData);

  const { data: plainNotifListData, isError: plainNotifFetchingError } =
    useGetAllPlainNotificationsQuery({
      appId: btoa(appId),
      page: notificationData.page,
      limit: notificationData.limit,
      finalFilterVal: "",
    });

  const returnStatusInNum = (statusVal) => {
    let status = "";

    if (statusVal === FILTER_STATUS.DRAFT) {
      status = 0;
    } else if (statusVal === FILTER_STATUS.SENT) {
      status = 1;
    } else if (statusVal === FILTER_STATUS.SCHEDULED) {
      status = 2;
    } else if (statusVal === FILTER_STATUS.FAILED) {
      status = 3;
    }
    return status;
  };

  const handleRightPanelExpand = (value) =>
    setNotificationData({
      appRightPanelExpand: value,
    });

  const handleMobileMainDropdown = () =>
    setNotificationData({ ntfnFilterShow: false });

  const handleContainerOnBottom = () => {
    if (notificationData.hitNextPagination) {
      setNotificationData({
        page: notificationData.page + 1,
        bottomSpinner: true,
      });
    }
  };

  const buildFilters = () => {
    let dateCheck = false;
    let platFormCheck = false;
    let statusCheck = false;

    notificationData.filterSelectionArr.forEach((row) => {
      if (row.value === DEFAULT_FILTER_OPTIONS[1].value) {
        //date
        dateCheck = true;
      } else if (row.value === DEFAULT_FILTER_OPTIONS[2].value) {
        //platform
        platFormCheck = true;
      } else if (row.value === DEFAULT_FILTER_OPTIONS[0].value) {
        //status
        statusCheck = true;
      }
    });

    let finalFilterVal = "";
    finalFilterVal = `&status=${
      statusCheck && notificationData.statusFilterVal
        ? returnStatusInNum(notificationData.statusFilterVal)
        : ""
    }&plateform=${
      platFormCheck && notificationData.deviceFilterVal
        ? notificationData.deviceFilterVal
        : ""
    }&delivery_start_date=${
      dateCheck && notificationData.startDate
        ? moment(notificationData.startDate).format("L")
        : ""
    }&delivery_end_date=${
      dateCheck && notificationData.endDate
        ? moment(notificationData.endDate).format("L")
        : ""
    }`;

    return finalFilterVal;
  };
  const handleFilterSubmit = () => {
    if (
      (JSON.stringify(notificationData.startDate).length &&
        !JSON.stringify(notificationData.endDate).length) ||
      (!JSON.stringify(notificationData.startDate).length &&
        JSON.stringify(notificationData.endDate).length)
    ) {
      return;
    }

    if (
      notificationData.filterSelectionArr.some(
        (item) => item.value === DEFAULT_FILTER_OPTIONS[2].value
      ) &&
      notificationData.deviceFilterVal === null
    ) {
      return;
    }

    if (
      notificationData.filterSelectionArr.some(
        (item) => item.value === DEFAULT_FILTER_OPTIONS[0].value
      ) &&
      notificationData.statusFilterVal === null
    ) {
      return;
    }

    if (
      notificationData.startDate ||
      notificationData.endDate ||
      notificationData.statusFilterVal ||
      notificationData.deviceFilterVal
    ) {
      setNotificationData({
        redDotOnFilter: true,
      });
    }

    setNotificationData({
      isNotifFilterOpen: false,
      spinner: true,
    });
    document.body.classList.remove("opec");

    setNotificationData({
      page: 1,
    });

    getNotifDetails({
      appId: btoa(appId),
      page: notificationData.page,
      limit: notificationData.limit,
      finalFilterVal: buildFilters(),
    })
      .unwrap()
      .then((res) => {
        setNotificationData({
          spinner: false,
          multisiteData: res?.multisite,
          isEnableScreen: res?.is_enable_screen,
          androidNotifyId: res?.android_notification_project_id,
          iosNotifyId: res?.ios_notification_project_id,
          hitNextPagination:
            res.data?.length > 0 && res.data?.length === notificationData.limit
              ? true
              : false,
          bottomSpinner: false,
          displayTable: res.data || [],
        });
      })
      .catch(() => {})
      .finally(() => {});
  };

  const handleResetFilter = () => {
    const notRefData = stateRef.current;

    let finalFilterVal = `&status=&plateform=&delivery_start_date=&delivery_end_date=`;

    setNotificationData({
      redDotOnFilter: false,
      isNotifFilterOpen: false,
      spinner: true,
      page: 1,
    });
    document.body.classList.remove("opec");

    getNotifDetails({
      appId: btoa(appId),
      page: notRefData.page,
      limit: notRefData.limit,
      finalFilterVal: finalFilterVal,
    })
      .unwrap()
      .then((res) => {
        setNotificationData({
          spinner: false,
          multisiteData: res?.multisite,
          isEnableScreen: res?.is_enable_screen,
          androidNotifyId: res?.android_notification_project_id,
          iosNotifyId: res?.ios_notification_project_id,
          hitNextPagination:
            res.data?.length > 0 && res.data?.length === notRefData.limit
              ? true
              : false,
          bottomSpinner: false,
          displayTable: res.data || [],
          isNotifFilterOpen: false,
          redDotOnFilter: false,
          startDate: new Date(),
          endDate: null,
          deviceFilterVal: null,
          statusFilterVal: null,
          filterSelectionArr: [
            {
              index: 0,
              value: "",
            },
          ],
        });
      })
      .catch(() => {});
  };

  const handleNotificationClick = () => {
    setNotificationData({
      isNotifFilterOpen: true,
    });
    if (notificationData.isNotifFilterOpen)
      document.body.classList.remove("opec");
    else document.body.classList.add("opec");

    document.body.classList.add("opec");
  };

  useEffect(() => {
    document.title = MetaTitle("Basic notifications");
  }, []);

  useEffect(() => {
    setNotificationData({
      spinner: true,
    });
    if (plainNotifFetchingError) {
      setNotificationData({
        spinner: false,
      });
    }
    if (plainNotifListData && plainNotifListData.code === 200) {
      let temp = notificationData.displayTable;
      setNotificationData({
        spinner: false,
        multisiteData: plainNotifListData?.multisite,
        isEnableScreen: plainNotifListData?.is_enable_screen,
        androidNotifyId: plainNotifListData?.android_notification_project_id,
        iosNotifyId: plainNotifListData?.ios_notification_project_id,
        hitNextPagination:
          plainNotifListData.data?.length > 0 &&
          plainNotifListData.data?.length === notificationData.limit
            ? true
            : false,
        bottomSpinner: false,
        displayTable: [...temp, ...plainNotifListData.data],
      });
      localStorage.setItem(
        "create_notification_img",
        JSON.stringify(plainNotifListData?.app_icon_data)
      );
    }
  }, [plainNotifListData, plainNotifFetchingError, notificationData.limit]);

  return (
    <>
      <div className="d-md-block">
        <Header
          customclass={"header-responsive-new"}
          postfiltershow={notificationData.ntfnFilterShow}
          handleMobileMainDropdown={handleMobileMainDropdown}
        />
      </div>

      <section className="dasboard_page newmobile-display pushnotification-section">
        <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
        <BottomScrollListener
          onBottom={() => handleContainerOnBottom()}
          offset={100}
        >
          {(scrollRef) => (
            <div
              ref={scrollRef}
              className={`right-panel-gap ${
                notificationData.appRightPanelExpand
                  ? "right-panel-gap-expand"
                  : ""
              }   `}
            >
              <MobSubHeader
                notificationData={notificationData}
                setNotificationData={setNotificationData}
                agencyId={agencyId}
                handleNotificationClick={handleNotificationClick}
              />
              <div className="mob-upgradenotificationpage">
                <SubHeader
                  notificationData={notificationData}
                  setNotificationData={setNotificationData}
                  agencyId={agencyId}
                  handleNotificationClick={handleNotificationClick}
                />

                <div
                  className={
                    notificationData.isEnableScreen &&
                    notificationData.multisiteData &&
                    notificationData.multisiteData.is_app_link_to_multisite > 0
                      ? ""
                      : notificationData.spinner
                      ? " notification-right-page"
                      : null
                  }
                >
                  {notificationData.spinner ? (
                    <Spinner />
                  ) : (
                    <>
                      {notificationData.isEnableScreen &&
                      notificationData.multisiteData &&
                      notificationData.multisiteData.is_app_link_to_multisite >
                        0 ? (
                        <Suspense fallback={""}>
                          <MultiSiteAlert
                            value={notificationData.multisiteData}
                          />
                        </Suspense>
                      ) : (
                        <div className="row notification-right-page plain-notification-right-page">
                          <div
                            className={`right_general ${
                              !notificationData.isEnableScreen
                                ? ""
                                : " notificationtab"
                            }`}
                          >
                            <div className="mob-pushnotification-platform">
                              <div>
                                <TableList
                                  displayTable={notificationData.displayTable}
                                  notificationData={notificationData}
                                  setNotificationData={setNotificationData}
                                  buildFilters={buildFilters}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </BottomScrollListener>

        <FilterDrawer
          notificationData={notificationData}
          setNotificationData={setNotificationData}
          handleFilterSubmit={handleFilterSubmit}
          handleResetFilter={handleResetFilter}
        />
        {/* Mobile view Status filter dropdown */}
        <MobStatusFilterModal
          notificationData={notificationData}
          setNotificationData={setNotificationData}
        />
        {/* Mobile view Device filter dropdown */}
        <MobDeviceFilterModal
          notificationData={notificationData}
          setNotificationData={setNotificationData}
        />
        {/* Firebasemodal */}
        <PerformaceReportModal
          notificationData={notificationData}
          setNotificationData={setNotificationData}
        />
      </section>
    </>
  );
};

export default PlainNotificationListing;
