import React from "react";

const SupportCard = ({ heading, text, link, icon }) => {

  const supportPlan = localStorage.getItem("supportplan");
  const isPremiumSubscriber = parseInt(localStorage.getItem("isPremiumSubscriber")) === 1;

  const isSupportPlanValid = supportPlan && supportPlan !== "null" && supportPlan !== "undefined";

  let href;

  if (heading === "Live chat") {
    href = (isSupportPlanValid || isPremiumSubscriber) ? undefined : "/support-pricing";
  } else if (heading === "Book a call") {
    href = isSupportPlanValid ? "/book-a-call" : "/support-pricing";
  } else {
    href = link;
  }

  return (
    <div
      containerid={
        heading === "Live chat" &&
        ( ( localStorage.getItem("supportplan")  &&
          localStorage.getItem("supportplan") !== "null" &&
          localStorage.getItem("supportplan") !== "undefined"
          )
          || parseInt(localStorage.getItem('isPremiumSubscriber')) === 1
        )

          ? "zsiqchat"
          : ""
      }
      id={
        heading === "Live chat" &&
        ( ( localStorage.getItem("supportplan")  &&
        localStorage.getItem("supportplan") !== "null" &&
        localStorage.getItem("supportplan") !== "undefined"
        )
        || parseInt(localStorage.getItem('isPremiumSubscriber')) === 1
      )
          ? "zsiqchat"
          : ""
      }
      className="agency-support-body-card"
    >
      <a
        href={href}
        onClick={() => {
          if (
            ( ( localStorage.getItem("supportplan")  &&
          localStorage.getItem("supportplan") !== "null" &&
          localStorage.getItem("supportplan") !== "undefined"
          )
          || parseInt(localStorage.getItem('isPremiumSubscriber')) === 1
        ) &&
            heading === "Live chat"
          ) {
            setTimeout(() => {
              if (window.$zoho) {
                window.$zoho?.salesiq?.floatwindow?.visible("show");
              }
            }, 2000);
          }
        }}
        target="_blank"
        className="agency-dashboard-support-card-link"
      ></a>
      <img
        className="agency-support-link-icon"
        src={require("../../../Agency/assets/images/support-arrow.svg").default}
        alt="link-arrow"
      />
      <img
        className="agency-support-icon"
        src={require(`../../../Agency/assets/images/${icon}.svg`).default}
        alt={icon}
      />

      <div>
        <h4 className="agency-support-body-title">{heading}</h4>
        <h6 className="agency-support-body-description">{text}</h6>
      </div>
    </div>
  );
};

export default SupportCard;
