import React, { useContext, useEffect, useReducer, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select from "react-select";
import {
  getWorkspacesList,
  setAllAppdata,
} from "../../../Api/Multisite/Multisiteapi";
import { Popupnotanyapp } from "../Popupnotanyapp";
import BottomScrollListener from "react-bottom-scroll-listener";
import {
  mergeApiSlice,
  useGetAllAppsQuery,
  useLinkAppsMutation,
} from "../../../Redux/slices/mergeappSlice";
import { errortoast, sucesstoast } from "../../Toaster";
import { useDispatch } from "react-redux";
import SecondaryBodySpinner from "../../../Components/commonIcons/SecondaryBodySpinner";
import { previewContext } from "../../../App";

const MergeAppAddModal = ({ mergeState, unlink }) => {
  const [addModalData, setAddModalData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      mergeAppList: [],
      productSelected: { value: "payPerApp", label: "Pay-per-app" },
      workSpacesList: [],
      workSpaceSelected: null,
      mergeArray: [],
      options: null,
      appsToLink: [],
      isMerging: false,
      appsLoading: false,
      searchField: "",
      isSearching: false,
      secondbodyspinner: false,
      noMoreApps: false,
    }
  );
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [fromSearch, setFromSearch] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const appPlan= localStorage.getItem("app_plan")
  const {toggleActivateModal} = useContext(previewContext)

  const {
    data: allAppsData,
    isError: allAppsDataError,
    isLoading: allAppsDataLoading,
    isFetching: allAppsDataFetching,
  } = useGetAllAppsQuery(
    {
      page,
      workspace_id: addModalData.workSpaceSelected?.value,
      search_field: addModalData.searchField,
    },

    {
      skip:
        addModalData.searchField ||
        (!addModalData.isSearching && addModalData.searchField),
    }
  );

  // To manually trigger a query call on enter in search field
  const [trigger] = mergeApiSlice.endpoints.getAllApps.useLazyQuery();

  const [linkApps, { linkAppsResult }] = useLinkAppsMutation();

  useEffect(() => {
    getWorkspacesList({ setState: setAddModalData, setPage });
  }, []);

  useEffect(() => {
    setAllAppdata(
      addModalData,
      setAddModalData,
      allAppsData,
      allAppsDataLoading,
      allAppsDataError,
      page,
      setPage,
      allAppsDataFetching
    );
  }, [allAppsData, addModalData.productSelected.value]);

  useEffect(() => {
    if (addModalData.productSelected.value === "workspace") {
      if (
        addModalData.productSelected?.value === "workspace" &&
        !addModalData?.workSpaceSelected &&
        !!addModalData?.workSpacesList?.length
      ) {
        setAddModalData({
          workSpaceSelected: {
            label: addModalData.workSpacesList?.[0]?.workspace_name,
            value: addModalData.workSpacesList?.[0]?.id,
          },
          appsLoading: true,
        });
      }

      if (!allAppsDataLoading && !allAppsDataError && !allAppsDataFetching) {
        if (allAppsData.code === 200) {
          setAddModalData({
            appsLoading: false,
            noMoreApps: false,
            isSearching: false,
          });

          if (allAppsData.data?.length === 0) {
            setAddModalData({
              scrollstop: false,
              appsLoading: false,
              noMoreApps: true,
              secondbodyspinner: false,
            });
            // setPage(page < 1 ? 1 : page - 1); // removed this coz but dont know if we need this
          }
          setAddModalData({
            mergeAppList:
              page <= 1
                ? [
                    ...allAppsData?.data?.map((elem) => ({
                      ...elem,
                      isSelected: false,
                    })),
                  ]
                : addModalData.mergeAppList.concat(
                    allAppsData.data?.map((elem) => ({
                      ...elem,
                      isSelected: false,
                    }))
                  ),
            secondbodyspinner: false,
            appsLoading: false,
          });
        }
      }
    }
  }, [allAppsData, addModalData.productSelected.value]);

  // To Clear current App selection on product or workspace change
  const clearMerging = () =>
    setAddModalData({ isMerging: false, appsToLink: [] });

  const handleProductSelected = (e) => {
    clearMerging();
    if (addModalData.productSelected.value === e.value) return;
    if (e.value === "payPerApp") {
      setAddModalData({ workSpaceSelected: null });
    }
    setAddModalData({
      productSelected: e,
      mergeAppList: [],
      appsLoading: allAppsDataFetching,
    });
    setIsFetching(false); // Reset the infinite scroll state
    setPage(1);
  };

  const handleWorkSpaceSelected = (e) => {
    setAddModalData({
      isMerging: false,
      appsToLink: [],
      workSpaceSelected: e,
      appsLoading: true,
    });
    setIsFetching(false); // Reset the infinite scroll state
    setPage(1);
  };

  const handleAppSelected = (idx, appId, isSelected) => {
    const currObj = { ...addModalData };
    if (!isSelected) {
      currObj.appsToLink.push(appId);
      currObj.mergeAppList[idx] = {
        ...addModalData.mergeAppList[idx],
        isSelected: !addModalData.mergeAppList[idx].isSelected,
      };
      setAddModalData(currObj);
    } else {
      currObj.mergeAppList[idx] = {
        ...addModalData.mergeAppList[idx],
        isSelected: !addModalData.mergeAppList[idx].isSelected,
      };
      currObj.appsToLink = currObj.appsToLink.filter((elem) => elem !== appId);
      setAddModalData(currObj);
    }
  };

  const handleLinkApps = (e) => {
    if(mergeState?.status_code === 203 ){
      toggleActivateModal(e)
      return;
     }
    setAddModalData({ isMerging: true });

    linkApps({
      app_id: parseInt(localStorage.getItem("Appid")),
      child_app_array: addModalData.appsToLink,
    })
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          sucesstoast(
            `${
              addModalData.appsToLink?.length > 1 ? "Apps" : "App"
            } merged successfully`
          );
        }
      })
      .catch((err) => {
        errortoast(err?.message);
      })
      .finally(() => {
        setAddModalData({
          mergeAppList: [
            ...allAppsData?.data?.map((elem) => ({
              ...elem,
              isSelected: false,
            })),
          ],
          workSpaceSelected: null,
          productSelected: { value: "payPerApp", label: "Pay-per-app" },
          isMerging: false,
          appsToLink: [],
        });
        unlink(true);
        setIsFetching(false); // Reset the infinite scroll state
        resetAppSelection();
      });
  };

  const handleAppSearch = (e) =>
    setAddModalData({ searchField: e.target.value });

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    if (addModalData.appsLoading || addModalData.secondbodyspinner) return;

    setPage(1);
    !addModalData.searchField ? setFromSearch(false) : setFromSearch(true);
    setAddModalData({ noMoreApps: false, isSearching: true });

    trigger(
      {
        page,
        workspace_id: addModalData.workSpaceSelected?.value,
        search_field: addModalData.searchField,
      },
      true
    )
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          // update the cache here
          dispatch(
            mergeApiSlice.util.updateQueryData(
              "getAllApps",
              undefined,
              (cacheData) => {}
            )
          );
          // update the state here
          setAddModalData({
            mergeAppList:
              page <= 1
                ? [
                    ...response?.data?.map((elem) => ({
                      ...elem,
                      isSelected: false,
                    })),
                  ]
                : addModalData.mergeAppList.concat(
                    response.data?.map((elem) => ({
                      ...elem,
                      isSelected: false,
                    }))
                  ),
            secondbodyspinner: false,
            appsLoading: false,
          });
        }
      })
      .catch(() => {});
  };

  const handleBottomScrollListner = () => {
    if (
      addModalData.productSelected.value === "workspace" &&
      !addModalData.workSpaceSelected?.value
    )
      return;

    if (isFetching || addModalData.noMoreApps || addModalData.appsLoading)
      return;

    setIsFetching(true);
    setAddModalData({ secondbodyspinner: true });
    const el = document.querySelector(".applist-with-loader");
    window.scrollTo(0, el.scrollHeight);

    setPage((p) => {
      return p + 1;
    });
  };

  const isAppOnFreePlan = (code) => {
    if (
      code === "preview" ||
      code === "paddle_preview" ||
      code === process.env.REACT_APP_STRIPE_PREVIEW ||
      code === process.env.REACT_APP_zoho_preview ||
      code === process.env.REACT_APP_Lifetime_preview ||
      code === process.env.REACT_APP_Lifetime_preview
    ) {
      return true;
    }
  };

  const getSelectedAppCountText = (mergeAppList) => {
    const selectedCount = mergeAppList.filter((elem) => elem.isSelected).length;
    return selectedCount ? (
      <p>
        {selectedCount} app{selectedCount > 1 ? "s" : ""} selected
      </p>
    ) : null;
  };

  const resetAppSelection = () => {
    // Reset App state and selection on modal close
    setAddModalData({
      mergeAppList: [
        ...allAppsData?.data?.map((elem) => ({
          ...elem,
          isSelected: false,
        })),
      ],
      workSpaceSelected: null,
      productSelected: { value: "payPerApp", label: "Pay-per-app" },
    });
    setIsFetching(false); // Reset the infinite scroll state
    unlink();
    clearMerging();
  };

  const handleClearSearch = () => {
    setAddModalData({ appsLoading: true });
    trigger(
      {
        page,
        workspace_id: addModalData.workSpaceSelected?.value,
        search_field: "",
      },
      false
    )
      .unwrap()
      .then((response) => {
        setAddModalData({
          mergeAppList:
            page <= 1
              ? [
                  ...response?.data?.map((elem) => ({
                    ...elem,
                    isSelected: false,
                  })),
                ]
              : addModalData.mergeAppList.concat(
                  response.data?.map((elem) => ({
                    ...elem,
                    isSelected: false,
                  }))
                ),
          secondbodyspinner: false,
          appsLoading: false,
        });
      });
    if (
      !addModalData.searchField ||
      addModalData.appsLoading ||
      addModalData.secondbodyspinner
    )
      return;

    setPage(1);

    setAddModalData({
      searchField: "",
      appsLoading: false,
      noMoreApps: false,
      isSearching: false,
    });
    setFromSearch(false);
  };

  return (
    <Modal
      className="mergeapp-selectpopup"
      isOpen={mergeState.mdlunlink}
      toggle={() => resetAppSelection()}
      centered
    >
      <div className=" mob-modal-body">
        <ModalHeader>
          <h1>
            Select apps to merge
            <h5 className="mergeapp-tooltip">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 15 15"
              >
                <g
                  id="Group_5792"
                  data-name="Group 5792"
                  transform="translate(-1057.885 -227.299)"
                >
                  <circle
                    id="Ellipse_213"
                    data-name="Ellipse 213"
                    cx="7"
                    cy="7"
                    r="7"
                    transform="translate(1058.385 227.799)"
                    fill="none"
                    stroke="#7782a1"
                    stroke-miterlimit="10"
                    stroke-width="1"
                  />
                  <path
                    id="Path_6038"
                    data-name="Path 6038"
                    d="M1117.679,269.437a2.125,2.125,0,1,1,2.771,2.025.921.921,0,0,0-.646.875v.625"
                    transform="translate(-54.219 -36.045)"
                    fill="none"
                    stroke="#7782a1"
                    stroke-miterlimit="10"
                    stroke-width="1"
                  />
                  <line
                    id="Line_3"
                    data-name="Line 3"
                    y2="0.918"
                    transform="translate(1065.583 238.03)"
                    fill="none"
                    stroke="#7782a1"
                    stroke-miterlimit="10"
                    stroke-width="1"
                  />
                </g>
              </svg>
              <h5 className="mergeapp-tooltipsubheader">
                Select the apps you wish to merge into a single unified app. You
                can combine both free and paid apps, but the merge process can
                only be initiated on a premium plan. Only WordPress &
                WooCommerce apps can be merged together, and Custom Apps & Web
                to App apps can be merged together.
              </h5>
            </h5>
          </h1>

          <div className="mergeapp-modal-headright">
            <div className="mob-mergeapp-modal-headright">
              {localStorage.getItem("agencyid") &&
              localStorage.getItem("agencyid") == 1 ? (
                <Select
                  isSearchable={false}
                  style={{ marginLeft: "0px" }}
                  options={[
                    { value: "payPerApp", label: "Pay-per-app" },
                    { value: "workspace", label: "Workspace" },
                  ]}
                  onChange={(e) => handleProductSelected(e)}
                  value={addModalData.productSelected}
                  classNamePrefix="select-filter"
                  className="mergeapp-react-select-dropdown"
                />
              ) : (
                <></>
              )}
              {addModalData.productSelected?.value === "workspace" && (
                <Select
                  isSearchable={false}
                  placeholder="Select"
                  options={addModalData.workSpacesList.map((workSpace) => ({
                    label: workSpace.workspace_name,
                    value: workSpace.id,
                  }))}
                  classNamePrefix="select-filter"
                  onChange={(e) => handleWorkSpaceSelected(e)}
                  value={addModalData.workSpaceSelected}
                  style={{ marginRight: "10px" }}
                  className="mergeapp-react-select-second-dropdown"
                  formatOptionLabel={(ws) => {
                    const res = addModalData.workSpacesList?.find(
                      (e) => e.workspace_name === ws.label
                    );
                    return (
                      <div className="d-flex">
                        {!res?.profile_image ? (
                          <span style={{ marginRight: "6px" }}>
                            <img
                              src={
                                require("../../../assets/images/dashboard/sub-overview-img.png")
                                  .default
                              }
                              style={{
                                height: "24px",
                                width: "24px",
                                borderRadius: "50%",
                                minWidth: "24px",
                              }}
                              className=""
                              alt="profileIc"
                            />
                          </span>
                        ) : (
                          <span style={{ marginRight: "6px" }}>
                            <img
                              src={
                                process.env.REACT_APP_Image_Path +
                                res.user_id +
                                process.env.REACT_APP_Workspace_Profile +
                                res.id +
                                "/" +
                                res.profile_image
                              }
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src =
                                  require("../../../assets/images/dashboard/sub-overview-img.png").default;
                              }}
                              className=""
                              style={{
                                height: "24px",
                                width: "24px",
                                borderRadius: "50%",
                              }}
                              alt="workspace"
                            />
                          </span>
                        )}
                        <span className="billing-options">
                          {res.workspace_name}
                        </span>
                      </div>
                    );
                  }}
                />
              )}
            </div>
            <div className="mergeapp-searchdiv">
              <div
                className="mergeapp-searchicon"
                onClick={(e) => handleSearchSubmit(e)}
              >
                <svg
                  id="Group_3669"
                  data-name="Group 3669"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.53"
                  height="18.53"
                  viewBox="0 0 18.53 18.53"
                >
                  <g
                    id="Ellipse_246"
                    data-name="Ellipse 246"
                    transform="translate(16.508) rotate(90)"
                    fill="none"
                    stroke="#bcc1ce"
                    stroke-width="1.5"
                  >
                    <ellipse
                      cx="8.254"
                      cy="8.254"
                      rx="8.254"
                      ry="8.254"
                      stroke="none"
                    />
                    <ellipse
                      cx="8.254"
                      cy="8.254"
                      rx="7.504"
                      ry="7.504"
                      fill="none"
                    />
                  </g>
                  <path
                    id="Path_6064"
                    data-name="Path 6064"
                    d="M0,6.02V0"
                    transform="translate(18 18) rotate(135)"
                    fill="none"
                    stroke="#bcc1ce"
                    stroke-width="1.5"
                  />
                </svg>
              </div>
              <form onSubmit={(e) => handleSearchSubmit(e)}>
                <input
                  className="add-app-search-input"
                  placeholder="Search"
                  value={addModalData.searchField}
                  onChange={(e) => handleAppSearch(e)}
                  disabled={
                    addModalData.appsLoading || addModalData.secondbodyspinner
                  }
                />

                {addModalData.searchField.length > 0 && (
                  <div
                    className="d-flex align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      onClick={() => handleClearSearch()}
                      src={
                        require("../../../assets/images/dashboard/cross.png")
                          .default
                      }
                      alt="Ic"
                    />
                  </div>
                )}
              </form>
            </div>
          </div>
        </ModalHeader>
        <BottomScrollListener onBottom={handleBottomScrollListner}>
          {(scrollRef) => (
            <ModalBody>
              <div className="applist-with-loader" ref={scrollRef}>
                <div className="app-list-body ">
                  {(addModalData.appsLoading && allAppsDataFetching) ||
                  (allAppsDataFetching && !addModalData.secondbodyspinner) ? (
                    <div className="newspinner">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xlink="http://www.w3.org/1999/xlink"
                        style={{
                          margin: "auto",
                          background: "rgb(241, 242, 243,0)",
                          display: "block",
                          shaperendering: "auto",
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                        width="200px"
                        height="200px"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="xMidYMid"
                      >
                        <g
                          transform="translate(20 50)"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <circle
                            cx="0"
                            cy="0"
                            r="6"
                            fill="#e6261f"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <animateTransform
                              attributeName="transform"
                              type="scale"
                              begin="-0.375s"
                              calcMode="spline"
                              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                              values="0;1;0"
                              keyTimes="0;0.5;1"
                              dur="1s"
                              repeatCount="indefinite"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            ></animateTransform>
                          </circle>
                        </g>
                        <g
                          transform="translate(40 50)"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <circle
                            cx="0"
                            cy="0"
                            r="6"
                            fill="#f7d038"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <animateTransform
                              attributeName="transform"
                              type="scale"
                              begin="-0.25s"
                              calcMode="spline"
                              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                              values="0;1;0"
                              keyTimes="0;0.5;1"
                              dur="1s"
                              repeatCount="indefinite"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            ></animateTransform>
                          </circle>
                        </g>
                        <g
                          transform="translate(60 50)"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <circle
                            cx="0"
                            cy="0"
                            r="6"
                            fill="#49da9a"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <animateTransform
                              attributeName="transform"
                              type="scale"
                              begin="-0.125s"
                              calcMode="spline"
                              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                              values="0;1;0"
                              keyTimes="0;0.5;1"
                              dur="1s"
                              repeatCount="indefinite"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            ></animateTransform>
                          </circle>
                        </g>
                        <g
                          transform="translate(80 50)"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <circle
                            cx="0"
                            cy="0"
                            r="6"
                            fill="#4355db"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <animateTransform
                              attributeName="transform"
                              type="scale"
                              begin="0s"
                              calcMode="spline"
                              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                              values="0;1;0"
                              keyTimes="0;0.5;1"
                              dur="1s"
                              repeatCount="indefinite"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            ></animateTransform>
                          </circle>
                        </g>
                      </svg>
                    </div>
                  ) : addModalData.mergeAppList.length > 0 ? (
                    addModalData.mergeAppList.map((app, idx) => {
                      const isAppFree = isAppOnFreePlan(app.plan_code);
                      return (
                        <div
                          className={`mergeapp-appdiv ${isAppFree ? "" : ""}`}
                          onClick={() =>
                            handleAppSelected(idx, app.app_id, app.isSelected)
                          }
                          key={`listModal_${idx}`}
                        >
                          <div className="mergeapp-appdiv-head">
                            <div className="mergeapp-appdiv-head-left">
                              <h1>{app.app_name}</h1>
                              <p>ID: {app.app_id}</p>
                            </div>

                            <div className="mergeapp-select-maindiv">
                              <label className="mergeapp-select-container">
                                <input
                                  checked={app.isSelected}
                                  type="checkbox"
                                />
                                <span
                                  onClick={() =>
                                    handleAppSelected(
                                      idx,
                                      app.app_id,
                                      app.isSelected
                                    )
                                  }
                                  className="meregeapp-select-checkmark"
                                ></span>
                              </label>
                            </div>
                          </div>
                          <div className="mergeapp-popup-appicon">
                            <img
                              style={{ width: "100%", height: "100%" }}
                              className="merge-app-appicon"
                              src={
                                process.env.REACT_APP_Image_Path +
                                app.user_id +
                                "/" +
                                app.app_id +
                                process.env.REACT_APP_AppIcon_Display +
                                app.image
                              }
                              alt="app-icon"
                              onError={({ currentTarget }) => {
                                currentTarget.src =
                                  require("../../../assets/images/dashboard/the-icon.png").default;
                              }}
                            ></img>
                            <div className="appType-tooltip">
                              {isAppFree ? "Free" : app.plan}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center w-100"
                      style={{ height: "487px" }}
                    >
                      {fromSearch ? (
                        <div className="no-app-found-on-search">
                          <div>
                            <img
                              src={
                                require("../../../assets/images/dashboard/no-media.png")
                                  .default
                              }
                              alt="no-media"
                              className="img-fluid"
                            />
                          </div>
                          <p className="notingat-the-moment">
                            Nothing here at the moment
                          </p>
                        </div>
                      ) : (
                        <div
                        // style={{ marginBottom: "200px" }}
                        >
                          <Popupnotanyapp />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {addModalData.secondbodyspinner && allAppsDataFetching ? (
                  <SecondaryBodySpinner />
                ) : null}
              </div>
            </ModalBody>
          )}
        </BottomScrollListener>
      </div>
      <ModalFooter>
        <button
          className="mergeApp-popup-cancel"
          onClick={() => resetAppSelection()}
        >
          Cancel
        </button>
        <svg
          onClick={() => {
            // reset selection
            unlink();
            clearMerging();
            setAddModalData({
              workSpaceSelected: null,
              productSelected: { value: "payPerApp", label: "Pay-per-app" },
            });
          }}
          className="mergeApp-popup-cancel-aero"
          xmlns="http://www.w3.org/2000/svg"
          width="20.759"
          height="15.408"
          viewBox="0 0 20.759 15.408"
        >
          <g
            id="Group_27465"
            data-name="Group 27465"
            transform="translate(-19.742 -22.295)"
          >
            <path
              id="Path_81245"
              data-name="Path 81245"
              d="M-13783.922-19217h19.346"
              transform="translate(13805.077 19247)"
              fill="none"
              stroke="#7782a1"
              stroke-width="2"
            />
            <path
              id="Path_82232"
              data-name="Path 82232"
              d="M14053.656,19255.426l-7,7,7,7"
              transform="translate(-14025.504 -19232.424)"
              fill="none"
              stroke="#7782a1"
              stroke-width="2"
            />
          </g>
        </svg>
        <div className="mergeApp-popup-done">
          {addModalData.mergeAppList.length > 0 &&
            getSelectedAppCountText(addModalData.mergeAppList)}
          {addModalData.isMerging ? (
            <button className="d-flex justify-content-center align-items-center">
              <div className="new-loader mob-done-loader"></div>
            </button>
          ) : (
            <button
              disabled={!addModalData.appsToLink.length}
              onClick={(e)=>handleLinkApps(e)}
            >
              Done
            </button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default MergeAppAddModal;
