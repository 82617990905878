import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import "./../../assets/css/Dashboard.css";
import "./../../assets/css/emailsupport.css";
import Header from "./../Header";
import Leftpanel from "./../Leftpanel";
import { Link, useLocation } from "react-router-dom";
import emailValidator from "email-validator";
import Tooltip from "../../Components/commonIcons/Tooltip";
import {
  Media,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
 
} from "reactstrap";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";

import { FormGroup, Label, Input } from "reactstrap";

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
// import Editor from "./EditorImplement";

import Title from "./Title";
import ImageBlock from "./ImageBlock";
import HeadingBlock from "./HeadingBlock";
import ParagraphBlock from "./ParagraphBlock";
import SpacerBlock from "./SpacerBlock";
import SeparatorBlock from "./SeparatorBlock";
import ButtonBlock from "./ButtonBlock";
import GalleryBlock from "./GalleryBlock";
import CodeBlock from "./CodeBlock";
import Preview from "./Preview";
import imagebackground from "./../../assets/images/dashboard/my-account.png";
import {
  cmsGetEachPageApi,
  cmsGetPagesApi,
  cmsGetParent,

  cmsSavePageApi,
  getAdvertisementDataForCMS,
  getPagesSettingApi,
  teamApiForCMS,
  uploadAnyVideotos3,
} from "../../Api/CmsApi/Cmsapi";
import { useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { parentPage } from "./CommonPopUp";
import "./cmsBuilderstyle.css";
import { errortoast } from "../Toaster";
import {  uploadAnyBase64Imagetos3 } from "../../Api/NewHomescreen/NewHomescreenApi";
import moment from "moment";
import VideoBlock from "./VideoBlock";
import FacebookVideo from "./FacebookVideo";
import YouTubeVideoBlock from "./YouTubeVideoBlock";
import VimeoVideoBlock from "./VimeoVidepBlock";
import { SingleSelectionPopUpForCustomApp } from "../Reusable/SingleSelectionPopUp";
import BannerAdBlock from "./BannerAdBlock";
import CustomAdBlock from "./CustomAdBlock";
import { GetPageSettings } from "../../Api/Dashboard/DashboardApi";
import SettingIcon from "../../Components/commonIcons/SettingIcon";
const Editpage = (props) => {
  const messageRefLeft = useRef();
  const leftSideRef = useRef(null);
  const rightSideRef = useRef(null);
  const [activeTab, setactiveTab] = useState(true);

  const [showcustomBlockpopup, setshowcustomBlockpopup] = useState(false);
  const [selectedItem, setselectedItem] = useState("");
  const [galleryToggle, setgalleryToggle] = useState("6");
  const [content, setcontent] = useState("");
  const [featuredImage, setfeaturedImage] = useState("");
  const [parentId, setparentId] = useState(0);
  const [parentName, setparentName] = useState("");
  const [parentTempName, setParentTempName] = useState("");
  const [tempParentId, setTempParentId] = useState(0);
  const [cmsData, setcmsData] = useState([]);
  const [isRowOpen, setisRowOpen] = useState([]);

  const [galleryTab, setgalleryTab] = useState("6");

  const [value, setValue] = useState("");
  const [isParentpopup, setIsParentpopup] = useState(false);
  const [allPage, setAllPage] = useState([]);
  const [title, setTitle] = useState("");
  const [spinner, setSpinner] = useState(true);
  const [spinner2, setSpinner2] = useState(true);
  const [isLoader, setIsLoader] = useState("d-none");
  const [submitval, setSubmitval] = useState("Publish");
  const [loaderimage, setLoaderimage] = useState(
    require("../../assets/images/signup/Loader_GIF.gif").default
  );
  const [isLoader2, setIsLoader2] = useState("d-none");
  const [submitval2, setSubmitval2] = useState("Save draft");
  const [loaderimage2, setLoaderimage2] = useState(
    require("../../assets/images/signup/Loader_GIF.gif").default
  );
  const [showMobilPreview, setShowhowMobilPreview] = useState(false);
  const [isLastItemOpen, setIsLastItemOpen] = useState(false);
  const [openItemIndex, setOpenItemIndex] = useState(null);
  const [searchPageVal, setSearchPageVal] = useState("");
  const [openMobileAppDropdown, setOpenMobileAppDropdown] = useState(false);
  const [statusOfPage, setStatusOfPage] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [authorId, setAuthorId] = useState(parseInt(localStorage.getItem("logged_in_user_id")));
  // const [dummyArr, setDummyArr] = useState(["1", "1", "1", "1"]);
  const [isAuthorPopup, setIsAuthorPopup] = useState(false);
  const [openMobileAuthorDropdown, setOpenMobileAuthorDropdown] = useState(false);
  const [mergeUserOwnerData, setMergeUserOwnerData] = useState([]);
  const [searchValForPopUp, setSearchValForPopUp] = useState("");
  const [openSingleSelectionPopUp, setOpenSingleSelectionPopUp] = useState(false);
  const [subData,setSubData] = useState([]);
  const [breadcrumbArr,setBreadcrumbArr] = useState([]);
  const [oldSubData,setOldSubData] = useState([]);
  const [oldBreadcrumbArr,setOldBreadcrumbArr] = useState([]);
  const [adsData, setAdsData] = useState([]);
  const [selectedPreviewDevice, setSelectedPreviewDevice] = useState("android");
  const [page, setPage] = useState(1);
	const [paginationSpinner , setPaginationSpinner] = useState(false);
	const [hitNextPagination , setHitNextPagination] = useState(false);
  const [sectionTopMargin, setSectionTopMargin] = useState("7.5");
  const [sectionBottomMargin, setSectionBottomMargin] = useState("7.5");
  const [sectionRightMargin, setSectionRightMargin] = useState("15");
  const [sectionLeftMargin, setSectionLeftMargin] = useState("15");
  const [imgShapeForDetails, setImgShapeForDetails] = useState("soft_corner");
  const [imgCenterForDetails, setImgCenterForDetails] = useState(true);
  

  const [appRightPanelExpand, setAppRightPanelExpand] = useState( localStorage.getItem("appSideBarCollapse") === true ||
  localStorage.getItem("appSideBarCollapse") === "true"
    ? true
    : false);


  let { pageID } = useParams("pageID");

  useEffect(() => {
    if ( parseInt(localStorage.getItem("website_technology")) !== 3 && window.location.pathname.includes("custom")) {
      window.location.href="/app/dashboard"
    
       }
    if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
    {
      document.title = ( pageID!==undefined && pageID!==null && pageID!=="create" && pageID!=="" ) ? "Edit page | AppMySite": "Create page | AppMySite";
    }
    else
    {
      document.title = ( pageID!==undefined && pageID!==null && pageID!=="create" && pageID!=="" ) ? "Edit page |" + ' ' +localStorage.getItem('agencyname'): "Create page |" + ' ' +localStorage.getItem('agencyname');
            
    }

    getAdvertisementDataForCMS(setAdsData);
    
    
    let limit = 99;
    let pageNo = 1;

    cmsGetPagesApi(
      setAllPage,
      btoa(localStorage.getItem("Appid")),
      setSpinner2,(allPageData)=>{
        if ( pageID!==undefined && pageID!==null && pageID!=="create" && pageID!=="" ) {
          cmsGetEachPageApi(
            setcmsData,
            pageID,
            setparentId,
            setTempParentId,
            setTitle,
            setSpinner,
            setParentTempName,
            setparentName ,
            setStatusOfPage,
            setUpdatedAt,
            setAuthorId,
            allPageData,
            setBreadcrumbArr,
            setSubData,
            setOldSubData,
            setOldBreadcrumbArr,
          );
    
        }
      },
      limit,
      pageNo,
      allPage,
      setHitNextPagination,
      setPaginationSpinner,
    );

    const params = {
      app_id: btoa(localStorage.getItem("Appid")),
    };

    teamApiForCMS(
      params,
      setMergeUserOwnerData
    );
    GetPageSettings(params,setSectionTopMargin,setSectionBottomMargin,setSectionLeftMargin,setSectionRightMargin,setImgCenterForDetails,setImgShapeForDetails)
    setTimeout(()=>{


    if ( pageID!==undefined && pageID!==null && pageID!=="create" && pageID!=="" ) {

      // cmsGetEachPageApi(
      //   setcmsData,
      //   pageID,
      //   setparentId,
      //   setTempParentId,
      //   setTitle,
      //   setSpinner,
      //   setParentTempName,
      //   setparentName ,
      //   setStatusOfPage,
      //   setUpdatedAt,
      //   setAuthorId,
      //   allPage,
      //   setBreadcrumbArr,
      //   setSubData,
      // );

    } else {
      let arr = [
        {
          item_type: "title",
          status:1,
          value: {
            title: { text: "" },
          },
          position: 0,
        },
        {
          item_type: "image",
          value: {},
          portal_value: {
            design_id:"",
            type: 1,
            source_file: "",
            image_link: "",
            resize_image_link: "",
            base64_image:"",
            base64_org_image:"",
          },
          status:1,
          position: 1,
          style: {
            aspect_ratio: "4:3",
          },
        },
        {
          item_type: "heading",
          value: { heading: { text: "" } },
          position: 2,
          status:1,
          style: {
            text_color: "",
            text_align: "left",

            bold_text: null,
            italic_text: null,
            underline: null,
          },
        },

        {
          item_type: "paragraph",
          status:1,
          value: { paragraph: { text: "<p>Craft a compelling narrative here. Share your thoughts, insights, or details related to your post’s topic. Engage your audience with valuable content that adds depth to your message. </p><p><br></p><p>Remember to keep it respectful and within the app store guidelines. Let’s start the conversation</p>" } },
          position: 3,
          style: { text_align: "", text_color: "" },
        },
        
      ];
      setcmsData(arr);
      setSpinner(false);
    }
  },5000)
    initialLoad();
  }, []);

  const toggle = (tab) => {
    // if (activeTab !== tab) {
      setactiveTab(!activeTab);
    // }
  };

  const handleDeviceChange = (e,from) => {
    setSelectedPreviewDevice(from);
  }

  const checkIfCustomAdBlockIsAdded = () => {
    let bool=false;
    for(let i=0; i<cmsData.length; i++){
      if(cmsData[i].item_type==="custom_ad"){
        bool=true;
        break;
      }
    }
    return bool;
  }


  const galleryToggleUpdate = (gtab) => {
    if (galleryTab !== gtab) {
      setgalleryTab(gtab);
    }
  };

  const customBlock = () => {
    setshowcustomBlockpopup(!showcustomBlockpopup);
    setselectedItem("");
  };

  // Initial Load

  const initialLoad = (from, dragDstIndx, val) => {
    let isRowOpen = [];

    for (let i = 0; i < cmsData.length; i++) {
      // if (isLastItemOpen === true && i === cmsData.length - 1) {
      //   isRowOpen.push(true);
      // } else {
      if (from !== undefined && i === dragDstIndx) {
        isRowOpen.push(val);
      } else {
        isRowOpen.push(false);
      }
    }
  
    // }
    setisRowOpen(isRowOpen);
  };

  const handleItem = (e) => {
    setselectedItem(e.target.value);
  };

  const handleAddItem = (e) => {
    // let counter = this.state.counter;
    e.preventDefault();
    if (selectedItem === "") return;
    // setOpenItemIndex(cmsData.length);
    // setIsLastItemOpen(true);

    let param;
    switch (selectedItem) {
      case "image":
        param = {
          item_type: "image",
          status:1,
          value: {
            // image: "",
            // source_file: "",
            // image_url: "",
          },
          portal_value: {
            design_id:"",
            type: 1,
            source_file: "",
            image_link: "",
            resize_image_link: "",
            base64_image:"",
            base64_org_image:"",
          },

          position: cmsData.length,
          style: {
            aspect_ratio: "4:3",
          },
        };

        break;
      case "gallery":
        param = {
          item_type: selectedItem,
          status:1,
          value: {},
          portal_value: [
            { design_id:"",
              source_file: null,
              image_link: "",
              resize_image_link: "",
              type: 1,
              base64_image:"",
              base64_org_image:"",
            },
            { design_id:"",
              source_file: null,
              image_link: "",
              resize_image_link: "",
              type: 1,
              base64_image:"",
              base64_org_image:"",
            },
            { design_id:"",
              source_file: null,
              image_link: "",
              resize_image_link: "",
              type: 1,
              base64_image:"",
              base64_org_image:"",
            },
            { design_id:"",
              source_file: null,
              image_link: "",
              resize_image_link: "",
              type: 1,
              base64_image:"",
              base64_org_image:"",
            },
          ],
          showOnlyArr: ["", "", "", ""],
          position: cmsData.length,
          style: { row: 2, column: 2, aspect_ratio: "4:3" },
        };
        break;
      case "heading":
        param = {
          item_type: selectedItem,
          status:1,
          value: { heading: { text: "" } },
          position: cmsData.length,
          style: {
            text_color: "",
            text_align: "left",

            bold_text: null,
            italic_text: null,
            underline: null,
          },
        };
        break;
      case "separator":
        param = {
          item_type: selectedItem,
          status:1,
          position: cmsData.length,
          value: {
            separator: "",
          },
          style: {
            color: "#808080",
            height: "10",
          },
          // value: [],
        };
        break;
      case "spacer":
        param = {
          item_type: selectedItem,
          status:1,
          position: cmsData.length,
          value: {
            spacer: "",
          },
          style: { height: "5" },
          // value: [],
        };
        break;
      case "button":
        param = {
          item_type: selectedItem,
          status:1,
          value: {
            button: {
              text: "Click here",
              link: "url",
              target: "",
              target_alias: "",
              // phone_code: "91",
            },
          },
          position: cmsData.length,
          style: {
            text_color: "#ffffff",
            button_color: "#1a1a1a",

            text_align: "",
          },
        };
        break;
      case "code":
        param = {
          item_type: selectedItem,
          status:1,
          value: {
            code: {
              text: "",
            },
          },
          position: cmsData.length,
          style: null,
        };
        break;
      case "paragraph":
        param = {
          item_type: selectedItem,
          status:1,
          value: { paragraph: { text: "" } },
          position: cmsData.length,
          style: { text_align: "", text_color: "" },
        };
        break;
      case "custom_video":
        param = {
          item_type: selectedItem,
          status:1,
          value: null,
          
          position: cmsData.length,
          style: {
            height:"",
            width:""
          },
          portal_value: { thumbnail: "" , source_file:"" }
        };
          break;
      case "facebook_video":
        param = {
          item_type: selectedItem,
          status:1,
          value: { facebook_video: { video_url:"" } },
          position: cmsData.length,
          style: null,
          portal_value:{
            is_link_err:false,
            link:""
          }
        };
        break;
      case "vimeo_video":
        param = {
          item_type: selectedItem,
          status:1,
          value: { vimeo_video: { video_id: "" } },
          position: cmsData.length,
          style: null,
          portal_value:{
            is_link_err:false,
            link:""
          }
        };
        break;
        case "youtube_video":
          param = {
            item_type: selectedItem,
            status:1,
            value: { youtube_video: { video_id: "" } },
            position: cmsData.length,
            style: null,
            portal_value:{
              is_link_err:false,
              link:""
            }
          };
          break;
      case "banner_ad":
        param = {
          item_type: selectedItem,
          status:1,
          value: {ad_id:"",ad_name:""},
          position: cmsData.length,
          style:null,
        
        };
        break;
      case "custom_ad":
          param = {
            item_type: selectedItem,
            status:1,
            value: {ad_id:"",ad_name:""},
            position: cmsData.length,
            style:null,
          
          };
          break;
    }

    let cmsArray = [...cmsData];

    cmsArray.push(param);

    setcmsData(cmsArray);
    setshowcustomBlockpopup(!showcustomBlockpopup);
    setselectedItem("");
  };

  const deleteRow = (e, key) => {
    e.preventDefault();
    // setIsLastItemOpen(false);
    let newArr = [...cmsData];
    newArr.splice(key, 1);
    newArr.map((row, i) => {
      row.position = i;
    });

    //let isRowOpen = [];
    // for (let a = 1; a < cmsData.length; a++) {
    //   isRowOpen.push(false);
    // }

    //setisRowOpen(isRowOpen);
    setcmsData(newArr);
  };

  const duplicateItem = (e, eachBlock) => {
    // setIsLastItemOpen(true);
    let cmsArray = JSON.parse(JSON.stringify(cmsData));
    const newEachBlock = JSON.parse(JSON.stringify(eachBlock));
    // if(newEachBlock.item_type==="image"){
    // newEachBlock.portal_value.base64_org_image="";
    // newEachBlock.portal_value.base64_image="";
    // }
    // if(newEachBlock.item_type==="gallery"){
    //   newEachBlock.portal_value && newEachBlock.portal_value.map((eachGallery)=>{
    //     eachGallery.base64_org_image="";
    //     eachGallery.base64_image="";
    //   })
    // }
    cmsArray.push(newEachBlock);
    cmsArray.map((row, key) => {
      row.position = key;
    });
    setcmsData(cmsArray);
  };

  // const tempFun = (element) =>{
  //   
  //   if (element) {
  //   setTimeout(()=>{
  //     element.scrollIntoView({ 
  //       behavior: "smooth",
  //        block: "center",
  //        inline: "nearest", });
  //   },100)
  // }
    
  // }



  

  
    const handleItemClickRight = (index) => {
     if(!window.matchMedia("(max-width: 1200px)").matches){
      const previewItemElement = rightSideRef.current.children[index];
   
 
      if ( previewItemElement) {
    
      
        previewItemElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
     }
      
    };


    const handleItemClickLeft = (index,callback) => {
      if(!window.matchMedia("(max-width: 1200px)").matches){
      const itemElement = leftSideRef.current.children[index];
     

      
      if (itemElement ) {
      
      itemElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        
      }
      setTimeout(()=>{

        callback();
      },500)
      }

    };
  

  const handleIsRowOpen = (e, key,from) => {
  


    setOpenItemIndex(key);
    let updateRowOpen = [...isRowOpen];
    updateRowOpen = isRowOpen.map((res, j) => (key === j ?from==="linkErr"?true: !res : false));

    setisRowOpen(updateRowOpen);

 
    setTimeout(()=>{
      handleItemClickLeft(key,()=>{
      handleItemClickRight(key) ;
       }) ;
     },500)


    // let element = document.getElementById(key+"scrollable-item-now");

    // if (element) {
    //  setTimeout(()=>{
    //   element.scrollIntoView({ 
    //     behavior: "smooth",
    //      block: "start",
    //      inline: "nearest",
    //      });
    //     
    //  })
    
    // }
    
  };

  //Finding first image to send into API
  const firstImage = () => {
    let indx;
    for (let i = 0; i < cmsData.length; i++) {
      if (cmsData[i].item_type === "image") {
        indx = i;
        break;
      }
    }
    return indx;
  };

  //Finding first paragraph to send into API
  const firstPara = () => {
    let indx = null;
    for (let i = 0; i < cmsData.length; i++) {
      if (cmsData[i].item_type === "paragraph") {
        indx = i;

        break;
      }
    }
    return indx;
  };

  // Sending Pages Data to API
  const handleSubmitPagesData = (e, status) => {
    e.preventDefault();

    const YouTubeGetID = (link) =>{
      let video_id="";
      video_id = link.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      return (video_id[2] !== undefined) ? video_id[2].split(/[^0-9a-z_\-]/i)[0] : video_id[0];
   }

   const VimeoGetID = (link) =>{
    let video_id="";
    
    var match = /vimeo.*\/(\d+)/i.exec( link );

    if ( match ) {
      
      video_id = match[1];
    }
    return video_id;
 }

//  const FBGetID = (link) =>{
//   let video_id="";

//   var match = /^((?:https?\:\/\/|www\.)(?:facebook)(?:.com\/)(?:[-a-z0-9]+\.)*[-a-z0-9]+.*?)$/i.exec( link );
// 
//   if ( match ) {
    
//     video_id = match[1];
//   }
//   return video_id;
// }

 
 
    const btnSpinnerProcess  = () =>{
      setIsLoader("d-none");
      setSubmitval("Publish");
      setIsLoader2("d-none");
      setSubmitval2("Save draft");
    }
    if (status === "publish") {
      setIsLoader("d-block");
      setSubmitval("");
    } else if (status === "draft") {
      setIsLoader2("d-block");
      setSubmitval2("");
    }

    let newArr = [...cmsData];
    newArr.map((row, indx) => {
      if (row.item_type === "gallery") {
        if (
          row.portal_value.length >
          parseInt(row.style.column) * parseInt(row.style.row)
        ) {
          row.portal_value.length =
            parseInt(row.style.column) * parseInt(row.style.row);
        }
        row.portal_value && row.portal_value.length>0 && row.portal_value.map((eachGallery)=>{
           //Upoading Resize Image on s3
          if (
            eachGallery.base64_image && eachGallery.type===2
             ) {
               const params = {
                 data: eachGallery.base64_image,
                 filetype: "png",
       
                 path:  localStorage.getItem("user_id") +
                 "/" +
                 localStorage.getItem("Appid") +
                 process.env.REACT_APP_CMS_Media,
               };
               uploadAnyBase64Imagetos3(params,eachGallery.resize_image_link.replace(".png",""))
             
               eachGallery.base64_image="";
               
               




             }
            //Upoading Original Image on s3

            //  if (
            //   eachGallery.base64_org_image && eachGallery.type===2
            //    ) {
            //      const params = {
            //        data: eachGallery.base64_org_image,
            //        filetype: "png",
         
            //        path:  localStorage.getItem("user_id") +
            //        "/" +
            //        localStorage.getItem("Appid") +
            //        process.env.REACT_APP_CMS_Media,
            //      };
            //      uploadAnyBase64Imagetos3(params,eachGallery.image_link.replace(".png",""))
               
            //      eachGallery.base64_org_image="";
                 
                 
  
  
  
  
            //    }
               eachGallery.base64_org_image="";

        })
        

      }
      else if (row.item_type === "image") {
        if (
       row.portal_value.base64_image && row.portal_value.type===2
        ) {
         
          const params = {
            data: row.portal_value.base64_image,
            filetype: "png",
  
            path:  localStorage.getItem("user_id") +
            "/" +
            localStorage.getItem("Appid") +
            process.env.REACT_APP_CMS_Media,
          };
          uploadAnyBase64Imagetos3(params,row.portal_value.resize_image_link.replace(".png",""))
        
          row.portal_value.base64_image="";
        }

        //Upoading Original Image on s3
        // if (
        //   row.portal_value.base64_org_image && row.portal_value.type===2
        //    ) {
            
        //      const params = {
        //        data: row.portal_value.base64_org_image,
        //        filetype: "png",
     
        //        path:  localStorage.getItem("user_id") +
        //        "/" +
        //        localStorage.getItem("Appid") +
        //        process.env.REACT_APP_CMS_Media,
        //      };
        //      uploadAnyBase64Imagetos3(params,row.portal_value.image_link.replace(".png",""))
           
        //      row.portal_value.base64_org_image="";
        //    }
   
        row.portal_value.base64_org_image="";

      } 
      else if (row.item_type === "youtube_video") {
        if(!row.portal_value.link){
        
         row.portal_value.is_link_err=true;
        }else{
          row.value.youtube_video.video_id=YouTubeGetID(row.portal_value.link);
        } 
        }
        else if (row.item_type === "vimeo_video") {
          if(!row.portal_value.link){
          
           row.portal_value.is_link_err=true;
          }else{
            row.value.vimeo_video.video_id=VimeoGetID(row.portal_value.link);
          }
          }
          else if (row.item_type === "facebook_video") {
            if(!row.portal_value.link){
            
             row.portal_value.is_link_err=true;
            }else{
              row.value.facebook_video.video_url=row.portal_value.link;
              // FBGetID(row.portal_value.link);
            } 
            }

    
    });
    setcmsData(newArr);

    let error = false;
    let error2 = false;
    let emailErr = false;
    let error3 = false ;

    let youTubeUrlErr = false;
    let vimeoUrlErr = false;
    let facebookUrlErr = false ;

    for(let i=0; i<cmsData.length; i++){
      if (cmsData[i].item_type === "gallery") {
        if (
          cmsData[i].style.row === "" ||
          cmsData[i].style.row === undefined ||
          cmsData[i].style.row === null ||
          cmsData[i].style.column === "" ||
          cmsData[i].style.column === undefined ||
          cmsData[i].style.column === null
        ) {
          error = true;
        }
      }
      else if (cmsData[i].item_type === "spacer") {
        if (
          cmsData[i].style.height === undefined ||
          cmsData[i].style.height === "" ||
          cmsData[i].style.height === null
        ) {
          error3 = true;
        }
      }
      else if (cmsData[i].item_type === "separator") {
        if (
          cmsData[i].style.height === undefined ||
          cmsData[i].style.height === "" ||
          cmsData[i].style.height === null
        ) {
          error2 = true;
        }
      } else if (cmsData[i].item_type === "button") {
        if (cmsData[i].value.button.link === "email") {
          if (!emailValidator.validate(cmsData[i].value.button.target)) {
            emailErr = true;
          }
        }
      }
      else if (cmsData[i].item_type === "youtube_video") {
        if(cmsData[i].portal_value.is_link_err!==false || !cmsData[i].portal_value.link){
          youTubeUrlErr=true;
          handleIsRowOpen("",i,"linkErr");
          btnSpinnerProcess();
          break;
        } 
        }
        else if (cmsData[i].item_type === "vimeo_video") {
          if(cmsData[i].portal_value.is_link_err!==false || !cmsData[i].portal_value.link){
            vimeoUrlErr=true;
            handleIsRowOpen("",i,"linkErr");
            btnSpinnerProcess();
            break;
          }
          }
          else if (cmsData[i].item_type === "facebook_video") {
            if(cmsData[i].portal_value.is_link_err!==false || !cmsData[i].portal_value.link){
              facebookUrlErr=true;
              handleIsRowOpen("",i,"linkErr");
              btnSpinnerProcess();
              break;
            }
            }
    }

    const params = {
      app_id: parseInt(localStorage.getItem("Appid")),
      user_id: parseInt(localStorage.getItem("user_id")),
      author_user_id:authorId,
      // client_id: 389,
      title: title,
      description:
        firstPara() !== null && firstPara() !== undefined
          ? cmsData[firstPara()].value.paragraph.text
          : "",
      content: cmsData,
      parent: parentId,
      parent_name: parentName,
      featured_media: "", // cmsData[firstImage()].value.image,
      source_file:
        firstImage() !== null && firstImage() !== undefined
          ? cmsData[firstImage()].portal_value.source_file
          : "",
      status: status,
      override_post_master_settings:0,
      page_id:
      ( pageID!==undefined && pageID!==null && pageID!=="create" && pageID!=="" ) ? pageID : "",
    };



    if (params.title === undefined || params.title.trim() === "") {
      errortoast("Title can't be empty");
      btnSpinnerProcess()
    } else if (error) {
      errortoast("Select atleast one row and one column");
      btnSpinnerProcess()
    } else if (error2) {
      errortoast("Please enter valid seperator height");
      btnSpinnerProcess()
    }
    
    else if (error3) {
      errortoast("Please enter valid spacer height");
      btnSpinnerProcess()
    }
    
    else if (emailErr) {
      errortoast("Please enter valid email");
      btnSpinnerProcess()
    } 
    
    else if (params.parent===params.page_id) {
      errortoast("A page can't be it's own child");
      btnSpinnerProcess()
    }
    else if (youTubeUrlErr) {
      // errortoast("Please enter a valid YouTube video url");
      btnSpinnerProcess()
    }
    else if (facebookUrlErr) {
      // errortoast("Please enter a valid Facebook video url");
      btnSpinnerProcess()
    }
    else if (vimeoUrlErr) {
      // errortoast("Please enter a valid Vimeo video url");
      btnSpinnerProcess()
    }
    
    else {
      
      cmsSavePageApi(
        params,
        setIsLoader,
        setSubmitval,
        setIsLoader2,
        setSubmitval2,
        redirectFun,
        status,
        setUpdatedAt
      );
    }
  };

  // Drag an drop
  const reorderList = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.map((row, indx) => {
      row.position = indx;
    });
    return result;
  };

  const handleDragEnd = (result) => {
    if (
      result.destination === null ||
      result.destination === undefined ||
      result.source === undefined
    ) {
      return;
    } else {
      setcmsData(
        reorderList(cmsData, result.source.index, result.destination.index)
      );
      initialLoad(
        "drag",
        result.destination.index,
        isRowOpen[result.source.index]
      );
    }
  };

  //Rendering specific(selected) block
  const handleSwitch = (cmsRow, indx, provided) => {
   
    switch (cmsRow.item_type) {
       case "title":
         return (
          <Title
      indx={indx}
      cmsArray={cmsData}
      setcmsData={setcmsData}
      isRowOpen={isRowOpen}
      handleIsRowOpen={handleIsRowOpen}
      // eachBlock={cmsRow}
      provided={provided}
      from={"page"}
      title={title}
      setTitle={setTitle}
    />
       );
      case "image":
        return (
         

          
          <ImageBlock
            indx={indx}
            cmsArray={cmsData}
            setcmsData={setcmsData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={deleteRow}
            duplicateItem={duplicateItem}
            eachBlock={cmsRow}
            provided={provided}
            searchValForPopUp={searchValForPopUp}
            setSearchValForPopUp={setSearchValForPopUp}
            parentComponent={"page"}
         
          />
       
        );

      case "heading":
        return (
        
          <HeadingBlock
            indx={indx}
            cmsArray={cmsData}
            setcmsData={setcmsData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={deleteRow}
            duplicateItem={duplicateItem}
            eachBlock={cmsRow}
            provided={provided}
          />
      
        );

      case "paragraph":
        return (

          <ParagraphBlock
            indx={indx}
            cmsArray={cmsData}
            setcmsData={setcmsData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={deleteRow}
            duplicateItem={duplicateItem}
            value={value}
            setValue={setValue}
            eachBlock={cmsRow}
            provided={provided}
          />
     
        );

      case "spacer":
        return (

          <SpacerBlock
            indx={indx}
            cmsArray={cmsData}
            setcmsData={setcmsData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={deleteRow}
            duplicateItem={duplicateItem}
            eachBlock={cmsRow}
            provided={provided}
          />
      
        );

      case "separator":
        return (
          
          <SeparatorBlock
            indx={indx}
            cmsArray={cmsData}
            setcmsData={setcmsData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={deleteRow}
            duplicateItem={duplicateItem}
            eachBlock={cmsRow}
            provided={provided}
          />
        
        );

      case "button":
        return (
          
          <ButtonBlock
            indx={indx}
            cmsArray={cmsData}
            setcmsData={setcmsData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={deleteRow}
            duplicateItem={duplicateItem}
            eachBlock={cmsRow}
            allPage={allPage}
            provided={provided}
          />
        
        );

      case "gallery":
        return (
        
          <GalleryBlock
            indx={indx}
            cmsArray={cmsData}
            setcmsData={setcmsData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={deleteRow}
            duplicateItem={duplicateItem}
            galleryToggle={galleryToggle}
            setgalleryToggle={setgalleryToggle}
            galleryTab={galleryTab}
            setgalleryTab={setgalleryTab}
            eachBlock={cmsRow}
            galleryToggleUpdate={galleryToggleUpdate}
            // dummyArr={dummyArr}
            // setDummyArr={setDummyArr}
            searchValForPopUp={searchValForPopUp}
            setSearchValForPopUp={setSearchValForPopUp}
            provided={provided}
          />
        
        );

      case "code":
        return (
         
          <CodeBlock
            indx={indx}
            cmsArray={cmsData}
            setcmsData={setcmsData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={deleteRow}
            duplicateItem={duplicateItem}
            eachBlock={cmsRow}
            provided={provided}
          />
        
        );
        case "custom_video":
          return (
          
            <VideoBlock
            indx={indx}
            cmsArray={cmsData}
            setcmsData={setcmsData}
            isRowOpen={isRowOpen}
            handleIsRowOpen={handleIsRowOpen}
            deleteRow={deleteRow}
            duplicateItem={duplicateItem}
            eachBlock={cmsRow}
            provided={provided}
            searchValForPopUp={searchValForPopUp}
            setSearchValForPopUp={setSearchValForPopUp}
            from={"page"}
            />
        
          );
          case "facebook_video":
            return (
            
              <FacebookVideo
              indx={indx}
              cmsArray={cmsData}
              setcmsData={setcmsData}
              isRowOpen={isRowOpen}
              handleIsRowOpen={handleIsRowOpen}
              deleteRow={deleteRow}
              duplicateItem={duplicateItem}
              eachBlock={cmsRow}
              provided={provided}
          
              />
          
            );
            case "youtube_video":
              return (
              
                <YouTubeVideoBlock
                indx={indx}
                cmsArray={cmsData}
                setcmsData={setcmsData}
                isRowOpen={isRowOpen}
                handleIsRowOpen={handleIsRowOpen}
                deleteRow={deleteRow}
                duplicateItem={duplicateItem}
                eachBlock={cmsRow}
                provided={provided}
               
                />
            
              );
              case "vimeo_video":
                return (
                
                  <VimeoVideoBlock
                  indx={indx}
                  cmsArray={cmsData}
                  setcmsData={setcmsData}
                  isRowOpen={isRowOpen}
                  handleIsRowOpen={handleIsRowOpen}
                  deleteRow={deleteRow}
                  duplicateItem={duplicateItem}
                  eachBlock={cmsRow}
                  provided={provided}
                 
                  />
                
              
                );
                case "banner_ad":
                  return (
                    <BannerAdBlock
                      indx={indx}
                      cmsArray={cmsData}
                      setcmsData={setcmsData}
                      isRowOpen={isRowOpen}
                      handleIsRowOpen={handleIsRowOpen}
                      deleteRow={deleteRow}
                      duplicateItem={duplicateItem}
                      eachBlock={cmsRow}
                      adsData={adsData}
                      setAdsData={setAdsData}
                      provided={provided}
                    />
                  );
                  case "custom_ad":
                    return (
                      <CustomAdBlock
                      indx={indx}
                      cmsArray={cmsData}
                      setcmsData={setcmsData}
                      isRowOpen={isRowOpen}
                      handleIsRowOpen={handleIsRowOpen}
                      deleteRow={deleteRow}
                      duplicateItem={duplicateItem}
                      eachBlock={cmsRow}
                      adsData={adsData}
                      setAdsData={setAdsData}
                      provided={provided}
                      />
                    );
    }
  };
  

  // Parent PopUp selection
  const handleParentPop = (e) => {
   
    setIsParentpopup(!isParentpopup);
  };

  //Saving parent Id
  const handleParentChange = (e, pID, title) => {
    // setparentId(pID)
    // setparentName(title)
    setTempParentId(pID);
    setParentTempName(title);
    setOpenMobileAppDropdown(!openMobileAppDropdown)
   
  };


  const handleMobileAppDropdown = (e) => {
    setOpenMobileAppDropdown(!openMobileAppDropdown)
  };


  // Redirect fun
  const redirectFun = (page_id, status) => {
    if (status === "draft") {
      props.history.push(`/app/page/${page_id}`);
      setStatusOfPage(status);
    } else {
      props.history.push("/app/pages");
    }
  };



  const   handleMobileAuthorDropdown = (e) => {
    setOpenMobileAuthorDropdown(!openMobileAuthorDropdown)
    
   };

   const handleAuthorPopUp = (e) => {
   
    setIsAuthorPopup(!isAuthorPopup);
  };

  const handleAuthorChange = (e,value) => {
    setAuthorId(value);
    setOpenMobileAuthorDropdown(false);
    // setPostRelatedTo(value)
   
  };

   const returnSelectedAuthorName =(author_id)=>{
    let name=""
    mergeUserOwnerData && mergeUserOwnerData.map((res)=>{
    if(res.user_id===author_id){
      name=res.name
    }
  })
  return name ;
  }

  const returnSelectedImagePath =(author_id)=>{
    let path=""
    mergeUserOwnerData && mergeUserOwnerData.map((res)=>{
      if(res.user_id===author_id){
        path=res.profile_image &&res.profile_image.indexOf("https")>-1 ?res.profile_image  : process.env.REACT_APP_Image_Path+res.user_id+
        process.env.REACT_APP_Profile+res.profile_image
      }
  })
  return path ;
  }

  const handleOpenSelectionPopUp = (e,from) => {
    setOpenSingleSelectionPopUp(!openSingleSelectionPopUp);

    if(from === "cancel"){
      setSubData(oldSubData);
      setBreadcrumbArr(oldBreadcrumbArr);
      setTempParentId(parentId);
      setParentTempName(parentName);
    }else{
      if(from==="open")
      {
        if(!subData || subData.length === 0){
          setSubData(allPage);
        }
        setparentId(tempParentId);
        setparentName(parentTempName);
        setOldSubData(subData);
        setOldBreadcrumbArr(breadcrumbArr);
      }

    }
  }

  const handleBottomScrollListner = () => {
    if (paginationSpinner === false) {
      if (hitNextPagination) 
      {
          let limit = 99;
          let pageNo = page + 1;
          setPage(pageNo);
          setPaginationSpinner(true);
          cmsGetPagesApi(
            setAllPage,
            btoa(localStorage.getItem("Appid")),
            setSpinner,
            (data,mergeData)=>{
              setSubData(mergeData)
            },
            limit,
            pageNo,
            allPage,
            setHitNextPagination,
            setPaginationSpinner,
          );
      }
  }
 }
  

  const handleRightPanelExpand = (value) => {

    setAppRightPanelExpand(value);
  }

  useMemo(() => initialLoad(), [cmsData.length]);

  return (
    <>
      <div className="d-md-block">
        <Header customclass={"header-responsive-new"} />
      </div>
      {/* Mobile View */}
      {/* Mobile View End*/}
      <section className="dasboard_page cms-module mobile-display mt-4 mt-md-0">
       <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
       <div className={`right-panel-gap ${(localStorage.getItem("appSideBarCollapse") !== undefined &&
                      localStorage.getItem("appSideBarCollapse") !== null&&
                      localStorage.getItem("appSideBarCollapse") === "true") ?
                          " right-panel-gap-expand": ""}`}>

        <div className="pageshead">
              <div>
                <h1>{( pageID!==undefined && pageID!==null && pageID!=="create" && pageID!=="" )?"Edit page":"New page"}</h1>
              </div>
              <div className="createpage">
              {
                  updatedAt &&
                
              <p className="profile-latest-update d-none d-xl-block">Updated {moment(
                          updatedAt
                        ).format("D MMMM YYYY, HH:mm")}</p>
              }
                <div>
                  <div>
                  {
                      activeTab === true ? 
                    
                //     <div className="consent-setting-clicked" style={{marginRight: "20px",cursor:"default"}}>
                //             <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                //        <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                //     <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                //     <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="#7782a1" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                //   </g>
                // </svg>
                //             </div>
                <></>
                    :
                  <div className="notification-setting " style={{marginRight: "20px"}}  onClick={() => {
                    toggle();
                  }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                  <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                    <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                    <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                  </g>
                </svg>
    

                        </div>
                    }


                  {/* <button
                      className={activeTab===true?"setting-bt-on":" setting-bt-off"}
                      onClick={() => {
                        toggle();
                      }}
                    >
                      <div className="setting-first-shadow"></div>
                   <div className="setting-second-shadow"></div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="23.5" height="23.499" viewBox="0 0 23.5 23.499">
                        <g id="Group_38368" data-name="Group 38368" transform="translate(-1598.25 -88.901)">
                          <path id="Path_99646" data-name="Path 99646" d="M11.513,22.5a10.829,10.829,0,0,0,1.266-.074.615.615,0,0,0,.542-.642,2.437,2.437,0,0,1,1.488-2.388,2.278,2.278,0,0,1,.887-.171,2.527,2.527,0,0,1,1.855.788.616.616,0,0,0,.837.067,10.968,10.968,0,0,0,1.739-1.76.615.615,0,0,0-.066-.829,2.432,2.432,0,0,1,1.592-4.231h.056a.73.73,0,0,0,.72-.542,11,11,0,0,0,0-2.5.592.592,0,0,0-.613-.54h-.022A2.543,2.543,0,0,1,19.4,8.19a2.437,2.437,0,0,1,.613-2.744.614.614,0,0,0,.066-.836A10.919,10.919,0,0,0,18.3,2.851a.606.606,0,0,0-.377-.131.615.615,0,0,0-.454.2,2.532,2.532,0,0,1-1.855.785,2.259,2.259,0,0,1-.868-.165A2.449,2.449,0,0,1,13.238,1.2a.614.614,0,0,0-.544-.63,11.023,11.023,0,0,0-2.474,0,.615.615,0,0,0-.539.643A2.443,2.443,0,0,1,8.195,3.6a2.318,2.318,0,0,1-.89.172,2.524,2.524,0,0,1-1.853-.788.615.615,0,0,0-.837-.069,10.968,10.968,0,0,0-1.739,1.76.615.615,0,0,0,.066.829A2.43,2.43,0,0,1,1.279,9.733a.718.718,0,0,0-.708.54,11.037,11.037,0,0,0,0,2.5.617.617,0,0,0,.6.542h.181A2.464,2.464,0,0,1,3.6,14.808a2.438,2.438,0,0,1-.615,2.743.614.614,0,0,0-.066.837A11,11,0,0,0,4.7,20.145a.616.616,0,0,0,.83-.069,2.535,2.535,0,0,1,1.853-.785,2.3,2.3,0,0,1,.87.165A2.443,2.443,0,0,1,9.76,21.8a.614.614,0,0,0,.545.628A11.109,11.109,0,0,0,11.513,22.5Z" transform="translate(1598.501 89.151)" fill="" stroke="" stroke-linejoin="round" stroke-width="1.5"/>
                          <path id="Path_99647" data-name="Path 99647" d="M9.818,5.846a4,4,0,1,1-4,4A4,4,0,0,1,9.818,5.846Z" transform="translate(1600.182 90.804)" fill="rgba(256,256,256,1)" stroke="" stroke-linejoin="round" stroke-width="1.5"/>
                        </g>
                      </svg>

                    </button> */}
                    <div
                          onClick={() => {
                            toggle();
                          }}
                          className="cms-editpage-btn"
                        >
                          <SettingIcon
                           
                           isActive={!activeTab}
                          />
                        </div>

                    <button
                      onClick={() => {
                        setShowhowMobilPreview(!showMobilPreview);
                      }}
                      className="cmspreviewbtn d-none"
                    >
                      Preview
                    </button>
                    <button
                    disabled={ (isLoader2==="d-block" || isLoader==="d-block") }
                    className={`d-none d-xl-flex ${(isLoader==="d-block")?"saveasdraft-disable":"saveasdraft"}`}
                      onClick={(e) => handleSubmitPagesData(e, "draft")}
                      style={{
                        // backgroundColor:localStorage.getItem('button_background_color'),
                        border:'1px solid '+localStorage.getItem('button_background_color'),
                        color:localStorage.getItem('button_text_color'),
                        "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                        "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
                        "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                        }}

                    >
                      <div className={isLoader2}>
                        <div className="new-blue-loader"></div>
                      </div>
                     <p
                    style={{
                      color:localStorage.getItem('button_background_color'),
                     }}
                     >{window.matchMedia("(max-width: 1200px)").matches
                        ? isLoader2 === "d-none"
                          ? "Save"
                          : ""
                        : submitval2}</p> 
                    </button>

                    <button
                     disabled={ (isLoader2==="d-block" || isLoader==="d-block") }
                     className={`d-none d-xl-flex ${(isLoader2==="d-block")?"creatnew-disable":"createnew"}`}
                      onClick={(e) => handleSubmitPagesData(e, "publish")}
                      style={{
                        backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                      }}
                    >
                      <div className={isLoader}>
                        <div className="new-loader"></div>
                      </div>
                      <span style={{
                        marginTop:"2px"
                      }}
                    
                      >{statusOfPage === "publish" ?isLoader==="d-none"? "Save":"" : submitval}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

          <div className="whitebg cmspagelayout">
            
            <div className="custom-page-preview">
              <div className="row">
                {((showMobilPreview === false &&
                  window.matchMedia("(max-width: 1200px)").matches) ||
                  window.matchMedia("(min-width: 1200px)").matches) && (
                  <div className="col-md-12 col-xl-6">
                    <div className="page-editor" style={{position: "relative",height:"80vh"}}>

                    {spinner === true ? (
                                  <div className="newspinner">
                                  <svg xmlns="http://www.w3.org/2000/svg" 
                            xlink="http://www.w3.org/1999/xlink" 
                            style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
                            width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                            <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                            <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                              <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                              style={{animationplaystate: "running", animationdelay: "0s"}}>
                              </animateTransform>
                            </circle>
                            </g>
                            <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                            <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
                            <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                            style={{animationplaystate: "running", animationdelay: "0s"}}>
                            </animateTransform>
                            </circle>
                            </g>
                            <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                            <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
                            <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                            style={{animationplaystate: "running", animationdelay: "0s"}}>
                    
                            </animateTransform>
                            </circle>
                            </g>
                            <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                            <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
                            <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                            style={{animationplaystate: "running", animationdelay: "0s"}}>
                    
                            </animateTransform>
                            </circle>
                            </g>
                            </svg>
                                </div>
                                ) : (
                      <div>
                        <div className="pages-options">
                          {/* <Nav tabs className="pages-tabs">
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "1",
                                })}
                                onClick={() => {
                                  toggle("1");
                                }}
                              >
                                Content
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "2",
                                })}
                                onClick={() => {
                                  toggle("2");
                                }}
                              >
                                Settings
                              </NavLink>
                            </NavItem>
                          </Nav> */}
                          {/* <Link className="addmoreblock" onClick={customBlock}>
                            <img
                              className="mr-2"
                              src={
                                require("../../assets/images/dashboard/cms/blue-plus.png")
                                  .default
                              }
                            />
                            Add Block
                          </Link> */}

                         

                          <Modal
                            isOpen={showcustomBlockpopup}
                            className="addblockpopup"
                            centered
                            toggle={customBlock}
                          >
                            <ModalHeader toggle={customBlock}>
                            <div className="crossmodal mt-1">	
              	
                <svg  onClick={customBlock} xmlns="http://www.w3.org/2000/svg" width="10.96" height="10.961" viewBox="0 0 10.96 10.961">	
                  <g id="Group_38368" data-name="Group 38368" transform="translate(-1914.917 -169.92)">	
                    <path id="Path_82532" data-name="Path 82532" d="M-16354-3830v14" transform="translate(10781.152 14442.694) rotate(45)" fill="none" stroke="#c8cedb" stroke-width="1.5"/>	
                    <path id="Path_82533" data-name="Path 82533" d="M-16354-3830v14" transform="translate(-12346.896 9036.155) rotate(135)" fill="none" stroke="#c8cedb" stroke-width="1.5"/>	
                  </g>	
                </svg>	
                  
              </div>
                            Add section
                            </ModalHeader>
                            <ModalBody toggle={customBlock}>
                              <FormGroup
                                tag="fieldset"
                                className="select-block-type"
                              >
                                <FormGroup>
                                  <Label className="addblock-ratio">
                                    <Input
                                      type="radio"
                                      name="addblockradio"
                                      value="heading"
                                      onClick={(e) => handleItem(e)}
                                    />

                                    <div className="image">
                                      <div className="d-block text-center">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="20.003" height="21" viewBox="0 0 20.003 21">
                                            <path id="Union_1758" data-name="Union 1758" d="M10456,17357v-3h2v-15h3v15h2v3Zm-13,0v-3h2v-15h3v6h10v3h-10v6h2v3Zm13-18v-3h7v3Zm-13,0v-3h7v3Z" transform="translate(-10442.999 -17336)" fill=""/>
                                          </svg>
                                        <p>Heading</p>
                                      </div>
                                    </div>
                                  </Label>
                                  <span className="block-type-tooltip1">Highlight important sections with clear and bold headings.</span>
                                </FormGroup>
                                <FormGroup>
                                  <Label className="addblock-ratio">
                                    <Input
                                      type="radio"
                                      name="addblockradio"
                                      value="paragraph"
                                      onClick={(e) => handleItem(e)}
                                    />
                                    <div className="image">
                                      <div className="d-block text-center">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22">
                                          <path id="Union_176" data-name="Union 176" d="M4.75,22V18.857H7.917V3.143H3.167V6.285H0V0H19V6.285H15.833V3.143h-4.75V18.857H14.25V22Z" fill=""/>
                                        </svg>
                                        <p>Paragraph</p>
                                      </div>
                                    </div>
                                  </Label>
                                  <span className="block-type-tooltip1">Add a block of text to describe your product or service.</span>
                                </FormGroup>
                                <FormGroup>
                                  <Label className="addblock-ratio">
                                    <Input
                                      type="radio"
                                      name="addblockradio"
                                      value="image"
                                      onClick={(e) => handleItem(e)}
                                    />
                                    <div className="image">
                                      <div className="d-block text-center">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="25.001" height="22" viewBox="0 0 25.001 22">
                                          <path id="Exclusion_356" data-name="Exclusion 356" d="M22.657,22H2.343A2.4,2.4,0,0,1,0,19.556V7.333a2.408,2.408,0,0,1,1.96-2.41l3.386-.589,1.072-2.8A2.337,2.337,0,0,1,8.593,0h7.813a2.338,2.338,0,0,1,2.176,1.536l1.072,2.8,3.387.589A2.407,2.407,0,0,1,25,7.333V19.556A2.4,2.4,0,0,1,22.657,22ZM12.413,6.875a5.469,5.469,0,1,0,5.469,5.469A5.475,5.475,0,0,0,12.413,6.875Zm0,8.751a3.282,3.282,0,1,1,3.281-3.281A3.285,3.285,0,0,1,12.413,15.626Z" transform="translate(0.001)" fill=""/>
                                        </svg>
                                        <p>Image</p>
                                      </div>
                                    </div>
                                  </Label>
                                  <span className="block-type-tooltip2"> Upload an image to enhance the visual appeal of your content.</span>
                                </FormGroup>
                                <FormGroup>
                                  <Label className="addblock-ratio">
                                    <Input
                                      type="radio"
                                      name="addblockradio"
                                      value="gallery"
                                      onClick={(e) => handleItem(e)}
                                    />
                                    <div className="image">
                                      <div className="d-block text-center">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="25.001" height="25.003" viewBox="0 0 25.001 25.003">
                                          <path id="Union_1754" data-name="Union 1754" d="M15.061,25a1,1,0,0,1-1-1V15.062a1,1,0,0,1,1-1H24a1,1,0,0,1,1,1V24a1,1,0,0,1-1,1ZM1,25a1,1,0,0,1-1-1V15.062a1,1,0,0,1,1-1H9.935a1,1,0,0,1,1,1V24a1,1,0,0,1-1,1ZM15.061,10.936a1,1,0,0,1-1-1V1a1,1,0,0,1,1-1H24a1,1,0,0,1,1,1V9.936a1,1,0,0,1-1,1ZM1,10.936a1,1,0,0,1-1-1V1A1,1,0,0,1,1,0H9.935a1,1,0,0,1,1,1V9.936a1,1,0,0,1-1,1Z" transform="translate(0.003 0.003)" fill=""/>
                                        </svg>
                                        <p>Gallery</p>
                                      </div>
                                    </div>
                                  </Label>
                                  <span className="block-type-tooltip1">Showcase multiple images in a creative gallery format.</span>
                                </FormGroup>
                                <FormGroup>
                                  <Label className="addblock-ratio">
                                    <Input
                                      type="radio"
                                      name="addblockradio"
                                      value="custom_video"
                                      onClick={(e) => handleItem(e)}
                                    />
                                    <div className="image">
                                      <div className="d-block text-center">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="32.003" height="21.998" viewBox="0 0 32.003 21.998">
                                      <path id="Subtraction_156" data-name="Subtraction 156" d="M11989,19084h-20a2,2,0,0,1-2-2v-18a2,2,0,0,1,2-1.992h20a2,2,0,0,1,2,1.992V19070l6.4-4.8a.977.977,0,0,1,.6-.205,1.006,1.006,0,0,1,1,1v14a1,1,0,0,1-1,1,.993.993,0,0,1-.6-.2l-6.4-4.8v6A2,2,0,0,1,11989,19084Zm-12-15.2a1,1,0,0,0-1,1v6.391a1,1,0,0,0,1.532.848l5.11-3.2a1,1,0,0,0,0-1.7l-5.11-3.2A1,1,0,0,0,11977,19068.8Z" transform="translate(-11966.996 -19062.002)" fill=""/>
                                    </svg>

                                        <p>Video</p>
                                      </div>
                                    </div>
                                  </Label>
                                  <span className="block-type-tooltip2">Upload your video to enhance your post with engaging multimedia content.</span>
                                </FormGroup>
                                <FormGroup>
                                  <Label className="addblock-ratio">
                                    <Input
                                      type="radio"
                                      name="addblockradio"
                                      value="code"
                                      onClick={(e) => handleItem(e)}
                                    />
                                    <div className="image">
                                      <div className="d-block text-center">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="32.938" height="20.627" viewBox="0 0 32.938 20.627">
                                          <path id="Union_1761" data-name="Union 1761" d="M10463.327,17354.047l7.566-7.736-7.563-7.732,2.521-2.578,7.563,7.732,2.525,2.582-10.089,10.313Zm-20.325-7.732,2.521-2.582,7.567-7.732,2.518,2.578-7.563,7.732,7.567,7.736-2.524,2.58Z" transform="translate(-10443.002 -17336)" fill=""/>
                                        </svg>
                                        <p>Code</p>
                                      </div>
                                    </div>
                                  </Label>
                                  <span className="block-type-tooltip1">Add iframe snippets for interactivity and specialized features.</span>
                                </FormGroup>
                                <FormGroup>
                                  <Label className="addblock-ratio">
                                    <Input
                                      type="radio"
                                      name="addblockradio"
                                      value="button"
                                      onClick={(e) => handleItem(e)}
                                    />
                                    <div className="image">
                                      <div className="d-block text-center">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                          <path id="Union_183" data-name="Union 183" d="M25,25H0V0H25V25ZM3.126,21.876H21.876V3.124H3.126ZM6.29,12.54H9.376V7.771h6.249v4.77h3.166l-6.249,6.25Z" fill=""/>
                                        </svg>
                                        <p>Button</p>
                                      </div>
                                    </div>
                                  </Label>
                                  <span className="block-type-tooltip2">Insert engaging buttons to direct users and encourage interaction.</span>
                                </FormGroup>
                                <FormGroup>
                                  <Label className="addblock-ratio">
                                    <Input
                                      type="radio"
                                      name="addblockradio"
                                      value="spacer"
                                      onClick={(e) => handleItem(e)}
                                    />
                                    <div className="image">
                                      <div className="d-block text-center">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="18.391" height="28.775" viewBox="0 0 18.391 28.775">
                                          <path id="Union_1783" data-name="Union 1783" d="M14430.072,17362.652l-7.068-7.074,2.118-2.117,5.573,5.57v-17.289l-5.573,5.574-2.123-2.123,7.073-7.072,2.122-2.123,2.118,2.123,7.073,7.072-2.118,2.123-5.573-5.574v17.289l5.573-5.57,2.122,2.117-7.072,7.074-2.123,2.121Z" transform="translate(-14422.999 -17335.998)" fill=""/>
                                        </svg>
                                        <p>Spacer</p>
                                      </div>
                                    </div>
                                  </Label>
                                  <span className="block-type-tooltip2">Create space between elements for a neat and organized layout.</span>
                                </FormGroup>
                                <FormGroup>
                                  <Label className="addblock-ratio">
                                    <Input
                                      type="radio"
                                      name="addblockradio"
                                      value="separator"
                                      onClick={(e) => handleItem(e)}
                                    />
                                    <div className="image">
                                      <div className="d-block text-center">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 3">
                                          <path id="Union_1776" data-name="Union 1776" d="M25,0V3H0V0Z" fill=""/>
                                        </svg>
                                        <p>Separator</p>
                                      </div>
                                    </div>
                                  </Label>
                                  <span className="block-type-tooltip2">Insert a separator to visually divide different sections of your post.</span>
                                </FormGroup>
                                <FormGroup>
                                  <Label className="addblock-ratio">
                                    <Input
                                      type="radio"
                                      name="addblockradio"
                                      value="youtube_video"
                                      onClick={(e) => handleItem(e)}
                                    />
                                    <div className="image">
                                      <div className="d-block text-center">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="18.281" viewBox="0 0 26 18.281">
                                        <path id="Subtraction_157" data-name="Subtraction 157" d="M8589,18851.277c-.021,0-2.057,0-4.318-.062-1.328-.045-2.472-.1-3.4-.166a14.751,14.751,0,0,1-2.442-.312,3.3,3.3,0,0,1-1.46-.834,3.1,3.1,0,0,1-.512-.66,3.021,3.021,0,0,1-.329-.785,12.992,12.992,0,0,1-.312-1.85c-.069-.6-.122-1.3-.163-2.064-.065-1.307-.065-2.375-.065-2.385s0-1.08.065-2.385c.041-.764.094-1.463.163-2.066a12.909,12.909,0,0,1,.313-1.848,3.272,3.272,0,0,1,2.3-2.312,14.854,14.854,0,0,1,2.442-.316c.926-.068,2.069-.125,3.4-.162,2.259-.072,4.3-.072,4.318-.072s2.059,0,4.317.072c1.326.037,2.468.094,3.4.162a14.952,14.952,0,0,1,2.442.316,3.263,3.263,0,0,1,2.3,2.313,13.4,13.4,0,0,1,.316,1.848c.065.6.12,1.3.159,2.066.07,1.3.07,2.373.07,2.385s0,1.078-.07,2.385c-.039.766-.094,1.461-.159,2.064a13.494,13.494,0,0,1-.316,1.85,3.25,3.25,0,0,1-.327.785,2.993,2.993,0,0,1-.516.66,3.268,3.268,0,0,1-1.454.834,14.847,14.847,0,0,1-2.442.313c-.93.07-2.071.121-3.4.166C8591.057,18851.277,8589.02,18851.277,8589,18851.277Zm-2.66-12.982v7.73l6.8-3.867-6.8-3.863Z" transform="translate(-8576.003 -18832.996)" fill=""/>
                                      </svg>
                                        <p>YouTube video</p>
                                      </div>
                                    </div>
                                  </Label>
                                  <span className="block-type-tooltip1">Add captivating videos by pasting the YouTube link here.</span>
                                </FormGroup>
                                <FormGroup>
                                  <Label className="addblock-ratio">
                                    <Input
                                      type="radio"
                                      name="addblockradio"
                                      value="facebook_video"
                                      onClick={(e) => handleItem(e)}
                                    />
                                    <div className="image">
                                      <div className="d-block text-center">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="12.081" height="26" viewBox="0 0 12.081 26">
                                        <path id="_104498_facebook_icon" data-name="104498_facebook_icon" d="M30.063,14.515H25.949v-2.7a1.1,1.1,0,0,1,1.145-1.25H30V6.112L26,6.1c-4.439,0-5.449,3.323-5.449,5.449v2.97H17.982v4.591h2.567V32.1h5.4V19.106h3.644Z" transform="translate(-17.982 -6.096)" fill=""/>
                                      </svg>

                                        <p>Facebook video</p>
                                      </div>
                                    </div>
                                  </Label>
                                  <span className="block-type-tooltip3">Engage your audience with social media content by pasting the Facebook video link here.</span>
                                </FormGroup>
                                <FormGroup>
                                  <Label className="addblock-ratio">
                                    <Input
                                      type="radio"
                                      name="addblockradio"
                                      value="vimeo_video"
                                      onClick={(e) => handleItem(e)}
                                    />
                                    <div className="image">
                                      <div className="d-block text-center">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="24.216" viewBox="0 0 28 24.216">
                                        <path id="_5305169_vimeo_vimeo_logo_icon" data-name="5305169_vimeo_vimeo logo_icon" d="M27.986,40.2Q27.8,44.285,22.276,51.39q-5.71,7.419-9.662,7.421-2.449,0-4.142-4.519-1.13-4.143-2.26-8.287-1.257-4.518-2.7-4.519a8.663,8.663,0,0,0-2.2,1.318L0,41.108q2.072-1.821,4.085-3.643,2.765-2.388,4.15-2.514,3.268-.314,4.023,4.468.816,5.16,1.132,6.416.943,4.281,2.075,4.28.878,0,2.641-2.778a10.943,10.943,0,0,0,1.885-4.227q.251-2.4-1.885-2.4a5.248,5.248,0,0,0-2.073.459q2.065-6.76,7.886-6.57Q28.234,34.726,27.986,40.2Z" transform="translate(0 -34.594)" fill=""/>
                                      </svg>
                                        <p>Vimeo video</p>
                                      </div>
                                    </div>
                                  </Label>
                                  <span className="block-type-tooltip2">Showcase stunning videos by pasting the Vimeo link in this block.</span>
                                </FormGroup>
                                <FormGroup>
                                  <Label className="addblock-ratio">
                                    <Input
                                      type="radio"
                                      name="addblockradio"
                                      value="banner_ad"
                                      onClick={(e) => handleItem(e)}
                                    />
                                    <div className="image">
                                      <div className="d-block text-center">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <g id="Group_41751" data-name="Group 41751" transform="translate(-137.686 -1407)">
                                          <g id="Group_5389" data-name="Group 5389" transform="translate(137.686 1407)">
                                            <path id="Path_9215" data-name="Path 9215" d="M92,8.034C92.179,8,92.884,8,93.052,8A12.172,12.172,0,0,1,104.89,19.491v9.433A3.141,3.141,0,0,1,101.7,32a3.489,3.489,0,0,1-2.975-3.068V19.5a6.07,6.07,0,0,0-5.743-5.5,6.661,6.661,0,0,0-.982.014" transform="translate(-80.89 -7.998)" fill=""/>
                                            <path id="Path_9216" data-name="Path 9216" d="M146.162,143.083A3.081,3.081,0,1,1,143.08,140,3.082,3.082,0,0,1,146.162,143.083Z" transform="translate(-122.165 -122.165)" fill=""/>
                                            <path id="Path_9217" data-name="Path 9217" d="M19.946,32.1a3.126,3.126,0,0,0,3.2-3.05,3.042,3.042,0,0,0-3.23-2.908h-.034a6.072,6.072,0,0,1-5.666-6.368l0-.057a6.1,6.1,0,0,1,5.731-5.508,3.019,3.019,0,0,0,3.2-2.916,3.128,3.128,0,0,0-3.2-3.051c-.071,0-.143,0-.214,0C14.378,8.42,8.073,12.754,8.01,20.12v.045c0,7.475,6.738,11.93,11.76,11.93h.177Z" transform="translate(-8.01 -8.203)" fill=""/>
                                          </g>
                                        </g>
                                      </svg>
                                        <p>Banner ad</p>
                                      </div>
                                    </div>
                                  </Label>
                                  <span className="block-type-tooltip2" style={{width: "145px"}}>Incorporate promotional banners to enhance engagement and generate revenue.</span>
                                </FormGroup> 
                                 <FormGroup>
                                  <Label className="addblock-ratio">
                                    <Input
                                      type="radio"
                                      name="addblockradio"
                                      value="custom_ad"
                                      onClick={(e) => handleItem(e)}
                                    />
                                    <div className="image">
                                      <div className="d-block text-center">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="19.001" viewBox="0 0 30 19.001">
                                          <g id="Group_42976" data-name="Group 42976" transform="translate(0.632 1.559)">
                                            <rect id="Rectangle_22260" data-name="Rectangle 22260" width="30" height="18" rx="2" transform="translate(-0.632 -0.558)" fill=""/>
                                            <text id="_" data-name="&lt;/&gt;" transform="translate(14.367 12.441)" fill="#fff" font-size="13" font-family="Axiforma-Bold, Axiforma" font-weight="700"><tspan x="-11.512" y="0">&lt;/&gt;</tspan></text>
                                          </g>
                                        </svg>
                                        <p>Custom ad</p>
                                      </div>
                                    </div>
                                  </Label>
                                  <span className="block-type-tooltip2" style={{bottom: "-69px"}}>Insert a custom ad seamlessly into your content.</span>
                                </FormGroup>​
                              </FormGroup>
                            </ModalBody>
                            <ModalFooter toggle={customBlock}>
                              <Button onClick={customBlock}>Cancel</Button>
                              <Button
                                className={`${
                                  selectedItem !== "" ? "" : "disabled"
                                }`}
                                onClick={(e) => handleAddItem(e)}
                              >
                                Done
                              </Button>
                            </ModalFooter>
                          </Modal>

                        </div>
                        <TabContent activeTab={activeTab}>
                       
                          <TabPane tabId={true}>
                         
                            <Row>
                            
                              <Col sm="12">
                              
                              <div className="cms-pages-left">
                               
                                  <div class="accordionWrapper pages-fields">
                                    <DragDropContext onDragEnd={handleDragEnd}>
                                      <Droppable droppableId="1234567">
                                        {(provided) => (
                                          <div ref={provided.innerRef}>
                                            {/* <Title
                                              indx={0}
                                              cmsArray={cmsData}
                                              setcmsData={setcmsData}
                                              isRowOpen={isRowOpen}
                                              handleIsRowOpen={handleIsRowOpen}
                                              // eachBlock={cmsRow}
                                              provided={provided}
                                              from={"page"}
                                              title={title}
                                              setTitle={setTitle}
                                            /> */}
                                            <div className="scroll-new-container" ref={leftSideRef}>
                                            {cmsData &&
                                              cmsData.map((cmsRow, indx) => {
                                                return (
                                                  indx > 0 ? (
                                                    <div key={indx}>
                                                      <Draggable
                                                        draggableId={indx.toString()}
                                                        key={indx}
                                                        index={indx}
                                                      >
                                                        {(provided) => (
                                                          <div 
                                                          
                                                            className="draggable-container"
                                                            ref={
                                                              provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                          >
                                                            {handleSwitch(
                                                              cmsRow,
                                                              indx,
                                                              provided
                                                            )}
                                                          </div>
                                                        )}
                                                      </Draggable>
                                                    </div>
                                                  )
                                                  :<div key={indx}>
                                                  
                                                      <div 
                                                      
                                                        className="draggable-container"
                                                       
                                                      >
                                                        {handleSwitch(
                                                          cmsRow,
                                                          indx,
                                                          provided
                                                        )}
                                                      </div>
                                                 
                                                </div>
                                                )
                                                
                                              })}
                                              </div>
                                            {provided.placeholder}
                                          </div>
                                        )}
                                      </Droppable>
                                    </DragDropContext>
                                  </div>

                                  <div className="add-more-blocks">
                                  <p className="addmoreblock" onClick={customBlock}
                                  style={{
                                    cursor:"pointer",
                                    marginBottom:"0px"
                                  }}
                                  >
                                    <img
                                      className="mr-2"
                                      src={
                                        require("../../assets/images/dashboard/cms/blue-plus.png")
                                          .default
                                      }
                                    />
                                    Add section
                                  </p>
                                  </div>

                                  </div>  

                              </Col>
                            
                            </Row>
                           
                          </TabPane>
                          
                          <TabPane tabId={false}>
                            <Row>
                              <Col sm="12">
                                <div className="pages-setting">
                                  <h2>Page settings</h2>
                                  <h6 >Author
                                  {/* <p className="custom-settings-tooltip">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg>
                                      <p className="custom-settings-tooltipsubheader">Choose an author to display their name along with this page.</p>
                                      </p> */}
                                      <Tooltip message={"Choose an author to display their name along with this page."}/>
                                  </h6>
                                        <div className="categogy-chip-section" >
                                          
                                        <Dropdown className="author-dropdown search-dropdown" 
                                         isOpen={window.matchMedia("(max-width: 1200px)").matches?false :isAuthorPopup}
                                         onClick={window.matchMedia("(max-width: 1200px)").matches?handleMobileAuthorDropdown:null}
                                          toggle={handleAuthorPopUp}
                                         >
                                    {/* <DropdownToggle caret><span className="search-dropdown-before" style={{
                                    textTransform:"capitalize"
                                    }}>{returnSelectedAuthorName(authorId)?returnSelectedAuthorName(authorId):"Select"}</span>
                                    </DropdownToggle> */}
                                    <DropdownToggle caret>
                                     
                                     <div className="cms-author cms-author-selected">
                                     {
                                            returnSelectedAuthorName(authorId) && 
                                           <div style={{textTransform:"capitalize",
                                          
                                          }}
                                           >
                                             <img className="img-fluid" 
                                         src={returnSelectedImagePath(authorId)}

                                          alt="profile-img" 
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src =imagebackground;
                                          }}/>
                                           </div>
                                         }
 
                                           <h3 
                                           style={{
                                            top:returnSelectedAuthorName(authorId)?"":"0"
                                          }}
                                          >{returnSelectedAuthorName(authorId)?returnSelectedAuthorName(authorId):"Select"}</h3>
                                         </div>
                                     </DropdownToggle>

                                    <DropdownMenu >
                               
                                    
                                     
                                  {/* <DropdownItem  
                                   onClick={(e)=>handleAuthorChange(e,"")}
                                   active={postRelatedTo===""}
                                  >Select
                                  </DropdownItem> */}
                                  
                               
                                   { mergeUserOwnerData &&
                                    mergeUserOwnerData.map((res)=>{
                                    return(
                                        // <DropdownItem  
                                        //  onClick={(e)=>handleAuthorChange(e,res.user_id)}
                                        //  active={authorId===res.user_id}
                                        // > {res.name}
                                        // </DropdownItem>
                                        res.status !== 0 &&
                                        <DropdownItem  
                                         onClick={(e)=>handleAuthorChange(e,res.user_id)}
                                         active={authorId===res.user_id}
                                        > 
                                        <div className="cms-author">
                                          <div 
                                        
                                          >
                                           
                                          {
                                            res.profile_image &&  res.profile_image.indexOf("https")>-1 ?
                                              <img className="img-fluid" src={res.profile_image}  alt="profile-img"
                                                 onError={({ currentTarget }) => {
                                                  currentTarget.onerror = null;
                                                  currentTarget.src =imagebackground;
                                                }}
                                                />
                                            :
                                         <img className="img-fluid" src={process.env.REACT_APP_Image_Path+res.user_id+
                                          process.env.REACT_APP_Profile+res.profile_image}  alt="profile-img"
                                           onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src =imagebackground;
                                          }}
                                          />
                                        }
                                          </div>

                                          <p>{res.name}</p>
                                        </div>
                                        </DropdownItem>
                                      )

                                    })
                                   }
                                  

                                  
                                
                                 
                                     
                                     
                                      
                                    </DropdownMenu>
                                  </Dropdown>
                                        </div>
                              <div className="search-dropdown">
                                  <h6 className="mt-20">Parent page
                                  {/* <p className="custom-settings-tooltip">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg>
                                      <p className="custom-settings-tooltipsubheader">Select the desired parent page from the dropdown menu, to organize your pages hierarchically, if required.</p>
                                      </p> */}
                                      <Tooltip message={"Select the desired parent page from the dropdown menu, to organize your pages hierarchically, if required."}/>
                                  </h6>
                                  <div className="category-parent-dropdown cat-parent-input-popup" 
                                  style={{
                                    cursor:"pointer",
                                  }}
                          // style={{
                          //   border:"1px solid black",
                          //   height:"50px",
                          //   width:"100%",
                          // }}
                          onClick={(e)=>handleOpenSelectionPopUp(e,"open")}
                          >
                            <p style={{display: "flex", alignItems: "center", marginBottom:"0px", color:parentName?"":"#bcc1ce"}}  >{parentName?parentName:"Select"}</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19">
                              <g id="Group_42666" data-name="Group 42666" transform="translate(-1005 -1029)">
                                <g id="Rectangle_31915" data-name="Rectangle 31915" transform="translate(1005 1029)" fill="#fff" stroke="#bcc1ce" stroke-width="1">
                                  <rect width="20" height="19" rx="2" stroke="none"/>
                                  <rect x="0.5" y="0.5" width="19" height="18" rx="1.5" fill="none"/>
                                </g>
                                <path id="Path_119031" data-name="Path 119031" d="M15578.713-3593.554h2" transform="translate(-14569.713 4628.054)" fill="none" stroke="#bcc1ce" stroke-width="1"/>
                                <path id="Path_119032" data-name="Path 119032" d="M15578.713-3593.554h2" transform="translate(-14569.713 4632.054)" fill="none" stroke="#bcc1ce" stroke-width="1"/>
                                <path id="Path_119033" data-name="Path 119033" d="M15578.713-3593.554h2" transform="translate(-14569.713 4636.054)" fill="none" stroke="#bcc1ce" stroke-width="1"/>
                                <path id="Path_119034" data-name="Path 119034" d="M15578.711-3593.554h8" transform="translate(-14565.713 4628.054)" fill="none" stroke="#bcc1ce" stroke-width="1"/>
                                <path id="Path_119035" data-name="Path 119035" d="M15578.711-3593.554h8" transform="translate(-14565.713 4632.054)" fill="none" stroke="#bcc1ce" stroke-width="1"/>
                                <path id="Path_119036" data-name="Path 119036" d="M15578.711-3593.554h8" transform="translate(-14565.713 4636.054)" fill="none" stroke="#bcc1ce" stroke-width="1"/>
                              </g>
                             </svg>

                        
                          </div>   
                                  {/* <Dropdown isOpen={window.matchMedia("(max-width: 1200px)").matches?false :isParentpopup}
                                  onClick={window.matchMedia("(max-width: 1200px)").matches?handleMobileAppDropdown:null}
                                   toggle={handleParentPop}
                                  >
                                    <DropdownToggle caret><span className="search-dropdown-before">{parentName?parentName:"Select"}</span></DropdownToggle>
                                    <DropdownMenu >
                                   {
                                    allPage.length===0 &&
                                    <div className="no-app-dropdown">
                                    <img className="img-fluid" src={require("../../assets/images/dashboard/no-tag-available.png").default} alt="No App Found" />
                                    </div>
                                   }
                                   {
                                    allPage.length>0 &&
                                    <FormGroup>
                                    <div className="dropdown-search">
                                      <input placeholder="Search" type="text" value={searchPageVal}
                                      onChange={(e)=>{
                                        setSearchPageVal(e.target.value)
                                        
                                      }}
                                      ></input>
                                      {searchPageVal.trim() && <p onClick={(e)=>{
                                      setSearchPageVal("")
                                    }}>Clear</p>}
                                    </div>
                                  </FormGroup>
                                 }
                                   {allPage.length>0 && !searchPageVal &&
                                   <DropdownItem  
                                   onClick={(e)=>handleParentChange(e,0,"")}
                                  >Select
                                  </DropdownItem>
                                   }
                                 {allPage.length>0 && recurs(allPage,"parent")}
                                     
                                     
                                      
                                    </DropdownMenu>
                                  </Dropdown> */}
                                  </div>
                                  </div>
                               
                                  
                                  {/* <div
                                    className="parentpagename"
                                    onClick={(e) => handleParentPop(e)}
                                  >
                                   

                                    <p>
                                      {parentId === "" ||
                                      parentId === undefined ||
                                      parentId === 0
                                        ? "-"
                                        : parentName}
                                    </p>
                                   
                                  </div> */}
                               
                              </Col>
                            </Row>
                          </TabPane>
                          
                        </TabContent>

                        <div className="cms-mobile-btn-navigation">
                            <button

                                  disabled={ (isLoader2==="d-block" || isLoader==="d-block") }
                                  className={` ${( isLoader==="d-block")?"save-draft-mobile-disable":"save-draft-mobile"}`}
                                  onClick={(e) => handleSubmitPagesData(e, "draft")}

                                  style={{
                                    // backgroundColor:localStorage.getItem('button_background_color'),
                                    border:'1px solid '+localStorage.getItem('button_background_color'),
                                    color:localStorage.getItem('button_text_color'),
                                    "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                                    "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
                                    "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                                    }}
                                >
                                  <div className={isLoader2}>
                                    <div className="new-blue-loader"></div>
                                  </div>
                                  <div style={{
                          color:localStorage.getItem('button_background_color'),
                         }}>

                                  {window.matchMedia("(max-width: 1200px)").matches
                                    ? isLoader2 === "d-none"
                                    ? "Save draft"
                                    : ""
                                    : submitval2}
                                    </div>
                                </button>

                              <button
                             className={`${(isLoader2==="d-block")?"publish-mobile-disable":"publish-mobile"}`}
                             disabled={ (isLoader2==="d-block" || isLoader==="d-block") }
                                onClick={(e) => handleSubmitPagesData(e, "publish")}
                                style={{
                                  backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                                }}
                              >
                                <div className={isLoader}>
                                  <div className="new-loader"></div>
                                </div>
                                {statusOfPage === "publish" ?isLoader==="d-none"? "Save":"" : submitval}
                              </button>
                              {
                              updatedAt &&
                               <p className="profile-latest-update" style={{width: "100%", marginTop: "15px", textAlign: "center"}}>Updated {moment(
                                  updatedAt
                                ).format("D MMMM YYYY, HH:mm")}</p>
                              }

                          </div>


                      </div>
                                )}
                    </div>

                  </div>
                )}
                {((showMobilPreview === true &&
                  window.matchMedia("(max-width: 1200px)").matches) ||
                  window.matchMedia("(min-width: 1200px)").matches) && (
                  <div className="col-md-12 col-xl-6">
                    <div className="create-page-preview">
                      {/* <Suspense fallback="fetching"> */}
                        <Preview
                          cmsData={cmsData}
                          title={title}
                          openItemIndex={openItemIndex}
                          diff={"page"}
                          rightSideRef={rightSideRef}
                          adsData={adsData}
                          selectedDevice={selectedPreviewDevice}
                          sectionTopMargin={sectionTopMargin}
                          sectionBottomMargin={sectionBottomMargin}
                          sectionLeftMargin={sectionLeftMargin}
                          sectionRightMargin={sectionRightMargin}
                          imgCenterForDetails={imgCenterForDetails}
                          imageShape={imgShapeForDetails}
                          
                          // dummyArr={dummyArr}
                        />
                      {/* </Suspense> */}

                      {
                           checkIfCustomAdBlockIsAdded() && 

                            <div className="newplatswitch notificationplat">
                              <ul
                                id="myTabs"
                                className="nav nav-pills nav-justified  d-flex justify-content-center"
                                role="tablist"
                                data-tabs="tabs"
                              >
                                
                                <li 
                                style={{
                                  marginRight:"16px",
                                }} 
                                className={`android-icontochange ${selectedPreviewDevice ==="android"?" techselect":"technotselect"}`}>
                                 
                                  <svg  onClick={(e)=>handleDeviceChange(e,"android")} xmlns="http://www.w3.org/2000/svg" width="18.591" height="22" viewBox="0 0 18.591 22">
                                    <path id="Path_7126" data-name="Path 7126" d="M1.376,15.116A1.3,1.3,0,0,1,0,13.912V8.761A1.3,1.3,0,0,1,1.376,7.556,1.3,1.3,0,0,1,2.753,8.761v5.151A1.3,1.3,0,0,1,1.376,15.116Zm10.411,5.037,1.2,1.2a.379.379,0,0,1-.537.536l-1.48-1.478a7.024,7.024,0,0,1-3.32,0L6.175,21.889a.379.379,0,1,1-.537-.536l1.2-1.193A4.85,4.85,0,0,1,3.45,15.8c0-.023,11.706,0,11.706,0A4.843,4.843,0,0,1,11.788,20.153ZM7.582,17.178a.687.687,0,1,0,.689.687A.687.687,0,0,0,7.582,17.178Zm3.444,0a.687.687,0,1,0,.689.687A.687.687,0,0,0,11.025,17.178Zm6.19-2.062a1.3,1.3,0,0,1-1.377-1.2V8.761a1.389,1.389,0,0,1,2.753,0v5.151A1.3,1.3,0,0,1,17.216,15.116ZM3.45,6.875a2.063,2.063,0,0,1,2.059-2.06V1.205A1.3,1.3,0,0,1,6.886,0,1.3,1.3,0,0,1,8.261,1.205V4.814H10.33V1.205A1.3,1.3,0,0,1,11.706,0a1.3,1.3,0,0,1,1.376,1.205V4.814h.009a2.063,2.063,0,0,1,2.066,2.06v8.243H3.451V6.875Z" transform="translate(18.591 22) rotate(180)" fill="" />
                                  </svg>
                                </li>

                                <li 
                                 style={{
                                  marginRight:"0px",
                                }} 
                                className={`ios-icontochange ${selectedPreviewDevice ==="ios" ?" techselect":"technotselect"} `}>
                                 
                                  <svg onClick={(e)=>handleDeviceChange(e,"ios")} xmlns="http://www.w3.org/2000/svg" width="18.417" height="22" viewBox="0 0 18.417 22">
                                    <g id="apple-logo" transform="translate(0)">
                                      <g id="Group_1428" data-name="Group 1428" transform="translate(0 0)">
                                        <path id="Path_668" data-name="Path 668" d="M55.02,116.775a5.034,5.034,0,0,1-.912-2.948,4.711,4.711,0,0,1,.859-2.737,8.568,8.568,0,0,1,1.507-1.56,6.818,6.818,0,0,0-1.375-1.322,4.834,4.834,0,0,0-2.816-.859A7.461,7.461,0,0,0,50,107.8a6.621,6.621,0,0,1-1.891.45,8.505,8.505,0,0,1-1.825-.4,8.8,8.8,0,0,0-2.34-.4,4.564,4.564,0,0,0-3.755,1.9,7.844,7.844,0,0,0-1.494,4.945,13.554,13.554,0,0,0,1.944,6.65q1.97,3.424,3.979,3.424a4.779,4.779,0,0,0,1.745-.45,5.1,5.1,0,0,1,1.878-.436,5.846,5.846,0,0,1,2,.423,6.112,6.112,0,0,0,1.851.423q1.692,0,3.4-2.591a12.762,12.762,0,0,0,1.626-3.305A4.022,4.022,0,0,1,55.02,116.775Z" transform="translate(-38.692 -102.378)" fill="" />
                                        <path id="Path_669" data-name="Path 669" d="M236.488,4.178a5.427,5.427,0,0,0,1.23-1.824,5.044,5.044,0,0,0,.383-1.8c0-.035,0-.079-.007-.132s-.006-.1-.006-.132a1.28,1.28,0,0,1-.033-.145A1.224,1.224,0,0,0,238.021,0a5.269,5.269,0,0,0-3.305,1.957,6.03,6.03,0,0,0-1.031,3.4,7.388,7.388,0,0,0,1.375-.225A3.747,3.747,0,0,0,236.488,4.178Z" transform="translate(-224.655 0)" fill="" />
                                      </g>
                                    </g>
                                  </svg>
                                </li>
                              </ul>
                            </div>  }
                      <div
                        className="cmsmobilepreview"
                        onClick={() => {
                          setShowhowMobilPreview(!showMobilPreview);
                        }}
                      >
                        <img
                          src={
                            require("../../assets/images/dashboard/preview-cross.png")
                              .default
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <Modal className="protip-popup-message" isOpen={window.matchMedia("(max-width: 1200px)").matches?openMobileAppDropdown:false} centered>
          
          <ModalBody>
            <div className="mobileglobalsearch">
              <button onClick={handleMobileAppDropdown}><svg xmlns="http://www.w3.org/2000/svg" width="20.759" height="15.408" viewBox="0 0 20.759 15.408">
            <g id="Group_27465" data-name="Group 27465" transform="translate(-19.742 -22.295)">
              <path id="Path_81245" data-name="Path 81245" d="M-13783.922-19217h19.346" transform="translate(13805.077 19247)" fill="none" stroke="#7782a1" stroke-width="2"/>
              <path id="Path_82232" data-name="Path 82232" d="M14053.656,19255.426l-7,7,7,7" transform="translate(-14025.504 -19232.424)" fill="none" stroke="#7782a1" stroke-width="2"/>
            </g>
          </svg></button>
          {

          allPage.length > 0 &&
          <input placeholder="Search" type="text" value={searchPageVal}
                                  onChange={(e)=>{
                                   setSearchPageVal(e.target.value)
                                  }}
                                  ></input>
                                }
              {searchPageVal.trim() && <p onClick={(e)=>{
                                   setSearchPageVal("")
                                }}>Clear</p> }
          </div>

          <div className="mobilesearchsuggestion">
            {
            allPage.length>0 && !searchPageVal &&
            
          <p style={{	
            fontSize:"16px",	
            color: "#1B2952",	
             fontFamily: "Axiforma-Regular",	
            margin: "0px 20px 40px 20px",	
             display: "block",	
          }}
           onClick={(e)=>handleParentChange(e,0,"")}
           >Select</p>
            }
          {allPage.length>0 ? recursForMobile(allPage,"parent")
        : <div className="no-app-dropdown">
        <img className="img-fluid" src={require("../../assets/images/dashboard/no-tag-available.png").default} alt="No App Found" />
        </div> 
        }

          

            </div>
          </ModalBody>
      </Modal> */}
{/* Author Mobile Dropdown */}
<Modal className="protip-popup-message" isOpen={window.matchMedia("(max-width: 1200px)").matches?openMobileAuthorDropdown:false} centered>
          
          <ModalBody>
            <div className="mobileglobalsearch">
              <button onClick={handleMobileAuthorDropdown}><svg xmlns="http://www.w3.org/2000/svg" width="20.759" height="15.408" viewBox="0 0 20.759 15.408">
            <g id="Group_27465" data-name="Group 27465" transform="translate(-19.742 -22.295)">
              <path id="Path_81245" data-name="Path 81245" d="M-13783.922-19217h19.346" transform="translate(13805.077 19247)" fill="none" stroke="#7782a1" stroke-width="2"/>
              <path id="Path_82232" data-name="Path 82232" d="M14053.656,19255.426l-7,7,7,7" transform="translate(-14025.504 -19232.424)" fill="none" stroke="#7782a1" stroke-width="2"/>
            </g>
          </svg></button>
          {/* {

          allPage.length > 0 &&
          <input placeholder="Search" type="text" value={searchPageVal}
                                  onChange={(e)=>{
                                   setSearchPageVal(e.target.value)
                                  }}
                                  ></input>
                                }
              {searchPageVal.trim() && <p onClick={(e)=>{
                                   setSearchPageVal("")
                                }}>Clear</p> } */}
          </div>

          <div className="mobilesearchsuggestion">
            {
            mergeUserOwnerData && mergeUserOwnerData.map((res)=>{
              return(
                res.status !== 0 &&
                <div className="cms-author-mobile">
                  <div 
                 
                                          >
                   {
                                             res.profile_image && res.profile_image.indexOf("https")>-1 ?
                                              <img className="img-fluid" src={res.profile_image}  alt="profile-img"
                                                 onError={({ currentTarget }) => {
                                                  currentTarget.onerror = null;
                                                  currentTarget.src =imagebackground;
                                                }}
                                                />
                                            :
                                         <img className="img-fluid" src={process.env.REACT_APP_Image_Path+res.user_id+
                                          process.env.REACT_APP_Profile+res.profile_image}  alt="profile-img"
                                           onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src =imagebackground;
                                          }}
                                          />
                                        }
                  </div>

                  <p onClick={(e)=>handleAuthorChange(e,res.user_id)}>{res.name}</p>
                </div>
             
              )
             }) 
            }
         
       
        

          

            </div>
          </ModalBody>
      </Modal>
      <SingleSelectionPopUpForCustomApp 
          openPopUp={openSingleSelectionPopUp}
          handlePopUp={handleOpenSelectionPopUp}
          data={allPage}
          handleInputClick={handleParentChange}
          selectedId={tempParentId}
          subData={subData}
          setSubData={setSubData}
          searchVal={searchValForPopUp}
          setSearchVal={setSearchValForPopUp}
          breadcrumbArr={breadcrumbArr}
          setBreadcrumbArr={setBreadcrumbArr}
          from={"page"}
          oldBreadcrumbArr={oldBreadcrumbArr}
          oldSubData={oldSubData}
          handleBottomScrollListner={handleBottomScrollListner}
          paginationSpinner={paginationSpinner}
       />
      </section>
    </>
  );
};

export default Editpage;
