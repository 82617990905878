import React from "react";

const DiscountBadgeIcon = ({ color,showDiscountPercentage }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={showDiscountPercentage?"20":"13"}
      height="18"
      viewBox={showDiscountPercentage?"0 0 20 18":"0 0 13 18"}
    >
      <path
        id="Path_119101"
        data-name="Path 119101"
        d="M122.691,0V18l3.334-2.057L129.357,18l3.333-2.057L136.024,18l3.333-2.057L142.691,18V0Z"
        transform="translate(-122.691 0)"
        fill={color}
      ></path>
    </svg>
  );
};

export default DiscountBadgeIcon;
