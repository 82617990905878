import axios from "axios";
import { errortoast, sucesstoast } from "../../Dashboard/Toaster";
const Url = process.env.REACT_APP_API_URL + "api/app/";
const Currencyurl = process.env.REACT_APP_API_URL + "api/";
const Activationurl =
  process.env.REACT_APP_API_URL + "api/app/user-management/invitation-email";

//Invitation Api
export const Invitationapi = (params, state) => {
  if (params) {
    axios.post(Activationurl, params).then((res) => {
      if (res.data.code === 200) {
        sucesstoast("Invitation Accepted.");
          if (res.data.is_new_user === 1) {
            state.props.history.push('/sign-up');
          } else {
            state.props.history.push('/');
          }
        state.setState({
          spinner: false,
          code: res.data.code,
        });
      } 
      else if (res.data.code === 403) 
      {
        state.props.history.push("/link-expired");
        state.setState({
          spinner: false,
          message: res.data.data,
          code: res.data.code,
        });
      } 
      else if (res.data.code === 401) 
      {
        state.props.history.push("/link-expired");
        state.setState({
          spinner: false,
          message: res.data.data,
          code: res.data.code,
        });
      } else {
        errortoast(
          "We are facing some technical issues. Please try again after sometime."
        );
      }
    })
    .catch((error) =>{});
  }
};
//Status

export const Changestatus = (
  params, 
  setlimitreached, 
  callback,
  setBtnCustomerActiveLoader,
  setBtnCustomerDeactiveLoader,
  setMdlCustomerDeactive,setMdlCustomerActive,
  insCount) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "user-management/update-status", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        
        if(setBtnCustomerActiveLoader){
          setBtnCustomerActiveLoader("d-none");
          setBtnCustomerDeactiveLoader("d-none");
          setMdlCustomerDeactive(false);
          setMdlCustomerActive(false);
        }
        insCount();
      } else if (res.data.code === 203) {
        setlimitreached(true);
        if(setBtnCustomerActiveLoader){
          setBtnCustomerActiveLoader("d-none");
          setBtnCustomerDeactiveLoader("d-none");
          setMdlCustomerDeactive(false);
          setMdlCustomerActive(false);
        }
        callback();
      }
    })
    .catch((error) => {
      if(setBtnCustomerActiveLoader){
        setBtnCustomerActiveLoader("d-none");
        setBtnCustomerDeactiveLoader("d-none");
        setMdlCustomerDeactive(false);
        setMdlCustomerActive(false);
      }});
};


export const WorkspaceChangestatus = (params, setlimitreached, callback) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Currencyurl + "update-workspace-users", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        // insCount();
      } else if (res.data.code === 203) {
        setlimitreached(true);
        callback();
      }
    })
    .catch((error) => {});
};


export const WorkspaceActiveChangestatus = (params, setlimitreached,setMdlCustomerActive,setPopSpinner,popspinner,callback) => {
  
  setMdlCustomerActive(false);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Currencyurl + "update-workspace-users", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
      setTimeout(() => {
        setPopSpinner(false);                
      }, 2000);
      setMdlCustomerActive(false);
      callback();
        // insCount();
      } else if (res.data.code === 203) {
      setTimeout(() => {
        setPopSpinner(false);
                
      }, 2000);
      setMdlCustomerActive(false);
        setlimitreached(true);
        callback();
      }
      
    })
    .catch(
     
      (error) => {},
      
      );
};

export const WorkspaceDeactiveChangestatus = (params, setlimitreached,setMdlCustomerDeactive,setPopSpinner,popspinner,callback) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  setMdlCustomerDeactive(false);
  

  axios
    .post(Currencyurl + "update-workspace-users", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        setMdlCustomerDeactive(false);
        setTimeout(() => {
        
        setPopSpinner(false);
                
      }, 2000);
      callback();
        // insCount();
      } else if (res.data.code === 203) {
        setlimitreached(true);
        callback();
        setMdlCustomerDeactive(false);
        setTimeout(() => {
          setPopSpinner(false);      
        }, 2000);
      }
      
    })
    .catch(
      (error) => {},
      );
};

export const Userlist = (
  params,
  setSpinner,
  setData,
  setUserdata,
  setuser_role_id,
  setuser_email,
  setSubscription_plan,
  setuser_count,
  setaddon_quantity,
  setallowed_users,
  setallowed_screen ,
  setPlan ,
  setAddonCode,
  setResellerPackageId,
  setrole_id,
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(
      Url +
        "user-management/" +
        params.app_id +
        "?page=1&limit=100&search_field=&timestamp=" +
        new Date().getTime()+"&agency_id="+ (
        localStorage.getItem("agencyid") != 1 && 
        localStorage.getItem("agencyid") != null && 
        localStorage.getItem("agencyid") != "null" &&
        localStorage.getItem("agencyid") != "undefined" &&
        localStorage.getItem("agencyid") != undefined?
        btoa(localStorage.getItem("agencyid")):btoa(null)),
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        setSpinner(false);
        setUserdata(res.data.owner_data);
        setData(res.data.data);
        setuser_role_id(res.data.appuser_role_id===null?1:res.data.appuser_role_id);
        setrole_id(res.data.appuser_role_id===null?1:res.data.appuser_role_id);
        setuser_email(res.data.user_email);
        setSubscription_plan(res.data.subscription_plan);

        setuser_count(res.data.user_count);
        setaddon_quantity(res.data.addon_quantity);
        setallowed_users(res.data.allowed_users);
        setallowed_screen(res.data.is_enable_screen);
        setPlan(res.data.plan);
        setAddonCode(res.data.addon_code);
        setResellerPackageId(res.data.reseller_package_id);
      } else if (res.data.code === 401) {
        setSpinner(false);
        setuser_role_id(res.data.appuser_role_id);
      } else {
        setSpinner(false);
      }
    })
    .catch((error) => {});
};

export const Saveuser = (params, addusertoggle, insCount, errorhandling) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "user-management", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        addusertoggle();
        insCount();
      } else if (res.data.code === 403) {
        errorhandling();
        errortoast(res.data.data);
      } else if (res.data.code === 400) {
        errorhandling();
        errortoast(res.data.data.email[0]);
      } else if(res.data.code === 203){
        errorhandling();
        errortoast(res.data.data);
      }
    })
    .catch((error) => {});
};
//Delete User
export const Deleteuser = (params, insCount,setDeleteLoader,setSubmitvalDeleteVal,setOpenDeletePopUp) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "user-management/delete", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        insCount();
        setDeleteLoader("d-none");
        setSubmitvalDeleteVal("Delete");
        setOpenDeletePopUp(false);
        // window.location.reload();
      } else if (res.data.code === 403) {
        setDeleteLoader("d-none");
        setSubmitvalDeleteVal("Delete");
        errortoast(res.data.data);
      }
    })
    .catch((error) => 
    {
    
    setDeleteLoader("d-none");
    setSubmitvalDeleteVal("Delete");
    }
    );
};

//Delete User
export const DeleteWorkspaceuser = (params, insCount) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Currencyurl + "remove-workspace-users", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        insCount();
        window.location.reload();
      } else if (res.data.code === 403) {
        errortoast(res.data.data);
      }
    })
    .catch((error) =>{});
};

//Change currency
export const Currencychangeapi = (
  params,
  setLoader,
  setsubmit,
  setdeluser,
  load
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Currencyurl + "user/updateCurrency", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        setLoader("d-none");
        setsubmit("UPDATE");
        load();
      } else {
        setLoader("d-none");
        setsubmit("UPDATE");
        setdeluser(false);
      }
    })
    .catch((error) =>{});
};

// Save user for workspace
export const saveUserForWorkspace = (
  params,
  setLoader,
  setadduser,
  adduser,
  setsubmit
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Currencyurl + "save-workspace-user", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) 
      {
        setLoader("d-none");
        // sucesstoast("User added successfully");
        setadduser(!adduser);
        window.location.reload();
      }
      else if(res.data.code == 403)
      {
        setLoader("d-none");
        setsubmit("Invite");
        setadduser(!adduser);
        errortoast("already exists");
      }
    })
    .catch((err) => {
      errortoast("Something went wrong");
    });
};

// Get All user for workspace

export const getUserForWorkspace = (
  params,
  setSpinner,
  setOwnerData,
  setUserdata,
  setuser_role_id,
  setuser_email,
  setData,
  setSubscription_plan,
  setuser_count,
  setaddon_quantity,
  setallowed_users,
  setallowed_screen,
  setPlan
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(
      Currencyurl + "list-workspace-users/" + params.workspace_id,
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        setSpinner(false);
        setUserdata(res.data.data);
        setOwnerData(res.data.owner_data);
        setuser_role_id(res.data.appuser_role_id);
        setuser_email(res.data.user_email);
        setData(res.data.data);
        setSubscription_plan(res.data.subscription_plan);
        setuser_count(res.data.user_count);
        setaddon_quantity(res.data.addon_quantity);
        setallowed_users(res.data.allowed_users);
        setallowed_screen(res.data.is_enable_screen);
        setPlan(res.data.plan);
        // sucesstoast("User added successfully");
      } else if (res.data.code === 401) {
        setSpinner(false);
        setuser_role_id(res.data.appuser_role_id);
      } else {
        setSpinner(false);
      }
    })
    .catch((error) => {});
};

//Verify Organization Email Api
export const verifyEmailID = (params, setSpinner, setMessage, setCode, redirectToMainPage) => {
  	if (params) 
	{
		const headers = {
			"Content-Type": "application/json",
			Authorization: "Bearer " + localStorage.getItem("amstoken"),
		};

    	axios
    		.post(Currencyurl + "org-from-email-activation", params, {
      			headers: headers,
    		})
    	.then((res) => {
      		if (res.data.code === 200) 
			{
        		sucesstoast("Invitation Accepted.");

				redirectToMainPage("/");

				setSpinner(false);
				setCode(res.data.code);
      		} 
			else if (res.data.code === 403) 
			{
				setSpinner(false);
				setCode(res.data.code);
				setMessage(res.data.data);        		
      		} 
			else if (res.data.code === 401) 
			{
				setSpinner(false);
				setCode(res.data.code);
				setMessage(res.data.data);
      		} 
			else 
			{
				errortoast( "We are facing some technical issues. Please try again after sometime." );
      		}
    	});
  	}
};

//Verify Organization Email Api
export const verifySMTPConfiguration = (params, setSpinner, setMessage, setCode, redirectToMainPage) => {
	if (params) 
  {
	  const headers = {
		  "Content-Type": "application/json",
		  Authorization: "Bearer " + localStorage.getItem("amstoken"),
	  };

	  axios
		  .post(Currencyurl + "org-smtp-config-activation", params, {
				headers: headers,
		  })
	  .then((res) => {
			if (res.data.code === 200) 
		  {
			  sucesstoast("Invitation Accepted.");

			  redirectToMainPage("/");

			  setSpinner(false);
			  setCode(res.data.code);
			} 
		  else if (res.data.code === 403) 
		  {
			  setSpinner(false);
			  setCode(res.data.code);
			  setMessage(res.data.data);        		
			} 
		  else if (res.data.code === 401) 
		  {
			  setSpinner(false);
			  setCode(res.data.code);
			  setMessage(res.data.data);
			} 
		  else 
		  {
			  errortoast( "We are facing some technical issues. Please try again after sometime." );
			}
	  });
	}
};