import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const designThemeApiSlice = createApi({
  reducerPath: "webBuilder",
  keepUnusedDataFor: 1800,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "api/user",
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("amstoken")}`
      );
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getDesignThemeData: builder.query({
      query: () => `/get_style/${btoa(localStorage.getItem("Appid"))}`,
      providesTags: ["DesignThemeSave"],
      keepUnusedDataFor: 15 * 60
    }),
    saveDesignThemeData: builder.mutation({
      query: (payload) => ({
        url: "/style",
        method: "POST",
        body: payload
      }),
      invalidatesTags: ["DesignThemeSave"]
    })
  })
});

export const { useGetDesignThemeDataQuery, useSaveDesignThemeDataMutation } =
  designThemeApiSlice;
