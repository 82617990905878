import axios from "axios";
import { errortoast, sucesstoast } from "../../Dashboard/Toaster";

const Url = process.env.REACT_APP_API_URL + "api/user/";
const url = process.env.REACT_APP_API_URL + "api/";

export const Verifyapistatus= (state,callback) => {
  const headers = {
    "Content-Type": "application/json",
    'X-App-Device-Type':'ios',
    'X-App-Code-Ver':localStorage.getItem("Apiversion"),
  };
 
    let sendwrl = atob(localStorage.getItem("weburl"));
		   if(atob(localStorage.getItem("weburl"))[atob(localStorage.getItem("weburl")).length - 1] === "/"){
			  sendwrl = atob(localStorage.getItem("weburl"))
		   }else{
		      sendwrl = atob(localStorage.getItem("weburl")) + "/"
           }
  
    axios.get(process.env.REACT_APP_cors_api + sendwrl.replace("www.", "") + "wp-json/wc/v3/ams-menu-names?timestamp="+new Date().getTime(),{
        headers:headers,
        data:{},
    }).then(res => {
      if(res.status === 200){
		  state.setState({
           error:false,
           spinner:false,
         })
        callback()
      }
     
    }).catch(error => {
        //errortoast("We're having trouble connecting to your website. Please check your connectivity settings.")
          state.setState({
           spinner:false,
           error:true
          })
		  callback()
    })
  }

  //Api for iframe
  export const iframeApi= (params,state, callback) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    axios.get(Url + "preview/" + params.app_id,{
      headers: headers
        }).then(res => {
          //https://appetize.io/embed/bm55vex6651xqxqjvav7jmm03g?device=pixel4&scale=77&autoplay=false&orientation=portrait&deviceColor=white&xdocMsg=true&centered=true&params=
            if(res.data.code === 200){
              //bm55vex6651xqxqjvav7jmm03g
            //  https://appetize.io/app/86brvhcnbreputax92rdtrx68w?device=iphone11promax&scale=75&orientation=portrait&osVersion=13.3&deviceColor=white&debug=true
                state.setState({
                    data: encodeURIComponent(JSON.stringify({
                        "AmsClientID": res.data.data.AmsClientID.toString(),
                        "AmsClientSecret": res.data.data.AmsClientSecret,
                        "AMSEnvironment": res.data.data.AMSEnvironment
                    })),
                    iosdata: encodeURIComponent(JSON.stringify({
                        "AmsClientID": res.data.data.AmsClientID.toString(),
                        "AmsClientSecret": res.data.data.AmsClientSecret,
                        "AMSEnvironment": res.data.data.AMSEnvironment
                    })),
					          connectivity_log:res.data.data.latest_connectivity_log,
                    simulator_status:res.data.simulator_status
                })
				callback()
          }else{
           
            state.setState({
                data:"",
                iosdata:"",
                connectivity_log:res.data.data.latest_connectivity_log !== undefined ? res.data.data.latest_connectivity_log : null,
                simulator_status:res.data.simulator_status !== undefined ? res.data.simulator_status : null
            })
            callback()
          }
        
        })
   
  }

  export const Createlambdatoken = (state) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("amstoken"),
    };
  
    axios
      .get(url + "app/generate-preview-session-token/" + btoa(localStorage.getItem("Appid")), {
        headers: headers,
      })
      .then((res) => 
        {
          if (res.data.code === 200) 
            {
              state.setState({
                lambdatoken: res.data.token,
                androidAppUrl:  res.data?.LAMBDATEST_ANDROID_CUSTOM_APP_URL,
                iosAppUrl: res.data?.LAMBDATEST_IOS_CUSTOM_APP_URL,
                androidWordPressAppUrl: res.data?.LAMBDATEST_ANDROID_WP_APP_URL,
                iosWordPressAppUrl: res.data?.LAMBDATEST_IOS_WP_APP_URL,
              }, () => {
                lambdaGetLocations(res.data.token, state)
                lambdaGetLanguages(res.data.token, state)
              });
            }      
      });
  };

  export const CreateIOSlambdatoken = (state) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("amstoken"),
    };
  
    axios
      .get(url + "app/generate-preview-session-token/" + btoa(localStorage.getItem("Appid")), {
        headers: headers,
      })
      .then((res) => 
        {
          if (res.data.code === 200) 
            {
              state.setState({
                ioslambdatoken: res.data.token,
              });
            }      
      });
  };

  export const GetsplashApi = (state, callback) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("amstoken"),
    };
  
    axios
      .get(Url + "get_splash/" + btoa(localStorage.getItem("Appid")), {
        headers: headers,
      })
      .then((res) => {
        if (res.data.code === 200) {
          state.setState({
            is_connected: res.data.is_connected !== null && res.data.is_connected !== '' ? res.data.is_connected : 0,
            is_verified: res.data.is_verified !== null && res.data.is_verified !== '' ? res.data.is_verified : 0,
            website_type: res.data.data !== null && res.data.data !== '' ? res.data.data.website_type  : 2,
          });
          localStorage.setItem("weburl", btoa(res.data.web_url));
          callback();
        }
      });
  };


  export const lambdaStartRecording =  (lambdaToken, state, device, callback) => {
      let config = {
      method: 'post',
      headers: { 
        'Authorization': 'Bearer '+ lambdaToken, 
        'Content-Type': 'application/json'
      },
      data : {
        "name": "sample-video"
      }
    };
     axios('https://manual-api.lambdatest.com/api-gateway/v1.0/api/videostream?type=start', config)
     .then((res) => {
      const uniqueKey = res.data.key.split('/video/')[1].split('/')[0]
      if (res.status === 200) {
        if(device === 'ios'){
          state.setState({
            iosRecording: true,
            recordingUniqueId: uniqueKey
          });
      } else {
          state.setState({
            androidRecording: true,
            recordingUniqueIdAndroid: uniqueKey

          });
        }
        callback(device)
      }
    }).catch(err =>  {
      errortoast(err.message)
    })
  }

  export const lambdaStopRecording =  (lambdaToken, state, device, callback) => {
    let config = {
      method: 'post',
      headers: { 
        'Authorization': 'Bearer '+ lambdaToken, 
        'Content-Type': 'application/json'
      },
      // IMP!! need to pass empty object
      data : {}
    };
     axios('https://manual-api.lambdatest.com/api-gateway/v1.0/api/videostream?type=stop', config)
     .then((res) => {
      if (res.status === 200) {
        if(device === 'ios'){
          state.setState({
            iosRecording: false,
            recordingProcessing:true
          });
      } else {
          state.setState({
            androidRecording: false,
            recordingProcessingAndroid:true
          });
        }
        callback(device)
      }
    }).catch(err =>  {
      errortoast(err.message)
      if(device === 'ios'){
        state.setState({
          iosRecording: false,
          });
      } else {
          state.setState({
            androidRecording: false,
          });
      }
    })
  }

  export const lambdaGetRecording =  async (lambdaToken, state) => {
    try{
      let config = {
        method: 'get',
        headers: { 
          Authorization: "Bearer " + localStorage.getItem("amstoken"),
  
        },
      };
      const apiUrl = `${url}app/get-lambdatest-gallery/${btoa(localStorage.getItem("Appid"))}?token=${lambdaToken}`
      const res = await axios(apiUrl, config)
      return res
    }catch(err){
      errortoast(err.message)
    }
 
  }

  export const updateLambdaLocation= (lambdaToken, compState, device) => {
    let config = {
      method: 'post',
      headers: { 
        'Content-Type': 'application/json',
        Authorization: "Bearer " + localStorage.getItem("amstoken"),
      },
      data: {...compState.state[device === "ios" ? 'selectedLocation': 'selectedLocationAndroid'], app_id: parseInt(localStorage.getItem("Appid")), 
      token: lambdaToken}
    };
     axios(`${url}app/change-lambdatest-proxy`, config)
     .then((res) => {
      if (res.data.code === 200) {
        if(res.data.message === "Update proxy successfull"){
          sucesstoast('Location updated')
          compState.setState({mdlunlink: false, updatingLocation: false})
        }
      } else {
        errortoast('Error updating location')
        compState.setState({ updatingLocation: false})
      }
    }).catch(err =>  {
      
      errortoast(err.message)
      compState.setState({ updatingLocation: false})
    })
  }

  export const updateLanguage = (lambdaToken, compState, device) => {
    let config = {
      method: 'post',
      headers: { 
        Authorization: "Bearer " + localStorage.getItem("amstoken"),
        'Content-Type': 'application/json'
      },
      data: {
        language: device == 'ios' ? compState.state.selectedLanguage?.mac_code : compState.state.selectedLanguageAndroid?.android_code,
        app_id: parseInt(localStorage.getItem("Appid")), 
        token: lambdaToken,
        type:  device == 'ios' ? 'changeLang' : 'data'
      }
    };
     axios(`${url}app/change-lambdatest-langauge`, config)
     .then((res) => {
      if (res.data.code === 200) {
        compState.setState({languageModal: false, updatingLanguage: false })
        sucesstoast('Language updated')
      } else {
        errortoast('Error updating language')
        compState.setState({ updatingLanguage: false})
      }
    }).catch(err =>  {
      
      errortoast(err.message)
      compState.setState({ updatingLanguage: false })
    })
  }

  function concatAndRemoveKeys(data) {
    let result = [];
    for (let key in data) {
        if (data.hasOwnProperty(key)) {
            result = result.concat(data[key]);
        }
    }
    return result.sort((a,b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
  }

  export const lambdaGetLocations =  (lambdaToken, state) => {
    let config = {
      method: 'get',
      headers: { 
        'Authorization': 'Bearer '+ lambdaToken, 
        'Content-Type': 'application/json'
      },
    };
     axios('https://manual-api.lambdatest.com/ltms/locations', config)
     .then((res) => {
      if (res.status === 200) {
        state.setState({
          locationsArr: concatAndRemoveKeys(res.data),
          locationsArrCopy: concatAndRemoveKeys(res.data),
        })
      }
    }).catch(err =>  {
      errortoast(err.message)
    })
  }

  export const lambdaGetLanguages =  (lambdaToken, state) => {
    let config = {
      method: 'get',
      headers: { 
        'Authorization': 'Bearer '+ lambdaToken, 
        'Content-Type': 'application/json'
      },
    };
     axios('https://manual-api.lambdatest.com/keyboard', config)
     .then((res) => {
      if (res.status === 200 && res.data.data) {
        const androidLanguageArr = res.data.data.filter(lang => lang.android_code);
        const iosLanguageArr = res.data.data.filter(lang => lang.mac_code);
        state.setState({
          androidLanguages: androidLanguageArr,
          iosLanguages: iosLanguageArr,
          androidLanguagesCopy: androidLanguageArr,
          iosLanguagesCopy: iosLanguageArr,
        })
      }
    }).catch(err =>  {
      errortoast(err.message)
    })
  }
