import React, { Suspense } from "react";

import { Link } from "react-router-dom";
import Header from "../Header";
import Select from "react-select";
import moment from "moment";
import {
  Getuserdataadvaced,
  Appsumoappstatus,
  Unsuspend,
  Savedefaultdata,
  WorkspaceInfoById,
  getmyappsWorkspace,
} from "../../Api/Dashboard/DashboardApi";
import {
  Media,
  Modal,
ModalHeader,
ModalBody,
} from "reactstrap";
import { Verifyemailaccount } from "../../Api/Authenticate/Api";
import { Spinner } from "reactstrap";
import { errortoast, sucesstoast } from "../Toaster";
import { Foot } from "../Foot";
import BottomScrollListener from "react-bottom-scroll-listener";
import Notvalidateuser from "../Reusable/Notvalideuser";
import userdefaultImgOnError from "../../assets/images/dashboard/my-account.png";
import wpdefaultImgOnError from "../../assets/images/dashboard/workspace/workspace-default-img.png";
import { Advancedsearch } from "./Context";
import "./Myapps.css";
import { AppCloneApi } from "../../Api/MyApps/MyAppsApi";
const Search = React.lazy(() => import("./Advancesearch"));
const Alertpopup = React.lazy(() => import("../Reusable/Appsumopopup"));
export default class Myapps extends React.Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      userdata: [],
      spinner: true,
      imagespinner: true,
      modal: false,
      appid: "",
      appsumo_app_id: "",
      roledata: [],
      pageval: 1,
      handlepage: ["1"],
      searchvalue: "",
      search_field: "",
      datanumber: "",
      bottomspinner: false,
      showsearch: false,
      user_status: 1,
      status_code: 200,
      submitval_verifypopup: "I’m sure",
      loader_remove_verify: "d-none",
      loaderverify_remove: require("../../assets/images/dashboard/loader.gif")
        .default,
      //for popup
      title: "",
      description: "",
      hitpopup: false,
      bothbutton: true,
      submitval_confirmpop: "Yes",
      loader_remove: "d-none",
      loaderimage_remove: require("../../assets/images/dashboard/loader.gif")
        .default,
      //appsumohandling
      appsumodata: [],
      helpDropdownOpen: false,
      tiervalue: "Tier 1 (2/5 apps)",
      //check for appsumo
      customer_type: null,
      is_appsumo_user: null,
      advanceMenuSearch: false,
      advancesearch: "Search",
      advancesearchloader: "d-none",
      advancesearchimage: require("../../assets/images/signup/Loader_GIF.gif")
        .default,
      selectionarray: ["1"],
      list1: "",
      list2: "",
      list3: "",
      list4: "",
      list5: "",
      list1_else: "",
      list1_app_status: "",
      list1_status: "",
      list1_addon_status: "",
      list2_else: "",
      list2_app_status: "",
      list2_addon_status: "",
      list2_status: "",
      list3_else: "",
      list3_app_status: "",
      list3_addon_status: "",
      list3_status: "",
      list4_else: "",
      list4_app_status: "",
      list4_addon_status: "",
      list4_status: "",
      list5_else: "",
      list5_app_status: "",
      list5_addon_status: "",
      list5_status: "",
      list1_licence: "",
      list2_licence: "",
      list3_licence: "",
      list4_licence: "",
      list5_licence: "",
      applyhit: false,
      selection_array: [],
      suspended_app_id: "",
      workspace_subscription: "",
      arrayForSrchTag: [
        {
          title: "",
          value: "",
          index: "1",
        },
      ],
      array2ForSrchTag: [],
      showSrchTag: false,
      showSrchTag1: false,
      searchvalueforbutton: '',
      invitedWorkspaceData: [],
      menuData: [],
      checked:true,
      openClonePopUp:false,
      cloneBtnText:"Clone",
      cloneBtnLoader:"d-none",
      appIdForClone:"",
      optionsForBilling: [
        // { value:"",label:"Select" ,isDisabled:true } ,
        { value: "Owner name", label: "Owner name" },
        { value: "Owner email", label: "Owner email" },
        { value: "Domain", label: "Domain" },
        { value: "Plan", label: "Plan" },
        { value: "App name", label: "App name" },
        { value: "App id", label: "App id" },
        { value: "Client id", label: "Client id" },
        { value: "User id", label: "User id" },
        { value: "Type", label: "Type" },
        { value: "Reseller", label: "Reseller" },
        { value: "Payment gateway", label: "Payment gateway" },
      ],
      cloneAppName:"",
      isShareWithSameTeam:false,
    };

  }

  handleClickOutside(event) {
    if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) 
    {
      document.getElementById("item-2").checked = true;
    }
  }

  handle_status_changed = () => {
    let app_id = parseInt(this.state.suspended_app_id);
    const params = {
      app_id: app_id,
    };
    Unsuspend(params, this);
  };
  handleapply_data = () => {
    let selectionarray = this.state.selectionarray;



    let sendvalue = [];
    if (selectionarray.indexOf("1") > -1) {

      if (this.state.list1 === "name")
        sendvalue.push({ name: this.state.list1_else });
      if (this.state.list1 === "email")
        sendvalue.push({ email: this.state.list1_else });
      if (this.state.list1 === "domain")
        sendvalue.push({ domain: this.state.list1_else });
      if (this.state.list1 === "plan_code")
        sendvalue.push({ plan_code: this.state.list1_else });
      if (this.state.list1 === "app_name")
        sendvalue.push({ app_name: this.state.list1_else });
      if (this.state.list1 === "app_id")
        sendvalue.push({ app_id: this.state.list1_else });
      if (this.state.list1 === "client_id")
        sendvalue.push({ client_id: this.state.list1_else });
      if (this.state.list1 === "user_id")
        sendvalue.push({ user_id: this.state.list1_else });
      if (this.state.list1 === "technology")
        sendvalue.push({ technology: this.state.list1_technology });
      if (this.state.list1 === "reseller")
        sendvalue.push({ reseller: this.state.list1_reseller });
      if (this.state.list1 === "payment_gateway")
        sendvalue.push({ payment_gateway: this.state.list1_payment_gateway });
    }
    if (selectionarray.indexOf("2") > -1) {
      if (this.state.list2 === "name")
        sendvalue.push({ name: this.state.list2_else });
      if (this.state.list2 === "email")
        sendvalue.push({ email: this.state.list2_else });
      if (this.state.list2 === "domain")
        sendvalue.push({ domain: this.state.list2_else });
      if (this.state.list2 === "plan_code")
        sendvalue.push({ plan_code: this.state.list2_else });
      if (this.state.list2 === "app_name")
        sendvalue.push({ app_name: this.state.list2_else });
      if (this.state.list2 === "app_id")
        sendvalue.push({ app_id: this.state.list2_else });
      if (this.state.list2 === "client_id")
        sendvalue.push({ client_id: this.state.list2_else });
      if (this.state.list2 === "user_id")
        sendvalue.push({ user_id: this.state.list2_else });
      if (this.state.list2 === "technology")
        sendvalue.push({ technology: this.state.list2_technology });
      if (this.state.list2 === "reseller")
        sendvalue.push({ reseller: this.state.list2_reseller });
      if (this.state.list2 === "payment_gateway")
        sendvalue.push({ payment_gateway: this.state.list2_payment_gateway });
    }
    if (selectionarray.indexOf("3") > -1) {
      if (this.state.list3 === "name")
        sendvalue.push({ name: this.state.list3_else });
      if (this.state.list3 === "email")
        sendvalue.push({ email: this.state.list3_else });
      if (this.state.list3 === "domain")
        sendvalue.push({ domain: this.state.list3_else });
      if (this.state.list3 === "plan_code")
        sendvalue.push({ plan_code: this.state.list3_else });
      if (this.state.list3 === "app_name")
        sendvalue.push({ app_name: this.state.list3_else });
      if (this.state.list3 === "app_id")
        sendvalue.push({ app_id: this.state.list3_else });
      if (this.state.list3 === "client_id")
        sendvalue.push({ client_id: this.state.list3_else });
      if (this.state.list3 === "user_id")
        sendvalue.push({ user_id: this.state.list3_else });
      if (this.state.list3 === "technology")
        sendvalue.push({ technology: this.state.list3_technology });
      if (this.state.list3 === "reseller")
        sendvalue.push({ reseller: this.state.list3_reseller });
      if (this.state.list3 === "payment_gateway")
        sendvalue.push({ payment_gateway: this.state.list3_payment_gateway });
    }
    if (selectionarray.indexOf("4") > -1) {
      if (this.state.list4 === "name")
        sendvalue.push({ name: this.state.list4_else });
      if (this.state.list4 === "email")
        sendvalue.push({ email: this.state.list4_else });
      if (this.state.list4 === "domain")
        sendvalue.push({ domain: this.state.list4_else });
      if (this.state.list4 === "plan_code")
        sendvalue.push({ plan_code: this.state.list4_else });
      if (this.state.list4 === "app_name")
        sendvalue.push({ app_name: this.state.list4_else });
      if (this.state.list4 === "app_id")
        sendvalue.push({ app_id: this.state.list4_else });
      if (this.state.list4 === "client_id")
        sendvalue.push({ client_id: this.state.list4_else });
      if (this.state.list4 === "user_id")
        sendvalue.push({ user_id: this.state.list4_else });
      if (this.state.list4 === "technology")
        sendvalue.push({ technology: this.state.list4_technology });
      if (this.state.list4 === "reseller")
        sendvalue.push({ reseller: this.state.list4_reseller });
      if (this.state.list4 === "payment_gateway")
        sendvalue.push({ payment_gateway: this.state.list4_payment_gateway });
    }
    if (selectionarray.indexOf("5") > -1) {
      if (this.state.list5 === "name")
        sendvalue.push({ name: this.state.list5_else });
      if (this.state.list5 === "email")
        sendvalue.push({ email: this.state.list5_else });
      if (this.state.list5 === "domain")
        sendvalue.push({ domain: this.state.list5_else });
      if (this.state.list5 === "plan_code")
        sendvalue.push({ plan_code: this.state.list5_else });
      if (this.state.list5 === "app_name")
        sendvalue.push({ app_name: this.state.list5_else });
      if (this.state.list5 === "app_id")
        sendvalue.push({ app_id: this.state.list5_else });
      if (this.state.list5 === "client_id")
        sendvalue.push({ client_id: this.state.list5_else });
      if (this.state.list5 === "user_id")
        sendvalue.push({ user_id: this.state.list5_else });
      if (this.state.list5 === "technology")
        sendvalue.push({ technology: this.state.list5_technology });
      if (this.state.list5 === "reseller")
        sendvalue.push({ reseller: this.state.list5_reseller });
      if (this.state.list5 === "payment_gateway")
        sendvalue.push({ payment_gateway: this.state.list5_payment_gateway });
    }

    const pagination = {
      page: this.state.pageval,
      limit: 20,
      search_field: "",
      workspace_id:
        this.props.match.params.workspaceId === undefined ||
          this.props.match.params.workspaceId === "undefined"
          ? ""
          : this.props.match.params.workspaceId,
      name: sendvalue
        .filter((res) => res.name)
        .map((res) => res.name)
        .toString(),
      email: sendvalue
        .filter((res) => res.email)
        .map((res) => res.email)
        .toString(),
      domain: sendvalue
        .filter((res) => res.domain)
        .map((res) => res.domain)
        .toString(),
      plan_code: sendvalue
        .filter((res) => res.plan_code)
        .map((res) => res.plan_code)
        .toString(),
      app_name: sendvalue
        .filter((res) => res.app_name)
        .map((res) => res.app_name)
        .toString(),
      app_id: sendvalue
        .filter((res) => res.app_id)
        .map((res) => res.app_id)
        .toString(),
      client_id: sendvalue
        .filter((res) => res.client_id)
        .map((res) => res.client_id)
        .toString(),
      user_id: sendvalue
        .filter((res) => res.user_id)
        .map((res) => res.user_id)
        .toString(),
      reseller: sendvalue
        .filter((res) => res.reseller)
        .map((res) => parseInt(res.reseller))
        .toString(),
      payment_gateway: sendvalue
        .filter((res) => res.payment_gateway)
        .map((res) => res.payment_gateway)
        .toString(),
      technology: sendvalue
        .filter((res) => res.technology)
        .map((res) => res.technology)
        .toString(),
    };

    return pagination;
  };
  handleapply = () => {
    this.setState(
      {
        advancesearch: "",
        advancesearchloader: "load",
        pageval: 1,
        applyhit: true,
        searchvalue: "",
        search_field:"",
        showSrchTag: true,
        showSrchTag1: true,
      },
      () => {
        let getapply_data = this.handleapply_data();

        const arrayForSrchTag = this.state.arrayForSrchTag;



        if (arrayForSrchTag[0].title == '' && arrayForSrchTag[0].value == '') {

          this.setState({
            showSrchTag: false,
          });
        }

        arrayForSrchTag.sort((a, b) => a.index - b.index);
        this.setState({
          arrayForSrchTag: arrayForSrchTag,
        });

        this.setState({ searchspinner: true });
        this.handlepagination(getapply_data);
      }
    );
  };
  handleContainerOnBottom = () => {
    if (this.state.bottomspinner === false) {
      if (this.state.datanumber < 20) {
      } else {
        this.setState({ pageval: 1 + this.state.pageval, bottomspinner: true });
        if (this.state.applyhit === true) {
          let getapply_data = this.handleapply_data();
          getapply_data.userdata = this.state.userdata;
          this.handlepagination(getapply_data);
        } else {
          const pagination = {
            page: this.state.pageval,
            limit: 20,
            search_field:
              this.state.search_field !== "" ? this.state.search_field : "",
            userdata: this.state.userdata,
            workspace_id:
              this.props.match.params.workspaceId === undefined ||
                this.props.match.params.workspaceId === "undefined"
                ? ""
                : this.props.match.params.workspaceId,
          };
          this.handlepagination(pagination);
        }
      }
    }
  };

  handlepagination = (pagination) => {
    Getuserdataadvaced(this, pagination);

    // const params = {
    //   default_app_id: null,
    //   default_agency_id: null,
    //   default_workspace_id: this.props.match.params.workspaceId === undefined || this.props.match.params.workspaceId === "undefined"
    //       ? "null"
    //       : parseInt(atob(this.props.match.params.workspaceId)),
    // };

    // Savedefaultdata(params);
  };

  // End Data
  handlereset = () => {
    this.setState(
      {
        selectionarray: ["1"],
        list1: "",
        pageval: 1,
        list1_else: "",
        list1_app_status: "paid",
        showSrchTag: false,
        list1_status: "2",
        selection_array: [],
        list1_addon_status: "app_store_design",
        applyhit: false,
        arrayForSrchTag: [
          {
            title: "",
            value: "",
            index: "1",
          },
        ],
        array2ForSrchTag: [],
      },
      () => {
        const pagination = {
          page: this.state.pageval,
          limit: 20,
          search_field: "",
          workspace_id:
            this.props.match.params.workspaceId === undefined ||
              this.props.match.params.workspaceId === "undefined"
              ? ""
              : this.props.match.params.workspaceId,
        };
        // this.setState({ searchspinner: true });
        // this.handlepagination(pagination);
      }
    );
  };

  handle_else = (e, em) => {
    const arrayForSrchTag = this.state.arrayForSrchTag;

    if (em === "1") {
      this.setState({
        list1_else: e.target.value,
      });
    }
    if (em === "2") {
      this.setState({
        list2_else: e.target.value,
      });
    }
    if (em === "3") {
      this.setState({
        list3_else: e.target.value,
      });
    }
    if (em === "4") {
      this.setState({
        list4_else: e.target.value,
      });
    }
    if (em === "5") {
      this.setState({
        list5_else: e.target.value,
      });
    }

    setTimeout(() => {
      arrayForSrchTag.map((row) => {
        if (parseInt(row.index) === parseInt(em)) {
          row.value = this.state["list" + em + "_else"];
        }
      });
      let getapply_data = this.handleapply_data();

      this.setState({
        searchvalueforbutton: getapply_data,
      });
    });

    this.setState({
      arrayForSrchTag: arrayForSrchTag,
    });
  };

  handle_status = (e, em) => {
    const arrayForSrchTag = this.state.arrayForSrchTag;
    arrayForSrchTag.map((row) => {
      if (parseInt(row.index) === parseInt(em)) 
      {
        row.value = e.target.options[e.target.selectedIndex].text === "Select"
                  ? ""
                    : e.target.options[e.target.selectedIndex].text;
      }
    });
    if (em === "1") {
      this.setState({
        [e.target.id]: e.target.value,
      });
    }
    if (em === "2") {
      this.setState({
        [e.target.id]: e.target.value,
      });
    }
    if (em === "3") {
      this.setState({
        [e.target.id]: e.target.value,
      });
    }
    if (em === "4") {
      this.setState({
        [e.target.id]: e.target.value,
      });
    }
    if (em === "5") {
      this.setState({
        [e.target.id]: e.target.value,
      });
    }
    this.setState({
      arrayForSrchTag: arrayForSrchTag,
    });

    setTimeout(() => {
      let getapply_data = this.handleapply_data();

      this.setState({
        searchvalueforbutton: getapply_data,
      });
    });
  };

  mobile_handle_status = (e, em) => {
    const arrayForSrchTag = this.state.arrayForSrchTag;
    arrayForSrchTag.map((row) => {
      if (parseInt(row.index) === parseInt(em)) 
      {
        row.value = e.target.options[e.target.selectedIndex].text === "Select"
                  ? ""
                    : e.target.options[e.target.selectedIndex].text;
      }
    });
    if (em === "1") {
      this.setState({
        [e.target.id]: e.value,
      });
    }
    if (em === "2") {
      this.setState({
        [e.target.id]: e.value,
      });
    }
    if (em === "3") {
      this.setState({
        [e.target.id]: e.value,
      });
    }
    if (em === "4") {
      this.setState({
        [e.target.id]: e.value,
      });
    }
    if (em === "5") {
      this.setState({
        [e.target.id]: e.value,
      });
    }
    this.setState({
      arrayForSrchTag: arrayForSrchTag,
    });

    setTimeout(() => {
      let getapply_data = this.handleapply_data();

      this.setState({
        searchvalueforbutton: getapply_data,
      });
    });
  };

  handleapp_status = (e, em) => {
    const arrayForSrchTag = this.state.arrayForSrchTag;

    arrayForSrchTag.map((row) => {
      if (parseInt(row.index) === parseInt(em)) {
        // row.value =
        //   e.target.options[e.target.selectedIndex].text === "Select"
        //     ? ""
        //     : e.target.options[e.target.selectedIndex].text;
        row.value =
          e.value === ""
            ? ""
            : e.value;
      }
    });

    if (em === "1") {

      this.setState({
        list1_technology: e.value,
      });
    }
    if (em === "2") {
      this.setState({
        list2_technology: e.value,
      });
    }
    if (em === "3") {
      this.setState({
        list3_technology: e.value,
      });
    }
    if (em === "4") {
      this.setState({
        list4_technology: e.value,
      });
    }
    if (em === "5") {
      this.setState({
        list5_technology: e.value,
      });
    }
    this.setState({
      arrayForSrchTag: arrayForSrchTag,
    });
    setTimeout(() => {
      let getapply_data = this.handleapply_data();

      this.setState({
        searchvalueforbutton: getapply_data,
      });
    });
  };

  handlereseller_status = (e, em) => {
    const arrayForSrchTag = this.state.arrayForSrchTag;
    arrayForSrchTag.map((row) => {
      if (parseInt(row.index) === parseInt(em)) {
        // row.value =
        //   e.target.options[e.target.selectedIndex].text === "Select"
        //     ? ""
        //     : e.target.options[e.target.selectedIndex].text;
        row.value =
          e.value === "Select"
            ? ""
            : e.value;
      }
    });

    // if (em === "1") {
    //   this.setState({
    //     list1_reseller: e.target.value,
    //   });
    // }
    // if (em === "2") {
    //   this.setState({
    //     list2_reseller: e.target.value,
    //   });
    // }
    // if (em === "3") {
    //   this.setState({
    //     list3_reseller: e.target.value,
    //   });
    // }
    // if (em === "4") {
    //   this.setState({
    //     list4_reseller: e.target.value,
    //   });
    // }
    // if (em === "5") {
    //   this.setState({
    //     list5_reseller: e.target.value,
    //   });
    // }
    if (em === "1") {
      this.setState({
        list1_reseller: e.value,
      });
    }
    if (em === "2") {
      this.setState({
        list2_reseller: e.value,
      });
    }
    if (em === "3") {
      this.setState({
        list3_reseller: e.value,
      });
    }
    if (em === "4") {
      this.setState({
        list4_reseller: e.value,
      });
    }
    if (em === "5") {
      this.setState({
        list5_reseller: e.value,
      });
    }
    this.setState({
      arrayForSrchTag: arrayForSrchTag,
    });
    setTimeout(() => {
      let getapply_data = this.handleapply_data();

      this.setState({
        searchvalueforbutton: getapply_data,
      });
    });
  };

  handlepayment_gateway = (e, em) => {
    const arrayForSrchTag = this.state.arrayForSrchTag;
    arrayForSrchTag.map((row) => {
      if (parseInt(row.index) === parseInt(em)) {
        // row.value =
        //   e.target.options[e.target.selectedIndex].text === "Select"
        //     ? ""
        //     : e.target.options[e.target.selectedIndex].text;
        row.value =
          e.value === "Select"
            ? ""
            : e.value;
      }
    });

    // if (em === "1") {
    //   this.setState({
    //     list1_payment_gateway: e.target.value,
    //   });
    // }
    // if (em === "2") {
    //   this.setState({
    //     list2_payment_gateway: e.target.value,
    //   });
    // }
    // if (em === "3") {
    //   this.setState({
    //     list3_payment_gateway: e.target.value,
    //   });
    // }
    // if (em === "4") {
    //   this.setState({
    //     list4_payment_gateway: e.target.value,
    //   });
    // }
    // if (em === "5") {
    //   this.setState({
    //     list5_payment_gateway: e.target.value,
    //   });
    // }
    if (em === "1") {
      this.setState({
        list1_payment_gateway: e.value,
      });
    }
    if (em === "2") {
      this.setState({
        list2_payment_gateway: e.value,
      });
    }
    if (em === "3") {
      this.setState({
        list3_payment_gateway: e.value,
      });
    }
    if (em === "4") {
      this.setState({
        list4_payment_gateway: e.value,
      });
    }
    if (em === "5") {
      this.setState({
        list5_payment_gateway: e.value,
      });
    }
    this.setState({
      arrayForSrchTag: arrayForSrchTag,
    });
    setTimeout(() => {
      let getapply_data = this.handleapply_data();

      this.setState({
        searchvalueforbutton: getapply_data,
      });
    });
  };

  handledeleterow = (e, from) => {
    let selection_array = this.state.selection_array;
    let getarray = this.state.selectionarray;


    const index = getarray.indexOf(e);
    if (index > -1) {
      getarray.splice(index, 1);
      selection_array.splice(index, 1);
    }
    if (e === "1")
      this.setState({
        list1: "",
        list1_else: "",
        list1_app_status: "",
        list1_status: "",
        list1_addon_status: "",
        list1_licence: "",
      });
    if (e === "2")
      this.setState({
        list2: "",
        list2_else: "",
        list2_app_status: "",
        list2_addon_status: "",
        list2_status: "",
        list2_licence: "",
      });
    if (e === "3")
      this.setState({
        list3: "",
        list3_else: "",
        list3_app_status: "",
        list3_addon_status: "",
        list3_status: "",
        list3_licence: "",
      });
    if (e === "4")
      this.setState({
        list4: "",
        list4_else: "",
        list4_app_status: "",
        list4_addon_status: "",
        list4_status: "",
        list4_licence: "",
      });
    if (e === "5")
      this.setState({
        list5: "",
        list5_else: "",
        list5_app_status: "",
        list5_addon_status: "",
        list5_status: "",
        list5_licence: "",
      });

    let arrayForSrchTag = this.state.arrayForSrchTag;
    arrayForSrchTag = arrayForSrchTag.filter(
      (row, indx) => parseInt(e) !== parseInt(row.index)
    );

    this.setState({
      selectionarray: getarray,
      selection_array: selection_array,
      arrayForSrchTag: arrayForSrchTag,
    });
    if (from === "callAPI") {
      this.handleapply();
    }
    if (
      from === "callAPI" &&
      parseInt(this.state.arrayForSrchTag.length) === 1
    ) {
      this.setState({
        selectionarray: ["1"],
        list1: "",
        pageval: 1,
        list1_else: "",
        list1_app_status: "paid",
        list1_status: "2",
        selection_array: [],
        list1_addon_status: "app_store_design",
        applyhit: false,
        showSrchTag: false,
        showSrchTag1: false,
        arrayForSrchTag: [
          {
            title: "",
            value: "",
            index: "1",
          },
        ],
        array2ForSrchTag: [],
      });
    }
  };

  handleaddfield = () => {
    let getarray = this.state.selectionarray;
    let arrayForSrchTag = this.state.arrayForSrchTag;
    let obj = {
      title: "",
      value: "",
      index: "",
    };
    arrayForSrchTag.push(obj);
    if (getarray.indexOf("1") === -1) {
      obj.index = "1";
      getarray.push("1");
    } else if (getarray.indexOf("2") === -1) {
      obj.index = "2";
      getarray.push("2");
    } else if (getarray.indexOf("3") === -1) {
      obj.index = "3";
      getarray.push("3");
    } else if (getarray.indexOf("4") === -1) {
      obj.index = "4";
      getarray.push("4");
    } else if (getarray.indexOf("5") === -1) {
      obj.index = "5";
      getarray.push("5");
    }
    this.setState({
      selectionarray: getarray,
      arrayForSrchTag: arrayForSrchTag,
    });
  };

  handleselection = (e, em) => 
  {
    const arrayForSrchTag = this.state.arrayForSrchTag;

    arrayForSrchTag.map((row) => {
      if (parseInt(row.index) === parseInt(em)) {


        // row.title = e.target.options[e.target.selectedIndex].text;
        row.title = e.value;
        row.value = "";
      }
    });

    let selection_array = this.state.selection_array;
    if (em === "1") {
      this.setState({
        // list1: e.target.value,
        list1: e.value
      });
      // if (selection_array.indexOf(e.target.value) === -1) {
      //   selection_array[0] = e.target.value;
      // }
      if (selection_array.indexOf(e.value) === -1) {
        selection_array[0] = e.value;
      }
    }
    if (em === "2") {
      // this.setState({
      //   list2: e.target.value,
      // });
      // if (selection_array.indexOf(e.target.value) === -1) {
      //   selection_array[1] = e.target.value;
      // }
      this.setState({
        list2: e.value,
      });
      if (selection_array.indexOf(e.value) === -1) {
        selection_array[1] = e.value;
      }
    }
    if (em === "3") {
      // this.setState({
      //   list3: e.target.value,
      // });
      // if (selection_array.indexOf(e.target.value) === -1) {
      //   selection_array[2] = e.target.value;
      // }
      this.setState({
        list3: e.value,
      });
      if (selection_array.indexOf(e.value) === -1) {
        selection_array[2] = e.value;
      }
    }
    if (em === "4") {
      // this.setState({
      //   list4: e.target.value,
      // });
      // if (selection_array.indexOf(e.target.value) === -1) {
      //   selection_array[3] = e.target.value;
      // }
      this.setState({
        list4: e.value,
      });
      if (selection_array.indexOf(e.value) === -1) {
        selection_array[3] = e.value;
      }
    }
    if (em === "5") {
      // this.setState({
      //   list5: e.target.value,
      // });
      // if (selection_array.indexOf(e.target.value) === -1) {
      //   selection_array[4] = e.target.value;
      // }
      this.setState({
        list5: e.value,
      });
      if (selection_array.indexOf(e.value) === -1) {
        selection_array[4] = e.value;
      }
    }
    this.setState({
      selection_array: selection_array,
      arrayForSrchTag: arrayForSrchTag,
    });
    setTimeout(() => {
      let getapply_data = this.handleapply_data();

      this.setState({
        searchvalueforbutton: getapply_data,
      });
    });
  };
  advanceMenu = () => {
    document.body.classList.add('opec');
    this.setState({
      advanceMenuSearch: !this.state.advanceMenuSearch,
      searchvalue: "",
      search_field:"",
    });
  };
  advanceMenucancel = () => {
    document.body.classList.remove('opec');
    this.setState({
      advanceMenuSearch: !this.state.advanceMenuSearch,
      searchvalue: "",
      search_field:"",
    });
  };
  helpToggle = () => {
    this.setState((prevState) => ({
      helpDropdownOpen: !prevState.helpDropdownOpen,
    }));
  };
  photoggle = (e, appid) => {
    e.persist();
    this.setState({
      modal: !this.state.modal,
      appid: appid,
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    
      if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
      {
        document.title = "Apps | AppMySite";
      }
      else 
      {
        document.title = "Apps |"+ ' ' +localStorage.getItem('agencyname');
      }
    document.body.classList.remove('opec');

    if (
      this.props.match.params.workspaceId === undefined ||
      this.props.match.params.workspaceId === "undefined"
    ) {
      localStorage.setItem("workspaceId", "");
      localStorage.setItem("logged_in_user_role_id", 1);


      localStorage.setItem("workspaceId", "");
      localStorage.setItem("workspaceName", "");
      localStorage.setItem("workspaceImage", "");
    }
    // if(window.location.pathname==="/apps"){

    // }
    const pagination = {
      page: this.state.pageval,
      limit: 20,
      search_field: "",
      workspace_id:
        this.props.match.params.workspaceId === undefined ||
          this.props.match.params.workspaceId === "undefined"
          ? ""
          : this.props.match.params.workspaceId,
    };

    if (
      this.props.match.params.workspaceId !== undefined &&
      this.props.match.params.workspaceId !== ""
    ) {
      WorkspaceInfoById(this.props.match.params.workspaceId);
    }

    this.handlepagination(pagination);
    getmyappsWorkspace(this, "", "", "all");
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setname = (value) => {
    let appshort = value.split(" ");

    let i;
    let apparray = [];

    if (appshort.length > 1) {
      for (i = 0; i < appshort.length; i++) {
        apparray.push(appshort[i].charAt(0).toUpperCase());
      }
      return apparray.join("").slice(0, 3);
    } else {
      return appshort.join("").slice(0, 2);
    }
  };
  handleAppdata = (
    user_id,
    status,
    appname,
    id,
    version,
    appshortname,
    subscription,
    store_url,
    plugin_data,
    gateway,
    dashboard_count,
    website_type,
    website_technology,
    is_verified,
    appicon_count,
    splash_icon_count,
    login_reg_count,
    style_count
  ) => {
    //if(this.state.roledata.role === 1){
    localStorage.setItem("user_id", user_id);
    //}
    if (id !== "") {
      localStorage.setItem("app_icon_count", appicon_count);
      localStorage.setItem("splash_icon_count", splash_icon_count);
      localStorage.setItem("login_register_icon_count", login_reg_count);
      localStorage.setItem("style_icon_count", style_count);
      localStorage.setItem("dashboard_icon_count", dashboard_count);
      localStorage.setItem("Appid", id);
      localStorage.setItem("Appname", appname);
      localStorage.setItem("Appversion", version);
      localStorage.setItem("appshortname", appshortname);
      localStorage.setItem("store_url", store_url);
      localStorage.setItem("plugin_dataactive", parseInt(plugin_data));
      localStorage.setItem("appsettingactive", parseInt(gateway));
      localStorage.setItem("personaliseactive", parseInt(dashboard_count));
      localStorage.setItem("website_type", parseInt(website_type));
      localStorage.setItem("website_type_select", parseInt(website_type));
      localStorage.setItem("website_technology", website_technology);
      localStorage.setItem("is_verified", is_verified);
      localStorage.removeItem("session");
      if (
        this.props.match.params.workspaceId !== "undefined" &&
        this.props.match.params.workspaceId !== undefined
      ) {
        localStorage.setItem(
          "workspaceId",
          atob(this.props.match.params.workspaceId)
        );
      } else {
        localStorage.setItem("workspaceId", "");
      }
      let subscriptionlength = subscription;

      if (
        subscriptionlength === null ||
        subscriptionlength.plan_name.indexOf("preview") !== -1
      ) {
        localStorage.setItem("subscriptionactive", "0");
        localStorage.setItem("plan_code", btoa("preview"));
        if (
          status === "duplicate" ||
          status === "deleted" ||
          status === "Cancelled"
        ) {
          window.location.href = "/apps";
        } else {
          window.location.href = `/app/dashboard`;
        }

        if (status === "duplicate") {
          this.setState({
            hitpopup: true,
            title: "Duplicate App Suspected",
            description:
              "This app appears to be a duplicate as the same domain was used to create another app. Would you still like to proceed?",
            bothbutton: false,
            suspended_app_id: id,
          });
        }
        if (status === "deleted") {
          // errortoast("App deleted");
        }
        if (status.toLowerCase() === "cancelled") {
          // errortoast("App deleted");
        }
      } else {
        let subpackagename = subscriptionlength.addon_codes;
        localStorage.setItem("plan_code", btoa(subscriptionlength.plan_code));
        localStorage.setItem(
          "subscriptionactive",
          btoa(JSON.stringify(subpackagename))
        );
        if (
          status === "duplicate" ||
          status === "deleted" ||
          status === "Cancelled"
          // ||status === "inactive"
        ) {
          window.location.href = "/apps";
        } else {
          window.location.href = `/app/dashboard`;
        }
        if (status === "duplicate") {
          this.setState({
            hitpopup: true,
            title: "Duplicate App Suspected",
            description:
              "This app appears to be a duplicate as the same domain was used to create another app. Would you still like to proceed?",
            bothbutton: false,
            suspended_app_id: id,
          });
        }
        //For appsumo client
        if (
          status === "inactive" &&
          subscription.plan_code
            .toString()
            .indexOf(process.env.REACT_APP_Lifetime_plan) !== -1
        ) {
          this.setState({
            hitpopup: true,
            title: "Your app is inactive",
            description:
              "This app is currently inactive.  Would you like to activate it now?",
            bothbutton: true,
            appsumo_app_id: id,
          });
        }
        //For appsumo_preview inactive client
        // if (
        //   status === "inactive" &&
        //   subscription.plan_code.toString() ===
        //     process.env.REACT_APP_Lifetime_preview
        // ) {
        //   this.setState({
        //     hitpopup: true,
        //     title: "License",
        //     description: "License not found",
        //     bothbutton: false,
        //   });
        // }
        if (status === "deleted") {
          // errortoast("App deleted");
        }
        if (status.toLowerCase() === "cancelled") {
          // errortoast("App deleted");
        }
      }
    }
  };
  //appsumo status
  handleapp_sumo_status = () => {
    const params = {
      app_id: this.state.appsumo_app_id,
    };
    Appsumoappstatus(params, this);
  };

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handlesearch = (e) => 
  {
    if(e.target.value != '')
    {
      this.setState({
        // searchvalue: e.target.value.replace(/^https?:\/\//i, ""),
        search_field: e.target.value.replace(/^https?:\/\//i, ""),
      });
    }
    else
    {
      this.setState({
        searchvalue: "",
        search_field: "",
      });
    }

    // if (e.target.value === "") {
    //   const pagination = {
    //     page: this.state.pageval,
    //     limit: 20,
    //     search_field: "",
    //     workspace_id:
    //       this.props.match.params.workspaceId === undefined ||
    //         this.props.match.params.workspaceId === "undefined"
    //         ? ""
    //         : this.props.match.params.workspaceId,
    //   };
    //   this.setState({ searchspinner: true });
    //   this.handlepagination(pagination);
    // }
  };

  handlecancelsearch = (e) =>
  {
    this.setState({
      search_field: "",
      searchvalue: "",
    });
      const pagination = {
        page: 1,
        limit: 20,
        search_field: "",
        workspace_id:
          this.props.match.params.workspaceId === undefined ||
            this.props.match.params.workspaceId === "undefined"
            ? ""
            : this.props.match.params.workspaceId,
      };
      this.setState({ searchspinner: true });
      this.handlepagination(pagination);
  }

  handlecancelvaluesearch = (e) =>
  {
    this.setState({
      search_field: "",
      searchvalue: "",
    });
  }


  handlesearchform = (e) => {
    this.setState({ array2ForSrchTag: [] });
    e.preventDefault();
    this.setState({ searchspinner: true, pageval: 1, applyhit: false, showSrchTag: false, showSrchTag1: true }, () => {
      const pagination = {
        page: 1,
        limit: 20,
        search_field: this.state.search_field,
        userdata: this.state.userdata,
        workspace_id:
          this.props.match.params.workspaceId === undefined ||
            this.props.match.params.workspaceId === "undefined"
            ? ""
            : this.props.match.params.workspaceId,
      };
      this.setState({ searchspinner: true });
      this.handlepagination(pagination);
    });
  };
  showsearch = () => {
    this.setState({
      showsearch: !this.state.showsearch,
    });
  };
  handleuser_status = () => {
    this.setState({
      user_status: 1,
    });
  };

  handlesend = () => {
    const params = {
      token: localStorage.getItem("amstoken"),
      check: "check",
    };
    Verifyemailaccount(params, this);
  };

  handleClonePopUp = (e,from,appID,appName) => { 
    
    if(from==="done"){
      const params = {
        app_id:this.state.appIdForClone,
        app_name:this.state.cloneAppName,
        is_share_with_team:this.state.isShareWithSameTeam ? 1 : 0,
      }

        this.setState({
          cloneBtnText:"",
          cloneBtnLoader: "d-block",
        });
        AppCloneApi(params,this);
      

    }else{
      if(appID){
        this.setState({
          appIdForClone : appID,
          cloneAppName:   (appName + " - Clone").substring(0,30)
        })
      }
      this.setState({
        openClonePopUp : !this.state.openClonePopUp,
      });
      
    }
    e.stopPropagation();
   }


   handleRefreshAfterClone = () => {
    this.setState({
      search_field:"",
      applyhit: false,
    })
    const pagination = {
      page: 1,
      limit: 20,
      search_field: "",
      workspace_id:
        this.props.match.params.workspaceId === undefined ||
          this.props.match.params.workspaceId === "undefined"
          ? ""
          : this.props.match.params.workspaceId,
    };
    window.scrollTo(0, 0);
    this.handlepagination(pagination);
   }

   handleChangeCloneName = (e) => {
    this.setState({
      cloneAppName:e.target.value,
    })
   }

   handleShareWithTeamCheckbox = (e) => {
   this.setState({
     isShareWithSameTeam:e.target.checked,
   })
   }
 

  render() {
    if (this.state.user_status === 0) {

      window.location.pathname = "/verify-email"
    }

    const handleprops = {
      state: this,
      handleuser_status: (e) => this.handleuser_status(),
    };
    const handleadvance = {
      state: this,
    };


    const returnWorkspaceStatus = (status) => {
      if (status === "active" || status === "live") {
        return (
          <p
            className="myapps_viewstatus-responsive  status-set-in-center"
            style={{
              color: "#47CE70",
              border: "1px solid #47CE70",
              backgroundColor: "#EFFFF4",
              fontSize: "10px !important",
              borderRadius: "20px",
              padding: "0px 7px 0px 7px"
            }}
          >
            Active
          </p>

        );
      } else if (
        status === "free" ||
        status === "paused" ||
        status === "cancelled" ||
        status === "canceled" ||
        status === "deleted"
      ) {
        return (
          <p
            className={
              "billings-viewstatus-responsive billings-viewstatus-responsive-red billings-viewstatus "
            }
            style={{
              textTransform: "capitalize",
              color: "#7782A1",
              // border: "1px solid #7782A1",
              backgroundColor: "#F1F3F9",
              display: "flex",
              alignItems: "center"
            }}
          >
            {status === "cancelled" ? "Canceled" : status}
          </p>
        );
      } else {
        return (

          <p
            className="myapps_viewstatus-responsive  status-set-in-center"
            style={{
              color: "#E20000",
              border: "1px solid #E20000",
              fontSize: "10px !important",
              borderRadius: "20px",
              padding: "0px 7px 0px 7px"
            }}
          >
            {status === "past_due" ? "Past Due" : status}
          </p>
        );
      }
    };
    const returnAppStatus = (status) => {
      if (status === "active" || status === "live") {
        return (
          <p
            className={
              status !== "free"
                ? "myapps_viewstatus-responsive "
                : "d-none"
            }
            style={{
              color: "#47CE70",
              border:
                "1px solid #47CE70",
              backgroundColor:
                "#EFFFF4",
            }}
          >
            Active
          </p>

        );
      } else if (
        // status === "free" ||
        status === "paused" ||
        status === "cancelled" ||
        status === "canceled" ||
        status === "deleted"
      ) {
        return (
          <p
            className={
              status !== "free"
                ? "myapps_viewstatus-responsive "
                : "d-none"
            }
            style={{
              textTransform: "capitalize",
              color: "#7782A1",
              // border: "1px solid #7782A1 !important",
              backgroundColor: "#F1F3F9",
              display: "flex",
              alignItems: "center"
            }}
          >
            {status === "cancelled" ? "Canceled" : status}
          </p>
        );
      } else {
        return (

          //   <p
          //   className="myapps_viewstatus-responsive  status-set-in-center"
          //   style={{
          //     color: "#E20000",
          //     border: "1px solid #E20000",
          //     // fontSize: "10px !important",
          //     borderRadius: "20px",
          //     padding: "0px 7px 0px 7px"
          //   }}
          // >
          //   {status === "cancelled" ? "Canceled" : status}
          // </p>
          <p
            className={

              status !== "free"
                ? "myapps_viewstatus-responsive  7"
                : "d-none"
            }
            style={{
              color:

                status === "active"
                  ? "#47CE70"
                  : "#E20000",
              border:

                status === "active"
                  ? "1px solid #47CE70"
                  : "1px solid #E20000",
            }}
          >
            {status === "past_due" ? "Past Due" : status}

          </p>
        );
      }
    };


    
    return (
      <>

        <Suspense fallback={""}>
          {this.state.hitpopup === true && <Alertpopup value={this} />}
        </Suspense>
        <BottomScrollListener
          onBottom={this.handleContainerOnBottom}
          offset={300}
        />
        {/* <div className="d-none d-md-block"> */}
        <Header
          myappsClass={"myapps-display-spec"}
          newlogoclass={"d-md-block d-none"}
          customlogoclass={"d-md-block d-none"}
          customclass={
            "header-responsive-new header-responsive-myapps-new header-shadow-mobile"
          }
          showsearch={(e) => this.showsearch()}
          from="myapps"
          workspace_subscription={this.state.workspace_subscription}
        />
        {/* </div> */}
        {/* Mobile View */}
        {/* <div className="myapps-filter-div">
        <div className="filtermain">
        <div className="myapps-filter-head">
          <h1>Filter</h1>
          <a>Reset</a>   
        </div>
        <div className="myapps-filter-seconddiv">
          <Select
                   placeholder=""   
                  //  defaultMenuIsOpen
                   classNamePrefix="select-filter" 
                   options={this.state.optionsForBilling}   
                      />
                     
          <div className="d-flex">
          <input
                                  type="text"
                                  placeholder="Enter app name"
                                  className="filter-input"
                                  
                                />
                                <div className="select-paymentgatewaydiv">
                                <Select
                                 placeholder="Select"
                                //  defaultMenuIsOpen
                                 classNamePrefix="select-paymentgateway" 
                                 options={this.state.optionsForBilling} 
                          />
                          </div>
          <div className="myapssdelete-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14.999" viewBox="0 0 15 14.999">
  <g id="Group_38004" data-name="Group 38004" transform="translate(-15 -15)">
    <g id="Group_3246" data-name="Group 3246" transform="translate(15 15.5)">
      <path id="Rectangle_2307" data-name="Rectangle 2307" d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z" transform="translate(1.5 1.999)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1"/>
      <path id="Rectangle_2308" data-name="Rectangle 2308" d="M1,0H4A1,1,0,0,1,5,1V2A0,0,0,0,1,5,2H0A0,0,0,0,1,0,2V1A1,1,0,0,1,1,0Z" transform="translate(5)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1"/>
      <g id="Group_3245" data-name="Group 3245" transform="translate(5.5 5.5)">
        <line id="Line_110" data-name="Line 110" y2="5" transform="translate(4 0)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1"/>
        <line id="Line_111" data-name="Line 111" y1="5" transform="translate(0 0)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1"/>
      </g>
      <line id="Line_112" data-name="Line 112" x2="15" transform="translate(0 1.999)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1"/>
    </g>
  </g>
</svg>
          </div>
          </div>
          <h2 className="myapps-filterand"> AND</h2>
          <a className="d-flex myapps-filteradd">  
          <img
                                    src={
                                      require("../../assets/images/dashboard/plus.png")
                                        .default
                                    }
                                    alt="plus"
                                    className=""
                                  />
                                  <p>Add filter</p></a>

                                 
        </div>
        <button className="filter-buttondisabled">Search</button>
        </div> */}

        {/* mobileview-option */}

        {/* <div className="filtersearch">
        <div className="filtersearch-head">
        <svg xmlns="http://www.w3.org/2000/svg" width="20.759" height="15.408" viewBox="0 0 20.759 15.408">
  <g id="Group_27465" data-name="Group 27465" transform="translate(-19.742 -22.295)">
    <path id="Path_81245" data-name="Path 81245" d="M-13783.922-19217h19.346" transform="translate(13805.077 19247)" fill="none" stroke="#7782a1" stroke-width="2"/>
    <path id="Path_82232" data-name="Path 82232" d="M14053.656,19255.426l-7,7,7,7" transform="translate(-14025.504 -19232.424)" fill="none" stroke="#7782a1" stroke-width="2"/>
  </g>
</svg>

        </div>
        <ul>
          <li>Owner name</li>
          <li>Owner email</li>
          <li>Domain</li>
          <li>Plan</li>
          <li>App name</li>
          <li>App id</li>
          <li>Client id</li>
          <li>User id</li>
          <li>Type</li>
          <li>Reseller</li>
          <li>Payment gateway</li>
        </ul>
       </div> */}

        {/* mobileview-option-end */}


        {/* </div> */}

        {/* <div className="filtersearch">
        <div className="filtersearch-head"></div>
        <ul>
          <li></li>
        </ul>
       </div> */}


        <div>
          {/* <div className="workspace-search d-flex d-md-none justify-content-between align-items-center workspace-myapps-search">
            <div className="text-search-bar myapps-bar">
              <input
                placeholder="Search"
                className="myapps-input"
              />
            </div>
            <div className="work-search-icon myapps-work-search">
              <img
                src={
                  require("../../assets/images/dashboard/workspace/add-workspace-search.png")
                    .default
                }
              />
            </div>
            <div className="search-funnel-new">
              <img
                src={
                  require("../../assets/images/dashboard/search-funnel.png")
                    .default
                }
              />
            </div>
          </div> */}
          {/* <div className="d-flex d-md-none justify-content-start filter-tag-myapps">
            <div className="d-flex justify-content-between align-items-center filter-tags">
              <h5>www.yourstore.com</h5>
              <img
                src={
                  require("../../assets/images/dashboard/filter-cross.png")
                    .default
                }
                className="filter-cross-img"
              />
            </div>
            <div className="d-flex justify-content-between align-items-center filter-tags">
              <h5>Paid</h5>
              <img
                src={
                  require("../../assets/images/dashboard/filter-cross.png")
                    .default
                }
                className="filter-cross-img"
              />
            </div>
          </div> */}

        </div>
        <section className="  iphone-full-width-myapps consistent-email-color myapps_mobileviewdiv" >
          <div
            className={this.state.user_status === 0 ? "myapps-notvaliduser" : " container-myapps-new myapps_container"}
          // style={{ paddingBottom: "30px" }}
          >
            {this.state.spinner ? (
              <div className="newspinner">
                <svg xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  style={{ margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s" }}
                  width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                  <g transform="translate(20 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                    <circle cx="0" cy="0" r="6" fill="#e6261f" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                      <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                        style={{ animationplaystate: "running", animationdelay: "0s" }}>
                      </animateTransform>
                    </circle>
                  </g>
                  <g transform="translate(40 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                    <circle cx="0" cy="0" r="6" fill="#f7d038" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                      <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                        style={{ animationplaystate: "running", animationdelay: "0s" }}>
                      </animateTransform>
                    </circle>
                  </g>
                  <g transform="translate(60 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                    <circle cx="0" cy="0" r="6" fill="#49da9a" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                      <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                        style={{ animationplaystate: "running", animationdelay: "0s" }}>

                      </animateTransform>
                    </circle>
                  </g>
                  <g transform="translate(80 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                    <circle cx="0" cy="0" r="6" fill="#4355db" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                      <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                        style={{ animationplaystate: "running", animationdelay: "0s" }}>

                      </animateTransform>
                    </circle>
                  </g>
                </svg>
              </div>
            )
              :
              (
                <>
                  {this.state.user_status === 0 ?
                    (
                      <></>
                      // <Notvalidateuser handleprops={handleprops} />
                    )
                    : (this.state.userdata.length < 1 && this.state.showSrchTag1 != true) ?
                      <>
                        <div className=" align-items-center myapps_topdiv">

                          <div className=" align-items-center myapps_account_flex ">
                            {this.props.match.params.workspaceId === undefined ||
                              this.props.match.params.workspaceId === "undefined" ? (
                              <div className="myapps-web-profileimg">
                                {localStorage.getItem("user_profile") === "" ||
                                  localStorage.getItem("user_profile") === null ||
                                  localStorage.getItem("user_profile") === "null" ||
                                  localStorage.getItem("user_profile") ===
                                  undefined ||
                                  localStorage.getItem("user_profile") ===
                                  "undefined" ||
                                  !localStorage.getItem("user_profile") ? (
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/my-account.png")
                                        .default
                                    }
                                    className="img-fluid"
                                    width="56px"
                                    borderRadius="50%"
                                    height="56px"
                                    alt="profile logo 6"
                                  />
                                ) : (
                                  <>
                                    {localStorage
                                      .getItem("user_profile")
                                      .indexOf("https") === -1 ? (
                                      <img
                                        src={
                                          process.env.REACT_APP_Image_Path +
                                          localStorage.getItem(
                                            "logged_in_user_id"
                                          ) +
                                          process.env.REACT_APP_Profile +
                                          localStorage.getItem("user_profile")
                                        }
                                        style={{
                                          borderRadius: "50%",
                                        }}
                                        width="56px"
                                        height="56px"
                                        alt="5"
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src =
                                            localStorage.getItem("workspaceId") !==
                                              "" &&
                                              localStorage.getItem("workspaceId") !==
                                              "undefined" &&
                                              localStorage.getItem("workspaceId") !==
                                              undefined &&
                                              localStorage.getItem("workspaceId") !==
                                              "null" &&
                                              localStorage.getItem("workspaceId") !==
                                              null
                                              ? wpdefaultImgOnError
                                              : userdefaultImgOnError;
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={localStorage.getItem("user_profile")}
                                        style={{
                                          borderRadius: "50%",
                                        }}
                                        width="56px"
                                        height="56px"
                                        alt="4"
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src =
                                            localStorage.getItem("workspaceId") !==
                                              "" &&
                                              localStorage.getItem("workspaceId") !==
                                              "undefined" &&
                                              localStorage.getItem("workspaceId") !==
                                              undefined &&
                                              localStorage.getItem("workspaceId") !==
                                              "null" &&
                                              localStorage.getItem("workspaceId") !==
                                              null
                                              ? wpdefaultImgOnError
                                              : userdefaultImgOnError;
                                        }}
                                      />
                                    )}
                                  </>
                                )}
                              </div>
                            ) : (
                              <div className="left-image-container">
                                {localStorage.getItem("workspaceImage") === "" ||
                                  localStorage.getItem("workspaceImage") === null ||
                                  localStorage.getItem("workspaceImage") === "null" ||
                                  localStorage.getItem("workspaceImage") ===
                                  undefined ||
                                  localStorage.getItem("workspaceImage") ===
                                  "undefined" ||
                                  !localStorage.getItem("workspaceImage") ? (
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/workspace/workspace-default-img.png")
                                        .default
                                    }
                                    className="img-fluid"
                                    width="56px"
                                    borderRadius="50%"
                                    height="56px"
                                    alt="profile logo 3"
                                  />
                                ) : (
                                  <>
                                    {localStorage
                                      .getItem("workspaceImage")
                                      .indexOf("https") === -1 ? (
                                      <img
                                        src={
                                          process.env.REACT_APP_Image_Path +
                                          (parseInt(
                                            localStorage.getItem(
                                              "logged_in_user_role_id"
                                            )
                                          ) === 1
                                            ? localStorage.getItem(
                                              "logged_in_user_id"
                                            )
                                            : localStorage.getItem(
                                              "invited_by_user_id"
                                            )) +
                                          process.env.REACT_APP_Workspace_Profile +
                                          localStorage.getItem("workspaceId") +
                                          "/" +
                                          localStorage.getItem("workspaceImage")
                                        }
                                        style={{
                                          borderRadius: "50%",
                                        }}
                                        width="56px"
                                        height="56px"
                                        alt="2"
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src =
                                            localStorage.getItem("workspaceId") !==
                                              "" &&
                                              localStorage.getItem("workspaceId") !==
                                              "undefined" &&
                                              localStorage.getItem("workspaceId") !==
                                              undefined &&
                                              localStorage.getItem("workspaceId") !==
                                              "null" &&
                                              localStorage.getItem("workspaceId") !==
                                              null
                                              ? wpdefaultImgOnError
                                              : userdefaultImgOnError;
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={localStorage.getItem("workspaceImage")}
                                        style={{
                                          borderRadius: "50%",
                                        }}
                                        width="56px"
                                        height="56px"
                                        alt="1"
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src =
                                            localStorage.getItem("workspaceId") !==
                                              "" &&
                                              localStorage.getItem("workspaceId") !==
                                              "undefined" &&
                                              localStorage.getItem("workspaceId") !==
                                              undefined &&
                                              localStorage.getItem("workspaceId") !==
                                              "null" &&
                                              localStorage.getItem("workspaceId") !==
                                              null
                                              ? wpdefaultImgOnError
                                              : userdefaultImgOnError;
                                        }}
                                      />
                                    )}
                                  </>
                                )}
                              </div>
                            )}
                            <div className=" myapps_left_worspace">
                              <h1 className="d-flex myapp_headingone ">
                                {this.props.match.params.workspaceId ===
                                  undefined ||
                                  this.props.match.params.workspaceId === "undefined"
                                  ? "My apps"
                                  : "My apps"
                                }
                                { (localStorage.getItem("agencyid") == 1) ?
                                  <div class="myapps-profile-dropdown" ref={this.wrapperRef}>
                                      <label for="item-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.063" height="7.591" viewBox="0 0 13.063 7.591">
                                          <path id="Path_82120" data-name="Path 82120" d="M-17299.426-4409.886l6,6,6-6" transform="translate(17299.957 4410.416)" fill="none" stroke="#1b2952" stroke-width="1.5" />
                                        </svg>
                                      </label>
                                      <input className="myapps-checkinput" type="checkbox" name="rwo" id="item-2" defaultChecked={this.state.checked}/>

                                      <div className="select-myapss ">
                                        <div className="select-myapps-div">
                                          <div className="select-prosnal">
                                            <h1>
                                            Pay-per-app
                                            </h1>
                                            <a href="/apps">
                                            <div className={"select-prosnalprofile " + (localStorage.getItem("workspaceId") ? '' : 'selected-workspace-div')}>
                                              <img
                                              src={localStorage.getItem('user_profile') !== '' && localStorage.getItem('user_profile') !== null && localStorage.getItem('user_profile').indexOf("https") === -1 ? (process.env.REACT_APP_Image_Path + localStorage.getItem("originalid") + process.env.REACT_APP_Profile + localStorage.getItem('user_profile')) : localStorage.getItem('user_profile') !== '' && localStorage.getItem('user_profile') !== null ? localStorage.getItem('user_profile') : ''}
                                                style={{
                                                  borderRadius: "50%",
                                                }}
                                                width="56px"
                                                height="56px"
                                                alt="1"
                                                onError={({ currentTarget }) => {
                                                  currentTarget.onerror = null;
                                                  currentTarget.src = userdefaultImgOnError;
                                                }}
                                              />
                                              <div className="myprofile-name-app">

                                                <h2>{localStorage.getItem('user_name')}</h2>
                                                <p>{localStorage.getItem('personal_apps_count')} apps</p>
                                              </div>
                                            </div>
                                            </a>
                                          </div>
                                          <div className="select-workspace">
                                            <h1>
                                            Unlimited Workspace
                                            </h1>

                                      { this.state.menuData !== null &&
                                        this.state.menuData.map((row) => {
                                        return (
                                            <a href={`/apps/`+btoa(row.id)}>
                                                <div className={"select-workspaceprofile " + (localStorage.getItem("workspaceId") &&  parseInt(localStorage.getItem("workspaceId")) === row.id ? 'selected-workspace-div' : '')}>
                                                  <img
                                                    src=
                                                    {row.profile_image && row.profile_image.indexOf("https") === -1 
                                                    ? 
                                                      process.env.REACT_APP_Image_Path +
                                                      row.user_id + process.env.REACT_APP_Workspace_Profile +
                                                      row.id + "/" + row.profile_image
                                                    : row.profile_image 
                                                    ? row.profile_image
                                                    : ''
                                                    }
                                                    style={{
                                                      borderRadius: "50%",
                                                    }}
                                                    width="56px"
                                                    height="56px"
                                                    alt="1"
                                                    onError={({ currentTarget }) => {
                                                      currentTarget.onerror = null;
                                                      currentTarget.src = wpdefaultImgOnError;
                                                    }}
                                                  />
                                                  <div className="myprofile-name-app">

                                                    <h2>{row.workspace_name}</h2>
                                                    <p>{row.app_count} apps</p>
                                                  </div>
                                                </div>
                                              </a>
                                              );

                                        })
                                      }

                                      { this.state.invitedWorkspaceData !== null &&
                                        this.state.invitedWorkspaceData.map((row) => {
                                        return (
                                              <a href={`/apps/`+btoa(row.id)}>
                                                <div className={"select-workspaceprofile "+ (localStorage.getItem("workspaceId") &&  parseInt(localStorage.getItem("workspaceId")) === row.id ? 'selected-workspace-div' : '')}>
                                                  <img
                                                    src=
                                                    {row.profile_image && row.profile_image.indexOf("https") === -1 
                                                    ? 
                                                      process.env.REACT_APP_Image_Path +
                                                      row.user_id + process.env.REACT_APP_Workspace_Profile +
                                                      row.id + "/" + row.profile_image
                                                    : row.profile_image 
                                                    ? row.profile_image
                                                    : ''
                                                    }
                                                    style={{
                                                      borderRadius: "50%",
                                                    }}
                                                    width="56px"
                                                    height="56px"
                                                    alt="1"
                                                    onError={({ currentTarget }) => {
                                                      currentTarget.onerror = null;
                                                      currentTarget.src = wpdefaultImgOnError;
                                                    }}
                                                  />
                                                  <div className="myprofile-name-app">

                                                    <h2>{row.workspace_name}</h2>
                                                    <p>{row.app_count} apps</p>
                                                  </div>
                                                </div>
                                              </a>
                                              );
                                        })
                                      }

                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  : null
                                }
                                {/* <Link
                                  className="mobile-createapp"
                                  to={`/app/create${+this.props.match.params.workspaceId ===
                                    "undefined" ||
                                    this.props.match.params.workspaceId === undefined
                                    ? ""
                                    : "/" + this.props.match.params.workspaceId
                                    }`}
                                >
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/plus.png")
                                        .default
                                    }
                                    alt="plus"
                                    className=""
                                  />
                                  <p className="mobile-createapptext">Create</p></Link> */}
                              </h1>
                              {this.props.match.params.workspaceId === undefined ||
                                this.props.match.params.workspaceId ===
                                "undefined" ? (
                                <div>
                                  <p className="myapps_peronal-apps ">Pay-per-app</p>
                                </div>
                              ) : (
                                <div>
                                  {this.state.workspace_subscription !== null &&
                                    this.state.workspace_subscription.plan_name !== null &&
                                    parseInt(localStorage.getItem("logged_in_user_role_id")) < 3 ? (
                                    <p
                                      title={
                                        this.state.workspace_subscription.plan_name
                                      }
                                      className="namespac2"
                                      style={{ color: "#7782A1" }}
                                    >
                                      {/* {this.state.workspace_subscription.plan_name} */}
                                      {localStorage.getItem('workspaceName')}
                                    </p>
                                  ) : (
                                    <p className="namespac2">{localStorage.getItem('workspaceName')}</p>
                                  )}
                                  {/* {this.state.workspace_subscription !== null && parseInt(localStorage.getItem("logged_in_user_role_id")) < 3 &&
                                  this.state.workspace_subscription.plan !==
                                  "preview" ? (
                                  <>
                                    {
                                      returnWorkspaceStatus(this.state.workspace_subscription.status)
                                    }
                                    
                                  </>
                                ) : 
                                  null
                                  } */}
                                  {" "}
                                  {/* {(parseInt(
                                  localStorage.getItem("logged_in_user_role_id")
                                ) == 1 ||
                                  parseInt(
                                    localStorage.getItem("logged_in_user_role_id")
                                  ) == 2) && (
                                    <a
                                      href={
                                        "/workspace/pricing/" +
                                        this.props.match.params.workspaceId
                                      }

                                      className="upgrade-link myapps_upgrade-text"
                                    >
                                      Upgrade
                                    </a>
                                  )} */}
                                </div>
                              )}


                            </div>

                          </div>
                        </div>
                        <div className="noapps-created">
                          <img
                            src={
                              require("../../assets/images/dashboard/zeroappcreated.png")
                                .default
                            }
                            alt="createapp"
                            className=""
                          />
                          <h1>Go ahead and create your first app</h1>
                          <p>It’ll take only a few minutes to design, build and publish your app.</p>
                          <Link
                            to={`/app/create${+this.props.match.params.workspaceId ===
                              "undefined" ||
                              this.props.match.params.workspaceId === undefined
                              ? ""
                              : "/" + this.props.match.params.workspaceId
                              }`}
                          >
                            <button class="getstartedcustom" style={{
                              backgroundColor:localStorage.getItem('button_background_color'),
                              color:localStorage.getItem('button_text_color'),
                              "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                              "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor')}}>Get started</button>
                          </Link>
                        </div>
                      </>
                      :
                      <div className="my-app">
                        {/* Notification */}

                        {/* {(this.state.customer_type === 1 && this.state.is_appsumo_user === 1) && (
							<div className="d-flex  align-items-center annual-plan-offer mb-3 ml-md-3 mr-md-3 justify-content-start" style={{background:"#FF4848",height:"62px",cursor:"default"}}>
								<h6 className="m-0 p-0" style={{color:"white"}}><img src= {require("../../assets/images/dashboard/alertstrip.png").default} alt="alert" className="mr-1"/> 
								It appears that you are registered with AppMySite but have also purchased an AppSumo deal. To activate the AppSumo deal, you will need to delete this account and reactivate it by simply clicking the "Activate my account" button on the welcome email.
								</h6>
							</div>
							)} */}
                        {/* Notification End*/}
                        {this.state.user_status === 0 ? (
                          <div className=" justify-content-between  align-items-center  flex-wrap   myappnotificationdiv ">

                            <div className="notificationdiv1">
                              <h6 className="m-0 p-0">
                                <img
                                  src={
                                    require("../../assets/images/dashboard/exclamation.png")
                                      .default
                                  }
                                  alt="alert"


                                />
                                Your account has not been verified. Please click on the link in the verification email sent to your email.
                              </h6>
                            </div>
                            <div className="d-flex align-items-center ">

                              <h5 className="">
                                <span className=" " onClick={(e) => this.handlesend()}>
                                  Resend the verification email
                                </span>

                              </h5>

                            </div>
                          </div>
                        )
                          : null
                        }
                        {this.state.user_status === 0 ? (
                          <div className=" justify-content-between showwebstrip align-items-center pl-3 pr-3 flex-wrap mb-3  notificationdiv3 notification3padding">
                            <div style={{ display: "flex" }}>
                              <div className="mobileimg">
                                <img
                                  src={
                                    require("../../assets/images/dashboard/exclamation.png")
                                      .default
                                  }

                                  alt="alert"

                                />
                              </div>

                              <div style={{ marginLeft: "15px" }}>
                                <div className="notificationdiv1">
                                  <h6 className="m-0 p-0 notification3 lineheighttrouble">
                                    Your account has not been verified. Please click on the link in the verification email sent to your email.
                                  </h6>
                                  <h5 className="m-0 p-0" style={{ fontSize: "12px", lineHeight: "20px", marginTop: "12px !important", color: "white" }}>
                                    <span className="mobile_span " onClick={(e) => this.handlesend()} >
                                      Resend the verification email
                                    </span>

                                  </h5>
                                </div>
                                {/* <div className="d-flex align-items-center notificationdiv2">
                          
                          
                          
                        </div> */}
                              </div>

                            </div>

                          </div>
                        )
                          : null
                        }





                        <div className=" align-items-center myapps_topdiv">

                          <div className=" align-items-center myapps_account_flex ">
                            {this.props.match.params.workspaceId === undefined ||
                              this.props.match.params.workspaceId === "undefined" ? (
                              <div className="myapps-web-profileimg">
                                {localStorage.getItem("user_profile") === "" ||
                                  localStorage.getItem("user_profile") === null ||
                                  localStorage.getItem("user_profile") === "null" ||
                                  localStorage.getItem("user_profile") ===
                                  undefined ||
                                  localStorage.getItem("user_profile") ===
                                  "undefined" ||
                                  !localStorage.getItem("user_profile") ? (
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/my-account.png")
                                        .default
                                    }
                                    className="img-fluid"
                                    width="56px"
                                    borderRadius="50%"
                                    height="56px"
                                    alt="profile logo 6"
                                  />
                                ) : (
                                  <>
                                    {localStorage
                                      .getItem("user_profile")
                                      .indexOf("https") === -1 ? (
                                      <img
                                        src={
                                          process.env.REACT_APP_Image_Path +
                                          localStorage.getItem(
                                            "logged_in_user_id"
                                          ) +
                                          process.env.REACT_APP_Profile +
                                          localStorage.getItem("user_profile")
                                        }
                                        style={{
                                          borderRadius: "50%",
                                        }}
                                        width="56px"
                                        height="56px"
                                        alt="5"
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src =
                                            localStorage.getItem("workspaceId") !==
                                              "" &&
                                              localStorage.getItem("workspaceId") !==
                                              "undefined" &&
                                              localStorage.getItem("workspaceId") !==
                                              undefined &&
                                              localStorage.getItem("workspaceId") !==
                                              "null" &&
                                              localStorage.getItem("workspaceId") !==
                                              null
                                              ? wpdefaultImgOnError
                                              : userdefaultImgOnError;
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={localStorage.getItem("user_profile")}
                                        style={{
                                          borderRadius: "50%",
                                        }}
                                        width="56px"
                                        height="56px"
                                        alt="4"
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src =
                                            localStorage.getItem("workspaceId") !==
                                              "" &&
                                              localStorage.getItem("workspaceId") !==
                                              "undefined" &&
                                              localStorage.getItem("workspaceId") !==
                                              undefined &&
                                              localStorage.getItem("workspaceId") !==
                                              "null" &&
                                              localStorage.getItem("workspaceId") !==
                                              null
                                              ? wpdefaultImgOnError
                                              : userdefaultImgOnError;
                                        }}
                                      />
                                    )}
                                  </>
                                )}
                              </div>
                            ) : (
                              <div className="left-image-container">
                                {localStorage.getItem("workspaceImage") === "" ||
                                  localStorage.getItem("workspaceImage") === null ||
                                  localStorage.getItem("workspaceImage") === "null" ||
                                  localStorage.getItem("workspaceImage") ===
                                  undefined ||
                                  localStorage.getItem("workspaceImage") ===
                                  "undefined" ||
                                  !localStorage.getItem("workspaceImage") ? (
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/workspace/workspace-default-img.png")
                                        .default
                                    }
                                    className="img-fluid"
                                    width="56px"
                                    borderRadius="50%"
                                    height="56px"
                                    alt="profile logo 3"
                                  />
                                ) : (
                                  <>
                                    {localStorage
                                      .getItem("workspaceImage")
                                      .indexOf("https") === -1 ? (
                                      <img
                                        src={
                                          process.env.REACT_APP_Image_Path +
                                          (parseInt(
                                            localStorage.getItem(
                                              "logged_in_user_role_id"
                                            )
                                          ) === 1
                                            ? localStorage.getItem(
                                              "logged_in_user_id"
                                            )
                                            : localStorage.getItem(
                                              "invited_by_user_id"
                                            )) +
                                          process.env.REACT_APP_Workspace_Profile +
                                          localStorage.getItem("workspaceId") +
                                          "/" +
                                          localStorage.getItem("workspaceImage")
                                        }
                                        style={{
                                          borderRadius: "50%",
                                        }}
                                        width="56px"
                                        height="56px"
                                        alt="2"
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src =
                                            localStorage.getItem("workspaceId") !==
                                              "" &&
                                              localStorage.getItem("workspaceId") !==
                                              "undefined" &&
                                              localStorage.getItem("workspaceId") !==
                                              undefined &&
                                              localStorage.getItem("workspaceId") !==
                                              "null" &&
                                              localStorage.getItem("workspaceId") !==
                                              null
                                              ? wpdefaultImgOnError
                                              : userdefaultImgOnError;
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={localStorage.getItem("workspaceImage")}
                                        style={{
                                          borderRadius: "50%",
                                        }}
                                        width="56px"
                                        height="56px"
                                        alt="1"
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src =
                                            localStorage.getItem("workspaceId") !==
                                              "" &&
                                              localStorage.getItem("workspaceId") !==
                                              "undefined" &&
                                              localStorage.getItem("workspaceId") !==
                                              undefined &&
                                              localStorage.getItem("workspaceId") !==
                                              "null" &&
                                              localStorage.getItem("workspaceId") !==
                                              null
                                              ? wpdefaultImgOnError
                                              : userdefaultImgOnError;
                                        }}
                                      />
                                    )}
                                  </>
                                )}
                              </div>
                            )}
                            <div className=" myapps_left_worspace">
                              <h1 className=" myapp_heading ">
                                <div className="myapps-dropdown-head">
                                  {this.props.match.params.workspaceId ===
                                    undefined ||
                                    this.props.match.params.workspaceId === "undefined"
                                    ? "My apps"
                                    : "My apps"
                                  }
                                  { (localStorage.getItem("agencyid") == 1) ?
                                  <div class="myapps-profile-dropdown" ref={this.wrapperRef}>
                                    <label for="item-2">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="13.063" height="7.591" viewBox="0 0 13.063 7.591">
                                        <path id="Path_82120" data-name="Path 82120" d="M-17299.426-4409.886l6,6,6-6" transform="translate(17299.957 4410.416)" fill="none" stroke="#1b2952" stroke-width="1.5" />
                                      </svg>
                                    </label>
                                    <input className="myapps-checkinput" type="checkbox" name="rwo" id="item-2" defaultChecked={this.state.checked}/>

                                    <div className="select-myapss ">
                                      <div className="select-myapps-div">
                                        <div className="select-prosnal">
                                          <h1>
                                          Pay-per-app
                                          </h1>
                                          <a href="/apps">
                                          <div className={"select-prosnalprofile " + (localStorage.getItem("workspaceId") ? '' : 'selected-workspace-div')}>
                                            <img
                                             src={localStorage.getItem('user_profile') !== '' && localStorage.getItem('user_profile') !== null && localStorage.getItem('user_profile').indexOf("https") === -1 ? (process.env.REACT_APP_Image_Path + localStorage.getItem("originalid") + process.env.REACT_APP_Profile + localStorage.getItem('user_profile')) : localStorage.getItem('user_profile') !== '' && localStorage.getItem('user_profile') !== null ? localStorage.getItem('user_profile') : ''}
                                              style={{
                                                borderRadius: "50%",
                                              }}
                                              width="56px"
                                              height="56px"
                                              alt="1"
                                              onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = userdefaultImgOnError;
                                              }}
                                            />
                                            <div className="myprofile-name-app">

                                              <h2>{localStorage.getItem('user_name')}</h2>
                                              <p>{localStorage.getItem('personal_apps_count')} apps</p>
                                            </div>
                                          </div>
                                          </a>
                                        </div>
                                        <div className="select-workspace">
                                          <h1>
                                          Unlimited Workspace
                                          </h1>

                                    { this.state.menuData !== null &&
                                      this.state.menuData.map((row) => {
                                      return (
                                           <a href={`/apps/`+btoa(row.id)}>
                                              <div className={"select-workspaceprofile " + (localStorage.getItem("workspaceId") &&  parseInt(localStorage.getItem("workspaceId")) === row.id ? 'selected-workspace-div' : '')}>
                                                <img
                                                  src=
                                                  {row.profile_image && row.profile_image.indexOf("https") === -1 
                                                  ? 
                                                    process.env.REACT_APP_Image_Path +
                                                    row.user_id + process.env.REACT_APP_Workspace_Profile +
                                                    row.id + "/" + row.profile_image
                                                  : row.profile_image 
                                                  ? row.profile_image
                                                  : ''
                                                  }
                                                  style={{
                                                    borderRadius: "50%",
                                                  }}
                                                  width="56px"
                                                  height="56px"
                                                  alt="1"
                                                  onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src = wpdefaultImgOnError;
                                                  }}
                                                />
                                                <div className="myprofile-name-app">

                                                  <h2>{row.workspace_name}</h2>
                                                  <p>{row.app_count} apps</p>
                                                </div>
                                              </div>
                                            </a>
                                             );

                                      })
                                    }

                                    { this.state.invitedWorkspaceData !== null &&
                                      this.state.invitedWorkspaceData.map((row) => {
                                      return (
                                            <a href={`/apps/`+btoa(row.id)}>
                                              <div className={"select-workspaceprofile "+ (localStorage.getItem("workspaceId") &&  parseInt(localStorage.getItem("workspaceId")) === row.id ? 'selected-workspace-div' : '')}>
                                                <img
                                                  src=
                                                  {row.profile_image && row.profile_image.indexOf("https") === -1 
                                                  ? 
                                                    process.env.REACT_APP_Image_Path +
                                                    row.user_id + process.env.REACT_APP_Workspace_Profile +
                                                    row.id + "/" + row.profile_image
                                                  : row.profile_image 
                                                  ? row.profile_image
                                                  : ''
                                                  }
                                                  style={{
                                                    borderRadius: "50%",
                                                  }}
                                                  width="56px"
                                                  height="56px"
                                                  alt="1"
                                                  onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src = wpdefaultImgOnError;
                                                  }}
                                                />
                                                <div className="myprofile-name-app">

                                                  <h2>{row.workspace_name}</h2>
                                                  <p>{row.app_count} apps</p>
                                                </div>
                                              </div>
                                            </a>
                                             );
                                      })
                                    }

                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                  : null
                                  }
                                </div>

                                <Link
                                  className="mobile-createapp"
                                  to={`/app/create${+this.props.match.params.workspaceId ===
                                    "undefined" ||
                                    this.props.match.params.workspaceId === undefined
                                    ? ""
                                    : "/" + this.props.match.params.workspaceId
                                    }`}
                                >
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/plus.png")
                                        .default
                                    }
                                    alt="plus"
                                    className=""
                                  />
                                  <p className="mobile-createapptext" style={{color:localStorage.getItem('button_background_color')}}>Create</p></Link>
                              </h1>
                              {this.props.match.params.workspaceId === undefined ||
                                this.props.match.params.workspaceId ===
                                "undefined" ? (
                                <div>
                                  <p className="myapps_peronal-apps ">Pay-per-app</p>
                                </div>
                              ) : (
                                <div className="d-flex align-items-center myapps_all-status ">
                                  {this.state.workspace_subscription !== null &&
                                    this.state.workspace_subscription.plan_name !== null &&
                                    parseInt(localStorage.getItem("logged_in_user_role_id")) < 3 ? (
                                    <p
                                      title={
                                        this.state.workspace_subscription.plan_name
                                      }
                                      className="namespac2"
                                      style={{ color: "#7782A1" }}
                                    >
                                      {/* {this.state.workspace_subscription.plan_name} */}
                                      {localStorage.getItem('workspaceName')}
                                    </p>
                                  ) : (
                                    <p className="namespac2">{localStorage.getItem('workspaceName')}</p>
                                  )}
                                  {this.state.workspace_subscription !== null && parseInt(localStorage.getItem("logged_in_user_role_id")) < 3 &&
                                    this.state.workspace_subscription.plan !==
                                    "preview" ? (
                                    // appname.show_plan_name === 1 returnWorkspaceStatus? (
                                    <>
                                      {/* {
                                      returnWorkspaceStatus(this.state.workspace_subscription.status)
                                    } */}
                                      {/* {this.state.workspace_subscription.status ===
                                  "live" ? (
                                    <p
                                      className="myapps_viewstatus-responsive  status-set-in-center"
                                      style={{
                                        color: "#47CE70",
                                        border: "1px solid #47CE70",
                                        backgroundColor: "#EFFFF4",
                                        fontSize: "10px !important",
                                        borderRadius: "20px",
                                        padding: "0px 7px 0px 7px"
                                      }}
                                    >
                                      {this.state.workspace_subscription.status}
                                    </p>
                                  ) : this.state.workspace_subscription
                                      .status === "free" ? (
                                    <></>
                                  ) : this.state.workspace_subscription
                                      .status === "active" ? (
                                    <p
                                      className="myapps_viewstatus-responsive  status-set-in-center"
                                      style={{
                                        color: "#47CE70",
                                        border: "1px solid #47CE70",
                                        backgroundColor: "#EFFFF4",
                                        fontSize: "10px !important",
                                         borderRadius: "20px",
                                         padding: "0px 7px 0px 7px"
                                      }}
                                    >
                                      {this.state.workspace_subscription.status}
                                    </p>
                                  ) : (
                                    <p
                                      className="myapps_viewstatus-responsive  status-set-in-center"
                                      style={{
                                        color: "#E20000",
                                        border: "1px solid #E20000",
                                      }}
                                    >
                                      {this.state.workspace_subscription.status}
                                    </p>
                                  )} */}
                                    </>
                                  ) : // ) : (
                                    //   <p>-</p>
                                    // )
                                    null}{" "}
                                  {/* {(parseInt(
                                  localStorage.getItem("logged_in_user_role_id")
                                ) == 1 ||
                                  parseInt(
                                    localStorage.getItem("logged_in_user_role_id")
                                  ) == 2) && (
                                    <a
                                      href={
                                        "/workspace/pricing/" +
                                        this.props.match.params.workspaceId
                                      }

                                      className="upgrade-link myapps_upgrade-text"
                                    >
                                      Upgrade
                                    </a>
                                  )} */}
                                </div>
                              )}


                            </div>

                          </div>

                          {/* {window.matchMedia("(min-width: 768px)").matches ? ( */}
                          <Suspense fallback={""}>
                            <Advancedsearch.Provider value={handleadvance}>
                              <Search />
                            </Advancedsearch.Provider>
                          </Suspense>
                          {/* ) : (
                        <>
                          {this.state.showsearch && (
                            <div className="apps-search apps-search-mobile mt-0 mb-4 d-flex d-md-none">
                              <form
                                className="form-group d-flex justify-content-between align-items-center "
                                onSubmit={this.handlesearchform}
                              >
                                <input
                                  type="text"
                                  placeholder="Search"
                                  className="pl-1 search-my-app"
                                  value={this.state.searchvalue}
                                  onChange={(e) => this.handlesearch(e)}
                                />
                                <button
                                  type="submit"
                                  className="apps-search-btn-main"
                                >
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/search.png")
                                        .default
                                    }
                                    alt="search"
                                    className="img-fluid"
                                  />
                                </button>
                              </form>
                            </div>
                          )}
                        </> */}
                          {/* )} */}
                        </div>

                        <div className="myapps-appsdiv">
                          <div className={this.state.userdata.length == 0 && this.state.showSrchTag1 == true ? "d-flex flex-wrap align-items-center justifycontentcenter" : "d-flex flex-wrap align-items-center"} >
                            {this.state.searchspinner ? (
                              <div className="newspinner">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                  xlink="http://www.w3.org/1999/xlink"
                                  style={{ margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s" }}
                                  width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                  <g transform="translate(20 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                    <circle cx="0" cy="0" r="6" fill="#e6261f" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                      <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                        style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                      </animateTransform>
                                    </circle>
                                  </g>
                                  <g transform="translate(40 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                    <circle cx="0" cy="0" r="6" fill="#f7d038" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                      <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                        style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                      </animateTransform>
                                    </circle>
                                  </g>
                                  <g transform="translate(60 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                    <circle cx="0" cy="0" r="6" fill="#49da9a" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                      <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                        style={{ animationplaystate: "running", animationdelay: "0s" }}>

                                      </animateTransform>
                                    </circle>
                                  </g>
                                  <g transform="translate(80 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                    <circle cx="0" cy="0" r="6" fill="#4355db" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                      <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                        style={{ animationplaystate: "running", animationdelay: "0s" }}>

                                      </animateTransform>
                                    </circle>
                                  </g>
                                </svg>
                              </div>
                            ) : (
                              <>
                                {/* <div className=" myapps_box myapps_createappbox">
                          <Link
                            className="boxlink myapps_applinks "
                            to={`/app/create${
                              +this.props.match.params.workspaceId ===
                                "undefined" ||
                              this.props.match.params.workspaceId === undefined
                                ? ""
                                : "/" + this.props.match.params.workspaceId
                            }`}
                          ></Link>
                          <div className="myapps_inside_image  d-flex flex-wrap align-items-center justify-content-center dash-border-new">
                            <div className="first-app">
                              <svg
                                className="d-block d-md-none"
                                id="Group_3055"
                                data-name="Group 3055"
                                width="16.222"
                                height="16.222"
                                viewBox="0 0 16.222 16.222"
                              >
                                <g
                                  id="Group_1664"
                                  data-name="Group 1664"
                                  transform="translate(0 8.111)"
                                >
                                  <line
                                    id="Line_85"
                                    data-name="Line 85"
                                    y2="16.222"
                                    transform="translate(16.222) rotate(90)"
                                    fill="none"
                                    stroke="#7782a1"
                                    stroke-width="2"
                                  />
                                </g>
                                <g
                                  id="Group_1665"
                                  data-name="Group 1665"
                                  transform="translate(8.111 0) rotate(90)"
                                >
                                  <line
                                    id="Line_85-2"
                                    data-name="Line 85"
                                    y2="16.222"
                                    transform="translate(16.222) rotate(90)"
                                    fill="none"
                                    stroke="#7782a1"
                                    stroke-width="2"
                                  />
                                </g>
                              </svg>
                              <p className="d-md-none creatapps">Create New App</p>
                              <img
                                src={
                                  require("../../assets/images/dashboard/create-app.png")
                                    .default
                                }
                                alt="createapp"
                                className="d-none d-md-block"
                              />
                              <h4 className="myapps-grey-name text-center d-none d-md-block">
                                Create App
                              </h4>
                            </div>
                          </div>
                        </div> */}

                                {this.state.userdata.length == 0 && this.state.showSrchTag1 == true ?
                                  <div className="noapps-filtered">
                                    <img
                                      src={
                                        require("../../assets/images/dashboard/noappfiltered.png")
                                          .default
                                      }
                                      alt="createapp"
                                      className=""
                                    />
                                    <p>No results found</p>

                                  </div>
                                  : null
                                }

                                {this.state.userdata.map((appname, i) => (
                                  <div className=" myapps_box myapps_appboxs" key={i}>
                                    <div className="boxlink">
                                      <div className="myapps_inside_image inside_image_new myapps_appbox">
                                        {window.matchMedia("(min-width: 200px)")
                                          .matches ? (
                                          <>
                                            {/* <div className="topsection topsectionapp-ne d-none d-md-block myapps_topsection">
                                            <div className="d-grid justify-content-between">
                                              <h2>{appname.app_name}</h2>
                                              <h3>Id: <span>029471</span></h3> */}
                                            {/* {(this.props.match.params
                                                .workspaceId === undefined ||
                                                this.props.match.params
                                                  .workspaceId === "undefined") &&
                                                appname.subscription !== null
                                                ? appname.show_plan_name === 1 && (
                                                  // returnAppStatus
                                                  <>{
                                                    returnAppStatus(appname.subscription.status)
                                                  } */}
                                            {/* {appname.subscription.status ===
                                                "live" ? (
                                                  <p
                                                    className={
                                                      appname.subscription
                                                        .status !== "free"
                                                        ? "myapps_viewstatus-responsive "
                                                        : "d-none"
                                                    }
                                                    style={{
                                                      color: "#47CE70",
                                                      border:
                                                        "1px solid #47CE70",
                                                      backgroundColor:
                                                        "#EFFFF4",
                                                    }}
                                                  >
                                                    {
                                                      appname.subscription
                                                        .status
                                                    }
                                                  </p>
                                                ) : appname.subscription
                                                    .status === "active" ? (
                                                  <p
                                                    className={
                                                      appname.subscription
                                                        .status !== "free"
                                                        ? "myapps_viewstatus-responsive "
                                                        : "d-none"
                                                    }
                                                    style={{
                                                      color: "#47CE70",
                                                      border:
                                                        "1px solid #47CE70",
                                                      backgroundColor:
                                                        "#EFFFF4",
                                                    }}
                                                  >
                                                    {
                                                      appname.subscription
                                                        .status
                                                    }
                                                  </p>
                                                ) : (
                                                  <p
                                                    className={
                                                      appname.subscription
                                                        .status !== "free"
                                                        ? "myapps_viewstatus-responsive  7"
                                                        : "d-none"
                                                    }
                                                    style={{
                                                      color:
                                                        appname.subscription
                                                          .status === "active"
                                                          ? "#47CE70"
                                                          : "#E20000",
                                                      border:
                                                        appname.subscription
                                                          .status === "active"
                                                          ? "1px solid #47CE70"
                                                          : "1px solid #E20000",
                                                    }}
                                                  >
                                                    {
                                                      appname.subscription
                                                        .status
                                                    }
                                                    
                                                  </p>
                                                )} */}
                                            {/* </>
                                                )
                                                : null} */}
                                            {/* </div> */}

                                            {/* <div className="d-flex align-items-center justify-content-between">
                                              {(this.props.match.params
                                                .workspaceId === undefined ||
                                                this.props.match.params
                                                  .workspaceId === "undefined") &&
                                                appname.subscription !== null ? (
                                                appname.show_plan_name === 1 ? (
                                                  <h3
                                                    title={
                                                      appname.subscription.plan_name
                                                    }
                                                    className="namespac"
                                                    style={{ color: "#7782A1" }}
                                                  >
                                                    {appname.subscription
                                                      .plan_name === null
                                                      ? appname.subscription.plan_code
                                                        .toString()
                                                        .replace("_", " ")
                                                        .substr(0, 16) +
                                                        appname.subscription.plan_code
                                                          .toString()
                                                          .replace("_", " ")
                                                          .length >=
                                                        16
                                                        ? "..."
                                                        : ""
                                                      : window.matchMedia(
                                                        "(max-width: 359px)"
                                                      ).matches
                                                        ? appname.subscription.plan_name.substr(
                                                          0,
                                                          10
                                                        ) +
                                                        (appname.subscription
                                                          .plan_name.length >= 10
                                                          ? "..."
                                                          : "")
                                                        : appname.subscription
                                                          .plan_name}
                                                  </h3>
                                                ) : (
                                                  <h3
                                                    className="namespac namespac-red namespac-red-margin"
                                                    style={{
                                                      color: "#7782A1",
                                                      textTransform: "lowercase",
                                                    }}
                                                    title={appname.store_url}
                                                  >
                                                    {appname.store_url
                                                      .replace("https://", "")
                                                      .substr(0, 16) +
                                                      appname.store_url.replace(
                                                        "https://",
                                                        ""
                                                      ).length >=
                                                      16
                                                      ? "..."
                                                      : ""}
                                                  </h3>
                                                )
                                              ) : <h3
                                                className="namespac namespac-red namespac-red-margin"
                                                style={{
                                                  color: "#7782A1",
                                                  textTransform: "lowercase",
                                                }}
                                                title={appname.store_url}
                                              ></h3>}
                                              <p
                                                className={
                                                  this.props.match.params
                                                    .workspaceId === undefined ||
                                                    this.props.match.params
                                                      .workspaceId === "undefined"
                                                    ? "created-date-personal"
                                                    : "created-date-workspace"
                                                }
                                              >
                                                {appname.created_date}
                                              </p>
                                            </div> */}
                                            {/* </div> */}
                                            <div className="   ipad-view-myapps-icons myapps_hover"  
                                            onClick={(e) =>
                                                  this.handleAppdata(
                                                    appname.user_id,
                                                    appname.subscription.status,
                                                    appname.app_name,
                                                    appname.id,
                                                    appname.version,
                                                    this.setname(appname.app_name),
                                                    appname.subscription,
                                                    appname.store_url,
                                                    appname.plugin_keys,
                                                    appname.menu_fill,
                                                    appname.dashboard_count,
                                                    appname.website_type,
                                                    appname.website_technology,
                                                    appname.is_verified,
                                                    appname.appicon_count,
                                                    appname.splash_count,
                                                    appname.login_reg_count,
                                                    appname.style_count
                                                  )
                                                }>
                                              {/* {appname.app_icons.icon !== "" ? 
								
								<div onClick = {e => this.handleAppdata(appname.user_id,appname.subscription.status,appname.app_name,appname.id,appname.version,this.setname(appname.app_name),appname.subscription,appname.store_url,appname.plugin_keys,appname.menu_fill,appname.dashboard_count,appname.website_type,appname.is_verified,appname.appicon_count,appname.splash_count,appname.login_reg_count,appname.style_count)} style={{background:appname.app_icons.app_frontend_display_icon === "" ? <></> : 'url(' +  process.env.REACT_APP_Image_Path + appname.user_id + "/" + appname.id + process.env.REACT_APP_AppIcon_Display + appname.app_icons.app_frontend_display_icon + ')',width:"auto",height:"300px"}} className="new-appimg">
								</div>
								: */}<div className="topsection topsectionapp-ne  myapps_topsection">
                                                <div className="d-grid justify-content-between">
                                                  <h2>{appname.app_name}</h2>
                                                  <h3>ID: <span>{(appname.id)}</span></h3>
                                                  {/* {(this.props.match.params
                                                .workspaceId === undefined ||
                                                this.props.match.params
                                                  .workspaceId === "undefined") &&
                                                appname.subscription !== null
                                                ? appname.show_plan_name === 1 && (
                                                  // returnAppStatus
                                                  <>{
                                                    returnAppStatus(appname.subscription.status)
                                                  } */}
                                                  {/* {appname.subscription.status ===
                                                "live" ? (
                                                  <p
                                                    className={
                                                      appname.subscription
                                                        .status !== "free"
                                                        ? "myapps_viewstatus-responsive "
                                                        : "d-none"
                                                    }
                                                    style={{
                                                      color: "#47CE70",
                                                      border:
                                                        "1px solid #47CE70",
                                                      backgroundColor:
                                                        "#EFFFF4",
                                                    }}
                                                  >
                                                    {
                                                      appname.subscription
                                                        .status
                                                    }
                                                  </p>
                                                ) : appname.subscription
                                                    .status === "active" ? (
                                                  <p
                                                    className={
                                                      appname.subscription
                                                        .status !== "free"
                                                        ? "myapps_viewstatus-responsive "
                                                        : "d-none"
                                                    }
                                                    style={{
                                                      color: "#47CE70",
                                                      border:
                                                        "1px solid #47CE70",
                                                      backgroundColor:
                                                        "#EFFFF4",
                                                    }}
                                                  >
                                                    {
                                                      appname.subscription
                                                        .status
                                                    }
                                                  </p>
                                                ) : (
                                                  <p
                                                    className={
                                                      appname.subscription
                                                        .status !== "free"
                                                        ? "myapps_viewstatus-responsive  7"
                                                        : "d-none"
                                                    }
                                                    style={{
                                                      color:
                                                        appname.subscription
                                                          .status === "active"
                                                          ? "#47CE70"
                                                          : "#E20000",
                                                      border:
                                                        appname.subscription
                                                          .status === "active"
                                                          ? "1px solid #47CE70"
                                                          : "1px solid #E20000",
                                                    }}
                                                  >
                                                    {
                                                      appname.subscription
                                                        .status
                                                    }
                                                    
                                                  </p>
                                                )} */}
                                                  {/* </>
                                                )
                                                : null} */}
                                                </div>

                                                {/* <div className="d-flex align-items-center justify-content-between">
                                              {(this.props.match.params
                                                .workspaceId === undefined ||
                                                this.props.match.params
                                                  .workspaceId === "undefined") &&
                                                appname.subscription !== null ? (
                                                appname.show_plan_name === 1 ? (
                                                  <h3
                                                    title={
                                                      appname.subscription.plan_name
                                                    }
                                                    className="namespac"
                                                    style={{ color: "#7782A1" }}
                                                  >
                                                    {appname.subscription
                                                      .plan_name === null
                                                      ? appname.subscription.plan_code
                                                        .toString()
                                                        .replace("_", " ")
                                                        .substr(0, 16) +
                                                        appname.subscription.plan_code
                                                          .toString()
                                                          .replace("_", " ")
                                                          .length >=
                                                        16
                                                        ? "..."
                                                        : ""
                                                      : window.matchMedia(
                                                        "(max-width: 359px)"
                                                      ).matches
                                                        ? appname.subscription.plan_name.substr(
                                                          0,
                                                          10
                                                        ) +
                                                        (appname.subscription
                                                          .plan_name.length >= 10
                                                          ? "..."
                                                          : "")
                                                        : appname.subscription
                                                          .plan_name}
                                                  </h3>
                                                ) : (
                                                  <h3
                                                    className="namespac namespac-red namespac-red-margin"
                                                    style={{
                                                      color: "#7782A1",
                                                      textTransform: "lowercase",
                                                    }}
                                                    title={appname.store_url}
                                                  >
                                                    {appname.store_url
                                                      .replace("https://", "")
                                                      .substr(0, 16) +
                                                      appname.store_url.replace(
                                                        "https://",
                                                        ""
                                                      ).length >=
                                                      16
                                                      ? "..."
                                                      : ""}
                                                  </h3>
                                                )
                                              ) : <h3
                                                className="namespac namespac-red namespac-red-margin"
                                                style={{
                                                  color: "#7782A1",
                                                  textTransform: "lowercase",
                                                }}
                                                title={appname.store_url}
                                              ></h3>}
                                              <p
                                                className={
                                                  this.props.match.params
                                                    .workspaceId === undefined ||
                                                    this.props.match.params
                                                      .workspaceId === "undefined"
                                                    ? "created-date-personal"
                                                    : "created-date-workspace"
                                                }
                                              >
                                                {appname.created_date}
                                              </p>
                                            </div> */}

                                            {
                                             (parseInt(localStorage.getItem("logged_in_user_id")) === appname.user_id || appname.is_admin_permission)
                                            ?
                                              <div 
                                              className="clone-svg"
                                              style={{ zIndex:"1" }} 
                                              onClick={(e)=>this.handleClonePopUp(e,"cancel",appname.id,appname.app_name)}>
                                                    <svg className="web-clone-svg" xmlns="http://www.w3.org/2000/svg" width="15.001" height="15" viewBox="0 0 15.001 15">
                                                      <g id="Group_26180" data-name="Group 26180" transform="translate(-450.5 -124.5)">
                                                        <g id="Path_9276" data-name="Path 9276" transform="translate(0 0)" fill="none" stroke-miterlimit="10">
                                                          <path d="M462.5,125.923V128.5h-6.72a1.189,1.189,0,0,0-1.28,1.28v6.72h-2.577a1.419,1.419,0,0,1-1.423-1.423v-9.154a1.419,1.419,0,0,1,1.423-1.423h9.154A1.419,1.419,0,0,1,462.5,125.923Z" stroke="none" />
                                                          <path d="M 451.9228515625 125.5000152587891 C 451.6857299804688 125.5000152587891 451.5 125.6857452392578 451.5 125.9228668212891 L 451.5 135.0771636962891 C 451.5 135.3142700195312 451.6857299804688 135.5 451.9228515625 135.5 L 453.5 135.5 L 453.5 129.7800140380859 C 453.5 128.4375762939453 454.4375610351562 127.5 455.7799682617188 127.5 L 461.5 127.5 L 461.5 125.9228668212891 C 461.5 125.6857452392578 461.3142395019531 125.5000152587891 461.0771179199219 125.5000152587891 L 451.9228515625 125.5000152587891 M 451.9228515625 124.5000152587891 L 461.0771179199219 124.5000152587891 C 461.8656616210938 124.5000152587891 462.5 125.1343078613281 462.5 125.9228668212891 L 462.5 128.5 L 455.7799682617188 128.5 C 454.9913940429688 128.5 454.5 128.991455078125 454.5 129.7800140380859 L 454.5 136.5 L 451.9228515625 136.5 C 451.13427734375 136.5 450.5 135.8657531738281 450.5 135.0771636962891 L 450.5 125.9228668212891 C 450.5 125.1343078613281 451.13427734375 124.5000152587891 451.9228515625 124.5000152587891 Z" stroke="none" fill="#bcc1ce" />
                                                        </g>
                                                        <g id="Path_9277" data-name="Path 9277" transform="translate(-1.999 -2)" fill="none" stroke-miterlimit="10">
                                                          <path d="M467.5,130.923v9.154a1.419,1.419,0,0,1-1.423,1.423h-9.154a1.419,1.419,0,0,1-1.423-1.423v-9.154a1.419,1.419,0,0,1,1.423-1.423h9.154A1.419,1.419,0,0,1,467.5,130.923Z" stroke="none" />
                                                          <path d="M 456.9228210449219 130.5000152587891 C 456.6856994628906 130.5000152587891 456.4999694824219 130.6857452392578 456.4999694824219 130.9228515625 L 456.4999694824219 140.0771484375 C 456.4999694824219 140.3142700195312 456.6856994628906 140.5000152587891 456.9228210449219 140.5000152587891 L 466.0771179199219 140.5000152587891 C 466.3142395019531 140.5000152587891 466.4999694824219 140.3142700195312 466.4999694824219 140.0771484375 L 466.4999694824219 130.9228515625 C 466.4999694824219 130.6857452392578 466.3142395019531 130.5000152587891 466.0771179199219 130.5000152587891 L 456.9228210449219 130.5000152587891 M 456.9228210449219 129.5000152587891 L 466.0771179199219 129.5000152587891 C 466.8656921386719 129.5000152587891 467.4999694824219 130.1342926025391 467.4999694824219 130.9228515625 L 467.4999694824219 140.0771484375 C 467.4999694824219 140.86572265625 466.8656921386719 141.5000152587891 466.0771179199219 141.5000152587891 L 456.9228210449219 141.5000152587891 C 456.1342163085938 141.5000152587891 455.4999694824219 140.86572265625 455.4999694824219 140.0771484375 L 455.4999694824219 130.9228515625 C 455.4999694824219 130.1342926025391 456.1342163085938 129.5000152587891 456.9228210449219 129.5000152587891 Z" stroke="none" fill="#bcc1ce" />
                                                        </g>
                                                      </g>
                                                    </svg>
                                                    <svg className="mob-clone-svg" xmlns="http://www.w3.org/2000/svg" width="20" height="20.001" viewBox="0 0 20 20.001">
                                                      <g id="Group_26180" data-name="Group 26180" transform="translate(-450.5 -124.5)">
                                                        <g id="Path_9276" data-name="Path 9276" transform="translate(0 0)" fill="none" stroke-miterlimit="10">
                                                          <path d="M466.5,126.4v3.1h-9.7c-1.051,0-1.3.249-1.3,1.3v9.7h-3.1a1.892,1.892,0,0,1-1.9-1.9V126.4a1.892,1.892,0,0,1,1.9-1.9H464.6A1.893,1.893,0,0,1,466.5,126.4Z" stroke="none" />
                                                          <path d="M 452.3971557617188 125.5000152587891 C 451.8941040039062 125.5000152587891 451.5000610351562 125.8940887451172 451.5000610351562 126.3971557617188 L 451.5000610351562 138.6028747558594 C 451.5000610351562 139.1059417724609 451.8941040039062 139.5000152587891 452.3971557617188 139.5000152587891 L 454.501953125 139.5000152587891 L 454.501953125 130.8000030517578 C 454.501953125 129.1878509521484 455.1897888183594 128.5 456.8019409179688 128.5 L 465.5000610351562 128.5 L 465.5000610351562 126.3971557617188 C 465.5000610351562 125.8940887451172 465.10595703125 125.5000152587891 464.6028747558594 125.5000152587891 L 452.3971557617188 125.5000152587891 M 452.3971557617188 124.5000152587891 L 464.6028747558594 124.5000152587891 C 465.6542663574219 124.5000152587891 466.5000610351562 125.3457336425781 466.5000610351562 126.3971557617188 L 466.5000610351562 129.5 L 456.8019409179688 129.5 C 455.7505493164062 129.5 455.501953125 129.7485809326172 455.501953125 130.8000030517578 L 455.501953125 140.5000152587891 L 452.3971557617188 140.5000152587891 C 451.345703125 140.5000152587891 450.5000610351562 139.6543579101562 450.5000610351562 138.6028747558594 L 450.5000610351562 126.3971557617188 C 450.5000610351562 125.3457336425781 451.345703125 124.5000152587891 452.3971557617188 124.5000152587891 Z" stroke="none" fill="#bcc1ce" />
                                                        </g>
                                                        <g id="Path_9277" data-name="Path 9277" transform="translate(-0.999 -0.999)" fill="none" stroke-miterlimit="10">
                                                          <path d="M471.5,131.4V143.6a1.892,1.892,0,0,1-1.9,1.9H457.4a1.892,1.892,0,0,1-1.9-1.9V131.4a1.892,1.892,0,0,1,1.9-1.9H469.6A1.892,1.892,0,0,1,471.5,131.4Z" stroke="none" />
                                                          <path d="M 457.3970947265625 130.5000152587891 C 456.89404296875 130.5000152587891 456.4999694824219 130.8940734863281 456.4999694824219 131.3971252441406 L 456.4999694824219 143.6028594970703 C 456.4999694824219 144.1059417724609 456.89404296875 144.5000152587891 457.3970947265625 144.5000152587891 L 469.6028137207031 144.5000152587891 C 470.1058959960938 144.5000152587891 470.4999694824219 144.1059417724609 470.4999694824219 143.6028594970703 L 470.4999694824219 131.3971252441406 C 470.4999694824219 130.8940734863281 470.1058959960938 130.5000152587891 469.6028137207031 130.5000152587891 L 457.3970947265625 130.5000152587891 M 457.3970947265625 129.5000152587891 L 469.6028137207031 129.5000152587891 C 470.6542663574219 129.5000152587891 471.4999694824219 130.345703125 471.4999694824219 131.3971252441406 L 471.4999694824219 143.6028594970703 C 471.4999694824219 144.654296875 470.6542663574219 145.5000152587891 469.6028137207031 145.5000152587891 L 457.3970947265625 145.5000152587891 C 456.3456420898438 145.5000152587891 455.4999694824219 144.654296875 455.4999694824219 143.6028594970703 L 455.4999694824219 131.3971252441406 C 455.4999694824219 130.345703125 456.3456420898438 129.5000152587891 457.3970947265625 129.5000152587891 Z" stroke="none" fill="#bcc1ce" />
                                                        </g>
                                                      </g>
                                                    </svg>
                                                    <div className="cloneTooltip"> Clone this app.</div>
                                              </div>
                                              :
                                              <></>
                                            }
                                              </div>
                                              <div
                                                onClick={(e) =>
                                                  this.handleAppdata(
                                                    appname.user_id,
                                                    appname.subscription.status,
                                                    appname.app_name,
                                                    appname.id,
                                                    appname.version,
                                                    this.setname(appname.app_name),
                                                    appname.subscription,
                                                    appname.store_url,
                                                    appname.plugin_keys,
                                                    appname.menu_fill,
                                                    appname.dashboard_count,
                                                    appname.website_type,
                                                    appname.website_technology,
                                                    appname.is_verified,
                                                    appname.appicon_count,
                                                    appname.splash_count,
                                                    appname.login_reg_count,
                                                    appname.style_count
                                                  )
                                                }
                                                style={{
                                                  background:
                                                    appname.app_icons
                                                      .app_frontend_display_icon ===
                                                      "" ? (
                                                      <></>
                                                    ) : (
                                                      "url(" +
                                                      process.env.REACT_APP_Image_Path +
                                                      appname.user_id +
                                                      "/" +
                                                      appname.id +
                                                      process.env
                                                        .REACT_APP_AppIcon_Display +
                                                      appname.app_icons
                                                        .app_frontend_display_icon +
                                                      ")"
                                                    ),
                                                  width: "170px",
                                                  height: "170px",
                                                  borderRadius: "48px",
                                                }}
                                                className="new-appimg app-icon-display-status"
                                              ></div>
                                              {/* } */}
                                              {/* ON Hover */}
                                              {appname.subscription !== null &&
                                                (appname.subscription.plan_code
                                                  .toString()
                                                  .indexOf(
                                                    process.env.REACT_APP_Lifetime_plan
                                                  ) > -1 ||
                                                  appname.subscription.plan_code
                                                    .toString()
                                                    .indexOf("appsumo") > -1) &&
                                                appname.show_plan_name === 1 && (
                                                  <div className="taco">
                                                    {/* <img
                                                    src={
                                                      require("../../assets/images/dashboard/taco.png")
                                                        .default
                                                    }
                                                    title="AppSumo"
                                                    alt="appsumo"
                                                    className="d-none d-md-block"
                                                  /> */}
                                                  </div>
                                                )}

                                              {/* <div className="onhoverdata myapps-display-status">
                                        <div
                                          className="hoverdashboard"
                                          onClick={(e) =>
                                            this.handleAppdata(
                                              appname.user_id,
                                              appname.subscription.status,
                                              appname.app_name,
                                              appname.id,
                                              appname.version,
                                              this.setname(appname.app_name),
                                              appname.subscription,
                                              appname.store_url,
                                              appname.plugin_keys,
                                              appname.menu_fill,
                                              appname.dashboard_count,
                                              appname.website_type,
                                              appname.website_technology,
                                              appname.is_verified,
                                              appname.appicon_count,
                                              appname.splash_count,
                                              appname.login_reg_count,
                                              appname.style_count
                                            )
                                          }
                                        >
                                          <p>Dashboard</p>
                                        </div>
                                      </div> */}
                                              <div className="d-flex align-items-center justify-content-between myapps-basediv">
                                                <div className="app-created-date">{moment(appname.created_at).format(
                                                  "D MMMM YYYY"
                                                )}</div>
                                                <div className="d-flex">
                                                  {appname.website_technology === 5 ?
                                                    (
                                                      <>
                                                        <img
                                                          src={
                                                            require("../../assets/images/dashboard/wordpressgray.png")
                                                              .default
                                                          }

                                                          alt="wordpress"
                                                          title="Wordpress App"
                                                        />
                                                        <img
                                                          src={
                                                            require("../../assets/images/dashboard/woogray.png")
                                                              .default
                                                          }

                                                          alt="Woocommerce"
                                                          title="WooCommerce App"
                                                        />
                                                      </>
                                                    )
                                                    : appname.website_technology === 4 ?
                                                      (
                                                        <img
                                                          src={
                                                            require("../../assets/images/dashboard/wordpressgray.png")
                                                              .default
                                                          }

                                                          alt="wordpress"
                                                          title="Wordpress App"
                                                        />
                                                      )
                                                      : appname.website_technology === 3 ?
                                                        <img
                                                          src={
                                                            require("../../assets/images/dashboard/customappgray.png")
                                                              .default
                                                          }

                                                          alt="wordpress"
                                                          title="Custom App"
                                                        />
                                                        :

                                                        <img
                                                          src={
                                                            require("../../assets/images/dashboard/websitegray.png")
                                                              .default
                                                          }

                                                          alt="Web to App"
                                                          title="Web to App"
                                                        />
                                                  }
                                                </div>
                                                {/* <div className="d-flex justify-content-start align-items-center web-woo-wp"> */}
                                                {/* {appname.website_technology === 5 ?
                                                  (

                                                    <>
                                                      <img
                                                        src={
                                                          require("../../assets/images/dashboard/web-myapps-icon.png")
                                                            .default
                                                        }
                                                        className="mr-2"
                                                        alt="wordpress"
                                                        title="Wordpress"
                                                      />
                                                      <img
                                                        src={
                                                          require("../../assets/images/dashboard/woo-myapps-icon.png")
                                                            .default
                                                        }
                                                        className="mr-2"
                                                        alt="woocommerce"
                                                        title="Woocommerce"
                                                      />
                                                    </>
                                                  )
                                                  : appname.website_technology === 4 ?
                                                    (
                                                      <img
                                                        src={
                                                          require("../../assets/images/dashboard/web-myapps-icon.png")
                                                            .default
                                                        }
                                                        className="mr-2"
                                                        alt="wordpress"
                                                        title="Wordpress"
                                                      />
                                                    )
                                                    : appname.website_technology === 3 ?
                                                      (
                                                        <img
                                                          src={
                                                            require("../../assets/images/dashboard/grey-custom-app-icon.png")
                                                              .default
                                                          }
                                                          className="mr-2"
                                                          alt="cms Icon"
                                                          title="Custom App"
                                                        />
                                                      ) : (
                                                        <img
                                                          src={
                                                            require("../../assets/images/dashboard/web-myapps-icon.png")
                                                              .default
                                                          }
                                                          className="mr-2"
                                                          alt="webtoapp"
                                                          title="Web to App"
                                                        />
                                                      )} */}
                                                {/* </div> */}

                                                {/* <div className="connectivity-myapps-new d-flex justify-content-center align-items-center">
                                                {(appname.website_type.toString() ===
                                                  process.env
                                                    .REACT_APP_webitetype_first_option ||
                                                  appname.website_type.toString() ===
                                                  process.env
                                                    .REACT_APP_webitetype_second_option ||
                                                  appname.website_type.toString() ===
                                                  process.env
                                                    .REACT_APP_webitetype_third_option) &&
                                                  parseInt(
                                                    appname.website_technology
                                                  ) !== 2 &&
                                                  parseInt(
                                                    appname.website_technology
                                                  ) !== 3 ? (
                                                  appname.is_verified === 1 &&
                                                    appname.plugin_keys === 1 ? (
                                                    <img
                                                      src={
                                                        require("../../assets/images/dashboard/connect-myapps-icon.png")
                                                          .default
                                                      }
                                                      title="Connected"
                                                      alt="createapp"
                                                      className="d-none d-md-block"
                                                    />
                                                  ) : (
                                                    <img
                                                      src={
                                                        require("../../assets/images/dashboard/notconnect-myapps-icon.png")
                                                          .default
                                                      }
                                                      title="Disconnected"
                                                      alt="notconnect"
                                                      className="d-none d-md-block"
                                                    />
                                                  )
                                                ) : appname.is_verified === 1 &&
                                                  parseInt(
                                                    appname.website_technology
                                                  ) !== 2 &&
                                                  parseInt(
                                                    appname.website_technology
                                                  ) !== 3 ? (
                                                  <img
                                                    src={
                                                      require("../../assets/images/dashboard/connect-myapps-icon.png")
                                                        .default
                                                    }
                                                    title="Connected"
                                                    alt="createapp"
                                                    className="d-none d-md-block"
                                                  />
                                                ) : parseInt(
                                                  appname.website_technology
                                                ) !== 2 &&
                                                  parseInt(
                                                    appname.website_technology
                                                  ) !== 3 ? (
                                                  <img
                                                    src={
                                                      require("../../assets/images/dashboard/notconnect-myapps-icon.png")
                                                        .default
                                                    }
                                                    title="Disconnected"
                                                    alt="notconnect"
                                                    className="d-none d-md-block"
                                                  />
                                                ) : (
                                                  <></>
                                                )}
                                                {appname.website_technology === 1 &&
                                                  appname.plugin_keys === 1 && (
                                                    <img
                                                      src={
                                                        require("../../assets/images/dashboard/lock-myapps-icon.png")
                                                          .default
                                                      }
                                                      title="Grant aceess"
                                                      alt="grant-access"
                                                      className=""
                                                    />
                                                  )}
                                              </div> */}

                                                {/* Cart Icon */}
                                                {/* {(appname.website_type.toString() ===
                                        process.env
                                          .REACT_APP_webitetype_first_option ||
                                        appname.website_type.toString() ===
                                          process.env
                                            .REACT_APP_webitetype_second_option ||
                                        appname.website_type.toString() ===
                                          process.env
                                            .REACT_APP_webitetype_third_option) &&
                                        parseInt(appname.website_technology) !==
                                          2 && (
                                          <div className="cart-my-app">
                                            <img
                                              src={
                                                require("../../assets/images/dashboard/cart-my-app.png")
                                                  .default
                                              }
                                              title="WooCommerce"
                                              alt="connect"
                                              className="d-none d-md-block"
                                            />
                                          </div>
                                        )} */}
                                                {/* {appname.subscription !== null &&
                                                appname.subscription.plan_name !==
                                                null && (
                                                  <>
                                                    {appname.subscription.plan_name
                                                      .toString()
                                                      .toLowerCase() !==
                                                      "preview" && (
                                                        <div className="android-my-app d-flex justify-content-end">
                                                          {appname.latest_build
                                                            .android !== null &&
                                                            (appname.latest_build
                                                              .android.build_status ===
                                                              "1" ||
                                                              appname.latest_build
                                                                .android
                                                                .build_status ===
                                                              "4") &&
                                                            appname.latest_build.android
                                                              .app_in_process === 0 && (
                                                              <img
                                                                src={
                                                                  require("../../assets/images/dashboard/android-myapps-icon.png")
                                                                    .default
                                                                }
                                                                title="Build Completed"
                                                                alt="createapp"
                                                                className="d-none d-md-block"
                                                              />
                                                            )}
                                                          {appname.latest_build.ios !==
                                                            null &&
                                                            (appname.latest_build.ios
                                                              .build_status === "1" ||
                                                              appname.latest_build.ios
                                                                .build_status ===
                                                              "4") &&
                                                            appname.latest_build.ios
                                                              .app_in_process === 0 && (
                                                              <img
                                                                src={
                                                                  require("../../assets/images/dashboard/apple-myapps-icon.png")
                                                                    .default
                                                                }
                                                                alt="createapp"
                                                                title="Build Completed"
                                                                className="ml-2 d-none d-md-block"
                                                              />
                                                            )}
                                                        </div>
                                                      )}
                                                  </>
                                                )} */}
                                              </div>
                                              <div className="clearfix"></div>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            {/* Mobile view */}
                                            <div
                                              className="w-100 d-flex d-md-none"
                                              onClick={(e) =>
                                                this.handleAppdata(
                                                  appname.user_id,
                                                  appname.subscription.status,
                                                  appname.app_name,
                                                  appname.id,
                                                  appname.version,
                                                  this.setname(appname.app_name),
                                                  appname.subscription,
                                                  appname.store_url,
                                                  appname.plugin_keys,
                                                  appname.menu_fill,
                                                  appname.dashboard_count,
                                                  appname.website_type,
                                                  appname.website_technology,
                                                  appname.is_verified,
                                                  appname.appicon_count,
                                                  appname.splash_count,
                                                  appname.login_reg_count,
                                                  appname.style_count
                                                )
                                              }
                                            >
                                              <div className=" onhoverparent-myapps myapps_smallscreen">
                                                {/* {appname.app_icons.icon !== "" ? 
								
								<div style={{background:appname.app_icons.app_frontend_display_icon === "" ? <></> : 'url(' +  process.env.REACT_APP_Image_Path + appname.user_id + "/" + appname.id + process.env.REACT_APP_AppIcon_Display + appname.app_icons.app_frontend_display_icon + ')',width:"auto",height:"100px"}} className="new-appimg">
								</div>
								: */}
                                                <div
                                                  style={{
                                                    background:
                                                      appname.app_icons
                                                        .app_frontend_display_icon ===
                                                        "" ? (
                                                        <></>
                                                      ) : (
                                                        "url(" +
                                                        process.env
                                                          .REACT_APP_Image_Path +
                                                        appname.user_id +
                                                        "/" +
                                                        appname.id +
                                                        process.env
                                                          .REACT_APP_AppIcon_Display +
                                                        appname.app_icons
                                                          .app_frontend_display_icon +
                                                        ")"
                                                      ),
                                                    width: "auto",
                                                    height: "100px",
                                                    borderRadius: "24px",
                                                  }}
                                                  className="new-appimg appimg-myapps"
                                                ></div>
                                                {/* } */}

                                                <div className="clearfix"></div>
                                              </div>
                                              <div className="topsection topsectionapp-ne topsectionapp-myapps myappps_topsectionapp ">
                                                <h2>{appname.app_name}</h2>

                                                {(this.props.match.params
                                                  .workspaceId === undefined ||
                                                  this.props.match.params
                                                    .workspaceId === "undefined") &&
                                                  appname.subscription !== null ? (
                                                  appname.show_plan_name === 1 ? (
                                                    <h3
                                                      className="namespac namespac-red namespac-red-margin"
                                                      style={{
                                                        color: "#7782A1",
                                                      }}
                                                    >
                                                      {appname.subscription
                                                        .plan_name === null
                                                        ? appname.subscription.plan_code
                                                          .toString()
                                                          .replace("_", " ")
                                                          .substr(0, 16) +
                                                          appname.subscription.plan_code
                                                            .toString()
                                                            .replace("_", " ")
                                                            .length >=
                                                          16
                                                          ? "..."
                                                          : ""
                                                        : window.matchMedia(
                                                          "(max-width: 359px)"
                                                        ).matches
                                                          ? appname.subscription.plan_name.substr(
                                                            0,
                                                            12
                                                          ) +
                                                          (appname.subscription
                                                            .plan_name.length >= 12
                                                            ? "..."
                                                            : "")
                                                          : appname.subscription
                                                            .plan_name}
                                                    </h3>
                                                  ) : (
                                                    <h3
                                                      className="namespac"
                                                      style={{
                                                        color: "#7782A1",
                                                        textTransform: "lowercase",
                                                      }}
                                                    >
                                                      {appname.store_url
                                                        .replace("https://", "")
                                                        .substr(0, 16) +
                                                        appname.store_url.replace(
                                                          "https://",
                                                          ""
                                                        ).length >=
                                                        16
                                                        ? "..."
                                                        : ""}
                                                    </h3>
                                                  )
                                                ) : null}
                                                <div className="d-flex align-items-center  new-mobile-myapp myapps_mobiledate">
                                                  <p
                                                    style={{
                                                      textAlign: "left",

                                                    }}
                                                    className="date-personal-mobile"
                                                  >
                                                    {moment(appname.created_at).format(
                                                      "D MMMM YYYY"
                                                    )}
                                                  </p>

                                                  {(this.props.match.params
                                                    .workspaceId === undefined ||
                                                    this.props.match.params
                                                      .workspaceId === "undefined") &&
                                                    appname.subscription !== null ? (
                                                    appname.show_plan_name === 1 && (

                                                      returnAppStatus(appname.subscription.status)


                                                      // appname.subscription.status ===
                                                      //   "live" ? (
                                                      //   <p
                                                      //     className={
                                                      //       appname.subscription
                                                      //         .status !== "free"
                                                      //         ? "myapps_viewstatus-responsive "
                                                      //         : "d-none"
                                                      //     }
                                                      //     style={{
                                                      //       color: "#47CE70",
                                                      //       border: "1px solid #47CE70",
                                                      //       backgroundColor: "#EFFFF4",
                                                      //     }}
                                                      //   >
                                                      //     {appname.subscription.status}
                                                      //   </p>
                                                      // ) : appname.subscription.status ===
                                                      //   "active" ? (
                                                      //   <p
                                                      //     className={
                                                      //       appname.subscription
                                                      //         .status !== "free"
                                                      //         ? "myapps_viewstatus-responsive "
                                                      //         : "d-none"
                                                      //     }
                                                      //     style={{
                                                      //       color: "#47CE70",
                                                      //       border: "1px solid #47CE70",
                                                      //       backgroundColor: "#EFFFF4",
                                                      //     }}
                                                      //   >
                                                      //     {appname.subscription.status}
                                                      //   </p>
                                                      // ) : (
                                                      //   <p
                                                      //     className={
                                                      //       appname.subscription
                                                      //         .status !== "free"
                                                      //         ? "myapps_viewstatus-responsive 8"
                                                      //         : "d-none"
                                                      //     }
                                                      //     style={{
                                                      //       color:
                                                      //         appname.subscription
                                                      //           .status === "active"
                                                      //           ? "#47CE70"
                                                      //           : "#E20000",
                                                      //       border:
                                                      //         appname.subscription
                                                      //           .status === "active"
                                                      //           ? "1px solid #47CE70"
                                                      //           : "1px solid #E20000",
                                                      //     }}
                                                      //   >
                                                      //     {appname.subscription.status}
                                                      //   </p>
                                                      // )
                                                    )
                                                  ) : null}
                                                </div>
                                                <div className="myapps-status-icons d-flex justify-content-between align-items-center myapps_statusiconimg">
                                                  <div className="d-flex justify-content-between align-items-center">
                                                    {appname.website_technology === 5 ? (

                                                      <>
                                                        <img
                                                          src={
                                                            require("../../assets/images/dashboard/web-myapps-icon.png")
                                                              .default
                                                          }
                                                          className="mr-1"
                                                          alt="wordpress"
                                                          title="Wordpress"
                                                        />
                                                        <img
                                                          src={
                                                            require("../../assets/images/dashboard/woo-myapps-icon.png")
                                                              .default
                                                          }
                                                          className="mr-1 woo-myapps-new"
                                                          alt="woocommerce"
                                                          title="Woocommerce"
                                                        />
                                                      </>
                                                    )
                                                      : appname.website_technology === 4 ? (
                                                        <>
                                                          <img
                                                            src={
                                                              require("../../assets/images/dashboard/web-myapps-icon.png")
                                                                .default
                                                            }
                                                            className="mr-1"
                                                            alt="wordpress"
                                                            title="Wordpress"
                                                          />

                                                        </>
                                                      ) : appname.website_technology ===
                                                        3 ? (
                                                        <img
                                                          src={
                                                            require("../../assets/images/dashboard/grey-custom-app-icon.png")
                                                              .default
                                                          }
                                                          className="mr-2"
                                                          alt="cms Icon"
                                                          title="Custom App"
                                                        />
                                                      ) : (
                                                        <img
                                                          src={
                                                            require("../../assets/images/dashboard/web-myapps-icon.png")
                                                              .default
                                                          }
                                                          className="mr-2"
                                                          alt="webtoapp"
                                                          title="Web to App"
                                                        />
                                                      )}
                                                  </div>
                                                  <div className="d-flex justify-content-between align-items-center">
                                                    {(appname.website_type.toString() ===
                                                      process.env
                                                        .REACT_APP_webitetype_first_option ||
                                                      appname.website_type.toString() ===
                                                      process.env
                                                        .REACT_APP_webitetype_second_option ||
                                                      appname.website_type.toString() ===
                                                      process.env
                                                        .REACT_APP_webitetype_third_option) &&
                                                      parseInt(
                                                        appname.website_technology
                                                      ) !== 2 &&
                                                      parseInt(
                                                        appname.website_technology
                                                      ) !== 3 ? (
                                                      appname.is_verified === 1 &&
                                                        appname.plugin_keys === 1 ? (
                                                        <img
                                                          src={
                                                            require("../../assets/images/dashboard/connect-myapps-icon.png")
                                                              .default
                                                          }
                                                          title="Connected"
                                                          alt="createapp"
                                                          className="mr-1"
                                                        />
                                                      ) : (
                                                        <img
                                                          src={
                                                            require("../../assets/images/dashboard/notconnect-myapps-icon.png")
                                                              .default
                                                          }
                                                          title="Disconnected"
                                                          alt="notconnect"
                                                          className="mr-1"
                                                        />
                                                      )
                                                    ) : appname.is_verified === 1 &&
                                                      parseInt(
                                                        appname.website_technology
                                                      ) !== 2 &&
                                                      parseInt(
                                                        appname.website_technology
                                                      ) !== 3 ? (
                                                      <img
                                                        src={
                                                          require("../../assets/images/dashboard/connect-myapps-icon.png")
                                                            .default
                                                        }
                                                        title="Connected"
                                                        alt="createapp"
                                                        className="mr-1"
                                                      />
                                                    ) : parseInt(
                                                      appname.website_technology
                                                    ) !== 2 &&
                                                      parseInt(
                                                        appname.website_technology
                                                      ) !== 3 ? (
                                                      <img
                                                        src={
                                                          require("../../assets/images/dashboard/notconnect-myapps-icon.png")
                                                            .default
                                                        }
                                                        title="Disconnected"
                                                        alt="notconnect"
                                                        className="mr-1"
                                                      />
                                                    ) : (
                                                      <></>
                                                    )}
                                                    {appname.website_technology === 1 &&
                                                      appname.plugin_keys === 1 && (
                                                        <img
                                                          src={
                                                            require("../../assets/images/dashboard/lock-myapps-icon.png")
                                                              .default
                                                          }
                                                          title="Grant aceess"
                                                          alt="grant-access"
                                                          className="mr-1"
                                                        />
                                                      )}
                                                  </div>
                                                  {appname.subscription !== null &&
                                                    appname.subscription.plan_name !==
                                                    null && (
                                                      <>
                                                        {appname.subscription.plan_name
                                                          .toString()
                                                          .toLowerCase() !==
                                                          "preview" && (
                                                            <div className="d-flex">
                                                              {appname.latest_build
                                                                .android !== null &&
                                                                (appname.latest_build
                                                                  .android
                                                                  .build_status === "1" ||
                                                                  appname.latest_build
                                                                    .android
                                                                    .build_status ===
                                                                  "4") &&
                                                                appname.latest_build
                                                                  .android
                                                                  .app_in_process ===
                                                                0 && (
                                                                  <img
                                                                    src={
                                                                      require("../../assets/images/dashboard/android-myapps-icon.png")
                                                                        .default
                                                                    }
                                                                    title="Build Completed"
                                                                    alt="createapp"
                                                                    className="mr-1"
                                                                  />
                                                                )}
                                                              {appname.latest_build
                                                                .ios !== null &&
                                                                (appname.latest_build.ios
                                                                  .build_status === "1" ||
                                                                  appname.latest_build.ios
                                                                    .build_status ===
                                                                  "4") &&
                                                                appname.latest_build.ios
                                                                  .app_in_process ===
                                                                0 && (
                                                                  <img
                                                                    src={
                                                                      require("../../assets/images/dashboard/apple-myapps-icon.png")
                                                                        .default
                                                                    }
                                                                    alt="createapp"
                                                                    title="Build Completed"
                                                                    className="mr-1"
                                                                  />
                                                                )}
                                                            </div>
                                                          )}
                                                      </>
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                            {/* Mobile view end */}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                {this.state.bottomspinner && (

                                  <div className="text-center w-100 m-5">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                      xlink="http://www.w3.org/1999/xlink"
                                      style={{ margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s" }}
                                      width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                      <g transform="translate(20 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                        <circle cx="0" cy="0" r="6" fill="#e6261f" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                          <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                            style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                          </animateTransform>
                                        </circle>
                                      </g>
                                      <g transform="translate(40 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                        <circle cx="0" cy="0" r="6" fill="#f7d038" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                          <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                            style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                          </animateTransform>
                                        </circle>
                                      </g>
                                      <g transform="translate(60 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                        <circle cx="0" cy="0" r="6" fill="#49da9a" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                          <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                            style={{ animationplaystate: "running", animationdelay: "0s" }}>

                                          </animateTransform>
                                        </circle>
                                      </g>
                                      <g transform="translate(80 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                        <circle cx="0" cy="0" r="6" fill="#4355db" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                          <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                            style={{ animationplaystate: "running", animationdelay: "0s" }}>

                                          </animateTransform>
                                        </circle>
                                      </g>
                                    </svg>
                                  </div>
                                )}
                                <div className="clearfix"></div>
                              </>
                            )}
                          </div>

                          <div className="clearfix"></div>
                        </div>

                      </div>
                  }
                </>
              )}


             <Modal 
              className="clone-conf-modal"
              style={{ maxWidth: "600px", maxHeight: "353px" }}
              isOpen={this.state.openClonePopUp} 
              // toggle={(e) => this.handleClonePopUp(e, "cancel")}
              centered   
            >
                    <ModalHeader>
             
                        <div className="notification-conf-secondmodal-crossmodal" style={{ zIndex:"9" }} 
                        onClick={(e)=>this.handleClonePopUp(e,"cancel")}
                        >
                            <img src={ require("../../assets/images/dashboard/cross.png").default } />
                        </div>
                    </ModalHeader>

                    <ModalBody>

                        <div className='clone-conf-modal-popup-maindiv'>
                            <div className='clone-conf-modal-popup-head'>
                            
                                <h2>Clone this app?</h2>
                            </div>

                            <p>Are you sure you want to clone this app? Cloning will create a replica of your source app.</p>
                  <div className="clone-conf-modal-input">
                    <h2>Give your app a name</h2>
                    <input value={this.state.cloneAppName} onChange={(e)=>this.handleChangeCloneName(e)} maxLength={30}/>
                  </div>
                  <div className="clone-conf-tick">
                  <label className="checkboxmainbox">
                                                <input
                                                  type="checkbox"
                                                  id="secureconnection"
                                                  checked={
                                                    this.state.isShareWithSameTeam
                                                  }
                                                  onClick={(e)=> this.handleShareWithTeamCheckbox(e)}
                                                />
                                                <span className="checkmark"></span>
                                                <p>Share it with the same team members</p>
                                              </label>
                   
                  </div>
                            <div className='clone-conf-modal-popup-bottom'>
                                
                                <button 
                                className='clone-conf-modal-popup-cancel'
                                onClick={(e)=>this.handleClonePopUp(e,"cancel")}
                                >Cancel</button>

                                <button 
                                className={`clone-conf-modal-popup-confirm ${(this.state.cloneAppName && this.state.cloneAppName.trim() ) ?"":"clone-conf-modal-popup-confirm-disable"}`}
                                style={{
                                  display:"flex",
                                  justifyContent:"center",
                                  alignItems:"center",
                                }}
                                disabled={(!this.state.cloneAppName || !this.state.cloneAppName.trim())}
                                onClick={(e)=>this.handleClonePopUp(e,"done")}
                                >
                                  <div className={this.state.cloneBtnLoader}>
                                    <div className="new-loader"></div>
                                  </div>
                                  <div>{this.state.cloneBtnText}</div>
                                  
                                  </button>

                            </div>
                        </div>

                    </ModalBody>

                </Modal>
          </div>


        </section>
      </>
    );
  }
}
