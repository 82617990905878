import moment from "moment";
import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import androidIc from "../../../Agency/assets/images/android.png";
import iosIc from "../../../Agency/assets/images/ios.png";
import crossIc from "../../../assets/images/signup/cross.png";

const ConfirmationModal = ({
  notificationData,
  setNotificationData,
  toggleConfirmationModal,
  handleSubmitNotification,
}) => {
  return (
    <Modal
      isOpen={notificationData.isConfirmModalOpen}
      centered
      dataclass="newnotification"
      className="created-msg-review-modal"
    >
      <ModalHeader
        toggle={toggleConfirmationModal}
        className="notificationmodal"
      >
        <div className="d-md-flex new-pus_notification_head align-items-center justify-content-between ">
          <h2 className="review-notification-header web-review-noticfication">
            Review basic notification
          </h2>
          <div onClick={toggleConfirmationModal}>
            <img className="newcrossmodal" src={crossIc} alt="cross" />
          </div>
        </div>
      </ModalHeader>
      <ModalBody className="newpush-notification-body">
        <h2 className="review-notification-header mobile-review-noticfication">
          Review basic notification
        </h2>
        <div className="round-corner">
          <div>
            <h4>{notificationData.title}</h4>
            <p>{notificationData.message} </p>
          </div>

          {notificationData.notificationImage && (
            <div className="d-flex justify-content-center">
              <img
                src={
                  process.env.REACT_APP_Image_Path +
                  localStorage.getItem("user_id") +
                  "/" +
                  localStorage.getItem("Appid") +
                  process.env.REACT_APP_Plain_Notification_Media +
                  notificationData.notificationImage
                }
                className=""
                alt="notification"
              />
            </div>
          )}
        </div>
        <div className="push-messagesendto">
          <p>Device</p>
          <div className="deviceSelected">
            {notificationData.settings.sendToAndroid && (
              <img src={androidIc} alt="android" />
            )}
            {notificationData.settings.sendToIos && (
              <img src={iosIc} alt="ios" />
            )}
          </div>
        </div>
        {notificationData.deliverySchedule === false ? (
          <div className="pushnotification-schedule">
            <h4>Schedule:</h4>
            <span>
              {moment(notificationData.date).format("D MMMM YYYY, HH:mm")}
            </span>
          </div>
        ) : (
          <div className="pushnotification-schedule">
            <h4>Schedule</h4>
            <span>Send immediately</span>
          </div>
        )}
        <div className="mobile-schedulebtn">
          <button
            onClick={() => handleSubmitNotification()}
            className="push-notificationsend"
          >
            <div className="d-flex align-items-center justify-content-center h-100 w-100">
              {notificationData.sendSpinner ? (
                <div>
                  <div className="new-loader"></div>
                </div>
              ) : (
                <div>Send</div>
              )}
            </div>
          </button>

          <button
            className=" push-notificationcancel"
            onClick={toggleConfirmationModal}
          >
            Cancel
          </button>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="w-100 newpus_notification_footer">
          <div className="d-flex align-items-center justify-content-between">
            <button
              className=" push-notificationcancel"
              onClick={toggleConfirmationModal}
            >
              Cancel
            </button>
            <button
              onClick={() => handleSubmitNotification()}
              className="push-notificationsend"
            >
              <div className="d-flex align-items-center justify-content-center h-100 w-100">
                {notificationData.sendSpinner ? (
                  <div>
                    <div className="new-loader"></div>
                  </div>
                ) : (
                  <div>Send</div>
                )}
              </div>
            </button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
