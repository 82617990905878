import React, { useEffect, useState } from "react";
import CommonBlog from "./CommonBlog";

const HMSrecentBlog = ({
  homescreenData,
  setHomescreenData,
  blockIndx,
  handleStatus,
  handleIsRowOpen,
  isRowOpen,
  setIsRowOpen,
  handleDeleteItem,
  eachBlock,
  handleDuplicateItem,presetColors,
  provided,
}) => {
  return (
    <CommonBlog
      homescreenData={homescreenData}
      setHomescreenData={setHomescreenData}
      blockIndx={blockIndx}
      handleStatus={handleStatus}
      handleIsRowOpen={handleIsRowOpen}
      isRowOpen={isRowOpen}
      setIsRowOpen={setIsRowOpen}
      handleDeleteItem={handleDeleteItem}
      eachBlock={eachBlock}
      handleDuplicateItem={handleDuplicateItem}
      presetColors={presetColors}
      provided={provided}
      from={"Recent"}
    />
  );
};

export default HMSrecentBlog;
