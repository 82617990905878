import React, {useState, useEffect} from "react";
import "./../assets/css/common.css";
import Header from "../../Dashboard/Header";
import LeftPanel from "./../LeftPanel";
import { Link, useParams } from "react-router-dom";
import {errortoast} from '../../Dashboard/Toaster';
import {
    getSubscriptionList,receivedSubscriptionData,changesubscriptionplan,getsearchsubsdata,getAgencyDomainStatus
} from "../../Api/Agency/AgencyApi";
import imagebackground from "./../assets/images/accountprofile.png";
import imagebackground2 from "./../assets/images/alexandergrey.jpg";
import moment from "moment";
import NewSpinner from "./../NewSpinner";
import BottomScrollListener from "react-bottom-scroll-listener";
import LimitReach from "./LimitReach";
import {
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import {
    Media,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Label,
    Input,
    FormGroup,
  } from "reactstrap";

const Subscriptions = () =>{

    const [rightpanelexpand,setRightPanelExpand] = useState(true);
    const [spinner, setSpinner] = useState(true);
    const [spinner1, setSpinner1] = useState(false);
    const [subscriptionlist, setSubscriptionList] = useState([]);
    const [plans, setplans] = useState("");
    const [mdleditplanpopup, setMdlEditPlanPopup] = useState(false);
    const [btnchangeplanloader, setBtnChangePlanLoader] = useState("d-none");
    const [btnchangelabel, setBtnChangeLabel] = useState("Change");
    const [openstatDropdown,setOpenStatDropdown] = useState(false);
    const [openstatDropdown1,setOpenStatDropdown1] = useState(false);
    const [openstatDropdown2,setOpenStatDropdown2] = useState(false);
    const [usrid,setusrid] = useState("");
    const [appid,setappid] = useState("");
    const [currentPage, setcurrentPage] = useState(1);
    const[stoplength,setstoplength] = useState(0);
    const[ChangePlanValue,setChangePlanValue] = useState('Select');
    const[PreviousPlanValue,setPreviousPlanValue] = useState('');
    const[ChangeTechValue,setChangeTechValue] = useState('Select');
    const[ChangeTechID,setChangeTechID] = useState('');
    const[ChangePlanID,setChangePlanID] = useState('');
    const[openMobileAppPlanDropdown,setopenMobileAppPlanDropdown] = useState(false);
    const[openMobileAppTechDropdown,setopenMobileAppTechDropdown] = useState(false);
    const[ChangeFreqValue,setChangeFreqValue] = useState('');
    const [btnsavelabel, setBtnSaveLabel] = useState("Change");
	const [btnloader, setBtnLoader] = useState("d-none");
    const [searcheable, setsearcheable] = useState(false);
    const [searchtext, setSearchText] = useState("");
    const [applimit, setAppLimit] = useState(0);
    const [islimitreachopen, setIsLimitReachOpen] = useState(false);
    const [subscriptionlimit, setSubscriptionLimit] = useState(0);
    const [paidsusbcriptionapps, setPaidSubscriptionApps] = useState(0);
    const[domainstatus,setDomainStatus] = useState('');
    const[domainname,setDomainName] = useState('');
    const [isagencydeal,setAgencyDeal] = useState(0);
    const [premiumaddonpurchased,setPremiumAddonPurchased] = useState(0);

    

    let { agencyID } = useParams("agencyID");

    useEffect(()=>{
        
        if(agencyID !== '' && agencyID !== undefined)
        {
            getSubscriptionList(agencyID, setSpinner, setSubscriptionList,setplans,setAppLimit,setSubscriptionLimit,setPaidSubscriptionApps,setAgencyDeal,setPremiumAddonPurchased);
            getAgencyDomainStatus(agencyID,setDomainName,setDomainStatus);
        }

        document.title = "Subscriptions | AppMySite";
        
    }, []);

    const handleTxtSearch = (e) =>
    {
        setSearchText(e.target.value);
    }
    const searchdata = (e) =>
    {
        if(e.keyCode == 13)
        {
            getsearchsubsdata(searchtext,agencyID, setSpinner, setSubscriptionList,setplans,setsearcheable);
        }
    }

    const handleMobileAppPlanDropdown=()=>{
   
        setopenMobileAppPlanDropdown(!openMobileAppPlanDropdown);
    }

    const handleMobileAppTechDropdown = () =>
    {
        setopenMobileAppTechDropdown(!openMobileAppTechDropdown);
    }

    const handleRightPanelExpand = (rightpanelvalue) => {
        setRightPanelExpand(rightpanelvalue);
    }

    const handleEditPlanPopup = (userid,appid,techid,planid,previousplan) =>
    {
        
            setusrid(userid);
            setappid(appid);
            setChangeTechID(techid);
            setPreviousPlanValue(previousplan);
            if(techid == 3)
            {
                setChangeTechValue('Custom App');
            }
            else if(techid == 4)
            {
                setChangeTechValue('Wordpress App');  
            }
            else if(techid == 2)
            {
                setChangeTechValue('Web to App'); 
            }
            else if(techid == 5)
            {
                setChangeTechValue('WooCommerce App'); 
            }
            setChangePlanID(planid);

            for (let i = 0; i < plans.length; i++) {
                if(plans[i].id == planid)
                {
                    setChangePlanValue(plans[i].plan_name);
                    setMdlEditPlanPopup(!mdleditplanpopup);
                    return true;
                }
                else
                {
                    setChangePlanValue('Free');
                }
            }
            
            setMdlEditPlanPopup(!mdleditplanpopup);
	}

    const btnCloseEditPlanPopup = () =>{
        setMdlEditPlanPopup(false);
    }

    const btnChangePlan = (id,value) =>{
        setChangePlanID(id);
        setChangePlanValue(value);
        setopenMobileAppPlanDropdown(false);
        setopenMobileAppTechDropdown(false);
    }

    const btnChangetech = (id,value) =>
    {
        setChangeTechID(id);
       setChangeTechValue(value);
       setopenMobileAppPlanDropdown(false);
       setopenMobileAppTechDropdown(false);
    }
    const btnChangefrequency = (value) =>
    {
        setChangeFreqValue(value);
    }
    const submitbtnChangePlan = () =>
    {
        if(ChangePlanValue != 'Free' && ChangePlanValue != 'Free')
        {
           
            if((ChangePlanValue != 'Free' && ChangePlanValue != 'Free' ) && isagencydeal == 1 &&  subscriptionlimit != '' && (subscriptionlimit <= paidsusbcriptionapps)) 
            {
                setIsLimitReachOpen(true);
            }
            else if(PreviousPlanValue != 'Free' && PreviousPlanValue != 'Free' )
            {
                setBtnChangePlanLoader("");
                setBtnChangeLabel("");
                changesubscriptionplan(usrid,appid,agencyID,ChangeTechID,ChangePlanID);
            }
            else
            {
                setBtnChangePlanLoader("");
                setBtnChangeLabel("");
                changesubscriptionplan(usrid,appid,agencyID,ChangeTechID,ChangePlanID);
            }
        }
        else
        {
            setBtnChangePlanLoader("");
            setBtnChangeLabel("");
            changesubscriptionplan(usrid,appid,agencyID,ChangeTechID,ChangePlanID);
        }
        
    }

    const handleStatdropdown = (rightpanelvalue) => {
        setOpenStatDropdown(!openstatDropdown);
    }
    const handleStatdropdown1 = (rightpanelvalue) => {
        setOpenStatDropdown1(!openstatDropdown1);
    }
    const handleStatdropdown2 = (rightpanelvalue) => {
        setOpenStatDropdown2(!openstatDropdown2);
    }

     // bottom scroll listner
    const handleBottomScrollListner = () => 
    {
        if(stoplength != 1)
        {
            setSpinner1(true);
            const selectedPage = currentPage+1;
            setcurrentPage(selectedPage);
            receivedSubscriptionData(selectedPage,agencyID, setSpinner1, subscriptionlist,setSubscriptionList,setstoplength);
        }
 
 
   };

    return(
        <>
        <BottomScrollListener onBottom={handleBottomScrollListner}>
        {(scrollRef) => (
        <>
            <div className="org-module org-module-customer-div" ref={scrollRef}>
        <Header />
        <div className="org-structure">

            <LeftPanel rightpanelbody={handleRightPanelExpand}/>
             
                <div className={(localStorage.getItem('sidebaserotate') !== null && localStorage.getItem('sidebaserotate') !== undefined && localStorage.getItem('sidebaserotate') === 'true') ? "org_background org_background-expand" : "org_background "}>
               
                        <div className="org-customer-head">

                            <div className="agency-headings agency-sub-minhead">
                                <div className="agency-sub-head">
                                    <h1>Subscriptions</h1>
                                    <p class="agency-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                    <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                                        <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                                        <circle cx="9" cy="9" r="9" stroke="none"/>
                                        <circle cx="9" cy="9" r="8.25" fill="none"/>
                                        </g>
                                        <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"/>
                                        <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                    </g>
                                    </svg><p class="agency-tooltipsubheader">All app subscriptions are listed here.</p></p>
                                    </div>

                                    { !spinner && isagencydeal == 1  && subscriptionlimit && subscriptionlimit != '' ?
                                        <>
                                                <h5 className="subscription-number-mob"><span>{paidsusbcriptionapps}/{subscriptionlimit}</span> used</h5>
                                        </>
                                        : <></>
                                        }

                                    </div>

                                    <div className="agency-subscription-stats">
                                        { !spinner && isagencydeal == 1  && subscriptionlimit && subscriptionlimit != '' ?
                                        <>
                                                <h5 className="subscription-number"><span>{paidsusbcriptionapps}/{subscriptionlimit}</span> used</h5>
                                        </>
                                        : <></>
                                        }

                                        { subscriptionlist.length > 0 || searcheable ?
                                            <div className="agency-search">
                                                
                                                <svg id="Group_37943" data-name="Group 37943" xmlns="http://www.w3.org/2000/svg" width="16.53" height="16.53" viewBox="0 0 16.53 16.53">
                                                    <g id="Ellipse_246" data-name="Ellipse 246" transform="translate(14.675) rotate(90)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                                                        <ellipse cx="7.337" cy="7.337" rx="7.337" ry="7.337" stroke="none"/>
                                                        <ellipse cx="7.337" cy="7.337" rx="6.587" ry="6.587" fill="none"/>
                                                    </g>
                                                    <path id="Path_6064" data-name="Path 6064" d="M0,5.347V0" transform="translate(16 16) rotate(135)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                                </svg>
                                                <input type="text" placeholder="Search" id="searchorgs" onKeyDown={(e) => searchdata(e)} onChange={(e)=>handleTxtSearch(e)} value={searchtext}/>
                                            </div>
                                        : null
                                        }
                                    </div>
                        </div>
                            

                    {spinner ?
                            <NewSpinner />
                    :    
                    <>                
                        {     ((subscriptionlist.length <= 0) ?
                                <div className="org-module-shadow">
                                    <div className="no-customer">
                                        <div>
                                            <img src={require("../assets/images/no-subscriptions.png").default} alt="no-subscriptions"/>
                                            <p>Nothing here at the moment. Come back later?</p>
                                            {domainstatus == 2 ?
                                                                  <a href={domainname} target="_blank">Go to my domain <svg xmlns="http://www.w3.org/2000/svg" width="11.2" height="11.2" viewBox="0 0 11.2 11.2">
                                                                  <g id="Group_9160" data-name="Group 9160" transform="translate(-695.4 -246.4)">
                                                                    <path id="Path_10239" data-name="Path 10239" d="M4,1H1A1,1,0,0,0,0,2V9a1,1,0,0,0,1,1H8A1,1,0,0,0,9,9V6" transform="translate(696 247)" fill="none" stroke="#c8cedb" stroke-width="1.2"/>
                                                                    <path id="Path_10240" data-name="Path 10240" d="M4,4V0H0" transform="translate(702 247)" fill="none" stroke="#c8cedb" stroke-width="1.2"/>
                                                                    <path id="Path_10241" data-name="Path 10241" d="M2144-23303l-6,6" transform="translate(-1438 23550)" fill="none" stroke="#c8cedb" stroke-width="1.2"/>
                                                                  </g>
                                                                </svg></a>
                                                                : <a href={`/agency/domain/`+(agencyID)}>Activate domain</a>
                                                                }
                                        </div>
                                    </div>
                                </div>
                            :    
                                <>
                                <div className="org-module-shadow agency-sub-web">
                                    <div className="agency-customer-list">
                                        <div className="agency-subscription-head">
                                            <div>
                                                <h6>App</h6>
                                            </div>
                                            <div>
                                                <h6>Domain</h6>
                                            </div>
                                            <div>
                                                <h6>Plan</h6>
                                            </div>
                                            <div>
                                                <h6>Customer</h6>
                                            </div>
                                            <div>
                                                <h6>Created</h6>
                                            </div>
                                        </div>

                                        <div className="agency-subscription-body">

                                            { subscriptionlist.map((subscriptionlist, subscriptionkey)=>{
                                                if(subscriptionlist.app_icon !== '' && subscriptionlist.app_icon !== null && subscriptionlist.app_icon !== undefined) 
                                                {
                                                    var appicon = JSON.parse(subscriptionlist.app_icon.create_json);
                                                }
                                                else
                                                {
                                                    var appicon = '';
                                                }
                                            
                                                return(
                                                <div className="customer-body-row" key={subscriptionkey}>

                                                    <div className="customer-profile agency-subscription-entry">
                                                        <div className="agency-app-image" 
                                                        style=
                                                        {{
                                                            backgroundImage:
                                                            appicon && appicon.app_frontend_display_icon && 
                                                            subscriptionlist.subscription[0] && 
                                                            subscriptionlist.subscription[0] !== null && 
                                                            subscriptionlist.subscription[0] !== undefined && 
                                                            subscriptionlist.subscription.length > 0 
                                                            ?
                                                                "url(" +
                                                                    process.env.REACT_APP_Image_Path +
                                                                    subscriptionlist.subscription[0].user_id +
                                                                    "/" +
                                                                    subscriptionlist.id +
                                                                    process.env.REACT_APP_AppIcon_Display +
                                                                    appicon.app_frontend_display_icon +
                                                                ")"
                                                            : "url(" + imagebackground2 + ")"
                                                            ,
                                                        }}
                                                        >
                                                        </div>
                                                        <div>
                                                            <h2>{ (subscriptionlist.app_name) }</h2>
                                                            <h3>App ID: { subscriptionlist.id }</h3>
                                                        </div>
                                                    </div>

                                                    <div className="agency-subscription-entry">
                                                        <h4>{subscriptionlist.store_url && subscriptionlist.store_url != '' ? subscriptionlist.store_url.replace('https://','') : '' }</h4>
                                                    </div>

                                                    <div className="agency-subscription-entry">
                                                        <h4>{subscriptionlist.subscription && subscriptionlist.subscription.length > 0 ? subscriptionlist.subscription[0].package_info.plan_name : '' }</h4>
                                                        <h5>Plan <span onClick={subscriptionlist.subscription && subscriptionlist.subscription.length > 0 ? ()=>handleEditPlanPopup(subscriptionlist.user_id,subscriptionlist.id,subscriptionlist.website_technology,subscriptionlist.subscription[0].package_info.id,subscriptionlist.subscription[0].package_info.plan_name) : ()=>handleEditPlanPopup(subscriptionlist.user_id,subscriptionlist.id,subscriptionlist.website_technology,'','') }>Edit</span></h5>
                                                    </div>

                                                    <div className="customer-profile agency-subscription-entry">

                                                
                                                        <div  className="subscription-profile-image"  style={{backgroundImage: 
                                                            subscriptionlist.app_user.profile_image !== '' && 
                                                            subscriptionlist.app_user.profile_image !== null && 
                                                            subscriptionlist.app_user.profile_image !== "null" && 
                                                            subscriptionlist.app_user.profile_image.indexOf("https") === -1 ? 
                                                            "url(" +process.env.REACT_APP_Image_Path + subscriptionlist.app_user.id + process.env.REACT_APP_Profile + subscriptionlist.app_user.profile_image+ ")" : 
                                                            subscriptionlist.app_user.profile_image !== '' && subscriptionlist.app_user.profile_image !== null
                                                                && subscriptionlist.app_user.profile_image !== "null" ? 
                                                            "url(" +subscriptionlist.app_user.profile_image + ")"
                                                            :  "url(" +imagebackground+ ")"
                                                        }}
                                                        >
                                                        </div>
                                                        <div>
                                                            <h4>{ (subscriptionlist.app_user.name).charAt(0).toUpperCase() + (subscriptionlist.app_user.name).slice(1) }</h4>
                                                            <h3>User ID: { subscriptionlist.app_user.id }</h3>
                                                        </div>
                                                    </div>

                                                    <div className="agency-subscription-entry">
                                                        <h4>{ moment( subscriptionlist.created_at ).format("D MMMM YYYY, HH:mm") }</h4>
                                                    </div>

                                                </div>
                                                )})
                                            } 
                                            {spinner1 ?
                                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                                                                <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                    <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                        <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                        </animateTransform>
                                                                                    </circle>
                                                                                </g>
                                                                                <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                    <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                        <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                        </animateTransform>
                                                                                    </circle>
                                                                                </g>
                                                                                <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                    <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                        <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                        </animateTransform>
                                                                                    </circle>
                                                                                </g>
                                                                                <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                    <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                        <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                        </animateTransform>
                                                                                    </circle>
                                                                                </g>
                                                                            </svg>
                                                                            :
                                                                                null
                                                                            }                               

                                        </div>

                                       
                                    </div>
                                </div>

                                <Modal isOpen={mdleditplanpopup} style={{ maxWidth: "600px", maxHeight: "304px" }} className="agency-change-plan-popup" centered>
                                            <ModalBody>
                                                <div className="crossmodal mt-1" onClick={btnCloseEditPlanPopup}>
                                                    <img
                                                        src={require("../assets/images/cross.png").default}
                                                    />
                                                </div>
                                                <div>
                                                    <div className="delete-org-body">
                                                        <h4>Edit Plan</h4>
                                                        <h5>Select a new plan from the list below to modify this subscription.</h5>

                                                        <label>Technology</label>
                                                        <div className="search-dropdown">
                                                        <Dropdown isOpen={window.matchMedia("(max-width: 1200px)").matches?false : openstatDropdown}
                                                        onClick={window.matchMedia("(max-width: 1200px)").matches ? handleMobileAppPlanDropdown:null}
                                                        toggle={handleStatdropdown}
                                                        >
                                                            <DropdownToggle caret>
                                                            {ChangeTechValue}
                                                            </DropdownToggle>
                                                            <DropdownMenu >
                                                        
                                                                
                                                            <DropdownItem onClick={() => btnChangetech('3','Custom App')}>Custom App</DropdownItem>
                                                            <DropdownItem onClick={() => btnChangetech('2','Web to App')}>Web to App</DropdownItem> 
                                                            <DropdownItem onClick={() => btnChangetech('4','Wordpress App')}>Wordpress App</DropdownItem> 
                                                            <DropdownItem onClick={() => btnChangetech('5','WooCommerce App')}>WooCommerce App</DropdownItem>
                                                        
                                                            </DropdownMenu>
                                                            </Dropdown>
                                                        </div>

                                                        <label>Plan</label>
                                                        <div className="search-dropdown">
                                                        <Dropdown isOpen={window.matchMedia("(max-width: 1200px)").matches?false : openstatDropdown1}
                                                        onClick={window.matchMedia("(max-width: 1200px)").matches ? handleMobileAppTechDropdown:null}
                                                        toggle={handleStatdropdown1}
                                                        >
                                                            <DropdownToggle caret>
                                                            {ChangePlanValue}
                                                            </DropdownToggle>
                                                            <DropdownMenu >
                                                           
                                                            { plans.map((planlist, plankey)=>{
                                                            
                                                            return(
                                                                  isagencydeal == 1 && premiumaddonpurchased ==  0 ?
                                                                  <>
                                                                    {(planlist.plan_code == 'agency_preview' || planlist.plan_code == 'agency_pro' ) &&  
                                                                        <DropdownItem onClick={() => btnChangePlan(planlist.id,planlist.plan_name)}>{planlist.plan_name}</DropdownItem>
                                                                    }
                                                                  </>
                                                                : isagencydeal == 1 && premiumaddonpurchased == 1 ?
                                                                  <>
                                                                    {(planlist.plan_code == 'agency_preview' || planlist.plan_code == 'agency_premium' || planlist.plan_code == 'agency_pro' ) &&  
                                                                        <DropdownItem onClick={() => btnChangePlan(planlist.id,planlist.plan_name)}>{planlist.plan_name}</DropdownItem>
                                                                    }
                                                                 </>
                                                                : isagencydeal == 0 && premiumaddonpurchased == 0 ?
                                                                  <DropdownItem onClick={() => btnChangePlan(planlist.id,planlist.plan_name)}>{planlist.plan_name}</DropdownItem>
                                                                : 
                                                                  <DropdownItem onClick={() => btnChangePlan(planlist.id,planlist.plan_name)}>{planlist.plan_name}</DropdownItem>
                                                                )}
                                                        )}
                                                            
                                                            </DropdownMenu>
                                                            </Dropdown>
                                                        </div>

                                                        {/* <label>Frequency</label>
                                                        <div className="search-dropdown">
                                                        <Dropdown isOpen={window.matchMedia("(max-width: 1200px)").matches?false : openstatDropdown2}
                                                        //   onClick={window.matchMedia("(max-width: 1200px)").matches?this.handleMobileAppDropdown:null}
                                                        toggle={handleStatdropdown2}
                                                        >
                                                            <DropdownToggle caret>
                                                            Select
                                                            </DropdownToggle>
                                                            <DropdownMenu >
                                                                
                                                            
                                                            <DropdownItem onClick={() => btnChangefrequency('1')}>Monthly</DropdownItem> 
                                                            <DropdownItem onClick={() => btnChangefrequency('2')}>Yearly</DropdownItem> 
                                                            <DropdownItem onClick={() => btnChangefrequency('3')}>Lifetime</DropdownItem>
                                                            <DropdownItem onClick={() => btnChangefrequency('4')}>Free</DropdownItem>

                                                            </DropdownMenu>
                                                            </Dropdown>
                                                        </div> */}
                                                        
                                                    </div>

                                                    <div className="delete-agency-footer">
                                                        <div className="delete-agency-footer-cancel" onClick={btnCloseEditPlanPopup}>Cancel</div>

                                                        <div className="delete-agency-footer-change" onClick={submitbtnChangePlan}>
                                                            <div className={btnchangeplanloader}>
                                                                <div className="new-loader"></div>
                                                            </div>
                                                            { btnchangelabel }
                                                        </div>
                                                    </div>
                                                </div>
                                            </ModalBody>
                                </Modal>

                                <Modal className="protip-popup-message" isOpen={openMobileAppPlanDropdown} centered>
          
                                    <ModalBody>
                                        <div className="mobileglobalsearch">
                                        <button onClick={handleMobileAppPlanDropdown}><svg xmlns="http://www.w3.org/2000/svg" width="20.759" height="15.408" viewBox="0 0 20.759 15.408">
                                        <g id="Group_27465" data-name="Group 27465" transform="translate(-19.742 -22.295)">
                                        <path id="Path_81245" data-name="Path 81245" d="M-13783.922-19217h19.346" transform="translate(13805.077 19247)" fill="none" stroke="#7782a1" stroke-width="2"/>
                                        <path id="Path_82232" data-name="Path 82232" d="M14053.656,19255.426l-7,7,7,7" transform="translate(-14025.504 -19232.424)" fill="none" stroke="#7782a1" stroke-width="2"/>
                                        </g>
                                    </svg></button>
                                    
                                    </div>

                                    <div className="mobilesearchsuggestion">
                                    
                                                             <p className="mobilesearchsuggestion-para"  onClick={() => btnChangetech('3','Custom App')}>Custom App</p>
                                                             <p className="mobilesearchsuggestion-para"  onClick={() => btnChangetech('2','Web to App')}>Web to App</p> 
                                                             <p className="mobilesearchsuggestion-para"  onClick={() => btnChangetech('4','Wordpress App')}>Wordpress App</p> 
                                                             <p className="mobilesearchsuggestion-para"  onClick={() => btnChangetech('5','WooCommerce App')}>WooCommerce App</p>


                                        </div>
                                    </ModalBody>
                                </Modal>

                                <Modal className="protip-popup-message" isOpen={openMobileAppTechDropdown} centered>
          
                                    <ModalBody>
                                        <div className="mobileglobalsearch">
                                        <button onClick={handleMobileAppTechDropdown}><svg xmlns="http://www.w3.org/2000/svg" width="20.759" height="15.408" viewBox="0 0 20.759 15.408">
                                        <g id="Group_27465" data-name="Group 27465" transform="translate(-19.742 -22.295)">
                                        <path id="Path_81245" data-name="Path 81245" d="M-13783.922-19217h19.346" transform="translate(13805.077 19247)" fill="none" stroke="#7782a1" stroke-width="2"/>
                                        <path id="Path_82232" data-name="Path 82232" d="M14053.656,19255.426l-7,7,7,7" transform="translate(-14025.504 -19232.424)" fill="none" stroke="#7782a1" stroke-width="2"/>
                                        </g>
                                    </svg></button>
                                    
                                    </div>

                                    <div className="mobilesearchsuggestion">
                                    
                                                        { plans.map((planlist, plankey)=>{
                                                            
                                                            return(
                                                                isagencydeal == 1 && premiumaddonpurchased ==  0 ?
                                                                <>
                                                                  {(planlist.plan_code == 'agency_preview' || planlist.plan_code == 'agency_pro' ) &&  
                                                                      <p className="mobilesearchsuggestion-para" onClick={() => btnChangePlan(planlist.id,planlist.plan_name)}>{planlist.plan_name}</p>
                                                                  }
                                                                </>
                                                              : isagencydeal == 1 && premiumaddonpurchased == 1 ?
                                                                <>
                                                                  {(planlist.plan_code == 'agency_preview' || planlist.plan_code == 'agency_premium' || planlist.plan_code == 'agency_pro' ) &&  
                                                                      <p className="mobilesearchsuggestion-para" onClick={() => btnChangePlan(planlist.id,planlist.plan_name)}>{planlist.plan_name}</p>
                                                                  }
                                                               </>
                                                              : isagencydeal == 0 && premiumaddonpurchased == 0 ?
                                                                <p className="mobilesearchsuggestion-para" onClick={() => btnChangePlan(planlist.id,planlist.plan_name)}>{planlist.plan_name}</p>
                                                              : <p className="mobilesearchsuggestion-para" onClick={() => btnChangePlan(planlist.id,planlist.plan_name)}>{planlist.plan_name}</p>
                                                                
                                                            )}
                                                        )}
                                                           

                                        </div>
                                    </ModalBody>
                                </Modal>

                                <div className="agency-sub-mobile">
                                    
                                    { subscriptionlist.map((subscriptionlist, subscriptionkey)=>{
                                                    if(subscriptionlist.app_icon !== '' && subscriptionlist.app_icon !== null && subscriptionlist.app_icon !== undefined) 
                                                    {
                                                        var appicon = JSON.parse(subscriptionlist.app_icon.create_json);
                                                    }
                                                    else
                                                    {
                                                        var appicon = '';
                                                    }
                                                
                                                return(
                                                    <div className="org-module-shadow" key={subscriptionkey}>

                                                        <div className="agency-sub-mob-1">
                                                            <div>
                                                                <h2>{ (subscriptionlist.app_name) }</h2>
                                                                <h3>App ID: { (subscriptionlist.id) }</h3>
                                                            </div>

                                                            <div className="agency-app-image-mobile" style=
                                                            {{
                                                                backgroundImage:
                                                                appicon && appicon.app_frontend_display_icon && 
                                                                subscriptionlist.subscription[0] && 
                                                                subscriptionlist.subscription[0] !== null && 
                                                                subscriptionlist.subscription[0] !== undefined && 
                                                                subscriptionlist.subscription.length > 0 
                                                                ?
                                                                    "url(" +
                                                                        process.env.REACT_APP_Image_Path +
                                                                        subscriptionlist.subscription[0].user_id +
                                                                        "/" +
                                                                        subscriptionlist.id +
                                                                        process.env.REACT_APP_AppIcon_Display +
                                                                        appicon.app_frontend_display_icon +
                                                                    ")"
                                                                : "url(" + imagebackground2 + ")"
                                                                ,
                                                            }}></div>
                                                        </div>

                                                        <div className="agency-sub-mob-2">
                                                            <div>
                                                                <h6>Domain</h6>
                                                                <h4>{subscriptionlist.store_url && subscriptionlist.store_url !='' ? subscriptionlist.store_url.replace('https://','') : '' }</h4>
                                                            </div>
                                                        </div>

                                                        <div className="agency-sub-mob-3">
                                                            <div>
                                                                <h6>Plan</h6>
                                                                <h4>{subscriptionlist.subscription && subscriptionlist.subscription.length > 0 ? subscriptionlist.subscription[0].package_info.plan_name : '' }</h4>
                                                                {/* <h5>Plan</h5> */}
                                                            </div>

                                                            <span onClick={subscriptionlist.subscription && subscriptionlist.subscription.length > 0 ? ()=>handleEditPlanPopup(subscriptionlist.user_id,subscriptionlist.id,subscriptionlist.website_technology,subscriptionlist.subscription[0].package_info.id) : ()=>handleEditPlanPopup(subscriptionlist.user_id,subscriptionlist.id,subscriptionlist.website_technology,'') }>Edit</span>
                                                        </div>

                                                        <div className="agency-sub-mob-4">
                                                            <div>
                                                                <h6>Customer</h6>
                                                                <h4>{ (subscriptionlist.app_user.name).charAt(0).toUpperCase() + (subscriptionlist.app_user.name).slice(1) }</h4>
                                                                <h5>User ID:  { subscriptionlist.app_user.id }</h5>
                                                            </div>

                                                            <div  className="subscription-profile-image-mob"  style={{backgroundImage: 
                                                                                                                        subscriptionlist.app_user.profile_image !== '' && 
                                                                                                                        subscriptionlist.app_user.profile_image !== null && 
                                                                                                                        subscriptionlist.app_user.profile_image.indexOf("https") === -1 ? 
                                                                                                                        "url(" +(process.env.REACT_APP_Image_Path + subscriptionlist.app_user.id + process.env.REACT_APP_Profile + subscriptionlist.app_user.profile_image)+ ")" : 
                                                                                                                        subscriptionlist.app_user.profile_image !== '' && subscriptionlist.app_user.profile_image !== null ? 
                                                                                                                        "url(" +subscriptionlist.app_user.profile_image + ")"
                                                                                                                        :  "url(" +imagebackground+ ")"
                                                                                                                    }}
                                                            >
                                                            </div>
                                                        </div>
                                                        <div className="agency-sub-mob-5">
                                                            <div>
                                                                <h6>Created</h6>
                                                                <h4>{ moment( subscriptionlist.created_at ).format("D MMMM YYYY, HH:mm") }</h4>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    
                                                )})
                                    }
                                    {spinner1 ?
                                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                                                                <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                    <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                        <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                        </animateTransform>
                                                                                    </circle>
                                                                                </g>
                                                                                <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                    <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                        <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                        </animateTransform>
                                                                                    </circle>
                                                                                </g>
                                                                                <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                    <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                        <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                        </animateTransform>
                                                                                    </circle>
                                                                                </g>
                                                                                <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                    <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                        <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                        </animateTransform>
                                                                                    </circle>
                                                                                </g>
                                                                            </svg>
                                                                            :
                                                                                null
                                                                            }     

                                </div>

                                    </>
                            )
                        }  
                    </>
                    }
                    
                </div>
           
        </div>
    </div>
    </>
        )}
        </BottomScrollListener>
         <LimitReach limitreachpop={islimitreachopen} btnCrossLimitReach={setIsLimitReachOpen}/>
        </>
        );


    
}

export default Subscriptions;