import axios from "axios";
import S3 from 'react-aws-s3';
import { errortoast, sucesstoast } from "../../Dashboard/Toaster";
import { CustomS3Client } from "../../utils/S3utils";
const Url = process.env.REACT_APP_API_URL + "api/app/";

// For Publish Module
export const Publishget = (
  params,
  setData,
  setAppid,
  setSpinner,
  setlast_successful_build,
  setis_build_process, 
  setpublishing_logs,
  setCode,
  setplan_code,
  setaddon_code ,
  setreseller_package_id,
  setnew, 
  load, 
  setLoader, 
  setsubmit,
  setEnableScreen,
  setDeviceType,
  setsubmitreview,
  setsubmitreviewloader,
  setappuser_role_id,
  setBottomSpinner,
  setMultisiteData,
  setOpenApiSuccessPopUp,
  setOpenApiErrPopUp,
  isTimeOut,
  ) => {
  
  const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    axios.get(Url + "get-ios-publishing-data/" + params.app_id + "&timestamp=" + new Date().getTime(),{
      headers: headers
        }).then(res => {
          if(res.data.code === 200){
              setBottomSpinner(false);
              setData(res.data.data);
              setAppid(res.data.data === null ? "" : res.data.data.apple_id);
              setlast_successful_build(res.data.last_successful_build);
              setis_build_process(res.data.is_build_process);
              setpublishing_logs(res.data.publishing_logs);
              setCode(res.data.code);
              setnew(res.data.data === null ? "" : res.data.data.whats_new === null ? "" : res.data.data.whats_new);
              setSpinner(false);
              setLoader("d-none");
              setsubmitreviewloader("d-none");
              setsubmit("Submit");
              setsubmitreview("Submit for review");
              setplan_code(res.data.plan?res.data.plan:res.data.plan_code);
              setEnableScreen(res.data.is_enable_screen);
              setDeviceType(res.data.device_type);
              setappuser_role_id(res.data.appuser_role_id);
              if(res.data.data !== null){
                if(isTimeOut==="timeout"){
                  if(res.data.data.publishing_status === 3){
                    setOpenApiErrPopUp(true);
                  }else if(res.data.data.publishing_status === 4){
                    setOpenApiSuccessPopUp(true);

                  }
                }
                if(res.data.data.publishing_status === 1 || res.data.data.publishing_status === 2){
                  load(true);
                }
              }
              setMultisiteData(res.data.multisite);
          }
          else if(res.data.code === 203){
              setappuser_role_id(res.data.appuser_role_id);
              setCode(res.data.code);
              setplan_code(res.data.plan?res.data.plan:res.data.plan_code);
              setreseller_package_id(res.data.reseller_package_id);
              setSpinner(false);
              setBottomSpinner(false);
              setaddon_code(res.data.addon_code);
          }
        })
       
   
}


// For Publish App store connection
export const getPublishApi= (        
  setSpinner,
  setkey_id,
  setissue_id,
  setapple_id,
  setcertificatefilename,
  setcertificates3name,
  setPlan,
  setUpdatedAt,
  setAppstoreConnectionStatus,
  load,
  setErrorDescription,
  setOpenNotConnectedPopUp,
  setIsBuildInProcess,
  from,setCode
  ) => {
  
  const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    axios.get(Url + "get-ios-publishing-data/" +btoa(parseInt(localStorage.getItem("Appid"))) + "&timestamp=" + new Date().getTime(),{
      headers: headers
        }).then(res => {
          if(res.data.code === 200){
            setCode(res.data.code);
            setPlan(res.data.plan);
             setSpinner(false);
              if(res.data.data !== null){
                setkey_id(res.data.data.key_id);
                setissue_id(res.data.data.issuer_id);
                setapple_id(res.data.data.apple_id);
                setcertificatefilename(res.data.data.key_file);
                setcertificates3name(res.data.data.key_file);
                setUpdatedAt(res.data.data.updated_at);
                setAppstoreConnectionStatus(res.data.data.publishing_connectivity_status);
                setIsBuildInProcess(res.data.is_build_process);
                if(from === "timeout"){
                if(res.data.data && res.data.data.publishing_connectivity_status === 3 ){
                  setErrorDescription(res.data.data ? res.data.data.publishing_connectivity_description:"");
                  setOpenNotConnectedPopUp(true);
                }else if(res.data.data && res.data.data.publishing_connectivity_status === 2 ){
                  sucesstoast("The record has been verified.");  
                }
              }


                if(res.data.data.publishing_connectivity_status===1){
                  load(true);
                }
              }
            
          }
          else if(res.data.code === 203){
             setPlan(res.data.plan);
             setCode(res.data.code);
              // setappuser_role_id(res.data.appuser_role_id);
              // setCode(res.data.code);
              // setreseller_package_id(res.data.reseller_package_id);
              setSpinner(false);
              // setaddon_code(res.data.addon_code);
          }
        })
       
   
}

export const CreateConnection = (
  params,
  setSaveBtnText,
  setVerifyBtnText,
  setLoaderForSaveBtn,
  setLoaderForVerifyBtn,
  setUpdatedAt,
  setOpenNotConnectedPopUp,
  setAppstoreConnectionStatus,
  appstoreConnectionStatus,
  setErrorDescription,
  load,
  from
) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.post(Url + "ios-publishing-data",params,{
      headers: headers
        }).then(res => {
          if(res.data.code === 200){
            setUpdatedAt(res.data.updated_at);
            if(from==="save"){

              setSaveBtnText("Save");
              setLoaderForSaveBtn("d-none");
              sucesstoast("The record has been saved.");
              
            }else{
              if(res.data.publishing_data && res.data.publishing_data.publishing_connectivity_status===1){
                load(true);
              }
              setAppstoreConnectionStatus(res.data.publishing_data ? res.data.publishing_data.publishing_connectivity_status : appstoreConnectionStatus);
              setVerifyBtnText("Verify");
              setLoaderForVerifyBtn("d-none");
              // if(res.data.publishing_data && res.data.publishing_data.publishing_connectivity_status === 3 ){
              //   setErrorDescription(res.data.publishing_data ? res.data.publishing_data.publishing_connectivity_description:"");
              //   setOpenNotConnectedPopUp(true);
              // }else if(res.data.publishing_data && res.data.publishing_data.publishing_connectivity_status === 2 ){
              //   sucesstoast("The record has been verified.");  
              // }
            }
            
          }else{

            if(from==="save"){
              errortoast("Oh no! Something’s not right.");
              setSaveBtnText("Save");
              setLoaderForSaveBtn("d-none");
            
              
            }else{
              setAppstoreConnectionStatus(0);
              errortoast("Oh no! Something’s not right.");
              setVerifyBtnText("Verify");
              setLoaderForVerifyBtn("d-none");
              
            }
          }
       }).catch((error) => {
        
        if(from==="save"){

          setSaveBtnText("Save");
          setLoaderForSaveBtn("d-none");
          
          
        }else{
          setVerifyBtnText("Verify");
          setLoaderForVerifyBtn("d-none");
          
        }
        errortoast("Oh no! Something’s not right.");
    })
   
}
export const Publishform = (
  params, 
  setLoader,
  setsubmit,
  load,
  setSaveBtnText,
  setLoaderForSaveBtn,
  setUpdatedAt,
  setBottomSpinner,
  data,
  setData,
  from,

  ) => {
  if(from === "submit"){
    setLoader("d-block");
    setsubmit("");
  }else{
    setLoaderForSaveBtn("d-block");
    setSaveBtnText("");
  }
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': "Bearer " + localStorage.getItem("amstoken"),
  }
  
  axios.post(Url + "ios-publishing-data",params,{
    headers: headers
      }).then(res => {
        if(res.data.code === 200){
          setUpdatedAt(res.data.updated_at);
          //hit get api
          load(false);
          // setBottomSpinner(true);
          if(from === "submit"){
            setLoader("d-none");
            setsubmit("Submit");
          }else{
            setLoaderForSaveBtn("d-none");
            setSaveBtnText("Save");
          }
        }
        else if(res.data.code === 202){
          sucesstoast("The record has been saved.");
          setUpdatedAt(res.data.updated_at);
          load(false);
          // setBottomSpinner(true);
          if(from === "submit"){
            setLoader("d-none");
            setsubmit("Submit");
          }else{
            setLoaderForSaveBtn("d-none");
            setSaveBtnText("Save");
          }
        }
        else{
          errortoast("Oh no! Something’s not right.");
          if(from === "submit"){
            setLoader("d-none");
            setsubmit("Submit");
            let newData = {...data};
            newData.publishing_status = 3;
            setData(newData);
          }else{
            setLoaderForSaveBtn("d-none");
            setSaveBtnText("Save");
          }
          
        }
     }).catch( (error) => {
     
     if(from === "submit"){
      setLoader("d-none");
      setsubmit("Submit");
      let newData = {...data};
      newData.publishing_status = 3;
      setData(newData);
    }else{
      setLoaderForSaveBtn("d-none");
      setSaveBtnText("Save");
    }
      errortoast("Oh no! Something’s not right.");
  })
 
}

export const Certificate= (params, setcertificates3name, setdisabledsubmitbutton) => {
  
  function dataURLtoFileoriginal(dataurl, filename) {
    var arr = dataurl.split(','),
        //mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: "application/p8" });
  }
  let originalfile = dataURLtoFileoriginal(params.original,'hello.p8');
  const oriconfig = {
    bucketName: process.env.REACT_APP_bucketName,
    dirName: params.original_path.replace(/\/$/, ""), /* optional */
    region: process.env.REACT_APP_region,
    accessKeyId: process.env.REACT_APP_accessKeyId,
    secretAccessKey: process.env.REACT_APP_secretAccessKey,
    s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, ""), /* optional */
  }
  // const ReactS3original = new S3(oriconfig);
  const customS3Client = new CustomS3Client(oriconfig);
  const originalFileName = params.imgname;
  customS3Client.uploadFile(originalfile,originalFileName).then(data => {
        if(data.status === 204){
          setcertificates3name(data.key.split("/").pop());
          setdisabledsubmitbutton(false)
        }
  }
  ).catch(err => {
        errortoast("Something went wrong. Try again after sometime.")
      })
}

//unlink app
export const UnlinkMergeApp = (params,setConfirmBtnLoader,setConfirmBtnText) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "multi-site/unlink-app", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        sucesstoast("App successfully unlinked");
        setConfirmBtnLoader("d-none");
        setConfirmBtnText("Confirm");
        window.location.reload(true);
      } else {
        errortoast(res.data.data);
        setConfirmBtnLoader("d-none");
        setConfirmBtnText("Confirm");
      }
    })
    .catch((err) => {
      setConfirmBtnLoader("d-none");
      setConfirmBtnText("Confirm");
      
    });
};