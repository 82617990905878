import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiEndPoints from "../utils/apiEndpoints";

export const createAppApiSlice = createApi({
  reducerPath: "appCreate",
  keepUnusedDataFor: 120,
  baseQuery: fetchBaseQuery({
    baseUrl: apiEndPoints.userUrl,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("amstoken")}`
      );
      return headers;
    },
  }),
  endpoints: (builder) => ({
    ShowtecApi: builder.query({
      query: ({ workspaceId }) => `technology/${workspaceId}`,
    }),
    ShowtypeApi: builder.query({
      query: () =>
        `websitetype?agency_id=${btoa(localStorage.getItem("agencyid"))}`,
    }),
    CreateApi: builder.mutation({
      query: (newData) => ({
        url: "app_details",
        method: "POST",
        body: newData,
      }),
    }),
  }),
});

export const { useShowtecApiQuery, useCreateApiMutation, useShowtypeApiQuery } =
  createAppApiSlice;
