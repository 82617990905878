import React from "react";
import Header from "./../Header";
import Leftpanel from "./../Leftpanel";
import imagebackground from "./../../assets/images/dashboard/cms/uploadbackground.png";
import imagebackground2 from "./../../assets/images/dashboard/file-icon.png";
import { 
	Modal,
	ModalHeader,
	ModalBody,
} from "reactstrap";
import Accordion from 'react-bootstrap/Accordion';
import {BuildappApi,androidbuilingapp,iosAndroidBuildReset} from "../../Api/Dashboard/DashboardApi";
import {newgetFormData,andoridsave,jksfile} from "../../Api/Custombundle/Custombundle";
import { errortoast, sucesstoast } from "../Toaster";
import moment from "moment";

const versionRegex = /^(?!0)\d+\.(?!0\d)\d+\.(?!0\d)\d+$/; // Matches only the X.Y.Z format
const specialCharRegex = /[^a-zA-Z0-9.]/; // Matches any character that is not a letter, digit, or dot
const versionCodeRegex = /^\d+$/; // Matches only whole numbers (one or more digits)

export default class BuildAndroid extends React.PureComponent {
    constructor(props) {
      super(props);
  
      this.state = {
        openDownloadPopup :false,
        subscription: localStorage.getItem("subscriptionactive"),
        urlvar: "",
        spinner: true,
        modalspinner: true,
        errormodal: false,
        title: "",
        errormessage: "",
        android_pushtoggle: true,
        google_enable: false,
        androidgoogle_enable: true,
        //Form Data
        submitval: "Build App",
        submitval2: "Save",
        submitval3: "Reset",
        allowBuildBtn: true,
        allowSaveBtn: true,
        allowResetBtn: true,
        loader2: "d-none",
        loaderimage2: require("../../assets/images/signup/Loader_GIF.gif")
          .default,
        loader3: "d-none",
        loaderimage3: require("../../assets/images/signup/Loader_GIF.gif")
          .default,
        disabledsubmitbutton: false,
        appid: "",
        userid: "",
        // sha_copy:"Copy",
        //android
        androidserverkey: "",
        androidpushnotificationfilename: "",
        androidpushcertificate: "",
        androidpushnotifications3name: "",
        androidsubmitval: "Build App",
        androidloader: "d-none",
        androidloaderimage: require("../../assets/images/signup/Loader_GIF.gif")
          .default,
        androiddisabledsubmitbutton: false,
  
        bundle_android_identifier: "",
        original_json_file: "",
        //android custom build filed
        jks_file: "",
        jks_filename: ".jks file supported",
        getjksfilenameAWS: "",
        keystore_alias: "",
        keystore_password: "",
        keystore_private_key_password: "",
        android_custompackage: 0,
        is_beta_release: true,
        //admob
        admob_app_id: "",
        is_admob_enabled: true,
        ios_admob_app_id: "",
        ios_is_admob_enabled: false,
        sha_spinner: false,
        spinner:true,
        in_process_sha: null,
        sha1: null,
        build_process: null,
        //permission
        is_enable_foreground_location_permission: false,
        is_enable_background_location_permission: false,
        is_enable_camera_permission: false,
        is_enable_photos_permission: false,
        is_enable_videos_permission: false,
        is_enable_microphone_permission: false,
        is_enable_contacts_permission: false,
        current_version_code:"",
        current_version_name:"",
        old_current_version_code:"",
        old_current_version_name:"",
        bundle_id:"",
        old_bundle_id:"",
        is_success: 0,
        is_notification_enabled :0,
        is_rich_notification_enabled :0,
        is_google_login_enabled : 0,
        is_admob_enabled :0,
        //Redirect to parent page
        app_data: null,
        chat_preferences: null,
        is_app_already_published: "",
        is_social_login_enabled: "",
        custompackage:"",
        saveclass:"d-none",
        rebuildclass:"d-none",
        website_tech: parseInt(localStorage.getItem("website_technology")),
        androiddata:'',
        notification_config_file:'',
        appRightPanelExpand: localStorage.getItem("appSideBarCollapse") === true ||
              localStorage.getItem("appSideBarCollapse") === "true"
                ? true
                : false,
          androidsenderid: "",
        updatedAt:null
        }
    }

    componentDidMount() 
    {
        window.scrollTo(0, 0);

        if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
        {
          document.title = "Android build settings | AppMySite";

        }
        else
        {
          document.title = "Android build settings |"+ ' ' +localStorage.getItem('agencyname');
        }
    
        const androidparams = {
          type: "android",
          for_sha: false,
        };
        this.getandroidformdatas();
    }
      
    componentWillUnmount() {
        clearInterval(this.interval);
      }

    handleAttributePopUp = () =>{
        this.setState({openDownloadPopup:!this.state.openDownloadPopup});
    }
    

    getandroidformdatas = () => {
        const androidparams = {
          type: "android",
        };
        newgetFormData(androidparams, this);
      };

    handleRightPanelExpand = (value) => {

        this.setState({appRightPanelExpand:value});
    }

    handlepermission = (e) => {
        const { name, checked } = e.target;
        this.setState({
          [name]: checked,
        });
      };

    finalbuild = () => {
        const buildparams = {
          app_id: localStorage.getItem("Appid"),
          platform: "android",
          is_beta_release: this.state.is_beta_release === true ? 1 : 0,
        };
        BuildappApi(buildparams, this);
      };
      handleLiveAppToggle = (e) => 
      {
        this.setState({
          is_app_already_published: e.target.checked === true ? true : false,
          custompackage:e.target.checked === true ? true : false,
        }, () => {
            this.afterSetStateFinished();
        });
      };

      handlecustompackage = (e) =>
      {
        if(!this.state.is_app_already_published)
        {
            this.setState({
                custompackage: e.target.checked === true ? true : false,
                is_app_already_published:false,
              }, () => {
                this.afterSetStateFinished();
            });
        }

      }

      afterSetStateFinished = () =>
      {
        this.setState({
            bundle_id: 
                       this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.androiddata.already_have_live_files ?
                       this.state.androiddata.already_have_live_files.bundle_id
                    :  this.state.custompackage == 1 && this.state.is_app_already_published == 0 && this.state.androiddata.custom_bundle_files ?
                       this.state.androiddata.custom_bundle_files.bundle_id
                    :  this.state.is_app_already_published == 0 && this.state.custompackage == 0 && this.state.androiddata.default_bundle_files ?  
                       this.state.androiddata.default_bundle_files.bundle_id
                    :  this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files != null 
                    ?  this.state.androiddata.already_have_live_files.bundle_id
                    : ''
                    ,
            current_version_code: 
                       this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.androiddata.already_have_live_files ?
                       this.state.androiddata.already_have_live_files.version
                    :  this.state.custompackage == 1 && this.state.is_app_already_published == 0 && this.state.androiddata.custom_bundle_files?
                       this.state.androiddata.custom_bundle_files.version
                    :  this.state.is_app_already_published == 0 && this.state.custompackage == 0 && this.state.androiddata.default_bundle_files
                    ?  this.state.androiddata.default_bundle_files.version
                    :  this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files != null 
                    ?  this.state.androiddata.already_have_live_files.version
                    :  ''
                    ,                    
            current_version_name:  
                  this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.androiddata.already_have_live_files ?
                  this.state.androiddata.already_have_live_files.current_version
                : this.state.custompackage== 1 && this.state.is_app_already_published == 0  && this.state.androiddata.custom_bundle_files?
                  this.state.androiddata.custom_bundle_files.current_version
                : this.state.custompackage== 0 && this.state.is_app_already_published == 0  && this.state.androiddata.default_bundle_files?
                  this.state.androiddata.default_bundle_files.current_version
                :  this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files != null
                ? this.state.androiddata.already_have_live_files.current_version
                : ''
                ,
            keystore_alias:
                  this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.androiddata.already_have_live_files ?
                  this.state.androiddata.already_have_live_files.keystore_alias_name
                :  this.state.custompackage== 1 && this.state.is_app_already_published == 0  && this.state.androiddata.custom_bundle_files?
                  this.state.androiddata.custom_bundle_files.keystore_alias_name
                : this.state.custompackage== 0 && this.state.is_app_already_published == 0  && this.state.androiddata.default_bundle_files?
                  this.state.androiddata.default_bundle_files.keystore_alias_name
                : this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files != null
                ? this.state.androiddata.already_have_live_files.keystore_alias_name
                : ''
                ,
            keystore_password: 
                  this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.androiddata.already_have_live_files ?
                  this.state.androiddata.already_have_live_files.keystore_password
                :  this.state.custompackage== 1 && this.state.is_app_already_published == 0  && this.state.androiddata.custom_bundle_files?
                  this.state.androiddata.custom_bundle_files.keystore_password
                : this.state.custompackage== 0 && this.state.is_app_already_published == 0  && this.state.androiddata.default_bundle_files?
                  this.state.androiddata.default_bundle_files.keystore_password
                : this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files != null
                ? this.state.androiddata.already_have_live_files.keystore_password
                : ''
                ,
            keystore_private_key_password:
            this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.androiddata.already_have_live_files ?
                  this.state.androiddata.already_have_live_files.keystore_private_key_password
                :  this.state.custompackage== 1 && this.state.is_app_already_published == 0  && this.state.androiddata.custom_bundle_files?
                  this.state.androiddata.custom_bundle_files.keystore_private_key_password
                : this.state.custompackage== 0 && this.state.is_app_already_published == 0  && this.state.androiddata.default_bundle_files?
                  this.state.androiddata.default_bundle_files.keystore_private_key_password
                : this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files != null
                ? this.state.androiddata.already_have_live_files.keystore_private_key_password
                : ''
                ,
            jks_filename:
                this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.androiddata.already_have_live_files ?
                  this.state.androiddata.already_have_live_files.jks_file
                :  this.state.custompackage== 1 && this.state.is_app_already_published == 0  && this.state.androiddata.custom_bundle_files?
                  this.state.androiddata.custom_bundle_files.jks_file
                : this.state.custompackage== 0 && this.state.is_app_already_published == 0  && this.state.androiddata.default_bundle_files?
                  this.state.androiddata.default_bundle_files.jks_file
                : this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files != null
                ? this.state.androiddata.already_have_live_files.jks_file
                : ''
                ,
            getjksfilenameAWS:
                this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.androiddata.already_have_live_files ?
                  this.state.androiddata.already_have_live_files.jks_file
                :  this.state.custompackage== 1 && this.state.is_app_already_published == 0  && this.state.androiddata.custom_bundle_files?
                  this.state.androiddata.custom_bundle_files.jks_file
                : this.state.custompackage== 0 && this.state.is_app_already_published == 0  && this.state.androiddata.default_bundle_files?
                  this.state.androiddata.default_bundle_files.jks_file
                : this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files != null
                ? this.state.androiddata.already_have_live_files.jks_file
                : ''
                ,
            is_enable_foreground_location_permission:
                this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.androiddata.already_have_live_files ?
                this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files.permissions ? JSON.parse(this.state.androiddata.already_have_live_files.permissions).is_enable_foreground_location_permission : 0
                :  this.state.custompackage== 1 && this.state.is_app_already_published == 0  && this.state.androiddata.custom_bundle_files ?
                  this.state.androiddata.custom_bundle_files && this.state.androiddata.custom_bundle_files.permissions ? JSON.parse(this.state.androiddata.custom_bundle_files.permissions).is_enable_foreground_location_permission : 0
                :  this.state.custompackage== 0 && this.state.is_app_already_published == 0  && this.state.androiddata.default_bundle_files ?
                  this.state.androiddata.default_bundle_files && this.state.androiddata.default_bundle_files.permissions ? JSON.parse(this.state.androiddata.default_bundle_files.permissions).is_enable_foreground_location_permission : 0
                : this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files.permissions ? JSON.parse(this.state.androiddata.already_have_live_files.permissions).is_enable_foreground_location_permission : 0
                ,
            
            is_enable_background_location_permission :
                this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.androiddata.already_have_live_files ?
                this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files.permissions ? JSON.parse(this.state.androiddata.already_have_live_files.permissions).is_enable_background_location_permission : 0
                :  this.state.custompackage== 1 && this.state.is_app_already_published == 0  && this.state.androiddata.custom_bundle_files ?
                  this.state.androiddata.custom_bundle_files && this.state.androiddata.custom_bundle_files.permissions ? JSON.parse(this.state.androiddata.custom_bundle_files.permissions).is_enable_background_location_permission : 0
                :  this.state.custompackage== 0 && this.state.is_app_already_published == 0  && this.state.androiddata.default_bundle_files ?
                  this.state.androiddata.default_bundle_files && this.state.androiddata.default_bundle_files.permissions ? JSON.parse(this.state.androiddata.default_bundle_files.permissions).is_enable_background_location_permission : 0
                : this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files.permissions ? JSON.parse(this.state.androiddata.already_have_live_files.permissions).is_enable_background_location_permission : 0
                ,
            is_enable_camera_permission :
                this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.androiddata.already_have_live_files ?
                this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files.permissions ? JSON.parse(this.state.androiddata.already_have_live_files.permissions).is_enable_camera_permission : 0
                :  this.state.custompackage== 1 && this.state.is_app_already_published == 0  && this.state.androiddata.custom_bundle_files ?
                  this.state.androiddata.custom_bundle_files && this.state.androiddata.custom_bundle_files.permissions ? JSON.parse(this.state.androiddata.custom_bundle_files.permissions).is_enable_camera_permission : 0
                :  this.state.custompackage== 0 && this.state.is_app_already_published == 0  && this.state.androiddata.default_bundle_files ?
                  this.state.androiddata.default_bundle_files && this.state.androiddata.default_bundle_files.permissions ? JSON.parse(this.state.androiddata.default_bundle_files.permissions).is_enable_camera_permission : 0
                : this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files.permissions ? JSON.parse(this.state.androiddata.already_have_live_files.permissions).is_enable_camera_permission : 0
                ,
            is_enable_photos_permission :
                this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.androiddata.already_have_live_files ?
                this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files.permissions ? JSON.parse(this.state.androiddata.already_have_live_files.permissions).is_enable_photos_permission : 0
                :  this.state.custompackage== 1 && this.state.is_app_already_published == 0  && this.state.androiddata.custom_bundle_files ?
                  this.state.androiddata.custom_bundle_files && this.state.androiddata.custom_bundle_files.permissions ? JSON.parse(this.state.androiddata.custom_bundle_files.permissions).is_enable_photos_permission : 0
                :  this.state.custompackage== 0 && this.state.is_app_already_published == 0  && this.state.androiddata.default_bundle_files ?
                  this.state.androiddata.default_bundle_files && this.state.androiddata.default_bundle_files.permissions ? JSON.parse(this.state.androiddata.default_bundle_files.permissions).is_enable_photos_permission : 0
                : this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files.permissions ? JSON.parse(this.state.androiddata.already_have_live_files.permissions).is_enable_photos_permission : 0
                ,
            is_enable_videos_permission :
                this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.androiddata.already_have_live_files ?
                this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files.permissions ? JSON.parse(this.state.androiddata.already_have_live_files.permissions).is_enable_videos_permission : 0
                :  this.state.custompackage== 1 && this.state.is_app_already_published == 0  && this.state.androiddata.custom_bundle_files ?
                  this.state.androiddata.custom_bundle_files && this.state.androiddata.custom_bundle_files.permissions ? JSON.parse(this.state.androiddata.custom_bundle_files.permissions).is_enable_videos_permission : 0
                :  this.state.custompackage== 0 && this.state.is_app_already_published == 0  && this.state.androiddata.default_bundle_files ?
                  this.state.androiddata.default_bundle_files && this.state.androiddata.default_bundle_files.permissions ? JSON.parse(this.state.androiddata.default_bundle_files.permissions).is_enable_videos_permission : 0
                : this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files.permissions ? JSON.parse(this.state.androiddata.already_have_live_files.permissions).is_enable_videos_permission : 0
                ,
            is_enable_microphone_permission :
                this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.androiddata.already_have_live_files ?
                this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files.permissions ? JSON.parse(this.state.androiddata.already_have_live_files.permissions).is_enable_microphone_permission : 0
                :  this.state.custompackage== 1 && this.state.is_app_already_published == 0  && this.state.androiddata.custom_bundle_files ?
                  this.state.androiddata.custom_bundle_files && this.state.androiddata.custom_bundle_files.permissions ? JSON.parse(this.state.androiddata.custom_bundle_files.permissions).is_enable_microphone_permission : 0
                : this.state.custompackage== 0 && this.state.is_app_already_published == 0  && this.state.androiddata.default_bundle_files ?
                  this.state.androiddata.default_bundle_files && this.state.androiddata.default_bundle_files.permissions ? JSON.parse(this.state.androiddata.default_bundle_files.permissions).is_enable_microphone_permission : 0
                : this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files.permissions ? JSON.parse(this.state.androiddata.already_have_live_files.permissions).is_enable_microphone_permission : 0
                ,
            is_enable_contacts_permission :
                this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.androiddata.already_have_live_files ?
                this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files.permissions ? JSON.parse(this.state.androiddata.already_have_live_files.permissions).is_enable_contacts_permission : 0
                :  this.state.custompackage== 1 && this.state.is_app_already_published == 0  && this.state.androiddata.custom_bundle_files ?
                  this.state.androiddata.custom_bundle_files && this.state.androiddata.custom_bundle_files.permissions ? JSON.parse(this.state.androiddata.custom_bundle_files.permissions).is_enable_contacts_permission : 0
                :  this.state.custompackage== 0 && this.state.is_app_already_published == 0  && this.state.androiddata.default_bundle_files ?
                  this.state.androiddata.default_bundle_files && this.state.androiddata.default_bundle_files.permissions ? JSON.parse(this.state.androiddata.default_bundle_files.permissions).is_enable_contacts_permission : 0
                : this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files.permissions ? JSON.parse(this.state.androiddata.already_have_live_files.permissions).is_enable_contacts_permission : 0
                ,
            is_notification_enabled :
                this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.androiddata.already_have_live_files ?
                this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files.permissions ? JSON.parse(this.state.androiddata.already_have_live_files.permissions).is_notification_enabled : 0
                :  this.state.custompackage== 1 && this.state.is_app_already_published == 0  && this.state.androiddata.custom_bundle_files ?
                  this.state.androiddata.custom_bundle_files && this.state.androiddata.custom_bundle_files.permissions ? JSON.parse(this.state.androiddata.custom_bundle_files.permissions).is_notification_enabled : 0
                :  this.state.custompackage== 0 && this.state.is_app_already_published == 0  && this.state.androiddata.default_bundle_files ?
                  this.state.androiddata.default_bundle_files && this.state.androiddata.default_bundle_files.permissions ? JSON.parse(this.state.androiddata.default_bundle_files.permissions).is_notification_enabled : 0
                : this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files.permissions ? JSON.parse(this.state.androiddata.already_have_live_files.permissions).is_notification_enabled : 0
                ,
              is_rich_notification_enabled :
                this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.androiddata.already_have_live_files ?
                this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files.permissions ? JSON.parse(this.state.androiddata.already_have_live_files.permissions).is_rich_notification_enabled: 0
                :  this.state.custompackage== 1 && this.state.is_app_already_published == 0  && this.state.androiddata.custom_bundle_files ?
                  this.state.androiddata.custom_bundle_files && this.state.androiddata.custom_bundle_files.permissions ? JSON.parse(this.state.androiddata.custom_bundle_files.permissions).is_rich_notification_enabled: 0
                :  this.state.custompackage== 0 && this.state.is_app_already_published == 0  && this.state.androiddata.default_bundle_files ?
                  this.state.androiddata.default_bundle_files && this.state.androiddata.default_bundle_files.permissions ? JSON.parse(this.state.androiddata.default_bundle_files.permissions).is_rich_notification_enabled : 0
                : this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files.permissions ? JSON.parse(this.state.androiddata.already_have_live_files.permissions).is_rich_notification_enabled : 0
                ,
            is_admob_enabled :
                this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.androiddata.already_have_live_files ?
                this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files.permissions ? JSON.parse(this.state.androiddata.already_have_live_files.permissions).is_admob_enabled : 0
                :  this.state.custompackage== 1 && this.state.is_app_already_published == 0  && this.state.androiddata.custom_bundle_files ?
                  this.state.androiddata.custom_bundle_files && this.state.androiddata.custom_bundle_files.permissions ? JSON.parse(this.state.androiddata.custom_bundle_files.permissions).is_admob_enabled : 0
                :  this.state.custompackage== 0 && this.state.is_app_already_published == 0  && this.state.androiddata.default_bundle_files ?
                  this.state.androiddata.default_bundle_files && this.state.androiddata.default_bundle_files.permissions ? JSON.parse(this.state.androiddata.default_bundle_files.permissions).is_admob_enabled : 0
                : this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files.permissions ? JSON.parse(this.state.androiddata.already_have_live_files.permissions).is_admob_enabled : 0
                ,
            is_google_login_enabled :
                this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.androiddata.already_have_live_files ?
                this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files.permissions ? JSON.parse(this.state.androiddata.already_have_live_files.permissions).is_google_login_enabled : 0
                :  this.state.custompackage== 1 && this.state.is_app_already_published == 0  && this.state.androiddata.custom_bundle_files ?
                  this.state.androiddata.custom_bundle_files && this.state.androiddata.custom_bundle_files.permissions ? JSON.parse(this.state.androiddata.custom_bundle_files.permissions).is_google_login_enabled : 0
                :  this.state.custompackage== 0 && this.state.is_app_already_published == 0  && this.state.androiddata.default_bundle_files ?
                  this.state.androiddata.default_bundle_files && this.state.androiddata.default_bundle_files.permissions ? JSON.parse(this.state.androiddata.default_bundle_files.permissions).is_google_login_enabled : 0
                : this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files.permissions ? JSON.parse(this.state.androiddata.already_have_live_files.permissions).is_google_login_enabled : 0
                ,
            notification_config_file:
                this.state.is_app_already_published == 1 && this.state.custompackage == 1 && this.state.androiddata.already_have_live_files ?
                  this.state.androiddata.already_have_live_files.notification_config_file
                :  this.state.custompackage== 1 && this.state.is_app_already_published == 0  && this.state.androiddata.custom_bundle_files?
                  this.state.androiddata.custom_bundle_files.notification_config_file
                : this.state.custompackage== 0 && this.state.is_app_already_published == 0  && this.state.androiddata.default_bundle_files?
                  this.state.androiddata.default_bundle_files.notification_config_file
                : this.state.androiddata.already_have_live_files && this.state.androiddata.already_have_live_files != null
                ? this.state.androiddata.already_have_live_files.notification_config_file
                : ''
                ,
          });
      }

      handleselection = (id) => {
        var copyText = document.getElementById(id);
        var textArea = document.createElement("textarea");
        textArea.value = copyText.textContent;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("Copy");
        textArea.remove();
      };

    savedata = () => {
      const versionVal = this.state.current_version_name.trim(); // Version name
      const versionCode = this.state.current_version_code.trim(); // Version code
      
      if (versionVal && specialCharRegex.test(versionVal)) {
        errortoast('Special characters are not allowed in the version name');
        return;
      }
      
      if (versionVal && !versionRegex.test(versionVal)) {
        errortoast('Invalid version format. Ensure it follows X.Y.Z, without leading zeros');
        return;
      }

      if (versionCode && !versionCodeRegex.test(versionCode)) {
        errortoast('Version code must be a whole number and cannot contain special characters or spaces');
        return;
      }

        this.setState({saveclass:"new-blue-loader",buttonsaveclass:"d-none"});
        const buildparams = {
          is_draft:1,
          app_id: parseInt(localStorage.getItem("Appid")),
          status: this.state.custompackage ==  1 || this.state.custompackage ==  true ? 1 : 0,
          bundle_id: this.state.bundle_id ? this.state.bundle_id : '',
          current_version_code: this.state.current_version_code ? parseInt(this.state.current_version_code) : '',
          current_version_name:this.state.current_version_name ? this.state.current_version_name : '',
          is_app_already_published: this.state.is_app_already_published ==  true ? 1 : 0,
          instant_generate_signing_files: this.state.instant_generate_signing_files,
          is_enable_foreground_location_permission: this.state.is_enable_foreground_location_permission == true ? 1 : 0,
          is_enable_background_location_permission: this.state.is_enable_background_location_permission == true ? 1 : 0,
          is_enable_camera_permission: this.state.is_enable_camera_permission == true ? 1 : 0,
          is_enable_photos_permission: this.state.is_enable_photos_permission == true ? 1 : 0,
          is_enable_videos_permission: this.state.is_enable_videos_permission == true ? 1 : 0 ,
          is_enable_microphone_permission: this.state.is_enable_microphone_permission == true ? 1 : 0,
          is_enable_contacts_permission: this.state.is_enable_contacts_permission == true ? 1 : 0,
          jks_file: this.state.getjksfilenameAWS ? this.state.getjksfilenameAWS : '',
          keystore_alias: this.state.keystore_alias ? this.state.keystore_alias : '',
          keystore_password: this.state.keystore_password ? this.state.keystore_password : '',
          keystore_private_key_password: this.state.keystore_private_key_password ? this.state.keystore_private_key_password : '',
          instant_generate_signing_files:0,
          // is_admob_enabled:this.state.is_admob_enabled ==  true || this.state.is_admob_enabled == 1 ? 1 : 0,
          // admob_app_id:this.state.admob_app_id
        };
        andoridsave(buildparams, this);

    };

    handleadmob_app_id = (e) => {
      this.setState({
        admob_app_id: e.target.value,
      });
    };

    handlecheck = (e) => {
        const { name, value } = e.target;
        this.setState({
          [name]: value,
        });
      };

      handleversionnamecheck = (e) => {
        const value = e.target.value;
        this.setState({ current_version_name: value });
      };

      handleversioncodecheck = (e) => {
        const value = e.target.value;
        this.setState({ current_version_code: value});
      };

      handlejks_file = (e) => {
        let self = this;
        let reader = new FileReader();
        let file = e.target.files[0];
        
        if (file !== undefined) 
        {
          if (file.name.indexOf("jks") !== -1) 
          {
            this.setState({
              jks_filename: file.name,
            });
            reader.onload = function (upload) 
            {
              self.setState(
                {
                  jks_file: upload.target.result,
                },
                () => {
                  self.updatetoaws();
                }
              );
            };
            reader.readAsDataURL(file);
          } 
          else 
          {
            errortoast("File format not supported. Upload jks file only.");
          }
        }
      };

    closePopup = () =>
    {
        this.setState({errormodal:false});
    }

    updatetoaws = () => {
        const awsparams = {
          original_path:
            localStorage.getItem("user_id") +
            "/" +
            localStorage.getItem("Appid") +
            "/apk/signing_files/",
          original: this.state.jks_file,
          imgname: this.state.jks_filename,
        };
        jksfile(awsparams, this);
      };
    
    
      finalbuild = () => {
        const buildparams = {
          app_id: localStorage.getItem("Appid"),
          platform: "android",
          is_beta_release: this.state.is_beta_release === true ? 1 : 0,
        };
        BuildappApi(buildparams, this);
      };

      // only for custom build end
  androidsubmit = () => 
  {

    let pattern = /^([a-zA-Z][a-z0-9A-Z_]+)+((\.)([a-zA-Z][a-z0-9A-Z_]+))+$/i;
    let result = pattern.test(this.state.bundle_id);

    let result1 = versionRegex.test(this.state.current_version_name);

    if(
        ((this.state.is_app_already_published === true || this.state.is_app_already_published === 1) 
        || (this.state.custompackage ==  1 || this.state.custompackage ==  true)) 
        && (this.state.bundle_id == '' || this.state.bundle_id == null || this.state.bundle_id == undefined)
      )
    {

        errortoast("Package name is required");

        return;
    }

    if(((this.state.is_app_already_published === true || this.state.is_app_already_published === 1) 
    || (this.state.custompackage ==  1 || this.state.custompackage ==  true)) && !result)
    {

        errortoast("Enter a valid package name.");

        return;
    }

    if(
      ( (this.state.is_app_already_published === true || this.state.is_app_already_published === 1) 
      ||(this.state.custompackage ==  1 || this.state.custompackage ==  true)) 
      && (this.state.current_version_name == '' || this.state.current_version_name == null || this.state.current_version_name == undefined)
   )
    {

          errortoast("Version name is required");

          return;
    }

    if(((this.state.is_app_already_published === true || this.state.is_app_already_published === 1) 
    || (this.state.custompackage ==  1 || this.state.custompackage ==  true)) && !result1)
    {
      const versionVal = this.state.current_version_name.trim(); // Version name
      
      if (specialCharRegex.test(versionVal)) {
        errortoast('Special characters are not allowed in the version name');
        return;
      }
      
      if (!versionRegex.test(versionVal)) {
        errortoast('Invalid version format. Ensure it follows X.Y.Z, without leading zeros');
        return;
      }
    }
    
    

    if(
        ((this.state.is_app_already_published === true || this.state.is_app_already_published === 1) 
        || (this.state.custompackage ==  1 || this.state.custompackage ==  true)) 
        && (this.state.current_version_code == '' || this.state.current_version_code == null || this.state.current_version_code == undefined || this.state.current_version_code.trim() === '')
     )
    {

          errortoast("Version code is required");

          return;
    }

   if (
    (
        (this.state.is_app_already_published || this.state.custompackage) &&
        (!this.state.current_version_code || isNaN(this.state.current_version_code) || !Number.isInteger(Number(this.state.current_version_code)))
    )
    ) 
    {
      errortoast('Version code must be a whole number and cannot contain special characters or spaces');
      return;
    }


   
        if ((this.state.is_app_already_published === true || this.state.is_app_already_published === 1) && this.state.keystore_alias.trim() === "") 
        {

            errortoast("Keystore alias is required");

            return;
        } 
          else if (
            (this.state.is_app_already_published === true || this.state.is_app_already_published === 1) &&
            (this.state.keystore_private_key_password === "" || this.state.keystore_private_key_password.trim() === "" )
          ) 
          {
            errortoast("Keystore password is required");
            return;
          } 
          else if (
            (this.state.is_app_already_published === true || this.state.is_app_already_published === 1) &&
            ( this.state.keystore_password === "" || this.state.keystore_password.trim() === "" )
          ) {

            errortoast("Keystore private key password is required");
            return
          } else if (
            (this.state.is_app_already_published === true || this.state.is_app_already_published === 1 ) &&
            this.state.getjksfilenameAWS === ""
          ) {
            errortoast("Keystore file is required");
            return;
          }
            else 
            {
                this.setState({
                    rebuildclass:"new-loader",
                    buttonbuildclass:"d-none",
                })
            this.androidUploadcertificatetoS3("build");
            }
  };

  androidUploadcertificatetoS3 = (scenerio) => 
  {
    const androiddata = {
        is_draft:0,
        app_id: parseInt(localStorage.getItem("Appid")),
        status: this.state.custompackage ==  1 || this.state.custompackage ==  true ? 1 : 0,
        bundle_id: this.state.bundle_id ? this.state.bundle_id : '',
        current_version_code: this.state.current_version_code ? parseInt(this.state.current_version_code) : '',
        current_version_name:this.state.current_version_name ? this.state.current_version_name : '',
        is_app_already_published: this.state.is_app_already_published ==  true ? 1 : 0,
        instant_generate_signing_files: this.state.instant_generate_signing_files,
        is_enable_foreground_location_permission: this.state.is_enable_foreground_location_permission == true ? 1 : 0,
        is_enable_background_location_permission: this.state.is_enable_background_location_permission == true ? 1 : 0,
        is_enable_camera_permission: this.state.is_enable_camera_permission == true ? 1 : 0,
        is_enable_photos_permission: this.state.is_enable_photos_permission == true ? 1 : 0,
        is_enable_videos_permission: this.state.is_enable_videos_permission == true ? 1 : 0 ,
        is_enable_microphone_permission: this.state.is_enable_microphone_permission == true ? 1 : 0,
        is_enable_contacts_permission: this.state.is_enable_contacts_permission == true ? 1 : 0,
        jks_file: this.state.getjksfilenameAWS ? this.state.getjksfilenameAWS : '',
        keystore_alias: this.state.keystore_alias ? this.state.keystore_alias : '',
        keystore_password: this.state.keystore_password ? this.state.keystore_password : '',
        keystore_private_key_password: this.state.keystore_private_key_password ? this.state.keystore_private_key_password : '',
        instant_generate_signing_files:0,
        // is_admob_enabled:this.state.is_admob_enabled ==  true || this.state.is_admob_enabled == 1 ? 1 : 0,
        // admob_app_id:this.state.admob_app_id
    };
    if(scenerio == "build")
    {
      if(this.state.is_success > 0 && ((this.state.old_current_version_code !=  this.state.current_version_code) || 
         (this.state.old_current_version_name !=  this.state.current_version_name) ||
         (this.state.old_bundle_id !=  this.state.bundle_id))  
      )
      {
        androiddata.is_reset = 1;
      }
    }

      androidbuilingapp(androiddata, this,scenerio);
  };

  handleReset = (e) => {
    this.setState({
      resetclass: "new-loader",
      buttonresetclass: "d-none",
    });
    const param = {
      app_id: parseInt(localStorage.getItem("Appid")),
      type: "android",
    };
    iosAndroidBuildReset(param, this);
  };

  copy = (e, id) => 
  {
    
    const el = document.createElement("input");
    
    el.value = id;
    
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    sucesstoast("Copied!");
    e.stopPropagation();
  };

  //admob handling
  handleis_admob_enabled = (e) => {
    this.setState({
      is_admob_enabled: e.target.checked,
    });
  };

  
  removefile = () => 
  {

    this.setState({jks_filename:'',getjksfilenameAWS:''});

  };
    

render() {

  return (
    <>
       
        <div>
        <Header customclass={"header-responsive-new"} />
        </div>

        <section className="dasboard_page mobile-display mt-4 mt-md-0 cms-module">
      <Leftpanel handleRightPanelExpand={this.handleRightPanelExpand} />
      <div className={`right-panel-gap ${(localStorage.getItem("appSideBarCollapse") !== undefined &&
                      localStorage.getItem("appSideBarCollapse") !== null&&
                      localStorage.getItem("appSideBarCollapse") === "true") ?
                          " right-panel-gap-expand": ""}`}>


        <div className="download-head build-configuration">
            <h1>Android build configuration
            </h1>
            {this.state.spinner ?
             <></>
            :
            <div className="build-configuration-head-right">
                {/* <button className="download-reset-btn" onClick={(e) => this.handleAttributePopUp(e)}> 
                Reset
                </button> */}
                {/* <p className="download-updated-date">Updated 30 May 2024, 16:51 </p> */}
                <p className="download-updated-date">{this.state.updatedAt   ? `Updated ${moment(this.state.updatedAt).format("D MMMM YYYY, HH:mm")}` : ''} </p>
                <button className="download-save-btn" onClick={()=>this.savedata()}
                 style={{
                    // backgroundColor:localStorage.getItem('button_background_color'),
                    border:'1px solid '+localStorage.getItem('button_background_color'),
                    color:localStorage.getItem('button_text_color'),
                    "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                    "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
                    "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                    }}
                ><div class="d-flex align-items-center justify-content-center h-100 w-100">
                    <div class={this.state.saveclass}>
                       
                    </div>
                    <div class={this.state.buttonsaveclass}   style={{
                       color:localStorage.getItem('button_background_color'),
                      }}>Save</div>
                </div>
                </button>
                <button className="download-build-app-btn" onClick={()=>this.androidsubmit()}
                 style={{
                    backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                  }}
                >
                <div class="d-flex align-items-center justify-content-center h-100 w-100">
                    <div class={this.state.rebuildclass}>
                       
                    </div>
                    <div class={this.state.buttonbuildclass}>Build app</div>
                </div>
                </button>
            </div>
            }
        </div>
        {this.state.spinner ?
                <div className="chat-main-topdiv" style={{ overflow: "hidden" }}>
                  <div className="newspinner">
                    <svg xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      style={{ margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s" }}
                      width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                      <g transform="translate(20 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <circle cx="0" cy="0" r="6" fill="#e6261f" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                          <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                            style={{ animationplaystate: "running", animationdelay: "0s" }}>
                          </animateTransform>
                        </circle>
                      </g>
                      <g transform="translate(40 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <circle cx="0" cy="0" r="6" fill="#f7d038" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                          <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                            style={{ animationplaystate: "running", animationdelay: "0s" }}>
                          </animateTransform>
                        </circle>
                      </g>
                      <g transform="translate(60 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <circle cx="0" cy="0" r="6" fill="#49da9a" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                          <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                            style={{ animationplaystate: "running", animationdelay: "0s" }}>

                          </animateTransform>
                        </circle>
                      </g>
                      <g transform="translate(80 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <circle cx="0" cy="0" r="6" fill="#4355db" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                          <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                            style={{ animationplaystate: "running", animationdelay: "0s" }}>

                          </animateTransform>
                        </circle>
                      </g>
                    </svg>
                  </div>
                </div>

                :
                <>
                    <div className="android-build-config">
                        <div className="android-build-conf-left">

                        <Accordion>

                            <Accordion.Item eventKey="0" className="android-config-main">
                            {(this.state.bundle_android_identifier != "") && 
                            (
                            <h4 className="android-id-web">Package name: <span>{
                                  this.state.custompackage && this.state.bundle_id
                                ? this.state.bundle_id
                                : this.state.bundle_android_identifier}</span> <svg onClick={
                                  this.state.custompackage && this.state.bundle_id
                                    ? (e)=>this.copy(e, this.state.bundle_id)
                                    : (e)=>this.copy(e, this.state.bundle_android_identifier)}  id="Group_41547" data-name="Group 41547" xmlns="http://www.w3.org/2000/svg" width="14.001" height="14" viewBox="0 0 14.001 14">
                                <g id="Path_9276" data-name="Path 9276" transform="translate(-450.5 -124.5)" fill="none" stroke-miterlimit="10">
                                    <path d="M461.5,125.923V128.5h-5.72a1.189,1.189,0,0,0-1.28,1.28v5.72h-2.577a1.419,1.419,0,0,1-1.423-1.423v-8.154a1.419,1.419,0,0,1,1.423-1.423h8.154A1.419,1.419,0,0,1,461.5,125.923Z" stroke="none"/>
                                    <path d="M 451.9228515625 125.5000152587891 C 451.6857299804688 125.5000152587891 451.5 125.6857452392578 451.5 125.9228668212891 L 451.5 134.0771636962891 C 451.5 134.3142700195312 451.6857299804688 134.5 451.9228515625 134.5 L 453.5 134.5 L 453.5 129.7800140380859 C 453.5 128.4375762939453 454.4375610351562 127.5 455.7799682617188 127.5 L 460.5 127.5 L 460.5 125.9228668212891 C 460.5 125.6857452392578 460.3142395019531 125.5000152587891 460.0771179199219 125.5000152587891 L 451.9228515625 125.5000152587891 M 451.9228515625 124.5000152587891 L 460.0771179199219 124.5000152587891 C 460.8656616210938 124.5000152587891 461.5 125.1343078613281 461.5 125.9228668212891 L 461.5 128.5 L 455.7799682617188 128.5 C 454.9913940429688 128.5 454.5 128.991455078125 454.5 129.7800140380859 L 454.5 135.5 L 451.9228515625 135.5 C 451.13427734375 135.5 450.5 134.8657531738281 450.5 134.0771636962891 L 450.5 125.9228668212891 C 450.5 125.1343078613281 451.13427734375 124.5000152587891 451.9228515625 124.5000152587891 Z" stroke="none" fill="#bcc1ce"/>
                                </g>
                                <g id="Path_9277" data-name="Path 9277" transform="translate(-452.499 -126.5)" fill="none" stroke-miterlimit="10">
                                    <path d="M466.5,130.923v8.154a1.419,1.419,0,0,1-1.423,1.423h-8.154a1.419,1.419,0,0,1-1.423-1.423v-8.154a1.419,1.419,0,0,1,1.423-1.423h8.154A1.419,1.419,0,0,1,466.5,130.923Z" stroke="none"/>
                                    <path d="M 456.9228210449219 130.5000152587891 C 456.6856994628906 130.5000152587891 456.4999694824219 130.6857452392578 456.4999694824219 130.9228515625 L 456.4999694824219 139.0771484375 C 456.4999694824219 139.3142700195312 456.6856994628906 139.5000152587891 456.9228210449219 139.5000152587891 L 465.0771179199219 139.5000152587891 C 465.3142395019531 139.5000152587891 465.4999694824219 139.3142700195312 465.4999694824219 139.0771484375 L 465.4999694824219 130.9228515625 C 465.4999694824219 130.6857452392578 465.3142395019531 130.5000152587891 465.0771179199219 130.5000152587891 L 456.9228210449219 130.5000152587891 M 456.9228210449219 129.5000152587891 L 465.0771179199219 129.5000152587891 C 465.8656921386719 129.5000152587891 466.4999694824219 130.1342926025391 466.4999694824219 130.9228515625 L 466.4999694824219 139.0771484375 C 466.4999694824219 139.86572265625 465.8656921386719 140.5000152587891 465.0771179199219 140.5000152587891 L 456.9228210449219 140.5000152587891 C 456.1342163085938 140.5000152587891 455.4999694824219 139.86572265625 455.4999694824219 139.0771484375 L 455.4999694824219 130.9228515625 C 455.4999694824219 130.1342926025391 456.1342163085938 129.5000152587891 456.9228210449219 129.5000152587891 Z" stroke="none" fill="#bcc1ce"/>
                                </g>
                                </svg>
                                </h4>
                            )}

                            <Accordion.Header>

                            <div className="android-config-toggle">

                            <h5><svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
                            <g id="Group_41895" data-name="Group 41895" transform="translate(-260 -170)">
                                <rect id="Rectangle_967" data-name="Rectangle 967" width="54" height="54" rx="3" transform="translate(260 170)" fill="#f8fafe"/>
                                <path id="Union_2846" data-name="Union 2846" d="M18980.488-14271.564v-4.685h-2.7v4.685a1.694,1.694,0,0,1-1.8,1.564,1.688,1.688,0,0,1-1.793-1.564v-4.685h-.012a2.687,2.687,0,0,1-2.7-2.676v-10.7h15.283v10.7a2.686,2.686,0,0,1-2.689,2.676v4.685a1.69,1.69,0,0,1-1.793,1.564A1.691,1.691,0,0,1,18980.488-14271.564Zm7.191-9.807v-6.689a1.69,1.69,0,0,1,1.8-1.562,1.693,1.693,0,0,1,1.8,1.563v6.689a1.693,1.693,0,0,1-1.8,1.563A1.693,1.693,0,0,1,18987.68-14281.371Zm-20.678,0v-6.689a1.693,1.693,0,0,1,1.795-1.562,1.691,1.691,0,0,1,1.8,1.563v6.689a1.691,1.691,0,0,1-1.8,1.563A1.691,1.691,0,0,1,18967-14281.371Zm4.463-9.064a7.482,7.482,0,0,1,3.908-6l-1.277-2.193a.268.268,0,0,1,.115-.33.267.267,0,0,1,.344.063l1.295,2.228a8.043,8.043,0,0,1,6.564,0l1.293-2.233a.256.256,0,0,1,.162-.094.263.263,0,0,1,.256.094.274.274,0,0,1,.043.27l-1.277,2.2a7.5,7.5,0,0,1,3.91,6Zm10.555-2.807a.64.64,0,0,0,.639.637.635.635,0,0,0,.639-.637.637.637,0,0,0-.641-.637A.64.64,0,0,0,18982.02-14293.242Zm-6.871-.449a.632.632,0,0,0-.137.691.645.645,0,0,0,.588.395.639.639,0,0,0,.615-.471.635.635,0,0,0-.3-.715.651.651,0,0,0-.32-.086A.629.629,0,0,0,18975.148-14293.691Z" transform="translate(-18692.002 14481.999)" fill="#00de7a"/>
                            </g>
                            </svg>App identity<span class="build-tooltip">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                    <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                    <circle cx="7" cy="7" r="7" stroke="none"/>
                                    <circle cx="7" cy="7" r="6.4" fill="none"/>
                                    </g>
                                    <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                    <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                </g>
                                </svg>
                                <span class="build-tooltipsubheader">Customize your app's identity. This step is optional. Enable only if you need to modify the key identifiers of your app.</span>
                                </span>
                                </h5>
                            

                            <div className="android-id-toggle">
                                
                                <svg className="thechatarrow" id="Group_39685" data-name="Group 39685" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                                <circle id="Ellipse_6953" data-name="Ellipse 6953" cx="11" cy="11" r="11" fill="none"/>
                                <path id="Path_81302" data-name="Path 81302" d="M-12935-18908l6-6,6,6" transform="translate(12940.501 18921.504)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
                                </svg>
                            </div>

                            </div>

                            </Accordion.Header>

                            <Accordion.Body>
                            
                            <div className="row" style={{marginBottom: "10px"}}>

                                <div className="col-md-6">

                                    <div class="android-setting-toggle" style={{marginTop: "0px"}}>
                                        <h6>Migrate my live app<span class="build-tooltip">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                    <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                    <circle cx="7" cy="7" r="7" stroke="none"/>
                                    <circle cx="7" cy="7" r="6.4" fill="none"/>
                                    </g>
                                    <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                    <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                </g>
                                </svg>
                                <span class="build-tooltipsubheader">Enable to migrate your existing live app. This helps retain your app users.</span>
                                </span></h6>
                                        <label class="switch" for="isliveapp">
                                            <input type="checkbox"  id="isliveapp"
                                                form="iOSform"
                                                checked={this.state.is_app_already_published}
                                                onClick={(e) => this.handleLiveAppToggle(e)}/>
                                            <div class="slider round"></div>
                                        </label>
                                    </div>


                                    <div class={this.state.is_app_already_published ? "android-setting-toggle download-toggle-lock" : "android-setting-toggle"}>
                                        <h6>Customize my package details<span class="build-tooltip">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                    <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                    <circle cx="7" cy="7" r="7" stroke="none"/>
                                    <circle cx="7" cy="7" r="6.4" fill="none"/>
                                    </g>
                                    <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                    <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                </g>
                                </svg>
                                <span class="build-tooltipsubheader">Enable to take control of your app's identity. This helps in retaining your existing app’s identifiers or personalizing them according to your preferences.</span>
                                </span></h6>
                                        <label class="switch" for="custompackage">
                                            <input type="checkbox" id="custompackage"  name="custompackage" 
                                                checked={this.state.custompackage || this.state.is_app_already_published}
                                                onClick={(e) => this.handlecustompackage(e)}
                                                readOnly={this.state.is_app_already_published}
                                            />
                                            <div class="slider round"></div>
                                        </label>
                                    </div>
                                </div>

                            </div>
                        
                        {this.state.is_app_already_published || this.state.custompackage ?
                            <>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div class="android-setting-input">
                                            <h5>Package name<span class="build-tooltip">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                    <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                        <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                        <circle cx="7" cy="7" r="7" stroke="none"/>
                                        <circle cx="7" cy="7" r="6.4" fill="none"/>
                                        </g>
                                        <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                        <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                    </g>
                                    </svg>
                                    <span class="build-tooltipsubheader">The package name (also referred to as the application ID) of an Android app serves as a unique identifier for your app on the device and within Google Play Store.</span>
                                    </span></h5>
                                            <input type="text"  
                                            // readOnly={this.state.is_success !== 0 ? true : false}  
                                            name="bundle_id"
                                            required
                                            value={this.state.bundle_id}
                                            onChange={(e) => this.handlecheck(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div class="android-setting-input">
                                            <h5>Version name<span class="build-tooltip-two">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                    <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                        <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                        <circle cx="7" cy="7" r="7" stroke="none"/>
                                        <circle cx="7" cy="7" r="6.4" fill="none"/>
                                        </g>
                                        <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                        <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                    </g>
                                    </svg>
                                    <span class="build-tooltipsubheader-two">The Android version name is a label assigned to identify a specific release version of your app. It plays a crucial role in differentiating releases and communicating updates to users within the Android ecosystem. If you’re migrating your app, the version number you input will be automatically incremented by 1.0.0 in the next immediate build.  If your current app version is 1.2.1, the next build would be version 2.0.0. The version name will be visible to your users.</span>
                                    </span></h5>
                                            <input type="text"  
                                            name="current_version_name"
                                            required
                                            value={this.state.current_version_name}
                                            onChange={(e) => this.handleversionnamecheck(e)}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div class="android-setting-input">
                                            <h5>Version code
                                            <span class="build-tooltip-two">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                    <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                                        <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                                        <circle cx="7" cy="7" r="7" stroke="none"/>
                                                        <circle cx="7" cy="7" r="6.4" fill="none"/>
                                                        </g>
                                                        <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                                        <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                                    </g>
                                                    </svg>
                                                <span class="build-tooltipsubheader-two">This whole number value represents the incremental build of your app.  If you’re migrating your app, the version code you input will be automatically incremented by 1 in the next immediate build.  If your current app version is 7, the next build would be version 8. The version code will not be visible to your users.</span>
                                            </span>
                                            </h5>
                                        <input type="text"  
                                        // readOnly={this.state.is_success !== 0 ? true : false}
                                        
                                        className="form-control form-name"
                                        name="current_version_code"
                                        pattern="[1-9]+"
                                        title="Version code should be number"
                                        required
                                        value={this.state.current_version_code}
                                        onChange={(e) => this.handleversioncodecheck(e)}/>
                                        </div>
                                    </div>
                                {this.state.is_app_already_published ?
                                    <div className="col-md-6">
                                        <div class="android-setting-input">
                                            <h5>Key Alias
                                            <span class="build-tooltip">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                    <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                                        <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                                        <circle cx="7" cy="7" r="7" stroke="none"/>
                                                        <circle cx="7" cy="7" r="6.4" fill="none"/>
                                                        </g>
                                                        <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                                        <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                                    </g>
                                                    </svg>
                                                <span class="build-tooltipsubheader">Specify the Alias for the Key associated with the app you are migrating. The Alias serves as an identifier for the Key. Obtain this information from the developer or development platform you are migrating from.</span>
                                            </span>
                                            </h5>
                                            <input type="text" required
                                                    name="keystore_alias"
                                                    value={this.state.keystore_alias}
                                                    onChange={(e) => this.handlecheck(e)}/>
                                        </div>
                                    </div>
                                : <></>
                                }
                                </div>

                                {this.state.is_app_already_published 
                                ?
                                    <>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div class="android-setting-input">
                                                    <h5>Key password
                                                    <span class="build-tooltip">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                    <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                                        <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                                        <circle cx="7" cy="7" r="7" stroke="none"/>
                                                        <circle cx="7" cy="7" r="6.4" fill="none"/>
                                                        </g>
                                                        <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                                        <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                                    </g>
                                                    </svg>
                                                <span class="build-tooltipsubheader">Specify the password for the Key associated with the app you are migrating. Obtain this information from the developer or development platform you are migrating from.</span>
                                            </span>
                                                    </h5>
                                                    <input type="password" required
                                                    name="keystore_private_key_password"
                                                    value={
                                                        this.state
                                                        .keystore_private_key_password
                                                    }
                                                    onChange={(e) => this.handlecheck(e)}
                                                    autoComplete="new-password"/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                            <div class="android-setting-input">
                                                    <h5>KeyStore file<span class="build-tooltip">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                            <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                                <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                                <circle cx="7" cy="7" r="7" stroke="none"/>
                                                <circle cx="7" cy="7" r="6.4" fill="none"/>
                                                </g>
                                                <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                                <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                            </g>
                                            </svg>
                                            <span class="build-tooltipsubheader">Upload the KeyStore file for the app you are migrating. This file will have a .jks extension. Obtain this information from the developer or development platform you are migrating from.</span>
                                            </span></h5>
                                                    <div class="download-image-uploader">
                                                        <input type="file" id="jks_file" onChange={(e) =>this.handlejks_file(e)} style={{ display: "none" }} accept="jks/file"/>
                                                        {this.state.jks_filename != '' && this.state.jks_filename && this.state.jks_filename.indexOf(".jks") != 0 ? 
                                                            <>
                                                            
                                                            <label htmlFor="jks_file">
                                                                <div class="d-flex align-items-center" >
                                                                <div class="download-bayimage" style={{backgroundImage : "url(" + imagebackground2 + ")"}}></div>
                                                                    <p style={{marginLeft: "15px"}} class="download-image-placeholder">{this.state.jks_filename}{" "}</p>
                                                                </div>
                                                            </label>
                                                            <svg width="11.06" height="11.06" viewBox="0 0 11.06 11.06" onClick={()=>this.removefile('')}>
                                                              <path id="Union_1696" data-name="Union 1696" d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z" transform="translate(0.53 0.53)" fill="none" stroke="#7782a1" stroke-width="1.5"></path></svg>
                                                            </>
                                                        :
                                                        <>
                                                            <label htmlFor="jks_file">
                                                                <div class="d-flex align-items-center" >
                                                                    <div class="download-bayimage" style={{backgroundImage : "url(" + imagebackground + ")"}}></div>
                                                                    <p style={{color: "#BCC1CE"}} class="download-image-placeholder">Upload</p>
                                                                </div>
                                                            </label>
                                                        </>  
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div class="android-setting-input">
                                                    <h5>KeyStore password
                                                    <span class="build-tooltip">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                    <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                                        <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                                        <circle cx="7" cy="7" r="7" stroke="none"/>
                                                        <circle cx="7" cy="7" r="6.4" fill="none"/>
                                                        </g>
                                                        <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                                        <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                                    </g>
                                                    </svg>
                                                <span class="build-tooltipsubheader">Specify the password for your KeyStore. Obtain this information from the developer or development platform you are migrating from.</span>
                                            </span>
                                                    </h5>
                                                    <input type="password" required
                                                    name="keystore_password"
                                                    value={this.state.keystore_password}
                                                    onChange={(e) => this.handlecheck(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                :   <></>
                                }
                            </>
                        : <></>
                        }
                                
                            </Accordion.Body>

                            </Accordion.Item>

                            <Accordion.Item eventKey="1" className="android-config-main">

                            <Accordion.Header>

                            <div className="android-config-toggle">

                            <h5><svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
                            <g id="Group_41895" data-name="Group 41895" transform="translate(-260 -385)">
                                <rect id="Rectangle_967" data-name="Rectangle 967" width="54" height="54" rx="3" transform="translate(260 385)" fill="#f8fafe"/>
                                <path id="_3668850_insurance_protection_safety_shield_icon" data-name="3668850_insurance_protection_safety_shield_icon" d="M25.778,5.067A39.162,39.162,0,0,1,15.879,3a37.279,37.279,0,0,1-9.9,2.067A1.157,1.157,0,0,0,5,6.155V18.23a8.867,8.867,0,0,0,4.787,7.833l5.548,2.828A.654.654,0,0,0,15.77,29c.218,0,.326,0,.435-.109l5.548-2.828A8.743,8.743,0,0,0,26.54,18.23V6.155C26.757,5.611,26.322,5.176,25.778,5.067ZM20.992,24.1l-5.113,2.611V16.054h-8.7V7.243a47.677,47.677,0,0,0,8.7-1.849V16.054h8.7v2.285A6.537,6.537,0,0,1,20.992,24.1Z" transform="translate(271 396)" fill="#6089ff"/>
                            </g>
                            </svg>App permissions<span class="build-tooltip">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                    <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                    <circle cx="7" cy="7" r="7" stroke="none"/>
                                    <circle cx="7" cy="7" r="6.4" fill="none"/>
                                    </g>
                                    <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                    <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                </g>
                                </svg>
                                <span class="build-tooltipsubheader">Enable or disable each permission based on your app's functionality, whether native or via the in-app browser. You may be required to provide an explanation detailing how each of these permissions is utilized when submitting your app to the Google Play Store.</span>
                                </span></h5>
                            

                            <div className="android-id-toggle">
                                
                                <svg className="thechatarrow" id="Group_39685" data-name="Group 39685" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                                <circle id="Ellipse_6953" data-name="Ellipse 6953" cx="11" cy="11" r="11" fill="none"/>
                                <path id="Path_81302" data-name="Path 81302" d="M-12935-18908l6-6,6,6" transform="translate(12940.501 18921.504)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
                                </svg>
                            </div>

                            </div>

                            </Accordion.Header>

                            <Accordion.Body>
                            
                            <div className="row">
                                <div className="col-md-6">
                                    <div class="android-setting-toggle build-gap-zero">
                                        <h6>Foreground location<span class="build-tooltip">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                    <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                    <circle cx="7" cy="7" r="7" stroke="none"/>
                                    <circle cx="7" cy="7" r="6.4" fill="none"/>
                                    </g>
                                    <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                    <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                </g>
                                </svg>
                                <span class="build-tooltipsubheader">This permission is crucial for apps that require location data while the app is running in the foreground.</span>
                                </span></h6>
                                        <label class="switch" for="is_enable_foreground_location_permission">
                                            <input type="checkbox" id="is_enable_foreground_location_permission" name="is_enable_foreground_location_permission"
                                                checked={this.state.is_enable_foreground_location_permission}
                                                onChange={(e) =>this.handlepermission(e)} />
                                            <div class="slider round"></div>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div class="android-setting-toggle build-gap-zero">
                                        <h6>Background location<span class="build-tooltip">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                    <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                    <circle cx="7" cy="7" r="7" stroke="none"/>
                                    <circle cx="7" cy="7" r="6.4" fill="none"/>
                                    </g>
                                    <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                    <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                </g>
                                </svg>
                                <span class="build-tooltipsubheader">This permission is crucial for apps that require location data while the app is running in the background.</span>
                                </span></h6>
                                        <label class="switch" for="is_enable_background_location_permission">
                                            <input type="checkbox" id="is_enable_background_location_permission" name="is_enable_background_location_permission"
                                                checked={this.state.is_enable_background_location_permission}
                                                onChange={(e) =>this.handlepermission(e)}/>
                                            <div class="slider round"></div>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div class="android-setting-toggle">
                                        <h6>Camera<span class="build-tooltip">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                    <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                    <circle cx="7" cy="7" r="7" stroke="none"/>
                                    <circle cx="7" cy="7" r="6.4" fill="none"/>
                                    </g>
                                    <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                    <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                </g>
                                </svg>
                                <span class="build-tooltipsubheader">This permission is crucial for apps that utilize camera functionality.</span>
                                </span></h6>
                                        <label class="switch" for="is_enable_camera_permission">
                                            <input type="checkbox" 
                                                disabled={this.state.chat_preferences !== null &&
                                                            this.state.chat_preferences.some((res) => res.module_status === 1)
                                                        }
                                                id="is_enable_camera_permission" name="is_enable_camera_permission"
                                                checked={this.state.is_enable_camera_permission}
                                                onChange={(e) =>this.handlepermission(e)}/>
                                            <div class="slider round"></div>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div class="android-setting-toggle">
                                        <h6>Photos<span class="build-tooltip">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                    <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                    <circle cx="7" cy="7" r="7" stroke="none"/>
                                    <circle cx="7" cy="7" r="6.4" fill="none"/>
                                    </g>
                                    <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                    <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                </g>
                                </svg>
                                <span class="build-tooltipsubheader">This permission is crucial for applications that require access to read or write to the photo gallery.</span>
                                </span></h6>
                                        <label class="switch" for="is_enable_photos_permission">
                                            <input type="checkbox" id="is_enable_photos_permission" name="is_enable_photos_permission"
                                                checked={this.state.is_enable_photos_permission}
                                                onChange={(e) =>this.handlepermission(e)}/>
                                            <div class="slider round"></div>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div class="android-setting-toggle">
                                        <h6>Videos<span class="build-tooltip">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                    <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                    <circle cx="7" cy="7" r="7" stroke="none"/>
                                    <circle cx="7" cy="7" r="6.4" fill="none"/>
                                    </g>
                                    <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                    <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                </g>
                                </svg>
                                <span class="build-tooltipsubheader">This permission is crucial for applications that require access to read or write to the video gallery.</span>
                                </span></h6>
                                        <label class="switch" for="is_enable_videos_permission">
                                            <input type="checkbox" id="is_enable_videos_permission" name="is_enable_videos_permission"
                                                checked={this.state.is_enable_videos_permission}
                                                onChange={(e) =>this.handlepermission(e)}/>
                                            <div class="slider round"></div>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div class="android-setting-toggle">
                                        <h6>Microphone<span class="build-tooltip">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                    <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                    <circle cx="7" cy="7" r="7" stroke="none"/>
                                    <circle cx="7" cy="7" r="6.4" fill="none"/>
                                    </g>
                                    <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                    <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                </g>
                                </svg>
                                <span class="build-tooltipsubheader">This permission is crucial for apps that involve audio input functionality.</span>
                                </span></h6>
                                        <label class="switch" for="is_enable_microphone_permission">
                                            <input type="checkbox"  id="is_enable_microphone_permission"
                                                    name="is_enable_microphone_permission"
                                                    checked={this.state.is_enable_microphone_permission}
                                                    onChange={(e) => this.handlepermission(e)}/>
                                            <div class="slider round"></div>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div class="android-setting-toggle">
                                        <h6>Contacts<span class="build-tooltip">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                    <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                    <circle cx="7" cy="7" r="7" stroke="none"/>
                                    <circle cx="7" cy="7" r="6.4" fill="none"/>
                                    </g>
                                    <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                    <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                </g>
                                </svg>
                                <span class="build-tooltipsubheader">This permission is crucial for apps that require access to the contacts on the device.</span>
                                </span></h6>
                                        <label class="switch" for="is_enable_contacts_permission">
                                            <input type="checkbox" id="is_enable_contacts_permission"
                                                name="is_enable_contacts_permission"
                                                checked={this.state.is_enable_contacts_permission}
                                                onChange={(e) =>this.handlepermission(e)}/>
                                            <div class="slider round"></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                                
                            </Accordion.Body>

                            </Accordion.Item>

                            {/*   <Accordion.Item eventKey="2" className="android-config-main">

                              <Accordion.Header>

                              <div className="android-config-toggle">

                              <h5><svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
  <g id="Group_42831" data-name="Group 42831" transform="translate(-336 -367)">
    <rect id="Rectangle_31978" data-name="Rectangle 31978" width="54" height="54" rx="3" transform="translate(336 367)" fill="#f8fafe"/>
    <g id="Group_41750" data-name="Group 41750" transform="translate(213.314 -1025)">
      <g id="Group_5389" data-name="Group 5389" transform="translate(137.686 1407)">
        <path id="Path_9215" data-name="Path 9215" d="M92,8.034C92.18,8,92.889,8,93.058,8a12.214,12.214,0,0,1,11.908,11.493v9.434A3.151,3.151,0,0,1,101.757,32a3.5,3.5,0,0,1-2.992-3.068V19.5a6.089,6.089,0,0,0-5.777-5.5,6.74,6.74,0,0,0-.988.014" transform="translate(-80.966 -7.998)" fill="#fbbc04"/>
        <path id="Path_9216" data-name="Path 9216" d="M146.2,143.1a3.1,3.1,0,1,1-3.1-3.1A3.1,3.1,0,0,1,146.2,143.1Z" transform="translate(-122.201 -122.201)" fill="#4285f4"/>
        <path id="Path_9217" data-name="Path 9217" d="M20.017,32.1a3.136,3.136,0,0,0,3.215-3.05,3.053,3.053,0,0,0-3.249-2.908h-.034a6.085,6.085,0,0,1-5.7-6.368l0-.057a6.124,6.124,0,0,1,5.764-5.508,3.029,3.029,0,0,0,3.215-2.916,3.138,3.138,0,0,0-3.216-3.051c-.072,0-.144,0-.216,0C14.416,8.42,8.073,12.754,8.01,20.12v.045c0,7.475,6.778,11.929,11.829,11.929h.178Z" transform="translate(-8.01 -8.203)" fill="#ea4335"/>
      </g>
    </g>
  </g>
</svg>
Admob<span class="build-tooltip">
                              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                  <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                      <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                      <circle cx="7" cy="7" r="7" stroke="none"/>
                                      <circle cx="7" cy="7" r="6.4" fill="none"/>
                                      </g>
                                      <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                      <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                  </g>
                                  </svg>
                                  <span class="build-tooltipsubheader"> Connect your app to your Google AdMob account to display ads in your Android app</span>
                                  </span></h5>


                              <div className="android-id-toggle">
                                  
                                  <svg className="thechatarrow" id="Group_39685" data-name="Group 39685" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                                  <circle id="Ellipse_6953" data-name="Ellipse 6953" cx="11" cy="11" r="11" fill="none"/>
                                  <path id="Path_81302" data-name="Path 81302" d="M-12935-18908l6-6,6,6" transform="translate(12940.501 18921.504)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
                                  </svg>
                              </div>

                              </div>

                              </Accordion.Header>

                                <Accordion.Body>

                                <div className="row" style={{marginBottom: "10px"}}>

                                  <div className="col-md-6">

                                  
                                    <div class="android-setting-toggle">
                                        <h6>Enable App Monetization<span class="build-tooltip">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                        <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                            <circle cx="7" cy="7" r="7" stroke="none"/>
                                            <circle cx="7" cy="7" r="6.4" fill="none"/>
                                            </g>
                                            <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                            <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                        </g>
                                        </svg>
                                <span class="build-tooltipsubheader">Turn on to enable app monetization</span>
                                </span></h6>
                                        <label class="switch" for="is_admob_enabled">
                                            <input type="checkbox" id="is_admob_enabled"  name="is_admob_enabled" 
                                                checked={this.state.is_admob_enabled}
                                                onClick={(e) => this.handleis_admob_enabled(e)}
                                            />
                                            <div class="slider round"></div>
                                        </label>
                                    </div>
                                </div>

                                </div>

                                      <div className="row">
                                          <div className="col-md-6">
                                              <div class="android-setting-input">
                                                  <h5>Admob App ID<span class="build-tooltip">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                              <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                              <circle cx="7" cy="7" r="7" stroke="none"/>
                                              <circle cx="7" cy="7" r="6.4" fill="none"/>
                                              </g>
                                              <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                              <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                          </g>
                                          </svg>
                                          <span class="build-tooltipsubheader">Enter your Google AdMob App ID here.</span>
                                          </span></h5>
                                              <input type="text"  
                                              name="admob_app_id"
                                              required
                                              value={this.state.admob_app_id}
                                              onChange={(e) => this.handleadmob_app_id(e)}
                                              />
                                              </div>
                                          </div>
                                         
                                      </div>
                                        
                                    </Accordion.Body>

                                                      </Accordion.Item> */}

                            </Accordion>

                        </div>

                        <div className="android-build-conf-right">
                            <h3>Features in this build</h3>

                            <div className="android-seven-steps">

                                <div className="android-steps">

                                <a href="/app/basic-notifications"></a>
                                <img src={require("../../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                <div>
                            {(this.state.is_notification_enabled == 1 && this.state.notification_config_file != '') || 
                             (this.state.is_notification_enabled == true && this.state.notification_config_file != '') ? 
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <g id="Group_41817" data-name="Group 41817" transform="translate(-1510 -164)">
                                    <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                    <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                </g>
                                </svg>
                             : (this.state.is_rich_notification_enabled == 1 && this.state.notification_config_file != '') || 
                               (this.state.is_rich_notification_enabled == true && this.state.notification_config_file != '') ? 
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <g id="Group_41817" data-name="Group 41817" transform="translate(-1510 -164)">
                                    <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                    <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                </g>
                                </svg>
                            :
                                <svg id="Group_41846" data-name="Group 41846" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" fill="#ff4848"/>
                                <path id="Path_101776" data-name="Path 101776" d="M-16063.67,2569.475l6.416-6.416" transform="translate(16070.461 -2556.267)" fill="none" stroke="#fff" stroke-width="2"/>
                                <path id="Path_118731" data-name="Path 118731" d="M-16057.254,2569.474l-6.416-6.415" transform="translate(16070.461 -2556.267)" fill="none" stroke="#fff" stroke-width="2"/>
                                </svg>
                            }


                                </div>
                                <div>
                                    <h4>Notifications</h4>
                                    {(this.state.is_notification_enabled == 1 && this.state.notification_config_file != '') || 
                                     (this.state.is_notification_enabled == true && this.state.notification_config_file != '') ?
                                      <p>Notifications are configured and enabled.</p>
                                    : (this.state.is_rich_notification_enabled == 1 && this.state.notification_config_file != '') || 
                                      (this.state.is_rich_notification_enabled == true && this.state.notification_config_file != '') ?
                                      <p>Notifications are configured and enabled.</p>
                                    :
                                      <p>Notifications are not configured. </p>
                                    }
                                </div>
                                </div>

                                <div className="android-steps">

                                <a href="/app/ad-build-settings"></a>
                                <img src={require("../../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                <div>
                                {this.state.is_admob_enabled == 1 || this.state.is_admob_enabled == true ? 
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <g id="Group_41817" data-name="Group 41817" transform="translate(-1510 -164)">
                                    <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                    <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                </g>
                                </svg>
                            :
                                <svg id="Group_41846" data-name="Group 41846" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" fill="#ff4848"/>
                                <path id="Path_101776" data-name="Path 101776" d="M-16063.67,2569.475l6.416-6.416" transform="translate(16070.461 -2556.267)" fill="none" stroke="#fff" stroke-width="2"/>
                                <path id="Path_118731" data-name="Path 118731" d="M-16057.254,2569.474l-6.416-6.415" transform="translate(16070.461 -2556.267)" fill="none" stroke="#fff" stroke-width="2"/>
                                </svg>
                            }

                                </div>
                                <div>
                                    <h4>Advertisement</h4>
                                    {this.state.is_admob_enabled == 1 || this.state.is_admob_enabled == true ?
                                      <p>Ads are configured and enabled.</p>
                                    :
                                      <p>Ads are not configured.</p>
                                    }
                                </div>
                                </div>
                                {(
                                    parseInt(localStorage.getItem("website_technology")) !== 2 
                                ) &&
                                    (
                                        <div className="android-steps">

                                          <a href="/app/login/build-settings"></a>
                                        <img src={require("../../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                        <div>
                                        {(this.state.is_google_login_enabled == 1 && this.state.notification_config_file != '') 
                                        || (this.state.is_google_login_enabled == true && this.state.notification_config_file != '') ? 
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                        <g id="Group_41817" data-name="Group 41817" transform="translate(-1510 -164)">
                                            <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                            <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                        </g>
                                        </svg>
                                    :
                                        <svg id="Group_41846" data-name="Group 41846" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                        <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" fill="#ff4848"/>
                                        <path id="Path_101776" data-name="Path 101776" d="M-16063.67,2569.475l6.416-6.416" transform="translate(16070.461 -2556.267)" fill="none" stroke="#fff" stroke-width="2"/>
                                        <path id="Path_118731" data-name="Path 118731" d="M-16057.254,2569.474l-6.416-6.415" transform="translate(16070.461 -2556.267)" fill="none" stroke="#fff" stroke-width="2"/>
                                        </svg>
                                    }
                                        </div>
                                        <div>
                                            <h4>Social login</h4>
                                            {(this.state.is_google_login_enabled == 1 && this.state.notification_config_file != '') || 
                                             (this.state.is_google_login_enabled == true && this.state.notification_config_file != '') ?
                                              <p>Social logins are configured and enabled.</p>
                                            :
                                              <p>Social logins are not configured.</p>
                                            }
                                        </div>
                                        </div>
                                    )
                                }

                                <div className="android-steps-animation"></div>
                            </div>
                        </div>
                    </div>
                </>
            }

        <div className="android-mob-btns">
            <button className="download-save-btn-mob" onClick={()=>this.savedata()}
          style={{
            // backgroundColor:localStorage.getItem('button_background_color'),
            border:'1px solid '+localStorage.getItem('button_background_color'),
            color:localStorage.getItem('button_text_color'),
            "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
            "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
            "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
            }}
            ><div class="d-flex align-items-center justify-content-center h-100 w-100">
                      <div class={this.state.saveclass}>
                       
                       </div>
                       <div class={this.state.buttonsaveclass}   style={{
                       color:localStorage.getItem('button_background_color'),
                      }}>Save</div>
                </div>
                </button>
                
                <button className="download-build-app-btn-mob" onClick={()=>this.androidsubmit()}
                 style={{
                    backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                  }}
                >
                <div class="d-flex align-items-center justify-content-center h-100 w-100">
                        <div class={this.state.rebuildclass}>
                       
                       </div>
                       <div class={this.state.buttonbuildclass}>Build app</div>
                </div>
                </button>
                <p className="download-updated-mobdate">
                  {this.state.updatedAt   ? `Updated ${moment(this.state.updatedAt).format("D MMMM YYYY, HH:mm")}` : ''}
                 </p>
        </div>

        
        


        </div>

            </section>
        
            <Modal isOpen={this.state.errormodal} onClick={this.closePopup} className="download-error-popup" centered >

                <ModalBody>

                <ModalHeader >												
                    <div className="security-crossmodal "  onClick={this.closePopup}>
                        <img src={require("../../assets/images/dashboard/cross.png").default} />
                    </div>
                </ModalHeader>

                <div className="download-error-body">
                        <h4>{this.state.title}</h4>
                        <h5>{this.state.errormessage}</h5>
                        <button onClick={this.closePopup}>Okay</button>
                        
                </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={this.state.openDownloadPopup} centered className="download-reset-popup">
                <ModalHeader >												
                    <div className="security-crossmodal " onClick={this.handleAttributePopUp}>
                        <img src={require("../../assets/images/dashboard/cross.png").default} />
                    </div>
                </ModalHeader>
                <ModalBody>

                        <div className="download-reset-body">
                            <h4>Reset </h4>
                            <h5>All data reset.</h5>
                            <div className="download-reset-footer">
                                <button onClick={this.handleAttributePopUp}>Cancel</button>
                                <button  onClick={this.handleReset}><div className={this.state.resetclass}></div><span className={this.state.buttonresetclass}>Reset</span></button>
                            </div>
                        </div>											
                </ModalBody>
            </Modal>
    </>


                    
);
}
}