import React from "react";
import { Link } from "react-router-dom";
export const Notaddonactive = () => {
  return (
    <div className="row align-items-center w-100 m-0">
      <div className="col-md-6">
        <div className="w-100 text-center pt-md-5 pt-3">
          <img
            src={
              require("../../assets/images/dashboard/inactiveuser.png").default
            }
            alt="addmobpre"
            className="img-fluid mb-3 mb-md-0"
          />
        </div>
      </div>
      <div className="col-md-6 web-platform ">
        <div>
          <h3 className="mb-md-2">Team management made easy</h3>
          <p> Collaborate with team members and clients in one place. </p>
          <ul>
            <li>Invite people by email addresses. </li>
            <li>Assign predefined roles to manage access levels. </li>
            <li>View last login and IP address information. </li>
            <li>Revoke access with a click.</li>
          </ul>
          {/* <Link
            to={
              atob(localStorage.getItem("plan_code")) ===
              process.env.REACT_APP_Lifetime_preview
                ? "/deals"
                : localStorage.getItem("workspaceId") !== "" &&
                  localStorage.getItem("workspaceId") !== undefined &&
                  localStorage.getItem("workspaceId") !== "undefined" &&
                  localStorage.getItem("workspaceId") !== null
                ? "/pricing/" + btoa(localStorage.getItem("workspaceId"))
                : "/subscription/" + btoa(localStorage.getItem("Appid"))
            }
            className="next_btn d-block"
          >
            Upgrade Plan
          </Link> */}
         { (localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || 
                             localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || 
                             localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1) ?
           <Link
            to={
              atob(localStorage.getItem("plan_code")) ===
              process.env.REACT_APP_Lifetime_preview
                ? "/deals"
                : localStorage.getItem("workspaceId") !== "" &&
                  localStorage.getItem("workspaceId") !== undefined &&
                  localStorage.getItem("workspaceId") !== "undefined" &&
                  localStorage.getItem("workspaceId") !== null
                ?`/workspace/pricing/${btoa(localStorage.getItem("workspaceId"))}`
                : parseInt(localStorage.getItem('website_technology')) === 4 ? 
                 "/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid'))
                : parseInt(localStorage.getItem('website_technology')) === 5 ? 
                 "/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'))
                : parseInt(localStorage.getItem('website_technology')) === 3 ? 
                 "/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'))
                : parseInt(localStorage.getItem('website_technology')) === 2 ? 
                  "/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'))
                : "/app/pricing"
            }
            className="next_btn d-block"
          >
            Upgrade Plan
          </Link>
        : null
        }
        </div>
      </div>
    </div>
  );
};
