import React from "react";

const Tooltip = ({ message }) => {
  return (
    <p className="full-page-tooltip">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
      >
        <g
          id="Group_40476"
          data-name="Group 40476"
          transform="translate(-1727 -19.132)"
        >
          <g
            id="Ellipse_7706"
            data-name="Ellipse 7706"
            transform="translate(1727 19.132)"
            fill="#fff"
            stroke="#bcc1ce"
            stroke-width="1.2"
          >
            <circle cx="7" cy="7" r="7" stroke="none" />
            <circle cx="7" cy="7" r="6.4" fill="none" />
          </g>
          <path
            id="Path_98061"
            data-name="Path 98061"
            d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
            transform="translate(1725.126 17.539)"
            fill="none"
            stroke="#bcc1ce"
            stroke-miterlimit="10"
            stroke-width="1.2"
          />
          <line
            id="Line_343"
            data-name="Line 343"
            y2="1.2"
            transform="translate(1734 29.132)"
            fill="none"
            stroke="#bcc1ce"
            stroke-miterlimit="10"
            stroke-width="1.2"
          />
        </g>
      </svg>
      <span className="full-page-tooltipsubheader">{message}</span>
    </p>
  );
};

export default Tooltip;
