import React, { useEffect, useRef } from "react";
import Tooltip from "../../../Components/commonIcons/Tooltip";
import Accordion from "react-bootstrap/Accordion";
import Reactcolorpicker from "../../../Dashboard/Appearance/NewReactcolorpicker";
import goback from "../../../assets/images/dashboard/goback.svg";
import goforward from "../../../assets/images/dashboard/goforward.svg";
import ProductDefaultImage from "../../../Components/commonIcons/ProductDefaultImage";
import ThemeHamburgerMenuIcon from "../../../Components/commonIcons/ThemeHamburgerMenuIcon";
import ThemeSearchIcon from "../../../Components/commonIcons/ThemeSearchIcon";
import accordianOpenImg from "../../../assets/images/dashboard/cms/accordian-open.png";
import ThemeBookmarkIcon from "../../../Components/commonIcons/ThemeBookmarkIcon";
import FontSelectionDropdown from "../../../Constants/AppTheme/FontSelectionDropdown";
import Newfeatureicon from '../../../Components/commonIcons/Newfeatureicon';

const ThemeSettings = ({
  headerBackgroundColor,
  textColor,
  seperatorColor,
  handleChange,
  allBlack,
  buttonBackgroundColor,
  buttonTextColor,
  selectedShape,
  handleClick,
  iconColor,
  enableIconPage,
  appFontFamily,
  setAppFontFamily,
  openFontDropdown,
  setOpenFontDropdown,
}) => {

  const wrapperRef = useRef(null);
  const handleClickOutside = (event) => {
    try {
      if (
        wrapperRef &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        setOpenFontDropdown(false);
      }
    } catch (err) {
      setOpenFontDropdown(false);

    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div>
      <div>
        <div className="themeSelct-color-div">
          <div className="themeSelct-color-Left">
            <Accordion>
              <Accordion.Item eventKey="1" className="theme-config-main">
                <div>
                  <Accordion.Header>
                    <div className="theme-left-head-accordian">
                      <h6 className="theme-headText">Header</h6>
                      <div className="theme-head-Link">
                        <img src={accordianOpenImg} />
                      </div>
                    </div>
                  </Accordion.Header>

                  <Accordion.Body>
                    <div className="">
                      <div className="theme-head-colorselect">
                        <div className="theme-headBackgroundColor">
                          <label className="theme-input-label">
                            Header background color
                            <Tooltip
                              message={
                                "Set the background color of the app header."
                              }
                            />
                          </label>
                          <div className="theme-color-picker">
                            <Reactcolorpicker
                              colorname={headerBackgroundColor}
                              onChange={(e) =>
                                handleChange(e, "headerBackgroundColor")
                              }
                              valuecontrol={false}
                              classselection={"themefixback"}
                            />
                          </div>
                        </div>
                        <div className="theme-Separatorcolor mob-theme-inputbottomgap">
                          <label className="theme-input-label">
                            Separator color
                            <Tooltip
                              message={"Set the color of the separator."}
                            />
                            {/* <Newfeatureicon/> */}
                          </label>
                          <div className="theme-color-picker">
                            <Reactcolorpicker
                              colorname={seperatorColor}
                              onChange={(e) =>
                                handleChange(e, "seperatorColor")
                              }
                              valuecontrol={true}
                              classselection={"themefixback"}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="theme-textIcon">
                        <div className="theme-headBackgroundColor">
                          <label className="theme-input-label">
                            Text color
                            <Tooltip
                              message={
                                "Define the color of the text in the header."
                              }
                            />
                          </label>
                          <div className="theme-color-picker">
                            <Reactcolorpicker
                              colorname={textColor}
                              onChange={(e) => handleChange(e, "textColor")}
                              valuecontrol={true}
                              classselection={"themefixback"}
                            />
                          </div>
                        </div>
                        <div
                          className={
                            !allBlack
                              ? "theme-Separatorcolor theme-color-picker-disabled"
                              : "theme-Separatorcolor"
                          }
                        >
                          <label className="theme-input-label">
                            Icon color
                            <Tooltip
                              message={
                                "Choose a color for icons in the header. This feature currently only works if a  black header icon is selected from the icon selection pop up."
                              }
                            />
                            {/* <Newfeatureicon/> */}
                          </label>
                          <div className="theme-color-picker">
                            <Reactcolorpicker
                              colorname={iconColor}
                              onChange={(e) => handleChange(e, "iconColor")}
                              valuecontrol={true}
                              classselection={"themefixback"}
                              pickerShouldNotOpen={!allBlack}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="theme-font-setting-label-div">
                        <h6 className="theme-font-setting-label">
                          Font
                          <Tooltip message={" Select font for app header."} />
                        </h6>

                        <FontSelectionDropdown
                          appFontFamily={appFontFamily}
                          setAppFontFamily={setAppFontFamily}
                          openFontDropdown={openFontDropdown}
                          setOpenFontDropdown={setOpenFontDropdown}
                          wrapperRef={wrapperRef}
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </div>
              </Accordion.Item>

              <Accordion.Item eventKey="2" className="theme-config-main">
                <div>
                  <Accordion.Header>
                    <div className="theme-left-head-accordian">
                      <h6 className="theme-headText">Buttons</h6>
                      <div className="theme-head-Link">
                        <img src={accordianOpenImg} />
                      </div>
                    </div>
                  </Accordion.Header>

                  <Accordion.Body>
                    <div>
                      <div className="theme-head-colorselect button-background-color">
                        <div className="theme-headBackgroundColor">
                          <label className="theme-input-label">
                            Button background color
                            <Tooltip
                              message={
                                "This sets the background color of the call-to-action buttons."
                              }
                            />
                          </label>
                          <div>
                            <Reactcolorpicker
                              colorname={buttonBackgroundColor}
                              valuecontrol={false}
                              onChange={(e) =>
                                handleChange(e, "buttonBackgroundColor")
                              }
                              classselection={"themefixback"}
                            />
                          </div>
                        </div>
                        <div className="theme-Separatorcolor mob-theme-inputbottomgap">
                          <label className="theme-input-label">
                            Button text color
                            <Tooltip
                              message={
                                "This sets the text color of the call-to-action buttons."
                              }
                            />
                          </label>
                          <div>
                            <Reactcolorpicker
                              colorname={buttonTextColor}
                              onChange={(e) =>
                                handleChange(e, "buttonTextColor")
                              }
                              valuecontrol={true}
                              classselection={"themefixback"}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="button-shape-div">
                        <h6 className="button-shape-label">
                          Button shape
                          <Tooltip
                            message={
                              "Choose the desired shape for your call-to-action buttons."
                            }
                          />
                          {/* <Newfeatureicon/> */}
                        </h6>
                        <div className="button-shapes-div">
                          <div
                            onClick={() => handleClick("sharp")}
                            className={
                              selectedShape == "sharp"
                                ? "sharp-shape selected-shape"
                                : "sharp-shape"
                            }
                          ></div>
                          <div
                            onClick={() => handleClick("midround")}
                            className={
                              selectedShape == "midround"
                                ? "mid-rounder-shape selected-shape"
                                : "mid-rounder-shape"
                            }
                          ></div>
                          <div
                            onClick={() => handleClick("round")}
                            className={
                              selectedShape == "round"
                                ? "rounded-shape selected-shape"
                                : "rounded-shape"
                            }
                          ></div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </div>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="themeSelct-color-Right">
            {enableIconPage && (
              <div className="login-mob-previewtop">
                <div className="login-mob-previewtopback login-mob-previewtopbackopacity">
                  <img src={goback} />
                </div>
                <div className="login-mob-previewtopnext mob-previewtopnextdiv">
                  <img src={goforward} />
                </div>
              </div>
            )}

            <div className="theme-preview-frame theme-settings-frame-div">
              <div
                style={{
                  background: headerBackgroundColor
                    ? headerBackgroundColor
                    : " linear-gradient(137.05deg, #32A7FF 7.01%, #5A6FFB 53.88%, #7757FF 96.63%",
                  borderBottom: seperatorColor
                    ? `1px solid ${seperatorColor}`
                    : "1px solid #000",
                }}
                className="theme-preview-header"
              >
                <div className="theme-preview-header-left">
                  <ThemeHamburgerMenuIcon
                    allBlack={allBlack}
                    iconColor={iconColor}
                  />
                  <p
                    style={{
                      color: textColor ? textColor : "#1A1A1A",
                      fontFamily: appFontFamily,
                    }}
                    className="theme-preview-head-text"
                  >
                    Your app
                  </p>
                </div>
                <div className="theme-preview-header-right">
                  <ThemeSearchIcon allBlack={allBlack} iconColor={iconColor} />
                  <ThemeBookmarkIcon
                    allBlack={allBlack}
                    iconColor={iconColor}
                  />
                </div>
              </div>
              <div className="theme-body">
                <h1 className="theme-heading-paragraph">Welcome to London</h1>
                <p className="theme-paragraph">
                  London: Iconic skyline, rich history, bustling streets,
                  diverse culture, royal landmarks, Thames river, vibrant
                  nightlife, renowned museums, global finance hub.
                </p>
                <div className="theme-paragraph-imagediv">
                  <ProductDefaultImage />
                </div>
                <p className="theme-paragraph">
                  The city's cultural scene is unparalleled, with world-class
                  museums like the British Museum, the Tate Modern, and the
                  Victoria and Albert Museum showcasing treasures from every
                  corner of the globe. West End theaters light up with dazzling
                  performances, whi
                </p>
                <div
                  style={{
                    borderRadius:
                      selectedShape == "sharp"
                        ? ""
                        : selectedShape == "midround"
                        ? "8px"
                        : "50px",
                    background: buttonBackgroundColor
                      ? buttonBackgroundColor
                      : "#4E7FFC",
                    color: buttonTextColor ? buttonTextColor : "#fff",
                  }}
                  className="theme-preview-button"
                >
                  Read more
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThemeSettings;
