import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormGroup, Label, Input } from "reactstrap";

import imagebackground from "./../../assets/images/dashboard/cms/uploadbackground.png";
import NewImageGalleryPopup from "./../NewImageGalleryPopup";
import VistaPopup from "../Appearance/VistaPopup";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
export const ImageBlock = ({
  indx,
  cmsArray,
  setcmsData,
  isRowOpen,
  handleIsRowOpen,
  deleteRow,
  duplicateItem,
  eachBlock,

  provided,
  searchValForPopUp ,
  setSearchValForPopUp ,
  setTempListingThumb,
  setListingThumb,
  listingThumb,
  parentComponent,
  fromState,
  setThumbnailActive,
  thumbnailActive,
  setBase64imgThumb
  
}) => {
  const [isGalleryPopup, setIsGalleryPopup] = useState(false);
  const [prevImgUrl, setprevImgUrl] = useState("");
  const location = useLocation()
  // const [openVistaPopUp, setOpenVistaPopUp] = useState(false);
  
  // const [openSmallScreenPopUp, setOpenSmallScreenPopUp] = useState(false);
  // const [apiImageUrl, setApiImageUrl] = useState("");

  const handleRatioChange = (e) => {
    let newArray = [...cmsArray];
    newArray[indx].style.aspect_ratio = e.target.value;
    setcmsData(newArray);
  };

  const imageGalleryPopup = (e) => {
    let newArray = [...cmsArray];
    setIsGalleryPopup(!isGalleryPopup);

    setprevImgUrl(newArray[indx].portal_value.source_file);
  
    if(!thumbnailActive && parentComponent ==="post"){
    setListingThumb("") 
    setTempListingThumb("")
    }
  };

  // Remove Image
  const handleCancelImage = (e) => {
    let newArray = [...cmsArray];

    newArray[indx].portal_value.source_file = "";
    newArray[indx].portal_value.image_link = "";
    newArray[indx].portal_value.base64_image = "";
    newArray[indx].portal_value.base64_org_image = "";
   
    setcmsData(newArray);
  };



  const  blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const settingYourdata =async (imgName, from, orgFileName, url1200, useUrl,uploadedFileBase64,croppedImageForCms) => {

    let newArr = [...cmsArray];
    const resizeImgName= "ams_resize_"  + new Date().getTime() +"_" + Math.floor(1000 + Math.random() * 9000)+".png";
    const originalImgName= "ams_original_"  + new Date().getTime() +"_" + Math.floor(1000 + Math.random() * 9000)+".png";
    
    if (from === "resize") {
      // newArr[indx].portal_value.image_link =originalImgName;
      newArr[indx].portal_value.image_link ="";
      newArr[indx].portal_value.source_file = imgName;
      newArr[indx].portal_value.type = 2;
      newArr[indx].portal_value.resize_image_link = resizeImgName;
      newArr[indx].portal_value.base64_image =await blobToBase64(croppedImageForCms);
      newArr[indx].portal_value.base64_org_image = "";
      newArr[indx].portal_value.design_id = "";
      
      if(!listingThumb && parentComponent ==="post"){
        setTempListingThumb(newArr?.find((row) => row.item_type === "image")?.portal_value?.source_file || "" )
        setListingThumb(newArr?.find((row) => row.item_type === "image")?.portal_value.resize_image_link || "")
        setBase64imgThumb(await blobToBase64(croppedImageForCms));
        }
      // newArr[indx].portal_value.base64_org_image = uploadedFileBase64;
      

    } else if (from === "apiImg") {
      newArr[indx].portal_value.source_file = imgName;
      newArr[indx].portal_value.resize_image_link = url1200;
      // newArr[indx].portal_value.image_link = useUrl;
      newArr[indx].portal_value.image_link = "";
      newArr[indx].portal_value.type = 1;
      newArr[indx].portal_value.base64_image = ""
      newArr[indx].portal_value.design_id = "";
      
      if(!listingThumb && parentComponent ==="post"){
        setTempListingThumb(newArr?.find((row) => row.item_type === "image")?.portal_value?.source_file || "" )
        setListingThumb(newArr?.find((row) => row.item_type === "image")?.portal_value.resize_image_link || "")
        setBase64imgThumb("");
        }
      
    }

    setcmsData(newArr);
  };

  const yourErrFun = () => {
    let newArr = [...cmsArray];

    newArr[indx].portal_value.source_file = prevImgUrl;

    setcmsData(newArr);
  };

  const loadpicVistaCreatePopUp = (data) =>{


  //  const params = {
  //   filename: data.url.replace("https://", ""),
    
  //   path: localStorage.getItem("user_id") +
  //   "/" +
  //   localStorage.getItem("Appid") +
  //   process.env.REACT_APP_CMS_Media,
  // };
  //  thirdPartyImgUploadViaURL(params,successFn)
  // setOpenVistaPopUp(false);
   let newArr = [...cmsArray];
   newArr[indx].portal_value.source_file = data.url;
      newArr[indx].portal_value.resize_image_link = data.url;
   
      newArr[indx].portal_value.image_link = "";
      newArr[indx].portal_value.type = 1;
      newArr[indx].portal_value.base64_image = ""
      newArr[indx].portal_value.base64_org_image = ""
      newArr[indx].portal_value.design_id = data.id;

   setcmsData(newArr);
   setIsGalleryPopup(false);
   if(!listingThumb && parentComponent ==="post"){
    setTempListingThumb(newArr?.find((row) => row.item_type === "image")?.portal_value?.source_file || "" )
    setListingThumb(newArr?.find((row) => row.item_type === "image")?.portal_value.resize_image_link || "")
    setBase64imgThumb("");
    }
   
  //  
  //  if(document.querySelector(".crello-closeBtn")){
  //   document.querySelector(".crello-closeBtn").click();
  //   
  //  }

  }

// const successFn = (imgName) =>{
//   let newArr = [...cmsArray];
//   newArr[indx].portal_value.source_file = imgName;
//      newArr[indx].portal_value.resize_image_link = imgName;
  
//      newArr[indx].portal_value.image_link = "";
//      newArr[indx].portal_value.type = 2;
//      newArr[indx].portal_value.base64_image = ""
//      newArr[indx].portal_value.base64_org_image = ""

//      setOpenVistaPopUp(false);
//   setcmsData(newArr);
// }
  const closeVistaCreatePopUp = () =>{

  }

  const handleStatus = (e) =>{

    let cmsData = [...cmsArray];
    cmsData[indx].status = ( cmsData[indx].status===1 || cmsData[indx].status===undefined || cmsData[indx].status===null)
    ?0:1;
  
    // setbgColor(e.hex);
    setcmsData(cmsData);
  }


  return (
    <div className={`accordionItem ${cmsArray[indx].status===0?"cms-section-hidden":""}`}>
      
      <h2
        class="accordionItemHeading allflex"
        draggableId={indx.toString()}
        {...provided.dragHandleProps}
      > Image
        {/* {from === "post" ? "Featured Image" : "Image"} */}

        <span className="cms-section-visiblity" onClick={(e)=>handleStatus(e)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="25.284" height="20.465" viewBox="0 0 25.284 20.465">
        <g id="Group_39189" data-name="Group 39189" transform="translate(0.568 0.353)">
          <path id="Path_103079" data-name="Path 103079" d="M68.716,9.742a13.852,13.852,0,0,1-12.07,7.684A13.876,13.876,0,0,1,44.568,9.742,13.876,13.876,0,0,1,56.646,2.058,13.852,13.852,0,0,1,68.716,9.742Z" transform="translate(-44.568 0.138)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1"/>
          <ellipse id="Ellipse_8629" data-name="Ellipse 8629" cx="3.842" cy="3.842" rx="3.842" ry="3.842" transform="translate(8.233 6.038)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1"/>
          <line id="Line_430" data-name="Line 430" x2="19.758" y2="19.758" transform="translate(2.196 0.001)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1"/>
        </g>
      </svg>
    </span>

    
       <p className="cms-accordian-p"
          onClick={(e) => {
            handleIsRowOpen(e, indx);
          }}
        >
          <img
            src={
              isRowOpen[indx] === true
                ? require("../../assets/images/dashboard/cms/accordian-open.png")
                    .default
                : require("../../assets/images/dashboard/cms/accordian-icon.png")
                    .default
            }
          />
        </p>
      </h2>

      {isRowOpen[indx] === true && (
        <div class="accordionItemContent">

          <label className="set-full-img">
            <div className="image-uploader">
              <div
                className="d-flex align-items-center"
                onClick={(e) => imageGalleryPopup(e)}
              >
                <div
                  className="uploadpixabayimage"
                  style={{
                    backgroundImage:
                      "url(" +
                      (cmsArray[indx].portal_value.source_file !== undefined &&
                      cmsArray[indx].portal_value.source_file !== null &&
                      cmsArray[indx].portal_value.source_file !== ""
                        ? //  cmsArray[indx].portal_value.source_file ===
                          //     "rainbow-wall.jpg" ||
                          //   cmsArray[indx].portal_value.source_file ===
                          //     "floating-unicorn.jpg"
                          //   ? process.env.REACT_APP_Image_Path +
                          //     process.env.REACT_APP_DEFAULT_CMS_IMG +
                          //     cmsArray[indx].portal_value.source_file
                          //   :
                          cmsArray[indx].portal_value.source_file.indexOf("https")>-1?
                          cmsArray[indx].portal_value.source_file
                          :
                          process.env.REACT_APP_Image_Path +
                          localStorage.getItem("user_id") +
                          "/" +
                          localStorage.getItem("Appid") +
                          process.env.REACT_APP_CMS_Media +
                          cmsArray[indx].portal_value.source_file
                        : imagebackground) +
                      ")",
                  }}
                ></div>

                <p className={cmsArray[indx].portal_value.source_file !== "" &&
                  cmsArray[indx].portal_value.source_file !== undefined
                    ?"": "cms-image-placeholder"}>
                  {cmsArray[indx].portal_value.source_file !== "" &&
                  cmsArray[indx].portal_value.source_file !== undefined
                    ? cmsArray[indx].portal_value.source_file.replace("https://create.vista.com/downloads/?filePath=%2Fdownloads%2F","")
                    : "Upload"}
                </p>
              </div>
              {cmsArray[indx].portal_value.source_file !== "" &&
                cmsArray[indx].portal_value.source_file !== undefined && (
                  <svg
                    width="11.06"
                    height="11.06"
                    viewBox="0 0 11.06 11.06"
                    onClick={(e) => handleCancelImage(e)}
                  >
                    <path
                      id="Union_1696"
                      data-name="Union 1696"
                      d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z"
                      transform="translate(0.53 0.53)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="1.5"
                    />
                  </svg>
                )}
            </div>
          </label>

          <FormGroup tag="fieldset" className="select-gallery-ratio" style={{position: "absolute"}}>
            
            <FormGroup>
              <Label className="galleryimg-ratio" htmlFor={`aspectratio${indx}3`}>
                <Input
                  id={`aspectratio${indx}3`}
                  type="radio"
                  // name="radio1"
                  value="16:9"
                  checked={cmsArray[indx].style.aspect_ratio === "16:9"}
                  onChange={(e) => handleRatioChange(e)}
                />
                
                <div className="image">
                <span class="cms-icon-tooltip">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="13.5" viewBox="0 0 24 13.5">
                  <g id="Rectangle_18739" data-name="Rectangle 18739" fill="" stroke="" stroke-width="2">
                    <rect width="24" height="13.5" stroke="none"/>
                    <rect x="1" y="1" width="22" height="11.5" fill="none"/>
                  </g>
                  </svg>
                  <p>16:9</p>
                  <span class="cms-icon-tooltipsubheader">Select for a widescreen image format, ideal for panoramic views and cinematic visuals.</span>
                  </span>
                </div>
                
              </Label>
            </FormGroup>

            <FormGroup>
              <Label className="galleryimg-ratio" htmlFor={`aspectratio${indx}0`}>
                <Input
                  id={`aspectratio${indx}0`}
                  type="radio"
                  // name="radio1"
                  value="4:3"
                  checked={cmsArray[indx].style.aspect_ratio === "4:3"}
                  onChange={(e) => handleRatioChange(e)}
                />
                <div className="image">
                <span class="cms-icon-tooltip">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15">
                  <g id="Rectangle_18739" data-name="Rectangle 18739" fill="" stroke="" stroke-width="2">
                    <rect width="20" height="15" stroke="none"/>
                    <rect x="1" y="1" width="18" height="13" fill="none"/>
                  </g>
                </svg>

                  <p>4:3</p>
                  <span class="cms-icon-tooltipsubheader">Opt to maintain a classic image aspect ratio, suitable for balanced compositions.</span>
                  </span>
                </div>
              </Label>
            </FormGroup>

            <FormGroup>
              <Label className="galleryimg-ratio" htmlFor={`aspectratio${indx}2`}>
                <Input
                  id={`aspectratio${indx}2`}
                  type="radio"
                  // name="radio1"
                  value="1:1"
                  checked={cmsArray[indx].style.aspect_ratio === "1:1"}
                  onChange={(e) => handleRatioChange(e)}
                />
                <div className="image">
                <span class="cms-icon-tooltip">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                  <g id="Rectangle_18739" data-name="Rectangle 18739" fill="" stroke="" stroke-width="2">
                    <rect width="18" height="18" stroke="none"/>
                    <rect x="1" y="1" width="16" height="16" fill="none"/>
                  </g>
                </svg>
                  <p>1:1</p>
                  <span class="cms-icon-tooltipsubheader">Choose for a square image format, great for symmetrical and uniform visuals.</span>
                  </span>
                </div>
              </Label>
            </FormGroup>

            <FormGroup>
              <Label className="galleryimg-ratio" htmlFor={`aspectratio${indx}1`}>
                <Input
                  id={`aspectratio${indx}1`}
                  type="radio"
                  // name="radio1"
                  value="3:4"
                  checked={cmsArray[indx].style.aspect_ratio === "3:4"}
                  onChange={(e) => handleRatioChange(e)}
                />
                <div className="image">
                <span class="cms-icon-tooltip">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 15 20">
                    <g id="Rectangle_18739" data-name="Rectangle 18739" fill="" stroke="" stroke-width="2">
                      <rect width="15" height="20" stroke="none"/>
                      <rect x="1" y="1" width="13" height="18" fill="none"/>
                    </g>
                  </svg>
                  <p>3:4</p>
                  <span class="cms-icon-tooltipsubheader">Pick for a portrait-oriented image format, perfect for capturing vertical scenes and portraits.</span>
                  </span>
                </div>
              </Label>
            </FormGroup>

          </FormGroup>

          <div className="duplicate-delete">
            <div className="cms-dup-del-btn"   onClick={(e) => duplicateItem(e, eachBlock)}>
            <span class="cms-delete-tooltip">
            <img
                src={
                  require("../../assets/images/dashboard/cms/duplicate.png")
                    .default
                }
              />
               <span class="cms-delete-tooltipsubheader">Duplicate</span>
                  </span>
            </div>

            <div className="cms-dup-del-btn"  
              onClick={(e) => deleteRow(e, indx)}
              // className={`${indx === 1 ? "d-none" : ""}`}
            >
              <span class="cms-delete-tooltip">
            <img
                src={
                  require("../../assets/images/dashboard/cms/delete.png")
                    .default
                }
              />
               <span class="cms-delete-tooltipsubheader">Delete</span>
                  </span>
            </div>
          </div>
        </div>
      )}
     
      <NewImageGalleryPopup
        isGalleryPopup={isGalleryPopup}
        setIsGalleryPopup={setIsGalleryPopup}
        settingYourdata={settingYourdata}
        yourErrFun={yourErrFun}
        uploadPath={
          localStorage.getItem("user_id") +
          "/" +
          localStorage.getItem("Appid") +
          process.env.REACT_APP_CMS_Media
        }
        settingOrgImgFrom3rdParty={false}
        hideImageGallery={false}
        widthForRestriction={1125}
        heightForRestriction={480}
        aspectRatio={cmsArray[indx].style.aspect_ratio}
        isNotCustomUploadOrgImg={true}
        isCms={true}
        
        designid={cmsArray[indx].portal_value.design_id ? cmsArray[indx].portal_value.design_id : ""}
        loadpicVistaCreatePopUp={loadpicVistaCreatePopUp}
        closeVistaCreatePopUp={closeVistaCreatePopUp}
        searchValForPopUp ={searchValForPopUp}
        setSearchValForPopUp ={setSearchValForPopUp}
      />
         

   
    </div>
  );
};

export default ImageBlock;
