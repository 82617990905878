import {
    Modal,
    ModalHeader,
    ModalBody,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ModalFooter,
  } from "reactstrap";

export const SingleSelectionPopUpForAdBlock = ({
    openPopUp,
    handlePopUp,
    handleInputClick,
    selectedId,
    subData,
    searchVal,
    setSearchVal,
    from,
  }) => {
  

    const handleSearchVal = (e) => {
      setSearchVal(e.target.value);
    };
  
    let allCount = 0;

    let finalDataArr = subData &&
     subData.filter((row) =>(
        (row.name.toLowerCase().includes(searchVal.toLowerCase())) 
    && (row.type.toLowerCase().includes(from.toLowerCase()))
    && (row.ad_status==="active")
    ));
  
    return (
      <div>
     
        <Modal
          className="Single-select-popup"
          isOpen={openPopUp}
          centered
          toggle={(e) => handlePopUp(e, "cancel")}
        >
          <ModalHeader>
            <div className="single-select-header">
              <h2>Ads</h2>
  
              <div className="mob-single-select-top">
  
                <div className="mob-single-select-search">
                  <svg
                    onClick={(e) => handlePopUp(e,"cancel")}
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.759"
                    height="15.408"
                    viewBox="0 0 20.759 15.408"
                  >
                    <g
                      id="Group_27465"
                      data-name="Group 27465"
                      transform="translate(-19.742 -22.295)"
                    >
                      <path
                        id="Path_81245"
                        data-name="Path 81245"
                        d="M-13783.922-19217h19.346"
                        transform="translate(13805.077 19247)"
                        fill="none"
                        stroke="#7782a1"
                        stroke-width="2"
                      />
                      <path
                        id="Path_82232"
                        data-name="Path 82232"
                        d="M14053.656,19255.426l-7,7,7,7"
                        transform="translate(-14025.504 -19232.424)"
                        fill="none"
                        stroke="#7782a1"
                        stroke-width="2"
                      />
                    </g>
                  </svg>
                  
                  <div className="singleselect-serachdiv">
                    <input
                      placeholder="Search"
                      value={searchVal}
                      onChange={(e) => handleSearchVal(e)}
                    />
                    {selectedId?
                  <p className={selectedId?"mob-popUp-done-enable" : "mob-popUp-done-disable"}
                  onClick={(e) =>
                    selectedId?
                    handlePopUp(e, "open")
                    :null
                  }>Done</p>
                  :
                    searchVal.trim() && (
                      <p
                        className="Single-selct-search-clear"
                        onClick={() => {
                          setSearchVal("");
                        }}
                      >
                        Clear
                      </p>
                    )}
                  </div>
                  <div
                    className="single-select-serach-crossdiv"
                    onClick={(e) => handlePopUp(e, "cancel")}
                  >
                    <img
                      src={
                        require("../../assets/images/dashboard/cross.png").default
                      }
                    />
                  </div>
                </div>
              </div>
  
              <div
                className="publish-crossmodal single-select-web-cross"
                onClick={(e) => handlePopUp(e, "cancel")}
                style={{
                  zIndex: "9",
                  cursor: "pointer",
                }}
              >
                <img
                  src={require("../../assets/images/dashboard/cross.png").default}
                />
              </div>
            </div>
          </ModalHeader>
          <ModalBody>
            <div>
               {
                 (!finalDataArr || finalDataArr.length === 0) ? (
                  <div className="select-no-data-found 22">
                    <img
                      src={
                        require("../../assets/images/dashboard/no-media.png")
                          .default
                      }
                    />
                    <p>No results found</p>
                  </div>
                ) : (
               
                <div>
                  {finalDataArr &&
                    finalDataArr
                      .map((res) => {
                        return (
                          <div className="single-select-maindiv">
                            <label className="Single-select-container">
                              {res.name?res.name:"-"}
                              <input
                                type="radio"
                                checked={res.id === selectedId}
                                onChange={(e) =>
                                  handleInputClick(
                                    e,
                                    res.id,
                                    res.name
                                  )
                                }
                              />
                              <span className="Single-select-checkmark"></span>
                            </label>

                          </div>
                        );
                      })}
                </div> 
                )}

              </div>
          </ModalBody>
          <ModalFooter>
            <button
              className="Single-select-cancel-btn "
              onClick={(e) => handlePopUp(e, "cancel")}
            >
              Cancel
            </button>
            <div
              className="d-flex"
              style={{ margin: "0px", alignItems: "center" }}
            >
              <p className="single-select-selected">
                {selectedId ? "1 item selected" : ""}
              </p>
              <button
              className={
                selectedId
                  ? "Single-select-done-btn"
                  : "Single-select-done-disable"
              }
              disabled={
                selectedId
                  ? false
                  : true
              }
                onClick={(e) => handlePopUp(e, "open")}
              >
                Done
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  };