import axios from "axios";
const dashboardurl = process.env.REACT_APP_API_URL + "api/";
const cmsAnalyticsUrl = process.env.REACT_APP_CMS_Analytics + "api/";


export const Reportdata = (state, componentname, callback) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  
  
  axios
    .get(
      dashboardurl + "app/analytics/" + btoa(localStorage.getItem("Appid")),
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        
        if (state.stopmounting === true) {
          if (componentname === "analytics") {
            state.setState({
              spinner: res.data.data.is_plugin_enabled === 1 ? true : false,
              token: res.data.token,
              data: res.data.data,
              app_analytics: JSON.parse(res.data.app_analytics),
              code: res.data.code,
              addon_code:res.data.addon_code,
              android_bundle_identifier: res.data.android_bundle_identifier,
              analytics_status: res.data.analytics_status,
              androidnotifyid: res.data.android_notification_project_id,
              iosnotifyid: res.data.ios_notification_project_id,
              trackData:
              (res.data.data.record_analytics_data===undefined || res.data.data.record_analytics_data===null)
              ?true:
              res.data.data.record_analytics_data === 1 ? true:false,
              trackDataFinalVal:(res.data.data.record_analytics_data===undefined || res.data.data.record_analytics_data===null)
              ?true:
              res.data.data.record_analytics_data === 1 ? true:false,
              // appstoreConnectionStatus:res.data.data.analytics_connectivity_status,

            });
          } else if (componentname === "dashboard") {
            state.setState({
              // spinner: res.data.data.is_plugin_enabled === 1 ? true : false,
              token: res.data.token,
              website_type: res.data.data.website_type,
              // dashboarddata: res.data.data,
              app_analytics: JSON.parse(res.data.app_analytics),
              statuscode: res.data.code,
              addon_code:res.data.addon_code,
              android_bundle_identifier: res.data.android_bundle_identifier,
              activeandroidspinner: false,
            });
          }

          //res.data.data.is_plugin_enabled === 1 &&
          callback();
        }
      } else if (res.data.code === 203) {
      
        if (state.stopmounting === true) {
          if (componentname === "analytics") {
            state.setState({
              spinner: false,
              code: res.data.code,
              plan_code: res.data.plan_code,
              addon_code:res.data.addon_code,
              reseller_package_id: res.data.reseller_package_id,
              analytics_status: res.data.analytics_status,
            });
          } else if (componentname === "dashboard") {
            state.setState({
              statuscode: res.data.code,
              histogramspinner:false,
            });
          }
        }
      }
    });
};

export const Getanalytics = (params, state, url) => {
 
  const headers = {
    "Content-Type": "application/json",
    "X-App-Device-Type": "administrator",
    Authorization: `Bearer ${params}`,
  };
  axios
    .get(
        `${(url === "cms-analytics" || url === "non-wp-analytics" ) ? cmsAnalyticsUrl:(process.env.REACT_APP_Aalytics+"/")}${url}?from=${state.state.selectfilter}&timestamp=${new Date().getTime()}&calendar_interval=${state.state.intervalFilterVal}`,
      {
        headers: headers,
        data: {},
      },
    )
    .then((res) => {
      if (res.status === 200) {
        state.setState({
          spinner: false,
          reportdata: res.data,

          histogramspinner: false,
          activeuserspinner: false,
        });
      } else {
        state.setState({
          spinner: false,
          reportdata: [],
          histogramspinner: false,
          activeuserspinner: false,
        });
      }
    })
    .catch((error) => {
      state.setState({
        spinner: false,
        reportdata: [],
        histogramspinner: false,
        activeuserspinner: false,
      });
      
    });
};

// export const GetanalyticsCMS = (params, state, url) => {
//   const headers = {
//     // "Content-Type": "application/json",
//     // Authorization: "Bearer " + params,
//     // "X-App-Device-Type": "administrator",
//     "Content-Type": "application/json",
//     Authorization: "Bearer " + params,

//     "X-App-Device-Type": "Ios",
//     "X-App-Ver": "3.10.0",
//   };
//   axios
//     .get(
//       process.env.REACT_APP_CMS_Analytics +
//         "/" +
//         url +
//         "?range=" +
//         state.state.selectfilter +
//         "&timestamp=" +
//         new Date().getTime(),
//       {
//         headers: headers,
//         data: {},
//       }
//     )
//     .then((res) => {
//       if (res.status === 200) {
//         state.setState({
//           spinner: false,
//           reportdata: res.data,

//           histogramspinner: false,
//           activeuserspinner: false,
//         });
//       } else {
//         state.setState({
//           spinner: false,
//           reportdata: [],
//           histogramspinner: false,
//           activeuserspinner: false,
//         });
//       }
//     })
//     .catch((error) => {
//       state.setState({
//         spinner: false,
//         reportdata: [],
//         histogramspinner: false,
//         activeuserspinner: false,
//       });
//       
//     });
// };
