import React from "react";
const removepreview = () => {
  document.querySelector(".customiseapp").style.display = "none";
  document.querySelector(".preview-red-cross").style.display = "none";
  document.querySelector(".phone-menu ").style.display = "block";
};
export const RemoveApppreview = () => {
  return (
    <div
      className="preview-red-cross redcross-spacing preview-red-cross-style"
      onClick={removepreview}
    >
      <img
        src={require("../../assets/images/dashboard/preview-cross.png").default}
        className="img-fluid"
        alt="error"
      />
    </div>
  );
};
