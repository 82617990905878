export const SUPPORTCARDDETAILS = [
    {
        heading:"Knowledge base",
        text:"Find articles that answer your questions. ",
        link:"https://support.appmysite.com/",
        icon:"knowledge-base",
    },
    {
        heading:"Email support",
        text:"Submit a request and get help from experts.",
        link:`${window.location.protocol}//${window.location.host}/email-support`,
        icon:"email-support",
    },
    {
        heading:"Live chat",
        text:"Chat with a mobile app specialist.",
        link : undefined , 

        icon:"live-chat",
    },
    {
        heading:"Book a call",
        text:"Book a call with a tech support specialist.",
        link: "/book-a-call",
        icon:"book-call",
    },
    {
        heading:"Speak to sales",
        text:"Schedule a call with a product specialist.",
        link:"https://calendly.com/appmysite/sales",
        icon:"speak-sales",
    },
    {
        heading:"Email sales",
        text:"Schedule a call with a product specialist.",
        link:"/get-in-touch/",
        icon:"email-sales",
    },
]