export const APP_PLANS = { starter: "starter", preview: "preview" };
export const DEVICE_TYPE = { android: "android", ios: "ios", both: "both" };
export const DEFAULT_LINKS_ARRAY = [
  {
    invidual_selected_item_id: "",
    temp_invidual_selected_item_id: "",
    name: "Pages",
    post_type: "",
    rest_base: "",
    slug: "page",
    type: "cms_page",
    isCms: 1,
  },
  {
    invidual_selected_item_id: "",
    temp_invidual_selected_item_id: "",
    name: "Posts",
    post_type: "",
    rest_base: "",
    slug: "post",
    type: "cms_post",
    isCms: 1,
  },

  {
    invidual_selected_item_id: "",
    temp_invidual_selected_item_id: "",
    name: "Post Categories",
    post_type: "",
    rest_base: "",
    slug: "category",
    type: "cms_category",
    isCms: 1,
  },
  {
    invidual_selected_item_id: "",
    temp_invidual_selected_item_id: "",
    name: "Post Tags",
    post_type: "",
    rest_base: "",
    slug: "tag",
    type: "cms_tag",
    isCms: 1,
  },
];
export const DEFAULT_FILTER_OPTIONS = [
  { value: "Status", label: "Status" },
  { value: "Date", label: "Date" },
  { value: "Platform", label: "Platform" },
];
export const DEFAULT_FILTER_VALUE = "Select";
export const FILTER_CUSTOM_STYLES = {
  control: (base, state) => ({
    ...base,
    // match with the menu
    borderRadius: 3,
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#7782a1" : "#C8CEDB",
    backgroundColor: state.isDisabled ? "#ffffff" : "",
    height: 50,
    minHeight: 50,
    fontSize: 14,
    color: "#7782A1",
    paddingLeft: 10,
    boxShadow: state.isFocused ? "none" : "none",
    "&:hover": {
      boxShadow: state.isFocused ? "none" : "none",
    },
  }),
  valueContainer: (base) => ({
    ...base,
    height: 50,
    minHeight: 50,
    fontSize: 14,
    color: "#7782A1",
  }),
  menu: (base) => ({
    ...base,
    fontSize: "14px",
  }),
  input: (base) => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#1B2952",
    marginRight: 20,
  }),
};
export const FILTER_STATUS = {
  SENT: "sent",
  DRAFT: "draft",
  SCHEDULED: "scheduled",
  FAILED: "failed",
  ALL: "all",
};
export const WOO_OPTIONS = [
  { item_name: "Cart", item_type: "cart" },
  { item_name: "Chat", item_type: "chat" },
  { item_name: "Home screen", item_type: "home" },
  { item_name: "Menu", item_type: "main_menu" },
  { item_name: "Orders", item_type: "orders" },
  { item_name: "Pages", item_type: "pages" },
  { item_name: "Product categories", item_type: "product_categories" },
  { item_name: "Profile", item_type: "profile" },
  { item_name: "Settings", item_type: "settings" },
  { item_name: "Web view", item_type: "web_view" },
];
export const WORDPRESS_OPTIONS = [
  { item_name: "Chat", item_type: "chat" },
  { item_name: "Home screen", item_type: "home" },
  { item_name: "Menu", item_type: "main_menu" },
  { item_name: "Pages", item_type: "pages" },
  { item_name: "Profile", item_type: "profile" },
  { item_name: "Settings", item_type: "settings" },
  { item_name: "Web view", item_type: "web_view" },
];
export const WEBTOAPP = [
  { item_name: "Chat", item_type: "chat" },
  { item_name: "Menu", item_type: "main_menu" },
  { item_name: "Settings", item_type: "settings" },
  { item_name: "Web view", item_type: "web_view" },
];
export const CUSTOM_OPTIONS = [
  { item_name: "Chat", item_type: "chat" },
  { item_name: "Menu", item_type: "main_menu" },
  { item_name: "Settings", item_type: "settings" },
  { item_name: "Web view", item_type: "web_view" },
];
