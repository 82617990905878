import React from "react";
import { Modal, ModalBody } from "reactstrap";

const ScreenDetectionModal = ({ openPopUp, handleMobVistaPopup }) => {
  return (
    <Modal className="no-editor" isOpen={openPopUp} centered>
      <ModalBody>
        <div className="no-editor-popup">
          <h4 className="no-editor-popup-title">Small screen detected</h4>
          <h6 className="no-editor-popup-desc">
            Please login from a device with a larger screen such as a laptop or
            desktop, for a better design and editing experience.{" "}
          </h6>
          <button className="no-editor-popup-okay-btn" onClick={(e) => handleMobVistaPopup(e, "cancel")}>
            Okay
          </button>
          {/* <span className="no-editor-popup-proceed" onClick={(e) => handleMobVistaPopup(e, "proceed")}>
            Proceed anyway
          </span> */}
        </div>

        <div className="crossmodal mt-1">
          <img
            onClick={(e) => handleMobVistaPopup(e, "cancel")}
            src={require("../../../assets/images/dashboard/cross.png").default}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ScreenDetectionModal;
