import React from 'react'

const CommonInput = ({type, toggleName, handleStateChange, webViewState}) => {
  return (
<>
<label className="switch" htmlFor={toggleName}>
    <input
      type="checkbox"
      id={toggleName}
      name={toggleName}
      checked={webViewState[toggleName]}
      onChange={(e) =>
        handleStateChange("checkbox", {
          id: e.target.id,
          checked: e.target.checked,
        })
      }
    />

    <div className="slider round"></div>
    </label>
</>
  )
}

export default CommonInput