import React from "react";
import ItemsSorting from "../ItemsSorting";
// import "react-sortable-tree/style.css";
// import SortableTree from "react-sortable-tree";
// import MenuBuilder from "react-dnd-menu-builder";
// import TreeView from "./TreeView";

const MenuSortingList = ({
  menuListingData,
  menuListingArrName,
  setMenuState,
  menuType,
}) => {
  const settingIds = (listingData, startId = 0) => {
    let currentId = startId;

    const recursTraverse = (arr) => {
      return (
        arr &&
        arr.map((item) => {
          const newItem = {
            ...item,
            id: Math.floor(Math.random() * 2000) + currentId,
            item_position: currentId,
          };
          currentId += 1;
          if (newItem.children && newItem.children.length) {
            newItem.children = recursTraverse(newItem.children);
          }

          return newItem;
        })
      );
    };

    return recursTraverse(listingData);
  };

  const handleMenuSorting = (sortingObj) => {
    setMenuState({
      [menuListingArrName]: settingIds(sortingObj.items),
    });
  };

  return (
    <>
      <div className="menu-nested-main-div">
          <p className="primary-menu-nested-head">{menuType} menu</p>
        <ItemsSorting
          itemsArr={menuListingData}
          handleSorting={handleMenuSorting}
        />
      </div>

      {/* <hr />
      <div style={{ height: "400px" }}>
        <SortableTree
          treeData={menuListingData}
          onChange={(sortingObj) => handleMenuSorting1(sortingObj)}
          generateNodeProps={(rowInfo) => ({
            title: rowInfo.node.item_name,
          })}
          style={{
            touchAction: "auto",
          }}
        />
      </div>

      <hr />
      <MenuBuilder
        items={treeData}
        onChange={handleMenuSorting}
        setItems={setTreeData}
        renderItem={(item, { drag }) => (
          <div
            ref={drag}
            style={{ padding: "8px", border: "1px solid gray", margin: "4px" }}
          >
            {item.title}
          </div>
        )}
      />

      <hr />

      <TreeView
        menuListingData={menuListingData}
        setMenuState={setMenuState}
        menuListingArrName={menuListingArrName}
      /> */}
    </>
  );
};

export default MenuSortingList;
