import axios from "axios";

export class CustomS3Client {
  constructor(config) {
    this.client = config;
  }
  getFileNameWithExtension(file, newFileName) {
    if (newFileName && newFileName.includes(".")) {
      return newFileName;
    }
    return `${newFileName}.${file.type.split("/")[1]}`;
  }

  getFilePath(dirName) {
    if (/\bprofile\b/.test(dirName)) {
      return "profile";
    } else if (/\bsupport\b/.test(dirName)) {
      return "support";
    } else if (dirName.includes("workspaces")) {
      return `workspaces/${localStorage.getItem("workspaceId")}`;
    } else if (dirName.includes("agencies")) {
      return `agencies/${localStorage.getItem("agency_id")}/media`;
    } else {
      return dirName.split(`${localStorage.getItem("Appid")}/`)[1];
    }
  }

  async fetchPresignedUrl(fileName, contentType) {
    try {
      const { data, status } = await axios({
        url: `${process.env.REACT_APP_API_URL}api/user/get-presigned-url`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("amstoken")}`
        },
        data: {
          related_to: this.client.related_to,
          file_name: fileName,
          file_type: contentType,
          file_path: this.getFilePath(this.client.dirName),
          app_id: this.client.from === "support" ? String(this.client.appId) : localStorage.getItem("Appid")
        }
      });
      if (status === 200) {
        if (data.url) {
          return { success: true, presignedUrl: data.url };
        } else {
          return { success: false, message: "Failed to fetch presigned URL" };
        }
      } else {
        return {
          success: false,
          message: "Failed to fetch presigned URL: " + data.status
        };
      }
    } catch (error) {
      return {
        success: false,
        message:
          error.response?.data?.message ||
          error?.message ||
          "An error occurred while fetching the presigned URL."
      };
    }
  }

  async uploadToS3(file, presignedUrl, filename) {
    try {
      const response = await axios.put(presignedUrl, file, {
        headers: {
          "Content-Type": file.type
        },
        onUploadProgress: (progressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (this.client.onProgressSetter) {
            this.client.onProgressSetter(percentage);
          }
        }
      });
      if (response.status === 200) {
        return {
          message: "File uploaded successfully!",
          status: 204,
          key: filename
        };
      } else {
        console.error("Upload failed:", response.data);
        // return { message: "Upload failed.", status: 400 };
      }
    } catch (error) {
      console.error(
        "Error uploading file:",
        error.response?.data || error.message
      );
      return {
        message:
          error.response?.data?.message ||
          "An error occurred during the upload.",
        status: 500
      };
    }
  }

  async uploadFile(file, newFileName) {
    const fileName = this.getFileNameWithExtension(file, newFileName);
    const { presignedUrl, success, message } = await this.fetchPresignedUrl(
      fileName,
      file.type
    );
    if (!success) {
      console.error(message);
      return;
    }

    return this.uploadToS3(file, presignedUrl, fileName);
  }
}
