
import axios from "axios";
const dashboardurl = process.env.REACT_APP_API_URL + "api/";
const logsUrl = ( parseInt(localStorage.getItem("website_technology")) === 2 || parseInt(localStorage.getItem("website_technology")) === 3 ) ?
 process.env.REACT_APP_CMS_Analytics + "api":
 process.env.REACT_APP_Aalytics;


  export const LogsApi = async (setToken,setIOSnotifyid,setANDROIDnotifyid) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    const data = await  axios.get(dashboardurl + "app/analytics/" + btoa(localStorage.getItem("Appid")) + "?skip_addon_check=1",{
        headers: headers
      }).then(res=>{
        if(res.data.code === 200) {
            setToken(res.data.token)
            setIOSnotifyid(res.data.ios_notification_project_id)
            setANDROIDnotifyid(res.data.android_notification_project_id)
            return res.data.token
          }
        })
    return data
  }
      
  export const getData = (params,setData,setIsLoading,filter,pagination,setBottomSpinner,data,setEndData,setCheckFilter,checkFilter,increamentData) => {
    const headers = {
      //  'Authorization': "Bearer " + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRfaWQiOiIxMDAwMDI4MCIsImlhdCI6MTY0MTk2MzY1MywiZXhwIjoxNjQyMDA2ODUzfQ.gDa2JRyFFAwDszgEmKZUxnTsqXc_lQeGCVIq7jyvqfc",
      // 'X-App-Code-Ver': '1.16.8',
      'Content-Type': 'application/json',
      'X-App-Device-Type': 'Administrator',
      'Accept':'application/json',
      'Authorization': "Bearer " + params,
      
    }

    let feedData = pagination.from * pagination.size
    if(increamentData === false) {
      feedData = 0
    }

    axios.get(`${logsUrl}/${( parseInt(localStorage.getItem("website_technology")) === 2 || parseInt(localStorage.getItem("website_technology")) === 3 )?"custom-app-":""}api-errors?timestamp=${new Date().getTime()}&range=${filter}&size=${pagination.size}&from=${feedData}`,{
      headers: headers,
      data:{} 
    }).then(res => {
      if(res.status === 200) 
      {
    
        if(res.data.hits.hits.length < pagination.size) {
          setEndData(true)
        }
        if (pagination.from > 0 && checkFilter === false) {
          const previousData = data;
          res.data.hits.hits.map(item => {
            previousData?.hits.push(item)
          })
          setData(previousData)
          setIsLoading(false)
          if(res.data.hits.hits.length > 0) {
            setBottomSpinner(false)
          }
        }else {
          setCheckFilter(false)
          setData(res.data.hits)
          setIsLoading(false)
          if(res.data.hits.hits.length > 0) {
            setBottomSpinner(false)
          }
        }
      }
    }).catch(error => {
     setIsLoading(false)
     setData(null)
    })
  }

  export const connectivityLogs = async (setTokenId) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    const data = await  axios.get(dashboardurl + "app/analytics/" + btoa(localStorage.getItem("Appid")) + "?skip_addon_check=1",{
        headers: headers
      }).then(res=>{
        if(res.data.code === 200) {
          setTokenId(res.data.token)
          return res.data.token
          }
        })
    return data
  }

  export const connectivityError = (filter,tokenId,setLogsData,setIsLoading) => {
    // hears to pass in api call
    const headers = {
      // 'Authorization': "Bearer " + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRfaWQiOiIxMDAwMDI4MCIsImlhdCI6MTYzOTU1NjUzOCwiZXhwIjoxNjM5NTk5NzM4fQ.1nu-xuRmYGn0516Ol0EvxyOuLq1O3XQIEQkfvzMG9QI",
      'Authorization': "Bearer " + tokenId,
      
      'X-App-Device-Type': 'Administrator',
      'Accept':'application/json',
      'Content-Type': 'application/json',
    }
    // api call
    axios.get(`${logsUrl}/connectivity-errors?range=${filter}&timestamp=${new Date().getTime()}`,{
      headers: headers,
      data:{}
    }).then(res => {
      setLogsData(res.data.hits)
      setIsLoading(false)
    })
  }