import React from "react";
import AppleIcon from "../commonIcons/AppleIcon";
import AndroidIcon from "../commonIcons/AndroidIcon";
import AppleMobPreview from "../../assets/images/dashboard/newapplemobilepreview.png";
import AndroidMobPreview from "../../assets/images/dashboard/newandroidmobilepreview.png";
import dotIc from "../../assets/images/dashboard/dotIc.svg";
import upArrIc from "../../assets/images/dashboard/upAr.svg";
const NotificationPreview = ({ notificationData, setNotificationData }) => {
  const USER_ID = JSON.parse(
    localStorage.getItem("create_notification_img")
  )?.user_id;
  const APP_ID = JSON.parse(
    localStorage.getItem("create_notification_img")
  )?.app_id;
  const APP_IMAGE = JSON.parse(
    localStorage.getItem("create_notification_img")
  )?.image;

  const userIcon =
    process.env.REACT_APP_Image_Path +
    USER_ID +
    "/" +
    APP_ID +
    process.env.REACT_APP_AppIcon_Display +
    APP_IMAGE;

  const uploadedImage = notificationData.notificationImage
    ? process.env.REACT_APP_Image_Path +
      localStorage.getItem("user_id") +
      "/" +
      localStorage.getItem("Appid") +
      process.env.REACT_APP_Plain_Notification_Media +
      notificationData.notificationImage
    : "";

  return (
    <div className="createpushnotificationright">
      <div className=" two-btn-tabs newtabwrapper">
        <div className="text-center">
          <div className="tab-content push-notify">
            <div
              role="tabpanel"
              className="tab-pane fade in active show newmobile_bg"
              id="ios"
            >
              <div
                className={`${
                  notificationData.isIos ? "notimobimg" : "notimobimg"
                }`}
              >
                <img
                  src={
                    notificationData.isIos ? AppleMobPreview : AndroidMobPreview
                  }
                  className="img-fluid"
                  alt="speaker"
                />
              </div>
              <div
                className={`push-newnotifications ${
                  notificationData.isIos ? " " : "push-newnotificationsandroid"
                }`}
              >
                <div className="d-flex align-items-center justify-content-between">
                  {notificationData.isIos ? (
                    <>
                      <div className="d-flex align-items-center justify-content-start">
                        <img
                          src={userIcon}
                          width="28px"
                          height="28px"
                          alt="Appicon"
                          className="rounded"
                        />
                      </div>

                      <div className="iosnotify-time">
                        <div className="newnotification-title">
                          <h2>
                            {notificationData.title
                              ? notificationData.title
                              : "Enter a title here"}
                          </h2>
                          <p>
                            {notificationData.message
                              ? notificationData.message
                              : "Compose your message here"}
                          </p>
                        </div>
                        <div className="text-end">
                          <p className="schedulestatus">
                            {!notificationData.deliverySchedule
                              ? "scheduled"
                              : "now"}
                          </p>
                          {uploadedImage ? (
                            <img
                              className="message-offerimg"
                              src={uploadedImage}
                              alt="uploaded ic"
                            />
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="d-flex align-items-center justify-content-start android-pushnotificationmsg">
                        <img
                          src={userIcon}
                          width="14px"
                          height="14px"
                          alt="Appicon"
                          className="rounded"
                        />
                        <h4 className="pushNotification-app">
                          {localStorage.getItem("Appname")}
                        </h4>
                        {/* <img src={dotIc} alt="dot" /> */}
                        <div className="notification-dot-icon"></div>
                        <div className="newnotify-time">
                          <p>
                            {!notificationData.deliverySchedule
                              ? "scheduled"
                              : "now"}
                          </p>
                        </div>
                      </div>
                      <div className="pushmsg-aerodown">
                        <img src={upArrIc} alt="up" />
                      </div>
                    </>
                  )}
                </div>
                {!notificationData.isIos ? (
                  <div className="newnotification-title mt-2">
                    <div className="d-flex justify-content-between">
                      <div>
                        <h2>
                          {notificationData.title
                            ? notificationData.title
                            : "Enter a title here"}
                        </h2>
                        <p>
                          {notificationData.message
                            ? notificationData.message
                            : "Compose your message here"}
                        </p>
                      </div>
                      <div>
                        {uploadedImage ? (
                          <img
                            className="message-offerimg"
                            src={uploadedImage}
                            alt="uploaded ic"
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="newplatswitch notificationplat">
            <ul
              id="myTabs"
              className="nav nav-pills nav-justified  d-flex justify-content-center"
              role="tablist"
              data-tabs="tabs"
            >
              <li
                className={`ios-icontochange ${
                  notificationData.isIos ? "techselect" : "technotselect"
                }`}
                onClick={() =>
                  setNotificationData({
                    isIos: !notificationData.isIos,
                  })
                }
              >
                <AppleIcon />
              </li>
              <li
                className={`android-icontochange ${
                  notificationData.isIos ? "technotselect" : "techselect"
                }`}
                onClick={() =>
                  setNotificationData({
                    isIos: !notificationData.isIos,
                  })
                }
              >
                <AndroidIcon />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationPreview;
