import React, {useState, useEffect} from "react";
import "./assets/css/common.css";


const AgencyBlock = (props) =>{

    useEffect(()=>
    {

        document.title = "404";

    }, []);

    return (
    <div className="org-module">

        <div className="agency-errorpage">
            <div>
                <h1>404 - Page unavailable</h1>
                <h3>This page is currently unavailable. Please contact your domain administrator for assistance.</h3>
                <img className="agency-error-webimg" src={require("../Agency/assets/images/404web.webp") .default} alt="customers"/>
                <img className="agency-error-mobimg" src={require("../Agency/assets/images/404mobile.webp") .default} alt="customers"/>
            </div>
        </div>

    </div>

    );
}

export default AgencyBlock;