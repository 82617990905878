import React from "react";
import { Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";

const TeamLimitReach = ({limitreachpop, btnCrossLimitReach, plandata}) =>{

    const closeLimitReachReachPopup = (e) =>{

        btnCrossLimitReach(false);
    }

    return (
    <Modal isOpen={limitreachpop} onClick={closeLimitReachReachPopup} style={{ maxWidth: "480px", maxHeight: "270px" }} className="activate-customer-popup" centered >

        <ModalBody>

        <div className="crossmodal mt-1" onClick={closeLimitReachReachPopup} >
            <img
                src={require("../assets/images/cross.png").default}
            />
        </div>

        <div>
            <div className="confirm-org-body">
                <h4>User limit exceeded</h4>
                <h5>You have reached the maximum number of team members allowed in your current plan.</h5>
                
            </div>
            <div className="deactivate-customer-footer">

                <div className="team-limit-btn-cancel activate-customer-cancel-btn" onClick={closeLimitReachReachPopup} >
                {
                        localStorage.getItem("isUpgradeVisibleInAgency") ==0 ?"Okay":"Cancel" }
                </div>
               
                    {
                        localStorage.getItem("isUpgradeVisibleInAgency")!=0 &&
                    
                <a href={`/agency/pricing/`+btoa(localStorage.getItem("agency_id"))}>
                <div className="team-limit-btn" id="btncustomrtactiveid">
                    Upgrade
                 </div>
                 </a> }
                
                
               

                

            </div>

        </div>

        </ModalBody>

       
    </Modal>
    );
}

export default TeamLimitReach;