import React, { useState, useEffect, useReducer } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
  } from "reactstrap";
  import ProductDelete from "../../../../assets/images/dashboard/productDelete.svg";
  import UploadProduct from "../../../../assets/images/dashboard/UploadProduct.svg";
  import UploadTag from "../../../../assets/images/dashboard/UploadTag.svg";
  import UploadTagnotSelected from "../../../../assets/images/dashboard/UploadTagnotSelected.svg";
  import Linktab from "../../../../assets/images/dashboard/Linktab.svg"
  import LinktabNotselected from "../../../../assets/images/dashboard/LinkTagNotselected.svg"
  import CreateImage from "../../../../assets/images/dashboard/CreateImage.svg"
  import "./AddProductPopup.css";


  const AddProductPopup = ({deleteAccount, userdelete}) => {

    return (
      <div>
  
  <Modal
  isOpen={userdelete}
  toggle={deleteAccount}
className="addNewproductPopup modal-dialog-centered"
>
      <ModalHeader >
      <div className="productPopupHead">
      
      <img
        src={
          require("../../../../assets/images/dashboard/goBackicon.png")
            .default
        }
        
        alt="back"
        className="gobackProductScreen"
      />
      <h1 className="upload-media-title">Upload media</h1>
      <div className="productPopup-headright">
        <p className="selecttab selectedTab"><img  className="headerUploadtab" src={UploadProduct}/><span className="tabName">Upload</span></p>
        <p className="selecttab"><img  className="headerUploadtab" src={CreateImage}/><span className="tabName">Create</span></p>
      </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="productPopup-bodyMaindiv">
          <div className="productPopup-bodyLeft">
               <h6 className="mob-upload-media-title">Upload media</h6>


            <div className="productUpload-swich-delete">
             <div className="mob-product-switch">
            <div className="image-uploadfromfile divSelected">
            <img  className="uploadfromfileimg" src={UploadTagnotSelected}/>
            <img  className="uploadfromfileimg" src={UploadTag}/>
              <span>Upload</span>
            </div>
            <div className="image-uploadfromLink">
            <img  className="uploadfromLinkimg" src={Linktab}/>
            <img  className="uploadfromLinkimg" src={LinktabNotselected}/>
            <span>Link</span> </div>
            </div>
            <img  className="deleteIcon" src={ProductDelete}/>
            </div>
            
          </div>
          <div className="productPopup-bodyRight">

            <div className="productUpload-mainDiv">
            
            <div className="uploaded-productImg">
                <div className="upload-Product-img">
                <img  className="" src={UploadProduct}/>
                </div>

                <div className="Uploaded-product-img">
                  <img  className="deleteIcon" src={ProductDelete}/>
                  {/* <img  className="productImg" src={require("../../../../assets/images/dashboard/offer-image.png") .default}/> */}
                  </div>

                <div className="Uploaded-product-img">
                <img  className="deleteIcon" src={ProductDelete}/>
                {/* <img  className="productImg" src={require("../../../../assets/images/dashboard/p1.png") .default}/> */}
                </div>

                <div className="Uploaded-product-img"><img  className="deleteIcon" src={ProductDelete}/></div>
                <div className="Uploaded-product-img"><img  className="deleteIcon" src={ProductDelete}/></div>
                <div className="Uploaded-product-img"><img  className="deleteIcon" src={ProductDelete}/></div>
                <div className="Uploaded-product-img"><img  className="deleteIcon" src={ProductDelete}/></div>
                <div className="Uploaded-product-img"><img  className="deleteIcon" src={ProductDelete}/></div>
                <div className="Uploaded-product-img"><img  className="deleteIcon" src={ProductDelete}/></div>
                <div className="Uploaded-product-img"><img  className="deleteIcon" src={ProductDelete}/></div>
                
                </div>

                     <section className="productUpload-nodata d-none">
                
                                 <div
                                    className="product-drags-drops"
                                    
                                  >
                                  <input
                                      type="file"
                                      className="d-none"
                                    />
                                    <div className="product-dragupload-image">

                                      <div className="product-dragupload-imagediv">
                                      
                                        <svg xmlns="http://www.w3.org/2000/svg" width="33.694" height="24.749" viewBox="0 0 33.694 24.749">
                                          <g id="_172620_upload_icon" data-name="172620_upload_icon" transform="translate(0 -4.546)">
                                            <g id="Group_38467" data-name="Group 38467" transform="translate(1 5.546)">
                                              <path id="Path_102634" data-name="Path 102634" d="M27.477,24.994a5.217,5.217,0,1,0,0-10.435l-.038,0a8.015,8.015,0,0,0-15.473-3.772,3.967,3.967,0,0,0-6.775,2.8c0,.056.007.11.009.166A5.722,5.722,0,0,0,6.725,24.994" transform="translate(-1 -5.546)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" />
                                              <path id="Path_102635" data-name="Path 102635" d="M20,28.419,25.419,23l5.419,5.419" transform="translate(-9.572 -13.456)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" />
                                              <line id="Line_394" data-name="Line 394" y1="12.986" transform="translate(15.847 9.763)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" />
                                            </g>
                                          </g>
                                        </svg>
                                      </div>

                                      <p className="image-drag-type">
                                        Drag here to upload,{" "} or{" "}
                                        <span className="image-drag-typebroused">browse</span>
                                      </p>
                                    
                                        <h6 className="image-resolution-type">
                                        Supported extensions - PNG, SVG or ICO, minimum resolution - 128 (H) x 128 (V) pixels, shape - square , maximum size - 2MB
                                        </h6>
                                    
                                    </div>
                                  </div>
                                  
                       </section>
<div className="mobDoneError">
  <p className="productadd-error"> <img className="alertIcon" src={require("../../../../assets/images/dashboard/alert.png") .default}/>Maximum 10 images allowed.</p>
                       <button className="productadd-errorButton">Done</button>

                       </div>

      
            </div>



        <div className="productLink-uploaddiv d-none">
          <div className="productLink-uploadMaindiv">
            <p className="productLink-head">Paste link</p>
            <div className="productLink-inputdiv">
              <input type="text" className="product-input"
                placeholder="https://www.example.com/background.jpg"/>
                <img className="img-fluid product-inputimg" src={require("../../../../assets/images/dashboard/popupinputlink.png") .default}/>
            </div>
            <p className="productLink-description">Supported extensions - PNG, SVG or ICO, minimum resolution - 128 (H) x 128 (V) pixels, shape - square , maximum size - 2MB</p>
          </div>
        </div>

          </div>
        </div>
      
      </ModalBody>
      <ModalFooter>
       
          <button className="propductPopup-cancelbtn">Cancel</button>
          <div className="propductPopup-footerRight d-flex">
          <p className="itomCountText">5 items</p>
          <p className="errortext"> <img className="alertIcon" src={require("../../../../assets/images/dashboard/alert.png") .default}/>Maximum 10 images allowed.</p>
          <button className="propductPopup-donebtn propductPopup-donebtn-enable">Done</button>
          </div>
        
      </ModalFooter>
    </Modal>
    </div>
  )
}

export default AddProductPopup