import React, { useState } from "react";
import { Solver, Color } from "../../BottomBar/Iconcolor";
import Styles from "../../BottomBar/Bottom.module.css";
import { useEffect } from "react";
const BottomBarPreview = ({ bottomMenuData }) => {
  const [filter, setFilter] = useState(
    "invert(32%) sepia(25%) saturate(4935%) hue-rotate(210deg) brightness(105%) contrast(96%)"
  );
  const [selectedfilter, setSelectedfilter] = useState(
    "invert(32%) sepia(25%) saturate(4935%) hue-rotate(210deg) brightness(105%) contrast(96%)"
  );
useEffect(()=>{
 //Change icon color
 var rgb = hexToRgb(
  bottomMenuData.bottom_menu_style.bottom_menu_icon_colour_object.portal_data
);

if (
  rgb !== undefined &&
  rgb === null &&
  bottomMenuData.bottom_menu_style.bottom_menu_icon_colour_object
    .portal_data != ""
) {
  const colorvalue =
    bottomMenuData.bottom_menu_style.bottom_menu_icon_colour_object.portal_data
      .match(/\d+/g)
      .map(Number);
  rgb = [];
  rgb[0] = colorvalue[0];
  rgb[1] = colorvalue[1];
  rgb[2] = colorvalue[2];
} else if (
  rgb !== undefined &&
  bottomMenuData.bottom_menu_style.bottom_menu_icon_colour_object
    .portal_data != "" &&
  rgb.length !== 3
) {
  return;
}

if (
  bottomMenuData.bottom_menu_style.bottom_menu_icon_colour_object
    .portal_data !== undefined &&
  bottomMenuData.bottom_menu_style.bottom_menu_icon_colour_object
    .portal_data != ""
) {
  const color = new Color(rgb[0], rgb[1], rgb[2]);
  const solver = new Solver(color);
  const result = solver.solve();
  setFilter(result.filter);
}

if (
  bottomMenuData.bottom_menu_style.bottom_menu_selected_icon_colour_object
    .portal_data != ""
) {
  //change default icon color
  var rgb = hexToRgb(
    bottomMenuData.bottom_menu_style.bottom_menu_selected_icon_colour_object
      .portal_data
  );
  if (
    rgb === null &&
    bottomMenuData.bottom_menu_style.bottom_menu_selected_icon_colour_object
      .portal_data != ""
  ) {
    const colorvalue =
      bottomMenuData.bottom_menu_style.bottom_menu_selected_icon_colour_object.portal_data
        .match(/\d+/g)
        .map(Number);
    rgb = [];
    rgb[0] = colorvalue[0];
    rgb[1] = colorvalue[1];
    rgb[2] = colorvalue[2];
  } else if (
    rgb !== undefined &&
    rgb.length !== 3 &&
    bottomMenuData.bottom_menu_style.bottom_menu_selected_icon_colour_object
      .portal_data != ""
  ) {
    return;
  }
  if (
    bottomMenuData.bottom_menu_style.bottom_menu_selected_icon_colour_object
      .portal_data !== undefined &&
    bottomMenuData.bottom_menu_style.bottom_menu_selected_icon_colour_object
      .portal_data != ""
  ) {
    const color = new Color(rgb[0], rgb[1], rgb[2]);
    const solver = new Solver(color);
    const result = solver.solve();

    setSelectedfilter(result.filter);
  }
}
},[])
  const hexToRgb = (hex) => {
    if (hex !== undefined) {
      // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (m, r, g, b) => {
        return r + r + g + g + b + b;
      });

      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16),
          ]
        : null;
    }
  };

 
  return (
    <div className={`${Styles.mobile_parent} homescreen-bottom-bar-first-class`}>
      <div>
        {bottomMenuData.bottom_menu_items.length > 0 && (
          <div
            className={
              "d-flex align-items-center justify-content-center " +
              Styles.bottom_menu_bar +` homescreen-bottom-bar-second-class`
            }
            style={{
              background:
                bottomMenuData.bottom_menu_style.bottom_menu_bg_colour_object
                  .portal_data,
              position: "absolute",
              width: "100%",
              bottom: "0px",
              borderTop:
                bottomMenuData.bottom_menu_items.some(
                  (res) => res.status === 1
                ) === true &&
                bottomMenuData.bottom_menu_style
                  .bottom_menu_border_colour_object.portal_data != ""
                  ? `1px solid ${bottomMenuData.bottom_menu_style.bottom_menu_border_colour_object.portal_data}`
                  : null,
            }}
          >
            {bottomMenuData.bottom_menu_items
              .slice()
              .sort((x, y) => x.item_position - y.item_position)
              .map(
                (res, i) =>
                  res.status === 1 && (
                    <div
                      key={i}
                      className="ml-1 mr-1 d-flex align-items-center justify-content-center bottom-bar-icons"
                      style={{ width: "19%", height: "60px" }}
                    >
                      <div className="w-100">
                        {res.is_default === 1 ? (
                          <>
                            {res.item_icon.indexOf(
                              "cdn-icons-png.flaticon.com"
                            ) > -1 ? (
                              <>
                                <img
                                  className="img-fluid 1"
                                  style={{
                                    filter:
                                      res.item_icon_flaticon_color ===
                                        "black" &&
                                      bottomMenuData.bottom_menu_style
                                        .bottom_menu_selected_icon_colour_object
                                        .portal_data !== ""
                                        ? selectedfilter
                                        : null,
                                  }}
                                  src={res.item_icon}
                                  flaticon={res.item_icon_flaticon_color}
                                  alt="icon"
                                  string="1"
                                  width="39"
                                />
                                {bottomMenuData.bottom_menu_style
                                  .bottom_menu_show_text === 1 && (
                                  <p
                                    className="mt-1"
                                    style={{
                                      color:
                                        bottomMenuData.bottom_menu_style
                                          .bottom_menu_selected_text_colour_object
                                          .portal_data !== ""
                                          ? bottomMenuData.bottom_menu_style
                                              .bottom_menu_selected_text_colour_object
                                              .portal_data
                                          : null,
                                    }}
                                  >
                                    {res.item_name}
                                  </p>
                                )}
                              </>
                            ) : res.item_icon_newtype !== undefined &&
                              res.item_icon_newtype === "flaticon" ? (
                              <>
                                <img
                                  className="img-fluid 2"
                                  style={{
                                    filter:
                                      res.item_icon_flaticon_color ===
                                        "black" &&
                                      bottomMenuData.bottom_menu_style
                                        .bottom_menu_selected_icon_colour_object
                                        .portal_data !== ""
                                        ? selectedfilter
                                        : null,
                                  }}
                                  src={
                                    process.env.REACT_APP_Image_Path +
                                    parseInt(localStorage.getItem("user_id")) +
                                    "/" +
                                    parseInt(localStorage.getItem("Appid")) +
                                    process.env.REACT_APP_NEW_ICON_PATH +
                                    res.item_icon
                                  }
                                  flaticon={res.item_icon_flaticon_color}
                                  alt="icon"
                                  string="1"
                                  width="39"
                                />
                                {bottomMenuData.bottom_menu_style
                                  .bottom_menu_show_text === 1 && (
                                  <p
                                    className="mt-1"
                                    style={{
                                      color:
                                        bottomMenuData.bottom_menu_style
                                          .bottom_menu_selected_text_colour_object
                                          .portal_data !== ""
                                          ? bottomMenuData.bottom_menu_style
                                              .bottom_menu_selected_text_colour_object
                                              .portal_data
                                          : null,
                                    }}
                                  >
                                    {res.item_name}
                                  </p>
                                )}
                              </>
                            ) : (
                              <>
                                <img
                                  className="img-fluid 3"
                                  style={{
                                    filter:
                                      (res.item_icon_flaticon_color ===
                                        "black" ||
                                        res.item_icon_flaticon_color ===
                                          undefined) &&
                                      bottomMenuData.bottom_menu_style
                                        .bottom_menu_selected_icon_colour_object
                                        .portal_data !== ""
                                        ? selectedfilter
                                        : null,
                                  }}
                                  src={
                                    process.env.REACT_APP_Image_Path +
                                    (() => {
                                      switch (res.item_icon_type) {
                                        case "light":
                                          return process.env
                                            .REACT_APP_light_path;
                                        case "bold":
                                          return process.env
                                            .REACT_APP_bold_path;
                                        case "regular":
                                          return process.env
                                            .REACT_APP_regular_path;
                                        default:
                                          return process.env
                                            .REACT_APP_light_path;
                                      }
                                    })() +
                                    res.item_icon
                                  }
                                  flaticon={res.item_icon_flaticon_color}
                                  alt={res.item_icon}
                                  width="39"
                                />
                                {bottomMenuData.bottom_menu_style
                                  .bottom_menu_show_text === 1 && (
                                  <p
                                    className="mt-1"
                                    style={{
                                      color:
                                        bottomMenuData.bottom_menu_style
                                          .bottom_menu_selected_text_colour_object
                                          .portal_data !== ""
                                          ? bottomMenuData.bottom_menu_style
                                              .bottom_menu_selected_text_colour_object
                                              .portal_data
                                          : "#000000",
                                    }}
                                  >
                                    {res.item_name}
                                  </p>
                                )}
                              </>
                            )}
                          </>
                        ) : res.item_icon.indexOf(
                            "cdn-icons-png.flaticon.com"
                          ) > -1 ? (
                          <>
                            <img
                              className="img-fluid 4"
                              style={{
                                filter:
                                  res.item_icon_flaticon_color === "black" &&
                                  bottomMenuData.bottom_menu_style
                                    .bottom_menu_icon_colour_object
                                    .portal_data !== ""
                                    ? filter
                                    : null,
                              }}
                              src={res.item_icon}
                              flaticon={res.item_icon_flaticon_color}
                              alt="icon"
                              string="1"
                              width="39"
                            />
                            {bottomMenuData.bottom_menu_style
                              .bottom_menu_show_text === 1 && (
                              <p
                                className="mt-1"
                                style={{
                                  color:
                                    bottomMenuData.bottom_menu_style
                                      .bottom_menu_text_colour_object
                                      .portal_data !== ""
                                      ? bottomMenuData.bottom_menu_style
                                          .bottom_menu_text_colour_object
                                          .portal_data
                                      : "#000000",
                                }}
                              >
                                {res.item_name}
                              </p>
                            )}
                          </>
                        ) : res.item_icon_newtype !== undefined &&
                          res.item_icon_newtype === "flaticon" ? (
                          <>
                            <img
                              className="img-fluid 5"
                              style={{
                                filter:
                                  res.item_icon_flaticon_color === "black" &&
                                  bottomMenuData.bottom_menu_style
                                    .bottom_menu_icon_colour_object
                                    .portal_data !== ""
                                    ? filter
                                    : null,
                              }}
                              src={
                                process.env.REACT_APP_Image_Path +
                                parseInt(localStorage.getItem("user_id")) +
                                "/" +
                                parseInt(localStorage.getItem("Appid")) +
                                process.env.REACT_APP_NEW_ICON_PATH +
                                res.item_icon
                              }
                              flaticon={res.item_icon_flaticon_color}
                              alt="icon"
                              string="1"
                              width="39"
                            />
                            {bottomMenuData.bottom_menu_style
                              .bottom_menu_show_text === 1 && (
                              <p
                                className="mt-1"
                                style={{
                                  color:
                                    bottomMenuData.bottom_menu_style
                                      .bottom_menu_text_colour_object
                                      .portal_data !== ""
                                      ? bottomMenuData.bottom_menu_style
                                          .bottom_menu_text_colour_object
                                          .portal_data
                                      : "#000000",
                                }}
                              >
                                {res.item_name}
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <img
                              className="img-fluid 6"
                              style={{
                                filter:
                                  (res.item_icon_flaticon_color === "black" ||
                                    res.item_icon_flaticon_color ===
                                      undefined) &&
                                  bottomMenuData.bottom_menu_style
                                    .bottom_menu_icon_colour_object
                                    .portal_data !== ""
                                    ? filter
                                    : null,
                              }}
                              src={
                                process.env.REACT_APP_Image_Path +
                                (() => {
                                  switch (res.item_icon_type) {
                                    case "light":
                                      return process.env.REACT_APP_light_path;
                                    case "bold":
                                      return process.env.REACT_APP_bold_path;
                                    case "regular":
                                      return process.env.REACT_APP_regular_path;
                                    default:
                                      return process.env.REACT_APP_light_path;
                                  }
                                })() +
                                res.item_icon
                              }
                              alt="icon"
                              flaticon={res.item_icon_flaticon_color}
                              string="2"
                              width="39"
                            />
                            {bottomMenuData.bottom_menu_style
                              .bottom_menu_show_text === 1 && (
                              <p
                                className="mt-1"
                                style={{
                                  color:
                                    bottomMenuData.bottom_menu_style
                                      .bottom_menu_text_colour_object
                                      .portal_data !== ""
                                      ? bottomMenuData.bottom_menu_style
                                          .bottom_menu_text_colour_object
                                          .portal_data
                                      : "#000000",
                                }}
                              >
                                {res.item_name}
                              </p>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )
              )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BottomBarPreview;
