import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styles from "./cmsBuilderstyle.css";
import Header from "./../Header";
import Leftpanel from "./../Leftpanel";
import imagebackground from "./../../assets/images/dashboard/cms/uploadbackground.png";
import { 
	Spinner, 
	UncontrolledDropdown,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from "reactstrap";
import ReactTags from 'react-tag-autocomplete';
import { cmsDeleteAtrValApi, cmsGetAtrApi, cmsGetSpecificAtrApi, cmsSaveAtrValApi } from "../../Api/CmsApi/Cmsapi";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const AttributesListingChild = ({
  
}) => {

    const [openAtrValPopup, setOpenAtrValPopup] = useState(false);
  
    
    
    const [appRightPanelExpand, setAppRightPanelExpand] = useState( localStorage.getItem("appSideBarCollapse") === true ||
    localStorage.getItem("appSideBarCollapse") === "true"
    ? true
    : false);
    const [bottomSpinner, setBottomSpinner] = useState(false);
    const [btmSpinnerforAtrListing, setBtmSpinnerforAtrListing] = useState(false);
    const [spinner, setSpinner] = useState(true);
    const [spinnerforAtrListing, setSpinnerForAtrListing] = useState(true);
    const [atrValArr, setAtrValArr] = useState([]);
    const [atrName, setAtrName] = useState("");
    const [atrIcon, setAtrIcon] = useState("");
    const [createAtrPopUpSpin, setCreateAtrPopUpSpin] = useState(false);
    const [searchVal, setSearchVal] = useState("");
    const [isTrashed, setIsTrashed] = useState(false);
    const [deletedIdForAtrVal, setDeleteIdForAtrVal] = useState("");
    const [isSaved, setIsSaved] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [isLoader, setIsLoader] = useState("d-none");
    
    const [atrValIsLoader, setAtrValIsLoader] = useState("d-none");
    const [loaderimage, setLoaderimage] = useState( require("../../assets/images/signup/Loader_GIF.gif").default );
    const [atrValId, setAtrValId] = useState("");
    const [atrValCreateBtntext, setAtrValCreateBtntext] = useState("Create");
    const [atrValTobeAdd, setAtrValTobeAdd] = useState([]);
    const [valNameTobeEdit, setValNameTobeEdit] = useState("");
    const [atrListingData,setAtrListingData] = useState([]);

    const [pageForAtr, setPageForAtr] = useState(1);
    const [paginationSpinnerForAtr , setPaginationSpinnerForAtr] = useState(false);
    const [hitNextPaginationForAtr , setHitNextPaginationForAtr] = useState(false);
    const [atrSubData,setAtrSubData] = useState([]);

    let { atrId } = useParams("atrId");




    const reactTags = useRef();

    useEffect(() => {
        if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
        {
        document.title = "Post attribute values | AppMySite";
          
        }
        else
        {
        document.title = "Post attribute values |"+ ' ' +localStorage.getItem('agencyname');
        }
        cmsGetSpecificAtrApi(atrId,setAtrName,setAtrIcon,setAtrValArr,setSpinner,setBottomSpinner);

        
       let limit = 99;
       let pageNo = 1;
       setPageForAtr(pageNo);

        cmsGetAtrApi(
          setSpinnerForAtrListing,
          setAtrListingData,
          setBtmSpinnerforAtrListing,
          "atr",
          false,
          false,
          limit,
          pageNo,
          atrListingData,
          setHitNextPaginationForAtr,
          setPaginationSpinnerForAtr,
          setAtrSubData,
        );
      }, [isDeleted,isSaved])


    const handleAtrValPopUp = (e,from) =>{
        if(from==="cancel"){
            setAtrValId("");
          
          }
       
        setOpenAtrValPopup(!openAtrValPopup)
    }

    const handleOpenEditAtrValPopUp = (e,atrValId,valName) =>{
        setAtrValId(atrValId);
        setValNameTobeEdit(valName);
        setAtrValTobeAdd([]);
        setOpenAtrValPopup(!openAtrValPopup);
        // setCreateAtrPopUpSpin(true);
       
      
    //   cmsGetSpecificAtrApi(atrId,setAtrName,setAtrIcon,setAtrValArr,setCreateAtrPopUpSpin);
      }
     const handleEditValChange = (e) =>{
       setValNameTobeEdit(e.target.value);
      }
  
    const handleRightPanelExpand = (value) => {

        setAppRightPanelExpand(value);
    }

    const handleOpenDeleteValPopUp = (e, from, id) => {
        setIsTrashed(!isTrashed);
        if (from === "add") {
            setDeleteIdForAtrVal(id);
        }
    };
    
    //Trash Post
    const handleTrashedAtr = (e) => {
        setIsTrashed(!isTrashed);
        setSpinner(!spinner);
        const params = {attribute_id:atrId , attribute_term_id: deletedIdForAtrVal ,   app_id :   parseInt(localStorage.getItem("Appid"))
        };
        cmsDeleteAtrValApi(params,setIsDeleted,isDeleted);
       
    
        
    
    };
    const handleSearchVal = (e) =>{
        setSearchVal(e.target.value);
        }

        const handleCreateAtrVal = (e) =>{
            setAtrValCreateBtntext("");
            setAtrValIsLoader("d-block");
            const params = {
                app_id: parseInt(localStorage.getItem("Appid")),
                attribute_id:atrId?atrId: null, //for edit , otherwise null
           
                attribute_terms:atrValId?[{id:atrValId,name:valNameTobeEdit}]   : atrValTobeAdd,
            }
            cmsSaveAtrValApi(params,
              setAtrValCreateBtntext,
              setAtrValIsLoader,
              setOpenAtrValPopup,
              setBottomSpinner,
              setIsSaved,
              isSaved,
              setAtrValId,
              setAtrValTobeAdd,
              atrValId,
              );
        }

        const handleAttValDelete =  (i) =>{
		
		

            const newArr = [...atrValTobeAdd];
    
            newArr.splice(i, 1);
    
            setAtrValTobeAdd(newArr);    	
    
        }
        
        const  handleAttValAdd = (valData) => {
        
          if(valData.name.trim()){
    
        
            setAtrValTobeAdd(() => 
            {
                // let newArr = { "name": (valData.name).charAt(0).toUpperCase() + (valData.name).slice(1),"id":null};
                let newArr = { "name": valData.name.substring(0,60),"id":null};
                
                return [...atrValTobeAdd, newArr];
            });
    
            // setArrTagIds(() => 
            // {
            //     return [...arrtagids, ""];
            // });
          }
            
        }


  return (
    <>
        <div>
        <Header customclass={"header-responsive-new"} />
        </div>

        <section className="dasboard_page mobile-display mt-4 mt-md-0 cms-module">
      <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
      <div className={`right-panel-gap ${(localStorage.getItem("appSideBarCollapse") !== undefined &&
                      localStorage.getItem("appSideBarCollapse") !== null&&
                      localStorage.getItem("appSideBarCollapse") === "true") ?
                          " right-panel-gap-expand": ""}`}>




            <div className="post-heading category-heading">
                <h1>
                {
                        atrIcon?
                    <img style={{
                    width:"24px",
                    height:"24px",
                    marginRight: "8px",
                }} className="img-fluid"
                  src={process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/"+localStorage.getItem("Appid")+process.env.REACT_APP_NEW_ICON_PATH +atrIcon} alt="icon"/>
                   :
                            <img style={{
                              marginRight: "8px",
                          }} src={imagebackground} className="img-fluid"/>
                          }
                  {atrName}
                  </h1>

                <div 
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                }}  
                onClick={(e)=>handleAtrValPopUp(e,"cancel")}> <img className="mr-2" src={require("../../assets/images/dashboard/plus.png").default}/>{" "}
                    <p 
                    style={{
                    marginBottom:"0px",
                    marginTop:"0px",
                    fontSize:"15px",
                    color:"#007bff"
                }} >Create</p>
                </div>
                
            </div>

            <div className="pageshead attribute-page-head">

         
              
                <div>
                {
                !spinner && 
                <h1>    
                  {  

                atrIcon ?
                
                <img style={{
                    width:"24px",
                    height:"24px",
                }} 
                className="img-fluid"
                src={process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/"+localStorage.getItem("Appid")+process.env.REACT_APP_NEW_ICON_PATH +atrIcon} alt="icon"/>
                             
                  
                  :
                  <img src={imagebackground} className="img-fluid"/>
                  }
                
                             
                  {atrName}
                </h1> }
                </div>
              

              <div className="createpage allpages">

              {
               atrValArr && atrValArr.length > 0 &&
               <div className="cms-pages-search">
                  <input placeholder="Search" type="text" value={searchVal} onChange={(e)=>handleSearchVal(e)}/>
                  <img src={require("../../assets/images/dashboard/cms/lens.png").default} />
                </div>}


                
                <div style={{
                  cursor:"pointer"
                }}>
                  <p onClick={(e)=>handleAtrValPopUp(e,"cancel")}
                    className="createpage d-none d-xl-flex"
                   style={{backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                  >
                    <img
                      className="mr-2"
                      src={
                        require("../../assets/images/dashboard/cms/plus.png")
                          .default
                      }
                    />{" "}
                    <span>
                      Create
                      </span>
                  </p>

                </div>
              </div>
            </div>

           

            <div className="attributes-width" style={{position : "relative"}}>
            {spinner === true ? 
										(
											<div className="newspinner">
												<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"  style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
													<g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
														<circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
															<animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
															</animateTransform>
														</circle>
													</g>
													<g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
														<circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
															<animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
															</animateTransform>
														</circle>
													</g>
													<g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
														<circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
															<animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
															</animateTransform>
														</circle>
													</g>
													<g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
														<circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
															<animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
															</animateTransform>
														</circle>
													</g>
												</svg>
											</div>
										) :
                <>
                <div className="attributes-listing-left" style={{
                  width:atrValArr &&atrValArr.length > 0?"50%":"100%"
                }}>
                {
                        atrValArr &&atrValArr.length > 0 &&  <div className="attributes-list-title">
                        <h2>Value</h2>
                        <h5><span>Posts</span> <span>Edit, Delete</span></h5>
                    </div> }
                    {
                        atrValArr &&atrValArr.length > 0 ?
                        <>
                        <>
                        {
                        atrValArr&&atrValArr.filter((res) => res.attribute_term_name.toLowerCase().includes(searchVal.toLowerCase()) ).map((row,indx)=>{
                        return(
                            <div className="attribute-entry">
                  
                      <div className="attribute-summry">
                       
                        <div className="attribute attribute-child">
                            <h2>{row.attribute_term_name}</h2>
                           
                        </div>
                      </div>

                      <div className="attribute-sub">
                        <div className="attribute-related-post">
                            <p>{row.post_count}</p>
                        </div>

                        <div className="attribute-action">
                            <img onClick={(e)=>handleOpenEditAtrValPopUp(e,row._id,row.attribute_term_name)} src={require("../../assets/images/dashboard/cms/edit.png").default} className="img-fluid"/>
                            <img onClick={(e)=>handleOpenDeleteValPopUp(e,"add",row._id)} src={require("../../assets/images/dashboard/cms/delete.png").default} className="img-fluid"/>
                        </div>
                      </div>

                    </div>
                        )
                        })}
                        </>
                          {bottomSpinner && (

                            <div className="form-group" style={{ background: "none" , margin:"auto" }}>
                                          
                            <svg xmlns="http://www.w3.org/2000/svg" 
                              xlink="http://www.w3.org/1999/xlink" 
                              style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
                              width="200px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                              <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                              <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                              <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                              style={{animationplaystate: "running", animationdelay: "0s"}}>
                              </animateTransform>
                              </circle>
                              </g>
                              <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                              <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
                              <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                              style={{animationplaystate: "running", animationdelay: "0s"}}>
                              </animateTransform>
                              </circle>
                              </g>
                              <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                              <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
                              <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                              style={{animationplaystate: "running", animationdelay: "0s"}}>

                              </animateTransform>
                              </circle>
                              </g>
                              <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                              <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
                              <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                              style={{animationplaystate: "running", animationdelay: "0s"}}>

                              </animateTransform>
                              </circle>
                              </g>
                            </svg>
                            </div>
                                      )}
                         </>
                          : 
                          bottomSpinner?
                          <div className="newspinner">
                          <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"  style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                            <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                              <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                </animateTransform>
                              </circle>
                            </g>
                            <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                              <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                </animateTransform>
                              </circle>
                            </g>
                            <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                              <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                </animateTransform>
                              </circle>
                            </g>
                            <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                              <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                </animateTransform>
                              </circle>
                            </g>
                          </svg>
                          </div>             :
                            
                            <div className="no-attributes">
                            <div>
                            <img src={require("../../assets/images/dashboard/cms/no-attributes.png").default} />
                            <p>Nothing here at the moment. Come back later?</p>
                            </div>
                        </div>
                            }
                    


                </div>
                {
                atrValArr && atrValArr.length > 0  &&
                <div className="attributes-listing-right">
                    {/* <div className="attributes-preview-screen">

                      <div className="attributes-preview-header">
                        <div className="attributes-preview-header-left">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14.705" height="12.513" viewBox="0 0 14.705 12.513">
                            <g id="Group_38608" data-name="Group 38608" transform="translate(0.707 0.354)">
                            <g id="Group_2097" data-name="Group 2097" transform="translate(0)">
                            <path id="Path_6037" data-name="Path 6037" d="M0,5.9,5.9,0l5.9,5.9" transform="translate(0 11.806) rotate(-90)" fill="none" stroke="#333" stroke-width="1"/>
                            </g>
                            <path id="Path_9120" data-name="Path 9120" d="M-13838.828-23465.541h14" transform="translate(13838.826 23471.443)" fill="none" stroke="#333" stroke-width="1"/>
                            </g>
                            </svg>
                            <p>{atrName}</p>
                        </div>
                        <div className="attributes-preview-header-right">
                            <p>Clear</p>
                        </div>
                      </div>
                      <div className="attribute-preview-list" style={{height: "564px"}}>
                      {
                        atrValArr.filter((res) => res.attribute_term_name.toLowerCase().includes(searchVal.toLowerCase()) ).map((row,indx)=>{
                        return(

                    

                            <div>
                                <h4>{row.attribute_term_name}</h4>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <g id="Ellipse_3025" data-name="Ellipse 3025" fill="#fff" stroke="#a1a1a1" stroke-width="1">
                                    <circle cx="10" cy="10" r="10" stroke="none"/>
                                    <circle cx="10" cy="10" r="9.5" fill="none"/>
                                </g>
                                </svg>


                           

                            

                        </div>) })}
                        
                        </div>

                    </div> */}
                     <div className="attributes-preview-screen">
                    <div className="attributes-preview-header">
                        <div className="attributes-preview-header-left">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14.705" height="12.513" viewBox="0 0 14.705 12.513">
                            <g id="Group_38608" data-name="Group 38608" transform="translate(0.707 0.354)">
                            <g id="Group_2097" data-name="Group 2097" transform="translate(0)">
                            <path id="Path_6037" data-name="Path 6037" d="M0,5.9,5.9,0l5.9,5.9" transform="translate(0 11.806) rotate(-90)" fill="none" stroke="#333" stroke-width="1"/>
                            </g>
                            <path id="Path_9120" data-name="Path 9120" d="M-13838.828-23465.541h14" transform="translate(13838.826 23471.443)" fill="none" stroke="#333" stroke-width="1"/>
                            </g>
                            </svg>
                            <p>Filter</p>
                        </div>
                      
                      </div>
                      <div className="attribute-filter-body">
                        <div className="attribute-filter-body-left">
                          {
                           
                            <h1>{atrName}</h1>
                          }
                          
                          <div className="attribute-filter-body-left-parent">
                          {
                            atrListingData && atrListingData.length > 0  &&
                             atrListingData.map((row,indx)=>{
                            return(
                              atrId !== row._id &&
                            <p>{row.attribute_name}</p>
                            )})}
                          </div>
                        </div>
                        <div className="attribute-filter-body-right">
                          <div  className="attribute-filter-body-right-search">
                          <svg id="Group_39925" data-name="Group 39925" xmlns="http://www.w3.org/2000/svg" width="12.075" height="12.075" viewBox="0 0 12.075 12.075">
                            <path id="Path_103441" data-name="Path 103441" d="M5.1,1.073A4.025,4.025,0,1,1,1.073,5.1,4.029,4.029,0,0,1,5.1,1.073M5.1,0a5.1,5.1,0,1,0,5.1,5.1A5.1,5.1,0,0,0,5.1,0" fill="#b0b0b0"/>
                            <path id="Line_144" data-name="Line 144" d="M1.891,2.611-1.414-.695l.719-.719L2.611,1.891Z" transform="translate(9.464 9.464)" fill="#b0b0b0"/>
                          </svg> <span>Search</span>
                          </div>
                          <div className="attribute-filter-body-right-child">

                          {
                           ( atrValArr && atrValArr.length > 0 )
                           && 
                           <>
                           {
                            atrValArr && atrValArr.length > 0 &&
                            atrValArr.map((term)=>{
                             return(
                              <p>
                              <svg xmlns="http://www.w3.org/2000/svg" width="10.166" height="7.609" viewBox="0 0 10.166 7.609">
                                <path id="Path_106216" data-name="Path 106216" d="M-10008.486,1863.6l2.905,2.8,6.218-6.027" transform="translate(10009.007 -1859.83)" fill="none" stroke="#b0b0b0" stroke-width="1.5"/>
                              </svg><span>{term.attribute_term_name}</span>
                              </p>
                             )
                            })
                           }
                          
                           </>
                          }
                            
                           
                            
                          </div>
                        </div>
                      </div>
                      <div className="attribute-filter-body-bottom">
                        <button className="attribute-filter-body-clear">Clear</button>
                        <button className="attribute-filter-body-apply">Apply</button>
                      </div>
                </div>
                </div> }
                </>}
            </div>

            <div className="attributes-mobile">
            {spinner === true ? 
										(
											<div className="newspinner">
												<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"  style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
													<g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
														<circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
															<animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
															</animateTransform>
														</circle>
													</g>
													<g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
														<circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
															<animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
															</animateTransform>
														</circle>
													</g>
													<g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
														<circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
															<animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
															</animateTransform>
														</circle>
													</g>
													<g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
														<circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
															<animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
															</animateTransform>
														</circle>
													</g>
												</svg>
											</div>
										) :
              <>
            {
                        atrValArr && atrValArr.length>0 ?
                        <>
                        <>{
                        atrValArr.filter((res) => res.attribute_term_name.toLowerCase().includes(searchVal.toLowerCase()) ).map((row,indx)=>{
                          return(
                <div className="attributes-mobile-entry">
                    <h3>Value</h3>
                    <div className="attributes-mobile-entry-first">
                        <div>
                            <h4>{row.attribute_term_name}</h4>
                          
                        </div>
                       
                    </div>

                    <div className="attributes-mobile-entry-second">

                      <div>
                        <h4>Posts</h4>
                        <h5>{row.post_count}</h5>
                      </div>

                      <div className="attribute-action">
                            <img onClick={(e)=>handleOpenEditAtrValPopUp(e,row._id,row.attribute_term_name)} src={require("../../assets/images/dashboard/cms/edit.png").default} className="img-fluid"/>
                            <img onClick={(e)=>handleOpenDeleteValPopUp(e,"add",row._id)} src={require("../../assets/images/dashboard/cms/delete.png").default} className="img-fluid"/>
                        </div>

                    </div>

                </div>
                 )})
                 
                 }
                </>
                {bottomSpinner && (

                  <div className="form-group" style={{ background: "none" , margin:"auto" }}>
                                
                  <svg xmlns="http://www.w3.org/2000/svg" 
                    xlink="http://www.w3.org/1999/xlink" 
                    style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
                    width="200px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                    <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                    <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                    style={{animationplaystate: "running", animationdelay: "0s"}}>
                    </animateTransform>
                    </circle>
                    </g>
                    <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                    <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
                    <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                    style={{animationplaystate: "running", animationdelay: "0s"}}>
                    </animateTransform>
                    </circle>
                    </g>
                    <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                    <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
                    <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                    style={{animationplaystate: "running", animationdelay: "0s"}}>

                    </animateTransform>
                    </circle>
                    </g>
                    <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                    <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
                    <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                    style={{animationplaystate: "running", animationdelay: "0s"}}>

                    </animateTransform>
                    </circle>
                    </g>
                  </svg>
                  </div>
                            )}
                            </>
                :
					bottomSpinner?
					<div className="newspinner">
					<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"  style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
						<g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
							<circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
								<animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
								</animateTransform>
							</circle>
						</g>
						<g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
							<circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
								<animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
								</animateTransform>
							</circle>
						</g>
						<g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
							<circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
								<animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
								</animateTransform>
							</circle>
						</g>
						<g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
							<circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
								<animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
								</animateTransform>
							</circle>
						</g>
					</svg>
				</div>             :
						
            <div className="no-attributes">
            <div>
            <img src={require("../../assets/images/dashboard/cms/no-attributes.png").default} />
            <p>Nothing here at the moment. Come back later?</p>
            </div>
        </div>
                    }
                    </>}

            


            </div>




        </div>

        



            <Modal isOpen={openAtrValPopup} centered className="add-attribute-popup">
                <ModalHeader >												
                    <div className="security-crossmodal " onClick={(e)=>handleAtrValPopUp(e,"add")}>
                        <img src={require("../../assets/images/dashboard/cross.png").default} />
                    </div>
                </ModalHeader>
                <ModalBody>
                {createAtrPopUpSpin === true ? (
                  <div className="newspinner">
                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{
                        margin: "auto",
                        background: "rgb(241, 242, 243,0)",
                        display: "block",
                        shaperendering: "auto",
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }} width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
  <g transform="translate(20 50)" style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}>
    <circle cx="0" cy="0" r="6" fill="#e6261f" style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}>
      <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}></animateTransform>
    </circle>
  </g>
  <g transform="translate(40 50)" style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}>
    <circle cx="0" cy="0" r="6" fill="#f7d038" style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}>
      <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}></animateTransform>
    </circle>
  </g>
  <g transform="translate(60 50)" style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}>
    <circle cx="0" cy="0" r="6" fill="#49da9a" style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}>
      <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}></animateTransform>
    </circle>
  </g>
  <g transform="translate(80 50)" style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}>
    <circle cx="0" cy="0" r="6" fill="#4355db" style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}>
      <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}></animateTransform>
    </circle>
  </g>
                    </svg>
                  </div>
                ) : (
                    <div>
                        <div className="add-attribute-body">

                            <h2>{atrValId?"Edit value":"New value"}</h2>
                            <h3>Update your value name.</h3>

                            <h4>Value</h4>
                            {
                                atrValId ? <input className="add-attribute-input" maxLength={"60"} value={valNameTobeEdit} onChange={(e)=>handleEditValChange(e)}/>
                            :
                            <ReactTags tags={atrValTobeAdd} onDelete={handleAttValDelete} onAddition={handleAttValAdd} ref={reactTags} allowNew addOnBlur={true} 
                            placeholderText = ""
                             /> }

                        </div>
                        <div className="add-attribute-footer">
                            <button className="add-attribute-footer-btn1" onClick={(e)=>handleAtrValPopUp(e,"add")}>Cancel</button>
                            <button className={`add-attribute-footer-btn2  ${atrValId?(!valNameTobeEdit.trim())?"disabled-attribute-btn":""
                                :(!atrValTobeAdd|| atrValTobeAdd.length===0)?"disabled-attribute-btn":""}`}
                            onClick={(e)=>handleCreateAtrVal(e)}
                            disabled={atrValId?(!valNameTobeEdit.trim())?true:false
                                      :(!atrValTobeAdd|| atrValTobeAdd.length===0)?true:false} 
                            style={{
                                cursor:atrValId?(!valNameTobeEdit.trim())?"not-allowed":""
                                :(!atrValTobeAdd|| atrValTobeAdd.length===0)?"not-allowed":"",
                                backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                            }}
                            >
                                <div className={atrValIsLoader}>
                                    <div className="new-loader"></div>
                                </div>
                                {atrValId ?atrValIsLoader==="d-none"? "Save":"" : atrValCreateBtntext}
                            
                            </button>
                        </div>
                    </div>)}

                </ModalBody>
            </Modal>

        
            <Modal
          isOpen={isTrashed}
          centered
          className="delete-popup"
          toggle={(e) => handleOpenDeleteValPopUp(e, "cancel")}
         
          dataclassName="addonpopup"
        >
          <ModalBody className="">
          <div className="security-crossmodal " onClick={(e) => handleOpenDeleteValPopUp(e, "cancel")}>
              <img src={require("../../assets/images/dashboard/cross.png").default} />
          </div>


            <div>
              <h5>Delete this value?</h5>
              <h6>This action will permanently delete this value.</h6>

              <div className="d-flex align-items-center justify-content-between cms-delete-popup-footer">

                <div className="d-none d-xl-block grey-btn" onClick={(e) => handleOpenDeleteValPopUp(e, "cancel")}>
                  <p>Cancel</p>
                </div>

                <div onClick={(e) => handleTrashedAtr(e)}>
                  <div className="d-flex align-items-center justify-content-center h-100 w-100 red-btn">
                    <p>
                      <div className={isLoader}>
                        <img src={loaderimage} alt="loader" />
                      </div>
                      <div>Delete</div>
                    </p>
                  </div>
                </div>

				<div className="d-block d-xl-none grey-btn" onClick={(e) => handleOpenDeleteValPopUp(e, "cancel")}>
                  <p>Cancel</p>
                </div>

              </div>
            </div>
          </ModalBody>
        </Modal>
            </section>
    </>


                    
  );
};

export default AttributesListingChild;
