import React from 'react'

const libraryIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 16.75C2.53603 16.7495 2.09126 16.565 1.76318 16.2369C1.43511 15.9088 1.25053 15.464 1.25 15V3C1.25053 2.53603 1.43511 2.0912 1.76318 1.76312C2.09126 1.43505 2.53603 1.25053 3 1.25H15C15.464 1.25026 15.9091 1.43475 16.2372 1.76288C16.5653 2.09101 16.7497 2.53595 16.75 3V15C16.7495 15.464 16.5649 15.9088 16.2368 16.2369C15.9087 16.565 15.464 16.7495 15 16.75H3ZM2.75 3V13.39L5.19202 11.003C5.33322 10.8649 5.52317 10.788 5.7207 10.7891C5.91823 10.7902 6.10732 10.8692 6.24695 11.009L7.36597 12.128L12.326 7.16797C12.4666 7.02813 12.6567 6.94965 12.855 6.94965C13.0532 6.94965 13.2435 7.02813 13.384 7.16797L15.25 9.01599V3.00201C15.2497 2.93588 15.2232 2.87253 15.1764 2.82587C15.1295 2.7792 15.0661 2.75299 15 2.75299H3C2.93421 2.75299 2.87102 2.7789 2.82422 2.82513C2.77742 2.87137 2.75079 2.93422 2.75 3ZM5.25 6C5.25053 5.53603 5.43511 5.0912 5.76318 4.76312C6.09126 4.43505 6.53603 4.25053 7 4.25C7.46397 4.25053 7.90874 4.43505 8.23682 4.76312C8.56489 5.0912 8.74947 5.53603 8.75 6C8.74947 6.46397 8.56489 6.9088 8.23682 7.23688C7.90874 7.56495 7.46397 7.74947 7 7.75C6.53621 7.74947 6.0916 7.56504 5.76355 7.23718C5.4355 6.90932 5.25079 6.46379 5.25 6Z" fill=""/>
</svg>
  )
}

export default libraryIcon