import React from "react";
import "./../../assets/css/Dashboard.css";
import "./../../assets/css/profileleftpanel.css";
import "./../../assets/css/splash-screen.css";
import Header from "./../Header";
import Leftpanel from "./../Leftpanel";
import VistaPopup from "./VistaPopup";
import { Link} from "react-router-dom";
import NewImageGalleryPopup from "../NewImageGalleryPopup";
import {
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  NewGetsplashApi,
  SplashApi,
} from "../../Api/Dashboard/DashboardApi";
import moment from "moment";
import RebuildRequired from "./RebuildRequired";
import { connect } from "react-redux";
import { appDashboardApiSlice } from "../../services/appDashboardApiSlice";



class SplashScreen extends React.PureComponent {

  constructor(props) {
    super(props);
  this.state = {
    isVistaPopup : false,
    mdlprotip : false,
    mdlicondownload: false,
    mdlimage : false,
    finalfile:"",
    designid:"",
    isGalleryPopup:false,
    is_publish: 0,
    hidegridebutton:true,
    savespinner:false,
    last_updated_timestamp:'',
    finalfile:"",
    original_icon:"",
    spinner:true,
    is_default:1,
    rebuildpopvalue:false,
    appRightPanelExpand: localStorage.getItem("appSideBarCollapse") === true ||
    localStorage.getItem("appSideBarCollapse") === "true"
      ? true
      : false,
  };
}
  componentDidMount() {
    window.scrollTo(0, 0);
    if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
		{
      document.title = "Splash | AppMySite";

		}
		else
		{
      document.title = "Splash |"+ ' ' +localStorage.getItem('agencyname');
		}
    
    this.stopmounting = true;

    NewGetsplashApi(this);

    
  }

  handleVistaPopup = (e) =>
  {this.setState({isGalleryPopup:false});
    if((window.matchMedia("(min-width: 1200px)").matches))
    {
      this.setState({isVistaPopup:!this.state.isVistaPopup});
    }
    else
    {
      this.setState({isVistaPopup:false});
      this.setState({mdlvistatip:!this.state.mdlvistatip});
    }
    
  }

  closeRequireBuildPopup = (e) =>
  {

    this.setState({rebuildpopvalue:!this.state.rebuildpopvalue});
  }

  openProtipModal=() =>
  {
    this.setState({isVistaPopup:false});
    this.setState({mdlprotip:!this.state.mdlprotip});
  }
  openIconPopup=() =>
  {
    if(window.matchMedia("(max-width: 1200px)").matches)
    {
      this.setState({isVistaPopup:false});
      this.setState({mdlicondownload:!this.state.mdlicondownload});
  
    }
  }
  handleBtnDone = () =>
  {
    this.setState({isVistaPopup:false});
    this.setState({mdlprotip:false});
  }
  handleVistaDone = () =>
  {
    this.setState({isVistaPopup:false});
    this.setState({mdlvistatip:false});
  }
  handlemobileVistaPopup = () =>
  {
    this.setState({isVistaPopup:true});
  }
  handleBtnImageClose=() =>
  {
    this.setState({isVistaPopup:false});
    this.setState({mdlimage:false});
  }
  handleBtnImageDownload = () =>
  {
    
  }
  handleOpenImageModal = () =>
  {
    this.setState({isVistaPopup:false});
    this.setState({mdlimage:!this.state.mdlimage});
  }

  loadpic = (data) => {
   
    this.setState({finalfile:data.url,designid:data.id,is_default:0});
    this.setState({original_icon:data.url});
    this.setState({isVistaPopup:false});
    this.setState({isGalleryPopup:false});
    if(document.querySelector(".crello-closeBtn")){

      document.querySelector(".crello-closeBtn").click();
    }
    this.createsubmit();
  };

  closeVistaCreatePopUp = () =>{
  
  }

  settingYourAppLogodata=(imgName,from)=>{

    this.setState({isVistaPopup:false});
  
    if(from==="resize")
    {
      this.setState
      ({
        is_default:0,
        finalfile: imgName,
        upload_icon: imgName,
        designid:"",
       })
      }
     else
     {
      this.setState({
        original_icon:imgName,
        designid:"",
      // finalfile: imgName,
      });
    }
  }

  handleAppUploadimgPopUp=()=>{
    this.setState({isVistaPopup:false});
    this.setState({
      isGalleryPopup:!this.state.isGalleryPopup
    })
  }

  handlegridbutton=()=>{
    this.setState({isVistaPopup:false});
    this.setState({
      hidegridebutton:!this.state.hidegridebutton
    })
  }

  yourErrFun=()=>{

  }

  submitfunction = (e) =>
  {
    this.setState({savespinner:true});
    this.setState({rebuildpopvalue:!this.state.rebuildpopvalue});

    const params = {
      app_id: parseInt(localStorage.getItem("Appid")),
      design_id:this.state.designid !== '' && this.state.designid !== undefined && this.state.designid !== null ? this.state.designid : '',
      upload_image: this.state.finalfile,
      original_image: this.state.original_icon,
      is_android_rebuild_required:
        this.state.is_publish.android_success_build_count > 0 ? 1 : 0,
      is_ios_rebuild_required:
        this.state.is_publish.ios_success_build_count > 0 ? 1 : 0,
      website_technology: this.state.website_technology,
    };
  
    SplashApi(params, this);
    this.props.refetchItems();

  }
  createsubmit = async (e) => 
  {
    this.setState({isVistaPopup:false});

  this.setState({savespinner:true});

  if(this.state.is_publish.android_success_build_count > 0 || this.state.is_publish.ios_success_build_count > 0)
    {
      this.setState({isVistaPopup:false});
      this.setState({savespinner:false});
      this.setState({rebuildpopvalue:!this.state.rebuildpopvalue});
      return ;
    }
    
  const params = {
    app_id: parseInt(localStorage.getItem("Appid")),
    design_id:this.state.designid !== '' && this.state.designid !== undefined && this.state.designid !== null ? this.state.designid : '',
    upload_image: this.state.finalfile,
    original_image: this.state.original_icon,
    is_android_rebuild_required:
      this.state.is_publish.android_success_build_count > 0 ? 1 : 0,
    is_ios_rebuild_required:
      this.state.is_publish.ios_success_build_count > 0 ? 1 : 0,
    website_technology: this.state.website_technology,
  };

  await SplashApi(params, this);
  this.props.refetchItems();
}

arrowclick = (e) =>
{
  this.setState({isVistaPopup:false});
  if(e === 'left')
  {
    document.querySelector(".icon-left-arow").classList.add("arrow-disabled");
    document.querySelector(".icon-right-arow").classList.remove("arrow-disabled");
    document.getElementsByClassName('the-icon-width')[0].classList.remove("the-icon-width-slide");
    document.getElementsByClassName('the-icon-width')[1].classList.remove("the-icon-width-slide");
    // document.querySelector('.icon-slide-1').classList.remove('d-none');
    setTimeout(
      () => document.querySelector('.icon-slide-2').classList.add('d-none'), 
      1000
    );
    
  }
  else if( e === 'right')
  {
    document.querySelector(".icon-right-arow").classList.add("arrow-disabled");
    document.querySelector(".icon-left-arow").classList.remove("arrow-disabled");
    document.getElementsByClassName('the-icon-width')[0].classList.add("the-icon-width-slide");
    document.getElementsByClassName('the-icon-width')[1].classList.add("the-icon-width-slide");
    // document.querySelector('.icon-slide-1').classList.add('d-none');
    setTimeout(
      () => document.querySelector('.icon-slide-2').classList.remove('d-none'), 
      0
    );
  }

}
handleRightPanelExpand = (value) => {

  this.setState({
    appRightPanelExpand: value
  })
  }
  

  render() {

    if(!this.state.isVistaPopup)
    {
      if(document.querySelector(".crello-closeBtn") !== null && document.querySelector(".crello-closeBtn") !== undefined)
      {
        document.querySelector(".crello-closeBtn").click();

      }
    }


    return (
      <>
      <div>
      <Header customclass={"header-responsive-new"} />
        </div>
        
        {/* Mobile View End*/}
        <section className="dasboard_page mobile-display mt-4 mt-md-0">
        <Leftpanel handleRightPanelExpand={this.handleRightPanelExpand} />
        <div className={`right-panel-gap ${(localStorage.getItem("appSideBarCollapse") !== undefined &&
                      localStorage.getItem("appSideBarCollapse") !== null&&
                      localStorage.getItem("appSideBarCollapse") === "true") ?
                          " right-panel-gap-expand": ""}`}>
            <div className="splash-icon-page">

              <h1>Design<p className="billing-tooltip">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
              <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                  <circle cx="9" cy="9" r="9" stroke="none"/>
                  <circle cx="9" cy="9" r="8.25" fill="none"/>
                </g>
                <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"/>
                <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
              </g>
            </svg>
              <p className="billing-tooltipsubheader">
              Create a visual aesthetic which appeals and conveys meaning to your app users.
              </p>
            </p></h1>

            <div className="app-icon-head-mobile">
              <h2>Splash Screen</h2>
              
              <div className="app-icon-spark">
                <svg onClick={this.openProtipModal}  xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                  <g id="Group_37044" data-name="Group 37044" transform="translate(-351 -714.999)">
                    <g id="Group_37045" data-name="Group 37045" transform="translate(351 715)">
                      <path id="_211696_bolt_icon_2_" data-name="211696_bolt_icon (2)" d="M150.3,64,143,74.245h4.382l-1.349,7.318,7.3-10.245h-4.382L150.3,64Z" transform="translate(-135.562 -59.883)" fill=""/>
                      <g id="Ellipse_7748" data-name="Ellipse 7748" transform="translate(0 -0.001)" fill="none" stroke="" stroke-width="1.5">
                        <circle cx="12.5" cy="12.5" r="12.5" stroke="none"/>
                        <circle cx="12.5" cy="12.5" r="11.75" fill="none"/>
                      </g>
                    </g>
                  </g>
                </svg>
                <p className="protiptooltip">Pro tip</p>
              </div>
            </div>

            <div className="apperance-module-tabs">
              <div style={{height: "40px"}}>
                <Link to="/app/app-icon" style={{"--custom_color4": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color')}}>App icon</Link>
                <Link className="active" to="/app/splash" style={{"border-bottom": localStorage.getItem('agencyid') == 1 ? "2px solid #3064f9" : "2px solid "+ localStorage.getItem('agency_primary_color'),"color": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color')}}>Splash</Link>
                <Link to="/app/theme" style={{"--custom_color4": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color')}}>Theme</Link>
              </div>

              <div className="apperance-head">
            {!this.state.spinner ?     
            <>
                  {this.state.last_updated_timestamp !== '' && this.state.last_updated_timestamp !== undefined ?
                      <p>Updated  {moment(this.state.last_updated_timestamp).format(
                        "D MMMM YYYY, HH:mm"
                              )}{" "}</p>
                    : null
                    }

                {this.state.finalfile !== '' ?
                <button style={{backgroundColor:localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor')}} onClick={() => this.createsubmit()}>
                {this.state.savespinner ? 
                <div className="d-none d-flex align-items-center justify-content-center">
                  <div className="new-loader"></div>
                  </div> 
                :
                  'Save'
                }
                  </button>
                :
                <button style={{backgroundColor: "#BCC1CE", cursor: "not-allowed"}}>Save</button>
                }
              </>
              : null
              }
            </div>
              
            </div>



            {this.state.spinner ?
            <div className="splash-section" style={{height: "75vh"}}>
                <div className="newspinner" style={{borderRadius: "6px"}}>
                <svg xmlns="http://www.w3.org/2000/svg" 
          xlink="http://www.w3.org/1999/xlink" 
          style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
          width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
            style={{animationplaystate: "running", animationdelay: "0s"}}>
            </animateTransform>
          </circle>
          </g>
          <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
          style={{animationplaystate: "running", animationdelay: "0s"}}>
          </animateTransform>
          </circle>
          </g>
          <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
          style={{animationplaystate: "running", animationdelay: "0s"}}>

          </animateTransform>
          </circle>
          </g>
          <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
          style={{animationplaystate: "running", animationdelay: "0s"}}>

          </animateTransform>
          </circle>
          </g>
          </svg>
              </div>
              </div>
        :

            <div className="splash-section" style={{minHeight : '500px'}}>
            
          <>
          <div className="splash-screen-spark">
            <svg onClick={this.openProtipModal} xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
  <g id="Group_37044" data-name="Group 37044" transform="translate(-351 -714.999)">
    <g id="Group_37045" data-name="Group 37045" transform="translate(351 715)">
      <path id="_211696_bolt_icon_2_" data-name="211696_bolt_icon (2)" d="M150.3,64,143,74.245h4.382l-1.349,7.318,7.3-10.245h-4.382L150.3,64Z" transform="translate(-135.562 -59.883)" fill=""/>
      <g id="Ellipse_7748" data-name="Ellipse 7748" transform="translate(0 -0.001)" fill="none" stroke="" stroke-width="1.5">
        <circle cx="12.5" cy="12.5" r="12.5" stroke="none"/>
        <circle cx="12.5" cy="12.5" r="11.75" fill="none"/>
      </g>
    </g>
  </g>
            </svg>
            <p className="protiptooltip">Pro tip</p>
            </div>

              <div className="splash-screen-width">


              <div  className= {(this.state.finalfile !== '' &&  (window.matchMedia("(max-width: 1200px)").matches) && this.state.is_default == 0) ? "splash-section-one d-none" : "splash-section-one" }>

                <div className="splash-section-one-width">
                  <h2>Create a beautiful splash screen</h2>
                  <p>Design a stunning screen which keeps users interested during the app launch. The splash screen appears for about 3 seconds each time your app starts. </p>

                  <div className="splash-screen-buttons">
                  <span  onClick={() => this.handleVistaPopup()} style={{backgroundColor:localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor')}}>{this.state.designid === '' || this.state.designid === undefined ? 'Start designing' : 'Edit design'}</span>
                  <span  onClick={() => this.handleAppUploadimgPopUp()} style={{"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color')}}>or upload an image</span>
                  </div>

                  <div className="splash-screen-options">
                  <span  onClick={() => this.handleAppUploadimgPopUp()} style={{"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color')}}>or upload an image</span>
                  {(localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || 
                             localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || 
                             localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1) ?
                  <a className="ask-an-expert" href="/app/addons"><img src={require("./../../assets/images/dashboard/diamond.png").default} alt="diamond"/> Ask an expert</a>
                : <></>
                }
                  </div>
                  
                </div>

                

              </div>



             
            {this.state.finalfile === undefined || this.state.finalfile === '' || this.state.is_default === 1  ? 
               <div className="splash-section-two">
               <img className="img-fluid web-splash-img" src={require("./../../assets/images/dashboard/splash-screen-default.png").default} alt="splash-screen-default"/>
               
               <img className="img-fluid mob-splash-img" src={require("./../../assets/images/dashboard/mob-splash-screen-default.png").default} alt="splash-screen-default"/>
               </div>

            :
              <div className="splash-section-two" style={{width: "50%"}}>
                <div className="splash-preview">

                  <div className="splash-preview-child"   onClick={(e) => this.openIconPopup()} style={{position: "relative"}}> 
                  
                  {this.state.finalfile !== '' && this.state.finalfile !== undefined && this.state.finalfile.includes('create.vista.com') ?
                        <img className="img-fluid"  src={this.state.finalfile} alt="App-icon-default"/>
                      : this.state.finalfile !== '' && this.state.finalfile !== undefined && this.state.finalfile.includes('ams') ?
                        <img className="img-fluid"  
                        src={process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/" + localStorage.getItem("Appid")+process.env.REACT_APP_NewSplashScreen + this.state.finalfile} 
                        onError={({ currentTarget }) => {
                          currentTarget.src =
                          process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/" + localStorage.getItem("Appid")+process.env.REACT_APP_SplashScreen_Crop + this.state.finalfile;
                        }}
                        alt="App-icon-default"/>
                      : null
                      }
                         {this.state.hidegridebutton ?  
                        <div className="download-icon ">
                          <>
                          <a href={this.state.finalfile !== '' && this.state.finalfile !== undefined && this.state.finalfile.includes('create.vista.com') ? this.state.finalfile : this.state.finalfile !== '' && this.state.finalfile !== undefined && this.state.finalfile.includes('ams') ? process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/" + localStorage.getItem("Appid")+process.env.REACT_APP_NewSplashScreen + this.state.finalfile : ''} target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="36.392" height="35.164" viewBox="0 0 36.392 35.164">
                              <g id="Group_36045" data-name="Group 36045" transform="translate(0.5 1)">
                                <g id="Group_36042" data-name="Group 36042" transform="translate(17.696)">
                                  <g id="Group_36048" data-name="Group 36048">
                                    <line id="Line_368" data-name="Line 368" y1="24.566" fill="none" stroke="#fff" strokeLinecap="round" stroke-linejoin="round" stroke-width="2"/>
                                  </g>
                                </g>
                                <path id="Path_99648" data-name="Path 99648" d="M3.5,6l9.846,9.826L23.153,6" transform="translate(4.37 9.231)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                <g id="Group_36044" data-name="Group 36044" transform="translate(0.5 23.338)">
                                  <g id="Group_36047" data-name="Group 36047">
                                    <path id="Path_99649" data-name="Path 99649" d="M34.892,10v9.826H.5V10" transform="translate(-0.5 -10)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                  </g>
                                </g>
                              </g>
                            </svg>
                            </a>
                          </>
                          </div>
                      :<>
                        <div className="app-icon-grid">	
                        <img src={require("./../../assets/images/dashboard/splash-grid.png").default} className="img-fluid"/>
                        </div>
                        
                        </>
                      }
                  </div>

                  <div>
                  {this.state.hidegridebutton  ?
                    <>
                    {this.state.is_default != '1' ?

                    <div className="the-tooltip" onClick={() => this.handlegridbutton()}>

                    <p className="grid-line-icon-show">Show gridlines</p>

                    <div className="tooltip-view">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                        <g id="Group_35012" data-name="Group 35012" transform="translate(-1726.404 -18.404)">
                          <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1726.404 18.404)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                            <circle cx="8" cy="8" r="8" stroke="none"/>
                            <circle cx="8" cy="8" r="7.25" fill="none"/>
                          </g>
                          <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1734.404 22.405)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"/>
                          <path id="Path_98224" data-name="Path 98224" d="M0,1V-4" transform="translate(1734.404 29.405)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                        </g>
                      </svg>
                      
                    <span className="grid-line-show-tooltip-splash">Gridlines keep your design centered and aligned. They also make sure your design does not spill out upon rendering.</span>
                    </div>
                    </div>

                    : null
                    }
                    </>
                  :

                  <div className="the-tooltip" onClick={() => this.handlegridbutton()}>

                      <p className="grid-line-icon-hide">Hide gridlines</p>

                      <div className="tooltip-view">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                          <g id="Group_35012" data-name="Group 35012" transform="translate(-1726.404 -18.404)">
                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1726.404 18.404)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                              <circle cx="8" cy="8" r="8" stroke="none"/>
                              <circle cx="8" cy="8" r="7.25" fill="none"/>
                            </g>
                            <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1734.404 22.405)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"/>
                            <path id="Path_98224" data-name="Path 98224" d="M0,1V-4" transform="translate(1734.404 29.405)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                          </g>
                        </svg>
                        
                      <span className="grid-line-show-tooltip-splash">Gridlines keep your design centered and aligned. They also make sure your design does not spill out upon rendering.</span>
                      </div>
                      </div>
     
                    }
                  </div>

                  <Link className="edit-design-mobile-link" to="#" onClick={() => this.handleVistaPopup()}>{this.state.designid === '' || this.state.designid === undefined || this.state.designid === null ? 'Start designing' : 'Edit design'}</Link> 

                        <div className="app-icon-options d-flex d-xl-none">
                        <span  onClick={() => this.handleAppUploadimgPopUp()}>or upload an image</span>
                        {(localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || 
                             localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || 
                             localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1) ?
                        <a className="ask-an-expert" href="/app/addons"><img src={require("./../../assets/images/dashboard/diamond.png").default} alt="diamond"/> Ask an expert</a>
                      : <></>
                      }
                        </div>
                        
                </div>
                </div>
            }
             

              </div>
              </>

            </div>

          }
          

            <div className="footer-save">
                    
                  {this.state.finalfile !== '' ?
                  <button style={{backgroundColor:localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor')}} onClick={() => this.createsubmit()}>
                  {this.state.savespinner ? 
                  <div className="d-none d-flex align-items-center justify-content-center">
                    <div className="new-loader"></div>
                    </div> 
                  :
                    'Save'
                  }
                    </button>
                  :
                  <button style={{backgroundColor: "#BCC1CE"}}>Save</button>
                  }

                  {this.state.last_updated_timestamp !== '' && this.state.last_updated_timestamp !== undefined ?
                      <p>Updated  {moment(this.state.last_updated_timestamp).format(
                                "D MMMM YYYY, HH:mm"
                              )}{" "}</p>
                    : null
                    }
              
              </div>


            </div>
          </div>
          </section>
          
          <RebuildRequired rebuildpop={this.state.rebuildpopvalue} btnRebuildCancel={this.closeRequireBuildPopup} submitform={this.submitfunction} />

          <Modal className="protip-popup-message" isOpen={this.state.mdlprotip} centered>
          
          <ModalBody>

          <div className="protip-popup"> 

            <div className="protip-popup-one splash-right">           
              <h4>Pro tip</h4>
              <h6>Level-up your creation with these tips and best practices.</h6>
              <ul>
                <li>Strike a  balance between aesthetics and impact.</li>  

                <li>The splash screen will be displayed for about 3 seconds, so keep it simple.</li>

                <li>Use design elements and color themes consistently throughout your app.</li>

                <li>Choose images over text.</li>

              </ul>

              <button onClick={this.handleBtnDone}>Okay</button>
            </div>  

            <div className="protip-popup-two">
            <img src={require("./../../assets/images/dashboard/protip-img.png").default} className="img-fluid" alt="icon-preview"/>
            </div>

            </div>

            <div className="protip-popup-crossmodal">
                <img onClick={this.handleBtnDone} src={ require("./../../assets/images/dashboard/cross.png").default }/>
            </div>

          </ModalBody>
      </Modal>

      <Modal className="no-editor" isOpen={this.state.mdlvistatip} centered>
          
          <ModalBody>

          <div className="no-editor-popup"> 
           
           <h4>Small screen detected</h4>
           <h6>Please login from a device with a larger screen such as a laptop or desktop, for a better design and editing experience. </h6>
           <button onClick={this.handleVistaDone}>Okay</button>
           <span onClick={() => this.handlemobileVistaPopup()}>Proceed anyway</span>

          </div>

            <div className="crossmodal mt-1">
                <img onClick={this.handleVistaDone} src={ require("./../../assets/images/dashboard/cross.png").default }/>
            </div>

          </ModalBody>
      </Modal>

      <Modal className="download-splash-popup-modal" isOpen={this.state.mdlicondownload}>
          
          <ModalBody>

             <div className="download-splash-popup"> 
             <img className="img-fluid downloaded-icon" src={this.state.finalfile !== '' && this.state.finalfile !== undefined && this.state.finalfile.includes('create.vista.com') ? this.state.finalfile : this.state.finalfile !== '' && this.state.finalfile !== undefined && this.state.finalfile.includes('ams') ? process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/" + localStorage.getItem("Appid")+process.env.REACT_APP_NewSplashScreen + this.state.finalfile : ''} alt="App-icon-default"/>

            <div className="download-splash-popup-footer">
              <svg onClick={()=>this.openIconPopup()} xmlns="http://www.w3.org/2000/svg" width="21.414" height="21.414" viewBox="0 0 21.414 21.414">
  <g id="Group_37047" data-name="Group 37047" transform="translate(-53.793 -664.793)">
    <path id="Path_101682" data-name="Path 101682" d="M-13508.229-19301.85l-20,20" transform="translate(13582.729 19967.35)" fill="none" stroke="#fff" stroke-width="2"/>
    <path id="Path_101683" data-name="Path 101683" d="M-13528.229-19301.85l20,20" transform="translate(13582.729 19967.35)" fill="none" stroke="#fff" stroke-width="2"/>
  </g>
              </svg>

              <a href={this.state.finalfile !== '' && this.state.finalfile !== undefined && this.state.finalfile.includes('create.vista.com') ? this.state.finalfile : this.state.finalfile !== '' && this.state.finalfile !== undefined && this.state.finalfile.includes('ams') ? process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/" + localStorage.getItem("Appid")+process.env.REACT_APP_NewSplashScreen + this.state.finalfile : ''} target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.103" height="25.242" viewBox="0 0 26.103 25.242">
  <g id="Group_36045" data-name="Group 36045" transform="translate(0.5 1)">
    <g id="Group_36042" data-name="Group 36042" transform="translate(12.551)">
      <g id="Group_36048" data-name="Group 36048">
        <line id="Line_368" data-name="Line 368" y1="17.216" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      </g>
    </g>
    <path id="Path_99648" data-name="Path 99648" d="M3.5,6l6.9,6.886L17.273,6" transform="translate(2.165 4.674)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <g id="Group_36044" data-name="Group 36044" transform="translate(0.5 16.355)">
      <g id="Group_36047" data-name="Group 36047">
        <path id="Path_99649" data-name="Path 99649" d="M24.6,10v6.886H.5V10" transform="translate(-0.5 -10)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      </g>
    </g>
  </g>
              </svg>
            </a>

            </div>

              </div>

          </ModalBody>
      </Modal>


      <Modal isOpen={this.state.mdlimage} style={{ maxWidth: "600px", maxHeight: "247px" }}  centered>
          
          <ModalBody>
             <div className="code-popup-body">             
              <div className="crossmodal mt-1">
          {/* <img src={ require("../assets/images/dashboard/cross.png").default }/> */}
                </div>
                <h4>Image Popup</h4>
        <h6>
        In case you don't have access to your authenticator app, you can use one of these codes to log in. Each code can only be used once. Please make sure you copy them and store them somewhere safe.
        </h6>
                
            </div>
            <div className="code-popup-footer">
        <button onClick={this.handleBtnImageClose}>Cross</button>
        <button onClick={this.handleBtnImageDownload}>Download</button>
            </div>
          </ModalBody>
      </Modal>

        {this.state.isVistaPopup ? 
          <VistaPopup width={1500} height={2688} projectname="splash" downloadformat="png" designid={this.state.designid} formatkey="instagramStorySM" loadpicfunction={this.loadpic} closefunction={this.close}  />
        : 
        null
        }
                         {
                            this.state.isGalleryPopup && 
                              <NewImageGalleryPopup
                                isGalleryPopup={this.state.isGalleryPopup}
                                setIsGalleryPopup={true}
                                uploadPath={
                                 localStorage.getItem("user_id") +"/"+localStorage.getItem("Appid")+
                                 process.env.REACT_APP_NewSplashScreen.replace(/\/$/,"")
                                }
                                settingYourdata={this.settingYourAppLogodata}
                                yourErrFun={this.yourErrFun}
                                settingOrgImgFrom3rdParty={false}
                                hideImageGallery={true}
                                widthForRestriction={1500}
                                heightForRestriction={2688}
                                aspectRatio={"1500:2688"}
                                classCompoState={this}
                                isNotCustomUploadOrgImg={true}
                                handleVistaCreateOpenFn={this.handleVistaPopup}
                                designid={this.state.designid}
                                loadpicVistaCreatePopUp={this.loadpic}
                                closeVistaCreatePopUp={this.closeVistaCreatePopUp}
                                iconscaler={false}
                                tempuploadPath={
                                 localStorage.getItem("user_id") +"/"+localStorage.getItem("Appid")+
                                 process.env.REACT_APP_NewSplashScreen.replace(/\/$/,"")+'/temp'
                                }
                                minWidth={94}
                                minHeight={168}
                                scaletype={'splash_screen'}
                              />
                          }
          </>
    )
  }
}


const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  refetchItems: () => dispatch(appDashboardApiSlice.endpoints.appDashboardData.initiate()).refetch(),
});

export default connect(mapStateToProps, mapDispatchToProps)(SplashScreen);