import React from 'react'; 
import { Link } from "react-router-dom";
class Troubleshooting extends React.Component{
    render(){
        return(
             <>
                    <div className="d-flex  align-items-center justify-content-center get-support-pop" >
                        {/* <svg width="17.075" height="14.739" viewBox="0 0 17.075 14.739">
								<g id="Group_4026" data-name="Group 4026" transform="translate(-278.685 -282.109)">
											<path id="Path_7205" data-name="Path 7205" d="M294.023,296.955h-13.6a1.008,1.008,0,0,1-.82-1.542l6.8-11.227a.95.95,0,0,1,1.64,0l6.8,11.227A1.007,1.007,0,0,1,294.023,296.955Z" transform="translate(0 -0.858)" fill="none" stroke="#3064f9" strokeMiterlimit="10" strokeWidth="1.5"/>
											<line id="Line_135" data-name="Line 135" y2="4.04" transform="translate(287.222 287.614)" fill="#fff" stroke="#3064f9" strokeMiterlimit="10" strokeWidth="1.5"/>
											<line id="Line_136" data-name="Line 136" y2="1.488" transform="translate(287.222 292.403)" fill="#fff" stroke="#3064f9" strokeMiterlimit="10" strokeWidth="1.5"/>
								</g>
						</svg> */}
						<Link to="/app/connect/troubleshoot-logs" className="connect-troublshootinglink">Troubleshoot</Link>
					</div>
               
             </>                      
             )
    }
} 

export default React.memo(Troubleshooting)

