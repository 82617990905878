import React from "react";

const DeleteIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="13.999"
      viewBox="0 0 14 13.999"
    >
      <g id="Group_38801" data-name="Group 38801" transform="translate(0 0)">
        <g
          id="Rectangle_2307"
          data-name="Rectangle 2307"
          transform="translate(1.01 1.999)"
          fill="none"
          stroke={color}
          stroke-miterlimit="10"
          stroke-width="1"
        >
          <path
            d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z"
            stroke="none"
          />
          <path
            d="M.5.5h11a0,0,0,0,1,0,0V10.7a.8.8,0,0,1-.8.8H1.3a.8.8,0,0,1-.8-.8V.5A0,0,0,0,1,.5.5Z"
            fill="none"
          />
        </g>
        <g
          id="Rectangle_2308"
          data-name="Rectangle 2308"
          transform="translate(4.01 0)"
          fill="none"
          stroke={color}
          stroke-miterlimit="10"
          stroke-width="1"
        >
          <path
            d="M1,0H5A1,1,0,0,1,6,1V3A0,0,0,0,1,6,3H0A0,0,0,0,1,0,3V1A1,1,0,0,1,1,0Z"
            stroke="none"
          />
          <path
            d="M1,.5H5a.5.5,0,0,1,.5.5V2.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V1A.5.5,0,0,1,1,.5Z"
            fill="none"
          />
        </g>
        <g id="Group_3245" data-name="Group 3245" transform="translate(5.5 5)">
          <line
            id="Line_110"
            data-name="Line 110"
            y2="6"
            transform="translate(3 0)"
            fill="none"
            stroke={color}
            stroke-miterlimit="10"
            stroke-width="1"
          />
          <line
            id="Line_111"
            data-name="Line 111"
            y1="6"
            transform="translate(0 0)"
            fill="none"
            stroke={color}
            stroke-miterlimit="10"
            stroke-width="1"
          />
        </g>
        <line
          id="Line_112"
          data-name="Line 112"
          x2="14"
          transform="translate(0 2.499)"
          fill="none"
          stroke={color}
          stroke-miterlimit="10"
          stroke-width="1"
        />
      </g>
    </svg>
  );
};

export default DeleteIcon;
