import React, {useContext} from 'react';
import styles from './publish.module.css';
import { Creation } from "./Context"; 
 const Notappleaccount = () => {
    const context = useContext(Creation);
   
        return (
            <>
            <div className={"text-center mt-5 mb-5 " + styles.notanyapp}>
                <div>
                    <img src={require("../../../assets/images/dashboard/publish/app-store.png").default} alt="notanyapp" className="img-fluid"/>
                    <h2 className="mt-4 mb-3">Link your account to App Store Connect</h2>
                    <p>A connection between your app and the Apple App Store Connect is required. </p>
                    <button className={styles.buttononnotapp + " d-flex align-items-center justify-content-center"}  style={{backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}} onClick={e => context.setPopup(true)}>
                            <img src={require("../../../assets/images/dashboard/whiteplus.png").default} alt="whiteplus" className="img-fluid mr-2"/>
                            Connect
                    </button>
                </div>
            </div>
                   
		    </>
        )
}
export default Notappleaccount;