import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { prepareAuthHeaders } from "../../utils/commonUtils";
import apiEndPoints from "../../utils/apiEndpoints";

export const profileSlice = createApi({
  reducerPath: "profile",
  keepUnusedDataFor: 1800,
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => prepareAuthHeaders(headers),
  }),
  tagTypes: ["get-profile"],
  endpoints: (builder) => ({
    getProfileData: builder.query({
      query: () => apiEndPoints.userUrl+'user_profile',
      providesTags: ["get-profile"],
    }),
    getCountryData: builder.query({
      query: () => apiEndPoints.userUrl+'country',
      transformResponse: (response) => response.data,
    }),
    getIPData: builder.query({
      query: () => "https://api.ipstack.com/check?access_key=" +
        process.env.REACT_APP_ipstack_key +
        "&timestamp=" +
        new Date().getTime()
    }),
    saveProfileData: builder.mutation({
      query: (data) => ({
        url: apiEndPoints.userUrl+"edit_profile",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["get-profile"],
    }),
    getAgencyLoginData: builder.query({
      query: (data) => ({
        url: apiEndPoints.generalUrl+'agency-login-data',
        method: "POST",
        body: data,
      }),
        
    })
  }),
});

export const { useGetProfileDataQuery, useSaveProfileDataMutation, useGetCountryDataQuery, useGetIPDataQuery, useGetAgencyLoginDataQuery } = profileSlice;
