import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiEndPoints from "../utils/apiEndpoints";

export const headerApiSlice = createApi({
  reducerPath: "header",
  keepUnusedDataFor: 1800,
  baseQuery: fetchBaseQuery({
    baseUrl: apiEndPoints.generalUrl,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("amstoken")}`
      );
      return headers;
    },
  }),
  endpoints: (builder) => ({
    g2RatingData: builder.query({
      query: () => `user/g2rating`,
    }),
    saveG2RatingData: builder.mutation({
      query: (newData) => ({
        url: "user/save_g2rating",
        method: "POST",
        body: newData,
      }),
    }),
  }),
});

export const { useG2RatingDataQuery, useSaveG2RatingDataMutation } =
  headerApiSlice;
