import { Modal, ModalBody } from "reactstrap";
import backIc from "../../assets/images/dashboard/backIc.svg";
export const ParentFilterModal = ({
  handleMainFilterChange,
  handleOpenParentDropdown,
  openMobParentFilter,
  optionForMainFilter,
  filterSelectionArr,
  index,
  value,
}) => {
  return (
    <div>
      <Modal
        className="protip-popup-message"
        isOpen={
          window.matchMedia("(max-width: 1200px)").matches
            ? openMobParentFilter
            : false
        }
        centered
      >
        <ModalBody>
          <div className="mobileglobalsearch">
            <button onClick={() => handleOpenParentDropdown(index)}>
              <img src={backIc} alt="back" />
            </button>
          </div>

          <div className="mobilesearchsuggestion">
            {optionForMainFilter &&
              optionForMainFilter.map((option, i) => {
                return (
                  <p
                    style={{
                      fontSize: "16px",
                      color: filterSelectionArr.find(
                        (row) => row.value === option.value
                      )
                        ? "#C8CEDB"
                        : "#1B2952",
                      fontFamily: "Axiforma-Regular",
                      margin: "0px 20px 40px 20px",
                      display: "block",
                    }}
                    onClick={
                      filterSelectionArr.find(
                        (row) => row.value === option.value
                      )
                        ? null
                        : (e) => handleMainFilterChange(option, index)
                    }
                    key={i}
                  >
                    {option.label}
                  </p>
                );
              })}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
