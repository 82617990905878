import React from 'react';

export const Foot = () => {
    
        return (
          
            <div className="footer ">
              {/* <p className="mb-0"> Copyright © 2022 AppMySite, Inc. All rights reserved. </p> */}
              
            </div>
          
        )
    
}
export const getFoot = () => {
    
  return (
    
      <div className="footer d-flex justify-content-between">
        {/* <p className="mb-0"> Copyright © 2021 AppMySite, Inc. All rights reserved. </p>
        <a href="http://www.appmysite.com/careers/" target="_blank" rel="noreferrer" className="mb-0 d-none d-md-block" style={{color:"#7782A1"}}> We are hiring! </a> */}
      </div>
    
  )

}
export const InnerFoot = () => {
    
        return (
            <div className="footer innerfoot">
              {/* <p className="mb-0"> Copyright © 2022 AppMySite, Inc. All rights reserved. </p> */}
            </div>
        )
    
}


