import axios from "axios";
import { errortoast, sucesstoast } from "../../Dashboard/Toaster";
const Url = process.env.REACT_APP_API_URL + "api/app/";
const url = process.env.REACT_APP_API_URL + "api/";
const Url1 = process.env.REACT_APP_API_URL + "api/user/";
const Loader = (state) => {
  state.setState({
    submitbuttontext: "",
    loader: "loader"
  });
};
//Table List
export const Applist = (params, state, mounting) => {
  state.setState({
    bodyspinner: true
  });
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken")
  };
  axios
    .get(
      Url +
        "multi-site/list-linked-apps/" +
        params.app_id +
        "?page=1&limit=100&timestamp=" +
        new Date().getTime(),
      {
        headers: headers
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        if (state.stopmounting || mounting) {
          if (!res.data.data.length) {
            state.setState({
              is_enable_screen: res.data.is_enable_screen,
              bodyspinner: false,
              tableData: res.data.data,
              multisite: res.data.multisite,
              updatedAt: res.data?.updated_at,
              parent_app_name: res.data.multisite?.parent_app_name,
              parent_app_url: res.data.multisite?.parent_app_url
            });
          } else {
            const settings = {
              title: res.data.data[0]?.title || "Make a selection",
              showOnEveryLaunch: Boolean(
                res.data.data[0]?.show_on_every_launch
              ),
              showOnFirstLaunch: Boolean(
                res.data.data[0]?.show_on_first_launch
              ),
              showLocations: Boolean(res.data.data[0]?.show_locations),
              showDistance: Boolean(res.data.data[0]?.show_distance),
              showAppIcon: Boolean(res.data.data[0]?.show_app_icon),
              showAppName: Boolean(res.data.data[0]?.show_app_name),
              showWebsiteLink: Boolean(res.data.data[0]?.show_site_url),
              showCountryFlag: Boolean(res.data.data[0]?.show_country_flag),
              showCountryName: Boolean(res.data.data[0]?.show_country_name),
              showLanguage: Boolean(res.data.data[0]?.show_language),
              iconFilter: res.data.data[0]?.is_black_icon,
              markerColor: res.data.data[0]?.location_marker_color_object
                ? JSON.parse(res.data.data[0]?.location_marker_color_object)
                    ?.portal_data
                : "#838383",
              selectedMarkerColor: res.data.data[0]
                ?.selected_location_marker_color_object
                ? JSON.parse(
                    res.data.data[0]?.selected_location_marker_color_object
                  )?.portal_data
                : "#FF4848",
              locationMarkerIcon:
                res.data.data[0]?.location_marker_image || "default"
            };
            state.setState({
              settings,
              selectedLayout: res.data.data[0]?.layout || "layout-0",
              updatedAt: res.data?.updated_at,
              tableData: res.data.data,
              show_site_url:
                res.data.data[0] !== undefined
                  ? res.data.data[0].show_site_url
                  : 0,
              show_app_icon:
                res.data.data[0] !== undefined
                  ? res.data.data[0].show_app_icon
                  : 0,
              show_app_name:
                res.data.data[0] !== undefined
                  ? res.data.data[0].show_app_name
                  : 0,
              show_country_flag:
                res.data.data[0] !== undefined
                  ? res.data.data[0].show_country_flag
                  : 0,
              show_country_name:
                res.data.data[0] !== undefined
                  ? res.data.data[0].show_country_name
                  : 0,
              parent_app_name: res.data.multisite.parent_app_name,
              parent_app_url: res.data.multisite.parent_app_url,
              bodyspinner: false,
              plan_code: res.data.app_plan,
              selected_country: res.data.selected_country,
              multisite: res.data.multisite,
              handleurl:
                res.data.data.length === 0
                  ? false
                  : res.data.data[0].show_site_url === 1
                  ? true
                  : false,
              is_enable_screen: res.data.is_enable_screen
            });
          }
        }
      } else if (res.data.code === 203) {
        if (state.stopmounting || mounting) {
          state.setState({
            bodyspinner: false,
            status_code: res.data.code,
            plan_code: res.data.app_plan,
            reseller_package_id: res.data.reseller_package_id,
            multisite: res.data.multisite,
            addon_code: res.data.addon_code
          });
        }
      }
    })
    .catch((error) => {
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

export const WorkspaceList = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken")
  };

  axios
    .get(url + `get-workspaces?limit=500`, { headers: headers })
    .then((res) => {
      if (res.data.code === 200) {
        // let wpData = res.data.data;
        let wpData = res.data.data.filter((wp) => {
          if (parseInt(localStorage.getItem("website_technology")) === 3) {
            
            if (
              parseInt(localStorage.getItem("website_technology")) ===
              wp.subscription.package_info.website_technology
            ) {
              return wp;
            }
          } else {
            return wp.subscription.package_info.website_technology !== 3 && wp;
          }
        });
        let invited_workspace = res.data.invited_workspace;
        let useInfoObj = {
          id: res.data.user_info.id,
          workspace_name: res.data.user_info.name,
          profile_image: res.data.user_info.profile_image,
          type: "user"
        };
        wpData.push(useInfoObj);
        
        if (state.state.mergeArray !== undefined) {
          state.setState({
            mergeArray: wpData
          });
          var obj = [];

          obj.push({
            value: null,
            label: "All",
            image:
              process.env.REACT_APP_Image_Path +
              localStorage.getItem("logged_in_user_id") +
              process.env.REACT_APP_Profile +
              localStorage.getItem("user_profile"),
            workspace_id: null,
            imagevalue: res.data.user_info.profile_image
          });

          for (var i = 0; i < state.state.mergeArray.length; i++) {
            if (state.state.mergeArray[i].user_id !== undefined) {
              obj.push({
                value: state.state.mergeArray[i].subscription.workspace_id,
                label: state.state.mergeArray[i].workspace_name,
                image:
                  process.env.REACT_APP_Image_Path +
                  state.state.mergeArray[i].user_id +
                  process.env.REACT_APP_Workspace_Profile +
                  state.state.mergeArray[i].id +
                  "/" +
                  state.state.mergeArray[i].profile_image,
                workspace_id:
                  state.state.mergeArray[i].subscription.workspace_id,
                imagevalue: state.state.mergeArray[i].profile_image
              });
            }
          }
          state.setState({ options: obj });
        }
      }
    })
    .catch((err) => {
      
    });
};

export const getWorkspacesList = (state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken")
  };

  axios
    .get(url + `get-workspaces?limit=500`, { headers: headers })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({ workSpacesList: res.data.data });
      }
    })
    .catch((err) => {
      
    });
};

//App list for popup
export const Popupapplist = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken")
  };
  var page_id = 1;
  var workspace_id = null;
  var search_field = "";

  if (params.page_id === undefined || params.page_id === null) {
    page_id = 1;
  } else {
    page_id = params.page_id;
  }

  if (params.workspace_id === undefined || params.workspace_id === null) {
    workspace_id = null;
  } else {
    workspace_id = params.workspace_id;
  }

  if (params.search_field === undefined || params.search_field === null) {
    search_field = "";
  } else {
    search_field = params.search_field;
  }

  if (page_id === 1) {
    state.setState({
      appselectionfield: []
    });
  }

  axios
    .get(
      Url +
        "merge-apps/list-all-apps/" +
        params.app_id +
        "?limit=40&page=" +
        page_id +
        "&workspace_id=" +
        workspace_id +
        "&search_field=" +
        search_field +
        "&timestamp=" +
        new Date().getTime(),
      {
        headers: headers
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        if (state.state.stopmounting === true) {
          if (res.data.data.length === 0) {
            state.setState({
              setPage: state.state.setPage - 1,
              scrollstop: false
            });
          }
          state.setState({
            appselectionfield: state.state.appselectionfield.concat(
              res.data.data
            ),
            bodyspinner: false,
            secondbodyspinner: false
          });
        }
      }
    })
    .catch((err) => {
      
    });
};

//Get Apps List to be Merged
export const getAppsList = (
  params,
  currState,
  state,
  page,
  workspace_id = null,
  setIsFetching = null,
  el = null
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken")
  };
  if (page > 1) {
    state.setState({
      secondbodyspinner: true
    });
  }
  axios
    .get(
      `${Url}merge-apps/list-all-apps/${
        params.app_id
      }?limit=40&page=${page}&search_field=${
        params.search_field
      }&timestamp=${new Date().getTime()}
        &workspace_id=${workspace_id ?? ""}`,
      {
        headers: headers
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        if (res.data.data?.length === 0) {
          state.setState({
            scrollstop: false,
            appsLoading: false,
            noMoreApps: true,
            secondbodyspinner: false
          });
          state.setPage(page < 1 ? 1 : page - 1);
        }
        state.setState({
          mergeAppList:
            page <= 1
              ? [
                  ...res.data.data?.map((elem) => ({
                    ...elem,
                    isSelected: false
                  }))
                ]
              : currState.mergeAppList.concat(
                  res.data.data?.map((elem) => ({
                    ...elem,
                    isSelected: false
                  }))
                ),
          secondbodyspinner: false,
          appsLoading: false
        });
      }
    })
    .catch((err) => {
      
    })
    .finally(() => {
      setIsFetching && setIsFetching(false);
      el &&
        el?.scrollBy({
          top: -10,
          behavior: "instant"
        });
    });
};

// Set data to state
export const setAllAppdata = (
  addModalData,
  setAddModalData,
  allAppsData,
  allAppsDataLoading,
  allAppsDataError,
  page,
  setPage,
  allAppsDataFetching
) => {
  if (addModalData.productSelected.value === "payPerApp") {
    // set state here
    if (!allAppsDataLoading && !allAppsDataError) {
      if (allAppsData.code === 200) {
        setAddModalData({ appsLoading: true, noMoreApps: false, isSearching: false });

        if (allAppsData.data?.length === 0) {
          setAddModalData({
            scrollstop: false,
            appsLoading: false,
            noMoreApps: true,
            secondbodyspinner: false,
          });
        }
        setAddModalData({
          mergeAppList:
            page <= 1
              ? [
                  ...allAppsData?.data?.map((elem) => ({
                    ...elem,
                    isSelected: false,
                  })),
                ]
              : addModalData.mergeAppList.concat(
                  allAppsData.data?.map((elem) => ({
                    ...elem,
                    isSelected: false,
                  }))
                ),
          secondbodyspinner: false,
          appsLoading: false,
        });
      }
    }
  }
};

//App list for popup
export const getapps = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken")
  };

  let finalvale;

  finalvale =
    "&timestamp=" +
    new Date().getTime() +
    "&workspace_id=" +
    params.workspace_id;

  axios
    .get(Url1 + "user_apps?limit=100" + finalvale, {
      headers: headers
    })
    .then((res) => {
      if (res.data.code === 200) {
        
      }
    })
    .catch((err) => {
      
    });
};

//Link App
export const Linkapp = (params, state) => {
  Loader(state);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken")
  };

  axios
    .post(Url + "multi-site/link-app", params, {
      headers: headers
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          disabled: false,
          appselectioncheckbox: [],
          submitbuttontext: "Done",
          loader: "d-none"
        });
        state.context.setState({
          appmodal: false,
          bodyspinner: true
        });
        state.context.applist();
      } else {
        state.setState({
          disabled: false,
          appselectioncheckbox: [],
          submitbuttontext: "Done",
          loader: "d-none"
        });
      }
    })
    .catch((err) => {
      
    });
};

export const LinkSelectedApps = (params, state, cb) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken")
  };

  axios
    .post(Url + "multi-site/link-app", params, {
      headers: headers
    })
    .then((res) => {
      if (res.data.code === 200) {
        sucesstoast(
          `${
            params.child_app_array.length > 1 ? "Apps" : "App"
          } merged successfully`
        );
      }
    })
    .catch((err) => {
      
      errortoast(err.message);
    })
    .finally(() => {
      state.setState({
        isMerging: false,
        appsToLink: []
      });
      cb(true);
    });
};

//Delete App this function not used anymore
export const Deleteapp = (params, state) => {
  state.setState({
    submitval_confirmpop: "",
    loader_remove: "loader"
  });
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken")
  };

  axios
    .post(Url + "multi-site/delete-app", params, {
      headers: headers
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          submitval_confirmpop: "Yes",
          loader_remove: "d-none"
        });
        state.context.setState({
          removeappstate: false
        });
        state.context.applist();
      } else {
        state.setState({
          submitval_confirmpop: "Yes",
          loader_remove: "d-none"
        });
        errortoast(res.data.data);
      }
    })
    .catch((err) => {
      
    });
};

//Handle status

export const Changestatus = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken")
  };

  axios
    .post(Url + "merge-apps/update-settings", params, {
      headers: headers
    })
    .then((res) => {
      if (res.data.code === 200) {
      } else {
        errortoast(res.data.data);
      }
    })
    .catch((err) => {
      
    });
};
export const ChangeSettingstatus = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken")
  };

  

  axios
    .post(Url + "merge-apps/update-settings", params, {
      headers: headers
    })
    .then((res) => {
      if (res.data.code === 200) {
      } else {
        errortoast(res.data.data);
      }
    })
    .catch((err) => {
      
    });
};
export const Changetogglestatus = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken")
  };
  axios
    .post(Url + "merge-apps/update-settings", params, {
      headers: headers
    })
    .then((res) => {
      if (res.data.code === 200) {
        sucesstoast("Data Saved Successfully");
        state.setState({
          updatedAt: res.data.data.updated_at
        });
      } else {
        errortoast(
          res.data?.data[Object.keys(res.data.data)[0]][0] ||
            "Error updating Data"
        );
      }
    })
    .catch((err) => {
      
    })
    .finally(() => {
      state.setState({
        savingDetails: false
      });
    });
};
//Save country code
export const Countrycode = (params, state) => {
  Loader(state);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken")
  };

  axios
    .post(Url + "multi-site/update-country", params, {
      headers: headers
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.context.applist();
        state.setState({
          disabled: true,
          bodyspinner: true,
          submitbuttontext: "Done",
          loader: "d-none"
        });
        state.context.setState({
          countrymodal: false
        });
      } else {
        errortoast(res.data.data);
        state.setState({
          submitbuttontext: "Done",
          loader: "d-none"
        });
      }
    })
    .catch((err) => {
      
    });
};

//unlink app
export const Unlinkapp = (params) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken")
  };

  axios
    .post(Url + "multi-site/unlink-app", params, {
      headers: headers
    })
    .then((res) => {
      if (res.data.code === 200) {
        sucesstoast("App successfully unlinked");
        window.location.reload(true);
      } else {
        errortoast(res.data.data);
      }
    })
    .catch((err) => {
      
    });
};

export const UnlinkMergedapp = (params, cb, setLoading, setUnlinkModal) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken")
  };

  axios
    .post(Url + "multi-site/unlink-app", params, {
      headers: headers
    })
    .then((res) => {
      if (res.data.code === 200) {
        sucesstoast("App successfully unlinked");
        cb();
      } else {
        errortoast(res.data.data);
      }
    })
    .catch((err) => {
      
    })
    .finally(() => {
      setLoading(false);
      setUnlinkModal(false);
    });
};
