import React from "react";
import previewnotification from "../../../assets/images/dashboard/preview-notification-icon.png";
import Appearancemoon from "../../../assets/images/dashboard/Appearance-moon.png";
import arrownext from "../../../assets/images/dashboard/arrownext.png";
import offlineicon from "../../../assets/images/dashboard/offline-icon.png";
import siteicon from "../../../assets/images/dashboard/site-icon.png";
import languageicon from "../../../assets/images/dashboard/language-icon.png";
import Currencyicon from "../../../assets/images/dashboard/Currency-icon.png";
import ternandcondition from "../../../assets/images/dashboard/tern&condition.png";
import chaticon from "../../../assets/images/dashboard/newchat-icon.png";
import backIcon from "../../../assets/images/dashboard/backIcon.svg";
import home from "../../../assets/images/dashboard/settingicon/home.svg";
import shop from "../../../assets/images/dashboard/settingicon/shop.svg";
import blog from "../../../assets/images/dashboard/settingicon/blog.svg";
import watch from "../../../assets/images/dashboard/settingicon/watch.svg";
import more from "../../../assets/images/dashboard/settingicon/more.svg";
import crossFrame from "../../../assets/images/dashboard/settingicon/crossFrame.svg";
const GeneralSettingsPreview = ({ settingsState }) => {
  const appPlan = localStorage.getItem("app_plan");

  return (
    <div className="generalsetting-right">
      <div className="generalsetting-preview-right">
        <div className="setting-preview-frame-div">
          <div className="generalsetting-preview-product-frame">
            {/* Product Preview */}
            {/* Setting Page preview */}
            <div className="setting-page-with-header">
              <div className="setting-header-div">
                <img src={backIcon} className="back-arrow-width" alt="back" />
                <p className="setting-head-div-text">Settings</p>
              </div>
              <div className="general-setting-preview-body">
                <div>
                  {/* Push Notification */}
                  <div className="general-setting-preview-tabs">
                    <div className="general-setting-preview-tabs-left ">
                      <img
                        src={previewnotification}
                        className="icon-img"
                        alt="preview"
                      />
                      <p className="general-setting-tagname">
                        Push Notifications
                      </p>
                    </div>
                    {/* setting-toggle-on */}
                    <div className="setting-preview-toggle-div setting-toggle-on">
                      <div className="setting-rounded-toggle"></div>
                    </div>
                  </div>

                  {/* Appearance */}
                  <div className="general-setting-preview-tabs">
                    <div className="general-setting-preview-tabs-left">
                      <img
                        src={Appearancemoon}
                        className="icon-img"
                        alt="appear"
                      />
                      <p className="general-setting-tagname">Appearance</p>
                    </div>
                    <img
                      src={arrownext}
                      className="setting-arrownwext"
                      alt="next"
                    />
                  </div>

                  {/* Offline mode */}
                  {settingsState.website_technology !== 2 ? (
                    appPlan === "premium" ? (
                      <div
                        className={`general-setting-preview-tabs ${
                          settingsState.enableOfflineMode &&
                          settingsState.isBeaconBlinkingOffline
                            ? "glow-effect-infinite"
                            : settingsState.isBeaconBlinkingOffline
                            ? "general-setting-preview-tab-hidden"
                            : !!!settingsState.enableOfflineMode
                            ? "general-setting-preview-tab-hidden"
                            : ""
                        }`}
                      >
                        <div className="general-setting-preview-tabs-left">
                          <img
                            src={offlineicon}
                            className="icon-img"
                            alt="offline"
                          />
                          <p className="general-setting-tagname">
                            Offline mode
                          </p>
                        </div>
                        <div className="setting-preview-toggle-div">
                          <div className="setting-rounded-toggle"></div>
                        </div>
                      </div>
                    ) : null
                  ) : null}

                  {/* Site */}
                  <div
                    className={`general-setting-preview-tabs ${
                      settingsState.isBeaconBlinkingWebsiteUrl &&
                      settingsState.websiteUrl?.trim()?.length > 0
                        ? "glow-effect-infinite "
                        : ""
                    } ${
                      settingsState.isBeaconBlinkingWebsiteUrl &&
                      settingsState.userInputWebsiteUrl
                        ? "glow-effect-infinite"
                        : ""
                    }`}
                  >
                    <div className="general-setting-preview-tabs-left">
                      <img src={siteicon} className="icon-img" alt="site" />
                      <div className="general-setting-site-div">
                        <p className="general-setting-tagname">Site</p>
                        {settingsState.websiteUrl && (
                          <span className="general-setting-spantag">
                            {"https://" + settingsState.websiteUrl}
                          </span>
                        )}
                      </div>
                    </div>
                    <img
                      src={arrownext}
                      className="setting-arrownwext"
                      alt="arroe"
                    />
                  </div>

                  {/* Languag */}
                  {appPlan === "premium" && (
                    <>
                      {settingsState.website_technology !== 2 ? (
                        <div
                          className={`general-setting-preview-tabs ${
                            !!settingsState.enableLanguage &&
                            settingsState.isBeaconBlinkingLanguage
                              ? "glow-effect-infinite"
                              : !!!settingsState.enableLanguage &&
                                appPlan === "premium"
                              ? "general-setting-preview-tab-hidden"
                              : settingsState.isBeaconBlinkingLanguage
                              ? "glow-effect-infinite"
                              : ""
                          }`}
                        >
                          <>
                            {!settingsState.openEnableLanguageToggle ? (
                              <>
                                <div className="general-setting-preview-tabs-left">
                                  <img
                                    src={languageicon}
                                    className="icon-img"
                                    alt="language"
                                  />
                                  <div className="general-setting-site-div">
                                    <p className="general-setting-tagname">
                                      Language
                                    </p>
                                    <span className="general-setting-spantag">
                                      {settingsState.defaultlanguage}
                                    </span>
                                  </div>
                                </div>
                                <img
                                  src={arrownext}
                                  className="setting-arrownwext"
                                  alt="arrow"
                                />
                              </>
                            ) : (
                              <>
                                <div className="general-setting-preview-tabs-left">
                                  <img
                                    src={languageicon}
                                    className="icon-img"
                                    alt="language"
                                  />
                                  <div className="general-setting-site-div">
                                    <p className="general-setting-tagname">
                                      Language
                                    </p>
                                    <span className="general-setting-spantag">
                                      {settingsState.defaultlanguage}
                                    </span>
                                  </div>
                                </div>
                                <img
                                  src={arrownext}
                                  className="setting-arrownwext"
                                  alt="arrow"
                                />
                              </>
                            )}
                          </>
                        </div>
                      ) : null}
                    </>
                  )}
                  {/* Currency */}
                  {settingsState.website_technology === 5 && (
                    <div className="general-setting-preview-tabs">
                      <div className="general-setting-preview-tabs-left">
                        <img
                          src={Currencyicon}
                          className="icon-img"
                          alt="currency"
                        />
                        <div className="general-setting-site-div">
                          <p className="general-setting-tagname">Currency</p>
                          <span className="general-setting-spantag">USD</span>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Terms & conditions */}
                  {settingsState.website_technology > 3 ? (
                    <div
                      className={`general-setting-preview-tabs ${
                        settingsState.isBeaconBlinkingTnc &&
                        settingsState.termandcondition?.trim()?.length > 0
                          ? "glow-effect-infinite "
                          : ""
                      } ${
                        settingsState.termandcondition?.trim()?.length === 0
                          ? "general-setting-preview-tab-hidden"
                          : ""
                      }`}
                    >
                      <div className="general-setting-preview-tabs-left">
                        <img
                          src={ternandcondition}
                          className="icon-img"
                          alt="tnc"
                        />
                        <p className="general-setting-tagname">
                          Terms & conditions
                        </p>
                      </div>
                      <img
                        src={arrownext}
                        className="setting-arrownwext"
                        alt="arrow"
                      />
                    </div>
                  ) : null}

                  {/* Chat */}
                  <div className="general-setting-preview-tabs">
                    <div className="general-setting-preview-tabs-left">
                      <img src={chaticon} className="icon-img" alt="chat" />
                      <p className="general-setting-tagname">Chat</p>
                    </div>
                    <img
                      src={arrownext}
                      className="setting-arrownwext"
                      alt="next"
                    />
                  </div>
                  {/* Rate us */}
                </div>
                <p className="setting-preview-version-text">Version 3.2.1</p>
              </div>
              <div className="setting-page-preview-foolter">
                <div className="setting-page-footer-item-div">
                  <img src={home} alt="home" />
                  <p className="setting-page-item-text">Home</p>
                </div>
                <div className="setting-page-footer-item-div">
                  <img src={shop} alt="shop" />
                  <p className="setting-page-item-text">Shop</p>
                </div>
                <div className="setting-page-footer-item-div">
                  <img src={blog} alt="blog" />
                  <p className="setting-page-item-text">Blog</p>
                </div>
                <div className="setting-page-footer-item-div">
                  <img src={watch} alt="watch" />
                  <p className="setting-page-item-text">Watch</p>
                </div>
                <div className="setting-page-footer-item-div">
                  <img src={more} alt="more" />
                  <p className="setting-page-item-text">More</p>
                </div>
              </div>
              <div
                className={
                  settingsState.promptOnExitDrawerOpen
                    ? "exit-bar-background"
                    : ""
                }
              >
                <div
                  className={`exit-bar-frame ${
                    settingsState.promptOnExitDrawerOpen
                      ? "exit-bar-frame-appear"
                      : ""
                  }`}
                >
                  <div>
                    <img
                      src={crossFrame}
                      className="exit-bar-cross"
                      alt="cross"
                    />

                    <h1 className="exitbar-header">Exit</h1>
                    <p className="exitbar-text">
                      Are you sure you want to exit?
                    </p>
                  </div>
                  <div className="exit-bar-buttons">
                    <button disabled className="exit-barcancel-btn">
                      Cancel
                    </button>
                    <button disabled className="exit-bar-okay-btn">
                      Okay
                    </button>
                  </div>
                </div>
              </div>
              {settingsState.forceAppUpdateModalOpen && (
                <div className="update-poup-background">
                  <div className="update-poup-frame">
                    <div className="update-poup-frame-top">
                      <h3 className="update-poup-header">
                        New version available
                      </h3>
                      <p className="update-poup-text">
                        There are new features available, please update your app
                      </p>
                    </div>
                    <p className="update-poup-update">Update</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralSettingsPreview;
