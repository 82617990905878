import React from 'react'

const ThemeSearchIcon = ({iconColor,allBlack}) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.354"
    height="15.353"
    viewBox="0 0 15.354 15.353"
  >
    <path
      id="Union_2934"
      data-name="Union 2934"
      d="M10.686,10.5l4,4ZM.185,6a6,6,0,1,1,6,6A6,6,0,0,1,.185,6Z"
      transform="translate(0.315 0.5)"
      fill="none"
      stroke={
        iconColor && allBlack ? iconColor : "#1a1a1a"
      }
      stroke-miterlimit="10"
      stroke-width="1"
    />
  </svg>
  )
}

export default ThemeSearchIcon