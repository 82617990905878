import React from "react";

const YellowEmoji = ({ isActive }) => {
  return (
    <svg
      className={`g2yellowsvg ${isActive ? "activeYellowG2icon" : ""}`}
      id="_9025890_smiley_sad_icon"
      data-name="9025890_smiley_sad_icon"
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <rect
        id="Rectangle_31264"
        data-name="Rectangle 31264"
        width="36"
        height="36"
        fill="none"
      />
      <circle
        className="yellowsvgback"
        id="Ellipse_8287"
        data-name="Ellipse 8287"
        cx="15"
        cy="15"
        r="15"
        transform="translate(3 3)"
        fill=""
        stroke=""
        stroke-miterlimit="10"
        stroke-width="2"
      />
      <circle
        className="yellowsvgeye"
        id="Ellipse_8290"
        data-name="Ellipse 8290"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(22 14)"
        fill=""
      />
      <circle
        className="yellowsvgeye"
        id="Ellipse_8291"
        data-name="Ellipse 8291"
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="translate(11 14)"
        fill=""
      />
      <path
        className="yellowsvgsmile"
        id="Path_104597"
        data-name="Path 104597"
        d="M11.291,19.5h8"
        transform="translate(2.709 3.18)"
        fill=""
        stroke=""
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </svg>
  );
};

export default YellowEmoji;
