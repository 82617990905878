import React from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { CreateIOSlambdatoken, Createlambdatoken, GetsplashApi, iframeApi, lambdaGetRecording, lambdaStartRecording, lambdaStopRecording, updateLambdaLocation, updateLanguage, Verifyapistatus } from "../../Api/Preview/PreviewApi";
import { iosDevices } from "../../Constants/iosDevices.js";
import Connectpopup from "../Reusable/Connectpopup.js";
import "./appPreview.css";
import { Errorstatuscon } from "./Context";
import { DownApp } from "./DownApp";

class LambdaSplash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copiedLeft: false,
      copiedRight: false,
      spinner: true,
      data: null,
      iosdata: null,
      androidlanguage: "en",
      ioslanguage: "en",
      devicename: "iPhone 14 Pro",
      androiddevicename: "Google Pixel 8 Pro",
      androiddeviceOSname: "14.0",
      iosscale: "67",
      osVersion: "17.4",
      androidscale: "77",
      limitreached: false,
      ios: true,
      android: true,
      screenshot: false,
      iosspinner: false,
      androidspinner: false,
      error: false,
      connectivity_log: null,
      appplan: atob(localStorage.getItem("plan_code")),
      is_connected: null,
      is_verified: null,
      website_type: null,
      simulator_status: null,
      ItemAppPopup: false,
      ItemAppIOSPopup: false,
      ItemMobAppPopup: false,
      ItemAppAndoridPopup: false,
      ItemAppAndoridDevicePopup: false,
      ItemMobAppAndoridPopup: false,
      modal: false,
      unlink: false,
      lambdatoken: "",
      ioslambdatoken: "",
      androiddevices: [
        "Google Pixel 6A",
        "Google Pixel 8 Pro",
        "Google Pixel 8",
        "Google Pixel 7 Pro",
        "Google Pixel 7",
        "Google Pixel 6",
        "Google Pixel 6 Pro",
        "OnePlus 11 5G",
        "Realme GT2 Pro",
        "Galaxy S24 Ultra",
        "Galaxy S24 Plus",
        "Galaxy S24",
        "Galaxy A53 5G",
        "Nothing Phone(2a)"
      ],
      iosdevices: [
        "iPhone 11",
        "iPhone 11 Pro",
        "iPhone 11 Pro Max",
        "iPhone 12",
        "iPhone 12 Mini",
        "iPhone 12 Pro",
        "iPhone 12 Pro Max",
        "iPhone 13",
        "iPhone 13 Mini",
        "iPhone 13 Pro",
        "iPhone 13 Pro Max",
        "iPhone 14",
        "iPhone 15",
        "iPhone 14 Plus",
        "iPhone 14 Pro Max",
        "iPhone 15 Plus",
        "iPhone 15 Pro",
        "iPhone 14 Pro",
        "iPhone 15 Pro Max",
        "iPad (6th Generation)",
        "iPad (7th generation)",
        "iPad (8th generation)",
        "iPad (9th generation)",
        "iPad Air (3rd generation)",
        "iPad Air (4th generation)",
        "iPad Mini (5th generation)",
        "iPad Pro (11-inch)",
        "iPad Pro (11-inch) (2nd generation)",
        "iPad Pro (11-inch) (3rd generation)",
        "iPad Pro (12.9-inch) (3rd generation)",
        "iPad Pro (12.9-inch) (4th generation)"
      ],
      // andoridversions:['4.1','4.4','5.0','5.1','6.0','7.0','7.1','8.0','8.1','9.0','10.0','11.0','12.0','13.0','14.0'],
      andoridversions: ["8.0", "8.1", "9.0", "10.0", "11.0", "12.0", "13.0", '14.0'],
      // iosversions:["8.1", "8.2", "8.3", "8.4", "9.0", "9.1", "9.2", "9.3", "10.0", "10.1", "10.2", "10.3", "11.0", "11.1", "11.2", "11.3", "12.0", "12.2", "12.4", "13.0", "13.3", "13.4", "13.5", "13.6", "14.1", "14.2", "14.4", "14.5", "15.0", "15.2", "15.4", "15.5", "16.0", "16.2", "16.4", "17.0", "17.2", "17.4"],
      iosversions: [
        "16.0",
        "16.2",
        "16.4",
        "17.0",
        "17.2",
        "17.4"
      ],
      androidAppUrl: "",
      iosAppUrl: "",
      androidWordPressAppUrl: "",
      iosWordPressAppUrl: "",
      iosRecording: false,
      androidRecording: false,
      recordingUrl: null,
      recordingUniqueId: null,
      recordingUniqueIdAndroid: null,
      recordingProcessing: false,
      recordingProcessingAndroid: false,
      currentTime: "00:00:00",
      currentTimeAndroid: "00:00:00",
      isRunning: false,
      isRunningAndroid: false,
      intervalId: null,
      intervalIdAndroid: null,
      selectedLocation: {},
      selectedLocationAndroid: {},
      selectedLanguage: {},
      selectedLanguageAndroid: {},
      deviceModal: null,
      deviceModalLanguage: null,
      locationsArr: [],
      locationsArrCopy: [],
      locationInput: "",
      languageInput: "",
      updatingLocation: false,
      isAndroidLoaded: false,
      isIosLoaded: false,
      androidLanguages: [],
      iosLanguages: [],
      androidLanguagesCopy: [],
      iosLanguagesCopy: [],
      languageModal: false,
      updatingLanguage: false
    };
    this.startTime= null;
    this.startTimeAndroid= null;
    this.toggle = this.toggle.bind(this);
    this.iframeRef = React.createRef();
    this.handleLocationSubmit = this.handleLocationSubmit.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
  }
  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  static contextType = Errorstatuscon;
  componentDidMount() 
  {
    if (window.matchMedia("(max-width: 960px)").matches) {
      this.setState({
        ios: true,
        android: false,
      });
    }

    Createlambdatoken(this);
    CreateIOSlambdatoken(this);

    GetsplashApi(this, () => {
      if (parseInt(localStorage.getItem("website_technology")) === 5 || parseInt(localStorage.getItem("website_technology")) === 4) 
      {
        Verifyapistatus(this, () => {
          this.context.handlefunerror(this.state.error);
        });
      }
       else 
       {
        this.setState({
          error: false,
          spinner: false,
        });
        this.context.handlefunerror(this.state.error);
      }
        if( this.state.is_verified == 0)
        {
            this.setState({limitreached:true});
        }
      (
        (parseInt(localStorage.getItem("website_technology")) === 4) ||
        (parseInt(localStorage.getItem("website_technology")) === 5) ||
        parseInt(localStorage.getItem("website_technology")) === 2 ||
        parseInt(localStorage.getItem("website_technology")) === 3) &&
        iframeApi(params, this, () => {
          this.context.handleresult(
            this.state.connectivity_log,
            this.state.is_verified,
            this.state.is_connected,
            this.state.website_type,
            this.state.simulator_status
          );
        });
    });
    const params = {
      app_id: btoa(localStorage.getItem("Appid")),
    };

  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.isRunning !== this.state.isRunning) {
  //     this.handleTimer();
  //   }
  // }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
    clearInterval(this.state.intervalIdAndroid);
  }

  selectionmodel = (e) => {
    e.preventDefault();
    this.setState({
      screenshot: !this.state.screenshot,
    });
  };
  handleImageLoaded() {
    this.setState({ spinner: false });
  }
  saveScreenshot = (deviceRequested) => {
    if(deviceRequested === "#iosframe" && !this.state.isIosLoaded || deviceRequested === "#androidiframe"  && !this.state.isAndroidLoaded ) return;
    let iframe = document.querySelector(deviceRequested);
    iframe.contentWindow.postMessage({
      action: "screenshot"
  }, "*");
    let elementios = document.getElementById("preview-screnbtneffect-ios");
    let elementiosmob = document.getElementById("preview-screnbtneffect-ios-mob"); 
   let elementandroid = document.getElementById("preview-screnbtneffect-android-mob"); 

    if(deviceRequested === "#iosframe"){

      if(elementios){
        elementios.classList.add("preview-btntransform");
      }
      setTimeout(()=>{
        if(elementios){
          elementios.classList.remove("preview-btntransform");
        }
      },100)
    
      if(elementiosmob){
        elementiosmob.classList.add("preview-btntransform");
      }
      setTimeout(()=>{
        if(elementiosmob){
          elementiosmob.classList.remove("preview-btntransform");
        }
      },100)
    }
else{

  if(elementandroid){
    elementandroid.classList.add("preview-btntransform");
  }
  setTimeout(()=>{
    if(elementandroid){
      elementandroid.classList.remove("preview-btntransform");
    }
  },100)
}
    this.setState({
      screenshot: false,
    });
  };



  restartApp = (e) => {
    if(e == "#iosframe" && !this.state.isIosLoaded || e === "#androidiframe"  && !this.state.isAndroidLoaded ) return;
    let iframe = document.querySelector(e);
    iframe?.contentWindow.postMessage("restartApp", "*");
    let elementios = document.getElementById("preview-btneffect-ios");
    let elementandroid = document.getElementById("preview-btneffect-android");
    let elementiosmob = document.getElementById("preview-btneffect-ios-mob");


if(e === "#iosframe"){

  if(elementios){
    elementios.classList.add("preview-btntransform");
  }
  setTimeout(()=>{
    if(elementios){
      elementios.classList.remove("preview-btntransform");
    }
  },100)
  CreateIOSlambdatoken(this)
  if(elementiosmob){
    elementiosmob.classList.add("preview-btntransform");
  }
  setTimeout(()=>{
    if(elementiosmob){
      elementiosmob.classList.remove("preview-btntransform");
    }
  },100)
}
else{
  if(elementandroid){
    elementandroid.classList.add("preview-btntransform");
  }
  Createlambdatoken(this);

  setTimeout(()=>{
    if(elementandroid){
      elementandroid.classList.remove("preview-btntransform");
    }
  },100)
}

    

    this.setState({
      screenshot: false,
    });
  };

  onMyFrameLoad = () => {
    this.setState({
      spinner: false,
      iosspinner: false,
      androidspinner: false,
    });
    
  };

  handleiospreview = (version) => {

    CreateIOSlambdatoken(this,()=>{
      
    });
    let devices = [];
    
    if (version === "16.0" || version === "16.2" || version === "16.4") {
      devices = [
        "iPhone 11",
        "iPhone 11 Pro",
        "iPhone 11 Pro Max",
        "iPhone 12",
        "iPhone 12 Mini",
        "iPhone 12 Pro",
        "iPhone 12 Pro Max",
        "iPhone 13",
        "iPhone 13 Mini",
        "iPhone 13 Pro",
        "iPhone 13 Pro Max",
        "iPhone 14",
        "iPhone 14 Plus",
        "iPhone 14 Pro",
        "iPhone 14 Pro Max",
        "iPad (6th Generation)",
        "iPad (7th generation)",
        "iPad (8th generation)",
        "iPad (9th generation)",
        "iPad Air (3rd generation)",
        "iPad Air (4th generation)",
        "iPad Mini (5th generation)",
        "iPad Pro (11-inch)",
        "iPad Pro (11-inch) (2nd generation)",
        "iPad Pro (11-inch) (3rd generation)",
        "iPad Pro (12.9-inch) (3rd generation)",
        "iPad Pro (12.9-inch) (4th generation)"
      ];
    } else if (version === "17.0" || version === "17.2" || version === "17.4") {
      devices = [
        "iPhone 11",
        "iPhone 11 Pro",
        "iPhone 11 Pro Max",
        "iPhone 12",
        "iPhone 12 Mini",
        "iPhone 12 Pro",
        "iPhone 12 Pro Max",
        "iPhone 13",
        "iPhone 13 Mini",
        "iPhone 13 Pro",
        "iPhone 13 Pro Max",
        "iPhone 14",
        "iPhone 15",
        "iPhone 14 Plus",
        "iPhone 14 Pro Max",
        "iPhone 15 Plus",
        "iPhone 15 Pro",
        "iPhone 14 Pro",
        "iPhone 15 Pro Max",
        "iPad (6th Generation)",
        "iPad (7th generation)",
        "iPad (8th generation)",
        "iPad (9th generation)",
        "iPad Air (3rd generation)",
        "iPad Air (4th generation)",
        "iPad Mini (5th generation)",
        "iPad Pro (11-inch)",
        "iPad Pro (11-inch) (2nd generation)",
        "iPad Pro (11-inch) (3rd generation)",
        "iPad Pro (12.9-inch) (3rd generation)",
        "iPad Pro (12.9-inch) (4th generation)"
      ];}

    this.setState({
      osVersion: version,
      iosdevices: devices,
      devicename: devices[0],
      screenshot: false,
      iosspinner: true,
    });



  };

  handleiosdevice = (value) =>
    {
      CreateIOSlambdatoken(this,()=>{
      
      });
      this.setState({

        devicename: value,
      });
    }

  handleandroiddevicename = (e) => {
    Createlambdatoken(this,()=>{
      
    });
    if (e === "nexus5") 
    {
      this.setState({
        androiddevicename: e,
        androiddeviceOSname: "11.0",
        androidspinner: true,
        screenshot: false,
        androidscale: "80",
      });
    }
    else if (e === "pixel5") 
    {
      this.setState({
        androiddevicename: e,
        androiddeviceOSname: "12.0",
        androidspinner: true,
        screenshot: false,
        androidscale: "80",
      });
    } 
    else 
    {
      this.setState({
        androiddevicename: e,
        androiddeviceOSname: "11.0",
        androidspinner: true,
        screenshot: false,
        androidscale: "77",
      });
    }

    // this.setState({
    //     ioslanguage:e.target.value
    // },() => {
    //     this.setLanguage("#iosframe",this.state.ioslanguage)
    // })
  };
  mobhandleandroidosversion = (version) => 
  {
    Createlambdatoken(this,()=>{
      
    });
    let phones = [];
    
    if (version == 14.0) {
      phones = [
        "Google Pixel 8 Pro",
        "Nothing Phone(2a)",
        "Google Pixel 6A",
        "Google Pixel 8",
        "Google Pixel 7 Pro",
        "Google Pixel 7",
        "Google Pixel 6",
        "Google Pixel 6 Pro",
        "OnePlus 11 5G",
        "Realme GT2 Pro",
        "Galaxy S24 Ultra",
        "Galaxy S24 Plus",
        "Galaxy S24",
        "Galaxy A53 5G"
      ];
    } else if (version == 13.0) {
      phones = [
        "Google Pixel 7 Pro",
        "Google Pixel 7",
        "Google Pixel 4XL",
        "Xiaomi Mi 11",
        "Google Pixel 4",
        "Google Pixel 5 - 5G",
        "Galaxy Note 20 Ultra",
        "Galaxy A71 5G",
        "Samsung A51 5G",
        "Galaxy S21 5G",
        "Galaxy S21 Ultra 5G",
        "Galaxy Tab S8 Plus",
        "Galaxy Tab S7 Plus",
        "Google Pixel 6A",
        "Google Pixel 4A",
        "OnePlus 11 5G",
        "OnePlus 11R",
        "OnePlus 10 Pro",
        "Google Pixel 6",
        "Xiaomi Mi 12 Pro",
        "Galaxy A53 5G",
        "Samsung Galaxy Tab S8",
        "Galaxy S23 Ultra",
        "Galaxy S23 Plus",
        "Galaxy S23",
        "Galaxy S22 Ultra 5G",
        "Galaxy S22 Plus 5G",
        "Oppo Find X5 Pro",
        "Realme Narzo 50i",
        "Xiaomi Redmi Note 10S"
      ];
    } else if (version == 12.0) {
      phones = [
        "Galaxy S10 Plus",
        "Google Pixel 6",
        "Poco M2",
        "Xiaomi Redmi Note 10S",
        "Vivo T1 5G",
        "OnePlus 9",
        "OnePlus 9 Pro",
        "Oppo Reno 6 Pro",
        "OnePlus Nord 2",
        "Xiaomi Mi 11",
        "Xiaomi Mi 11 Pro",
        "Galaxy A53 5G",
        "Samsung Galaxy A40",
        "Galaxy S20",
        "Galaxy S20 Plus",
        "Google Pixel 4A",
        "Google Pixel 4XL",
        "Google Pixel 4",
        "Google Pixel 5 - 5G",
        "Galaxy Note 20 Ultra",
        "Galaxy A71 5G",
        "Samsung A51 5G",
        "Galaxy S20 Ultra 5G",
        "Galaxy S20 FE",
        "Galaxy S21 5G",
        "Galaxy S21 Ultra 5G",
        "Galaxy Tab S7 Plus",
        "Google Pixel 4A 5G",
        "Samsung Galaxy M30s",
        "Realme Narzo 50i",
        "Lenovo Tab M9",
        "Lenovo Tab M10 Plus"
      ];
    } else if (version == 11.0) {
      phones = [
        "OnePlus 9",
        "Galaxy A10S",
        "OnePlus 9 Pro",
        "Oppo Reno 6 Pro",
        "Realme Narzo 50i",
        "Galaxy Tab A7",
        "OnePlus Nord 2",
        "Poco M2",
        "Xiaomi Mi 11",
        "Xiaomi Mi 11 Pro",
        "Galaxy S20",
        "Galaxy S20 Plus",
        "OnePlus 8",
        "OnePlus 8 Pro",
        "Google Pixel 4A",
        "Google Pixel 4",
        "Google Pixel 5 - 5G",
        "Galaxy Note 20 Ultra",
        "Galaxy A71 5G",
        "Samsung A51 5G",
        "Galaxy S20 Ultra 5G",
        "OnePlus Nord",
        "OnePlus 8T",
        "Xiaomi Mi 10",
        "Xiaomi Mi 10 Pro",
        "Samsung Galaxy A40",
        "Galaxy S20 FE",
        "Galaxy S21 5G",
        "Galaxy S21 Ultra 5G",
        "Galaxy Tab S7 Plus",
        "Google Pixel 4A 5G",
        "OnePlus Nord N100",
        "OnePlus Nord N10 5G",
        "Vivo Y50",
        "Oppo Reno Pro",
        "Vivo V20",
        "Samsung Galaxy M30s",
        "Xiaomi Redmi Note 10S"
      ];
    } else if (version == 10.0) {
      phones = [
        "Galaxy S10 Plus",
        "Galaxy A10S",
        "LG Stylo 6",
        "Samsung Galaxy A10S",
        "Samsung Galaxy note 10 plus",
        "Galaxy S20",
        "OnePlus 8",
        "Google Pixel 4",
        "Samsung A51",
        "Samsung Galaxy S20 Ultra",
        "Google Pixel 4A",
        "Redmi Note 9 Pro",
        "Galaxy S20 Plus",
        "OnePlus 7T",
        "Galaxy Tab S7",
        "Galaxy Tab S7 Plus",
        "OnePlus Nord N100",
        "Oppo Reno Pro",
        "Realme 5",
        "Realme 5 Pro",
        "Redmi Note 7",
        "Redmi Note 7 Pro",
        "Redmi Note 8 Pro",
        "Samsung Galaxy M30s",
        "Samsung Galaxy A40",
        "Lenovo Tab M10 HD"
      ];
    } else if (version == 9.0) {
      phones = [
        "Google Pixel 3",
        "Galaxy A10S",
        "Amazon Fire HD 10",
        "Realme Pad",
        "Google Pixel 3 XL",
        "Samsung Galaxy A10S",
        "Galaxy S9 Plus",
        "OnePlus 6T",
        "Xiaomi Mi 8",
        "Xiaomi Mi Note 5 Pro",
        "Samsung Galaxy S10",
        "Galaxy S10 Plus",
        "Samsung Galaxy S10E",
        "Huawei P20 Pro",
        "Galaxy Tab S5E",
        "OnePlus 7",
        "OnePlus 7 pro",
        "Xiaomi Redmi Note 6 Pro",
        "Xiaomi Mi A3",
        "Vivo V15 Pro",
        "Oppo F11 Pro",
        "Galaxy note 10",
        "Google Pixel 3A",
        "Google Pixel 3A XL",
        "Huawei P30 Pro",
        "Moto G6",
        "Galaxy Tab S6",
        "Amazon Fire HD 8",
        "Realme 5",
        "Realme 5 Pro",
        "Redmi Note 7",
        "Redmi Note 8 Pro",
        "Samsung Galaxy A40"
      ];
    } else if (version == 8.1) {
      phones = ["Huawei Y9"];
    } else if (version == 8.0) {
      phones = [
        "Galaxy S9",
        "Google Pixel 2",
        "Sony Xperia xz2",
        "Google Pixel",
        "Galaxy S7",
        "LG G6",
        "Xiaomi Redmi Note 6"
      ];
    }
    // else if (version == 8.0) {
    //     phones = ["Galaxy S9", "Google Pixel 2", "Sony Xperia xz2", "Google Pixel", "Galaxy S7", "LG G6", "Xiaomi Redmi Note 6"];
    // } else if (version == 7.1) {
    //     phones = ["Nexus 9"];
    // } else if (version == 7.0) {
    //     phones = ["Galaxy J7 max", "Galaxy S8+", "Galaxy S8", "Nexus 6p", "Nexus 5x", "Huawei Honor 6x", "Oppo R9", "LG V20", "Galaxy Tab S3 9.3", "LG K8", "MC3300"];
    // } else if (version == 6.0) {
    //     phones = ["Galaxy J5", "HTC Ten", "Xiaomi Mi 5", "Huawei P10 Plus", "Huawei Honor joy 5s"];
    // } else if (version == 5.1) {
    //     phones = ["Gionee Marathon M5"];
    // } else if (version == 5.0) {
    //     phones = ["Galaxy S6", "MotoG 2nd gen"];
    // } else if (version == 4.4) {
    //     phones = ["Galaxy S5", "Galaxy S4", "HTC One M8"];
    // } else if (version == 4.1) {
    //     phones = ["Galaxy S3"];
    // } 
    else {
        phones = []; 
    }

    this.setState({
      androiddevices: phones,
      androiddeviceOSname: version,
      androiddevicename:phones[0],
      androidspinner: true,
      screenshot: false,
    });



    
  };

  mobhandleandroiddevice = (value) => 
    {
      Createlambdatoken(this,()=>{});
      this.setState({androiddevicename:value});  
    }

  handleAndroid = () => {
    this.setState({
      android: true,
      ios: false,
    });
  };

  unlink = () => {
    if(this.state.deviceModal === "ios" && !this.state.isIosLoaded || this.state.deviceModal === "android"  && !this.state.isAndroidLoaded ) return;
    this.setState({ mdlunlink: !this.state.mdlunlink, locationsArr: this.state.locationsArrCopy, locationInput:''});
  }

  unlinkLanguage = () => {
    if(this.state.deviceModalLanguage === "ios" && !this.state.isIosLoaded || this.state.deviceModalLanguage === "android"  && !this.state.isAndroidLoaded ) {
      return
    } else{
      if(this.state.deviceModalLanguage === "ios"){
        this.setState({ languageModal: !this.state.languageModal, languageInput:'', iosLanguages: this.state.iosLanguagesCopy});
      } else{
        this.setState({ languageModal: !this.state.languageModal, languageInput:'', androidLanguages: this.state.androidLanguagesCopy});
      }
    }
  }

  handleBtnDone =() =>
  {
    this.setState({ mdlunlink: false });
  }

  handleBtnDoneLanguage =() =>
    {
      this.setState({ languageModal: false });
    }

  handleIos = () => {
    this.setState({
      ios: true,
      android: false,
    });
  };
  copyios = () => {
    this.setState({
      copiedLeft: true,
      copiedRight: false,
    });
    const el = document.createElement("input");
    if(localStorage.getItem("website_technology") == 3)
    {
      el.value = "https://testflight.apple.com/join/DKgriDVM";
    }
    else if(localStorage.getItem("website_technology") == 2)
    {
      el.value = "https://testflight.apple.com/join/1cvKgAXc";
    }
    else if(localStorage.getItem("website_technology") == 4)
    {
      el.value = "https://testflight.apple.com/join/OLPs2xOB";
    }
    else if(localStorage.getItem("website_technology") == 5)
    {
      el.value = "https://testflight.apple.com/join/cq3qO8y7"
    }  
    else
    {
      el.value = "";
    }                  
    // el.value = "https://testflight.apple.com/join/I7Xx3gMA";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };
  copygplay = () => {
    this.setState({
      copiedRight: true,
      copiedLeft: false,
    });
    const el = document.createElement("input");
    // el.value =
    //   "https://play.google.com/store/apps/details?id=com.appmysite.shop";
    if(localStorage.getItem("website_technology") == 3)
    {
      el.value = "https://play.google.com/store/apps/details?id=com.appmysite.custom";
    }
    else if(localStorage.getItem("website_technology") == 2 )
    {
      el.value = "https://play.google.com/store/apps/details?id=com.appmysite.web";
    }
    else if(localStorage.getItem("website_technology") == 4)
    {
      el.value = "https://play.google.com/store/apps/details?id=com.appmysite.wordpress";
    }
    else if(localStorage.getItem("website_technology") == 5)
    {
      el.value = "https://play.google.com/store/apps/details?id=com.appmysite.shop";
    }
    else 
    {
      el.value = "";
    }
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };
  handleAppdropdown = (e) =>
  {
    this.setState({ItemAppPopup:!this.state.ItemAppPopup});
  }

    handleAppiosdropdown = (e) =>
    {
      this.setState({ItemAppIOSPopup:!this.state.ItemAppIOSPopup});
    }

  mobhandleAppdropdown = (e) =>
  {
    this.setState({ItemMobAppPopup:!this.state.ItemMobAppPopup});
  }

  handleAppAndoriddropdown = (e) =>
  {
    this.setState({ItemAppAndoridPopup:!this.state.ItemAppAndoridPopup});
  }

    handleAppAndoriddevicedropdown = (e) =>
    {
      this.setState({ItemAppAndoridDevicePopup:!this.state.ItemAppAndoridDevicePopup});
    }

  

  mobhandleAppAndoriddropdown = (e) =>
  {
    this.setState({ItemMobAppAndoridPopup:!this.state.ItemMobAppAndoridPopup});
  }

  closeLimitReachReachPopup = (e) =>
	  {
	  
		this.setState({limitreached:false});
	  }

  handleStart = (device) => {
    if(device === "ios"){
      this.setState({ isRunning: true }, () => {
        if (this.state.isRunning) {
          this.startTime = new Date();
          this.handleTimer(device);
        }
      });
    } else{
      this.setState({ isRunningAndroid: true }, () => {
        if (this.state.isRunningAndroid) {
          this.startTimeAndroid = new Date();
          this.handleTimerAndroid(device);
        }
      });
    }
  };

  handleStop = (device) => {
    if(device === "ios"){
      this.setState({ isRunning: false }, () => {
        this.handleTimer(device)
      })
    }else{
      this.setState({ isRunningAndroid: false }, () => {
        this.handleTimerAndroid(device)
      })
    }
  };

  handleTimer = () => {
    if (this.state.isRunning) {
      this.state.intervalId = setInterval(() => {
        const now = new Date();
        const elapsedTime = now - this.startTime;
        const hours = Math.floor((elapsedTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, '0');
        const minutes = Math.floor((elapsedTime % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
        const seconds = Math.floor((elapsedTime % (1000 * 60)) / 1000).toString().padStart(2, '0');
        this.setState({ currentTime: `${hours}:${minutes}:${seconds}` });
      }, 1000);
    } else {
      clearInterval(this.state.intervalId);
       this.setState({
        intervalId : null,
        currentTime: "00:00:00"
      }) 
    }
  };

  handleTimerAndroid = () => {
    if (this.state.isRunningAndroid) {
      this.state.intervalIdAndroid = setInterval(() => {
        const now = new Date();
        const elapsedTime = now - this.startTimeAndroid;
        const hours = Math.floor((elapsedTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, '0');
        const minutes = Math.floor((elapsedTime % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
        const seconds = Math.floor((elapsedTime % (1000 * 60)) / 1000).toString().padStart(2, '0'); 
        this.setState({ currentTimeAndroid: `${hours}:${minutes}:${seconds}` });
      }, 1000);
    } else {
      clearInterval(this.state.intervalIdAndroid);
      this.setState({
        intervalIdAndroid : null,
        currentTimeAndroid: "00:00:00"
      });
    }
  };
  
  handleRecordingStart = (device) => {
    if(device === "ios" && !this.state.isIosLoaded || device === "android"  && !this.state.isAndroidLoaded ) return;
    lambdaStartRecording(device === "ios" ? this.state.ioslambdatoken : this.state.lambdatoken, this, device, this.handleStart)
  }

  totalSecondsElapsed = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  handleRecordingStop = (device) => {
    const secondsElapsed = this.totalSecondsElapsed(this.state[device === "ios" ? 'currentTime' : 'currentTimeAndroid' ]);
    const timer = secondsElapsed <= 120 ? 8 : 4 * (Math.ceil(secondsElapsed / 60));
    lambdaStopRecording(device === "ios" ? this.state.ioslambdatoken : this.state.lambdatoken, this, device, this.handleStop)
    setTimeout(async () => {
      const response = await lambdaGetRecording(device === "ios" ? this.state.ioslambdatoken : this.state.lambdatoken, this)
      if(response.data){
        this.setState({
          [device === "ios" ? 'recordingProcessing' : 'recordingProcessingAndroid']:false
        })
        const { data : { videos } } = response.data;
        const url = videos.find(elem => elem.video_url.includes(this.state[device === "ios" ? 'recordingUniqueId' : 'recordingUniqueIdAndroid']))?.video_url
        if(url){
          const linkElement = document.querySelector('#download-recording')
          linkElement.setAttribute('href', url)
          linkElement.click()
        }
      }
    }, timer * 1000)
  }

  handleLocationSubmit = (event) =>{
    event?.preventDefault()
    if(!this.state.locationInput){
      this.setState({
        locationsArr: this.state.locationsArrCopy
      })
    }else{
      const filteredLocations = this.state.locationsArrCopy.filter(location => 
        location.name.toLowerCase().includes(this.state.locationInput.toLowerCase()))
      this.setState({
        locationsArr: filteredLocations
      })
    }
  }

  handleLanguageSubmit = (event) =>{
    event?.preventDefault()
    if(this.state.deviceModalLanguage === "ios"){
      if(!this.state.languageInput){
        this.setState({
          iosLanguages: this.state.iosLanguagesCopy
        })
      } else {
        const filteredLanguages = this.state.iosLanguagesCopy.filter(location => 
          location.name.toLowerCase().includes(this.state.languageInput.toLowerCase()))
        this.setState({
          iosLanguages: filteredLanguages
        })
      }
    } else {
      if(!this.state.languageInput){
        this.setState({
          androidLanguages: this.state.androidLanguagesCopy
        })
      } else {
        const filteredLanguages = this.state.androidLanguagesCopy.filter(location => 
          location.name.toLowerCase().includes(this.state.languageInput.toLowerCase()))
        this.setState({
          androidLanguages: filteredLanguages
        })
      }
    }
  }

  handleLocationChange = (event) => {
      this.setState({
        locationInput: event.target.value
      })
  }

  gcd(a, b) {
    return b === 0 ? a : this.gcd(b, a % b);
  }
  
  deviceRatio(deviceWidth, deviceHeight){
    const divisor = this.gcd(deviceWidth, deviceHeight);
    const widthRatio = deviceWidth / divisor;
    const heightRatio = deviceHeight / divisor;
    if(widthRatio > 2500) {
      return '3/4'
    }
    return `${widthRatio}/${heightRatio}`;
  }
  
  getDeviceStyle(){
    const deviceDetails = iosDevices[this.state.osVersion].find(device => device.deviceName === this.state.devicename);
    return {
      width: "380px",
      marginTop: "100px",
      aspectRatio : this.deviceRatio(deviceDetails.deviceWidth, deviceDetails.deviceHeight)
    }
  }

  render() 
  {
    const Errordata = {
      heading_first: "Connect website with app",
      heading_second: "You have connectivity issue with your website",
      heading_third:
        "You have to check your website connectivity to check app preview",
      button_text: "Run Troubleshooter",
      button_link: "/app/connect/troubleshoot-logs",
    };
    const Errordata_plugin = {
      heading_first: "Connect website with app",
      heading_second:
        "This step is mandatory to sync your website with the mobile app.",
      heading_third:
        "Install the AppMySite WP plugin, enter the API details and verify it to connect website with app.",
      button_text: "Connect App",
      button_link: "/app/connect/wp-plugin",
    };
    const Errordata_api = {
      heading_first: "Connect website with app",
      heading_second:
        "This step is mandatory to sync your website with the mobile app.",
      heading_third:
        "Enter the API details and verify it to connect website with app.",
      button_text: "Connect App",
      button_link: "/app/connect/wp-grant-access",
    };
    const Errordata_for_wordpress = {
      heading_first: "Connect website with app",
      heading_second:
        "This step is mandatory to sync your website with the mobile app.",
      heading_third:
        "Install the AppMySite WP plugin and verify it in to connect website with app.",
      button_text: "Connect App",
      button_link: "/app/connect/wp-plugin",
    };
    const checkurl = () => {
      //WP     
      
    if((parseInt(localStorage.getItem('website_technology')) == 4))
    {
        return (
          "https://embed.lambdatest.com?sessionToken="+this.state.ioslambdatoken+
          "&device="+ this.state.devicename+
          "&osVersion="+this.state.osVersion+
          "&deviceType=simulator"+
          // "&appUrl=https://d3fg3fwfxon4h5.cloudfront.net/1/311969/ios/builds/1.1.5/ams_ios_311969_live.ipa"+
            "&appUrl="+this.state.iosWordPressAppUrl+
          "&appLaunchParams="+this.state.iosdata
      );
    }
    // Woo Comm
    else if((parseInt(localStorage.getItem('website_technology')) == 5))
    {

      return (
        "https://embed.lambdatest.com?sessionToken="+this.state.ioslambdatoken+
        "&device="+ this.state.devicename+
        "&osVersion="+this.state.osVersion+
        "&deviceType=simulator"+
        // "&appUrl=https://d3fg3fwfxon4h5.cloudfront.net/1/306/ios/builds/3.11.11/ams_ios_306_live.ipa"+
        "&appUrl="+this.state.iosWordPressAppUrl+
        "&appLaunchParams="+this.state.iosdata
    );

    }
    else
    {
      return (
        "https://embed.lambdatest.com?sessionToken="+this.state.ioslambdatoken+
        "&device="+ this.state.devicename+
        "&osVersion="+this.state.osVersion+
        "&deviceType=simulator"+
        // "&appUrl=https://d32med8i20nhsa.cloudfront.net/20/440/ios/builds/2.1.34/ams_ios_440_test.ipa"+
        "&appUrl="+this.state.iosAppUrl+
        "&appLaunchParams="+this.state.iosdata
    );
    }

    };
    const androidcheckurl = () => {
      
      if((parseInt(localStorage.getItem('website_technology')) == 4))
      {

        return (
            "https://embed.lambdatest.com?sessionToken="+this.state.lambdatoken+
            "&device="+ this.state.androiddevicename+
            "&osVersion="+this.state.androiddeviceOSname+
            "&deviceType=emulator"+
            // "&appUrl=https://d3fg3fwfxon4h5.cloudfront.net/1/311969/android/builds/2.1.6/ams_android_311969_live.apk"+
            "&appUrl="+this.state.androidWordPressAppUrl+
            "&appLaunchParams="+this.state.data
        );
      }
      else if((parseInt(localStorage.getItem('website_technology')) == 5))
        {
          return (
            "https://embed.lambdatest.com?sessionToken="+this.state.lambdatoken+
            "&device="+ this.state.androiddevicename+
            "&osVersion="+this.state.androiddeviceOSname+
            "&deviceType=emulator"+
            // "&appUrl=https://d3fg3fwfxon4h5.cloudfront.net/1/306/android/builds/4.1.13/ams_android_306_live.apk"+
            "&appUrl="+this.state.androidWordPressAppUrl+
            "&appLaunchParams="+this.state.data
        ); 
        }
      else
      {
        return (
          "https://embed.lambdatest.com?sessionToken="+this.state.lambdatoken+
          "&device="+ this.state.androiddevicename+
          "&osVersion="+this.state.androiddeviceOSname+
          "&deviceType=emulator"+
          // "&appUrl=https://d33cfg8bjlyn3i.cloudfront.net/12/319/android/builds/1.1.0/ams_android_319_dev.apk"+
          "&appUrl="+this.state.androidAppUrl+
          "&appLaunchParams="+this.state.data
        );
      }
    };
    return (
      <>
        {this.state.spinner ? (
          <div className="newspinner previewspinner">
          <svg xmlns="http://www.w3.org/2000/svg" 
    xlink="http://www.w3.org/1999/xlink" 
    style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
    width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
    <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
    <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
      <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
      style={{animationplaystate: "running", animationdelay: "0s"}}>
      </animateTransform>
    </circle>
    </g>
    <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
    <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
    <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
    style={{animationplaystate: "running", animationdelay: "0s"}}>
    </animateTransform>
    </circle>
    </g>
    <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
    <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
    <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
    style={{animationplaystate: "running", animationdelay: "0s"}}>

    </animateTransform>
    </circle>
    </g>
    <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
    <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
    <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
    style={{animationplaystate: "running", animationdelay: "0s"}}>

    </animateTransform>
    </circle>
    </g>
    </svg>
        </div>
        ) : (
          <>
            {(() => {
                  return (
                    <>
                     {/* { 
                        (this.state.is_verified === 0 &&  parseInt(localStorage.getItem('website_technology')) === 4 ) ||
                        (this.state.is_verified === 0 &&  parseInt(localStorage.getItem('website_technology')) === 5 )
                      ?
                        <Connectpopup limitreached={this.state.limitreached} closefunction={this.closeLimitReachReachPopup}/>
                      : <></>
                      } */}
                      <div className="  mob-ios-android-tabs lamda-mob-ios-android">
                        <div
                          className="preview-iosmob"
                          onClick={(e) => this.handleIos()}
                        >
                            
                          {this.state.ios === true ? (
                            <>
                              <img
                                src={
                                  require("../../assets/images/dashboard/apple-previreon.png")
                                    .default
                                }
                                alt="preview-ios"
                                className="img-fluid"
                              />
                            </>
                          ) : (
                            <>
                              <img
                                src={
                                  require("../../assets/images/dashboard/apple-preview-off.png")
                                    .default
                                }
                                alt="preview-ios"
                                className="img-fluid"
                              />
                            </>
                          )}

                          {/* <div>
                          <Dropdown className=" appPreview-dropdown"
                                    isOpen={this.state.ItemMobAppPopup}
                                    toggle={this.mobhandleAppdropdown}
                                    >
                                      <div className={this.state.ItemMobAppPopup? "report-aerotop-ios": "report-aerohidden"}>
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/dropdownaerotop.png")
                                            .default
                                        }
                                        className=""
                                        alt=" "
                                      />
                                      </div>
                                    <DropdownToggle caret>
                                    {this.state.iosversions.map((osversion,indx) => {
                                        return (this.state.osVersion == osversion ? osversion : '');
                                      })
                                      }
                                    
                                    </DropdownToggle>
                                    <DropdownMenu end={true}>
                                    {this.state.iosversions.map((osversion,indx) => {
                                        return (<DropdownItem active={this.state.osVersion == osversion ? true: ""} 
                                        onClick={() => this.handleiospreview(osversion)}>{osversion}</DropdownItem>);
                                      })
                                    }
                                     
                                    </DropdownMenu>
                            </Dropdown>



                          </div> */}
                          
                        </div>

                        <div
                          className=" preview-androidmob"
                          onClick={(e) => this.handleAndroid()}
                        >
                          
                          {this.state.android === true ? (
                            <>
                              <img
                                src={
                                  require("../../assets/images/dashboard/android-previewon.png")
                                    .default
                                }
                                alt="preview-android"
                                className="img-fluid "
                              />
                             
                            </>
                          ) : (
                            <>
                              <img
                                src={
                                  require("../../assets/images/dashboard/android-preview-off.png")
                                    .default
                                }
                                alt="preview-android"
                                className="img-fluid"
                              />
                              {/* <p
                                style={{
                                  color: "#7782A1",
                                  fontSize: "14px",
                                  fontFamily: "Proxima-Regular",
                                  marginBottom: "0px",
                                }}
                              >
                                Android
                              </p> */}
                            </>
                          )}
                          {/* <div>
                          <Dropdown className="appPreview-dropdown"
                                    isOpen={this.state.ItemMobAppAndoridPopup}
                                    toggle={this.mobhandleAppAndoriddropdown}
                                    >
                                      <div className={this.state.ItemMobAppAndoridPopup? "report-aerotop-android": "report-aerohidden"}>
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/dropdownaerotop.png")
                                            .default
                                        }
                                        className=""
                                        alt=" "
                                      />
                                      </div>
                                    <DropdownToggle caret>
                                      {this.state.andoridversions.map((osversion,indx) => {
                                          return (this.state.androiddeviceOSname == osversion ? osversion : '');
                                        })
                                        }
                                    </DropdownToggle>
                                    <DropdownMenu end={true}>
                                    {this.state.andoridversions.map((osversion,indx) => {
                                        return (<DropdownItem active={this.state.androiddeviceOSname == osversion ? true: ""} 
                                        onClick={() => this.mobhandleandroidosversion(osversion)}>{osversion}</DropdownItem>);
                                      })
                                    }
                                     
                                    </DropdownMenu>
                                  </Dropdown>
                          </div> */}
                        </div>
                      </div>
                      {window.screen.width <= 1200 &&  <div className="lamda-dropdown-select">
                        {this.state.ios &&  
                        <div  className={`${!this.state.isIosLoaded ? "lamda-preview-opacity" : ""}`}>
                           <div className="d-flex align-items-center justify-content-center top_screenshot">
                                    <div className="preview-new-tab d-flex justify-content-center align-items-center">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/apple-logs.png")
                                            .default
                                        }
                                        alt="preview-android"
                                        className="img-fluid mr-2"
                                      />
                                      <p></p>
                                    </div>
                                    <div className="appPreview-new-button">

                                  <div className="">
                                    <Dropdown className=" appPreview-dropdown appPreview-dropdown-margin"
                                    isOpen={this.state.ItemAppPopup}
                                    toggle={this.handleAppdropdown}
                                    // disabled={!this.state.isIosLoaded}
                                    >
                                      <div className={this.state.ItemAppPopup? "report-aerotop-ios": "report-aerohidden"}>
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/dropdownAero.png")
                                            .default
                                        }
                                        className=""
                                        alt=" "
                                      />
                                      </div>
                                      <DropdownToggle caret>
                                      {this.state.iosversions.map((osversion,indx) => {
                                        return (this.state.osVersion == osversion ? osversion : '');
                                      })
                                      }
                                    
                                    
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      
                                    {this.state.iosversions.map((osversion,indx) => {
                                        return (<DropdownItem active={this.state.osVersion == osversion ? true: ""} 
                                        onClick={() => this.handleiospreview(osversion)}>{osversion}</DropdownItem>);
                                      })
                                    }
                                     
                                    </DropdownMenu>
                                  </Dropdown>
                                  </div>
                                  {
                
              }
                                    </div>
                                      <div className="appPreview-new-button">
                                        <div className="">
                                          <Dropdown className=" appPreview-dropdown"
                                            isOpen={this.state.ItemAppIOSPopup}
                                            toggle={this.handleAppiosdropdown}
                                            // disabled={!this.state.isIosLoaded}
                                            >
                                              <div className={this.state.ItemAppIOSPopup? "report-aerotop-ios": "report-aerohidden"}>
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/dropdownAero.png")
                                                    .default
                                                }
                                                className=""
                                                alt=" "
                                              />
                                              </div>
                                              <DropdownToggle caret>
                                              {this.state.iosdevices.map((device,indx) => {
                                                return (this.state.devicename == device ? device : '');
                                              })
                                              }
                                            
                                            
                                            </DropdownToggle>
                                            <DropdownMenu>
                                              
                                            {this.state.iosdevices.map((device,indx) => {
                                                return (<DropdownItem active={this.state.devicename == device ? true: ""} 
                                                onClick={() => this.handleiosdevice(device)}>{device}</DropdownItem>);
                                              })
                                            }
                                            
                                            </DropdownMenu>
                                          </Dropdown>
                                        </div>
                                        
                                      </div>
                                    </div>
                        </div>
                       }
              
             {this.state.android && 
             <div  className={`${!this.state.isAndroidLoaded ? "lamda-preview-opacity" : ""}`}>
                <div className="d-flex align-items-center justify-content-center top_screenshot">
                                        <div className="preview-new-tab d-flex justify-content-center align-items-center">
                                          <img
                                            src={
                                              require("../../assets/images/dashboard/android_active.png")
                                                .default
                                            }
                                            alt="preview-android"
                                            className="img-fluid mr-2"
                                          />
                                          <p></p>
                                        </div>
                                        <div className="appPreview-new-button ">
                                          <div className="">
                                                
                                          <div className="">
                                      <Dropdown className="appPreview-dropdown appPreview-dropdown-margin"
                                      isOpen={this.state.ItemAppAndoridPopup}
                                      toggle={this.handleAppAndoriddropdown}
                                      // disabled={!this.state.isAndroidLoaded}
                                      >
                                        <div className={this.state.ItemAppAndoridPopup? "report-aerotop-android": "report-aerohidden"}>
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/dropdownAero.png")
                                              .default
                                          }
                                          className=""
                                          alt=" "
                                        />
                                        </div>
                                      <DropdownToggle caret>
                                        {this.state.andoridversions.map((osversion,indx) => {
                                            return (this.state.androiddeviceOSname == osversion ? osversion : '');
                                          })
                                          }
                                      </DropdownToggle>
                                      <DropdownMenu end={true}>
                                      {this.state.andoridversions.map((osversion,indx) => {
                                          return (<DropdownItem active={this.state.androiddeviceOSname == osversion ? true: ""} 
                                          onClick={() => this.mobhandleandroidosversion(osversion)}>{osversion}</DropdownItem>);
                                        })
                                      }
                                      
                                      </DropdownMenu>
                                    </Dropdown>
                                    </div>
                                          </div>
                                        </div>
                                        <div className="appPreview-new-button">
                                          <div className="">
                                                
                                          <div className="">
                                      <Dropdown className="appPreview-dropdown"
                                      isOpen={this.state.ItemAppAndoridDevicePopup}
                                      toggle={this.handleAppAndoriddevicedropdown}
                                      // disabled={!this.state.isAndroidLoaded}
                                      >
                                        <div className={this.state.ItemAppAndoridDevicePopup? "report-aerotop-android": "report-aerohidden"}>
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/dropdownAero.png")
                                              .default
                                          }
                                          className=""
                                          alt=" "
                                        />
                                        </div>
                                      <DropdownToggle caret>
                                        {this.state.androiddevices.map((device,indx) => {
                                            return (this.state.androiddevicename == device ? device : '');
                                          })
                                          }
                                      </DropdownToggle>
                                      <DropdownMenu end={true}>
                                      {this.state.androiddevices.map((device,indx) => {
                                          return (<DropdownItem active={this.state.androiddevicename == device ? true: ""} 
                                          onClick={() => this.mobhandleandroiddevice(device)}>{device}</DropdownItem>);
                                        })
                                      }
                                      
                                      </DropdownMenu>
                                    </Dropdown>
                                    </div>
                                          </div>
                                        </div>
                                      </div>
              </div> }
              </div>}
          


                      <div
                        className="d-flex justify-content-center text-center preview-rowreverse"
                        style={{ position: "relative", minHeight: "400px" }}
                      >
                        {/* {this.state.error === false ?  */}
                        {/* ( */}
                          
                          <>
                            {this.state.iosdata !== null && (
                              <div className={`preview-left-pannel lamda-preview-left-pannel ${!this.state.isIosLoaded ? 'lamda-preview-opacity' : ""}`}>
                               {window.screen.width > 1200 && <div className="android-frame lamda-apple-frame">
                                  <div className="d-flex align-items-center justify-content-center top_screenshot">
                                    <div className="preview-new-tab d-flex justify-content-center align-items-center">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/apple-logs.png")
                                            .default
                                        }
                                        alt="preview-android"
                                        className="img-fluid mr-2"
                                      />
                                      <p></p>
                                    </div>
                                    <div className="appPreview-new-button">

                                  <div className="">
                                    <Dropdown className=" appPreview-dropdown appPreview-dropdown-margin"
                                    isOpen={this.state.ItemAppPopup}
                                    toggle={this.handleAppdropdown}
                                    // disabled={!this.state.isIosLoaded}
                                    >
                                      <div
                                       className={this.state.ItemAppPopup? "report-aerotop-ios": "report-aerohidden"}>
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/dropdownAero.png")
                                            .default
                                        }
                                        className=""
                                        alt=" "
                                      />
                                      </div>
                                      <DropdownToggle caret>
                                      {this.state.iosversions.map((osversion,indx) => {
                                        return (this.state.osVersion == osversion ? osversion : '');
                                      })
                                      }
                                    
                                    
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      
                                    {this.state.iosversions.map((osversion,indx) => {
                                        return (<DropdownItem active={this.state.osVersion == osversion ? true: ""} 
                                        onClick={() => this.handleiospreview(osversion)}>{osversion}</DropdownItem>);
                                      })
                                    }
                                     
                                    </DropdownMenu>
                                  </Dropdown>
                                  </div>
                                     
                                    </div>
                                      <div className="appPreview-new-button">
                                        <div className="">
                                          <Dropdown className=" appPreview-dropdown"
                                            isOpen={this.state.ItemAppIOSPopup}
                                            toggle={this.handleAppiosdropdown}
                                            // disabled={!this.state.isIosLoaded}
                                            >
                                              <div className={this.state.ItemAppIOSPopup? "report-aerotop-ios": "report-aerohidden"}>
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/dropdownAero.png")
                                                    .default
                                                }
                                                className=""
                                                alt=" "
                                              />
                                              </div>
                                              <DropdownToggle caret>
                                              {this.state.iosdevices.map((device,indx) => {
                                                return (this.state.devicename == device ? device : '');
                                              })
                                              }
                                            
                                            
                                            </DropdownToggle>
                                            <DropdownMenu>
                                              
                                            {this.state.iosdevices.map((device,indx) => {
                                                return (<DropdownItem active={this.state.devicename == device ? true: ""} 
                                                onClick={() => this.handleiosdevice(device)}>{device}</DropdownItem>);
                                              })
                                            }
                                            
                                            </DropdownMenu>
                                          </Dropdown>
                                        </div>
                                        
                                      </div>
                                    </div>
                                </div>}
                                <div className="apple-preview-div  preview-rowreverse">

                                <div className=" apple-preview-div-left lamda-apple-preview-div-left">
                                <div
                                        className="apple-preview-div-left-top apple-preview-div-left-top-left "
                                        id="preview-btneffect-android"
                                      >
                                       
                                        <svg   onClick={(e) =>
                                            this.restartApp("#iosframe")
                                          } xmlns="http://www.w3.org/2000/svg" width="27.443" height="27.444" viewBox="0 0 27.443 27.444">
                                          <g id="Group_40115" data-name="Group 40115" transform="translate(-1259.077 -211.687)">
                                            <path id="Path_103621" data-name="Path 103621" d="M9.094,0a9.094,9.094,0,1,0,9.094,9.094,9.206,9.206,0,0,0-2.259-6" transform="matrix(0.485, 0.875, -0.875, 0.485, 1276.343, 213.047)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                            <path id="Path_103620" data-name="Path 103620" d="M5.871,0V5.871H0" transform="translate(1275.699 215.701)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                          </g>
                                        </svg>
                                        <p className="reloadtooltip Reset-left-tooltip">Reset</p>
                                      </div>

                                  <div style={{position: "relative"}}>
                                <a id="download-recording" className="d-none" target="_blank">Download</a>
                               {!this.state.iosRecording && !this.state.recordingProcessing && <div 
                                onClick={()=> this.handleRecordingStart("ios")}
                                    className=" apple-preview-div-left-top apple-preview-div-left-top-left"
                                   id="preview-btneffect-ios"
                                  >
                                <>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16">
                                  <g id="Group_43329" data-name="Group 43329" transform="translate(-1312.5 -527.5)">
                                    <rect id="Rectangle_32508" data-name="Rectangle 32508" width="15" height="14" rx="1.623" transform="translate(1313.5 528.5)" fill="none" stroke="#bcc1ce" stroke-linejoin="round" stroke-width="2"/>
                                    <path id="Path_119302" data-name="Path 119302" d="M22.5,14.5l-7-5v-4l7-5Z" transform="translate(1313 528)" fill="none" stroke="#bcc1ce" stroke-linejoin="round" stroke-width="2"/>
                                  </g>
                                </svg>

                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                      <path id="Union_3162" data-name="Union 3162" d="M-4513.486,617.485A11.92,11.92,0,0,1-4517,609a11.922,11.922,0,0,1,3.514-8.485A11.925,11.925,0,0,1-4505,597a11.924,11.924,0,0,1,8.485,3.514A11.923,11.923,0,0,1-4493,609a11.921,11.921,0,0,1-3.515,8.485A11.92,11.92,0,0,1-4505,621,11.921,11.921,0,0,1-4513.486,617.485ZM-4515,609a10.012,10.012,0,0,0,10,10,10.012,10.012,0,0,0,10-10,10.011,10.011,0,0,0-10-10A10.011,10.011,0,0,0-4515,609Zm8,4a2,2,0,0,1-2-2v-4a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2v4a2,2,0,0,1-2,2Z" transform="translate(4517 -597)" fill="#ff4848"/>
                                    </svg> */}

                                     <p className="reloadtooltip "> Create a screen recording of the app’s simulation.</p></>

                                  </div>}
                                  {this.state.recordingProcessing && <div 
                                      className=" apple-preview-div-left-top apple-preview-div-left-top-left"
                                      id="preview-btneffect-ios"
                                      >
                                        <div className="new-grey-loader"></div>
                                  </div>}
                                 { this.state.iosRecording && !this.state.recordingProcessing &&  <div onClick={() => this.handleRecordingStop("ios")} style={{position: "relative"}}>
                                    <div className="apple-preview-div-left-top apple-preview-div-left-top-left" id="preview-btneffect-android">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path id="Union_3162" data-name="Union 3162" d="M-4513.486,617.485A11.92,11.92,0,0,1-4517,609a11.922,11.922,0,0,1,3.514-8.485A11.925,11.925,0,0,1-4505,597a11.924,11.924,0,0,1,8.485,3.514A11.923,11.923,0,0,1-4493,609a11.921,11.921,0,0,1-3.515,8.485A11.92,11.92,0,0,1-4505,621,11.921,11.921,0,0,1-4513.486,617.485ZM-4515,609a10.012,10.012,0,0,0,10,10,10.012,10.012,0,0,0,10-10,10.011,10.011,0,0,0-10-10A10.011,10.011,0,0,0-4515,609Zm8,4a2,2,0,0,1-2-2v-4a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2v4a2,2,0,0,1-2,2Z" transform="translate(4517 -597)" fill="#ff4848"></path></svg>
                                      <p className="reloadtooltip stop-recording-tooltip">Stop Recording</p>
                                    </div>
                                    <p className="timerstart">{this.state.currentTime}</p>
                                  </div>}
                                  {/* <p className="timerstart">00.00.15 ios</p> */}
                                  {/* <p className="timerstart">{this.state.currentTime}</p> */}
                                  </div>
                                  <div
                                    className=" apple-preview-div-left-top apple-preview-div-left-top-left  1"
                                    id="preview-screnbtneffect-ios"
                                  >
                                    <svg  onClick={(e) =>
                                        this.saveScreenshot("#iosframe")
                                      } xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                        <g id="Group_40111" data-name="Group 40111" transform="translate(-1333 -525)">
                                          <path id="Path_103616" data-name="Path 103616" d="M10,4V8a2,2,0,0,1-2,2H4" transform="translate(1342 534)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                          <path id="Path_103617" data-name="Path 103617" d="M10,8V4A2,2,0,0,0,8,2H4" transform="translate(1342 524)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                          <path id="Path_103618" data-name="Path 103618" d="M2,4V8a2,2,0,0,0,2,2H8" transform="translate(1332 534)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                          <path id="Path_103619" data-name="Path 103619" d="M2,8V4A2,2,0,0,1,4,2H8" transform="translate(1332 524)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                          <rect id="Rectangle_30022" data-name="Rectangle 30022" width="8" height="8" rx="1" transform="translate(1339 531)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                        </g>
                                      </svg>
                                    <p className="reloadtooltip">Take screenshots at any given point during the app simulation.</p>
                                  </div>
                                  <div
                                    className="apple-preview-div-left-top apple-preview-div-left-top-left"
                                    id="preview-btneffect-ios-mob"
                                    onClick={() => {
                                      this.setState({deviceModalLanguage: "ios"}, () => this.unlinkLanguage())
                                     }}
                                  >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                          <g id="Group_43334" data-name="Group 43334" transform="translate(-31.934 -19.434)">
                                            <path id="Path_119304" data-name="Path 119304" d="M22.5,11.5a11,11,0,1,1-3.011-7.562A11,11,0,0,1,22.5,11.5Z" transform="translate(32.434 19.934)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="2"/>
                                            <path id="Path_119305" data-name="Path 119305" d="M18.559,3a10.894,10.894,0,0,1-7.935,3.414A10.894,10.894,0,0,1,2.69,3" transform="translate(33.244 21.922)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="2"/>
                                            <path id="Path_119306" data-name="Path 119306" d="M2.69,14.914a10.927,10.927,0,0,1,15.869,0" transform="translate(33.244 23.031)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="2"/>
                                            <ellipse id="Ellipse_9527" data-name="Ellipse 9527" cx="4" cy="11" rx="4" ry="11" transform="translate(39.934 20.434)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="2"/>
                                          </g>
                                    </svg>
                                  <p className="reloadtooltip">Simulate your app in different languages.</p>
                                  </div>
                                  <div
                                    className="   apple-preview-div-left-bottom apple-preview-div-left-bottom-left"
                                   id="preview-btneffect-ios"
                                   onClick={() => {
                                    this.setState({deviceModal: "ios"}, () => this.unlink())
                                   }}
                                  >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24.285" viewBox="0 0 18 24.285">
                                      <g id="Group_43328" data-name="Group 43328" transform="translate(-1315 -523)">
                                        <path id="Path_119301" data-name="Path 119301" d="M16.5,8.5c0,7.448-8,14-8,14s-8-6.373-8-14a8,8,0,0,1,16,0Z" transform="translate(1315.5 523.5)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="2"/>
                                        <circle id="Ellipse_9526" data-name="Ellipse 9526" cx="3" cy="3" r="3" transform="translate(1321 529)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="2"/>
                                      </g>
                                    </svg>

                                     <p className="reloadtooltip">Simulate your app in different geolocations.</p>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display:
                                      this.state.ios === true
                                        ? "block"
                                        : "none",
                                    position: "relative",
                                  }}
                                >
                                  {/* {this.state.iosspinner && (
                                    <div className="spinn">
                                      <Spinner
                                        animation="border"
                                        variant="primary"
                                      />
                                    </div>
                                  )} */}
                                  {this.state.ioslambdatoken && this.state.isIosLoaded &&
                                    <iframe
                                      src={checkurl()}
                                      ref={this.iframeRef}
                                      style={this.state.devicename.toLowerCase().includes('ipad') ? this.getDeviceStyle() : {
                                        width:"360px",
                                        height:"726px"
                                      }}
                                      frameBorder="0"
                                      scrolling="no"
                                      id="iosframe"
                                      className="mr-0"
                                      onLoad={(e) => this.onMyFrameLoad()}
                                      title="ios"
                                    ></iframe>
                                  }
                                  {!this.state.isIosLoaded && <div className="lamda-preview-clickdiv"
                                    onClick={() => this.setState({isIosLoaded: true})}
                                  >
                                  </div>}
                                </div>
                                {this.state.android != true ?
                                <div className=" mobapple-preview-div-left">
                                  <div
                                        className="apple-preview-div-left-top apple-preview-div-left-top-left "
                                        id="preview-btneffect-android"
                                      >
                                       
                                        <svg   onClick={(e) =>
                                            this.restartApp("#iosframe")
                                          } xmlns="http://www.w3.org/2000/svg" width="27.443" height="27.444" viewBox="0 0 27.443 27.444">
                                          <g id="Group_40115" data-name="Group 40115" transform="translate(-1259.077 -211.687)">
                                            <path id="Path_103621" data-name="Path 103621" d="M9.094,0a9.094,9.094,0,1,0,9.094,9.094,9.206,9.206,0,0,0-2.259-6" transform="matrix(0.485, 0.875, -0.875, 0.485, 1276.343, 213.047)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                            <path id="Path_103620" data-name="Path 103620" d="M5.871,0V5.871H0" transform="translate(1275.699 215.701)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                          </g>
                                        </svg>
                                        <p className="reloadtooltip" >Reset</p>
                                      </div>
                                  
                                  {!this.state.iosRecording && !this.state.recordingProcessing && <div
                                    className=" apple-preview-div-left-top apple-preview-div-left-top-right "
                                    onClick={() => this.handleRecordingStart("ios")}
                                    id="preview-btneffect-ios-mob"
                                  >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16">
                                    <g id="Group_43329" data-name="Group 43329" transform="translate(-1312.5 -527.5)">
                                      <rect id="Rectangle_32508" data-name="Rectangle 32508" width="15" height="14" rx="1.623" transform="translate(1313.5 528.5)" fill="none" stroke="#bcc1ce" stroke-linejoin="round" stroke-width="2"/>
                                      <path id="Path_119302" data-name="Path 119302" d="M22.5,14.5l-7-5v-4l7-5Z" transform="translate(1313 528)" fill="none" stroke="#bcc1ce" stroke-linejoin="round" stroke-width="2"/>
                                    </g>
                                  </svg>

                                  <p className="reloadtooltip "> Create a screen recording of the app’s simulation.</p>

                                  </div>}
                                 
                                  {this.state.recordingProcessing && <div 
                                      className=" apple-preview-div-left-top apple-preview-div-left-top-left"
                                      id="preview-btneffect-ios"
                                      >
                                        <div className="new-grey-loader"></div>
                                  </div>}

                                  { this.state.iosRecording  && !this.state.recordingProcessing && <div onClick={() => this.handleRecordingStop("ios")} style={{position: "relative"}}>
                                    <div className="apple-preview-div-left-top apple-preview-div-left-top-right" id="preview-btneffect-android">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path id="Union_3162" data-name="Union 3162" d="M-4513.486,617.485A11.92,11.92,0,0,1-4517,609a11.922,11.922,0,0,1,3.514-8.485A11.925,11.925,0,0,1-4505,597a11.924,11.924,0,0,1,8.485,3.514A11.923,11.923,0,0,1-4493,609a11.921,11.921,0,0,1-3.515,8.485A11.92,11.92,0,0,1-4505,621,11.921,11.921,0,0,1-4513.486,617.485ZM-4515,609a10.012,10.012,0,0,0,10,10,10.012,10.012,0,0,0,10-10,10.011,10.011,0,0,0-10-10A10.011,10.011,0,0,0-4515,609Zm8,4a2,2,0,0,1-2-2v-4a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2v4a2,2,0,0,1-2,2Z" transform="translate(4517 -597)" fill="#ff4848"></path></svg>
                                      <p className="reloadtooltip stop-recording-tooltip">Stop Recording</p>
                                    </div>
                                    <p className="timerstart">{this.state.currentTime}</p>
                                  </div>}
                                  <div
                                    className=" apple-preview-div-left-top apple-preview-div-left-top-right "
                                    id="preview-btneffect-ios-mob"
                                  >
                          <svg  onClick={(e) =>this.saveScreenshot("#iosframe") } 
                                        xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                          <g id="Group_40111" data-name="Group 40111" transform="translate(-1333 -525)">
                                            <path id="Path_103616" data-name="Path 103616" d="M10,4V8a2,2,0,0,1-2,2H4" transform="translate(1342 534)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                            <path id="Path_103617" data-name="Path 103617" d="M10,8V4A2,2,0,0,0,8,2H4" transform="translate(1342 524)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                            <path id="Path_103618" data-name="Path 103618" d="M2,4V8a2,2,0,0,0,2,2H8" transform="translate(1332 534)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                            <path id="Path_103619" data-name="Path 103619" d="M2,8V4A2,2,0,0,1,4,2H8" transform="translate(1332 524)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                            <rect id="Rectangle_30022" data-name="Rectangle 30022" width="8" height="8" rx="1" transform="translate(1339 531)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                          </g>
                                        </svg>
                                        <p className="reloadtooltip mob-screenshort">Take screenshots at any given point during the app simulation.</p>
                                  </div>
                                  <div
                                    className=" apple-preview-div-left-top apple-preview-div-left-top-right "
                                    id="preview-btneffect-ios-mob"
                                    onClick={() => {
                                      this.setState({deviceModalLanguage: "ios"}, () => this.unlinkLanguage())
                                     }}
                                  >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                          <g id="Group_43334" data-name="Group 43334" transform="translate(-31.934 -19.434)">
                                            <path id="Path_119304" data-name="Path 119304" d="M22.5,11.5a11,11,0,1,1-3.011-7.562A11,11,0,0,1,22.5,11.5Z" transform="translate(32.434 19.934)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="2"/>
                                            <path id="Path_119305" data-name="Path 119305" d="M18.559,3a10.894,10.894,0,0,1-7.935,3.414A10.894,10.894,0,0,1,2.69,3" transform="translate(33.244 21.922)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="2"/>
                                            <path id="Path_119306" data-name="Path 119306" d="M2.69,14.914a10.927,10.927,0,0,1,15.869,0" transform="translate(33.244 23.031)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="2"/>
                                            <ellipse id="Ellipse_9527" data-name="Ellipse 9527" cx="4" cy="11" rx="4" ry="11" transform="translate(39.934 20.434)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="2"/>
                                          </g>
                                    </svg>
                                  <p className="reloadtooltip">Simulate your app in different languages.</p>
                                  </div>
                              

                                  <div
                                    className=" apple-preview-div-left-bottom apple-preview-div-left-bottom-right 2"
                                    id="preview-screnbtneffect-ios-mob"
                                   onClick={() => {
                                    this.setState({deviceModal: "ios"}, () => this.unlink())
                                   }}
                                  >
                                   <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24.285" viewBox="0 0 18 24.285">
  <g id="Group_43328" data-name="Group 43328" transform="translate(-1315 -523)">
    <path id="Path_119301" data-name="Path 119301" d="M16.5,8.5c0,7.448-8,14-8,14s-8-6.373-8-14a8,8,0,0,1,16,0Z" transform="translate(1315.5 523.5)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="2"/>
    <circle id="Ellipse_9526" data-name="Ellipse 9526" cx="3" cy="3" r="3" transform="translate(1321 529)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="2"/>
  </g>
</svg>
                                  <p className="reloadtooltip">Simulate your app in different geolocations.</p>
                                  </div>
                                </div>
                                :<></>
                                }

                                </div>
                              </div>
                            )}

                            {this.state.data !== null && (
                              <>
                                <div className={`previeww-right-pannel lamda-previeww-right-pannel ${!this.state.isAndroidLoaded ? ' lamda-preview-opacity' : ''}`}
                                >
                                 {window.screen.width > 1200 && 
                                 <div className="android-frame lamda-android-frame top_screenshot">
                                    <div className="d-flex align-items-center justify-content-center">
                                      <div className="preview-new-tab d-flex justify-content-center align-items-center">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/android_active.png")
                                              .default
                                          }
                                          alt="preview-android"
                                          className="img-fluid mr-2"
                                        />
                                        <p></p>
                                      </div>
                                      <div className="appPreview-new-button ">
                                        <div className="">
                                              
                                        <div className="">
                                    <Dropdown className="appPreview-dropdown appPreview-dropdown-margin"
                                    isOpen={this.state.ItemAppAndoridPopup}
                                    toggle={this.handleAppAndoriddropdown}
                                    // disabled={!this.state.isAndroidLoaded}
                                    >
                                      <div className={this.state.ItemAppAndoridPopup? "report-aerotop-android": "report-aerohidden"}>
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/dropdownAero.png")
                                            .default
                                        }
                                        className=""
                                        alt=" "
                                      />
                                      </div>
                                    <DropdownToggle caret>
                                      {this.state.andoridversions.map((osversion,indx) => {
                                          return (this.state.androiddeviceOSname == osversion ? osversion : '');
                                        })
                                        }
                                    </DropdownToggle>
                                    <DropdownMenu end={true}>
                                    {this.state.andoridversions.map((osversion,indx) => {
                                        return (<DropdownItem active={this.state.androiddeviceOSname == osversion ? true: ""} 
                                        onClick={() => this.mobhandleandroidosversion(osversion)}>{osversion}</DropdownItem>);
                                      })
                                    }
                                     
                                    </DropdownMenu>
                                  </Dropdown>
                                  </div>
                                        </div>
                                      </div>
                                      <div className="appPreview-new-button">
                                        <div className="">
                                              
                                        <div className="">
                                    <Dropdown className="appPreview-dropdown"
                                    isOpen={this.state.ItemAppAndoridDevicePopup}
                                    toggle={this.handleAppAndoriddevicedropdown}
                                    // disabled={!this.state.isAndroidLoaded}
                                    >
                                      <div className={this.state.ItemAppAndoridDevicePopup? "report-aerotop-android": "report-aerohidden"}>
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/dropdownAero.png")
                                            .default
                                        }
                                        className=""
                                        alt=" "
                                      />
                                      </div>
                                    <DropdownToggle caret>
                                      {this.state.androiddevices.map((device,indx) => {
                                          return (this.state.androiddevicename == device ? device : '');
                                        })
                                        }
                                    </DropdownToggle>
                                    <DropdownMenu end={true}>
                                    {this.state.androiddevices.map((device,indx) => {
                                        return (<DropdownItem active={this.state.androiddevicename == device ? true: ""} 
                                        onClick={() => this.mobhandleandroiddevice(device)}>{device}</DropdownItem>);
                                      })
                                    }
                                     
                                    </DropdownMenu>
                                  </Dropdown>
                                  </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>}
                                  <div className="apple-preview-div  preview-rowreverse">
                                  <div
                                    style={{
                                      display:
                                        this.state.android === true
                                          ? "block"
                                          : "none",
                                      position: "relative",
                                    }}
                                  >
                                    {/* {this.state.androidspinner && (
                                      <div className="spinn">
                                        <Spinner
                                          animation="border"
                                          variant="primary"
                                        />
                                      </div>
                                    )} */}
                                    {this.state.lambdatoken && this.state.isAndroidLoaded &&
                                      <iframe
                                        src={androidcheckurl()}
                                        width="340px"
                                        height="700px"
                                        frameBorder="0"
                                        scrolling="no"
                                        id="androidiframe"
                                        className="ml-0"
                                        onLoad={(e) => this.onMyFrameLoad()}
                                        title="android"
                                      ></iframe>
                                    }
                                     {!this.state.isAndroidLoaded  && <div className="lamda-preview-clickdiv"
                                        onClick={() => this.setState({isAndroidLoaded: true})}
                                     ></div>}
                                  </div>
                                  <div className="android-preview-div-right lamda-android-preview-div-right">
                                    {this.state.android === true ?
                                     <>
                                     <div
                                        className="apple-preview-div-left-top apple-preview-div-left-top-left "
                                        id="preview-btneffect-android"
                                      >
                                       
                                        <svg   onClick={(e) =>
                                            this.restartApp("#androidiframe")
                                          } xmlns="http://www.w3.org/2000/svg" width="27.443" height="27.444" viewBox="0 0 27.443 27.444">
                                          <g id="Group_40115" data-name="Group 40115" transform="translate(-1259.077 -211.687)">
                                            <path id="Path_103621" data-name="Path 103621" d="M9.094,0a9.094,9.094,0,1,0,9.094,9.094,9.206,9.206,0,0,0-2.259-6" transform="matrix(0.485, 0.875, -0.875, 0.485, 1276.343, 213.047)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                            <path id="Path_103620" data-name="Path 103620" d="M5.871,0V5.871H0" transform="translate(1275.699 215.701)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
                                          </g>
                                        </svg>
                                        <p className="reloadtooltip lamda-reset-for-android">Reset</p>
                                      </div>
                                    {/* <div style={{position: "relative"}}> */}
                                    {!this.state.androidRecording && !this.state.recordingProcessingAndroid &&  <div
                                        className="apple-preview-div-left-top apple-preview-div-left-top-right"
                                        id="preview-btneffect-android"
                                        onClick={() => this.handleRecordingStart("android")}
                                      >
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16">
                                          <g id="Group_43329" data-name="Group 43329" transform="translate(-1312.5 -527.5)">
                                            <rect id="Rectangle_32508" data-name="Rectangle 32508" width="15" height="14" rx="1.623" transform="translate(1313.5 528.5)" fill="none" stroke="#bcc1ce" stroke-linejoin="round" stroke-width="2"/>
                                            <path id="Path_119302" data-name="Path 119302" d="M22.5,14.5l-7-5v-4l7-5Z" transform="translate(1313 528)" fill="none" stroke="#bcc1ce" stroke-linejoin="round" stroke-width="2"/>
                                          </g>
                                        </svg>

                                        <p className="reloadtooltip android-div-tooltipcreate"> Create a screen recording of the app’s simulation.</p>
                                      </div>}
                                      {/* <p className="timerstart">00.00.15 android</p> */}
                                      {/* </div> */}
                                      {this.state.recordingProcessingAndroid && <div 
                                      className=" apple-preview-div-left-top apple-preview-div-left-top-left"
                                      id="preview-btneffect-ios"
                                      >
                                        <div className="new-grey-loader"></div>
                                  </div>}
                                      {/* <div style={{position: "relative"}}> */}
                                      {this.state.androidRecording && !this.state.recordingProcessingAndroid && <div
                                        className="apple-preview-div-left-top apple-preview-div-left-top-right"
                                        id="preview-btneffect-android"
                                        onClick={() => this.handleRecordingStop("android")}
                                      >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path id="Union_3162" data-name="Union 3162" d="M-4513.486,617.485A11.92,11.92,0,0,1-4517,609a11.922,11.922,0,0,1,3.514-8.485A11.925,11.925,0,0,1-4505,597a11.924,11.924,0,0,1,8.485,3.514A11.923,11.923,0,0,1-4493,609a11.921,11.921,0,0,1-3.515,8.485A11.92,11.92,0,0,1-4505,621,11.921,11.921,0,0,1-4513.486,617.485ZM-4515,609a10.012,10.012,0,0,0,10,10,10.012,10.012,0,0,0,10-10,10.011,10.011,0,0,0-10-10A10.011,10.011,0,0,0-4515,609Zm8,4a2,2,0,0,1-2-2v-4a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2v4a2,2,0,0,1-2,2Z" transform="translate(4517 -597)" fill="#ff4848"/>
                                      </svg>
                                        <p className="reloadtooltip mob-stop-recording-tooltip">Stop Recording</p>
                                        <p className="timerstart">{this.state.currentTimeAndroid}</p>

                                      </div>}
                                      {/* <p className="timerstart">00.00.15 android</p> */}
                                      {/* </div> */}
                                      <div
                                        className="apple-preview-div-left-top apple-preview-div-left-top-right"
                                        id="preview-btneffect-android"
                                      >
                                       <svg  onClick={(e) =>
                                            this.saveScreenshot("#androidiframe")
                                          } xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <g id="Group_40111" data-name="Group 40111" transform="translate(-1333 -525)">
    <path id="Path_103616" data-name="Path 103616" d="M10,4V8a2,2,0,0,1-2,2H4" transform="translate(1342 534)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
    <path id="Path_103617" data-name="Path 103617" d="M10,8V4A2,2,0,0,0,8,2H4" transform="translate(1342 524)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
    <path id="Path_103618" data-name="Path 103618" d="M2,4V8a2,2,0,0,0,2,2H8" transform="translate(1332 534)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
    <path id="Path_103619" data-name="Path 103619" d="M2,8V4A2,2,0,0,1,4,2H8" transform="translate(1332 524)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
    <rect id="Rectangle_30022" data-name="Rectangle 30022" width="8" height="8" rx="1" transform="translate(1339 531)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
  </g>
</svg>
                                        <p className="reloadtooltip android-div-tooltipscreenshort">Take screenshots at any given point during the app simulation.</p>
                                      </div>

                                      <div
                                    className=" apple-preview-div-left-top apple-preview-div-left-top-right "
                                    id="preview-btneffect-ios-mob"
                                    onClick={() => {
                                      this.setState({deviceModalLanguage: "android"}, () => this.unlinkLanguage())
                                     }}
                                  >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                          <g id="Group_43334" data-name="Group 43334" transform="translate(-31.934 -19.434)">
                                            <path id="Path_119304" data-name="Path 119304" d="M22.5,11.5a11,11,0,1,1-3.011-7.562A11,11,0,0,1,22.5,11.5Z" transform="translate(32.434 19.934)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="2"/>
                                            <path id="Path_119305" data-name="Path 119305" d="M18.559,3a10.894,10.894,0,0,1-7.935,3.414A10.894,10.894,0,0,1,2.69,3" transform="translate(33.244 21.922)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="2"/>
                                            <path id="Path_119306" data-name="Path 119306" d="M2.69,14.914a10.927,10.927,0,0,1,15.869,0" transform="translate(33.244 23.031)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="2"/>
                                            <ellipse id="Ellipse_9527" data-name="Ellipse 9527" cx="4" cy="11" rx="4" ry="11" transform="translate(39.934 20.434)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="2"/>
                                          </g>
                                    </svg>
                                  <p className="reloadtooltip android-div-tooltipcreate">Simulate your app in different languages.</p>
                                  </div>
                                      <div
                                        className="apple-preview-div-left-bottom apple-preview-div-left-bottom-right"
                                        id="preview-screnbtneffect-android-mob"
                                         onClick={() => {
                                          this.setState({deviceModal: "android"}, () => this.unlink())
                                         }}

                                      >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24.285" viewBox="0 0 18 24.285">
  <g id="Group_43328" data-name="Group 43328" transform="translate(-1315 -523)">
    <path id="Path_119301" data-name="Path 119301" d="M16.5,8.5c0,7.448-8,14-8,14s-8-6.373-8-14a8,8,0,0,1,16,0Z" transform="translate(1315.5 523.5)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="2"/>
    <circle id="Ellipse_9526" data-name="Ellipse 9526" cx="3" cy="3" r="3" transform="translate(1321 529)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="2"/>
  </g>
</svg>

                                       
                                        <p className="reloadtooltip mob-simulate_tooltip">Simulate your app in different geolocations.</p>
                                      </div>
                                      </>
                                    : <></>
                                    }
                                  </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        
                         {/* ) 
                         : (
                           <Error errordata={Errordata} />
                         )
                        } */}
                        <DownApp
                          togglemodalapp={(e) => this.selectionmodel(e)}
                        />
                      </div>
                    </>
                  );
               
            }
            )()
            }
          </>
        )}

         <Modal 
              className="country-conf-secondmodal"
              style={{ maxWidth: "600px", maxHeight: "420px" }}
              isOpen={this.state.mdlunlink} 
              toggle={()=> this.unlink()}
              centered   
            >
                    <ModalHeader>
                    Update Location
                        <div className="country-conf-secondmodal-crossmodal" style={{ zIndex:"9" }} 
                        
                        >
                            <img onClick={this.handleBtnDone} src={ require("../../assets/images/dashboard/cross.png").default } />
                        </div>
                    </ModalHeader>

                    <ModalBody>

                      <div>
                        
                        <div className="language-search">
                        <svg
                        onClick={this.handleBtnDone}
                        xmlns="http://www.w3.org/2000/svg" width="20.759" height="15.408" viewBox="0 0 20.759 15.408">
                        <g id="Group_27465" data-name="Group 27465" transform="translate(-19.742 -22.295)">
                          <path id="Path_81245" data-name="Path 81245" d="M-13783.922-19217h19.346" transform="translate(13805.077 19247)" fill="none" stroke="#7782a1" stroke-width="2"/>
                          <path id="Path_82232" data-name="Path 82232" d="M14053.656,19255.426l-7,7,7,7" transform="translate(-14025.504 -19232.424)" fill="none" stroke="#7782a1" stroke-width="2"/>
                        </g>
                      </svg>
                        <form onSubmit={this.handleLocationSubmit}>
                        <input placeholder="Search..."
                          value={this.state.locationInput}
                          onChange={this.handleLocationChange}
                        />
                                        {this.state.locationInput && <svg
                                            onClick={() => this.setState({locationInput:''}, () => this.handleLocationSubmit())}
                                            className="search-cross-icon" xmlns="http://www.w3.org/2000/svg" width="11.06" height="11.06" viewBox="0 0 11.06 11.06">
                                            <path id="Union_2970" data-name="Union 2970" d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>}
                        </form>
                        <button className="mob-language-done-btn" id="mob-done-btn"
                          disabled={this.state.deviceModal === "ios" && !this.state.selectedLocation.country_name ||
                            this.state.deviceModal === "android" && !this.state.selectedLocationAndroid.country_name
                          }
                          onClick={() => {
                            this.setState({
                              updatingLocation: true
                            }, () => {
                              updateLambdaLocation(this.state.deviceModal === "ios" ?
                                this.state.ioslambdatoken : this.state.lambdatoken, this)
                            })
                          }}
                        >Done</button>
                        <p id="mob-clear" onClick={() => this.setState({locationInput:''}, () => this.handleLocationSubmit())}>Clear</p>
                        </div>
                        {/* <div className="language-type-div language-type-div-select">
                          <div className="countryflag">
                          <img src={ require("../../assets/images/dashboard/mergeapp-preview-flag1.png").default } />
                          </div>
                          <p className="language-type">English</p>
                        </div>
                        <div className="language-type-div">
                          <div className="countryflag">
                          <img src={ require("../../assets/images/dashboard/mergeapp-preview-flag1.png").default } />
                          </div>
                          <p className="language-type">English</p>
                        </div>
                      </div> */}
                      
                          <div className="language-type-body">
                            
                           {this.state.updatingLocation && <div className="newspinner previewspinner" style={{position: "unset"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                  xlink="http://www.w3.org/1999/xlink" 
                                  style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
                                  width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                  <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                  <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                    <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                                    style={{animationplaystate: "running", animationdelay: "0s"}}>
                                    </animateTransform>
                                  </circle>
                                  </g>
                                  <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                  <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                  <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                                  style={{animationplaystate: "running", animationdelay: "0s"}}>
                                  </animateTransform>
                                  </circle>
                                  </g>
                                  <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                  <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                  <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                                  style={{animationplaystate: "running", animationdelay: "0s"}}>

                                  </animateTransform>
                                  </circle>
                                  </g>
                                  <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                  <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                  <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                                  style={{animationplaystate: "running", animationdelay: "0s"}}>

                                  </animateTransform>
                                  </circle>
                                  </g>
                                </svg>
                             </div>}
                          {!this.state.updatingLocation ? 
                          this.state.locationsArr.length > 0 ? this.state.locationsArr.map((location, idx) => (
                            this.state.deviceModal === "ios" ? <div key={`location_${idx}`} 
                            className={`${this.state.selectedLocation?.country_name === location.name ? "language-type-div-select" :""} language-type-div`}
                            onClick={() => {
                                this.setState({
                                  selectedLocation: {
                                    country_code: location.code,
                                    proxy_type: location.proxy_type,
                                    country_name: location.name
                                  },
                                })
                              }
                            }
                            >
                              <input type="radio" checked={this.state.selectedLocation?.country_name === location.name} />
                              <span className="language-select-checkmark"></span>
                            <p className="language-type">{location.name}</p>


                          </div> : <div key={`location_${idx}`} 
                            className={`${this.state.selectedLocationAndroid?.country_name === location.name ? "language-type-div-select" :""} language-type-div`}
                            onClick={() => {
                                this.setState({
                                  selectedLocationAndroid: {
                                    country_code: location.code,
                                    proxy_type: location.proxy_type,
                                    country_name: location.name
                                  },
                                })
                              }
                            }
                            >
                              <input type="radio" checked={this.state.selectedLocationAndroid?.country_name === location.name} />
                              <span className="language-select-checkmark"></span>
                            <p className="language-type">{location.name}</p>
                          </div>
                          )) : 
                          <div className="select-no-data-found" id="location-modal">
                          <img
                            src={
                              require("../../assets/images/dashboard/no-media.png").default
                            }
                          />
                          <p>No results found</p>
                        </div> : <></>}
                          </div>
                          </div>
                    </ModalBody>
                    <ModalFooter>
                      <button className="location-cancel-btn" id="web-cancel-btn"
                        onClick={this.handleBtnDone}
                      >Cancel</button>
                      <button className="location-done-btn" id="web-done-btn"
                        disabled={this.state.deviceModal === "ios" && !this.state.selectedLocation.country_name ||
                        this.state.deviceModal === "android" && !this.state.selectedLocationAndroid.country_name
                      }
                        onClick={() => {
                          this.setState({
                            updatingLocation: true
                          }, () => {
                            updateLambdaLocation(this.state.deviceModal === "ios" ?
                              this.state.ioslambdatoken : this.state.lambdatoken, this, this.state.deviceModal)
                          })
                        }}
                      >Done</button>
                    </ModalFooter>

                </Modal>
                <Modal
                  className="country-conf-secondmodal"
                  style={{ maxWidth: "600px", maxHeight: "420px" }}
                  isOpen={this.state.languageModal}
                  toggle={() => this.unlinkLanguage()}
                  centered
                  id="language-modal"
                >
                  <ModalHeader>
                    Update Language
                    <div
                      className="country-conf-secondmodal-crossmodal"
                      style={{ zIndex: "9" }}
                    >
                      <img
                        onClick={this.handleBtnDoneLanguage}
                        src={require("../../assets/images/dashboard/cross.png").default}
                      />
                    </div>
                  </ModalHeader>
                  <ModalBody>
                    <div>
                      <div className="language-search">
                        <svg
                          onClick={this.handleBtnDoneLanguage}
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.759"
                          height="15.408"
                          viewBox="0 0 20.759 15.408"
                        >
                          <g
                            id="Group_27465"
                            data-name="Group 27465"
                            transform="translate(-19.742 -22.295)"
                          >
                            <path
                              id="Path_81245"
                              data-name="Path 81245"
                              d="M-13783.922-19217h19.346"
                              transform="translate(13805.077 19247)"
                              fill="none"
                              stroke="#7782a1"
                              stroke-width="2"
                            />
                            <path
                              id="Path_82232"
                              data-name="Path 82232"
                              d="M14053.656,19255.426l-7,7,7,7"
                              transform="translate(-14025.504 -19232.424)"
                              fill="none"
                              stroke="#7782a1"
                              stroke-width="2"
                            />
                          </g>
                        </svg>
                        <form onSubmit={this.handleLanguageSubmit}>
                          <input
                            placeholder="Search..."
                            value={this.state.languageInput}
                            onChange={(event) => {
                              this.setState({
                                languageInput: event.target.value
                              })
                            }}
                          />
                          {this.state.languageInput && <svg
                                            onClick={() => this.setState({languageInput:''}, () => this.handleLanguageSubmit())}
                                            className="search-cross-icon" xmlns="http://www.w3.org/2000/svg" width="11.06" height="11.06" viewBox="0 0 11.06 11.06">
                                            <path id="Union_2970" data-name="Union 2970" d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>}
                        </form>
                        <button
                          className="mob-language-done-btn"
                          id="mob-done-btn"
                          disabled={this.state.deviceModalLanguage === "ios" && !this.state.selectedLanguage.name ||
                        this.state.deviceModalLanguage === "android" && !this.state.selectedLanguageAndroid.name
                        }
                          onClick={() => {
                            this.setState({
                              updatingLanguage: true,
                            });
                            updateLanguage(
                              this.state.deviceModalLanguage === "ios"
                                ? this.state.ioslambdatoken
                                : this.state.lambdatoken,
                              this,
                              this.state.deviceModalLanguage
                            );
                          }}
                        >
                          Done
                        </button>
                        <p id="mob-clear" 
                         onClick={() => this.setState({languageInput:''}, () => this.handleLanguageSubmit())}
                        >
                          Clear
                        </p>
                      </div>
                     
                      <div className="language-type-body">
                        {this.state.updatingLanguage && (
                          <div className="newspinner previewspinner">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xlink="http://www.w3.org/1999/xlink"
                              style={{
                                margin: "auto",
                                background: "rgb(241, 242, 243,0)",
                                display: "block",
                                shaperendering: "auto",
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                              width="200px"
                              height="200px"
                              viewBox="0 0 100 100"
                              preserveAspectRatio="xMidYMid"
                            >
                              <g
                                transform="translate(20 50)"
                                style={{ animationplaystate: "running", animationdelay: "0s" }}
                              >
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="6"
                                  fill="#e6261f"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <animateTransform
                                    attributeName="transform"
                                    type="scale"
                                    begin="-0.375s"
                                    calcMode="spline"
                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                    values="0;1;0"
                                    keyTimes="0;0.5;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  ></animateTransform>
                                </circle>
                              </g>
                              <g
                                transform="translate(40 50)"
                                style={{ animationplaystate: "running", animationdelay: "0s" }}
                              >
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="6"
                                  fill="#f7d038"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <animateTransform
                                    attributeName="transform"
                                    type="scale"
                                    begin="-0.25s"
                                    calcMode="spline"
                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                    values="0;1;0"
                                    keyTimes="0;0.5;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  ></animateTransform>
                                </circle>
                              </g>
                              <g
                                transform="translate(60 50)"
                                style={{ animationplaystate: "running", animationdelay: "0s" }}
                              >
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="6"
                                  fill="#49da9a"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <animateTransform
                                    attributeName="transform"
                                    type="scale"
                                    begin="-0.125s"
                                    calcMode="spline"
                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                    values="0;1;0"
                                    keyTimes="0;0.5;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  ></animateTransform>
                                </circle>
                              </g>
                              <g
                                transform="translate(80 50)"
                                style={{ animationplaystate: "running", animationdelay: "0s" }}
                              >
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="6"
                                  fill="#4355db"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <animateTransform
                                    attributeName="transform"
                                    type="scale"
                                    begin="0s"
                                    calcMode="spline"
                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                    values="0;1;0"
                                    keyTimes="0;0.5;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  ></animateTransform>
                                </circle>
                              </g>
                            </svg>
                          </div>
                        )}
                        {!this.state.updatingLanguage && this.state.deviceModalLanguage === "ios" ?
                          this.state.iosLanguages.length > 0 ? this.state.iosLanguages.map((lang, idx) => (
                            <div
                              key={`lang_${idx}`}
                              className={`${
                                this.state.selectedLanguage.name === lang.name
                                  ? "language-type-div-select"
                                  : ""
                              } language-type-div`}
                              onClick={() => {
                                this.setState({
                                  selectedLanguage: lang
                                });
                              }}
                            >
                              <input
                                type="radio"
                                checked={
                                  this.state.selectedLanguage.name === lang.name
                                }
                              />
                              <span className="language-select-checkmark"></span>
                              <p className="language-type">{lang.name}</p>
                            </div>
                          )) :  
                          <div className="select-no-data-found" id="ios-lang">
                          <img
                            src={
                              require("../../assets/images/dashboard/no-media.png").default
                            }
                          />
                          <p>No results found</p>
                          </div> : 
                          null}
                          {this.state.deviceModalLanguage === "android" ?
                            this.state.androidLanguages.length > 0 ? this.state.androidLanguages.map((lang, idx) => (
                            <div
                              key={`lang_${idx}`}
                              className={`${
                                this.state.selectedLanguageAndroid.name === lang.name
                                  ? "language-type-div-select"
                                  : ""
                              } language-type-div`}
                              onClick={() => {
                                this.setState({
                                  selectedLanguageAndroid:  lang
                                });
                              }}
                            >
                              <input
                                type="radio"
                                checked={
                                  this.state.selectedLanguageAndroid.name === lang.name
                                }
                              />
                              <span className="language-select-checkmark"></span>
                              <p className="language-type">{lang.name}</p>
                            </div>
                          )) :
                          <div className="select-no-data-found" id="android-lang">
                          <img
                            src={
                              require("../../assets/images/dashboard/no-media.png").default
                            }
                          />
                          <p>No results found</p>
                        </div> :
                        null
                          }
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <button
                      className="location-cancel-btn"
                      id="web-cancel-btn"
                      onClick={this.handleBtnDoneLanguage}
                    >
                      Cancel
                    </button>
                    <button
                      className="location-done-btn"
                      id="web-done-btn"
                      disabled={this.state.deviceModalLanguage === "ios" && !this.state.selectedLanguage.name ||
                        this.state.deviceModalLanguage === "android" && !this.state.selectedLanguageAndroid.name
                      }
                      onClick={() => {
                        this.state.deviceModalLanguage === "ios" ? 
                        this.setState({
                          updatingLanguage: true,
                        }) : 
                        this.setState({
                          languageModal: false,
                        })
                        updateLanguage(
                          this.state.deviceModalLanguage === "ios"
                            ? this.state.ioslambdatoken
                            : this.state.lambdatoken,
                          this,
                          this.state.deviceModalLanguage
                        );
                      }}
                    >
                      Done
                    </button>
                  </ModalFooter>
                </Modal>
        <Modal
          isOpen={this.state.screenshot}
          toggle={this.selectionmodel}
          style={{ maxWidth: "740px", maxHeight: "350px" }}
          className="mobile-download-app"
        >
          <ModalHeader
            toggle={this.selectionmodel}
            className="modal-xxl-content modal-categories"
          >
            <p className="ml-0" style={{ fontWeight: "bold" }}>
              Options
            </p>
            <div
              className="crossmodal mt-1"
              onClick={(e) => this.selectionmodel(e)}
            >
              <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                <path
                  id="Union_18"
                  data-name="Union 18"
                  d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                  transform="translate(0.529 0.531)"
                  fill="#1b2952"
                  stroke="#1b2952"
                  strokeWidth="1.5"
                />
              </svg>
            </div>
          </ModalHeader>
          <ModalBody className="update-woo-plugin">
            <div className="android-frame android-form-mobile">
              <div
                className="preview-new-button d-flex justify-content-between align-items-center pl-md-2"
                onClick={(e) =>
                  this.state.ios === true
                    ? this.saveScreenshot("#iosframe")
                    : this.saveScreenshot("#androidiframe")
                }
              >
                <p>Screenshot</p>
                <img
                  src={
                    require("../../assets/images/dashboard/screenshot.png")
                      .default
                  }
                  alt="screenshot"
                  className="img-fluid mr-2"
                />
              </div>
              <div
                className="preview-new-button d-flex justify-content-between align-items-center pl-md-2"
                onClick={(e) =>
                  this.state.ios === true
                    ? this.restartApp("#iosframe")
                    : this.restartApp("#androidiframe")
                }
              >
                <p>Restart</p>
                <img
                  src={
                    require("../../assets/images/dashboard/restart.png").default
                  }
                  alt="restart"
                  className="img-fluid mr-2"
                />
              </div>
              <div className="preview-new-button d-flex form-group justify-content-center align-items-center">
                <div className="http ">
                  {this.state.ios === true ? (
                    <select
                      value={this.state.devicename}
                      onChange={(e) => this.handleiosdevice(e)}
                    >
                      {this.state.iosdevices.map((device,indx) => {
                          return (<option value={device}>{device}</option>);
                        })
                      }
                    </select>
                  ) : (
                    <select
                      value={this.state.androiddevicename}
                      onChange={(e) => this.handleandroiddevicename(e)}
                    >
                      <option value="pixel5">Pixel 5</option>
                      <option value="nexus5">Nexus 5</option>
                    </select>
                  )}
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

      </>
    );
  }
}

export { LambdaSplash };

