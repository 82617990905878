import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiEndPoints from "../utils/apiEndpoints";
import { prepareAuthHeaders } from "../utils/commonUtils";

export const webViewApiSlice = createApi({
  reducerPath: "webView",
  baseQuery: fetchBaseQuery({
    baseUrl: apiEndPoints.appUrl,
    prepareHeaders: (headers) => prepareAuthHeaders(headers),
  }),
  tagTypes: ["webview-details"],

  endpoints: (builder) => ({
    // Gives the list of all apps that are merged with current app
    getWebViewData: builder.query({
      query: (params) =>
        "web-view-toggle/" + params.app_id,
      providesTags: ["webview-details"],
      keepUnusedDataFor: 15 * 60,
    }),

   saveWebViewData: builder.mutation({
      query: (body) => ({
        url:"web-view-toggle",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["webview-details"],
      keepUnusedDataFor: 15 * 60,
    }),


  }),
});

export const {
useGetWebViewDataQuery,
useSaveWebViewDataMutation
} = webViewApiSlice;
