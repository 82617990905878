import React from "react";

const ChecklistComplete = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g
        id="Group_37699"
        data-name="Group 37699"
        transform="translate(-1510 -164)"
      >
        <circle
          id="Ellipse_7739"
          data-name="Ellipse 7739"
          cx="10"
          cy="10"
          r="10"
          transform="translate(1510 164)"
          fill="#52cb86"
        />
        <path
          id="Path_101776"
          data-name="Path 101776"
          d="M-16065.254,2565.891l2.584,2.584,5.416-5.416"
          transform="translate(17581.254 -2392.059)"
          fill="none"
          stroke="#fff"
          stroke-width="2"
        />
      </g>
    </svg>
  );
};

export default ChecklistComplete;
