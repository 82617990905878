import React from "react";

const HamburgerMenu = ({ state }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.004"
      height="11.002"
      viewBox="0 0 12.004 11.002"
    >
      <path
        id="Union_3001"
        data-name="Union 3001"
        d="M19638,3380v-1h12v1Zm0-5v-1h12v1Zm0-5v-1h12v1Z"
        transform="translate(-19637.998 -3369)"
        fill={state.iconColor}
      />
    </svg>
  );
};

export default HamburgerMenu;
