import React from "react";

const AgencySpinner = () =>{


    return(
    <div className="agencyspinner" >
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                    <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                    </animateTransform>
                </circle>
            </g>
            <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
                    <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                    </animateTransform>
                </circle>
            </g>
            <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
                    <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                    </animateTransform>
                </circle>
            </g>
            <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
                    <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                    </animateTransform>
                </circle>
            </g>
        </svg>
    </div>
    );
}

export default AgencySpinner;