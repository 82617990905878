import React from "react";

const DefaulyMenuIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 18 18" fill="none">
    <path d="M16 11H12C11.4477 11 11 11.4477 11 12V16C11 16.5523 11.4477 17 12 17H16C16.5523 17 17 16.5523 17 16V12C17 11.4477 16.5523 11 16 11Z" fill="#BCC1CE"/>
    <path d="M4.5 17C6.433 17 8 15.433 8 13.5C8 11.567 6.433 10 4.5 10C2.567 10 1 11.567 1 13.5C1 15.433 2.567 17 4.5 17Z" fill="#BCC1CE"/>
    <path d="M14.963 1.5918H15.003C15.4972 1.59258 15.971 1.78861 16.3212 2.13719C16.6715 2.48577 16.8698 2.95865 16.873 3.4528C16.8767 3.72182 16.8268 3.98889 16.7264 4.2385C16.626 4.4881 16.477 4.71527 16.288 4.9068L13.898 7.3018C13.7666 7.4305 13.5899 7.50235 13.406 7.5018C13.2221 7.50235 13.0454 7.4305 12.914 7.3018L10.473 4.8568C10.2808 4.66349 10.132 4.43152 10.0363 4.17628C9.94065 3.92104 9.90038 3.64838 9.91816 3.37638C9.93594 3.10439 10.0114 2.83929 10.1394 2.59867C10.2675 2.35806 10.4453 2.14744 10.661 1.9808C11.0398 1.70037 11.5075 1.56717 11.9772 1.60598C12.4469 1.6448 12.8864 1.853 13.214 2.1918L13.404 2.3828L13.636 2.1458C13.8096 1.97036 14.0163 1.83108 14.2441 1.73599C14.4718 1.6409 14.7162 1.59189 14.963 1.5918Z" fill="#BCC1CE"/>
    <path d="M3.56321 1.75953C3.60696 1.68306 3.67014 1.6195 3.74636 1.5753C3.82257 1.53109 3.90911 1.50781 3.99721 1.50781C4.08532 1.50781 4.17186 1.53109 4.24807 1.5753C4.32428 1.6195 4.38746 1.68306 4.43121 1.75953L7.57021 7.25153C7.61369 7.32763 7.63639 7.41383 7.63605 7.50147C7.63571 7.58911 7.61234 7.67513 7.56828 7.75089C7.52422 7.82666 7.46102 7.88951 7.38501 7.93315C7.309 7.97678 7.22286 7.99968 7.13521 7.99953H0.859214C0.771655 7.9995 0.685638 7.97648 0.60977 7.93277C0.533901 7.88906 0.470842 7.82619 0.4269 7.75046C0.382957 7.67472 0.359674 7.58878 0.359378 7.50122C0.359082 7.41366 0.381784 7.32756 0.425214 7.25153L3.56321 1.75953Z" fill="#BCC1CE"/>
   </svg>
  );
};

export default DefaulyMenuIcon;
