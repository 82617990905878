import React from 'react'
import Header from "../Header";
import Leftpanel from "../Leftpanel";
import { Link } from "react-router-dom";
import Reactcolorpicker from "../Appearance/NewReactcolorpicker";
import moment from "moment";
import ReactSelect from "react-select";
import { Carousel } from 'react-responsive-carousel';
// import './Productsetting.css'
// import { GetProductapi, Productapi } from '../../Api/Dashboard/DashboardApi';

import ProductPreview from './ProductPreview';
import {Modal, ModalHeader, ModalBody } from "reactstrap";
import {ProductGetApi, ProductSaveApi } from '../../Api/ProductsApi/ProductApi';

export default class Product extends React.PureComponent {

  constructor(props) {

    super(props);
    this.wishlist_ref = React.createRef();
    this.additional_info_ref = React.createRef();
    this.related_products_ref = React.createRef();
  
    this.state = {
      bodyspinner: true,
      submitval: "Save",
      loader: "d-none",
      loaderimage: require("../../assets/images/signup/Loader_GIF.gif").default,
      //show_product_custom_fields:false,
      show_related_products: true,
      show_additional_information: true,
      show_product_filter_and_sorting: true,
      show_out_of_stock: true,
      show_product_search: true,
      show_discount_percentage: true,
      fit_image_horizontally: true,
      product_view_style: true,
      enable_cart_badge: true,
      cart_badge_counter_bg_colour: "#FF3D3D",
      cart_badge_counter_text_colour: "#FFFFFF",
      enable_discount_badge: true,
      enable_web_view_interface: false,
      discount_badge_text_colour: "#FFFFFF",
      discount_badge_bg_colour: "#FA4143",
      appRightPanelExpand: localStorage.getItem("appSideBarCollapse") === true ||
      localStorage.getItem("appSideBarCollapse") === "true"
        ? true
        : false,
        // New Fields
      show_filter_on_listing_page:true,
      show_sort_on_listing_page:true,
      enable_wishlist_on_listing_page:true,
      enable_wishlist_on_detail_page:true ,
      show_images_in_gallery:true,
      show_images_as_thumbnails_in_gallery:true,
      show_additional_information_as_list_on_detail_page:false, 
      image_ratio_on_listing_page:"1:1",
      image_ratio_on_detail_page:"1:1",
      image_shape_on_listing_page:"soft_corner",
      filter_related_products_by_on_detail_page:"default",
      show_native_payment_screen:true,
      optionsForFilter:[{label:"Default",value:"default"},{label:"Categories",value:"categories"},{label:"Featured",value:"featured"}],
      //cartUrl: "",
      // handlehttp: 1,
      active_slide:0,
      open_mob_filter_pop_up:false ,
      show_add_to_cart_on_listing_page:true,
      show_center_crop_images_on_listing_page:true ,
      show_center_crop_images_on_detail_page:true,
      last_updated_timestamp:null ,
      temp_show_grid_view:true,
    };
  }

    handleFilterMobPopUp = () =>{
      this.setState({
        open_mob_filter_pop_up:!this.state.open_mob_filter_pop_up
      })
    }

   handlesliderChange = (from) => {
 

    if(from==="left"){
      if(this.state.active_slide>0){

      
      this.setState({
        active_slide:(this.state.active_slide===3&&this.state.show_images_in_gallery===false) ?this.state.active_slide-2:this.state.active_slide-1,
      })
    }
    }else{
      if(this.state.active_slide<3 ){

        this.setState({
          active_slide:(this.state.active_slide===1&&this.state.show_images_in_gallery===false ) ?this.state.active_slide+2 :this.state.active_slide+1,
        })
      }
    }
   
    
  };
  handleprimaryColor = (e) => {
    this.setState({
      discount_badge_bg_colour: e,
    });
  };
  handlediscount_badge_text_colour = (e) => {
    this.setState({
      discount_badge_text_colour: e,
    });
  };
  handlecart_badge_counter_bg_colour = (e) => {
    this.setState({
      cart_badge_counter_bg_colour: e.hex,
    });
  };
  handlecart_badge_counter_text_colour = (e) => {
    this.setState({
      cart_badge_counter_text_colour: e.hex,
    });
  };
  // handleCartUrl = (e) => {
  //   this.setState({
  //     cartUrl: e.target.value,
  //   });
  // };
  // handleHttpValue = (e) => {
  //   this.setState({
  //     handlehttp: parseInt(e.target.value),
  //   });
  // };
  componentDidMount() {
    window.scrollTo(0, 0);
    // document.title = "Product Settings";
    if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
    {
      document.title = "Product settings | AppMySite";
    }
    else 
    {
      document.title = "Product settings |"+ ' ' +localStorage.getItem('agencyname');
    }
    if (
      parseInt(localStorage.getItem("website_technology")) === 4
    ) {
      this.props.history.push("/app/settings");
    }
    const params = {
      app_id: btoa(localStorage.getItem("Appid")),
    };
    ProductGetApi(params, this);
  }

  handleform = (e,from) => {
    const { name, checked } = e.target;
    if(from==="list"){
    if(name==="product_view_style"){
      this.setState({
        temp_show_grid_view:checked,
      });
    }
      this.setState({
        active_slide:0,
      });
    }else if(from==="details"){
    
      this.setState({
        active_slide:1
      });

      if(name==="show_additional_information"||name==="show_additional_information_as_list_on_detail_page"){
        if(!window.matchMedia("(max-width: 1200px)").matches){
          setTimeout(()=>{
          const itemElement = this.additional_info_ref.current;
           if (itemElement ) {
            itemElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
              
          }
        },1000)
        }
      }else if(name==="show_related_products"){
     
        if(!window.matchMedia("(max-width: 1200px)").matches){
          
          setTimeout(()=>{
          const itemElement = this.related_products_ref.current;
           if (itemElement ) {
            itemElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
              
          }
        },1000)
        } 
      }
      else if(name==="enable_wishlist_on_detail_page"){
        if(!window.matchMedia("(max-width: 1200px)").matches){
          setTimeout(()=>{
          const itemElement = this.wishlist_ref.current;
           if (itemElement ) {
            itemElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
              
          }
        },1000)
        }
      }


    }else if(from==="3rdSlide"){
     
      if(e.target.checked){

      
      this.setState({
        active_slide:name==="show_images_as_thumbnails_in_gallery"&& this.state.show_images_in_gallery?2:name==="show_images_in_gallery"?2:1,
     
      });
    }else{
      this.setState({
       active_slide:name==="show_images_as_thumbnails_in_gallery"&& this.state.show_images_in_gallery?2:1,
   
      });
    }
    }
    else if(from==="checkout"){
      
      this.setState({
        active_slide:3
      });
    }

    this.setState({
      [name]: checked,
    });
  };

  handleListGridSvg = (e,from) =>{
    
    this.setState({
      temp_show_grid_view:from==="grid"?true:false ,
    })
  }

  pluginsubmit = (e) => {
    e.preventDefault();
    const params = {
      show_product_filter_and_sorting:this.state.show_product_filter_and_sorting === true ? 1 : 0, //Deprecated
      cart_badge_counter_bg_colour: this.state.cart_badge_counter_bg_colour, //Deprecated
      cart_badge_counter_text_colour: this.state.cart_badge_counter_text_colour, //Deprecated
      enable_cart_badge: this.state.enable_cart_badge === true ? 1 : 0, //Deprecated
      
      app_id: localStorage.getItem("Appid"),
      show_discount_percentage:this.state.show_discount_percentage === true ? 1 : 0,
      show_related_products: this.state.show_related_products === true ? 1 : 0,
      show_additional_information:this.state.show_additional_information === true ? 1 : 0,
      show_out_of_stock: this.state.show_out_of_stock === true ? 1 : 0,
      show_product_search: this.state.show_product_search === true ? 1 : 0,
      fit_image_horizontally:this.state.fit_image_horizontally === true ? 1 : 0,
      product_view_style:this.state.product_view_style === true ? "grid" : "list",
      enable_web_view_interface:this.state.enable_web_view_interface === true ? 1 : 0,
      discount_badge_bg_colour: this.state.discount_badge_bg_colour,
      discount_badge_text_colour: this.state.discount_badge_text_colour,
      enable_discount_badge: this.state.enable_discount_badge === true ? 1 : 0,
      
      // New Keys
      show_filter_on_listing_page:this.state.show_filter_on_listing_page===true?1:0 ,
      show_sort_on_listing_page:this.state.show_sort_on_listing_page===true?1:0,
      enable_wishlist_on_listing_page:this.state.enable_wishlist_on_listing_page===true?1:0,
      enable_wishlist_on_detail_page:this.state.enable_wishlist_on_detail_page===true?1:0,
      image_ratio_on_listing_page:this.state.image_ratio_on_listing_page,
      image_ratio_on_detail_page:this.state.image_ratio_on_detail_page,
      image_shape_on_listing_page:this.state.image_shape_on_listing_page,
      show_images_in_gallery :this.state.show_images_in_gallery===true?1:0,
      show_images_as_thumbnails_in_gallery :this.state.show_images_as_thumbnails_in_gallery===true?1:0,
      show_additional_information_as_list_on_detail_page:this.state.show_additional_information_as_list_on_detail_page===true?1:0,
      filter_related_products_by_on_detail_page:this.state.filter_related_products_by_on_detail_page,
      
      show_add_to_cart_on_listing_page:this.state.show_add_to_cart_on_listing_page===true?1:0,
      show_center_crop_images_on_detail_page : this.state.show_center_crop_images_on_detail_page===true?1:0,
      show_center_crop_images_on_listing_page : this.state.show_center_crop_images_on_listing_page===true?1:0,
      // Checkout key
      show_payment_methods_screen_bool:this.state.show_native_payment_screen===true?1:0,
      show_update_profile_toogle_on_checkout:1,
    };
    ProductSaveApi(params, this);
  };

  handleRightPanelExpand = (value) => {

    this.setState({
      appRightPanelExpand: value
    })
    }

    handleImageRatio = (e,ratio) =>{
      this.setState({
        image_ratio_on_listing_page:ratio,
        active_slide:0,
      })
    }
    handleImageRatioForDetails = (e,ratio) =>{
      this.setState({
        image_ratio_on_detail_page:ratio,
        active_slide:1,
      })
    }

    handleImageShape = (e,shape) =>{
      this.setState({
      image_shape_on_listing_page:shape
      })
    }
    handleFilterChange = (e) =>{
      
      this.setState({
        filter_related_products_by_on_detail_page:e.value,
        open_mob_filter_pop_up:false ,
        })
    }

  render() {
    return (

      <>
        <div>
          <Header customclass={"header-responsive-new"} />
        </div>
        <section className="new-consent-login">
          <Leftpanel handleRightPanelExpand={this.handleRightPanelExpand} />

          <div className={`right-panel-gap ${(localStorage.getItem("appSideBarCollapse") !== undefined &&
            localStorage.getItem("appSideBarCollapse") !== null &&
            localStorage.getItem("appSideBarCollapse") === "true") ?
            " right-panel-gap-expand" : ""}`}>
            <div className="new-consent-head ">
            <div className="productsetting-tophead-div">
              <h1 className='new-consent-headh'>Products
                <p className=" productsetting-tooltip">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                    <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                      <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                        <circle cx="9" cy="9" r="9" stroke="none" />
                        <circle cx="9" cy="9" r="8.25" fill="none" />
                      </g>
                      <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5" />
                      <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5" />
                    </g>
                  </svg>
                  <p className="productsetting-tooltipsubheader">
                  Customize your WooCommerce product settings for a seamless shopping experience for your customers.
                  </p>
                </p>
                </h1>
                <div className='date-and-savediv'>
                 {
                  this.state.last_updated_timestamp && 
                  <p className='connectionupdatedate'>Updated {moment(this.state.last_updated_timestamp).format("D MMMM YYYY, HH:mm")}</p> }
                  <button style={{backgroundColor:localStorage.getItem('button_background_color'),
                  border:'1px solid '+localStorage.getItem('button_background_color'),
                  color:localStorage.getItem('button_text_color'),
                  "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                  "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
                  "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                   className='productsettingsave' onClick={(e)=>this.pluginsubmit(e)}>
                  <div className="d-flex align-items-center justify-content-center h-100 w-100">
                    <div className={this.state.loader}>
                    <div className="new-loader"></div>
                    </div>
                    <div>{this.state.submitval}</div>
                  </div>
                  </button>

                </div>
                </div>
              

              <div className="consent-modal-tab">
                {/* <div style={{ height: "40px" }}>
                  <Link className="active" to="/app/settings/newproducts" style={{ "border-bottom": localStorage.getItem('agencyid') == 1 ? "2px solid #3064f9" : "2px solid " + localStorage.getItem('agency_primary_color'), "color": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color'), "--custom_color4": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color'), "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color') }}>Products</Link>
                  <Link to="/app/settings/newcheckout" style={{ "--custom_color4": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color'), "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color') }}>Checkout</Link>

                </div> */}
                {/* <div className='date-and-savediv'>
                  <p className='connectionupdatedate'>Updated 10 Apr 2023, 11:45</p>
                  <button className='productsettingsave'>Save</button>

                </div> */}
              </div>
              <div>
                <h3 className='product-setting-mobhead'>Products</h3>
              </div>
              {this.state.bodyspinner === true ? (
                  <div className='product-main-spinner' style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      style={{
                        margin: "auto",
                        background: "rgb(241, 242, 243,0)",
                        display: "block",
                        shaperendering: "auto",
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                      width="200px"
                      height="200px"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid"
                    >
                      <g
                        transform="translate(20 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#e6261f"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.375s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(40 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#f7d038"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.25s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(60 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#49da9a"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.125s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(80 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#4355db"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="0s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                    </svg>
                  </div>
                ) : (
              <div className='setting-productdiv'>
                <div className='product-setting-left'>
                  <div className='product-setting-tophead'>
                    <h3>Settings</h3>
                    <p>Fine-tune your product display preferences with these settings.</p>
                  </div>

                  <ul>
                    <h2>Product list</h2>
                    <li>
                      <p className='setting-enablep'>Show filter  <p className="productsetting-tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g>
                            <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                            <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader setting-managed">
                        Enable to allow your app users to filter your products by attributes.
                        </p>
                      </p></p>
                      <label className=" access-switch access-monitor-slider-padding" htmlFor='show_filter_on_listing_page'>
                      <input
                                      type="checkbox"
                                      id="show_filter_on_listing_page"
                                      name="show_filter_on_listing_page"
                                      checked={this.state.show_filter_on_listing_page}
                                      onChange={(e) => this.handleform(e,"list")}
                                    />
                        <div className="access-slider round"></div>
                      </label>
                    </li>

                    <li>
                      <p className='setting-enablep'>Show sort  <p className="productsetting-tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g>
                            <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                            <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader setting-managed">
                        Enabling to allow your customers to sort your products based on their preferences.
                        </p>
                      </p></p>
                      <label className=" access-switch access-monitor-slider-padding" htmlFor='show_sort_on_listing_page'>
                      <input
                                      type="checkbox"
                                      id="show_sort_on_listing_page"
                                      name="show_sort_on_listing_page"
                                      checked={this.state.show_sort_on_listing_page}
                                      onChange={(e) => this.handleform(e,"list")}
                                    />
                        <div className="access-slider round"></div>
                      </label>
                    </li>
                       
                           <li>
                      <p className='setting-enablep'>Show search icon in header  <p className="productsetting-tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g>
                            <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                            <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader setting-managed">
                        Enable to display a search icon in header for quicker access to relevant content.
                        </p>
                      </p></p>
                      <label className=" access-switch access-monitor-slider-padding" htmlFor='show_product_search'>
                      <input
                                      type="checkbox"
                                      id="show_product_search"
                                      name="show_product_search"
                                      checked={this.state.show_product_search}
                                      onChange={(e) => this.handleform(e,"list")}
                                    />
                        <div className="access-slider round"></div>
                      </label>
                    </li>


                    <li>
                      <p className='setting-enablep'>Show grid view  <p className="productsetting-tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g>
                            <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                            <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader setting-managed">
                        Enable to display products in a grid view instead of a list view.
                        </p>
                      </p></p>
                      <label className=" access-switch access-monitor-slider-padding"   htmlFor="product_view_style">
                      <input
                                      type="checkbox"
                                      id="product_view_style"
                                      name="product_view_style"
                                      checked={this.state.product_view_style}
                                      onChange={(e) => this.handleform(e,"list")}
                                    />
                        <div className="access-slider round"></div>
                      </label>
                    </li>

                    <li>
                      <p className='setting-enablep'>Show discount badge  <p className="productsetting-tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g>
                            <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                            <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader setting-managed">
                        Enable to display the discount badge on your products. This will appear for products that have a discounted sale price.
                        </p>
                      </p></p>
                      <label
                                    className="access-switch access-monitor-slider-padding"
                                    htmlFor="enable_discount_badge"
                                  >
                                    <input
                                      type="checkbox"
                                      id="enable_discount_badge"
                                      name="enable_discount_badge"
                                      checked={this.state.enable_discount_badge}
                                      onChange={(e)=>this.handleform(e,"list")}
                                    />
                                    <div className="access-slider round"></div>
                                  </label>
                      
                    </li>

                    
               

                    {
                      this.state.enable_discount_badge && 

                    <div className='product-color-picker'>
                      <li>
                        <p className='setting-enablep'>Badge background color  <p className="productsetting-tooltip">
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                            <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                              <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                <circle cx="7" cy="7" r="7" stroke="none" />
                                <circle cx="7" cy="7" r="6.4" fill="none" />
                              </g>
                              <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                              <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                            </g>
                          </svg>
                          <p className="productsetting-tooltipsubheader setting-managed">
                          Customize the background color of discount badges.
                          </p>
                        </p></p>
                        
                                      <Reactcolorpicker
                                        colorname={
                                          this.state.discount_badge_bg_colour
                                        }
                                       
                                        onChange={(e) => this.handleprimaryColor(e)}
                                        classselection={"fixback"}
                                        valuecontrol={true}
                                      />
                      </li>
                      <li>
                        <p className='setting-enablep'>Badge icon color  <p className="productsetting-tooltip">
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                            <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                              <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                <circle cx="7" cy="7" r="7" stroke="none" />
                                <circle cx="7" cy="7" r="6.4" fill="none" />
                              </g>
                              <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                              <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                            </g>
                          </svg>
                          <p className="productsetting-tooltipsubheader setting-managed">
                          Customize the icon color of discount badges.
                          </p>
                        </p></p>
                        <Reactcolorpicker
                                        colorname={
                                          this.state.discount_badge_text_colour
                                        }
                                        onChange={(e)=>
                                          this.handlediscount_badge_text_colour(e)
                                        }
                                        classselection={"fixback"}
                                        valuecontrol={true}
                                      />
                      </li>
                    </div> }

                    {
                    this.state.enable_discount_badge && 
                    <li>
                      <p className='setting-enablep'>Show discount percentage  <p className="productsetting-tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g>
                            <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                            <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader setting-managed">
                        Enable to display the discount percentage in the discount badge.
                        </p>
                      </p></p>
                      <label
                                    className="access-switch access-monitor-slider-padding"
                                    htmlFor="show_discount_percentage"
                                  >
                                    <input
                                      type="checkbox"
                                      id="show_discount_percentage"
                                      name="show_discount_percentage"
                                      checked={this.state.show_discount_percentage}
                                      onChange={(e)=>this.handleform(e,"list")}
                                    />
                                    <div className="access-slider round"></div>
                                  </label>
                      
                    </li>
                     }
                    <li>
                      <p className='setting-enablep'>Show "Out of stock" watermark <p className="productsetting-tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g>
                            <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                            <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader setting-managed">
                        Enable to display the"Out of Stock" watermark. This watermark will appear as per your inventory settings.
                        </p>
                      </p></p>
                      <label className=" access-switch access-monitor-slider-padding" htmlFor="show_out_of_stock">
                      <input
                                      type="checkbox"
                                      id="show_out_of_stock"
                                      name="show_out_of_stock"
                                      checked={this.state.show_out_of_stock}
                                      onChange={(e)=>this.handleform(e,"list")}
                                    />
                        <div className="access-slider round"></div>
                      </label>
                    </li>
                    <li>
                      <p className='setting-enablep'>Original images  <p className="productsetting-tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g>
                            <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                            <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader setting-managed">
                        Enable to display originally uploaded images of your products. Original images are not optimized and will increase the load time of your app.
                        </p>
                      </p></p>
                      <label className=" access-switch access-monitor-slider-padding" htmlFor="height">
                      <input
                                      type="checkbox"
                                      id="height"
                                      name="fit_image_horizontally"
                                      checked={
                                        this.state.fit_image_horizontally
                                      }
                                      onChange={(e)=>this.handleform(e,"list")}
                                    />
                        <div className="access-slider round"></div>
                      </label>
                    </li>

                    <li>
                      <p className='setting-enablep'>Show add to cart button  <p className="productsetting-tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g>
                            <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                            <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader setting-managed">
                        Enable to display the "Add to Cart" button.
                        </p>
                      </p></p>
                      <label className=" access-switch access-monitor-slider-padding" htmlFor="show_add_to_cart_on_listing_page">
                      <input
                                      type="checkbox"
                                      id="show_add_to_cart_on_listing_page"
                                      name="show_add_to_cart_on_listing_page"
                                      checked={this.state.show_add_to_cart_on_listing_page}
                                      onChange={(e)=>this.handleform(e,"list")}
                                    />
                        <div className="access-slider round"></div>
                      </label>
                    </li>
                    <li>
                      <p className='setting-enablep'>Show wishlist icon <p className="productsetting-tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g>
                            <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                            <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader setting-managed">
                        Enable to allow app users to add products to the app’s local wishlist.
                        </p>
                      </p></p>
                      <label className=" access-switch access-monitor-slider-padding" htmlFor='enable_wishlist_on_listing_page'>
                      <input
                                      type="checkbox"
                                      id="enable_wishlist_on_listing_page"
                                      name="enable_wishlist_on_listing_page"
                                      checked={this.state.enable_wishlist_on_listing_page}
                                      onChange={(e)=>this.handleform(e,"list")}
                                    />
                        <div className="access-slider round"></div>
                      </label>
                    </li>

                    <li>
                      <p className='setting-enablep'>Image ratio  <p className="productsetting-tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g>
                            <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                            <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader setting-managed">
                        Define the aspect ratio of your product images. Select a suitable image ratio corresponding to your product image.
                        </p>
                      </p></p>
                      {/* <label className=" access-switch access-monitor-slider-padding">
                                  <input
                                    type="checkbox"
                                    className="toplogo"
                                    id="skip_login"
                                    name="skip_login"
                                    // checked={this.state.skip_login}
                                    // onChange={(e) => this.handleform(e)}
                                  />
                                  <div className="access-slider round"></div>
                                </label> */}
                      <div className='product-box-size'>
                        <p onClick={(e)=>this.handleImageRatio(e,"16:9")}>
                          <svg 
                          // className={this.state.image_ratio_on_listing_page==="16:9"?'product-shapeselect':'product-shapenotselect'}
                           xmlns="http://www.w3.org/2000/svg" width="24" height="14" viewBox="0 0 24 14">
                            <g id="Path_118621" data-name="Path 118621" fill="#fff">
                              <path d="M 23 13 L 19.25 13 L 1 13 L 1 1 L 23 1 L 23 13 Z" stroke="none" />
                              <path className={this.state.image_ratio_on_listing_page==="16:9"?'product-shapeselect':'product-shapenotselect'} d="M 2 2 L 2 12 L 22 12 L 22 2 L 2 2 M 0 0 L 24 0 L 24 14 L 0 14 L 0 0 Z" stroke="none" fill="" />
                            </g>
                          </svg>
                          <span>16:9</span>
                        </p>
                        

                        <p onClick={(e)=>this.handleImageRatio(e,"4:3")}>
                          <svg className={this.state.image_ratio_on_listing_page==="4:3"?'product-shapeselect':'product-shapenotselect'} xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15">
                            <g id="Rectangle_18739" data-name="Rectangle 18739" fill="#fff" stroke="" stroke-width="2">
                              <rect width="20" height="15" stroke="none" />
                              <rect x="1" y="1" width="18" height="13" fill="none" />
                            </g>
                          </svg>
                          <span>4:3</span>
                        </p>
                        <p onClick={(e)=>this.handleImageRatio(e,"1:1")}>
                          <svg className={this.state.image_ratio_on_listing_page==="1:1"?'product-shapeselect':'product-shapenotselect'} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                            <g id="Rectangle_18739" data-name="Rectangle 18739" fill="#fff" stroke="" stroke-width="2">
                              <rect width="18" height="18" stroke="none" />
                              <rect x="1" y="1" width="16" height="16" fill="none" />
                            </g>
                          </svg>
                          <span className='product-ratio-select'>1:1</span>
                        </p>
                        <p onClick={(e)=>this.handleImageRatio(e,"3:4")}>
                          <svg className={this.state.image_ratio_on_listing_page==="3:4"?'product-shapeselect':'product-shapenotselect'} xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 15 20">
                            <g id="Rectangle_18739" data-name="Rectangle 18739" fill="#fff" stroke="" stroke-width="2">
                              <rect width="15" height="20" stroke="none" />
                              <rect x="1" y="1" width="13" height="18" fill="none" />
                            </g>
                          </svg>
                          <span>3:4</span>
                        </p>
                        <p onClick={(e)=>this.handleImageRatio(e,"9:16")}>
                        <svg className={this.state.image_ratio_on_listing_page==="9:16"?'product-shapeselect':'product-shapenotselect'} xmlns="http://www.w3.org/2000/svg" width="14" height="24" viewBox="0 0 14 24">
                          <g id="Rectangle_31455" data-name="Rectangle 31455" transform="translate(14) rotate(90)" fill="#fff" stroke="" stroke-width="2">
                            <rect width="24" height="14" stroke="none"/>
                            <rect x="1" y="1" width="22" height="12" fill="none"/>
                          </g>
                        </svg>
                          <span>9:16</span>
                        </p>
                      </div>
                    </li>
                    <li>
                      <p className='setting-enablep'>Image shape  <p className="productsetting-tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g>
                            <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                            <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader setting-managed">
                        Define the preferred shape of your product images.
                        </p>
                      </p></p>
                      {/* <label className=" access-switch access-monitor-slider-padding">
                                  <input
                                    type="checkbox"
                                    className="toplogo"
                                    id="skip_login"
                                    name="skip_login"
                                    // checked={this.state.skip_login}
                                    // onChange={(e) => this.handleform(e)}
                                  />
                                  <div className="access-slider round"></div>
                                </label> */}
                      <div className='shape-of-product'>
                        <svg onClick={(e)=>this.handleImageShape(e,"sharp_corner")} 
                        className={this.state.image_shape_on_listing_page==="sharp_corner"?'product-shapeselect':'product-shapenotselect'} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                          <g id="Rectangle_18739" data-name="Rectangle 18739" fill="#fff" stroke="" stroke-width="2">
                            <rect width="18" height="18" stroke="none" />
                            <rect x="1" y="1" width="16" height="16" fill="none" />
                          </g>
                        </svg>
                        <svg onClick={(e)=>this.handleImageShape(e,"soft_corner")} 
                        className={this.state.image_shape_on_listing_page==="soft_corner"?'product-shapeselect':'product-shapenotselect'} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                          <g id="Rectangle_18739" data-name="Rectangle 18739" fill="#fff" stroke="" stroke-width="2">
                            <rect width="18" height="18" rx="5" stroke="none" />
                            <rect x="1" y="1" width="16" height="16" rx="4" fill="none" />
                          </g>
                        </svg>
                      </div>

                    </li>
                    <li>
                      <p className='setting-enablep'>Center crop images <p className="productsetting-tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g>
                            <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                            <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader setting-managed">
                        When enabled, your images will be centred within the image frame, for a consistent look. When disabled, images will be displayed to fit the frame while maintaining their original aspect ratios.
                        </p>
                      </p></p>
                      <label className=" access-switch access-monitor-slider-padding" htmlFor='show_center_crop_images_on_listing_page'>
                      <input
                                      type="checkbox"
                                      id="show_center_crop_images_on_listing_page"
                                      name="show_center_crop_images_on_listing_page"
                                      checked={this.state.show_center_crop_images_on_listing_page}
                                      onChange={(e)=>this.handleform(e,"list")}
                                    />
                        <div className="access-slider round"></div>
                      </label>
                    </li>
                  </ul>

                  <ul>
                    <h2>Product details</h2>
                    <li>
                      <p className='setting-enablep'>Render as web view  <p className="productsetting-tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g>
                            <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                            <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader setting-managed">
                        Enable to render the product details page exactly as displayed on your mobile browser.
                        </p>
                      </p></p>
                      <label className=" access-switch access-monitor-slider-padding"   htmlFor="enable_web_view_interface">
                      <input
                                      type="checkbox"
                                      id="enable_web_view_interface"
                                      name="enable_web_view_interface"
                                      checked={
                                        this.state.enable_web_view_interface
                                      }
                                      onChange={(e) => this.handleform(e,"details")}
                                    />
                        <div className="access-slider round"></div>
                      </label>
                    </li>
                    <li>
                      <p className='setting-enablep'>Show wishlist icon  <p className="productsetting-tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g>
                            <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                            <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader setting-managed">
                        Enable to allow app users to add products to the app’s local wishlist.
                        </p>
                      </p></p>
                      <label className=" access-switch access-monitor-slider-padding" htmlFor='enable_wishlist_on_detail_page'>
                      <input
                                      type="checkbox"
                                      id="enable_wishlist_on_detail_page"
                                      name="enable_wishlist_on_detail_page"
                                      checked={this.state.enable_wishlist_on_detail_page}
                                      onChange={(e) => this.handleform(e,"details")}
                                    />
                        <div className="access-slider round"></div>
                      </label>
                    </li>
                    <li>
                      <p className='setting-enablep'>Show images in gallery  <p className="productsetting-tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g>
                            <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                            <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader setting-managed">
                        Enable to allow users to tap on the product image to launch a gallery.
                        </p>
                      </p></p>
                      <label className=" access-switch access-monitor-slider-padding" htmlFor='show_images_in_gallery'>
                      <input
                                      type="checkbox"
                                      id="show_images_in_gallery"
                                      name="show_images_in_gallery"
                                      checked={this.state.show_images_in_gallery}
                                      onChange={(e) => this.handleform(e,"3rdSlide")}
                                    />
                        <div className="access-slider round"></div>
                      </label>
                    </li>
                    {
                      this.state.show_images_in_gallery && 
                    
                    <li>
                      <p className='setting-enablep'>Show images as thumbnails in gallery <p className="productsetting-tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g>
                            <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                            <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader setting-managed">
                        Enable to display additional images as thumbnails in the gallery view.
                        </p>
                      </p></p>
                      <label className=" access-switch access-monitor-slider-padding" htmlFor='show_images_as_thumbnails_in_gallery'>
                      <input
                                      type="checkbox"
                                      id="show_images_as_thumbnails_in_gallery"
                                      name="show_images_as_thumbnails_in_gallery"
                                      checked={this.state.show_images_as_thumbnails_in_gallery}
                                      onChange={(e) => this.handleform(e,"3rdSlide")}
                                    />
                        <div className="access-slider round"></div>
                      </label>
                    </li> }
                    <li>
                      <p className='setting-enablep'>Show additional information  <p className="productsetting-tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g>
                            <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                            <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader setting-managed">
                        Enable to display additional product information.
                        </p>
                      </p></p>
                      <label className=" access-switch access-monitor-slider-padding"    htmlFor="show_additional_information">
                      <input
                                      type="checkbox"
                                      id="show_additional_information"
                                      name="show_additional_information"
                                      checked={
                                        this.state.show_additional_information
                                      }
                                      onChange={(e) => this.handleform(e,"details")}
                                    />
                        <div className="access-slider round"></div>
                      </label>
                    </li>
                    {
                      this.state.show_additional_information && 
                    
                    <li>
                      <p className='setting-enablep'>Show additional information as list  <p className="productsetting-tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g>
                            <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                            <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader setting-managed">
                        Enable to display additional product information as a list instead of values separated by commas.
                        </p>
                      </p></p>
                      <label className=" access-switch access-monitor-slider-padding" htmlFor='show_additional_information_as_list_on_detail_page'>
                      <input
                                      type="checkbox"
                                      id="show_additional_information_as_list_on_detail_page"
                                      name="show_additional_information_as_list_on_detail_page"
                                      checked={this.state.show_additional_information_as_list_on_detail_page}
                                      onChange={(e) => this.handleform(e,"details")}
                                    />
                        <div className="access-slider round"></div>
                      </label>
                    </li> }
                    <li>
                      <p className='setting-enablep'>Show related products  <p className="productsetting-tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g>
                            <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                            <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader setting-managed">
                        Enable to display related products below the viewed product.
                        </p>
                      </p></p>
                      <label className=" access-switch access-monitor-slider-padding"  htmlFor="show_related_products">
                      <input
                                      type="checkbox"
                                      id="show_related_products"
                                      name="show_related_products"
                                      checked={this.state.show_related_products}
                                      onChange={(e) => this.handleform(e,"details")}
                                    />
                        <div className="access-slider round"></div>
                      </label>
                    </li>
                    {
                      this.state.show_related_products &&
                    
                    <li className='mobile-view-click setting-last-li'>
                      <p className='setting-enablep' style={{ marginBottom: "10px" }}>Filter related products by  <p className="productsetting-tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g>
                            <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                            <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader setting-managed">
                        Choose what related products to show below the viewed product.
                        </p>
                      </p></p>
                      <div className="mobile-view-click" onClick={(e)=>window.matchMedia("(max-width: 1200px)").matches?
                      this.handleFilterMobPopUp(e):null}>
                      <ReactSelect
                        options={this.state.optionsForFilter}
                        onChange={(e) =>
                          this.handleFilterChange(e)
                        }
                        required={true}
                        classNamePrefix="profile-role-dropdown"
                        // value={{ label: this.state.user_roles }}
                         value={this.state.optionsForFilter.filter((res)=>res.value===this.state.filter_related_products_by_on_detail_page)}
                        placeholder="Select...."
                      isSearchable={false}
                      isClearable={false}
                      isDisabled={
                        window.matchMedia("(max-width: 1200px)")
                          .matches
                          ? true
                          : false
                      }
                      // styles={customStyles}
                      />
                      </div>
                    </li> }
                    <li>
                      <p className='setting-enablep'>Image ratio  <p className="productsetting-tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g>
                            <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                            <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader setting-managed">
                        Define the image aspect ratio for your products. Select a suitable image ratio corresponding to your product image.
                        </p>
                      </p></p>
                     
                      <div className='product-box-size'>
                        <p onClick={(e)=>this.handleImageRatioForDetails(e,"16:9")}>
                          <svg 
                          // className={this.state.image_ratio_on_detail_page==="16:9"?'product-shapeselect':'product-shapenotselect'}
                           xmlns="http://www.w3.org/2000/svg" width="24" height="14" viewBox="0 0 24 14">
                            <g id="Path_118621" data-name="Path 118621" fill="#fff">
                              <path d="M 23 13 L 19.25 13 L 1 13 L 1 1 L 23 1 L 23 13 Z" stroke="none" />
                              <path className={this.state.image_ratio_on_detail_page==="16:9"?'product-shapeselect':'product-shapenotselect'} d="M 2 2 L 2 12 L 22 12 L 22 2 L 2 2 M 0 0 L 24 0 L 24 14 L 0 14 L 0 0 Z" stroke="none" fill="" />
                            </g>
                          </svg>
                          <span>16:9</span>
                        </p>
                        
                        <p onClick={(e)=>this.handleImageRatioForDetails(e,"4:3")}>
                          <svg className={this.state.image_ratio_on_detail_page==="4:3"?'product-shapeselect':'product-shapenotselect'} xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15">
                            <g id="Rectangle_18739" data-name="Rectangle 18739" fill="#fff" stroke="" stroke-width="2">
                              <rect width="20" height="15" stroke="none" />
                              <rect x="1" y="1" width="18" height="13" fill="none" />
                            </g>
                          </svg>
                          <span>4:3</span>
                        </p>
                        <p onClick={(e)=>this.handleImageRatioForDetails(e,"1:1")}>
                          <svg className={this.state.image_ratio_on_detail_page==="1:1"?'product-shapeselect':'product-shapenotselect'} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                            <g id="Rectangle_18739" data-name="Rectangle 18739" fill="#fff" stroke="" stroke-width="2">
                              <rect width="18" height="18" stroke="none" />
                              <rect x="1" y="1" width="16" height="16" fill="none" />
                            </g>
                          </svg>
                          <span className='product-ratio-select'>1:1</span>
                        </p>
                        <p onClick={(e)=>this.handleImageRatioForDetails(e,"3:4")}>
                          <svg className={this.state.image_ratio_on_detail_page==="3:4"?'product-shapeselect':'product-shapenotselect'} xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 15 20">
                            <g id="Rectangle_18739" data-name="Rectangle 18739" fill="#fff" stroke="" stroke-width="2">
                              <rect width="15" height="20" stroke="none" />
                              <rect x="1" y="1" width="13" height="18" fill="none" />
                            </g>
                          </svg>
                          <span>3:4</span>
                        </p>
                        <p onClick={(e)=>this.handleImageRatioForDetails(e,"9:16")}>
                        <svg className={this.state.image_ratio_on_detail_page==="9:16"?'product-shapeselect':'product-shapenotselect'} xmlns="http://www.w3.org/2000/svg" width="14" height="24" viewBox="0 0 14 24">
                          <g id="Rectangle_31455" data-name="Rectangle 31455" transform="translate(14) rotate(90)" fill="#fff" stroke="" stroke-width="2">
                            <rect width="24" height="14" stroke="none"/>
                            <rect x="1" y="1" width="22" height="12" fill="none"/>
                          </g>
                        </svg>
                          
                          <span>9:16</span>
                        </p>
                      </div>
                    </li>
                  

                    <li>
                      <p className='setting-enablep'>Center crop images <p className="productsetting-tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g>
                            <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                            <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader setting-managed">
                        When enabled, your images will be centred within the image frame, for a consistent look. When disabled, images will be displayed to fit the frame while maintaining their original aspect ratios.
                        </p>
                      </p></p>
                      <label className=" access-switch access-monitor-slider-padding" htmlFor='show_center_crop_images_on_detail_page'>
                      <input
                                      type="checkbox"
                                      id="show_center_crop_images_on_detail_page"
                                      name="show_center_crop_images_on_detail_page"
                                      checked={this.state.show_center_crop_images_on_detail_page}
                                      onChange={(e)=>this.handleform(e,"details")}
                                    />
                        <div className="access-slider round"></div>
                      </label>
                    </li>
                  </ul>
                    

                  <ul className='productdetails-ul'>
                    <h2>Checkout</h2>
                    <li>
                      <p className='setting-enablep'>Show native payment screen  <p className="productsetting-tooltip">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                          <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g>
                            <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                            <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2" />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader setting-managed">
                        Enable to display your payment options on a native screen. Disabling this option allows users to choose payment options directly on the rendered web view payment screen.
                        </p>
                      </p></p>
                      <label className=" access-switch access-monitor-slider-padding" htmlFor="show_native_payment_screen">
                      <input
                                      type="checkbox"
                                      id="show_native_payment_screen"
                                      name="show_native_payment_screen"
                                      checked={this.state.show_native_payment_screen}
                                      onChange={(e) => this.handleform(e,"checkout")}
                                    />
                        <div className="access-slider round"></div>
                      </label>
                    </li>
                  

                  </ul>
                </div>
                <div className='product-setting-right'>
                  <div className='product-mob-previewbtn'>
                    <div className={`product-mob-previewtopback  ${this.state.active_slide===0?"login-mob-previewtopbackopacity":""}`}  onClick={()=>this.handlesliderChange("left")}>

                      <svg xmlns="http://www.w3.org/2000/svg" width="7.017" height="11.914" viewBox="0 0 7.017 11.914">
                        <path id="Path_101670" data-name="Path 101670" d="M-14099.574-20964l-5.426,5.426,5.426,5.426" transform="translate(14106.061 20964.531)" fill="none" stroke="#7782a1" stroke-width="1.5" />
                      </svg>
                    </div>
                    <div className={`product-mob-previewtopnext   ${this.state.active_slide===3?"login-mob-previewtopbackopacity":""}`} onClick={()=>this.handlesliderChange("right")}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="7.017" height="11.914" viewBox="0 0 7.017 11.914">
                        <path id="Path_101670" data-name="Path 101670" d="M-14105-20964l5.426,5.426-5.426,5.426" transform="translate(14105.53 20964.531)" fill="none" stroke="#7782a1" stroke-width="1.5" />
                      </svg>
                    </div>
                  </div>
                  <ProductPreview
                   value={this} 
                   wishlist_ref={this.wishlist_ref}
                   additional_info_ref={this.additional_info_ref}
                   related_products_ref={this.related_products_ref}
                  />
                  
  {
                this.state.product_view_style && this.state.active_slide ===0 &&
                  <div className='product-togglegrid'>

                    <svg onClick={(e)=>{this.handleListGridSvg(e,"list")}}
                     
                    
                     className={!this.state.temp_show_grid_view?"toggle-product-svg":"nottoggle-product-svg"} style={{marginRight: "20px"}} id="Group_38251" data-name="Group 38251" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <g id="Rectangle_4591" data-name="Rectangle 4591" fill="#fff" stroke="" stroke-width="2">
    <rect width="9" height="9" rx="1" stroke="none"/>
    <rect x="1" y="1" width="7" height="7" fill="none"/>
  </g>
  <g id="Rectangle_4596" data-name="Rectangle 4596" transform="translate(0 11)" fill="#fff" stroke="" stroke-width="2">
    <rect width="9" height="9" rx="1" stroke="none"/>
    <rect x="1" y="1" width="7" height="7" fill="none"/>
  </g>
  <path id="Path_9130" data-name="Path 9130" d="M954.017,177.785h9" transform="translate(-943.016 -175.434)" fill="#fff" stroke="" stroke-width="2"/>
  <path id="Path_9133" data-name="Path 9133" d="M954.017,177.785h9" transform="translate(-943.016 -163.775)" fill="#fff" stroke="" stroke-width="2"/>
  <path id="Path_9131" data-name="Path 9131" d="M954.017,177.785h6" transform="translate(-943.016 -171.364)" fill="#fff" stroke="" stroke-width="2"/>
  <path id="Path_9132" data-name="Path 9132" d="M954.017,177.785h6" transform="translate(-943.016 -159.706)" fill="#fff" stroke="" stroke-width="2"/>
                    </svg>

                    <svg onClick={(e)=>{this.handleListGridSvg(e,"grid")}} className={this.state.temp_show_grid_view?"toggle-product-svg":"nottoggle-product-svg"} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                      <g id="Group_38250" data-name="Group 38250" transform="translate(0.222)">
                        <g id="Rectangle_4592" data-name="Rectangle 4592" transform="translate(-0.222)" fill="#fff" stroke="" stroke-width="2">
                          <rect width="9" height="9" rx="1" stroke="none"/>
                          <rect x="1" y="1" width="7" height="7" fill="none"/>
                        </g>
                        <g id="Rectangle_4595" data-name="Rectangle 4595" transform="translate(-0.222 11)" fill="#fff" stroke="" stroke-width="2">
                          <rect width="9" height="9" rx="1" stroke="none"/>
                          <rect x="1" y="1" width="7" height="7" fill="none"/>
                        </g>
                        <g id="Rectangle_4593" data-name="Rectangle 4593" transform="translate(10.778)" fill="#fff" stroke="" stroke-width="2">
                          <rect width="9" height="9" rx="1" stroke="none"/>
                          <rect x="1" y="1" width="7" height="7" fill="none"/>
                        </g>
                        <g id="Rectangle_4594" data-name="Rectangle 4594" transform="translate(10.778 11)" fill="#fff" stroke="" stroke-width="2">
                          <rect width="9" height="9" rx="1" stroke="none"/>
                          <rect x="1" y="1" width="7" height="7" fill="none"/>
                        </g>
                      </g>
                    </svg>
                  </div> }
                  
                </div>


              </div> )}
              <div className='productsetting-save-mob-btn'>
                <button
                onClick={(e)=>this.pluginsubmit(e)}
                style={{backgroundColor:localStorage.getItem('button_background_color'),
                  border:'1px solid '+localStorage.getItem('button_background_color'),
                  color:localStorage.getItem('button_text_color'),
                  "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                  "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
                  "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}>

                    <div className="d-flex align-items-center justify-content-center h-100 w-100">
                    <div className={this.state.loader}>
                    <div className="new-loader"></div>
                    </div>
                    <div>{this.state.submitval}</div>
                  </div>
                  </button>
                {
                  this.state.last_updated_timestamp &&  <p>Updated {moment(this.state.last_updated_timestamp).format("D MMMM YYYY, HH:mm")}</p> }
              </div>
            </div>
          </div>
          <Modal className="protip-popup-message" isOpen={window.matchMedia("(max-width: 1200px)").matches?this.state.open_mob_filter_pop_up:false} centered>
          
          <ModalBody>
            <div className="mobileglobalsearch">
              <button onClick={this.handleFilterMobPopUp}><svg xmlns="http://www.w3.org/2000/svg" width="20.759" height="15.408" viewBox="0 0 20.759 15.408">
            <g id="Group_27465" data-name="Group 27465" transform="translate(-19.742 -22.295)">
              <path id="Path_81245" data-name="Path 81245" d="M-13783.922-19217h19.346" transform="translate(13805.077 19247)" fill="none" stroke="#7782a1" stroke-width="2"/>
              <path id="Path_82232" data-name="Path 82232" d="M14053.656,19255.426l-7,7,7,7" transform="translate(-14025.504 -19232.424)" fill="none" stroke="#7782a1" stroke-width="2"/>
            </g>
          </svg></button>
          
          </div>

          <div className="mobilesearchsuggestion">
            
         
            
        {
          this.state.optionsForFilter &&  this.state.optionsForFilter.map((option)=>{
            return(
              <p  className="mobilesearchsuggestion-para"
              onClick={()=>this.handleFilterChange(option)}
                // active={postRelatedTo==="authors"}
                >{option.label}
                </p>
            )
          })
        }
            
            </div>
          </ModalBody>
      </Modal>
        </section>
      </>

    )
  }

}

