import React from "react";
import BackwardIcon from "../../../Components/commonIcons/BackwardIcon";
import ForwardIcon from "../../../Components/commonIcons/ForwardIcon";
import HamburgerMenu from "../../../Components/commonIcons/HamburgerMenu";
import KebabMenu from "../../../Components/commonIcons/KebabMenu";
import footerText from "../../../assets/images/dashboard/webview-footer-text.png";
import websiteSidebar from "../../../assets/images/dashboard/webview-hamburger.png";
import headerText from "../../../assets/images/dashboard/webview-header-text.png";
import webViewIcon from "../../../assets/images/dashboard/webview-icon.png";
import webViewSearch from "../../../assets/images/dashboard/webview-search.png";

const WebViewPreview = ({ webViewState }) => {
  return (
    <div className="webview-right">
      <div className="webview-preview-right">
        <div className="webview-preview-screen">
          {(webViewState.showAppheader === true ||
            webViewState.showAppheader === 1) && (
            <>
              {webViewState.titleAlignment === "left" ? (
                <div
                  className="webview-ams-header-one"
                  style={{
                    borderBottom: `1px solid ${webViewState.borderColor}`,
                    background: webViewState.backgroundColor,
                  }}
                >
                  <div className="webview-ams-header-left">
                    <HamburgerMenu state={webViewState} />
                    <div className="webview-ams-header-title-div">
                      <h4
                        className="webview-ams-header-title"
                        style={{
                          color: webViewState.textColor,
                        }}
                      >
                        {webViewState.showCustomTitle === true ||
                        webViewState.showCustomTitle === 1
                          ? webViewState.customTitle
                          : webViewState.showWebsiteTitle === true ||
                            webViewState.showWebsiteTitle === 1
                          ? "Your website title"
                          : ""}
                      </h4>
                      {(webViewState.showDomain === true ||
                        webViewState.showDomain === 1) && (
                        <h6
                          className="webview-ams-header-domain"
                          style={{
                            color: webViewState.textColor,
                          }}
                        >
                          example.com
                        </h6>
                      )}
                    </div>
                  </div>

                  <div className="webview-ams-header-right">
                    {(webViewState.showBackAndForthArrows === true ||
                      webViewState.showBackAndForthArrows === 1) && (
                      <>
                        <BackwardIcon state={webViewState} />
                        <ForwardIcon state={webViewState} />
                      </>
                    )}
                    {(webViewState.showOptionsShortcut === true ||
                      webViewState.showOptionsShortcut === 1) && (
                      <KebabMenu state={webViewState} />
                    )}
                  </div>
                </div>
              ) : (
                <div
                  className="webview-ams-header-two"
                  style={{
                    borderBottom: `1px solid ${webViewState.borderColor}`,
                    background: webViewState.backgroundColor,
                  }}
                >
                  <div className="webview-ams-header-left-two">
                    <HamburgerMenu
                      state={webViewState}
                      className="webView-hamburger"
                    />

                    {(webViewState.showBackAndForthArrows === true ||
                      webViewState.showBackAndForthArrows === 1) && (
                      <BackwardIcon
                        state={webViewState}
                        className="webView-backward"
                      />
                    )}
                  </div>

                  <div
                    className="webview-ams-header-center-div"
                    style={{
                      marginRight: !webViewState.showOptionsShortcut
                        ? "8px"
                        : "0px",
                    }}
                  >
                    <p
                      className="webview-ams-header-title"
                      style={{
                        color: webViewState.textColor,
                      }}
                    >
                      {webViewState.showCustomTitle === true ||
                      webViewState.showCustomTitle === 1
                        ? webViewState.customTitle
                        : webViewState.showWebsiteTitle === true ||
                          webViewState.showWebsiteTitle === 1
                        ? "Your website title"
                        : ""}
                    </p>
                    {(webViewState.showDomain === true ||
                      webViewState.showDomain === 1) && (
                      <h6
                        className="webview-ams-header-domain"
                        style={{
                          color: webViewState.textColor,
                        }}
                      >
                        example.com
                      </h6>
                    )}
                  </div>
                  <div className="webview-ams-header-right-two">
                    {(webViewState.showBackAndForthArrows === true ||
                      webViewState.showBackAndForthArrows === 1) && (
                      <ForwardIcon state={webViewState} />
                    )}
                    {(webViewState.showOptionsShortcut === true ||
                      webViewState.showOptionsShortcut === 1) && (
                      <KebabMenu state={webViewState} />
                    )}
                  </div>
                </div>
              )}
            </>
          )}
          {(webViewState.showWebsiteHeader === true ||
            webViewState.showWebsiteHeader === 1) && (
            <div className="webview-header">
              <div className="webview-hamburger-menu">
                {webViewState.showWebsiteSidebar ? (
                  <img
                    className="webview--header-hamburger"
                    src={websiteSidebar}
                    alt="webview-hamburger"
                  />
                ) : (
                  ""
                )}
              </div>

              <img src={headerText} alt="webview-header-text" />
              <img src={webViewSearch} alt="webview-search" />
            </div>
          )}

          <div className="webview-body">
            <img src={webViewIcon} alt="webview-icon" />
          </div>
          {(webViewState.showWebsiteFooter === true ||
            webViewState.showWebsiteFooter === 1) && (
            <div className="webview-footer">
              <img src={footerText} alt="webview-footer-text" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WebViewPreview;
