import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    appUserRoleId:1,
    appName:"",
    appId:"",
    Previouslocation:"",
    Buildversion:"",
    Apiversion:"",
    Websitetype:"",
    Websitetypeselect:"",
    WebsiteTechnology:"",
    Appiconcount:0,
    Splashiconcount:0,
    Loginregistericoncount:0,
    Styleiconcount:0,
    Dashboardiconcount:0,
    Userid:null,
    Appcount:0,
    Appname:"",
    Appversion:"1.0.0",
    Storeurl:"",
    Appid: "",
    Plugindataactive:0,
    Subscriptionactive:0,
    Appsettingactive:0,
    Personaliseactive:0,
    Isverified:0,
    paymentSource:"",
}


const appDataSlice = createSlice({

name:"appData",
initialState,
reducers:{
    setAppUserRoleId(state,action){
      state.appUserRoleId=action.payload
    },
    setAppId(state,action){
      state.appId=action.payload
    },
    setAppName(state,action){
      state.appName=action.payload
    },
    setPreviouslocation(state,action){
      state.appName=action.payload
    },
    setBuildversion(state,action){
      state.appName=action.payload
    },
    setApiversion(state,action){
      state.appName=action.payload
    },
    setWebsitetype(state,action){
      state.appName=action.payload
    },
    setWebsitetypeselect(state,action){
      state.appName=action.payload
    },
    setWebsiteTechnology(state,action){
      state.appName=action.payload
    },
    setAppiconcount(state,action){
      state.appName=action.payload
    },
    setSplashiconcount(state,action){
      state.appName=action.payload
    },
    setLoginregistericoncount(state,action){
      state.appName=action.payload
    },
    setStyleiconcount(state,action){
      state.appName=action.payload
    },
    setDashboardiconcount(state,action){
      state.appName=action.payload
    },
    setUserid(state,action){
      state.appName=action.payload
    },
    setAppcount(state,action){
      state.appName=action.payload
    },
    setAppname(state,action){
      state.appName=action.payload
    },
    setAppversion(state,action){
      state.appName=action.payload
    },
    setStoreurl(state,action){
      state.appName=action.payload
    },
    setAppid(state,action){
      state.appName=action.payload
    },
    setPlugindataactive(state,action){
      state.appName=action.payload
    },
    setSubscriptionactive(state,action){
      state.appName=action.payload
    },
    setAppsettingactive(state,action){
      state.appName=action.payload
    },
    setPersonaliseactive(state,action){
      state.appName=action.payload
    },
    setIsverified(state,action){
      state.appName=action.payload
    }, 
     setPaymentSource(state,action){
      state.paymentSource=action.payload
    },
}



})

export const {setAppUserRoleId,setAppName,setAppId, setPreviouslocation, setBuildversion, setApiversion, setWebsitetype, setWebsitetypeselect, setWebsiteTechnology, setAppiconcount, setSplashiconcount, setLoginregistericoncount, setStyleiconcount, setDashboardiconcount, setUserid, setAppcount, setAppname, setAppversion, setStoreurl, setAppid, setPlugindataactive, setSubscriptionactive, setAppsettingactive, setPersonaliseactive, setIsverified,setPaymentSource} = appDataSlice.actions;

export default appDataSlice.reducer;
