import React, { useEffect, useState, useRef } from "react";
import "./../../assets/css/Dashboard.css";
import Header from "./../Header";
import Leftpanel from "./../Leftpanel";
import { Link } from "react-router-dom";
import { 
	Spinner, 
	UncontrolledDropdown,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from "reactstrap";
import moment from "moment";
import { InnerFoot } from "./../Foot";
import Slider from "./.././Reusable/Slider";
import imagebackground from "./../../assets/images/dashboard/cms/uploadbackground.png";
import styles from "./cmsBuilderstyle.css";
import {
  	Media,
  	Dropdown,
  	DropdownToggle,
  	DropdownMenu,
  	DropdownItem,
} from "reactstrap";
import {
  	cmsDeleteTag,
  	cmsGetEachTag,
  	cmsgetTagsApi,
  	cmsSaveTagApi,
	getTagSettingsApi,
	saveTagSettingsApi,
} from "../../Api/CmsApi/Cmsapi";
import { errortoast } from "../Toaster";
import { useMemo } from "react";
import ReactTags from 'react-tag-autocomplete';
import imagebackground2 from "./../../assets/images/dashboard/cms/bannerimg.jpg";
import "./tagsandcategory.css";
import { FormGroup, Label, Input } from "reactstrap";
import Reactcolorpicker from "../Appearance/NewReactcolorpicker";
import BottomScrollListener from "react-bottom-scroll-listener";
import SettingIcon from "../../Components/commonIcons/SettingIcon";
const Tagpage = () => {
  	const [eachTagData, setEachTagData] = useState([]);
  	const [spinner, setSpinner] = useState(true);
  	const [spinner2, setSpinner2] = useState(false);
  	const [tagsData, setTagsData] = useState([]);
  	const [name, setName] = useState("");
  	const [tagID, setTagID] = useState("");
  	const [searchVal, setSearchVal] = useState("");
  	const [isDeleted, setisDeleted] = useState(false);
  	const [isLoader, setIsLoader] = useState("d-none");
  	const [isSaved, setisSaved] = useState(false);
  	const [loaderimage, setLoaderimage] = useState( require("../../assets/images/signup/Loader_GIF.gif").default );
	const [mdltags, setMdlTags] = useState(false);
	const [arrtags, setArrTags] = useState([]);
	const [arrtagids, setArrTagIds] = useState([]);
	const [submitval, setSubmitval] = useState("Create");
	const [isTrashed, setIsTrashed] = useState(false);
	const [deleteIdForTag, setDeleteIdForTag] = useState("");
	const [bottomSpinner, setBottomSpinner] = useState(false);

	// setting screen key

	const [activeSettingTab, setActiveSettingTab] = useState(false);
	const [activeLayoutTab, setActiveLayoutTab] = useState(false);
	const [showSearchInHeader, setShowSearchInHeader] = useState(true);
	const [bgColor, setBgColor] = useState("#F9F9F9");
	const [textColor, setTextColor] = useState("#333333");
	const [textBgColor, setTextBgColor] = useState("#B0B0B0");
	const [tagLayout, setTagLayout] = useState("layout-1");
	const [settingUpdatedAt, setSettingUpdatedAt] = useState("");
	
	const [settingSaveBtnVal, setSettingSaveBtnVal] = useState("Save");
	const [settingSaveBtnLoader, setSettingSaveBtnLoader] = useState("d-none");

	const [page, setPage] = useState(1);
	const [paginationSpinner , setPaginationSpinner] = useState(false);
	const [hitNextPagination , setHitNextPagination] = useState(false);
	const [subData , setSubData] = useState([]);
	

	const [appRightPanelExpand, setAppRightPanelExpand] = useState( localStorage.getItem("appSideBarCollapse") === true ||
	localStorage.getItem("appSideBarCollapse") === "true"
	  ? true
	  : false);
	const reactTags = useRef();


  	useEffect(() => {
		if ( parseInt(localStorage.getItem("website_technology")) !== 3 && window.location.pathname.includes("custom")) {
			window.location.href="/app/dashboard"
	  
		   }
	if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
    {
		document.title = "Post tags | AppMySite";
      
    }
    else
    {
		document.title = "Post tags |"+ ' ' +localStorage.getItem('agencyname');
    }

		let limit = 20;
		let pageNo = 1;
		setPage(pageNo);

    	cmsgetTagsApi(
		 setTagsData,
		 btoa(localStorage.getItem("Appid")),
		 setSpinner,
		 setBottomSpinner,
		 false,
		 false,
		 limit,
		 pageNo,
		 tagsData,
		 setHitNextPagination,
		 setPaginationSpinner,
		 setSubData,
		);

		getTagSettingsApi(setShowSearchInHeader,setBgColor,setTextColor,setTextBgColor,setTagLayout,setSettingUpdatedAt);
  	}, [isDeleted, isSaved]);

	
	  const handleSettingIconClick = (e) => {
		setActiveSettingTab(!activeSettingTab);
		setActiveLayoutTab(false);
	};

	const handleLayoutIconClick = (e) => {
		setActiveLayoutTab(!activeLayoutTab);
		setActiveSettingTab(false);
	};

	const handleHomeBtn = (e) => {
		setActiveLayoutTab(false);
		setActiveSettingTab(false);
	};
	  
  	/*const handleAddTag = (e) => {
    	setIsLoader("d-block");
    	setSubmitval("");
    	if (name.trim() === "" || name === undefined || name === null) 
		{
      		errortoast("Name can't be empty");
      		setIsLoader("d-none");
      		setSubmitval("Save Tag");
    	} 
		else 
		{
      		const params = {
        					title: name,
        					app_id: parseInt(localStorage.getItem("Appid")),
        					user_id: parseInt(localStorage.getItem("user_id")),
        					content: "",
        					tag_id: tagID,
			};

      		cmsSaveTagApi( params, setIsLoader, setSubmitval, setName, setisSaved, isSaved, setSpinner, setTagID );
    	}
  	};*/

  	const handleName = (e) => {
    	setName(e.target.value);
  	};

  	const handleDelete = (e, tagID) => {
    	const params = { tag_id: tagID ,   app_id :   parseInt(localStorage.getItem("Appid"))
	};
    	setSpinner(!spinner);
    	setTagID("");
    	setName("");
    	cmsDeleteTag(params, setisDeleted, isDeleted);
  	};

  	const handleEditTag = (e, tagId) => {
    	if (tagId !== tagID) 
		{
      		setSpinner2(!spinner2);
      		setTagID(tagId);
    	}
  	};

  	const handleSearchVal = (e) => {
    	setSearchVal(e.target.value);
  	};

  	const EditApi = () => {
    	if (tagID !== undefined && tagID !== null && tagID !== "") 
		{
      		cmsGetEachTag(tagID, setEachTagData, setName, setSpinner2);
    	}
  	};

	const handleContainerOnBottom = () => {
		if (paginationSpinner === false && bottomSpinner ===false) {
			if (hitNextPagination) 
			{
				let limit = 20;
				let pageNo = page + 1;
				setPage(pageNo);
				setPaginationSpinner(true);

				cmsgetTagsApi(
					setTagsData,
					btoa(localStorage.getItem("Appid")),
					setSpinner,
					setBottomSpinner,
					false,
					false,
					limit,
					pageNo,
					tagsData,
					setHitNextPagination,
					setPaginationSpinner,
					setSubData,
				);
			}
		}
	};

	const handleNotHitOnBottom = () => {

	}

  	useMemo(() => EditApi(), [tagID]);

	const handleTagPopupShow = () =>{
		
		setArrTags([]);
		setMdlTags(!mdltags);
	}

	const handleCloseTagsModel = () =>{
		setArrTags([]);
		setMdlTags(false);
	}

	const onTagDelete =  (i) =>{
		//e.preventDefault();

		/*const tags = arrtags.slice(0);

		tags.splice(i, 1);

		setArrTags( tags );*/
		

		const newTags = [...arrtags];

    	newTags.splice(i, 1);

		setArrTags(newTags);    	

	}
	
	const  onTagAddition = (tagdata) => {
		if(tagdata.name.trim()){
		setArrTags(() => 
		{
			let newtagdat = { "name": (tagdata.name.substring(0,60)).charAt(0).toUpperCase() + (tagdata.name.substring(0,60)).slice(1),"id":null};
			
			return [...arrtags, newtagdat];
		});

		setArrTagIds(() => 
		{
			return [...arrtagids, ""];
		});
	}

	}

	const handleTagsName = (e) =>{
		let isSameNameExist = false;

		if(arrtags && arrtags.length > 0){

			for(let j=0; j<arrtags.length; j++){

				for(let i=j+1; i<arrtags.length; i++){
					if(arrtags[j].name === arrtags[i].name){
						isSameNameExist = true;
						break;
					}	
				if(isSameNameExist){
					break;
				}
			}		
			}
		}
		
    	if (arrtags.length <= 0) 
		{
      		errortoast("Name can't be empty");
    	} 
		else if(isSameNameExist){
			errortoast("Tag name already exist");
		}
		else 
		{

			const params = {
						title: arrtags,
						app_id: parseInt(localStorage.getItem("Appid")),
						user_id: parseInt(localStorage.getItem("user_id")),
						author_user_id:parseInt(localStorage.getItem("originalid")),
						content: "",
						tag_id: arrtagids
			};
			
	

			setSubmitval("");
			setIsLoader("d-block");
      		cmsSaveTagApi( params, setIsLoader, setSubmitval, setMdlTags, setisSaved, isSaved, setSpinner ,setBottomSpinner );
    	}
	}

	  // Trash Pop up

	  const handleTrashedPopUp = (e, from, id) => {
		setIsTrashed(!isTrashed);
		if (from === "add") {
			setDeleteIdForTag(id);
		}
	  };
	
	  //Trash Post
	  const handleTrashedTag = (e) => {
		setIsTrashed(!isTrashed);
		const params = { tag_id: deleteIdForTag ,   app_id :   parseInt(localStorage.getItem("Appid"))
	};
    	setSpinner(!spinner);
    	setTagID("");
    	setName("");
    	cmsDeleteTag(params, setisDeleted, isDeleted);
		
	
	  };

	  const handleRightPanelExpand = (value) => {

		setAppRightPanelExpand(value);
	  }

	  // Settings fn

	const  handleShowSearchToggle = (e) => {
		setShowSearchInHeader(e.target.checked);
	  }
	const  handleBgColor = (e) => {
		setBgColor(e);
	 }
    const  handleTextColor = (e) => {
		setTextColor(e);
	  }
	const handleTextBgColor = (e) => {
		setTextBgColor(e);
	  }

	  const handleChangeLayout = (e,from) => {
        setTagLayout(from);
	  }

	  const handleSubmitTagSettingsData = () => {
		setSettingSaveBtnLoader("d-block");
		setSettingSaveBtnVal("");
	 
	   const params = {
			app_id: parseInt(localStorage.getItem("Appid")),
			layout:tagLayout,
			show_search_in_header:showSearchInHeader ? 1 : 0,
			background_color:bgColor,
			text_color:textColor,
			text_bg_color:textBgColor,

	   } ;
	   saveTagSettingsApi(params, setSettingSaveBtnLoader,setSettingSaveBtnVal,setSettingUpdatedAt);
	  }
  	return (
    <>
      	<div className="d-md-block">
        	<Header customclass={"header-responsive-new"} />
      	</div>
      	{/* Mobile View */}
      	{/* Mobile View End*/}
      	<section className="dasboard_page mobile-display mt-4 mt-md-0 cms-module">
		  <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
		  <BottomScrollListener
              onBottom={window.matchMedia("(max-width: 1200px)").matches?handleContainerOnBottom:handleNotHitOnBottom}
              offset={100}
            >
              {(scrollRef) => (
                <div
                style={{
                  height: "calc(100vh - 60px)",
                  overflowY: "auto",
                }}
                ref={scrollRef}
		  
		  className={`right-panel-gap ${(localStorage.getItem("appSideBarCollapse") !== undefined &&
                      localStorage.getItem("appSideBarCollapse") !== null&&
                      localStorage.getItem("appSideBarCollapse") === "true") ?
                          " right-panel-gap-expand": ""}`}>

			<div className="post-heading d-none d-xl-flex">
			<h1>Posts <p class="cms-posts-tooltip">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5"><circle cx="9" cy="9" r="9" stroke="none"></circle><circle cx="9" cy="9" r="8.25" fill="none"></circle></g><line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"></line><path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5"></path></g></svg>
                  <p class="cms-posts-tooltipsubheader">A post is a time stamped article organized through categories and tags. Most recent posts will appear first.</p>
                  </p></h1>
            </div>
			
			<div className="post-heading category-heading">
                <h1>Tags</h1>
				
				<div className="tags-mobile-header-btns">
				{/* {activeLayoutTab === false && activeSettingTab === false &&
				<Link to="#" onClick={handleTagPopupShow} >
					<img className="mr-2" src={ require("../../assets/images/dashboard/plus.png").default } />
					<p>Create </p>
				</Link> } */}
				{
					tagsData && tagsData.length > 0 &&
				<>
				                {
                    window.matchMedia("(max-width: 1200px)").matches &&
                   <>
                    {
                      ( activeSettingTab === false && activeLayoutTab === false ) ? 
                    
                //     <div className="consent-setting-clicked" style={{marginRight: "0px",marginLeft:"20px" ,cursor:"default"}}>
                //             <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                //        <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                //     <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                //     <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="#7782a1" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                //   </g>
                // </svg>
                //             </div>
				<></>
                    :
                  <div className="notification-setting " style={{marginRight: "0px",marginLeft:"20px"}} 
                  onClick={(e) => handleHomeBtn(e)}
                  >
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                  <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                    <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                    <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                  </g>
                </svg>
    

                        </div>
                    }
                    </>
                }


				<button
                      className={activeLayoutTab===true?"tagpagelayout-svg setting-bt-off custom-layout-gap-mob": "tagpagelayout-svg setting-bt-on custom-layout-gap-mob"}
                      onClick={(e) =>handleLayoutIconClick(e)}
                    >
                      {/* <div className="setting-first-shadow"></div>
                   <div className="setting-second-shadow"></div> */}
				  { activeLayoutTab===true?
		
<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="26" height="26" viewBox="0 0 26 26">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_32448" data-name="Rectangle 32448" width="26" height="26" transform="translate(62 1)" fill="rgba(0,0,0,0)"/>
    </clipPath>
  </defs>
  <g id="Mask_Group_34872" data-name="Mask Group 34872" transform="translate(-62 -1)" clip-path="url(#clip-path)">
    <g id="Group_43107" data-name="Group 43107" transform="translate(63.887 2.887)">
      <g id="Rectangle_32447" data-name="Rectangle 32447" transform="translate(3.113 0.113)" fill="#7782a1" stroke="#7782a1" stroke-linejoin="round" stroke-width="1">
        <rect width="16" height="22" rx="1.5" stroke="none"/>
        <rect x="-0.5" y="-0.5" width="17" height="23" rx="2" fill="none"/>
      </g>
      <path id="Union_3151" data-name="Union 3151" d="M15359,21365v-2h6v2Zm-4,0v-2h2v2Zm4-6v-2h6v2Zm-4,0v-2h2v2Zm4-6v-2h6v2Zm-4,0v-2h2v2Z" transform="translate(-15348.887 -21346.887)" fill="#fff"/>
    </g>
  </g>
</svg>

:
<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="26" height="26" viewBox="0 0 26 26">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_29591" data-name="Rectangle 29591" width="26" height="26" transform="translate(62 1)" fill="rgba(0,0,0,0)"/>
    </clipPath>
  </defs>
  <g id="Mask_Group_25287" data-name="Mask Group 25287" transform="translate(-62 -1)" clip-path="url(#clip-path)">
    <g id="Group_39235" data-name="Group 39235" transform="translate(63.887 2.887)">
      <rect id="Rectangle_29590" data-name="Rectangle 29590" width="16" height="22" rx="1.5" transform="translate(3.113 0.113)" fill="none" stroke="#7782a1" stroke-linejoin="round" stroke-width="1.5"/>
      <path id="Path_119244" data-name="Path 119244" d="M11.113,5.113h6" transform="translate(-1)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
      <path id="Path_119245" data-name="Path 119245" d="M11.113,5.113h6" transform="translate(-1 6)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
      <path id="Path_119246" data-name="Path 119246" d="M11.113,5.113h6" transform="translate(-1 12)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
      <g id="Group_43104" data-name="Group 43104" transform="translate(6.113 4.363)">
        <path id="Path_119244-2" data-name="Path 119244" d="M11.113,5.113h2" transform="translate(-11.113 -4.363)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
        <path id="Path_119245-2" data-name="Path 119245" d="M11.113,5.113h2" transform="translate(-11.113 1.637)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
        <path id="Path_119246-2" data-name="Path 119246" d="M11.113,5.113h2" transform="translate(-11.113 7.637)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
      </g>
    </g>
  </g>
</svg>
}


                    </button>

					<button
                      className={activeSettingTab===true?" setting-bt-off custom-layout-gap-mob":"setting-bt-on custom-layout-gap-mob"}
                      onClick={(e) => handleSettingIconClick(e)}
                    >
                      {/* <div className="setting-first-shadow"></div>
                   <div className="setting-second-shadow"></div> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="23.5" height="23.499" viewBox="0 0 23.5 23.499">
                        <g id="Group_38368" data-name="Group 38368" transform="translate(-1598.25 -88.901)">
                          <path id="Path_99646" data-name="Path 99646" d="M11.513,22.5a10.829,10.829,0,0,0,1.266-.074.615.615,0,0,0,.542-.642,2.437,2.437,0,0,1,1.488-2.388,2.278,2.278,0,0,1,.887-.171,2.527,2.527,0,0,1,1.855.788.616.616,0,0,0,.837.067,10.968,10.968,0,0,0,1.739-1.76.615.615,0,0,0-.066-.829,2.432,2.432,0,0,1,1.592-4.231h.056a.73.73,0,0,0,.72-.542,11,11,0,0,0,0-2.5.592.592,0,0,0-.613-.54h-.022A2.543,2.543,0,0,1,19.4,8.19a2.437,2.437,0,0,1,.613-2.744.614.614,0,0,0,.066-.836A10.919,10.919,0,0,0,18.3,2.851a.606.606,0,0,0-.377-.131.615.615,0,0,0-.454.2,2.532,2.532,0,0,1-1.855.785,2.259,2.259,0,0,1-.868-.165A2.449,2.449,0,0,1,13.238,1.2a.614.614,0,0,0-.544-.63,11.023,11.023,0,0,0-2.474,0,.615.615,0,0,0-.539.643A2.443,2.443,0,0,1,8.195,3.6a2.318,2.318,0,0,1-.89.172,2.524,2.524,0,0,1-1.853-.788.615.615,0,0,0-.837-.069,10.968,10.968,0,0,0-1.739,1.76.615.615,0,0,0,.066.829A2.43,2.43,0,0,1,1.279,9.733a.718.718,0,0,0-.708.54,11.037,11.037,0,0,0,0,2.5.617.617,0,0,0,.6.542h.181A2.464,2.464,0,0,1,3.6,14.808a2.438,2.438,0,0,1-.615,2.743.614.614,0,0,0-.066.837A11,11,0,0,0,4.7,20.145a.616.616,0,0,0,.83-.069,2.535,2.535,0,0,1,1.853-.785,2.3,2.3,0,0,1,.87.165A2.443,2.443,0,0,1,9.76,21.8a.614.614,0,0,0,.545.628A11.109,11.109,0,0,0,11.513,22.5Z" transform="translate(1598.501 89.151)" fill="" stroke="" stroke-linejoin="round" stroke-width="1.5"/>
                          <path id="Path_99647" data-name="Path 99647" d="M9.818,5.846a4,4,0,1,1-4,4A4,4,0,0,1,9.818,5.846Z" transform="translate(1600.182 90.804)" fill="rgba(256,256,256,1)" stroke="" stroke-linejoin="round" stroke-width="1.5"/>
                        </g>
                      </svg>

                    </button>
					</>
                  }
				</div>
				
        	</div>

			{ window.matchMedia("(max-width: 1200px)").matches && 
         <>
         {
         ( activeLayoutTab === false && activeSettingTab === false ) &&        
          <div className="notification-mob-create-btn postCreate-mob-btn" onClick={handleTagPopupShow}>
                <p className=" update-mob-upgadelink" 
				style={{
					marginBottom:"0px"
				}}
                
                >
                  <img src={ require("../../assets/images/dashboard/plus.png").default } className="img-fluid mr-2 symbolspace" alt="plus" />
                  Create
              </p>
                </div>
         }
         </>
             }
			
        <div className="pageshead"
		 style={{
            padding:(window.matchMedia("(max-width: 1200px)").matches && (activeSettingTab ===true || activeLayoutTab === true ))?"0":""
          }}
		>
              
              <div className="post-sublinks">
                <Link to="/app/posts" style={{"--custom_color4": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color')}}>Posts</Link>
                <Link to="/app/post-categories" style={{"--custom_color4": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color')}}>Categories</Link>
                <Link className="active" to="/app/post-tags" style={{"border-bottom": localStorage.getItem('agencyid') == 1 ? "2px solid #3064f9" : "2px solid "+ localStorage.getItem('agency_primary_color'),"color": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color')}}>Tags</Link>
				<Link to="/app/post-attributes" style={{"--custom_color4": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color')}}>Attributes</Link>
			  </div>
              <div className="createpage allpages">
			  {settingUpdatedAt && (activeSettingTab === true || activeLayoutTab === true) &&
              <p style={{
                display:window.matchMedia("(max-width: 1200px)").matches?"none":"block",
                textAlign:"center",
                color:"#bcc1ce",
                fontSize:"12px"
              }}>Updated  {moment(settingUpdatedAt).format("D MMMM YYYY, HH:mm")}{" "}</p>
            }
				{
					tagsData && tagsData.length > 0 && activeLayoutTab === false && activeSettingTab === false &&
				
                <div className="cms-pages-search">
                  <input
                    placeholder="Search..."
                    type="text"
					value={searchVal}
                    onChange={(e)=>handleSearchVal(e)}
                  />
                  <img src={require("../../assets/images/dashboard/cms/lens.png").default} />
                </div>

				
                
					}


				{
					tagsData && tagsData.length > 0 &&
				<>
                  {
                    window.matchMedia("(min-width: 1200px)").matches &&
                   <>
                    {
                      ( activeSettingTab === false && activeLayoutTab === false ) ? 
                    
                //     <div className="consent-setting-clicked" style={{marginRight: "0px",marginLeft:"20px" ,cursor:"default"}}>
                //             <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                //        <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                //     <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                //     <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="#7782a1" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                //   </g>
                // </svg>
                //             </div>
				<></>
                    :
                  <div className="notification-setting " style={{marginRight: "0px",marginLeft:"20px"}} 
                  onClick={(e) => handleHomeBtn(e)}
                  >
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                  <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                    <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                    <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                  </g>
                </svg>
    

                        </div>
                    }
                    </>
                }
				</>}

					{
						tagsData && tagsData.length > 0 &&
					<>
					<button
                      className={ activeLayoutTab===true?" setting-bt-off custom-layout-gap tagpagelayout-svg": " setting-bt-on custom-layout-gap tagpagelayout-svg"}
                      onClick={(e) =>handleLayoutIconClick(e)}
					  
                    >
                      {/* <div className="setting-first-shadow"></div>
                   <div className="setting-second-shadow"></div> */}
				   { activeLayoutTab===true?
					<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="26" height="26" viewBox="0 0 26 26">
  <defs>
    <clipPath id="clip-path1">
      <rect id="Rectangle_32448" data-name="Rectangle 32448" width="26" height="26" transform="translate(62 1)" fill="rgba(0,0,0,0)"/>
    </clipPath>
  </defs>
  <g id="Mask_Group_34872" data-name="Mask Group 34872" transform="translate(-62 -1)" clip-path="url(#clip-path1)">
    <g id="Group_43107" data-name="Group 43107" transform="translate(63.887 2.887)">
      <g id="Rectangle_32447" data-name="Rectangle 32447" transform="translate(3.113 0.113)" fill="#7782a1" stroke="#7782a1" stroke-linejoin="round" stroke-width="1">
        <rect width="16" height="22" rx="1.5" stroke="none"/>
        <rect x="-0.5" y="-0.5" width="17" height="23" rx="2" fill="none"/>
      </g>
      <path id="Union_3151" data-name="Union 3151" d="M15359,21365v-2h6v2Zm-4,0v-2h2v2Zm4-6v-2h6v2Zm-4,0v-2h2v2Zm4-6v-2h6v2Zm-4,0v-2h2v2Z" transform="translate(-15348.887 -21346.887)" fill="#fff"/>
    </g>
  </g>
</svg>

:
<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="26" height="26" viewBox="0 0 26 26">
  <defs>
    <clipPath id="clip-path2">
      <rect id="Rectangle_29591" data-name="Rectangle 29591" width="26" height="26" transform="translate(62 1)" fill="rgba(0,0,0,0)"/>
    </clipPath>
  </defs>
  <g id="Mask_Group_25287" data-name="Mask Group 25287" transform="translate(-62 -1)" clip-path="url(#clip-path2)">
    <g id="Group_39235" data-name="Group 39235" transform="translate(63.887 2.887)">
      <rect id="Rectangle_29590" data-name="Rectangle 29590" width="16" height="22" rx="1.5" transform="translate(3.113 0.113)" fill="none" stroke="#7782a1" stroke-linejoin="round" stroke-width="1.5"/>
      <path id="Path_119244" data-name="Path 119244" d="M11.113,5.113h6" transform="translate(-1)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
      <path id="Path_119245" data-name="Path 119245" d="M11.113,5.113h6" transform="translate(-1 6)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
      <path id="Path_119246" data-name="Path 119246" d="M11.113,5.113h6" transform="translate(-1 12)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
      <g id="Group_43104" data-name="Group 43104" transform="translate(6.113 4.363)">
        <path id="Path_119244-2" data-name="Path 119244" d="M11.113,5.113h2" transform="translate(-11.113 -4.363)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
        <path id="Path_119245-2" data-name="Path 119245" d="M11.113,5.113h2" transform="translate(-11.113 1.637)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
        <path id="Path_119246-2" data-name="Path 119246" d="M11.113,5.113h2" transform="translate(-11.113 7.637)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
      </g>
    </g>
  </g>
</svg>
}

                    </button>

					{/* <button
                      className={activeSettingTab===true?" setting-bt-off custom-layout-gap":"setting-bt-on custom-layout-gap"}
                      onClick={(e) => handleSettingIconClick(e)}
                    >
                      
                    <svg xmlns="http://www.w3.org/2000/svg" width="23.5" height="23.499" viewBox="0 0 23.5 23.499">
                        <g id="Group_38368" data-name="Group 38368" transform="translate(-1598.25 -88.901)">
                          <path id="Path_99646" data-name="Path 99646" d="M11.513,22.5a10.829,10.829,0,0,0,1.266-.074.615.615,0,0,0,.542-.642,2.437,2.437,0,0,1,1.488-2.388,2.278,2.278,0,0,1,.887-.171,2.527,2.527,0,0,1,1.855.788.616.616,0,0,0,.837.067,10.968,10.968,0,0,0,1.739-1.76.615.615,0,0,0-.066-.829,2.432,2.432,0,0,1,1.592-4.231h.056a.73.73,0,0,0,.72-.542,11,11,0,0,0,0-2.5.592.592,0,0,0-.613-.54h-.022A2.543,2.543,0,0,1,19.4,8.19a2.437,2.437,0,0,1,.613-2.744.614.614,0,0,0,.066-.836A10.919,10.919,0,0,0,18.3,2.851a.606.606,0,0,0-.377-.131.615.615,0,0,0-.454.2,2.532,2.532,0,0,1-1.855.785,2.259,2.259,0,0,1-.868-.165A2.449,2.449,0,0,1,13.238,1.2a.614.614,0,0,0-.544-.63,11.023,11.023,0,0,0-2.474,0,.615.615,0,0,0-.539.643A2.443,2.443,0,0,1,8.195,3.6a2.318,2.318,0,0,1-.89.172,2.524,2.524,0,0,1-1.853-.788.615.615,0,0,0-.837-.069,10.968,10.968,0,0,0-1.739,1.76.615.615,0,0,0,.066.829A2.43,2.43,0,0,1,1.279,9.733a.718.718,0,0,0-.708.54,11.037,11.037,0,0,0,0,2.5.617.617,0,0,0,.6.542h.181A2.464,2.464,0,0,1,3.6,14.808a2.438,2.438,0,0,1-.615,2.743.614.614,0,0,0-.066.837A11,11,0,0,0,4.7,20.145a.616.616,0,0,0,.83-.069,2.535,2.535,0,0,1,1.853-.785,2.3,2.3,0,0,1,.87.165A2.443,2.443,0,0,1,9.76,21.8a.614.614,0,0,0,.545.628A11.109,11.109,0,0,0,11.513,22.5Z" transform="translate(1598.501 89.151)" fill="" stroke="" stroke-linejoin="round" stroke-width="1.5"/>
                          <path id="Path_99647" data-name="Path 99647" d="M9.818,5.846a4,4,0,1,1-4,4A4,4,0,0,1,9.818,5.846Z" transform="translate(1600.182 90.804)" fill="rgba(256,256,256,1)" stroke="" stroke-linejoin="round" stroke-width="1.5"/>
                        </g>
                      </svg>

                    </button> */}
					<div
                          onClick={(e) => handleSettingIconClick(e)}
                          className="cms-setting-btn web-cms-setting-btn"
                        >
                          <SettingIcon
                           
                            isActive={activeSettingTab}
                          />
                        </div>
					</>
                   }

                <div>
					{
						(activeLayoutTab === false && activeSettingTab === false ) ?
					
                  <button
                    className="createpage d-none d-xl-flex"
                     onClick={handleTagPopupShow} 
					style={{backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
				 >
                    <img
                      className="mr-2"
                      src={
                        require("../../assets/images/dashboard/cms/plus.png")
                          .default
                      }
                    />
              
			        <span style={{
                      marginTop:"2px"
                    }}>
                      Create
                      </span>
				
                  </button>
				  :!window.matchMedia("(max-width: 1200px)").matches &&
				  <button
				  className="createpage"
				  onClick={(e) => handleSubmitTagSettingsData(e)}
				  style={{backgroundColor:localStorage.getItem('button_background_color'),
				  border:'1px solid '+localStorage.getItem('button_background_color'),
				  width: "100px",
				//   marginLeft: "40px",
				  color:localStorage.getItem('button_text_color'),
				  "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
				  "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
				  "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
					
				>
				  <div className={settingSaveBtnLoader}>
					<div className="new-loader"></div>
				  </div>
				  <div>{ settingSaveBtnVal}</div>
				   </button>
               }

                </div>
              </div>
            </div>	

				
			{activeSettingTab === false && activeLayoutTab === false?
          		<>
          		<div className="whitebg add-category-page">
            		{
						tagsData!==null && tagsData.length > 0  
					&&
				  <div className="category-list-title">
					<h2>Tag</h2>
					<h5>Posts</h5>
				</div>
                 }
					<div // className="cms-category-listing"
					style={{
						display:tagsData && tagsData.length > 0  ? "flex":""
					  }}>

							<div>
								<div className="create-category-tag d-none">
								<div className="category-tags-editor">
									{spinner2 === true ? 
										(
											<div className="spinn">
												<Spinner animation="border" variant="primary" />
											</div>
										) 
									: 
										(
											<>
											{/*<div className="row">
												<div className="col-md-12">
													<h6>Name</h6>
													<input type="text" onChange={(e) => handleName(e)} value={name} />
												</div>
											</div>

											<div className="row">
												<div className="col-md-12">
													<button className="add-cat-tag" onClick={(e) => handleTagsName(e)} >
														<div className={isLoader}>
															<div className="new-loader"></div>
														</div>
														{submitval}
													</button>
												</div>
											</div>*/}
											<Modal isOpen={mdltags} centered dataclass="countrycode" className="cms-tag-popup">
												<ModalHeader >												
													<div className="security-crossmodal " onClick={handleCloseTagsModel}>
														<img src={require("../../assets/images/dashboard/cross.png").default} />
													</div>
												</ModalHeader>
												<ModalBody>
													
													<div id="verifyqrcodesection" class="">
														<div className="cms-tag-body">

															<h4>New tag</h4>
															<h5>Tags are specific labels that define context within your posts.</h5>

															<div className="cms-tag-add" id="backupdivid">

																<h2>Name*</h2>
																<div>																
																	<ReactTags tags={arrtags} onDelete={onTagDelete} onAddition={onTagAddition} ref={reactTags} allowNew addOnBlur={true} placeholderText = "Enter tags" />
																</div>
																
															</div>
														</div>
														<div className="cms-tag-footer">
															<button className="cms-tag-footer-btn1" onClick={handleCloseTagsModel}>Back</button>
															<button className="cms-tag-footer-btn2" onClick={handleTagsName} 
															 style={{
																backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
															  }}
															>
																<div className={isLoader}>
																	<div className="new-loader"></div>
																</div>
																{submitval}
															</button>
														</div>
													</div>												
												</ModalBody>
											</Modal>
											</>
										)
									}
								</div>
								</div>
							</div>

							<BottomScrollListener
								onBottom={window.matchMedia("(min-width: 1200px)").matches?handleContainerOnBottom:handleNotHitOnBottom}
								offset={100}
								>
							{(scrollRef) => (
								<div
								ref={scrollRef}
						 className="category-tag-view tagpage-main-div">

								
							<div
								
								className="tags-list" style={{position : "relative"}}>
									{spinner === true ? 
										(
											<div className="newspinner">
												<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"  style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
													<g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
														<circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
															<animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
															</animateTransform>
														</circle>
													</g>
													<g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
														<circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
															<animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
															</animateTransform>
														</circle>
													</g>
													<g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
														<circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
															<animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
															</animateTransform>
														</circle>
													</g>
													<g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
														<circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
															<animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
															</animateTransform>
														</circle>
													</g>
												</svg>
											</div>
										) 
									: <> {
										tagsData && tagsData.length > 0 ? 
										<>{
												tagsData.filter((row) => row.name.toLowerCase().includes(searchVal.toLowerCase()) )
														.map((eachTag, indx) => {
															return ( 
															<div className="tagsdetail" key={ "catdet" + indx }>
																<div className="tags-names" 
																// onClick={(e) => handleEditTag(e, eachTag._id)} 
																>
																	<div>
																		<h6>{eachTag.name}</h6>
																		<p className="d-block d-xl-none">
																			{eachTag.post_count}
																		</p>
																	</div>
																</div>

																<div className="tag-count-number d-none d-xl-block">
																	<p>{eachTag.post_count} </p>
																</div>

																<div className="tagsaction d-none d-xl-flex">
																	{/* <img onClick={(e) => handleEditTag(e, eachTag._id)} className="img-fluid" src={ require("../../assets/images/dashboard/cms/edit.png").default } /> */}

																	{/* <a href=""> */}
																	<img onClick={(e) => handleTrashedPopUp(e,"add", eachTag._id)} className="img-fluid" src={ require("../../assets/images/dashboard/cms/delete.png").default } />
																	{/* </a> */}
																</div>

																<div className="d-block d-xl-none pageedit">
																	<UncontrolledDropdown className="custom-drop">
																		<DropdownToggle caret>
																			<img className="img-fluid" src={ require("../../assets/images/dashboard/cms/threedot.png").default } />
																		</DropdownToggle>
																		<DropdownMenu flip>
																			{/* <DropdownItem onClick={(e) => handleEditTag(e, eachTag._id) } >
																			Edit
																			</DropdownItem> */}

																			<DropdownItem onClick={(e) => handleTrashedPopUp(e, "add",eachTag._id) } >
																			Delete
																			</DropdownItem>
																		</DropdownMenu>
																	</UncontrolledDropdown>
																</div>
															</div>
															);
														})
													}
													{bottomSpinner && (

												<div className="form-group" style={{ background: "none" , margin:"auto" }}>
																			
												<svg xmlns="http://www.w3.org/2000/svg" 
													xlink="http://www.w3.org/1999/xlink" 
													style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
													width="200px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
													<g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
													<circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
													<animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
													style={{animationplaystate: "running", animationdelay: "0s"}}>
													</animateTransform>
													</circle>
													</g>
													<g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
													<circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
													<animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
													style={{animationplaystate: "running", animationdelay: "0s"}}>
													</animateTransform>
													</circle>
													</g>
													<g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
													<circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
													<animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
													style={{animationplaystate: "running", animationdelay: "0s"}}>

													</animateTransform>
													</circle>
													</g>
													<g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
													<circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
													<animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
													style={{animationplaystate: "running", animationdelay: "0s"}}>

													</animateTransform>
													</circle>
													</g>
												</svg>
												</div>
						                         )}

                                                {paginationSpinner && window.matchMedia("(min-width: 1200px)").matches && (
													<div className={`form-group`} style={{ background: "none", margin: "auto" }}>

													<svg xmlns="http://www.w3.org/2000/svg"
													xlink="http://www.w3.org/1999/xlink"
													style={{ margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s" }}
													width="200px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
													<g transform="translate(20 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<circle cx="0" cy="0" r="6" fill="#e6261f" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
															style={{ animationplaystate: "running", animationdelay: "0s" }}>
														</animateTransform>
														</circle>
													</g>
													<g transform="translate(40 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<circle cx="0" cy="0" r="6" fill="#f7d038" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
															style={{ animationplaystate: "running", animationdelay: "0s" }}>
														</animateTransform>
														</circle>
													</g>
													<g transform="translate(60 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<circle cx="0" cy="0" r="6" fill="#49da9a" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
															style={{ animationplaystate: "running", animationdelay: "0s" }}>

														</animateTransform>
														</circle>
													</g>
													<g transform="translate(80 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<circle cx="0" cy="0" r="6" fill="#4355db" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
															style={{ animationplaystate: "running", animationdelay: "0s" }}>

														</animateTransform>
														</circle>
													</g>
													</svg>
													</div>
												)}
													</>
											
										: 
										bottomSpinner?
										<div className="newspinner">
										<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"  style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
											<g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
												<circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
													<animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
													</animateTransform>
												</circle>
											</g>
											<g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
												<circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
													<animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
													</animateTransform>
												</circle>
											</g>
											<g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
												<circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
													<animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
													</animateTransform>
												</circle>
											</g>
											<g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
												<circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
													<animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
													</animateTransform>
												</circle>
											</g>
										</svg>
									</div>             :
											
												<div className="cat-tag ">
													
													<div className="no-cat-tag">
														<img
														src={
															require("../../assets/images/dashboard/nothing-tag.png")
															.default
														}
														/>
														<p>Nothing here at the moment. Come back later?</p>
													</div>
												</div>
											}
												</>
											
										}
								</div>
							</div>
						)}
						</BottomScrollListener>
					{
						tagsData!==null && tagsData.length > 0  &&

						<div className="category-preview-list">
							<div className="tagsetting-preview"
								style={{
									background:bgColor,
								}}
								>
									<div className="tagsetting-preview-header"
									style={{
										background:"#ffffff",
									}}
									>
										<div>
										<svg xmlns="http://www.w3.org/2000/svg" width="14.706" height="12.513" viewBox="0 0 14.706 12.513">
										<g id="Group_38211" data-name="Group 38211" transform="translate(0.707 0.354)">
											<g id="Group_2097" data-name="Group 2097" transform="translate(0)">
											<path id="Path_6037" data-name="Path 6037" d="M0,5.9,5.9,0l5.9,5.9" transform="translate(0 11.806) rotate(-90)" fill="none" stroke="#333" stroke-width="1"/>
											</g>
											<path id="Path_9120" data-name="Path 9120" d="M-13838.828-23465.541h14" transform="translate(13838.826 23471.443)" fill="none" stroke="#333" stroke-width="1"/>
										</g>
										</svg>
											<h4>Tags</h4>
										</div>
                                       {
										showSearchInHeader &&
									   
										<svg id="Group_39221" data-name="Group 39221" xmlns="http://www.w3.org/2000/svg" width="13.822" height="13.822" viewBox="0 0 13.822 13.822">
										<g id="Ellipse_246" data-name="Ellipse 246" transform="translate(12.351) rotate(90)" fill="none" stroke="#323232" stroke-width="1">
											<ellipse cx="6.175" cy="6.176" rx="6.175" ry="6.176" stroke="none"/>
											<ellipse cx="6.175" cy="6.176" rx="5.675" ry="5.676" fill="none"/>
										</g>
										<path id="Path_6064" data-name="Path 6064" d="M0,4.508V0" transform="translate(13.468 13.468) rotate(135)" fill="none" stroke="#323232" stroke-width="1"/>
										</svg>
                                       }   

									</div>
                                   
								   {
									tagLayout === "layout-1" &&
								   
									<div className="tag-type-one" 
									// style={{
									// 	background:bgColor,
									// }}
									 >
										{
											tagsData && tagsData.map((row1)=>{
										return(
											<div><p  style={{color:textColor}} >{row1.name}</p></div>
										)
											})
										}


									</div>
                                   }

                                  {
									tagLayout === "layout-2" &&

									<div className="tag-type-two" 
									// style={{
									// 	background:bgColor,
									// }}
									>
										{
											tagsData && tagsData.map((row1)=>{
										return(
											<div
											
											style={{
												background:textBgColor,
											}}>
												<p 
												 style={{
												  color:textColor,
												 }}
												>{row1.name}
												</p>
											</div>
										)
											})
										}
								
									</div> }
								</div>
					{/* <div className="category-preview-list-box">
						<div className="page-listing-head">

							<h3>
							<svg className="mr-3" xmlns="http://www.w3.org/2000/svg" width="14.706" height="12.513" viewBox="0 0 14.706 12.513">
							<g id="Group_38211" data-name="Group 38211" transform="translate(0.707 0.354)">
							<g id="Group_2097" data-name="Group 2097" transform="translate(0)">
								<path id="Path_6037" data-name="Path 6037" d="M0,5.9,5.9,0l5.9,5.9" transform="translate(0 11.806) rotate(-90)" fill="none" stroke="#333" stroke-width="1"/>
							</g>
							<path id="Path_9120" data-name="Path 9120" d="M-13838.828-23465.541h14" transform="translate(13838.826 23471.443)" fill="none" stroke="#333" stroke-width="1"/>
							</g>
							</svg>

							<img
							src={
							require("../../assets/images/dashboard/cms/hamburger.png")
								.default
							}
						/> Tags</h3>
						</div>

					<div className="category-list-preview">

						{tagsData!==null && tagsData.length > 0  &&
							tagsData.filter((row) => row.name.toLowerCase().includes(searchVal.toLowerCase()) )
							.map((eachTag, indx) => {
								return ( 
                        <div>
						<p>{eachTag.name}</p>
						<svg xmlns="http://www.w3.org/2000/svg" width="11.566" height="10.367" viewBox="0 0 11.566 10.367">
						<g id="Group_38212" data-name="Group 38212" transform="translate(0 0.354)">
							<path id="Path_8624" data-name="Path 8624" d="M0,0,4.83,4.83,9.66,0" transform="translate(6.029 9.66) rotate(-90)" fill="rgba(0,0,0,0)" stroke="#a1a1a1" stroke-width="1"/>
							<path id="Path_9125" data-name="Path 9125" d="M-11679.109-23265.5h-10.859" transform="translate(11689.969 23270.33)" fill="none" stroke="#a1a1a1" stroke-width="1"/>
						</g>
						</svg>
						</div>
								)
						})}
					

						

						

					</div>

					</div> */}
						</div>
}
					</div>
          		</div>

				  <div className="category-mobile-view">
				  {spinner === true ? 
										(
											<div className="newspinner">
												<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"  style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
													<g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
														<circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
															<animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
															</animateTransform>
														</circle>
													</g>
													<g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
														<circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
															<animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
															</animateTransform>
														</circle>
													</g>
													<g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
														<circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
															<animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
															</animateTransform>
														</circle>
													</g>
													<g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
														<circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
															<animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
															</animateTransform>
														</circle>
													</g>
												</svg>
											</div>
										) 
									:
					
						tagsData!==null && tagsData.length > 0 
					?
					<>
					{
					tagsData.filter((row) => row.name.toLowerCase().includes(searchVal.toLowerCase()) )
							.map((eachTag, indx) => {
								return ( 
							<div className="category-entry-mob">
							<div className="category-entry-one">

								<div className="tags-info-mob">
								<h2>Tag</h2>
								<h5>{eachTag.name}</h5>
								</div>

								</div>
								<div className="category-entry-two tags-edit-mob">
									<div>
									<h3>Posts</h3>
									<h6>{eachTag.post_count}</h6>
									</div>

									<div>
										<img onClick={(e) => handleTrashedPopUp(e,"add", eachTag._id)} className="img-fluid" src={ require("../../assets/images/dashboard/cms/delete.png") .default} />
									</div>
								</div>
							</div>
								)
							}) 
						}
							{bottomSpinner && (

								<div className="form-group" style={{ background: "none" , margin:"auto" }}>
															
								<svg xmlns="http://www.w3.org/2000/svg" 
									xlink="http://www.w3.org/1999/xlink" 
									style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
									width="200px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
									<g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
									<circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
									<animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
									style={{animationplaystate: "running", animationdelay: "0s"}}>
									</animateTransform>
									</circle>
									</g>
									<g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
									<circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
									<animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
									style={{animationplaystate: "running", animationdelay: "0s"}}>
									</animateTransform>
									</circle>
									</g>
									<g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
									<circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
									<animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
									style={{animationplaystate: "running", animationdelay: "0s"}}>
							
									</animateTransform>
									</circle>
									</g>
									<g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
									<circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
									<animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
									style={{animationplaystate: "running", animationdelay: "0s"}}>
							
									</animateTransform>
									</circle>
									</g>
								</svg>
								</div>
													)}
							{paginationSpinner && window.matchMedia("(max-width: 1200px)").matches && (
								<div className={`form-group`} style={{ background: "none", margin: "auto" }}>

								<svg xmlns="http://www.w3.org/2000/svg"
								xlink="http://www.w3.org/1999/xlink"
								style={{ margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s" }}
								width="200px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
								<g transform="translate(20 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
									<circle cx="0" cy="0" r="6" fill="#e6261f" style={{ animationplaystate: "running", animationdelay: "0s" }}>
									<animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
										style={{ animationplaystate: "running", animationdelay: "0s" }}>
									</animateTransform>
									</circle>
								</g>
								<g transform="translate(40 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
									<circle cx="0" cy="0" r="6" fill="#f7d038" style={{ animationplaystate: "running", animationdelay: "0s" }}>
									<animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
										style={{ animationplaystate: "running", animationdelay: "0s" }}>
									</animateTransform>
									</circle>
								</g>
								<g transform="translate(60 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
									<circle cx="0" cy="0" r="6" fill="#49da9a" style={{ animationplaystate: "running", animationdelay: "0s" }}>
									<animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
										style={{ animationplaystate: "running", animationdelay: "0s" }}>

									</animateTransform>
									</circle>
								</g>
								<g transform="translate(80 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
									<circle cx="0" cy="0" r="6" fill="#4355db" style={{ animationplaystate: "running", animationdelay: "0s" }}>
									<animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
										style={{ animationplaystate: "running", animationdelay: "0s" }}>

									</animateTransform>
									</circle>
								</g>
								</svg>
								</div>
							)}

													</>
					:
					bottomSpinner?
					<div className="newspinner">
					<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"  style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
						<g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
							<circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
								<animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
								</animateTransform>
							</circle>
						</g>
						<g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
							<circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
								<animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
								</animateTransform>
							</circle>
						</g>
						<g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
							<circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
								<animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
								</animateTransform>
							</circle>
						</g>
						<g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
							<circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
								<animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
								</animateTransform>
							</circle>
						</g>
					</svg>
				</div>             :
						
							<div className="cat-tag ">
								
								<div className="no-cat-tag">
									<img
									src={
										require("../../assets/images/dashboard/nothing-tag.png")
										.default
									}
									/>
									<p>Nothing here at the moment. Come back later?</p>
								</div>
							</div>
                    }

				</div>
				</>
				:
				activeLayoutTab === true ?

				<div className="tags-layout-preview">
					<div className="taglayoutbg">
						<div className="tag-layout-header">
							<h1>Layout</h1>
							<p>Choose a layout to style the appearance of tags on your mobile app.</p>
						</div>
					<FormGroup tag="fieldset" className="select-tag-type">
					<FormGroup>
					<Label className="tagtype-ratio">
					   <Input
						type="radio"
						name="addblockradio1"
						checked={tagLayout==="layout-1"}
						onChange={(e)=>handleChangeLayout(e,"layout-1")}
						/>

						<div className="tag-layout">
						<img className="img-fluid" src={require("../../assets/images/dashboard/tag-layout-one.png") .default}/>
						</div>
					</Label>
					</FormGroup>
					<FormGroup>
					<Label className="tagtype-ratio">
					<Input
					type="radio"
					name="addblockradio2"
					checked={tagLayout==="layout-2"}
					onChange={(e)=>handleChangeLayout(e,"layout-2")}
					
					/>
						<div className="tag-layout">
						<img className="img-fluid" src={require("../../assets/images/dashboard/tag-layout-two.png") .default}/>
						</div>
					</Label>
					</FormGroup>
					
					
					
					</FormGroup>
					</div>
					{
					window.matchMedia("(max-width: 1200px)").matches &&
					<div className="post-list-setting-btn-mobile">
				  <button
				  onClick={(e) => handleSubmitTagSettingsData(e)}
				  style={{backgroundColor:localStorage.getItem('button_background_color'),
				  border:'1px solid '+localStorage.getItem('button_background_color'),
				  color:localStorage.getItem('button_text_color'),
				  "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
				  "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
				  "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
					
				>
				  <div className={settingSaveBtnLoader}>
				  <div className="d-flex justify-content-center align-items-center ">

<div className="new-loader"></div>
</div>
				  </div>
				  <div>{ settingSaveBtnVal}</div>
				   </button>
				   </div>
                   }
				    {settingUpdatedAt &&
              <p style={{
                display:window.matchMedia("(max-width: 1200px)").matches?"block":"none",
                textAlign:"center",
                color:"#bcc1ce",
                fontSize:"12px"
              }}>Updated  {moment(settingUpdatedAt).format("D MMMM YYYY, HH:mm")}{" "}</p>
            }
				</div>
					
				:
				
				<div className="tags-layout-setting">
					<div className="tagsettingbg">
						<div className="tagsetting-width">
							<div className="tagsetting-left">
								<div className="tagsetting-left-header">
									<h1>Settings</h1>
									<p>Customize the appearance of tags in your posts.</p>
								</div>

								<div className="tag-arrow-toggle">
									<h4>Show search icon<h5 className="tagsetting-tooltip">
										<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg>
										<h5 className="tagsetting-tooltipsubheader">Enable to display a search icon in header for quicker access to required content.</h5></h5></h4>
									<label class="switch" for="show_search">
										<input type="checkbox" id="show_search" name="show_search" 
										checked={showSearchInHeader} 
										onChange={(e)=>handleShowSearchToggle(e)}/>
										<div className="slider round"></div>
									</label>
								</div>

								<div className="row">
									<div className="col-md-6">
										<h6>Background color<h5 className="tagsetting-tooltip">
										<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg>
										<h5 className="tagsetting-tooltipsubheader">Set a background color for the page.</h5></h5></h6>
										<Reactcolorpicker
                                        colorname={
                                          bgColor
                                        }
                                       
                                        onChange={(e) => handleBgColor(e)}
                                        classselection={"fixback"}
                                        valuecontrol={false}
                                      />
									</div>
									<div className="col-md-6">
										<h6>Tag text color<h5 className="tagsetting-tooltip">
										<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg>
										<h5 className="tagsetting-tooltipsubheader">Define the color of the text within the tag labels.</h5></h5></h6>
										<Reactcolorpicker
                                        colorname={
                                          textColor
                                        }
                                        onChange={(e)=>
                                          handleTextColor(e)
                                        }
                                        classselection={"fixback"}
                                        valuecontrol={true}
                                      />
									</div>
								</div>
								<div className="row">
								{
									tagLayout === "layout-2" &&
									<div className="col-md-6">
										<h6>Tag background color<h5 className="tagsetting-tooltip">
										<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg>
										<h5 className="tagsetting-tooltipsubheader">Set the background color of the tag labels.</h5></h5></h6>
										<Reactcolorpicker
                                        colorname={
                                         textBgColor
                                        }
                                       
                                        onChange={(e) => handleTextBgColor(e)}
                                        classselection={"fixback"}
                                        valuecontrol={false}
                                      />
									</div>
                                  }
								</div>

							</div>
							<div className="tagsetting-right">
								<div className="tagsetting-preview"
								style={{
									background:bgColor,
								}}
								>
									<div className="tagsetting-preview-header"
									style={{
										background:"#ffffff",
									}}
									>
										<div>
										<svg xmlns="http://www.w3.org/2000/svg" width="14.706" height="12.513" viewBox="0 0 14.706 12.513">
										<g id="Group_38211" data-name="Group 38211" transform="translate(0.707 0.354)">
											<g id="Group_2097" data-name="Group 2097" transform="translate(0)">
											<path id="Path_6037" data-name="Path 6037" d="M0,5.9,5.9,0l5.9,5.9" transform="translate(0 11.806) rotate(-90)" fill="none" stroke="#333" stroke-width="1"/>
											</g>
											<path id="Path_9120" data-name="Path 9120" d="M-13838.828-23465.541h14" transform="translate(13838.826 23471.443)" fill="none" stroke="#333" stroke-width="1"/>
										</g>
										</svg>
											<h4>Tags</h4>
										</div>
                                       {
										showSearchInHeader &&
									   
										<svg id="Group_39221" data-name="Group 39221" xmlns="http://www.w3.org/2000/svg" width="13.822" height="13.822" viewBox="0 0 13.822 13.822">
										<g id="Ellipse_246" data-name="Ellipse 246" transform="translate(12.351) rotate(90)" fill="none" stroke="#323232" stroke-width="1">
											<ellipse cx="6.175" cy="6.176" rx="6.175" ry="6.176" stroke="none"/>
											<ellipse cx="6.175" cy="6.176" rx="5.675" ry="5.676" fill="none"/>
										</g>
										<path id="Path_6064" data-name="Path 6064" d="M0,4.508V0" transform="translate(13.468 13.468) rotate(135)" fill="none" stroke="#323232" stroke-width="1"/>
										</svg>
                                       }   

									</div>
                                   
								   {
									tagLayout === "layout-1" &&
								   
									<div className="tag-type-one" 
									// style={{
									// 	background:bgColor,
									// }}
									 >
										{
											tagsData && tagsData.map((row1)=>{
										return(
											<div><p  style={{color:textColor}} >{row1.name}</p></div>
										)
											})
										}


									</div>
                                   }

                                  {
									tagLayout === "layout-2" &&

									<div className="tag-type-two" 
									// style={{
									// 	background:bgColor,
									// }}
									>
										{
											tagsData && tagsData.map((row1)=>{
										return(
											<div 
											style={{
												background:textBgColor,
											}}>
												
												<p 
												 style={{
												  color:textColor,
												 }}
												>{row1.name}
												</p>
											</div>
										)
											})
										}
								
									</div> }
								</div>
							</div>
						</div>
					</div>

					{
					window.matchMedia("(max-width: 1200px)").matches &&
					<div className="post-list-setting-btn-mobile">
				  <button
		
				  onClick={(e) => handleSubmitTagSettingsData(e)}
				  style={{backgroundColor:localStorage.getItem('button_background_color'),
				  border:'1px solid '+localStorage.getItem('button_background_color'),
				  color:localStorage.getItem('button_text_color'),
				  width:"100%",
				  "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
				  "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
				  "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
					
				>
				  <div className={settingSaveBtnLoader}>
					<div className="d-flex justify-content-center align-items-center ">

					<div className="new-loader"></div>
					</div>
				  </div>
				  <div>{ settingSaveBtnVal}</div>
				   </button>
				   </div>
                   }
				    {settingUpdatedAt &&
              <p style={{
                display:window.matchMedia("(max-width: 1200px)").matches?"block":"none",
                textAlign:"center",
                color:"#bcc1ce",
                fontSize:"12px"
              }}>Updated  {moment(settingUpdatedAt).format("D MMMM YYYY, HH:mm")}{" "}</p>
            }
				</div>
				}
				  

				

        	</div>)}
			</BottomScrollListener>


			<Modal
          isOpen={isTrashed}
          centered
          className="delete-popup"
          toggle={(e) => handleTrashedPopUp(e, "cancel")}
          // className={this.props.className}
          dataclassName="addonpopup"
        >
          <ModalBody className="">
            <div>
              <h5>Delete this tag?</h5>
              <h6>This action will permanently delete your tag.</h6>

              <div className="d-flex align-items-center justify-content-between cms-delete-popup-footer">

                <div className="d-none d-xl-block grey-btn" onClick={(e) => handleTrashedPopUp(e, "cancel")}>
                  <p>Cancel</p>
                </div>

                <div onClick={(e) => handleTrashedTag(e)}>
                  <div className="d-flex align-items-center justify-content-center h-100 w-100 red-btn">
                    <p>
                      <div className={isLoader}>
                        <img src={loaderimage} alt="loader" />
                      </div>
                      <div>Delete</div>
                    </p>
                  </div>
                </div>

				<div className="d-block d-xl-none grey-btn" onClick={(e) => handleTrashedPopUp(e, "cancel")}>
                  <p>Cancel</p>
                </div>

              </div>
            </div>
          </ModalBody>
        </Modal>

      	</section>
    </>
  );
};
export default Tagpage;
