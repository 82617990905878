import React from 'react'
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import backIc from "../../assets/images/dashboard/backIc.svg";
const FilterProductModal = ({productState, handleStateChange}) => {
  return (
    <Modal
          className="protip-popup-message"
          isOpen={
            window.matchMedia("(max-width: 1200px)").matches
              ? productState.openMobFilterPopUp
              : false
          }
          centered
        >
          <ModalBody>
            <div className="mobileglobalsearch">
              <button onClick={() => handleStateChange("toggleFilterPopUp")}>
              <img src={backIc} alt="back" />
              </button>
            </div>

            <div className="mobilesearchsuggestion">
              {productState.optionsForFilter &&
                productState.optionsForFilter.map((option) => {
                  return (
                    <p
                      className="mobilesearchsuggestion-para"
                      onClick={() => handleStateChange("updateFilter", option)}
                    >
                      {option.label}
                    </p>
                  );
                })}
            </div>
          </ModalBody>
        </Modal>
  )
}

export default FilterProductModal