import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SingleSelectionPopUpForAdBlock } from "./CommonSelectionPopUp";
const CustomAdBlock = ({
  indx,
  cmsArray,
  setcmsData,
  isRowOpen,
  handleIsRowOpen,
  deleteRow,
  duplicateItem,
  eachBlock,
  adsData,
  setAdsData,
  provided,
}) => {

  const [openSingleSelectionPopUp, setOpenSingleSelectionPopUp] = useState(false);
  const [searchValForPopUp, setSearchValForPopUp] = useState("");
 
  const handleOpenSelectionPopUp = (e,from) => {
    setOpenSingleSelectionPopUp(!openSingleSelectionPopUp);

    let cmsData = [...cmsArray];

    if(from === "cancel"){
      cmsData[indx].value.temp_ad_id = cmsData[indx].value.ad_id;
      cmsData[indx].value.temp_ad_name = cmsData[indx].value.ad_name;
    }else{
      if(from==="open")
      {
        cmsData[indx].value.ad_id = cmsData[indx].value.temp_ad_id;
        cmsData[indx].value.ad_name = cmsData[indx].value.temp_ad_name;
      }

  }
  delete cmsData[indx].value.temp_ad_id;
  delete cmsData[indx].value.temp_ad_name
  setcmsData(cmsData);
}

  const handleAdChange = (e,id,name) => {
    let cmsData = [...cmsArray];
    cmsData[indx].value.temp_ad_id = id;
    cmsData[indx].value.temp_ad_name = name;
    setcmsData(cmsData);
  }
 

  const handleStatus = (e) =>{

    let cmsData = [...cmsArray];
    cmsData[indx].status = ( cmsData[indx].status===1 || cmsData[indx].status===undefined || cmsData[indx].status===null)
    ?0:1;
  
    // setbgColor(e.hex);
    setcmsData(cmsData);
  }

  return (
    <div className={`accordionItem ${cmsArray[indx].status===0?"cms-section-hidden":""}`}>
      <h2
        draggableId={indx.toString()}
        class="accordionItemHeading allflex"
        {...provided.dragHandleProps}
      >
        Custom ad
        {/* <h6 class="cmspostsetting-tooltip">
              <svg style={{ cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg>
              <h6 class="cmspostsetting-tooltipsubheader"> Select a custom ad.</h6>
            </h6> */}

        <span className="cms-section-visiblity" onClick={(e)=>handleStatus(e)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="25.284" height="20.465" viewBox="0 0 25.284 20.465">
        <g id="Group_39189" data-name="Group 39189" transform="translate(0.568 0.353)">
          <path id="Path_103079" data-name="Path 103079" d="M68.716,9.742a13.852,13.852,0,0,1-12.07,7.684A13.876,13.876,0,0,1,44.568,9.742,13.876,13.876,0,0,1,56.646,2.058,13.852,13.852,0,0,1,68.716,9.742Z" transform="translate(-44.568 0.138)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1"/>
          <ellipse id="Ellipse_8629" data-name="Ellipse 8629" cx="3.842" cy="3.842" rx="3.842" ry="3.842" transform="translate(8.233 6.038)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1"/>
          <line id="Line_430" data-name="Line 430" x2="19.758" y2="19.758" transform="translate(2.196 0.001)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1"/>
        </g>
      </svg>
    </span>

    
        <p className="cms-accordian-p"
          onClick={(e) => {
            handleIsRowOpen(e, indx);
          }}
        >
          <img
            src={
              isRowOpen[indx] === true
                ? require("../../assets/images/dashboard/cms/accordian-open.png")
                    .default
                : require("../../assets/images/dashboard/cms/accordian-icon.png")
                    .default
            }
          />
        </p>
      </h2>

      {isRowOpen[indx] === true && (
        <div class="accordionItemContent">

          {/* <h6>Use this section to place your attributes. Go to “Settings” to add or remove attributes from this post.</h6> */}
          <label className="advertismentblock-type">Select ad
            <h6 class="cmspostsetting-tooltip">
              <svg style={{ cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg>
              <h6 class="cmspostsetting-tooltipsubheader">  Insert a custom ad seamlessly into your content.</h6>
            </h6>

          </label>
          <div className="advertismentblock-input" onClick={(e)=>handleOpenSelectionPopUp(e)}>
            <p>{cmsArray[indx].value.ad_name}</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19">
              <g id="Group_42667" data-name="Group 42667" transform="translate(-1005 -1029)">
                <g id="Rectangle_31915" data-name="Rectangle 31915" transform="translate(1005 1029)" fill="#fff" stroke="#bcc1ce" stroke-width="1">
                  <rect width="20" height="19" rx="2" stroke="none"/>
                  <rect x="0.5" y="0.5" width="19" height="18" rx="1.5" fill="none"/>
                </g>
                <path id="Path_119031" data-name="Path 119031" d="M15578.713-3593.554h2" transform="translate(-14569.713 4628.054)" fill="none" stroke="#bcc1ce" stroke-width="1"/>
                <path id="Path_119032" data-name="Path 119032" d="M15578.713-3593.554h2" transform="translate(-14569.713 4632.054)" fill="none" stroke="#bcc1ce" stroke-width="1"/>
                <path id="Path_119033" data-name="Path 119033" d="M15578.713-3593.554h2" transform="translate(-14569.713 4636.054)" fill="none" stroke="#bcc1ce" stroke-width="1"/>
                <path id="Path_119034" data-name="Path 119034" d="M15578.711-3593.554h8" transform="translate(-14565.713 4628.054)" fill="none" stroke="#bcc1ce" stroke-width="1"/>
                <path id="Path_119035" data-name="Path 119035" d="M15578.711-3593.554h8" transform="translate(-14565.713 4632.054)" fill="none" stroke="#bcc1ce" stroke-width="1"/>
                <path id="Path_119036" data-name="Path 119036" d="M15578.711-3593.554h8" transform="translate(-14565.713 4636.054)" fill="none" stroke="#bcc1ce" stroke-width="1"/>
              </g>
            </svg>
          </div>
         
          <div className="duplicate-delete">
            <div className="cms-dup-del-btn" onClick={(e) => duplicateItem(e, eachBlock)}>
            <span class="cms-delete-tooltip">
            <img
                src={
                  require("../../assets/images/dashboard/cms/duplicate.png")
                    .default
                }
              />
               <span class="cms-delete-tooltipsubheader">Duplicate</span>
                  </span>
            </div>
            <div
            className="cms-dup-del-btn"
              onClick={(e) => {
                deleteRow(e, indx);
              }}
            >
              <span class="cms-delete-tooltip">
            <img
                src={
                  require("../../assets/images/dashboard/cms/delete.png")
                    .default
                }
              />
               <span class="cms-delete-tooltipsubheader">Delete</span>
                  </span>
            </div>
          </div>
        </div>
      )}

     <SingleSelectionPopUpForAdBlock
        openPopUp={openSingleSelectionPopUp}
        handlePopUp={handleOpenSelectionPopUp}
        handleInputClick={handleAdChange}
        selectedId={(cmsArray[indx].value.temp_ad_id !== undefined && cmsArray[indx].value.temp_ad_id !== null ) ?
        cmsArray[indx].value.temp_ad_id
        : cmsArray[indx].value.ad_id}
        subData={adsData}
        searchVal={searchValForPopUp}
        setSearchVal={setSearchValForPopUp}
        from={"custom"}
    />
    </div>
  );
};

export default CustomAdBlock;
