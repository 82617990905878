import React from "react";
import HomeIcon from "../../commonIcons/HomeIcon";
import SettingIcon from "../../commonIcons/SettingIcon";
import { useParams } from "react-router-dom";
import { APP_PLANS } from "../../../Constants/notificationConsts";
import ModuleTitleTooltip from "../../commonIcons/ModuleTitleTooltip";

const NotificationCreateHeader = ({
  notificationData,
  setNotificationData,
  handleSaveDraft,
  toggleConfirmationModal,
}) => {
  const { nfID } = useParams(); // Access the route parameter

  const isValidId = (id) => id && id !== "undefined" && id !== "null";

  const notificationType = isValidId(nfID)
    ? "Edit notification"
    : "New notification";

  const draftStyle = {
    border: "1px solid " + localStorage.getItem("button_background_color"),
    color: localStorage.getItem("button_text_color"),
    "--custom_color5":
      +localStorage.getItem("agencyid") === 1
        ? "#134bec"
        : localStorage.getItem("button_hover_bgcolor"),
    "--custom_color4":
      +localStorage.getItem("agencyid") === 1
        ? "white"
        : localStorage.getItem("button_hover_txtcolor"),
    "--custom_color6":
      +localStorage.getItem("agencyid") === 1
        ? "#3064f9"
        : localStorage.getItem("button_hover_bgcolor"),
  };

  const disabledDraftStyle = {
    border: "",
    color: "",
    "--custom_color4": +localStorage.getItem("agencyid") === 1 ? "white" : "",
    "--custom_color6": +localStorage.getItem("agencyid") === 1 ? "#3064f9" : "",
  };

  const sendStyle = {
    backgroundColor: localStorage.getItem("button_background_color"),
    border: "1px solid " + localStorage.getItem("button_background_color"),
    color: localStorage.getItem("button_text_color"),
    "--custom_color5":
      +localStorage.getItem("agencyid") === 1
        ? "#134bec"
        : localStorage.getItem("button_hover_bgcolor"),
    "--custom_color4":
      +localStorage.getItem("agencyid") === 1
        ? "white"
        : localStorage.getItem("button_hover_txtcolor"),
    "--custom_color6":
      +localStorage.getItem("agencyid") === 1
        ? "#3064f9"
        : localStorage.getItem("button_hover_bgcolor"),
  };

  const settingsToggle = () =>
    setNotificationData({
      isSettingsOpen: !notificationData.isSettingsOpen,
    });

  return (
    <div className="align-items-center justify-content-between newtop-portion">
      <div className="newapp_iconname nwcreate-notification-head">
        <ModuleTitleTooltip title={notificationType} />
      </div>
      <div className="d-flex align-items-center justify-content-between notify-buttons plain-notify-buttons">
        {notificationData.isSettingsOpen ? (
          <div
            className="common-navigation-btn"
            onClick={() => settingsToggle()}
          >
            <HomeIcon />
          </div>
        ) : null}

        <div onClick={() => settingsToggle()}>
          <SettingIcon
            
            isActive={notificationData.isSettingsOpen}
          />
        </div>
        {notificationData.appPlan !== APP_PLANS.preview ? (
          <div className=" webnewdraft_fotter">
            {(!notificationData.title.trim() &&
              !notificationData.message.trim()) ||
            (!notificationData.settings.sendToAndroid &&
              !notificationData.settings.sendToIos) ? (
              <button
                className="savedraft-buttondisabled"
                style={disabledDraftStyle}
              >
                <div className=""> Save draft </div>
              </button>
            ) : (
              <button className="pushnotification-savedraft" style={draftStyle}>
                {notificationData.draftSpinner ? (
                  <div className="w-100">
                    <div className="new-blue-loader"></div>
                  </div>
                ) : (
                  <div
                    onClick={() =>
                      notificationData.draftSpinner ? null : handleSaveDraft()
                    }
                  >
                    Save draft
                  </div>
                )}
              </button>
            )}

            {!notificationData.settings.sendToAndroid &&
            !notificationData.settings.sendToIos ? (
              <button className="send-buttondisabled"> Send </button>
            ) : (
              <button
                onClick={() => toggleConfirmationModal()}
                className={
                  notificationData.title.trim() &&
                  notificationData.message.trim()
                    ? "send-notificationsend"
                    : "send-buttondisabled"
                }
                disabled={
                  !notificationData.title.trim() &&
                  !notificationData.message.trim()
                }
                style={sendStyle}
              >
                Send
              </button>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default NotificationCreateHeader;
