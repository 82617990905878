import React from "react";
import "../assets/css/Signup.css";
import "../assets/css/Media.css";
import {
  Appsumosignupapi,
  Countrycode,
  SignupApi,
} from "../Api/Authenticate/Api";
import { Versionapi } from "../Api/Dashboard/DashboardApi";
import { Redirect } from "react-router-dom";
import { Spinner, Media } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { errortoast } from "../Dashboard/Toaster";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

export default class Woocommercesignup extends React.Component {
  constructor(props) {
    super(props);
    this.helpToggle = this.helpToggle.bind(this);
    this.state = {
      helpDropdownOpen: false,
      modalopen: false,
       progress:0,
      count:0,
    };
  }
  componentDidMount() {
    Versionapi();
    document.title = "Register";
  }
  helpToggle() {
    this.setState((prevState) => ({
      helpDropdownOpen: !prevState.helpDropdownOpen,
    }));
  }
   

  render() {
    const token = localStorage.getItem("amstoken");
    if (!token || token === "null" || token === "undefined") {
    } else {
      return <Redirect to="/apps" />;
    }
    return (
      <>
        <header className="integrationheader">
          <div className="container signhead">
            <div className="logo stackcommerce">
              <img
                src={
                  require("../assets/images/dashboard/woocommerce-logo.png")
                    .default
                }
                alt="AMS"
                className="img-fluid"
              />
            </div>
            <div className="d-flex align-items-center">
              <div className="back-home">
                <Media
                  tag="a"
                  href={process.env.REACT_APP_WEBSITE_URL}
                  className=""
                >
                  <img
                    src={
                      require("../assets/images/signup/website-icon.png")
                        .default
                    }
                    alt="contact"
                    className="d-block d-md-none"
                  />{" "}
                  <span className="d-none d-md-block">Website</span>
                </Media>
                <Media
                  tag="a"
                  href="/sign-in"
                  className="d-none d-md-block ipad-signup-spacing"
                >
                  Sign in
                </Media>
              </div>
             
               <div className="login-help login-help-woo">
               <Dropdown
                  isOpen={this.state.helpDropdownOpen}
                  toggle={this.helpToggle}
                >
                  <DropdownToggle className="d-flex align-items-center  justify-content-between">
                    <img
                      className="d-block d-md-none"
                      src={
                        require("../assets/images/dashboard/help-light.png")
                          .default
                      }
                      width="24px"
                      height="24px"
                      alt="cross"
                    />
                    <p className="d-none d-md-block">Help</p>
                  </DropdownToggle>
                   <DropdownMenu className="helpheaderdropdown">
                    <div className="support-tab">
                      <p>Support</p>
                    </div>
                    <DropdownItem>
                      <a
                        href="https://www.appmysite.com/support/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="d-flex justify-content-start align-items-center">
                          <svg
                            width="19.229"
                            height="19.363"
                            viewBox="0 0 19.229 19.363"
                            className="mr-3"
                          >
                            <g
                              id="Group_3391"
                              data-name="Group 3391"
                              transform="translate(-435.997 -836.966)"
                            >
                              <g id="Group_3390" data-name="Group 3390">
                                <g id="Group_3389" data-name="Group 3389">
                                  <path
                                    id="Path_6899"
                                    data-name="Path 6899"
                                    d="M438.538,837.716a1.791,1.791,0,0,0-1.791,1.791v10.205a1.791,1.791,0,0,0,1.791,1.791h1.537v3.213l3.812-3.213h8.8a1.791,1.791,0,0,0,1.791-1.791V839.507a1.791,1.791,0,0,0-1.791-1.791Z"
                                    transform="translate(0 0)"
                                    fill="none"
                                    stroke="#7782a1"
                                    strokeMiterlimit="10"
                                    strokeWidth="1.5"
                                  />
                                </g>
                              </g>
                              <g
                                id="Group_3258"
                                data-name="Group 3258"
                                transform="translate(443.313 840.973)"
                              >
                                <path
                                  id="Path_6900"
                                  data-name="Path 6900"
                                  d="M491.39,867.121a2.3,2.3,0,1,1,3.036,2.177,1.105,1.105,0,0,0-.738,1.051v.56"
                                  transform="translate(-491.39 -864.823)"
                                  fill="none"
                                  stroke="#7782a1"
                                  strokeMiterlimit="10"
                                  strokeWidth="1.5"
                                />
                                <line
                                  id="Line_114"
                                  data-name="Line 114"
                                  y2="1"
                                  transform="translate(2.289 6.983)"
                                  fill="none"
                                  stroke="#7782a1"
                                  strokeMiterlimit="10"
                                  strokeWidth="1.5"
                                />
                              </g>
                            </g>
                          </svg>
                          Help Center
                          <img
                        src={
                          require("../assets/images/dashboard/Goto.png").default
                        }
                        className="dashboard-gotospace"
                        alt="Goto"
                      />
                        </div>
                      </a>
                    </DropdownItem>

                    
                    <DropdownItem>
                      <a
                        href="https://app.appmysite.com/get-in-touch/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="d-flex justify-content-start align-items-center">
                         <svg width="18.451" height="14.581" viewBox="0 0 18.451 14.581" class="mr-3"><g id="Group_3263" data-name="Group 3263" transform="translate(-1599.25 -183.55)"><g id="Group_3392" data-name="Group 3392"><g id="Group_3391" data-name="Group 3391" transform="translate(-1 -3)"><g id="Group_3260" data-name="Group 3260" transform="translate(1395.75 -652.82)"><path id="Path_6901" data-name="Path 6901" d="M222.2,845.152v9.973a1.554,1.554,0,0,1-1.555,1.555H206.8a1.555,1.555,0,0,1-1.555-1.555v-9.973a1.544,1.544,0,0,1,.455-1.1l7.481,7.481a.668.668,0,0,0,.941,0l7.615-7.488A1.546,1.546,0,0,1,222.2,845.152Z" transform="translate(0 -3.478)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"></path><path id="Path_6902" data-name="Path 6902" d="M225.248,840.572l-.428.421-7.187,7.068a.669.669,0,0,1-.941,0l-7.481-7.481a1.549,1.549,0,0,1,1.1-.455h13.843A1.55,1.55,0,0,1,225.248,840.572Z" transform="translate(-3.505)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"></path></g></g></g></g></svg>
                          Contact Us
                        </div>
                      </a>
                    </DropdownItem>

                    <DropdownItem>
                      <a
                        href="https://updates.appmysite.com/en/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="d-flex justify-content-start align-items-center">
                          <svg
                            width="15.63"
                            height="20.148"
                            viewBox="0 0 15.63 20.148"
                            className="mr-3"
                          >
                            <g
                              id="Group_3395"
                              data-name="Group 3395"
                              transform="translate(-1602.25 -235.044)"
                            >
                              <g id="Group_3393" data-name="Group 3393">
                                <g id="Group_3394" data-name="Group 3394">
                                  <g id="Group_3392" data-name="Group 3392">
                                    <g
                                      id="Group_3261"
                                      data-name="Group 3261"
                                      transform="translate(1501.73 -580.365)"
                                    >
                                      <path
                                        id="Path_6903"
                                        data-name="Path 6903"
                                        d="M136.063,822.932a7.056,7.056,0,0,1-2.831,5.953,3.764,3.764,0,0,0-1.516,3.012h-5.422a3.8,3.8,0,0,0-1.537-3.027,7.065,7.065,0,1,1,11.306-5.937Z"
                                        transform="translate(-20.67 0)"
                                        fill="none"
                                        stroke="#7782a1"
                                        strokeMiterlimit="10"
                                        strokeWidth="1.5"
                                      />
                                      <path
                                        id="Path_6904"
                                        data-name="Path 6904"
                                        d="M160.622,936.41v2.31a.6.6,0,0,1-.6.6H155.8a.6.6,0,0,1-.6-.6v-2.31Z"
                                        transform="translate(-49.577 -104.512)"
                                        fill="none"
                                        stroke="#7782a1"
                                        strokeMiterlimit="10"
                                        strokeWidth="1.5"
                                      />
                                      <line
                                        id="Line_115"
                                        data-name="Line 115"
                                        y1="6.168"
                                        transform="translate(108.335 825.73)"
                                        fill="none"
                                        stroke="#7782a1"
                                        strokeMiterlimit="10"
                                        strokeWidth="1.5"
                                      />
                                      <path
                                        id="Path_6905"
                                        data-name="Path 6905"
                                        d="M164.009,873.157l-2.111,2.111-2.111-2.111"
                                        transform="translate(-53.563 -49.538)"
                                        fill="none"
                                        stroke="#7782a1"
                                        strokeMiterlimit="10"
                                        strokeWidth="1.5"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                          What’s New
                          <img
                        src={
                          require("../assets/images/dashboard/Goto.png").default
                        }
                        className="dashboard-gotospace"
                        alt="Goto"
                      />
                        </div>
                      </a>
                    </DropdownItem>

                   {/* <DropdownItem>
                      <a
                        href="https://www.g2.com/products/appmysite/reviews/start"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="d-flex justify-content-start align-items-center">
                          <img
                            src={
                              require("../assets/images/addons/review.png")
                                .default
                            }
                            alt="review"
                            className="mr-3"
                          />
                          Leave a Review
                        </div>
                      </a>
                    </DropdownItem> */}
                    <DropdownItem>
                      <a
                        href="https://updates.appmysite.com/roadmap/en/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="d-flex justify-content-start align-items-center">
                          <img
                            src={
                              require("../assets/images/dashboard/roadmap.png")
                                .default
                            }
                            alt="roadmap"
                            className="mr-3 pr-1"
                          />
                         Product Roadmap
                         <img
                        src={
                          require("../assets/images/dashboard/Goto.png").default
                        }
                        className="dashboard-gotospace"
                        alt="Goto"
                      />
                        </div>
                      </a>
                    </DropdownItem>
                     <DropdownItem>
                      <a
                        href="https://updates.appmysite.com/requests/en/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="d-flex justify-content-start align-items-center">
                          <img
                            src={
                              require("../assets/images/dashboard/Request.png")
                                .default
                            }
                            alt="roadmap"
                            className="mr-3 pr-1"
                          />
                          Request a Feature
                          <img
                        src={
                          require("../assets/images/dashboard/Goto.png").default
                        }
                        className="dashboard-gotospace"
                        alt="Goto"
                      />
                        </div>
                      </a>
                    </DropdownItem>
                     <DropdownItem>
                      <a
                        href="https://community.appmysite.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="d-flex justify-content-start align-items-center">
                          <img
                            src={
                              require("../assets/images/dashboard/community.png")
                                .default
                            }
                            alt="roadmap"
                            className="mr-3"
                          />
                          Community
                          <img
                        src={
                          require("../assets/images/dashboard/Goto.png").default
                        }
                        className="dashboard-gotospace"
                        alt="Goto"
                      />
                        </div>
                      </a>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>

            </div>
          </div>
        </header>
        <div className="integrationpages">
          <img
            src={require("../assets/images/signup/branding.png").default}
            className="branding1"
            alt="AMS branding"
          />

          <img
            src={require("../assets/images/signup/branding2.png").default}
            className="branding2"
            alt="AMS branding"
          />
          <section className="height100 create-account integrationsignup woocommercepage">
            <div className="signup_left">
              <div className="white-bg ">
                <div className="signup_form pb-4">
                  {/* Register Form Start */}
                  <Registerform
                    history={this.props.history}
                    // url={this.props.match.params.email}
                  />
                  {/* Register Form Start End */}
                </div>
              </div>
            </div>
            {/* {Foot()} */}
          </section>
        </div>
      </>
    );
  }
}
class Registerform extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      usertype: "",
      password: "",
      passattribute: true,
      passattibutetype: "password",
      eyeimage: require("../assets/images/signup/secure.png").default,
      submitval: "REGISTER",
      loader: "d-none",
      loaderimage: require("../assets/images/signup/Loader_GIF.gif").default,
      nameerror: null,
      emailerror: null,
      ownererror: null,
      passworderror: null,
      nameerrormesg: null,
      emailerrormesg: null,
      passworderrormesg: null,
      captchavalue: "",
      login_ip: "",
      user_country: "",
    };
  }
  componentDidMount() {
    document.title = "Signup";

    window.scrollTo(0, 0);
    if (localStorage.getItem("buildversion") === null) {
      localStorage.setItem("buildversion", process.env.REACT_APP_BUILD);
    }
    if (localStorage.getItem("buildversion") !== null) {
      if (
        localStorage.getItem("buildversion") !== process.env.REACT_APP_BUILD
      ) {
        window.location.reload(true);
        localStorage.setItem("buildversion", process.env.REACT_APP_BUILD);
      }
    }
  }
  onChange = (value) => {
    this.setState({
      captchavalue: value,
    });
  };
  changepass = () => {
    this.setState({
      passattribute: !this.state.passattribute,
    });
    if (this.state.passattribute) {
      this.setState({
        passattibutetype: "text",
        eyeimage: require("../assets/images/signup/eyeopen.png").default,
      });
    } else {
      this.setState({
        passattibutetype: "password",
        eyeimage: require("../assets/images/signup/secure.png").default,
      });
    }
  };
  handleChange = (e) => {
    // if (e.target.name === "email") {
    //   e.preventDefault();
    // } else if (e.target.name === "name") {
    //   this.setState({
    //     [e.target.name]: e.target.value
    //       .replace("http://", "")
    //       .replace("https://", "")
    //       .replace("www.", ""),
    //     passworderror: false,
    //     emailerror: false,
    //   });
    // } else {
    this.setState({
      [e.target.name]: e.target.value,
      passworderror: false,
      emailerror: false,
    });
    // }
  };
  handlechangeNewpass = (e) => {

    
    
    const passwordValue= e.target.value;
    const passwordLength= passwordValue.length;
    
    const weakRegExp = /[a-z]/;
    const almostRegExp = /[0-9]/;
    const strongRegExp = /[A-Z]/;

    var PoorPassword = false;
    var WeakPassword = false;
    var AlmostPassword = false;
    var StrongPassword = false;

    if(passwordLength >= 1)
    {
      PoorPassword = true;
    }
    
    if(passwordLength < 8)
    {
      PoorPassword = false; 
    }

    if(weakRegExp.test(passwordValue))
    {

      WeakPassword = true;
    }
    else
    {
      WeakPassword =false;      
    }

    if(almostRegExp.test(passwordValue))
    {

      AlmostPassword =true;
    }
    else
    {
      AlmostPassword =false;      
    }

    if(strongRegExp.test(passwordValue))
    {

      StrongPassword =true;
    }
    else
    {
      StrongPassword=false;      
    }

    // if(passwordLength >= 72)
    // {
    //     PoorPassword=false;
    // }


    const arr1 = [PoorPassword, WeakPassword, AlmostPassword, StrongPassword];
    const count1 = arr1.filter(value => value === true).length;
    
    this.setState({progress:count1});


    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  registerForm = (e) => {
    e.preventDefault();

    if (this.state.captchavalue === "") {
      errortoast("Captcha is required");
    }

    this.state.captchavalue &&
      Countrycode(this, () => {
        this.setState({
          submitval:""
        })
        const params = {
          name: this.state.name.trim(),
          email: this.state.email.replace(/\s/g, "").toLowerCase(),
          owner: this.state.usertype,
          password: btoa(this.state.password),
          user_ip: this.state.login_ip,
          user_country: this.state.user_country,
          reseller_type: 4,
          agency_id:localStorage.getItem('agency_id') ? parseInt(localStorage.getItem('agency_id')) : 1
        };
       
        SignupApi(params, this);
      });
  };

  render() {
    return (
      <>
        <div className="d-flex align-item-center justify-content-between">
          <div className="signupcontent">
            <h3 className="text-left mt-0">Complete your account</h3>
          </div>
        </div>
        <form onSubmit={this.registerForm}>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              name="name"
              placeholder="Name*"
              value={this.state.name}
              onChange={(e) => this.handleChange(e)}
              required
            />
            <div className="d-flex align-items-center mt-md-1 error">
              <div>
                {this.state.nameerror ? (
                  <div className="d-flex align-items-center mt-md-1 error">
                    <img
                      src={require("../assets/images/signup/error.png").default}
                      className="img-fluid"
                      alt="error"
                    />
                    <p>{this.state.nameerrormesg}</p>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              name="email"
              value={this.state.email}
              onChange={(e) => this.handleChange(e)}
              placeholder="Email*"
              required
            />
            <div className="d-flex align-items-center mt-md-1 error">
              <div>
                {this.state.emailerror ? (
                  <div className="d-flex align-items-center mt-md-1 error">
                    <img
                      src={require("../assets/images/signup/error.png").default}
                      className="img-fluid"
                      alt="error"
                    />
                    <p>{this.state.emailerrormesg}</p>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          {/* <div className="form-group">
                            <select name="usertype" value={this.state.usertype} onChange={e => this.handleChange(e)} required>
                                <option value="">I'm a</option>
                                <option value="Website Owner">Website Owner</option>
                                <option value="Freelance Developer" defaultValue>Freelance Developer</option>
                                <option value="Website Development Company">Website Development Company</option>
                            </select>
                            <div className="d-flex align-items-center mt-md-1 error">
                                <div> 
                                {this.state.ownererror ?  <div className="d-flex align-items-center mt-md-1 error">
                                <img src={require( "../assets/images/signup/error.png").default} className="img-fluid" alt="error" />
                                <p>{this.state.ownererror}</p>
                            </div>:<></> }</div>
                            </div>
                        </div> */}
          <div className="form-group">
            <input
              required
              type={this.state.passattibutetype}
              className="form-control eye-close"
              name="password"
              value={this.state.password}
              onChange={(e) => this.handlechangeNewpass(e)}
              placeholder="Password*"
            />
            <div className="eye" onClick={this.changepass}>
              <img
                src={this.state.eyeimage}
                className="img-fluid"
                alt="secure"
              />
            </div>
            {this.state.passworderror ? (
              <div className="d-flex align-items-center mt-md-1 error">
                <img
                  src={require("../assets/images/signup/error.png").default}
                  className="img-fluid"
                  alt="error"
                />
                <p>{this.state.passworderrormesg}</p>
              </div>
            ) : (
              <></>
            )}
          </div>
          
          <div className="progressbar">
            <div>
              <>
                           {this.state.progress == 0 ?
                            <>
                              <progress id="file" max="100" value="0" className="">{" "} </progress>
                              <progress id="file" max="100" value="0" className="">{" "} </progress>
                              <progress id="file" max="100" value="0" className="">{" "} </progress>
                              <progress id="file" max="100" value="0" className="">{" "} </progress>
                            </>
                            : this.state.progress == 1 ?
                              <>
                              <progress id="file" max="100" value="100" className="redprogress">{" "} </progress>
                              <progress id="file" max="100" value="0" className="">{" "} </progress>
                              <progress id="file" max="100" value="0" className="">{" "} </progress>
                              <progress id="file" max="100" value="0" className="">{" "} </progress>
                            </>
                            : this.state.progress == 2 ?
                            <>
                              <progress id="file" max="100" value="100" className="orangeprogress">{" "} </progress>
                              <progress id="file" max="100" value="100" className="orangeprogress">{" "} </progress>
                              <progress id="file" max="100" value="0" className="">{" "} </progress>
                              <progress id="file" max="100" value="0" className="">{" "} </progress>
                            </>
                            : this.state.progress == 3 ?
                              <>
                              <progress id="file" max="100" value="100" className="lightgreenprogress">{" "} </progress>
                              <progress id="file" max="100" value="100" className="lightgreenprogress">{" "} </progress>
                              <progress id="file" max="100" value="100" className="lightgreenprogress">{" "} </progress>
                              <progress id="file" max="100" value="0" className="">{" "} </progress>
                            </>
                            : this.state.progress == 4 ?
                              <>
                              <progress id="file" max="100" value="100" className="greenprogress">{" "} </progress>
                              <progress id="file" max="100" value="100" className="greenprogress">{" "} </progress>
                              <progress id="file" max="100" value="100" className="greenprogress">{" "} </progress>
                              <progress id="file" max="100" value="100" className="greenprogress">{" "} </progress>
                              </>
                            : <>
                              <progress id="file" max="100" value="0" className="">{" "} </progress>
                              <progress id="file" max="100" value="0" className="">{" "} </progress>
                              <progress id="file" max="100" value="0" className="">{" "} </progress>
                              <progress id="file" max="100" value="0" className="">{" "} </progress>
                            </>
                          }
                          </>
                          </div>
            <p>
              Password must contain an uppercase letter, a lowercase letter and
              a digit.
            </p>
          </div>
          <div className="text-center m-auto">
            <ReCAPTCHA
              sitekey="6LeOmMAUAAAAAGj7NXf5SlZA25DNAfvL1HwxaAeP"
              onChange={this.onChange}
            />
          </div>

          <div>
            <button
              type="submit"
              className="loginbtn woocommerce-login-btn"
             
            >
              <div className="d-flex align-items-center justify-content-center h-100 w-100">
                {/* <div className={this.state.loader}><img src={this.state.loaderimage} alt="loader"/></div> */}
                <div className="d-flex align-items-center woocommerce-page-div-body" >
                  {this.state.submitval === "" && (
                    <Spinner
                      animation="border"
                      variant="primary"
                      className="woocommerce-page-spinner"
                     
                    />
                  )}
                  {this.state.submitval}
                </div>
              </div>
            </button>
          </div>

          <div className="tc">
            <h6 className="mt-md-2 mb-md-2">
              By creating an account, you agree to our
              <a
                href="https://www.appmysite.com/terms-conditions/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Terms &amp; Conditions
              </a>{" "}
              &amp;
              <a
                href="https://www.appmysite.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Privacy Policy.{" "}
              </a>
              You also agree to receiving occasional emails about our product
              and services on your registered email address.
            </h6>
          </div>
        </form>
      </>
    );
  }
}
