import { Modal, ModalBody } from "reactstrap";

export const FilterModal = ({
  handleMainFilterChange,
  handleOpenParentDropdown,
  openMobParentFilter,
  optionForMainFilter,
  filterSelectionArr,
  index,
}) => {
  return (
    <div>
      <Modal
        className="protip-popup-message"
        isOpen={
          window.matchMedia("(max-width: 1200px)").matches
            ? openMobParentFilter
            : false
        }
        centered
      >
        <ModalBody>
          <div className="mobileglobalsearch">
            <button onClick={() => handleOpenParentDropdown(index)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.759"
                height="15.408"
                viewBox="0 0 20.759 15.408"
              >
                <g
                  id="Group_27465"
                  data-name="Group 27465"
                  transform="translate(-19.742 -22.295)"
                >
                  <path
                    id="Path_81245"
                    data-name="Path 81245"
                    d="M-13783.922-19217h19.346"
                    transform="translate(13805.077 19247)"
                    fill="none"
                    stroke="#7782a1"
                    stroke-width="2"
                  />
                  <path
                    id="Path_82232"
                    data-name="Path 82232"
                    d="M14053.656,19255.426l-7,7,7,7"
                    transform="translate(-14025.504 -19232.424)"
                    fill="none"
                    stroke="#7782a1"
                    stroke-width="2"
                  />
                </g>
              </svg>
            </button>
          </div>

          <div className="mobilesearchsuggestion">
            {optionForMainFilter &&
              optionForMainFilter.map((option, i) => {
                return (
                  <p
                    style={{
                      fontSize: "16px",
                      color: filterSelectionArr.find(
                        (row) => row.value === option.value
                      )
                        ? "#C8CEDB"
                        : "#1B2952",
                      fontFamily: "Axiforma-Regular",
                      margin: "0px 20px 40px 20px",
                      display: "block",
                    }}
                    onClick={
                      filterSelectionArr.find(
                        (row) => row.value === option.value
                      )
                        ? null
                        : (e) => handleMainFilterChange(option, index)
                    }
                  >
                    {option.label}
                  </p>
                );
              })}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
