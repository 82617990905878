import React, { useState, useEffect} from "react";

import { Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import Select from "react-select";

const SessionCountSegment = ({keydata, scKey, setSCKey, scValue, setSCValue, scFilterType, setSCFilterType, scoperators, setSCOperators, onRemoveSC, scRowOpen, scopenrownumber, selectruleserror, selectvalueerror, duplicateSCSegment}) =>{

	const [scdropdownopen, setSCDropDownOpen] = useState(false);
		
    const rulesoption = [
        { value: "eq", label: "is" },
        { value: "gt", label: "greater than" },
        { value: "lt", label: "less than" },
    ];


    const [refresh, setRefresh] = useState(false);

    useEffect(()=>{

        /*if(!refresh) 
        {*/
            let getfiltertype = [...scFilterType];
            getfiltertype[keydata] = 3;
            
            setSCFilterType(getfiltertype);
            

            let getoperator = [...scoperators];
            
            let filterdivcount = document.querySelectorAll('.define-filters').length;
            //
            if(filterdivcount > 1 && (filterdivcount - 1) !== keydata)
            {
                
                getoperator[keydata]  = "AND";
                setSCOperators(getoperator);
            }

            //setRefresh(true);
        //}

    }, []);

    const handleSCSelectChange = (e) =>{
        //e.preventDefault();
        
        //setSCKey(e.target.value);

        let getsckey = [...scKey];
        getsckey[keydata] = e.value;
        //getsckey[keydata] = e.target.value;
        setSCKey(getsckey);

		setSCDropDownOpen(false);
    }

    const handleSCInputChnge = (e) =>{
        e.preventDefault();
        
        const validInput = /^[0-9]*$/.test(e.target.value);

        if (!validInput) {
        return;
        }
        //setSCValue(e.target.value);

        let getscvalue = [...scValue];
        getscvalue[keydata] = e.target.value;
        setSCValue(getscvalue);
    }
	
	const handleMobileSCDropdown = () =>{

        setSCDropDownOpen(!scdropdownopen);
    }



    return(
        <div className="accordionItem">
            <div onClick={ (e) => scRowOpen(e, 3, keydata) }>
                <div className="heading-wrapper accordionItemHeading allflex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 25 18.999">
                        <path id="Union_1894" data-name="Union 1894" d="M5480,1140v-3h19v3Zm-6,0v-3h3v3Zm6-8v-3h19v3Zm-6,0v-3h3v3Zm6-8v-3h19v3Zm-6,0v-3h3v3Z" transform="translate(-5474 -1121)" fill="#ffc834"/>
                    </svg>
                    <h2 className="heading">Visit count</h2>
                </div>
                <div className="arrow-img">
                    { scopenrownumber[keydata] === true ?   
                        <svg xmlns="http://www.w3.org/2000/svg" width="13.061" height="7.592" viewBox="0 0 13.061 7.592">
                            <path id="Path_81302" data-name="Path 81302" d="M-12935-18908l6-6,6,6" transform="translate(12935.531 18915.064)" fill="none" stroke="#7782a1" stroke-width="1.5" />
                        </svg>
                    :
                        <svg xmlns="http://www.w3.org/2000/svg" width="13.061" height="7.592" viewBox="0 0 13.061 7.592">
                            <path id="Path_81302" data-name="Path 81302" d="M-12935-18914l6,6,6-6" transform="translate(12935.531 18914.535)" fill="none" stroke="#7782a1" stroke-width="1.5" />
                        </svg>
                    }
                </div>
            </div>

            { scopenrownumber[keydata] === true &&
            <div className="accordian-text-wrapper">
                <div className="accordion-text">
                    <div className="select-wrapper">
                        <div className={ selectruleserror[keydata] ?  "mobile-view-click notification-selecterror" : "mobile-view-click" } onClick={ window.matchMedia("(max-width: 1200px)").matches ? handleMobileSCDropdown : null }>
                            <Select options={rulesoption} onChange={handleSCSelectChange } value={ rulesoption.filter(function (option) {
                                      return (option.value === scKey[keydata]); }) } isSearchable={false} isClearable={false} isDisabled={ window.matchMedia("(max-width: 1200px)").matches ? true : false } classNamePrefix="profile-role-dropdown" />
                        </div>
                     
                    </div>
                    <div className="audience-category-parent-dropdown">
                        {/*<input type="text" placeholder="168" class="total-hours-count" name="fsvalue" onChange={handleLSInputChnge} value={lsValue[keydata]} />*/}
                        <Input type="text" name="scvalue" onChange={handleSCInputChnge} value={scValue[keydata]}  class={selectvalueerror[keydata] ? "segmet-error-input" : "" } />
                        <span>sessions</span>
                    </div>
                </div>
                <div className="delete-and-copy">
                    {/* <div className="copy" onClick={(e) =>duplicateSCSegment(e, 3)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.001" height="15" viewBox="0 0 15.001 15" >
                            <g id="Group_26180" data-name="Group 26180" transform="translate(-450.5 -124.5)" >
                                <g id="Path_9276" data-name="Path 9276" transform="translate(0 0)" fill="none" stroke-miterlimit="10" >
                                    <path d="M462.5,125.923V128.5h-6.72a1.189,1.189,0,0,0-1.28,1.28v6.72h-2.577a1.419,1.419,0,0,1-1.423-1.423v-9.154a1.419,1.419,0,0,1,1.423-1.423h9.154A1.419,1.419,0,0,1,462.5,125.923Z" stroke="none" />
                                    <path d="M 451.9228515625 125.5000152587891 C 451.6857299804688 125.5000152587891 451.5 125.6857452392578 451.5 125.9228668212891 L 451.5 135.0771636962891 C 451.5 135.3142700195312 451.6857299804688 135.5 451.9228515625 135.5 L 453.5 135.5 L 453.5 129.7800140380859 C 453.5 128.4375762939453 454.4375610351562 127.5 455.7799682617188 127.5 L 461.5 127.5 L 461.5 125.9228668212891 C 461.5 125.6857452392578 461.3142395019531 125.5000152587891 461.0771179199219 125.5000152587891 L 451.9228515625 125.5000152587891 M 451.9228515625 124.5000152587891 L 461.0771179199219 124.5000152587891 C 461.8656616210938 124.5000152587891 462.5 125.1343078613281 462.5 125.9228668212891 L 462.5 128.5 L 455.7799682617188 128.5 C 454.9913940429688 128.5 454.5 128.991455078125 454.5 129.7800140380859 L 454.5 136.5 L 451.9228515625 136.5 C 451.13427734375 136.5 450.5 135.8657531738281 450.5 135.0771636962891 L 450.5 125.9228668212891 C 450.5 125.1343078613281 451.13427734375 124.5000152587891 451.9228515625 124.5000152587891 Z" stroke="none" fill="#7782a1" />
                                </g>
                                <g id="Path_9277" data-name="Path 9277" transform="translate(-1.999 -2)" fill="none" stroke-miterlimit="10" >
                                    <path d="M467.5,130.923v9.154a1.419,1.419,0,0,1-1.423,1.423h-9.154a1.419,1.419,0,0,1-1.423-1.423v-9.154a1.419,1.419,0,0,1,1.423-1.423h9.154A1.419,1.419,0,0,1,467.5,130.923Z" stroke="none" />
                                    <path d="M 456.9228210449219 130.5000152587891 C 456.6856994628906 130.5000152587891 456.4999694824219 130.6857452392578 456.4999694824219 130.9228515625 L 456.4999694824219 140.0771484375 C 456.4999694824219 140.3142700195312 456.6856994628906 140.5000152587891 456.9228210449219 140.5000152587891 L 466.0771179199219 140.5000152587891 C 466.3142395019531 140.5000152587891 466.4999694824219 140.3142700195312 466.4999694824219 140.0771484375 L 466.4999694824219 130.9228515625 C 466.4999694824219 130.6857452392578 466.3142395019531 130.5000152587891 466.0771179199219 130.5000152587891 L 456.9228210449219 130.5000152587891 M 456.9228210449219 129.5000152587891 L 466.0771179199219 129.5000152587891 C 466.8656921386719 129.5000152587891 467.4999694824219 130.1342926025391 467.4999694824219 130.9228515625 L 467.4999694824219 140.0771484375 C 467.4999694824219 140.86572265625 466.8656921386719 141.5000152587891 466.0771179199219 141.5000152587891 L 456.9228210449219 141.5000152587891 C 456.1342163085938 141.5000152587891 455.4999694824219 140.86572265625 455.4999694824219 140.0771484375 L 455.4999694824219 130.9228515625 C 455.4999694824219 130.1342926025391 456.1342163085938 129.5000152587891 456.9228210449219 129.5000152587891 Z" stroke="none" fill="#7782a1" />
                                </g>
                            </g>
                        </svg>
                    </div> */}
                    <div className="delete">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.999" viewBox="0 0 14 13.999" onClick = { (e)=>onRemoveSC(e, keydata) } >
                            <g id="Group_5465" data-name="Group 5465" transform="translate(-835.516 -682.992)">
                                <g id="Rectangle_2307" data-name="Rectangle 2307" transform="translate(836.515 684.992)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1">
                                    <path d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z" stroke="none"/>
                                    <path d="M.5.5h11a0,0,0,0,1,0,0V10.7a.8.8,0,0,1-.8.8H1.3a.8.8,0,0,1-.8-.8V.5A0,0,0,0,1,.5.5Z" fill="none"/>
                                </g>
                                <g id="Rectangle_2308" data-name="Rectangle 2308" transform="translate(839.515 682.992)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" >
                                    <path d="M1,0H5A1,1,0,0,1,6,1V3A0,0,0,0,1,6,3H0A0,0,0,0,1,0,3V1A1,1,0,0,1,1,0Z" stroke="none" />
                                    <path d="M1,.5H5a.5.5,0,0,1,.5.5V2.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V1A.5.5,0,0,1,1,.5Z" fill="none" />
                                </g>
                                <g id="Group_3245" data-name="Group 3245" transform="translate(841.016 687.993)" >
                                    <line id="Line_110" data-name="Line 110" y2="6" transform="translate(3 0)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" />
                                    <line id="Line_111" data-name="Line 111" y1="6" transform="translate(0 0)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" />
                                </g>
                                <line id="Line_112" data-name="Line 112" x2="14" transform="translate(835.516 685.492)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" />
                            </g>
                        </svg>
                    </div>
                </div>
				
				<Modal isOpen={ window.matchMedia("(max-width: 1200px)").matches ? scdropdownopen : false } style={{ maxWidth: "700px", maxHeight: "350px" }} className={" profile-code-modal"} centered dataclass="countrycode" >
                    <ModalHeader className=" ">
                        <div className="d-flex align-items-center search-box justify-content-center profile-country-search">
                            <div className="d-flex align-items-center w-100 ">
                                <div className="backaero-svgimg" onClick={handleMobileSCDropdown} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.759" height="15.408" viewBox="0 0 20.759 15.408" >
                                        <g id="Group_27465" data-name="Group 27465" transform="translate(-19.742 -22.295)" >
                                            <path id="Path_81245" data-name="Path 81245" d="M-13783.922-19217h19.346" transform="translate(13805.077 19247)" fill="none" stroke="#7782a1" stroke-width="2" />
                                            <path id="Path_82232" data-name="Path 82232" d="M14053.656,19255.426l-7,7,7,7" transform="translate(-14025.504 -19232.424)" fill="none" stroke="#7782a1" stroke-width="2" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody className="code-selector p-0">
                        <div className="mob-popup-company-size">
                            {rulesoption.map((res, i) => (
                            <div className="d-flex align-items-center justify-content-between" key={i} >
                                <div className="d-flex align-items-center justify-content-start" onClick={() => handleSCSelectChange(res) } >
                                    <p className="mob-company-size">{res.label}</p>
                                </div>
                            </div>
                            ))}
                        </div>

                    </ModalBody>
                </Modal>
            </div>
            }
        </div>
    );
}

export default SessionCountSegment;