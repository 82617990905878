import React from "react";
import { Link } from "react-router-dom";
import buildSettingsIc from "../../../assets/images/dashboard/buildSettings.svg";
import plusIc from "../../../assets/images/dashboard/plus.png";
import searchIc from "../../../assets/images/dashboard/search.png";
import filterIc from "../../../assets/images/dashboard/filterIcWithoutDot.svg";
import firebaseIc from "../../../assets/images/dashboard/firebaseIc.svg";
import buildsettingnotselect from "../../../assets/images/dashboard/buildsetting-notselect.svg";
const MobSubHeader = ({
  notificationData,
  setNotificationData,
  agencyId,
  handleNotificationClick,
}) => {
  return (
    <>
      <div className="update-mob-upgadehead">
        <p>Push Notifications</p>
        {notificationData.isEnableScreen ? (
          <div className="build-settingmob">
            
              <Link
                to={"/app/notifications/build-settings"}
                className="pointcursor consent-setting"
              >
                <img src={buildsettingnotselect} alt="build settings" />
              </Link>
            
          </div>
        ) : null}
      </div>
      {agencyId &&
      +agencyId !== 1 &&
      window.matchMedia("(max-width: 1200px)").matches &&
      !notificationData.isEnableScreen &&
      notificationData.spinner ? (
        <div className="upgrade-feature upgrade-feature-with-margin">
          <h4 className="upgrade-unavailable notification-upgrade-support">
            This feature is currently unavailable
          </h4>
          <p className="upgrade-support notification-upgrade-support">
            Please contact Support for assistance.
          </p>
        </div>
      ) : null}

      <div className="notification-mob-create-btn">
        <Link
          to="/app/basic-notification/create"
          className=" update-mob-upgadelink"
        >
          <img src={plusIc} className="img-fluid mr-2 symbolspace" alt="plus" />
          Create
        </Link>
      </div>
      {notificationData.isEnableScreen && !notificationData.spinner ? (
        <div className="pushnotification-mobfilterdiv">
          {notificationData.isEnableScreen &&
            notificationData.displayTable &&
            notificationData.displayTable?.length > 0 && (
              <div className="pushnotification-Search">
                <button>
                  <img src={searchIc} alt="search" />
                </button>
                <input
                  placeholder="Search"
                  value={notificationData.searchVal}
                  onChange={(e) =>
                    setNotificationData({ searchVal: e.target.value })
                  }
                ></input>
              </div>
            )}
          {(!notificationData.displayTable ||
            notificationData.displayTable?.length === 0) &&
          !notificationData.redDotOnFilter ? null : (
            <div
              className="pushnotification-filter"
              onClick={(e) => handleNotificationClick(e)}
            >
              <img src={filterIc} alt="filter" />
              {notificationData.redDotOnFilter ? (
                <div className="filterapplyed-reddot notification-filter-red-dot"></div>
              ) : null}
            </div>
          )}
          {notificationData.displayTable &&
          notificationData.displayTable?.length > 0 ? (
            <div
              className="pushnotification-filter"
              onClick={() => setNotificationData({ isFirebaseModalOpen: true })}
            >
              <img src={firebaseIc} alt="firebase" />
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default MobSubHeader;
