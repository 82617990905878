import axios from "axios";
import { errortoast } from "../../Dashboard/Toaster";
const Url = process.env.REACT_APP_API_URL + "api/user/";
const HistoryUrl =
  process.env.REACT_APP_API_URL + "api/app/connectivity-history";
const slowwebsite = process.env.REACT_APP_API_URL + "api/email/slow-website";
const slowwebsitestatus =
  process.env.REACT_APP_API_URL + "api/app/slow-website/update-status";
export const Troubleshootingapi = (params, state, callback) => {
  let headers = {};
  if (params.store_api_version === 1) {
    headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-App-Device-Type": "Administrator",
      // 'AmsClientID': state.state.clientID
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Accept: "application/json",

      // 'AmsClientID': state.state.clientID
    };
  }

  axios
    .post(
      params.store_api_version === 1
        ? process.env.REACT_APP_TROUBLESHOOT_API_V2
        : process.env.REACT_APP_TROUBLESHOOT_API_V1,
      params,
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.status === 200) {
        let successarray = [];
        let i;
        for (i = 0; i < res.data.length; i++) {
          if (
            Object.values(res.data[i])[0] === "success" ||
            Object.values(res.data[i])[0] === "warning"
          ) {
            successarray.push(i);
          }
        }

        state.setState({
          spinner: false,
          showstatus: res.data,
          errormessage: false,
          statusimage:
            res.data[0].is_plugin_installed === "success"
              ? require("../../assets/images/dashboard/statussuccess.png")
                  .default
              : require("../../assets/images/dashboard/statuserror.png")
                  .default,
          statusmessage: res.data[0].message,
          errorcode:
            res.data[0].is_plugin_installed === "success"
              ? ""
              : res.data[0].error_code === undefined
              ? ""
              : res.data[0].error_code,
          testurl:
            res.data[0].is_plugin_installed === "success"
              ? ""
              : res.data[0].help_url === undefined
              ? ""
              : res.data[0].help_url,
          successcount: successarray,
          hit: true,
          buttontext: "Troubleshoot",
          troubleshootloader: "d-none",
          disabledbutton: false,
        });
        callback();
      }
    })
    .catch((error) => {
      
      // state.setState({
      //   spinner: false,
      //   hit: false,
      //   buttontext: "Troubleshoot",
      //   disabledbutton: false,
      // });
      // errortoast("Something went wrong");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      // if (error.response) {
      //   if (error.response.status === 422) {
      //     if (error.response.data.errors !== undefined) {
      //       if (error.response.data.errors !== undefined) {
      //         errortoast(error.response.data.errors);
      //         state.setState({
      //           spinner: false,
      //           hit: false,
      //           buttontext: "Troubleshoot",
      //           disabledbutton: false,
      //         });
      //       }
      //     }
      //   }
      // }
    });
};
export const Troubleshootingapiwithoutcsck = (params, state, callback) => {
  let headers = {};

  if (
    params.store_api_version === 1 ||
    parseInt(localStorage.getItem("website_technology")) === 2
  ) {
    headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-App-Device-Type": "Administrator",
      // 'AmsClientID': state.state.clientID
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Accept: "application/json",

      // 'AmsClientID': state.state.clientID
    };
  }

  axios
    .post(
      parseInt(localStorage.getItem("website_technology")) === 2
        ? process.env.REACT_APP_TROUBLESHOOT_API_GENERAL_V1
        : params.store_api_version === 1
        ? process.env.REACT_APP_TROUBLESHOOT_API_WITHOUTCSK_V2
        : process.env.REACT_APP_TROUBLESHOOT_API_WITHOUTCSK_V1,
      params,
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.status === 200) {
        let successarray = [];
        let i;
        for (i = 0; i < res.data.length; i++) {
          if (Object.values(res.data[i])[0] === "success") {
            successarray.push(i);
          }
        }
        state.setState({
          spinner: false,
          showstatus: res.data,
          errormessage: false,
          statusimage:
            res.data[0].is_plugin_installed === "success"
              ? require("../../assets/images/dashboard/statussuccess.png")
                  .default
              : require("../../assets/images/dashboard/statuserror.png")
                  .default,
          statusmessage: res.data[0].message,
          errorcode:
            res.data[0].is_plugin_installed === "success"
              ? ""
              : res.data[0].error_code === undefined
              ? ""
              : res.data[0].error_code,
          testurl:
            res.data[0].is_plugin_installed === "success"
              ? ""
              : res.data[0].help_url === undefined
              ? ""
              : res.data[0].help_url,
          successcount: successarray,
          hit: true,
          disabledbutton: false,
          buttontext: "Troubleshoot",
          troubleshootloader: "d-none"
        });
        callback();
      }
    })
    .catch((error) => {
      
      errortoast("Something went wrong");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      // if (error.response) {
      //   if (error.response.status === 422) {
      //     if (error.response.data.errors !== undefined) {
      //       if (error.response.data.errors !== undefined) {
      //         errortoast(error.response.data.errors);
      //         state.setState({
      //           spinner: false,
      //           hit: false,
      //           disabledbutton: false,
      //           buttontext: "Troubleshoot",
      //         });
      //       }
      //     }
      //   }
      // }
    });
};

export const SpeedSlow = (params) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(slowwebsitestatus, params, {
      headers: headers,
    })
    .then((res) => {
      if (res.status === 200) {
      }
    })
    .catch((error) => {});
};
export const Getappcredentailapi = (state, callback) => {
  
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(Url + "get_credentials/" + btoa(localStorage.getItem("Appid")), {
      headers: headers,
    })
    .then((res) => {
      let sendwrl = res.data.data.websiteurl;
      if (
        res.data.data.websiteurl[res.data.data.websiteurl.length - 1] === "/"
      ) {
        sendwrl = res.data.data.websiteurl;
      }

      if (res.data.code === 200) {
        state.setState({
          ck: res.data.data.client_key,
          cs: res.data.data.client_secret,
          sendwrl: sendwrl,
          website_type: res.data.data.website_type.toString(),
          spinner: false,
          historySpinner: false,
          plan_code: res.data.data.subscription.plan_code,
          connectivity_history: res.data.data.connectivity_history,
          // historyDataForPreview:res.data.data.connectivity_history.length>0? res.data.data.connectivity_history[0].result_json:res.data.data.connectivity_history,
          is_connected: res.data.data.is_connected,
          wp_username: res.data.data.wp_username,
          wp_application_password: res.data.data.wp_application_password,
          authorization_type: res.data.data.authorization_type,
          connection_to_establish: res.data.data.connection_to_establish,
          is_trigger_slow_website_email:
            res.data.is_trigger_slow_website_email === 0 ? true : false,
          is_verified: res.data.data.is_verified,
          clientID: res.data.client_id,
          store_api_version: res.data.store_api_version,
        });
        callback();
      }
    })
    .catch((error) => {
      state.setState({
        spinner: false,
      });
      
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

//Connectivity_History

export const Connectivity_History = (params,state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(HistoryUrl, params, {
      headers: headers,
    })
    .then((res) => {
   
      if (res.status === 200) {
       
        state.setState({
          historySpinner: true,
          // isTextChange: false,
          // historyDataForPreview:state.state.connectivity_history[0].result_json,
          selectedIndx:0
        })
    
        state.loadingFun();
        state.props.history.push(`/app/connect/troubleshoot/${btoa(0)}`);
      }
    })
    .catch((error) => {
      
    });
};

export const Slowwebsiteapi = (params) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(slowwebsite, params, {
      headers: headers,
    })
    .then((res) => {
      if (res.status === 200) {
      }
    });
};
