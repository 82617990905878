import React, { useState } from "react";
import "../../../Components/commonComponents/CommonFilter/commonFilter.css";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import bluePlus from "../../../assets/images/dashboard/plus.png";
import { FilterModal } from "./FilterModal";
import DeleteIcon from "../../../assets/images/dashboard/cms/delete.png";
import moment from "moment";

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const CustomFilter = ({ onClose, onSearch }) => {
  const [optionForMainFilter] = useState([
    // { value: "Status", label: "Status" },
    { value: "Created", label: "Created" },
    { value: "Last Login", label: "Last Login" },
  ]);
  const [filterSelectionArr, setFilterSelectionArr] = useState([
    { index: 0, value: "" },
  ]);
  const [openMobParentFilter, setOpenMobParentFilter] = useState([
    false,
    false,
    false,
  ]);
  const [openStatusFilter, setOpenStatusFilter] = useState(false);
  const [statusFilterVal, setStatusFilterVal] = useState("all");
  const [openMobStatusFilter, setOpenMobStatusFilter] = useState(false);
  const [createdDateRange, setCreatedDateRange] = useState([null, null]);
  const [lastLoginDateRange, setLastLoginDateRange] = useState([null, null]);
  const isMobileView = window.matchMedia("(max-width: 1200px)").matches;

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderRadius: 3,
      borderColor: state.isFocused ? "#7782a1" : "#C8CEDB",
      backgroundColor: state.isDisabled ? "#ffffff" : "",
      height: 50,
      minHeight: 50,
      fontSize: 14,
      color: "#7782A1",
      paddingLeft: 10,
      boxShadow: state.isFocused ? "none" : "none",
      "&:hover": {
        boxShadow: state.isFocused ? "none" : "none",
      },
    }),
    valueContainer: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
      fontSize: 14,
      color: "#7782A1",
    }),
    menu: (base) => ({
      ...base,
      fontSize: "14px",
    }),
    input: (base) => ({
      ...base,
      margin: 0,
      padding: 0,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#1B2952", // Custom colour
      marginRight: 20,
    }),
  };

  const returnSelectedStatusLabel = (status) => {
    switch (status) {
      case "":
      case null:
      case undefined:
        return "All";
      case "active":
        return "Active";
      case "block":
        return "Block";
      default:
        return "All";
    }
  };

  const handleMainFilterChange = (option, index) => {
    let isMatch = false;
    let newArr = [...filterSelectionArr];

    // Reset other filter data if the new selection differs from the previous selection
    if (option.value !== newArr[index].value) {
      if (newArr[index].value === "Status") setStatusFilterVal(null);
      if (newArr[index].value === "Created") setCreatedDateRange([null, null]);
      if (newArr[index].value === "Last Login")
        setLastLoginDateRange([null, null]);
    }

    // Update or add the new filter type
    newArr.forEach((row) => {
      if (row.index === index) {
        row.value = option.value;
        isMatch = true;
      }
    });

    if (!isMatch) {
      newArr.push({ index, value: option.value });
    }

    setFilterSelectionArr(newArr);
    setOpenMobParentFilter([false, false, false]);
  };

  const handleAddFilter = () => {
    setFilterSelectionArr([
      ...filterSelectionArr,
      { index: filterSelectionArr.length, value: "" },
    ]);
  };
  const handleStatusFilterChange = (e, status) => {
    e.preventDefault();
    setStatusFilterVal(status);
    setOpenMobStatusFilter(false);
  };

  const handlemobStatusFilterChange = (status) => {
    setStatusFilterVal(status.value);
    setOpenMobStatusFilter(false);
  };

  const returnSelectedFilter = (val, indx) => {
    // if (val === "Status") {
    //   return (
    //     <div className="common-filter-dropdown">
    //       <div
    //         className="w-100"
    //         onClick={isMobileView ? handleMobileStatusDropdown : null}
    //       >
    //         <Dropdown
    //           isOpen={isMobileView ? false : openStatusFilter}
    //           toggle={(e) => handleOpenStatusFilter(e)}
    //         >
    //           <DropdownToggle caret>
    //             {statusFilterVal
    //               ? returnSelectedStatusLabel(statusFilterVal)
    //               : "All"}
    //           </DropdownToggle>
    //           <DropdownMenu>
    //             <DropdownItem
    //               onClick={(e) => handleStatusFilterChange(e, "all")}
    //               active={statusFilterVal === "all"}
    //             >
    //               All
    //             </DropdownItem>
    //             <DropdownItem
    //               onClick={(e) => handleStatusFilterChange(e, "active")}
    //               active={statusFilterVal === "active"}
    //             >
    //               Active
    //             </DropdownItem>
    //             <DropdownItem
    //               onClick={(e) => handleStatusFilterChange(e, "block")}
    //               active={statusFilterVal === "block"}
    //             >
    //               Blocked
    //             </DropdownItem>
    //           </DropdownMenu>
    //         </Dropdown>
    //       </div>
    //       {isMobileView && openMobStatusFilter && (
    //         <FilterModal
    //           handleMainFilterChange={handlemobStatusFilterChange}
    //           handleOpenParentDropdown={handleOpenParentDropdown}
    //           openMobParentFilter={openMobStatusFilter}
    //           optionForMainFilter={[
    //             { value: "all", label: "All" },
    //             { value: "active", label: "Active" },
    //             { value: "block", label: "Blocked" },
    //           ]}
    //           filterSelectionArr={filterSelectionArr}
    //           index={indx}
    //           onCancel={() => handleMobileStatusDropdown(false)} // Close modal on cancel
    //         />
    //       )}
    //     </div>
    //   );
    // }
    if (val === "Created") {
      return (
        <div className="common-filter-body-datepicker w-100">
          <DatePicker
            selected={createdDateRange[0]} // Start date of the range
            onChange={(update) => setCreatedDateRange(update)} // Updates start and end dates
            startDate={createdDateRange[0]}
            endDate={createdDateRange[1]}
            selectsRange
            dateFormat="d MMM yyyy"
            className="date-input-post2"
            placeholderText="Select date range"
            maxDate={new Date()}
          />
        </div>
      );
    } else if (val === "Last Login") {
      return (
        <div className="common-filter-body-datepicker w-100">
          <DatePicker
            selected={lastLoginDateRange[0]} // Start date of the range
            onChange={(update) => setLastLoginDateRange(update)} // Updates start and end dates
            startDate={lastLoginDateRange[0]}
            endDate={lastLoginDateRange[1]}
            selectsRange
            dateFormat="d MMM yyyy"
            className="date-input-post2"
            placeholderText="Select date range"
            maxDate={new Date()}
          />
        </div>
      );
    }
  };

  const handleMobileStatusDropdown = () => {
    setOpenMobStatusFilter(!openMobStatusFilter);
  };

  const handleOpenStatusFilter = (e) => {
    setOpenStatusFilter(!openStatusFilter);
  };

  const returnDeleteButton = (val, indx) => {
    return (
      filterSelectionArr.length > 1 && (
        <button
          className="common-filter-delete"
          onClick={() => handleDeleteFilter(val, indx)}
        >
          <img src={DeleteIcon} alt="Delete filter" />
        </button>
      )
    );
  };

  const handleDeleteFilter = (val, key, from) => {
    let newArr = [...filterSelectionArr];
    newArr.splice(key, 1);
    newArr &&
      newArr.map((row, indx) => {
        row.index = indx;
      });
    setFilterSelectionArr(newArr);

    if (val === "Created") {
      setCreatedDateRange([null, null]);
    }
    // else if (val === "Status") {
    //   setStatusFilterVal(null);
    // }
    else if (val === "Last Login") {
      setLastLoginDateRange([null, null]);
    }
  };

  const handleReset = () => {
    setFilterSelectionArr([{ index: 0, value: "" }]);
    setStatusFilterVal("all");
    setCreatedDateRange([null, null]);
    setLastLoginDateRange([null, null]);
    setOpenMobParentFilter([false, false, false]);
    setOpenStatusFilter(false);
    setOpenMobStatusFilter(false);
    onSearch(null);
  };

  const handleSearch = () => {
    const filterData = {
      // status_filter: statusFilterVal ? statusFilterVal : null,
      created_after_filter: createdDateRange[0]
        ? moment(createdDateRange[0]).format("YYYY-MM-DD")
        : null,
      created_before_filter: createdDateRange[1]
        ? moment(createdDateRange[1]).format("YYYY-MM-DD")
        : null,
      last_login_after_filter: lastLoginDateRange[0]
        ? moment(lastLoginDateRange[0]).format("YYYY-MM-DD")
        : null,
      last_login_before_filter: lastLoginDateRange[1]
        ? moment(lastLoginDateRange[1]).format("YYYY-MM-DD")
        : null,
    };

    const filteredData = Object.fromEntries(
      Object.entries(filterData).filter(
        ([key, value]) => value !== null && value !== undefined
      )
    );

    const finalFilterData =
      Object.keys(filteredData).length === 0 ? null : filteredData;

    if (onSearch) onSearch(finalFilterData);
  };

  const handleOpenParentDropdown = (index) => {
    // Toggle the modal for the clicked dropdown
    setOpenMobParentFilter(
      (prev) => prev.map((val, i) => (i === index ? !val : false)) // Toggle the state of the clicked index
    );
  };

  const renderFilter = (row, indx) => (
    <div key={indx}>
      {indx > 0 && <p className="FilterAnd-text">and</p>}

      <div className="common-filter-dropdown-select-div">
        <div
          className="mobile-view-click"
          onClick={() => {
            if (isMobileView) {
              handleOpenParentDropdown(indx); // Open the modal on click
            }
          }}
        >
          <Select
            key={`${indx}-${row.value}`}
            options={optionForMainFilter}
            classNamePrefix="common-filter-select"
            onChange={(option) => handleMainFilterChange(option, indx)}
            placeholder="Select"
            styles={customStyles}
            value={optionForMainFilter.find(
              (option) => option.value === row.value
            )}
            isClearable={false}
            isSearchable={false}
            isOptionDisabled={(option) =>
              filterSelectionArr.some((f) => f.value === option.value)
            }
            isDisabled={isMobileView ? true : false}
          />
        </div>

        {isMobileView && openMobParentFilter[indx] && (
          <FilterModal
            handleMainFilterChange={handleMainFilterChange}
            handleOpenParentDropdown={handleOpenParentDropdown}
            openMobParentFilter={openMobParentFilter[indx]}
            optionForMainFilter={optionForMainFilter}
            filterSelectionArr={filterSelectionArr}
            index={indx}
            value={row.value}
            onCancel={() => handleOpenParentDropdown(indx)} // Close modal on cancel
          />
        )}

        <div className="common-filter-second-dropdown-div">
          {returnSelectedFilter(row.value, indx)}
          {row.value && (
            <button
              className="common-filter-delete"
              onClick={() => handleDeleteFilter(row.value, indx)}
            >
              <img src={DeleteIcon} alt="Delete filter" />
            </button>
          )}
        </div>
      </div>
    </div>
  );

  const isSearchButtonEnabled = () => {
    return (
      filterSelectionArr.length > 0 &&
      filterSelectionArr.every((filter) => {
        if (filter.value === "Created") {
          return createdDateRange[0] && createdDateRange[1];
        }
        if (filter.value === "Last Login") {
          return lastLoginDateRange[0] && lastLoginDateRange[1];
        }
        return false;
      })
    );
  };

  return (
    <>
      <div className="common-filter-head">
        <h1 className="common-filter-header">Filter</h1>
        <p className="common-filter-reset" onClick={handleReset}>
          Reset
        </p>
      </div>
      <div className="common-filter-dropdown-select-div">
        {filterSelectionArr.map((row, indx) => renderFilter(row, indx))}
      </div>
      {filterSelectionArr.length < 2 && (
        <div className="Addbtn-common-filter">
          <div className="addfilter" onClick={handleAddFilter}>
            <img src={bluePlus} alt="Add filter" />
            <p className="Addbtn-common-filter-text">Add filter</p>
          </div>
        </div>
      )}
      <button
        className={`${
          isSearchButtonEnabled()
            ? "common-filter-search-btn"
            : "common-filter-btn-disable"
        }`}
        onClick={handleSearch}
        disabled={!isSearchButtonEnabled()}
      >
        Search
      </button>
    </>
  );
};

export default CustomFilter;
