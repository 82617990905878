import React from "react";
import styles from './logs.module.css'; 

export const Errorsvg = () => {
    return <svg className={styles.errorimg} width="18" height="18" viewBox="0 0 18 18">
    <g id="Group_4456" data-name="Group 4456" transform="translate(-324.314 -455)">
      <g id="Ellipse_2829" data-name="Ellipse 2829" transform="translate(324.314 455)" fill="#fff1f1" stroke="#ff4848" strokeWidth="1.5">
        <circle cx="9" cy="9" r="9" stroke="none"/>
        <circle cx="9" cy="9" r="8.25" fill="none"/>
      </g>
      <path id="Path_8311" data-name="Path 8311" d="M-19250.449-4210.9l3.775-3.777" transform="translate(16923.113 -10169.316) rotate(-45)" fill="none" stroke="#ff4848" strokeWidth="2"/>
      <path id="Path_10113" data-name="Path 10113" d="M-19250.449-4213.446l1.227-1.228" transform="translate(16924.914 -10164.118) rotate(-45)" fill="none" stroke="#ff4848" strokeWidth="2"/>
    </g>
  </svg>
  
}