import moment from "moment";
import React, { useEffect, useReducer } from "react";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import teamImg from "../../../Agency/assets/images/team-image.png";
import whiteplusimg from "../../../Agency/assets/images/white-plus.png";
import blueplusimg from "../../../assets/images/dashboard/cms/blue-plus.png";
import userdefaultImgOnError from "../../../assets/images/dashboard/accountprofile.png";
import Spinner from "../../../Components/commonComponents/Spinner";
import ActivateUserModal from "../../../Components/commonComponents/Team/ActivateUserModal";
import DeactivateUserModal from "../../../Components/commonComponents/Team/DeactivateUserModal";
import DeleteUserModal from "../../../Components/commonComponents/Team/DeleteUserModal";
import InviteUserModal from "../../../Components/commonComponents/Team/InviteUserModal";
import { Limit } from "../../../Components/commonComponents/Team/Limitmodal";
import ModuleTitleTooltip from "../../../Components/commonIcons/ModuleTitleTooltip";
import Header from "../../../Dashboard/Header";
import Leftpanel from "../../../Dashboard/Leftpanel";
import { errortoast } from "../../../Dashboard/Toaster";
import { Limittrigger } from "../../../Dashboard/User/Context";
import "../../../Dashboard/User/newUser.css";
import { Notallowedtouser } from "../../../Dashboard/User/Usernotalllowed";
import { useAppDashboardDataQuery } from "../../../services/appDashboardApiSlice";
import {
  useDeleteUserMutation,
  useInviteUserMutation,
  useUpdateUserStatusMutation,
  useUserListQuery,
} from "../../../services/teamApiSlice";
import { MetaTitle } from "../../../utils/commonUtils";
import DeleteIcon from "../../../Components/commonIcons/DeleteIcon";
import EditIcon from "../../../Components/commonIcons/EditIcon";

const AppTeam = () => {
  const [teamState, setTeamState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      submitValue: "Invite",
      spinner: false,
      data: [],
      allowedScreen: "",
      userData: null,
      userId: "",
      userEmail: "",
      role_id: localStorage.getItem("appuser_role_id"),
      addUser: false,
      dropRole: "",
      searchUser: "",
      user_email: "",
      mode: "addUser",
      userRoleId: null,
      subscriptionPlan: null,
      resellerPackageId: null,
      plan: null,
      addonCode: "",
      userCount: null,
      limitReached: false,
      addonQuantity: null,
      allowedUsers: 0,
      openDeletePopUp: false,
      deleteUserID: null,
      userIdTobeUpdate: "",
      openDeactivateUserPopup: false,
      openActivateUserPopup: false,
      selectedUserIdForEdit: null,
      appRightPanelExpand:
        localStorage.getItem("appSideBarCollapse") === true ||
        localStorage.getItem("appSideBarCollapse") === "true"
          ? true
          : false,
    }
  );
  const { refetch } = useAppDashboardDataQuery();
  const params = {
    app_id:
      appID &&
      appID !== "null" &&
      appID !== "undefined" 
        ? appID
        : btoa(localStorage.getItem("Appid")),
  };
  const {
    data: userList,
    isLoading: userListLoading,
    refetch: refetchUser,
    isFetching,
  } = useUserListQuery(params);
  const [inviteUser, { isLoading: inviteUserLoading }] =
    useInviteUserMutation();
  const [deleteUser, { isLoading: deleteUserLoading }] =
    useDeleteUserMutation();
  const [updateUserStatus, { isLoading: updateUserStatusLoading }] =
    useUpdateUserStatusMutation();

  let { appID } = useParams("appID");

  if (appID && appID !== "null" && appID !== "undefined") {
    localStorage.setItem("Appid", atob(appID));
  }

  const editForm = (id, email, role_id, mode) => {
    setTeamState({
      addUser: true,
      userId: id,
      userEmail: email,
      role_id: role_id,
      mode: mode,
      submitValue: "Done",
      selectedUserIdForEdit: role_id,
    });
  };
  const addUsertoggle = () => {
    setTeamState({
      addUser: !teamState.addUser,
      userId: "",
      userEmail: "",
      role_id: localStorage.getItem("appuser_role_id"),
      mode: "addUser",
      selectedUserIdForEdit: null,
    });
  };

  const addUserbutton = () => {
    setTeamState({ submitValue: "Invite" });
    if (teamState.allowedUsers === "") {
      setTeamState({ addUser: !teamState.addUser });
    } else {
      if (
        teamState.userCount >=
        teamState.addonQuantity + teamState.allowedUsers
      ) {
        setTeamState({ limitReached: true });
      } else {
        setTeamState({ addUser: !teamState.addUser });
      }
    }
  };

  const errorHandler = () => {
    setTeamState({ mode: "addUser", submitValue: "Invite" });
  };

  const setToDefault = () => {
    setTeamState({
      userEmail: "",
      mode: "addUser",
    });
  };

  //Form Submission
  const inviteUserFunction = (e) => {
    e.preventDefault();
    const dataParam = {
      id: teamState.userId,
      app_id: parseInt(localStorage.getItem("Appid")),
      email: teamState.userEmail.trim(),
      role_id: parseInt(teamState.role_id),
    };
    inviteUser(dataParam)
      .unwrap()
      .then((res) => {
        if (res.code === 200) {
          addUsertoggle();
          setToDefault();
          refetchUser();
        } else if (res.code === 403) {
          errorHandler();
          errortoast(res.data);
        } else if (res.code === 400) {
          errorHandler();
          errortoast(res.data.email[0]);
        } else if (res.code === 203) {
          errorHandler();
          errortoast(res.data);
        }
      })
      .catch((error) => {
        errortoast("Oops! Something went wrong.");
      });
    setTimeout(() => {
      refetch();
    }, 5000);
  };

  //Delete user PopUp
  const handleDeleteUserID = (id) => {
    setTeamState({
      deleteUserID: id,
      openDeletePopUp: !teamState.openDeletePopUp,
    });
  };

  //Delete user
  const deleteUserFunction = (e, from) => {
    if (from === "add") {
      const deleteparams = {
        workspace_id: "",
        id: teamState.deleteUserID,
        app_id: parseInt(localStorage.getItem("Appid")),
      };
      deleteUser(deleteparams)
        .unwrap()
        .then((res) => {
          if (res.code === 200) {
            setToDefault();
            refetchUser();
            setTeamState({ openDeletePopUp: false });
          } else if (res.code === 403) {
            errortoast(res.data);
          }
        })
        .catch((error) => {
          errortoast("Oops! Something went wrong.");
        });

      setTimeout(() => {
        refetch();
      }, 4000);
    } else {
      setTeamState({ openDeletePopUp: !teamState.openDeletePopUp });
    }
  };

  const limitreached_modal = {
    limitreached_status: teamState.limitReached,
    adduserrole: teamState.userRoleId,
    subscription_plan: teamState.subscriptionPlan,
    handlelimitstatus: (e) => setTeamState({ limitReached: false }),
    addonQuantity: teamState.addonQuantity,
    plan: teamState.plan,
    addon_code: teamState.addonCode,
    resellerPackageId: teamState.resellerPackageId,
  };
  const handleUserStatus = (e, status, from) => {
    const params = {
      workspace_id: "",
      id: teamState.userIdTobeUpdate,
      app_id: parseInt(localStorage.getItem("Appid")),
      status: status,
    };
    updateUserStatus(params)
      .unwrap()
      .then((res) => {
        if (res.code === 200) {
          refetchUser();
          setTeamState({
            openDeactivateUserPopup: false,
            openActivateUserPopup: false,
          });
          setToDefault();
        } else if (res.code === 203) {
          setTeamState({
            limitReached: true,
          });

          setTeamState({
            openDeactivateUserPopup: false,
            openActivateUserPopup: false,
          });

          let user_status_change_on_error = teamState.data.filter((res) => {
            if (res.id === teamState.userIdTobeUpdate) {
              res.status = 2;
            }
            return res;
          });
          setTeamState({ data: user_status_change_on_error });
        }
      })
      .catch((error) => {
        setTeamState({
          openDeactivateUserPopup: false,
          openActivateUserPopup: false,
        });
      });
  };

  const handleCustomeDeactivePopup = (userid) => {
    setTeamState({
      userIdTobeUpdate: userid,
      openDeactivateUserPopup: true,
    });
  };

  const handleCustomerActivePopup = (userid) => {
    setTeamState({
      userIdTobeUpdate: userid,
      openActivateUserPopup: true,
    });
  };

  const btnCancelCustomerDeactivePopup = () => {
    setTeamState({
      userIdTobeUpdate: "",
      openDeactivateUserPopup: false,
    });
  };

  const btnCancelCustomerActivePopup = () => {
    setTeamState({
      userIdTobeUpdate: "",
      openActivateUserPopup: false,
    });
  };

  const handleRightPanelExpand = (value) => {
    setTeamState({ appRightPanelExpand: value });
  };

  //Call table api onload
  useEffect(() => {
    document.title = MetaTitle("Team");
    if (userList && userList?.code === 200) {
      setTeamState({
        spinner: false,
        userData: userList.owner_data,
        data: userList.data,
        userRoleId:
          userList.appuser_role_id === null ? 1 : userList.appuser_role_id,
        role_id:
          userList.appuser_role_id === null ? 1 : userList.appuser_role_id,
        user_email: userList.user_email,
        subscriptionPlan: userList.subscription_plan,
        userCount: userList.user_count,
        addonQuantity: userList.addon_quantity,
        allowedUsers: userList.allowed_users,
        allowedScreen: userList.is_enable_screen,
        plan: userList.plan,
        addonCode: userList.addon_code,
        resellerPackageId: userList.reseller_package_id,
      });
    } else if (userList && userList?.code === 401) {
      setTeamState({
        spinner: false,
        userRoleId: userList.appuser_role_id,
      });
    } else {
      setTeamState({
        spinner: false,
      });
    }
  }, [userList]);

  return (
    <>
      <Header customclass={"header-responsive-new"} />
      <section className="payperapp-team_page">
        <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
        <div
          className={`right-panel-gap ${
            localStorage.getItem("appSideBarCollapse") &&
            localStorage.getItem("appSideBarCollapse") === "true"
              ? " right-panel-gap-expand"
              : ""
          }`}
        >
          <>
            {(localStorage.getItem("workspaceId") &&
              localStorage.getItem("workspaceId") !== "undefined" &&
              localStorage.getItem("workspaceId") !== "null" &&
              parseInt(localStorage.getItem("logged_in_user_role_id")) === 4) ||
            teamState.userRoleId === 4 ? (
              <Notallowedtouser />
            ) : (
              <>
                <div className="payperapp-team-tophead">
                  <ModuleTitleTooltip
                    title={"Team"}
                    text={"Invite team members to your app."}
                  />

                  {localStorage.getItem("agencyid") &&
                    localStorage.getItem("agencyid") != 1 &&
                    teamState.plan === "preview" &&
                    window.matchMedia("(min-width: 1200px)").matches &&
                    teamState.spinner === false && (
                      <div
                        style={{ marginLeft: "13px" }}
                        className="upgrade-feature"
                      >
                        <h4
                          style={{ textAlign: "unset" }}
                          className="upgrade-unavailable"
                        >
                          This feature is currently unavailable
                        </h4>
                        <p
                          style={{ textAlign: "unset" }}
                          className="upgrade-support"
                        >
                          Please contact Support for assistance.
                        </p>
                      </div>
                    )}
                  {teamState.plan !== "preview" && (
                    <div className="payperapp-team-topheadright">
                      {!teamState.spinner &&
                        teamState.plan !== "preview" &&
                        teamState.subscriptionPlan &&
                        teamState.allowedUsers &&
                        parseInt(teamState.userCount + 1) <=
                          (teamState.subscriptionPlan &&
                            parseInt(teamState.addonQuantity) +
                              parseInt(teamState.allowedUsers + 1)) && (
                          <p className="payperapp-team-usercount">
                            {parseInt(teamState.userCount + 1)}/
                            {teamState.subscriptionPlan &&
                              parseInt(teamState.addonQuantity) +
                                parseInt(teamState.allowedUsers + 1)}{" "}
                            <span className="payperapp-team-usercountspan">users</span>
                          </p>
                        )}
                      {teamState.plan !== "preview" &&
                        teamState.userRoleId !== 4 &&
                        !userListLoading && (
                          <div
                            className="payperapp-team-invitebtn"
                            onClick={addUserbutton}
                            style={{
                              backgroundColor: localStorage.getItem(
                                "button_background_color"
                              ),
                              color: localStorage.getItem("button_text_color"),
                              "--custom_color5":
                                localStorage.getItem("agencyid") == 1
                                  ? "#134bec"
                                  : localStorage.getItem(
                                      "button_hover_bgcolor"
                                    ),
                              "--custom_color4":
                                localStorage.getItem("agencyid") == 1
                                  ? "white"
                                  : localStorage.getItem(
                                      "button_hover_txtcolor"
                                    ),
                              "--custom_color6":
                                localStorage.getItem("agencyid") == 1
                                  ? "#3064f9"
                                  : localStorage.getItem(
                                      "button_hover_bgcolor"
                                    ),
                            }}
                          >
                            <img src={whiteplusimg} alt="invite" />
                            Invite
                          </div>
                        )}
                    </div>
                  )}
                </div>
                {localStorage.getItem("agencyid") &&
                  localStorage.getItem("agencyid") != 1 &&
                  teamState.plan === "preview" &&
                  window.matchMedia("(max-width: 1200px)").matches &&
                  !teamState.spinner && (
                    <div
                      style={{ marginLeft: "0px", marginTop: "15px" }}
                      className="upgrade-feature"
                    >
                      <h4
                        style={{ textAlign: "unset" }}
                        className="upgrade-unavailable"
                      >
                        This feature is currently unavailable
                      </h4>
                      <p
                        style={{ textAlign: "unset" }}
                        className="upgrade-support"
                      >
                        Please contact Support for assistance.
                      </p>
                    </div>
                  )}
                {teamState.plan !== "preview" &&
                  teamState.userRoleId !== 4 &&
                  !teamState.spinner && (
                    <div
                      className="payperapp-team-mobinvitebtn"
                      onClick={addUserbutton}
                      style={{
                        backgroundColor:
                          localStorage.getItem("button_background_color") &&
                          localStorage.getItem("button_background_color") !=
                            "undefined" &&
                          localStorage.getItem("button_background_color") !=
                            "null"
                            ? localStorage.getItem("button_background_color")
                            : "",
                        border:
                          "1px solid " +
                          localStorage.getItem("button_background_color"),
                        color: localStorage.getItem("button_text_color"),
                      }}
                    >
                      <img src={blueplusimg} alt="invite" />
                      Invite
                    </div>
                  )}

                <div
                  className="payperapp-team-maindiv"
                  style={{
                    position: "relative",
                    minHeight: userListLoading || isFetching ? ( "83vh") : ("unset"),
                  }}
                >
                  {userListLoading || isFetching ? (
                    <Spinner />
                  ) : (
                    <>
                      {teamState.plan === "preview" ? (
                        <div className="payperapp-team-upgrademain">
                          <div className="team-upgrade-left">
                            <h2>Build better, together</h2>
                            <p>Manage your team and clients in one place.</p>
                            <ul>
                              <li>Collaborate with team members.</li>
                              <li>Delegate responsibilities to others.</li>
                              <li>One-click access management.</li>
                              <li>Assign roles and limit visibility.</li>
                            </ul>
                            {localStorage.getItem("agencyid") == "" ||
                            localStorage.getItem("agencyid") == "undefined" ||
                            localStorage.getItem("agencyid") == "null" ||
                            localStorage.getItem("agencyid") == 1 ? (
                              <Link
                                to={
                                  localStorage.getItem("workspaceId") &&
                                  localStorage.getItem("workspaceId") !==
                                    "undefined"
                                    ? `/workspace/pricing/${btoa(
                                        localStorage.getItem("workspaceId")
                                      )}`
                                    : parseInt(
                                        localStorage.getItem(
                                          "website_technology"
                                        )
                                      ) === 4
                                    ? "/app/wordpress-pricing/" +
                                      btoa(localStorage.getItem("Appid"))
                                    : parseInt(
                                        localStorage.getItem(
                                          "website_technology"
                                        )
                                      ) === 5
                                    ? "/app/woocommerce-pricing/" +
                                      btoa(localStorage.getItem("Appid"))
                                    : parseInt(
                                        localStorage.getItem(
                                          "website_technology"
                                        )
                                      ) === 3
                                    ? "/app/custom-app-pricing/" +
                                      btoa(localStorage.getItem("Appid"))
                                    : parseInt(
                                        localStorage.getItem(
                                          "website_technology"
                                        )
                                      ) === 2
                                    ? "/app/web-to-app-pricing/" +
                                      btoa(localStorage.getItem("Appid"))
                                    : "/app/pricing"
                                }
                              >
                                Upgrade
                              </Link>
                            ) : null}
                          </div>
                          <div className="team-upgrade-right">
                            <img src={teamImg} alt="team-image" />
                          </div>
                        </div>
                      ) : (
                        <div className="">
                          <div className="payperapp-teamtable-head">
                            <div>User</div>
                            <div> Email</div>
                            <div>Role</div>
                            <div> Last seen</div>
                            <div>Status</div>
                          </div>
                          {/* Owner data */}
                          {teamState.userData &&
                            (teamState.dropRole === "" ||
                              teamState.dropRole === "1") &&
                            (teamState.searchUser === "" ||
                              teamState.userData.email
                                .toLowerCase()
                                .indexOf(teamState.searchUser.toLowerCase()) !==
                                -1) && (
                              <div className="payperapp-teamtable-list">
                                <div className="payperapp-user-name">
                                  <div
                                    className="payperapp-teammember-img"
                                   
                                  >
                                    {teamState.userData.profile_image === "" ||
                                    teamState.userData.profile_image ===
                                      null ? (
                                      <img
                                        src={userdefaultImgOnError}
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src =
                                            userdefaultImgOnError;
                                        }}
                                        alt="defaultImg"
                                        className="img-fluid"
                                        height="45"
                                        width="45"
                                      />
                                    ) : teamState.userData.profile_image &&
                                      teamState.userData.profile_image.indexOf(
                                        "ams"
                                      ) === -1 ? (
                                      <img
                                        src={teamState.userData.profile_image}
                                        key={teamState.userData.profile_image}
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src =
                                            userdefaultImgOnError;
                                        }}
                                        className="rounded-circle"
                                        alt="defaultimg"
                                        height="45"
                                        width="45"
                                      />
                                    ) : (
                                      <img
                                        src={
                                          process.env.REACT_APP_Image_Path +
                                          localStorage.getItem("user_id") +
                                          "/profile/" +
                                          teamState.userData.profile_image
                                        }
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src =
                                            userdefaultImgOnError;
                                        }}
                                        alt={teamState.userData.profile_image}
                                        key={teamState.userData.profile_image}
                                        className="rounded-circle"
                                        height="45"
                                        width="45"
                                      />
                                    )}
                                  </div>
                                  <div className="">
                                  <h6 className="payperapp-teammob-label">
                                    User
                                    </h6>
                                    <p className="payperapp-user-namediv">
                                      {teamState.userData.name}
                                    </p>
                                    <p className="payperapp-user-iddiv">
                                      User ID: {teamState.userData.id}
                                    </p>
                                  </div>
                                </div>
                                <div className="payperapp-team-email">
                                  <h6 className="payperapp-teammob-label">
                                    Email
                                  </h6>
                                  <p className="payperapp-team-email-id"> {teamState.userData.email}</p>
                                </div>
                                <div className="payperapp-team-Role">
                                  <h6 className="payperapp-teammob-label">
                                    Role
                                  </h6>
                                  <p className="payperapp-team-role-type">Owner</p>
                                </div>
                                <div className="payperapp-team-seen">
                                  <h6 className="payperapp-teammob-label">
                                    Last seen
                                  </h6>
                                  <p className="payperapp-team-seendate">
                                    {!teamState.userData.last_login
                                      ? "-"
                                      : moment(
                                          teamState.userData.last_login
                                        ).format("D MMMM YYYY, HH:mm")}
                                  </p>
                                  <p className="payperapp-team-seenip">
                                    {!teamState.userData.last_login_IP
                                      ? teamState.userData.registration_IP ===
                                        ""
                                        ? ""
                                        : "IP " +
                                          teamState.userData.registration_IP
                                      : "IP " +
                                        teamState.userData.last_login_IP}
                                  </p>
                                </div>
                                <div className="payperapp-team-statusmain">
                                  <h6 className="payperapp-teammob-label">
                                    Status
                                  </h6>
                                  <div className="payperapp-team-status">
                                    <div>
                                      <h5 className="teamstatusgreen">
                                        Active
                                      </h5>
                                    </div>
                                    <div></div>
                                  </div>
                                </div>
                              </div>
                            )}
                          {/* User data */}
                          {teamState.data.length > 0 &&
                            teamState.data
                              .filter((row) =>
                                teamState.dropRole === ""
                                  ? row.role_id
                                  : row.role_id === parseInt(teamState.dropRole)
                              )
                              .filter((res) =>
                                teamState.searchUser === ""
                                  ? res.invitation_email
                                  : res.invitation_email
                                      .toLowerCase()
                                      .indexOf(
                                        teamState.searchUser.toLowerCase()
                                      ) !== -1
                              )
                              .map((res) => (
                                <div
                                  key={res.id}
                                  className="payperapp-teamtable-list"
                                >
                                  <div className="payperapp-user-name">
                                    <div
                                      className="payperapp-teammember-img"
                                      style={{
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                      }}
                                    >
                                      {!res.image ? (
                                        <img
                                          src={userdefaultImgOnError}
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src =
                                              userdefaultImgOnError;
                                          }}
                                          alt="defaultImg"
                                          className="img-fluid"
                                          height="45"
                                          width="45"
                                        />
                                      ) : res.image &&
                                        res.image.indexOf("ams") === -1 ? (
                                        <img
                                          src={res.image}
                                          key={res.image}
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src =
                                              userdefaultImgOnError;
                                          }}
                                          className="rounded-circle"
                                          alt="defaultimg"
                                          height="45"
                                          width="45"
                                        />
                                      ) : (
                                        <img
                                          src={
                                            process.env.REACT_APP_Image_Path +
                                            res.user_id +
                                            "/profile/" +
                                            res.image
                                          }
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src =
                                              userdefaultImgOnError;
                                          }}
                                          alt={res.image}
                                          key={res.image}
                                          className="rounded-circle"
                                          height="45"
                                          width="45"
                                        />
                                      )}
                                    </div>
                                    <div className="">
                                    <h6 className="payperapp-teammob-label">
                                    User
                                    </h6>
                                      <p className="payperapp-user-namediv">
                                        {res.profile_name
                                          ? res.profile_name
                                          : "-"}
                                      </p>
                                      <p className="payperapp-user-iddiv">
                                        {res.user_id
                                          ? `User ID: ${res.user_id}`
                                          : null}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="payperapp-team-email">
                                    <h6 className="payperapp-teammob-label">
                                      Email
                                    </h6>
                                    <p className="payperapp-team-email-id"> {res.invitation_email}</p>
                                  </div>
                                  <div className="payperapp-team-Role">
                                    <h6 className="payperapp-teammob-label">
                                      Role
                                    </h6>
                                    <p className="payperapp-team-role-type">                
                                      {(() => {
                                        if (res.role_id === 1) {
                                          return "Owner";
                                        } else if (res.role_id === 2) {
                                          return "Admin";
                                        } else if (res.role_id === 3) {
                                          return "Manager";
                                        } else {
                                          return "User";
                                        }
                                      })()}
                                    </p>
                                  </div>
                                  <div className="payperapp-team-seen">
                                    <h6 className="payperapp-teammob-label">
                                      Last seen
                                    </h6>
                                    <div className="mob-last-seendiv">
                                      <div>
                                        <p className="payperapp-team-seendate">
                                          {res.last_login === ""
                                            ? "-"
                                            : moment(res.last_login).format(
                                                "D MMMM YYYY, HH:mm"
                                              )}
                                        </p>
                                        <p className="payperapp-team-seenip">
                                          {res.last_login_IP === ""
                                            ? res.registration_IP === ""
                                              ? ""
                                              : "IP " + res.registration_IP
                                            : "IP " + res.last_login_IP}
                                        </p>
                                      </div>
                                      <div className="mob-team-delete-div">
                                        {teamState.user_email !==
                                          res.invitation_email &&
                                          (res.role_id >=
                                          teamState.userRoleId && (
                                            <>
                                              {(res.status === 1 ||
                                                res.status === 2) && (
                                                <div
                                                  onClick={(e) =>
                                                    editForm(
                                                      res.id,
                                                      res.invitation_email,
                                                      res.role_id,
                                                      "editmode"
                                                    )
                                                  }
                                                >
                                                  <EditIcon color="#7782a1" />
                                                </div>
                                              )}
                                              <div
                                                onClick={(e) =>
                                                  handleDeleteUserID(res.id)
                                                }
                                              >
                                                <DeleteIcon color={"#7782a1"} />
                                              </div>
                                            </>
                                          ))}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="payperapp-team-statusmain">
                                    <h6 className="payperapp-teammob-label">
                                      Status
                                    </h6>
                                    <div className="payperapp-team-status">
                                      <div className="mob-payperapp-team-status">
                                        {res.status === 0 ? (
                                          <h5 className="teamstatusgrey">
                                            Invited
                                          </h5>
                                        ) : res.status === 1 ? (
                                          <>
                                            <h5 className="teamstatusgreen">
                                              Active
                                            </h5>
                                            {teamState.user_email !==
                                              res.invitation_email &&
                                            res.role_id >=
                                              teamState.userRoleId && (
                                              <span
                                                onClick={() =>
                                                  handleCustomeDeactivePopup(
                                                    res.id
                                                  )
                                                }
                                                className="mobileactivate"
                                              >
                                                Deactivate
                                              </span>
                                            ) }
                                          </>
                                        ) : res.status === 2 ? (
                                          <>
                                            <h5 className="teamstatusred">
                                              Inactive
                                            </h5>
                                            {teamState.user_email !==
                                              res.invitation_email &&
                                            res.role_id >=
                                              teamState.userRoleId && (
                                              <span
                                                onClick={() =>
                                                  handleCustomerActivePopup(
                                                    res.id
                                                  )
                                                }
                                                className="mobileactivate"
                                              >
                                                Activate
                                              </span>
                                            )}
                                          </>
                                        ) : null}
                                      </div>
                                      <div className="user-web-delete-icondiv">
                                        {teamState.user_email !==
                                          res.invitation_email &&
                                          (res.role_id >=
                                          teamState.userRoleId && (
                                            <>
                                              {(res.status === 1 ||
                                                res.status === 2) && (
                                                <div
                                                  onClick={(e) =>
                                                    editForm(
                                                      res.id,
                                                      res.invitation_email,
                                                      res.role_id,
                                                      "editmode"
                                                    )
                                                  }
                                                >
                                                  <EditIcon color={"#7782a1"} />
                                                </div>
                                              )}
                                              <div
                                                onClick={(e) =>
                                                  handleDeleteUserID(res.id)
                                                }
                                              >
                                                <DeleteIcon color={"#7782a1"} />
                                              </div>
                                            </>
                                          ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </>
        </div>
      </section>
      <InviteUserModal
        teamState={teamState}
        setTeamState={setTeamState}
        addUsertoggle={addUsertoggle}
        inviteUserFunction={inviteUserFunction}
        inviteUserLoading={inviteUserLoading}
      />

      <DeleteUserModal
        teamState={teamState}
        deleteUserLoading={deleteUserLoading}
        deleteUserFunction={deleteUserFunction}
      />
      <DeactivateUserModal
        teamState={teamState}
        updateUserStatusLoading={updateUserStatusLoading}
        btnCancelCustomerDeactivePopup={btnCancelCustomerDeactivePopup}
        handleUserStatus={handleUserStatus}
      />
      <ActivateUserModal
        teamState={teamState}
        updateUserStatusLoading={updateUserStatusLoading}
        btnCancelCustomerActivePopup={btnCancelCustomerActivePopup}
        handleUserStatus={handleUserStatus}
      />
      {/* Limit Reached modal  */}
      <Limittrigger.Provider value={limitreached_modal}>
        <Limit />
      </Limittrigger.Provider>
      {/* Limit Reached modal End */}
    </>
  );
};

export default AppTeam;
