import React, { useEffect, useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Reactcolorpicker from "../Reactcolorpicker";
import classnames from "classnames";
import { FormGroup, Label, Input } from "reactstrap";
import { Link } from "react-router-dom";
import NewReactcolorpicker from "../NewReactcolorpicker";
import {
  itemSelectionPopUp,
  itemSelectionPopUpForNonCMSWithoutChild,
  multiSelectionWithChildForCMS,
  pagesSelectionForWP,
} from "./allPopups";
import { pagesBlogCatPostForWPandWC } from "../../../Api/NewHomescreen/NewHomescreenApi";
import { pageAndPostDataPopUp } from "../../CmsBuilder/CommonPopUp";
import { cmsGetPagesApi } from "../../../Api/CmsApi/Cmsapi";

const HMSpages = ({
  homescreenData,
  setHomescreenData,
  blockIndx,
  handleStatus,
  handleIsRowOpen,
  isRowOpen,
  setIsRowOpen,
  handleDeleteItem,
  eachBlock,
  handleDuplicateItem,
  presetColors,
  provided,
}) => {
  const [gallerytab, setgalleryTab] = useState("7");
  const [isCustomize, setIsCustomize] = useState(false);
  const [pageData, setPageData] = useState([]);
  const [pageL2Data, setPageL2Data] = useState([]);
  const [pageL2ReserveData, setPageL2ReserveData] = useState([]);
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [pageSpinner, setPageSpinner] = useState(false);
  const [openPageLayoutPopUp, setOpenPageLayoutPopUp] = useState(false);
  // const [pageLayout, setPageLayout] = useState(
  //   homescreenData[blockIndx].style.layout
  // );
  const [isItemTypePopUp, setIsItemTypePopUp] = useState(false);
  const [pageSelectionArr, setPageSelectionArr] = useState(
    JSON.parse(JSON.stringify(homescreenData[blockIndx].value.selection_array))
  );
  const [breadcrumbReserveData, setBreadcrumbReserveData] = useState([]);
  const [showHeadingNameInput, setShowHeadingNameInput] = useState(false);
  useEffect(() => {
    if (parseInt(localStorage.getItem("website_technology")) === 3) {
      cmsGetPagesApi(
        setPageData,
        btoa(localStorage.getItem("Appid")),
        setPageSpinner
      );
    } else {
      pagesBlogCatPostForWPandWC("pages", setPageData, setPageSpinner);
    }
  }, []);

  const galleryToggle = (gtab) => {
    if (gallerytab !== gtab) {
      setgalleryTab(gtab);
      // this.setState({
      //   galleryTab: gtab,
      // });
    }
  };
  const handleCustomize = (e) => {
    setIsCustomize(!isCustomize);
  };
  const handlePageRowChange = (e) => {
    let newArr = [...homescreenData];
    if (e.target.value === "" || e.target.value === "e") {
      newArr[blockIndx].style.row = "";
    } else {
      let rowVal = parseInt(e.target.value);

      if (rowVal > 10 || rowVal < 1) {
        rowVal = 1;
      }
      newArr[blockIndx].style.row = rowVal;
    }
    setHomescreenData(newArr);
  };

  const handleShowHeading = (e) => {
    let newArr = [...homescreenData];

    newArr[blockIndx].style.show_heading = e.target.checked === true ? 1 : 0;
    setHomescreenData(newArr);
  };

  const handlePageTextColor = (color, blockIndx) => {
    let newArr = [...homescreenData];
    newArr[blockIndx].style.text_color = color;

    setHomescreenData(newArr);
  };

  const handlePageIconColor = (color, blockIndx) => {
    let newArr = [...homescreenData];
    newArr[blockIndx].style.icon_color = color;

    setHomescreenData(newArr);
  };

  const handleWholeSecbgColor = (color, blockIndx) => {
    let newArr = [...homescreenData];
    newArr[blockIndx].style.section_background_color = color;

    setHomescreenData(newArr);
  };

  const handleHeadingColor = (color, blockIndx) => {
    let newArr = [...homescreenData];
    newArr[blockIndx].style.heading_color = color;

    setHomescreenData(newArr);
  };

  const handleHeadingIconColor = (color, blockIndx) => {
    let newArr = [...homescreenData];
    newArr[blockIndx].style.heading_icon_color = color;

    setHomescreenData(newArr);
  };

  const handleHeadingIconbgColor = (color, blockIndx) => {
    let newArr = [...homescreenData];
    newArr[blockIndx].style.heading_icon_background_color = color;

    setHomescreenData(newArr);
  };

  const handleApplyToAllSec = (
    e,
    iconBgColor,
    iconColor,
    sectionBgColor,
    headingColor
  ) => {
    let newArr = [...homescreenData];
    if (e.target.checked) {
      newArr[blockIndx].apply_to_all_section_bool = 1;
      newArr &&
        newArr.map((row, indx) => {
          if (indx !== blockIndx) {
            switch (row.item_type) {
              case "product_categories":
                row.style.heading_color = headingColor;
                row.style.section_background_color = sectionBgColor;
                row.style.heading_icon_color = iconColor;
                row.style.heading_icon_background_color = iconBgColor;
                row.apply_to_all_section_bool = 0;
                break;
              case "blog_categories":
                row.style.heading_color = headingColor;
                row.style.section_background_color = sectionBgColor;
                row.style.heading_icon_color = iconColor;
                row.style.heading_icon_background_color = iconBgColor;
                row.apply_to_all_section_bool = 0;
                break;
              case "featured_products":
                row.style.heading_color = headingColor;
                row.style.section_background_color = sectionBgColor;
                row.style.heading_icon_color = iconColor;
                row.style.heading_icon_background_color = iconBgColor;
                row.apply_to_all_section_bool = 0;
                break;
              case "sale_products":
                row.style.heading_color = headingColor;
                row.style.section_background_color = sectionBgColor;
                row.style.heading_icon_color = iconColor;
                row.style.heading_icon_background_color = iconBgColor;
                row.apply_to_all_section_bool = 0;
                break;
              case "new_products":
                row.style.heading_color = headingColor;
                row.style.section_background_color = sectionBgColor;
                row.style.heading_icon_color = iconColor;
                row.style.heading_icon_background_color = iconBgColor;
                row.apply_to_all_section_bool = 0;
                break;
              case "recently_viewed_products":
                row.style.heading_color = headingColor;
                row.style.section_background_color = sectionBgColor;
                row.style.heading_icon_color = iconColor;
                row.style.heading_icon_background_color = iconBgColor;
                row.apply_to_all_section_bool = 0;
                break;
              case "cart":
                row.style.heading_color = headingColor;
                row.style.section_background_color = sectionBgColor;
                row.style.heading_icon_color = iconColor;
                row.style.heading_icon_background_color = iconBgColor;
                row.apply_to_all_section_bool = 0;
                break;
              case "sticky_blogs":
                row.style.heading_color = headingColor;
                row.style.section_background_color = sectionBgColor;
                row.style.heading_icon_color = iconColor;
                row.style.heading_icon_background_color = iconBgColor;
                row.apply_to_all_section_bool = 0;
                break;
              case "new_blogs":
                row.style.heading_color = headingColor;
                row.style.section_background_color = sectionBgColor;
                row.style.heading_icon_color = iconColor;
                row.style.heading_icon_background_color = iconBgColor;
                row.apply_to_all_section_bool = 0;
                break;
              case "pages":
                row.style.heading_color = headingColor;
                row.style.section_background_color = sectionBgColor;
                row.style.heading_icon_color = iconColor;
                row.style.heading_icon_background_color = iconBgColor;
                row.apply_to_all_section_bool = 0;
                break;
            }
          }
        });
    } else {
      newArr[blockIndx].apply_to_all_section_bool = 0;
    }
    setHomescreenData(newArr);
  };

  const handleItemTypePopUp = (e, from) => {
    let newArr = [...homescreenData];

    if (from === "add") {
      setPageSelectionArr(
        JSON.parse(
          JSON.stringify(homescreenData[blockIndx].value.selection_array)
        )
      );
    } else {
      newArr[blockIndx].value.selection_array = JSON.parse(
        JSON.stringify(pageSelectionArr)
      );
    }
    setHomescreenData(newArr);

    setIsItemTypePopUp(!isItemTypePopUp);
  };

  const handleL1Selection = (e, selectedData) => {
    let newArr = [...homescreenData];
    if (e.target.checked) {
      newArr[blockIndx].value.selection_array.push(selectedData._id);
    } else {
      newArr[blockIndx].value.selection_array = newArr[
        blockIndx
      ].value.selection_array.filter((id) => id !== selectedData._id);
    }

    setHomescreenData(newArr);
  };
  const handleL2Selection = (e, selectedData) => {
    let newArr = [...homescreenData];
    if (e.target.checked) {
      newArr[blockIndx].value.selection_array.push(selectedData._id.toString());
    } else {
      newArr[blockIndx].value.selection_array = newArr[
        blockIndx
      ].value.selection_array.filter(
        (id) => id !== selectedData._id.toString()
      );
    }

    setHomescreenData(newArr);
  };

  const handleChildTextClick = (e, childCatData, k) => {
    let newBreadcrumbReserveData = [...breadcrumbReserveData];
    newBreadcrumbReserveData.push(childCatData.child);
    setBreadcrumbReserveData(newBreadcrumbReserveData);
    let newBreadcrumb = [...breadcrumb];
    newBreadcrumb.push(childCatData.title);
    setBreadcrumb(newBreadcrumb);
    setPageL2ReserveData(JSON.parse(JSON.stringify(pageL2Data)));
    setPageL2Data(childCatData.child);
  };

  const handleTextParentItemClick = (e, childCatData, k) => {
    let newBreadcrumbReserveData = [];
    newBreadcrumbReserveData.push(childCatData.child);
    setBreadcrumbReserveData(newBreadcrumbReserveData);
    setPageL2Data(childCatData.child);
    let newBreadcrumb = [childCatData.title];
    setBreadcrumb(newBreadcrumb);
  };

  // const handleBreadcrumb = (e, val, indx) => {
  //   // let newBreadcrumb = [...breadcrumb];
  //   let newBreadcrumb = [];
  //   let breadcrumb2 = JSON.parse(JSON.stringify(breadcrumb));
  //   for (let i = 0; i < breadcrumb2.length; i++) {
  //     if (val === breadcrumb2[i]) {
  //       newBreadcrumb.push(breadcrumb2[i]);
  //       break;
  //     } else {
  //       newBreadcrumb.push(breadcrumb2[i]);
  //     }
  //   }
  //   // newBreadcrumb.push(childCatData.name);
  //   setBreadcrumb(newBreadcrumb);
  //   setPageL2Data(pageL2ReserveData);
  // };

  const handleBreadcrumb = (e, val, indx) => {
    // let newBreadcrumb = [...breadcrumb];

    let newBreadcrumb = [];
    let breadcrumb2 = JSON.parse(JSON.stringify(breadcrumb));
    for (let i = 0; i < breadcrumb2.length; i++) {
      if (val === breadcrumb2[i]) {
        newBreadcrumb.push(breadcrumb2[i]);
        break;
      } else {
        newBreadcrumb.push(breadcrumb2[i]);
      }
    }

    setBreadcrumb(newBreadcrumb);

    if (breadcrumb.length - 1 !== indx) {
      setPageL2Data(breadcrumbReserveData[indx]);
    }
    if (indx === 0) {
      setBreadcrumbReserveData(
        breadcrumbReserveData.filter((s, sidx) => indx === sidx)
      );
    }
  };

  //Saving parent Id for Page both
  const handlePopUpVal = (e, pageId, from) => {
    let newArr = [...homescreenData];
    if (e.target.checked) {
      if (from == "all") {
        newArr[blockIndx].value.selection_array = ["all"];
      } else {
        newArr[blockIndx].value.selection_array = newArr[
          blockIndx
        ].value.selection_array.filter((item) => item !== "all");

        newArr[blockIndx].value.selection_array.push(pageId.toString());
      }
    } else {
      newArr[blockIndx].value.selection_array = newArr[
        blockIndx
      ].value.selection_array.filter((id) => id !== pageId.toString());
    }
    setHomescreenData(newArr);
  };

  const handleEditHeadingname = (e) => {
    e.stopPropagation()
    setShowHeadingNameInput(!showHeadingNameInput);
   


    let newArr = [...isRowOpen];
    newArr = newArr.map((val, indx) => (false));

    setIsRowOpen(newArr);
  };

  const handleHeadingName = (e) => {
    let newArr = [...homescreenData];

    newArr[blockIndx].value.label = e.target.value;

    setHomescreenData(newArr);
  };

  return (
    <div className="accordionItem ">
      <h2
        className="accordionItemHeading allflex"
        draggableId={blockIndx.toString()}
        {...provided.dragHandleProps}
        onClick={(e) => handleIsRowOpen(e, blockIndx, setShowHeadingNameInput)}
      >
        <span className="homescreen-section-name">Pages{" "}
        <span className="catsection-name" onClick={(e) => handleEditHeadingname(e)}>
          {eachBlock.value.label === `Pages` ? "" : eachBlock.value.label}

          <svg
            
            xmlns="http://www.w3.org/2000/svg"
            width="16.263"
            height="16.263"
            viewBox="0 0 16.263 16.263"
          >
            <g
              id="Group_27285"
              data-name="Group 27285"
              transform="translate(-999.142 -1409.971) rotate(45)"
            >
              <g
                id="Path_82084"
                data-name="Path 82084"
                transform="translate(1713 281)"
                fill="#fff"
              >
                <path
                  d="M 2 17.29290008544922 L -0.5 14.79290008544922 L -0.5 2 C -0.5 1.724300026893616 -0.2757000029087067 1.5 0 1.5 L 4 1.5 C 4.275700092315674 1.5 4.5 1.724300026893616 4.5 2 L 4.5 14.79290008544922 L 2 17.29290008544922 Z"
                  stroke="none"
                />
                <path
                  d="M 0 2 L 0 14.58578014373779 L 2 16.58577919006348 L 4 14.58578014373779 L 4 2 L 0 2 M 0 1 L 4 1 C 4.552279949188232 1 5 1.447719573974609 5 2 L 5 15 L 2 18 L -1 15 L -1 2 C -1 1.447719573974609 -0.5522799491882324 1 0 1 Z"
                  stroke="none"
                  fill="#7782a1"
                />
              </g>
              <path
                id="Path_82085"
                data-name="Path 82085"
                d="M-4459-11434.725h6"
                transform="translate(6171 11721)"
                fill="none"
                stroke="#7782a1"
                stroke-width="1"
              />
            </g>
          </svg>
        </span>
        </span>
        <div className="homescreensection">
          {/* <img
            className="mr-3"
            src={
              require("../../../assets/images/dashboard/wordpress-grey.png")
                .default
            }
          /> */}
          <label className="switch" htmlFor={`checkstatus${blockIndx}`}>
            <input
              type="checkbox"
              id={`checkstatus${blockIndx}`}
              onChange={(e) => handleStatus(e, blockIndx)}
              checked={homescreenData[blockIndx].enable === 1 ? true : false}
            ></input>
            <span className="slider round"></span>
          </label>
        </div>
        <img
          src={
            isRowOpen[blockIndx] === true
              ? require("../../../assets/images/dashboard/cms/accordian-open.png")
                  .default
              : require("../../../assets/images/dashboard/cms/accordian-icon.png")
                  .default
          }
        />
      </h2>
      {showHeadingNameInput && (
        <div className="row">
          <div className="col-md-12">
            <input
              className="mt-20 homescreen-section-space"
              type="text"
              placeholder="Heading name"
              value={eachBlock.value.label}
              onChange={(e) => handleHeadingName(e)}
            ></input>
          </div>
        </div>
      )}
      {isRowOpen[blockIndx] === true && (
        <div className="accordionItemContent category-customize">
          {!isCustomize && (
            <div className="manage-category-layouts">
              <div className="category-customize-layout">
                <div>
                  <label class="input-box-checkbox">
                    <p>Heading</p>
                    <input
                      type="checkbox"
                      onChange={(e) => handleShowHeading(e)}
                      checked={
                        homescreenData[blockIndx].style.show_heading === 1
                          ? true
                          : false
                      }
                    />
                    <span class="homescreen-checkmark"></span>
                  </label>
                </div>

                <div>
                  <Link onClick={(e) => handleCustomize(e)}>
                    <svg width="15" height="15" viewBox="0 0 15 15">
                      <g
                        id="Group_27322"
                        data-name="Group 27322"
                        transform="translate(-790 -429)"
                      >
                        <g
                          id="Rectangle_19968"
                          data-name="Rectangle 19968"
                          transform="translate(790 429)"
                          fill="none"
                          stroke="#7782a1"
                          stroke-width="1"
                        >
                          <path
                            d="M1,0H14a1,1,0,0,1,1,1V7a2,2,0,0,1-2,2H2A2,2,0,0,1,0,7V1A1,1,0,0,1,1,0Z"
                            stroke="none"
                          />
                          <path
                            d="M1,.5H14a.5.5,0,0,1,.5.5V7A1.5,1.5,0,0,1,13,8.5H2A1.5,1.5,0,0,1,.5,7V1A.5.5,0,0,1,1,.5Z"
                            fill="none"
                          />
                        </g>
                        <g
                          id="Rectangle_19969"
                          data-name="Rectangle 19969"
                          transform="translate(795 437)"
                          fill="none"
                          stroke="#7782a1"
                          stroke-width="1"
                        >
                          <path
                            d="M0,0H5A0,0,0,0,1,5,0V4.5A2.5,2.5,0,0,1,2.5,7h0A2.5,2.5,0,0,1,0,4.5V0A0,0,0,0,1,0,0Z"
                            stroke="none"
                          />
                          <path
                            d="M.5.5h4a0,0,0,0,1,0,0v4a2,2,0,0,1-2,2h0a2,2,0,0,1-2-2V.5A0,0,0,0,1,.5.5Z"
                            fill="none"
                          />
                        </g>
                        <path
                          id="Path_82125"
                          data-name="Path 82125"
                          d="M-3-17610.645H12"
                          transform="translate(793 18045.145)"
                          fill="none"
                          stroke="#7782a1"
                          stroke-width="1"
                        />
                        <path
                          id="Path_82126"
                          data-name="Path 82126"
                          d="M.614-17618v4"
                          transform="translate(794.886 18047)"
                          fill="none"
                          stroke="#7782a1"
                          stroke-width="1"
                        />
                        <path
                          id="Path_82128"
                          data-name="Path 82128"
                          d="M.614-17618v4"
                          transform="translate(798.886 18047)"
                          fill="none"
                          stroke="#7782a1"
                          stroke-width="1"
                        />
                      </g>
                    </svg>
                    Customize
                  </Link>
                </div>
              </div>

              <div className="category-slders-option">
                <Nav tabs className="row-column-tabs-homescreen">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: gallerytab === "7" })}
                      onClick={(e) => galleryToggle("7")}
                    >
                      <svg width="18" height="20" viewBox="0 0 18 20">
                        <path
                          id="Exclusion_352"
                          data-name="Exclusion 352"
                          d="M-528,14008h-16a2,2,0,0,1-2-2v-14a2,2,0,0,1,2-2h16a2,2,0,0,1,2,2v14A2,2,0,0,1-528,14008Zm-7-15v12h6v-12Zm-8,0v12h6v-12Z"
                          transform="translate(14008.001 545.999) rotate(90)"
                        />
                      </svg>
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={gallerytab} className="gallery-tabs">
                  <TabPane tabId="7">
                    <input
                      type="number"
                      required
                      className="homescreen-category-layout"
                      min="1"
                      max="10"
                      value={homescreenData[blockIndx].style.row}
                      onChange={(e) => {
                        handlePageRowChange(e);
                      }}
                    />
                    <input
                      type="range"
                      id="rangeid3"
                      className="slider-range"
                      min="1"
                      max="10"
                      step="1"
                      value={homescreenData[blockIndx].style.row}
                      onChange={(e) => {
                        handlePageRowChange(e);
                      }}
                    />
                  </TabPane>
                </TabContent>
              </div>

              <div className="row mt-30">
                <div className="col-md-6">
                  <label>Text Color</label>
                  <NewReactcolorpicker
                    colorname={homescreenData[blockIndx].style.text_color}
                    onChange={(color) => handlePageTextColor(color, blockIndx)}
                    classselection={"fixback"}
                    valuecontrol={true}
                     presetColors={presetColors}
                  />
                </div>

                <div className="col-md-6">
                  <label>Icon Color</label>
                  <NewReactcolorpicker
                    colorname={homescreenData[blockIndx].style.icon_color}
                    onChange={(color) => handlePageIconColor(color, blockIndx)}
                    classselection={"fixback"}
                    valuecontrol={true}
                     presetColors={presetColors}
                  />
                </div>
              </div>

              <div className="row mt-20">
                <div
                  className="col-md-12"
                  onClick={(e) => handleItemTypePopUp(e)}
                >
                  <div className="category-select-link">
                    {homescreenData[blockIndx].value.selection_array.length ===
                    0 ? (
                      <p style={{ color: "#bcc1ce" }}>Select pages</p>
                    ) : (
                      <span style={{ color: "#1B2952" }}>
                        {homescreenData[blockIndx].value.selection_array[0] ===
                        "all"
                          ? "All pages selected"
                          : homescreenData[blockIndx].value.selection_array
                              .length + " pages selected"}
                      </span>
                    )}
                    <div className="select-cat-popup">
                      <img
                        src={
                          require("../../../assets/images/dashboard/wordpress-grey.png")
                            .default
                        }
                      />

                      <img
                        src={
                          require("../../../assets/images/dashboard/cms/select-parent.png")
                            .default
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {isCustomize && (
            <div className="customize-options">
              <div
                className="hide-homecustomize-options"
                onClick={(e) => handleCustomize(e)}
              >
                <svg width="11.06" height="11.06" viewBox="0 0 11.06 11.06">
                  <path
                    id="Union_1805"
                    data-name="Union 1805"
                    d="M13951,4311l-5,5,5-5-5-5,5,5,5-5-5,5,5,5Z"
                    transform="translate(-13945.471 -4305.469)"
                    fill="none"
                    stroke="#7782a1"
                    stroke-width="1.5"
                  />
                </svg>
                <p>Close</p>
              </div>

              <div className="row mt-20">
                <div className="col-md-6">
                  <label>Background Color</label>
                  <NewReactcolorpicker
                    colorname={
                      homescreenData[blockIndx].style.section_background_color
                    }
                    onChange={(color) =>
                      handleWholeSecbgColor(color, blockIndx)
                    }
                    classselection={"fixback"}
                    valuecontrol={false}
                     presetColors={presetColors}
                  />
                </div>

                <div className="col-md-6">
                  <label>Heading color</label>
                  <NewReactcolorpicker
                    colorname={homescreenData[blockIndx].style.heading_color}
                    onChange={(color) => handleHeadingColor(color, blockIndx)}
                    classselection={"fixback"}
                    valuecontrol={true}
                     presetColors={presetColors}
                  />
                </div>
              </div>

              <div className="row mt-20">
                <div className="col-md-6">
                  <label>Icon background color</label>
                  <NewReactcolorpicker
                    colorname={
                      homescreenData[blockIndx].style
                        .heading_icon_background_color
                    }
                    onChange={(color) =>
                      handleHeadingIconbgColor(color, blockIndx)
                    }
                    classselection={"fixback"}
                    valuecontrol={false}
                     presetColors={presetColors}
                  />
                </div>

                <div className="col-md-6">
                  <label>Icon color</label>
                  <NewReactcolorpicker
                    colorname={
                      homescreenData[blockIndx].style.heading_icon_color
                    }
                    onChange={(color) =>
                      handleHeadingIconColor(color, blockIndx)
                    }
                    classselection={"fixback"}
                    valuecontrol={true}
                     presetColors={presetColors}
                  />
                </div>
                <div className="col-md-12">
                  <label class="input-box-checkbox">
                    <p>Apply to all sections</p>
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        handleApplyToAllSec(
                          e,
                          homescreenData[blockIndx].style
                            .heading_icon_background_color,
                          homescreenData[blockIndx].style.heading_icon_color,
                          homescreenData[blockIndx].style
                            .section_background_color,
                          homescreenData[blockIndx].style.heading_color
                        )
                      }
                      checked={
                        homescreenData[blockIndx].apply_to_all_section_bool ===
                        1
                          ? true
                          : false
                      }
                    />
                    <span class="homescreen-checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          )}
          <div className="homescreen-delete-option">
            <p onClick={(e) => handleDuplicateItem(e, eachBlock)}>
              <img
                src={
                  require("../../../assets/images/dashboard/cms/duplicate.png")
                    .default
                }
              />
              Duplicate
            </p>
            <p onClick={(e) => handleDeleteItem(e, blockIndx)}>
              <img
                src={
                  require("../../../assets/images/dashboard/cms/delete.png")
                    .default
                }
              />
              Delete
            </p>
          </div>
        </div>
      )}

      {isItemTypePopUp === true &&
        parseInt(localStorage.getItem("website_technology")) !== 3 &&
        itemSelectionPopUpForNonCMSWithoutChild(
          isItemTypePopUp,
          handleItemTypePopUp,
          pageData,
          handlePopUpVal,
          homescreenData[blockIndx].value.selection_array,
          "Pages"
        )}

      {isItemTypePopUp === true &&
        parseInt(localStorage.getItem("website_technology")) === 3 &&
        multiSelectionWithChildForCMS(
          handleItemTypePopUp,
          isItemTypePopUp,
          pageData,
          pageSpinner,
          breadcrumb,
          handleBreadcrumb,
          false,
          true,
          pageL2Data,
          handleL1Selection,
          handleL2Selection,
          handleTextParentItemClick,
          handleChildTextClick,
          homescreenData[blockIndx].value.selection_array,
          "Pages"
        )}
    </div>
  );
};

export default HMSpages;
