import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiEndPoints from "../utils/apiEndpoints";

export const teamApiSlice = createApi({
  reducerPath: "team",
  // keepUnusedDataFor:1800,
  baseQuery: fetchBaseQuery({
    baseUrl: apiEndPoints.appUrl,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("amstoken")}`
      );
      return headers;
    },
  }),
  tagTypes: ["userlist", "deleteInvition"],

  endpoints: (builder) => ({
    userList: builder.query({
      query: (params) =>
        "user-management/" +
        params.app_id +
        "?page=1&limit=100&search_field=&timestamp=" +
        new Date().getTime() +
        "&agency_id=" +
        (parseInt(localStorage.getItem("agencyid")) !== 1 &&
        localStorage.getItem("agencyid") &&
        localStorage.getItem("agencyid") !== "null" &&
        localStorage.getItem("agencyid") !== "undefined"
          ? btoa(localStorage.getItem("agencyid"))
          : btoa(null)),
      providesTags: ["userlist", "deleteInvition"],
    }),
    inviteUser: builder.mutation({
      query: (data) => ({
        url: "user-management",
        method: "POST",
        body: data,
      }),
      providesTags: ["userlist", "deleteInvition"],
    }),
    deleteUser: builder.mutation({
      query: (data) => ({
        url: "user-management/delete",
        method: "POST",
        body: data,
      }),
      providesTags: ["userlist", "deleteInvition"],
    }),
    updateUserStatus: builder.mutation({
      query: (data) => ({
        url: "user-management/update-status",
        method: "POST",
        body: data,
      }),
      providesTags: ["userlist", "deleteInvition"],
    })
  }),
});

export const {
  useUserListQuery,
  useInviteUserMutation,
  useDeleteUserMutation,
  useUpdateUserStatusMutation
} = teamApiSlice;
