import React from 'react'
import "./pagenotfound.css";
import Header from "../../../Dashboard/Header";
import { Link } from "react-router-dom";

import productnotfoundweb from "../../../assets/images/dashboard/productnotfound404web.png";
import productnotfoundmob from "../../../assets/images/dashboard/productnotfound404mob.png";
const PagenotFound = () => {
  return (
    <div> <div>
    <Header customclass={"header-responsive-new"} />
    <section className='pagenotfound-mainsection'>
      <div>
        <div className='notFound-div-main'>
            <h2 className='notfound-head'>Oops, someone messed up.</h2>
            <p className='notfount-para'>Hopefully, its not you. Start over maybe?</p>
            <Link 
              to={
                (
                  !localStorage.getItem("agencyid") == 
                  localStorage.getItem("agencyid") === "undefined" ||
                  localStorage.getItem("agencyid") === "null" ||
                  localStorage.getItem("agencyid") == 1
                ) ? 
                  "/home" : "/apps"
                }
              className="gotohome-btn"
            >
            Home</Link>
        </div>
        <img className="notfound-web-img" src={productnotfoundweb}/>
        <img className="notfound-mob-img" src={productnotfoundmob}/>
        </div>
    </section>
  </div></div>
  )
}

export default PagenotFound