import React, { useEffect, useState, Suspense } from "react";
import Header from "../../Header";
import Leftpanel from "../../Leftpanel";
import "../IOS/newPublish.css";
import imagebackground from "../../../assets/images/dashboard/cms/uploadbackground.png";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalFooter,
  Media,
} from "reactstrap";



import { Link } from "react-router-dom";



export const NewPublishAndroid = () => {

  // const [submitreviewloaderimage] = useState(require( "../../../assets/images/signup/Loader_GIF.gif").default);
  const [opencreationpopup, setPopup] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [data, setData] = useState(null);
 
  const [appRightPanelExpand, setAppRightPanelExpand] = useState( localStorage.getItem("appSideBarCollapse") === true ||
	localStorage.getItem("appSideBarCollapse") === "true"
	  ? true
	  : false);




  const handleRightPanelExpand = (value) => {

		setAppRightPanelExpand(value);
	  }


    return (
      <>
        <Header customclass={"header-responsive-new"} />
        <section className="newAdvertisment-page">
        <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
          <div
            className={`right-panel-gap ${localStorage.getItem("appSideBarCollapse") !== undefined &&
              localStorage.getItem("appSideBarCollapse") !== null &&
              localStorage.getItem("appSideBarCollapse") === "true"
              ? " right-panel-gap-expand"
              : ""
              }`}
          >
            <div className="publish-head">
              <h1 className='publish-headh'>Publish
                <p className=" consent-tooltip">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                    <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                      <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                        <circle cx="9" cy="9" r="9" stroke="none" />
                        <circle cx="9" cy="9" r="8.25" fill="none" />
                      </g>
                      <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5" />
                      <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5" />
                    </g>
                  </svg>
                  <p className="consent-tooltipsubheader">
                    Define how users enter the app.
                  </p>
                </p></h1>
                <h1 className='mob-publish-headh'>Android</h1>
            
            </div>
            <div className="consent-modal-tab">
              <div className='publish-modal-tab' style={{lineHeight:"38px" }}>

                <Link  to="/app/publish/ios" 
                style={{ 
                  "color": localStorage.getItem('agencyid') == 1 ? 
                  "#3064f9" : localStorage.getItem('agency_primary_color'), 
                  "--custom_color4": localStorage.getItem('agencyid') == 1 ? 
                  "#3064f9" : localStorage.getItem('agency_primary_color'),
                   "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color') }}>iOS</Link>
                <Link className="active" to="/app/publish/android" style={{ "--custom_color4": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color'), "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color') }}>Android</Link>
              </div>
            



            </div>
            <div className="publish-main-div">
             
              <div className=''>
                <div className='publish-update-page '>
                  <div className='publish-update-page-left'>
                  <div className='publish-update-left-div'>
                    <h2>Effortless app publishing</h2>

                    <p>Submit your app updates on Google Play Store with ease and efficiency.</p>
                    <ul>
                      <li>Share app updates directly on the Play Store listing page.</li>
                      <li>Track your app publishing history for better management.</li>
                      <li>Resolve submission errors quickly and seamlessly.</li>
                      <li>Celebrate successful submissions with instant notifications.</li>
                    </ul>

                    <button className="comming-soon-publish-btn" style={{
                      cursor:"default",
                    }}>Coming soon</button>
                    </div>
                  </div>
                  <div className='publish-update-page-right'>

                    <img
                      src={
                        require("../../../assets/images/dashboard/publish/publishandroidUpgrade.png")
                          .default
                      }
                      alt="publishUpgrade-image"
                    />
                  </div>
                </div>
              </div>
            
            
            </div>
          </div>
        
     



        

    













        </section>
      </>
    )
};
