import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// import Notfound from '../../Signup/Notfound';
import PagenotFound from '../../Layouts/Pages/404Page/PageNotFound';

let isWhiteLabelCustomer = parseInt(localStorage.getItem("agencyid")) > 1;

const ProtectedRoute = ({ component: Component, ...rest}) => (
  <Route
  {...rest}
    render={(props) =>
        isWhiteLabelCustomer ? (
            <PagenotFound/>
      ) : (
        <Component {...props} />
      )
    }
  />
);

export default ProtectedRoute;