import React, {useState, useEffect} from "react";
import "./assets/css/common.css";
import Header from "./../Dashboard/Header";
import { Link, useParams } from "react-router-dom";
import LeftPanel from "./LeftPanel";
import {
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Progress,
} from "reactstrap";
import {
    getAgencyDashboard,getAgencyDataGraph,
    paddleAgencyChangePlan
} from "../Api/Agency/AgencyApi";
import {Savedefaultdata} from "../Api/Dashboard/DashboardApi";
import styles from "./assets/css/chart.module.css";
import moment from "moment";
import NewSpinner from "./NewSpinner";
import DefaultAgencyLogo from "./DefaultAgencyLogo";
import DomainVerifiedStrip from "./Domains/DomainVerifiedStrip";
import { Bar } from 'react-chartjs-2';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from "reactstrap";
import SupportSection from "../Components/dashboardCards/SupportSection/SupportSection";
import { isJSON } from "../utils/commonUtils";
import { SUPPORTCARDDETAILS } from "../Constants/dashboardSupportSection/dashboardSupport";

const AgencyDashboard = () =>{


    const [spinner,setSpinner] = useState(true);
    const [graphload,setGraphLoad] = useState(true);
    const [logoimage,setLogoImage] = useState("");
    const [orgname,setOrgName] = useState("");
    const [subdomain,setSubDomain] = useState("");
    const [orgdate,setOrgDate] = useState("");
    const [planname,setPlanName] = useState("");
    const [freesubscribercount,setFreeSubscriberCount] = useState(0);
    const [paidsubscribercount,setPaidSubscriberCount] = useState(0);
    const [androidappscount,setAndroidAppsCount] = useState(0);
    const [iosappscount,setIosAppsCount] = useState(0);
    const [googleappscount,setGoogleAppsCount] = useState(0);
    const [appleappscount,setAppleAppsCount] = useState(0);
    const [appscount,setAppsCount] = useState(0);
    const [customerCount,setCustomerCount] = useState(0);
    const [ownerdata,setOwnerData] = useState(null);
    const [usersdata,setUsersData] = useState([]);
    const [domainverified,setDomainVerified] = useState(false);
    const [emaildataenable,setEmailDataEnable] = useState(false);
    const [socialloginenable,setSocialLoginEnable] = useState(false);
    const [pluginenable,setPluginEnable] = useState(false);
    const [supportenable,setSupportEnable] = useState(false);
    const [billingenable,setBillingEnable] = useState(false);
    const [rightpanelexpand,setRightPanelExpand] = useState(true);
    const [setupcount,setSetupCount] = useState(0);
    const [generalsettingenable,setGeneralSettingEnable] = useState(false);
    const [openstatDropdown,setOpenStatDropdown] = useState(false);
    const [openMobileAppDropdown,setopenMobileAppDropdown] = useState(false);
    const [appsdownload,setAppsDownload] = useState(0);
    const [datefilter,setdatefilter] = useState("7 days");
    const [periods,setperiods] = useState([]);
    const [graphdata,setgraphdata] = useState([]);
    const [subscriptioninfo,setSubscription]= useState('');
    const [updatedat,setUpdatedAt]= useState('');
    const [userinfo,setUserInfo] = useState('');
    const [dashboarddata,setDashboardData] = useState('');
    const [openIntervalDrop,setOpenIntervalDrop] = useState(false);
    const [intervalFilterVal,setIntervalFilterVal] = useState('day');
    const [openMobIntervalPopUp,setOpenMobIntervalPopUp] = useState(false);
    const [openReactivatePopUp, setOpenReactivatePopUp] = useState(false);
    const [submitValReactivate, setSubmitValReactivate] = useState("Confirm");
    const [reactivateLoader, setReactivateLoader] = useState("d-none");
   

    const localuserid = localStorage.getItem("user_id");
	//const agencyimgpath = process.env.REACT_APP_Image_Path+localuserid+'/'+process.env.REACT_APP_Agency_Profile;
	const agencyimgpath = process.env.REACT_APP_Image_Path + "agencies/" + localStorage.getItem("agency_id") + process.env.REACT_APP_Agency_Profile;

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false,
            position: 'top',
          },
          title: {
            display: false,
            text: 'Chart.js Bar Chart',
            fontFamily: "Axiforma-Regular",
          },
          
        },
      };

      const labels = periods;

      const data = {
        labels,
        datasets: [
          {
            data: graphdata,
            backgroundColor: 'rgba(110, 148, 255, 1)',
            fontFamily: "Axiforma-Regular",
          },
          
        //   {
        //     label: 'Dataset 2',
        //     data: [126, 127, 923, 250, 270, 725, 401],
        //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
        //   },
        ],
      };

    let { agencyID } = useParams("agencyID");

    let singlecountval = parseFloat(100 / 7). toFixed(2);

    useEffect(()=>
    {

        if(agencyID !== '' && agencyID !== null && agencyID !== undefined)
        {            

            getAgencyDashboard(agencyID, setSpinner,setAppsCount,setCustomerCount, setLogoImage,setOrgName, setSubDomain, setOrgDate, setPlanName, setFreeSubscriberCount, setPaidSubscriberCount, setAndroidAppsCount, setIosAppsCount, setGoogleAppsCount, setAppleAppsCount, setOwnerData, setUsersData, setDomainVerified, setEmailDataEnable, setSocialLoginEnable, setPluginEnable, setSupportEnable, setBillingEnable, setSetupCount, setGeneralSettingEnable,setAppsDownload,setSubscription,setUpdatedAt,setUserInfo,setDashboardData);
            getAgencyDataGraph(agencyID,datefilter,setperiods,setgraphdata,setGraphLoad,intervalFilterVal);
        }

        const params = {
            default_app_id: null,
            default_workspace_id: null,
            default_agency_id: atob(agencyID),
          };
      
          Savedefaultdata(params);

        document.title = "Dashboard | AppMySite";

    }, []);

    const handleRightPanelExpand = (rightpanelvalue) => {
        setRightPanelExpand(rightpanelvalue);
    }
    const handleStatdropdown = () => 
    {
        setOpenStatDropdown(!openstatDropdown);
    }

    const handleIntervaldropdown = () => {
      setOpenIntervalDrop(!openIntervalDrop);  
    }

    const handleMobileAppDropdown = () => 
      {
          setopenMobileAppDropdown(!openMobileAppDropdown);
      };
      const handleMobIntervalPopUp = () => 
      {
          setOpenMobIntervalPopUp(!openMobIntervalPopUp);
      };


    const clickdatafiltervalue = (e,valuedate) => 
    {   
        if(valuedate!==datefilter){

        
        let newFilterVal=intervalFilterVal;
        setGraphLoad(true);
        setopenMobileAppDropdown(false);
        e.preventDefault();
        setdatefilter(valuedate);
    
        newFilterVal = valuedate==="today"?"hour": ( valuedate==="6 month" || valuedate==="12 month" )?"month":"day";
        setIntervalFilterVal(newFilterVal);
        getAgencyDataGraph(agencyID,valuedate,setperiods,setgraphdata,setGraphLoad,newFilterVal);
        }
     
    }

    const handleIntervalFilter = (from) => {
        if(from!==intervalFilterVal){
            setOpenMobIntervalPopUp(false);
            setGraphLoad(true);
            setopenMobileAppDropdown(false);
            setIntervalFilterVal(from);

            getAgencyDataGraph(agencyID,datefilter,setperiods,setgraphdata,setGraphLoad,from);
        }

    }

      // Redirect fun
  const redirectFun = () => {
   
    window.open("/transaction-successful","_self");
  
   
 };

    const handleReactivatePopUp = () =>{
        setOpenReactivatePopUp(!openReactivatePopUp)
      }

      const handleReactivateSubscription = () =>{
        const paddleParam = {
            workspace_id:null,
            app_id:null,
            agency_id:parseInt(atob(agencyID)),
            deal_id: null, //Optional
            order_type: 2, //Optional
            price_id: "agency_preview",
            quantity: 1, //Optional
            type: 1, //Optional
            downgrade_at_term_end: 0,
            app_count:0, //Optional
            user_count:0 //Optional
          };
          
         setReactivateLoader("d-block");
         setSubmitValReactivate("");
          paddleAgencyChangePlan(paddleParam,redirectFun,setReactivateLoader,setSubmitValReactivate,setOpenReactivatePopUp); 
    }

    return(
    <div className="org-module">
        
        <Header />
        <div className="org-structure">
            <LeftPanel rightpanelbody={handleRightPanelExpand}/>
            <div className={(localStorage.getItem('sidebaserotate') !== null && localStorage.getItem('sidebaserotate') !== undefined &&
            localStorage.getItem('sidebaserotate') === 'true') ? "org_background org_background-expand"
            : "org_background "}>
                      

                        <div className="org-dashboard-head">
                            <div className="agency-headings">
                                <div className="agency-dashboard-header-div">
                                    <h2>Dashboard</h2>
                                    <p class="agency-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                    <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                                        <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                                        <circle cx="9" cy="9" r="9" stroke="none"/>
                                        <circle cx="9" cy="9" r="8.25" fill="none"/>
                                        </g>
                                        <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"/>
                                        <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                    </g>
                                    </svg><p class="agency-tooltipsubheader">Your agency dashboard is the command center for managing your agency and your branded white-label app-building platform. Access key agency information, track subscriptions, and monitor essential stats and analytics.</p></p>
                                    </div>
                                    <h6>{updatedat ?
											'Updated ' + moment(updatedat).format("D MMMM YYYY, HH:mm")
										: ''
										}</h6>
                                    </div>
                        </div>
                { spinner ? 
                    <NewSpinner />
                :
                    <>
        
                        <div className="org-dashboard">
                            <div className="org-dashboard-midsection">
                                <div className="org-dash-mid-panel">

                                    <div className="org-app-info">

                                        <div className="agency-data">
                                            <div className="org-icon">
                                                { (logoimage === '' || logoimage === null || logoimage === undefined ) ? 
                                                    
                                                    <img src={require("./assets/images/Your logo-latest.png").default} alt="link"/>
                                                :
                                                    ( logoimage.indexOf("https") !== -1 ? 

                                                        <img src={ logoimage } alt={orgname} title={orgname} />
                                                    :
                                                        <img src={agencyimgpath + logoimage} alt={orgname} title={orgname} />
                                                    ) 
                                                    
                                                }
                                            
                                            </div>
                                            <div className="org-data">
                                                <h3>{orgname}</h3>
                                                { (subdomain !== '' && subdomain !== null && subdomain !== undefined) ?
                                                    <a href={'https://'+subdomain} target="_blank" >
                                                        <h4>{subdomain} <img src={require("./assets/images/link.png").default} alt="link"/></h4>
                                                    </a>
                                                :
                                                    
                                                    null
                                                }

                                                <h5>{  moment( orgdate ).format("D MMMM YYYY, HH:mm") }</h5>

                                                <h6>ID: {atob(agencyID)}</h6>
                                                
                                            </div>
                                        </div>

                                    </div>
                                {dashboarddata && parseInt(dashboarddata.agency_user_role_id) != 3 && parseInt(dashboarddata.agency_user_role_id) != 4 ?
                                    <div className="agency-plan-detail mobileshow">

                                        <div className="agency-plan-alignment">

                                        <div className="agency-plan-section-one">
                                            <h3>Subscription</h3>
                                            { subscriptioninfo.plan == 'preview' || subscriptioninfo.plan_name == 'Free' || subscriptioninfo.plan_name == 'free' || subscriptioninfo.plan_name == 'preview' || subscriptioninfo.plan_name == 'Preview' ?  
                                            <p>You’re on the free plan. Please upgrade to access advanced features.</p>
                                            : ( subscriptioninfo.downgrade_at_term_end == 1 || subscriptioninfo.reseller_package_id )&&  subscriptioninfo.package_duration_id != 4 ?
                                            <p>Your existing plan is scheduled for automated cancellation on  {moment( subscriptioninfo.reseller_package_id ?subscriptioninfo.reseller_package_expiry_date  : subscriptioninfo.billing_date ).format("D MMMM YYYY")}.</p>
                                            : subscriptioninfo.package_duration_id != 4 ?
                                            <p>Your existing plan is scheduled for automated renewal on {moment( dashboarddata.renewal_date ).format("D MMMM YYYY")}.</p>
                                            : subscriptioninfo.package_duration_id == 4 ?
                                            <p>You’re on the Lifetime plan. Enjoy updates and support without any recurring charges.</p>
                                            : <></>
                                            }
                                            <h6>Plan: <span className="agencytype-plan-name-capital">{subscriptioninfo && subscriptioninfo != '' ? subscriptioninfo.plan_name : ''}</span></h6>
                                            {subscriptioninfo && subscriptioninfo != '' && subscriptioninfo.gateway_subscription_id != '' ?
                                            <h6>ID: {subscriptioninfo.gateway_subscription_id}</h6>
                                            : ''}
                                        </div>

                                        <div className="agency-plan-section-two">
                                        { subscriptioninfo.plan == 'preview' || subscriptioninfo.plan_name == 'Free' || subscriptioninfo.plan_name == 'free' || subscriptioninfo.plan_name == 'preview' || subscriptioninfo.plan_name == 'Preview' ?  
                                            <img className="img-fluid" src={require("../Agency/assets/images/free-plan.png") .default} alt="plan-icon"/>
                                        :   <img className="img-fluid" src={require("../Agency/assets/images/paid-plan.png") .default} alt="plan-icon"/>
                                        }
                                        </div>

                                        </div>

                                        <div className="agency-status-upgrade">
                                            <p className="agencytype-plan-name-capital">{subscriptioninfo.status}</p>
                                           <a 
                                           style={{
                                            color:subscriptioninfo.downgrade_at_term_end === 1?"#BCC1CE":"",
                                            cursor:subscriptioninfo.downgrade_at_term_end === 1?"pointer":"",
                                            fontSize:subscriptioninfo.downgrade_at_term_end === 1?"13px":"",
                                        }}
                                           href={`/agency/pricing/`+(agencyID)}>{subscriptioninfo.downgrade_at_term_end === 1?"Reactivate":"Upgrade"}</a>
                                        </div>

                                    </div>
                                : <></>
                                }

                                    <div className="agency-personal-data">
                                        <div>
                                            <img className="img-fluid"
                                             src={userinfo && userinfo.image !== '' && userinfo.image !== null && userinfo.image.indexOf("https") === -1 ? (process.env.REACT_APP_Image_Path + userinfo.user_id + process.env.REACT_APP_Profile + userinfo.image) : userinfo.image !== '' && userinfo.image !== null ? userinfo.image : ''}
                                             onError={({ currentTarget }) => {
                                                currentTarget.src =require("../Agency/assets/images/accountprofile.png") .default}} 
                                            alt="profile-picture"/>
                                        </div>

                                        <div>
                                            <h2>{userinfo.profile_name}</h2>
                                            <h5>{userinfo.email}</h5>
                                            <h6>ID: {userinfo.user_id}</h6>
                                        </div>
                                    </div>

                                </div>

                                

                                <div className="agency-app-data">

                                    <div>
                                        <img src={require("../Agency/assets/images/customers.png") .default} alt="customers"/>
                                        <h3>{customerCount.toLocaleString("en-US")}</h3>
                                        <p>customers</p>
                                    </div>

                                    <div>
                                        <img src={require("../Agency/assets/images/apps.png") .default} alt="apps"/>
                                        <h3>{appscount.toLocaleString("en-US")}</h3>
                                        <p>apps</p>
                                    </div>

                                    <div>
                                        <img src={require("../Agency/assets/images/downloads.png") .default} alt="downloads"/>
                                        <h3>{appsdownload.toLocaleString("en-US")}</h3>
                                        <p>downloads</p>
                                    </div>

                                </div>
                            {domainverified  ?

                                <div className="dashboard-bar-graph">
                                    <div className="bar-graph-head">
                                        <h3>Apps</h3>
                                        <div className="d-flex agency-dropdown-border-right">
                                        <Dropdown isOpen={
                                                            window.matchMedia("(max-width: 1200px)").matches
                                                            ? false
                                                            : openstatDropdown
                                                        }
                                                  onClick={
                                                            window.matchMedia("(max-width: 1200px)").matches
                                                        ? handleMobileAppDropdown
                                                        : null
                                                        }
                                                toggle={handleStatdropdown}
                                        >
                                              <div className={openstatDropdown? "agency-report-aerotopsecond": "report-aerohidden"}>
                                      {/* <img
                                        src={
                                          require("../assets/images/dashboard/dropdownaerotop.png")
                                            .default
                                        }
                                        className=""
                                        alt=" "
                                      /> */}
                                      </div>
                                            <DropdownToggle caret><span>{datefilter == 'today' ? 'Today'
                                            :datefilter == '7 days' ? '7 days'
                                            // :datefilter == '14d' ? '14 days'
                                            :datefilter == '30 days' ? '1 month'
                                            // :datefilter == '60d' ? '2 months'
                                            :datefilter == '3 month' ? '3 months'
                                            :datefilter == '6 month' ? '6 months'
                                            :datefilter == '12 month' ? '12 months'
                                            : '7 Days'
                                            }
                                      </span></DropdownToggle>
                                            <DropdownMenu className="agency-analytics-second-dropdown">
                                            <DropdownItem onClick={(e) =>clickdatafiltervalue(e,"today")}>Today</DropdownItem> 
                                                <DropdownItem onClick={(e) =>clickdatafiltervalue(e,"7 days")}>7 days</DropdownItem> 
                                                {/* <DropdownItem onClick={(e) =>clickdatafiltervalue(e,"14 days")}>14 days</DropdownItem> */}
                                                <DropdownItem onClick={(e) =>clickdatafiltervalue(e,"30 days")}>1 month</DropdownItem> 
                                                {/* <DropdownItem onClick={(e) =>clickdatafiltervalue(e,"60 days")}>60 days</DropdownItem> */}
                                                <DropdownItem onClick={(e) =>clickdatafiltervalue(e,"3 month")}>3 months</DropdownItem>
                                                <DropdownItem onClick={(e) =>clickdatafiltervalue(e,"6 month")}>6 months</DropdownItem>
                                                <DropdownItem onClick={(e) =>clickdatafiltervalue(e,"12 month")}>12 months</DropdownItem> 
                                            
                                            </DropdownMenu>
                                        </Dropdown>

                                        <Dropdown className="analytics-second-dropdown"
                                    isOpen={
                                        window.matchMedia("(max-width: 1200px)").matches
                                        ? false
                                        : openIntervalDrop
                                    }
                              onClick={
                                        window.matchMedia("(max-width: 1200px)").matches
                                    ? handleMobIntervalPopUp
                                    : null
                                    }
                                    toggle={handleIntervaldropdown}
                                    >
                                      <div className={openIntervalDrop? "agency-report-day-aerotopsecond": "report-aerohidden"}>
                                      {/* <img
                                        src={
                                          require("../assets/images/dashboard/dropdownaerotop.png")
                                            .default
                                        }
                                        className=""
                                        alt=" "
                                      /> */}
                                      </div>
                                    <DropdownToggle caret>
                                      <span>{intervalFilterVal == 'hour' ? 'Hour'
                                            :intervalFilterVal == 'day' ? 'Day'
                                            :intervalFilterVal == 'month' ? 'Month'
                               
                                            : 'Day'
                                            }
                                      </span>
                                    </DropdownToggle>
                                    <DropdownMenu className="agency-analytics-second-dropdown" end={true}>
                                    {/* {this.state.selectfilter== "90d" || this.state.selectfilter=="180d" ||  this.state.selectfilter=="360d" ?
                                      <></> 
                                    :
                                      <> */}
                                      <DropdownItem 
                                       disabled={datefilter !=="today"?true:false}
                                       onClick={() =>handleIntervalFilter('hour')}>Hour</DropdownItem>
                                      <DropdownItem 
                                      disabled={datefilter ==="today"?true:false}
                                      onClick={() =>handleIntervalFilter('day')}>Day</DropdownItem>
                                      {/* </>
                                    } */}

                                      <DropdownItem  
                                      disabled={(datefilter ==="3 month" || datefilter ==="6 month" || datefilter ==="12 month" ) ?false:true}
                                      onClick={() =>handleIntervalFilter('month')}>Month</DropdownItem>
                                    
                                    </DropdownMenu>
                                  </Dropdown>
                                        </div>
                                       
                                    </div>
                                    {graphload ?
                                        <div className={styles.histogramspinner}>
                                            <Spinner className="agency-analytics-spinner" animation="border" variant="primary" />
                                        </div>
                                    :
                                        <>
                                        {
                                            window.matchMedia("(max-width: 600px)").matches
                                            ? <Bar  options={options} data={data}  height="420px"  width={"420px"} />
                                            : <Bar  options={options} data={data}  height="420px"  width={"1280px"} />
                                        }
                                        </>
                                    }
                                </div>
                                
                            :

                                <div className="no-data-graph">
                                
                                <img className="img-fluid" src={require("../Agency/assets/images/no-data-graph.png") .default} alt="no-data-graph"/>
                                
                                </div>
                            }

                            <div className="mob-org-right-panel">
                            {dashboarddata && parseInt(dashboarddata.agency_user_role_id) != 3 && parseInt(dashboarddata.agency_user_role_id) != 4 ?
                            <div className="agency-plan-detail webshow">

                                <div className="agency-plan-alignment">

                                <div className="agency-plan-section-one">
                                    <h3>Subscription</h3>
                                    {/* <p>You are on the free plan. Please upgrade to access premium features.</p> */}
                                    { subscriptioninfo.plan == 'preview' || subscriptioninfo.plan_name == 'Free' || subscriptioninfo.plan_name == 'free' || subscriptioninfo.plan_name == 'preview' || subscriptioninfo.plan_name == 'Preview' ?  
                                    <p>You’re on the free plan. Please upgrade to access advanced features.</p>
                                    : ( subscriptioninfo.downgrade_at_term_end == 1 || subscriptioninfo.reseller_package_id )&&  subscriptioninfo.package_duration_id != 4 ?
                                    <p>Your existing plan is scheduled for automated cancellation on  {moment( subscriptioninfo.reseller_package_id ?subscriptioninfo.reseller_package_expiry_date  : subscriptioninfo.billing_date ).format("D MMMM YYYY")}.</p>
                                    : subscriptioninfo.package_duration_id != 4 ?
                                    <p>Your existing plan is scheduled for automated renewal on {moment( dashboarddata.renewal_date ).format("D MMMM YYYY")}.</p>
                                    : subscriptioninfo.package_duration_id == 4 ?
                                    <p>You’re on the Lifetime plan. Enjoy updates and support without any recurring charges.</p>
                                    : <></>
                                    }
                                    <h6>Plan: <span className="agencytype-plan-name-capital">{subscriptioninfo && subscriptioninfo != '' ? subscriptioninfo.plan_name : ''}</span></h6>
                                    {subscriptioninfo && subscriptioninfo != '' && subscriptioninfo.gateway_subscription_id != '' ?
                                    <h6>ID: {subscriptioninfo.gateway_subscription_id}</h6>
                                    : ''}
                                </div>

                                <div className="agency-plan-section-two">
                                { subscriptioninfo.plan == 'preview' || subscriptioninfo.plan_name == 'Free' || subscriptioninfo.plan_name == 'free' || subscriptioninfo.plan_name == 'preview' || subscriptioninfo.plan_name == 'Preview' ?
                                <img className="img-fluid" src={require("../Agency/assets/images/free-plan.png") .default} alt="plan-icon"/>
                                :
                                <img className="img-fluid" src={require("../Agency/assets/images/paid-plan.png") .default} alt="plan-icon"/>
                                }
                                </div>

                                </div>

                                <div className="agency-status-upgrade">
                                    <p className="agencytype-plan-name-capital">
                                        {subscriptioninfo.status}</p>
                                        <a 
                                          style={{
                                            color:subscriptioninfo.downgrade_at_term_end === 1?"#BCC1CE":"",
                                            cursor:subscriptioninfo.downgrade_at_term_end === 1?"pointer":"",
                                            fontSize:subscriptioninfo.downgrade_at_term_end === 1?"13px":"",
                                        }}
                                          href={`/agency/pricing/`+(agencyID)}>{subscriptioninfo.downgrade_at_term_end === 1?"Reactivate":"Upgrade"}</a>
                                        
                                </div>

                            </div>
                            : <></>
                            }

                                

                                <div className="agency-total-steps">
                                    <div className="dashboard-progress">
                                        { setupcount === 7 ?
                                            <h2>You're all set<img src={require("../Agency/assets/images/hurray.png") .default} alt="hurray"/></h2>
                                        :
                                            <h2>Let's set up your agency</h2>
                                        }
                                        <div className="dashboard-progress-bar">
                                            <div>
                                                <p>{setupcount}/7</p>
                                            </div>
                                            <Progress value={singlecountval * setupcount} />
                                        </div>    
                                    </div>

                                    <div className="agency-seven-steps">


                                    <div className="agency-steps">

                                            { domainverified ?
                                                <>
                                                    <a href={`/agency/domain/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg>

                                                    </div>
                                                </>
                                            :
                                                <>
                                                    <a href={`/agency/domain/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37698" data-name="Group 37698" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#dde2f2"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg> */}
                                                        <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"></path></svg>

                                                    </div>
                                                </>
                                            }
                                            <div>
                                                <h4>Domain</h4>
                                                <p>Give your online app business a new home.</p>
                                            </div>
                                    </div>


                                    <div className="agency-steps">
                                            { supportenable ?
                                                <>
                                                    <a href={`/agency/support/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg>

                                                    </div>
                                                </>
                                            
                                            :
                                                <>
                                                    <a href={`/agency/support/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37698" data-name="Group 37698" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#dde2f2"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg> */}
                                                        <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"></path></svg>

                                                    </div>
                                                </>
                                            }
                                            <div>
                                                <h4>Support</h4>
                                                <p>Add resources to enable customer support.</p>
                                            </div>
                                        </div>



                                        <div className="agency-steps">
                                            { emaildataenable ?
                                                <>
                                                    <a href={`/agency/email/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg>

                                                    </div>
                                                </>
                                                
                                            :
                                                <>                                                    
                                                    <a href={`/agency/email/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37698" data-name="Group 37698" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#dde2f2"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg> */}
                                                    <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"></path></svg>
                                                    </div>
                                                </>
                                            }
                                            <div>
                                                <h4>Email</h4>
                                                <p>Configure emails to be sent from your domain.</p>
                                            </div>
                                        </div>

                                        

                                        <div className="agency-steps">
                                            { socialloginenable ?
                                                <>
                                                    <a href={`/agency/authentication/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg>

                                                    </div>
                                                </>
                                            :
                                                <>
                                                    <a href={`/agency/authentication/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37698" data-name="Group 37698" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#dde2f2"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg> */}
                                                        <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"></path></svg>

                                                    </div>
                                                </>
                                            }
                                            <div>
                                                <h4>Authentication</h4>
                                                <p>Manage & configure third-party login services.</p>
                                            </div>
                                        </div>

                                        <div className="agency-steps">
                                            { generalsettingenable ?
                                                <>
                                                    <a href={`/agency/settings/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg>

                                                    </div>
                                                </>
                                            :
                                                <>
                                                    <a href={`/agency/settings/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37698" data-name="Group 37698" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#dde2f2"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg> */}
                                                    <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"></path></svg>
                                                    </div>
                                                </>
                                            }
                                            <div>
                                                <h4>General settings</h4>
                                                <p>Personalize and configure your agency interface.</p>
                                            </div>
                                        </div>

                                        <div className="agency-steps">
                                            { pluginenable ?
                                                <>
                                                    <a href={`/agency/wordpress-plugin/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg>

                                                    </div>  
                                                </>
                                            :
                                                <>
                                                    <a href={`/agency/wordpress-plugin/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37698" data-name="Group 37698" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#dde2f2"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg> */}
                                                        <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"></path></svg>

                                                    </div>
                                                </>
                                            }
                                            <div>
                                                <h4>Plugin</h4>
                                                <p>White-label your WordPress & WooCommerce plugin.</p>
                                            </div>
                                        </div>


                                        <div className="agency-steps">
                                            { (usersdata !== null && usersdata !== undefined && usersdata.length > 0) ?
                                                <>
                                                   <a href={`/agency/team/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg>

                                                    </div> 
                                                </>
                                            :
                                                <>                                                
                                                    <a href={`/agency/team/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37698" data-name="Group 37698" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#dde2f2"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg> */}
                                                     <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"></path></svg>
                                                    </div>
                                                </>
                                            }
                                            <div>
                                                <h4>Team</h4>
                                                <p>Collaborate with teams and manage access.</p>
                                            </div>
                                        </div>

                                        <div className="agency-steps-animation"></div>

                                    </div>
                                </div>
                
                                <div className="org-dash-teams">
                                    <a href={`/agency/team/${(agencyID)}`}>
                                        <h3>
                                            Team
                                            <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                        </h3>
                                    </a>
                
                                    <div className="org-teamlist">

                                        { ( ownerdata === '' || ownerdata === null || ownerdata === undefined || ownerdata === 'null' ) ?

                                            <div className="org-teammember" >
                                                <div className="offonline"></div>
                                            </div>
                                        :
                                            ((ownerdata.image === '' || ownerdata.image === null || ownerdata.image === undefined || ownerdata.image === 'null') ?
                                            <div className="org-teammember" style={{ background: "url(" + require("./assets/images/accountprofile.png").default + ")" }} title={`${ownerdata.profile_name}`}>
                                                <div className="online"></div>
                                            </div>

                                            :
                                                ( ownerdata.image.indexOf("https") === -1 ?
                                                    <div className="org-teammember" key="" style={{ background: "url(" + process.env.REACT_APP_Image_Path + ownerdata.user_id + process.env.REACT_APP_Profile + ownerdata.image + ")" }} title={`${ownerdata.profile_name}`} >
                                                        
                                                        <div className="online"></div>                                            
                                                    </div>
                                                :
                                                    <div className="org-teammember" key="" style={{ background: "url(" + ownerdata.image + ")" }} title={`${ownerdata.profile_name}`} >

                                                        <div className="online"></div>                                            
                                                    </div>
                                                )
                                            )
                                        }

                                        { (usersdata !== null && usersdata !== undefined && usersdata.length > 0) ? 
                                            usersdata.map((userlist, useridx)=>{
                                                if(userlist.image === '' || userlist.image === null || userlist.image === undefined || userlist.image === 'null')
                                                {
                                                    return(
                                                    <div className="org-teammember" key={useridx} style={{ background: "url(" + require("./assets/images/accountprofile.png").default + ")"}} title={`${userlist.profile_name}`}>
                                                       
                                                        
                                                        { userlist.status === 1 ?
                                                            <div className="online"></div>                                                    
                                                        :
                                                            <div className="offonline"></div>
                                                        }
                                                    </div>
                                                    )
                                                }
                                                else
                                                {
                                                    if(userlist.image.indexOf("https") === -1)
                                                    {
                                                        return(
                                                        <div className="org-teammember" key={useridx} style={{ background: "url(" + process.env.REACT_APP_Image_Path + userlist.user_id + process.env.REACT_APP_Profile + userlist.image + ")"}} title={`${userlist.profile_name}`}>
                                                            
                                                            { userlist.status === 1 ?
                                                                <div className="online"></div>
                                                            :
                                                                <div className="offonline"></div>
                                                            }
                                                        </div>
                                                        )
                                                    }
                                                    else
                                                    {
                                                        return(
                                                        <div className="org-teammember" key={useridx} style={{ background: "url(" + userlist.image + ")"}} title={`${userlist.profile_name}`} >

                                                            { userlist.status === 1 ?
                                                                <div className="online"></div>
                                                            :
                                                                <div className="offonline"></div>
                                                            }
                                                        </div>
                                                        )
                                                    }
                                                }
                                            })
                                        :
                                            null                                
                                        }

                                        {/*<div className="org-teammember">
                                            <div className="online"></div>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>

                            <SupportSection
                              supportCardArr={SUPPORTCARDDETAILS} 
                              planName={
                                localStorage.getItem('agencyid') &&  localStorage.getItem('agencyid') == 1 ?
                                  localStorage.getItem('supportplan') && isJSON((localStorage.getItem('supportplan'))) 
                                  ?
                                    isJSON((localStorage.getItem('supportplan'))).plan_name
                                  : 
                                    "Basic" 
                                : ""
                              }
                              planDesc={
                                localStorage.getItem('agencyid') &&  localStorage.getItem('agencyid') == 1 ?
                                localStorage.getItem('supportplan') && isJSON((localStorage.getItem('supportplan'))) 
                                ?
                                  isJSON((localStorage.getItem('supportplan'))).plan_name === "Basic" ?
                                  "Basic plan gives you access to essential support through dedicated support channels."
                                  : isJSON((localStorage.getItem('supportplan'))).plan_name === "Business" ?
                                 "Business plan provides swift response times and advanced troubleshooting. " :
                                isJSON((localStorage.getItem('supportplan'))).plan_name === "Enterprise" ?
                                "Enterprise plan comes with top-tier support and prompt response times. Get a dedicated Success Manager for custom enhancements."
                                :
                                "Basic plan gives you access to essential support through dedicated support channels."
                                :
                                  "Basic plan gives you access to essential support through dedicated support channels." 
                              : ""}
                              expireOn={ localStorage.getItem('agencyid') &&  localStorage.getItem('agencyid') == 1 ?
                                localStorage.getItem('supportplan') && isJSON((localStorage.getItem('supportplan'))) 
                                ?
                                  isJSON((localStorage.getItem('supportplan'))).renewal_date
                                : 
                                  "" 
                              : ""}
                              downgradeAtTermEnd={ 
                                localStorage.getItem('agencyid') &&  localStorage.getItem('agencyid') == 1 ?
                                localStorage.getItem('supportplan') && isJSON((localStorage.getItem('supportplan'))) 
                                ?
                                  isJSON((localStorage.getItem('supportplan'))).downgrade_at_term_end
                                : 
                                  "" 
                              : ""}
                            />

                            
                            </div>
                
                            <div className="web-org-right-panel">
                            {dashboarddata && parseInt(dashboarddata.agency_user_role_id) != 3 && parseInt(dashboarddata.agency_user_role_id) != 4 ?
                            <div className="agency-plan-detail webshow">

                                <div className="agency-plan-alignment">

                                <div className="agency-plan-section-one">
                                    <h3>Subscription</h3>
                                    {/* <p>You are on the free plan. Please upgrade to access premium features.</p> */}
                                    { subscriptioninfo.plan == 'preview' || subscriptioninfo.plan_name == 'Free' || subscriptioninfo.plan_name == 'free' || subscriptioninfo.plan_name == 'preview' || subscriptioninfo.plan_name == 'Preview' ?  
                                    <p>You’re on the free plan. Please upgrade to access advanced features.</p>
                                    : ( subscriptioninfo.downgrade_at_term_end == 1 || subscriptioninfo.reseller_package_id )&&  subscriptioninfo.package_duration_id != 4 ?
                                    <p>Your existing plan is scheduled for automated cancellation on  {moment( subscriptioninfo.reseller_package_id ?subscriptioninfo.reseller_package_expiry_date  : subscriptioninfo.billing_date ).format("D MMMM YYYY")}.</p>
                                    : subscriptioninfo.package_duration_id != 4 ?
                                    <p>Your existing plan is scheduled for automated renewal on {moment( dashboarddata.renewal_date ).format("D MMMM YYYY")}.</p>
                                    : subscriptioninfo.package_duration_id == 4 ?
                                    <p>You’re on the Lifetime plan. Enjoy updates and support without any recurring charges.</p>
                                    : <></>
                                    }
                                    <h6>Plan: <span className="agencytype-plan-name-capital">{subscriptioninfo && subscriptioninfo != '' ? subscriptioninfo.plan_name : ''}</span></h6>
                                    {subscriptioninfo && subscriptioninfo != '' && subscriptioninfo.gateway_subscription_id != '' ?
                                    <h6>ID: {subscriptioninfo.gateway_subscription_id}</h6>
                                    : ''}
                                </div>

                                <div className="agency-plan-section-two">
                                { subscriptioninfo.plan == 'preview' || subscriptioninfo.plan_name == 'Free' || subscriptioninfo.plan_name == 'free' || subscriptioninfo.plan_name == 'preview' || subscriptioninfo.plan_name == 'Preview' ?
                                <img className="img-fluid" src={require("../Agency/assets/images/free-plan.png") .default} alt="plan-icon"/>
                                :
                                <img className="img-fluid" src={require("../Agency/assets/images/paid-plan.png") .default} alt="plan-icon"/>
                                }
                                </div>

                                </div>

                                <div className="agency-status-upgrade">
                                    <p className="agencytype-plan-name-capital">
                                        {subscriptioninfo.status}</p>
                                        <a 
                                          style={{
                                            color:subscriptioninfo.downgrade_at_term_end === 1?"#BCC1CE":"",
                                            cursor:subscriptioninfo.downgrade_at_term_end === 1?"pointer":"",
                                            fontSize:subscriptioninfo.downgrade_at_term_end === 1?"13px":"",
                                        }}
                                          href={`/agency/pricing/`+(agencyID)}>{subscriptioninfo.downgrade_at_term_end === 1?"Reactivate":"Upgrade"}</a>
                                        
                                </div>

                            </div>
                            : <></>
                            }

                                

                                <div className="agency-total-steps">
                                    <div className="dashboard-progress">
                                        { setupcount === 7 ?
                                            <h2>You're all set<img src={require("../Agency/assets/images/hurray.png") .default} alt="hurray"/></h2>
                                        :
                                            <h2>Let's set up your agency</h2>
                                        }
                                        <div className="dashboard-progress-bar">
                                            <div>
                                                <p>{setupcount}/7</p>
                                            </div>
                                            <Progress value={singlecountval * setupcount} />
                                        </div>    
                                    </div>

                                    <div className="agency-seven-steps">


                                    <div className="agency-steps">

                                            { domainverified ?
                                                <>
                                                    <a href={`/agency/domain/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg>

                                                    </div>
                                                </>
                                            :
                                                <>
                                                    <a href={`/agency/domain/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37698" data-name="Group 37698" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#dde2f2"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg> */}
                                                        <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"></path></svg>

                                                    </div>
                                                </>
                                            }
                                            <div>
                                                <h4>Domain</h4>
                                                <p>Give your online app business a new home.</p>
                                            </div>
                                    </div>


                                    <div className="agency-steps">
                                            { supportenable ?
                                                <>
                                                    <a href={`/agency/support/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg>

                                                    </div>
                                                </>
                                            
                                            :
                                                <>
                                                    <a href={`/agency/support/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37698" data-name="Group 37698" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#dde2f2"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg> */}
                                                        <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"></path></svg>

                                                    </div>
                                                </>
                                            }
                                            <div>
                                                <h4>Support</h4>
                                                <p>Add resources to enable customer support.</p>
                                            </div>
                                        </div>



                                        <div className="agency-steps">
                                            { emaildataenable ?
                                                <>
                                                    <a href={`/agency/email/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg>

                                                    </div>
                                                </>
                                                
                                            :
                                                <>                                                    
                                                    <a href={`/agency/email/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37698" data-name="Group 37698" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#dde2f2"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg> */}
                                                    <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"></path></svg>
                                                    </div>
                                                </>
                                            }
                                            <div>
                                                <h4>Email</h4>
                                                <p>Configure emails to be sent from your domain.</p>
                                            </div>
                                        </div>

                                        

                                        <div className="agency-steps">
                                            { socialloginenable ?
                                                <>
                                                    <a href={`/agency/authentication/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg>

                                                    </div>
                                                </>
                                            :
                                                <>
                                                    <a href={`/agency/authentication/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37698" data-name="Group 37698" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#dde2f2"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg> */}
                                                        <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"></path></svg>

                                                    </div>
                                                </>
                                            }
                                            <div>
                                                <h4>Authentication</h4>
                                                <p>Manage & configure third-party login services.</p>
                                            </div>
                                        </div>

                                        <div className="agency-steps">
                                            { generalsettingenable ?
                                                <>
                                                    <a href={`/agency/settings/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg>

                                                    </div>
                                                </>
                                            :
                                                <>
                                                    <a href={`/agency/settings/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37698" data-name="Group 37698" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#dde2f2"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg> */}
                                                    <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"></path></svg>
                                                    </div>
                                                </>
                                            }
                                            <div>
                                                <h4>General settings</h4>
                                                <p>Personalize and configure your agency interface.</p>
                                            </div>
                                        </div>

                                        <div className="agency-steps">
                                            { pluginenable ?
                                                <>
                                                    <a href={`/agency/wordpress-plugin/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg>

                                                    </div>  
                                                </>
                                            :
                                                <>
                                                    <a href={`/agency/wordpress-plugin/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37698" data-name="Group 37698" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#dde2f2"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg> */}
                                                        <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"></path></svg>

                                                    </div>
                                                </>
                                            }
                                            <div>
                                                <h4>Plugin</h4>
                                                <p>White-label your WordPress & WooCommerce plugin.</p>
                                            </div>
                                        </div>


                                        <div className="agency-steps">
                                            { (usersdata !== null && usersdata !== undefined && usersdata.length > 0) ?
                                                <>
                                                   <a href={`/agency/team/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg>

                                                    </div> 
                                                </>
                                            :
                                                <>                                                
                                                    <a href={`/agency/team/${(agencyID)}`}></a>
                                                    <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37698" data-name="Group 37698" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#dde2f2"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg> */}
                                                     <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"></path></svg>
                                                    </div>
                                                </>
                                            }
                                            <div>
                                                <h4>Team</h4>
                                                <p>Collaborate with teams and manage access.</p>
                                            </div>
                                        </div>

                                        <div className="agency-steps-animation"></div>

                                    </div>
                                </div>
                
                                <div className="org-dash-teams">
                                    <a href={`/agency/team/${(agencyID)}`}>
                                        <h3>
                                            Team
                                            <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                        </h3>
                                    </a>
                
                                    <div className="org-teamlist">

                                        { ( ownerdata === '' || ownerdata === null || ownerdata === undefined || ownerdata === 'null' ) ?

                                            <div className="org-teammember" >
                                                <div className="offonline"></div>
                                            </div>
                                        :
                                            ((ownerdata.image === '' || ownerdata.image === null || ownerdata.image === undefined || ownerdata.image === 'null') ?
                                            <div className="org-teammember" style={{ background: "url(" + require("./assets/images/accountprofile.png").default + ")" }} title={`${ownerdata.profile_name}`}>
                                                <div className="online"></div>
                                            </div>

                                            :
                                                ( ownerdata.image.indexOf("https") === -1 ?
                                                    <div className="org-teammember" key="" style={{ background: "url(" + process.env.REACT_APP_Image_Path + ownerdata.user_id + process.env.REACT_APP_Profile + ownerdata.image + ")" }} title={`${ownerdata.profile_name}`} >
                                                        
                                                        <div className="online"></div>                                            
                                                    </div>
                                                :
                                                    <div className="org-teammember" key="" style={{ background: "url(" + ownerdata.image + ")" }} title={`${ownerdata.profile_name}`} >

                                                        <div className="online"></div>                                            
                                                    </div>
                                                )
                                            )
                                        }

                                        { (usersdata !== null && usersdata !== undefined && usersdata.length > 0) ? 
                                            usersdata.map((userlist, useridx)=>{
                                                if(userlist.image === '' || userlist.image === null || userlist.image === undefined || userlist.image === 'null')
                                                {
                                                    return(
                                                    <div className="org-teammember" key={useridx} style={{ background: "url(" + require("./assets/images/accountprofile.png").default + ")"}} title={`${userlist.profile_name}`}>
                                                       
                                                        
                                                        { userlist.status === 1 ?
                                                            <div className="online"></div>                                                    
                                                        :
                                                            <div className="offonline"></div>
                                                        }
                                                    </div>
                                                    )
                                                }
                                                else
                                                {
                                                    if(userlist.image.indexOf("https") === -1)
                                                    {
                                                        return(
                                                        <div className="org-teammember" key={useridx} style={{ background: "url(" + process.env.REACT_APP_Image_Path + userlist.user_id + process.env.REACT_APP_Profile + userlist.image + ")"}} title={`${userlist.profile_name}`}>
                                                            
                                                            { userlist.status === 1 ?
                                                                <div className="online"></div>
                                                            :
                                                                <div className="offonline"></div>
                                                            }
                                                        </div>
                                                        )
                                                    }
                                                    else
                                                    {
                                                        return(
                                                        <div className="org-teammember" key={useridx} style={{ background: "url(" + userlist.image + ")"}} title={`${userlist.profile_name}`} >

                                                            { userlist.status === 1 ?
                                                                <div className="online"></div>
                                                            :
                                                                <div className="offonline"></div>
                                                            }
                                                        </div>
                                                        )
                                                    }
                                                }
                                            })
                                        :
                                            null                                
                                        }

                                        {/*<div className="org-teammember">
                                            <div className="online"></div>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
            <Modal
                className="protip-popup-message"
                isOpen={openMobileAppDropdown}
                centered
              >
                <ModalBody>
                  <div className="mobileglobalsearch">
                    <button onClick={handleMobileAppDropdown}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.759"
                        height="15.408"
                        viewBox="0 0 20.759 15.408"
                      >
                        <g
                          id="Group_27465"
                          data-name="Group 27465"
                          transform="translate(-19.742 -22.295)"
                        >
                          <path
                            id="Path_81245"
                            data-name="Path 81245"
                            d="M-13783.922-19217h19.346"
                            transform="translate(13805.077 19247)"
                            fill="none"
                            stroke="#7782a1"
                            stroke-width="2"
                          />
                          <path
                            id="Path_82232"
                            data-name="Path 82232"
                            d="M14053.656,19255.426l-7,7,7,7"
                            transform="translate(-14025.504 -19232.424)"
                            fill="none"
                            stroke="#7782a1"
                            stroke-width="2"
                          />
                        </g>
                      </svg>
                    </button>
                   
                  </div>

                  <div className="mobilesearchsuggestion email-mob-popup">
                        <p

                        className="agency-mob-dropdown-selection-text"
                             
                              onClick={(e) =>clickdatafiltervalue(e,"7 days")}
                        >7 Days</p>
                         
                         <p
                              className="agency-mob-dropdown-selection-text"
                              onClick={(e) =>clickdatafiltervalue(e,"30 days")}
                        >1 month</p>
                        
                         <p
                               className="agency-mob-dropdown-selection-text"
                              onClick={(e) =>clickdatafiltervalue(e,"3 month")}
                        >3 months</p>
                         <p
                              className="agency-mob-dropdown-selection-text"
                              onClick={(e) =>clickdatafiltervalue(e,"6 month")}
                        >6 months</p>
                         <p
                              className="agency-mob-dropdown-selection-text"
                              onClick={(e) =>clickdatafiltervalue(e,"12 month")}
                        >12 months</p>

                    
                  </div>
                </ModalBody>
              </Modal>
              <Modal
                className="protip-popup-message"
                isOpen={openMobIntervalPopUp}
                centered
              >
                <ModalBody>
                  <div className="mobileglobalsearch">
                    <button onClick={handleMobIntervalPopUp}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.759"
                        height="15.408"
                        viewBox="0 0 20.759 15.408"
                      >
                        <g
                          id="Group_27465"
                          data-name="Group 27465"
                          transform="translate(-19.742 -22.295)"
                        >
                          <path
                            id="Path_81245"
                            data-name="Path 81245"
                            d="M-13783.922-19217h19.346"
                            transform="translate(13805.077 19247)"
                            fill="none"
                            stroke="#7782a1"
                            stroke-width="2"
                          />
                          <path
                            id="Path_82232"
                            data-name="Path 82232"
                            d="M14053.656,19255.426l-7,7,7,7"
                            transform="translate(-14025.504 -19232.424)"
                            fill="none"
                            stroke="#7782a1"
                            stroke-width="2"
                          />
                        </g>
                      </svg>
                    </button>
                   
                  </div>

                  <div className="mobilesearchsuggestion email-mob-popup">
                        <p
                             className="agency-mob-dropdown-selection-text"
                              style={{
                            
                                cursor:datefilter !=="today"?"not-allowed":"pointer",
                                color:datefilter !=="today"?"#adb5bd":"",
                              }}
                              onClick={() => datefilter !=="today"?null:handleIntervalFilter('hour')}
                        >Hour</p>
                        
                         <p

                          className="agency-mob-dropdown-selection-text"
                              style={{
                                cursor:datefilter ==="today"?"not-allowed":"pointer",
                                color:datefilter  ==="today"?"#adb5bd":"",
                              }}
                              onClick={() =>datefilter ==="today"?null:handleIntervalFilter('day')}
                        >Day</p>
                        
                         <p

                          className="agency-mob-dropdown-selection-text"
                              style={{
                                cursor:(datefilter ==="3 month" || datefilter ==="6 month" || datefilter ==="12 month" ) ?"pointer":"not-allowed",
                                color:(datefilter ==="3 month" || datefilter ==="6 month" || datefilter ==="12 month" ) ?"":"#adb5bd",
                              }}
                              onClick={() =>(datefilter ==="3 month" || datefilter ==="6 month" || datefilter ==="12 month" ) ?handleIntervalFilter('month'):null}
                        >Month</p>
                        
                  

                    
                  </div>
                </ModalBody>
              </Modal>

              <Modal
                    isOpen={openReactivatePopUp}
                    centered
                    toggle={(e) =>handleReactivatePopUp(e)}
                    style={{ maxWidth: "480px", maxHeight: "296px" }}
                    className="plan-cancel-popup"
                    dataclassName="addonpopup"
                >
                    <div className="crossmodal mt-1" onClick={(e) => handleReactivatePopUp(e)}>
                    <img src={ require("../assets/images/dashboard/cross.png") .default }alt="cross"/>
              </div>
                    <ModalBody className="">
                    <div>
                        <div className="d-flex justify-content-center">
                        
                        <div className="confirmtext agency-cancelpopup">
                            <h4>Plan reactivation confirmation </h4>
                           
                                    <h6>
                                    Are you sure you want to reactivate your current plan? 
                                    </h6>
                                
                           
                         
                        </div>
                        </div>
                        <div className="plan-cancel-popup-footer d-flex justify-content-center">
                        <div className="plan-cancelbtn" onClick={(e) => handleReactivatePopUp(e)}>
                            Cancel
                        </div>

                        <div
                            onClick={(e) =>
                           
                                 handleReactivateSubscription(e)
                            }
                        >
                            <div className="d-flex align-items-center justify-content-center h-100 w-100">
                            <div className="plan-upgradebtn">
                            <div className={reactivateLoader}>
                              <img
                                src={require("../assets/images/signup/Loader_GIF.gif").default}
                                alt="loader"
                              />
                            </div>
                           {submitValReactivate}</div>
                          
                            </div>
                        </div>

                        </div>
                    </div>
                    </ModalBody>
                </Modal>
        </div>
    </div>
    );
}

export default AgencyDashboard;