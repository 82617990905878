import React, { useState, useEffect, Suspense, useRef } from "react";
import "../assets/css/Dashboard.css";
import Accountleftpanel from "./Accountleftpanel";
import "../assets/css/profileleftpanel.css";
import Header from "./Header";
import ProfileLeftPanel from "./ProfileLeftPanel";
import {
	Spinner,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from "reactstrap";

import Tooltip from "../Dashboard/Tooltip";

import { Foot } from "../Dashboard/Foot";
import { errortoast, sucesstoast } from "../Dashboard/Toaster";
import { Link } from "react-router-dom";
import "../assets/css/Newprofile.css";
import "../assets/css/Newsecurity.css";
import {
	sendGenerateQRReq,
	sendOTPVerification,
	disable2FactorAuth,
	UserVerifyTwoFactor,
	UserSessionData,
	SignoutAllDevices,
} from "../Api/Authenticate/Api";

import {
	Changepasswordapi,
	GetuserprofileApi,
  } from "../Api/Dashboard/DashboardApi";
import { QRCodeCanvas } from 'qrcode.react';
import moment from "moment";
import MobileProfileMenu from "./MobileProfileMenu";

const Security = (props) => {

	const [mdlcode, setMdlCode] = useState(false);
	const [mdlqrcode, setMdlQrCode] = useState(false);
	const [mdlverify, setMdlVerify] = useState(false);
	const [mdlhide2f, setMdlHide2F] = useState(false);
	const [spinner, setSpinner] = useState(true);
	//const [profile, setProfile] = useState("");
	//const [profileimage, setProfileImage] = useState("");
	const [qrrequest, setQRRequest] = useState("");
	const [qrvalue1, setQRValue1] = useState("");
	const [qrvalue2, setQRValue2] = useState("");
	const [qrvalue3, setQRValue3] = useState("");
	const [qrvalue4, setQRValue4] = useState("");
	const [qrvalue5, setQRValue5] = useState("");
	const [qrvalue6, setQRValue6] = useState("");
	const [testModal, setTestModal] = useState(false);
	const [verifycodebool, setVerifyCodeBool] = useState(false);
	const [qrcodeerror, setQRCodeError] = useState("");
	const [backupcode, setBackupCode] = useState("");
	const [backupcodestar, setBackupCodeStar] = useState(false);
	const [btnenable, setBtnEnable] = useState("Enable");
	const [btnverify, setBtnVerify] = useState("Verify");
	const [right_panel_expand, setRight_panel_expand] = useState(true);
	//const [loaderimage, setLoaderimage] = useState( require("../assets/images/signup/Loader_GIF.gif").default );
	const [isLoader, setIsLoader] = useState("d-none");
	const [isverifyloader, setIsVerifyLoader] = useState("d-none");
	const [isrecoveryloader, setIsRecoveryLoader] = useState("d-none");
	const [is2fdisableloader, setIs2FDisableLoader] = useState("d-none");
	const [btnopenverifytxt, setBtnOpenVerifyTxt] = useState("Next");
	const [btnopenrecoverytxt, setBtnOpenRecoveryTxt] = useState("Next");
	const [btn2fdisabletxt, setBtn2FDisableTxt] = useState("Disable");
	const [updatetimetxt, setUpdateTimeTxt] = useState("");
	const [showupdatetime, setShowUpdateTime] = useState(false);

	const [passupdatetimetxt, setPassUpdateTimeTxt] = useState("");
	const [showpassupdatetime, setShowPassUpdateTime] = useState(false);
	const[passprogress, setPassProgress] = useState(null);
	const[oldpassword, setOldPassword] = useState("");
	const[newpassword, setNewPassword] = useState("");
	const[confirmpassword, setConfirmPassword] = useState("");
	const[oldpassworderror, setOldPasswordError] = useState(null);
	const[newpassworderror, setNewPasswordError] = useState(null);
	const[confirmpassworderror, setConfirmPasswordError] = useState(null);
	const[loaderimage, setLoaderImage] = useState("../../assets/images/signup/Loader_GIF.gif");
	const[loaderclass, setLoaderClass] = useState("d-none");
	const[passattibutetype1, setPassAttributeType1] = useState("password");
	const[passattibutetype2, setPassAttributeType2] = useState("text");
	const[passattibutetype3, setPassAttributeType3] = useState("text");
	const[labelsavebutton, setLabelSaveButton] = useState("Save");
	const[iseyepassinput1, setIsEyePassInput1] = useState(false);
	const[passinput1eyeopen, setPassInput1EyeOpen] = useState(true);
	const[iseyepassinput2, setIsEyePassInput2] = useState(false);
	const[passinput2eyeopen, setPassInput2EyeOpen] = useState(false);
	const[iseyepassinput3, setIsEyePassInput3] = useState(false);
	const[passinput3eyeopen, setPassInput3EyeOpen] = useState(false);
	const[ispassloader, setIsPassLoader] = useState("d-none");
	const[labelpasssave, setLabelPassSave] = useState("Save");

	const[showqrcodesection, setShowQrCodeSection] = useState(true);
	const[showverifycodesection, setShowVerifyCodeSection] = useState(false);
	const[showbackupcodesection, setShowBackupCodeSection] = useState(false);
	const[ispasswordupdate, setIsPasswordUpdate] = useState(false);
	const[is2faupdate, setIs2FAUpdate] = useState(false);
	const[qrsecret, setQRSecret] = useState("");
	const[qrcodereq, setQrCodeReq] = useState(false);
	const[open_profile_menu, setOpen_profile_menu] = useState(false);
	const[sessiondata, setSessionData] = useState([]);
	
	const [mdlsignoutpopoup, setMdlSignoutPopup] = useState(false);
	const [issignoutloader, setIsSignoutLoader] = useState("d-none");
	const [signouttxt, setSignoutTxt] = useState("Sign out");

	

	const profilepath = "";

	const mdlclassName = " code-popup";

	const modeldesktop = {
						"maxWidth": "600px", 
						"maxHeight": "400px",
						"marginTop": "24vh"
					};
	const modelmobile = {
		"maxWidth": "600px", 
		"maxHeight": "400px"
	};

	useEffect(() => {
        // document.title="Security | AppMySite"
		if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
		{
			document.title = "Security | AppMySite";

		}
		else
		{
			document.title = "Security |"+ ' ' +localStorage.getItem('agencyname');
		}
		UserVerifyTwoFactor(setSpinner, setVerifyCodeBool, setUpdateTimeTxt, setShowUpdateTime, setPassUpdateTimeTxt, setShowPassUpdateTime, setIs2FAUpdate);

		UserSessionData(setSessionData);



	}, [is2faupdate]);

	const codemodalapp = (e) => {
		e.preventDefault();
		setMdlCode(false);

		setIsLoader("d-none");
		setQRRequest("");
		setVerifyCodeBool(true);

		setBtnVerify("Verify");
	};

	const openCodePopup = () => {
		setMdlCode(true);
		/*this.setState({
				mdlcode: true,
		});*/
	};

	const generateQRCode = () => {

		//setSpinner(true);
		//setIsLoader("d-block");
		//setBtnEnable("");

		const params = {
			agency_id:localStorage.getItem('agency_id') ? parseInt(localStorage.getItem('agency_id')) : 1
		};
		//sendGenerateQRReq(params, setQRRequest, setIsLoader, setBtnEnable);
		setMdlQrCode(true);
		sendGenerateQRReq(params, setQRRequest, setQRSecret);
	}

	const handleQRValue1 = (e) => {
		setQRValue1(e.target.value);
		//document.getElementById("qrvalue2").focus();

		sendFocusNextInput(e.target.value, "qrstep2");

	}

	const handleQRValue2 = (e) => {
		setQRValue2(e.target.value);

		sendFocusNextInput(e.target.value, "qrstep3");
	}

	const handleQRValue3 = (e) => {
		
		setQRValue3(e.target.value);

		sendFocusNextInput(e.target.value, "qrstep4");
	}

	const handleQRValue4 = (e) => {
		
		setQRValue4(e.target.value);

		sendFocusNextInput(e.target.value, "qrstep5");
	}

	const handleQRValue5 = (e) => {
		
		setQRValue5(e.target.value);

		sendFocusNextInput(e.target.value, "qrstep6");
	}

	const handleQRValue6 = (e) => {
		
		setQRValue6(e.target.value);

		sendFocusNextInput(e.target.value, "qrstep7");
	}

	const sendFocusNextInput = (inputval, inputname) => {

		if (inputval.length === 1) {
			const nextSibling = document.querySelector("input[name=" + inputname + "]");
			
			if (nextSibling !== null) {
				nextSibling.focus();
			}
		}
	}

	const handleRemoveCode = (e, previnputname, nextinputname) => {

		if (e.key === "Delete" || e.key === "Backspace") {
			const next = e.target.tabIndex - 1;
			
			if (next > -1) 
			{

				//e.target.elements[next].focus();
				
				if (previnputname !== '' && previnputname !== undefined) 
				{
					
					let inputdata = document.querySelector("input[name=" + (previnputname) + "]");
					inputdata.focus();

					/*if(previnputname === "qrstep5")
					{						
						document.getElementById("qrstep6").classList.remove("varify-code-error");
					}
					else
					{
						document.getElementById(previnputname).classList.remove("varify-code-error");
					}*/

					if(previnputname === 'qrstep6' )
					{
						document.getElementById(nextinputname).classList.remove("varify-code-error");
					}
					else
					{
						document.getElementById(previnputname).classList.remove("varify-code-error");
					}
					
				}
				else
				{
					document.getElementById(nextinputname).classList.remove("varify-code-error");
				}
				

			}
		}
		else {
			

			const next = e.target.tabIndex;
			
			if (next < 6) {
				
				//e.target.elements[next].focus();
				let inputdata = document.querySelector("input[name=" + (nextinputname) + "]");
				inputdata.focus();
			}
		}

		/*if(e.keyCode === 8)
		{
			
			if(previnputname1 !== '' && previnputname1 !== null)
			{
				let previnput = document.querySelector("input[name="+(previnputname1)+"]");
				

				previnput.select();
			}
			


			//sendFocusBackInput(inputname1);	
		}*/
	}

	const sendFocusBackInput = (inputname1) => {

		const nextSibling = document.querySelector("input[name=" + (inputname1) + "]");
		
		if (nextSibling !== null) {
			
			if (inputname1 === 'qrstep5') {
				//document.querySelector("input[name=qrstep6]").focus();
				//setQRValue6("");

				//nextSibling.focus();
				nextSibling.select();

			}
			else {
				//nextSibling.focus();
				nextSibling.select();
			}

			//nextSibling.focus();

		}
	}

	const verifyQRCode = (e) => {

		//setSpinner(true);
		/*setIsLoader("d-block");
		setBtnVerify("");

		
		let entered_qrcode = qrvalue1 + qrvalue2 + qrvalue3 + qrvalue4 + qrvalue5 + qrvalue6;
		
		setVerifyCodeBool(false);

		let params = {
			authentication_code: entered_qrcode,
		};

		sendOTPVerification(params, setQRCodeError, setMdlCode, setBackupCode, setBackupCodeStar, setIsLoader, setBtnVerify);*/

		setMdlVerify(true);

	}

	const handlePasteCode = (e) => {

		const pasted = e.clipboardData.getData("text/plain");
		
		setQRValue1(pasted.split("").slice(0, 1));
		setQRValue2(pasted.split("").slice(1, 2));
		setQRValue3(pasted.split("").slice(2, 3));
		setQRValue4(pasted.split("").slice(3, 4));
		setQRValue5(pasted.split("").slice(4, 5));
		setQRValue6(pasted.split("").slice(5, 6));
	}

	const showBackupCodeNumber = (e) => {

		setBackupCodeStar(false);

		
		
	}

	const showBackupCodeStar = (e) => {

		setBackupCodeStar(true);
		
		
	}

	const copyBackupCode = (e) => {

		navigator.clipboard.writeText(backupcode);
		sucesstoast("Copied");
	}

	const handleCopyQRCode = (e) =>{
		navigator.clipboard.writeText(qrsecret);
		sucesstoast("Copied");
	}

	const handleBtnDone = (e) => {

		//setIsLoader("d-none");
		setQRRequest("");
		setMdlCode(false);
		setVerifyCodeBool(true);

		//setBtnVerify("Verify");

		setMdlQrCode(false);
		setMdlVerify(false);
		setMdlCode(false);

		//setShowUpdateTime(true);

		setIs2FAUpdate(!is2faupdate);

	}

	const handleBtnCancel = (e) => {

		setMdlCode(false);
		setVerifyCodeBool(true);

		setIsLoader("d-none");
		setQRRequest("");
		setVerifyCodeBool(true);

		setBtnVerify("Verify");
	}

	const handleDisable2FactorAuth = () => {

		//setVerifyCodeBool(false);

		setQRValue1("");
		setQRValue2("");
		setQRValue3("");
		setQRValue4("");
		setQRValue5("");
		setQRValue6("");

		let params = {};

		setIs2FDisableLoader("d-block");
		setBtn2FDisableTxt("");

		let createbtndiv = document.getElementById("btndisable2fid");
		createbtndiv.classList.add("btnloader");
		

		//disable2FactorAuth(params, setVerifyCodeBool, setQRRequest, setMdlHide2F);
		disable2FactorAuth(params, setIs2FDisableLoader, setBtn2FDisableTxt, setMdlHide2F, setVerifyCodeBool, setShowUpdateTime);
	}

	const handleCloseQrCodeModel = () =>{
		setMdlQrCode(false);
		setQRRequest("");

		setShowQrCodeSection(true);
		setShowVerifyCodeSection(false);
		setShowBackupCodeSection(false);
	}

	const handleBtnNext = () =>{


	}

	const handleBtnOpenVerifyModel = () =>{
		/*setMdlQrCode(false);
		setMdlVerify(true);
		setMdlCode(false);*/

		setShowQrCodeSection(false);
		setShowVerifyCodeSection(true);
		setShowBackupCodeSection(false);

		setQRValue1("");
		setQRValue2("");
		setQRValue3("");
		setQRValue4("");
		setQRValue5("");
		setQRValue6("");

		setQRCodeError(false);
		setQrCodeReq(false);

	}

	const handleCloseVerifyModel = () =>{
		setMdlQrCode(false);
		setMdlVerify(false);
		setMdlCode(false);
	}
	const handleBtnBackQrModel = () =>{
		/*setMdlQrCode(true);
		setMdlVerify(false);
		setMdlCode(false);*/

		setShowQrCodeSection(true);
		setShowVerifyCodeSection(false);
		setShowBackupCodeSection(false);
	}
	const handleBtnRecoveryCode = () =>{

		
		let entered_qrcode = qrvalue1 + qrvalue2 + qrvalue3 + qrvalue4 + qrvalue5 + qrvalue6;
		
		setVerifyCodeBool(false);

		if((qrvalue1 === '' || qrvalue1 === null ) && (qrvalue2 === '' || qrvalue2 === null ) && (qrvalue3 === '' || qrvalue3 === null ) && (qrvalue4 === '' || qrvalue4 === null ) && (qrvalue5 === '' || qrvalue5 === null ) && (qrvalue6 === '' || qrvalue6 === null ))
		{			
			setQRCodeError(true);
			setQrCodeReq(true);
		}
		else
		{
			setQRCodeError(false);
			setQrCodeReq(false);

			let params = {
				authentication_code: entered_qrcode,
				agency_id:localStorage.getItem('agency_id') ? parseInt(localStorage.getItem('agency_id')) : 1,
			};

			setIsRecoveryLoader("d-block");
			setBtnOpenRecoveryTxt("");

			let createbtndiv = document.getElementById("btnrecoveryid");
			createbtndiv.classList.add("btnloader");

			sendOTPVerification(params, setQRCodeError, setShowBackupCodeSection, setShowQrCodeSection, setShowVerifyCodeSection, setBackupCode, setBackupCodeStar, setIsRecoveryLoader, setBtnOpenRecoveryTxt, setUpdateTimeTxt);
		}

	}
	
	const handleOpenDisableModel = () =>{
		setMdlHide2F(true);

		setShowQrCodeSection(true);
		setShowVerifyCodeSection(false);
		setShowBackupCodeSection(false);
	}
	const handleCloseHide2FModel = () =>{

		setMdlHide2F(false);
	}


	const handleCloseCodeModel = () =>{
		setMdlCode(false);
		setMdlQrCode(false);
		setMdlVerify(false);
	}

	const handleRightPanelExpand = (rightpanelvalue) => {
		

		setRight_panel_expand(rightpanelvalue)

	}
	const handleEnableButton = () => {
		setTestModal(!testModal)

	}

	const handleChangePassword = (e) =>{

		if(oldpassword === '' || oldpassword === null)
		{
			setOldPasswordError("Required");
		}
		else
		{
			setOldPasswordError(null);
		}
		
		if(newpassword === '' || newpassword === null)
		{
			setNewPasswordError("Required");
		}
		else
		{
			setNewPasswordError(null);
		}

		if(confirmpassword === '' || confirmpassword === null)
		{
			setConfirmPasswordError("Required");
		}
		else
		{
			setConfirmPasswordError(null);
		}

		if(oldpassword !== '' && newpassword !== ''  && confirmpassword !== '')
		{
			const params = {
						old_password: btoa(oldpassword),
						new_password: btoa(newpassword),
						confirm_password: btoa(confirmpassword),
						agency_id:localStorage.getItem('agency_id') ? parseInt(localStorage.getItem('agency_id')) : 1
					};

			setIsPassLoader("d-block");
			setLabelPassSave("");
			//Changepasswordapi(params, this);
			Changepasswordapi(params, setOldPassword, setOldPasswordError, setNewPassword, setNewPasswordError, setConfirmPassword, setConfirmPasswordError, setIsPassLoader, setLabelPassSave, setPassProgress, setIsEyePassInput1, setIsEyePassInput2, setIsEyePassInput3,setPassUpdateTimeTxt, setShowPassUpdateTime);
		}
	}

	const handleSetOldPassword = (e) =>{

		setOldPassword(e.target.value);
		setOldPasswordError(null);

		if(e.target.value.length > 0)
		{
			setIsEyePassInput1(true);
			setIsEyePassInput2(true);
			setIsEyePassInput3(true);
		}
		else
		{
			setIsEyePassInput1(false);
			setIsEyePassInput2(false);
			setIsEyePassInput3(false);
		}
		
	}

	const handleSetNewPass = (e) =>{

		setNewPassword(e.target.value);
		setNewPasswordError(null);

		const passwordValue = e.target.value;
		const passwordLength = passwordValue.length;

		const weakRegExp = /[a-z]/;
		const almostRegExp = /[0-9]/;
		const strongRegExp = /[A-Z]/;

		var PoorPassword = false;
		var WeakPassword = false;
		var AlmostPassword = false;
		var StrongPassword = false;

		if (passwordLength >= 8) 
		{
			PoorPassword = true;
		}
		else
		{
			PoorPassword = false;
		}

		if (weakRegExp.test(passwordValue)) 
		{
			WeakPassword = true;
		} 
		else 
		{
			WeakPassword = false;
		}

		if (almostRegExp.test(passwordValue)) 
		{
			AlmostPassword = true;
		} 
		else 
		{
			AlmostPassword = false;
		}

		if (strongRegExp.test(passwordValue)) 
		{
			StrongPassword = true;
		} 
		else 
		{
			StrongPassword = false;
		}

		const arr1 = [PoorPassword, WeakPassword, AlmostPassword, StrongPassword];
		const count1 = arr1.filter((value) => value === true).length;
		
		//this.setState({ progress: count1 });
		setPassProgress(count1);
	}

	const handleSetConfPass = (e) =>{

		setConfirmPassword(e.target.value);
		setConfirmPasswordError(null);

	}

	const handleOldPasswordEye = (e, eyestatus) =>{
	
		if(eyestatus)
		{
			setPassAttributeType1("password");
			setPassInput1EyeOpen(true);
		}
		else
		{
			setPassAttributeType1("text");
			setPassInput1EyeOpen(false);
		}
	}
	const handleNewPasswordEye = (e, eyestatus) =>{

		if(eyestatus)
		{
			setPassAttributeType2("password");
			setPassInput2EyeOpen(true);
		}
		else
		{
			setPassAttributeType2("text");
			setPassInput2EyeOpen(false);
		}
	}

	const handleConfirmPasswordEye = (e, eyestatus) =>{

		if(eyestatus)
		{
			setPassAttributeType3("password");
			setPassInput3EyeOpen(true);
		}
		else
		{
			setPassAttributeType3("text");
			setPassInput3EyeOpen(false);
		}
	} 

	const  openProfileMenus=(e)=>{
		setOpen_profile_menu(!open_profile_menu);
	}

	const handleSignoutAllDevices = () =>{

		const params = {};
		
		setIsSignoutLoader("d-block");
		setSignoutTxt("");
		
		SignoutAllDevices(params, redirectToLoginPage, setIsSignoutLoader, setSignoutTxt);
	}

	const redirectToLoginPage = () =>{

        props.history.push("/sign-in");
    }
	
	const handleSignoutPopupShow = () =>{
		setMdlSignoutPopup(!mdlsignoutpopoup);
	}
	
	const handleCloseSignoutPopup = () =>{
		setMdlSignoutPopup(false);		
	}


	return (
		
		<>
			{/* <MobileProfileMenu openProfileMenus={openProfileMenus}/> */}
			<Header openProfileMenus={openProfileMenus}/>
				<section className="portal-body">
				
				<ProfileLeftPanel rightpanelbody={handleRightPanelExpand} profile={""} />


				<div className={(localStorage.getItem('sidebaserotate') !== null && localStorage.getItem('sidebaserotate') !== undefined &&
           localStorage.getItem('sidebaserotate') === 'true')  ? "right-panel-gap right-panel-gap-expand"
            : "right-panel-gap "}>
					{/* <h1 className="d-none d-md-block mb-0">My Profile</h1>
					<p className=" d-none d-md-block mb-4 pl-3 pl-md-0 pr-3 pr-md-0">
						Add your personal details to update your account.{" "}
						<a href="https://support.appmysite.com" target="_blank" rel="noopener noreferrer" >
							Learn More{" "}
							<img src={require("../assets/images/dashboard/Goto.png").default} className="gotoappicon appear-spacing" alt="Goto" />
						</a>
						​{" "}
					</p> */}

					<div className="right_panel_profile pl-0 pr-0 pb-0" style={{ minHeight: "auto" }} >

						<div className="right_general ">

							<div className="my-security-page mt-md-0 ">
							<div className="div-security-page">
											<div className="d-flex align-items-center security-header">
												<h1>Security</h1>
												<p className="security-tooltip">

													<img src={ require("../assets/images/dashboard/Billing/billingexclamation.png").default } alt="app-icon"
														className="" />
													<p className="security-tooltipsubheader">
														Protect your account with a strong password and two-factor authentication (2FA).
													</p>

												</p>
											</div>
										</div>
								<div className=" security-myprofile-shadow" style={{ overflow: "visible" }}>

									{spinner ?
										(
											<div className=" security-spinner" >
												<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{ margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s" }} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
													<g transform="translate(20 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<circle cx="0" cy="0" r="6" fill="#e6261f" style={{ animationplaystate: "running", animationdelay: "0s" }}>
															<animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{ animationplaystate: "running", animationdelay: "0s" }}>
															</animateTransform>
														</circle>
													</g>
													<g transform="translate(40 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<circle cx="0" cy="0" r="6" fill="#f7d038" style={{ animationplaystate: "running", animationdelay: "0s" }}>
															<animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{ animationplaystate: "running", animationdelay: "0s" }}>
															</animateTransform>
														</circle>
													</g>
													<g transform="translate(60 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<circle cx="0" cy="0" r="6" fill="#49da9a" style={{ animationplaystate: "running", animationdelay: "0s" }}>
															<animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{ animationplaystate: "running", animationdelay: "0s" }}>
															</animateTransform>
														</circle>
													</g>
													<g transform="translate(80 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<circle cx="0" cy="0" r="6" fill="#4355db" style={{ animationplaystate: "running", animationdelay: "0s" }}>
															<animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{ animationplaystate: "running", animationdelay: "0s" }}>
															</animateTransform>
														</circle>
													</g>
												</svg>
											</div>
										)
										:

										
										<div className="div-security-page"> 
									<div className="security-firstdiv ">
										<div className=" d-flex align-items-center justify-content-between security-firstdiv-head">

											<div className="">
												<h1>Change password</h1>
												<p>
													Changing your password will sign you out of all devices.
												</p>
											</div>
											<div className="security-websave-btn">
												{ showpassupdatetime &&
													<p className="last-enable-text">Updated { moment( passupdatetimetxt ).format("D MMMM YYYY, HH:mm") }</p>
												}

												{ (oldpassword !== '' || newpassword !== '' || confirmpassword !== '') ?
													<button className="security-twofa-enable" onClick={handleChangePassword} id="btnsavepassid" style={{backgroundColor:localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor')}}>
														<div className={ispassloader}>
															{/*<div className="new-loader"></div>*/}
															<img src={require("../assets/images/signup/Loader_GIF.gif").default} alt="loader" />
														</div>
														{labelpasssave}														
													</button>
												:
													<button className="changes-save" style={{backgroundColor:localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color')}}>
														<div className="d-flex align-items-center justify-content-center h-100 w-100">
															<div className={loaderclass}>
																<img src={require("../assets/images/signup/Loader_GIF.gif").default} alt="loader" />
															</div>{" "}
															<div>{labelsavebutton}</div>
														</div>
													</button>
												}
											</div>

										</div>
										<div className="security-passwordvarification">
											<div className="input-password-set">
												<div className="">
													<h4>Current password*</h4>
													<div className="form-group">
														<input type={passattibutetype1} className={ oldpassworderror ? "form-control form-nameone security-validate-input" : "form-control form-nameone"} form="form1" maxLength={72} value={oldpassword} onChange={handleSetOldPassword} onPaste={(e) => {e.preventDefault();return false;}} onCopy={(e) => { e.preventDefault(); return false; }} required />
														
														{iseyepassinput1 &&
															<div className="eyeopenclose">
																{passinput1eyeopen ?
																	<img src={ require("../../src/assets/images/signup/securityeyeclose.png").default } alt="app-icon" className="" onClick={(e) =>handleOldPasswordEye(e, false) } />
																:
																	<img src={ require("../../src/assets/images/signup/securityeyeopen.png").default } alt="app-icon" className="" onClick={(e) =>handleOldPasswordEye(e, true) } />
																}
															</div>
														}
														{/* <div className="security-eye">
														

															<svg id="Group_34968" data-name="Group 34968" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="23.135" height="15" viewBox="0 0 23.135 15">
																<defs>
																	<clipPath id="clip-path">
																		<rect id="Rectangle_25429" data-name="Rectangle 25429" width="23.135" height="15" fill="none" stroke="#7782a1" stroke-width="1" />
																	</clipPath>
																</defs>
																<g id="Group_34967" data-name="Group 34967" clip-path="url(#clip-path)">
																	<path id="Path_98214" data-name="Path 98214" d="M22.568,7.5a12.62,12.62,0,0,1-11,7,12.643,12.643,0,0,1-11-7,12.643,12.643,0,0,1,11-7A12.62,12.62,0,0,1,22.568,7.5Z" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" />
																	<circle id="Ellipse_7711" data-name="Ellipse 7711" cx="3.5" cy="3.5" r="3.5" transform="translate(8.068 4)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" />
																</g>
															</svg>


															<svg id="Group_34966" data-name="Group 34966" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="23.135" height="18.707" viewBox="0 0 23.135 18.707">
																<defs>
																	<clipPath id="clip-path">
																		<rect id="Rectangle_25428" data-name="Rectangle 25428" width="23.135" height="18.707" fill="none" stroke="#7782a1" stroke-width="1" />
																	</clipPath>
																</defs>
																<g id="Group_34965" data-name="Group 34965" transform="translate(0 0)" clip-path="url(#clip-path)">
																	<path id="Path_98213" data-name="Path 98213" d="M22.568,9.354a12.62,12.62,0,0,1-11,7,12.643,12.643,0,0,1-11-7,12.643,12.643,0,0,1,11-7A12.62,12.62,0,0,1,22.568,9.354Z" transform="translate(0 0)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" />
																	<ellipse id="Ellipse_7710" data-name="Ellipse 7710" cx="3.5" cy="3.5" rx="3.5" ry="3.5" transform="translate(8.068 5.853)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" />
																	<line id="Line_360" data-name="Line 360" x2="18" y2="18" transform="translate(2.568 0.353)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" />
																</g>
															</svg>
														</div> */}
														
														{ oldpassworderror &&
														<div className="d-flex security-validation">
															<svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
																<path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848" />
																<path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1" />
																<path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1" />
															</svg>

															<p className="security-redinputs">{oldpassworderror}</p>
														</div>
														}
													</div>
												</div>
												<div className="">
													<h4>New password*</h4>
													<div className="form-group">
														<input type={passattibutetype2} className={ newpassworderror ? "form-control form-nameone security-validate-input" : "form-control form-nameone"} form="form1" maxLength={72} value={newpassword} onChange={handleSetNewPass} onPaste={(e) => { e.preventDefault(); return false; }} onCopy={(e) => { e.preventDefault(); return false; }} required />
														
														{iseyepassinput2 &&
														<div className="eyeopenclose">
															{ passinput2eyeopen ?
																<img src={ require("../../src/assets/images/signup/securityeyeclose.png").default } alt="app-icon" className="" onClick={(e) =>handleNewPasswordEye(e, false) } />
															:
                   												<img src={ require("../../src/assets/images/signup/securityeyeopen.png").default } alt="app-icon" className="" onClick={(e) =>handleNewPasswordEye(e, true) } />
															}
														</div>
														}

														{newpassworderror &&
														<div className="d-flex security-validation">
															<svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
																<path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848" />
																<path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1" />
																<path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1" />
															</svg>

															<p className="security-redinputs">{newpassworderror}</p>
														</div>
														}
													</div>
												</div>
												<div className="">
													<h4>Verify new password*</h4>
													<div className="form-group">
														<input type={passattibutetype3} className={ confirmpassworderror ? "form-control form-nameone security-validate-input" : "form-control form-nameone"} form="form1" maxLength={72} value={confirmpassword} onChange={handleSetConfPass} onPaste={(e) => { e.preventDefault(); return false; }} onCopy={(e) => { e.preventDefault(); return false; }} required />
														
														{ iseyepassinput3 &&
														<div className="eyeopenclose">
															{ passinput3eyeopen ?
																
																<img src={ require("../../src/assets/images/signup/securityeyeclose.png").default } alt="app-icon" className="" onClick={(e) =>handleConfirmPasswordEye(e, false) } />
															:
                   												
																<img src={ require("../../src/assets/images/signup/securityeyeopen.png").default } alt="app-icon" className="" onClick={(e) =>handleConfirmPasswordEye(e, true) } />
															}
															
														</div>
														}
														
														
														{ confirmpassworderror &&
														<div className="d-flex security-validation">
															<svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
																<path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848" />
																<path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1" />
																<path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1" />
															</svg>

															<p className="security-redinputs">{confirmpassworderror}</p>
														</div>
														}
													</div>
												</div>
											</div>
											<div className="password-set-contain">
												<h2> Your password must contain:</h2>
												<div className="d-flex">
													{passprogress === 4 ?
														<>
															<p className="required-contain-correct"></p>
															<p className="required-contain-correct"></p>
															<p className="required-contain-correct"></p>
															<p className="required-contain-correct"></p>
														</>
													:
														(passprogress === 3 ?
															<>
																<p className="required-contain-semicorrectlight"></p>
																<p className="required-contain-semicorrectlight"></p>
																<p className="required-contain-semicorrectlight"></p>
																<p className="required-contain-filled"></p>
															</>
														:
															(passprogress === 2 ?
																<>
																	<p className="required-contain-semicorrect"></p>
																	<p className="required-contain-semicorrect"></p>
																	<p className="required-contain-filled"></p>
																	<p className="required-contain-filled"></p>
																</>
															:
																(passprogress === 1 ?
																	<>
																		<p className="required-contain-notcorrect"></p>
																		<p className="required-contain-filled"></p>
																		<p className="required-contain-filled"></p>
																		<p className="required-contain-filled"></p>
																	</>
																:
																	(passprogress === 0 ?
																		<>
																			<p className="required-contain-filled"></p>
																			<p className="required-contain-filled"></p>
																			<p className="required-contain-filled"></p>
																			<p className="required-contain-filled"></p>
																		</>
																	:
																		<>
																			<p className="required-contain-filled"></p>
																			<p className="required-contain-filled"></p>
																			<p className="required-contain-filled"></p>
																			<p className="required-contain-filled"></p>
																		</>
																	)
																)
															)
														)
													}
													
													{/*<p className="required-contain-filled"></p>
													<p className="required-contain-correct"></p>
												<p className="required-contain-correct"></p>*/}

												</div>

												<ul className="security-contain-list">
													{ newpassword.length > 7 ?
														<li className="ticklist">a minimum of 8 to a maximum of 72 characters</li>
													:
														<li className="notticklist">a minimum of 8 to a maximum of 72 characters</li>
													}

													{ /[A-Z]/.test(newpassword) ?
														<li className="ticklist">at least 1 upper case letter (A-Z)</li>
													:
														<li className="notticklist">at least 1 upper case letter (A-Z)</li>
													}

													{ /[0-9]/.test(newpassword) ? 
														<li className="ticklist">at least 1 number (0-9)</li>
													:
														<li className="notticklist">at least 1 number (0-9)</li>
													}

													{ /[a-z]/.test(newpassword) ?
														<li className="ticklist">at least 1 lower case letter (a-z)</li>
													:
														<li className="notticklist">at least 1 lower case letter (a-z)</li>
													}
																										
												</ul>
											</div>

											<div className="security-mob-save-btn">
												{/*<button className="changes-save-mobview">Save</button>*/}

												{ (oldpassword !== '' || newpassword !== '' || confirmpassword !== '') ?
													<button className="security-twofa-enable-mobbutton" style={{backgroundColor:localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color')}} onClick={handleChangePassword} id="btnsavepassid">
														<div className={ispassloader}>
															{/*<div className="new-loader"></div>*/}
															<img src={require("../assets/images/signup/Loader_GIF.gif").default} alt="loader" />
														</div>
														{labelpasssave}														
													</button>
												:
													<button className="changes-save-mobview" style={{backgroundColor:localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color')}}>
														<div className="d-flex align-items-center justify-content-center h-100 w-100">
															<div className={loaderclass}>
																<img src={require("../assets/images/signup/Loader_GIF.gif").default} alt="loader" />
															</div>{" "}
															<div>{labelsavebutton}</div>
														</div>
													</button>
												}

												{/*<p className="security-twofa-updatemob-text">Updated 10 April 2023, 11:45</p>*/}
												{ showpassupdatetime &&
													<p className="security-twofa-updatemob-text">Updated { moment( passupdatetimetxt ).format("D MMMM YYYY, HH:mm") }</p>
												}
											</div>
										</div>



									</div>
									<div className="security-seconddiv">
										<div className=" d-flex align-items-center justify-content-between">

											<div>
												<h1>Two-factor authentication</h1>
												<p className="twofa-para">
													2FA protects your account even if your password is compromised.
												</p>
											</div>
											<div className="security-web-enablebtn ">
												{showupdatetime &&
													<p className="last-enable-text">Updated { moment( updatetimetxt ).format("D MMMM YYYY, HH:mm") }</p>
												}

													{/*qrrequest === '' ?
														<button className="security-twofa-enable"  onClick={ generateQRCode }>
															<div className={isLoader}>
													
																<div className="new-loader"></div>
															</div>
															{btnenable}
														</button>
													:
														<button className="security-twofa-enable" onClick={ verifyQRCode }>
															<div className={isLoader}>
													
																<div className="new-loader"></div>
															</div>
															{btnverify}
														</button>
													*/}

												{ verifycodebool === false ?

													<button className="security-twofa-enable" style={{backgroundColor:localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color')}}  onClick={ generateQRCode }>
														<div className={isLoader}>
												
															<div className="new-loader"></div>
														</div>
														{btnenable}
													</button>
												:

													<button className={ ((verifycodebool === false) ? "security-twofa-disable d-none" : "security-twofa-disable")} style={{backgroundColor:localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color')}} onClick={handleOpenDisableModel}>Disable</button>
													
												}
											</div>

										</div>
										
										{verifycodebool &&
										<div className="security-twofa-enableddiv">
											<div className="d-flex security-twofa-tick">
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
													<g id="Group_27505" data-name="Group 27505" transform="translate(-942 -504)">
														<circle id="Ellipse_2306" data-name="Ellipse 2306" cx="12" cy="12" r="12" transform="translate(942 504)" fill="#47ce70" />
														<path id="Path_7161" data-name="Path 7161" d="M-19252.924-4211.554l3.537,3.535,6.652-6.654" transform="translate(20201.877 4727.391)" fill="none" stroke="#fff" stroke-width="2.5" />
													</g>
												</svg>

												<p>Two-factor authentication is currently enabled.</p>
											</div>

										</div>
										}

										{ verifycodebool === false &&
										<div className="twofa-steps ">
											
												<>
													<div className="security-stepone d-flex ">
														<div>

															<svg xmlns="http://www.w3.org/2000/svg" width="40" height="64" viewBox="0 0 40 64">
																<g id="Group_35041" data-name="Group 35041" transform="translate(-300 -689)">
																	<g id="Rectangle_25455" data-name="Rectangle 25455" transform="translate(300 689)" fill="#fff" stroke="#e3e6f1" stroke-width="3">
																		<rect width="40" height="64" rx="6" stroke="none" />
																		<rect x="1.5" y="1.5" width="37" height="61" rx="4.5" fill="none" />
																	</g>
																	<path id="Union_2469" data-name="Union 2469" d="M20967-9881v-3h16v3Zm0-14h5v-8h6v8h5l-8,9Z" transform="translate(-20655 10613)" fill="#bcc1ce" />
																	<path id="Rectangle_25564" data-name="Rectangle 25564" d="M0,0H14a0,0,0,0,1,0,0V0a3,3,0,0,1-3,3H3A3,3,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(313 692)" fill="#e3e6f1" />
																	<rect id="Rectangle_25565" data-name="Rectangle 25565" width="10" height="2" rx="1" transform="translate(315 746)" fill="#e3e6f1" />
																</g>
															</svg>
														</div>
														<div className="stepsnumber">
															<h2>Step 1</h2>
															<p>Download and install an authenticator app on your mobile.
																We recommend Authy or Google Authenticator.</p>
														</div>
													</div>
													<div className="security-steptwo d-flex ">
														<div>
															<svg xmlns="http://www.w3.org/2000/svg" width="58" height="58.002" viewBox="0 0 58 58.002">
																<g id="Group_35040" data-name="Group 35040" transform="translate(-831 -690.999)">
																	<path id="Union_2462" data-name="Union 2462" d="M21013.992-9846v-2h8v-8h2v10Zm-47.992,0v-10h2v8h8v2Zm55.994-48v-8h-8v-2h10v10Zm-55.994,0v-10h10v2h-8v8Z" transform="translate(-20135 10595)" fill="#e3e6f1" />
																	<path id="Union_2467" data-name="Union 2467" d="M21001-9866.006h-11V-9869h11v-8.008h-6V-9880h9v14Zm-17,0v-3h3v3Zm-6,0h-11v-14h14v14Zm-8-2.994h8v-8.008h-8Zm25-2v-4h4v4Zm-23,0v-4h4v4Zm12-3v-9h-11v-3h17v3h-3v3h3v3h-3v3Zm11-9v-3h9v3Zm-28,0v-3h3v3Zm17-5.994v-8h3v8Zm17,0h-11v-14h14v14Zm-8-2.994h8v-8.007h-8Zm-15,2.994h-11v-14h14v14Zm-8-2.994h8v-8.007h-8Zm25-2v-4h4v4Zm-23,0v-4h4v4Zm12-6v-3h3v3Z" transform="translate(-20125 10605)" fill="#bcc1ce" />
																</g>
															</svg>
														</div>
														<div className="stepsnumber">
															<h2>Step 2</h2>
															<p>Use the authenticator app to scan the QR code.</p>
														</div>
													</div>
													<div className="security-stepthree d-flex ">
														<div>
															<svg xmlns="http://www.w3.org/2000/svg" width="58.393" height="54" viewBox="0 0 58.393 54">
																<g id="Group_35039" data-name="Group 35039" transform="translate(-1307.001 -887)">
																	<g id="Ellipse_7722" data-name="Ellipse 7722" transform="translate(1320.001 887)" fill="#fff" stroke="#bcc1ce" stroke-width="2">
																		<circle cx="16" cy="16" r="16" stroke="none" />
																		<circle cx="16" cy="16" r="15" fill="none" />
																	</g>
																	<path id="Path_98241" data-name="Path 98241" d="M-19622,10799.122l4.287,4.285,8.713-8.712" transform="translate(20951.5 -9896.051)" fill="none" stroke="#bcc1ce" stroke-width="3" />
																	<path id="Union_2471" data-name="Union 2471" d="M20971.7-9891v-4.268l-3.7,2.135-1-1.732,3.7-2.135-3.7-2.135,1-1.732,3.7,2.135V-9903h2v4.267l3.7-2.135,1,1.732-3.7,2.135,3.7,2.135-1,1.732-3.7-2.135V-9891Z" transform="translate(-19660 10832)" fill="#bcc1ce" />
																	<path id="Union_2472" data-name="Union 2472" d="M20971.7-9891v-4.268l-3.7,2.135-1-1.732,3.7-2.135-3.7-2.135,1-1.732,3.7,2.135V-9903h2v4.267l3.7-2.135,1,1.732-3.7,2.135,3.7,2.135-1,1.732-3.7-2.135V-9891Z" transform="translate(-19643.998 10832)" fill="#bcc1ce" />
																	<path id="Union_2473" data-name="Union 2473" d="M20971.7-9891v-4.268l-3.7,2.135-1-1.732,3.7-2.135-3.7-2.135,1-1.732,3.7,2.135V-9903h2v4.267l3.7-2.135,1,1.732-3.7,2.135,3.7,2.135-1,1.732-3.7-2.135V-9891Z" transform="translate(-19613 10832)" fill="#bcc1ce" />
																	<path id="Union_2474" data-name="Union 2474" d="M20971.7-9891v-4.268l-3.7,2.135-1-1.732,3.7-2.135-3.7-2.135,1-1.732,3.7,2.135V-9903h2v4.267l3.7-2.135,1,1.732-3.7,2.135,3.7,2.135-1,1.732-3.7-2.135V-9891Z" transform="translate(-19628.002 10832)" fill="#bcc1ce" />
																</g>
															</svg>
														</div>
														<div className="stepsnumber">
															<h2>Step 3</h2>
															<p>Enter the code from the authenticator app and verify.</p>
														</div>
													</div>
												</>
											

											{/*<div className="security-enable-mob-btn">
												<button className="security-twofa-enable-mobbutton" onClick={handleEnableButton}>Enable</button>
												<button className="security-twofa-disable-mobbutton" onClick={handleEnableButton}>Disable</button>
												<p className="security-twofa-updatemob-text">Updated 10 April 2023, 11:45</p>
											</div>*/}

											
										</div>
										}

										<div className="security-enable-mob-btn ">												
											{ verifycodebool === false ?
												
												<button className="security-twofa-enable-mobbutton" style={{backgroundColor:localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color')}}  onClick={ generateQRCode }>
													<div className={isLoader}>																
														<div className="new-loader"></div>
													</div>
													{btnenable}
												</button>
											:
												<>
													<button style={{backgroundColor:localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color')}} className={ ((verifycodebool === false) ? "security-twofa-disable-mobbutton d-none" : "security-twofa-disable-mobbutton")} onClick={handleOpenDisableModel}>Disable</button>
													
													{/*<p className="last-enable-text">Updated 10 April 2023, 11:45</p>*/}
													{showupdatetime &&
														<p className="last-enable-text">Updated { moment( updatetimetxt ).format("D MMMM YYYY, HH:mm") }</p>
													}
												</>
											}
											
										</div>

									</div>
									
									{sessiondata.length > 0 &&
									<div className="security-thirddiv">
										<div className="  align-items-center  thirddivhead">

											<div>
												<h1>Sessions</h1>
												<p>
													Devices you have logged in from.
												</p>
											</div>
											<div>
												<button className="security-signout-all" onClick={handleSignoutPopupShow}>Sign out of all devices</button>
											</div>

										</div>
											
										<div className="web-view-lastSeenTable">
											
											<div className="security-tablehead">
												<p className="System">System</p>
												<p className="Browser">Browser</p>
												<p className="Location"> Location</p>
												<p className="First-seen"> First seen</p>
												<p className="Last-seen">Last seen</p>
												{/*<p className="current"></p>*/}
											</div>
											
											{sessiondata.length > 0 &&
												sessiondata.map((sessionlist, sessionkey)=>{
												
													return (
													<div className="security-tabledata" key={sessionkey}>
														<p className="System">{ sessionlist.opsystem }</p>
														<p className="Browser">{ sessionlist.browser }</p>
														<div className="Location ">
															<p> {sessionlist.ip} </p>
															<span>{sessionlist.country_code} ({sessionlist.region_code})</span>
														</div>

														<p className="First-seen"> { moment( sessionlist.created_at ).format("D MMMM YYYY, HH:mm") } </p>
														
														{ (sessionlist.last_seen_datetime !== undefined || sessionlist.last_seen_datetime !== null || sessionlist.last_seen_datetime !== '') ?
														
															<p className="Last-seen">{ moment( sessionlist.last_seen_datetime ).format("D MMMM YYYY, HH:mm") } </p>
														:
															<p className="Last-seen">{ moment( sessionlist.updated_at ).format("D MMMM YYYY, HH:mm") } </p>
														}
														{/*<p className="current">Current session</p>*/}
													</div>
													)
												})
											}
										</div>
																					

										{sessiondata.length > 0 &&
											sessiondata.map((sessionlist, sessionkey)=>{
											
												return (
												<div className="mobile-view-lastSeenTable ">
												<div className="d-grid security-tbrow">
													<p className="security-mob-tbhd">System</p>
													<div className="d-flex justify-content-between">
														<p className="security-mob-tbtr">{ sessionlist.opsystem }</p>
														{/*<p className="mob-latest-login">Current session</p>*/}
													</div>
												</div>

												<div className="security-tbrow">
													<p className="security-mob-tbhd">Browser</p>
													<p className="security-mob-tbtr">{ sessionlist.browser }</p>
												</div>

												<div className="security-tbrow">
													<p className="security-mob-tbhd"> Location</p>
													<div>
														<p className="security-mob-tbtr"> {sessionlist.ip}</p>
														<span>US (NY)</span>
													</div>
												</div>

												<div className="security-tbrow">
													<p className="security-mob-tbhd"> First seen</p>
													<p className="security-mob-tbtr"> { moment( sessionlist.created_at ).format("D MMMM YYYY, HH:mm") }</p>
												</div>

												<div className="security-tbrow">
													<p className="security-mob-tbhd">Last seen</p>
													{ (sessionlist.last_seen_datetime !== undefined || sessionlist.last_seen_datetime !== null || sessionlist.last_seen_datetime !== '') ?
														
														<p className="security-mob-tbtr">{ moment( sessionlist.last_seen_datetime ).format("D MMMM YYYY, HH:mm") } </p>
													:
														<p className="security-mob-tbtr">{ moment( sessionlist.updated_at ).format("D MMMM YYYY, HH:mm") } </p>
													}
												</div>
												</div>
												)
											})
										}

										

									</div>
									}
									</div>

									}
                                   
								</div>



							</div>
						</div>
					</div>
					{/* {Foot()} */}
				</div>
			</section>

			<Modal isOpen={mdlqrcode}  style={window.matchMedia("(max-width: 1200px)").matches ? modelmobile : modeldesktop } dataclass="" className="modal-recovery-key" centered={ window.matchMedia("(max-width: 1200px)").matches ?  true: false }>
				<ModalHeader >
					{!showbackupcodesection &&
					<div className="security-crossmodal " onClick={handleCloseQrCodeModel}>
						<img src={require("../assets/images/dashboard/cross.png").default} />
					</div>
					}
				</ModalHeader>
				<ModalBody>
					{showqrcodesection &&
					<div id="qrcodesection">
						<div className="security-code-popup-body">

							<h4>Scan the code</h4>
							<h6>
								Use your authenticator app to scan the QR code, or manually enter the text displayed.
							</h6>

							<div className="security-qr-code-list" id="backupdivid">

								<div className="qrcode-div">
									
									{ qrrequest !== '' ?	
										<QRCodeCanvas size={120} value={qrrequest} />
									:
										<div className="security-without-qr">
                                            <div className="new-loader-qr"></div>
										</div>
									}

								</div>


								<div className="security-qr-controls">

									<h1>or type in</h1>
									<div className="d-flex">
										{ qrrequest !== '' &&
											<>
												<p>{qrsecret}</p>
											
												<svg id="Group_26180" data-name="Group 26180" xmlns="http://www.w3.org/2000/svg" width="14.001" height="14" viewBox="0 0 14.001 14" onClick={ handleCopyQRCode }>
													<g id="Path_9276" data-name="Path 9276" transform="translate(-450.5 -124.5)" fill="none" stroke-miterlimit="10">
														<path d="M461.5,125.923V128.5h-5.72a1.189,1.189,0,0,0-1.28,1.28v5.72h-2.577a1.419,1.419,0,0,1-1.423-1.423v-8.154a1.419,1.419,0,0,1,1.423-1.423h8.154A1.419,1.419,0,0,1,461.5,125.923Z" stroke="none" />
														<path d="M 451.9228515625 125.5000152587891 C 451.6857299804688 125.5000152587891 451.5 125.6857452392578 451.5 125.9228668212891 L 451.5 134.0771636962891 C 451.5 134.3142700195312 451.6857299804688 134.5 451.9228515625 134.5 L 453.5 134.5 L 453.5 129.7800140380859 C 453.5 128.4375762939453 454.4375610351562 127.5 455.7799682617188 127.5 L 460.5 127.5 L 460.5 125.9228668212891 C 460.5 125.6857452392578 460.3142395019531 125.5000152587891 460.0771179199219 125.5000152587891 L 451.9228515625 125.5000152587891 M 451.9228515625 124.5000152587891 L 460.0771179199219 124.5000152587891 C 460.8656616210938 124.5000152587891 461.5 125.1343078613281 461.5 125.9228668212891 L 461.5 128.5 L 455.7799682617188 128.5 C 454.9913940429688 128.5 454.5 128.991455078125 454.5 129.7800140380859 L 454.5 135.5 L 451.9228515625 135.5 C 451.13427734375 135.5 450.5 134.8657531738281 450.5 134.0771636962891 L 450.5 125.9228668212891 C 450.5 125.1343078613281 451.13427734375 124.5000152587891 451.9228515625 124.5000152587891 Z" stroke="none" fill="#7782a1" />
													</g>
													<g id="Path_9277" data-name="Path 9277" transform="translate(-452.499 -126.5)" fill="none" stroke-miterlimit="10">
														<path d="M466.5,130.923v8.154a1.419,1.419,0,0,1-1.423,1.423h-8.154a1.419,1.419,0,0,1-1.423-1.423v-8.154a1.419,1.419,0,0,1,1.423-1.423h8.154A1.419,1.419,0,0,1,466.5,130.923Z" stroke="none" />
														<path d="M 456.9228210449219 130.5000152587891 C 456.6856994628906 130.5000152587891 456.4999694824219 130.6857452392578 456.4999694824219 130.9228515625 L 456.4999694824219 139.0771484375 C 456.4999694824219 139.3142700195312 456.6856994628906 139.5000152587891 456.9228210449219 139.5000152587891 L 465.0771179199219 139.5000152587891 C 465.3142395019531 139.5000152587891 465.4999694824219 139.3142700195312 465.4999694824219 139.0771484375 L 465.4999694824219 130.9228515625 C 465.4999694824219 130.6857452392578 465.3142395019531 130.5000152587891 465.0771179199219 130.5000152587891 L 456.9228210449219 130.5000152587891 M 456.9228210449219 129.5000152587891 L 465.0771179199219 129.5000152587891 C 465.8656921386719 129.5000152587891 466.4999694824219 130.1342926025391 466.4999694824219 130.9228515625 L 466.4999694824219 139.0771484375 C 466.4999694824219 139.86572265625 465.8656921386719 140.5000152587891 465.0771179199219 140.5000152587891 L 456.9228210449219 140.5000152587891 C 456.1342163085938 140.5000152587891 455.4999694824219 139.86572265625 455.4999694824219 139.0771484375 L 455.4999694824219 130.9228515625 C 455.4999694824219 130.1342926025391 456.1342163085938 129.5000152587891 456.9228210449219 129.5000152587891 Z" stroke="none" fill="#7782a1" />
													</g>
												</svg>
											</>
										}
									</div>

								</div>

							</div>
						</div>
						<div className="security-code-popup-footer">
							<button onClick={handleCloseQrCodeModel} className="modal-firstcancle">Cancel</button>
							<button onClick={handleBtnOpenVerifyModel}>
								<div className={isverifyloader}>
									<img src={require("../assets/images/signup/Loader_GIF.gif").default} alt="loader" />
								</div>
								{btnopenverifytxt}
							</button>
						</div>
					</div>
					}

					{showverifycodesection &&
					<div id="verifyqrcodesection" class="">
						<div className="security-code-popup-body">

							<h4>Verify code</h4>
							<h6 className="security-code-input-head">
							Type in the digits generated by your authenticator app.
							</h6>

							<div className="security-inputbox-div" id="backupdivid">

								<h2>Enter code</h2>
								<div>
									<input className={qrcodeerror ? "security-enter-code varify-code-error" : "security-enter-code"} type="text" name="qrstep1" id="qrstep1" value={qrvalue1} onChange={ handleQRValue1 } maxLength="1" onKeyUp={(e) => handleRemoveCode(e, "", "qrstep1")} onPaste={ handlePasteCode }  autoComplete="off" tabIndex="1"  inputMode="numeric"/>

									<input className={qrcodeerror ? "security-enter-code varify-code-error" : "security-enter-code"} type="text" name="qrstep2" id="qrstep2" value={qrvalue2} onChange={ handleQRValue2 } maxLength="1" onKeyUp={(e) => handleRemoveCode(e, "qrstep1", "qrstep2")} onPaste={ handlePasteCode }  autoComplete="off" tabIndex="2"  inputMode="numeric"/>

									<input className={qrcodeerror ? "security-enter-code varify-code-error" : "security-enter-code"} type="text" name="qrstep3" id="qrstep3" value={qrvalue3} onChange={ handleQRValue3 } maxLength="1" onKeyUp={(e) => handleRemoveCode(e, "qrstep2", "qrstep3")} onPaste={ handlePasteCode }  autoComplete="off" tabIndex="3"  inputMode="numeric"/>

									<input className={qrcodeerror ? "security-enter-code varify-code-error" : "security-enter-code"} type="text" name="qrstep4" id="qrstep4" value={qrvalue4} onChange={ handleQRValue4 } maxLength="1" onKeyUp={(e) => handleRemoveCode(e, "qrstep3", "qrstep4")} onPaste={ handlePasteCode }  autoComplete="off" tabIndex="4"  inputMode="numeric"/>

									<input className={qrcodeerror ? "security-enter-code varify-code-error" : "security-enter-code"} type="text" name="qrstep5" id="qrstep5" value={qrvalue5} onChange={ handleQRValue5 } maxLength="1" onKeyUp={(e) => handleRemoveCode(e, "qrstep4", "qrstep5")} onPaste={ handlePasteCode } autoComplete="off" tabIndex="5"  inputMode="numeric"/>

									<input className={qrcodeerror ? "security-enter-code varify-code-error" : "security-enter-code"} type="text" name="qrstep6" id="qrstep6" value={qrvalue6} onChange={ handleQRValue6 } maxLength="1" onKeyUp={(e) => handleRemoveCode(e, "qrstep5", "qrstep6")} onPaste={ handlePasteCode } autoComplete="off" tabIndex="6"  inputMode="numeric"/>
								</div>
								
								{qrcodeerror &&
								<div className="d-flex security-code-red">
									<svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
										<path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"/>
										<path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"/>
										<path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"/>
									</svg>
									
									{ qrcodereq ? 
										<p className="security-redinput">Required</p>
									:
										<p className="security-redinput">Incorrect or timed out code. Please try again.</p>
									}
									
								</div>
								}

							</div>
						</div>
						<div className="security-code-popup-footer">
							<button onClick={handleBtnBackQrModel}>Back</button>
							<button onClick={handleBtnRecoveryCode} id="btnrecoveryid">
								<div className={isrecoveryloader}>
									<img src={require("../assets/images/signup/Loader_GIF.gif").default} alt="loader" />
								</div>
								{btnopenrecoverytxt}
							</button>
						</div>
					</div>
					}

					{showbackupcodesection &&
					<div id="backupcodesection" class="">
						<div className="security-code-popup-body">

							<h4>Recovery key</h4>
							<h6>
								In case you don't have access to your authenticator app, you can use this key instead. Each key can be used only once. Please make sure you copy and store it somewhere safe.
							</h6>

							<div className="security-code-list" id="backupdivid">

								<div>
									{backupcodestar === false ?
										<p className="security-code-xx">{ backupcode }</p>
									:									
										<p className="security-code-xx">XXXX XXXX XXXX XXXX</p>
									}

								</div>


								<div className="security-code-controls">

									{ backupcodestar ?
										
										<img className="img-fluid" src={require("../assets/images/signup/securityeyeclose.png").default} onClick={showBackupCodeNumber} />
									 
										
									:
										<img className="img-fluid" src={require("../assets/images/signup/securityeyeopen.png").default}  onClick={showBackupCodeStar} />
										
									}

									<svg id="Group_26180" data-name="Group 26180" xmlns="http://www.w3.org/2000/svg" width="14.001" height="14" viewBox="0 0 14.001 14" onClick={ copyBackupCode }>
										<g id="Path_9276" data-name="Path 9276" transform="translate(-450.5 -124.5)" fill="none" stroke-miterlimit="10">
											<path d="M461.5,125.923V128.5h-5.72a1.189,1.189,0,0,0-1.28,1.28v5.72h-2.577a1.419,1.419,0,0,1-1.423-1.423v-8.154a1.419,1.419,0,0,1,1.423-1.423h8.154A1.419,1.419,0,0,1,461.5,125.923Z" stroke="none" />
											<path d="M 451.9228515625 125.5000152587891 C 451.6857299804688 125.5000152587891 451.5 125.6857452392578 451.5 125.9228668212891 L 451.5 134.0771636962891 C 451.5 134.3142700195312 451.6857299804688 134.5 451.9228515625 134.5 L 453.5 134.5 L 453.5 129.7800140380859 C 453.5 128.4375762939453 454.4375610351562 127.5 455.7799682617188 127.5 L 460.5 127.5 L 460.5 125.9228668212891 C 460.5 125.6857452392578 460.3142395019531 125.5000152587891 460.0771179199219 125.5000152587891 L 451.9228515625 125.5000152587891 M 451.9228515625 124.5000152587891 L 460.0771179199219 124.5000152587891 C 460.8656616210938 124.5000152587891 461.5 125.1343078613281 461.5 125.9228668212891 L 461.5 128.5 L 455.7799682617188 128.5 C 454.9913940429688 128.5 454.5 128.991455078125 454.5 129.7800140380859 L 454.5 135.5 L 451.9228515625 135.5 C 451.13427734375 135.5 450.5 134.8657531738281 450.5 134.0771636962891 L 450.5 125.9228668212891 C 450.5 125.1343078613281 451.13427734375 124.5000152587891 451.9228515625 124.5000152587891 Z" stroke="none" fill="#7782a1" />
										</g>
										<g id="Path_9277" data-name="Path 9277" transform="translate(-452.499 -126.5)" fill="none" stroke-miterlimit="10">
											<path d="M466.5,130.923v8.154a1.419,1.419,0,0,1-1.423,1.423h-8.154a1.419,1.419,0,0,1-1.423-1.423v-8.154a1.419,1.419,0,0,1,1.423-1.423h8.154A1.419,1.419,0,0,1,466.5,130.923Z" stroke="none" />
											<path d="M 456.9228210449219 130.5000152587891 C 456.6856994628906 130.5000152587891 456.4999694824219 130.6857452392578 456.4999694824219 130.9228515625 L 456.4999694824219 139.0771484375 C 456.4999694824219 139.3142700195312 456.6856994628906 139.5000152587891 456.9228210449219 139.5000152587891 L 465.0771179199219 139.5000152587891 C 465.3142395019531 139.5000152587891 465.4999694824219 139.3142700195312 465.4999694824219 139.0771484375 L 465.4999694824219 130.9228515625 C 465.4999694824219 130.6857452392578 465.3142395019531 130.5000152587891 465.0771179199219 130.5000152587891 L 456.9228210449219 130.5000152587891 M 456.9228210449219 129.5000152587891 L 465.0771179199219 129.5000152587891 C 465.8656921386719 129.5000152587891 466.4999694824219 130.1342926025391 466.4999694824219 130.9228515625 L 466.4999694824219 139.0771484375 C 466.4999694824219 139.86572265625 465.8656921386719 140.5000152587891 465.0771179199219 140.5000152587891 L 456.9228210449219 140.5000152587891 C 456.1342163085938 140.5000152587891 455.4999694824219 139.86572265625 455.4999694824219 139.0771484375 L 455.4999694824219 130.9228515625 C 455.4999694824219 130.1342926025391 456.1342163085938 129.5000152587891 456.9228210449219 129.5000152587891 Z" stroke="none" fill="#7782a1" />
										</g>
									</svg>
								</div>

							</div>
						</div>
						<div className="security-code-popup-footer">
							<button className="cancle-not-visible"></button>
							<button className="btndonecodepopup" onClick={handleBtnDone}>Done</button>
						</div>
					</div>
					}
				</ModalBody>
			</Modal>





			{/*<Modal isOpen={mdlverify} style={{ maxWidth: "600px", maxHeight: "400px" }} centered dataclass="countrycode" className="modal-recovery-key">
				<ModalHeader >
					<div className="security-crossmodal " onClick={handleCloseVerifyModel}>
						<img src={require("../assets/images/dashboard/cross.png").default} />
					</div>
				</ModalHeader>
				<ModalBody>
				<div>
					<div className="security-code-popup-body">

						<h4>Verify code</h4>
						<h6 className="security-code-input-head">
						Type in the digits generated by your authenticator app.
						</h6>

						<div className="security-inputbox-div" id="backupdivid">

							<h2>Enter code</h2>
							<div>
								<input className={qrcodeerror ? "security-enter-code varify-code-error" : "security-enter-code"} type="text" name="qrstep1" id="qrstep1" value={qrvalue1} onChange={ handleQRValue1 } maxLength="1" onKeyUp={(e) => handleRemoveCode(e, "", "qrstep1")} onPaste={ handlePasteCode }  autoComplete="off" tabIndex="1"/>

								<input className={qrcodeerror ? "security-enter-code varify-code-error" : "security-enter-code"} type="text" name="qrstep2" id="qrstep2" value={qrvalue2} onChange={ handleQRValue2 } maxLength="1" onKeyUp={(e) => handleRemoveCode(e, "qrstep1", "qrstep2")} onPaste={ handlePasteCode }  autoComplete="off" tabIndex="2"/>

								<input className={qrcodeerror ? "security-enter-code varify-code-error" : "security-enter-code"} type="text" name="qrstep3" id="qrstep3" value={qrvalue3} onChange={ handleQRValue3 } maxLength="1" onKeyUp={(e) => handleRemoveCode(e, "qrstep2", "qrstep3")} onPaste={ handlePasteCode }  autoComplete="off" tabIndex="3"/>

								<input className={qrcodeerror ? "security-enter-code varify-code-error" : "security-enter-code"} type="text" name="qrstep4" id="qrstep4" value={qrvalue4} onChange={ handleQRValue4 } maxLength="1" onKeyUp={(e) => handleRemoveCode(e, "qrstep3", "qrstep4")} onPaste={ handlePasteCode }  autoComplete="off" tabIndex="4"/>

								<input className={qrcodeerror ? "security-enter-code varify-code-error" : "security-enter-code"} type="text" name="qrstep5" id="qrstep5" value={qrvalue5} onChange={ handleQRValue5 } maxLength="1" onKeyUp={(e) => handleRemoveCode(e, "qrstep4", "qrstep5")} onPaste={ handlePasteCode } autoComplete="off" tabIndex="5"/>

								<input className={qrcodeerror ? "security-enter-code varify-code-error" : "security-enter-code"} type="text" name="qrstep6" id="qrstep6" value={qrvalue6} onChange={ handleQRValue6 } maxLength="1" onKeyUp={(e) => handleRemoveCode(e, "qrstep5", "qrstep6")} onPaste={ handlePasteCode } autoComplete="off" tabIndex="6"/>
							</div>
							
							{qrcodeerror &&
							<div className="d-flex security-code-red">
								<svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
									<path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"/>
									<path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"/>
									<path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"/>
								</svg>

								<p className="security-redinput">Incorrect or timed out code. Please try again.</p>
							</div>
							}

						</div>
					</div>
					<div className="security-code-popup-footer">
						<button onClick={handleBtnBackQrModel}>Back</button>
						<button onClick={handleBtnRecoveryCode} id="btnrecoveryid">
							<div className={isrecoveryloader}>
								
								<img src={require("../assets/images/signup/Loader_GIF.gif").default} alt="loader" />
							</div>
							{btnopenrecoverytxt}
						</button>
					</div>
					</div>
				</ModalBody>
			</Modal>


			 
			 <Modal isOpen={mdlcode} style={{ maxWidth: "600px", maxHeight: "400px" }} centered dataclass="countrycode" className="modal-recovery-key">
				<ModalHeader >
					<div className="security-crossmodal " onClick={handleCloseCodeModel}>
						<img src={require("../assets/images/dashboard/cross.png").default} />
					</div>
				</ModalHeader>
				<ModalBody>
					<div>
					<div className="security-code-popup-body">

						<h4>Recovery key</h4>
						<h6>
							In case you don't have access to your authenticator app, you can use this key instead. Each key can be used only once. Please make sure you copy and store it somewhere safe.
						</h6>

						<div className="security-code-list" id="backupdivid">

							<div>
								{backupcodestar === false ?
									<p className="security-code-xx">{ backupcode }</p>
								:									
									<p className="security-code-xx">XXXX XXXX XXXX XXXX</p>
								}

							</div>


							<div className="security-code-controls">

								{ backupcodestar ?
									
									<img className="img-fluid" src={require("../assets/images/signup/eyeopen.png").default} onClick={showBackupCodeNumber} />
									
									
								:
									<img className="img-fluid" src={require("../assets/images/signup/secure.png").default}  onClick={showBackupCodeStar} />
									
								}

								<svg id="Group_26180" data-name="Group 26180" xmlns="http://www.w3.org/2000/svg" width="14.001" height="14" viewBox="0 0 14.001 14" onClick={ copyBackupCode }>
									<g id="Path_9276" data-name="Path 9276" transform="translate(-450.5 -124.5)" fill="none" stroke-miterlimit="10">
										<path d="M461.5,125.923V128.5h-5.72a1.189,1.189,0,0,0-1.28,1.28v5.72h-2.577a1.419,1.419,0,0,1-1.423-1.423v-8.154a1.419,1.419,0,0,1,1.423-1.423h8.154A1.419,1.419,0,0,1,461.5,125.923Z" stroke="none" />
										<path d="M 451.9228515625 125.5000152587891 C 451.6857299804688 125.5000152587891 451.5 125.6857452392578 451.5 125.9228668212891 L 451.5 134.0771636962891 C 451.5 134.3142700195312 451.6857299804688 134.5 451.9228515625 134.5 L 453.5 134.5 L 453.5 129.7800140380859 C 453.5 128.4375762939453 454.4375610351562 127.5 455.7799682617188 127.5 L 460.5 127.5 L 460.5 125.9228668212891 C 460.5 125.6857452392578 460.3142395019531 125.5000152587891 460.0771179199219 125.5000152587891 L 451.9228515625 125.5000152587891 M 451.9228515625 124.5000152587891 L 460.0771179199219 124.5000152587891 C 460.8656616210938 124.5000152587891 461.5 125.1343078613281 461.5 125.9228668212891 L 461.5 128.5 L 455.7799682617188 128.5 C 454.9913940429688 128.5 454.5 128.991455078125 454.5 129.7800140380859 L 454.5 135.5 L 451.9228515625 135.5 C 451.13427734375 135.5 450.5 134.8657531738281 450.5 134.0771636962891 L 450.5 125.9228668212891 C 450.5 125.1343078613281 451.13427734375 124.5000152587891 451.9228515625 124.5000152587891 Z" stroke="none" fill="#7782a1" />
									</g>
									<g id="Path_9277" data-name="Path 9277" transform="translate(-452.499 -126.5)" fill="none" stroke-miterlimit="10">
										<path d="M466.5,130.923v8.154a1.419,1.419,0,0,1-1.423,1.423h-8.154a1.419,1.419,0,0,1-1.423-1.423v-8.154a1.419,1.419,0,0,1,1.423-1.423h8.154A1.419,1.419,0,0,1,466.5,130.923Z" stroke="none" />
										<path d="M 456.9228210449219 130.5000152587891 C 456.6856994628906 130.5000152587891 456.4999694824219 130.6857452392578 456.4999694824219 130.9228515625 L 456.4999694824219 139.0771484375 C 456.4999694824219 139.3142700195312 456.6856994628906 139.5000152587891 456.9228210449219 139.5000152587891 L 465.0771179199219 139.5000152587891 C 465.3142395019531 139.5000152587891 465.4999694824219 139.3142700195312 465.4999694824219 139.0771484375 L 465.4999694824219 130.9228515625 C 465.4999694824219 130.6857452392578 465.3142395019531 130.5000152587891 465.0771179199219 130.5000152587891 L 456.9228210449219 130.5000152587891 M 456.9228210449219 129.5000152587891 L 465.0771179199219 129.5000152587891 C 465.8656921386719 129.5000152587891 466.4999694824219 130.1342926025391 466.4999694824219 130.9228515625 L 466.4999694824219 139.0771484375 C 466.4999694824219 139.86572265625 465.8656921386719 140.5000152587891 465.0771179199219 140.5000152587891 L 456.9228210449219 140.5000152587891 C 456.1342163085938 140.5000152587891 455.4999694824219 139.86572265625 455.4999694824219 139.0771484375 L 455.4999694824219 130.9228515625 C 455.4999694824219 130.1342926025391 456.1342163085938 129.5000152587891 456.9228210449219 129.5000152587891 Z" stroke="none" fill="#7782a1" />
									</g>
								</svg>
							</div>

						</div>
					</div>
					<div className="security-code-popup-footer">
						<button onClick={handleCloseCodeModel}>Cancel</button>
						<button onClick={handleBtnDone}>Done</button>
					</div>
					</div>
				</ModalBody>
			</Modal>*/} 



			<Modal isOpen={mdlhide2f} style={{ maxWidth: "480px", maxHeight: "400px" }} toggle={codemodalapp} centered dataclass="countrycode" className="modal-recovery-key">
				<ModalHeader >
					<div className="security-crossmodal " onClick={handleCloseHide2FModel}>
						<img src={require("../assets/images/dashboard/cross.png").default} />
					</div>
				</ModalHeader>
				<ModalBody>
				<div>
					<div className=" security-disable2fa ">

						<h4>Disable two-factor authentication?</h4>
						<h6 className="">
						Disabling 2FA is not recommended and may weaken the security of your account.
						</h6>

						
					</div>
					<div className="disable2fa-button disable-buttons">
						<button onClick={handleCloseHide2FModel}>Cancel</button>
						<button onClick={handleDisable2FactorAuth} id="btndisable2fid">
							<div className={is2fdisableloader}>
								<div className="new-loader new-red-loader"></div>
							</div>
							{btn2fdisabletxt}
							
						</button>
					</div>

					</div>
				</ModalBody>
			</Modal>



			<Modal isOpen={mdlsignoutpopoup} style={{ maxWidth: "480px", maxHeight: "400px" }} toggle={codemodalapp} centered dataclass="countrycode" className="modal-recovery-key">
				<ModalHeader >
					<div className="security-crossmodal "  onClick={handleCloseSignoutPopup}>
						<img src={require("../assets/images/dashboard/cross.png").default} />
					</div>
				</ModalHeader>
				<ModalBody>
					<div>
					<div className=" security-disable2fa">

						<h4>Sign out of all devices?</h4>
						<h6 className="">
						This will log you out of all your devices
						</h6>

						
					</div>
					<div className="disable2fa-button">
						<button onClick={handleCloseSignoutPopup}>Cancel</button>
						<button onClick={handleSignoutAllDevices}>
							<div className={issignoutloader}>
								<div className="new-loader new-red-loader"></div>
							</div>
							{signouttxt}
							
						</button>
					</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
}

export default Security;