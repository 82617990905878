import moment from "moment";
import React from "react";

const CardFooter = ({
  addon,
  customerBillingType,
  addonIndx,
  handleAddonCancelationPopUp,
  handleReactivateAddonPopup,
  renderBuyButton,
  paddleAddonPriceList,
  paddleCurrency,
  finalPaddlePrice,
  isServiceAddonPurchased,
  addonsState,
}) => {
  return (
    <div
      className={`addons-price-div ${
        addon.addon_type !== 1 && addon.addon_identifier === "USER_MANAGEMENT"
          ? "team-addon-footer"
          : ""
      }`}
    >
      <div>
        <p className="addons-price">
          <>
            {customerBillingType === 3 ? (
              <>
                {paddleCurrency}
                {finalPaddlePrice.toLocaleString()}
              </>
            ) : (
              <>
                {addon.package_currency.currency_symbol}
                {addon.addon_type === 1
                  ? parseInt(addon.amount.toString().split(".")[0])
                  : parseInt(addon.amount.toString().split(".")[0]) / 12}
              </>
            )}
          </>

          <span className="addons-price-afterdot">
            .
            {customerBillingType === 3
              ? paddleAddonPriceList[addon.addon_code]?.toString().split(".")[1]
              : "00"}
            {addon.addon_identifier === "USER_MANAGEMENT" &&
            addon.addon_type !== 1 ? (
              <span className="addons-price-type"> per user</span>
            ) : null}
          </span>
        </p>
        <span className="addons-price-type">
          {" "}
          {addon.addon_type === 1 ? "one time" : "per month paid yearly"}
        </span>
      </div>

      {/* Status Start*/}
      {addon.is_deprecated ? (
        <></>
      ) : (
        <div className="">
          <div className={isServiceAddonPurchased ? "purchesed-addon" : ""}>
            {addon.addon_type === 1 ? (
              isServiceAddonPurchased ? (
                <p
                  id={`check-active-fn${addonIndx}`}
                  className={`active-addons purchesed-addon-para `}
                >
                  Purchased
                </p>
              ) : null
            ) : (
              <>
                {addon.is_active === 1 && (
                  <>
                    {addon?.downgrade_at_term_end === 1 &&
                    !addonsState.isLegacyLogic ? (
                      <>
                        <p className="cancellation-addons-date">
                          Cancellation on{" "}
                          {moment(addon.billing_date).format("D MMMM YYYY")}
                        </p>
                        <p
                          className="addon-reactivation"
                          onClick={(e) =>
                            handleReactivateAddonPopup(
                              addon.active_addon?.length > 0
                                ? addon.active_addon[0]
                                : addon.addon_code,
                              addon.amount,
                              addon.package_duration.name.toLowerCase(),
                              "cancel"
                            )
                          }
                        >
                          Reactivate
                        </p>
                      </>
                    ) : (
                      <>
                        <p
                          id={`check-active-fn${addonIndx}`}
                          className={`active-addons `}
                        >
                          Active
                        </p>
                        {addon.is_auto_addon !== 1 && (
                          <p
                            className="addon-cancel"
                            onClick={(e) =>
                              handleAddonCancelationPopUp(
                                addon.active_addon?.length > 0
                                  ? addon.active_addon[0]
                                  : addon.addon_code,
                                addon.amount,
                                addon.package_duration.name.toLowerCase(),
                                "cancel"
                              )
                            }
                          >
                            Cancel subscription
                          </p>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}

            {/* Buy Button  */}
            {renderBuyButton()}
          </div>
        </div>
      )}
      {/* Status End*/}
    </div>
  );
};

export default CardFooter;
