import React from 'react'; 
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';

 class Reactcolorpicker extends React.Component {
   constructor(props){
       super(props);
       this.handleOutsideClick = this.handleOutsideClick.bind(this);
       this.state = {
        displayColorPicker: false,
        color: props.colorname,
        presetColors: props.presetColors,
        classselection:props.classselection
       }
   }
   UNSAFE_componentWillReceiveProps({colorname,classselection}) {
    this.setState({
        color: colorname,
        classselection:classselection
    })
    
  }
  componentWillUnmount(){
    document.removeEventListener('click', this.handleOutsideClick, false);
  }
   handleClick = () => {
			
    if (!this.state.displayColorPicker) {
         if(window.innerWidth < 900){
            document.querySelector("body").style.position="fixed"
         }
        
    
        document.addEventListener('click', this.handleOutsideClick, false);
      } else {
        if(window.innerWidth < 900){
            document.querySelector("body").style.position="static"
         }
    
        document.removeEventListener('click', this.handleOutsideClick, false);
      }
  
      this.setState(prevState => ({
        displayColorPicker: !prevState.displayColorPicker,
      }));
    
  };
  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (this.node.contains(e.target)) {
      return;
    }
    
    this.handleClick();
  }
  handleChange = (color) => {
    this.props.onChange(color)
 
};

   render(){
    const styles = reactCSS({
        'default': {
          color: {
            width: "36px",
            height: "36px",
            border: "1px solid #C8CEDB",
            borderRadius: ".25rem",
            marginRight: "5px",
            marginLeft: "4px",
            backgroundColor: this.state.color,
          },
         
         
          swatch: {
            height: "100%",
            cursor: 'pointer',
            display:"flex",
            alignItems:"center"
          },
          popover: {
            position: 'absolute',
            zIndex: '2',
          },
          cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          },
        },
      });
       return (
           <>
                <div className="color_input" ref={node => { this.node = node; }}>
                    <div style={ styles.swatch } onClick={ this.handleClick } >
                        <div className='colorbox' style={ styles.color } />
                        <p className="m-0 p-0">{this.state.color}</p>
                        </div>
                    { this.state.displayColorPicker ? <div style={ styles.popover } className={this.state.classselection}> 
                    <div  />
                    <div>
                        <SketchPicker color={ this.state.color } onChange={ this.handleChange } presetColors={this.state.presetColors} width="250px" disableAlpha/>
                        <div className="mobileok d-md-none" onClick={ this.handleClick } ><p>OK</p></div>
                    </div>
                    </div> 
                    : null 
                    }			
                </div>
           </>
       )
   }
}

export default React.memo(Reactcolorpicker)