import React from "react";
import "../assets/css/Signup.css";
import axios from 'axios';
import {
  LoginApi,
  Googleloginin,
  Facebooklogins,
  Countrycode,
  VerifyUserApi,
  DeviceDetectData,
  UserSaveDevice,
} from "../Api/Authenticate/Api";

const publicIp = require("public-ip");


export default class SignUpRedirect extends React.PureComponent {
  	constructor(props) {
		super(props);
		this.helpToggle = this.helpToggle.bind(this);
		this.state = {
			helpDropdownOpen: false,
			user_country:"",
			login_ip:"",
			os_name: "",
			browser: "",
			concode: "",
			conname: "",
			regcode: "",
			regname: "",
			cityname: "",
			timezone: "",
      setline:false,
		};
  	}

  	helpToggle() 
	{
    	this.setState((prevState) => ({
      		helpDropdownOpen: !prevState.helpDropdownOpen,
		}));
  	}

  	componentDidMount() 
  	{
      if(localStorage.getItem("agency_id") && localStorage.getItem("agency_id") == 1)
      {
        document.title = "Sign up redirect | AppMySite";
      }
      else if(localStorage.getItem('agencyname') != undefined && localStorage.getItem('agencyname') != null && localStorage.getItem('agencyname') != 'undefined' && localStorage.getItem('agencyname') != 'null' && localStorage.getItem('agencyname') != '')
      {
        document.title = "Sign up redirect |"+ ' ' +localStorage.getItem('agencyname');        
      }
      else if(this.state.agency_name != undefined && this.state.agency_name != null && this.state.agency_name != 'undefined' && this.state.agency_name != 'null' && this.state.agency_name != '')
      {
        document.title = "Sign up redirect |"+' '+ this.state.agency_name;
      }
      else
      {
        document.title = "Sign up redirect";
      }

      setTimeout(() => {
        this.setState({setline:true});
      }, 5000);

    	var url = new URL(window.location.href);
    	var c = url.searchParams.get("data");
   
    	const publicIp = require("public-ip");
    	
      
  
    	if(c != '' && c != undefined && atob(c) != '' && atob != undefined)
  		{
    		var data = JSON.parse((atob(c)));
  		}

		      
  
        if(data.login_type == 0)
        {
          var params = 
          {
                email: data.email,
                login_type: data.login_type,
                password: data.password,
        		    agency_id:localStorage.getItem('agency_id') ? parseInt(localStorage.getItem('agency_id')) : 1,
				        deviceuuid: ((data.deviceid !== '' && data.deviceid !== null && data.deviceid !== undefined) ? data.deviceid : null),
      		};
        }
        else if(data.login_type == 1)
        {
          var params = 
              {
                email: data.email,
                login_type: data.login_type,
                access_token: data.token,
                agency_id: localStorage.getItem('agency_id') ? parseInt(localStorage.getItem('agency_id')) : 1,
                deviceuuid: ((data.deviceid !== '' && data.deviceid !== null && data.deviceid !== undefined) ? data.deviceid : null),
              };
        }
        else if(data.login_type == 2)
        {
          var params = 
              {
                    email: data.email,
                    login_type: data.login_type,
                    access_token: data.token,
                    id: data.id,
                    agency_id: localStorage.getItem('agency_id') ? parseInt(localStorage.getItem('agency_id')) : 1,
                    deviceuuid: ((data.deviceid !== '' && data.deviceid !== null && data.deviceid !== undefined) ? data.deviceid : null),
              };
        }

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
          'event': 'user_sign_up',
          'user_email': data.email,
          'user_name' : data.name,
          'user_id': data.id,
          'user_login_count': '1',
          'agency_id':localStorage.getItem('agency_id') ? parseInt(localStorage.getItem('agency_id')) : 1,
          });

      		UserSaveDevice(params, this); 
    }



  render() 
  {
    
    return (
      <>
       <section className="redirect-page d-flex align-items-center justify-content-center redirect-page-height">
        <div className="d-block text-center">
        <svg xmlns="http://www.w3.org/2000/svg" 
          xlink="http://www.w3.org/1999/xlink" 
          style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
          width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
            style={{animationplaystate: "running", animationdelay: "0s"}}>
            </animateTransform>
          </circle>
          </g>
          <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
          style={{animationplaystate: "running", animationdelay: "0s"}}>
          </animateTransform>
          </circle>
          </g>
          <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
          style={{animationplaystate: "running", animationdelay: "0s"}}>

          </animateTransform>
          </circle>
          </g>
          <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
          style={{animationplaystate: "running", animationdelay: "0s"}}>

          </animateTransform>
          </circle>
          </g>
          </svg>
          {this.state.setline ?
          <p>If you’re not redirected automatically,<a href="/apps">click here.</a></p>
          : 
          <></>
          }
          </div>
        </section>
      </>
    );
  }
}
