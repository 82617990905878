import React from "react";
import noCustomerImage from "../../assets/images/dashboard/no-customer-found.webp";
import "../../Layouts/Pages/Customers/customers.css";

const NoCustomerFound = ({showfilterData,showsearchInput}) => {
  const additionalClass = showfilterData || showsearchInput ? "no-Search-found" : "";
  return (
    <div className={`no-customer-found ${additionalClass}`}>
      <div className="no-customer-found-child">
        <img
          className="customer-page-search-icon"
          src={noCustomerImage}
          alt="No customer found"
        />
        <h4 className="no-customer-found-content">
          Nothing here at the moment. Come back later?
        </h4>
      </div>
    </div>
  );
};

export default NoCustomerFound;
