import React from "react";
import { Doughnut } from "react-chartjs-2";
import "./Appcount.css";
class Donut extends React.Component {
  state = {
    data: [],
  };


  componentDidMount() {
    // this.handleDonutData();
  }

  render() {
    const handleDonutData = () => {
      let updatedData=[];
      let app_count = this.props.value.state.reportdata;
      let count = 0;
      for (count; count < app_count.length; count++) {
        if (app_count[count].item_type === "android_vs_ios_users_count") {
          if (app_count[count]?.value?.length) {
            
            let newobj = app_count[count].value
              .filter((res) =>
                res.key.toLowerCase() === "android" || res.key.toLowerCase() === "ios"
              )
              .map((res) => {
                if (res.key.toLowerCase() === "android") {
                  res.key = "Android";
                } else if (res.key.toLowerCase() === "ios") {
                  res.key = "iOS";
                }
                return res;
              });
            updatedData={
              labels: newobj.map((res) => res.key),
              datasets: [
                {
                  data: newobj.map((res) => res.doc_count),
                  backgroundColor: ["#6E94FF", "#3064F9"],
                  fill: true,
                },
              ],
            }
          }
        }
      }
      return updatedData;
    }
    return (
      <>
        <div className="device_chart donut_box device_chart_redesign w-100 canvas_donut">
          {handleDonutData().length === 0 ? (
            <div className="w-100 text-center">
              <img
                src={
                  require("../../assets/images/dashboard/no-donut.png").default
                }
                alt="donut"
                className="img-fluid m-auto"
              />
            </div>
          ) : (
            <>
            <Doughnut
              // data={this.state.data.toLocaleString("en-US")}
              data={handleDonutData()}
              height={320 + "px"}
              options={{
                maintainAspectRatio: false,
                cutoutPercentage: 60,
                responsive: true,
              }}
            />
         
            </>
          )}
        </div>
      </>
    );
  }
}

export { Donut as default };
