import React from "react";
import { Modal, ModalBody } from "reactstrap";

const BuildPopup = ({ rebuildpop, btnRebuildCancel, submitform }) => {
  return (
    <Modal
      isOpen={rebuildpop}
      toggle={btnRebuildCancel}
      style={{ maxWidth: "480px", maxHeight: "270px" }}
      className="activate-customer-popup rebuild-required-popup"
      centered
    >
      <ModalBody>
        <div className="crossmodal mt-1" onClick={btnRebuildCancel}>
          <img
            src={require("./../../assets/images/dashboard/cross.png").default}
          />
        </div>

        <div>
          <div className="confirm-org-body">
            <h4>
              <svg
                style={{ marginTop: "-4px" }}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g
                  id="Group_37879"
                  data-name="Group 37879"
                  transform="translate(-1700 -18)"
                >
                  <rect
                    id="Rectangle_25579"
                    data-name="Rectangle 25579"
                    width="24"
                    height="24"
                    transform="translate(1700 18)"
                    fill="none"
                  />
                  <g
                    id="_2682803_attention_erro_exclamation_mark_warn_icon"
                    data-name="2682803_attention_erro_exclamation_mark_warn_icon"
                    transform="translate(1695.441 10.545)"
                  >
                    <path
                      id="Exclusion_46"
                      data-name="Exclusion 46"
                      d="M19.165,18H.833a.842.842,0,0,1-.723-.429.89.89,0,0,1,0-.858L9.274.424a.831.831,0,0,1,1.45,0l9.164,16.288a.878.878,0,0,1,0,.858A.842.842,0,0,1,19.165,18ZM10,13.127A1.2,1.2,0,0,0,8.82,14.334a1.169,1.169,0,0,0,.333.87,1.116,1.116,0,0,0,.794.339H10a1.209,1.209,0,0,0,0-2.417ZM8.95,6.406h0l.3,6.049h1.482l.284-6.049Z"
                      transform="translate(6.559 10.455)"
                      fill="#ffb300"
                    />
                  </g>
                </g>
              </svg>{" "}
              Social login configuration required
            </h4>
            <h5>
            This feature requires configuring social logins in your Login Build settings. Enable and configure social logins in your Login Build settings.
            </h5>

          </div>
          <div className="deactivate-customer-footer">
            {/* <div
              className="team-limit-btn-cancel activate-customer-cancel-btn"
              onClick={btnRebuildCancel}
            >
              Cancel
            </div> */}

            <div
              className="team-limit-btn"
              id="btncustomrtactiveid"
              // onClick={submitform}
              onClick={btnRebuildCancel}
            >
              Okay
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default BuildPopup;
