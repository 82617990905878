export const myAppsEvent = (data) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer?.push({
    event: "my_apps_visited",
    user_email: data.user_email,
    user_name: data.user_name,
    user_id: data.user_id,
  });
};
export const dashboardEvent = (data) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer?.push({
    event: "dashboard_visited",
    user_email: data.user_email,
    user_name: data.user_name,
    user_id: data.user_id,
  });
};
export const homePageEvent = (data) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer?.push({
    event: "homepage_visited",
    user_email: data.user_email,
    user_name: data.user_name,
    user_id: data.user_id,
  });
};
