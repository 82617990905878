import React, { useEffect, useReducer, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";
import addDays from "date-fns/addDays";
import DatePicker from "react-datepicker";
import Header from "../../../Dashboard/Header";
import Leftpanel from "../../../Dashboard/Leftpanel";
import CrossIc from "../../../assets/images/dashboard/cross.png";
import Spinner from "../../../Components/commonComponents/Spinner";
import Tooltip from "../../../Components/commonIcons/Tooltip";
import AccordianOpen from "../../../assets/images/dashboard/cms/accordian-open.png";
import AccordianClose from "../../../assets/images/dashboard/cms/accordian-icon.png";
import UploadIc from "../../../assets/images/dashboard/cms/uploadbackground.png";
import NotificationPreview from "../../../Components/NotificationComp/NotificationPreview";
import LinkSection from "../../../Dashboard/Pushnotification/LinkSection";
import NewImageGalleryPopup from "../../../Dashboard/NewImageGalleryPopup";
import NotificationCreateHeader from "../../../Components/NotificationComp/CreateNotification/NotificationCreateHeader";
import NotificationCreateFooter from "../../../Components/NotificationComp/CreateNotification/NotificationCreateFooter";
import ConfirmationModal from "../../../Components/NotificationComp/CreateNotification/ConfirmationModal";
import BuildSettingErrorModal from "../../../Components/NotificationComp/CreateNotification/BuildSettingErrorModal";
import { errortoast } from "../../../Dashboard/Toaster";
import { appDashboardApiSlice } from "../../../services/appDashboardApiSlice";
import {
  useLazyGetBottomMenuTaxonomyQuery,
  useLazyGetBottomMenuTypesQuery,
} from "../../../Redux/slices/Notification/wordpressNotificationSlice";
import {
  useLazyGetAllPlainNotificationsQuery,
  useGetPlainNotificationDetailsQuery,
  useLazyGetNotificationDetailsQuery,
  useSaveDraftMutation,
  useSendNotificationMutation,
} from "../../../Redux/slices/Notification/basicNotificationSlice";
import {
  APP_PLANS,
  DEFAULT_LINKS_ARRAY,
  DEVICE_TYPE,
} from "../../../Constants/notificationConsts";

const CreateNotification = (props) => {
  const { refetchItems } = props;
  const { nfID } = useParams(); // Access the route parameter
  let history = useHistory();
  const appId = localStorage.getItem("Appid");
  const isSidebarCollapsed = JSON.parse(
    localStorage.getItem("appSideBarCollapse")
  );

  const [rightpanelexpand, setRightPanelExpand] = useState(true);
  const [notificationData, setNotificationData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      appData: {},
      accordianAt: null,
      isSettingsOpen: false,
      isLoading: false,
      isIos: true,
      appPlan: "",
      title: "",
      message: "",
      image: "",
      link: "",
      deliverySchedule: true,
      showCalender: true,
      date: new Date(),
      draftSpinner: false,
      sendSpinner: false,
      is_ios_build_toggle_enable: 0,
      is_android_build_toggle_enable: 0,
      deviceType: "android",
      isConfirmModalOpen: false,
      iosOpenPopUp: false,
      androidOpenPopUp: false,
      itemName: "",
      itemType: "",
      slug: "",
      postType: "",
      restBase: "",
      invidualSelectedItemId: "",
      typeArray: "",
      mergeArray: "",
      webViewUrl: "",
      storeUrl: localStorage.getItem("store_url"),

      isGalleryPopup: false,
      notificationImage: "",
      settings: {
        sendToIos: true,
        sendToAndroid: true,
        sendImmediately: false,
      },
    }
  );

  const { data: plainNotifData, isFetching: plainNotifFetching } =
    useGetPlainNotificationDetailsQuery();

  const [getNotifDetails, { data: notifDetailsData }] =
    useLazyGetNotificationDetailsQuery();

  const [getAllPlainNotifications] = useLazyGetAllPlainNotificationsQuery();
  const [saveDraft] = useSaveDraftMutation();
  const [sendNotification] = useSendNotificationMutation();
  const [getTypesData] = useLazyGetBottomMenuTypesQuery();
  const [getTaxonomyData] = useLazyGetBottomMenuTaxonomyQuery();

  const uploadedImage = notificationData.notificationImage
    ? process.env.REACT_APP_Image_Path +
      localStorage.getItem("user_id") +
      "/" +
      localStorage.getItem("Appid") +
      process.env.REACT_APP_Plain_Notification_Media +
      notificationData.notificationImage
    : UploadIc;

  const handleRightPanelExpand = (value) => setRightPanelExpand(value);

  const handleAccordianChange = (value) => {
    if (value === notificationData.accordianAt)
      setNotificationData({
        accordianAt: null,
      });
    else
      setNotificationData({
        accordianAt: value,
      });
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setNotificationData({ [name]: value.replace('"', "") });
  };

  const handleIosToggle = (e) => {
    if (!e.target.checked) {
      const currObj = { ...notificationData.settings };
      currObj.sendToIos = false;
      currObj.sendToAndroid = true;
      setNotificationData({
        settings: currObj,
      });
    } else {
      const currObj = { ...notificationData.settings };
      currObj.sendToIos = true;
      setNotificationData({
        settings: currObj,
      });
    }
  };

  const handleAndroidToggle = (e) => {
    if (!e.target.checked) {
      const currObj = { ...notificationData.settings };
      currObj.sendToIos =
        notificationData.appPlan === APP_PLANS.starter ? false : true;
      currObj.sendToAndroid = false;
      setNotificationData({
        settings: currObj,
      });
    } else {
      const currObj = { ...notificationData.settings };
      currObj.sendToAndroid = true;
      setNotificationData({
        settings: currObj,
      });
    }
  };

  const handleSendImmediately = (e) => {
    setNotificationData({
      deliverySchedule: e.target.checked,
    });
    if (e.target.checked)
      setNotificationData({
        showCalender: true,
      });
    else
      setNotificationData({
        date: null,
        showCalender: false,
      });
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  const isValidNotificationId = (id) =>
    id && id !== "undefined" && id !== "null" && id !== "create" && id !== "";

  const isValidNotificationExist = (id) =>
    id && id !== "undefined" && id !== "null";

  const handleSaveDraft = () => {
    setNotificationData({ draftSpinner: true });

    let devicetype = "";

    if (
      notificationData.settings.sendToIos &&
      notificationData.settings.sendToAndroid
    ) {
      devicetype = DEVICE_TYPE.both;
    } else if (notificationData.settings.sendToIos) {
      devicetype = DEVICE_TYPE.ios;
    } else {
      devicetype = DEVICE_TYPE.android;
    }

    if (!notificationData.deliverySchedule && !notificationData.date) {
      errortoast("Proper Date is required");
      setNotificationData({
        draftSpinner: false,
      });
      return;
    }

    const body = {
      app_id: parseInt(localStorage.getItem("Appid")),
      title: notificationData.title,
      notify_id: nfID ? parseInt(atob(nfID)) : "",
      description: notificationData.message,
      delivery_type: !notificationData.deliverySchedule ? 2 : 1,
      delivery_date: !notificationData.deliverySchedule
        ? moment(notificationData.date).utc().format("Y-MM-DD HH:mm:ss")
        : "",
      status: 0,
      device_type: devicetype,
      custom_data: {
        item_type: notificationData.itemType,
        item_id: notificationData.invidualSelectedItemId
          ? notificationData.invidualSelectedItemId.toString()
          : "",
        item_title: notificationData.itemName,
        web_view_url:
          notificationData.itemType === "web_view"
            ? notificationData.webViewUrl
            : "",
        slug: notificationData.slug,
        rest_base: notificationData.restBase,
        post_type: notificationData.postType,
      },
      image: notificationData.notificationImage,
    };

    saveDraft(body)
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          fetchListAllNotification();
        } else if (response.code === 400) {
          response.data.delivery_date !== undefined &&
            errortoast(response.data.delivery_date[0]);
        } else {
          errortoast(response.status);
        }
      })
      .catch(() => {
        errortoast("Oops! Something went wrong.");
      });
  };

  const fetchListAllNotification = () => {
    getAllPlainNotifications({
      appId: btoa(appId),
      page: 1,
      limit: 20,
      finalFilterVal: "",
    })
      .unwrap()
      .then((res) => {
        setNotificationData({
          spinner: false,
          multisiteData: res?.multisite,
          isEnableScreen: res?.is_enable_screen,
          androidNotifyId: res?.android_notification_project_id,
          iosNotifyId: res?.ios_notification_project_id,
          hitNextPagination:
            res.data?.length > 0 && res.data?.length === notificationData.limit
              ? true
              : false,
          bottomSpinner: false,
          displayTable: res.data || [],
        });
      })
      .catch(() => {})
      .finally(() => {
        setNotificationData({
          spinner: false,
          draftSpinner: false,
          sendSpinner: false,
        });
        history.push("/app/basic-notifications");
      });
  };

  const handleSubmitNotification = () => {
    if (
      notificationData.title?.trim().length > 0 &&
      notificationData.message?.trim().length > 0
    ) {
      const params = {
        app_id: parseInt(localStorage.getItem("Appid")),
        title: notificationData.title,
        notify_id: isValidNotificationExist(nfID) ? parseInt(atob(nfID)) : "",
        description: notificationData.message,
        delivery_type: !notificationData.deliverySchedule ? 2 : 1,
        delivery_date: !notificationData.deliverySchedule
          ? moment(notificationData.date).utc().format("Y-MM-DD HH:mm:ss")
          : "",

        status: 1,
        device_type:
          notificationData.settings.sendToIos &&
          notificationData.settings.sendToAndroid
            ? "both"
            : notificationData.settings.sendToIos
            ? "ios"
            : "android",
        custom_data: {
          item_type: notificationData.itemType,
          item_id: notificationData.invidualSelectedItemId
            ? notificationData.invidualSelectedItemId.toString()
            : "",
          item_title: notificationData.itemName,
          web_view_url:
            notificationData.itemType === "web_view"
              ? notificationData.webViewUrl
              : "",
          slug: notificationData.slug,
          rest_base: notificationData.restBase,
          post_type: notificationData.postType,
        },
        image: notificationData.notificationImage,
      };

      if (
        notificationData.deliverySchedule === false &&
        !notificationData.date
      ) {
        errortoast("Proper Date is required");
        setNotificationData({
          draftSpinner: false,
        });
        return;
      }

      if (
        notificationData.is_android_build_toggle_enable === 0 &&
        notificationData.settings.sendToAndroid
      ) {
        setNotificationData({ androidOpenPopUp: true });
        return;
      } else if (
        notificationData.is_ios_build_toggle_enable === 0 &&
        notificationData.settings.sendToIos
      ) {
        setNotificationData({ iosOpenPopUp: true });
        return;
      }

      setNotificationData({
        sendSpinner: true,
      });

      sendNotification(params)
        .unwrap()
        .then((response) => {
          if (response.code === 200) {
            setNotificationData({
              isConfirmModalOpen: false,
            });
            fetchListAllNotification();
          } else if (response.code === 400) {
            response.data.delivery_date !== undefined &&
              errortoast(response.data.delivery_date[0]);
          } else if (response.code === 500) {
            errortoast(response.status);
            setNotificationData({
              isConfirmModalOpen: false,
            });
          } else {
            errortoast(response.status);
          }
        })
        .catch(() => {
          errortoast("Oops! Something went wrong.");
        })
        .finally(() => {
          setNotificationData({
            sendSpinner: true,
          });
        });
      refetchItems();
    }
  };

  const taxonomyDataset = (newTaxonomyData, newTypesData1) => {
    let mergeArray1 = [];
    let res = [];
    let taxnomyArray = [];
    let typeArray1 = [];

    if (parseInt(localStorage.getItem("website_technology")) === 3) {
      setNotificationData({
        mergeArray: DEFAULT_LINKS_ARRAY,
      });
    } else {
      mergeArray1 = [];
      if (typeof newTaxonomyData === "object" && newTaxonomyData !== null) {
        taxnomyArray = Object.keys(newTaxonomyData).map(
          (key) => newTaxonomyData[key]
        );
      }
      if (typeof newTypesData1 === "object" && newTypesData1 !== null) {
        typeArray1 = Object.keys(newTypesData1).map(
          (key) => newTypesData1[key]
        );
      }

      for (let i = 0; i < typeArray1.length; i++) {
        res = [];
        res.name = typeArray1[i].name;
        res.slug = typeArray1[i].slug;
        res.rest_base = typeArray1[i].rest_base;
        res.type = "post_type";
        res.post_type = typeArray1[i].rest_base;
        res.invidual_selected_item_id = "";
        res.temp_invidual_selected_item_id = "";
        mergeArray1.push(res);

        res = [];
        res.name = typeArray1[i].name + " search";
        res.slug = typeArray1[i].slug;
        res.rest_base = typeArray1[i].rest_base;
        res.type = "search";
        res.post_type = typeArray1[i].rest_base;
        res.invidual_selected_item_id = "";
        res.temp_invidual_selected_item_id = "";
        mergeArray1.push(res);

        for (let j = 0; j < typeArray1[i].taxonomies.length; j++) {
          if (taxnomyArray.length !== 0) {
            const index = taxnomyArray
              .map((object) => object.slug)
              .indexOf(typeArray1[i].taxonomies[j]);
            res = [];
            res.name =
              taxnomyArray[index].name +
              (typeArray1[i].taxonomies.length > 1
                ? " (" + typeArray1[i].name + ")"
                : "");
            res.slug = taxnomyArray[index].slug;
            res.rest_base = taxnomyArray[index].rest_base;
            res.type = "taxonomy";
            res.post_type = typeArray1[i].rest_base;
            res.invidual_selected_item_id = "";
            res.temp_invidual_selected_item_id = "";
            mergeArray1.push(res);
          }
        }
      }

      mergeArray1 = mergeArray1.filter(
        (row) =>
          !row.name.toLowerCase().includes("search") &&
          !row.name.toLowerCase().includes("navigation") &&
          !row.name.toLowerCase().includes("pattern") &&
          !row.name.toLowerCase().includes("template") &&
          !row.name.toLowerCase().includes("font")
      );

      mergeArray1 = mergeArray1.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
      setNotificationData({
        mergeArray: mergeArray1,
      });
      setNotificationData({
        typeArray: typeArray1,
      });
    }
  };

  const settingWpData = async () => {
    // wp types and taxonomies API call
    if (parseInt(localStorage.getItem("website_technology")) === 3) {
      setNotificationData({
        mergeArray: DEFAULT_LINKS_ARRAY,
      });
    } else if (
      parseInt(localStorage.getItem("website_technology")) === 4 ||
      parseInt(localStorage.getItem("website_technology")) === 5
    ) {
      try {
        const typesRes = await getTypesData(notificationData.storeUrl).unwrap();
        const taxonomyRes = await getTaxonomyData(
          notificationData.storeUrl
        ).unwrap();

        taxonomyDataset(taxonomyRes, typesRes);
      } catch (e) {}
    }
  };

  const toggleConfirmationModal = () => {
    if (
      notificationData.title?.trim()?.length &&
      notificationData.message?.trim()?.length
    )
      setNotificationData({
        isConfirmModalOpen: !notificationData.isConfirmModalOpen,
      });
    else errortoast("Notification Title and Message are required.");
  };

  useEffect(() => {
    // Update the document title & set taxonomies data
    const agencyId = localStorage.getItem("agencyid");
    const agencyName = localStorage.getItem("agencyname");

    const isAgencyIdOne = agencyId && +agencyId === 1;
    const titlePrefix = isValidNotificationId(nfID)
      ? "Edit notification"
      : "Create notification";
    const titleSuffix = isAgencyIdOne ? "AppMySite" : agencyName;

    document.title = `${titlePrefix} | ${titleSuffix}`;

    settingWpData();
  }, [nfID]);

  useEffect(() => {
    if (plainNotifData && plainNotifData.code === 200) {
      const currObj = { ...notificationData.settings };
      currObj.sendToIos =
        plainNotifData.plandata.plan === APP_PLANS.starter ? false : true;
      setNotificationData({
        appPlan: plainNotifData.plandata.plan,
        settings: currObj,
        is_ios_build_toggle_enable:
          plainNotifData?.iosdata?.is_notification_enabled || 0,
        is_android_build_toggle_enable:
          plainNotifData?.androiddata?.is_notification_enabled || 0,
      });
    }
    if (isValidNotificationExist(nfID)) {
      const params = {};
      params.id = nfID;

      if (plainNotifFetching)
        setNotificationData({
          isLoading: true,
        });
      else
        setNotificationData({
          isLoading: false,
        });

      // Create notificcation fetch data
      if (!notifDetailsData) {
        setNotificationData({
          isLoading: true,
        });
        getNotifDetails(nfID)
          .unwrap()
          .then((res) => {
            if (res.code === 200) {
              const currObj = { ...notificationData.settings };
              currObj.sendToIos =
                res.plan === APP_PLANS.starter
                  ? false
                  : res.data.device_type === DEVICE_TYPE.ios ||
                    res.data.device_type === "both"
                  ? true
                  : false;

              currObj.sendToAndroid =
                res.data.device_type === DEVICE_TYPE.android ||
                res.data.device_type === "both"
                  ? true
                  : false;

              setNotificationData({
                title: res.data.title,
                message: res.data.description,
                deviceType: res.data.device_type,
                deliverySchedule: res.data.delivery_type === 2 ? false : true,
                showCalender: res.data.delivery_type === 2 ? false : true,
                settings: currObj,
                notificationImage: res.data.image,
              });

              let filterdata = res.data.filterdata
                ? JSON.parse(res.data.filterdata)
                : null;

              if (filterdata) {
                setNotificationData({
                  invidualSelectedItemId: filterdata.item_id,
                  itemType: filterdata.item_type,
                  slug: filterdata.slug,
                  restBase: filterdata.rest_base,
                  postType: filterdata.post_type,
                  itemName: filterdata.item_title,
                  webViewUrl: filterdata.web_view_url,
                });
              }

              if (!res.data.delivery_date) {
                setNotificationData({
                  date: new Date(),
                });
              } else {
                const dateObj = new Date(res.data.delivery_date).getTime();

                setNotificationData({
                  date: new Date(dateObj),
                });
              }
            }
          })
          .catch(() => {})
          .finally(() => {
            setNotificationData({
              isLoading: false,
            });
          });
      }
    }
  }, [plainNotifData, plainNotifFetching, notifDetailsData]);

  return (
    <>
      <div className="d-md-block">
        <Header customclass={"header-responsive-new"} />
      </div>

      <section className="dasboard_page newmobile-display">
        <div
          className={`right-panel-gap${
            isSidebarCollapsed ? " right-panel-gap-expand" : ""
          }`}
        >
          <NotificationCreateHeader
            notificationData={notificationData}
            setNotificationData={setNotificationData}
            handleSaveDraft={handleSaveDraft}
            toggleConfirmationModal={toggleConfirmationModal}
          />
          <div className="pushnotification-right_panel">
            <>
              {!notificationData.isLoading ? (
                <>
                  <div className="right_general mob-notification-create-back">
                    <div className="createpushnotification">
                      <div className="createpushnotificationleft">
                        {!notificationData.isSettingsOpen ? (
                          <div className="">
                            <div className=" d-flex align-items-center justify-content-between flex-wrap mob-notification-create-back">
                              <div className="pushnotification-title">
                                <div
                                  className="d-flex align-items-center justify-content-between pushnotification-titlefirstdiv"
                                  onClick={() => handleAccordianChange(0)}
                                >
                                  <p className="pushnotification-title-text">
                                    Title
                                  </p>

                                  <img
                                    src={
                                      notificationData.accordianAt === 0
                                        ? AccordianOpen
                                        : AccordianClose
                                    }
                                    alt="accordian ic"
                                  />
                                </div>
                                {notificationData.accordianAt === 0 ? (
                                  <div className="planenotification-input-div">
                                    <input
                                      name="title"
                                      value={notificationData.title || ""}
                                      type="text"
                                      placeholder="Enter a title here"
                                      className=" planenotification-input-box"
                                      maxLength="65"
                                      required
                                      onChange={onChangeHandler}
                                    />
                                    <span className="textnumber-count">
                                      {65 - notificationData.title.length}{" "}
                                      characters
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                              <div className="pushnotification-title ">
                                <div
                                  className="d-flex align-items-center justify-content-between pushnotification-titlefirstdiv"
                                  onClick={(e) => handleAccordianChange(1)}
                                >
                                  <p className="pushnotification-title-text">
                                    Message
                                  </p>
                                  <img
                                    src={
                                      notificationData.accordianAt === 1
                                        ? AccordianOpen
                                        : AccordianClose
                                    }
                                    alt="accordian ic"
                                  />
                                </div>
                                {notificationData.accordianAt === 1 ? (
                                  <div className=" planenotification-input-div">
                                    <textarea
                                      name="message"
                                      value={notificationData.message || ""}
                                      placeholder="Compose your message here "
                                      className=" planenotification-textarea-box"
                                      rows="5"
                                      maxLength="178"
                                      onChange={onChangeHandler}
                                    ></textarea>
                                    <span className="textnumber-count">
                                      {178 - notificationData.message.length}{" "}
                                      characters
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                              <div className=" pushnotification-title">
                                <div
                                  className="d-flex align-items-center justify-content-between pushnotification-titlefirstdiv"
                                  onClick={() => handleAccordianChange(2)}
                                >
                                  <p className="pushnotification-title-text">
                                    Image
                                  </p>

                                  <img
                                    src={
                                      notificationData.accordianAt === 2
                                        ? AccordianOpen
                                        : AccordianClose
                                    }
                                    alt="accordian ic"
                                  />
                                </div>
                                {notificationData.accordianAt === 2 ? (
                                  <div className="planenotification-input-div">
                                    <div className="popupimage-uploader">
                                      <div
                                        className="d-flex align-items-center"
                                        onClick={() =>
                                          setNotificationData({
                                            isGalleryPopup:
                                              !notificationData.isGalleryPopup,
                                          })
                                        }
                                      >
                                        <div className="uploadpixabayimage">
                                          <img
                                            className="notification-image-uploader"
                                            src={uploadedImage}
                                            alt="uploaded ic"
                                          />
                                        </div>

                                        <p
                                          className="createpushnotificationright-text"
                                          style={{
                                            color:
                                              notificationData.notificationImage
                                                ? ""
                                                : "#bcc1ce",
                                          }}
                                        >
                                          {notificationData.notificationImage ||
                                            "Upload"}
                                        </p>
                                      </div>

                                      {notificationData.notificationImage ? (
                                        <img
                                          src={CrossIc}
                                          alt="close"
                                          onClick={() =>
                                            setNotificationData({
                                              notificationImage: "",
                                            })
                                          }
                                        />
                                      ) : null}
                                    </div>
                                  </div>
                                ) : null}
                              </div>

                              <div className=" pushnotification-title">
                                <div
                                  className="d-flex align-items-center justify-content-between pushnotification-titlefirstdiv"
                                  onClick={() => handleAccordianChange(3)}
                                >
                                  <p className="pushnotification-title-text">
                                    Link
                                  </p>

                                  <img
                                    src={
                                      notificationData.accordianAt === 3
                                        ? AccordianOpen
                                        : AccordianClose
                                    }
                                    alt="accordian ic"
                                  />
                                </div>
                                {notificationData.accordianAt === 3 ? (
                                  <div className="link-form-group">
                                    <LinkSection
                                      savingLinkPopUpData={(
                                        invidualSelectedItemId,
                                        itemType,
                                        slug,
                                        restBase,
                                        postType,
                                        itemName
                                      ) => {
                                        setNotificationData({
                                          invidualSelectedItemId:
                                            invidualSelectedItemId,
                                          itemType: itemType,
                                          slug: slug,
                                          restBase: restBase,
                                          postType: postType,
                                          itemName: itemName,
                                        });
                                      }}
                                      handleWebViewUrl={(e) => {
                                        setNotificationData({
                                          webViewUrl: e.target.value.replace(
                                            "https://",
                                            ""
                                          ),
                                        });
                                      }}
                                      parentDataObj={{
                                        itemName: notificationData.itemName,
                                        itemType: notificationData.itemType,
                                        slug: notificationData.slug,
                                        postType: notificationData.postType,
                                        restBase: notificationData.restBase,
                                        invidualSelectedItemId:
                                          notificationData.invidualSelectedItemId,
                                        typeArray: notificationData.typeArray,
                                        mergeArray: notificationData.mergeArray,
                                        webViewUrl: notificationData.webViewUrl,
                                      }}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="setting-notificationpreview">
                            <div className="notificationsetting-div">
                              <h1 className="notificatiosetting-head">
                                Settings
                              </h1>
                              <p className="notificationsetting-para">
                                Manage your notification preferences.
                              </p>
                            </div>
                            <div>
                              <ul className="notificationsetting-ul">
                                <li className="notificationsetting-li">
                                  <h2 className="notification-setting-toggle-div">
                                    Send to iOS devices
                                    <Tooltip
                                      message={
                                        "Enable to send notifications to iOS device users."
                                      }
                                    />
                                  </h2>
                                  {notificationData.appPlan ===
                                  APP_PLANS.starter ? (
                                    <label className=" notification-lock-switch notification-monitor-slider-padding">
                                      <input
                                        type="checkbox"
                                        className="toplogo"
                                        id="userregistration1"
                                        readonly=""
                                      />
                                      <div className="notification-access-slider round"></div>
                                    </label>
                                  ) : (
                                    <label
                                      className=" access-switch access-monitor-slider-padding"
                                      htmlFor="iosNotification"
                                    >
                                      <input
                                        type="checkbox"
                                        className="toplogo"
                                        id="iosNotification"
                                        name="iosNotification"
                                        checked={
                                          notificationData.settings.sendToIos
                                        }
                                        onChange={(e) => handleIosToggle(e)}
                                      />

                                      <div className="access-slider round"></div>
                                    </label>
                                  )}
                                </li>
                                <li className="notificationsetting-li">
                                  <h2 className="notification-setting-toggle-div">
                                    Send to Android devices
                                    <Tooltip
                                      message={
                                        "Enable to send notifications to Android device users."
                                      }
                                    />
                                  </h2>

                                  <label
                                    className=" access-switch access-monitor-slider-padding"
                                    htmlFor="androidNotification"
                                  >
                                    <input
                                      type="checkbox"
                                      className="toplogo"
                                      id="androidNotification"
                                      name="androidNotification"
                                      checked={
                                        notificationData.settings.sendToAndroid
                                      }
                                      onChange={(e) => handleAndroidToggle(e)}
                                    />

                                    <div className="access-slider round"></div>
                                  </label>
                                </li>
                                <li className="notification-li">
                                  <div className="pushnotification-lastsetting">
                                    <div className="publish-immediately">
                                      <h2 className="notification-setting-toggle-div">
                                        Send immediately{" "}
                                        <Tooltip
                                          message={
                                            "Enable to send notifications immediately."
                                          }
                                        />
                                      </h2>
                                      <label
                                        className=" access-switch access-monitor-slider-padding"
                                        htmlFor="publishImmediately"
                                      >
                                        <input
                                          type="checkbox"
                                          className="toplogo"
                                          id="publishImmediately"
                                          name="publishImmediately"
                                          checked={
                                            notificationData.deliverySchedule
                                          }
                                          onChange={(e) =>
                                            handleSendImmediately(e)
                                          }
                                        />
                                        <div className="access-slider round"></div>
                                      </label>
                                    </div>
                                    {!notificationData.showCalender ? (
                                      <div className="newparentsection-selectdate">
                                        <DatePicker
                                          className="date-input-post2"
                                          selected={notificationData.date}
                                          filterTime={filterPassedTime}
                                          showTimeSelect
                                          dateFormat="d MMMM yyyy, HH:mm"
                                          timeIntervals={15}
                                          minDate={new Date()}
                                          maxDate={addDays(new Date(), 7)}
                                          onChange={(e) =>
                                            setNotificationData({ date: e })
                                          }
                                        />
                                      </div>
                                    ) : null}
                                  </div>
                                </li>

                                <li className="d-none notification-li sendallmargin-top">
                                  <div className="pushnotification-lastsetting">
                                    <div className="publish-immediately">
                                      <p className="">Send to all users</p>
                                      <label className=" access-switch access-monitor-slider-padding">
                                        <input
                                          type="checkbox"
                                          className="toplogo"
                                          onChange={() => {}}
                                          id="google_login"
                                          name="google_login"
                                        />
                                        <div className="access-slider round"></div>
                                      </label>
                                    </div>

                                    <div className="notificationsend-all">
                                      <label>Send to</label>
                                      <div className="newparentsection"></div>
                                    </div>
                                    <div className="notificationsend-all">
                                      <label>Exclude</label>
                                      <div className="newparentsection"></div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                      <NotificationPreview
                        notificationData={notificationData}
                        setNotificationData={setNotificationData}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <Spinner />
              )}
            </>
            {!notificationData.isLoading &&
            notificationData.appPlan !== APP_PLANS.preview ? (
              <NotificationCreateFooter
                notificationData={notificationData}
                setNotificationData={setNotificationData}
                toggleConfirmationModal={toggleConfirmationModal}
                handleSaveDraft={handleSaveDraft}
              />
            ) : null}
          </div>
        </div>
        <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
      </section>
      <BuildSettingErrorModal
        notificationData={notificationData}
        setNotificationData={setNotificationData}
      />
      <ConfirmationModal
        notificationData={notificationData}
        setNotificationData={setNotificationData}
        toggleConfirmationModal={toggleConfirmationModal}
        handleSubmitNotification={handleSubmitNotification}
      />
      <NewImageGalleryPopup
        isGalleryPopup={notificationData.isGalleryPopup}
        setIsGalleryPopup={true}
        settingYourdata={(imgName) => {
          setNotificationData({
            notificationImage: imgName,
          });
        }}
        yourErrFun={() => {}}
        uploadPath={
          localStorage.getItem("user_id") +
          "/" +
          localStorage.getItem("Appid") +
          process.env.REACT_APP_Plain_Notification_Media
        }
        settingOrgImgFrom3rdParty={false}
        hideImageGallery={true}
        widthForRestriction={512}
        heightForRestriction={256}
        aspectRatio="2:1"
        isNotCustomUploadOrgImg={true}
        classCompoState={{
          setState: setNotificationData,
        }}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  refetchItems: () =>
    dispatch(
      appDashboardApiSlice.endpoints.appDashboardData.initiate()
    ).refetch(),
});

export default connect(null, mapDispatchToProps)(CreateNotification);
