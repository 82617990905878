import React, {useState, useEffect} from "react";
import "./../assets/css/common.css";
import Header from "../../Dashboard/Header";
import LeftPanel from "./../LeftPanel";
import { Link, useParams } from "react-router-dom";
import {errortoast} from '../../Dashboard/Toaster';
import {
    getSocialAuth,
	saveSocialAuth
} from "../../Api/Agency/AgencyApi";
import NewSpinner from "./../NewSpinner";
import moment from "moment";

const SocialLogin = () =>{

    const [btnloader, setBtnLoader] = useState("d-none");
    const [btnsavelabel, setBtnSaveLabel] = useState("Save");
    const [chkgooglelogin, setChkGoogleLogin] = useState(false);
    const [chkcaptcha, setChkCaptcha] = useState(false);
    const [captchaid, setCaptchaID] = useState("");
    const [captchasecret, setCaptchaSecret] = useState("");
    const [googleappid, setGoogleAppID] = useState("");
    const [googleappsecret, setGoogleAppSecret] = useState("");
    const [chkfacebooklogin, setChkFacebookLogin] = useState(false);
    const [facebookappid, setFacebookAppID] = useState("");
    const [facebookappsecret, setFacebookAppSecret] = useState("");
    const [spinner, setSpinner] = useState(true);
    const [logoimage,setLogoImage] = useState("");
    const [orgname,setOrgName] = useState("");
    const [errorcaptchaid, setErrorCaptchaID] = useState("");
    const [errorcaptchasecret, setErrorCaptchaSecret] = useState("");
    const [errorgoogleappid, setErrorGoogleAppID] = useState("");
    const [errorgoogleappsecret, setErrorGoogleAppSecret] = useState("");
    const [errorfbappid, setErrorFBAppID] = useState("");
    const [errorfbappsecret, setErrorFBAppSecret] = useState("");
    const [rightpanelexpand,setRightPanelExpand] = useState(true);
    const [lastdate,setLastDate] = useState("");


    let { agencyID } = useParams("agencyID");

    useEffect(()=>{

        if(agencyID !== '' && agencyID !== undefined)
        {
            getSocialAuth(agencyID, setChkGoogleLogin, setGoogleAppID, setGoogleAppSecret, setChkFacebookLogin, setFacebookAppID, setFacebookAppSecret, setSpinner, setLastDate, setChkCaptcha,setCaptchaID,setCaptchaSecret);
        }

        document.title = "Authentication | AppMySite";

    }, []);

    const handleGoogleLogin = (e) =>{

    
        if(e.target.checked)
        {            
            setChkGoogleLogin(true);
        }
        else
        {
            setChkGoogleLogin(false);
        }
    }

    const handleCaptcha = (e) =>
    {
        if(e.target.checked)
        {            
            setChkCaptcha(true);
        }
        else
        {
            setChkCaptcha(false);
        } 
    }

    const handleCaptchaID = (e) =>{

        setCaptchaID(e.target.value.replace(/\s/g,''));
        setErrorCaptchaID("");
    }
    
    const handleCaptchaSecret = (e) =>{

        setCaptchaSecret(e.target.value.replace(/\s/g,''));
        setErrorCaptchaSecret("");
    }
    
    const handleGoogleAppID = (e) =>{

        setGoogleAppID(e.target.value.replace(/\s/g,''));
        setErrorGoogleAppID("");
    }

    const handleGoogleAppSecret = (e) =>{

        setGoogleAppSecret(e.target.value.replace(/\s/g,''));
        setErrorGoogleAppSecret("");
    }

    const handleFacebookLogin = (e) =>{

     
        if(e.target.checked)
        {            
            setChkFacebookLogin(true);
        }
        else
        {
            setChkFacebookLogin(false);
        }
    }

    const handleFacebookAppID = (e) =>{

        setFacebookAppID(e.target.value.replace(/\s/g,''));
        setErrorFBAppID("");
    }

    const handleFacebookAppSecret = (e) =>{

        setFacebookAppSecret(e.target.value.replace(/\s/g,''));
        setErrorFBAppSecret("");
    }

    const handleSaveSocialLogin = (e) =>
    {
     

        if(chkgooglelogin)
        {
            if(googleappid === '' || googleappid === null)
            {
                //errortoast("Google App ID is required");
                setErrorGoogleAppID("Google app id is required");
               
            }
            
            if(googleappsecret === '' || googleappsecret === null)
            {
                //errortoast("Google App Secret is required");
                setErrorGoogleAppSecret("Google app secret is required");
               
            }
        }

        if(chkfacebooklogin)
        {
            if(facebookappid === '' || facebookappid === null)
            {
                //errortoast("Facebook App ID is required");
                setErrorFBAppID("Facebook app id is required");
                
            }
            
            if(facebookappsecret === '' || facebookappsecret === null)
            {
                //errortoast("Facebook App Secret is required");
                setErrorFBAppSecret("Facebook app secret is required");
                
            }
        }

        if(chkcaptcha)
        {
            if(captchaid === '' || captchaid === null)
            {
                //errortoast("Facebook App ID is required");
                setErrorCaptchaID("Captcha id is required");
                
            }
            
            if(captchasecret === '' || captchasecret === null)
            {
                //errortoast("Facebook App Secret is required");
                setErrorCaptchaSecret("Captcha secret is required");
                
            }
        }

        if((chkcaptcha && (captchaid == '' || captchasecret == '' || captchaid == null || captchasecret == null)) || (chkgooglelogin && (googleappid == '' || googleappsecret == '' || googleappid == null || googleappsecret == null)) || (chkfacebooklogin && (facebookappid == '' || facebookappsecret =='' || facebookappid == null || facebookappsecret == null)))
        {
            return;
        }

        const params = {
                        agency_id: parseInt(atob(agencyID)),
                        google_app_id: googleappid,
                        google_app_secret: googleappsecret,
                        fb_app_id: facebookappid,
                        fb_app_secret: facebookappsecret,
                        is_google_login_enabled: chkgooglelogin,
                        is_fb_login_enabled: chkfacebooklogin,
                        google_captcha_key:captchaid,
                        google_captcha_secret:captchasecret,
                        is_google_captcha_enabled:chkcaptcha,
                    };
        
        setBtnSaveLabel("");
        setBtnLoader("d-block");
        
  
        
        let savesocialbtndiv = document.getElementById("btnsavesocialid");
		savesocialbtndiv.classList.add("btnloader");

        saveSocialAuth(params, setBtnSaveLabel, setBtnLoader,setLastDate);
    }

    const handleRightPanelExpand = (rightpanelvalue) => {
        setRightPanelExpand(rightpanelvalue);
      }

    return(
    <div className="org-module">
        <Header />
        <div className="org-structure">

        <LeftPanel rightpanelbody={handleRightPanelExpand}/>

            <div className={(localStorage.getItem('sidebaserotate') !== null && localStorage.getItem('sidebaserotate') !== undefined &&
            localStorage.getItem('sidebaserotate') === 'true') ? "org_background org_background-expand"
            : "org_background "}>
                

                <div className="org-setting-head">
                        <div>  
                                <div className="agency-headings">
                            <h1>Authentication</h1>
                            <p class="agency-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                            <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                                <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                                <circle cx="9" cy="9" r="9" stroke="none"/>
                                <circle cx="9" cy="9" r="8.25" fill="none"/>
                                </g>
                                <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"/>
                                <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                            </g>
                            </svg><p class="agency-tooltipsubheader">Configure third-party login integrations for a seamless and secure login experience.</p></p>
                            </div>
                                
                        </div>
                        { spinner ? <></> 

                        :    <div  className="agency-web-timestamp-main-div">

                                <h5 className="agency-web-timestamp">
                                    {lastdate ?
                                        'Updated ' + moment(lastdate).format("D MMMM YYYY, HH:mm")
                                    : ''
                                    }
                                
                                </h5>
                                
                                <div className="save-social-btn" onClick={ handleSaveSocialLogin } id="btnsavesocialid">
                                    <div className={btnloader}>
                                        <div className="new-loader"></div>
                                    </div>
                                    {btnsavelabel}
                                </div>
                            </div>
                        }
                </div>
                { spinner ? 
                    <NewSpinner />
                :
                    <>
                        <div className="org-module-shadow">
                                
                            
                            <div className="org-social-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="org-social-left">

                                        <div className="org-google-login">

                                            <div className="org-social-tabs">
                                                <div className="d-flex align-items-center justify-content-between">    
                                                <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
                                                    <g id="Group_29286" data-name="Group 29286" transform="translate(-1322 -170)">
                                                        <g id="btn_google_dark_normal" transform="translate(1322.285 170)">
                                                        <rect id="Rectangle_967" data-name="Rectangle 967" width="54" height="54" rx="3" transform="translate(-0.285)" fill="#f8fafe"/>
                                                        <g id="recaptcha" transform="translate(14.715 15.04)">
                                                            <path id="Path_102226" data-name="Path 102226" d="M83.31,11.955q0-.259-.012-.515V1.69l-2.684,2.7a11.947,11.947,0,0,0-18.824.334l4.4,4.465a5.849,5.849,0,0,1,1.782-2.008,5.3,5.3,0,0,1,3.362-1.094,1.2,1.2,0,0,1,.425.062,5.8,5.8,0,0,1,4.434,2.682l-3.114,3.128c3.944-.016,8.4-.025,10.232,0" transform="translate(-59.31)" fill="#1c3aa9"/>
                                                            <path id="Path_102227" data-name="Path 102227" d="M11.988-.03c-.173,0-.344,0-.515.012H1.729L4.423,2.675a12.012,12.012,0,0,0,.334,18.884l4.462-4.413a5.847,5.847,0,0,1-2.007-1.788,5.325,5.325,0,0,1-1.094-3.372,1.207,1.207,0,0,1,.061-.427A5.819,5.819,0,0,1,8.86,7.111l3.126,3.124c-.016-3.957-.025-8.427,0-10.265" transform="translate(0 -0.01)" fill="#4285f4"/>
                                                            <path id="Path_102228" data-name="Path 102228" d="M.01,299.68q0,.259.012.515v9.75l2.684-2.7a11.947,11.947,0,0,0,18.824-.333l-4.4-4.465a5.844,5.844,0,0,1-1.782,2.008,5.3,5.3,0,0,1-3.362,1.094,1.2,1.2,0,0,1-.425-.062,5.8,5.8,0,0,1-4.433-2.682l3.114-3.128c-3.944.016-8.4.025-10.232,0" transform="translate(-0.01 -287.715)" fill="#ababab"/>
                                                        </g>
                                                        </g>
                                                    </g>
                                                    </svg>

                                                    <h4>Google reCAPTCHA<span>v2 Checkbox</span></h4>
                                                </div>

                                                <label className="switch" for="captchaenable">
                                                    <input type="checkbox" className="toplogo" id="captchaenable" name="captchaenable" onChange={handleCaptcha} value={chkcaptcha} checked={(chkcaptcha === 1 || chkcaptcha === true) ? "true" : ""} />
                                                    <div className="slider round"></div>
                                                </label>
                                            </div>

                                            {(chkcaptcha === 1 || chkcaptcha === true) ?
                                                <div className="social-auth-detail">

                                                <label>Site key</label>
                                                
                                                    <>
                                                        <input type="text" name="google_appid" value={captchaid} onChange={handleCaptchaID} className={ errorcaptchaid ?  "errormessages" : ""}/>
                                                        {errorcaptchaid ?
                                                            <span className="agencyerror"><svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"><path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"></path></svg>{errorcaptchaid}</span>
                                                        : null
                                                        }
                                                    </>
                                                
                                                
                                                <label>Secret key</label>
                                                <>
                                                    <input type="text" name="google_appsecret" value={captchasecret} onChange={handleCaptchaSecret} className={ errorcaptchasecret ?  "errormessages" : ""}/>
                                                    {errorcaptchasecret ?
                                                            <span className="agencyerror"><svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"><path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"></path></svg>{errorcaptchasecret}</span>
                                                        : null
                                                        }
                                                    </>

                                                </div>
                                            : null
                                            }

                                            </div>

                                            <div className="org-google-login">

                                                <div className="org-social-tabs">
                                                    <div className="d-flex align-items-center justify-content-between">    
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
                                                    <g id="Group_29286" data-name="Group 29286" transform="translate(-1322 -170)">
                                                        <g id="btn_google_dark_normal" transform="translate(1322.285 170)">
                                                        <rect id="Rectangle_967" data-name="Rectangle 967" width="54" height="54" rx="3" transform="translate(-0.285)" fill="#f8fafe"/>
                                                        <g id="logo_googleg_48dp" transform="translate(15.308 15.308)">
                                                            <path id="Shape" d="M20.6,9.835a13.889,13.889,0,0,0-.22-2.471H9v4.673h6.5a5.557,5.557,0,0,1-2.411,3.646v3.031H17a11.782,11.782,0,0,0,3.6-8.879Z" transform="translate(3.081 2.521)" fill="#4285f4" fill-rule="evenodd"/>
                                                            <path id="Shape-2" data-name="Shape" d="M11.753,20.5a11.534,11.534,0,0,0,8-2.927l-3.9-3.031A7.288,7.288,0,0,1,4.993,10.71H.957v3.13a12.076,12.076,0,0,0,10.8,6.656Z" transform="translate(0.328 3.666)" fill="#34a853" fill-rule="evenodd"/>
                                                            <path id="Shape-3" data-name="Shape" d="M5.321,12.679a7.142,7.142,0,0,1,0-4.591V4.958H1.285a12.1,12.1,0,0,0,0,10.851l4.036-3.13Z" transform="translate(0 1.697)" fill="#fbbc05" fill-rule="evenodd"/>
                                                            <path id="Shape-4" data-name="Shape" d="M11.753,4.8a6.527,6.527,0,0,1,4.618,1.807l3.465-3.465A11.61,11.61,0,0,0,11.753,0,12.076,12.076,0,0,0,.957,6.655l4.036,3.13A7.2,7.2,0,0,1,11.753,4.8Z" transform="translate(0.328)" fill="#ea4335" fill-rule="evenodd"/>
                                                            <path id="Shape-5" data-name="Shape" d="M0,0H24.162V24.162H0Z" fill="none" fill-rule="evenodd"/>
                                                        </g>
                                                        </g>
                                                    </g>
                                                    </svg>

                                                        <h4>Google login</h4>
                                                    </div>

                                                    <label className="switch" for="googlelogin">
                                                        <input type="checkbox" className="toplogo" id="googlelogin" name="googlelogin" onChange={handleGoogleLogin} value={chkgooglelogin} checked={(chkgooglelogin === 1 || chkgooglelogin === true) ? "true" : ""} />
                                                        <div className="slider round"></div>
                                                    </label>
                                                </div>

                                                {(chkgooglelogin === 1 || chkgooglelogin === true) ?
                                                    <div className="social-auth-detail">

                                                    <label>Client ID</label>
                                                    
                                                        <>
                                                            <input type="text" name="google_appid" value={googleappid} onChange={handleGoogleAppID} className={ errorgoogleappid ?  "errormessages" : ""}/>
                                                            {errorgoogleappid ?
                                                                <span className="agencyerror"><svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"><path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"></path></svg>{errorgoogleappid}</span>
                                                            : null
                                                            }
                                                        </>
                                                    
                                                    
                                                    <label>Secret key</label>
                                                    <>
                                                        <input type="text" name="google_appsecret" value={googleappsecret} onChange={handleGoogleAppSecret} className={ errorgoogleappsecret ?  "errormessages" : ""}/>
                                                        {errorgoogleappsecret ?
                                                                <span className="agencyerror"><svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"><path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"></path></svg>{errorgoogleappsecret}</span>
                                                            : null
                                                            }
                                                        </>

                                                    </div>
                                                : null
                                                }

                                            </div>

                                            {/* <div className="org-facebook-login">
                                                <div className="org-social-tabs">
                                                    <div className="d-flex align-items-center justify-content-between">    
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
                                                        <g id="Group_29287" data-name="Group 29287" transform="translate(-1396 -170)">
                                                            <g id="Group_38369" data-name="Group 38369">
                                                            <rect id="Rectangle_967" data-name="Rectangle 967" width="54" height="54" rx="3" transform="translate(1396 170)" fill="#f8fafe"/>
                                                            <path id="Path_88" data-name="Path 88" d="M48.644,33.981H45.3V46.246H40.224V33.981H37.812V29.67h2.412V26.881c0-1.995.947-5.118,5.117-5.118l3.757.016v4.184H46.373A1.032,1.032,0,0,0,45.3,27.138v2.537h3.791Z" transform="translate(1379.365 163.236)" fill="#1877f2"/>
                                                            </g>
                                                        </g>
                                                        </svg>
                                                        <h4>Facebook login</h4>
                                                    </div>

                                                    <label className="switch" for="fblogin">
                                                        <input type="checkbox" className="toplogo" id="fblogin" name="fblogin" onChange={handleFacebookLogin} value={chkfacebooklogin} checked={(chkfacebooklogin === 1 || chkfacebooklogin === true) ? "true" : ""} />
                                                        <div className="slider round"></div>
                                                    </label>
                                                </div>

                                                {(chkfacebooklogin === 1 || chkfacebooklogin === true) ?
                                                    <div className="social-auth-detail">
                                                    <label>Client ID</label>
                                                    <>
                                                        <input type="text" name="facebook_appid" value={facebookappid || ''} onChange={handleFacebookAppID}  className={ errorfbappid ?  "errormessages" : ""}/>
                                                        {errorfbappid ?
                                                                <span className="agencyerror"><svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"><path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"></path></svg>{errorfbappid}</span>
                                                            : null
                                                            }
                                                        </>

                                                    <label>Secret key</label>
                                                    <>
                                                        <input type="text" name="facebook_appsecret" value={facebookappsecret} onChange={handleFacebookAppSecret} className={ errorfbappsecret ?  "errormessages" : ""} />
                                                        {errorfbappsecret ?
                                                                <span className="agencyerror"><svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"><path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"></path></svg>{errorfbappsecret}</span>
                                                            : null
                                                            }
                                                        </>
                                                    </div>
                                                : null
                                                }
                                            </div> */}

                                            <div className="org-facebook-login">
                                                <div className="org-social-tabs">
                                                    <div className="d-flex align-items-center justify-content-between">    
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
                                                    <g id="Group_37585" data-name="Group 37585" transform="translate(-1322 -170)">
                                                        <g id="btn_google_dark_normal" transform="translate(1322.285 170)">
                                                        <rect id="Rectangle_967" data-name="Rectangle 967" width="54" height="54" rx="3" transform="translate(-0.285)" fill="#f8fafe"/>
                                                        <g id="microsoft-5" transform="translate(15.941 16.226)">
                                                            <path id="Path_101703" data-name="Path 101703" d="M10,10H0V0H10Z" transform="translate(-0.227 -0.226)" fill="#ff3e00"/>
                                                            <path id="Path_101704" data-name="Path 101704" d="M1321.6,10h-10V0h10V10Z" transform="translate(-1299.826 -0.226)" fill="#6bbe00"/>
                                                            <path id="Path_101705" data-name="Path 101705" d="M10,1322.1H0v-10H10Z" transform="translate(-0.227 -1300.326)" fill="#00a5f6"/>
                                                            <path id="Path_101706" data-name="Path 101706" d="M1321.6,1322.1h-10v-10h10v10Z" transform="translate(-1299.826 -1300.326)" fill="#ffb700"/>
                                                        </g>
                                                        </g>
                                                    </g>
                                                    </svg>

                                                        <h4>Microsoft login</h4>
                                                    </div>

                                                    <h6>Coming soon</h6>
                                                </div>

                                            </div>

                                            <div className="org-facebook-login">
                                                <div className="org-social-tabs">
                                                    <div className="d-flex align-items-center justify-content-between">    
                                                    <svg id="Group_37589" data-name="Group 37589" xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
                                                        <g id="btn_google_dark_normal">
                                                            <rect id="Rectangle_967" data-name="Rectangle 967" width="54" height="54" rx="3" fill="#f8fafe"/>
                                                            <path id="linkedin-icon" d="M134.684,133.695H130.1V118.87h4.589Zm-2.316-16.766a2.735,2.735,0,1,1,2.713-2.735A2.725,2.725,0,0,1,132.367,116.929Zm19.512,16.766h-4.566v-7.782c0-2.134-.811-3.326-2.5-3.326-1.837,0-2.8,1.241-2.8,3.326v7.782h-4.4V118.87h4.4v2a5.17,5.17,0,0,1,4.467-2.449c3.143,0,5.394,1.919,5.394,5.89Z" transform="translate(-113.654 -95.458)" fill="#0966c2"/>
                                                        </g>
                                                        </svg>


                                                        <h4>LinkedIn login</h4>
                                                    </div>

                                                    <h6>Coming soon</h6>
                                                </div>

                                            </div>

                                            <div className="org-facebook-login">
                                                <div className="org-social-tabs">
                                                    <div className="d-flex align-items-center justify-content-between">    
                                                    <svg id="Group_37586" data-name="Group 37586" xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
                                                        <rect id="Rectangle_967" data-name="Rectangle 967" width="54" height="54" rx="3" fill="#f8fafe"/>
                                                        <path id="twitter-3" d="M143.806,163.79a10.683,10.683,0,0,1-3.063.839,5.348,5.348,0,0,0,2.345-2.952,10.649,10.649,0,0,1-3.387,1.294,5.337,5.337,0,0,0-9.227,3.653,5.448,5.448,0,0,0,.137,1.214,15.148,15.148,0,0,1-11-5.574,5.343,5.343,0,0,0,1.651,7.122,5.322,5.322,0,0,1-2.416-.668v.069a5.337,5.337,0,0,0,4.279,5.231,5.415,5.415,0,0,1-2.409.092,5.337,5.337,0,0,0,4.983,3.7,10.7,10.7,0,0,1-6.625,2.284,10.872,10.872,0,0,1-1.273-.074,15.17,15.17,0,0,0,23.354-12.78c0-.229-.006-.46-.016-.689a10.844,10.844,0,0,0,2.66-2.76Z" transform="translate(-103.806 -145.288)" fill="#00a2f6"/>
                                                        </svg>


                                                        <h4>Twitter login</h4>
                                                    </div>

                                                    <h6>Coming soon</h6>
                                                </div>

                                            </div>

                                            <div className="org-facebook-login">
                                                <div className="org-social-tabs">
                                                    <div className="d-flex align-items-center justify-content-between">    
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
                                                        <g id="Group_37590" data-name="Group 37590" transform="translate(-1396 -170)">
                                                            <rect id="Rectangle_967" data-name="Rectangle 967" width="54" height="54" rx="3" transform="translate(1396 170)" fill="#f8fafe"/>
                                                            <path id="apple-13" d="M23.723,46.835a5.851,5.851,0,0,1,3.134-5.315,6.734,6.734,0,0,0-5.308-2.8c-2.225-.175-4.657,1.3-5.547,1.3-.94,0-3.1-1.235-4.788-1.235C7.717,38.844,4,41.577,4,47.136a15.621,15.621,0,0,0,.9,5.089c.8,2.3,3.7,7.941,6.719,7.847,1.579-.038,2.695-1.122,4.751-1.122,1.993,0,3.027,1.122,4.788,1.122,3.046-.044,5.666-5.171,6.43-7.477a6.213,6.213,0,0,1-3.867-5.76ZM20.176,36.544A5.9,5.9,0,0,0,21.68,32a6.646,6.646,0,0,0-4.256,2.187,6,6,0,0,0-1.6,4.506A5.264,5.264,0,0,0,20.176,36.544Z" transform="translate(1407.705 151.459)"/>
                                                        </g>
                                                        </svg>

                                                        <h4>Apple login</h4>
                                                    </div>

                                                    <h6>Coming soon</h6>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                    
                                    <div className="col-md-6">
                                        <div className="org-social-right">
                                            <div className="org-login-form">
                                                <h5>Sign In</h5>
                                                <div className="org-social-btns">
                                                    { chkgooglelogin ?
                                                    <div className="agency-google-btns">
                                                            <img src={require("../assets/images/g-button.png").default} alt="google" className="img-fluid"/>
                                                            </div>
                                                    :
                                                        null
                                                    }

                                                    { chkfacebooklogin ?
                                                    <div className="agency-facebook-btns">
                                                        <img src={require("../assets/images/fb-button.png").default} alt="facebook" className="img-fluid"/>
                                                        </div>
                                                    :
                                                        null
                                                    }

                                                </div>    
                                                <img src={require("../assets/images/login-one.png").default} alt="login" className="img-fluid"/>
                                                { chkcaptcha ?
                                                <img src={require("../assets/images/captcha.png").default} alt="login" className="img-fluid social-captcha"/>
                                                : null
                                                }
                                                <img src={require("../assets/images/login-two.png").default} alt="login" className="img-fluid social-btns"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                
                        </div>

                        <div className="agency-mobile-btns">

                        
                        <div className="agency-mobile-btns-blue" onClick={ handleSaveSocialLogin } id="btnsavesocialid">
                                        <div className={btnloader}>
                                            <div className="new-loader"></div>
                                        </div>
                                        {btnsavelabel}
                                    </div>

                        <h5> {lastdate ?
                                            'Updated ' + moment(lastdate).format("D MMMM YYYY, HH:mm")
                                        : ''
                                        }</h5>

                        </div>

                    </>
                }
            </div>
        </div>
    </div>
    );
    
}

export default SocialLogin;