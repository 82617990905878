import React, {useState, useEffect} from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { errortoast } from "./../../Dashboard/Toaster";

const SegmentPopup = (props) =>{

    const [blockvalue, setBlockValue] = useState("");

    const handleSegmentBlocks = (event, segmentid) =>{
       //event.preventDefault();

        //props.onChange(event.target.value);
        setBlockValue(segmentid);

    }

    const btnClickDone = (event) =>{
        event.preventDefault();

        if(blockvalue === '')
        {
            errortoast("Please select atleast one segment filter");
        }
        else
        {
            props.btnPopupClickDone(blockvalue);

            setBlockValue("");
        }
        
    }

    const btnClickCancel = (event) =>{
        event.preventDefault();

        props.btnPopupClickCancel(blockvalue);

        setBlockValue("");

    }


    return(
    <Modal isOpen={props.popupopen} style={{ maxWidth: "66.67%", maxHeight: "660px" }} dataclass="audience-modal-wrapper" className="modal-dialog-centered" >
        <ModalHeader className="audience-modal-header">
            <div className="d-md-flex add-filter-modal align-items-center justify-content-between ">
                <h2 className="">Add filter</h2>
                <div className="cross-icon-modal">
                    <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059" onClick={btnClickCancel}>
                        <path id="Union_18" data-name="Union 18" d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z" transform="translate(0.529 0.531)" fill="#C8CEDB" stroke="#C8CEDB" strokeWidth="1.5" />
                    </svg>
                </div>
            </div>
        </ModalHeader>
        <ModalBody className="create-segment audience-modal-body">
            <div className="img-container d-flex flex-wrap">
                <div className={ blockvalue === 1 ?  "img-box selected-segment" : "img-box" } style={{ 'cursor': "pointer" }}  onClick={(e) => handleSegmentBlocks(e, 1) }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="35.61" height="32.391" viewBox="0 0 35.61 32.391" data-value="1">
                        <g transform="translate(5.927 2.895)">
                            <path d="M13.236,0A13.236,13.236,0,1,1,0,13.236,13.38,13.38,0,0,1,3.877,3.877,13.013,13.013,0,0,1,13.236,0Z" transform="translate(0.252 0.065)" fill="none" />
                            <path d="M13.236,27.971A14.736,14.736,0,0,1-1.5,13.236,14.863,14.863,0,0,1,2.792,2.841l2.17,2.071A11.918,11.918,0,0,0,1.5,13.236,11.736,11.736,0,1,0,13.236,1.5v-3A14.736,14.736,0,0,1,27.972,13.236,14.736,14.736,0,0,1,13.236,27.971Z" transform="matrix(-0.105, -0.995, 0.995, -0.105, 1.708, 27.847)" fill="#7297ff" />
                            <path d="M8.739,8.739H0v-3H5.739V0h3Z" transform="translate(0.252 5.548) rotate(45)" fill="#7297ff" />
                            <path d="M17.321,19.794l-6.237-6.237V5.8h3v6.518l5.358,5.358Z" transform="translate(0.903 0.365)" fill="#7297ff" />
                        </g>
                    </svg>
                    <p className="fs-13 txt-box">First visit</p>
                    <span className="audience-popup-tooltip">Filter users based on the date they first accessed your app.</span>
                </div>
                <div className={ blockvalue === 2 ?  "img-box selected-segment" : "img-box" } style={{ 'cursor': "pointer" }} onClick={(e) => handleSegmentBlocks(e, 2) }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="35.611" height="32.391" viewBox="0 0 35.611 32.391" data-value="2" >
                        <g transform="translate(1.649 1.648)">
                            <path d="M13.236,0A13.236,13.236,0,1,0,26.472,13.236,13.38,13.38,0,0,0,22.6,3.877,13.013,13.013,0,0,0,13.236,0Z" transform="translate(1.31 1.312)" fill="none" />
                            <path d="M13.236,26.472A13.236,13.236,0,1,0,0,13.236,13.38,13.38,0,0,0,3.877,22.6" transform="matrix(0.105, -0.995, 0.995, 0.105, 0, 26.327)" fill="none" stroke="#7297ff" stroke-width="3" />
                            <path d="M7.239,7.239V0H0" transform="translate(32.901 11.914) rotate(135)" fill="none" stroke="#7297ff" stroke-width="3" />
                            <path d="M18.382,18.733l-5.8-5.8V5.8" transform="translate(2.164 1.612)" fill="none" stroke="#7297ff" stroke-width="3" />
                        </g>
                    </svg>
                    <p className="fs-13 txt-box">Last visit</p>
                    <span className="audience-popup-tooltip">Target users by the date they last engaged with your app.</span>
                </div>
                <div className={ blockvalue === 3 ?  "img-box selected-segment" : "img-box" } style={{ 'cursor': "pointer" }}  onClick={(e) => handleSegmentBlocks(e, 3) }>
                    <svg className="top-svg" width="25" height="18.999" viewBox="0 0 25 18.999" data-value="3">
                        <path d="M5480,1140v-3h19v3Zm-6,0v-3h3v3Zm6-8v-3h19v3Zm-6,0v-3h3v3Zm6-8v-3h19v3Zm-6,0v-3h3v3Z" transform="translate(-5474 -1121)" fill="#ffc834" />
                    </svg>
                    <p className="fs-13 txt-box txt-top">Visit count</p>
                    <span className="audience-popup-tooltip">Segment users based on the total number of visits to your app.</span>
                </div>
                <div className={ blockvalue === 4 ?  "img-box selected-segment" : "img-box" } style={{ 'cursor': "pointer" }}  onClick={(e) => handleSegmentBlocks(e, 4) }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="29.472" height="29.471" viewBox="0 0 29.472 29.471" data-value="4">
                        <g transform="translate(0.19 0.188)">
                            <path d="M13.236,0A13.236,13.236,0,1,0,26.472,13.236,13.38,13.38,0,0,0,22.6,3.877,13.013,13.013,0,0,0,13.236,0Z" transform="translate(1.31 1.312)" fill="none" stroke="#ff7db0" stroke-width="3" />
                            <path d="M18.382,18.733l-5.8-5.8V5.8" transform="translate(2.164 1.612)" fill="none" stroke="#ff7db0" stroke-width="3" />
                        </g>
                    </svg>
                    <p className="fs-13 txt-box txt-box-m2"> Visit duration </p>
                    <span className="audience-popup-tooltip">Define groups based on the time users spend in your app per session.</span>
                </div>
                <div className={ blockvalue === 5 ?  "img-box selected-segment" : "img-box" } style={{ 'cursor': "pointer" }} onClick={(e) => handleSegmentBlocks(e, 5) }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" data-value="5">
                        <g transform="translate(-973 -226)">
                            <path d="M13.855,24H2.058L0,18.546H6.349L.089,0H13.855A2.166,2.166,0,0,1,16,2.181V21.817A2.165,2.165,0,0,1,13.855,24Z" transform="translate(987 232.001)" fill="#47c2ff" />
                            <path d="M2.111,0h8.916L19,23H2.111A2.1,2.1,0,0,1,0,20.909V2.091A2.1,2.1,0,0,1,2.111,0Z" transform="translate(974.5 227.5)" fill="none" stroke="#47c2ff" strokeLinejoin="round" stroke-width="3" />
                            <path d="M944.186,402.009l3.664-9.746,3.665,9.746" transform="translate(34.349 -157.679)" fill="none" stroke="#47c2ff" strokeLinejoin="round" strokeWidth="2" />
                            <path d="M945.605,396.75h4.016" transform="translate(34.606 -155.684)" fill="none" stroke="#47c2ff" strokeWidth="2" />
                            <path d="M956.132,396.75h9.259" transform="translate(35.343 -156.339)" fill="none" stroke="#fff" strokeWidth="2" />
                            <path d="M959.729,395.6v2.448" transform="translate(35.643 -157.667)" fill="none" stroke="#fff" strokeWidth="2" />
                            <path d="M963.23,396.75a10.889,10.889,0,0,1-6.058,8.4" transform="translate(35.43 -156.339)" fill="none" stroke="#fff" strokeWidth="2" />
                            <path d="M957.881,399.721a27.582,27.582,0,0,0,5.764,6.442" transform="translate(35.016 -156.091)" fill="none" stroke="#fff" strokeWidth="2" />
                        </g>
                    </svg>
                    <p className="fs-13 txt-box txt-box-m2">Language</p>
                    <span className="audience-popup-tooltip">Group users by the language settings on their device.</span>
                </div>
                <div className={ blockvalue === 6 ?  "img-box selected-segment" : "img-box" } style={{ 'cursor': "pointer" }}  onClick={(e) => handleSegmentBlocks(e, 6) }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="32" viewBox="0 0 22 32" data-value="6">
                        <g transform="translate(-1102 -227)">
                            <circle  cx="4.5" cy="4.5" r="4.5" transform="translate(1102 227)" fill="#a678ff" />
                            <circle cx="4.5" cy="4.5" r="4.5" transform="translate(1102 250)" fill="#a678ff" />
                            <circle cx="4.5" cy="4.5" r="4.5" transform="translate(1115 227)" fill="#a678ff" />
                            <rect width="3" height="18" transform="translate(1105 234)" fill="#a678ff" />
                            <path d="M20.5,8v7.75a4.844,4.844,0,0,1-4.844,4.844H7" transform="translate(1099 224)" fill="none" stroke="#a678ff" stroke-width="3" />
                        </g>
                    </svg>
                    <p className="fs-13 txt-box">App version</p>
                    <span className="audience-popup-tooltip">Target users by the specific version of your app they are using.</span>
                </div>
                <div className={ blockvalue === 7 ?  "img-box selected-segment" : "img-box" } style={{ 'cursor': "pointer" }}   onClick={(e) => handleSegmentBlocks(e, 7) }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="36.394" height="36.394" viewBox="0 0 36.394 36.394" data-value="7">
                        <path d="M20.615,4A10.617,10.617,0,0,0,10,14.615c0,7.961,10.615,19.713,10.615,19.713S31.23,22.576,31.23,14.615A10.617,10.617,0,0,0,20.615,4Zm0,14.406a3.791,3.791,0,1,1,3.791-3.791A3.792,3.792,0,0,1,20.615,18.406Z" transform="translate(-2.418 -0.967)" fill="#ff7d7d" />
                        <path d="M0,0H36.394V36.394H0Z" fill="none" />
                    </svg>
                    <p className="fs-13 txt-box txt-box-pd">Location</p>
                    <span className="audience-popup-tooltip">Filter users based on their specific geographic location.</span>
                </div>
                {/* <div className={ blockvalue === 8 ?  "img-box selected-segment" : "img-box" } style={{ 'cursor': "pointer" }}  onClick={(e) => handleSegmentBlocks(e, 8) }>
                    <svg className="top-svg" xmlns="http://www.w3.org/2000/svg" width="30" height="23" viewBox="0 0 30 23" data-value="8">
                        <g transform="translate(-1123.036 -583.555)">
                            <path d="M6282.374,1340.328h-26a2,2,0,0,1-2-2V1320l13.83,12.216a1.5,1.5,0,0,0,1.972.013l14.2-12.234v18.335A2,2,0,0,1,6282.374,1340.328Zm-13.165-11.229h0l-13.272-11.723a2,2,0,0,1,.437-.048h26a2.017,2.017,0,0,1,.439.049l-13.6,11.722Z" transform="translate(-5131.339 -733.772)" fill="#40d1ec" />
                        </g>
                    </svg>
                    <p className="fs-13 txt-box txt-box-m2">Email</p>
                </div> */}
                <div className={ blockvalue === 9 ?  "img-box selected-segment" : "img-box" } style={{ 'cursor': "pointer" }}  onClick={(e) => handleSegmentBlocks(e, 9) }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="29.999" height="29.98" viewBox="0 0 29.999 29.98" data-value="9">
                        <path d="M52.447,45.594a14.837,14.837,0,0,0,1.5-6.532A15.025,15.025,0,0,0,42.815,24.571h-.072a14.967,14.967,0,0,0-13.539,3.1l-.187.173a14.981,14.981,0,0,0,9.935,26.214H39.3A15.039,15.039,0,0,0,52.36,45.782h0Zm-.807-6.532a12.876,12.876,0,0,1-.793,4.427l-6.69-4.109a2.307,2.307,0,0,0-.908-.332l-3.288-.433a2.321,2.321,0,0,0-2.206.98h-1.24l-.548-1.139A2.336,2.336,0,0,0,34.38,37.2l-.952-.2.36-.851a1.168,1.168,0,0,1,1.067-.707h2.321a2.321,2.321,0,0,0,1.11-.288l1.759-.98a2.321,2.321,0,0,0,.433-.3l3.879-3.518a2.264,2.264,0,0,0,.649-2.437A12.689,12.689,0,0,1,51.639,39.063Zm-25.378,0a12.559,12.559,0,0,1,1.37-5.724l1.5,4.023A2.321,2.321,0,0,0,30.8,38.8l.793.173h.014l1.73.375a1.125,1.125,0,0,1,.793.62l.3.634a2.321,2.321,0,0,0,2.076,1.3h.173l-1.11,2.48a2.293,2.293,0,0,0,.4,2.509L38.3,49.4a1.2,1.2,0,0,1,.288.995l-.26,1.341A12.7,12.7,0,0,1,26.262,39.063Z"
                        transform="translate(-23.947 -24.078)" fill="#83d298" />
                    </svg>
                    <p className="fs-13 txt-box txt-box-m2">Country</p>
                    <span className="audience-popup-tooltip">Create audience groups by filtering users based on their country of access.</span>
                </div>
                {/*<div className="img-box">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28.384" height="32.248" viewBox="0 0 28.384 32.248"  onClick={handleSegmentBlocks} data-value="10">
                        <g transform="translate(0 3)">
                            <path d="M881.275,251.285l-5.492,2.557a4.81,4.81,0,0,0-2.743,4.343v4.232h28.384v-4.232a4.809,4.809,0,0,0-2.742-4.343l-5.526-2.557" transform="translate(-873.041 -233.168)" fill="#fc8855" />
                                <g transform="translate(-881.317 -208)" fill="#fc8855" >
                                    <path d="M 895.5086669921875 227.32568359375 C 894.1669311523438 227.32568359375 892.8527221679688 226.9582061767578 891.6024780273438 226.2334594726562 C 890.489990234375 225.5885925292969 889.4793090820312 224.6915893554688 888.5984497070312 223.5673522949219 C 886.9124755859375 221.4155883789062 885.9059448242188 218.6250152587891 885.9059448242188 216.1025543212891 C 885.9059448242188 210.8076782226562 890.2136840820312 206.4999847412109 895.5086669921875 206.4999847412109 C 900.8037109375 206.4999847412109 905.111572265625 210.8076782226562 905.111572265625 216.1025543212891 C 905.111572265625 218.6250305175781 904.1050415039062 221.4156188964844 902.4190673828125 223.5673675537109 C 901.5381469726562 224.6915893554688 900.5274658203125 225.5886077880859 899.4149780273438 226.2334594726562 C 898.1646728515625 226.9582061767578 896.8504028320312 227.32568359375 895.5086669921875 227.32568359375 Z" stroke="none" />
                                    <path d="M 895.5086669921875 207.9999847412109 C 891.0336303710938 207.9999847412109 887.4059448242188 211.6275939941406 887.4059448242188 216.1025543212891 C 887.4059448242188 220.5774841308594 891.0336303710938 225.82568359375 895.5086669921875 225.82568359375 C 899.98388671875 225.82568359375 903.611572265625 220.5774841308594 903.611572265625 216.1025543212891 C 903.611572265625 211.6275939941406 899.98388671875 207.9999847412109 895.5086669921875 207.9999847412109 M 895.5086669921875 204.9999847412109 C 898.474365234375 204.9999847412109 901.2625732421875 206.1548309326172 903.359619140625 208.2518005371094 C 905.4566650390625 210.3487854003906 906.611572265625 213.1369018554688 906.611572265625 216.1025543212891 C 906.611572265625 218.9916687011719 905.5137939453125 222.0496826171875 903.5997924804688 224.4925079345703 C 902.6005859375 225.7677612304688 901.4456787109375 226.7901153564453 900.167236328125 227.5312042236328 C 898.6854248046875 228.3901519775391 897.1180419921875 228.82568359375 895.5086669921875 228.82568359375 C 893.8992919921875 228.82568359375 892.3319702148438 228.3901519775391 890.8501586914062 227.5311889648438 C 889.57177734375 226.7901000976562 888.4168701171875 225.7677459716797 887.417724609375 224.4924774169922 C 885.503662109375 222.0496520996094 884.4059448242188 218.9916381835938 884.4059448242188 216.1025543212891 C 884.4059448242188 213.1369171142578 885.5608520507812 210.3488159179688 887.6578979492188 208.2518157958984 C 889.7548828125 206.1548461914062 892.5430297851562 204.9999847412109 895.5086669921875 204.9999847412109 Z" stroke="none" fill="#fff"
                                />
                            </g>
                        </g>
                    </svg>
                    <p className="fs-13 txt-box txt-box-mr">Test Users</p>
                </div>*/}
            </div>

            <div className="w-100 modal-filter-footer mob-modal-filter-footer">
                <div className="d-flex align-items-center justify-content-between">
                    <button className=" filter-notification-cancel" onClick={btnClickCancel}>
                    Cancel
                    </button>
                    <button className="filter-notification-send" onClick={btnClickDone}>
                        <div className="d-flex align-items-center justify-content-center h-100 w-100">
                            Done
                        </div>
                    </button>
                </div>
            </div>
        </ModalBody>
        <ModalFooter>
            <div className="w-100 modal-filter-footer web-modal-filter-footer">
                <div className="d-flex align-items-center justify-content-between">
                    <button className=" filter-notification-cancel" onClick={btnClickCancel}>
                    Cancel
                    </button>
                    <button className={ (blockvalue === "" || blockvalue === null) ? "filter-notification-send btndisable" : "filter-notification-send" } onClick={btnClickDone}>
                        <div className="d-flex align-items-center justify-content-center h-100 w-100">
                            Done
                        </div>
                    </button>
                </div>
            </div>
        </ModalFooter>
    </Modal>
    );
}

export default SegmentPopup;