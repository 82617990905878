import React, { useState, useReducer, lazy, Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";

// Components
import Header from "../../../Dashboard/Header.js";
import Leftpanel from "../../../Dashboard/Leftpanel.js";
import ModuleTitleTooltip from "../../../Components/commonIcons/ModuleTitleTooltip.js";
import ModuleNavigations from "../../../Components/commonComponents/ModuleNavigation/ModuleNavigations.js";
import UpdatedAt from "../../../Components/commonComponents/UpdatedAt.js";
import SettingIcon from "../../../Components/commonIcons/SettingIcon.js";
import SaveButton from "../../../Components/commonComponents/SaveButton.js";
import BottomItemAccordian from "../../../Components/commonComponents/BottomBar/BottomItemAccordian.js";
import AmsSpinner from "../../../Components/commonIcons/AmsSpinner.js";
import BottomPreview from "./BottomPreview.js";
import Tooltip from "../../../Components/commonIcons/Tooltip.js";
import DefaulItem from "../../../Components/commonComponents/WithoutCancelModal.js";
import DefaulLandingScreen from "../../../Components/commonComponents/WithoutCancelModal.js";
import "./bottombar.css";
import {
  WORDPRESSOPTIONS,
  WOOCOMMERCEOPTIONS,
  CUSTOMAPPOPTIONS,
  W2APPOPTIONS,
  SETTINGS,
  DEFAULT_ITEM_POPUP,
  MAIN_MODULE_VALIDATION_POPUP,
  LINK_ARRAY,
} from "../../../Constants/settingConsts/bottomBar.js";
import HomeIcon from "../../../Components/commonIcons/HomeIcon.js";

// Utilities
import { errortoast, sucesstoast } from "../../../Dashboard/Toaster.js";
import { MetaTitle } from "../../../utils/commonUtils.js";

// Redux
import {
  setTaxonomyData,
  setTypeData,
} from "../../../Redux/slices/userWebsiteDataSlice.js";

// Services
import {
  useTaxonomyDataQuery,
  useTypeDataQuery,
} from "../../../services/userWebsiteApiSlice.js";
import {
  useBottomDataQuery,
  useSaveBottomDataMutation,
} from "../../../services/bottomApiSlice.js";
import BottomSetting from "./BottomSetting.js";
import { useMenuDataQuery } from "../../../services/menuApiSlice.js";

// const BottomSetting = lazy(() => import("./BottomSetting.js"));

const BottomBar = () => {
  const dispatch = useDispatch();
  const { refetch: refetchMenu } = useMenuDataQuery();
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1200); // Adjust the width as needed
    };

    handleResize(); // Check initially
    window.addEventListener("resize", handleResize); // Listen for resize
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [saveBottomBarData] = useSaveBottomDataMutation();

  const saveBottomPostApi = (params) => {
    saveBottomBarData(params)
      .unwrap()
      .then((res) => {
        if (res.code === 200) {
          setBottomState({
            loader: false,
            updated_at: res.getdata.updated_at,
          });
          refetch();
          refetchMenu();
          sucesstoast("The record has been saved.");
        } else {
          errortoast("Oops! Something went wrong.");
          setBottomState({
            loader: false,
          });
        }
      })
      .catch((err) => {});
  };

  const getLocalStorageBoolean = (key) => {
    return localStorage.getItem(key) === "true";
  };

  const [bottomState, setBottomState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      spinner: true,
      appRightPanelExpand: getLocalStorageBoolean("appSideBarCollapse"),
      linkArr: LINK_ARRAY,
      openBottomSetting: false,
      isMenuEnable: false,
      hidecontrol: true,
      presetColors: null,
      enable_bottom_menu: true,
      bottom_menu_items: [],
      pewview_bottom_item: [],
      setting: [],
      mergeArray: [],
      setinputcolor: 0,
      keys_data: null,
      is_chat_enabled: 0,
      chat_data: [],
      storeUrl: "",
      updated_at: "",
      website_technology: parseInt(localStorage.getItem("website_technology")),
      is_any_default_item_in_menu: false,
      defaultOptionData: [],
      openMainModuleValidationPopUp: false,
      openDefaultItemValidationPopUp: false,
      wordpressOptions: WORDPRESSOPTIONS,
      wooCommerceOptions: WOOCOMMERCEOPTIONS,
      customAppOptions: CUSTOMAPPOPTIONS,
      w2AppOptions: W2APPOPTIONS,
      settings: SETTINGS,
    }
  );

  const { data: bottomApiData, refetch } = useBottomDataQuery();

  const { data: apiTaxonomyData } = useTaxonomyDataQuery(
    { storeUrl: bottomState.storeUrl },
    {
      skip:
        !bottomState.storeUrl ||
        parseInt(localStorage.getItem("website_technology")) === 2,
    }
  );

  const { data: apiTypeData } = useTypeDataQuery(
    { storeUrl: bottomState.storeUrl },
    {
      skip:
        !bottomState.storeUrl ||
        parseInt(localStorage.getItem("website_technology")) === 2,
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = MetaTitle("Bottom bar");
    settingBottomListingData();
    if (parseInt(localStorage.getItem("website_technology")) === 3) {
      setBottomState({ defaultOptionData: bottomState.customAppOptions });
    } else if (parseInt(localStorage.getItem("website_technology")) === 2) {
      setBottomState({ defaultOptionData: bottomState.w2AppOptions });
    } else {
      if (parseInt(localStorage.getItem("website_technology")) === 5) {
        setBottomState({ defaultOptionData: bottomState.wooCommerceOptions });
      } else if (parseInt(localStorage.getItem("website_technology")) === 4) {
        setBottomState({ defaultOptionData: bottomState.wordpressOptions });
      }
    }
  }, [bottomApiData]);

  useEffect(() => {
    if (apiTaxonomyData) {
      let taxnomyArray = [];

      if (typeof apiTaxonomyData === "object" && apiTaxonomyData) {
        taxnomyArray = Object.keys(apiTaxonomyData).map(
          (key) => apiTaxonomyData[key]
        );
      }

      let currObj = { ...bottomState };
      currObj.taxonomyData = taxnomyArray;
      setBottomState(currObj);

      dispatch(setTaxonomyData(taxnomyArray));
    }
  }, [apiTaxonomyData]);

  useEffect(() => {
    if (apiTypeData) {
      let typeArray = [];

      if (typeof apiTypeData === "object" && apiTypeData !== null) {
        typeArray = Object.keys(apiTypeData).map((key) => apiTypeData[key]);
      }

      let currObj = { ...bottomState };
      currObj.typesData = typeArray;
      setBottomState(currObj);
      dispatch(setTypeData(typeArray));
    }
  }, [apiTypeData]);

  const handleRightPanelExpand = (value) => {
    setBottomState({
      appRightPanelExpand: value,
    });
  };

  const handleSettingBtn = () => {
    setBottomState({
      openBottomSetting: !bottomState.openBottomSetting,
    });
  };

  const handleOpenMainModuleValidationPopUp = (e) => {
    setBottomState({
      openMainModuleValidationPopUp: !bottomState.openMainModuleValidationPopUp,
    });
  };

  const handleOpenDefaultItemPopUp = (e) => {
    setBottomState({
      openDefaultItemPopUp: !bottomState.openDefaultItemPopUp,
    });
  };

  const handleToggle = (e) => {
    // if (!bottomState.isMenuEnable && !e.target.checked) {
    //   setBottomState({
    //     openMainModuleValidationPopUp:
    //       !bottomState.openMainModuleValidationPopUp,
    //   });
    //   return;
    // }
    if (!e.target.checked) {
      // setBottomState({
      //   bottom_menu_items: bottomState.bottom_menu_items.map((item) => {
      //     return {
      //       ...item,
      //       is_default: 0,
      //     };
      //   }),
      // });
      setBottomState({ isRowOpen: new Array(bottomState.bottom_menu_items.length).fill(false) });
    }

    if (!bottomState.loginemail && !bottomState.google_login) {
      setBottomState({ loginemail: true });
    }

    if (bottomState.enable_bottom_menu) {
      setBottomState({ rowOpen: "" });
    }

    setBottomState({ enable_bottom_menu: !bottomState.enable_bottom_menu });
  };

  const settingBottomListingData = () => {
    let currObj = { ...bottomState };
    let currSettings = { ...bottomState.settings };
    if (bottomApiData) {
      if (bottomApiData.code === 200) {
        let newBottomMenuArr = JSON.parse(
          JSON.stringify(bottomApiData?.data?.bottom_menu_items)
        );

        newBottomMenuArr.map((item) => {
          item.temp_item_type = item.item_type;
          item.module_label = "";
          item.temp_module_label = "";
          item.temp_invidual_selected_item_id = item.invidual_selected_item_id;
          item.temp_subData = [];
          item.subData = [];
          item.temp_breadcrumbArr = [];
          item.breadcrumbArr = [];
          item.showL1Options = true;
          item.temp_showL1Options = true;
          item.subdropdownSpinner = false;
          item.subdropdownData = [];
          item.temp_rest_base = item.rest_base;
          item.temp_slug = item.slug;
          item.temp_post_type = item.post_type;
        });

        currObj.bottom_menu_items = newBottomMenuArr;

        currObj.enable_bottom_menu = bottomApiData.data.enable_bottom_menu
          ? true
          : false;
        currSettings.bottom_menu_bg_colour = bottomApiData.data
          .bottom_menu_style.bottom_menu_bg_colour_object
          ? bottomApiData.data.bottom_menu_style.bottom_menu_bg_colour_object
              ?.portal_data
          : bottomApiData.data.bottom_menu_style.bottom_menu_bg_colour;

        currSettings.bottom_menu_border_colour = bottomApiData.data
          .bottom_menu_style.bottom_menu_border_colour_object
          ? bottomApiData.data.bottom_menu_style
              .bottom_menu_border_colour_object?.portal_data
          : bottomApiData.data.bottom_menu_style.bottom_menu_border_colour;

        currSettings.bottom_menu_icon_colour =
          bottomApiData.data.bottom_menu_style.bottom_menu_icon_colour_object
            ?.portal_data || "rgba(255,255,255,1)";

        currSettings.bottom_menu_selected_icon_colour =
          bottomApiData.data.bottom_menu_style
            .bottom_menu_selected_icon_colour_object?.portal_data ||
          "rgba(187,209,255,1)";

        currSettings.bottom_menu_text_colour = bottomApiData.data
          .bottom_menu_style.bottom_menu_text_colour_object
          ? bottomApiData.data.bottom_menu_style.bottom_menu_text_colour_object
              ?.portal_data
          : bottomApiData.data.bottom_menu_style
              .bottom_menu_icon_and_text_colour;

        currSettings.bottom_menu_selected_text_colour = bottomApiData.data
          .bottom_menu_style.bottom_menu_selected_text_colour_object
          ? bottomApiData.data.bottom_menu_style
              .bottom_menu_selected_text_colour_object?.portal_data
          : bottomApiData.data.bottom_menu_style
              .bottom_menu_selected_item_colour;

        currSettings.enable_haptic_on_touch =
          bottomApiData?.data?.bottom_menu_style &&
          (bottomApiData?.data?.bottom_menu_style?.enable_haptic_on_touch ===
            0 ||
            bottomApiData?.data?.bottom_menu_style?.enable_haptic_on_touch ===
              1)
            ? !!bottomApiData?.data?.bottom_menu_style?.enable_haptic_on_touch
            : false; // Default value is OFF for old apps where enable_haptic_on_touch key is not present

        currSettings.bottom_menu_show_icon = Boolean(
          bottomApiData.data.bottom_menu_style.bottom_menu_show_icon || 0
        );

        currSettings.bottom_menu_show_text = Boolean(
          bottomApiData.data.bottom_menu_style.bottom_menu_show_text ? 1 : 0
        );

        currSettings.bottom_menu_text_for_menu = bottomApiData.data
          .bottom_menu_style.bottom_menu_text_for_menu
          ? bottomApiData.data.bottom_menu_style.bottom_menu_text_for_menu
          : "More";

        currSettings.bottom_menu_icon_for_menu = bottomApiData.data
          .bottom_menu_style.bottom_menu_icon_for_menu
          ? bottomApiData.data.bottom_menu_style.bottom_menu_icon_for_menu
          : "";

        currSettings.bottom_menu_icon_colour_type_for_menu =
          bottomApiData.data.bottom_menu_style
            ?.bottom_menu_icon_colour_type_for_menu || "";

        currSettings.bottom_menu_show_grid_view =
          bottomApiData.data.bottom_menu_style.bottom_menu_show_grid_view || 0;

        currSettings.bottom_menu_grid_view_show_icon = Boolean(
          bottomApiData.data.bottom_menu_style
            .bottom_menu_grid_view_show_icon || 0
        );

        currSettings.bottom_menu_grid_view_show_text = Boolean(
          bottomApiData.data.bottom_menu_style
            .bottom_menu_grid_view_show_text || 0
        );

        currSettings.bottom_menu_grid_view_tile_shape = bottomApiData.data
          .bottom_menu_style.bottom_menu_grid_view_tile_shape
          ? bottomApiData.data.bottom_menu_style
              .bottom_menu_grid_view_tile_shape
          : "square";

        currSettings.bottom_menu_grid_view_column = bottomApiData.data
          .bottom_menu_style.bottom_menu_grid_view_column
          ? bottomApiData.data.bottom_menu_style.bottom_menu_grid_view_column
          : 2;

        currSettings.bottom_menu_grid_view_icon_color = bottomApiData.data
          .bottom_menu_style.bottom_menu_grid_view_icon_color_object
          ? bottomApiData.data.bottom_menu_style
              .bottom_menu_grid_view_icon_color_object?.portal_data
          : "rgba(255,255,255,1)";

        currSettings.bottom_menu_grid_view_icon_text_color = bottomApiData.data
          .bottom_menu_style.bottom_menu_grid_view_icon_text_color_object
          ? bottomApiData.data.bottom_menu_style
              .bottom_menu_grid_view_icon_text_color_object?.portal_data
          : "rgba(255,255,255,1)";

        currSettings.bottom_menu_grid_view_tile_background_color = bottomApiData
          .data.bottom_menu_style
          .bottom_menu_grid_view_tile_background_color_object
          ? bottomApiData.data.bottom_menu_style
              .bottom_menu_grid_view_tile_background_color_object?.portal_data
          : "rgba(255,255,255, 0.7)";

        currSettings.bottom_menu_grid_view_tile_border_color = bottomApiData
          .data.bottom_menu_style.bottom_menu_grid_view_tile_border_color_object
          ? bottomApiData.data.bottom_menu_style
              .bottom_menu_grid_view_tile_border_color_object?.portal_data
          : "rgba(60,153,254,1)";

        currSettings.bottom_menu_grid_view_selected_icon_color = bottomApiData
          .data.bottom_menu_style
          .bottom_menu_grid_view_selected_icon_color_object
          ? bottomApiData.data.bottom_menu_style
              .bottom_menu_grid_view_selected_icon_color_object?.portal_data
          : "rgba(187,209,255,1)";

        currSettings.bottom_menu_grid_view_selected_icon_text_color =
          bottomApiData.data.bottom_menu_style
            .bottom_menu_grid_view_selected_icon_text_color_object
            ? bottomApiData.data.bottom_menu_style
                .bottom_menu_grid_view_selected_icon_text_color_object
                ?.portal_data
            : "rgba(255,255,255,1)";

        currSettings.bottom_menu_grid_view_selected_tile_background_color =
          bottomApiData.data.bottom_menu_style
            .bottom_menu_grid_view_selected_tile_background_color_object
            ? bottomApiData.data.bottom_menu_style
                .bottom_menu_grid_view_selected_tile_background_color_object
                ?.portal_data
            : "rgba(255,255,255,0.7)";

        currSettings.bottom_menu_grid_view_selected_tile_border_color =
          bottomApiData.data.bottom_menu_style
            .bottom_menu_grid_view_selected_tile_border_color_object
            ? bottomApiData.data.bottom_menu_style
                .bottom_menu_grid_view_selected_tile_border_color_object
                ?.portal_data
            : "rgba(60,153,254,1)";

        currSettings.bottom_menu_grid_view_bg_color = bottomApiData.data
          .bottom_menu_style.bottom_menu_grid_view_bg_color_object
          ? bottomApiData.data.bottom_menu_style
              .bottom_menu_grid_view_bg_color_object?.portal_data
          : "linear-gradient(129deg, rgba(50, 167, 255,1) 0%, rgba(90, 111, 251,1) 50%, rgba(119, 87, 255,1) 100%)";

        currSettings.bottom_menu_grid_page_bg_color = bottomApiData.data
          .bottom_menu_style.bottom_menu_grid_page_bg_color_object
          ? bottomApiData.data.bottom_menu_style
              .bottom_menu_grid_page_bg_color_object?.portal_data
          : "#eeeeee";
        currObj.spinner = false;
        currObj.isMenuEnable = bottomApiData.enable_menu;
        currObj.storeUrl = bottomApiData.store_url;
        currObj.keys_data = bottomApiData.keys_data;
        currObj.is_chat_enabled = bottomApiData.is_chat_enabled;
        currObj.chat_data = bottomApiData.chat_data;
        currObj.updated_at = bottomApiData.updated_at
          ? bottomApiData.updated_at
          : "";
        currObj.is_any_default_item_in_menu =
          bottomApiData.is_any_default_item_in_menu;
      } else if (bottomApiData.code === 201) {
      }
    }
    currObj.settings = currSettings;
    setBottomState(currObj);
    processBottomItems(currObj.bottom_menu_items, true);
  };

  const handleHomeBtn = () => {
    setBottomState({ openBottomSetting: false });
  };

  const handleSaveMenu = (e) => {
    e.preventDefault();
    setBottomState({ loader: "new-loader" });

    let flag = 0;

    let rearrangeposition = bottomState.bottom_menu_items.map(
      (res, i) => (
        ((res.item_position = i),
        (res.slug = !res.slug ? "" : res.slug),
        (res.rest_base = !res.rest_base ? "" : res.rest_base),
        (res.post_type = !res.post_type ? "" : res.post_type),
        (res.invidual_selected_item_id = !res.invidual_selected_item_id
          ? ""
          : res.invidual_selected_item_id.toString()),
        (res.item_title = !res.item_title
          ? res.item_name.toString()
          : res.item_title.toString()),
        (res.item_type = res.item_type === "####" ? "" : res.item_type)),
        res
      )
    );

    rearrangeposition.map((row, i) => {
      let web_view_settings = {};
      if (row.item_type === "") {
        flag = flag + 1;
      }
      if (row.item_type === "web_view") {
        row["web_view_url"] =
          row["web_view_url"] && row["web_view_url"] != ""
            ? row["web_view_url"].replace(/^https?:\/\//i, "")
            : "";
        web_view_settings = {
          show_app_header: !row.web_view_settings
            ? 1
            : row.web_view_settings.show_app_header,
          show_web_view_footer: !row.web_view_settings
            ? 1
            : row.web_view_settings.show_web_view_footer,
          show_web_view_header: !row.web_view_settings
            ? 1
            : row.web_view_settings.show_web_view_header,
          hide_html_element_by_class: !row.web_view_settings
            ? ""
            : row.web_view_settings.hide_html_element_by_class,
          hide_html_element_by_id: !row.web_view_settings
            ? ""
            : row.web_view_settings.hide_html_element_by_id,
        };
        row["web_view_settings"] = web_view_settings;
      } else {
        delete row["web_view_settings"];
        delete row["web_view_url"];
      }
      delete row["app_header"];
      delete row["website_footer"];
      delete row["website_header"];
      delete row["hide_html_element_by_class"];
      delete row["hide_html_element_by_id"];
    });

    // return;
    setBottomState({ bottom_menu_items: rearrangeposition });

    const params = {
      app_id: parseInt(localStorage.getItem("Appid")),
      user_id: parseInt(localStorage.getItem("user_id")),
      bottom_menu_style: Object.fromEntries(
        Object.entries(bottomState.settings).map(([key, value]) => [
          key,
          typeof value === "boolean" ? +value : value,
        ])
      ),
      bottom_menu_items: rearrangeposition,
      enable_bottom_menu: bottomState.enable_bottom_menu ? 1 : 0,
      bottom_menu_status: 1,
      website_technology: bottomState.website_technology,
    };

    //Check item name is empty
    let validate_itemname = params.bottom_menu_items.filter(
      (res) => res.item_name.trim() === ""
    );
    //Check default selection
    let default_selection = params.bottom_menu_items.filter(
      (res) => res.is_default === 1 || res.is_default
    );
    //Check Web view type and web view URL
    let Web_view_url = params.bottom_menu_items.filter(
      (res) =>
        res.item_type === "web_view" &&
        (res.web_view_url === "" || res.web_view_url === undefined)
    );

    //url validation
    let recheck =
      /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    let Web_view_url_validation = params.bottom_menu_items.some(
      (res) => res.item_type === "web_view"
    );

    //check status is off and default selection is on
    let check_status = params.bottom_menu_items.some((res) => res.status === 1);
    //Check item name is same
    let valueArr = params.bottom_menu_items.map(function (item) {
      return item.item_name.toLowerCase();
    });

    let isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) !== idx;
    });
    let inValidUrl = false;
    let statusToggleOffCount = 0;
    params.bottom_menu_items &&
      params.bottom_menu_items.map((eachItem) => {
        if (eachItem.status === 1) {
          statusToggleOffCount++;
        }
        if (
          eachItem.item_type === "web_view" &&
          !recheck.test("https://" + eachItem.web_view_url)
        ) {
          inValidUrl = true;
        }
      });

    if (validate_itemname.length > 0) {
      errortoast("Item name cannot be empty");
      setBottomState({ loader: "" });
    } else if (
      !default_selection.length &&
      !bottomState.is_any_default_item_in_menu
    ) {
      setBottomState({
        openDefaultItemPopUp: !bottomState.openDefaultItemPopUp,
        loader: "",
      });
    } else if (statusToggleOffCount === 0 && enable_bottom_menu) {
      errortoast("At least one item is required.");
      setBottomState({ loader: "" });
    } else if (Web_view_url.length > 0) {
      errortoast("Web view URL cannot be empty.");
      setBottomState({ loader: "" });
    } else if (inValidUrl) {
      errortoast("Web view URL is invalid.");
      setBottomState({ loader: "" });
    } else {
      if (flag > 0) {
        errortoast("Link is required.");
        setBottomState({ loader: "" });
      } else {
        let new_bottom_menu_items = [];
        params.bottom_menu_items &&
          params.bottom_menu_items.map((eachItem) => {
            let newArr = {};
            newArr.id = eachItem.id;
            newArr.invidual_selected_item_id =
              eachItem.invidual_selected_item_id;
            newArr.isCms = eachItem.isCms;
            newArr.is_default = eachItem.is_default;
            newArr.item_icon = eachItem.item_icon;
            newArr.item_icon_flaticon_color = eachItem.item_icon_flaticon_color;
            newArr.item_icon_newtype = eachItem.item_icon_newtype;
            newArr.item_icon_type = eachItem.item_icon_type;
            newArr.item_name = eachItem.item_name;
            newArr.item_position = eachItem.item_position;
            newArr.item_title = eachItem.item_title;
            newArr.item_type = eachItem.item_type;
            newArr.post_type = eachItem.post_type;
            newArr.rest_base = eachItem.rest_base;
            newArr.slug = eachItem.slug;
            newArr.status = eachItem.status;
            newArr.web_view_url = eachItem.web_view_url;
            new_bottom_menu_items.push(newArr);
          });

        params.bottom_menu_items = new_bottom_menu_items;

        setBottomState({ loader: true });
        saveBottomPostApi(params);
      }
    }
  };

  const {
    updated_at,
    spinner,
    openBottomSetting,
    loader,
    enable_bottom_menu,
    linkArr,
    appRightPanelExpand,
  } = bottomState;

  const newRow = (e) => {
    const data = {
      id: bottomState.bottom_menu_items.length - 1,
      item_name: "Item",
      item_icon: "",
      item_icon_type: "new",
      item_icon_newtype: "flaticon",
      item_position: bottomState.bottom_menu_items.length,
      invidual_selected_item_id: "",
      isCms: 0,
      is_default: 0,
      item_icon_flaticon_color: "",
      item_title: "Item",
      item_type: "web_view",
      post_type: "",
      rest_base: "",
      slug: "",
      temp_rest_base: "",
      temp_slug: "",
      temp_post_type: "",
      status: 1,
      web_view_url: "youtube.com",
      temp_item_type: "web_view",
      module_label: "Web view",
      temp_module_label: "Web view",
      temp_invidual_selected_item_id: "",
      temp_subData: [],
      subData: [],
      temp_breadcrumbArr: [],
      breadcrumbArr: [],
      showL1Options: true,
      temp_showL1Options: true,
      subdropdownSpinner: false,
      subdropdownData: [],
    };
    e.preventDefault();
    let newArr = bottomState.bottom_menu_items;
    newArr.push(data);

    processBottomItems(newArr, false);
  };

  const processBottomItems = (Items, isInitialLoad = false) => {
    let previewBottom = [];
    let isRowOpen = [];

    // Loop through menu items to set `previewBottom` and `isRowOpen`
    for (let i = 0; i < Items.length; i++) {
      isRowOpen.push(false);
      if (Items[i].status === 1) {
        previewBottom.push(Items[i]);
      }
    }

    // Determine `setvalue` and `showexpand`
    let setValue = previewBottom.length > 5 ? 4 : previewBottom.length;
    let showExpand = previewBottom.length > 5;

    // Set state based on previewBottom length
    const newState = {
      bottom_menu_items: Items,
      setvalue: previewBottom.length === 5 ? 5 : setValue,
      pewview_bottom_item: previewBottom,
      showexpand: showExpand,
      isRowOpen: isRowOpen,
    };

    if (isInitialLoad) {
      delete newState.bottom_menu_items; // `initialLoad` doesn't modify `bottom_menu_items`
    }

    setBottomState(newState);
  };

  return (
    <>
      <Header customclass={"header-responsive-new"} />
      <section className="dasboard_page bottombar-module mobile-display">
        <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />

        <>
          <div
            className={`right-panel-gap ${
              appRightPanelExpand ? " right-panel-gap-expand" : ""
            }`}
          >
            <div className="menu-parentDiv">
              {isLargeScreen && (
                <ModuleTitleTooltip
                  title={"Navigation"}
                  text={
                    "Customize the navigation of your app to enhance user experience."
                  }
                />
              )}
              <h1 className="menuhead-formob">Bottom bar</h1>

              <div className="menu-builder-head ">
                <ModuleNavigations linkArr={linkArr} />
                <div className="menu-header-rightdiv">
                  {updated_at && (
                    <UpdatedAt
                      timestamp={updated_at}
                      format={"D MMMM YYYY, HH:mm"}
                    />
                  )}
                  {!openBottomSetting ? (
                    <></>
                  ) : (
                    <div
                      className="common-navigation-btn "
                      onClick={(e) => handleHomeBtn(e)}
                    >
                      <HomeIcon />
                    </div>
                  )}

                  {!spinner && (
                    <>
                      <div onClick={(e) => handleSettingBtn(e)}>
                        <SettingIcon
                          
                          isActive={openBottomSetting}
                        />
                      </div>
                      <div className="menu-web-save-btn-parent">
                        <SaveButton
                          handleBtnClick={(e) => handleSaveMenu(e)}
                          isBtnLoader={loader}
                          btnText={"Save"}
                          isDisable={false}
                          variant={"primary-submit-web-btn"}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="  ">
              <div className="bottombar-page">
                <div className="bottombar-width">
                  <>
                    {bottomState.spinner ? (
                      <div className="bottombarbg-spinner-div">
                        <AmsSpinner spinWidth={"200px"} spinHeight={"80vh"} />
                      </div>
                    ) : (
                      <div className="bottombarbg">
                        {!openBottomSetting ? (
                          <div className={"bottombar-left"}>
                            <div className="bottombar-master-toggle">
                              <h4 className="menuBuilder-enable-head">
                              Show bottom bar
                                <Tooltip
                                  message={
                                    "Turn on to activate the Bottom bar for easy app navigation"
                                  }
                                />
                              </h4>

                              <label
                                className=" common-toogle-switch "
                                htmlFor="bottombarenable"
                              >
                                <input
                                  type="checkbox"
                                  className="common-switch-input"
                                  id="bottombarenable"
                                  name="bottombarenable"
                                  checked={enable_bottom_menu}
                                  onChange={handleToggle}
                                />
                                <div className="switch-slider round"></div>
                              </label>
                            </div>
                            <BottomItemAccordian
                              bottomState={bottomState}
                              setBottomState={setBottomState}
                            />
                            <div
                              class="newbottomvar"
                              style={{
                                opacity: bottomState.enable_bottom_menu
                                  ? "1"
                                  : "0.5",
                                cursor: bottomState.enable_bottom_menu
                                  ? "pointer"
                                  : "not-allowed",
                              }}
                              onClick={
                                bottomState.enable_bottom_menu
                                  ? (e) => newRow(e)
                                  : null
                              }
                            >
                              <h4
                                className="add-bottom-bar-item"
                                style={{
                                  opacity: bottomState.enable_bottom_menu
                                    ? "1"
                                    : "0.5",
                                  cursor: bottomState.enable_bottom_menu
                                    ? "pointer"
                                    : "not-allowed",
                                }}
                              >
                                <img
                                  className="add-bottom-bar-item-icon"
                                  src={
                                    require("../../../assets/images/dashboard/cms/blue-plus.png")
                                      .default
                                  }
                                  alt="blue plus"
                                />
                                Add item
                              </h4>
                            </div>
                          </div>
                        ) : (
                          // <Suspense fallback={""}>
                          <BottomSetting
                            bottomState={bottomState}
                            setBottomState={setBottomState}
                          />
                          // </Suspense>
                        )}

                        <BottomPreview bottomState={bottomState} />
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>
            <div className="mob-bottombar-footer-div">
              {!spinner && (
                <div className="bottombar-mob-btn">
                  <SaveButton
                    handleBtnClick={(e) => handleSaveMenu(e)}
                    isBtnLoader={loader}
                    btnText={"Save"}
                    isDisable={false}
                    variant={"bottombar-footer-save-btn"}
                  />
                  {updated_at && (
                    <UpdatedAt
                      timestamp={updated_at}
                      format={"D MMMM YYYY, HH:mm"}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      </section>

      <DefaulItem
        open={bottomState.openDefaultItemPopUp}
        toggle={() => handleOpenDefaultItemPopUp()}
        title={DEFAULT_ITEM_POPUP.title}
        description={DEFAULT_ITEM_POPUP.description}
        submitbutton={DEFAULT_ITEM_POPUP.submitButton}
        onSubmit={() => handleOpenDefaultItemPopUp()}
      />

      <DefaulLandingScreen
        open={bottomState.openMainModuleValidationPopUp}
        toggle={() => handleOpenMainModuleValidationPopUp()}
        title={MAIN_MODULE_VALIDATION_POPUP.title}
        description={MAIN_MODULE_VALIDATION_POPUP.description}
        submitbutton={MAIN_MODULE_VALIDATION_POPUP.submitButton}
        onSubmit={() => handleOpenMainModuleValidationPopUp()}
      />
    </>
  );
};

export default BottomBar;
