import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiEndPoints from "../../../utils/apiEndpoints";

export const wordpressNotificationSlice = createApi({
  reducerPath: "wordpressNotificationData",
  baseQuery: fetchBaseQuery({
    baseUrl: apiEndPoints.redirectUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("Apiversion");
      headers.set("Content-Type", "application/json");
      headers.set("X-App-Device-Type", "ios");
      headers.set("X-App-Code-Ver", token);
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getBottomMenuTypes: builder.query({
      query: (storeUrl) =>
        apiEndPoints.redirectUrl +
        storeUrl.replace("www.", "") +
        "/wp-json/wp/v2/types?page=1%26per_page=99",
    }),

    getBottomMenuTaxonomy: builder.query({
      query: (storeUrl) =>
        apiEndPoints.redirectUrl +
        storeUrl.replace("www.", "") +
        "/wp-json/wp/v2/taxonomies?page=1%26per_page=99",
    }),
  }),
});

export const {
  useLazyGetBottomMenuTypesQuery,
  useLazyGetBottomMenuTaxonomyQuery,
} = wordpressNotificationSlice;
