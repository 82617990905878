import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormGroup, Label, Input } from "reactstrap";

import imagebackground from "./../../assets/images/dashboard/cms/uploadbackground.png";
import NewImageGalleryPopup from "./../NewImageGalleryPopup";
import VistaPopup from "../Appearance/VistaPopup";
import VideoPopUp from "../VideoPopUp";
import ReactPlayer from 'react-player'



export const VideoBlock = ({
  indx,
  cmsArray,
  setcmsData,
  isRowOpen,
  handleIsRowOpen,
  deleteRow,
  duplicateItem,
  eachBlock,

  provided,

}) => {
  const [isGalleryPopupForVideo, setIsGalleryPopupForVideo] = useState(false);
  const [isGalleryPopup, setIsGalleryPopup] = useState(false);
  const [uploadVideoWidth, setUploadVideoWidth] = useState(null);
  const [uploadVideoHeight, setUploadVideoHeight] = useState(null);


  const videoGalleryPopup = (e) => {         
   
    setIsGalleryPopupForVideo(!isGalleryPopupForVideo);

    // setprevImgUrl(newArray[indx].portal_value.source_file);
  };


  const imageGalleryPopup = (e) => {
   
    setIsGalleryPopup(!isGalleryPopup);

    // setprevImgUrl(newArray[indx].portal_value.source_file);
  };





  const settingYourdataForVideo = (filenameOrURL,uploadVideoHeight,uploadVideoWidth) => {

    let newArr = [...cmsArray];
    newArr[indx].portal_value.source_file =  filenameOrURL;
    newArr[indx].style.height =uploadVideoHeight? uploadVideoHeight.toString():"" ;
    newArr[indx].style.width =uploadVideoWidth? uploadVideoWidth.toString():"" ;
    setcmsData(newArr);
    setIsGalleryPopupForVideo(false);

  };

  const yourErrFun = () => {
    // let newArr = [...cmsArray];

    // newArr[indx].portal_value.source_file = prevImgUrl;

    // setcmsData(newArr);
  };


  const handleStatus = (e) =>{

    let cmsData = [...cmsArray];
    cmsData[indx].status = ( cmsData[indx].status===1 || cmsData[indx].status===undefined || cmsData[indx].status===null)
    ?0:1;
  
 
    setcmsData(cmsData);
  }

  const handleRemoveVideo = (e) =>{
    let cmsData = [...cmsArray];
    cmsData[indx].portal_value.source_file = "";
  
 
    setcmsData(cmsData);
  }




  

  const settingYourdataForImage =async (imgName, from, orgFileName, url1200, useUrl,uploadedFileBase64,croppedImageForCms) => {

    let newArr = [...cmsArray];
  
    if (from === "resize") {
 
      newArr[indx].portal_value.thumbnail =imgName;
   

    }

    setcmsData(newArr);
  };


    // Remove Image
    const handleCancelImage = (e) => {
      let newArray = [...cmsArray];
  
      newArray[indx].portal_value.thumbnail = "";

     
      setcmsData(newArray);
    };

    const handleVideoDimensionParentFn = (width,height) =>{
      setUploadVideoWidth(width);
      setUploadVideoHeight(height);
    }

  return (
    <div className={`accordionItem ${cmsArray[indx].status===0?"cms-section-hidden":""}`}>
      
      <h2
        class="accordionItemHeading allflex"
        draggableId={indx.toString()}
        {...provided.dragHandleProps}
      > Video
      

        <span className="cms-section-visiblity" onClick={(e)=>handleStatus(e)} >
         
          <svg xmlns="http://www.w3.org/2000/svg" width="25.284" height="20.465" viewBox="0 0 25.284 20.465">
        <g id="Group_39189" data-name="Group 39189" transform="translate(0.568 0.353)">
          <path id="Path_103079" data-name="Path 103079" d="M68.716,9.742a13.852,13.852,0,0,1-12.07,7.684A13.876,13.876,0,0,1,44.568,9.742,13.876,13.876,0,0,1,56.646,2.058,13.852,13.852,0,0,1,68.716,9.742Z" transform="translate(-44.568 0.138)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1"/>
          <ellipse id="Ellipse_8629" data-name="Ellipse 8629" cx="3.842" cy="3.842" rx="3.842" ry="3.842" transform="translate(8.233 6.038)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1"/>
          <line id="Line_430" data-name="Line 430" x2="19.758" y2="19.758" transform="translate(2.196 0.001)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1"/>
        </g>
      </svg>   
    </span>

    
      <p className="cms-accordian-p"
          onClick={(e) => {
            handleIsRowOpen(e, indx);
          }}
        >
          <img
            src={
              isRowOpen[indx] === true
                ? require("../../assets/images/dashboard/cms/accordian-open.png")
                    .default
                : require("../../assets/images/dashboard/cms/accordian-icon.png")
                    .default
            }
          />
        </p>
      </h2>

      {isRowOpen[indx] === true && (
        <div class="accordionItemContent">

          <label className="set-full-img">
            <h6>Video</h6>
            <div className="image-uploader">
              <div
                className="d-flex align-items-center"
                onClick={(e) => videoGalleryPopup(e)}
              >
                 { cmsArray[indx].portal_value.source_file ?
                 <div className="uploadpixabayimage">
          <ReactPlayer controls={false}  url={cmsArray[indx].portal_value.source_file.indexOf("http")>-1?
                    cmsArray[indx].portal_value.source_file:   process.env.REACT_APP_Image_Path + localStorage.getItem("user_id") +
                    "/" + localStorage.getItem("Appid") +process.env.REACT_APP_CMS_Media + cmsArray[indx].portal_value.source_file}  width="100%" height="100%"/>
          </div>
          :
               <div className="uploadpixabayimage" style={{backgroundImage : "url(" + imagebackground + ")"}}> </div> }
              {cmsArray[indx].portal_value.source_file ?
               <p>{cmsArray[indx].portal_value.source_file}</p>  :
               <p className={"cms-image-placeholder"}>Upload your video here</p> }
              </div>
   {
   cmsArray[indx].portal_value.source_file  && <svg onClick={(e)=>handleRemoveVideo(e)}
    width="11.06"
    height="11.06"
    viewBox="0 0 11.06 11.06">
    <path
      id="Union_1696"
      data-name="Union 1696"
      d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z"
      transform="translate(0.53 0.53)"
      fill="none"
      stroke="#7782a1"
      stroke-width="1.5"
    />
  </svg>
   }
              
              
            </div>
          </label>


          <label className="set-full-img mt-20">
            <h6>Thumbnail</h6>
            <div className="image-uploader">
              <div style={{
                cursor:cmsArray[indx].portal_value.source_file ? "":"not-allowed"
              }}
                className="d-flex align-items-center"
                onClick={(e) =>cmsArray[indx].portal_value.source_file ? imageGalleryPopup(e):null}
              >
               <div className="uploadpixabayimage"     style={{
                    backgroundImage:
                      "url(" +
                      (cmsArray[indx].portal_value.thumbnail !== undefined &&
                      cmsArray[indx].portal_value.thumbnail !== null &&
                      cmsArray[indx].portal_value.thumbnail !== ""
                        ? //  cmsArray[indx].portal_value.thumbnail ===
                          //     "rainbow-wall.jpg" ||
                          //   cmsArray[indx].portal_value.thumbnail ===
                          //     "floating-unicorn.jpg"
                          //   ? process.env.REACT_APP_Image_Path +
                          //     process.env.REACT_APP_DEFAULT_CMS_IMG +
                          //     cmsArray[indx].portal_value.thumbnail
                          //   :
                          cmsArray[indx].portal_value.thumbnail.indexOf("https")>-1?
                          cmsArray[indx].portal_value.thumbnail
                          :
                          process.env.REACT_APP_Image_Path +
                          localStorage.getItem("user_id") +
                          "/" +
                          localStorage.getItem("Appid") +
                          process.env.REACT_APP_CMS_Media +
                          cmsArray[indx].portal_value.thumbnail
                        : imagebackground) +
                      ")",
                  }}> </div>
               { cmsArray[indx].portal_value.thumbnail ? <p>{cmsArray[indx].portal_value.thumbnail}</p>
                :<p className="cms-image-placeholder">Upload your video thumbnail image here</p>
               } 
              </div>
{
   cmsArray[indx].portal_value.thumbnail &&

              <svg onClick={(e) => handleCancelImage(e)}
                    width="11.06"
                    height="11.06"
                    viewBox="0 0 11.06 11.06">
                    <path
                      id="Union_1696"
                      data-name="Union 1696"
                      d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z"
                      transform="translate(0.53 0.53)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="1.5"
                    />
                  </svg>  }
              
            </div>
          </label>

        

          <div className="duplicate-delete">
            <div className="cms-dup-del-btn"   onClick={(e) => duplicateItem(e, eachBlock)}>
            <span class="cms-delete-tooltip">
            <img
                src={
                  require("../../assets/images/dashboard/cms/duplicate.png")
                    .default
                }
              />
               <span class="cms-delete-tooltipsubheader">Duplicate</span>
                  </span>
            </div>

            <div className="cms-dup-del-btn"  
              onClick={(e) => deleteRow(e, indx)}
              // className={`${indx === 1 ? "d-none" : ""}`}
            >
              <span class="cms-delete-tooltip">
            <img
                src={
                  require("../../assets/images/dashboard/cms/delete.png")
                    .default
                }
              />
               <span class="cms-delete-tooltipsubheader">Delete</span>
                  </span>
            </div>
          </div>
        </div>
      )}
     
     <VideoPopUp
        isGalleryPopup={isGalleryPopupForVideo}
        setIsGalleryPopup={setIsGalleryPopupForVideo}
        settingYourdata={settingYourdataForVideo}
        yourErrFun={yourErrFun}
        uploadPath={
          localStorage.getItem("user_id") +
          "/" +
          localStorage.getItem("Appid") +
          process.env.REACT_APP_CMS_Media
        }
        handleVideoDimensionParentFn={handleVideoDimensionParentFn}
      />

       <NewImageGalleryPopup
        isGalleryPopup={isGalleryPopup}
        setIsGalleryPopup={setIsGalleryPopup}
        settingYourdata={settingYourdataForImage}
        yourErrFun={yourErrFun}
        uploadPath={
          localStorage.getItem("user_id") +
          "/" +
          localStorage.getItem("Appid") +
          process.env.REACT_APP_CMS_Media
        }
        settingOrgImgFrom3rdParty={false}
        hideImageGallery={true}
        widthForRestriction={uploadVideoWidth?uploadVideoWidth:600}
        heightForRestriction={uploadVideoHeight?uploadVideoHeight:400}
        aspectRatio={(uploadVideoHeight&&uploadVideoWidth)? `${uploadVideoWidth}:${uploadVideoHeight}` :"600:400"}
        isNotCustomUploadOrgImg={true}
        isCms={true}
        
        designid={""}
        loadpicVistaCreatePopUp={false}
        closeVistaCreatePopUp={false}
     
      />
    </div>
  );
};

export default VideoBlock;


