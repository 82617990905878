import React, { useState } from "react";
import imagebackground from "../../../assets/images/dashboard/cms/uploadbackground.png";
import IconSelectionPopUp from "../../../Dashboard/Reusable/IconSelectionPopUp";
import CrossIcon from "../../commonIcons/CrossIcon";
import Tooltip from "../../commonIcons/Tooltip";
const MenuIconField = ({ item, parentSaveIconFn, menuType }) => {
  const [openIconPopUp, setOpenIconPopUp] = useState(false);

  const handleIconPopUp = () => {
    setOpenIconPopUp(!openIconPopUp);
  };

  const saveIconName = (uploadedIconName, selectedColorType) => {
    setOpenIconPopUp(false);
    parentSaveIconFn(uploadedIconName, selectedColorType, item.id);
  };

  const removeIcon = (e) => {
    parentSaveIconFn("", "", item.id);
  };

  return (
    <div>
      <label className="menu-inputtype-label">Icon</label>
      <label className="menu-input-icon-label">
        <div className="menubuilder-icon-field d-flex align-items-center justify-content-between">
          <div
            className="d-flex align-items-center w-100"
            onClick={(e) => handleIconPopUp(e)}
          >
            <div className="">
              {!item.item_icon ? (
                <div
                  className="selectpixabayimage 1"
                  style={{
                    height: "36px",
                    width: "36px",
                    marginLeft: "8px",
                    marginRight: "10px",
                    background: "url(" + imagebackground + ")",
                  }}
                ></div>
              ) : item.item_icon.includes("cdn-icons-png.flaticon.com") ? (
                <div className="menu-Selected_icon">
                  <img
                    className="selectpixabayimage 1"
                    src={item.item_icon}
                    alt="home-item1"
                    style={{
                      height: "22px",
                      width: "22px",
                      minWidth: "22px",
                      // marginLeft: "15px"
                    }}
                  />
                </div>
              ) : item.item_icon_newtype === "flaticon" ? (
                <div className="menu-Selected_icon">
                  <img
                    className="selectpixabayimage 2"
                    src={
                      process.env.REACT_APP_Image_Path +
                      parseInt(localStorage.getItem("user_id")) +
                      "/" +
                      parseInt(localStorage.getItem("Appid")) +
                      process.env.REACT_APP_NEW_ICON_PATH +
                      item.item_icon
                    }
                    alt="home-item2"
                    style={{
                      height: "22px",
                      width: "22px",
                      minWidth: "22px",
                      // marginLeft: "15px"
                    }}
                  />
                </div>
              ) : menuType === "primary" ? (
                <div className="menu-Selected_icon">
                  <img
                    className="selectpixabayimage 3"
                    src={
                      process.env.REACT_APP_Image_Path +
                      (() => {
                        switch (item.item_icon_type) {
                          case "light":
                            return process.env.REACT_APP_light_path;
                          case "bold":
                            return process.env.REACT_APP_bold_path;
                          case "regular":
                            return process.env.REACT_APP_regular_path;
                          default:
                            return process.env.REACT_APP_light_path;
                        }
                      })() +
                      item.item_icon
                    }
                    alt="home-item3"
                    style={{
                      height: "22px",
                      width: "22px",
                      minWidth: "22px",
                      // marginLeft: "15px"
                    }}
                  />
                </div>
              ) : (
                <div className="menu-Selected_icon">
                  <img
                    className="selectpixabayimage 2"
                    src={
                      process.env.REACT_APP_Image_Path +
                      parseInt(localStorage.getItem("user_id")) +
                      "/" +
                      parseInt(localStorage.getItem("Appid")) +
                      process.env.REACT_APP_NEW_ICON_PATH +
                      item.item_icon
                    }
                    alt="home-item2"
                    style={{
                      height: "22px",
                      width: "22px",
                      minWidth: "22px",
                      // marginLeft: "15px"
                    }}
                  />
                </div>
              )}
            </div>
            <div
              style={{
                width: "calc(100% - 89px)",
              }}
            >
              <p
                className={" m-0 pr-3 save_img menu-img-name"}
                style={{
                  fontSize: "13px",
                  color: !item.item_icon ? "#BCC1CE" : "",
                }}
              >
                {!item.item_icon
                  ? "Upload"
                  : item.item_icon
                      .replace("%2Bxml", "")
                      .split("/")
                      .slice(-1)
                      .join(" ")}
              </p>
            </div>
          </div>
          {!item.item_icon ? (
            <></>
          ) : (
            <div>
              <CrossIcon handleCrossClick={(e) => removeIcon(e)} />
            </div>
          )}
        </div>
      </label>

      {openIconPopUp && (
        <IconSelectionPopUp
          handleIconPopUp={handleIconPopUp}
          saveIconName={saveIconName}
          openIconPopUp={openIconPopUp}
          widthForRestriction={128}
          heightForRestriction={128}
        />
      )}
    </div>
  );
};

export default MenuIconField;
