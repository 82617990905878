import React from "react";

const ImageDownloadIcon = () => {
  return (
    <div className="download-icon ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36.392"
        height="35.164"
        viewBox="0 0 36.392 35.164"
      >
        <g
          id="Group_36045"
          data-name="Group 36045"
          transform="translate(0.5 1)"
        >
          <g
            id="Group_36042"
            data-name="Group 36042"
            transform="translate(17.696)"
          >
            <g id="Group_36048" data-name="Group 36048">
              <line
                id="Line_368"
                data-name="Line 368"
                y1="24.566"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </g>
          </g>
          <path
            id="Path_99648"
            data-name="Path 99648"
            d="M3.5,6l9.846,9.826L23.153,6"
            transform="translate(4.37 9.231)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <g
            id="Group_36044"
            data-name="Group 36044"
            transform="translate(0.5 23.338)"
          >
            <g id="Group_36047" data-name="Group 36047">
              <path
                id="Path_99649"
                data-name="Path 99649"
                d="M34.892,10v9.826H.5V10"
                transform="translate(-0.5 -10)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default ImageDownloadIcon;
