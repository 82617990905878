import React, { useState } from "react";
import imagebackground from "../../../assets/images/dashboard/cms/uploadbackground.png";
import IconSelectionPopUp from "../../../Dashboard/Reusable/IconSelectionPopUp";
import CrossIcon from "../../commonIcons/BottomCrossIcon";

const BottomIconField = ({ item, parentSaveIconFn, indx }) => {
  const [openIconPopUp, setOpenIconPopUp] = useState(false);

  const handleIconPopUp = () => {
    setOpenIconPopUp(!openIconPopUp);
  };

  const saveIconName = (uploadedIconName, selectedColorType) => {
    setOpenIconPopUp(false);
    parentSaveIconFn(uploadedIconName, selectedColorType, indx);
  };

  const removeIcon = (e) => {
    parentSaveIconFn("", "", indx);
  };

  let iconPath = process.env.REACT_APP_light_path; // Default path

  if (item.item_icon_type === "bold") {
    iconPath = process.env.REACT_APP_bold_path;
  } else if (item.item_icon_type === "regular") {
    iconPath = process.env.REACT_APP_regular_path;
  } else if (item.item_icon_type === "new") {
    const userId = parseInt(localStorage.getItem("user_id"));
    const appId = parseInt(localStorage.getItem("Appid"));
    iconPath = `${userId}/${appId}${process.env.REACT_APP_NEW_ICON_PATH}`;
  }

  const backgroundImage = `url(${process.env.REACT_APP_Image_Path}${iconPath}${item.item_icon})`;

  return (
    <>
      <h6 className="bottombar-gap accordian-item-name">Icon</h6>
      <label className="set-full-img">
        <div className="image-uploader">
          <div
            className="d-flex align-items-center"
            onClick={(e) => handleIconPopUp(e)}
          >
            {item.item_icon && item.item_icon_newtype === "flaticon" ? (
              <div
                className={
                  item.item_icon
                    ? "uploadpixabayimage afterupload"
                    : "uploadpixabayimage"
                }
                style={{
                  backgroundImage: item.item_icon
                    ? "url(" +
                      process.env.REACT_APP_Image_Path +
                      localStorage.getItem("user_id") +
                      "/" +
                      localStorage.getItem("Appid") +
                      process.env.REACT_APP_NEW_ICON_PATH +
                      item.item_icon +
                      ")"
                    : "url(" + imagebackground + ")",
                }}
              ></div>
            ) : item.item_icon ? (
              <div
                className={
                  item.item_icon
                    ? "uploadpixabayimage afterupload"
                    : "uploadpixabayimage"
                }
                style={{ backgroundImage: backgroundImage }}
              ></div>
            ) : (
              <div
                className="uploadpixabayimage"
                style={{ backgroundImage: "url(" + imagebackground + ")" }}
              ></div>
            )}

            <p
              style={{
                color: item.item_icon ? "" : "#BCC1CE",
              }}
            >
              {item.item_icon
                ? item.item_icon.replace("%2Bxml", "")
                : "Select an icon"}
            </p>
          </div>
          {item.item_icon ? (
            <CrossIcon handleCrossClick={(e) => removeIcon(e)} />
          ) : (
            <></>
          )}
        </div>
      </label>

      {openIconPopUp && (
        <IconSelectionPopUp
          handleIconPopUp={handleIconPopUp}
          saveIconName={saveIconName}
          openIconPopUp={openIconPopUp}
          widthForRestriction={128}
          heightForRestriction={128}
        />
      )}
    </>
  );
};

export default BottomIconField;
