import React from "react";
import Header from "../Header";
import PricingDealList from "./PricingDeal-list";
import PricingMobileDealList from "./PricingMobile-Deal-list";
import { useParams } from "react-router-dom";
function PricingDealselection(props) {
  const { appid } = useParams();
document.title="Apply deal | AppMySite"
  const handleMyDeals = () => {
    window.location.href =  parseInt(localStorage.getItem('website_technology')) === 4 ? 
    "/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid'))
  : parseInt(localStorage.getItem('website_technology')) === 5 ? 
    "/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'))
  : parseInt(localStorage.getItem('website_technology')) === 3 ? 
    "/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'))
  : parseInt(localStorage.getItem('website_technology')) === 2 ? 
    "/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'))
  : "/app/pricing";
  };
  return (
    <>
        <div className='pricing-deal-body'>
    
        <Header customclass={"header-responsive-new"} />
    
        <div className='web-pricing-deals'>
            <PricingDealList btnCheck={1} appId={appid} props={props} />
        </div>
    
        <PricingMobileDealList btnCheck={1} appId={appid} props={props} />
    
    </div>
    </>
         
           
      );
    }

export default PricingDealselection;
