import React from "react";

const ChecklistWarning = () => {
  return (
    <svg
      id="Group_42776"
      data-name="Group 42776"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        id="Path_82474"
        data-name="Path 82474"
        d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z"
        fill="#ffb300"
      />
      <path
        id="Path_7077"
        data-name="Path 7077"
        d="M10991-4358.209v6"
        transform="translate(-10981 4363.209)"
        fill="none"
        stroke="#fff"
        stroke-width="2"
      />
      <path
        id="Path_7078"
        data-name="Path 7078"
        d="M10991-4347.192v2"
        transform="translate(-10981 4360.192)"
        fill="none"
        stroke="#fff"
        stroke-width="2"
      />
    </svg>
  );
};

export default ChecklistWarning;
