export const ADDONCARDDATA = {
  USER_MANAGEMENT: {
    src: require("../../../src/assets/images/dashboard/addon/addonsusers.png")
      .default,
    name: "Team",
    desc: "Collaborate effortlessly with additional team members, colleagues and clients. ",
    strip_color: "#47C2FF",
    link: "https://www.appmysite.com/user-management/",
  },
  WHITE_LABEL: {
    src: require("../../../src/assets/images/dashboard/addon/addonswhitelabel.png")
      .default,
    name: "Remove AppMySite branding",
    desc: "Remove our watermark and logo from your app and rebrand it as your own. ",
    strip_color: "#FFC834",
    link: "",
  },
  UPLOAD_TO_APP_STORE: {
    src: require("../../../src/assets/images/dashboard/addon/uploadappstore.png")
      .default,
    name: "Upload to App stores",
    desc: "Get Google play and Apple app store specialists to help you publish your app. ",
    strip_color: "#FF5858",
    link: "https://www.appmysite.com/upload-to-app-stores/",
  },
  LANGUAGE_SUPPORT: {
    src: require("../../../src/assets/images/dashboard/addon/addonlanguage.png")
      .default,
    name: "Multilingual",
    desc: "Allow your app users to easily choose the language of their choice. ",
    strip_color: "#83D298",
    link: "https://www.appmysite.com/language-support/",
  },
  MOBILE_APP_DESIGN: {
    src: require("../../../src/assets/images/dashboard/addon/mobappdesign.png")
      .default,
    name: "Mobile app design",
    desc: "Get professionally created design elements to make your app stand out. ",
    strip_color: "#83D298",
    link: "https://www.appmysite.com/mobile-app-design/",
  },
  CONSENT_FORM: {
    src: require("../../../src/assets/images/dashboard/addon/addonsconsent.png")
      .default,
    name: "Consent",
    desc: "Prompt users with a consent message to control access to your app’s content. ",
    strip_color: "#FFC834",
    link: "",
  },
  APP_STORE_DESIGN: {
    src: require("../../../src/assets/images/dashboard/addon/addonsappstoredesign.png")
      .default,
    name: "App store artwork",
    desc: "Get a designer to create app store artwork that resonates with your audience. ",
    strip_color: "#FC8855",
    link: "https://www.appmysite.com/app-store-design/",
  },
  MULTISITE_SUPPORT: {
    src: require("../../../src/assets/images/dashboard/addon/addmultisite.png")
      .default,
    name: "Merge",
    desc: "Publish one consolidated mobile app by combining multiple apps into one. ",
    strip_color: "#FF5858",
    link: "https://www.appmysite.com/merge-apps/",
  },
  ANALYTICS: {
    src: require("../../../src/assets/images/dashboard/addon/addAnalytics.png")
      .default,
    name: "Analytics",
    desc: "Get content insights into user behavior that help you make informed decisions. ",
    strip_color: "#7297FF",
    link: "https://www.appmysite.com/analytics/",
  },
  PUBLISHING: {
    src: require("../../../src/assets/images/dashboard/addon/autopublish.png")
      .default,
    name: "Easy publish",
    desc: "Upload and deploy your app to the app store directly from within your account. ",
    strip_color: "#FFC834",
    link: "https://www.appmysite.com/publish/",
  },
  CHAT_MODULE: {
    src: require("../../../src/assets/images/dashboard/addon/addcustomerchat.png")
      .default,
    name: "Chat",
    desc: "Enable the chat feature in your app and address customer concerns promptly. ",
    strip_color: "#FC8855",
    link: "https://www.appmysite.com/chat/",
  },
  BUILD_AND_PUBLISH_APP: {
    src: require("../../../src/assets/images/dashboard/addon/build&publish.png")
      .default,
    name: "Build and Upload to App store",
    desc: "Let our experts handle everything from app creation to deployment to the app stores. ",
    strip_color: "#47C2FF",
    link: "",
  },
  IN_APP_PURCHASE: {
    src: require("../../../src/assets/images/dashboard/addon/Inapppurchaseaddon.png")
      .default,
    name: "In-App Purchase",
    desc: "Monetize your app by offering subscriptions, in-game currencies, or premium content  and collect payments without a payment gateway. ",
    strip_color: "#FC8855",
    link: "",
  },
  RICH_NOTIFICATIONS: {
    src: require("../../../src/assets/images/dashboard/addon/RichNotificationaddon.png")
      .default,
    name: "Advanced notifications",
    desc: "Deliver targeted messaging to your app users, with rich media based notifications. ",
    strip_color: "#83D298",
    link: "",
  },
  DEDICATED_SERVER: {
    src: require("../../../src/assets/images/dashboard/addon/dedicated-server-addon.svg")
      .default,
    name: "Dedicated server",
    desc: "Boost app performance with a dedicated server near your customers, ensuring faster speed and reduced latency. ",
    strip_color: "#83D298",
    link: "",
  },
};
