import moment from "moment";
import React from "react";

const TechnologyCard = ({ technologyData }) => {

  const returnTechnologyImg = () => {
    switch (parseInt(technologyData.website_technology)) {
      case 2:
        return "webtoapp-dashboard.png";
      case 3:
        return "customapp-dashboard.png";
      case 4:
        return "wordpress-dashboard.png";
      case 5:
        return "woocomerce-dashboard.png";
      default:
        return "webtoapp-dashboard.png";
    }
  };

  return (
    <div className="dashboard-app-info-two">
      <div>
          <img
            src={
              require(`../../assets/images/dashboard/${returnTechnologyImg()}`)
                .default
            }
            className="img-fluid"
            alt={returnTechnologyImg()}
          />
      </div>

      <div>
        <h2 className="appName">
          {parseInt(technologyData.website_technology) === 4 ? (
            "Wordpress app"
          ) : parseInt(technologyData.website_technology) === 5 ? (
            "Woocommerce app"
          ) : parseInt(technologyData.website_technology) === 2 ? (
            "Web to app"
          ) : parseInt(technologyData.website_technology) === 3 ? (
            "Custom app"
          ) : (
            <></>
          )}
        </h2>
        <p className="appCreatedDate">
          {" "}
          {moment(technologyData.app_created_date).format(
            "D MMMM YYYY, h:mm a"
          )}{" "}
        </p>
      </div>
    </div>
  );
};

export default TechnologyCard;
