import React from "react";

import Header from "../Header";
import Leftpanel from "../Leftpanel";
import { Link } from "react-router-dom";
// import InputColor from 'react-input-color';
import { MobileApppreview } from "../Apppreview";
import Reactcolorpicker from "./NewReactcolorpicker";
import { Styleapi, GetStyleapi } from "../../Api/Dashboard/DashboardApi";
import { Spinner, Media } from "reactstrap";
import { errortoast } from "../Toaster";
import Tooltip from "../../Dashboard/Tooltip";
import { RemoveApppreview } from "./RemoveApppreview";
import Amsvideo from "../Reusable/Amsvideos";
import { InnerFoot } from "../../Dashboard/Foot";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import RebuildRequired from "./RebuildRequired";
//import Verifyemailstatus from '../../Verification/Checkverification';
export default class Style extends React.PureComponent {
  state = {
    spinner: true,
    buttoncolor: "#3064f9",
    initialbuttoncolor: "#3064f9",
    buttontextcolor: "#ffffff",
    initialbuttontextcolor: "#ffffff",
    submitval: "Save",
    loader: "d-none",
    loaderimage: require("../../assets/images/signup/Loader_GIF.gif").default,
    appFontFamily: "Poppins-SemiBold",
    initialprimarycolor: "#3064f9",
    initialsecondaryColor: "#ffffff",
    primaryColor: "#3064f9",
    secondaryColor: "",
    notch: require("../../assets/images/dashboard/speaker.png").default,
    holloimage: require("../../assets/images/dashboard/phoneback.png").default,
    hollowactive: true,
    rebuildpopvalue:false,
    //hit: false,
    website_technology: parseInt(localStorage.getItem("website_technology")),
    presetColors: null,
    android_build_version:null,
    android_supported_version:null,
    ios_build_version:null,
    ios_supported_version:null,
    appRightPanelExpand: localStorage.getItem("appSideBarCollapse") === true ||
    localStorage.getItem("appSideBarCollapse") === "true"
      ? true
      : false,
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
      {
        document.title = "Theme | AppMySite";
  
      }
      else
      {
        document.title = "Theme |"+ ' ' +localStorage.getItem('agencyname');
      }
    this.setState({
      submitval:
        localStorage.getItem("style_icon_count") === "1" ? "Save" : "Save",
    });
    GetStyleapi(this);
  }
  switchmobile = (e, data) => {
    e.preventDefault();
    if (data === "android") {
      this.setState({
        holloimage: require("../../assets/images/dashboard/androidapps.png")
          .default,
        notch: require("../../assets/images/dashboard/androidnotch.png")
          .default,
        hollowactive: false,
      });
    } else {
      this.setState({
        holloimage: require("../../assets/images/dashboard/phoneback.png")
          .default,
        notch: require("../../assets/images/dashboard/speaker.png").default,
        hollowactive: true,
      });
    }
  };
  //Button Color
  handlebuttoncolor = (e) => {
    this.setState({
      buttoncolor: e,
    });
  };
  //Button text color
  handlebuttontextcolor = (e) => {
    this.setState({
      buttontextcolor: e,
    });
  };

  closeRequireBuildPopup = (e) =>
  {

    this.setState({rebuildpopvalue:!this.state.rebuildpopvalue});
  }

  getappcolor = (str) => {
    if (str !== undefined) {
      str = str.trim();

      str = str.substring(str.indexOf("(") + 1, str.lastIndexOf(")"));

      str.split(/,(?![^(]*\))(?![^"']*["'](?:[^"']*["'][^"']*["'])*[^"']*$)/);

      str = str.replace(/, /g, ",");

      str = str.replace(/RGBA/g, "rgba");

      str = str.split("%,");
    }
    let str1 = [];

    return (str1 = str);
  };

  checkVersion(a, b) {
    const x = a.split('.').map(e => parseInt(e, 10));
    const y = b.split('.').map(e => parseInt(e, 10));

    var a1 = '';
    var b1 = '';
    for (const i in x) 
    {
        if(i == 0)
        {
          a1 = x[i] ;
          b1 = y[i] ;
        }
        else
        {
          a1 = a1 + '.'+ x[i] ;
          b1 = b1 + '.'+ y[i] ;
        }

    }
    return a1 < b1 ? 1 : 0;
}

  handleformsubmit = (e) => 
  {
    e.preventDefault();
    if(this.state.android_build_version != null || this.state.ios_build_version != null)
    {

      if(this.state.android_build_version != null &&  this.state.android_supported_version != null)
      {
        
        if(this.checkVersion(this.state.android_build_version,this.state.android_supported_version))
        {
          this.setState({rebuildpopvalue:!this.state.rebuildpopvalue});
          return ;
        }
      }
      else if(this.state.ios_build_version != null &&  this.state.ios_supported_version != null)
      {

        if(this.checkVersion(this.state.ios_build_version,this.state.ios_supported_version))
        {
          this.setState({rebuildpopvalue:!this.state.rebuildpopvalue});
          return ;
        }

      }

    } 
    
    const params = {
      app_id: localStorage.getItem("Appid"),
      button_color: this.state.buttoncolor,
      button_text_color: this.state.buttontextcolor,
      header_primary_color: this.state.primaryColor,
      header_secondary_color: this.state.secondaryColor,
      header_font_name:
        this.state.appFontFamily.indexOf("hitmo") > -1
          ? this.state.appFontFamily.slice(0, 6) +
            "." +
            this.state.appFontFamily.slice(6)
          : this.state.appFontFamily,
      website_technology: this.state.website_technology,
      // app_button_color: this.getappcolor(this.state.buttoncolor),
      // app_button_text_color: this.state.buttontextcolor,
      // app_header_primary_color: this.getappcolor(this.state.primaryColor),
      // app_header_secondary_color: this.state.secondaryColor,
    };
    // if (/^#([0-9A-F]{3}){1,2}$/i.test(params.header_primary_color) === false) {
    //   errortoast("Enter a valid primary color code.");
    // } else if (
    //   /^#([0-9A-F]{3}){1,2}$/i.test(params.header_secondary_color) === false
    // ) {
    //   errortoast("Enter a valid text color code.");
    // } else if (/^#([0-9A-F]{3}){1,2}$/i.test(params.button_color) === false) {
    //   errortoast("Enter a valid button  color code.");
    // } else if (
    //   /^#([0-9A-F]{3}){1,2}$/i.test(params.button_text_color) === false
    // ) {
    //   errortoast("Enter a valid button text color code.");
    // } else {
    Styleapi(params, this);
    // }
  };

  submitfunction = (e) =>
  {
    this.setState({loader:""});
    this.setState({rebuildpopvalue:!this.state.rebuildpopvalue});

    const params = {
      app_id: localStorage.getItem("Appid"),
      button_color: this.state.buttoncolor,
      button_text_color: this.state.buttontextcolor,
      header_primary_color: this.state.primaryColor,
      header_secondary_color: this.state.secondaryColor,
      header_font_name:
        this.state.appFontFamily.indexOf("hitmo") > -1
          ? this.state.appFontFamily.slice(0, 6) +
            "." +
            this.state.appFontFamily.slice(6)
          : this.state.appFontFamily,
      website_technology: this.state.website_technology,
      // app_button_color: this.getappcolor(this.state.buttoncolor),
      // app_button_text_color: this.state.buttontextcolor,
      // app_header_primary_color: this.getappcolor(this.state.primaryColor),
      // app_header_secondary_color: this.state.secondaryColor,
    };

    Styleapi(params, this);
  }

  //AppFontFamily Function
  handleappFontFamily = (e) => {
    this.setState({
      appFontFamily: e,
    });
  };
  //PrimaryColor Function
  handleprimaryColor = (e) => {
    this.setState({
      primaryColor: e,
    });
  };
  //SecondaryColor Function
  handlesecondaryColor = (e) => {
    this.setState({
      secondaryColor: e,
    });
  };

  handleRightPanelExpand = (value) => {

    this.setState({
      appRightPanelExpand: value
    })
    }

  render() {
    return (
      <>
        <div className="d-md-block">
          <Header customclass={"header-responsive-new"} value={this} />
        </div>
        {/* Mobile View */}
        {/* Mobile View End*/}
        <section className="dasboard_page dasboard_page_style mobile-display">
        <Leftpanel handleRightPanelExpand={this.handleRightPanelExpand} />
          {/* <div className="transparent_background transparent_background_style"> */}
          <div className={`right-panel-gap ${(localStorage.getItem("appSideBarCollapse") !== undefined &&
                      localStorage.getItem("appSideBarCollapse") !== null&&
                      localStorage.getItem("appSideBarCollapse") === "true") ?
                          " right-panel-gap-expand": ""}`}>
            <div className="right_panel pb-4">
              {this.state.spinner ? (
                <div className="newspinner">
                <svg xmlns="http://www.w3.org/2000/svg" 
          xlink="http://www.w3.org/1999/xlink" 
          style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
          width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
            style={{animationplaystate: "running", animationdelay: "0s"}}>
            </animateTransform>
          </circle>
          </g>
          <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
          style={{animationplaystate: "running", animationdelay: "0s"}}>
          </animateTransform>
          </circle>
          </g>
          <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
          style={{animationplaystate: "running", animationdelay: "0s"}}>
  
          </animateTransform>
          </circle>
          </g>
          <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
          style={{animationplaystate: "running", animationdelay: "0s"}}>
  
          </animateTransform>
          </circle>
          </g>
          </svg>
              </div>
              ) : (
                <></>
              )}
              <div
                className="right_general"
                style={{ opacity: this.state.spinner === true ? 0 : 1 }}
              >
                <div className="d-md-flex align-items-center justify-content-between ipad-margin-top top-portion ipad-portion-appearance ipad-special-appearance">
                  <div className="d-none d-md-block">
                    <div className="app_iconname">
                      <div className="d-flex align-items-center">
                        <h3>Color Theme</h3>
                        {/* <Amsvideo
                          timespan={88}
                          addclass="d-md-flex d-none align-items-center justify-content-between see_video"
                        /> */}
                      </div>
                      <h5>
                        Style your mobile app layout.{" "}
                        {/* <Media
                          tag="a"
                          href="https://www.appmysite.com/support/design-your-apps-color-theme/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Learn More{" "}
                          <img
                            src={
                              require("../../assets/images/dashboard/Goto.png")
                                .default
                            }
                            className="gotoappicon appear-spacing"
                            alt="Goto"
                          />
                        </Media> */}
                      </h5>
                    </div>
                  </div>
                  <form onSubmit={this.handleformsubmit}>
                    <div className="d-flex align-items-center justify-content-between fixed-button">
                      <div className="w-100 custom-100button">
                        <MobileApppreview />
                      </div>
                      <button
                        className={"next_btn w-100"}
                        disabled={this.state.disabledsubmitbutton}
                        style={{backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),
                        "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                        "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
                        "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                      >
                        <div className="d-flex align-items-center justify-content-center h-100 w-100">
                          <div className={this.state.loader}>
                            <img src={this.state.loaderimage} alt="loader" />
                          </div>{" "}
                          <div>{this.state.submitval}</div>
                        </div>
                      </button>
                    </div>
                  </form>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="customiseappfeatures">
                    
                    <form onSubmit={this.handleformsubmit}>
                      <div className="mt-0 ipad">
                        <div className="d-flex  align-items-center justify-content-between pt-2 mb-2 ipad-custom-padding p-md-0 ps-3 style-mobile-padding">
                          {/* Tool tip */}
                          <Tooltip
                            firsttag={"h5"}
                            tagclass={"mb-0 mr-2"}
                            classdata={
                              "module_title d-flex justify-content-start align-items-center"
                            }
                            heading={"Top header"}
                            content={"Select style and colors for app header."}
                          />
                          {/* Tool tip */}
                        </div>
                        <div>
                          <div>
                            <div className="upload_files d-flex align-items-center justify-content-between flex-wrap ipad-login-padding">
                              <div className="color_picker mb-4">
                                {/* Tool tip */}
                                <Tooltip
                                  firsttag={"h4"}
                                  tagclass={"mb-0 mr-2"}
                                  classdata={
                                    "d-flex justify-content-start align-items-center mb-1"
                                  }
                                  heading={"Primary color"}
                                  content={
                                    "Select primary color for app header."
                                  }
                                />
                                {/* Tool tip */}
                                <div className="form-group old-themecolorPicker">
                                  {/* <div className="color_input">
									                     	 <div className="backcheck">
										                 	 <div style={{backgroundColor:this.state.primaryColor}} className="backcolorview"></div>
										                	  <p className="m-0 p-0">{this.state.primaryColor}</p>
										                     </div>
										                      <InputColor initialHexColor={this.state.initialprimarycolor} onChange={this.handleprimaryColor} className="gettingcolors w-100" />
									                        </div> */}
                                  <Reactcolorpicker
                                    colorname={this.state.primaryColor}
                                    presetColors={this.state.presetColors}
                                    onChange={this.handleprimaryColor}
                                    classselection={"accessfixback"}
                                    valuecontrol={false}
                                  />
                                </div>
                              </div>

                              <div className="color_picker mb-4">
                                {/* Tool tip */}
                                <Tooltip
                                  firsttag={"h4"}
                                  tagclass={"mb-0 mr-2"}
                                  classdata={
                                    "d-flex justify-content-start align-items-center mb-1"
                                  }
                                  heading={"Text color"}
                                  content={
                                    "Select secondary color for app header."
                                  }
                                />
                                {/* Tool tip */}
                                <div className="form-group old-themecolorPicker">
                                  {/* <div className="color_input">
									                     	 <div className="backcheck">
										                 	 <div style={{backgroundColor:this.state.secondaryColor}} className="backcolorview"></div>
										                	  <p className="m-0 p-0">{this.state.secondaryColor}</p>
										                     </div>
										                      <InputColor initialHexColor={this.state.initialsecondaryColor} onChange={this.handlesecondaryColor} className="gettingcolors w-100" />
									                        </div> */}
                                  <Reactcolorpicker
                                    colorname={this.state.secondaryColor}
                                    presetColors={this.state.presetColors}
                                    onChange={this.handlesecondaryColor}
                                    classselection={"accessfixback"}
                                    valuecontrol={true}
                                  />
                                </div>
                              </div>

                              <div className="color_picker ipad-margin-top">
                                {/* Tool tip */}
                                <Tooltip
                                  firsttag={"h4"}
                                  tagclass={"mb-0 mr-2"}
                                  classdata={
                                    "d-flex justify-content-start align-items-center mb-1"
                                  }
                                  heading={"Font"}
                                  content={"Select font for app header."}
                                />
                                {/* Tool tip */}
                                <div className="form-group">
                                  <DropdownButton
                                    className="font-dropdown"
                                    alignRight
                                    title={this.state.appFontFamily}
                                    id="dropdown-menu-align-right"
                                    style={{
                                      fontFamily: this.state.appFontFamily,
                                    }}
                                    onSelect={(e) =>
                                      this.handleappFontFamily(e)
                                    }
                                    value={this.state.appFontFamily}
                                  >
                                    <Dropdown.Item
                                      style={{
                                        fontFamily: "Arial",
                                        background:
                                          this.state.appFontFamily == "Arial"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily == "Arial"
                                            ? "white"
                                            : "",
                                      }}
                                      eventKey="Arial"
                                    >
                                      Arial
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      style={{
                                        fontFamily: "Arial-Black",
                                        background:
                                          this.state.appFontFamily ==
                                          "Arial-Black"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "Arial-Black"
                                            ? "white"
                                            : "",
                                      }}
                                      eventKey="Arial-Black"
                                    >
                                      Arial Black
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      style={{
                                        fontFamily: "bainsley-regular",
                                        background:
                                          this.state.appFontFamily ==
                                          "bainsley-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "bainsley-regular"
                                            ? "white"
                                            : "",
                                      }}
                                      eventKey="bainsley-regular"
                                    >
                                      Bainsley-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      style={{
                                        fontFamily: "bainsley-bold",
                                        background:
                                          this.state.appFontFamily ==
                                          "bainsley-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "bainsley-bold"
                                            ? "white"
                                            : "",
                                      }}
                                      eventKey="bainsley-bold"
                                    >
                                      Bainsley Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      style={{
                                        fontFamily: "barracuda-regular",
                                        background:
                                          this.state.appFontFamily ==
                                          "barracuda-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "barracuda-regular"
                                            ? "white"
                                            : "",
                                      }}
                                      eventKey="barracuda-regular"
                                    >
                                      Barracuda Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      style={{
                                        fontFamily: "barracuda-bold",
                                        background:
                                          this.state.appFontFamily ==
                                          "barracuda-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "barracuda-bold"
                                            ? "white"
                                            : "",
                                      }}
                                      eventKey="barracuda-bold"
                                    >
                                      Barracuda Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="betelgeuse-regular"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "betelgeuse-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "betelgeuse-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "betelgeuse-regular",
                                      }}
                                    >
                                      {" "}
                                      Betelgeuse-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="betelgeuse-bold"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "betelgeuse-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "betelgeuse-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "betelgeuse-bold",
                                      }}
                                    >
                                      {" "}
                                      Betelgeuse-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="Bookman"
                                      style={{
                                        background:
                                          this.state.appFontFamily == "Bookman"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily == "Bookman"
                                            ? "white"
                                            : "",
                                        fontFamily: "Bookman",
                                      }}
                                    >
                                      {" "}
                                      Bookman
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="cactron-regular"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "cactron-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "cactron-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "cactron-regular",
                                      }}
                                    >
                                      {" "}
                                      Cactron-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="cactronbold"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "cactronbold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "cactronbold"
                                            ? "white"
                                            : "",
                                        fontFamily: "cactronbold",
                                      }}
                                    >
                                      {" "}
                                      Cactron-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="cokanut-regular"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "cokanut-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "cokanut-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "cokanut-regular",
                                      }}
                                    >
                                      {" "}
                                      Cokanut-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="cokanut-bold"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "cokanut-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "cokanut-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "cokanut-bold",
                                      }}
                                    >
                                      {" "}
                                      Cokanut-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="ComicSansMS"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "ComicSansMS"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "ComicSansMS"
                                            ? "white"
                                            : "",
                                        fontFamily: "ComicSansMS",
                                      }}
                                    >
                                      {" "}
                                      Comic Sans MS
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="cormorant-regular"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "cormorant-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "cormorant-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "cormorant-regular",
                                      }}
                                    >
                                      {" "}
                                      Cormorant-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="cormorant-bold"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "cormorant-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "cormorant-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "cormorant-bold",
                                      }}
                                    >
                                      {" "}
                                      Cormorant-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="Courier-New"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "Courier-New"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "Courier-New"
                                            ? "white"
                                            : "",
                                        fontFamily: "Courier-New",
                                      }}
                                    >
                                      {" "}
                                      Courier New
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="dukascf-regular"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "dukascf-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "dukascf-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "dukascf-regular",
                                      }}
                                    >
                                      {" "}
                                      Dukas Cf-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="dukascf-bold"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "dukascf-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "dukascf-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "dukascf-bold",
                                      }}
                                    >
                                      {" "}
                                      Dukas Cf-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="emberly-regular"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "emberly-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "emberly-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "emberly-regular",
                                      }}
                                    >
                                      {" "}
                                      Emberly-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="emberly-bold"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "emberly-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "emberly-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "emberly-bold",
                                      }}
                                    >
                                      {" "}
                                      Emberly-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="epigrafica-regular"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "epigrafica-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "epigrafica-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "epigrafica-regular",
                                      }}
                                    >
                                      {" "}
                                      Epigrafica-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="epigrafica-bold"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "epigrafica-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "epigrafica-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "epigrafica-bold",
                                      }}
                                    >
                                      {" "}
                                      Epigrafica-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="Garamond"
                                      style={{
                                        background:
                                          this.state.appFontFamily == "Garamond"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily == "Garamond"
                                            ? "white"
                                            : "",
                                        fontFamily: "Garamond",
                                      }}
                                    >
                                      {" "}
                                      Garamond
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="Georgia"
                                      style={{
                                        background:
                                          this.state.appFontFamily == "Georgia"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily == "Georgia"
                                            ? "white"
                                            : "",
                                        fontFamily: "Georgia",
                                      }}
                                    >
                                      {" "}
                                      Georgia
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="grenze-regular"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "grenze-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "grenze-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "grenze-regular",
                                      }}
                                    >
                                      {" "}
                                      Grenze-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="grenze-bold"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "grenze-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "grenze-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "grenze-bold",
                                      }}
                                    >
                                      {" "}
                                      Grenze-Bold
                                    </Dropdown.Item>
                                    {/* <Dropdown.Item
                                      eventKey="hitmo20-regular"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "hitmo20-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "hitmo20-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "hitmo20-regular",
                                      }}
                                    >
                                      {" "}
                                      Hitmo-Regular
                                    </Dropdown.Item> */}

                                    {/* <Dropdown.Item
                                      eventKey="hitmo20-bold"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "hitmo20-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "hitmo20-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "hitmo20-bold",
                                      }}
                                    >
                                      Hitmo-Bold
                                    </Dropdown.Item> */}
                                    <Dropdown.Item
                                      eventKey="horizon-regular"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "horizon-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "horizon-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "horizon-regular",
                                      }}
                                    >
                                      {" "}
                                      Horizon Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="horizon-bold"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "horizon-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "horizon-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "horizon-bold",
                                      }}
                                    >
                                      {" "}
                                      Horizon Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="Impact"
                                      style={{
                                        background:
                                          this.state.appFontFamily == "Impact"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily == "Impact"
                                            ? "white"
                                            : "",
                                        fontFamily: "Impact",
                                      }}
                                    >
                                      {" "}
                                      Impact
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="karantina-regular"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "karantina-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "karantina-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "karantina-regular",
                                      }}
                                    >
                                      {" "}
                                      Karantina-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="karantina-bold"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "karantina-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "karantina-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "karantina-bold",
                                      }}
                                    >
                                      {" "}
                                      Karantina-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="kubanpro-regular"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "kubanpro-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "kubanpro-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "kubanpro-regular",
                                      }}
                                    >
                                      {" "}
                                      Kuban Pro Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="kubanpro-bold"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "kubanpro-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "kubanpro-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "kubanpro-bold",
                                      }}
                                    >
                                      {" "}
                                      Kuban Pro Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="kufam-regular"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "kufam-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "kufam-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "kufam-regular",
                                      }}
                                    >
                                      {" "}
                                      Kufam-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="kufam-bold"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "kufam-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "kufam-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "kufam-bold",
                                      }}
                                    >
                                      {" "}
                                      Kufam-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="mangoGrotesque-regular"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "mangoGrotesque-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "mangoGrotesque-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "mangoGrotesque-regular",
                                      }}
                                    >
                                      {" "}
                                      MangoGrotesque-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="mangoGrotesque-bold"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "mangoGrotesque-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "mangoGrotesque-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "mangoGrotesque-bold",
                                      }}
                                    >
                                      {" "}
                                      MangoGrotesque-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="mesheddisplay-regular"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "mesheddisplay-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "mesheddisplay-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "mesheddisplay-regular",
                                      }}
                                    >
                                      {" "}
                                      Meshed Display-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="mesheddisplay-bold"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "mesheddisplay-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "mesheddisplay-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "mesheddisplay-bold",
                                      }}
                                    >
                                      {" "}
                                      Meshed Display-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="nymeria-regular"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "nymeria-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "nymeria-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "nymeria-regular",
                                      }}
                                    >
                                      {" "}
                                      Nymeria-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="nymeria-bold"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "nymeria-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "nymeria-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "nymeria-bold",
                                      }}
                                    >
                                      {" "}
                                      Nymeria-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="Palatino"
                                      style={{
                                        background:
                                          this.state.appFontFamily == "Palatino"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily == "Palatino"
                                            ? "white"
                                            : "",
                                        fontFamily: "Palatino",
                                      }}
                                    >
                                      {" "}
                                      Palatino
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="piazzolla-regular"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "piazzolla-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "piazzolla-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "piazzolla-regular",
                                      }}
                                    >
                                      {" "}
                                      Piazzolla-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="piazzolla-bold"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "piazzolla-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "piazzolla-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "piazzolla-bold",
                                      }}
                                    >
                                      {" "}
                                      Piazzolla-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="Poppins-SemiBold"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "Poppins-SemiBold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "Poppins-SemiBold"
                                            ? "white"
                                            : "",
                                        fontFamily: "Poppins-SemiBold",
                                      }}
                                    >
                                      {" "}
                                      Poppins SemiBold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="radiocanada-regular"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "radiocanada-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "radiocanada-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "radiocanada-regular",
                                      }}
                                    >
                                      {" "}
                                      Radio Canada-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="radiocanada-bold"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "radiocanada-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "radiocanada-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "radiocanada-bold",
                                      }}
                                    >
                                      {" "}
                                      Radio Canada-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="rebeqa-regular"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "rebeqa-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "rebeqa-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "rebeqa-regular",
                                      }}
                                    >
                                      {" "}
                                      Rebeqa Font-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="rebeqa-bold"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "rebeqa-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "rebeqa-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "rebeqa-bold",
                                      }}
                                    >
                                      {" "}
                                      Rebeqa Font-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="rogerex-regular"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "rogerex-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "rogerex-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "rogerex-regular",
                                      }}
                                    >
                                      {" "}
                                      Rogerex-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="rogerex-bold"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "rogerex-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "rogerex-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "rogerex-bold",
                                      }}
                                    >
                                      {" "}
                                      Rogerex-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="RozinaV04Regular"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "RozinaV04Regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "RozinaV04Regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "RozinaV04Regular",
                                      }}
                                    >
                                      {" "}
                                      Rozina-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="RozinaV06Bold"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "RozinaV06Bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "RozinaV06Bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "RozinaV06Bold",
                                      }}
                                    >
                                      {" "}
                                      Rozina-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="Sans-Serif"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "Sans-Serif"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "Sans-Serif"
                                            ? "white"
                                            : "",
                                        fontFamily: "Sans-Serif",
                                      }}
                                    >
                                      {" "}
                                      Sans Serif
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="TimesNewRomanPSMT"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "TimesNewRomanPSMT"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "TimesNewRomanPSMT"
                                            ? "white"
                                            : "",
                                        fontFamily: "TimesNewRomanPSMT",
                                      }}
                                    >
                                      {" "}
                                      Times New Roman
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="Trebuchet-MS"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "Trebuchet-MS"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "Trebuchet-MS"
                                            ? "white"
                                            : "",
                                        fontFamily: "Trebuchet-MS",
                                      }}
                                    >
                                      {" "}
                                      Trebuchet MS
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="unbounded-regular"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "unbounded-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "unbounded-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "unbounded-regular",
                                      }}
                                    >
                                      {" "}
                                      Unbounded Polkadot-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="unbounded-bold"
                                      style={{
                                        background:
                                          this.state.appFontFamily ==
                                          "unbounded-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily ==
                                          "unbounded-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "unbounded-bold",
                                      }}
                                    >
                                      {" "}
                                      Unbounded Polkadot-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="Verdana"
                                      style={{
                                        background:
                                          this.state.appFontFamily == "Verdana"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.appFontFamily == "Verdana"
                                            ? "white"
                                            : "",
                                        fontFamily: "Verdana",
                                      }}
                                    >
                                      {" "}
                                      Verdana
                                    </Dropdown.Item>
                                  </DropdownButton>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex  align-items-center justify-content-between mt-md-4 mt-4 mb-2 pt-2 ipad-custom-padding p-md-0 ps-3 ipad-custom-padding">
                          {/* Tool tip */}
                          <Tooltip
                            firsttag={"h5"}
                            tagclass={"mb-0 mr-2"}
                            classdata={
                              "module_title d-flex justify-content-start align-items-center"
                            }
                            heading={"Buttons"}
                            content={"Select style & color for app buttons."}
                          />
                          {/* Tool tip */}
                        </div>
                        <div className="upload_files d-flex align-items-center justify-content-between flex-wrap ipad-login-padding old-themecolorPicker">
                          <div className="color_picker mb-md-0 mb-4">
                            {/* Tool tip */}
                            <Tooltip
                              firsttag={"h4"}
                              tagclass={"mb-0 mr-2"}
                              classdata={
                                "d-flex justify-content-start align-items-center mb-1"
                              }
                              heading={"Button color"}
                              content={"Select colour for action button."}
                            />
                            {/* Tool tip */}
                            {/* <div className="color_input">
										<div className="backcheck">
											<div style={{backgroundColor:this.state.buttoncolor}} className="backcolorview"></div>
											<p className="m-0 p-0">{this.state.buttoncolor}</p>
										</div>
										<InputColor initialHexColor={this.state.initialbuttoncolor} onChange={this.handlebuttoncolor} className="gettingcolors w-100" />
									</div> */}
                            <Reactcolorpicker
                              colorname={this.state.buttoncolor}
                              presetColors={this.state.presetColors}
                              onChange={this.handlebuttoncolor}
                              classselection={"loginfixback"}
                              valuecontrol={false}
                            />
                          </div>
                          <div className="color_picker ipad-margin-top old-themecolorPicker">
                            {/* Tool tip */}
                            <Tooltip
                              firsttag={"h4"}
                              tagclass={"mb-0 mr-2"}
                              classdata={
                                "d-flex justify-content-start align-items-center mb-1"
                              }
                              heading={"Button text color"}
                              content={"Select text colour for action button."}
                            />
                            {/* Tool tip */}
                            {/* <div className="color_input">
										<div className="backcheck">
											<div style={{backgroundColor:this.state.buttontextcolor}} className="backcolorview"></div>
											<p className="m-0 p-0">{this.state.buttontextcolor}</p>
										</div>
										<InputColor initialHexColor={this.state.initialbuttontextcolor} onChange={this.handlebuttontextcolor} className="gettingcolors w-100" />
									</div> */}
                            <Reactcolorpicker
                              colorname={this.state.buttontextcolor}
                              presetColors={this.state.presetColors}
                              onChange={this.handlebuttontextcolor}
                              classselection={"loginfixback"}
                              valuecontrol={true}
                            />
                          </div>
                        </div>
                      </div>
                      {/*  Remove Mobile App */}
                      <RemoveApppreview />
                      {/*  Remove Mobile App */}
                    </form>
                  </div>
                  <div className="customiseapp mobilepreview mobilepreviewnew">
                    <div className="two-banner two-btn-tabs tabwrapper">
                      <div className="platswitch altswitch">
                        <ul
                          id="myTabs"
                          className="nav nav-pills nav-justified promotertabs mb-4"
                          role="tablist"
                          data-tabs="tabs"
                        >
                          <li>
                            <Link
                              to="#"
                              onClick={(e) => this.switchmobile(e, "ios")}
                              className={
                                this.state.hollowactive ? "active" : ""
                              }
                              data-toggle="tab"
                              id="year"
                            >
                              iOS
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="#"
                              onClick={(e) => this.switchmobile(e, "android")}
                              className={
                                this.state.hollowactive ? "" : "active"
                              }
                              data-toggle="tab"
                            >
                              Android
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="text-center sign_in style-page-cart">
                        <div className="tab-content mb-2">
                          <div
                            role="tabpanel"
                            className="tab-pane fade in active show"
                            id="ios"
                          >
                            <div
                              className="hollow_phone style-head"
                              style={{
                                backgroundImage:
                                  "url(" + this.state.holloimage + ")",
                              }}
                            >
                              <div className="mobile-head">
                                <img
                                  src={this.state.notch}
                                  className="img-fluid"
                                  alt="notch"
                                />
                              </div>
                              <div
                                className="white-mobile-border style-mobile"
                                id="applogo"
                              >
                                <div
                                  className="top-header"
                                  style={{
                                    background: this.state.primaryColor,
                                  }}
                                >
                                  <div className=" d-flex align-items-center justify-content-between">
                                    <div className="menu-icon d-flex align-items-center justify-content-between">
                                      <svg
                                        className="ml-3 mt-1"
                                        width="10.124"
                                        height="9.715"
                                        viewBox="0 0 10.124 9.715"
                                      >
                                        <g
                                          id="Group_5340"
                                          data-name="Group 5340"
                                          transform="translate(1.061 0.53)"
                                        >
                                          <g
                                            id="Group_2097"
                                            data-name="Group 2097"
                                            transform="translate(0)"
                                          >
                                            <path
                                              id="Path_6037"
                                              data-name="Path 6037"
                                              d="M0,4.327,4.327,0,8.655,4.327"
                                              transform="translate(0 8.655) rotate(-90)"
                                              fill="none"
                                              stroke={this.state.secondaryColor}
                                              stroke-width="1.5"
                                            />
                                          </g>
                                          <path
                                            id="Path_9120"
                                            data-name="Path 9120"
                                            d="M-13838.824-23465.541h8.859"
                                            transform="translate(13839.028 23469.971)"
                                            fill="none"
                                            stroke={this.state.secondaryColor}
                                            stroke-width="1.5"
                                          />
                                        </g>
                                      </svg>
                                      <p
                                        style={{
                                          fontFamily: this.state.appFontFamily,
                                          color: this.state.secondaryColor,
                                        }}
                                      >
                                        About
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="style-page-items">
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/Rainbow.jpg")
                                        .default
                                    }
                                    className="img-fluid"
                                    alt="Blog"
                                  />
                                  <div
                                    className="colortheme-detail"
                                    style={{ textAlign: "justify" }}
                                  >
                                    <h2>The standard Lorem Ipsum passage</h2>
                                    <p style={{ textAlign: "justify" }}>
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                      incididunt ut labore et dolore magna
                                      aliqua. Ut enim ad minim veniam, quis
                                      nostrud exercitation ullamco laboris nisi
                                      ut aliquip ex ea commodo consequat.
                                    </p>
                                    {/* <img
                                      src={
                                        require("../../assets/images/dashboard/about-page.png")
                                          .default
                                      }
                                      className="img-fluid mt-2"
                                      alt="Blog"
                                    /> 
                                    <h2 className="mt-2">Our Mission </h2> */}
                                    <p style={{ textAlign: "justify" }}>
                                      {" "}
                                      Duis aute irure dolor in reprehenderit in
                                      voluptate velit esse cillum dolore eu
                                      fugiat nulla pariatur. Excepteur sint
                                      occaecat cupidatat non proident, sunt in
                                      culpa qui officia deserunt mollit anim id
                                      est laborum.
                                    </p>
                                  </div>

                                  <div
                                    className="proceed-to-checkout proceed-to-checkout-new"
                                    style={{
                                      background: this.state.buttoncolor,
                                    }}
                                  >
                                    <p
                                      style={{
                                        color: this.state.buttontextcolor,
                                      }}
                                    >
                                      Read more
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {InnerFoot()} */}
          </div>
          <RebuildRequired rebuildpop={this.state.rebuildpopvalue} btnRebuildCancel={this.closeRequireBuildPopup} submitform={this.submitfunction}/>
        </section>
      </>
    );
  }
}
