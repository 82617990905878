import React, { createRef } from "react";

import { Link } from "react-router-dom";

import Header from "../Header";
import Leftpanel from "../Leftpanel";
import moment from "moment";
import Tooltipicon from "../../Components/commonIcons/Tooltip"
import "../../assets/css/Generalsetting.css";
import {
  Appnameapi,
  Appgeneralinfo,
  ShowtypeApi,
  DeleteApp,
  LeaveApp,
  getWorkspaces,
  transferApp,
  Countrylist
} from "../../Api/Dashboard/DashboardApi";
import { Spinner, Media, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Deletesvg,Leavesvg } from "../Svg";
import { InnerFoot } from "../../Dashboard/Foot";
import Verifypopup from "./Verifypopup";
import { errortoast } from "../Toaster";
import Verifyemailstatus from "../../Verification/Checkverification";
import Tooltip from "../Tooltip";
// import {Navigation} from './Topnavigation';
import { Deleteusers } from "../Svg";
import Amsvideo from "../Reusable/Amsvideos";
import ReactSelect from "react-select";
import userdefaultImgOnError from "../../assets/images/dashboard/my-account.png";
import wpdefaultImgOnError from "../../assets/images/dashboard/workspace/workspace-default-img.png";
import { LoadScript, GoogleMap, Marker, StandaloneSearchBox } from '@react-google-maps/api';

const customStyles = {
  control: (base, state) => ({
    ...base,
    // match with the menu
    borderRadius: 3,
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#7782a1" : "#C8CEDB",
    backgroundColor: state.isDisabled ? "#ffffff" : "",
    height: 50,
    minHeight: 50,
    fontSize: 14,
    color: "#7782A1",
    paddingLeft: 10,
    boxShadow: state.isFocused ? "none" : "none",
    "&:hover": {
      boxShadow: state.isFocused ? "none" : "none",
    },
  }),
  valueContainer: (base) => ({
    ...base,
    height: 50,
    minHeight: 50,
    fontSize: 14,
    color: "#7782A1",
  }),
  menu: (base) => ({
    ...base,
    fontSize: "14px",
  }),
  input: (base) => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    // color: "#1B2952", // Custom colour
    // marginRight: 20,
  }),
};
export default class Appnamenew extends React.PureComponent {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      plan_code: "",
      handlerender: true,
      verifypop: false,
      verifypopmodal: false,
      verifyhiturl: "",
      spinner: false,
      submitval: "Save",
      loader: "d-none",
      websitetype: false,
      websiteUrl: "",
      comparewebsiteUrl: "",
      loaderimage: require("../../assets/images/signup/Loader_GIF.gif").default,
      appname: "",
      oldappname: "",
      appplatform: "",
      appurl: "",
      hitapi: false,
      cancelpopup: false,
      typevalue: "",
      typeselection: [],
      dropdownSearchVal: "",
      bodyspinner: true,
      termandcondition: "",
      appnameid: "",
      transfermodal: false,
      changetypemodal:false,
      readstatus: false,
      websiteLocator: "1",
      is_verified: 0,
      build_status: 0,
      platformtype: "",
      business_phone: "",
      business_email: "",
      share_app: false,
      share_app_url: "",
      consent_form: false,
      consent_title: "",
      consent_description: "",
      consent_form_purchased: 0,
      appuser_role_id: null,
      allowdelete: false,
      appspinner:true,
      delete_app: false,
      paidsubscriptionpopup:false,
      openCountryList: false,
      countryselectfield: [],
      country_name_prefix: "",
      country_name:"",
      phonecodemodal: false,
      deleteappname:'',
      loader_remove: "d-none",
      loaderimage_remove: require("../../assets/images/dashboard/loader.gif")
        .default,
      submitval_confirmpop: "Delete",
      submitval_confirmpop1: "Leave",
      store_api_version: "",
      website_technology: parseInt(localStorage.getItem("website_technology")),
      website_platform: parseInt(localStorage.getItem("website_technology")),
      radioCheck:
        parseInt(localStorage.getItem("website_technology")) === 4 || parseInt(localStorage.getItem("website_technology")) === 5
          ? true
          : false,
      radioCheck2:
        parseInt(localStorage.getItem("website_technology")) === 2
          ? true
          : false,
      workspaceWebTechnology: "",
      appTechnology: "",
      wooToggleCount: 0,
      mergeArray1: [],
      appTransfer:
        localStorage.getItem("workspaceId") !== null &&
        localStorage.getItem("workspaceId") !== undefined &&
        localStorage.getItem("workspaceId") !== ""
          ? parseInt(localStorage.getItem("workspaceId"))
          : parseInt(localStorage.getItem("user_id")),
      appType: null,
      apptrans: false,
      isOwner: 0,
      isPaid: false,
      appTfDropData: null,
      leavemodal:false,
      force_app_to_update_latest_version:0,
      tfLoader: "d-none",
      tfLoaderimage: require("../../assets/images/signup/Loader_GIF.gif")
        .default,
      tfSubmitText: "Move",
      allow_changing_technology:0,
      openMobOperateDropdown: false,
      userInfo:'',
      lastdate:'',
      sameselected:true,
      defaultlanguage:'English (United States)',
      defaultlanguagecode:'en',
      user_country:'',
      app_country:'US',
      website_technology1: parseInt(localStorage.getItem("website_technology")),
      appRightPanelExpand: localStorage.getItem("appSideBarCollapse") === true ||
      localStorage.getItem("appSideBarCollapse") === "true"
        ? true
        : false,
      optionsForDefaultLanguage:  [
        {
            "name": "Afrikaans",
            "code": "af",
            "oriname": "Afrikaans",
            "type": "LTR",
            value :"af",
            label :"Afrikaans",
        },
        {
            "name": "Amharic",
            "code": "am",
            "oriname": "አማርኛ",
            "type": "LTR",
            value :"am",
            label :"Amharic",
        },
        {
            "name": "Arabic",
            "code": "ar",
            "oriname": "العربية",
            "type": "RTL",
            value :"ar",
            label :"Arabic",
        },
        {
            "name": "Bulgarian",
            "code": "bg_BG",
            "oriname": "Български",
            "type": "LTR",
            value :"bg_BG",
            label :"Bulgarian",
        },
        {
            "name": "Catalan",
            "code": "ca",
            "oriname": "Català",
            "type": "LTR",
            value :"ca",
            label :"Catalan",
        },
        {
            "name": "Catalan (Balear)",
            "code": "bal",
            "oriname": "Català (Balear)",
            "type": "LTR",
            value :"bal",
            label :"Catalan (Balear)",
        },
        {
            "name": "Chinese (China)",
            "code": "zh_CN",
            "oriname": "简体中文",
            "type": "LTR",
            value :"zh_CN",
            label :"Chinese (China)",
        },
        {
            "name": "Chinese (Hong Kong)",
            "code": "zh_HK",
            "oriname": "香港中文版",
            "type": "LTR",
            value :"zh_HK",
            label :"Chinese (Hong Kong)",
        },
        {
            "name": "Chinese (Taiwan)",
            "code": "zh_TW",
            "oriname": "繁體中文",
            "type": "LTR",
            value :"zh_TW",
            label :"Chinese (Taiwan)",
        },
        {
            "name": "Croatian",
            "code": "hr",
            "oriname": "Hrvatski",
            "type": "LTR",
            value :"hr",
            label :"Croatian",
        },
        {
            "name": "Czech",
            "code": "cs_CZ",
            "oriname": "Čeština‎",
            "type": "LTR",
            value :"cs_CZ",
            label :"Czech",
        },
        {
            "name": "Dutch",
            "code": "nl_NL",
            "oriname": "Nederlands",
            "type": "LTR",
            value :"nl_NL",
            label :"Dutch",
        },
        {
            "name": "Dutch (Belgium)",
            "code": "nl_BE",
            "oriname": "Nederlands (België)",
            "type": "LTR",
            value :"nl_BE",
            label :"Dutch (Belgium)",
        },
        {
            "name": "Danish",
            "code": "da_DK",
            "oriname": "Dansk",
            "type": "LTR",
            value :"da_DK",
            label :"Danish",
        },
        {
            "name": "English (Australia)",
            "code": "en_AU",
            "oriname": "English (Australia)",
            "type": "LTR",
            value :"en_AU",
            label :"English (Australia)",
        },
        {
            "name": "English (Canada)",
            "code": "en_CA",
            "oriname": "English (Canada)",
            "type": "LTR",
            value :"en_CA",
            label :"English (Canada)",
        },
        {
            "name": "English (New Zealand)",
            "code": "en_NZ",
            "oriname": "English (New Zealand)",
            "type": "LTR",
            value :"en_NZ",
            label :"English (New Zealand)",
        },
        {
            "name": "English (South Africa)",
            "code": "en_ZA",
            "oriname": "English (South Africa)",
            "type": "LTR",
            value :"en_ZA",
            label :"English (South Africa)",
        },
        {
            "name": "English (UK)",
            "code": "en_GB",
            "oriname": "English (UK)",
            "type": "LTR",
            value :"en_GB",
            label :"English (UK)",
        },
        {
            "name": "English (United States)",
            "code": "en",
            "oriname": "English (United States)",
            "type": "LTR",
            value :"en",
            label :"English (United States)",
        },
        {
            "name": "Finnish",
            "code": "fi",
            "oriname": "Suomi",
            "type": "LTR",
            value :"fi",
            label :"Finnish",
        },
        {
            "name": "French (Belgium)",
            "code": "fr_BE",
            "oriname": "Français de Belgique",
            "type": "LTR",
            value :"fr_BE",
            label :"French (Belgium)",
        },
        {
            "name": "French (Canada)",
            "code": "fr_CA",
            "oriname": "Français du Canada",
            "type": "LTR",
            value :"fr_CA",
            label :"French (Canada)",
        },
        {
            "name": "French (France)",
            "code": "fr_FR",
            "oriname": "Français",
            "type": "LTR",
            value :"fr_FR",
            label :"French (France)",
        },
        {
            "name": "German",
            "code": "de_DE",
            "oriname": "Deutsch",
            "type": "LTR",
            value :"de_DE",
            label :"German",
        },
        {
            "name": "German (Switzerland)",
            "code": "de_CH",
            "oriname": "Deutsch (Schweiz)",
            "type": "LTR",
            value :"de_CH",
            label :"German (Switzerland)",
        },
        {
            "name": "Hebrew",
            "code": "he_IL",
            "oriname": "עִבְרִית",
            "type": "RTL",
            value :"he_IL",
            label :"Hebrew",
        },
        {
            "name": "Hindi",
            "code": "hi_IN",
            "oriname": "हिन्दी",
            "type": "LTR",
            value :"hi_IN",
            label :"Hindi",
        },
        {
            "name": "Indonesian",
            "code": "id_ID",
            "oriname": "Bahasa Indonesia",
            "type": "LTR",
            value :"id_ID",
            label :"Indonesian",
        },
        {
            "name": "Italian",
            "code": "it_IT",
            "oriname": "Italiano",
            "type": "LTR",
            value :"it_IT",
            label :"Italian",
        },
        {
            "name": "Japanese",
            "code": "ja",
            "oriname": "日本語",
            "type": "LTR",
            value :"ja",
            label :"Japanese",
        },
        {
            "name": "Korean",
            "code": "ko_KR",
            "oriname": "한국어",
            "type": "LTR",
            value :"ko_KR",
            label :"Korean",
        },
        {
            "name": "Malay",
            "code": "ms_MY",
            "oriname": "Bahasa Melayu",
            "type": "LTR",
            value :"ms_MY",
            label :"Malay",
        },
        {
            "name": "Norwegian (Bokmål)",
            "code": "nb_NO",
            "oriname": "Norsk bokmål",
            "type": "LTR",
            value :"nb_NO",
            label :"Norwegian (Bokmål)",
        },
        {
            "name": "Norwegian (Nynorsk)",
            "code": "nn_NO",
            "oriname": "Norsk nynorsk",
            "type": "LTR",
            value :"nn_NO",
            label :"Norwegian (Nynorsk)",
        },
        {
            "name": "Polish",
            "code": "pl_PL",
            "oriname": "Polski",
            "type": "LTR",
            value :"pl_PL",
            label :"Polish",
        },
        {
            "name": "Persian",
            "code": "fa_IR",
            "oriname": "فارسی",
            "type": "RTL",
            value :"fa_IR",
            label :"Persian",
        },
        {
            "name": "Persian (Afghanistan)",
            "code": "fa_AF",
            "oriname": "(فارسی (افغانستان",
            "type": "RTL",
            value :"fa_AF",
            label :"Persian (Afghanistan)",
        },
        {
            "name": "Portuguese (Brazil)",
            "code": "pt_BR",
            "oriname": "Português do Brasil",
            "type": "LTR",
            value :"pt_BR",
            label :"Portuguese (Brazil)",
        },
        {
            "name": "Portuguese (Portugal)",
            "code": "pt_PT",
            "oriname": "Português",
            "type": "LTR",
            value :"pt_PT",
            label :"Portuguese (Portugal)",
        },
        {
            "name": "Russian",
            "code": "ru_RU",
            "oriname": "Русский",
            "type": "LTR",
            value :"ru_RU",
            label :"Russian",
        },
        {
            "name": "Spanish (Argentina)",
            "code": "es_AR",
            "oriname": "Español de Argentina",
            "type": "LTR",
            value :"es_AR",
            label :"Spanish (Argentina)",
        },
        {
            "name": "Spanish (Chile)",
            "code": "es_CL",
            "oriname": "Español de Chile",
            "type": "LTR",
            value :"es_CL",
            label :"Spanish (Chile)",
        },
        {
            "name": "Spanish (Colombia)",
            "code": "es_CO",
            "oriname": "Español de Colombia",
            "type": "LTR",
            value :"es_CO",
            label :"Spanish (Colombia)",
        },
        {
            "name": "Spanish (Costa Rica)",
            "code": "es_CR",
            "oriname": "Español de Costa Rica",
            "type": "LTR",
            value :"es_CR",
            label :"Spanish (Costa Rica)",
        },
        {
            "name": "Spanish (Guatemala)",
            "code": "es_GT",
            "oriname": "Español de Guatemala",
            "type": "LTR",
            value :"es_GT",
            label :"Spanish (Guatemala)",
        },
        {
            "name": "Spanish (Mexico)",
            "code": "es_MX",
            "oriname": "Español de México",
            "type": "LTR",
            value :"es_MX",
            label :"Spanish (Mexico)",
        },
        {
            "name": "Spanish (Peru)",
            "code": "es_PE",
            "oriname": "Español de Perú",
            "type": "LTR",
            value :"es_PE",
            label :"Spanish (Peru)",
        },
        {
            "name": "Spanish (Puerto Rico)",
            "code": "es_PR",
            "oriname": "Español de Puerto Rico",
            "type": "LTR",
            value :"es_PR",
            label :"Spanish (Puerto Rico)",
        },
        {
            "name": "Spanish (Spain)",
            "code": "es_ES",
            "oriname": "Español",
            "type": "LTR",
            value :"es_ES",
            label :"Spanish (Spain)",
        },
        {
            "name": "Spanish (Uruguay)",
            "code": "es_UY",
            "oriname": "Español de Uruguay",
            "type": "LTR",
            value :"es_UY",
            label :"Spanish (Uruguay)",
        },
        {
            "name": "Spanish (Venezuela)",
            "code": "es_VE",
            "oriname": "Español de Venezuela",
            "type": "LTR",
            value :"es_VE",
            label :"Spanish (Venezuela)",
        },
        {
            "name": "Swahili",
            "code": "sw",
            "oriname": "Kiswahili",
            "type": "LTR",
            value :"sw",
            label :"Swahili",
        },
        {
            "name": "Swedish",
            "code": "sv_SE",
            "oriname": "Svenska",
            "type": "LTR",
            value :"sv_SE",
            label :"Swedish",
        },
        {
            "name": "Swiss German",
            "code": "gsw",
            "oriname": "Schwyzerdütsch",
            "type": "LTR",
            value :"gsw",
            label :"Swiss German",
        },
        {
            "name": "Thai",
            "code": "th",
            "oriname": "ไทย",
            "type": "LTR",
            value :"th",
            label :"Thai",
        },
        {
            "name": "Turkish",
            "code": "tr_TR",
            "oriname": "Türkçe",
            "type": "LTR",
            value :"tr_TR",
            label :"Turkish",
        },
        {
            "name": "Vietnamese",
            "code": "vi",
            "oriname": "Tiếng Việt",
            "type": "LTR",
            value :"vi",
            label :"Vietnamese",
        },
        {
            "name": "Zulu",
            "code": "zul",
            "oriname": "isiZulu",
            "type": "LTR",
            value :"zul",
            label :"Zulu",
        }
    ],
      locationModal:false,
      lat: null, 
      lng: null,
      zoom: 10,
      mapSearchInput: '',
      mapSelectedLocation: null,
      enableLanguage: 0,
      enableOfflineMode: 0,
      enable_prompt_on_exit:true,
    };
    this.handleTransfer= this.handleTransfer.bind(this);
    this.mapRef = createRef(null)
    this.searchRef = createRef(null)
    this.inputRef = createRef(null);
    this.intervalId = null;
  }

  handleverifymodal = () => {
    this.setState({
      verifypopmodal: !this.state.verifypopmodal,
    });
  };
  loadapi = () => {
    window.scrollTo(0, 0);
    const params = {
      app_id: btoa(localStorage.getItem("Appid")),
      workspace_id:
        localStorage.getItem("workspaceId") !== "" &&
        localStorage.getItem("workspaceId") !== undefined &&
        localStorage.getItem("workspaceId") !== "undeined" &&
        localStorage.getItem("workspaceId") !== null &&
        localStorage.getItem("workspaceId") !== "null"
          ? btoa(localStorage.getItem("workspaceId"))
          : btoa(null),
    };
    Appgeneralinfo(params, this,() => this.selectdefaultlanguage());
  };
  componentDidMount() {
    this.intervalId = setInterval(() => {
      if (this.inputRef.current) {
        const value = this.inputRef.current.value;
        if (value !== this.state.mapSearchInput) {
          this.setState({mapSearchInput: value });
          this.handleLocationSelected(value)
        }
      }
    }, 500);
    // document.title = "General Settings";
    if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
		{
      document.title = "Settings | AppMySite";

		}
		else
		{
      document.title = "Settings |"+ ' ' +localStorage.getItem('agencyname');
		}

    document.addEventListener("mousedown", this.handleClickOutside);

    this.loadapi();
    ShowtypeApi(this);
    Countrylist(this);
    this.setState({
      appurl: localStorage.getItem("store_url"),
      appnameid: localStorage.getItem("Appid"),
      appplatform: localStorage.getItem("website_type_select"),
      typevalue: localStorage.getItem("website_type_select"),
    });

    getWorkspaces(this, "", "", "all", "appsetting");
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  handleClickOutside(event) {
    try {

      if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {


        this.setState({
          openCountryList: false
        })

      }
    } catch (err) {
      
      // this.setState({
      //   openCountryList:!this.state.openCountryList
      // })
    }
  }

  deleteapps = (e) => {
    e.preventDefault();
    this.setState({
      delete_app: !this.state.delete_app,
      deleteappname:'',
    });
  };

  selectdefaultlanguage = () =>
  {
  
    const renData = this.state.optionsForDefaultLanguage.map((data, idx) => 
    {
      return ( data.value == this.state.defaultlanguagecode ?
               data.label
              : ''    
            )
    });

    this.setState({defaultlanguage:renData});
  }

  paidsubscriptionpopup = (e) =>
  {
    this.setState({
      paidsubscriptiondetected: false,
    });
  }

  handleCountryDropdown = (e, res) => {

    this.setState({
      phone_code: res.phone_code,
      country_name_prefix: res.country_code,
      country_name:res.country_name,
      app_country:res.country_code,
      openCountryList: window.matchMedia("(max-width: 1200px)").matches ? this.state.openCountryList : !this.state.openCountryList,
      phonecodemodal: window.matchMedia("(max-width: 1200px)").matches ? !this.state.phonecodemodal : this.state.phonecodemodal
    })

  }

  handleOpenCoutryList = () => {
    this.setState({
      openCountryList: !this.state.openCountryList
    })

  }

  handleDropdownSearch = (e) => {
    this.setState({
      dropdownSearchVal: e.target.value
    })
  }

  // handlePaidSubs = (e) => 
  // {
  //   if((localStorage.getItem("agencyid") && localStorage.getItem("agencyid") != 1))
  //   {
  //      return;
  //   }
  //   else if(localStorage.getItem("workspaceId") !== "" &&
  //           localStorage.getItem("workspaceId") !== null &&
  //           localStorage.getItem("workspaceId") !== undefined &&
  //           localStorage.getItem("logged_in_user_role_id") !== null && 
  //           parseInt(this.state.allow_changing_technology) === 0 && 
  //           parseInt(localStorage.getItem("appuser_role_id")) < 3)
  //   {                                
  //       this.setState({changetypemodal: !this.state.changetypemodal});
  //   }
  //   else if(parseInt(this.state.allow_changing_technology) === 0 && parseInt(localStorage.getItem("appuser_role_id")) < 3)
  //   {
  //       this.setState({changetypemodal: !this.state.changetypemodal});
  //   }
  //   else if(((localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1) && (parseInt(this.state.allow_changing_technology) === 1)))
  //   {
  //       this.setState({
  //         isPaid: !this.state.isPaid,
  //       });
  //   }
  //   else
  //   {
  //     return;
  //   }
    
  // };

  handlePaidSubs = () => 
    {
     if(parseInt(localStorage.getItem("appuser_role_id")) < 3 && localStorage.getItem("agencyid") == 1)
      {
        this.setState({
          isPaid: !this.state.isPaid,
          website_technology1:this.state.website_technology,
        });
      }
    };

  handleWebsiteLocator = (e) => {
    this.setState({
      websiteLocator: e.target.value,
      hitapi: true,
    });
  };
  handleWebsiteUrl = (e) => {
    this.setState({
      websiteUrl: (e.target.value.replace(/ /g, '+')).replace(/^https?:\/\//i, ""),
      hitapi: true,
    });
    if (e.target.value.indexOf("https://") > -1) {
      this.setState({
        websiteLocator: "1",
      });
    }
    if (e.target.value.indexOf("http://") > -1) {
      this.setState({
        websiteLocator: "0",
      });
    }
  };
  handlewebsiteplatform1 = (e) => {
    this.setState({
      websitetype: true,
      sameselected:true
    });
  };
  handlewebsiteplatform2 = (e) => {
    this.setState({
      website_platform: e.target.checked === true && 2,
      hitapi: true,
      radioCheck2: e.target.checked,
      radioCheck: false,
      websitetype: true,
      typevalue: "4",
      wooToggleCount: 0,
    });
  };
  handleAppname = (e) => {
    var setvalue = e.target.value.replace(/^\s+/, "");
    setvalue = setvalue.replace(/[`~_|?"<>\[\]\\]/gi,"");

    this.setState({
      appname: setvalue,
      hitapi: true,
    });
  };

  handleDeleteAppname = (e) => 
  {
    var setvalue = e.target.value.replace(/^\s+/, "");
    // setvalue = setvalue.replace(/[`~!()_|?;:'",.<>\{\}\[\]\\\/]/gi, "");
    setvalue.replace(/[`~_|?"<>\[\]\\\/]/gi,"");

    this.setState({
      deleteappname: setvalue
    });
  };

  handleTransfer = (data,type) => 
  {
    
    var previousvalue = '';

        if(localStorage.getItem("workspaceId") !== null &&
           localStorage.getItem("workspaceId") !== undefined &&
           localStorage.getItem("workspaceId") !== "")
        {
          previousvalue = parseInt(localStorage.getItem("workspaceId"));
        }
        else
        {
          previousvalue = parseInt(localStorage.getItem("user_id"));
        }

    if (previousvalue === data.id) 
    {
      this.setState({sameselected:true});
      return;
    }
    else
    {
      this.setState({sameselected:false});
      this.setState({
        appTransfer: data.id,
        appType: type,
        apptrans: true,
        appTfDropData: data,
      });
    }
    
  };


  updateTransfer = () => 
  {
    let param = {};
    this.setState({
      tfLoader: "d-block",
      tfSubmitText: "",
    });
    if (this.state.appType === "user") 
    {
      param = {
        app_id: parseInt(localStorage.getItem("Appid")),
        workspace_id: null,
      };
    }
     else 
     {
      param = {
        app_id: parseInt(localStorage.getItem("Appid")),
        workspace_id: this.state.appTransfer,
      };
    }
    transferApp(param, this);
   
  };

  togglewebsitetype1 = (e) => {
    e.preventDefault();
    this.setState({
      apptrans: !this.state.apptrans,
    });
  };

  cancelpopup2 = () => {
    this.setState({
      apptrans: false,
    });
  };

  togglewebsitetype = (e) => {
    e.preventDefault();
    this.setState({
      websitetype: !this.state.websitetype,
    });
  };
  handleterm = (e) => {
    this.setState({
      termandcondition: (e.target.value.replace(/ /g, '+')).replace(/^https?:\/\//i, ""),
      hitapi: true,
    });
  };
  handleshare_app = (e) => {
    this.setState({
      share_app: e.target.checked,
      hitapi: true,
    });
  };
  handleconsent_form = (e) => {
    this.setState({
      consent_form: e.target.checked,
      hitapi: true,
    });
  };
  handleconsent_description = (e) => {
    this.setState({
      consent_description: e.target.value,
      hitapi: true,
    });
  };
  handleconsent_title = (e) => {
    this.setState({
      consent_title: e.target.value,
      hitapi: true,
    });
  };
  handleshareappurl = (e) => {
    this.setState({
      share_app_url: e.target.value.replace("https://", ""),
      hitapi: true,
    });
  };

  pluginsubmit = (e) => {
    e.preventDefault();
    
    const params = {
      workspace_id:
        localStorage.getItem("workspaceId") !== null &&
        localStorage.getItem("workspaceId") !== undefined &&
        localStorage.getItem("workspaceId") !== ""
          ? parseInt(localStorage.getItem("workspaceId"))
          : "",
      app_id: parseInt(localStorage.getItem("Appid")),
      app_name: this.state.appname.trim(),
      default_language:this.state.defaultlanguagecode,
      force_app_to_update_latest_version:this.state.force_app_to_update_latest_version,
      website_type: parseInt(this.state.appplatform),
      web_terms_condition_url: this.state.termandcondition !== '' ? 'https://'+((this.state.termandcondition.replace(/ /g, '+')).replace(/^https?:\/\//i, "")) : '',
      store_url:
        this.state.websiteUrl !== null && this.state.website_technology1 !== '3' ?
        (this.state.websiteUrl.replace(/^https?:\/\//i, "")) : '',
      httpprotocol: this.state.websiteLocator,
      business_phone: this.state.business_phone,
      business_email: this.state.business_email,
      share_app_bool: this.state.share_app === true ? 1 : 0,
      share_app_url: this.state.share_app_url,
      consent_form_bool: this.state.consent_form === true ? 1 : 0,
      consent_form_description: this.state.consent_description,
      consent_form_title: this.state.consent_title,
      app_country:this.state.app_country,
      is_android_rebuild_required:
        this.state.readstatus.android_success_build_count > 0 &&
        this.state.appname.trim() !== this.state.oldappname.trim()
          ? 1
          : 0,
      is_ios_rebuild_required:
        this.state.readstatus.ios_success_build_count > 0 &&
        this.state.appname.trim() !== this.state.oldappname.trim()
          ? 1
          : 0,
      store_api_version: this.state.store_api_version,

      website_technology: this.state.website_technology1,
      map_coordinates:
      {
        latitude:this.state.lat,
        longitude:this.state.lng
      },
      enable_language_selection: this.state.enableLanguage,
      enable_offline_mode: this.state.enableOfflineMode,
      enable_prompt_on_exit : this.state.enable_prompt_on_exit ? 1 : 0 ,
    };
    if (params.app_name.length < 3) 
    {
      errortoast("The app name must be atleast 3 characters");
    }
     else 
     {
      params.httpprotocol === "0" &&
        errortoast("We support only HTTPS Website");
      // params.httpprotocol === "1" &&
      //   this.state.hitapi &&
      //   Appnameapi(params, this);
      params.httpprotocol === "1" &&
        Appnameapi(params, this);
    }
  };
  cancelpopup = () => {
    if (this.state.wooToggleCount > 0) {
      this.setState({
        websitetype: false,
      });
    } else {
      this.setState({
        website_platform: !this.state.radioCheck === true ? 1 : 2,
        appplatform: this.state.appplatform,
        websitetype: false,
        radioCheck: !this.state.radioCheck,
        radioCheck2: !this.state.radioCheck2,
        website_technology1:this.state.website_technology,
      });
    }
  };
  updatepopup = () => {
    this.setState({
      appplatform: this.state.typevalue,
      websitetype: false,

      guestlogin: true,
      registerallow: true,
      facebooklogin: false,
      google_login: false,
      skip_login: true,
      loginbutton: false,
      website_technology:this.state.website_technology1,
    });
  };
  handletype = (e) => {
    this.setState({
      typevalue: e.target.checked === true ? "2" : "4",
      hitapi: true,
      websitetype: true,
      wooToggleCount: this.state.wooToggleCount + 1,
    });
  };
  // handletype2 = () => {
  //   this.setState({
  //     typevalue: "1",
  //     hitapi: true,
  //   });
  // };

  handlebusinessmail = (e) => {
    this.setState({
      business_email: e.target.value,
      hitapi: true,
    });
  };
  handlebusinessphone = (e) => {
    this.setState({
      business_phone: e.target.value.replace(/[^+0-9 ]/g, ""),
      hitapi: true,
    });
  };
  photoggle = (e, appid) => {
    e.persist();
    this.setState({
      websitetype:false,
      transfermodal: !this.state.transfermodal,
    });
  };


  deletapp = (e, id) => 
  {
    
    
    if(this.state.appname != this.state.deleteappname || this.state.deleteappname == '')
    {
      errortoast('Please enter valid app name');
      return;
      return false;
    }
    e.preventDefault();
    const params = 
    {
      app_id: btoa(id),
    };
    DeleteApp(params, this);
  };

  leavetoggle = (e, appid) => {
    e.persist();
    this.setState({
      leavemodal: !this.state.leavemodal,
    });
  };

  leaveapp = (e, id) => {
    e.preventDefault();
    const params = 
    {
      app_id: parseInt(id),
    };
    LeaveApp(params, this);
  };

  handleDeleteuser = () => {
    this.setState({
      allowdelete: !this.state.allowdelete,
    });
  };

  handletypetab = (e) => 
  {
    
    this.setState({website_technology1: e});
    
  };

  handletypechangepopup = (e) =>
  {
    if(parseInt(this.state.website_technology) == parseInt(this.state.website_technology1))
    {
      this.setState({changetypemodal: false,isPaid:false});
    }
    else
    {
      this.setState({changetypemodal: !this.state.changetypemodal,isPaid:!this.state.isPaid});
    }
    
  }

  handletypechangepopupclose = (e) =>
  {
    this.setState({changetypemodal: false});

  }
  handletypechangepopupsubmit = (e) =>
  {
    if((localStorage.getItem("agencyid") && localStorage.getItem("agencyid") != 1))
    {
       return;
    }
    else if(localStorage.getItem("workspaceId") !== "" &&
            localStorage.getItem("workspaceId") !== null &&
            localStorage.getItem("workspaceId") !== undefined &&
            localStorage.getItem("logged_in_user_role_id") !== null && 
            parseInt(this.state.allow_changing_technology) === 0 && 
            parseInt(localStorage.getItem("appuser_role_id")) < 3)
    {
        this.setState({changetypemodal: false});                                    
        window.location.href = "/workspace/pricing/"+btoa(localStorage.getItem('workspaceId'))+'/'+localStorage.getItem('website_technology');
    }
    else if(parseInt(this.state.allow_changing_technology) === 0 && parseInt(localStorage.getItem("appuser_role_id")) < 3)
    {
        this.setState({changetypemodal: false});
        
        // window.location.href = "/app/pricing";

        window.location.href =
          parseInt(this.state.website_technology1) === 4 ? "/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid'))
        : parseInt(this.state.website_technology1) === 5 ? "/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'))
        : parseInt(this.state.website_technology1) === 3 ? "/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'))
        : parseInt(this.state.website_technology1) === 2 ? "/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'))
        :"/app/pricing"
    }
    else if(((localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1) && (parseInt(this.state.allow_changing_technology) === 1)))
    {
      this.setState({changetypemodal: false,website_technology:this.state.website_technology1});
    }
    else
    {
      return;
    }


  }

  handletoggle = (e) => {
    this.setState({
      store_api_version: e.target.checked === true ? 1 : 0,
      hitapi: true,
    });
  };

  handleAllSettingToggles = (e) => {
   const {name , checked} = e.target;
   this.setState({
    [name] : checked
   })
  }

  handleforcetoggle = (e) =>
  {
    
    this.setState({
      force_app_to_update_latest_version: e.target.checked === true ? 1 : 0,
      hitapi: true,
    });
  }

  togglephonecodemodal = () => {
    this.setState({
      phonecodemodal: !this.state.phonecodemodal,
    });
  };

  handleseachradio = (e) => {
    this.setState({
      phone_code: e.target.value,
      phonecodemodal: false,
    });
  };

  handleRightPanelExpand = (value) => {

    this.setState({
      appRightPanelExpand: value
    })
    }

    handleDefaultLanguageChange = (e) => {
      this.setState({
        defaultlanguage: e.label,
        defaultlanguagecode:e.value,
        openMobOperateDropdown: false,
      });
    };

    handleOpenMobDefaultLanguageDropdown = () => {
      this.setState({
        openMobOperateDropdown: !this.state.openMobOperateDropdown,
      });
    };

    handleLocationToggle() {
      !this.state.locationModal && this.state.mapSelectedLocation?.lat === 0  && this.handleLocationClick()
      this.setState({
        locationModal: !this.state.locationModal,
      });
    }


  handleMapClick(event){
    const newCoords = event.latLng;
    this.setState({ mapSelectedLocation: { lat: newCoords.lat(), lng: newCoords.lng() } });
  };

  handleLocationSelected = (address) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.results && data.results.length > 0) {
          const { lat, lng } = data.results[0].geometry.location; 
          const locationData = { address, lat, lng }; 
          this.setState({mapSelectedLocation: locationData, zoom: 14 });
        } else {
          errortoast("No location results found.");
        }
      })
      .catch((error) =>
        errortoast("Error fetching location coordinates")
      );
  };


  handleLocationClick(){
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.setState({mapSelectedLocation : {lat: position.coords.latitude,
          lng: position.coords.longitude}, zoom: 14});
      },
      (error) => {
        
      }
    );
  } else {
    errortoast('Geolocation is not supported by this browser.');
  }
  };

  render() {
    

    return (
      <>
        {this.state.verifypop === true && <Verifypopup value={this} />}

        <Header customclass={"header-responsive-new"} />

        <section className="dasboard_page bottombar-module mobile-display mt-4 mt-md-0">
        <Leftpanel handleRightPanelExpand={this.handleRightPanelExpand} />
          {/* <div className="right-panel-gap"> */}
          <div className={`right-panel-gap ${(localStorage.getItem("appSideBarCollapse") !== undefined &&
                        localStorage.getItem("appSideBarCollapse") !== null&&
                        localStorage.getItem("appSideBarCollapse") === "true") ?
                            " right-panel-gap-expand": ""}`}>

            <div className="generalsetting-head">

              <h1>Settings<p className="generalsetting-tooltip">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                  <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                    <circle cx="9" cy="9" r="9" stroke="none"/>
                    <circle cx="9" cy="9" r="8.25" fill="none"/>
                  </g>
                  <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"/>
                  <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                </g>
              </svg>
                <p className="generalsetting-tooltipsubheader">
                Take control of your app's settings
                </p>
              </p></h1>
              {this.state.appspinner ? 
                <></>
              :

                <>
              <div className="generalsetting-head-right-mob">
                              {(this.state.appuser_role_id === 1)&& (localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || 
                                localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || 
                                localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1) && (
                                <div className="d-flex align-items-center justify-content-center " onClick={(e)=>this.handlewebsiteplatform1(e)}>
                                    
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.881" height="15" viewBox="0 0 13.881 15">
                                      <g id="Group_40464" data-name="Group 40464" transform="translate(-1599 -64)">
                                        <path id="Path_107620" data-name="Path 107620" d="M12.5,12.31a1.235,1.235,0,0,1-1.275,1.19H2.775A1.235,1.235,0,0,1,1.5,12.31V.69A1.235,1.235,0,0,1,2.775-.5h8.45A1.235,1.235,0,0,1,12.5.69" transform="translate(1598 65)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1"/>
                                        <path id="Path_107622" data-name="Path 107622" d="M26.619,9.749l3.538,3.583-3.449,3.493" transform="translate(1581.881 58.213)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                        <line id="Line_432" data-name="Line 432" x1="8" transform="translate(1603.5 71.5)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1"/>
                                      </g>
                                    </svg>
                                </div>
                              )}
                     {localStorage.getItem("workspaceId") !== "" &&
                    localStorage.getItem("workspaceId") !== null &&
                    localStorage.getItem("workspaceId") !== undefined &&
                    localStorage.getItem("logged_in_user_role_id") !== null && 
                    (parseInt(localStorage.getItem("logged_in_user_role_id")) === 1 
                    ) 
                    ? 
                    (
                      <div className="d-flex align-items-center justify-content-center"  onClick={(e)=>this.deleteapps(e)}>
                    
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.999" viewBox="0 0 14 13.999">
                        <g id="Group_38801" data-name="Group 38801" transform="translate(-835.516 -682.992)">
                          <g id="Rectangle_2307" data-name="Rectangle 2307" transform="translate(836.515 684.992)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1">
                            <path d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z" stroke="none"/>
                            <path d="M.5.5h11a0,0,0,0,1,0,0V10.7a.8.8,0,0,1-.8.8H1.3a.8.8,0,0,1-.8-.8V.5A0,0,0,0,1,.5.5Z" fill="none"/>
                          </g>
                          <g id="Rectangle_2308" data-name="Rectangle 2308" transform="translate(839.515 682.992)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1">
                            <path d="M1,0H5A1,1,0,0,1,6,1V3A0,0,0,0,1,6,3H0A0,0,0,0,1,0,3V1A1,1,0,0,1,1,0Z" stroke="none"/>
                            <path d="M1,.5H5a.5.5,0,0,1,.5.5V2.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V1A.5.5,0,0,1,1,.5Z" fill="none"/>
                          </g>
                          <g id="Group_3245" data-name="Group 3245" transform="translate(841.016 687.993)">
                            <line id="Line_110" data-name="Line 110" y2="6" transform="translate(3 0)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1"/>
                            <line id="Line_111" data-name="Line 111" y1="6" transform="translate(0 0)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1"/>
                          </g>
                          <line id="Line_112" data-name="Line 112" x2="14" transform="translate(835.516 685.492)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1"/>
                        </g>
                      </svg>
  
                          
                      </div>
                    )
                    : localStorage.getItem("appuser_role_id") !== null &&
                      localStorage.getItem("appuser_role_id") !== undefined &&
                      ( parseInt(localStorage.getItem("appuser_role_id")) === 1 ) ? (
                       
                       <div className="d-flex align-items-center justify-content-center"  onClick={(e)=>this.deleteapps(e)}>
                    
                       <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.999" viewBox="0 0 14 13.999">
                         <g id="Group_38801" data-name="Group 38801" transform="translate(-835.516 -682.992)">
                           <g id="Rectangle_2307" data-name="Rectangle 2307" transform="translate(836.515 684.992)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1">
                             <path d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z" stroke="none"/>
                             <path d="M.5.5h11a0,0,0,0,1,0,0V10.7a.8.8,0,0,1-.8.8H1.3a.8.8,0,0,1-.8-.8V.5A0,0,0,0,1,.5.5Z" fill="none"/>
                           </g>
                           <g id="Rectangle_2308" data-name="Rectangle 2308" transform="translate(839.515 682.992)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1">
                             <path d="M1,0H5A1,1,0,0,1,6,1V3A0,0,0,0,1,6,3H0A0,0,0,0,1,0,3V1A1,1,0,0,1,1,0Z" stroke="none"/>
                             <path d="M1,.5H5a.5.5,0,0,1,.5.5V2.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V1A.5.5,0,0,1,1,.5Z" fill="none"/>
                           </g>
                           <g id="Group_3245" data-name="Group 3245" transform="translate(841.016 687.993)">
                             <line id="Line_110" data-name="Line 110" y2="6" transform="translate(3 0)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1"/>
                             <line id="Line_111" data-name="Line 111" y1="6" transform="translate(0 0)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1"/>
                           </g>
                           <line id="Line_112" data-name="Line 112" x2="14" transform="translate(835.516 685.492)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1"/>
                         </g>
                       </svg>
   
                           
                       </div>
                    ) 
                    : null
                    }
                        
              </div>

                <div className="generalsetting-head-right">
                    {/* <p>Updated 10 Apr 2023, 11:45</p> */}
                    {this.state.lastdate && this.state.lastdate !== '' && this.state.lastdate !== undefined ?
                      <p className='consent-save-date'>Updated  {moment(this.state.lastdate).format(
                                "D MMMM YYYY, HH:mm"
                              )}{" "}</p>
                    : null
                    }


                    
                  {(this.state.appuser_role_id === 1) && localStorage.getItem("agencyid") <= 1 
                  // && (localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || 
                  //             localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || 
                  //             localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1)
                              
                              && (
                    <button className="generalsetting-delete-btn" onClick={(e)=>this.handlewebsiteplatform1(e)}>
                      <div className="d-flex align-items-center justify-content-center h-100 w-100">
                          
                          <div>
                          <svg xmlns="http://www.w3.org/2000/svg" width="13.881" height="15" viewBox="0 0 13.881 15">
                      <g id="Group_40464" data-name="Group 40464" transform="translate(-1599 -64)">
                        <path id="Path_107620" data-name="Path 107620" d="M12.5,12.31a1.235,1.235,0,0,1-1.275,1.19H2.775A1.235,1.235,0,0,1,1.5,12.31V.69A1.235,1.235,0,0,1,2.775-.5h8.45A1.235,1.235,0,0,1,12.5.69" transform="translate(1598 65)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1"/>
                        <path id="Path_107622" data-name="Path 107622" d="M26.619,9.749l3.538,3.583-3.449,3.493" transform="translate(1581.881 58.213)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                        <line id="Line_432" data-name="Line 432" x1="8" transform="translate(1603.5 71.5)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1"/>
                      </g>
                    </svg>Move</div>
                        </div>
                    </button>
                  )}

                    
                    { 
                      localStorage.getItem("appuser_role_id") !== null &&
                      localStorage.getItem("appuser_role_id") !== undefined &&
                      ( parseInt(localStorage.getItem("appuser_role_id")) === 2 ||
                      parseInt(localStorage.getItem("appuser_role_id")) === 3 ||
                      parseInt(localStorage.getItem("appuser_role_id")) === 4 ) ? 
                      (
                        <button className="generalsetting-delete-btn" onClick={(e) => this.leavetoggle(e, this.state.appnameid)}>
                          <div className="d-flex align-items-center justify-content-center h-100 w-100">
                          
                            <div>
                              {Leavesvg()}
                              Leave
                            </div>
                            </div>
                        </button>
                      )
                      : ""
                    }
                    {localStorage.getItem("workspaceId") !== "" &&
                    localStorage.getItem("workspaceId") !== null &&
                    localStorage.getItem("workspaceId") !== undefined &&
                    localStorage.getItem("logged_in_user_role_id") !== null && 
                    (parseInt(localStorage.getItem("logged_in_user_role_id")) === 1 
                    ) 
                    ? 
                    (
                      <button className="generalsetting-delete-btn" onClick={(e)=>this.deleteapps(e)}>
                      <div className="d-flex align-items-center justify-content-center h-100 w-100">
                          
                          <div>
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.999" viewBox="0 0 14 13.999">
                          <g id="Group_38801" data-name="Group 38801" transform="translate(-835.516 -682.992)">
                            <g id="Rectangle_2307" data-name="Rectangle 2307" transform="translate(836.515 684.992)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1">
                              <path d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z" stroke="none"/>
                              <path d="M.5.5h11a0,0,0,0,1,0,0V10.7a.8.8,0,0,1-.8.8H1.3a.8.8,0,0,1-.8-.8V.5A0,0,0,0,1,.5.5Z" fill="none"/>
                            </g>
                            <g id="Rectangle_2308" data-name="Rectangle 2308" transform="translate(839.515 682.992)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1">
                              <path d="M1,0H5A1,1,0,0,1,6,1V3A0,0,0,0,1,6,3H0A0,0,0,0,1,0,3V1A1,1,0,0,1,1,0Z" stroke="none"/>
                              <path d="M1,.5H5a.5.5,0,0,1,.5.5V2.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V1A.5.5,0,0,1,1,.5Z" fill="none"/>
                            </g>
                            <g id="Group_3245" data-name="Group 3245" transform="translate(841.016 687.993)">
                              <line id="Line_110" data-name="Line 110" y2="6" transform="translate(3 0)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1"/>
                              <line id="Line_111" data-name="Line 111" y1="6" transform="translate(0 0)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1"/>
                            </g>
                            <line id="Line_112" data-name="Line 112" x2="14" transform="translate(835.516 685.492)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1"/>
                          </g>
                        </svg>
                        Delete</div>
                        </div>
                    </button>
                    )
                    : localStorage.getItem("appuser_role_id") !== null &&
                      localStorage.getItem("appuser_role_id") !== undefined &&
                      ( parseInt(localStorage.getItem("appuser_role_id")) === 1 ) ? (
                        <button className="generalsetting-delete-btn" onClick={(e)=>this.deleteapps(e)}>
                        <div className="d-flex align-items-center justify-content-center h-100 w-100">
                            
                            <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.999" viewBox="0 0 14 13.999">
                            <g id="Group_38801" data-name="Group 38801" transform="translate(-835.516 -682.992)">
                              <g id="Rectangle_2307" data-name="Rectangle 2307" transform="translate(836.515 684.992)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1">
                                <path d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z" stroke="none"/>
                                <path d="M.5.5h11a0,0,0,0,1,0,0V10.7a.8.8,0,0,1-.8.8H1.3a.8.8,0,0,1-.8-.8V.5A0,0,0,0,1,.5.5Z" fill="none"/>
                              </g>
                              <g id="Rectangle_2308" data-name="Rectangle 2308" transform="translate(839.515 682.992)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1">
                                <path d="M1,0H5A1,1,0,0,1,6,1V3A0,0,0,0,1,6,3H0A0,0,0,0,1,0,3V1A1,1,0,0,1,1,0Z" stroke="none"/>
                                <path d="M1,.5H5a.5.5,0,0,1,.5.5V2.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V1A.5.5,0,0,1,1,.5Z" fill="none"/>
                              </g>
                              <g id="Group_3245" data-name="Group 3245" transform="translate(841.016 687.993)">
                                <line id="Line_110" data-name="Line 110" y2="6" transform="translate(3 0)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1"/>
                                <line id="Line_111" data-name="Line 111" y1="6" transform="translate(0 0)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1"/>
                              </g>
                              <line id="Line_112" data-name="Line 112" x2="14" transform="translate(835.516 685.492)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1"/>
                            </g>
                          </svg>
                          Delete</div>
                          </div>
                      </button>
                    ) 
                    : null
                    }

                    

                    <button className="generalsetting-save-btn" form="pluginsub" style={{backgroundColor:localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}>
                      <div className="d-flex align-items-center justify-content-center h-100 w-100">
                        <div className={this.state.loader}>
                        <div className="new-loader"></div>
                        </div>
                        <div>{this.state.submitval}</div>
                      </div>
                    </button>

                </div>
                </>
              }

            </div>
            {this.state.appspinner ? (
                 <div className="generalsettingbg" style={{minHeight:'80vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
                 <svg xmlns="http://www.w3.org/2000/svg" 
           xlink="http://www.w3.org/1999/xlink" 
           style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
           width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
           <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
           <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
             <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
             style={{animationplaystate: "running", animationdelay: "0s"}}>
             </animateTransform>
           </circle>
           </g>
           <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
           <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
           <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
           style={{animationplaystate: "running", animationdelay: "0s"}}>
           </animateTransform>
           </circle>
           </g>
           <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
           <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
           <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
           style={{animationplaystate: "running", animationdelay: "0s"}}>
   
           </animateTransform>
           </circle>
           </g>
           <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
           <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
           <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
           style={{animationplaystate: "running", animationdelay: "0s"}}>
   
           </animateTransform>
           </circle>
           </g>
           </svg>
               </div>
                ) : (
          <>
          <form onSubmit={this.pluginsubmit} id="pluginsub">
            <div className="generalsettingbg">
              <div className="generalsetting-width">
                <div className="generalsetting-settings">
                <div className="general-setting-head">
                  <h3>General</h3>
                  <h4>This is your app’s central control hub for customizing key configurations.</h4>
                </div>

                <div className="general-setting-body">

                  <div>

                    <h6>App name<p class="gsetting-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><p class="gsetting-tooltipsubheader">Specify a unique name for your app.</p></p></h6>

                    <input type="text" maxLength="30" minLength="3" value={this.state.appname} onChange={(e) => this.handleAppname(e)}/>
                  </div>

                  <div className="general-setting-gap1">

                    <h6>Product<p className="gsetting-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><p className="gsetting-tooltipsubheader">Choose the product that best suits your app development needs.</p></p></h6>

                    <div className="app-type-general-settings">
                     
                    <div>
                      { this.state.website_technology === 2 ?
                        <>
                        <img className="img-fluid" src={ require("../../assets/images/dashboard/app-type1.png").default} alt="app-type1"/>
                        <p>Web to app</p>
                        </>
                      : this.state.website_technology === 5 ?
                        <>
                        <img className="img-fluid" src={ require("../../assets/images/dashboard/app-type3.png").default} alt="app-type2"/>
                        <p>WooCommerce app</p>
                        </>
                      : this.state.website_technology === 3 ?
                        <>
                        <img className="img-fluid" src={ require("../../assets/images/dashboard/app-type4.png").default} alt="app-type3"/>
                        <p>Custom app</p>
                        </>
                      : this.state.website_technology === 4 ?
                        <>
                        <img className="img-fluid" src={ require("../../assets/images/dashboard/app-type2.png").default} alt="app-type4"/>
                        <p>Wordpress app</p>
                        </>
                      : <></>
                      }
                        
                      </div>
                      {((localStorage.getItem("agencyid") && localStorage.getItem("agencyid") != 1) ) ?
                        <></>
                      : (this.state.appuser_role_id == 1 || this.state.appuser_role_id == 2) &&
                        <h4 onClick={(e)=>this.handlePaidSubs(e)}>Change</h4>
                      }
                    </div>

                  </div>
                  {parseInt(this.state.website_technology) !== 3 && (
                  <div className="general-setting-gap1 general-setting-url">

                    <h6>Website<p className="gsetting-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><p className="gsetting-tooltipsubheader">Provide your website’s link here. This will establish a vital connection between your website and app.</p></p></h6>

                    <div className="d-flex">
                      <div className="general-setting-link">
                        <span>https://</span>
                      </div>
                        <input placeholder="www.yourwebsite.com" type="text" name="hello" id="hello" value={this.state.websiteUrl} required onChange={(e) =>this.handleWebsiteUrl(e)}/>

                        <div>
                      {parseInt(localStorage.getItem("website_technology")) !== 2 && parseInt(localStorage.getItem("website_technology")) !== 3
                      ?
                      <>
                        {this.state.is_verified === 1
                        ? <svg id="Group_27505" data-name="Group 27505" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                          <circle id="Ellipse_2306" data-name="Ellipse 2306" cx="8" cy="8" r="8" fill="#52cb86"/>
                          <path id="Path_7161" data-name="Path 7161" d="M-19252.924-4212.33l2.656,2.656,5-5" transform="translate(19257.096 4220.174)" fill="none" stroke="#fff" stroke-width="1.5"/>
                          </svg>
                        :  <Link to="/app/connect/wp-plugin">
                            <h4>Verify now</h4>
                           </Link>
                        }
                      </>
                      : <></>
                      }
                          

                          

                        </div>

                    </div>

                  </div>
                  )}

                {(parseInt(this.state.website_technology) == 5 || parseInt(this.state.website_technology) == 4) && 
                (
                  <div className="general-setting-gap1">

                    <h6>Terms and conditions<p className="gsetting-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><p className="gsetting-tooltipsubheader">Add a link to your terms and conditions page. It’ll appear on your app’s user registration page if enabled.</p></p></h6>

                    <div className="d-flex">
                      <div className="general-setting-link">
                        <span>https://</span>
                      </div>
                        <input type="text" placeholder="www.yourwebsite.com/terms-and-conditions" title="We support only HTTPS" value={this.state.termandcondition.replace(/^https?:\/\//i, "")}
                                onChange={(e) => this.handleterm(e)}/>

                        
                    </div>

                  </div>
                )}

                  {/* {(parseInt(this.state.website_technology) == 3 || parseInt(this.state.website_technology) == 2) &&  */}
                  {/* ( */}
                      {/* <> */}
                                <div className="general-setting-gap1">
                                  <h6>Country<p className="gsetting-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><p className="gsetting-tooltipsubheader">Select the country associated to your app.</p></p></h6>
                                  <div>
                                    <div className="form-group select-input align-items-center"  ref={this.wrapperRef}>
                                      <div className="phonecodeselects">
                                        {
                                          this.state.openCountryList &&
                                          
                                          <div className="profile-selectflag" >
                                            <div className="d-flex profile-selectflagtop">
                                              <input className="searchfleginput" placeholder="Search..." value={this.state.dropdownSearchVal} onChange={(e) => this.handleDropdownSearch(e)} />
                                              {this.state.dropdownSearchVal.trim() && 
                                                <p onClick={(e) => {this.setState({dropdownSearchVal: ""})}} className="clear-search">Clear</p>
                                              }
                                            </div>
                                            <div className="countrydiv">
                                              <div className=" countryflagtype">
                                                
                                                {this.state.countryselectfield
                                                  .filter((name) =>
                                                    name.country_name
                                                      .toLowerCase()
                                                      .includes(this.state.dropdownSearchVal.toLowerCase())
                                                  )
                                                  .map((res, i) => (
                                                    <div
                                                      className="d-flex align-items-center justify-content-between country-line-flag"
                                                      key={i}
                                                      onClick={(e) => this.handleCountryDropdown(e, res)}
                                                    >
                                                      <div className="d-flex align-items-center justify-content-start"

                                                      >
                                                        <img
                                                          src={
                                                            process.env.REACT_APP_Image_Path +
                                                            process.env.REACT_APP_SMALL_FLAG +
                                                            res.country_code.toLowerCase() +
                                                            ".png"
                                                          }
                                                          alt={i}
                                                        />
                                                        <p className="country-name">
                                                          {res.country_name} ({"+" + res.phone_code})
                                                        </p>
                                                      </div>

                                                    </div>
                                                  ))}
                                              </div>
                                            </div>
                                          </div>
                                        }
                                        <div style={{ width: "100%", height: "50px", display: "flex", alignItems: "center", cursor: "pointer" }} onClick={(e) => window.matchMedia("(max-width: 1200px)").matches ?
                                          this.togglephonecodemodal(e) : this.handleOpenCoutryList(e)}>
                                        {this.state.app_country && this.state.app_country != '' ?
                                          <img

                                            src={
                                              process.env.REACT_APP_Image_Path +
                                              process.env.REACT_APP_SMALL_FLAG +
                                              this.state.app_country.toLowerCase() +
                                              ".png"
                                            }
                                            alt="country-flag"
                                          />
                                        : 
                                            <img

                                            src={
                                              process.env.REACT_APP_Image_Path +
                                              process.env.REACT_APP_SMALL_FLAG +
                                              this.state.user_country.toLowerCase() +
                                              ".png"
                                            }
                                            alt="country-flag"
                                          />
                                        }
                                          {this.state.app_country && this.state.app_country != '' ? 
                                              <>
                                                  {this.state.countryselectfield
                                                    .filter((name) =>
                                                      name.country_name.toLowerCase()
                                                    )
                                                    .map((res, i) => (
                                                      res.country_code == this.state.app_country ?
                                                      
                                                        (res.country_name)
                                                      : ''
                                                    ))}
                                              </>
                                          :   <>
                                                {this.state.countryselectfield
                                                  .filter((name) =>
                                                    name.country_name
                                                      .toLowerCase()
                                                  )
                                                  .map((res, i) => (
                                                    res.country_code == this.state.user_country ?
                                                      (res.country_name)
                                                    : ''
                                                  ))}
                                              </> 
                                          }
                                        </div>
                                      </div>
                                    
                                    </div>

                                    {/* </Modal> */}

                                  </div>
                                </div>

                             
                   {/* </> */}
                  {/* )}  */}
                   <div className="general-setting-gap1">
                                  <h6>Map coordinates<p className="gsetting-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><p className="gsetting-tooltipsubheader">Specify the primary location of your app. Some features may require this information.</p></p></h6>
                                  <div className="setting-map-cordinate-main-div" onClick={() =>this.handleLocationToggle()}>
                                    <div className="generalsetting-parent-input-popup">
                                      <p className="generalsetting-popup-data-name" style={{color: this.state.lat ? '#1B2952' : '#bcc1ce'}}>{
                                      this.state.lat ? this.state.lat + " " + this.state.lng : 'Select'}</p>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="18"
                                        viewBox="0 0 20 26"
                                      >
                                        <path
                                          id="Subtraction_317"
                                          data-name="Subtraction 317"
                                          d="M10,26h0c-.006,0-.643-.443-1.562-1.215A31.615,31.615,0,0,1,5,21.42a23.187,23.187,0,0,1-3.437-5.093A14.679,14.679,0,0,1,0,9.927a9.86,9.86,0,0,1,2.929-7.02,10.039,10.039,0,0,1,14.142,0A9.86,9.86,0,0,1,20,9.927a14.139,14.139,0,0,1-1.562,6.2A24.638,24.638,0,0,1,15,21.243,33.715,33.715,0,0,1,10,26ZM10,6.229a3.792,3.792,0,1,0,3.784,3.792A3.792,3.792,0,0,0,10,6.229Z"
                                          fill="#838383"
                                          opacity="0.5"
                                        />
                                      </svg>
                                    </div>

                                  </div>

                                </div>
                  
                                {(parseInt(this.state.website_technology) == 3 || parseInt(this.state.website_technology) == 2) && 
                  (
                      <>
                                

                              <div className="general-setting-gap1">
                                  <h6>Language<p className="gsetting-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><p className="gsetting-tooltipsubheader">Choose the language of your app, ensuring a seamless experience for users in their preferred language and layout.</p></p></h6>
                                    <div className="mobile-view-click"
                                         onClick={window.matchMedia("(max-width: 1200px)").matches
                                                ? () => this.handleOpenMobDefaultLanguageDropdown()
                                                : null
                                                }
                                    >
                                      <ReactSelect
                                        options={this.state.optionsForDefaultLanguage}
                                        onChange={(e) =>
                                          this.handleDefaultLanguageChange(e)
                                        }
                                        required={true}
                                        classNamePrefix="profile-role-dropdown"
                                        value={{ label: this.state.defaultlanguage }}
                                        placeholder="Select..."
                                        isSearchable={true}
                                        isClearable={false}
                                        isDisabled={
                                          window.matchMedia("(max-width: 1200px)")
                                            .matches
                                            ? true
                                            : false
                                        }
                                        styles={customStyles}
                                      />
                                    </div>      
                              </div>
                      </>
                  )}
                  {/* <div className="general-setting-toggle">
                    <h6>Enable RTL<p className="bbsetting-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg>
                      <p className="bbsetting-tooltipsubheader">Enable Right-to-Left text support for languages that read from right to left.</p>
                      </p>
                      </h6>

                      <label className="switch" for="generalsettings">
                        <input type="checkbox" id="generalsettings" name="generalsettings"/>
                        <div className="slider round"></div>
                      </label>
                  </div> */}
               <div className="general-setting-toggle">
                    <h6>Enable language selection<p className="bbsetting-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg>
                      <p className="bbsetting-tooltipsubheader">Enabling this toggle will allow app users to choose their preferred language. Disabling this toggle will hide the language selection option, restricting users to a single language.</p>
                      </p>
                      </h6>

                      <label className="switch" for="generalLangSettings">
                        <input type="checkbox" id="generalLangSettings" name="generalsettings1" checked={this.state.enableLanguage} onChange={(e) => this.setState({enableLanguage : !this.state.enableLanguage ? 1 : 0})}/>
                        <div className="slider round"></div>
                      </label>
                  </div>
                  
                  <div className="general-setting-toggle">
                    <h6>Force app update<p className="bbsetting-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg>
                      <p className="bbsetting-tooltipsubheader">Display a prompt to your app users to update the app when you release a new version on the app stores.</p>
                      </p>
                      </h6>

                      <label className="switch" for="generalsettings1">
                        <input type="checkbox" id="generalsettings1" name="generalsettings1" checked={this.state.force_app_to_update_latest_version === 1 ? true: false} onChange={(e) =>this.handleforcetoggle(e)}/>
                        <div className="slider round"></div>
                      </label>
                  </div>
                  {localStorage.getItem('app_plan') && localStorage.getItem('app_plan') === "premium" && localStorage.getItem('website_technology')
                   && localStorage.getItem('website_technology') !== "2" ?  <div className="general-setting-toggle">
                    <h6>Enable offline mode
                      <Tooltipicon message={"Enable to allow users to access your app offline."}/>
                      </h6>
                      <label className="switch" for="generalSettingsOfflineMode" >
                        <input type="checkbox" 
                        id="generalSettingsOfflineMode" name="generalSettingsOfflineMode" 
                        checked={this.state.enableOfflineMode} onChange={() => this.setState({enableOfflineMode : !this.state.enableOfflineMode ? 1 : 0})}
                        />
                        <div className="slider round"></div>
                      </label>
                  </div> : null}
                  {/* {parseInt(this.state.website_technology) !== 3 && parseInt(this.state.website_technology) !== 2 && (
                  <div className="general-setting-toggle">
                    <h6>Connect via v2 API <img style={{marginLeft: "6px"}} className="img-fluid" src={ require("../../assets/images/dashboard/beta.png").default} alt="beta"/><p className="bbsetting-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg>
                      <p className="bbsetting-tooltipsubheader">Enable connectivity via  our new v2 API for enhanced app connectivity and performance. Disable if you encounter issues.</p>
                      </p>
                      </h6>

                      <label className="switch" for="generalsettings2">
                        <input type="checkbox" id="generalsettings2" name="generalsettings2" checked={this.state.store_api_version === 1 ? true: false} onChange={(e) =>this.handletoggle(e)}/>
                        <div className="slider round"></div>
                      </label>
                  </div>
                   )} */}

                  {/* {parseInt(this.state.website_technology) !== 3 && parseInt(this.state.website_technology) !== 2 && ( */}
                  <div className="general-setting-toggle">
                    <h6>Prompt on exit <p className="bbsetting-tooltip">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg>
                      <p className="bbsetting-tooltipsubheader"> Enable to prompt Android device users with a confirmation pop-up message before exiting the app preventing accidental exits.</p>
                      </p>
                      </h6>

                      <label className="switch" for="enable_prompt_on_exit">
                        <input 
                          type="checkbox" 
                          id="enable_prompt_on_exit" 
                          name="enable_prompt_on_exit" 
                          checked={this.state.enable_prompt_on_exit} 
                          onChange={(e) =>this.handleAllSettingToggles(e)}
                        />
                        <div className="slider round"></div>
                      </label>
                  </div>
                   {/* )} */}

                </div>

                </div>

                <div className="generalsetting-right">
                  <div className="generalsetting-preview-right">
                    {this.state.website_technology == 2 ?
                      <img className="img-fluid" src={ require("../../assets/images/dashboard/app-screen1.png").default} alt="app-screen1"/>
                    : this.state.website_technology == 4 ?
                      <img className="img-fluid" src={ require("../../assets/images/dashboard/app-screen2.png").default} alt="app-screen2"/>
                    : this.state.website_technology == 3 ?
                      <img className="img-fluid" src={ require("../../assets/images/dashboard/app-screen4.png").default} alt="app-screen3"/>
                    : this.state.website_technology == 5 ?
                      <img className="img-fluid" src={ require("../../assets/images/dashboard/app-screen3.png").default} alt="app-screen4"/>
                    : <></>
                    }

                  </div>
                </div>
              </div>
            </div>

            <button className="general-mobile-btns"  form="pluginsub" style={{backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}>

                  <div className="general-mobile-btns-blue">
                    <div className={this.state.loader}>
                      <div className="new-loader"></div>
                    </div>
                    {this.state.submitval}
                  </div>

                {/* <h5>Updated 10 Apr 2023, 11:45</h5>  */}
                {this.state.lastdate && this.state.lastdate !== '' && this.state.lastdate !== undefined ?
                      <h5 >Updated  {moment(this.state.lastdate).format(
                                "D MMMM YYYY, HH:mm"
                              )}{" "}</h5>
                    : null
                    }
            </button>
          </form>

            <Modal
          isOpen={this.state.websitetype}
          style={{ maxWidth: "600px", maxHeight: "560px" }}
          className="move-app-popup"
          centered
        >
          
          <ModalBody>

          <div className="move-app-body">

            <div className="moveapp-header">
              <div className="crossmodal mt-1" onClick={this.cancelpopup}>
                <img src={require("../../assets/images/dashboard/cross.png").default} />
              </div>

              <div className="move-back-arrow" onClick={this.cancelpopup}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20.759" height="15.408" viewBox="0 0 20.759 15.408">
                  <g id="Group_27465" data-name="Group 27465" transform="translate(-19.742 -22.295)">
                    <path id="Path_81245" data-name="Path 81245" d="M-13783.922-19217h19.346" transform="translate(13805.077 19247)" fill="none" stroke="#7782a1" stroke-width="2"/>
                    <path id="Path_82232" data-name="Path 82232" d="M14053.656,19255.426l-7,7,7,7" transform="translate(-14025.504 -19232.424)" fill="none" stroke="#7782a1" stroke-width="2"/>
                  </g>
                </svg>
              </div>

              </div>

            <h1>Move app</h1>
            <h2>Move your app between your Pay-per-app, Workspace and Agency subscriptions.</h2>


              <div className="move-app-list">
              <h6>Pay-per-app</h6>
              <label class="general-setting-radio-button">
                <span class="general-setting-radio-button__label">

                <div className="move-app-view">
                   <>
                   {this.state.userInfo && this.state.userInfo.profile_image && this.state.userInfo.profile_image.indexOf("https") === -1 ? 
                   (
                     <img src={process.env.REACT_APP_Image_Path +
                         (localStorage.getItem("invited_by_user_id") === undefined ||
                          localStorage.getItem("invited_by_user_id") === null ||
                          localStorage.getItem("invited_by_user_id") === "" 
                            ? localStorage.getItem("user_id")
                            : localStorage.getItem("invited_by_user_id")) +
                         process.env.REACT_APP_Profile +
                         this.state.userInfo.profile_image
                       }
                       onError={({ currentTarget }) => 
                       {
                         currentTarget.onerror = null;
                         currentTarget.src = userdefaultImgOnError;
                       }}
                     />
                   ) 
                   : 
                   (
                     <img
                       src={this.state.userInfo && this.state.userInfo.profile_image ? this.state.userInfo.profile_image : ''}
                       onError={({ currentTarget }) => 
                       {
                         currentTarget.onerror = null;
                         currentTarget.src = userdefaultImgOnError;
                       }}
                     />
                   )
                  }
                  </>
                  <div>
                    <h5>{this.state.userInfo && this.state.userInfo.name ? this.state.userInfo.name : ''}</h5>
                    <p>{parseInt(localStorage.getItem('personal_apps_count'))}{" "}{parseInt(localStorage.getItem('personal_apps_count')) == 1 ? 'app' :' apps'}</p>
                  </div>
                </div>

                </span>
                  <input type="radio" class="general-setting-radio-button__input"  name="choice2"
                   defaultChecked = {(
                    localStorage.getItem("workspaceId") === null ||
                    localStorage.getItem("workspaceId") === undefined ||
                    localStorage.getItem("workspaceId") === "" ||
                    localStorage.getItem("workspaceId") === "null")
                  ? true
                  : false
                  }
                  onChange={() => this.handleTransfer(this.state.userInfo,'user')}
                  />
                  <span class="general-setting-radio-button__control"></span>
                </label>
              <h6>Unlimited Workspace</h6>
             
              
              {this.state.mergeArray1.map((value, k) => 
              {
                return(
                <>                                        
                  <label class="general-setting-radio-button ">
                  <span class="general-setting-radio-button__label">

                  <div className="move-app-view">
                    <img src={process.env.REACT_APP_Image_Path +
                              value.user_id +
                              process.env.REACT_APP_Workspace_Profile +
                              value.id +
                              "/" +
                              value.profile_image} 
                      onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src =  require("../../assets/images/dashboard/workspace/used-workspace-icon.png")
                                .default;
                              }} 
                     />
                    <div>
                      <h5>{value.workspace_name}</h5>
                      <p>{value.app_count }{" "}{value.app_count == 1 ? 'app' :' apps'}</p>
                    </div>
                  </div>

                  </span>
                    <input type="radio" class="general-setting-radio-button__input" name="choice2" 
                    defaultChecked = {localStorage.getItem("workspaceId") !== null &&
                               localStorage.getItem("workspaceId") !== undefined &&
                               localStorage.getItem("workspaceId") !== "" &&
                               localStorage.getItem("workspaceId") !== "null" && 
                               value.id == parseInt(localStorage.getItem("workspaceId"))
                              ? true
                              : false
                              }
                              onChange={() => this.handleTransfer(value,'workspace')}
                  />
                    <span class="general-setting-radio-button__control"></span>
                  </label>
                </>)
              })}
                {/* <h6>Unlimited apps</h6>
                <label class="general-setting-radio-button">
                <span class="general-setting-radio-button__label">

                <div className="move-app-view">
                  <img src={require("../../assets/images/dashboard/accountprofile.png").default} />
                  <div>
                    <h5>John Smith</h5>
                    <p>Yes</p>
                  </div>
                </div>

                </span>
                  <input type="radio" class="general-setting-radio-button__input" id="choice2-2" name="choice2"/>
                  <span class="general-setting-radio-button__control"></span>
                </label>

                <label class="general-setting-radio-button">
                <span class="general-setting-radio-button__label">

                <div className="move-app-view">
                  <img src={require("../../assets/images/dashboard/appicon.png").default} />
                  <div>
                    <h5>John Smith</h5>
                    <p>Yes</p>
                  </div>
                </div>

                </span>
                  <input type="radio" class="general-setting-radio-button__input" id="choice2-3" name="choice2"/>
                  <span class="general-setting-radio-button__control"></span>
                </label> */}

                {/* <h6>Agency</h6>
                <label class="general-setting-radio-button">
                <span class="general-setting-radio-button__label">

                <div className="move-app-view">
                  <img src={require("../../assets/images/dashboard/appicon.png").default} />
                  <div>
                    <h5>John Smith</h5>
                    <p>Yes</p>
                  </div>
                </div>

                </span>
                  <input type="radio" class="general-setting-radio-button__input" id="choice2-4" name="choice2"/>
                  <span class="general-setting-radio-button__control"></span>
                </label>

                <label class="general-setting-radio-button">
                <span class="general-setting-radio-button__label">

                <div className="move-app-view">
                  <img src={require("../../assets/images/dashboard/bags.png").default} />
                  <div>
                    <h5>John Smith</h5>
                    <p>Yes</p>
                  </div>
                </div>

                </span>
                  <input type="radio" class="general-setting-radio-button__input" id="choice2-5" name="choice2"/>
                  <span class="general-setting-radio-button__control"></span>
                </label> */}

              </div>

            </div>

            <div className="move-app-footer">
                <div className="move-app-cancel" onClick={this.cancelpopup}>
                <p>Cancel</p>
                </div>
              
                <div className="move-app-ok" style={{backgroundColor:this.state.sameselected ? "#BCC1CE" : "",borderColor:this.state.sameselected ? "#BCC1CE" : "",cursor:this.state.sameselected ? "not-allowed" : "pointer"}} onClick={this.state.sameselected ? void (0) : (e)=>this.photoggle(e)}>
                <p>Move</p>
                </div>
            </div>


          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.paidsubscriptiondetected}
          style={{ maxWidth: "460px", maxHeight: "240px" }}
          className="generalstngs1"
          centered
        >
          <ModalHeader  toggle={this.paidsubscriptionpopup}>
          <div className="crossmodal mt-1" onClick={this.paidsubscriptionpopup}>
              <img src={require("../../assets/images/dashboard/cross.png").default} />
            </div>
				</ModalHeader>

          <ModalBody>
            <div>
              <h4>Paid subscription detected</h4>
              {this.state.customer_type === 1 ? (
              <h6>
                {" "}
                       You’re currently on a paid plan or have an active paid add on
                subscription. Please downgrade to the free plan, cancel any
                active paid add on subscriptions, and try again.
              </h6>
            ) : (
              <h6>
                {" "}
                       You’re currently on a paid plan or have an active paid add on
                subscription. Please downgrade to the free plan, cancel any
                active paid add on subscriptions, and try again.
              </h6>
            )}
              {/* <h6>You cannot transfer this app while you have an active paid subscription associated to it.</h6> */}
              
              <div className="generalstngs1-footer">
              
                <div className="generalstngs1-ok">
                <p onClick={this.paidsubscriptionpopup}>Okay</p>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.transfermodal}
          style={{ maxWidth: "460px", maxHeight: "240px" }}
          className="generalstngs2"
          centered
        >
          <ModalHeader>
            <div className="crossmodal mt-1" onClick={(e) => this.photoggle(e, "")}>
              <img src={require("../../assets/images/dashboard/cross.png").default} />
            </div>
          </ModalHeader>
          
          <ModalBody>
            <div>
              <h4>Confirm move?</h4>
              <h6>Are you sure you want to move this app to the selected subscription?</h6>
              
              <div className="generalstngs2-footer">

              <div className="generalstngs2-cancel" onClick={(e) => this.photoggle(e, "")}>
                <p>Cancel</p>
                </div>
              
                <div className="generalstngs2-ok" onClick={this.updateTransfer}>
                <div className={this.state.tfLoader}>
                  <div className="new-loader"></div>
                </div>
                <p>{this.state.tfSubmitText}</p>
                </div>
              </div>
            </div>
          </ModalBody>

        </Modal>

        <Modal
          isOpen={this.state.leavemodal}
          toggle={(e) => this.leavetoggle(e, "")}
          style={{ maxWidth: "460px", maxHeight: "240px" }}
          className="generalstngs2"
          centered
        >
          <ModalHeader
            toggle={(e) => this.leavetoggle(e, "")}
          >
           <div className="crossmodal mt-1" onClick={(e) => this.leavetoggle(e, "")}>
              <img src={require("../../assets/images/dashboard/cross.png").default} />
            </div>
          </ModalHeader>
          <ModalBody className="">
            <div>
              
                  <h4>Are you sure you want to leave your app?</h4>
                  <h6>You cannot undo this action.</h6>
                  <div className="generalstngs2-footer">
                    <div className="generalstngs2-cancel" onClick={(e) => this.leavetoggle(e, "")}>
                      <p>No</p>
                    </div>
                    <div className="generalstngs2-ok" onClick={(e) => this.leaveapp(e, this.state.appnameid)}>
                      <div className={this.state.loader_remove}>
                        <div className="new-loader"></div>
                      </div>
                      <p>{this.state.submitval_confirmpop1}</p>
                    </div>
                  </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.changetypemodal}
          style={{ maxWidth: "460px", maxHeight: "240px" }}
          className="generalstngs2"
          centered
        >
          <ModalHeader>
            <div className="crossmodal mt-1" onClick={(e) => this.handletypechangepopupclose(e)}>
              <img src={require("../../assets/images/dashboard/cross.png").default} />
            </div>
          </ModalHeader>

          <ModalBody>
            <div>
              <h4>Change product technology?</h4>
              <h6>Changing your selected product technology will reset a majority of your app’s settings. We strongly recommend that you republish your apps to the app stores after making this change. Beware that your customers’ apps may experience temporary disruptions until they download the republished version of the app. This process can be intricate, so please proceed with caution.</h6>
              
              <div className="generalstngs2-footer">

              <div className="generalstngs2-cancel" onClick={(e) => this.handletypechangepopupclose(e)}>
                <p>Cancel</p>
                </div>
              
                <div className="generalstngs2-ok" onClick={(e) => this.handletypechangepopupsubmit(e)}>
                <p>Proceed</p>
                </div>
              </div>
            </div>
          </ModalBody>

        </Modal>

        <Modal
          isOpen={this.state.delete_app}
          toggle={this.deleteapps}
          style={{ maxWidth: "600px", maxHeight: "340px" }} className="confirm-org-popups" centered>

          
          <ModalBody>

            <div className="crossmodal mt-1" onClick={this.deleteapps}>
                <img src={require("../../assets/images/dashboard/cross.png").default} />
            </div>

            <div>
              <div className="confirm-org-body">
                <h4>Delete app</h4>
                <h5>Are you sure you want to proceed? Deleting your app is permanent and cannot be undone. This action will remove your app and all associated data.</h5>

                <h6>Please type in the app name to confirm.</h6>
                  <input type="text" id="orgnameconfirm" name="orgnameconfirm" placeholder="App name" maxLength="30" minLength="3" autoComplete="off" onPaste={(e) => { e.preventDefault(); return false;}} value={this.state.deleteappname} onChange={(e) => this.handleDeleteAppname(e)}/>
              </div>

              <div className="confirm-org-footer">                
                <button className="confirm-org-footer-button-cancel" onClick={this.deleteapps}>Cancel</button>
                <button className="confirm-org-footer-button-delete" id="btndeleteorgid" onClick={(e) => this.deletapp(e, this.state.appnameid)}>
                <div className={this.state.loader_remove}>
                  <div className="new-red-loader"></div>
                </div>
                {this.state.submitval_confirmpop}								
              </button>
              </div>
          </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.isPaid}
          style={{ maxWidth: "600px", maxHeight: "560px" }}
          className="move-app-popup"
          centered
        >
          
          <ModalBody>
          <div className="move-app-body">

          <div className="moveapp-header">
          <div className="crossmodal mt-1" onClick={this.handlePaidSubs}>
              <img src={require("../../assets/images/dashboard/cross.png").default} />
            </div>

              <div className="move-back-arrow" onClick={this.handlePaidSubs}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20.759" height="15.408" viewBox="0 0 20.759 15.408">
                  <g id="Group_27465" data-name="Group 27465" transform="translate(-19.742 -22.295)">
                    <path id="Path_81245" data-name="Path 81245" d="M-13783.922-19217h19.346" transform="translate(13805.077 19247)" fill="none" stroke="#7782a1" stroke-width="2"/>
                    <path id="Path_82232" data-name="Path 82232" d="M14053.656,19255.426l-7,7,7,7" transform="translate(-14025.504 -19232.424)" fill="none" stroke="#7782a1" stroke-width="2"/>
                  </g>
                </svg>
              </div>

              </div>

            

            <h1>Change product</h1>
            <h2>The process of changing your underlying product technology can be intricate, so please proceed with caution.</h2>


              <div className="select-move-type">

                <label class="general-setting-radio-button change-active-app">
                <span class="general-setting-radio-button__label">

                <div className="move-app-view">
                  <img src={require("../../assets/images/dashboard/new-website.png").default} />
                  <div>
                    <h5>Web to app</h5>
                  </div>
                </div>

                </span>
                  <input type="radio" class="general-setting-radio-button__input" id="choicetype1-1" name="choicetype" defaultChecked={this.state.website_technology == 2 ? true :false} onClick={(e) => {this.handletypetab(2)}}/>
                  <span class="general-setting-radio-button__control"></span>
                </label>

                <label class="general-setting-radio-button">
                <span class="general-setting-radio-button__label">

                <div className="move-app-view">
                  <img src={require("../../assets/images/dashboard/new-wordpress.png").default} />
                  <div>
                    <h5>WordPress to app</h5>
                  </div>
                </div>

                </span>
                  <input type="radio" class="general-setting-radio-button__input" id="choicetype1-2" name="choicetype" defaultChecked={this.state.website_technology == 4 ? true :false} onClick={(e) => {this.handletypetab(4)}}/>
                  <span class="general-setting-radio-button__control"></span>
                </label>

                <label class="general-setting-radio-button">
                <span class="general-setting-radio-button__label">

                <div className="move-app-view">
                  <img src={require("../../assets/images/dashboard/new-woocomerce.png").default} />
                  <div>
                    <h5>WooCommerce to app</h5>
                  </div>
                </div>

                </span>
                  <input type="radio" class="general-setting-radio-button__input" id="choicetype1-3" name="choicetype" defaultChecked={this.state.website_technology == 5 ? true :false} onClick={(e) => {this.handletypetab(5)}}/>
                  <span class="general-setting-radio-button__control"></span>
                </label>

                <label class="general-setting-radio-button">
                <span class="general-setting-radio-button__label">

                <div className="move-app-view">
                  <img src={require("../../assets/images/dashboard/new-custom.png").default} />
                  <div>
                    <h5>Custom app</h5>
                  </div>
                </div>

                </span>
                  <input type="radio" class="general-setting-radio-button__input" id="choicetype1-4" name="choicetype" defaultChecked={this.state.website_technology == 3 ? true :false} onClick={(e) => {this.handletypetab(3)}}/>
                  <span class="general-setting-radio-button__control"></span>
                </label>

              </div>

            </div>

              <div className="move-app-footer">
                  <div className="move-app-cancel">
                  <p onClick={this.handlePaidSubs}>Cancel</p>
                  </div>
                
                  <div className="move-app-ok"
                  style={{
                    color:parseInt(this.state.website_technology) === parseInt(this.state.website_technology1)? "#fff":"",
                    backgroundColor: parseInt(this.state.website_technology) === parseInt(this.state.website_technology1)?"#bcc1ce":"",
                    cursor: parseInt(this.state.website_technology) === parseInt(this.state.website_technology1)?"not-allowed":"",
                    border: parseInt(this.state.website_technology) === parseInt(this.state.website_technology1)?"unset":"",
                  }}
                  >
                  <p onClick={()=>parseInt(this.state.website_technology) === parseInt(this.state.website_technology1)?null:
                    this.handletypechangepopup()}>Done</p>
                  </div>
              </div>

          </ModalBody>
        </Modal>
            </>
            
          )
          }

          </div>
          <Modal
          isOpen={
            window.matchMedia("(max-width: 1200px)").matches
              ? this.state.openMobOperateDropdown
              : false
          }
          style={{ maxWidth: "700px", maxHeight: "350px" }}
          className={" profile-code-modal"}
          centered
          dataclass="countrycode"
        >
          <ModalHeader className=" ">
            <div className="d-flex align-items-center search-box justify-content-center profile-country-search">
              <div className="d-flex align-items-center w-100 ">
                <div
                  className="backaero-svgimg"
                  onClick={this.handleOpenMobDefaultLanguageDropdown}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.759"
                    height="15.408"
                    viewBox="0 0 20.759 15.408"
                  >
                    <g
                      id="Group_27465"
                      data-name="Group 27465"
                      transform="translate(-19.742 -22.295)"
                    >
                      <path
                        id="Path_81245"
                        data-name="Path 81245"
                        d="M-13783.922-19217h19.346"
                        transform="translate(13805.077 19247)"
                        fill="none"
                        stroke="#7782a1"
                        stroke-width="2"
                      />
                      <path
                        id="Path_82232"
                        data-name="Path 82232"
                        d="M14053.656,19255.426l-7,7,7,7"
                        transform="translate(-14025.504 -19232.424)"
                        fill="none"
                        stroke="#7782a1"
                        stroke-width="2"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </ModalHeader>
          <ModalBody className="code-selector p-0">
            <div className="mob-popup-company-size">
              {this.state.optionsForDefaultLanguage.map((res, i) => (
                <div
                  className="d-flex align-items-center justify-content-between"
                  key={i}
                >
                  <div
                    className="d-flex align-items-center justify-content-start"
                    onClick={() => this.handleDefaultLanguageChange(res)}
                    // onClick={(e) => this.handleCountryDropdown(e, res)}
                  >
                    <p className="mob-company-size">{res.label}</p>
                  </div>
                </div>
              ))}
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.phonecodemodal}
          // isOpen= {false}
          toggle={this.togglephonecodemodal}
          style={{ maxWidth: "700px", maxHeight: "350px" }}
          className={this.props.className + " profile-code-modal"}
          centered
          dataclass="countrycode"
        >
          <ModalHeader
            toggle={this.togglephonecodemodal}
            className=" "
          >
           
            <div className="d-flex align-items-center search-box justify-content-center profile-country-search">
              <div className="d-flex align-items-center w-100 ">
                <div className="backaero-svgimg" onClick={this.togglephonecodemodal}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20.759" height="15.408" viewBox="0 0 20.759 15.408">
                    <g id="Group_27465" data-name="Group 27465" transform="translate(-19.742 -22.295)">
                      <path id="Path_81245" data-name="Path 81245" d="M-13783.922-19217h19.346" transform="translate(13805.077 19247)" fill="none" stroke="#7782a1" stroke-width="2" />
                      <path id="Path_82232" data-name="Path 82232" d="M14053.656,19255.426l-7,7,7,7" transform="translate(-14025.504 -19232.424)" fill="none" stroke="#7782a1" stroke-width="2" />
                    </g>
                  </svg>
                </div>

                <input
                  type="text"
                  className="form-control flagsearch-input"
                  placeholder="Search..."
                  value={this.state.dropdownSearchVal} onChange={(e) => this.handleDropdownSearch(e)} />
                {this.state.dropdownSearchVal.trim() && <p onClick={(e) => {
                  this.setState({
                    dropdownSearchVal: ""
                  })
                }} className="cleartext">Clear</p>}
              </div>
            </div>
          </ModalHeader>
          <ModalBody className="code-selector p-0">
           
            <div className=" profile-countrycode-flag ">
              {this.state.countryselectfield
                .filter((name) =>
                  name.country_name
                    .toLowerCase()
                    .includes(this.state.dropdownSearchVal.toLowerCase())
                )
                .map((res, i) => (
                  <div
                    className="d-flex align-items-center justify-content-between country-line-flag"
                    key={i}
                  >
                    <div className="d-flex align-items-center justify-content-start"
                      onClick={(e) => this.handleCountryDropdown(e, res)}
                    >
                      <img
                        src={
                          process.env.REACT_APP_Image_Path +
                          process.env.REACT_APP_SMALL_FLAG +
                          res.country_code.toLowerCase() +
                          ".png"
                        }
                        alt={i}
                      />
                      <p className="country-name">
                        {res.country_name}
                      </p>
                    </div>
                   
                  </div>
                ))}
            </div>
          </ModalBody>
        </Modal>


        <Modal
          isOpen={this.state.locationModal}
          toggle={() => this.handleLocationToggle()}
          style={{ maxWidth: "700px", maxHeight: "350px" }}
          className="map-coordinate-popup"
          centered
        >
          <ModalHeader>
            <h2>Map coordinates</h2>
            <img
                          onClick={() => this.handleLocationToggle()}
                          src={
                            require("../../assets/images/dashboard/cross.png")
                              .default
                          }
                        />
              
             
           
          </ModalHeader>
          
          <ModalBody className="">
            <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
              libraries={["places"]}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}    className="popupmap-div">
              <StandaloneSearchBox  ref={this.searchRef}>
                  <div className="map-popup-head-div">
                    
                    <svg onClick={() =>  this.handleLocationToggle()} xmlns="http://www.w3.org/2000/svg" width="20.759" height="15.408" viewBox="0 0 20.759 15.408">
                      <g id="Group_27465" data-name="Group 27465" transform="translate(-19.742 -22.295)">
                        <path id="Path_81245" data-name="Path 81245" d="M-13783.922-19217h19.346" transform="translate(13805.077 19247)" fill="none" stroke="#7782a1" stroke-width="2" />
                        <path id="Path_82232" data-name="Path 82232" d="M14053.656,19255.426l-7,7,7,7" transform="translate(-14025.504 -19232.424)" fill="none" stroke="#7782a1" stroke-width="2" />
                      </g>
                    </svg>
                  
                  <div className="web-popup-search-div">
                  <input
                    ref={this.inputRef}
                    type="text"
                    placeholder="Search location"
                    className="form-control flagsearch-input"
                    value={this.state.mapSearchInput}
                    onChange={(e) => this.setState({mapSearchInput: e.target.value})}
                    onKeyDown={(e) => {
                      this.setState({mapSearchInput: e.target.value})
                      if (e.key === 'Enter') {
                        this.handleLocationSelected(e.target.value);
                      }
                    }}
                  />
                  {this.state.mapSearchInput.length > 0 && <p className="cleartextweb" onClick={() => this.setState({mapSearchInput: ''})}>Clear</p>}
                  </div>
                      <div className="mob-map-done-div">
                        {/* <img
                          onClick={() => this.setState({ mapSearchInput: '' })}
                          src={
                            require("../../assets/images/dashboard/cross.png")
                              .default
                          }
                        />
                        <hr /> */}
                        <p className={`${!this.state.mapSelectedLocation?.lat ? 'map-donetextmob-disable' : 'map-donetextmob'}`}
                          onClick={() => this.setState({ lat: this.state.mapSelectedLocation.lat, lng: this.state.mapSelectedLocation.lng }, () => this.state.mapSelectedLocation?.lat && this.handleLocationToggle())}
                        >Done</p>


                      </div>
                </div>
                
              </StandaloneSearchBox>
                <GoogleMap
                  mapContainerStyle={{ width: '100%', height: '100%' }}
                  zoom={this.state.zoom}
                  center={this.state.mapSelectedLocation?.lat ? this.state.mapSelectedLocation : { lat :  40.7058989699728, lng : -74.0099789190983 }}
                  onClick={(e) => this.handleMapClick(e)}
                  ref={this.mapRef}
                  streetViewControl={false} 
                  options={{
                    streetViewControl: false,
                  }}
                >
                  <Marker position={this.state.mapSelectedLocation} />
                  <button onClick={() =>this.handleLocationClick()} className="map-current-location">
                      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 15 15" fill="none">
                        <path d="M7 15V13.4785C5.58494 13.3599 4.25748 12.7449 3.25293 11.7412C2.25072 10.7377 1.63601 9.41144 1.51855 7.99805H0V6.99805H1.51758C1.63605 5.58492 2.2506 4.259 3.25195 3.25488C4.2559 2.25035 5.58369 1.63422 6.99902 1.5166V0H7.99902V1.51758C9.41194 1.6355 10.7373 2.25173 11.7383 3.25586C12.7415 4.25913 13.3565 5.58526 13.4756 6.99902H15V7.99902H13.4785C13.359 9.41267 12.744 10.7387 11.7412 11.7422C10.7393 12.745 9.41446 13.361 8.00195 13.4805V15.001L7 15ZM2.7168 7.5C2.71786 8.76741 3.22197 9.98271 4.11816 10.8789C5.01436 11.7751 6.22966 12.2792 7.49707 12.2803C8.76432 12.2787 9.97892 11.774 10.875 10.8779C11.7711 9.98185 12.2758 8.76725 12.2773 7.5C12.2752 6.23291 11.771 5.01804 10.875 4.12207C9.97903 3.2261 8.76416 2.72184 7.49707 2.71973C6.22982 2.72131 5.01522 3.22599 4.11914 4.12207C3.22306 5.01815 2.71838 6.23275 2.7168 7.5ZM4.49805 7.5C4.49805 6.70435 4.81434 5.94152 5.37695 5.37891C5.93956 4.8163 6.7024 4.5 7.49805 4.5C8.2937 4.5 9.05653 4.8163 9.61914 5.37891C10.1817 5.94152 10.498 6.70435 10.498 7.5C10.498 8.29565 10.1817 9.05849 9.61914 9.62109C9.05653 10.1837 8.2937 10.5 7.49805 10.5C6.70274 10.4995 5.94011 10.1836 5.37793 9.62109C4.81575 9.05854 4.5 8.2953 4.5 7.5H4.49805Z" fill="#666666" />
                      </svg>
                 </button>
                  </GoogleMap>
                  </div>
            </LoadScript>
          </ModalBody>
          <ModalFooter>
            <div className="map-coordinate-popup-footer">
              <button className="map-cancel-btn" onClick={() => this.handleLocationToggle()}>Cancel</button>
              <button disabled={!this.state.mapSelectedLocation?.lat} className={`${!this.state.mapSelectedLocation?.lat ? 'map-done-btn map-done-btn-disabled' : 'map-done-btn'}`} onClick={() => this.setState({lat: this.state.mapSelectedLocation.lat, lng: this.state.mapSelectedLocation.lng}, () => this.handleLocationToggle())}>Done</button>
            </div>
           
          </ModalFooter>
        </Modal>
        </section>





        
      </>
    );
  }
}
