import React, { useEffect } from "react";
import moment from "moment";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import "./../assets/css/pricingdeals.css";
import { Link } from "react-router-dom";
import { paddleChangePlan } from "../../Api/Addons/Addonsapi";
import { getDeals} from "../../Api/Agency/AgencyApi";

export default class PricingMobileDealList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spinner: true,
      agencyID: this.props.agencyID,
      needOfId: 1,
      loaderimage: require("../../assets/images/signup/Loader_GIF.gif").default,
      accordian: false,
      index: "",
      customer_type: 1,
      index_checker: "",
      customer_billing_type: "",
      is_plan: 1,
      mobileAccordian:false,
      deals:[],
    };
  }
  componentDidMount() {
    getDeals(this,this.props.agencyID);
  }
  handleAccordian = (e, i) => {
    if (i === this.state.index) {
      this.setState({
        accordian: !this.state.accordian,
        index: i,
      });
    } else {
      this.setState({
        accordian: !this.state.accordian,
        index: i,
      });
    }
  };
  handleMobileAccordian = () => {
   this.setState({
    mobileAccordian:!this.state.mobileAccordian
   })
  };

  handleClick = (e, id, customerBillingType, row, i) => 
    {  
      this.setState({
        submitval: "",
        index_checker: i,
      });
  
        const paddleParam = {
          workspace_id: null,
          app_id: null,
          agency_id: atob(this.state.agencyID),
          deal_id: id,
          order_type: 1,
          price_id: row.plan_data.plan_code,
          quantity: 1,
          type: 1,
        };
        paddleChangePlan(paddleParam,this, () =>
        {
            if(this.state.code == 501 || this.state.code == 204)
            {
                this.setState({
                  submitval: "Make Payment",
                  loader: "d-none",
                  formbuttondisabled: false,
                  index_checker: "",
                });
            }
        })
    };


  handleReturnActiveImg = (customer_type) => {
   
    if (customer_type === 2) {
      return (
        <img
          src={require("../../assets/images/deals/delas-tako.png").default}
          alt="app-icon"
          className=""
        />
      );
    } else if (customer_type === 3) {
      return (
        <img
          src={require("../../assets/images/deals/deals-inf.png").default}
          alt="app-icon"
          className=""
        />
      );
    } else if (customer_type === 4) {
      return (
        <img
          src={require("../../assets/images/deals/deals-woo.png").default}
          alt="app-icon"
          className=""
        />
      );
    }
  };

  handleReturnDeactiveImg = (customer_type) => {
  
    if (customer_type === 2) {
      return (
        <img
          src={require("../../assets/images/deals/deact-tako.png").default}
          alt="app-icon"
          className=""
        />
      );
    } else if (customer_type === 3) {
      return (
        <img
          src={require("../../assets/images/deals/deact-inf.png").default}
          alt="app-icon"
          className=""
        />
      );
    } else if (customer_type === 4) {
      return (
        <img
          src={require("../../assets/images/deals/deact-woo.png").default}
          alt="app-icon"
          className=""
        />
      );
    }
  };

  render() {
    let count = 0;
    
    return (
      <>
        {
        this.state.spinner ? (
          <div className="payment-billingnewspinner" style={{height:'100vh'}} >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
              style={{
                margin: "auto",
                background: "rgb(241, 242, 243,0)",
                display: "block",
                shaperendering: "auto",
                animationplaystate: "running",
                animationdelay: "0s",
              }}
              width="200px"
              height="200px"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
            >
              <g
                transform="translate(20 50)"
                style={{ animationplaystate: "running", animationdelay: "0s" }}
              >
                <circle
                  cx="0"
                  cy="0"
                  r="6"
                  fill="#e6261f"
                  style={{
                    animationplaystate: "running",
                    animationdelay: "0s",
                  }}
                >
                  <animateTransform
                    attributeName="transform"
                    type="scale"
                    begin="-0.375s"
                    calcMode="spline"
                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                    values="0;1;0"
                    keyTimes="0;0.5;1"
                    dur="1s"
                    repeatCount="indefinite"
                    style={{
                      animationplaystate: "running",
                      animationdelay: "0s",
                    }}
                  ></animateTransform>
                </circle>
              </g>
              <g
                transform="translate(40 50)"
                style={{ animationplaystate: "running", animationdelay: "0s" }}
              >
                <circle
                  cx="0"
                  cy="0"
                  r="6"
                  fill="#f7d038"
                  style={{
                    animationplaystate: "running",
                    animationdelay: "0s",
                  }}
                >
                  <animateTransform
                    attributeName="transform"
                    type="scale"
                    begin="-0.25s"
                    calcMode="spline"
                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                    values="0;1;0"
                    keyTimes="0;0.5;1"
                    dur="1s"
                    repeatCount="indefinite"
                    style={{
                      animationplaystate: "running",
                      animationdelay: "0s",
                    }}
                  ></animateTransform>
                </circle>
              </g>
              <g
                transform="translate(60 50)"
                style={{ animationplaystate: "running", animationdelay: "0s" }}
              >
                <circle
                  cx="0"
                  cy="0"
                  r="6"
                  fill="#49da9a"
                  style={{
                    animationplaystate: "running",
                    animationdelay: "0s",
                  }}
                >
                  <animateTransform
                    attributeName="transform"
                    type="scale"
                    begin="-0.125s"
                    calcMode="spline"
                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                    values="0;1;0"
                    keyTimes="0;0.5;1"
                    dur="1s"
                    repeatCount="indefinite"
                    style={{
                      animationplaystate: "running",
                      animationdelay: "0s",
                    }}
                  ></animateTransform>
                </circle>
              </g>
              <g
                transform="translate(80 50)"
                style={{ animationplaystate: "running", animationdelay: "0s" }}
              >
                <circle
                  cx="0"
                  cy="0"
                  r="6"
                  fill="#4355db"
                  style={{
                    animationplaystate: "running",
                    animationdelay: "0s",
                  }}
                >
                  <animateTransform
                    attributeName="transform"
                    type="scale"
                    begin="0s"
                    calcMode="spline"
                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                    values="0;1;0"
                    keyTimes="0;0.5;1"
                    dur="1s"
                    repeatCount="indefinite"
                    style={{
                      animationplaystate: "running",
                      animationdelay: "0s",
                    }}
                  ></animateTransform>
                </circle>
              </g>
            </svg>
          </div>
        ) 
        : (
            <>   
              <div className={`mob-deals-div`}>
               <div className='mob-pricing-head'> <p>Select a deal</p></div>
                {this.state.deals.map((row, i) => {
                  
                  if (row.status === "active" &&
                     (row.total_agency_licence === "" || row.used_agency_licence <= row.total_agency_licence)) 
                   {
                      count++;
                  }
                  let customerBillingType;
                  if (row.plan_data) 
                  {
                    customerBillingType = row.plan_data.customer_billing_type;
                  } 
                  else 
                  {
                    customerBillingType = "notFound";
                  }
                  return (
                    ((row.status === "active" &&
                      (row.used_agency_licence <= row.total_agency_licence ||
                        row.total_agency_licence === "")) ||
                      this.props.btnCheck !== 1) && (
                      <>
                        <div className="mob-delas-maindiv">
                          <div className="mob-pricing-deals" key={i}>
                          <div className="mob-topdiv-pricing">
                            <div className="mob-pricing-topdiv">
                            <div className='mob-pricing-technology'>
                              {row.customer_type === 2 ? ( // for 2 AppSumo for 3 stackcommerce // row.plan_data.plan_code===row.app_plan? Selected : null
                                <h1>AppSumo</h1>
                              ) : (
                                <h1>Stackcommerce</h1>
                              )}

                              {row.plan_data && (
                                <h2>
                                  {row.plan_data.plan_name}{" "}
                                </h2>
                              )}
                              {row.activation_date && (
                                <p className="deal-activation-date">
                                  Activated on{" "}
                                  {moment(row.activation_date).format(
                                    "D MMMM YYYY"
                                  )}
                                </p>
                              )}
                              </div>
                              <div className="image-technology">
                              {row.status === "active"
                                ? this.handleReturnActiveImg(row.customer_type)
                                : this.handleReturnDeactiveImg(row.customer_type)}
                            
                            </div>
                            </div>
                            
                            <div class="buy"><span class="pricing-deals-mob-border"></span><span class="down-pricing-deals-border"></span></div>
                          </div>
                          </div>
                          <div className="mob-pricing-bottomdiv">
                              {row.total_agency_licence === "" ? 
                                (
                                  <h1>{row.used_agency_licence} used</h1>
                                ) : 
                                (
                                  <h1>
                                    {row.used_agency_licence} of {row.total_agency_licence} used
                                  </h1>
                                )}
                            <div className="mob-pricing-exp">
                              
                                {row.expiry_date === null ? 
                                (
                              
                                  <h2 className='mob-pricing-expinactive'>
                                        {row.status === "inactive"
                                          ? "Expired"
                                          : "Never Expires"}
                                      </h2>
                                ) : 
                                (
                                      <>
                                        {moment(row.expiry_date).isAfter(moment()) ===
                                        false ? (
                                          <h2 className='mob-pricing-expinactive'>
                                            Expired on{" "}
                                            {moment(row.expiry_date).format("D MMMM YYYY")}
                                          </h2>
                                        ) : (
                                          <h2 className='mob-pricing-expactive'>
                                            Expires on{" "}
                                            {moment(row.expiry_date).format("D MMMM YYYY")}
                                          </h2>
                                        )}
                                        </>
                                )}
                                {row.status === "active" ?
                                  <p className='pricing-deals-active'>Active</p>
                                  : row.status === "inactive" ?
                                  <p className='pricing-deals-inactive'>Inactive</p>
                                  :
                                  <p className='pricing-deals-inactive'>Inactive</p>
                                }
                              {/* </div> */}
                              
                            </div>
                            <div className="pricing-deals-coupon-code">
                              
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                    <path id="Path_9581" data-name="Path 9581" d="M4.472,11.664l7.2-7.2A1.61,1.61,0,0,1,12.8,4h5.6A1.6,1.6,0,0,1,20,5.6v5.6a1.583,1.583,0,0,1-.472,1.132l-7.2,7.2a1.6,1.6,0,0,1-2.26,0l-5.6-5.6a1.6,1.6,0,0,1,0-2.268ZM17.2,8A1.2,1.2,0,1,0,16,6.8,1.2,1.2,0,0,0,17.2,8Z" transform="translate(-4 -4)" fill="#bcc1ce"/>
                                  </svg>
                                <p className="deals-coupon-code-text">
                                  {row.licence_uuid}
                                </p>
                            </div>
                            {this.props.btnCheck === 1 && (
                                <button
                                  className={
                                        row.used_agency_licence == row.total_agency_licence 
                                      ? "pricing-delas-mob-dactiveebtn"
                                      : row.is_selected_deal === 1 && row.plan_data.plan_code === row.app_plan
                                      ? "pricing-delas-mob-dactiveebtn"
                                      : "pricing-delas-mob-activebtn"
                                  }
                                  disabled={
                                        row.used_agency_licence == row.total_agency_licence 
                                      ? true
                                      : row.is_selected_deal === 1 && row.plan_data.plan_code === row.app_plan
                                      ? true
                                      : false
                                  }
                                  onClick={(e) =>
                                    this.handleClick(
                                      e,
                                      row.id,
                                      this.state.customer_billing_type,
                                      row,
                                      i
                                    )
                                  }
                                >
                                  {this.state.index_checker === i && (
                                    <div className="new-loader">
                                      
                                    </div>
                                  )}

                                  {this.state.index_checker === i
                                    ? ""
                                    : row.used_agency_licence == row.total_agency_licence
                                    ? "Selected"
                                    : row.is_selected_deal === 1 && row.plan_data.plan_code === row.app_plan
                                    ? "Selected"
                                    : "Select"}
                                </button>
                            )}
                          </div>
                        </div>
                      </>       
                    )
                  );
                })
                }
              </div>
            </>
        )}

        <Modal
          isOpen={this.state.mobileAccordian}
          centered
          className="creditpopup"
          style={{ maxWidth: "490px !important" }}
        >
          <ModalHeader 
           
            className="modal-xxl-content deals-terms-popup-head"
          >
            <div className="crossmodal-deals " >
              <img onClick={(e)=>this.handleMobileAccordian(e)}
                src={require("../../assets/images/dashboard/cross.png").default}
                alt="alert"
              />
            </div>
          </ModalHeader>
          <ModalBody className=" popup-spec-view deals-updateappmod">
          <div className="d-flex justify-content-between deals-descriptions-chart">
                      <ul className="deals-desc-list">
                        <h5 className="deals-desc-heading">Deals Terms</h5>
                          <li>Activate your license within 60 days of purchase</li>
                        <li>
                        Lifetime access to AppMySite pro plan
                        </li>
                        <li>
                        Includes all future Pro Plan updates
                        </li>
                        <li>Ability to upgrade or downgrade between 5 license tiers</li>
                        <li>
                          60-day money-back guarantee, no matter the reason
                        </li>
                      </ul>
                      <ul className="deals-desc-list">
                        <h5 className="dealss-desc-heading">
                         Plan details
                        </h5>
                        <li>Convert any Website to app</li>
                        <li>Convert WordPress to app</li>
                        <li>Convert WooCommerce to app</li>
                        <li>Publish on Android & iOS App Stores</li>
                        <li>App personalization</li>
                        <li>Test on real device</li>
                        <li>Push notifications</li>
                        <li>App Monetization</li>
                        <li>User Consent</li>
                      </ul>
                    </div>
          
          
          </ModalBody>
         
        </Modal>

        {/* No deals Page/ */}

        {this.state.spinner1 === false &&
        (this.state.deals.length === 0 || (count === 0 && this.props.btnCheck === 1)) ? (
          <div class="">
            <img
              src={require("../../assets/images/deals/NoDeals.png").default}
              className="nodeals-img mx-auto d-block noDeals"
              alt="no-deals "
            />

            <div className="billing_nodatatext">
              <span className="custom-size text-center not-subscribed span1">
                Nothing here at the moment.
              </span>
              <Link
                to={"/redeem"}
                className="custom-size text-center not-subscribed span2"
              >
                Redeem code
              </Link>
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }
}
