export const THEME_HEADER_LINKS = [
    {
        link: "/app/app-icon",
        name: "App icon",
        is_active: false,
        is_show: true,
      },
      {
        link: "/app/splash",
        name: "Splash",
        is_active: false,
        is_show: true,
        
      },
      {
        link: "/app/theme",
        name: "Theme",
        is_active: true,
        is_show: true,
      },
]