import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { downloadFile } from "../../../utils/commonUtils";

const ImageDownloadModal = ({
  openPopUp,
  handleImgDownloadPopUp,
  imageUrl,
  imgName,
}) => {
  // const imgUrl =
  //   "https://images.unsplash.com/photo-1733714954042-c9111b67b957?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzNTI2MzN8MHwxfHNlYXJjaHwzfHxtb2Rlcm4lMjBuYXR1cmV8ZW58MHwwfDJ8fDE3MzM4MDM4OTF8MA&ixlib=rb-4.0.3&q=80&w=200";

  return (
    <Modal className="download-icon-popup-modal" isOpen={openPopUp}>
      <ModalBody>
        <div className="download-icon-popup">
          <img
            className="img-fluid downloaded-icon"
            src={imageUrl}
            alt="App-icon-default"
          />

          <div className="download-icon-popup-footer">
            <svg
              onClick={() => handleImgDownloadPopUp()}
              xmlns="http://www.w3.org/2000/svg"
              width="21.414"
              height="21.414"
              viewBox="0 0 21.414 21.414"
            >
              <g
                id="Group_37047"
                data-name="Group 37047"
                transform="translate(-53.793 -664.793)"
              >
                <path
                  id="Path_101682"
                  data-name="Path 101682"
                  d="M-13508.229-19301.85l-20,20"
                  transform="translate(13582.729 19967.35)"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                />
                <path
                  id="Path_101683"
                  data-name="Path 101683"
                  d="M-13528.229-19301.85l20,20"
                  transform="translate(13582.729 19967.35)"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                />
              </g>
            </svg>

            <svg
              onClick={() => downloadFile(imageUrl, imgName)}
              xmlns="http://www.w3.org/2000/svg"
              width="26.103"
              height="25.242"
              viewBox="0 0 26.103 25.242"
            >
              <g
                id="Group_36045"
                data-name="Group 36045"
                transform="translate(0.5 1)"
              >
                <g
                  id="Group_36042"
                  data-name="Group 36042"
                  transform="translate(12.551)"
                >
                  <g id="Group_36048" data-name="Group 36048">
                    <line
                      id="Line_368"
                      data-name="Line 368"
                      y1="17.216"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </g>
                </g>
                <path
                  id="Path_99648"
                  data-name="Path 99648"
                  d="M3.5,6l6.9,6.886L17.273,6"
                  transform="translate(2.165 4.674)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
                <g
                  id="Group_36044"
                  data-name="Group 36044"
                  transform="translate(0.5 16.355)"
                >
                  <g id="Group_36047" data-name="Group 36047">
                    <path
                      id="Path_99649"
                      data-name="Path 99649"
                      d="M24.6,10v6.886H.5V10"
                      transform="translate(-0.5 -10)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ImageDownloadModal;
