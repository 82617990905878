import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import crossIcon from "../../../assets/images/dashboard/cross.png";

const LeaveAppModal = ({
  settingsState,
  leavetoggle,
  leaveapp,
  leaveAppDataLoading,
}) => {
  return (
    <div>
      <Modal
        isOpen={settingsState.leavemodal}
        toggle={(e) => leavetoggle(e, "")}
        
        className="generalstngs2 leaveAppModal"
        centered
      >
        <ModalHeader toggle={(e) => leavetoggle(e, "")}>
          <div className="crossmodal mt-1" onClick={(e) => leavetoggle(e, "")}>
            <img src={crossIcon} alt="cross-icon" />
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="productTechnologyModal-body">
            <h4 className="productTechnologyModal-body-head">
              Are you sure you want to leave your app?
            </h4>
            <h6 className="productTechnologyModal-body-para">
              You cannot undo this action.
            </h6>
            <div className="productTechnologyModal-footer">
              <div
                className="productTechnologyModal-cancel"
                onClick={(e) => leavetoggle(e, "")}
              >
                <p className="productTechnologyModal-cancel-text">No</p>
              </div>
              <button
                className="productTechnologyModal-ok"
                onClick={(e) => leaveapp(e, settingsState.appnameid)}
                disabled={leaveAppDataLoading}
              >
                {leaveAppDataLoading ? (
                  <div className="new-loader"></div>
                ) : (
                  <p className="productTechnologyModal-ok-text">Leave</p>
                )}
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LeaveAppModal;
