import React from "react";
import Header from "../Header";
import LeftPanel from "./workspaceLeftpannel";
import "./workspacecommon.css";
import {WorkspaceDashboardAPI,getWorkspaceDataGraph} from "../../Api/Dashboard/DashboardApi";
import moment from "moment";
import { Link } from "react-router-dom";
import {Progress} from "reactstrap";
import { Bar } from 'react-chartjs-2';
import styles from "../../Agency/assets/css/chart.module.css";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from "reactstrap";
  import {
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import SupportSection from "../../Components/dashboardCards/SupportSection/SupportSection";
import { SUPPORTCARDDETAILS } from "../../Constants/dashboardSupportSection/dashboardSupport";
import { isJSON } from "../../utils/commonUtils";
export default class WorkspaceDashboard extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {	
        workspaceId: atob(this.props.match.params.workspaceID), 
        workspaceid:'',
        subscription:'',
        owner_data:'',
        workspacedata:'',
        management_users:'',
        spinner:true,
        show_management_users:0,
        appscount:0,
        graphload:true,
        datefilter:"7 days",
        openMobileAppDropdown:false,
        periods:[],
        labels:[],
        rightpanelexpand:true,
        totalcheckpoint:parseInt(localStorage.getItem("logged_in_user_role_id")) != 4 ? 2 : 1,
        pointcheck:0,
        profilepath:
            process.env.REACT_APP_Image_Path +
            (
            (parseInt(localStorage.getItem("logged_in_user_role_id")) === 1 ||
            parseInt(localStorage.getItem("logged_in_user_role_id")) === 2)
            ? localStorage.getItem("logged_in_user_id")
            : localStorage.getItem("invited_by_user_id")) +
            process.env.REACT_APP_Workspace_Profile +
            localStorage.getItem("workspaceId") +
            "/",
        options : {
            responsive: true,
            maintainAspectRatio: true,
            plugins: {
              legend: {
                display: false,
                position: 'top',
              },
              title: {
                display: false,
                text: 'Chart.js Bar Chart',
                fontFamily: "Axiforma-Regular",
              },
              
            },
          },
        data: "",
	  }
    }

    componentDidMount() 
    {
		window.scrollTo(0, 0);
		document.title = "Dashboard | AppMySite";
		
		  if(this.state.workspaceId !== null && this.state.workspaceId !== undefined && this.state.workspaceId !== "null" && this.state.workspaceId !== "undefined")
		  {
			WorkspaceDashboardAPI(this, btoa(this.state.workspaceId) ,() => {
                this.handlepointcheck();
            });
            getWorkspaceDataGraph(this,this.state.workspaceId,this.state.datefilter);
			this.setState({workspaceid:this.state.workspaceId});
		  }
		  else
		  {
			WorkspaceDashboardAPI(this, localStorage.getItem("workspaceId"),() => {
               this.handlepointcheck();
            });
            getWorkspaceDataGraph(this,localStorage.getItem("workspaceId"),this.state.datefilter);
		  }
	}

     handlepointcheck = () =>
     {
        if(parseInt(localStorage.getItem("logged_in_user_role_id")) == 4)
        {
            var pointcheck = 0;
            
            // if(this.state.workspacedata && (this.state.workspacedata.profile_image != null) && this.state.workspacedata.profile_image != '')
            // {
            //     pointcheck = pointcheck+1;
            // }

            if(parseInt(this.state.appscount) > 0 )
            {
                
                pointcheck = pointcheck+1;
            }

            

            this.setState({pointcheck:pointcheck});
        }
        else
        {

            var pointcheck = 0;
            
            // if(this.state.workspacedata && (this.state.workspacedata.profile_image != null) && this.state.workspacedata.profile_image != '')
            // {
            //     pointcheck = pointcheck+1;
            // }

            if(parseInt(this.state.appscount) > 0 )
            {
                
                pointcheck = pointcheck+1;
            }

            // if(this.state.subscription.plan != 'preview' )
            // {
            //     pointcheck = pointcheck+1;
            // }

            if(this.state.management_users && (this.state.management_users != null) && this.state.management_users.length > 0 )
            {
                pointcheck = pointcheck+1;
            }

            

            this.setState({pointcheck:pointcheck});

        }
     }
      
      handleStatdropdown = () => 
      {
        this.setState({openstatDropdown:!this.state.openstatDropdown});
      }
      
      handleMobileAppDropdown = () => 
      {
          this.setState({openMobileAppDropdown:!this.state.openMobileAppDropdown});
      };

      clickdatafiltervalue = (e,valuedate) => 
      {
            e.preventDefault();
            
          
          this.setState({graphload:true,datefilter:valuedate,openMobileAppDropdown:false});
          getWorkspaceDataGraph(this,this.state.workspaceId,valuedate);
      }

      handleRightPanelExpand = (rightpanelvalue) => {
        this.setState({rightpanelvalue:rightpanelvalue});
        }


      render() 
      {
		return (
		  <>
        <div className="workspace-org-module">
            <Header />
            <div className="workspace-org-structure">

                <LeftPanel rightpanelbody={this.handleRightPanelExpand}/>
                <div className={localStorage.getItem("worksidebaserotate") === 'true' ? "workspace_background"
                    : "workspace_background workspace_background-expand"}>

                    <div className="workspace-dashboard-head">
                        <div className="workspace-headings workspace-headings-mob">
                            <div  className="workspace-headings-div">
                                <h2>Dashboard</h2>
                                <p class="workspace-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                    <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                                        <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                                            <circle cx="9" cy="9" r="9" stroke="none" />
                                            <circle cx="9" cy="9" r="8.25" fill="none" />
                                        </g>
                                        <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5" />
                                        <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5" />
                                    </g>
                                </svg><p class="workspace-tooltipsubheader">Your Workspace dashboard is the command center for managing your Workspace. Access key information, track downloads, and monitor essential stats and analytics.</p></p>
                            </div>
                                    <h6 className="workspace-timediv">{this.state.workspacedata && this.state.workspacedata.updated_at ?
											'Updated ' + moment(this.state.workspacedata.updated_at).format("D MMMM YYYY, HH:mm")
										: ''
										}</h6>
                        </div>
                    </div>
                { this.state.spinner ? 
                     <div className="app-icon-section access-loader workspace-loader-div">
                     <div className="newspinner">
                     <svg xmlns="http://www.w3.org/2000/svg" 
                         xlink="http://www.w3.org/1999/xlink" 
                         style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
                         width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                         <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                         <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                         <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                         style={{animationplaystate: "running", animationdelay: "0s"}}>
                         </animateTransform>
                         </circle>
                         </g>
                         <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                         <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
                         <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                         style={{animationplaystate: "running", animationdelay: "0s"}}>
                         </animateTransform>
                         </circle>
                         </g>
                         <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                         <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
                         <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                         style={{animationplaystate: "running", animationdelay: "0s"}}>

                         </animateTransform>
                         </circle>
                         </g>
                         <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                         <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
                         <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                         style={{animationplaystate: "running", animationdelay: "0s"}}>

                         </animateTransform>
                         </circle>
                         </g>
                         </svg>
                   </div>
                 </div>
                :
                    <>
                    <div className="workspace-dashboard">
                        <div className={parseInt(localStorage.getItem("logged_in_user_role_id")) != 4  ? "workspace-dashboard-midsection" : "workspace-dashboard-midsection workspace-dashboard-asuser"}>

                            <div className={parseInt(localStorage.getItem("logged_in_user_role_id")) != 4  ? "workspace-dash-mid-panel" : "workspace-dash-mid-panel workspace-dash-miduser-panel"}>
                                <div className="workspace-app-info">
                                    <div className="workspace-datamain">
                                        <div className="workspace-icon">
                                           
                                            {   this.state.workspacedata && (
                                                this.state.workspacedata.profile_image === "" ||
                                                this.state.workspacedata.profile_image === null ||
                                                this.state.workspacedata.profile_image === "null" ||
                                                this.state.workspacedata.profile_image === undefined ||
                                                this.state.workspacedata.profile_image === "undefined" ||
                                                this.state.workspacedata.profile_image == '' ) ? 
                                                (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                                        <path id="Subtraction_260" data-name="Subtraction 260" d="M24,30a2,2,0,0,1-2-2V24a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2v4a2,2,0,0,1-2,2ZM13,30a2,2,0,0,1-2-2V24a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2v4a2,2,0,0,1-2,2ZM2,30a2,2,0,0,1-2-2V24a2,2,0,0,1,2-2H6a2,2,0,0,1,2,2v4a2,2,0,0,1-2,2ZM24,19a2,2,0,0,1-2-2V13a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2v4a2,2,0,0,1-2,2ZM13,19a2,2,0,0,1-2-2V13a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2v4a2,2,0,0,1-2,2ZM2,19a2,2,0,0,1-2-2V13a2,2,0,0,1,2-2H6a2,2,0,0,1,2,2v4a2,2,0,0,1-2,2ZM24,8a2,2,0,0,1-2-2V2a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2V6a2,2,0,0,1-2,2ZM13,8a2,2,0,0,1-2-2V2a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2V6a2,2,0,0,1-2,2ZM2,8A2,2,0,0,1,0,6V2A2,2,0,0,1,2,0H6A2,2,0,0,1,8,2V6A2,2,0,0,1,6,8Z" transform="translate(0)" fill="#fff" />
                                                    </svg>
                                                ) 
                                                : this.state.workspacedata.profile_image != "" && this.state.workspacedata.profile_image != null ?
                                                (
                                                    <>
                                                        {this.state.workspacedata && this.state.workspacedata.profile_image.indexOf("https") === -1 ? 
                                                        (
                                                            <div
                                                                style={{
                                                                background:
                                                                    "url(" +
                                                                    this.state.profilepath +
                                                                    this.state.workspacedata.profile_image +
                                                                    ")",
                                                                width: "60px",
                                                                borderRadius: "50%",
                                                                height: "60px",
                                                                backgroundRepeat: "no-repeat",
                                                                backgroundSize: "cover",
                                                                }}
                                                                className="proimg profile-pic-img"
                                                            ></div>
                                                        ) 
                                                        : 
                                                        (
                                                            <div
                                                                style={{
                                                                background: "url(" + this.state.workspacedata.profile_image + ")",
                                                                width: "60px",
                                                                borderRadius: "50%",
                                                                height: "60px",
                                                                backgroundRepeat: "no-repeat",
                                                                backgroundSize: "cover",
                                                                }}
                                                                className="proimg profile-pic-img"
                                                            ></div> 
                                                        )}
                                                    </>
                                                    )
                                                :   
                                                <> <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                                    <path id="Subtraction_260" data-name="Subtraction 260" d="M24,30a2,2,0,0,1-2-2V24a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2v4a2,2,0,0,1-2,2ZM13,30a2,2,0,0,1-2-2V24a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2v4a2,2,0,0,1-2,2ZM2,30a2,2,0,0,1-2-2V24a2,2,0,0,1,2-2H6a2,2,0,0,1,2,2v4a2,2,0,0,1-2,2ZM24,19a2,2,0,0,1-2-2V13a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2v4a2,2,0,0,1-2,2ZM13,19a2,2,0,0,1-2-2V13a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2v4a2,2,0,0,1-2,2ZM2,19a2,2,0,0,1-2-2V13a2,2,0,0,1,2-2H6a2,2,0,0,1,2,2v4a2,2,0,0,1-2,2ZM24,8a2,2,0,0,1-2-2V2a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2V6a2,2,0,0,1-2,2ZM13,8a2,2,0,0,1-2-2V2a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2V6a2,2,0,0,1-2,2ZM2,8A2,2,0,0,1,0,6V2A2,2,0,0,1,2,0H6A2,2,0,0,1,8,2V6A2,2,0,0,1,6,8Z" transform="translate(0)" fill="#fff" />
                                                </svg></>
                                                }

                                        </div>
                                        <div className="workspace-data">
                                            <h3>{this.state.workspacedata.workspace_name}</h3>


                                            {/* <h5>{  moment( this.state.workspacedata.created_at).format("D MMMM YYYY, HH:mm") }</h5> */}

                                            <h5 className="workspace-state-id">ID: {this.state.workspacedata.id}</h5>

                                        </div>
                                    </div>
                                </div>
                                <div className="workspace-app-info">
                                    <div className="workspace-datamain">
                                        {/* <div className="workspace-icon">
                                           
                                            {   this.state.workspacedata && (
                                                this.state.workspacedata.profile_image === "" ||
                                                this.state.workspacedata.profile_image === null ||
                                                this.state.workspacedata.profile_image === "null" ||
                                                this.state.workspacedata.profile_image === undefined ||
                                                this.state.workspacedata.profile_image === "undefined" ||
                                                !this.state.workspacedata.profile_image ) ? 
                                                (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                                                        <path id="Subtraction_260" data-name="Subtraction 260" d="M24,30a2,2,0,0,1-2-2V24a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2v4a2,2,0,0,1-2,2ZM13,30a2,2,0,0,1-2-2V24a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2v4a2,2,0,0,1-2,2ZM2,30a2,2,0,0,1-2-2V24a2,2,0,0,1,2-2H6a2,2,0,0,1,2,2v4a2,2,0,0,1-2,2ZM24,19a2,2,0,0,1-2-2V13a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2v4a2,2,0,0,1-2,2ZM13,19a2,2,0,0,1-2-2V13a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2v4a2,2,0,0,1-2,2ZM2,19a2,2,0,0,1-2-2V13a2,2,0,0,1,2-2H6a2,2,0,0,1,2,2v4a2,2,0,0,1-2,2ZM24,8a2,2,0,0,1-2-2V2a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2V6a2,2,0,0,1-2,2ZM13,8a2,2,0,0,1-2-2V2a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2V6a2,2,0,0,1-2,2ZM2,8A2,2,0,0,1,0,6V2A2,2,0,0,1,2,0H6A2,2,0,0,1,8,2V6A2,2,0,0,1,6,8Z" transform="translate(0)" fill="#fff" />
                                                    </svg>
                                                ) 
                                                : 
                                                (
                                                <>
                                                    {this.state.workspacedata && this.state.workspacedata.profile_image.indexOf("https") === -1 ? 
                                                    (
                                                        <div
                                                            style={{
                                                            background:
                                                                "url(" +
                                                                this.state.profilepath +
                                                                this.state.workspacedata.profile_image +
                                                                ")",
                                                            width: "60px",
                                                            borderRadius: "50%",
                                                            height: "60px",
                                                            backgroundRepeat: "no-repeat",
                                                            backgroundSize: "cover",
                                                            }}
                                                            className="proimg profile-pic-img"
                                                        ></div>
                                                    ) 
                                                    : 
                                                    (
                                                        <div
                                                            style={{
                                                            background: "url(" + this.state.workspacedata.profile_image + ")",
                                                            width: "60px",
                                                            borderRadius: "50%",
                                                            height: "60px",
                                                            backgroundRepeat: "no-repeat",
                                                            backgroundSize: "cover",
                                                            }}
                                                            className="proimg profile-pic-img"
                                                        ></div> 
                                                    )}
                                                </>
                                                )}

                                        </div> */}
                                        <div>
                                        { this.state.subscription.website_technology == 2 ?
                                          <img src={ require("../../assets/images/dashboard/new-website.png") .default }/>
                                        : this.state.subscription.website_technology == 4 ?
                                          <img src={ require("../../assets/images/dashboard/new-wordpress.png") .default }/>
                                        : this.state.subscription.website_technology == 5 ?
                                          <img src={ require("../../assets/images/dashboard/new-woocomerce.png") .default }/>
                                        : this.state.subscription.website_technology == 3 ?
                                          <img src={ require("../../assets/images/dashboard/new-custom.png") .default }/>
                                        :   <img src={ require("../../assets/images/dashboard/workspace/new-allinone.png") .default }/>
                                        }
                                        </div>
                                        <div className="workspace-data">
                                        { this.state.subscription.website_technology == 2 ?
                                          <h3>Web to app</h3>
                                        : this.state.subscription.website_technology == 4 ?
                                          <h3>Wordpress app</h3>
                                        : this.state.subscription.website_technology == 5 ?
                                          <h3>Woocommerce app</h3>
                                        : this.state.subscription.website_technology == 3 ?
                                          <h3>Custom app</h3>
                                        : <h3> All-In-One</h3>
                                        }
                                           


                                            <h5 style={{marginBottom: "0px"}}>{  moment( this.state.workspacedata.created_at).format("D MMMM YYYY, HH:mm") }</h5>

                                            {/* <h6>ID: {this.state.workspacedata.id}</h6> */}

                                        </div>
                                    </div>
                                </div>
                                {parseInt(localStorage.getItem("logged_in_user_role_id")) != 4 && parseInt(localStorage.getItem("logged_in_user_role_id")) != 3 ?
                                <div className="workspace-plan-detail wprkspace-mobshow">
                                
                                    <div className="workspace-plan-alignment">

                                        <div className="workspace-plan-section-one">
                                            <h3>Subscription</h3>
                                         
                                            { this.state.subscription.plan_code == 'paddle_preview' || this.state.subscription.plan_code == 'agency_preview' || 
                                                this.state.subscription.plan_code === process.env.REACT_APP_Lifetime_preview ||
                                                this.state.subscription.plan_code === process.env.REACT_APP_STRIPE_PREVIEW ||
                                                this.state.subscription.plan_code === process.env.REACT_APP_zoho_preview ?  
                                            <p>You’re on the free plan. Please upgrade to publish your app and access premium features.</p>
                                            : this.state.subscription.downgrade_at_term_end === 1 && this.state.subscription.package_duration_id != 4 ?
                                            <p>Your existing plan is scheduled for automated cancellation on  {moment( this.state.subscription.billing_date ).format("D MMMM YYYY")}.</p>
                                            : this.state.subscription.package_duration_id != 4 ?
                                            <p>Your existing plan is scheduled for automated renewal on {moment( this.state.subscription.billing_date ).format("D MMMM YYYY")}.</p>
                                            : this.state.subscription.package_duration_id == 4 ?
                                            <p>You’re on the Lifetime plan. Enjoy updates and support without any recurring charges.</p>
                                            : <></>
                                            }
                                                <h6 className="workspace-plan-name">Plan: {this.state.subscription.plan_name}</h6>
                                            {this.state.subscription.gateway_subscription_id && this.state.subscription.gateway_subscription_id != '' ?
                                                <h6>ID: {this.state.subscription.gateway_subscription_id}</h6>
                                                : <></>
                                            }
                                        </div>

                                        <div className="workspace-plan-section-two">
                                            <img className="img-fluid" src={require("../../Agency/assets/images/free-plan.png").default} alt="plan-icon" />
                                            <img className="img-fluid d-none" src={require("../../Agency/assets/images/paid-plan.png").default} alt="plan-icon" />
                                        </div>

                                    </div>

                                    <div className="workspace-status-upgrade">
                                        <p className="workspace-plan-name">{this.state.subscription.status} </p>
                                        <Link 
                                        className = {this.state.subscription.downgrade_at_term_end === 1?
                                            "workspace-reactivate-text":""}
                                        to={`/workspace/pricing/`+btoa(this.state.workspacedata.id)}>{this.state.subscription.downgrade_at_term_end === 1?"Reactivate":"Upgrade"}</Link>
                                    </div>
                                   
                                            {this.state.subscription && this.state.subscription.package_duration_id != '' && this.state.subscription.package_duration_id == 1 ?
                                                 <div className="workspace-discaunt">
                                                    <>
                                                      {this.state.subscription.website_technology && parseInt(this.state.subscription.website_technology) == 5 ?
                                                        <>
                                                        <p><span>Save up to 20%</span> on annual plan.</p>
                                                            {/* {this.state.subscription.plan == 'starter' ?
                                                            <>
                                                                <p><span>Save up to 34%</span> on annual plan.</p>
                                                            </>
                                                            :this.state.subscription.plan == 'pro' ?
                                                            <>
                                                            <p><span>Save up to 20%</span> on annual plan.</p>
                                                            </>
                                                            :this.state.subscription.plan == 'premium' ?
                                                            <>
                                                                <p><span>Save up to 20%</span> on annual plan.</p>
                                                            </>
                                                            :
                                                            <></>
                                                            } */}
                                                       </>
                                                      : this.state.subscription.website_technology && parseInt(this.state.subscription.website_technology) == 1 ?
                                                        <>
                                                        <p><span>Save up to 20%</span> on annual plan.</p>
                                                            {/* {this.state.subscription.plan == 'starter' ?
                                                                <>
                                                                <p><span>Save up to 20%</span> on annual plan.</p>
                                                                </>
                                                            :this.state.subscription.plan == 'pro' ?
                                                                <>
                                                                <p><span>Save up to 14%</span> on annual plan.</p>
                                                                </>
                                                            :this.state.subscription.plan == 'premium' ?
                                                                <>
                                                                    <p><span>Save up to 17%</span> on annual plan.</p>
                                                                </>
                                                            :
                                                                <></>
                                                            } */}
                                                        </>
                                                      :
                                                        <>
                                                        <p><span>Save up to 20%</span> on annual plan.</p>
                                                            {/* {this.state.subscription.plan == 'starter' ?
                                                              <>
                                                                <p><span>Save up to 20%</span> on annual plan.</p>
                                                              </>
                                                            :this.state.subscription.plan == 'pro' ?
                                                              <>
                                                              <p><span>Save up to 25%</span> on annual plan.</p>
                                                              </>
                                                            :this.state.subscription.plan == 'premium' ?
                                                              <>
                                                                  <p><span>Save up to 17%</span> on annual plan.</p>
                                                              </>
                                                            :
                                                              <></>
                                                            } */}
                                                          </>
                                                      }
                                                    </>
                                                  </div>
                                              : <></>
                                              }
                                    
                                </div>
                                : <></>
                                }
                                <div className="workspace-personal-data">
                                    <div>
                                        <img className="img-fluid"
                                            src={require("../../Agency/assets/images/accountprofile.png").default}
                                            alt="profile-picture" />
                                    </div>

                                    <div>
                                        <h2>{this.state.owner_data.profile_name}</h2>
                                        <h5>{this.state.owner_data.email}</h5>
                                        <h6>ID: {this.state.owner_data.id}</h6>
                                    </div>
                                </div>
                            
                            </div>
                            <div className={parseInt(localStorage.getItem("logged_in_user_role_id")) != 4  ? "workspace-app-data" : "workspace-app-data  workspace-user-app-data"}>
                                {parseInt(localStorage.getItem("logged_in_user_role_id")) != 4  ?
                                    <div>
                                        <img src={require("../../Agency/assets/images/customers.png").default} alt="customers" />
                                        <div className="downloaddivstyle">
                                        <h3>{this.state.management_users.length}</h3>
                                        <p>users</p>
                                        </div>
                                        
                                    </div>
                                :   <></>
                                }

                                <div>
                                    <img src={require("../../Agency/assets/images/apps.png").default} alt="apps" />
                                    <div className="downloaddivstyle">
                                    <h3>{this.state.appscount}</h3>
                                    <p>apps</p>
                                    </div>
                                </div>

                                <div>
                                    <img src={require("../../Agency/assets/images/downloads.png").default} alt="downloads" />
                                    <div className="downloaddivstyle">
                                    <h3>0</h3>
                                    <p>downloads</p>
                                    </div>
                                </div>
                            </div>
                            {this.state.appscount > 0  ?

                                <div className="dashboard-bar-graph">
                                    <div className="bar-graph-head">
                                        <h3>Apps</h3>
                                        <>
                                        <Dropdown isOpen={
                                                            window.matchMedia("(max-width: 1200px)").matches
                                                            ? false
                                                            : this.state.openstatDropdown
                                                        }
                                                onClick={
                                                            window.matchMedia("(max-width: 1200px)").matches
                                                        ? this.handleMobileAppDropdown
                                                        : null
                                                        }
                                                toggle={this.handleStatdropdown}
                                        >
                                            <DropdownToggle caret> <span> {this.state.datefilter}</span></DropdownToggle>
                                            <DropdownMenu >
                                                
                                                <DropdownItem onClick={(e) =>this.clickdatafiltervalue(e,"7 days")}>7 days</DropdownItem> 
                                                <DropdownItem onClick={(e) =>this.clickdatafiltervalue(e,"14 days")}>14 days</DropdownItem>
                                                <DropdownItem onClick={(e) =>this.clickdatafiltervalue(e,"30 days")}>30 days</DropdownItem> 
                                                <DropdownItem onClick={(e) =>this.clickdatafiltervalue(e,"60 days")}>60 days</DropdownItem>
                                                <DropdownItem onClick={(e) =>this.clickdatafiltervalue(e,"3 month")}>3 month</DropdownItem>
                                                <DropdownItem onClick={(e) =>this.clickdatafiltervalue(e,"6 month")}>6 month</DropdownItem>
                                                <DropdownItem onClick={(e) =>this.clickdatafiltervalue(e,"12 month")}>12 month</DropdownItem> 
                                            
                                            </DropdownMenu>
                                        </Dropdown>
                                        </>
                                    
                                    </div>
                                    {this.state.graphload ?
                                        <div className={styles.histogramspinner}>
                                            <Spinner className="position-relative" animation="border" variant="primary" />
                                        </div>
                                    :
                                        <>
                                        {
                                            window.matchMedia("(max-width: 600px)").matches
                                            ? <Bar  options={this.state.options} data={this.state.data}  height="320px"  width={"320px"} />
                                            : <Bar  options={this.state.options} data={this.state.data}  height="420px"  width={"1280px"} />
                                        }
                                        </>
                                    }
                                </div>

                                :

                                <div className={parseInt(localStorage.getItem("logged_in_user_role_id")) != 4 ? "no-data-graph" : "no-data-graph workspace-user-app-data"}>

                                <img className="img-fluid" src={require("../../Agency/assets/images/no-data-graph.png") .default} alt="no-data-graph"/>

                                </div>
                                }

                                <div className="workspace-mobile-right">
                                {parseInt(localStorage.getItem("logged_in_user_role_id")) != 4 ?
                            <div className="workspace-org-right-panel">
                                {parseInt(localStorage.getItem("logged_in_user_role_id")) != 4 && parseInt(localStorage.getItem("logged_in_user_role_id")) != 3  ?
                                    <div className="workspace-plan-detail webshow">
                                        <div className="workspace-plan-alignment">

                                            <div className="workspace-plan-section-one">
                                                <h3>Subscription</h3>
                                             
                                                { this.state.subscription.plan_code == 'paddle_preview' || this.state.subscription.plan_code == 'agency_preview' || 
                                                    this.state.subscription.plan_code === process.env.REACT_APP_Lifetime_preview ||
                                                    this.state.subscription.plan_code === process.env.REACT_APP_STRIPE_PREVIEW ||
                                                    this.state.subscription.plan_code === process.env.REACT_APP_zoho_preview ?  
                                                <p>You’re on the free plan. Please upgrade to publish your app and access premium features.</p>
                                                : this.state.subscription.downgrade_at_term_end === 1 && this.state.subscription.package_duration_id != 4 ?
                                                <p>Your existing plan is scheduled for automated cancellation on  {moment( this.state.subscription.billing_date ).format("D MMMM YYYY")}.</p>
                                                : this.state.subscription.package_duration_id != 4 ?
                                                <p>Your existing plan is scheduled for automated renewal on {moment( this.state.subscription.billing_date ).format("D MMMM YYYY")}.</p>
                                                : this.state.subscription.package_duration_id == 4 ?
                                                <p>You’re on the Lifetime plan. Enjoy updates and support without any recurring charges.</p>
                                                : <></>
                                                }
                                                    <h6 className="workspace-plan-name">Plan: {this.state.subscription.plan_name}</h6>
                                                {this.state.subscription.gateway_subscription_id && this.state.subscription.gateway_subscription_id != '' ?
                                                    <h6>ID: {this.state.subscription.gateway_subscription_id}</h6>
                                                    : <></>
                                                }
                                            </div>

                                            <div className="workspace-plan-section-two">
                                                <img className="img-fluid" src={require("../../Agency/assets/images/free-plan.png").default} alt="plan-icon" />
                                                <img className="img-fluid d-none" src={require("../../Agency/assets/images/paid-plan.png").default} alt="plan-icon" />
                                            </div>

                                        </div>

                                        <div className="workspace-status-upgrade">
                                        <p className="workspace-plan-name">{this.state.subscription.status} </p>
                                            <Link 
                                            className = {this.state.subscription.downgrade_at_term_end === 1?
                                                "workspace-reactivate-text":""}
                                           
                                            to={`/workspace/pricing/`+btoa(this.state.workspacedata.id)}>{this.state.subscription.downgrade_at_term_end === 1?"Reactivate":"Upgrade"}</Link>
                                        </div>
                                        
                                        {this.state.subscription && this.state.subscription.package_duration_id != '' && this.state.subscription.package_duration_id == 1 ?
                                                <div className="workspace-discaunt">
                                                    <>
                                                      {this.state.subscription.website_technology && parseInt(this.state.subscription.website_technology) == 5 ?
                                                        <>
                                                        <p><span>Save up to 20%</span> on annual plan.</p>
                                                            {/* {this.state.subscription.plan == 'starter' ?
                                                            <>
                                                                <p><span>Save up to 34%</span> on annual plan.</p>
                                                            </>
                                                            :this.state.subscription.plan == 'pro' ?
                                                            <>
                                                            <p><span>Save up to 20%</span> on annual plan.</p>
                                                            </>
                                                            :this.state.subscription.plan == 'premium' ?
                                                            <>
                                                                <p><span>Save up to 20%</span> on annual plan.</p>
                                                            </>
                                                            :
                                                            <></>
                                                            } */}
                                                       </>
                                                      : this.state.subscription.website_technology && parseInt(this.state.subscription.website_technology) == 1 ?
                                                        <>
                                                        <p><span>Save up to 20%</span> on annual plan.</p>
                                                            {/* {this.state.subscription.plan == 'starter' ?
                                                                <>
                                                                <p><span>Save up to 20%</span> on annual plan.</p>
                                                                </>
                                                            :this.state.subscription.plan == 'pro' ?
                                                                <>
                                                                <p><span>Save up to 14%</span> on annual plan.</p>
                                                                </>
                                                            :this.state.subscription.plan == 'premium' ?
                                                                <>
                                                                    <p><span>Save up to 17%</span> on annual plan.</p>
                                                                </>
                                                            :
                                                                <></>
                                                            } */}
                                                        </>
                                                      :
                                                        <>
                                                        <p><span>Save up to 20%</span> on annual plan.</p>
                                                            {/* {this.state.subscription.plan == 'starter' ?
                                                              <>
                                                                <p><span>Save up to 20%</span> on annual plan.</p>
                                                              </>
                                                            :this.state.subscription.plan == 'pro' ?
                                                              <>
                                                              <p><span>Save up to 25%</span> on annual plan.</p>
                                                              </>
                                                            :this.state.subscription.plan == 'premium' ?
                                                              <>
                                                                  <p><span>Save up to 17%</span> on annual plan.</p>
                                                              </>
                                                            :
                                                              <></>
                                                            } */}
                                                          </>
                                                      }
                                                  </>
                                                </div>
                                              : <></>
                                              }
                                   
                                    </div>
                                :  <></>
                                }
                                <div className="workspace-total-steps">
                                    <div className="workspace-dashboard-progress">
                                        {/* { setupcount === 7 ?
                                                <h2>You're all set<img src={require("../../Agency/assets/images/hurray.png") .default} alt="hurray"/></h2>
                                            : */}
                                       
                                        {this.state.pointcheck == this.state.totalcheckpoint ?
                                           <h2>You’re all set<span><img src={require("../../assets/images/dashboard/success-set.png").default} alt="arrow" /></span></h2>
                                        :  <h2>Let’s set up your Workspace</h2>
                                        }
                                        {/* } */}
                                        <div className="workspace-dashboard-progress-bar">
                                            <div>
                                                <p>{this.state.pointcheck}/{this.state.totalcheckpoint}</p>
                                            </div>
                                            <Progress value={this.state.pointcheck*(100/this.state.totalcheckpoint)}  />
                                        </div>
                                    </div>
                                    <div className="workspace-three-steps">

                                    {/* {parseInt(localStorage.getItem("logged_in_user_role_id")) != 4 ?
                                        <div className="workspace-steps">

                                        {this.state.subscription.plan != 'preview' ?
                                            <>
                                                <a href={`/workspace/pricing/`+btoa(this.state.workspacedata.id)}></a>
                                                <img src={require("../../assets/images/dashboard/arrow-grey.png").default} alt="arrow" />
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                        <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                                            <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86" />
                                                            <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2" />
                                                        </g>
                                                    </svg>

                                                </div>
                                            </>
                                        :
                                            <>
                                                <a href={`/workspace/pricing/`+btoa(this.state.workspacedata.id)}></a>
                                                <img src={require("../../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                        <g id="Group_37698" data-name="Group 37698" transform="translate(-1510 -164)">
                                                            <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#dde2f2"/>
                                                            <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                        </g>
                                                    </svg>

                                                </div>
                                            </>
                                        }
                                            <div>
                                                <h4>Upgrade</h4>
                                                <p>Give your online app business a new home.</p>
                                            </div>
                                        </div>
                                    : <></>
                                    } */}

{/* 
                                        <div className="workspace-steps">
                                            { this.state.workspacedata && (this.state.workspacedata.profile_image != null) && this.state.workspacedata.profile_image != '' ?
                                                <>
                                                    <a href={`/workspace/settings/`+btoa(this.state.workspacedata.id)}></a>
                                                    <img src={require("../../assets/images/dashboard/arrow-grey.png").default} alt="arrow" />
                                                    <div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86" />
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2" />
                                                            </g>
                                                        </svg>

                                                    </div>
                                                </>
                                            :
                                                <>
                                                    <a href={`/workspace/settings/`+btoa(this.state.workspacedata.id)}></a>
                                                    <img src={require("../../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37698" data-name="Group 37698" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#dde2f2"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg>

                                                    </div>
                                                </>
                                            } 
                                            <div>
                                                <h4>Settings</h4>
                                                <p>Customize your preferences with ease.</p>
                                            </div>
                                        </div> */}



                                        <div className="workspace-steps">
                                        { this.state.appscount == 0 ?
                                            <>
                                             
                                                <a href={`/workspace/apps/`+btoa(this.state.workspacedata.id)}></a>
                                                <img src={require("../../assets/images/dashboard/arrow-grey.png").default} alt="arrow" />
                                                <div>
                                                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                                <g id="Group_37698" data-name="Group 37698" transform="translate(-1510 -164)">
                                                                    <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#dde2f2"/>
                                                                    <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                                </g>
                                                            </svg> */}
                                                            <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"></path></svg>

                                                </div>
                                            </>
                                        :
                                                    <>                                                    
                                                        <a href={`/workspace/apps/`+btoa(this.state.workspacedata.id)}></a>
                                                        <img src={require("../../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                                <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                                                    <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86" />
                                                                    <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2" />
                                                                </g>
                                                            </svg>

                                                        </div>
                                                    </>
                                        }
                                            <div>
                                                <h4>Create App</h4>
                                                <p>Create your first app to begin.</p>
                                            </div>
                                        </div>
                                        {parseInt(localStorage.getItem("logged_in_user_role_id")) != 4 ?
                                            <div className="workspace-steps">
                                                { this.state.management_users && (this.state.management_users.length != null) && this.state.management_users.length > 0 ?
                                                    <>
                                                        <a href={`/workspace/team/`+btoa(this.state.workspacedata.id)}></a>
                                                        <img src={require("../../assets/images/dashboard/arrow-grey.png").default} alt="arrow" />
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                                <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                                                    <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86" />
                                                                    <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2" />
                                                                </g>
                                                            </svg>

                                                        </div>
                                                    </>
                                                :
                                                            <>                                                    
                                                                <a href={`/workspace/team/`+btoa(this.state.workspacedata.id)}></a>
                                                                <img src={require("../../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                                <div>
                                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                                        <g id="Group_37698" data-name="Group 37698" transform="translate(-1510 -164)">
                                                                            <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#dde2f2"/>
                                                                            <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                                        </g>
                                                                    </svg> */}
                                                                    <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"></path></svg>

                                                                </div>
                                                            </>
                                                } 
                                                <div>
                                                    <h4>Team Members</h4>
                                                    <p>Add at least one team member.</p>
                                                </div>
                                            </div>
                                        : <></>
                                        }


                                        {/* <div className="workspace-steps-animation"></div> */}

                                    </div>
                                </div>
                                {parseInt(localStorage.getItem("logged_in_user_role_id")) != 4 ?
                                    <div className="workspace-dash-teams">
                                        <a href={`/workspace/team/${btoa(this.state.workspacedata.id)}`}>
                                        <h3>
                                            Team

                                        </h3>
                                        </a>

                                        <div className="workspace-teamlist">

                                        { ( this.state.owner_data === '' || this.state.owner_data === null || this.state.owner_data === undefined || this.state.owner_data === 'null' ) ?

                                            <div className="org-teammember" >
                                                <div className="offonline"></div>
                                            </div>
                                            :
                                            ((this.state.owner_data.image === '' || this.state.owner_data.image === null || this.state.owner_data.image === undefined || this.state.owner_data.image === 'null') ?
                                            <div className="org-teammember org-mamber-images-div" style={{ background: "url(" + require("../../Agency/assets/images/accountprofile.png").default + ")" }} title={`${this.state.owner_data.profile_name}`}>
                                                <div className="online"></div>
                                            </div>

                                            :
                                                ( this.state.owner_data.image.indexOf("https") === -1 ?
                                                    <div className="org-teammember org-mamber-images-div" key="" style={{ background: "url(" + process.env.REACT_APP_Image_Path + this.state.owner_data.user_id + process.env.REACT_APP_Profile + this.state.owner_data.image + ")"}} title={`${this.state.owner_data.profile_name}`} >
                                                        
                                                        <div className="online"></div>                                            
                                                    </div>
                                                :
                                                    <div className="org-teammember org-mamber-images-div" key="" style={{ background: "url(" + this.state.owner_data.image + ")" }} title={`${this.state.owner_data.image}`} >

                                                        <div className="online"></div>                                            
                                                    </div>
                                                )
                                            )
                                        }

                                                { (this.state.management_users !== null && this.state.management_users !== undefined && this.state.management_users.length > 0) ? 
                                                    this.state.management_users.map((userlist, useridx)=>{
                                                        if(userlist.image === '' || userlist.image === null || userlist.image === undefined || userlist.image === 'null')
                                                        {
                                                            return(
                                                            <div className="org-teammember org-mamber-images-div" key={useridx} style={{ background: "url(" + require("../../Agency/assets/images/accountprofile.png").default + ")"}} title={`${userlist.profile_name}`}>
                                                                
                                                                
                                                                { userlist.status === 1 ?
                                                                    <div className="online"></div>                                                    
                                                                :
                                                                    <div className="offonline"></div>
                                                                }
                                                            </div>
                                                            )
                                                        }
                                                        else
                                                        {
                                                            if(userlist.image.indexOf("https") === -1)
                                                            {
                                                                return(
                                                                <div className="org-teammember org-mamber-images-div" key={useridx} style={{ background: "url(" + process.env.REACT_APP_Image_Path + userlist.user_id + process.env.REACT_APP_Profile + userlist.image + ")" }} title={`${userlist.profile_name}`}>
                                                                    
                                                                    { userlist.status === 1 ?
                                                                        <div className="online"></div>
                                                                    :
                                                                        <div className="offonline"></div>
                                                                    }
                                                                </div>
                                                                )
                                                            }
                                                            else
                                                            {
                                                                return(
                                                                <div className="org-teammember org-mamber-images-div" key={useridx} style={{ background: "url(" + userlist.image + ")"}} title={`${userlist.profile_name}`} >

                                                                    { userlist.status === 1 ?
                                                                        <div className="online"></div>
                                                                    :
                                                                        <div className="offonline"></div>
                                                                    }
                                                                </div>
                                                                )
                                                            }
                                                        }
                                                    })
                                                :
                                                    null                                
                                                }






                                            {/*<div className="org-teammember">
                                                    <div className="online"></div>
                                                </div>*/}
                                        </div>
                                    </div>
                                :   <></>
                                }
                            </div>
                        : <></>
                        }
                                </div>
                                <SupportSection
                              supportCardArr={SUPPORTCARDDETAILS} 
                              planName={
                                localStorage.getItem('agencyid') &&  localStorage.getItem('agencyid') == 1 ?
                                  localStorage.getItem('supportplan') && isJSON((localStorage.getItem('supportplan'))) 
                                  ?
                                    isJSON((localStorage.getItem('supportplan'))).plan_name
                                  : 
                                    "Basic" 
                                : ""
                              }
                              planDesc={
                                localStorage.getItem('agencyid') &&  localStorage.getItem('agencyid') == 1 ?
                                localStorage.getItem('supportplan') && isJSON((localStorage.getItem('supportplan'))) 
                                ?
                                  isJSON((localStorage.getItem('supportplan'))).plan_name === "Basic" ?
                                  "Basic plan gives you access to essential support through dedicated support channels."
                                  : isJSON((localStorage.getItem('supportplan'))).plan_name === "Business" ?
                                 "Business plan provides swift response times and advanced troubleshooting. " :
                                isJSON((localStorage.getItem('supportplan'))).plan_name === "Enterprise" ?
                                "Enterprise plan comes with top-tier support and prompt response times. Get a dedicated Success Manager for custom enhancements."
                                :
                                "Basic plan gives you access to essential support through dedicated support channels."
                                :
                                  "Basic plan gives you access to essential support through dedicated support channels." 
                              : ""}
                              expireOn={ localStorage.getItem('agencyid') &&  localStorage.getItem('agencyid') == 1 ?
                                localStorage.getItem('supportplan') && isJSON((localStorage.getItem('supportplan'))) 
                                ?
                                  isJSON((localStorage.getItem('supportplan'))).renewal_date
                                : 
                                  "" 
                              : ""}
                              downgradeAtTermEnd={ 
                                localStorage.getItem('agencyid') &&  localStorage.getItem('agencyid') == 1 ?
                                localStorage.getItem('supportplan') && isJSON((localStorage.getItem('supportplan'))) 
                                ?
                                  isJSON((localStorage.getItem('supportplan'))).downgrade_at_term_end
                                : 
                                  "" 
                              : ""}
                            />
                        </div>
                        <div className="workspace-web-right">
                        {parseInt(localStorage.getItem("logged_in_user_role_id")) != 4 ?
                            <div className="workspace-org-right-panel">
                                {parseInt(localStorage.getItem("logged_in_user_role_id")) != 4 && parseInt(localStorage.getItem("logged_in_user_role_id")) != 3  ?
                                    <div className="workspace-plan-detail webshow">
                                        <div className="workspace-plan-alignment">

                                            <div className="workspace-plan-section-one">
                                                <h3>Subscription</h3>
                                              
                                                { this.state.subscription.plan_code == 'paddle_preview' || this.state.subscription.plan_code == 'agency_preview' || 
                                                    this.state.subscription.plan_code === process.env.REACT_APP_Lifetime_preview ||
                                                    this.state.subscription.plan_code === process.env.REACT_APP_STRIPE_PREVIEW ||
                                                    this.state.subscription.plan_code === process.env.REACT_APP_zoho_preview ?  
                                                <p>You’re on the free plan. Please upgrade to publish your app and access premium features.</p>
                                                : this.state.subscription.downgrade_at_term_end === 1 && this.state.subscription.package_duration_id != 4 ?
                                                <p>Your existing plan is scheduled for automated cancellation on  {moment( this.state.subscription.billing_date ).format("D MMMM YYYY")}.</p>
                                                : this.state.subscription.package_duration_id != 4 ?
                                                <p>Your existing plan is scheduled for automated renewal on {moment( this.state.subscription.billing_date ).format("D MMMM YYYY")}.</p>
                                                : this.state.subscription.package_duration_id == 4 ?
                                                <p>You’re on the Lifetime plan. Enjoy updates and support without any recurring charges.</p>
                                                : <></>
                                                }
                                                    <h6 className="workspace-plan-name">Plan: {this.state.subscription.plan_name}</h6>
                                                {this.state.subscription.gateway_subscription_id && this.state.subscription.gateway_subscription_id != '' ?
                                                    <h6>ID: {this.state.subscription.gateway_subscription_id}</h6>
                                                    : <></>
                                                }
                                            </div>

                                            <div className="workspace-plan-section-two">
                                                <img className="img-fluid" src={require("../../Agency/assets/images/free-plan.png").default} alt="plan-icon" />
                                                <img className="img-fluid d-none" src={require("../../Agency/assets/images/paid-plan.png").default} alt="plan-icon" />
                                            </div>

                                        </div>

                                        <div className="workspace-status-upgrade">
                                        <p className="workspace-plan-name">{this.state.subscription.status} </p>
                                            <Link 
                                            className = {this.state.subscription.downgrade_at_term_end === 1?
                                                "workspace-reactivate-text":""}
                                           
                                            to={`/workspace/pricing/`+btoa(this.state.workspacedata.id)}>{this.state.subscription.downgrade_at_term_end === 1?"Reactivate":"Upgrade"}</Link>
                                        </div>
                                        
                                        {this.state.subscription && this.state.subscription.package_duration_id != '' && this.state.subscription.package_duration_id == 1 ?
                                                <div className="workspace-discaunt">
                                                    <>
                                                      {this.state.subscription.website_technology && parseInt(this.state.subscription.website_technology) == 5 ?
                                                        <>
                                                        <p><span>Save up to 20%</span> on annual plan.</p>
                                                            {/* {this.state.subscription.plan == 'starter' ?
                                                            <>
                                                                <p><span>Save up to 34%</span> on annual plan.</p>
                                                            </>
                                                            :this.state.subscription.plan == 'pro' ?
                                                            <>
                                                            <p><span>Save up to 20%</span> on annual plan.</p>
                                                            </>
                                                            :this.state.subscription.plan == 'premium' ?
                                                            <>
                                                                <p><span>Save up to 20%</span> on annual plan.</p>
                                                            </>
                                                            :
                                                            <></>
                                                            } */}
                                                       </>
                                                      : this.state.subscription.website_technology && parseInt(this.state.subscription.website_technology) == 1 ?
                                                        <>
                                                        <p><span>Save up to 20%</span> on annual plan.</p>
                                                            {/* {this.state.subscription.plan == 'starter' ?
                                                                <>
                                                                <p><span>Save up to 20%</span> on annual plan.</p>
                                                                </>
                                                            :this.state.subscription.plan == 'pro' ?
                                                                <>
                                                                <p><span>Save up to 14%</span> on annual plan.</p>
                                                                </>
                                                            :this.state.subscription.plan == 'premium' ?
                                                                <>
                                                                    <p><span>Save up to 17%</span> on annual plan.</p>
                                                                </>
                                                            :
                                                                <></>
                                                            } */}
                                                        </>
                                                      :
                                                        <>
                                                        <p><span>Save up to 20%</span> on annual plan.</p>
                                                            {/* {this.state.subscription.plan == 'starter' ?
                                                              <>
                                                                <p><span>Save up to 20%</span> on annual plan.</p>
                                                              </>
                                                            :this.state.subscription.plan == 'pro' ?
                                                              <>
                                                              <p><span>Save up to 25%</span> on annual plan.</p>
                                                              </>
                                                            :this.state.subscription.plan == 'premium' ?
                                                              <>
                                                                  <p><span>Save up to 17%</span> on annual plan.</p>
                                                              </>
                                                            :
                                                              <></>
                                                            } */}
                                                          </>
                                                      }
                                                  </>
                                                </div>
                                              : <></>
                                              }
                                   
                                    </div>
                                :  <></>
                                }
                                <div className="workspace-total-steps">
                                    <div className="workspace-dashboard-progress">
                                        {/* { setupcount === 7 ?
                                                <h2>You're all set<img src={require("../../Agency/assets/images/hurray.png") .default} alt="hurray"/></h2>
                                            : */}
                                       
                                        {this.state.pointcheck == this.state.totalcheckpoint ?
                                           <h2>You’re all set<span><img src={require("../../assets/images/dashboard/success-set.png").default} alt="arrow" /></span></h2>
                                        :  <h2>Let’s set up your Workspace</h2>
                                        }
                                        {/* } */}
                                        <div className="workspace-dashboard-progress-bar">
                                            <div>
                                                <p>{this.state.pointcheck}/{this.state.totalcheckpoint}</p>
                                            </div>
                                            <Progress value={this.state.pointcheck*(100/this.state.totalcheckpoint)}  />
                                        </div>
                                    </div>
                                    <div className="workspace-three-steps">

                                    {/* {parseInt(localStorage.getItem("logged_in_user_role_id")) != 4 ?
                                        <div className="workspace-steps">

                                        {this.state.subscription.plan != 'preview' ?
                                            <>
                                                <a href={`/workspace/pricing/`+btoa(this.state.workspacedata.id)}></a>
                                                <img src={require("../../assets/images/dashboard/arrow-grey.png").default} alt="arrow" />
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                        <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                                            <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86" />
                                                            <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2" />
                                                        </g>
                                                    </svg>

                                                </div>
                                            </>
                                        :
                                            <>
                                                <a href={`/workspace/pricing/`+btoa(this.state.workspacedata.id)}></a>
                                                <img src={require("../../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                        <g id="Group_37698" data-name="Group 37698" transform="translate(-1510 -164)">
                                                            <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#dde2f2"/>
                                                            <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                        </g>
                                                    </svg>

                                                </div>
                                            </>
                                        }
                                            <div>
                                                <h4>Upgrade</h4>
                                                <p>Give your online app business a new home.</p>
                                            </div>
                                        </div>
                                    : <></>
                                    } */}

{/* 
                                        <div className="workspace-steps">
                                            { this.state.workspacedata && (this.state.workspacedata.profile_image != null) && this.state.workspacedata.profile_image != '' ?
                                                <>
                                                    <a href={`/workspace/settings/`+btoa(this.state.workspacedata.id)}></a>
                                                    <img src={require("../../assets/images/dashboard/arrow-grey.png").default} alt="arrow" />
                                                    <div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86" />
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2" />
                                                            </g>
                                                        </svg>

                                                    </div>
                                                </>
                                            :
                                                <>
                                                    <a href={`/workspace/settings/`+btoa(this.state.workspacedata.id)}></a>
                                                    <img src={require("../../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                    <div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <g id="Group_37698" data-name="Group 37698" transform="translate(-1510 -164)">
                                                                <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#dde2f2"/>
                                                                <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                            </g>
                                                        </svg>

                                                    </div>
                                                </>
                                            } 
                                            <div>
                                                <h4>Settings</h4>
                                                <p>Customize your preferences with ease.</p>
                                            </div>
                                        </div> */}



                                        <div className="workspace-steps">
                                        { this.state.appscount == 0 ?
                                            <>
                                             
                                                <a href={`/workspace/apps/`+btoa(this.state.workspacedata.id)}></a>
                                                <img src={require("../../assets/images/dashboard/arrow-grey.png").default} alt="arrow" />
                                                <div>
                                                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                                <g id="Group_37698" data-name="Group 37698" transform="translate(-1510 -164)">
                                                                    <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#dde2f2"/>
                                                                    <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                                </g>
                                                            </svg> */}
                                                            <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"></path></svg>

                                                </div>
                                            </>
                                        :
                                                    <>                                                    
                                                        <a href={`/workspace/apps/`+btoa(this.state.workspacedata.id)}></a>
                                                        <img src={require("../../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                                <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                                                    <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86" />
                                                                    <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2" />
                                                                </g>
                                                            </svg>

                                                        </div>
                                                    </>
                                        }
                                            <div>
                                                <h4>Create App</h4>
                                                <p>Create your first app to begin.</p>
                                            </div>
                                        </div>
                                        {parseInt(localStorage.getItem("logged_in_user_role_id")) != 4 ?
                                            <div className="workspace-steps">
                                                { this.state.management_users && (this.state.management_users.length != null) && this.state.management_users.length > 0 ?
                                                    <>
                                                        <a href={`/workspace/team/`+btoa(this.state.workspacedata.id)}></a>
                                                        <img src={require("../../assets/images/dashboard/arrow-grey.png").default} alt="arrow" />
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                                <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                                                    <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86" />
                                                                    <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2" />
                                                                </g>
                                                            </svg>

                                                        </div>
                                                    </>
                                                :
                                                            <>                                                    
                                                                <a href={`/workspace/team/`+btoa(this.state.workspacedata.id)}></a>
                                                                <img src={require("../../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/>
                                                                <div>
                                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                                        <g id="Group_37698" data-name="Group 37698" transform="translate(-1510 -164)">
                                                                            <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#dde2f2"/>
                                                                            <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                                                        </g>
                                                                    </svg> */}
                                                                    <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"></path></svg>

                                                                </div>
                                                            </>
                                                } 
                                                <div>
                                                    <h4>Team Members</h4>
                                                    <p>Add at least one team member.</p>
                                                </div>
                                            </div>
                                        : <></>
                                        }


                                        {/* <div className="workspace-steps-animation"></div> */}

                                    </div>
                                </div>
                                {parseInt(localStorage.getItem("logged_in_user_role_id")) != 4 ?
                                    <div className="workspace-dash-teams">
                                        <a href={`/workspace/team/${btoa(this.state.workspacedata.id)}`}>
                                        <h3>
                                            Team

                                        </h3>
                                        </a>

                                        <div className="workspace-teamlist">

                                        { ( this.state.owner_data === '' || this.state.owner_data === null || this.state.owner_data === undefined || this.state.owner_data === 'null' ) ?

                                            <div className="org-teammember" >
                                                <div className="offonline"></div>
                                            </div>
                                            :
                                            ((this.state.owner_data.image === '' || this.state.owner_data.image === null || this.state.owner_data.image === undefined || this.state.owner_data.image === 'null') ?
                                            <div className="org-teammember org-mamber-images-div" style={{ background: "url(" + require("../../Agency/assets/images/accountprofile.png").default + ")"}} title={`${this.state.owner_data.profile_name}`}>
                                                <div className="online"></div>
                                            </div>

                                            :
                                                ( this.state.owner_data.image.indexOf("https") === -1 ?
                                                    <div className="org-teammember org-mamber-images-div" key="" style={{ background: "url(" + process.env.REACT_APP_Image_Path + this.state.owner_data.user_id + process.env.REACT_APP_Profile + this.state.owner_data.image + ")" }} title={`${this.state.owner_data.profile_name}`} >
                                                        
                                                        <div className="online"></div>                                            
                                                    </div>
                                                :
                                                    <div className="org-teammember org-mamber-images-div" key="" style={{ background: "url(" + this.state.owner_data.image + ")"}} title={`${this.state.owner_data.image}`} >

                                                        <div className="online"></div>                                            
                                                    </div>
                                                )
                                            )
                                        }

                                                { (this.state.management_users !== null && this.state.management_users !== undefined && this.state.management_users.length > 0) ? 
                                                    this.state.management_users.map((userlist, useridx)=>{
                                                        if(userlist.image === '' || userlist.image === null || userlist.image === undefined || userlist.image === 'null')
                                                        {
                                                            return(
                                                            <div className="org-teammember org-mamber-images-div" key={useridx} style={{ background: "url(" + require("../../Agency/assets/images/accountprofile.png").default + ")"}} title={`${userlist.profile_name}`}>
                                                                
                                                                
                                                                { userlist.status === 1 ?
                                                                    <div className="online"></div>                                                    
                                                                :
                                                                    <div className="offonline"></div>
                                                                }
                                                            </div>
                                                            )
                                                        }
                                                        else
                                                        {
                                                            if(userlist.image.indexOf("https") === -1)
                                                            {
                                                                return(
                                                                <div className="org-teammember org-mamber-images-div" key={useridx} style={{ background: "url(" + process.env.REACT_APP_Image_Path + userlist.user_id + process.env.REACT_APP_Profile + userlist.image + ")" }} title={`${userlist.profile_name}`}>
                                                                    
                                                                    { userlist.status === 1 ?
                                                                        <div className="online"></div>
                                                                    :
                                                                        <div className="offonline"></div>
                                                                    }
                                                                </div>
                                                                )
                                                            }
                                                            else
                                                            {
                                                                return(
                                                                <div className="org-teammember org-mamber-images-div" key={useridx} style={{ background: "url(" + userlist.image + ")"}} title={`${userlist.profile_name}`} >

                                                                    { userlist.status === 1 ?
                                                                        <div className="online"></div>
                                                                    :
                                                                        <div className="offonline"></div>
                                                                    }
                                                                </div>
                                                                )
                                                            }
                                                        }
                                                    })
                                                :
                                                    null                                
                                                }






                                            {/*<div className="org-teammember">
                                                    <div className="online"></div>
                                                </div>*/}
                                        </div>
                                    </div>
                                :   <></>
                                }
                            </div>
                        : <></>
                        }
                        </div>
                    </div>
                    </>
                }

                </div>

            </div>
        </div>
        <Modal
                className="protip-popup-message"
                isOpen={this.state.openMobileAppDropdown}
                centered
              >
                <ModalBody>
                  <div className="mobileglobalsearch">
                    <button onClick={this.handleMobileAppDropdown}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.759"
                        height="15.408"
                        viewBox="0 0 20.759 15.408"
                      >
                        <g
                          id="Group_27465"
                          data-name="Group 27465"
                          transform="translate(-19.742 -22.295)"
                        >
                          <path
                            id="Path_81245"
                            data-name="Path 81245"
                            d="M-13783.922-19217h19.346"
                            transform="translate(13805.077 19247)"
                            fill="none"
                            stroke="#7782a1"
                            stroke-width="2"
                          />
                          <path
                            id="Path_82232"
                            data-name="Path 82232"
                            d="M14053.656,19255.426l-7,7,7,7"
                            transform="translate(-14025.504 -19232.424)"
                            fill="none"
                            stroke="#7782a1"
                            stroke-width="2"
                          />
                        </g>
                      </svg>
                    </button>
                   
                  </div>

                  <div className="mobilesearchsuggestion email-mob-popup">
                        <p
                             className="mobilesearchsuggestion-para"
                              onClick={(e) =>this.clickdatafiltervalue(e,"7 days")}
                        >7 Days</p>
                         <p
                              className="mobilesearchsuggestion-para"
                              onClick={(e) =>this.clickdatafiltervalue(e,"14 days")}
                        >14 Days</p>
                         <p
                             className="mobilesearchsuggestion-para"
                              onClick={(e) =>this.clickdatafiltervalue(e,"30 days")}
                        >30 Days</p>
                         <p
                             className="mobilesearchsuggestion-para"
                              onClick={(e) =>this.clickdatafiltervalue(e,"60 days")}
                        >60 Days</p>
                         <p
                              className="mobilesearchsuggestion-para"
                              onClick={(e) =>this.clickdatafiltervalue(e,"3 month")}
                        >3 month</p>
                         <p
                              className="mobilesearchsuggestion-para"
                              onClick={(e) =>this.clickdatafiltervalue(e,"6 month")}
                        >6 month</p>
                         <p
                             className="mobilesearchsuggestion-para"
                              onClick={(e) =>this.clickdatafiltervalue(e,"12 month")}
                        >12 month</p>

                    
                  </div>
                </ModalBody>
              </Modal>
        </>
        );
      }
}