import React, { useState, useEffect, Suspense } from "react";
import "./../../assets/css/Dashboard.css";
import "./../../assets/css/profileleftpanel.css";
import "./../../assets/css/appicon.css";
import Header from "./../Header";
import Leftpanel from "./../Leftpanel";
import { Link} from "react-router-dom";
//import { Spinner, Media } from "reactstrap";
import {
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
  } from "reactstrap";
import {
    GetNotificationIcon,
	SaveUploadNotificationIcon,
} from "../../Api/RichNotification/RichNotificationApi";
import {
    ExtractColor,
  } from "../../Api/Dashboard/DashboardApi";
import Select from "react-select";
import { Notaddonactive, UpgradeNotification } from "./UpgradeNotification";
import moment from "moment";
import VistaPopup from "./../Appearance/VistaPopup";
import NewImageGalleryPopup from "./../NewImageGalleryPopup";
//import { InnerFoot } from "../Foot";
//import Table from "./Table";
//import { Tabledata } from "./Context";
const Multisitealert = React.lazy(() => import("./Multisitealert"));



export default class NotificationIcon  extends React.PureComponent {

    state = {
        isVistaPopup : false,
        mdlprotip : false,
        mdlicondownload: false,
        mdlimage : false,
        finalfile:"",
        designid:"",
        isGalleryPopup:false,
        is_publish: 0,
        hidegridebutton:true,
        savespinner:false,
        spinner:true,
        finalfile:"",
        original_icon:"",
        is_default:1,
        last_updated_timestamp:'',
        mdlvistatip:false,
        rebuildpopvalue:false,
        appRightPanelExpand: localStorage.getItem("appSideBarCollapse") === true ||
        localStorage.getItem("appSideBarCollapse") === "true" ? true : false,
        ios: true,
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
        {
          document.title = "App Icon | AppMySite";
    
        }
        else
        {
            document.title = "App Icon |"+ ' ' +localStorage.getItem('agencyname');
        }
       
        this.stopmounting = true;
    
        GetNotificationIcon(this);

        if (parseInt(localStorage.getItem("website_technology")) !== 3) 
        {
            ExtractColor(this);
        }
        
    }

    openProtipModal = () =>
    {
        //setIsVistaPopup(false);
        //setMdlProTip(!mdlprotip);

        this.setState({
            isVistaPopup: false,
            mdlprotip: !this.state.mdlprotip
        });
        
    }

    handleVistaPopup = (e) =>
    {
        // this.setState({isGalleryPopup:false});
 
        if((window.matchMedia("(min-width: 1200px)").matches))
        { 
            //this.setState({isVistaPopup:!this.state.isVistaPopup});
            //setIsVistaPopup(!isvistapopup);

            this.setState({
                isVistaPopup: !this.state.isVistaPopup
            }); 
        }
        else
        {
            //setIsVistaPopup(false);
            //setMdlVistaTip(!mdlvistatip);
            this.setState({
                isVistaPopup: false,
                mdlvistatip: !this.state.mdlvistatip
            });
            //this.setState({isVistaPopup:false});
            //this.setState({mdlvistatip:!this.state.mdlvistatip});
        }
    }

    handleAppUploadimgPopUp=()=>
    {
        //setIsVistaPopup(false);
        //setIsGalleryPopup(!isgallerypopup);

        this.setState({
            isVistaPopup: false,
            isGalleryPopup: !this.state.isGalleryPopup
        });

        /*this.setState({isVistaPopup:false});
        this.setState({
            isGalleryPopup:!this.state.isGalleryPopup
        })*/
    }

    handleBtnDone = () =>
    {
        //setIsVistaPopup(false);
        //setMdlProTip(false);

        this.setState({
            isVistaPopup: false,
            mdlprotip: !this.state.mdlprotip
        });

        //this.setState({isVistaPopup:false});
        //this.setState({mdlprotip:false});
    }

    handlegridbutton=()=>
    {
        //setIsVistaPopup(false);
        //setHideGrideButton(!hidegridebutton);

        this.setState({
            isVistaPopup: false,
            hidegridebutton: !this.state.hidegridebutton
        });

        /*this.setState({isVistaPopup:false});
        this.setState({
            hidegridebutton:!this.state.hidegridebutton
        })*/
    }

    arrowclick = (e) =>
    {
        //setIsVistaPopup(false);

        this.setState({
            isVistaPopup: false
        });

        //this.setState({isVistaPopup:false});

        if(e === 'left')
        {
            document.querySelector(".icon-left-arow").classList.add("arrow-disabled");
            document.querySelector(".icon-right-arow").classList.remove("arrow-disabled");
            document.getElementsByClassName('the-icon-width')[0].classList.remove("the-icon-width-slide");
            document.getElementsByClassName('the-icon-width')[1].classList.remove("the-icon-width-slide");
            // document.querySelector('.icon-slide-1').classList.remove('d-none');
            setTimeout(
                () => document.querySelector('.icon-slide-2').classList.add('d-none'), 
                1000
            );
    
        }
        else if( e === 'right')
        {
            document.querySelector(".icon-right-arow").classList.add("arrow-disabled");
            document.querySelector(".icon-left-arow").classList.remove("arrow-disabled");
            document.getElementsByClassName('the-icon-width')[0].classList.add("the-icon-width-slide");
            document.getElementsByClassName('the-icon-width')[1].classList.add("the-icon-width-slide");
            // document.querySelector('.icon-slide-1').classList.add('d-none');
            setTimeout(
                () => document.querySelector('.icon-slide-2').classList.remove('d-none'), 
                0
            );
        }
    }

    openIconPopup=() =>
    {
        if(window.matchMedia("(max-width: 1200px)").matches)
        {
            //setIsVistaPopup(false);
            //setMdlIconDownload(!mdlicondownload);

            this.setState({
                isVistaPopup: false,
                mdlicondownload: !this.state.mdlicondownload
            });
            //this.setState({isVistaPopup:false});
            //this.setState({mdlicondownload:!this.state.mdlicondownload});
        }
    }

    handleVistaDone = () =>
    {
        //setIsVistaPopup(false);
        //setMdlVistaTip(false);

        this.setState({
            isVistaPopup: false,
            mdlvistatip: !this.state.mdlvistatip
        });
        //this.setState({isVistaPopup:false});
        //this.setState({mdlvistatip:false});
    }

    handlemobileVistaPopup = () =>
    {
        //setIsVistaPopup(true);
        this.setState({
            isVistaPopup: false,
        });
        //this.setState({isVistaPopup:true});
    }

    handleBtnImageClose=() =>
    {
        //setIsVistaPopup(false);
        //setMdlImage(false);

        this.setState({
            isVistaPopup: false,
            mdlimage: !this.state.mdlimage
        });
        //this.setState({isVistaPopup:false});
        //this.setState({mdlimage:false});
    }

    handleBtnImageDownload = () =>
    {
    
    }

    loadpic = (data) => 
    {
        
        
        this.setState({ finalfile: data.url, designid: data.id, is_default: 0 });

        this.setState({isVistaPopup:!this.state.isVistaPopup});
        this.setState({isGalleryPopup:false});

        /*setFinalFile(data.url);
        
        setDesignID(data.id);
        
        setIsDefault(0);*/
        //this.setState({finalfile:data.url,designid:data.id,is_default:0});
        //this.setState({isVistaPopup:!this.state.isVistaPopup});
        //this.setState({isGalleryPopup:false});
 
        const params = {
                app_id: parseInt(localStorage.getItem("Appid")),
                design_id: this.state.designid !== '' && this.state.designid !== undefined && this.state.designid !== null ? this.state.designid : '',
                upload_icon: this.state.finalfile,
                original_icon: this.state.original_icon,
                is_android_rebuild_required: this.state.is_publish.android_success_build_count > 0 ? 1 : 0,
                is_ios_rebuild_required: this.state.is_publish.ios_success_build_count > 0 ? 1 : 0,
                website_technology: this.state.website_technology,
        };

        

        if(this.state.is_publish.android_success_build_count > 0 || this.state.is_publish.ios_success_build_count > 0)
        {
            //setRebuildPopupValue(!rebuildpopvalue);
            this.setState({rebuildpopvalue:!this.state.rebuildpopvalue});
            //this.setState({rebuildpopvalSaveUploadAppIcon(params, this);
        }

        SaveUploadNotificationIcon(params, this);
        //SaveUploadNotificationIcon(params, setSpinner, setUpdatedTimestamp);
        
        if(document.querySelector(".crello-closeBtn"))
        {
            
            //document.querySelector(".crello-closeBtn").click();

            var popupbtnLink = document.querySelector('.crello-closeBtn');
            popupbtnLink.click();

        }

        /*setIsVistaPopup(!isvistapopup);
        setIsGalleryPopup(false);
        setVistapopupOpen(true);*/
    };

    settingYourAppLogodata=(imgName,from)=>
    {
        //setIsVistaPopup(false);
        this.setState({ isVistaPopup: false });
        //this.setState({isVistaPopup:false});
  
        if(from==="resize")
        {
            this.setState({
                is_default:0,
                finalfile: imgName,
                upload_icon: imgName,
                designid:"",
            });

            //setIsDefault(0);
            //setFinalFile(imgName);
            //setUploadIcon(imgName);
            //setDesignID("");
            /*this.setState({
                            is_default:0,
                            finalfile: imgName,
                            upload_icon: imgName,
                            designid:"",
                        })*/
        }
        else
        {
            //setOriginalIcon(imgName);
            //setDesignID("");

            this.setState({
                original_icon:imgName,
                designid:"",
                // finalfile: imgName,
            });
        }
    }

    closeVistaCreatePopUp = () =>{

    }

    yourErrFun = () =>{

    }

    createsubmit = (e) => {

        this.setState({isVistaPopup:false});
        this.setState({savespinner:true});
    
        
        if(this.state.is_publish.android_success_build_count > 0 || this.state.is_publish.ios_success_build_count > 0)
        {
            this.setState({isVistaPopup:false});
            this.setState({savespinner:false});
            this.setState({rebuildpopvalue:!this.state.rebuildpopvalue});
            return;
        }
    
        const params = {
            app_id: parseInt(localStorage.getItem("Appid")),
            design_id:this.state.designid !== '' && this.state.designid !== undefined && this.state.designid !== null ? this.state.designid : '',
            upload_icon: this.state.finalfile,
            original_icon: this.state.original_icon,
            is_android_rebuild_required: this.state.is_publish.android_success_build_count > 0 ? 1 : 0,
            is_ios_rebuild_required: this.state.is_publish.ios_success_build_count > 0 ? 1 : 0,
            website_technology: this.state.website_technology,
        };
    
        SaveUploadNotificationIcon(params, this);
    }


    render() {

        if(!this.state.isVistaPopup)
        {
            if(document.querySelector(".crello-closeBtn") !== null)
            {
                document.querySelector(".crello-closeBtn").click();
            }
        }

        
        

        return (
        <>
            <div className="d-md-block">
                <Header customclass={"header-responsive-new"} />
            </div>
            {/* Mobile View */}
            {/* Mobile View End*/}
            <section className="dasboard_page newmobile-display">
                <Leftpanel ipadView={"initial-leftpanel-ipadview"} ipadLeftclass={"ipadview-leftscroll"} />

                <div className="right-panel-gap" >                    

                    <div className="mob-upgradenotificationpage os-icon-mob-div">
                        <div className='web-notification-head' style={{marginBottom: "0px"}}>
                            <h1>Advanced notifications
                                <p className=" consent-tooltip">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                                                <circle cx="9" cy="9" r="9" stroke="none" />
                                                <circle cx="9" cy="9" r="8.25" fill="none" />
                                            </g>
                                            <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5" />
                                            <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5" />
                                        </g>
                                    </svg>
                                    <p className="consent-tooltipsubheader">
                                    Send out updates and engaging messages to your app users.{" "}
                                    </p>
                                </p>
                            </h1>
                        </div>


                        <div className="notification-modal-tab">
                            <div className="notificationlink" style={{ height: "33px" }}>                
                                <Link to="/app/advanced-notifications">Notifications</Link>
                                <Link to="/app/advanced-notifications/subsets">Group</Link>
                                <Link  to="/app/advanced-notifications/audience">Users</Link>
                                <Link className="active" to="/app/rich-notifications/icon">Icon</Link>                
                            </div>
                            <div className='date-and-savediv'>
                                
                                {this.state.last_updated_timestamp !== '' && this.state.last_updated_timestamp !== undefined ?
                                    <p className='consent-save-date'>Updated { moment(this.state.last_updated_timestamp).format("D MMMM YYYY, HH:mm")}{" "}</p>

                                :
                                    null
                                }

                                {this.state.finalfile !== '' ?
                                    <button type="submit" className="consent-login-save-enable" style={{ backgroundColor: localStorage.getItem('button_background_color'), color: localStorage.getItem('button_text_color'), "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'), "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor') }} onClick={() => this.createsubmit()} >
                                        {this.state.savespinner ? 
                                            <div className="d-flex align-items-center justify-content-center h-100  w-100">
                                                <div className="new-loader"></div>
                                            </div>
                                        :
                                            'Save'
                                        }
                                    </button>
                                :
                                    <button className="icon-btn-disable" style={{backgroundColor: "#BCC1CE", cursor: "not-allowed"}}>Save</button>
                                }
                            </div>
                            
                        </div>
                    
                        <div className="row notification-icon-page">
                            {this.state.spinner ? (
                                <div className="newspinner">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                        <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                            <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                </animateTransform>
                                            </circle>
                                        </g>
                                        <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                            <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                                                style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                </animateTransform>
                                            </circle>
                                        </g>
                                        <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                            <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                                                style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                </animateTransform>
                                            </circle>
                                        </g>
                                        <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                            <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                                                style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                </animateTransform>
                                            </circle>
                                        </g>
                                    </svg>
                                </div>
                            ) 
                            : 
                            (
                                <>
                                    <div className="right_general notificationtab">
                                        <div className="mob-pushnotification-platform">
                                            <div className="app-icon-section notification-app-icon">
                                            <>
                                                <div className="app-icon-spark">
                                                    <svg onClick={ this.openProtipModal } xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                                        <g id="Group_37044" data-name="Group 37044" transform="translate(-351 -714.999)">
                                                            <g id="Group_37045" data-name="Group 37045" transform="translate(351 715)">
                                                                <path id="_211696_bolt_icon_2_" data-name="211696_bolt_icon (2)" d="M150.3,64,143,74.245h4.382l-1.349,7.318,7.3-10.245h-4.382L150.3,64Z" transform="translate(-135.562 -59.883)" fill=""/>
                                                                <g id="Ellipse_7748" data-name="Ellipse 7748" transform="translate(0 -0.001)" fill="none" stroke="" stroke-width="1.5">
                                                                    <circle cx="12.5" cy="12.5" r="12.5" stroke="none"/>
                                                                    <circle cx="12.5" cy="12.5" r="11.75" fill="none"/>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                    <p className="protiptooltip">Pro tip</p>

                                                    <Modal className="protip-popup-message" isOpen={ this.state.mdlprotip } centered>          
                                                        <ModalBody>
                                                            <div className="protip-popup"> 
                                                                <div className="protip-popup-one">  
                                                                    <h4>Pro tip</h4>
                                                                    <h6>Level-up your creation with these tips and best practices.</h6>
                                                                    <ul>
                                                                        <li>Choose a design that’s easy to recognise and stands out on your user’s mobile device.</li>  
                                                                        <li>Think of your app icon as an icon with a background, and not a picture.</li>
                                                                        <li>Text within your icon can be too small to read.</li>
                                                                        <li>Avoid including your app’s name in your icon since your app name is displayed below. </li>
                                                                        <li>Create a design which depicts your brand’s purpose or function.</li>
                                                                        <li>Avoid getting too close to the edges of the icon.</li>
                                                                        <li>Brands evolve, and icons evolve with them. Make gradual changes so that users continue to relate to your app.</li>
                                                                    </ul>
                                                                    <button onClick={ this.handleBtnDone }>Okay</button>
                                                                </div>  

                                                                <div className="protip-popup-two">
                                                                    <img src={require("./../../assets/images/dashboard/protip-img.png").default} className="img-fluid" alt="icon-preview"/>
                                                                </div>
                                                            </div>

                                                            <div className="crossmodal mt-1">
                                                                <img onClick={ this.handleBtnDone } src={ require("./../../assets/images/dashboard/cross.png").default }/>
                                                            </div>
                                                        </ModalBody>
                                                    </Modal>
                                                </div>

                                                <div className="app-icon-section-width">
                                                    <div  className= { this.state.is_default === 1 ? "app-icon-section-one" : ( this.state.finalfile !== '' &&  (window.matchMedia("(max-width: 1200px)").matches)) ? "app-icon-section-one d-none" : "app-icon-section-one" }>
                                                        <div className="app-icon-section-one-width">
                                                            <h2>Create a memorable notification icon</h2>
                                                            <p>First impressions matter. Design an elegant and recognizable icon that stands out, and clearly represents your app’s purpose.</p>
                                                            <p>Your app users will click on your app icon to launch your app from their mobile devices.</p>

                                                            <div className="app-icon-buttons">
                                                                <span  onClick={ this.handleVistaPopup } style={{backgroundColor:localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor')}}>{ this.state.designid === '' || this.state.designid === undefined || this.state.designid === null ? 'Start designing' : 'Edit design'}</span>
                                                                <span  onClick={ this.handleAppUploadimgPopUp } style={{"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color')}}>or upload an image</span>
                                                            </div>

                                                            <div className="app-icon-options">
                                                                <span  onClick={ this.handleAppUploadimgPopUp }>or upload an image</span>
                                                                {(localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || 
                                                                localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || 
                                                                localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1) ?
                                                                <a className="ask-an-expert" href="/app/addons"><img src={require("./../../assets/images/dashboard/diamond.png").default} alt="diamond"/> Ask an expert</a>
                                                                : 
                                                                    <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="app-icon-section-two">
                                                        {/* <p onClick={()=>this.openIconPopup()}>Hello</p> */}
                                                        <>
                                                            { this.state.is_default === 1 ?
                                                                <img className="img-fluid" src={require("./../../assets/images/dashboard/pushicon.png").default} alt="App-icon-default"/>
                                                            :
                                                                <>
                                                                    <div className="apperance-slider">             

                                                                        <div className="the-icon-slider">                   
                                
                                                                            <div className="the-icon-width">
                                                                                <div className="icon-slide-2">
                                                                                <div className="text-center">
                                                                                    <div className="tab-content push-notify">
                                                                                        <div role="tabpanel" className="tab-pane fade in active show newmobile_bg" id="ios" >
                                                                                            {/*<div className={ ios ? " notimobimg" : " notimobimg" } >
                                                                                                {ios ? 
                                                                                                (
                                                                                                    <img src={ require("../../assets/images/dashboard/newapplemobilepreview.png").default } className="img-fluid" alt="speaker" />
                                                                                                ) 
                                                                                                : 
                                                                                                (
                                                                                                    <img src={ require("../../assets/images/dashboard/newandroidmobilepreview.png").default } className="img-fluid" alt="speaker" />
                                                                                                )}
                                                                                            </div>*/}
                                                                                            <div className={ this.state.ios ? " notimobimg" : " notimobimg" } >
                                                                                                <img src={ require("../../assets/images/dashboard/newapplemobilepreview.png").default } className="img-fluid" alt="speaker" />
                                                                                            </div>

                                                                                            <div className="push-newnotifications push-newnotificationsandroid" style={{ top: "232px" }}>
                                                                                                <div className="d-flex align-items-center justify-content-between">
                                                                                                    {this.state.ios ? 
                                                                                                        (
                                                                                                            <>
                                                                                                                <div className="d-flex align-items-center justify-content-start">
                                                                                                                    
                                                                                                                    <img className="rounded" src={ this.state.finalfile !== '' && this.state.finalfile.includes('create.vista.com') ? this.state.finalfile : this.state.finalfile !== '' && this.state.finalfile.includes('notification') ? process.env.REACT_APP_Image_Path + localStorage.getItem("user_id") + "/" + localStorage.getItem("Appid") + process.env.REACT_APP_Notification_Media + this.state.finalfile : ''} width="28px" height="28px" alt="Appicon" />
                                                                                                                        
                                                                                    
                                                                                                                </div>

                                                                                                                <div className="iosnotify-time">
                                                                                                                    <div className="newnotification-title">
                                                                                                                        <h2>Flash sale starts tonight!</h2>
                                                                                                                        <p>The sale starts tonight. Grab heavy discounts on all products.</p>   
                                                                                                                        
                                                                                                                    </div>

                                                                                                                    <div className="d-grid">
                                                                                                                        <p className="schedulestatus">
                                                                                                                            now
                                                                                                                        </p>
                                                                                                                        
                                                                                                                        <img className="message-offerimg" src={ require("../../assets/images/dashboard/offer-image.png").default }  alt="Goto" />
                                                                                                                        
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                        ) 
                                                                                                        : 
                                                                                                            null
                                                                                                        
                                                                                                    }
                                                                                                    </div>

                                                                                                    {/* android &&
                                                                                                    <div className="newnotification-title mt-2">
                                                                                                        { title !== '' && title !== null && title !== undefined ?
                                                                                                            <h2>{title}</h2>
                                                                                                        :
                                                                                                            <h2>Flash sale starts tonight!</h2>
                                                                                                        }

                                                                                                        { message !== '' && message !== null && message !== undefined ?
                                                                                                            <p>{message}</p>
                                                                                                        :
                                                                                                            <p>The sale starts tonight. Grab heavy discounts on all products.</p>
                                                                                                        }
                                                                                                    </div>
                                                                                                    */}
                                                                                                </div>
                                                                                            </div>
                                                                                    </div>

                                                                                   
                                                                                </div>
                                                                                    {/*<div className="app-icon-preview">
                                                                                        <img src={require("./../../assets/images/dashboard/new-mobile-screen.png").default} className="img-fluid" alt="icon-preview"/>
                                                                                        <div className="app-icon-small">
                                                                                            <div className="app-icon-small-preview">
                                                                                                <img src={ this.state.finalfile !== '' && this.state.finalfile.includes('create.vista.com') ? this.state.finalfile : this.state.finalfile !== '' && this.state.finalfile.includes('ams') ? process.env.REACT_APP_Image_Path + localStorage.getItem("user_id") + "/" + localStorage.getItem("Appid") + process.env.REACT_APP_Notification_Media + this.state.finalfile : ''} className="img-fluid" onError={({ currentTarget }) => {
                                                                                                    currentTarget.src = process.env.REACT_APP_Image_Path + localStorage.getItem("user_id") + "/" + localStorage.getItem("Appid") + process.env.REACT_APP_Notification_Media + this.state.finalfile;
                                                                                                }} alt="icon"/>
                                                                                            </div>
                                                                                            <p>{localStorage.getItem('Appname')}</p>
                                                                                        </div>
                                                                                    </div>*/}

                                                                                    <Link className="edit-design-mobile-link" to="#" onClick={ this.handleVistaPopup }>{ this.state.designid === '' || this.state.designid === undefined || this.state.designid === null ? 'Start designing' : 'Edit design'}</Link> 
                                                                                    <div className="app-icon-options d-flex d-xl-none">
                                                                                        <span  onClick={ this.handleAppUploadimgPopUp }>or upload an image</span>
                                                                                        {(localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1) ?
                                                                                            <a className="ask-an-expert" href="/app/addons"><img src={require("./../../assets/images/dashboard/diamond.png").default} alt="diamond"/> Ask an expert</a>
                                                                                        : 
                                                                                            <></>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                        </>
                                                    </div>
                                                </div>
                                            </>
                                            </div>                                         
                                        </div>
                                    </div>
                                </>
                            )
                            }
                        </div>
                    </div>
                        {/* {InnerFoot()} */}
                </div>
            </section>

            <Modal className="no-editor" isOpen={this.state.mdlvistatip} centered>
                <ModalBody>
                    <div className="no-editor-popup">
                        <h4>Small screen detected</h4>
                        <h6>Please login from a device with a larger screen such as a laptop or desktop, for a better design and editing experience. </h6>
                        <button onClick={ this.handleVistaDone }>Okay</button>
                        <span onClick={ this.handlemobileVistaPopup }>Proceed anyway</span>

                    </div>

                    <div className="crossmodal mt-1">
                        <img onClick={ this.handleVistaDone} src={ require("./../../assets/images/dashboard/cross.png").default }/>
                    </div>

                </ModalBody>
            </Modal>

            <Modal className="download-icon-popup-modal" isOpen={this.state.mdlicondownload}>
                <ModalBody>
                    <div className="download-icon-popup"> 
                        <img className="img-fluid downloaded-icon" src={ this.state.finalfile !== '' && this.state.finalfile.includes('create.vista.com') ? this.state.finalfile : this.state.finalfile !== '' && this.state.finalfile.includes('ams') ? process.env.REACT_APP_Image_Path + localStorage.getItem("user_id") +"/" + localStorage.getItem("Appid") + process.env.REACT_APP_Notification_Media + this.state.finalfile : ''} alt="App-icon-default"/>

                        <div className="download-icon-popup-footer">
                            <svg onClick={ this.openIconPopup } xmlns="http://www.w3.org/2000/svg" width="21.414" height="21.414" viewBox="0 0 21.414 21.414">
                                <g id="Group_37047" data-name="Group 37047" transform="translate(-53.793 -664.793)">
                                    <path id="Path_101682" data-name="Path 101682" d="M-13508.229-19301.85l-20,20" transform="translate(13582.729 19967.35)" fill="none" stroke="#fff" stroke-width="2"/>
                                    <path id="Path_101683" data-name="Path 101683" d="M-13528.229-19301.85l20,20" transform="translate(13582.729 19967.35)" fill="none" stroke="#fff" stroke-width="2"/>
                                </g>
                            </svg>
                            <a href={ this.state.finalfile !== '' && this.state.finalfile.includes('create.vista.com') ? this.state.finalfile : this.state.finalfile !== '' && this.state.finalfile.includes('ams') ? process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/" + localStorage.getItem("Appid") + process.env.REACT_APP_Notification_Media + this.state.finalfile : ''} target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="26.103" height="25.242" viewBox="0 0 26.103 25.242">
                                    <g id="Group_36045" data-name="Group 36045" transform="translate(0.5 1)">
                                        <g id="Group_36042" data-name="Group 36042" transform="translate(12.551)">
                                            <g id="Group_36048" data-name="Group 36048">
                                                <line id="Line_368" data-name="Line 368" y1="17.216" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" stroke-width="2"/>
                                            </g>
                                        </g>
                                        <path id="Path_99648" data-name="Path 99648" d="M3.5,6l6.9,6.886L17.273,6" transform="translate(2.165 4.674)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" stroke-width="2"/>
                                        <g id="Group_36044" data-name="Group 36044" transform="translate(0.5 16.355)">
                                            <g id="Group_36047" data-name="Group 36047">
                                                <path id="Path_99649" data-name="Path 99649" d="M24.6,10v6.886H.5V10" transform="translate(-0.5 -10)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" stroke-width="2"/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </a>

                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={ this.state.mdlimage } style={{ maxWidth: "600px", maxHeight: "247px" }}  centered>
                <ModalBody>
                    <div className="code-popup-body">             
                        <div className="crossmodal mt-1">
                            {/* <img src={ require("../assets/images/dashboard/cross.png").default }/> */}
                        </div>
                        <h4>Image Popup</h4>
                        <h6>
                        In case you don't have access to your authenticator app, you can use one of these codes to log in. Each code can only be used once. Please make sure you copy them and store them somewhere safe.
                        </h6>
                    </div>
                    <div className="code-popup-footer">
                        <button onClick={ this.handleBtnImageClose }>Cross</button>
                        <button onClick={ this.handleBtnImageDownload }>Download</button>
                    </div>
                </ModalBody>
            </Modal>

            {this.state.isVistaPopup ? 
                <VistaPopup width={1024} height={1024} projectname="Notification icon" downloadformat="pngTransparent" designid={this.state.designid} formatkey="logoMM" loadpicfunction={ this.loadpic } closefunction=""  />
            : 
                null
            }
                
            {
                this.state.isGalleryPopup && 
                    <NewImageGalleryPopup
                        isGalleryPopup={ this.state.isGalleryPopup }
                        setIsGalleryPopup={true}
                        uploadPath={ localStorage.getItem("user_id") +"/"+localStorage.getItem("Appid") + process.env.REACT_APP_Notification_Media.replace(/\/$/,"") }
                        settingYourdata={ this.settingYourAppLogodata }
                        yourErrFun={ this.yourErrFun }
                        settingOrgImgFrom3rdParty={false}
                        hideImageGallery={true}
                        widthForRestriction={256}
                        heightForRestriction={256}
                        aspectRatio={"256:256"}
                        classCompoState={this}
                        isNotCustomUploadOrgImg={true}
                        designid={ this.state.designid }
                        loadpicVistaCreatePopUp={ this.loadpic }
                        closeVistaCreatePopUp={ this.closeVistaCreatePopUp }
                    />
            }
        </>
        );
    }
}
