import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiEndPoints from "../utils/apiEndpoints";

export const customCustomerApiSlice = createApi({
  reducerPath: "customCustomer",
  keepUnusedDataFor: 120,
  baseQuery: fetchBaseQuery({
    baseUrl: apiEndPoints.cmsUrl,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      const token = localStorage.getItem("amstoken");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    showCustomer: builder.query({
      query: ({
        page = 1,
        limit = 20,
        filters = null, // default to null instead of empty string
        searchInput = "",
        sorting_key = "",
        sorting_value = 2,
      }) => {
        const filterParams = filters ? new URLSearchParams(filters).toString() : "";
        const appId = localStorage.getItem("Appid");
        const appIdEncoded = appId ? btoa(appId) : "";

        let url = `get-customers-list/${appIdEncoded}?page=${page}&limit=${limit}`;

        if (searchInput) {
          url += `&search_filter=${encodeURIComponent(searchInput)}`;
        }

        if (filterParams) {
          url += `&${filterParams}`;
        }

        if (sorting_key) {
          url += `&sorting_key=${sorting_key}&sorting_value=${sorting_value}`;
        }

        return url;
      },
    }),
    blockUnblockCustomer: builder.mutation({
      query: (newData) => ({
        url: "update-customers-status",
        method: "POST",
        body: newData,
      }),
    }),
  }),
});

export const {
  useShowCustomerQuery,
  useBlockUnblockCustomerMutation,
} = customCustomerApiSlice;
