import JoditEditor, { Jodit } from "jodit-react";
import React, { useState } from "react";
import { useRef } from "react";
const config = {
  readonly: false,

  height: "240px",
  width: "100%",
  toolbarAdaptive: false,
  toolbarButtonSize: "middle",
  colorPickerDefaultTab: "background",
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  defaultActionOnPaste: "insert_only_text",
  // processPasteHTML: false,
  // pasteHTMLActionList: ["p"],
  textIcons: false,
  uploader: { url: "none" },
  placeholder: 'Add descriptive text about your product or service.',
  link: {
    openInNewTabCheckbox: false,
    noFollowCheckbox: false,
    modeClassName: "",
  },
  popup: {
    a: "",
  },
  // events: {
  //   paste: (e) => (isPaste = true),
  // },
  buttons: [
    // "fontsize",
    "bold",
    "italic",
    "underline",
    // "brush",
    "align",
    "ul",
    "ol",
    "link",
  ],
  controls: {
    // fontsize: {
    //   list: {
    //     9: "9",
    //     10: "10",
    //     11: "11",
    //     12: "12",
    //     13: "13",
    //     14: "14",
    //     15: "15",
    //     16: "16",
    //     17: "17",
    //     18: "18",
    //     19: "19",
    //     20: "20",
    //   },
    // },
  },
  // uploader: { insertImageAsBase64URI: true },
};
const Editor = ({ value, setValue, handleEditorText }) => {
  // let isPaste = 0;
  // function preparePaste(jodit) {
  //   jodit.e.on(
  //     "paste",
  //     (e) => {
  //       handleEditorText(e.clipboardData.getData("text/plain"), true);
  //       // isPaste++;
  //       // return;
  //     },
  //     { top: true }
  //   );
  // }
  // Jodit.plugins.add("onPaste", preparePaste);

  const editor = useRef(null);
  return (
    <div>
      <JoditEditor
        id="editor-data2"
        value={value}
        ref={editor}
        onChange={(content) => handleEditorText(content, false)}
        config={config}
      />
      {/*      
      <p>{value}</p> */}
    </div>
  );
};

export default Editor;
