import React from "react";
import validationicon from "../../../assets/images/dashboard/validationicon.svg";
const RequiredError = () => {
  return (
    <div className="d-flex profile-validation">
       <img src={validationicon}/>

      <p className="profile-redinput">Required</p>
    </div>
  );
};

export default RequiredError;
