import React from "react";
import {
  Countrylist,
  BillingApi,
  proratedCalculation
} from "../../Api/Dashboard/DashboardApi";
import {
  GetSupportprofileApi,
  GetSupportPlanApi,
  makepayment,
  submitstripepayment,
  submitpaddlepayment,
  updatepayment
} from "../../Api/Dashboard/SupportPlanApi";
import { Addcard } from "../../Api/Paymentmethod/Paymentapi";
import styles from "./checkout.css";
import Header from "../Header";
import { errortoast } from "../Toaster";
import {
  Media,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
const SUPPORT_SUBSCRIPTION_ID = localStorage.getItem("support_subscription_id");

export default class SupportCheckout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addondata: [],
      checkspinner: true,
      packagedetail: [],
      plan_code: "",
      submitval: "Make payment",
      loader: "d-none",
      loaderimage: require("../../assets/images/signup/Loader_GIF.gif").default,
      //billing code
      first_name: "",
      last_name: "",
      userid:"",
      customer_id:"",
      company_name: "",
      billing_address: "",
      billing_city: "",
      billing_state: "",
      billing_zip: "",
      billing_country: "",
      billing_country_code:"",
      customer_billing_type:"",
      email: "",
      selected_addon_code: [],
      countryselectfield: [],
      payment_methods:[],
      add_user_quantity: null,
      addCardLoader: "d-none",
      addCardText: "Add a card",
      paddleTab: "checkout",
      gateway_subscription_id:'',
      paymentMethodID:'',
      pm_link:'',
      monthdata:[],
      searchAppVal:'',
      ams_order_id:'',
      openAppDropdown:false,
      openAppDropdown1:false,
      isPlan:0,
      payment_method_id: "",
      planID:atob( this.props.match.params.planID),
    };
    this._isMounted = false;
   
  }

  handleAppdropdown = () => {
   
    this.setState({
      openAppDropdown: !this.state.openAppDropdown,
    });
  };
  handleAppdropdown1 = () => {
   
    this.setState({
      openAppDropdown1: !this.state.openAppDropdown1,
    });
  };

  componentDidMount() {
    
    
    var isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') == -1 &&
    navigator.userAgent.indexOf('FxiOS') == -1;

    

    if(localStorage.getItem('reload') === null && (!(isSafari)))
    {
      localStorage.setItem('reload',true);
      window.location.reload();
    }

    const Paddle = window.Paddle;

    if 
    (
      window.location.host.indexOf("localhost") > -1 ||
      window.location.host.indexOf("test.app") > -1 ||
      window.location.host.indexOf("stage.app") > -1 ||
      window.location.host.indexOf("dev.app") > -1
    ) 
    {
      window.Paddle.Environment.set("sandbox");
    }
    window.Paddle.Setup({
      vendor: parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID), 
    });

    localStorage.removeItem('checkoutpageamount');
    localStorage.removeItem('checkoutsubscriptionid');
    localStorage.removeItem('checkoutcustomerid');
    
    Countrylist(this);
    GetSupportprofileApi(this);
    GetSupportPlanApi(this,()=>{
      const paramforprorated = {
        app_id:null,
        workspace_id:null,
        agency_id:null,
        plan_code:atob( this.props.match.params.planID),
        addon_code:"",
        addon_quantity: "",
        app_count:0 ,
        user_count:0,
        is_support_plan:1,
      };
  
      proratedCalculation(paramforprorated, this);
    });
 
  }

  componentWillUnmount() {
    this._isMounted = false;
    
    window.Paddle.Setup({
      vendor: parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID), 
    
    });
  }

  handlecountryname = (isovalue,id) =>
  {
    this.setState({
      billing_country_code: isovalue,
      billing_country:id,
      paddleTaxrate:'',
    });
  }

  makepayment = () =>
  {
      let billingparam = 
      {
        app_id: "",
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        company_name: this.state.company_name,
        billing_address: this.state.billing_address,
        billing_city: this.state.billing_city,
        billing_state: this.state.billing_state,
        billing_zip: this.state.billing_zip,
        billing_country: this.state.billing_country,
        tax_number: this.state.tax_number,
      };
            //Payment Api
            BillingApi(billingparam, this, () => {
              const params = {
                price_id:this.state.planID,
                payment_method_id: this.state.subscription_id === "" ? "payment_method_id": this.state.payment_method_id,
              }

              if(this.state.subscription_id === "") {
                submitstripepayment(params,this);
              } else {
                if(this.state.customer_billing_type == 2 && this.state.payment_methods.length === 0)
                  {
                    this.addcardbutton();
                  }
                  else
                  {
                      if(this.state.customer_billing_type == 2 || this.state.customer_billing_type == 1)
                      {
                        if(this.state.subscription_id && this.state.subscription_id != '')
                        {
                          updatepayment(params,this); 
                        }
                        else
                        {
                          submitstripepayment(params,this);
                        }
                      }
                  }
              }
             

              if(this.state.customer_billing_type == 3)
              {
                const params ={
                  price_id:this.state.planID,
                  payment_method_id:"",
                }
                
                if(this.state.subscription_id && this.state.subscription_id != '')
                {
                  updatepayment(params,this); 
                }
                else
                {
                  submitpaddlepayment(params,this);
                }
                
              }

            });

  }



  loadPaddleCheckout = (data) => 
  {
    
    this.setState({
      paddleTab: "payment",
      loader: "d-none",
      submitval: "Make payment",
    });
  };

  checkoutComplete(data, isPlan) 
  {

    var metaData = 
    {
      customer_id: this.state.customer_id,
      email: this.state.email,
      userid: this.state.userid,
    };
    
        var order_amount = (data.checkout.prices.vendor.total - data.checkout.prices.vendor.total_tax).toFixed(2);
  
          localStorage.setItem('checkoutpageamount',order_amount);
          localStorage.setItem('checkoutsubscriptionid',data.checkout.id);
          localStorage.setItem('checkoutcustomerid',this.state.customer_id);
        if(this.state.customer_id &&  this.state.customer_id != '' && this.state.customer_id != null && this.state.customer_id != 'null')
        {
          localStorage.setItem('checkoutcustomerid',this.state.customer_id);
        }
        else
        {
          localStorage.setItem('checkoutcustomerid',this.state.userid);
        }
      window.location.href = "/transaction-successful/support";
  }

  openPaddleCheckout = (overrideURL, ams_order_id) => {
    
    let passthroughObj;
  
    passthroughObj = 
    {
      ams_order_id: ams_order_id,
      user_id: parseInt(localStorage.getItem("logged_in_user_id")),
    };

    window.Paddle.Setup({
      vendor: parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID), 
    });

  window.Paddle.Checkout.open({
    country: this.state.countryselectfield
      ? this.state.countryselectfield[
          parseInt(this.state.billing_country) - 1
        ].country_code
      : "",
    postcode: this.state.billing_zip,
    passthrough: passthroughObj,
    override: overrideURL,
    successCallback: (data) => this.checkoutComplete(data, this.state.isPlan),
    closeCallback: this.checkoutClosed,
    method: "inline",
    loadCallback: this.loadPaddleCheckout(),
    frameTarget: "paddle-payment", // The className of your checkout <div>
    frameInitialHeight: 416,
    inputStyle: "background-color: #FF0000;",
    frameStyle:
      "width:100%; min-width:312px; background-color: transparent; border: none;", // Please ensure the minimum width is kept at or above 286px with checkout padding disabled, or 312px with checkout padding enabled. See "General" section under "Branded Inline Checkout" below for more information on checkout padding.
  });
};

checkoutClosed(data) {
  this.setState({
    loader: "d-none",
    submitval: "Make Payment",
  });
  errortoast(
    "Your purchase has been canceled, we hope to see you again soon!"
  );

  // alert("Your purchase has been canceled, we hope to see you again soon!");
}

  handlebillingstate = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value.replace(/[^a-zA-Z0-9 ]/g, ""),
    });
  };

  handleformfunction = (e) => {
    const { name, value} = e.target;
    if (name === "tax_number") {
      this.setState({
        [name]: value
          .replace(/[`~!@#$%^&*(){}_|+/[\-=?;:'",.<>\\]/gi, "")
          .replace("]", ""),
      });
    } 
    else
     {
      if(name === 'billing_country')
      {
        this.getcountryname();
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index];
        var isovalue =  optionElement.getAttribute('isovalue');
     
        this.setState({
          billing_country_code: isovalue,
          paddleTaxrate:'',
        });
      }
      this.setState({
        [name]: value,
      });
    }
  };

  getpaymentmethod = (paymentmethodid) =>
  {
  
    if(this.state.payment_methods.length > 0)
    {
      var lop = this.state.payment_methods.filter((item)=>
      {
        
          if(parseInt(item.id) == parseInt(paymentmethodid))
          {
          
            return item
          }
      
      })
        
        if(lop && lop.length > 0 )
        {
          return `XXXX XXXX XXXX `+lop[0].card_last4_digit;
        }
      
    }
    else
    {
      return `XXXX XXXX XXXX `;
    }

  }

  setpaymentmethodid = (i, id, cerddigit, expiry_month, expiry_year) =>
  {


    this.setState({payment_method_id:id});
    this.comparedate(i, id, cerddigit, expiry_month, expiry_year);

  } 

  comparedate = (i, id, cerddigit, expiry_month, expiry_year) => {
    let today = new Date();
    let someday = new Date().setFullYear(expiry_year, expiry_month, 1);

    if (someday < today) 
    {
      return (
        <DropdownItem  style={{ color: "#FF4848" }} disabled>
          {" "}
          {`*****${cerddigit} Expired ${expiry_month}/${expiry_year}`}{" "}
        </DropdownItem>
      );
    }
    else if(id == this.state.payment_method_id)
    {
      return (
        <DropdownItem key={i} value={id} disabled>
          {" "}
          {`*****${cerddigit} Expires ${expiry_month}/${expiry_year}`}{" "}
        </DropdownItem>
      );
    } 
    else 
    {
      return (
        <DropdownItem key={i} value={id}  onClick={(e) =>
          this.setpaymentmethodid(i, id, cerddigit, expiry_month, expiry_year)
        }>
          {" "}
          {`*****${cerddigit} Expires ${expiry_month}/${expiry_year}`}{" "}
        </DropdownItem>
      );
    }
  };

  addcardbutton = () => 
  {
    // return;
    if(this.state.tax_number !== '')
    {
      if(this.state.company_name === '')
      {
        errortoast('Please enter company name');
        return false;
      }
    }

    if(this.state.billing_country === '')
    {
        errortoast('Please select country');
        return false;
    }

    const params = 
    {
      path: window.location.pathname,
    };
    

    let billingparam = {
        app_id: '',
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        company_name: this.state.company_name,
        billing_address: this.state.billing_address,
        billing_city: this.state.billing_city,
        billing_state: this.state.billing_state,
        billing_zip: this.state.billing_zip,
        billing_country: this.state.billing_country,
        tax_number: this.state.tax_number,
    }

    this.setState({
      addCardLoader: "d-block",
      addCardText: "",
    });

      BillingApi(billingparam, this, () => {
        Addcard(params, this);
      });
  };

  getcountryname = (countryid) =>
  {

    var lop = this.state.countryselectfield.filter((item)=>
    {

        if(parseInt(item.id) == parseInt(countryid))
        {
        
          return item.country_name
        }
    
    })

    if(lop && lop != '' && lop.length > 0)
    {
      return lop[0].country_name;
    }
    else
    {
      return '';
    }
    

  }


  render() 
  {
    
    if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
		{
      document.title = this.state.paddleTab === "checkout"? "Checkout | AppMySite" : "Payment | AppMySite";

		}
		else
		{
      document.title = "Checkout |"+ ' ' +localStorage.getItem('agencyname');
		}
   

    return (
      <>
        <div className=" ">
          <Header />
        </div>        
        <section className="dasboard_page mobile-display mt-4 mt-md-0 new-checkout-page" style={{maxWidth: "100%"}}>
          {this.state.checkspinner ? (
                  <div className="newspinner">
                  <svg xmlns="http://www.w3.org/2000/svg" 
            xlink="http://www.w3.org/1999/xlink" 
            style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
            width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
              <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
              style={{animationplaystate: "running", animationdelay: "0s"}}>
              </animateTransform>
            </circle>
            </g>
            <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
            style={{animationplaystate: "running", animationdelay: "0s"}}>
            </animateTransform>
            </circle>
            </g>
            <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
            style={{animationplaystate: "running", animationdelay: "0s"}}>

            </animateTransform>
            </circle>
            </g>
            <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
            style={{animationplaystate: "running", animationdelay: "0s"}}>

            </animateTransform>
            </circle>
            </g>
            </svg>
                </div>
          ) 
          : 
          (
            <>
              <div className="transparent_background addonpage payment-last-step">

                <div className="checkout-page-head">
                    {(this.state.gateway_subscription_id == '' && this.state.customer_billing_type == 3) ?
                      <h1>
                        {this.state.paddleTab === "checkout"
                        ? "Checkout"
                        : "Payment"}
                      </h1>
                    :  <h1>
                        {this.state.paddleTab === "checkout"
                        ? "Checkout"
                        : "Payment"}
                      </h1>
                    }
                  { (this.state.gateway_subscription_id === '') && this.state.customer_billing_type === 3 && 
                  (
                    <h2><span className={this.state.paddleTab == "checkout"? "checkout-active-underline" : "checkout-active"}  onClick={() =>
                      this.setState({
                        paddleTab: "checkout",
                      })
                    }>Checkout</span> {'>'} <span className={this.state.paddleTab == "checkout"? "" : "checkout-active-underline"}>Payment</span></h2>
                  )}
                </div>
                  <>
                    {this.state.paddleTab === "checkout" 
                    ? (
                          <div className="checkout-page">
                        
                            <div className="checkout-billing-details">
                              <h4>Billing details</h4>
                              <h5>Please provide your details to complete the checkout process.</h5>

                              <div className="checkout-double">
                                <div className="checkout-half">
                                  <h6>First name*</h6>
                                  <input type="text"  required name="first_name" value={this.state.first_name}
                                      onChange={(e) =>
                                        this.handleformfunction(e)
                                      }
                                      readOnly={this.state.subscription_id !== '' && this.state.customer_billing_type === 3 ? true : false} 
                                      />
                                </div>

                                <div className="checkout-half">
                                  <h6>Last name*</h6>
                                  <input type="text" value={this.state.last_name}
                                                  required
                                                  name="last_name"
                                                  onChange={(e) =>
                                                    this.handleformfunction(e)
                                                  }
                                                  readOnly={this.state.subscription_id !== '' && this.state.customer_billing_type === 3 ? true : false} 
                                                  />
                                </div>
                              </div>

                              <div className="checkout-single">
                                <div className="checkout-full">
                                  <h6>Company</h6>
                                  <input type="text"
                                                  maxLength="30"
                                                  name="company_name"
                                                  value={this.state.company_name}
                                                  onChange={(e) =>
                                                    this.handleformfunction(e)
                                                  }
                                                  readOnly={this.state.subscription_id !== '' && this.state.customer_billing_type === 3 ? true : false} 
                                                  />
                                </div>
                              </div>

                              <div className="checkout-single">
                                <div className="checkout-full">
                                  <h6>Tax ID</h6>
                                  <input type="text" maxLength="30"
                                                  name="tax_number"
                                                  value={this.state.tax_number}
                                                  onChange={(e) =>
                                                    this.handleformfunction(e)
                                                  }
                                                  readOnly={this.state.subscription_id !== '' && this.state.customer_billing_type === 3 ? true : false} 
                                                  />
                                </div>
                              </div>

                              <div className="checkout-single">
                                <div className="checkout-full">
                                  <h6>Address*</h6>
                                  <input type="text" required
                                                name="billing_address"
                                                value={this.state.billing_address}
                                                onChange={(e) => this.handleformfunction(e)}
                                                readOnly={this.state.subscription_id !== '' && this.state.customer_billing_type === 3 ? true : false} 
                                                />
                                </div>
                              </div>

                              <div className="checkout-double">
                                <div className="checkout-half">
                                  <h6>City*</h6>
                                  <input type="text" required
                                                  name="billing_city"
                                                  value={this.state.billing_city}
                                                  onChange={(e) =>
                                                    this.handlebillingstate(e)
                                                  }
                                                  readOnly={this.state.subscription_id !== '' && this.state.customer_billing_type === 3 ? true : false} 
                                                  />
                                </div>

                                <div className="checkout-half">
                                  <h6>State/Region*</h6>
                                  <input type="text" required
                                                  name="billing_state"
                                                  value={this.state.billing_state}
                                                  onChange={(e) =>
                                                    this.handlebillingstate(e)
                                                  }
                                                  readOnly={this.state.subscription_id !== '' && this.state.customer_billing_type === 3 ? true : false} 
                                                  />
                                </div>
                              </div>

                              <div className="checkout-single postal-code">
                                <div className="checkout-full">
                                  <h6>Postal code*</h6>
                                  <input type="text" required
                                                  name="billing_zip"
                                                  maxLength="10"
                                                  value={this.state.billing_zip}
                                                  onChange={(e) =>
                                                    this.handleformfunction(e)
                                                  }
                                                  readOnly={this.state.subscription_id !== '' && this.state.customer_billing_type === 3 ? true : false} 
                                                  />
                                </div>
                              </div>

                              <div className="checkout-single">
                                <div className="checkout-full">
                                  <h6>Country*</h6>
                                  
                                  <div className="checkout-country">
                                  <Dropdown isOpen={this.state.openAppDropdown}
                                      onClick={this.handleMobileAppDropdown}
                                        toggle={this.handleAppdropdown}
                                      >
                                        <DropdownToggle caret disabled={this.state.subscription_id !== '' && this.state.customer_billing_type === 3 ? true : false}>
                                        {this.state.billing_country != '' ?   
                                          this.getcountryname(this.state.billing_country)
                                        :'Select Country'}
                                        </DropdownToggle>
                                        <DropdownMenu >
                                        

                                        <FormGroup>
                                        <div className="dropdown-search">
                                          <input placeholder="Search..." type="text"  name="billing_country" 
                                          disabled={this.state.subscription_id !== '' && this.state.customer_billing_type === 3 ? true : false}  
                                          onChange={(e)=>{
                                            this.setState({
                                              searchAppVal:e.target.value
                                            })
                                          }}
                                          ></input>
                                          {this.state.searchAppVal.trim() && <p onClick={(e)=>{
                                          this.setState({
                                            searchAppVal:""
                                          })
                                        }}>Clear</p>}
                                        </div>
                                      </FormGroup>
                                      {
                                              this.state.countryselectfield && this.state.countryselectfield.filter((row1) =>
                                              row1.country_name
                                                .toLowerCase()
                                                .includes(this.state.searchAppVal.toLowerCase())
                                            ).map((res)=>{
                                                return(
                                                  <DropdownItem  disabled={this.state.subscription_id !== '' && this.state.customer_billing_type === 3 ? true : false}  onClick={(e) => this.handlecountryname(res.country_code,res.id)} value={res.id} isovalue={res.country_code} >{res.country_name}</DropdownItem>
                                                )
                                              })
                                            }
                                      
                                        </DropdownMenu>
                                      </Dropdown>
                                    </div>
                                </div>
                              </div>

                            </div>
                            <div className="checkout-purchase-detail">
                            {SUPPORT_SUBSCRIPTION_ID === "" ? null : (
                            <>
                            {(this.state.customer_billing_type === 2 || this.state.customer_billing_type === 1) && (
                                <div className="checkout-payment-method">
                                  <div className="checkout-payment-method-head">
                                    <h4>Purchase method</h4>

                                     {(this.state.customer_billing_type === 2 || this.state.customer_billing_type === 1) && (
                                        <>
                                            {this.state.payment_methods.length > 0 && (
                                              <>
                                            <h6 onClick={(e) => this.addcardbutton(e)}><svg id="Group_3055" data-name="Group 3055" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                            <g id="Group_1664" data-name="Group 1664" transform="translate(0 6.001)">
                                              <line id="Line_85" data-name="Line 85" y2="12" transform="translate(12 0) rotate(90)" fill="none" stroke="#3064f9" stroke-width="1.5"/>
                                            </g>
                                            <g id="Group_1665" data-name="Group 1665" transform="translate(5.959) rotate(90)">
                                              <line id="Line_85-2" data-name="Line 85" y2="12" transform="translate(12) rotate(90)" fill="none" stroke="#3064f9" stroke-width="1.5"/>
                                            </g>
                                          </svg>
                                          Add card <img className="img-fluid" src={require("../../assets/images/dashboard/link.png").default} alt="link" /></h6>
                                          </>
                                          )}
                                        </>
                                      )}
                                  </div>
                                    {(this.state.customer_billing_type === 2 || this.state.customer_billing_type === 1) && (
                                      <div className="checkout-country">
                                        <Dropdown isOpen={this.state.openAppDropdown1}
                                            onClick={this.handleMobileAppDropdown}
                                              toggle={this.handleAppdropdown1}
                                            >
                                              <DropdownToggle caret>
                                              {this.state.payment_methods.length > 0 && this.state.payment_method_id != '' ?   
                                                this.getpaymentmethod(this.state.payment_method_id)
                                              :'Select Payment Method'}
                                              </DropdownToggle>
                                              <DropdownMenu >
  
                                              {this.state.payment_methods.length === 0 && (
                                                      <DropdownItem>No card to select </DropdownItem>
                                                  )}
  
                                            {this.state.payment_methods.map((res, i) =>
                                                  this.comparedate(i,res.id,res.card_last4_digit,res.expiry_month,res.expiry_year))}  
                                                                              
                                              </DropdownMenu>
                                            </Dropdown>
                                          </div>
                                      )}
                                     
                                </div>
                                )}
                                </>
                              )}

                                {this.state.customer_billing_type === 3 && this.state.pm_link && this.state.pm_link != ''  ?
                                  <div className="checkout-payment-method">
                                      <div className="checkout-payment-method-head">
                                        <h4>Purchase method</h4>
                                        <>
                                          {this.state.pm_link && this.state.pm_link != '' ?
                                              <>
                                              <a href= {(JSON.parse(this.state.pm_link)).update_pm_link} target="_blank">
                                                  <h6>Update <img className="img-fluid" src={require("../../assets/images/dashboard/link.png").default} alt="link" /></h6>
                                              </a>
                                              </>
                                          : 
                                          <></>
                                          }
                                        </>
                                      </div>

                                      <div className="show-card-number-preview">
                                          <h3>XXXX  XXXX  XXXX  XXXX</h3>
                                      </div>
                                
                                  </div>
                                : <></>
                                }

                                <div className="checkout-purchase-summary">
                                    {this.state.customer_billing_type === 3 ? 
                                        <>
                                          <h4>Purchase details</h4>

                                          
                                              <>
                                                  <div className="purchase-summary-sections">
                                                  { this.state.monthdata && this.state.monthdata.length > 0 && this.state.monthdata.map((res)=>(
                                                      res.plan_code == this.state.planID
                                                      ?
                                                        (
                                                          <>
                                                            <h2>{res.plan_name}</h2>
                                                            <h3>${res.amount}</h3>
                                                          </>
                                                        )
                                                      :
                                                        <>
                                                        </>
                                                  ))}
                                                     
                                                    
                                                  </div>
                                              
                                                    
                                                  {/* <div className="purchase-summary-sections">
                                                        <h2>Applied credits </h2>
                                                        <h3>
                                                      
                                                        </h3>
                                                  </div> */}
                                                  {/* <div className="purchase-summary-sections">
                                                  <h2>Tax </h2>
                                                  <h3>
                                                
                                                </h3>
                                                </div> */}
                                                            
                                                <div className="purchase-summary-total">
                                                  <h2>Total</h2>
                                                  { this.state.monthdata && this.state.monthdata.length > 0 && this.state.monthdata.map((res)=>(
                                                      res.plan_code == this.state.planID
                                                      ?
                                                        (
                                                          <>
                                                            <h3>${res.amount}</h3>
                                                          </>
                                                        )
                                                      :
                                                        <>
                                                        </>
                                                  ))}
                                                </div>
                                              </>
                                            
                                        </> 
                                      :
                                        <> 
                                            <h4>Purchase details</h4>
                                            
                                                  <div className="purchase-summary-sections">
                                                      { this.state.monthdata && this.state.monthdata.length > 0 && this.state.monthdata.map((res)=>(
                                                          res.plan_code == this.state.planID
                                                          ?(
                                                            <>
                                                              <h2>{res.plan_name}</h2>
                                                              <h3>${res.amount}</h3>
                                                            </>
                                                            )
                                                          :
                                                            <>
                                                            </>
                                                      ))}
                                                     
                                                  </div>
                                                  
                                                  {/* <div className="purchase-summary-sections">
                                                    <h2>Applied credits </h2>
                                                    <h3>
                                                    </h3>
                                                  </div> */}
                                                  <div className="purchase-summary-total">
                                                    <h2>Total</h2>
                                                    { this.state.monthdata && this.state.monthdata.length > 0 && this.state.monthdata.map((res)=>(
                                                      res.plan_code == this.state.planID
                                                      ?
                                                        (
                                                          <>
                                                            <h3>${res.amount}</h3>
                                                          </>
                                                        )
                                                      :
                                                        <>
                                                        </>
                                                  ))}
                                                  </div>
                                        </>
                                    }
                                </div>

                                <button type="button" class="support-checkoutbtn" onClick={(e) => {this.makepayment(e);}}>
                                  <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                    <div className={this.state.loader}>
                                    <div className="new-loader"></div>
                                  </div>
                                  <div>{this.state.submitval}</div>
                                  </div>
                                </button>
                                <p class="support-checkouttext">By proceeding, you agree to our <a href="https://www.appmysite.com/terms-conditions/" rel="noopener noreferrer" target="_blank">Terms & Conditions</a> and <a href="https://www.appmysite.com/privacy-policy/"
                                            rel="noopener noreferrer"
                                            target="_blank">Privacy Policy</a>.</p>
                            </div>
                          </div>
                      )
                    : (
                      <>
                                  <div className="checkout-page">
                                    <div className="paddle-payment checkout-billing-details">
                                    </div>
                        
                                      <div className="checkout-purchase-detail">
                                          <div className="checkout-purchase-summary">
                                            <h4>Purchase details </h4>
                                              <div className="purchase-summary-sections">
                                                  { this.state.monthdata && this.state.monthdata.length > 0 && this.state.monthdata.map((res)=>(
                                                      res.plan_code == this.state.planID
                                                      ?
                                                        (
                                                          <>
                                                            <h2>{res.plan_name}</h2>
                                                            <h3>${res.amount}</h3>
                                                          </>
                                                        )
                                                      :
                                                        <>
                                                        </>
                                                  ))} 
                                              </div>
                                                  {/* <div className="purchase-summary-sections">
                                                        <h2>Applied credits </h2>
                                                        <h3>
                                                      
                                                        </h3>
                                                  </div> */}
                                                  {/* <div className="purchase-summary-sections">
                                                  <h2>Tax </h2>
                                                  <h3>
                                                
                                                </h3>
                                                </div> */}
                                                            
                                                <div className="purchase-summary-total">
                                                  <h2>Total</h2>
                                                  { this.state.monthdata && this.state.monthdata.length > 0 && this.state.monthdata.map((res)=>(
                                                      res.plan_code == this.state.planID
                                                      ?
                                                        (
                                                          <>
                                                            <h3>${res.amount}</h3>
                                                          </>
                                                        )
                                                      :
                                                        <>
                                                        </>
                                                  ))}
                                                </div>
                                          </div>
                                      </div>
                                  </div>
                      </>
                    )
                    }
                  </>
              </div>
            </>
          )
          }
        </section>
        

      </>
    );
  }
}
