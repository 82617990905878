import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormGroup, Label, Input } from "reactstrap";

import imagebackground from "./../../assets/images/dashboard/cms/uploadbackground.png";
import NewImageGalleryPopup from "./../NewImageGalleryPopup";
import VistaPopup from "../Appearance/VistaPopup";
export const VimeoVideoBlock = ({
  indx,
  cmsArray,
  setcmsData,
  isRowOpen,
  handleIsRowOpen,
  deleteRow,
  duplicateItem,
  eachBlock,

  provided,

}) => {
 
 
  const [UrlErr, setUrlErr] = useState(false);

  const handleStatus = (e) =>{

    let cmsData = [...cmsArray];
    cmsData[indx].status = ( cmsData[indx].status===1 || cmsData[indx].status===undefined || cmsData[indx].status===null)
    ?0:1;
  
 
    setcmsData(cmsData);
  }

  const validateVimeoURL = (url) => {
    
    return /^(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)$/gmi.test(url);
  }

  const checkHttps = (string) =>{
    var prefix = 'https://';
    let finalStr=string;
  if (string.substr(0, prefix.length) !== prefix)
  {
    finalStr = prefix + string;
  }
  return finalStr ;
  }

  const handleVideoUrlChange = (e)=>{
    let cmsData = [...cmsArray];
    let finalUrl=checkHttps(e.target.value);
    cmsData[indx].portal_value.link = finalUrl;
   
    if( validateVimeoURL(finalUrl)){
      setUrlErr(false);
      cmsData[indx].portal_value.is_link_err = false;
    }
    else{
      setUrlErr(true);
      cmsData[indx].portal_value.is_link_err = true;

   }
   setcmsData(cmsData);
  }

  return (
    <div className={`accordionItem ${cmsArray[indx].status===0?"cms-section-hidden":""}`}>
      
      <h2
        class="accordionItemHeading allflex"
        draggableId={indx.toString()}
        {...provided.dragHandleProps}
      > Vimeo video
      

        <span className="cms-section-visiblity" onClick={(e)=>handleStatus(e)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="25.284" height="20.465" viewBox="0 0 25.284 20.465">
        <g id="Group_39189" data-name="Group 39189" transform="translate(0.568 0.353)">
          <path id="Path_103079" data-name="Path 103079" d="M68.716,9.742a13.852,13.852,0,0,1-12.07,7.684A13.876,13.876,0,0,1,44.568,9.742,13.876,13.876,0,0,1,56.646,2.058,13.852,13.852,0,0,1,68.716,9.742Z" transform="translate(-44.568 0.138)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1"/>
          <ellipse id="Ellipse_8629" data-name="Ellipse 8629" cx="3.842" cy="3.842" rx="3.842" ry="3.842" transform="translate(8.233 6.038)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1"/>
          <line id="Line_430" data-name="Line 430" x2="19.758" y2="19.758" transform="translate(2.196 0.001)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1"/>
        </g>
      </svg>
    </span>

    
        <p className="cms-accordian-p"
          onClick={(e) => {
            handleIsRowOpen(e, indx);
          }}
        >
          <img
            src={
              isRowOpen[indx] === true
                ? require("../../assets/images/dashboard/cms/accordian-open.png")
                    .default
                : require("../../assets/images/dashboard/cms/accordian-icon.png")
                    .default
            }
          />
        </p>
      </h2>

      {isRowOpen[indx] === true && (
        <div class="accordionItemContent">

          
            <div className={`video-upload ${cmsArray[indx].portal_value.is_link_err===true?" video-error":""}`}>
              <div>
                <p>https://</p>
                </div>
                <input type="text" 
                // placeholder="https://www.vimeo.com/example-video/" 
                placeholder="Paste your Vimeo video link here."
                onChange={(e)=>handleVideoUrlChange(e)} value={cmsArray[indx].portal_value.link.
                replace("https://","").replace("http://","")}/>
            </div>

           {
            cmsArray[indx].portal_value.is_link_err===true &&
            <h6 className="video-error-text">
            <svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
              <path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"/>
              <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"/>
              <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"/>
            </svg>
            Invalid Vimeo video link: Please enter a valid link.
            </h6>  }

        

          <div className="duplicate-delete">
            <div className="cms-dup-del-btn"  onClick={(e) => duplicateItem(e, eachBlock)}>
            <span class="cms-delete-tooltip">
            <img
                src={
                  require("../../assets/images/dashboard/cms/duplicate.png")
                    .default
                }
              />
               <span class="cms-delete-tooltipsubheader">Duplicate</span>
                  </span>
            </div>

            <div className="cms-dup-del-btn" 
              onClick={(e) => deleteRow(e, indx)}
              // className={`${indx === 1 ? "d-none" : ""}`}
            >
              <span class="cms-delete-tooltip">
            <img
                src={
                  require("../../assets/images/dashboard/cms/delete.png")
                    .default
                }
              />
               <span class="cms-delete-tooltipsubheader">Delete</span>
                  </span>
            </div>
          </div>
        </div>
      )}
     
  
   
    </div>
  );
};

export default VimeoVideoBlock;



