import React from "react";

const MoveIcon = () => {
  return (
    <>
      {" "}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13.881"
        height="15"
        viewBox="0 0 13.881 15"
      >
        <g
          id="Group_40464"
          data-name="Group 40464"
          transform="translate(-1599 -64)"
        >
          <path
            id="Path_107620"
            data-name="Path 107620"
            d="M12.5,12.31a1.235,1.235,0,0,1-1.275,1.19H2.775A1.235,1.235,0,0,1,1.5,12.31V.69A1.235,1.235,0,0,1,2.775-.5h8.45A1.235,1.235,0,0,1,12.5.69"
            transform="translate(1598 65)"
            fill="none"
            stroke="#bcc1ce"
            stroke-miterlimit="10"
            stroke-width="1"
          />
          <path
            id="Path_107622"
            data-name="Path 107622"
            d="M26.619,9.749l3.538,3.583-3.449,3.493"
            transform="translate(1581.881 58.213)"
            fill="none"
            stroke="#bcc1ce"
            stroke-miterlimit="10"
            stroke-width="1.2"
          />
          <line
            id="Line_432"
            data-name="Line 432"
            x1="8"
            transform="translate(1603.5 71.5)"
            fill="none"
            stroke="#bcc1ce"
            stroke-miterlimit="10"
            stroke-width="1"
          />
        </g>
      </svg>
    </>
  );
};

export default MoveIcon;
