import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { DURATION_ARRAY } from "../../../Constants/webviewConstants/WebviewConstants";
import BackArrow from "../../commonIcons/BackArrow";


const LoaderDurationModal = ({ webViewState, handleStateChange }) => {
  return (
    <Modal
      className="protip-popup-message"
      isOpen={
        window.matchMedia("(max-width: 1200px)").matches
          ? webViewState.openMobNativeDropdown
          : false
      }
      centered
    >
      <ModalBody>
        <div className="mobileglobalsearch">
          <button
            onClick={() => handleStateChange("toggle_openMobNativeDropdown")}
          >
            <BackArrow />
          </button>
        </div>

        <div className="mobilesearchsuggestion">
          {DURATION_ARRAY.map((duration, idx) => (
            <p
              className="mobilesearchsuggestion-para"
              onClick={(e) =>
                handleStateChange("nativeLoaderDuration", duration, {
                  openMobNativeDropdown: false,
                })
              }
              key={idx}
            >
              {duration}
            </p>
          ))}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default LoaderDurationModal;
