import React from "react";

const ForwardIcon = ({ state }) => {
  return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="7.273"
        height="12.849"
        viewBox="0 0 7.273 12.849"
      >
        <g
          id="Group_38820"
          data-name="Group 38820"
          transform="translate(0.424 0.424)"
        >
          <g id="Group_38819" data-name="Group 38819" transform="translate(0)">
            <path
              id="Path_102773"
              data-name="Path 102773"
              d="M0,0,6,6l6-6"
              transform="translate(0 12) rotate(-90)"
              fill="none"
              stroke="#7782A1"
              stroke-width="1.2"
            />
          </g>
        </g>
      </svg>
  );
};

export default ForwardIcon;