import React from "react";
import Header from "../Header";
import { Link } from "react-router-dom";
import {
  MakestripWorkspacePayment,
  wooChangePlan,
  workspacePlanListing,
} from "../../Api/Dashboard/DashboardApi";
import { workspacePlanDetailsApi } from "../../Api/WorkspaceApi/WorkspaceApi";
import moment from "moment";
import { paddleChangePlan } from "../../Api/Addons/Addonsapi";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import ReactHtmlParser from "react-html-parser";
import flow from "@prosperstack/flow";

export default class UnlimitedWorkspace extends React.PureComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef(); // Create a ref object
    this.state = {
      checkedtab: "yearly",
      checkedmobtab: "preview",
      monthlydata: [],
      savemonthlydata: [],
      newmonthlydata: [],
      yearlydata: [],
      saveyearlydata: [],
      newyearlydata: [],
      lifetimedata: [],
      savelifetimedata: [],
      newlifetimedata: [],
      one_time: [],
      savemonthlydatalength: 0,
      saveyearlydatalength: 0,
      savelifetimedatalength: 0,
      loader_remove: "d-none",
      loaderimage_remove: require("../../assets/images/signup/Loader_GIF.gif")
        .default,
      submitval_confirmpop: "plan-upgradebtn",
      spinner: true,
      customer_type: "",
      checker_web_technology: 1,
      downgradeplan: false,
      currentplanname: "",
      currentplanduration: "",
      currentplanrpice: "",
      endcycledata: "",
      currency: "",
      downgrade_at_term_end: 1,
      is_android_rebuild_required: 0,
      is_ios_rebuild_required: 0,
      is_deal_plan: null,
      appuser_role_id: "",
      paddlecurreny: "",
      openReactivatePopUp: false,
      reactiveSubmitText: "Confirm",
      downgradeSubmitText: "Confirm",
      downgradeLoader:"d-none",
      reactiveLoader: "d-none",
      selectedfaqIndex: null,
      customer_billing_type: null,
      workspaceIdFromUrl: this.props.match.params.id,
      subscription_plan: "",
      stripe_customer_id:"",
      subscription_id:"",
      current_package_status: null,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    
    localStorage.setItem("AgencyForThankyouPage","");
    localStorage.setItem("workspaceId", atob(this.state.workspaceIdFromUrl));

    document.title = "Pricing | AppMySite";
    this.stopmounting = true;

    const Paddle = window.Paddle;
    localStorage.removeItem("reload");

    if (
      window.location.host.indexOf("localhost") > -1 ||
      window.location.host.indexOf("test.app") > -1 ||
      window.location.host.indexOf("stage.app") > -1 ||
      window.location.host.indexOf("dev.app") > -1
    ) {
      window.Paddle.Environment.set("sandbox");
    }
    Paddle.Setup({
      vendor: parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID),
    });

    workspacePlanDetailsApi(this, 1, () => {
      if (
        this.state.customer_billing_type === 3 &&
        this.state.yearlydata !== "" &&
        this.state.yearlydata !== undefined
      ) {
        this.state.yearlydata.map((item, i) => {
          window.Paddle.Product.Prices(parseInt(item.plan_code), (prices) =>
            this.settingPaddlePrices(i, prices, "year")
          );
        });
      } else {
        this.setState({ newyearlydata: "" });
        this.setState({ newyearlydata: this.state.yearlydata });
      }

      if (
        this.state.customer_billing_type === 3 &&
        this.state.monthlydata !== "" &&
        this.state.monthlydata !== undefined
      ) {
        this.state.monthlydata.map((item, i) => {
          window.Paddle.Product.Prices(parseInt(item.plan_code), (prices) =>
            this.settingPaddlePrices(i, prices, "month")
          );
        });
      } else {
        this.setState({ newmonthlydata: "" });
        this.setState({ newmonthlydata: this.state.monthlydata });
      }

      // if(this.state.customer_billing_type === 3)
      // {
      //     this.state.lifetimedata.map((item, i) =>
      //     {
      //         window.Paddle.Product.Prices(parseInt(item.plan_code), (prices) =>
      //         this.settingPaddlePrices(i,prices,'lifetime')
      //         );
      //     });
      // }
      // else{
      //     this.setState({newlifetimedata:''});
      //     this.setState({newlifetimedata:this.state.lifetimedata});
      // }
    });

    // workspacePlanDetailsApi(this, 1,() =>{
    //     this.setState({newyearlydata:this.state.yearlydata});
    //     this.setState({newmonthlydata:this.state.monthlydata});
    //     this.setState({newlifetimedata:this.state.lifetimedata});
    // });
  }

  newcurrencyFormater = (number, country_name) => {
    let formatedNum = new Intl.NumberFormat(country_name).format(number);
    return formatedNum;
  };

  settingPaddlePrices = (i, prices, type) => {
    this.setState({ currency: prices.price.gross.slice(0, 1) });
    if (
      type == "year" &&
      this.state.saveyearlydatalength < this.state.yearlydata.length
    ) {
      var numb = prices.price.gross.replaceAll(",", "");
      numb = numb.replace(/^\D+/g, "");

      var currency = prices.price.gross
        .replace(/[0-9]/g, "")
        .replace(/[`~!@#%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
      currency = currency.replace("US", " ");

      var values = this.newcurrencyFormater(
        parseFloat(parseFloat(numb) / 12).toFixed(2),
        "en-" + prices.country
      );

      let decimalValue = prices.price.gross.toString().indexOf(".");
      let result =
        "." + prices.price.gross.toString().substring(decimalValue + 1);

      this.state.yearlydata[i].paddleprice =
        `<h5>` +
        currency +
        values +
        `<span class='pricing-decimal'>` +
        result +
        `</span><span> per month paid yearly</span></h5>`;
      this.state.saveyearlydata.push(this.state.yearlydata[i]);
      this.setState({
        saveyearlydatalength: this.state.saveyearlydatalength + 1,
        paddlecurreny: currency,
      });
      if (this.state.saveyearlydatalength == this.state.yearlydata.length) {
        var results1 = this.state.saveyearlydata;
        this.setState({ newyearlydata: "" });
        this.setState({ newyearlydata: results1 });
      }
    }

    if (
      type == "month" &&
      this.state.savemonthlydatalength < this.state.monthlydata.length
    ) {
      var numb = prices.price.gross.replaceAll(",", "");
      numb = numb.replace(/^\D+/g, "");

      var currency = prices.price.gross
        .replace(/[0-9]/g, "")
        .replace(/[`~!@#%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "");
      currency = currency.replace("US", " ");

      var values = this.newcurrencyFormater(
        parseFloat(parseFloat(numb)),
        "en-" + prices.country
      );

      let decimalValue = prices.price.gross.toString().indexOf(".");
      let result =
        "." + prices.price.gross.toString().substring(decimalValue + 1);

      this.state.monthlydata[i].paddleprice =
        `<h5>` +
        currency +
        values +
        `<span class='pricing-decimal'>` +
        result +
        `</span><span> per month</span></h5>`;

      this.state.savemonthlydata.push(this.state.monthlydata[i]);
      this.setState({
        savemonthlydatalength: this.state.savemonthlydatalength + 1,
        paddlecurreny: currency,
      });
      if (this.state.savemonthlydatalength == this.state.monthlydata.length) {
        var results = this.state.savemonthlydata;
        this.setState({ newmonthlydata: "" });
        this.setState({ newmonthlydata: results });
      }
    }

    // if(type == 'lifetime' && this.state.savelifetimedatalength < this.state.lifetimedata.length)
    // {

    //   var numb = prices.price.gross.replaceAll(',','');
    //   numb = numb.replace(/^\D+/g, '');

    //   var currency = (prices.price.gross.replace(/[0-9]/g, '')).replace(/[`~!@#%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    //   currency = currency.replace("US", " ");

    //   var values =  this.newcurrencyFormater((parseFloat(parseFloat(numb))), "en-"+ prices.country);

    //   let decimalValue = prices.price.gross.toString().indexOf(".");
    //   let result = '.'+prices.price.gross.toString().substring(decimalValue+1);

    //   this.state.lifetimedata[i].paddleprice = `<h4>`+currency + values +`<span class='pricing-decimal'>`+result+`</span></h4>`;
    //     this.state.savelifetimedata.push(this.state.lifetimedata[i]);
    //     this.setState({savelifetimedatalength:this.state.savelifetimedatalength+1,paddlecurreny:currency});
    //     if(this.state.savelifetimedatalength == this.state.lifetimedata.length)
    //     {
    //         var results2 = this.state.savelifetimedata;
    //         this.setState({newlifetimedata:''});
    //         this.setState({newlifetimedata:results2});
    //     }
    // }
  };

  handleTabs = (tab) => {
    this.setState({
      checkedtab: tab,
    });
  };
  handleAccordianToggle = (e, indx) => {
    if (indx === this.state.selectedfaqIndex) {
      this.setState({
        selectedfaqIndex: null,
      });
    } else {
      this.setState({
        selectedfaqIndex: indx,
      });
    }
  };

  seemorefunction = () => {
    var element = document.getElementById("showfaq");
    element.classList.remove("hiddenfaq");
    document.getElementById("seemorelink").classList.add("d-none");
    document.getElementById("seelesslink").classList.remove("d-none");
  };

  seelessfunction = () => {
    var element = document.getElementById("showfaq");
    element.classList.add("hiddenfaq");
    document.getElementById("seemorelink").classList.remove("d-none");
    document.getElementById("seelesslink").classList.add("d-none");
  };

  dlink = (plan_code, recurring_price, package_name, interval_unit, id) => {
    if (this.state.appuser_role_id !== 3 && this.state.appuser_role_id !== 4) {
      return (
        "/checkout/" +
        plan_code +
        "/" +
        btoa(recurring_price) +
        "/" +
        btoa(plan_code) +
        "/" +
        btoa(interval_unit) +
        "/" +
        btoa(id) +
        "/" +
        btoa(null) +
        "/" +
        "1"
      );
    }
  };

  handleReactivePopUp = (e, from) => {
    if (from === "cancel") {
      this.setState({
        openReactivatePopUp: !this.state.openReactivatePopUp,
      });
    } else {
      this.setState({
        reactiveLoader: "d-block",
        reactiveSubmitText: "",
      });
      this.previewdowngrade(e,from);
    }
  };

  previewdowngrade = (e,from) => {
    // e.preventDefault();

    this.setState({
      loader_remove: "d-block plan-upgradebtn",
      submitval_confirmpop: "d-none",
      downgradeSubmitText:"",
      downgradeLoader:"d-block",
    });
    const workspaceParam = {
      workspace_id: parseInt(atob(this.state.workspaceIdFromUrl)),
      plan_array: [
        {
          price_id: this.state.one_time[0].plan_code,

          quantity: 1,
          type: 1,
        },
      ],
      payment_method_id: "downgrade",
      order_type: 2,
      deal_id: "",
      downgrade_at_term_end: this.state.downgrade_at_term_end === 0 ? 1 : 0,
      // action: this.state.payment_method_id,
    };

    const paddleParam = {
      workspace_id: parseInt(atob(this.state.workspaceIdFromUrl)),
      app_id: null,
      deal_id: null,
      order_type: 2,
      price_id: this.state.one_time[0].plan_code,
      quantity: 1,
      type: 1,
      downgrade_at_term_end: this.state.downgrade_at_term_end === 0 ? 1 : 0,
    };

    this.state.customer_billing_type === 3
      ? paddleChangePlan(paddleParam, this)
      : MakestripWorkspacePayment(workspaceParam, this);
      
  };

  removepopup = () => {
    if (this.state.appuser_role_id !== 3 && this.state.appuser_role_id !== 4) {
      this.setState({
        deluser: !this.state.deluser,
      });
    }
  };
  downgradepopupdeal = () => {
    if (this.state.appuser_role_id !== 3 && this.state.appuser_role_id !== 4) {
      this.setState({
        downgradeuser: !this.state.downgradeuser,
      });
    }
  };

  handleWooCustomer = (e, customer_type, plan_code) => {
    this.setState({
      loader_remove: "d-block plan-upgradebtn",
      submitval_confirmpop: "d-none",
      downgradeSubmitText:"",
      downgradeLoader:"d-block",
    });
    this.setState({
      tempFilter: true,
    });
    if (customer_type === 4) {
      const params = {
        price_id: plan_code,
        type: 1,
        app_id: parseInt(atob(this.state.workspaceIdFromUrl)), //either this or workspace_id

        return_url:
          window.location.protocol + "//" + window.location.host + "/thank-you",
      };
      wooChangePlan(this, params);
    }
  };

  render() {
    return (
      <>
        <Header customclassName={"header-responsive-new"} />

        {parseInt(localStorage.getItem("logged_in_user_role_id")) === 3 ||
        parseInt(localStorage.getItem("logged_in_user_role_id")) === 4 ? (
          <>
            <section className="blueback allplans teampricing  paddingbottom80 pricing-main-page">
              <div className="col-md-12">
                <div className="w-100 text-center restrictuser pt-5 pb-5">
                  {/* <img
                  src={require("../../assets/images/dashboard/del-user.png").default}
                  alt="Changes not allowed"
                  className="mb-4 img-fluid"
                />
                <h4>You currently do not have permissions to view this feature.</h4>
                <h4> Please contact your Administrator if you require access.</h4> */}
                  <img
                    src={
                      require("../../assets/images/dashboard/restrict-user.png")
                        .default
                    }
                    alt="Changes not allowed"
                    className="mb-4 img-fluid"
                  />
                  <h3>
                    You currently do not have permission to access this feature.
                    Please contact your administrator if you require access
                  </h3>
                  <h4>
                    You currently do not have permission to access this feature.
                  </h4>
                  <h4>
                    {" "}
                    Please contact your administrator if you require access
                  </h4>
                </div>
              </div>
            </section>
          </>
        ) : (
          <div style={{ minHeight: "100vh" }}>
            {this.state.spinner ? (
              <div className="newspinner">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  style={{
                    margin: "auto",
                    background: "rgb(241, 242, 243,0)",
                    display: "block",
                    shaperendering: "auto",
                    animationplaystate: "running",
                    animationdelay: "0s",
                  }}
                  width="200px"
                  height="200px"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="xMidYMid"
                >
                  <g
                    transform="translate(20 50)"
                    style={{
                      animationplaystate: "running",
                      animationdelay: "0s",
                    }}
                  >
                    <circle
                      cx="0"
                      cy="0"
                      r="6"
                      fill="#e6261f"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <animateTransform
                        attributeName="transform"
                        type="scale"
                        begin="-0.375s"
                        calcMode="spline"
                        keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                        values="0;1;0"
                        keyTimes="0;0.5;1"
                        dur="1s"
                        repeatCount="indefinite"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      ></animateTransform>
                    </circle>
                  </g>
                  <g
                    transform="translate(40 50)"
                    style={{
                      animationplaystate: "running",
                      animationdelay: "0s",
                    }}
                  >
                    <circle
                      cx="0"
                      cy="0"
                      r="6"
                      fill="#f7d038"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <animateTransform
                        attributeName="transform"
                        type="scale"
                        begin="-0.25s"
                        calcMode="spline"
                        keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                        values="0;1;0"
                        keyTimes="0;0.5;1"
                        dur="1s"
                        repeatCount="indefinite"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      ></animateTransform>
                    </circle>
                  </g>
                  <g
                    transform="translate(60 50)"
                    style={{
                      animationplaystate: "running",
                      animationdelay: "0s",
                    }}
                  >
                    <circle
                      cx="0"
                      cy="0"
                      r="6"
                      fill="#49da9a"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <animateTransform
                        attributeName="transform"
                        type="scale"
                        begin="-0.125s"
                        calcMode="spline"
                        keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                        values="0;1;0"
                        keyTimes="0;0.5;1"
                        dur="1s"
                        repeatCount="indefinite"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      ></animateTransform>
                    </circle>
                  </g>
                  <g
                    transform="translate(80 50)"
                    style={{
                      animationplaystate: "running",
                      animationdelay: "0s",
                    }}
                  >
                    <circle
                      cx="0"
                      cy="0"
                      r="6"
                      fill="#4355db"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <animateTransform
                        attributeName="transform"
                        type="scale"
                        begin="0s"
                        calcMode="spline"
                        keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                        values="0;1;0"
                        keyTimes="0;0.5;1"
                        dur="1s"
                        repeatCount="indefinite"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      ></animateTransform>
                    </circle>
                  </g>
                </svg>
              </div>
            ) : (
              <>
                <section className="blueback teampricing paddingbottom100 agency-header-gap workspace-priceing">
                <div className="container">
            {this.state.current_package_status === 0 && 
            this.state.is_deal_plan === 0 &&
            this.state.subscription_plan !=="paddle_preview" 
            && this.state.subscription_plan !=="agency_preview"
            && this.state.subscription_plan !== process.env.REACT_APP_Lifetime_preview
            && this.state.subscription_plan !== process.env.REACT_APP_zoho_preview
            && this.state.subscription_plan !== process.env.REACT_APP_STRIPE_PREVIEW  &&  <div className="pricing-notice">
                <h3 className="pricing-notice-header">Your current plan has been discontinued.</h3>
                <p className="pricing-notice-para">Your ongoing subscription and renewals remain unaffected by the price update.</p>
            </div>}
            </div>
                  <div className="container">
                    <h1 className="text-left">Unlimited workspace</h1>

                    <div className="all-discount-percent">
                      <p>Save up to 20%</p>
                      <img
                        src={require("../../assets/images/dashboard/pricing-arrow.png").default}
                        alt="white-plus"
                      />
                    </div>

                    <div className="tabbed price-main-tab">
                      <input
                        type="radio"
                        id="tab1"
                        name="css-tabs"
                        checked={this.state.checkedtab === "monthly"}
                      />

                      <input
                        type="radio"
                        id="tab2"
                        name="css-tabs"
                        checked={this.state.checkedtab === "yearly"}
                      />

                      <ul className="tabs">
                        <li className="tab">
                          <label
                            onClick={() => this.handleTabs("monthly")}
                            for="tab1"
                          >
                            Monthly
                          </label>
                        </li>

                        <li className="tab">
                          <label
                            onClick={() => this.handleTabs("yearly")}
                            for="tab2"
                          >
                            Yearly
                          </label>
                        </li>
                      </ul>

                      {this.state.checkedtab === "monthly" ? (
                        <div className="tab-content">
                          {this.state.newmonthlydata &&
                            this.state.newmonthlydata.length > 0 && (
                              <div className="agency-pricing">
                                <img
                                  src={
                                    require("../../assets/images/dashboard/agency-pricing.webp")
                                      .default
                                  }
                                  alt="white-plus"
                                />

                                <div className="agency-pricing-details">
                                  <h3>What’s included?</h3>
                                  <h4>
                                  Manage unlimited apps, and collaborate with team members, all with a single subscription.
                                  </h4>
                                  {this.state.customer_billing_type === 3 ? (
                                    <>
                                      {ReactHtmlParser(
                                        this.state.newmonthlydata[0].paddleprice
                                      )}
                                    </>
                                  ) : (
                                    <h5>
                                      {this.state.newmonthlydata[0]
                                        .package_currency.currency_symbol +
                                        this.state.newmonthlydata[0].amount.replace(
                                          "00",
                                          ""
                                        )}
                                      <span>00</span> <span>per month</span>
                                    </h5>
                                  )}

                                  <div className="agency-pricing-footer">
                                    {this.state.newmonthlydata[0].show_plan ===
                                    0 ? (
                                      <Link
                                        to="#"
                                        className="agency-pricing-buy disabled"
                                      >
                                        {this.state.newmonthlydata[0]
                                          .is_subscribed === 1
                                          ? //       ||
                                            //     ( this.state.subscription_plan && this.state.subscription_plan !== "preview" &&
                                            //       this.state.subscription_plan !== "paddle_preview" &&
                                            //       this.state.subscription_plan !== process.env.REACT_APP_STRIPE_PREVIEW &&
                                            //       this.state.subscription_plan !== process.env.REACT_APP_zoho_preview &&
                                            //       this.state.subscription_plan !== process.env.REACT_APP_Lifetime_preview  &&
                                            //       this.state.subscription_plan !== process.env.REACT_APP_Lifetime_preview
                                            //     )
                                            //  ) && this.state.currentplanduration === 1 )

                                            "Current"
                                          : "Unavailable"}
                                      </Link>
                                    ) : (
                                      <Link
                                        className={
                                          this.state.newmonthlydata[0]
                                            .is_subscribed === 1
                                            ? // ||
                                              // ( this.state.subscription_plan && this.state.subscription_plan !== "preview" &&
                                              //   this.state.subscription_plan !== "paddle_preview" &&
                                              //   this.state.subscription_plan !== process.env.REACT_APP_STRIPE_PREVIEW &&
                                              //   this.state.subscription_plan !== process.env.REACT_APP_zoho_preview &&
                                              //   this.state.subscription_plan !== process.env.REACT_APP_Lifetime_preview  &&
                                              //   this.state.subscription_plan !== process.env.REACT_APP_Lifetime_preview
                                              // )
                                              "agency-pricing-buy disabled"
                                            : "agency-pricing-buy"
                                        }
                                        to={
                                          this.state.customer_type === 4 ||
                                          this.state.newmonthlydata[0]
                                            .is_subscribed === 1
                                          // ||
                                          // ( this.state.subscription_plan && this.state.subscription_plan !== "preview" &&
                                          //   this.state.subscription_plan !== "paddle_preview" &&
                                          //   this.state.subscription_plan !== process.env.REACT_APP_STRIPE_PREVIEW &&
                                          //   this.state.subscription_plan !== process.env.REACT_APP_zoho_preview &&
                                          //   this.state.subscription_plan !== process.env.REACT_APP_Lifetime_preview  &&
                                          //   this.state.subscription_plan !== process.env.REACT_APP_Lifetime_preview
                                          // )
                                            ? "#"
                                            : this.dlink(
                                                this.state.newmonthlydata[0]
                                                  .plan_code,
                                                parseInt(
                                                  this.state.newmonthlydata[0]
                                                    .amount
                                                ),
                                                this.state.newmonthlydata[0]
                                                  .plan_name,
                                                this.state.newmonthlydata[0]
                                                  .package_currency
                                                  .currency_symbol,
                                                this.state.newmonthlydata[0]
                                                  .plan_code
                                              )
                                        }
                                        onClick={
                                          this.state.customer_type === 4 &&
                                          this.state.newmonthlydata[0]
                                            .is_subscribed === 0
                                          //   ||
                                          //  !this.state.subscription_plan || this.state.subscription_plan === "preview" ||
                                          //   this.state.subscription_plan === "paddle_preview" ||
                                          //   this.state.subscription_plan === process.env.REACT_APP_STRIPE_PREVIEW ||
                                          //   this.state.subscription_plan === process.env.REACT_APP_zoho_preview ||
                                          //   this.state.subscription_plan === process.env.REACT_APP_Lifetime_preview  ||
                                          //   this.state.subscription_plan === process.env.REACT_APP_Lifetime_preview
                                            ? (e) =>
                                                this.handleWooCustomer(
                                                  e,
                                                  this.state.customer_type,
                                                  this.state.newmonthlydata[0]
                                                    .plan_code,
                                                  0
                                                )
                                            : null
                                        }
                                      >
                                        {this.state.newmonthlydata[0]
                                          .is_subscribed === 1
                                          ? // ||
                                            // ( this.state.subscription_plan && this.state.subscription_plan !== "preview" &&
                                            //   this.state.subscription_plan !== "paddle_preview" &&
                                            //   this.state.subscription_plan !== process.env.REACT_APP_STRIPE_PREVIEW &&
                                            //   this.state.subscription_plan !== process.env.REACT_APP_zoho_preview &&
                                            //   this.state.subscription_plan !== process.env.REACT_APP_Lifetime_preview  &&
                                            //   this.state.subscription_plan !== process.env.REACT_APP_Lifetime_preview
                                            // )
                                            "Current"
                                          : "Continue"}
                                      </Link>
                                    )}

                                    {this.state.currentplanduration !== 4 && (
                                      <>
                                        {(this.state.newmonthlydata[0]
                                          .is_subscribed === 1 ||
                                          (this.state.subscription_plan &&
                                            this.state.subscription_plan !==
                                              "preview" &&
                                            this.state.subscription_plan !==
                                              "paddle_preview" &&
                                            this.state.subscription_plan !==
                                              process.env
                                                .REACT_APP_STRIPE_PREVIEW &&
                                            this.state.subscription_plan !==
                                              process.env
                                                .REACT_APP_zoho_preview &&
                                            this.state.subscription_plan !==
                                              process.env
                                                .REACT_APP_Lifetime_preview &&
                                            this.state.subscription_plan !==
                                              process.env
                                                .REACT_APP_Lifetime_preview)) &&
                                        this.state.currentplanduration === 1 ? (
                                          this.state.downgrade_at_term_end ===
                                          0 ? (
                                            this.state.customer_type === 4 &&
                                            this.state.reseller_licence_id ===
                                              null ? (
                                              <a
                                                className="cancel-agency-subscription"
                                                target="_blank"
                                                href={
                                                  process.env
                                                    .REACT_APP_WOOCOMMERCE_URL +
                                                  "my-account/my-subscriptions/"
                                                }
                                              >
                                                Cancel subscription
                                                <img
                                                  src={
                                                    require("../../assets/images/dashboard/Goto.png")
                                                      .default
                                                  }
                                                  alt="Goto"
                                                />
                                              </a>
                                            ) : (
                                              <p
                                                className="cancel-agency-subscription"
                                                onClick={(e) =>
                                                  this.state.is_deal_plan == 1
                                                    ? this.downgradepopupdeal(e)
                                                    : this.removepopup(e)
                                                }
                                              >
                                                Cancel subscription
                                              </p>
                                            )
                                          ) : (

                                            <div className="agency-precing-reactivate">
                                <p className="agency-cancel-reactive">
                                Cancellation on{" "}
                                              {moment(
                                                this.state.endcycledata
                                              ).format("D MMMM YYYY")}
                                              
                                            </p>
                                            <span
                                                onClick={(e) =>
                                                  this.state.customer_type === 4
                                                    ? this.handleWooCustomer(
                                                        e,
                                                        this.state
                                                          .customer_type,
                                                        this.state.one_time[0]
                                                          .plan_code
                                                      )
                                                    : this.handleReactivePopUp(
                                                        e,
                                                        "cancel"
                                                      )
                                                }
                                              >
                                                Reactivate
                                              </span>
                            </div>
                                            
                                          )
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )}

                                    {/* <p className="cancel-agency-subscription">Cancel subscription</p>

                            <div className="agency-precing-reactivate">
                                <p className="agency-cancel-reactive" >Cancellation on 18 May, 2023</p>
                                <a href="#">Reactivate</a>   
                            </div> */}
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>
                      ) : (
                        <div className="tab-content">
                          {this.state.newyearlydata &&
                            this.state.newyearlydata.length > 0 && (
                              <div className="agency-pricing">
                                <img
                                  src={
                                    require("../../assets/images/dashboard/agency-pricing.webp")
                                      .default
                                  }
                                  alt="white-plus"
                                />

                                <div className="agency-pricing-details">
                                  <h3>What’s included?</h3>
                                  <h4>
                                  Manage unlimited apps, and collaborate with team members, all with a single subscription.
                                  </h4>
                                  {this.state.customer_billing_type === 3 ? (
                                    <>
                                      {ReactHtmlParser(
                                        this.state.newyearlydata[0].paddleprice
                                      )}
                                    </>
                                  ) : (
                                    <h5>
                                      {this.state.newyearlydata[0]
                                        .package_currency.currency_symbol +
                                        this.state.newyearlydata[0].amount.replace(
                                          "00",
                                          ""
                                        )}
                                      <span>00</span> <span>per month paid yearly</span>
                                    </h5>
                                  )}
                                  <div className="agency-pricing-footer">
                                    {this.state.newyearlydata[0].show_plan ===
                                    0 ? (
                                      <Link
                                        to="#"
                                        className="agency-pricing-buy disabled"
                                      >
                                        {this.state.newyearlydata[0]
                                          .is_subscribed === 1
                                          ? //     ||
                                            //     ( this.state.subscription_plan && this.state.subscription_plan !== "preview" &&
                                            //       this.state.subscription_plan !== "paddle_preview" &&
                                            //       this.state.subscription_plan !== process.env.REACT_APP_STRIPE_PREVIEW &&
                                            //       this.state.subscription_plan !== process.env.REACT_APP_zoho_preview &&
                                            //       this.state.subscription_plan !== process.env.REACT_APP_Lifetime_preview  &&
                                            //       this.state.subscription_plan !== process.env.REACT_APP_Lifetime_preview
                                            //     )
                                            //  ) && this.state.currentplanduration === 2 )

                                            "Current"
                                          : "Unavailable"}
                                      </Link>
                                    ) : (
                                      <Link
                                        className={
                                          this.state.newyearlydata[0]
                                            .is_subscribed === 1
                                            ? //      ||
                                              //     ( this.state.subscription_plan && this.state.subscription_plan !== "preview" &&
                                              //       this.state.subscription_plan !== "paddle_preview" &&
                                              //       this.state.subscription_plan !== process.env.REACT_APP_STRIPE_PREVIEW &&
                                              //       this.state.subscription_plan !== process.env.REACT_APP_zoho_preview &&
                                              //       this.state.subscription_plan !== process.env.REACT_APP_Lifetime_preview  &&
                                              //       this.state.subscription_plan !== process.env.REACT_APP_Lifetime_preview
                                              //     )
                                              //  ) && this.state.currentplanduration === 2 )

                                              "agency-pricing-buy disabled"
                                            : "agency-pricing-buy"
                                        }
                                        to={
                                          this.state.customer_type === 4 ||
                                          this.state.newyearlydata[0]
                                            .is_subscribed === 1
                                          //   ||
                                          // ( this.state.subscription_plan && this.state.subscription_plan !== "preview" &&
                                          //   this.state.subscription_plan !== "paddle_preview" &&
                                          //   this.state.subscription_plan !== process.env.REACT_APP_STRIPE_PREVIEW &&
                                          //   this.state.subscription_plan !== process.env.REACT_APP_zoho_preview &&
                                          //   this.state.subscription_plan !== process.env.REACT_APP_Lifetime_preview  &&
                                          //   this.state.subscription_plan !== process.env.REACT_APP_Lifetime_preview
                                          // ) && this.state.currentplanduration === 2
                                            ? "#"
                                            : this.dlink(
                                                this.state.newyearlydata[0]
                                                  .plan_code,
                                                parseInt(
                                                  this.state.newyearlydata[0]
                                                    .amount
                                                ),
                                                this.state.newyearlydata[0]
                                                  .plan_name,
                                                this.state.newyearlydata[0]
                                                  .package_currency
                                                  .currency_symbol,
                                                this.state.newyearlydata[0]
                                                  .plan_code
                                              )
                                        }
                                        onClick={
                                          this.state.customer_type === 4 &&
                                          this.state.newyearlydata[0]
                                            .is_subscribed === 0

                                          //     ||
                                          //    !this.state.subscription_plan || this.state.subscription_plan === "preview" ||
                                          //     this.state.subscription_plan === "paddle_preview" ||
                                          //     this.state.subscription_plan === process.env.REACT_APP_STRIPE_PREVIEW ||
                                          //     this.state.subscription_plan === process.env.REACT_APP_zoho_preview ||
                                          //     this.state.subscription_plan === process.env.REACT_APP_Lifetime_preview  ||
                                          //     this.state.subscription_plan === process.env.REACT_APP_Lifetime_preview

                                          //  ) && this.state.currentplanduration === 2
                                            ? (e) =>
                                                this.handleWooCustomer(
                                                  e,
                                                  this.state.customer_type,
                                                  this.state.newyearlydata[0]
                                                    .plan_code,
                                                  0
                                                )
                                            : null
                                        }
                                      >
                                        {this.state.newyearlydata[0]
                                          .is_subscribed === 1 &&
                                        // ||
                                        // ( this.state.subscription_plan && this.state.subscription_plan !== "preview" &&
                                        //   this.state.subscription_plan !== "paddle_preview" &&
                                        //   this.state.subscription_plan !== process.env.REACT_APP_STRIPE_PREVIEW &&
                                        //   this.state.subscription_plan !== process.env.REACT_APP_zoho_preview &&
                                        //   this.state.subscription_plan !== process.env.REACT_APP_Lifetime_preview  &&
                                        //   this.state.subscription_plan !== process.env.REACT_APP_Lifetime_preview
                                        // )
                                        this.state.currentplanduration === 2
                                          ? "Current"
                                          : "Continue"}
                                      </Link>
                                    )}

                                    {this.state.currentplanduration !== 4 && (
                                      <>
                                        {(this.state.newyearlydata[0]
                                          .is_subscribed === 1 ||
                                          (this.state.subscription_plan &&
                                            this.state.subscription_plan !==
                                              "preview" &&
                                            this.state.subscription_plan !==
                                              "paddle_preview" &&
                                            this.state.subscription_plan !==
                                              process.env
                                                .REACT_APP_STRIPE_PREVIEW &&
                                            this.state.subscription_plan !==
                                              process.env
                                                .REACT_APP_zoho_preview &&
                                            this.state.subscription_plan !==
                                              process.env
                                                .REACT_APP_Lifetime_preview &&
                                            this.state.subscription_plan !==
                                              process.env
                                                .REACT_APP_Lifetime_preview)) &&
                                        this.state.currentplanduration === 2 ? (
                                          this.state.downgrade_at_term_end ===
                                          0 ? (
                                            this.state.customer_type === 4 &&
                                            this.state.reseller_licence_id ===
                                              null ? (
                                              <a
                                                className="cancel-agency-subscription"
                                                target="_blank"
                                                href={
                                                  process.env
                                                    .REACT_APP_WOOCOMMERCE_URL +
                                                  "my-account/my-subscriptions/"
                                                }
                                              >
                                                Cancel subscription
                                                <img
                                                  src={
                                                    require("../../assets/images/dashboard/Goto.png")
                                                      .default
                                                  }
                                                  alt="Goto"
                                                />
                                              </a>
                                            ) : (
                                              <p
                                                className="cancel-agency-subscription"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                onClick={(e) =>
                                                  this.state.is_deal_plan == 1
                                                    ? this.downgradepopupdeal(e)
                                                    : this.removepopup(e)
                                                }
                                              >
                                                Cancel subscription
                                              </p>
                                            )
                                          ) : (

                                            <div className="agency-precing-reactivate">
                                <p className="agency-cancel-reactive">
                                Cancellation on{" "}
                                              {moment(
                                                this.state.endcycledata
                                              ).format("D MMMM YYYY")}
                                              
                                            </p>
                                            <span
                                                onClick={(e) =>
                                                  this.state.customer_type === 4
                                                    ? this.handleWooCustomer(
                                                        e,
                                                        this.state
                                                          .customer_type,
                                                        this.state.one_time[0]
                                                          .plan_code
                                                      )
                                                    : this.handleReactivePopUp(
                                                        e,
                                                        "cancel"
                                                      )
                                                }
                                              >
                                                Reactivate
                                              </span>   
                            </div>

                                            
                                          )
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="container">
                    <div className="custom-quote" style={{backgroundColor : "#fff"}}>
                      <div>
                        <h2>Need help?</h2>
                        <h3>
                        Feel free to contact us for any queries about our Unlimited workspace product.
                        </h3>
                      </div>

                      <a href="https://app.appmysite.com/get-in-touch/">
                        Get in touch
                      </a>
                    </div>
                  </div>
                </section>

                <section className="agency-table">
                  <div className="container">
                    <h2>Feature highlights</h2>
                    <h3>
                    Explore the details of our Unlimited workspace product offerings.
                    </h3>

                    <div className="agency-table-detail">
                      <div className="agency-table-detail-list">
                        <h4>Products</h4>

                        <div className="agency-table-detail-feature">
                          <div className="agency-feature-entry">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <g
                                id="Group_8367"
                                data-name="Group 8367"
                                transform="translate(-170 -1511)"
                              >
                                <circle
                                  id="Ellipse_4121"
                                  data-name="Ellipse 4121"
                                  cx="8"
                                  cy="8"
                                  r="8"
                                  transform="translate(170 1511)"
                                  fill="#3064f9"
                                />
                                <path
                                  id="Path_9596"
                                  data-name="Path 9596"
                                  d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                                  transform="translate(-3548.643 18005.063)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="2"
                                />
                              </g>
                            </svg>
                            <p>
                              <a
                                target="_blank"
                                href="https://www.appmysite.com/wordpress-pricing/"
                              >
                                WordPress App
                              </a>
                            </p>
                          </div>

                          <div className="agency-feature-entry">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <g
                                id="Group_8367"
                                data-name="Group 8367"
                                transform="translate(-170 -1511)"
                              >
                                <circle
                                  id="Ellipse_4121"
                                  data-name="Ellipse 4121"
                                  cx="8"
                                  cy="8"
                                  r="8"
                                  transform="translate(170 1511)"
                                  fill="#3064f9"
                                />
                                <path
                                  id="Path_9596"
                                  data-name="Path 9596"
                                  d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                                  transform="translate(-3548.643 18005.063)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="2"
                                />
                              </g>
                            </svg>
                            <p>
                              <a
                                target="_blank"
                                href="https://www.appmysite.com/woocommerce-pricing/"
                              >
                                WooCommerce App
                              </a>
                            </p>
                          </div>

                          <div className="agency-feature-entry">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <g
                                id="Group_8367"
                                data-name="Group 8367"
                                transform="translate(-170 -1511)"
                              >
                                <circle
                                  id="Ellipse_4121"
                                  data-name="Ellipse 4121"
                                  cx="8"
                                  cy="8"
                                  r="8"
                                  transform="translate(170 1511)"
                                  fill="#3064f9"
                                />
                                <path
                                  id="Path_9596"
                                  data-name="Path 9596"
                                  d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                                  transform="translate(-3548.643 18005.063)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="2"
                                />
                              </g>
                            </svg>
                            <p>
                              <a
                                target="_blank"
                                href="https://www.appmysite.com/web-to-app-pricing/"
                              >
                                Web-to-App
                              </a>
                            </p>
                          </div>

                          <div className="agency-feature-entry">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <g
                                id="Group_8367"
                                data-name="Group 8367"
                                transform="translate(-170 -1511)"
                              >
                                <circle
                                  id="Ellipse_4121"
                                  data-name="Ellipse 4121"
                                  cx="8"
                                  cy="8"
                                  r="8"
                                  transform="translate(170 1511)"
                                  fill="#3064f9"
                                />
                                <path
                                  id="Path_9596"
                                  data-name="Path 9596"
                                  d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                                  transform="translate(-3548.643 18005.063)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="2"
                                />
                              </g>
                            </svg>
                            <p>
                              <a
                                target="_blank"
                                href="https://www.appmysite.com/custom-app-pricing/"
                              >
                                Custom App
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="agency-table-detail-list">
                        <h4>Plans</h4>

                        <div className="agency-table-detail-feature">
                          <div className="agency-feature-entry">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <g
                                id="Group_8367"
                                data-name="Group 8367"
                                transform="translate(-170 -1511)"
                              >
                                <circle
                                  id="Ellipse_4121"
                                  data-name="Ellipse 4121"
                                  cx="8"
                                  cy="8"
                                  r="8"
                                  transform="translate(170 1511)"
                                  fill="#3064f9"
                                />
                                <path
                                  id="Path_9596"
                                  data-name="Path 9596"
                                  d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                                  transform="translate(-3548.643 18005.063)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="2"
                                />
                              </g>
                            </svg>
                            <p>Premium</p>
                          </div>
                        </div>
                      </div>

                      <div className="agency-table-detail-list">
                        <h4>Devices</h4>

                        <div className="agency-table-detail-feature">
                          <div className="agency-feature-entry">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <g
                                id="Group_8367"
                                data-name="Group 8367"
                                transform="translate(-170 -1511)"
                              >
                                <circle
                                  id="Ellipse_4121"
                                  data-name="Ellipse 4121"
                                  cx="8"
                                  cy="8"
                                  r="8"
                                  transform="translate(170 1511)"
                                  fill="#3064f9"
                                />
                                <path
                                  id="Path_9596"
                                  data-name="Path 9596"
                                  d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                                  transform="translate(-3548.643 18005.063)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="2"
                                />
                              </g>
                            </svg>
                            <p>Android</p>
                          </div>

                          <div className="agency-feature-entry">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <g
                                id="Group_8367"
                                data-name="Group 8367"
                                transform="translate(-170 -1511)"
                              >
                                <circle
                                  id="Ellipse_4121"
                                  data-name="Ellipse 4121"
                                  cx="8"
                                  cy="8"
                                  r="8"
                                  transform="translate(170 1511)"
                                  fill="#3064f9"
                                />
                                <path
                                  id="Path_9596"
                                  data-name="Path 9596"
                                  d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                                  transform="translate(-3548.643 18005.063)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="2"
                                />
                              </g>
                            </svg>
                            <p>iOS</p>
                          </div>

                          <div className="agency-feature-entry">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <g
                                id="Group_8367"
                                data-name="Group 8367"
                                transform="translate(-170 -1511)"
                              >
                                <circle
                                  id="Ellipse_4121"
                                  data-name="Ellipse 4121"
                                  cx="8"
                                  cy="8"
                                  r="8"
                                  transform="translate(170 1511)"
                                  fill="#3064f9"
                                />
                                <path
                                  id="Path_9596"
                                  data-name="Path 9596"
                                  d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                                  transform="translate(-3548.643 18005.063)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="2"
                                />
                              </g>
                            </svg>
                            <p>PWA (Coming soon)</p>
                          </div>
                        </div>
                      </div>

                      <div className="agency-table-detail-list">
                        <h4>Capacities</h4>

                        <div className="agency-table-detail-feature">
                          <div className="agency-feature-entry">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <g
                                id="Group_8367"
                                data-name="Group 8367"
                                transform="translate(-170 -1511)"
                              >
                                <circle
                                  id="Ellipse_4121"
                                  data-name="Ellipse 4121"
                                  cx="8"
                                  cy="8"
                                  r="8"
                                  transform="translate(170 1511)"
                                  fill="#3064f9"
                                />
                                <path
                                  id="Path_9596"
                                  data-name="Path 9596"
                                  d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                                  transform="translate(-3548.643 18005.063)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="2"
                                />
                              </g>
                            </svg>
                            <p>Unlimited customers</p>
                          </div>

                          <div className="agency-feature-entry">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <g
                                id="Group_8367"
                                data-name="Group 8367"
                                transform="translate(-170 -1511)"
                              >
                                <circle
                                  id="Ellipse_4121"
                                  data-name="Ellipse 4121"
                                  cx="8"
                                  cy="8"
                                  r="8"
                                  transform="translate(170 1511)"
                                  fill="#3064f9"
                                />
                                <path
                                  id="Path_9596"
                                  data-name="Path 9596"
                                  d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                                  transform="translate(-3548.643 18005.063)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="2"
                                />
                              </g>
                            </svg>
                            <p>Unlimited apps</p>
                          </div>

                          <div className="agency-feature-entry">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <g
                                id="Group_8367"
                                data-name="Group 8367"
                                transform="translate(-170 -1511)"
                              >
                                <circle
                                  id="Ellipse_4121"
                                  data-name="Ellipse 4121"
                                  cx="8"
                                  cy="8"
                                  r="8"
                                  transform="translate(170 1511)"
                                  fill="#3064f9"
                                />
                                <path
                                  id="Path_9596"
                                  data-name="Path 9596"
                                  d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                                  transform="translate(-3548.643 18005.063)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="2"
                                />
                              </g>
                            </svg>
                            <p>Unlimited team members</p>
                          </div>

                          <div className="agency-feature-entry">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <g
                                id="Group_8367"
                                data-name="Group 8367"
                                transform="translate(-170 -1511)"
                              >
                                <circle
                                  id="Ellipse_4121"
                                  data-name="Ellipse 4121"
                                  cx="8"
                                  cy="8"
                                  r="8"
                                  transform="translate(170 1511)"
                                  fill="#3064f9"
                                />
                                <path
                                  id="Path_9596"
                                  data-name="Path 9596"
                                  d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                                  transform="translate(-3548.643 18005.063)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="2"
                                />
                              </g>
                            </svg>
                            <p>Unlimited app builds</p>
                          </div>

                          <div className="agency-feature-entry">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <g
                                id="Group_8367"
                                data-name="Group 8367"
                                transform="translate(-170 -1511)"
                              >
                                <circle
                                  id="Ellipse_4121"
                                  data-name="Ellipse 4121"
                                  cx="8"
                                  cy="8"
                                  r="8"
                                  transform="translate(170 1511)"
                                  fill="#3064f9"
                                />
                                <path
                                  id="Path_9596"
                                  data-name="Path 9596"
                                  d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                                  transform="translate(-3548.643 18005.063)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="2"
                                />
                              </g>
                            </svg>
                            <p>Unlimited app downloads</p>
                          </div>

                          <div className="agency-feature-entry">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <g
                                id="Group_8367"
                                data-name="Group 8367"
                                transform="translate(-170 -1511)"
                              >
                                <circle
                                  id="Ellipse_4121"
                                  data-name="Ellipse 4121"
                                  cx="8"
                                  cy="8"
                                  r="8"
                                  transform="translate(170 1511)"
                                  fill="#3064f9"
                                />
                                <path
                                  id="Path_9596"
                                  data-name="Path 9596"
                                  d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                                  transform="translate(-3548.643 18005.063)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="2"
                                />
                              </g>
                            </svg>
                            <p>Unlimited app users</p>
                          </div>

                          <div className="agency-feature-entry">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <g
                                id="Group_8367"
                                data-name="Group 8367"
                                transform="translate(-170 -1511)"
                              >
                                <circle
                                  id="Ellipse_4121"
                                  data-name="Ellipse 4121"
                                  cx="8"
                                  cy="8"
                                  r="8"
                                  transform="translate(170 1511)"
                                  fill="#3064f9"
                                />
                                <path
                                  id="Path_9596"
                                  data-name="Path 9596"
                                  d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                                  transform="translate(-3548.643 18005.063)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="2"
                                />
                              </g>
                            </svg>
                            <p>Unlimited app page views</p>
                          </div>

                          <div className="agency-feature-entry">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <g
                                id="Group_8367"
                                data-name="Group 8367"
                                transform="translate(-170 -1511)"
                              >
                                <circle
                                  id="Ellipse_4121"
                                  data-name="Ellipse 4121"
                                  cx="8"
                                  cy="8"
                                  r="8"
                                  transform="translate(170 1511)"
                                  fill="#3064f9"
                                />
                                <path
                                  id="Path_9596"
                                  data-name="Path 9596"
                                  d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                                  transform="translate(-3548.643 18005.063)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="2"
                                />
                              </g>
                            </svg>
                            <p>Unlimited push notifications</p>
                          </div>

                          <div className="agency-feature-entry">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <g
                                id="Group_8367"
                                data-name="Group 8367"
                                transform="translate(-170 -1511)"
                              >
                                <circle
                                  id="Ellipse_4121"
                                  data-name="Ellipse 4121"
                                  cx="8"
                                  cy="8"
                                  r="8"
                                  transform="translate(170 1511)"
                                  fill="#3064f9"
                                />
                                <path
                                  id="Path_9596"
                                  data-name="Path 9596"
                                  d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                                  transform="translate(-3548.643 18005.063)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="2"
                                />
                              </g>
                            </svg>
                            <p>Unlimited pages</p>
                          </div>

                          <div className="agency-feature-entry">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <g
                                id="Group_8367"
                                data-name="Group 8367"
                                transform="translate(-170 -1511)"
                              >
                                <circle
                                  id="Ellipse_4121"
                                  data-name="Ellipse 4121"
                                  cx="8"
                                  cy="8"
                                  r="8"
                                  transform="translate(170 1511)"
                                  fill="#3064f9"
                                />
                                <path
                                  id="Path_9596"
                                  data-name="Path 9596"
                                  d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                                  transform="translate(-3548.643 18005.063)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="2"
                                />
                              </g>
                            </svg>
                            <p>Unlimited posts</p>
                          </div>

                          <div className="agency-feature-entry">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <g
                                id="Group_8367"
                                data-name="Group 8367"
                                transform="translate(-170 -1511)"
                              >
                                <circle
                                  id="Ellipse_4121"
                                  data-name="Ellipse 4121"
                                  cx="8"
                                  cy="8"
                                  r="8"
                                  transform="translate(170 1511)"
                                  fill="#3064f9"
                                />
                                <path
                                  id="Path_9596"
                                  data-name="Path 9596"
                                  d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                                  transform="translate(-3548.643 18005.063)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="2"
                                />
                              </g>
                            </svg>
                            <p>Unlimited products</p>
                          </div>

                          <div className="agency-feature-entry">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <g
                                id="Group_8367"
                                data-name="Group 8367"
                                transform="translate(-170 -1511)"
                              >
                                <circle
                                  id="Ellipse_4121"
                                  data-name="Ellipse 4121"
                                  cx="8"
                                  cy="8"
                                  r="8"
                                  transform="translate(170 1511)"
                                  fill="#3064f9"
                                />
                                <path
                                  id="Path_9596"
                                  data-name="Path 9596"
                                  d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                                  transform="translate(-3548.643 18005.063)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="2"
                                />
                              </g>
                            </svg>
                            <p>Unlimited payments</p>
                          </div>

                          <div className="agency-feature-entry">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                            >
                              <g
                                id="Group_8367"
                                data-name="Group 8367"
                                transform="translate(-170 -1511)"
                              >
                                <circle
                                  id="Ellipse_4121"
                                  data-name="Ellipse 4121"
                                  cx="8"
                                  cy="8"
                                  r="8"
                                  transform="translate(170 1511)"
                                  fill="#3064f9"
                                />
                                <path
                                  id="Path_9596"
                                  data-name="Path 9596"
                                  d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                                  transform="translate(-3548.643 18005.063)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="2"
                                />
                              </g>
                            </svg>
                            <p>Unlimited data sync</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="blueback agencypricing paddingtop100 paddingbottom100 pricingfaq newagencypricingfaq">
                  <div className="container">
                    <h3 class="sectionheadings text-center">
                      Frequently asked questions
                    </h3>

                    <div class="accordionWrapper">
                      <div
                        class={`accordionItem ${
                          this.state.selectedfaqIndex === 0
                            ? "open"
                            : "close accordion-item"
                        }`}
                        eventKey="0"
                        style={{ marginBottom: "30px" }}
                      >
                        <h2
                          class="accordionItemHeading allflex accordion-header"
                          onClick={(e) => this.handleAccordianToggle(e, 0)}
                        >
                          What do you mean by ‘Unlimited Workspaces’?
                          {this.state.selectedfaqIndex === 0 ? (
                            <img
                              src={
                                require("../../assets/images/dashboard/faqminus.webp")
                                  .default
                              }
                              alt="pricing-type"
                              className="img-fluid"
                            />
                          ) : (
                            <img
                              src={
                                require("../../assets/images/dashboard/faqplus.webp")
                                  .default
                              }
                              alt="pricing-type"
                              className="img-fluid"
                            />
                          )}
                        </h2>
                        {this.state.selectedfaqIndex === 0 && (
                          <div class="accordionItemContent">
                            <p>
                              ‘Unlimited Workspaces’ is a plan suitable for
                              businesses that want to build unlimited apps. If
                              you purchase an Unlimited Workspaces plan, you’ll
                              be able to build any number of apps. All your apps
                              will be billed under one subscription. The plan
                              comes with a dedicated workspace where you can
                              create and manage all your apps. Please note that
                              with this plan, you can choose to pay for your
                              subscription on a monthly or yearly basis.
                            </p>
                          </div>
                        )}
                      </div>

                      <div
                        class={`accordionItem ${
                          this.state.selectedfaqIndex === 1
                            ? "open"
                            : "close accordion-item"
                        }`}
                        eventKey="1"
                        style={{ marginBottom: "30px" }}
                      >
                        <h2
                          class="accordionItemHeading allflex accordion-header"
                          onClick={(e) => this.handleAccordianToggle(e, 1)}
                        >
                          Are the prices including taxes?
                          {this.state.selectedfaqIndex === 1 ? (
                            <img
                              src={
                                require("../../assets/images/dashboard/faqminus.webp")
                                  .default
                              }
                              alt="pricing-type"
                              className="img-fluid"
                            />
                          ) : (
                            <img
                              src={
                                require("../../assets/images/dashboard/faqplus.webp")
                                  .default
                              }
                              alt="pricing-type"
                              className="img-fluid"
                            />
                          )}
                        </h2>
                        {this.state.selectedfaqIndex === 1 && (
                          <div class="accordionItemContent">
                            <p>
                              All AppMySite plans are inclusive of taxes. There
                              are no hidden charges.
                            </p>
                          </div>
                        )}
                      </div>

                      <div
                        class={`accordionItem ${
                          this.state.selectedfaqIndex === 2
                            ? "open"
                            : "close accordion-item"
                        }`}
                        eventKey="2"
                        style={{ marginBottom: "30px" }}
                      >
                        <h2
                          class="accordionItemHeading allflex accordion-header"
                          onClick={(e) => this.handleAccordianToggle(e, 2)}
                        >
                          Will my apps continue working if I stop paying my
                          subscription?
                          {this.state.selectedfaqIndex === 2 ? (
                            <img
                              src={
                                require("../../assets/images/dashboard/faqminus.webp")
                                  .default
                              }
                              alt="pricing-type"
                              className="img-fluid"
                            />
                          ) : (
                            <img
                              src={
                                require("../../assets/images/dashboard/faqplus.webp")
                                  .default
                              }
                              alt="pricing-type"
                              className="img-fluid"
                            />
                          )}
                        </h2>
                        {this.state.selectedfaqIndex === 2 && (
                          <div class="accordionItemContent">
                            <p>
                              Your app will be downgraded to the free Preview
                              plan when you stop paying your monthly or annual
                              subscription. In this case, your iOS apps will
                              stop working, and your Android apps will start
                              showing AppMySite branding.
                            </p>
                          </div>
                        )}
                      </div>

                      <div
                        class={`accordionItem ${
                          this.state.selectedfaqIndex === 3
                            ? "open"
                            : "close accordion-item"
                        }`}
                        eventKey="3"
                        style={{ marginBottom: "30px" }}
                      >
                        <h2
                          class="accordionItemHeading allflex accordion-header"
                          onClick={(e) => this.handleAccordianToggle(e, 3)}
                        >
                          Will AppMySite publish my apps as well?
                          {this.state.selectedfaqIndex === 3 ? (
                            <img
                              src={
                                require("../../assets/images/dashboard/faqminus.webp")
                                  .default
                              }
                              alt="pricing-type"
                              className="img-fluid"
                            />
                          ) : (
                            <img
                              src={
                                require("../../assets/images/dashboard/faqplus.webp")
                                  .default
                              }
                              alt="pricing-type"
                              className="img-fluid"
                            />
                          )}
                        </h2>
                        {this.state.selectedfaqIndex === 3 && (
                          <div class="accordionItemContent">
                            <p>
                              Once you finish building your app on AppMySite,
                              you can download your app build(s) and publish to
                              the app store(s). Furthermore, you can refer to
                              our{" "}
                              <a
                                target="_blank"
                                href="https://www.appmysite.com/support/"
                              >
                                Help Center
                              </a>{" "}
                              for documentation and video tutorials regarding
                              app submission to the app stores.
                            </p>
                          </div>
                        )}
                      </div>

                      <div
                        class={`accordionItem ${
                          this.state.selectedfaqIndex === 4
                            ? "open"
                            : "close accordion-item"
                        }`}
                        eventKey="4"
                        style={{ marginBottom: "30px" }}
                      >
                        <h2
                          class="accordionItemHeading allflex accordion-header"
                          onClick={(e) => this.handleAccordianToggle(e, 4)}
                        >
                          Does AppMySite offer custom app development services?
                          {this.state.selectedfaqIndex === 4 ? (
                            <img
                              src={
                                require("../../assets/images/dashboard/faqminus.webp")
                                  .default
                              }
                              alt="pricing-type"
                              className="img-fluid"
                            />
                          ) : (
                            <img
                              src={
                                require("../../assets/images/dashboard/faqplus.webp")
                                  .default
                              }
                              alt="pricing-type"
                              className="img-fluid"
                            />
                          )}
                        </h2>
                        {this.state.selectedfaqIndex === 4 && (
                          <div class="accordionItemContent">
                            <p>
                              We do not offer any form of custom development
                              services. AppMySite is a DIY platform where users
                              can sign up and create a mobile app on their own.
                            </p>
                          </div>
                        )}
                      </div>

                      <div
                        class={`accordionItem ${
                          this.state.selectedfaqIndex === 5
                            ? "open"
                            : "close accordion-item"
                        }`}
                        eventKey="5"
                        style={{ marginBottom: "30px" }}
                      >
                        <h2
                          class="accordionItemHeading allflex accordion-header"
                          onClick={(e) => this.handleAccordianToggle(e, 5)}
                        >
                          Do you have a refund policy?
                          {this.state.selectedfaqIndex === 5 ? (
                            <img
                              src={
                                require("../../assets/images/dashboard/faqminus.webp")
                                  .default
                              }
                              alt="pricing-type"
                              className="img-fluid"
                            />
                          ) : (
                            <img
                              src={
                                require("../../assets/images/dashboard/faqplus.webp")
                                  .default
                              }
                              alt="pricing-type"
                              className="img-fluid"
                            />
                          )}
                        </h2>
                        {this.state.selectedfaqIndex === 5 && (
                          <div class="accordionItemContent">
                            <p>
                              Click{" "}
                              <a
                                target="_blank"
                                href="https://www.appmysite.com/refund-policy/"
                              >
                                here
                              </a>{" "}
                              to learn more about our refund policy.
                            </p>
                          </div>
                        )}
                      </div>

                      <div className="hiddenfaq" id="showfaq">
                        <div
                          class={`accordionItem ${
                            this.state.selectedfaqIndex === 6
                              ? "open"
                              : "close accordion-item"
                          }`}
                          eventKey="6"
                          style={{ marginBottom: "30px" }}
                        >
                          <h2
                            class="accordionItemHeading allflex accordion-header"
                            onClick={(e) => this.handleAccordianToggle(e, 6)}
                          >
                            Do these plans also include the cost of creating
                            Apple and Google developer accounts?
                            {this.state.selectedfaqIndex === 6 ? (
                              <img
                                src={
                                  require("../../assets/images/dashboard/faqminus.webp")
                                    .default
                                }
                                alt="pricing-type"
                                className="img-fluid"
                              />
                            ) : (
                              <img
                                src={
                                  require("../../assets/images/dashboard/faqplus.webp")
                                    .default
                                }
                                alt="pricing-type"
                                className="img-fluid"
                              />
                            )}
                          </h2>
                          {this.state.selectedfaqIndex === 6 && (
                            <div class="accordionItemContent">
                              <p>
                                Once you finish creating an app on AppMySite,
                                you can submit your app to Google Play and Apple
                                App Store via developer accounts.
                              </p>
                              <p>
                                You’re required to create an Apple and Google
                                account on your own. Your AppMySite subscription
                                plan doesn’t include the cost of creating Apple
                                and Google developer accounts. Visit our{" "}
                                <a
                                  target="_blank"
                                  href="https://www.appmysite.com/support/"
                                >
                                  Help Center
                                </a>{" "}
                                to learn how to create an Apple and Google
                                developer account.
                              </p>
                            </div>
                          )}
                        </div>

                        <div
                          class={`accordionItem ${
                            this.state.selectedfaqIndex === 7
                              ? "open"
                              : "close accordion-item"
                          }`}
                          eventKey="7"
                          style={{ marginBottom: "30px" }}
                        >
                          <h2
                            class="accordionItemHeading allflex accordion-header"
                            onClick={(e) => this.handleAccordianToggle(e, 7)}
                          >
                            What is your cancellation policy?
                            {this.state.selectedfaqIndex === 7 ? (
                              <img
                                src={
                                  require("../../assets/images/dashboard/faqminus.webp")
                                    .default
                                }
                                alt="pricing-type"
                                className="img-fluid"
                              />
                            ) : (
                              <img
                                src={
                                  require("../../assets/images/dashboard/faqplus.webp")
                                    .default
                                }
                                alt="pricing-type"
                                className="img-fluid"
                              />
                            )}
                          </h2>
                          {this.state.selectedfaqIndex === 7 && (
                            <div class="accordionItemContent">
                              <p>
                                You can cancel your paid subscription and
                                downgrade to the free plan. Once canceled, you
                                will not be charged for any subsequent billing
                                cycles.
                              </p>

                              <p>
                                Your app will move to the free plan at the end
                                of your billing cycle.
                              </p>
                            </div>
                          )}
                        </div>

                        <div
                          class={`accordionItem ${
                            this.state.selectedfaqIndex === 8
                              ? "open"
                              : "close accordion-item"
                          }`}
                          eventKey="8"
                          style={{ marginBottom: "30px" }}
                        >
                          <h2
                            class="accordionItemHeading allflex accordion-header"
                            onClick={(e) => this.handleAccordianToggle(e, 8)}
                          >
                            What is a distribution license?
                            {this.state.selectedfaqIndex === 8 ? (
                              <img
                                src={
                                  require("../../assets/images/dashboard/faqminus.webp")
                                    .default
                                }
                                alt="pricing-type"
                                className="img-fluid"
                              />
                            ) : (
                              <img
                                src={
                                  require("../../assets/images/dashboard/faqplus.webp")
                                    .default
                                }
                                alt="pricing-type"
                                className="img-fluid"
                              />
                            )}
                          </h2>
                          {this.state.selectedfaqIndex === 8 && (
                            <div class="accordionItemContent">
                              <p>
                                Distribution licenses authorize you to download
                                your app from within your account for uploading
                                them to the app stores.
                              </p>
                            </div>
                          )}
                        </div>

                        <div
                          class={`accordionItem ${
                            this.state.selectedfaqIndex === 9
                              ? "open"
                              : "close accordion-item"
                          }`}
                          eventKey="9"
                          style={{ marginBottom: "30px" }}
                        >
                          <h2
                            class="accordionItemHeading allflex accordion-header"
                            onClick={(e) => this.handleAccordianToggle(e, 9)}
                          >
                            Do I need a developer account?
                            {this.state.selectedfaqIndex === 9 ? (
                              <img
                                src={
                                  require("../../assets/images/dashboard/faqminus.webp")
                                    .default
                                }
                                alt="pricing-type"
                                className="img-fluid"
                              />
                            ) : (
                              <img
                                src={
                                  require("../../assets/images/dashboard/faqplus.webp")
                                    .default
                                }
                                alt="pricing-type"
                                className="img-fluid"
                              />
                            )}
                          </h2>
                          {this.state.selectedfaqIndex === 9 && (
                            <div class="accordionItemContent">
                              <p>
                                Yes, depending on your plan you’ll need
                                developer accounts on the Google Play Store
                                and/or the Apple App Store in order to publish
                                your apps.
                              </p>

                              <p>
                                Once you build your apps, you’ll be able to
                                download your APK or AAB files (for your Android
                                apps) and an IPA file (for your iOS apps) which
                                then can be uploaded to your developer accounts.
                                You can also request AppMySite to publish your
                                apps for you, by simply purchasing the Upload to
                                App Stores add on.
                              </p>
                            </div>
                          )}
                        </div>

                        <div
                          class={`accordionItem ${
                            this.state.selectedfaqIndex === 10
                              ? "open"
                              : "close accordion-item"
                          }`}
                          eventKey="10"
                          style={{ marginBottom: "30px" }}
                        >
                          <h2
                            class="accordionItemHeading allflex accordion-header"
                            onClick={(e) => this.handleAccordianToggle(e, 10)}
                          >
                            How much time does it take to publish an app?
                            {this.state.selectedfaqIndex === 10 ? (
                              <img
                                src={
                                  require("../../assets/images/dashboard/faqminus.webp")
                                    .default
                                }
                                alt="pricing-type"
                                className="img-fluid"
                              />
                            ) : (
                              <img
                                src={
                                  require("../../assets/images/dashboard/faqplus.webp")
                                    .default
                                }
                                alt="pricing-type"
                                className="img-fluid"
                              />
                            )}
                          </h2>
                          {this.state.selectedfaqIndex === 10 && (
                            <div class="accordionItemContent">
                              <p>
                                Once an app is submitted to the relevant app
                                store, it is subject to a review from their end.
                                Both stores generally take 24-48 hours but could
                                take longer depending on various factors. You
                                can find more details on their official
                                websites.
                              </p>
                            </div>
                          )}
                        </div>
                      </div>

                      <h5 id="seemorelink" onClick={this.seemorefunction}>
                        See More
                      </h5>

                      <h5
                        id="seelesslink"
                        class="d-none"
                        onClick={this.seelessfunction}
                      >
                        See Less
                      </h5>
                    </div>
                  </div>
                </section>
              </>
            )}
          </div>
        )}

          {this.state.spinner ?
            <></>
            :
            <>
                {/* Downgrade Pop Up */}
                <Modal
                  isOpen={this.state.deluser}
                  centered
                  toggle={(e) => this.removepopup(e)}
                  style={{ maxWidth: "480px", maxHeight: "296px" }}
                  className="plan-cancel-popup"
                  dataclassName="addonpopup"
                >
                  <div className="crossmodal mt-1" onClick={(e) => this.removepopup()}>
                    <img
                      src={require("../../assets/images/dashboard/cross.png").default}
                      alt="cross"
                    />
                  </div>
                  <ModalBody className="">
                    <div>
                      <div className="d-flex">
                        <div className="confirmtext">
                          <h4>Plan change confirmation</h4>
                          {moment().format("D MMMM YYYY") ==
                          moment(this.state.endcycledata).format("D MMMM YYYY") ? (
                            <>
                              <h6>Your plan renewal is being processed today.</h6>
                              <h6>
                                If you proceed, your current will change to Free Plan at
                                the end of your next billing cycle.
                              </h6>
                            </>
                          ) : (
                            <h6>
                              Your current plan will change at the end of your billing
                              cycle, on{" "}
                              <span>
                                {moment(this.state.endcycledata).format("D MMMM YYYY")}
                              </span>{" "}
                            </h6>
                          )}
                        </div>
                      </div>
                      <div className="plan-cancel-popup-footer d-flex justify-content-center">
                        <div
                          className="plan-cancelbtn"
                          onClick={(e) => this.removepopup()}
                        >
                          Cancel
                        </div>

                        <div
                          onClick={(e) =>
                            this.state.customer_type === 4
                              ? this.handleWooCustomer(
                                  e,
                                  this.state.customer_type,
                                  this.state.one_time[0].plan_code
                                )
                              : this.previewdowngrade(e,"")
                          }
                        >

                          <div className="d-flex align-items-center justify-content-center h-100 w-100">
                            

                          <div className="plan-cacel-confirm d-flex align-items-center justify-content-center">
                            <div className={this.state.downgradeLoader}>
                              <div className="new-red-loader"></div>
                            </div>
                            {this.state.downgradeSubmitText}
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                </Modal>

                {/* Downgrade Pop Up */}
                <Modal
                  isOpen={this.state.downgradeuser}
                  centered
                  toggle={(e) => this.downgradepopupdeal(e)}
                  style={{ maxWidth: "480px", maxHeight: "296px" }}
                  className="plan-cancel-popup"
                  dataclassName="addonpopup"
                >
                  <div
                    className="crossmodal mt-1"
                    onClick={(e) => this.downgradepopupdeal()}
                  >
                    <img
                      src={require("../../assets/images/dashboard/cross.png").default}
                      alt="cross"
                    />
                  </div>
                  <ModalBody className="">
                    <div>
                      <div className="d-flex">
                        <div className="confirmtext">
                          <h6>
                            Are you sure you want to downgrade to the Preview plan?{" "}
                          </h6>
                          <h6>
                            Your subscription will automatically be downgraded at the
                            end of your subscription.{" "}
                          </h6>
                        </div>
                      </div>
                      <div className="plan-cancel-popup-footer d-flex justify-content-center">
                        <div
                          className="plan-cancelbtn"
                          onClick={(e) => this.downgradepopupdeal()}
                        >
                          Cancel
                        </div>

                        <div
                          onClick={(e) =>
                            this.state.customer_type === 4
                              ? this.handleWooCustomer(
                                  e,
                                  this.state.customer_type,
                                  this.state.one_time[0].plan_code
                                )
                              : this.previewdowngrade(e,"")
                          }
                        >
                          <div className="d-flex align-items-center justify-content-center h-100 w-100">
                            

                            <div className="plan-cacel-confirm d-flex align-items-center justify-content-center">
                              <div className={this.state.downgradeLoader}>
                                <div className="new-red-loader"></div>
                              </div>
                              {this.state.downgradeSubmitText}
                            </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                </Modal>

                {/* Reactivate Pop Up */}
                <Modal
                  isOpen={this.state.openReactivatePopUp}
                  style={{ maxWidth: "460px", maxHeight: "240px" }}
                  className="generalstngs2"
                  centered
                >
                  <ModalHeader>
                    <div
                      className="crossmodal mt-1"
                      onClick={(e) => this.handleReactivePopUp(e, "cancel")}
                    >
                      <img
                        src={require("../../assets/images/dashboard/cross.png").default}
                      />
                    </div>
                  </ModalHeader>

                  <ModalBody>
                    <div>
                      <h4>Stay on current plan?</h4>
                      <h6>
                        Are you sure you want to cancel your plan change request and
                        retain your current plan?
                      </h6>

                      <div className="generalstngs2-footer">
                        <div
                          className="generalstngs2-cancel"
                          onClick={(e) => this.handleReactivePopUp(e, "cancel")}
                        >
                          <p>Cancel</p>
                        </div>

                        <div
                          className="generalstngs2-ok"
                          onClick={(e) => this.handleReactivePopUp(e, "done")}
                        >
                          <div className={this.state.reactiveLoader}>
                            <div className="new-loader"></div>
                          </div>
                          <p>{this.state.reactiveSubmitText}</p>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                </Modal>
            </>
          }
      </>
    );
  }
}
