import React, { useEffect, useState, Suspense } from "react";
import Header from "../../Header";
import Leftpanel from "../../Leftpanel";
import "./newPublish.css";
import imagebackground from "../../../assets/images/dashboard/cms/uploadbackground.png";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalFooter,
  Media,
} from "reactstrap";

import styles from "./publish.module.css";

import Tooltip from "../../Tooltip";
import Notappleaccount from "./Notappleaccount";


import History from "./History";
import Storeconnection from "./Storeconnection";


import { Creation } from "./Context";
import { Link } from "react-router-dom";
import Errormessage from "../../Reusable/Hookerror";
import { Notallowedtouser } from "../../User/Usernotalllowed";
import { InnerFoot } from "../../Foot";
import { Publishform, Publishget, UnlinkMergeApp } from "../../../Api/Publishapp/NewPublishApi";
import moment from "moment";
import { errortoast } from "../../Toaster";
import { Unlinkapp } from "../../../Api/Multisite/Multisiteapi";
import SkipBtn from "../../../Components/commonIcons/SkipBtn";
import PremiumTag from "../../../Components/commonIcons/PremiumTag";
const Notaddonactive = React.lazy(() => import("./Notaddonactive"));

export const NewPublishiOS = () => {
  const [errormodal, setErrormodal] = useState(false);
  const [title, settitle] = useState("");
  const [errormessage, seterrormessage] = useState("");
  const [count] = useState(1);
  const [submitval, setsubmit] = useState("Submit");
  const [submitreview, setsubmitreview] = useState("Submit for review");
  const [submitreviewloader, setsubmitreviewloader] = useState("d-none");
  const [loader, setLoader] = useState("d-none");
  const [loaderimage] = useState(
    require("../../../assets/images/signup/Loader_GIF.gif").default
  );
  // const [submitreviewloaderimage] = useState(require( "../../../assets/images/signup/Loader_GIF.gif").default);
  const [opencreationpopup, setPopup] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [data, setData] = useState(null);
  const [last_successful_build, setlast_successful_build] = useState(null);
  const [whatnew, setnew] = useState("");
  const [is_build_process, setis_build_process] = useState(0);
  const [publishing_logs, setpublishing_logs] = useState([]);
  const [code, setCode] = useState(200);
  const [plan_code, setplan_code] = useState("");
  const [addon_code, setaddon_code] = useState("");
  const [reseller_package_id, setreseller_package_id] = useState(null);
  const [enableScreen, setEnableScreen] = useState(null);
  const [devicetype, setDeviceType] = useState(null);
  const [appid, setAppid] = useState("");
  const [appuser_role_id, setappuser_role_id] = useState(null);
  // const [verifyBtnText, setVerifyBtnText] = useState("Verify");
  const [saveBtnText, setSaveBtnText] = useState("Save");
  // const [loaderForVerifyBtn, setLoaderForVerifyBtn] = useState("d-none");
  const [loaderForSaveBtn, setLoaderForSaveBtn] = useState("d-none");
  const [openErrPopUp, setOpenErrPopUp] = useState(false);
  const [openApiErrPopUp, setOpenApiErrPopUp] = useState(false);
  const [openApiSuccessPopUp, setOpenApiSuccessPopUp] = useState(false);
  const [whtasNewErr, setWhtasNewErr] = useState(false);
  const [updatedAt, setUpdatedAt] = useState("");
  const [bottomSpinner, setBottomSpinner] = useState(false);
  const [multisiteData, setMultisiteData] = useState(null);
  const [openUnlinkPopUp, setOpenUnlinkPopUp] = useState(false);
  const [confirmBtnText, setConfirmBtnText] = useState("Confirm");
  const [confirmBtnLoader, setConfirmBtnLoader] = useState("d-none");
  const [errDescription, setErrDescription] = useState("");
  const [openWarningCertificatePopUp, setOpenWarningCertificatePopUp] = useState(false);
  const [isUserAgree, setIsUserAgree] = useState(false);
  const [skipButtonActive,setSkipButtonActive] = useState(false)
  
  
  const [appRightPanelExpand, setAppRightPanelExpand] = useState( localStorage.getItem("appSideBarCollapse") === true ||
	localStorage.getItem("appSideBarCollapse") === "true"
	  ? true
	  : false);
  const appPlan= localStorage.getItem("app_plan")


  const load = (hittype, isSubscribed) => {
    if (hittype === true) {
      const params = {
        app_id: btoa(localStorage.getItem("Appid")),
      };
      const interval = setTimeout(() => {
        Publishget(
          params,
          setData,
          setAppid,
          setSpinner,
          setlast_successful_build,
          setis_build_process,
          setpublishing_logs,
          setCode,
          setplan_code,
          setaddon_code,
          setreseller_package_id,
          setnew,
          load,
          setLoader,
          setsubmit,
          setEnableScreen,
          setDeviceType,
          setsubmitreview,
          setsubmitreviewloader,
          setappuser_role_id,
          setBottomSpinner,
          setMultisiteData,
          setOpenApiSuccessPopUp,
          setOpenApiErrPopUp,
          "timeout",
        );
      }, 30000);
      return () => clearTimeout(interval);
    } else {
      // setSpinner(true);

      const params = {
        app_id: btoa(localStorage.getItem("Appid")),
      };
      Publishget(
        params,
        setData,
        setAppid,
        setSpinner,
        setlast_successful_build,
        setis_build_process,
        setpublishing_logs,
        setCode,
        setplan_code,
        setaddon_code,
        setreseller_package_id,
        setnew,
        load,
        setLoader,
        setsubmit,
        setEnableScreen,
        setDeviceType,
        setsubmitreview,
        setsubmitreviewloader,
        setappuser_role_id,
        setBottomSpinner,
        setMultisiteData,
        setOpenApiSuccessPopUp,
        setOpenApiErrPopUp,
        "not-timeout"
      );
    }
  };

  useEffect(() => {
    if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
    {
      document.title = "Publish | AppMySite";
    }
    else
    {
      document.title = "Publish |"+ ' ' +localStorage.getItem('agencyname');      
    }
    load(false);
  }, [count]);

  const handleErrPopUp = (e,des) => {
    setOpenErrPopUp(!openErrPopUp);
    if(des){
      setErrDescription(des);
    }
  }
  const handleAPIErrPopUp = (e) => {
    setOpenApiErrPopUp(!openApiErrPopUp);
  }
  const handleAPISuccessPopUp = (e) => {
    setOpenApiSuccessPopUp(!openApiSuccessPopUp);
  }


  const handleUnLinkPopUp = (e,from) => {

   
    if(from==="confirm"){
      setConfirmBtnLoader("d-block");
      setConfirmBtnText("");

      const params = {
        app_id:parseInt(localStorage.getItem("Appid"))
      }
      UnlinkMergeApp(params,setConfirmBtnLoader,setConfirmBtnText);
    }else{
      setOpenUnlinkPopUp(!openUnlinkPopUp);
    }
  }


  const handlenew = (e) => {
    if(e.target.value.trim()){
     setWhtasNewErr(false);
    }
    let value = e.target.value.replace(/[^\w\s,.]/g, "");
    setnew(value);
  };
  const publishformSubmit = (e, submitvalue) => {
    // e.preventDefault();
    const params = {
      app_id: parseInt(localStorage.getItem("Appid")),
      action: "step2",
      submit_for_review: submitvalue,
      whats_new: whatnew,
      status:1,
    };
    if (is_build_process === 0 && last_successful_build === null) {
      errortoast("You have not created a build yet");
      // settitle("Create Build");
      // setErrormodal(true);
      // seterrormessage("You have not created a build yet ");
    } else 
    {
      if (is_build_process === 0) {
        if (whatnew.trim() === "") {
          setWhtasNewErr(true);
          // settitle("What's new in this version");
          // setErrormodal(true);
          // seterrormessage("Please fill this field");
        } else {
          if (last_successful_build !== null && data !== null && data.publishing_connectivity_status ===2) {
            if (data.publishing_status !== 4 || data.publishing_status !== 1 || data.publishing_status !== 2) {
              setOpenWarningCertificatePopUp(false);
              let newData = {...data};
              newData.publishing_status = 1;
              setData(newData);
              Publishform(
                params,
                setLoader,
                setsubmit,
                load,
                setSaveBtnText,
                setLoaderForSaveBtn,
                setUpdatedAt,
                setBottomSpinner,
                data,
                setData,
                "submit",
              );
            }
          } else {
            setOpenWarningCertificatePopUp(false);
            let newData = {...data};
            newData.publishing_status = 1;
            setData(newData);
            Publishform(
              params,
              setLoader,
              setsubmit,
              load,
              setSaveBtnText,
              setLoaderForSaveBtn,
              setUpdatedAt,
              setBottomSpinner,
              data,
              setData,
              "submit",
            );
          }
        }
      } else {
        errortoast("Your build is still processing.");
        // setErrormodal(true);
        // settitle("Build Processing");
        // seterrormessage("Your build is still processing.");
      }
    }
  };

  const publishformDraft = (e, submitvalue) => {
    // e.preventDefault();
    const params = {
      app_id: parseInt(localStorage.getItem("Appid")),
      action: "step2",
      submit_for_review: submitvalue,
      whats_new: whatnew,
      status:0,
    };

        if (whatnew.trim() === "") {
          setWhtasNewErr(true);
        } else {
            // setBottomSpinner(true);
            Publishform(
              params,
              setLoader,
              setsubmit,
              load,
              setSaveBtnText,
              setLoaderForSaveBtn,
              setUpdatedAt,
              setBottomSpinner,
              data,
              setData,
              "draft",
            );
          
        
      } 
    
  };
  const handleappcreationpopup = (e) => {
    if (is_build_process === 0) {
      setPopup(true);
    } else {
      setErrormodal(true);
      settitle("Build Processign");
      seterrormessage("Your build is still processing.");
    }
  };

  const Errortext = {
    title: title,
    errormessage: errormessage,
    errormodal: errormodal,
    setErrormodal: setErrormodal,
  };

  const handleRightPanelExpand = (value) => {

		setAppRightPanelExpand(value);
	  }

    const handleOpenWarningCertificatePopUp = (e) => {
      setOpenWarningCertificatePopUp(!openWarningCertificatePopUp);
     }
     const handleUserAgree = (e) => {
      setIsUserAgree(e.target.checked === true ? true : false);
     }
     
    return (
      <>
        <Header customclass={"header-responsive-new"} />
        <section className="newAdvertisment-page">
        <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
          <div
            className={`right-panel-gap ${localStorage.getItem("appSideBarCollapse") !== undefined &&
              localStorage.getItem("appSideBarCollapse") !== null &&
              localStorage.getItem("appSideBarCollapse") === "true"
              ? " right-panel-gap-expand"
              : ""
              }`}
          >
            <div className="publish-head">
              <h1 className='publish-headh'>Publish
                <p className=" consent-tooltip">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                    <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                      <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                        <circle cx="9" cy="9" r="9" stroke="none" />
                        <circle cx="9" cy="9" r="8.25" fill="none" />
                      </g>
                      <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5" />
                      <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5" />
                    </g>
                  </svg>
                  <p className="consent-tooltipsubheader">
                  Submit updates to the app stores effortlessly, track publishing history and celebrate successful submissions with instant notifications.
                  </p>
                </p></h1>
                <h1 className='mob-publish-headh'>iOS</h1>
              {/* <h1 className='publish-headh'>Ios</h1>
                <h1 className='publish-headh'>Android</h1> */}

                
                {
                  spinner === false &&
                <div className="mob-conn-publish-top">
              {skipButtonActive &&  <PremiumTag/>}
              
               { ( code !== 200 && !skipButtonActive  )?
               <></>:
               (!multisiteData || multisiteData.is_app_link_to_multisite !== 1 ) &&
               <Link  to={{
                pathname: "/app/app-store-connection/publish",
                state: { skipButtonActive:skipButtonActive }
              }}
               style={{
                 marginRight:"0px",
                 marginLeft: "20px",
                 position:"relative",
                 height: "40px",
                 marginTop: "-9px"
               }}>
                {((code === 200) &&
                              data !== null && data.publishing_connectivity_status ===2  &&
                              (data.publishing_status === 1 || data.publishing_status === 2)) ? 
                              <></>:
                              (code === 200|| skipButtonActive) ?
              <div className={`mob-publish-iossvgdiv ${( data === null || data.publishing_connectivity_status !==2) ?"red-publish-iossvgdiv":""}`} style={{marginTop: "0px"}}>
                {
                  ( data === null || data.publishing_connectivity_status !==2)
                ?
               
                <svg xmlns="http://www.w3.org/2000/svg" width="26.567" height="23.465" viewBox="0 0 26.567 23.465">
                    <g id="Group_41817" data-name="Group 41817" transform="translate(-1462 -125)">
                      <g id="app-store-1_2_" data-name="app-store-1 (2)" transform="translate(1462 125)">
                        <path id="Path_84796" data-name="Path 84796" d="M160,216.791h0l-1.283,2.222a1.7,1.7,0,0,1-2.938-1.7l.945-1.637.091-.157a1.4,1.4,0,0,1,1.361-.559s1.882.2,2.018,1.182A1.144,1.144,0,0,1,160,216.791Zm18.2-5.676h-4a.59.59,0,0,1-.438-.172l0-.005-4.283-7.418-.006,0-.257-.368c-.421-.644-1.089,1-1.089,1a5.75,5.75,0,0,0,.431,4.551l5.949,10.3a1.7,1.7,0,1,0,2.938-1.7l-1.488-2.577c-.029-.062-.079-.232.226-.233H178.2a1.7,1.7,0,1,0,0-3.392Zm-7.771,2.306s.214,1.086-.613,1.086H155.028a1.7,1.7,0,0,1,0-3.392h3.8c.614-.036.759-.39.759-.39l0,0,4.964-8.6h0a.379.379,0,0,0,0-.348l-1.639-2.839a1.7,1.7,0,1,1,2.938-1.7l.76,1.317.759-1.314a1.7,1.7,0,1,1,2.938,1.7l-6.907,11.963c-.03.073-.039.187.185.207h4.129v.04A2.773,2.773,0,0,1,170.432,213.421Z" transform="translate(-153.332 -196.398)" fill="#7782a1" />
                      </g>
                    </g>
                  </svg>
                  :
                  <svg xmlns="http://www.w3.org/2000/svg" width="26.567" height="23.465" viewBox="0 0 26.567 23.465">
                  <defs>
                    <linearGradient id="linear-gradient2" x1="0.5" y1="1" x2="0.5" gradientUnits="objectBoundingBox">
                      <stop offset="0" stop-color="#1d76f2" />
                      <stop offset="1" stop-color="#1ac2fb" />
                    </linearGradient>
                  </defs>
                  <path id="Path_84796" data-name="Path 84796" d="M160,216.791h0l-1.283,2.222a1.7,1.7,0,0,1-2.938-1.7l.945-1.637.091-.157a1.4,1.4,0,0,1,1.361-.559s1.882.2,2.018,1.182A1.144,1.144,0,0,1,160,216.791Zm18.2-5.676h-4a.59.59,0,0,1-.438-.172l0-.005-4.283-7.418-.006,0-.257-.368c-.421-.644-1.089,1-1.089,1a5.75,5.75,0,0,0,.431,4.551l5.949,10.3a1.7,1.7,0,1,0,2.938-1.7l-1.488-2.577c-.029-.062-.079-.232.226-.233H178.2a1.7,1.7,0,1,0,0-3.392Zm-7.771,2.306s.214,1.086-.613,1.086H155.028a1.7,1.7,0,0,1,0-3.392h3.8c.614-.036.759-.39.759-.39l0,0,4.964-8.6h0a.379.379,0,0,0,0-.348l-1.639-2.839a1.7,1.7,0,1,1,2.938-1.7l.76,1.317.759-1.314a1.7,1.7,0,1,1,2.938,1.7l-6.907,11.963c-.03.073-.039.187.185.207h4.129v.04A2.773,2.773,0,0,1,170.432,213.421Z" transform="translate(-153.332 -196.398)" fill="url(#linear-gradient2)" />
                </svg>
               }
              </div>:null
            }
              </Link>
             }
             {
             (!multisiteData || multisiteData.is_app_link_to_multisite !== 1 ) &&
             <>
             {/* {
              (code === 200 && data !== null && data.publishing_connectivity_status === 2 &&
                (data.publishing_status === 1 || data.publishing_status === 2)) ?
                <></>
                : 
                    <div className='mob-monetisation-tech-clicked'
                    style={{
                      marginTop:"-10px"
                    }}
                    >
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                  <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                    <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                    <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="#7782a1" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                  </g>
                </svg>

                </div>
                } */}
                </>
                }
               </div>
               }
        
            </div>
            {
                (localStorage.getItem("agencyid") && localStorage.getItem("agencyid") != 1) && code !== 200 &&
                spinner === false &&
                window.matchMedia("(max-width: 1200px)").matches &&
              <div className="" style={{marginBottom: "15px"}}>
                      <h4 className="upgrade-unavailable">This feature is currently unavailable</h4>
                      <p className="upgrade-support">Please contact Support for assistance.</p>
                    </div> }
            <div className="consent-modal-tab ">
              <div className='publish-modal-tab' style={{lineHeight:"38px" }}>

                <Link className="active" to="/app/publish/ios" style={{ "border-bottom": localStorage.getItem('agencyid') == 1 ? "2px solid #3064f9" : "2px solid " + localStorage.getItem('agency_primary_color'), "color": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color'), "--custom_color4": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color'), "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color')}}>iOS</Link>
                <Link to="/app/publish/android" style={{ "--custom_color4": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color'), "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color') }}>Android</Link>
              </div>
              { ( code !== 200 && !skipButtonActive )?
              <></>:
              (!multisiteData || multisiteData.is_app_link_to_multisite !== 1 ) && spinner === false &&
              <div className='publish-tab-right'>
                {(code === 200 &&
                             data !== null && data.publishing_connectivity_status ===2 &&
                              (data.publishing_status === 1 || data.publishing_status === 2)) ? 
                              <></>:
                    
                     data && data.updated_at &&
                
              <p className='publishweb-date'>Updated{" "}
              {moment(data.updated_at).format("D MMMM YYYY, HH:mm")}</p>
                }

                         {(code === 200 &&
                             data !== null && data.publishing_connectivity_status ===2 &&
                              (data.publishing_status === 1 || data.publishing_status === 2)) ? 
                              <></>:
                <>
                {appPlan !== "premium" && skipButtonActive && <PremiumTag/> }
                  <div className='publish-technology'>
                          <Link   to={{
                                  pathname: "/app/app-store-connection/publish",
                                  state: { skipButtonActive:skipButtonActive }
                                }}
                          style={{
                            marginRight:"0px",
                            position:"relative",
                            marginLeft: "20px",
                          }}
                          >
                          {
                           ( data === null || data.publishing_connectivity_status !==2) ?
                            <>
                            
                            
                            <svg xmlns="http://www.w3.org/2000/svg" width="26.567" height="23.465" viewBox="0 0 26.567 23.465">
                            <g id="Group_41817" data-name="Group 41817" transform="translate(-1462 -125)">
                              <g id="app-store-1_2_" data-name="app-store-1 (2)" transform="translate(1462 125)">
                                <path id="Path_84796" data-name="Path 84796" d="M160,216.791h0l-1.283,2.222a1.7,1.7,0,0,1-2.938-1.7l.945-1.637.091-.157a1.4,1.4,0,0,1,1.361-.559s1.882.2,2.018,1.182A1.144,1.144,0,0,1,160,216.791Zm18.2-5.676h-4a.59.59,0,0,1-.438-.172l0-.005-4.283-7.418-.006,0-.257-.368c-.421-.644-1.089,1-1.089,1a5.75,5.75,0,0,0,.431,4.551l5.949,10.3a1.7,1.7,0,1,0,2.938-1.7l-1.488-2.577c-.029-.062-.079-.232.226-.233H178.2a1.7,1.7,0,1,0,0-3.392Zm-7.771,2.306s.214,1.086-.613,1.086H155.028a1.7,1.7,0,0,1,0-3.392h3.8c.614-.036.759-.39.759-.39l0,0,4.964-8.6h0a.379.379,0,0,0,0-.348l-1.639-2.839a1.7,1.7,0,1,1,2.938-1.7l.76,1.317.759-1.314a1.7,1.7,0,1,1,2.938,1.7l-6.907,11.963c-.03.073-.039.187.185.207h4.129v.04A2.773,2.773,0,0,1,170.432,213.421Z" transform="translate(-153.332 -196.398)" fill="#7782a1" />
                              </g>
                            </g>
                          </svg>
                          <div className='publish-red-color'></div>
                          </>
                          :
                          
                        <svg xmlns="http://www.w3.org/2000/svg" width="26.567" height="23.465" viewBox="0 0 26.567 23.465">
                          <defs>
                            <linearGradient id="linear-gradient" x1="0.5" y1="1" x2="0.5" gradientUnits="objectBoundingBox">
                              <stop offset="0" stop-color="#1d76f2" />
                              <stop offset="1" stop-color="#1ac2fb" />
                            </linearGradient>
                          </defs>
                          <path id="Path_84796" data-name="Path 84796" d="M160,216.791h0l-1.283,2.222a1.7,1.7,0,0,1-2.938-1.7l.945-1.637.091-.157a1.4,1.4,0,0,1,1.361-.559s1.882.2,2.018,1.182A1.144,1.144,0,0,1,160,216.791Zm18.2-5.676h-4a.59.59,0,0,1-.438-.172l0-.005-4.283-7.418-.006,0-.257-.368c-.421-.644-1.089,1-1.089,1a5.75,5.75,0,0,0,.431,4.551l5.949,10.3a1.7,1.7,0,1,0,2.938-1.7l-1.488-2.577c-.029-.062-.079-.232.226-.233H178.2a1.7,1.7,0,1,0,0-3.392Zm-7.771,2.306s.214,1.086-.613,1.086H155.028a1.7,1.7,0,0,1,0-3.392h3.8c.614-.036.759-.39.759-.39l0,0,4.964-8.6h0a.379.379,0,0,0,0-.348l-1.639-2.839a1.7,1.7,0,1,1,2.938-1.7l.76,1.317.759-1.314a1.7,1.7,0,1,1,2.938,1.7l-6.907,11.963c-.03.073-.039.187.185.207h4.129v.04A2.773,2.773,0,0,1,170.432,213.421Z" transform="translate(-153.332 -196.398)" fill="url(#linear-gradient)" />
                        </svg>  }
                        </Link>

                        
                        </div>
</>
                     }


                        {/* {
                        (code === 200 && data !== null && data.publishing_connectivity_status === 2 &&
                          (data.publishing_status === 1 || data.publishing_status === 2)) ?
                          <></>
                          : 
                          
                        <div className='web-monetisation-tech-clicked 11'
                         style={{
                          marginRight:"20px",
                          marginLeft:"0px"
                        }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                          <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                            <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                            <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="#7782a1" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                          </g>
                        </svg>

                        </div>
                      
                       } */}
              {/* {              (data !== null && data.publishing_connectivity_status ===2 &&
                              (data.publishing_status === 0 ||
                                data.publishing_status === 3 ||
                                (last_successful_build !== null &&
                                  data.build_trigger_id !==
                                    last_successful_build.id))
                              ) && ( */}
                                      <>
                              {(code === 200 &&
                              data !== null && data.publishing_connectivity_status ===2 &&
                              (data.publishing_status === 1 || data.publishing_status === 2))? 
                                <button className={`publishsave-preview  web-publish-disable processing-publish-btn-web`} 
                                  
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "14px",
                        fontFamily: 'Axiforma-Regular',
                        cursor:"default",
                      border:
                        "1px solid " +
                        localStorage.getItem("button_background_color"),
                      color: localStorage.getItem("button_text_color"),
                      "--custom_color4":
                        localStorage.getItem("agencyid") == 1
                          ? "white"
                          : localStorage.getItem("button_hover_txtcolor"),
                      "--custom_color6":
                        localStorage.getItem("agencyid") == 1
                          ? "#3064f9"
                          : localStorage.getItem("button_hover_bgcolor"),
                    }}
                   
                
                     > 
                     <div className='new-grey-loader' style={{margin:"unset", marginRight:"6px"}}></div>
                     Uploading...
                  </button>
                  :
            
               <>
              <button className={`publishsave-preview  ${( data === null || data.publishing_connectivity_status !==2)?'web-publish-disable  ':'web-publish-enable '}`} 
                              
                  style={{
                  border:
                    "1px solid " +
                    localStorage.getItem("button_background_color"),
                  color: localStorage.getItem("button_text_color"),
                  "--custom_color4":
                    localStorage.getItem("agencyid") == 1
                      ? "white"
                      : localStorage.getItem("button_hover_txtcolor"),
                  "--custom_color6":
                    localStorage.getItem("agencyid") == 1
                      ? "#3064f9"
                      : localStorage.getItem("button_hover_bgcolor"),
                }}
                disabled={ ( data === null || data.publishing_connectivity_status !==2) ? true : false }
                  onClick={(e) => publishformDraft(e, 0)}
                 > 
                 <div className={loaderForSaveBtn}>
                        <div className="new-blue-loader"></div>
                      </div>
                      <div 
                      // style={{
                      //    color:( this.state.adType || this.state.adName.trim() ||
                      //           this.state.androidAdUnitId.trim() ||
                      //           this.state.iosAdUnitId.trim()
                      //          )
                      //      ? localStorage.getItem("button_background_color")
                      //      : null ,
                      // }}
              >{saveBtnText}</div>
              </button>

              <button 
                style={{width: "100px"}}
                  className={`publishsubmit-preview ${(data !== null && data.publishing_connectivity_status ===2 &&
                    (data.publishing_status === 0 ||
                      data.publishing_status === 3 ||
                      (last_successful_build !== null &&
                        data.build_trigger_id !==
                          last_successful_build.id))
                    )?'web-publish-enable':' web-publish-disable'}  `}
                disabled={(data !== null && data.publishing_connectivity_status ===2 &&
                  (data.publishing_status === 0 ||
                    data.publishing_status === 3 ||
                    (last_successful_build !== null &&
                      data.build_trigger_id !==
                        last_successful_build.id))
                  )?false:true}  
                  // onClick={(e) => publishformSubmit(e, 0)}
                  onClick={(e) => handleOpenWarningCertificatePopUp(e)}
                  >
                  <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                        <div className={loader}>
                                        <div className="new-loader"></div>
                                        </div>{" "}
                                        <div>{submitval}</div>
                                      </div>

              </button>

              {
                    ( data === null || data.publishing_connectivity_status !==2)?
                    <button 
                    className={` publishsubmit-preview web-publish-disable`}
                    style={{
                      cursor:"not-allowed",
                      
                    }}
                    disabled={true}
                    >
                      Submit for review{" "}
                      <svg style={{marginLeft: "6px"}} xmlns="http://www.w3.org/2000/svg" width="11.2" height="11.2" viewBox="0 0 11.2 11.2">
  <g id="Group_9158" data-name="Group 9158" transform="translate(-695.4 -246.4)">
    <path id="Path_10239" data-name="Path 10239" d="M4,1H1A1,1,0,0,0,0,2V9a1,1,0,0,0,1,1H8A1,1,0,0,0,9,9V6" transform="translate(696 247)" fill="none" stroke="#fff" stroke-width="1.2"/>
    <path id="Path_10240" data-name="Path 10240" d="M4,4V0H0" transform="translate(702 247)" fill="none" stroke="#fff" stroke-width="1.2"/>
    <path id="Path_10241" data-name="Path 10241" d="M2144-23303l-6,6" transform="translate(-1438 23550)" fill="none" stroke="#fff" stroke-width="1.2"/>
  </g>
</svg>
                    </button>
                    :
                        <a
                            href={"https://appstoreconnect.apple.com/apps"}
                            rel="noopener noreferrer"
                            target="_blank"
                            className={`publishsubmit-preview  web-publish-enable  `}
                            style={{backgroundColor:localStorage.getItem('button_background_color') && localStorage.getItem('button_background_color') != 'undefined' && localStorage.getItem('button_background_color') != 'null' ? localStorage.getItem('button_background_color') : '',color:localStorage.getItem('button_text_color')}}
                            
                          >
                            Submit for review{" "}
                            <svg style={{marginLeft: "6px"}} xmlns="http://www.w3.org/2000/svg" width="11.2" height="11.2" viewBox="0 0 11.2 11.2">
  <g id="Group_9158" data-name="Group 9158" transform="translate(-695.4 -246.4)">
    <path id="Path_10239" data-name="Path 10239" d="M4,1H1A1,1,0,0,0,0,2V9a1,1,0,0,0,1,1H8A1,1,0,0,0,9,9V6" transform="translate(696 247)" fill="none" stroke="#fff" stroke-width="1.2"/>
    <path id="Path_10240" data-name="Path 10240" d="M4,4V0H0" transform="translate(702 247)" fill="none" stroke="#fff" stroke-width="1.2"/>
    <path id="Path_10241" data-name="Path 10241" d="M2144-23303l-6,6" transform="translate(-1438 23550)" fill="none" stroke="#fff" stroke-width="1.2"/>
  </g>
</svg>
                          </a>
}
              </>
                }
              </>


              {/* )} */}
              </div>
              }
                                {
                      (localStorage.getItem("agencyid") && localStorage.getItem("agencyid") != 1) && code !== 200 &&
                      spinner === false &&
                    <div className="upgrade-feature">
                      <h4 className="upgrade-unavailable">This feature is currently unavailable</h4>
                      <p className="upgrade-support">Please contact Support for assistance.</p>
                    </div>
                    }



            </div>
            <div className="publish-main-div">
              {
                spinner ?  <div className="newspinner">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  style={{
                    margin: "auto",
                    background: "rgb(241, 242, 243,0)",
                    display: "block",
                    shaperendering: "auto",
                    animationplaystate: "running",
                    animationdelay: "0s",
                  }}
                  width="200px"
                  height="200px"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="xMidYMid"
                >
                  <g
                    transform="translate(20 50)"
                    style={{
                      animationplaystate: "running",
                      animationdelay: "0s",
                    }}
                  >
                    <circle
                      cx="0"
                      cy="0"
                      r="6"
                      fill="#e6261f"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <animateTransform
                        attributeName="transform"
                        type="scale"
                        begin="-0.375s"
                        calcMode="spline"
                        keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                        values="0;1;0"
                        keyTimes="0;0.5;1"
                        dur="1s"
                        repeatCount="indefinite"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      ></animateTransform>
                    </circle>
                  </g>
                  <g
                    transform="translate(40 50)"
                    style={{
                      animationplaystate: "running",
                      animationdelay: "0s",
                    }}
                  >
                    <circle
                      cx="0"
                      cy="0"
                      r="6"
                      fill="#f7d038"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <animateTransform
                        attributeName="transform"
                        type="scale"
                        begin="-0.25s"
                        calcMode="spline"
                        keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                        values="0;1;0"
                        keyTimes="0;0.5;1"
                        dur="1s"
                        repeatCount="indefinite"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      ></animateTransform>
                    </circle>
                  </g>
                  <g
                    transform="translate(60 50)"
                    style={{
                      animationplaystate: "running",
                      animationdelay: "0s",
                    }}
                  >
                    <circle
                      cx="0"
                      cy="0"
                      r="6"
                      fill="#49da9a"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <animateTransform
                        attributeName="transform"
                        type="scale"
                        begin="-0.125s"
                        calcMode="spline"
                        keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                        values="0;1;0"
                        keyTimes="0;0.5;1"
                        dur="1s"
                        repeatCount="indefinite"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      ></animateTransform>
                    </circle>
                  </g>
                  <g
                    transform="translate(80 50)"
                    style={{
                      animationplaystate: "running",
                      animationdelay: "0s",
                    }}
                  >
                    <circle
                      cx="0"
                      cy="0"
                      r="6"
                      fill="#4355db"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <animateTransform
                        attributeName="transform"
                        type="scale"
                        begin="0s"
                        calcMode="spline"
                        keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                        values="0;1;0"
                        keyTimes="0;0.5;1"
                        dur="1s"
                        repeatCount="indefinite"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      ></animateTransform>
                    </circle>
                  </g>
                </svg>
               </div>

              :
              <>
              {
                (code !== 200 && !skipButtonActive ) ?
              
              <div className=''>

                <div className='publish-update-page '>
                  <div className='publish-update-page-left'>
                  <div className='publish-update-left-div'>
                                <h2>Publish iOS apps effortlessly</h2>

                                <p>Submit your app updates on Apple App Store with ease and efficiency.</p>

                                <ul>
                                  <li>Share app updates directly on the App Store listing page.</li>
                                  <li>Track your app publishing history for better management.</li>
                                  <li>Resolve submission errors quickly and seamlessly.</li>
                                  <li>Celebrate successful submissions with instant notifications.</li>
                                </ul>

                                <div className="upgrade-skip-publish">
                    {(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") != 1) ?  
                          null
                          :
                          ( appuser_role_id == 1 || appuser_role_id == 2 ) &&
                     
                          <Link to={
                                    localStorage.getItem("workspaceId") !== "" &&
                                    localStorage.getItem("workspaceId") !== undefined &&
                                    localStorage.getItem("workspaceId") !== "undefined" &&
                                    localStorage.getItem("workspaceId") !== null
                                  ?`/workspace/pricing/${btoa(localStorage.getItem("workspaceId"))}`
                                    

                                  : ( reseller_package_id || (plan_code && plan_code.includes("appsumo") ))?"/app/addons":
                                  ( parseInt(localStorage.getItem('website_technology')) === 4 ? "/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid'))
                                    : parseInt(localStorage.getItem('website_technology')) === 5 ? "/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'))
                                    : parseInt(localStorage.getItem('website_technology')) === 3 ? "/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'))
                                    : parseInt(localStorage.getItem('website_technology')) === 2 ? "/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'))
                                    : "/app/pricing"
                                  )
                                  }
                          >
                            {
                              ( reseller_package_id || (plan_code && plan_code.includes("appsumo") ))?"Buy addon":"Upgrade"
                            }
                            
                            </Link>
                      }

                      <div className="skip-btn-head" onClick={()=>setSkipButtonActive(true)}>
                      <SkipBtn/>
                      </div>
                      </div>
             
             </div>
                  </div>
                  <div className='publish-update-page-right'>
                    <img
                      src={
                        require("../../../assets/images/dashboard/publish/publishiosUpgrade.png")
                          .default
                      }
                      alt="publishUpgrade-image"
                    />

                  </div>
                </div>

              </div>
              :
              <div className=''>
                {
                  (multisiteData && multisiteData.is_app_link_to_multisite === 1 ) ?
         
                  <div className="publish-merged">
                    <div>
                      <div className="publish-grid">
                      <div className="publish-child-app">
                          <div className="publish-child-tag">
                          <h3>Primary app</h3>
                
                          </div>
                          <div className="publish-appicon">
                            <img src={
                              process.env.REACT_APP_Image_Path +
                              multisiteData.parent_app_user_id +
                              "/" +
                              multisiteData.parent_app_id +
                              process.env.REACT_APP_AppIcon_Display +
                              multisiteData.parent_app_icon
                            }
                              onError={({ currentTarget }) => {
                                currentTarget.src =
                                  require("../../../assets/images/dashboard/app-dashboard.png").default;
                              }}
                              alt="download-img" className="img-fluid" />
                          </div>
                          <h4>{multisiteData.parent_app_name}</h4>
                          <h5>{multisiteData.parent_app_url
                           ? multisiteData.parent_app_url
                           : ""
                          }
                          </h5>
                        </div>
                        

                        <img src={require("../../../assets/images/dashboard/download-merge.png").default} alt="download-img" className="img-fluid" />
                  


                        <div className="publish-parent-app">
                          <div className="publish-parent-tag">
                          <h3>Merged app</h3>
                          </div>
                          <div className="publish-appicon">
                            <img src={
                              process.env.REACT_APP_Image_Path +
                              localStorage.getItem("user_id") +
                              "/" +
                              localStorage.getItem("Appid") +
                              process.env.REACT_APP_AppIcon_Display +
                              localStorage.getItem("Appicon")
                            }
                              onError={({ currentTarget }) => {
                                currentTarget.src =
                                  require("../../../assets/images/dashboard/app-dashboard.png").default;
                              }}
                              alt="download-img" className="img-fluid" />
                          </div>
                          <h4>{localStorage.getItem('Appname')}</h4>
                          <h5>{parseInt(localStorage.getItem("website_technology"))!==3
                           ? localStorage.getItem('store_url')
                           : ""
                          }
                          </h5>
                        </div>

                      </div>

                      <h1>This app is already merged</h1>
                      <h2>This app appears to be merged with another app.  To manage this feature, navigate to the primary app where this feature was enabled. Alternatively, click Unlink to disconnect this app.</h2>
                      <div className="publish-merged-btns">
                        <Link to={"/apps"}>Manage</Link>
                        <button
                        onClick={(e)=>handleUnLinkPopUp(e,"cancel")}
                        >Unlink</button>
                      </div>
                    </div>
                  </div> 
                :
                <>
                  {( data === null || data.publishing_connectivity_status !==2 )  ? (
                <div className='main-publishpage'>

                    <div className="App-Store-Connect-div">
                  <div className='publis-tech-image'>
                    <img
                      src={
                        require("../../../assets/images/dashboard/publish/appstoreicon.png")
                          .default
                      }
                      alt="publishUpgrade-image"
                    />
                  </div>


                  <p>Click 'Connect' to establish a link with your App Store account.</p>
                  <Link
                    to={{
                      pathname: "/app/app-store-connection/publish",
                      state: { skipButtonActive:skipButtonActive }
                    }}
                    style={{
                      backgroundColor: localStorage.getItem(
                        "button_background_color"
                      ),
                      // border:
                      //   "1px solid " +
                      //   localStorage.getItem("button_background_color"),
                      color: localStorage.getItem("button_text_color"),
                      "--custom_color5":
                        localStorage.getItem("agencyid") == 1
                          ? "#134bec"
                          : localStorage.getItem("button_hover_bgcolor"),
                      "--custom_color4":
                        localStorage.getItem("agencyid") == 1
                          ? "white"
                          : localStorage.getItem("button_hover_txtcolor"),
                      "--custom_color6":
                        localStorage.getItem("agencyid") == 1
                          ? "#3064f9"
                          : localStorage.getItem("button_hover_bgcolor"),
                      margin: 0,
                    }}
                    className="newpublish-ios-connect"
                  >
                    <img
                      src={
                        require("../../../assets/images/dashboard/whiteplus.png")
                          .default
                      }
                      alt="invite"
                    />
                    Connect
                  </Link>

                </div>
                </div>
                )
                :
                (
                <div>
                  <div>

                    {/* publish apple appstore connection */}

                    <div className='appleappstore-connect'>
                      <div className='appleappstore-connect-left'>
                        <div className='appleappstoreconnect'>
                          <h2>App store submission</h2>
                          <p>Submit your iOS app for review.</p>
                        </div>
                        <div className='mob-publish-paddingdiv mob-publish-divpadding'>
                          <div className='publish-apple-id-field'>
                            <label>
                            App version*<p className="bbsetting-tooltip ">
                                <svg style={{ cursor: "pointer", position: "unset" }} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg>
                                <p class="bbsetting-tooltipsubheader">Select the app version that you wish to submit to the App Store. This app version is pre-populated from your recent build history.</p></p>
                            </label>
                            {/* <input className='publish-apple-id-input' /> */}
                            <div className="publish-ios-build-version-div">
                             {is_build_process === 0 &&
                                      last_successful_build === null && (
                                        <input
                                          type="text"
                                          style={{
                                            background: "none",
                                            border: "none",
                                            flex: 1,
                                            color: "#FF4848",
                                            fontSize: "13px",
                                            fontFamily: "Axiforma-Regular"
                                          }}
                                          disabled
                                          value="You have not created a build yet "
                                        />
                                      )}
                                    {is_build_process === 0 &&
                                      last_successful_build !== null && (
                                        <input
                                          type="text"
                                          style={{
                                            background: "none",
                                            border: "none",
                                          }}
                                          disabled
                                          value={
                                            last_successful_build.build_release
                                          }
                                        />
                                      )}
                                    {is_build_process === 1 && (
                                      <input
                                        type="text"
                                        style={{
                                          background: "none",
                                          border: "none",
                                          color: "#FF4848",
                                          flex: 1,
                                          fontSize: "13px",
                                          fontFamily: "Axiforma-Regular"
                                        }}
                                        disabled
                                        value="Your build is still processing."
                                      />
                                    )}
                                    {is_build_process === 0 &&
                                      last_successful_build !== null &&
                                      data.build_trigger_id !==
                                        last_successful_build.id && (
                                        <div>
                                          <p
                                            className="p-0 m-0 goTodownload-div"
                                            style={{
                                              color: "#7782A1",
                                              fontSize: "13px",
                                              fontFamily: "Axiforma-Regular"
                                            }}
                                          >
                                            This build can be published.
                                          </p>
                                        </div>
                                      )}
                                    {is_build_process === 0 &&
                                      last_successful_build !== null &&
                                      data.build_trigger_id ===
                                        last_successful_build.id &&
                                      data.publishing_status === 4 && (
                                        <div>
                                          {data.submit_for_review === 0 && (
                                            <p
                                              className="p-0 m-0 goTodownload-div"
                                              style={{
                                                color: "#7782A1",
                                                fontSize: "13px",
                                              }}
                                            >
                                              This build has already been
                                              uploaded.
                                            </p>
                                          )}
                                          {data.submit_for_review === 1 && (
                                            <p
                                              className="p-0 m-0 goTodownload-div"
                                              style={{
                                                color: "#7782A1",
                                                fontSize: "13px",
                                              }}
                                            >
                                              This build has already been
                                              uploaded.
                                            </p>
                                          )}
                                        </div>
                                      )}
                                    {is_build_process === 1 && (
                                      <div className="goTodownload-div">
                                        <Link
                                          to="/app/download"
                                          className="p-0 m-0"
                                          style={{
                                            color: "#3064F9",
                                            fontSize: "13px",
                                          }}
                                        >
                                          Go to downloads
                                        </Link>
                                      </div>
                                    )}
                                    {is_build_process === 0 &&
                                      last_successful_build === null && (
                                        <div className="goTodownload-div">
                                          <Link
                                            to="/app/download"
                                            className="p-0 m-0"
                                            style={{
                                              color: "#3064F9",
                                              fontSize: "13px",
                                            }}
                                          >
                                            Go to downloads
                                          </Link>
                                        </div>
                                      )}
                          </div>
                          </div>
                          <div className='publish-apple-id-field'>
                            <label>
                            What’s new in this version?*<p className="bbsetting-tooltip ">
                                <svg style={{ cursor: "pointer", position: "unset" }} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg>
                                <p class="bbsetting-tooltipsubheader">Briefly describe changes or updates made to your app since your last release. This will be visible to your app users on the app listing page on the App Store.</p></p>
                            </label>

                            <textarea 
                                className={`publish-apple-id-textarea ${whtasNewErr ? "profile-validate-border" : ""}`}

                            //  placeholder='Bug fixes, security updates and a few performance updates.'
                             rows="4"
                             value={whatnew}
                             onChange={(e) => handlenew(e)}
                             />

                                  {
                                    whtasNewErr &&
                                    <div className="d-flex profile-validation">
                                      <svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                                        <path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848" />
                                        <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1" />
                                        <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1" />
                                      </svg>


                                      <p className="profile-redinput">Required</p>
                                    </div>
                                  }
                          </div>
                        </div>
                      </div>
                      <div className='appleappstore-connect-right'>
                        <img
                          src={
                            require("../../../assets/images/dashboard/publish/publishiosapp.png")
                              .default
                          }
                          alt="publishUpgrade-image"
                        />
                      </div>
                    </div>

                 {/* mob-btns */}
                 <div className='mob-publish-btns'>
                  {(code === 200 &&
                              data !== null && data.publishing_connectivity_status ===2 &&
                              (data.publishing_status === 1 || data.publishing_status === 2)) ? 
                                <button className={`mob-publish-save-btn  publish-disable`} 
                                  
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "16px",
                        fontFamily: 'Axiforma-Regular',
                      border:
                        "1px solid " +
                        localStorage.getItem("button_background_color"),
                      color: localStorage.getItem("button_text_color"),
                      "--custom_color4":
                        localStorage.getItem("agencyid") == 1
                          ? "white"
                          : localStorage.getItem("button_hover_txtcolor"),
                      "--custom_color6":
                        localStorage.getItem("agencyid") == 1
                          ? "#3064f9"
                          : localStorage.getItem("button_hover_bgcolor"),
                    }}
                   
                
                     > 
                     <div className='new-grey-loader' style={{margin:"unset", marginRight:"6px"}}></div>
                     Uploading...
                   
                  </button>
                  :
                  <>
                    <button
                     className={`mob-publish-save-btn  ${( data === null || data.publishing_connectivity_status !==2)?'publish-disable  ':'publish-enable '}`}
                     
                      style={{
                        border:
                          "1px solid " +
                          localStorage.getItem("button_background_color"),
                        color: localStorage.getItem("button_text_color"),
                        "--custom_color4":
                          localStorage.getItem("agencyid") == 1
                            ? "white"
                            : localStorage.getItem("button_hover_txtcolor"),
                        "--custom_color6":
                          localStorage.getItem("agencyid") == 1
                            ? "#3064f9"
                            : localStorage.getItem("button_hover_bgcolor"),
                      }}
                      disabled={ ( data === null || data.publishing_connectivity_status !==2) ? true : false }
                        onClick={(e) => publishformDraft(e, 0)}
                     >
                       <div className={loaderForSaveBtn}>
                        <div className="new-blue-loader"></div>
                      </div>
                      <div 
                      // style={{
                      //    color:( this.state.adType || this.state.adName.trim() ||
                      //           this.state.androidAdUnitId.trim() ||
                      //           this.state.iosAdUnitId.trim()
                      //          )
                      //      ? localStorage.getItem("button_background_color")
                      //      : null ,
                      // }}
                      >{saveBtnText}</div></button>
                    <button 
                    className={`mob-publish-varify-btn  ${(data !== null && data.publishing_connectivity_status ===2 &&
                      (data.publishing_status === 0 ||
                        data.publishing_status === 3 ||
                        (last_successful_build !== null &&
                          data.build_trigger_id !==
                            last_successful_build.id))
                      )?'publish-enable':' publish-disable'}`}
                      style={{
                        marginBottom:"20px"
                      }}
                      disabled={(data !== null && data.publishing_connectivity_status ===2 &&
                        (data.publishing_status === 0 ||
                          data.publishing_status === 3 ||
                          (last_successful_build !== null &&
                            data.build_trigger_id !==
                              last_successful_build.id))
                        )?false:true}  
                        onClick={(e) => handleOpenWarningCertificatePopUp(e)}
                        // onClick={(e) => publishformSubmit(e, 0)}
                    >
                                   <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                        <div className={loader}>
                                        <div className="new-loader"></div>
                                        </div>{" "}
                                        <div>{submitval}</div>
                                      </div>
                    
                    </button>
                    {
                    ( data === null || data.publishing_connectivity_status !==2)?
                    <button 
                    className={` mob-publish-varify-btn  publish-disable `}
                    style={{
                      cursor:"not-allowed"
                    }}
                    disabled={true}
                    >
                       Submit for review{" "}
                       <svg style={{marginLeft: "6px"}} xmlns="http://www.w3.org/2000/svg" width="11.2" height="11.2" viewBox="0 0 11.2 11.2">
  <g id="Group_9158" data-name="Group 9158" transform="translate(-695.4 -246.4)">
    <path id="Path_10239" data-name="Path 10239" d="M4,1H1A1,1,0,0,0,0,2V9a1,1,0,0,0,1,1H8A1,1,0,0,0,9,9V6" transform="translate(696 247)" fill="none" stroke="#fff" stroke-width="1.2"/>
    <path id="Path_10240" data-name="Path 10240" d="M4,4V0H0" transform="translate(702 247)" fill="none" stroke="#fff" stroke-width="1.2"/>
    <path id="Path_10241" data-name="Path 10241" d="M2144-23303l-6,6" transform="translate(-1438 23550)" fill="none" stroke="#fff" stroke-width="1.2"/>
  </g>
</svg>
                    </button>
                    :
                    <a
                    
                            href={"https://appstoreconnect.apple.com/apps"}
                            rel="noopener noreferrer"
                            target="_blank"
                            className={`mob-publish-varify-btn publish-enable`}
                            style={{backgroundColor:localStorage.getItem('button_background_color') && localStorage.getItem('button_background_color') != 'undefined' && localStorage.getItem('button_background_color') != 'null' ? localStorage.getItem('button_background_color') : '',color:localStorage.getItem('button_text_color')}}
                            
                          >
                            Submit for review{" "}
                            <svg style={{marginLeft: "6px"}} xmlns="http://www.w3.org/2000/svg" width="11.2" height="11.2" viewBox="0 0 11.2 11.2">
  <g id="Group_9158" data-name="Group 9158" transform="translate(-695.4 -246.4)">
    <path id="Path_10239" data-name="Path 10239" d="M4,1H1A1,1,0,0,0,0,2V9a1,1,0,0,0,1,1H8A1,1,0,0,0,9,9V6" transform="translate(696 247)" fill="none" stroke="#fff" stroke-width="1.2"/>
    <path id="Path_10240" data-name="Path 10240" d="M4,4V0H0" transform="translate(702 247)" fill="none" stroke="#fff" stroke-width="1.2"/>
    <path id="Path_10241" data-name="Path 10241" d="M2144-23303l-6,6" transform="translate(-1438 23550)" fill="none" stroke="#fff" stroke-width="1.2"/>
  </g>
</svg>
                          </a>
       }

                    {(code === 200 &&
                              data !== null && data.publishing_connectivity_status ===2 &&
                              (data.publishing_status === 1 || data.publishing_status === 2)) ? 
                              <></>:
                    
                              data && data.updated_at &&
                
              <p className='mob-publish-update'>Updated{" "}
              {moment(data.updated_at).format("D MMMM YYYY, HH:mm")}</p>
                }
                </>
                }
                  </div>


                    {/* publish history */}
                    {
                     (( publishing_logs && publishing_logs.length>0 )|| bottomSpinner  ) &&
                    
                    <div>
                      <div className='publishhistory-head'>
                        Publish history
                      </div>

                      <div className='appleappstore-connect-history'>
                        <div className='appleappstore-table-head'>
                          <div>Date</div>
                          <div>App version</div>
                          <div>Apple ID</div>
                          <div>Bundle ID</div>
                          <div>Status</div>

                        </div>

                        {/* <div className='appleappstore-table-data'>
                          <div>
                            <p className='mob-publish-history-head'>Date</p>
                            <p>
                              <img
                                src={
                                  require("../../../assets/images/dashboard/apple-logs.png")
                                    .default
                                }
                                alt="publishUpgrade-image"
                              />
                              20 Apr 2020, 22:35
                            </p>
                          </div>
                          <div>
                            <p className='mob-publish-history-head'>iOS Build Version & String</p>
                            <p>
                              3.10.21(1.0.0)
                            </p>
                          </div>
                          <div>
                            <p className='mob-publish-history-head'>Apple ID</p>
                            <p>
                              app@example.com
                            </p>
                          </div>
                          <div><p className='mob-publish-history-head'>Bundle ID</p>
                            <p>
                              com.example.store
                            </p></div>
                          <div><p className='mob-publish-history-head'>Status</p> <p className='apple-submitted-review'>Submitted for review</p></div>
                        </div> */}

                        {/* <div className='appleappstore-table-data'>
                          <div>
                            <p className='mob-publish-history-head'>Date</p>
                            <p>
                              <img
                                src={
                                  require("../../../assets/images/dashboard/apple-logs.png")
                                    .default
                                }
                                alt="publishUpgrade-image"
                              />
                              20 Apr 2020, 22:35
                            </p>
                          </div>
                          <div>
                            <p className='mob-publish-history-head'>iOS Build Version & String</p>
                            <p>
                              3.10.21(1.0.0)
                            </p>
                          </div>
                          <div>
                            <p className='mob-publish-history-head'>Apple ID</p>
                            <p>
                              app@example.com
                            </p>
                          </div>
                          <div><p className='mob-publish-history-head'>Bundle ID</p>
                            <p>
                              com.example.store
                            </p></div>
                          <div><p className='mob-publish-history-head'>Status</p> <p className='apple-submitted-review connection-failed'>Failed</p>
                            <Link >View error</Link></div>
                        </div> */}
                            {
                      bottomSpinner && <div className="form-group" style={{ background: "none" , margin:"auto" }}>
                              
                      <svg xmlns="http://www.w3.org/2000/svg" 
                          xlink="http://www.w3.org/1999/xlink" 
                          style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
                          width="200px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                          <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                          <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                            <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                            style={{animationplaystate: "running", animationdelay: "0s"}}>
                            </animateTransform>
                          </circle>
                          </g>
                          <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                          <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
                          <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                          style={{animationplaystate: "running", animationdelay: "0s"}}>
                          </animateTransform>
                          </circle>
                          </g>
                          <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                          <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
                          <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                          style={{animationplaystate: "running", animationdelay: "0s"}}>
                    
                          </animateTransform>
                          </circle>
                          </g>
                          <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                          <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
                          <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                          style={{animationplaystate: "running", animationdelay: "0s"}}>
                    
                          </animateTransform>
                          </circle>
                          </g>
                      </svg>
                      </div>
                    }
                        {
                          publishing_logs && publishing_logs.map((res,indx)=>{
                            return(
                           <div className='appleappstore-table-data'>
                          <div>
                            <p className='mob-publish-history-head'>Date</p>
                            <p>
                              <img
                                src={
                                  require("../../../assets/images/dashboard/apple-logs.png")
                                    .default
                                }
                                alt="publishUpgrade-image"
                              />
                              {moment(res.date).format("D MMMM YYYY, HH:mm")}
                            </p>
                          </div>
                          <div>
                            <p className='mob-publish-history-head'>App version</p>
                            <p>
                            {res.build_release} ({res.version})
                            </p>
                          </div>
                          <div>
                            <p className='mob-publish-history-head'>Apple ID</p>
                            <p>
                            {res.apple_id}
                            </p>
                          </div>
                          <div><p className='mob-publish-history-head'>Bundle ID</p>
                            <p>
                            {res.bundle_id}
                            </p></div>
                          <div><p className='mob-publish-history-head'>Status</p> 
                          
													{(res.status === 4 && res.submit_for_review === 0) && 
														<p className='apple-submitted-review connection-uploaded  '>
															Uploaded
														</p>
													}
                          {(res.status === 4 && res.submit_for_review === 1) && 
														<p className='apple-submitted-review connection-uploaded  '>
															Published
														</p>
													}

													{res.status === 3 && 
														<div className="failed-publish-view-err">
														<p className='apple-submitted-review  connection-failed '>
															Failed 
														</p>
														<span 
                            onClick={(e)=>handleErrPopUp(e,res.description)}
                            >View error</span>
					
												
													</div>
													}
													 {(res.status === 1 || res.status === 2) &&
														<p style={{color:"#7782A1",fontSize:"12px",marginBottom:"0px"}}>
															Processing...
														</p>
													 }
													{res.status === 0 && 
														<p style={{color:"#7782A1",fontSize:"12px",marginBottom:"0px"}}>
															Draft
														</p>
													}
													</div>

                          </div>
                       
                            )
                          })
                        }
                        
                      </div>
                    </div>
         } 
                  </div>


                



                  {/* publish steps */}
                  {

                  (!publishing_logs || publishing_logs.length===0 ) &&
                  <div className='publishconectmethod'>
                    <div className='publishconectsteps'>
                                              <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                                <g id="Group_43314" data-name="Group 43314" transform="translate(-316 -638)">
                                                  <rect id="Rectangle_32513" data-name="Rectangle 32513" width="44" height="44" transform="translate(316 638)" fill="none" />
                                                  <g id="Group_43313" data-name="Group 43313" transform="translate(55 -23)">
                                                    <g id="Rectangle_32511" data-name="Rectangle 32511" transform="translate(270 661)" fill="none" stroke="#e3e6f1" stroke-width="3">
                                                      <rect width="26" height="44" rx="5" stroke="none" />
                                                      <rect x="1.5" y="1.5" width="23" height="41" rx="3.5" fill="none" />
                                                    </g>
                                                    <path id="Path_119306" data-name="Path 119306" d="M11-10H9.333A3.436,3.436,0,0,0,6-6.471V16.471A3.436,3.436,0,0,0,9.333,20H11" transform="translate(256.5 678.5)" fill="none" stroke="#e3e6f1" stroke-width="3" />
                                                    <path id="Path_119307" data-name="Path 119307" d="M11-10h1.667A3.436,3.436,0,0,1,16-6.471V16.471A3.436,3.436,0,0,1,12.667,20H11" transform="translate(287.5 678.5)" fill="none" stroke="#e3e6f1" stroke-width="3" />
                                                    <path id="Path_119308" data-name="Path 119308" d="M-19644.049-2401.156l3.912,3.87,8.088-8" transform="translate(19921.049 3084.286)" fill="none" stroke="#e3e6f1" stroke-width="3" />
                                                    <path id="Rectangle_32512" data-name="Rectangle 32512" d="M0,0H10a0,0,0,0,1,0,0V3A2,2,0,0,1,8,5H2A2,2,0,0,1,0,3V0A0,0,0,0,1,0,0Z" transform="translate(278 661)" fill="#e3e6f1" />
                                                  </g>
                                                </g>
                                              </svg>
                      <div className='publishstepwise'>
                        <h2>Step 1</h2>
                        <p>Select app version.</p>
                      </div>
                    </div>
                    <div className='publishconectsteps'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
  <g id="Group_43316" data-name="Group 43316" transform="translate(-856 -638)">
    <path id="Path_119314" data-name="Path 119314" d="M0,0H44V44H0Z" transform="translate(856 638)" fill="none"/>
    <g id="Group_43315" data-name="Group 43315" transform="translate(1 -72)">
      <g id="Path_119310" data-name="Path 119310" transform="translate(859 710)" fill="none">
        <path d="M5,0H27l9,9V39a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z" stroke="none"/>
        <path d="M 5 3 C 3.897201538085938 3 3 3.897201538085938 3 5 L 3 39 C 3 40.10279846191406 3.897201538085938 41 5 41 L 31 41 C 32.10279846191406 41 33 40.10279846191406 33 39 L 33 10.24264144897461 L 25.75736045837402 3 L 5 3 M 5 0 L 27 0 L 36 9 L 36 39 C 36 41.76142120361328 33.76142120361328 44 31 44 L 5 44 C 2.238571166992188 44 0 41.76142120361328 0 39 L 0 5 C 0 2.238571166992188 2.238571166992188 0 5 0 Z" stroke="none" fill="#e3e6f1"/>
      </g>
      <g id="Path_119309" data-name="Path 119309" transform="translate(885 710)" fill="none">
        <path d="M0,0,10,10H3.571A3.571,3.571,0,0,1,0,6.429Z" stroke="none"/>
        <path d="M 0 0 L 10 10 L 3.571430206298828 10 C 1.598979949951172 10 0 8.401020050048828 0 6.428569793701172 L 0 0 Z" stroke="none" fill="#e3e6f1"/>
      </g>
      <path id="Path_119311" data-name="Path 119311" d="M-19054.578-2358.377h20" transform="translate(19921.578 3090.877)" fill="none" stroke="#e3e6f1" stroke-width="3"/>
      <path id="Path_119312" data-name="Path 119312" d="M-19054.578-2358.377h20" transform="translate(19921.578 3100.877)" fill="none" stroke="#e3e6f1" stroke-width="3"/>
      <path id="Path_119313" data-name="Path 119313" d="M-19054.574-2358.377h10" transform="translate(19921.578 3080.877)" fill="none" stroke="#e3e6f1" stroke-width="3"/>
    </g>
  </g>
</svg>
                      <div className='publishstepwise'>
                        <h2>Step 2</h2>
                        <p>Describe updates.</p>
                      </div>
                    </div>
                    <div className='publishconectsteps'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                        <g id="Group_41879" data-name="Group 41879" transform="translate(-1381 -785)">
                          <g id="Ellipse_7722" data-name="Ellipse 7722" transform="translate(1381 785)" fill="none" stroke="#e3e6f1" stroke-width="3">
                            <circle cx="22" cy="22" r="22" stroke="none" />
                            <circle cx="22" cy="22" r="20.5" fill="none" />
                          </g>
                          <path id="Path_98241" data-name="Path 98241" d="M-19622,10800.763l5.879,5.873,11.941-11.941" transform="translate(21016.088 -9993.664)" fill="none" stroke="#e3e6f1" stroke-width="3" />
                        </g>
                      </svg>
                      <div className='publishstepwise'>
                        <h2>Step 3</h2>
                        <p>Submit for review.</p>
                      </div>
                    </div>
                  </div>
                }
                </div>
              )}
                </>
               }
              </div>
            
            }
            </>
            }
            </div>
          </div>
        
          <Modal
            className="publish-secondmodal"
            style={{ maxWidth: "480px", maxHeight: "280px" }}
            isOpen={openErrPopUp}
            centered
            toggle={(e) => handleErrPopUp(e)}
          >
            <ModalHeader>
             
              <div
                className="publish-crossmodal"
                onClick={(e) => handleErrPopUp(e)}
                style={{
                  zIndex:"9"
                }}
              >
                <img
                  src={
                    require("../../../assets/images/dashboard/cross.png").default
                  }
                />
              </div>
            </ModalHeader>
            <ModalBody>

            <div className='publish-popup-maindiv'>
              <div className='publish-popup-head'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                    <g id="Group_41408" data-name="Group 41408" transform="translate(-324.314 -455.386)">
                      <g id="Ellipse_2829" data-name="Ellipse 2829" transform="translate(324.314 455.386)" fill="#fff1f1" stroke="#ff4848" stroke-width="1">
                        <circle cx="8" cy="8" r="8" stroke="none" />
                        <circle cx="8" cy="8" r="7.5" fill="none" />
                      </g>
                      <path id="Path_8311" data-name="Path 8311" d="M0,3.438,3.438,0" transform="translate(330.187 461.902) rotate(-45)" fill="none" stroke="#ff4848" stroke-width="1" />
                      <path id="Path_10113" data-name="Path 10113" d="M0,1.118,1.117,0" transform="translate(331.826 466.634) rotate(-45)" fill="none" stroke="#ff4848" stroke-width="1" />
                    </g>
                  </svg>
                <h2>Your app could not be submitted</h2>
              </div>
           <p>{errDescription}</p>

              <div className='publish-popup-bottom'>
                {/* <button className='publish-popup-cancel'>Cancel</button> */}
                <button className='publish-popup-confirm' onClick={(e) => handleErrPopUp(e)}>Okay</button>
              </div>

            </div>

            </ModalBody>
          </Modal>

          <Modal
            className="publish-secondmodal"
            style={{ maxWidth: "480px", maxHeight: "280px" }}
            isOpen={openUnlinkPopUp}
            centered
            toggle={(e) => handleUnLinkPopUp(e,"cancel")}
          >
            <ModalHeader>
             
              <div
                className="publish-crossmodal"
                onClick={(e) => handleUnLinkPopUp(e,"cancel")}
                style={{
                  zIndex:"9"
                }}
              >
                <img
                  src={
                    require("../../../assets/images/dashboard/cross.png").default
                  }
                />
              </div>
            </ModalHeader>
            <ModalBody>

            <div className='publish-popup-maindiv'>
              <div className='publish-popup-head'>
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                    <g id="Group_41408" data-name="Group 41408" transform="translate(-324.314 -455.386)">
                      <g id="Ellipse_2829" data-name="Ellipse 2829" transform="translate(324.314 455.386)" fill="#fff1f1" stroke="#ff4848" stroke-width="1">
                        <circle cx="8" cy="8" r="8" stroke="none" />
                        <circle cx="8" cy="8" r="7.5" fill="none" />
                      </g>
                      <path id="Path_8311" data-name="Path 8311" d="M0,3.438,3.438,0" transform="translate(330.187 461.902) rotate(-45)" fill="none" stroke="#ff4848" stroke-width="1" />
                      <path id="Path_10113" data-name="Path 10113" d="M0,1.118,1.117,0" transform="translate(331.826 466.634) rotate(-45)" fill="none" stroke="#ff4848" stroke-width="1" />
                    </g>
                  </svg> */}
                <h2>Are you sure you want to unlink?</h2>
              </div>
           <p>This action will disconnect the merged apps.</p>

              <div className='publish-popup-bottom'>
                <button className='publish-popup-cancel' onClick={(e) => handleUnLinkPopUp(e,"cancel")}>Cancel</button>
                <button className='publish-popup-confirm'
                style={{
                  backgroundColor:localStorage.getItem('button_background_color'),
                  color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                }}
                onClick={(e) => handleUnLinkPopUp(e,"confirm")}>
                        <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                        <div className={confirmBtnLoader}>
                                        <div className="new-loader"></div>
                                        </div>{" "}
                                        <div>{confirmBtnText}</div>
                                      </div>
                  </button>
              </div>

            </div>

            </ModalBody>
          </Modal>

         {/* Warning submit popup */}
         <Modal
       className=" time-out-modal"
       style={{ maxWidth: "560px", maxHeight: "414px" }}
       isOpen={openWarningCertificatePopUp}
       centered
       toggle={(e) => handleOpenWarningCertificatePopUp(e)}
     >
       <ModalHeader>
        
         <div
           className="publish-crossmodal"
           onClick={(e) => handleOpenWarningCertificatePopUp(e)}
           style={{
             zIndex:"9"
           }}
         >
           <img
             src={
               require("../../../assets/images/dashboard/cross.png").default
             }
           />
         </div>
       </ModalHeader>
       <ModalBody>

       <div className='publish-popup-maindiv'>
         <div className='publish-popup-head'>
             {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
               <g id="Group_41408" data-name="Group 41408" transform="translate(-324.314 -455.386)">
                 <g id="Ellipse_2829" data-name="Ellipse 2829" transform="translate(324.314 455.386)" fill="#fff1f1" stroke="#ff4848" stroke-width="1">
                   <circle cx="8" cy="8" r="8" stroke="none" />
                   <circle cx="8" cy="8" r="7.5" fill="none" />
                 </g>
                 <path id="Path_8311" data-name="Path 8311" d="M0,3.438,3.438,0" transform="translate(330.187 461.902) rotate(-45)" fill="none" stroke="#ff4848" stroke-width="1" />
                 <path id="Path_10113" data-name="Path 10113" d="M0,1.118,1.117,0" transform="translate(331.826 466.634) rotate(-45)" fill="none" stroke="#ff4848" stroke-width="1" />
               </g>
             </svg> */}
           <h2>Create your app on App Store Connect</h2>
         </div>
        <p>Before you proceed, ensure that you have finished creating your app in your App Store Connect account. To create your app, log in to your App Store Connect account &gt; Apps &gt; click on the plus icon &gt; enter the required details in the New App pop-up. Select the iOS platform, primary language and the Bundle ID corresponding to your app. Enter the app name, SKU and provide the desired level of access to finish creating your app. </p>


        <div className='tick-for-identify'>
          <label>
          <input type='checkbox' checked={isUserAgree} onChange={(e)=>handleUserAgree(e)}/>
          <span></span>
          </label>
          <p style={{textAlign:"left"}}>I have created my app on App Store Connect.
        </p>
        </div>
         <div className='publish-popup-bottom time-required-btn'>
           <button className='publish-popup-cancel'>
            <a href={"https://appstoreconnect.apple.com/apps"}
            target="_blank"
            >
            Apple Store Connect
           <svg xmlns="http://www.w3.org/2000/svg" width="11.2" height="11.2" viewBox="0 0 11.2 11.2">
            <g id="Group_43292" data-name="Group 43292" transform="translate(-695.4 -246.4)">
              <path id="Path_10239" data-name="Path 10239" d="M4,1H1A1,1,0,0,0,0,2V9a1,1,0,0,0,1,1H8A1,1,0,0,0,9,9V6" transform="translate(696 247)" fill="none" stroke="#bcc1ce" stroke-width="1.2"/>
              <path id="Path_10240" data-name="Path 10240" d="M4,4V0H0" transform="translate(702 247)" fill="none" stroke="#bcc1ce" stroke-width="1.2"/>
              <path id="Path_10241" data-name="Path 10241" d="M2144-23303l-6,6" transform="translate(-1438 23550)" fill="none" stroke="#bcc1ce" stroke-width="1.2"/>
            </g>
          </svg>
          </a>
           </button>
           <button className={`publish-popup-confirm ${isUserAgree?"":"disable-proceed-download-btn"}`} 
            disabled={isUserAgree ? false : true}
            onClick={(e) => publishformSubmit(e, 0)}
            >Proceed</button>
         </div>
         <div className='timeestimated-div'>
          <p>5 minutes estimated</p>
         </div>

       </div>

       </ModalBody>
     </Modal>

{/* API error */}
         <Modal
            className="publish-secondmodal"
            style={{ maxWidth: "480px", maxHeight: "280px" }}
            isOpen={openApiErrPopUp}
            centered
            toggle={(e) => handleAPIErrPopUp(e)}
          >
            <ModalHeader>
             
              <div
                className="publish-crossmodal"
                onClick={(e) => handleAPIErrPopUp(e)}
                style={{
                  zIndex:"9"
                }}
              >
                <img
                  src={
                    require("../../../assets/images/dashboard/cross.png").default
                  }
                />
              </div>
            </ModalHeader>
            <ModalBody>

            <div className='publish-popup-maindiv'>
              <div className='publish-popup-head'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                    <g id="Group_41408" data-name="Group 41408" transform="translate(-324.314 -455.386)">
                      <g id="Ellipse_2829" data-name="Ellipse 2829" transform="translate(324.314 455.386)" fill="#fff1f1" stroke="#ff4848" stroke-width="1">
                        <circle cx="8" cy="8" r="8" stroke="none" />
                        <circle cx="8" cy="8" r="7.5" fill="none" />
                      </g>
                      <path id="Path_8311" data-name="Path 8311" d="M0,3.438,3.438,0" transform="translate(330.187 461.902) rotate(-45)" fill="none" stroke="#ff4848" stroke-width="1" />
                      <path id="Path_10113" data-name="Path 10113" d="M0,1.118,1.117,0" transform="translate(331.826 466.634) rotate(-45)" fill="none" stroke="#ff4848" stroke-width="1" />
                    </g>
                  </svg>
                <h2>Your app could not be submitted</h2>
              </div>
              <p>Your app submission couldn't be processed successfully. Please review the error and resubmit</p>

              <div className='publish-popup-bottom'>
                {/* <button className='publish-popup-cancel'>Cancel</button> */}
                <button className='publish-popup-confirm' onClick={(e) => handleAPIErrPopUp(e)}>Okay</button>
              </div>

            </div>

            </ModalBody>
          </Modal>


          {/* Api success */}
          <Modal
            className="publish-secondmodal "
            style={{ maxWidth: "480px", maxHeight: "280px" }}
            isOpen={openApiSuccessPopUp}
            centered
            toggle={(e) => handleAPISuccessPopUp(e)}
          >
            <ModalHeader>
             
              <div
                className="publish-crossmodal"
                onClick={(e) => handleAPISuccessPopUp(e)}
                style={{
                  zIndex:"9"
                }}
              >
                <img
                  src={
                    require("../../../assets/images/dashboard/cross.png").default
                  }
                />
              </div>
            </ModalHeader>
            <ModalBody>

            <div className='publish-popup-maindiv'>
              <div className='publish-popup-head'>
                <h2>App submitted successfully</h2>
              </div>
               <p>Your app has been submitted for review successfully. Head over to App Store Connect to complete the publishing process.</p>

              <div className='publish-popup-bottom in-app-timeoutbtns'>
                <button className='publish-popup-cancel'>
                  <a    href={"https://appstoreconnect.apple.com/apps"}
                    rel="noopener noreferrer"
                    target="_blank">App Store Connect
                <img
                      src={
                        require("../../../assets/images/dashboard/Goto.png")
                          .default
                      }
                      className="dashboard-gotospace"
                      alt="Goto"
                    />
                    </a>
                </button>
                <button className='publish-popup-confirm' onClick={(e) => handleAPISuccessPopUp(e)}>Okay</button>
                 
              </div>

            </div>

            </ModalBody>
          </Modal>
        </section>
      </>
    )
};
