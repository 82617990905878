import React, { useEffect, useReducer } from "react";
// import { Modal, ModalBody } from "reactstrap";
import "./Addons.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Header from "../../../Dashboard/Header";
import Leftpanel from "../../../Dashboard/Leftpanel";
import { Notallowedtouser } from "../../../Dashboard/User/Usernotalllowed";
import {
  useAddonDataQuery,
  usePaddleDowngradeSaveDataMutation,
  useReactivateAddonPlanMutation,
  useStripeDowngradeSaveDataMutation,
  useStripeZohoAddonUpdationDataMutation,
  useWooChangePlanSaveDataMutation,
  // useZohoDowngradeSaveDataMutation,
} from "../../../services/addonsApiSlice";
import { errortoast } from "../../../Dashboard/Toaster";
import { MetaTitle } from "../../../utils/commonUtils";
import ModuleTitleTooltip from "../../../Components/commonIcons/ModuleTitleTooltip";
import AmsSpinner from "../../../Components/commonIcons/AmsSpinner";
import AddonCard from "../../../Components/commonComponents/Addons/AddonCard";
import UpgradeModal from "../../../Components/commonComponents/Addons/UpgradeModal";
import CancellationModal from "../../../Components/commonComponents/Addons/CancellationModal";
import { useDispatch } from "react-redux";
import { setPaymentSource } from "../../../Redux/slices/appDataSlice";
import Newfeatureicon from "../../../Components/commonIcons/Newfeatureicon";
import Modal from "../../../Components/commonComponents/Modal";
const Addons = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [addonsState, setAddonsState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      spinner: true,
      // addondata: [],
      recurringAddons: [],
      serviceAddons: [],
      deprecatadAddons: [],
      addonCode: "",
      appPlanCode: atob(localStorage.getItem("plan_code")),
      appId: "",
      oneTimeAddons: "",
      oneTimeAddonsPurchasedJSON: null,
      recurringAddonsPurchasedJSON: null,
      appUserRoleId: null,
      allowedModule: "",
      customerBillingType: null,
      appPlanName: "",
      isDealPlan: 0,
      paddleAddonPriceList: "",
      downgradeAtTermEnd: 0,
      newAddUserQuantity: null,
      addUserQuantity: null,
      openAddonCancelModal: false,
      addonToBeRemoved: "",
      addonPriceToBeRemoved: "",
      addonDurationToBeRemoved: "",
      removeModalLoader: "d-none",
      currAddonQuantity: 0,
      openUpgradePlanPopUp: false,
      customerType: "",
      isWorkspaceApp: 0,
      isLegacyLogic: false,
      reactivateLoader: false,
      appRightPanelExpand:
        localStorage.getItem("appSideBarCollapse") === true ||
        localStorage.getItem("appSideBarCollapse") === "true"
          ? true
          : false,
      openReactivateAddonModal: false,
    }
  );

  const {
    data: addonApiData,
    refetch: refetchAddonApiData,
    isFetching: refetchLoading,
    isLoading: addonApiDataLoading,
  } = useAddonDataQuery({
    plan_code: addonsState.appPlanCode.toString(),
  });

  const [stripeZohoAddonUpdationApi, { zohoStripeAddonUpdationData }] =
    useStripeZohoAddonUpdationDataMutation();

  const [wooChangePlanApi, { wooChangePlanData }] =
    useWooChangePlanSaveDataMutation();

  // const [zohoDowngradeApi, { zohoDowngradeData }] =
  //   useZohoDowngradeSaveDataMutation();

  const [stripeDowngradeApi, { stripeDowngradeData }] =
    useStripeDowngradeSaveDataMutation();

  const [paddleDowngradeApi, { paddleDowngradeData }] =
    usePaddleDowngradeSaveDataMutation();

  const [reactivateAddonApi, { reactivateAddonData }] =
    useReactivateAddonPlanMutation();

  const stripeZohoAddonUpdationFn = (params, callback) => {
    let actionparam = params.downgrade_at_term_end ? "downgrade" : "upgrade";
    stripeZohoAddonUpdationApi(params)
      .unwrap()
      .then((res) => {
        if (res.code === 200) {
          const userinfo = JSON.parse(localStorage.getItem("user_info"));
          let type = "";
          if (
            localStorage.getItem("workspaceId") !== "undefined" &&
            localStorage.getItem("workspaceId") !== "null" &&
            localStorage.getItem("workspaceId")
          ) {
            type = "workspace";
          } else {
            type = "pay-per-app";
          }

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "checkout_successful",
            user_email: userinfo.email,
            user_name: userinfo.name,
            user_id: userinfo.id,
            agency_id: localStorage.getItem("agency_id")
              ? parseInt(localStorage.getItem("agency_id"))
              : 1,
            transactionDetails: [
              {
                product_type: "plan",
                product_action: actionparam,
                plan_type: type,
                price: res.total,
                currency: "USD",
              },
            ],
          });
          callback();
        } else if (res.code === 501) {
          callback();
        }
      })
      .catch((err) => {});
  };

  const wooChangePlanFn = (params) => {
    wooChangePlanApi(params)
      .unwrap()
      .then((res) => {
        if (res.code === 200) {
          window.location.href = res.payment_url;
        } else if (res.code === 201) {
          setAddonsState({
            removeModalLoader: "",
          });
          history.push("/transaction-successful");
        } else if (res.code === 401) {
          if (res.status === "Token Expired") {
            let myItem = localStorage.getItem("buildversion");
            let myItemversion = localStorage.getItem("Apiversion");
            let previous_location = localStorage.getItem("Previouslocation");
            localStorage.clear();
            localStorage.setItem("Previouslocation", previous_location);
            localStorage.setItem("buildversion", myItem);
            localStorage.setItem("Apiversion", myItemversion);
          }
        }
      })
      .catch((err) => {});
  };

  const stripeDowngradeFn = (params) => {
    stripeDowngradeApi(params)
      .unwrap()
      .then((res) => {
        if (res.code === 200) {
          history.push(
            `/transaction-successful/${btoa(
              "paddle"
            )}?product=addon&action=downgrade`
          );
          setAddonsState({
            openAddonCancelModal: false,
            removeModalLoader: "d-none",
          });
        } else if (res.code === 203) {
          setAddonsState({
            openAddonCancelModal: false,
            removeModalLoader: "d-none",
          });
        }
      })
      .catch((error) => {
        setAddonsState({
          openAddonCancelModal: false,
          removeModalLoader: "d-none",
        });
      });
  };

  const paddleDowngradeFn = (params) => {
    paddleDowngradeApi(params)
      .unwrap()
      .then((res) => {
        if (res.code === 200) {
          history.push(
            `/transaction-successful/${btoa(
              "paddle"
            )}?product=addon&action=downgrade`
          );

          setAddonsState({
            openAddonCancelModal: false,
            removeModalLoader: "d-none",
            loader_downgrade: "d-none",
          });
        } else {
          errortoast("Your Addon removal failed.");
          setAddonsState({
            openAddonCancelModal: false,
            removeModalLoader: "d-none",
            loader_downgrade: "d-none",
          });
        }
      })
      .catch((err) => {
        errortoast("Your Addon removal failed.");
        setAddonsState({
          openAddonCancelModal: false,
          removeModalLoader: "d-none",
          loader_downgrade: "d-none",
        });
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = MetaTitle("Add-ons");
    localStorage.setItem("AgencyForThankyouPage", "");
    dispatch(
      setPaymentSource(
        localStorage.getItem("workspaceId") !== "undefined" &&
          localStorage.getItem("workspaceId") !== "null" &&
          localStorage.getItem("workspaceId")
          ? "Workspace"
          : "App"
      )
    );
    const Paddle = window.Paddle;
    localStorage.removeItem("reload");

    if (
      window.location.host.indexOf("localhost") > -1 ||
      window.location.host.indexOf("test.app") > -1 ||
      window.location.host.indexOf("stage.app") > -1 ||
      window.location.host.indexOf("dev.app") > -1
    ) {
      window.Paddle.Environment.set("sandbox");
    }
    Paddle.Setup({
      vendor: parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID),
    });
  }, []);

  const getPaddleAddonPricingList = async (code) => {
    return new Promise((resolve) => {
      window.Paddle.Product.Prices(
        parseInt(code),
        (prices) => resolve(prices.price.gross) // Resolve with the price
      );
    });
  };

  const settingAddonsListingData = async () => {
    if (addonApiData.code === 200) {
      let flattenedPrices = "";
      if (addonApiData.data?.user_data?.customer_billing_type === 3) {
        let addonMergeArr = [
          ...addonApiData.data.active_service_addons,
          ...addonApiData.data.active_recurring_addons,
          ...addonApiData.data.deprecatad_addons,
        ];
        const setAddonPrices = await Promise.all(
          addonMergeArr.map(async (addon) => {
            const price = await getPaddleAddonPricingList(addon.addon_code);

            return { [addon.addon_code]: price };
          })
        );

        flattenedPrices = setAddonPrices.reduce((acc, curr) => {
          return { ...acc, ...curr };
        }, {});
      }

      const recurringAddons = addonApiData.data.subscription
        ?.recurring_addons_purchased_json
        ? JSON.parse(
            addonApiData.data.subscription.recurring_addons_purchased_json
          )
        : null;
      const getAddonQuantity =
        addonApiData.addon_quantity === 0 ? 1 : addonApiData.addon_quantity;

      setAddonsState({
        isLegacyLogic: addonApiData?.data?.is_legacy_logic,
        spinner: false,
        isWorkspaceApp: addonApiData.data.is_workspace_app,
        appPlanCode: addonApiData.data.subscription?.plan_code || "preview",
        customerType: parseInt(addonApiData.data.user_data?.customer_type),
        paddleAddonPriceList: flattenedPrices,
        // addondata: addonApiData.data.addons,
        recurringAddons: addonApiData.data.active_recurring_addons,
        serviceAddons: addonApiData.data.active_service_addons,
        deprecatadAddons: addonApiData.data.deprecatad_addons,
        appId:
          addonApiData.data.subscription?.app_id ||
          localStorage.getItem("Appid"),
        oneTimeAddons: addonApiData.data.subscription?.one_time_addons,
        addonCode: addonApiData.data.subscription?.addon_codes,
        appUserRoleId: addonApiData.data.appuser_role_id,
        allowedModule: addonApiData.data.subscription?.device_type,
        customerBillingType: addonApiData.data.user_data?.customer_billing_type,
        isDealPlan: addonApiData.data.plan_data?.is_deal_plan,
        appPlanName: addonApiData.data.plan_data?.plan_code || "preview",

        oneTimeAddonsPurchasedJSON: JSON.parse(
          addonApiData.data.subscription?.onetime_addons_purchased_json || "0"
        ),
        recurringAddonsPurchasedJSON: JSON.parse(
          addonApiData.data.subscription?.recurring_addons_purchased_json || "0"
        ),

        downgradeAtTermEnd:
          addonApiData.data.subscription?.downgrade_at_term_end,
        currAddonQuantity: addonApiData.addon_quantity || 0,
        addUserQuantity:
          addonApiData.data.user_data?.customer_billing_type !== 2 &&
          (!recurringAddons || recurringAddons["add_user"]?.quantity === 0)
            ? 1
            : recurringAddons?.["add_user"]?.quantity ?? 1,
        newAddUserQuantity:
          addonApiData.data.user_data?.customer_billing_type !== 2
            ? recurringAddons?.[addonsState.addoncode]?.quantity === 0 ||
              !recurringAddons?.[addonsState.addoncode]
              ? getAddonQuantity
              : recurringAddons?.[addonsState.addoncode]?.quantity
            : getAddonQuantity,
      });
    }
  };

  useEffect(() => {
    if (addonApiData) {
      settingAddonsListingData();
    }
  }, [addonApiData]);

  const handleRightPanelExpand = (value) => {
    setAddonsState({
      appRightPanelExpand: value,
    });
  };

  const handleTeamAddonQuantity = (val) => {
    if (val === "minus") {
      if (addonsState.newAddUserQuantity > 1) {
        setAddonsState({
          newAddUserQuantity: addonsState.newAddUserQuantity - 1,
        });
      }
    } else {
      setAddonsState({
        newAddUserQuantity: addonsState.newAddUserQuantity + 1,
      });
    }
  };

  const handleAddonCancelationPopUp = (addonname, price, duration) => {
    setAddonsState({
      openAddonCancelModal: !addonsState.openAddonCancelModal,
      addonToBeRemoved: addonname,
      addonPriceToBeRemoved: price,
      addonDurationToBeRemoved: duration,
    });
  };
  const handleReactivateAddonPopup = (addonname, price, duration) => {
    setAddonsState({
      openReactivateAddonModal: !addonsState.openReactivateAddonModal,
      addonToBeRemoved: addonname,
      // addonPriceToBeRemoved: price,
      // addonDurationToBeRemoved: duration,
    });
  };

  //paymentquantity
  const paymentapiquantity = (addonlist) => {
    if (addonlist === "add_user") {
      let quantity = !addonsState.recurringAddonsPurchasedJSON
        ? addonsState.addUserQuantity
        : !addonsState.recurringAddonsPurchasedJSON[addonlist].quantity
        ? addonsState.addUserQuantity
        : addonsState.addUserQuantity;

      return quantity;
    } else if (addonlist === "appsumo_team_member_yearly") {
      let quantity = !addonsState.recurringAddonsPurchasedJSON
        ? addonsState.newAddUserQuantity
        : !addonsState.recurringAddonsPurchasedJSON[addonlist]
        ? addonsState.newAddUserQuantity
        : addonsState.newAddUserQuantity;
      return quantity;
    } else if (addonlist === "language_support") {
      let quantity = !addonsState.recurringAddonsPurchasedJSON
        ? 1
        : !addonsState.recurringAddonsPurchasedJSON[addonlist].quantity
        ? 1
        : addonsState.recurringAddonsPurchasedJSON[addonlist].quantity;
      return quantity;
    } else if (addonlist === "appsumo_user_consent_yearly") {
      let quantity =
        !addonsState.recurringAddonsPurchasedJSON ||
        !addonsState.recurringAddonsPurchasedJSON[addonlist]
          ? 1
          : !addonsState.recurringAddonsPurchasedJSON[addonlist].quantity
          ? 1
          : addonsState.recurringAddonsPurchasedJSON[addonlist].quantity;
      return quantity;
    } else if (addonlist === "appsumo_customer_chat_yearly") {
      let quantity =
        !addonsState.recurringAddonsPurchasedJSON ||
        !addonsState.recurringAddonsPurchasedJSON[addonlist]
          ? 1
          : !addonsState.recurringAddonsPurchasedJSON[addonlist].quantity
          ? 1
          : addonsState.recurringAddonsPurchasedJSON[addonlist].quantity;
      return quantity;
    } else if (addonlist === "appsumo_analytics_yearly") {
      let quantity =
        !addonsState.recurringAddonsPurchasedJSON ||
        !addonsState.recurringAddonsPurchasedJSON[addonlist]
          ? 1
          : !addonsState.recurringAddonsPurchasedJSON[addonlist].quantity
          ? 1
          : addonsState.recurringAddonsPurchasedJSON[addonlist].quantity;
      return quantity;
    } else if (addonlist === "appsumo_multisite_yearly") {
      let quantity =
        !addonsState.recurringAddonsPurchasedJSON ||
        !addonsState.recurringAddonsPurchasedJSON[addonlist]
          ? 1
          : !addonsState.recurringAddonsPurchasedJSON[addonlist].quantity
          ? 1
          : addonsState.recurringAddonsPurchasedJSON[addonlist].quantity;
      return quantity;
    } else if (addonlist === "appsumo_publish_yearly") {
      let quantity =
        !addonsState.recurringAddonsPurchasedJSON ||
        !addonsState.recurringAddonsPurchasedJSON[addonlist]
          ? 1
          : !addonsState.recurringAddonsPurchasedJSON[addonlist].quantity
          ? 1
          : addonsState.recurringAddonsPurchasedJSON[addonlist].quantity;
      return quantity;
    } else if (addonlist === "appsumo_white_label_yearly") {
      let quantity =
        !addonsState.recurringAddonsPurchasedJSON ||
        !addonsState.recurringAddonsPurchasedJSON[addonlist]
          ? 1
          : !addonsState.recurringAddonsPurchasedJSON[addonlist].quantity
          ? 1
          : addonsState.recurringAddonsPurchasedJSON[addonlist].quantity;
      return quantity;
    } else {
      return 1;
    }
  };

  const initiateCancellation = (
    addon_code,
    price,
    duration,
    ordertype,
    callback
  ) => {
    // Payment Api
    if (addonsState.isLegacyLogic) {
      // Cancel subscription immediately if legacy logic is enabled
      let paymentstateforsend = {};
      paymentstateforsend.zoho_plan_code = addon_code;
      paymentstateforsend.type = 2;
      paymentstateforsend.amount = Number(price);
      paymentstateforsend.quantity = paymentapiquantity(addon_code);

      let addonandpaymnet = [paymentstateforsend];
      const sendparam = {
        app_id: parseInt(localStorage.getItem("Appid")),
        total: Number(price),
        plan_array: addonandpaymnet,
        currency: "$",
        order_type: ordertype,
      };
      stripeZohoAddonUpdationFn(sendparam, callback);
    } else {
      callback();
    }
  };

  //Downgrade addon
  const handleAddonCancellation = (addonname, price, duration) => {
    setAddonsState({
      removeModalLoader: "loader",
    });

    if (addonsState.customerBillingType === 2) {
      // Stripe downgrade
      initiateCancellation(addonname, price, duration, "downgrade", () => {
        const downgrade_param = {
          app_id: parseInt(localStorage.getItem("Appid")),
          addon_array: [
            {
              addon_code: addonname,
              quantity_to_remove: 1,
            },
          ],
          type: "unsubscribe",
        };
        stripeDowngradeFn(downgrade_param);
      });
    } else if (addonsState.customerBillingType === 3) {
      // Paddle downgrade
      const downgrade_param = {
        // app_id: !localStorage.getItem("workspaceId")
        //   ? parseInt(localStorage.getItem("Appid"))
        //   : null,
        // workspace_id: !localStorage.getItem("workspaceId")
        //   ? null
        //   : parseInt(localStorage.getItem("workspaceId")),
        app_id: parseInt(localStorage.getItem("Appid")),
        workspace_id: null,
        addon_code: addonsState.addonToBeRemoved,
        agency_id: null,
      };
      paddleDowngradeFn(downgrade_param);
    }
  };
  const handleReactivate = (addonname, price, duration) => {
    setAddonsState({
      removeModalLoader: "loader",
      reactivateLoader: true,
    });

    const downgrade_param = {
      app_id: parseInt(localStorage.getItem("Appid")),
      addon_array: [
        {
          addon_code: addonname,
          quantity_to_remove: 1,
        },
      ],
      type: "unsubscribe",
    };
    reactivateAddonApi(downgrade_param)
      .unwrap()
      .then((res) => {
        if (res.code === 200) {
          setAddonsState({
            // openReactivateAddonModal: !addonsState.openReactivateAddonModal,
            removeModalLoader: "d-none",
            reactivateLoader: false,
          });
          refetchAddonApiData();
        }
      })
      .catch(() => {
        errortoast("Oops! Something went wrong.");
      });
  };

  const handleUpgradePopUp = () => {
    setAddonsState({
      openUpgradePlanPopUp: !addonsState.openUpgradePlanPopUp,
    });
  };

  const handleWooCustomer = (e, code) => {
    const params = {
      price_id: code,
      type: 2, ///1 for plan AND 2 for addon
      app_id: parseInt(localStorage.getItem("Appid")), //either this or workspace_id

      return_url:
        window.location.protocol +
        "//" +
        window.location.host +
        "/transaction-successful",
    };
    wooChangePlanFn(params);
  };

  if (parseInt(localStorage.getItem("agencyid")) > 1) {
    window.location.href = "/apps";
  }

  useEffect(() => {
    // to keep the modal open while the addon data is refetched after reaactivating addon
    if (
      !addonApiDataLoading &&
      refetchLoading &&
      addonsState.reactivateLoader
    ) {
      setAddonsState({
        openReactivateAddonModal: true,
      });
    } else if (!addonApiDataLoading && !refetchLoading) {
      setAddonsState({
        openReactivateAddonModal: false,
      });
    }
  }, [addonApiDataLoading, refetchLoading]);

  return (
    <>
      <Header customclass={"header-responsive-new"} />
      <section className="addons-dasboard_page">
        <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
        <div
          className={`right-panel-gap ${
            localStorage.getItem("appSideBarCollapse") === "true"
              ? " right-panel-gap-expand"
              : ""
          }`}
        >
          {" "}
          <div className="new-addons-spinner-div">
            {addonsState.spinner ? (
              <>
                <ModuleTitleTooltip
                  title={"Service add-ons"}
                  text={
                    "Get professional assistance from experts for your app."
                  }
                />
                <div>
                  <AmsSpinner spinWidth={"200px"} spinHeight={"200px"} />
                </div>
              </>
            ) : (
              <div>
                {addonsState.appUserRoleId === 3 ||
                addonsState.appUserRoleId === 4 ||
                parseInt(localStorage.getItem("logged_in_user_role_id")) ===
                  3 ||
                parseInt(localStorage.getItem("logged_in_user_role_id")) ===
                  4 ? (
                  Notallowedtouser()
                ) : (
                  <>
                    {addonsState.serviceAddons?.length > 0 ? (
                      <>
                        <ModuleTitleTooltip
                          title={"Service add-ons"}
                          text={
                            "Get professional assistance from experts for your app."
                          }
                        />
                        <div className="addons-ticket-div">
                          {addonsState.serviceAddons?.map((res, addonIndx) => (
                            <div
                              className="addons-ticket"
                              key={res.addon_identifier + res.addon_code}
                            >
                              {res.addon_identifier !== "WORDPRESS_HOSTING" &&
                                res.addon_identifier !== "NEW_APP_VERSION" &&
                                (addonsState.oneTimeAddons.indexOf(
                                  res.addon_code
                                ) !== -1 ||
                                  addonsState.addonCode.indexOf(
                                    res.addon_code
                                  ) !== -1 ||
                                  res.addon_identifier === "ANALYTICS" ||
                                  res.addon_identifier ===
                                    "MOBILE_APP_DESIGN" ||
                                  res.addon_identifier === "APP_STORE_DESIGN" ||
                                  res.addon_identifier === "CHAT_MODULE" ||
                                  res.addon_identifier === "CONSENT_FORM" ||
                                  res.addon_identifier === "LANGUAGE_SUPPORT" ||
                                  res.addon_identifier ===
                                    "MULTISITE_SUPPORT" ||
                                  res.addon_identifier === "PUBLISHING" ||
                                  res.addon_identifier ===
                                    "UPLOAD_TO_APP_STORE" ||
                                  res.addon_identifier === "USER_MANAGEMENT" ||
                                  res.addon_identifier === "WHITE_LABEL" ||
                                  res.addon_identifier ===
                                    "BUILD_AND_PUBLISH_APP") && (
                                  <AddonCard
                                    addon={res}
                                    customerBillingType={
                                      addonsState.customerBillingType
                                    }
                                    paddleAddonPriceList={
                                      addonsState.paddleAddonPriceList
                                    }
                                    downgradeAtTermEnd={
                                      addonsState.downgradeAtTermEnd
                                    }
                                    customerType={addonsState.customerType}
                                    appPlanName={addonsState.appPlanName}
                                    appId={addonsState.appId}
                                    handleAddonCancelationPopUp={
                                      handleAddonCancelationPopUp
                                    }
                                    addonIndx={addonIndx}
                                    handleUpgradePopUp={handleUpgradePopUp}
                                    handleWooCustomer={handleWooCustomer}
                                    newAddUserQuantity={
                                      addonsState.newAddUserQuantity
                                    }
                                    currAddonQuantity={
                                      addonsState.currAddonQuantity
                                    }
                                    handleTeamAddonQuantity={
                                      handleTeamAddonQuantity
                                    }
                                    allowedModule={addonsState.allowedModule}
                                    oneTimeAddonsPurchasedJSON={
                                      addonsState.oneTimeAddonsPurchasedJSON
                                    }
                                    addonsState={addonsState}
                                  />
                                )}
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {addonsState.recurringAddons?.length > 0 ? (
                      <div>
                        <div className="discontinued-addons-head-parent">
                        <ModuleTitleTooltip
                          title={"Feature add-ons"}
                          text={
                            "Unlock additional features to enhance the functionality of your app."
                          }
                        />
                        </div>
                        <div className="addons-ticket-div">
                          {addonsState.recurringAddons?.map(
                            (res, addonIndx) =>
                              (res.addon_identifier !== "LANGUAGE_SUPPORT" ||
                                parseInt(
                                  localStorage.getItem("website_technology")
                                ) !== 2) && (
                                <div
                                  className="addons-ticket"
                                  key={res.addon_identifier + res.addon_code}
                                >
                                  {res.addon_identifier !==
                                    "WORDPRESS_HOSTING" &&
                                    res.addon_identifier !==
                                      "NEW_APP_VERSION" &&
                                    (addonsState.oneTimeAddons.indexOf(
                                      res.addon_code
                                    ) !== -1 ||
                                      addonsState.addonCode.indexOf(
                                        res.addon_code
                                      ) !== -1 ||
                                      res.addon_identifier === "ANALYTICS" ||
                                      res.addon_identifier ===
                                        "MOBILE_APP_DESIGN" ||
                                      res.addon_identifier ===
                                        "APP_STORE_DESIGN" ||
                                      res.addon_identifier === "CHAT_MODULE" ||
                                      res.addon_identifier === "CONSENT_FORM" ||
                                      res.addon_identifier ===
                                        "LANGUAGE_SUPPORT" ||
                                      res.addon_identifier ===
                                        "MULTISITE_SUPPORT" ||
                                      res.addon_identifier === "PUBLISHING" ||
                                      res.addon_identifier ===
                                        "UPLOAD_TO_APP_STORE" ||
                                      res.addon_identifier ===
                                        "USER_MANAGEMENT" ||
                                      res.addon_identifier === "WHITE_LABEL" ||
                                      res.addon_identifier ===
                                        "RICH_NOTIFICATIONS" ||
                                      res.addon_identifier ===
                                        "IN_APP_PURCHASE" ||
                                      res.addon_identifier ===
                                        "DEDICATED_SERVER") && (
                                      <AddonCard
                                        key={
                                          res.addon_identifier + res.addon_code
                                        }
                                        addon={res}
                                        customerBillingType={
                                          addonsState.customerBillingType
                                        }
                                        paddleAddonPriceList={
                                          addonsState.paddleAddonPriceList
                                        }
                                        downgradeAtTermEnd={
                                          addonsState.downgradeAtTermEnd
                                        }
                                        customerType={addonsState.customerType}
                                        appPlanName={addonsState.appPlanName}
                                        appId={addonsState.appId}
                                        handleAddonCancelationPopUp={
                                          handleAddonCancelationPopUp
                                        }
                                        handleReactivateAddonPopup={
                                          handleReactivateAddonPopup
                                        }
                                        addonIndx={addonIndx}
                                        handleUpgradePopUp={handleUpgradePopUp}
                                        handleWooCustomer={handleWooCustomer}
                                        newAddUserQuantity={
                                          addonsState.newAddUserQuantity
                                        }
                                        currAddonQuantity={
                                          addonsState.currAddonQuantity
                                        }
                                        handleTeamAddonQuantity={
                                          handleTeamAddonQuantity
                                        }
                                        allowedModule={
                                          addonsState.allowedModule
                                        }
                                        oneTimeAddonsPurchasedJSON={
                                          addonsState.oneTimeAddonsPurchasedJSON
                                        }
                                        addonsState={addonsState}
                                      />
                                    )}
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {addonsState.deprecatadAddons?.length > 0 ? (
                      <div>
                        <div className="discontinued-addons-head-parent">
                          <ModuleTitleTooltip
                            title={"Discontinued add-ons"}
                            text={"These add-ons are no longer available"}
                          />
                          {/* <Newfeatureicon /> */}
                        </div>

                        <div className="addons-ticket-div addon-discontinued">
                          {addonsState.deprecatadAddons?.map(
                            (res, addonIndx) => (
                              <div
                                className="addons-ticket"
                                key={res.addon_identifier + res.addon_code}
                              >
                                <AddonCard
                                  key={res.addon_identifier + res.addon_code}
                                  addon={res}
                                  customerBillingType={
                                    addonsState.customerBillingType
                                  }
                                  paddleAddonPriceList={
                                    addonsState.paddleAddonPriceList
                                  }
                                  downgradeAtTermEnd={
                                    addonsState.downgradeAtTermEnd
                                  }
                                  customerType={addonsState.customerType}
                                  appPlanName={addonsState.appPlanName}
                                  appId={addonsState.appId}
                                  handleAddonCancelationPopUp={
                                    handleAddonCancelationPopUp
                                  }
                                  addonIndx={addonIndx}
                                  handleUpgradePopUp={handleUpgradePopUp}
                                  handleWooCustomer={handleWooCustomer}
                                  newAddUserQuantity={
                                    addonsState.newAddUserQuantity
                                  }
                                  currAddonQuantity={
                                    addonsState.currAddonQuantity
                                  }
                                  handleTeamAddonQuantity={
                                    handleTeamAddonQuantity
                                  }
                                  allowedModule={addonsState.allowedModule}
                                  oneTimeAddonsPurchasedJSON={
                                    addonsState.oneTimeAddonsPurchasedJSON
                                  }
                                  addonsState={addonsState}
                                />
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </section>

      {/* Cancel Addon subscription Modal */}
      <CancellationModal
        handlePopUp={handleAddonCancelationPopUp}
        openPopUp={addonsState.openAddonCancelModal}
        handleDoneBtn={handleAddonCancellation}
        addonToBeRemoved={addonsState.addonToBeRemoved}
        addonPriceToBeRemoved={addonsState.addonPriceToBeRemoved}
        addonDurationToBeRemoved={addonsState.addonDurationToBeRemoved}
        isLoader={addonsState.removeModalLoader}
        addonsState={addonsState}
      />
      {/* Upgrade Pop Up */}
      <UpgradeModal
        handlePopUp={handleUpgradePopUp}
        openPopUp={addonsState.openUpgradePlanPopUp}
      />
      <Modal
        open={addonsState.openReactivateAddonModal}
        toggle={(e) => handleReactivateAddonPopup(e)}
        title="Stay on current plan?"
        description="Are you sure you want to cancel your plan change request and retain your current plan?"
        cancelbutton="Cancel"
        submitbutton="Confirm"
        onSubmit={() => handleReactivate(addonsState.addonToBeRemoved)}
        isLoading={addonsState.reactivateLoader || refetchLoading}
      />
    </>
  );
};

export default Addons;
