import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import crossIcon from "../../../assets/images/dashboard/cross.png";

const PaidSubscriptionModal = ({ settingsState, paidsubscriptionpopup }) => {
  return (
    <div>
      <Modal
        isOpen={settingsState.paidsubscriptiondetected}
        className="generalstngs1 transfer-modal-div"
        centered
      >
        <ModalHeader toggle={paidsubscriptionpopup}>
          <div className="crossmodal mt-1" onClick={paidsubscriptionpopup}>
            <img src={crossIcon} alt="cross-icon" />
          </div>
        </ModalHeader>

        <ModalBody>
          <div>
            <h4>Paid subscription detected</h4>
            {settingsState.customer_type === 1 ? (
              <h6>
                You’re currently on a paid plan or have an active paid add on
                subscription. Please downgrade to the free plan, cancel any
                active paid add on subscriptions, and try again.
              </h6>
            ) : (
              <h6>
                 You’re currently on a paid plan or have an active paid add on
                subscription. Please downgrade to the free plan, cancel any
                active paid add on subscriptions, and try again.
              </h6>
            )}

            <div className="generalstngs1-footer">
              <div className="generalstngs1-ok">
                <p onClick={paidsubscriptionpopup}>Okay</p>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PaidSubscriptionModal;
