import axios from "axios";
import { errortoast, sucesstoast } from "../../Dashboard/Toaster";
const Url = process.env.REACT_APP_API_URL + "api/user/";
const Errorurl = process.env.REACT_APP_API_URL + "api/frontend-log";
const Activationurl =
  process.env.REACT_APP_API_URL + "api/user/email-verification";

//Loader Function
const Loader = (state) => {
  state.setState({
    submitval: "",
    loader: "loader mr-2",
  });
};
const erroLoader = (state) => {
  state.setState({
    submitval: "Create account",
    loader: "d-none",
  });
};
const errorloginLoader = (state) => {
  state.setState({
    submitval: "Sign In",
    loader: "d-none",
  });
};

const setname = (value) => 
{

  let appshort = value.split(" ");

  let i;
  let apparray = [];

  if (appshort.length > 1) {
    for (i = 0; i < appshort.length; i++) {
      apparray.push(appshort[i].charAt(0).toUpperCase());
    }
    return apparray.join("").slice(0, 3);
  } else {
    return appshort.join("").slice(0, 2);
  }
};

const getappdetailbyid = (data,state) => 
{
  const id = data.default_app_id;
  if(id !== null && id !== undefined && id !== '')
  {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("amstoken"),
    };

    axios
      .get(Url + "get_app_details/" + btoa(id), {
        headers: headers,
      })
      .then((res) => {
        if (res.data.code === 200) 
      {
        localStorage.setItem("app_icon_count", res.data.data.appicon_count);
        localStorage.setItem("splash_icon_count", res.data.data.splash_icon_count);
        localStorage.setItem("login_register_icon_count", res.data.data.login_register_icon_count);
        localStorage.setItem("style_icon_count", res.data.data.style_icon_count);
        localStorage.setItem("dashboard_icon_count", res.data.data.dashboard_icon_count);
        localStorage.setItem("Appid", res.data.data.id);
        localStorage.setItem("Appname", res.data.data.app_name);
        localStorage.setItem("Appversion", res.data.data.version);
        localStorage.setItem("appshortname", setname( res.data.data.app_name));
        localStorage.setItem("store_url", res.data.data.store_url);
        localStorage.setItem("plugin_dataactive", parseInt(res.data.data.plugin_data));
        localStorage.setItem("appsettingactive", parseInt(res.data.data.menu_fill));
        localStorage.setItem("personaliseactive", parseInt(res.data.data.dashboard_icon_count));
        localStorage.setItem("website_type", parseInt(res.data.data.website_type));
        localStorage.setItem("website_type_select", parseInt(res.data.data.website_type));
        localStorage.setItem("website_technology", res.data.data.website_technology);
        localStorage.setItem("is_verified", res.data.data.is_verified);
        
        if(data.default_workspace_id === null || data.default_workspace_id === '' || data.default_workspace_id === 0)
          {
            var previouslocaiton =  localStorage.getItem("Previouslocation");
            
            if(previouslocaiton !== '' && previouslocaiton !== 'null' && previouslocaiton !== null && previouslocaiton !== undefined && previouslocaiton !== 'undefined')
            {
              localStorage.removeItem("Previouslocation");
              state.props.history.push(previouslocaiton);
            }
            else
            {
              if(localStorage.getItem('agencyid') &&  localStorage.getItem('agencyid') == 1)
              {
                state.props.history.push("/home");
              }
              else
              {
                state.props.history.push("/apps");
              }
            }
          }
          else if(data.default_workspace_id !== '')
          {
            localStorage.setItem("workspaceId",parseInt(data.default_workspace_id));
             if(localStorage.getItem('agencyid') &&  localStorage.getItem('agencyid') == 1)
              {
                state.props.history.push("/home");
              }
              else
              {
                state.props.history.push("/apps");
              }
          }
          else
          {
            var previouslocaiton =  localStorage.getItem("Previouslocation");
            if(previouslocaiton !== '' && previouslocaiton !== 'null' && previouslocaiton !== null && previouslocaiton !== undefined && previouslocaiton !== 'undefined')
            {
              localStorage.removeItem("Previouslocation");
              state.props.history.push(previouslocaiton);
            }
            else
            {
              if(localStorage.getItem('agencyid') &&  localStorage.getItem('agencyid') == 1)
              {
                state.props.history.push("/home");
              }
              else
              {
                state.props.history.push("/apps");
              }
            }
          }
      }
      else
      {
        if(localStorage.getItem('agencyid') &&  localStorage.getItem('agencyid') == 1)
        {
          state.props.history.push("/");
        }
        else
        {
          state.props.history.push("/apps");
        }
      }
    })
    .catch((error) => {
      
    });
  }
  else
  {
    if(localStorage.getItem('agencyid') &&  localStorage.getItem('agencyid') == 1)
    {
      state.props.history.push("/home");
    }
    else
    {
      state.props.history.push("/apps");
    }
  }
};


//Login API
export const LoginApi = (params, state) => {
  Loader(state);
  
  const headers = {
    "Content-Type": "application/json",
    "X-App-Code-ver":localStorage.getItem("Apiversion"),
    "X-App-Device-Type":"React",
  };

  

  axios
    .post(Url + "login", params,
      { headers: headers,
      })
    .then((res) => {
      if (res.data.code === 200) 
      {
        const datasend = 
          {
                email: res.data.data[0].email,
                name:res.data.data[0].name,
                id:res.data.data[0].id,        		   
				        deviceid: params.deviceuuid,
                password:params.password,
                login_type:0,
      		};

          const datasend1 = btoa(encodeURIComponent(JSON.stringify(datasend)));
          localStorage.removeItem("skipdone");
          state.props.history.push("/sign-in-redirect?data="+datasend1);
        
		
      } 
      else if (res.data.code === 400) 
      {
        //Remove Pleasewait function
        errorloginLoader(state);
        if (Object.keys(res.data.data).indexOf("email") === -1) {
          state.setState({
            emailerror: null,
          });
        } else {
          state.setState({
            emailerror: res.data.data.email[0],
          });
        }
        if (Object.keys(res.data.data).indexOf("password") === -1) {
          state.setState({
            passworderror: null,
          });
        } else {
          state.setState({
            passworderror: res.data.data.password[0],
          });
        }
      } else if (res.data.code === 401) 
      {
        errorloginLoader(state);
        errortoast(res.data.data);
      }
    })
    .catch((error) => {
      
      state.props.history.push("/500?error="+error);
    });
};
//error logs
export const Errorlogs = (params) => {
  axios.post(Errorurl, params).then((res) => {
    
  });
};
//Country code
export const Countrycode = (state, callback) => {
  Loader(state);

  axios
    .get(
      "https://api.ipstack.com/check?access_key=" +
        process.env.REACT_APP_ipstack_key +
        "&timestamp=" +
        new Date().getTime()
    )
    .then((res) => {
      if (res.data.error === undefined) {
        state.setState({
          user_country: res.data.country_code,
          login_ip: res.data.ip,
        });
        callback();
      } else {
        state.setState({
          user_country: "",
          login_ip: "",
        });
        const params = {
          code: res.data.error.code,
          type: res.data.error.type,
          info: res.data.error.info,
        };
        Errorlogs(params);
        callback();
      }
    })
    .catch((error) => {
      state.setState({
        user_country: "",
        login_ip: "",
      });
      callback();
    });
};
//Google Signupapi
export const Googlesignup = (params, state) => {
  if (params) {
    Loader(state);
    axios.post(Url + "google", params).then((res) => {
      if (res.data.code === 200) {
        localStorage.setItem("customer_type", res.data.data[0].customer_type);
        localStorage.setItem("amstoken", res.data.token.access_token);
        localStorage.setItem("user_id", res.data.data[0].id);
        localStorage.setItem("logged_in_user_id", res.data.data[0].id);
        localStorage.setItem("logged_in_user_role_id", 1);
        localStorage.setItem("originalid", res.data.data[0].id);
        localStorage.setItem("user_name", res.data.data[0].name);
        localStorage.setItem("user_profile", res.data.data[0].profile_image);
        localStorage.setItem(
          "roleid",
          res.data.data[0].role_id !== undefined ? res.data.data[0].role_id : 2
        );
        if (res.data.check === "login") 
        { 
          getappdetailbyid(res.data.data[0],state);
        } 
        else 
        {
          localStorage.setItem("skipdone", 'done');
          state.props.history.push("/app/create");
        }
      } else if (res.data.code === 400) {
      }
    })
    .catch((error) => {});
  }
};
export const Facebooksignup = (params, state) => {
  if (params) {
    Loader(state);
    axios
      .post(process.env.REACT_APP_API_URL + "api/user/facebook", params)
      .then((res) => {
        if (res.data.code === 200) {
          localStorage.setItem("customer_type", res.data.data[0].customer_type);
          localStorage.setItem("amstoken", res.data.token.access_token);
          localStorage.setItem("user_id", res.data.data[0].id);
          localStorage.setItem("logged_in_user_id", res.data.data[0].id);
          localStorage.setItem("logged_in_user_role_id", 1);
          localStorage.setItem("originalid", res.data.data[0].id);
          localStorage.setItem("user_name", res.data.data[0].name);
          localStorage.setItem("user_profile", res.data.data[0].profile_image);
          if (res.data.check === "login") 
          { 
            getappdetailbyid(res.data.data[0],state);
            
        } 
           else {
            localStorage.setItem("skipdone", 'done');
            //state.props.history.push("/app/create");
            state.props.history.push("/apps");
          }
        } else if (res.data.code === 400) {
        }
      })
      .catch((error) => {});
  }
};

export const UserSaveDevice =(params,state) =>
{
  if (params) 
  {
    Loader(state);
    axios.post(Url + "save-user-device", params).then((res) => 
    {
      if (res.data.code === 200) 
      {
        localStorage.setItem("customer_type", res.data.data[0].customer_type);
        localStorage.setItem("amstoken", res.data.token.access_token);
        localStorage.setItem("user_id", res.data.data[0].id);
        localStorage.setItem("logged_in_user_id", res.data.data[0].id);
        localStorage.setItem("logged_in_user_role_id", 1);
        localStorage.setItem("originalid", res.data.data[0].id);
        localStorage.setItem("user_name", res.data.data[0].name);
        localStorage.setItem("user_profile", res.data.data[0].profile_image);
        localStorage.setItem
        (
          "roleid",
          res.data.data[0].role_id !== undefined ? res.data.data[0].role_id : 2
        );
        
        if(res.data.data[0].status === 0 && window.location.pathname !== '/verify-email' && window.location.pathname !== '/profile' && window.location.pathname !== '/apps')
        {
            window.location.href = "/verify-email";
            
        }
        else
        {
          if (res.data.check === "login") 
            { 
    
              getappdetailbyid(res.data.data[0],state);
                
            }  
            else 
            {
              localStorage.setItem("skipdone", 'done');
              //state.props.history.push("/app/create");
              if(localStorage.getItem('agencyid') &&  localStorage.getItem('agencyid') == 1)
              {
                state.props.history.push("/home");
              }
              else
              {
                state.props.history.push("/apps");
              }
            }
        }


		
		if(res.data.data[0].role_id !== undefined && res.data.data[0].role_id === 1)
		{
			localStorage.setItem("is_admin", 1);
		}
		else
		{
			localStorage.setItem("is_admin", 0);
		}
		
      } else if (res.data.code === 401) 
      {
        errortoast(
          "Your account has been deactivated. Contact support to re-activate account."
        );
      }
    })
    .catch((error) => {});
  }

}
export const Googleloginin = (params, state) => {
  if (params) {
    Loader(state);
    axios.post(Url + "google", params).then((res) => {
      if (res.data.code === 200) {
        localStorage.setItem("customer_type", res.data.data[0].customer_type);
        localStorage.setItem("amstoken", res.data.token.access_token);
        localStorage.setItem("user_id", res.data.data[0].id);
        localStorage.setItem("logged_in_user_id", res.data.data[0].id);
        localStorage.setItem("logged_in_user_role_id", 1);
        localStorage.setItem("originalid", res.data.data[0].id);
        localStorage.setItem("user_name", res.data.data[0].name);
        localStorage.setItem("user_profile", res.data.data[0].profile_image);
        localStorage.setItem(
          "roleid",
          res.data.data[0].role_id !== undefined ? res.data.data[0].role_id : 2
        );
        if (res.data.check === "login") 
        { 
          getappdetailbyid(res.data.data[0],state);
            
        }  
        else {
          localStorage.setItem("skipdone", 'done');
          //state.props.history.push("/app/create");
          if(localStorage.getItem('agencyid') &&  localStorage.getItem('agencyid') == 1)
          {
            state.props.history.push("/home");
          }
          else
          {
            state.props.history.push("/apps");
          }
        }
		
		if(res.data.data[0].role_id !== undefined && res.data.data[0].role_id === 1)
		{
			localStorage.setItem("is_admin", 1);
		}
		else
		{
			localStorage.setItem("is_admin", 0);
		}
		
      } else if (res.data.code === 401) {
        errortoast(
          "Your account has been deactivated. Contact support to re-activate account."
        );
      }
    })
    .catch((error) => {});
  }
};
export const Facebooklogins = (params, state) => {
  if (params) {
    Loader(state);
    axios
      .post(process.env.REACT_APP_API_URL + "api/user/facebook", params)
      .then((res) => {
        if (res.data.code === 200) {
          localStorage.setItem("customer_type", res.data.data[0].customer_type);
          localStorage.setItem("amstoken", res.data.token.access_token);
          localStorage.setItem("user_id", res.data.data[0].id);
          localStorage.setItem("logged_in_user_id", res.data.data[0].id);
          localStorage.setItem("logged_in_user_role_id", 1);
          localStorage.setItem("originalid", res.data.data[0].id);
          localStorage.setItem("user_name", res.data.data[0].name);
          localStorage.setItem("user_profile", res.data.data[0].profile_image);
          if (res.data.check === "login") 
        { 
          getappdetailbyid(res.data.data[0],state);
        } 
           else {
            localStorage.setItem("skipdone", 'done');
            state.props.history.push("/app/create");
          }
		  
			if(res.data.data[0].role_id !== undefined && res.data.data[0].role_id === 1)
			{
				localStorage.setItem("is_admin", 1);
			}
			else
			{
				localStorage.setItem("is_admin", 0);
			}
		
        } else if (res.data.code === 400) {
        }
      })
      .catch((error) => {});
  }
};
export const SignupApi = (params, state) => {
  if (params) {
    //Loader(state);
    const headers = {
      "Content-Type": "application/json",
      "X-App-Code-ver":localStorage.getItem("Apiversion"),
      "X-App-Device-Type":"React",
    };

    axios.post(Url + "signup", params,{headers:headers}).then((res) => 
    {
      if (res.data.code === 200) 
      {
        const datasend = 
        {
              email: params.email,
              user_ip: params.login_ip,
              user_country: params.user_country,
              agency_id:localStorage.getItem('agency_id') ? parseInt(localStorage.getItem('agency_id')) : 1,
              os_name: params.os_name,
              browser: params.browser,
              concode: params.concode,
              conname: params.conname,
              regcode: params.regcode,
              regname: params.regname,
              cityname: params.cityname,
              timezone: params.timezone,
              deviceid: params.deviceuuid,
              name:res.data.data.name,
              id:res.data.data.id,
              password:params.password,
              login_type:0,
        };

        const datasend1 = (btoa(JSON.stringify(datasend)));
        localStorage.setItem("skipdone", 'done');

        state.props.history.push("/sign-up-redirect?data="+datasend1);
       
      }
      else if(res.data.code === 401)
      {
        errortoast(res.data.data);
        erroLoader(state);
      } 
      else if (res.data.code === 201) 
      {
        if(res.data.data === "Please check your email to activate your account.") 
        {
          sucesstoast(res.data.data);
          localStorage.clear();
          localStorage.setItem("skipdone", 'done');
          state.props.history.push("/");
        } 
        else 
        {
          errortoast(res.data.data);
        }
        erroLoader(state);
      } 
      else if (res.data.code === 400) 
      {
        //Remove Pleasewait function
        erroLoader(state);
        //Set error
        if (Object.keys(res.data.data).indexOf("name") === -1) {
          state.setState({
            nameerror: false,
			nameerrormesg: null
          });
        } else {
          state.setState({
            nameerror: true,
			nameerrormesg: res.data.data.name[0]
          });
        }
        if (Object.keys(res.data.data).indexOf("email") === -1) {
          state.setState({
            emailerror: false,
			emailerrormesg: null,
          });
        } else {
          state.setState({
            emailerror: true,
			emailerrormesg: res.data.data.email[0],
          });
        }
        if (Object.keys(res.data.data).indexOf("owner") === -1) {
          state.setState({
            ownererror: null,
          });
        } else {
          state.setState({
            ownererror: res.data.data.owner[0],
          });
        }
        if (Object.keys(res.data.data).indexOf("password") === -1) {
          state.setState({
            passworderror: false,
			passworderrormesg: null
          });
        } else {
          state.setState({
            passworderror: true,
			passworderrormesg: res.data.data.password[0]
          });
        }
      }
    })
    .catch((error) => {});
  }
};
//Forgotpassword API
export const ForgotpasswordApi = (params, state) => {
  Loader(state);
  axios
    .post(Url + "forgot-password", params)
    .then((res) => {
      if (res.data.code === 200) {
        sucesstoast("Please check your email to reset password");
        state.setState({
          submitval: "RESEND",
          loader: "d-none",
          confirmmail: "Please check your email to reset password",
          emailerror: null,
        });
        state.props.history.push("/");
      } else if (res.data.code === 400) {
        state.setState({
          submitval: "SEND",
          loader: "d-none",
        });
        if (Object.keys(res.data.data).indexOf("email") === -1) {
          state.setState({
            emailerror: null,
            confirmmail: null,
          });
        } else {
          state.setState({
            emailerror: res.data.data.email[0],
            confirmmail: null,
          });
        }
      } else if (res.data.code === 401) {
        state.setState({
          emailerror: res.data.data,
          confirmmail: null,
          submitval: "SEND",
          loader: "d-none",
        });
      }
    })
    .catch((error) => {
    });
};
export const ResetpasswordApi = (params, state) => {
  Loader(state);
  axios
    .post(Url + "reset", params)
    .then((res) => {
      if (res.data.code === 200) {
        sucesstoast("Password has been successfully updated.");
        state.props.history.push("/");

        localStorage.clear();
      } else if (res.data.code === 400) {
        state.setState({
          submitval: "SEND",
          loader: "d-none",
        });
        if (Object.keys(res.data.data).indexOf("confirm_password") === -1) {
          state.setState({
            confirm_passworderror: null,
          });
        } else {
          state.setState({
            confirm_passworderror: res.data.data.confirm_password[0],
          });
        }
        if (Object.keys(res.data.data).indexOf("password") === -1) {
          state.setState({
            passworderror: null,
          });
        } else {
          state.setState({
            passworderror: res.data.data.password[0],
          });
        }
      } else if (res.data.code === 401) {
        state.setState({
          submitval: "SEND",
          loader: "d-none",
        });
        state.setState({
          confirm_passworderror:
            "The link has expired. Click on Forgot Password to reset account password.",
        });

        state.props.history.push("/link-expired");
      }
    })
    .catch((error) => {
    });
};

//Activation Api
export const Activatetoken = (params, state) => {
  if (params) {
    axios.post(Activationurl, params).then((res) => 
      {
      if (res.data.code === 200) 
      {
        state.setState({
          spinner: false,
        });
      } else if (res.data.code === 201) {
        if(res.data.data == "Invalid Link")
          {
            state.setState({
              linkexpire: true,
            });
          }
        state.setState({
          spinner: false,
        });
       
      } else if (res.data.code === 401) {
        // errortoast(res.data.data);
      } else {
        errortoast(
          "We are facing some technical issues. Please try again after sometime."
        );
      }
    })
    .catch((error) => {});
  }
};

//Redirectadmin

export const Redirectadmin = (params, state) => {
  const headers = {
    "Content-Type": "text/plain",
    Authorization: "Bearer " + params.token,
  };
  axios
    .post(Url + "details", params.admin, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        localStorage.setItem("user_id", res.data.data.id);

        localStorage.setItem("originalid", res.data.data.id);
        localStorage.setItem("user_name", res.data.data.name);
        localStorage.setItem("user_profile", res.data.data.profile_image);
        state.props.history.push("/apps");
      } else if (res.data.code === 401) {
        errortoast("Please login again");
      }
    })
    .catch((error) => {});
};
//Redirectadmin

export const Validategoogleadmin = (valideparam, callback) => {
  axios.post(Url + "validate-google-login", valideparam).then((res) => {
    if (res.data.code === 200) {
      callback();
    } else {
      errortoast("You are not the valide user to login");
    }
  });
};

export const Verifyemailaccount = (params, state) => {
  const headers = {
    "Content-Type": "text/plain",
    Authorization: "Bearer " + params.token,
  };
  axios
    .post(Url + "verify-email", params.check, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) 
      {
          if(res.data.data == 'Email Address is already verified.')
          {
              if(window.location.host == process.env.REACT_APP_OUR_DOMAIN)
              {
                state.props.history.push("/home");
              }
              else
              {
                state.props.history.push("/apps");
              }
              
          }
          else
          {
            sucesstoast(res.data.data);
          }
        
        state.setState({
          verifytext: "Resend verification email",
          verifyEmailLoader:false,
		  resendtxt: "Resend",
	        loader: "d-none"
        });
      }else{
        errortoast("Oh no! Something’s not right.")
        state.setState({
          
          verifyEmailLoader:false,
		  resendtxt: "Resend",
	        loader: "d-none"
        });
      }
    })
    .catch((error) =>{
      errortoast("Oh no! Something’s not right.")
        state.setState({
          
          verifyEmailLoader:false,
		  resendtxt: "Resend",
	        loader: "d-none"
        });
      
    });
};

//Appsumo signup
export const Appsumosignupapi = (params, state) => {
  if (params) {
    Loader(state);
    const headers = {
      "Content-Type": "application/json",
      "X-App-Code-ver":localStorage.getItem("Apiversion"),
      "X-App-Device-Type":"React",
    };

    axios.post(Url + "signup", params,{headers:headers}).then((res) => {
      if (res.data.code === 200) {
        localStorage.setItem("amstoken", res.data.token.access_token);
        localStorage.setItem("customer_type", res.data.data.customer_type);
        localStorage.setItem("user_id", res.data.data.id);
        localStorage.setItem("logged_in_user_id", res.data.data.id);
        localStorage.setItem("logged_in_user_role_id", 1);
        localStorage.setItem("originalid", res.data.data.id);
        localStorage.setItem("user_name", res.data.data.name);
        localStorage.setItem("user_profile", res.data.data.profile_image);
        state.props.history.push("/deals");
      } else if (res.data.code === 201) {
        if (
          res.data.data === "Please check your email to activate your account."
        ) {
          sucesstoast(res.data.data);
          state.props.history.push("/");
          localStorage.clear();
        } else {
          errortoast(res.data.data);
        }
        erroLoader(state);
      } else if (res.data.code === 400) {
        //Remove Pleasewait function
        erroLoader(state);
        //Set error
        if (Object.keys(res.data.data).indexOf("name") === -1) {
          state.setState({
            nameerror: null,
          });
        } else {
          state.setState({
            nameerror: res.data.data.name[0],
          });
        }
        if (Object.keys(res.data.data).indexOf("email") === -1) {
          state.setState({
            emailerror: null,
          });
        } else {
          state.setState({
            emailerror: res.data.data.email[0],
          });
        }
        if (Object.keys(res.data.data).indexOf("owner") === -1) {
          state.setState({
            ownererror: null,
          });
        } else {
          state.setState({
            ownererror: res.data.data.owner[0],
          });
        }
        if (Object.keys(res.data.data).indexOf("password") === -1) {
          state.setState({
            passworderror: null,
          });
        } else {
          state.setState({
            passworderror: res.data.data.password[0],
          });
        }
      }
    })
    .catch((error) =>{});
  }
};

/**********2 Factor Authentication***********/
export const sendGenerateQRReq = (params, setQRRequest, setQRSecret) =>{
	const headers = {
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("amstoken"),
	};
	axios
	.post(Url + "two-factor-authentication", params, {
		headers: headers,
	})
	.then((res) => {
		
		if (res.data.code === 200) 
		{

			//var url_string = res.data.data; 
			//var tokenval = url_string.split("token=");
			//setIsLoader("d-none");
			//setBtnEnable("Enable");
			setQRRequest(res.data.data.qr_string);
      setQRSecret(res.data.data.secret);
        
		} 
		else if (res.data.code === 400) 
		{
			
		}
	})
  .catch((error) => {});
}

export const sendOTPVerification = (params, setQRCodeError, setShowBackupCodeSection, setShowQrCodeSection, setShowVerifyCodeSection, setBackupCode, setBackupCodeStar, setIsRecoveryLoader, setBtnOpenRecoveryTxt, setUpdateTimeTxt) =>{

  const headers = {
		  "Content-Type": "application/json",
		  Authorization: "Bearer " + localStorage.getItem("amstoken"),
	};
	axios.post(Url + "2fa-user-status", params, {
		headers: headers,
	})
	.then((res) => {
		
		if (res.data.code === 200) 
		{

			
			//setIsLoader("d-none");
			//setBtnVerify("Verify");

      //setIsRecoveryLoader("d-none");
      //setBtnOpenRecoveryTxt("Next");

			setQRCodeError(false);
			//setVerifyCodeBool(res.data.data);

      //let createbtndiv = document.getElementById("btnrecoveryid");
		  //createbtndiv.classList.remove("btnloader");

			if(res.data.data.status === 'verified')
			{

				let param = {};
				localStorage.setItem('is2faenable', true);
				generateBackupCodes(param, setBackupCode, setShowBackupCodeSection, setBackupCodeStar, setShowQrCodeSection, setShowVerifyCodeSection, setIsRecoveryLoader, setBtnOpenRecoveryTxt, setUpdateTimeTxt);

			}
			else
			{
				/*setMdlCode(false);
				setMdlQrCode(false);
				setMdlVerify(true);*/

				setShowBackupCodeSection(false);
				setShowQrCodeSection(false);
				setShowVerifyCodeSection(true);

			}
	
		} 
		else if (res.data.code === 400) 
		{
			//setIsLoader("d-none");
			//setBtnVerify("Verify");
      		setIsRecoveryLoader("d-none");
      		setBtnOpenRecoveryTxt("Next");
			setQRCodeError(true);
			//errortoast(res.data.status);
      		/*setMdlVerify(true);
      		setMdlCode(false);
			setMdlQrCode(false);*/
			setShowBackupCodeSection(false);
			setShowQrCodeSection(false);
			setShowVerifyCodeSection(true);
			//errortoast("");
      let createbtndiv = document.getElementById("btnrecoveryid");
		  createbtndiv.classList.remove("btnloader");

		}
		else if (res.data.code === 401) 
		{		
			//setIsLoader("d-none");
			//setBtnVerify("Verify");		  
      		setIsRecoveryLoader("d-none");
      		setBtnOpenRecoveryTxt("Next");
			setQRCodeError(true);
      		/*setMdlVerify(true);
      		setMdlCode(false);
        	setMdlQrCode(false);*/

			setShowBackupCodeSection(false);
			setShowQrCodeSection(false);
			setShowVerifyCodeSection(true);
			//errortoast(res.data.status); 
      let createbtndiv = document.getElementById("btnrecoveryid");
		  createbtndiv.classList.remove("btnloader");

		}
	})
  .catch((error) => {});
}

export const generateBackupCodes = (param, setBackupCode, setShowBackupCodeSection, setBackupCodeStar, setShowQrCodeSection, setShowVerifyCodeSection, setIsRecoveryLoader, setBtnOpenRecoveryTxt, setUpdateTimeTxt) =>{

  const headers = {
		  "Content-Type": "application/json",
		  Authorization: "Bearer " + localStorage.getItem("amstoken"),
	};
	axios.post(Url + "generate-backup-codes", param, {
		headers: headers,
	})
	.then((res) => {
		if (res.data.code === 200) 
		{
        
			//setBackupCode(Object.values(res.data.data));

      		setIsRecoveryLoader("d-none");
      		setBtnOpenRecoveryTxt("Next");

      		let createbtndiv = document.getElementById("btnrecoveryid");
		  createbtndiv.classList.remove("btnloader");


			let bcodedata = (res.data.data).match(/.{1,4}/g);
			let backupcodevalue = bcodedata.join('  ');
			setBackupCode(backupcodevalue);

      		//setMdlQrCode(false);
      		//setMdlVerify(false);
			setShowQrCodeSection(false);
			setShowVerifyCodeSection(false);


			//setMdlCode(true);
			setShowBackupCodeSection(true);
			setBackupCodeStar(true);
      		//setUpdateTimeTxt(res.data.updatetime);
      
        
		} 
		else if (res.data.code === 400) 
		{
			
			setShowQrCodeSection(false);
			setShowVerifyCodeSection(true);
			setShowBackupCodeSection(false);
		}
		else if (res.data.code === 401) 
		{				  

			setShowQrCodeSection(false);
			setShowVerifyCodeSection(true);
			setShowBackupCodeSection(false);
        
		}
	})
  .catch((error) => {});
}

export const disable2FactorAuth = (params, setIs2FDisableLoader, setBtn2FDisableTxt, setMdlHide2F, setVerifyCodeBool, setShowUpdateTime) =>{
	const headers = {
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("amstoken"),
	};
	axios
	.post(Url + "disable-2fa", params, {
	  headers: headers,
	})
	.then((res) => {
		
		if (res.data.code === 200) 
		{

			localStorage.setItem('is2faenable', false);

			
	
			/*setVerifyCodeBool(false);

			setQRRequest("");*/

      		setIs2FDisableLoader("d-none");
      		setBtn2FDisableTxt("Disable");

      		let createbtndiv = document.getElementById("btndisable2fid");
		  createbtndiv.classList.remove("btnloader");

			//window.location.reload();

      		setMdlHide2F(false);
      		setVerifyCodeBool(false);

			  setShowUpdateTime(false);
		  
		} 
		else if (res.data.code === 400) 
		{
			  
        setMdlHide2F(true);
		}
	})
  .catch((error) => {});
}

export const VerifyUserApi = (params, state) => {
	Loader(state);
	axios
	.post(Url + "check-user-2fa-status", params)
	.then((res) => {
		if (res.data.code === 200) 
		{
			

			if(res.data.data === 1)
			{
				localStorage.setItem("email", params.email);
				localStorage.setItem("user_ip", params.user_ip);
				localStorage.setItem("user_country", params.user_country);
				localStorage.setItem("os_name", params.os_name);
				localStorage.setItem("browser", params.browser);
				localStorage.setItem("concode", params.concode);
				localStorage.setItem("conname", params.conname);
				localStorage.setItem("regcode", params.regcode);
				localStorage.setItem("regname", params.regname);
				localStorage.setItem("cityname", params.cityname);
				state.props.history.push("/two-factor");
			}
			else
			{
				LoginApi(params, state);
			}
			
		} 
		else if (res.data.code === 400) 
		{
			//Remove Pleasewait function
			//errorloginLoader(state);
			
			errorloginLoader(state);
			errortoast(res.data.data);
		} 
		else if (res.data.code === 401) 
		{
			
			errorloginLoader(state);
			errortoast(res.data.data);
		}
	})
	.catch((error) => {
		
		errorloginLoader(state);
		state.props.history.push("/error");
	});
};

export const Verify2FactorAuthCodeApi = (params, setIsVerifyLoader, setSignInText, redirectToMyApps, redirectToGetStarted, setQRCodeError, setQRCodeErrorMesg) => {
	setIsVerifyLoader("d-block");
	setSignInText("");

	axios
	.post(Url + "verify-2fa-login", params)
	.then((res) => {
		if (res.data.code === 200) 
		{
			//setLoader("d-none");
			//setSubmitVal("Sign In");
			
			setIsVerifyLoader("d-none");
			setSignInText("Sign In");

			

			localStorage.setItem("customer_type", res.data.data[0].customer_type);
			localStorage.setItem("amstoken", res.data.token.access_token);
			localStorage.setItem("user_id", res.data.data[0].id);
			localStorage.setItem("logged_in_user_id", res.data.data[0].id);
			localStorage.setItem("originalid", res.data.data[0].id);
			localStorage.setItem("user_name", res.data.data[0].name);
			localStorage.setItem("user_profile", res.data.data[0].profile_image);
			localStorage.setItem("logged_in_user_role_id", 1);
			localStorage.setItem(
			"roleid",
			res.data.data[0].role_id !== undefined ? res.data.data[0].role_id : 2
			);
			localStorage.setItem("is2faenable", true);
			if (res.data.check === "login") 
			{
				redirectToMyApps();
			} 
			else 
			{
				redirectToGetStarted();
			}


			
		} 
		else if (res.data.code === 400) 
		{
			//Remove Pleasewait function
			//errorloginLoader(state);
			
			setIsVerifyLoader("d-none");
			setSignInText("Sign In");
			//setTwoFactorCodeError(res.data.status);
			setQRCodeError(true);
			setQRCodeErrorMesg(res.data.status);

			
		} 
		else if (res.data.code === 401) 
		{
			
			setIsVerifyLoader("d-none");
			setSignInText("Sign In");

			//setTwoFactorCodeError(res.data.status);
			
			setQRCodeError(true);
			setQRCodeErrorMesg(res.data.status);

			//errorloginLoader(state);
        	//errortoast(res.data.status);
		}
	})
	.catch((error) => {
		
		//state.props.history.push("/error");
		window.location.href = '/error';
	});
};

export const ValidateTwoFactorBackupCodeApi = (params, setLoader, setSubmitVal, setTwoFactorBCodeError, redirectToMyApps, redirectToGetStarted) => {
	setLoader("d-block");
	setSubmitVal("");

	axios
	.post(Url + "validate-backup-codes", params)
	.then((res) => {
		if (res.data.code === 200) 
		{
			setLoader("d-none");
			setSubmitVal("Sign In");

			

			localStorage.setItem("customer_type", res.data.data[0].customer_type);
			localStorage.setItem("amstoken", res.data.token.access_token);
			localStorage.setItem("user_id", res.data.data[0].id);
			localStorage.setItem("logged_in_user_id", res.data.data[0].id);
			localStorage.setItem("originalid", res.data.data[0].id);
			localStorage.setItem("user_name", res.data.data[0].name);
			localStorage.setItem("user_profile", res.data.data[0].profile_image);
			localStorage.setItem("logged_in_user_role_id", 1);
			localStorage.setItem(
			"roleid",
			res.data.data[0].role_id !== undefined ? res.data.data[0].role_id : 2
			);
			localStorage.setItem("is2faenable", false);

			if (res.data.check === "login") 
			{
				redirectToMyApps();
			} 
			else 
			{
				redirectToGetStarted();
			}
			

		} 
		else if (res.data.code === 400) 
		{
			//Remove Pleasewait function
			//errorloginLoader(state);
			
			setLoader("d-none");
			setSubmitVal("Sign In");
			setTwoFactorBCodeError("");

			if(res.data.data.email !== '')
			{
				errortoast(res.data.data.email[0]);
			}

			if(res.data.data.user_ip !== '')
			{
				errortoast(res.data.data.user_ip[0]);
			}

		} 
		else if (res.data.code === 401) 
		{
			
			setLoader("d-none");
			setSubmitVal("Sign In");
			setTwoFactorBCodeError("");

			if(res.data.status !== '')
			{
				errortoast(res.data.status);
			}

			if(res.data.data.email !== '')
			{
				errortoast(res.data.data.email[0]);
			}

			if(res.data.data.user_ip !== '')
			{
				errortoast(res.data.data.user_ip[0]);
			}

			//errorloginLoader(state);
        	//errortoast("");
		}
	})
	.catch((error) => {
		
		//state.props.history.push("/error");
		//window.location.href = '/error';
		
	});
};

//Check user two factor enabled or not
export const UserVerifyTwoFactor = (setSpinner, setVerifyCodeBool, setUpdateTimeTxt, setShowUpdateTime, setPassUpdateTimeTxt, setShowPassUpdateTime, setIsPasswordUpdate, setIs2FAUpdate) => {
  	
  	const headers = {
    	"Content-Type": "application/json",
    	Authorization: "Bearer " + localStorage.getItem("amstoken"),
  	};

  	axios
	.get(Url + "user_profile", {
		headers: headers,
    })
    .then((res) => {
      	if (res.data.code === 200) 
		{
			setSpinner(false);
			if(res.data.data.tfa_status === 1)
			{
				setVerifyCodeBool(true);
        		setUpdateTimeTxt(res.data.data.tfa_updated_date);
        		setShowUpdateTime(true);
			}
			else
			{
				setVerifyCodeBool(false);
        		setUpdateTimeTxt("");
        		setShowUpdateTime(false);
			}
			
			if(res.data.data.password_updated_date !== null && res.data.data.password_updated_date !== '' && res.data.data.password_updated_date !== undefined)
			{
				
				setPassUpdateTimeTxt(res.data.data.password_updated_date);
				setShowPassUpdateTime(true);
        setIsPasswordUpdate(false);
        setIs2FAUpdate(false);
			}
			else
			{
				
				setPassUpdateTimeTxt("");
				setShowPassUpdateTime(false);
        setIsPasswordUpdate(false);
        setIs2FAUpdate(false);
			}
			
      	} 
		else if(res.data.code === 203) 
		{
      	}
    })
    .catch((error) => {});
};

export const DeviceDetectData = (state, callback, classtype=null) =>{


	const ipstackurl = "https://api.ipstack.com/check?access_key=" + process.env.REACT_APP_ipstack_key + "&timestamp=" + new Date().getTime();
	const userstackurl = process.env.REACT_APP_userstack_path + "?access_key=" + process.env.REACT_APP_userstack_accesskey + "&ua=" + window.navigator.userAgent;

	const ipstackresp = axios.get(ipstackurl);
	const userstackresp = axios.get(userstackurl);


	/*const []

	axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
		axios.spread(({data: user}, {data:repos}, {data:followers}, {data:following}) => {
		  
		})
	);*/

	Promise.all([ipstackresp, userstackresp])
  		.then(results=>{

			
			if ((results[0] !== undefined && results[0] !== null && results[0] !== '') && (results[1] !== undefined && results[1] !== null && results[1] !== '') ) 
			{
				
				if(classtype === null)
				{
					state.setState({
						user_country: results[0].data.country_code,
						login_ip: results[0].data.ip,
						os_name: results[1].data.os.name,
						browser: results[1].data.browser.name,
						concode: results[0].data.country_code,
						conname: results[0].data.country_name,
						regcode: results[0].data.region_code,
						regname: results[0].data.region_name,
						cityname: results[0].data.city,
						timezone: results[0].data.time_zone.id
					});
				}
				
				localStorage.setItem("usession_ip", results[0].data.ip);
				localStorage.setItem("usession_os", results[1].data.os.name);
				localStorage.setItem("usession_brname", results[1].data.browser.name);
				localStorage.setItem("usession_concode", results[0].data.country_code);
				localStorage.setItem("usession_conname", results[0].data.country_name);
				localStorage.setItem("usession_regcode", results[0].data.region_code);
				localStorage.setItem("usession_regname", results[0].data.region_name);
				localStorage.setItem("usession_timezone", results[0].data.time_zone.id);
				localStorage.setItem("usession_city", results[0].data.city);

				callback();
			} 
			else 
			{
				state.setState({
					user_country: "",
					login_ip: "",
					os_name: "",
					browser: "",
					concode: "",
					conname: "",
					regcode: "",
					regname: "",
					cityname: "",
					timezone: "",
				});
				
				callback();
			}
    
  	})
	.catch((error) => {
		
		
		/*state.setState({
			user_country: "",
			login_ip: "",
			os_name: "",
			browser: "",
			concode: "",
			conname: "",
			regcode: "",
			regname: "",
			cityname: "",
			timezone: ""
		});*/
		
	});
}

export const UserSessionData = (setSessionData) =>{

	const headers = {
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("amstoken"),
	};

	axios
	.get(Url + "sessiondata", {
		headers: headers,
    })
    .then((res) => {
      	if (res.data.code === 200) 
		{
			
			

			setSessionData(res.data.data);
      	} 
		else if(res.data.code === 203) 
		{
        		
      	}
    })
    .catch((error) => {
		
		
    });
}

export const SignoutAllDevices = (params, redirectToLoginPage) =>{

	const headers = {
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("amstoken"),
	};

	axios
	.get(Url + "signoutalldevices", {
		headers: headers,
    })
    .then((res) => {
      	if (res.data.code === 200) 
		{
			
			
			let myItem = localStorage.getItem("buildversion");

          	let myItemversion = localStorage.getItem("Apiversion");

          	let previous_location = localStorage.getItem("Previouslocation");

          	localStorage.clear();

          	localStorage.setItem("Previouslocation", previous_location);

          	localStorage.setItem("buildversion", myItem);

          	localStorage.setItem("Apiversion", myItemversion);

			redirectToLoginPage();

      	} 
		else if(res.data.code === 203) 
		{
			

      	}
		else if(res.data.code === 400) 
		{
			

		}
    })
    .catch((error) => {
		
		
    });
}

//Update user current session
export const saveUserCurrentSession = (params) => {

	const headers = {
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("amstoken"),
	};

	axios
	.post(Url + "save-user-current-session", params, {
		headers: headers,
    })
	.then((res) => {
		if (res.data.code === 200) 
		{			

			

		} 
		else if (res.data.code === 400) 
		{
			//Remove Pleasewait function
			//errorloginLoader(state);
			
			

		} 
		else if (res.data.code === 401) 
		{
			
		}
	})
	.catch((error) => {
		
		
	});
};

   
