import axios from "axios";
import {errortoast, sucesstoast} from '../../Dashboard/Toaster';
import { Redirect, useHistory } from "react-router-dom";
import S3 from "react-aws-s3";
import { parse } from "date-fns";
import moment from "moment";
import { useNavigate } from 'react-router-dom';



const newUrlForPaddle = process.env.REACT_APP_API_URL + "api/paddle/";
const Url = process.env.REACT_APP_API_URL + "api/";
const UseUrl = process.env.REACT_APP_API_URL + "api/user/";
//const Url = "https://test.admin.appmysite.com/" + "api/";



//Save Agency Name
export const saveAgency = (params, setBtnLoader, setBtnSubmitName, redirectToOrgDetailPage) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.post(Url + "save-agency", params, {
      headers: headers
    }).then(res => {
      
        if(res.data.code === 200)
        {
            setBtnLoader("d-none");
            setBtnSubmitName("Create");            

            localStorage.removeItem("agency_name");
            localStorage.removeItem("agencyfavicon");
            localStorage.removeItem("agencylogo");
            localStorage.removeItem("agencyname");
            localStorage.removeItem("agencyprimary_color");
            localStorage.removeItem("agency_id");
            localStorage.removeItem("agencysecondary_color");

            let createbtndiv = document.getElementById("btncreateorgid");
		    createbtndiv.classList.remove("btnloader");

            localStorage.setItem("agency_name", params.name);
            localStorage.setItem("agency_id", res.data.data.id);

            const userinfo  = JSON.parse(localStorage.getItem("user_info"));
            let original_id = parseInt(localStorage.getItem("logged_in_user_role_id"))=== 1 
                            ? localStorage.getItem("originalid") 
                            : localStorage.getItem("invited_by_user_id");

            window.dataLayer = window.dataLayer || [];
            
            window.dataLayer.push({
            'event': 'create_agency',
            'user_email': userinfo.email,
            'user_name' : userinfo.name,
            'user_id': original_id,
            'agency_id': res.data.data.id
            });

			let urldata = "/agency/dashboard/" + btoa(res.data.data.id);
            redirectToOrgDetailPage(urldata);
            
        }
		else if(res.data.code === 400)
        {

            setBtnLoader("d-none");
            setBtnSubmitName("Create");
            //redirectToOrgDetailPage("agency-details/");

			
			errortoast('Oops! Something went wrong.');
            
        }
     
    })
}

//Get Agency Name
export const getAgencyName = (agencyID, setOrgName, setSpinner, setFaviconImage, setLogoImage, setUserID, setPrimaryColor, setSecondaryColor, setFaviconUpdTxt,setButtonColor,setButtonTextColor,setLastUpdatedAt,setappscount,setButtonHoverColor,setButtonHoverTextColor) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.get(Url + "get-agency-detail/" + agencyID, {
      headers: headers
    }).then(res => {
      
        if(res.data.code === 200)
        {           
            //setBtnLoader("d-none");
            //setBtnSubmitName("Create");
            setSpinner(false);
            localStorage.setItem("agency_id", res.data.data.id);
            setappscount(res.data.apps_count);
            
            if(res.data.data !== null && res.data.data !== '')
            {
                setOrgName(res.data.data.agency_name);
            }
			
            if(res.data.data.favicon !== null && res.data.data.favicon !== '')
            {
                setFaviconImage(res.data.data.favicon);
                setFaviconUpdTxt(res.data.data.favicon);
            }

            if(res.data.data.agency_logo !== null && res.data.data.agency_logo !== '')
            {
                setLogoImage(res.data.data.agency_logo);
            }

            setUserID(res.data.data.user_id);

            if(res.data.data.brand_primary_color !== null && res.data.data.brand_primary_color !== '')
            {
                setPrimaryColor(res.data.data.brand_primary_color);
            }

            if(res.data.data.brand_secondary_color !== null && res.data.data.brand_secondary_color !== '')
            {
                setSecondaryColor(res.data.data.brand_secondary_color);
            }

            if(res.data.data.button_background_color !== null && res.data.data.button_background_color !== '')
            {
                setButtonColor(res.data.data.button_background_color);
            }

            if(res.data.data.button_text_color !== null && res.data.data.button_text_color !== '')
            {
                setButtonTextColor(res.data.data.button_text_color);
            }

            if(res.data.data.button_hover_bgcolor !== null && res.data.data.button_hover_bgcolor !== '')
            {
                setButtonHoverColor(res.data.data.button_hover_bgcolor);
            }

            if(res.data.data.button_hover_txtcolor !== null && res.data.data.button_hover_txtcolor !== '')
            {
                setButtonHoverTextColor(res.data.data.button_hover_txtcolor);
            }

            setLastUpdatedAt(res.data.data.updated_at);
        }
     
    })
}

//Get Agency Dashboard data
export const getAgencyDataGraph = (agencyID, datefilter,setperiods,setgraphdata,setGraphLoad,intervalFilterVal) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }

    const params = {
        "agency_id": parseInt(atob(agencyID)),
        "datefilter": datefilter,
        "interval":intervalFilterVal,
    }
    
    axios.post(Url + "get-agency-dashboard-graph", params,{
      headers: headers
    }).then(res => {
      
        if(res.data.code === 200)
        {
            setperiods(res.data.period);
            setgraphdata(res.data.graphdata);
            setGraphLoad(false);
           
        }
     
    })
}

//Get Agency Name
export const leftgetAgencyName = (agencyID, setFaviconImage,setuserrole) => 
{
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.get(Url + "get-agency-detail/" + agencyID, {
      headers: headers
    }).then(res => {
      
        if(res.data.code === 200)
        {
            localStorage.setItem('isUpgradeVisibleInAgency',res.data.billing_type); 
            localStorage.setItem('agencyname',res.data.data.agency_name); 
            localStorage.setItem('agencyuserroleid',res.data.data.role_id);
            localStorage.setItem('isAgencyReseller',res.data.reseller_package_id);
            if(res.data.data.favicon !== null && res.data.data.favicon !== '')
            {
                localStorage.setItem('agencyfaciconimage',res.data.data.favicon);
                setFaviconImage(res.data.data.favicon);
               
            }
            setuserrole(res.data.data.role_id);
            
        }
     
    })
}

//Get Agency Name
export const getAgencyDomainStatus = (agencyID, setDomainName,setDomainStatus) => 
{
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.get(Url + "get-agency-detail/" + agencyID, {
      headers: headers
    }).then(res => {
      
        if(res.data.code === 200)
        {
            setDomainName('https://'+res.data.data.agency_domain);
            setDomainStatus(res.data.data.subdomain_verification_status);
            
        }
     
    })
}

/********Save Agency Details**********/
export const saveAgencyDetails = (params, setBtnSaveLabel, setBtnLoader, redirectToOrgListPage,setLastUpdatedAt) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.post(Url + "save-agency-details", params, {
      headers: headers
    }).then(res => {
        
        setLastUpdatedAt(res.data.data.updated_at);
        if(res.data.code === 200)
        {
            setBtnLoader("d-none");
            setBtnSaveLabel("Save");

            localStorage.setItem("agencylogo", params.agency_logo);
            localStorage.setItem("agencyfaciconimage", params.favicon);
            localStorage.setItem("agency_name", params.agency_name);

			//let urldata = "/agencies";
            //redirectToOrgListPage(urldata);

            sucesstoast("The record has been saved.");
            
        }
		else if(res.data.code === 400)
        {

            setBtnLoader("d-none");
            setBtnSaveLabel("Save");
            //redirectToOrgDetailPage("agency-details/");

			/*if(res.data.data.agency_id !== '')
			{
				errortoast(res.data.data.agency_id[0]);
			}*/
            
        }
     
    })
}


/********Get All Agency List******/
export const getAgencyList = (setAgencyList, setSpinner, setInvitedOrgList) => {
    //Loader(state);
    
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    axios.get(Url + "get-agencies" ,{
      headers: headers
        }).then(res => {
            if(res.data.code === 200)
            {                
                setSpinner(false);
                //setagencyList(res.data.data);
                setAgencyList(res.data.ownner_data);
                setInvitedOrgList(res.data.invited_data);

                localStorage.removeItem("agency_name");
                localStorage.removeItem("agencyfavicon");
                localStorage.removeItem("agencylogo");
                localStorage.removeItem("agencyname");
                localStorage.removeItem("agencyprimary_color");
                localStorage.removeItem("agency_id");
                localStorage.removeItem("agencysecondary_color");
                
            }
       })
       .catch((err) => {
            errortoast("Oops! Something went wrong");
        });
}

/********Leave Agency**********/
export const LeaveAgency = (params, redirectToOrgListPage, setBtnLeaveLoader, setLeaveLabel) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.post(Url + "leave-agency", params, {
      headers: headers
    }).then(res => {
      
        if(res.data.code === 200)
        {
            setBtnLeaveLoader("d-none");
            setLeaveLabel("LEAVE");

            let leavebtndiv = document.getElementById("btnleaveorgid");
		    leavebtndiv.classList.remove("btnloader");

			let urldata = "/agencies";
            redirectToOrgListPage(urldata);
            
        }
		else if(res.data.code === 400)
        {

            //setBtnLoader("d-none");
            //setBtnSaveLabel("SAVE");
            //redirectToOrgDetailPage("agency-details/");

            setBtnLeaveLoader("d-none");
            setLeaveLabel("LEAVE");

			if(res.data.data.agency_id !== '')
			{
				errortoast('Oops! Something went wrong.');
			}
            
        }
     
    })
}

/********Delete Agency**********/
export const DeleteAgency = (params, redirectToOrgListPage, setBtnDeleteLoader, setDeleteLabel) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.post(Url + "delete-agency", params, {
      headers: headers
    }).then(res => {
      
        if(res.data.code === 200)
        {
            setBtnDeleteLoader("d-none");
            setDeleteLabel("Delete");

            let delbtndiv = document.getElementById("btndeleteorgid");
			delbtndiv.classList.remove("btnloader");

			let urldata = "/agencies";
            redirectToOrgListPage(urldata);
            
        }
        else if(res.data.code === 500)
        {
            setBtnDeleteLoader("d-none");
            setDeleteLabel("Delete");
            
			errortoast('Please delete all apps before deleting agency.');
			
        }
        else if(res.data.code === 501)
        {
            setBtnDeleteLoader("d-none");
            setDeleteLabel("Delete");
            
            errortoast(res.data.data);
            
        }
		else if(res.data.code === 400)
        {

            setBtnDeleteLoader("d-none");
            setDeleteLabel("Delete");
            //redirectToOrgDetailPage("agency-details/");

			if(res.data.data.agency_id !== '')
			{
				errortoast('Oops! Something went wrong.');
			}
            
        }
     
    })
}

/*****Get Support Details*************/
export const getSupportDetail = (agencyID, setSpinner, setEmail, setPhone, setPhoneCode, setCountryNamePrefix, setKnowledgeURL, setProductURL, setCommunityURL,setLastDate,setMasterToggle) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.get(Url + "get-agency-support-data/"+agencyID, {
      headers: headers
    }).then(res => {
      
        if(res.data.code === 200)
        {
            //setBtnLoader("d-none");
            //setBtnSubmitName("Create");
            setSpinner(false);

            if(res.data.data !== '' && res.data.data !== null)
            {
			    setEmail(res.data.data.support_email);
			    setPhone(res.data.data.phone);
			    //setHelpURL(res.data.data.help_center_url);
                if(res.data.data.phone_country_code !== undefined && res.data.data.phone_country_code !== null && res.data.data.phone_country_code !== '')
                {
                    setPhoneCode(res.data.data.phone_country_code);
                }
                else
                {
                    setPhoneCode(91);
                }

                if(res.data.data.country_code !== undefined && res.data.data.country_code !== null && res.data.data.country_code !== '')
                {
                    setCountryNamePrefix(res.data.data.country_code);
                }
                else
                {
                    setCountryNamePrefix("IN");
                }

                setKnowledgeURL(res.data.data.knowledge_base_url);
                setProductURL(res.data.data.product_url); 
                setCommunityURL(res.data.data.community_url);
                setLastDate(res.data.data.customer_support_datetime);
                setMasterToggle(
                  res.data.data.is_customer_support_enable === true ? 1 
                : res.data.data.is_customer_support_enable === 1 ? 1 
                : res.data.data.is_customer_support_enable === false ? 0 
                : res.data.data.is_customer_support_enable === 0 ? 0 
                : false)
                
            }
            
        }
     
    })
}

/********Save Support Details**********/
export const saveSupportDetails = (params, setBtnSaveLabel, setBtnLoader,setLastDate) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.post(Url + "agency-support-data", params, {
      headers: headers
    }).then(res => {

        setLastDate(res.data.data.updated_at);

        if(res.data.code === 200)
        {
            setBtnLoader("d-none");
            setBtnSaveLabel("Save");


            sucesstoast("The record has been saved.");
            
        }
		else if(res.data.code === 400)
        {

            setBtnLoader("d-none");
            setBtnSaveLabel("Save");
            //redirectToOrgDetailPage("agency-details/");

			errortoast('Oops! Something went wrong.');
            
        }
     
    })
}

/*****Get Download Plugin Details*************/
export const getDownloadPluginDetails = (agencyID, setSpinner, setPluginName, setPluginDesc, setAuthorName, setAuthorURL,setLastDate) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.get(Url + "get-agency-plugin-data/"+agencyID, {
      headers: headers
    }).then(res => {
      
        if(res.data.code === 200)
        {

            if(res.data.data !== '' && res.data.data !== null)
            {
                setLastDate(res.data.data.updated_at);
                setPluginName(res.data.data.plugin_name);
                setPluginDesc(res.data.data.description);
                setAuthorName(res.data.data.author_name);
                setAuthorURL(res.data.data.author_url);
               
            }
            setSpinner(false);
            
        }
     
    })
}

/********Save Download Plugin Details**********/
export const saveDownloadPluginDetails = (params, setBtnSaveLabel, setBtnLoader,setLastDate) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.post(Url + "agency-plugin-data", params, {
      headers: headers
    }).then(res => {
      
        setLastDate(res.data.data.updated_at);
        if(res.data.code === 200)
        {
            setBtnLoader("d-none");
            setBtnSaveLabel("Save");

            sucesstoast("The record has been saved.");
            
        }
		else if(res.data.code === 400)
        {

            setBtnLoader("d-none");
            setBtnSaveLabel("Save");
            //redirectToOrgDetailPage("agency-details/");

			if(res.data.data.agency_id !== '')
			{
				errortoast('Oops! Something went wrong.');
			}
            
        }
     
    })
}

/********Save Social Authentication**********/
export const saveSocialAuth = (params, setBtnSaveLabel, setBtnLoader,setLastDate) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.post(Url + "agency-social-data", params, {
      headers: headers
    }).then(res => {

        setLastDate(res.data.data.updated_at);
      
        if(res.data.code === 200)
        {
            setBtnLoader("d-none");
            setBtnSaveLabel("Save");

            let savesocialbtndiv = document.getElementById("btnsavesocialid");
		    savesocialbtndiv.classList.remove("btnloader");
			
            sucesstoast("The record has been saved.");
            
        }
		else if(res.data.code === 400)
        {

            setBtnLoader("d-none");
            setBtnSaveLabel("Save");
            //redirectToOrgDetailPage("agency-details/");

			if(res.data.data.fb_app_id !== '')
			{
				errortoast('Oops! Something went wrong.');
			}
            else if(res.data.data.fb_app_secret !== '')
			{
				errortoast('Oops! Something went wrong.');
			}
            
        }
     
    })
}

/*****Get Social Authentication Details*************/
export const getSocialAuth = (agencyID, setChkGoogleLogin, setGoogleAppID, setGoogleAppSecret, setChkFacebookLogin, setFacebookAppID, setFacebookAppSecret, setSpinner,setLastDate,setChkCaptcha,setCaptchaID,setCaptchaSecret) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.get(Url + "get-agency-social-data/"+agencyID, {
      headers: headers
    }).then(res => {
      
        if(res.data.code === 200)
        {
            //setBtnLoader("d-none");
            //setBtnSubmitName("Create");
            setSpinner(false);
			setChkGoogleLogin(parseInt(res.data.data.is_google_login_enabled));
			setGoogleAppID(res.data.data.google_app_id);
			setGoogleAppSecret(res.data.data.google_app_secret);
			setChkFacebookLogin(parseInt(res.data.data.is_fb_login_enabled));
			setFacebookAppID(res.data.data.fb_app_id);
			setFacebookAppSecret(res.data.data.fb_app_secret);
            setLastDate(res.data.data.social_update_datetime);
            setChkCaptcha(parseInt(res.data.data.is_google_captcha_enabled));
            setCaptchaID((res.data.data.google_captcha_key));
            setCaptchaSecret((res.data.data.google_captcha_secret));

            
        }
     
    })
}

/************Get Top Header Menu Data *******************/
export const getMenuData = (limit, setMenuData, setUserInfo, setInvitedWorkspace, setWorkspaceCount, setPersonalAppCount, setPersonalInvitedAppCount, setUserRoles, setAgencies, setProfileImage) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.get(Url + "get-dropdown-data?limit=3", {
      headers: headers
    }).then(res => {
      
        if(res.data.code === 200)
        {
            
            //setSpinner(false);

            if(res.data.data.length > 0)
            {
                setMenuData(res.data.data);
            }
            else
            {
                setMenuData(null);
            }

            setUserInfo(res.data.user_info);
            setInvitedWorkspace(res.data.invited_workspace);
            setWorkspaceCount(res.data.total_workspace_count);
            setPersonalAppCount(res.data.total_personal_app_count);
            setPersonalInvitedAppCount(res.data.total_personal_invited_app_count);
            setUserRoles(res.data.user_role);			
            setAgencies(res.data.agency_data);

            if(res.data.user_info.profile_image !== '' && res.data.user_info.profile_image !== null && res.data.user_info.profile_image !== undefined)
            {
                setProfileImage(res.data.user_info.profile_image);
            }
        }
     
    })
}

/********Save Email Configuration**********/
export const saveEmailConfiguration = (params, setBtnSaveLabel, setBtnLoader, setIsBtnVerify,setLastDate,setFromEmailStatus, setSMTPConnectionStatus) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.post(Url + "agency-email", params, {
      headers: headers
    }).then(res => {
      
        if(res.data.code === 200)
        {
            setBtnLoader("d-none");
            setBtnSaveLabel("Save");
            setLastDate(res.data.data.updated_at);
            setFromEmailStatus(res.data.data.from_email_status == 1 ? true :false);
            setSMTPConnectionStatus(res.data.data.smtp_connection_status == 1 ? true :false);

            setIsBtnVerify(true);

            let saveemailbtndiv = document.getElementById("btnsaveemailid");
		    saveemailbtndiv.classList.remove("btnloader");
			
            sucesstoast("The record has been saved.");
            
        }
		else if(res.data.code === 400)
        {

            setBtnLoader("d-none");
            setBtnSaveLabel("Save");
            //redirectToOrgDetailPage("agency-details/");

			if(res.data.data.is_smtp_enabled !== '')
            {
                errortoast('Oops! Something went wrong.');
            }            
            else if(res.data.data.smtp_host !== '')
            {
                errortoast('Oops! Something went wrong.');
            }
            else if(res.data.data.smtp_password !== '')
            {
                errortoast('Oops! Something went wrong.');
            }
            else if(res.data.data.smtp_port !== '')
            {
                errortoast('Oops! Something went wrong.');
            }
            else if(res.data.data.smtp_username !== '')
            {
                errortoast('Oops! Something went wrong.');
            }
            else if(res.data.data.connection_type !== '')
            {
                errortoast('Oops! Something went wrong.');
            }            
            else if(res.data.data.agency_id !== '')
			{
				errortoast('Oops! Something went wrong.');
			}

            
        }
     
    })
}

/*****Get Email Configuration*************/
export const getEmailConfiguration = (setSpinner, agencyID, setFromName, setFromAddress, setIsSMTPActive, setSMTPHost, setUsername, setPassword, setConnectionType, setConnectionPort, setDomainStatus, setFromEmailStatus, setSMTPConnectionStatus, setIsBtnVerify,setLastDate) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.get(Url + "get-agency-email/"+agencyID, {
      headers: headers
    }).then(res => {
      
        if(res.data.code === 200)
        {
            //setBtnLoader("d-none");
            //setBtnSubmitName("Create");
            setSpinner(false);
			setFromName(res.data.data.from_name);
            setFromAddress(res.data.data.from_email);
            setIsSMTPActive(res.data.data.smtp_status);
            setSMTPHost(res.data.data.smtp_host);
            setUsername(res.data.data.smtp_username);
        if(res.data && res.data.data && res.data.data.smtp_password && res.data.data.smtp_password != '' )
        {
            setPassword(atob(res.data.data.smtp_password));
        }
            
            setConnectionType(res.data.data.connection_type ? res.data.data.connection_type : '3');
            setConnectionPort(res.data.data.smtp_port ? res.data.data.smtp_port : '25');
            setDomainStatus(res.data.data.domain_verification_status);
            setFromEmailStatus(res.data.data.from_email_status);
            setSMTPConnectionStatus(res.data.data.smtp_connection_status);
            setLastDate(res.data.data.email_config_datetime);

            localStorage.setItem("org_email", res.data.data.from_email);

            if(res.data.data.from_email !== '' && res.data.data.from_email !== null && res.data.data.from_email !== undefined && res.data.data.from_email !== 'null')
            {
                setIsBtnVerify(true);
            }
        }
        else
        {
            setSpinner(false);   
        }
     
    })
}

/********Save Team Data**********/
export const saveTeamsData = (params, setBtnSaveLabel, setBtnLoader, setAddTeamPopup, setIsAdded, isadded) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.post(Url + "save-agency-user", params, {
      headers: headers
    }).then(res => {
      
        if(res.data.code === 200)
        {
            setBtnLoader("d-none");
            setBtnSaveLabel("Save");
            setAddTeamPopup(false);
            setIsAdded(!isadded);

            if(params.id === '')
            {
                let invitebtndiv = document.getElementById("btninviteid");
                invitebtndiv.classList.remove("btnloader");
            }

            if(params.id !== '')
            {
                let updatebtndiv = document.getElementById("btnupdateid");
                updatebtndiv.classList.remove("btnloader");
            }
			
            sucesstoast("The record has been saved.");
            
        }
		else if(res.data.code === 400)
        {

            setBtnLoader("d-none");
            setBtnSaveLabel("Save");
            setAddTeamPopup(false);

			if(res.data.data.agency_id !== '')
			{
				errortoast('Oops! Something went wrong.');
			}
            
        }
        else if(res.data.code === 403)
        {
            setBtnLoader("d-none");
            setBtnSaveLabel("Save");
            setAddTeamPopup(false);

            if(res.data.data !== '')
			{
				errortoast('Oops! Something went wrong.');
			}
        }
     
    })
}

/********Update Team Data**********/
export const updateTeamsData = (params, setBtnUpdateLabel, setBtnLoader, setEditTeamPopup, setIsAdded, isadded) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.post(Url + "save-agency-user", params, {
      headers: headers
    }).then(res => {
      
        if(res.data.code === 200)
        {
            setBtnLoader("d-none");
            setBtnUpdateLabel("Save");
            setEditTeamPopup(false);
            setIsAdded(!isadded);

            if(params.id === '')
            {
                let invitebtndiv = document.getElementById("btninviteid");
                invitebtndiv.classList.remove("btnloader");
            }

            if(params.id !== '')
            {
                let updatebtndiv = document.getElementById("btnupdateid");
                updatebtndiv.classList.remove("btnloader");
            }
			
            sucesstoast("The record has been updated.");
            
        }
		else if(res.data.code === 400)
        {

            setBtnLoader("d-none");
            setBtnUpdateLabel("Save");
            setEditTeamPopup(false);

			if(res.data.data.agency_id !== '')
			{
				errortoast('Oops! Something went wrong.');
			}
            
        }
     
    })
}

/*****Get Team Data*************/
export const getTeamsData = (agencyID, setUsers, setSpinner, usercount, setUserCount, setOwner, setPlanName,setLogoImage,setOrgName,setUserlimit,setuser_email,setuser_role_id) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.get(Url + "list-agency-users/"+agencyID, {
      headers: headers
    }).then(res => {
      
        if(res.data.code === 200)
        {  
            setSpinner(false);
			setUsers(res.data.data);
            let totalcount = parseInt(res.data.user_count);
            setUserCount(totalcount);
            setOwner(res.data.owner_data);
            setPlanName(res.data.plan);
            
        if(res.data.allowed_users === '')
        {
            setUserlimit(100000);
        }
        else
        {
            setUserlimit(res.data.allowed_users);
        }
            
            setuser_email(res.data.user_email);
            setuser_role_id(res.data.appuser_role_id);

        }
     
    })
}

/********Delete Team Data**********/
export const DeleteAgencyTeam = (params, setIsDelete,setSpinner, isdelete) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.post(Url + "remove-agency-user", params, {
      headers: headers
    }).then(res => {
      
        if(res.data.code === 200)
        {
            //setBtnLoader("d-none");
            //setBtnSaveLabel("SAVE");
            //setAddTeamPopup(false);
            setIsDelete(!isdelete);
            setSpinner(false);
			
            sucesstoast("The record has been deleted.");
            
        }
		else if(res.data.code === 400)
        {

            //setBtnLoader("d-none");
            //setBtnSaveLabel("SAVE");
            //setAddTeamPopup(false);

			if(res.data.data.agency_id !== '')
			{
				errortoast('Oops! Something went wrong.');
			}
            
        }
     
    })
}

/********Save Domain Data**********/
export const saveDomainDetails = (params, setBtnLoader, setBtnSaveLabel, setIsBtnVerify,setLastDate,setIsVerify,setcreatedomain,setSubdomainIsVerify,setSubdomainIsPending) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.post(Url + "create-agency-domain", params, {
      headers: headers
    }).then(res => {
      
        if(res.data.code === 200)
        {
            setBtnLoader("d-none");
            setBtnSaveLabel("Save");
            setIsBtnVerify(true);
            setLastDate(res.data.updated_date);

            if(res.data.data.domain_verification_status === 1)
            {
                setIsVerify(true);
            }
            else
            {
                setIsVerify(false);
            }

            if(res.data.data.subdomain_verification_status === 2)
            {
                setcreatedomain(false);
                setSubdomainIsVerify(true);
            }
            else if(res.data.data.subdomain_verification_status === 3)
            {
                
                setcreatedomain(true);
            }
            else if(res.data.data.subdomain_verification_status === 1)
            {
                setcreatedomain(false);
                setSubdomainIsPending(true);
            }
            else
            {
                setcreatedomain(false);
                setSubdomainIsVerify(false);
                setSubdomainIsPending(false)
            }
            

            let savebtndiv = document.getElementById("btnsavedomainid");
		    savebtndiv.classList.remove("btnloader");
			
            sucesstoast("The record has been saved.");
            
        }
		else if(res.data.code === 400)
        {

            setBtnLoader("d-none");
            setBtnSaveLabel("Save");
            setLastDate(res.data.updated_date);

            if(res.data.status == "already exists")
            {
                errortoast("Subdomain already in use.");
            }
            else
            {
                errortoast("Oops! Something went wrong.");
            }

			
            
        }
        else
        {
            errortoast("Oops! Something went wrong.");
        }
     
    })
}

/*****Get Team Data*************/
export const getDomainDetail = (agencyID, setSpinner, setDomain, setSubDomain, setDisplayDomain, setExt, setCname, setIsVerify, setCnameValue, setIsBtnVerify,setLastDate,setSubdomainIsVerify,setSubdomainIsPending,setPaidSubscription,setcreatedomain) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.get(Url + "get-agency-domain/"+agencyID, {
      headers: headers
    }).then(res => {
      
        if(res.data.code === 200)
        {
            
            //setBtnLoader("d-none");
            //setBtnSubmitName("Create");
            setSpinner(false);
			setDomain(res.data.data.domain_name);
			setSubDomain(res.data.data.subdomain_name);
            setLastDate(res.data.data.domain_update_datetime);
            setPaidSubscription(res.data.data.paid_subscription_status);

            let domainname = res.data.data.domain_name;

            if(domainname !== null)
            {
                let domain_split = domainname.split(".");
                if(domain_split.length > 0)
                {
                    setDisplayDomain(domain_split[0]);
                    setExt(domain_split[1]);
                }
            }

            if(res.data.data.subdomain_name !== '' && res.data.data.subdomain_name !== null && res.data.data.subdomain_name !== undefined)
            {
                let cname_data = res.data.data.subdomain_name + "." + res.data.data.domain_name;
                setCname(cname_data);
                setIsBtnVerify(true);
            }
            /*else
            {
                cname_data = "app." + (event.target.value);
            }*/

            if(res.data.data.domain_verification_status === 1)
            {
                setIsVerify(true);
            }
            else
            {
                setIsVerify(false);
            }

            if(res.data.data.subdomain_verification_status === 2)
            {
                setcreatedomain(false);
                setSubdomainIsVerify(true);
            }
            else if(res.data.data.subdomain_verification_status === 3)
            {
                
                setcreatedomain(true);
            }
            else if(res.data.data.subdomain_verification_status === 1)
            {
                setcreatedomain(false);
                setSubdomainIsPending(true);
            }
            else
            {
                setcreatedomain(false);
                setSubdomainIsVerify(false);
                setSubdomainIsPending(false)
            }

            if(res.data.data.cnamevalue !== '' && res.data.data.cnamevalue !== null)
            {
                setCnameValue(res.data.data.cnamevalue);
            }


        }
    }).catch((err)=>{
     console.error(err);
    })
}

/********Verify Domain Data**********/
export const verifyDomainDetails = (params, setBtnVerifyLoader, setBtnVerifyLabel, setChkVerify, setIsVerify,setSubdomainIsVerify,setLastDate,setSubdomainIsPending,setcreatedomain) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.post(Url + "verify-domain-name", params, {
      headers: headers
    }).then(res => {
      
        if(res.data.code === 200)
        {
            setBtnVerifyLoader("d-none");
            setBtnVerifyLabel("Verify");
			setChkVerify(true);
            setLastDate(res.data.updated_date);
           

            let verifybtndiv = document.getElementById("btnverifydomainid");
		    verifybtndiv.classList.remove("btnloader");
        
            if(res.data.subdomain_status === 'exists')
            {
                setIsVerify(false);
                setSubdomainIsVerify(true);
                setSubdomainIsPending(false);
                setcreatedomain(false);
                sucesstoast("The record has been verified.");
            }
            else if(res.data.subdomain_status === 'error')
            {
                setIsVerify(false);
                setSubdomainIsVerify(false);
                setSubdomainIsPending(false);
                setcreatedomain(false);
                errortoast("Subdomain verification failed. Check your DNS record and try again.");
            }
            else if(res.data.subdomain_status == 'cpanel_error')
            {
                setIsVerify(false);
                setSubdomainIsVerify(false);
                setSubdomainIsPending(false);
                setcreatedomain(true);
                errortoast("Something went wrong. Please contact support.");
            }
            else if(res.data.subdomain_status === 'success')
            {
                setIsVerify(false);
                setSubdomainIsVerify(true);
                setSubdomainIsPending(false);
                setcreatedomain(false);
                sucesstoast("Subdomain created successfully.");
            }
            else
            {
                setIsVerify(false);
                setSubdomainIsVerify(false);
                setSubdomainIsPending(false);
                setcreatedomain(false);
                errortoast("Something went wrong.");
            }
            

            
        }
		else if(res.data.code === 400)
        {

            setBtnVerifyLoader("d-none");
            setBtnVerifyLabel("Verify");
            setChkVerify(true);
            setIsVerify(false);
            setLastDate(res.data.updated_date);
			/*if(res.data.status !== '')
			{
				errortoast(res.data.status);
			}*/
            if(res.data.subdomain_status === 'pending')
            {
                setIsVerify(false);
                setSubdomainIsPending(false);
                setSubdomainIsVerify(false);
                sucesstoast("Subdomain activation request submitted.");
            }
            else if(res.data.subdomain_status === 'error')
            {
                setIsVerify(false);
                setSubdomainIsVerify(false);
                setSubdomainIsPending(false);
                errortoast("Subdomain verification failed. Check your DNS record and try again.");
            }
            else if(res.data.status === 'exists')
            {
                setIsVerify(false);
                setSubdomainIsPending(false);
                setSubdomainIsVerify(false);
                errortoast("Subdomain already in use.");
            }
            else if(res.data.status === 'success')
            {
                setIsVerify(false);
                setSubdomainIsPending(false);
                setSubdomainIsVerify(false);
                sucesstoast("Subdomain activation request submitted.");
            }
            
        }
     
    })
}

/********Get All Agency List******/
export const getAgencyDashboard = (agencyID, setSpinner, setAppsCount,setCustomerCount,setLogoImage,setOrgName, setSubDomain, setOrgDate, setPlanName, setFreeSubscriberCount, setPaidSubscriberCount, setAndroidAppsCount, setIosAppsCount, setGoogleAppsCount, setAppleAppsCount, setOwnerData, setUsersData, setDomainVerified, setEmailDataEnable, setSocialLoginEnable, setPluginEnable, setSupportEnable, setBillingEnable, setSetupCount, setGeneralSettingEnable,setAppsDownload,setSubscription,setUpdatedAt,setUserInfo,setDashboardData) => {
    //Loader(state);
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    axios.get(Url + "get-agency-dashboard-data/" + agencyID ,{
      headers: headers
        }).then(res => {
            if(res.data.code === 200)
            { 
                let screencount = 0;               
                setSpinner(false);
                
	            //const agencyimgpath = process.env.REACT_APP_Image_Path + localuserid + '/' + process.env.REACT_APP_agency_Profile;
                //let logoimg = "ams_resize_1676461861279_6735.png";
                let logoimg = res.data.data.agency_logo;

                if(logoimg !== '' && logoimg !== null && logoimg !== undefined)
                {
                    localStorage.setItem("agencylogo", logoimg);
                }
                //localStorage.setItem("orgname", res.data.data.agency_name);

                localStorage.setItem("agency_id", atob(agencyID));
                
                setLogoImage(logoimg);
                setOrgName(res.data.data.agency_name);

                setSubDomain(res.data.data.agency_domain);                
                setOrgDate(res.data.data.agency_created_date);
                setPlanName("");
                setSubscription(res.data.data.subscription);
                setFreeSubscriberCount(res.data.data.subscribers.free_subscribers);
                setPaidSubscriberCount(res.data.data.subscribers.paid_subscribers);
                setAndroidAppsCount(res.data.data.apps_created.android_apps);
                setAppsDownload(res.data.data.appsdownload);
                setIosAppsCount(res.data.data.apps_created.ios_apps);
                setOwnerData(res.data.data.owner_data);
                setUsersData(res.data.data.management_users);
                setDomainVerified(res.data.data.is_domain_verified);
                setEmailDataEnable(res.data.data.emailshow);
                setAppsCount(res.data.data.appscount);
                setCustomerCount(res.data.data.customercount);
                setUpdatedAt(res.data.data.general_setting_datetime);
                setUserInfo(res.data.data.owner_data);
                setDashboardData(res.data.data);
                localStorage.setItem('agencyuserroleid',res.data.data.agency_user_role_id);

                if(res.data.data.social_login_status.is_google_login_enabled == 1)
                {
                    setSocialLoginEnable(true);
                    screencount = screencount + 1;
                    setSetupCount(screencount);

                }
                else if(res.data.data.social_login_status.is_fb_login_enabled == 1)
                {
                    setSocialLoginEnable(true);

                    screencount = screencount + 1;
                    setSetupCount(screencount);
                }
                else if(res.data.data.social_login_status.is_google_captcha_enabled == 1)
                {
                    setSocialLoginEnable(true);

                    screencount = screencount + 1;
                    setSetupCount(screencount);
                }
                else
                {
                    setSocialLoginEnable(false);
                }
                
                setPluginEnable(res.data.data.is_plugin_data_available);
                setSupportEnable(res.data.data.is_support_data_available);
                setBillingEnable(false);

                setGeneralSettingEnable(res.data.data.is_general_setting);

                

                if(res.data.data.is_domain_verified === true)
                {
                    screencount = screencount + 1;
                    setSetupCount(screencount);
                }

                if(res.data.data.emailshow === true)
                {
                    screencount = screencount + 1;
                    setSetupCount(screencount);
                }

                if(res.data.data.is_general_setting === true)
                {
                    screencount = screencount + 1;
                    setSetupCount(screencount);
                }

                /*if(socialloginenable === true)
                {
                    screencount = screencount + 1;
                    setSetupCount(screencount);
                }*/

                if(res.data.data.is_plugin_data_available === true)
                {
                    screencount = screencount + 1;
                    setSetupCount(screencount);
                }

                if(res.data.data.is_support_data_available === true)
                {
                    screencount = screencount + 1;
                    setSetupCount(screencount);
                }

                if(( res.data.data.management_users !== '' && res.data.data.management_users !== null && res.data.data.management_users !== undefined && res.data.data.management_users !== 'null' ) && res.data.data.management_users.length > 0 )
                {
                    screencount = screencount + 1;
                    setSetupCount(screencount);
                }

                
            }
       })
       .catch((err) => {
            errortoast("Oops! Something went wrong.");
        });
}

/********User Logout**********/
export const userLogout = (params) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.post(Url + "user/logout", params, {
      headers: headers
    }).then(res => {
      
        if(res.data.code === 200)
        {

            let myItem = localStorage.getItem("buildversion");
            let myItemversion = localStorage.getItem("Apiversion");
            localStorage.clear();
            localStorage.setItem("buildversion", myItem);
            localStorage.setItem("Apiversion", myItemversion);
            //window.location.pathname = "/signin";
            
        }
    })
}


/********Get Agency Data At Login Time**********/
export const getAgencyData = (params, state) => {
    axios
	.post(Url + "agency-login-data", params)
	.then((res) => {
		if (res.data.code === 200) 
		{
            if(window.location.host == 'localhost:3000' || window.location.host == 'dev.app.appmysite.com' || window.location.host == 'test.app.appmysite.com' || window.location.host == 'stage.app.appmysite.com' || window.location.host == 'app.appmysite.com')
            {
                state.setState({agency_id: 1,spinner: false});
                localStorage.setItem("agencyid", 1);
                localStorage.setItem("agency_id", 1);
            } 
            else
            {
                if(res.data.data.agency_name == 'undefined' || res.data.data.agency_name == undefined)
                {
                    window.location.href="/contact-admin";
                }

                if(res.data.data.agency_subscription !=="paddle_preview" && res.data.data.agency_subscription !=="agency_preview"
                    && res.data.data.agency_subscription !== process.env.REACT_APP_Lifetime_preview
                    && res.data.data.agency_subscription !== process.env.REACT_APP_zoho_preview
                )
                {

                        state.setState({
                                agency_favicon:res.data.data.agency_favicon,
                                agency_logo: res.data.data.agency_logo,
                                agency_name: res.data.data.agency_name,
                                agency_id: res.data.data.agency_id,
                                agency_logo: process.env.REACT_APP_Image_Path + "agencies/" + res.data.data.agency_id + process.env.REACT_APP_Agency_Profile + res.data.data.agency_logo,
                                is_google_login_enabled: res.data.data.is_google_login_enabled,
                                is_fb_login_enabled: res.data.data.is_fb_login_enabled,
                                is_google_captcha_enabled: res.data.data.is_google_captcha_enabled,
                                google_app_id:res.data.data.google_app_id,
                                captcha_key:res.data.data.captcha_key,
                                captcha_secret:res.data.data.captcha_secret,
                                facebookid: res.data.data.fb_app_id,
                                agency_primary_color: res.data.data.agency_primary_color,
                                agency_secondary_color: res.data.data.agency_secondary_color,
                                button_background_color: res.data.data.button_background_color,
                                button_text_color: res.data.data.button_text_color,
                                button_hover_txtcolor: res.data.data.button_hover_txtcolor,
                                button_hover_bgcolor: res.data.data.button_hover_bgcolor,
                        });
                        
                        localStorage.setItem("agencyid", res.data.data.agency_id);
                        localStorage.setItem("agency_id", res.data.data.agency_id);
                        localStorage.setItem("agencyname", res.data.data.agency_name);
                        localStorage.setItem("agencylogo", res.data.data.agency_logo);
                        localStorage.setItem("agency_primary_color", res.data.data.agency_primary_color);
                        localStorage.setItem("agency_secondary_color", res.data.data.agency_secondary_color);
                        localStorage.setItem("button_background_color", res.data.data.button_background_color);
                        localStorage.setItem("button_text_color", res.data.data.button_text_color);
                        localStorage.setItem("button_hover_txtcolor", res.data.data.button_hover_txtcolor);
                        localStorage.setItem("button_hover_bgcolor", res.data.data.button_hover_bgcolor);
                        localStorage.setItem("community_url", res.data.data.community_url);
                        localStorage.setItem("knowledge_url", res.data.data.knowledge_url);
                        localStorage.setItem("is_customer_support_enable", res.data.data.is_customer_support_enable);
                        localStorage.setItem("phone", res.data.data.phone);
                        localStorage.setItem("phoneno",res.data.data.phone_country_code);
                        localStorage.setItem("support_email", res.data.data.support_email);
                        localStorage.setItem("product_url", res.data.data.product_url);
                        localStorage.setItem("agencyfavicon", process.env.REACT_APP_Image_Path + "agencies/" + res.data.data.agency_id + process.env.REACT_APP_Agency_Profile + res.data.data.agency_favicon);

                        setTimeout(() => {
                            state.setState({spinner:false}); 
                        }, 2000);
                }
                else
                {
                    window.location.href="/contact-admin";
                }
            }
		}
        else
        {
            if(window.location.host == 'localhost:3000' || window.location.host == 'dev.app.appmysite.com' || window.location.host == 'test.app.appmysite.com' || window.location.host == 'stage.app.appmysite.com' || window.location.host == 'app.appmysite.com')
            {
                state.setState({agency_id: 1,spinner: false});
                localStorage.setItem("agency_id", 1);
                localStorage.setItem("agencyid", 1);
            }
            else
            {
                window.location.href="/contact-admin";
            } 
           
        } 		
	})
	.catch((error) => {
	});
};

/********Get Agency Data At Login Time**********/
export const getheaderAgencyData = (params) => 
{
    
	axios
	.post(Url + "agency-login-data", params)
	.then((res) =>
     {
            if (res.data.code === 200) 
            {

                if(window.location.host == 'localhost:3000' || window.location.host == 'dev.app.appmysite.com' || window.location.host == 'test.app.appmysite.com' || window.location.host == 'stage.app.appmysite.com' || window.location.host == 'app.appmysite.com')
                {
                    localStorage.setItem("agencyid", 1);
                    localStorage.setItem("agency_id", 1);
                } 
                else
                {

                    if(res.data.data.agency_subscription !=="paddle_preview" && res.data.data.agency_subscription !=="agency_preview"
                        && res.data.data.agency_subscription !== process.env.REACT_APP_Lifetime_preview
                        && res.data.data.agency_subscription !== process.env.REACT_APP_zoho_preview
                    )
                    {
            
                        localStorage.setItem("agencyid", res.data.data.agency_id);
                        localStorage.setItem("agency_id", res.data.data.agency_id);
                        localStorage.setItem("agencyname", res.data.data.agency_name);
                        localStorage.setItem("agencylogo", res.data.data.agency_logo);
                        localStorage.setItem("agency_primary_color", res.data.data.agency_primary_color);
                        localStorage.setItem("agency_secondary_color", res.data.data.agency_secondary_color);
                        localStorage.setItem("button_background_color", res.data.data.button_background_color);
                        localStorage.setItem("button_text_color", res.data.data.button_text_color);
                        localStorage.setItem("button_hover_txtcolor", res.data.data.button_hover_txtcolor);
                        localStorage.setItem("button_hover_bgcolor", res.data.data.button_hover_bgcolor);
                        localStorage.setItem("community_url", res.data.data.community_url);
                        localStorage.setItem("knowledge_url", res.data.data.knowledge_url);
                        localStorage.setItem("is_customer_support_enable", res.data.data.is_customer_support_enable);
                        localStorage.setItem("phone", res.data.data.phone);
                        localStorage.setItem("phoneno",res.data.data.phone_country_code);
                        localStorage.setItem("support_email", res.data.data.support_email);
                        localStorage.setItem("product_url", res.data.data.product_url);
                        localStorage.setItem("agencyfavicon", process.env.REACT_APP_Image_Path + "agencies/" + res.data.data.agency_id + process.env.REACT_APP_Agency_Profile + res.data.data.agency_favicon);
                
                    }
                    else
                    {
                        let myItem = localStorage.getItem("buildversion");
                        let myItemversion = localStorage.getItem("Apiversion");
                        let previous_location = localStorage.getItem("Previouslocation");
                        localStorage.clear();
                        localStorage.setItem("Previouslocation", previous_location);
                        localStorage.setItem("buildversion", myItem);
                        localStorage.setItem("Apiversion", myItemversion);
                    }
                }
            }
        		
	})
	.catch((error) => {
	});
};


/********Verify Email Data**********/
export const verifyEmailConfiguration = (params, setVerifyLabel, setBtnVerifyLoader, setFromEmailStatus, setSMTPConnectionStatus, setIsAdd,setLastDate, isadd,setSmtpError) => {

	const headers = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("amstoken"),
      }
      
      axios.post(Url + "agency-from-email-verification",params, {
        headers: headers
      }).then(res => {

        setLastDate(res.data.data.updated_at);
        
        if(res.data.code === 200)
        {
            var setparam = 0;
            var setparam1 = 0;
            setFromEmailStatus(res.data.data.from_email_status == 1 ? true :false);
            setSMTPConnectionStatus(res.data.data.smtp_connection_status == 1 ? true :false);
            if(res.data.from_status == null || res.data.from_status == 300)
            {
                setparam = 1;

            }

            if(res.data.smtp_status == null || res.data.smtp_status == 300)
            {
                setparam1 = 1;
            }

            setSmtpError(res.data.error_details);

            setBtnVerifyLoader("d-none");
            setVerifyLabel("Verify");
            let verifyemailbtndiv = document.getElementById("btnverifyemailid");
		    verifyemailbtndiv.classList.remove("btnloader");

            if(res.data.from_status == null && res.data.smtp_status == null)
            {
                sucesstoast("The record has been verified.");
            }
            else if(setparam == 1 && setparam1 == 1)
            {
                sucesstoast("Verification email sent. Please check your inbox.");
            }
            else
            {
                errortoast("SMTP credentials invalid.");
            }
        }
        else
        {
            errortoast("Oops! Something went wrong.");
        }
       
    })
    .catch((err) => 
    {
        setBtnVerifyLoader("d-none");
        setVerifyLabel("Verify");
        let verifyemailbtndiv = document.getElementById("btnverifyemailid");
        verifyemailbtndiv.classList.remove("btnloader");
        errortoast("Oops! Something went wrong.");
    });
};

/********Get Phone Code List******/
export const getCountryList = (setPhoneCodeCountry) => {
    //Loader(state);
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    axios.get(Url + "user/country" ,{
      headers: headers
        }).then(res => {
            if(res.data.code === 200)
            {                
                //setSpinner(false);
                
	            setPhoneCodeCountry(res.data.data);
                
            }
       })
       .catch((err) => {
            errortoast("Oops! Something went wrong.");
        });
}


//Verify Organization Email Api
export const verifyEmailID = (params, setSpinner, setMessage, setCode, redirectToMainPage) => {
    if (params) 
  {
      const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("amstoken"),
      };

      axios
          .post(Url + "agency-from-email-activation", params, {
                headers: headers,
          })
      .then((res) => {
            if (res.data.code === 200) 
          {
              sucesstoast("The record has been verified.");

              redirectToMainPage("/agencies");

              setSpinner(false);
              setCode(res.data.code);
            } 
          else if (res.data.code === 403) 
          {
              setSpinner(false);
              setCode(res.data.code);
              setMessage(res.data.data);        		
            } 
          else if (res.data.code === 401) 
          {
              setSpinner(false);
              setCode(res.data.code);
              setMessage(res.data.data);
            } 
          else 
          {
              errortoast( "Oops! Something went wrong." );
            }
      });
    }
};

//Verify Organization Email Api
export const verifySMTPConfiguration = (params, setSpinner, setMessage, setCode, redirectToMainPage) => {
  if (params) 
{
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("amstoken"),
    };

    axios
        .post(Url + "agency-smtp-config-activation", params, {
              headers: headers,
        })
    .then((res) => {
          if (res.data.code === 200) 
        {
            sucesstoast("The record has been verified.");

            redirectToMainPage("/agencies");

            setSpinner(false);
            setCode(res.data.code);
          } 
        else if (res.data.code === 403) 
        {
            setSpinner(false);
            setCode(res.data.code);
            setMessage(res.data.data);        		
          } 
        else if (res.data.code === 401) 
        {
            setSpinner(false);
            setCode(res.data.code);
            setMessage(res.data.data);
          } 
        else 
        {
            errortoast( "Oops! Something went wrong." );
          }
    });
  }
};

//Web socket Api
export const verifySocketUser = (params) => {
    if (params) 
  {
      const headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("amstoken"),
      };
  
      axios
          .post(Url + "sockettest", params, {
                headers: headers,
          })
      .then((res) => {
            if (res.data.code === 200) 
          {
              //sucesstoast("Invitation Accepted.");
              
            } 
          else if (res.data.code === 400) 
          {
              //setSpinner(false);
                     		
            } 
          else if (res.data.code === 401) 
          {
              //setSpinner(false);
              
            } 
          else 
          {
              
            }
      });
    }
  };

/********Get All Agency Apps List******/
export const getAppsDetail = (agencyID, setSpinner, apps,setApps,setpageCount,setSpinner1,setAppsCount,setUseAppsCount) => {
    //Loader(state);
    
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    axios.get(Url + "agency-apps/" + agencyID ,{
      headers: headers
        }).then(res => {
            if(res.data.code === 200)
            {    
               
                setSpinner(false);
                setSpinner1(false);
                setApps(res.data.data.apps);
                setAppsCount(res.data.data.totalappscount);
                setUseAppsCount(res.data.data.upgradedappscount);
                setpageCount(Math.ceil(res.data.data.appscount/15));
                
            }
       })
       .catch((err) => {
            // errortoast("Something went wrong");
        });
}

/********Get All Agency Customers List******/
export const getCustomers = (agencyID, setSpinner, setCustomers,setDomain) => {
    //Loader(state);
    
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    axios.get(Url + "agency-customers/" + agencyID ,{
      headers: headers
        }).then(res => {
            if(res.data.code === 200)
            {  
                setDomain(res.data.agency.agency_domain);              
                setSpinner(false);
                setCustomers(res.data.data);
                
            }
       })
       .catch((err) => {
            // errortoast("Something went wrong");
        });
}

export const getsearchdata = (searchvalue,agencyID, setSpinner, setCustomers,setDomain,setsearcheable) => {
    //Loader(state);
    setSpinner(true);
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    axios.get(Url + "agency-customers/" + agencyID +"?searchquery="+searchvalue ,{
      headers: headers
        }).then(res => {
            if(res.data.code === 200)
            {  
                setDomain(res.data.agency.agency_domain);              
                setSpinner(false);
                setCustomers(res.data.data);
                setsearcheable(true);
                
            }
       })
       .catch((err) => {
            // errortoast("Something went wrong");
        });
}

export const getsearchappsdata = (searchvalue,agencyID, setSpinner, setApps,setsearcheable) => {
    //Loader(state);
    setSpinner(true);
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    axios.get(Url + "agency-apps/" + agencyID +"?searchquery="+searchvalue ,{
      headers: headers
        }).then(res => {
            if(res.data.code === 200)
            {                
                setSpinner(false);
                setApps(res.data.data.apps);
                setsearcheable(true);
                
            }
       })
       .catch((err) => {
            // errortoast("Something went wrong");
        });
}

export const getsearchsubsdata = (searchvalue,agencyID, setSpinner, setSubscriptionList,setplans,setsearcheable) => {
    //Loader(state);
    setSpinner(true);
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    axios.get(Url + "agency-subscription/" + agencyID +"?searchquery="+searchvalue ,{
      headers: headers
        }).then(res => {
            if(res.data.code === 200)
            {                
                setSpinner(false);
                setSubscriptionList(res.data.data.apps);
                setplans(res.data.data.plans);
                setsearcheable(true);
                
            }
       })
       .catch((err) => {
            // errortoast("Something went wrong");
            
        });
}

export const receivedCustomerData = (selectedPage,agencyID, setSpinner1, customers,setCustomers,setstoplength) => 
{
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("amstoken"),
      }

    axios.get(Url + "agency-customers/" + agencyID + '/'+selectedPage ,{
      headers: headers
        }).then(res => {

            if(res.data.code === 200)
            {    
                if(res.data.data.length  == 0 )
                {
                    setstoplength(1);
                }
                var data1 = customers.concat(res.data.data);   
                setSpinner1(false);
                setCustomers(data1);
            }
        })
        .catch((err) => {
            // errortoast("Something went wrong");
        });
}

export const receivedData = (selectedPage,agencyID, setSpinner1, dataapps,setApps,setpageCount,setstoplength) => 
{
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("amstoken"),
      }

    axios.get(Url + "agency-apps/" + agencyID + '/'+selectedPage ,{
      headers: headers
        }).then(res => {

            if(res.data.code === 200)
            {  
                if(res.data.data.apps.length  == 0 )
                {
                    setstoplength(1);
                }  
                var data1 = dataapps.concat(res.data.data.apps);            
                setSpinner1(false);
                setApps(data1);
                setpageCount(Math.ceil(res.data.data.appscount/15));
            }
        })
        .catch((err) => {
            // errortoast("Something went wrong");
        });
}

/********Get All Agency Subscription List******/
export const getSubscriptionList = (agencyID, setSpinner, setSubscriptionList,setplans,setAppLimit,setSubscriptionLimit,setPaidSubscriptionApps,setAgencyDeal,setPremiumAddonPurchased) => {
    //Loader(state);
    
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    axios.get(Url + "agency-subscription/" + agencyID ,{
      headers: headers
        }).then(res => {
            if(res.data.code === 200)
            {   
                setplans(res.data.data.plans);
                setSpinner(false);
                setSubscriptionList(res.data.data.apps);
                setAppLimit(res.data.data.apps_limit_count);
                setSubscriptionLimit(res.data.data.apps_limit_count);
                setPaidSubscriptionApps(res.data.data.paid_apps_count);
                setAgencyDeal(res.data.data.is_agency_deal);
                setPremiumAddonPurchased(res.data.data.is_premium_addon_purchased);
                
                
            }
       })
       .catch((err) => {
            // errortoast("Something went wrong");
        });
}

/********Get All Agency Subscription List******/
export const receivedSubscriptionData = (selectedPage,agencyID, setSpinner1, subscriptionlist,setSubscriptionList,setstoplength) => {
    //Loader(state);
    
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    axios.get(Url + "agency-subscription/" + agencyID+ '/'+selectedPage ,{
      headers: headers
        }).then(res => {
            if(res.data.code === 200)
            { 
                if(res.data.data.apps.length  == 0 )
                {
                    setstoplength(1);
                }  
                var data1 = subscriptionlist.concat(res.data.data.apps);            
                setSpinner1(false);
                setSubscriptionList(data1);
                
            }
       })
       .catch((err) => {
            // errortoast("Something went wrong");
        });
}

/********Get All Agency Subscription List******/
export const activatecustomer = (params,setMdlCustomerActive,setSpinner,callback) => 
{
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }

    axios.post(Url + "agency-customer-enable", params ,
        {
            headers: headers
        }).then(res => {
            if(res.data.code === 200)
            {   
                setMdlCustomerActive(false);
                sucesstoast('The record has been enabled.');
                callback();
            }
       })
       .catch((err) => {
        setSpinner(false);
        });
}

/********Get All Agency Subscription List******/
export const deactivatecustomer = (params,setMdlCustomerDeactive,setSpinner, callback) => 
{

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }

    axios.post(Url + "agency-customer-disable", params ,
        {
            headers: headers
        }).then(res => {
            if(res.data.code === 200)
            {   
                setMdlCustomerDeactive(false);
                sucesstoast('The record has been disabled.');
                callback();
                
            }
       })
       .catch((err) => {
        setSpinner(false);
            
        });
}

export const saveonlocalhost = (userinfo) =>
{
    localStorage.setItem("amstoken", userinfo.token.access_token);
    localStorage.setItem("customer_type", userinfo.userdata.customer_type);
    localStorage.setItem("user_profile", userinfo.userdata.profile_image);
    localStorage.setItem("workspaceId", "");
    localStorage.setItem("workspaceName", "");
    localStorage.setItem("workspaceImage", "");
    localStorage.setItem("loginasclient", 1);
    localStorage.setItem("logged_in_user_role_id", 1);
    localStorage.setItem("user_name", userinfo.userdata.name);
    localStorage.setItem("user_id", userinfo.userdata.id);
    localStorage.setItem("logged_in_user_id", userinfo.userdata.id);
    localStorage.setItem("originalid", userinfo.userdata.id);
    window.location.href = "/apps";
}

//Login Admin as a Client
export const allowAdminAsClient = (userid,agencyID,domain,setSpinner,setMdlCustomerLogin,setBtnCustomerLoginLoader) => 
{
    const param = 
    {
      user_id: userid,
      agency_id : agencyID,
    };
    const headers = 
    {
      "Content-Type": "application/json",
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    };

    axios
      .post(Url + `agency-login-as-client`, param, {
        headers: headers,
      })
      .then((res1) => 
      {
        if (res1.data.code === 200) 
        {
          var token = res1.data.token;
          var loginData = res1.data.userdata;
          const params = {
                token :token,
                userdata:loginData
          }

          
          setSpinner(false);
          setMdlCustomerLogin(false);
          setBtnCustomerLoginLoader('d-none');

          const dataparam = btoa(JSON.stringify(params));
          const url = `https://${domain}/agency/customers-as-admin?token=${encodeURIComponent(dataparam)}`;
          
          window.open(url, "_blank", 'noreferrer');
        } 
        else 
        {
          errortoast("Oops! Something went wrong.");
        }
      })
      .catch((err) => 
      {
        
        errortoast("Oops! Something went wrong.");
      });
  };

//Login Admin as a Client
export const changesubscriptionplan = (userid,appid,agencyID,techid,planid) => 
{
    const param = 
    {
      user_id: userid,
      app_id: appid,
      agency_id : parseInt(atob(agencyID)),
      technology : techid,
      plan_id:planid,
    };
    const headers = 
    {
      "Content-Type": "application/json",
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    };

    axios
      .post(Url + `agency-subscription-plan-change`, param, {
        headers: headers,
      })
      .then((res1) => 
      {
        if (res1.data.code === 200) 
        {
            window.location.reload();
        } 
        else 
        {
          errortoast("Oops! Something went wrong.");
        }
      })
      .catch((err) => 
      {
        
        errortoast("Oops! Something went wrong.");
      });
 };
 
 /******************Agency Team Invitation******************************/
export const AgencyTeamInvitationApi = (params, state) => {
    if (params) 
    {
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("amstoken"),
        };
    
        axios
            .post(Url + "agency-team-invitation-email", params, {
                  headers: headers,
            })
        .then((res) => {
            if (res.data.code === 200) 
            {
                sucesstoast("The record has been verified.");
                state.props.history.push("/agencies");
                state.setState({
                    spinner: false,
                    code: res.data.code,
                });
            } 
            else if (res.data.code === 403) 
            {
                state.setState({
                    spinner: false,
                    message: res.data.data,
                    code: res.data.code,
                  });       		
            } 
            else if (res.data.code === 401) 
            {
                state.setState({
                    spinner: false,
                    message: res.data.data,
                    code: res.data.code,
                  });
            } 
            else 
            {
                errortoast( "Oops! Something went wrong." );
            }
        })
        .catch((err) => {
            
        });
    }
};

export const agencyPricingApi = (
    params,
    setSpinner,
    setAgencyPlanCode,
    setAgencyPlanName,
    setCurrencySymbol,
    setapps,
    setusers,
    setuserprice,
    setappprice,
    setIsBtnDisable,
    setSelectedApiApps,
    setSelectedApiUsers,
    sliderStyle,
    setAgencySubscriptionPlan,
    setendCycleDate,
    setDowngradeAtTermEnd,
    setPaidSubscriptionApps,
    setPaidSubscriptionUsers,
    setYearlyData,
    setMonthlyData,
    setTabs,
    setLicenceData,
    setCurrentPackageStatus,
    setisDealPlan
) => {
   
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("amstoken"),
        };
    
        axios
            .get(Url + `agency-packages/${params.agency_id}`, {
                  headers: headers,
            })
        .then((res) => {
            if (res.data.code === 200) 
            {   
               if(res.data.data!==null &&res.data.data!==undefined ){
                setPaidSubscriptionApps(res.data.data.agency_paid_apps_count);
                setPaidSubscriptionUsers(res.data.data.agency_users);
                sliderStyle(res.data.data.no_of_apps,res.data.data.no_of_users);
                setAgencySubscriptionPlan(res.data.data.subscription_plan);
                setSelectedApiApps(res.data.data.no_of_apps);
                setSelectedApiUsers(res.data.data.no_of_users);
                setSpinner(false);
                setAgencyPlanCode(res.data.data.month[0].plan_code);
                setAgencyPlanName(res.data.data.month[0].plan_name);
                setCurrencySymbol(res.data.data.month[0].package_currency.currency_symbol);
                setapps(res.data.data.no_of_apps?res.data.data.no_of_apps:1);
                setusers(res.data.data.no_of_users?res.data.data.no_of_users:1);
                setappprice(parseInt(99)*parseInt(res.data.data.no_of_apps?res.data.data.no_of_apps:1));
                setuserprice(parseInt(49)*parseInt(res.data.data.no_of_users?res.data.data.no_of_users:1));
                setendCycleDate(res.data.data.billing_date);
                setDowngradeAtTermEnd(res.data.data.downgrade_at_term_end);
                setYearlyData(res.data.data.year);
                setMonthlyData(res.data.data.month);
                setTabs(res.data.data.package_duration === 1 ? 'monthly' 
                    : res.data.data.package_duration === 2 ? 'yearly'
                    : 'yearly');
                setLicenceData(res.data.data.licence_data);
                setCurrentPackageStatus(res.data.data.current_package_status);
                setisDealPlan(res.data.data.is_deal_plan);
            } 
            } 
        })
        .catch((err) => {
            
        });
    
};

export const ChangeTeamStatus = (params,setMdlCustomerDeinactive,setMdlCustomerActive,setSpinner,spinner,callback) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("amstoken"),
    };
    setMdlCustomerDeinactive(false);
    setMdlCustomerActive(false);
    
    
    axios
      .post(Url + "update-agency-users", params, 
      {
        headers: headers,
      })
      .then((res) => {
          setMdlCustomerDeinactive(false);
          setMdlCustomerActive(false);
        if (res.data.code === 200) 
        {
          setTimeout(() => {  
            setSpinner(false);
            }, 2000);
            callback();
        } 
        else if (res.data.code === 203) 
        {
          callback();  
          setTimeout(() => {
            setSpinner(false);      
          }, 2000);
        }
        else
        {
            setTimeout(() => {
                setSpinner(false);      
              }, 2000);
        }
        
      })
      .catch(
        (error) => {},
        );
  };

// Agency Subsription downgrade API
export const paddleAgencyChangePlan = (params,redirectFun,setIsLoader,setSubmitValConfirmPopUp,setOpenCancelSubscriptionPopUp) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("amstoken"),
    };
  
    axios
      .post(newUrlForPaddle + "update-subscription", params, {
        headers: headers,
      })
      .then((res) => {
        if (res.data.code === 200) {
     
             setSubmitValConfirmPopUp("Confirm");   
             setOpenCancelSubscriptionPopUp(false);
             setIsLoader("d-none");
            
          
           if(redirectFun){
            redirectFun();
          }
        } else {
         
                setIsLoader("d-none");
                setSubmitValConfirmPopUp("Confirm");   
             
          errortoast("something went wrong");
        
          }
      })
      .catch((error) => {
      
            setIsLoader("d-none");
            setSubmitValConfirmPopUp("Confirm");   
         
        
        errortoast("something went wrong");
     
        
       
      });
  };

//Get Licence type
export const getlicenceDeals = (Setlicence,Setspinner) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.get(Url + "list-deals" , {
      headers: headers
    }).then(res => {
      
        if(res.data.code === 200)
        {
            Setlicence(res.data.deals);
        }
        Setspinner(false)
     
    })
}

//Get Licence type
export const getDeals = (state,id) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.get(Url + "list-deals?agency_id="+id , {
      headers: headers
    }).then(res => {
      
        if(res.data.code === 200)
        {

            state.setState({
                deals:res.data.deals,
                spinner: false,

              });
        }
        else
        {
            state.setState({
                spinner: false,
              });
        }
     
    })
}

// Getalladdons get API
export const AddonListingApi = (
    params,
    from,
    agencyID,
    setSpinner,
    setAddonListingData,
    setDowngradeAtTermEnd,
    setAgencyPlanName,
    setOpenReactivateAddonModal,
    setLoaderRemove
) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("amstoken"),
    };
  
    const getPricing = async (code) => 
    {
  
      return new Promise((resolve) => 
      {
        
        var pricevalue = '';
         
        window.Paddle.Product.Prices(parseInt(code), (prices) =>
          pricevalue = prices.price.gross)
        setTimeout(() => {
          resolve(pricevalue)
        }, 2000);
         
        
      });
      
    };
  
    axios
      .get(
        UseUrl +
          "get_package_addons/" +
          (from === "workspace"
            ? btoa(null)
            : btoa(null)) +
          "/" +
          (from === "workspace"
            ? btoa(null)
            : btoa(null)) +
            "/" +
            (from === "agency"
                ? agencyID
                : btoa(null))
                +"/"+
          btoa(params.plan_code)+"/0",
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.data.code === 200) {
  
          var setpricevalue = [];
  
          res.data.data.addons.map(async(res1, i) =>  setpricevalue[res1.addon_code] = await (getPricing(res1.addon_code)));
  
          
          setAddonListingData(res.data.data.addons);
          setSpinner(false);
          setDowngradeAtTermEnd(res.data.data.subscription.downgrade_at_term_end);
          setAgencyPlanName(res.data.data.subscription === null? "preview": res.data.data.subscription.plan);
          setOpenReactivateAddonModal(false);
          setLoaderRemove("d-none")

        // setTimeout(() => {
  
        //   if(res.data.data.plan_data && res.data.data.plan_data.is_deal_plan == 1)
        //   {
        //     state.handlefilter(2, 0);
        //   }
        //   state.setState({
        //     spinner: false,
        //     paddleaddonprice:setpricevalue,
        //     addondata:
        //       params.plan_code === "preview" || params.plan_code === process.env.REACT_APP_Lifetime_preview
        //         ? res.data.data.addons
        //         : res.data.data.addons,
        //     selected_addon_code:
        //       res.data.data.subscription === null
        //         ? []
        //         : res.data.data.subscription.addon_codes,
        //     zoho_subscription_id:
        //       res.data.data.subscription === null
        //         ? ""
        //         : res.data.data.subscription.zoho_subscription_id,
        //     auto_addons:
        //       res.data.data.subscription === null
        //         ? []
        //         : res.data.data.subscription.auto_addons,
        //     app_id:
        //       res.data.data.subscription === null
        //         ? localStorage.getItem("Appid")
        //         : res.data.data.subscription.app_id,
        //     onetimeaddon: res.data.data.subscription.one_time_addons,
        //     addon_code: res.data.data.subscription.addon_codes,
        //     appuser_role_id: res.data.data.appuser_role_id,
        //     addon_info: res.data.data.addon_info,
        //     allowed_module: res.data.data.subscription.device_type,
        //     customer_billing_type: res.data.data.user_data.customer_billing_type,
        //     is_deal_plan : res.data.data.plan_data.is_deal_plan,
        //     plan:
        //       res.data.data.subscription === null
        //         ? "preview"
        //         : res.data.data.subscription.plan,
        //     onetime_addons_purchased_json:
        //       res.data.data.subscription === null ||
        //       res.data.data.subscription.onetime_addons_purchased_json === "" ||
        //       res.data.data.subscription.onetime_addons_purchased_json === null
        //         ? 0
        //         : JSON.parse(
        //             res.data.data.subscription.onetime_addons_purchased_json
        //           ),
        //     recurring_addons_purchased_json:
        //       res.data.data.subscription === null ||
        //       res.data.data.subscription.recurring_addons_purchased_json ===
        //         null ||
        //       res.data.data.subscription.recurring_addons_purchased_json === ""
        //         ? 0
        //         : JSON.parse(
        //             res.data.data.subscription.recurring_addons_purchased_json
        //           ),
        //     setprice : setpricevalue,
        //     addon_json_for_paddle:res.data.data.subscription.addon_json,
        //     downgrade_at_term_end:res.data.data.subscription.downgrade_at_term_end,
        //     addon_quantity:
        //     res.data.addon_quantity === undefined ? 0 : res.data.addon_quantity,
        //     add_user_quantity:
        //     res.data.data.user_data.customer_billing_type !== 2 &&
        //     (res.data.data.subscription === null ||
        //     res.data.data.subscription.recurring_addons_purchased_json === "" ||
        //     res.data.data.subscription.recurring_addons_purchased_json ===
        //       null ||
        //     res.data.data.subscription.recurring_addons_purchased_json ===
        //       "[]" ||
        //     res.data.data.subscription.recurring_addons_purchased_json[
        //       "add_user"
        //     ] === undefined
        //       ? 1
        //       : JSON.parse(
        //           res.data.data.subscription.recurring_addons_purchased_json
        //         )["add_user"].quantity === 0
        //       ? 1
        //       : JSON.parse(
        //           res.data.data.subscription.recurring_addons_purchased_json
        //         )["add_user"].quantity),
        //   new_add_user_quantity:
        //     res.data.data.user_data.customer_billing_type !== 2 ?
        //     (res.data.data.subscription === null ||
        //     res.data.data.subscription.recurring_addons_purchased_json === "" ||
        //     res.data.data.subscription.recurring_addons_purchased_json === null
        //       ? res.data.data.user_data.customer_billing_type===3 ?res.data.addon_quantity===0?1:res.data.addon_quantity  : 1
        //       :
              
              
        //       JSON.parse(
        //           res.data.data.subscription.recurring_addons_purchased_json
        //         )[state.state.addoncode] === undefined
        //       ? (
        //         res.data.addon_quantity===0?1:res.data.addon_quantity
        //       )
        //       : JSON.parse(
        //           res.data.data.subscription.recurring_addons_purchased_json
        //         )[state.state.addoncode].quantity === 0
        //       ? 1
        //       : JSON.parse(
        //           res.data.data.subscription.recurring_addons_purchased_json
        //         )[state.state.addoncode].quantity)
                
        //         : res.data.addon_quantity===0?1:res.data.addon_quantity ,
        //   });
        // }, 3000);
          
        }
      })
      .catch((error) => 
      {
        // errortoast("Something went wrong. Try again after sometime.");
      });
  };


  export const AgencyPaddleDowngradeApi = (params,setLoaderRemove,setSubmitvalConfirmpop,redirectFun) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("amstoken"),
    };
  
    axios
      .post(newUrlForPaddle + "cancel-addon-subscription", params, {
        headers: headers,
      })
      .then((res) => {
        if (res.data.code === 200) {
            setLoaderRemove("d-none");
            setSubmitvalConfirmpop("Confirm");
            redirectFun();
        } else {
          errortoast("Your Addon removal failed.");
          setLoaderRemove("d-none");
          setSubmitvalConfirmpop("Confirm");
        }
      })
      .catch((error) => {
        setLoaderRemove("d-none");
        setSubmitvalConfirmpop("Confirm");
        errortoast("Your Addon removal failed.");
      });
  };