import React, {useState, useEffect} from "react";
import "../Agency/assets/css/common.css";
import {useLocation} from 'react-router-dom';


const ErrorPage = (props) =>{
    const location = useLocation();
    const [error, seterror] = useState("");
    useEffect(()=>
    {

        document.title = "500";
        let searchstr = location.search;
        let urlParams = (new URLSearchParams(searchstr));
        
        seterror(urlParams.get('error'));
        
    }, []);


    return (
    <div className="org-module">

        <div className="agency-errorpage">
            <div>
                <h1>Oops, someone messed up.</h1>
                <h3>Hopefully, its not you. Start over maybe?</h3>
                <a href="/apps">My Apps</a>
                <img className="agency-error-webimg" src={require("../assets/images/dashboard/five-web.png") .default} alt="customers"/>
                <img className="agency-error-mobimg" src={require("../assets/images/dashboard/five-mob.png") .default} alt="customers"/>
                {error != '' ?
                    <h2 style={{marginTop : "30px"}}>
                        {error}
                    </h2>
                : <></>
                }
            </div>
            
        </div>
   

    </div>

    );
}

export default ErrorPage;