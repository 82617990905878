import React, { useState, useEffect, useReducer } from "react";
import Header from "../../../../Dashboard/Header";
import Leftpanel from "../../../../Dashboard/Leftpanel";
import "./ProductCreation.css";
import ModuleTitleTooltip from "../../../../Components/commonIcons/ModuleTitleTooltip";
import ProductCarousel from "../../../../Components/commonComponents/ProductCarousel";
import ProductAddModal from "./AddProductPopup.js"
import Accordion from 'react-bootstrap/Accordion';
import galleryimg from "../../../../assets/images/dashboard/add-to-gallery.png";
import backgroundImage from "../../../../assets/images/dashboard/fashion.png";
import AddProductPopup from "./AddProductPopup";



const ProductCreation = () => {

  const [userdelete, setUserDelete] = useState(false);
  const [mdluserdelete, setMdlUserDelete] = useState(false);
  const deleteAccount = (e) => {
    e.preventDefault();
    setUserDelete(!userdelete);
    setMdlUserDelete(!mdluserdelete);
  };


  const [productState, setProductState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      activeSettingTab: false,
      appRightPanelExpand:
        localStorage.getItem("appSideBarCollapse") === true ||
        localStorage.getItem("appSideBarCollapse") === "true"
          ? true
          : false,
    }
  );

  // const [userdelete, setUserDelete] = useState(false);
  //   const [mdluserdelete, setMdlUserDelete] = useState(false);
  //   const deleteAccount = (e) => {
  //     e.preventDefault();
  //     setUserDelete(!userdelete);
  //     setMdlUserDelete(!mdluserdelete);
  //   };

  const handleRightPanelExpand = (value) => {
    setProductState({ appRightPanelExpand: value });
  };

  const handleSettingTab = (value) => {

    setProductState({ activeSettingTab: !productState.activeSettingTab });
  };

  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: '#F8FAFE',
  };
  



  return (
    <>
      <Header customclass={"header-responsive-new"} />
      <AddProductPopup deleteAccount={deleteAccount} userdelete={userdelete}/>
      <section className="create-product">
        <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
          
            <div
              className={`right-panel-gap ${
                localStorage.getItem("appSideBarCollapse") &&
                localStorage.getItem("appSideBarCollapse") === "true"
                  ? " right-panel-gap-expand"
                  : ""
              }`}
            >
              <div className="create-product-head">
                <ModuleTitleTooltip title={"New product"}/>
                <div className="product-head-right">
                  <h5 className="product-timestamp">Updated 10 Apr 2023, 11:45</h5>
                  <h6 className="product-draft">Draft</h6>

                  <button
                    className={ 
                      productState.activeSettingTab 
                    ? " setting-bt-off post-list-setting"
                    : "setting-bt-on post-list-setting"
                    } style={{marginLeft: "0px"}}
                    onClick={(e) => handleSettingTab(e)}
                  >
                    {/* <div className="setting-first-shadow"></div>
                    <div className="setting-second-shadow"></div> */}
                    { productState.activeSettingTab ?
		
                  <svg xmlns="http://www.w3.org/2000/svg" width="23.5" height="23.499" viewBox="0 0 23.5 23.499">
                        <g id="Group_38368" data-name="Group 38368" transform="translate(-1598.25 -88.901)">
                          <path id="Path_99646" data-name="Path 99646" d="M11.513,22.5a10.829,10.829,0,0,0,1.266-.074.615.615,0,0,0,.542-.642,2.437,2.437,0,0,1,1.488-2.388,2.278,2.278,0,0,1,.887-.171,2.527,2.527,0,0,1,1.855.788.616.616,0,0,0,.837.067,10.968,10.968,0,0,0,1.739-1.76.615.615,0,0,0-.066-.829,2.432,2.432,0,0,1,1.592-4.231h.056a.73.73,0,0,0,.72-.542,11,11,0,0,0,0-2.5.592.592,0,0,0-.613-.54h-.022A2.543,2.543,0,0,1,19.4,8.19a2.437,2.437,0,0,1,.613-2.744.614.614,0,0,0,.066-.836A10.919,10.919,0,0,0,18.3,2.851a.606.606,0,0,0-.377-.131.615.615,0,0,0-.454.2,2.532,2.532,0,0,1-1.855.785,2.259,2.259,0,0,1-.868-.165A2.449,2.449,0,0,1,13.238,1.2a.614.614,0,0,0-.544-.63,11.023,11.023,0,0,0-2.474,0,.615.615,0,0,0-.539.643A2.443,2.443,0,0,1,8.195,3.6a2.318,2.318,0,0,1-.89.172,2.524,2.524,0,0,1-1.853-.788.615.615,0,0,0-.837-.069,10.968,10.968,0,0,0-1.739,1.76.615.615,0,0,0,.066.829A2.43,2.43,0,0,1,1.279,9.733a.718.718,0,0,0-.708.54,11.037,11.037,0,0,0,0,2.5.617.617,0,0,0,.6.542h.181A2.464,2.464,0,0,1,3.6,14.808a2.438,2.438,0,0,1-.615,2.743.614.614,0,0,0-.066.837A11,11,0,0,0,4.7,20.145a.616.616,0,0,0,.83-.069,2.535,2.535,0,0,1,1.853-.785,2.3,2.3,0,0,1,.87.165A2.443,2.443,0,0,1,9.76,21.8a.614.614,0,0,0,.545.628A11.109,11.109,0,0,0,11.513,22.5Z" transform="translate(1598.501 89.151)" fill="" stroke="" stroke-linejoin="round" stroke-width="1.5"/>
                          <path id="Path_99647" data-name="Path 99647" d="M9.818,5.846a4,4,0,1,1-4,4A4,4,0,0,1,9.818,5.846Z" transform="translate(1600.182 90.804)" fill="rgba(256,256,256,1)" stroke="" stroke-linejoin="round" stroke-width="1.5"/>
                        </g>
                      </svg>
                  :
                  <svg xmlns="http://www.w3.org/2000/svg" width="23.5" height="23.499" viewBox="0 0 23.5 23.499">
                        <g id="Group_38368" data-name="Group 38368" transform="translate(-1598.25 -88.901)">
                          <path id="Path_99646" data-name="Path 99646" d="M11.513,22.5a10.829,10.829,0,0,0,1.266-.074.615.615,0,0,0,.542-.642,2.437,2.437,0,0,1,1.488-2.388,2.278,2.278,0,0,1,.887-.171,2.527,2.527,0,0,1,1.855.788.616.616,0,0,0,.837.067,10.968,10.968,0,0,0,1.739-1.76.615.615,0,0,0-.066-.829,2.432,2.432,0,0,1,1.592-4.231h.056a.73.73,0,0,0,.72-.542,11,11,0,0,0,0-2.5.592.592,0,0,0-.613-.54h-.022A2.543,2.543,0,0,1,19.4,8.19a2.437,2.437,0,0,1,.613-2.744.614.614,0,0,0,.066-.836A10.919,10.919,0,0,0,18.3,2.851a.606.606,0,0,0-.377-.131.615.615,0,0,0-.454.2,2.532,2.532,0,0,1-1.855.785,2.259,2.259,0,0,1-.868-.165A2.449,2.449,0,0,1,13.238,1.2a.614.614,0,0,0-.544-.63,11.023,11.023,0,0,0-2.474,0,.615.615,0,0,0-.539.643A2.443,2.443,0,0,1,8.195,3.6a2.318,2.318,0,0,1-.89.172,2.524,2.524,0,0,1-1.853-.788.615.615,0,0,0-.837-.069,10.968,10.968,0,0,0-1.739,1.76.615.615,0,0,0,.066.829A2.43,2.43,0,0,1,1.279,9.733a.718.718,0,0,0-.708.54,11.037,11.037,0,0,0,0,2.5.617.617,0,0,0,.6.542h.181A2.464,2.464,0,0,1,3.6,14.808a2.438,2.438,0,0,1-.615,2.743.614.614,0,0,0-.066.837A11,11,0,0,0,4.7,20.145a.616.616,0,0,0,.83-.069,2.535,2.535,0,0,1,1.853-.785,2.3,2.3,0,0,1,.87.165A2.443,2.443,0,0,1,9.76,21.8a.614.614,0,0,0,.545.628A11.109,11.109,0,0,0,11.513,22.5Z" transform="translate(1598.501 89.151)" fill="" stroke="" stroke-linejoin="round" stroke-width="1.5"/>
                          <path id="Path_99647" data-name="Path 99647" d="M9.818,5.846a4,4,0,1,1-4,4A4,4,0,0,1,9.818,5.846Z" transform="translate(1600.182 90.804)" fill="rgba(256,256,256,1)" stroke="" stroke-linejoin="round" stroke-width="1.5"/>
                        </g>
                      </svg>
                  }
                  </button>

                  <button className="product-draft-before">Save draft</button>
                  <button className="product-publish-before" onClick={deleteAccount} >Publish</button>
                </div>
              </div>

              {!productState.activeSettingTab?

              <div className="product-create">
                  <div className="create-product-left">
                  <Accordion>

                    <Accordion.Item eventKey="1" className="product-config-main">
                      <Accordion.Header>
                      <div className="product-config-toggle">
                          <h5 className="product-config-section-heading">Details</h5>
                          <div className="product-config-toggle">
                          <img className="img-fluid thechatarrow" src={require("../../../../assets/images/dashboard/cms/accordian-open.png") .default}/>
                          </div>
                      </div>
                      </Accordion.Header>
                        <Accordion.Body>
                          <h4 className="product-field-title">Name</h4>
                          <input className="create-product-input" type="text" placeholder="Enter product name"/>
                          <p className="product-error"><img className="img-fluid product-error-img" src={require("../../../../assets/images/dashboard/error-icon.svg") .default}/>Required</p>

                          <h4 className="product-field-title product-top-20">Short description</h4>
                          <textarea className="create-product-textarea" type="text" placeholder="Sort description"/>
                          <p className="product-error"><img className="img-fluid product-error-img" src={require("../../../../assets/images/dashboard/error-icon.svg") .default}/>Required</p>

                          <h4 className="product-field-title product-top-20">Full description</h4>
                          <textarea className="create-product-textarea" type="text" placeholder="Sort description"/>
                          <p className="product-error"><img className="img-fluid product-error-img" src={require("../../../../assets/images/dashboard/error-icon.svg") .default}/>Required</p>

                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="2" className="product-config-main">
                      <Accordion.Header>
                      <div className="product-config-toggle">
                          <h5 className="product-config-section-heading">Images</h5>
                          <div className="product-config-toggle">
                          <img className="img-fluid thechatarrow" src={require("../../../../assets/images/dashboard/cms/accordian-open.png") .default}/>
                          </div>
                      </div>
                      </Accordion.Header>
                        <Accordion.Body>
                        <div className="product-create-images">
                          <div className="product-create-single product-create-upload" onClick={deleteAccount}>
                            <img className="img-fluid default-product-img" src={galleryimg} alt="add gallery" />
                          </div>

                            <div className="product-create-single" style={backgroundStyle}> </div>
                            <div className="product-create-single" style={backgroundStyle}> </div>
                            <div className="product-create-single" style={backgroundStyle}> </div>
                            <div className="product-create-single" style={backgroundStyle}> </div>
                            <div className="product-create-single" style={backgroundStyle}> </div>
                            <div className="product-create-single" style={backgroundStyle}> </div>
                            <div className="product-create-single" style={backgroundStyle}> </div>
                            <div className="product-create-single" style={backgroundStyle}> </div>
                            <div className="product-create-single" style={backgroundStyle}> </div>

                        </div>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="3" className="product-config-main">
                      <Accordion.Header>
                      <div className="product-config-toggle">
                          <h5 className="product-config-section-heading">Pricing</h5>
                          <div className="product-config-toggle">
                          <img className="img-fluid thechatarrow" src={require("../../../../assets/images/dashboard/cms/accordian-open.png") .default}/>
                          </div>
                      </div>
                      </Accordion.Header>
                        <Accordion.Body>
                          <h4 className="product-field-title">Price</h4>
                          <div style={{position: "relative"}}>
                            <h5 className="product-currency-preset">$</h5>
                            <input style={{paddingLeft: "30px"}} className="create-product-input" type="text"/>
                          </div>
                          <p className="product-error"><img className="img-fluid product-error-img" src={require("../../../../assets/images/dashboard/error-icon.svg") .default}/>Required</p>

                          <h4 className="product-field-title product-top-20">Sale Price</h4>
                          <div style={{position: "relative"}}>
                            <h5 className="product-currency-preset">$</h5>
                            <input style={{paddingLeft: "30px"}} className="create-product-input" type="text"/>
                          </div>
                          <p className="product-error"><img className="img-fluid product-error-img" src={require("../../../../assets/images/dashboard/error-icon.svg") .default}/>Required</p>

                        </Accordion.Body>
                      </Accordion.Item> 

                      <Accordion.Item eventKey="4" className="product-config-main">
                      <Accordion.Header>
                      <div className="product-config-toggle">
                          <h5 className="product-config-section-heading">Stock</h5>
                          <div className="product-config-toggle">
                          <div className="product-config-toggle">
                          <img className="img-fluid thechatarrow" src={require("../../../../assets/images/dashboard/cms/accordian-open.png") .default}/>
                          </div>
                          </div>
                      </div>
                      </Accordion.Header>
                        <Accordion.Body>
                          <h4 className="product-field-title">SKU</h4>
                          <input className="create-product-input" type="text"/>
                          <p className="product-error"><img className="img-fluid product-error-img" src={require("../../../../assets/images/dashboard/error-icon.svg") .default}/>Required</p>

                          <div className="create-product-toggle product-top-30">
                            <h6 className="create-product-toggle-name">Track inventory</h6>
                            <label className="switch" >
                              <input type="checkbox"/>
                              <div class="slider round"></div>
                            </label>
                          </div>

                          <h4 className="product-field-title product-top-30">Stock status</h4>
                          <input className="create-product-input" type="text"/>
                          <p className="product-error"><img className="img-fluid product-error-img" src={require("../../../../assets/images/dashboard/error-icon.svg") .default}/>Required</p>

                          <div className="create-product-toggle product-top-30">
                            <h6 className="create-product-toggle-name">Manage quantity</h6>
                            <label className="switch" >
                              <input type="checkbox"/>
                              <div class="slider round"></div>
                            </label>
                          </div>

                          <h4 className="product-field-title product-top-30">Quantity</h4>
                          <input className="create-product-input" type="text"/>
                          <p className="product-error"><img className="img-fluid product-error-img" src={require("../../../../assets/images/dashboard/error-icon.svg") .default}/>Required</p>

                          <div className="create-product-toggle product-top-30">
                            <h6 className="create-product-toggle-name">Sold individually</h6>
                            <label className="switch" >
                              <input type="checkbox"/>
                              <div class="slider round"></div>
                            </label>
                          </div>

                          <div className="create-product-toggle product-top-30">
                            <h6 className="create-product-toggle-name">Allow back orders</h6>
                            <label className="switch" >
                              <input type="checkbox"/>
                              <div class="slider round"></div>
                            </label>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item> 


                  </Accordion>
                  </div>

                  <div className="create-product-right">
                    <div className="product-preview-screen">

                      <div className="product-preview-header">
                        <div className="product-preview-header-left">
                        <img className="img-fluid" src={require("../../../../assets/images/dashboard/post-back.svg") .default}/>
                        </div>

                        <div className="product-preview-header-right">
                        <img className="img-fluid" src={require("../../../../assets/images/dashboard/share-icon.svg") .default}/>

                        <img style={{ marginLeft: "15px" }} className="img-fluid" src={require("../../../../assets/images/dashboard/heart-icon.svg") .default}/>

                        <img style={{ marginLeft: "15px" }} className="img-fluid" src={require("../../../../assets/images/dashboard/cart-icon.svg") .default}/>
                        </div>
                      </div>

                      <ProductCarousel/>

                      <div className="product-preview-summry">
                        <h3 className="product-preview-summry-name">Your product name</h3>
                        <h4 className="product-preview-rating"><img className="img-fluid product-preview-star"
                          src={require("../../../../assets/images/dashboard/star-icon.svg") .default } />4.5<span className="product-preview-reviewnum">(245 reviews)</span></h4>
                        <div className="product-price-preview-section">
                          <h5 className="product-price-preview">$19.20</h5>
                          <h6 className="product-price-preview-original">$24.00</h6>
                          <div className="product-price-preview-discount">
                            <p className="product-preview-discount-percentage">20%</p>
                          </div>
                        </div>  
                        <h6 className="preview-stock-status-green">In stock</h6>
                      </div>

                      <div className="product-preview-intro">
                          <h3 className="product-summry-short-description">Yellow light wash 5-pocket mid-rise cropped jeans, clean look with no fade, has a button and zip closure, waistband with belt loops.</h3>
                          <h4 className="product-preview-sku">SKU: 08000749670</h4>
                      </div>

                      <div className="product-preview-buy">
                        <button className="product-add-to-cart" style={{backgroundColor: "#000", borderRadius: "4px", color: "#fff", border: "1px solid #000"}}>Add to cart</button>
                      </div>

                      <div className="product-preview-detail">
                        <p className="product-preview-description">Yellow light wash 5-pocket mid-rise cropped jeans, clean look with no fade, has a button and zip closure, waistband with belt loops Blue light wash 5-pocket mid-rise cropped jeans, clean look with no fade, has a button and zip closure, waistband with</p>

                        <img style={{marginTop:"15px", marginBottom:"15px", borderRadius: "10px" }} className="img-fluid" src={require("../../../../assets/images/dashboard/dummy.jpg") .default}/>

                        <p className="product-preview-description">Yellow light wash 5-pocket mid-rise cropped jeans, clean look with no fade, has a button and zip closure, waistband with belt loops Blue light wash 5-pocket mid-rise cropped jeans, clean look with no fade, has a button and zip closure, waistband with</p>
                      </div>

                    </div>
                  </div>
              </div>
              :
              <div className="product-create-setting">
                <div className="product-create-setting-left">
                <div className="product-create-setting-left-header">
                  <h1 className="product-create-setting-left-header-heading">Settings</h1>
                  <p className="product-create-setting-left-header-para">Customize and organize your posts.</p>
                </div>

                <div className="product-create-setting-tags">

                  <h4 className="product-field-title product-top-30">Assign categories</h4>
                  <div className="create-product-chip-input" 
                 
                  >
                      
                      <div className="create-product-chipset">
                        <p className="create-product-chipset-name">Fashion</p>
                          <img className="img-fluid remove-product-chipset" src={require("../../../../assets/images/dashboard/remove-tag.png") .default } alt="Remove"/>
                      </div>

                      <div className="create-product-chipset">
                        <p className="create-product-chipset-name">Men</p>
                          <img className="img-fluid remove-product-chipset" src={require("../../../../assets/images/dashboard/remove-tag.png") .default } alt="Remove"/>
                      </div>

                      <div className="create-product-chipset">
                        <p className="create-product-chipset-name">trending</p>
                          <img className="img-fluid remove-product-chipset" src={require("../../../../assets/images/dashboard/remove-tag.png") .default } alt="Remove"/>
                      </div>

                      <div className="create-product-chipset">
                        <p className="create-product-chipset-name">clothing</p>
                          <img className="img-fluid remove-product-chipset" src={require("../../../../assets/images/dashboard/remove-tag.png") .default } alt="Remove"/>
                      </div>

                      <div className="create-product-chipset">
                        <p className="create-product-chipset-name">Material</p>
                          <img className="img-fluid remove-product-chipset" src={require("../../../../assets/images/dashboard/remove-tag.png") .default } alt="Remove"/>
                      </div>

                      <div className="create-product-chipset">
                        <p className="create-product-chipset-name">Color</p>
                          <img className="img-fluid remove-product-chipset" src={require("../../../../assets/images/dashboard/remove-tag.png") .default } alt="Remove"/>
                      </div>

                      <div className="create-product-chipset">
                        <p className="create-product-chipset-name">Weight</p>
                          <img className="img-fluid remove-product-chipset" src={require("../../../../assets/images/dashboard/remove-tag.png") .default } alt="Remove"/>
                      </div>

                    <img className="img-fluid chip-upload-sign" src={require("../../../../assets/images/dashboard/upload-sign.svg") .default } alt="upload"/>
                  </div>

                  <h4 className="product-field-title product-top-20">Assign tags</h4>
                  <div className="create-product-chip-input" 
                
                  >
                      
                      <div className="create-product-chipset">
                        <p className="create-product-chipset-name">Fashion</p>
                          <img className="img-fluid remove-product-chipset" src={require("../../../../assets/images/dashboard/remove-tag.png") .default } alt="Remove"/>
                      </div>

                      <div className="create-product-chipset">
                        <p className="create-product-chipset-name">Men</p>
                          <img className="img-fluid remove-product-chipset" src={require("../../../../assets/images/dashboard/remove-tag.png") .default } alt="Remove"/>
                      </div>

                      <div className="create-product-chipset">
                        <p className="create-product-chipset-name">trending</p>
                          <img className="img-fluid remove-product-chipset" src={require("../../../../assets/images/dashboard/remove-tag.png") .default } alt="Remove"/>
                      </div>

                      <div className="create-product-chipset">
                        <p className="create-product-chipset-name">clothing</p>
                          <img className="img-fluid remove-product-chipset" src={require("../../../../assets/images/dashboard/remove-tag.png") .default } alt="Remove"/>
                      </div>

                      <div className="create-product-chipset">
                        <p className="create-product-chipset-name">Material</p>
                          <img className="img-fluid remove-product-chipset" src={require("../../../../assets/images/dashboard/remove-tag.png") .default } alt="Remove"/>
                      </div>

                      <div className="create-product-chipset">
                        <p className="create-product-chipset-name">Color</p>
                          <img className="img-fluid remove-product-chipset" src={require("../../../../assets/images/dashboard/remove-tag.png") .default } alt="Remove"/>
                      </div>

                      <div className="create-product-chipset">
                        <p className="create-product-chipset-name">Weight</p>
                          <img className="img-fluid remove-product-chipset" src={require("../../../../assets/images/dashboard/remove-tag.png") .default } alt="Remove"/>
                      </div>

                    <img className="img-fluid chip-upload-sign" src={require("../../../../assets/images/dashboard/upload-sign.svg") .default } alt="upload"/>
                  </div>

                  <h4 className="product-field-title product-top-20">Assign attributes</h4>
                  <div className="create-product-chip-input" 
                  
                  >
                      
                      <div className="create-product-chipset">
                        <p className="create-product-chipset-name">Fashion</p>
                          <img className="img-fluid remove-product-chipset" src={require("../../../../assets/images/dashboard/remove-tag.png") .default } alt="Remove"/>
                      </div>

                      <div className="create-product-chipset">
                        <p className="create-product-chipset-name">Men</p>
                          <img className="img-fluid remove-product-chipset" src={require("../../../../assets/images/dashboard/remove-tag.png") .default } alt="Remove"/>
                      </div>

                      <div className="create-product-chipset">
                        <p className="create-product-chipset-name">trending</p>
                          <img className="img-fluid remove-product-chipset" src={require("../../../../assets/images/dashboard/remove-tag.png") .default } alt="Remove"/>
                      </div>

                      <div className="create-product-chipset">
                        <p className="create-product-chipset-name">clothing</p>
                          <img className="img-fluid remove-product-chipset" src={require("../../../../assets/images/dashboard/remove-tag.png") .default } alt="Remove"/>
                      </div>

                      <div className="create-product-chipset">
                        <p className="create-product-chipset-name">Material</p>
                          <img className="img-fluid remove-product-chipset" src={require("../../../../assets/images/dashboard/remove-tag.png") .default } alt="Remove"/>
                      </div>

                      <div className="create-product-chipset">
                        <p className="create-product-chipset-name">Color</p>
                          <img className="img-fluid remove-product-chipset" src={require("../../../../assets/images/dashboard/remove-tag.png") .default } alt="Remove"/>
                      </div>

                      <div className="create-product-chipset">
                        <p className="create-product-chipset-name">Weight</p>
                          <img className="img-fluid remove-product-chipset" src={require("../../../../assets/images/dashboard/remove-tag.png") .default } alt="Remove"/>
                      </div>

                    <img className="img-fluid chip-upload-sign" src={require("../../../../assets/images/dashboard/upload-sign.svg") .default } alt="upload"/>
                  </div>

                  <div class="create-product-toggle product-top-30">
                    <h6 class="create-product-toggle-name">Featured product</h6>
                    <label class="switch"><input type="checkbox"/>
                    <div class="slider round"></div>
                    </label>
                  </div>

                  </div>
                </div>

                <div className="product-create-setting-right">
                  <div className="product-create-setting-screen">
                    <div className="product-create-setting-header">
                      <img className="img-fluid" src={require("../../../../assets/images/dashboard/post-back.svg") .default}/>
                      <h3 className="pro-create-set-heading">Filter</h3>
                    </div>
                    <div className="product-create-setting-body">
                      <div className="product-create-setting-body-left">
                        <h1 className="product-create-setting-body-left-active">Size</h1>
                        <div className="product-create-setting-body-left-parent">
                          <p className="product-create-setting-body-left-parent-entry">Color</p>
                        </div>
                      </div>

                      <div className="product-create-setting-body-right">
                        <p className="product-create-setting-body-right-child">
                          <img className="img-fluid product-create-setting-body-right-child-svg" src={require("../../../../assets/images/dashboard/grey-tick.svg") .default}/>
                          Small
                        </p>
                        <p className="product-create-setting-body-right-child">
                          <img className="img-fluid product-create-setting-body-right-child-svg" src={require("../../../../assets/images/dashboard/grey-tick.svg") .default}/>
                          Medium
                        </p>
                        <p className="product-create-setting-body-right-child">
                          <img className="img-fluid product-create-setting-body-right-child-svg" src={require("../../../../assets/images/dashboard/grey-tick.svg") .default}/>
                          Large
                        </p>
                        <p className="product-create-setting-body-right-child">
                          <img className="img-fluid product-create-setting-body-right-child-svg" src={require("../../../../assets/images/dashboard/grey-tick.svg") .default}/>
                          Extra large
                        </p>
                      </div>
                    </div>

                    <div className="product-create-setting-body-bottom">
                      <button className="product-create-setting-body-clear">Clear</button>
                      <button className="product-create-setting-body-apply">Apply</button>
                    </div>
                  </div>
                </div>
              </div>
            }

            
              <div className="product-create-mob-btns">
                <button className="product-create-mob-draft-before">Save draft</button>
                <button className="product-create-mob-publish-before">Publish</button>
                <p className="product-create-mob-timestamp">Updated 10 April 2023, 11:45</p>
              </div>
            </div>

<ProductAddModal
mdluserdelete={mdluserdelete} 
deleteAccount={deleteAccount}
/>

        
      </section>
    </>
  );
};

export default ProductCreation;
