import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FormGroup, Label, Input } from "reactstrap";

const HeadingBlock = ({
  indx,
  cmsArray,
  setcmsData,
  isRowOpen,
  handleIsRowOpen,
  deleteRow,
  duplicateItem,
  eachBlock,
  provided,
}) => {
  const handleHeading = (e) => {
    let newArr = [...cmsArray];
    newArr[indx].value.heading.text = e.target.value;

    setcmsData(newArr);
  };
  const handleType = (e, type) => {
    let newArr = [...cmsArray];
    if (type === "bold") {
      newArr[indx].style.bold_text = e.target.checked;
    }
    if (type === "italic") {
      newArr[indx].style.italic_text = e.target.checked;
    }
    if (type === "underline") {
      newArr[indx].style.underline = e.target.checked;
    }

    setcmsData(newArr);
  };

  const handleAlign = (e) => {
    let newArr = [...cmsArray];

    newArr[indx].style.text_align = e.target.value;

    setcmsData(newArr);
  };
  const handleStatus = (e) =>{

    let cmsData = [...cmsArray];
    cmsData[indx].status = ( cmsData[indx].status===1 || cmsData[indx].status===undefined || cmsData[indx].status===null)
     ?0:1;
  
    // setbgColor(e.hex);
    setcmsData(cmsData);
  }

  return (
    <div
      className={`accordionItem ${cmsArray[indx].status===0?"cms-section-hidden":""}`}
      // draggable={!isRowOpen[indx]}
      // style={{ cursor: "move" }}
    >
      <h2
        class="accordionItemHeading allflex"
        draggableId={indx.toString()}
        {...provided.dragHandleProps}
      >
        Heading

        <span className="cms-section-visiblity" onClick={(e)=>handleStatus(e)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="25.284" height="20.465" viewBox="0 0 25.284 20.465">
        <g id="Group_39189" data-name="Group 39189" transform="translate(0.568 0.353)">
          <path id="Path_103079" data-name="Path 103079" d="M68.716,9.742a13.852,13.852,0,0,1-12.07,7.684A13.876,13.876,0,0,1,44.568,9.742,13.876,13.876,0,0,1,56.646,2.058,13.852,13.852,0,0,1,68.716,9.742Z" transform="translate(-44.568 0.138)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1"/>
          <ellipse id="Ellipse_8629" data-name="Ellipse 8629" cx="3.842" cy="3.842" rx="3.842" ry="3.842" transform="translate(8.233 6.038)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1"/>
          <line id="Line_430" data-name="Line 430" x2="19.758" y2="19.758" transform="translate(2.196 0.001)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1"/>
        </g>
      </svg>
    </span>

    
    <p className="cms-accordian-p"
          onClick={(e) => {
            handleIsRowOpen(e, indx);
          }}
        >
          <img
            src={
              isRowOpen[indx] === true
                ? require("../../assets/images/dashboard/cms/accordian-open.png")
                    .default
                : require("../../assets/images/dashboard/cms/accordian-icon.png")
                    .default
            }
          />
        </p>
      </h2>

      {isRowOpen[indx] === true && (
        <div class="accordionItemContent">
          <input
            type="text"
            placeholder="Enter your bold and clear heading here."
            value={cmsArray[indx].value.heading.text}
            onChange={(e) => handleHeading(e)}
          />

          <FormGroup tag="fieldset" className="select-gallery-ratio select-headings-ratio" style={{position: "absolute"}}>

            <FormGroup>
              <Label className="galleryimg-ratio" value="LLLL">
                <Input
                  type="radio"
                  name="radio3"
                  value={"left"}
                  onChange={(e) => handleAlign(e)}
                  checked={cmsArray[indx].style.text_align === "left"}
                />
                <div className="image">
                <span class="cms-heading-tooltip">
                <svg
                    width="16.004"
                    height="15.046"
                    viewBox="0 0 16.004 15.046"
                  >
                    <g
                      id="Group_26426"
                      data-name="Group 26426"
                      transform="translate(0.002 0.75)"
                    >
                      <path
                        id="Path_6061"
                        data-name="Path 6061"
                        d="M-14517.724-3751.961h16"
                        transform="translate(14517.726 3751.961)"
                        fill="none"
                        stroke=""
                        stroke-width="1.5"
                      />
                      <path
                        id="Path_9224"
                        data-name="Path 9224"
                        d="M-14517.729-3751.961h12"
                        transform="translate(14517.727 3756.233)"
                        fill="none"
                        stroke=""
                        stroke-width="1.5"
                      />
                      <path
                        id="Path_9225"
                        data-name="Path 9225"
                        d="M-14517.724-3751.961h16"
                        transform="translate(14517.726 3760.991)"
                        fill="none"
                        stroke=""
                        stroke-width="1.5"
                      />
                      <path
                        id="Path_9226"
                        data-name="Path 9226"
                        d="M-14517.729-3751.961h12"
                        transform="translate(14517.727 3765.507)"
                        fill="none"
                        stroke=""
                        stroke-width="1.5"
                      />
                    </g>
                  </svg>
                  <span class="cms-heading-tooltipsubheader">Left align</span>
                  </span>
                </div>
              </Label>
            </FormGroup>

            <FormGroup>
              <Label className="galleryimg-ratio" value="LLLLL">
                <Input
                  type="radio"
                  name="radio3"
                  value={"center"}
                  onChange={(e) => handleAlign(e)}
                  checked={cmsArray[indx].style.text_align === "center"}
                />
                <div className="image">
                <span class="cms-heading-tooltip">
                <svg width="16" height="15.046" viewBox="0 0 16 15.046">
                    <g
                      id="Group_26428"
                      data-name="Group 26428"
                      transform="translate(0.498 0.75)"
                    >
                      <path
                        id="Path_6061"
                        data-name="Path 6061"
                        d="M-14517.724-3751.961h16"
                        transform="translate(14517.226 3751.961)"
                        fill="none"
                        stroke=""
                        stroke-width="1.5"
                      />
                      <path
                        id="Path_9224"
                        data-name="Path 9224"
                        d="M-14517.729-3751.961h12"
                        transform="translate(14519.23 3756.233)"
                        fill="none"
                        stroke=""
                        stroke-width="1.5"
                      />
                      <path
                        id="Path_9225"
                        data-name="Path 9225"
                        d="M-14517.724-3751.961h16"
                        transform="translate(14517.226 3760.991)"
                        fill="none"
                        stroke=""
                        stroke-width="1.5"
                      />
                      <path
                        id="Path_9226"
                        data-name="Path 9226"
                        d="M-14517.729-3751.961h12"
                        transform="translate(14519.23 3765.507)"
                        fill="none"
                        stroke=""
                        stroke-width="1.5"
                      />
                    </g>
                  </svg>
                  <span class="cms-heading-tooltipsubheader">Center align</span>
                  </span>
                  
                </div>
              </Label>
            </FormGroup>

            <FormGroup>
              <Label className="galleryimg-ratio" value="LLLLL">
                <Input
                  type="radio"
                  name="radio3"
                  value={"right"}
                  onChange={(e) => handleAlign(e)}
                  checked={cmsArray[indx].style.text_align === "right"}
                />
                <div className="image">
                <span class="cms-heading-tooltip">
                <svg
                    width="16.004"
                    height="15.045"
                    viewBox="0 0 16.004 15.045"
                  >
                    <g
                      id="Group_26427"
                      data-name="Group 26427"
                      transform="translate(0 0.749)"
                    >
                      <path
                        id="Path_6061"
                        data-name="Path 6061"
                        d="M-14501.724-3751.961h-16"
                        transform="translate(14517.724 3751.962)"
                        fill="none"
                        stroke=""
                        stroke-width="1.5"
                      />
                      <path
                        id="Path_9224"
                        data-name="Path 9224"
                        d="M-14505.729-3751.961h-12"
                        transform="translate(14521.732 3756.233)"
                        fill="none"
                        stroke=""
                        stroke-width="1.5"
                      />
                      <path
                        id="Path_9225"
                        data-name="Path 9225"
                        d="M-14501.724-3751.961h-16"
                        transform="translate(14517.724 3760.992)"
                        fill="none"
                        stroke=""
                        stroke-width="1.5"
                      />
                      <path
                        id="Path_9226"
                        data-name="Path 9226"
                        d="M-14505.729-3751.961h-12"
                        transform="translate(14521.732 3765.507)"
                        fill="none"
                        stroke=""
                        stroke-width="1.5"
                      />
                    </g>
                  </svg>
                  <span class="cms-heading-tooltipsubheader">Right align</span>
                  </span>
                  
                </div>
              </Label>
            </FormGroup>

          </FormGroup>

          <div className="duplicate-delete">
            <div className="cms-dup-del-btn"   onClick={(e) => duplicateItem(e, eachBlock)}>
            <span class="cms-delete-tooltip">
            <img
                src={
                  require("../../assets/images/dashboard/cms/duplicate.png")
                    .default
                }
              />
               <span class="cms-delete-tooltipsubheader">Duplicate</span>
                  </span>
            </div>
            <div className="cms-dup-del-btn"  
              onClick={(e) => {
                deleteRow(e, indx);
              }}
            >
              <span class="cms-delete-tooltip">
            <img
                src={
                  require("../../assets/images/dashboard/cms/delete.png")
                    .default
                }
              />
               <span class="cms-delete-tooltipsubheader">Delete</span>
                  </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeadingBlock;
