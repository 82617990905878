import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { cmsGetPagesApi, cmsGetPostsApi } from "../../Api/CmsApi/Cmsapi";
import { Countrylist } from "../../Api/Dashboard/DashboardApi";
import Reactcolorpicker from "../Appearance/NewReactcolorpicker";
import { parentPage } from "./CommonPopUp";
import Select from "react-select";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
} from "reactstrap";
import { SingleSelectionPopUpForCustomApp } from "../Reusable/SingleSelectionPopUp";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { sucesstoast } from "../Toaster";
import Tooltip from "../../Components/commonIcons/Tooltip";

const ButtonBlock = ({
  indx,
  cmsArray,
  setcmsData,
  isRowOpen,
  handleIsRowOpen,
  deleteRow,
  duplicateItem,
  eachBlock,

  provided,
  setIapValue,
  iapValue,
  from,
}) => {
  const urlParams = useParams();

  const [spinner2, setSpinner2] = useState(true);

  const [pagesData, setPagesData] = useState([]);
  const [postData, setPostData] = useState([]);

  const [phonecodemodal, setPhonecodemodal] = useState(false);

  const [phonecodesearch, setPhonecodesearch] = useState("");

  const [countryselectfield, setCountryselectfield] = useState([]);
  const [phoneCode, setPhoneCode] = useState("+1");
  const [phoneNo, setPhoneNo] = useState("");
  const [linkOptions, setLinkOptions] = useState([
    //  { value:"",label:"Select" ,isDisabled:true } ,
    { value: "url", label: "Web Page" },
    { value: "page", label: "Page" },
    { value: "post", label: "Post" },
    { value: "email", label: "Email" },
    { value: "phone", label: "Phone" },
  ]);

  const [openAppDropdown, setOpenAppDropdown] = useState(false);
  const [openMobileAppDropdown, setOpenMobileAppDropdown] = useState(false);
  const [countryNamePrefix, setcountryNamePrefix] = useState(
    cmsArray[indx].value.button.target_alias
      ? cmsArray[indx].value.button.target_alias
      : "US"
  );
  const [phone, setPhone] = useState("");
  const [openMobileLinkDropdown, setOpenMobileLinkDropdown] = useState(false);
  const [openMobilePageDropdown, setOpenMobilePageDropdown] = useState(false);
  const [openPageDropdown, setOpenPageDropdown] = useState(false);
  const [openMobilePostDropdown, setOpenMobilePostDropdown] = useState(false);
  const [openPostDropdown, setOpenPostDropdown] = useState(false);
  const [searchPageVal, setSearchPageVal] = useState("");
  const [searchPostVal, setSearchPostVal] = useState("");

  // Link Pop Up for Post
  const [openPostPopUp, setOpenPostPopUp] = useState(false);
  const [tempSubData, setTempSubData] = useState([]);
  const [subData, setSubData] = useState([]);
  const [breadcrumbArr, setBreadcrumbArr] = useState([]);
  const [oldSubData, setOldSubData] = useState([]);
  const [oldBreadcrumbArr, setOldBreadcrumbArr] = useState([]);
  const [searchValForPopUp, setSearchValForPopUp] = useState("");
  const [tempSelectedID, setTempSelectedID] = useState(
    cmsArray[indx].value.button.target
  );
  const [tempSelectedName, setTempSelectedName] = useState(
    cmsArray[indx].value.button.target_alias
  );
  const [selectedID, setSelectedID] = useState(
    cmsArray[indx].value.button.target
  );
  const [selectedName, setSelectedName] = useState(
    cmsArray[indx].value.button.target_alias
  );
  const [pageForPost, setPageForPost] = useState(1);
  const [pageForPage, setPageForPage] = useState(1);
  const [paginationSpinnerForPost, setPaginationSpinnerForPost] =
    useState(false);
  const [paginationSpinnerForPage, setPaginationSpinnerForPage] =
    useState(false);
  const [hitNextPaginationForPost, setHitNextPaginationForPost] =
    useState(false);
  const [hitNextPaginationForPage, setHitNextPaginationForPage] =
    useState(false);

  useEffect(() => {
    let string = cmsArray[indx].value.button.target.split("-");
    setPhoneCode(string[0]);
    setPhoneNo(string[1]);
    let limit = 99;
    let pageNo = 1;

    Countrylist("", setCountryselectfield);
    cmsGetPostsApi(
      setPostData,
      btoa(localStorage.getItem("Appid")),
      setSpinner2,
      null,
      limit,
      pageNo,
      postData,
      setTempSubData,
      setHitNextPaginationForPost,
      setPaginationSpinnerForPost
    );

    cmsGetPagesApi(
      setPagesData,
      btoa(localStorage.getItem("Appid")),
      setSpinner2,
      "",
      limit,
      pageNo,
      pagesData,
      setHitNextPaginationForPage,
      setPaginationSpinnerForPage
    );
    if (from == "Post") {
      setLinkOptions(
    [    { value: "url", label: "Web Page" },
        { value: "page", label: "Page" },
        { value: "post", label: "Post" },
        { value: "email", label: "Email" },
        { value: "phone", label: "Phone" },
        // { value: "iap", label: "In-app purchase" }
      ]
      );
    } else {
      setLinkOptions(
     [   { value: "url", label: "Web Page" },
        { value: "page", label: "Page" },
        { value: "post", label: "Post" },
        { value: "email", label: "Email" },
        { value: "phone", label: "Phone" }]
      );
    }
  }, []);

  // useEffect(() => {
  //   let cmsData = [...cmsArray];

  //   if (cmsData[indx]?.value?.button.link === "iap" && from == "Post") {
  //     setIapValue(
  //       cmsData[indx].value.button.target
  //         ? cmsData[indx].value.button.target.toString()
  //         : ""
  //     );
  //   }
  // }, [iapValue]);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // match with the menu
      borderRadius: 3,
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#7782a1" : "#C8CEDB",
      backgroundColor: state.isDisabled ? "#ffffff" : "",
      height: 50,
      minHeight: 50,
      fontSize: 14,
      color: "#7782A1",
      paddingLeft: 10,
      boxShadow: state.isFocused ? "none" : "none",
      "&:hover": {
        boxShadow: state.isFocused ? "none" : "none",
      },
    }),
    valueContainer: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
      fontSize: 14,
      color: "#7782A1",
    }),
    menu: (base) => ({
      ...base,
      fontSize: "14px",
    }),
    input: (base) => ({
      ...base,
      margin: 0,
      padding: 0,
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#1B2952", // Custom colour
      marginRight: 20,
    }),
  };

  const handleButtonname = (e) => {
    let cmsData = [...cmsArray];
    cmsData[indx].value.button.text = e.target.value;

    setcmsData(cmsData);
  };

  const handleSelectopt = (e) => {
    let cmsData = [...cmsArray];

    if (e.value !== cmsData[indx].value.button.link) {
      if (e.value === "phone") {
        setPhoneCode("+1");
        setcountryNamePrefix("US");
      }
      // if (from == "Post") {
      //   if (e.value === "iap") {
      //     setIapValue("");
      //   } else {
      //     setIapValue(null);
      //   }
      // }

      cmsData[indx].value.button.link = e.value;
      cmsData[indx].value.button.target = e.value === "phone" ? "+1" : "";
      cmsData[indx].value.button.target_alias = "";
      setcmsData(cmsData);
      setOpenMobileLinkDropdown(false);
      setTempSelectedID("");
      setTempSelectedName("");
      setSelectedID("");
      setSelectedName("");
      setSubData(
        cmsArray[indx].value.button.link === "post" ? postData : pagesData
      );
      setBreadcrumbArr([]);
      setOldBreadcrumbArr([]);

      // setPage(1);
      // setHitNextPagination(true);
    }
  };

  const handleUrl = (e, key) => {
    let cmsData = [...cmsArray];
    if (cmsData[indx].value.button.link === "phone") {
      setPhoneNo(e.target.value.replace(/[^0-9]/g, ""));
    }
     else if (cmsData[indx].value.button.link === "iap") {
      setIapValue(e.target.value.toString());
    }
    cmsData[indx].value.button.target =
      cmsData[indx].value.button.link === "phone"
        ? phoneCode + "-" + e.target.value.replace(/[^0-9]/g, "")
        : cmsData[indx].value.button.link === "url"
        ? e.target.value.replace("https://", "")
        : cmsData[indx].value.button.link === "iap"
        ? e.target.value
        : e.target.value;
    cmsData[indx].value.button.target_alias =
      cmsData[indx].value.button.link === "phone"
        ? cmsData[indx].value.button.target_alias
        : "";

    setcmsData(cmsData);
  };

  const handlebgColor = (e, key) => {
    let cmsData = [...cmsArray];
    cmsData[key].style.button_color = e;

    // setbgColor(e.hex);
    setcmsData(cmsData);
  };
  const handletextColor = (e, key) => {
    let cmsData = [...cmsArray];
    cmsData[key].style.text_color = e;

    // settextColor(e.hex);
    setcmsData(cmsData);
  };

  //Saving parent Id for Post and Page both
  const handleTarget = (eachPage) => {
    let cmsData = [...cmsArray];
    cmsData[indx].value.button.target = eachPage._id; //pTitle
    cmsData[indx].value.button.target_alias = eachPage.title; //pTitle
    setcmsData(cmsData);

    setOpenMobilePageDropdown(false);
    setOpenMobilePostDropdown(false);
  };

  // New Country Selection

  const handleAppdropdown = () => {
    setOpenAppDropdown(!openAppDropdown);
  };

  const handleMobileAppDropdown = () => {
    setOpenMobileAppDropdown(!openMobileAppDropdown);
  };

  const handleCountryChange = (e, phoneCode, country_code) => {
    setcountryNamePrefix(country_code);
    setOpenMobileAppDropdown(!openMobileAppDropdown);

    let newArr = [...cmsArray];
    setPhoneCode("+" + phoneCode);

    const myArray = newArr[indx].value.button.target.split("-");
    let word = myArray[1];
    newArr[indx].value.button.target = "+" + phoneCode + "-" + word;
    newArr[indx].value.button.target_alias = country_code;
    setcmsData(newArr);
    setPhonecodemodal(false);
  };

  // const handlePhoneChange = (e) => {
  //   setPhone(e.target.value.replace(/[^0-9]/g, ""));

  // };

  const handleMobileLinkDropdown = () => {
    setOpenMobileLinkDropdown(!openMobileLinkDropdown);
  };
  const handlePageDropdown = () => {
    setOpenPageDropdown(!openPageDropdown);
  };
  const handleMobilePageDropdown = () => {
    setOpenMobilePageDropdown(!openMobilePageDropdown);
  };
  const handlePostDropdown = () => {
    setOpenPostDropdown(!openPostDropdown);
  };
  const handleMobilePostDropdown = () => {
    setOpenMobilePostDropdown(!openMobilePostDropdown);
  };
  const handleDash = (level) => {
    switch (level) {
      case 0:
        return <span></span>;
      case 1:
        return <span> - </span>;
      case 2:
        return <span> - - </span>;
      case 3:
        return <span> - - - </span>;
      case 4:
        return <span> - - - - </span>;
      case 5:
        return <span> - - - - - </span>;
      case 6:
        return <span> - - - - - - </span>;
      case 1:
        return <span> - - - - - - - </span>;
      case 7:
        return <span> - - - - - - - - </span>;
      case 8:
        return <span> - - - - - - - - - </span>;
      case 9:
        return <span> - - - - - - - - - - </span>;
    }
  };

  const childParentList = (newArr) => {
    return newArr
      .filter((row1) =>
        row1.title.toLowerCase().includes(searchPageVal.toLowerCase())
      )
      .map((eachPage, indx) => {
        return (
          (eachPage.level < 10 || eachPage.level === undefined) &&
          eachPage.deleted_at === null && (
            <DropdownItem onClick={(e) => handleTarget(eachPage)}>
              {handleDash(eachPage.level)}
              {eachPage.title}
            </DropdownItem>
          )
        );
      });
  };

  let newArr = [];

  function recurs(arr, check) {
    return arr.map((eachPage, indx) => {
      newArr.push(eachPage);

      if (eachPage.child !== undefined && eachPage.child.length > 0) {
        // if parent has childrens
        recurs(eachPage.child, "child");
      }

      return (
        check === "parent" &&
        indx === pagesData.length - 1 &&
        childParentList(newArr)
      );
    });
  }

  const childParentListForMobile = (newArr1) => {
    return newArr1
      .filter((row1) =>
        row1.title.toLowerCase().includes(searchPageVal.toLowerCase())
      )
      .map((eachPage, indx) => {
        return (
          (eachPage.level < 10 || eachPage.level === undefined) &&
          eachPage.deleted_at === null && (
            <p
              style={{
                fontSize: "16px",
                color: "#1B2952",
                fontFamily: "Axiforma-Regular",
                margin: "0px 20px 40px 20px",
                display: "block",
              }}
              onClick={(e) => handleTarget(eachPage)}
            >
              {handleDash(eachPage.level)}
              {eachPage.title}
            </p>
          )
        );
      });
  };

  let newArrForMobile = [];

  function recursForMobile(arr, check) {
    return arr.map((eachPage, indx) => {
      newArrForMobile.push(eachPage);

      if (eachPage.child !== undefined && eachPage.child.length > 0) {
        // if parent has childrens
        recursForMobile(eachPage.child, "child");
      }

      return (
        check === "parent" &&
        indx === pagesData.length - 1 &&
        childParentListForMobile(newArrForMobile)
      );
    });
  }

  const handleStatus = (e) => {
    let cmsData = [...cmsArray];
    cmsData[indx].status =
      cmsData[indx].status === 1 ||
      cmsData[indx].status === undefined ||
      cmsData[indx].status === null
        ? 0
        : 1;

    // setbgColor(e.hex);
    setcmsData(cmsData);
  };

  const handlePostPopUp = (e, from) => {
    let cmsData = [...cmsArray];
    setOpenPostPopUp(!openPostPopUp);
    if (from === "cancel") {
      cmsData[indx].value.button.target = selectedID; //pTitle
      cmsData[indx].value.button.target_alias = selectedName; //pTitle
      setSubData(oldSubData);
      setBreadcrumbArr(oldBreadcrumbArr);
      setTempSelectedID(selectedID);
      setTempSelectedName(selectedName);
    } else {
      if (from === "open") {
        if (!subData || subData.length === 0) {
          setSubData(
            cmsArray[indx].value.button.link === "post" ? postData : pagesData
          );
        }

        cmsData[indx].value.button.target = tempSelectedID; //pTitle
        cmsData[indx].value.button.target_alias = tempSelectedName; //pTitle

        setSelectedID(tempSelectedID);
        setSelectedName(tempSelectedName);
        setOldSubData(subData);
        setOldBreadcrumbArr(breadcrumbArr);
      }
    }

    setcmsData(cmsData);
  };

  const handleParentChange = (e, pID, name) => {
    setTempSelectedID(pID);
    setTempSelectedName(name);
  };

  const handleBottomScrollListner = () => {
    if (cmsArray[indx].value.button.link === "page") {
      if (paginationSpinnerForPage === false) {
        if (hitNextPaginationForPage) {
          let limit = 99;
          let pageNo = pageForPage + 1;
          setPageForPage(pageNo);
          setPaginationSpinnerForPage(true);

          cmsGetPagesApi(
            setPagesData,
            btoa(localStorage.getItem("Appid")),
            setSpinner2,
            (data, mergeData) => {
              setSubData(mergeData);
            },
            limit,
            pageNo,
            pagesData,
            setHitNextPaginationForPage,
            setPaginationSpinnerForPage
          );
        }
      }
    } else {
      if (paginationSpinnerForPost === false) {
        if (hitNextPaginationForPost) {
          let limit = 99;
          let pageNo = pageForPost + 1;
          setPageForPost(pageNo);
          setPaginationSpinnerForPost(true);
          cmsGetPostsApi(
            setPostData,
            btoa(localStorage.getItem("Appid")),
            setSpinner2,
            null,
            limit,
            pageNo,
            postData,
            setSubData,
            setHitNextPaginationForPost,
            setPaginationSpinnerForPost
          );
        }
      }
    }
  };

  return (
    <div
      className={`accordionItem ${
        cmsArray[indx].status === 0 ? "cms-section-hidden" : ""
      }`}
    >
      <h2
        draggableId={indx.toString()}
        {...provided.dragHandleProps}
        className="accordionItemHeading allflex"
      >
        Button
        <span
          className="cms-section-visiblity"
          onClick={(e) => handleStatus(e)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25.284"
            height="20.465"
            viewBox="0 0 25.284 20.465"
          >
            <g
              id="Group_39189"
              data-name="Group 39189"
              transform="translate(0.568 0.353)"
            >
              <path
                id="Path_103079"
                data-name="Path 103079"
                d="M68.716,9.742a13.852,13.852,0,0,1-12.07,7.684A13.876,13.876,0,0,1,44.568,9.742,13.876,13.876,0,0,1,56.646,2.058,13.852,13.852,0,0,1,68.716,9.742Z"
                transform="translate(-44.568 0.138)"
                fill="none"
                stroke=""
                stroke-miterlimit="10"
                stroke-width="1"
              />
              <ellipse
                id="Ellipse_8629"
                data-name="Ellipse 8629"
                cx="3.842"
                cy="3.842"
                rx="3.842"
                ry="3.842"
                transform="translate(8.233 6.038)"
                fill="none"
                stroke=""
                stroke-miterlimit="10"
                stroke-width="1"
              />
              <line
                id="Line_430"
                data-name="Line 430"
                x2="19.758"
                y2="19.758"
                transform="translate(2.196 0.001)"
                fill="none"
                stroke=""
                stroke-miterlimit="10"
                stroke-width="1"
              />
            </g>
          </svg>
        </span>
        <p
          className="cms-accordian-p"
          onClick={(e) => {
            handleIsRowOpen(e, indx);
          }}
        >
          <img
            src={
              isRowOpen[indx] === true
                ? require("../../assets/images/dashboard/cms/accordian-open.png")
                    .default
                : require("../../assets/images/dashboard/cms/accordian-icon.png")
                    .default
            }
          />
        </p>
      </h2>
      {isRowOpen[indx] === true && (
        <div className="accordionItemContent pages-fields cms-button-block">
          <div className="row">
            <div className="col-md-12">
              <h6>Text</h6>
            </div>
          </div>
          <input
            type="text"
            placeholder="Enter button text here"
            value={cmsArray[indx].value.button.text}
            onChange={(e) => handleButtonname(e, indx)}
          />

          <div className="row">
            <div className="col-md-12 mt-20">
              <h6>Link</h6>
            </div>

            <div className="col-md-12">
              <div
                className="mobile-view-click"
                onClick={
                  window.matchMedia("(max-width: 1200px)").matches
                    ? handleMobileLinkDropdown
                    : null
                }
              >
                <Select
                  options={linkOptions}
                  classNamePrefix="email-support-dropdown"
                  onChange={(e) => handleSelectopt(e)}
                  required={true}
                  placeholder="Select"
                  styles={customStyles}
                  isClearable={false}
                  isSearchable={false}
                  value={linkOptions?.filter(
                    (option) =>
                      option.value === cmsArray[indx].value.button.link
                  )}
                  isDisabled={
                    window.matchMedia("(max-width: 1200px)").matches
                      ? true
                      : false
                  }
                />
              </div>
            </div>
            <div className="col-md-12 mt-20">
              {cmsArray[indx].value.button.link === "iap" && from=="Post" ? (
                <h6>
                  Product ID
                  <span style={{color:"#fff"}}>   <Tooltip message={" This product ID must be entered in the In-app purchase section of your App Store account. Upon clicking this button, the purchase prompt will appear. "} /></span>
                  {/* {
  cmsArray[indx].value.button.link === "url" ? "URL" :
  cmsArray[indx].value.button.link === "post" ? "Post" :
  cmsArray[indx].value.button.link === "page" ? "Page" :
  cmsArray[indx].value.button.link === "phone" ? "Phone" :
  cmsArray[indx].value.button.link === "email" ? "Email" :
  "Product ID"
} */}
                </h6>
              ) : (
                ""
              )}
            </div>

            <div className="col-md-12">
              {cmsArray[indx].value.button.link === "url" && (
                <div style={{ display: "flex" }}>
                  <div
                    className="https-link"
                    style={{ backgroundColor: "white" }}
                  >
                    <p>https://</p>
                  </div>
                  <input
                    type="text"
                    placeholder="www.example.com"
                    onChange={(e) => handleUrl(e, indx)}
                    value={cmsArray[indx].value.button.target.replace(
                      /^https?:\/\//i,
                      ""
                    )}
                  />
                </div>
              )}
              {(cmsArray[indx].value.button.link === "post" ||
                cmsArray[indx].value.button.link === "page") && (
                <div
                  className="select-popup-for-buttonblock"
                  onClick={(e) => handlePostPopUp(e, "open")}
                >
                  <span
                    style={{
                      color: cmsArray[indx].value.button.target_alias
                        ? ""
                        : "#BCC1CE",
                    }}
                    className="search-dropdown-before"
                    onClick={(e) => handlePostPopUp(e, "open")}
                  >
                    {cmsArray[indx].value.button.target_alias
                      ? cmsArray[indx].value.button.target_alias
                      : "Select"}
                  </span>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                  >
                    <g
                      id="Group_42666"
                      data-name="Group 42666"
                      transform="translate(-1005 -1029)"
                    >
                      <g
                        id="Rectangle_31915"
                        data-name="Rectangle 31915"
                        transform="translate(1005 1029)"
                        fill="#fff"
                        stroke="#bcc1ce"
                        stroke-width="1"
                      >
                        <rect width="20" height="19" rx="2" stroke="none" />
                        <rect
                          x="0.5"
                          y="0.5"
                          width="19"
                          height="18"
                          rx="1.5"
                          fill="none"
                        />
                      </g>
                      <path
                        id="Path_119031"
                        data-name="Path 119031"
                        d="M15578.713-3593.554h2"
                        transform="translate(-14569.713 4628.054)"
                        fill="none"
                        stroke="#bcc1ce"
                        stroke-width="1"
                      />
                      <path
                        id="Path_119032"
                        data-name="Path 119032"
                        d="M15578.713-3593.554h2"
                        transform="translate(-14569.713 4632.054)"
                        fill="none"
                        stroke="#bcc1ce"
                        stroke-width="1"
                      />
                      <path
                        id="Path_119033"
                        data-name="Path 119033"
                        d="M15578.713-3593.554h2"
                        transform="translate(-14569.713 4636.054)"
                        fill="none"
                        stroke="#bcc1ce"
                        stroke-width="1"
                      />
                      <path
                        id="Path_119034"
                        data-name="Path 119034"
                        d="M15578.711-3593.554h8"
                        transform="translate(-14565.713 4628.054)"
                        fill="none"
                        stroke="#bcc1ce"
                        stroke-width="1"
                      />
                      <path
                        id="Path_119035"
                        data-name="Path 119035"
                        d="M15578.711-3593.554h8"
                        transform="translate(-14565.713 4632.054)"
                        fill="none"
                        stroke="#bcc1ce"
                        stroke-width="1"
                      />
                      <path
                        id="Path_119036"
                        data-name="Path 119036"
                        d="M15578.711-3593.554h8"
                        transform="translate(-14565.713 4636.054)"
                        fill="none"
                        stroke="#bcc1ce"
                        stroke-width="1"
                      />
                    </g>
                  </svg>
                </div>
              )}
              {/* {cmsArray[indx].value.button.link === "page" && (
              <div className="cms-button-dropdown">
              <Dropdown isOpen={window.matchMedia("(max-width: 1200px)").matches?false :openPageDropdown}
              onClick={window.matchMedia("(max-width: 1200px)").matches?handleMobilePageDropdown:null}
               toggle={handlePageDropdown}
              >
                <DropdownToggle caret><span className="search-dropdown-before">{cmsArray[indx].value.button.target_alias?
                cmsArray[indx].value.button.target_alias:"Select"}</span></DropdownToggle>
                <DropdownMenu >
               {
                pagesData.length===0 &&
                <div className="no-app-dropdown">
                <img className="img-fluid" src={require("../../assets/images/dashboard/no-tag-available.png").default} alt="No App Found" />
                </div>
               }
               {
                pagesData.length>0 &&
                <FormGroup>
                <div className="dropdown-search">
                  <input placeholder="Search..." type="text" value={searchPageVal}
                  onChange={(e)=>{
                    setSearchPageVal(e.target.value)
                    
                  }}
                  ></input>
                  {searchPageVal.trim() && <p onClick={(e)=>{
                  setSearchPageVal("")
                }}>Clear</p>}
                </div>
              </FormGroup>
             }
               
             {pagesData.length>0 && recurs(pagesData,"parent")}
                 
                 
                  
                </DropdownMenu>
              </Dropdown>
              </div>
              )} */}
              {/* {cmsArray[indx].value.button.link === "post" && (
              <div className="cms-button-dropdown">
              <Dropdown isOpen={window.matchMedia("(max-width: 1200px)").matches?false :openPostDropdown}
              onClick={window.matchMedia("(max-width: 1200px)").matches?handleMobilePostDropdown:null}
               toggle={handlePostDropdown}
              >
                <DropdownToggle caret><span className="search-dropdown-before">{cmsArray[indx].value.button.target_alias?
                cmsArray[indx].value.button.target_alias:"Select"}</span></DropdownToggle>
                <DropdownMenu >
               {
                postData.length===0 &&
                <div className="no-app-dropdown">
                <img className="img-fluid" src={require("../../assets/images/dashboard/no-tag-available.png").default} alt="No App Found" />
                </div>
               }
               
               {
                postData.length>0 &&
                <FormGroup>
                <div className="dropdown-search">
                  <input placeholder="Search..." type="text" value={searchPostVal}
                  onChange={(e)=>{
                    setSearchPostVal(e.target.value)
                    
                  }}
                  ></input>
                  {searchPostVal.trim() && <p onClick={(e)=>{
                  setSearchPostVal("")
                }}>Clear</p>}
                </div>
              </FormGroup>
             }
               
             {postData.length>0 &&
             
             postData.filter((row1) =>
             row1.title
               .toLowerCase()
               .includes(searchPostVal.toLowerCase())
           ).map((eachPost, indx) => {
               return (
                 ((eachPost.level < 10) ||
                   eachPost.level === undefined) &&
                 eachPost.deleted_at === null && (
            <DropdownItem  
            onClick={(e)=>handleTarget(eachPost)}
            >{eachPost.title}</DropdownItem>
             )
               )
             }
           )
            }
                 
                 
                  
                </DropdownMenu>
              </Dropdown>
              </div>
              )} */}
              {cmsArray[indx].value.button.link === "email" && (
                <input
                  type="email"
                  placeholder="someone@example.com"
                  onChange={(e) => handleUrl(e, indx)}
                  value={cmsArray[indx].value.button.target}
                />
              )}{" "}
              {cmsArray[indx].value.button.link === "phone" && (
                <div className="">
                  <div className="d-flex">
                    <div className="cms-phone-dropdown">
                      <Dropdown
                        className="cms-phone-book"
                        isOpen={
                          window.matchMedia("(max-width: 1200px)").matches
                            ? false
                            : openAppDropdown
                        }
                        onClick={
                          window.matchMedia("(max-width: 1200px)").matches
                            ? handleMobileAppDropdown
                            : null
                        }
                        toggle={handleAppdropdown}
                      >
                        <DropdownToggle
                          caret
                          className="connectus-dropdowntoggle"
                        >
                          <img
                            src={
                              process.env.REACT_APP_Image_Path +
                              process.env.REACT_APP_SMALL_FLAG +
                              countryNamePrefix.toLowerCase() +
                              ".png"
                            }
                            alt={"flag"}
                          />
                          <h1>{phoneCode}</h1>
                        </DropdownToggle>
                        <DropdownMenu>
                          {/* <div className="no-app-dropdown">
                              <img className="img-fluid" src={require("../assets/images/dashboard/no-tag-available.png").default} alt="No App Found" />
                              </div> */}
                          <FormGroup>
                            <div className="dropdown-search">
                              <input
                                placeholder="Search..."
                                type="text"
                                onChange={(e) => {
                                  setPhonecodesearch(e.target.value);
                                }}
                                value={phonecodesearch}
                              ></input>
                              {phonecodesearch.trim() && (
                                <p
                                  onClick={(e) => {
                                    setPhonecodesearch("");
                                  }}
                                >
                                  Clear
                                </p>
                              )}
                            </div>
                          </FormGroup>
                          {countryselectfield
                            .filter((name) =>
                              name.country_name
                                .toLowerCase()
                                .includes(phonecodesearch.toLowerCase())
                            )
                            .map((res, i) => (
                              <DropdownItem
                                className="d-flex"
                                onClick={(e) =>
                                  handleCountryChange(
                                    e,
                                    res.phone_code,
                                    res.country_code
                                  )
                                }
                              >
                                <img
                                  src={
                                    process.env.REACT_APP_Image_Path +
                                    process.env.REACT_APP_SMALL_FLAG +
                                    res.country_code.toLowerCase() +
                                    ".png"
                                  }
                                  alt={i}
                                />
                                <p className="country-name">
                                  {res.country_name} ({"+" + res.phone_code})
                                </p>
                              </DropdownItem>
                            ))}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    {/* {"+"}
                  {phoneCode} */}
                    {/* </div>
                <div> */}
                    <input
                      type="tel"
                      className="form-control  contact-flaginputbox"
                      // name="email"
                      // required
                      maxLength="19"
                      value={phoneNo}
                      onChange={(e) => handleUrl(e)}
                      autocomplete="off"
                    />
                  </div>
                </div>
              )}
              {cmsArray[indx].value.button.link === "iap" && (
                <div className="row">
                  <div className="col-md-12 relative cms_productId">
                    <input
                      // type="tel"
                      onChange={(e) => handleUrl(e)}
                      autocomplete="off"
                      value={cmsArray[indx].value.button.target}
                      // pattern="[0-9]*"
                      maxLength="9"
                    />
                    {cmsArray[indx].value.button.target.length >= 1 && (
                      <img
                        onClick={() => {
                          navigator.clipboard.writeText(
                            cmsArray[indx].value.button.target
                          );
                          sucesstoast("Copied");
                        }}
                        src={
                          require("../../assets/images/dashboard/cms/duplicate.png")
                            .default
                        }
                        className="productId-dubliacte"
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* <div className="row">
            <div className="col-md-12 mt-20">
              <h6>Link</h6>
            </div>
            <div className="col-md-12">
                <div className="select-popup-for-buttonblock" onClick={(e)=>handlePostPopUp(e,"open")}>
                  <span style={{color:cmsArray[indx].value.button.target_alias? "": "#BCC1CE"}} className="search-dropdown-before" onClick={(e)=>handlePostPopUp(e,"open")}>{cmsArray[indx].value.button.target_alias?
                cmsArray[indx].value.button.target_alias:"Select"}</span>

                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19">
                    <g id="Group_42666" data-name="Group 42666" transform="translate(-1005 -1029)">
                      <g id="Rectangle_31915" data-name="Rectangle 31915" transform="translate(1005 1029)" fill="#fff" stroke="#bcc1ce" stroke-width="1">
                        <rect width="20" height="19" rx="2" stroke="none" />
                        <rect x="0.5" y="0.5" width="19" height="18" rx="1.5" fill="none" />
                      </g>
                      <path id="Path_119031" data-name="Path 119031" d="M15578.713-3593.554h2" transform="translate(-14569.713 4628.054)" fill="none" stroke="#bcc1ce" stroke-width="1" />
                      <path id="Path_119032" data-name="Path 119032" d="M15578.713-3593.554h2" transform="translate(-14569.713 4632.054)" fill="none" stroke="#bcc1ce" stroke-width="1" />
                      <path id="Path_119033" data-name="Path 119033" d="M15578.713-3593.554h2" transform="translate(-14569.713 4636.054)" fill="none" stroke="#bcc1ce" stroke-width="1" />
                      <path id="Path_119034" data-name="Path 119034" d="M15578.711-3593.554h8" transform="translate(-14565.713 4628.054)" fill="none" stroke="#bcc1ce" stroke-width="1" />
                      <path id="Path_119035" data-name="Path 119035" d="M15578.711-3593.554h8" transform="translate(-14565.713 4632.054)" fill="none" stroke="#bcc1ce" stroke-width="1" />
                      <path id="Path_119036" data-name="Path 119036" d="M15578.711-3593.554h8" transform="translate(-14565.713 4636.054)" fill="none" stroke="#bcc1ce" stroke-width="1" />
                    </g>
                  </svg>
                </div>
            </div>
          </div> */}

          <div className="row mt-20">
            <div className="col-md-6">
              <h6>Background Color</h6>
              <Reactcolorpicker
                colorname={cmsArray[indx].style.button_color}
                onChange={(e) => handlebgColor(e, indx)}
                classselection={"fixback"}
                valuecontrol={false}
                // presetColors={this.props.value.state.presetColors}
              />
            </div>

            <div className="col-md-6">
              <h6>Text Color</h6>
              <Reactcolorpicker
                colorname={cmsArray[indx].style.text_color}
                onChange={(e) => handletextColor(e, indx)}
                valuecontrol={true}
                classselection={"fixback"}
              />
            </div>
          </div>

          <div className="duplicate-delete">
            <div
              className="cms-dup-del-btn"
              onClick={(e) => {
                duplicateItem(e, eachBlock);
              }}
            >
              <span class="cms-delete-tooltip">
                <img
                  src={
                    require("../../assets/images/dashboard/cms/duplicate.png")
                      .default
                  }
                />
                <span class="cms-delete-tooltipsubheader">Duplicate</span>
              </span>
            </div>
            <div
              className="cms-dup-del-btn"
              onClick={(e) => {
                deleteRow(e, indx);
                if (from === "Post") {
                  {
                    setIapValue(null);
                  }
                }
              }}
            >
              <span class="cms-delete-tooltip">
                <img
                  src={
                    require("../../assets/images/dashboard/cms/delete.png")
                      .default
                  }
                />
                <span class="cms-delete-tooltipsubheader">Delete</span>
              </span>
            </div>
          </div>
        </div>
      )}

      {/* Country selection dropdown in Mobile view */}
      <Modal
        isOpen={
          window.matchMedia("(max-width: 1200px)").matches
            ? openMobileAppDropdown
            : false
        }
        style={{ maxWidth: "700px", maxHeight: "350px" }}
        className={" profile-code-modal"}
        centered
        dataclass="countrycode"
      >
        <ModalHeader className=" ">
          <div className="d-flex align-items-center search-box justify-content-center profile-country-search">
            <div className="d-flex align-items-center w-100 ">
              <div
                className="backaero-svgimg"
                onClick={handleMobileAppDropdown}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.759"
                  height="15.408"
                  viewBox="0 0 20.759 15.408"
                >
                  <g
                    id="Group_27465"
                    data-name="Group 27465"
                    transform="translate(-19.742 -22.295)"
                  >
                    <path
                      id="Path_81245"
                      data-name="Path 81245"
                      d="M-13783.922-19217h19.346"
                      transform="translate(13805.077 19247)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                    <path
                      id="Path_82232"
                      data-name="Path 82232"
                      d="M14053.656,19255.426l-7,7,7,7"
                      transform="translate(-14025.504 -19232.424)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </div>
              <input
                className="form-control flagsearch-input"
                placeholder="Search..."
                type="text"
                onChange={(e) => {
                  setPhonecodesearch(e.target.value);
                }}
                value={phonecodesearch}
              ></input>
              {phonecodesearch.trim() && (
                <p
                  className="cleartext"
                  onClick={(e) => {
                    setPhonecodesearch("");
                  }}
                >
                  Clear
                </p>
              )}
            </div>
          </div>
        </ModalHeader>
        <ModalBody className="code-selector p-0">
          <div className=" profile-countrycode-flag ">
            {countryselectfield
              .filter((name) =>
                name.country_name
                  .toLowerCase()
                  .includes(phonecodesearch.toLowerCase())
              )
              .map((res, i) => (
                <div
                  className="d-flex align-items-center justify-content-between country-line-flag"
                  key={i}
                >
                  <div
                    className="d-flex align-items-center justify-content-start"
                    onClick={(e) =>
                      handleCountryChange(e, res.phone_code, res.country_code)
                    }
                    // onClick={(e) => this.handleCountryDropdown(e, res)}
                  >
                    <img
                      src={
                        process.env.REACT_APP_Image_Path +
                        process.env.REACT_APP_SMALL_FLAG +
                        res.country_code.toLowerCase() +
                        ".png"
                      }
                      alt={i}
                    />
                    <p className="country-name">
                      {res.country_name} ({"+" + res.phone_code})
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </ModalBody>
      </Modal>

      {/* Left btn options selection dropdown in Mobile view */}
      <Modal
        className="protip-popup-message"
        isOpen={
          window.matchMedia("(max-width: 1200px)").matches
            ? openMobileLinkDropdown
            : false
        }
        centered
      >
        <ModalBody>
          <div className="mobileglobalsearch">
            <button onClick={handleMobileLinkDropdown}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.759"
                height="15.408"
                viewBox="0 0 20.759 15.408"
              >
                <g
                  id="Group_27465"
                  data-name="Group 27465"
                  transform="translate(-19.742 -22.295)"
                >
                  <path
                    id="Path_81245"
                    data-name="Path 81245"
                    d="M-13783.922-19217h19.346"
                    transform="translate(13805.077 19247)"
                    fill="none"
                    stroke="#7782a1"
                    stroke-width="2"
                  />
                  <path
                    id="Path_82232"
                    data-name="Path 82232"
                    d="M14053.656,19255.426l-7,7,7,7"
                    transform="translate(-14025.504 -19232.424)"
                    fill="none"
                    stroke="#7782a1"
                    stroke-width="2"
                  />
                </g>
              </svg>
            </button>
          </div>

          <div className="mobilesearchsuggestion">
            {linkOptions &&
              linkOptions?.map((row) => {
                return (
                  <p
                    onClick={() => handleSelectopt(row)}
                    className="mobilesearchsuggestion-para"
                  >
                    {row.label}
                  </p>
                );
              })}
          </div>
        </ModalBody>
      </Modal>

      {/* Page selection dropdown in Mobile view */}
      {/* <Modal className="protip-popup-message" isOpen={window.matchMedia("(max-width: 1200px)").matches?openMobilePageDropdown:false} centered>
          
          <ModalBody>
            <div className="mobileglobalsearch">
              <button onClick={handleMobilePageDropdown}><svg xmlns="http://www.w3.org/2000/svg" width="20.759" height="15.408" viewBox="0 0 20.759 15.408">
            <g id="Group_27465" data-name="Group 27465" transform="translate(-19.742 -22.295)">
              <path id="Path_81245" data-name="Path 81245" d="M-13783.922-19217h19.346" transform="translate(13805.077 19247)" fill="none" stroke="#7782a1" stroke-width="2"/>
              <path id="Path_82232" data-name="Path 82232" d="M14053.656,19255.426l-7,7,7,7" transform="translate(-14025.504 -19232.424)" fill="none" stroke="#7782a1" stroke-width="2"/>
            </g>
          </svg></button>
          {

          pagesData.length > 0 &&
          <input placeholder="Search..." type="text" value={searchPageVal}
                                  onChange={(e)=>{
                                   setSearchPageVal(e.target.value)
                                  }}
                                  ></input>
                                }
              {searchPageVal.trim() && <p onClick={(e)=>{
                                   setSearchPageVal("")
                                }}>Clear</p> }
          </div>

          <div className="mobilesearchsuggestion">
           
          {pagesData.length>0 ? recursForMobile(pagesData,"parent")
        : <div className="no-app-dropdown">
        <img className="img-fluid" src={require("../../assets/images/dashboard/no-tag-available.png").default} alt="No App Found" />
        </div> 
        }

          

            </div>
          </ModalBody>
      </Modal> */}

      {/* Post selection dropdown in Mobile view */}
      {/* <Modal className="protip-popup-message" isOpen={window.matchMedia("(max-width: 1200px)").matches?openMobilePostDropdown:false} centered>
          
          <ModalBody>
            <div className="mobileglobalsearch">
              <button onClick={handleMobilePostDropdown}><svg xmlns="http://www.w3.org/2000/svg" width="20.759" height="15.408" viewBox="0 0 20.759 15.408">
            <g id="Group_27465" data-name="Group 27465" transform="translate(-19.742 -22.295)">
              <path id="Path_81245" data-name="Path 81245" d="M-13783.922-19217h19.346" transform="translate(13805.077 19247)" fill="none" stroke="#7782a1" stroke-width="2"/>
              <path id="Path_82232" data-name="Path 82232" d="M14053.656,19255.426l-7,7,7,7" transform="translate(-14025.504 -19232.424)" fill="none" stroke="#7782a1" stroke-width="2"/>
            </g>
          </svg></button>
          {

          postData.length > 0 &&
          <input placeholder="Search..." type="text" value={searchPostVal}
                                  onChange={(e)=>{
                                   setSearchPostVal(e.target.value)
                                  }}
                                  ></input>
                                }
              {searchPostVal.trim() && <p onClick={(e)=>{
                                   setSearchPostVal("")
                                }}>Clear</p> }
          </div>

          <div className="mobilesearchsuggestion">
           
          {postData.length>0 ?  
             
             postData.filter((row1) =>
             row1.title
               .toLowerCase()
               .includes(searchPostVal.toLowerCase())
           ).map((eachPost, indx) => {
               return (
                 ((eachPost.level < 10) ||
                   eachPost.level === undefined) &&
                 eachPost.deleted_at === null && (
            <p   style={{	
              fontSize:"16px",	
              color: "#1B2952",	
               fontFamily: "Axiforma-Regular",	
              margin: "0px 20px 40px 20px",	
               display: "block",	
            }}
            onClick={(e)=>handleTarget(eachPost)}
            >{eachPost.title}</p>
             )
               )
             }
           )
            
        : <div className="no-app-dropdown">
        <img className="img-fluid" src={require("../../assets/images/dashboard/no-tag-available.png").default} alt="No App Found" />
        </div> 
        }

          

            </div>
          </ModalBody>
      </Modal> */}

      {/* Post Pop up */}

      <SingleSelectionPopUpForCustomApp
        openPopUp={openPostPopUp}
        handlePopUp={handlePostPopUp}
        data={
          cmsArray[indx].value.button.link === "post" ? postData : pagesData
        }
        handleInputClick={handleParentChange}
        selectedId={tempSelectedID}
        subData={subData}
        setSubData={setSubData}
        searchVal={searchValForPopUp}
        setSearchVal={setSearchValForPopUp}
        breadcrumbArr={breadcrumbArr}
        setBreadcrumbArr={setBreadcrumbArr}
        from={"page"}
        oldBreadcrumbArr={oldBreadcrumbArr}
        oldSubData={oldSubData}
        handleBottomScrollListner={handleBottomScrollListner}
        paginationSpinner={
          cmsArray[indx].value.button.link === "post"
            ? paginationSpinnerForPost
            : paginationSpinnerForPage
        }
        hideSelectOption={true}
        pageOrPostId={
          cmsArray[indx].value.button.link === "post"
            ? urlParams.postID
            : urlParams.pageID
        }
      />
    </div>
  );
};

export default ButtonBlock;
