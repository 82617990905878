import React from 'react'

const Success = () => {
  return (
    <svg id="Group_27505" data-name="Group 27505" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <circle id="Ellipse_2306" data-name="Ellipse 2306" cx="8" cy="8" r="8" fill="#52cb86" />
    <path id="Path_7161" data-name="Path 7161" d="M-19252.924-4212.33l2.656,2.656,5-5" transform="translate(19257.096 4220.174)" fill="none" stroke="#fff" stroke-width="1.5" />
  </svg>
  )
}

export default Success;