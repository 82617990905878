import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FormGroup, Label, Input, Modal } from "reactstrap";
import galleryimg from "../../assets/images/dashboard/add-to-gallery.png";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import { useMemo } from "react";
import NewImageGalleryPopup from "../NewImageGalleryPopup";
import { useEffect } from "react";

import VistaPopup from "../Appearance/VistaPopup";
import { ModalBody } from "react-bootstrap";
const GalleryBlock = ({
  indx,
  cmsArray,
  setcmsData,
  isRowOpen,
  handleIsRowOpen,
  deleteRow,
  duplicateItem,
  galleryToggle,
  setgalleryToggle,
  galleryTab,
  setgalleryTab,
  eachBlock,
  galleryToggleUpdate,
  searchValForPopUp ,
  setSearchValForPopUp ,
  provided,
}) => {
  const [galleryValue, setgalleryValue] = useState(
    parseInt(cmsArray[indx].style.column)
  );
  const [galleryValue2, setgalleryValue2] = useState(
    parseInt(cmsArray[indx].style.row)
  );
  const [isGalleryPopup, setIsGalleryPopup] = useState(false);
  const [prevImgUrl, setprevImgUrl] = useState("");
  const [imgIndex, setImgIndex] = useState(null);
  // const [openVistaPopUp, setOpenVistaPopUp] = useState(false);
  const [apiImageUrl, setApiImageUrl] = useState("");
  // const [openSmallScreenPopUp, setOpenSmallScreenPopUp] = useState(false);
  useEffect(() => {
    if (
      cmsArray[indx].showOnlyArr === undefined ||
      cmsArray[indx].showOnlyArr === null
    ) {
      let newArray = [...cmsArray];
      let arr = [];
      newArray[indx].portal_value.map((row, indx) => {
        arr.push("");
      });
      newArray[indx].showOnlyArr = arr;
      setcmsData(newArray);
    }
  }, []);

  const imageGalleryPopup = (e, i) => {
    let newArray = [...cmsArray];
    setImgIndex(i);
    setIsGalleryPopup(!isGalleryPopup);

    setprevImgUrl(newArray[indx].portal_value[i].source_file);
    // setApiImageUrl(newArray[indx].value.image_url[i]);
  };

  const handlegalleryVal = (e) => {
    let newArr2 = [...cmsArray];
    if (e.target.value === "") {
      newArr2[indx].style.column = e.target.value;
      setcmsData(newArr2);
      return;
    }
    let rowVal = parseInt(e.target.value);

    if (rowVal > 4 || rowVal < 1) {
      rowVal = 1;
    }
    setgalleryValue(rowVal);

    if (newArr2[indx].showOnlyArr.length <= rowVal * galleryValue2) {
      for (
        let i = newArr2[indx].showOnlyArr.length;
        i < rowVal * galleryValue2;
        i++
      ) {
        if (newArr2[indx].portal_value.length < galleryValue2 * rowVal) {
          newArr2[indx].portal_value.splice(
            newArr2[indx].portal_value.length,
            0,
            {
              source_file: null,
              image_link: "",
              resize_image_link: "",
              type: 1,
              base64_image:"" ,
              base64_org_image:"",
              design_id:""
            }
          );
        }

        newArr2[indx].showOnlyArr.splice(i, 0, "");
      }
    } else {
      for (
        let i = newArr2[indx].showOnlyArr.length;
        i >= rowVal * galleryValue2;
        i--
      ) {
        newArr2[indx].showOnlyArr.splice(i, 1);
      }
    }

    newArr2[indx].style.column = rowVal;
    setcmsData(newArr2);
  };
  const handlegalleryVal2 = (e) => {
    let newArr2 = [...cmsArray];
    if (e.target.value === "") {
      newArr2[indx].style.row = e.target.value;
      setcmsData(newArr2);
      return;
    }
    let colVal = parseInt(e.target.value);

    if (colVal > 10 || colVal < 1) {
      colVal = 1;
    }
    setgalleryValue2(colVal);
    // let newArr = [...dummyArr];

    if (newArr2[indx].showOnlyArr.length <= galleryValue * colVal) {
      for (
        let i = newArr2[indx].showOnlyArr.length;
        i < galleryValue * colVal;
        i++
      ) {
        // newArr.splice(i, 0, "1");
        if (newArr2[indx].portal_value.length < galleryValue * colVal) {
          newArr2[indx].portal_value.splice(
            newArr2[indx].portal_value.length,
            0,
            {
              source_file: null,
              image_link: "",
              resize_image_link: "",
              type: 1,
              base64_image:"" ,
              base64_org_image:"",
              design_id:"",
            }
          );
        }
        newArr2[indx].showOnlyArr.splice(i, 0, "");
      }
    } else {
      for (
        let i = newArr2[indx].showOnlyArr.length;
        i >= galleryValue * colVal;
        i--
      ) {
        newArr2[indx].showOnlyArr.splice(i, 1);
      }
    }

    newArr2[indx].style.row = colVal;
    setcmsData(newArr2);
  };

  // Ratio selection
  const handleRatioChange = (e) => {
    let newArray = [...cmsArray];
    newArray[indx].style.aspect_ratio = e.target.value;
    setcmsData(newArray);
  };



  // Remove Image
  const handleCancelImage = (e, imageIndex) => {
    let newArr = [...cmsArray];
    newArr[indx].portal_value[imageIndex].resize_image_link = "";
    newArr[indx].portal_value[imageIndex].source_file = "";
    newArr[indx].portal_value[imageIndex].image_link = "";
    newArr[indx].portal_value[imageIndex].type = 1;
    newArr[indx].portal_value[imageIndex].base64_image = "";
    newArr[indx].portal_value[imageIndex].base64_org_image = "";
    
    setcmsData(newArr);
  };

  const  blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const settingYourdata = async(imgName, from, orgFileName, url1200, useUrl,uploadedFileBase64,croppedImageForCms) => {
    let newArr = [...cmsArray];
    const resizeImgName= "ams_resize_" +  (imgIndex ? imgIndex :"" ) + new Date().getTime() +"_" + Math.floor(1000 + Math.random() * 9000)+".png";
    const originalImgName= "ams_original_"  + new Date().getTime() +"_" + Math.floor(1000 + Math.random() * 9000)+".png";
    if (from === "resize") {
      // newArr[indx].portal_value[imgIndex].image_link = originalImgName;
      newArr[indx].portal_value[imgIndex].image_link = "";
      newArr[indx].portal_value[imgIndex].source_file = imgName;
      newArr[indx].portal_value[imgIndex].resize_image_link = resizeImgName;
      newArr[indx].portal_value[imgIndex].type = 2;
      newArr[indx].portal_value[imgIndex].base64_image =await blobToBase64(croppedImageForCms);
      newArr[indx].portal_value[imgIndex].base64_org_image = "";
      newArr[indx].portal_value[imgIndex].design_id = "";
    } else if (from === "apiImg") {
      newArr[indx].portal_value[imgIndex].source_file = imgName;
      newArr[indx].portal_value[imgIndex].resize_image_link = url1200;
      // newArr[indx].portal_value[imgIndex].image_link = useUrl;
      newArr[indx].portal_value[imgIndex].image_link = "";
      newArr[indx].portal_value[imgIndex].type = 1;
      newArr[indx].portal_value[imgIndex].base64_image = "";
      newArr[indx].portal_value[imgIndex].base64_org_image = "";
      newArr[indx].portal_value[imgIndex].design_id = "";
    }

    setcmsData(newArr);
  };

  const yourErrFun = () => {
    let newArr = [...cmsArray];

    newArr[indx].portal_value[imgIndex].source_file = prevImgUrl;

    setcmsData(newArr);
  };


  const loadpicVistaCreatePopUp = (data) =>{


    //  const params = {
    //   filename: data.url.replace("https://", ""),
      
    //   path: localStorage.getItem("user_id") +
    //   "/" +
    //   localStorage.getItem("Appid") +
    //   process.env.REACT_APP_CMS_Media,
    // };
    //  thirdPartyImgUploadViaURL(params,successFn)
     let newArr = [...cmsArray];
     newArr[indx].portal_value[imgIndex].source_file = data.url;
        newArr[indx].portal_value[imgIndex].resize_image_link = data.url;
        newArr[indx].portal_value[imgIndex].image_link = "";
        newArr[indx].portal_value[imgIndex].type = 1;
        newArr[indx].portal_value[imgIndex].base64_image = ""
        newArr[indx].portal_value[imgIndex].base64_org_image = ""
        newArr[indx].portal_value[imgIndex].design_id = data.id;
  
  
     setcmsData(newArr);
    //  setOpenVistaPopUp(false);
     setIsGalleryPopup(false);
     
    //  if(document.querySelector(".crello-closeBtn")){
    //   document.querySelector(".crello-closeBtn").click();
    //  }
    }
  
  // const successFn = (imgName) =>{
  //   let newArr = [...cmsArray];
  //   newArr[indx].portal_value[imgIndex].source_file = imgName;
  //      newArr[indx].portal_value[imgIndex].resize_image_link = imgName;
    
  //      newArr[indx].portal_value[imgIndex].image_link = "";
  //      newArr[indx].portal_value[imgIndex].type = 2;
  //      newArr[indx].portal_value[imgIndex].base64_image = ""
  //      newArr[indx].portal_value[imgIndex].base64_org_image = ""
  
  //      setOpenVistaPopUp(false);
  //   setcmsData(newArr);
  // }
    const closeVistaCreatePopUp = () =>{
  
    }
  
    const handleStatus = (e) =>{

      let cmsData = [...cmsArray];
      cmsData[indx].status = ( cmsData[indx].status===1 || cmsData[indx].status===undefined || cmsData[indx].status===null)
      ?0:1;
    
      // setbgColor(e.hex);
      setcmsData(cmsData);
    }

 
  return (
    <div className={`accordionItem gallery ${cmsArray[indx].status===0?"cms-section-hidden":""}`}> 
    
      <h2
        className="accordionItemHeading allflex"
        draggableId={indx.toString()}
        {...provided.dragHandleProps}
      >
        Gallery
        {/* <div className="homescreensection"></div> */}

        <span className="cms-section-visiblity" onClick={(e)=>handleStatus(e)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="25.284" height="20.465" viewBox="0 0 25.284 20.465">
        <g id="Group_39189" data-name="Group 39189" transform="translate(0.568 0.353)">
          <path id="Path_103079" data-name="Path 103079" d="M68.716,9.742a13.852,13.852,0,0,1-12.07,7.684A13.876,13.876,0,0,1,44.568,9.742,13.876,13.876,0,0,1,56.646,2.058,13.852,13.852,0,0,1,68.716,9.742Z" transform="translate(-44.568 0.138)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1"/>
          <ellipse id="Ellipse_8629" data-name="Ellipse 8629" cx="3.842" cy="3.842" rx="3.842" ry="3.842" transform="translate(8.233 6.038)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1"/>
          <line id="Line_430" data-name="Line 430" x2="19.758" y2="19.758" transform="translate(2.196 0.001)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1"/>
        </g>
      </svg>
    </span>

    
        <p className="cms-accordian-p"
          onClick={(e) => {
            handleIsRowOpen(e, indx);
          }}
        >
          <img
            src={
              isRowOpen[indx] === true
                ? require("../../assets/images/dashboard/cms/accordian-open.png")
                    .default
                : require("../../assets/images/dashboard/cms/accordian-icon.png")
                    .default
            }
          />
        </p>
      </h2>
      {isRowOpen[indx] === true && (
        <div className="accordionItemContent pages-fields">
          
          <div className="gallery-row-column">

            <div className="gallery-column">
              <h6>Column</h6>
              <input
                type="number"
                className=""
                min="1"
                max="4"
                value={cmsArray[indx].style.column}
                onChange={(e) => {
                  handlegalleryVal(e);
                }}
              />
              </div>

              <div className="gallery-column">
              <h6>Row</h6>
              <input
                type="number"
                className="gallery-layout-value"
                min="1"
                max="10"
                value={cmsArray[indx].style.row}
                onChange={(e) => {
                  handlegalleryVal2(e);
                }}
              />
              </div>
              
          </div>


          <div className="row">
            <div className="col-md-12"></div>

            <div className="col-md-12 gallery-images">
              {cmsArray[indx].showOnlyArr &&
                cmsArray[indx].showOnlyArr.map((row, i) => {
                  return (
                    <>
                      <div
                        className="gallery-single default-gallery"
                        onClick={(e) => imageGalleryPopup(e, i)}
                      >
                        {cmsArray[indx].portal_value[i].source_file !== null &&
                        cmsArray[indx].portal_value[i].source_file !==
                          undefined &&
                        cmsArray[indx].portal_value[i].source_file !== "" ? (
                          <div
                            style={{
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center center",
                              backgroundSize: "cover",
                              width: "100%",
                              height: "100%",
                              backgroundImage:
                                "url(" +
                                (cmsArray[indx].portal_value[i].source_file.indexOf("https")>-1 ? cmsArray[indx].portal_value[i].source_file
                                 : process.env.REACT_APP_Image_Path +
                                localStorage.getItem("user_id") +
                                "/" +
                                localStorage.getItem("Appid") +
                                process.env.REACT_APP_CMS_Media +
                                cmsArray[indx].portal_value[i].source_file ) +
                                ")",
                            }}
                          >
                            <div className="gallery-edit">
                              {/* <a href=""></a> */}
                              <p onClick={(e) => handleCancelImage(e, i)}>
                                Remove
                              </p>
                            </div>
                          </div>
                        ) : (
                          <img
                            className="img-fluid"
                            src={galleryimg}
                            alt="add gallery"
                          />
                        )}

                        {/* <img
                    
                        className="img-fluid"
                        src={
                          cmsArray[indx].value[0].images[i].media_id !== null &&
                          cmsArray[indx].value[0].images[i].media_id !==
                            undefined &&
                          cmsArray[indx].value[0].images[i].media_id !== ""
                            ? process.env.REACT_APP_Image_Path +
                              localStorage.getItem("user_id") +
                              "/" +
                              localStorage.getItem("Appid") +
                              process.env.REACT_APP_Dashboard_Crop +
                              cmsArray[indx].value[0].images[i].media_id
                            : galleryimg
                        }
                        alt="add gallery"
                      /> */}
                      </div>
                    </>
                  );
                })}
            </div>

            <div className="col-md-12">
              <FormGroup tag="fieldset" className="the-gallery-ratio" style={{position: "absolute"}}>
                <FormGroup>
                  <Label className="imgallery-ratio" htmlFor={`aspectratio${indx}3`}>
                    <Input
                      id={`aspectratio${indx}3`}
                      type="radio"
                      // name="radio1"
                      value="16:9"
                      checked={cmsArray[indx].style.aspect_ratio === "16:9"}
                      onChange={(e) => handleRatioChange(e)}
                    />
                    <div className="image">
                    <span class="cms-icon-tooltip">
                      <svg width="36" height="20" viewBox="0 0 36 20">
                        <g
                          id="Rectangle_18739"
                          data-name="Rectangle 18739"
                          stroke-width="2"
                        >
                          <rect width="36" height="20" stroke="none" />
                          <rect
                            x="1"
                            y="1"
                            width="34"
                            height="18"
                            fill="none"
                          />
                        </g>
                      </svg>

                      <p>16:9</p>
                      <span class="cms-icon-tooltipsubheader">Select for a widescreen image format, ideal for panoramic views and cinematic  visuals</span>
                  </span>
                    </div>
                  </Label>
                </FormGroup>
                <FormGroup>
                  <Label className="imgallery-ratio" htmlFor={`aspectratio${indx}0`}>
                    <Input
                      id={`aspectratio${indx}0`}
                      type="radio"
                      // name="radio1"
                      value="4:3"
                      checked={cmsArray[indx].style.aspect_ratio === "4:3"}
                      onChange={(e) => handleRatioChange(e)}
                    />
                    <div className="image">
                    <span class="cms-icon-tooltip">
                      <svg width="26" height="20" viewBox="0 0 26 20">
                        <g
                          id="Rectangle_18739"
                          data-name="Rectangle 18739"
                          stroke-width="2"
                        >
                          <rect width="26" height="20" stroke="none" />
                          <rect
                            x="1"
                            y="1"
                            width="24"
                            height="18"
                            fill="none"
                          />
                        </g>
                      </svg>
                      <p>4:3</p>
                      <span class="cms-icon-tooltipsubheader">Opt for to maintain a classic image aspect ratio, suitable for balanced compositions.</span>
                  </span>
                    </div>
                  </Label>
                </FormGroup>
                <FormGroup>
                  <Label className="imgallery-ratio" htmlFor={`aspectratio${indx}2`}>
                    <Input
                      id={`aspectratio${indx}2`}
                      type="radio"
                      // name="radio1"
                      value="1:1"
                      checked={cmsArray[indx].style.aspect_ratio === "1:1"}
                      onChange={(e) => handleRatioChange(e)}
                    />
                    <div className="image">
                    <span class="cms-icon-tooltip">
                      <svg width="20" height="20" viewBox="0 0 20 20">
                        <g
                          id="Rectangle_18739"
                          data-name="Rectangle 18739"
                          stroke-width="2"
                        >
                          <rect width="20" height="20" stroke="none" />
                          <rect
                            x="1"
                            y="1"
                            width="18"
                            height="18"
                            fill="none"
                          />
                        </g>
                      </svg>
                      <p>1:1</p>
                      <span className="cms-icon-tooltipsubheader last-icon-tooltip">Choose for a square image format, great for symmetrical and uniform visuals.</span>
                  </span>
                    </div>
                  </Label>
                </FormGroup>
                <FormGroup>
                  <Label className="imgallery-ratio" htmlFor={`aspectratio${indx}1`}>
                    <Input
                      id={`aspectratio${indx}1`}
                      type="radio"
                      // name="radio1"
                      value="3:4"
                      checked={cmsArray[indx].style.aspect_ratio === "3:4"}
                      onChange={(e) => handleRatioChange(e)}
                    />
                    <div className="image">
                    <span class="cms-icon-tooltip">
                      <svg width="15" height="20" viewBox="0 0 15 20">
                        <g
                          id="Rectangle_18739"
                          data-name="Rectangle 18739"
                          stroke-width="2"
                        >
                          <rect width="15" height="20" stroke="none" />
                          <rect
                            x="1"
                            y="1"
                            width="13"
                            height="18"
                            fill="none"
                          />
                        </g>
                      </svg>
                      <p>3:4</p>
                      <span className="cms-icon-tooltipsubheader last-icon-tooltip">Pick for a portrait-oriented image format, perfect for capturing vertical scenes and portraits.</span>
                  </span>
                    </div>
                  </Label>
                </FormGroup>
              </FormGroup>
                </div>

          </div>

          <div className="duplicate-delete">
            <div className="cms-dup-del-btn"  onClick={(e) => duplicateItem(e, eachBlock)}>
            <span class="cms-delete-tooltip">
            <img
                src={
                  require("../../assets/images/dashboard/cms/duplicate.png")
                    .default
                }
              />
               <span class="cms-delete-tooltipsubheader">Duplicate</span>
                  </span>
            </div>
            <div className="cms-dup-del-btn" 
              onClick={(e) => {
                deleteRow(e, indx);
              }}
            >
              <span class="cms-delete-tooltip">
            <img
                src={
                  require("../../assets/images/dashboard/cms/delete.png")
                    .default
                }
              />
               <span class="cms-delete-tooltipsubheader">Delete</span>
                  </span>
            </div>
          </div>
        </div>
      )}
      {imgIndex !== null && (
        <NewImageGalleryPopup
          
          isGalleryPopup={isGalleryPopup}
          setIsGalleryPopup={setIsGalleryPopup}
          settingYourdata={settingYourdata}
          yourErrFun={yourErrFun}
          uploadPath={
            localStorage.getItem("user_id") +
            "/" +
            localStorage.getItem("Appid") +
            process.env.REACT_APP_CMS_Media
          }
          settingOrgImgFrom3rdParty={false}
          hideImageGallery={false}
          widthForRestriction={1125}
          heightForRestriction={480}
          aspectRatio={cmsArray[indx].style.aspect_ratio}
          isNotCustomUploadOrgImg={true}
          isCms={true}
          designid={cmsArray[indx].portal_value[imgIndex].design_id ? cmsArray[indx].portal_value[imgIndex].design_id : ""}
          loadpicVistaCreatePopUp={loadpicVistaCreatePopUp}
          closeVistaCreatePopUp={closeVistaCreatePopUp}
          searchValForPopUp ={searchValForPopUp}
          setSearchValForPopUp ={setSearchValForPopUp}
        />
       )}

          

    </div>
  );
};

export default GalleryBlock;
