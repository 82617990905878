import React, {useContext} from 'react';
import styles from './publish.module.css'; 
import { Creation } from "./Context"; 
import moment from 'moment';
const History = () => {
	const context = useContext(Creation);
    return(
        <>
		{context.length > 0 && (
		<>
        <div className={styles.publish}>
            <h3>Publish History</h3>
        </div>
            <div className={styles.historytable}>
												<div className={"d-none d-md-flex align-items-center justify-content-between  pl-0 pr-0 " + styles.historytabletop}>
													<div className={styles.date}>
														<p>Date</p>
													</div>
													<div className={styles.date}>
														<p className="d-inline">iOS Build Version &amp; String</p>
														
													</div>
													<div className={styles.date}>
														<p className="d-inline">Apple ID</p>
													</div>
												
													<div className={styles.date}>
														<p className="d-inline">Bundle ID</p>
													</div>
													
													<div className={styles.date}>
														<p>Status</p>
													</div>
												</div>
												{context.map((res,i) => (
												<div key={i} className={"d-flex align-items-center dashboard-version-page justify-content-between common-height " + styles.hiscommonheight }>
													<div className={" d-flex align-items-center " + styles.date}>
														
														<svg width="48" height="48" viewBox="0 0 48 48">
														<g id="Group_4567" data-name="Group 4567" transform="translate(-310 -951)">
															<g id="Rectangle_1782" data-name="Rectangle 1782" transform="translate(310 951)" fill="#fff" stroke="#c8cedb" strokeWidth="1">
															<rect width="48" height="48" rx="12" stroke="none"/>
															<rect x="0.5" y="0.5" width="47" height="47" rx="11.5" fill="none"/>
															</g>
															<g id="apple-logo" transform="translate(325 964)">
															<g id="Group_1428" data-name="Group 1428" transform="translate(0 0)">
																<path id="Path_668" data-name="Path 668" d="M55.062,116.8a5.047,5.047,0,0,1-.914-2.956,4.723,4.723,0,0,1,.861-2.744,8.59,8.59,0,0,1,1.511-1.564,6.835,6.835,0,0,0-1.379-1.325,4.847,4.847,0,0,0-2.823-.861,7.481,7.481,0,0,0-2.293.451,6.637,6.637,0,0,1-1.9.451,8.528,8.528,0,0,1-1.829-.4,8.818,8.818,0,0,0-2.346-.4,4.576,4.576,0,0,0-3.764,1.909,7.864,7.864,0,0,0-1.5,4.957,13.589,13.589,0,0,0,1.949,6.667q1.975,3.433,3.99,3.433a4.792,4.792,0,0,0,1.75-.451,5.109,5.109,0,0,1,1.882-.437,5.861,5.861,0,0,1,2,.424,6.128,6.128,0,0,0,1.856.424q1.7,0,3.406-2.6a12.8,12.8,0,0,0,1.63-3.314A4.032,4.032,0,0,1,55.062,116.8Z" transform="translate(-38.692 -102.365)" fill="#f68751"/>
																<path id="Path_669" data-name="Path 669" d="M236.5,4.189a5.441,5.441,0,0,0,1.233-1.829,5.056,5.056,0,0,0,.384-1.8c0-.035,0-.079-.007-.133s-.006-.1-.006-.132a1.276,1.276,0,0,1-.033-.146A1.232,1.232,0,0,0,238.033,0a5.283,5.283,0,0,0-3.314,1.962,6.045,6.045,0,0,0-1.034,3.406,7.407,7.407,0,0,0,1.378-.225A3.756,3.756,0,0,0,236.5,4.189Z" transform="translate(-224.632 0)" fill="#f68751"/>
															</g>
															</g>
														</g>
													   </svg>
														
														
														<div style={{marginLeft:'10px'}}>
												           {/* <h4>Version {res.portal_version }</h4> */}
														   <h4 className="mb-0">{ moment(res.date).format('Do MMM YYYY')}</h4>
														  
															<h4 className="mb-0" style={{color:"#7782A1",fontSize:"13px"}}>{moment(res.date).format('h:mm:ss a')}</h4>
														</div>
													</div>
													<div className={"last-payment d-none d-md-block " + styles.date}>
													<h4>
                                                    {res.build_release} ({res.version})
													</h4>
													</div>
													<div className={"next-payment d-none d-md-block pr-5 " + styles.date}>

														<h4>
															 {res.apple_id}
														</h4>
													  
													
													</div>
													
													{/* iOS column */}
													<div className={"payment-method d-none d-md-block " + styles.date} >
														<h4>
															{res.bundle_id}
														</h4>
													</div>

													
													<div className={"bundle-id " + styles.date}>
													{(res.status === 4 && res.submit_for_review === 0) && 
														<h4 style={{color:"#47CE70"}}>
															Uploaded
														</h4>
													}
													{(res.status === 4 && res.submit_for_review === 1) && 
														<h4 style={{color:"#47CE70"}}>
															Published
														</h4>
													}
													{res.status === 3 && 
														<div className="d-flex justify-content-end">
														<h4 style={{color:"rgb(255, 72, 72)"}} className="m-0 p-0">
															Failed 
														</h4>
														<div className="tooltip-container ml-2">
														<span className="tooltip1 tooltip2" style={{right:"10px"}}>{res.description}</span>
															<img src= {require("../../../assets/images/dashboard/tool-tip.png").default} alt="alert"  /> 								
														</div>
													</div>
													}
													{(res.status === 1 || res.status === 2) && 
														<h4 style={{color:"rgb(71, 206, 112)"}}>
															Processing
														</h4>
													}
													{res.status === 0 && 
														<h4 style={{color:"#C8CEDB"}}>
															Draft
														</h4>
													}
													</div>
													
												</div>
												))}
												
											</div>
     	</>
		 )}  
	   </>
    )
}
export default History