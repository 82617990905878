import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./slices/counterSlice";
import { appDashboardApiSlice } from "../services/appDashboardApiSlice";
import { analyticsApiSlice } from "../services/analyticsApiSlice";
import { createAppApiSlice } from "../services/createAppApiSlice";
import { customCustomerApiSlice } from "../services/customCustomerApiSlice";
import { bottomApiSlice } from "../services/bottomApiSlice";
import appDataReducer from "./slices/appDataSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import userWebsiteDataReducer from "./slices/userWebsiteDataSlice";
import { menuApiSlice } from "../services/menuApiSlice";
import { userWebsiteApiSlice } from "../services/userWebsiteApiSlice";
import { cmsApiSlice } from "../services/cmsApiSlice";
import { mergeApiSlice } from "./slices/mergeappSlice";
import { teamApiSlice } from "../services/teamApiSlice";
import { addonsApiSlice } from "../services/addonsApiSlice";
import { designThemeApiSlice } from "../services/design-theme/designThemeSlice";
import { basicNotificationSlice } from "./slices/Notification/basicNotificationSlice";
import { wordpressNotificationSlice } from "./slices/Notification/wordpressNotificationSlice";
import { generalSettingsApiSlice } from "../services/generalSettingsApiSlice";
import { appIconSlice } from "../services/appIconApiSlice";
import { splashSlice } from "../services/splashApiSlice";
import { headerApiSlice } from "../services/headerApiSlice";
import { webViewApiSlice } from "../services/webViewApiSlice";
import buildConfigReducer from "./slices/buildConfigSlice";
import { profileSlice } from "../services/ProfileSlice/profileSlice";
import { productsApiSlice } from "../services/productsApiSlice";
import { agencyDomainApiSlice } from "../services/Agency/Domain/agencyDomainApiSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    appData: appDataReducer,
    buildConfiguration: buildConfigReducer,
    userWebsiteData: userWebsiteDataReducer,
    [appDashboardApiSlice.reducerPath]: appDashboardApiSlice.reducer,
    [analyticsApiSlice.reducerPath]: analyticsApiSlice.reducer,
    [createAppApiSlice.reducerPath]: createAppApiSlice.reducer,
    [customCustomerApiSlice.reducerPath]: customCustomerApiSlice.reducer,
    [menuApiSlice.reducerPath]: menuApiSlice.reducer,
    [bottomApiSlice.reducerPath]: bottomApiSlice.reducer,
    [cmsApiSlice.reducerPath]: cmsApiSlice.reducer,
    [userWebsiteApiSlice.reducerPath]: userWebsiteApiSlice.reducer,
    [mergeApiSlice.reducerPath]: mergeApiSlice.reducer,
    [teamApiSlice.reducerPath]: teamApiSlice.reducer,
    [addonsApiSlice.reducerPath]: addonsApiSlice.reducer,
    [designThemeApiSlice.reducerPath]: designThemeApiSlice.reducer,
    [basicNotificationSlice.reducerPath]: basicNotificationSlice.reducer,
    [wordpressNotificationSlice.reducerPath]:
      wordpressNotificationSlice.reducer,
    [generalSettingsApiSlice.reducerPath]: generalSettingsApiSlice.reducer,
    [appIconSlice.reducerPath]: appIconSlice.reducer,
    [splashSlice.reducerPath]: splashSlice.reducer,
    [headerApiSlice.reducerPath]: headerApiSlice.reducer,
    [profileSlice.reducerPath]: profileSlice.reducer,
    [webViewApiSlice.reducerPath]: webViewApiSlice.reducer,
    [productsApiSlice.reducerPath]: productsApiSlice.reducer,
    [agencyDomainApiSlice.reducerPath]: agencyDomainApiSlice.reducer
  },
  // Adding the Api Slice middleware enables caching and invalidation
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      appDashboardApiSlice.middleware,
      analyticsApiSlice.middleware,
      createAppApiSlice.middleware,
      customCustomerApiSlice.middleware,
      menuApiSlice.middleware,
      bottomApiSlice.middleware,
      userWebsiteApiSlice.middleware,
      cmsApiSlice.middleware,
      mergeApiSlice.middleware,
      teamApiSlice.middleware,
      addonsApiSlice.middleware,
      designThemeApiSlice.middleware,
      basicNotificationSlice.middleware,
      wordpressNotificationSlice.middleware,
      generalSettingsApiSlice.middleware,
      appIconSlice.middleware,
      splashSlice.middleware,
      headerApiSlice.middleware,
      profileSlice.middleware,
      webViewApiSlice.middleware,
      webViewApiSlice.middleware,
      productsApiSlice.middleware,
      agencyDomainApiSlice.middleware
    )
});

setupListeners(store.dispatch);

export default store;
