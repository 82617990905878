import React from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  } from "reactstrap";

const Connectpopup = (props) =>{
  
return(
      	<>
        	 <Modal isOpen={props.limitreached} style={{ maxWidth: "460px", maxHeight: "270px" }} className="activate-customer-popup" centered >

<ModalBody>

<div className="crossmodal mt-1" onClick={props.closefunction} >
	<img
		src={require("../../Agency/assets/images/cross.png").default}
	/>
</div>

<div>
	<div className="confirm-org-body">
		<h4>Connection failed</h4>
		<h5>Unable to establish a connection between your website and this app. Check your connectivity settings to identify and troubleshoot.</h5>
		
	</div>
	<div className="deactivate-customer-footer">

		<div className="team-limit-btn-cancel activate-customer-cancel-btn" onClick={props.closefunction} >
			Cancel
		</div>
									
		<a href={`/app/connect/troubleshoot-logs`}>
		<div className="team-limit-btn troubleshoot-team-limit-btn" id="btncustomrtactiveid">
		Troubleshoot
		</div>
		</a> 
		
	</div>
</div>
</ModalBody>
</Modal>
		</>
    	);


	}
	
export default Connectpopup;
