import React, { useEffect, Suspense, useReducer } from "react";
import "./CreateApp.css";
import Header from "../../../Dashboard/Header";
import Spinner from "../../../Components/commonComponents/Spinner";
import { Popup } from "./Context";
// import { toPng } from "html-to-image";
import { errortoast } from "../../../Dashboard/Toaster";
import CreateAppForm from "../../../Components/CreateApp/CreateAppForm"; // Replacing the form component with SharedForm
import imageSrcWordPress from "../../../assets/images/dashboard/selected-wordpress-new.png";
import imageSrcWooCommerce from "../../../assets/images/dashboard/selected-woocomerce-new.png";
import imageSrcCustomApp from "../../../assets/images/dashboard/selected-customapp-new.png";
import imageSrcWebToApp from "../../../assets/images/dashboard/selected-website-new.png";
import ProductFeatures from "../../../Components/CreateApp/ProductFeatures"; // Single reusable component
import {
  setPreviouslocation,
  setBuildversion,
  setApiversion,
  setWebsitetype,
  setWebsitetypeselect,
  setWebsiteTechnology,
  setAppiconcount,
  setSplashiconcount,
  setLoginregistericoncount,
  setStyleiconcount,
  setDashboardiconcount,
  setUserid,
  setAppcount,
  setAppname,
  setAppversion,
  setStoreurl,
  setAppid,
  setPlugindataactive,
  setSubscriptionactive,
  setAppsettingactive,
  setPersonaliseactive,
  setIsverified,
} from "../../../Redux/slices/appDataSlice";
import {
  useShowtecApiQuery,
  useShowtypeApiQuery,
  useCreateApiMutation,
} from "../../../services/createAppApiSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";

const Alertpopup = React.lazy(() =>
  import("../../../Dashboard/Reusable/Appsumopopup")
);
const Currencychange = React.lazy(() =>
  import("../../../Components/CreateApp/Changecurrency")
);

const CreateAppMain = ({ match }) => {
  const tempHistory = useHistory();
  const dispatch = useDispatch();
  const [createData, setcreateData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      websiteLocator: "1",
      websiteUrl: "",
      updateWebsiteUrl: "",
      websiteName: "",
      storeUrlError: null,
      appNameError: null,
      submitVal: "Create",
      loader: false,
      spinner: false,
      WooCommerceCheck: "",
      websiteContent: "",
      activeLink: true,
      websitePlatform: 2,
      websiteTypeSelect: 1,
      licenceId: null,
      supportText: "",
      refreshHttp: "https://",
      readOnly: false,
      technologyData: [],
      typeSelection: [],
      licenceType: [],
      customerType: null,
      bodySpinner: true,
      appCount: 0,
      userCountry: null,
      currencyPopup: false,
      title: "",
      description: "",
      hitPopup: false,
      bothButton: true,
      customerBillingType: 0,
      workspaceId: match.params.workspaceId
        ? atob(match.params.workspaceId)
        : null,
      workspaceWebTechnology: "2",
      workspacePlan: "",
      emailVerified: false,
      openAppDropdown: false,
      licencePlanName: "Select",
    }
  );

  const {
    data: ShowtecApiData,
    isError,
    isLoading,
  } = useShowtecApiQuery({ createData: createData.workspaceId });

  const { data: TypeApiData, isError1, isLoading1 } = useShowtypeApiQuery();

  const [CreateDataApi, { CreateApi }] = useCreateApiMutation();

  const handleWebsiteTypeChange = (type) => {
    setcreateData({ websitePlatform: type });
  };

  useEffect(() => {
    const { workspaceId, websitePlatform, websiteTypeSelect } = createData;

    document.title =
      localStorage.getItem("agencyid") === "1"
        ? "Create app | AppMySite"
        : `Create app | ${localStorage.getItem("agencyname")}`;

    window.scrollTo(0, 0);

    // const node = document.getElementById("clockid");
    document.body.classList.remove("opec3", "opec4");

    // toPng(node).then((dataUrl) => {
    //   var img = new Image();
    //   img.src = dataUrl;
    // });

    if (ShowtecApiData) {
      let currObj = { ...createData };

      if (ShowtecApiData.code === 200) {
        currObj.spinner = false;
        currObj.technologyData = ShowtecApiData.data;
        currObj.websitePlatform =
          workspaceId === null || workspaceId === "null"
            ? websitePlatform
            : ShowtecApiData.workspace_technology === 1
            ? 2
            : ShowtecApiData.workspace_technology || 2;
        currObj.websiteTypeSelect =
          workspaceId === null || workspaceId === "null"
            ? websiteTypeSelect
            : ShowtecApiData.workspace_website_type;
        currObj.workspaceWebTechnology = ShowtecApiData.workspace_technology;
        currObj.workspacePlan = ShowtecApiData.workspace_plan_code;

        setcreateData(currObj);
      } else if (currObj.code === 401 && currObj.status === "Token Expired") {
        handleTokenExpiry();
      } else if (currObj.code === 201) {
        errortoast("Something went wrong. Try again after sometime.");
        currObj.spinner = false;
      }
    }

    if (TypeApiData) {
      let currObj1 = { ...createData };
      if (TypeApiData.code === 200) {
        currObj1.spinner = false;
        currObj1.typeSelection = TypeApiData.data;
        currObj1.licenceType = TypeApiData.user_licences;
        currObj1.customerType = TypeApiData.customer_type;
        currObj1.bodySpinner = false;
        currObj1.appCount = TypeApiData.app_count;
        currObj1.userCountry = "US";
        currObj1.customerBillingType = TypeApiData.customer_billing_type;
        currObj1.emailVerified = TypeApiData.email_marketing?.email_verified;

        setcreateData(currObj1);

        if (TypeApiData.email_marketing) {
          trackUser(TypeApiData.email_marketing);
        }
      } else if (currObj1.code === 401 && currObj1.status === "Token Expired") {
        handleTokenExpiry();
      } else if (currObj1.code === 201) {
        errortoast("Something went wrong. Try again after sometime.");
        currObj1.spinner = false;
      }
    }

  }, [
    createData.workspaceId,
    createData.websitePlatform,
    ShowtecApiData,
    TypeApiData,
  ]);

  const handleTokenExpiry = () => {
    let buildVersion = localStorage.getItem("buildversion");
    let apiVersion = localStorage.getItem("Apiversion");
    let previousLocation = localStorage.getItem("Previouslocation");

    localStorage.clear();
    localStorage.setItem("Previouslocation", previousLocation);
    localStorage.setItem("buildversion", buildVersion);
    localStorage.setItem("Apiversion", apiVersion);

    dispatch(setPreviouslocation(previousLocation));
    dispatch(setBuildversion(buildVersion));
    dispatch(setApiversion(apiVersion));
  };

  const trackUser = (emailMarketing) => {
    const params = {
      contact: {
        FIRSTNAME: emailMarketing.name,
        AMS_CUSTOMER_ID: emailMarketing.ams_customer_id,
        PAYMENT_CUSTOMER_ID: emailMarketing.zoho_customer_id,
        EMAIL_VERIFIED: emailMarketing.email_verified,
        CUSTOMER_TYPE: emailMarketing.customer_type.toString(),
        APP_PLAN: emailMarketing.appsumo_plan,
        FREE_OR_PAID: emailMarketing.free_or_paid,
        OWNER_TYPE: emailMarketing.owner_type,
        LOGIN_COUNTER: emailMarketing.login_counter,
        COUNTRY_CODE: emailMarketing.country_code,
        TOTAL_APPS: emailMarketing.total_apps,
      },
      cuid: emailMarketing.ams_customer_id.toString(),
      email_id: emailMarketing.email,
      sib_type: "identify",
    };

    if (window.hotjar && window.hotjar.initialized()) {
      window.hotjar.identify("USER_ID", params);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setcreateData({ [name]: value });
    setcreateData({storeUrlError: '',appNameError:''});
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    let params = [];

    if (createData.loader) {
      return false;
    }

    const urlRegex = /^(?!:\/\/)(?=.{1,255}$)((.{1,63}\.){1,127}(?![0-9]*$)[_a-z0-9=?\-\/]+\.?)$/i;
    // Validate website URL
    if (createData.websitePlatform !== 3 && createData.websiteUrl) 
    {
      if (!urlRegex.test(createData.websiteUrl)) {
        setcreateData({ storeUrlError: 'Please enter a valid website URL' });
        return false;
      } else {
        setcreateData({ storeUrlError: '' });
      }
    }

    if (
      createData.websitePlatform !== 3 &&
      (createData.websiteName == "" || createData.websiteUrl == "")
    ) {
      return false;
    }

    if (createData.websitePlatform == 3 && createData.websiteName == "") {
      return false;
    }

    if (createData.websiteName != "" && createData.websiteName.length < 3) {
      setcreateData({
        appNameError: "Please enter at least 3 character for app name",
      });
      return false;
    } else {
      setcreateData({ appNameError: "" });
    }

    params = {
      user_id: localStorage.getItem("user_id"),
      app_name: createData.websiteName,
      httpprotocol:
        createData.websitePlatform !== 3 ? createData.websiteLocator : null,
      store_url:
        createData.websitePlatform !== 3
          ? createData.websiteUrl
              .replace(/ /g, "+")
              .replace(/^https?:\/\//i, "")
          : null,
      website_technology:
        createData.websitePlatform === null
          ? 2
          : createData.websitePlatform === 0
          ? 2
          : parseInt(createData.websitePlatform),
      website_type:
        createData.websitePlatform === 2
          ? 1
          : parseInt(createData.websiteTypeSelect),
      licence_id: createData.licenceId,
      workspace_id:
        match.params.workspaceId === undefined ||
        match.params.workspaceId === "undefined"
          ? ""
          : parseInt(atob(match.params.workspaceId)),
      emailverified: createData.emailVerified,
      agency_id: localStorage.getItem("agencyid")
        ? parseInt(localStorage.getItem("agencyid"))
        : 1,
    };

    localStorage.setItem("website_type", createData.websitePlatform);
    localStorage.setItem("website_type_select", createData.websiteTypeSelect);
    localStorage.setItem("website_technology", createData.websitePlatform);
    dispatch(setWebsitetype(createData.websitePlatform));
    dispatch(setWebsitetypeselect(createData.websiteTypeSelect));
    dispatch(setWebsiteTechnology(createData.websitePlatform));

    params.httpprotocol === "0" && errortoast("We support only HTTPS Website");

    setcreateData({ submitVal: "", loader: true });

    ((params.httpprotocol === "1" && createData.activeLink) ||
      createData.websitePlatform === 3) &&
      CreateDataApi(params)
        .unwrap()
        .then((response) => {
          if (response.code == 200) {
            updateLocalStorage(response.data);
            navigateUser(match, params.emailverified, response.data.id);
            localStorage.removeItem("skipdone");
          }
          else
          {
            setcreateData({ submitVal: "Create", loader: false });
            errortoast("Oops! Something went wrong.");
          }
        })
        .catch((err) => {
          setcreateData({ submitVal: "Create", loader: false });
          errortoast("Oops! Something went wrong.");
        });
  };

  const updateLocalStorage = (data) => {
    const originalId =
      parseInt(localStorage.getItem("logged_in_user_role_id")) === 1
        ? localStorage.getItem("originalid")
        : localStorage.getItem("invited_by_user_id");

    localStorage.setItem("app_icon_count", 0);
    localStorage.setItem("splash_icon_count", 0);
    localStorage.setItem("login_register_icon_count", 0);
    localStorage.setItem("style_icon_count", 0);
    localStorage.setItem("dashboard_icon_count", 0);
    localStorage.setItem("user_id", originalId);
    localStorage.setItem("Appcount", data.appcount);
    localStorage.setItem("Appname", data.app_name);
    localStorage.setItem("Appversion", data.version);
    localStorage.setItem("store_url", data.store_url);
    localStorage.setItem("Appid", data.id);
    localStorage.setItem("plugin_dataactive", "0");
    localStorage.setItem("subscriptionactive", "0");
    localStorage.setItem("appsettingactive", "0");
    localStorage.setItem("personaliseactive", "0");
    localStorage.setItem("is_verified", "0");

    dispatch(setAppiconcount(0));
    dispatch(setSplashiconcount(0));
    dispatch(setLoginregistericoncount(0));
    dispatch(setStyleiconcount(0));
    dispatch(setDashboardiconcount(0));
    dispatch(setUserid(originalId));
    dispatch(setAppcount(data.appcount));
    dispatch(setAppname(data.app_name));
    dispatch(setAppversion(data.version));
    dispatch(setStoreurl(data.store_url));
    dispatch(setAppid(data.id));
    dispatch(setPlugindataactive(0));
    dispatch(setSubscriptionactive(0));
    dispatch(setAppsettingactive(0));
    dispatch(setPersonaliseactive(0));
    dispatch(setIsverified(0));
  };

  const navigateUser = (match, emailVerified, appId) => {
    if (emailVerified === 0) {
      tempHistory.push("/apps");
    } else {
      const userInfo = JSON.parse(localStorage.getItem("user_info"));
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "create_app",
        user_email: userInfo.email,
        user_name: userInfo.name,
        user_id: localStorage.getItem("user_id"),
        app_id: appId,
        agency_id: localStorage.getItem("agency_id")
          ? parseInt(localStorage.getItem("agency_id"))
          : 1,
      });
      window.location.href = "/app/dashboard";
    }
  };

  const getFeatureData = () => {
    switch (createData.websitePlatform) {
      case 4:
        return {
          imageSrc: imageSrcWordPress,
          title: "Product features",
          featureList: [
            "Ideal for WordPress websites",
            "WordPress plugin support",
            "Native WordPress compatibility",
            "One-click WordPress connection",
            "Auto-populate app screens",
            "Troubleshoot connectivity issues",
            "Unlimited themes supported",
            "Synchronized user-login",
            "Seamless website-app sync",
            "Web to app functionality included",
          ],
        };
      case 5:
        return {
          imageSrc: imageSrcWooCommerce,
          title: "Product features",
          featureList: [
            "Ideal for WooCommerce websites",
            "WooCommerce plugin support",
            "Native WooCommerce compatibility",
            "One-click WooCommerce connection",
            "Auto-populate products & categories",
            "Universal payment and shipping support",
            "Coupons, orders, & more",
            "Synchronized user-login",
            "Seamless website-app sync",
            "Web to app functionality included",
          ],
        };
      case 3:
        return {
          imageSrc: imageSrcCustomApp,
          title: "Product features",
          featureList: [
            "No website required",
            "In-built Content Management System",
            "Add unlimited pages and posts",
            "No hosting required",
            "Embed maps, videos & custom scripts",
            "Enhance posts with CTAs, buttons & links",
            "Create attributes for advanced filtering",
            "Web to app functionality included",
          ],
        };
      case 2:
        return {
          imageSrc: imageSrcWebToApp,
          title: "Product features",
          featureList: [
            "Convert any website to an app",
            "Compatible with any web technology",
            "Supports all platforms and frameworks",
            "Real-time sync between web and app",
            "Add native features to enhance functionality",
            "Customizable web view interface",
            "Maintain complete brand consistency",
            "Compliant with app stores",
          ],
        };
      default:
        return {
          imageSrc: imageSrcWebToApp,
          title: "Product features",
          featureList: [
            "Convert any website to an app",
            "Compatible with any web technology",
            "Supports all platforms and frameworks",
            "Real-time sync between web and app",
            "Add native features to enhance functionality",
            "Customizable web view interface",
            "Maintain complete brand consistency",
            "Compliant with app stores",
          ],
        };
    }
  };

  const { imageSrc, title, featureList } = getFeatureData();

  const Appdropdown = () => {
    setcreateData({ openAppDropdown: !createData.openAppDropdown });
  };

  const handleProps = {
    title: "Create an app",
    websiteName: createData.websiteName,
    appNameError: createData.appNameError,
    handleWebsiteName: handleChange,
    workspaceWebTechnology: createData.workspaceWebTechnology,
    websitePlatform: createData.websitePlatform,
    handleWebsiteType: handleWebsiteTypeChange,
    workspacePlan: createData.workspacePlan,
    licenceType: createData.licenceType,
    licencePlanName: createData.licencePlanName,
    handleLicenceId: (id, name) =>
      setcreateData({ licenceId: id, licencePlanName: name }),
    licenceId: createData.licenceId,
    openAppDropdown: createData.openAppDropdown,
    handleAppDropdown: Appdropdown,
    storeUrlError: createData.storeUrlError,
    websiteUrl: createData.websiteUrl,
    readOnly: createData.readOnly,
    handleWebsiteUrl: handleChange,
    updateInput: () => {}, // Placeholder for update input logic
    createApp: handleFormSubmit,
    loader: createData.loader,
    submitVal: createData.submitVal,
    match: match, // Pass match to handleProps
  };

  return (
    <>
      <Header />
      {createData.customerBillingType !== 2 &&
        !createData.bodySpinner &&
        createData.appCount === 0 && (
          <Popup.Provider value={handleProps}>
            <Suspense fallback={<Spinner />}>
              <Currencychange />
            </Suspense>
          </Popup.Provider>
        )}
      <Suspense fallback={<Spinner />}>
        {createData.hitPopup && <Alertpopup value={handleProps} />}
      </Suspense>
      <section className="new-create-app-new">
        {createData.bodySpinner ? <Spinner /> : null}
        <div className="row new-create-appdiv">
          <div className="col-md-6 new-createapp-left">
            <CreateAppForm {...handleProps} />
          </div>
          <div className="col-md-6 new-createapp-right">
            <div>
              <ProductFeatures
                imageSrc={imageSrc}
                title={title}
                featureList={featureList}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateAppMain;
