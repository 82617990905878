import React, { Component } from "react";
import { Carousel } from "react-responsive-carousel";

export class CustomAdPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // activeSlide: 0,
    };
  }

  calculateHeightFinalHeight = (width,height) => {

    let finalHeight=10;
    let mobWidth=this.props.value.state.fitBanner?302:286;
    finalHeight = ((mobWidth*height)/width );
    return finalHeight;

  };

  returnSelectedModuleImg = (module_name, ad_position,item) => {
    if (module_name === "home_screen") {
      return(
        <div style={{
          height:"643px"
        }}>
          <div className="customad-bottomad">
          {ad_position === "bottom" && (
            <div className="third-ad-textdiv ">
              <div
                className={`${ "fourth-ad-text fourth-ad-text-withoutabsolute"
                } ${
                  this.props.value.state.fitBanner === true
                    ? ""
                    : "ad-with-padding"
                }`}
                style={{
                  top:"unset",
                  bottom:  "20px" ,
                  marginBottom: "10px",
                  height:this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height),
                }}
              >
                        <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                  {/* <p>Custom ad</p> */}
                
              </div>
            </div>
          )}
        </div>
        <div
        className=""
        style={{
          backgroundColor: "#ffffff",
        }}
      >
       
        <div>
          <img
            src={
              require("../../assets/images/dashboard/advertisement/new-image/homeadheader.png")
                .default
            }
            className=""
            alt=""
          />
        </div>
        <div>
          {ad_position === "top" && (
            <div className="third-ad-textdiv ">
              <div
                className={`${"fourth-ad-text fourth-ad-text-withoutabsolute"} ${ 
                  this.props.value.state.fitBanner === true
                    ? ""
                    : "ad-with-padding"
                }`}
                style={{
                  top:this.props.value.state.fitBanner === true? "44px": "54px",
                     
                  bottom: "unset",
                  marginTop:
                    this.props.value.state.fitBanner === true ? "0px" : "",
                    height:this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height),
                }}
              >
                <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                  {/* <p>Custom ad</p> */}
           
              </div>
            </div>
          )}
        </div>
        <div className="customad-previewbody">
          {
            ad_position==="top"?
          
          <img
            src={
              require("../../assets/images/dashboard/advertisement/new-image/homeadtop.png")
                .default
            }
            className=""
            alt=""
          />:
          <img
          src={
            require("../../assets/images/dashboard/advertisement/new-image/homeadbottom.png")
              .default
          }
          className=""
          alt=""
        />
    }
        </div>
       
        </div>
        </div>
      )
    } else if (
      (module_name === "product_cat" && item ==="all" ) ||
      module_name === "blog_cat" ||
      module_name === "post_cat"
    ) {
      return (

        <div style={{
          height:"643px"
        }}>
          <div className="customad-bottomad">
          {ad_position === "bottom" && (
            <div className="third-ad-textdiv ">
              <div
                className={`${ "fourth-ad-text fourth-ad-text-withoutabsolute"
                } ${
                  this.props.value.state.fitBanner === true
                    ? ""
                    : "ad-with-padding"
                }`}
                style={{
                  top:"unset",
                  bottom:  "20px" ,
                  marginBottom: "10px",
                  height:this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height),
                }}
              >
                <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                  {/* <p>Custom ad</p> */}
                
              </div>
            </div>
          )}
        </div>
        <div
        className=""
        style={{
          backgroundColor: "#ffffff",
        }}
      >
       
        <div>
          <img
            src={
              require("../../assets/images/dashboard/advertisement/new-image/categorieshead.png")
                .default
            }
            className=""
            alt=""
          />
        </div>
        <div>
        {ad_position === "top" && (
            <div className="third-ad-textdiv ">
              <div
                className={`${"fourth-ad-text fourth-ad-text-withoutabsolute"} ${ 
                  this.props.value.state.fitBanner === true
                    ? ""
                    : "ad-with-padding"
                }`}
                style={{
                  top:this.props.value.state.fitBanner === true? "44px": "54px",
                     
                  bottom: "unset",
                  marginTop:
                    this.props.value.state.fitBanner === true ? "0px" : "",
                    height:this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height),
                }}
              >
                <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                  {/* <p>Custom ad</p> */}
           
              </div>
            </div>
          )}
        </div>
        <div className="customad-previewbody">
          {
            ad_position==="top"?
          
          <img
            src={
              require("../../assets/images/dashboard/advertisement/new-image/categoriesadtop.png")
                .default
            }
            className=""
            alt=""
          />:
          <img
          src={
            require("../../assets/images/dashboard/advertisement/new-image/categoriesadbottom.png")
              .default
          }
          className=""
          alt=""
        />
    }
        </div>
       
        </div>
        </div>
  
      );
    } else if ( (module_name === "product_cat" && item !=="all" ) ||module_name === "product_list") {
      return (

        <div style={{
          height:"643px"
        }}>
          <div className="customad-bottomad">
          {ad_position === "bottom" && (
            <div className="third-ad-textdiv ">
              <div
                className={`${ "fourth-ad-text fourth-ad-text-withoutabsolute"
                } ${
                  this.props.value.state.fitBanner === true
                    ? ""
                    : "ad-with-padding"
                }`}
                style={{
                  top:"unset",
                  bottom:  "20px" ,
                  marginBottom: "10px",
                  height:this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height),
                }}
              >
             <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                  {/* <p>Custom ad</p> */}
                
              </div>
            </div>
          )}
        </div>
        <div
        className=""
        style={{
          backgroundColor: "#ffffff",
        }}
      >
       
        <div>
          <img
            src={
              require("../../assets/images/dashboard/advertisement/new-image/productshead.png")
                .default
            }
            className=""
            alt=""
          />
        </div>
        <div>
        {ad_position === "top" && (
            <div className="third-ad-textdiv ">
              <div
                className={`${"fourth-ad-text fourth-ad-text-withoutabsolute"} ${ 
                  this.props.value.state.fitBanner === true
                    ? ""
                    : "ad-with-padding"
                }`}
                style={{
                  top:this.props.value.state.fitBanner === true? "44px": "54px",
                     
                  bottom: "unset",
                  marginTop:
                    this.props.value.state.fitBanner === true ? "0px" : "",
                    height:this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height),
                }}
              >
              <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                  {/* <p>Custom ad</p> */}
           
              </div>
            </div>
          )}
        </div>
        <div className="customad-previewbody">
          {
            ad_position==="top"?
          
          <img
            src={
              require("../../assets/images/dashboard/advertisement/new-image/produstbody.png")
                .default
            }
            className=""
            alt=""
          />:
          <img
          src={
            require("../../assets/images/dashboard/advertisement/new-image/produstbody.png")
              .default
          }
          className=""
          alt=""
        />
    }
        </div>
       
        </div>
        </div>
  
      );
    } else if (module_name === "product_details") {
      return (

        <div style={{
          height:"643px"
        }}>
          <div className="customad-bottomad">
          {ad_position === "bottom" && (
            <div className="third-ad-textdiv ">
              <div
                className={`${ "fourth-ad-text fourth-ad-text-withoutabsolute"
                } ${
                  this.props.value.state.fitBanner === true
                    ? ""
                    : "ad-with-padding"
                }`}
                style={{
                  top:"unset",
                  bottom:  "20px" ,
                  marginBottom: "10px",
                  height:this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height),
                }}
              >
               <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                  {/* <p>Custom ad</p> */}
                
              </div>
            </div>
          )}
        </div>
        <div
        className=""
        style={{
          backgroundColor: "#ffffff",
        }}
      >
       
        <div>
          <img
            src={
              require("../../assets/images/dashboard/advertisement/new-image/productdetailshead.png")
                .default
            }
            className=""
            alt=""
          />
        </div>
        <div>
        {ad_position === "top" && (
            <div className="third-ad-textdiv ">
              <div
                className={`${"fourth-ad-text fourth-ad-text-withoutabsolute"} ${ 
                  this.props.value.state.fitBanner === true
                    ? ""
                    : "ad-with-padding"
                }`}
                style={{
                  top:this.props.value.state.fitBanner === true? "44px": "54px",
                     
                  bottom: "unset",
                  marginTop:
                    this.props.value.state.fitBanner === true ? "0px" : "",
                    height:this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height),
                }}
              >
               <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                  {/* <p>Custom ad</p> */}
           
              </div>
            </div>
          )}
        </div>
        <div className="customad-previewbody">
          {
            ad_position==="top"?
          
          <img
            src={
              require("../../assets/images/dashboard/advertisement/new-image/productdetailsadtop.png")
                .default
            }
            className=""
            alt=""
          />:
          <img
          src={
            require("../../assets/images/dashboard/advertisement/new-image/productdetailsbottomad.png")
              .default
          }
          className=""
          alt=""
        />
    }
        </div>
       
        </div>
        </div>
  
      );
    } else if (module_name === "cart") {
      return(
        <div style={{
          height:"643px"
        }}>
          <div className="customad-bottomad">
          {ad_position === "bottom" && (
            <div className="third-ad-textdiv ">
              <div
                className={`${ "fourth-ad-text fourth-ad-text-withoutabsolute"
                } ${
                  this.props.value.state.fitBanner === true
                    ? ""
                    : "ad-with-padding"
                }`}
                style={{
                  top:"unset",
                  bottom:  "20px" ,
                  marginBottom: "10px",
                  height:this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height),
                }}
              >
                 <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                  {/* <p>Custom ad</p> */}
                
              </div>
            </div>
          )}
        </div>
        <div
        className=""
        style={{
          backgroundColor: "#ffffff",
        }}
      >
       
        <div>
          <img
            src={
              require("../../assets/images/dashboard/advertisement/new-image/carthead.png")
                .default
            }
            className=""
            alt=""
          />
        </div>
        <div>
        {ad_position === "top" && (
            <div className="third-ad-textdiv ">
              <div
                className={`${"fourth-ad-text fourth-ad-text-withoutabsolute"} ${ 
                  this.props.value.state.fitBanner === true
                    ? ""
                    : "ad-with-padding"
                }`}
                style={{
                  top:this.props.value.state.fitBanner === true? "44px": "54px",
                     
                  bottom: "unset",
                  marginTop:
                    this.props.value.state.fitBanner === true ? "0px" : "",
                    height:this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height),
                }}
              >
             <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                  {/* <p>Custom ad</p> */}
           
              </div>
            </div>
          )}
        </div>
        <div className="customad-previewbody">
          {
            ad_position==="top"?
          
          <img
            src={
              require("../../assets/images/dashboard/advertisement/new-image/cartaddtop.png")
                .default
            }
            className=""
            alt=""
          />:
          <img
          src={
            require("../../assets/images/dashboard/advertisement/new-image/cartadbottom.png")
              .default
          }
          className=""
          alt=""
        />
    }
        </div>
       
        </div>
        </div> )
    } else if (module_name === "page_list") {
      return(
      <div style={{
        height:"643px"
      }}>
        <div className="customad-bottomad">
        {ad_position === "bottom" && (
          <div className="third-ad-textdiv ">
            <div
              className={`${ "fourth-ad-text fourth-ad-text-withoutabsolute"
              } ${
                this.props.value.state.fitBanner === true
                  ? ""
                  : "ad-with-padding"
              }`}
              style={{
                top:"unset",
                bottom:  "20px" ,
                marginBottom: "10px",
                height:this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height),
              }}
            >
             <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                  {/* <p>Custom ad</p> */}
              
            </div>
          </div>
        )}
      </div>
      <div
      className=""
      style={{
        backgroundColor: "#ffffff",
      }}
    >
     
      <div>
        <img
          src={
            require("../../assets/images/dashboard/advertisement/new-image/pageshead.png")
              .default
          }
          className=""
          alt=""
        />
      </div>
      <div>
      {ad_position === "top" && (
          <div className="third-ad-textdiv ">
            <div
              className={`${"fourth-ad-text fourth-ad-text-withoutabsolute"} ${ 
                this.props.value.state.fitBanner === true
                  ? ""
                  : "ad-with-padding"
              }`}
              style={{
                top:this.props.value.state.fitBanner === true? "44px": "54px",
                   
                bottom: "unset",
                marginTop:
                  this.props.value.state.fitBanner === true ? "0px" : "",
                  height:this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height),
              }}
            > <iframe 
            srcDoc={
              this.props.value.state.deviceTypeForPreview === "ios" ?
              this.props.value.state.iosAdScript :
              this.props.value.state.androidAdScript
            } 
           style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
           />
    {/* <p>Custom ad</p> */}
         
            </div>
          </div>
        )}
      </div>
      <div className="customad-previewbody">
        {
          ad_position==="top"?
        
        <img
          src={
            require("../../assets/images/dashboard/advertisement/new-image/pagesbody.png")
              .default
          }
          className=""
          alt=""
        />:
        <img
        src={
          require("../../assets/images/dashboard/advertisement/new-image/pagesbody.png")
            .default
        }
        className=""
        alt=""
      />
  }
      </div>
     
      </div>
      </div> )
    } else if (
      module_name === "post_details" ||
      module_name === "blog_details"
     ) {
      return(
        <div style={{
          height:"643px"
        }}>
          <div className="customad-bottomad">
          {ad_position === "bottom" && (
            <div className="third-ad-textdiv ">
              <div
                className={`${ "fourth-ad-text fourth-ad-text-withoutabsolute"
                } ${
                  this.props.value.state.fitBanner === true
                    ? ""
                    : "ad-with-padding"
                }`}
                style={{
                  top:"unset",
                  bottom:  "20px" ,
                  marginBottom: "10px",
                  height:this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height),
                }}
              >
               <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                  {/* <p>Custom ad</p> */}
                
              </div>
            </div>
          )}
        </div>
        <div
        className=""
        style={{
          backgroundColor: "#ffffff",
        }}
      >
       
        <div>
          <img
            src={
              require("../../assets/images/dashboard/advertisement/new-image/postpagehead.png")
                .default
            }
            className=""
            alt=""
          />
        </div>
        <div>
        {ad_position === "top" && (
            <div className="third-ad-textdiv ">
              <div
                className={`${"fourth-ad-text fourth-ad-text-withoutabsolute"} ${ 
                  this.props.value.state.fitBanner === true
                    ? ""
                    : "ad-with-padding"
                }`}
                style={{
                  top:this.props.value.state.fitBanner === true? "44px": "54px",
                     
                  bottom: "unset",
                  marginTop:
                    this.props.value.state.fitBanner === true ? "0px" : "",
                    height:this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height),
                }}
              >
                 <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                  {/* <p>Custom ad</p> */}
           
              </div>
            </div>
          )}
        </div>
        <div className="customad-previewbody">
          {
            ad_position==="top"?
          
          <img
            src={
              require("../../assets/images/dashboard/advertisement/new-image/postpageadtop.png")
                .default
            }
            className=""
            alt=""
          />:
          <img
          src={
            require("../../assets/images/dashboard/advertisement/new-image/postpageadbottom.png")
              .default
          }
          className=""
          alt=""
        />
    }
        </div>
       
        </div>
        </div> )
    } else if (
      module_name === "page_details"
    ) {
      return(
        <div style={{
          height:"643px"
        }}>
          <div className="customad-bottomad">
          {ad_position === "bottom" && (
            <div className="third-ad-textdiv ">
              <div
                className={`${ "fourth-ad-text fourth-ad-text-withoutabsolute"
                } ${
                  this.props.value.state.fitBanner === true
                    ? ""
                    : "ad-with-padding"
                }`}
                style={{
                  top:"unset",
                  bottom:  "20px" ,
                  marginBottom: "10px",
                  height:this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height),
                }}
              >
               <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                  {/* <p>Custom ad</p> */}
                
              </div>
            </div>
          )}
        </div>
        <div
        className=""
        style={{
          backgroundColor: "#ffffff",
        }}
      >
       
        <div>
          <img
            src={
              require("../../assets/images/dashboard/advertisement/new-image/postdetailshead.png")
                .default
            }
            className=""
            alt=""
          />
        </div>
        <div>
        {ad_position === "top" && (
            <div className="third-ad-textdiv ">
              <div
                className={`${"fourth-ad-text fourth-ad-text-withoutabsolute"} ${ 
                  this.props.value.state.fitBanner === true
                    ? ""
                    : "ad-with-padding"
                }`}
                style={{
                  top:this.props.value.state.fitBanner === true? "44px": "54px",
                     
                  bottom: "unset",
                  marginTop:
                    this.props.value.state.fitBanner === true ? "0px" : "",
                    height:this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height),
                }}
              >
                <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                  {/* <p>Custom ad</p> */}
           
              </div>
            </div>
          )}
        </div>
        <div className="customad-previewbody">
          {
            ad_position==="top"?
          
          <img
            src={
              require("../../assets/images/dashboard/advertisement/new-image/postdetailsbody.png")
                .default
            }
            className=""
            alt=""
          />:
          <img
          src={
            require("../../assets/images/dashboard/advertisement/new-image/pagebottombody.png")
              .default
          }
          className=""
          alt=""
        />
    }
        </div>
       
        </div>
        </div> )
    } else if (module_name === "web_view") {
      return(
        <div style={{
          height:"643px",
          backgroundColor:"#F9F9F9"
        }}>
          <div className="customad-bottomad">
          {ad_position === "bottom" && (
            <div className="third-ad-textdiv ">
              <div
                className={`${ "fourth-ad-text fourth-ad-text-withoutabsolute"
                } ${
                  this.props.value.state.fitBanner === true
                    ? ""
                    : "ad-with-padding"
                }`}
                style={{
                  top:"unset",
                  bottom:  "20px" ,
                  marginBottom: "10px",
                  height:this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height),
                }}
              >
                <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                  {/* <p>Custom ad</p> */}
                
              </div>
            </div>
          )}
        </div>
        <div
        className=""
     
      >
       
        <div>
          <img
            src={
              require("../../assets/images/dashboard/advertisement/new-image/webviewhead.png")
                .default
            }
            className=""
            alt=""
          />
        </div>
        <div>
        {ad_position === "top" && (
            <div className="third-ad-textdiv ">
              <div
                className={`${"fourth-ad-text fourth-ad-text-withoutabsolute"} ${ 
                  this.props.value.state.fitBanner === true
                    ? ""
                    : "ad-with-padding"
                }`}
                style={{
                  top:this.props.value.state.fitBanner === true? "44px": "54px",
                     
                  bottom: "unset",
                  marginTop:
                    this.props.value.state.fitBanner === true ? "0px" : "",
                    height:this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height),
                }}
              >
                 <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                  {/* <p>Custom ad</p> */}
           
              </div>
            </div>
          )}
        </div>
        <div className="customad-previewbody" style={{height: `calc(580px - ${this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height)}px)`}}>
          {
            ad_position==="top"?
          
          <img
            src={
              require("../../assets/images/dashboard/advertisement/new-image/webviewcenterimage.png")
                .default
            }
            className=""
            alt=""
          />:
          <img
          src={
            require("../../assets/images/dashboard/advertisement/new-image/webviewcenterimage.png")
              .default
          }
          className=""
          alt=""
        />
    }
        </div>
       
        </div>
        </div> )
    } else if (module_name === "post_list" || module_name === "blog_list") {
      return(
      <div style={{
        height:"643px"
      }}>
        <div className="customad-bottomad">
        {ad_position === "bottom" && (
          <div className="third-ad-textdiv ">
            <div
              className={`${ "fourth-ad-text fourth-ad-text-withoutabsolute"
              } ${
                this.props.value.state.fitBanner === true
                  ? ""
                  : "ad-with-padding"
              }`}
              style={{
                top:"unset",
                bottom:  "20px" ,
                marginBottom: "10px",
                height:this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height),
              }}
            >
            <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                  {/* <p>Custom ad</p> */}
              
            </div>
          </div>
        )}
      </div>
      <div
      className=""
      style={{
        backgroundColor: "#ffffff",
      }}
    >
     
      <div>
        <img
          src={
            require("../../assets/images/dashboard/advertisement/new-image/postshead.png")
              .default
          }
          className=""
          alt=""
        />
      </div>
      <div>
      {ad_position === "top" && (
          <div className="third-ad-textdiv ">
            <div
              className={`${"fourth-ad-text fourth-ad-text-withoutabsolute"} ${ 
                this.props.value.state.fitBanner === true
                  ? ""
                  : "ad-with-padding"
              }`}
              style={{
                top:this.props.value.state.fitBanner === true? "44px": "54px",
                   
                bottom: "unset",
                marginTop:
                  this.props.value.state.fitBanner === true ? "0px" : "",
                  height:this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height),
              }}
            >
              <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                  {/* <p>Custom ad</p> */}
         
            </div>
          </div>
        )}
      </div>
      <div className="customad-previewbody">
        {
          ad_position==="top"?
        
        <img
          src={
            require("../../assets/images/dashboard/advertisement/new-image/postbody.png")
              .default
          }
          className=""
          alt=""
        />:
        <img
        src={
          require("../../assets/images/dashboard/advertisement/new-image/postbody.png")
            .default
        }
        className=""
        alt=""
      />
  }
      </div>
     
      </div>
      </div> )
    }
  };

  calculateHeight = (ratio) => {
    let height = 43.75;
    switch (ratio) {
      case "300×250":
        height = 233.33;
        break;
      case "336×280":
        height = 233.33;
        break;
      case "320×50":
        height = 43.75;
        break;
      case "320×100":
        height = 87.5;
        break;
      case "336×336":
        height = 280;
        break;
      case "300×600":
        height = 560;
        break;
      case "360×300":
        height = 233.33;
        break;
      case "360×120":
        height = 93.33;
        break;
      case "320×480":
        height = 420;
        break;
      case "320×180":
        height = 157.5;
        break;
    }
    return height;
  };

  calculateHeightFullWidth = (ratio) => {
    let height = 45.93;
    switch (ratio) {
      case "300×250":
        height = 245;
        break;
      case "336×280":
        height = 245;
        break;
      case "320×50":
        height = 45.93;
        break;
      case "320×100":
        height = 91.87;
        break;
      case "336×336":
        height = 294;
        break;
      case "300×600":
        height = 588;
        break;
      case "360×300":
        height = 245;
        break;
      case "360×120":
        height = 98;
        break;
      case "320×480":
        height = 441;
        break;
      case "320×180":
        height = 165.375;
        break;
    }
    return height;
  };

  render() {
    return (
      <div className="customadd-previewframe">
      <Carousel
        selectedItem={this.props.value.state.activeSlide}
        // onChange={(e) => this.handlesliderChange(e)}
        showArrows={false}
        swipeable={true}
        showThumbs={false}
      >
        {this.props.value.state.locationArr &&
          this.props.value.state.locationArr.map((res, indx) => {
            return (
              <>
                {this.returnSelectedModuleImg(res.module_name, res.ad_position,res.item)}
              </>
            );
          })}
      </Carousel>
      </div>
    );
  }
}

export default CustomAdPreview;
